<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">TRANSMIT UNPOSTED INVOICE(S) TO CODA </label>
        <br />
        <br />

        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="four wide field">
              <div class="field" [class.error]="model['error-dateTime'] != null && model['error-dateTime'] != ''" >
                <label >Date Time</label>
                <!--<datetime-plugin  #dateMaintenanceEffectiveFrom [settings]="settingDateMaintenanceEffectiveFrom" ></datetime-plugin>-->
                <div class = "field" style="background-color: lightgray;" >
                  <date-plugin #dateTime [settings]="settingDateTime" (change)="changeEventDateFrom($event)" ></date-plugin> 
                </div>
              </div>
            </div>

            <div class="four wide field">
              <div class="one field" style="width:45%" [class.error]="model['error-userCreated'] != null && model['error-userCreated'] != ''">
                <label>User</label>
                <input type="text" name="userCreated" placeholder="user" [(ngModel)]="model['userCreated']" disabled ="disabled" style="background-color: lightgray;" >
              </div>
              
            </div>

          
          </div>
        </div>

        <div class="field" width="50%">
        
          <textarea-plugin #txtRemarksProcess [settings]="settingRemarksProcess" [disabled]="true"></textarea-plugin>

        </div>
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
      </div>  <!-- end of raised segment -->
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
