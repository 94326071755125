

export class Invoice {
userCreated  : string ='';
    
  
  
constructor() {
  
    }
}