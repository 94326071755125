<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #pfcToolbar
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <!--Error Message Block-->
    <div class="ui error message" [class.hidden]="isErrorFlag == false">
      <ul>
        <li
          [class.hidden]="
            model['error-pfcRecordId'] == null ||
            model['error-pfcRecordId'] == ''
          "
        >
          {{ model["error-pfcRecordId"] }}
        </li>
      </ul>
    </div>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-pfcFreightPartyId'] == null ||
            model['error-pfcFreightPartyId'] == ''
          "
        >
          {{ model["error-pfcFreightPartyId"] }}
        </li>
        <li
            [class.hidden]="
              model['error-pfcFreightPartyIdInvalid'] != null ||
              model['error-pfcFreightPartyIdInvalid'] != ''
            "
        >
         {{ model["error-pfcFreightPartyIdInvalid"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcShipperId'] == null ||
            model['error-pfcShipperId'] == ''
          "
        >
          {{ model["error-pfcShipperId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcBookingPartyId'] == null ||
            model['error-pfcBookingPartyId'] == ''
          "
        >
          {{ model["error-pfcBookingPartyId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcPlaceOfReceiptCode'] == null ||
            model['error-pfcPlaceOfReceiptCode'] == ''
          "
        >
          {{ model["error-pfcPlaceOfReceiptCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcLoadPortCode'] == null ||
            model['error-pfcLoadPortCode'] == ''
          "
        >
          {{ model["error-pfcLoadPortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcDischargePortCode'] == null ||
            model['error-pfcDischargePortCode'] == ''
          "
        >
          {{ model["error-pfcDischargePortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcPlaceOfDeliveryCode'] == null ||
            model['error-pfcPlaceOfDeliveryCode'] == ''
          "
        >
          {{ model["error-pfcPlaceOfDeliveryCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcReceiptTypeCode'] == null ||
            model['error-pfcReceiptTypeCode'] == ''
          "
        >
          {{ model["error-pfcReceiptTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcDeliveryTypeCode'] == null ||
            model['error-pfcDeliveryTypeCode'] == ''
          "
        >
          {{ model["error-pfcDeliveryTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcEffectiveFrom'] == null ||
            model['error-pfcEffectiveFrom'] == ''
          "
        >
          {{ model["error-pfcEffectiveFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcEffectiveTo'] == null ||
            model['error-pfcEffectiveTo'] == ''
          "
        >
          {{ model["error-pfcEffectiveTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcAcctPic'] == null || model['error-pfcAcctPic'] == ''
          "
        >
          {{ model["error-pfcAcctPic"] }}
        </li>
        <li
          [class.hidden]="
            model['error-effectiveDateExceedValidator'] == null ||
            model['error-effectiveDateExceedValidator'] == ''
          "
        >
          {{ model["error-effectiveDateExceedValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-expiryDateValidator'] == null ||
            model['error-expiryDateValidator'] == ''
          "
        >
          {{ model["error-expiryDateValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePORValidator'] == null ||
            model['error-samePORValidator'] == ''
          "
        >
          {{ model["error-samePORValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePOLValidator'] == null ||
            model['error-samePOLValidator'] == ''
          "
        >
          {{ model["error-samePOLValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePODValidator'] == null ||
            model['error-samePODValidator'] == ''
          "
        >
          {{ model["error-samePODValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePODLValidator'] == null ||
            model['error-samePODLValidator'] == ''
          "
        >
          {{ model["error-samePODLValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-sameFDestValidator'] == null ||
            model['error-sameFDestValidator'] == ''
          "
        >
          {{ model["error-sameFDestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankFreightChargesValidator'] == null ||
            model['error-blankFreightChargesValidator'] == ''
          "
        >
          {{ model["error-blankFreightChargesValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-missingMainChargesValidator'] == null ||
            model['error-missingMainChargesValidator'] == ''
          "
        >
          {{ model["error-missingMainChargesValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-mainChargesOnlyOneValidator'] == null ||
            model['error-mainChargesOnlyOneValidator'] == ''
          "
        >
          {{ model["error-mainChargesOnlyOneValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankDetentionRequestValidator'] == null ||
            model['error-blankDetentionRequestValidator'] == ''
          "
        >
          {{ model["error-blankDetentionRequestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankDemurrageRequestValidator'] == null ||
            model['error-blankDemurrageRequestValidator'] == ''
          "
        >
          {{ model["error-blankDemurrageRequestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankAdhocVoyageValidator'] == null ||
            model['error-blankAdhocVoyageValidator'] == ''
          "
        >
          {{ model["error-blankAdhocVoyageValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankPayerValidator'] == null ||
            model['error-blankPayerValidator'] == ''
          "
        >
          <!--{{ model["error-blankPayerValidator"] }}-->
          <div [innerHtml]="model['error-blankPayerValidator']"></div>
        </li>
        <li
          [class.hidden]="
            model['error-exceedPayerPOPValidator'] == null ||
            model['error-exceedPayerPOPValidator'] == ''
          "
        >
          {{ model["error-exceedPayerPOPValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-faiGroupValidator'] == null ||
            model['error-faiGroupValidator'] == ''
          "
        >
          {{ model["error-faiGroupValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-invalidPayerValidator'] == null ||
            model['error-invalidPayerValidator'] == ''
          "
        >
          <!--{{ model["error-invalidPayerValidator"] }}-->
          <div [innerHtml]="model['error-invalidPayerValidator']"></div>
        </li>
        <li
          [class.hidden]="
            model['error-allInvalidPayerValidator'] == null ||
            model['error-allInvalidPayerValidator'] == ''
          "
        >
          <div [innerHtml]="model['error-allInvalidPayerValidator']"></div>
        </li>
      </ul>
    </div>
    <!--End of Error Message Block-->

    <form class="ui form">
      <button class="ui button" style="display: none"></button>

      <div
        [style.display]="
          modeForm == '' || modeForm == 'freightFlagStatus' ? 'block' : 'none'
        "
        style="width: 100%"
      >
        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <div
            class="ui inverted dimmer"
            [class.active]="loading == true"
            style="z-index: 1"
          >
            <div class="ui medium text loader">Loading</div>
          </div>

          <div
            class="ui top attached tabular menu"
            style="background-color: beige"
          >
            <a class="item active" data-tab="General">General</a>
            <a class="item" data-tab="Terminal">Terminal</a>
          </div>

          <!-- data for tab general must goes here -->
          <div
            class="ui bottom attached tab segment active"
            id="GeneralTab"
            data-tab="General"
          >
            <a class="ui red ribbon label">Search Criteria</a>
            <!-- Row 1 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Freight Party</label>
                <combo-plugin
                  #cbFreightParty
                  [settings]="settingCbFreightParty"
                  (change)="changeFreightParty($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="validateFieldSurcharge($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Ownership</label>
                <combo-plugin
                  #cbContainerOwnership
                  [settings]="settingCbContainerOwnership"
                  (change)="changeContainerOwnership($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="validateFieldSurcharge($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Effective From</label
                ><!--settingEffectiveFrom-->
                <datetime-plugin
                  #dateEffectiveFrom
                  [settings]="settingDateEffectiveFrom"
                  [disableDatetime]="lockEffectiveFrom"
                ></datetime-plugin>
              </div>
              <div class="field" style="padding-right: 5px">
                <div class="field">
                  <label>Expiry On</label>
                  <datetime-plugin
                    #dateEffectiveTo
                    [settings]="settingDateEffectiveTo"
                    [disableDatetime]="lockEffectiveTo"
                  ></datetime-plugin>
                </div>
              </div>
            </div>
            <!-- Row 1 end -->

            <!-- Row 2 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Booking Party</label>
                <combo-plugin
                  #cbBookingParty
                  [settings]="settingCbBookingParty"
                  (change)="changeBookingParty($event)"
                  (keypress)="validateFieldSurcharge($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Shipper</label>
                <combo-plugin
                  #cbShipper
                  [settings]="settingCbShipper"
                  (change)="changeShipper($event)"
                  (keypress)="validateFieldSurcharge($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Approve From</label
                ><!--settingEffectiveFrom-->
                <datetime-plugin
                  #dateApprovedFrom
                  [settings]="settingDateApprovedFrom"
                  [disableDatetime]="lockDateApprovedFrom"
                ></datetime-plugin>
              </div>
              <div class="field" style="padding-right: 5px">
                <div class="field">
                  <label>Approved To</label>
                  <datetime-plugin
                    #dateApprovedTo
                    [settings]="settingDateApprovedTo"
                    [disableDatetime]="lockDateApprovedTo"
                  ></datetime-plugin>
                </div>
              </div>
            </div>
            <!-- Row 2 end -->

            <!-- Row 3 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Place of Receipt</label>
                <combo-plugin
                  #cbPlaceOfReceipt
                  [settings]="settingCbPlaceOfReceipt"
                  (change)="changePlaceOfReceipt($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Port of Load</label>
                <combo-plugin
                  #cbPortOfLoad
                  [settings]="settingCbPortOfLoad"
                  (change)="changePortOfLoad($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Type</label>
                <combo-plugin
                  #cbContainerType
                  [settings]="settingCbContainerType"
                  (change)="changeContainerType($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Status</label>
                <combo-plugin
                  #cbContainerStatus
                  [settings]="settingCbContainerStatus"
                  (change)="changeContainerStatus($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 3 end -->

            <!-- Row 4 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Port of Discharge</label>
                <combo-plugin
                  #cbPortOfDischarge
                  [settings]="settingCbPortOfDischarge"
                  (change)="changePortOfDischarge($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Place of Delivery</label>
                <combo-plugin
                  #cbPlaceOfDelivery
                  [settings]="settingCbPlaceOfDelivery"
                  (change)="changePlaceOfDelivery($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <div class="fields">
                  <div class="eight wide field">
                    <div class="field">
                      <label>Receipt Type</label>
                      <combo-plugin
                        #cbReceiptType
                        [settings]="settingCbReceiptType"
                        (change)="changeReceiptType($event)"
                        [disableCombo]="checkLock == true"
                      ></combo-plugin>
                    </div>
                  </div>
                  <div class="eight wide field">
                    <div class="field">
                      <label>Delivery Type</label>
                      <combo-plugin
                        #cbDeliveryType
                        [settings]="settingCbDeliveryType"
                        value="CY"
                        (change)="changeDeliveryType($event)"
                        [disableCombo]="checkLock == true"
                      ></combo-plugin>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field">
                <label>DG Class</label>
                <combo-plugin
                  #cbDGClass
                  [settings]="settingCbDGClass"
                  (change)="changeDGClass($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 4 end -->
            <!-- Row 5 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Port of Transshipment (1)</label>
                <combo-plugin
                  #cbPortOfTransshipment
                  [settings]="settingCbPortOfTransshipment"
                  (change)="changePortOfTransshipment($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Final Destination</label>
                <combo-plugin
                  #cbFinalDestination
                  [settings]="settingCbFinalDestination"
                  (change)="changeFinalDestination($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Shipment Type</label>
                <combo-plugin
                  #cbShipmentType
                  [settings]="settingCbShipmentType"
                  (change)="changeShipmentType($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Valid/Invalid</label>
                <combo-plugin
                  #cbValidInvalid
                  [settings]="settingCbValidInvalid"
                  (change)="changeValidInvalid($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 5 end -->

            <!-- Row 6 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field" style="margin-top: 5px">
                <div class="ui checkbox" style="margin-bottom: 0px">
                  <input
                    [checked]="model['pfcSerchByRefNo'] == 'Y'"
                    value="Y"
                    type="checkbox"
                    (change)="changeEventRetrieveByRefNo($event)"
                    name="checkbox"
                  />
                  <label>Retrieve by Reference Number</label>
                </div>
                <input
                  type="text"
                  [(ngModel)]="model['pfcRecordId']"
                  name="pfcRecordId"
                  placeholder="Ref No."
                  [disabled]="checkLock == false"
                  maxlength="30"
                />
                <!--<div class="field" style="margin-top:10px">
                <div class="ui checkbox" >
                  <input id="isDeleted" type="checkbox"  [checked]="model['pfcIsDeleted'] == 'Y'"  (change)="changeIsDeleted($event)" >
                  <label>Deleted&nbsp;&nbsp;&nbsp;</label>
                </div>
                <div class="ui checkbox" >
                  <input id="isCommissionable" type="checkbox"  [checked]="model['pfcIsCommission'] == 'Y'" (change)="changeIsCommissionable($event)" >
                  <label>Commissionable</label>
                </div>
              </div>-->
              </div>
              <div class="field">
                <label>Pending/Rejected/Approved</label>
                <combo-plugin
                  #cbAppStatus
                  [settings]="settingCbAppStatus"
                  (change)="changeEventCbAppStatus($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Adhoc Vessel</label>
                <combo-plugin
                  #cbAdhocVessel
                  [settings]="settingCbAdhocVessel"
                  (change)="changeAdhocVessel($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Adhoc Voyage</label>
                <combo-plugin
                  #cbAdhocVoyage
                  [settings]="settingCbAdhocVoyage"
                  (change)="changeAdhocVoyage($event)"
                  [disableCombo]="checkLock == true"
                  (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 6 end -->
            <!-- Row 7 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field"></div>
              <div class="field"></div>
              <div class="field">
                <label class="hidden">Test</label>
                <div class="ui checkbox">
                  <input
                    [checked]="model['pfcAdhocFilter'] == 'Y'"
                    value="Y"
                    type="checkbox"
                    (change)="changeEventAdHocFilter($event)"
                    name="checkbox"
                  />
                  <label>Adhoc Shipment Only&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                </div>
                <div class="ui checkbox">
                  <input
                    id="isDeleted"
                    type="checkbox"
                    [checked]="model['pfcIsDeleted'] == 'Y'"
                    (change)="changeIsDeleted($event)"
                  />
                  <label>Deleted</label>
                </div>

              </div>
              <div class="field" style="margin-top: 20px">
                <div class="ui checkbox">
                  <input
                    id="isCommissionable"
                    type="checkbox"
                    [checked]="model['pfcIsCommission'] == 'Y'"
                    (change)="changeIsCommissionable($event)"
                  />
                  <label>Commissionable</label>
                </div>
              </div>
            </div>
            <!-- Row 7 end -->


          </div>
          <!-- end of tab general -->

          <!-- Start of Tab Terminnal -->
          <div
            class="ui bottom attached tab segment"
            id="TerminalTab"
            data-tab="Terminal"
          >
            <a class="ui red ribbon label">Search Criteria</a>

            <!-- Row 1 start -->
            <div class="two fields" style="margin-bottom: 0px">
              <div class="field">
                <label>POR Terminal</label>
                <combo-plugin
                  #cbPORTerminal
                  [settings]="settingCbPORTerminal"
                  (change)="changePORTerminal($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>PODL Terminal</label>
                <combo-plugin
                  #cbPODLTerminal
                  [settings]="settingCbPODLTerminal"
                  (change)="changePODLTerminal($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 1 end-->

            <!-- Row 2 start -->
            <div class="two fields" style="margin-bottom: 0px">
              <div class="field">
                <label>POL Terminal</label>
                <combo-plugin
                  #cbPOLTerminal
                  [settings]="settingCbPOLTerminal"
                  (change)="changePOLTerminal($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>POT1 Terminal</label>
                <combo-plugin
                  #cbPOT1Terminal
                  [settings]="settingCbPOT1Terminal"
                  (change)="changePOT1Terminal($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 2 end-->

            <!-- Row 3 start -->
            <div class="two fields" style="margin-bottom: 0px">
              <div class="field">
                <label>POD Terminal</label>
                <combo-plugin
                  #cbPODTerminal
                  [settings]="settingCbPODTerminal"
                  (change)="changePODTerminal($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>FDEST Terminal</label>
                <combo-plugin
                  #cbFDESTTerminal
                  [settings]="settingCbFDESTTerminal"
                  (change)="changeFDESTTerminal($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 3 end-->
          </div>

          <a class="ui red ribbon label">Search Result</a>
          <div class="field">
            <grid-plugin
              #mainGrid
              [settings]="settingMainGrid"
              (gridEvent)="mainGridEvent($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
        </div>
        <!-- End of container -->
      </div>
      <!-- End of flag status form control -->

      <!--div [style.display]="modeForm=='freightMaintenance'?'block':'none'" style="width:100%"  -->
      <div
        [style.display]="
          modeForm == '' || modeForm == 'freightMaintenance' ? 'block' : 'none'
        "
      >
        <!-- maintenance start -->
        <div
          class="ui top attached tabular menu"
          style="background-color: beige"
        >
          <a class="item active" data-tab="maintenanceGeneralTab"
            >General &nbsp;
            <span style="color: red; text-transform: uppercase">{{
              appStatusLabel
            }}</span></a
          >
          <a class="item" data-tab="maintenanceTerminalTab">Terminal</a>
        </div>

        <!-- Maintenance General Tab Start -->
        <div
          class="ui bottom attached tab segment active"
          id="maintenanceGeneralTab"
          data-tab="maintenanceGeneralTab"
        >
          <!-- Tab General Start -->
          <div class="field">
            <div
              class="ui inverted dimmer active"
              style="z-index: 1; opacity: 0.1; padding-top: 15%;width:55% !important;"
              [class.hidden]="isModeArchive == false"
            >
              <i class="lockicon circular inverted large lock icon"></i>
            </div>
            <div class="fields">
              <!-- divide into 4 sections -->
              <div class="three wide field">
                <!-- 1st Pane from Left Start -->
                <div
                  class="required field"
                  [class.error]="
                    model['error-pfcFreightPartyId'] != null &&
                    model['error-pfcFreightPartyId'] != ''
                  "
                >
                  <label style="color: red">Freight Party
                  <a
                    [class.hidden]="fpAcctCode == ''"
                    style="font-size: 10px; font-weight: bold; color: red"
                    >{{ fpAcctCode }}</a
                  >
                  </label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceFreightParty
                    [settings]="settingCbMaintenanceFreightParty"
                    (change)="changeCbMaintenanceFreightParty($event)"
                    (keypress)="validateFieldSurcharge($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Booking Party</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceBookingParty
                    [settings]="settingCbMaintenanceBookingParty"
                    (change)="changeCbMaintenanceBookingParty($event)"
                    (keypress)="validateFieldSurcharge($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Shipper</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceShipper
                    [settings]="settingCbMaintenanceShipper"
                    (change)="changeCbMaintenanceShipper($event)"
                    (keypress)="CharOnly($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-pfcPlaceOfReceiptCode'] != null &&
                    model['error-pfcPlaceOfReceiptCode'] != ''
                  "
                >
                  <label style="color: red">Place Of Receipt</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePlaceOfReceipt
                    [settings]="settingCbMaintenancePlaceOfReceipt"
                    (change)="changeCbMaintenancePlaceOfReceipt($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-pfcLoadPortCode'] != null &&
                    model['error-pfcLoadPortCode'] != ''
                  "
                >
                  <label style="color: red">Port Of Load</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePortOfLoad
                    [settings]="settingCbMaintenancePortOfLoad"
                    (change)="changeCbMaintenancePortOfLoad($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-pfcDischargePortCode'] != null &&
                    model['error-pfcDischargePortCode'] != ''
                  "
                >
                  <label style="color: red">Port Of Discharge</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePortOfDischarge
                    [settings]="settingCbMaintenancePortOfDischarge"
                    (change)="changeCbMaintenancePortOfDischarge($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-pfcPlaceOfDeliveryCode'] != null &&
                    model['error-pfcPlaceOfDeliveryCode'] != ''
                  "
                >
                  <label style="color: red">Place Of Delivery</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePlaceOfDelivery
                    [settings]="settingCbMaintenancePlaceOfDelivery"
                    (change)="changeCbMaintenancePlaceOfDelivery($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Final Destination</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceFinalDestination
                    [settings]="settingCbMaintenanceFinalDestination"
                    (change)="changeCbMaintenanceFinalDestination($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Port Of Transshipment(1) </label>
                  <combo-plugin
                    #cbMaintenancePOT1
                    [settings]="settingCbMaintenancePOT1"
                    (change)="changeCbMaintenancePOT1($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field" style="margin-bottom: 5px">
                  <label>Port Of Transshipment(2) </label>
                  <combo-plugin
                    #cbMaintenancePOT2
                    [settings]="settingCbMaintenancePOT2"
                    (change)="changeCbMaintenancePOT2($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field" style="margin-bottom: 5px">
                  <label>Port Of Transshipment(3) </label>
                  <combo-plugin
                    #cbMaintenancePOT3
                    [settings]="settingCbMaintenancePOT3"
                    (change)="changeCbMaintenancePOT3($event)"
                    (keypress)="
                      formValidation.ModifiedPattern($event, '-,(.)/')
                    "
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <fieldset
                    style="
                      border: 1px solid lightgray;
                      margin-bottom: 5px;
                      margin-top: 10px;
                    "
                  >
                    <legend style="font-weight: bold">Misc</legend>
                    <div class="field" style="margin-bottom: 5px">
                      <div class="field">
                        <label>Commodity</label>
                        <combo-plugin
                          #cbMaintenanceCommodity
                          [settings]="settingCbMaintenanceCommodity"
                          (change)="changeCbMaintenanceCommodity($event)"
                          [disableCombo]="lock == true"
                        ></combo-plugin>
                      </div>
                    </div>
                    <div class="field" style="margin-bottom: 5px">
                      <label>Weight</label>
                      <input
                        type="number"
                        [(ngModel)]="model['pfcWeight']"
                        name="pfcWeight"
                        placeholder="Weight"
                        maxlength="50"
                        style="border: 1px solid gray; text-align: right"
                        disabled
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              <!-- 1st Pane from Left End -->

              <div class="four wide field">
                <!-- 2nd Pane from Left Start -->
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-pfcReceiptTypeCode'] != null &&
                          model['error-pfcReceiptTypeCode'] != ''
                        "
                      >
                        <label style="font-size: 13px">Receipt Type</label>
                        <combo-plugin
                          #cbMaintenanceReceiptType
                          [settings]="settingCbMaintenanceReceiptType"
                          (change)="changeCbMaintenanceReceiptType($event)"
                          (keypress)="CharOnly($event)"
                          [disableCombo]="lock == true"
                        ></combo-plugin>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-pfcDeliveryTypeCode'] != null &&
                          model['error-pfcDeliveryTypeCode'] != ''
                        "
                      >
                        <label style="font-size: 13px">Delivery Type</label>
                        <combo-plugin
                          #cbMaintenanceDeliveryType
                          [settings]="settingCbMaintenanceDeliveryType"
                          (change)="changeCbMaintenanceDeliveryType($event)"
                          (keypress)="CharOnly($event)"
                          [disableCombo]="lock == true"
                        ></combo-plugin>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold; color: red">
                      Container Ownership*
                    </legend>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="
                                model['pfcContainerOwnership'] == 'COC'
                              "
                              value="COC"
                              (change)="
                                changeEventPfcContainerOwnership($event)
                              "
                              name="pfcContainerOwnership"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>COC</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="
                                model['pfcContainerOwnership'] == 'COCTS'
                              "
                              value="COCTS"
                              (change)="
                                changeEventPfcContainerOwnership($event)
                              "
                              name="pfcContainerOwnership"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>COC T/S</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="
                                model['pfcContainerOwnership'] == 'SOC'
                              "
                              value="SOC"
                              (change)="
                                changeEventPfcContainerOwnership($event)
                              "
                              name="pfcContainerOwnership"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>SOC</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="
                                model['pfcContainerOwnership'] == 'SOCTS'
                              "
                              value="SOCTS"
                              (change)="
                                changeEventPfcContainerOwnership($event)
                              "
                              name="pfcContainerOwnership"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>SOC T/S</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="
                                model['pfcContainerOwnership'] == 'SLOT'
                              "
                              value="SLOT"
                              (change)="
                                changeEventPfcContainerOwnership($event)
                              "
                              name="pfcContainerOwnership"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>SLOT</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui checkbox">
                            <input
                              [checked]="model['pfcIsCommission'] == 'Y'"
                              type="checkbox"
                              (change)="changeEventPfcIsCommission($event)"
                            />
                            <label>Commissionable</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold; color: red">
                      Container Status*
                    </legend>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerStatus'] == 'EMPTY'"
                              value="EMPTY"
                              (change)="changeEventPfcContainerStatus($event)"
                              name="pfcContainerStatus"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>Empty</label>
                          </div>
                        </div>

                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerStatus'] == 'FULL'"
                              value="FULL"
                              (change)="changeEventPfcContainerStatus($event)"
                              name="pfcContainerStatus"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>Full</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <fieldset
                        style="
                          border: 1px solid lightgray;
                          background: lavender;
                        "
                        disabled
                      >
                        <legend style="font-weight: bold; font-size: 13px">
                          Weight Class
                        </legend>
                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcWeightClass'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcWeightClass($event)"
                                  name="pfcWeightClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label style="font-size: 12px">N/A</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcWeightClass'] == 'L'"
                                  value="L"
                                  (change)="changeEventPfcWeightClass($event)"
                                  name="pfcWeightClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label style="font-size: 12px"
                                  >Light (10000 KGS)</label
                                >
                              </div>
                            </div>
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcWeightClass'] == 'H'"
                                  value="H"
                                  (change)="changeEventPfcWeightClass($event)"
                                  name="pfcWeightClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label style="font-size: 12px"
                                  >Heavy (20000 KGS)</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcWeightClass'] == 'M'"
                                  value="M"
                                  (change)="changeEventPfcWeightClass($event)"
                                  name="pfcWeightClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label style="font-size: 12px"
                                  >Medium (15000 KGS)</label
                                >
                              </div>
                            </div>
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcWeightClass'] == 'X'"
                                  value="X"
                                  (change)="changeEventPfcWeightClass($event)"
                                  name="pfcWeightClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label style="font-size: 12px"
                                  >Extra Heavy (24000)</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold">Shipment Type</legend>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'N'"
                              value="N"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>N/A</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'D'"
                              value="D"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>RD-TRIP</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'I'"
                              value="I"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>IRE</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'S'"
                              value="S"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>SPECIAL</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'R'"
                              value="R"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>ROB</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcShipmentType'] == 'C'"
                              value="C"
                              (change)="changeEventPfcShipmentType($event)"
                              name="pfcShipmentType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>CRB</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="ui radio checkbox">
                        <input
                          [checked]="model['pfcShipmentType'] == 'T'"
                          value="T"
                          (change)="changeEventPfcShipmentType($event)"
                          name="pfcShipmentType"
                          type="radio"
                          tabindex="0"
                          class="hidden"
                        />
                        <label>THRU</label>
                      </div>
                    </div>
                  </fieldset>
                  <!--Shipment Type -->
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold" disabled="true">
                      Ad-HOC Shipment
                    </legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="field">
                            <label>Vessel</label>
                            <combo-plugin
                              #cbMaintenanceAdhocVessel
                              [settings]="settingCbMaintenanceAdhocVessel"
                              (change)="changeCbMaintenanceAdhocVessel($event)"
                              [disableCombo]="lock == true"
                              (keypress)="
                                  formValidation.ModifiedPattern($event, '-./ ')
                                "
                            ></combo-plugin>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="field">
                            <label>Voyage</label>
                            <combo-plugin
                              class="toDisable"
                              #cbMaintenanceAdhocVoyage
                              [settings]="settingCbMaintenanceAdhocVoyage"
                              (change)="changeCbMaintenanceAdhocVoyage($event)"
                              [disableCombo]="lock == true"
                              (keypress)="
                                  formValidation.ModifiedPattern($event, '-./ ')
                                "
                            ></combo-plugin>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="field">
                            <label>ETA</label>
                            <input
                              type="text"
                              [(ngModel)]="model['pfcLocalEta']"
                              name="pfcLocalEta"
                              placeholder="ETA"
                              maxlength="30"
                              style="border: 1px solid gray"
                              readonly
                              style="background-color: lightgray"
                            />
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="field">
                            <label>ETD</label>
                            <input
                              type="text"
                              [(ngModel)]="model['pfcLocalEtd']"
                              name="pfcLocalEtd"
                              placeholder="ETD"
                              maxlength="30"
                              style="border: 1px solid gray"
                              readonly
                              style="background-color: lightgray"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="field">
                            <label>ATA</label>
                            <input
                              type="text"
                              [(ngModel)]="model['pfcLocalAta']"
                              name="pfcLocalAta"
                              placeholder="ATA"
                              maxlength="30"
                              style="border: 1px solid gray"
                              readonly
                              style="background-color: lightgray"
                            />
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="field">
                            <label>ATD</label>
                            <input
                              type="text"
                              [(ngModel)]="model['pfcLocalAtd']"
                              name="pfcLocalAtd"
                              placeholder="ATD"
                              maxlength="30"
                              style="border: 1px solid gray"
                              readonly
                              style="background-color: lightgray"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--Ado-HOC Shipment -->
                </div>
              </div>
              <!-- 2nd Pane from Left End -->

              <div class="five wide field">
                <!-- 3rd Pane from Left Start -->
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-pfcEffectiveFrom'] != null &&
                          model['error-pfcEffectiveFrom'] != ''
                        "
                      >
                        <label style="color: red">Effective From</label>
                        <datetime-plugin
                          #dateMaintenanceEffectiveFrom
                          [settings]="settingDateMaintenanceEffectiveFrom"
                          [disableDatetime]="lockMaintenanceEffectiveFrom"
                        ></datetime-plugin>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-pfcEffectiveTo'] != null &&
                          model['error-pfcEffectiveTo'] != ''
                        "
                      >
                        <label style="color: red">Expiry On</label>
                        <datetime-plugin
                          #dateMaintenanceEffectiveTo
                          [settings]="settingDateMaintenanceEffectiveTo"
                          [disableDatetime]="lockMaintenanceEffectiveTo"
                        ></datetime-plugin>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold; color: red">
                      Container Type*
                    </legend>
                    <div class="field">
                      <!--[class.disabled]="lock==true"-->
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'STD'"
                              value="STD"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('STD')">Normal</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'TNK'"
                              value="TNK"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('TNK')">Tank</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <!--[class.disabled]="lock==true"-->
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'REF'"
                              value="REF"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('REF')">Reefer</label>
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'DRY'"
                              value="DRY"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('DRY')"
                              >Reefer Dry</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <!--[class.disabled]="lock==true"-->
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'OPT'"
                              value="OPT"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('OPT')"
                              >Open Top</label
                            >
                          </div>
                        </div>
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'UC'"
                              value="UC"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('UC')">UC</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>InG</label>
                          </div>
                        </div>
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>OutG</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <!--[class.disabled]="lock==true"-->
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'FLR'"
                              value="FLR"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('FLR')"
                              >Flat Rack</label
                            >
                          </div>
                        </div>
                        <div class="eight wide field">&nbsp;</div>
                      </div>
                    </div>

                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>InG</label>
                          </div>
                        </div>
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>OutG</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <!--[class.disabled]="lock==true"-->
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcContainerType'] == 'PLT'"
                              value="PLT"
                              (change)="changeEventPfcContainerType($event)"
                              name="pfcContainerType"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                              [disabled]="lockCT"
                            />
                            <label [class.bold]="map.has('PLT')"
                              >Platform</label
                            >
                          </div>
                        </div>
                        <div class="eight wide field">&nbsp;</div>
                      </div>
                    </div>

                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>InG</label>
                          </div>
                        </div>
                        <div class="four wide field">
                          <div class="ui test checkbox">
                            <input
                              name="containerType"
                              type="checkbox"
                              tabindex="0"
                              class="hidden"
                              disabled="true"
                            />
                            <label>OutG</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--Container Type-->
                </div>

                <div class="field">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold">DG Details</legend>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="four wide field">
                          <label style="font-size: 13px">UNNO</label>
                          <input
                            type="text"
                            maxlength="10"
                            disabled
                            style="background-color: lightgray"
                          />
                        </div>
                        <div class="four wide field">
                          <label style="font-size: 12px">Flash Pt.</label>
                          <input
                            type="text"
                            maxlength="10"
                            disabled
                            style="background-color: lightgray"
                          />
                        </div>
                        <div class="four wide field">
                          <label style="font-size: 13px">IMO</label>
                          <input
                            type="text"
                            maxlength="10"
                            disabled
                            style="background-color: lightgray"
                          />
                        </div>
                        <div class="four wide field">
                          <label style="font-size: 13px">DGDesk</label>
                          <input
                            type="text"
                            maxlength="10"
                            disabled
                            style="background-color: lightgray"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="field" style="margin-top: 10px">
                      <fieldset>
                        <legend style="font-weight: bold">DG Class</legend>
                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDgClass'] == 'NA'"
                                  value="NA"
                                  (change)="changeEventPfcDgClass($event)"
                                  name="pfcDgClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>N/A</label>
                              </div>
                            </div>
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDgClass'] == 'C3'"
                                  value="C3"
                                  (change)="changeEventPfcDgClass($event)"
                                  name="pfcDgClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Class 3</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDgClass'] == 'C2'"
                                  value="C2"
                                  (change)="changeEventPfcDgClass($event)"
                                  name="pfcDgClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Class 2</label>
                              </div>
                            </div>
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDgClass'] == 'C2F'"
                                  value="C2F"
                                  (change)="changeEventPfcDgClass($event)"
                                  name="pfcDgClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Class 2F</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="field" [class.disabled]="lock == true">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDgClass'] == 'C1'"
                                  value="C1"
                                  (change)="changeEventPfcDgClass($event)"
                                  name="pfcDgClass"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Class 1</label>
                              </div>
                            </div>
                            <div class="eight wide field">&nbsp;</div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </fieldset>
                  <!--DGClass -->
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold">IHC</legend>
                    <div class="field" [class.disabled]="lock == true">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="three wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcIhc'] == 'N'"
                              value="N"
                              (change)="changeEventPfcIhc($event)"
                              name="pfcIhc"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>N/A</label>
                          </div>
                        </div>
                        <div class="five wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcIhc'] == 'S'"
                              value="S"
                              (change)="changeEventPfcIhc($event)"
                              name="pfcIhc"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>Shipper</label>
                          </div>
                        </div>
                        <div class="five wide field">
                          <div class="ui radio checkbox">
                            <input
                              [checked]="model['pfcIhc'] == 'C'"
                              value="C"
                              (change)="changeEventPfcIhc($event)"
                              name="pfcIhc"
                              type="radio"
                              tabindex="0"
                              class="hidden"
                            />
                            <label>Consignee</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--IHC -->
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold" disabled="true">
                      Prospect Volume (Per MTH)
                    </legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="five wide field">
                          <label>D20</label>
                          <input
                            type="number"
                            [(ngModel)]="model['pfcProspectD20']"
                            name="pfcProspectD20"
                            oninput="this.value = Math.abs(this.value)"
                            onkeydown="return (event.keyCode!=13);"
                            placeholder="0"
                            maxlength="10"
                            style="text-align: right"
                            [disabled]="lock == true"
                          />
                        </div>
                        <div class="five wide field">
                          <label>D40</label>
                          <input
                            type="number"
                            [(ngModel)]="model['pfcProspectD40']"
                            name="pfcProspectD40"
                            oninput="this.value = Math.abs(this.value)"
                            onkeydown="return (event.keyCode!=13);"
                            placeholder="0"
                            maxlength="10"
                            style="text-align: right"
                            [disabled]="lock == true"
                          />
                        </div>
                        <div class="five wide field">
                          <label>D45</label>
                          <input
                            type="number"
                            [(ngModel)]="model['pfcProspectD45']"
                            name="pfcProspectD45"
                            oninput="this.value = Math.abs(this.value)"
                            onkeydown="return (event.keyCode!=13);"
                            placeholder="0"
                            maxlength="10"
                            style="text-align: right"
                            [disabled]="lock == true"
                          />
                        </div>
                      </div>

                      <div class="field">
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="five wide field">
                            <label>H20</label>
                            <input
                              type="number"
                              [(ngModel)]="model['pfcProspectH20']"
                              name="pfcProspectH20"
                              oninput="this.value = Math.abs(this.value)"
                              onkeydown="return (event.keyCode!=13);"
                              placeholder="0"
                              maxlength="10"
                              style="text-align: right"
                              [disabled]="lock == true"
                            />
                          </div>
                          <div class="five wide field">
                            <label>H40</label>
                            <input
                              type="number"
                              [(ngModel)]="model['pfcProspectH40']"
                              name="pfcProspectH40"
                              oninput="this.value = Math.abs(this.value)"
                              onkeydown="return (event.keyCode!=13);"
                              placeholder="0"
                              maxlength="10"
                              style="text-align: right"
                              [disabled]="lock == true"
                            />
                          </div>
                          <div class="five wide field">
                            <label>H45</label>
                            <input
                              type="number"
                              [(ngModel)]="model['pfcProspectH45']"
                              name="pfcProspectH45"
                              oninput="this.value = Math.abs(this.value)"
                              onkeydown="return (event.keyCode!=13);"
                              placeholder="0"
                              maxlength="10"
                              style="text-align: right"
                              [disabled]="lock == true"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <!--Prospect Booking -->
                </div>
              </div>
              <!-- 3rd Pane from Left End -->

              <div class="four wide field">
                <!-- 4th Pane from Left Start -->
                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label>Reference No</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcRecordId']"
                        name="pfcRecordId"
                        placeholder="Reference Number"
                        maxlength="30"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>

                    <div class="field"
                    [class.hidden]="revisionPfc == null || revisionPfc == ''"
                    >
                    <!-- [class.hidden]="model['pfcRecordId'] == null || model['pfcRecordId'] == ''" -->
                      <label>Rev</label>
                      <input
                        type="text"
                        [class.hidden]="revisionPfc == null || revisionPfc == ''"
                        [(ngModel)]="revisionPfc"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="Revision"
                        maxlength="20"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>

                    <div class="eight wide field">
                      <div
                        class="ui checkbox"
                        style="margin-top: 25px"
                        [style.opacity]="
                          !actions.includes('invalidCheckbox') ||
                          model['disableInvalid'] == 'true' ||
                          isInvalid == false
                            ? 0.5
                            : 1
                        "
                        [class.disabled]="
                          !actions.includes('invalidCheckbox') ||
                          model['disableInvalid'] == 'true' ||
                          isInvalid == false
                        "
                      >
                        <input
                          id="invalidcheckbox"
                          type="checkbox"
                          [checked]="model['pfcIsValid'] == 'N'"
                          (change)="changeInvalid($event)"
                        />
                        <label>Invalid</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field"
                  [class.hidden]="
                  model['pfcRecordId'] == null || model['pfcRecordId'] == ''
                ">
                  <div class="fields" style="margin-bottom: 0px">
                    <div
                      class="twelve wide field"
                      style="margin-bottom: 5px"
                    >
                      <label>PFC Archive</label>
                      <combo-plugin
                        #cbArchivePfc
                        [settings]="settingCbArchivePfc"
                        (change)="changeArchivePfc($event)"
                      ></combo-plugin>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label>User ID</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcUserCreated']"
                        name="pfcUserId"
                        placeholder="User ID"
                        maxlength="50"
                        style="border: 1px solid gray"
                        disabled
                      />
                    </div>
                    <div
                      class="eight wide required field"
                      [class.error]="
                        model['error-pfcAcctPic'] != null &&
                        model['error-pfcAcctPic'] != ''
                      "
                      style="margin-bottom: 5px"
                    >
                      <label>Acct PIC</label>
                      <combo-plugin
                        #cbAcctPic
                        [settings]="settingCbAcctPic"
                        (change)="changeAcctPic($event)"
                        [disableCombo]="lock"
                      ></combo-plugin>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label>Created By</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcUserCreated']"
                        name="pfcUserCreated"
                        placeholder="Created By"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                    <div class="eight wide field">
                      <label>Created Date</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcDateCreated']"
                        name="pfcDateCreated"
                        placeholder="Created Date"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label style="font-size: 11px">Last Modified By</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcUserModified']"
                        name="pfcUserModified"
                        placeholder="Last Modified By"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>

                    <div class="eight wide field">
                      <label style="font-size: 11px">Last Modified Date</label>
                      <input
                        type="text"
                        [(ngModel)]="model['pfcDateModified']"
                        name="pfcDateModified"
                        placeholder="Last Modified Date"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold">
                      POL
                    </legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <legend style="font-weight: bold">Detention</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDetentionPOL'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDetentionPOL($event)"
                                  name="pfcDetentionPOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="
                                    lock == true || lockDetentionPol == false
                                  "
                                />
                                <label>N/A</label>
                              </div>
                            </div>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDetentionPOL'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDetentionPOL($event)"
                                  name="pfcDetentionPOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div
                                class="ui radio checkbox"
                                style="margin-top: 1px"
                              >
                                <input
                                  [checked]="model['pfcDetentionPOL'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDetentionPOL($event)"
                                  name="pfcDetentionPOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                [(ngModel)]="model['pfcDetentionRequestPOL']"
                                name="pfcDetentionRequestPOL"
                                placeholder="0"
                                maxlength="20"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="
                                  lock == true ||
                                  model['pfcDetentionPOL'] != 'R'
                                "
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <!--width: 56%;-->
                            <legend style="font-weight: bold">Demurrage</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurragePOL'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDemurragePOL($event)"
                                  name="pfcDemurragePOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="
                                    lock == true || lockDemurragePol == false
                                  "
                                />
                                <label>N/A</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurragePOL'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDemurragePOL($event)"
                                  name="pfcDemurragePOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurragePOL'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDemurragePOL($event)"
                                  name="pfcDemurragePOL"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                [(ngModel)]="model['pfcDemurrageRequestPOL']"
                                name="pfcDemurrageRequestPOL"
                                placeholder="0"
                                maxlength="20"
                                [disabled]="
                                  lock == true ||
                                  model['pfcDemurragePOL'] != 'R'
                                "
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray">
                    <legend style="font-weight: bold">POD</legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <legend style="font-weight: bold">Detention</legend>
                            <div class="field" [class.disabled]="lock == true">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDetention'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDetention($event)"
                                  name="pfcDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  disabled="true"
                                />
                                <label>N/A</label>
                              </div>
                            </div>
                            <div class="field" [class.disabled]="lock == true">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDetention'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDetention($event)"
                                  name="pfcDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field" [class.disabled]="lock == true">
                              <div
                                class="ui radio checkbox"
                                style="margin-top: 1px"
                              >
                                <input
                                  [checked]="model['pfcDetention'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDetention($event)"
                                  name="pfcDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field" [class.disabled]="lock == true">
                              <input
                                type="number"
                                [(ngModel)]="model['pfcDetentionRequest']"
                                name="pfcDetentionRequest"
                                placeholder="0"
                                maxlength="20"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="model['pfcDetention'] != 'R'"
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <!--width: 56%;-->
                            <legend style="font-weight: bold">Demurrage</legend>
                            <div class="field" [class.disabled]="lock == true">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurrage'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="pfcDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  disabled="true"
                                />
                                <label>N/A</label>
                              </div>
                            </div>

                            <div class="field" [class.disabled]="lock == true">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurrage'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="pfcDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field" [class.disabled]="lock == true">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['pfcDemurrage'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="pfcDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field" [class.disabled]="lock == true">
                              <input
                                type="number"
                                [(ngModel)]="model['pfcDemurrageRequest']"
                                name="pfcDemurrageRequest"
                                placeholder="0"
                                maxlength="20"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="model['pfcDemurrage'] != 'R'"
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <!-- 4th Pane from Left End -->
            </div>
          </div>
        </div>
        <!-- Tab General end -->

        <!-- Start of Tab Terminal -->
        <div
          class="ui bottom attached tab segment"
          id="maintenanceTerminalTab"
          data-tab="maintenanceTerminalTab"
        >
          <!-- Row 1 start -->
          <div class="two fields">
            <div class="field">
              <label>POR Terminal</label>
              <combo-plugin
                #cbMaintenancePORTerminal
                [settings]="settingCbMaintenancePORTerminal"
                (change)="changeCbMaintenancePORTerminal($event)"
                [disableCombo]="lockPORTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>PODL Terminal</label>
              <combo-plugin
                #cbMaintenancePODLTerminal
                [settings]="settingCbMaintenancePODLTerminal"
                (change)="changeCbMaintenancePODLTerminal($event)"
                [disableCombo]="lockPODLTerminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 1 end-->

          <!-- Row 2 start -->
          <div class="two fields">
            <div class="field">
              <label>POL Terminal</label>
              <combo-plugin
                #cbMaintenancePOLTerminal
                [settings]="settingCbMaintenancePOLTerminal"
                (change)="changeCbMaintenancePOLTerminal($event)"
                [disableCombo]="lockPOLTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>POT1 Terminal</label>
              <combo-plugin
                #cbMaintenancePOT1Terminal
                [settings]="settingCbMaintenancePOT1Terminal"
                (change)="changeCbMaintenancePOT1Terminal($event)"
                [disableCombo]="lockPOT1Terminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 2 end-->

          <!-- Row 3 start -->
          <div class="two fields">
            <div class="field">
              <label>POD Terminal</label>
              <combo-plugin
                #cbMaintenancePODTerminal
                [settings]="settingCbMaintenancePODTerminal"
                (change)="changeCbMaintenancePODTerminal($event)"
                [disableCombo]="lockPODTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>FDEST Terminal</label>
              <combo-plugin
                #cbMaintenanceFDESTTerminal
                [settings]="settingCbMaintenanceFDESTTerminal"
                (change)="changeCbMaintenanceFDESTTerminal($event)"
                [disableCombo]="lockFDESTTerminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 3 end-->
        </div>
        <!-- End of Maintenance terminal tab -->

        <div class="ui styled accordion field" style="width: 100%">
          <!-- Grid Charge Detail Start -->
          <div class="title"><i class="icon dropdown"></i>Details</div>

          <div class="three fields" style="margin-left: 10px">
            <div class="field">
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailNew()"
                [class.disabled]="isLockedBottomButton == true"
              >
                New
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailUpdate()"
                [class.disabled]="isLockedBottomButton == true"
              >
                Update
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailRemove()"
                [class.disabled]="isLockedBottomButton == true"
              >
                Remove
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailCopy()"
                [class.disabled]="isLockedBottomButton == true"
              >
                Copy
              </button>
            </div>
            <div class="field"></div>
            <div class="field"></div>
          </div>
          <div
            class="ui top attached tabular menu"
            style="background-color: beige"
          >
            <a
              class="item"
              [class.active]="tabStatus == 'tab1'"
              data-tab="Prepaid"
              (click)="initTab1()"
              >Prepaid</a
            >
            <a
              class="item"
              [class.active]="tabStatus == 'tab2'"
              data-tab="Collect"
              (click)="initTab2()"
              >Collect</a
            >
          </div>

          <div
            class="ui bottom attached tab segment"
            [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
            id="PrepaidTab"
            data-tab="Prepaid"
          >
            <div class="field">
              <!--<grid-plugin id='gridPrepaid'  #gridPrepaid [settings]="settingGridPrepaid"  (gridEvent)="gridEventPrepaid($event)" (infoGrid)="infoGridPrepaid($event)"  ></grid-plugin>-->
              <ag-grid-angular
                #gridPrepaid2
                style="width: 100%; height: 400px"
                [getRowStyle]="getRowStyle"
                class="ag-theme-balham"
                id="gridPrepaid2"
                [enableSorting]="false"
                [enableFilter]="true"
                [enableColResize]="true"
                [rowData]="rowDataPrepaid"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="true"
                [columnDefs]="columnDefsPrepaid"
                [pinnedBottomRowData]="pinnedBottomRowDataPrepaid"
                [animateRows]="true"
                [enableCellChangeFlash]="true"
                (rowDoubleClicked)="rowDoubleClick($event)"
                (gridReady)="onGridReadyPrepaid($event)"
                (selectionChanged)="onSelectionChanged($event)"
              >
              </ag-grid-angular>
            </div>
            <!--field-->

            <div class="field">
              <button
                class="ui button"
                style="font-size: 9px"
                [class.hidden]="isHidePrevApproval == true"
                (click)="onPrevApprovalUpdateLevel()"
              >
                Previous Approval
              </button>
              <button
                class="ui button"
                style="font-size: 9px"
                [class.hidden]="isHideBack == true"
                (click)="onBack()"
              >
                Back
              </button>
            </div>
            <!-- Previous Approval -->
          </div>
          <!-- Prepaid-->

          <div
            class="ui bottom attached tab segment"
            [class.active]="tabStatus2 == 'active' || tabStatus == 'tab2'"
            id="CollectTab"
            data-tab="Collect"
          >
            <div class="field">
              <!--<grid-plugin id='gridCollect' #gridCollect [settings]="settingGridCollect"  (gridEvent)="gridEventCollect($event)" (infoGrid)="infoGridCollect($event)"></grid-plugin>-->
              <ag-grid-angular
                #gridCollect2
                style="width: 100%; height: 400px"
                [getRowStyle]="getRowStyle"
                class="ag-theme-balham"
                id="gridCollect2"
                [enableSorting]="false"
                [enableFilter]="true"
                [enableColResize]="true"
                [rowData]="rowDataCollect"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="true"
                [columnDefs]="columnDefsCollect"
                [pinnedBottomRowData]="pinnedBottomRowDataCollect"
                [animateRows]="true"
                [enableCellChangeFlash]="true"
                (rowDoubleClicked)="rowDoubleClick($event)"
                (gridReady)="onGridReadyCollect($event)"
                (selectionChanged)="onSelectionChanged($event)"
              >
              </ag-grid-angular>
            </div>
            <!--field-->
            <div class="field">
              <button
                class="ui button"
                style="font-size: 9px"
                [class.hidden]="isHidePrevApproval == true"
                (click)="onPrevApprovalUpdateLevel()"
              >
                Previous Approval
              </button>
              <button
                class="ui button"
                style="font-size: 9px"
                [class.hidden]="isHideBack == true"
                (click)="onBack()"
              >
                Back
              </button>
            </div>
            <!-- Previous Approval -->
          </div>
          <div class="field">
            <div class="two wide fields">
              <div class="field">
                <label>Remarks Principal</label>
                <textarea-plugin
                  #txtRemarksPrincipal
                  [settings]="settingRemarksPrincipal"
                  [disabled]="lockRemarksPrincipal"
                ></textarea-plugin>
              </div>
              <div class="field">
                <label>Remarks Agency</label>
                <textarea-plugin
                  #txtRemarksAgency
                  [settings]="settingRemarksAgency"
                  [disabled]="lockRemarksAgency"
                ></textarea-plugin>
              </div>
            </div>
          </div>
        </div>
        <!-- Grid Charge Detail Stop -->
      </div>
      <!-- End of Freight Maintenance Form Control -->
    </form>
  </div>
</div>

<div id="NCTForm" class="ui screen modal" style="width: 25%; padding: 0.5%">
  <!--Start Modal Popup Surcharge -->
  <form class="ui form" style="width: 100%">
    <fieldset style="border: 1px solid lightgray; margin-left: 0.5px">
      <!--margin-right:15px;-->
      <legend style="font-weight: bold; color: dodgerblue; margin-bottom: 0.1%">
        Copy Rate From Container Type
      </legend>
      <div class="field" style="margin-bottom: 2%">
        <label style="font-size: 11px"
          >Which cntr type do you want to copy from?</label
        >

        <combo-plugin
          #nctform_ctrType
          [settings]="setting_nctFormForContainerType"
          (change)="value_nctFormForContainerType($event)"
          [disableCombo]="lock"
        ></combo-plugin>
      </div>
      <div class="two fields">
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="onNCT_Save()"
          >
            Save
          </button>
        </div>
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="onNCT_Cancel($event)"
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- END, For NCT Form -->

<!-- Form Previous Approval -->
<div
  id="previousApprovalForm"
  style="display: none; font-family: Lucida Console, monospace"
>
  <div class="field">
    <button
      class="ui button"
      style="font-size: 12px"
      (click)="onPrevApproval_Close()"
    >
      Close
    </button>
  </div>
  <div class="field" style="margin-top: 10px">
    <grid-plugin
      id="gridPrevApproval"
      #gridPrevApproval
      [settings]="settingGridPrevApproval"
      (gridEvent)="gridEventPrevApproval($event)"
      (infoGrid)="infoGridPrevApproval($event)"
    >
    </grid-plugin>
  </div>
  <!--field-->
</div>

<!-- charge management section -->
<div
  id="freightNewChargeForm"
  style="display: none; font-family: Lucida Console, monospace"
>
  <!-- class="ui fullscreen modal"  style="width:90%;padding:1%;" -->

  <!--Error Message Block-->
  <div class="ui error message" [class.hidden]="isErrorDetail == false">
    <ul>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailChargeCode'] == null ||
          modelDetail['error-pfcDetailChargeCode'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailChargeCode"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailCurrency'] == null ||
          modelDetail['error-pfcDetailCurrency'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailCurrency"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailPC'] == null ||
          modelDetail['error-pfcDetailPC'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailPC"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailPlaceOfPayment'] == null ||
          modelDetail['error-pfcDetailPlaceOfPayment'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailPlaceOfPayment"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailPayer'] == null ||
          modelDetail['error-pfcDetailPayer'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailPayer"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-chargesValueValidator'] == null ||
          modelDetail['error-chargesValueValidator'] == ''
        "
      >
        {{ modelDetail["error-chargesValueValidator"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDuplicateChargeCode'] == null ||
          modelDetail['error-pfcDuplicateChargeCode'] == ''
        "
      >
        {{ modelDetail["error-pfcDuplicateChargeCode"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-mainChargesValidator'] == null ||
          modelDetail['error-mainChargesValidator'] == ''
        "
      >
        {{ modelDetail["error-mainChargesValidator"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-pfcDetailFAIGroup'] == null ||
          modelDetail['error-pfcDetailFAIGroup'] == ''
        "
      >
        {{ modelDetail["error-pfcDetailFAIGroup"] }}
      </li>
    </ul>
  </div>
  <!--End of Error Message Block-->

  <form class="ui form" style="width: 100%">
    <!-- form start -->
    <h4
      class="ui dividing header"
      style="width: 100%; text-align: left; margin-top: 10px"
    ></h4>
    <div class="field">
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_OK()"
        [class.disabled]="isLockedBottomButton == true"
      >
        OK
      </button>
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_Reset()"
        [class.disabled]="isLockedBottomButton == true"
      >
        Reset
      </button>
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_Close()"
      >
        Close
      </button>
    </div>

    <fieldset
      style="width: 100%; border: 1px solid lightgray; margin-bottom: 1px"
    >
      <div class="nine wide fields" style="margin-left: 0.5px">
        <div class="field" style="margin-bottom: 3px; width: 10%">
          <label>Seq No.</label>
          <input
            type="number"
            [(ngModel)]="modelDetail['pfcDetailSeqNo']"
            name="pfcDetailSeqNo"
            placeholder="0"
            maxlength="3"
            readonly
          />
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-pfcDetailChargeCode'] != null &&
            modelDetail['error-pfcDetailChargeCode'] != ''
          "
          style="width: 35%"
        >
          <label style="color: red">Charge</label>
          <combo-plugin
            #cbMaintenanceDetailCharge
            [settings]="settingCbMaintenanceDetailCharge"
            (change)="changeCbMaintenanceDetailCharge($event)"
            [disableCombo]="lockFreightEntityScreen === true"
            (keypress)="validateFieldSurcharge($event)"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-pfcDetailCurrency'] != null &&
            modelDetail['error-pfcDetailCurrency'] != ''
          "
          style="width: 15%"
        >
          <label style="color: red">Currency</label>
          <combo-plugin
            #cbMaintenanceDetailCurrency
            [settings]="settingCbMaintenanceDetailCurrency"
            (change)="changeCbMaintenanceDetailCurrency($event)"
            [disableCombo]="lockFreightEntityScreen === true"
            (keypress)="formValidation.AlphabetsOnlyWOSpace($event)"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-pfcDetailPC'] != null &&
            modelDetail['error-pfcDetailPC'] != ''
          "
          style="width: 9%"
        >
          <label style="color: red">P/C</label>
          <combo-plugin
            #cbMaintenanceDetailPC
            [settings]="settingCbMaintenanceDetailPC"
            (change)="changeCbMaintenanceDetailPC($event)"
            [disableCombo]="lockFreightEntityScreen === true"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-pfcDetailPlaceOfPayment'] != null &&
            modelDetail['error-pfcDetailPlaceOfPayment'] != ''
          "
          style="width: 35%"
        >
          <label style="color: red">Place of Payment</label>
          <combo-plugin
            #cbMaintenanceDetailPlaceOfPayment
            [settings]="settingCbMaintenanceDetailPlaceOfPayment"
            (change)="changeCbMaintenancePlaceOfPayment($event)"
            [disableCombo]="lockFreightEntityScreen === true"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-pfcDetailPayer'] != null &&
            modelDetail['error-pfcDetailPayer'] != ''
          "
          style="width: 35%"
        >
          <label style="color: red">Payer
            <a
            [class.hidden]="fpPayer == ''"
            style="font-size: 10px; font-weight: bold; color: red"
            >{{ fpPayer }}</a
          >

          </label>
          <combo-plugin
            #cbMaintenanceDetailPayer
            [settings]="settingCbMaintenanceDetailPayer"
            (change)="changeCbMaintenanceDetailPayer($event)"
            [disableCombo]="lockFreightEntityScreen === true"
            (keypress)="validateFieldSurcharge($event)"
          ></combo-plugin>
        </div>
      </div>
      <!--End for nine wide fields-->

      <div class="two wide fields">
        <div class="field">
          <div class="nine wide fields" style="margin-left: 2px">
            <!--div class="six wide fields"-->
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D20</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailD20']"
                name="pfcDetailD20"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H20</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailH20']"
                name="pfcDetailH20"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D40</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailD40']"
                name="pfcDetailD40"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H40</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailH40']"
                name="pfcDetailH40"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D45</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailD45']"
                name="pfcDetailD45"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H45</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailH45']"
                name="pfcDetailH45"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                style="text-align: right"
                [disabled]="lockFreightEntityScreen === true"
              />
            </div>
            <!--/div-->
          </div>
          <div class="nine wide fields" style="margin-left: 2px">
            <!--div class="six wide fields"-->
            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>M3</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailM3']"
                name="pfcDetailM3"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>KT</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailKT']"
                name="pfcDetailKT"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>RT</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailRT']"
                name="pfcDetailRT"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 19%">
              <label>Min Charge</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailMinCharge']"
                name="pfcDetailMinCharge"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 20%">
              <label>Kill Slot</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['pfcDetailKillSlot']"
                name="pfcDetailKillSlot"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>
            <!--/div-->
          </div>
          <div
            class="three wide fields"
            style="margin-bottom: 3px; margin-left: 7.2px"
          >
            <div class="field">
              <div class="ui test checkbox">
                <input
                  type="checkbox"
                  name="pfcDetailFAIGroup"
                  tabindex="0"
                  [checked]="modelDetail['pfcDetailFAIGroup'] == 'Y'"
                  (change)="changeEventPfcDetailFAIGroup($event)"
                  [disabled]="lockFreightEntityScreen === true"
                />
                <label style="margin-left: 3px; font-weight: bold"
                  >FAI Group</label
                >
              </div>
            </div>
            <div class="field">
              <div class="ui test checkbox">
                <input
                  [checked]="modelDetail['pfcDetailCopyTab'] == 'Y'"
                  value="Y"
                  type="checkbox"
                  name="pfcDetailCopyTab"
                  tabindex="0"
                  (change)="changeEventPfcDetailCopyTab($event)"
                  [disabled]="lockFreightEntityScreen === true"
                />
                <label style="margin-left: 3px; font-weight: bold"
                  >Copy Freight to
                  {{
                    currentActiveTab == "prepaid" ? "Collect" : "Prepaid"
                  }}</label
                >
              </div>
            </div>
          </div>
          <div class="field">&nbsp;</div>
        </div>
        <div class="field">
          <div class="two wide fields">
            <div
              class="field"
              [class.disabled]="lockOOGOOG == true"
              style="width: 70%"
            >
              <fieldset
                id="oogDetailCMGroup"
                style="border: 1px solid lightgray; margin-bottom: 1px"
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  OOG Details(CM)
                </legend>
                <div class="field" style="width: 36%; margin-bottom: 3px">
                  <label>OH</label>
                  <input
                    type="number"
                    min="0"
                    [disabled]="
                      lockOOGOOG == true || lockFreightEntityScreen === true
                    "
                    [(ngModel)]="modelDetail['pfcDetailOogOH']"
                    name="pfcDetailOogOH"
                    onkeydown="return (event.keyCode!=13);"
                    placeholder="0"
                    maxlength="3"
                    style="text-align: right"
                  />
                </div>

                <div
                  class="two fields"
                  [class.disabled]="
                    lockOOGOOG == true ||
                    this.modelDetail.pfcDetailContainerType == 'OPT'
                  "
                >
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OWL</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.pfcDetailContainerType == 'OPT' ||
                        lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogOWL']"
                      name="pfcDetailOogOWL"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OWR</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.pfcDetailContainerType == 'OPT' ||
                        lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogOWR']"
                      name="pfcDetailOogOWR"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <div
                  class="two fields"
                  [class.disabled]="
                    lockOOGOOG == true ||
                    this.modelDetail.pfcDetailContainerType == 'OPT'
                  "
                >
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OLF</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.pfcDetailContainerType == 'OPT' ||
                        lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogOLF']"
                      name="pfcDetailOogOLF"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OLB</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.pfcDetailContainerType == 'OPT' ||
                        lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogOLB']"
                      name="pfcDetailOogOLB"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="field" [class.disabled]="lockOOGDoorOpen == true">
              <fieldset
                id="doorOpenGroup"
                style="
                  width: 100%;
                  border: 1px solid lightgray;
                  margin-bottom: 1px;
                "
                [disabled]="lockFreightEntityScreen === true"
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  Door Open
                </legend>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'N'"
                        value="N"
                        [disabled]="
                          lockOOGDoorOpen == true ||
                          lockFreightEntityScreen === true
                        "
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="pfcDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>N/A</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'D'"
                        value="D"
                        [disabled]="
                          lockOOGDoorOpen == true ||
                          lockFreightEntityScreen === true
                        "
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="pfcDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Detached</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'B'"
                        value="B"
                        [disabled]="
                          lockOOGDoorOpen == true ||
                          lockFreightEntityScreen === true
                        "
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="pfcDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Tied Both</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'L'"
                        value="L"
                        [disabled]="
                          lockOOGDoorOpen == true ||
                          lockFreightEntityScreen === true
                        "
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="pfcDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Tied Left</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'R'"
                        value="R"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="pfcDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                        [disabled]="
                          lockOOGDoorOpen == true ||
                          lockFreightEntityScreen === true
                        "
                      />
                      <label>Tied Right</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <!--Door Open-->

            <div class="field" [class.disabled]="lockOOGUC == true">
              <fieldset
                id="ucCMGroup"
                style="
                  width: 80%;
                  border: 1px solid lightgray;
                  margin-bottom: 1px;
                "
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  UC(CM)
                </legend>
                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>H</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGUC == true || lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogUcH']"
                      name="pfcDetailOogUcH"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for first row //two fields width:50%;-->
                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>L</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGUC == true || lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogUcL']"
                      name="pfcDetailOogUcL"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for second row //two fields -->

                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>W</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGUC == true || lockFreightEntityScreen === true
                      "
                      [(ngModel)]="modelDetail['pfcDetailOogUcW']"
                      name="pfcDetailOogUcW"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength="3"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for 3rd row //two fields -->
              </fieldset>
            </div>
            <!--UCM-->
          </div>
        </div>
      </div>
    </fieldset>
  </form>
  <!-- form end -->
</div>
<!-- end of charge management section -->

<!-- START, For Delete Form -->
<div id="DeleteForm" style="width: 25%; padding: 0.5%; display: none">
  <div class="ui error message" [class.hidden]="isErrorDeleted == false">
    <ul>
      <li
        [class.hidden]="
          modelDelete['error-pfcReasonDeleted'] == null ||
          modelDelete['error-pfcReasonDeleted'] == ''
        "
      >
        {{ modelDelete["error-pfcReasonDeleted"] }}
      </li>
    </ul>
  </div>
  <form class="ui form" style="width: 100%">
    <fieldset style="border: 1px solid lightgray; margin-left: 0.5px">
      <!--margin-right:15px;-->
      <div class="field" style="margin-bottom: 2%">
        <label style="font-size: 11px">Please entry the reason of delete</label>
        <textarea
          id="reasonDelete"
          name="reason"
          style="font-size: 12px"
          [(ngModel)]="modelDelete['pfcReasonDeleted']"
          placeholder="reason delete"
        ></textarea>
      </div>
      <div class="two fields">
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="this.deleteProcess()"
          >
            Process
          </button>
        </div>
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="
              this.closeDialog('DeleteForm');
              modelDelete['pfcReasonDeleted'] = '';
              this.isErrorDeleted = false
            "
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- END, For Delete Form -->

<div
  id="messageDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="msg"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onMessageDialogOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="historyDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td>
            <div [innerHTML]="msg"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onHistoryDialogOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="confirmCopyDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="msg"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onConfirmCopyYes($event)">
        Yes
      </button>
      <button class="negative ui button" (click)="onConfirmCopyNo($event)">
        No
      </button>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
