<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <div [style.display]="modeForm?'none':'block'">
      <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Customer</h4>
      <!-- Begin of Grid -->
      <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm?'block':'none'">
      <div class="ui segment" style="padding-left:1%;padding-right:20%;">
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id='container'>

          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == ''?'block':'none'" >

            <h4 class="ui dividing header">Master Customer</h4>

            <div class="three fields">
              <div class="field">
                <label><br/></label>
                <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                  <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                  <label>Valid</label>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">

              <div class="one field" style="width:100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom:5px;">General</a>

                  <div class="ten wide fields">
                    <div class="one field" style="width:100%">
                      <div class="ten wide fields">
                        <div class="required field" style="width:100%" [class.error]="model['error-customerName'] != null && model['error-customerName'] != ''">
                          <label>Name</label>
                          <input type="text"
                                 [(ngModel)]="model['customerName']" (ngModelChange)="model['error-customerName']=''" name="customerName" placeholder="Customer Name" maxlength="50"
                                 [disabled]="lock == true" (keypress)="none($event)">
                          <div class = "ui basic red pointing prompt label transition error-message-hidden"
                               [class.error-message-visible]="model['error-customerName'] != null && model['error-customerName'] != ''">
                            {{model['error-customerName']}}
                          </div>
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div class="required five field" style="width:100%" [class.error]="model['error-countryName'] != null && model['error-countryName'] != ''">
                          <label>Country</label>
                          <combo-plugin #cbCountry [settings]="settingCountry" [disableCombo]="lock" (change)="changeEventCountry($event)" style="width:100%;"></combo-plugin>
                          <div class = "ui basic red pointing prompt label transition error-message-hidden"
                               [class.error-message-visible]="model['error-countryName'] != null && model['error-countryName'] != ''">
                            {{model['error-countryName']}}
                          </div>
                        </div>
                        <div class="five field" style="width:100%">
                          <label>Company Registration Number</label>
                          <input type="text" [(ngModel)]="model['companyRegNo']" name="companyRegNo"  placeholder="Company Registration Number" [disabled]="lock == true" maxlength="15"
                          (keypress)="none($event)">
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div class="field" style="width:100%">
                          <label>Remarks</label>
                          <textarea style="margin-top:5px;" [(ngModel)]="model['remarks']" name="remarks" placeholder="Input your remarks..." [disabled]="lock == true" ></textarea>
                        </div>
                      </div>

                    </div>

                    <div class="one field" style="width:100%">
                      <div class="ui raised segment" style="margin-left:5px;">
                        <a class="ui red ribbon label" style="margin-bottom:5px;">Customer Type</a>

                        <div class="two fields">
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isShipper']" name="isShipper" tabindex="0" class="hidden">
                              <label>Shipper</label>
                            </div>
                          </div>
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isAgency']" name="isAgency" tabindex="0" class="hidden">
                              <label>Agency</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isConsignee']" name="isConsigneee" tabindex="0" class="hidden">
                              <label>Consignee</label>
                            </div>
                          </div>
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isBookingParty']" name="isBookingParty" tabindex="0" class="hidden">
                              <label>Booking Party</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isNotifyParty']" name="isNotifyParty" tabindex="0" class="hidden">
                              <label>Notify Party</label>
                            </div>
                          </div>
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isHaulier']" name="isHaulier" tabindex="0" class="hidden">
                              <label>Haulier</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isPayer']" name="isPayer" tabindex="0" class="hidden">
                              <label>Payer</label>
                            </div>
                          </div>
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isNvocc']" name="isNvocc" tabindex="0" class="hidden">
                              <label>NVOCC</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width:100%">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                              <input type="checkbox" [(ngModel)]="model['isSupplier']" name="isSupplier" tabindex="0" class="hidden">
                              <label>Supplier</label>
                            </div>
                          </div>
                          <div class="field" style="width:100%">
                           &nbsp;
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width:100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom:5px;">Container Ownership</a>

                  <div class="three fields">
                    <div class="field" style="width:100%">
                      <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                        <input type="checkbox" [(ngModel)]="model['isCoc']" (ngModelChange)="changeEventOwnership('COC-'+$event)" name="isCoc" tabindex="0" class="hidden">
                        <label>COC</label>
                      </div>
                    </div>
                    <div class="field" style="width:100%">
                      <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                        <input type="checkbox" [(ngModel)]="model['isSoc']" name="isSoc" (ngModelChange)="changeEventOwnership('SOC-'+$event)" tabindex="0" class="hidden">
                        <label>SOC</label>
                      </div>
                    </div>
                    <div class="field" style="width:100%">
                      <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                        <input type="checkbox" [(ngModel)]="model['isSlot']" name="isSlot" (ngModelChange)="changeEventOwnership('SLOT-'+$event)" tabindex="0" class="hidden">
                        <label>SLOT</label>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class="field" style="width:100%" [class.error]="model['error-cocOperatorCode'] != null && model['error-cocOperatorCode'] != ''">
                      <label>Operator Code</label>
                      <label style="font-size: 9px; color: navy; font-weight: 200" >** 2 to 4 characters separated by Comma. Eg: AA,BBBB,...</label>
                      <input type="text" [(ngModel)]="model['cocOperatorCode']" name="cocOperatorCode"  placeholder="Operator Code" [disabled]="isCOC == false" maxlength="50"
                      (keypress)="none($event)" >
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-cocOperatorCode'] != null && model['error-cocOperatorCode'] != ''">
                           <span [innerHTML]="model['error-cocOperatorCode']"></span>
                      </div>
                    </div>
                    <div class="field" style="width:100%" [class.error]="model['error-socOperatorCode'] != null && model['error-socOperatorCode'] != ''">
                      <label>Operator Code</label>
                      <label style="font-size: 9px; color: navy; font-weight: 200" >** 2 to 4 characters separated by Comma. Eg: AA,BBBB,...</label>
                      <input type="text" [(ngModel)]="model['socOperatorCode']" name="socOperatorCode"  placeholder="Operator Code" [disabled]="isSOC == false" maxlength="50"
                      (keypress)="none($event)">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-socOperatorCode'] != null && model['error-socOperatorCode'] != ''">
                        <span [innerHTML]="model['error-socOperatorCode']"></span>
                      </div>
                    </div>
                    <div class="field" style="width:100%" [class.error]="model['error-slotOperatorCode'] != null && model['error-slotOperatorCode'] != ''">
                      <label>Operator Code</label>
                      <label style="font-size: 9px; color: navy; font-weight: 200" >** 2 to 4 characters separated by Comma. Eg: AA,BBBB,...</label>
                      <input type="text" [(ngModel)]="model['slotOperatorCode']" name="slotOperatorCode"  placeholder="Operator Code" [disabled]="isSLOT == false" maxlength="50"
                      (keypress)="none($event)">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-slotOperatorCode'] != null && model['error-slotOperatorCode'] != ''">
                        <span [innerHTML]="model['error-slotOperatorCode']"></span>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class="field" style="width:100%">
                      <label>Account Code</label>
                      <input type="text" [(ngModel)]="model['cocAccountCode']" name="cocAccountCode"  placeholder="Account Code" [disabled]="isCOC == false" maxlength="10"
                      (keypress)="none($event)">
                    </div>
                    <div class="field" style="width:100%">
                      <label>Account Code</label>
                      <input type="text" [(ngModel)]="model['socAccountCode']" name="socAccountCode"  placeholder="Account Code" [disabled]="isSOC == false" maxlength="10"
                      (keypress)="none($event)">
                    </div>
                    <div class="field" style="width:100%">
                      <label>Account Code</label>
                      <input type="text" [(ngModel)]="model['slotAccountCode']" name="slotAccountCode"  placeholder="Account Code" [disabled]="isSLOT == false" maxlength="10"
                      (keypress)="none($event)">
                    </div>
                  </div>

                  <div class="three fields">
                    <div class="field" style="width:100%" [class.error]="model['error-cocCreditDays'] != null && model['error-cocCreditDays'] != ''">
                      <label>Credit Days</label>
                      <input type="number" [(ngModel)]="model['cocCreditDays']" name="cocCreditDays"  placeholder="Credit Days" [disabled]="isCOC == false" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="none($event)">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-cocCreditDays'] != null && model['error-cocCreditDays'] != ''">
                        {{model['error-cocCreditDays']}}
                      </div>
                    </div>
                    <div class="field" style="width:100%" [class.error]="model['error-socCreditDays'] != null && model['error-socCreditDays'] != ''">
                      <label>Credit Days</label>
                      <input type="number" [(ngModel)]="model['socCreditDays']" name="socCreditDays"  placeholder="Credit Days" [disabled]="isSOC == false" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="none($event)">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-socCreditDays'] != null && model['error-socCreditDays'] != ''">
                        {{model['error-socCreditDays']}}
                      </div>
                    </div>
                    <div class="field" style="width:100%" [class.error]="model['error-slotCreditDays'] != null && model['error-slotCreditDays'] != ''">
                      <label>Credit Days</label>
                      <input type="number" [(ngModel)]="model['slotCreditDays']" name="slotCreditDays"  placeholder="Credit Days" [disabled]="isSLOT == false" maxlength="4" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="none($event)">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-slotCreditDays'] != null && model['error-slotCreditDays'] != ''">
                        {{model['error-slotCreditDays']}}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="twelve wide fields">
              <div class="one field" style="width:100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom:5px;">Details</a>

                  <div id="displayGrid">
                    <div class="one fields">
                      <div class="field" style="width:100%">
                        <div class="ui icon tiny buttons">
                          <button class="ui button sapi" [disabled]="lock == true" data-content="Add" (click)="addDetail();"><i class="plus icon"></i></button>
                          <button class="ui button sapi" [disabled]="lock == true" data-content="Remove" (click)="removeDetail();"><i class="minus icon"></i></button>
                        </div>
                        <grid-plugin id='detail-customer' #gridDetails [settings]="settingGridDetails" (gridEvent)="gridEventDetails($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>
                      </div>
                    </div>
                  </div>

                  <div id="display">
                    <div class="two fields">
                      <div class="required field" [class.error]="modelDetail['error-customerDisplayName'] != null && modelDetail['error-customerDisplayName'] != ''">
                        <label>Display Name</label>
                        <input type="text" [(ngModel)]="modelDetail['customerDisplayName']" (ngModelChange)="modelDetail['error-customerDisplayName']=''" name="customerDisplayName"  placeholder="Display Name" [disabled]="lock == true" maxlength="70" (keypress)="none($event)">
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="modelDetail['error-customerDisplayName'] != null && modelDetail['error-customerDisplayName'] != ''">
                          {{modelDetail['error-customerDisplayName']}}
                        </div>
                      </div>
                      <div class="field" style="width:100%">
                        <label>Contact Person</label>
                        <input type="text" [(ngModel)]="modelDetail['contactPerson']" name="contactPerson"  placeholder="Contact Person" [disabled]="lock == true" maxlength="50" (keypress)="none($event)">
                      </div>
                    </div>

                    <div class="two fields">
                      <div class="required field" [class.error]="modelDetail['error-fullAddress'] != null && modelDetail['error-fullAddress'] != ''" style="width:100%">
                        <label>Display Address</label>
                        <textarea style="margin-top:5px;" maxlength="143" [(ngModel)]="modelDetail['fullAddress']" (ngModelChange)="modelDetail['error-fullAddress']=''" name="fullAddress" placeholder="Input your display address.." [disabled]="lock == true" ></textarea>
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="modelDetail['error-fullAddress'] != null && modelDetail['error-fullAddress'] != ''">
                          {{modelDetail['error-fullAddress']}}
                        </div>
                      </div>
                      <div class="field" style="width:100%">
                        <div class="one fields">
                          <div class="field" style="width:100%">
                            <label>Tel. No(s).</label>
                            <input type="text" [(ngModel)]="modelDetail['telno']" name="tel"  placeholder="Tel. No(s)." [disabled]="lock == true" maxlength="50" (keypress)="none($event)">
                          </div>
                        </div>
                        <div class="one fields">
                          <div class="field" style="width:100%" [class.error]="modelDetail['error-email'] != null && modelDetail['error-email'] != ''">
                            <label>Email</label>
                            <input style="text-transform: lowercase;" type="text" [(ngModel)]="modelDetail['email']" name="email"  placeholder="Email" [disabled]="lock == true" maxlength="50" (keypress)="none($event)">
                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="modelDetail['error-email'] != null && modelDetail['error-email'] != ''">
                              {{modelDetail['error-email']}}
                            </div>
                          </div>
                        </div>
                        <div class="one fields">
                          <div class="field" style="width:100%">
                            <label>Fax. No(s).</label>
                            <input type="text" [(ngModel)]="modelDetail['fax']" name="fax"  placeholder="Fax. No(s)." [disabled]="lock == true" maxlength="50" (keypress)="none($event)">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field" style="width:100%">
                      </div>
                      <div class="field" style="width:100%">
                        <div class="ui buttons">
                          <button class="ui button" (click)="cancelDetail()">Cancel</button>
                          <div class="or"></div>
                          <button class="ui positive button" (click)="saveDetail()">Save</button>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
            </div>

          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Master Customer</h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Customer</a>

              <div class="one fields" style="margin-top:10px;">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                  <button class="ui button" (click)="generateCustomerHistoryReport($event)">Export</button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>Customer Name</label>
                  <combo-plugin #cbCustomerHistroy [settings]="settingCustomerHistory" (change)="changeEventCustomerHistory($event)"></combo-plugin>
                </div>
                <!--
                <div class="field">
                    <label>Customer Code</label>
                    <input type="text"  [value]="customerCodeHistory" name="customerCodeHistory"  placeholder="Customer History Code" disabled readonly style="background-color: lightgray">
                </div>
                -->
              </div>

              <div class="field">
                <grid-plugin #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
              </div>

            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>

  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
