import {
    Component,
    OnInit,
    EventEmitter,
    AfterViewInit,
    ViewChild,
    ChangeDetectorRef,
  } from "@angular/core";
  import {
    ListStore,
    Authorize,
    Validator,
    GridPluginComponent,
    ComboPluginComponent,
    GenericService,
    CalendarPluginComponent,
    GenericUtil,
    DatePluginComponent,
    CookieService,
    ConfigService,
    PdfPluginComponent,
  } from "sibego-ui-library";
  import { Router } from "@angular/router";
  import { Promise } from "q";
  import { config } from "rxjs/internal/config";
  import * as FileSaver from "file-saver";
  import { HttpClient, HttpHeaders } from "@angular/common/http";
  import { load } from "@angular/core/src/render3/instructions";
  import {
    specialCharUtil
  } from "../shared/index";

  export class ReportCustomerLifting {
    dateFrom = "";
    dateTo = "";
    containerOwnership = "";
    customerArea = "";
    constructor() {}
  }
  
  declare var $: any;
  
  @Component({
    selector: "app-report-soc-customer-rate",
    templateUrl: "./report-lifting-customer-page.component.html",
    styleUrls: ["./report-lifting-customer-page.component.css"],
  })
  export class ReportLiftingCustomerPageComponent
    extends Validator
    implements OnInit, AfterViewInit
  {
    dialog: any;
  
    @ViewChild("dateFrom") dateFrom: DatePluginComponent;
    @ViewChild("dateTo") dateTo: DatePluginComponent;
  
    /* Parameter for information into Footer */
    info = "";
    frmDialogMessage = "";
    /* Parameter for enable/disable Toolbar buttons */
    invisibleToolbarButtons = "";
    disableToolbarButtons = "";
    modeToolbar = false;
    loading = false;
    /* Parameter settings */
    settingToolbar;
    settingDateFrom;
    settingDateTo;
    officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    userName = this.cookieService.getName();
    isError: Boolean = false;
    // temporaryOk: Boolean = false;
    cButtonLock = true;
    dButtonLock = true;
    model = new ReportCustomerLifting();

    sCharUtil = new specialCharUtil();
    sessionId = "";
  
    validatorRules = {};
    constructor(
      private cdr: ChangeDetectorRef,

      private _http: HttpClient,
      private auth: Authorize,
      private cookieService: CookieService,
      private genericService: GenericService,
      private genericUtil: GenericUtil,
      private router: Router,
      private configService: ConfigService
    ) {
      super();
      this.model.containerOwnership = "SOC";
      this.model.customerArea = "IN-SIN";
  
      this.settingToolbar = {
        buttonsFront: [
          { name: "Ok", event: "ok", icon: "checkmark" },
          { name: "Cancel", event: "cancel", icon: "cancel" },
          { name: "Close", event: "close", icon: "power" },
        ],
        buttonsDetail: [],
        createDefaultFront: false,
        createDefaultDetail: false,
        toolbarType: "label",
        label: "Customer Lifting/Rate Report",
      };
  
      this.settingDateFrom = {
        id: "dateFrom",
        placeholder: "Date From",
        autoHide: true,
        type: "date", // date|custom
        format: "yyyy-mm-dd",
        customtext: "",
        maxlength: 10
      };

      this.settingDateTo = {
        id: "dateTo",
        placeholder: "Date To",
        autoHide: true,
        type: "date", // date|custom
        format: "yyyy-mm-dd",
        customtext: "",
        maxlength: 10
      };
  
    }
  
    clearValidatorError() {
      this.model["error-dateFrom"] = "";
      this.model["error-dateTo"] = "";
      this.isError = false;
      // this.temporaryOk = false;
    }
  

    setValidatorDetails() {
      this.clearValidatorError();
      this.validatorRules = {
        dateFrom: {
          rules: [
            {
              type: "empty",
              prompt: "Please select date from",
            },
          ],
        },
        dateTo: {
          rules: [
            {
              type: "empty",
              prompt: "Please select date to",
            },
          ],
        },
        // dateValidator: {
        //   rules: [
        //     {
        //       type: "custom",
        //       prompt: "Eta to Date must be later than Eta From.",
        //     },
        //   ],
        // },
      };
      // console.log("calidasinya = ", this.validatorRules)
    }
  
    ngOnInit() {
    }
  
    ngAfterViewInit() {
      this.cdr.detectChanges();
    }

  
    toolbarEvent(event) {
      switch (event) {
        case "cancel":
          this.onCancel();
          break;
        case "print":
          console.log("printing");
          // this.handlePrint();
          break;
        case "ok":
            this.setValidatorDetails();
            // console.log("ok");
            this.handleOk();
            break;
        case "close":
          // handle close event
          this.router.navigateByUrl("/main/home");
          break;
      }
    }
  
    eventMessage(event) {}
    
    handleOk(){
      this.clearValidatorError();
      this.isError = this.onValidate(this.model);
      // this.printLoading = true;
      console.log(this.isError)
      if (!this.isError) {
        this.genericUtil.showLoader();
        var self = this;
        self.genericService
          .GET(
            self.configService.config.BASE_API.toString() +
            // "http://localhost:15000" +
              "/BLReport/customerLiftingAndRatesReportsV2/" +
              self.model.dateFrom +
              "/" +
              self.model.dateTo +
              "/" +
              self.model.containerOwnership + 
              "/" + 
              self.model.customerArea +
              "/" +
              self.cookieService.getName()
          )
          .subscribe((resp) => {
            // console.log("sini", resp.json()['message']);
              if(resp.json()['message'] == 'ok'){
                // console.log("1 ", self.configService.config.BASE_API.toString() + resp.json()['downloadPath']);
                // this.downloadFile( "http://10.10.100.85" + resp.json()['downloadPath']);
                // this.genericUtil.hideLoader();                 

                this.sessionId = resp.json()['session-id'];
                this._http
                  .get(
                    self.configService.config.BASE_API.toString() +
                    // "http://localhost:15000" +
                    "?q=/BLReport/customerLiftingAndRatesReports/" +
                    this.sCharUtil.htmlEncode(
                      this.sessionId
                    ),
                    {
                      headers: new HttpHeaders({
                      "Content-Type": "application/json",
                      Token: localStorage.getItem("token"),
                    }),
                    responseType: "blob",
                    }
                  )
                  .subscribe((resp) => {
                    self.genericUtil.hideLoader();
                    var docName = this.sessionId + ".xlsx";
                    FileSaver.saveAs(resp, docName);



                    // this.frmDialogMessage = "Downloaded!";
                    // this.genericUtil.showDialog(
                    //   "ResponseOk",
                    //   "Information",
                    //   350,
                    //   150
                    // );
                  });


              }else{
                this.genericUtil.hideLoader(); 
                this.frmDialogMessage = "Data not found!";

                this.genericUtil.showDialog(
                  "ResponseOk",
                  "Information",
                  350,
                  150
                );

              }

            },
            (error) => {
              // console.log("gagal");
              // console.log(error);
              this.genericUtil.hideLoader();

              this.frmDialogMessage = "Failed!";

                this.genericUtil.showDialog(
                  "ResponseOk",
                  "Information",
                  350,
                  150
                );


            }
          );

      }
    }
  
    message(
      txtIcon: string,
      txtHeader: string,
      txtContent: string,
      btns: string,
      eve: any
    ) {
      this.dialog = {
        icon: txtIcon,
        header: txtHeader,
        content: txtContent,
        buttons: btns,
        event: eve,
      };
      $("#dialog").modal("show");
    }
  
    onCancel() {
      // console.log("clear Data")
      this.clearValidatorError();
      this.dateFrom.reset();
      this.dateTo.reset();
      this.model.dateFrom           = ""
      this.model.dateTo             = ""
      this.model.containerOwnership = "SOC"
      this.model.customerArea       = "IN-SIN"
    }
  
    changeEventDateFrom(event) {
      // console.log("** changeEventDateFrom2 = ", event.replace("datepick-", ""));
      this.model.dateFrom = event.replace("datepick-", "")
    }

    changeEventDateTo(event) {
      this.model.dateTo = event.replace("datepick-", "")
    }

    changeEventContainerOwnership(event){
      this.model.containerOwnership = event.target.value
    }

    changeEventCustomerArea(event){
      // console.log(event.target.value);
      this.model.customerArea = event.target.value
    }

    checkDateFormLessThanDateTo(): Boolean {
      let result: Boolean = false;
      if (this.model.dateTo != "") {
        if (this.model.dateTo <= this.model.dateFrom) {
          this.model["error-DateFormLessThanDateTo"] =
            "Date (" +
            this.model.dateTo.toString() +
            ") must be later than Date From.";
          result = true;
        }
      }
  
      return result;
    }

    onDialogResponseOk() {
      // console.log("OK");
      this.clearValidatorError();
      // this.dateFrom.reset();
      // this.dateTo.reset();
      // this.model.dateFrom           = ""
      // this.model.dateTo             = ""
      // this.model.containerOwnership = "SOC"
      // this.model.customerArea       = "IN-SIN"
      this.genericUtil.closeDialog("ResponseOk");
    }

    downloadFile(data) {
      // const blob = new Blob([data], { type: 'text/xlsx' });
      // const url= window.URL.createObjectURL(data);
      // window.open(url);
      var link = document.createElement("a");
      link.href = data;
      link.click();
    }
  }