<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-bOceanVesselCode'] == null || param['error-bOceanVesselCode'] == '' || param['error-bOceanVesselCode'] == undefined ">
        {{param['error-bOceanVesselCode']}}
      </li>
      <li [class.hidden]="param['error-bOceanVesselVoyage'] == null || param['error-bOceanVesselVoyage'] == '' ">
        {{param['error-bOceanVesselVoyage']}}
      </li>
      <li [class.hidden]="param['error-bOceanVesselBound'] == null || param['error-bOceanVesselBound'] == ''">
        {{param['error-bOceanVesselBound']}}
      </li>
    </ul>
  </div>

  <form class="ui form">
    <div class="six wide fields">
      <div class="two field" style="width:100%" [class.error]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
        <label>Vessel</label>
        <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
        <div class = "ui basic red pointing prompt label transition error-message-hidden"
             [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
          {{model['error-vesselName']}}
        </div>
      </div>
      <div class="one field" style="width:75%" [class.error]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
        <label>Voyage</label>
        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
        <div class = "ui basic red pointing prompt label transition error-message-hidden"
             [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
          {{model['error-voyage']}}
        </div>
      </div>
      <div class="one field" style="width:60%" [class.error]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
        <label>Bound</label>
        <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['bound']" (change)="model['error-bound'] = '';updateVoyageUrl('header');" [disabled] = "retLock">
        <div class = "ui basic red pointing prompt label transition error-message-hidden"
             [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
          {{model['error-bound']}}
        </div>
      </div>

      <div class="two field" style="width:100%">
        <label>Port of Load</label>
        <combo-plugin #cbPOL [settings]="settingPOL" (change)="changeEventPOL($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <label>Port of  Discharge</label>
        <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="background-color:lightgray; width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:105%">
        <label>Port of Transhipment 1</label>
        <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <label>Final Destination</label>
        <combo-plugin #cbFinalDestination [settings]="settingFinalDestination" (change)="changeEventFinalDestination($event)" style="background-color:lightgray; width:100%;"></combo-plugin>
      </div>
    </div>

    <div class="six wide fields">

      <!--
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isCOC == true " [(ngModel)]="isCOC" name="isCOC" (change)="changeContainerCOC($event)" tabindex="0">
          <label>COC</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isSOC == true "  [(ngModel)]="isSOC" name="isSOC" (change)="changeContainerSOC($event)" tabindex="0">
          <label>SOC</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isSLOT == true " [(ngModel)]="isSLOT" name="isSLOT" (change)="changeContainerSLOT($event)" tabindex="0">
          <label>SLOT</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isNVOCC == true" [(ngModel)]="isNVOCC" name="isNVOCC" (change)="changeContainerNVOCC($event)" tabindex="0">
          <label>NVOCC</label>
        </div>
      </div>
-->
      <!--
      <div class="two field" style="width:100%;" >
        <div class="ui selection dropdown">
          <input type="hidden" name="dataCSSN" style="width:100%;" [(ngModel)]="param['dataCSSN']" (change)="changeEventCSSN($event)">
          <i class="dropdown icon"></i>
          <div class="default text"> All / COC / SOC / SLOT </div>
          <div class="menu">
            <div class="item" value='All'>All</div>
            <div class="item" value='COC'>COC</div>
            <div class="item" value='SOC'>SOC</div>
            <div class="item" value='SLOT'>SLOT</div>
            <div class="item" value='NVOCC'>NVOCC</div>
          </div>
        </div>
      </div>
      -->

      <div class="two field" style="width:100%">
        <combo-plugin #cbCossn [settings]="settingCossn" (change)="changeCossn($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <combo-plugin #cbLocalTranshipment [settings]="settingLocalTranshipment" (change)="changeEventLocalTranshipment($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <combo-plugin #cbFullEmpty [settings]="settingFullEmpty" (change)="changeEventFullEmpty($event)" style="width:100%;"></combo-plugin>
      </div>
    </div>

    <div class="one wide fields" style="width:100%;align-self:right;">

      <div class="one field">
        <label>Shipper Draft BL Number</label>
      </div>
      <div class="four field" style="width: 25%">
        <input type="text" name="shdraft" placeholder="BL Number" style="width:100%;" [(ngModel)]="param['shdraft']" >
      </div>
      <div class="four field">
        <label> &nbsp; &nbsp;  &nbsp; &nbsp;   &nbsp; </label>
      </div>

      <div class="tfield">
        <div class="ui checkbox">
          <input type="checkbox" [checked]="isCom == true" [(ngModel)]="isCom" name="isCom" (change)="changeContainerCom($event)" tabindex="0">
          <label style="font-size:12px">Finished</label>
        </div>
      </div>

      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" [checked]="isUncom == true" [(ngModel)]="isUncom" name="isUncom" (change)="changeContainerUncom($event)" tabindex="0">
          <label style="font-size:12px">Not Finished</label>
        </div>
      </div>

      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" [checked]="isDel == true" [(ngModel)]="isDel" name="isDel" (change)="changeContainerDel($event)" tabindex="0">
          <label style="font-size:12px">Deleted</label>
        </div>
      </div>

      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" [checked]="isWcon == true" [(ngModel)]="isWcon" name="isWcon" (change)="changeContainerWcon($event)" tabindex="0">
          <label style="font-size:12px">Without Container</label>
        </div>
      </div>

      <div class="field">
        <div class="ui checkbox">
          <input type="checkbox" [checked]="isWcharge == true" [(ngModel)]="isWcharge" name="isWcharge" (change)="changeContainerWcharge($event)" tabindex="0">
          <label style="font-size:12px">Without Charge</label>
        </div>
      </div>
    </div>

    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="six wide fields">
      <div class="two field" style="width:100%">
        <grid-plugin id="gridHeader" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
    </div>

    <div class="six wide fields" style="width: 50%">
      <div class="one field" style="width:25%">
        <label>SOC B/Ls</label>
        <input type="text" readonly name="TbSoc" style="background-color:lightgray;" [(ngModel)]="param['TbSoc']" placeholder="SOC B/Ls" style="width:100%;" >
      </div>
      <div class="one field" style="width:25%">
        <label>COC B/Ls</label>
        <input type="text" readonly name="TbCoc" style="background-color:lightgray;" [(ngModel)]="param['TbCoc']" placeholder="SOC B/Ls" style="width:100%;" >
      </div>
      <div class="one field" style="width:25%">
        <label>SLOT B/Ls</label>
        <input type="text" readonly name="TbSlot" style="background-color:lightgray;" [(ngModel)]="param['TbSlot']" placeholder="SOC B/Ls" style="width:100%;">
      </div>
      <div class="one field" style="width:25%">
        <label>Total B/Ls</label>
        <input type="text" readonly name="TbTotal" style="background-color:lightgray;" [(ngModel)]="param['TbTotal']" placeholder="SOC B/Ls" style="width:100%;">
      </div>
    </div>
    <div class="six wide fields" style="width: 50%">
      <div class="one field" style="width:25%">
        <label>NVOCC B/Ls</label>
        <input type="text" readonly name="TbNvocc" style="background-color:lightgray;" [(ngModel)]="param['TbNvocc']" placeholder="NVOCC B/Ls" style="width:100%;">
      </div>
    </div>
  </form>

</div>

<footer-plugin [info]="info"></footer-plugin>
