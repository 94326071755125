import { Component, Input, Output, OnInit, EventEmitter, AfterViewInit, OnDestroy} from '@angular/core';
declare  var $:any;

@Component({
  selector: 'new-dialog',
  templateUrl: './new-dialog.component.html',
  styleUrls: ['./new-dialog.component.css']
})
export class newDialogComponent implements OnInit, OnDestroy{
  @Input('settings') _settings:any;
  @Output('eventDialogs') eventDialog = new EventEmitter<any>();

  id: string = 'dialogNew';

  prompterType:string = 'information';
  prompterHeader:string = 'Information';
  prompterContent:string = '';
  prompterButtons = [{name:"ok", event:""}];

  constructor() {

  }

  ngOnInit() {
    if (this._settings['id'] != undefined) {
      this.id = this._settings['id'];
    }
  }

  ngAfterViewInit(){

  }

  ngOnDestroy(){
    this.closePrompter(this.id);
  }

  openPrompterDialog(){
    $("#"+this.id).dialog({
      title:"Information",
      modal:true,
      width:400,
      height:'auto',
      open: function(event, ui) {
        //hide close button.
        //console.log($(this));
        $('.ui-dialog').css('z-index',103);
        $('.ui-widget-overlay').css('z-index',102);
        $(this).parent().children().children('.ui-dialog-titlebar-close').hide();
        $("body").first().css("overflow","hidden");
        $('.ui-widget-content').css('min-height','auto');
    },
    close: function(event, ui){
      $("body").first().css("overflow"," auto");
    }
    }).dialog("open");
  }

  closePrompter(id:string){
    $("#"+id).dialog("close");
    $("#"+id).dialog("destroy");
  }

  showPrompterDialog(icon:string, title:string, content:string, button:any){
    this.prompterType = icon;
    this.prompterHeader = title;
    this.prompterContent = content;
    this.prompterButtons = button;

    this.openPrompterDialog();
  }

  clickEvent(event){
    this.closePrompter(this.id);

    if(event != ""){
      this.eventDialog.emit(event);
    }
  }

}
