export class SurchargeDetailTotal{
    schDetailChargeName:string="";

    //for charge amount
    schDetailD20:number=0;
    schDetailD40:number=0;
    schDetailD45:number=0;
    schDetailH20:number=0;
    schDetailH40:number=0;
    schDetailH45:number=0;

}


export class SurchargeMaster {
    //validator
    checkEmptyDetailValidator:Boolean = false;
    checkExistingValidator:Boolean = false;
    checkDuplicateValidator:Boolean = false;

    //fields in table
    schOfficeCode       :string="";
    schSurchargeId         :string="";   
    schFreightPartyId      :string="";
    schPlaceOfReceiptCode :string="";
    schLoadPortCode :string="";
    schDischargePortCode  :string="";
    schPlaceOfDeliveryCode  :string="";
    schFinalDestinationCode :string="";
    schPortOfTransshipment1Code  :string="";
    schPortOfTransshipment2Code  :string="";
    schPortOfTransshipment3Code  :string="";
    schCommodityId  :string="";
    schReceiptTypeCode  :string="CY";
    schDeliveryTypeCode :string="CY";
    schEffectiveFrom  :string="";
    schEffectiveTo  :string="";
    schContainerOwnership :string="ALL";
    schContainerStatus  :string="ALL";
    schDgClass  : string="ALL";
    schShipmentType :string="N";
    schVesselId :string="";
    schVoyage :string="";
    schDetention  :string="S";
    schDetentionRequest :number=0;
    schDemurrage  :string="S";
    schDemurrageRequest :number=0;
    schRemarks :string="";
    schUserCreated  :string="";
    schDateCreated  :string="";
    schUserModified :string="";
    schDateModified :string="";
    schIsValid:string='ALL';
    schUserInvalid:string='';
    schDateInvalid:string='';
    schPORTerminalCode  :string="";
    schPORTerminalName  :string="";
    schPOLTerminalCode  :string="";
    schPOLTerminalName  :string="";
    schPODTerminalCode  :string="";
    schPODTerminalName :string="";
    schPODLTerminalCode  :string="";
    schPODLTerminalName  :string="";
    schPOT1TerminalCode  :string="";
    schPOT1TerminalName  :string="";
    schPOT2TerminalCode  :string="";
    schPOT3TerminalCode  :string="";
    schFDESTTerminalCode  :string="";
    schFDESTTerminalName  :string="";
    schIsDeleted:string='N';
    schUserDeleted:string='';
    schDateDeleted:string='';
    schReasonDeleted:string='';     
    schIsCommission:string='N';
    schAcctPic:string='';
    
    //additional
    schContainerType   :string="ALL";
    schSurchargeDetails:SurchargeDetail[] = [];
    schPolPrefix:string="";
    schPodPrefix:string="";
    schContainerOwnershipPrefix:string="";
    schSerchByRefNo:string="N";
    schHeaderKey:string="";
    schFreightPartyCountry:string = '';
    schLocalEta:string="";
    schLocalEtd:string="";
    schLocalAta:string="";
    schLocalAtd:string="";
    schWeight:number=0;
    schDateApprovedFrom:string='';
    schDateApprovedTo:string='';
    isCopy:boolean = false;
    isCreate:string = '';
    lastSurchargeFromStatus:string = '';
    lastSurchargeFrom:string = '';
    lastSurchargeFromStart:string = '';
    lastSurchargeFromEnd:string = '';
    lastSurchargeToStatus:string = '';
    lastSurchargeTo:string = '';
    lastSurchargeToStart:string = '';
    lastSurchargeToEnd:string = '';
    disableInvalid:string = 'false';
    disableValid:string = 'false';
    schUserID:string = "";
    historyList:SurchargeHistory[] = [];
    schBookingPartyId      :string="";
    schShipperId      :string="";
    schBookingPartyCountry:string = '';
    schShipperCountry:string = '';

    schDetentionPOL  :string="S";
    schDetentionRequestPOL :number=0;
    schDemurragePOL  :string="S";
    schDemurrageRequestPOL :number=0;

    constructor(){}
}

export class SurchargeHistory{
    after:string = "";
    current:string = "";
    before:string = "";
}

export class SurchargeDetail {
    schDetailPCTab:string="";
    schDetailPC:string="";
    schDetailContainerType:string="STD";
    schDetailSeqNo:number=0;
    schDetailChargeCode:string="";
    schDetailChargeName:string="";
    schDetailCurrency:string="";
    schDetailPlaceOfPayment:string="";
    schDetailPlaceOfPaymentName:string="";
    schDetailPayer:string="";
    schDetailPayerName:string="";

    //for charge amount
    schDetailD20:number=0;
    schDetailD40:number=0;
    schDetailD45:number=0;
    schDetailH20:number=0;
    schDetailH40:number=0;
    schDetailH45:number=0;

    //for OOG
    schDetailStatus:string="P";
    schDetailM3:number=0;
    schDetailKT:number=0;
    schDetailRT:number=0;
    schDetailMinCharge:number=0;
    schDetailKillSlot:number=0;
    schDetailFAIGroup:string="N";
    schDetailOogOH:number=0;
    schDetailOogOWL:number=0;
    schDetailOogOWR:number=0;
    schDetailOogOLF:number=0;
    schDetailOogOLB:number=0;
    schDetailOogDoorOpen:string="N";
    schDetailOogUcH:number=0;
    schDetailOogUcL:number=0;
    schDetailOogUcW:number=0;

    //additional
    no:number = 0;
    schDetailTotalChargeValue:number=0;
    schDetailCopyTab:string="N";
    schDetailOogDoorOpenName:string="N/A";
    schDetailContainerTypeName:string="NORMAL";
    isValidPayer: string;

    constructor(){}

}


