import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { autoCreationBLModel, newDialogComponent} from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, ConfigService, GenericUtil,CookieService, UploadPluginComponent, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $:any;

@Component({
  selector: 'app-transaction-discrepancies-page',
  templateUrl: './transaction-discrepancies-page.component.html',
  styleUrls: ['./transaction-discrepancies-page.component.css']
})

export class TransactionDiscrepanciesPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  @ViewChild('obopMatchContainer') obopMCFileUpload:UploadPluginComponent;
  @ViewChild('obopPM4FileMCUpload') obopPM4MCFileUpload:UploadPluginComponent;
  @ViewChild('dialogObopMCD') dialogObopMC:newDialogComponent;

  @ViewChild('obopMCPdfContainer') obopPdfMCContainer: PdfPluginComponent;


  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  labelToolbar = "Discrepancies";

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingUploadObopMCFile;
  settingDialogOBOPMC;
  settingObopPDFMC;

  //types

  //test


  // lock

  // model declare
  model = new autoCreationBLModel;
  isCreateDisabled:boolean = false;
  isObopExist:boolean = false;
  hitUrl:string = this.configService.config.BASE_API.toString();
  postData:any = {};

  validatorRules = {};

  constructor(private auth:Authorize,
      private genericService:GenericService,
      private genericUtil:GenericUtil,
      private router: Router,
      private configService:ConfigService,
      private cookieService: CookieService,
      private _http:HttpClient) {
    super();
    var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    if(officeCode == "SGSIN"){
      this.labelToolbar = "Discrepancies (OBOP vs BL)";
    }

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'power'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    }

    this.settingUploadObopMCFile = {
      id   : 'uploadObopMC',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    }

    this.settingDialogOBOPMC = {
      id  : 'dialogObopMC',
    }

    this.settingObopPDFMC = {
      id : 'obopPdfMCContainer',
      url : '',
      hidePrint : true,
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initForm();
    var self = this;
    $("#myfile").change(function(e){
      // console.log("file name : "+$(this).val());
      var fileName = $(this).val();
      if(fileName.split(".").pop().toLowerCase() == "csv"){
        self.model.fileName = $(this).val();
        self.disableToolbarButtons = 'pReport';
      } else {
        self.message("information","Information", "Please choose CSV file!","okonly",{ok:""});
      }
    });
  }

  initModel(){
    this.model.fileName = "";
    this.model.pm4FileName = "";
    this.model.fileType = "OBOPMC";
    this.model.isCreateBL = false;
    this.model.messageValue = "TO BEGIN, PLEASE BROWSE FOR THE FILE TO BE PROCESSED.\nTHEN CHOOSE A FILE TYPE: OBOP (Matching container only)/OBOP (Create slot BL) AND CLICK ON THE PROCESS BUTTON.";
    this.disableToolbarButtons = 'process,pReport';
    this.model.userCreated = this.cookieService.getName();
    this.model.officeCode = this.cookieService.getDefaultLocationCode();

    this.postData["officeCode"] = this.model.officeCode;
    this.postData["userId"] = this.model.userCreated;
    this.postData["fileName"] = "";
    this.postData["pm4FileName"] = "";

    this.obopMCFileUpload.store = [];

    this.isObopExist = false;
  }

  initForm(){
    this.initModel();
    this.isCreateDisabled = true;
  }

  uploadProcess(type:string){
    switch(type){
      case "OBOP":
        console.log("run");
        this.obopMCFileUpload.onUpload();
        break;
      case "PM4":
        this.obopPM4MCFileUpload.onUpload();
        break;
    }
  }

  radioChange(event){
    // console.log(event);
    switch(event){
      case "OBOPMC" :
        this.isCreateDisabled = true;
        break;
      case "OBOPSlot" :
        this.isCreateDisabled = false;
        break;
      default:
        break;
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'process' :
        this.uploadObopFileToServer();
        //this.dialogObopMC.showPrompterDialog("information","Information","this function is still under maintenance",[{name:"OK",event:"this.showObopReport();"}]);
        break;
      case 'cancel' :
        this.initForm();
       break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  uploadPM4(){}

  uploadEventObopMCFile(event){
    console.log(event);
    if(event.includes("-")){
      var temp:any[] = event.split("-");
      var key = temp[1].trim();
      var fileName = temp[0].trim();
      //console.log(temp.size())
      if(temp.length > 1){
        key = temp[temp.length - 1].trim();
        var loopInt = 0;
        fileName = "";
        temp.forEach(fe=>{
          if(loopInt < (temp.length-1)){
            fileName = fileName + fe + "-";
          }
          loopInt++;
        });

        fileName = fileName.substr(0, fileName.length-1).trim();
      }
      switch(key){
        case "uploaded":
          this.disableToolbarButtons = "process";
          this.model.fileName = fileName;
          this.isObopExist = true;
          this.uploadProcess("OBOP");
          //this.uploadSastFileToServer();
          break;
        case "deleted":
          this.model.fileName = "";
          this.postData["fileName"] = "";
          this.isObopExist = false;
          this.disableToolbarButtons = "process";
          break;
      }
    } else {
      switch(event){
        case "success":
          this.postData["fileName"] = this.model.fileName;
          this.disableToolbarButtons = "";
          //this.processingSASTFile(postData);

          break;
        default:
          break;
      }
    }
  }

  uploadObopFileToServer(){
    this.loading = true;
    this.postData["obopFileExist"] = this.isObopExist;
    this.postData["pm4FileExist"] = false;
    this.postData["createdDate"] = this.genericUtil.getPSIDate();

    console.log("postData");
    console.log(this.postData);
    this.processionObopFile();

  }

  processionObopFile(){
    var uri = this.hitUrl + "?q=/ContainerLoadList/upload/ObopMatching";
    this._http.post(uri,this.postData,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      console.log(resp);
      var result = resp;
      if(result["status"] == "ok"){
        this.showObopReport(result["session-id"]);
        //this.loading = false;
      } else {
        //this.message("Information","information",result["message"],"okonly",{ok:""});
        this.dialogObopMC.showPrompterDialog("information","Information",result["message"],[{name:"OK",event:""}]);
        this.loading = false;
      }
    });
  }

  eventdialogObopMC(event){
    //console.log(event);
    if(event != ""){
      eval(event);
    }
  }

  eventObopPDFMC(event){
    console.log(event);
    if(event == "close-pdf"){
      this.initModel();
    }
  }

  showObopReport(sessionId){
    console.log("oh yee");
    var reportDate = this.postData["createdDate"].split(" ")[0].split("-")[2] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[1] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[0] + "X" + this.postData["createdDate"].split(" ")[1];
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/ObopMatchingDiscrepancyReport.pdf?sessionId="+sessionId+"&Date="+reportDate+"&userId="+this.model.userCreated;
    var fileName = "Obop-Matching-Discrepancy-Report-"+this.model.userCreated+".pdf";

    var postDataX = {};
    postDataX["fileName"] = fileName;
    postDataX["url"] = jasperURL;

    this.genericService.POST(hitUrl,postDataX).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.obopPdfMCContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.obopPdfMCContainer.showPDF();
        }

      }

      this.loading = false;
    });
  }

}
