import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
  DatetimePluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { BLHeader, jasperModel, specialCharUtil } from "../shared/index";
import { RequestOptions, RequestOptionsArgs, ResponseContentType } from "@angular/http";

declare var $: any;

@Component({
  selector: 'app-report-in-local-reporting',
  templateUrl: './report-in-local-reporting.component.html',
  styleUrls: ['./report-in-local-reporting.component.css']
})
export class ReportInLocalReportingComponent implements OnInit, AfterViewInit {

 dialog: any;
 
   @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
   @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
   @ViewChild("cbInboundVessel") cbInboundVessel: ComboPluginComponent;
   @ViewChild("cbInboundVoyage") cbInboundVoyage: ComboPluginComponent;
   @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;
 
   /* Parameter for information into Footer */
   info = "";
 
   /* Parameter for enable/disable Toolbar buttons */
   invisibleToolbarButtons = "";
   disableToolbarButtons = "";
 
   modeToolbar = false;
 
   loading = false;
 
   /* Parameter settings */
   settingToolbar;
   settingVessel;
   settingVoyage;
   settingInboundVessel;
   settingInboundVoyage;
 
   Modul: String = "Copy";
   config: any = require("config.json");
 
   isError: Boolean = false;
   model = new BLHeader();

   sCharUtil = new specialCharUtil();
 
   jasper: jasperModel;
 
   validatorRules = {};
 
   jasperPath = [];
   isDummyPrinter: boolean = false;
   bound = 'I';
   
   chkBl: boolean = false;
   chkContainer: boolean = false;
   chkCharge: boolean = false;
 
   constructor(
     private cdr: ChangeDetectorRef,
     private genericService: GenericService,
     private genericUtil: GenericUtil,
     private router: Router,
     private configService: ConfigService,
     private cookieService: CookieService
   ) {
 
     this.settingToolbar = {
       buttonsFront: [
         { name: "Export", event: "export", icon: "print" },
         { name: "Cancel", event: "cancel", icon: "cancel" },
         { name: "Close", event: "close", icon: "power" },
       ],
       buttonsDetail: [],
       createDefaultFront: false,
       createDefaultDetail: false,
       toolbarType: "label",
       label: "Inbound Data (CSV)",
     };
 
     this.settingVessel = {
       id: "cbVessel",
       type: "search enter", // search | select | select input
       url:
         this.configService.config.BASE_API.toString() +
         "/MasterVessels/findByComboBoxControl/vesselName={query}",
       maxChars: 0,
       maxlength: 50,
       template: "grid", // default
       placeholder: "-- Vessel --",
       title: "vesselName",
       description: "",
       isMark: true,
       columns: [
         { header: "Vessel Code", field: "vesselCode", width: 150 },
         { header: "Vessel Name", field: "vesselName", width: 300 },
         { header: "Valid", field: "isValid", width: 50 },
       ],
     };
     this.settingInboundVessel = {
       id: "cbInboundVessel",
       type: "search enter", // search | select | select input
       url:
         this.configService.config.BASE_API.toString() +
         "/MasterVessels/findByComboBoxControl/vesselName={query}",
       maxChars: 0,
       maxlength: 50,
       template: "grid", // default
       placeholder: "-- Vessel --",
       title: "vesselName",
       description: "",
       isMark: true,
       columns: [
         { header: "Vessel Code", field: "vesselCode", width: 150 },
         { header: "Vessel Name", field: "vesselName", width: 300 },
         { header: "Valid", field: "isValid", width: 50 },
       ],
     };
 
     this.settingVoyage = {
       id: "cbVoyage",
       type: "search enter", // search | select | select input
       url: "",
       maxChars: 0,
       maxlength: 20,
       template: "grid", // default
       placeholder: "-- Voyage --",
       title: "voyage",
       description: "",
       isMark: true,
       columns: [
         { header: "Voyage", field: "voyage", width: 100 },
         { header: "Bound", field: "bound", width: 100 },
         { header: "Service", field: "serviceCode", width: 100 },
       ],
     };
     this.settingInboundVoyage = {
       id: "cbInboundVoyage",
       type: "search enter", // search | select | select input
       url: "",
       maxChars: 0,
       maxlength: 20,
       template: "grid", // default
       placeholder: "-- Voyage --",
       title: "voyage",
       description: "",
       isMark: true,
       columns: [
         { header: "Voyage", field: "voyage", width: 100 },
         { header: "Bound", field: "bound", width: 100 },
         { header: "Service", field: "serviceCode", width: 100 },
       ],
     };
 
   }
 
   ngOnInit() {}
 
   ngAfterViewInit() {
     this.jasper = new jasperModel(this.genericService, this.configService);
     this.chkBl = true;
   }
 
   toolbarEvent(event) {
     switch (event) {
       case "cancel":
         this.handleCancel();
         break;
       case "close":
         // handle close event
         this.router.navigateByUrl("/main/home");
         break;
       case "export":
         this.exportBLData();
         break;
     }
   }
 
   exportBLData() {
    this.loading = true;

    if ($("#chkBl").is(":checked")) {
      this.chkBl = true;
    } else {
      this.chkBl = false;
    }

    if ($("#chkContainer").is(":checked")) {
      this.chkContainer = true;
    } else {
      this.chkContainer = false;
    }

    if ($("#chkCharge").is(":checked")) {
      this.chkCharge = true;
    } else {
      this.chkCharge = false;
    }

     console.log("***** clicked export start *****");
     console.log(this.model.blOceanVesselId);
     console.log(this.model.blOceanVesselVoyage);
     console.log(this.chkBl);
     console.log(this.chkContainer);
     console.log(this.chkCharge);
     console.log("***** clicked export end*****");
     if (this.model.blOceanVesselId == '' || this.model.blOceanVesselVoyage == '') {
        this.message(
          "warning",
          "Warning",
          "Choose vessel and voyage",
          "okonly",
          { ok: "this.loading=false;" }
        );
     } else if (this.chkBl == false && this.chkContainer == false && this.chkCharge == false) {
        this.message(
          "warning",
          "Warning",
          "Select at least one Inbound type Bl or Container or Charge",
          "okonly",
          { ok: "this.loading=false;" }
        );
     } else {
        var downloadFileName = this.generateDownloadFileName();

        let uri = '';
        if (this.configService.config.standalone) {
          const localReportingConfig = this.configService.config.standalone.find(item => item.endpoint === "/localReportingController");

          if (localReportingConfig) {
            uri = localReportingConfig.target + "/localReportingController/export";
          } else {
            uri = this.configService.config.BASE_API.toString() + "?q=/localReportingController/export";
          }
        } else {
          uri = this.configService.config.BASE_API.toString() + "?q=/localReportingController/export";
        }

        var self = this;

        $.ajaxSetup({
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Token', localStorage.getItem("token"))
          }
        });

        $.ajax({
          url: uri,
          method: "POST",
          data: JSON.stringify({
            officeCode: this.cookieService.getDefaultLocationCode(),
            vesselId:  this.model.blOceanVesselId,
            veselCode:  this.model.blOceanVesselCode,
            voyage:  this.model.blOceanVesselVoyage,
            chkBl: this.chkBl,
            chkContainer: this.chkContainer,
            chkCharge: this.chkCharge
          }),
          xhrFields: {
            responseType: "blob",
          },
          success: function (data) {
            console.log("#3");
            console.log(data);

            if (data.size == 0) {
              self.message(
                "information",
                "Information ",
                "No data found!",
                "okonly",
                { ok: "this.loading=false;" }
              );
            } else {
              const contentType = data.type;
              const blob = new Blob([data], { type: contentType });

              // Determine file extension based on response type
              const fileName = contentType === 'application/zip' ? downloadFileName + '.zip' : downloadFileName + '.csv';

              // Create download link
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              // FileSaver.saveAs(data, fileName + '.csv');

              // const blob = new Blob([data], { type: 'application/zip' });
              // const url = window.URL.createObjectURL(blob);
              // const a = document.createElement('a');
              // a.href = url;
              // a.download = 'bl_data.zip';
              // document.body.appendChild(a);
              // a.click();
              // document.body.removeChild(a);
            }
          },
        });
     }
     
     this.loading = false;
   }
  generateDownloadFileName() {
    if(this.chkBl && this.chkContainer && this.chkCharge) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_BL_Container_Charge" ;
    } else if (this.chkBl && this.chkContainer) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_BL_Container" ;
    } else if (this.chkBl && this.chkCharge) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_BL_Charge" ;
    } else if (this.chkContainer && this.chkCharge) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_Container_Charge" ;
    } else if (this.chkBl) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_BL" ;
    } else if (this.chkContainer) {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_Container" ;
    } else {
      return this.model.blOceanVesselCode + this.model.blOceanVesselVoyage + this.getCurrentDateFormatted() + "_Charge" ;
    }
  }
 
   eventMessage(event) {}

   getCurrentDateFormatted(): string {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if day is less than 10
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if month is less than 10
    const year = currentDate.getFullYear();
  
    return `${day}${month}${year}`;
  }
 
   message(
     txtIcon: string,
     txtHeader: string,
     txtContent: string,
     btns: string,
     eve: any
   ) {
     this.dialog = {
       icon: txtIcon,
       header: txtHeader,
       content: txtContent,
       buttons: btns,
       event: eve,
     };
     $("#dialog").modal("show");
   }

   changeEventVessel(event) {
    // console.log("EVENTnya :"+event.vesselCode);
    //  console.log("Vessel Id :"+event.vesselId);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyageInward/" +
        event.vesselId +
        "/" +
        this.bound.toUpperCase() +
        "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventVoyage(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }
 
   setValidatorVessel() {
     this.clearValidatorError();
     this.validatorRules = {
       blOceanVesselCode: {
         rules: [
           {
             type: "empty",
             prompt: "Please input Vessel.",
           },
         ],
       },
       blOceanVesselVoyage: {
         rules: [
           {
             type: "empty",
             prompt: "Please input Voyage.",
           },
         ],
       },
       blOceanVesselBound: {
         rules: [
           {
             type: "empty",
             prompt: "Please input Bound.",
           },
         ],
       },
     };
   }

 
   
   handleCancel() {
     this.clearMode();
   }
 
   clearValidatorError() {
     this.model["error-blOceanVesselCode"] = "";
     this.model["error-blOceanVesselVoyage"] = "";
     this.model["error-blOceanVesselBound"] = "";
     this.model["error-blLoadPortCode"] = "";
     this.model["error-blPlaceOfDeliveryCode"] = "";
     this.model["error-blPlaceOfReceiptCode"] = "";
     this.model["error-blDischargePortCode"] = "";
     this.model["error-blReceiptTypeCode"] = "";
   }
 
   clearMode() {
     this.cbVessel.setValue("");
     this.cbVessel.clearSelect();
     this.cbVoyage.setValue("");
     this.cbVoyage.clearSelect();

     $("#chkContainer").prop("checked", false);
     $("#chkCharge").prop("checked", false);
   }

}
