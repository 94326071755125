<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
  <form class="ui form">
      <button class="ui button" style="display:none;"></button>
    <fieldset style="border: 1px solid lightgray;">
      <div class="two fields">
        <div class="field">
          <label>Booking No. :</label>
          <input style = "width : 300px" type="text" name="bNo"  placeholder="Booking No." [(ngModel)]="model['bNo']" >
        </div>
        <!--
        <div class="field">                 
          <div class="ui tiny right floated buttons" style="margin-top:21px;float: left;margin-left:-50%">
            <button  class="ui button retrieve" data-content="Retrieve">Retrieve</button>
          </div>           
        </div>   
      -->
      </div>
    </fieldset>  
    <br>

    <fieldset style="border: 1px solid lightgray;">
      <div class="four wide fields">
        <div class="field">
          <label>Booking Date : </label>
          <input style = "width : 200px" type="text" name="bNoDate"  placeholder="Booking Date" [(ngModel)]="model['bNoDate']" >
        </div>
        <div class="field">
          <label>Vessel: </label>
          <input style = "width : 200px" type="text" name="vessel"  placeholder="Vessel" [(ngModel)]="model['vessel']" >
        </div>
        <div class="field">
          <label>Voyage: </label>
          <input style = "width : 200px" type="text" name="voyage"  placeholder="Voyage" [(ngModel)]="model['voyage']" >
        </div>
        <div class="field">
          <label>Bound: </label>
          <input style = "width : 200px" type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['bound']" >
        </div>
    </div>
  </fieldset>
  <br>
  <div class="field">
    <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
  </div>  

  </form>
</form>

<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
<footer-plugin [info]="info"></footer-plugin>
