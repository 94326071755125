export class china_tally_list  {

    //blNo : string = '';
    //containerNo : string = '';
    fileName : string = '';
    vesselName : string = '';
    voyageNo : string = '';
    // ata : string = '';
    // atd : string = '';
    // status : string = '';
    // special : string = '';
    // partOfOneContainer : string = '';
    // sealNo : string = '';
    // containerSize : string = '';
    // containerType : string = '';
    // noOfOuterPackage : string = '';
    // outerPackageType : string = '';
    // grossMeasurement : string = '';
    // grossWeight : string = '';
    // tareWeight : string = '';
    // cellLocation : string = '';
    // podCode : string = '';
    // podName : string = '';
    // fdestCode : string = '';
    // fdestName : string = '';
    // operatorCode : string = '';
    // vesselCode : string = '';
    // vesselId : string = ''; 
    // dateReceived : string = '';
    // timeReceived : string = '';
    // isProcessedCll : string = '';
    // isProcessedBl : string = '';
    officeCode: string = '';
    user : string = '';
    processBtn: Boolean = false;
    rejectContainers: Containers[] = [];
    acceptContainers: Containers[] = [];

    constructor() {

    }

}

export class Containers {

    blNo: String = "";
    containerNo: String = "";
  
    constructor() {}
}

export class china_tally_list_edi {

    fileName : string = '';
    vesselName : string = '';
    voyageNo : string = '';

    ediId: string = '';
    remarks : string = '';
    uploaded : string = '';

    constructor() {}
}