import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  CalendarPluginComponent,
  GenericUtil,
  DatePluginComponent,
  CookieService,
  ConfigService,
  PdfPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { Promise } from "q";
import { config } from "rxjs/internal/config";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { load } from "../../../node_modules/@angular/core/src/render3/instructions";

export class ReportParam {
  // fromDate = "";
  // toDate = "";

  yearParam = "";
  monthParam = "";
  locationParam = "*";

  reportType = "*";

  constructor() {}
}

declare var $: any;

@Component({
  selector: "app-report-soc-customer-rate",
  templateUrl: "./report-soc-customer-rate-page.component.html",
  styleUrls: ["./report-soc-customer-rate-page.component.css"],
})
export class ReportSocCustomerRatePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("etaFrom") etaFrom: DatePluginComponent;
  @ViewChild("etaTo") etaTo: DatePluginComponent;
  @ViewChild("cbYear") cbYear: ComboPluginComponent;
  @ViewChild("cbMonth") cbMonth: ComboPluginComponent;
  @ViewChild("cbLoadPortCode") cbLoadPortCode: ComboPluginComponent;
  // @ViewChild("cbReportType") cbReportType: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPOC;
  settingDatePicker;
  settingDateFrom;
  settingDateTo;
  settingCbYear;
  settingCbMonth;
  settingCbLoadPortCode;
  // settingCbReportType;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  userName = this.cookieService.getName();
  isError: Boolean = false;

  // lock
  //lock2
  cButtonLock = true;
  dButtonLock = true;

  model = new ReportParam();

  validatorRules = {};

  listYear = '[{ "yearCode" : "2022", "yearText" : "2022" }]';
  listMonth = '[{"monthCode":"01","monthText":"01"},{"monthCode":"02","monthText":"02"},{"monthCode":"03","monthText":"03"},{"monthCode":"04","monthText":"04"},{"monthCode":"05","monthText":"05"},{"monthCode":"06","monthText":"06"},{"monthCode":"07","monthText":"07"},{"monthCode":"08","monthText":"08"},{"monthCode":"09","monthText":"09"},{"monthCode":"10","monthText":"10"},{"monthCode":"11","monthText":"11"},{"monthCode":"12","monthText":"12"}]';
  // listReportType = '[{ "reportTypeCode" : "Rate", "reportTypeText" : "Rate" }, { "reportTypeCode" : "Lifting", "reportTypeText" : "Lifting" }]';

  constructor(
    private _http: HttpClient,
    private auth: Authorize,
    private cookieService: CookieService,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Report SOC Customer",
    };

    this.settingDatePicker = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingDateFrom = {
      id: "idEtaFrom",
      placeholder: "ETA FROM",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingDateTo = {
      id: "idEtaTo",
      placeholder: "ETA TO",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingPOC = {
      id: "cbPOC",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/100/*/*",
      maxChars: 3,
      template: "grid",
      placeholder: "Search ...",
      title: "locationName",
      desciption: "",
      flag: "", // for template : 2
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Country Name", field: "countryName", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Is Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbYear = {
      id: "cbYear",
      type: "select",
      url: this.buildYear(),
      urlType: "inject",
      code: "yearCode",
      title: "yearText",
    };

    this.settingCbMonth = {
      id: "cbMonth",
      type: "select",
      url: this.listMonth,
      urlType: "inject",
      code: "monthCode",
      title: "monthText",
    };

    this.settingCbLoadPortCode = {
      id: "cbLoadPortCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Location",
      title: "locationCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

  //   this.settingCbReportType = {
  //     id: "cbReportType",
  //     type: "select",
  //     url: this.listReportType,
  //     urlType: "inject",
  //     code: "reportTypeCode",
  //     title: "reportTypeText",
  //   };

  }

  clearValidatorError() {
    this.model["error-yearParam"] = "";
    this.model["error-monthParam"] = "";
    this.model["error-locationParam"] = "";
    this.isError = false;
    this.model["dateValidator"] = false;
  }

  setValidator() {
    this.validatorRules = {
      yearParam: {
        rules: [
          {
            type: "empty",
            prompt: "Please select year",
          },
        ],
      },
      monthParam: {
        rules: [
          {
            type: "empty",
            prompt: "Please select month",
          },
        ],
      },
      // locationParam: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please select location",
      //     },
      //   ],
      // },
      dateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Eta to Date must be later than Eta From.",
          },
        ],
      },
    };
  }

  dateValidator(): Boolean {
    let result: Boolean = false;
    
    if (this.model.yearParam != "" && this.model.monthParam) {
      var dateNow = new Date();
      var yearNow = dateNow.toLocaleString('default', {year: 'numeric'});
      var monthNow = dateNow.toLocaleString('default', {month: '2-digit'});
      console.log(dateNow);
      console.log(parseInt(this.model.yearParam+this.model.monthParam));
      console.log(parseInt(yearNow+monthNow));
      if (parseInt(this.model.yearParam+this.model.monthParam) > parseInt(yearNow+monthNow)) {
        this.model["error-etaToValidator"] =
          "Month and Year selected (" +
          this.model.yearParam+'-'+this.model.monthParam+
          ") must be less than "+yearNow +'-'+monthNow;
        return true;
      }
    }

    return result;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: "yyyy-mm-dd",
      zIndex: 2048,
    });

    setTimeout(() => {
      var startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      this.model.yearParam = startDate.toLocaleString('default', {year: 'numeric'});
      this.cbYear.setValue(this.model.yearParam);
      this.model.monthParam = startDate.toLocaleString('default', {month: '2-digit'});
      this.cbMonth.setValue(this.model.monthParam);
      // this.cbReportType.setValue(this.model.reportType);
    }, 100);
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "cancel":
        this.onCancel();
        break;
      case "print":
        console.log("printing");
        // this.model.fromDate = $("#etaFrom").val();
        // this.model.toDate = $("#etaTo").val();
        this.handlePrint();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  eventMessage(event) {}

  handlePrint() {
    this.setValidator();
    this.isError = this.onValidate(this.model);
    this.clearValidatorError();
    this.model["dateValidator"] = false;
    console.log("VALIDASINYA -- > " + this.onValidate(this.model));
    let isPassedValidation: boolean = this.onValidate(this.model);
    this.model['dateValidator'] = this.dateValidator();
    if(this.model['dateValidator'])this.isError = true;

    console.log(this.model);
    var dateParam = new Date(parseInt(this.model.yearParam), parseInt(this.model.monthParam),0);
    console.log(dateParam.getDate());

    if (!isPassedValidation && !this.model["dateValidator"]) {
      this.clearValidatorError();
      this.loading = true;
      var dateFrom = this.model.yearParam+'-'+this.model.monthParam+'-01';
      var dateTo = this.model.yearParam+'-'+this.model.monthParam+'-'+dateParam.getDate();
      var printUrl = "";

      printUrl = this.genericService.getUrl(this.configService.config.BASE_API.toString() + "/?q=/BLReport/reportsoccustomer/"+dateFrom+"/"+dateTo+"/"+this.model.reportType+"/"+this.userName+"/"+this.model.locationParam);
      
      const urlHitData = this.configService.config.BASE_API.toString() + "/BLReport/countreportsoccustomer/"+dateFrom+"/"+dateTo+"/"+this.model.reportType+"/"+this.userName+"/"+this.model.locationParam;
      this.genericService.GET(urlHitData).subscribe((resp) => {
        console.log("RESP Data : " + resp.json()["totalData"]);
        if (resp.json()["totalData"] == 0) {
          this.message(
            "information",
            "Information",
            "No record(s) found.",
            "okonly",
            { ok: "this.loading=true;" }
          );

          this.loading = false;
        } else {
          window.open(printUrl);
          this.loading = false;
        }
      });
    }

    /*this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{

      console.log(res.size);

      //if(res.size > 4138)
          FileSaver.saveAs(res, 'Monthly Lifting Normal.xlsx');
      //else
          //this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});

      this.loading = false;
    });*/
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  onCancel() {
    this.clearValidatorError();
    $("#etaFrom").val("");
    $("#etaTo").val("");
  }

  changeEventCbYear(event) {
    if (
      event.yearCode != null &&
      event.yearCode !== "" &&
      event.yearCode !== undefined
    ) {
      this.model.yearParam = event.yearCode;
    } else {
      this.model.yearParam = "";
    }
  }

  changeEventCbMonth(event) {
    if (
      event.monthCode != null &&
      event.monthCode !== "" &&
      event.monthCode !== undefined
    ) {
      this.model.monthParam = event.monthCode;
    } else {
      this.model.monthParam = "";
    }
  }

  changeEventCbLocation(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.locationParam = event.locationCode;
    } else {
      this.model.locationParam = "*";
    }
  }

  // changeEventCbReportType(event) {
  //   if (
  //     event.reportTypeCode != null &&
  //     event.reportTypeCode !== "" &&
  //     event.reportTypeCode !== undefined
  //   ) {
  //     this.model.reportType = event.reportTypeCode;
  //   } else {
  //     this.model.reportType = "Rate";
  //   }
  // }

  buildYear() {
    var date = new Date();
    date.setFullYear( date.getFullYear() - 2 );

    var result=[];
    //set both start and end date to first date of the month
    const start_date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    while(date.getFullYear()<=start_date.getFullYear()){
      var obj = {"yearCode":date.toLocaleString('default', {year: 'numeric'}), "yearText":date.toLocaleString('default', {year: 'numeric'})};
      result.push(obj);
      date.setFullYear(date.getFullYear() + 1);
    }
    this.listYear = JSON.stringify(result);
    console.log(result);

    return this.listYear;
  }
}
