<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

  <form class="ui form">
    <button class="ui button" style="display:none;"></button>

    
    <div class="ui top attached tabular menu" style="background-color:beige;">
      <a class="item active" data-tab="pending" (click)="onTab()">Pending</a>
      <a class="item" data-tab="nominate" (click)="onTab()">Nominate</a>
    </div>

    <div class="ui bottom attached tab segment active" id="pendingTab" data-tab="pending">
      <!-- Pending -->
      <div class="field">
          <div class="six fields">

            <div class="field">
              <label>Load Port</label>
              <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field">
                <label>Next Port</label>
                <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
            </div>
                  
            <div class="field">
              <label>Vessel</label>
              <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field">
              <label>Voyage</label>
              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
            </div>

            <div class="field" style="width:30%;">
              <fieldset style="width: 100%;border: 1px solid gray;">
                <legend style="font-weight:bold;color:dodgerblue;">Status</legend>
                <div class="six wide fields">
               
                  <div class="field" style="width:30%;align-self:center;">
                      <div class="ui radio checkbox">
                          <input [checked]="model['status'] == 'A' " (change)="changeStatus($event)" value="A" name="status" type="radio" tabindex="0" class="hidden">
                          <label>ALL</label>
                      </div>
                  </div>
                  <div class="field" style="width:30%;align-self:center;">
                      <div class="ui radio checkbox">
                        <input [checked]="model['status'] == 'P' " (change)="changeStatus($event)" value="P" name="status" type="radio" tabindex="0" class="hidden">
                        <label>PENDING</label>
                      </div>
                  </div>
                  <div class="field" style="width:30%;align-self:center;">
                    <div class="ui radio checkbox">
                        <input [checked]="model['status'] == 'C' " (change)="changeStatus($event)" value="C" name="status" type="radio" tabindex="0" class="hidden">
                        <label>COMPLETED</label>
                    </div>
                  </div>
                
                </div>            
              </fieldset>
            
            </div>

             
            
          </div>

          <grid-plugin id='blTranshipmentGrid' #transhipmentGrid [settings]="settingTranshipmentGrid" (gridEvent)="eventTranshipmentGrid($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>

      </div>

    </div>

    <div class="ui bottom attached tab segment" id="nominateTab" data-tab="nominate">
      <!-- nominate -->
      <div class="field">
          <div class="six fields">

            <div class="field">
              <label>Load Port</label>
              <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field">
                <label>Next Port</label>
                <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field">
              <label>Vessel</label>
              <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field">
              <label>Voyage</label>
              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
            </div>
            
          </div>

          <grid-plugin id='blTranshipmentGrid' #transhipmentGrid [settings]="settingTranshipmentGrid" (gridEvent)="eventTranshipmentGrid($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>

      </div>
       
    </div>

  </form>

  <!--
  <div id='container'>

    <form class="ui form">
      <br />
      
      <div id="pendingContainer">
        <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
          <label class="ui red ribbon small label">Transhipment - {{formMode}}</label>
          <br /><br />
          <div class="ui equal width padded grid">
            <div class="four wide field">
              <label style="font-size:12px">Load Port</label>
              <combo-plugin #clp [settings]="settingLoadPort" (change)="eventLoadPort($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin>
            </div>
            <div class="four wide field">
              <label style="font-size:12px">Next Port</label>
              <combo-plugin #cnp [settings]="settingNextPort" (change)="eventNextPort($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin>
            </div>
            <div class="four wide field">
              <label style="font-size:12px">Vessel Name</label>
              <combo-plugin #cvn [settings]="settingVesselName" (change)="eventVesselName($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin>
            </div>
            <div class="four wide field">
              <label style="font-size:12px">Voyage</label>
              <combo-plugin #cbv [settings]="settingBaseVoyage" (change)="eventBaseVoyage($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin>
            </div>
            <div class="twelve wide inline fields" style="width:869px">
              <label style="font-size:12px" [style.display]="formMode == 'Pending'?'initial':'none'" >Status</label>
              <div class="field" [style.display]="formMode == 'Pending'?'initial':'none'">
                <div class="ui radio checkbox">
                  <input type="radio" name="status" value="all">
                  <label style="font-size:12px">All</label>
                </div>
              </div>
              <div class="field" [style.display]="formMode == 'Pending'?'initial':'none'">
                <div class="ui radio checkbox">
                  <input type="radio" name="status" value="pending">
                  <label style="font-size:12px">Pending</label>
                </div>
              </div>
              <div class="field" [style.display]="formMode == 'Pending'?'initial':'none'">
                <div class="ui radio checkbox">
                  <input type="radio" name="status" value="complete">
                  <label style="font-size:12px">Complete</label>
                </div>
              </div>
            </div>
            <div class="four wide field" style="padding-left:29px">
              <button class="ui primary mini button" id="idBtnRetrieve">Retrieve</button>
              <button class="ui primary mini button" id="idBtnPrint" [style.display]="formMode == 'Nominate'?'initial':'none'">Print</button>
            </div>
          </div>
        </div>
      </div>

      <grid-plugin id='blTranshipmentGrid' #transhipmentGrid [settings]="settingTranshipmentGrid" (gridEvent)="eventTranshipmentGrid($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>

    </form>
  </div>-->  
  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
