<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
    <div class="ui medium text loader">Loading</div>
  </div>
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="modelParam['error-periodFrom'] == null || modelParam['error-periodFrom'] == ''">
        {{modelParam['error-periodFrom']}}
      </li>
      <li [class.hidden]="modelParam['error-periodTo'] == null || modelParam['error-periodTo'] == ''">
        {{modelParam['error-periodTo']}}
      </li>
      <li [class.hidden]="modelParam['error-potCode'] == null || modelParam['error-potCode'] == ''">
        {{modelParam['error-potCode']}}
      </li>
    </ul>
  </div>
  <div>
    <form class="ui form">
    <dialog-plugin #dialogPortPair [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    
    <div class="five fields" style="margin:unset !important;">
      <div class="three wide field">
        <div class="one fields" style="width: 100%;">
          <div class="required field" style="width: 100%;" >
            <label style="display: inline-block;margin-bottom: 5px;">Period From</label><span style="color: cornflowerblue;">(ETA POT)</span>
            <datetime-plugin #periodFrom  [settings]="settingPeriodFrom"  [disableDatetime]="isRetrieve" > </datetime-plugin>
          </div>
        </div>
      </div>
      <div class="three wide field">
        <div class="one fields" style="width: 100%;">
          <div class="required field" style="width: 100%;" >
            <label style="display: inline-block;margin-bottom: 5px;">Period To</label><span style="color: cornflowerblue;">(ETA POT)</span>
            <datetime-plugin #periodTo [settings]="settingPeriodTo" [disableDatetime]="isRetrieve" ></datetime-plugin>
          </div>
        </div>
      </div>
      <div class="three wide field">
        <div class="one fields" style="width: 100%;">
          <div class="required field" style="width: 100%;" >
            <label>POT</label>
            <combo-plugin #cbPOT [settings]="settingPOT" (change)="changeEventPOT($event)" style="width: 100%" [disableCombo]="isRetrieve"
            (keypress)="formValidation.keypressEventRegexWith($event, modelParam, 'settingPOT', '[a-zA-Z0-9-() ]')"
            (paste)="formValidation.pasteEventRegexWith($event, modelParam, 'settingPOT', '[^a-zA-Z0-9-() ]', 50)">
            </combo-plugin>
          </div>
        </div>
      </div>
      <div class="three wide field">
        <div class="one fields" style="width: 100%;">
          <div class="field" style="width: 100%;" >
            <label>Terminal</label>
            <combo-plugin #cbTerminal [settings]="settingTerminal" (change)="changeEventTerminal($event)"  style="width:100%;" [disableCombo]="isRetrieve == false ? !hasTerminal: isRetrieve"></combo-plugin>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>
  <div id='container'>
    <table class="ui celled padded table accordion" style="min-height: 295px;" >
      <thead style="display: block;
      overflow-y: scroll;
      overscroll-behavior: none;
      scrollbar-width: thin;
      scrollbar-color: #F9FAFB #F9FAFB;">
        <tr style="display: table;
        table-layout: fixed;
        width: 100%;">
          <th>Vessel</th>
          <th>Voyage</th>
          <th>Bound</th>
          <th>Service</th>
          <th>ETA POT</th>
          <th>POT</th>
        </tr>
      </thead>
      <tbody style="display: block;
      overflow-y: scroll;
      overscroll-behavior: none;
      scrollbar-width: thin; /*max-height: 17.99957143em;*/">
        
        <ng-container *ngFor="let data of SummaryConnectingVesselList; index as i;">

        <tr class="ui title" (click)="loadData(data,i)" style="display: table;
        table-layout: fixed;
        width: 100%;">
          <td>{{data.vesselName}}</td>
          <td>{{data.voyage}}</td>
          <td>{{data.bound}}</td>
          <td>{{data.serviceCode}}</td>
          <td>{{data.eta}}</td>
          <td>{{data.potName}}</td>
        </tr>
        <tr style="display:none" >
          <td colspan="4" class="ui content" style="background: beige;">
            <form class="ui form" [class.loading]="isLoadingDetail == true" >
              <button class="ui button" style="display:none;"></button>
              <br />
              <div class="field">
                <div class="five fields">
                  <div class="six wide field">
                    <div class="ui raised segment">
                      <a class="ui red ribbon label">Connecting Vessel</a>
                      <div class="three fields">
                        <div style="width: 35%;" class="required field">
                          <label>Vessel</label>
                          <input type="text" name="vessel" [(ngModel)]="model['vesselName']" disabled>
                        </div>
                        <div style="width: 25%;" class="required field">
                          <label>Voyage</label>
                          <input type="text" name="voyage" [(ngModel)]="model['voyage']" disabled>
                        </div>
                        <div style="width: 20%;" class="field required">
                          <label>Bound</label>
                          <input type="text" name="bound" [(ngModel)]="model['tsConnVesselBound']" disabled>
                        </div>
                        <div style="width: 20%;" class="field">
                          <label>Service</label>
                          <input type="text" name="service" [(ngModel)]="model['tsServiceCode']" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="six wide field">
                    <div class="ui raised segment">
                      <a class="ui red ribbon label">POT</a>
                      <div class="three fields">
                        <div class="required field">
                          <label>POT</label>
                          <input type="text" name="pot" [(ngModel)]="model['bPot1Name']" disabled>
                        </div>
                        <div class="field">
                          <label>ETA</label>
                          <input type="text" name="eta" [(ngModel)]="model['tsEta']" disabled>
                        </div>
                        <div class="field">
                          <label>ETD</label>
                          <input type="text" name="etd" [(ngModel)]="model['tsEtd']" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wide field">
                    <div class="ui raised segment" style="padding-top: 40px;height: 100%;">
                      <div class="field">
                        <div class="ui radio checkbox disabled">
                          <input type="radio" name="containerOwner" value="SOC" [checked]="model['tsContainerOwnership'] == 'SOC'" disabled>
                          <label>SOC</label>
                        </div>
                        <div class="ui radio checkbox">
                          <input type="radio" name="containerOwner" value="COC" [checked]="model['tsContainerOwnership'] == 'COC'" disabled>
                          <label style="color: green;font-weight: bold;">COC</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="three wide field">
                    <div class="ui raised segment" style="padding-top: 40px;">
                      <div class="three fields">
                        <div class="field">
                          <label style="color: green;">TOTAL:</label>
                          <input type="text" name="total" [(ngModel)]="model['tsTotal']" disabled>
                        </div>
        
                        <div class="field">
                          <label style="color: green;">LOCAL:</label>
                          <input type="text" name="local" [(ngModel)]="model['tsLocal']" disabled>
                        </div>
        
                        <div class="field">
                          <label style="color: green;">T/S:</label>
                          <input type="text" name="T/S" [(ngModel)]="model['tsTS']" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        
              <div class="field">
                <div class="six fields">
                  <div class="six wide field">
                    <div class="ui raised segment">
                      <a class="ui red ribbon label">Rotation</a>
                      <div class="one fields">
                        <form class="field" style="width: 100%;padding-top: 5px;">
                          <fieldset style="width: 100%; border: 1px solid gray; min-height: 33px;">
                            <label style="float: left; font-weight: bold">{{model['tsRotation']}}</label>
                          </fieldset>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <app-custom-ag-grid-angular id="summary-grid-{{i}}"
                [getRowStyle]="getRowStyle" [rowData]="rowData[i]" [rowMultiSelectWithClick]="false" [defaultColumnDefs]="defaultColumnDefs"
                [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (gridReady)="onGridReady($event)"
                [rowSelection]="'single'" [pinnedBottomRowData]="pinnedBottomRowData[i]">
              </app-custom-ag-grid-angular>
            </form>
          </td>
        </tr>
      </ng-container>
      </tbody>
      
    </table>

  </div>
  <!--
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-vesselCode'] == null || model['error-vesselCode'] == ''">
        {{model['error-vesselCode']}}
      </li>
      <li [class.hidden]="model['error-voyage'] == null || model['error-voyage'] == ''">
        {{model['error-voyage']}}
      </li>
      <li [class.hidden]="model['error-bPot1Code'] == null || model['error-bPot1Code'] == ''">
        {{model['error-bPot1Code']}}
      </li>
    </ul>
  </div>

  <div id='container'>
    <dialog-plugin #dialogPortPair [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <br />
      <div class="field">
        <div class="five fields">
          <div class="six wide field">

            <div class="ui raised segment">
              <a class="ui red ribbon label">Connecting Vessel</a>
              <div class="three fields">
                <div style="width: 30%;" class="required field" [class.error]="
                    model['error-vesselCode'] != null &&
                    model['error-vesselCode'] != '' &&
                    model['error-vesselCode'] != undefined
                  ">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"
                    style="width:100%;"></combo-plugin>
                </div>
                <div style="width: 30%;" class="required field" [class.error]="
                    model['error-voyage'] != null &&
                    model['error-voyage'] != '' &&
                    model['error-voyage'] != undefined
                  ">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)"
                    style="width:100%;"></combo-plugin>
                </div>
                <div style="width: 20%;" class="field required" [class.error]="
                    model['error-bound'] != null &&
                    model['error-bound'] != '' &&
                    model['error-bound'] != undefined
                  ">
                  <label>Bound</label>
                  <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                    [value]="model.bound" (change)="changeBound($event)"
                    (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
                </div>
                <div style="width: 20%;" class="field"
                  [class.tooltip]="model['tsServiceCode'] != 'null' && model['tsServiceCode'] != ''">
                  <label>Service</label>
                  <input type="text" name="service" placeholder="" [(ngModel)]="model['tsServiceCode']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsServiceCode"] }}</span>
                </div>
              </div>
            </div>

          </div>
          
          <div class="six wide field">
            <div class="ui raised segment">
              <a class="ui red ribbon label">POT</a>
              <div class="three fields">
                <div class="required field" [class.error]="
                    model['error-bPot1Code'] != null &&
                    model['error-bPot1Code'] != '' &&
                    model['error-bPot1Code'] != undefined
                  ">
                  <label>POT</label>
                  <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width: 100%"
                    (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)">
                  </combo-plugin>
                </div>
               
                <div class="field" [class.tooltip]="
                  model['tsEta'] != '' && model['tsEta'] != 'null'
                ">
                  <label>ETA</label>
                  <input type="text" name="eta" placeholder="" [(ngModel)]="model['tsEta']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsEta"] }}</span>
                </div>
                <div class="field" [class.tooltip]="
                  model['tsEtd'] != '' && model['tsEtd'] != 'null'
                ">
                  <label>ETD</label>
                  <input type="text" name="etd" placeholder="" [(ngModel)]="model['tsEtd']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsEtd"] }}</span>
                </div>
              </div>
            </div>

          </div>

          <div class="wide field">
            <div class="ui raised segment" style="padding-top: 40px;height: 100%;">
              <div class="field">
                <div class="ui radio checkbox disabled">
                  <input type="radio" name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'"
                    (change)="changeEventTransmit($event)" value="SOC" disabled>
                  <label>SOC</label>
                </div>
                <div class="ui radio checkbox">
                  <input type="radio" name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'"
                    (change)="changeEventTransmit($event)" value="COC">
                  <label style="color: green;font-weight: bold;">COC</label>
                </div>
              </div>
            </div>
          </div>


          <div class="three wide field">
            <div class="ui raised segment" style="padding-top: 40px;">
              <div class="three fields">
                <div class="field">
                  <label style="color: green;">TOTAL:</label>
                  <input type="text" name="total" [(ngModel)]="model['tsTotal']" style="background-color: lightgray"
                    disabled>
                </div>

                <div class="field">
                  <label style="color: green;">LOCAL:</label>
                  <input type="text" name="local" [(ngModel)]="model['tsLocal']" style="background-color: lightgray"
                    disabled>
                </div>

                <div class="field">
                  <label style="color: green;">T/S:</label>
                  <input type="text" name="T/S" [(ngModel)]="model['tsTS']" style="background-color: lightgray"
                    disabled>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="six fields">
          <div class="six wide field">
            <div class="ui raised segment">
              <a class="ui red ribbon label">Rotation</a>
              <div class="one fields">
                <form class="field" style="width: 100%;padding-top: 5px;">
                  <fieldset style="width: 100%; border: 1px solid gray; min-height: 33px;">
                    <label style="float: left; font-weight: bold">{{ model.tsRotation }}</label>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width:100%; margin-top: 50px;">
        <app-custom-ag-grid-angular #topGrid id="transaction-ts-port-pair-allocation-page-v2"
          [getRowStyle]="getRowStyle" [rowData]="rowData" [rowMultiSelectWithClick]="true" [defaultColumnDefs]="defaultColumnDefs"
          [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)" (selectionChanged)="onSelectionChanged($event)"
          [rowSelection]="'multiple'" [pinnedBottomRowData]="pinnedBottomRowData" [isRowSelectable]="isRowSelectable">
        </app-custom-ag-grid-angular>
      </div>

    </form>
  </div>
-->
</form>

<footer-plugin [info]="info"></footer-plugin>
