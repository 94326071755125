import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { ReportPrint,officeModel } from '../shared/index';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $: any;

export class ReportParam {
  periodFrom = ''
  periodTo = ''
  constructor() { }
}

@Component({
  selector: 'app-report-booking-deleted-page',
  templateUrl: './report-booking-deleted-page.component.html',
  styleUrls: ['./report-booking-deleted-page.component.css']
})

export class ReportBookingDeletedPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  @ViewChild('cbTranshipmentPort') cbTranshipmentPort: ComboPluginComponent;
  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;
  isError = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  settingTranshipmentPort;
  validatorRules = {
    vesselId: {
      rules: [{
        type  : 'empty',
        prompt: 'Please input Vessel.'
        }]
    },
    voyage: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Voyage.'
      }]
    },
    bound: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Bound.'
      }]
    },
    /*
    pol: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Loading Port.'
      }]
    },
    */
  };

  param = new ReportParam();
  office = new officeModel;

  constructor(private _http: HttpClient,private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Deleted Booking Report'
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [

        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingTranshipmentPort = {
      id          : 'cbTranshipmentPort',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }


  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.param = new ReportParam();
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });
  }

  toolbarEvent(event) {
    // // console.log('event : ' + event);
    switch (event) {
      case 'print' :
        this.handlePrint();
        break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }





  handleCancel() {
    this.isError = false;
    $('[data-toggle="datepicker"]').datepicker('reset');
    this.param = new ReportParam();
    //this.cbVessel.setUrl('');
   // this.cbVessel.setForceValue('');
    //this.cbVoyage.setUrl('');
   // this.cbVoyage.setForceValue('');
    //this.cbLoadPort.setForceValue('');
  }

  handlePrint() {
    this.resetError();

    this.isError = this.onValidate(this.param);
    // console.log('this.iserror => ' + this.isError);
//    if (!this.isError) {
      this.loading = true;
      //const urlHit = 'http://glossys.samudera.com:14000' + '/BookingReport/bookingDeletedReport/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val()
      const urlHit = this.configService.config.BASE_API.toString()+ '?q=/BookingReport/bookingDeletedReport/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val()+'/'+this.cookieService.getDefaultLocationCode()
      //window.open(urlHit);

      this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{

        console.log(res.size);

        if(res.size > 4138)
            FileSaver.saveAs(res, 'Deleted Booking Report.xlsx');
        else
            this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});

        this.loading = false;
    });

  //  }
  }

  resetError(){
    this.param['error-vesselId'] = '';
    this.param['error-voyage'] = '';
    this.param['error-bound'] = '';
    this.param['error-pol'] = '';

  }
}
