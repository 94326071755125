export class MasterBooking {
    bOfficeCode : string='';
    bPrefix : string='';
    bNo : string = '';
    bStatus : string='';
    bIsFinished : string='';

    bOceanVesselId  : string = '';
    bOceanVesselCode : string='';
    bOceanVesselName : string='';
    bOceanVesselVoyage : string='';
    bOceanVesselBound : string = '';
    bShipmentType : string='';
    bContainerOwnership : string='';

    constructor() { 
      
    }
}