import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService,CalendarPluginComponent, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

export class reportParam{
  placeOfCreation:string = '';
  fromDate:string = '';
  toDate:string = '';
  userId:string = '';
  containerOwnership:string ='';

  listContainerOwnership = [];

  reportType:string = 'PDF';

  constructor(){
  }
}
@Component({
  selector: 'app-report-operation-booking-forecast-daily-vessel-page',
  templateUrl: './report-operation-booking-forecast-daily-vessel-page.component.html',
  styleUrls: ['./report-operation-booking-forecast-daily-vessel-page.component.css']
})
export class ReportOperationBookingForecastDailyVesselPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  // @ViewChild('dateLocalETA') dateLocalETA:CalendarPluginComponent;
  // @ViewChild('dateLocalETD') dateLocalETD:CalendarPluginComponent;
  @ViewChild('cbPOC') cbPOC:ComboPluginComponent;
  @ViewChild('dailyBookingProcessPDFContainer') pdfContainer: PdfPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPOC;
  settingDatePicker;
  settingPDF;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';

  radioContainerOwnerSOC = "N";
  radioContainerOwnerCOC = "Y";
  radioContainerOwnerSLOT = "N";

  ckCOCLOCAL:boolean = false;
  ckCOCTS:boolean = false;
  ckSOCLOCAL:boolean = false;
  ckSOCTS:boolean = false;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  lock = true;

  model = new reportParam;
  hitUrl = this.configService.config.BASE_API.toString();
  user = '';

  validatorRules = {};

  listContainerOwner = [];


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Generate', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Booking Forecast (Daily Vessels) Report'
    }

    this.settingPOC = {
      id          : 'cbPOC',
      type        : 'search enter', // search | select | select input
      url         :  this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingDatePicker = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingPDF = {
      id : 'dbProgressReportPDFCntry',
      url : '',
      hidePrint : false,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.dateLocalETA.initCalendar("");
    // this.dateLocalETD.initCalendar("");
    this.user = this.cookieService.getName();
    this.model = new reportParam;

    this.cbPOC.setForceValue(this.cookieService.getDefaultLocationName())
    this.model.placeOfCreation = this.cookieService.getDefaultLocationCode()

    this.model.containerOwnership = "COC";
    this.ckCOCLOCAL = true;
    this.ckCOCTS = true;

    this.listContainerOwner = ["COC", "COCLOCAL", "COCTS"];

    var self = this;
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    }).on('pick.datepicker', function(e){
      //console.log('calendar hide');
      //console.log(e);
      if(e.target.id == 'fromDate'){
        //console.log('fromdate ' + $(this).datepicker('getDate', true));
        self.setDate('from', $(this).datepicker('getDate', true));
      } else if (e.target.id == 'toDate') {
        //console.log('toDate ' + $(this).datepicker('getDate', true));
        self.setDate('to', $(this).datepicker('getDate', true));
      }
    });
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'print' :
        this.printDailyBookingProgress();
        break;
      case "cancel" :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventPOC(event){
    console.log(event)
    if(event.locationId != ""){
      this.model.placeOfCreation = event.locationCode;
    } else {
      this.model.placeOfCreation = '';
    }
  }

  eventRadio(event,val){
    //console.log(event);
    var checked = event.srcElement.checked;

    if(checked){
      this.model.reportType = val;
    }

  }

  setDate(type, val){
    if(type == "from"){
      this.model.fromDate = val + " 00:12:00";
    } else if(type == "to"){
      this.model.toDate = val + " 00:12:00";
    }
  }

  printDailyBookingProgress(){
    console.log("print");
    console.log(this.model);

    if(this.model.placeOfCreation == ''){
      this.message("information","Information","Please insert Place of creation", "okonly",{ok:""});
    } else if(this.model.fromDate == ""){
      this.message("information","Information","Please insert Vessel ETA From", "okonly",{ok:""});
    } else if(this.model.toDate == ""){
      this.message("information","Information","Please insert Vessel ETA TO", "okonly",{ok:""});
    } else {
      this.loading = true;
      console.log("tempat print an")
      console.log(this.listContainerOwner)
      this.model.listContainerOwnership = this.listContainerOwner;
      this.model.userId = this.cookieService.getName();

      console.log(this.model)
        var hitUri = this.hitUrl + "/BookingReport/createReportForecastDailyVessel";
        this.genericService.POST(hitUri, this.model).subscribe((resp)=>{
            if(resp.ok){
              var result = resp.json();
              if(parseInt(result["total-data"]) > 0){
                      console.log("#1");
                      console.log(result['fromDate']);
                      var hitUrl = this.hitUrl  + '/edi-util/getFile';
                      var jasperURL =  this.configService.config.jasperUrl.toString() + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/OperationBookingForecastDailyVesselReport.pdf?FromDate='+result['fromDate']+"&ToDate="+result['toDate']+"&keyCode="+this.user;
                      var fileName = 'Operation-Booking-Forecast-Daily-Vessel-Report-'+this.user+'.pdf';


                      var postData = {};
                      postData["fileName"] = fileName;
                      postData["url"] = jasperURL;

                      console.log(postData["url"])
                      console.log(this.model.reportType)
                    if(this.model.reportType == "PDF"){
                      this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
                        console.log(resp);
                        if(resp.ok){
                          var result = resp.json();

                          console.log(result["message"])
                          if(result["message"] == "success"){
                            console.log("masuk");
                            console.log(result["filePath"]);
                            this.pdfContainer.setPDFURL(this.configService.config.pdfUrl+result["filePath"]);
                            this.pdfContainer.showPDF();
                          }

                          this.loading = false;
                        }
                      });
                    } else if(this.model.reportType == "XLS"){
                      jasperURL = jasperURL.replace(".pdf",".xls");
                      window.open(jasperURL);
                      this.loading = false;
                    }
            } else {
                    this.message('information','Information','No Data Found.', 'okonly',{ok:''});
                    this.loading = false;
            }
          }
      });
    }
  }



  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      //this.disableToolbarButtons = 'print';
    }
  }

  handleCancel(){
    this.model = new reportParam;

    this.model.placeOfCreation = this.cookieService.getDefaultLocationCode();

    $("#fromDate").val("");
    $("#toDate").val("");
    this.checkedRadio("pdf", true);
    this.checkedContainer("COC", true);

    console.log(this.ckCOCLOCAL)
    this.ckCOCLOCAL = false;
    this.ckCOCTS = false;
    this.ckSOCLOCAL = false;
    this.ckSOCTS = false;

  };

  checkedRadio(val, checked:boolean){
    $("#rType[value="+val+"]").prop('checked', checked);
  }

  checkedContainer(val, checked:boolean){
    $("#containerOwner[value="+val+"]").prop('checked', checked);
  }

  changeEventTransmit(event){

    if(this.listContainerOwner != []){
      this.ckCOCLOCAL = false;
      this.ckCOCTS = false;
      this.ckSOCLOCAL = false;
      this.ckSOCTS = false;

      this.listContainerOwner = [];
    }

    if(event.target.defaultValue == "SOC"){
      this.ckSOCLOCAL = true;
      this.ckSOCTS = true;
      this.ckCOCLOCAL = false;
      this.ckCOCTS = false;

      this.listContainerOwner.push(event.target.defaultValue, "SOCLOCAL", "SOCTS");
    }else if(event.target.defaultValue == "COC"){
      this.ckCOCLOCAL = true;
      this.ckCOCTS = true;
      this.ckSOCLOCAL = false;
      this.ckSOCTS = false;

      this.listContainerOwner.push(event.target.defaultValue, "COCLOCAL", "COCTS");
    }else{
      this.listContainerOwner.push(event.target.defaultValue);
    }

    console.log("isi list")
    console.log(this.listContainerOwner);
  }

  changeEventContainerOwner(event){

  }

  changeEventCOCLOCAL(event){
    if(event.target.checked){
      this.checkedContainer("COC", true);

      this.ckCOCLOCAL= true;
      this.ckSOCLOCAL = false;
      this.ckSOCTS = false;

      console.log("isi pertama")
      console.log(this.listContainerOwner)
      var tempList = this.listContainerOwner;

      this.listContainerOwner = []
      if(tempList != []){
          tempList.forEach(list=>{
            if(list == "COC" || list == "COCTS"){
              this.listContainerOwner.push(list);
            }
          });
      }

      this.listContainerOwner.push(event.target.defaultValue);
      console.log("isi di dalem coc local")
      console.log(this.listContainerOwner)


      //OPTIONAL
      this.listContainerOwner.forEach(list=>{
        if(list == "COCTS"){
          this.listContainerOwner.push("COC");
        }
      })
    }else{
      this.listContainerOwner.forEach(list=>{
        if(list == "COCLOCAL"){
          this.listContainerOwner.splice(this.listContainerOwner.indexOf(list), 1);
        }
      })
      console.log("list")

      console.log(this.listContainerOwner)

    }
  }
  changeEventCOCTS(event){
    if(event.target.checked){
      this.checkedContainer("COC", true);

      this.ckSOCLOCAL = false;
      this.ckSOCTS = false;

      this.ckCOCTS= true;

      console.log("isi pertama")
      console.log(this.listContainerOwner)
      var tempList = this.listContainerOwner;

      this.listContainerOwner = []
      if(tempList != []){
          tempList.forEach(list=>{
            if(list == "COC" || list == "COCLOCAL"){
              this.listContainerOwner.push(list);
            }
          });
        }

      this.listContainerOwner.push(event.target.defaultValue);
      console.log("isi di dalem coc ts")
      console.log(this.listContainerOwner)

      //OPTIONAL
      this.listContainerOwner.forEach(list=>{
        if(list == "COCLOCAL"){
          this.listContainerOwner.push("COC");
        }
      })
    }else{
      this.listContainerOwner.forEach(list=>{
        if(list == "COCTS"){
          this.listContainerOwner.splice(this.listContainerOwner.indexOf(list), 1);
        }
      })
      console.log("list")

      console.log(this.listContainerOwner)

    }
  }

  changeEventSOCLOCAL(event){
    if(event.target.checked){
      this.checkedContainer("SOC", true);
      this.ckSOCLOCAL = true ;
      this.ckCOCLOCAL = false;
      this.ckCOCTS = false;

      console.log("isi pertama")
      console.log(this.listContainerOwner)
      var tempList = this.listContainerOwner;

      this.listContainerOwner = []
        if(tempList != []){
          tempList.forEach(list=>{
            if(list == "SOC" || list == "SOCTS"){
              this.listContainerOwner.push(list);
            }
          });
        }

      this.listContainerOwner.push(event.target.defaultValue);
      console.log("isi di dalem soc local")
      console.log(this.listContainerOwner)

      //OPTIONAL
      this.listContainerOwner.forEach(list=>{
        if(list == "SOCTS"){
          this.listContainerOwner.push("SOC");
        }
      })
    }else{
      this.listContainerOwner.forEach(list=>{
        if(list == "SOCLOCAL"){
          this.listContainerOwner.splice(this.listContainerOwner.indexOf(list), 1);
        }
      })
      console.log("list")

      console.log(this.listContainerOwner)

    }
  }

  changeEventSOCTS(event){
    if(event.target.checked){
      this.checkedContainer("SOC", true);
      this.ckSOCTS = true ;
      this.ckCOCLOCAL = false;
      this.ckCOCTS = false;

      console.log("isi pertama")
      console.log(this.listContainerOwner)
      var tempList = this.listContainerOwner;

      this.listContainerOwner = []
        if(tempList != []){
          tempList.forEach(list=>{
            if(list == "SOC" || list == "SOCLOCAL"){
              this.listContainerOwner.push(list);
            }
          });
        }
      this.listContainerOwner.push(event.target.defaultValue);
      console.log("isi di dalem soc ts")
      console.log(this.listContainerOwner)

      //OPTIONAL
      this.listContainerOwner.forEach(list=>{
        if(list == "SOCLOCAL"){
          this.listContainerOwner.push("SOC");
        }
      })
    }else{
      this.listContainerOwner.forEach(list=>{
        if(list == "SOCTS"){
          this.listContainerOwner.splice(this.listContainerOwner.indexOf(list), 1);
        }
      })
      console.log("list")

      console.log(this.listContainerOwner)

    }
  }
}


