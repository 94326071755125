<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Local Master Adhoc Charge
        </h4>
        <grid-plugin
          #chargeGrid
          [settings]="settingGrid"
          (gridEvent)="chargeGridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Local Master Adhoc Charge
            </h4>
            <div class="ui error message" style="display: block;" *ngIf="isInvalidChargeValue">
              <ul>
                <li>
                  Please input at least 1 charge value
                </li>
              </ul>
            </div>
            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="two fields">
                  <div class="field">
                    <div class="required field"
                      [class.error]="model['error-chargeName'] != null && model['error-chargeName'] != ''">
                      <label>Charge Name</label>
                      <combo-plugin #cbChargeName [settings]="settingChargeName" (change)="changeEventChargeName($event)"
                        (keypress)="formValidation.ModifiedPattern($event, '-.()&\'/ ')"
                        (keyup)="backspaceValue($event,formValidation.regName, 'chargeName', false, 'and -./')"
                        (paste)="onPasteValidation(
                        $event,
                        'chargeName',
                        formValidation.regName,
                        false, 'and -(.)&\'')"
                        [disableCombo]="lock == true">
                      </combo-plugin>
                      <div class="ui basic red pointing prompt label transition"
                        *ngIf="model['error-chargeName'] != null && model['error-chargeName'] != ''">
                        {{model['error-chargeName']}}
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="required field" [class.error]="model['error-chargeCode'] != null && model['error-chargeCode'] != ''">
                      <label>Charge Code</label>
                      <combo-plugin #cbChargeCode [settings]="settingChargeCode" (change)="changeEventChargeCode($event)"
                        (keyup)="backspaceValue($event,formValidation.regAlphaNumeric, 'chargeCode', false, 'and -./')"
                        (keypress)="formValidation.ModifiedPattern($event)"
                        (paste)="
                          onPasteValidation(
                            $event,
                            'chargeCode',
                            formValidation.regAlphaNumeric
                          )
                        "
                        [disableCombo]="lock == true">
                      </combo-plugin>
                      <div class="ui basic red pointing prompt label transition"
                        *ngIf="model['error-chargeCode'] != null && model['error-chargeCode'] != ''">
                        {{model['error-chargeCode']}}
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="required field"
                    [class.error]="
                      model['error-chargeName'] != null &&
                      model['error-chargeName'] != ''
                    "
                  >
                    <label>Charge Name</label>
                    <input
                      type="text"
                      [(ngModel)]="model['chargeName']"
                      (ngModelChange)="
                        checkIfError('chargeName', regChargeName)
                      "
                      name="chargeName"
                      placeholder="Charge Name"
                      [disabled]="lock == true"
                      maxlength="30"
                      (keypress)="
                        formValidation.ModifiedPattern($event, '-.()&\' ')
                      "
                      (paste)="
                        onPasteValidation(
                          $event,
                          'chargeName',
                          regChargeName,
                          'and -(.)&\''
                        )
                      "
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-chargeName'] != null &&
                        model['error-chargeName'] != ''
                      "
                    >
                      {{ model["error-chargeName"] }}
                    </div>
                  </div> -->

                  <!-- <div
                    class="required field"
                    [class.error]="
                      model['error-chargeCode'] != null &&
                      model['error-chargeCode'] != ''
                    "
                  >
                    <label>Charge Code</label>
                    <input
                      type="text"
                      [(ngModel)]="model['chargeCode']"
                      (ngModelChange)="
                        checkIfError(
                          'chargeCode',
                          formValidation.regAlphaNumeric
                        )
                      "
                      name="chargeCode"
                      placeholder="Charge Code"
                      [disabled]="lock == true"
                      maxlength="10"
                      (keypress)="formValidation.ModifiedPattern($event)"
                      (paste)="
                        onPasteValidation(
                          $event,
                          'chargeCode',
                          formValidation.regAlphaNumeric
                        )
                      "
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-chargeCode'] != null &&
                        model['error-chargeCode'] != ''
                      "
                    >
                      {{ model["error-chargeCode"] }}
                    </div>
                  </div> -->
                  <div class="field">
                    <div class="required field" [class.error]="model['error-currencyCode'] != null && model['error-currencyCode'] != ''">
                      <label>Currency</label>
                      <combo-plugin #cbCurrency [settings]="settingCurrency" (change)="changeEventCurrency($event)"
                        (keyup)="backspaceValue($event,formValidation.regAlphaNumeric, 'currencyCode', false, 'and -./')"
                        (keypress)="formValidation.ModifiedPattern($event)" (paste)="
                                      onPasteValidation(
                                        $event,
                                        'currencyCode',
                                        formValidation.regAlphaNumeric
                                      )
                                    "
                        [disableCombo]="lock == true">
                      </combo-plugin>
                      <div class="ui basic red pointing prompt label transition"
                        *ngIf="model['error-currencyCode'] != null && model['error-currencyCode'] != ''">
                        {{model['error-currencyCode']}}
                      </div>
                    </div>
                  </div>

                  <!-- <div class="required field" [class.error]="model['error-currency'] != null && model['error-currency'] != ''">
                    <label>Currency</label>
                    <input type="text"
                      [(ngModel)]="model['currency']"
                      (ngModelChange)="checkIfError('currency', formValidation.regAlphaNumeric)"
                      name="currency"
                      placeholder="Currency"
                      [disabled]="lock == true"
                      maxlength="10"
                      (keypress)="formValidation.ModifiedPattern($event)"
                      (paste)="
                        onPasteValidation(
                            $event,
                            'currency',
                            formValidation.regAlphaNumeric
                          )
                        " />
                    <div class="ui basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                          " [class.error-message-visible]="
                          model['error-currency'] != null &&
                          model['error-currency'] != ''
                          ">
                      {{ model["error-currency"] }}
                    </div>
                  </div> -->

                  <div class="field" style="display: flex;align-items: center;">
                    <div class="ui test toggle checkbox" [class.disabled]="lockManifest">
                      <input type="checkbox" [disabled]="lockManifest" [(ngModel)]="model['isManifested']" name="isManifested" tabindex="0" class="hidden" />
                      <label>Manifest</label>
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field" style="width: 50%;">
                    <label>Container Type</label>
                    <combo-plugin #cbBlContainerContainerType [settings]="settingCbBlContainerContainerType"
                      (change)="changeEventCbBlContainerContainerType($event)" [disableCombo]="lock == true"></combo-plugin>
                  </div>
                  <div class="one wide fields" style="width: 45%">
                    <div class="one field" [class.error]="
                                      model['error-unitContainerValidator'] != null &&
                                      model['error-unitContainerValidator'] != ''
                                    " style="width: 50%">
                      <label>D20</label>
                      <input type="number" style="
                                        padding-left: 1px;
                                        padding-right: 1px;
                                        text-align: right;
                                      " [disabled]="lock == true" [(ngModel)]="d20" name="20" min="0" (change)="changeEventD20($event)"
                        (keyup)="changeEventD20($event)" />
                    </div>
                    <div class="one field" [class.error]="
                                      model['error-unitContainerValidator'] != null &&
                                      model['error-unitContainerValidator'] != ''
                                    " style="width: 50%">
                      <label>H20</label>
                      <input type="number" style="
                                        padding-left: 1px;
                                        padding-right: 1px;
                                        text-align: right;
                                      " [disabled]="lock == true" [(ngModel)]="h20" name="h20" min="0"
                        (change)="changeEventH20($event)" (keyup)="changeEventH20($event)" />
                    </div>
                    <div class="one field" [class.error]="
                                              model['error-unitContainerValidator'] != null &&
                                              model['error-unitContainerValidator'] != ''
                                            " style="width: 50%">
                      <label>D40</label>
                      <input type="number" style="
                                              padding-left: 1px;
                                              padding-right: 1px;
                                              text-align: right;
                                            " [disabled]="lock" [(ngModel)]="d40" name="d40"
                        min="0" (change)="changeEventD40($event)" (keyup)="changeEventD40($event)" />
                    </div>
                    <div class="one field" [class.error]="
                                              model['error-unitContainerValidator'] != null &&
                                              model['error-unitContainerValidator'] != ''
                                            " style="width: 50%">
                      <label>H40</label>
                      <input type="number" style="
                                              padding-left: 1px;
                                              padding-right: 1px;
                                              text-align: right;
                                            " [disabled]="lock" [(ngModel)]="h40"
                        name="h40" min="0" (change)="changeEventH20($event)" (keyup)="changeEventH20($event)" />
                    </div>
                    <div class="one field" [class.error]="
                                                model['error-unitContainerValidator'] != null &&
                                                model['error-unitContainerValidator'] != ''
                                              " style="width: 50%">
                      <label>D45</label>
                      <input type="number" style="
                                                padding-left: 1px;
                                                padding-right: 1px;
                                                text-align: right;
                                              " [disabled]="lock" [(ngModel)]="d45"
                        name="d45" min="0" (change)="changeEventH20($event)" (keyup)="changeEventH20($event)" />
                    </div>
                    <div class="one field" [class.error]="
                                              model['error-unitContainerValidator'] != null &&
                                              model['error-unitContainerValidator'] != ''
                                            " style="width: 50%">
                      <label>H45</label>
                      <input type="number" style="
                                              padding-left: 1px;
                                              padding-right: 1px;
                                              text-align: right;
                                            " [disabled]="lock" [(ngModel)]="h45"
                        name="h45" min="0" (change)="changeEventH20($event)" (keyup)="changeEventH20($event)" />
                    </div>
                  </div>
                  <!-- <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isMainCharge']"
                        name="isMainCharge"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Main Charge</label>
                    </div>
                  </div> -->

                  <!-- <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isManifested']"
                        name="isManifested"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Manifest</label>
                    </div>
                  </div> -->
                </div>

                <!-- <div class="two fields">
                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isSurcharge']"
                        name="isSurcharge"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Surcharge</label>
                    </div>
                  </div>

                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isShownInReport']"
                        name="isShownInReport"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Show in Report</label>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="two fields">
                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isAdhoc']"
                        name="isAdhoc"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Ad Hoc Charge</label>
                    </div>
                  </div>
                </div> -->

                <div
                  class="field"
                  [class.error]="
                    model['error-remarks'] != null &&
                    model['error-remarks'] != ''
                  "
                >
                  <label>Remarks</label>
                  <textarea
                    [(ngModel)]="model['remarks']"
                    (ngModelChange)="model['error-remarks'] = ''"
                    name="remarks"
                    placeholder="Input your remarks..."
                    [disabled]="lock == true"
                    maxlength="200"
                    (keypress)="formValidation.AllChar($event)"
                  ></textarea>
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-remarks'] != null &&
                      model['error-remarks'] != ''
                    "
                  >
                    {{ model["error-remarks"] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Local Master Adhoc Charge
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Charge</a>

              <div class="active content">
                <div class="one fields" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateChargeHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-chargeName'] != null &&
                      model['error-chargeName'] != ''
                    "
                  >
                    <label>Charge Name</label>
                    <combo-plugin
                      #cbChargeNameHistory
                      [settings]="settingChargeHistory"
                      (change)="valueChargeHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-chargeName'] != null &&
                        model['error-chargeName'] != ''
                      "
                    >
                      {{ model["error-chargeName"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-chargeCode'] != null &&
                      model['error-chargeCode'] != ''
                    "
                  >
                    <label>Charge Code</label>
                    <input
                      type="text"
                      [value]="valChargeCode"
                      name="chargeHistoryCode"
                      placeholder="Charge Code"
                      [disabled]="lock == true"
                      maxlength="10"
                      readonly
                      style="background-color: lightgray"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-chargeCode'] != null &&
                        model['error-chargeCode'] != ''
                      "
                    >
                      {{ model["error-chargeCode"] }}
                    </div>
                  </div>
                </div>
                <!--
                <div class="two fields">
                  <div class="field">
                    <label>Log Start Date</label>
                    <calendar-plugin id="logHistoryChargeStartDate" [pluginType]="typeChargeLogStartDate" [settings]="settingChargeLogStartDate"></calendar-plugin>
                  </div>
                  <div class="field">
                    <label>Log End Date</label>
                    <calendar-plugin id="logHistoryChargeEndDate" [pluginType]="typeChargeLogEndDate" [settings]="settingChargeLogEndDate"></calendar-plugin>
                  </div>
                </div>
                -->
                <div class="field">
                  <grid-plugin
                    #chargeGridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="chargeGridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
