export class blfreightmatch  {
  no : number = 0;
  clauseId    : string = '';
  clauseCode  : string = '';
  clauseName  : string = '';
  remarks     : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  vesselId : string ='';
  vesselCode : string ='';
  vesselName : string ='';
  bOceanVesselVoyageDes: string ='';
  voyage : string ='';
  bound : string ='';

  bOceanVesselVoyage : string ='';
  bOceanVesselBound: string ='';

  blNo:string='';
  blStatus:string='';
  blShipperName:string='';
  blConsigneeName:string='';
  blNotifyPartyName:string='';
  blReceiptType:string='';
  blDeliveryType:string='';
  blDischargePort:string='';
  blIsFinished:string='';
  blPortOfTransshipment1code:string='';
  blUserCreated:string='';
  blDateCreated:string='';
  blUserModified:string='';
  blLoadPortCode:string='';
  blDateModified:string='';
  blDischargePortCode:string='';
  blPortOfDelivery:string='';
  blFinalDestination:string='';
  blIsLocked:string='';


  constructor () { }
}
