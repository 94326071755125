export class MServiceDetail{
    serviceId: string='';
    serviceCode: string ='';
    serviceSeq: number;
    seqNo: number;
    portId:string='';
    portCode: string ='';
    portName: string ='';
    terminalId: string='';
    terminalCode: string ='';
    terminalName: string ='';
    fmPortHours: number =0;
    fmPortHourTemp: string ='0';
    fmPortDaysHours: string = '0' ;
    atPortHours:number = 0 ;
    atPortHourTemp:string = '0' ;
    atPortDaysHours:string = '0' ;
    callReason: string ='';
    callReasonCode: string='';
    userCreated: string ='';
    userModified: string ='';
    userInvalid: string='';     
    
    constructor() { 
      
    }
}
