<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">Stage 1 : Auto Matching of OBOP vs BL</label>
        <br /><br />
        <div class="ui equal width padded grid">
          <!-- start row -->
          <div class="eight wide field">
            <label style="font-size:12px">FILE to be Processed</label>
            <input type="file" id="myfile" style="display:none">
            <div class="inline field">
              <!-- button class="ui primary mini button" id="iduploadFile" (click)="uploadProcess();">Upload</button>
              <label style="font-size:12px">{{model.fileName}}</label -->
              <upload-plugin #obopMatchContainer [settings]="settingUploadObopMCFile" (eventUpload)="uploadEventObopMCFile($event)"></upload-plugin>
            </div>                        
          </div>
          
          <!-- <div class="eight wide field">
            <label style="font-size:12px">PM4 to be Processed</label>
            <input type="file" id="myfile2" style="display:none">
            <div class="inline field">
              <button class="ui primary mini button" id="uploadPM4" (click)="uploadPM4();">Upload</button>
              <label style="font-size:12px">{{model.pm4FileName}}</label>
            </div>
          </div>-->
          
          <div class="sixteen wide inline field">
            <label style="font-size:12px">File type</label>
           
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="frequency" [(ngModel)]="model.fileType" value = "OBOPMC" (ngModelChange)="radioChange($event)">
                <label style="font-size:12px">OBOP (Matching of containers only)</label>
              </div>
            </div>
           
          </div>
          <!--<div class="sixteen wide inline fields">
            <div class="field">
              <div class="ui checkbox">
                <input type="checkbox" name="isBLCreated" [checked]="model.isCreateBL" [disabled]="isCreateDisabled">
                <label style="font-size:12px">Proceed to creation of Bill of Lading (BL) after auto-matching ?</label>
              </div>
            </div>
          </div>-->

          <div class="sixteen wide field">
            <label style="font-size:12px">Loader</label>
            <div class="ui input">
              <input type="text" name="secondCarrier" [(ngModel)] = "model.secondCarrier">
            </div>            
          </div>
          
          <div class="sixteen wide field">
            <label style="font-size:12px">Instruction</label>
            <textarea name="instruction" style="font-size:12px">{{model.messageValue}}
            </textarea>
          </div>
          <!-- end of row -->
        </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>

<pdf-plugin #obopMCPdfContainer style="margin-left:3px;" [settings]="settingObopPDFMC" (change)="eventObopPDFMC($event);"></pdf-plugin>

<new-dialog #dialogObopMCD style="margin-left:3px;" [settings]="settingDialogOBOPMC" (eventDialogs)="eventdialogObopMC($event)"></new-dialog>

<footer-plugin [info]="info"></footer-plugin>