<div class="ui segment">
    <div class="ui divided items">

        <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar"
            [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons"
            (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
        <!-- <calendar-plugin [modeCalender]="modeCalender" [settings]="settingCalender"></calendar-plugin>-->
        <!-- <combo-plugin [settings]="settingFormType" style="width:100%;"></combo-plugin>-->
        <div [style.display]="modeForm?'none':'block'">
            <div class="ui segment">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index: 1">
                    <div class="ui medium text loader">Loading</div>
                </div>
                <div style="text-align:center;">
                    <h4>Region Master</h4>
                </div>
                <!-- Begin of Grid -->
                <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)"
                    (infoGrid)="infoGrid($event)"></grid-plugin>
                <!-- End of Grid -->
            </div>
        </div>

        <div [style.display]="modeForm?'block':'none'">
            <!--  <h4  style="text-align:center;">Region Master</h4>-->

            <div class="ui segment" style="padding-left:1%;padding-right:20%;">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                    <div class="ui medium text loader">Loading</div>
                </div>

                <div id='container'>

                    <!-- Begin of Form 1-->
                    <form class="ui form" name="General" [style.display]="form == ''?'block':'none'">
                        <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Regions</h4>
                        <div class="field">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                                <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0"
                                    class="hidden">
                                <label>Valid?</label>
                            </div>
                        </div>


                        <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>


                        <div class="ui styled accordion field" style="width:100%; height: 50%;">
                            <div class="active title">
                                <i class="icon dropdown"></i>
                                General
                            </div>
                            <div class="active content">
                                <div class="two fields">
                                    <div class="required field"
                                        [class.error]="model['error-regionName'] != null && model['error-regionName'] != ''">
                                        <label>Region Name</label>
                                        <input type="text" [(ngModel)]="model['regionName']" name="regionName"
                                            placeholder="" [disabled]="lock == true" maxlength="50">
                                        <div class="ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-regionName'] != null && model['error-regionName'] != ''">
                                            {{model['error-regionName']}}
                                        </div>
                                    </div>
                                    <div class="required field"
                                        [class.error]="model['error-regionCode'] != null && model['error-regionCode'] != ''">
                                        <label>Region Code</label>
                                        <input type="text" [(ngModel)]="model['regionCode']" name="regionCode"
                                            placeholder="" [disabled]="lock == true" maxlength="3" style="width:100%;"
                                            (keypress)="noneSpace($event)">
                                        <div class="ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-regionCode'] != null && model['error-regionCode'] != ''">

                                            <p [innerHtml]="model['error-regionCode']"></p>
                                        </div>
                                    </div>


                                </div>
                                <div class="two fields">
                                    <div class="field">
                                        <label>Region Position</label>
                                        <input type="number" [(ngModel)]="model['regionPosition']" name="regionPosition"
                                            placeholder="0" [disabled]="lock == true" maxlength="4" min="0"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                        <div class="ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-regionPosition'] != null && model['error-regionPosition'] != ''">
                                            {{model['error-regionPosition']}}
                                        </div>

                                    </div>

                                    <div class="field">
                                        <label>Trade Liner Code</label>
                                        <input type="text" [(ngModel)]="model['linerTradeCode']" name="linerTradeCode"
                                            placeholder="" [disabled]="lock == true" maxlength="10">

                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                                        <input type="checkbox" [(ngModel)]="model['exception']" name="exception"
                                            tabindex="0" class="hidden">
                                        <label>Exception</label>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <!-- End of Form 1-->
                        <br>

                        <div class="ui styled accordion field" style="width:100%">
                            <div class="title">
                                <i class="icon dropdown"></i>
                                Misc
                            </div>
                            <div class="active content">
                                <div class="two fields">
                                    <div class="field">
                                        <label>Created By</label>
                                        <input type="text" [(ngModel)]="model['userCreated']" name="userCreated"
                                            placeholder="Created By" readonly
                                            style="background-color:lightgray;width:100%;">

                                    </div>

                                    <div class="field">
                                        <label>Created Date</label>
                                        <input type="text" [(ngModel)]="model['dateCreated']" name="dateCreated"
                                            placeholder="Created Date" readonly
                                            style="background-color:lightgray;width:100%;">

                                    </div>
                                </div>
                                <div class="two fields">
                                    <div class="field">
                                        <label>Last Modified By</label>
                                        <input type="text" [(ngModel)]="model['userModified']" name="userModified"
                                            placeholder="Last Modified By" readonly
                                            style="background-color:lightgray;width:100%;">
                                    </div>

                                    <div class="field">
                                        <label>Last Modified Date</label>
                                        <input type="text" [(ngModel)]="model['dateModified']" name="dateModified"
                                            placeholder="Modified Date" readonly
                                            style="background-color:lightgray;width:100%;">

                                    </div>
                                </div>
                                <div class="two fields">

                                    <div class="field">
                                        <label>Invalid By</label>
                                        <input type="text" [(ngModel)]="model['userValid']" name="userValid"
                                            placeholder="" readonly style="background-color:lightgray;width:100%;">
                                    </div>

                                    <div class="field">
                                        <label>Invalid Date</label>
                                        <input type="text" [(ngModel)]="model['dateValid']" name="dateValid"
                                            placeholder="" readonly style="background-color:lightgray;width:100%;">

                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                    <!--End of Form 2-->

                    <!--Begin of History Form-->
                    <form class="ui form" [style.display]="form == 'history'?'block':'none'">
                        <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Master Regions
                        </h4>


                        <div class="ui raised segment">
                            <a class="ui red ribbon label">Select Region</a>
                            <div class="fields">
                                <div class="field">
                                    <div class="ui button" style="height: 65%; margin-top: 22px" tabindex="0"
                                        (click)="retrieveHistory($event)">
                                        Retrieve
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui button" style="height: 65%; margin-top: 22px"
                                        (click)="generateRegionHistoryReport($event)"
                                        tabindex="0">
                                        Export
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="active content">
                                <div class="one fields" style="margin-top:10px;">
                                    <div class="field">
                                        <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                                        <button class="ui button"
                                            (click)="generateRegionHistoryReport($event)">Export</button>
                                    </div>
                                </div> -->

                            <div class="two fields">
                                <div class="required field"
                                    [class.error]="model['error-regionName'] != null && model['error-regionName'] != ''">
                                    <label>Region Name</label>
                                    <combo-plugin #cbRegionNameHistory [settings]="settingRegionHistory"
                                        (change)="valueRegionHistory($event)"></combo-plugin>

                                    <div class="ui basic red pointing prompt label transition error-message-hidden"
                                        [class.error-message-visible]="model['error-regionName'] != null && model['error-regionName'] != ''">
                                        {{model['error-regionName']}}
                                    </div>

                                </div>
                                <!--end of region name history-->

                                <div class="required field"
                                    [class.error]="model['error-regionCode'] != null && model['error-regionCode'] != ''">
                                    <label>Region Code</label>
                                    <input type="text" [value]="valRegionCode" name="regionHistoryCode"
                                        placeholder="Region Code" [disabled]="lock == true" maxlength="3" readonly
                                        style="background-color: lightgray">
                                    <div class="ui basic red pointing prompt label transition error-message-hidden"
                                        [class.error-message-visible]="model['error-regionCode'] != null && model['error-regionCode'] != ''">
                                        {{model['error-regionCode']}}
                                    </div>
                                </div>
                            </div>

                            <grid-plugin2 #gridHistory [settings]="settingGridHistory"
                                (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)">
                            </grid-plugin2>
                        </div>

                    </form>
                    <!-- End of History Form -->

                </div>
            </div>
        </div>
    </div>
</div>
<footer-plugin [info]="info"></footer-plugin>