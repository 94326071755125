<form class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <!--<Error Message Block-->
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-fromDate'] == null || model['error-fromDate'] == ''">
          {{model['error-fromDate']}}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <fieldset style="border: 1px solid lightgray">
        <div class="field">
          <fieldset
          style="border: 1px solid lightgray;"
          [disabled]="disabledPeriod == false"
        >
          <legend style="font-weight: bold; color: dodgerblue"></legend>
          <div class="field" style="width: 20%;">
            <label>EDI Transmission Date</label>
            <div class="ui left icon input">
            <input id="etaFrom" readonly type="text" class="form-control" data-toggle="datepicker" placeholder="Date From">
            <i class="calendar icon"></i>
            </div>
            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-fromDate'] != null && model['error-fromDate'] != ''">
              {{model['error-fromDate']}}
            </div>            
          </div>

          <div class="field">
            <div class="ui radio checkbox">
              <input
                id="invoice"
                type="radio"
                [checked]="model.reportType == 'INVOICE'"
                value="INVOICE"
                name="isType"
                (change)="changeType($event)"
                tabindex="0"
              />
              <label>Actual Invoice (AI)</label>
            </div>
          </div>
          <br />
          <div class="field">
            <div class="ui radio checkbox">
              <input
                id="CN"
                type="radio"
                [checked]="model.reportType == 'CN'"
                value="CN"
                name="isType"
                (change)="changeType($event)"
                tabindex="0"
              />
              <label>Credit Note (CN)</label>
            </div>
          </div>
        </fieldset>
        </div>
      </fieldset>
    </form>
  </div>
  <!-- end div container -->
</form>

<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<footer-plugin [info]="info"></footer-plugin>
