<div class="ui raised segment">
  <dialog-plugin #dialogPlugin
                 [settings]="dialog"
                 (eventDialog)="eventMessage($event)"
  ></dialog-plugin>
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li
        [class.hidden]="
          model['error-blNo'] == null ||
          model['error-blNo'] == '' ||
          model['error-blNo'] == undefined
        "
      >
        {{ model["error-blNo"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blShipperId'] == null ||
          model['error-blShipperId'] == '' ||
          model['error-blShipperId'] == undefined
        "
      >
        {{ model["error-blShipperId"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blShipperAddress'] == null ||
          model['error-blShipperAddress'] == '' ||
          model['error-blShipperAddress'] == undefined
        "
      >
        {{ model["error-blShipperAddress"] }}
      </li>

      <li
        [class.hidden]="
          model['error-blConsigneeId'] == null ||
          model['error-blConsigneeId'] == '' ||
          model['error-blConsigneeId'] == undefined
        "
      >
        {{ model["error-blConsigneeId"] }}
      </li>

      <li
        [class.hidden]="
          model['error-blConsigneeAddress'] == null ||
          model['error-blConsigneeAddress'] == '' ||
          model['error-blConsigneeAddress'] == undefined
        "
      >
        {{ model["error-blConsigneeAddress"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blNotifyPartyId'] == null ||
          model['error-blNotifyPartyId'] == '' ||
          model['error-blNotifyPartyId'] == undefined
        "
      >
        {{ model["error-blNotifyPartyId"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blNotifyPartyAddress'] == null ||
          model['error-blNotifyPartyAddress'] == '' ||
          model['error-blNotifyPartyAddress'] == undefined
        "
      >
        {{ model["error-blNotifyPartyAddress"] }}
      </li>
      <li
        [class.hidden]="
          model['error-bothConsigneeAndParty'] == null ||
          model['error-bothConsigneeAndParty'] == '' ||
          model['error-bothConsigneeAndParty'] == undefined
        "
      >
        {{ model["error-bothConsigneeAndParty"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blReceiptTypeCode'] == null ||
          model['error-blReceiptTypeCode'] == '' ||
          model['error-blReceiptTypeCode'] == undefined
        "
      >
        {{ model["error-blReceiptTypeCode"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blDeliveryTypeCode'] == null ||
          model['error-blDeliveryTypeCode'] == '' ||
          model['error-blDeliveryTypeCode'] == undefined
        "
      >
        {{ model["error-blDeliveryTypeCode"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blLoadPortCode'] == null ||
          model['error-blLoadPortCode'] == '' ||
          model['error-blLoadPortCode'] == undefined
        "
      >
        {{ model["error-blLoadPortCode"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blDischargePortCode'] == null ||
          model['error-blDischargePortCode'] == '' ||
          model['error-blDischargePortCode'] == undefined
        "
      >
        {{ model["error-blDischargePortCode"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blOceanVesselId'] == null ||
          model['error-blOceanVesselId'] == '' ||
          model['error-blOceanVesselId'] == undefined
        "
      >
        {{ model["error-blOceanVesselId"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blOceanVesselVoyage'] == null ||
          model['error-blOceanVesselVoyage'] == '' ||
          model['error-blOceanVesselVoyage'] == undefined
        "
      >
        {{ model["error-blOceanVesselVoyage"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blOceanVesselBound'] == null ||
          model['error-blOceanVesselBound'] == '' ||
          model['error-blOceanVesselBound'] == undefined
        "
      >
        {{ model["error-blOceanVesselBound"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blMarkNoCount'] == null ||
          model['error-blMarkNoCount'] == '' ||
          model['error-blMarkNoCount'] == undefined
        "
      >
        {{ model["error-blMarkNoCount"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blShipper'] == null ||
          model['error-blShipper'] == '' ||
          model['error-blShipper'] == undefined
        "
      >
        {{ model["error-blShipper"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blFaiGroup'] == null ||
          model['error-blFaiGroup'] == '' ||
          model['error-blFaiGroup'] == undefined
        "
      >
        <div [innerHtml]="model['error-blFaiGroup']"></div>
      </li>
      <li
        [class.hidden]="
          model['error-blShortshipment'] == null ||
          model['error-blShortshipment'] == '' ||
          model['error-blShortshipment'] == undefined
        "
      >
        {{ model["error-blShortshipment"] }}
      </li>
      <li
        [class.hidden]="
          model['error-payerValidator'] == null ||
          model['error-payerValidator'] == ''
        "
      >
        {{ model["error-payerValidator"] }}
      </li>
      <li
        [class.hidden]="
          model['error-popValidator'] == null ||
          model['error-popValidator'] == ''
        "
      >
        {{ model["error-popValidator"] }}
      </li>
      <li
        [class.hidden]="
          model['error-nextVesselValidator'] == null ||
          model['error-nextVesselValidator'] == ''
        "
      >
        {{ model["error-nextVesselValidator"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blDoiAndSob'] == null ||
          model['error-blDoiAndSob'] == '' ||
          model['error-blDoiAndSob'] == undefined
        "
      >
        {{ model["error-blDoiAndSob"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blNotifyPartyId'] == null ||
          model['error-blNotifyPartyId'] == '' ||
          model['error-blNotifyPartyId'] == undefined
        "
      >
        {{ model["error-blNotifyPartyId"] }}
      </li>
      <ng-container *ngIf="lineInvalid.length > 0">
        <li *ngFor="let item of lineInvalid">
          <ng-container *ngIf="item.isCC; else invalidCharacter">
            {{item.message}}
          </ng-container>
          <ng-template #invalidCharacter>
            {{showMessageInvalidCharacter(item)}}
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>

  <form class="ui form">
    <button class="ui button" style="display: none"></button>

    <div class="field">
      <!-- 1st Row -->
      <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
        <legend style="font-weight: bold; color: dodgerblue">B/L Search</legend>
        <div class="fields" style="margin-bottom: 0px">
          <div class="four wide field">
            <div class="inline field">
              <div class="fields">
                <div
                  class="four wide field"
                  [class.error]="
                    model['error-blNo'] != null &&
                    model['error-blNo'] != '' &&
                    model['error-blNo'] != undefined
                  "
                  style="margin-top: 10px"
                >
                  <label>BL Ref.</label>
                </div>
                <div class="twelve wide field">
                  <input
                    type="text"
                    name="blRef"
                    [(ngModel)]="model['blNo']"
                    placeholder="BL Reference"
                    maxlength="20"
                    (keypress)="formValidation.CharNumOnly($event)"
                    [disabled]="isInsideRetrieveMode == true"
                    (keyup.enter)="handleRetrieveEnter()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="five wide field">
            <div class="inline field">
              <div class="fields">
                <div class="four wide field" style="margin-top: 10px">
                  <label>BKG Ref.</label>
                </div>
                <div class="twelve wide field">
                  <input
                    type="text"
                    name="blBkgRef"
                    [(ngModel)]="
                      model['blBookingPrefix'] + model['blBookingNo']
                    "
                    placeholder="BKG Reference"
                    maxlength="20"
                    disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="seven wide field">
          <div class="inline field">
            <div class="fields">
              <div class="six wide field" style="margin-top: 10px">
                <label>Shipper Draft BL Ref.</label>
              </div>
              <div class="eight wide field">
                <input
                  type="text"
                  name="blShipperDraftBlRef"
                  [(ngModel)]="model['blShipperDraftBlNoEntry']"
                  placeholder="Shipper Draft BL Reference"
                  maxlength="20"
                  disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="five wide field">
        <div class="inline field">
          <div class="fields">
            <div class="four wide field" style="margin-top: 10px">
              <label>THRU BL Ref</label>
            </div>
            <div class="twelve wide field">
              <input
                type="text"
                name="blThruBLRef"
                [(ngModel)]="model['blThruBLRef']"
                placeholder="THRU BL Ref"
                [disabled]="isInsideRetrieveMode == true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>
<!-- 1st Row -->

    <div class="field">
      <!-- 2nd Row -->
      <div class="ui top attached tabular menu" style="background-color: beige">
        <a
          class="item"
          *ngIf="lockParty == false"
          [class.active]="tabStatus == 'tab1'"
          data-tab="blPartyTab"
          (click)="tabPartyClicked()"
        >Party<i
          *ngIf="lockTab1 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span class="item" *ngIf="lockParty == true" style="color: darkgray"
        >Party<i
          *ngIf="lockTab1 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          *ngIf="lockPlace == false"
          [class.active]="tabStatus == 'tab2'"
          data-tab="blPlaceTab"
          (click)="tabPlaceClicked()"
        >Place<i
          *ngIf="lockTab2 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span class="item" *ngIf="lockPlace == true" style="color: darkgray"
        >Place<i
          *ngIf="lockTab2 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          *ngIf="lockContainer == false"
          [class.active]="tabStatus == 'tab3'"
          data-tab="blContainerTab"
          (click)="tabContainerClicked()"
        >Container<i
          *ngIf="lockTab3 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span class="item" *ngIf="lockContainer == true" style="color: darkgray"
        >Container<i
          *ngIf="lockTab3 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          *ngIf="lockMarkNo == false"
          [class.active]="tabStatus == 'tab4'"
          data-tab="blMarkNoTab"
          (click)="tabMarkClicked()"
        >Mark No.<i
          *ngIf="lockTab4 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span class="item" *ngIf="lockMarkNo == true" style="color: darkgray"
        >Mark No.<i
          *ngIf="lockTab4 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          *ngIf="lockDesc == false"
          [class.active]="tabStatus == 'tab5'"
          data-tab="blDescTab"
          (click)="tabDescClicked()"
        >Description<i
          *ngIf="lockTab5 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span class="item" *ngIf="lockDesc == true" style="color: darkgray"
        >Description<i
          *ngIf="lockTab5 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          *ngIf="lockNettCharge == false"
          [class.active]="tabStatus == 'tab6'"
          data-tab="blNettChargeTab"
          (click)="tabNettClicked()"
        >Nett Charge<i
          *ngIf="lockTab6 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span
          class="item"
          *ngIf="lockNettCharge == true"
          style="color: darkgray"
        >Nett Charge<i
          *ngIf="lockTab6 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          [style.display]="officeCode === 'SGSIN' ? 'none' : 'block'"
          *ngIf="lockGrossCharge == false"
          [class.active]="tabStatus == 'tab7'"
          data-tab="blGrossChargeTab"
          (click)="tabGrossClicked()"
        >Gross Charge</a
        >
        <span
          class="item"
          [style.display]="officeCode === 'SGSIN' ? 'none' : 'block'"
          *ngIf="lockGrossCharge == true"
          style="color: darkgray"
        >Gross Charge</span
        >

        <a
          class="item"
          [style.display]="adminZen === 'ADMINZEN' ? 'block' : 'none'"
          *ngIf="lockNettChargeConsole == false"
          [class.active]="tabStatus == 'tab8'"
          data-tab="blNettChargeConsoleTab"
          (click)="tabNettConsoleClicked()"
        >Nett Charge Console<i
          *ngIf="lockTab8 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></a>
        <span
          class="item"
          [style.display]="adminZen === 'ADMINZEN' ? 'block' : 'none'"
          *ngIf="lockNettChargeConsole == true"
          style="color: darkgray"
        >Nett Charge Console<i
          *ngIf="lockTab8 == true"
          class="lock icon"
          style="margin-left: 5px; color: red"
        ></i
        ></span>

        <a
          class="item"
          [style.display]="officeCode === 'SGSIN' ? 'block' : 'none'"
          *ngIf="lockGrossChargeConsole == false"
          [class.active]="tabStatus == 'tab9'"
          data-tab="blGrossChargeConsoleTab"
          (click)="tabGrossConsoleClicked()"
        >Gross Charge Console</a
        >
        <span
          class="item"
          [style.display]="officeCode === 'SGSIN' ? 'block' : 'none'"
          *ngIf="lockGrossChargeConsole == true"
          style="color: darkgray"
        >Gross Charge Console</span
        >
      </div>

      <!-- Tab Party Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
        id="blPartyTab"
        data-tab="blPartyTab"
      >
        <!-- dont change && -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab1 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="field">
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              Shipper
            </legend>
            <div class="fields" style="margin-bottom: 0px">
              <div class="eight wide field">
                <div class="field">
                  <div class="field" style="margin-top: 22px">
                    <div class="fields" style="margin-bottom: 5px">
                      <div
                        class="twelve wide field"
                        [class.error]="
                          model['error-blShipperId'] != null &&
                          model['error-blShipperId'] != '' &&
                          model['error-blShipperId'] != undefined
                        "
                        style="margin-top: 2px"
                      >
                        <combo-plugin
                          #cbBlPartyShipper
                          [settings]="settingCbBlPartyShipper"
                          (change)="changeEventCbPartyShipper($event)"
                          (keypress)="validateShipper($event)"
                        ></combo-plugin>
                        <!--
                          formValidation.ModifiedPattern($event, '-\'& ')
                        -->
                      </div>
                      <div class="four wide field">
                        <combo-plugin
                          #cbBlPartyShipperAddress
                          [settings]="settingCbBlPartyShipperAddress"
                          (change)="changeEventCbPartyShipperAddress($event)"
                          [disableCombo]="lockAddressRetrieve == true"
                        ></combo-plugin>
                      </div>
                    </div>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <div class="fields">
                      <div class="twelve wide field">
                        <textarea-v2-plugin
                          #txtBlPartyShipperAddress
                          [settings]="settingTxtBlPartyShipperAddress"
                          (keypress)="formValidation.AllChar($event)"
                        ></textarea-v2-plugin>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="eight wide field">
                <div class="field">
                  <div class="field">
                    <label>Shipper Draft BL Ref.</label>
                    <input
                      type="text"
                      name="txtBlPartyShipperDraftBlNumber"
                      placeholder=""
                      [(ngModel)]="model['blShipperDraftBlNo']"
                      (keypress)="formValidation.CharNumOnly($event)"
                      maxlength="20"
                    />
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label>Shipper BKG Ref.</label>
                    <textarea
                      placeholder=""
                      name="txtBlPartyShipperDraftBookingNumbers"
                      [(ngModel)]="model['blShipperBookingNo']"
                      rows="5"
                      readonly
                    ></textarea>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        [checked]="model['blShipperDraftBlNoSkipPrint'] == true"
                        [(ngModel)]="model['blShipperDraftBlNoSkipPrint']"
                        name="chBlPartyShipPrintDraftBLNoSkipPrint"
                        (change)="
                          changeEventChBlPartySkipPrintShipperDraftBlNo($event)
                        "
                        tabindex="0"
                      />
                      <label>Skip Print Shipper Draft BL Ref.</label>
                    </div>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label>Shipper EDI Ref.</label>
                    <input
                      type="text"
                      name="txtBlPartyShipperEDIRef"
                      [(ngModel)]="model['blShipperEdiReference']"
                      placeholder=""
                      (keypress)="formValidation.CharNumOnly($event)"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="field">
          <div class="fields">
            <div class="eight wide field">
              <div class="field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Consignee
                  </legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 5px">
                      <div
                        class="twelve wide field"
                        [class.error]="
                          model['error-blConsigneeId'] != null &&
                          model['error-blConsigneeId'] != '' &&
                          model['error-blConsigneeId'] != undefined
                        "
                        style="margin-top: 2px"
                      >
                        <combo-plugin
                          #cbBlPartyConsignee
                          [settings]="settingCbBlPartyConsignee"
                          (change)="changeEventCbPartyConsignee($event)"
                        ></combo-plugin>
                      </div>
                      <div class="four wide field">
                        <combo-plugin
                          #cbBlPartyConsigneeAddress
                          [settings]="settingCbBlPartyConsigneeAddress"
                          (change)="changeEventCbPartyConsigneeAddress($event)"
                          [disableCombo]="lockAddressRetrieve1 == true"
                        ></combo-plugin>
                      </div>
                      <!--div class="two wide field"-->
                      <!--div-- class="ui button" (click)="btnBlPartyConsigneeToOrder()">To Order</div-->
                      <!--/div-->
                    </div>
                  </div>
                  <div class="field">
                    <div class="fields">
                      <div class="twelve wide field">
                        <textarea-v2-plugin
                          #txtBlPartyConsigneeAddress
                          [settings]="settingTxtBlPartyConsigneeAddress"
                        ></textarea-v2-plugin>
                      </div>
                      <div class="four wide field">
                        <div class="field" style="margin-bottom: 5px">
                          <div
                            class="ui tiny button"
                            (click)="btnBlPartyConsigneeToOrder()"
                          >
                            To Order
                          </div>
                        </div>
                        <div class="field">
                          <div
                            class="ui tiny button"
                            (click)="btnBlPartyConsigneeToOrderShipper()"
                          >
                            To Order of Shipper
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Notify Party
                  </legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 5px">
                      <div
                        class="twelve wide field"
                        [class.error]="
                          model['error-blNotifyPartyId'] != null &&
                          model['error-blNotifyPartyId'] != '' &&
                          model['error-blNotifyPartyId'] != undefined
                        "
                        style="margin-top: 2px"
                      >
                        <combo-plugin
                          #cbBlPartyNotifyParty
                          [settings]="settingCbBlPartyNotifyParty"
                          (change)="changeEventCbPartyNotifyParty($event)"
                        ></combo-plugin>
                      </div>
                      <div class="four wide field">
                        <combo-plugin
                          #cbBlPartyNotifyPartyAddress
                          [settings]="settingCbBlPartyNotifyPartyAddress"
                          (change)="
                            changeEventCbPartyNotifyPartyAddress($event)
                          "
                          [disableCombo]="lockAddressRetrieve2 == true"
                        ></combo-plugin>
                      </div>
                      <!--div class="two wide field"-->
                      <!--div class="ui button" (click)="btnBlPartyNotifyPartyToOrder()">To Order</div-->
                      <!--/div-->
                    </div>
                  </div>
                  <div class="field">
                    <div class="fields">
                      <div class="twelve wide field">
                        <textarea-v2-plugin
                          #txtBlPartyNotifyPartyAddress
                          [settings]="settingTxtBlPartyNotifyPartyAddress"
                        ></textarea-v2-plugin>
                      </div>
                      <div class="four wide field">
                        <div class="field" style="margin-bottom: 5px">
                          <div
                            class="ui tiny button"
                            (click)="btnBlPartyNotifyPartyAsConsigneeAsCnee()"
                          >
                            As CNEE
                          </div>
                        </div>
                        <div class="field">
                          <div
                            class="ui tiny button"
                            (click)="btnBlPartyNotifyPartySameAsConsignee()"
                          >
                            Same as CNEE
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="eight wide field">
              <div class="field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    BL Type
                  </legend>
                  <div class="field">
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        model['blType'] == 'SW' || model['blType'] == 'OIS'
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyMemoBL"
                        [checked]="model['blType'] == 'MEMO'"
                        (change)="changeEventRbBlPartyMemo($event)"
                      />
                      <label>Memo&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        isLockBLTypeOBL === true
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartySWBL"
                        [checked]="model['blType'] == 'SW'"
                        (change)="changeEventRbBlPartySW($event)"
                      />
                      <label>SW&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        model['blType'] == 'SW' || model['blType'] == 'OIS'
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyCRBBL"
                        [checked]="model['blType'] == 'CRB'"
                        (change)="changeEventRbBlPartyCRB($event)"
                      />
                      <label>CRB&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        model['blType'] == 'SW' || model['blType'] == 'OIS'
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyRFSBL"
                        [checked]="model['blType'] == 'RFS'"
                        (change)="changeEventRbBlPartyRFS($event)"
                      />
                      <label>RFS&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        isLockBLTypeOBL === true
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyOISBL"
                        [checked]="model['blType'] == 'OIS'"
                        (change)="changeEventRbBlPartyOIS($event)"
                      />
                      <label>OIS&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        model['blType'] == 'SW' || model['blType'] == 'OIS'
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyIREBL"
                        [checked]="model['blType'] == 'IRE'"
                        (change)="changeEventRbBlPartyIRE($event)"
                      />
                      <label>IRE&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui radio checkbox"
                      [class.disabled]="
                        model['blType'] == 'SW' || model['blType'] == 'OIS'
                      "
                    >
                      <input
                        type="radio"
                        name="rbBlPartyNABL"
                        [checked]="model['blType'] == 'N'"
                        (change)="changeEventRbBlPartyNA($event)"
                      />
                      <label>OBL</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="field" [class.disabled]="true">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Container Ownership
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlContainerOwnershipCOC"
                        [checked]="model['blContainerOwnership'] == 'COC'"
                        (change)="changeEventRbBlContainerOwnershipCOC($event)"
                      />
                      <label>COC&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlContainerOwnershipCOCTS"
                        [checked]="model['blContainerOwnership'] == 'COCTS'"
                        (change)="
                          changeEventRbBlContainerOwnershipCOCTS($event)
                        "
                      />
                      <label>COC T/S&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlContainerOwnershipSLOT"
                        [checked]="model['blContainerOwnership'] == 'SLOT'"
                        (change)="changeEventRbBlContainerOwnershipSLOT($event)"
                      />
                      <label>SLOT&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlContainerOwnershipSOC"
                        [checked]="model['blContainerOwnership'] == 'SOC'"
                        (change)="changeEventRbBlContainerOwnershipSOC($event)"
                      />
                      <label>SOC&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlContainerOwnershipSOCTS"
                        [checked]="model['blContainerOwnership'] == 'SOCTS'"
                        (change)="
                          changeEventRbBlContainerOwnershipSOCTS($event)
                        "
                      />
                      <label>SOC T/S</label>
                    </div>
                    <div class="field" style="margin-top: 10px">
                      <label style="font-weight: bold"
                      >OP Code: {{ model["blOperatorCode"] }}</label
                      >
                      <label style="font-weight: bold"
                      >SO Code: {{ model["blSOOperatorCode"] }}</label
                      >
                      <label style="font-weight: bold"
                      >NVOCC: {{ nvoccIndicator }}</label
                      >
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="field" [class.disabled]="true">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Container Status
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbContainerStatusEmpty"
                        [checked]="model['blContainerStatus'] == 'EMPTY'"
                        (change)="changeEventRbBlContainerStatusEmpty($event)"
                      />
                      <label>Empty&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbContainerStatusFull"
                        [checked]="model['blContainerStatus'] == 'FULL'"
                        (change)="changeEventRbBlContainerStatusFull($event)"
                      />
                      <label>Full</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="field" [class.disabled]="true">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    DG Class
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbDgClassC3"
                        [checked]="model['blDgClass'] == 'C3'"
                        (change)="changeEventRbBlDgClassC3($event)"
                      />
                      <label>C3&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbDgClassC2"
                        [checked]="model['blDgClass'] == 'C2'"
                        (change)="changeEventRbBlDgClassC2($event)"
                      />
                      <label>C2&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbDgClassC2F"
                        [checked]="model['blDgClass'] == 'C2F'"
                        (change)="changeEventRbBlDgClassC2F($event)"
                      />
                      <label>C2F&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbDgClassC1"
                        [checked]="model['blDgClass'] == 'C1'"
                        (change)="changeEventRbBlDgClassC1($event)"
                      />
                      <label>C1&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbDgClassNA"
                        [checked]="model['blDgClass'] == 'N'"
                        (change)="changeEventRbBlDgClassNA($event)"
                      />
                      <label>N/A</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="field">
                <div class="fields">
                  <div class="field" [class.disabled]="true">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 0px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Detention (POL)
                      </legend>
                      <div class="field">
                        <!--div class="fields">
                          <div class="five wide field"-->
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionNAPol"
                              [checked]="model['blDetentionPol'] == 'N'"
                            />
                            <label>N/A&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionStandardPol"
                              [checked]="model['blDetentionPol'] == 'S'"
                            />
                            <label>Standard&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionRequestPol"
                              [checked]="model['blDetentionPol'] == 'R'"
                            />
                            <label>Request</label>
                          </div>
                        </div>
                        <!--/div-->
                        <!--/div-->
                      </div>
                      <div class="field">
                        <!--input type="text" name="blDetentionRequestPol" [(ngModel)]="model['blDetentionRequestPol']" -->
                        <input
                          type="text"
                          name="blDetentionRequestPol"
                          [(ngModel)]="model['blDetentionRequestPol']"
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div class="field" >
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 0px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Detention (POD)
                        <div class="ui test checkbox">
                          <input
                            [checked]="model['blIsDetDemPrinted']=='Y'"
                            name="printDetDem"
                            value="N"
                            type="checkbox"
                            (change)="changeEventPrintDetDem($event)"
                            tabindex="0"
                            class="hidden"
                            [class.disabled]="false"
                          />
                          <label>Print</label>
                        </div>
                      </legend>

                      <div class="field" [class.disabled]="true">
                        <!--div class="fields"-->
                        <!--div class="five wide field"-->
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionNA"
                              [checked]="model['blDetention'] == 'N'"
                            />
                            <label>N/A&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field" [class.disabled]="true">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionStandard"
                              [checked]="model['blDetention'] == 'S'"
                            />
                            <label>Standard&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field" [class.disabled]="true">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDetentionRequest"
                              [checked]="model['blDetention'] == 'R'"
                            />
                            <label>Request</label>
                          </div>
                        </div>

                        <!--/div-->

                        <!--/div-->
                      </div>
                      <div class="field" [class.disabled]="true">
                        <input
                          type="text"
                          name="blDetentionRequest"
                          [(ngModel)]="model['blDetentionRequest']"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <!-- neti-->
              <div class="field">
                <div class="fields">
                  <div class="field" [class.disabled]="true">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 0px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Demurrage (POL)
                      </legend>
                      <!--
                          <div class="field">
                            <div class="fields">
                              <div class="five wide field">
                                <div class="ui radio checkbox">
                                  <input type="radio" name="rbDemurrageNA"  [checked]="false">
                                  <label>N/A&nbsp;&nbsp;&nbsp;</label>
                                </div>
                                <div class="ui radio checkbox">
                                  <input type="radio" name="rbDemurrageStandard" [checked]="false">
                                  <label>Standard&nbsp;&nbsp;&nbsp;</label>
                                </div>
                                <div class="ui radio checkbox">
                                  <input type="radio" name="rbDemurrageRequest" [checked]="false">
                                  <label>Request</label>
                                </div>
                              </div>
                              <div class="six wide field" style="margin-top: 20px;">
                                <input type="text" name="blDemurrageRequest" >
                              </div>
                            </div>
                          </div>
                          -->
                      <div class="field">
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageNAPol"
                              [checked]="model['blDemurragePol'] == 'N'"
                            />
                            <label>N/A&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageStandardPol"
                              [checked]="model['blDemurragePol'] == 'S'"
                            />
                            <label>Standard&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageRequestPol"
                              [checked]="model['blDemurragePol'] == 'R'"
                            />
                            <label>Request</label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <input
                          type="text"
                          name="blDemurrageRequestPol"
                          [(ngModel)]="model['blDemurrageRequestPol']"
                        />
                      </div>
                    </fieldset>
                  </div>
                  <div class="field" [class.disabled]="true">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 0px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Demurrage (POD)
                      </legend>
                      <!--
                        <div class="field">
                          <div class="fields">
                            <div class="five wide field">
                              <div class="ui radio checkbox">
                                <input type="radio" name="rbDemurrageNA"  [checked]="model['blDemurrage'] == 'N'">
                                <label>N/A&nbsp;&nbsp;&nbsp;</label>
                              </div>
                              <div class="ui radio checkbox">
                                <input type="radio" name="rbDemurrageStandard" [checked]="model['blDemurrage'] == 'S'">
                                <label>Standard&nbsp;&nbsp;&nbsp;</label>
                              </div>
                              <div class="ui radio checkbox">
                                <input type="radio" name="rbDemurrageRequest" [checked]="model['blDemurrage'] == 'R'">
                                <label>Request</label>
                              </div>
                            </div>
                            <div class="six wide field" style="margin-top: 20px;">
                              <input type="text" name="blDemurrageRequest" [(ngModel)]="model['blDemurrageRequest']" >
                            </div>
                          </div>
                        </div>
                        -->
                      <div class="field">
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageNA"
                              [checked]="model['blDemurrage'] == 'N'"
                            />
                            <label>N/A&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageStandard"
                              [checked]="model['blDemurrage'] == 'S'"
                            />
                            <label>Standard&nbsp;&nbsp;&nbsp;</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              type="radio"
                              name="rbDemurrageRequest"
                              [checked]="model['blDemurrage'] == 'R'"
                            />
                            <label>Request</label>
                          </div>
                        </div>
                      </div>
                      <div class="field">
                        <input
                          type="text"
                          name="blDemurrageRequest"
                          [(ngModel)]="model['blDemurrageRequest']"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tab Party End -->

      <!-- Tab Party Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus2 == 'active' || tabStatus == 'tab2'"
        id="blPlaceTab"
        data-tab="blPlaceTab"
      >
        <!-- dont change || -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab2 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="fields">
          <div class="eight wide field">
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div
                  class="eight wide field"
                  [class.error]="
                    model['error-blReceiptTypeCode'] != null &&
                    model['error-blReceiptTypeCode'] != '' &&
                    model['error-blReceiptTypeCode'] != undefined
                  "
                >
                  <label>Receipt Type</label>
                  <combo-plugin
                    #cbBlPlaceReceiptType
                    [settings]="settingCbBlPlaceReceiptType"
                    (change)="changeEventCbBlPlaceReceiptType($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.AlphabetsOnly($event)"
                  ></combo-plugin>
                </div>
                <div
                  class="eight wide field"
                  [class.error]="
                    model['error-blDeliveryTypeCode'] != null &&
                    model['error-blDeliveryTypeCode'] != '' &&
                    model['error-blDeliveryTypeCode'] != undefined
                  "
                >
                  <label>Delivery Type</label>
                  <combo-plugin
                    #cbBlPlaceDeliveryType
                    [settings]="settingCbBlPlaceDeliveryType"
                    (change)="changeEventCbBlPlaceDeliveryType($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.AlphabetsOnly($event)"
                  ></combo-plugin>
                </div>
              </div>

              <!--
              <div class="fields">
                <div class="eight wide field">
                  <div class="field">
                    <label>Receipt Type</label>
                    <combo-plugin #cbBlPlaceReceiptType [settings]="settingCbBlPlaceReceiptType" (change)="changeEventCbBlPlaceReceiptType($event);" ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Place of Receipt</label>
                    <combo-plugin #cbBlPlacePOR [settings]="settingCbBlPlacePOR" (change)="changeEventCbBlPlacePOR($event);"></combo-plugin>
                  </div>
                  <div class="field" [class.error]="model['error-blLoadPortCode'] != null && model['error-blLoadPortCode'] != '' && model['error-blLoadPortCode'] != undefined ">
                    <label>Load Port</label>
                    <combo-plugin #cbBlPlacePOL [settings]="settingCbBlPlacePOL"(change)="changeEventCbBlPlacePOL($event);"></combo-plugin>
                  </div>
                  <div class="field" [class.error]="model['error-blDischargePortCode'] != null && model['error-blDischargePortCode'] != '' && model['error-blDischargePortCode'] != undefined ">
                    <label>Discharge Port</label>
                    <combo-plugin #cbBlPlacePOD [settings]="settingCbBlPlacePOD"(change)="changeEventCbBlPlacePOD($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Place of Delivery</label>
                    <combo-plugin #cbBlPlacePODL [settings]="settingCbBlPlacePODL"(change)="changeEventCbBlPlacePODL($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Final Destination</label>
                    <combo-plugin #cbBlPlaceFDEST [settings]="settingCbBlPlaceFDEST"(change)="changeEventCbBlPlaceFDEST($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Transshipment Port 1</label>
                    <combo-plugin #cbBlPlacePOT1 [settings]="settingCbBlPlacePOT1"(change)="changeEventCbBlPlacePOT1($event);($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Transshipment Port 2</label>
                    <combo-plugin #cbBlPlacePOT2 [settings]="settingCbBlPlacePOT2"(change)="changeEventCbBlPlacePOT2($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Transshipment Port 3</label>
                    <combo-plugin #cbBlPlacePOT3 [settings]="settingCbBlPlacePOT3"(change)="changeEventCbBlPlacePOT3($event);"></combo-plugin>
                  </div>
                </div>
                <div class="eight wide field">
                  <div class="field">
                    <label>Delivery Type</label>
                    <combo-plugin #cbBlPlaceDeliveryType [settings]="settingCbBlPlaceDeliveryType"(change)="changeEventCbBlPlaceDeliveryType($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>B/L Print</label>
                    <input type="text" name="txtBlPlacePOR" [(ngModel)]="model['blPlaceOfReceiptPrint']" maxlength="50">
                  </div>
                  <div class="field">
                    <label>&nbsp;</label>
                    <input type="text" name="txtBlPlacePOL"  [(ngModel)]="model['blLoadPortPrint']" maxlength="50">
                  </div>
                  <div class="field">
                    <label>&nbsp;</label>
                    <input type="text" name="txtBlPlacePOD"  [(ngModel)]="model['blDischargePortPrint']" maxlength="50">
                  </div>
                  <div class="field">
                    <label>&nbsp;</label>
                    <input type="text" name="txtBlPlacePODL"  [(ngModel)]="model['blPlaceOfDeliveryPrint']" maxlength="50">
                  </div>
                  <div class="field">
                    <label>&nbsp;</label>
                    <input type="text" name="txtBlPlaceFDEST"  [(ngModel)]="model['blFinalDestinationPrint']" maxlength="50">
                  </div>
                  <div class="field">
                    <label>Principal</label>
                    <combo-plugin #cbBlPlacePrincipal [settings]="settingCbBlPlacePrincipal"(change)="changeEventCbBlPlacePrincipal($event);"></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Service</label>
                    <combo-plugin #cbBlPlaceService [settings]="settingCbBlPlaceService"(change)="changeEventCbBlPlaceService($event);"></combo-plugin>
                  </div>
                </div>
              </div>
              -->
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Place of Receipt</label>
                  <combo-plugin
                    #cbBlPlacePOR
                    [settings]="settingCbBlPlacePOR"
                    (change)="changeEventCbBlPlacePOR($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>B/L Print</label>
                  <input
                    type="text"
                    name="txtBlPlacePOR"
                    [(ngModel)]="model['blPlaceOfReceiptPrint']"
                    maxlength="50"
                    [disabled]="blPlacePlaceOfReceiptPrintLock == true "
                    (keypress)="formValidation.ModifiedPattern($event, ',*. ')"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div
                  class="eight wide field"
                  [class.error]="
                    model['error-blLoadPortCode'] != null &&
                    model['error-blLoadPortCode'] != '' &&
                    model['error-blLoadPortCode'] != undefined
                  "
                >
                  <label>Load Port</label>
                  <combo-plugin
                    #cbBlPlacePOL
                    [settings]="settingCbBlPlacePOL"
                    (change)="changeEventCbBlPlacePOL($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>&nbsp;</label>
                  <input
                    type="text"
                    name="txtBlPlacePOL"
                    [(ngModel)]="model['blLoadPortPrint']"
                    maxlength="50"
                    [disabled]="blPlaceLoadPortPrintLock == true"
                    (keypress)="formValidation.ModifiedPattern($event, ',*. ')"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div
                  class="eight wide field"
                  [class.error]="
                    model['error-blDischargePortCode'] != null &&
                    model['error-blDischargePortCode'] != '' &&
                    model['error-blDischargePortCode'] != undefined
                  "
                >
                  <label>Discharge Port</label>
                  <combo-plugin
                    #cbBlPlacePOD
                    [settings]="settingCbBlPlacePOD"
                    (change)="changeEventCbBlPlacePOD($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>&nbsp;</label>
                  <input
                    type="text"
                    name="txtBlPlacePOD"
                    [(ngModel)]="model['blDischargePortPrint']"
                    maxlength="50"
                    [disabled]="blPlaceDischargePortPrintLock == true"
                    (keypress)="formValidation.ModifiedPattern($event, ',*. ')"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Place of Delivery</label>
                  <combo-plugin
                    #cbBlPlacePODL
                    [settings]="settingCbBlPlacePODL"
                    (change)="changeEventCbBlPlacePODL($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>&nbsp;</label>
                  <input
                    type="text"
                    name="txtBlPlacePODL"
                    [(ngModel)]="model['blPlaceOfDeliveryPrint']"
                    maxlength="50"
                    [disabled]="blPlacePlaceOfDeliveryPrintLock == true"
                    (keypress)="formValidation.ModifiedPattern($event, ',*. ')"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Final Destination</label>
                  <combo-plugin
                    #cbBlPlaceFDEST
                    [settings]="settingCbBlPlaceFDEST"
                    (change)="changeEventCbBlPlaceFDEST($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>&nbsp;</label>
                  <input
                    type="text"
                    name="txtBlPlaceFDEST"
                    [(ngModel)]="model['blFinalDestinationPrint']"
                    [disabled]="blPlacePrintBlFinalDestination2 == true"
                    maxlength="50"
                    (keypress)="formValidation.ModifiedPattern($event, ',*. ')"
                  />
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Transshipment Port 1</label>
                  <combo-plugin
                    #cbBlPlacePOT1
                    [settings]="settingCbBlPlacePOT1"
                    (change)="changeEventCbBlPlacePOT1($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>Principal</label>
                  <!--combo-plugin #cbBlPlacePrincipal [settings]="settingCbBlPlacePrincipal"(change)="changeEventCbBlPlacePrincipal($event);" [disableCombo]="model['blType'] != 'SW' && model['blType'] != 'OIS'"></combo-plugin-->
                  <combo-plugin
                    #cbBlPlacePrincipal
                    [settings]="settingCbBlPlacePrincipal"
                    (change)="changeEventCbBlPlacePrincipal($event)"
                    [disableCombo]="true"
                    (keypress)="formValidation.CharNumOnly($event)"
                  ></combo-plugin>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Transshipment Port 2</label>
                  <combo-plugin
                    #cbBlPlacePOT2
                    [settings]="settingCbBlPlacePOT2"
                    (change)="changeEventCbBlPlacePOT2($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>Service Code</label>
                  <!--combo-plugin #cbBlPlaceService [settings]="settingCbBlPlaceService"(change)="changeEventCbBlPlaceService($event);" [disableCombo]="model['blType'] != 'SW' && model['blType'] != 'OIS'"></combo-plugin-->
                  <combo-plugin
                    #cbBlPlaceService
                    [settings]="settingCbBlPlaceService"
                    (change)="changeEventCbBlPlaceService($event)"
                    [disableCombo]="true"
                    (keypress)="formValidation.ModifiedPattern($event, '- ')"
                  ></combo-plugin>
                  <!--label style="margin-top: 5px; color: maroon;">{{blPlaceServiceName}}</label-->
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields" style="margin-bottom: 5px">
                <div class="eight wide field">
                  <label>Transshipment Port 3</label>
                  <combo-plugin
                    #cbBlPlacePOT3
                    [settings]="settingCbBlPlacePOT3"
                    (change)="changeEventCbBlPlacePOT3($event)"
                    [disableCombo]="
                      model['blType'] != 'SW' && model['blType'] != 'OIS'
                    "
                    (keypress)="formValidation.ModifiedPattern($event, ', ')"
                  ></combo-plugin>
                </div>
                <div class="eight wide field">
                  <label>Service Name</label>
                  <input
                    type="text"
                    name="txtServiceName"
                    [(ngModel)]="blPlaceServiceName"
                    maxlength="50"
                    readonly
                  />
                  <!--label style="margin-top: 5px; color: maroon;">{{blPlaceServiceName}}</label-->
                </div>
              </div>
            </div>
          </div>

          <div class="eight wide field">
            <div class="field">
              <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
                <legend style="font-weight: bold; color: dodgerblue">
                  Pre Vessel
                </legend>
                <div class="fields">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="model['blPreVesselSkipCheck'] == true"
                      [(ngModel)]="model['blPreVesselSkipCheck']"
                      name="chBlPlacePreVesselSkipCheck"
                      (change)="changeEventChBlPlacePreVesselSkipCheck($event)"
                    />
                    <label>Skip Check</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox">
                    <!--<input type="checkbox" [checked]="model['blPreVesselIsPrinted'] == true"  [(ngModel)]="model['blPreVesselIsPrinted']" name="chBlPlacePrint"  (change)="changeEventChBlPlacePrint($event)" >-->
                    <input
                      type="checkbox"
                      [checked]="isPreVesselPrinted == true"
                      name="chPreVesselPrint"
                      (change)="changeEventChBlPlacePrint($event)"
                    />
                    <label>Print</label>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blPreVesselName'] != null &&
                        model['error-blPreVesselName'] != ''
                      "
                    >
                      <label>Vessel</label>
                      <combo-plugin
                        #cbBlPlacePreVessel
                        [settings]="settingCbBlPlacePreVessel"
                        (change)="changeEventCbBlPlacePreVessel($event)"
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blPreVesselName')"
                      ></combo-plugin>
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blPreVesselName'] != null &&
                          model['error-blPreVesselName'] != ''
                        "
                      >
                        {{ model["error-blPreVesselName"] }}
                      </div>
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blPreVesselVoyage'] != null &&
                        model['error-blPreVesselVoyage'] != ''
                      "
                    >
                      <label>Voyage</label>
                      <input
                        type="text"
                        name="blPreVesselVoyage"
                        [(ngModel)]="model['blPreVesselVoyage']"
                        (ngModelChange)="checkIfError('blPreVesselVoyage')"
                        (blur)="checkPreVesselVoyage()"
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blPreVesselVoyage')"
                        maxlength="20"
                      />
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blPreVesselVoyage'] != null &&
                          model['error-blPreVesselVoyage'] != ''
                        "
                      >
                        {{ model["error-blPreVesselVoyage"] }}
                      </div>
                    </div>
                    <div class="two wide field">
                      <label>Bound</label>
                      <input
                        type="text"
                        name="txtBlPlacePreVesselBound"
                        [(ngModel)]="model['blPreVesselBound']"
                        (change)="changeBlPreVesselBound()"
                        (blur)="checkPreVesselVoyage()"
                        maxlength="1"
                      />
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="
                        model['blPreVesselIsClausePrintedOnBl'] == true
                      "
                      [(ngModel)]="model['blPreVesselIsClausePrintedOnBl']"
                      name="chBlPlacePrintPreVesselClauseOnBL"
                      (change)="
                        changeEventChBlPlacePrintPreVesselClauseOnBL($event)
                      "
                    />
                    <label>Print as Ocean Vessel</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input
                      type="radio"
                      name="rbBlPlacePreVesselFooter"
                      [checked]="model['blPreVesselFooterOnBl'] == true"
                      (change)="changeEventRbBlPlacePreVesselFooter($event)"
                    />
                    <label>Footer of BL</label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="field">
              <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
                <legend style="font-weight: bold; color: dodgerblue">
                  Ocean Vessel
                </legend>
                <!--div class="field">
                  <div class="ui checkbox" >
                    <input type="checkbox" [checked]="model['blPreVesselSkipCheck'] == 'Y' "  [(ngModel)]="model['blPreVesselSkipCheck']" name="chBlPlaceOceanVesselShipCheck" (change)="changeEventChBlPlacePreVesselSkipCheck($event)"  tabindex="0">
                    <label>Skip Check</label>
                  </div>
                </div-->
                <div class="field">
                  <div class="fields">
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blOceanVesselId'] != null &&
                        model['error-blOceanVesselId'] != '' &&
                        model['error-blOceanVesselId'] != undefined
                      "
                    >
                      <label>Vessel</label>
                      <combo-plugin
                        #cbBlPlaceOceanVessel
                        [settings]="settingCbBlPlaceOceanVessel"
                        (change)="changeEventCbBlPlaceOceanVessel($event)"
                        [disableCombo]="
                          model['blType'] != 'SW' && model['blType'] != 'OIS'
                        "
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blOceanVesselId')"
                      ></combo-plugin>
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blOceanVesselId'] != null &&
                          model['error-blOceanVesselId'] != ''
                        "
                      >
                        {{ model["error-blOceanVesselId"] }}
                      </div>
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blOceanVesselVoyage'] != null &&
                        model['error-blOceanVesselVoyage'] != '' &&
                        model['error-blOceanVesselVoyage'] != undefined
                      "
                    >
                      <label>Voyage</label>
                      <combo-plugin
                        #cbBlPlaceOceanVoyage
                        [settings]="settingCbBlPlaceOceanVoyage"
                        (change)="changeEventCbBlPlaceOceanVoyage($event)"
                        [disableCombo]="
                          model['blType'] != 'SW' && model['blType'] != 'OIS'
                        "
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blOceanVesselVoyage')"
                      ></combo-plugin>
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blOceanVesselVoyage'] != null &&
                          model['error-blOceanVesselVoyage'] != ''
                        "
                      >
                        {{ model["error-blOceanVesselVoyage"] }}
                      </div>
                    </div>
                    <div
                      class="two wide field"
                      [class.error]="
                        model['error-blOceanVesselBound'] != null &&
                        model['error-blOceanVesselBound'] != '' &&
                        model['error-blOceanVesselBound'] != undefined
                      "
                    >
                      <label>Bound</label>
                      <input
                        type="text"
                        name="txtBlPlaceOceanVesselBound"
                        [(ngModel)]="model['blOceanVesselBound']"
                        (keyup)="changeBlOceanBound()"
                        maxlength="1"
                        [disabled]="
                          model['blType'] != 'SW' && model['blType'] != 'OIS'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <div class="field">
                        <div class="ui checkbox">
                          <input
                            type="checkbox"
                            [checked]="
                              model['blOceanVesselIsClausePrintedOnBl'] == true
                            "
                            [(ngModel)]="
                              model['blOceanVesselIsClausePrintedOnBl']
                            "
                            name="chBlPlacePreVesselIsClausePrintedOnBl"
                            (change)="
                              changeEventChBlPlacePrintOceanVesselOnBL($event)
                            "
                          />
                          <label>Print as Pre Vessel</label>
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui radio checkbox">
                          <input
                            type="radio"
                            name="rbBlPlaceOceanVesselFooter"
                            [checked]="model['blOceanVesselFooterOnBl'] == true"
                            (change)="
                              changeEventRbBlPlaceOceanVesselFooter($event)
                            "
                          />
                          <label>Footer of BL</label>
                        </div>
                      </div>
                    </div>
                    <div class="eight wide field" style="text-align: right">
                      &nbsp;
                      <!--
                      <div class="field">
                        <button class="ui button" (click)="eventBtnUndoRadio()" >Undo</button>
                      </div>
                      -->
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="field">
              <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
                <legend style="font-weight: bold; color: dodgerblue">
                  Next Vessel
                </legend>
                <div class="field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="model['blNextVesselSkipCheck'] == true"
                      [(ngModel)]="model['blNextVesselSkipCheck']"
                      name="cbBlPlaceNextVesselSkipCheck"
                      (change)="changeEventChBlPlaceNextVesselSkipCheck($event)"
                    />
                    <label>Skip Check</label>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blNextVesselName'] != null &&
                        model['error-blNextVesselName'] != ''
                      "
                    >
                      <label>Vessel</label>
                      <combo-plugin
                        #cbBlPlaceNextVessel
                        [settings]="settingCbBlPlaceNextVessel"
                        (change)="changeEventCbBlPlaceNextVessel($event)"
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blNextVesselName')"
                      ></combo-plugin>
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blNextVesselName'] != null &&
                          model['error-blNextVesselName'] != ''
                        "
                      >
                        {{ model["error-blNextVesselName"] }}
                      </div>
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        model['error-blNextVesselVoyage'] != null &&
                        model['error-blNextVesselVoyage'] != ''
                      "
                    >
                      <label>Voyage</label>
                      <input
                        type="text"
                        name="blNextVesselVoyage"
                        [(ngModel)]="model['blNextVesselVoyage']"
                        (ngModelChange)="checkIfError('blNextVesselVoyage')"
                        (blur)="checkNextVesselVoyage()"
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-./ ')
                        "
                        (paste)="errorVesVoy($event, 'blNextVesselVoyage')"
                        maxlength="20"
                      />
                      <div
                        class="transition ui basic red pointing prompt label error-message-hidden"
                        [class.error-message-visible]="
                          model['error-blNextVesselVoyage'] != null &&
                          model['error-blNextVesselVoyage'] != ''
                        "
                      >
                        {{ model["error-blNextVesselVoyage"] }}
                      </div>
                    </div>
                    <div class="two wide field">
                      <label>Bound</label>
                      <input
                        type="text"
                        name="txtBlPlaceNextVesselBound"
                        [(ngModel)]="model['blNextVesselBound']"
                        (change)="changeBlNextVesselBound()"
                        (blur)="checkNextVesselVoyage()"
                        maxlength="1"
                      />
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="
                        model['blNextVesselPrintedAsOceanVessel'] == true
                      "
                      [(ngModel)]="model['blNextVesselPrintedAsOceanVessel']"
                      name="chBlPlacePrintNextVesselAsOceanVessel"
                      (change)="
                        changeEventChBlPlacePrintNextVesselAsOceanVessel($event)
                      "
                    />
                    <label>Print as Ocean Vessel</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="model['blNextVesselClauseIsPrinted'] == true"
                      [(ngModel)]="model['blNextVesselClauseIsPrinted']"
                      name="chBlPlacePrintNextVesselClauseOnBL"
                      (change)="changeEventChBlPlacePrintNextVesselOnBL($event)"
                    />
                    <label>Print Next-Vessel Clause on BL</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input
                      type="radio"
                      name="rbBlPlaceNextVesselFooter"
                      [checked]="model['blNextVesselFooterOnBl'] == true"
                      (change)="changeEventRbBlPlaceNextVesselFooter($event)"
                    />
                    <label>Footer of BL</label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <!-- Tab Party End -->

      <!-- Tab Container Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus3 == 'active' || tabStatus == 'tab3'"
        id="blContainerTab"
        data-tab="blContainerTab"
      >
        <!-- dont change || -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab3 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="field">
          <grid-plugin
            #gridBlContainer
            [settings]="settingGridBlContainer"
            (gridEvent)="gridEventBlContainer($event)"
            (infoGrid)="infoGridBlContainer($event)"
          ></grid-plugin>
        </div>

        <div class="field">
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              BL Container Entry
            </legend>
            <div class="field">
              <button
                class="ui button"
                (click)="eventBtnBlContainerNew()"
                [class.disabled]="btnBlContainerNew == false"
              >
                New
              </button>
              <button
                class="ui button"
                (click)="eventBtnBlContainerSave()"
                [class.disabled]="btnBlContainerSave == false"
              >
                {{ txtBlContainerSaveUpdate }}
              </button>
              <button
                class="ui button"
                (click)="eventBtnBlContainerDelete()"
                [class.disabled]="btnBlContainerDelete == false"
              >
                Delete
              </button>
              <button
                style="z-index: 10; position: relative"
                class="ui button"
                (click)="eventBtnBlContainerCancel()"
                [class.disabled]="btnBlContainerCancel == false"
              >
                Cancel
              </button>
            </div>

            <div
              class="ui error message"
              [class.errorContainer]="isErrorContainer == true"
            >
              <ul>
                <li
                  [class.hidden]="
                    paramContainer['error-blContainerNumber'] == null ||
                    paramContainer['error-blContainerNumber'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerNumber"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-blContainerSize'] == null ||
                    paramContainer['error-blContainerSize'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerSize"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-checkWtMeasValidator'] == null ||
                    paramContainer['error-checkWtMeasValidator'] == ''
                  "
                >
                  {{ paramContainer["error-checkWtMeasValidator"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-blContainerShortshipment'] == null ||
                    paramContainer['error-blContainerShortshipment'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerShortshipment"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-blContainerHSCodeDetection'] ==
                      null ||
                    paramContainer['error-blContainerHSCodeDetection'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerHSCodeDetection"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-UchMeasurementsValidator'] == null ||
                    paramContainer['error-UchMeasurementsValidator'] == ''
                  "
                >
                  {{ paramContainer["error-UchMeasurementsValidator"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-UclMeasurementsValidator'] == null ||
                    paramContainer['error-UclMeasurementsValidator'] == ''
                  "
                >
                  {{ paramContainer["error-UclMeasurementsValidator"] }}
                </li>
                <li
                  [class.hidden]="
                    paramContainer['error-UcwMeasurementsValidator'] == null ||
                    paramContainer['error-UcwMeasurementsValidator'] == ''
                  "
                >
                  {{ paramContainer["error-UcwMeasurementsValidator"] }}
                </li>

                <li
                  [class.hidden]="
                    paramContainer['error-blContainerFlashPointScale'] == null ||
                    paramContainer['error-blContainerFlashPointScale'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerFlashPointScale"] }}
                </li>

                <li
                  [class.hidden]="
                    paramContainer['error-blContainerReeferScale'] == null ||
                    paramContainer['error-blContainerReeferScale'] == ''
                  "
                >
                  {{ paramContainer["error-blContainerReeferScale"] }}
                </li>

                <li [class.hidden]="
                      paramContainer['error-blContainerSeqNo'] == null ||
                      paramContainer['error-blContainerSeqNo'] == ''
                    ">
                  {{ paramContainer["error-blContainerSeqNo"] }}
                </li>
              </ul>
            </div>

            <div class="field" style="margin-bottom: 0px">
              <div class="fields" style="margin-bottom: 0px">
                <div class="five wide field">
                  <label>Seq</label>
                  <input
                    type="text"
                    name="txtBlContainerSequence"
                    [(ngModel)]="paramContainer['blContainerSeq']"
                    disabled
                  />
                </div>
                <div class="eleven wide field">
                  <fieldset
                    style="border: 1px solid lightgray; margin-top: 20px"
                  >
                    <div class="ui checkbox" [class.disabled]="true">
                      <input
                        type="checkbox"
                        [checked]="
                          paramContainer['blContainerSkipCheck'] == true
                        "
                        [(ngModel)]="paramContainer['blContainerSkipCheck']"
                        name="chBlContainerSkipCheckBlContainerNo"
                        (change)="
                          changeEventChBlContainerSkipCheckBlContainerNo($event)
                        "
                        tabindex="0"
                      />
                      <label>Skip Check Container No.&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div
                      class="ui checkbox"
                      [class.disabled]="
                        model['blType'] === 'OIS' || model['blType'] === 'SW'
                      "
                    >
                      <!--[class.disabled]="model.blType == 'OIS' && model.blType == 'SW'?false:true"-->
                      <input
                        type="checkbox"
                        [checked]="paramContainer['blContainerIsEmpty'] == true"
                        [(ngModel)]="paramContainer['blContainerIsEmpty']"
                        name="chBlContainerEmpty"
                        (change)="changeEventChBlContainerIsEmpty($event)"
                        tabindex="0"
                      />
                      <label>Empty&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <!--div class="ui checkbox" [class.disabled]="true"-->
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        [checked]="paramContainer['blContainerIsDry'] == true"
                        [(ngModel)]="paramContainer['blContainerIsDry']"
                        name="chBlContainerIsDry"
                        (change)="changeEventChBlContainerIsDry($event)"
                        tabindex="0"
                      />
                      <label>Dry&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        [checked]="
                          paramContainer['blContainerShortShipContainer'] ==
                          true
                        "
                        [(ngModel)]="
                          paramContainer['blContainerShortShipContainer']
                        "
                        name="chBlContainerShortShipContainer"
                        (change)="
                          changeEventChBlContainerShortShipContainer($event)
                        "
                        tabindex="0"
                      />
                      <label>Short Shipment&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        [checked]="
                          paramContainer['blContainerIsReworkedContainer'] ==
                          true
                        "
                        [(ngModel)]="
                          paramContainer['blContainerIsReworkedContainer']
                        "
                        name="chblContainerIsReworkedContainer"
                        (change)="
                          changeEventChblContainerIsReworkedContainer($event)
                        "
                        [disabled]="isDisabledReworked"
                        tabindex="0"
                      />
                      <label>Reworked</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="field" style="margin-bottom: 0px">
              <div class="fields" style="margin-bottom: 0px">
                <div class="three wide field" style="margin-top: 15px">
                  <div
                    class="field"
                    [class.error]="
                      paramContainer['error-blContainerNumber'] != null &&
                      paramContainer['error-blContainerNumber'] != '' &&
                      paramContainer['error-blContainerNumber'] != undefined
                    "
                  >
                    <label>Container No.</label>
                    <input
                      type="text"
                      name="txtBlContainerNo"
                      [(ngModel)]="paramContainer['blContainerNumber']"
                      [disabled]="frmContainerLock == true"
                      maxlength="14"

                      (keypress)="keypressEventBlContainer($event)"
                      (focusout)="focusOuteventBlContainer($event, 'blContainerNumber')"
                      (paste)="pasteEventBlContainer($event, 'blContainerNumber')"
                    />
                  </div>
                  <div class="field" style="margin-top: 5px" [class.error]="
                      paramContainer['error-blContainerSeqNo'] != null &&
                      paramContainer['error-blContainerSeqNo'] != '' &&
                      paramContainer['error-blContainerSeqNo'] != undefined
                    ">
                    <label>Seal No. (1)</label>
                    <input
                      type="text"
                      name="txtBlContainerSeal1"
                      [(ngModel)]="paramContainer['blContainerSealNo1']"
                      [disabled]="frmContainerLock == true"
                      maxlength="20"

                      (keypress)="keypressEventSealNo($event)"
                      (focusout)="focusOuteventBlContainer($event, 'blContainerSealNo1')"
                      (paste)="pasteEventBlContainer($event, 'blContainerSealNo1')"
                    />
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label>Seal No. (2)</label>
                    <input
                      type="text"
                      name="txtBlContainerSeal2"
                      [(ngModel)]="paramContainer['blContainerSealNo2']"
                      [disabled]="frmContainerLock == true"
                      maxlength="20"
                      (keypress)="keypressEventSealNo($event)"
                      (focusout)="focusOuteventBlContainer($event, 'blContainerSealNo2')"
                      (paste)="pasteEventBlContainer($event, 'blContainerSealNo2')"
                    />
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label>Seal No. (3)(Cont. Type)</label>
                    <input
                      type="text"
                      name="txtBlContainerSeal3"
                      [(ngModel)]="paramContainer['blContainerSealNo3']"
                      [disabled]="frmContainerLock == true"
                      maxlength="20"

                      (keypress)="keypressEventSealNo($event)"
                      (focusout)="focusOuteventBlContainer($event, 'blContainerSealNo3')"
                      (paste)="pasteEventBlContainer($event, 'blContainerSealNo3')"
                    />
                  </div>

                  <div class="field" style="margin-top: 5px">

                  </div>

                  <div class="field" style="margin-top: 8px">
                    <div class="fields">
                      <div class="eight wide field">
                        <label>OOG Scale</label>
                        <combo-plugin
                          #cbBlContainerOogScale
                          [settings]="settingCbBlContainerOogScale"
                          (change)="changeEventBlContainerOogScale($event)"
                          [disableCombo]="frmContainerLock == true"
                        ></combo-plugin>

                        <!--
                       <div class="ui fluid selection dropdown" [class.disabled]="frmContainerLock==true">
                         <div class="text">{{blDegreeTemp}}</div>
                         <i class="dropdown icon"></i>
                         <div class="menu">
                           <div class="item" [class.active]="blDegreeTemp == 'C'" [class.selected]="blDegreeTemp == 'C'" (click)="onSelectDegreeTemp('C')">C</div>
                           <div class="item" [class.active]="blDegreeTemp == 'F'" [class.selected]="blDegreeTemp == 'F'" (click)="onSelectDegreeTemp('F')">F</div>
                         </div>
                       </div>
                       -->
                      </div>
                      <div class="eight wide field">
                        <label>OH</label>
                        <input
                          type="number"
                          name="txtBlContainerOH"
                          [(ngModel)]="paramContainer['blContainerOogOH']"
                          [disabled]="frmContainerLock == true"
                          step="0.1"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field" style="margin-top: -5px">
                    <label>DG CLS</label>
                    <combo-plugin
                      #cbBlContainerContainerDGClass
                      [settings]="settingCbBlContainerContainerDGClass"
                      (change)="
                        changeEventCbBlContainerContainerDgClass($event)
                      "
                      [disableCombo]="dgClassLock == true"
                    ></combo-plugin>
                  </div>

                  <div class="fields" style="margin-top: 5px">
                    <div class="eight wide field">
                      <label>Reefer Temp.</label>
                      <input
                        type="text"
                        name="txtBlContainerReeferTemp"
                        [(ngModel)]="
                          paramContainer['blContainerReeferTemperature']
                        "
                        [disabled]="frmContainerLock == true"
                        (keypress)="formValidation.NumWDash($event)"
                        (paste)="formValidation.pasteEventRegexWith($event, paramContainer, 'blContainerReeferTemperature', '[^0-9\s-]', 18)"
                        maxlength="18"
                      />
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        paramContainer['error-blContainerReeferScale'] != null &&
                        paramContainer['error-blContainerReeferScale'] != '' &&
                        paramContainer['error-blContainerReeferScale'] != undefined
                      "
                    >
                      <label>&nbsp;</label>
                      <combo-plugin
                        #cbBlContainerReeferScale
                        [settings]="settingCbBlContainerReeferScale"
                        (change)="changeEventBlContainerReeferScale($event)"
                        [disableCombo]="frmContainerLock == true"
                      ></combo-plugin>

                    </div>
                  </div>

                </div>
                <div class="three wide field" style="margin-top: 10px">

                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Outer Quantity</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerQuantityLock == true"
                      [(ngModel)]="blContainerQuantityLock"
                      name="blContainerQuantityLock"
                      (change)="changeEventBlContainerQuantityLock(blContainerQuantityLock)"
                      [disabled]="disableBlContainerQuantityLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>

                    <input
                      type="number"
                      name="txtBlContainerQuantity"
                      [(ngModel)]="paramContainer['blContainerQuantity']"
                      [disabled]="frmContainerLock == true"
                      min="0"
                      maxlength="18"
                      (keypress)="formValidation.keypressEventRegexWith($event, paramContainer, 'blContainerQuantity', '[0-9\s.]')"
                      (paste)="formValidation.pasteEventRegexWith($event, paramContainer, 'blContainerQuantity', '[^0-9\s.]', 18)"
                    />
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Outer Package Type</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerPackageTypeLock == true"
                      [(ngModel)]="blContainerPackageTypeLock"
                      name="blContainerPackageTypeLock"
                      (change)="changeEventBlContainerPackageTypeLock(blContainerPackageTypeLock)"
                      [disabled]="disableBlContainerPackageTypeLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>

                    <combo-plugin
                      #cbBlContainerPackageType
                      [settings]="settingCbBlContainerPackageType"
                      (change)="changeEventCbBlContainerPackageType($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Gross Weight (KGS)</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerGrossWeightLock == true"
                      [(ngModel)]="blContainerGrossWeightLock"
                      name="blContainerGrossWeightLock"
                      (change)="changeEventBlContainerGrossWeightLock(blContainerGrossWeightLock)"
                      [disabled]="disableBlContainerGrossWeightLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>
                    <div [class.tooltip]="showTooltip !== ''">
                      <input
                        type="text"
                        name="blContainerGrossWeightString"
                        [(ngModel)]="paramContainer['blContainerGrossWeightString']"
                        [disabled]="frmContainerLock == true"
                        (focus)="showTooltip=''"
                        (change)="changeEventGrossWeight($event)"
                        (paste)="onPasteDecimalFilter($event, paramContainer, 'blContainerGrossWeightString')"
                        appFourDigitDecimalNumber
                      />
                      <span class="tooltiptext">{{ showTooltip }}</span>
                    </div>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Gross Meas (CMB)</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerGrossMeasureLock == true"
                      [(ngModel)]="blContainerGrossMeasureLock"
                      name="blContainerGrossMeasureLock"
                      (change)="changeEventBlContainerGrossMeasureLock(blContainerGrossMeasureLock)"
                      [disabled]="disableBlContainerGrossMeasureLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>
                    <div [class.tooltip]="showTooltip !== ''">
                      <input
                        type="text"
                        name="blContainerGrossMeasureString"
                        [(ngModel)]="paramContainer['blContainerGrossMeasureString']"
                        [disabled]="frmContainerLock == true"
                        (focus)="showTooltip=''"
                        (paste)="onPasteDecimalFilter($event, paramContainer, 'blContainerGrossMeasureString')"
                        appFourDigitDecimalNumber
                      />
                      <span class="tooltiptext">{{ showTooltip }}</span>
                    </div>
                  </div>

                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Nett Weight (KGS)</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerNettWeightLock == true"
                      [(ngModel)]="blContainerNettWeightLock"
                      name="blContainerNettWeightLock"
                      (change)="changeEventBlContainerNettWeightLock(blContainerNettWeightLock)"
                      [disabled]="disableBlContainerNettWeightLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>
                    <div [class.tooltip]="showTooltip !== ''">
                      <input
                        type="text"
                        name="blContainerNettWeightString"
                        [(ngModel)]="paramContainer['blContainerNettWeightString']"
                        [disabled]="frmContainerLock == true"
                        (focus)="showTooltip=''"
                        (paste)="onPasteDecimalFilter($event, paramContainer, 'blContainerNettWeightString')"
                        appFourDigitDecimalNumber
                      />
                      <span class="tooltiptext">{{ showTooltip }}</span>
                    </div>
                  </div>

                  <!-- <div class="fields" style="margin-top: 8px">
                    <br />
                    <br />
                    <br />
                  </div> -->

                  <div class="field" style="margin-top: 8px">

                    <div class="fields">
                      <div class="eight wide field">
                        <label>OWL</label>
                        <input
                          type="number"
                          name="txtBlContainerOWL"
                          [(ngModel)]="paramContainer['blContainerOogOWL']"
                          [disabled]="frmContainerLock == true"
                          step="0.1"
                        />
                      </div>
                      <div class="eight wide field">
                        <label>OWR</label>
                        <input
                          type="number"
                          name="txtBlContainerOWR"
                          [(ngModel)]="paramContainer['blContainerOogOWR']"
                          [disabled]="frmContainerLock == true"
                          step="0.1"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field" style="margin-top: 5px">
                    <div class="fields" style="margin-bottom: 14px">
                      <div class="eight wide field">
                        <label>IMO</label>
                        <input
                          type="text"
                          maxlength="4"
                          name="txtBlContainerIMO"
                          [(ngModel)]="paramContainer['blContainerIMO']"
                          [disabled]="frmContainerLock == true"
                          (keypress)="formValidation.NumWDot($event)"
                        />
                      </div>
                      <div class="eight wide field">
                        <label>UNNO</label>
                        <input
                          type="text"
                          maxlength="5"
                          name="txtBlContainerUNNO"
                          [(ngModel)]="paramContainer['blContainerUNNO']"
                          [disabled]="frmContainerLock == true"
                          (keypress)="formValidation.NumWOnly($event)"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- <div
                    class="field"
                    style="margin-bottom: 5px; margin-top: 3px"
                  >
                    <label>Inner Package Type</label>
                    <combo-plugin
                      #cbBlContainerInnerPkgType
                      [settings]="settingCbBlContainerInnerPkgType"
                      (change)="changeEventCbBlContainerInnerPkgType($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div> -->

                  <div class="field" style="margin-bottom: 5px; margin-top: 8px">
                    <label>Commodity</label>
                    <combo-plugin
                      #cbBlContainerCommodity
                      [settings]="settingCbBlContainerCommodity"
                      (change)="changeEventCbBlContainerCommodity($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div>

                </div>
                <div class="three wide field" style="margin-top: 15px">
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <label>R/Type</label>
                        <combo-plugin
                          #cbBlContainerReceiptType
                          [settings]="settingCbBlContainerReceiptType"
                          (change)="changeEventCbBlContainerReceiptType($event)"
                          [disableCombo]="frmContainerLock == true"
                        ></combo-plugin>
                      </div>
                      <div class="eight wide field">
                        <label>D/Type</label>
                        <combo-plugin
                          #cbBlContainerDeliveryType
                          [settings]="settingCbBlContainerDeliveryType"
                          (change)="
                            changeEventCbBlContainerDeliveryType($event)
                          "
                          [disableCombo]="frmContainerLock == true"
                        ></combo-plugin>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>Container Type</label>
                    <combo-plugin
                      #cbBlContainerContainerType
                      [settings]="settingCbBlContainerContainerType"
                      (change)="changeEventCbBlContainerContainerType($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div>

                  <div
                    class="field"
                    [class.error]="
                      paramContainer['error-blContainerSize'] != null &&
                      paramContainer['error-blContainerSize'] != '' &&
                      paramContainer['error-blContainerSize'] != undefined
                    "
                  >
                    <label>Container Size</label>
                    <combo-plugin
                      #cbBlContainerContainerSize
                      [settings]="settingCbBlContainerContainerSize"
                      (change)="changeEventCbBlContainerContainerSize($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Container Height</label>
                    <combo-plugin
                      #cbBlContainerContainerHeight
                      [settings]="settingCbBlContainerContainerHeight"
                      (change)="changeEventCbBlContainerContainerHeight($event)"
                      [disableCombo]="frmContainerLock == true"
                    ></combo-plugin>
                  </div>

                  <div class="field" style="margin-top: 5px">
                    <label style="display:inline-block;margin-right:5px">Nett Measurement (CMB)</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      [checked]="blContainerNettMeasureLock == true"
                      [(ngModel)]="blContainerNettMeasureLock"
                      name="blContainerNettMeasureLock"
                      (change)="changeEventBlContainerNettMeasurementLock(blContainerNettMeasureLock)"
                      [disabled]="disableBlContainerNettMeasureLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>
                    <div [class.tooltip]="showTooltip !== ''">
                      <input
                        type="text"
                        name="blContainerNettMeasureString"
                        [(ngModel)]="paramContainer['blContainerNettMeasureString']"
                        [disabled]="frmContainerLock == true"
                        (focus)="showTooltip=''"
                        (paste)="onPasteDecimalFilter($event, paramContainer, 'blContainerNettMeasureString')"
                        appFourDigitDecimalNumber
                      />
                      <span class="tooltiptext">{{ showTooltip }}</span>
                    </div>
                  </div>

                  <div class="field" style="margin-top: 5px">
                    <div class="fields">
                      <div class="eight wide field">
                        <label>OLF</label>
                        <input
                          type="number"
                          name="txtBlContainerOLF"
                          [(ngModel)]="paramContainer['blContainerOogOLF']"
                          [disabled]="frmContainerLock == true"
                          step="0.1"
                        />
                      </div>
                      <div class="eight wide field">
                        <label>OLB</label>
                        <input
                          type="number"
                          name="txtBlContainerOLB"
                          [(ngModel)]="paramContainer['blContainerOogOLB']"
                          [disabled]="frmContainerLock == true"
                          step="0.1"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div
                      class="fields"
                      style="margin-bottom: 5px"
                    >
                      <div class="eight wide field">
                        <label>F/P</label>
                        <input
                          type="text"
                          name="txtBlContainerFlashPoint"
                          [(ngModel)]="
                            paramContainer['blContainerFlashPointTemperature']
                          "
                          (keypress)="formValidation.NumWDash($event)"
                          (paste)="formValidation.pasteEventRegexWith($event, paramContainer, 'blContainerFlashPointTemperature', '[^0-9\s-]', 18)"
                          [disabled]="frmContainerLock == true"
                          maxlength="18"
                        />
                      </div>
                      <div class="eight wide field"
                           [class.error]="
                          paramContainer['error-blContainerFlashPointScale'] != null &&
                          paramContainer['error-blContainerFlashPointScale'] != '' &&
                          paramContainer['error-blContainerFlashPointScale'] != undefined
                        "
                      >
                        <label>&nbsp;</label>
                        <combo-plugin
                          #cbBlContainerFlashPointScale
                          [settings]="settingCbBlContainerFlashPointScale"
                          (change)="
                            changeEventBlContainerFlashPointScale($event)
                          "
                          [disableCombo]="frmContainerLock == true"
                        ></combo-plugin>
                        <!--
                       <div class="ui fluid selection dropdown" [class.disabled]="frmContainerLock==true">
                         <div class="text">{{blDegreeTemp}}</div>
                         <i class="dropdown icon"></i>
                         <div class="menu">
                           <div class="item" [class.active]="blDegreeTemp == 'C'" [class.selected]="blDegreeTemp == 'C'" (click)="onSelectDegreeTemp('C')">C</div>
                           <div class="item" [class.active]="blDegreeTemp == 'F'" [class.selected]="blDegreeTemp == 'F'" (click)="onSelectDegreeTemp('F')">F</div>
                         </div>
                       </div>
                       -->
                      </div>
                    </div>
                  </div>

                  <!-- GOS-662: Add "VGM" field-->
                  <div class="field" style="margin-top: 12px">
                    <label style="display:inline-block;margin-right:5px">VGM (KGS)</label>
                    <input
                      class="ui checkbox"
                      type="checkbox"
                      name="blContainerVGMLock"
                      [checked]="blContainerVGMLock == true"
                      [(ngModel)]="blContainerVGMLock"
                      (change)="changeEventBlContainerVGMLock(blContainerVGMLock)"
                      [disabled]="disableBlContainerVGMLock == true"
                    />
                    <span style="color: red;font-weight:700"> Lock</span>
                    <div [class.tooltip]="showTooltip !== ''">
                      <input
                        type="text"
                        name="blContainerVGM"
                        [(ngModel)]="paramContainer['blContainerVgm']"
                        [disabled]="frmContainerLock == true"
                        (focus)="showTooltip=''"
                        (paste)="onPasteDecimalFilter($event, paramContainer, 'blContainerVgm')"
                        appFourDigitDecimalNumber
                      />
                      <span class="tooltiptext">{{ showTooltip }}</span>
                    </div>
                  </div>

                </div>

                <div class="seven wide field" style="margin-top: 5px">

                  <!-- <div class="field" style="margin-top: 5px">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 5px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Harmonised System (HS) Reference
                      </legend>
                      <div class="field">
                        <grid-plugin
                          #gridBlContainerHS
                          [settings]="settingGridBlContainerHS"
                          (gridEvent)="gridEventBlContainerHS($event)"
                          (infoGrid)="infoGridBlContainerHS($event)"
                        ></grid-plugin>

                        <ag-grid-angular
                          style="width: 100%; height: 200px"
                          #ContainerGridHs
                          class="ag-theme-balham"
                          [rowData]="rowDataContainerHs"
                          [columnDefs]="columnDefsContainerHs"
                          (rowDoubleClicked)="gridEventBlContainerHS($event)"
                          [enableSorting]="true"
                          [enableFilter]="true"
                          [enableColResize]="true"
                        >
                        </ag-grid-angular>

                        &nbsp;
                        <div class="field">
                          <button
                            class="ui tiny button"
                            (click)="blBlContainerHSNew()"
                            [class.disabled]="btnBlContainerHSNew == false"
                          >
                            New
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div> -->

                  <div class="field" style="margin-top: 5px">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 5px"
                    >
                      <legend style="font-weight: bold;color: dodgerblue">
                        Description

                        <input
                          class="ui checkbox"
                          type="checkbox"
                          [checked]="blContainerDescPrint == true"
                          [(ngModel)]="blContainerDescPrint"
                          name="blContainerDescPrint"
                          (change)="changeEventBlContainerCheckbox('blContainerDescPrint', blContainerDescPrint)"
                          [disabled]="disableBlContainerDescPrint == true"
                        />
                        <span style="font-weight: 400;color: black"> Print &nbsp;</span>

                        <input
                          class="ui checkbox"
                          type="checkbox"
                          [checked]="blContainerDescLock == true"
                          [(ngModel)]="blContainerDescLock"
                          name="blContainerDescLock"
                          (change)="changeEventBlContainerCheckbox('blContainerDescLock', blContainerDescLock)"
                          [disabled]="disableBlContainerDescLock == true"
                        />
                        <span style="font-weight: 400;color: red"> Lock &nbsp;</span>

                      </legend>

                      <div class="field" style="width: 100%">
                        <!-- <textarea
                          wrap="off"
                          style="margin-top: 5px"
                          [(ngModel)]="paramContainerExtra['blContainerDesc']"
                          name="blContainerDesc"
                          placeholder="Input your description..."
                          [disabled]="frmContainerLock == true"
                        ></textarea> -->

                        <textarea-plugin
                          #txtBlContainerDesc
                          [settings]="settingTxtBlContainerDesc"
                          [disabled]="frmContainerLock == true"
                        ></textarea-plugin>
                      </div>
                    </fieldset>
                  </div>

                  <div class="field" style="margin-top: 5px">
                    <fieldset
                      style="border: 1px solid lightgray; margin-bottom: 5px"
                    >
                      <legend style="font-weight: bold; color: dodgerblue">
                        Additional Details

                        <input
                          class="ui checkbox"
                          type="checkbox"
                          [checked]="blContainerAdPrint == true"
                          [(ngModel)]="blContainerAdPrint"
                          name="blContainerAdPrint"
                          (change)="changeEventBlContainerCheckbox('blContainerAdPrint', blContainerAdPrint)"
                          [disabled]="disableBlContainerAdPrint == true"
                        />
                        <span style="font-weight: 400;color: black"> Print &nbsp;</span>
                      </legend>

                      <div class="field">

                        <ag-grid-angular
                          style="width: 100%; height: 200px"
                          #ADContainerGrid
                          class="ag-theme-balham"
                          [rowData]="rowDataADContainer"
                          [columnDefs]="columnDefsADContainer"
                          (rowDoubleClicked)="gridEventBlAdContainer($event)"
                          [enableSorting]="true"
                          [enableFilter]="true"
                          [enableColResize]="true"
                        >
                        </ag-grid-angular>

                        &nbsp;
                        <div class="field">
                          <!-- disable new button to add additional details -->
                          <button
                            class="ui tiny button"
                            [class.disabled]="btnBlAdContainerNew == false || true"
                            (click)="blBLAdContainerAdd()"
                          >
                            New
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>


                </div>
              </div>
            </div>

            <!--
           <div class="field" style="margin-bottom: 0px">
             <div class="fields" style="margin-bottom: 0px">
               <div class="three wide field" [class.error]="paramContainer['error-blContainerNumber'] != null && paramContainer['error-blContainerNumber'] != '' && paramContainer['error-blContainerNumber'] != undefined ">
                  <label>Container No.</label>
                   <input type="text" name="txtBlContainerNo" [(ngModel)]="paramContainer['blContainerNumber']" [disabled]="frmContainerLock==true" maxlength="20">
               </div>
               <div class="five wide field">
                 <label>Qty.</label>
                 <input type="number"  name="txtBlContainerQuantity" [(ngModel)]="paramContainer['blContainerQuantity']" [disabled]="frmContainerLock==true" >
               </div>
               <div class="three wide field">
                 <label>Receipt Type</label>
                 <combo-plugin #cbBlContainerReceiptType [settings]="settingCbBlContainerReceiptType" (change)="changeEventCbBlContainerReceiptType($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
               <div class="three wide field">
                 <label>Delivery Type</label>
                 <combo-plugin #cbBlContainerDeliveryType [settings]="settingCbBlContainerDeliveryType" (change)="changeEventCbBlContainerDeliveryType($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
             </div>
           </div>
           <div class="field" style="margin-bottom: 0px">
             <div class="fields" style="margin-bottom: 0px">
               <div class="three wide field">
                 <label>Seal No. (1)</label>
                 <input type="text" name="txtBlContainerSeal1" [(ngModel)]="paramContainer['blContainerSealNo1']" [disabled]="frmContainerLock==true" maxlength="20">
               </div>
               <div class="five wide field">
                 <label>Package Type</label>
                 <combo-plugin #cbBlContainerPackageType [settings]="settingCbBlContainerPackageType" (change)="changeEventCbBlContainerPackageType($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
               <div class="six wide field">
                 <label>Container Type</label>
                 <combo-plugin #cbBlContainerContainerType [settings]="settingCbBlContainerContainerType"(change)="changeEventCbBlContainerContainerType($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
             </div>
           </div>
           <div class="field" style="margin-bottom: 0px">
             <div class="fields" style="margin-bottom: 0px">
               <div class="five wide field">
                 <label>Seal No. (2)</label>
                 <input type="text" name="txtBlContainerSeal2" [(ngModel)]="paramContainer['blContainerSealNo2']" [disabled]="frmContainerLock==true" maxlength="20">
               </div>
               <div class="five wide field">
                 <label>Gross Weight (KGS)</label>
                 <input type="number"  name="txtBlContainerGrossWeight" [(ngModel)]="paramContainer['blContainerGrossWeight']" [disabled]="frmContainerLock==true">
               </div>
               <div class="six wide field" [class.error]="paramContainer['error-blContainerSize'] != null && paramContainer['error-blContainerSize'] != '' && paramContainer['error-blContainerSize'] != undefined ">
                 <label>Container Size</label>
                 <combo-plugin #cbBlContainerContainerSize [settings]="settingCbBlContainerContainerSize" (change)="changeEventCbBlContainerContainerSize($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
             </div>
           </div>
           <div class="field">
             <div class="fields">
               <div class="five wide field">
                 <label>Seal No. (3)(Cont. Type)</label>
                 <input type="text"  name="txtBlContainerSeal3" [(ngModel)]="paramContainer['blContainerSealNo3']" [disabled]="frmContainerLock==true" maxlength="20">
               </div>
               <div class="five wide field">
                 <label>Gross Meas (CBM)</label>
                 <input type="number"  name="txtBlContainerGrossMeas" [(ngModel)]="paramContainer['blContainerGrossMeasure']" [disabled]="frmContainerLock==true">
               </div>
               <div class="six wide field">
                 <label>Container Height</label>
                 <combo-plugin #cbBlContainerContainerHeight [settings]="settingCbBlContainerContainerHeight" (change)="changeEventCbBlContainerContainerHeight($event);" [disableCombo]="frmContainerLock==true"></combo-plugin>
               </div>
             </div>
           </div>
           -->
          </fieldset>
        </div>

        <div class="field">
          <div class="fields">
            <div class="nine wide field">
              <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
                <legend style="font-weight: bold; color: dodgerblue">
                  UC Measurements
                </legend>

                <div class="field" style="margin-top: 5px">
                  <div class="fields">
                    <div
                      class="eight wide field"
                      [class.error]="
                        paramContainer['error-UchMeasurementsValidator'] !=
                          null &&
                        paramContainer['error-UchMeasurementsValidator'] != ''
                      "
                    >
                      <label>Height</label>
                      <input
                        type="number"
                        step=".01"
                        name="uch"
                        [(ngModel)]="paramContainerExtra['blContainerUch']"
                        placeholder=""
                        [disabled]="frmContainerLock == true || paramContainer.blContainerType != 'UC' "
                        maxlength="9"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        paramContainer['error-UclMeasurementsValidator'] !=
                          null &&
                        paramContainer['error-UclMeasurementsValidator'] != ''
                      "
                    >
                      <label>Length</label>
                      <input
                        type="number"
                        step=".01"
                        name="ucl"
                        [(ngModel)]="paramContainerExtra['blContainerUcl']"
                        placeholder=""
                        [disabled]="frmContainerLock == true || paramContainer.blContainerType != 'UC' "
                        maxlength="9"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </div>
                    <div
                      class="eight wide field"
                      [class.error]="
                        paramContainer['error-UcwMeasurementsValidator'] !=
                          null &&
                        paramContainer['error-UcwMeasurementsValidator'] != ''
                      "
                    >
                      <label>Width</label>
                      <input
                        type="number"
                        step=".01"
                        name="ucw"
                        [(ngModel)]="paramContainerExtra['blContainerUcw']"
                        placeholder=""
                        [disabled]="frmContainerLock == true || paramContainer.blContainerType != 'UC' "
                        maxlength="9"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div
          class="field"
          [class.disabled]="
            paramContainer['blContainerShortShipContainer'] == false
          "
        >
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              Short Shipment Info
            </legend>
            <div class="field" class="field" style="margin-bottom: 5px">
              <div class="fields">
                <div class="five wide field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="
                        paramContainer[
                          'blContainerIsPrintShortShipmentInfoOnBL'
                        ] == true
                      "
                      [(ngModel)]="
                        paramContainer[
                          'blContainerIsPrintShortShipmentInfoOnBL'
                        ]
                      "
                      name="chBlContainerPrintShortshipmentInformationOnBl"
                      (change)="
                        changeEventChBlContainerPrintShortShipment($event)
                      "
                    />
                    <label>Print Short Shipment Info on B/L</label>
                  </div>
                </div>
                <div class="one wide field">
                  <div class="ui radio checkbox">
                    <input
                      type="radio"
                      name="rbBlContainerShortShipmentTo"
                      [checked]="
                        paramContainer['blContainerShortShipmentTo'] == true
                      "
                      (change)="changeEventRbBlContainerShortShipmentTo($event)"
                    />
                    <label>To</label>
                  </div>
                </div>
                <div class="one wide field">
                  <div class="ui radio checkbox">
                    <input
                      type="radio"
                      name="rbBlContainerShortShipmentFrom"
                      [checked]="
                        paramContainer['blContainerShortShipmentFrom'] == true
                      "
                      (change)="
                        changeEventRbBlContainerShortShipmentFrom($event)
                      "
                    />
                    <label>From</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields">
                <div
                  class="five wide field"
                  [class.error]="
                    paramContainer['error-blContainerShortshipment'] != null &&
                    paramContainer['error-blContainerShortshipment'] != '' &&
                    paramContainer['error-blContainerShortshipment'] !=
                      undefined
                  "
                >
                  <label>B/L Ref.</label>
                  <input
                    type="text"
                    name="txtBlContainerShortShipmentBlRef"
                    [(ngModel)]="
                      paramContainer['blContainerShortShipmentBlRef']
                    "
                    (blur)="checkShortshipmentBlData()"
                  />
                </div>
                <div class="four wide field">
                  <label>Vessel</label>
                  <combo-plugin
                    #cbBlContainerShortShipmentVessel
                    [settings]="settingCbBlContainerShortShipmentVessel"
                    (change)="
                      changeEventCbBlContainerShortShipmentVessel($event)
                    "
                    [disableCombo]="
                      paramContainer['blContainerShortShipmentFrom'] == true
                    "
                  ></combo-plugin>
                </div>
                <div class="four wide field">
                  <label>Voyage</label>
                  <combo-plugin
                    #cbBlContainerShortShipmentVoyage
                    [settings]="settingCbBlContainerShortShipmentVoyage"
                    (change)="
                      changeEventCbBlContainerShortShipmentVoyage($event)
                    "
                    [disableCombo]="
                      paramContainer['blContainerShortShipmentFrom'] == true
                    "
                  ></combo-plugin>
                </div>
                <!--
               <div class="four wide field">
                 <label>Vessel</label>
                 <input  type="text" name="txtBlContainerShortShipmentVessel" [(ngModel)]="model['blShortShipVesselName']" [disabled]="true" style="background-color:lightgray;">
               </div>
               <div class="four wide field">
                 <label>Voyage</label>
                 <input  type="text" name="txtBlContainerShortShipmentVoyage" [(ngModel)]="model['blShortShipVesselVoyage']" [disabled]="true" style="background-color:lightgray;">
               </div>
               -->
                <div class="three wide field">
                  <label>Bound</label>
                  <input
                    type="text"
                    name="txtBlContainerShortShipmentBound"
                    [(ngModel)]="
                      paramContainer['blContainerShortShipmentVesselBound']
                    "
                    [disabled]="
                      paramContainer['blContainerShortShipmentFrom'] == true ||
                      this.paramContainer.blContainerShortShipmentVesselBound ==
                        'O'
                    "
                    (change)="changeBlShortShipmentOceanBound()"
                    maxlength="1"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div
          class="field"
          [class.disabled]="
          paramContainer['blContainerIsReworkedContainer'] == false
        "
          [style.display]="isReworkedChecked ? 'block' : 'none'"
        >
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              Reworked Info
            </legend>
            <div class="field" class="field" style="margin-bottom: 5px">
              <div class="fields">
                <div class="five wide field">
                  <div class="ui checkbox">
                    <input
                      type="checkbox"
                      [checked]="
                      paramContainer[
                        'blContainerIsPrintReworkedInfoOnBL'
                      ] == true
                    "
                      [(ngModel)]="
                      paramContainer[
                        'blContainerIsPrintReworkedInfoOnBL'
                      ]
                    "
                      name="blContainerIsPrintReworkedInfoOnBL"
                      (change)="
                      changeEventChBlContainerPrintReworkedInfoOnBL($event)
                    "
                    />
                    <label>Print Reworked Info on B/L</label>
                  </div>
                </div>
                <div class="ten  wide field">
                  <div class="ui radio checkbox mr-40px">
                    <input
                      type="radio"
                      name="blContainerReworkedTo"
                      [checked]="
                      paramContainer['blContainerReworkedTo'] == true
                    "
                      (change)="changeEventblContainerReworkedTo($event)"
                    />
                    <label>
                      To
                      <span class="color-al-blue">(POL Insert Memo B/L Ref.)</span>
                    </label>
                  </div>
                  <div class="ui radio checkbox">
                    <input
                      type="radio"
                      name="blContainerReworkedFrom"
                      [checked]="
                      paramContainer['blContainerReworkedFrom'] == true
                    "
                      (change)="
                      changeEventblContainerReworkedFrom($event)
                    "
                    />
                    <label>
                      From
                      <span class="color-al-blue">(POT Insert Thru B/L Ref.)</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields">
                <div class="five wide field">
                  <div class="fields">
                    <div class="sixteen wide field">
                      <label>Container No.</label>
                      <input
                        #containerNoReworked
                        type="text"
                        name="blContainerReworkedContainerNumber"
                        [(ngModel)]="
                          paramContainer['blContainerReworkedContainerNumber']
                        "
                      />
                    </div>
                    <div class="sixteen wide field">
                      <label>B/L Ref.</label>
                      <input
                        type="text"
                        name="txtblContainerReworkedBlRef"
                        [(ngModel)]="
                          paramContainer['blContainerReworkedBlRef']
                        "
                        (blur)="checkReworkedFrom()"
                      />
                    </div>
                  </div>
                </div>
                <div class="four wide field">
                  <label>Vessel</label>
                  <combo-plugin
                    #cbBlContainerReworkedVessel
                    [settings]="settingCbBlContainerReworkedVessel"
                    (change)="
                    changeEventCbBlContainerReworkedVessel($event)
                  "
                    [disableCombo]="
                    paramContainer['blContainerReworkedFrom'] == true
                  "
                  ></combo-plugin>
                </div>
                <div class="four wide field">
                  <label>Voyage</label>
                  <combo-plugin
                    #cbBlContainerReworkedVoyage
                    [settings]="settingCbBlContainerReworkedVoyage"
                    (change)="
                    changeEventCbBlContainerReworkedVoyage($event)
                  "
                    [disableCombo]="
                    paramContainer['blContainerReworkedFrom'] == true
                  "
                  ></combo-plugin>
                </div>
                <div class="three wide field">
                  <label>Bound</label>
                  <input
                    type="text"
                    name="txtBlContainerReworkedBound"
                    [(ngModel)]="
                    paramContainer['blContainerReworkedBound']
                  "
                    [disabled]="
                    paramContainer['blContainerReworkedFrom'] == true ||
                    this.paramContainer.blContainerReworkedBound ==
                      'O'
                  "
                    (change)="changeBlReworkedOceanBound()"
                    maxlength="1"
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>

      </div>
      <!-- Tab Container End -->

      <!-- Tab Mark No Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus4 == 'active' || tabStatus == 'tab4'"
        id="blMarkNoTab"
        data-tab="blMarkNoTab"
      >
        <!-- dont change || -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab4 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>
        <div class="field">
          <grid-plugin
            #gridBlMarkNo
            [settings]="settingGridBlMarkNo"
            (gridEvent)="gridEventBlMarkNo($event)"
            (infoGrid)="infoGridBlMarkNo($event)"
          ></grid-plugin>
        </div>
        <div class="field" style="margin-bottom: 0px">
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              BL Mark No. Entry
            </legend>
            <div class="field">
              <button
                class="ui button"
                (click)="eventBtnBlMarkNoNew()"
                [class.disabled]="btnBlMarkNoNew == false"
              >
                New
              </button>
              <button
                class="ui button"
                (click)="eventBtnBlMarkNoSave()"
                [class.disabled]="btnBlMarkNoSave == false"
              >
                {{ txtBlMarkNoSaveUpdate }}
              </button>
              <button
                class="ui button"
                (click)="eventBtnBlMarkNoDelete()"
                [class.disabled]="btnBlMarkNoDelete == false"
              >
                Delete
              </button>
              <button
                style="z-index: 10; position: relative"
                class="ui button"
                (click)="eventBtnBlMarkNoCancel()"
                [class.disabled]="btnBlMarkNoCancel == false"
              >
                Cancel
              </button>
              <button
                class="ui button"
                (click)="eventBtnBlMarkNoNoMark()"
                [class.disabled]="btnBlMarkNoNoMark == false"
              >
                No Mark
              </button>
            </div>

            <div
              class="ui error message"
              [class.errorMarkNo]="isErrorMarkNo == true"
            >
              <ul>
                <li
                  [class.hidden]="
                    paramMarkNo['error-blMarkNoDescription'] == null ||
                    paramMarkNo['error-blMarkNoDescription'] == ''
                  "
                >
                  {{ paramMarkNo["error-blMarkNoDescription"] }}
                </li>
              </ul>
            </div>

            <div class="field" style="margin-bottom: 5px">
              <div class="fields" style="margin-bottom: 0px">
                <div class="eight wide field">
                  <label>Seq</label>
                  <input
                    type="text"
                    name="txtBlMarkNoSequence"
                    [(ngModel)]="paramMarkNo['blMarkNoSeq']"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div class="field" style="margin-bottom: 5px">
              <div class="fields" style="margin-bottom: 0px">
                <div class="eight wide field">
                  <textarea-v2-plugin
                    #txtBlMarkNoDesc
                    [settings]="settingTxtBlMarkNoDesc"
                    [disabled]="frmMarkNoLock == true"
                  ></textarea-v2-plugin>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- Tab Mark No End -->

      <!-- Tab Desc Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus5 == 'active' || tabStatus == 'tab5'"
        id="blDescTab"
        data-tab="blDescTab"
      >
        <!-- dont change || -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab5 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="field">
          <grid-plugin
            #gridBlDesc
            [settings]="settingGridBlDesc"
            (gridEvent)="gridEventBlDesc($event)"
            (infoGrid)="infoGridBlDesc($event)"
          ></grid-plugin>
        </div>
        <div
          class="field"
          [style.display]="
            modeFormBlDesc == '' || modeFormBlDesc == 'view' ? 'block' : 'none'
          "
        >
          <div class="field">
            <div
              class="ui button"
              (click)="btnBlDescNew()"
              [class.disabled]="true"
            >
              New
            </div>
          </div>
          <div class="field">
            <div class="fields">
              <div class="eight wide field">
                <div class="field">
                  <label>Total No. of Containers or Packages in words</label>
                  <!--
                  <textarea-plugin #txtBlDescTotalContainerPackageInWord [settings]="settingTxtBlDescTotalContainerPackageInWord" [disabled]="btnBlDescClauseCancel==true"></textarea-plugin>
                  -->
                  <textarea-v2-plugin
                    #txtBlDescTotalContainerPackageInWord
                    [settings]="settingTxtBlDescTotalContainerPackageInWord"
                    [disabled]="btnBlDescClauseCancel == true"
                  ></textarea-v2-plugin>
                </div>
                <div class="field" style="margin-bottom: 5px">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <div
                        class="ui checkbox"
                        style="margin-top: 20px"
                        [class.disabled]="btnBlDescClauseCancel == true"
                      >
                        <input
                          type="checkbox"
                          [checked]="model['blIsPrintAgentInfoOnBl'] == true"
                          [(ngModel)]="model['blIsPrintAgentInfoOnBl']"
                          name="chBlIsPrintAgentInfoOnBl"
                          (change)="changeEventChBlIsPrintAgentInfoOnBl($event)"
                          tabindex="0"
                        />
                        <label>Print Agent info On B/L</label>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label>Agent's Address ID</label>
                      <combo-plugin
                        #cbBlDescAgentId
                        [settings]="settingCbBlDescAgentId"
                        (change)="changeEventChBlDescAgentAddress($event)"
                        [disableCombo]="
                          model['blIsPrintAgentInfoOnBl'] == false ||
                          btnBlDescClauseCancel == true
                        "
                      ></combo-plugin>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label>Agent's Name & Address</label>
                  <!--
                  <textarea-plugin #txtBlDescAgentNameAddress [settings]="settingTxtBlDescAgentNameAddress" [disabled]="true"></textarea-plugin>
                  -->
                  <textarea-v2-plugin
                    #txtBlDescAgentNameAddress
                    [settings]="settingTxtBlDescAgentNameAddress"
                    [disabled]="true"
                  ></textarea-v2-plugin>
                </div>
                <div class="field" style="margin-top: 5px">
                  <div class="fields">
                    <div class="eight wide field">
                      <fieldset
                        style="border: 1px solid lightgray; margin-bottom: 5px"
                      >
                        <legend style="font-weight: bold; color: dodgerblue">
                          Exporter
                        </legend>
                        <div class="field">
                          <div class="fields">
                            <div class="twelve wide field">
                              <label>Tax Registration Number</label>
                              <input
                                type="text"
                                name="exporterTaxRegNumber"
                                [(ngModel)]="model['blExporterTaxNumber']"
                                [disabled]="btnBlDescClauseCancel == true"
                                maxlength="50"
                              />
                            </div>
                            <div
                              class="four wide field"
                              style="margin-left: -8px"
                            >
                              <div
                                class="ui checkbox"
                                style="margin-top: 20px"
                                [class.disabled]="btnBlDescClauseCancel == true"
                              >
                                <input
                                  type="checkbox"
                                  [checked]="
                                    model['blExporterTaxNumberPrint'] == true
                                  "
                                  [(ngModel)]="
                                    model['blExporterTaxNumberPrint']
                                  "
                                  name="chBlExporterTaxNumberPrint"
                                  (change)="
                                    changeEventChBlExporterPrint($event)
                                  "
                                  tabindex="0"
                                />
                                <label>Print</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="eight wide field">
                      <fieldset
                        style="border: 1px solid lightgray; margin-bottom: 5px"
                      >
                        <legend style="font-weight: bold; color: dodgerblue">
                          Importer
                        </legend>
                        <div class="field">
                          <div class="fields">
                            <div class="twelve wide field">
                              <label>Tax Registration Number</label>
                              <input
                                type="text"
                                name="importerTaxRegNumber"
                                [(ngModel)]="model['blImporterTaxNumber']"
                                [disabled]="btnBlDescClauseCancel == true"
                                maxlength="50"
                              />
                            </div>
                            <div
                              class="four wide field"
                              style="margin-left: -8px"
                            >
                              <div
                                class="ui checkbox"
                                style="margin-top: 20px"
                                [class.disabled]="btnBlDescClauseCancel == true"
                              >
                                <input
                                  type="checkbox"
                                  [checked]="
                                    model['blImporterTaxNumberPrint'] == true
                                  "
                                  [(ngModel)]="
                                    model['blImporterTaxNumberPrint']
                                  "
                                  name="chBlImporterTaxNumberPrint"
                                  (change)="
                                    changeEventChBlImporterPrint($event)
                                  "
                                  tabindex="0"
                                />
                                <label>Print</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
              <div class="eight wide field">
                <!-- desc right pane -->
                <div class="field">
                  <div class="fields">
                    <div class="five wide field">
                      <label>Declared Value (US$)</label>
                      <input
                        type="text"
                        name="txtBlDeclaredValue"
                        name="txtBlDescDeclaredValue"
                        [(ngModel)]="model['blDeclaredValue']"
                        [disabled]="btnBlDescClauseCancel == true"
                        maxlength="10"
                        (keypress)="keypressDeclaredValue($event)"
                      />
                    </div>
                    <div class="four wide field">
                      <div
                        class="ui checkbox"
                        style="margin-top: 20px"
                        [class.disabled]="btnBlDescClauseCancel == true"
                      >
                        <input
                          type="checkbox"
                          [checked]="model['blIsIrePermit'] == true"
                          [(ngModel)]="model['blIsIrePermit']"
                          name="chBlIsIrePermit"
                          (change)="changeEventChBlIsIrePermit($event)"
                          tabindex="0"
                        />
                        <label>IRE Permit</label>
                      </div>
                    </div>
                    <div class="seven wide field">
                      <div
                        class="ui checkbox"
                        style="margin-top: 20px"
                        [class.disabled]="btnBlDescClauseCancel == true"
                      >
                        <input
                          type="checkbox"
                          [checked]="model['blIsSingleConsigneePermit'] == true"
                          [(ngModel)]="model['blIsSingleConsigneePermit']"
                          name="chBlIsSingleConsigneePermit"
                          (change)="
                            changeEventChBlIsSingleConsigneePermit($event)
                          "
                          tabindex="0"
                        />
                        <label>Single Consignee Permit</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <grid-plugin
                    #gridBlDescClause
                    [settings]="settingGridBlDescClause"
                    (gridEvent)="gridEventBlDescClause($event)"
                    (infoGrid)="infoGridBlClause($event)"
                  ></grid-plugin>
                </div>
                <div class="field" style="margin-top: 5px">
                  <fieldset
                    style="border: 1px solid lightgray; margin-bottom: 5px"
                  >
                    <legend style="font-weight: bold; color: dodgerblue">
                      B/L Clause
                    </legend>
                    <div class="field">
                      <button
                        class="ui small button"
                        (click)="onBlDescClauseNew()"
                        [class.disabled]="btnBlDescClauseNew == false"
                      >
                        New
                      </button>
                      <button
                        class="ui small button"
                        (click)="onBlDescClauseUpdate()"
                        [class.disabled]="btnBlDescClauseSave == false"
                      >
                        Update
                      </button>
                      <button
                        class="ui small button"
                        (click)="onBlDescClauseDelete()"
                        [class.disabled]="btnBlDescClauseDelete == false"
                      >
                        Delete
                      </button>
                      <button
                        class="ui small button"
                        (click)="onBlDescClauseCancel()"
                        [class.disabled]="btnBlDescClauseCancel == false"
                      >
                        Cancel
                      </button>
                    </div>

                    <div
                      class="ui error message"
                      [class.errorDescClause]="isErrorDescClause == true"
                    >
                      <ul>
                        <li
                          [class.hidden]="
                            paramDescClause['error-blDescClause'] == null ||
                            paramDescClause['error-blDescClause'] == ''
                          "
                        >
                          {{ paramDescClause["error-blDescClause"] }}
                        </li>
                      </ul>
                    </div>

                    <div
                      class="field"
                      style="margin-bottom: 5px; margin-top: 5px"
                    >
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="eight wide field">
                          <label>Seq</label>
                          <input
                            type="text"
                            name="txtBlDescBlClauseSeq"
                            [(ngModel)]="paramDescClause['blDescClauseSeq']"
                            disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="field" style="margin-bottom: 0px">
                      <label>Clause</label>
                      <!--
                      <textarea-plugin #txtBlDescClauseDescription [settings]="settingTxtBlDescClauseDescription" [disabled]="frmDescClauseLock==true"></textarea-plugin>
                      -->
                      <combo-plugin
                        #cbBlDescClauses
                        [settings]="settingCbBlDescClauses"
                        (change)="changeEventCbBlDescClauses($event)"
                        [disableCombo]="frmDescClauseLock == true"
                      ></combo-plugin>
                    </div>
                  </fieldset>
                </div>
              </div>
              <!-- end desc right pane -->
            </div>
          </div>
        </div>

        <div
          class="field"
          [style.display]="
            modeFormBlDesc == '' || modeFormBlDesc == 'edit' ? 'block' : 'none'
          "
        >
          <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
            <legend style="font-weight: bold; color: dodgerblue">
              B/L Desc Entity
            </legend>
            <div class="field">
              <div
                class="ui button"
                (click)="btnBlDescUpdate()"
                [class.disabled]="blDescUpdate == false"
              >
                Update
              </div>
              <div
                class="ui button"
                (click)="btnBlDescDelete()"
                [class.disabled]="blDescDelete == false"
              >
                Delete
              </div>
              <div
                style="z-index: 10; position: relative"
                class="ui button"
                (click)="btnBlDescCancel()"
                [class.disabled]="blDescCancel == false"
              >
                Cancel
              </div>
            </div>
            <div
              class="ui error message"
              [class.errorDesc]="isErrorDesc == true"
            >
              <ul>
                <li
                  [class.hidden]="
                    paramDesc['error-blDescWeight'] == null ||
                    paramDesc['error-blDescWeight'] == ''
                  "
                >
                  {{ paramDesc["error-blDescWeight"] }}
                </li>
                <li
                  [class.hidden]="
                    paramDesc['error-blDescMeasurement'] == null ||
                    paramDesc['error-blDescMeasurement'] == ''
                  "
                >
                  {{ paramDesc["error-blDescMeasurement"] }}
                </li>
                <li
                  [class.hidden]="
                    paramDesc['error-blDescOuterQuantity'] == null ||
                    paramDesc['error-blDescOuterQuantity'] == ''
                  "
                >
                  {{ paramDesc["error-blDescOuterQuantity"] }}
                </li>
                <li
                  [class.hidden]="
                    paramDesc['error-blDescSumChars'] == null ||
                    paramDesc['error-blDescSumChars'] == ''
                  "
                >
                  {{ paramDesc["error-blDescSumChars"] }}
                </li>

                <ng-container *ngIf="paramDescValidateMessage.length > 0">
                  <li *ngFor="let err of paramDescValidateMessage">
                    {{ err }}
                  </li>
                </ng-container>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescPSAPackageType'] == null ||
                      paramDesc['error-cbBlDescPSAPackageType'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescPSAPackageType"] }}
                </li>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescInnerPkgType'] == null ||
                      paramDesc['error-cbBlDescInnerPkgType'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescInnerPkgType"] }}
                </li>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescOuterPkgType'] == null ||
                      paramDesc['error-cbBlDescOuterPkgType'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescOuterPkgType"] }}
                </li>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescReceiptType'] == null ||
                      paramDesc['error-cbBlDescReceiptType'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescReceiptType"] }}
                </li>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescDeliveryType'] == null ||
                      paramDesc['error-cbBlDescDeliveryType'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescDeliveryType"] }}
                </li>

                <li [class.hidden]="
                      paramDesc['error-cbBlDescCommodityCode'] == null ||
                      paramDesc['error-cbBlDescCommodityCode'] == ''
                    ">
                  {{ paramDesc["error-cbBlDescCommodityCode"] }}
                </li>

              </ul>
            </div>

            <div class="field" style="margin-bottom: 5px">
              <!-- divide the section into 3 columns -->
              <div class="fields">
                <div class="five wide field">
                  <!-- 1st column -->
                  <div class="field" style="margin-bottom: 5px">
                    <label>Seq</label>
                    <input
                      type="text"
                      name="txtBlDescSequence"
                      [(ngModel)]="paramDesc['blDescSeq']"
                      disabled
                    />
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <label>Inner Quantity</label>
                    <input
                      type="number"
                      name="txtBlDescNetInnerQty"
                      [(ngModel)]="paramDesc['blDescInnerQuantity']"
                      min="0"
                      maxlength="18"
                      (keypress)="formValidation.keypressEventRegexWith($event, paramDesc, 'blDescInnerQuantity', '[0-9\s]')"
                      (paste)="formValidation.pasteEventRegexWith($event, paramDesc, 'blDescInnerQuantity', '[^0-9\s]', 18)"
                    />
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <label>Inner Package Type</label>
                    <combo-plugin
                      #cbBlDescInnerPkgType
                      [settings]="settingCbBlDescInnerPkgType"
                      (change)="changeEventCbBlDescInnerPkgType($event)"
                      [disableCombo]="isDisableInnerPkg"
                      (keypress)="keypressPackageType($event, formValidation.regAlphabetsCommaDash)"
                      (keyup)="backspacePackageType($event, 'cbBlDescInnerPkgType', formValidation.regAlphabetsCommaDash)"
                      (paste)="onPasteValidationPackageType($event, 'cbBlDescInnerPkgType', formValidation.regAlphabetsCommaDash)"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="ten wide field" style="margin-bottom: 0px">
                        <label style="display:inline-block;margin-right:5px">Outer Quantity</label>
                        <div class="ui checkbox">
                          <input
                            type="checkbox"
                            [checked]="chkBlDescOuterQuantityPrint == true"
                            [(ngModel)]="chkBlDescOuterQuantityPrint"
                            name="blDescOuterQuantityPrint"
                            (change)="changeEventBlDescOuterQtyPrint(chkBlDescOuterQuantityPrint)"
                            tabindex="0"
                          />
                          <span style="margin-left:25px;">Print</span>
                        </div>
                        <input
                          type="number"
                          name="txtBlDescOuterQuantity"
                          [(ngModel)]="paramDesc['blDescOuterQuantity']"
                          min="0"
                          maxlength="18"
                          (keypress)="formValidation.keypressEventRegexWith($event, paramDesc, 'blDescOuterQuantity', '[0-9\s]')"
                          (paste)="formValidation.pasteEventRegexWith($event, paramDesc, 'blDescOuterQuantity', '[^0-9\s]', 18)"
                        />
                      </div>
                      <div class="six wide field" style="margin-bottom: 0px">
                        <div class="ui checkbox" style="margin-top: 25px">
                          <input
                            type="checkbox"
                            [checked]="
                              paramDesc['blDescOuterQuantityLock'] == true
                            "
                            [(ngModel)]="paramDesc['blDescOuterQuantityLock']"
                            name="chBlDescOuterQuantityLock"
                            (change)="
                              changeEventChBlDescOuterQuantityLock($event)
                            "
                            tabindex="0"
                          />
                          <label style="color: red">Lock</label>
                        </div>
                      </div>
                    </div>
                    <div class="field" style="margin-bottom: 5px; margin-top: 5px">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="ten wide field" style="margin-bottom: 0px">
                          <label style="display:inline-block;margin-right:5px">Outer Package Type</label>
                          <div class="ui checkbox">
                            <input
                              type="checkbox"
                              [checked]="chkBlDescOuterPkgTypePrint == true"
                              [(ngModel)]="chkBlDescOuterPkgTypePrint"
                              name="blDescOuterPkgTypePrint"
                              (change)="changeEventBlDescOuterPkgPrint(chkBlDescOuterPkgTypePrint)"
                            />
                            <span style="margin-left:25px;">Print</span>
                          </div>
                          <combo-plugin
                            #cbBlDescOuterPkgType
                            [settings]="settingCbBlDescOuterPkgType"
                            (change)="changeEventCbBlDescOuterPkgType($event)"
                            (keypress)="keypressPackageType($event, formValidation.regAlphabetsCommaDash)"
                            (keyup)="backspacePackageType($event, 'cbBlDescOuterPkgType', formValidation.regAlphabetsCommaDash)"
                            (paste)="onPasteValidationPackageType($event, 'cbBlDescOuterPkgType', formValidation.regAlphabetsCommaDash)"
                          ></combo-plugin>
                        </div>
                        <div class="six wide field" style="margin-bottom: 0px">
                          <div class="ui checkbox" style="margin-top: 25px">
                            <input
                              type="checkbox"
                              [checked]="
                                paramDesc['blDescOuterPkgTypeLock'] == true
                              "
                              [(ngModel)]="paramDesc['blDescOuterPkgTypeLock']"
                              name="chBlDescOuterPkgType"
                              (change)="changeEventChBlDescOuterPkgType($event)"
                              tabindex="0"
                            />
                            <label style="color: red">Lock</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="five wide field">
                  <!-- 2nd column -->
                  <div class="field" style="margin-bottom: 5px">
                    <label>PSA Package Type</label>
                    <combo-plugin
                      #cbBlDescPSAPackageType
                      [settings]="settingCbBlDescPSAPackageType"
                      (change)="changeEventCbBlDescPSAPackageType($event)"
                      (keypress)="keypressPackageType($event, formValidation.regAlphabetsCommaDash)"
                      (keyup)="backspacePackageType($event, 'cbBlDescPSAPackageType', formValidation.regAlphabetsCommaDash)"
                      (paste)="onPasteValidationPackageType($event, 'cbBlDescPSAPackageType', formValidation.regAlphabetsCommaDash)"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="ten wide field" style="margin-bottom: 0px">
                        <label>Gross Weight</label>
                        <!-- <input
                          type="text"
                          name="txtBlDescGrossWt"
                          (keypress)="NumDotOnly($event)"
                          [(ngModel)]="paramDesc['blDescDg2']"
                          (change)="changeGrossWeightNum($event)"
                          (focusout)="changeGrossWeightNum($event)"
                          (paste)="pasteEvent($event, 'blDescDg2')"
                        /> -->
                        <div [class.tooltip]="showTooltip !== ''">
                          <input
                            type="text"
                            name="blDescDg2"
                            [(ngModel)]="paramDesc['blDescDg2']"
                            (focus)="showTooltip=''"
                            (paste)="onPasteDecimalFilter($event, paramDesc, 'blDescDg2')"
                            appFourDigitDecimalNumberWithCommasSeparator
                          />
                          <span class="tooltiptext">{{ showTooltip }}</span>
                        </div>
                      </div>
                      <div class="six wide field" style="margin-bottom: 0px">
                        <div class="ui checkbox" style="margin-top: 25px">
                          <input
                            type="checkbox"
                            [checked]="
                              paramDesc['blDescGrossWeightLock'] == true
                            "
                            [(ngModel)]="paramDesc['blDescGrossWeightLock']"
                            name="chBlDescGrossWeightLock"
                            (change)="
                              changeEventChBlDescGrossWeightLock($event)
                            "
                            tabindex="0"
                          />
                          <label style="color: red">Lock</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="ten wide field" style="margin-bottom: 0px">
                        <label>Nett Weight</label>
                        <div [class.tooltip]="showTooltip !== ''">
                          <input
                            type="text"
                            name="blDescDg2R"
                            [(ngModel)]="paramDesc['blDescDg2R']"
                            (focus)="showTooltip=''"
                            (paste)="onPasteDecimalFilter($event, paramDesc, 'blDescDg2R')"
                            appFourDigitDecimalNumberWithCommasSeparator
                          />
                          <span class="tooltiptext">{{ showTooltip }}</span>
                        </div>
                      </div>
                      <div class="six wide field" style="margin-bottom: 0px">
                        <div class="ui checkbox" style="margin-top: 25px">
                          <input
                            type="checkbox"
                            [checked]="
                              paramDesc['blDescNettWeightLock'] == true
                            "
                            [(ngModel)]="paramDesc['blDescNettWeightLock']"
                            name="chblDescNettWeightLock"
                            (change)="
                              changeEventChBlDescNettWeightLock($event)
                            "
                            tabindex="0"
                          />
                          <label style="color: red">Lock</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="ten wide field" style="margin-bottom: 0px">
                        <label>Gross Measurement</label>
                        <!-- <input
                          type="text"
                          name="txtBlDescGrossMeas"
                          (keypress)="NumDotOnly($event)"
                          [(ngModel)]="paramDesc['blDescAwkward']"
                          (change)="changeGrossMeasureNum($event)"
                          (focusout)="changeGrossMeasureNum($event)"
                          (paste)="pasteEvent($event, 'blDescAwkward')"
                        /> -->
                        <div [class.tooltip]="showTooltip !== ''">
                          <input
                            type="text"
                            name="blDescAwkward"
                            [(ngModel)]="paramDesc['blDescAwkward']"
                            (focus)="showTooltip=''"
                            (paste)="onPasteDecimalFilter($event, paramDesc, 'blDescAwkward')"
                            appFourDigitDecimalNumber
                          />
                          <span class="tooltiptext">{{ showTooltip }}</span>
                        </div>
                      </div>
                      <div class="six wide field" style="margin-bottom: 0px">
                        <div class="ui checkbox" style="margin-top: 25px">
                          <input
                            type="checkbox"
                            [checked]="paramDesc['blDescGrossMeasLock'] == true"
                            [(ngModel)]="paramDesc['blDescGrossMeasLock']"
                            name="chBlDescGrossMeasLock"
                            (change)="changeEventChBlDescGrossMeasLock($event)"
                            tabindex="0"
                          />
                          <label style="color: red">Lock</label>
                        </div>
                      </div>
                    </div>
                    <!-- GOS-663: Add "VGM" field-->
                    <div class="field" style="margin-bottom: 5px; margin-top: 5px">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="ten wide field" style="margin-bottom: 0px">
                          <label>VGM</label>
                          <div [class.tooltip]="showTooltip !== ''">
                            <input
                              type="text"
                              name="blDescVGM"
                              [(ngModel)]="paramDesc['blDescVgm']"
                              (focus)="showTooltip=''"
                              (paste)="onPasteDecimalFilter($event, paramDesc, 'blDescVgm')"
                              appFourDigitDecimalNumber
                            />
                            <span class="tooltiptext">{{ showTooltip }}</span>
                          </div>
                        </div>
                        <div class="six wide field" style="margin-bottom: 0px">
                          <div class="ui checkbox" style="margin-top: 25px">
                            <input
                              type="checkbox"
                              [checked]="blDescVgmLock == true"
                              [(ngModel)]="blDescVgmLock"
                              name="chBlDescblDescVgm"
                              (change)="changeEventChBlDescblDescVgm(blDescVgmLock)"
                              tabindex="0"
                            />
                            <label style="color: red">Lock</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="six wide field">
                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="ten wide field" style="margin-bottom: 0px">
                        <label>Nett Measurement</label>
                        <div [class.tooltip]="showTooltip !== ''">
                          <input
                            type="text"
                            name="blDescNettMeasure"
                            [(ngModel)]="paramDesc['blDescNettMeasure']"
                            (focus)="showTooltip=''"
                            (paste)="onPasteDecimalFilter($event, paramDesc, 'blDescNettMeasure')"
                            appFourDigitDecimalNumberWithCommasSeparator
                          />
                          <span class="tooltiptext">{{ showTooltip }}</span>
                        </div>
                      </div>
                      <div class="six wide field" style="margin-bottom: 0px">
                        <div class="ui checkbox" style="margin-top: 25px">
                          <input
                            type="checkbox"
                            [checked]="
                              paramDesc['blDescNettMeasLock'] == true
                            "
                            [(ngModel)]="paramDesc['blDescNettMeasLock']"
                            name="chblDescNettMeasLock"
                            (change)="
                            changeEventChBlDescNettMeasurementLock($event)
                            "
                            tabindex="0"
                          />
                          <label style="color: red">Lock</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field" style="margin-bottom: 5px">
                    <label>Receipt Type</label>
                    <combo-plugin
                      #cbBlDescReceiptType
                      [settings]="settingCbBlDescReceiptType"
                      (change)="changeEventCbBlDescReceiptType($event)"
                      (keypress)="keypressPackageType($event, formValidation.regStringSpacesOnly)"
                      (keyup)="backspacePackageType($event, 'cbBlDescReceiptType', formValidation.regStringSpacesOnly)"
                      (paste)="onPasteValidationPackageType($event, 'cbBlDescReceiptType', formValidation.regStringSpacesOnly)"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <label>Delivery Type</label>
                    <combo-plugin
                      #cbBlDescDeliveryType
                      [settings]="settingCbBlDescDeliveryType"
                      (change)="changeEventCbBlDescDeliveryType($event)"
                      (keypress)="keypressPackageType($event, formValidation.regStringSpacesOnly)"
                      (keyup)="backspacePackageType($event, 'cbBlDescDeliveryType', formValidation.regStringSpacesOnly)"
                      (paste)="onPasteValidationPackageType($event, 'cbBlDescDeliveryType', formValidation.regStringSpacesOnly)"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <label>Commodity Type</label>
                    <combo-plugin
                      #cbBlDescCommodityCode
                      [settings]="settingCbBlDescCommodityCode"
                      (change)="changeEventCbBlDescCommodityCode($event)"
                      (keypress)="keypressPackageType($event, formValidation.regStringSpacesOnly)"
                      (keyup)="backspacePackageType($event, 'cbBlDescCommodityCode', formValidation.regStringSpacesOnly)"
                      (paste)="onPasteValidationPackageType($event, 'cbBlDescCommodityCode', formValidation.regStringSpacesOnly)"
                    ></combo-plugin>
                  </div>
                  <div class="field" style="margin-bottom: 5px">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="sixteen wide field">
                        <label>PEB</label>
                        <input
                          type="text"
                          name="txtblDescBlDutiable"
                          [(ngModel)]="paramDesc['blDescBlDutiable']"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="fields">
                <div class="ten wide field">
                  <label style="width: 175px; display: inline-table"
                  >Description of Goods</label
                  ><label
                  style="
                      font-weight: 600;
                      color: red;
                      display: inline;
                      width: 148px;
                    "
                >[Please hit Enter key when nearing the red line.]</label
                >
                  <textarea-plugin
                    #txtBlDescDescriptionOfGoods
                    [settings]="settingTxtBlDescDescriptionOfGoods"
                  ></textarea-plugin>
                </div>
                <div class="six wide field">
                  <div class="ui checkbox" style="margin-top: 25px">
                    <input
                      type="checkbox"
                      [checked]="paramDesc['blDescDescriptionLock'] == true"
                      [(ngModel)]="paramDesc['blDescDescriptionLock']"
                      name="chBlDescription"
                      (change)="changeEventChBlDescriptionLock($event)"
                      tabindex="0"
                    />
                    <label style="color: red">Lock</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- Tab Desc End -->

      <!-- Tab Nett Charge Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus6 == 'active' || tabStatus == 'tab6'"
        id="blNettChargeTab"
        data-tab="blNettChargeTab"
      >
        <!-- dont change || -->
        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab6 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div
          class="ui error message"
          [class.errorNettCharge]="isErrorNettCharge == true"
        >
          <ul>
            <li
              [class.hidden]="
                model['error-placeOfPayment'] == null ||
                model['error-placeOfPayment'] == '' ||
                model['error-placeOfPayment'] == undefined
              "
            >
              {{ model["error-placeOfPayment"] }}
            </li>
            <li
              [class.hidden]="
                model['error-billingParty'] == null ||
                model['error-billingParty'] == '' ||
                model['error-billingParty'] == undefined
              "
            >
              {{ model["error-billingParty"] }}
            </li>
          </ul>
        </div>

        <div
          class="field"
          [style.display]="
            modeFormBlNettCharge == '' || modeFormBlNettCharge == 'view'
              ? 'block'
              : 'none'
          "
        >
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="five wide field">
                <div class="field">
                  <div class="ui checkbox" style="margin-bottom: 5px">
                    <input
                      type="checkbox"
                      [checked]="model['blIsFreightAsArranged'] == true"
                      [(ngModel)]="model['blIsFreightAsArranged']"
                      name="chBlIsFreightAsArranged"
                      (change)="
                        changeEventChBlNettChargeIsFreightAsArranged($event)
                      "
                      tabindex="0"
                    />
                    <label>Freight As Arranged</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox" style="margin-bottom: 5px">
                    <input
                      type="checkbox"
                      [checked]="
                        model['blIsPrintLoadingVesselVoyageClause'] == true
                      "
                      [(ngModel)]="model['blIsPrintLoadingVesselVoyageClause']"
                      name="changeEventChBlNettChargePrintLoadingVesselVoyageClause"
                      (change)="
                        changeEventChBlNettChargePrintLoadingVesselVoyageClause(
                          $event
                        )
                      "
                      tabindex="0"
                    />
                    <!--label>{{blNettChargeCheckBoxOIS}}</label-->
                    <label>Print SWB/OIS Clause</label>
                  </div>
                </div>
                <div class="field">
                  <div
                    class="ui checkbox"
                    style="margin-bottom: 5px"
                    [class.disabled]="model['blType'] == 'RFS'"
                  >
                    <input
                      type="checkbox"
                      [checked]="model['blIsPrintShippedOnBoard'] == true"
                      [(ngModel)]="model['blIsPrintShippedOnBoard']"
                      name="chBlNettChargePrintShippedOnBoard"
                      (change)="
                        changeEventChBlNettChargePrintShippedOnBoard($event)
                      "
                      tabindex="0"
                    />
                    <label
                      [style.color]="
                        model['blType'] == 'RFS' ? 'lightgray' : 'black'
                      "
                    >Print Shipped On Board</label
                    >
                  </div>
                </div>
              </div>
              <div class="two wide field">
                <label>No. of OBLs</label>
                <input
                  class="num"
                  type="number"
                  name="txtBlNettChargeNumberOfOBL"
                  [(ngModel)]="model['blNoOfOBL']"
                  (change)="changeEventBlNoOfOBL($event)"
                  (ngModelChange)="validateObls($event, 'num')"
                  (keypress)="keyPressObls($event)"
                  maxlength="1"
                  min="0"
                  max="6"
                />
              </div>
              <div class="three wide field">
                <label>Freight Covered in BL</label>
                <input
                  type="text"
                  name="txtBlNettChargeFreightCoveredInBlNo"
                  [(ngModel)]="model['blFreightCoveredInBlNo']"
                  maxlength="20"
                  readonly
                  style="background-color: lightgray"
                />
              </div>
              <div class="three wide field">
                <label>Place of Issue</label>
                <combo-plugin
                  #cbBlNettChargePlaceOfIssue
                  [settings]="settingCbBlNettChargePlaceOfIssue"
                  (change)="changeEventCbBlNettChargePlaceOfIssue($event)"
                ></combo-plugin>
                <!--input type="text" name="txtBlNettChargePlaceOfIssue" [(ngModel)]="model['blPlaceOfIssueName']" -->
              </div>
              <div class="two wide field">
                <label>Date of Issue</label>
                <!--<datetime-plugin #dateBlNettChargeDateOfIssue  [settings]="settingDateNettChargeDateOfIssue"></datetime-plugin>-->
                <datetime-plugin
                  #dateBlNettChargeDateOfIssue
                  [settings]="settingDateNettChargeDateOfIssue"
                  (change)="changeEventDateOfIssue($event)"
                ></datetime-plugin>
              </div>
              <div class="one wide field">
                <div class="ui checkbox" style="margin-top: 30px">
                  <input
                    type="checkbox"
                    [checked]="model['blDateOfIssueLock'] == true"
                    [(ngModel)]="model['blDateOfIssueLock']"
                    name="chBlNettChargeDateOfIssueLock"
                    (change)="changeEventBlNettChargeIsDateOfIssueLock($event)"
                    tabindex="0"
                  />
                  <label style="color: red">Lock</label>
                </div>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="three wide field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Freight Type
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeFreightTypeNett"
                        [checked]="model['blFreightType'] == 'NETT'"
                        (change)="
                          changeEventBlNettChargeFreightTypeNett($event)
                        "
                      />
                      <label>Nett&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox" [class.disabled]="true">
                      <input
                        type="radio"
                        name="rbBlNettChargeFreightTypeGross"
                        [checked]="model['blFreightType'] == 'GROSS'"
                        (change)="
                          changeEventBlNettChargeFreightTypeGross($event)
                        "
                      />
                      <label>Gross</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="seven wide field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Exchange Rate
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeExRateNormal"
                        [checked]="model['blExchangeRateType'] == 'NORMAL'"
                        (change)="
                          changeEventBlNettChargeExchangeRateTypeNormal($event)
                        "
                      />
                      <label>Normal&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <!--
                    <div class="ui radio checkbox">
                      <input type="radio" name="rbBlNettChargeExRateSpecial" [checked]="model['blExchangeRateType'] == 'SPECIAL'" (change)="changeEventBlNettChargeExchangeRateTypeSpecial($event)">
                      <label>Special Rate&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    -->
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeExRateBlRate"
                        [checked]="model['blExchangeRateType'] == 'BLRATE'"
                        (change)="
                          changeEventBlNettChargeExchangeRateTypeBlRate($event)
                        "
                      />
                      <label>BL</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!--
              <div class="three wide field" [style.display]="modeFormCoba=='ELSE'?'block':'none'">
                <label>Exchange Rate Date</label>
                <date-plugin #dateBlNettChargeExchangeRate [settings]="settingDateNettChargeExchangeRate" (change)="changeEventExchangeRateDate($event)"></date-plugin>
              </div>
              <div class="three wide field" [style.display]="modeFormCoba=='ELSE'?'block':'none'">
                <div class="ui checkbox" style="margin-top:30px;">
                  <input type="checkbox" [checked]="model['blIsExchangeRateLock'] == true "  [(ngModel)]="model['blIsExchangeRateLock']" name="chBlNettChargeIsExchangeRateLock" (change)="changeEventBlNettChargeIsExchangeRateLock($event)" tabindex="0">
                  <label style="color:red">Lock</label>
                </div>
              </div>
              -->
              <!-- GSO 166-->
              <div class="two wide field">
                <label>Exchange Rate Date</label>
                <!--<datetime-plugin #dateBlNettChargeExchangeRate [settings]="settingDateNettChargeExchangeRate"></datetime-plugin>-->
                <datetime-plugin
                  #dateBlNettChargeExchangeRate
                  [settings]="settingDateNettChargeExchangeRate"
                  (change)="changeEventExchangeRateDate($event)"
                ></datetime-plugin>
              </div>
              <div class="one wide field">
                <div
                  class="ui checkbox"
                  style="margin-top: 5px;"
                  [style.display]="modeFormCoba == 'SGMODE' ? 'block' : 'none'"
                >
                  <input
                    type="checkbox"
                    [checked]="model['blGST'] == true"
                    [(ngModel)]="model['blGST']"
                    name="chGST"
                    tabindex="0"
                    disabled
                  />
                  <label>GST</label>
                </div>
                <div
                  class="ui checkbox"
                  style="margin-top: 5px;"
                  [style.display]="modeFormCoba == 'SGMODE' ? 'none' : 'block'"
                >
                </div>
                <div class="ui checkbox" style="margin-top: 10px">
                  <input
                    type="checkbox"
                    [checked]="model['blIsExchangeRateLock'] == true"
                    [(ngModel)]="model['blIsExchangeRateLock']"
                    name="chBlNettChargeIsExchangeRateLock"
                    (change)="changeEventBlNettChargeIsExchangeRateLock($event)"
                    tabindex="0"
                  />
                  <label style="color: red">Lock</label>
                </div>

              </div>
              <!--<div
                class="fields"

              >-->
              <div class="two wide field" >
                <label>Shipped on Board Date</label>
                <!--<datetime-plugin #dateBlNettChargeDateOfIssue  [settings]="settingDateNettChargeDateOfIssue"></datetime-plugin>-->
                <datetime-plugin
                  #dateBlNettChargeShippedOnBoard
                  [settings]="settingDateNettChargeShippedOnBoard"
                  (change)="changeEventShippedOnBoard($event)"
                  [disableDatetime]="lockSOB"
                ></datetime-plugin>
              </div>
              <div class="one wide field">
                <div class="ui checkbox" style="margin-top: 30px">
                  <input
                    type="checkbox"
                    [checked]="model['blExtraShippedOnBoardLock'] == true"
                    [(ngModel)]="model['blExtraShippedOnBoardLock']"
                    name="chBlNettChargeShippedOnBoardLock"
                    (change)="
                    changeEventBlNettChargeIsShippeOnBoardLock($event)
                    "
                    tabindex="0"
                  />
                  <label style="color: red">Lock</label>
                </div>
              </div>

              <!--</div>-->
              <!-- minta dihilangkan <div class="one wide field" [style.display]="modeFormCoba=='SGMODE'?'block':'none'">
                <div class="ui checkbox" style="margin-top:30px;margin-left:-40px">
                  <input type="checkbox" [checked]="model['blGSTLock'] == true "  [(ngModel)]="model['blGSTLock']" name="chGSTLock"  tabindex="0">
                  <label style="color:red">Lock</label>
                </div>
              </div> -->
              <!-- -->
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="five wide field">
                <label>Prepaid At (1)</label>
                <combo-plugin
                  #cbBlNettChargePrepaid1
                  [settings]="settingCbBlNettChargePrepaid1"
                  (change)="changeEventCbBlNettChargePrepaid1($event)"
                  [disableCombo]="IsLockPrepaidCollect('P1')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePrepaid1', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePrepaid1',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePrepaid1'] != null && model['error-cbBlNettChargePrepaid1'] != ''">
                  {{model['error-cbBlNettChargePrepaid1']}}
                </div>
              </div>
              <div class="five wide field">
                <label>Payable At (1)</label>
                <combo-plugin
                  #cbBlNettChargePayableAt1
                  [settings]="settingCbBlNettChargePayableAt1"
                  (change)="changeEventCbBlNettChargePayable1($event)"
                  [disableCombo]="IsLockPrepaidCollect('C1')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePayableAt1', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePayableAt1',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePayableAt1'] != null && model['error-cbBlNettChargePayableAt1'] != ''">
                  {{model['error-cbBlNettChargePayableAt1']}}
                </div>
              </div>
              <div class="six wide field">
                <div class="fields">
                  <div class="ten wide field">
                    <label>Billing Party (1)</label>
                    <combo-plugin
                      #cbBlNettChargeBillingParty1
                      [settings]="settingCbBlNettChargeBillingParty1"
                      (change)="changeEventCbBlNettChargeBillingParty1($event)"
                      [disableCombo]="IsLockPayer('1')"
                      (keypress)="formValidation.CharNumOnly($event)"
                      (keyup)="backspaceValue($event,formValidation.regAlphaNumericWSpace, 'cbBlNettChargeBillingParty1', false, '')"
                      (paste)="onPasteValidation(
                        $event,
                        'cbBlNettChargeBillingParty1',
                        formValidation.regAlphaNumericWSpace,
                        false, '')"
                    ></combo-plugin>
                    <div class="transition ui basic red pointing prompt label"
                         *ngIf="model['error-cbBlNettChargeBillingParty1'] != null && model['error-cbBlNettChargeBillingParty1'] != ''">
                      {{model['error-cbBlNettChargeBillingParty1']}}
                    </div>
                  </div>
                  <div class="ten wide field" style="margin-top: 20px">
                    <combo-plugin
                      #cbBlNettChargeBillingParty1Address
                      [settings]="settingCbNettChargeBillingParty1Address"
                      (change)="changeEventCbNettChargeBillingParty1Address($event)"
                      [disableCombo]="IsLockPayer1 == true"
                    ></combo-plugin>
                  </div>
                </div>
                <div class="fields" *ngIf="officeCode === 'SGSIN'">
                  <div class="field w-100">
                    <label>Customer's Internal Order #</label>
                    <input type="text" maxlength="30" name="blCustomerInternalOrder1"
                           [(ngModel)]="model.blCustomerInternalOrder1"
                           (keypress)="valiAlphanumericCIO($event)"
                           (paste)="formValidation.pasteEventRegexWith($event, model, 'blCustomerInternalOrder1', '[^a-zA-Z0-9#:,./-]', 30)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="five wide field">
                <label>Prepaid At (2)</label>
                <combo-plugin
                  #cbBlNettChargePrepaid2
                  [settings]="settingCbBlNettChargePrepaid2"
                  (change)="changeEventCbBlNettChargePrepaid2($event)"
                  [disableCombo]="IsLockPrepaidCollect('P2')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePrepaid2', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePrepaid2',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePrepaid2'] != null && model['error-cbBlNettChargePrepaid2'] != ''">
                  {{model['error-cbBlNettChargePrepaid2']}}
                </div>
              </div>
              <div class="five wide field">
                <label>Payable At (2)</label>
                <combo-plugin
                  #cbBlNettChargePayableAt2
                  [settings]="settingCbBlNettChargePayableAt2"
                  (change)="changeEventCbBlNettChargePayable2($event)"
                  [disableCombo]="IsLockPrepaidCollect('C2')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePayableAt2', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePayableAt2',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePayableAt2'] != null && model['error-cbBlNettChargePayableAt2'] != ''">
                  {{model['error-cbBlNettChargePayableAt2']}}
                </div>
              </div>
              <div class="six wide field">
                <div class="fields">
                  <div class="ten wide field">
                    <label>Billing Party (2)</label>
                    <combo-plugin
                      #cbBlNettChargeBillingParty2
                      [settings]="settingCbBlNettChargeBillingParty2"
                      (change)="changeEventCbBlNettChargeBillingParty2($event)"
                      [disableCombo]="IsLockPayer('2')"
                      (keypress)="formValidation.BillingPartyAndSpace($event)"
                      (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargeBillingParty2', false, '')"
                      (paste)="onPasteValidation(
                        $event,
                        'cbBlNettChargeBillingParty2',
                        formValidation.regBillingPartyAndSpace,
                        false, '')"
                    ></combo-plugin>
                    <div class="transition ui basic red pointing prompt label"
                         *ngIf="model['error-cbBlNettChargeBillingParty2'] != null && model['error-cbBlNettChargeBillingParty2'] != ''">
                      {{model['error-cbBlNettChargeBillingParty2']}}
                    </div>
                  </div>
                  <div class="ten wide field" style="margin-top: 20px">
                    <combo-plugin
                      #cbBlNettChargeBillingParty2Address
                      [settings]="settingCbNettChargeBillingParty2Address"
                      (change)="changeEventCbNettChargeBillingParty2Address($event)"
                      [disableCombo]="IsLockPayer2 == true"
                    ></combo-plugin>
                  </div>
                </div>
                <div class="fields" *ngIf="officeCode === 'SGSIN'">
                  <div class="field w-100">
                    <label>Customer's Internal Order #</label>
                    <input type="text" maxlength="30" name="blCustomerInternalOrder2"
                           [(ngModel)]="model.blCustomerInternalOrder2"
                           (keypress)="valiAlphanumericCIO($event)"
                           (paste)="formValidation.pasteEventRegexWith($event, model, 'blCustomerInternalOrder2', '[^a-zA-Z0-9#:,./-]', 30)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="five wide field">
                <label>Prepaid At (3)</label>
                <combo-plugin
                  #cbBlNettChargePrepaid3
                  [settings]="settingCbBlNettChargePrepaid3"
                  (change)="changeEventCbBlNettChargePrepaid3($event)"
                  [disableCombo]="IsLockPrepaidCollect('P3')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePrepaid3', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePrepaid3',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePrepaid3'] != null && model['error-cbBlNettChargePrepaid3'] != ''">
                  {{model['error-cbBlNettChargePrepaid3']}}
                </div>
              </div>
              <div class="five wide field">
                <label>Payable At (3)</label>
                <combo-plugin
                  #cbBlNettChargePayableAt3
                  [settings]="settingCbBlNettChargePayableAt3"
                  (change)="changeEventCbBlNettChargePayable3($event)"
                  [disableCombo]="IsLockPrepaidCollect('C3')"
                  (keypress)="formValidation.validateBilling($event)"
                  (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargePayableAt3', false, '')"
                  (paste)="onPasteValidation(
                    $event,
                    'cbBlNettChargePayableAt3',
                    formValidation.regBillingPartyAndSpace,
                    true, '')"
                ></combo-plugin>
                <div class="transition ui basic red pointing prompt label"
                     *ngIf="model['error-cbBlNettChargePayableAt3'] != null && model['error-cbBlNettChargePayableAt3'] != ''">
                  {{model['error-cbBlNettChargePayableAt3']}}
                </div>
              </div>
              <div class="six wide field">
                <div class="fields">
                  <div class="ten wide field">
                    <label>Billing Party (3)</label>
                    <combo-plugin
                      #cbBlNettChargeBillingParty3
                      [settings]="settingCbBlNettChargeBillingParty3"
                      (change)="changeEventCbBlNettChargeBillingParty3($event)"
                      [disableCombo]="IsLockPayer('3')"
                      (keypress)="formValidation.BillingPartyAndSpace($event)"
                      (keyup)="backspaceValue($event,formValidation.regBillingPartyAndSpace, 'cbBlNettChargeBillingParty3', false, '')"
                      (paste)="onPasteValidation(
                        $event,
                        'cbBlNettChargeBillingParty3',
                        formValidation.regBillingPartyAndSpace,
                        false, '')"
                    ></combo-plugin>
                    <div class="transition ui basic red pointing prompt label"
                         *ngIf="model['error-cbBlNettChargeBillingParty3'] != null && model['error-cbBlNettChargeBillingParty3'] != ''">
                      {{model['error-cbBlNettChargeBillingParty3']}}
                    </div>
                  </div>
                  <div class="ten wide field" style="margin-top: 20px">
                    <combo-plugin
                      #cbBlNettChargeBillingParty3Address
                      [settings]="settingCbNettChargeBillingParty3Address"
                      (change)="changeEventCbNettChargeBillingParty3Address($event)"
                      [disableCombo]="IsLockPayer3 == true"
                    ></combo-plugin>
                  </div>
                </div>
                <div class="fields" *ngIf="officeCode === 'SGSIN'">
                  <div class="field w-100">
                    <label>Customer's Internal Order #</label>
                    <input type="text" maxlength="30" name="blCustomerInternalOrder3"
                           [(ngModel)]="model.blCustomerInternalOrder3"
                           (keypress)="valiAlphanumericCIO($event)"
                           (paste)="formValidation.pasteEventRegexWith($event, model, 'blCustomerInternalOrder3', '[^a-zA-Z0-9#:,./-]', 30)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <label>Freight Remarks</label>
            <textarea-plugin
              #txtBlNettChargeRemarks
              [settings]="settingTxtBlNettChargeRemarks"
              (change)="changeEventRemarks()"
            ></textarea-plugin>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <label>Remarks</label>
            <textarea-plugin
              #txtBlNettChargeNewRemarks
              [settings]="settingTxtBlNettChargeNewRemarks"
              (change)="changeEventNewRemarks()"
            ></textarea-plugin>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="one wide fields">
              <div class="field">
                <button class="ui button" (click)="onBlNettChargeNew()" disabled>
                  New
                </button>
              </div>
              <!--
              <div class="field" *ngIf="isOfficeInIndia">
                <div [style.display]="blLock == 'Y' ? 'block' : 'none'">
                  <button class="ui button" (click)="onAdhoc()" [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">
                    Adhoc
                  </button>
                </div>
                <div [style.display]="blLock != 'Y' ? 'block' : 'none'">
                  <button class="ui violet button" style="z-index: 10 !important; position: relative" (click)="onAdhoc()"
                    [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">
                    Adhoc
                  </button>
                </div>
              </div>
              -->

              <!--
              <button class="ui violet button" (click)="onBlNettChargeRefreight()" [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">Refreight</button>
              <button class="ui navy button" (click)="onBlNettChargeRefreightNew()" [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">Refreight (Optimized)</button>
              -->
              <div class="field" [style.z-index]="lockContainer ? null : 10">
                <div [style.display]="blLock == 'Y' ? 'block' : 'none'">
                  <button
                    class="ui button"
                    (click)="onRefreightFinal()"
                    [class.disabled]="
                      model['blType'] == 'SW' || model['blType'] == 'OIS' || true
                    "
                  >
                    Refreight
                  </button>
                </div>
                <div [style.display]="blLock != 'Y' ? 'block' : 'none'">
                  <button
                    class="ui violet button"
                    style="z-index: 8 !important; position: relative"
                    (click)="onRefreightFinal()"
                    [class.disabled]="
                      model['blType'] == 'SW' || model['blType'] == 'OIS' || true
                    "
                  >
                    Refreight
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="field"
          [style.display]="
            modeFormBlNettCharge == '' || modeFormBlNettCharge == 'edit'
              ? 'block'
              : 'none'
          "
        >
          <div
            class="ui error message"
            [class.errorNettCharge]="isErrorNettChargeEntry == true"
          >
            <ul>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCChargeCode'] == null ||
                  paramFreightCharge['error-blFCChargeCode'] == '' ||
                  paramFreightCharge['error-blFCChargeCode'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCChargeCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCPayer'] == null ||
                  paramFreightCharge['error-blFCPayer'] == '' ||
                  paramFreightCharge['error-blFCPayer'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCPayer"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCPc'] == null ||
                  paramFreightCharge['error-blFCPc'] == '' ||
                  paramFreightCharge['error-blFCPc'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCPc"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCPerCode'] == null ||
                  paramFreightCharge['error-blFCPerCode'] == '' ||
                  paramFreightCharge['error-blFCPerCode'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCPerCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCCurrencyCode'] == null ||
                  paramFreightCharge['error-blFCCurrencyCode'] == '' ||
                  paramFreightCharge['error-blFCCurrencyCode'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCCurrencyCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCDuplicateRecord'] == null ||
                  paramFreightCharge['error-blFCDuplicateRecord'] == '' ||
                  paramFreightCharge['error-blFCDuplicateRecord'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCDuplicateRecord"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCFaiGroup'] == null ||
                  paramFreightCharge['error-blFCFaiGroup'] == '' ||
                  paramFreightCharge['error-blFCFaiGroup'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCFaiGroup"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCRevenue'] == null ||
                  paramFreightCharge['error-blFCRevenue'] == '' ||
                  paramFreightCharge['error-blFCRevenue'] == undefined
                "
              >
                {{ paramFreightCharge["error-blFCRevenue"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightCharge['error-blFCRate'] ==
                    null ||
                  paramFreightCharge['error-blFCRate'] ==
                    '' ||
                  paramFreightCharge['error-blFCRate'] ==
                    undefined
                  "
              >
                {{ paramFreightCharge["error-blFCRate"] }}
              </li>
              <!--
              <li [class.hidden]="paramFreightCharge['error-blFCPlaceOfPaymentName'] == null || paramFreightCharge['error-blFCPlaceOfPaymentName'] == '' || paramFreightCharge['error-blFCPlaceOfPaymentName'] == undefined ">
                {{paramFreightCharge['error-blFCPlaceOfPaymentName']}}
              </li>
              -->
            </ul>
          </div>

          <div class="field">
            <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
              <legend style="font-weight: bold; color: dodgerblue">
                B/L Charge Entity
              </legend>
              <div class="field">
                <button
                  class="ui button"
                  (click)="onBlNettChargeEntryInsert()"
                  [class.disabled]="disableNewUpdateChargeButton == false"
                >
                  {{ txtBlContainerInsertUpdate }}
                </button>
                <button
                  class="ui button"
                  (click)="onBlNettChargeEntryDelete()"
                  [class.disabled]="
                    nettChargeDisableDelete == true ||
                    paramFreightCharge['blFCIsEntered'] == false
                  "
                >
                  Delete
                </button>
                <button class="ui button" style="z-index: 10; position: relative" (click)="onBlNettChargeEntryCancel()">
                  Cancel
                </button>
              </div>
              <div class="field" style="margin-bottom: 5px">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="two wide field" style="margin-top: 10px">
                    <label>Seq</label>
                  </div>
                  <div class="one wide field" style="margin-left: -35px">
                    <input
                      type="text"
                      name="txtBlNettChargeEntrySeq"
                      disabled
                      [(ngModel)]="paramFreightCharge['blFCSeq']"
                    />
                  </div>
                  <div class="two wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="chBlNettChargeEntryPrint"
                        [checked]="
                          paramFreightCharge['blFCIsChargePrinted'] == true
                        "
                        [(ngModel)]="paramFreightCharge['blFCIsChargePrinted']"
                        (change)="
                          changeEventChBlNettChargeIsChargePrinted($event)
                        "
                        [disabled]="netChargeLock == true"
                      />
                      <label>Print</label>
                    </div>
                  </div>
                  <div class="two wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="chBlNettChargeEntryFai"
                        [checked]="paramFreightCharge['blFCFaiGroup'] == true"
                        [(ngModel)]="paramFreightCharge['blFCFaiGroup']"
                        (change)="
                          changeEventChBlNettChargeIsChargeFaiGroup($event)
                        "
                        disabled
                      />
                      <label>FAI Group</label>
                    </div>
                  </div>
                  <div class="five wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="chBlNettChargeEntryManifestedFreight"
                        [checked]="
                          paramFreightCharge['blFCIsManifestedFreight'] == true
                        "
                        [(ngModel)]="
                          paramFreightCharge['blFCIsManifestedFreight']
                        "
                        (change)="
                          changeEventChBlNettChargeIsManifestedFreight($event)
                        "
                        [disabled]="netChargeLock === true"
                      />
                      <label>Manifested Freight</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field" style="margin-bottom: 5px">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="eight wide field">
                    <!-- Charge left section -->
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Charge Code</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCChargeCode'] !=
                              null &&
                            paramFreightCharge['error-blFCChargeCode'] != ''
                          "
                        >
                          <combo-plugin
                            #cbBlNettChargeInputCharge
                            [settings]="settingCbBlNettChargeInputCharge"
                            (change)="
                              changeEventCbBlNettChargeInputCharge($event)
                            "
                            [disableCombo]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                            (keypress)="formValidation.AlphabetsOnly($event)"
                            (keyup)="backspaceValue($event,formValidation.regStringSpace, 'blFCChargeCode', true, '')"
                            (paste)="onPasteValidation(
                              $event,
                              'blFCChargeCode',
                              formValidation.regStringSpace,
                              true, '')"
                          ></combo-plugin>
                          <div class="transition ui basic red pointing prompt label"
                               *ngIf="model['error-blFCChargeCode'] != null && model['error-blFCChargeCode'] != ''">
                            {{model['error-blFCChargeCode']}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Quantity</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCRevenue'] != null &&
                            paramFreightCharge['error-blFCRevenue'] != ''
                          "
                        >
                          <input
                            class="quantity"
                            type="number"
                            name="txtBlNettChargeEntryQuantity"
                            [(ngModel)]="paramFreightCharge['blFCRevenue']"
                            (change)="calculateNettChargeAmount()"
                            min="0"
                            maxlength="10"
                            [disabled]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                            (keypress)="formValidation.NumWOnly($event)"
                            (ngModelChange)="validateObls($event, 'quantity')"
                          />
                          <div class="transition ui basic red pointing prompt label"
                               *ngIf="model['error-blFCRevenue'] != null && model['error-blFCRevenue'] != ''">
                            {{model['error-blFCRevenue']}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Rate</label>
                        </div>
                        <div class="twelve wide field"
                             [class.error]="paramFreightCharge['error-blFCRate']"
                        >
                          <input
                            type="number"
                            min="0"
                            class="rate"
                            maxlength="10"
                            name="txtBlNettChargeEntryRate"
                            [(ngModel)]="paramFreightCharge['blFCRate']"
                            (change)="calculateNettChargeAmount()"
                            [disabled]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                            (keypress)="formValidation.NumWDot($event)"
                            (ngModelChange)="validateObls($event, 'rate')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Per</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCPerCode'] != null &&
                            paramFreightCharge['error-blFCPerCode'] != ''
                          "
                        >
                          <combo-plugin
                            #cbBlNettChargeInputPerCode
                            [settings]="settingCbBlNettChargeInputPerCode"
                            (change)="
                              changeEventCbBlNettChargeInputPerCode($event)
                            "
                            [disableCombo]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                            (keypress)="formValidation.CharNumOnly($event)"
                            (keyup)="backspaceValue($event,formValidation.regAlphaNumericWSpace, 'blFCPerCode', false, '')"
                            (paste)="onPasteValidation(
                              $event,
                              'blFCPerCode',
                              formValidation.regAlphaNumericWSpace,
                              true, '')"
                          ></combo-plugin>
                          <div class="transition ui basic red pointing prompt label"
                               *ngIf="model['error-blFCPerCode'] != null && model['error-blFCPerCode'] != ''">
                            {{model['error-blFCPerCode']}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Currency</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCCurrencyCode'] !=
                              null &&
                            paramFreightCharge['error-blFCCurrencyCode'] != ''
                          "
                        >
                          <combo-plugin
                            #cbBlNettChargeInputCurrency
                            [settings]="settingCbBlNettChargeInputCurrency"
                            (change)="
                              changeEventCbBlNettChargeInputCurrency($event)
                            "
                            [disableCombo]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                            (keypress)="formValidation.AlphabetsOnly($event)"
                            (keyup)="backspaceValue($event,formValidation.regStringSpace, 'blFCCurrencyCode', true, '')"
                            (paste)="onPasteValidation(
                              $event,
                              'blFCCurrencyCode',
                              formValidation.regStringSpace,
                              true, '')"
                          ></combo-plugin>
                          <div class="transition ui basic red pointing prompt label"
                               *ngIf="model['error-blFCCurrencyCode'] != null && model['error-blFCCurrencyCode'] != ''">
                            {{model['error-blFCCurrencyCode']}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="eight wide field">
                    <!-- Charge right section -->
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>Total Amount</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="number"
                            name="txtBlNettChargeEntryTotalAmount"
                            [(ngModel)]="paramFreightCharge['blFCAmount']"
                            readonly
                            tabindex="5"
                            [disabled]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>P/C</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCPc'] != null &&
                            paramFreightCharge['error-blFCPc'] != ''
                          "
                        >
                          <combo-plugin
                            #cbBlNettChargeInputPC
                            [settings]="settingCbBlNettChargeInputPC"
                            (change)="changeEventCbBlNettChargeInputPC($event)"
                            [disableCombo]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                          ></combo-plugin>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>PFC Ref.</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="text"
                            name="txtBlNettChargeEntryPFCReference"
                            [(ngModel)]="
                              paramFreightCharge['blFCFreightReference']
                            "
                            readonly
                            [disabled]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>Payer</label>
                        </div>
                        <!-- <div class="twelve wide field"> -->
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightCharge['error-blFCPayer'] != null &&
                            paramFreightCharge['error-blFCPayer'] != ''
                          "
                        >
                          <!--combo-plugin #cbBlNettChargeInputPayer [settings]="settingCbBlNettChargeInputPayer"(change)="changeEventCbBlNettChargeInputPayer($event);" [disableCombo]="(paramFreightCharge['blFCPc'] == 'C1' || paramFreightCharge['blFCPc'] == 'C2' || paramFreightCharge['blFCPc'] == 'C3' )"></combo-plugin-->
                          <combo-plugin
                            #cbBlNettChargeInputPayer
                            [settings]="settingCbBlNettChargeInputPayer"
                            (change)="
                              changeEventCbBlNettChargeInputPayer($event)
                            "
                            [disableCombo]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                          ></combo-plugin>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>Place of Payment</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="text"
                            name="txtBlNettChargeEntryPlaceOfPayment"
                            [(ngModel)]="
                              paramFreightCharge['blFCPlaceOfPaymentName']
                            "
                            readonly
                            [disabled]="
                              paramFreightCharge['blFCIsEntered'] == false ||
                              netChargeLock == true
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--
              <div class="field" style="margin-bottom: 5px;">
                <div class="fields" style="margin-bottom: 0px;">
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Charge Code</label>
                  </div>
                  <div class="six wide field">
                    <combo-plugin #cbBlNettChargeInputCharge [settings]="settingCbBlNettChargeInputCharge" (change)="changeEventCbBlNettChargeInputCharge($event);" ></combo-plugin>
                  </div>
                  <div class="one wide field">
                    &nbsp;
                  </div>
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Total Amount</label>
                  </div>
                  <div class="six wide field">
                    <input type="number" name="txtBlNettChargeEntryTotalAmount" [(ngModel)]="paramFreightCharge['blFCAmount']" readonly tabindex="5">
                  </div>
                </div>
              </div>
              <div class="field" style="margin-bottom: 5px;">
                <div class="fields" style="margin-bottom: 0px;">
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Quantity</label>
                  </div>
                  <div class="six wide field">
                    <input type="number" name="txtBlNettChargeEntryQuantity" [(ngModel)]="paramFreightCharge['blFCRevenue']" (change)="calculateNettChargeAmount()" tabindex="1" min="0" >
                  </div>
                  <div class="one wide field">
                    &nbsp;
                  </div>
                  <div class="two wide field" style="margin-top:10px;">
                    <label>P/C</label>
                  </div>
                  <div class="six wide field">
                    <combo-plugin #cbBlNettChargeInputPC [settings]="settingCbBlNettChargeInputPC" (change)="changeEventCbBlNettChargeInputPC($event);" ></combo-plugin>
                  </div>
                </div>
              </div>
              <div class="field" style="margin-bottom: 5px;">
                <div class="fields" style="margin-bottom: 0px;">
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Rate</label>
                  </div>
                  <div class="six wide field">
                    <input type="number" name="txtBlNettChargeEntryRate" [(ngModel)]="paramFreightCharge['blFCRate']" (change)="calculateNettChargeAmount()" tabindex="2">
                  </div>
                  <div class="one wide field">
                    &nbsp;
                  </div>
                  <div class="two wide field" style="margin-top:10px;">
                    <label>PFC Ref.</label>
                  </div>
                  <div class="six wide field">
                    <input type="text" name="txtBlNettChargeEntryPFCReference" [(ngModel)]="paramFreightCharge['blFCFreightReference']" readonly tabindex="7">
                  </div>
                </div>
              </div>
              <div class="field" style="margin-bottom: 5px;">
                <div class="fields" style="margin-bottom: 0px;">
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Per</label>
                  </div>
                  <div class="six wide field">
                    <combo-plugin #cbBlNettChargeInputPerCode [settings]="settingCbBlNettChargeInputPerCode"(change)="changeEventCbBlNettChargeInputPerCode($event);" ></combo-plugin>
                  </div>
                  <div class="one wide field">
                    &nbsp;
                  </div>
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Payer</label>
                  </div>
                  <div class="six wide field">
                    <combo-plugin #cbBlNettChargeInputPayer [settings]="settingCbBlNettChargeInputPayer"(change)="changeEventCbBlNettChargeInputPayer($event);"></combo-plugin>
                  </div>
                </div>
              </div>
              <div class="field" style="margin-bottom: 5px;">
                <div class="fields" style="margin-bottom: 0px;">
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Currency</label>
                  </div>
                  <div class="six wide field">
                    <combo-plugin #cbBlNettChargeInputCurrency [settings]="settingCbBlNettChargeInputCurrency"(change)="changeEventCbBlNettChargeInputCurrency($event);" ></combo-plugin>
                  </div>
                  <div class="one wide field">
                    &nbsp;
                  </div>
                  <div class="two wide field" style="margin-top:10px;">
                    <label>Place of Payment</label>
                  </div>
                  <div class="six wide field">
                    <input type="text" name="txtBlNettChargeEntryPlaceOfPayment" [(ngModel)]="paramFreightCharge['blFCPlaceOfPaymentName']" tabindex="9" readonly>
                  </div>
                </div>
              </div>

              -->
            </fieldset>
          </div>
        </div>
        <div class="field">
          <grid-plugin
            #gridBlNettCharge
            [settings]="settingGridBlNettCharge"
            (gridEvent)="gridEventBlNettCharge($event)"
            (infoGrid)="infoGridBlNettCharge($event)"
          ></grid-plugin>
        </div>
      </div>
      <!-- Tab Nett Charge End -->

      <!-- Tab Gross Charge Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus7 == 'active' || tabStatus == 'tab7'"
        id="blGrossChargeTab"
        data-tab="blGrossChargeTab"
      >
        <!-- dont change || -->
        <div class="field">-- NOTHING --</div>
      </div>
      <!-- Tab Gross Charge End -->

      <!-- Tab Nett Charge Console Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus8 == 'active' || tabStatus == 'tab8'"
        id="blNettChargeConsoleTab"
        data-tab="blNettChargeConsoleTab"
      >
        <!-- dont change || -->

        <div
          class="ui inverted dimmer active"
          style="z-index: 9; opacity: 0.2"
          [class.hidden]="lockTab8 == false"
        >
          <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div
          class="field"
          [style.display]="
            modeFormBlNettChargeConsole == '' ||
            modeFormBlNettChargeConsole == 'view'
              ? 'block'
              : 'none'
          "
        >
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="five wide field">
                <div class="field">
                  <div class="ui checkbox" style="margin-bottom: 5px">
                    <input
                      type="checkbox"
                      [checked]="model['blIsFreightAsArrangedConsole'] == true"
                      [(ngModel)]="model['blIsFreightAsArrangedConsole']"
                      name="chBlIsFreightAsArrangedConsole"
                      (change)="
                        changeEventChBlNettChargeIsFreightAsArrangedConsole(
                          $event
                        )
                      "
                      tabindex="0"
                    />
                    <label>Freight As Arranged</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox" style="margin-bottom: 5px">
                    <input
                      type="checkbox"
                      [checked]="
                        model['blIsPrintLoadingVesselVoyageClauseConsole'] ==
                        true
                      "
                      [(ngModel)]="
                        model['blIsPrintLoadingVesselVoyageClauseConsole']
                      "
                      name="changeEventChBlNettChargePrintLoadingVesselVoyageClauseConsole"
                      (change)="
                        changeEventChBlNettChargePrintLoadingVesselVoyageClauseConsole(
                          $event
                        )
                      "
                      tabindex="0"
                    />
                    <!--label>{{blNettChargeCheckBoxOIS}}</label-->
                    <label>Print SWB/OIS Clause</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui checkbox" style="margin-bottom: 5px">
                    <input
                      type="checkbox"
                      [checked]="
                        model['blIsPrintShippedOnBoardConsole'] == true
                      "
                      [(ngModel)]="model['blIsPrintShippedOnBoardConsole']"
                      name="chBlNettChargePrintShippedOnBoardConsole"
                      (change)="
                        changeEventChBlNettChargePrintShippedOnBoardConsole(
                          $event
                        )
                      "
                      tabindex="0"
                    />
                    <label>Print Shipped On Board</label>
                  </div>
                </div>
              </div>
              <div class="two wide field">
                <label>No. of OBLs</label>
                <input
                  type="number"
                  name="txtBlNettChargeNumberOfOBLConsole"
                  [(ngModel)]="model['blNoOfOBLConsole']"
                  (change)="changeEventBlNoOfOBL($event)"
                  (onKeyPress)="validateObls($event)"
                  min="1"
                  max="6"
                  maxlength="1"
                />
              </div>
              <div class="three wide field">
                <label>Freight Covered in BL</label>
                <input
                  type="text"
                  name="txtBlNettChargeFreightCoveredInBlNoConsole"
                  [(ngModel)]="model['blFreightCoveredInBlNoConsole']"
                  maxlength="20"
                  readonly
                  style="background-color: lightgray"
                />
              </div>
              <div class="three wide field">
                <label>Place of Issue</label>
                <combo-plugin
                  #cbBlNettChargePlaceOfIssueConsole
                  [settings]="settingCbBlNettChargePlaceOfIssueConsole"
                  (change)="
                    changeEventCbBlNettChargePlaceOfIssueConsole($event)
                  "
                ></combo-plugin>
                <!--input type="text" name="txtBlNettChargePlaceOfIssue" [(ngModel)]="model['blPlaceOfIssueName']" -->
              </div>
              <div class="two wide field">
                <label>Date of Issue</label>
                <!--<datetime-plugin #dateBlNettChargeDateOfIssue  [settings]="settingDateNettChargeDateOfIssue"></datetime-plugin>-->
                <date-plugin
                  #dateBlNettChargeDateOfIssueConsole
                  [settings]="settingDateNettChargeDateOfIssueConsole"
                  (change)="changeEventDateOfIssueConsole($event)"
                ></date-plugin>
              </div>
              <div class="one wide field">
                <div class="ui checkbox" style="margin-top: 30px">
                  <input
                    type="checkbox"
                    [checked]="model['blDateOfIssueLockConsole'] == true"
                    [(ngModel)]="model['blDateOfIssueLockConsole']"
                    name="chBlNettChargeDateOfIssueLockConsole"
                    (change)="
                      changeEventBlNettChargeIsDateOfIssueLockConsole($event)
                    "
                    tabindex="0"
                  />
                  <label style="color: red">Lock</label>
                </div>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 5px">
              <div class="three wide field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Freight Type
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeFreightTypeNettConsole"
                        [checked]="model['blFreightTypeConsole'] == 'NETT'"
                        (change)="
                          changeEventBlNettChargeFreightTypeNettConsole($event)
                        "
                      />
                      <label>Nett&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="ui radio checkbox" [class.disabled]="true">
                      <input
                        type="radio"
                        name="rbBlNettChargeFreightTypeGrossConsole"
                        [checked]="model['blFreightTypeConsole'] == 'GROSS'"
                        (change)="
                          changeEventBlNettChargeFreightTypeGrossConsole($event)
                        "
                      />
                      <label>Gross</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="seven wide field">
                <fieldset
                  style="border: 1px solid lightgray; margin-bottom: 5px"
                >
                  <legend style="font-weight: bold; color: dodgerblue">
                    Exchange Rate
                  </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeExRateNormalConsole"
                        [checked]="
                          model['blExchangeRateTypeConsole'] == 'NORMAL'
                        "
                        (change)="
                          changeEventBlNettChargeExchangeRateTypeNormalConsole(
                            $event
                          )
                        "
                      />
                      <label>Normal&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <!--
                    <div class="ui radio checkbox">
                      <input type="radio" name="rbBlNettChargeExRateSpecial" [checked]="model['blExchangeRateType'] == 'SPECIAL'" (change)="changeEventBlNettChargeExchangeRateTypeSpecial($event)">
                      <label>Special Rate&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    -->
                    <div class="ui radio checkbox">
                      <input
                        type="radio"
                        name="rbBlNettChargeExRateBlRateConsole"
                        [checked]="
                          model['blExchangeRateTypeConsole'] == 'BLRATE'
                        "
                        (change)="
                          changeEventBlNettChargeExchangeRateTypeBlRateConsole(
                            $event
                          )
                        "
                      />
                      <label>BL</label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!--
              <div class="three wide field" [style.display]="modeFormCoba=='ELSE'?'block':'none'">
                <label>Exchange Rate Date</label>
                <date-plugin #dateBlNettChargeExchangeRateConsole [settings]="settingDateNettChargeExchangeRateConsole" (change)="changeEventExchangeRateDateConsole($event)"></date-plugin>
              </div>
              <div class="three wide field" [style.display]="modeFormCoba=='ELSE'?'block':'none'">
                <div class="ui checkbox" style="margin-top:30px;">
                  <input type="checkbox" [checked]="model['blIsExchangeRateLockConsole'] == true "  [(ngModel)]="model['blIsExchangeRateLockConsole']" name="chBlNettChargeIsExchangeRateLockConsole" (change)="changeEventBlNettChargeIsExchangeRateLockConsole($event)" tabindex="0">
                  <label style="color:red">Lock</label>
                </div>
              </div>
               -->
              <!-- GSO 166-->
              <div
                class="two wide field"
                [style.display]="modeFormCoba == 'SGMODE' ? 'block' : 'none'"
              >
                <label>Exchange Rate Date</label>
                <!--<datetime-plugin #dateBlNettChargeExchangeRate [settings]="settingDateNettChargeExchangeRate"></datetime-plugin>-->
                <date-plugin
                  #dateBlNettChargeExchangeRateConsole
                  [settings]="settingDateNettChargeExchangeRateConsole"
                  (change)="changeEventExchangeRateDateConsole($event)"
                ></date-plugin>
              </div>
              <div
                class="one wide field"
                [style.display]="modeFormCoba == 'SGMODE' ? 'block' : 'none'"
              >
                <div class="ui checkbox" style="margin-top: 30px">
                  <input
                    type="checkbox"
                    [checked]="model['blIsExchangeRateLockConsole'] == true"
                    [(ngModel)]="model['blIsExchangeRateLockConsole']"
                    name="chBlNettChargeIsExchangeRateLockConsole"
                    (change)="
                      changeEventBlNettChargeIsExchangeRateLockConsole($event)
                    "
                    tabindex="0"
                  />
                  <label style="color: red">Lock</label>
                </div>
              </div>
              <div
                class="one wide field"
                [style.display]="modeFormCoba == 'SGMODE' ? 'block' : 'none'"
                style="margin-left: 50px"
              >
                <div
                  class="ui checkbox"
                  style="margin-top: 30px; margin-left: -50px"
                >
                  <input
                    type="checkbox"
                    [checked]="model['blGST'] == true"
                    [(ngModel)]="model['blGST']"
                    name="chGST"
                    tabindex="0"
                    disabled
                  />
                  <label>GST</label>
                </div>
              </div>
              <!-- minta dihilangkan <div class="one wide field" [style.display]="modeFormCoba=='SGMODE'?'block':'none'">
                <div class="ui checkbox" style="margin-top:30px;margin-left:-40px">
                  <input type="checkbox" [checked]="model['blGSTConsole'] == true "  [(ngModel)]="model['blGSTConsole']" name="chGSTConsole"  tabindex="0">
                  <label style="color:red">Lock</label>
                </div>
              </div> -->
              <!-- -->
            </div>
          </div>
          <!--
          <div class="field" style="margin-bottom: 5px;">
            <div class="fields" style="margin-bottom: 5px;">
              <div class="five wide field">
                <label>Prepaid At (1)</label>
                <combo-plugin #cbBlNettChargePrepaid1 [settings]="settingCbBlNettChargePrepaid1"(change)="changeEventCbBlNettChargePrepaid1($event);" [disableCombo]="IsLockPrepaidCollect('P1')"></combo-plugin>
              </div>
              <div class="five wide field">
                <label>Payable At (1)</label>
                <combo-plugin #cbBlNettChargePayableAt1 [settings]="settingCbBlNettChargePayableAt1"(change)="changeEventCbBlNettChargePayable1($event);" [disableCombo]="IsLockPrepaidCollect('C1')"></combo-plugin>
              </div>
              <div class="six wide field">
                <label>Billing Party(1)</label>
                <combo-plugin #cbBlNettChargeBillingParty1 [settings]="settingCbBlNettChargeBillingParty1"(change)="changeEventCbBlNettChargeBillingParty1($event);" [disableCombo]="IsLockPayer('1')"></combo-plugin>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px;">
            <div class="fields" style="margin-bottom: 5px;">
              <div class="five wide field">
                <label>Prepaid At (2)</label>
                <combo-plugin #cbBlNettChargePrepaid2 [settings]="settingCbBlNettChargePrepaid2"(change)="changeEventCbBlNettChargePrepaid2($event);" [disableCombo]="IsLockPrepaidCollect('P2')"></combo-plugin>
              </div>
              <div class="five wide field">
                <label>Payable At (2)</label>
                <combo-plugin #cbBlNettChargePayableAt2 [settings]="settingCbBlNettChargePayableAt2"(change)="changeEventCbBlNettChargePayable2($event);" [disableCombo]="IsLockPrepaidCollect('C2')"></combo-plugin>
              </div>
              <div class="six wide field">
                <label>Billing Party(2)</label>
                <combo-plugin #cbBlNettChargeBillingParty2 [settings]="settingCbBlNettChargeBillingParty2"(change)="changeEventCbBlNettChargeBillingParty2($event);" [disableCombo]="IsLockPayer('2')"></combo-plugin>
              </div>
            </div>
          </div>
          <div class="field" style="margin-bottom: 5px;">
            <div class="fields" style="margin-bottom: 5px;">
              <div class="five wide field">
                <label>Prepaid At (3)</label>
                <combo-plugin #cbBlNettChargePrepaid3 [settings]="settingCbBlNettChargePrepaid3"(change)="changeEventCbBlNettChargePrepaid3($event);" [disableCombo]="IsLockPrepaidCollect('P3')"></combo-plugin>
              </div>
              <div class="five wide field">
                <label>Payable At (3)</label>
                <combo-plugin #cbBlNettChargePayableAt3 [settings]="settingCbBlNettChargePayableAt3"(change)="changeEventCbBlNettChargePayable3($event);" [disableCombo]="IsLockPrepaidCollect('C3')"></combo-plugin>
              </div>
              <div class="six wide field">
                <label>Billing Party(3)</label>
                <combo-plugin #cbBlNettChargeBillingParty3 [settings]="settingCbBlNettChargeBillingParty3"(change)="changeEventCbBlNettChargeBillingParty3($event);" [disableCombo]="IsLockPayer('3')"></combo-plugin>
              </div>
            </div>
          </div>
        -->
          <div class="field" style="margin-bottom: 5px">
            <label>Remarks</label>
            <textarea-plugin
              #txtBlNettChargeRemarksConsole
              [settings]="settingTxtBlNettChargeRemarksConsole"
              (change)="changeEventRemarksConsole()"
            ></textarea-plugin>
          </div>
          <div class="field" style="margin-bottom: 5px">
            <div class="one wide fields">
              <div class="field">
                <button class="ui button" (click)="onBlNettChargeNewConsole()" disabled>
                  New
                </button>
              </div>

              <!--
              <button class="ui violet button" (click)="onBlNettChargeRefreight()" [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">Refreight</button>
              <button class="ui navy button" (click)="onBlNettChargeRefreightNew()" [class.disabled]="model['blType'] == 'SW' || model['blType'] == 'OIS'">Refreight (Optimized)</button>
              -->
              <div class="field">
                <div [style.display]="blLock == 'Y' ? 'block' : 'none'">
                  <button
                    class="ui button"
                    (click)="onRefreightFinalConsole()"
                    [class.disabled]="
                      model['blType'] == 'SW' || model['blType'] == 'OIS' || true
                    "
                  >
                    Refreight
                  </button>
                </div>
                <div [style.display]="blLock != 'Y' ? 'block' : 'none'">
                  <button
                    class="ui violet button"
                    style="z-index: 8 !important; position: relative"
                    (click)="onRefreightFinalConsole()"
                    [class.disabled]="
                      model['blType'] == 'SW' || model['blType'] == 'OIS' || true
                    "
                  >
                    Refreight
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="field"
          [style.display]="
            modeFormBlNettChargeConsole == '' ||
            modeFormBlNettChargeConsole == 'edit'
              ? 'block'
              : 'none'
          "
        >
          <div
            class="ui error message"
            [class.errorNettCharge]="isErrorNettChargeEntryConsole == true"
          >
            <ul>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCChargeCode'] == null ||
                  paramFreightChargeConsole['error-blFCChargeCode'] == '' ||
                  paramFreightChargeConsole['error-blFCChargeCode'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCChargeCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCPayer'] == null ||
                  paramFreightChargeConsole['error-blFCPayer'] == '' ||
                  paramFreightChargeConsole['error-blFCPayer'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCPayer"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCPc'] == null ||
                  paramFreightChargeConsole['error-blFCPc'] == '' ||
                  paramFreightChargeConsole['error-blFCPc'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCPc"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCPerCode'] == null ||
                  paramFreightChargeConsole['error-blFCPerCode'] == '' ||
                  paramFreightChargeConsole['error-blFCPerCode'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCPerCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCCurrencyCode'] == null ||
                  paramFreightChargeConsole['error-blFCCurrencyCode'] == '' ||
                  paramFreightChargeConsole['error-blFCCurrencyCode'] ==
                    undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCCurrencyCode"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCDuplicateRecord'] ==
                    null ||
                  paramFreightChargeConsole['error-blFCDuplicateRecord'] ==
                    '' ||
                  paramFreightChargeConsole['error-blFCDuplicateRecord'] ==
                    undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCDuplicateRecord"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCFaiGroup'] == null ||
                  paramFreightChargeConsole['error-blFCFaiGroup'] == '' ||
                  paramFreightChargeConsole['error-blFCFaiGroup'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCFaiGroup"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCRevenue'] == null ||
                  paramFreightChargeConsole['error-blFCRevenue'] == '' ||
                  paramFreightChargeConsole['error-blFCRevenue'] == undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCRevenue"] }}
              </li>
              <li
                [class.hidden]="
                  paramFreightChargeConsole['error-blFCPlaceOfPaymentCode'] ==
                    null ||
                  paramFreightChargeConsole['error-blFCPlaceOfPaymentCode'] ==
                    '' ||
                  paramFreightChargeConsole['error-blFCPlaceOfPaymentCode'] ==
                    undefined
                "
              >
                {{ paramFreightChargeConsole["error-blFCPlaceOfPaymentCode"] }}
              </li>
            </ul>
          </div>

          <div class="field">
            <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
              <legend style="font-weight: bold; color: dodgerblue">
                B/L Charge Entity
              </legend>
              <div class="field">
                <button
                  class="ui button"
                  (click)="onBlNettChargeEntryInsertConsole()"
                >
                  {{ txtBlContainerInsertUpdateConsole }}
                </button>
                <button
                  class="ui button"
                  (click)="onBlNettChargeEntryDeleteConsole()"
                  [class.disabled]="
                    nettChargeDisableDeleteConsole == true ||
                    paramFreightChargeConsole['blFCIsEntered'] == false
                  "
                >
                  Delete
                </button>
                <button
                  class="ui button"
                  (click)="onBlNettChargeEntryCancelConsole()"
                >
                  Cancel
                </button>
              </div>
              <div class="field" style="margin-bottom: 5px">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="two wide field" style="margin-top: 10px"></div>
                  <div class="one wide field" style="margin-top: 10px"></div>
                  <div class="two wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="chBlNettChargeEntryPrintConsole"
                        [checked]="
                          paramFreightChargeConsole['blFCIsChargePrinted'] ==
                          true
                        "
                        [(ngModel)]="
                          paramFreightChargeConsole['blFCIsChargePrinted']
                        "
                        (change)="
                          changeEventChBlNettChargeIsChargePrintedConsole(
                            $event
                          )
                        "
                      />
                      <label>Print</label>
                    </div>
                  </div>
                  <div class="two wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        class="disabled"
                        type="checkbox"
                        name="chBlNettChargeEntryFaiConsole"
                        [checked]="
                          paramFreightChargeConsole['blFCFaiGroup'] == true
                        "
                        [(ngModel)]="paramFreightChargeConsole['blFCFaiGroup']"
                        (change)="
                          changeEventChBlNettChargeIsChargeFaiGroupConsole(
                            $event
                          )
                        "
                        disabled
                      />
                      <label>FAI Group</label>
                    </div>
                  </div>
                  <div class="five wide field" style="margin-top: 10px">
                    <div class="ui checkbox">
                      <input
                        type="checkbox"
                        name="chBlNettChargeEntryManifestedFreightConsole"
                        [checked]="
                          paramFreightChargeConsole[
                            'blFCIsManifestedFreight'
                          ] == true
                        "
                        [(ngModel)]="
                          paramFreightChargeConsole['blFCIsManifestedFreight']
                        "
                        (change)="
                          changeEventChBlNettChargeIsManifestedFreightConsole(
                            $event
                          )
                        "
                      />
                      <label>Manifested Freight</label>
                    </div>
                  </div>
                </div>

                <div
                  class="fields"
                  style="margin-top: 10px; margin-bottom: 5px"
                >
                  <div class="two wide field">
                    <label>Seq</label>
                    <input
                      type="text"
                      name="txtBlNettChargeEntrySeqConsole"
                      disabled
                      [(ngModel)]="paramFreightChargeConsole['blFCSeq']"
                    />
                  </div>
                  <div
                    class="six wide field"
                    [class.error]="
                      paramFreightChargeConsole['error-blFCChargeCode'] !=
                        null &&
                      paramFreightChargeConsole['error-blFCChargeCode'] != ''
                    "
                  >
                    <label style="font-weight: bold; color: red"
                    >Charge Code *</label
                    >
                    <combo-plugin
                      #cbBlNettChargeInputChargeConsole
                      [settings]="settingCbBlNettChargeInputChargeConsole"
                      (change)="
                        changeEventCbBlNettChargeInputChargeConsole($event)
                      "
                      [disableCombo]="
                        paramFreightChargeConsole['blFCIsEntered'] == false
                      "
                    ></combo-plugin>
                  </div>
                  <div
                    class="three wide field"
                    [class.error]="
                      paramFreightChargeConsole['error-blFCCurrencyCode'] !=
                        null &&
                      paramFreightChargeConsole['error-blFCCurrencyCode'] != ''
                    "
                  >
                    <label style="font-weight: bold; color: red"
                    >Currency *</label
                    >
                    <combo-plugin
                      #cbBlNettChargeInputCurrencyConsole
                      [settings]="settingCbBlNettChargeInputCurrencyConsole"
                      (change)="
                        changeEventCbBlNettChargeInputCurrencyConsole($event)
                      "
                      [disableCombo]="
                        paramFreightChargeConsole['blFCIsEntered'] == false
                      "
                    ></combo-plugin>
                  </div>
                  <div
                    class="three wide field"
                    [class.error]="
                      paramFreightChargeConsole['error-blFCPc'] != null &&
                      paramFreightChargeConsole['error-blFCPc'] != ''
                    "
                  >
                    <label style="font-weight: bold; color: red">P/C *</label>
                    <combo-plugin
                      #cbBlNettChargeInputPCConsole
                      [settings]="settingCbBlNettChargeInputPCConsole"
                      (change)="changeEventCbBlNettChargeInputPCConsole($event)"
                      [disableCombo]="
                        paramFreightChargeConsole['blFCIsEntered'] == false
                      "
                    ></combo-plugin>
                  </div>
                  <div
                    class="six wide field"
                    [class.error]="
                      paramFreightChargeConsole[
                        'error-blFCPlaceOfPaymentCode'
                      ] != null &&
                      paramFreightChargeConsole[
                        'error-blFCPlaceOfPaymentCode'
                      ] != ''
                    "
                  >
                    <label style="font-weight: bold; color: red"
                    >Place of Payment *</label
                    >
                    <combo-plugin
                      #cbBlNettChargeInputPlaceOfPaymentConsole
                      [settings]="
                        settingCbBlNettChargeInputPlaceOfPaymentConsole
                      "
                      (change)="
                        changeEventCbBlNettChargeInputPlaceOfPaymentConsole(
                          $event
                        )
                      "
                    ></combo-plugin>
                  </div>
                  <div
                    class="six wide field"
                    [class.error]="
                      paramFreightChargeConsole['error-blFCPayer'] != null &&
                      paramFreightChargeConsole['error-blFCPayer'] != ''
                    "
                  >
                    <label style="font-weight: bold; color: red">Payer *</label>
                    <combo-plugin
                      #cbBlNettChargeInputPayerConsole
                      [settings]="settingCbBlNettChargeInputPayerConsole"
                      (change)="
                        changeEventCbBlNettChargeInputPayerConsole($event)
                      "
                    ></combo-plugin>
                  </div>
                </div>
              </div>

              <div class="field" style="margin-bottom: 5px">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="eight wide field">
                    <!-- Charge left section -->

                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Quantity</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightChargeConsole['error-blFCRevenue'] !=
                              null &&
                            paramFreightChargeConsole['error-blFCRevenue'] != ''
                          "
                        >
                          <input
                            type="number"
                            name="txtBlNettChargeEntryQuantityConsole"
                            [(ngModel)]="
                              paramFreightChargeConsole['blFCRevenue']
                            "
                            (change)="calculateNettChargeAmountConsole()"
                            min="0"
                            [disabled]="
                              paramFreightChargeConsole['blFCIsEntered'] ==
                              false
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Rate</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="number"
                            name="txtBlNettChargeEntryRateConsole"
                            [(ngModel)]="paramFreightChargeConsole['blFCRate']"
                            (change)="calculateNettChargeAmountConsole()"
                            [disabled]="
                              paramFreightChargeConsole['blFCIsEntered'] ==
                              false
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="three wide field">
                          <label>Per</label>
                        </div>
                        <div
                          class="twelve wide field"
                          [class.error]="
                            paramFreightChargeConsole['error-blFCPerCode'] !=
                              null &&
                            paramFreightChargeConsole['error-blFCPerCode'] != ''
                          "
                        >
                          <combo-plugin
                            #cbBlNettChargeInputPerCodeConsole
                            [settings]="
                              settingCbBlNettChargeInputPerCodeConsole
                            "
                            (change)="
                              changeEventCbBlNettChargeInputPerCodeConsole(
                                $event
                              )
                            "
                            [disableCombo]="
                              paramFreightChargeConsole['blFCIsEntered'] ==
                              false
                            "
                          ></combo-plugin>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="eight wide field">
                    <!-- Charge right section -->
                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>Total Amount</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="number"
                            name="txtBlNettChargeEntryTotalAmountConsole"
                            [(ngModel)]="
                              paramFreightChargeConsole['blFCAmount']
                            "
                            readonly
                            tabindex="5"
                            [disabled]="
                              paramFreightChargeConsole['blFCIsEntered'] ==
                              false
                            "
                          />
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 5px">
                        <div class="four wide field">
                          <label>PFC Ref.</label>
                        </div>
                        <div class="twelve wide field">
                          <input
                            type="text"
                            name="txtBlNettChargeEntryPFCReferenceConsole"
                            [(ngModel)]="
                              paramFreightChargeConsole['blFCFreightReference']
                            "
                            readonly
                            [disabled]="
                              paramFreightChargeConsole['blFCIsEntered'] ==
                              false
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="field">
          <grid-plugin
            #gridBlNettChargeConsole
            [settings]="settingGridBlNettChargeConsole"
            (gridEvent)="gridEventBlNettChargeConsole($event)"
            (infoGrid)="infoGridBlNettChargeConsole($event)"
          ></grid-plugin>
        </div>
      </div>
      <!-- Tab Nett Charge Console End -->

      <!-- Tab Gross Charge Console Started -->
      <div
        class="ui bottom attached tab segment"
        [class.active]="tabStatus9 == 'active' || tabStatus == 'tab9'"
        id="blGrossChargeConsoleTab"
        data-tab="blGrossChargeConsoleTab"
      >
        <!-- dont change || -->
        <div class="field">-- NOTHING --</div>
      </div>
      <!-- Tab Gross Charge End -->
    </div>
    <!-- 2nd Row -->
  </form>
</div>

<div
  id="blRetrievalDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="info circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlNo()">OK</button>

    </div>
  </form>
</div>

<div
  id="blSaveDialogOkOnly"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="info circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlSaveOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blDeleteDialogYesNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="question circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlYesDelete()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogBlNoDelete()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDeleteDialogOk"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlOk()">OK</button>
    </div>
  </form>
</div>

<div
  id="blDoubleClickYesNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="info circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlDeleteOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blOverwriteNotifyPartyAddress"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>
            {{ frmDialogMessage }}
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogNotifyPartyAddressYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogNotifyPartyAddressNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blOverwriteNotifyPartyAddressAsCnee"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>
            {{ frmDialogMessage }}
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogNotifyPartyAddressYesAsCnee()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogNotifyPartyAddressNoAsCnee()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blOverwriteNotifyPartyAddress2"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>
            {{ frmDialogMessage }}
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogNotifyPartyAddressYes2()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogNotifyPartyAddressNo2()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteContainer"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteContainerYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteContainerNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteContainerHS"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteContainerYesHS()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteContainerNoHS()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteAdContainer"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteAdContainerYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteAdContainerNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteMarkNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteMarkNoYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteMarkNoNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteDesc"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteDescYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteDescNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteNettCharge"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteNettChargeYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteNettChargeNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteNettChargeConsole"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteNettChargeYesConsole()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteNettChargeNoConsole()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteNettChargeExchangeRate"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteNettChargeExchangeRateYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteNettChargeExchangeRateNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blConfirmDeleteDescClause"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Confirm deletion?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogConfirmDeleteDescClauseYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogConfirmDeleteDescClauseNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blNettChargeSpecialExchangeRateDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <button class="ui button" style="display: none"></button>
    <div class="field" style="margin-bottom: 5px">
      <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
        <legend style="font-weight: bold; color: dodgerblue">
          Exchange Rate (Additional)
        </legend>
        <div
          class="ui error message"
          [class.errorNettChargeExchangeRate]="isErrorBlExchRate == true"
          style="margin-top: 0px; margin-bottom: 5px"
        >
          <ul>
            <li
              [class.hidden]="
                paramExchangeRate['error-blExchangeRateDestinationCurrency'] ==
                  null ||
                paramExchangeRate['error-blExchangeRateDestinationCurrency'] ==
                  '' ||
                paramExchangeRate['error-blExchangeRateDestinationCurrency'] ==
                  undefined
              "
            >
              {{ paramExchangeRate["error-blExchangeRateDestinationCurrency"] }}
            </li>
          </ul>
        </div>
        <div class="field">
          <grid-plugin
            #gridBlNettChargeExchangeRate
            [settings]="settingGridBlNettChargeExchangeRate"
            (gridEvent)="gridEventBlNettChargeExchangeRate($event)"
            (infoGrid)="infoGridBlNettChargeExchangeRate($event)"
          ></grid-plugin>
        </div>
        <div class="field">
          <label>Currency</label>
          <combo-plugin
            #cbBlNettChargeExchangeRateCurrency
            [settings]="settingCbBlNettChargeExchangeRateCurrency"
            (change)="changeEventCbBlNettChargeExchangeRateCurrency($event)"
            [disableCombo]="blExchRateUpdateLock == true"
            (keypress)="formValidation.CharNumOnly($event)"
            (keyup)="backspaceValue($event,formValidation.regAlphaNumericWSpace, 'cbBlNettChargeExchangeRateCurrency',  true, '')"
            (paste)="onPasteValidation(
              $event,
              'cbBlNettChargeExchangeRateCurrency',
              formValidation.regAlphaNumericWSpace,
              true, '')"
          ></combo-plugin>
          <div class="transition ui basic red pointing prompt label"
               *ngIf="model['error-cbBlNettChargeExchangeRateCurrency'] != null && model['error-cbBlNettChargeExchangeRateCurrency'] != ''">
            {{model['error-cbBlNettChargeExchangeRateCurrency']}}
          </div>
        </div>
        <div class="field">
          <label>Exchange Rate</label>
          <!-- <input
            type="text"
            name="blExchangeRatePerBl"
            autonumeric
            [options]="myOptions"
            [(ngModel)]="paramExchangeRate['blExchangeRate']"
            [disabled]="blExchRateUpdateLock == true"
            style="text-align: right"
            min="0"
            maxlength="7"
            (keydown)="validateExchage($event)"
            (paste)="onPaste($event)"
          /> -->

          <input
            type="text"
            name="blExchangeRatePerBl"
            [(ngModel)]="paramExchangeRate['blExchangeRate']"
            [disabled]="blExchRateUpdateLock == true"
            style="text-align: right"
            min="0"
            maxlength="7"
            (keydown)="validateExchage($event)"
            (paste)="onPaste($event)"
          />

          <!--<input type="number" name="blExchangeRatePerBl" [(ngModel)]="paramExchangeRate['blExchangeRate']" [disabled]="blExchRateUpdateLock == true">-->
        </div>
      </fieldset>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="ui tiny button"
        (click)="onDialogSERNew()"
        [class.disabled]="btnBlExchRateClauseNew == false"
        tabindex="-1"
      >
        New
      </button>
      <button
        class="ui tiny button"
        (click)="onDialogSERUpdate()"
        [class.disabled]="btnBlExchRateClauseUpdate == false"
        tabindex="-1"
      >
        Update
      </button>
      <button
        class="ui tiny button"
        (click)="onDialogSERDelete()"
        [class.disabled]="btnBlExchRateClauseDelete == false"
        tabindex="-1"
      >
        Delete
      </button>
      <button
        class="ui tiny button"
        (click)="onDialogSERCancel()"
        [class.disabled]="btnBlExchRateClauseCancel == false"
        tabindex="-1"
      >
        Cancel
      </button>
      <button class="ui tiny button" (click)="onDialogSERClose()">Close</button>
    </div>
  </form>
</div>

<div
  id="blPreVesselValidationDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogBlPreVesselValidationDialogOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blNextVesselValidationDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogBlNextVesselValidationDialogOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blContainerChargeDiscrepancyDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div
              [innerHTML]="frmDialogMessage | safe: 'html'"
              id="dialogVAlignTop"
            ></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogBlContainerChargeDiscrepancyDialogYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogBlContainerChargeDiscrepancyDialogNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blContainerChargeDiscrepancyDialogConsole"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div
              [innerHTML]="frmDialogMessage | safe: 'html'"
              id="dialogVAlignTop"
            ></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogBlContainerChargeDiscrepancyDialogYesConsole()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onDialogBlContainerChargeDiscrepancyDialogNoConsole()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogViewOnly"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onBlDialogViewOnlyYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onBlDialogViewOnlyNo()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogForBLLock"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="preRetrieve()">Yes</button>
      <button class="negative ui button" (click)="closePreRetrieve()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogForBLDeleted"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="preHandleRetrieve()">
        Yes
      </button>
      <button class="negative ui button" (click)="closePreRetrieveDeleted()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogRefreight"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onBlDialogRefreightYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onBlDialogRefreightNo()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogDateOfIssueEarlier"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onCheckDateOfIssueYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onCheckDateOfIssueNo()">
        No
      </button>
    </div>
  </form>
</div>


<div
  id="blDialogShortShipmentOverwrite"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogShortshipmentOverwriteYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onBlDialogShortshipmentOverwriteNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogSave"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onBlDialogSaveOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogShortshipEtd"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onBlDialogShortshipEtdOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogReworkedEtd"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onBlDialogReworkedEtdOk()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="blDialogSReworkedOverwrite"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogSReworkedOverwriteYes()"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onBlDialogSReworkedOverwriteNo()"
      >
        No
      </button>
    </div>
  </form>
</div>

<div
  id="clickRefreightDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogClickRefreightDialogOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="clickRefreightDialogNew"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogClickRefreightDialogNewOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="clickPreVesselAsOceanVessel"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogClickPreVesselAsOceanVesselOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>
<div
  id="clickNextVesselAsOceanVessel"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button
        class="positive ui button"
        (click)="onBlDialogClickNextVesselAsOceanVesselOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="printDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <div
    class="ui inverted dimmer"
    [class.active]="printLoading == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>
  <form class="ui form">
    <button
      class="mini ui icon right floated button"
      (click)="printButtonEvent('close')"
    >
      <i class="close icon"></i>
    </button>
    <!--
    <button
      class="mini ui icon right floated button"
      (click)="printButtonEvent2('print')"
    >
      <i class="print icon" style="color: red"></i>
    </button>
    -->
    <button
      class="mini ui icon right floated button"
      (click)="printButtonEvent('print')"
    >
      <i class="print icon"></i>
    </button>
    <br /><br />
    <table border="0">
      <tr>
        <td>
          <div class="ui checkbox">
            <input
              #allowPrintContainerNumWithSpacingChecbox
              id="allowPrintContainerNumWithSpacingChecbox"
              type="checkbox"
              [checked]="allowSpacingContainerNo"
              (change)="printContainerNumWithSpace(allowPrintContainerNumWithSpacingChecbox.checked)"
            />
            <label><b>Print Container Num. With Spacing</b></label>
          </div>

          <br />

          <div class="ui radio checkbox">
            <input
              id="original"
              type="radio"
              name="printType"
              (change)="printSelectionChange('original')"
              disabled
            />
            <label style="color: grey; opacity: 0.5; cursor: not-allowed; pointer-events: none;">B/L Print (Original)</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="draft"
              type="radio"
              name="printType"
              (change)="printSelectionChange('draft')"
              disabled
            />
            <label style="color: grey; opacity: 0.5; cursor: not-allowed; pointer-events: none;">B/L Print (Draft)</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="draftwt"
              type="radio"
              name="printType"
              (change)="printSelectionChange('draftwt')"
              disabled
            />
            <label style="color: grey; opacity: 0.5; cursor: not-allowed; pointer-events: none;">B/L Print (Draft) with Template</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="copy"
              type="radio"
              name="printType"
              (change)="printSelectionChange('copy')"
            />
            <label>NN Copy</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="copywt"
              type="radio"
              name="printType"
              (change)="printSelectionChange('copywt')"
            />
            <label>NN Copy with Template</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="copy"
              type="radio"
              name="printType"
              (change)="printSelectionChange('copynoa')"
            />
            <label>Noa Report</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input
              id="copywt"
              type="radio"
              name="printType"
              (change)="printSelectionChange('copynoawt')"
            />
            <label>Noa Report with Template</label>
          </div>
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </form>
</div>

<div
  id="blPrintDialogMessage"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="blPrintDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="blPrintOkButton()">OK</button>
    </div>
  </form>
</div>

<!-- ADDITIONAL DETAIL CONTAINER PART -->
<div
  id="blBLAdContainerAddDialog"
  style="display: none; z-index: 101; font-family: Lucida Console, monospace; overflow: visible"
>
  <div class="content">
    <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
      <legend style="font-weight: bold; color: dodgerblue">
        Insert/Update
      </legend>
      <form class="ui form">
        <button style="display: none"></button>

        <div class="field" style="margin-bottom: 0px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="six wide field">
              <div class="fields" style="margin-bottom: 0px">
                <div class="four wide field">
                  <label>Seq</label>
                  <input
                    type="text"
                    name="txtBlContainerADSeq"
                    [(ngModel)]="paramBlAdContainer['blAdContainerSeq']"

                    disabled="true"
                  />
                </div>

                <div class="required field"
                     [class.error]="
                      paramBlAdContainer['error-blAdContainerCommodityTypeId'] != null &&
                      paramBlAdContainer['error-blAdContainerCommodityTypeId'] != ''
                    "
                     style="width: 100%"
                >
                  <label>Commodity</label>
                  <!-- GOS-506 To set COMMODITY field in Additional Details section as FREE-TEXT-->
                  <!-- <combo-plugin
                    #cbBlAdContainerCommodity
                    [settings]="settingCbBlAdContainerCommodity"
                    (change)="changeEventCbBlAdContainerCommodity($event)"
                    (ngModelChange)="paramBlAdContainer['error-blAdContainerCommodityTypeId'] = ''"
                    (keypress)="CharNumOnly($event)"

                    [disableCombo]="frmContainerLock == true"
                  ></combo-plugin> -->
                  <input
                    type="text"
                    maxlength="225"
                    name="blAdContainerCommodityTypeName"
                    [(ngModel)]="paramBlAdContainer['blAdContainerCommodityTypeName']"
                    (ngModelChange)="changeCommodity($event)"
                  />
                  <div
                    class="transition ui basic red pointing prompt label error-message-hidden"
                    [class.error-message-visible]="
                        paramBlAdContainer['error-blAdContainerCommodityTypeId'] != null &&
                        paramBlAdContainer['error-blAdContainerCommodityTypeId'] != ''
                      "
                  >
                    {{ paramBlAdContainer['error-blAdContainerCommodityTypeId'] }}
                  </div>
                </div>
              </div>

              <div class="field">
                <label style="margin-top: 5px">Inner Quantity</label>
                <input
                  type="number"
                  name="txtBlAdContainerInnerQty"
                  [(ngModel)]="paramBlAdContainer['blAdContainerInnerQty']"
                  [disabled]="frmContainerLock == true"
                />
              </div>

              <div class="field">
                <label style="margin-top: 5px">DG CLS</label>
                <combo-plugin
                  #cbBlAdContainerDGClass
                  [settings]="settingCbBlAdContainerDGClass"
                  (change)="
                    changeEventCbBlAdContainerDgClass($event)
                  "
                ></combo-plugin>
              </div>

              <div class="required field"
                   [class.error]="
                paramBlAdContainer['error-blAdContainerGrossWeightString'] != null &&
                paramBlAdContainer['error-blAdContainerGrossWeightString'] != ''
                "
                   style="width: 100%"
              >
                <label style="margin-top: 5px">Weight (KGS)</label>
                <!-- <input
                  type="number"
                  name="txtBlAdContainerGrossWeight"
                  [(ngModel)]="paramBlAdContainer['blAdContainerGrossWeight']"
                  (ngModelChange)="paramBlAdContainer['error-blAdContainerGrossWeight'] = ''"
                  [disabled]="frmContainerLock == true"
                /> -->
                <div [class.tooltip]="showTooltip !== ''">
                  <input
                    type="text"
                    name="blAdContainerGrossWeightString"
                    [(ngModel)]="paramBlAdContainer['blAdContainerGrossWeightString']"
                    (ngModelChange)="paramBlAdContainer['error-blAdContainerGrossWeight'] = ''"
                    [disabled]="frmContainerLock == true"
                    (focus)="showTooltip=''"
                    (paste)="onPasteDecimalFilter($event, paramBlAdContainer, 'blAdContainerGrossWeightString')"
                    appFourDigitDecimalNumber
                  />
                  <span class="tooltiptext">{{ showTooltip }}</span>
                </div>
                <div
                  class="transition ui basic red pointing prompt label error-message-hidden"
                  [class.error-message-visible]="
                      paramBlAdContainer['error-blAdContainerGrossWeightString'] != null &&
                      paramBlAdContainer['error-blAdContainerGrossWeightString'] != ''
                    "
                >
                  {{ paramBlAdContainer['error-blAdContainerGrossWeightString'] }}
                </div>
              </div>
            </div>


            <div class="six wide field">

              <label>HS Code</label>
              <combo-plugin
                #cbBlAdContainerHsCode
                [settings]="settingCbBlAdContainerHsCode"
                (change)="changeEventCbBlAdContainerHsCode($event)"
                (keypress)="NumDotOnly($event)"
                (paste)="pasteEvent($event, 'blAdContainerHsCode')"
              ></combo-plugin>


              <label style="margin-top: 5px">Inner Package Type</label>
              <combo-plugin
                #cbBlAdContainerInnerPkgType
                [settings]="settingCbBlAdContainerInnerPkgType"
                (change)="changeEventCbBlAdContainerInnerPkgType($event)"
                [disableCombo]="frmContainerLock == true"
              ></combo-plugin>



              <div class="fields" style="margin-bottom: 0px">
                <div class="four wide field">
                  <label style="margin-top: 5px">IMO</label>
                  <input
                    type="text"
                    name="txtBlAdContainerIMO"
                    [(ngModel)]="paramBlAdContainer['blAdContainerIMO']"
                    [disabled]="frmContainerLock == true"
                    (keypress)="formValidation.NumWDot($event)"
                  />
                </div>

                <div class="four wide field">
                  <label style="margin-top: 5px">UNNO</label>
                  <input
                    type="text"
                    name="txtBlAdContainerUNNO"
                    [(ngModel)]="paramBlAdContainer['blAdContainerUNNO']"
                    [disabled]="frmContainerLock == true"
                    (keypress)="formValidation.NumWDot($event)"
                  />
                </div>

                <div class="five wide field">
                  <label style="margin-top: 5px">F/P</label>
                  <input
                    type="number"
                    name="txtBlAdContainerFlashPoint"
                    [(ngModel)]="
                    paramBlAdContainer['blAdContainerFlashPoint']
                    "
                    [disabled]="frmContainerLock == true"
                  />
                </div>

                <div class="field">
                  <label style="margin-top: 3px">&nbsp;</label>
                  <combo-plugin
                    #cbBlAdContainerFlashPointScale
                    [settings]="settingCbBlAdContainerFlashPointScale"
                    (change)="
                      changeEventBlAdContainerFlashPointScale($event)
                    "
                    [disableCombo]="frmContainerLock == true"
                  ></combo-plugin>
                </div>
              </div>

              <div class="required field"
                   [class.error]="
                paramBlAdContainer['error-blAdContainerGrossMeasureString'] != null &&
                paramBlAdContainer['error-blAdContainerGrossMeasureString'] != ''
                "
                   style="width: 100%"
              >
                <label style="margin-top: 7px">Measurement (CBM)</label>
                <div [class.tooltip]="showTooltip !== ''">
                  <input
                    type="text"
                    name="blAdContainerGrossMeasureString"
                    [(ngModel)]="paramBlAdContainer['blAdContainerGrossMeasureString']"
                    (ngModelChange)="paramBlAdContainer['error-blAdContainerGrossMeasure'] = ''"
                    [disabled]="frmContainerLock == true"
                    (focus)="showTooltip=''"
                    (paste)="onPasteDecimalFilter($event, paramBlAdContainer, 'blAdContainerGrossMeasureString')"
                    appFourDigitDecimalNumber
                  />
                  <span class="tooltiptext">{{ showTooltip }}</span>
                </div>

                <div
                  class="transition ui basic red pointing prompt label error-message-hidden"
                  [class.error-message-visible]="
                      paramBlAdContainer['error-blAdContainerGrossMeasureString'] != null &&
                      paramBlAdContainer['error-blAdContainerGrossMeasureString'] != ''
                    "
                >
                  {{ paramBlAdContainer['error-blAdContainerGrossMeasureString'] }}
                </div>
              </div>
            </div>

            <div class="six wide field">

              <label>HS/Product Description</label>
              <combo-plugin
                #cbBlAdContainerHsName
                [settings]="settingCbBlAdContainerHsName"
                (change)="changeEventCbBlAdContainerHsName($event)"
              ></combo-plugin>

              <label style="margin-top: 5px">Remarks</label>
              <textarea
                wrap="on"
                [(ngModel)]="paramBlAdContainer['blAdContainerHsRemarks']"
                name="blAdContainerHsRemarks"
                placeholder="Input your remark..."
                maxlength="999999"
                (keypress)="formValidation.AllChar($event)"
              ></textarea>

              <!-- <textarea-v2-plugin
                #txtBlAdContainerHsRemarks
                [settings]="settingTxtBlAdContainerHsRemarks"
              ></textarea-v2-plugin> -->

            </div>
          </div>
        </div>

        <div class="field" style="margin-bottom: 5px">
          <div class="eight wide field">
            <button class="positive ui tiny button" (click)="blAdContainerUpdate()"
                    [class.disabled]="true"
            >
              {{txtBlAdContainerSaveUpdate}}
            </button>
            <button class="ui tiny button" (click)="blBlAdContainerDelete()" [class.disabled]="true">
              delete
            </button>
            <button class="ui tiny button" (click)="blBlAdContainerCancelDialog()">
              cancel
            </button>
          </div>
        </div>

      </form>
    </fieldset>
  </div>
</div>

<div
  id="hsDialog"
  style="display: none; z-index: 101; font-family: Lucida Console, monospace; overflow: visible"
>
  <div class="content">
    <fieldset style="border: 1px solid lightgray; margin-bottom: 5px">
      <legend style="font-weight: bold; color: dodgerblue">
        Insert/Update
      </legend>

      <form class="ui form">
        <button style="display: none"></button>
        <div class="fields" style="margin-bottom: 5px">
          <div class="one wide field">
            <label>Seq</label>
            <input
              type="text"
              name="txtBlContainerHSSeq"
              [(ngModel)]="paramContainerHSCode['blContainerHSSeq']"
              disabled="true"
            />
          </div>
          <div class="ten wide field">
            <label>HS Code</label>

            <combo-plugin
              #cbBlContainerHS
              [settings]="settingCbBlContainerHS"
              (change)="changeEventCbBlContainerHS($event)"
              [disableCombo]="frmContainerHSLock == true"
            ></combo-plugin>
          </div>
          <div class="four wide field">
            <div class="ui checkbox" style="margin-top: 25px">
              <input
                type="checkbox"
                [checked]="paramContainerHSCode['blContainerHSPrint'] == true"
                [(ngModel)]="paramContainerHSCode['blContainerHSPrint']"
                name="chBlContainerHSCodePrint"
                (change)="changeEventChBlContainerHSPrint($event)"
                tabindex="0"
              />
              <label>Print</label>
            </div>
          </div>
        </div>
        <div class="field" style="margin-bottom: 5px">
          <div class="eight wide field">
            <button class="positive ui tiny button" (click)="blBlContainerHSUpdate()" [class.disabled]="true">
              {{txtBlContainerHSSaveUpdate}}
            </button>
            <button class="ui tiny button" (click)="blBlContainerHSDelete()" [class.disabled]="true">
              delete
            </button>
            <button class="ui tiny button" (click)="blBlContainerHSCancel()" [class.disabled]="false">
              cancel
            </button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</div>
<!-- END ADDITIONAL DETAIL CONTAINER PART -->

<!-- GOS-346 -->
<div
  id="dialogIas"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="info circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogIasOk()">
        OK
      </button>
    </div>
  </form>
</div>
<div
  id="dialogIasError"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="delete red big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogIasErrorOk()">
        OK
      </button>
    </div>
  </form>
</div>
<!-- End of GOS-346 -->

<!-- GOS-736  -->
<div id="showPrompterDiscrepancies" style="display: none; font-family: Lucida Console, monospace">
  <div class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px; margin-top: 20px;">
      <div class="d-flex">
        <div style="text-align: left; width: 15%;">
          <i class="info circle huge icon"></i>
        </div>
        <div style="width: 85%;">
          <p>Total quantity of DG class in Container and Booking not tally.</p>
          <p>Please amend before saving.</p>
          <table border="0">
            <tr *ngFor="let item of containerQuantityDGClass">
              <td class="td-Container-Quantity">
                {{item.title}}
              </td>
              <td>
                <span class="p-0-10">{{item.count}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.blContainerTypeName}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.blContainerSize}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.blContainerHeight}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.blContainerDGClass}}</span>
              </td>
            </tr>
            <tr *ngIf="containerQuantityDGClass.length === 0">
              <td>
                Container Quantity & DG Class
              </td>
              <td>
                [NOTHING]
              </td>
            </tr>
            <br>
            <br>
            <tr *ngFor="let item of bookingDataDetail">
              <td class="td-Container-Quantity">
                {{item.title}}
              </td>
              <td>
                <span class="p-0-10">{{item.bQuantity}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.bContainerTypeName}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.bContainerSize}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.bContainerHeight}}</span>
              </td>
              <td>
                <span class="p-0-10">{{item.bDgClass === 'N' ? 'N/A' :item.bDgClass}}</span>
              </td>
            </tr>
          </table>
          <span class="span-continue-al">Continue to proceed?</span>
        </div>
      </div>
    </div>
    <div class="field" style="text-align: center; margin-top: 15px;">
      <button class="positive ui button" (click)="yesPrompterDiscrepancies()">Yes</button>
      <button class="negative ui button" (click)="closePrompterDiscrepancies()">No</button>
    </div>
  </div>
</div>

<pdf-plugin
  #pdfContainer
  style="margin-left: 3px"
  [settings]="settingPDF"
  (change)="eventPDFPlugin($event)"
></pdf-plugin>
