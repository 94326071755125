import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { BLHeader, specialCharUtil } from "../shared";
declare var $: any;


@Component({
  selector: 'app-report-in-bl-freight-list-page',
  templateUrl: './report-in-bl-freight-list-page.component.html',
  styleUrls: ['./report-in-bl-freight-list-page.component.css']
})
export class ReportInBlFreightListPageComponent
  extends Validator
  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbVesselSum") cbVesselSum: ComboPluginComponent;
  @ViewChild("cbVoyageSum") cbVoyageSum: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridSum") gridSum: GridPluginComponent;
  @ViewChild("cbTranshipment") cbTranshipment: ComboPluginComponent;
  @ViewChild("cbFinDes") cbFinDes: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbLoadPortSum") cbLoadPortSUm: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbDischargePortSum") cbDischargePortSum: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVesselSum;
  settingVoyage;
  settingVoyageSum;
  settingPOD;
  settingPOR;
  settingLoadPort;
  settingLoadPortSum;
  settingDischargePort;
  settingDischargePortSum;
  settingTranshipment;
  settingFinDes;
  settingGrid;
  settingPDF;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  isCOC: Boolean = true;
  isSOC: Boolean = true;
  isSLOT: Boolean = true;
  isSOCTs: Boolean = true;
  isError: Boolean = false;
  isCOCTs: Boolean = true;
  chSilent: Boolean = false;
  chSilentSum: Boolean = false;
  lockBL: Boolean = false;
  lockBLSum: Boolean = false;
  cCOC: String;
  whichFlag: String = "Tab1";
  cSOC: String;
  cSLOT: String;
  cSOCTs: String;
  cCOCTs: String;
  chSelection: String;
  chTSelection: String;
  chType: String;
  rdType: String;
  generateAs: String;
  gA: String;
  param: String;
  userName: String;
  IsContOwn: String;
  isChecked: String = "Y";
  addRemoveLeftSide: String = "disable";
  addRemoveButton = "none";
  lengthArray: number = 0;
  postDt = {};
  rmode = "FLD";
  blNos = [];

  // lock
  cButtonLock = true;
  dButtonLock = true;

  sCharUtil = new specialCharUtil();
  model = new BLHeader();
  model2 = new BLHeader();

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  userId: string = "";

  validatorRules = {};

  constructor(
    private cdr: ChangeDetectorRef,
    auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Actual Freight List Report (Inward)",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselSum = {
      id: "cbVesselSum",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageSum = {
      id: "cbVoyageSum",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingTranshipment = {
      id: "cbTrans",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinDes = {
      id: "cbFinDes",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingLoadPortSum = {
      id: "cbLoadPortSum",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDiscPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePortSum = {
      id: "cbDiscPortSum",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url: "", //this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: "10",
      columns: [
        { header: "B/L No", field: "blNo", width: 200, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
      height: 200,
      minHeight: 100,
      autoSize: "static",
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
    };
  }

  setValidatorVessel() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    $(".dataTables_scrollBody").css("height", "422px");

    $(".menu .item").tab();

    this.afterViewMode();

    this.userId = this.cookieService.getName();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "print":
        /*if(this.whichFlag == "Tab1"){
        this.onPrint();
      }else{
        this.onPrintSum();
      }*/

        if (this.lockBL) {
          this.isError = false;
        } else {
          var checkVessel = false;
          var checkVoyage = false;
          var checkBound = false;
          if (this.model.blOceanVesselCode == "") {
            this.model["error-blOceanVesselCode"] = "Please input Vessel";
            checkVessel = true;
          } else {
            this.model["error-blOceanVesselCode"] = '';
            checkVessel = false;
          }

          if (this.model.blOceanVesselVoyage == "") {
            this.model["error-blOceanVesselVoyage"] = "Please input Voyage";
            checkVoyage = true;
          } else {
            this.model["error-blOceanVesselVoyage"] = '';
            checkVoyage = false;
          }

          if (this.model.blOceanVesselBound == "") {
            this.model["error-blOceanVesselBound"] = "Please input bound";
            checkBound = true;
          }

          this.isError = false;
          if (checkVessel || checkVoyage || checkBound) {
            this.isError = true;
          }
        }

        console.log("report mode ");
        console.log(this.rmode);
        if (this.rmode == "FLD") {
          this.onPrint();
        } else {
          if (this.rmode == "FSC") {
            this.rdType = "Cont";
          } else if (this.rmode == "FSP") {
            this.rdType = "POP";
          }
          this.onPrintSum();
        }

        break;
      case "cancel":
        this.onCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  clearValidatorError() {
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model2["error-blOceanVesselCode"] = "";
    this.model2["error-blOceanVesselVoyage"] = "";
    this.model2["error-blOceanVesselBound"] = "";
  }

  onPrintSum() {
    if (this.lockBL) {
      if (this.grid.listStore.store.length > 0) {
        this.saveBL();
      } else {
        this.message(
          "information",
          "Information",
          "Please insert at least 1 BL to print freight list",
          "okonly",
          { ok: "" }
        );
      }
    } else {
    }

    console.log("Print disini");
    //console.log ("VesselId : "+this.model2.blOceanVesselId);
    //console.log ("voyage : "+this.model2.blOceanVesselVoyage);
    //console.log ("bound : "+this.model2.blOceanVesselBound);
    //console.log("userName : "+this.cookieService.getName());
    console.log("Report Type : " + this.rdType);
    console.log("Generate as : " + this.generateAs);

    //this.userName = this.cookieService.getName();
    //this.param = this.model2.blOceanVesselId+"="+this.model2.blOceanVesselVoyage+"="+this.model2.blOceanVesselBound+"="+''+"="+this.model2.blLoadPortCode+"="+this.model2.blDischargePortCode+"="+''+"="+''+"="+''+"="+''+"="+'';

    this.userName = this.cookieService.getName();
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSLOT;
    this.param =
      this.model.blOceanVesselId +
      "=" +
      this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
      "=" +
      this.model.blOceanVesselBound +
      "=" +
      this.model.blPlaceOfReceiptCode +
      "=" +
      this.model.blLoadPortCode +
      "=" +
      this.model.blDischargePortCode +
      "=" +
      this.model.blPlaceOfDeliveryCode +
      "=" +
      this.model.blPortOfTransshipment1Code +
      "=" +
      this.model.blFinalDestinationCode +
      "=" +
      this.IsContOwn +
      "=" +
      this.chType;

    //this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
    //this.setValidatorVessel();
    //this.isError = this.onValidate(this.model);
    //this.setValidatorVessel();
    //this.isError = this.onValidate(this.model);
    console.log("isError " + this.isError);
    if (!this.isError) {
      if (this.model.blOceanVesselCode != "") {
        this.loading = true;

        var urlCheck = "";
        var postData = {};
        postData["officeCode"] = this.officeCode;
        postData["param"] = this.param;
        if (this.rdType == "Cont") {
          urlCheck =
            this.configService.config.BASE_API +
            "/FreightListReportInward/freightList/checkFreightActual/" +
            this.officeCode +
            "/" +
            this.param +
            "/freight-list-summary-cnt";
          postData["reportType"] = "flsc";
        } else {
          urlCheck =
            this.configService.config.BASE_API +
            "/FreightListReportInward/freightList/checkFreightActual/" +
            this.officeCode +
            "/" +
            this.param +
            "/freight-list-summary-pop";
          postData["reportType"] = "flsp";
        }
        this.genericService.GET(urlCheck).subscribe(
          (resp) => {
            //this.loading = false;
            if (resp.ok) {
              var result = resp.json()["content"];
              if (result["status"]) {
                //print
                var postUrl =
                  this.configService.config.BASE_API +
                  "/FreightListReportInward/freightList/print";

                this.genericService.POST(postUrl, postData).subscribe(
                  (resp) => {
                    //this.loading = false;
                    if (resp.ok) {
                      var res = resp.json()["content"];
                      if (res["status"]) {
                        this.printFreightBlSummary();
                      } else {
                        this.loading = false;
                        this.message(
                          "information",
                          "Information",
                          "There are some error occur while printing freight list report with error code : 1. Please contact your administrator!",
                          "okonly",
                          { ok: "" }
                        );
                      }
                    }
                  },
                  (error) => {
                    this.loading = false;
                    this.message(
                      "information",
                      "Information",
                      "There are some error occur while printing freight list report, error message : " +
                      error.json()["message"] +
                      ". Please contact your administrator!",
                      "okonly",
                      { ok: "" }
                    );
                  }
                );

                //this.printFreightList();
              } else {
                //not print
                this.loading = false;
                this.message(
                  "information",
                  "Information",
                  result["message"],
                  "okonly",
                  { ok: "" }
                );
              }
            }
          },
          (error) => {
            this.loading = false;
            this.message(
              "information",
              "Information",
              "There are some error occur while printing freight list report, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
              "okonly",
              { ok: "" }
            );
          }
        );
      }
    }
  }

  printFreightBlSummary() {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var createDate = this.genericUtil.getPSIDate();
    console.log("horaay " + this.gA);
    //if(this.generateAs =="PDF"){
    if (this.gA == "PDFFreight") {
      console.log("container");
      if (this.rdType == "Cont") {
        var jasperURL =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/freightListVesselSummaryContainerInward.pdf?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");
        var fileName = "Freight List Summary Container" + this.userId + ".pdf";
      } else {
        var jasperURL =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/FreightListSummaryByPOPInward.pdf?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");
        var fileName = "Freight List Summary POP" + this.userId + ".pdf";
      }

      console.log("HIT JASPER: " + jasperURL);

      var postData = {};
      postData["fileName"] = fileName;
      postData["url"] = jasperURL;

      this.genericService.POST(hitUrl, postData).subscribe((resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            this.pdfContainer.setPDFURL(
              this.configService.config.pdfUrl + result["filePath"]
            );
            this.pdfContainer.showPDF();
          }

          this.loading = false;
        }
      });
    } /*if (this.generateAs =="EXC")*/ else {
      if (this.rdType == "Cont") {
        console.log("here cont");
        var jasperURL =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/freightListVesselSummaryContainerInward.xls?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");
        var fileName = "Freight List Summary Container" + this.userId + ".xls";
      } else {
        console.log("here pop");
        var jasperURL =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/FreightListSummaryByPOPInward.xls?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");
        var fileName = "Freight List Summary POP" + this.userId + ".xls";
      }

      var postData = {};
      postData["fileName"] = fileName;
      postData["url"] = jasperURL;

      this.genericService.POST(hitUrl, postData).subscribe((resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            window.open(this.configService.config.pdfUrl + result["filePath"]);
          }

          this.loading = false;
        }
      });

      this.loading = false;
    }
  }

  onPrint() {
    // console.log("Print disini");
    // console.log ("VesselId : "+this.model.blOceanVesselId);
    //console.log ("voyage : "+this.model.blOceanVesselVoyage);
    //console.log ("bound : "+this.model.blOceanVesselBound);
    //  console.log("userName : "+this.cookieService.getName());
    // console.log("ContOwn : "+this.IsContOwn);
    // console.log("Status nya: "+this.lockBL);

    if (this.lockBL) {
      // if (this.grid.listStore.store.length > 0) {
      if (this.grid.getSelectedValues().length > 0) {
        if (this.grid.getSelectedValues().every(item => item.blNo === '')) {
          this.message(
            "information",
            "Information",
            "Please insert at least 1 BL to print freight list",
            "okonly",
            { ok: "" }
          );
        } else {
          this.saveBL();
        }
      } else {
        if (this.grid.listStore.store.some(item => item.blNo !== '')) {
          this.message(
            "information",
            "Information",
            "Please select BL to print",
            "okonly",
            { ok: "" }
          );
        } else {
          this.message(
            "information",
            "Information",
            "Please insert at least 1 BL to print freight list",
            "okonly",
            { ok: "" }
          );
        }

      }
    } else {
      this.userName = this.cookieService.getName();
      this.IsContOwn =
        this.cCOC +
        "-" +
        this.cSOC +
        "-" +
        this.cCOCTs +
        "-" +
        this.cSOCTs +
        "-" +
        this.cSLOT;
      this.param =
        this.model.blOceanVesselId +
        "=" +
        this.sCharUtil.htmlEncode(
          String(this.model.blOceanVesselVoyage).trim()
        ) +
        "=" +
        this.model.blOceanVesselBound +
        "=" +
        this.model.blPlaceOfReceiptCode +
        "=" +
        this.model.blLoadPortCode +
        "=" +
        this.model.blDischargePortCode +
        "=" +
        this.model.blPlaceOfDeliveryCode +
        "=" +
        this.model.blPortOfTransshipment1Code +
        "=" +
        this.model.blFinalDestinationCode +
        "=" +
        this.IsContOwn +
        "=" +
        this.chType;
      //this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
      //this.setValidatorVessel();
      //this.isError = this.onValidate(this.model);
      console.log("isError L : " + this.isError);
      if (!this.isError) {
        // validate freight
        this.loading = true;
        var urlCheck =
          this.configService.config.BASE_API +
          "/FreightListReportInward/freightList/checkFreightActual/" +
          this.officeCode +
          "/" +
          this.param +
          "/freight-list-details";
        this.genericService.GET(urlCheck).subscribe(
          (resp) => {
            //this.loading = false;
            if (resp.ok) {
              var result = resp.json()["content"];
              if (result["status"]) {
                if (resp.json()["noRateBlNosCount"] > 0) {
                  this.message(
                    "information",
                    "Information",
                    resp.json()["noRateBlNosMsg"],
                    "okonly",
                    { ok: "" }
                  );
                }

                if (resp.json()["hasRateBlNos"] > 0) {
                  if(result["proforma"]){
                    this.message(
                      "information",
                      "Information",
                      resp.json()["noRateBlNosMsg"],
                      "okonly",
                      { ok: "this.doPrintFreightList();" }
                    );
                  } else {
                    this.doPrintFreightList();
                  }
                } else {
                  this.loading = false;
                }

              } else {
                //not print
                this.loading = false;
                this.message(
                  "information",
                  "Information",
                  result["message"],
                  "okonly",
                  { ok: "" }
                );
              }
            }
          },
          (error) => {
            this.loading = false;
            this.message(
              "information",
              "Information",
              "There are some error occur while printing freight list report, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
              "okonly",
              { ok: "" }
            );
          }
        );
      }
    }
  }

  doPrintFreightList(){
    var postData = {};
    postData["officeCode"] = this.officeCode;
    postData["param"] = this.param;
    postData["reportType"] = "fld";

    var postUrl =
      this.configService.config.BASE_API +
      "/FreightListReportInward/freightList/print";

    this.genericService.POST(postUrl, postData).subscribe(
      (resp) => {
        this.loading = false;
        if (resp.ok) {
          var res = resp.json()["content"];
          if (res["status"]) {
            this.printFreightList();
          } else {
            this.message(
              "information",
              "Information",
              "There are some error occur while printing freight list report with error code : 1. Please contact your administrator!",
              "okonly",
              { ok: "" }
            );
          }
        }
      },
      (error) => {
        this.loading = false;
        this.message(
          "information",
          "Information",
          "There are some error occur while printing freight list report, error message : " +
          error.json()["message"] +
          ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  printFreightList() {
    var createDate = this.genericUtil.getPSIDate();
    if (this.gA == "PDFFreight") {
      if (this.model.blOceanVesselCode != "") {
        this.loading = true;
        //var hitUrl = this.configService.config.BASE_API.toString()  + "/edi-util/getFile";
        var hitUrl =
          this.configService.config.getPDFUrl + "/edi-util/getFile/multiple";
        var jasperURL =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/freightListVesselInward.pdf?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");
        var fileName = "FreightListVesel-" + this.userId + ".pdf";

        // console.log("HIT JASPER: "+jasperURL);

        var postData = {};
        postData["userid"] = this.userId;
        postData["processData"] = this.genericUtil.getPSIDate();
        postData["documentType"] = "Freight-List-Vessel";

        var eDataList = [];
        var eData = {};
        eData["fileName"] = fileName;
        eData["url"] = jasperURL;

        eDataList.push(eData);

        eData = {};
        eData["fileName"] = "summary-split-" + this.userId + ".pdf";
        eData["url"] =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/SummarySplitInward.pdf?userCreated=" +
          this.userName +
          "&officeCode=" +
          this.officeCode +
          "&param=" +
          this.param +
          "&createDate=" +
          createDate.replace(" ", "%20");

        eDataList.push(eData);

        postData["blData"] = eDataList;

        this.genericService.POST(hitUrl, postData).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json();

            if (result["message"] == "success") {
              this.pdfContainer.setPDFURL(
                this.configService.config.pdfUrl + result["filePath"]
              );
              this.pdfContainer.showPDF();
            }

            this.loading = false;
          }
        });
        //}
      }
    } else {
      console.log("here pop freight list detail");
      this.loading = true;
        var hitUrl = this.configService.config.BASE_API.toString()  + "/edi-util/getFile";

      var jasperURL =
        this.configService.config.jasperUrl +
        "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
        this.officeCode +
        "/freightListVesselInward.xls?userCreated=" +
        this.userName +
        "&officeCode=" +
        this.officeCode +
        "&param=" +
        this.param +
        "&createDate=" +
        createDate.replace(" ", "%20");
      
        var fileName = "FreightListVeselInward-" + this.userId + ".xls";

        var postData = {};
        postData["userid"] = this.userId;
        postData["processData"] = this.genericUtil.getPSIDate();
        postData["documentType"] = "Freight-List-Vessel";
        postData["fileName"] = fileName;
        postData["url"] = jasperURL;

        this.genericService.POST(hitUrl, postData).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json();

            if (result["message"] == "success") {
              window.open(this.configService.config.pdfUrl + result["filePath"]);
            }

            this.loading = false;
          }
        });
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVesselSum(event) {
    //  console.log("EVENTnya :"+event.vesselCode);
    //  console.log("Vessel Id :"+event.vesselId);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model2.blOceanVesselId = "";
      this.model2.blOceanVesselCode = "";
    } else {
      this.model2.blOceanVesselId = event.vesselId;
      this.model2.blOceanVesselCode = event.vesselCode;

      this.cbVoyageSum.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyageSum.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyageInward/" +
        // this.cookieService.getDefaultLocationCode() +
        // "/" +
        event.vesselId +
        "/" +
        this.model2.blOceanVesselBound.toUpperCase() +
        "/voyage_like={query}"
      );
      this.cbVoyageSum.setValue("");
    }

    this.cbVesselSum.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  rowEvent(act: string) {
    var tableStore = [];
    if (act == "add") {
      // console.log("here");

      this.grid.listStore.store.push({
        id: (this.grid.listStore.store.length + 1).toString(),
        blNo: "",
      });
      this.grid.listStore.store.forEach((tt) => {
        console.log(tt);
      });

      this.grid.loadData();
      //this.grid.is_select_all = 'checked';
      this.grid.onSelectAllByValue(true);
      this.grid.is_select_all = "checked";
    } else if (act == "del") {
      // console.log("PANJANG GRIDNYA: "+this.grid.onSelect.length);

      //  console.log("masuk remove");
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck: number;
      var storeTemp = new ListStore();

      this.grid.listStore.store.forEach((xy) => {
        //  console.log("xy "+xy);
        storeTemp.addData(xy);
      });

      storeTemp.store.forEach((el) => {
        if (el["select"] == "checked") {
          boxCheck = this.grid.listStore.store.indexOf(el);
          this.grid.listStore.store.splice(boxCheck, 1);

          $('input[name="gridCount"]').val(
            parseInt($('input[name="gridCount"]').val()) - 1
          );
        } else {
        }
      });

      this.grid.listStore.store.forEach((col) => {
        seqNo = seqNo + 1;
        col.seqNo = seqNo;
      });

      this.grid.loadData();
      this.grid.clearSelectedValues();
    }
  }

  changeEventVessel(event) {
    // console.log("EVENTnya :"+event.vesselCode);
    //  console.log("Vessel Id :"+event.vesselId);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyageInward/" +
        // this.cookieService.getDefaultLocationCode() +
        // "/" +
        event.vesselId +
        "/" +
        this.model.blOceanVesselBound.toUpperCase() +
        "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  Sum(event) {
    this.cbVoyageSum.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/distinctVoyage/" +
      event.vesselId +
      "/" +
      "O" +
      "/{query}/" +
      this.cookieService.getDefaultLocationCode()
    );
  }

  afterViewMode() {
    this.vesselMode();
    this.model.blOceanVesselBound = "I";
    this.model2.blOceanVesselBound = "I";
    this.chSelection = "Vessel";
    this.chTSelection = "FLD";
    this.rmode = "FLD";
    this.chType = "ALL";
    this.cCOC = "COC";
    this.cSOC = "SOC";
    this.cCOCTs = "COCTS";
    this.cSOCTs = "SOCTS";
    this.cSLOT = "SLOT";
    this.rdType = "Cont";
    this.generateAs = "PDF";
    this.gA = "PDFFreight";
    this.chSilent = false;
    this.chSilentSum = false;
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSLOT;
  }

  onCancel() {
    this.afterViewMode();
    //this.cbVessel.clearSelect();
    //this.cbVessel.setValue("");
    this.cbVessel.setForceValue("");
    //this.cbVoyage.clearSelect();
    //this.cbVoyage.setValue("");
    this.cbVoyage.setForceValue("");
    //this.cbVesselSum.clearSelect();
    //this.cbVesselSum.setValue("");
    //this.cbVoyageSum.clearSelect();
    //this.cbVoyageSum.setValue("");
    //this.cbDischargePort.clearSelect();
    //this.cbDischargePort.setValue("");
    this.cbDischargePort.setForceValue("");
    //this.cbLoadPort.clearSelect();
    //this.cbLoadPort.setValue("");
    this.cbLoadPort.setForceValue("");
    //this.cbFinDes.clearSelect();
    //this.cbFinDes.setValue("");
    this.cbLoadPort.setForceValue("");
    //this.cbTranshipment.clearSelect();
    //this.cbTranshipment.setValue("");
    this.cbTranshipment.setForceValue("");
    //this.cbPOD.clearSelect();
    //this.cbPOD.setValue("");
    this.cbPOD.setForceValue("");
    //this.cbPOR.clearSelect();
    //this.cbPOR.setValue("");
    this.cbPOR.setForceValue("");
    this.cbDischargePort.setForceValue("");
    //this.grid.listStore.store.splice(0,this.grid.listStore.store.length);
    //this.grid.url = '';
    this.grid.onClear();
    this.model["error-blOceanVesselCode"] = '';
    this.model["error-blOceanVesselVoyage"] = '';
    this.model.blOceanVesselCode = "";
    this.model.blOceanVesselId = "";
    this.model.blOceanVesselVoyage = "";
    this.isError = false;
  }

  changeContainerCOC(event) {
    if (event.target.checked) {
      this.isCOC = true;
      this.cCOC = "COC";
    } else {
      this.isCOC = false;
      this.cCOC = "";
    }
  }

  changeContainerSOC(event) {
    if (event.target.checked) {
      this.isSOC = true;
      this.cSOC = "SOC";
    } else {
      this.isSOC = false;
      this.cSOC = "";
    }
  }

  changeContainerCOCTs(event) {
    if (event.target.checked) {
      this.isCOCTs = true;
      this.cCOCTs = "COCTS";
    } else {
      this.isCOCTs = false;
      this.cCOCTs = "";
    }
  }

  changeContainerSOCTs(event) {
    if (event.target.checked) {
      this.isSOCTs = true;
      this.cSOCTs = "SOCTS";
    } else {
      this.cSOCTs = "";
      this.isSOCTs = false;
    }
  }

  changeContainerSLOT(event) {
    if (event.target.checked) {
      this.isSLOT = true;
      this.cSLOT = "SLOT";
    } else {
      this.isSLOT = false;
      this.cSLOT = "";
    }
  }

  initTab1() {
    console.log("ini tab 1");
    this.whichFlag = "Tab1";
  }
  initTab2() {
    console.log("ini tab 2");

    this.whichFlag = "Tab2";
  }
  changeEventVoyage(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }
  changeEventPOR(event) {
    console.log("POR " + event.locationCode);
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfReceiptCode = "";
    } else {
      this.model.blPlaceOfReceiptCode = event.locationCode;
    }
  }
  changeEventLoadPort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blLoadPortCode = "";
    } else {
      this.model.blLoadPortCode = event.locationCode;
    }
  }
  changeEventDischargePort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blDischargePortCode = "";
    } else {
      this.model.blDischargePortCode = event.locationCode;
    }
  }
  changeEventPOD(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfDeliveryCode = "";
    } else {
      this.model.blPlaceOfDeliveryCode = event.locationCode;
    }
  }
  changeEventTranshipment(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPortOfTransshipment1Code = "";
    } else {
      this.model.blPortOfTransshipment1Code = event.locationCode;
    }
  }
  changeEventFinDes(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blFinalDestinationCode = "";
    } else {
      this.model.blFinalDestinationCode = event.locationCode;
    }
  }
  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log("eventgrid" + event);
    switch (event.split(".")[0]) {
      case "selected":
        this.grid.getSelectedValues().forEach((element) => {
          if (element.select == "checked") {
            flgTool = 1;
          }
        });
        if (flgTool > 0) this.disableToolbarButtons = "";
          //console.log(flgTool);
          // this.disableToolbarButtons = "unlock";
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        else break;
      case "click":
        this.grid.listStore.store.forEach((yr) => {
          console.log("KLIK GRID " + yr);
          if (yr.select == "checked") {
            console.log("ceki ceki " + yr["blNo"]);
            this.model.blOfficeCode = this.officeCode;
            this.model.blNo = yr["blNo"].toUpperCase();
            flgTool = 1;
          } else {
            this.model.blNo = "";
            this.model.blOfficeCode = "";
          }
        });

        if (flgTool > 0) console.log(flgTool);
        // this.disableToolbarButtons = "retrieve";
        else this.isChecked = "N";
        //this.disableToolbarButtons = "retrieve,unlock";
        break;

      case "afterLoad":
        this.lengthArray = this.grid.listStore.store.length;

        if (this.lengthArray == 0) {
          console.log("LENGTH GRID --> " + this.grid.listStore.store.length);
          this.grid.listStore.store = [];
          // this.message(
          //   "information",
          //   "Information",
          //   "Invalid BL No.",
          //   "okonly",
          //   { ok: "this.loading=false;" }
          // );
        }

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          console.log("before split4 -> " + strEvent);
          console.log("arr3 -> " + arr);
          console.log("arr-split1 -> " + arr[0]);
          console.log("arr-split2 -> " + arr[1]);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              if (strEvent.split(";")[1] == "blNo") {
                this.getBLStatus(strEvent.split(";")[2]);
              }

              this.grid.listStore.store.forEach((yr) => {
                console.log(yr);
                soso.push(yr);
                if (yr.select == "checked") {
                  flgTool = 1;
                  this.model.blNo = yr["blNo"].toUpperCase();
                  this.model.blOfficeCode = this.officeCode;
                } else {
                  this.model.blNo = "";
                  this.model.blOfficeCode = "";
                }
              });

              if (flgTool > 0)
                //this.disableToolbarButtons = "retrieve";
                console.log(flgTool);
              else this.disableToolbarButtons = "unlock";
              dblFlag = this.checkDuplicateInObject("blNo", soso);

              if (dblFlag == true) {
                this.message(
                  "information",
                  "Information",
                  "Duplicate BL Reference(s) detected.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.grid.listStore.store.splice(
                  parseInt(arr[0].split(".")[1]) - 1,
                  1
                );
                this.grid.loadData();
              }

              break;
            case "dblClick":
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  getBLStatus(blNo: String) {
    var BLPrint: String;
    var boxCheck: number;

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/FreightListReportInward/findBLNo/" +
        this.officeCode +
        "/" +
        blNo.toUpperCase()
      )
      .subscribe((resp) => {
        console.log("Masuk getBLStatus");
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            BLPrint = resp.json()["content"][0].blIsBlPrinted;
            console.log("Status BL --> " + resp.json()["content"][0].blStatus);
            if(resp.json()["content"][0].blFound == "Y") {
              switch (resp.json()["content"][0].blStatus) {
                case "D":
                  console.log("bl delete");
                  this.grid.listStore.store.forEach((data) => {
                    if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                      this.message(
                        "information",
                        "Information",
                        "This BL has been deleted.",
                        "okonly",
                        {ok: "this.loading=false;"}
                      );

                      boxCheck = this.grid.listStore.store.indexOf(data);
                      console.log("BOXCEK --> " + boxCheck);
                      this.grid.listStore.store.splice(boxCheck, 1);
                      this.grid.loadData();
                    }
                  });
                  break;
                default:
                  break;
              }
            } else {
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.message(
                    "information",
                    "Information",
                    "BL Not Found.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.grid.loadData();
                }
              });
            }
          } else {
            this.grid.listStore.store.forEach((data) => {
              if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                this.message(
                  "information",
                  "Information",
                  "Invalid BL No.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );

                boxCheck = this.grid.listStore.store.indexOf(data);
                this.grid.listStore.store.splice(boxCheck, 1);
                this.grid.loadData();
              }
            });
          }
        }
      });
  }

  infoGrid(event) {}
  changeEventVoyageSum(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model2.blOceanVesselVoyage = "";
    } else {
      this.model2.blOceanVesselVoyage = event.voyage;
    }
  }
  changeEventLoadPortSum(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model2.blLoadPortCode = "";
    } else {
      this.model2.blLoadPortCode = event.locationCode;
    }
  }
  changeEventDischargePortSum(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model2.blDischargePortCode = "";
    } else {
      this.model2.blDischargePortCode = event.locationCode;
    }
  }

  changeType(event) {
    if (event.target.value == "ALL") {
      this.chType = "ALL";
    } else if (event.target.value == "FULL") {
      this.chType = "FULL";
    } else if (event.target.value == "EMPTY") {
      this.chType = "EMPTY";
    }
  }
  changeSelection(event) {
    if (event.target.value == "BL") {
      this.chSelection = "BL";
      this.blMode();
    } else if (event.target.value == "Vessel") {
      this.chSelection = "Vessel";
      this.vesselMode();
    }
  }

  changeSelectionR(event) {
    if (event.target.value == "FLD") {
      this.rmode = "FLD";
      this.chTSelection = "FLD";
    } else if (event.target.value == "FSC") {
      this.rmode = "FSC";
      this.chTSelection = "FSC";
    } else if (event.target.value == "FSP") {
      this.rmode = "FSP";
      this.chTSelection = "FSP";
    }
  }

  changeRadioType(event) {
    if (event.target.value == "Cont") {
      this.rdType = "Cont";
    } else if (event.target.value == "POP") {
      this.rdType = "POP";
    }
  }

  changeGenerate(event) {
    if (event.target.value == "PDF") {
      this.generateAs = "PDF";
    } else if (event.target.value == "EXC") {
      this.generateAs = "EXC";
    }
  }

  changeGenerateFreight(event) {
    console.log("Dimana dimana " + event.target.value);
    if (event.target.value == "PDFFreight") {
      this.gA = "PDFFreight";
    } else if (event.target.value == "EXCFreight") {
      this.gA = "EXCFreight";
    }
  }

  blMode() {
    // clear ves/voy
    this.onCancel();

    this.addRemoveButton = "block";
    this.lockBL = true;
    this.chSilent = false;
    this.grid.enable();
  }
  vesselMode() {
    console.log("vesselMode");
    this.chSilent = false;
    this.grid.disable();
    this.addRemoveButton = "none";
    this.lockBL = false;
    this.chSilent = false;
    
    // clear grid data
    this.grid.listStore.store = [];
    this.grid.loadData();
    this.grid.clearSelectedValues();
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  eventPDFPlugin(event) {}

  saveBL() {
    this.postDt = {};
    this.postDt["blNo"] = [];
    this.blNos = [];
    console.log("Masuk save BL 0");
    if (this.grid.getSelectedValues().length > 0) {
      console.log("Masuk save BL");
      this.grid.getSelectedValues().forEach((element) => {
        console.log("data elemen: " + element.blNo);
        this.postDt["blNo"].push(element.blNo);
        this.blNos.push(element.blNo);
      });
      this.postDt["sessionId"] = this.cookieService.getName();
      this.postDt["officeCode"] = this.officeCode;
      this.postDt["result"] = "freightListinward";
      this.postDt["containerOwnership"] = "";
      this.postDt["blOceanVesselId"] = "";
      this.postDt["blOceanVesselVoyage"] = "";
      this.postDt["blOceanVesselBound"] = "";
      this.postDt["blOceanVesselName"] = "";

      this.saveEvent(this.blNos);

      /*this.message('save', 'Saving data', 'Do you want to save the record? ', 'yesno', {
        yes: this.saveEvent(),
        no: 'this.loading = false;'
      });*/
    }
  }

  saveEvent(paramBlNos: string[]) {
    console.log("Save event");
    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/BLPrint/saveBLNo",
        this.postDt
      )
      .subscribe(
        (resp) => {
          console.log("STATUS=" + resp.status);
          console.log("MESSAGE=" + resp.json()["message"]);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            this.userName = this.cookieService.getName();
            this.param = "session=" + this.userName + "=" + "freightListInward"
              + "=" + paramBlNos;

            var urlCheck =
              this.configService.config.BASE_API +
              "/FreightListReportInward/freightList/checkFreightActual/" +
              this.officeCode +
              "/" +
              this.param +
              "/freight-list-details";
            if (this.rmode == "FSC") {
              urlCheck =
                this.configService.config.BASE_API +
                "/FreightListReportInward/freightList/checkFreightActual/" +
                this.officeCode +
                "/" +
                this.param +
                "/freight-list-summary-cnt";
              //postData["reportType"] = "flsc";
            } else if (this.rmode == "FSP") {
              urlCheck =
                this.configService.config.BASE_API +
                "/FreightListReportInward/freightList/checkFreightActual/" +
                this.officeCode +
                "/" +
                this.param +
                "/freight-list-summary-pop";
              //postData["reportType"] = "flsp";
            }

            this.genericService.GET(urlCheck).subscribe(
              (resp) => {
                if (resp.ok) {
                  var result = resp.json()["content"];
                  if (result["status"]) {
                    if (resp.json()["noRateBlNosCount"] > 0) {
                      this.message(
                        "information",
                        "Information",
                        resp.json()["noRateBlNosMsg"],
                        "okonly",
                        { ok: "" }
                      );
                    }
                    if (resp.json()["hasRateBlNos"] > 0) {
                      if(result["proforma"]){
                        this.message(
                          "information",
                          "Information",
                          resp.json()["noRateBlNosMsg"],
                          "okonly",
                          { ok: "this.doPrintFreightListSum();" }
                        );
                      } else {
                        this.doPrintFreightListSum();
                      }
                    } else {
                      this.loading = false;
                    }
                  } else {
                    this.loading = false;
                    this.message(
                      "information",
                      "Information",
                      result["message"],
                      "okonly",
                      { ok: "" }
                    );
                  }
                }
              },
              (error) => {
                this.loading = false;
                this.message(
                  "information",
                  "Information",
                  "There are some error occur while printing freight list report, error message : " +
                  error.json()["message"] +
                  ". Please contact your administrator!",
                  "okonly",
                  { ok: "" }
                );
              }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  doPrintFreightListSum(){
    var postData = {};
    postData["officeCode"] = this.officeCode;
    postData["param"] = this.param;
    postData["reportType"] = "fld";
    if (this.rmode == "FSC") {
      //urlCheck = this.configService.config.BASE_API + "/BLReport/freightList/checkFreight/"+this.officeCode+"/"+this.param+"/freight-list-summary-cnt";
      postData["reportType"] = "flsc";
    } else if (this.rmode == "FSP") {
      //urlCheck = this.configService.config.BASE_API + "/BLReport/freightList/checkFreight/"+this.officeCode+"/"+this.param+"/freight-list-summary-pop";
      postData["reportType"] = "flsp";
    }

    var postUrl =
      this.configService.config.BASE_API +
      "/FreightListReportInward/freightList/print";

    this.genericService.POST(postUrl, postData).subscribe(
      (resp) => {
        this.loading = false;
        if (resp.ok) {
          var res = resp.json()["content"];
          if (res["status"]) {
            if (this.rmode == "FLD") {
              this.printFreightListBL();
            } else {
              this.printFreightBlSummary();
            }
          } else {
            this.message(
              "information",
              "Information",
              "There are some error occur while printing freight list report with error code : 1. Please contact your administrator!",
              "okonly",
              { ok: "" }
            );
          }
        }
      },
      (error) => {
        this.loading = false;
        this.message(
          "information",
          "Information",
          "There are some error occur while printing freight list report, error message : " +
          error.json()["message"] +
          ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  printFreightListBL() {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var createDate = this.genericUtil.getPSIDate();
    var jasperURL =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
      this.officeCode +
      "/freightListVesselInward.pdf?userCreated=" +
      this.userName +
      "&officeCode=" +
      this.officeCode +
      "&param=" +
      this.param +
      "&createDate=" +
      createDate.replace(" ", "%20");
    var fileName = "FreightListVesel-" + this.userId + ".pdf";

    if (this.gA == "PDFFreight") {
      var postData = {};
      postData["fileName"] = fileName;
      postData["url"] = jasperURL;

      this.genericService.POST(hitUrl, postData).subscribe((resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            this.pdfContainer.setPDFURL(
              this.configService.config.pdfUrl + result["filePath"]
            );
            this.pdfContainer.showPDF();
          }

          this.loading = false;
        }
      });
    } else {
      //var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/freightListVessel.xls?userCreated="+this.userName+"&officeCode="+this.officeCode+"&param="+this.param+"&createDate="+createDate.replace(" ","%20");
      // window.open(jasperURL.replace(".pdf", ".xls"));
      var jasperURL =
        this.configService.config.jasperUrl +
        "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
        this.officeCode +
        "/freightListVesselInward.xls?userCreated=" +
        this.userName +
        "&officeCode=" +
        this.officeCode +
        "&param=" +
        this.param +
        "&createDate=" +
        createDate.replace(" ", "%20");
      var fileName = "FreightListVesel-" + this.userId + ".xls";
      var postData = {};
      postData["fileName"] = fileName;
      postData["url"] = jasperURL;

      this.genericService.POST(hitUrl, postData).subscribe((resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            window.open(this.configService.config.pdfUrl + result["filePath"]);
          }

          this.loading = false;
        }
      });
    }
  }

  printFreightListBL2(jasperURL, fileName) {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var createDate = this.genericUtil.getPSIDate();
    //var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/freightListVessel.pdf?userCreated="+this.userName+"&officeCode="+this.officeCode+"&param="+this.param+"&createDate="+createDate.replace(" ","%20");
    //var fileName = "FreightListVesel-"+this.userId+".pdf";

    console.log("HIT JASPER: " + jasperURL);

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl, postData).subscribe((resp) => {
      if (resp.ok) {
        var result = resp.json();

        if (result["message"] == "success") {
          this.pdfContainer.setPDFURL(
            this.configService.config.pdfUrl + result["filePath"]
          );
          this.pdfContainer.showPDF();
        }

        this.loading = false;
      }
    });
  }

}
