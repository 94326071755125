<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-vesselId'] == null || param['error-vesselId'] == '' || param['error-vesselId'] == undefined ">
        {{param['error-vesselId']}}
      </li>
      <li [class.hidden]="param['error-voyage'] == null || param['error-voyage'] == '' || param['error-voyage'] == undefined ">
        {{param['error-voyage']}}
      </li>
      <li [class.hidden]="param['error-bound'] == null || param['error-bound'] == '' || param['error-bound'] == undefined ">
        {{param['error-bound']}}
      </li>
      <li [class.hidden]="param['error-pol'] == null || param['error-pol'] == '' || param['error-pol'] == undefined ">
        {{param['error-pol']}}
      </li>
    </ul>
  </div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">Booking Forecast Report</label>
        <br />
        <br />

        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="four wide field">
              <label>Vessel</label>
              <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"></combo-plugin>
            </div>

            <div class="four wide field">
              <label>Voyage</label>
              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" ></combo-plugin>
            </div>

            <div class="four wide field">
              <div class="fields" style="margin-bottom: 0px">
                <div class="eight wide field">
                  <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="param['bound']" >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="fields" style="margin-bottom: 0px">
            <div class="four wide field">
              <label>Port of Loading</label>
              <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" ></combo-plugin>
            </div>
            <div class="four wide field">
              <label>Transhipment Port</label>
              <combo-plugin #cbTranshipmentPort [settings]="settingTranshipmentPort" (change)="changeEventTranshipmentPort($event)" ></combo-plugin>
            </div>
          </div>
          
        </div>
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
      </div>  <!-- end of raised segment -->
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
