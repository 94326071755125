export class AccountPic  {
    no : number = 0;
    accountId  : string = '';
    accountName  : string = '';
    department:string = '';
    designation:string = '';
    countryId: string = '';
    countryName: string = '';
    isValid        : boolean = false;
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid : string = '';
    userDeleted : string = '';
    dateDeleted : string = '';
    isDeleted      : boolean = false;

    constructor() {

    }
}
