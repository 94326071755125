<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
<form class="ui raised segment"> 
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <br><br>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <div class="field">
        <div class="fields">
          <div class="five wide field">
            <label>Edifact Type</label>
            <combo-plugin #cbEdifact [settings]="settingEdifact" (change)="changeEventEdifact($event)" style="width:100%;"></combo-plugin>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-vesselId'] != null && model['error-vesselId'] != ''">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-vesselId'] != null && model['error-vesselId'] != ''">
              {{model['error-vesselId']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" >
            <label>Voyage</label>
            <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <label>Bound</label>
            <input type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['bound']" (change)="changeEventBound($event)" (keypress)="noneSpace($event)" >
            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
              {{model['error-bound']}}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
