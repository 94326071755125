import { Component, OnInit } from '@angular/core';
import { TransactionTsPortHomeService } from './transaction-ts-port-home.service';

export enum Tab {
  SUMMARY_PORT_PAIR = "SUMMARY_PORT_PAIR",
  PORT_PAIR = "PORT_PAIR",
  PROSPECT = "PROSPECT",
  RENOMINATION = "RENOMINATION",
  TRANSFER = "TRANSFER",
  EXCEPTION = "EXCEPTION"
}

@Component({
  selector: 'app-transaction-port-home',
  templateUrl: './transaction-ts-port-home.component.html',
  styleUrls: ['./transaction-ts-port-home.component.css']
})

export class TransactionTsPortHomeComponent implements OnInit {
  tab = Tab;
  tabStatus = this.tab.SUMMARY_PORT_PAIR;

  constructor(
    private transactionTsPortHomeService: TransactionTsPortHomeService
  ) { }

  ngOnInit() {
  }

  handleInitTab(tab) {
    this.tabStatus = tab;
  }

  ngOnDestroy(){
    this.transactionTsPortHomeService.setDataPortPairAllocation({});
    this.transactionTsPortHomeService.setDataPropectBooking({});
    this.transactionTsPortHomeService.setDataRenominationBooking({});
    this.transactionTsPortHomeService.setDataTransferPodBooking({});
    this.transactionTsPortHomeService.setDataExceptionBooking({});
  }

}
