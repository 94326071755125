

export class LocationBak  {
    no : number = 0;
    countryCode  : string = '';
    countryName  : string = '';
    portCode : string = '';
    locationCode : string = '';
    portOriginalName : string = '';
    portNormalizedName : string = '';
    agencyCode   : number = 0;
    agencyName   : string = '';
    port         : boolean = false;
    place        : boolean = false;
    valid        : boolean = false;
    
    constructor() { 
      
    }
}
