import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild
} from "@angular/core";
import { AccountPic } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
declare var $: any;

@Component({
  selector: "app-master-customer-pic-page",
  templateUrl: "./master-customer-pic-page.component.html",
  styleUrls: ["./master-customer-pic-page.component.css"],
})
export class MasterCustomerPicPageComponent
  extends Validator
  implements OnInit, AfterViewInit {
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridDetails") gridDetails: GridPluginComponent;
  @ViewChild("cbCountry") cbCountry: ComboPluginComponent;
  @ViewChild("cbCustomerHistroy") cbCustomerHistory: ComboPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting: ToolbarPluginComponent;
  @ViewChild('cbCustomerHistroy') cbCustomerHistroy: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = "";

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingCountry;
  settingCustomerHistory;
  settingGridHistory;

  typeCustomerLogStartDate = "calendar";
  typeCustomerLogEndDate = "calendar";

  /* Parameter model */
  model = new AccountPic();

  loading = false;

  lock = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;
  accountIdHistory: string = "";

  /* validatorRules */
  validatorRules = {
    accountName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Account Name.",
        },
      ],
    },
    designation: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Designation.",
        },
      ],
    },
    department: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Department.",
        },
      ],
    },
    countryName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Country.",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Account Id", value: "accountId" },
        { display: "Account Name", value: "accountName" },
        { display: "Department", value: "department" },
        { display: "Designation", value: "designation" },
        { display: "Country Id", value: "countryId" },
        // { display: "Valid", value: "isValid" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" },
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Account Name", field: "accountName", width: 200 },
        { header: "Account Id", field: "accountId", width: 100 },
        { header: "Department", field: "department", width: 120 },
        { header: "Designation", field: "designation", width: 100 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "accountName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Account Name", field: "accountName", width: 200 },
        { header: "Account Id", field: "accountId", width: 100 },
        { header: "Department", field: "department", width: 120 },
        { header: "Designation", field: "designation", width: 100 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,

      enablePagination: true,
    };

    this.settingGridDetails = {
      url: "",
      page: 5,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Account Name", field: "accountName", width: 200 },
        { header: "Account Id", field: "accountId", width: 100 },
        { header: "Department", field: "department", width: 120 },
        { header: "Designation", field: "designation", width: 100 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "accountName",
      sortingDirection: "ASC",
    };

    this.settingCountry = {
      id: "cbCountry",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Country",
      title: "countryName",
      description: "",
      isMark: true,
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCustomerHistory = {
      id: "cbCustomerHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "accountName",
      description: "",
      isMark: true,
      columns: [
        { header: "Account PIC ID", field: "accountId", width: 70 },
        { header: "Account Name", field: "accountName", width: 70 },
        { header: "Valid?", field: "isValid", width: 70 },
      ],
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    $(".test.checkbox").checkbox();
    $(".special.cards .image").dimmer({
      on: "hover",
    });

    //this.setValidatorHeader();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.genericUtil.setBreadcrumb("Master,Entity,Master-Customer:/main/master-customer");
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridDetails(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleUpdate();
              break;
            case "dblClick":
              let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                let accountId: string = String(dt.accountId).trim();
                this.onRetrieve(accountId);

                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons = "create,update,delete";
                } else {
                  this.disableToolbarButtons = "";
                }
                this.invisibleToolbarButtons = "create,history,print";

                this.modeForm = true;
                this.modeToolbar = false;
                this.lock = true;
              }
              // this.gridDetails.disable();
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetails(event: any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              //this.setValidatorDetails();
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "save,cancel";
              }

              if (this.model["error-customerName"] != null) {
                this.model["error-customerName"] = "";
              }

              let dt = this.gridDetails.getSelectedValues()[0];
              this.gridDetails.listStore.store.forEach((element) => {
                if (dt.no == element["no"]) {
                  // this.modelDetail = new CustomerDetail;
                  // this.modelDetail = element;
                  // console.log(this.modelDetail);
                }
              });

              $("#display").transition();
              $("#displayGrid").transition("hide");

              break;

            default:
              break;
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    //// console.log('event : ' + event);
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        // if(this.model.customerId != ""){
        //   this.model.userModified = this.cookieService.getName();
        // } else {
        //   this.model.userCreated = this.cookieService.getName();
        // }
        this.model.userCreated = this.cookieService.getName();
        this.model.userModified = this.cookieService.getName();
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  setValidatorHeader() {}

  setValidatorDetails() {}

  handleCreate() {
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";

    this.model = new AccountPic();
    this.model.isValid = true;
  }

  handleUpdate() {
    let dt = this.grid.getSelectedValues()[0];

    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData(dt);
      this.lock = false;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,delete,print";

      let accountId: string = String(dt.accountId).trim();
      this.onRetrieve(accountId);
    }

    //this.onRetrieve()
  }

  handleDelete() {
    this.loading = true;
    let deleteList = [];
    var haveDeleteRow = false;

    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.accountId);
      if (element.isDeleted == "Y") {
        haveDeleteRow = true;
      }
    });

    if (!haveDeleteRow) {
      this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListAccountPICUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {

            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }

          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );

      // this.loading = true;
      // this.message(
      //   "delete",
      //   "Deletion Record",
      //   "Confirm to delete record(s)?",
      //   "yesno",
      //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
      // );
    } else {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
    }

  }

  handlePrint() {
    var formatName = "report-Account-PIC.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/63/Account-PIC/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReport/63/Account-PIC/*"
    // );
  }

  handleSave() {
    // handle save event
    this.model.dateCreated = this.genericUtil.getPSIDate();
    // console.log(this.model);

    let isValid: boolean = this.onValidate(this.model);

    if (!isValid) {
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;
    this.cbCountry.setValue("");
    this.cbCustomerHistory.setValue("");

    this.cbCustomerHistory.clearSelect();
    this.cbCustomerHistory.close();
  }

  handleHistory() {
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  saveEvent() {
    this.loading = true;
    //// console.log(this.model);

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterAccountPic/save",
        this.model
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //clear all
            this.model = new AccountPic();
            this.cbCountry.setValue("");

            this.loading = false;
            this.info = "save successfully";

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    //  this.grid.onFirst();
  }

  deleteEvent() {
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.accountId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"accountId":[]}
    paramsDelete.accountId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/MasterAccountPic/delete",
        // deleteList
        "/MasterAccountPic/softDelete",
          paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.loading = false;
            let data = resp.json();
            if (data.status == 'NOK') {
              this.message(
                "information",
                "Information",
                data.message,
                "okonly",
                { ok: "this.loading=false;" }
              );
              return;
            }
            this.model = new AccountPic();
            this.cbCountry.setValue("");
            this.message(
              "information",
              "Information",
              data.message,
              "okonly",
              { ok: "this.loading=false;this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  onRetrieve(accountId: string) {
    // this.gridDetails.onClear();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterAccountPic/findById/" +
          accountId
      )
      .subscribe((resp) => {
        let data = resp.json();
        this.setData(data);
      });
  }

  retrieveHistory(event: any) {
    this.gridHistory.search = this.cbCustomerHistory.data.accountId
    this.gridHistory.loadData();
  }

  changeEventCountry(event: any) {
    if (event["countryId"] != null) {
      this.model.countryId = event["countryId"];
    } else {
      this.model.countryId = "";
    }

    if (event["countryName"] != null) {
      this.model.countryName = event["countryName"];
      this.model['error-countryName'] = "";
    } else {
      this.model.countryName = "";
    }
  }

  changeEventCustomerHistory(event: any) {
    this.accountIdHistory = "";
    // console.log(event);
    // if(event.customerId == ''){
    //   if(event.target != ''){

    //   }

    // }else{
    this.accountIdHistory = String(event.accountId).trim();
    this.gridHistory.onHistory(this.accountIdHistory);
    // }
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  infoGridHistory(event: any) {
    // this.info=event;
  }

  setData(data: any) {
    // let data = this.grid.getSelectedValues()[0];

    console.log(data.countryName);
    this.model = new AccountPic();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.accountId = String(data.accountId).trim();
    this.model.countryId = String(data.countryId).trim();
    this.model.countryName = String(data.countryName).trim().toUpperCase();
    this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());
    this.model.department = String(data.department).trim().toUpperCase();
    this.model.designation = String(data.designation).trim();
    this.model.accountName = String(data.accountName)
      .trim()
      .replace(/&nbsp;/g, " ");
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.dateDeleted = String(data.dateDeleted).trim().toUpperCase();
    this.model.userDeleted = String(data.userDeleted).trim().toUpperCase();

    if (data.isDeleted == "Y") {
      this.model.isDeleted = true;
    } else {
      this.model.isDeleted = false;
    }
  }

  azAzAlpha(event: any) {
    var regexPattern = new RegExp(
      "[A-Za-z~!@#$%\\^&\\*()\\-`.+,/=\\{\\}\\[\\]<>?\\|\\_ ]"
    );
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  azAzAlphaWithException(event: any) {
    var regexPattern = new RegExp("[A-Za-z /&]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  azAzSpace(event: any) {
    var regexPattern = new RegExp("[A-Za-z ]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

  generateQlecHistoryReport(event: any) {
    if (this.cbCustomerHistroy.data.accountId == "" || this.cbCustomerHistroy.data.accountId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-History-Master-Account-PIC-"+this.cbCustomerHistroy.data.accountId+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/72/History-Master-Account-PIC-"
      +
      this.cbCustomerHistroy.data.accountId +
      '/' +
      this.cbCustomerHistroy.data.accountId
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2], '10000') + '/Mreport/runReport/72/History-Master-Account-PIC-' +
    //   this.cbCustomerHistroy.data.accountId +
    //   '/' +
    //   this.cbCustomerHistroy.data.accountId
    // );
  }

  fieldChanged(event, fieldName) {
    if (event.length > 0) {
      this.model[fieldName] = "";
    }
  }
}
