<form class="ui raised segment"  *ngIf="!isExportContent">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form" [formGroup]="receiptForm">
      <br />
      <div class="field">
        <div class="sixteen wide field">
          <fieldset style="border: 1px solid lightgray; height: auto">
            <legend style="font-weight: bold; color: dodgerblue">
              Receipt Information
            </legend>
            <div class="inline fields">
              <div class="field">
                <div class="one field flex-column-5"
                  [class.error]="(model['bContainerOwnership'] == 'SOC' || model['bContainerOwnership'] == 'SOCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')">
                  <label>Payment mode</label>
                  <select name="cars" id="cars" formControlName="paymentMode">
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="t/t">T/T</option>
                  </select>
                </div>
              </div>
        
              <div class="field">
                <div class="one field flex-column-5">
                    <label>Cheque / T/T No:</label>
                    <input maxlength="17" type="text" name="blNumber" placeholder="BL Number" formControlName="cheque" />
                </div>
              </div>
                
              <div class="field">
                <!-- Invoice BL Number -->
                  <div class="one flex-column-5 field">
                      <label>Date:</label>
                      <input maxlength="17" type="date" name="date" placeholder="Date" formControlName="date" />
                  </div>
              </div>

              <div class="field">
                  <div class="one flex-column-5 field">
                    <label>Currency:</label>
                    <div class="inline fields">
                      <div class="field">
                        <div class="ui radio checkbox">
                          <input type="radio" value="SGD" formControlName="currency" />
                          <label>SGD</label>
                        </div>
                      </div>
                    
                      <div class="field">
                        <div class="ui radio checkbox">
                          <input type="radio" value="USD" formControlName="currency" />
                          <label>USD</label>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div class="field">
                <div class="one field flex-column-5"
                  [class.error]="(model['bContainerOwnership'] == 'SOC' || model['bContainerOwnership'] == 'SOCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')">
                  <label>Account:</label>
                  <select name="account" id="acc" formControlName="account">
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="t/t">T/T</option>
                  </select>
                </div>
              </div>
                              
              <div class="field">
                  <div class="one flex-column-5 field">
                      <label>Amount:</label>
                      <input maxlength="17" type="text" name="blNumber" placeholder="Amount" formControlName="amount" />
                  </div>
              </div>

              <div class="field">
                <div class="one flex-column-5 field">
                  <label>Bank charges:</label>
                  <input maxlength="17" type="text" name="bankCharges" placeholder="Bank charges" formControlName="bankCharges" />
                </div>
              </div>


            </div>
          </fieldset>
        </div>
      </div>

      <div class="field">
        <div class="sixteen wide field">
          <fieldset style="border: 1px solid lightgray; height: auto">
            <legend style="font-weight: bold; color: dodgerblue">
              Invoice Information
            </legend>
            <div class="inline fields">
              <div class="field">
                <div class="one flex-column-5 field">
                  <label>Invoice no:</label>
                  <input maxlength="17" type="text" name="blNumber" placeholder="Invoice no" formControlName="invoice" />
                </div>
              </div>
      
              <div class="field">
                <div class="one flex-column-5 field">
                  <label>BL no:</label>
                  <input maxlength="17" type="text" name="blNumber" placeholder="BL Number" formControlName="blNo" />
                </div>
              </div>
      
              <div class="field">
                <div class="one flex-column-5 field">
                  <div class="inline fields flex-column-5" style="align-items: flex-start;">
                    <div class="field">
                      <div class="one field d-flex-vesel-voyage" 
                        [class.error]="(model['bContainerOwnership'] == 'SOC' || model['bContainerOwnership'] == 'SOCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')">
                        <label style="width: 180px;">Vessel name:</label>
                        <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                        <!-- <div class="ui basic red pointing prompt label transition"
                          *ngIf="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                          {{model['error-vesselName']}}
                        </div> -->
                      </div>
                    </div>
                    
                    <div class="field">
                      <div class="one field d-flex-vesel-voyage"
                        [class.error]="(model['bContainerOwnership'] == 'SOC' || model['bContainerOwnership'] == 'SOCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')">
                        <label style="width: 180px;">Voyage no:</label>
                        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                        <!-- <div class="ui basic red pointing prompt label transition"
                          *ngIf="model['error-invoiceVoyage'] != null && model['error-invoiceVoyage'] != ''">
                          {{model['error-invoiceVoyage']}}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      
              <div class="field">
                <!-- Invoice BL Number -->
                <div class="one flex-column-5 field">
                  <label>Customer name:</label>
                  <div class="flex">
                    <input maxlength="17" type="text" name="customerName" placeholder="Customer name" formControlName="customerName" />
                    <button>Search</button>
                  </div>
                </div>
              </div>
            </div>

          </fieldset>
        </div>
      </div>

      <div>
        <div class="field">
          <!-- DN 1st Row -->
          <div class="fields">
            <div class="six wide field">
              <div class="required field" [class.error]="
                    model['error-invoiceNumber'] != null &&
                    model['error-invoiceNumber'] != '' &&
                    model['error-invoiceNumber'] != undefined
                  ">
                <label>Customer name</label>
                <input maxlength="17" type="text" name="creditNoteNumber" placeholder="Customer name" formControlName="customerName2" />
              </div>
            </div>
            <div class="five wide field">
              <div class="field">
                <label>Total Records Selected:</label>
                <!-- <input maxlength="20" type="text" readonly="true" name="cnCreatedBy" formControlName="totalRecord" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="sixteen wide field">
          <fieldset style="border: 1px solid lightgray; height: auto">
            <legend style="font-weight: bold; color: dodgerblue">
              Invoice details
            </legend>
            <ag-grid-angular 
              style="width: 100%; height: 400px" 
              [getRowStyle]="getRowStyle" 
              class="ag-theme-balham"
              [rowData]="rowDataCNDN" 
              [columnDefs]="columnDefsCNDN" 
              [pinnedBottomRowData]="pinnedBottomRowDataCNDN"
              (gridReady)="onGridReadyCNDN($event)">
            </ag-grid-angular>
          </fieldset>
        </div>
      </div>
      <div class="invoice-total">
        <div class="total-left">
          <div class="field">
            <div class="field flex-row-5">
              <label class="title-total">Paid Invoice Total:</label>
              <input type="text" placeholder="0.00">
            </div>
          </div>
          <div class="field">
            <div class="field flex-row-5">
              <label class="title-total">Receipt Total:</label>
              <input type="text" placeholder="0.00">
            </div>
          </div>
          <div class="field">
            <div class="field flex-row-5">
              <label class="title-total">Balance:</label>
              <input type="text" placeholder="0.00">
            </div>
          </div>
        </div>

        <div style="display: flex;align-items: start;margin-left: 15px;gap: 5px">
          <input type="checkbox" style="width: auto;">
          <label>Show reference type</label>
        </div>
      </div>

    </form>
  </div>  <!-- end div container -->
</form>


<ng-container *ngIf="isExportContent">
  <app-transaction-receipt-page-export-coda (closeExportContent)="closeExportContent($event)"></app-transaction-receipt-page-export-coda>
</ng-container>


<footer-plugin [info]="info"></footer-plugin>
