import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuPluginComponent } from "sibego-ui-library";
import {LicenseManager} from "ag-grid-enterprise";

declare var $: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit,AfterViewInit {
  @ViewChild('menuPlugin') menuPlugin:MenuPluginComponent;

  constructor(private router:Router) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //console.log("#3 main")
    if(localStorage.getItem("changeLocation") != null){
      localStorage.removeItem("changeLocation")
      $('#popup').transition('fade');
      setTimeout(function(){
        $('#popup').transition('fade');
      },1000);
      this.menuPlugin.generateMenuStructure()
    }
  }


}
