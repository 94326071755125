export class ContractRate{
    checkEmptyDetailValidator:Boolean = false;
    checkExistingValidator:Boolean = false;

    pfcOfficeCode       :string="";
    pfcRecordId         :string="";
    pfcFreightPartyId      :string="";
    pfcShipperId   :string="";
    pfcPlaceOfReceiptCode :string="";
    pfcLoadPortCode :string="";
    pfcDischargePortCode  :string="";
    pfcPlaceOfDeliveryCode  :string="";
    pfcFinalDestinationCode :string="";
    pfcPortOfTransshipment1Code  :string="";
    pfcPortOfTransshipment2Code  :string="";
    pfcPortOfTransshipment3Code  :string="";
    pfcContainerType   :string="ALL";
    pfcCommodityId  :string="";
    pfcReceiptTypeCode  :string="CY";
    pfcDeliveryTypeCode :string="CY";
    pfcEffectiveFrom  :string="";
    pfcEffectiveTo  :string="";
    pfcContainerOwnership :string="ALL";
    pfcContainerStatus  :string="ALL";
    pfcWeightClass  : string="M";
    pfcUnno :string="";
    pfcImo  :string="";
    pfcFlashPoint :string="";
    pfcShipmentType :string="N";
    pfcVesselId :string="";
    pfcVoyage :string="";
    pfcDetention  :string="N";
    pfcDetentionRequest :number=0;
    pfcDemurrage  :string="N";
    pfcDemurrageRequest :number=0;
    pfcIhc  :string="N";
    pfcDgClass  : string="ALL";
    pfcPrincipalRemarks :string="";
    pfcAgentRemarks :string="";
    pfcCompetitorDataId :string="";
    pfcAppStatus  :string="C";
    pfcAgencyId  :string="";
    pfcUserCreated  :string="";
    pfcDateCreated  :string="";
    pfcUserModified :string="";
    pfcDateModified :string="";
    pfcUserRequested  :string="";
    pfcDateRequested  :string="";
    pfcUserApproved :string="";
    pfcDateApproved :string="";
    pfcIsTransmitted :string="N";
    pfcUserTransmitted: string="";
    pfcDateTransmitted: string="";
    pfcPORTerminalCode  :string="";
    pfcPOLTerminalCode  :string="";
    pfcPODTerminalCode  :string="";
    pfcPODLTerminalCode  :string="";
    pfcPOT1TerminalCode  :string="";
    pfcPOT2TerminalCode  :string="";
    pfcPOT3TerminalCode  :string="";
    pfcFDESTTerminalCode  :string="";
    pfcCustomColumn :string="";
    pfcLocalEta:string="";
    pfcLocalEtd:string="";
    pfcLocalAta:string="";
    pfcLocalAtd:string="";
    pfcWeight:number=0;
    pfcProspectD20:number=0;
    pfcProspectD40:number=0;
    pfcProspectD45:number=0;
    pfcProspectH20:number=0;
    pfcProspectH40:number=0;
    pfcProspectH45:number=0;
    pfcDetails:ContractRateDetail[] = [];
    pfcPolPrefix:string="";
    pfcPodPrefix:string="";
    pfcContainerOwnershipPrefix:string="";
    pfcSerchByRefNo:string="N";
    pfcHeaderKey:string="";
    pfcIsValid:string='N';
    pfcUserInvalid:string='';
    pfcDateInvalid:string='';
    pfcIsDeleted:string='N';
    pfcUserDeleted:string='';
    pfcDateDeleted:string='';
    pfcReasonDeleted:string='';
    pfcIsCommission:string='N';
    pfcAcctPic:string='';

  constructor(){}

}


export class ContractRateDetail{
  pfcDetailSeqNo:number=0;
  pfcDetailStatus:string="C";
  pfcDetailContainerType:string="STD";
  pfcDetailChargeCode:string="";
  pfcDetailChargeName:string="";
  pfcDetailCurrency:string="";
  pfcDetailPC:string="";
  pfcDetailPlaceOfPayment:string="";
  pfcDetailPlaceOfPaymentName:string="";
  pfcDetailPayer:string="";
  pfcDetailPayerName:string="";
  pfcDetailD20:number=0;
  pfcDetailD40:number=0;
  pfcDetailD45:number=0;
  pfcDetailH20:number=0;
  pfcDetailH40:number=0;
  pfcDetailH45:number=0;
  pfcDetailM3:number=0;
  pfcDetailKT:number=0;
  pfcDetailRT:number=0;
  pfcDetailMinCharge:number=0;
  pfcDetailKillSlot:number=0;
  pfcDetailFAIGroup:string="N";
  pfcDetailOogOH:number=0;
  pfcDetailOogOWL:number=0;
  pfcDetailOogOWR:number=0;
  pfcDetailOogOLF:number=0;
  pfcDetailOogOLB:number=0;
  pfcDetailOogDoorOpen:string="N";
  pfcDetailOogUcH:number=0;
  pfcDetailOogUcL:number=0;
  pfcDetailOogUcW:number=0;
  pfcDetailTotalChargeValue:number=0;
  pfcDetailCopyTab:string="N";
  pfcDetailPCTab:string="";

  constructor(){}
}

