import {Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {Booking} from '../shared/index';
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  TextareaPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService
} from 'sibego-ui-library';
import {MSailingSchedule, MSailingScheduleDetail, AllocationModel} from '../shared/index';
import {$NBSP} from "codelyzer/angular/styles/chars";
import {
  ContainerMaintenance, Description, FreightCharge, MarkNo,
  MasterBlHeader, PC
} from "../shared/models/transactionMaintenance";



declare var $: any;

export class Param {

  parameterShipper: string = '';
  parameterConsignee: string = '';
  parameterNotify: string = '';
  blType: string = '';
  ShipperDraftBl: string = '';
  OBLS: string = '';
  freightCovered: string = '';
  PlaseOfIssue: string = '';
  DateOfIssue: string = '';
  bDescNettW: number=0;
  receiptAndDeliveryTypeCode: string = '';
  locationCode: string = '';
  locationName: string = '';
  blServiceId: string = '';
  serviceCode: string = '';
  blPrincipalId: string = '';
  principalCode: string = '';
  blContainerPackageType:string='';

  //-------------------------------------------------------------------------------------------------------------------
  no:number=0;
  bOceanVesselId: string = '';
  bOceanVesselCode: string = '';
  bOceanVesselName: string = '';
  bOceanVesselVoyage: string = '';
  bOceanVesselBound: string = '';
  // ==================================================================================================================
  OceanVesselId: string = '';
  OceanVesselCode: string = '';
  OceanVesselName: string = '';
  OceanVesselVoyage: string = '';
  OceanVesselBound: string = '';
  // ==================================================================================================================
  NextVesselId: string = '';
  NextVesselCode: string = '';
  NextVesselName: string = '';
  NextVesselVoyage: string = '';
  NextVesselBound: string = '';
  // ==================================================================================================================

  FinalDestination: string = '';
  FinalDestinationPrint: string = '';
  bOfficeCode: string = '';
  bOceanShipperId: string = '';
  bOceanShipperName: string = '';
  bOceanShipperAddress: string = '';
  bOceanReciptType: string = '';
  bOceanReciptPlace: string = '';
  bOceanReciptPlacePrint: string = '';
  bOceanReciptPlaceContainer: string = '';
  bOceanDeliveryType: string = '';
  bOceanLoadPort: string = '';
  bOceanLoadPortPrint: string = '';
  bOceanDischargePort: string = '';
  bOceanDischargePortPrint: string = '';
  bOceanConsigneeToOrder: string = '';
  ShipperBlNo: string = "";
  BlNo: string = '';
  blNo: string = '';
  officeCode:string='';
  BKGNo: string = "";
  customerId: string = '';
  customerName: string = '';
  blShipperId: string = '';
  blShipperName: string = '';
  blShipperAddress: string = '';
  blConsigneeId: string = '';
  blConsigneeName: string = '';
  blConsigneeAddress: string = '';
  blNotifyPartyId: string = '';
  blNotifyPartyName: string = '';
  blNotifyPartyAddress: string = '';
  blContainerSeq: number = 0;
  bContainerNo: string = '';
  bContainerSeal1: string = '';
  bContainerSeal2: string = '';
  bContainerSeal3: string = '';
  bContainerOwnerShip: string = '';
  bContainerPackageType: string = '';
  bContainerGrossWeight: number = 0;
  bContainerGrossMeas: number = 0;
  bContainerQty: number = 0;
  blContainerType: string = '';
  blContainerSize: string = '';
  blContainerHeight: string = '';
  CheckIrePermit: string = "";
  bContainerReceiptType: string = "";
  bContainerDeliveryType: string = "";
  bMarkSeq: number = 0;
  bMarkAndNo: string = "";
  POT1: string = "";
  POT2: string = "";
  POT3: string = "";
  blMarkNoDesc: string = "";
  bDescSeq: string = "";
  bDescSeq2: number = 0;
  // bDescSeqC: ;
  bDescClause: string = "";
  bDescAgentAdd: string = "";
  bDescInnerQty: number=0;
  bDescOuterQty: number=0;
  bDescGrossW: number=0;
  bDesNettW: string = "";
  bDescGrossMeas: number=0;
  bDescNettMeas: number=0;
  blOuterPackageType:string='';
  blPsaPackageType:string='';
  blReceiptTypeDesc:string='';
  blDeliveryTypeDesc:string='';

  bNettSeq: string = "";
  QuantityNett: string = "";
  RateNett: string = "";

  blInnerPackageType:string='';


  lockNettRate: string = "";

  BKGRef: string = "";
  ShipperEDI: string = "";
  Shipper: string = "";
  direction: string = '';
  selected: string = '';
  ReciptType: string = '';
  DeliveryType: String = '';

  ReciptPlace: string = '';

  LoadPort: string = '';
  DischargePort: string = '';
  PODelivery: string = '';
  PODeliveryPrint: string = '';
  OPCode: string = "";
  DemurrageCode: string = "";
  DetentionCode: string = "";
  PartyNotify: string = "";
  PartyConsignee: string = "";

  //container
  Seq: string = "";
  ContainerNo: string = "";
  SealNo1: string = "";
  SealNo2: string = "";
  SealNo3ContType: string = "";

  PackageType: string = "";

  SeqMark: string = "";

  ReceiptTypeContainer: string = "";
  DeliveryTypeContainer: string = "";
  SkipCheckContainerNo: string = "";
  SkipCheck: string = "";
  // skipCheckPrintPreVessel: string = "";
  skipCheckNextVessel: string = "";
  skipCheckOceanVesselNext: string = "";
  skipCheckNextVesselMother: string = "";
  EmptyContainer: string = "";
  DryContainer: string = "";
  ShortShipmentContainer: string = "";
  TxtTotalNo: string = "";
  TxtAgentName: string = "";
  DeclareValue: string = "";
  SeqDescription: string = "";
  ClauseDescription: string = "";
  // skipCheckPrintOceanVessel: string = "";
  SkipPrintAgentInfo: string = "";

  CheckConsigneePermit: string = "";
  ShortShipment: string = "";
  FreightAsArranged: string = "";
  PrintLoadingVessel: string = "";
  PrintShippeeOnBoard: string = "";
  NettChargeLock: string = "";

  containerurl: string = "";
  loading: Boolean = false;
  allocationValidator: Boolean = false;
  constructor() {

  }



}

@Component({
  selector: 'app-transaction-bl-maintenance-page',
  templateUrl: './transaction-bl-maintenance-page.component.html',
  styleUrls: ['./transaction-bl-maintenance-page.component.css']
})

export class TransactionBLMaintenancePageComponent extends Validator implements OnInit, AfterViewInit {
 /* get lock(): boolean {
    return this._lock;
  }
  set lock(value: boolean) {
    this._lock = value;
  }
  get lockM():boolean{
    return this._lock;
  }
  set lockM(value:boolean){
    this._lock=value;
  }
  get lockD():boolean{
    return this._lock;
  }
  set lockD(value:boolean){
    this._lock=value;
  }
  get lockF():boolean{
    return this._lock;
  }
  set lockF(value:boolean){
    this._lock=value;
  }
  get model():ContainerMaintenance{
    return this._model;
  }
  get modeMl():MasterBlHeader{
    return this._modelM;
  }*/

  disableToolbarButtons = "";
  invisibleToolbarButtons = "";
  disableButtons="";
  dialog: any;
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbOceanVessel') cbOceanVessel: ComboPluginComponent;
  @ViewChild('cbVesselNext') cbVesselNext: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbOceanVoyage') cbOceanVoyage: ComboPluginComponent;
  @ViewChild('cbVoyageNext') cbVoyageNext: ComboPluginComponent;
  @ViewChild('cbShipper') cbShipper: ComboPluginComponent;
  @ViewChild('cbConsignee') cbConsignee: ComboPluginComponent;

  @ViewChild('cbNotify') cbNotify: ComboPluginComponent;
  @ViewChild('grid') grid: GridPluginComponent;
  @ViewChild('gridcontainer') gridcontainer: GridPluginComponent;
  @ViewChild('griddescription') griddescription: GridPluginComponent;
  @ViewChild('gridnettcharge') gridnettcharge: GridPluginComponent;
  @ViewChild('gridgrosscharge') gridgrosscharge: GridPluginComponent;
  @ViewChild('gridnettcharge2') gridnettcharge2: GridPluginComponent;
  @ViewChild('gridmarkno') gridmarkno: GridPluginComponent;
  @ViewChild('griddesc2') griddesc2: GridPluginComponent;
  @ViewChild('Retrieve') Retrieve: GridPluginComponent;
  @ViewChild('txtConsignee') txtConsignee: TextareaPluginComponent;
  @ViewChild('txtShipper') txtShipper: TextareaPluginComponent;
  @ViewChild('txtNotify') txtNotify: TextareaPluginComponent;
  @ViewChild('txtMarkNo') txtMarkNo: TextareaPluginComponent;
  @ViewChild('txtDescription') txtDescription: TextareaPluginComponent;
  @ViewChild('cbReceiptType') cbReceiptType: ComboPluginComponent;

  @ViewChild('cbReciptTypeContainer') cbReciptTypeContainer: ComboPluginComponent;
  @ViewChild('cbReceiptPlace') cbReceiptPlace: ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort: ComboPluginComponent;
  @ViewChild('cbPODelivery') cbPODelivery: ComboPluginComponent;
  @ViewChild('cbFinalDestination') cbFinalDestination: ComboPluginComponent;
  @ViewChild('cbPOT1') cbPOT1: ComboPluginComponent;
  @ViewChild('cbPOT2') cbPOT2: ComboPluginComponent;
  @ViewChild('cbPOT3') cbPOT3: ComboPluginComponent;
  @ViewChild('cbService') cbService: ComboPluginComponent;
  @ViewChild('cbDeliveryType') cbDeliveryType: ComboPluginComponent;
  @ViewChild('cbDeliveryTypeContainer') cbDeliveryContainer: ComboPluginComponent;
  @ViewChild('cbPrincipal') cbPrincipal: ComboPluginComponent;
  @ViewChild('cbbContainerPackageType') cbbContainerPackageType: ComboPluginComponent;
  @ViewChild('cbTypeContainer') cbTypeContainer: ComboPluginComponent;
  @ViewChild('cbSizeContainer') cbSizeContainer: ComboPluginComponent;
  @ViewChild('cbHeightContainer') cbHeightContainer: ComboPluginComponent;
  @ViewChild('cbAgentsDescription') cbAgentsDescription: ComboPluginComponent;
  @ViewChild('cbAgentAdd') cbAgentAdd: ComboPluginComponent;
  @ViewChild('cbInnerPackageType') cbInnerPackageType: ComboPluginComponent;
  @ViewChild('cbOuterPackageType') cbOuterPackageType: ComboPluginComponent;
  @ViewChild('cbPSA') cbPSA: ComboPluginComponent;
  @ViewChild('cbCommodityType') cbCommodityType: ComboPluginComponent;
  @ViewChild('cbNettChargeType') cbNettCharge: ComboPluginComponent;
  @ViewChild('cbPer') cbPer: ComboPluginComponent;
  @ViewChild('cbCurrency') cbCurrency: ComboPluginComponent;
  @ViewChild('cbPC') cbPC:ComboPluginComponent;
  @ViewChild('cbPayer') cbPayer:ComboPluginComponent;
// ==============================================================
  @ViewChild('cbPrepaid1') cbPrepaid1: ComboPluginComponent;
  @ViewChild('cbPrepaid2') cbPrepaid2: ComboPluginComponent;
  @ViewChild('cbPrepaid3') cbPrepaid3: ComboPluginComponent;

  @ViewChild('cbGrossPrepaid1') cbGrossPrepaid1: ComboPluginComponent;
  @ViewChild('cbGrossPrepaid2') cbGrossPrepaid2: ComboPluginComponent;
  @ViewChild('cbGrossPrepaid3') cbGrossPrepaid3: ComboPluginComponent;

  @ViewChild('cbPayable1') cbPayable1: ComboPluginComponent;
  @ViewChild('cbPayable2') cbPayable2: ComboPluginComponent;
  @ViewChild('cbPayable3') cbPayable3: ComboPluginComponent;
  @ViewChild('cbNettPlaceOfIssue') cbNettPlaceOfIssue: ComboPluginComponent;

  @ViewChild('cbGrossPayable1') cbGrossPayable1: ComboPluginComponent;
  @ViewChild('cbGrossPayable2') cbGrossPayable2: ComboPluginComponent;
  @ViewChild('cbGrossPayable3') cbGrossPayable3: ComboPluginComponent;

  @ViewChild('cbBillingParty1') cbBillingParty1: ComboPluginComponent;
  @ViewChild('cbBillingParty2') cbBillingParty2: ComboPluginComponent;
  @ViewChild('cbBillingParty3') cbBillingParty3: ComboPluginComponent;
  @ViewChild('cbReceiptTypeDesc') cbReceiptTypeDesc: ComboPluginComponent;
  @ViewChild('cbDeliveryTypeDesc') cbDeliveryTypeDesc: ComboPluginComponent;

  @ViewChild('cbGrossBillingParty1') cbGrossBillingParty1: ComboPluginComponent;
  @ViewChild('cbGrossBillingParty2') cbGrossBillingParty2: ComboPluginComponent;
  @ViewChild('cbGrossBillingParty3') cbGrossBillingParty3: ComboPluginComponent;

// ==============================================================

  @ViewChild('cbbContainerDeliveryType') cbbContainerDeliveryType: ComboPluginComponent;
  @ViewChild('cbbContainerReceiptType') cbbContainerReceiptType: ComboPluginComponent;
  @ViewChild('cbbContainerPackageType') cbbContainerPackageyType: ComboPluginComponent;

// ==================================================================

  /* Parameter modeForm (Default : false) */
  form = '';

  private _lock = true;
  modeForm = false;
  modeFormDesc= false;
  modeFormNett=false;
  modeToolbar = false;
  retLock = false;
  info = '';
  /* Parameter settings */
  settingVessel;
  settingVesselPre;
  settingOceanVessel;
  settingVesselNext;
  settingVoyage;
  settingVoyagePre;
  settingOceanVoyage;
  settingVoyageNext;
  settingToolbar;
  settingShipper;
  settingConsignee;
  settingNotify;
  settingTxtShipper;
  settingTxtConsignee;
  settingTxtNotify;
  settingTxtMarkNo;
  settingTxtDescription;
  settingDeliveryType;
  settingReceiptType;
  settingReceiptPlace;
  settingLoadPort;
  settingDischargePort;
  settingPODelivery;
  settingFinalDestination;
  settingReceiptTypeDesc;
  settingDeliveryTypeDesc;
  settingPOT1;
  settingPOT2;
  settingPOT3;
  settingService;
  settingPrincipal;
  //container
  settingbContainerPackageType;
  settingGrid;
  settingGridContainer;
  settingGridMarkNo;
  settingGridDescription;
  settingGridClauseDescription;
  settingGridNettCharge;
  settingGridGrossCharge;
  settingGridNettCharge2;
  descSettingGrid;
  descMiniSettingGrid;
  settingCossn;
  settingPC;
  settingPayer;
  settingTypeContainer;
  settingSizeContainer;
  settingHeightContainer;
  settingAgentsDescription;
  settingAgentAdd;
  settingInnerPackageType;
  settingOuterPackageType;
  settingPSA;
  settingbContainerDeliveryType;
  settingbContainerReceiptType;
  settingbContainerType;
  settingbContainerSize;
  settingbContainerHeight;
  settingCommodityType;
  settingNettCharge;
  settingNettPlaceOfIssue;
  settingPer;
  settingCurrency;

  //--------------Nett Charge-----------------
  settingPrepaid1;
  settingPrepaid2;
  settingPrepaid3;
  settingGrossPrepaid1;
  settingGrossPrepaid2;
  settingGrossPrepaid3;
  settingPayable1;
  settingPayable2;
  settingPayable3;
  settingGrossPayable1;
  settingGrossPayable2;
  settingGrossPayable3;
  settingBillingParty1;
  settingBillingParty2;
  settingBillingParty3;
  settingGrossBillingParty1;
  settingGrossBillingParty2;
  settingGrossBillingParty3;

  //-------------------------------------------

  settingRetrieve;

  //===========================================

  param = new Param();
  isLock1: Boolean = false;
  isLock2: Boolean = false;
  isCom: Boolean = false;
  isError: Boolean = false;
  tsBooking: string = 'All';
  //bolean
  isLoad: boolean = false;
  isNormalRate:boolean=false;
  isSpecialRate:boolean=false;
  isBLRate:boolean=false;
  //tab 2
  skipContainerNo: boolean = false;
  skipCheckPre: boolean = false;
  skipCheckPrintOceanVessel: boolean = false;
  skipCheckPrintPreVessel:boolean=false;
  skipCheckPrintNextVessel:boolean=false;
  SkipCheckContainerNo: boolean = false;
  skipCheckNettManifestedFreight:boolean=false;
  DryContainer: boolean = false;
  EmptyContainer: boolean = false;
  ShortShipmentContainer: boolean = false;
  SkipPrintAgentInfo: boolean = false;
  SkipCheckIrePermit: boolean = false;
  CheckConsigneePermit: boolean = false;
  lock:boolean=false;
  lockM:boolean=false;
  lockD:boolean=false;
  lockF:boolean=false;
  lockCon:boolean=false;
  FreightAsArranged: boolean = false;
  PrintLoadingVessel: boolean = false;
  PrintShippeeOnBoard: boolean = false;
  printNettCharge:boolean=false;
  NettChargeLock: boolean = false;
  lockNettRate: boolean = false;
  isMemo: boolean = false;
  isSW: boolean = false;
  isCRB: boolean = false;
  isNA: boolean = false;
  isRFS: boolean = false;
  isOIS: boolean = false;
  isIRE: boolean = false;
  COC: boolean = false;
  COCTS: boolean = false;
  SLOT: boolean = false;
  SOC: boolean = false;
  SOCTS: boolean = false;
  skipCheck:boolean=false;
  skipCheckOceanVessel:boolean=false;
  skipNextCheckVessel:boolean=false;
  freighttypec:boolean=false;
  // lockM:boolean=false;




  extVesselCapacity: number = 0;
  srcVesselCapacity: number = 0;
  localCSSNCode:string='';
  localCSSNName:string='';
  //model = new MSailingSchedule;
  modelM = new MasterBlHeader;
  modelD = new Description;
  modelF = new FreightCharge;
  modelMark = new MarkNo;
  modelP = new PC;
  private _model = new ContainerMaintenance();
  private _modelM = new MasterBlHeader();
  modelDetail =  new ContainerMaintenance();
  map = new Map();
  NCT_ContainerTypeList:  ContainerMaintenance[] =[];
  modelDetails = new AllocationModel;
  //modelCopy = new MSailingSchedule;

  lockNewContainer:boolean=false;
  lockSaveContainer:boolean=false;
  lockUpdateContainer:boolean=false;
  lockDeleteContainer:boolean=false;
  lockCancelContainer:boolean=false;

  lockNewMarkNo:boolean=false;
  lockSaveMarkNo:boolean=false;
  lockUpdateMarkNo:boolean=false;
  lockCancelMarkNo:boolean=false;
  lockDeleteMarkNo:boolean=false;

  lockSaveDesc:boolean=false;
  lockUpdateDesc:boolean=false;
  lockCancelDesc:boolean=false;
  lockDeleteDesc:boolean=false;

  lockNewFc:boolean=false;
  lockSaveFc:boolean=false;
  lockUpdateFc:boolean=false;
  lockDeleteFc:boolean=false;
  lockCancelFc:boolean=false;

  allocationValidator: Boolean = false;
  loading: Boolean = false;

  validatorRules = {


  }
  setValidatorContainer(){
    this.validatorRules = {
      ContainerNo: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Container number.'
          }
        ]
      },
      SealNo1: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Seal No1.'
          }
        ]
      },
      Size:  {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Seal Size.'
          }
        ]
      },

    }
    }



  setValidatorDesc(){
    this.validatorRules = {
      blDescOuterQty: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Outer.'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input Outer.'
          }
        ]
      },
      blDescInnerQty: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Inner Qty.'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input  Inner.'
          }
        ]
      },
      blDescGrossW: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Gross Weight .'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input  Gros.'
          }
        ]
      },
      blDescGrossMeas: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Gross Meas.'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input  Meas.'
          }
        ]
      },
      blDescNettW: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Gross Meas.'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input  Meas.'
          }
        ]
      },
      blDescNettMeas: {
        rules: [
          {
            type: 'positiveInteger',
            prompt: 'Please input Gross Meas.'
          },
          {
            type  : 'emptyZero',
            prompt : 'Please input  Meas.'
          }
        ]
      },
    }

  }


setValidatorMarkNo(){
    this.validatorRules = {
           bMarkAndNo: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input mark.'
          }
        ]
      },
   }
  }



  setValidatorNetCharge(){
    this.validatorRules = {
      blFcPlaceOfPaymentCode: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Place Payment .'
          }
        ]
      },
      blFchargeCode: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input charge Code.'
          }
        ]
      },
      blFcCurrency: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Currency.'
          }
        ]
      },
      blFcPer: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Per.'
          }
        ]
      },
      blFcPayer: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input Place Per.'
          }
        ]
      },
    }
  }


 setValidatorMaintenance(){
   this.validatorRules = {
     blShipperId: {
       rules: [
         {
           type: 'empty',
           prompt: 'Please input id shipper.'
         }
       ]
     },
     blConsigneeId: {
       rules: [
         {
           type: 'empty',
           prompt: 'Please input Consignee.'
         }
       ]
     },

     blNotifyPartyId: {
       rules: [
         {
           type: 'empty',
           prompt: 'Please input Notify Party.'
         }
       ]
     },


     blPlaceOfReceipt: {
       rules: [{
         type: 'empty',
         prompt: 'Please input place of receipt.'
       }]
     },


     blPrincipalId: {
       rules: [{
         type: 'empty',
         prompt: 'Please principal.'
       }]
     },
     POT1: {
       rules: [{
         type: 'empty',
         prompt: 'Please input POT1.'
       }]
     },
     blFinalDestination: {
       rules: [{
         type: 'empty',
         prompt: 'Please input Final Destination.'
       }]
     },
     blPlaceOfDelivery: {
       rules: [{
         type: 'empty',
         prompt: 'Please input delivery.'
       }]
     },
     blDischargePort: {
       rules: [{
         type: 'empty',
         prompt: 'Please input discharge.'
       }]
     },
     blLoadPort: {
       rules: [{
         type: 'empty',
         prompt: 'Please input load port.'
       }]
     },
     blServiceId: {
       rules: [{
         type: 'empty',
         prompt: 'Please input service.'
       }]
     },
     blOceanVesselVoyage: {
       rules: [{
         type: 'empty',
         prompt: 'Please input vessel voyage.'
       }]
     },

     blOceanVesselId: {
       rules: [{
         type: 'empty',
         prompt: 'Please input vessel ID.'
       }]
     },
     portofLoadingCode: {
       rules: [{
         type: 'empty',
         prompt: 'Please input port.'
       }]
     },

     blDescNettMeas: {
       rules: [{
         type: 'empty',
         prompt: 'Please input Nett Meas.'
       }]
     },
    }
  }

  setValidatorFreight(){
   this.validatorRules= {
     blPayer1Code: {
       rules: [{
         type: 'empty',
         prompt: 'Please input Billing Party 1.'
       }]
     },

     blIsPayableAt1Code: {
       rules: [{
         type: 'empty',
         prompt: 'Please input Payable.'
       }]
     },
     blIsPrepaidAt1Code: {
       rules: [{
         type: 'empty',
         prompt: 'Please input Prepaid.'
       }]
     },
   }
  }


  constructor(private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Partial Save', event: 'PartialSave', icon: 'save'},
        {name: 'Save', event: 'Save', icon: 'checkmark'},
        {name: 'Print Invoice', event: 'PrintInvoice', icon: 'file outline'},
        {name: 'Cancel', event: 'Cancel', icon: 'cancel'},
        {name: 'Close', event: 'Close', icon: 'power'},
        {name: 'Delete', event: 'Delete', icon: 'minus circle'},
        {name: 'Sync', event: 'Sync', icon: 'refresh'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL MAINTENANCE'
    }

    this.settingVessel = {
      id: 'cbVessel',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Vessel',
      title: 'vesselName',
      description: '',
      columns: [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }




    this.settingVesselNext = {
      id: 'cbVesselNext',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Vessel',
      title: 'vesselName',
      description: '',
      columns: [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingOceanVessel = {
      id: 'cbOceanVessel',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Vessel',
      title: 'vesselName',
      description: '',
      columns: [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id: 'cbVoyage',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 50},
        {header: 'Service', field: 'serviceCode', width: 100},
      ]
    }
    this.settingVoyagePre = {
      id: 'cbVoyagePre',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 50},
        {header: 'Service', field: 'serviceCode', width: 100},
      ]
    }

    this.settingOceanVoyage = {
      id: 'cbOceanVoyage',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 50},
        {header: 'Service', field: 'serviceCode', width: 100},
      ]
    }

    this.settingVoyageNext = {
      id: 'cbVoyageNext',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 50},
        {header: 'Service', field: 'serviceCode', width: 100},
      ]
    }

    this.settingTxtShipper = {
      id: 'txtShipper',
      name: 'blShipperAddress',

      title: 'blShipperAddress',
      placeholder: 'Shipper',
      cols: 30,
      rows: 10,
      maxlength: 255,
    }

    this.settingTxtConsignee = {
      id: 'txtConsignee',
      name: 'blConsigneeAddress',
      placeholder: 'Consignee',
      cols: 30,
      rows: 10,
      maxlength: 255,
    }

    this.settingTxtNotify = {
      id: 'txtNotify',
      name: 'blNotifyPartyAddress',
      placeholder: 'Notify',
      cols: 30,
      rows: 10,
      maxlength: 255,
    }

    this.settingTxtMarkNo = {
      id: 'txtMarkNo',
      name: 'blMarkNoDesc',
      placeholder: '',
      cols: 30,
      rows: 10,
      maxlength: 255
    }
    this.settingTxtDescription = {
      id: 'txtdescription',
      name: 'blDescription',
      placeholder: '',
      cols: 30,
      rows: 10,
      maxlength: 255
    }


    this.settingShipper = {
      id: 'cbShipper',
      type: 'search enter', //  GetShipper/{valid}/{search}/{currentPage}/{page}/{direction}/{columnSort}
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 2,
      template: 'grid', // default
      placeholder: ' SHIPPER ',

      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 300, type: 'string'},

        {header: 'Customer Code', field: 'customerCode', width: 300, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 300, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    };

    this.settingConsignee = {
      id: 'cbConsignee',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerConsignee/{query}/1/10', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 1,
      template: 'grid',
      placeholder: ' CONSIGNEE ',


      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 300, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 300, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 300, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    };

    this.settingNotify = {
      id: 'cbNotify',
      type: 'search enter', // search | select | select input

      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerNotifyParty/{query}/1/10',
      maxChars: 1,
      template: 'grid', // default
      placeholder: ' NOTIFY ',

      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 300, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 300, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 300, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    };
    this.settingReceiptType = {
      id: 'cbReciptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/*/*/{query}/1/5/*/*', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'RECEIPT TYPE',
      title: 'receiptAndDeliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };
    this.settingReceiptTypeDesc = {
      id: 'cbReceiptTypeDesc',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'RECEIPT TYPE',
      title: 'receiptAndDeliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };
    this.settingDeliveryTypeDesc = {
      id: 'cbDeliveryTypeDesc',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'RECEIPT TYPE',
      title: 'receiptAndDeliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingbContainerPackageType = {
      id: 'cbbContainerPackageType',
      type: 'search enter', // search | select | select input
      url: '', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'PACKAGE TYPE',
      title: 'blContainerPackageTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Customer Name', field: 'blContainerPackageTypeCode', width: 300, type: 'string'},
      ]
    };

    this.settingbContainerReceiptType = {
      id: 'cbbContainerReceiptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/*/*/{query}/1/5/*/*', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'RECEIPT TYPE',
      title: 'receiptAndDeliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingbContainerDeliveryType = {
      id: 'cbbContainerDeliveryType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'DELIVERY TYPE',
      title: 'receiptAndDeliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingReceiptPlace = {
      id: 'cbReciptPlace',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Place of Receipt',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingLoadPort = {
      id: 'cbLoadPort',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Load',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}
      ]
    };

    this.settingDischargePort = {
      id: 'cbDischargePort',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Discharge',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    };

    this.settingPODelivery = {
      id: 'cbPODelivery',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Place of Delivery',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [

        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    };

    this.settingFinalDestination = {
      id: 'finalDestination',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Final Destination',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Id', field: 'locationId', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    };

    this.settingPOT1 = {
      id: 'cbPOT1',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Transshipment',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Id', field: 'locationId', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}
      ]
    };

    this.settingPOT2 = {
      id: 'cbPOT2',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/5/ASC/locationName',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Transshipment',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Id', field: 'locationId', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}
      ]
    };

    this.settingPOT3 = {
      id: 'cbPOT3',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/5/ASC/locationName',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Transshipment',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Id', field: 'locationId', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}
      ]
    };

    this.settingService = {

      id: 'cbService',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral/*/*/{query}/1/100/*/*', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',

      title: 'serviceName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Service Name', field: 'serviceName', width: 200},
        {header: 'Service Code', field: 'serviceCode', width: 100},
        {header: 'Psa Service Code', field: 'psaServiceCode', width: 100},
        {header: 'Seq', field: 'serviceSequence', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Is Valid?', field: 'isValid', width: 125},
      ]
    };

    this.settingDeliveryType = {
      id: 'cbDeliveryType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/*/*/{query}/1/5/*/*',
      maxChars: 1,
      template: 'grid', // default
      placeholder: 'CY',
      title: 'receiptAndDeliveryTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Delivery Id', field: 'receiptAndDeliveryTypeId', width: 100},
        {header: 'Delivery Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Delivery Type Code', field: 'receiptAndDeliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    };

    this.settingInnerPackageType = {
      id: 'cbInnerPackageType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'receiptAndDeliveryTypeName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingOuterPackageType = {
      id: 'cbOuterPackageType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'receiptAndDeliveryTypeName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPSA = {
      id: 'cbPSA',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/*/*/{query}/1/5/*/*',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'receiptAndDeliveryTypeName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPrincipal = {
      id: 'cbPrincipal',
      type: 'search enter', // search | select | select input //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      url: this.configService.config.BASE_API.toString() + '/MasterPrincipals/findGeneralHistory/{query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',

      title: 'principalName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Principal Id', field: 'principalId', width: 50},
        {header: 'Principal Name', field: 'principalName', width: 50, type: 'string'},
        {header: 'Principal Code', field: 'principalCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    };


    this.settingNettCharge = {
      id: 'cbNettCharge',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findGeneralHistory/{query}', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'chargeName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Charge Id', field: 'chargeId', width:50},
        {header: 'Charge Name', field: 'chargeName', width: 50},
        {header: 'Charge Code', field: 'chargeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    };
    this.settingNettPlaceOfIssue = {
      id: 'cbNettPlaceOfIssue',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 2,
      template: 'grid', // default
      placeholder: '',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    };

    this.settingPer = {
      id: 'cbPer',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterPerCodes/findGeneralHistory/{query}', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'perName',
      description: '',
      isMark: true,
      columns: [
        {header: 'PerCode Id', field: 'perId', width:50},
        {header: 'Per Name', field: 'perName', width: 50},
        {header: 'Per Code', field: 'perCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    };

    this.settingCurrency = {
      id: 'cbCurrency',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralHistory/{query}', //this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '',
      title: 'currencyCode',
      description: '',
      isMark: true,
      columns: [
        {header: 'Currency Id', field: 'currencyId', width:50},
        {header: 'Currency Name', field: 'currencyName', width: 50},
        {header: 'Currency Code', field: 'currencyCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    };

    // ==========================================================================

    this.settingPrepaid1 = {
      id: 'cbPrepaid1',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }


    this.settingPrepaid2 = {
      id: 'cbPrepaid2',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingPrepaid3 = {
      id: 'cbPrepaid3',
      type: 'search enter', // search | select | select input
      url:  this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: '',
      title: 'locationName',
      description: '',
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingGrossPrepaid1 = {
      id: 'cbGrossPrepaid1',
      type: 'search enter', // search | select | select input
      url:  this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: '',
      title: 'locationName',
      description: '',
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }
    this.settingGrossPrepaid2 = {
      id: 'cbGrossPrepaid2',
      type: 'search enter', // search | select | select input
      url:  this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'locationName',
      description: '',
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }
    this.settingGrossPrepaid3 = {
      id: 'cbGrossPrepaid3',
      type: 'search enter', // search | select | select input
      url:  this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'locationName',
      description: '',
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingPayable1 = {
      id: 'cbPayable1',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingPayable2 = {
      id: 'cbPayable2',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingPayable3 = {
      id: 'cbPayable3',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}',
      maxChars: 3,
      template: 'grid', // default
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }
    this.settingGrossPayable1 = {
      id: 'cbGrossPayable1',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Payable', field: 'Payable', width: 100},
      ]
    }
    this.settingGrossPayable2 = {
      id: 'cbGrossPayable2',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Payable', field: 'Payable', width: 100},
      ]
    }
    this.settingGrossPayable3 = {
      id: 'cbGrossPayable3',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'voyage',
      description: '',
      columns: [
        {header: 'Payable', field: 'Payable', width: 100},
      ]
    }

    this.settingBillingParty1 = {
      id: 'cbBillingParty1',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 2,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }

    this.settingBillingParty2 = {
      id: 'cbBillingParty2',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 2,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }

    this.settingBillingParty3 = {
      id: 'cbBillingParty3',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      maxChars: 2,
      template: 'grid', // default
      placeholder: '',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }
    this.settingGrossBillingParty1 = {
      id: 'cbGrossBillingParty1',
      type: 'search enter', // search | select | select input
      url:'',// this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10'
      maxChars: 2,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }
    this.settingGrossBillingParty2 = {
      id: 'cbGrossBillingParty2',
      type: 'search enter', // search | select | select input
      url: '',//this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10'
      maxChars: 2,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }
    this.settingGrossBillingParty3 = {
      id: 'cbGrossBillingParty3',
      type: 'search enter', // search | select | select input
      url: '',//this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10'
      maxChars: 2,
      template: 'grid', // default
      placeholder: '-- --',
      title: 'customerName',
      description: '',
      columns: [
        {header: 'ID Customer', field: 'customerId', width: 100, type: 'string'},
        {header: 'Customer Code', field: 'customerCode', width: 100, type: 'string'},
        {header: 'Customer Name', field: 'customerName', width: 100, type: 'string'},
        {header: 'is Valid', field: 'isValid', width: 100, type: 'string'},
        {header: 'is Nvocc', field: 'isNvocc', width: 100, type: 'string'},
      ]
    }

    // ==========================================================================

    this.settingCossn = {
      id: 'cbCossn',
      type: 'select',
      url: 'assets/json/CSSN.json',
      placeholder: ' ',
      code: 'localCSSNCode',
      title: 'localCSSNName'

    }
    /*this.settingPC = {
      id: 'cbPC',
      type: 'select',
      url: '',
      placeholder: ' ',
      code: 'localCSSNCode',
      title: 'localCSSNCode',
      columns: [
        {header: 'localCSSNCode', field: 'localCSSNCode', width: 100, type: 'string'},
        {header: 'localCSSNCode', field: 'localCSSNName', width: 100, type: 'string'},

      ]
    }*/

    this.settingTypeContainer = {
      id: 'cbTypeContainer',
      type: 'select',
      url: 'assets/json/bContainerType.json',
      placeholder: 'TYPE',
      code: 'localCSSNCode',
      title: 'localCSSNCode'
    }

    this.settingSizeContainer = {
      id: 'cbSizeContainer',
      type: 'select',
      url: 'assets/json/bContainerSize.json',
      placeholder: 'SIZE',
      code: 'localCSSNCode',
      title: 'localCSSNCode'
    }

    this.settingHeightContainer = {
      id: 'cbHeightContainer',
      type: 'select',
      url: 'assets/json/bContainerHeight.json',
      placeholder: 'HEIGHT',
      code: 'localCSSNCode',
      title: 'localCSSNCode'
    }

    this.settingCommodityType = {
      id: 'cbCommodityType',
      type: 'select',
      url: 'assets/json/bCommodityType.json',
      placeholder: 'COMMODITY',
      code: 'localCSSNCode',
      title: 'localCSSNName'
    }


    this.settingAgentsDescription = {
      id: 'cbAgentsDescription',
      type: 'select',
      url: 'assets/json/CSSN.json',
      placeholder: 'DESCRIPTION',
      code: 'localCSSNCode',
      title: 'localCSSNName'
    }

    this.settingAgentAdd = {
      id: 'cbAgentAdd',
      type: 'select',
      url: 'assets/json/AgentAdr.json',
      placeholder: ' -- ',
      code: 'localCSSNCode',
      title: 'localCSSNName'
    }
    this.settingPC = {
      id: 'cbPC',
      type: 'select',
      url: 'assets/json/PC.json',
      placeholder: ' -- ',
      code: 'localCSSNCode',
      title: 'localCSSNCode'
    }
    this.settingPayer = {
      id: 'cbPayer',
      type: 'select',
      url: 'assets/json/payer.json',
      placeholder: ' -- ',
      code: 'localCSSNCode',
      title: 'localCSSNCode'
    }

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'se', width: 15},
        {header: 'Skip Check', field: '', width: 100},
        {header: 'Container Number', field: '', width: 15},
        {header: 'Seal No(1)', field: '', width: 100},
        {header: 'Seal No(2)', field: '', width: 15},
        {header: 'Seal No(3)', field: '', width: 100},
        {header: 'Qty', field: '', width: 15},
        {header: 'Pkg Type', field: '', width: 100},
        {header: 'Gross Wt', field: '', width: 15},
        {header: 'Gross Meas', field: '', width: 100},
        {header: 'Receipt Type', field: '', width: 15},
        {header: 'Delivery Type', field: '', width: 100},
        {header: 'Empty', field: '', width: 15},
        {header: 'Shortship', field: '', width: 15},
        {header: 'Type', field: '', width: 100},
        {header: 'Size', field: '', width: 15},
        {header: 'Height', field: '', width: 100},
        {header: 'Special Details', field: '', width: 100},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],


      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.settingGridMarkNo = {
      id: "gridmarkno",

      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'bMarkSeq', width: 15},
        {header: 'Mark No', field: 'bMarkAndNo', width: 500},

      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],

      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.settingGridContainer = {
      url: "",
      page: 10,
      columns: [

        {header: 'Seq', field: 'seqNo', width: 15},
        {header: 'Skip Check', field: 'skipcheck', width: 150},
        {header: 'Container Number', field: 'ContainerNo', width: 200},
        {header: 'Seal No(1)', field: 'SealNo1', width: 150},
        {header: 'Seal No(2)', field: 'SealNo2', width: 100},
        {header: 'Seal No(3)', field: 'SealNo3', width: 100},
        {header: 'qty', field: 'Qty', width: 100},
        {header: 'Pkg Type', field: 'PkgType', width: 100},
        {header: 'Gross Wt', field: 'GrossWt', width: 100},
        {header: 'Gross Meas', field: 'GrossMeas', width: 100},
        {header: 'Receipt Type', field: 'ReceiptType', width: 100},
        {header: 'Delivery Type', field: 'DeliveryType', width: 100},
        {header: 'Empty', field: 'Empty', width: 100},
        {header: 'ShortShip', field: 'Shortship', width: 100},
        {header: 'Type', field: 'Type', width: 100},
        {header: 'Size', field: 'Size', width: 100},
        {header: 'Height', field: 'Height', width: 100},
        {header: 'Spesial Detail', field: 'SpecialDetails', width: 100},

      ],
      buttons: [{name: 'Edit', event: 'edit', enabled: true}],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
    }

    this.settingGridDescription = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'blDescSeq', width: 15},
        {header: 'Inner Qty', field: 'blDescInnerQty', width: 100},
        {header: 'Inner Pkg', field: 'blDescInnerPackageType', width: 50},
        {header: 'Outer Qty', field: 'blDescOuterQty', width: 100},
        {header: 'Outer', field: 'blDescOuterPackageType', width: 15},
        {header: 'PSA Pkg', field: 'blDescPsaPackageType', width: 100},
        {header: 'Description of Goods', field: 'blDescription', width: 50},
        {header: 'Gross Wt', field: 'blDescGrossW', width: 100},
        {header: 'Nett Wt', field: 'blDescNettW', width: 50},
        {header: 'Gross Meas', field: 'blDescGrossMeas', width: 100},
        {header: 'Nett Meas', field: 'blDescNettMeas', width: 100},
        {header: 'Receipt Type', field: 'blDescReceiptType', width: 50},
        {header: 'Delivery Type', field: 'blDescDeliveryType', width: 150},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'BlNo',
      sortingDirection: 'ASC',
    }

    this.settingGridClauseDescription = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: '', width: 15},
        {header: 'Clause ID', field: '', width: 500},
        {header: 'Description', field: '', width: 500},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.descSettingGrid = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'blDescSeq', width: 15},
        {header: 'Inner Qty', field: 'blDescInnerQty', width: 100},
        {header: 'Inner Pkg', field: 'blDescInnerPackageType', width: 50},
        {header: 'Outer Qty', field: 'blDescOuterQty', width: 100},
        {header: 'Outer', field: 'blDescOuterPackageType', width: 15},
        {header: 'PSA Pkg', field: 'blDescPsaPackageType', width: 100},
        {header: 'Description of Goods', field: 'blDescription', width: 50},
        {header: 'Gross Wt', field: 'blDescGrossW', width: 100},
        {header: 'Nett Wt', field: 'blDescNettW', width: 50},
        {header: 'Gross Meas', field: 'blDescGrossMeas', width: 100},
        {header: 'Nett Meas', field: 'blDescNettMeas', width: 100},
        {header: 'Receipt Type', field: 'blDescReceiptType', width: 50},
        {header: 'Delivery Type', field: 'blDescDeliveryType', width: 150},

      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.descMiniSettingGrid = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: '', width: 15},
        {header: 'Inner Qty', field: '', width: 100},
        {header: 'Inner Pkg', field: '', width: 50},
        {header: 'Outer Qty', field: '', width: 100},
        {header: 'Outer', field: '', width: 15},
        {header: 'PSA Pkg', field: '', width: 100},
        {header: 'Description of Goods', field: '', width: 50},
        {header: 'Gross Wt', field: '', width: 100},
        {header: 'Nett Wt', field: '', width: 50},
        {header: 'Gross Meas', field: '', width: 100},
        {header: 'Receipt Type', field: '', width: 50},
        {header: 'Delivery Type', field: '', width: 150},

      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.settingGridNettCharge = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'blFcSeq', width: 15},
        {header: 'Print', field: 'print', width: 100},
        {header: 'FAI Group', field: 'fai', width: 50},
        {header: 'M. Freight', field: 'manifested', width: 100},
        {header: 'Charge', field: 'blFchargeCode', width: 15},
        {header: 'Qty', field: 'blFcQuantity', width: 100},
        {header: 'Rate', field: 'blFcRate', width: 100},
        {header: 'Per', field: 'blFcPer', width: 100},
        {header: 'Currency', field: 'blFcCurrency', width: 50},
        {header: 'Total Amount', field: 'blFcTotalMount', width: 100},
        {header: 'P/C', field: 'blFcPc', width: 50},
        {header: 'Payer', field: 'blFcPayer', width: 100},
        {header: 'Pbee Of Payment', field: 'blFcPlaceOfPaymentCode', width: 100},
        {header: 'Freight Reference', field: 'blPfcReference', width: 100},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
    }
    this.settingGridGrossCharge = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'blFcSeq', width: 15},
        {header: 'Print', field: 'print', width: 100},
        {header: 'FAI Group', field: 'fai', width: 50},
        {header: 'M. Freight', field: 'manifested', width: 100},
        {header: 'Charge', field: 'blFchargeCode', width: 15},
        {header: 'Qty', field: 'blFcQuantity', width: 100},
        {header: 'Rate', field: 'blFcRate', width: 100},
        {header: 'Per', field: 'blFcPer', width: 100},
        {header: 'Currency', field: 'blFcCurrency', width: 50},
        {header: 'Total Amount', field: 'blFcTotalMount', width: 100},
        {header: 'P/C', field: 'blFcPc', width: 50},
        {header: 'Payer', field: 'blFcPayer', width: 100},
        {header: 'Pbee Of Payment', field: 'blFcPlaceOfPaymentCode', width: 100},
        {header: 'Freight Reference', field: 'blPfcReference', width: 100},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
    }
    this.settingGridNettCharge2 = {
      url: "",
      page: 10,
      columns: [
        {header: 'Seq', field: 'blFcSeq', width: 15},
        {header: 'Print', field: 'blFcIsChargePrinted', width: 100},
        {header: 'FAI Group', field: 'blFcFaiGroup', width: 50},
        {header: 'M. Freight', field: 'blFcIsManifestedFreight', width: 100},
        {header: 'Charge', field: 'blFchargeCode', width: 15},
        {header: 'Qty', field: 'blFcQuantity', width: 100},
        {header: 'Rate', field: 'blFcRate', width: 100},
        {header: 'Per', field: 'blFcPer', width: 100},
        {header: 'Currency', field: 'blFcCurrency', width: 50},
        {header: 'Total Amount', field: 'blFcTotalMount', width: 100},
        {header: 'P/C', field: 'blFcPc', width: 50},
        {header: 'Payer', field: 'blFcPayer', width: 100},
        {header: 'Pbee Of Payment', field: 'blFcPlaceOfPaymentCode', width: 100},
        {header: 'Freight Reference', field: 'blPfcReference', width: 100},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
    }
  }



  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    $('.menu .item').tab();
    $('#gridHeader').find('th.collapsing').find('div.checkbox').remove();
    var self = this;
    //this.gridcontainer.search = '';
    //this.gridcontainer.onFirst();
    //this.gridMarkNo.onFirst();
    this.cbReceiptType.setValue("CY");
    this.modelM.blReceiptType='CY';
    this.cbDeliveryType.setValue("CY");
    this.modelM.blDeliveryType='CY';
    this.cbbContainerReceiptType.setValue("CY");
    this.modelDetail.ReceiptType='CY';
    this.cbbContainerDeliveryType.setValue("CY");
    this.modelDetail.DeliveryType='CY';
    this.cbTypeContainer.setValue("STD");
    this.modelDetail.Type='STD';
    this.cbHeightContainer.setValue("86");
    this.modelDetail.Height=86;
    // console.log(this.cbHeightContainer.setValue("86"));
    this.cbReceiptTypeDesc.setValue("CY");
    this.modelD.blDescReceiptType='CY';
    this.cbDeliveryTypeDesc.setValue("CY");
    this.modelD.blDescDeliveryType='CY';
    // this.PrintShippeeOnBoard=true;

	  //this.setValidatorMarkNo();
    //this.setValidatorContainer();
    //this.setValidatorMaintenance();


    this.lockNewContainer = false;
    this.lockSaveContainer = true;
    this.lockUpdateContainer = true;
    this.lockDeleteContainer = false;
    this.lockCancelContainer = true;

    this.lockNewMarkNo = false;
    this.lockSaveMarkNo = true;
    this.lockUpdateMarkNo = true;
    this.lockDeleteMarkNo = true;
    this.lockCancelMarkNo = true;

    this.lock=true;
    this.lockD=true;
    this.lockM=true;
    this.lockF=true;
    this.lockCon=true;

    this.SkipCheckContainerNo = true;
    this.skipCheckNettManifestedFreight = false;
    this.printNettCharge = false;
    this.modelF.blFcIsManifestedFreight="";
    this.modelF.blFcFaiGroup = "N";
    this.modelF.blFcIsChargePrinted="";

  }

  // tab 1
  changeEventShipper(event) {
   this.modelM['error-blShipperId'] = "";
    this.modelM.blShipperId = event.customerId;
    this.modelM.blShipperName = String(event.customerName).trim().toUpperCase().replace(/&NBSP;/g, " ");


  }

  changeEventReceiptTypeDesc(event) {
    this.modelD.blDescReceiptType = event.receiptAndDeliveryTypeCode;
  }

  changeEventDeliveryTypeDesc(event) {
    this.modelD.blDescDeliveryType = event.receiptAndDeliveryTypeCode;
  }

  changeEventMemo(event) {
    if (event.target.checked) {
      this.isMemo = true;
      this.modelM.blType = 'M';
    } else {
      this.isMemo = false;

    }
    // console.log(this.modelM.blType);
  }

  changeEventSw(event) {
    if (event.target.checked) {
      this.isSW = true;
      this.modelM.blType = "S";
    } else {
      this.isSW = false;
    }
  }


  changeContainerCom(event) {

  }

  changeEventConsignee(event) {

    this.modelM['error-blConsigneeId'] = "";
    this.modelM.blConsigneeId = event.customerId;
    this.modelM.blConsigneeName = String(event.customerName).trim().toUpperCase().replace(/&NBSP;/g, " ");
  }

  changeEventNotify(event) {

    this.modelM['error-blNotifyPartyId'] = "";
    this.modelM.blNotifyPartyId = event.customerId;
    this.modelM.blNotifyPartyName = String(event.customerName).trim().toUpperCase().replace(/&NBSP;/g, " ");
  }



  changeEventShipperBlNo(event) {

  }

  changeEventBKGNo(event) {

  }

  changeShipperEDI(event) {

  }

  changeTxtShipper(event) {
    // this.param.blShipperAddress = this.txtShipper.getValue();
    //// console.log("WOW SUKSES");
  }
  changeTxtMarkNo(event){
    this.modelMark.bMarkAndNo = this.txtMarkNo.getValue().toString().toUpperCase().replace("↵","");
  }

  changeEventDemurrageCode(event) {
    //this.param.DemurrageCode = event.DemurrageCode;
  }

  changeEventDetentionCode(event) {

  }

  changeEventOPCode(event) {

  }
  changeTxtDescription(event){
    this.modelD.blDescription = this.txtDescription.getValue().toString().toUpperCase().replace("↵","");
    // console.log(this.modelD.blDescription);
  }

//container


  changeEventSeq(event) {

  }

  changeEventContainerNo(event) {

  }

  changeEventSealNo1(event) {

  }

  changeEventSealNo2(event) {

  }

  changeEventSealNo3ContType(event) {

  }



  changeEventEmptyContainerNo(event) {


  }

  changeEventEmptyContainer(event) {

  }

  changeEventDryContainer(event) {

  }

  changeEventShortShipmentContainer(event) {

  }

  changeEventbContainerPackageType(event) {
    this.modelDetail.PkgType;

  }


  changeSkipCheckContainerNo(event) {
    if (event.target.checked) {
      this.SkipCheckContainerNo = true;

      this.modelDetail.skipcheck = 'Y';
    } else {

      this.SkipCheckContainerNo = false;
      this.modelDetail.skipcheck = 'N';
    }
    // console.log(this.modelDetail.skipcheck);
  }

  changeSkipCheck(event) {

    if (event.target.checked) {
      this.skipCheck = true;

      this.modelM.skipCheckPreVessel = 'Y';
    } else {

      this.skipCheck = false;
      this.modelM.skipCheckPreVessel = 'N';
    }
    // console.log(this.modelM.skipCheckPreVessel);
  }
  changeSkipCheckOceanVessel(event){
    if (event.target.checked) {
      this.skipCheckOceanVessel = true;

      this.modelM.skipCheckOceanVessel = 'Y';
    } else {

      this.skipCheckOceanVessel = false;
      this.modelM.skipCheckOceanVessel = 'N';
    }
    // console.log(this.modelM.skipCheckOceanVessel)
  }

  changeSkipCheckNextVessel(event) {
    if (event.target.checked) {
      this.skipNextCheckVessel = true;

      this.modelM.skipCheckNextVessel = 'Y';
    } else {

      this.skipNextCheckVessel = false;
      this.modelM.skipCheckNextVessel = 'N';
    }
    // console.log(this.modelM.skipCheckNextVessel)
  }



  changeSkipCheckIrePermit(event) {
    if (event.target.checked) {
      this.SkipCheckIrePermit = true;
      this.modelM.blIsIrePermit = 'Y';
    } else {
      this.SkipCheckIrePermit = false;
      this.modelM.blIsIrePermit = 'N';
    }
  }

  changeEmptyContainerNo(event) {
    if (event.target.checked) {
      this.EmptyContainer = true;
      this.modelDetail.Empty = 'Y';
    } else {
      this.EmptyContainer = false;
      this.modelDetail.Empty = 'N';
    }
  }

  changeDryContainer(event) {
    if (event.target.checked) {
      this.DryContainer = true;
      this.modelDetail.dry = 'Y';
    } else {
      this.DryContainer = false;
      this.modelDetail.dry = 'N';
    }
  }

  changeskipCheckPrintPreVessel(event) {
    if (event.target.checked) {
      this.skipCheckPrintPreVessel = true;
      this.modelM.printPreVessel = 'Y';
    } else {
      this.skipCheckPrintPreVessel = false;
      this.modelM.printPreVessel = 'N';
    }
  }
  changeSkipCheckPrintOceanVessel(event){
    if (event.target.checked) {
      this.skipCheckPrintOceanVessel = true;
      this.modelM.printOceanVessel = 'Y';
    } else {
      this.skipCheckPrintOceanVessel = false;
      this.modelM.printOceanVessel = 'N';
    }
  }
  changeSkipCheckPrintNextVesselMother(event){
    if (event.target.checked) {
      this.skipCheckPrintNextVessel = true;
      this.modelM.printNextVessel = 'Y';
    } else {
      this.skipCheckPrintNextVessel = false;
      this.modelM.printNextVessel = 'N';
    }
  }

  changeSkipCheckPre(event) {

  }

  changeShortShipmentContainer(event) {
    if (event.target.checked) {
      this.ShortShipmentContainer = true;
      this.modelDetail.Shortship = 'Y';
    } else {
      this.ShortShipmentContainer = false;
      this.modelDetail.Shortship = 'N';
    }
  }

  changeEventNormalRate(event) {
    if (event.target.checked) {
      this.isNormalRate = true;
      this.modelM.blExchangeRateMethod = 'Normal';
    } else {
      this.isNormalRate = false;

    }
    // console.log("test",this.isNormalRate);
  }
  changeEventSpecialRate(event) {
    if (event.target.checked) {
      this.isSpecialRate = true;
      this.modelM.blExchangeRateMethod = 'Special';
    } else {
      this.isSpecialRate = false;

    }
    // console.log("test",this.isSpecialRate);
  }
  changeEventBLRate(event) {
    if (event.target.checked) {
      this.isBLRate = true;
      this.modelM.blExchangeRateMethod = 'BL Rate';
    } else {
      this.isBLRate= false;

    }
    // console.log("test",this.isBLRate);
  }

  changeTypeContainer(event) {
    this.modelDetail.Type = event.localCSSNCode;
  }

  changeSizeContainer(event) {
    this.modelDetail['error-cbSizeContainer']
    this.modelDetail.Size = event.localCSSNCode;
  }

  changeSkipCheckPrintPreVessel(event) {

  }

  changeHeightContainer(event) {
    this.modelDetail.Height = event['localCSSNCode'];
  }

  changeSkipPrintAgentInfo(event) {

  }


  changeCheckConsigneePermit(event) {
    if (event.target.checked) {
      this.CheckConsigneePermit = true;
      this.modelM.blIsSingleConsigneePermit = 'Y';
    } else {
      this.CheckConsigneePermit = false;
      this.modelM.blIsSingleConsigneePermit = 'N';
    }
  }

  changeEventInnerPackageType(event) {
    this.modelD.blDescInnerPackageType=event.receiptAndDeliveryTypeCode;
  }

  changePSA(event) {
    this.modelD.blDescPsaPackageType;
  }

  changeEventOuterPackageType(event) {
    this.modelD.blDescOuterPackageType;
  }
  changeSkipCheckManifestedFreight(event) {
    if (event.target.checked) {
      this.skipCheckNettManifestedFreight = true;

      this.modelF.blFcIsManifestedFreight = 'Y';
    } else {

      this.skipCheckNettManifestedFreight = false;
      this.modelF.blFcIsManifestedFreight = 'N';
    }
    // console.log(this.modelF.blFcIsManifestedFreight);
  }

  changeContainerLock1(event) {

  }

  changeContainerLock2(event) {

  }

  changeCommodityType(event) {

  }

  changeEventNettCharge(event) {
    this.modelF.blFchargeCode = event.chargeCode;
  }
  changeEventNettPlaceOfIssue(event){
    this.modelM.blPlaceOfIssueCode = event.locationCode;
    this.cbNettPlaceOfIssue.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }



  changeEventCurrency(event) {
    this.modelF.blFcCurrency = event.currencyCode;
  }
  changePrintNett(event){
    if (event.target.checked) {
      this.printNettCharge = true;

      this.modelF.blFcIsChargePrinted = 'Y';
    } else {

      this.printNettCharge = false;
      this.modelF.blFcIsChargePrinted = 'N';
    }
    // console.log(this.modelF.blFcIsChargePrinted);
  }

  changeFreightAsArranged(event) {
    if (event.target.checked) {
      this.FreightAsArranged = true;

      this.modelM.blIsFreightAsArranged = 'Y';
    } else {

      this.FreightAsArranged = false;
      this.modelM.blIsFreightAsArranged = 'N';
    }
    // console.log(this.modelM.blIsFreightAsArranged);
  }

  changePrintLoadingVessel(event) {

  }

  changePrintShippedOnBoard(event) {
    if (event.target.checked) {
      this.PrintShippeeOnBoard = true;

      this.modelM.blIsPrintShippedBoard= 'Y';
    } else {

      this.PrintShippeeOnBoard = false;
      this.modelM.blIsPrintShippedBoard = 'N';
    }
    // console.log(this.modelM.blIsPrintShippedBoard);
  }

  changeSkipExchangeRate(event) {

  }
  changeSkipCheckNettManifestedFreight(event){

  }


  // ==========================================

  changeEventPrepaid1(event) {
    //this.modelP = new PC;
    this.modelM.blIsPrepaidAt1Code = event.locationCode;
    this.cbPrepaid1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
   // this.modelM.blPlaceOfDeliveryPrint = event.locationName;
   // this.localCSSNCode="P1";
   // this.localCSSNName = event.locationName;
   // this.cbPC.listStore.addData(this.modelP);
   // // console.log(this.cbPC.listStore);
  }

  changeEventPrepaid2(event) {
     this.modelM.blIsPrepaidAt2Code = event.locationCode;
     this.cbPrepaid2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    // this.modelP.localCSSNCode = "P2";
     //this.modelP.localCSSNName = event.locationName;
     //this.cbPC.listStore.addData(this.localCSSNCode);
     //// console.log(this.cbPC.listStore);
  }

  changeEventPrepaid3(event) {
    this.modelM.blIsPrepaidAt3Code= event.locationCode;
    this.cbPrepaid3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
   // this.localCSSNCode = "P3";
   // this.localCSSNName = event.locationName;
   // this.cbPC.setValue(this.localCSSNCode);
  }
  changeEventGrossPrepaid1(event){

  }
  changeEventGrossPrepaid2(event){

  }
  changeEventGrossPrepaid3(event){

  }

  changeEventPayable1(event) {
    this.modelM.blIsPayableAt1Code = event.locationCode;
    this.cbPayable1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeEventPayable2(event) {
    this.modelM.blIsPayableAt2Code = event.locationCode;
    this.cbPayable2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeEventPayable3(event) {
    this.modelM.blIsPayableAt3Code = event.locationCode;
    this.cbPayable3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeEventGrossPayable1(event) {

  }
  changeEventGrossPayable2(event) {

  }
  changeEventGrossPayable3(event) {

  }


  changeEventBillingParty1(event) {
    this.modelM.blPayer1Code = event.customerCode;


    // console.log(this.modelM.blPayer1Code);
  }

  changeEventBillingParty2(event) {
    this.modelM.blPayer2Code = event.customerCode;
  }

  changeEventBillingParty3(event) {
    this.modelM.blPayer3Code = event.customerCode;
  }
  changeEventGrossBillingParty1(event){

  }
  changeEventGrossBillingParty2(event){

  }
  changeEventGrossBillingParty3(event){

  }

  toInsert() {

    this.setValidatorNetCharge();
    // console.log(this.modelF);

    if(!this.onValidate(this.modelF)){

      if(this.modelF.no == 0){
        let data = new FreightCharge;
        data.blFcSeq = Number(this.modelF.blFcSeq);
        data.blFcIsChargePrinted = String(this.modelF.blFcIsChargePrinted);
        data.blFcIsManifestedFreight = String (this.modelF.blFcIsManifestedFreight) ;
        data.blFcFaiGroup = String(this.modelF.blFcFaiGroup);
        data.blFchargeCode = String(this.modelF.blFchargeCode);
        data.blFcQuantity = Number(this.modelF.blFcQuantity);
        data.blFcRate = Number(this.modelF.blFcRate);
        data.blFcPer = String(this.modelF.blFcPer);
        data.blFcCurrency = String(this.modelF.blFcCurrency);
        data.blFcTotalMount = Number(this.modelF.blFcTotalMount);
        data.blFcPc = String(this.modelF.blFcPc);
        data.blFcPayer = String(this.modelF.blFcPayer);
        data.blFcPlaceOfPaymentCode = String(this.modelF.blFcPlaceOfPaymentCode);
        data.blPfcReference = String(this.modelF.blPfcReference);


        this.gridnettcharge2.listStore.addData(data);
        this.gridnettcharge2.url='';
        this.gridnettcharge2.loadData();
        this.updateFreightCharge();

        this.gridnettcharge2.clearSelectedValues();
        this.modelF = new FreightCharge;


      }else{

        let data = new FreightCharge;
        data.blFcSeq = Number(this.modelF.blFcSeq);
        data.blFchargeCode = String(this.modelF.blFchargeCode);
        data.blFcQuantity = Number(this.modelF.blFcQuantity);
        data.blFcRate = Number(this.modelF.blFcRate);
        data.blFcPer = String(this.modelF.blFcPer);
        data.blFcCurrency = String(this.modelF.blFcCurrency);
        data.blFcTotalMount = Number(this.modelF.blFcTotalMount);
        data.blFcPc = String(this.modelF.blFcPc);
        data.blFcPayer = String(this.modelF.blFcPayer);
        data.blFcPlaceOfPaymentCode = String(this.modelF.blFcPlaceOfPaymentCode);
        data.blPfcReference = String(this.modelF.blPfcReference);


        this.gridnettcharge2.listStore.updateData(this.modelF.no-1 ,data);
        this.gridnettcharge2.url='';
        this.gridnettcharge2.loadData();
        this.gridnettcharge2.clearSelectedValues();
        this.modelF = new FreightCharge;


      }
      // this.txtMarkNo.setValue("");
      //this.forCreate = true;
      // this.toInsert = true;
      // this.lockDeleteMarkNo = true;
      // this.lockUpdateMarkNo = false;
      this.lockSaveFc=true;
      this.lockUpdateFc=true;
      this.lockDeleteFc = false;
      this.lockCancelFc=false;
      this.lockF  = true;
      this.cbPer.setValue("");
      this.cbCurrency.setValue("");
      this.cbPC.setValue("");
      this.cbNettCharge.setValue("");
      this.modeForm = false;
    }
  }
  toUpdate(){
    // console.log(this.modelF);
    if(!this.onValidate(this.modelF)) {

      if (this.modelF.no == 0) {
        let data = new FreightCharge;
        data.blFcSeq = Number(this.modelF.blFcSeq);
        data.blFcIsChargePrinted = String(this.modelF.blFcIsChargePrinted);
        data.blFcIsManifestedFreight = String(this.modelF.blFcIsChargePrinted);
        data.blFchargeCode = String(this.modelF.blFchargeCode);
        data.blFcQuantity = Number(this.modelF.blFcQuantity);
        data.blFcRate = Number(this.modelF.blFcRate);
        data.blFcPer = String(this.modelF.blFcPer);
        data.blFcCurrency = String(this.modelF.blFcCurrency);
        data.blFcTotalMount = Number(this.modelF.blFcTotalMount);
        data.blFcPc = String(this.modelF.blFcPc);
        data.blFcPayer = String(this.modelF.blFcPayer);


        this.gridnettcharge2.listStore.addData(data);
        this.gridnettcharge2.url='';
        this.gridnettcharge2.loadData();
        this.updateFreightCharge();

        this.gridnettcharge.clearSelectedValues();
        this.modelF = new FreightCharge;


      } else {

        let data = new FreightCharge;
        data.blFcSeq = Number(this.modelF.blFcSeq);
        data.blFchargeCode = String(this.modelF.blFchargeCode);
        data.blFcQuantity = Number(this.modelF.blFcQuantity);
        data.blFcRate = Number(this.modelF.blFcRate);
        data.blFcPer = String(this.modelF.blFcPer);
        data.blFcCurrency = String(this.modelF.blFcCurrency);
        data.blFcTotalMount = Number(this.modelF.blFcTotalMount);
        data.blFcPc = String(this.modelF.blFcPc);
        data.blFcPayer = String(this.modelF.blFcPayer);


        this.gridnettcharge2.listStore.updateData(this.modelF.no - 1, data);
        this.gridnettcharge2.url='';
        this.gridnettcharge2.loadData();
        this.gridnettcharge2.clearSelectedValues();
        this.modelF = new FreightCharge;


      }
      // this.txtMarkNo.setValue("");
      //this.forCreate = true;
      // this.toInsert = true;
      // this.lockDeleteMarkNo = true;
      // this.lockUpdateMarkNo = false;
      this.lockNewFc = false;
      this.lockSaveFc = true;
      this.lockUpdateFc = true;
      this.lockDeleteFc = false;
      this.lockCancelFc = false;
      this.lockF = true;
    }
  }
  toDelete() {
    this.gridnettcharge2.getSelectedValues().forEach(element => {
      this.gridnettcharge2.listStore.removeData(element);
      //  this.gridnettcharge2.loadData();
      this.gridnettcharge2.clearSelectedValues();
      this.updateFreightCharge();
    });

  }
  toNettCancel() {
    this.modeFormNett = false;
  }



  // ==========================================


  updateVoyageUrl(type: string) {
    switch (type) {
      case "header" :
        this.updateVoyageUrl1a(this.cbVoyage);
        this.updateVoyageUrl2a(this.cbOceanVoyage);
        break;

    }
  }

  updateVoyageUrl1a(combo: ComboPluginComponent) {
    if(this.modelM.blPreVesselBound == ''){
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.modelM.blPreVesselId+"/*/{query}/SGSIN");
    }else{
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.modelM.blPreVesselId+"/"+this.modelM.blPreVesselBound.toUpperCase()+"/{query}/SGSIN");
    }





  }
  updateVoyageUrl2a(combo: ComboPluginComponent) {
    if(this.modelM.blOceanVesselBound == ''){
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.modelM.blOceanVesselId+"/*/{query}/SGSIN");
    }else{
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.modelM.blOceanVesselId+"/"+this.modelM.blOceanVesselBound.toUpperCase()+"/{query}/SGSIN");
    }





  }

  changeEventVessel(event) {

    if(event.vesselId != null){
      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.modelM.blPreVesselBound.toUpperCase()+"/{query}/SGSIN");
      this.cbVoyage.setValue("");

      this.modelM.blPreVesselId= event.vesselId;
      this.modelM.blPreVesselCode= event.vesselCode;
      this.modelM.blPreVesselName = event.vesselName;
      if(event.capacity != null){
        this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.modelM.blPreVesselId= "";
      this.modelM.blPreVesselCode = "";
      this.modelM.blPreVesselName = "";
    }
  }




  changeEventOceanVessel(event) {
    this.modelM['error-blOceanVesselId'] = "";
    if (event.vesselId != null) {
      this.cbOceanVoyage.disableCombo = false;
      this.cbOceanVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/" + event.vesselId + "/" + this.modelM.blOceanVesselBound.toUpperCase() + "/{query}/SGSIN");
      this.cbOceanVoyage.setValue("");
      this.modelM.blOceanVesselId = event.vesselId;
      this.modelM.blOceanVesselCode = event.vesselCode;
      this.modelM.blOceanVesselName = event.vesselName;

      if (event.capacity != null) {
        this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.modelM.blOceanVesselId= "";
      this.modelM.blOceanVesselCode = "";
      this.modelM.blOceanVesselName = "";
    }
    // console.log(this.modelM);
  }


  changeEventVesselNext(event) {
    //this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
    if (event.vesselId != null) {
      this.cbVoyageNext.disableCombo = false;
      this.cbVoyageNext.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/" + event.vesselId + "/" + this.modelM.blNextVesselBound.toUpperCase() + "/{query}/SGSIN");
      this.cbVoyageNext.setValue("");
      this.modelM.blNextVesselId = event.vesselId;
      this.modelM.blNextVesselCode = event.vesselCode;
      this.modelM.blNextVesselName = event.vesselName;
      if (event.capacity != null) {
        this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.modelM.blNextVesselId = "";
      this.modelM.blNextVesselCode = "";
      this.modelM.blNextVesselName = "";
    }
  }

  changeEventVoyage(event) {

    if(event.voyage != null){
      this.modelM.blPreVesselBound = event.bound;
      this.modelM.blPreVesselVoyage = event.voyage;
    } else {

      this.modelM.blPreVesselVoyage = "";
    }

  }


  changeEventVoyagePre(event) {
  }

  changeOceanEventVoyage(event) {
    this.modelM['error-blOceanVesselVoyage'] = "";
    if (event.voyage != null) {

      this.modelM.blOceanVesselBound = event.bound;
      this.modelM.blOceanVesselVoyage = event.voyage;

    } else {
      this.modelM.blOceanVesselVoyage = event.voyage;
    }
  }

  changeEventPer(event) {
    this.modelF.blFcPer = event.perCode;
  }
  changeEventTotal(event){
    this.modelF.blFcTotalMount = Number(this.modelF.blFcQuantity*this.modelF.blFcRate);
    // console.log("total :",this.modelF.blFcTotalMount)
  }

  changeCossn(event) {

  }
  changePC(event){
    this.modelF.blFcPc = event.localCSSNCode
    this.modelF.blPfcReference = event.localCSSNName;
  }
  changePayer(event){
    this.modelF.blFcPayer = event.localCSSNCode
    this.modelF.blFcPlaceOfPaymentCode = event.localCSSNName;
  }
  changeEventVoyageNext(event) {
    if (event.voyage != null) {
      this.modelM.blNextVesselBound = event.bound;
      this.modelM.blNextVesselVoyage = event.voyage;

    } else {
      this.modelM.blNextVesselVoyage = "";
    }

  }

  changeEventReceiptType(event) {

    this.modelM.blReceiptType = event.receiptAndDeliveryTypeCode;


  }





  changeEventReceiptPlace(event) {
    //this.param.bOceanReciptType = event['blPlaceOfReceiptvalPrint'];
    this.modelM['error-blPlaceOfReceipt'] = "";
    this.modelM.blPlaceOfReceipt = event.locationCode;
    this.cbReceiptPlace.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    this.modelM.blPlaceOfPrint = event.locationName;
  }

  changeEventLoadPort(event) {
    this.modelM['error-blLoadPort'] = "";
    this.modelM.blLoadPort = event.locationCode;
    this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    this.modelM.blLoadPortPrint = event.locationName;
  }

  changeEventDischargePort(event) {
    this.modelM['error-blDischargePort'] = "";
    this.modelM.blDischargePort = event.locationCode;
    this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    this.modelM.blDischargePortPrint = event.locationName;
  }

  changeEventPODelivery(event) {
    this.modelM['error-blPlaceOfDelivery'] = "";
    this.modelM.blPlaceOfDelivery = event.locationCode;
    this.cbPODelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    this.modelM.blPlaceOfDeliveryPrint = event.locationName;

  }

  changeEventFinalDestination(event) {
    this.modelM['error-blFinalDestination'] = "";
    this.modelM.blFinalDestination = event.locationCode;
    this.cbFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
    this.modelM.blFinalDestinationPrint = event.locationName;

  }

  changeEventPOT1(event) {
    this.modelM['error-POT1'] = "";
    this.modelM.POT1 = event.locationCode;
    this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeEventPOT2(event) {
    this.modelM.POT2 = event.locationCode;
  }

  changeEventPOT3(event) {
    this.modelM.POT3 = event.locationCode;
  }

  changeEventService(event) {
    this.modelM['error-blServiceId'] = "";
    this.modelM.blServiceId = event.serviceCode;
  }

  changeEventPrincipal(event) {
    this.modelM['error-blPrincipalId'] = "";
    this.modelM.blPrincipalId = event.principalCode;
  }

  changeEventDeliveryType(event) {

    this.modelM.blDeliveryType = event.receiptAndDeliveryTypeCode;

  }

  changeEventDeliveryTypeContainer(event) {

  }

  changeAgentsDescription(event) {


  }

  changeAgentAdd(event) {
    //this.param.bDescAgentAdd = event['localCSSNCode'];
  }

  changeEventbContainerReceiptType(event) {

    this.modelDetail.ReceiptType = event['receiptAndDeliveryTypeCode'];

  }

  changeEventbContainerDeliveryType(event) {
    this.modelDetail.DeliveryType = event['receiptAndDeliveryTypeCode'];
  }

  //tab 3


  gridEvent(event) {

  }
  gridEventNettCharge(event){
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
            //  this.setValidatorDetails();
              this.lockNewFc = true;
              this.lockUpdateFc = false;
              this.lockDeleteFc = false;
              this.lockF=false;
              this.modeFormNett = true;
              let dt = this.gridnettcharge.getSelectedValues()[0];
              this.gridnettcharge.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelF = new FreightCharge;
                  this.modelF = element;
                  // console.log(this.modelF);
                  this.modelF.blFcPer;
                  this.cbPer.setValue(this.modelF.blFcPer);
                  // console.log(this.modelF.blFcPer);
                  this.modelF.blFcCurrency;
                  this.cbCurrency.setValue(this.modelF.blFcCurrency);
                  // console.log(this.modelF.blFcCurrency);
                  this.modelF.blFchargeCode
                  this.cbNettCharge.setValue(this.modelF.blFchargeCode);
                  // console.log(this.modelF.blFchargeCode);


                }
              });

              $('#display').transition();
              // $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }
  gridEventGrossCharge(event){

  }
  gridEventNettCharge2(event) {
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
             // this.setValidatorDetails();
              this.lockNewFc = false;
              this.lockUpdateFc = false;
              this.lockDeleteFc = false;
              this.lockF=false;


              let dt = this.gridnettcharge2.getSelectedValues()[0];
              this.gridnettcharge2.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelF = new FreightCharge;
                  this.modelF = element;
                  // console.log(this.modelF);
                }
              });

              $('#display').transition();
              // $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }

  setValidatorDetails(){

  }

  gridEventContainer(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
              this.setValidatorDetails();
              this.lockNewContainer = true;
              this.lockUpdateContainer = false;
              this.lockDeleteContainer = false;
              this.lockCancelContainer = false;
              this.lock=false;


              let dt = this.gridcontainer.getSelectedValues()[0];
              this.gridcontainer.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelDetail = new ContainerMaintenance;
                  this.modelDetail = element;
                  // console.log(this.modelDetail);
                }
              });

              $('#display').transition();
              // $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }

  gridEventMarkNo(event) {
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
              this.setValidatorDetails();
              this.lockNewMarkNo = true;
              this.lockUpdateMarkNo = false;
              this.lockDeleteMarkNo = false;
              this.lock=false;


              let dt = this.gridmarkno.getSelectedValues()[0];
              this.gridmarkno.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelMark = new MarkNo ;
                  this.modelMark = element;
                  this.txtMarkNo.setValue(this.modelMark.bMarkAndNo);
                  // console.log(this.modelMark);
                }
              });

              $('#display').transition();
              //  $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }

  gridEventDescription(event) {

  }

  gridEventClauseDescription(event) {

  }

  descgridEvent(event) {
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
              //this.setValidatorDetails();
              this.lockSaveDesc = true;
              this.lockUpdateDesc = false;
              //this.lockSaveDesc = true;
              this.lockDeleteDesc=false;
              this.lockCancelDesc=false;
              this.lockD=false;
              this.modeFormDesc=true;


              let dt = this.griddescription.getSelectedValues()[0];
              this.griddescription.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelD = new Description();
                  this.modelD = element;
                  // console.log(this.modelD);
                }
              });

              $('#display').transition();
              // $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }

  descGridEvent2(event) {
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'dblClick':
              this.setValidatorDetails();
              this.lockSaveDesc=true;
              this.lockUpdateDesc = false;
              this.lockDeleteDesc = false;
              this.lockCancelDesc = false;
              this.lockD=false;
             // this.modeFormDesc=true;


              let dt = this.griddesc2.getSelectedValues()[0];
              this.griddesc2.listStore.store.forEach(element => {
                if(dt.no ==  element['no']){
                  this.modelD = new Description();
                  this.modelD = element;
                  // console.log(this.modelD);
                }
              });

              $('#display').transition();
              // $('#displayGrid').transition('hide');

              break;

            default:
              break;
          }

        }

        break;
    }
  }

  descMiniGridEvent(event) {

  }


  infoGrid(event) {
    this.info = event;
  }


  infoGridContainer(event) {
    this.info = event;
  }

  infoGridMarkNo(event) {

    this.info = event;
  }

  infoGridDescription(event) {
    this.info = event;
  }

  handleRetrieve() {
    //// console.log("yes berhasil");
   // // console.log(this.modelM.blNo);



        let URL_Retrieve = "";
        URL_Retrieve = "http://glossys.samudera.com:16001/BlMaintenance/GetRetrieve/" + this.modelM.blNo.toString().toUpperCase().trim();
        this.genericService.GET(URL_Retrieve).subscribe((resp) => {
          if (resp.ok) {
            // console.log(this.modelM);
            this.modelM.maintenanceMarkNo = [];
            this.modelM.maintenanceMarkNo = resp.json()['content']['maintenanceMarkNo'];
            this.gridmarkno.listStore.store = resp.json()['content']['maintenanceMarkNo'];
            this.gridmarkno.loadData();
            // console.log("test",resp.json()['content']['maintenanceMarkNo']);


            this.modelM.maintenanceContainer = [];
            this.modelM.maintenanceContainer = resp.json()['content']['maintenanceContainer'];
            this.gridcontainer.listStore.store = resp.json()['content']['maintenanceContainer'];
            this.gridcontainer.loadData();
            // console.log("test",resp.json()['content']['maintenanceContainer']);


            this.modelM.maintenanceFreight = [];
            this.modelM.maintenanceFreight = resp.json()['content']['maintenanceFreight'];
            this.gridnettcharge.listStore.store = resp.json()['content']['maintenanceFreight'];
            this.gridnettcharge2.listStore.store = resp.json()['content']['maintenanceFreight'];
            this.gridnettcharge.loadData();
            this.gridnettcharge2.loadData();
            // console.log("test",resp.json()['content']['maintenanceFreight']);


            this.modelM.maintenanceDesc = [];
            this.modelM.maintenanceDesc = resp.json()['content']['maintenanceDesc'];
            this.griddescription.listStore.store = resp.json()['content']['maintenanceDesc'];
            this.griddesc2.listStore.store = resp.json()['content']['maintenanceDesc'];
            this.griddescription.loadData();
            this.griddesc2.loadData();
            // console.log("test",resp.json()['content']['maintenanceDesc']);


            this.modelM.blShipperId = resp.json()['content']['header'][0]['blShipperId'];
            this.cbShipper.setValue(resp.json()['content']['header'][0]['blShipperName'].toString());
            this.modelM.blShipperName = resp.json()['content']['header'][0]['blShipperName'].toString();
            this.txtShipper.setValue(resp.json()['content']['header'][0]['blShipperAddress'].toString());
            this.modelM.blConsigneeId = resp.json()['content']['header'][0]['blConsigneeId'];
            this.cbConsignee.setValue(resp.json()['content']['header'][0]['blConsigneeName'].toString());
            this.modelM.blConsigneeName = resp.json()['content']['header'][0]['blConsigneeName'].toString();
            this.txtConsignee.setValue(resp.json()['content']['header'][0]['blConsigneeAddress']);
            this.modelM.blNotifyPartyId = resp.json()['content']['header'][0]['blNotifyPartyId'];
            this.cbNotify.setValue(resp.json()['content']['header'][0]['blNotifyPartyName'].toString());
            this.modelM.blNotifyPartyName = resp.json()['content']['header'][0]['blNotifyPartyName'].toString();
            this.txtNotify.setValue(resp.json()['content']['header'][0]['blNotifyPartyAddress']);
            //this.modelM.bOceanShipperId = resp.json()['content'][0]['blShipperId'];
            //this.param.PartyConsignee = resp.json()['content'][0]['blConsigneeId'];
            //this.param.blNotifyPartyId = resp.json()['content'][0]['blNotifyPartyId'];
            this.modelM.ShipperBlNo = this.modelM.blNo;
            // console.log(this.modelM.blIsPrepaidAt1Code =resp.json()['content']['header'][0]['blPrepaid1Code']);
            this.cbPrepaid1.setValue(resp.json()['content']['header'][0]['blPrepaid1Code']);
            // console.log(this.modelM.blIsPayableAt1Code = resp.json()['content']['header'][0]['blIsPayableAt1Code']);
            this.cbPayable1.setValue(resp.json()['content']['header'][0]['blIsPayableAt1Code']);
            // console.log(this.modelM.blPayer1Code = resp.json()['content']['header'][0]['blIsPayer1Code']);
            this.cbBillingParty1.setValue(resp.json()['content']['header'][0]['blIsPayer1Code']);
            this.modelM.blDeclaredValue = resp.json()['content']['header'][0]['blDeclaredValue'];

            this.modelM.blType = resp.json()['content']['header'][0]['blType'];
            if (this.modelM.blType == 'M') {
              this.isMemo = true;

            } else if (this.modelM.blType == 'S') {
              this.isSW = true;
            } else {

            }


            this.modelM.skipCheckPreVessel = "";
            if (this.modelM.skipCheckPreVessel == 'Y') {
              this.skipCheck = true;
            } else if (this.modelM.skipCheckPreVessel == 'N') {
              this.skipCheck = false;
            }
            this.modelM.printOceanVessel = "";
            if (this.modelM.printOceanVessel == 'Y') {

            } else if (this.modelM.printOceanVessel == 'N') {

            }


            //place
            this.cbReceiptType.setValue(resp.json()['content']['header'][0]['blReceiptTypeCode']);
            this.modelM.blReceiptType = resp.json()['content']['header'][0]['blReceiptTypeCode'].toString();
            this.cbReceiptPlace.setValue(resp.json()['content']['header'][0]['blPlaceOfReceiptCode'].toString());
            this.modelM.blPlaceOfReceipt=resp.json()['content']['header'][0]['blPlaceOfReceiptCode'].toString();
            this.modelM.blPlaceOfPrint = resp.json()['content']['header'][0]['blPlaceOfReceiptPrint'];
            this.modelM.blLoadPort=resp.json()['content']['header'][0]['blLoadPortCode'].toString();
            this.cbLoadPort.setValue(resp.json()['content']['header'][0]['blLoadPortCode']);
            this.modelM.blLoadPortPrint = resp.json()['content']['header'][0]['blLoadPortPrint'];
            this.cbDischargePort.setValue(resp.json()['content']['header'][0]['blDischargePortCode']);
            this.modelM.blDischargePort=resp.json()['content']['header'][0]['blDischargePortCode'].toString();
            this.modelM.blDischargePortPrint = resp.json()['content']['header'][0]['blDischargePortPrint'];
            this.cbPODelivery.setValue(resp.json()['content']['header'][0]['blPlaceOfDeliveryCode']);
            this.modelM.blPlaceOfDelivery=resp.json()['content']['header'][0]['blPlaceOfDeliveryCode'].toString();
            this.modelM.blPlaceOfDeliveryPrint = resp.json()['content']['header'][0]['blPlaceOfDeliveryPrint'];
            this.cbFinalDestination.setValue(resp.json()['content']['header'][0]['blFinalDestinationCode']);
            this.modelM.blFinalDestination=resp.json()['content']['header'][0]['blFinalDestinationCode'].toString();
            this.modelM.blFinalDestinationPrint = resp.json()['content']['header'][0]['blFinalDestinationPrint'];
            this.cbPOT1.setValue(resp.json()['content']['header'][0]['blPortOfTransshipment1Code']);
            this.modelM.POT1=resp.json()['content']['header'][0]['blPortOfTransshipment1Code'];
            this.cbDeliveryType.setValue(resp.json()['content']['header'][0]['blDeliveryTypeCode']);
            this.modelM.blDeliveryType = resp.json()['content']['header'][0]['blDeliveryTypeCode'].toString();
            this.cbService.setValue(resp.json()['content']['header'][0]['blServiceId']);
            this.modelM.blServiceId = resp.json()['content']['header'][0]['blServiceId'].toString();
            this.cbPrincipal.setValue(resp.json()['content']['header'][0]['blPrincipalId']);
            this.modelM.blPrincipalId = resp.json()['content']['header'][0]['blPrincipalId'].toString();
            this.cbVessel.setValue(resp.json()['content']['header'][0]['blPreVesselId']);
            this.modelM.blPreVesselId = resp.json()['content']['header'][0]['blPreVesselId'].toString();
            this.cbVoyage.setValue(resp.json()['content']['header'][0]['blPreVesselVoyage']);
            this.modelM.blPreVesselVoyage = resp.json()['content']['header'][0]['blPreVesselVoyage'].toString();
            this.modelM.blPreVesselBound = resp.json()['content']['header'][0]['blPreVesselBound'];
            //this.cbOceanVessel.setUrl(this.configService.config.BASE_API.toString() + "/MasterVessels/findByComboBoxControl/vesselName={query}");
            this.modelM.blOceanVesselId = (resp.json()['content']['header'][0]['blOceanVesselId']);
            this.cbOceanVessel.setValue(resp.json()['content']['header'][0]['blOceanVesselCode']);
            this.modelM.blOceanVesselCode = resp.json()['content']['header'][0]['blOceanVesselCode'];
            this.cbOceanVoyage.setValue(resp.json()['content']['header'][0]['blOceanVesselVoyage']);
            this.modelM.blOceanVesselVoyage=resp.json()['content']['header'][0]['blOceanVesselVoyage'];
            this.modelM.blOceanVesselBound = resp.json()['content']['header'][0]['blOceanVesselBound'];
            this.cbVesselNext.setValue(resp.json()['content']['header'][0]['blVesselNextId']);
            this.modelM.blNextVesselId  = resp.json()['content']['header'][0]['blVesselNextId'].toString();
            this.cbVoyageNext.setValue(resp.json()['content'][0]['header']['blNextVesselVoyage']);
            this.modelM.blNextVesselVoyage = resp.json()['content']['header'][0]['blNextVesselVoyage'].toString();
            this.modelM.blNextVesselBound = resp.json()['content']['header'][0]['blNextVesselBound'];


            this.modelM.blIsIrePermit = resp.json()['content']['header'][0]['blIsIrePermit'];

            if (this.modelM.blIsIrePermit == "Y") {
              this.SkipCheckIrePermit = true;
            } else {
              this.SkipCheckIrePermit = false;
            }
            this.modelM.blIsSingleConsigneePermit = resp.json()['content']['header'][0]['blIsSingleConsigneePermit'];
            if (this.modelM.blIsSingleConsigneePermit == "Y") {
              this.CheckConsigneePermit = true;
            } else {
              this.CheckConsigneePermit = false;
            }
            this.modelM.blFreightCoveredInBl = resp.json()['content']['header'][0]['blFreightCoveredInBl'];
            this.modelM.blIsFreightAsArranged = resp.json()['content']['header'][0]['blIsFreightAsArranged'];

            if (this.modelM.blIsFreightAsArranged == "Y") {
              this.FreightAsArranged = true;

            } else {
              this.FreightAsArranged = false;
            }
            this.modelM.blIsPrintShippedBoard = resp.json()['content']['header'][0]['blIsPrintShippedBoard'];
            if (this.modelM.blIsPrintShippedBoard == "Y") {
              this.PrintShippeeOnBoard = true;
            } else {
              this.PrintShippeeOnBoard = false;
            }
            this.modelM.blExchangeRateDate = resp.json()['content']['header'][0]['blExchangeRateDate'];
            this.modelM.blExchangeRateMethod = resp.json()['content']['header'][0]['blExchangeRateMethod'];
            // this.cbCountry.setValue("");



          } else {
            // console.log("test2");
            //// console.log(resp.json()["content"]["status"]==undefined);
            this.message("information", "information", "wamba", "okonly", "");
          }

        })


  }




  handlePartialSave() {
    // console.log('handlePartialSave');



    /*this.isError = this.onValidate(this.param);
     if (!this.isError) {
     // console.log(this.param.BlNo);
     let isPassedValidation: boolean = this.onValidate(this.model);
     // console.log(this.model['error-clauseName']);
     if (this.model['error-clauseName'] != '') {
     this.txtConsignee.error = true;
     this.txtShipper.error = true;
     this.txtNotify.error = true;
     } else {
     this.txtConsignee.error = false;
     this.txtShipper.error = false;
     this.txtNotify.error = false;
     }



     // console.log('isPassedValidation ' + isPassedValidation);
     if (!isPassedValidation) {
     this.model["dateCreated"] = this.genericUtil.getPSIDate();
     this.message('save', 'Saving data', 'Do you want to save the record? ', 'yesno', {
     yes: 'this.saveEvent()',
     no: 'this.loading = false;'
     });
     } else {
     this.param.loading = false;
     }
     } else {

     }*/
    // console.log(this.modelM);


    this.modelM.maintenanceContainer = [];
    this.modelM.maintenanceContainer = this.gridcontainer.listStore.store;
    this.modelM.maintenanceMarkNo = [];
    this.modelM.maintenanceMarkNo = this.gridmarkno.listStore.store;
    this.modelM.maintenanceDesc = [];
    this.modelM.maintenanceDesc = this.griddesc2.listStore.store;
    this.modelM.maintenanceFreight = [];
    this.modelM.maintenanceFreight = this.gridnettcharge2.listStore.store;

    // console.log(this.modelM.maintenanceContainer);
    // console.log(this.modelM.maintenanceMarkNo);
    // console.log(this.modelM.maintenanceDesc);
    // console.log(this.modelM.maintenanceFreight);
    //this.modelM.officeCode = this.cookieService.getDefaultLocationCode();
    this.modelM.blShipperAddress = String(this.txtShipper.getValue()).trim().toUpperCase().replace("↵", "");
    this.modelM.blConsigneeAddress = String(this.txtConsignee.getValue()).trim().toUpperCase().replace("↵", "");
    this.modelM.blNotifyPartyAddress = String(this.txtNotify.getValue()).trim().toUpperCase().replace("↵", "");
    this.modelMark.bMarkAndNo = String(this.txtDescription.getValue().trim().toUpperCase().replace("↵",""));
  //  this.modelM.blShipperName = this.cbShipper.getValue();
    //this.cbShipper.getValue(this.modelM.bl);
    this.genericService.POST('http://glossys.samudera.com:16001/BlMaintenance/update', this.modelM).subscribe((resp) => {
      this.modelM = new MasterBlHeader;


      // this.cbCountry.setValue("");
      this.modelDetail = new ContainerMaintenance;
      this.gridcontainer.listStore.store = [];
      //this.gridcontainer.loadData();

      this.modelMark = new MarkNo;
      this.gridmarkno.listStore.store = [];
      //this.gridmarkno.loadData();

      this.modelD = new Description;
      this.griddesc2.listStore.store = [];
      //this.griddesc2.loadData();

      this.modelF = new FreightCharge;
      this.gridnettcharge2.listStore.store = [];
      //this.gridnettcharge2.loadData();


      this.loading = false;
      this.info = 'save successfully';
    })
    this.cbShipper.setValue("");
    this.txtShipper.setValue("");
    this.cbConsignee.setValue("");
    this.txtConsignee.setValue("");
    this.cbNotify.setValue("");
    this.txtNotify.setValue("");
    this.cbReceiptPlace.setValue("");
    this.cbLoadPort.setValue("");
    this.cbDischargePort.setValue("");
    this.cbPODelivery.setValue("");
    this.cbFinalDestination.setValue("");
    this.cbPOT1.setValue("");
    this.cbService.setValue("");
    this.cbPrincipal.setValue("");
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbOceanVessel.setValue("");
    this.cbOceanVoyage.setValue("");
    this.cbVesselNext.setValue("");
    this.cbVoyageNext.setValue("");
    this.cbPrepaid1.setValue("");
    this.cbPrepaid2.setValue("");
    this.cbPrepaid3.setValue("");
    this.cbPayable1.setValue("");
    this.cbPayable2.setValue("");
    this.cbPayable3.setValue("");
    this.cbBillingParty1.setValue("");
    this.cbBillingParty2.setValue("");
    this.cbBillingParty3.setValue("");

    }


  saveEvent(){

  }

  handleSave() {
    this.setValidatorMaintenance();

    // console.log("save event");
    // console.log('saveEvent');

    // let isValid:boolean = this.onValidate(this.modelM.blShipperId);
    // console.log(this.modelM);

    if(!this.onValidate(this.modelM)) {
      this.modelM.maintenanceContainer = [];
      this.modelM.maintenanceContainer = this.gridcontainer.listStore.store;
      this.modelM.maintenanceMarkNo = [];
      this.modelM.maintenanceMarkNo = this.gridmarkno.listStore.store;
      this.modelM.maintenanceDesc = [];
      this.modelM.maintenanceDesc = this.griddesc2.listStore.store;
      this.modelM.maintenanceFreight = [];
      this.modelM.maintenanceFreight = this.gridnettcharge2.listStore.store;
      // console.log(this.modelM.maintenanceContainer);
      // console.log(this.modelM.maintenanceMarkNo);
      // console.log(this.modelM.maintenanceDesc);
      // console.log(this.modelM.maintenanceFreight);
      //this.modelM.officeCode = this.cookieService.getDefaultLocationCode();
      this.modelM.blShipperAddress = String(this.txtShipper.getValue()).trim().toUpperCase().replace("↵", "");
      this.modelM.blConsigneeAddress = String(this.txtConsignee.getValue()).trim().toUpperCase().replace("↵", "");
      this.modelM.blNotifyPartyAddress = String(this.txtNotify.getValue()).trim().toUpperCase().replace("↵", "");
      this.modelMark.bMarkAndNo = String(this.txtDescription.getValue().trim().toUpperCase().replace("↵", ""));

      this.genericService.POST('http://glossys.samudera.com:16001/BlMaintenance/save', this.modelM).subscribe((resp) => {

        this.modelM = new MasterBlHeader;


        // this.cbCountry.setValue("");
        this.modelDetail = new ContainerMaintenance;
        this.gridcontainer.listStore.store = [];
        this.gridcontainer.loadData();

        this.modelMark = new MarkNo;
        this.gridmarkno.listStore.store = [];
        this.gridmarkno.loadData();

        this.modelD = new Description;
        this.griddesc2.listStore.store = [];
        this.griddesc2.loadData();

        this.modelF = new FreightCharge;
        this.gridnettcharge2.listStore.store = [];
        this.gridnettcharge2.loadData();


        this.loading = false;
        this.info = 'save successfully';
      })
      this.cbShipper.setValue("");
      this.txtShipper.setValue("");
      this.cbConsignee.setValue("");
      this.txtConsignee.setValue("");
      this.cbNotify.setValue("");
      this.txtNotify.setValue("");
      this.cbReceiptPlace.setValue("");
      this.cbLoadPort.setValue("");
      this.cbDischargePort.setValue("");
      this.cbPODelivery.setValue("");
      this.cbFinalDestination.setValue("");
      this.cbPOT1.setValue("");
      this.cbService.setValue("");
      this.cbPrincipal.setValue("");
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.cbOceanVessel.setValue("");
      this.cbOceanVoyage.setValue("");
      this.cbVesselNext.setValue("");
      this.cbVoyageNext.setValue("");
      this.cbPrepaid1.setValue("");
      this.cbPrepaid2.setValue("");
      this.cbPrepaid3.setValue("");
      this.cbPayable1.setValue("");
      this.cbPayable2.setValue("");
      this.cbPayable3.setValue("");
      this.cbBillingParty1.setValue("");
      this.cbBillingParty2.setValue("");
      this.cbBillingParty3.setValue("");


      // let isValidation:boolean = this.onValidate(this.modelM);


      /* if(isValidation){


       if(!this.modelM.blNo && !this.modelM.blShipperId && !this.modelM.blConsigneeId && !this.modelM.blNotifyPartyId ){

       // console.log("test1");
       }else if(!this.modelM.blReceiptType && !this.modelM.blPlaceOfReceipt && !this.modelM.blLoadPort ){
       // console.log("test2");
       }else{
       // console.log("test3");
       this.saveEvent();
       }


       }else{
       this.loading = false;
       }*/
    }else{

    }

  }

  handlePrintInvoice() {

  }

  handlePrint() {

  }


  handleCancel() {
    this.modelM = new MasterBlHeader;
    this.modelDetail = new ContainerMaintenance;
    this.modelMark = new MarkNo;
    this.modelD = new Description;
    this.modelF = new FreightCharge;
    this.cbShipper.setValue('');
    //this.tsBooking = '';
    this.isLoad = false;
    //this.grid.setBody(this.param);
    //this.grid.loadData();
  }

  handlePrints() {

  }


  handleDelete() {
    this.genericService.POST('http://glossys.samudera.com:16001/BlMaintenance/delete', this.modelM).subscribe((resp) => {

      this.modelM = new MasterBlHeader;
      this.loading = false;
      this.info = 'save successfully';
    })


  }


  handleSync() {

  }

  forCreate() {
    this.modeFormDesc = true;
    this.lockD = false;
    this.modelD.blDescSeq = this.griddesc2.listStore.store.length+1;
    this.modelD.blDescSeq  = this.griddescription.listStore.store.length+1;
    this.lockSaveDesc = false;
    this.lockUpdateDesc = true;
    this.lockDeleteDesc = false;
    this.lockCancelDesc = false;
	  this.setValidatorDesc();


  }

  forUpdate() {

  }

  forCancel() {
    this.modeForm = false;

  }

  forDelete() {

  }


  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event: eve};
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  changeConsigneeTxt(event) {
    // this.param.parameterConsignee = event;

  }

  toOrder(event) {
    /*

     let URL_Retrieve = "";
     URL_Retrieve = "http://localhost:16001/BlMaintenance/getRetrieveCoba/1"; //+this.param.BlNo;
     this.genericService.GET(URL_Retrieve).subscribe((resp)=>{
     // console.log(resp.json()['bl_shipper_id']);
     this.cbShipper.setValue( resp.json()['bl_shipper_id']);
     this.cbNotify.setValue( resp.json()['bl_notify_party_id']);
     this.txtConsignee.setValue("sukses");

     })*/
    this.txtConsignee.setValue("To Order");
  }

  toOrderShipper(event) {
    this.txtConsignee.setValue(this.txtShipper.getValue());
    // console.log(this.txtConsignee.getValue());
  }

  toOrderCSGN(event) {
    this.txtNotify.setValue(String(this.txtConsignee.getValue()));
    // console.log(this.txtNotify.getValue());

  }

  toOrderSameAsCSGN(event) {
    this.txtNotify.setValue("As Consignee");
  }

  toNew() {

  }



  toNewMarkNo() {
    this.lockSaveMarkNo = false;
    this.lockNewMarkNo = true;
    this.lockUpdateMarkNo = true;
    this.lockDeleteMarkNo = true;
    this.lockCancelMarkNo = false;
    this.modelMark.bMarkSeq = this.gridmarkno.listStore.store.length+1;
    this.lockM=true;
    this.lock=true;


  }

  toDeleteMark() {

  }

  toCancelMark() {

  }

  toUpdateMark() {

  }

  toNoMark() {
   // this.setValidatorMarkNo();
    // console.log(this.modelMark);
    if(this.modelMark){

      //gini aja
      let isNoMarkExisted:boolean = false;
      if(this.gridmarkno.listStore.store.length > 0){
        for(let i:number=0; i < this.gridmarkno.listStore.store.length; i++){
          if(this.gridmarkno.listStore.store[i]["bMarkAndNo"] == "***"){
            isNoMarkExisted = true;
          }
        }

        if(!isNoMarkExisted){
          //masukin logic bikin no mark disini
          if(this.modelMark.no == 0){
            let data = new MarkNo();

            data.bMarkSeq = 1;
            data.bMarkAndNo = "***";

            this.gridmarkno.listStore.addData(data);
            this.gridmarkno.url='';
            this.gridmarkno.loadData();
            this.updateMarkNo();

            this.gridmarkno.clearSelectedValues();
            this.modelMark = new MarkNo;


          }else{

            let data = new MarkNo;
            data.no = this.modelMark.no;
            data.bMarkSeq = this.modelMark.bMarkSeq;
            data.bMarkAndNo = this.modelMark.bMarkAndNo;



            this.gridmarkno.listStore.updateData(this.modelMark.no-1 ,data);
            this.gridmarkno.url='';
            this.gridmarkno.loadData();
            this.gridmarkno.clearSelectedValues();
            this.modelMark = new MarkNo;


          }
        }else{
          //do nothing, atau print log kalau mau buat debug
          // console.log("test",this.modelMark);
        }
      }else{
        if(this.modelMark.no == 0){
          let data = new MarkNo();

          data.bMarkSeq = 1;
          data.bMarkAndNo = "***";

          this.gridmarkno.listStore.addData(data);
          this.gridmarkno.url='';
          this.gridmarkno.loadData();
          this.updateMarkNo();

          this.gridmarkno.clearSelectedValues();
          this.modelMark = new MarkNo;


        }else{

          let data = new MarkNo;
          data.no = this.modelMark.no;
          data.bMarkSeq = this.modelMark.bMarkSeq;
          data.bMarkAndNo = this.modelMark.bMarkAndNo;



          this.gridmarkno.listStore.updateData(this.modelMark.no-1 ,data);
          this.gridmarkno.url='';
          this.gridmarkno.loadData();
          this.gridmarkno.clearSelectedValues();
          this.modelMark = new MarkNo;


        }
        //masukin logic bikin no mark disini
      }





    }

  }
  updateMarkNo(){
    let index:number = 0;
    this.gridmarkno.listStore.store.forEach(element => {
      element['bMarkSeq']=element['no'];
      this.gridmarkno.listStore.updateData(index,element);
      index++;
    });
  }
  updateFreightCharge(){
    let index:number = 0;
    this.gridnettcharge2.listStore.store.forEach(element => {
      element['blFcSeq']=element['no'];
      this.gridnettcharge2.listStore.updateData(index,element);
      index++;
    });
  }

  toNewDescription() {

  }

  toNewClauseDescription() {

  }

  toUpdateClauseDescription() {

  }

  toDeleteClauseDescription() {

  }

  toCancelClauseDescription() {

  }
  toNewContainer(){
    this.lockNewContainer=true;
    this.lockSaveContainer=false;
    this.lockUpdateContainer=true;
    this.lockDeleteContainer=true;
    this.lockCancelContainer=false;
    this.modelDetail = new ContainerMaintenance;
    this.modelDetail.seqNo = this.gridcontainer.listStore.store.length+1;
    this.lock=false;
    this.cbbContainerReceiptType.setValue("CY");
    this.modelDetail.ReceiptType = 'CY';
    this.cbbContainerDeliveryType.setValue("CY");
    this.modelDetail.DeliveryType = 'CY';
    this.cbTypeContainer.setValue("STD");
    this.modelDetail.Type='STD';
    this.cbHeightContainer.setValue("86");
    this.modelDetail.Height=86;
    this.modelDetail.skipcheck="Y";

  }
  toSaveContainer() {
    this.setValidatorContainer();

    if(!this.onValidate(this.modelDetail)){

      if(this.modelDetail.no == 0){
        // console.log("if container");
        let data = new ContainerMaintenance;
        data.seqNo = Number(this.modelDetail.seqNo);
        data.ContainerNo = String(this.modelDetail.ContainerNo);
        data.SealNo1 = String(this.modelDetail.SealNo1);
        data.SealNo2 = String(this.modelDetail.SealNo2);
        data.SealNo3 = String(this.modelDetail.SealNo3);
        data.skipcheck = String(this.modelDetail.skipcheck);
        data.dry = String(this.modelDetail.dry);
        data.Shortship = String(this.modelDetail.Shortship);
        data.Empty = String(this.modelDetail.Empty);
        data.Qty = Number(this.modelDetail.Qty);
        data.PkgType = String(this.modelDetail.PkgType);
        data.GrossWt = Number(this.modelDetail.GrossWt);
        data.GrossMeas = Number(this.modelDetail.GrossMeas);
        data.ReceiptType = String(this.modelDetail.ReceiptType);
        data.DeliveryType = String(this.modelDetail.DeliveryType);
        //data.Shortship = String(this.modelDetail.Shortship);
        data.Type = String(this.modelDetail.Type);
        data.Size = String(this.modelDetail.Size);
        data.Height = Number(this.modelDetail.Height);

        this.gridcontainer.listStore.addData(data);
        this.gridcontainer.url = '';
        this.gridcontainer.loadData();
        this.updateSeqNo();

        this.gridcontainer.clearSelectedValues();
        this.modelDetail = new ContainerMaintenance;



      }else{
        // console.log("else container");
        let data = new ContainerMaintenance;
        // data.no = this.modelDetail.no;
        data.seqNo = this.modelDetail.seqNo;
        data.ContainerNo = this.modelDetail.ContainerNo;
        data.SealNo1 =this.modelDetail.SealNo1;
        data.SealNo2 =this.modelDetail.SealNo2;
        data.SealNo3 =this.modelDetail.SealNo3;
        data.Qty =this.modelDetail.Qty;
        data.PkgType =this.modelDetail.PkgType;
        data.GrossWt =this.modelDetail.GrossWt;
        data.GrossMeas =this.modelDetail.GrossMeas;
        data.ReceiptType =this.modelDetail.ReceiptType;
        data.DeliveryType =this.modelDetail.DeliveryType;
        data.skipcheck = this.modelDetail.skipcheck;
        data.dry = this.modelDetail.dry;
        data.Shortship = this.modelDetail.Shortship;
        data.Empty = this.modelDetail.Empty;
        // data.Shortship =this.modelDetail.Shortship;
        data.Type =this.modelDetail.Type;
        data.Size =this.modelDetail.Size;
        data.Height =this.modelDetail.Height;


        this.gridcontainer.listStore.updateData(this.modelDetail.seqNo-1 ,data);
        this.gridcontainer.url = '';
        this.gridcontainer.loadData();
        this.gridcontainer.clearSelectedValues();
        this.modelDetail = new ContainerMaintenance;



      }

    //  this.cbHeightContainer.setValue("86");

      //this.cbHeightContainer.setValue(this.modelDetail.Height);

      this.cbTypeContainer.setValue("");
      // console.log(this.cbTypeContainer.setValue(""));
      this.cbSizeContainer.setValue("");
      this.cbHeightContainer.setValue("");
      this.SkipCheckContainerNo = true;
      this.lockUpdateContainer = true;
      this.lockNewContainer = false;
      this.lockSaveContainer = true;
      this.lockDeleteContainer = false;
      this.lockCancelContainer = true;
      this.lock = true;


    }

  }

  toUpdateContainer(){
    this.cbbContainerReceiptType.setValue("CY");
    this.modelDetail.ReceiptType = 'CY';
    this.cbbContainerDeliveryType.setValue("CY");
    this.modelDetail.DeliveryType = 'CY';
    this.cbTypeContainer.setValue("STD");
    this.modelDetail.Type='STD';
    this.cbHeightContainer.setValue("86");
    this.modelDetail.Height=86;
    if(!this.onValidate(this.modelDetail)){

      if(this.modelDetail.seqNo == 0){
        let data = new ContainerMaintenance;
        data.seqNo = Number(this.modelDetail.seqNo);
        data.ContainerNo = String(this.modelDetail.ContainerNo);
        data.SealNo1 = String(this.modelDetail.SealNo1);
        data.SealNo2 = String(this.modelDetail.SealNo2);
        data.SealNo3 = String(this.modelDetail.SealNo3);
        data.skipcheck = String(this.modelDetail.skipcheck);
        data.dry = String(this.modelDetail.dry);
        data.Shortship = String(this.modelDetail.Shortship);
        data.Empty = String(this.modelDetail.Empty);
        data.Qty = Number(this.modelDetail.Qty);
        data.PkgType = String(this.modelDetail.PkgType);
        data.GrossWt = Number(this.modelDetail.GrossWt);
        data.GrossMeas = Number(this.modelDetail.GrossMeas);
        data.ReceiptType = String(this.modelDetail.ReceiptType);
        data.DeliveryType = String(this.modelDetail.DeliveryType);
        //data.Shortship = String(this.modelDetail.Shortship);
        data.Type = String(this.modelDetail.Type);
        data.Size = String(this.modelDetail.Size);
        data.Height = Number(this.modelDetail.Height);

        this.gridcontainer.listStore.addData(data);
        this.gridcontainer.url='';
        this.gridcontainer.loadData();
        this.updateSeqNo();

        this.gridcontainer.clearSelectedValues();
        this.modelDetail = new ContainerMaintenance;


      }else{

        let data = new ContainerMaintenance;
       // data.no = this.modelDetail.no;
        data.seqNo = this.modelDetail.seqNo;
        data.ContainerNo = this.modelDetail.ContainerNo;
        data.SealNo1 =this.modelDetail.SealNo1;
        data.SealNo2 =this.modelDetail.SealNo2;
        data.SealNo3 =this.modelDetail.SealNo3;
        data.Qty =this.modelDetail.Qty;
        data.PkgType =this.modelDetail.PkgType;
        data.GrossWt =this.modelDetail.GrossWt;
        data.GrossMeas =this.modelDetail.GrossMeas;
        data.ReceiptType =this.modelDetail.ReceiptType;
        data.DeliveryType =this.modelDetail.DeliveryType;
        data.skipcheck = this.modelDetail.skipcheck;
        data.dry = this.modelDetail.dry;
        data.Shortship = this.modelDetail.Shortship;
        data.Empty = this.modelDetail.Empty;
        // data.Shortship =this.modelDetail.Shortship;
        data.Type =this.modelDetail.Type;
        data.Size =this.modelDetail.Size;
        data.Height =this.modelDetail.Height;


        this.gridcontainer.listStore.updateData(this.modelDetail.seqNo-1 ,data);
        this.gridcontainer.url='';
        this.gridcontainer.loadData();
        this.gridcontainer.clearSelectedValues();
        this.modelDetail = new ContainerMaintenance;


      }
      this.lockUpdateContainer = true;
      this.lockNewContainer = false;
      this.lockSaveContainer = true;
      this.lockDeleteContainer = false;
      this.lockCancelContainer = true;
      this.lock = true;
    }
  }
  toDeleteContainer() {
    this.gridcontainer.getSelectedValues().forEach(element => {
      this.gridcontainer.listStore.removeData(element);
      this.gridcontainer.clearSelectedValues();
      this.lock = true;
      this.lockDeleteContainer = true;
      this.lockSaveContainer = true;
      this.lockUpdateContainer = true;
      this.lockNewContainer = false;
      this.lockCancelContainer = true;
      this.updateSeqNo();
      this.modelDetail.seqNo=0;
      this.modelDetail.ContainerNo="";
      this.modelDetail.SealNo1="";
      this.modelDetail.SealNo2="";
      this.modelDetail.SealNo3="";
      this.modelDetail.Qty=0;
      this.modelDetail.PkgType="";
      this.modelDetail.GrossWt=0;
      this.modelDetail.GrossMeas=0;
      this.modelDetail.ReceiptType="CY";
      this.modelDetail.DeliveryType="CY";
      this.modelDetail.Size="";
      this.modelDetail.Height=0;
      this.modelDetail.Type="";
    });



  }
  toCancelContainer() {
    this.modelDetail = new ContainerMaintenance;
    this.modelDetail.seqNo = this.gridcontainer.listStore.store.length+1;
    this.modelDetail.bContainerNo="";
    this.modelDetail.SealNo1="";
    this.modelDetail.SealNo2="";
    this.modelDetail.SealNo3="";
    //this.modelDetail.Qty ="";
    this.modelDetail.PkgType ="";
    this.modelDetail.seqNo=0;
    this.SkipCheckContainerNo = true;
    this.cbSizeContainer.setValue("");
    this.cbTypeContainer.setValue("STD");
    this.cbHeightContainer.setValue("86");



    this.lockSaveContainer=true;
    this.lockUpdateContainer=true;
    this.lockDeleteContainer=true;
    this.lockNewContainer=false;
    this.lock=true;

    // this.modelDetail.GrossWt ="";
    // this.modelDetail.GrossMeas ="";
    // this.modelDetail.ReceiptType ="";
    // this.modelDetail.DeliveryType ="";
    // data.Shortship =this.modelDetail.Shortship;
    // this.modelDetail.Type ="";
    // this.modelDetail.Size ="";
    // this.modelDetail.Height ="";

  }



  toSaveMarkNo(){
    this.setValidatorMarkNo();
    if(!this.onValidate(this.modelMark)){

      if(this.modelMark.no == 0){
        let data = new MarkNo;

        data.bMarkSeq = Number(this.modelMark.bMarkSeq);
        data.bMarkAndNo = String(this.modelMark.bMarkAndNo);


        this.gridmarkno.listStore.addData(data);
        this.gridmarkno.url='';
        this.gridmarkno.loadData();
        this.updateMarkNo();

        this.gridmarkno.clearSelectedValues();
        this.modelMark = new MarkNo;


      }else{

        let data = new MarkNo;
        data.no = this.modelMark.no;
        data.bMarkSeq = this.modelMark.bMarkSeq;
        data.bMarkAndNo = this.modelMark.bMarkAndNo;



        this.gridmarkno.listStore.updateData(this.modelMark.no-1 ,data);
        this.gridmarkno.url='';
        this.gridmarkno.loadData();
        this.gridmarkno.clearSelectedValues();
        this.modelMark = new MarkNo;


      }
      this.txtMarkNo.setValue("");
      this.lockNewMarkNo = false;
      this.lockSaveMarkNo = true;
      this.lockDeleteMarkNo = false;
      this.lockM = true;
    }
  }
  toUpdateMarkNo(){

    if(!this.onValidate(this.modelMark)){

      if(this.modelMark.no == 0){
        let data = new MarkNo;
        data.bMarkSeq = Number(this.modelMark.bMarkSeq);
        data.bMarkAndNo = String(this.modelMark.bMarkAndNo);


        this.gridmarkno.listStore.addData(data);
        this.gridmarkno.url='';
        this.gridmarkno.loadData();
        this.updateMarkNo();

        this.gridmarkno.clearSelectedValues();
        this.modelMark = new MarkNo;


      }else{

        let data = new MarkNo;
        data.no = this.modelMark.no;
        data.bMarkSeq = this.modelMark.bMarkSeq;
        data.bMarkAndNo = this.modelMark.bMarkAndNo;



        this.gridmarkno.listStore.updateData(this.modelMark.no-1 ,data);
        this.gridmarkno.url='';
        this.gridmarkno.loadData();
        this.gridmarkno.clearSelectedValues();
        this.modelMark = new MarkNo;


      }
      this.txtMarkNo.setValue("");
      this.lockNewMarkNo = false;
      this.lockSaveMarkNo = true;
      this.lockDeleteMarkNo = false;
      this.lockCancelMarkNo = false;
    }
  }
  toDeleteMarkNo(){
    // console.log("dihapus");
    this.gridmarkno.getSelectedValues().forEach(element => {
      this.gridmarkno.listStore.removeData(element);
      this.gridmarkno.clearSelectedValues();
      this.lockM=true;
      this.txtDescription.setValue("");
      this.updateMarkNo();
      this.lockSaveMarkNo = true;
      this.lockDeleteMarkNo = true;
      this.lockUpdateMarkNo = true;
      this.lockNewMarkNo = false;
    });


  }
  toCancelMarkNo(){
    this.lockD = true;
    this.modelMark.bMarkSeq = 0;
    this.txtMarkNo.setValue("");
    this.lockSaveMarkNo = true;
    this.lockUpdateMarkNo = true;
    this.lockDeleteMarkNo = true;
    this.lockNewMarkNo = false;
  }
  toSaveDesc(){
    this.setValidatorDesc();
    // console.log("test",this.modelD);
    this.cbReceiptTypeDesc.setValue("CY");
    this.modelD.blDescReceiptType='CY';
    this.cbDeliveryTypeDesc.setValue("CY");
    this.modelD.blDescDeliveryType='CY';

    if(!this.onValidate(this.modelD)){

      if(this.modelD.no == 0){
        let data = new Description;
        data.blDescSeq = Number(this.modelD.blDescSeq);
        data.blDescInnerQty = Number(this.modelD.blDescInnerQty);
        //data.blDescInnerPackageType = String(this.modelD.blDescInnerQty);
        data.blDescOuterQty = Number(this.modelD.blDescOuterQty);
        // data.blDescOuterPackageType = String(this.modelD.blDescOuterPackageType);
        // data.blDescPsaPackageType  = String(this.modelD.blDescPsaPackageType);
        data.blDescGrossW  = Number(this.modelD.blDescGrossW);
        data.blDescNettW  = Number(this.modelD.blDescGrossW);
        data.blDescription  = String(this.modelD.blDescription);
        data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);
        data.blDescNettMeas  = Number(this.modelD.blDescNettMeas);
        data.blDescReceiptType = String(this.modelD.blDescReceiptType);
        data.blDescDeliveryType = String(this.modelD.blDescDeliveryType);
        //data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);


        this.griddesc2.listStore.addData(data);
        this.griddescription.listStore.addData(data);
        this.griddesc2.url='';
        this.griddescription.url='';
        this.griddesc2.loadData();
        this.griddescription.loadData();
        this.updateDescNo();

        this.griddesc2.clearSelectedValues();
        this.griddescription.clearSelectedValues();
        this.modelD = new Description;


      }else{

        let data = new Description;
        data.no = this.modelD.no;
        data.blDescSeq = Number(this.modelD.blDescSeq);
        data.blDescInnerQty = Number(this.modelD.blDescInnerQty);
        //data.blDescInnerPackageType = String(this.modelD.blDescInnerQty);
        data.blDescOuterQty = Number(this.modelD.blDescOuterQty);
        // data.blDescOuterPackageType = String(this.modelD.blDescOuterPackageType);
        // data.blDescPsaPackageType  = String(this.modelD.blDescPsaPackageType);
        data.blDescription  = String(this.modelD.blDescription);
        data.blDescGrossW  = Number(this.modelD.blDescGrossW);
        data.blDescNettW  = Number(this.modelD.blDescGrossW);
        data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);
        data.blDescNettMeas  = Number(this.modelD.blDescNettMeas);
        data.blDescReceiptType = String(this.modelD.blDescReceiptType);
        data.blDescDeliveryType = String(this.modelD.blDescDeliveryType);
        //data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);



        this.griddesc2.listStore.updateData(this.modelD.no-1 ,data);
        this.griddesc2.url='';
        this.griddesc2.loadData();
        this.griddesc2.clearSelectedValues();
        this.modelD = new Description;


      }
      //this.txtMarkNo.setValue("");
      this.lockDeleteDesc = true;
      this.lockSaveDesc = true;
      this.lockUpdateDesc = true;
      this.txtDescription.setValue("");
      this.lockD = true;
      this.modeFormDesc = false;
    }
  }
  toUpdateDesc(){
    this.cbReceiptTypeDesc.setValue("CY");
    this.modelD.blDescReceiptType='CY';
    this.cbDeliveryTypeDesc.setValue("CY");
    this.modelD.blDescDeliveryType='CY';

    if(!this.onValidate(this.modelD)) {

      if (this.modelD.no == 0) {
        let data = new Description;
        data.blDescSeq = Number(this.modelD.blDescSeq);
        data.blDescInnerQty = Number(this.modelD.blDescInnerQty);
        //data.blDescInnerPackageType = String(this.modelD.blDescInnerQty);
        data.blDescOuterQty = Number(this.modelD.blDescOuterQty);
        data.blDescription = String(this.modelD.blDescription);
        // data.blDescOuterPackageType = String(this.modelD.blDescOuterPackageType);
        // data.blDescPsaPackageType  = String(this.modelD.blDescPsaPackageType);
        data.blDescGrossW = Number(this.modelD.blDescGrossW);
        data.blDescNettW = Number(this.modelD.blDescGrossW);
        data.blDescGrossMeas = Number(this.modelD.blDescGrossMeas);
        data.blDescNettMeas = Number(this.modelD.blDescNettMeas);
        data.blDescReceiptType = String(this.modelD.blDescReceiptType);
        data.blDescDeliveryType = String(this.modelD.blDescDeliveryType);
        //data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);

        this.griddesc2.listStore.addData(data);
        this.griddescription.listStore.addData(data);
        this.griddesc2.url='';
        this.griddescription.url='';
        this.griddesc2.loadData();
        this.griddescription.loadData();
        this.updateDescNo();

        this.griddesc2.clearSelectedValues();
        this.griddescription.clearSelectedValues();
        this.modelD = new Description;


      } else {

        let data = new Description;
        data.no = this.modelD.no;
        data.blDescSeq = Number(this.modelD.blDescSeq);
        data.blDescInnerQty = Number(this.modelD.blDescInnerQty);
        //data.blDescInnerPackageType = String(this.modelD.blDescInnerQty);
        data.blDescOuterQty = Number(this.modelD.blDescOuterQty);
        data.blDescription = String(this.modelD.blDescription);
        // data.blDescOuterPackageType = String(this.modelD.blDescOuterPackageType);
        // data.blDescPsaPackageType  = String(this.modelD.blDescPsaPackageType);
        data.blDescGrossW = Number(this.modelD.blDescGrossW);
        data.blDescNettW = Number(this.modelD.blDescGrossW);
        data.blDescGrossMeas = Number(this.modelD.blDescGrossMeas);
        data.blDescNettMeas = Number(this.modelD.blDescNettMeas);
        data.blDescReceiptType = String(this.modelD.blDescReceiptType);
        data.blDescDeliveryType = String(this.modelD.blDescDeliveryType);
        //data.blDescGrossMeas  = Number(this.modelD.blDescGrossMeas);

        this.griddesc2.listStore.updateData(this.modelD.no - 1, data);
        this.griddescription.listStore.updateData(this.modelD.no-1,data);
        this.griddesc2.url='';
        this.griddescription.url='';
        this.griddesc2.loadData();
        this.griddescription.loadData();
        this.griddesc2.clearSelectedValues();
        this.griddescription.clearSelectedValues();
        this.modelD = new Description;


      }
      //this.txtMarkNo.setValue("");
      this.lockDeleteDesc = true;
      this.lockSaveDesc = false;
      this.lockCancelDesc = false;
      this.lockD = true;
      this.txtDescription.setValue(" ");
    }
  }
  toDeleteDesc(){
    this.griddesc2.getSelectedValues().forEach(element => {
      this.griddesc2.listStore.removeData(element);
      //this.griddesc2.loadData();
      this.griddesc2.clearSelectedValues();
      this.updateDescNo();
    });

  }
  toCancelDesc(){
    this.modeFormDesc = false;
    //this.lockD = false;
  }

  updateSeqNo(){
    let index:number = 0;
    this.gridcontainer.listStore.store.forEach(element => {
      element['seqNo']=element['no'];
      this.gridcontainer.listStore.updateData(index,element);
      index++;
    });

  }
  updateDescNo(){
    let index:number = 0;
    this.gridcontainer.listStore.store.forEach(element => {
      element['blDescSeq']=element['no'];
      this.gridcontainer.listStore.updateData(index,element);
      index++;
    });

  }








  toNettNew() {
    this.setValidatorFreight();
    // console.log(this.modelM);



    if(!this.onValidate(this.modelM)) {
      this.modeFormNett = true;
      this.modelF.blFcSeq = this.gridnettcharge2.listStore.store.length + 1;
      this.lockF = false;
      this.lockUpdateFc = true;
      this.lockDeleteFc = true;
      this.lockCancelFc = false;
      this.lockSaveFc = false;
    }
	//this.setValidatorNetCharge();
   // this.cbPC.listStore.addData(this.modelP);
  }

  toNettInsert() {
    this.modeForm = true;
  }

  toNettDelete() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve':
        this.handleRetrieve();
        break;
      case 'PartialSave':
        this.handlePartialSave();
        break;
      case 'Save':
        this.handleSave();
        break;
      case 'Print':
        this.handlePrint();
        break;
      case 'PrintInvoice':
        this.handlePrintInvoice();
        break;
      case 'Cancel' :
        this.handleCancel();
        break;
      case 'Close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case 'Delete' :
        this.handleDelete();
        break;
      case 'Sync' :
        this.handleSync();
        break;
      case 'Prints':
        $('#loader').css({'z-index': '50', 'margin-left': '19px', 'width': 'calc(100% - 40px)'}).dimmer('show');
        break;
      default:
        let str: string = event;
        // console.log('str = ' + str);
        break;
    }
  }

  toCancel(){}
}

