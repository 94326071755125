import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Clause, GridPluginComponent2 } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  TextareaPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-master-clause-page",
  templateUrl: "./master-clause-page.component.html",
  styleUrls: ["./master-clause-page.component.css"],
})
export class MasterClausePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent2;
  @ViewChild("cbClauseNameHistory") cbClauseNameHistory: ComboPluginComponent;
  @ViewChild("txtClauseName") txtClauseName: TextareaPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = true;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // * Form Validation
  formValidation = new FormValidation();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingClauseLogStartDate;
  //settingClauseLogEndDate;
  settingClauseHistory;
  settingClauseName;

  /* Misc Parameters */
  valClauseCode: string = "";
  valClauseName: string = "";
  valClauseId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  officeCode: string = "";

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  typeClauseLogStartDate = "calendar";
  typeClauseLogEndDate = "calendar";

  /* Parameter model */
  model = new Clause();
  //selectList = [];

  loading = false;

  lock = false;

  /* validatorRules */
  validatorRules = {
    clauseName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Clause Name.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[\s\r\na-zA-Z0-9-(.)/&',↵]*$/,
          prompt: "Invalid Clause Name, only allow character -()/,.&',",
        },
      ],
    },
    clauseCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Clause Code.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9]*$/,
          prompt: "Invalid Clause Code",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAllChar,
          prompt: "Invalid Remarks",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Clause Code", value: "clauseCode" },
        { display: "Clause Name", value: "clauseName" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      //url: this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneral',
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Clause Name",
          field: "clauseName",
          width: 200,
          type: "string",
        },
        {
          header: "Clause Code",
          field: "clauseCode",
          width: 50,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "clauseName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      id: "clauseHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterClauses/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Clause Id", field: "clauseId", width: 200 },
        {
          header: "Clause Name",
          field: "clauseName",
          width: 200,
          type: "string",
        },
        {
          header: "Clause Code",
          field: "clauseCode",
          width: 50,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
     
      enablePagination: true,
    };

    this.settingClauseHistory = {
      id: "cbClauseHistory",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneralHistory/{query}',
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "clauseName",
      description: "",
      isMark: true,
      columns: [
        { header: "Clause Id", field: "clauseId", width: 100 },
        {
          header: "Clause Name",
          field: "clauseName",
          width: 250,
          type: "string",
        },
        {
          header: "Clause Code",
          field: "clauseCode",
          width: 100,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 150, type: "string" },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingClauseName = {
      id: "txtClauseName",
      name: "clauseName",
      placeholder: "Clause Name",
      cols: 30,
      rows: 10,
      maxlength: 255,
      border: 400,
    };

    /*
     this.settingClauseLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingClauseLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search=this.officeCode+'/*/*/*';
    this.grid.url =
      this.configService.config.BASE_API.toString() +
      "/MasterClauses/findGeneral/" +
      this.officeCode;
    this.grid.loadData();

    this.cbClauseNameHistory.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterClauses/findGeneralHistory/" +
        this.officeCode +
        "/{query}"
    );
    //this.grid.onFirst();

    this.modeForm = false;

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  onInput(event) {
    this.model["error-clauseName"] = null;
    this.txtClauseName.error = false;
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      // case 'afterLoad':
      //   this.grid.url = this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneral';
      //   break;
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons = "update,delete";
                } else {
                  this.disableToolbarButtons = "";
                }
                this.invisibleToolbarButtons = "create,history,print";

                this.modeForm = true;
                this.modeToolbar = false;
                this.setData();
                this.lock = true;
                this.txtClauseName.disabled = true;
                this.txtClauseName.error = false;
              }
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.clauseId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          //this.grid.search = this.officeCode+'/'+str.replace("search:","");
          this.grid.search = str.replace("search:", "");

          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.txtClauseName.disabled = false;
    this.txtClauseName.error = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Clause();
    //this.model.clauseName = '12345678901234567890123456789012\r34567890123456789012345678901234\r567\r\r234 345234 234123 12312 121 123 \r1231';
    this.valClauseCode = "";
    this.valClauseName = "";
    this.txtClauseName.setValue("");
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.txtClauseName.disabled = false;
      this.txtClauseName.error = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event

    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      var deleteObj = {"clauseId":"","clauseCode":""};
      deleteObj.clauseId = element.clauseId;
      deleteObj.clauseCode = element.clauseCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListClauseUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            
            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
            
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );

    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    // window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/11/Clauses-Export-Reports/*');
    var formatName = "report-Clauses-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/11/Clauses-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // window.open(
    //   "http://glossys.samudera.com:10000/Mreport/runReport/11/Clauses-Export-Reports/*"
    // );
    // handle print event
  }

  /*
  insert(index:number, char:string, data:string) {
    var idx:number = index;
    if (idx > 0) {
      return data.substring(0, idx) + char + data.substring(idx, data.length);
    } else {
      return char + data;
    }
  };
  */

  handleSave() {
    // handle save event
    // console.log('handleSave');
    this.model.clauseName = this.txtClauseName.getValue();

    /*
    var self = this;
    $("#frmTextarea").submit(function(event) {
       var value = $(this).serialize().replace(/%20/g," ").replace(/%0D%0A/g,"↵");
       var txt = value.substring(11,value.length);

       var lines = txt.split("↵");

       for (var x = 0; x < lines.length; x++) {

           var counter:number = 1;
           var limit:number = 32;

           for (var i = 0; i < lines[x].length; i++) {
             if((i % limit) === 0){
                if(counter == 1){
                  lines[x] = self.insert(limit,"↵",lines[x]);
                }else{
                  lines[x] = self.insert(limit + 1 ,"↵",lines[x]);
                }
                counter = counter + 1;
                limit = limit * counter;
             }
           }

           // console.log(lines[x]);

       }

       var result:string = lines.join("↵");
       // console.log('result = ' + result);
       self.model.clauseName = result;

       event.preventDefault();

    });
    $("#frmTextarea").submit();
    */

    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log(this.model['error-clauseName']);
    if (this.model["error-clauseName"] != "") {
      this.txtClauseName.error = true;
    } else {
      this.txtClauseName.error = false;
    }
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new Clause();
    this.txtClauseName.error = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    // if(this.valClauseId != '') {
    this.valClauseCode = "";
    this.valClauseId = "";

    this.cbClauseNameHistory.setValue("");
    this.cbClauseNameHistory.close();

    this.txtClauseName.setValue("");
    this.gridHistory.onHistory("NaN");
    this.gridHistory.loadData();
    // }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.url =
      this.configService.config.BASE_API.toString() +
      "/MasterClauses/findGeneral/" +
      this.officeCode;
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new Clause();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.clauseId = String(data.clauseId).trim().toUpperCase();
    this.model.clauseCode = String(data.clauseCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.clauseName = String(data.clauseName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.txtClauseName.setValue(this.model.clauseName);
    this.model.remarks = String(data.remarks)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.officeCode = this.officeCode;
  }

  valueClauseHistory(event) {
    if (event["clauseCode"] != null) {
      this.valClauseCode = event["clauseCode"];
      this.valClauseId = event["clauseId"];
      // console.log("curr code= " + event["clauseCode"]);

      let tmp = String(event["clauseCode"])
        .toUpperCase()
        .replace(/&NBSP;/g, " ");
      this.valClauseCode = tmp;

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valClauseId);
    }
  }

  generateClauseHistoryReport(event: any) {
    //this.valHistoryStartDate= $('#logHistoryClauseStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryClauseEndDate').find('#datePicker').val();

    if (this.valClauseId == "" || this.valClauseId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      // window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/12/History-Clause-' + this.valClauseId  + '/'+this.valClauseId);
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000")  +"/Mreport/runReport/12/History-Clause-" +
      //     this.valClauseId +
      //     "/" +
      //     this.valClauseId
      // );
      var formatName = "report-History-Clause-"+this.valClauseId+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/12/History-Clause-" +
        this.valClauseId +
        "/" +
        this.valClauseId
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
    }
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    this.model.officeCode = this.officeCode;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterClauses/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new Clause();
            this.txtClauseName.setValue("");
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.clauseId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"clauseId":[]}
    paramsDelete.clauseId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + 
        // "/MasterClauses/delete",
        // deleteList
        "/MasterClauses/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new Clause();
            this.txtClauseName.setValue("");
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  // * Field Validation Regex
  regClauseName = /^[\s\r\na-zA-Z0-9-(.)/&',↵]*$/;

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar: string = ""
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      if (field === "clauseName") {
        this.txtClauseName.error = false;
      }

      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }
}
