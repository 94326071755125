export class BLHeader {
  // ** party ** //
  blOfficeCode: string = "";
  blNo: string = "";
  blShipperDraftBlNo: string = "";
  blShipperDraftBlNoEntry: string = "";
  blType: string = "N";
  blStatus: string = "N";
  blShipperId: string = "";
  blShipperName: string = "";
  blShipperAddressText: string = "";
  blShipperAddressOption: number = 0;
  blShipperDraftBlNoSkipPrint: boolean = false;
  blConsigneeId: string = "";
  blConsigneeName: string = "";
  blConsigneeAddressText: string = "";
  blConsigneeAddressOption: number = 0;
  blNotifyPartyId: string = "";
  blNotifyPartyName: string = "";
  blNotifyPartyAddressText: string = "";
  blNotifyPartyAddressOption: number = 0;
  blShipperEdiReference: string = "";

  blBookingContainerStatus: string = "";
  blBookingOfficeCode: string = "";
  blBookingPrefix: string = "";
  blBookingNo: string = "";
  blBookingFreightType: string = "";
  blBookingFreightParty: string = "";
  blBookingFreightPartyName: string = "";
  blBookingDetention: string = "";
  blBookingDetentionRequest: number = 0;
  blBookingDemurrage: string = "";
  blBookingDemurrageRequest: number = 0;
  blShipperBookingNo: string = "";
  blDgNos : string = "";
  blOperatorCode: string = "";
  blSOOperatorCode: string = "";
  blDetention: string = "";
  blDetentionRequest: number = 0;
  blDemurrage: string = "";
  blDemurrageRequest: number = 0;
  blContainerOwnership: string = "";
  blContainerStatus: string = "";
  blDgClass: string = "";
  blIsNvocc: string = "N";
  blGST: boolean = true;
  // ** end party ** //

  // ** place ** //
  blReceiptTypeCode: string = "";
  blDeliveryTypeCode: string = "";
  blPlaceOfReceiptCode: string = "";
  blLoadPortCode: string = "";
  blDischargePortCode: string = "";
  blPlaceOfDeliveryCode: string = "";
  blFinalDestinationCode: string = "";
  blPortOfTransshipment1Code: string = "";
  blPortOfTransshipment2Code: string = "";
  blPortOfTransshipment3Code: string = "";
  blPlaceOfReceiptPrint: string = "";
  blLoadPortPrint: string = "";
  blDischargePortPrint: string = "";
  blPlaceOfDeliveryPrint: string = "";
  blFinalDestinationPrint: string = "";
  blPortOfTransshipment1Print: string = "";
  blServiceId: string = "";
  blCommodityId: string = "";
  blPrincipalId: string = "";
  blPreVesselSkipCheck: boolean = false;
  blPreVesselId: string = "";
  blPreVesselCode: string = "";
  blPreVesselName: string = "";
  blPreVesselVoyage: string = "";
  blPreVesselBound: string = "";
  blPreVesselIsPrinted: boolean = false;
  blPreVesselIsClausePrintedOnBl: boolean = false;
  blOceanVesselId: string = "";
  blOceanVesselCode: string = "";
  blOceanVesselName: string = "";
  blOceanVesselEtd: string = "";
  blOceanVesselVoyage: string = "";
  blOceanVesselBound: string = "";
  blOceanVesselIsClausePrintedOnBl: boolean = false;
  blNextVesselSkipCheck: boolean = false;
  blNextVesselId: string = "";
  blNextVesselCode: string = "";
  blNextVesselName: string = "";
  blNextVesselVoyage: string = "";
  blNextVesselBound: string = "";
  blNextVesselClauseIsPrinted: boolean = false;
  blNextVesselPrintedAsOceanVessel: boolean = false;
  blPreVesselFooterOnBl: Boolean = false;
  blOceanVesselFooterOnBl: Boolean = true;
  blNextVesselFooterOnBl: Boolean = false;
  blContainerType: string = "";
  // ** end place ** //

  noOfSet = "1";

  // ** desc start ** //
  blTotalContainerPackageInWord: string = "";
  blDeclaredValue: number = 0;
  blIsIrePermit: boolean = false;
  blIsSingleConsigneePermit: boolean = false;
  blIsPrintAgentInfoOnBl: boolean = false;
  blAgentNameAndAddress: string = "";
  blExporterTaxNumber: string = "";
  blExporterTaxNumberPrint: boolean = false;
  blImporterTaxNumber: string = "";
  blImporterTaxNumberPrint: boolean = false;
  //  ** end desc ** //

  // ** Nett Charge ** //
  blIsFreightAsArranged: boolean = false;
  blIsPrintLoadingVesselVoyageClause: boolean = false;
  blIsPrintShippedOnBoard: boolean = true;
  blIsExchangeRateLock: boolean = true;
  blPlaceOfIssueCode: string = "";
  blPlaceOfIssueName: string = "";
  blFreightType: string = "NETT";
  blExchangeRateType: string = "NORMAL";
  blNoOfOBL: number = 3;
  blFreightCoveredInBlNo: string = "";
  blDateOfIssue: string = "";
  blDateOfIssueLock: boolean = true;
  blExchangeRateDate: string = "";
  blPrepaidAt1Code: string = "";
  blPrepaidAt1Name: string = "";
  blPrepaidAt2Code: string = "";
  blPrepaidAt2Name: string = "";
  blPrepaidAt3Code: string = "";
  blPrepaidAt3Name: string = "";
  blPayableAt1Code: string = "";
  blPayableAt1Name: string = "";
  blPayableAt2Code: string = "";
  blPayableAt2Name: string = "";
  blPayableAt3Code: string = "";
  blPayableAt3Name: string = "";
  blPayer1Code: string = "";
  blPayer1Name: string = "";
  blPayer2Code: string = "";
  blPayer2Name: string = "";
  blPayer3Code: string = "";
  blPayer3Name: string = "";
  blRemarks: string = "";
  // ** end Nett Charge ** //

  // ** Nett Charge Console ** //
  blIsFreightAsArrangedConsole: boolean = false;
  blIsPrintLoadingVesselVoyageClauseConsole: boolean = false;
  blIsPrintShippedOnBoardConsole: boolean = true;
  blIsExchangeRateLockConsole: boolean = false;
  blPlaceOfIssueCodeConsole: string = "";
  blPlaceOfIssueNameConsole: string = "";
  blFreightTypeConsole: string = "NETT";
  blExchangeRateTypeConsole: string = "NORMAL";
  blNoOfOBLConsole: number = 3;
  blFreightCoveredInBlNoConsole: string = "";
  blDateOfIssueConsole: string = "";
  blDateOfIssueLockConsole: boolean = false;
  blExchangeRateDateConsole: string = "";
  blPrepaidAt1CodeConsole: string = "";
  blPrepaidAt1NameConsole: string = "";
  blPrepaidAt2CodeConsole: string = "";
  blPrepaidAt2NameConsole: string = "";
  blPrepaidAt3CodeConsole: string = "";
  blPrepaidAt3NameConsole: string = "";
  blPayableAt1CodeConsole: string = "";
  blPayableAt1NameConsole: string = "";
  blPayableAt2CodeConsole: string = "";
  blPayableAt2NameConsole: string = "";
  blPayableAt3CodeConsole: string = "";
  blPayableAt3NameConsole: string = "";
  blPayer1CodeConsole: string = "";
  blPayer1NameConsole: string = "";
  blPayer2CodeConsole: string = "";
  blPayer2NameConsole: string = "";
  blPayer3CodeConsole: string = "";
  blPayer3NameConsole: string = "";
  blRemarksConsole: string = "";
  blNewRemarks: string = "";
  // ** end Nett Charge Console ** //

  // ** Misc ** //
  blSpecialInstruction: string = "";

  blIsAgentDetailPrint: string = "";
  blAgentAddressId: string = "";
  blDescAgentRemark: string = "";

  blIsSplit: string = "";
  blSplitKey: string = "";
  blSplitGenerateType: string = "";
  blSplitMethodType: string = "";
  blIsCombine: string = "";
  blCombineKey: string = "";
  blIsPrintFreightList: string = "N";
  blIsConfidentialChargePrinted: string = "N";
  blExchangeRateMethod: string = "";
  blIsChecklistPrinted: string = "N";
  blIsBlPrinted: string = "N";
  blPrintedUserid: string = "";
  blPrintedDate: string = "";
  blIsManifestPrinted: string = "N";
  blManifestPrintedUserid: string = "";
  blManifestPrintedDate: string = "";
  blIsTransmittedToCredo: string = "N";
  blTransmittedToCredoUserid: string = "";
  blTransmittedToCredoDate: string = "";
  blIsFreightManifestPrinted: string = "";
  blFreightManifestPrintedUserid: string = "";
  blFreightManifestPrintedDate: string = "";
  blIsFinished: boolean = false;
  blFinishedUserid: string = "";
  blFinishedDate: string = "";
  blIsArrivalNoticePrinted: string = "N";
  blArrivalNoticePrintedDate: string = "";
  blIsDeliveryOrderPrinted: string = "N";
  blIsTransmittedToPsa: string = "N";
  blTransmittedToPsaUserid: string = "";
  blTransmittedToPsaDate: string = "";
  blIsTransmittedToCoda: string = "N";
  blTransmittedToCodaUserid: string = "";
  blTransmittedToCodaDate: string = "";
  blIsPostedToCoda: string = "N";
  blPostedToCodaUserid: string = "";
  blPostedToCodaDate: string = "";
  blIsLocked: string = "";
  blFreightCoveredInCtmNo: string = "";
  blInvoiceNo: string = "";
  blIsInvoicePrinted: string = "N";
  blInvoicePrintedUserid: string = "";
  blInvoicePrintedDate: string = "";
  blShortShipPrint: boolean = false;
  blShortShipBlNo: string = "";
  blShortShipContainerNo: string = "";
  blShortShipVesselId: string = "";
  blShortShipVesselName: string = "";
  blShortShipVesselCode: string = "";
  blShortShipVesselVoyage: string = "";
  blShortShipVesselBound: string = "";
  blUserCreated: string = "";
  blDateCreated: string = "";
  blUserModified: string = "";
  blDateModified: string = "";
  blUserDeleted: string = "";
  blDateDeleted: string = "";
  blSplitNo: string = "";

  // ** End Misc ** //

  blMarkNo: BLMarkNo[] = [];
  blDesc: BLDesc[] = [];
  blDescClause: BLDescClause[] = [];
  blContainer: BLContainer[] = [];
  blNettCharge: BLFreightCharge[] = [];
  blNettChargeConsole: BLFreightCharge[] = [];
  blExchangeRate: BLExchangeRate[] = [];

  oceanVessel: string = "";
  nextVessel: string = "";
  preVessel: string = "";
  periodFrom: string = "";
  periodTo: string = "";
  year: string = "";
  weekName: string = "";

  // place resolver
  blPlaceOfReceiptName: string = "";
  blLoadPortName: string = "";
  blDischargePortName: string = "";
  blPlaceOfDeliveryName: string = "";
  blFinalDestinationName: string = "";
  blPortOfTransshipment1Name: string = "";
  blPortOfTransshipment2Name: string = "";
  blPortOfTransshipment3Name: string = "";

  // used by hack pre vessel
  blBookingExMotherVesselId: string = "";
  blBookingExMotherVesselCode: string = "";
  blBookingExMotherVesselVoyage: string = "";
  // end hac

  payerValidator: Boolean = false;
  popValidator: Boolean = false;

  //pop
  blFcPop: string = "";
  locationName: string = "";

  //flag QTY Changed
  bkgQtyChanged : string = "N";


  //BL Header Extra
  blExtraOfficeCode: string = "";
  blExtraBlNo: string = "";
  blExtraPayer1nameAddressOption: number = 0;
  blExtraPayer2nameAddressOption: number = 0;
  blExtraPayer3nameAddressOption: number = 0;
  blExtraPayer1nameAddressName: string  = "";
  blExtraPayer2nameAddressName: string  = "";
  blExtraPayer3nameAddressName: string  = "";
  blExtraShippedOnBoard: string = "";
  blExtraShippedOnBoardLock: boolean = true;

  //detention and demurrage pol
  blDetentionPol: string = "";
  blDetentionRequestPol: number = 0;
  blDemurragePol: string = "";
  blDemurrageRequestPol: number = 0;
  blFreightCharge = [];

  // GOS-632 // To add an additional 'THRU BL Ref’ field under BL maintenance screen.
  blThruBLRef: string = "";
  thruOfficeCode: any;

  //GSO-1003 Additional field of "Customer's Internal Order #"
  blCustomerInternalOrder1 = "";
  blCustomerInternalOrder2 = "";
  blCustomerInternalOrder3 = "";

  blIsDetDemPrinted = "N";

  // GOS-854
  nextVesselVoyage = "";

  // GOS-855
  preVesselId = "";
  oceanVesselId = "";
  nextVesselId = "";

  // GOS-859
  confirmedStatus = "N";

  constructor() {}
}

export class BLContainer {
  blContainerOfficeCode: string = "";
  blContainerBlNo: string = "";
  blContainerSeq: number = 0;
  blContainerNumber: string = "";
  blContainerSealNo1: string = "";
  blContainerSealNo2: string = "";
  blContainerSealNo3: string = "";
  blContainerQuantity: number = 0;
  blContainerPackageTypeCode: string = "";
  blContainerPackageTypeName: string = "";
  blContainerGrossWeight: any = 0;
  blContainerGrossMeasure: any = 0;
  blContainerReceiptTypeCode: string = "";
  blContainerDeliveryTypeCode: string = "";
  blContainerQ1: string = "";
  blContainerQ2: string = "";
  blContainerQ3: string = "";
  blContainerType: string = "";
  blContainerTypeName: string = "";
  blContainerSize: string = "";
  blContainerSizeName: string = "";
  blContainerHeight: string = "";
  blContainerHeightName: string = "";
  blContainerIsEmpty: boolean = false;
  blContainerIsEmptyString: string = "N";
  blContainerSkipCheck: boolean = true;
  blContainerSkipCheckString: String = "Y";
  blContainerShortShipContainer: boolean = false;
  blContainerShortShipContainerString: string = "N";
  blContainerIsDry: boolean = false;
  blContainerIsDryString: string = "N";
  blContainerLotNo: string = "";
  blContainerToDesc: string = "";
  blContainerCreatedUserid: string = "";
  blContainerCreatedDate: string = "";
  blContainerModifiedUserid: string = "";
  blContainerModifiedDate: string = "";

  blContainerDGClass: string = "";
  blContainerIMO: string = "";
  blContainerUNNO: string = "";
  blContainerFlashPointTemperature: number = 0.0;
  blContainerFlashPointScale: string = "";
  blContainerReeferTemperature: number = 0.0;
  blContainerReeferScale: string = "";
  blContainerOogOH: number = 0.0;
  blContainerOogOWL: number = 0.0;
  blContainerOogOWR: number = 0.0;
  blContainerOogOLF: number = 0.0;
  blContainerOogOLB: number = 0.0;
  blContainerOogScale: string = "";

  blContainerSpecialDetailCode: string = "";
  blContainerSpecialDetailName: string = "";

  blContainerIsPrintShortShipmentInfoOnBL: boolean = false;
  blContainerShortShipmentFrom: boolean = false;
  blContainerShortShipmentTo: boolean = false;
  blContainerShortShipmentBlRef: string = "";
  blContainerShortShipmentVesselId: string = "";
  blContainerShortShipmentVesselCode: string = "";
  blContainerShortShipmentVesselName: string = "";
  blContainerShortShipmentVesselVoyage: string = "";
  blContainerShortShipmentVesselBound: string = "";
  blContainerSpecialTypeName: string = "";

  // blContainerQuantityString: string     = '0';
  blContainerGrossWeightString: any  = '0';
  blContainerGrossMeasureString: any = '0';
  blContainerNettWeightString: any = '0';
  blContainerNettMeasurementString: any = '0';
  blContainerNettMeasureString: any = '0';

  blContainerExtra: BLContainerExtra;
  blContainerHSCode: BLContainerHSCode[] = [];
  blAdContainer: BLAdContainer[] = [];

  UchMeasurementsValidator: boolean = false;
  UclMeasurementsValidator: boolean = false;
  UcwMeasurementsValidator: boolean = false;

  blContainerVgm: number = 0;
  blContainerNettWeight: number = 0;
  blContainerNettMeasure: number  = 0;
  no: number = 0;

  // GSO-992 - GSO-993 Logic, Additional Checkbox for "Reworked Container"
  blContainerIsReworkedContainer: boolean = false;
  blContainerIsPrintReworkedInfoOnBL: boolean = false;
  blContainerReworkedTo : boolean = false;
  blContainerReworkedFrom : boolean = false;
  blContainerReworkedContainerNumber = "";
  blContainerReworkedBlRef = "";
  blContainerReworkedVesselId = "";
  blContainerReworkedVesselCode = "";
  blContainerReworkedVesselVoyage = "";
  blContainerReworkedVesselName = "";
  blContainerReworkedBound = "";

  constructor() {}
}

export class BLMarkNo {
  blMarkNoOfficeCode: string = "";
  blMarkNoBlNo: string = "";
  blMarkNoSeq: number = 0;
  blMarkNoDescription: string = "";
  blMarkNoDescriptionClean: string = "";
  blMarkNoCreatedUserid: string = "";
  blMarkNoCreatedDate: string = "";
  blMarkNoModifiedUserid: string = "";
  blMarkNoModifiedDate: string = "";
  constructor() {}
}

export class BLDesc {
  blDescOfficeCode: string = "";
  blDescBlNo: string = "";
  blDescSeq: number = 0;
  blDescInnerQuantity: number = 0;
  blDescInnerPackageTypeCode: string = "";
  blDescInnerPackageTypeName: string = "";
  blDescOuterQuantity: number = 0;
  blDescOuterPackageTypeCode: string = "";
  blDescOuterPackageTypeName: string = "";
  blDescPsaPackageTypeCode: string = "";
  blDescPsaPackageTypeName: string = "";
  blDescDescriptionText: string = "";
  blDescDescriptionTextClean: string = "";
  blDescGrossWeight: any = '0';
  blDescNettWeight: any = 0;
  blDescGrossMeasure: any = '0';
  blDescNettMeasure: any = '0';
  blDescReceiptTypeCode: string = "";
  blDescDeliveryTypeCode: string = "";
  blDescCommodityTypeId: string = "";
  blDescCommodityTypeName: string = "";
  blDescAwkward: any = "0";
  blDescDg2: any = "0";
  blDescDg2R: string = "0";
  blDescBlDutiable: string = "";
  blDescGrossWeightLock: boolean = true;
  blDescGrossMeasLock: boolean = true;
  blDescVgm: number = 0;
  blDescVgmLock: string = "Y";
  blDescOuterQuantityLock: boolean = true;
  blDescOuterPkgTypeLock: boolean = true;
  blDescDescriptionLock: boolean = true;

  blDescCreatedUserid: string = "";
  blDescCreatedDate: string = "";
  blDescModifiedUserid: string = "";
  blDescModifiedDate: string = "";

  blDescOuterQuantityPrint: string = "Y";
  blDescOuterPkgTypePrint: string = "Y";

  // blDescInnerQuantityString: string = '0';
  // blDescOuterQuantityString: string = '0';
  blDescGrossMeasureString: string = '0';
  blDescNettMeasureString: string = '0';
  blDescGrossWeightString: string  = '0';
  blDescNettWeightString: string   = '0';
  blDescNettWeightLock: boolean = true;
  blDescNettMeasLock: boolean = true;

  constructor() {}
}

export class BLFreightCharge {
  blFCOfficeCode: string = "";
  blFCBlNo: string = "";
  blFCSeq: number = 0;
  blFCChargeCode: string = "";
  blFCRevenue: number = 0;
  blFCRate: number = 0;
  blFCPerCode: string = "";
  blFCCurrencyCode: string = "";
  blFCAmount: number = 0;
  blFCPc: string = "";
  blFCPayerCode: string = "";
  blFCPayer: string = "";
  blFCPayerName: string = "";
  blFCPlaceOfPaymentCode: string = "";
  blFCPlaceOfPaymentName: string = "";
  blFCContainerType: string = "";
  blFCIsEntered: boolean = false;
  blFCIsEnteredString: string = "N";
  blFCIsManifestedFreight: boolean = false;
  blFCIsManifestedFreightString: string = "N";
  blFCIsChargePrinted: boolean = false;
  blFCIsChargePrintedString: string = "N";
  blFCFaiGroup: boolean = false;
  blFCFaiGroupString: string = "N";
  blFCFreightReference: string = "";
  blFCCreatedUserid: string = "";
  blFCCreatedDate: string = "";
  blFCModifiedUserid: string = "";
  blFCModifiedDate: string = "";

  constructor() {}
}

export class BLDescClause {
  blDescClauseOfficeCode: string = "";
  blDescClauseBlNo: string = "";
  blDescClauseId: string = "";
  blDescClauseSeq: number = 0;
  blDescClauseCode: string = "";
  blDescClauseName: string = "";
}

export class BLContainerExtra {
  blContainerOfficeCode: string = "";
  blContainerBlNo: string = "";
  blContainerSeq: number = 0;
  blContainerNumber: string = "";
  blContainerInnerQuantity: number = 0;
  blContainerInnerPackageTypeCode: string = "";
  blContainerInnerPackageTypeName: string = "";
  blContainerCommodityTypeId: string = "";
  blContainerCommodityTypeName: string = "";
  blContainerUch: number = 0;
  blContainerUcl: number = 0;
  blContainerUcw: number = 0;

  blContainerQuantityLock: string = "";
  blContainerPackageTypeLock: string = "";
  blContainerGrossWeightLock: string = "";
  blContainerGrossMeasureLock: string = "";
  blContainerNettWeightLock: string = "";
  blContainerNettMeasureLock: string = "";

  blContainerDesc: string = "";
  blContainerDescPrint: string = "";
  blContainerDescLock: string = "";
  blContainerAdPrint: string = "";

  blContainerVgm: number = 0;
  blContainerVgmLock: string = "";

  // blContainerInnerQuantityString: string = "0";

  constructor() {}
}

export class BLContainerHSCode {
  blContainerHSOfficeCode: string = "";
  blContainerHSBlNo: string = "";
  blContainerHSContainerSeq: number = 0;
  blContainerHSContainerNumber: string = "";
  blContainerHSSeq: number = 0;
  blContainerHSCode: string = "";
  blContainerHSDescription: string = "";
  blContainerHSPrint: boolean = false;
  blContainerHSPrintString: string = "N";

  constructor() {}
}

export class BLExchangeRate {
  blExchangeRateOfficeCode: string = "";
  blExchangeRateBlNo: string = "";
  blExchangeRateBlSeq: number = 0;
  blExchangeRateHomeCurrency: string = "";
  blExchangeRateDestinationCurrency: string = "";
  blExchangeRate: string = "0";

  constructor() {}
}

export class BLAdContainer{

  // blContainerOfficeCode: string = "";
  // blContainerBlNo: string = "";
  // blContainerSeq: number = 0;
  // blContainerNumber: string = "";

  blContainerOfficeCode: string = "";
  blContainerBlNo: string = "";
  blContainerSeq: number = 0;
  blContainerNumber: string = "";

  blAdContainerSeq: number = 0;
  blAdContainerCommodityTypeId: string = "";
  blAdContainerCommodityTypeName: string = "";
  blAdContainerInnerQty: number = 0;
  blAdContainerInnerPkgTypeCode: string = "";
  blAdContainerInnerPkgTypeName: string = "";
  blAdContainerGrossWeight: number = 0;
  blAdContainerGrossMeasure: number = 0;

  blAdContainerDGClassCode: string = "";
  blAdContainerDGClassName: string = "";
  blAdContainerIMO: string = "";
  blAdContainerUNNO: string = "";
  blAdContainerFlashPoint: number = 0.0;
  blAdContainerFlashPointScale: string = "";

  // temporary
  blAdContainerOfficeCode: string = "";
  blAdContainerBlNo: string = "";
  blAdContainerContainerSeq: number = 0;
  blAdContainerContainerNumber: string = "";

  blAdContainerOuterPkgTypeCode: string = "";
  blAdContainerOuterQty: number = 0;
  blAdContainerOuterPkgTypeName: string = "";

  blAdContainerUcHeight: number = 0;
  blAdContainerUcLength: number = 0;
  blAdContainerUcWidth: number = 0;
  // end temporary

  blAdContainerHsCode: string = "";
  blAdContainerHsName: string = "";
  blAdContainerHsRemarks: string = "";

  // blAdContainerInnerQtyString: string = "0";
  blAdContainerGrossWeightString: string = "0";
  blAdContainerGrossMeasureString: string = "0";

  constructor() {}
}

export class BLErrorList {
  bl: string = "";
  blPreVesselId: string = "";
  blVslId: string = "";
  booking: string = "";
  bookingPreVesselId: string = "";
  bookingVslId: string = "";
  message: string = "";
  type: string = "";
  constructor() {}
}
