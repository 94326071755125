<form class="ui raised segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-dateFrom'] == null || model['error-dateFrom'] == ''">
          {{model['error-dateFrom']}}
        </li>

        <li [class.hidden]="model['error-dateTo'] == null || model['error-dateTo'] == ''">
          {{model['error-dateTo']}}
        </li>

      </ul>
    </div>

    <!-- <div class="ui sementara message" [class.hidden]="temporaryOk == false">
      <ul>
        <li>OK!</li>
      </ul>
    </div> -->
    
    <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
    <div id='container'>
      <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
      <form class="ui form">
        <div class="field">
          <fieldset style="border: 1px solid lightgray;">          
            <div class="twelve fields">
              <div class="required field"
                [class.error]=" model['error-dateFrom'] != null && model['error-dateFrom'] != ''"
              >
                <label>Date From</label>
                <date-plugin
                  #dateFrom
                  [settings]="settingDateFrom"
                  (change)="changeEventDateFrom($event)"
                ></date-plugin>
              
              </div>

              <div class="required field"
                [class.error]=" model['error-dateTo'] != null && model['error-dateTo'] != ''"
              >
                <label>Date To</label>
                <date-plugin
                  #dateTo
                  [settings]="settingDateTo"
                  (change)="changeEventDateTo($event)"
                ></date-plugin>
              </div>
            </div>
            
            <div class="fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    value="SOC"
                    [checked]="model['containerOwnership'] == 'SOC'"
                    name="containerOwnership"
                    type="radio"
                    tabindex="0"
                    (change)="changeEventContainerOwnership($event)"
                  />
                  <label><b>SOC</b></label>
                </div>
              </div>
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    value="COC"
                    [checked]="model['containerOwnership'] == 'COC'"
                    name="containerOwnership"
                    type="radio"
                    tabindex="0"
                    (change)="changeEventContainerOwnership($event)"
                  />
                  <label><b>COC</b></label>
                </div>
              </div>
            </div> 

            <div class="fields">
              <div class="field" style="margin-top: 15px;">
                <div class="ui radio checkbox">
                  <input
                    value="IN-SIN"
                    [checked]="model['customerArea'] == 'IN-SIN'"
                    name="customerArea"
                    type="radio"
                    tabindex="0"
                    (change)="changeEventCustomerArea($event)"
                  />
                  <label><b>IN-SIN</b></label>
                </div>
              </div>
            </div>

            <div class="fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    value="EX-SIN"
                    [checked]="model['customerArea'] == 'EX-SIN'"
                    name="customerArea"
                    type="radio"
                    tabindex="0"
                    (change)="changeEventCustomerArea($event)"
                  />
                  <label><b>EX-SIN</b></label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>  <!-- end div container -->
  </form>

  <div
  id="ResponseOk"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="frmDialogMessage"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onDialogResponseOk()"
      >
        OK
      </button>
    </div>
  </form>
</div>
  
  
  <footer-plugin [info]="info"></footer-plugin>
  