import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  PdfPluginComponent,
  GenericService,
  ConfigService,
  CookieService,
  GenericUtil,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { interval } from "rxjs/observable/interval";
import FormValidation from "../../../src/utils/formValidation";
declare var $: any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselId: string = "";
  blOceanVesselCode: string = "";
  blOceanVesselName: string = "";
  blOceanVesselVoyage: string = "";
  blOceanVesselBound: string = "";

  containerOwnership: string = "SOC";
  user: string = "";

  blNoValidator: Boolean = false;
  bls: any[] = [];

  constructor() {}
}

export class ParamBL {
  blNo: string = "";
  constructor() {}
}

@Component({
  selector: "app-transaction-bl-freight-match-latest",
  templateUrl: "./transaction-bl-freight-match-latest-page.component.html",
  styleUrls: ["./transaction-bl-freight-match-latest-page.component.css"],
})
export class TransactionBlFreightMatchLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;

  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  formValidation = new FormValidation();

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGrid;
  settingPDF;

  /* Model */
  model = new Param();
  modelAdd = new ParamBL();

  radioFreightMatchByVessel = "Y";
  //radioContainerOwnership = 'ALL'
  radioContainerOwnership = "SOC";

  isEnter: boolean = false;
  isError: Boolean = false;
  isErrorAdd: boolean = false;
  validatorRules = {};

  msg: string = "";
  store: any[] = [];

  progressBarValue = 0;
  progressBarMessage = "";
  subscribe;

  blFMDialogMessage = "";

  mpDuplicate = new Map<number, String>();
  mpSameVessels = new Map<number, String>();
  mpMainVessels = new Map<number, String>();

  constructor(
    private auth: Authorize,
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericUtil: GenericUtil,
    private router: Router
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Run", event: "freightMatch", icon: "play" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BKG & BL Refreight",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url: "",
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 300, editType: "text" },
        {
          header: "",
          field: "btnRemove",
          width: 100,
          type: "button",
          labelButton: "Remove",
        },
      ],
      buttons: [
        {
          /*name: 'Edit', event: 'edit', enabled: true */
        },
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      editable: true,
      sortingDirection: "ASC",
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.setValidatorByVessel();
    this.model.blOceanVesselBound = "O";
    this.cdr.detectChanges();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "freightMatch":
        this.handleFreightMatch();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorByVessel() {
    this.isError = false;
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blNoValidator"] = "";

    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel should not be empty.",
          },
        ],
      },

      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage should not be empty.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
          },
        ],
      },

      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
    };
  }

  setValidatorByBL() {
    this.isError = false;
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blNoValidator"] = "";

    this.validatorRules = {
      blNoValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input data.",
          },
        ],
      },
    };
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
      this.model.blOceanVesselName = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;
      this.model.blOceanVesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }

  changeEventFreightMatchByVessel(event) {
    console.log(event);
    if (event.target.checked) {
      this.setValidatorByVessel();
      //this.radioContainerOwnership = 'ALL';
      this.radioContainerOwnership = "SOC";
      this.radioFreightMatchByVessel = "Y";
      this.model.blOceanVesselBound = "O";
      this.disableToolbarButtons = "";
      this.grid.listStore.store = [];
      this.grid.loadData();
    }
  }

  changeEventFreightMatchByBL(event) {
    console.log(event);
    if (event.target.checked) {
      this.setValidatorByBL();
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
      this.model.blOceanVesselVoyage = "";
      this.model.blOceanVesselBound = "O";

      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");

      this.radioFreightMatchByVessel = "N";
      this.disableToolbarButtons = "retrieve";
      this.grid.listStore.store = [];
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.grid.listStore.addData(modelAdd);
      this.grid.loadData();
    }
  }

  changeEventContainerOwnership(event) {
    if (event.target.value == "ALL") {
      this.radioContainerOwnership = "ALL";
      this.model.containerOwnership = "ALL";
    }

    if (event.target.value == "SOC") {
      this.radioContainerOwnership = "SOC";
      this.model.containerOwnership = "SOC";
    }

    if (event.target.value == "COC") {
      this.radioContainerOwnership = "COC";
      this.model.containerOwnership = "COC";
    }

    if (event.target.value == "SLOT") {
      this.radioContainerOwnership = "SLOT";
      this.model.containerOwnership = "SLOT";
    }
  }

  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  infoGrid(event) {
    this.info = event;
  }

  gridEventByBL(event) {
    console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var id: string = event.split(".")[1].split("-")[0];
        var no = Number.parseInt(event.split(".")[1].split("-")[1]);
        console.log(id + " - " + no);
        if (id == "btnRemove") {
          if (this.grid.listStore.store.length != no) {
            this.grid.listStore.store.splice(no - 1, 1);
            this.grid.loadData();
            this.mpSameVessels.delete(no - 1);
          }
        }

        break;
      case "AfterEnter":
        console.log("** AfterEnter Event **");
        this.isEnter = true;
        var no = Number.parseInt(event.split(".")[1]);
        var field: string = event.split(";")[1];
        var value: string = event.split(";")[2];
        console.log("value => " + value);

        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/BL/checkBlFreightMatchByBlNo/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              value
          )
          .subscribe((resp) => {
            console.log(resp);
            if (
              resp.json()["status"] == "OK" &&
              resp.json()["code"] == "MODE1"
            ) {
              console.log("#1");

              let vsl = resp.json()["blOceanVesselId"];
              let vyg = resp.json()["blOceanVesselVoyage"];
              console.log(vsl + " - " + vyg);
              this.mpSameVessels.set(no, vsl + "-" + vyg);

              if (this.grid.listStore.store.length == 1) {
                console.log("#1.1");

                let modelAdd = new ParamBL();
                modelAdd.blNo = "SSL";
                this.grid.listStore.addData(modelAdd);
                this.grid.loadData();
              } else {
                console.log("#2.2");
                let storeTemp: any[] = [];
                for (
                  let index = 0;
                  index < this.grid.listStore.store.length;
                  index++
                ) {
                  let modelAdd = new ParamBL();
                  modelAdd.blNo = this.grid.listStore.store[index].blNo;
                  if (modelAdd.blNo != "SSL") {
                    storeTemp.push(modelAdd);
                  }
                }

                this.grid.listStore.store = [];
                let modelAdd = new ParamBL();
                modelAdd.blNo = "SSL";
                storeTemp.push(modelAdd);

                this.grid.listStore.store = storeTemp;
                this.grid.loadData();

                // ------------------- check duplicate
                this.mpDuplicate.clear();
                let mpCheck = new Map<String, String>();
                for (
                  let index = 0;
                  index < this.grid.listStore.store.length;
                  index++
                ) {
                  let blNo = String(
                    this.grid.listStore.store[index].blNo
                  ).toUpperCase();
                  if (mpCheck.has(blNo)) {
                    this.mpDuplicate.set(index, blNo);
                  } else {
                    mpCheck.set(blNo, blNo);
                  }
                }

                let vslvyg: String = "";
                this.mpMainVessels.clear();
                for (let entry of Array.from(this.mpSameVessels.entries())) {
                  console.log(entry[0] + " - " + entry[1]);
                  if (vslvyg == "") {
                    vslvyg = entry[1];
                  } else {
                    if (vslvyg != entry[1]) {
                      this.mpMainVessels.set(entry[0], entry[1]);
                    }
                  }
                }

                if (this.mpDuplicate.size == 0) {
                  if (this.mpMainVessels.size == 0) {
                  } else {
                    this.msg = "BL(s) from different vessels not allowed.";
                    this.genericUtil.showDialog(
                      "msgDialogVessels",
                      "Information",
                      420,
                      160
                    );
                  }
                } else {
                  this.msg = "Duplicate BL Reference(s) detected.";
                  this.genericUtil.showDialog(
                    "msgDialogDuplicate",
                    "Information",
                    420,
                    160
                  );
                }
              }
            } else {
              console.log("#2");

              if (this.grid.listStore.store.length != no) {
                console.log("#2.1");
                this.grid.listStore.store.splice(no, 1);
                this.grid.loadData();
              } else {
                console.log("#2.2");
                this.grid.setValue(no, field, "SSL");
                this.grid.loadData();
              }

              this.mpSameVessels.delete(no);

              this.msg = "Invalid B/L No or B/L deleted.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          });

        break;
      case "AfterEdit":
        if (this.isEnter == false) {
          console.log("** AfterEnter Event **");
          var no = Number.parseInt(event.split(".")[1]);
          var field: string = event.split(";")[1];
          var value: string = event.split(";")[2];
          console.log("value => " + value);

          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BL/checkBlFreightMatchByBlNo/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                value
            )
            .subscribe((resp) => {
              if (
                resp.json()["status"] == "OK" &&
                resp.json()["code"] == "MODE1"
              ) {
                console.log("#1");

                let vsl = resp.json()["blOceanVesselId"];
                let vyg = resp.json()["blOceanVesselVoyage"];
                console.log(vsl + " - " + vyg);
                this.mpSameVessels.set(no, vsl + "-" + vyg);

                if (this.grid.listStore.store.length == 1) {
                  console.log("#1.1");

                  let modelAdd = new ParamBL();
                  modelAdd.blNo = "SSL";
                  this.grid.listStore.addData(modelAdd);
                  this.grid.loadData();
                } else {
                  console.log("#1.2");
                  let storeTemp: any[] = [];
                  for (
                    let index = 0;
                    index < this.grid.listStore.store.length;
                    index++
                  ) {
                    let modelAdd = new ParamBL();
                    modelAdd.blNo = this.grid.listStore.store[index].blNo;
                    if (modelAdd.blNo != "SSL") {
                      storeTemp.push(modelAdd);
                    }
                  }

                  this.grid.listStore.store = [];

                  let modelAdd = new ParamBL();
                  modelAdd.blNo = "SSL";
                  storeTemp.push(modelAdd);

                  this.grid.listStore.store = storeTemp;
                  this.grid.loadData();

                  // ------------------- check duplicate
                  this.mpDuplicate.clear();
                  let mpCheck = new Map<String, String>();
                  for (
                    let index = 0;
                    index < this.grid.listStore.store.length;
                    index++
                  ) {
                    let blNo = String(
                      this.grid.listStore.store[index].blNo
                    ).toUpperCase();
                    if (mpCheck.has(blNo)) {
                      this.mpDuplicate.set(index, blNo);
                    } else {
                      mpCheck.set(blNo, blNo);
                    }
                  }

                  let vslvyg: String = "";
                  this.mpMainVessels.clear();
                  for (let entry of Array.from(this.mpSameVessels.entries())) {
                    console.log(entry[0] + " - " + entry[1]);
                    if (vslvyg == "") {
                      vslvyg = entry[1];
                    } else {
                      if (vslvyg != entry[1]) {
                        this.mpMainVessels.set(entry[0], entry[1]);
                      }
                    }
                  }

                  if (this.mpDuplicate.size == 0) {
                    if (this.mpMainVessels.size == 0) {
                    } else {
                      this.msg = "BL(s) from different vessels not allowed.";
                      this.genericUtil.showDialog(
                        "msgDialogVessels",
                        "Information",
                        420,
                        160
                      );
                    }
                  } else {
                    this.msg = "Duplicate BL Reference(s) detected.";
                    this.genericUtil.showDialog(
                      "msgDialogDuplicate",
                      "Information",
                      420,
                      160
                    );
                  }
                }
              } else {
                console.log("#2");

                if (this.grid.listStore.store.length != no) {
                  console.log("#2.1");

                  this.grid.listStore.store.splice(no, 1);
                  this.grid.loadData();
                } else {
                  console.log("#2.2");

                  this.grid.setValueNoEvent(no, field, "SSL");
                  this.grid.loadData();
                }

                this.mpSameVessels.delete(no);

                this.msg = "Invalid B/L No or B/L deleted.";
                this.genericUtil.showDialog(
                  "msgDialog",
                  "Information",
                  420,
                  160
                );
              }
            });
        } else {
          this.isEnter = false;
        }

        break;
      default:
        break;
    }
  }

  handleCancel() {
    this.model = new Param();
    this.model.blOceanVesselBound = "O";
    this.model["blOceanVesselName"] = "";

    this.setValidatorByVessel();
    this.radioContainerOwnership = "SOC";
    this.radioFreightMatchByVessel = "Y";
    this.disableToolbarButtons = "";
    this.grid.listStore.store = [];
    this.grid.loadData();

    this.cbVessel.setValue("");
    this.cbVoyage.setUrl("");
    this.cbVoyage.setValue("");

    this.genericUtil.hideLoader();

    if (this.radioFreightMatchByVessel == "Y") {
      this.model["radioFreightMatchByVessel"] = "Y";
      this.disableToolbarButtons = "";
    }

    if (this.radioFreightMatchByVessel == "N") {
      this.model["radioFreightMatchByVessel"] = "N";
      this.grid.listStore.store = [];
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.grid.listStore.addData(modelAdd);
      this.grid.loadData();
    }
  }

  handleFreightMatch() {
    console.log("** handleFreightMatch2 **");
    if (this.radioFreightMatchByVessel == "Y") {
      this.model["radioFreightMatchByVessel"] = "Y";
      this.model.blOceanVesselBound = "O";

      this.setValidatorByVessel();
    }

    if (this.radioFreightMatchByVessel == "N") {
      this.model["radioFreightMatchByVessel"] = "N";
      this.model.blOceanVesselBound = "O";
      this.model["blOceanVesselName"] = "";

      this.setValidatorByBL();
      this.model.bls = [];
      this.model["blNoValidator"] = false;
      let storeTemp: any[] = [];
      for (let index = 0; index < this.grid.listStore.store.length; index++) {
        let modelAdd = new ParamBL();
        modelAdd.blNo = this.grid.listStore.store[index].blNo;
        if (modelAdd.blNo != "SSL") {
          storeTemp.push(modelAdd);
          this.model.bls.push(modelAdd);
        }
      }
      console.log(storeTemp.length);
      if (storeTemp.length == 0) {
        this.model["blNoValidator"] = true;
      }
    }

    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.user = this.cookieService.getName();

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      console.log(this.model);
      //this.loading = true;

      /*
      this.progressBarValue = 0;
      this.progressBarMessage = '';
      $('.ui.progress').progress('reset');
      this.genericUtil.showDialog('progressDialog', "Loading...", 600, 100);

      let status= ""
      // progress bar worker
      let self = this;
      const source = interval(500);
      this.subscribe = source.subscribe(val => {

        console.log("status ==> " + status);
        if(status == "NOK"){
          this.progressBarMessage = '';
          this.subscribe.unsubscribe();

          this.genericUtil.closeDialog('progressDialog')

          this.blFMDialogMessage = 'No record(s) found.';
          this.genericUtil.showDialog('blFMDialog', 'Confirmation', 350, 150);
        }

        this.genericService.GET(this.configService.config.BASE_API.toString() + '/BLFreightMatch/updateProgressBar/' + this.cookieService.getName().toUpperCase() + '/FM').subscribe((resp) => {
          console.log(resp.json());

          if(resp.json()['message'] != undefined){
            self.progressBarValue = resp.json()['progress'];
            self.progressBarMessage = '(Processing for BL = ' + resp.json()['message'] + ')';

            console.log('progress value = > ' + this.progressBarValue);
            if (self.progressBarValue >= 100){
              self.progressBarValue = 0;
              self.progressBarMessage = '';
              this.subscribe.unsubscribe();
            }

            self.onProgress();
          }

        });
      });

      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLFreightMatch/freightMatch2Before",this.model).subscribe((resp) => {
        status = resp.json()['status'];
        console.log(status);
        if(status == "OK"){
          this.printReport()
        }
      })
      */
      //this.loading = true;
      this.genericUtil.showLoader();
      this.disableToolbarButtons = "freightMatch,cancel";
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/BLFreightMatch/freightMatch2Before",
          this.model
        )
        .subscribe((resp) => {
          status = resp.json()["status"];
          const message = resp.json()["message"];
          console.log(status);

          if (status == "OK") {
            //this.loading = false;
            this.printReport();
          } else {
            this.genericUtil.hideLoader();
            this.disableToolbarButtons = "";
            this.message("information", "Information", message, "okonly", {
              ok: "",
            });
          }
        });

      /*
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLFreightMatch/freightMatch2Before",this.model).subscribe((resp) => {
        status = resp.json()['status'];

        if(status == "OK"){

          this.progressBarValue = 0;
          this.progressBarMessage = '';
          $('.ui.progress').progress('reset');
          this.genericUtil.showDialog('progressDialog', "Loading...", 600, 100);
          if(this.subscribe != null){
            this.subscribe.unsubscribe();
          }

          // progress bar worker
          let self = this;
          const source = interval(500);
          this.subscribe = source.subscribe(val => {


            this.genericService.GET(this.configService.config.BASE_API.toString() + '/BLFreightMatch/updateProgressBar/' + this.cookieService.getName().toUpperCase() + '/FM').subscribe((resp) => {
              console.log(resp.json());

              if(resp.json()['message'] != undefined){
                self.progressBarValue = resp.json()['progress'];
                self.progressBarMessage = '(Processing for BL = ' + resp.json()['message'] + ')';

                console.log('progress value = > ' + this.progressBarValue);
                if (self.progressBarValue >= 100){

                  this.genericUtil.closeDialog('progressDialog');
                  self.progressBarValue = 0;
                  self.progressBarMessage = '';
                  this.subscribe.unsubscribe();

                  this.printReport()
                }

                self.onProgress();
              }

            });
          });

        }else{
          this.blFMDialogMessage = 'No record(s) found.';
          this.genericUtil.showDialog('blFMDialog', 'Confirmation', 350, 150);
        }


      })
      */
    }
  }

  blFMDialogOkButton() {
    this.genericUtil.closeDialog("blFMDialog");
  }

  checkBLNoValidator(): Boolean {
    console.log("** check BL No Validator **");
    let result: Boolean = false;
    if (this.grid.listStore.store.length == 0) {
      this.model["error-blNoValidator"] =
        "BL(s) should not be empty. Please input BL at least one BL.";
      result = true;
    } else {
      this.grid.listStore.store.forEach((element) => {
        console.log(element);
      });
    }
    return result;
  }

  closeMsgDialog() {
    this.genericUtil.closeDialog("msgDialog");
  }

  printReport() {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";

    var jasperURL =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/freightMatchReport.pdf?sessionId=" +
      this.cookieService.getName();
    var fileName =
      "FreightMatchReport" +
      this.cookieService.getName().toUpperCase() +
      ".pdf";

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl, postData).subscribe((resp) => {
      if (resp.ok) {
        var result = resp.json();

        this.genericUtil.hideLoader();
        this.disableToolbarButtons = "";

        if (result["message"] == "success") {
          this.pdfContainer.setPDFURL(
            this.configService.config.pdfUrl + result["filePath"]
          );
          this.pdfContainer.showPDF();
        }

        //this.loading = false;
        this.subscribe.unsubscribe();
        this.genericUtil.closeDialog("progressDialog");
      }
    });
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event == "close-pdf") {
      this.disableToolbarButtons = "print";
    }
  }

  onProgress() {
    $("#recap-progress-bar").progress({
      percent: this.progressBarValue,
    });
  }

  closeMsgDialogDuplicate() {
    let hit = 0;
    for (let entry of Array.from(this.mpDuplicate.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      if (hit > 0) {
        i = entry[0] - 1;
      }
      this.grid.listStore.removeData(this.grid.listStore.store[i]);
      this.grid.loadData();
      hit++;
    }

    this.genericUtil.closeDialog("msgDialogDuplicate");
  }

  closeMsgDialogVessels() {
    let hit = 0;
    for (let entry of Array.from(this.mpMainVessels.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      //if(hit > 0){
      i = entry[0] - 1;
      //}
      this.grid.listStore.removeData(this.grid.listStore.store[i]);
      this.grid.loadData();

      this.mpSameVessels.delete(entry[0]);
      hit++;
    }

    this.genericUtil.closeDialog("msgDialogVessels");
  }
}
