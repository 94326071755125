<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == '' || model['error-blOceanVesselCode'] == undefined ">
          {{model['error-blOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == '' ">
          {{model['error-blOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
          {{model['error-blOceanVesselBound']}}
        </li>
        <li [class.hidden]="model['error-blLoadPortCode'] == null || model['error-blLoadPortCode'] == ''">
          {{model['error-blLoadPortCode']}}
        </li>
      </ul>
    </div>

    <form class="ui form">
        <div class="field" style="width: 100%">

            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">B/L Search</legend>
        
              <div class="six wide fields">
                <div class="required two field" style="width:100%" [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                  <label>Vessel </label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                </div>
                
                <div class="required two field" style="width:75%" [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                </div>
                
                <div class="required two field" style="width:60%">
                  <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']">
                </div>
                
                <div class="required two field" style="width:100%" [class.error]="model['error-blLoadPortCode'] != null && model['error-blLoadPortCode'] != ''">
                  <label>Load Port</label>
                  <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                </div>
                
                <div class="two field" style="width:100%">
                  <label>Transhipment Port</label>
                  <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width:100%;"></combo-plugin>
                </div>
        
                <div class="field" style="width:100%">
                  <label>Discharge Port</label>
                  <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="background-color:lightgray; width:100%;"></combo-plugin>
                </div>
                
                <div class="field" style="width:100%">
                  <label>Final Destination</label>
                  <combo-plugin #cbFinalDest [settings]="settingFinalDest" (change)="changeEventFinalDest($event)" style="background-color:lightgray; width:100%;"></combo-plugin>
                </div>
              </div>
            </fieldset>
        </div>

        
        <div class="field" style="width: 100%">
          <br/>
            <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        </div>
      
    </form>

  </div>




</div>
<footer-plugin [info]="info"></footer-plugin>


