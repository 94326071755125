import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import {
  Authorize, Validator, ComboPluginComponent, GenericService, GenericUtil,
  ConfigService, CookieService
} from 'sibego-ui-library';
import { officeModel } from '../shared/index';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
declare  var $: any;


export class ParamTransmitToPlanMaster {
  vesselId: String = '';
  vesselCode: String = '';
  vesselName: String = '';
  voyage: String = '';
  bound: String = 'O';
  constructor() {}
}

@Component({
  selector: 'app-edi-transmit-edifact-page',
  templateUrl: './edi-transmit-edifact-page.component.html',
  styleUrls: ['./edi-transmit-edifact-page.component.css']
})
export class EdiTransmitEdifactPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbEdifact') cbEdifact: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  dialog: any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new ParamTransmitToPlanMaster();
  office = new officeModel;
  userId = '';

  validatorRules = {};

  settingVessel;
  settingVoyage;

  settingEdifact;

  hitUrl = this.configService.config.BASE_API.toString();

  constructor(private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService, private _http: HttpClient) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Transmit', event: 'transmit', icon: 'exchange'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Transmit EDIFACT'
    };
    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 200},
        {header: 'Vessel Name', field: 'vesselName', width: 350},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }
    this.settingEdifact = {
      id          : 'cbEdifact',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterEdiEnum/findByComboBoxControl/edifactName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Edifact',
      title       : 'edifact_name',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Edifact Name', field: 'edifact_name', width: 350},
        {header: 'Valid', field: 'edifact_is_valid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
    this.model = new ParamTransmitToPlanMaster();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'transmit' :
        if(this.model.vesselId != "" && this.model.voyage != "" && this.model.bound != ""){
          this.transmitProcess();
        } else {
          this.message("information","Information","please insert vessel & voyage","okonly",{ok:""});
        }
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }

  firstInit(){
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  transmitProcess(){
    var splitUrl = this.configService.config.getPDFUrl.split(":");
    var uri = this.hitUrl + '?q=/ContainerLoadList/transmit/cll/bayplan/'+this.office.officeCode+'/'+this.model.vesselId+'/'+this.model.voyage+'/'+this.model.bound+'/'+this.genericUtil.getPSIDate();
    //this.genericService.GET(uri).subscribe((resp)=>{});
    var fileName = this.model.vesselCode + this.model.voyage.trim() + "-" + this.genericUtil.getPSIDate().replace(" ","T") + ".pml"

    this._http.get(uri,{ headers: new HttpHeaders({'Content-Type':  'application/json','Token': localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{
      FileSaver.saveAs(res, fileName);
    })

    //window.open(uri);
  }

  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }


  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = '';
    }
  }
  changeEventBound(event) {}
  changeEventEdifact(event) {}
  noneSpace(event: any) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
}
