<div class="ui raised segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <div class="ui error message" [class.hidden]="isError == false">
        <ul>
            <li [class.hidden]="model['error-bOceanVesselCode'] == null || model['error-bOceanVesselCode'] == ''">
                {{model['error-bOceanVesselCode']}}
            </li>
            <li [class.hidden]="model['error-bOceanVesselVoyage'] == null || model['error-bOceanVesselVoyage'] == ''">
                {{model['error-bOceanVesselVoyage']}}
            </li>
            <li [class.hidden]="model['error-allocationValidator'] == null || model['error-allocationValidator'] == ''">
                {{model['error-allocationValidator']}}
            </li>
            <li [class.hidden]="model['error-bPrefix'] == null || model['error-bPrefix'] == ''">
                {{model['error-bPrefix']}}
            </li>
            <li [class.hidden]="model['error-bNo'] == null || model['error-bNo'] == ''">
                {{model['error-bNo']}}
            </li>
            <li [class.hidden]="model['error-bBookingPartyCode'] == null || model['error-bBookingPartyCode'] == ''">
                {{model['error-bBookingPartyCode']}}
            </li>
            <li [class.hidden]="model['error-bShipperCode'] == null || model['error-bShipperCode'] == ''">
              {{model['error-bShipperCode']}}
            </li>
            <li [class.hidden]="model['error-bFreightPartyCode'] == null || model['error-bFreightPartyCode'] == ''">
              {{model['error-bFreightPartyCode']}}
            </li>
            <li [class.hidden]="model['error-bPorCode'] == null || model['error-bPorCode'] == ''">
                {{model['error-bPorCode']}}
            </li>
            <li [class.hidden]="model['error-bPolCode'] == null || model['error-bPolCode'] == ''">
                {{model['error-bPolCode']}}
            </li>
            <li [class.hidden]="model['error-bPodCode'] == null || model['error-bPodCode'] == ''">
                {{model['error-bPodCode']}}
            </li>
            <li [class.hidden]="model['error-bPodlCode'] == null || model['error-bPodlCode'] == ''">
                {{model['error-bPodlCode']}}
            </li>
            <li [class.hidden]="model['error-POLPODValidator'] == null || model['error-POLPODValidator'] == ''">
                {{model['error-POLPODValidator']}}
            </li>
            <li [class.hidden]="model['error-POLPOTValidator'] == null || model['error-POLPOTValidator'] == ''">
                {{model['error-POLPOTValidator']}}
            </li>
            <li [class.hidden]="model['error-PODPOTValidator'] == null || model['error-PODPOTValidator'] == ''">
                {{model['error-PODPOTValidator']}}
            </li>
            <li [class.hidden]="model['error-bReceiptType'] == null || model['error-bReceiptType'] == ''">
                {{model['error-bReceiptType']}}
            </li>
            <li [class.hidden]="model['error-bDeliveryType'] == null || model['error-bDeliveryType'] == ''">
                {{model['error-bDeliveryType']}}
            </li>
            <li [class.hidden]="model['error-rotationValidator'] == null || model['error-rotationValidator'] == ''">
                {{model['error-rotationValidator']}}
            </li>
            <li [class.hidden]="model['error-unitContainerValidator'] == null || model['error-unitContainerValidator'] == ''">
                {{model['error-unitContainerValidator']}}
            </li>
            <li [class.hidden]="model['error-operatorCodeValidator'] == null || model['error-operatorCodeValidator'] == ''">
                {{model['error-operatorCodeValidator']}}
            </li>
            <li [class.hidden]="model['error-coctsExceedValidator'] == null || model['error-coctsExceedValidator'] == ''">
                {{model['error-coctsExceedValidator']}}
            </li>
            <li [class.hidden]="model['error-cocLocalExceedValidator'] == null || model['error-cocLocalExceedValidator'] == ''">
                {{model['error-cocLocalExceedValidator']}}
            </li>
            <li [class.hidden]="model['error-podAllocationValidator'] == null || model['error-podAllocationValidator'] == ''">
                {{model['error-podAllocationValidator']}}
            </li>
            <li [class.hidden]="model['error-bContactPerson'] == null || model['error-bContactPerson'] == ''">
                {{model['error-bContactPerson']}}
            </li>
            <li [class.hidden]="model['error-feederValidator'] == null || model['error-feederValidator'] == ''">
                {{model['error-feederValidator']}}
            </li>
            <li [class.hidden]="model['error-ExVoyageValidator'] == null || model['error-ExVoyageValidator'] == ''">
                {{model['error-ExVoyageValidator']}}
            </li>
            <li [class.hidden]="model['error-NextVoyageValidator'] == null || model['error-NextVoyageValidator'] == ''">
                {{model['error-NextVoyageValidator']}}
            </li>
            <li [class.hidden]="model['error-ReeferDegreeValidator'] == null || model['error-ReeferDegreeValidator'] == ''">
                {{model['error-ReeferDegreeValidator']}}
            </li>
            <li [class.hidden]="model['error-bookingPartyValidator'] == null || model['error-bookingPartyValidator'] == ''">
                {{model['error-bookingPartyValidator']}}
            </li>
            <li [class.hidden]="model['error-ucValidator'] == null || model['error-ucValidator'] == ''">
                {{model['error-ucValidator']}}
            </li>
          <li [class.hidden]="model['error-bDegree'] == null || model['error-bDegree'] == ''">
            {{model['error-bDegree']}}
          </li>
        </ul>
    </div>



    <form class="ui form">
     <button class="ui button" style="display:none;"></button>

     <div class="six wide fields">
        <div class="required one field" [class.error]="model['error-bOceanVesselBound'] != null && model['error-bOceanVesselBound'] != ''" style="width:30%">
            <label>Bound</label>
            <input type="text" name="bound" [(ngModel)]="model['bOceanVesselBound']" readonly style="background-color:lightgray;" placeholder="Bound" >
        </div>
        <div class="one field" style="width:60%">
            <label>Service</label>
            <input type="text" name="service" [(ngModel)]="serviceCode" readonly style="background-color:lightgray;"  placeholder="Service" >
        </div>
        <div class="required two field" [class.error]="model['error-bOceanVesselCode'] != null && model['error-bOceanVesselCode'] != ''" style="width:100%">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" [disableCombo]="lockEdit" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
        </div>
        <div class="required one field" [class.error]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''" style="width:70%">
            <label>Voyage</label>
            <combo-plugin #cbVoyage [settings]="settingVoyage" [disableCombo]="lockEdit" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
        </div>

        <div class="two field" style="width:115%" [class.error]="(model['error-bPrefix'] != null && model['error-bPrefix'] != '') || (model['error-bNo'] != null && model['error-bNo'] != '')">
            <label>Booking Number</label>
            <input type="text" name="prefixBooking" [disabled]="lockEdit" [(ngModel)]="model['bPrefix']" style="background-color:floralwhite;width: 26%;float: left;"  >
            <input type="text" name="bookingNo" [readonly]="lockEdit" [(ngModel)]="model['bNo']" style="float: right;width: 73%;" placeholder="Booking Number" (keypress)="retrieve($event)" >
        </div>
        <div class="two field" style="width:100%">
            <label>Booking Date</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
           <input type="text" [(ngModel)]="model['bCreatedDate']" readonly  name="bCreatedDate" style="float: left;background-color:lightgray;"  >

        </div>
        <div class="two field" style="width:70%">
            <label>Created By</label>
            <!--<input type="text" name="createdBy" readonly style="background-color:lightgray;"  placeholder="Created By" >-->

           <input type="text" [(ngModel)]="model['bCreatedUserid']" readonly  name="bCreatedUserid" style="float: left;background-color:lightgray;"  >
        </div>
        <div class="field" style="width:50%;align-self:center;">
            <div class="ui test checkbox" >
                <input [checked]="model['bTsBooking'] == 'Y' " value="N" (change)="changeEventTS($event)" type="checkbox" name="tsBkg" tabindex="0">
                <label>TS BKG</label>
            </div>
        </div>
     </div>

     <div class="six wide fields">
        <div class="two field" style="width:100%">
            <label>ETA</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
            <input type="text" [(ngModel)]="model['ETA']" readonly name="ETA" style="float: left;background-color:lightgray;"  placeholder="ETA" >

        </div>
        <div class="two field" style="width:100%">
            <label>ETD</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
            <input type="text" [(ngModel)]="model['ETD']" readonly name="ETD" style="float: left;background-color:lightgray;"  placeholder="ETD" >

        </div>
        <div class="two field" style="width:100%">
            <label>ATB</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
            <input type="text" [(ngModel)]="model['ATA']" readonly  name="ATA" style="float: left;background-color:lightgray;"  placeholder="ATA" >
        </div>
        <div class="two field" style="width:100%">
            <label>ATD</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
            <input type="text" [(ngModel)]="model['ATD']" readonly name="ATD" style="float: left;background-color:lightgray;"  placeholder="ATD" >
        </div>
        <div class="two field" style="width:100%">
            <label>Modified Date</label>
            <!--
            <input type="text" readonly style="background-color:lightgray;" name="bookingDate" style="width: 55%;float: left;"  placeholder="dd MMM yyyy" >
            <input type="text" readonly style="background-color:lightgray;" name="bookingTime" style="float: right;width: 44%;" placeholder="HH:mm:ss" >
            -->
          <input type="text" [(ngModel)]="model['bModifiedDate']" readonly name="bModifiedDate" style="float: left;background-color:lightgray;"  >

        </div>
        <div class="two field" style="width:70%">
            <label>Modified By</label>
            <!--<input type="text" name="createdBy" readonly style="background-color:lightgray;"  placeholder="Created By" >-->
            <input type="text" [(ngModel)]="model['bModifiedUserid']" readonly name="bModifiedUserid" style="float: left;background-color:lightgray;"  >
        </div>
        <div class="field" style="width:48%;align-self:center;">
            <div class="ui test checkbox" >
                <input [checked]="model['bIsReserve'] == 'Y' " value="N" (change)="changeEventReserve($event)" type="checkbox" name="IsReserve" tabindex="0">
                <label>Resv. BKG</label>
            </div>
        </div>
     </div>

     <div class="ui top attached tabular menu" style="background-color:beige;">
        <a class="item active" data-tab="bookingNote1" (click)="initTab1()">Booking Note - 1 &nbsp; <span [style.display]="tabStatus=='tab1'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
        <a class="item" data-tab="bookingNote2" (click)="initTab2()">Booking Note - 2 &nbsp; <span [style.display]="tabStatus=='tab2'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
        <a class="item" data-tab="freightCharges" (click)="initTab3()">Freight Charges &nbsp; <span [style.display]="tabStatus=='tab3'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
        <a class="item" data-tab="containerInfo" (click)="initTab4()">Container Info &nbsp; <span [style.display]="tabStatus=='tab4'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
        <a class="item" data-tab="terminalInfo" (click)="initTab5()">Terminal Info &nbsp; <span [style.display]="tabStatus=='tab5'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
    </div>

    <div class="ui bottom attached tab segment active" id="bookingNote1Tab" data-tab="bookingNote1">
        <!-- Booking Note 1 -->

        <div class="ui inverted dimmer active" style="z-index:9;opacity:0.2;" [class.hidden]="lock == false">
            <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="twelve wide fields">
            <div class="one field" style="width:120%;border: 1px solid gray;
            border-radius: .28571429rem;padding: 9px;padding-bottom: 0px;">
                <div class="ten wide fields">

                    <label [class.hidden]="nvoccBookingParty == false" style="position: absolute;margin-left: 126px;font-size: 9px;font-weight: bold; color: red;">&lt;&lt; NVOCC &gt;&gt;</label>
                    <div class="required three field" [class.error]="model['error-bBookingPartyCode'] != null && model['error-bBookingPartyCode'] != ''" style="width:100%" >
                        <label>Booking Party</label>
                        <combo-plugin #cbBookingParty [settings]="settingBookingParty" (change)="changeEventBookingParty($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="required three field" [class.error]="model['error-bPolCode'] != null && model['error-bPolCode'] != ''"style="width:80%">
                        <label>Load Port</label>
                        <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="three field" style="width:80%">
                        <label>Transhipment Port 1</label>
                        <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width:100%;"></combo-plugin>
                    </div>

                </div>

                <div class="ten wide fields">

                    <label [class.hidden]="nvoccShipper == false" style="position: absolute;margin-left: 70px;font-size: 9px;font-weight: bold; color: red;">&lt;&lt; NVOCC &gt;&gt;</label>
                    <div class="three field" [class.error]="model['error-bShipperCode'] != null && model['error-bShipperCode'] != ''" style="width:100%" >
                        <label>Shipper</label>
                        <combo-plugin #cbShipper [settings]="settingShipper" (change)="changeEventShipper($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="required three field" [class.error]="(model['error-bPodCode'] != null && model['error-bPodCode'] != '') || (model['error-podAllocationValidator'] != null && model['error-podAllocationValidator'] != '')" style="width:80%">
                        <label>Discharge Port</label>
                        <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="three field" style="width:80%">
                        <label>Transhipment Port 2</label>
                        <combo-plugin #cbPOT2 [settings]="settingPOT2" (change)="changeEventPOT2($event)" style="width:100%;"></combo-plugin>
                    </div>

                </div>

                <div class="ten wide fields">

                    <label [class.hidden]="nvoccFreightParty == false" style="position: absolute;margin-left: 126px;font-size: 9px;font-weight: bold; color: red;">&lt;&lt; NVOCC &gt;&gt;</label>

                    <div class="three field" [class.error]="model['error-bFreightPartyCode'] != null && model['error-bFreightPartyCode'] != ''" style="width:100%" >
                        <label>Freight Party</label>
                        <combo-plugin #cbFreightParty [settings]="settingFreightParty" (change)="changeEventFreightParty($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="required three field" [class.error]="model['error-bPodlCode'] != null && model['error-bPodlCode'] != ''" style="width:80%">
                        <label>Place of Delivery</label>
                        <combo-plugin #cbPODL [settings]="settingPODL" (change)="changeEventPODL($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="three field" style="width:80%">
                        <label>Transhipment Port 3</label>
                        <combo-plugin #cbPOT3 [settings]="settingPOT3" (change)="changeEventPOT3($event)" style="width:100%;"></combo-plugin>
                    </div>

                </div>

                <div class="ten wide fields">

                    <div class="required three field" [class.error]="model['error-bPorCode'] != null && model['error-bPorCode'] != ''" style="width:100%" >
                        <label>Place of Receipt</label>
                        <combo-plugin #cbPOR [settings]="settingPOR" (change)="changeEventPOR($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="three field" style="width:80%">
                        <label>Final Destination</label>
                        <combo-plugin #cbFinalDest [settings]="settingFinalDest" (change)="changeEventFinalDest($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="three field" style="width:80%">
                        <label>Commodity</label>
                        <combo-plugin #cbCommodity [settings]="settingCommodity" (change)="changeEventCommodity($event)" style="width:100%;"></combo-plugin>
                    </div>

                </div>

                <div class="ten wide fields" style="margin-bottom:2px;">

                        <div class="three field" style="width:100%" >
                          <label>Portnet Reference</label>
                          <input type="text" name="portnetRef" [(ngModel)]="model['bPsaReferenceNo']"  placeholder="-- Portnet Reference --" >
                        </div>
                        <div class="three field" style="width:80%">
                          <label>Special Details</label>
                          <combo-plugin #cbSpecialDet [settings]="settingSpecialDet" (change)="changeEventSpecialDet($event)" style="width:100%;"></combo-plugin>
                        </div>
                        <div class="three field" style="width:80%">
                            <div class="three wide fields">
                            <div class="required one field" [class.error]="model['error-bReceiptType'] != null && model['error-bReceiptType'] != ''" style="width:50%">
                                <label>R/Type</label>
                                <combo-plugin #cbReceiptType [settings]="settingReceiptType" (change)="changeEventReceiptType($event)" style="width:100%;"></combo-plugin>
                            </div>
                            <div class="required one field" [class.error]="model['error-bDeliveryType'] != null && model['error-bDeliveryType'] != ''" style="width:50%">
                                <label>D/Type</label>
                                <combo-plugin #cbDeliveryType [settings]="settingDeliveryType" (change)="changeEventDeliveryType($event)" style="width:100%;"></combo-plugin>
                            </div>
                            </div>
                        </div>

                </div>

                <label [class.hidden]="isCOCLocalExceed == false" style="position: absolute;margin-left: 126px;font-size: 9px;font-weight: bold; color: red;">&lt;&lt; ** You're currently run out of COC Local allocation and system now will deduct from COC T/S allocation. &gt;&gt;</label>


            </div>

            <!--

            <div class="one field" style="width:80%">
                <fieldset style="width: 100%;border: 1px solid gray;margin-top:-8px;">
                    <legend style="font-weight:bold;color:dodgerblue;">Rotation</legend>
                    <label style="float: left; font-weight: bold;">{{rotation}}</label>
                </fieldset>
                <fieldset style="width: 100%;border: 1px solid gray;margin-top:8px;">
                    <legend style="font-weight:bold;color:dodgerblue;">Allocation</legend>

                    
                    <grid-plugin #grid [settings]="settingGridAllocation" (gridEvent)="gridAllocationEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>

                </fieldset>
            </div>
            -->

        </div>

        <div class="twelve wide fields">
             <div class="one field" style="width:100%">

                <div class="ten wide fields" >
                    <fieldset style="width: 15%;border: 1px solid gray;">
                        <legend style="font-weight:bold;color:dodgerblue;">Unit Container</legend>
                        <div class="one wide fields" style="width:100%" >
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>20</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;" [(ngModel)]="d20" name="20" min="0" (change)="changeEventD20($event)" (keyup)="changeEventD20($event);">
                            </div>
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>H20</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;"  [disabled]="lockTNK == true" [(ngModel)]="h20" name="h20" min="0" (change)="changeEventH20($event)" (keyup)="changeEventH20($event)">
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%" >
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>40</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;" [(ngModel)]="d40" name="40" min="0" (change)="changeEventD40($event)" (keyup)="changeEventD40($event)">
                            </div>
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>H40</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;" [disabled]="lockTNK == true" [(ngModel)]="h40" name="h40" min="0" (change)="changeEventH40($event)" (keyup)="changeEventH40($event)">
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%" >
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>45</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;" [(ngModel)]="d45" name="45" min="0" (change)="changeEventD45($event)" (keyup)="changeEventD45($event)">
                            </div>
                            <div class="one field" [class.error]="model['error-unitContainerValidator'] != null && model['error-unitContainerValidator'] != ''" style="width:50%" >
                                    <label>H45</label>
                                    <input type="number" style="padding-left:1px;padding-right:1px;text-align:right;" [disabled]="lockTNK == true" [(ngModel)]="h45" name="h45" min="0" (change)="changeEventH45($event)" (keyup)="changeEventH45($event)">
                            </div>
                        </div>
                    </fieldset>

                   <fieldset style="width: 13%;border: 1px solid gray;">
                       <legend style="font-weight:bold;color:dodgerblue;">Container Type</legend>

                       <div class="one wide fields" style="width:100%;margin-bottom: 8px;" *ngFor="let ct of containerTypes">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input id="{{ct.containerTypeCode}}" [checked]="currentContainerType == ct.containerTypeCode " (change)="changeEventContainerType($event)" value="{{ct.containerTypeCode}}" name="containerType" type="radio" tabindex="0" class="hidden">
                                    <label for="{{ct.containerTypeCode}}" [class.notEmpty]="ct.isSlot == 'Y' && model['bContainerOwnership'] =='SLOT' && bookingDetails.get(ct.containerTypeCode) != nil && bookingDetails.get(ct.containerTypeCode).size > 0" >{{ct.containerTypeName}}</label>
                                </div>
                            </div>
                        </div>

                        <!--
                       <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'N' " (change)="changeEventContainerType($event)" value="N" name="containerType" type="radio" tabindex="0" class="hidden">
                                    <label [class.notEmpty]="emptyNormal == false">Normal</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'O' " (change)="changeEventContainerType($event)" name="containerType" value="O" type="radio" tabindex="0" class="hidden">
                                    <label>Open Top</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:104%;margin-bottom: 8px;">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'F' " (change)="changeEventContainerType($event)" name="containerType" value="F" type="radio" tabindex="0" class="hidden">
                                    <label>Flat Rack</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'P' " (change)="changeEventContainerType($event)" name="containerType" value="P" type="radio" tabindex="0" class="hidden">
                                    <label>Platform</label>
                                </div>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field">
                                <div class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'R' " (change)="changeEventContainerType($event)" name="containerType" value="R" type="radio" tabindex="0" class="hidden">
                                    <label [class.notEmpty]="emptyRefeer == false">Reefer</label>
                                </div>
                            </div>
                        </div>
                        <div  class="one wide fields" style="width:108%;margin-bottom: 8px;">
                            <div  class="one field">
                                <div  class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'D' " (change)="changeEventContainerType($event)" name="containerType" value="D" type="radio" tabindex="0" class="hidden">
                                    <label [class.notEmpty]="emptyDry == false">Reefer Dry</label>
                                </div>
                            </div>
                        </div>
                        <div  class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div  class="one field">
                                <div  class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'T' " (change)="changeEventContainerType($event)" name="containerType" value="T" type="radio" tabindex="0" class="hidden">
                                    <label [class.notEmpty]="emptyTank == false">Tank</label>
                                </div>
                            </div>
                        </div>
                        <div  class="one wide fields" style="width:100%;margin-bottom: 0px;">
                            <div  class="one field">
                                <div  class="ui radio checkbox">
                                    <input [checked]="currentContainerType == 'U' " (change)="changeEventContainerType($event)" name="containerType" value="U" type="radio" tabindex="0" class="hidden">
                                    <label >UC</label>
                                </div>
                            </div>
                        </div>
                        -->
                    </fieldset>


                    <fieldset style="width: 33%;border: 1px solid gray;">
                       <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>

                       <div class="one wide fields" style="width:100%;margin-bottom: 18px;">
                            <div class="one field" style="width:20%;margin-top:30px;">
                                <div class="ui radio checkbox" [class.disabled]="lockSOC == true">
                                    <input [checked]="model['bContainerOwnership'] == 'SOC' " (change)="changeEventContainerOwnership($event)" value="SOC" name="containerOwner" type="radio" tabindex="0" class="hidden">
                                    <label>SOC</label>
                                </div>
                            </div>
                            <div class="one field" style="width: 43%;margin-top:30px;">
                                <div class="ui radio checkbox" [class.disabled]="lockSOC == true">
                                    <input [checked]="model['bContainerOwnership'] == 'SOCTS' " (change)="changeEventContainerOwnership($event)" value="SOCTS" name="containerOwner"  type="radio" tabindex="0" class="hidden">
                                    <label>SOC T/S</label>
                                </div>
                            </div>
                            <div class="one field" [class.error]="(model['bContainerOwnership'] == 'SOC' || model['bContainerOwnership'] == 'SOCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')" style="width:34%" >
                                    <label>CNTR OPR</label>
                                    <!--<input type="text" name="cntrOprSoc" [disabled]="lockSOCOPR == true"  placeholder="" >-->
                                    <combo-plugin #cbSocOPR [settings]="settingSocOPR" [disableCombo]="lockSOCOPR == true" (change)="changeEventSocOPR($event)"></combo-plugin>
                            </div>
                            <div class="one field" style="width:34%" >
                                    <label>SO OPR</label>
                                    <input type="text" name="soOprSoc" readonly style="background-color:lightgray;" [(ngModel)]="model['bSocOperatorCode']" [disabled]="lockSOCOPR == true" placeholder="" >
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 16px;">
                            <div class="one field" style="width:20%;margin-top:30px;">
                                <div class="ui radio checkbox" [class.disabled]="lockCOC == true">
                                    <input [checked]="model['bContainerOwnership'] == 'COC' " (change)="changeEventContainerOwnership($event)" value="COC" name="containerOwner" type="radio" tabindex="0" class="hidden">
                                    <label>COC</label>
                                </div>
                            </div>
                            <div class="one field" style="width: 43%;margin-top:30px;">
                                <div class="ui radio checkbox" [class.disabled]="lockCOC == true">
                                    <input [checked]="model['bContainerOwnership'] == 'COCTS' " (change)="changeEventContainerOwnership($event)" value="COCTS" name="containerOwner" type="radio" tabindex="0" class="hidden">
                                    <label>COC T/S</label>
                                </div>
                            </div>

                            <div class="one field" [class.error]="(model['bContainerOwnership'] == 'COC' || model['bContainerOwnership'] == 'COCTS') && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')" style="width:34%" >
                              <label>CNTR OPR</label>
                              <combo-plugin #cbCocOPR [settings]="settingCocOPR" [disableCombo]="lockCOCOPR == true" (change)="changeEventCocOPR($event)" ></combo-plugin>
                            </div>

                          <!--
                            <div class="one field" style="width:34%" >
                                    <label>CNTR OPR</label>
                                    <input type="text" name="cntrOprCoc"  [(ngModel)]="model['bCocCntrOperatorCode']"   [disabled]="lockCOCOPR == true"  placeholder="" >
                            </div>
                            -->
                            <div class="one field" style="width:34%" >
                                    <label>SO OPR</label>
                                    <input type="text" name="soOprCoc" readonly style="background-color:lightgray;" [(ngModel)]="model['bCocOperatorCode']" [disabled]="lockCOCOPR == true" placeholder="" >
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:20%;margin-top:30px;">
                                <div class="ui radio checkbox" [class.disabled]="lockSLOT == true">
                                    <input [checked]="model['bContainerOwnership'] == 'SLOT' " (change)="changeEventContainerOwnership($event)" value="SLOT" name="containerOwner" type="radio" tabindex="0" class="hidden">
                                    <label>SLOT</label>
                                </div>
                            </div>
                            <div class="one field" style="width: 43%;margin-top:30px;">
                                    <div class="ui radio checkbox" style="display:none;">
                                        <input name="containerOwner" type="radio" tabindex="0" class="hidden">
                                        <label>xxxxx</label>
                                    </div>
                                </div>
                            <div class="one field" style="width:34%" >
                                    <label>CNTR OPR</label>
                                    <input type="text" readonly style="background-color:gray;"  name="cntrOprSlot" [(ngModel)]="model['bOutwardSlotOperatorCode']" [disabled]="lockSLOTOPR == true"  placeholder="" >
                            </div>
                            <div class="one field" style="width:34%" [class.error]="model['bContainerOwnership'] == 'SLOT' && (model['error-operatorCodeValidator'] != null && model['error-operatorCodeValidator'] != '')" >
                                    <label>SO OPR</label>
                                    <!--<input type="text" name="slOprSlot" [disabled]="lockSLOTOPR == true" placeholder="" >-->
                                    <combo-plugin #cbSlotOPR [settings]="settingSlotOPR" [disableCombo]="lockSLOTOPR == true" (change)="changeEventSlotOPR($event)" ></combo-plugin>
                            </div>

                        </div>
                    </fieldset>

                   <fieldset style="width: 11%;border: 1px solid gray;">
                       <legend style="font-weight:bold;color:dodgerblue;">Shipment Type</legend>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:50%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'N' " (change)="changeEventShipmentType($event)" value="N" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label>N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width: 75%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'I' " (change)="changeEventShipmentType($event)" value="I" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label>IRE</label>
                                </div>
                            </div>

                        </div>
                        <div class="one wide fields" style="width:104%;margin-bottom: 8px;">
                            <div class="one field" style="width: 75%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'R' " (change)="changeEventShipmentType($event)" value="R" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label>ROB</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:50%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'T' " (change)="changeEventShipmentType($event)" value="T" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label>THRU</label>
                                </div>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'D' " (change)="changeEventShipmentType($event)" value="D" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label>RD-TRIP</label>
                                </div>
                            </div>
                        </div>
                        <div  class="one wide fields" style="width:108%;margin-bottom: 8px;">
                            <div  class="one field" style="width: 77%;">
                                <div  class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'S' " (change)="changeEventShipmentType($event)" value="S" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label >SPECIAL</label>
                                </div>
                            </div>
                        </div>
                        <div  class="one wide fields" style="width:100%;margin-bottom: 8px;" >
                            <div  class="one field" style="width:50%">
                                <div  class="ui radio checkbox">
                                    <input [checked]="model['bShipmentType'] == 'C' " (change)="changeEventShipmentType($event)" value="C" name="shipmentType" type="radio" tabindex="0" class="hidden">
                                    <label >CRB</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset style="width: 11%;border: 1px solid gray;">
                       <legend style="font-weight:bold;color:dodgerblue;">Class</legend>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:50%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bDgClass'] == 'N' " (change)="changeEventDGClass($event)" value="N" name="class" type="radio" tabindex="0" class="hidden">
                                    <label>N/A</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width: 100%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bDgClass'] == 'C1' " (change)="changeEventDGClass($event)" value="C1" name="class" type="radio" tabindex="0" class="hidden">
                                    <label>DG - C1</label>
                                </div>
                            </div>

                        </div>
                        <div class="one wide fields" style="width:104%;margin-bottom: 8px;">
                            <div class="one field" style="width: 100%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bDgClass'] == 'C2F' " (change)="changeEventDGClass($event)" value="C2F" name="class" type="radio" tabindex="0" class="hidden">
                                    <label>DG - C2F</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bDgClass'] == 'C2' " (change)="changeEventDGClass($event)" value="C2" name="class" type="radio" tabindex="0" class="hidden">
                                    <label>DG - C2</label>
                                </div>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bDgClass'] == 'C3' " (change)="changeEventDGClass($event)" value="C3" name="class" type="radio" tabindex="0" class="hidden">
                                    <label>DG - C3</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset style="width: 11%;border: 1px solid gray;">
                       <legend style="font-weight:bold;color:dodgerblue;">Weight Class</legend>
                        <div class="one wide fields" style="width:110%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'X' " (change)="changeEventWeightClass($event)" value="X" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Extra Heavy</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width: 75%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'H' " (change)="changeEventWeightClass($event)" value="H" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Heavy</label>
                                </div>
                            </div>

                        </div>
                        <div class="one wide fields" style="width:104%;margin-bottom: 8px;">
                            <div class="one field" style="width: 75%;">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'M' " (change)="changeEventWeightClass($event)" value="M" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Medium</label>
                                </div>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:50%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'L' " (change)="changeEventWeightClass($event)" value="L" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Light</label>
                                </div>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'E' " (change)="changeEventWeightClass($event)" value="E" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Empty</label>
                                </div>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui radio checkbox">
                                    <input [checked]="model['bWeightClass'] == 'C' " (change)="changeEventWeightClass($event)" value="C" name="weightClass" type="radio" tabindex="0" class="hidden">
                                    <label>Custom</label>
                                </div>
                            </div>
                        </div>

                         <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                            <div class="one field" style="width:100%">
                                <div class="ui input">
                                    <input type="number" name="bWeight" [(ngModel)]="model['bWeight']" (change)="changeEventWeight($event)" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);" [disabled]="lockWeight == true" >
                                </div>
                            </div>
                         </div>

                    </fieldset>

                </div>

             </div>

        </div>

        <div class="twelve wide fields">
             <div class="one field" style="width:100%">

                <div class="six wide fields">
                    <fieldset style="width: 50%;border: 1px solid gray;">
                        <legend style="font-weight:bold;color:dodgerblue;">Misc</legend>
                        <div class="one wide fields" style="width:60%;float:left;">
                           <div class="three field" style="width:100%">
                                <label>Remarks</label>
                                <textarea name="remarks" style="font-size:12px;" [(ngModel)]="model['bRemarks']"  placeholder="-- REMARKS --"></textarea>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:40%;float:right;">
                            <div class="one field" style="width:100%" >
                                <label>Kill Slot (TEUS)</label>
                                <input type="number" min="0" [(ngModel)]="killSlot" name="killSlot" (change)="changeEventKillSlot($event)" (keyup)="changeEventKillSlot($event);"  placeholder="-- KILL SLOT --" >
                            </div>
                        </div>
                        <div class="one wide fields" style="width:40%;float:right;">
                            <div class="required one field" [class.error]="model['error-bContactPerson'] != null && model['error-bContactPerson'] != ''" style="width:100%" >
                                <label>CTC PAX</label>
                                <input type="text" [(ngModel)]="model['bContactPerson']" name="contactPerson"  placeholder="-- CTC PAX --" >
                            </div>
                        </div>
                        <div class="one wide fields" style="width:40%;float:right;">
                            <div class="one field" style="width:100%" >
                                <label>Phone</label>
                                <input type="text" [(ngModel)]="model['bTelephone']"  name="telephone"  placeholder="-- TELEPHONE --" >
                            </div>
                        </div>
                    </fieldset>

                    <div class="one wide fields" style="margin-right:6px;">
                            <div class="one field" style="width:100%;height:112%;">

                                <div class="one wide fields" style="height:50%;">
                                    <div class="one field" style="width:100%">
                                        <fieldset style="width: 100%;border: 1px solid gray;height:95px;">
                                            <legend style="font-weight:bold;color:dodgerblue;">Frt. Type</legend>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                                                <div class="one field" style="width:50%">
                                                    <div class="ui radio checkbox">
                                                        <input [checked]="model['bFreightType'] == 'P' " (change)="changeEventFreightType($event)" value="P" name="freightType" type="radio" tabindex="0" class="hidden">
                                                        <label>Prepaid</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                                                <div class="one field" style="width: 75%;">
                                                    <div class="ui radio checkbox">
                                                        <input [checked]="model['bFreightType'] == 'C' " (change)="changeEventFreightType($event)" value="C" name="freightType" type="radio" tabindex="0" class="hidden">
                                                        <label>Collect</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div class="one wide fields" style="height:50%;">
                                    <div class="one field" style="width:100%">
                                        <fieldset style="width: 100%;border: 1px solid gray;height:95px;">
                                            <legend style="font-weight:bold;color:dodgerblue;">IHC</legend>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                                                <div class="one field" style="width:50%">
                                                    <div class="ui radio checkbox">
                                                        <input [checked]="model['bIhc'] == 'N' " (change)="changeEventIhc($event)" value="N" name="ihc" type="radio" tabindex="0" class="hidden">
                                                        <label>N/A</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                                                <div class="one field" style="width: 75%;">
                                                    <div class="ui radio checkbox">
                                                        <input [checked]="model['bIhc'] == 'S' " (change)="changeEventIhc($event)" value="S" name="ihc" type="radio" tabindex="0" class="hidden">
                                                        <label>Shipper</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;">
                                                <div class="one field" style="width: 75%;">
                                                    <div class="ui radio checkbox">
                                                        <input [checked]="model['bIhc'] == 'C' " (change)="changeEventIhc($event)" value="C" name="ihc" type="radio" tabindex="0" class="hidden">
                                                        <label>Consignee</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>

                            </div>
                        </div>


                </div>


             </div>
        </div>

    </div>

    <div class="ui bottom attached tab segment" id="bookingNote2Tab" data-tab="bookingNote2">
        <!-- Booking Note 2 -->
        <div class="ui inverted dimmer active" style="z-index:9;opacity:0.2;" [class.hidden]="lock == false">
            <i class="lockicon circular inverted large lock icon"></i>
        </div>

        <div class="twelve wide fields">

            <div class="one field" style="width:100%;">

                <div class="twelve wide fields">

                    <fieldset style="width: 50%;border: 1px solid gray;margin-top:-8px;" disabled=true>
                        <legend style="font-weight:bold;color:dodgerblue;">Cross Booking</legend>

                        <div class="one wide fields" style="width:50%;float:left;">
                            <div class="three field" style="width:100%">
                                    <label>Feeder Vessel</label>
                                    <combo-plugin #cbFeederVessel [settings]="settingFeederVessel" (change)="changeEventFeederVessel($event)" style="width:100%;"></combo-plugin>
                                </div>
                        </div>
                        <div class="one wide fields" style="width:50%;float:right;">
                            <div class="one field" [class.error]="model['error-feederValidator'] != null && model['error-feederValidator'] != ''" style="width:100%" >
                                <label>Feeder Voyage</label>
                                <combo-plugin #cbFeederVoyage [settings]="settingFeederVoyage" (change)="changeEventFeederVoyage($event)" style="width:100%;"></combo-plugin>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;float:left;">
                            <div class="three field" style="width:100%">
                                <label>Supplier</label>
                                <combo-plugin #cbSupplier [settings]="settingSupplier" (change)="changeEventSupplier($event)" style="width:100%;"></combo-plugin>
                            </div>
                        </div>

                    </fieldset>

                    <fieldset style="width: 50%;border: 1px solid gray;margin-top:-8px;">
                        <legend style="font-weight:bold;color:dodgerblue;">Reefer Temperature</legend>


                        <div class="one wide fields" style="width:50%;float:left;">
                            <div class="one field" style="width:100%">
                                    <label>Degree</label>
                                    <div class="three wide fields" style="width:100%;">

                                        <input type="number" [(ngModel)]="bDegree" name="degree" (change)="changeEventDegree($event)"  placeholder="" style="width:97px;margin-right:1px;" >

                                        <div class="ui fluid selection dropdown" style="width:60px;">
                                            <div class="text">{{bDegreeTemp}}</div>
                                            <i class="dropdown icon"></i>
                                            <div class="menu">
                                                <div class="item" [class.active]="bDegreeTemp == 'C'" [class.selected]="bDegreeTemp == 'C'" (click)="onSelectDegreeTemp('C')">C</div>
                                                <div class="item" [class.active]="bDegreeTemp == 'F'" [class.selected]="bDegreeTemp == 'F'" (click)="onSelectDegreeTemp('F')">F</div>
                                            </div>
                                        </div>

                                        <div class="ui test checkbox" style="margin-top: 10px;margin-left: 10px;" >
                                            <input [checked]="model['bIsDry'] == 'Y' " (change)="changeEventDry($event)" value="N" type="checkbox" name="dry" tabindex="0">
                                            <label>Dry</label>
                                        </div>
                                    </div>

                                </div>

                        </div>

                        <div class="one wide fields" style="width:100%;">
                            <div class="three field" style="width:100%">
                                <label>Reefer Collection</label>
                                <calendar-plugin #dtReeferCollection [pluginType]="typeReeferCollection" [settings]="settingReeferCollection" ></calendar-plugin>
                            </div>
                            <div class="one field" style="width:100%" >
                                <label>Vent</label>
                                 <div class="three wide fields" style="width:100%;">
                                    <div class="one field" style="width:100%">
                                        <div class="ui radio checkbox">
                                            <input [checked]="model['bVent'] == 'N' " (change)="changeEventVent($event)" value="N" name="vent" type="radio" tabindex="0" class="hidden">
                                            <label>N/A</label>
                                        </div>
                                    </div>
                                    <div class="one field" style="width:100%">
                                        <div class="ui radio checkbox">
                                            <input [checked]="model['bVent'] == 'C' " (change)="changeEventVent($event)" value="C" name="vent" type="radio" tabindex="0" class="hidden">
                                            <label>Close</label>
                                        </div>
                                    </div>
                                    <div class="one field" style="width:100%">
                                        <div class="ui radio checkbox">
                                            <input [checked]="model['bVent'] == 'O' " (change)="changeEventVent($event)" value="O" name="vent" type="radio" tabindex="0" class="hidden">
                                            <label>Open</label>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>

                    </fieldset>

                 </div>

                 <div class="twelve wide fields">

                    <fieldset style="width: 50%;border: 1px solid gray;margin-top:-8px;">
                        <legend style="font-weight:bold;color:dodgerblue;">Mother Vessel Info</legend>

                        <!--
                            <div class="one wide fields" style="width:100%;">
                                <div class="three field" style="width:100%">
                                        <label>Ex Vessel</label>
                                        <combo-plugin #cbExVessel [settings]="settingExVessel" (change)="changeEventExVessel($event)" style="width:100%;"></combo-plugin>
                                </div>
                                <div class="three field" [class.error]="model['error-ExVoyageValidator'] != null && model['error-ExVoyageValidator'] != ''" style="width:100%" >
                                    <label>Ex Voyage</label>
                                    <input type="text" style="width:100%;" [(ngModel)]="model['bExMotherVesselVoyage']" name="bExMotherVesselVoyage" placeholder="-- EX VOYAGE --" (blur)="checkBoundM()" maxlength="8">
                                </div>
                            </div>

                            <div class="one wide fields" style="width:100%;;">
                                <div class="three field" style="width:100%">
                                        <label>Next Vessel</label>
                                        <combo-plugin #cbNextVessel [settings]="settingNextVessel" (change)="changeEventNextVessel($event)" [disableCombo]="true" style="width:100%;" ></combo-plugin>
                                </div>
                                <div class="three field" [class.error]="model['error-NextVoyageValidator'] != null && model['error-NextVoyageValidator'] != ''" style="width:100%" >
                                    <label>Next Voyage</label>
                                    <combo-plugin #cbNextVoyage [settings]="settingNextVoyage" (change)="changeEventNextVoyage($event)" [disableCombo]="true"></combo-plugin>
                                </div>
                            </div>
                        -->

                        <div class="one wide fields" style="width:100%;">
                            <div class="three field" style="width:100%">
                                    <label>Ex Vessel</label>
                                    <combo-plugin #cbExVessel [settings]="settingExVessel" (change)="changeEventExVessel($event)" style="width:100%;"></combo-plugin>
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;">
                            <div class="three field" [class.error]="model['error-ExVoyageValidator'] != null && model['error-ExVoyageValidator'] != ''" style="width:100%" >
                                <label>Ex Voyage</label>
                                <input type="text" style="width:100%;" [(ngModel)]="model['bExMotherVesselVoyage']" name="bExMotherVesselVoyage" placeholder="-- EX VOYAGE --" (blur)="checkBoundM()" maxlength="8">
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;">
                            <div class="three field" style="width:100%" [class.disabled]="lockNextVessel==true">
                                    <label>Next Vessel</label>
                                    <combo-plugin #cbNextVessel [settings]="settingNextVessel" (change)="changeEventNextVessel($event)" [disableCombo]="this.defaultCountry == 'sg' ? true : false" style="width:100%;" ></combo-plugin>
                            </div>
                        </div>
                        <div class="one wide fields" style="width:100%;" [class.disabled]="lockNextVessel==true">
                            <div class="three field" [class.error]="model['error-NextVoyageValidator'] != null && model['error-NextVoyageValidator'] != ''" style="width:100%" >
                                <label>Next Voyage</label>
                                <combo-plugin #cbNextVoyage [settings]="settingNextVoyage" (change)="changeEventNextVoyage($event)" [disableCombo]="this.defaultCountry == 'sg' ? true : false"></combo-plugin>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset style="width: 55%;border: 1px solid grayw;margin-top:-8px;">
                        <legend style="font-weight:bold;color:dodgerblue;">Measurement</legend>

                        <div class="one wide fields" style="width:100%;">
                            <div class="one field" style="width:83%;margin-top:26px;">
                                <div class="ui test checkbox">
                                    <input [checked]="OH == 'Y' " name="oh" value="N" (change)="changeEventOH($event)" type="checkbox" tabindex="0" class="hidden">
                                    <label>Over Height (CM)</label>
                                </div>
                            </div>
                            <div class="one field" style="width:35%" >

                            </div>
                            <div class="one field" style="width:35%" >
                                    <label>Height</label>
                                    <input type="number" name="heightMeasure" [(ngModel)]="heightMeasure" (change)="changeEventHeightMeasure($event)" [disabled]="lockOH == true"  placeholder="" >
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;">
                            <div class="one field" style="width:83%;margin-top:26px;">
                                <div class="ui test checkbox">
                                    <input [checked]="OW == 'Y' " name="ow" value="N" (change)="changeEventOW($event)" type="checkbox" tabindex="0" class="hidden">
                                    <label>Over Width (CM)</label>
                                </div>
                            </div>
                            <div class="one field" style="width:35%" >
                                    <label>Left</label>
                                    <input type="number" name="leftMeasure" [(ngModel)]="leftMeasure" (change)="changeEventLeftMeasure($event)" [disabled]="lockOW == true" placeholder="" >
                            </div>
                            <div class="one field" style="width:35%" >
                                    <label>Right</label>
                                    <input type="number" name="rightMeasure" [(ngModel)]="rightMeasure" (change)="changeEventRightMeasure($event)" [disabled]="lockOW == true"  placeholder="" >
                            </div>
                        </div>

                        <div class="one wide fields" style="width:100%;">
                            <div class="one field" style="width:83%;margin-top:26px;">
                                <div class="ui test checkbox">
                                    <input [checked]="OL == 'Y' " name="ol" value="N" (change)="changeEventOL($event)" type="checkbox" tabindex="0" class="hidden">
                                    <label>Over Length (CM)</label>
                                </div>
                            </div>
                            <div class="one field" style="width:35%" >
                                    <label>Front</label>
                                    <input type="number" name="frontMeasure" [(ngModel)]="frontMeasure" (change)="changeEventFrontMeasure($event)" [disabled]="lockOL == true"  placeholder="" >
                            </div>
                            <div class="one field" style="width:35%" >
                                    <label>End</label>
                                    <input type="number" name="endMeasure" [(ngModel)]="endMeasure" (change)="changeEventEndMeasure($event)" [disabled]="lockOL == true"  placeholder="" >
                            </div>
                        </div>

                    </fieldset>
                 </div>



            </div>
            <!--
            <div class="one field" style="width:40%; margin-top: -16px">
                <fieldset style="width: 100%;border: 1px solid lightgray;margin-top:8px;">
                    <legend style="font-weight:bold;color:dodgerblue;">Allocation</legend>
                    <grid-plugin #grid2 [settings]="settingGridAllocation2" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                </fieldset>
            </div>
        -->
        </div>

        <!-- glg was here -->
      <div class="field">
        <div class="fields">
          <div class="sixteen wide field">
            <fieldset style="border: 1px solid gray">
              <legend style="font-weight:bold;color:dodgerblue;">Contact</legend>
              <div class="field">
                <div class="fields">
                  <div class="six wide field">
                    <label>Haulier</label>
                    <combo-plugin #cbHaulier [settings]="settingHaulier" (change)="changeEventHaulier($event)" style="width:100%;"></combo-plugin>
                  </div>
                  <div class="eight wide field">
                    <label>Contact Person & Number</label>
                    <input type="text" name="bContactNumber" [(ngModel)]="bookingMisc['bContactPerson']"  placeholder="-- CONTACT PERSON & NUMBER  --" >
                  </div>
                  <div class="two wide field">
                    <label>Receipt Type</label>
                    <combo-plugin #cbReceiptTypeExtra [settings]="settingCbReceiptTypeExtra" (change)="changeEventCbReceiptTypeExtra($event)" style="width:100%;"></combo-plugin>
                  </div>
                  <!--
                  <div class="four wide field">
                    <label>Closing Time</label>
                    <input type="text" name="subTerminalReturnToClosingTime" [(ngModel)]="model['bHaulierContact']" style="background-color: lightgray" disabled >
                  </div>
                  -->
                </div>
              </div>

              <div class="field">
                <div class="fields">
                  <div class="six wide field">
                    <label>Release At</label>
                    <combo-plugin #cbReleaseAt [settings]="settingReleaseAt" (change)="changeEventReleaseAt($event)" style="width:100%;"></combo-plugin>
                    <!--
                    <label>Contact Person</label>
                    <input type="text" name="haulierContact" [(ngModel)]="model['bHaulierContact']"  placeholder="-- CONTACT PERSON  --" >
                    -->
                  </div>
                  <div class="three wide field">
                    <label>Date</label>
                    <datetime-plugin #dateReleaseAt [settings]="settingDateReleaseAt"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReleaseAt" placeholder="--:--" name="timeReleaseAt" [(ngModel)]="bookingMisc['bReleaseAtTime']" />
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="fields">
                  <div class="three wide field">
                    <label>Return To (POR)</label>
                    <combo-plugin #cbReturnToPor [settings]="settingReturnToPor" (change)="changeEventReturnToPor($event)" style="width:100%;"></combo-plugin>
                    <!--
                    <label>Packed At Sub Terminal</label>
                    <combo-plugin #cbPackedAt [settings]="settingPackedAt" (change)="changeEventPackedAt($event)" style="width:100%;"></combo-plugin>
                    -->
                  </div>
                  <div class="two wide field">
                    <label>Gate-In Date</label>
                    <datetime-plugin #dateReturnToPor  [settings]="settingDateReturnToPor"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPor" placeholder="--:--" name="timeReturnToPor" [(ngModel)]="bookingMisc['bReturnToPorGateInTime']" />
                  </div>
                  <div class="three wide field">
                    <label>Gate-In Closing Date</label>
                    <datetime-plugin #dateReturnToPorGateInClosing [settings]="settingDateReturnToPorGateInClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPorClosing" placeholder="--:--" name="timeReturnToPorClosing" [(ngModel)]="bookingMisc['bReturnToPorClosingTime']"  />
                  </div>
                  <div class="two wide field">
                    <label>VGM Closing Date</label>
                    <datetime-plugin #dateReturnToPorVgmClosing [settings]="settingDateReturnToPorVgmClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPorVgm" name="timeReturnToPorVgm"  placeholder="--:--" [(ngModel)]="bookingMisc['bReturnToPorVgmTime']"  />
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="fields">
                  <div class="three wide field">
                    <label>Return To (POL)</label>
                    <combo-plugin #cbReturnToPol [settings]="settingReturnToPol" (change)="changeEventReturnToPol($event)" style="width:100%;"></combo-plugin>
                    <!--
                    <label>Packed At Sub Terminal</label>
                    <combo-plugin #cbPackedAt [settings]="settingPackedAt" (change)="changeEventPackedAt($event)" style="width:100%;"></combo-plugin>
                    -->
                  </div>
                  <div class="two wide field">
                    <label>Gate-In Date</label>
                    <datetime-plugin #dateReturnToPol  [settings]="settingDateReturnToPol"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPol" placeholder="--:--" name="timeReturnToPol" [(ngModel)]="bookingMisc['bReturnToPolGateInTime']" />
                  </div>
                  <div class="three wide field">
                    <label>Gate-In Closing Date</label>
                    <datetime-plugin #dateReturnToPolGateInClosing [settings]="settingDateReturnToPolGateInClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPolClosing" placeholder="--:--"  name="timeReturnToPolClosing" [(ngModel)]="bookingMisc['bReturnToPolClosingTime']" />
                  </div>
                  <div class="two wide field">
                    <label>VGM Closing Date</label>
                    <datetime-plugin #dateReturnToPolVgmClosing [settings]="settingDateReturnToPolVgmClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeReturnToPolVgm" placeholder="--:--" name="timeReturnToPolVgm"  [(ngModel)]="bookingMisc['bReturnToPolVgmTime']"  />
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="fields">
                  <div class="three wide field">
                    <label>Stuff At (POR)</label>
                    <combo-plugin #cbStuffAt [settings]="settingStuffAt" (change)="changeEventStuffAt($event)" style="width:100%;"></combo-plugin>
                    <!--
                    <label>Reg. Release Date</label>
                    <datetime-plugin #dtRequestReleaseDate [pluginType]="typeReqReleaseDate" [settings]="settingReqReleaseDate" ></datetime-plugin>
                    -->
                  </div>
                  <div class="two wide field">
                    <label>Stuffing Date</label>
                    <datetime-plugin #dateStuffAtPor  [settings]="settingDateStuffAtPor"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeStuffAtPor" name="timeStuffAtPor" placeholder="--:--"  [(ngModel)]="bookingMisc['bStuffAtPorGateInTime']"  />
                  </div>
                  <div class="three wide field">
                    <label>Stuffing Closing Date</label>
                    <datetime-plugin #dateStuffAtPorGateInClosing  [settings]="settingDateStuffAtPorGateInClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeStuffAtPorClosing"  placeholder="--:--"  name="timeStuffAtPorClosing" [(ngModel)]="bookingMisc['bStuffAtPorClosingTime']"  />
                  </div>
                  <div class="two wide field">
                    <label>VGM Closing Date</label>
                    <datetime-plugin #dateStuffAtPorVgmClosing  [settings]="settingDateStuffAtPorVgmClosing"  ></datetime-plugin>
                  </div>
                  <div class="two wide field">
                    <label>Time</label>
                    <input type="text" id="timeStuffAtPorVgm" placeholder="--:--"  name="timeStuffAtPorVgm" [(ngModel)]="bookingMisc['bStuffAtPorVgmTime']"  />
                  </div>
                  <!--
                  <div class="six wide field">
                    <label>Exp. Return Date</label>
                    <datetime-plugin #dtExpReturnDate [pluginType]="typeExpReturnDate" [settings]="settingExpReturnDate" ></datetime-plugin>
                  </div>
                  <div class="four wide field">
                    &nbsp;
                  </div>
                  -->
                </div>
              </div>

              <div class="field">
                <div class="fields">
                  <div class="sixteen wide field">
                    <label>Depot / Terminal Remarks</label>
                    <textarea name="contactRemarks" style="font-size:12px;" [(ngModel)]="bookingMisc['bAdditionalRemarks']"  placeholder="-- REMARKS --" rows="4"></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>




        </div>


        <div class="field">
          <div class="fields">
            <div class="ten wide field">
              <fieldset style="border: 1px solid lightgray">
                <legend style="font-weight:bold;color:dodgerblue;">Mandatory For CFS Cargoes</legend>
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <label>Wt. (KGS)</label>
                      <input type="number" name="wt" [(ngModel)]="model['bWt']" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);"  placeholder="" >
                    </div>
                    <div class="eight wide field">
                      <label>Meas. (CBM)</label>
                      <input type="number" name="measure" [(ngModel)]="model['bMeasure']" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);"  placeholder="" >
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <label>No. of Pkg</label>
                      <input type="text" name="packageNo" [(ngModel)]="model['bPackageNo']"  placeholder="-- No. of Pkg -- " >
                    </div>
                    <div class="eight wide field">
                      <label>Pkg Type</label>
                      <combo-plugin #cbPackageType [settings]="settingPackageType" (change)="changeEventPackageType($event)" style="width:100%;"></combo-plugin>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="three wide field">
              <fieldset style="border: 1px solid gray">
                <legend style="font-weight:bold;color:dodgerblue;">Detention</legend>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDetention'] == 'N' " (change)="changeEventDetention($event)" value="N" name="detention" type="radio" tabindex="0" class="hidden">
                    <label>N/A</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDetention'] == 'S' " (change)="changeEventDetention($event)" value="S" name="detention" type="radio" tabindex="0" class="hidden">
                    <label>Standard</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDetention'] == 'R' " (change)="changeEventDetention($event)" value="R" name="detention" type="radio" tabindex="0" class="hidden">
                    <label>Request</label>
                  </div>
                </div>
                <div class="field" style="margin-top:5px">
                  <input type="number" name="detentionReq" (change)="changeEventDetentionReq($event)" [(ngModel)]="model['bDetentionRequest']" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);" [disabled]="lockDetention == true" placeholder="">
                </div>
              </fieldset>
            </div>

            <div class="three wide field">
              <fieldset style="border: 1px solid gray">
                <legend style="font-weight:bold;color:dodgerblue;">Demurrage</legend>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDemurrage'] == 'N' " (change)="changeEventDemurrage($event)" value="N" name="demurrage" type="radio" tabindex="0" class="hidden">
                    <label>N/A</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDemurrage'] == 'S' " (change)="changeEventDemurrage($event)" value="S" name="demurrage" type="radio" tabindex="0" class="hidden">
                    <label>Standard</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="model['bDemurrage'] == 'R' " (change)="changeEventDemurrage($event)" value="R" name="demurrage" type="radio" tabindex="0" class="hidden">
                    <label>Request</label>
                  </div>
                </div>
                <div class="field" style="margin-top:5px">
                  <input type="number" name="demurrageReq" (change)="changeEventDemurrageReq($event)" [(ngModel)]="model['bDemurrageRequest']" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);"  [disabled]="lockDemurrage == true"  placeholder="">
                </div>

              </fieldset>
            </div>



          </div>
        </div>

        <div class="field">
          <div class="fields">
            <div class="ten wide field">
              &nbsp;
            </div>
            <div class="six wide field" style="margin-top: -54px">
              <fieldset style="border: 1px solid gray">
                <legend style="font-weight:bold;color:dodgerblue;">Door Open</legend>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="doorOpen == 'N' " (change)="changeEventDoorOpen($event)" value="N" name="doorOpen" type="radio" tabindex="0" class="hidden">
                    <label>N/A</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="doorOpen == 'D' " (change)="changeEventDoorOpen($event)" value="D" name="doorOpen" type="radio" tabindex="0" class="hidden">
                    <label>Detached</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="doorOpen == 'B' " (change)="changeEventDoorOpen($event)" value="B" name="doorOpen" type="radio" tabindex="0" class="hidden">
                    <label>Tied Both</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="doorOpen == 'L' " (change)="changeEventDoorOpen($event)" value="L" name="doorOpen" type="radio" tabindex="0" class="hidden">
                    <label>Tied Left</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input [checked]="doorOpen == 'R' " (change)="changeEventDoorOpen($event)" value="R" name="doorOpen" type="radio" tabindex="0" class="hidden">
                    <label>Tied Right</label>
                  </div>
                </div>
              </fieldset>


            </div>
          </div>
        </div>

        <div class="field">
          <div class="fields">
            <div class="ten wide field">
              &nbsp;
            </div>
            <div class="six wide field">
              <fieldset style="border: 1px solid gray;">
                <legend style="font-weight:bold;color:dodgerblue;">UC</legend>
                <div class="field">
                  <div class="fields">
                    <div class="five wide field" [class.error]="model['error-ucValidator'] != null && model['error-ucValidator'] != ''">
                      <label>Height</label>
                      <input type="number" name="uch" [(ngModel)]="UCH"  placeholder="" >
                    </div>
                    <div class="five wide field" [class.error]="model['error-ucValidator'] != null && model['error-ucValidator'] != ''">
                      <label>Length</label>
                      <input type="number" name="ucl" [(ngModel)]="UCL"  placeholder="" >
                    </div>
                    <div class="five wide field" [class.error]="model['error-ucValidator'] != null && model['error-ucValidator'] != ''">
                      <label>Width</label>
                      <input type="number" name="ucw" [(ngModel)]="UCW"  placeholder="" >
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>


      </div>

    </div>
        <div class="ui bottom attached tab segment" id="freightChargesTab" data-tab="freightCharges">
            <!-- Freight Charges -->
            <div class="ui inverted dimmer active" style="z-index:9;opacity:0.2;" [class.hidden]="lock == false">
                <i class="lockicon circular inverted large lock icon"></i>
            </div>

            <div class="twelve wide fields">
                <div class="two field" style="width:58%;">
                    <div class="one field" style="width:100%">
                        <grid-plugin #gridFreightCharges [settings]="settingGridFreightCharges" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </div>
                    <br>
                    <div class="two wide fields">
                    <div class="one field" style="width:50%">
                        <grid-plugin #gridFreightPerSummary [settings]="settingGridFreightPerSummary" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </div>

                    <div class="field" style="width:50%;">

                            <div class="two wide fields" style="margin-bottom: 5px">
                                <div class="field">
                                    <label>Effective From</label>
                                    <input type="text" [(ngModel)]="refreightEffectiveFrom" name="effectiveFrom"  placeholder="DD/MM/YYYY" readonly="true" style="background-color: lightgray;">
                                </div>
                                <div class="field">
                                    <label>Expiry On</label>
                                    <input type="text" [(ngModel)]="refreightExpiryOn" name="expiryOn"  placeholder="DD/MM/YYYY" readonly="true" style="background-color: lightgray;">
                                </div>
                            </div>

                            <div class="two wide fields" style="margin-bottom: 5px">
                                <div class="field">
                                    <label>Modified By</label>
                                    <input type="text" [(ngModel)]="refreightModifiedBy" name="modifiedBy"  placeholder="" readonly="true" style="background-color: lightgray;">
                                </div>
                                <div class="field">
                                    <label>Modified Date</label>
                                    <input type="text" [(ngModel)]="refreightModifiedDate" name="modifiedDate"  placeholder="DD/MM/YYYY" readonly="true" style="background-color: lightgray;">
                                </div>
                            </div>

                            <div class="two wide fields" style="margin-bottom: 5px">
                              <div class="field">
                                <label>Account PIC</label>
                                <input type="text" [(ngModel)]="refreightAccountPIC" name="accountPIC"  placeholder="" readonly="true" style="background-color: lightgray;">
                              </div>
                            </div>
                            <div class="two wide fields" style="margin-bottom: 5px">
                              <div class="field">
                                <label>Approved By</label>
                                <input type="text" [(ngModel)]="refreightApprovalBy" name="approvedBy"  placeholder="" readonly="true" style="background-color: lightgray;">
                              </div>
                              <div class="field">
                                <label>Approved Date</label>
                                <input type="text" [(ngModel)]="refreightApprovedDate" name="approvedDate"  placeholder="" readonly="true" style="background-color: lightgray;">
                              </div>

                            </div>
                    </div>
                    </div>
                </div>
                <div class="one field" style="width:40%;margin-top: -8px;">
                    <fieldset style="width: 100%;border: 1px solid lightgray">
                        <!--
                        <legend style="font-weight:bold;color:dodgerblue;">Allocation</legend>
                        <div class="field">
                          <grid-plugin #grid3 [settings]="settingGridAllocation3" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                        </div>
                        -->

                        <div class="field" style="margin-top: 10px">

                            <div class="field">
                              <grid-plugin #gridFreightCurrencySummary [settings]="settingGridFreightCurrencySummary" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                            </div>

                        </div>

                    </fieldset>
                </div>
            </div>
        </div>



        <div class="ui bottom attached tab segment" id="containerInfoTab" data-tab="containerInfo">
            <!-- Container Info -->
            <div class="ui inverted dimmer active" style="z-index:9;opacity:0.2;" [class.hidden]="lock == false">
                <i class="lockicon circular inverted large lock icon"></i>
            </div>

            <div class="field">
              <grid-plugin #gridContainerInfo [settings]="settingGridContainerInfo" (gridEvent)="gridEventContainerInfo($event)" (infoGrid)="infoGridContainerInfo($event)"></grid-plugin>
            </div>

            <div class="field">
              <button class="ui button" (click)="onContainerInfo__New()" [class.disabled]="lockBtnContainerNew == true">New</button>
              <button class="ui button" (click)="onContainerInfo__SaveUpdate()" [class.disabled]="lockBtnContainerSaveUpdate == true">Save/Update</button>
              <button class="ui button" (click)="onContainerInfo__Delete()" [class.disabled]="lockBtnContainerDelete == true">Delete</button>
              <button class="ui button" (click)="onContainerInfo__Cancel()" [class.disabled]="lockBtnContainerCancel == true">Cancel</button>
            </div>

          <div class="ui error message" [class.errorContainer]="isErrorContainerInfo == true">
            <ul>
              <li [class.hidden]="bookingContainerInfo['error-bContainerNo'] == null || bookingContainerInfo['error-bContainerNo'] == ''">
                {{bookingContainerInfo['error-bContainerNo']}}
              </li>
              <li [class.hidden]="bookingContainerInfo['error-checkWtMeasValidator'] == null || bookingContainerInfo['error-checkWtMeasValidator'] == ''">
                {{bookingContainerInfo['error-checkWtMeasValidator']}}
              </li>
            </ul>
          </div>

            <div class="field" style="margin-bottom: 5px">
              <div class="fields" style="margin-bottom: 0px">
                <div class="one wide field">
                  <label>Seq: </label>
                  <input type="text" [(ngModel)]="bookingContainerInfo['bSeqNo']" name="bContainerSeq" disabled="true" style="background-color: lightgray;">
                </div>
                <div class="two wide field">
                  <label>Page No: </label>
                  <input type="text" name="bContainerPageNo" [(ngModel)]="bookingContainerInfo['bPageNo']" disabled style="background-color: lightgray;" maxlength="20" >
                </div>
                <div class="two wide field">
                  <label>Vent: </label>
                  <input type="text" name="bContainerVent" [(ngModel)]="bookingContainerInfo['bVent']" disabled style="background-color: lightgray;" maxlength="20">
                </div>
                <div class="two wide field">
                  <label>No. Of Pkg: </label>
                  <input type="number" name="bContainerNoOfPkg" [(ngModel)]="bookingContainerInfo['bNoOfPkg']" disabled style="background-color: lightgray;">
                </div>
                <div class="two wide field">
                  <label>Pkg Group: </label>
                  <input type="text" name="bContainerPkgGroup" [(ngModel)]="bookingContainerInfo['bPkgGroup']" disabled style="background-color: lightgray;" maxlength="50">
                </div>
                <div class="two wide field">
                  <label>Release Date: </label>
                  <!--calendar-plugin #dtReleaseDate [pluginType]="typeReleaseDate" [settings]="settingReleaseDate" ></calendar-plugin-->
                  <input type="text" name="bContainerReleaseDate" [(ngModel)]="bookingContainerInfo['bReleaseDate']" [disabled]="true" style="background-color: lightgray;">
                </div>
                <div class="two wide field">
                  <label>Release Time:</label>
                  <input type="text" name="bContainerReleaseTime" [(ngModel)]="bookingContainerInfo['bReleaseTime']" [disabled]="true" style="background-color: lightgray;">
                </div>
              </div>
            </div>


          <!-- Row 2 -->
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 0px">
              <div class="two wide field" >
                <label>Package Type: </label>
                <combo-plugin #cbBContainerPackageType [settings]="settingCbBContainerPackageType" (change)="changeEventCbBContainerPackageType($event);" [disableCombo]="lockContainerInfo==true"></combo-plugin>
              </div>
              <div class="two wide field">
                <label>PSA Class: </label>
                <input type="text" name="bContainerPSAClass" [(ngModel)]="bookingContainerInfo['bPSAClass']" disabled style="background-color: lightgray;" maxlength="50">
              </div>
              <div class="two wide field">
                <label>Cont Operator: </label>
                <input type="text" name="bContainerContOperator" [(ngModel)]="bookingContainerInfo['bContOperator']" [disabled]="lockContainerInfo==true" maxlength="20">
              </div>
              <div class="two wide field">
                <div class="ui checkbox" style="margin-top: 20px" [class.disabled]="true">
                  <input type="checkbox"  name="chBlContainerEmpty"  tabindex="0">
                  <label>Part Lot</label>
                </div>
              </div>
              <div class="five wide field">
                <label>True Shipping Name: </label>
                <input type="text" name="bContainerTrueShippingName" [(ngModel)]="bookingContainerInfo['bTrueShippingName']" [disabled]="true" style="background-color: lightgray" maxlength="50">
              </div>
            </div>
          </div>
          <!-- End of Row 2 -->


          <!-- Row 3 -->
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 0px">
              <div class="two wide field" >
                <label>Cont Type: </label>
                <!-- input type="text" name="bContainerContType" [(ngModel)]="bookingContainerInfo['bContType']" [disabled]="lockContainerInfo==true" maxlength="20" -->
                <div id="typeSize" class="ui selection search dropdown" style="font-size:11px;margin-top:0px;height:32px;min-width: 12em" [class.loading]="typeSizeComboLoading" [class.disabled]="typeSizeComboDisabled">
                    <input type="hidden" name="dtSize" [(ngModel)]="bookingContainerInfo['bContType']">
                    <i class="dropdown icon"></i>
                    <div class="default text" style="font-size:11px">Select</div>
                    <div class="menu">
                    </div>
                </div>
              </div>
              <div class="two wide field">
                <label>Gross Wt (KGS): </label>
                <input type="number" name="bContainerGrossWeight" [(ngModel)]="bookingContainerInfo['bGrossWeight']" [disabled]="lockContainerInfo==true">
              </div>
              <div class="two wide field">
                <label>Gross Meas (M3): </label>
                <input type="number" name="bContainerGrossMeas" [(ngModel)]="bookingContainerInfo['bGrossMeas']" [disabled]="lockContainerInfo==true">
              </div>
              <div class="two wide field">
                <label>Nett Wt (KGS): </label>
                <input type="number" name="bContainerNettWt" [(ngModel)]="bookingContainerInfo['bNettWeight']" [disabled]="lockContainerInfo==true">
              </div>
              <div class="two wide field">
                <label>Label: </label>
                <input type="text" name="bContainerLabel" [(ngModel)]="bookingContainerInfo['bLabel']" disabled style="background-color: lightgray;" maxlength="100">
              </div>
              <div class="four wide field">
                <label>Chemical Name [Commodity]: </label>
                <input type="text" name="bContainerChemicalName" [(ngModel)]="bookingContainerInfo['bChemicalName']" [disabled]="true" style="background-color: lightgray" maxlength="50">
              </div>
            </div>
          </div>
          <!-- End of Row 3 -->


          <!-- Row 4 -->
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 0px">
              <div class="two wide field" >
                <label>IMO No: </label>
                <input type="text" name="bContainerIMMO" [(ngModel)]="bookingContainerInfo['bIMO']" [disabled]="lockContainerInfo==true" maxlength="20">
              </div>
              <div class="two wide field">
                <label>UN No: </label>
                <input type="text" name="bContainerUNNO" [(ngModel)]="bookingContainerInfo['bUNNO']" [disabled]="lockContainerInfo==true" maxlength="20">
              </div>
              <div class="two wide field">
                <label>Flash Point: </label>
                <input type="number" name="bContainerFlashPoint" [(ngModel)]="bookingContainerInfo['bFlashPoint']" [disabled]="lockContainerInfo==true">
              </div>
              <div class="two wide field">
                <label>EMS: </label>
                <input type="text" name="bContainerEMS" [(ngModel)]="bookingContainerInfo['bEMS']" disabled style="background-color: lightgray;" maxlength="50">
              </div>
              <div class="two wide field">
                <label>MFAG: </label>
                <input type="text" name="bContainerMFAG" [(ngModel)]="bookingContainerInfo['bMFAG']" disabled style="background-color: lightgray;" maxlength="50">
              </div>
              <div class="three wide field">
                <label>Receipt Type: </label>
                <!--input type="text" name="bContainerReceiptType" [(ngModel)]="bookingContainerInfo['bReceiptType']" [disabled]="lockContainerInfo==true" -->
                <combo-plugin #cbBContainerReceiptType [settings]="settingBContainerReceiptType" (change)="changeEventBContainerReceiptType($event)" [disableCombo]="lockContainerInfo==true"></combo-plugin>
              </div>
            </div>
          </div>
          <!-- End of Row 4 -->


          <!-- Row 4 -->
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 0px">
              <div class="two wide field" >
                <label>Container No: </label>
                <input type="text" name="bContainerContainerNo" [(ngModel)]="bookingContainerInfo['bContainerNo']" [disabled]="lockContainerInfo==true" maxlength="50">
              </div>
              <div class="two wide field">
                <label>Cell Location: </label>
                <input type="text" name="bContainerCellLocation" [(ngModel)]="bookingContainerInfo['bCellLocation']" [disabled]="lockContainerInfo==true" maxlength="50">
              </div>
              <div class="two wide field">
                <label>Mark: </label>
                <input type="text" name="bContainerMark" [(ngModel)]="bookingContainerInfo['bMark']" disabled style="background-color: lightgray;" maxlength="100">
              </div>
              <div class="two wide field">
                <div class="ui checkbox" style="margin-top: 20px">
                  <input type="checkbox"  name="bContainerVGMEnabled" [checked]="bookingContainerInfo['bVGMEnabled'] ==true " [(ngModel)]="bookingContainerInfo['bVGMEnabled']" (change)="changeEventChBookingContainerInfo($event)"  tabindex="0">
                  <label>VGM Enabled</label>
                </div>
              </div>
              <div class="two wide field">
                <label>VGM Method: </label>
                <combo-plugin #cbVGMMethod [settings]="settingCbVGMMethod" (change)="changeEventVGMMethod($event);" [disableCombo]="lockContainerInfo==true && bookingContainerInfo['bVGMEnabled']==false"></combo-plugin>
                <!--input type="text" name="bContainerVGMType" [(ngModel)]="bookingContainerInfo['bVGMType']" [disabled]="lockContainerInfo==true" -->
              </div>
              <div class="three wide field">
                <label>VGM Wt (KGS): </label>
                <input type="number" name="bContainerVGMWeight" [(ngModel)]="bookingContainerInfo['bVGMWeight']" [disabled]="lockContainerInfo==true && bookingContainerInfo['bVGMEnabled']==false">
              </div>
            </div>
          </div>
                <!-- End of Row 5 -->

          <!-- Row 4 -->
          <div class="field" style="margin-bottom: 5px">
            <div class="fields" style="margin-bottom: 0px">
              <div class="six wide field" >
                <label>Other Details</label>
                <textarea name="bContainerOtherDetails" [(ngModel)]="bookingContainerInfo['bOtherDetails']"  placeholder="-- OTHER DETAILS --" rows="3"></textarea>
              </div>
            </div>
          </div>
          <!-- End of Row 5 -->

        </div>




         <div class="ui bottom attached tab segment" id="terminalInfoTab" data-tab="terminalInfo">
            <!-- Terminal Info -->
            <div class="ui inverted dimmer active" style="z-index:9;opacity:0.2;" [class.hidden]="lock == false">
                <i class="lockicon circular inverted large lock icon"></i>
            </div>

            <div class="twelve wide fields">
                <div class="one field" style="width:58%;border: 1px solid lightgray;
                border-radius: .28571429rem;padding: 9px;padding-bottom: 0px;">

                    <div class="ten wide fields">
                        <div class="three field" style="width:100%" >
                          <label>POR Terminal</label>
                          <combo-plugin #cbPORTerminal [settings]="settingPORTerminal" [disableCombo]="lockPORTerminal"  (change)="changeEventPORTerminal($event)" style="width:100%;"></combo-plugin>

                        </div>
                        <div class="three field" style="width:100%">
                          <label>FDEST Terminal</label>
                          <combo-plugin #cbFinalDestTerminal [settings]="settingFinalDestTerminal" [disableCombo]="lockFDESTTerminal" (change)="changeEventFinalDestTerminal($event)" style="width:100%;"></combo-plugin>
                        </div>
                    </div>

                    <div class="ten wide fields">
                        <div class="three field" style="width:100%" >
                          <label>POL Terminal</label>
                          <combo-plugin #cbLoadPortTerminal [settings]="settingLoadPortTerminal" [disableCombo]="lockPOLTerminal" (change)="changeEventLoadPortTerminal($event)" style="width:100%;"></combo-plugin>
                        </div>
                        <div class="three field" style="width:100%">
                          <label>POT1 Terminal</label>
                          <combo-plugin #cbPOT1Terminal [settings]="settingPOT1Terminal" [disableCombo]="lockPOT1Terminal" (change)="changeEventPOT1Terminal($event)" style="width:100%;"></combo-plugin>

                        </div>
                    </div>

                    <div class="ten wide fields">
                        <div class="three field" style="width:100%">
                            <label>POD Terminal</label>
                            <combo-plugin #cbDischargePortTerminal [settings]="settingDischargePortTerminal" [disableCombo]="lockPODTerminal" (change)="changeEventDischargePortTerminal($event)" style="width:100%;"></combo-plugin>
                        </div>

                        <div class="three field" style="width:100%">
                          <label>POT2 Terminal</label>
                          <combo-plugin #cbPOT2Terminal [settings]="settingPOT2Terminal" [disableCombo]="lockPOT2Terminal" (change)="changeEventPOT2Terminal($event)" style="width:100%;"></combo-plugin>

                        </div>
                    </div>

                    <div class="ten wide fields">
                        <div class="three field" style="width:100%" >
                          <label>PODL Terminal</label>
                          <combo-plugin #cbPODLTerminal [settings]="settingPODLTerminal" [disableCombo]="lockPODLTerminal" (change)="changeEventPODLTerminal($event)" style="width:100%;"></combo-plugin>

                        </div>
                        <div class="three field" style="width:100%">
                          <label>POT3 Terminal</label>
                          <combo-plugin #cbPOT3Terminal [settings]="settingPOT3Terminal" [disableCombo]="lockPOT3Terminal" (change)="changeEventPOT3Terminal($event)" style="width:100%;"></combo-plugin>

                        </div>
                    </div>

                </div>
                <div class="one field" style="width:40%; margin-top: -16px">
                    <fieldset style="width: 100%;border: 1px solid lightgray;margin-top:8px;">
                        <legend style="font-weight:bold;color:dodgerblue;">Allocation</legend>
                        <grid-plugin #grid5 [settings]="settingGridAllocation5" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </fieldset>
                </div>
            </div>
        </div>

   </form>

</div>

<a id="openBookingAllotment" class="scrollToTop" style="display: block;" (click)="openBookingAllotment()">
    <i class="circular big chevron left inverted grey icon" style="margin-left:4px;"></i>
</a>


<div id="sidebar-booking" class="ui right sidebar cover overlay" style="width: 500px;overflow-x: hidden;box-shadow:none;"> <!-- cover overlay -->

    <a class="scrollToTop_right" style="display: block;background-color: ghostwhite" (click)="closeBookingAllotment()">
        <i class="big chevron right icon" style="margin-left:-11px;"></i>
    </a>

    <div style="background-color: transparent;width:100%;height:100%;">
        <div style="background-color: ghostwhite;height: 100%;width:100%;margin-left:25px;">
            <div class="ui raised segment">

                <div class="one field" style="width:40%">
                    <fieldset style="width: 250px;border: 1px solid gray;margin-top:-8px;margin-bottom:10px;">
                       <legend style="font-weight:bold;color:dodgerblue;">Weight Class Legend</legend>
                       Empty =   3000 KGS<br/>
                       Medium = 15000 KGS<br/>
                       Heavy = 20000 KGS<br/>
                       Extra Heavy = 24000 KGS<br/>
                       Light = 10000 KGS<br/>
                    </fieldset>
                </div>

                <div class="one field" style="width:40%">
                    <fieldset style="width: 250px;border: 1px solid gray;margin-top:-8px;margin-bottom:10px;">
                       <legend style="font-weight:bold;color:dodgerblue;">Weight Profiles</legend>
                       <grid-plugin #gridWeightProfiles [settings]="settingGridWeightProfiles" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </fieldset>
                </div>

                <div class="one field" style="width:40%">
                    <fieldset style="width: 250px;border: 1px solid gray;margin-top:-8px;">
                        <legend style="font-weight:bold;color:dodgerblue;">Container Profiles</legend>
                        <grid-plugin #gridContainerProfiles [settings]="settingGridContainerProfiles" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </fieldset>
                </div>
            </div>
        </div>
    </div>

</div>

<div id="compareDialog" class="ui large modal"  style="margin-right:400px;padding:1%;">
    <form class="ui form">
        <div class="six wide fields">
             <div class="one field" style="width:100%;">
                  <label>{{refreightMessage}}</label>
                  <label style="font-weight:normal;">If you want to compare between this booking and rate, please input beside reference no and click compare button</label>
            </div>
            <div class="one field" style="width:30%;">
                    <input type="text" name="referenceNo"  [(ngModel)]="refreightRefNo"  placeholder="Please input Reference No" >
              </div>
        </div>
        <div class="six wide fields">
                <div class="two field" style="width:100%;">
                    <button class="tiny ui positive button" (click) = "onCompare();" >Compare</button>
                    <button class="tiny ui negative button" (click) = "onCancelCompare();">Cancel</button>
                </div>
            </div>
    </form>
</div>

<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF"></pdf-plugin>
