import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, ToolbarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService, DatePluginComponent, } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { GridPluginComponent2 } from '../shared/index';

declare  var $:any;

export class Gst{
  gstId:string = '';
  gstPercentage:string='0';
  gstRemarks:string='';
  isValid        : boolean = false;
  userCreated : string = '';
  dateCreated : string = '';
  userModified : string = '';
  dateModified : string = '';
  userInvalid : string = '';
  dateInvalid : string = '';
  officeCode: string = '';
  gstEffectiveFrom : string = '';
  gstEffectiveTo : string = '';

}

@Component({
  selector: 'app-local-master-gst-page',
  templateUrl: './local-master-gst-page.component.html',
  styleUrls: ['./local-master-gst-page.component.css']
})
export class LocalMasterGstPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent2;
  @ViewChild('cbGstHistory') cbGstHistory:ComboPluginComponent;
  @ViewChild('gstEffectiveFrom') gstEffectiveFrom: DatePluginComponent;
  @ViewChild('gstEffectiveTo') gstEffectiveTo: DatePluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;


  modeForm = false;
  modeToolbar = false;
  form = '';
  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingCbHistory;
  settingDateEffectiveFrom;
  settingDateEffectiveTo;
  valGstId: string = '';
  valGstPercentage: string = '';
  lock = false;

  tempInvalid = false;


  model = new Gst();

  validatorRules = {};

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;
  lockEffectiveFrom;
  lockEffectiveTo;

  myOptions = {
    allowDecimalPadding: false,
    decimalPlaces: 2,
    maximumValue: "999",
    minimumValue: "0",
    digitGroupSeparator:"",
    leadingZero: "keep"
  }
  gstEffectiveFromValue: any;
  gstEffectiveToValue:any;
  count = 0;

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        { display: 'GST Percentage', value: 'gstPercentage'},
        { display: 'Effective From', value: 'gstEffectiveFrom'},
        { display: 'Expiry On', value: 'gstEffectiveTo'},
        { display: 'Remarks', value: 'gstRemarks'},
        { display: 'Valid', value: 'isValid'},
        { display: 'User Created', value: 'userCreated'},
        { display: 'Date Created', value: 'dateCreated'},
        { display: 'User Modified', value: 'userModified'},
        { display: 'Date Modified', value: 'dateModified'},
        { display: 'User Invalid', value: 'userInvalid'},
        { display: 'Date Invalid', value: 'dateInvalid'},
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findGeneralLocalMasterGst',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'GST Percentage (%)', field: 'gstPercentage', width: 100},
        {header: 'Effective From', field: 'gstEffectiveFrom', width: 110},
        {header: 'Expiry On', field: 'gstEffectiveTo', width: 110},
        {header: 'Remarks', field: 'gstRemarks', width: 200},
        {header: 'User Created', field: 'userCreated', width: 110},
        {header: 'Date Created', field: 'dateCreated', width: 110},
        {header: 'User Modified', field: 'userModified', width: 110},
        {header: 'Date Modified', field: 'dateModified', width: 110},
        {header: 'User Invalid', field: 'userInvalid', width: 110},
        {header: 'Date Invalid', field: 'dateInvalid', width: 110},
        {header: 'Valid', field: 'isValid', width: 80},
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'gstPercentage',
      sortingDirection: 'ASC'
    }

    this.settingGridHistory = {
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findGeneralGstHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'GST Percentage (%)', field: 'gstPercentage', width: 100},
        {header: 'Remarks', field: 'gstRemarks', width: 200},
        {header: 'User Created', field: 'userCreated', width: 110},
        {header: 'Date Created', field: 'dateCreated', width: 110},
        {header: 'User Modified', field: 'userModified', width: 110},
        {header: 'Date Modified', field: 'dateModified', width: 110},
        {header: 'User Invalid', field: 'userInvalid', width: 110},
        {header: 'Date Invalid', field: 'dateInvalid', width: 110},
        {header: 'Valid', field: 'isValid', width: 80}
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,

      enablePagination: true
    }

    this.settingDateEffectiveFrom = {
      id: "gstEffectiveFrom",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Effective From",
      customtext: "",
      maxlength: 10
    };

    this.settingDateEffectiveTo = {
      id: "gstEffectiveTo",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Expiry on",
      customtext: "",
      maxlength: 10
    };

    this.settingCbHistory = {
      id          : 'cbGstHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCharges/findGeneralGstHistory/{query}',
      maxChars    : 1,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'gstPercentage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'GST Percentage (%)', field: 'gstPercentage', width: 200},
        {header: 'gstRemarks', field: 'gstRemarks', width: 250},
      ]
    }

    /*this.settingDateEffectiveFrom = {
      id: 'gstEffectiveFrom',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Effective From',
      customtext: '',
    }
    this.settingDateEffectiveTo = {
      id: 'gstEffectiveTo',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Expiry On',
      customtext: '',
    }*/

  }
  ngOnInit() {
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    this.firstInit();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  firstInit(){
    this.model = new Gst();
    this.model.officeCode = this.cookieService.getDefaultLocationCode();
    this.currentMenuID = this.cookieService.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  toolbarEvent(event:any) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':

        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate(){
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";

    this.model = new Gst;
    this.model.isValid = true;

  }

  handleUpdate(){
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock=false;
      this.disableToolbarButtons = "delete";
      this.invisibleToolbarButtons = "create,delete,print";
      this.tempInvalid = this.model.isValid;
    }

    //this.onRetrieve()
  }

  handleDelete(){
    // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.localChargeGrid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"gstId":""};
    //   deleteObj.gstId = element.gstId;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListLocalMasterGstUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint(){
    var formatName = "report-GST-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/69/Account-PIC/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/69/Account-PIC/*');
  }

  handleSave(){
    // handle save event
    //this.model.dateCreated = this.genericUtil.getPSIDate();
    // console.log(this.model);

    this.model['error-gstPercentage'] = '';
    this.model['error-gstEffectiveTo'] ='';
    this.model['error-gstEffectiveFrom'] = '';
    console.log('percentage before ' + this.model.gstPercentage);
    this.model.gstPercentage = Number.parseFloat(this.model.gstPercentage).toString();
    // this.model.gstEffectiveFrom = $('#gstEffectiveFrom').val();
    // this.model.gstEffectiveTo = $('#gstEffectiveTo').val();

    this.model.gstEffectiveFrom = this.gstEffectiveFrom.getValue();
    this.model.gstEffectiveTo = this.gstEffectiveTo.getValue();

    let isValid:boolean = this.onValidate(this.model);
    console.log('percentage ' + this.model.gstPercentage);
    console.log('effective From ' + this.model.gstEffectiveFrom);
    console.log('effective To ' + this.model.gstEffectiveTo);
    if(this.model.gstPercentage === '0' || this.model.gstPercentage === 'NaN'){
      isValid = true;
      this.model['error-gstPercentage'] = 'Please input GST Percentage.';
    }

    if(this.model.gstEffectiveFrom === '' || this.model.gstEffectiveFrom === null){
      isValid = true;
      this.model['error-gstEffectiveFrom'] = 'Please input Effective From.';
    }

    if (this.model.gstEffectiveTo != "") {
      if (this.model.gstEffectiveTo <= this.model.gstEffectiveFrom) {
        this.model['error-gstEffectiveTo'] = "Expiry On Date (" + this.model.gstEffectiveTo.toString() + ") must be later than Effective From.";
        return true;
      }
    }

    if(!this.checkDate(this.gstEffectiveFromValue)){
      this.model['error-gstEffectiveFrom'] = 'Please Input Valid Date.';
      isValid = true;

    }
    if(!this.checkDate(this.gstEffectiveToValue) && this.gstEffectiveTo.getValue() != ""){
      this.model['error-gstEffectiveTo'] = 'Please Input Valid Date.';
      isValid = true;

    }


    if(this.tempInvalid){
      this.model.userInvalid = ""
      this.model.dateInvalid = ""
    }


    //validation
    if(!isValid){
      this.model["userCreated"] = this.cookieService.getName();
      this.model["userModified"] = this.cookieService.getName();
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.model["dateModified"] = this.genericUtil.getPSIDate();
      this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.lock = false;
    this.cbGstHistory.setValue("")
    this.cbGstHistory.clearSelect()
    this.cbGstHistory.close();
    $('#gstEffectiveFrom').val(null);
    $('#gstEffectiveTo').val(null);
    this.gstEffectiveFrom.setValue("");
    this.gstEffectiveTo.setValue("");
    this.model['error-gstEffectiveTo'] ='';
  }

  handleHistory(){
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  setData(){
    let data = this.grid.getSelectedValues()[0];

    console.log(data.countryName)
    this.model = new Gst;

    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }

    this.model.gstId = data.gstId.trim().toUpperCase();
    this.model.gstPercentage = data.gstPercentage;
    this.model.gstRemarks = String(data.gstRemarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    // $('#gstEffectiveFrom').val(data.gstEffectiveFrom);
    // $('#gstEffectiveTo').val(data.gstEffectiveTo);
    this.model.gstEffectiveFrom = String(data.gstEffectiveFrom).trim().toUpperCase();
    this.model.gstEffectiveTo = String(data.gstEffectiveTo).trim().toUpperCase();
    this.gstEffectiveFrom.setValue(data.gstEffectiveFrom);
    this.gstEffectiveTo.setValue(data.gstEffectiveTo);

  }

  saveEvent(){
    this.loading = true;
    //// console.log(this.model);

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterCharges/saveGst',this.model).subscribe((resp) =>{
      if(resp.status == 200 && resp.json()['message'] == 'OK'){
        //clear all
        //this.model = new AccountPic;
        this.cbGstHistory.setValue("");

        this.loading = false;
        this.info = 'save successfully';

        // Reset input
        this.gstEffectiveFrom.setValue("");
        this.gstEffectiveTo.setValue("");
        this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});

      }else{
        this.loading = false;
        this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;this.afterUnsave();'});
      }
    },error => {
      this.loading = false;
      console.log(error.message);
      this.info = error.message;
    });
  }

  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.form = '';
    $('#gstEffectiveFrom').val();
    $('#gstEffectiveTo').val();
    this.model['error-gstEffectiveTo'] ='';
    this.lock =  false;

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();

    //  this.grid.onFirst();
  }

  afterUnsave(){
    this.model.userCreated = ""
    this.model.userInvalid = ""
    this.model.userModified = ""
    this.model.dateModified = ""
    this.model.dateInvalid = ""
    this.model.dateCreated = ""
  }

  deleteEvent(){
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.gstId);
    });

    // remove item duplicate in deleteList
    deleteList = deleteList.filter((item, pos)=>{
      return deleteList.indexOf(item) == pos;
    })

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"gstId":[]}
    paramsDelete.gstId = deleteList;

    console.log("delete list")
    console.log(deleteList)
    this.genericService.POST(
      this.configService.config.BASE_API.toString() +
      // '/MasterCharges/deleteGst',
      // deleteList
      "/MasterCharges/softDeleteGst",
        paramsDelete
      )
      .subscribe((resp) =>{
        if(resp.status == 200){
          //this.model = new AccountPic;
          this.cbGstHistory.setValue("");
          this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false;this.afterDelete();'});
        }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  gridEvent(event:any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          }
          else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;

      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          }
           else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              this.handleUpdate();
              break;
            case 'dblClick':
              let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                if(this.viewOnlyStatus){
                  this.disableToolbarButtons = "update,delete";
                } else {
                  this.disableToolbarButtons = "delete";
                }
                this.invisibleToolbarButtons = "create,history,print,delete";

                this.modeForm = true;
                this.modeToolbar = false;

                let dt = this.grid.getSelectedValues()[0];
                // let custId:string = String(dt.customerId).trim();
                // this.onRetrieve(custId);

                this.setData()
                this.lock = true;
              }
              break;

            default:
              break;
          }

        }

        break;
    }
  }


  changeEventGstHistory(event:any){
    // this.gridHistory.loadData();
    this.valGstId = event.gstId;
    this.valGstPercentage = event.gstPercentage;

    this.gridHistory.onHistory(this.valGstPercentage);

    console.log("eennt = ", event);

  }

  infoGridGstHistory(event:any){

  }

  gridEventGstHistory(event:any){

  }

  generateGstHistoryReport(event){
    //this.valHistoryStartDate= $('#logHistoryChargeStartDate').find('#datePicker').val();
    if (this.valGstId == "" || this.valGstId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-History-Local-Charge-"+this.valGstId+"-"+this.valGstPercentage+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/69/History-Local-Charge-"
      +
      this.valGstId + '-' +
      this.valGstPercentage +
      '/'+
      this.valGstId
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    //  window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/69/History-Local-Charge-' +
    //   this.valGstId + '-' +
    //   this.valGstPercentage +
    //   '/'+
    //   this.valGstId
    //  );

  }

  retrieveHistory(event:any) {
    this.gridHistory.search = this.valGstId;
    this.gridHistory.loadData();
  }

    NumbersOnlyPlusDot(event: any) {
    var regexPattern = new RegExp("[0-9\.]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }else{
      console.log(this.model.gstPercentage);
      console.log(event.key === '.');
      if(this.model.gstPercentage.includes('.')){
        if(event.key === '.'){
          event.preventDefault();
        }else{
          if(this.model.gstPercentage.split('.')[1].length >= 2){
            event.preventDefault();
          }
        }
      }
    }
  }

  afterDelete() {
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  checkDate(tempDate: string): boolean{
    // var specialChar =  /^[0-9]\-\*$/

    let result:boolean = true;

    // if(!this.model.gstEffectiveFrom.match(specialChar) ){
    //     result = true;
    //     console.log("masuk 2")
    // }
    // else if(!this.model.gstEffectiveTo.match(specialChar)){
    //   result = true;
    //   console.log("masuk 2")
    // }

    var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;
    var gstTemp = tempDate;

        if(!regex_date.test(gstTemp))
        {
          console.log("hahaha")
          return false;

        }

        // Parse the date parts to integers
        var parts   = gstTemp.split("-");
        var day     = parseInt(parts[2], 10);
        var month   = parseInt(parts[1], 10);
        var year    = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
        {
          console.log("hihihi")
          return false;
        }

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        {
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];


    // return result;
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

  changeDateTimeTo(value: string) {
    document.getElementById('gstEffectiveTo-date-input');
    console.log((document.getElementById('gstEffectiveTo-date-input') as any).value);
    this.gstEffectiveToValue = value.toString().slice(0, 8) === 'datepick' ? this.gstEffectiveTo.getValue() : (document.getElementById('gstEffectiveTo-date-input') as any).value;
    if(this.count === 0){
      this.count += 1;
      return;
    }
    if(this.checkDate(this.gstEffectiveToValue)){
      this.model['error-gstEffectiveTo'] = '';
    } else {
      this.model['error-gstEffectiveTo'] = 'Please Input Valid Date.';
    }
    console.log(this.gstEffectiveToValue, this.checkDate(this.gstEffectiveToValue), this.count);
  }

  changeDateTimeFrom(value: string) {
    document.getElementById('gstEffectiveFrom-date-input');
    console.log((document.getElementById('gstEffectiveFrom-date-input') as any).value);
    this.gstEffectiveFromValue = value.toString().slice(0, 8) === 'datepick' ? this.gstEffectiveFrom.getValue() : (document.getElementById('gstEffectiveFrom-date-input') as any).value;
    if(this.count === 0){
      this.count += 1;
      return;
    }
    if(this.checkDate(this.gstEffectiveFromValue)){
      this.model['error-gstEffectiveFrom'] = '';
    } else {
      this.model['error-gstEffectiveFrom'] = 'Please Input Valid Date.';
    }
    console.log(this.gstEffectiveFromValue, this.checkDate(this.gstEffectiveFromValue), this.count);
  }

}
