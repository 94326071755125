import { AllocationService } from './allocation.service';
import { finalize, takeUntil } from 'rxjs/operators';

import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  DatetimePluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DialogPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import FormValidation from "../../../utils/formValidation";
import { Subject } from 'rxjs';
import { TransactionTsPortHomeService } from '../transaction-ts-port-home.service';

declare var $: any;

export class SummaryPortPairAllocation {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyage: string = "";
  bound: string = "";
  eta: string = "";
  coc: number = 0;
  cocLocal: number = 0;
  cocTS: number = 0;
  bPOT = "";
  bPot1Code = "";
  bPot1Name = "";
  containerOwnerShip = "COC";
  tsServiceId = "";
  tsTotal = "";
  tsLocal = "";
  tsTS = "";
  tsEta = "";
  tsEtd = "";
  tsRotation = "";
  vesselName: any;
  totalAllocated: any;
  totalBooked: any;
  totalConfirmed: any;
  totalAvailable: any;
  tsServiceCode = "";
  isHide:Boolean = true;
  constructor() { }
}

export class SummaryConnectingVessel {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  vesselName: string = "";
  voyage: string = "";
  serviceName: string = "";
  containerOwnerShip = "COC";
  bound:string = "O";
  potCode:string = "";
  potName:string = "";
  eta: string = "";
  isHide:Boolean = true;
  constructor() { }
}

export class SummaryParam {
  officeCode: string = "";
  periodFrom: string = "";
  periodTo: string = "";
  potCode: string = "";
  potName: string = "";
  terminalCode: string = "";
  terminalName: string = "";
}

@Component({
  selector: "app-transaction-ts-summary-port-pair-allocation-page",
  templateUrl: "./transaction-ts-summary-port-pair-allocation-page.component.html",
  styleUrls: ["./transaction-ts-summary-port-pair-allocation-page.component.css"],
  providers: [
    AllocationService
 ]
})
export class TransactionTSSummaryPortPairAllocationPageComponent
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy {
  dialog: any;
  private onDestroy$ = new Subject<boolean>();
  @ViewChild('cbTerminal') cbTerminal:ComboPluginComponent;
  @ViewChild("cbPOT") cbPOT: ComboPluginComponent;
  @ViewChild('periodFrom') periodFrom:DatetimePluginComponent;
  @ViewChild('periodTo') periodTo:DatetimePluginComponent;
  @ViewChild("dialogPortPair") dialogPluginPortPair: any;
  
  // Form Validation
  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "insert";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;
  isLoadingDetail = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;

  modelParam = new SummaryParam();
  model = new SummaryPortPairAllocation();

  officeCode: any;

  getRowStyle: any;

  SummaryConnectingVesselList: SummaryConnectingVessel[] = [];

  isError: Boolean = false;
  isRetrieve: Boolean = false;
  hasTerminal: Boolean = false;
  

  validatorRules = {};
  defaultColumnDefs: any;
  rowData = [];
  settingTerminal;
  settingPOT: any;
  settingPeriodFrom;
  settingPeriodTo;
  viewOnlyStatus = false;
  gridApi: any;
  pinnedBottomRowData = [];
  columnApi: any;
  BASE_URL = this.configService.config.BASE_API.toString();
  totalAllocated: any;
  totalBooked: any;
  totalConfirmed: any;
  totalAvailable: any;
  
  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private allocationService: AllocationService,
    private transactionTsPortHomeService: TransactionTsPortHomeService
  ) {
    super();

    this.handleSetting();
  }

  ngOnInit() {
    this.officeCode = this.cookieService.getDefaultLocationCode().toString().trim();
    this.cdr.detectChanges();

    $('.ui.accordion')
    .accordion({
      exclusive: false
    });
  }

  ngAfterViewInit() {
    this.eventAfterInitView();
  }
  

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "search", icon: "search" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Summary - Transhipment Port-Pair Allocation",
    };

    this.defaultColumnDefs = [
      { headerName: 'Load Port', field: 'tslLoadPortName', width: 150 },
      { headerName: 'Terminal(POL)', field: 'tslLoadPortTerminalName', width: 150 },
      { headerName: 'Discharge Port', field: 'tslDischargePortName', width: 150 },
      { headerName: 'Terminal(POD)', field: 'tslDischargePortTerminalName', width: 200 },
      { headerName: 'Allocated(Teus)', field: 'tslAllocatedTeus' },
      { headerName: 'Booked(Teus)', field: 'tslBookedTeus' },
      { headerName: 'Confirmed(Teus)', field: 'tslConfirmedTeus' },
      { headerName: 'Available(Teus)', field: 'tslAvailableTeus' },
      { headerName: 'Status', field: 'tslStatus' },
      { headerName: 'Created By', field: 'tslCreatedUserid' },
      { headerName: 'Created Date', field: 'tslCreatedDate' },
      { headerName: 'Modified By', field: 'tslModifiedUserid' },
      { headerName: 'Modified Date', field: 'tslModifiedDate' },
      { headerName: 'Deleted By', field: 'tslDeletedUserid' },
      { headerName: 'Deleted Date', field: 'tslDeletedDate' },
    ];

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tslIsDeleted === "Y") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.settingPOT = {
      id: "cbPOT",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
       
      maxChars: 0,
      template: "grid", // default
      placeholder: "Port of Transhipment",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
        { header: "POT", field: "isPot", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPeriodFrom = {
      id:'periodFrom',
      type : 'date',
      format : 'yyyy-mm-dd',
      placeholder : '',
      customtext :'',
      popupOptions : 'bottom left'
    }

    this.settingPeriodTo = {
      id:'periodTo',
      type : 'date',
      format : 'yyyy-mm-dd',
      placeholder : '',
      customtext :'',
      popupOptions : 'bottom right'
    }

    this.settingTerminal = {
      id          : 'cbTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Terminal',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
                    {header: 'Location Name', field: 'locationName', width: 200},
                    {header: 'Location Code', field: 'locationCode', width: 50},
      ]
   }

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "search":
        this.handleSearch();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.handleCancel();
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorRetrieve() {
    this.modelParam["error-periodFrom"] = "";
    this.modelParam["error-periodTo"] = "";
    this.modelParam["error-potCode"] = "";

    this.validatorRules = {
      periodFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Please input period from.",
          },
        ],
      },
      periodTo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input period to.",
          },
        ],
      },
      potCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      },
     
    };

   
  }

  searchEvent() {
    this.genericUtil.showLoader();
    this.rowData = [];
    this.pinnedBottomRowData = [];
    this.disableToolbarButtons = "";
    
    this.allocationService.getConnectingVessel(this.modelParam).pipe(
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.genericUtil.hideLoader();
        this.disableToolbarButtons = "";
        
        this.isRetrieve = true;
      })
    ).subscribe(
      res => {
        if (res) {
          const data = res.json();
          this.handleAfterSearch(data);
        } else {
          this.message(
            "Information",
            "information",
            'Something went wrong',
            "okonly",
            { ok: "" }
          );
          this.saveStage([]);
        }
      }
    )
  }

  handleAfterSearch(data) {
    if (data.message) {
      this.SummaryConnectingVesselList = [];
      this.saveStage([]);
      this.message(
        "Information",
        "information",
        data.message,
        "okonly",
        { ok: "" }
      );
      return;
    }
    this.disableToolbarButtons = "";
    console.log(data)
    this.SummaryConnectingVesselList = data.vesselList;

    this.saveStage(data.vesselList);
  }

  saveStage(gird){
    let paramData = {
      modelParam: this.modelParam,
      gird: gird,
    }
    this.transactionTsPortHomeService.setDataSummaryPortPairAllocation(paramData);
  }

  showDialog(id) {
    $(id).modal("setting", "closable", false).modal("show");
  }

  closeDialog(id) {
    $(id).modal("setting", "closable", false).modal("hide");
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();

  }


  onCancel() {
   
  }


  onClose() {
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any,
    id?: string
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
      id: 'dialog-port-pair'
    };
    setTimeout(() => {
      $("#dialog-port-pair").modal("setting", "closable", false);
      $("#dialog-port-pair").modal("show");
    }, 10);

  }


  handleSearch() {
    this.isError = false;
    this.isRetrieve = false;
    this.modelParam["error-periodFrom"] = "";
    this.modelParam["error-periodTo"] = "";
    this.modelParam["error-potCode"] = "";

    this.modelParam.periodFrom = this.periodFrom.getValue();
    this.modelParam.periodTo = this.periodTo.getValue();
    
    this.isError = this.onValidate(this.modelParam);
    if (!this.isError) {
      this.genericUtil.showLoader();
      this.searchEvent();
    }
  }

  handleCancel(){
    this.isRetrieve = false;
    this.disableToolbarButtons = "";
    this.modelParam = new SummaryParam();
    this.modelParam.potCode = 'SGSIN';
    this.setValidatorRetrieve();
    this.SummaryConnectingVesselList = [];
    this.periodFrom.clearCalendar();
    this.periodTo.clearCalendar();
    this.cbPOT.setValue("SGSIN");
    this.cbPOT.setForceValue("SINGAPORE");
    this.cbTerminal.clearSelect();

    this.saveStage([]);
  }

  handleRowDataChanged(event) {
    console.log(event);
  }

  eventAfterInitView() {
    this.setValidatorRetrieve();
    this.cbPOT.setValue("SGSIN");
    this.cbPOT.setForceValue("SINGAPORE");
    var self = this;
    setTimeout(() => {
      self.disableToolbarButtons = "";
      self.assignValueStage();
    }, 100);
  }

  assignValueStage(){
    
    this.transactionTsPortHomeService.getDataSummaryPortPairAllocation().subscribe(data=>{
      if(data.modelParam && data.gird){
        this.periodFrom.setValue(data.modelParam.periodFrom);
        this.periodTo.setValue(data.modelParam.periodTo);
        this.cbPOT.setValue(data.modelParam.potCode);
        this.cbPOT.setForceValue(data.modelParam.potName);
        this.modelParam = data.modelParam;
        this.SummaryConnectingVesselList = data.gird;
        if(this.SummaryConnectingVesselList.length > 0){
          this.isRetrieve = true;
          this.disableToolbarButtons = "";
        }
      }
    })
    
  }

  handleSortTable() {
    const sortModel = [
      { colId: 'tslStatus', sort: 'asc' },
      { colId: 'tslCreatedDate', sort: 'desc'}
    ];
    this.gridApi.setSortModel(sortModel);
  }


  loadData(data, i){
   console.log(data)
   this.model = new SummaryPortPairAllocation();
   this.isLoadingDetail = true;
      this.allocationService.getAllocation(data).pipe(
        takeUntil(this.onDestroy$),
        finalize(() => {
         this.isLoadingDetail = false;
        })
      ).subscribe(
        res => {
          if (res) {
            const respData = res.json();
            console.log(respData)
            this.model = respData.portPairAllocation;

            this.model.vesselId = data.vesselId;
            this.model.vesselCode = data.vesselCode;
            this.model.vesselName = data.vesselName;
            this.model.voyage = data.voyage;
            this.model.bPot1Code = this.modelParam.potCode;
            this.model.bPot1Name = this.modelParam.potName;
            
            this.rowData[i] = respData.tsPortPairAllocationLines;


            this.pinnedBottomRowData[i] = [
              {
                tslLoadPortName: 'Total Allocation:',
                tslAllocatedTeus: respData.totalAllocated,
                tslBookedTeus: respData.totalBooked,
                tslConfirmedTeus: respData.totalConfirmed,
                tslAvailableTeus: respData.totalAvailable
              }
            ];

          } else {
            this.message(
              "Information",
              "information",
              'Something went wrong',
              "okonly",
              { ok: "" }
            );
          }
        }
      )
   
  }

  changeEventPOT(event) {
    console.log(event)
    if (event.locationCode) {
      this.modelParam.periodFrom =  this.periodFrom.getValue();
      this.modelParam.periodTo =  this.periodTo.getValue();
      
      this.modelParam.potCode = event.locationCode;
      this.modelParam.potName = event.locationName;

      if (this.modelParam.periodFrom && this.modelParam.periodTo && this.modelParam.potCode) {
        this.isError = false;
      }
      this.hasTerminal = false;
      if(event.hasTerminal == 'Y'){
        this.hasTerminal = true;
      }
      this.cbTerminal.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.modelParam.potCode+'/{query}';
      this.cbTerminal.setValue("");

      this.modelParam['error-potCode'] = "";
    } else {
      this.cbTerminal.clearSelect();
      this.hasTerminal = false;
      this.modelParam.potCode = "";
      this.modelParam.potName = "";
    }
  }

  changeEventTerminal(event){
    if (event.locationCode) {
      this.modelParam.terminalCode = event.locationCode;
      this.modelParam.terminalName = event.locationName;
    } else {
      this.modelParam.terminalCode = "";
      this.modelParam.terminalName = "";
    }
  }
}
