<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-vesselCode'] == null || model['error-vesselCode'] == ''">
        {{model['error-vesselCode']}}
      </li>
      <li [class.hidden]="model['error-voyage'] == null || model['error-voyage'] == ''">
        {{model['error-voyage']}}
      </li>
      <li [class.hidden]="model['error-bound'] == null || model['error-bound'] == ''">
        {{model['error-bound']}}
      </li>
      <li [class.hidden]="model['error-bPot1Code'] == null || model['error-bPot1Code'] == ''">
        {{model['error-bPot1Code']}}
      </li>
      <li [class.hidden]="model['error-bPolCode'] == null || model['error-bPolCode'] == ''">
        {{model['error-bPolCode']}}
      </li>
      <li [class.hidden]="model['error-podCode'] == null || model['error-podCode'] == ''">
        {{model['error-podCode']}}
      </li>
      <li [class.hidden]="model['error-allocationValidator'] == null || model['error-allocationValidator'] == ''">
        {{model['error-allocationValidator']}}
      </li>
    </ul>
  </div>

  <div id='container' class="transaction-ts-renomination">
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <br />
      <div>
        <div class="four fields">
          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;" [class.error]="
                      model['error-bPot1Code'] != null &&
                      model['error-bPot1Code'] != '' &&
                      model['error-bPot1Code'] != undefined
                    ">
                <label>POT</label>
                <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)"
                  [disableCombo]="lockPOT">
                </combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;" [class.error]="
                                  model['error-vesselCode'] != null &&
                                  model['error-vesselCode'] != '' &&
                                  model['error-vesselCode'] != undefined
                                ">
                <label>Status</label>
                <combo-plugin #cbContOpr [settings]="settingContOpr"
                  (change)="changeEventContOpr($event)"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;" [class.error]="
                                          model['error-bPolCode'] != null &&
                                          model['error-bPolCode'] != '' &&
                                          model['error-bPolCode'] != undefined
                                        ">
                <label>Load Port</label>
                <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)"
                  style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;" [class.error]="
              model['error-podCode'] != null &&
              model['error-podCode'] != '' &&
              model['error-podCode'] != undefined
            ">
                <label>Discharge Port</label>
                <combo-plugin #cbDischargePort [settings]="settingDischargePort"
                  (change)="changeEventDischargePort($event)" style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'bPodCode', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'bPodCode', '[^a-zA-Z0-9-() ]', 50)">
                </combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Discharging Vessel</label>
                <combo-plugin #cbVesselDischarging [settings]="settingVesselDischarging"
                  (change)="changeEventVesselDischarging($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Connecting Vessel</label>
                <combo-plugin #cbVesselConnecting [settings]="settingVesselConnecting"
                  (change)="changeEventVesselConnecting($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Discharging Voyage</label>
                <combo-plugin #cbVoyageDischarging [settings]="settingVoyageDischarging"
                  (change)="changeEventVoyageDischarging($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Connecting Voyage</label>
                <combo-plugin #cbVoyageConnecting [settings]="settingVoyageConnecting"
                  (change)="changeEventVoyageConnecting($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="one wide field">
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;">
                <label>Bound</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                  [value]="model.bound" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;">
                <label>Bound</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                  [value]="model.bound" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
          </div>

          <div class="one wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Service</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Service"
                  [value]="model.serviceDischarging" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Service</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Service"
                  [value]="model.serviceConnecting" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
          </div>

          <div class="three wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POL: {{model.tsDischargingEtaPol}}</span>
                </div>
                <div>
                  <span>ETD at POL: {{model.tsDischargingEtdPol}}</span>
                </div>
              </div>
            </div>

            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POT: {{model.tsConnectingEtaPot}}</span>
                </div>
                <div>
                  <span>ETD at POT: {{model.tsConnectingEtdPot}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="three wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POT: {{model.tsDischargingEtaPot}}</span>
                </div>
                <div>
                  <span>ETD at POT: {{model.tsDischargingEtdPot}}</span>
                </div>
              </div>
            </div>

            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POD: {{model.tsConnectingEtaPod}}</span>
                </div>
                <div>
                  <span>ETD at POD: {{model.tsConnectingEtdPod}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style="width:100%; margin-top: 24px;">
        <app-custom-ag-grid-angular #topGrid id="transaction-prospect-booking-1" [isKeepPreviousFilterModel]="true"
          [getRowStyle]="getRowStyle" [rowData]="rowData" [rowMultiSelectWithClick]="true" [defaultColumnDefs]="defaultColumnDefs"
          [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)" (selectionChanged)="onSelectionChanged($event)"
          (cellValueChanged)="onCellValueChanged($event)" [rowSelection]="'multiple'"
          [pinnedBottomRowData]="pinnedBottomRowData" [isRowSelectable]="isRowSelectable"
          [enableBrowserTooltips]="true" (rowSelected)="handleRowSelected($event)">
        </app-custom-ag-grid-angular>
      </div>


      <div style="width:100%; margin: 50px 0px;">
        <div style="padding-bottom: 15px;font-weight: 700;">Port-Pair Allocation summary of selected Connecting Vessel /
          Voyage</div>
        <app-custom-ag-grid-angular #topGridPortPair id="transaction-prospect-booking-2" width="60%" height="180px"
          [getRowStyle]="getRowStyleGridPortPair" [rowData]="rowDataPortPair" [defaultColumnDefs]="defaultColumnDefsGridPortPair"
          [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (gridReady)="onGridPortReady($event)">
        </app-custom-ag-grid-angular>
      </div>

      <!-- form POT allocation -->
      <div id="potRemarksDialog" class="ui small modal" style="margin-right:400px;padding:1%;">
        <label>POT Remarks</label>
        <br><br>
        <div class="ui error message" [class.hidden]="isErrorDetail == false">
          <ul>
            <li [class.hidden]="model['error-polCode'] == null || model['error-polCode'] == ''">
              {{model['error-polCode']}}
            </li>
            <li [class.hidden]="model['error-podCode'] == null || model['error-podCode'] == ''">
              {{model['error-podCode']}}
            </li>
            <li [class.hidden]="model['error-alloted'] == null || model['error-alloted'] == ''">
              {{model['error-alloted']}}
            </li>
            <li [class.hidden]="model['error-api'] == null || model['error-api'] == ''">
              {{model['error-api']}}
            </li>
          </ul>
        </div>
        <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
          <form class="ui form">

            <div class="six wide fields">
              <div class="one field" style="width:31%;">
                <textarea name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
            <div class="six wide fields" style="margin-top: 2rem;">
              <div class="two field" style="width:100%;">
                <button class="tiny ui positive button" (click)="onInsert();">Insert</button>
                <button class="tiny ui button" (click)="onCancel();">Cancel</button>
                <button class="tiny ui negative button" (click)="onClose();">Close</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- form POT allocation -->
      <div id="potRemarksDialog" class="ui small modal" style="margin-right:400px;padding:1%;">
        <label>Outward Slot</label>
        <br><br>
        <div class="ui error message" [class.hidden]="isErrorDetail == false">
          <ul>
            <li [class.hidden]="model['error-polCode'] == null || model['error-polCode'] == ''">
              {{model['error-polCode']}}
            </li>
            <li [class.hidden]="model['error-podCode'] == null || model['error-podCode'] == ''">
              {{model['error-podCode']}}
            </li>
            <li [class.hidden]="model['error-alloted'] == null || model['error-alloted'] == ''">
              {{model['error-alloted']}}
            </li>
            <li [class.hidden]="model['error-api'] == null || model['error-api'] == ''">
              {{model['error-api']}}
            </li>
          </ul>
        </div>
        <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
          <form class="ui form">

            <div class="six wide fields">
              <div class="one field" style="width:31%;">
                <textarea name="" id="" cols="30" rows="10"></textarea>
              </div>
            </div>
            <div class="six wide fields" style="margin-top: 2rem;">
              <div class="two field" style="width:100%;">
                <button class="tiny ui positive button" (click)="onInsert();">Insert</button>
                <button class="tiny ui button" (click)="onCancel();">Cancel</button>
                <button class="tiny ui negative button" (click)="onClose();">Close</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
  <div class="ui medium text loader">Loading</div>
</div>

<!-- POT Remarks / Outward Slot /Reason Dialog-->
<div id="DialogConent" class="dialogConent">
  <div class="ui error message" *ngIf="messageValidateDialog">
    <span>{{messageValidateDialog}}</span>
  </div>
  <textarea name="" id="" rows="6" [(ngModel)]="valueDialog" (ngModelChange)="valueDialogChange($event)"
    [maxlength]="maxlengthDialog"></textarea>
    <p>
      {{noteContent}}
    </p>
  <div class="footer-dialogConent">
    <div class="footer-btn-event">
      <button class="ui tiny button" (click)="saveDialog()">Insert</button>
      <button class="ui tiny button" (click)="cancelDialog()">Cancel</button>
      <button class="ui tiny button" (click)="closeDialogRemarksOutReason()">Close</button>
    </div>
  </div>
</div>

<div id="UnconfirmedList" class="dialogUnconfirmedList">
  <table>
    <tr>
      <th>POL Booking No</th>
      <th>Discharging Vessel</th>
      <th>Discharging Voyage</th>
      <th>ETD POT</th>
      <th>Connecting Vessel</th>
      <th>Connecting Voyage</th>
      <th>ETA POT</th>
      <th>Difference</th>
      <th>Proceed connection?</th>
    </tr>
    <tr *ngFor="let item of unconfirmedList">
      <td>{{item.tsPolBookingPrefix}}{{item.tsPolBookingNo}}</td>
      <td>{{item.tsDischargingVesselName}}</td>
      <td>{{item.tsDischargingVesselVoyage}}</td>
      <td>{{item.tsDischargingEtdPot}}</td>
      <td>{{item.tsConnectingVesselName}}</td>
      <td>{{item.tsConnectingVesselVoyage}}</td>
      <td>{{item.tsConnectingEtaPot}}</td>
      <td>
        {{returnTime(item.tsConnectingEtaPot, item.tsDischargingEtdPot)}}
      </td>
      <td>
        <div class="d-flex-checkbox">
          <input type="radio" id="yes{{item.tsPolBookingNo}}" name="yes{{item.tsPolBookingNo}}" 
            [(ngModel)]="item.isConfirm" value="Y">
          <label for="yes{{item.tsPolBookingNo}}" class="mr-20px">Yes</label>

          <input type="radio" id="no{{item.tsPolBookingNo}}" name="no{{item.tsPolBookingNo}}"
            [(ngModel)]="item.isConfirm" value="N">
          <label for="no{{item.tsPolBookingNo}}">No</label>
        </div>
      </td>
    </tr>
  </table>
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-no" (click)="closeUnconfirmedList()">Close</button>
    <button class="ui button btn-yes" (click)="confirmUnconfirmedList()" [disabled]="isConfirm">Confirm</button>
  </div>
</div>

<div id="ChangeRotationList" class="dialogUnconfirmedList">
  <table>
    <tr>
      <th>POL Booking No</th>
      <th>Discharge Port</th>
      <th>Connecting Vessel</th>
      <th>Connecting Voyage</th>
      <th>Rotation</th>
    </tr>
    <tr *ngFor="let item of notReadyListByRotation">
      <td>{{item.tsPolBookingPrefix}}{{item.tsPolBookingNo}}</td>
      <td>{{item.tsPolDischargePortName}}</td>
      <td>{{item.tsConnectingVesselName}}</td>
      <td>{{item.tsConnectingVesselVoyage}}</td>
      <td>{{item.rotation}}</td>
    </tr>
  </table>
  <span style="font-weight: bold;">**Prospects listed within this grid will be excluded for processing due to Discharger Port not listed under Connecting Vessel rotation anymore.</span>
   
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-no" (click)="closeChangeRotationList()">Close</button>
    <button class="ui button btn-yes" (click)="confirmChangeRotationList()">Continue</button>
  </div>
</div>


<footer-plugin [info]="info"></footer-plugin>