import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/internal/operators/concatMap';
import { finalize } from 'rxjs/operators';
import { Authorize, ComboPluginComponent, ConfigService, CookieService, DatePluginComponent, GenericService, GenericUtil, PdfPluginComponent, Validator } from 'sibego-ui-library';
declare var $: any;

export class ReportParam{

  fromDate = '';
  reportType = 'INVOICE';

  constructor(){}

}

@Component({
  selector: 'app-report-finance-code-transmission-log',
  templateUrl: './report-finance-code-transmission-log.component.html',
  styleUrls: ['./report-finance-code-transmission-log.component.css']
})
export class ReportFinanceCodeTransmissionLogComponent extends Validator
implements OnInit, AfterViewInit
{

  dialog: any;

  @ViewChild("datePeriodTo") datePeriodTo: DatePluginComponent;
  @ViewChild("datePeriodFrom") datePeriodFrom: DatePluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbTransPort") cbTransPort: ComboPluginComponent;
  @ViewChild("cbPlaceDelivery") cbPlaceDelivery: ComboPluginComponent;
  @ViewChild("cbFinalDest") cbFinalDest: ComboPluginComponent;
  @ViewChild("cbPlaceReceipt") cbPlaceReceipt: ComboPluginComponent;
  @ViewChild("cbWeeks") cbWeeks: ComboPluginComponent;
  @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingLoadPort;
  settingDischargePort;
  settingTransPort;
  settingPlaceDelivery;
  settingFinalDest;
  settingPlaceReceipt;
  settingDatePicker;
  settingDateFrom;
  settingDateTo;
  settingWeeks;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";
  typePeriodFrom: string = "calendarPeriodFrom";
  typePeriodTo: string = "calendarPeriodTo";

  isContOwn: String;
  isType: String;
  isPrinted: String;

  year: String = "";
  week: String = "";
  flagPrint: String = "";

  isError: Boolean = false;
  disabledPeriod: Boolean = false;
  disabledByWeek: Boolean = false;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  model = new ReportParam;

  validatorRules = {};
  isErrorFlag: boolean;
  settingPDF: any;

  constructor(
    private auth: Authorize,
    private _http: HttpClient,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Ok", event: "ok", icon: "check" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Coda Transmission Log Report",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPlaceDelivery = {
      id: "cbPlaceDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingTransPort = {
      id: "cbTransPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinalDest = {
      id: "cbFinalDest",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPlaceReceipt = {
      id: "cbPlaceReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };
    this.settingWeeks = {
      id: "cbWeeks",
      type: "select", // search | select | select input
      url: "assets/json/weeks.json",
      placeholder: "Weeks",
      code: "weekCode",
      title: "weekName",
    };

    this.settingDateFrom = {
      id: "idPeriodFrom",
      placeholder: "Date From",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingDateTo = {
      id: "idPeriodTo",
      placeholder: "Period To",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.isType = "invoice";
    this.disabledPeriod = true;
    this.disabledByWeek = false;

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'ok' :
        this.model.fromDate = $('#etaFrom').val();
        console.log(this.model);
        this.handleOK();
        break;
      case 'cancel':
        //console.log(this.etaFrom.getValue());
        this.handleCancel();
        //this.etaFrom.setCustomText("00:10:00");
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}




  handleOK(){
    console.log("WHATATA: "+this.model.reportType);
    console.log("user Name :"+ this.cookieService.getName());



    this.setValidator();
    this.model['error-fromDate'] = '';
    this.model['error-toDate'] = '';
    this.model['error-etaToValidator'] = '';
    this.isErrorFlag = false;
    this.model['dateValidator'] = false;
     let isPassedValidation:boolean = this.onValidate(this.model);

     if(!isPassedValidation&&!this.model['dateValidator']){
      this.isErrorFlag = false;
     // this.disableToolbarButtons="ok,cancel,close";
      this.model['error-fromDate'] = '';
      this.model['error-toDate'] = '';
      this.loading = true;
      var pathUrl = '';
      var fileName = '';
      var userName = this.cookieService.getName().toUpperCase();
      const date = this.model.fromDate.replace(/-/g, "");
      pathUrl =  this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/codaTranmissionLogReport.pdf?logBatch='+date+'&type='+this.model.reportType+"&userId=" + userName;
      fileName = `codaTransmissionLog-${date}.pdf`;

      var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile";
       const urlTransmission = this.configService.config.BASE_API.toString() + '/invoice/codaTransmissionLogReport/param?logBatch=' + date +'&type='+this.model.reportType +"&userId=" + userName;
      var postData = {};
      postData["fileName"] = fileName;
      postData["url"] = pathUrl;
       this.loading = true;

      const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

      this.genericUtil.showLoader();
       this.genericService.GET(urlTransmission).pipe(
         concatMap(res => {
          var result1 = res.json();
          if (result1[0].status == "nok") {
          this.genericUtil.hideLoader();
          this.disableToolbarButtons = "";
          this.message("information", "Information", capitalize(result1[0].message), "okonly", {
            ok: "",
          });
        } else {
            return this.genericService.POST(hitUrl, postData);
          }
         }),
         finalize(() => this.genericUtil.hideLoader())
       ).subscribe(
         resp => {
          var result = resp.json();

          this.disableToolbarButtons = "";
          if (result["message"] == "success") {
            this.pdfContainer.setPDFURL(
              this.configService.config.pdfUrl + result["filePath"]
            );
            this.pdfContainer.showPDF();
          }

          //this.loading = false;
          this.genericUtil.closeDialog("progressDialog");
        }
      )
    }

  }

  handleCancel(){
    this.model = new ReportParam;
   // this.etaFrom.reset();
    //this.etaTo.reset();
    this.model['error-fromDate'] = '';
    this.model['error-toDate'] = '';
    $('#etaFrom').val('');
    $('#etaTo').val('');
    $('#rType1').prop('checked',true);
    $('#rType2').prop('checked',false);
    this.isErrorFlag = false;
  }

  setDate(type, value){
    if(type == 'from'){
      this.model.fromDate = value;
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  checkedRadio(val, checked:boolean){
    $("input[name=rType][value="+val+"]").prop("checked", checked);
    this.model.reportType = val;
  }

  eventRadio(event,val){
    var checked = event.srcElement.checked;

    if(checked){
      this.model.reportType = val;
    }

  }

  changeEventDate(event){
    console.log(event);
  }

  setValidator(){
    this.validatorRules = {
      fromDate: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel ETA From."
        }]
      }
    }
  }

  changeType(event) {
    if (event.target.value == "INVOICE") {
      this.model.reportType = "INVOICE";
    } else {
      this.model.reportType = "CN";
    }
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event == "close-pdf") {
      this.disableToolbarButtons = "print";
    }
  }
}
