import { ProspectBookingService } from './prospect-booking.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Authorize, ComboPluginComponent, ConfigService, CookieService, GenericService, GenericUtil, GridPluginComponent, Validator } from 'sibego-ui-library';
import { specialCharUtil } from '../../shared';
import { finalize } from 'rxjs/operators';
import FormValidation from "../../../utils/formValidation";
declare var $: any;
import * as FileSaver from 'file-saver';
import { FilterChangedEvent, IsRowSelectable, RowDataChangedEvent } from 'ag-grid-community';
import { TransactionTsPortHomeService } from '../transaction-ts-port-home.service';

export class ProspectBookingModel {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyageDischarging: string = "";
  voyageConnecting: string = "";
  bound: string = "O";
  eta: string = "";
  coc: number = 0;
  cocLocal: number = 0;
  cocTS: number = 0;
  bPOT = "";
  bPot1Code = "";
  bPot1Name = "";
  containerOwnerShip = "COC";
  service = "";
  tsServiceId = "";
  tsTotal = "";
  tsLocal = "";
  tsTS = "";
  tsEta = "";
  tsEtd = "";
  tsRotation = "";
  vesselName: string = "";
  totalAllocated: string = "";
  totalBooked: string = "";
  totalConfirmed: string = "";
  totalAvailable: string = "";
  tsServiceCode: string = "";
  bPolCode = "";
  bPolName: string;
  podId: string = "";
  podCode: string = "";
  podName: string = "";
  vesselIdDischarging: string = "";
  vesselCodeDischarging: string = "";
  vesselNameDischarging: string = "";
  vesselIdConnecting: string = "";
  vesselCodeConnecting: string = "";
  vesselNameConnecting: string = "";
  status: string = "ALL";
  serviceConnecting = "";
  serviceDischarging = "";
  tsConnectingEtaPod: any;
  tsConnectingEtaPot: any;
  tsConnectingEtdPod: any;
  tsConnectingEtdPot: any;
  tsDischargingEtaPol: any;
  tsDischargingEtaPot: any;
  tsDischargingEtdPol: any;
  tsDischargingEtdPot: any;
  tsPolBlNo: any;
  isCombinedRecord: any;
  combinedBundleKey: any;

  constructor() { }
}


@Component({
  selector: 'app-transaction-prospect-booking',
  templateUrl: './transaction-prospect-booking.component.html',
  styleUrls: ['./transaction-prospect-booking.component.css']
})
export class TransactionProspectBookingComponent extends Validator implements OnInit {
  dialog: any;

  @ViewChild("cbVesselDischarging") cbVesselDischarging: ComboPluginComponent;
  @ViewChild("cbVesselConnecting") cbVesselConnecting: ComboPluginComponent;

  @ViewChild("cbVoyageDischarging") cbVoyageDischarging: ComboPluginComponent;
  @ViewChild("cbVoyageConnecting") cbVoyageConnecting: ComboPluginComponent;

  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbLoadPortTerminal") cbLoadPortTerminal: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbDischargePortTerminal")
  cbDischargePortTerminal: ComboPluginComponent;

  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;

  // * Special Char Utils
  sCharUtil = new specialCharUtil();

  // Form Validation
  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingVessel;
  settingVoyage;

  settingLoadPort;
  settingLoadPortTerminal;
  settingDischargePort;
  settingDischargePortTerminal;

  settingVesselCopy;
  settingVoyageCopy;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = false;
  dButtonLock = false;

  isError: Boolean = false;
  isErrorDetail: Boolean = false;
  isErrorCopy: Boolean = false;

  model = new ProspectBookingModel();
  // modelDetail = new PortPairAllocationDetail();
  // modelCopy = new PortPairAllocationCopy();

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  lockPOLTerminal: Boolean = true;
  lockPODTerminal: Boolean = true;
  isRetrieve: Boolean = false;
  radioContainerOwnerSOC: any;
  getRowStyle: any;
  getRowStyleGridPortPair: any;

  isConfirm: Boolean = false;

  validatorRules = {};
  rdCoc: string;
  rdSoc: string;
  defaultColumnDefs: any;
  rowData = [];
  isView = true;
  modeForm: 'CREATE' | 'COPY';
  viewOnlyStatus = false;
  gridApi: any;
  topGridPortPair: any;
  pinnedBottomRowData = [];
  rotation = "";
  test = 'O';
  radioContainerOwnerCOC = 'Y';
  settingPOT1: any;
  columnApi: any;
  settingLoadPortInsert: any;
  userID: string;
  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.193.245:13000';
  settingPOT1Insert: any;
  newArray = [];
  settingContOpr: any;
  defaultColumnDefsGridPortPair: any;

  valueDialog = "";
  dialogFrom = "";
  messageValidateDialog = "";
  noteContent = "";
  maxlengthDialog = 4;
  settingVesselDischarging: any;
  settingVesselConnecting: any;
  settingVoyageDischarging: any;
  settingVoyageConnecting: any;
  rowDataPortPair: any;
  unconfirmedItem = {};
  unconfirmedList = [];
  readyListByRotation = [];
  notReadyListByRotation = [];
  rowIndexW: any;
  lockPOT = false;
  isRowSelectable: IsRowSelectable = (
    params: any
  ) => {
    return params.data.tsPolBlNo != '' && params.data.tsStatus !== "Rejected" && params.data.tsStatus !== "Deleted" && params.data.tsStatus !== "Archived";
  };

  labelConfirmCancelClose = "";

  // GST-216
  tsBookingProspectConfirmAllPayload = null;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private prospectBookingService: ProspectBookingService,
    private transactionTsPortHomeService: TransactionTsPortHomeService
  ) {
    super();

    this.handleSetting();
  }

  ngOnInit() {
    this.model.bound = "O";
    this.cdr.detectChanges();
    this.userID = this.cookieService.getName();
  }

  ngAfterViewInit() {
    this.eventAfterInitView();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "search", icon: "search" },
        { name: "POT Remarks", event: "potRemarks", icon: "plus" },
        { name: "Outward Slot", event: "outwardSlot", icon: "plus" },
        { name: "Confirm", event: "confirm", icon: "save" },
        { name: "Reason", event: "reason", icon: "copy" },
        { name: "Reject", event: "reject", icon: "remove" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "cancel" },
        { name: "Export", event: "export", icon: "save" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Prospect Booking",
    };

    this.settingContOpr = {
      id: "cbContOpr",
      type: "select",
      url: '[ { "contOprCode" : "ALL", "contOprName" : "All" }, { "contOprCode" : "PENDING", "contOprName" : "PENDING" }, { "contOprCode" : "CONFIRMED", "contOprName" : "CONFIRMED" }, { "contOprCode" : "RENOMINATED", "contOprName" : "RENOMINATED" }, { "contOprCode" : "ARCHIVED", "contOprName" : "ARCHIVED" }, { "contOprCode" : "REJECTED", "contOprName" : "REJECTED" }, { "contOprCode" : "DELETED", "contOprName" : "DELETED" }]',
      urlType: "inject",
      placeholder: "All",
      code: "contOprCode",
      title: "contOprName",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}",
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "ETD", field: "sailDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Port of Transhipment",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Is POT", field: "isPot", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingLoadPort = {
      id: 'cbLoadPort',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Location Code', field: 'locationCode', width: 100 },
        { header: 'Remarks', field: 'remarks', width: 100 },
        { header: 'Valid?', field: 'isValid', width: 100 },
      ]
    }

    this.defaultColumnDefs = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: 'Status', field: 'tsStatus', width: 100 },
      { headerName: 'Priority Booking', field: 'tsIsPriorityBooking', width: 150 },
      { headerName: 'POL B/L No.', field: 'tsPolBlNo', width: 150, tooltipField: 'tsPolBlNo' },
      { headerName: 'POL BKG No.', field: 'tsPolBookingNo', width: 150, tooltipField: 'tsPolBookingNo' },
      { headerName: 'BKG Party', field: 'tsPolBookingPartyName', width: 200, tooltipField: 'tsPolBookingPartyName', },
      { headerName: 'Shipper', field: 'tsPolShipperName', tooltipField: 'tsPolShipperName' },
      { headerName: 'Place of Receipt', field: 'tsPolBlPORName' },
      { headerName: 'Load Port', field: 'tsPolLoadPortName' },
      { headerName: 'Terminal (POL)', field: 'tsPolLoadPortTerminalName' },
      { headerName: 'Transhipment Port', field: 'tsPolBlPOT2Name' },
      { headerName: 'Discharge Port', field: 'tsPolDischargePortName' },
      { headerName: 'Terminal (POD)', field: 'tsPolDischargePortTerminalName' },
      { headerName: 'Place of Delivery', field: 'tsPolPodlName' },

      { headerName: 'Final Destination', field: 'tsPolFdestName' },
      { headerName: 'Sp Dtls', field: 'tsPolSpecialDetailsName' },
      { headerName: 'Ctr Type', field: 'tsPolContainerType' },
      { headerName: 'DG', field: 'tsPolDg' },

      { headerName: '20', field: 'tsPol20' },
      { headerName: '40', field: 'tsPol40' },
      { headerName: '45', field: 'tsPol45' },
      { headerName: 'H20', field: 'tsPolH20' },
      { headerName: 'H40', field: 'tsPolH40' },
      { headerName: 'H45', field: 'tsPolH45' },
      { headerName: 'POL BKG Remarks', field: 'tsPolBookingRemarks', tooltipField: 'tsPolBookingRemarks' },

      { headerName: 'Discharging Vessel', field: 'tsDischargingVesselName' },
      { headerName: 'Discharging Voyage', field: 'tsDischargingVesselVoyage' },
      { headerName: 'Bound', field: 'tsDischargingVesselBound' },
      { headerName: 'Service', field: 'tsDischargingServiceCode' },
      { headerName: 'ETA POL', field: 'tsDischargingEtaPol' },
      { headerName: 'ETD POL', field: 'tsDischargingEtdPol' },
      { headerName: 'ETA POT', field: 'tsDischargingEtaPot' },
      { headerName: 'ETD POT', field: 'tsDischargingEtdPot' },

      { headerName: 'Connecting Vessel', field: 'tsConnectingVesselName', cellStyle: params => {
        if(params.data.hoursDifference > 240){
          return { color : 'blue'}
        }
        return null;
      }},
      { headerName: 'Connecting Voyage', field: 'tsConnectingVesselVoyage', cellStyle: params => {
          if(params.data.hoursDifference > 240){
            return { color : 'blue'}
          }
        return null;
      }},
      { headerName: 'Bound', field: 'tsConnectingVesselBound' },
      { headerName: 'Service', field: 'tsConnectingServiceCode' },
      { headerName: 'ETA POT', field: 'tsConnectingEtaPot' },
      { headerName: 'ETD POT', field: 'tsConnectingEtdPot' },
      { headerName: 'ETA POD', field: 'tsConnectingEtaPod' },
      { headerName: 'ETD POD', field: 'tsConnectingEtdPod' },

      {
        headerName: 'Outward Slot', field: 'tsOutwardSlotOperator',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 4, rows: 1, cols: 20 }
      },
      {
        headerName: 'POT Remarks',
        field: 'tsPotRemarks',
        tooltipField: 'tsPotRemarks',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 320 }
      },
      {
        headerName: 'Reason for Rejection', field: 'tsReasonForRejection',
        tooltipField: 'tsReasonForRejection',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 320 }
      },
      { headerName: 'POT BKG No.', field: 'tsPotBookingNo' },
      { headerName: 'POT B/L No.', field: 'tsPotBlNo' },
      { headerName: 'Confirmed By', field: 'tsConfirmedUserId' },
      { headerName: 'Confirmed Date', field: 'tsConfirmedDate' },
      { headerName: 'Renominated By', field: 'tsRenominatedUserId' },
      { headerName: 'Renominated Date', field: 'tsRenominatedDate' },
      { headerName: 'Rejected By', field: 'tsRejectedUserId' },
      { headerName: 'Rejected Date', field: 'tsRejectedDate' },
    ];

    this.defaultColumnDefsGridPortPair = [
      { headerName: 'Load Port', field: 'tslLoadPortName' },
      { headerName: 'Terminal(POL)', field: 'tslLoadPortTerminalName' },
      { headerName: 'DischargePort', field: 'tslDischargePortName' },
      { headerName: 'Terminal(POD)', field: 'tslDischargePortTerminalName' },
      { headerName: 'Allocated(Teus)', field: 'tslAllocatedTeus' },
      { headerName: 'Booked(Teus)', field: 'tslBookedTeus' },
      { headerName: 'Confirmed(Teus)', field: 'tslConfirmedTeus' },
      { headerName: 'Available(Teus)', field: 'tslAvailableTeus' },
    ];

    this.settingVesselConnecting = {
      id: "cbVesselConnecting",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselDischarging = {
      id: "cbVesselDischarging",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageDischarging = {
      id: "cbVoyageDischarging",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageConnecting = {
      id: "cbVoyageConnecting",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVesselConnecting = {
      id: "cbVesselConnecting",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        //{header: 'ID', field: 'id', width: 50},
        //{header: 'Index', field: 'Index', width: 20},
        { header: "Load Port", field: "polName", width: 200 },
        { header: "Discharge Port", field: "podName", width: 200 },
        //{header: 'Container Type', field: 'contType', width: 200},
        { header: "Allocated (Teus)", field: "alloted", width: 200 },
        { header: "Booked (Teus)", field: "booked", width: 200 },
        { header: "Confirmed (Teus)", field: "confirm", width: 200 },
        { header: "Available (Teus)", field: "available", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      /*
      footer : {
        label : 'Total Allocation'
      },
      */
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "locationName",
      sortingDirection: "ASC",
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };


    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tsStatus === "Rejected") {
        return { "background-color": "lightcoral !important;" };
      }
      if (params.data.tsStatus === "Deleted") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.getRowStyleGridPortPair = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tsStatus === "Rejected") {
        return { "background-color": "lightcoral !important;" };
      }
      if (params.data.tsStatus === "Deleted") {
        return { "background-color": "lightcoral !important;" };
      }
    };

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "search":
        this.handleSearch();
        break;
      case "potRemarks":
        this.handlePotRemarks();
        break;
      case "outwardSlot":
        this.handleOutwardSlot();
        break;
      case "confirm":
        this.clickConfirm();
        break;
      case "reason":
        this.handleReason();
        break;
      case "cancel":
        this.confirmCancel("cancel");
        break;
      case "reject":
        this.handleReject();
        break;
      case "export":
        this.handleExport();
        break;
      case "close":
        this.confirmCancel("close");
        break;
    }
  }

  setValidatorRetrieve() {
    this.model["error-bound"] = "";
    this.model["error-bPolCode"] = "";
    this.model["error-bPot1Code"] = "";
    this.model["error-allocationValidator"] = "";

    this.validatorRules = {
      // bPolCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please input Load Port",
      //     },
      //   ],
      // },
      bPot1Code: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      },
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create allocation.",
          },
        ],
      },
    };
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any,
    id?: string
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
      id: 'dialog-prospect'
    };
    setTimeout(() => {
      $("#dialog-prospect").modal("setting", "closable", false);
      $("#dialog-prospect").modal("show");
    }, 10);
  }

  changeEventVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.model.eta = "";
      this.model.coc = 0;
      this.model.cocTS = 0;
      this.model.cocLocal = 0;
    }
  }

  changeEventVesselDischarging(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdDischarging = event.vesselId;
      this.model.vesselCodeDischarging = event.vesselCode;
      this.model.vesselNameDischarging = event.vesselName;
      this.cbVoyageDischarging.setValue("");
      this.cbVoyageDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + this.officeCode + '/' + this.model.vesselIdDischarging + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPolCode + "&potCode=" + this.model.bPot1Code);
      this.model.voyageDischarging = "";
      this.model.tsDischargingEtaPol = "";
      this.model.tsDischargingEtaPot = "";
      this.model.tsDischargingEtdPol = "";
      this.model.tsDischargingEtdPot = "";
      this.model.serviceDischarging = "";
    } else {
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.cbVoyageDischarging.setValue("");
      this.model.voyageDischarging = "";
      this.model.tsDischargingEtaPol = "";
      this.model.tsDischargingEtaPot = "";
      this.model.tsDischargingEtdPol = "";
      this.model.tsDischargingEtdPot = "";
      this.model.serviceDischarging = "";
    }
  }

  changeEventVesselConnecting(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdConnecting = event.vesselId;
      this.model.vesselCodeConnecting = event.vesselCode;
      this.model.vesselNameConnecting = event.vesselName;
      this.cbVoyageConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" + this.officeCode + '/' + this.model.vesselIdConnecting + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPot1Code);
      this.cbVoyageConnecting.setValue("");
      this.model.voyageConnecting = "";
      this.model.tsConnectingEtaPod = "";
      this.model.tsConnectingEtaPot = "";
      this.model.tsConnectingEtdPod = "";
      this.model.tsConnectingEtdPot = "";
      this.model.serviceConnecting = "";
    } else {
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.cbVoyageConnecting.setValue("");
      this.model.voyageConnecting = "";
      this.model.tsConnectingEtaPod = "";
      this.model.tsConnectingEtaPot = "";
      this.model.tsConnectingEtdPod = "";
      this.model.tsConnectingEtdPot = "";
      this.model.serviceConnecting = "";
    }
  }

  changeEventBound(event) {
    // this.cbVoyage.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //   "/MasterSailingSchedules/distinctVoyage/" +
    //   this.model.vesselId +
    //   "/" +
    //   this.model.bound.toUpperCase() +
    //   "/{query}/" +
    //   this.officeCode
    // );
  }

  changeEventVoyageDischarging(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageDischarging = event.voyage;
      this.model.serviceDischarging = event.serviceCode;
    } else {
      this.model.voyageDischarging = event.voyage;
    }
  }

  changeEventVoyageConnecting(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageConnecting = event.voyage;
      this.model.serviceConnecting = event.serviceCode;
    } else {
      this.model.voyageConnecting = event.voyage;
    }
  }

  changeEventLoadPort(event) {
    if (event.locationCode != null) {
      this.model.bPolCode = event.locationCode;
      this.model.bPolName = event.locationName;
      this.cbVesselDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + event.locationCode + "/{query}");
      this.model['error-bPolCode'] = "";
      if (this.model.bPolCode && this.model.bPot1Code) {
        this.isError = false;
      }
      this.cbVesselDischarging.setValue("");
      this.cbVoyageDischarging.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    } else {
      this.model.bPolCode = "";
      this.model.bPolName = "";
      this.cbVesselDischarging.setValue("");
      this.cbVoyageDischarging.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    }
  }

  changeEventLoadPortInsert(event) {
    if (event.locationCode != null) {

      if (event.hasTerminal == "Y") {
        this.lockPOLTerminal = false;
      } else {
        this.lockPOLTerminal = true;
      }

    } else {
    }
  }

  changeEventDischargePort(event) {
    // console.log(event);
    var self = this;
    if (event.locationCode != null) {
      this.model.podId = event.locationId;
      this.model.podCode = event.locationCode;
      this.model.podName = event.locationName;
      this.cbVesselConnecting.setValue("");
      this.cbVoyageConnecting.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
      this.model['error-podCode'] = "";
      this.model['error-bPolCode'] = "";
      if (this.model.bPot1Code) {
        this.isError = false;
      }
    } else {
      this.model.podCode = "";
      this.model.podName = "";
      this.cbVesselConnecting.setValue("");
      this.cbVoyageConnecting.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
    }
  }

  changeEventDischargePortTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
    } else {
    }
  }

  infoGrid(event) { }

  searchEvent(selectedRows?) {
    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",search");
    this.disableToolbarButtons = disableToolbarButtons;
    this.prospectBookingService.getProspectBooking(this.model).pipe(
      finalize(() => {
        const disableToolbarButtons = this.disableToolbarButtons.replace(',search','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.genericUtil.hideLoader();
      })
    ).subscribe(
      res => {
        if (res) {
          const data = res.json();
          this.handleAfterSearch(data, selectedRows);
        } else {
          this.message(
            "Information",
            "information",
            'Something went wrong',
            "okonly",
            { ok: "" }
          );
          this.saveStage(this.model, [], 0,0,0,0,0,0);
        }
      }, () => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
        this.saveStage(this.model, [], 0,0,0,0,0,0);
      }
    )
  }

  handleAfterSearch(data, selectedRows?) {
    if (data.status !== 'ok') {
      this.message(
        "Information",
        "information",
        data.message,
        "okonly",
        { ok: "" }
      );
      this.rowData = [];
      this.rowDataPortPair = [];
      this.setHeader(data);
      this.saveStage(this.model, [], 0,0,0,0,0,0);
      return;
    }
    this.disableToolbarButtons = "";
    this.rowData = data.content.tsBkgProspectList;
    this.rowDataPortPair = data.content.tsPortPairAllocations;
    this.gridApi.setPinnedBottomRowData([
      {
        tsStatus: 'Total Unit :',
        tsPol20: data.content.totalD20,
        tsPol40: data.content.totalD40,
        tsPol45: data.content.totalD45,
        tsPolH20: data.content.totalH20,
        tsPolH40: data.content.totalH40,
        tsPolH45: data.content.totalH45,
      }
    ]);

    this.lockPOT = true;
    this.setHeader(data);

    this.saveStage(this.model, data.content.tsBkgProspectList ? data.content.tsBkgProspectList : [], data.content.totalD20, data.content.totalD40, data.content.totalD45, data.content.totalH20, data.content.totalH40, data.content.totalH45);

    setTimeout(() => {
      if(selectedRows != undefined){
        this.gridApi.forEachNode((node)=> {
          selectedRows.forEach(selectRow => {
            if(selectRow.id ===  node.data.id){
              node.setSelected(true);
            }
          });
        })
        this.gridApi.redrawRows()
      }
    }, 200);

  }

  setHeader(data) {
    this.model.serviceConnecting = data.content.tsProspectHeader.tsConnectingServiceCode;
    this.model.serviceDischarging = data.content.tsProspectHeader.tsDischargingServiceCode;

    this.model.tsConnectingEtaPod = data.content.tsProspectHeader.tsConnectingEtaPod;
    this.model.tsConnectingEtaPot = data.content.tsProspectHeader.tsConnectingEtaPot;
    this.model.tsConnectingEtdPod = data.content.tsProspectHeader.tsConnectingEtdPod;
    this.model.tsConnectingEtdPot = data.content.tsProspectHeader.tsConnectingEtdPot;
    this.model.tsDischargingEtaPol = data.content.tsProspectHeader.tsDischargingEtaPol;
    this.model.tsDischargingEtaPot = data.content.tsProspectHeader.tsDischargingEtaPot;
    this.model.tsDischargingEtdPol = data.content.tsProspectHeader.tsDischargingEtdPol;
    this.model.tsDischargingEtdPot = data.content.tsProspectHeader.tsDischargingEtdPot;
  }

  saveStage(model, gird, totalD20, totalD40, totalD45, totalH20, totalH40, totalH45){
    this.gridApi
    let paramData = {
      model: model,
      gird: gird,
      totalD20: totalD20,
      totalD40: totalD40,
      totalD45: totalD45,
      totalH20: totalH20,
      totalH40: totalH40,
      totalH45: totalH45,
    }
    this.transactionTsPortHomeService.setDataPropectBooking(paramData);
  }

  showDialog(id) {
    $(id).modal("setting", "closable", false).modal("show");
  }

  closeDialog(id) {
    $(id).modal("hide");
  }

  addAllocation() {

    // this.cbLoadPort.setValue("");
    //this.cbLoadPortTerminal.setValue('');
    // this.cbDischargePort.setValue("");
    //this.cbDischargePortTerminal.setValue('');
    this.isRetrieve = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onGridPortReady(params) {
    this.topGridPortPair = params.api;
    this.topGridPortPair.hideOverlay();
  }

  onSaveCopy() { }

  onInsert() {
    console.log("** onInsert **");
  }

  onCancel() {
    this.cbLoadPort.clearSelect();
    this.cbDischargePort.clearSelect();
    this.isErrorDetail = false;
    // this.modelDetail.alloted = 0;
    // this.modelDetail = new PortPairAllocationDetail();
    // this.modelDetail["error-polid"] = "";
  }

  onCloseCopy() {
    this.closeDialog("#copyAllocDialog");
  }

  onClose() {
    this.closeDialog("#addAllocDialog");
  }

  handleSearch() {
    this.isError = false;
    this.model["allocationValidator"] = false;
    this.model["error-allocationValidator"] = "";
    this.model["error-podCode"] = "";
    this.model["error-bPolCode"] = "";
    this.isError = this.onValidate(this.model);
    if (!this.model.bPolCode && !this.model.podCode && !this.isError) {
      this.model["error-bPolCode"] = "Please input POL";
      this.model["error-podCode"] = "Please input POD";
      this.isError = true;
    }
    if (!this.isError) {
      if (this.model.bPolCode && this.model.podCode && !this.model.vesselIdConnecting && !this.model.vesselIdDischarging) {
        this.model["error-allocationValidator"] = "Please input at least 1 vessel/voyage.";
        this.isError = true;
        return;
      }

      if (this.model.bPolCode && !this.model.vesselIdDischarging && !this.model.vesselIdConnecting && !this.model.voyageConnecting) {
        this.model["error-allocationValidator"] = "Please input Discharging Vessel.";
        this.isError = true;
        return;
      }

      // GST-188  Missing Prompter for POD when Connecting Vessel Voy = Y
      if (this.model.bPolCode && !this.model.podCode && this.model.voyageConnecting && this.model.vesselIdConnecting) {
        this.model["error-podCode"] = "Please input POD.";
        this.isError = true;
        return;
      }

      if (!this.model.voyageDischarging && this.model.vesselIdDischarging) {
        this.model["error-allocationValidator"] = "Please input Discharging Voyage.";
        this.isError = true;
        return;
      }
      if (!this.model.vesselIdConnecting && !this.model.vesselIdDischarging && !this.model.voyageDischarging && !this.model.bPolCode) {
        this.model["error-allocationValidator"] = "Please input Connecting Vessel.";
        this.isError = true;
        return;
      }
      if (!this.model.voyageConnecting && this.model.vesselIdConnecting) {
        this.model["error-allocationValidator"] = "Please input Connecting Voyage.";
        this.isError = true;
        return;
      }
      this.genericUtil.showLoader();
      this.searchEvent();
    }
  }

  handleCreate() {
    this.addAllocation();
  }

  handleSave() {
    this.genericUtil.showLoader();
    const body = {
      tsPortPairAllocationLines: this.newArray,
    }
    this.prospectBookingService.saveAllocation(body).pipe(finalize(() => this.genericUtil.hideLoader())).subscribe(
      res => {
        const data = res.json();
        this.message(
          "information",
          "Information",
          data.message,
          "okonly",
          { ok: this.searchEvent() }
        );
      }, () => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    )
    this.cButtonLock = false;
    this.dButtonLock = false;
  }

  changeEventCoc(event) {
    if (event.target.value.toString().trim() === 'coc') {
      this.rdCoc = 'Y';
      this.rdCoc = 'N';
    }
  }
  changeEventSoc(event) {
    if (event.target.value.toString().trim() === 'soc') {
      this.rdCoc = 'N';
      this.rdCoc = 'Y';
    }
  }

  changeBound(event) {
  }

  onSelectionChanged(params) {
    const selectedRows = this.gridApi.getSelectedRows();

    // if (selectedRows.length > 0) {
    //   this.disableToolbarButtons = "";
    // } else {
    //   this.disableToolbarButtons = "reject";
    // }
  }

  handleRowSelected(event) {
    const data = event.data;
    const currentNode = event.node;
    const isSelected =  currentNode.isSelected();

    event.api.forEachNode((node) => {
      if (node.data.tsPolBookingNo === data.tsPolBookingNo) node.setSelected(isSelected);


      if (node.data.isCombinedRecord === 'Y' && node.data.combinedBundleKey === currentNode.data.combinedBundleKey){
        node.setSelected(isSelected);
      }

    });
  }

  onSelectionChangedGridPortPair(params) { }

  changeEventPOT1(event) {
    if (event.locationCode != null) {
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;
      this.cbVesselConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + event.locationCode + "/{query}");
      this.model['error-bPot1Code'] = "";
      this.isError = false;
    } else {
      this.model.bPot1Code = "";
      this.model.bPot1Name = "";
    }
  }

  changeEventTransmit(event) {
    this.model.containerOwnerShip = 'COC'
  }

  setData(data) {
    console.log(data);

  }

  rowDoubleClick(params) {
    let dt = params.data;
  }

  rowDoubleClickGridPortPair(params) { }

  confirmCancel(type){
    this.labelConfirmCancelClose = type;
    let message = type === 'cancel' ? 'No data will be confirmed / rejected. Proceed to cancel?' : 'No data will be confirmed / rejected. Proceed to close?'
    this.message('confirm', 'Confirm', message, 'yesno', { yes: 'this.confirmYes()', no: '' });
  }

  confirmYes(){
    if(this.labelConfirmCancelClose === 'cancel'){
      this.handleCancel();
    }else{
      this.router.navigateByUrl("/main/home");
    }
  }

  handleCancel() {
    this.isView = true;
    this.cbPOT1.setValue("SGSIN");
    this.cbVesselConnecting.setValue("");
    this.cbVesselDischarging.setValue("");
    this.cbVoyageConnecting.setValue("");
    this.cbVoyageDischarging.setValue("");
    this.cbPOT1.setValue("");
    this.cbLoadPort.setValue("");
    this.cbDischargePort.setValue("");
    this.cbVesselConnecting.disableCombo = false;
    this.cbVesselDischarging.disableCombo = false;
    this.cbVoyageConnecting.disableCombo = false;
    this.cbVoyageDischarging.disableCombo = false;
    this.model = new ProspectBookingModel();
    this.invisibleToolbarButtons = "insert";
    this.disableToolbarButtons = "reject,export,potRemarks,outwardSlot,confirm,reason";
    this.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    this.model.officeCode = this.officeCode;
    this.model.bound = "O";
    this.isRetrieve = false; //CR001
    this.rowData = [];
    this.rowDataPortPair = [];
    this.lockPOT = false;
    this.saveStage(this.model, [],0,0,0,0,0,0);
  }

  handleReject() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length < 1) {
      this.message(
        "Information",
        "information",
        'Please select at least 1 record to reject.',
        "okonly",
        { ok: "" }
      );
      return;
    }
    const isNoReason = selectedRows.some(e => e.tsReasonForRejection == '');

    if (isNoReason) {
      this.message(
        "Information",
        "information",
        'Please input reason for rejection.',
        "okonly",
        { ok: "" }
      );
      return;
    }
    this.message('delete', 'Rejection Record', 'Selected record(s) will be rejected. Proceed to reject?', 'yesno', { yes: 'this.rejectEvent()', no: 'this.loading = false;' });
  }

  rejectEvent() {
    const selectedRow = this.gridApi.getSelectedRows();
    const allocLineSeqnoList = [];
    selectedRow.forEach(element => {
      allocLineSeqnoList.push({ id: element.id });
    });
    const body = {
      userId: this.userID,
      tsBkgProspects: allocLineSeqnoList
    }

    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",reject");
    this.disableToolbarButtons = disableToolbarButtons;
    this.prospectBookingService.rejectAllocation(body)
    .pipe(
      finalize(()=>{
        const disableToolbarButtons = this.disableToolbarButtons.replace(',reject','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.genericUtil.hideLoader();
      })
    )
    .subscribe(
      res => {
        const data = res.json();
        this.message(
          "information",
          "Information",
          data.message,
          "okonly",
          { ok: "" }
        );
        if (data.status == 'ok') {
          this.searchEvent();
        }
      },
      () => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    )
  }

  eventAfterInitView() {
    this.setValidatorRetrieve();
    this.model.officeCode = this.officeCode;
    this.cbPOT1.setValue("SGSIN");
    var self = this;
    setTimeout(() => {
      self.disableToolbarButtons = "reject,export,potRemarks,outwardSlot,confirm,reason";
      self.assignValueStage();
    }, 100);
  }


  assignValueStage(){
    console.log("** assignValueStage **")
    this.transactionTsPortHomeService.getDataPropectBooking().subscribe(data=>{
      console.log(data)
      if(data.model && data.gird ){
        this.model = data.model;
        this.cbVesselDischarging.setForceValue(data.model.vesselNameDischarging || "");
        this.cbVesselDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPolCode + "/{query}");
        this.cbVoyageDischarging.setForceValue(data.model.voyageDischarging || "");
        this.cbVoyageDischarging.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + this.officeCode + '/' + this.model.vesselIdDischarging + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPolCode + "&potCode=" + this.model.bPot1Code);
        this.cbVesselConnecting.setForceValue(data.model.vesselNameConnecting || "");
        this.cbVesselConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPot1Code + "/{query}");
        this.cbVoyageConnecting.setForceValue(data.model.voyageConnecting || "");
        this.cbVoyageConnecting.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" + this.officeCode + '/' + this.model.vesselIdConnecting + "/" + this.model.bound + "/voyage_like=" + "{query}" + "?portOfCall=" + this.model.bPot1Code);
        this.cbLoadPort.setForceValue(data.model.bPolName);
        this.cbDischargePort.setForceValue(data.model.podName);
        this.rowData = data.gird;

        this.gridApi.setPinnedBottomRowData([
          {
            tsStatus: 'Total Unit :',
            tsPol20: data.totalD20,
            tsPol40: data.totalD40,
            tsPol45: data.totalD45,
            tsPolH20: data.totalH20,
            tsPolH40: data.totalH40,
            tsPolH45: data.totalH45,
          }
        ]);
    
      }
    })
  }

  changeEventContOpr(event) {
    if (
      event.contOprCode != null &&
      event.contOprCode !== "" &&
      event.contOprCode !== undefined
    ) {
      this.model.status = event.contOprCode;
    } else {
      this.model.status = "";
    }
  }

  handlePotRemarks() {
    const selectedRows = this.gridApi.getSelectedRows();
    console.log(selectedRows);

    if (selectedRows.length > 0) {
      this.dialogFrom = "POTREMARKS";
      this.noteContent = "Note: This remark will apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogConent", "POT Remarks", 500, 270);
      this.maxlengthDialog = 320;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert POT remarks.',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleOutwardSlot() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.dialogFrom = "OUTWARDSLOT";
      this.noteContent = "Note: This Outward Slot apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogConent", "Outward Slot", 500, 270);
      this.maxlengthDialog = 4;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to change Outward Slot.',
        "okonly",
        { ok: "" }
      );
    }
  }

  clickConfirm() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      // GST-115 Not showing promt when "Confirm" records # PENDING
      let tsStatusList = selectedRows.map(x=>x.tsStatus);
      if(tsStatusList.every(val => val === "Pending")){
        this.message(
          "confirm",
          "Confirmation Record",
          'Selected record(s) will be confirmed. Proceed to confirm?',
          "yesno",
          { yes: 'this.handleConfirm()', no: '' }
        );
      }else{
        this.message(
          "Information",
          "Information",
          'Only PENDING record(s) will be processed.',
          "okonly",
          { ok: "" }
        );
      }

    } else {
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to confirm.',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleConfirm() {
    this.readyListByRotation = [];
    this.notReadyListByRotation = [];
    this.isConfirm = false;
    const selectedRows = this.gridApi.getSelectedRows();
    // GST-115 Not showing promt when "Confirm" records # PENDING
    let recordPendingList = selectedRows.filter(item=> item.tsStatus === "Pending")
    if(recordPendingList.length === 0){
      this.message(
        "Information",
        "Information",
        'Only PENDING record(s) will be processed.',
        "okonly",
        { ok: "" }
      );
      return;
    }

    let validateReq = this.configService.config.BASE_API.toString() + "/TsBookingProspect/checkIfBkgAndBLDischargingVesselVoyageEqual";
    let validateReqBody = {
      userId: localStorage.getItem("logged").split("|")[0],
      tsBkgProspects: recordPendingList
    }

    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",confirm");
    this.disableToolbarButtons = disableToolbarButtons;

    this.genericService.POST(validateReq, validateReqBody).subscribe({
      next: (resp) => {
        const response = resp.json();
        this.tsBookingProspectConfirmAllPayload = response.content;
        if (response.status !== "ok") {
          const disableToolbarButtons = this.disableToolbarButtons.replace(',confirm','');
          this.disableToolbarButtons = disableToolbarButtons;
          this.genericUtil.hideLoader()
          this.tsBookingProspectConfirmAllPayload = null;
          this.message(
            "Information",
            "Information",
            response.message,
            "okonly",
            { ok: "" }
          );
        } else if (response.message.trim() !== "") {
          this.message(
            "Information",
            "Information",
            response.message,
            "okonly",
            { ok: "this.handleValidateRotation(this.tsBookingProspectConfirmAllPayload)" }
            //{ ok: "this.handleAfterValidate(this.tsBookingProspectConfirmAllPayload)" }
          );
        } else {
           //this.handleAfterValidate(this.tsBookingProspectConfirmAllPayload);
           this.handleValidateRotation(this.tsBookingProspectConfirmAllPayload);
        }
      },
      error: (error) => {
        console.error(error);
        this.genericUtil.hideLoader();
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      },
    })
  }

  handleValidateRotation(body = null) {
    let validateRotation = this.configService.config.BASE_API.toString() + "/TsBookingProspect/checkIfDischargePortNotContainsRotation";
    this.genericService.POST(validateRotation, body).subscribe({
      next: (resp) => {
        const response = resp.json();
        const respContent = response.content;

        /*
        const disableToolbarButtons = this.disableToolbarButtons.replace(',confirm','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.genericUtil.hideLoader();
         */
        if (response.status === "ok") {

            //show dialog
            this.notReadyListByRotation = respContent["notReadyListByRotation"]
            this.readyListByRotation = respContent["readyListByRotation"]
            this.handleChangeRotationList();

        }else{
          this.notReadyListByRotation = respContent["notReadyListByRotation"]
          this.readyListByRotation = respContent["readyListByRotation"]

          let validateReqBody = {
            userId: localStorage.getItem("logged").split("|")[0],
            tsBkgProspects: this.readyListByRotation
          }

          this.handleAfterValidate(validateReqBody);

        }

      },
      error: (error) => {
        console.error(error);
        this.genericUtil.hideLoader();
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      },
    })
  }

  handleAfterValidate(body = null) {
    let urlReqest = this.configService.config.BASE_API.toString() + "/TsBookingProspect/confirmAll";
    this.genericService.POST(urlReqest, body)
    .pipe(
      finalize(()=>{
        const disableToolbarButtons = this.disableToolbarButtons.replace(',confirm','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.tsBookingProspectConfirmAllPayload = null;
        this.genericUtil.hideLoader();
      })
    )
    .subscribe(
      (resp) => {
        if (resp.json()["status"] == "ok") {
          this.searchEvent();
          if (resp.json()["unconfirmedList"].length > 0) {
            this.unconfirmedList = resp.json()["unconfirmedList"].map(obj => ({ ...obj, isConfirm: 'N' }));
            if (resp.json()["unconfirmedList"].length > 0) {
              this.handleUnconfirmedList();
            }
          } else {
            this.message(
              "Information",
              "Information",
              resp.json().message,
              "okonly",
              { ok: "" }
            );
          }
        } else {
          this.message("error", "Error", resp.json().message, "okonly", {
            ok: "this.searchEvent()",
          });
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  handleUnconfirmedList() {
    let heightDialog = 215;
    if (this.unconfirmedList.length > 3) {
      heightDialog = 450;
    }
    if (this.unconfirmedList.length == 3) {
      heightDialog = 350;
    }
    if (this.unconfirmedList.length == 2) {
      heightDialog = 300;
    }
    if (this.unconfirmedList.length == 1) {
      heightDialog = 250;
    }
    this.genericUtil.showDialog(
      `UnconfirmedList`,
      "Unconfirmed List",
      1000,
      heightDialog
    );
  }

  returnTime(connectingEtaPotDate, dischargingEtdPotDate) {
    let msDate1 = new Date(connectingEtaPotDate);
    let msDate2 = new Date(dischargingEtdPotDate);
    let ispositiveNumbers = true;
    if (Number(msDate1) - Number(msDate2) < 0) {
      ispositiveNumbers = false;
    } else {
      ispositiveNumbers = true;
    }
    let ms = Math.abs(Number(msDate1) - Number(msDate2))
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms = ms % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);

    let daysInfo = days === 0 ? "" : `${days} days`;
    return ispositiveNumbers ? `${daysInfo} ${hours} hours ${minutes} minutes` : `-${daysInfo} ${hours} hours ${minutes} minutes`;
  }

  closeUnconfirmedList() {
    this.genericUtil.closeDialog("UnconfirmedList");
    this.unconfirmedList = [];
  }
  
  confirmUnconfirmedList() {
    console.log("** click confirm **");
    this.isConfirm = true;
    
    let tsBkgProspects = this.unconfirmedList.map(item => {
      return {
        id: item.id,
        isMandatory: item.isConfirm
      }
    })
    let body = {
      userId: localStorage.getItem("logged").split("|")[0],
      tsBkgProspects: tsBkgProspects
    }
    let urlReqest = this.configService.config.BASE_API.toString() + "/TsBookingProspect/confirmSpecific";
    this.genericService.POST(urlReqest, body).subscribe(
      (resp) => {
        this.isConfirm = false;
        if (resp.json()["status"] == "ok") {
          this.searchEvent();
          this.genericUtil.closeDialog("UnconfirmedList");
          this.message(
            "Information",
            "Information",
            'Status has been updated, please check results.',
            "okonly",
            { ok: "" }
          );

        } else {
          this.message("error", "Error", resp.json().message, "okonly", {
            ok: "",
          });
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
    
  }

  handleChangeRotationList() {
    let heightDialog = 215;
    if (this.notReadyListByRotation.length > 3) {
      heightDialog = 450;
    }
    if (this.notReadyListByRotation.length == 3) {
      heightDialog = 350;
    }
    if (this.notReadyListByRotation.length == 2) {
      heightDialog = 300;
    }
    if (this.notReadyListByRotation.length == 1) {
      heightDialog = 250;
    }
    this.genericUtil.showDialog(
      `ChangeRotationList`,
      "Change Rotation List",
      1000,
      heightDialog
    );
  }

  closeChangeRotationList() {
    this.genericUtil.closeDialog("ChangeRotationList");
    this.notReadyListByRotation = [];
  }

  confirmChangeRotationList() {
    this.genericUtil.closeDialog("ChangeRotationList");
    if(this.readyListByRotation.length > 0){
      this.genericUtil.showLoader();
      let validateReqBody = {
        userId: localStorage.getItem("logged").split("|")[0],
        tsBkgProspects: this.readyListByRotation
      }
      this.handleAfterValidate(validateReqBody);
    }
  }

  handleReason() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.noteContent = "Note: This reason will apply to ALL SELECTED records.";
      this.dialogFrom = "REASON";
      this.genericUtil.showDialog(
        "DialogConent",
        "Reason for Rejection",
        500,
        270
      );
      this.maxlengthDialog = 320;
    }else{
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert reason.',
        "okonly",
        { ok: "" }
      );
    }
  }

  closeShowInformationDialog() {
    this.genericUtil.closeDialog("showInformation");
  }

  valueDialogChange(event) {
    if (event) {
      this.messageValidateDialog = "";
    } else {
      switch (this.dialogFrom) {
        case "POTREMARKS":
          this.messageValidateDialog = "Please input remarks";
          break;
        case "OUTWARDSLOT":
          this.messageValidateDialog = "Please input Outward Slot";
          break;
        case "REASON":
          this.messageValidateDialog = "Please input reason";
          break;
        default:
          break;
      }
    }
  }

  cancelDialog() {
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  closeDialogRemarksOutReason() {
    this.genericUtil.closeDialog("DialogConent");
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  saveDialog() {
    if (!this.valueDialog) {
      this.closeDialogRemarksOutReason();
      return;
    }
    this.messageValidateDialog = "";
    let url = "";
    switch (this.dialogFrom) {
      case "POTREMARKS":
        url = "/TsBookingProspect/saveField/tsPotRemarks";
        break;
      case "OUTWARDSLOT":
        url = "/TsBookingProspect/saveField/tsOutwardSlotOperator";
        break;
      case "REASON":
        url = "/TsBookingProspect/saveField/tsReasonForRejection";
        break;
      default:
        break;
    }

    const selectedRows = this.gridApi.getSelectedRows();
    console.log("IDs", selectedRows.map(item => item.id));
    let body = {
      updatedContent: this.valueDialog,
      ids: selectedRows.map(item => item.id)
    }
    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService.POST(urlReqest, body)
      .pipe(finalize(() => { this.genericUtil.hideLoader() }))
      .subscribe(
        (resp) => {
          if (resp.json()["status"] == "ok") {
            this.closeDialogRemarksOutReason();
            this.searchEvent(selectedRows);
          } else {
            this.message("error", "Error", resp.json().message, "okonly", {
              ok: "",
            });
          }
        },
        (error) => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  handleExport() {
    // this.genericUtil.showLoader();
    let vesselName = "";
    let voyageName = "";

    if(this.model.vesselNameConnecting){
      vesselName = this.model.vesselNameConnecting;
      voyageName = this.model.voyageConnecting;
    }

    if(this.model.vesselNameDischarging || (this.model.vesselNameDischarging && this.model.vesselNameConnecting)){
      vesselName = this.model.vesselNameDischarging;
      voyageName = this.model.voyageDischarging;
    }

    var formatName = `${vesselName} V.${voyageName} Prospect Booking.xls`;
    var uri = this.configService.config.BASE_API.toString() +
      "?q=" + `/Mreport/exportTranshipmentReport?pot=${this.model.bPot1Code}&status=${this.model.status}&pol=${this.model.bPolCode}&pod=${this.model.podCode}&dischargingVesselId=${this.model.vesselIdDischarging}&dischargingVoyage=${this.model.voyageDischarging}&dischargingVesselBound=${this.model.bound}&connectingVesselId=${this.model.vesselIdConnecting}&connectingVoyage=${this.model.voyageConnecting}&connectingVesselBound=${this.model.bound}&dischargingServiceCode=${this.model.serviceDischarging}&connectingServiceCode=${this.model.serviceConnecting}&reportName=&reportType=Pro`
      var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
        self.genericUtil.hideLoader();
      },
      fail: function () {
        self.genericUtil.hideLoader();
      }
    });
  }

  onCellValueChanged(params) {
    this.rowIndexW = params.rowIndex;
    const colId = params.column.getId();

    let url;

    switch (colId) {
      case "tsPotRemarks":
        url = "/TsBookingProspect/saveField/tsPotRemarks";
        break;
      case "tsOutwardSlotOperator":
        url = "/TsBookingProspect/saveField/tsOutwardSlotOperator";
        break;
      case "tsReasonForRejection":
        url = "/TsBookingProspect/saveField/tsReasonForRejection";
        break;
      default:
        break;
    }

    let body = {
      updatedContent: params.newValue,
      ids: [params.data.id]
    }
    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService.POST(urlReqest, body)
      .pipe(finalize(() => { this.genericUtil.hideLoader() }))
      .subscribe(
        (resp) => {
          if (resp.json()["status"] == "ok") {
            // this.searchEvent();
          } else {
            this.message("error", "Error", resp.json().message, "okonly", {
              ok: "",
            });
          }
        },
        (error) => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }
}
