export class MtransactionMaintenance{
  id  : number = 0;
  vesselId : string='';
  vesselCode : string='';
  vesselName : string = '';
  voyage : string='';
  bound : string='O';
  serviceId:string='';
  serviceCode : string='';
  serviceName: string='';
  nameOfMaster : string= '';
  nationality : string='';
  blDate : string='';
  exchangeRateDate : string='';
  nonSsl : string='';
  localETA : string='';
  localETD : string='';
  gdvNumber : string='';
  leg : string='';
  callingSeq : string='';
  psaVoyage : string= '';
  agent : string='';
  dryDocking : string='';
  psaTransmitFlag : string='';
  lockedBlDate : string='';
  lockedExchangeRateDate : string='';
  isValid : string='';
  //officeCode : string='SGSIN';
  createdUserid : string='';
  createdDate: string='';
  modifiedUserid : string='';
  modifiedDate : string ='';

  portofLoadingCode : string = '';
  portofLoadingName : string = '';
  terminalCodeName : string = '';
  arrivalDateLoading : string = '';
  sailDateLoading : string = '';
  portofDischargeCode : string = '';
  portofDischargeName : string = '';
  terminalCodeDischargeName : string = '';
  arrivalDateDischarge : string = '';
  sailDateDischarge : string = '';
  stopBooking : string = 'N';
  no:number = 0;
  seqNo :  string = '';
  ContainerNo: string = '';
  SealNo1: number = 0;
  SealNo2: number = 0;
  SealNo3: number = 0;
  Qty: number = 0;
  PkgType: string = '';
  GrossWt: number = 0;
  GrossMeas: number = 0;
  ReceiptType: string = '';
  DeliveryType: string = '';
  Type: number = 0;
  Size: number = 0;
  Height: number = 0;
  SpecialDetails: number = 0;
  Shortship: string = '';
  Empty: number = 0;
  fullAddress: string = '';
  telno:string = '';
  email:string = '';
  fax:string = '';
  skipcheck: string = '';
  bContainerNo:string='';



  constructor() {

  }

}
export class ContainerMaintenance {
  no:number = 0;
  seqNo :  number = 0;
  ContainerNo: string = '';
  SealNo1: string = '';
  SealNo2: string = '';
  SealNo3: string = '';
  Qty: number = 0;
  PkgType: string = '';
  GrossWt: number = 0;
  GrossMeas: number = 0;
  ReceiptType: string = '';
  DeliveryType: string = '';
  Type: string = '';
  Size :   string = '';
  Height: number = 0;
  SpecialDetails: number = 0;
  Shortship: string = '';
  Empty: string = '';
  fullAddress: string = '';
  telno:string = '';
  //email:string = '';
  fax:string = '';
  skipcheck: string = '';
  dry:string='';
  bContainerNo:string='';
  containersize: number = 0;
}

export class MasterBlHeader{
  officeCode :string='SGSIN';
  blNo:string='';
  ShipperDraftBlNo:string='';
  blShipperId:string='';
  blShipperName:string='';
  blShipperAddress:string='';
  blConsigneeId:string='';
  blConsigneeName:string='';
  blConsigneeAddress:string='';
  blNotifyPartyId:string='';
  blNotifyPartyName:string='';
  blNotifyPartyAddress:string='';
  ShipperBlNo:string='';
  isCom:string='';
  blType:string='';
  OwnerShip:string='';
  OwnerStatus:string='';
  blReceiptType:string='';
  blPlaceOfReceipt:string='';
  blPlaceOfPrint:string='';
  blLoadPort:string='';
  blLoadPortPrint:string='';
  blDischargePort:string='';
  blDischargePortPrint:string='';
  blPlaceOfDelivery:string='';
  blPlaceOfDeliveryPrint:string='';
  blFinalDestination:string='';
  blFinalDestinationPrint:string='';
  blDeliveryType:string='';
  POT1:string='';
  POT2:string='';
  POT3:string='';

  skipCheckPreVessel:string='';
  skipCheckOceanVessel:string='';
  skipCheckNextVessel:string='';

  printPreVessel:string='';
  printOceanVessel:string='';
  printNextVessel:string='';

  blPreVesselId:string='';
  blPreVesselName:string='';

  blPreVesselCode:string='';
  blPreVesselVoyage:string='';
  blPreVesselBound:string='O';


  blOceanVesselId:string='';
  blOceanVesselCode:string='';
  blOceanVesselName:string='';
  blOceanVesselVoyage:string='';
  blOceanVesselBound:string='O';


  blNextVesselId:string='';
  blNextVesselCode:string='';
  blNextVesselVoyage:string='';
  blNextVesselBound:string='O';
  blNextVesselName:string='';



  blServiceId:string='';
  blPrincipalId:string='';
  blDeclaredValue:number=0;
  blAgentAddressId:string='';
  blIsIrePermit:string='';
  blIsSingleConsigneePermit:string='Y';
  blIsFreightAsArranged:string='';
  blIssueDate:string='';
  blIsPrepaidAt1Code:string='';
  blIsPrepaidAt2Code:string='';
  blIsPrepaidAt3Code:string='';

  blIsPayableAt1Code:string='';
  blIsPayableAt2Code:string='';
  blIsPayableAt3Code:string='';

  blPayer1Code:string='';
  blPayer2Code:string='';
  blPayer3Code:string='';

  blExchangeRateDate:string='';
  blFreightCoveredInBl:string='';
  blPlaceOfIssueCode:string='';
  blExchangeRateMethod:string='';

  blIsPrintShippedBoard:string='';
  BKGNo:string='';
  OBLS:number=0;
  dateCreated:string='';

  maintenanceContainer:ContainerMaintenance[] = [];
  maintenanceMarkNo:MarkNo[]=[];
  maintenanceDesc:Description[]=[];
  maintenanceFreight:FreightCharge[]=[];
  loading: Boolean = false;
  allocationValidator: Boolean = false;
  constructor(){

}

}

export class PC{
  localCSSNCode:string='';
  localCSSNName:string='';

}

export class MarkNo{
  no:number=0;
  blMarkNoOfficeCode:string='';
  bMarkSeq: number = 0;
  bMarkAndNo: string = "";
}

export class Description{
  no:number=0;
 blDescOfficeCode:string='';
 blDescBlNo:string='';
 blDescSeq:number=0;
 blDescription:string='';
 blDescInnerQty:number=0;
 blDescInnerPackageType:string='';
 blDescOuterQty:number=0;
 blDescOuterPackageType:string='';
 blDescPsaPackageType:string='';
 blDescGrossW:number=0;
 blDescNettW:number=0;
 blDescGrossMeas:number=0;
 blDescNettMeas:number=0;
 blDescReceiptType:string='';
 blDescDeliveryType:string='';
 blDescCommodityType:string='';
 blDescDescription:string='';
}

export class FreightCharge{
  no:number=0;
  blFcSeq:number=0;
  blFchargeCode:string='';
  blFcQuantity:number=0;
  blFcRevenue:string='';
  blFcRate:number=0;
  blFcPer:string='';
  blFcCurrency:string='';
  blFcTotalMount:number=0;
  blFcPayer:string='';
  blPfcReference:string='';
  blFcPlaceOfPaymentCode:string='';
  blFcContainerType:string='';
  blFcIsEntered:string='';
  blFcIsManifestedFreight:string='';
  blFcIsChargePrinted:string='';
  blFcFaiGroup:string='';
  blFcPc:string='';

}

