export class AllocationModel{

    vesselCode:string = "";
    voyage:string = "";
    bound:string = "";
    allocationId:string = "";
    seqNo:string = "";
    level:string = "";
    allotedBy:string = "";
    allocated:number = 0;
    booked:number = 0;
    reserved:number = 0;
    available:number = 0;

    parent:string = "";
    parentLevel:string = "";
    groupAvailable:number = 0;

    allocatedH:number = 0;

    constructor() { 
      
    }
}