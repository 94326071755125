<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-blOceanVesselCodeFrom'] == null ||
            model['error-blOceanVesselCodeFrom'] == '' ||
            model['error-blOceanVesselCodeFrom'] == undefined
          "
        >
          {{ model["error-blOceanVesselCodeFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselVoyageFrom'] == null ||
            model['error-blOceanVesselVoyageFrom'] == '' ||
            model['error-blOceanVesselVoyageFrom'] == undefined
          "
        >
          {{ model["error-blOceanVesselVoyageFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselBoundFrom'] == null ||
            model['error-blOceanVesselBoundFrom'] == '' ||
            model['error-blOceanVesselBoundFrom'] == undefined
          "
        >
          {{ model["error-blOceanVesselBoundFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselCodeTo'] == null ||
            model['error-blOceanVesselCodeTo'] == '' ||
            model['error-blOceanVesselCodeTo'] == undefined
          "
        >
          {{ model["error-blOceanVesselCodeTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselVoyageTo'] == null ||
            model['error-blOceanVesselVoyageTo'] == '' ||
            model['error-blOceanVesselVoyageTo'] == undefined
          "
        >
          {{ model["error-blOceanVesselVoyageTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselBoundTo'] == null ||
            model['error-blOceanVesselBoundTo'] == '' ||
            model['error-blOceanVesselBoundTo'] == undefined
          "
        >
          {{ model["error-blOceanVesselBoundTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blNoValidator'] == null ||
            model['error-blNoValidator'] == ''
          "
        >
          {{ model["error-blNoValidator"] }}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <div class="field">
        <div class="three fields">
          <div class="field" style="width: 20%">
            <fieldset
              style="border: 1px solid lightgray; height: 100%; width: 100%"
            >
              <legend style="font-weight: bold; color: dodgerblue">
                Selection Criteria
              </legend>

              <div class="field" style="margin-top: 10px">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="rdTransferBy"
                    [checked]="radioTransferByVessel == 'Y'"
                    (change)="changeEventTransferByVessel($event)"
                    value="vessel"
                  />
                  <label>Vessel Wise</label>
                </div>
              </div>

              <div class="field" style="margin-top: 10px">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="rdTransferBy"
                    [checked]="radioTransferByVessel == 'N'"
                    (change)="changeEventTransferByBL($event)"
                    value="bookingNumber"
                  />
                  <label>B/L Wise</label>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field">
            <fieldset
              style="border: 1px solid lightgray; height: 100%; width: 100%"
            >
              <legend style="font-weight: bold; color: dodgerblue">
                Transfer From
              </legend>

              <div class="four wide fields">
                <div
                  class="required two field"
                  style="width: 100%"
                  [class.error]="
                    model['error-blOceanVesselCodeFrom'] != null &&
                    model['error-blOceanVesselCodeFrom'] != ''
                  "
                >
                  <label>Vessel</label>
                  <combo-plugin
                    #cbVesselFrom
                    [settings]="settingVesselFrom"
                    (change)="changeEventVesselFrom($event)"
                    [disableCombo]="radioTransferByVessel == 'N'"
                    (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                    (paste)="
                      onPasteValidation(
                        $event,
                        'blOceanVesselCodeFrom',
                        this.formValidation.regVesVoy,
                        'and -./'
                      )
                    "
                    style="width: 100%"
                  ></combo-plugin>
                </div>

                <div
                  class="required one field"
                  style="width: 70%"
                  [class.error]="
                    model['error-blOceanVesselVoyageFrom'] != null &&
                    model['error-blOceanVesselVoyageFrom'] != ''
                  "
                >
                  <label>Voyage</label>
                  <combo-plugin
                    #cbVoyageFrom
                    [settings]="settingVoyageFrom"
                    (change)="changeEventVoyageFrom($event)"
                    [disableCombo]="radioTransferByVessel == 'N'"
                    (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                    (paste)="
                      onPasteValidation(
                        $event,
                        'blOceanVesselVoyageFrom',
                        this.formValidation.regVesVoy,
                        'and -./'
                      )
                    "
                    style="width: 100%"
                  ></combo-plugin>
                </div>

                <div
                  class="required one field"
                  style="width: 45%"
                  [class.error]="
                    model['error-blOceanVesselBoundFrom'] != null &&
                    model['error-blOceanVesselBoundFrom'] != ''
                  "
                >
                  <label>Bound</label>
                  <input
                    type="text"
                    name="boundFrom"
                    placeholder="Bound"
                    [(ngModel)]="model['blOceanVesselBoundFrom']"
                    [disabled]="radioTransferByVessel == 'N'"
                  />
                </div>
              </div>

              <div class="twelve wide field">
                <div class="one field" style="width: 100%">
                  <label style="float: left">Port Of Calls</label
                  ><label style="float: left">&nbsp;&nbsp;&nbsp;:&nbsp;</label
                  ><label
                    style="
                      float: left;
                      font-weight: normal;
                      color: cornflowerblue;
                    "
                    >{{ portOfCallFrom }}</label
                  >
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field">
            <fieldset
              style="border: 1px solid lightgray; height: 100%; width: 100%"
            >
              <legend style="font-weight: bold; color: dodgerblue">
                Transfer To
              </legend>

              <div class="four wide fields">
                <div
                  class="required two field"
                  style="width: 100%"
                  [class.error]="
                    model['error-blOceanVesselCodeTo'] != null &&
                    model['error-blOceanVesselCodeTo'] != ''
                  "
                >
                  <label>Vessel</label>
                  <combo-plugin
                    #cbVesselTo
                    [settings]="settingVesselTo"
                    (change)="changeEventVesselTo($event)"
                    (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                    (paste)="
                      onPasteValidation(
                        $event,
                        'blOceanVesselCodeTo',
                        this.formValidation.regVesVoy,
                        'and -./'
                      )
                    "
                    style="width: 100%"
                  ></combo-plugin>
                </div>

                <div
                  class="required one field"
                  style="width: 70%"
                  [class.error]="
                    model['error-blOceanVesselVoyageTo'] != null &&
                    model['error-blOceanVesselVoyageTo'] != ''
                  "
                >
                  <label>Voyage</label>
                  <combo-plugin
                    #cbVoyageTo
                    [settings]="settingVoyageTo"
                    (change)="changeEventVoyageTo($event)"
                    (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                    (paste)="
                      onPasteValidation(
                        $event,
                        'blOceanVesselVoyageTo',
                        this.formValidation.regVesVoy,
                        'and -./'
                      )
                    "
                    style="width: 100%"
                  ></combo-plugin>
                </div>

                <div
                  class="required one field"
                  style="width: 45%"
                  [class.error]="
                    model['error-blOceanVesselBoundTo'] != null &&
                    model['error-blOceanVesselBoundTo'] != ''
                  "
                >
                  <label>Bound</label>
                  <input
                    type="text"
                    name="boundDes"
                    placeholder="Bound"
                    [(ngModel)]="model['blOceanVesselBoundTo']"
                  />
                </div>
              </div>

              <div class="twelve wide field">
                <div class="one field" style="width: 100%">
                  <label style="float: left">Port Of Calls</label>
                  <label style="float: left">&nbsp;&nbsp;&nbsp;:&nbsp;</label>
                  <label
                    style="
                      float: left;
                      font-weight: normal;
                      color: cornflowerblue;
                    "
                    >{{ portOfCallTo }}</label
                  >
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div class="field">
        <fieldset style="border: 1px solid lightgray">
          <div
            class="field"
            [style.display]="radioTransferByVessel == 'Y' ? 'block' : 'none'"
          >
            <grid-plugin
              #gridBLTransferByVessel
              [settings]="settingGridBLTransferByVessel"
              (gridEvent)="gridEventByVessel($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>

          <div
            class="field"
            [style.display]="radioTransferByVessel == 'Y' ? 'none' : 'block'"
          >
            <!--
            <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
            <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
            <br/>
            -->
            <grid-plugin
              #gridBLTransferByBL
              [settings]="settingGridBLTransferByBL"
              (gridEvent)="gridEventByBL($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
  <!-- end div container -->
</div>

<!-- START, For Add Form -->
<div id="AddForm" style="width: 25%; padding: 0.5%; display: none">
  <div class="ui error message" [class.hidden]="isErrorAdd == false">
    <ul>
      <li
        [class.hidden]="
          modelAdd['error-blNo'] == null || modelAdd['error-blNo'] == ''
        "
      >
        {{ modelAdd["error-blNo"] }}
      </li>
    </ul>
  </div>

  <form class="ui form" style="width: 100%">
    <fieldset style="border: 1px solid lightgray; margin-left: 0.5px">
      <div class="field" style="margin-bottom: 2%">
        <label style="font-size: 11px">BL No</label>
      </div>
      <div class="two fields">
        <div class="field" style="width: 20%">
          <button class="ui button" style="font-size: 9px">OK</button>
        </div>
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="this.closeDialog('AddForm'); this.isErrorAdd = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- END, For Delete Form -->

<div
  id="beforeSaveDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onTransfer()">Yes</button>
      <button class="negative ui button" (click)="closeBeforeSaveDialog()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="afterSaveDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeAfterSaveDialog()">
        OK
      </button>
    </div>
  </form>
</div>

<div
  id="msgDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog()">Ok</button>
    </div>
  </form>
</div>

<div
  id="msgDialogDuplicate"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialogDuplicate()">
        Ok
      </button>
    </div>
  </form>
</div>

<div
  id="msgDialogVessels"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialogVessels()">
        Ok
      </button>
    </div>
  </form>
</div>

<div
  id="msgDialogCombine"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialogCombine()">
        Ok
      </button>
    </div>
  </form>
</div>

<div
  id="msgDialog2"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog2()">Ok</button>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
