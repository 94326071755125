export class FreightManagement {
    //validator
    checkEmptyDetailValidator:Boolean = false;
    checkExistingValidator:Boolean = false;
    checkDuplicateValidator:Boolean = false;

    //fields in table
    pfcOfficeCode       :string="";
    pfcRecordId         :string="";
    pfcFreightPartyId      :string="";
    pfcShipperId   :string="";
    pfcPlaceOfReceiptCode :string="";
    pfcLoadPortCode :string="";
    pfcDischargePortCode  :string="";
    pfcPlaceOfDeliveryCode  :string="";
    pfcFinalDestinationCode :string="";
    pfcPortOfTransshipment1Code  :string="";
    pfcPortOfTransshipment2Code  :string="";
    pfcPortOfTransshipment3Code  :string="";
    pfcCommodityId  :string="";
    pfcReceiptTypeCode  :string="CY";
    pfcDeliveryTypeCode :string="CY";
    pfcEffectiveFrom  :string="";
    pfcEffectiveTo  :string="";
    pfcContainerOwnership :string="ALL";
    pfcContainerStatus  :string="ALL";
    pfcWeightClass  : string="M";
    pfcDgClass  : string="ALL";
    pfcUnno :string="";
    pfcImo  :string="";
    pfcFlashPoint :string="";
    pfcShipmentType :string="ALL";
    pfcVesselId :string="";
    pfcVoyage :string="";
    pfcDetention  :string="S";
    pfcDetentionRequest :number=0;
    pfcDemurrage  :string="S";
    pfcDemurrageRequest :number=0;
    pfcIhc  :string="N";
    pfcPrincipalRemarks :string="";
    pfcAgentRemarks :string="";
    pfcCompetitorDataId :string="";
    pfcAppStatus  :string="P";
    pfcAgencyId  :string="";
    pfcUserCreated  :string="";
    pfcDateCreated  :string="";
    pfcUserModified :string="";
    pfcDateModified :string="";
    pfcUserRequested  :string="";
    pfcDateRequested  :string="";
    pfcUserApproved :string="";
    pfcDateApproved :string="";
    pfcIsTransmitted :string="N";
    pfcUserTransmitted: string="";
    pfcDateTransmitted: string="";
    pfcPORTerminalCode  :string="";
    pfcPORTerminalName  :string="";
    pfcPOLTerminalCode  :string="";
    pfcPOLTerminalName  :string="";
    pfcPODTerminalCode  :string="";
    pfcPODTerminalName  :string="";
    pfcPODLTerminalCode  :string="";
    pfcPODLTerminalName  :string="";
    pfcPOT1TerminalCode  :string="";
    pfcPOT1TerminalName  :string="";
    pfcPOT2TerminalCode  :string="";
    pfcPOT3TerminalCode  :string="";
    pfcFDESTTerminalCode  :string="";
    pfcFDESTTerminalName  :string="";
    pfcCustomColumn :string="";
    pfcProspectD20:number=0;
    pfcProspectD40:number=0;
    pfcProspectD45:number=0;
    pfcProspectH20:number=0;
    pfcProspectH40:number=0;
    pfcProspectH45:number=0;
    pfcIsValid:string='ALL';
    pfcUserInvalid:string='';
    pfcDateInvalid:string='';
    pfcIsDeleted:string='N';
    pfcUserDeleted:string='';
    pfcDateDeleted:string='';
    pfcReasonDeleted:string='';
    pfcIsCommission:string='N';
    pfcAcctPic:string='';

    //additional
    pfcContainerType   :string="ALL";
    pfcDetails:FreightDetail[] = [];
    pfcPolPrefix:string="";
    pfcPodPrefix:string="";
    pfcContainerOwnershipPrefix:string="";
    pfcSerchByRefNo:string="N";
    pfcHeaderKey:string="";
    pfcFreightPartyCountry:string = '';
    pfcLocalEta:string="";
    pfcLocalEtd:string="";
    pfcLocalAta:string="";
    pfcLocalAtd:string="";
    pfcWeight:number=0;
    pfcDateApprovedFrom:string='';
    pfcDateApprovedTo:string='';
    isCopy:boolean = false;
    disableInvalid:string = 'false';
    pfcUserId:string="";
    historyList:History[] = [];

    pfcAdhocFilter = '';

    pfcDetentionPOL  :string="S";
    pfcDetentionRequestPOL :number=0;
    pfcDemurragePOL  :string="S";
    pfcDemurrageRequestPOL :number=0;
    pfcBookingPartyId      :string="";

    pfcRevision      :string="000";

    pfcFreightPartyName: string = '';
    pfcFreightPartyIsFreightParty: string = '';
    pfcFreightPartyIsValid: string = '';
    pfcFreightPartyDateModified: string = '';
    pfcFreightPartyCocAccountCode: string = '';
    pfcFreightPartySocAccountCode: string = '';
    pfcFreightPartySlotAccountCode: string = '';

    pfcPORName: string = '';
    pfcPOLName: string = '';
    pfcPODName: string = '';
    pfcPODLName: string = '';
    pfcFDESTName: string = '';
    pfcPOT1Name: string = '';
    pfcPOT2Name: string = '';
    pfcPOT3Name: string = '';
    pfcVesselName: string = '';
    pfcCommodityName: string = '';

  constructor(){}
}

export class History{
    after:string = "";
    current:string = "";
    before:string = "";
}

export class FreightDetailTotal{
    pfcDetailChargeName:string="";

    //for charge amount
    pfcDetailD20:number=0;
    pfcDetailD40:number=0;
    pfcDetailD45:number=0;
    pfcDetailH20:number=0;
    pfcDetailH40:number=0;
    pfcDetailH45:number=0;

}

export class FreightDetail{
    pfcDetailPCTab:string="";
    pfcDetailPC:string="";
    pfcDetailContainerType:string="STD";
    pfcDetailSeqNo:number=0;
    pfcDetailChargeCode:string="";
    pfcDetailChargeName:string="";
    pfcDetailCurrency:string="";
    pfcDetailPlaceOfPayment:string="";
    pfcDetailPlaceOfPaymentName:string="";
    pfcDetailPayer:string="";
    pfcDetailPayerName:string="";
    pfcDetailPayerIsPayer:string="";
    pfcDetailPayerIsValid:string="";
    //for charge amount
    pfcDetailD20:number=0;
    pfcDetailD40:number=0;
    pfcDetailD45:number=0;
    pfcDetailH20:number=0;
    pfcDetailH40:number=0;
    pfcDetailH45:number=0;

    //for OOG
    pfcDetailStatus:string="P";
    pfcDetailM3:number=0;
    pfcDetailKT:number=0;
    pfcDetailRT:number=0;
    pfcDetailMinCharge:number=0;
    pfcDetailKillSlot:number=0;
    pfcDetailFAIGroup:string="N";
    pfcDetailOogOH:number=0;
    pfcDetailOogOWL:number=0;
    pfcDetailOogOWR:number=0;
    pfcDetailOogOLF:number=0;
    pfcDetailOogOLB:number=0;
    pfcDetailOogDoorOpen:string="N";
    pfcDetailOogUcH:number=0;
    pfcDetailOogUcL:number=0;
    pfcDetailOogUcW:number=0;

    //additional
    no:number = 0;
    pfcDetailTotalChargeValue:number=0;
    pfcDetailCopyTab:string="N";
    pfcDetailOogDoorOpenName:string="N/A";
    pfcDetailContainerTypeName:string="NORMAL";
    pfcSurchargeId:string = "";

    constructor(){}
}


export class PfcExtendApproval {
    //fields in table
    pfcOfficeCode       :string="";
    pfcRecordId         :string="";
    pfcEffectiveFromOld  :string="";
    pfcEffectiveToOld  :string="";
    pfcUserApprovedOld :string="";
    pfcDateApprovedOld :string="";
    pfcEffectiveFromNew  :string="";
    pfcEffectiveToNew  :string="";
    pfcUserApprovedNew :string="";
    pfcDateApprovedNew :string="";

    constructor(){}
}
