import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { AdhocCharge } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-master-adhoc-charge-page",
  templateUrl: "./master-adhoc-charge-page.component.html",
  styleUrls: ["./master-adhoc-charge-page.component.css"],
})
export class MasterAdhocChargePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("chargeGrid") chargeGrid: GridPluginComponent;
  @ViewChild("chargeGridHistory") chargeGridHistory: GridPluginComponent;
  @ViewChild("cbChargeNameHistory") cbChargeNameHistory: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting: ToolbarPluginComponent;
  @ViewChild('cbChargeName') cbChargeName: ComboPluginComponent;
  @ViewChild('cbChargeCode') cbChargeCode: ComboPluginComponent;
  @ViewChild('cbCurrency') cbCurrency: ComboPluginComponent;
  @ViewChild('cbBlContainerContainerType') cbBlContainerContainerType: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  /* Form Validation */
  formValidation = new FormValidation();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingChargeLogStartDate;
  //settingChargeLogEndDate;
  settingChargeHistory;

  /* Misc Parameters */
  valChargeCode: string = "";
  valChargeName: string = "";
  valChargeId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  /* Parameter model */
  model = new AdhocCharge();
  //selectList = [];

  loading = false;

  lock = false;
  lockTNK = false;
  lockUC = false;
  lockSTD = true;
  lockManifest = true;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  regChargeName = /^[a-zA-Z0-9-(.)&' ]*$/;

  validatorRules = {
    chargeName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Charge Name.",
        },
        {
          type: "modifiedPattern",
          pattern: this.regChargeName,
          prompt: "Please input valid characters",
        },
      ],
    },
    chargeCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Charge Code.",
        },
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAlphaNumeric,
          prompt: "Please input valid characters",
        },
      ],
    },
    currencyCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Currency.",
        }
      ]
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAllChar,
          prompt: "Please input valid characters",
        },
      ],
    },
  };
  settingTxtBlPartyShipperAddress: { id: string; name: string; placeholder: string; cols: number; rows: number; border: string; };
  settingCbBlContainerContainerType: {
    id: string; type: string; // search | select | select input
    url: string; urlType: string; placeholder: string; code: string; title: string;
  };
  paramContainer: any;
  d20 = 0;
  h20 = 0;
  d40 = 0;
  h40 = 0;
  d45 = 0;
  h45 = 0;
  chargeName: any;
  settingChargeName;
  settingChargeCode;
  settingCurrency;
  isInvalidChargeValue: boolean;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Charge Code", value: "chargeCode" },
        { display: "Charge Name", value: "chargeName" },
        { display: "Manifested Freight", value: "isManifested" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url: "",
        // "http://172.16.202.173:10000//AdhocMasterCharges/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Charge Name",
          field: "chargeName",
          width: 200,
          type: "string",
        },
        { header: "Charge Code", field: "chargeCode", width: 50 },

        { header: "Container Type", field: "containerType", width: 100 },
        { header: "Currency", field: "currency", width: 50 },
        { header: "D20", field: "d20", width: 50 },
        { header: "H20", field: "h20", width: 50 },
        { header: "D40", field: "d40", width: 50 },
        { header: "H40", field: "h40", width: 50 },
        { header: "D45", field: "d45", width: 50 },
        { header: "H45", field: "h45", width: 50 },
        { header: "Manifested Freight", field: "isManifested", width: 100 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Delete", field: "isDeleted", width: 80 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      // store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "chargeName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/AdhocMasterCharges/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Charge Id", field: "chargeId", width: 200 },
        {
          header: "Charge Name",
          field: "chargeName",
          width: 200,
          type: "string",
        },
        { header: "Charge Code", field: "chargeCode", width: 50 },
        { header: "Container Type", field: "containerType", width: 100 },
        { header: "Currency", field: "currency", width: 50 },
        { header: "D20", field: "d20", width: 50 },
        { header: "H20", field: "h20", width: 50 },
        { header: "D40", field: "d40", width: 50 },
        { header: "H40", field: "h40", width: 50 },
        { header: "D45", field: "d45", width: 50 },
        { header: "H45", field: "h45", width: 50 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "Main Charge", field: "isMainCharge", width: 100 },
        { header: "Manifest", field: "isManifested", width: 100 },
        { header: "Show in Report", field: "isShownInReport", width: 100 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true,
    };

    this.settingChargeHistory = {
      id: "cbChargeHistoryAdhoc",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findGeneralHistory/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "chargeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Charge Id", field: "chargeId", width: 300 },
        { header: "Charge Name", field: "chargeName", width: 100 },
        { header: "Charge Code", field: "chargeCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    /*
     this.settingChargeLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingChargeLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */

    this.settingCbBlContainerContainerType = {
      id: "cbBlContainerContainerType",
      type: "select", // search | select | select input
      url: '[{ "blContainerTypeCode" : "ALL", "blContainerTypeName" : "ALL"},{ "blContainerTypeCode" : "NORMAL", "blContainerTypeName" : "NORMAL"},{ "blContainerTypeCode" : "REEFER", "blContainerTypeName" : "REEFER" }, { "blContainerTypeCode" : "REEFER DRY", "blContainerTypeName" : "REEFER DRY" }, { "blContainerTypeCode" : "TANK", "blContainerTypeName" : "TANK" },{ "blContainerTypeCode" : "OPEN TOP", "blContainerTypeName" : "OPEN TOP" },{ "blContainerTypeCode" : "FLAT RACK", "blContainerTypeName" : "FLAT RACK" },{ "blContainerTypeCode" : "PLATFORM", "blContainerTypeName" : "PLATFORM" },{ "blContainerTypeCode" : "UC", "blContainerTypeName" : "UC" }]',
      urlType: "inject",
      placeholder: "Search ...",
      code: "blContainerTypeCode",
      title: "blContainerTypeName",
    };

    this.settingChargeName = {
      id: 'cbChargeName',
      type: 'search enter', //  search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findByComboBoxControlAdHocOnly/chargeCodeOrName={query}',
      maxChars: 0,
      template: 'grid', //  default
      placeholder: 'Search...',
      title: 'chargeName',
      description: '',
      columns: [
        { header: 'Charge Name', field: 'chargeName', width: 200 },
        { header: 'Charge Code', field: 'chargeCode', width: 100 },
        { header: 'is Manifested', field: 'isManifested', width: 50 }
      ],
      maxLength: 20,
    };

    this.settingChargeCode = {
      id: 'cbChargeCode',
      type: 'search enter', //  search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findByComboBoxControlAdHocOnly/chargeCodeOrName={query}',
      maxChars: 0,
      template: 'grid', //  default
      placeholder: 'Search...',
      title: 'chargeCode',
      description: '',
      columns: [
        { header: 'Charge Name', field: 'chargeName', width: 200 },
        { header: 'Charge Code', field: 'chargeCode', width: 100 },
        { header: 'is Manifested', field: 'isManifested', width: 50 }
      ],
      maxLength: 20,
    };

    this.settingCurrency = {
      id: 'cbCurrency',
      type: 'search enter', //  search | select | select input
      url: this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findGeneralEnum/{query}",
      maxChars: 0,
      template: 'grid', //  default
      placeholder: "Select Currency",
      code: "currencyCode",
      title: "currencyCode",
      description: "",
      columns: [
        { header: "Currency Name", field: "currencyName", width: 200 },
        { header: "Currency Code", field: "currencyCode", width: 150 },
      ],
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.chargeGrid.search='*/*/*';
    //this.chargeGrid.onFirst();
    this.firstInit();

    // Default values
    this.cbBlContainerContainerType.setValue('ALL');
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.chargeGrid.search = "*/*/*";
    this.chargeGrid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  chargeGridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.chargeGrid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.chargeGrid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.chargeGrid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.chargeGrid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
              this.lockSTD = true;
              this.lockTNK = true;
              this.lockUC = true;
              // this.handleUpdate();
            }
          }
        }

        break;
    }
  }

  chargeGridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.chargeId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        this.chargeGrid.url = this.configService.config.BASE_API.toString() +
          "/AdhocMasterCharges/findGeneral";
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.chargeGrid.search = str.replace("search:", "");
          this.chargeGrid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.resetForm();
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new AdhocCharge();
    this.valChargeCode = "";
    this.valChargeName = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.chargeGrid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {

    this.loading = true;
    // this.deleteRow();
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );

    // // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.chargeGrid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"chargeId":"","chargeCode":""};
    //   deleteObj.chargeId = element.chargeId;
    //   deleteObj.chargeCode = element.chargeCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListChargeUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    window.open(
      this.configService.config.BASE_API.toString().replace("9090", "10000") +
        "/Mreport/runReport/25/Charges-Export-Reports/*"
    );
    // handle print event
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */

      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      if (this.d20 + this.h20 + this.d40 + this.d45 + this.h45 === 0) {
        this.isInvalidChargeValue = true;
        return;
      }
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        {
          yes: "this.saveEvent()",
          no: "this.loading = false;",
        }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.model = new AdhocCharge();
    this.form = "";

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }

    this.chargeGrid.clearSelectedValues();

    if (this.valChargeId != "") {
      this.valChargeCode = "";
      this.valChargeId = "";
      this.cbChargeNameHistory.setValue("");
      this.cbChargeNameHistory.close();
      this.chargeGridHistory.onHistory("NaN");
      this.chargeGridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the chargeGrid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    if (this.chargeGrid.getSelectedValues().length >= 1) {
      this.chargeGrid.onSortDir("dateModified", "DESC");
    } else {
      this.chargeGrid.onSortDir("dateCreated", "DESC");
    }

    this.chargeGrid.clearSelectedValues();
    this.chargeGrid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    this.chargeGrid.clearSelectedValues();
    this.chargeGrid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.chargeGrid.getSelectedValues()[0];


    this.model = new AdhocCharge();

    // console.log(this.model);

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.chargeId = String(data.chargeId).trim().toUpperCase();
    this.model.chargeCode = String(data.chargeCode).trim().toUpperCase();
    this.model.chargeName = String(data.chargeName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");

    if (data.isManifested == "Y") {
      this.model.isManifested = true;
    } else {
      this.model.isManifested = false;
    }

    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();

    this.cbChargeCode.mymodel = data.chargeCode;
    this.cbChargeName.mymodel = String(data.chargeName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.cbCurrency.mymodel = data.currency;

    // set value containerType when edit
    this.model.containerType = data.containerType;
    this.cbBlContainerContainerType.setValue(data.containerType);

    this.model.currencyName = data.currencyName;
    this.model.currencyCode = data.currency;
    this.d20 = data.d20;
    this.h20 = data.h20;
    this.d40 = data.d40;
    this.h40 = data.h40;
    this.d45 = data.d45;
    this.h45 = data.h45;
  }

  valueChargeHistory(event) {
    this.valChargeCode = event["chargeCode"];
    this.valChargeId = event["chargeId"];
    // console.log("curr code= " + event["chargeCode"]);

    //refresh the history chargeGrid accordingly
    this.chargeGridHistory.onHistory(this.valChargeId);
  }

  generateChargeHistoryReport(event) {
    //this.valHistoryStartDate= $('#logHistoryChargeStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryChargeEndDate').find('#datePicker').val();
    window.open(
      this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
        "/Mreport/runReport/26/History-Charge-" +
        this.valChargeId +
        "-" +
        this.valChargeCode +
        "/" +
        this.valChargeId +
        "@" +
        this.valHistoryStartDate +
        "@" +
        this.valHistoryEndDate
    );
  }

  retrieveHistory(event) {
    this.chargeGridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    const params = {
        d20 : this.d20,
        h20 : this.h20,
        d40 : this.d40,
        h40 : this.h40,
        d45 : this.d45,
        h45: this.h45,
        charge_code: this.model.chargeCode,
        currency_code: this.model.currencyCode,
        container_type: this.model.containerType,
        is_valid: this.model.isValid ? 'Y' : 'N',
        remarks: this.model.remarks,
        id: this.model.chargeId
    }
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/AdhocMasterCharges/save",
        // 'http://172.16.202.173:10000/AdhocMasterCharges/save',
        params
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new AdhocCharge();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")

    this.loading = true;
    let deleteList = [];
    this.chargeGrid.getSelectedValues().forEach(element => {
      deleteList.push(element.chargeId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = { "userDeleted": this.cookieService.getName(), "dateDeleted": datetime, "chargeId": [], "isValid": this.model.isValid ? 'Y' : 'N' }
    paramsDelete.chargeId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        // "/MasterCharges/delete",
        // deleteList
        "/AdhocMasterCharges/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new AdhocCharge();
            //  this.selectList = [];
            this.loading = false;
            this.chargeGrid.clearSelectedValues();
            this.chargeGrid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  // // * Field Validation On Paste
  // onPasteValidation(
  //   event: any,
  //   field: string,
  //   regexName: RegExp,
  //   validSPChar?: string
  // ) {
  //   let clipboardData = event.clipboardData;
  //   let pastedText = clipboardData.getData("text");

  //   if (regexName.test(pastedText) == false) {
  //     this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar ? validSPChar : ''}`;
  //   } else {
  //     this.model[`error-${field}`] = "";
  //   }
  // }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  markRedRow(dataNo){
    var i = this.chargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.chargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

  changeEventCbBlContainerContainerType(event) {
    if (
      event.blContainerTypeCode != null &&
      event.blContainerTypeCode !== "" &&
      event.blContainerTypeCode !== undefined
    ) {
      this.model.containerType = event.blContainerTypeCode;
    }
  }

  changeEventD20(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.d20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d20 = 0;
    }
  }

  changeEventH20(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.h20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h20 = 0;
    }

    if (val > 0) {
    }
  }

  changeEventD40(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.d40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d40 = 0;
    }

    if (val > 0) {

    }
  }

  changeEventH40(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.h40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h40 = 0;
    }

    if (val > 0) {
    }
  }

  changeEventD45(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.d45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d45 = 0;
    }

    if (val > 0) {
    }
  }

  changeEventH45(event) {
    let val = Number.parseInt(event.target.value);
    this.checkChargeCode();
    if (isNaN(val)) {
      val = 0;
      this.h45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h45 = 0;
    }

    if (val > 0) {}
  }

  changeEventChargeName(event) {
    if (event.chargeCode == null || event.chargeCode === '' || event.chargeCode === undefined) {
      this.model.chargeName = '';
      this.model.chargeCode = '';
      this.model.isManifested = false;
    } else {
      this.model.chargeCode = event.chargeCode;
      this.model.chargeName = event.chargeName;
      this.model.isManifested = event.isManifested === 'Y' ? true : false;
      this.cbChargeCode.mymodel = event.chargeCode;
      this.model[`error-chargeName`] = "";
    }
  }

  changeEventChargeCode(event) {
    if (event.chargeCode == null || event.chargeCode === '' || event.chargeCode === undefined) {
      this.model.chargeName = '';
      this.model.chargeCode = '';
      this.model.isManifested = false;
    } else {
      this.model.chargeCode = event.chargeCode;
      this.model.chargeName = event.chargeName;
      this.model.isManifested = event.isManifested === 'Y' ? true: false;
      this.cbChargeName.mymodel = event.chargeName;
      this.model[`error-chargeCode`] = "";
    }
  }

  changeEventCurrency(event) {
    if (event.currencyCode == null || event.currencyCode === '' || event.currencyCode === undefined) {
      this.model.currencyName = '';
      this.model.currencyCode = '';
    } else {
      this.model.currencyName = event.currencyName;
      this.model.currencyCode = event.currencyCode;
      this.model[`error-currencyCode`] = "";
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = '',
    acctDebtorCode?: string
  ) {
    let clipboardData = acctDebtorCode ? '' : event.clipboardData;
    let pastedText = acctDebtorCode ? event : clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar);
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        if (field !== 'currencyCode' && event.target.value.length === 0) {
          this.resetForm();
        }
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar) {
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  resetForm() {
    this.model = new AdhocCharge();
    this.d20 = 0;
    this.h20 = 0;
    this.d40 = 0;
    this.h40 = 0;
    this.d45 = 0;
    this.h45 = 0;
    this.cbChargeCode.mymodel = '';
    this.cbChargeName.mymodel = '';
    this.cbBlContainerContainerType.mymodel = 'ALL';
    this.cbCurrency.mymodel = '';
    this.model.remarks = '';
    this.model.containerType = 'ALL';
    this.cbBlContainerContainerType.setValue("ALL");
  }

  deleteRow() {
    // handle delete event
    this.loading = true;
    let deleteList = [];
    this.chargeGrid.getSelectedValues().forEach((element) => {
      var deleteObj = { "chargeId": "", "chargeCode": "" };
      deleteObj.chargeId = element.chargeId;
      deleteObj.chargeCode = element.chargeCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/checkingListChargeUsed",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {

            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }

          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );
  }

  checkChargeCode() {
    if (this.d20 + this.h20 + this.d40 + this.d45 + this.h45 <= 0) {
      this.isInvalidChargeValue = true;
    }
    this.isInvalidChargeValue = false;
  }
}
