import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  ConfigService,
  CookieService,
  GenericUtil,
  PdfPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { trimTrailingNulls } from "../../../node_modules/@angular/compiler/src/render3/view/util";
import { DISABLED } from "../../../node_modules/@angular/forms/src/model";
import { specialCharUtil } from "../shared";

declare var $: any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselIdFrom: string = "";
  blOceanVesselCodeFrom: string = "";
  blOceanVesselVoyageFrom: string = "";
  blOceanVesselBoundFrom: string = "";
  blOceanVesselNameFrom: string = "";
  blOceanServiceCodeFrom: string = "";

  blOceanVesselIdTo: string = "";
  blOceanVesselCodeTo: string = "";
  blOceanVesselVoyageTo: string = "";
  //blOceanVesselBoundTo: string = '';

  constructor() { }
}

export class ParamBL {
  blNo: string = "";
  constructor() { }
}

@Component({
  selector: 'app-edi-transmit-to-pod-page',
  templateUrl: './edi-transmit-to-pod-page.component.html',
  styleUrls: ['./edi-transmit-to-pod-page.component.css']
})
export class EdiTransmitToPodPageComponent
  extends Validator
  implements OnInit, AfterViewInit {
  @ViewChild("cbVesselFrom") cbVesselFrom: ComboPluginComponent;
  @ViewChild("cbVoyageFrom") cbVoyageFrom: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("cbPOT") cbPOT: ComboPluginComponent;
  @ViewChild("gridBLTransferByVessel")
  gridBLTransferByVessel: GridPluginComponent;
  @ViewChild("gridBLTransferByBL") gridBLTransferByBL: GridPluginComponent;
  @ViewChild("ediTransmissionList") ediTransmissionList: PdfPluginComponent;

  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVesselFrom;
  settingVoyageFrom;
  settingPOD;
  settingPOT;
  settingGridBLTransferByVessel;
  settingGridBLTransferByBL;
  settingPDF;

  type: string = "";

  formatName: string = "";

  podCode: string = "%";
  potCode: string = "%";

  sCharUtil = new specialCharUtil();

  /* Model */
  model = new Param();
  modelAdd = new ParamBL();

  radioTransferByVessel = "Y";

  isError: Boolean = false;
  isErrorAdd: boolean = false;
  validatorRules = {};
  parameter = [];
  respJson = [];
  parameterJoin: string = "";
  radioTransferByVesselWise: string = "N";
  radioTransferByBlWise: string = "N";
  radioTransTypeActual: string = "N";
  radioTransTypeProforma: string = "N";
  portOfCallTo = "";
  portOfCallFrom = "";
  hitUrl = this.configService.config.BASE_API.toString();
  vesselId: string = "";
  vesselCode: string = "";
  vesselName: string = "";
  vesselVoyage: string = "";
  serviceCode: string = "";

  blFinished: Boolean = true;
  blFinishedU: Boolean = true;
  blFinishedN: Boolean = true;
  blFinishedD: Boolean = true;

  blUnfinished: Boolean = true;
  blUnfinishedU: Boolean = true;
  blUnfinishedN: Boolean = true;
  blUnfinishedD: Boolean = true;

  vesVoyGrid: string = "";

  isDownloadable: Boolean = false;
  officeCode = this.cookieService.getDefaultLocationCode();
  lockedBlTableData: any;
  isSwitchBL: Boolean = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericUtil: GenericUtil,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {

    super();
    // Hotfix:
    // Disable these 2 button, transmit v1.5 and transmit v2 so users at live should not accidentally clicked

    this.settingToolbar = this.getSettingToolbar();

    this.settingVesselFrom = {
      id: "cbVesselFrom",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageFrom = {
      id: "cbVoyageFrom",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POD",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT = {
      id: "cbPOT",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POT",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 100 },
        { header: "Valid", field: "isValid", width: 100 },
      ],
    };

    this.settingGridBLTransferByVessel = {
      id: "gridBLTransferByVessel",
      url:
        this.configService.config.BASE_API.toString() + "/BLTransfer/searchBL",
      page: 10,
      columns: [{ header: "B/L No", field: "blNo", width: 300 }],
      buttons: [
        {
          /*name: 'Edit', event: 'edit', enabled: true */
        },
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      editable: false,
      sortingDirection: "ASC",
    };

    this.settingGridBLTransferByBL = {
      id: "gridBLTransferByBL",
      url: "",
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 300, editType: "text" },
      ],
      buttons: [
        {
          /*name: 'Edit', event: 'edit', enabled: true */
        },
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      editable: true,
      sortingDirection: "ASC",
    };

    this.settingPDF = {
      id: "ediTransmissionList",
      url: "",
      hidePrint: false,
    };
  }
  ngOnInit() { }

  ngAfterViewInit() {
    console.log("init");
    this.setValidatorTransferByVessel();
    this.model.blOceanVesselBoundFrom = "O";
    //this.model.blOceanVesselBoundTo = "O";
    this.gridBLTransferByBL.disable();
    $("#addGrid").attr("disabled", true);
    $("#remGrid").attr("disabled", true);
    $("#blUnfinishedN").attr("disabled", true);
    $("#blUnfinishedU").attr("disabled", true);
    $("#blUnfinishedD").attr("disabled", true);
    this.radioTransferByVesselWise = "Y";
    this.radioTransTypeActual = "Y";

    $("#blUnfinished").change(function () {
      // this will contain a reference to the checkbox
      if (this.checked) {
        // the checkbox is now checked
        $(".clUnfinished").attr("disabled", false);
        $(".clUnfinished").attr("checked", true);
      } else {
        // the checkbox is now no longer checked
        $(".clUnfinished").attr("disabled", true);
        $(".clUnfinished").attr("checked", false);
      }
    });
    $(".clUnfinished").attr("disabled", false);

    $("#blFinished").change(function () {
      // this will contain a reference to the checkbox
      if (this.checked) {
        // the checkbox is now checked
        $(".clFinished").attr("disabled", false);
        $(".clFinished").attr("checked", true);
      } else {
        // the checkbox is now no longer checked
        $(".clFinished").attr("disabled", true);
        $(".clFinished").attr("checked", false);
      }
    });

    if (this.cookieService.getDefaultLocationCode() === "SGSIN") {
      console.log("singapore");

      this.isDownloadable = false;
      console.log(this.isDownloadable);
      $("#ckDownload").prop("checked", false);
    } else {
      this.isDownloadable = true;
      console.log("non singapore");
      console.log(this.isDownloadable);
      $("#ckDownload").prop("checked", true);
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.onRetrieve();
        break;

      case "transmitToPOD":
        console.log("transmit to pod");
        this.onTransmitToPOD();
        break;

      case "cancel":
        this.onCancel();
        break;

      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorTransferByVessel() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-blOceanVesselCodeTo"] = "";
    this.model["error-blOceanVesselVoyageTo"] = "";
    //this.model['error-blOceanVesselBoundTo'] = '';
    this.model["error-blNoValidator"] = "";

    this.validatorRules = {
      blOceanVesselCodeFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel should not be empty.",
          },
        ],
      },

      blOceanVesselVoyageFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage should not be empty.",
          },
        ],
      },

      blOceanVesselBoundFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
    };
  }

  setValidatorTransferByBL() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-cbPOD"] = "";
    this.model["error-cbPOT"] = "";
    //this.model['error-blOceanVesselBoundTo'] = '';
    this.model["error-blNoValidator"] = "";
    /*
    this.validatorRules = {
      blOceanVesselCodeTo: {
        rules: [{
          type: "empty",
          prompt: "Vessel Destination should not be empty."
        }]
      },

      blOceanVesselVoyageTo: {
        rules: [{
          type: "empty",
          prompt: "Voyage Destination should not be empty."
        }]
      },

      blOceanVesselBoundTo: {
        rules: [{
          type: "empty",
          prompt: "Bound Destination should not be empty."
        }]
      },

      blNoValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'BL No(s) are not valid. Please check the grid.'
        }]
      },
    }
    */
  }

  changeEventVesselFrom(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselIdFrom = "";
      this.model.blOceanVesselCodeFrom = "";
      this.model.blOceanVesselNameFrom = "";
    } else {
      this.model.blOceanVesselIdFrom = event.vesselId;
      this.model.blOceanVesselCodeFrom = event.vesselCode;
      this.model.blOceanVesselNameFrom = event.vesselName;

      this.cbVoyageFrom.disableCombo = false;
      this.cbVoyageFrom.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        event.vesselId +
        "/" +
        this.model.blOceanVesselBoundFrom +
        "/voyage_like={query}"
      );
      this.cbVoyageFrom.setValue("");
    }
  }

  changeEventPOD(event) {
    if (event.locationCode == null) {
      this.podCode = "%";
    } else {
      this.podCode = event.locationCode;
    }
  }

  changeEventPOT(event) {
    if (event.locationCode == null) {
      this.potCode = "%";
    } else {
      this.potCode = event.locationCode;
    }
  }

  changeEventVoyageFrom(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyageFrom = "";
      this.model.blOceanServiceCodeFrom = "";
      this.cbPOD.setValue("");
      this.cbPOT.setValue("");
    } else {
      this.model.blOceanVesselVoyageFrom = event.voyage;
      this.model.blOceanServiceCodeFrom = event.serviceCode;
      this.cbPOD.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/distinctPODByOfficeCodeVesselIdVoyageBound/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blOceanVesselIdFrom +
        "/" +
        this.sCharUtil.htmlEncode(String(event.voyage).trim()) +
        "/" +
        this.model.blOceanVesselBoundFrom +
        "/" +
        "{query}"
      );
      this.cbPOD.setValue("");
      this.cbPOT.setUrl(
        this.configService.config.BASE_API.toString() +
        //"/MasterBookings/distinctPOTByOfficeCodeVesselIdVoyageBound/" +
        "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blOceanVesselIdFrom +
        "/" +
        this.sCharUtil.htmlEncode(String(event.voyage).trim()) +
        "/" +
        this.model.blOceanVesselBoundFrom +
        "/" +
        "{query}"
      );
      this.cbPOT.setValue("");
    }
  }
  /*
  changeEventVesselTo(event){
    if(event.vesselId == null || event.vesselId == "" || event.vesselId == undefined){
      this.model.blOceanVesselIdTo = '';
      this.model.blOceanVesselCodeTo = '';

    }else{
      this.model.blOceanVesselIdTo = event.vesselId;
      this.model.blOceanVesselCodeTo = event.vesselCode;

      this.cbVoyageTo.disableCombo = false;
      this.cbVoyageTo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + event.vesselId + "/" + this.model.blOceanVesselBoundTo  + "/voyage_like={query}");
      this.cbVoyageTo.setValue("");
    }
  }

  changeEventVoyageTo(event){
    if(event.voyage == null){
      this.model.blOceanVesselVoyageTo = '';
    }else{
      this.model.blOceanVesselVoyageTo = event.voyage;
    }
  }
  */
  changeEventTransmit(event) {
    if (event.target.value.toString().trim().toUpperCase() == "VESSEL") {
      this.radioTransferByVesselWise = "Y";
      this.radioTransferByBlWise = "N";
      this.radioTransTypeProforma = "N";
      this.radioTransTypeActual = "Y";
      this.disableToolbarButtons = "";
      this.gridBLTransferByBL.disable();
      $("#addGrid").attr("disabled", true);
      $("#remGrid").attr("disabled", true);

      $("#myfieldset").attr("disabled", false);
      $("#myfieldset2").attr("disabled", false);
      $("#myfieldset3").attr("disabled", false);
      $("#myfieldset4").attr("disabled", false);
      this.gridBLTransferByBL.onClear();

      //this.grabBLNumber();
    } else {
      this.gridBLTransferByBL.enable();
      $("#addGrid").attr("disabled", false);
      $("#remGrid").attr("disabled", false);

      this.cbVesselFrom.setValue("");
      this.cbVoyageFrom.setValue("");
      this.model.blOceanVesselBoundFrom = "O";
      this.cbPOD.setValue("");
      this.cbPOT.setValue("");

      $("#myfieldset").attr("disabled", true);
      $("#myfieldset2").attr("disabled", true);
      $("#myfieldset3").attr("disabled", true);
      $("#myfieldset4").attr("disabled", true);

      this.radioTransferByBlWise = "Y";
      this.radioTransferByVesselWise = "N";
      this.radioTransTypeProforma = "Y";
      this.radioTransTypeActual = "N";
      this.setValidatorTransferByBL();
      this.disableToolbarButtons = "retrieve";

      $("#blUnfinishedN").attr("disabled", true);
      $("#blUnfinishedU").attr("disabled", true);
      $("#blUnfinishedD").attr("disabled", true);
      $("#blUnfinished").prop("checked", true);
      $(".clUnfinished").attr("disabled", true);
      $(".clUnfinished").attr("checked", false);

      $(".clFinished").prop("checked", true);
      $("#blFinished").prop("checked", true);

      $("#ckCOC").prop("checked", true);
      $("#ckSOC").prop("checked", true);
      $("#ckSLOT").prop("checked", true);
      $("#ckCOCTS").prop("checked", true);
      $("#ckSOCTS").prop("checked", true);
    }
  }
  changeEventTransferByVessel(event) {
    //    console.log(event)
    if (event.target.checked) {
      this.radioTransferByVessel = "Y";
      this.setValidatorTransferByVessel();
      this.disableToolbarButtons = "";
    }
  }
  changeEventTransferByBL(event) {
    //  console.log(event)
    if (event.target.checked) {
      this.radioTransferByVessel = "N";
      this.setValidatorTransferByBL();
      this.disableToolbarButtons = "retrieve";
    }
  }

  // start Transmission Type
  changeEventTransmissionType(event) {
    if (event.target.value.toString().trim().toUpperCase() == "ACTUAL") {
      this.radioTransTypeActual = "Y";
      this.radioTransTypeProforma = "N";
    } else {
      this.radioTransTypeProforma = "Y";
      this.radioTransTypeActual = "N";
    }
  }
  // end Transmission Type

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
    // Ensure the modal is initialized with required options
    const modalElement = $('#dialog');
    modalElement.modal({
      backdrop: 'static',
      keyboard: false,
      show: true,         
    });
  }

  rowEvent(act: string) {
    if (act == "add") {
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.addData(modelAdd);
      this.gridBLTransferByBL.loadData();
      //this.genericUtil.showDialog('AddForm', 'Add BL', 300, 300)
    } else {
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck: number;
      var storeTemp = new ListStore();

      this.gridBLTransferByBL.listStore.store.forEach((xy) => {
        // // // // console.log("xy "+xy);
        storeTemp.addData(xy);
      });

      storeTemp.store.forEach((el) => {
        if (el["select"] == "checked") {
          boxCheck = this.gridBLTransferByBL.listStore.store.indexOf(el);
          this.gridBLTransferByBL.listStore.store.splice(boxCheck, 1);
          this.gridBLTransferByBL.loadData();

          //  $('input[name="gridCount"]').val(parseInt($('input[name="gridCount"]').val())-1);
        }
      });

      /*
      this.gridBLTransferByBL.listStore.store.forEach(col=>{
        seqNo = seqNo + 1;
        col.seqNo=seqNo;
      });
      */

      console.log("Sizenya: " + this.gridBLTransferByBL.store.length);
      if (this.gridBLTransferByBL.store.length == 0) {
        this.vesVoyGrid = "";
      }
      this.gridBLTransferByBL.loadData();
      this.gridBLTransferByBL.clearSelectedValues();
    }
  }

  closeDialog(id: string) {
    this.genericUtil.closeDialog(id);
    this.gridBLTransferByBL.loadData();
  }

  infoGrid(event) {
    this.info = event;
  }

  gridEventByVessel(event) { }

  gridEventByBL(event) {
    console.log(event);
    // console.log(event.split(".")[0]);
    switch (event.split(".")[0]) {
      case "click":
        break;

      case "selected":
        break;

      case "afterLoad":
        break;

      case "AfterEdit":
        console.log("after edit");
        console.log(event.split(";")[2].toUpperCase());
        console.log(parseInt(event.split(".")[1]));

        this.checkValidBL(
          event.split(";")[2].toUpperCase(),
          parseInt(event.split(".")[1])
        );
        break;

      default:
        break;
    }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName].toUpperCase();
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  checkValidBL(param, nogrid: number) {
    console.log("grid no: " + nogrid);
    console.log(param);
    var doble = [];
    var dblFlag = false;

    this.gridBLTransferByBL.listStore.store.forEach((key) => {
      doble.push(key);
    });
    //RESET vesvoygrid if grid lengthnya cuma 1 karena tidak ada pembanding dengan BL lain
    if (this.gridBLTransferByBL.listStore.store.length == 1)
      this.vesVoyGrid = "";
    // console.log(doble)
    dblFlag = this.checkDuplicateInObject("blNo", doble);

    if (dblFlag == true) {
      this.message(
        "information",
        "Information",
        "Duplicate BL Reference(s) detected.",
        "okonly",
        { ok: "this.loading=false;" }
      );
      this.gridBLTransferByBL.listStore.store.splice(nogrid - 1, 1);
      if (this.gridBLTransferByBL.store.length == 0) {
        this.vesVoyGrid = "";
      }
      this.gridBLTransferByBL.loadData();
    }

    var cleanParam = param.replace(/[^a-z0-9]/gi, "-").replace(/;/g, "-");
    if (cleanParam == "") cleanParam = "-";
    console.log("clean param" + cleanParam);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/blreinstatement/getStatusByBlNo/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        cleanParam.toUpperCase()
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            console.log("vesvoygrid " + this.vesVoyGrid);
            if (this.vesVoyGrid != "") {
              if (
                resp.json()["content"][0].blOceanVesselCode +
                resp.json()["content"][0].blOceanVesselVoyage ==
                this.vesVoyGrid
              ) {
                this.vesVoyGrid =
                  resp.json()["content"][0].blOceanVesselCode +
                  resp.json()["content"][0].blOceanVesselVoyage;
              } else {
                this.message(
                  "information",
                  "Information",
                  "BL(s) from different vessels not allowed.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.gridBLTransferByBL.listStore.store.splice(nogrid - 1, 1);
                if (this.gridBLTransferByBL.store.length == 0) {
                  this.vesVoyGrid = "";
                }
                this.gridBLTransferByBL.loadData();
              }
            } else {
              this.vesVoyGrid =
                resp.json()["content"][0].blOceanVesselCode +
                resp.json()["content"][0].blOceanVesselVoyage;
            }
          } else {
            this.message(
              "information",
              "Information",
              "Invalid BL No.",
              "okonly",
              { ok: "this.loading=false;" }
            );
            this.gridBLTransferByBL.listStore.store.splice(nogrid - 1, 1);
            if (this.gridBLTransferByBL.store.length == 0) {
              this.vesVoyGrid = "";
            }

            this.gridBLTransferByBL.loadData();
          }
        }
      });
  }

  onRetrieve() {
    let isPassedValidation: boolean;
    var self = this;
    this.setValidatorTransferByVessel();
    self.vesselId = this.model.blOceanVesselIdFrom;
    self.vesselCode = this.model.blOceanVesselCodeFrom;
    self.vesselName = this.model.blOceanVesselNameFrom;
    self.vesselVoyage = this.model.blOceanVesselVoyageFrom;
    self.serviceCode = this.model.blOceanServiceCodeFrom;
    isPassedValidation = this.onValidate(this.model);

    if (!isPassedValidation) {
      this.grabBLNumber(function (callback) {
        // setTimeout(function(){

        if (self.parameterJoin.length > 0) {
          self.grabLockedBlNo(
            self.model.blOceanVesselIdFrom,
            self.sCharUtil.htmlEncode(
              String(self.model.blOceanVesselVoyageFrom).trim()
            ),
            self.model.blOceanVesselBoundFrom,
            self.parameterJoin,
            self.model.blOceanVesselCodeFrom
          );
        } else {
          self.message(
            "information",
            "Information",
            "No data(s) found.",
            "okonly",
            { ok: "self.loading=false;" }
          );
          self.cbPOD.setValue("");
          self.cbPOD.setUrl("");
          self.cbPOT.setValue("");
          self.cbPOT.setUrl("");
        }
      });
    }
  }

  grabLockedBlNo(
    vesselId: string,
    voyage: string,
    bound: string,
    parameter: string,
    vesselCode: string
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/podController/grabLockedBlNo/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        parameter
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if(resp.json()["lockBlCount"]){
            this.lockedBlTableData = resp.json()["lockBlInfo"];
          } else {
            this.message(
              "information",
              "Information",
              "No locked BL exists!!",
              "okonly",
              { ok: "" }
            );
          }
        }
      });
  }

  finBLNumber(callback?: (data) => void) {
    var flag = 0;

    this.gridBLTransferByBL.listStore.store.forEach((yur) => {
      this.parameter[flag] = yur.blNo.toUpperCase();
      flag++;
    });

    // prevent from after api call, parameter is empty
    var parameterCopy = [...this.parameter];
    var ocean = "x";
    var self = this;
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/BLInward/findBLDataForTransmitToPOD/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.parameter[0]
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if(!resp.json()["isSWType"]) {
            console.log(resp.json().blData["blOceanVesselVoyage"]);
            self.model.blOceanVesselCodeFrom =
              resp.json().blData["blOceanVesselCode"];
            self.model.blOceanVesselIdFrom =
              resp.json().blData["blOceanVesselId"];
            self.model.blOceanVesselVoyageFrom =
              resp.json().blData["blOceanVesselVoyage"];
            self.model.blOceanVesselNameFrom = 
              resp.json().bookingData["bOceanVesselName"]
  
            self.parameterJoin = parameterCopy.join();
  
            //this.formatName = this.model.blOceanVesselCodeFrom.trim()+''+this.model.blOceanVesselVoyageFrom.trim()+'.'+this.genericUtil.getPSIDate();
            //this.formatName = this.formatName.replace('-','').replace(' ','.').replace(':','');
            self.formatName =
              self.model.blOceanVesselCodeFrom.trim() +
              "" +
              self.model.blOceanVesselVoyageFrom.trim() +
              "." +
              self.genericUtil
                .getPSIDate()
                .replace("-", "")
                .replace("-", "")
                .replace(":", "")
                .replace(":", "")
                .replace(" ", "");
          } else {
            self.isSwitchBL = true;
          }
        
        }

        callback && callback("");
      });
  }

  grabBLNumber(callback?: (data) => void) {
    var postData = [];
    var cntOwn = {};
    var blFinished = {};
    var blFinishedSt = {};
    var blUnFinished = {};
    var blUnFinishedSt = {};

    if ($("#ckSOC").is(":checked")) {
      cntOwn["soc"] = "SOC";
    } else {
      cntOwn["soc"] = "XYZ";
    }

    if ($("#ckCOC").is(":checked")) {
      cntOwn["coc"] = "COC";
    } else {
      cntOwn["coc"] = "XYZ";
    }

    if ($("#ckSOCTS").is(":checked")) {
      cntOwn["socTS"] = "SOCTS";
    } else {
      cntOwn["socTS"] = "XYZ";
    }

    if ($("#ckCOCTS").is(":checked")) {
      cntOwn["cocTS"] = "COCTS";
    } else {
      cntOwn["cocTS"] = "XYZ";
    }

    if ($("#ckSLOT").is(":checked")) {
      cntOwn["slot"] = "SLOT";
    } else {
      cntOwn["slot"] = "XYZ";
    }

    if ($("#blFinishedN").is(":checked")) {
      blFinishedSt["blStatusN"] = "N";
    } else {
      blFinishedSt["blStatusN"] = "X";
    }

    if ($("#blFinished").is(":checked")) {
      blFinished["blFinished"] = "Y";
    } else {
      blFinished["blFinished"] = "X";
    }

    if ($("#blFinishedU").is(":checked")) {
      blFinishedSt["blStatusU"] = "U";
    } else {
      blFinishedSt["blStatusU"] = "X";
    }

    if ($("#blFinishedD").is(":checked")) {
      blFinishedSt["blStatusD"] = "D";
    } else {
      blFinishedSt["blStatusD"] = "X";
    }

    if ($("#blUnfinished").is(":checked")) {
      blUnFinished["blUnifinshed"] = "N";
    } else {
      blUnFinished["blUnifinshed"] = "X";
    }

    if ($("#blUnfinishedN").is(":checked")) {
      blUnFinishedSt["blStatusUN"] = "N";
    } else {
      blUnFinishedSt["blStatusUN"] = "X";
    }

    if ($("#blUnfinishedU").is(":checked")) {
      blUnFinishedSt["blStatusUU"] = "U";
    } else {
      blUnFinishedSt["blStatusUU"] = "X";
    }

    if ($("#blUnfinishedD").is(":checked")) {
      blUnFinishedSt["blStatusUD"] = "D";
    } else {
      blUnFinishedSt["blStatusUD"] = "X";
    }

    //String fileName = map.get("vesselCode")+ "" + map.get("voyage")+ "." + map.get("dateProc").replaceAll("-","").replaceAll(" ",".").replaceAll(":","");
    this.formatName =
      this.model.blOceanVesselCodeFrom.trim() +
      "" +
      this.model.blOceanVesselVoyageFrom +
      "." +
      this.genericUtil
        .getPSIDate()
        .replace("-", "")
        .replace("-", "")
        .replace(":", "")
        .replace(":", "")
        .replace(" ", "");

    console.log("nama file" + this.formatName);

    postData.push({
      vesselId: this.model.blOceanVesselIdFrom,
      vesselCode: this.model.blOceanVesselCodeFrom,
      voyage: this.model.blOceanVesselVoyageFrom,
      bound: this.model.blOceanVesselBoundFrom,
      office: this.cookieService.getDefaultLocationCode(),
      date: this.genericUtil.getPSIDate(),
      blFinished: blFinished["blFinished"],
      blUnfinished: blUnFinished["blUnifinshed"],
      blFinishedN: blFinishedSt["blStatusN"],
      blFinishedD: blFinishedSt["blStatusD"],
      blFinishedU: blFinishedSt["blStatusU"],
      blUnfinishedN: blUnFinishedSt["blStatusUN"],
      blUnfinishedD: blUnFinishedSt["blStatusUD"],
      blUnfinishedU: blUnFinishedSt["blStatusUU"],
      soc: cntOwn["soc"],
      coc: cntOwn["coc"],
      socTS: cntOwn["socTS"],
      cocTS: cntOwn["cocTS"],
      slot: cntOwn["slot"],
      podCode: this.podCode,
      potCode: this.potCode,
    });

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        "/ediController/grabBLNumber/",
        postData
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (resp.json().length > 0) {
            this.respJson = resp.json();

            var lenResp = 0;

            this.respJson.forEach((yx) => {
              this.parameter[lenResp] = yx.blNo;
              lenResp++;
              //this.parameter.push({
              //blNo : yx.blNo
              //})
            });

            this.parameterJoin = this.parameter.join();
          } //end lenght

          console.log("parameter : ", this.parameter);
        } //end ok

        //if(callback)
        //callback('');
        callback && callback("");
      });
  }

  onTransmitToPOD() {
    let isPassedValidation: boolean;
    this.parameter = [];
    this.parameterJoin = "";
    this.formatName = "";
    var self = this;

    console.log("#1");
    console.log(this.radioTransferByVesselWise);

    if (this.radioTransferByVesselWise == "Y") {
      this.setValidatorTransferByVessel();
      self.vesselId = this.model.blOceanVesselIdFrom;
      self.vesselCode = this.model.blOceanVesselCodeFrom;
      self.vesselName = this.model.blOceanVesselNameFrom;
      self.vesselVoyage = this.model.blOceanVesselVoyageFrom;
      self.serviceCode = this.model.blOceanServiceCodeFrom;
      isPassedValidation = this.onValidate(this.model);

      console.log("ispasseevalidation" + isPassedValidation);

      if (!isPassedValidation) {
        this.grabBLNumber(function (callback) {
          // setTimeout(function(){

          if (self.parameterJoin.length > 0) {
            var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);

            self.type = "Vessel";

            self.actionTransmitToPOD(
              self.model.blOceanVesselIdFrom,
              self.sCharUtil.htmlEncode(
                String(self.model.blOceanVesselVoyageFrom).trim()
              ),
              self.model.blOceanVesselBoundFrom,
              self.parameterJoin,
              self.model.blOceanVesselCodeFrom,
              cleanFileName,
              self.type,
              self.radioTransferByVesselWise,
              self.radioTransferByBlWise,
              self.radioTransTypeActual,
              self.radioTransTypeProforma
            );
          } else {
            self.message(
              "information",
              "Information",
              "No data(s) found.",
              "okonly",
              { ok: "self.loading=false;" }
            );
            self.cbPOD.setValue("");
            self.cbPOD.setUrl("");
            self.cbPOT.setValue("");
            self.cbPOT.setUrl("");
          }
        });
      }
    } else {
      this.finBLNumber(function (callback) {
        if (self.parameterJoin.length > 0) {
          var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);

          self.vesselId = self.model.blOceanVesselIdFrom;
          self.vesselCode = self.model.blOceanVesselCodeFrom;
          self.vesselName = self.model.blOceanVesselNameFrom;
          self.vesselVoyage = self.model.blOceanVesselVoyageFrom;
          self.serviceCode = self.model.blOceanServiceCodeFrom;

          self.type = "BL";

          self.actionTransmitToPOD(
            self.model.blOceanVesselIdFrom,
            self.sCharUtil.htmlEncode(
              String(self.model.blOceanVesselVoyageFrom).trim()
            ),
            self.model.blOceanVesselBoundFrom,
            self.parameterJoin,
            self.model.blOceanVesselCodeFrom,
            cleanFileName,
            self.type,
            self.radioTransferByVesselWise,
            self.radioTransferByBlWise,
            self.radioTransTypeActual,
            self.radioTransTypeProforma
          );
        } else {
          if(self.isSwitchBL) {
            self.message(
              "information",
              "Information",
              "Invalid BL No.",
              "okonly",
              { ok: "self.loading=false;" }
            );
          } else {
            self.message(
              "information",
              "Information",
              "No data(s) found.",
              "okonly",
              { ok: "self.loading=false;" }
            );
          }
          
          self.cbPOD.setValue("");
          self.cbPOD.setUrl("");
          self.cbPOT.setValue("");
          self.cbPOT.setUrl("");
        }
      });
    }
  }

  transmitToPODAsProforma() {
    var self = this;
    var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);
    self.onTransmitToPODAsProforma(
      self.model.blOceanVesselIdFrom,
      self.sCharUtil.htmlEncode(
        String(self.model.blOceanVesselVoyageFrom).trim()
      ),
      self.model.blOceanVesselBoundFrom,
      self.parameterJoin,
      self.model.blOceanVesselCodeFrom,
      cleanFileName,
      self.type,
      self.radioTransferByVesselWise,
      self.radioTransferByBlWise,
      self.radioTransTypeActual,
      self.radioTransTypeProforma
    );
  }

  onTransmitToPODAsProforma(
    vesselId: string,
    voyage: string,
    bound: string,
    parameter: string,
    vesselCode: string,
    formatName: string,
    type: string,
    radioTransferByVesselWise: string,
    radioTransferByBlWise: string,
    radioTransTypeActual: string,
    radioTransTypeProforma: string
  ) {
    radioTransTypeProforma = "Y";
    radioTransTypeActual = "N";

    console.log("FORMAT NAME" + formatName);
    var dullName = formatName;//.replace(".", "-");
    var ceked = "";

    if ($("#ckDownload").is(":checked")) {
      ceked = "cek";
    } else {
      ceked = "not";
    }

    let uri = '';
    if (this.configService.config.standalone) {
      const transmitToPodConfig = this.configService.config.standalone.find(item => item.endpoint === "/podController");

      if (transmitToPodConfig) {
        uri = transmitToPodConfig.target + "/podController/transmitToPOD";
      } else {
        uri = this.configService.config.BASE_API.toString() + "?q=/podController/transmitToPOD";
      }
    } else {
      uri = this.configService.config.BASE_API.toString() + "?q=/podController/transmitToPOD";
    }

    var self = this;
    this.loading = true;

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });

    $.ajax({
      url: uri,
      method: "POST",
      data: JSON.stringify({
        officeCode: this.cookieService.getDefaultLocationCode(),
        vesselId: this.vesselId,
        vesselCode: this.vesselCode,
        vesselName: this.vesselName,
        serviceCode: this.serviceCode,
        voyage: this.vesselVoyage,
        bound: bound,
        dateProc: this.genericUtil.getPSIDate().trim(),
        blNo: parameter,
        fileName: dullName,
        ceked: ceked,
        userId: this.cookieService.getName(),
        rdoVesselWise: radioTransferByVesselWise,
        rdoBlWise: radioTransferByBlWise,
        rdoActual: radioTransTypeActual,
        rdoProforma: radioTransTypeProforma
      }),
      // xhrFields: {
      //   responseType: "blob",
      // },
      success: function (data) {
        console.log("#3");
        console.log(data);

        if (data.blLockCount > 0) {
          self.message(
            "warning",
            "Warning",
            "Vessel is locked for " + data.POTCode + ", please contact POD/POT.",
            "okonly",
            { ok: "" }
          );
        } 
        if (data.processBlNos > 0) {
          self.generateEDIFile(
            self.vesselId,
            self.vesselCode,
            self.vesselVoyage,
            bound,
            data.blNos,
            formatName,
            dullName,
            type,
            ceked
          );
        } else {
          self.loading = false;
        }
      
      },
      error: function (xhr, status, error) {
        // This function will be called if the request fails
        console.error('Error:', error);
        console.error('Status:', status);
        console.error('Response:', xhr.responseText);
        // Handle the error here
        self.message(
          "information",
          "Information",
          "Error on Transmit to POD",
          "okonly",
          { ok: "self.loading=false;" }
        );
      }
    });

  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event == "close-pdf") {
      //this.disableToolbarButtons = 'print';
    }
  }

  actionTransmitToPOD(
    vesselId: string,
    voyage: string,
    bound: string,
    parameter: string,
    vesselCode: string,
    formatName: string,
    type: string,
    radioTransferByVesselWise: string,
    radioTransferByBlWise: string,
    radioTransTypeActual: string,
    radioTransTypeProforma: string
  ) {
    console.log("FORMAT NAME" + formatName);
    var dullName = formatName;//.replace(".", "-");
    var ceked = "";

    if ($("#ckDownload").is(":checked")) {
      ceked = "cek";
    } else {
      ceked = "not";
    }

    let uri = '';
    if (this.configService.config.standalone) {
      const transmitToPodConfig = this.configService.config.standalone.find(item => item.endpoint === "/podController");

      if (transmitToPodConfig) {
        uri = transmitToPodConfig.target + "/podController/transmitToPOD";
      } else {
        uri = this.configService.config.BASE_API.toString() + "?q=/podController/transmitToPOD";
      }
    } else {
      uri = this.configService.config.BASE_API.toString() + "?q=/podController/transmitToPOD";
    }

    var self = this;
    this.loading = true;

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });

    $.ajax({
      url: uri,
      method: "POST",
      data: JSON.stringify({
        officeCode: this.cookieService.getDefaultLocationCode(),
        vesselId: this.vesselId,
        vesselCode: this.vesselCode,
        vesselName: this.vesselName,
        voyage: this.vesselVoyage,
        serviceCode: this.serviceCode,
        bound: bound,
        dateProc: this.genericUtil.getPSIDate().trim(),
        blNo: parameter,
        fileName: dullName,
        ceked: ceked,
        userId: this.cookieService.getName(),
        rdoVesselWise: radioTransferByVesselWise,
        rdoBlWise: radioTransferByBlWise,
        rdoActual: radioTransTypeActual,
        rdoProforma: radioTransTypeProforma
      }),
      // xhrFields: {
      //   responseType: "blob",
      // },
      success: function (data) {
        console.log("#3");
        console.log(data);
      
        if (self.radioTransferByVesselWise == 'Y' && self.radioTransTypeActual == 'Y'
          && data.containUnFinishedBl > 0) {
          self.message(
            "save",
            "Warning",
            "Unfinished BL detected, data will be transmitted as Proforma status",
            "yesno",
            { yes: "this.transmitToPODAsProforma()", no: "this.loading=false;" }
          );

        } else if(self.radioTransferByBlWise == 'Y' && data.lockBl) {
          self.message(
            "warning",
            "Warning",
            data.message,
            "okonly",
            { ok: "this.loading=false;" }
          );
        } else if(data.officeCodeNotFound) {
          self.message(
            "warning",
            "Warning",
            data.officeCodeNotFoundMsg,
            "okonly",
            { ok: "this.loading=false;" }
          );
        } else {
          if (data.blLockCount > 0) {
            self.message(
              "warning",
              "Warning",
              "Vessel is locked for " + data.POTCode + ", please contact POD/POT.",
              "okonly",
              { ok: "" }
            );
          } else if (data.invalidBl) {
            self.message(
              "warning",
              "Warning",
              data.invalidBlMessage,
              "okonly",
              { ok: "" }
            );
          }

          if (data.blWiseLockBlCount > 0) {
            self.message(
              "warning",
              "Warning",
              data.blWiseLockBlCountMsg,
              "okonly",
              { ok: "" }
            );
          }

          if (data.processBlNos > 0) {
            self.generateEDIFile(
              vesselId,
              vesselCode,
              voyage,
              bound,
              data.blNos,
              formatName,
              dullName,
              type,
              ceked
            );
          } else {
            self.loading = false;
          }
          
        }
        
      },
      error: function (xhr, status, error) {
        // This function will be called if the request fails
        console.error('Error:', error);
        console.error('Status:', status);
        console.error('Response:', xhr.responseText);
        // Handle the error here
        self.message(
          "information",
          "Information",
          "Error on Transmit to POD",
          "okonly",
          { ok: "self.loading=false;" }
        );
      }
    });
  }

  generateEDIFile(
    vesselId: string,
    vesselCode: string,
    voyage: string,
    bound: string,
    parameter: string,
    formatName: string,
    dullName: string,
    type: string,
    ceked: string,
  ) {
    var objToday = new Date();
    var curHour =
      objToday.getHours() > 12
        ? objToday.getHours() - 12
        : objToday.getHours() < 10
          ? "0" + objToday.getHours()
          : objToday.getHours();
    var curMinute =
      objToday.getMinutes() < 10
        ? "0" + objToday.getMinutes()
        : objToday.getMinutes();
    var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";

    var curDate =
      objToday.getDate() +
      "/" +
      (objToday.getMonth() + 1) +
      "/" +
      objToday.getFullYear();

    var localTime =
      curDate + "@" + curHour + ":" + curMinute + "@" + curMeridiem;
    console.log("local Time" + localTime);

    var uri = this.configService.config.BASE_API.toString() + "?q=" +
      "/ediController/transmit15/edi/"
    var self = this;
    this.loading = true;
    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });

    $.ajax({
      url: uri,
      method: "POST",
      data: JSON.stringify({
        officeCode: this.cookieService.getDefaultLocationCode(),
        vesselId: vesselId,
        vesselCode: vesselCode,
        voyage: voyage,
        bound: bound,
        dateProc: this.genericUtil.getPSIDate().trim(),
        blNo: parameter,
        fileName: dullName,
        ceked: ceked,
      }),
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        console.log("#3");
        console.log(data);

        if (self.isDownloadable) {
          FileSaver.saveAs(data, self.sCharUtil.htmlDecode(dullName));
        }

        var hitUrl = self.configService.config.getPDFUrl + "/edi-util/getFile";
        var jasperURL =
          self.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code=" +
          self.cookieService.getDefaultLocationCode() +
          "&filename=" +
          formatName.replace(".", "") +
          "&userid=" +
          self.cookieService.getName() +
          "&localTime=" +
          localTime +
          "&type=" +
          type;
        var fileName = "EDI-TRANSMISSION-REPORT-LIST.pdf";

        console.log("jasperurl" + jasperURL);

        var postDatas = {};
        postDatas["fileName"] = fileName;
        postDatas["url"] = jasperURL;
        self.loading = false;
        self.genericService.POST(hitUrl, postDatas).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json();

            if (result["message"] == "success") {
              self.ediTransmissionList.setPDFURL(
                self.configService.config.pdfUrl + result["filePath"]
              );
              self.ediTransmissionList.showPDF();
            }
          }
        });
      },
    });
  }

  testing() {
    console.log("Testingggg.......");
  }

  onCancel() {
    this.cbVesselFrom.setValue("");
    this.cbVesselFrom.clearSelect();
    this.cbVoyageFrom.setValue("");
    this.cbVoyageFrom.clearSelect();
    this.cbVoyageFrom.setUrl("");

    this.cbPOD.setValue("");
    this.cbPOD.setUrl("");
    this.cbPOT.setValue("");
    this.cbPOT.setUrl("");
    this.gridBLTransferByBL.onClear();
    this.model.blOceanVesselBoundFrom = "O";
    this.parameter = [];
    this.parameterJoin = "";
    this.vesVoyGrid = "";
    this.setValidatorTransferByVessel();

    $("#blUnfinishedN").attr("disabled", false);
    $("#blUnfinishedU").attr("disabled", false);
    $("#blUnfinishedD").attr("disabled", false);

    $("#blUnfinished").prop("checked", true);
    $(".clFinished").prop("checked", true);

    $(".clUnfinished").attr("disabled", true);
    $(".clFinished").attr("disabled", false);

    $(".clUnfinished").attr("checked", true);

    $(".clUnfinished").prop("checked", true);
    $("#blFinished").prop("checked", true);

    if (this.cookieService.getDefaultLocationCode() === "SGSIN") {
      this.isDownloadable = false;
      $("#ckDownload").prop("checked", false);
    } else {
      this.isDownloadable = false;
      $("#ckDownload").prop("checked", false);
    }

    $("#ckCOC").prop("checked", true);
    $("#ckSOC").prop("checked", true);
    $("#ckSLOT").prop("checked", true);
    $("#ckCOCTS").prop("checked", true);
    $("#ckSOCTS").prop("checked", true);

    this.blFinished = true;
    this.blFinishedU = true;
    this.blFinishedN = true;
    this.blFinishedD = true;

    this.blUnfinished = true;
    this.blUnfinishedU = true;
    this.blUnfinishedN = true;
    this.blUnfinishedD = true;

    this.clearLockedBlTableData();

    /*
   this.genericService.GET(this.configService.config.BASE_API.toString() + '/ediController/ediDL/THBKK/LALAL').subscribe((resp) =>{

    console.log(resp);

  });
  */

    if (this.cookieService.getDefaultLocationCode() === "SGSIN") {
      this.isDownloadable = false;
    } else {
      this.isDownloadable = true;
    }
  }

  clearLockedBlTableData(): void {
    this.lockedBlTableData = [];
    this.cdr.detectChanges();
  }

  onTransfer() {
    if (this.radioTransferByVessel == "N") {
      this.setValidatorTransferByBL();
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.model["blNoValidator"] = this.checkBLNoValidator();

      this.isError = this.onValidate(this.model);

      if (!this.isError) {
      }
    } else {
      this.setValidatorTransferByVessel();
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
      }
    }
  }

  checkBLNoValidator(): Boolean {
    console.log("** check BL No Validator **");
    let result: Boolean = false;
    if (this.gridBLTransferByBL.listStore.store.length == 0) {
      this.model["error-blNoValidator"] =
        "BL(s) should not be empty. Please input BL at least one BL.";
      result = true;
    } else {
      this.gridBLTransferByBL.listStore.store.forEach((element) => {
        console.log(element);
      });
    }
    return result;
  }

  checklistFinishedDisabled() {
    $(".clFinished").attr("disabled", true);
  }

  checklistUnfinishedDisabled() {
    $(".clUnfinished").attr("disabled", true);
  }

  eventBLFinished(event) {
    if (event.target.checked) {
      this.blFinished = true;
      this.blFinishedN = true;
      this.blFinishedU = true;
      this.blFinishedD = true;
    } else {
      this.blFinished = false;
      this.blFinishedN = false;
      this.blFinishedU = false;
      this.blFinishedD = false;
      this.checklistFinishedDisabled();
    }
  }

  eventBLFinishedN(event) {
    if (event.target.checked) {
      this.blFinishedN = true;
      this.blFinished = true;
    } else {
      this.blFinishedN = false;
      console.log("EVENT CHECK N UNCHECK: " + this.blFinishedD);
      if (this.blFinishedD == false && this.blFinishedU == false) {
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }
    }
  }

  eventBLFinishedU(event) {
    if (event.target.checked) {
      this.blFinishedU = true;
      this.blFinished = true;
    } else {
      this.blFinishedU = false;
      if (this.blFinished == false && this.blFinishedN == false) {
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }
    }
  }

  eventBLFinishedD(event) {
    if (event.target.checked) {
      this.blFinishedD = true;
      this.blFinished = true;
    } else {
      this.blFinishedD = false;
      if (this.blFinishedU == false && this.blFinishedN == false) {
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }
    }
  }

  eventBLUnfinished(event) {
    if (event.target.checked) {
      this.blUnfinished = true;
      this.blUnfinishedN = true;
      this.blUnfinishedU = true;
      this.blUnfinishedD = true;
    } else {
      this.blUnfinished = false;
      this.blUnfinishedN = false;
      this.blUnfinishedU = false;
      this.blUnfinishedD = false;
      this.checklistUnfinishedDisabled();
    }
  }

  eventBLUnfinishedN(event) {
    if (event.target.checked) {
      this.blUnfinishedN = true;
      this.blUnfinished = true;
    } else {
      this.blUnfinishedN = false;
      console.log("EVENT CHECK N UNCHECK: " + this.blUnfinishedD);
      if (this.blUnfinishedD == false && this.blUnfinishedU == false) {
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }
    }
  }

  eventBLUnfinishedU(event) {
    if (event.target.checked) {
      this.blUnfinishedU = true;
      this.blUnfinished = true;
    } else {
      this.blUnfinishedU = false;
      if (this.blUnfinishedD == false && this.blUnfinishedN == false) {
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }
    }
  }

  eventBLUnfinishedD(event) {
    if (event.target.checked) {
      this.blUnfinishedD = true;
      this.blUnfinished = true;
    } else {
      this.blUnfinishedD = false;
      if (this.blUnfinishedU == false && this.blUnfinishedN == false) {
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }
    }
  }

  getSettingToolbar() {
    return {
      buttonsFront: [
        { name: "Transmit POD", event: "transmitToPOD", icon: "exchange" },
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Transmit to POT/POD",
    };
  }

}
