export class TerminalClosingTime {
  no = 0;
  closingId = "";
  vesselId = "";
  voyage = "";
  bound = "O";
  polCode = "";
  polTerminalCode = "";
  polTerminalClosingDate = "";
  polTerminalClosingTime = "";
  polVgmClosingDate = "";
  polVgmClosingTime = "";
  porCode = "";
  porTerminalCode = "";
  porTerminalClosingDate = "";
  porTerminalClosingTime = "";
  porVgmClosingDate = "";
  porVgmClosingTime = "";
  remarks = "";
  isValid = true;
  dateCreated = "";
  userCreated = "";
  dateModified = "";
  userModified = "";
  dateInvalid = "";
  userInvalid = "";
  officeCode = "";
  receiptType = "";
  porStuffTerminalCode = "";
  porStuffTerminalClosingDate = "";
  porStuffTerminalClosingTime = "";
  polStuffTerminalCode = "";
  polStuffTerminalClosingDate = "";
  polStuffTerminalClosingTime = "";
  polstuffVgmClosingTime;
  polstuffVgmClosingDate;
  porstuffVgmClosingTime;
  porstuffVgmClosingDate;
  updateMode = 0;
  oldValid = true;
  keyCode = "";
  stuffCode = "";
  stuffTerminalCode = "";
  stuffTerminalClosingDate = "";
  stuffTerminalClosingTime = "";
  stuffVgmClosingDate = "";
  stuffVgmClosingTime = "";

  // Misc
  weekName: string = "";
  year: string = "";

  terminalClosingTimeId = "";
  terminalClosingTimeCode = "";
  terminalClosingTimeName = "";
  terminalClosingTimePic = "";
  terminalClosingTimeTelp = "";
  terminalClosingTimeAddress = "";

  vesselHistory = "";
  voyageHistoryCode = "";
  boundHistory = "";

  constructor() {}
}
