<div class="ui raised segment">
  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-bOceanVesselCode'] == null || param['error-bOceanVesselCode'] == ''">
        {{param['error-bOceanVesselCode']}}
      </li>
      <li [class.hidden]="param['error-bOceanVesselVoyage'] == null || param['error-bOceanVesselVoyage'] == ''">
        {{param['error-bOceanVesselVoyage']}}
      </li>
      <li [class.hidden]="param['error-bOceanVesselBound'] == null || param['error-bOceanVesselBound'] == ''">
        {{param['error-bOceanVesselBound']}}
      </li>
      <li [class.hidden]="param['error-allocationValidator'] == null || param['error-allocationValidator'] == ''">
        {{param['error-allocationValidator']}}
      </li>
    </ul>
  </div>


  <form class="ui form">
    <button class="ui button" style="display:none;"></button>

    <div class="six wide fields">
      <div class="required two field" style="width:100%"  [class.error]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
        <label>Vessel</label>
        <combo-plugin #cbVessel [settings]="settingVessel"(change)="changeEventVessel($event);" style="width:100%;"></combo-plugin>
      </div>
      <div class="required one field" style="width:70%" [class.error]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
        <label>Voyage</label>
        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="required one field" style="width:45%" [class.error]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
        <label>Bound</label>
        <input type="text" name="bOceanVesselBound" style="text-transform: uppercase"  placeholder="Bound" [(ngModel)]="param['bOceanVesselBound']" (change)="changeBound($event)" >
      </div>
      <div class="two field" style="width:100%">
        <label>Port of Discharge</label>
        <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <label>Port of Transhipment 1</label>
        <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <label>Port of Load</label>
        <combo-plugin #cbPOL [settings]="settingPOL" (change)="changeEventPOL($event)" style="width:100%;"></combo-plugin>
      </div>

      <div class="two field" style="width:100%">
        <label>Final Destination</label>
        <combo-plugin #cbFDest [settings]="settingFDest" (change)="changeEventFDest($event)" style="width:100%;"></combo-plugin>
      </div>
    </div>

    <div class="six wide fields">
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isCOC == true " [(ngModel)]="isCOC" name="isCOC" (change)="changeContainerCOC($event)" tabindex="0">
          <label>COC</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isSOC == true "  [(ngModel)]="isSOC" name="isSOC" (change)="changeContainerSOC($event)" tabindex="0">
          <label>SOC</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [checked]="isSLOT == true " [(ngModel)]="isSLOT" name="isSLOT" (change)="changeContainerSLOT($event)" tabindex="0">
          <label>SLOT</label>
        </div>
      </div>
      <div class="field" style="width:48%;align-self:center;">
        <div class="ui test checkbox" >
          <input type="checkbox" [(ngModel)]="isNVOCC" name="tsBkg" (change)="changeContainerNVOCC($event)" tabindex="0">
          <label>NVOCC</label>
        </div>
      </div>
      <div class="two field" style="width:100%">
        <combo-plugin #cbLocalTranshipment [settings]="settingLocalTranshipment" (change)="changeEventLocalTranshipment($event)" style="width:100%;"></combo-plugin>
      </div>
      <div class="two field" style="width:100%">
        <combo-plugin #cbFullEmpty [settings]="settingFullEmpty" (change)="changeEventFullEmpty($event)" style="width:100%;"></combo-plugin>
      </div>

    </div>

    <div style="width:100%">
      <ag-grid-angular
        style="width: 100%; height: 400px;"
        #topGrid
        class="ag-theme-balham"
        [getRowStyle]="getRowStyle"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [enableSorting]="true"
        [enableFilter]="true"
        [enableColResize] = "true"
        (gridReady)="onGridReady($event)"
        (rowDoubleClicked)="rowDoubleClick($event)"
        [pinnedBottomRowData]="pinnedBottomRowData"
      >
      </ag-grid-angular>
      <!--
      <ag-grid-angular
        style="width: 100%; height: 50px;"
        #bottomGrid
        class="ag-theme-balham"
        [rowData]="rowData2"
        [columnDefs]="columnDefs"
        headerHeight="0"
        [rowStyle]="{ fontWeight: 'bold' }"
        (gridReady)="onGridReady2($event)"

      >
      </ag-grid-angular>
    -->


    </div>

    <div class="six wide fields">

      <div class="two field" style="width:100%">
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>

    </div>

    <div class="six wide fields">
      <div class="six field" style="width:100%">
        <fieldset>
          <legend style="font-weight:bold;color:dodgerblue;">All</legend>
          <table border ="0" width="100%">
            <tr> <!-- 1st line of record -->
              <td width="22%"><label style="float: left; font-weight: bold">Total Vsl. Teus</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_vsl_teus}}</label></td>
              <td width="22%"><label style="float: left; font-weight: bold">Laden</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_laden}}</label></td>
              <td width="22%"><label style="float: left; font-weight: bold">Empty</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_empty}}</label></td>
            </tr> <!-- end of 1st line of record -->

            <tr><td colspan="9">&nbsp;</td></tr>

            <tr> <!-- 2nd line of record -->
              <td width="22%"><label style="font-weight: bold;">SOC</label></td>
              <td width="1%">&nbsp;</td>
              <td width="10%">&nbsp;</td>
              <td width="22%"><label style="font-weight: bold;">COC</label></td>
              <td width="1%">&nbsp;</td>
              <td width="10%">&nbsp;</td>
              <td width="22%"><label style="font-weight: bold;">SLOT</label></td>
              <td width="1%">&nbsp;</td>
              <td width="10%">&nbsp;</td>
            </tr> <!-- end of 2nd line of record -->

            <tr> <!-- 3rd line of record -->
              <td width="22%"><label style="float: left;">Total SOC Allocated</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_allocated}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Allocated</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_allocated}}</label></td>
              <td width="22%"><label style="float: left;">Total SLOT Allocated</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_slot_allocated}}</label></td>
            </tr> <!-- end of 3rd line of record -->

            <tr> <!-- 4th line of record -->
              <td width="22%"><label style="float: left;">Total SOC TS</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_ts}}</label></td>
              <td width="22%"><label style="float: left;">Total COC TS</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_ts}}</label></td>
              <td width="22%"><label style="float: left;">Total SLOT Booked</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_slot_booked}}</label></td>
            </tr> <!-- end of 4th line of record -->

            <tr> <!-- 5th line of record -->
              <td width="22%"><label style="float: left;">Total SOC Local</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_local}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Local</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_local}}</label></td>
              <td width="22%"><label style="float: left;">Total SLOT Kill Slot</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_slot_killslot}}</label></td>
            </tr> <!-- end of 5th line of record -->

            <tr> <!-- 6th line of record -->
              <td width="22%"><label style="float: left;">Total SOC Laden Booked</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_laden_booked}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Laden Booked</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_laden_booked}}</label></td>
              <td width="22%"><label style="float: left;">Total SLOT Available</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_slot_available}}</label></td>
            </tr> <!-- end of 6th line of record -->

            <tr> <!-- 7th line of record -->
              <td width="22%"><label style="float: left;">Total SOC Empty Booked</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_empty_booked}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Empty Booked</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_empty_booked}}</label></td>
              <td width="22%">&nbsp;</td>
              <td width="1%">&nbsp;</td>
              <td width="10%">&nbsp;</td>
            </tr> <!-- end of 7th line of record -->

            <tr> <!-- 5.1 th (inset) line of record -->
              <td width="22%"><label style="float: left;">Total SOC Kill Slot</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_killslot}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Kill Slot</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_killslot}}</label></td>
              <td width="22%"><label style="float: left;">&nbsp;</label></td>
              <td width="1%"><label style="float: left;">&nbsp;</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">&nbsp;</label></td>
            </tr> <!-- end of 5.1 th (inset) line of record -->

            <tr> <!-- 8th line of record -->
              <td width="22%"><label style="float: left;">Total SOC Available</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_available}}</label></td>
              <td width="22%"><label style="float: left;">Total COC Available</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_available}}</label></td>
              <td width="22%">&nbsp;</td>
              <td width="1%">&nbsp;</td>
              <td width="10%">&nbsp;</td>
            </tr> <!-- end of 8th line of record -->

            <tr> <!-- 9th line of record -->
              <td colspan="9">&nbsp;</td>
            </tr>

            <tr>
              <td width="22%"><label style="float: left; font-weight: bold">Total SOC Deleted</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_soc_available}}</label></td>
              <td width="22%"><label style="float: left;  font-weight: bold">Total COC Deleted</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_available}}</label></td>
              <td width="22%"><label style="float: left;  font-weight: bold">Total SLOT Deleted</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="10%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_coc_available}}</label></td>
            </tr>

          </table>
        </fieldset>
      </div>
      <div class="one field" style="width:30%">
        <fieldset>
          <legend style="font-weight:bold;color:dodgerblue;">Reserve</legend>
          <table border="0" width="100%">
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve COC LD</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_laden_reserve_coc}}</label></td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve COC MT</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_empty_reserve_coc}}</label></td>
            </tr>
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve SOC LD</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_laden_reserve_soc}}</label></td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve SOC MT</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_empty_reserve_soc}}</label></td>
            </tr>
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve SLOT LD</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_laden_reserve_slot}}</label></td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve SLOT MT</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_empty_reserve_slot}}</label></td>
            </tr>
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">Reserve Total</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_reserve}}</label></td>
            </tr>
            <tr>
              <td colspan="3">&nbsp;</td>
            </tr>
          </table>

          <!--
          <div class="one field" style="width:100%">
              <label style="float: left;">Reserve Laden</label><label style="float: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_resv_laden}}</label>
          </div>
          <div class="one field" style="width:100%">
              <label style="float: left;">Reserve Empty</label><label style="float: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_resv_empty}}</label>
          </div>
          <div class="one field" style="width:100%">
              <label style="float: left;">Reserve Kill Slot</label><label style="float: left;">&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_resv_killslot}}</label>
          </div>
          <div class="one field" style="width:100%">
              <label style="float: left;">Reserve Total</label><label style="float: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{total_resv}}</label>
          </div>
          -->
        </fieldset>
        <fieldset>
          <legend style="font-weight:bold;color:dodgerblue;">UC</legend>
          <table border="0" width="100%">
            <tr>
              <td width="70%"><label style="float: left; font-weight: bold;">UC QTY</label></td>
              <td width="1%"><label style="float: left;">:</label></td>
              <td width="29%"><label style="float: left; font-weight: normal;color: cornflowerblue;">{{totalUC}}</label></td>
            </tr>
          </table>
        </fieldset>
      </div>
      <div class="one field" style="width:16%">
        <div class="one field" style="width:100%">
          <label style="float: left;">ETA</label><label style="float: left;">&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{eta}}</label>
        </div>
        <div class="one field" style="width:100%">
          <label style="float: left;">ETD</label><label style="float: left;">&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{etd}}</label>
        </div>
        <div class="one field" style="width:100%">
          <label style="float: left;">ATB</label><label style="float: left;">&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{ata}}</label>
        </div>
        <div class="one field" style="width:100%">
          <label style="float: left;">ATD</label><label style="float: left;">&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{atd}}</label>
        </div>

      </div>
    </div>

  </form>

</div>
