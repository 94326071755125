import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-experimental-register-page',
  templateUrl: './experimental-register-page.component.html',
  styleUrls: ['./experimental-register-page.component.css']
})
export class ExperimentalRegisterPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;


  /* Model */
  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Register Experimental'
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

}
