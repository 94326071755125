import { Component, OnInit, ViewChild } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { ParamTransmitBookingDataToPSAPage } from '../edi-transmit-booking-data-to-psa-page/edi-transmit-booking-data-to-psa-page.component';
import { specialCharUtil } from '../shared';
// tslint:disable-next-line:max-line-length
import { Authorize, ComboPluginComponent, ConfigService, CookieService, GenericService, GenericUtil, PdfPluginComponent, Validator } from 'sibego-ui-library';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-edi-generation-to-depot',
  templateUrl: './edi-generation-to-depot.component.html',
  styleUrls: ['./edi-generation-to-depot.component.css']
})
export class EdiGenerationToDepotComponent extends Validator implements OnInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('ediTransmissionList') ediTransmissionList: PdfPluginComponent;

  dialog: any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;

  radioContainerOwnerSOC = 'N';
  radioContainerOwnerCOC = 'N';

  /* Parameter settings */
  settingToolbar;
  settingPDF;
  formatName: String = '';
  // * HTML Encode
  sCharUtil = new specialCharUtil();

  // lock
  cButtonLock = true;
  dButtonLock = true;

  // model ='';
  model = new ParamTransmitBookingDataToPSAPage();

  validatorRules = {};

  isError: Boolean = false;

  settingVessel;
  settingVoyage;

  transmitViaSFTP = false;


  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: 'GENERATE', event: 'transmit', icon: 'exchange' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name: 'Close', event: 'close', icon: 'power' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'EDI GENERATION TO DEPOT',
    };
    this.settingVessel = {
      id: 'cbVessel',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxlength: 50,
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Vessel',
      title: 'vesselName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Vessel Code', field: 'vesselCode', width: 200 },
        { header: 'Vessel Name', field: 'vesselName', width: 350 },
        { header: 'Valid', field: 'isValid', width: 50 },
      ],
    };

    this.settingVoyage = {
      id: 'cbVoyage',
      type: 'search enter', // search | select | select input
      url: '',
      maxlength: 20,
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      isMark: true,
      columns: [
        { header: 'Voyage', field: 'voyage', width: 100 },
        { header: 'Bound', field: 'bound', width: 100 },
        { header: 'Service', field: 'serviceCode', width: 100 },
      ],
    };

    this.settingPDF = {
      id: 'ediTransmissionList',
      url: '',
      hidePrint: false,
    };

  }


  ngOnInit() { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {

    // set default container ownership to COC after the form is loaded
    this.radioContainerOwnerCOC = 'Y';
    this.model.containerOwnership = 'COC';
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'transmit':
        // this.setValidator()
        console.log(this.model.voyage);
        if (
          this.model.vesselId !== '' &&
          this.model.voyage !== '' &&
          this.model.bound !== '' &&
          this.model.containerOwnership !== ''

        ) {
          this.transmitProcess();

        } else {
          this.message(
            'information',
            'Information',
            'please insert vessel, voyage & container ownership',
            'okonly',
            { ok: '' }
          );
          this.loading = false;
        }
        break;
        case 'cancel':
          this.handleCancel();
          break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) { }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId === '' ||
      event.vesselId === undefined
    ) {
      this.model.vesselId = '';
    } else {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        '/MasterSailingSchedules/findByComboBoxControlVoyage/' +
        this.cookieService.getDefaultLocationCode() +
        '/' +
        event.vesselId +
        '/' +
        this.model.bound.toUpperCase() +
        '/voyage_like={query}'
      );
      this.cbVoyage.setValue('');
    }
  }

  changeEventVoyage(event) {
    if(event.voyage){
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = "";
    }
  }

  changeEventTransmit(event) {
    this.model.containerOwnership = event.target.value;
  }
  handleCancel(){
    // handle cancel event
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.model.vesselId = "";
    this.model.vesselCode = "";
    this.model.vesselName = "";
    this.model.voyage = "";
    this.model.bound ="O";
  }


  transmitProcess() {
    this.loading = true;
    const uri =
      this.configService.config.BASE_API +
      '/MasterBookings/sendCoparnToEngKongWithParam/SGSIN/' +
      this.model.vesselId +
      '/' +
      this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
      '/' +
      this.model.bound.toUpperCase() +
      '/' +
      this.model.containerOwnership;

    this.genericService.GET(uri).subscribe((resp) => {
      if (resp.ok) {
        if (resp.json()['status'] === 'ok') {
          var depotTransmittedDate = resp.json()['depotTransmittedDate']
          var failePath = resp.json()['fileLocation'].replace("\\\\", '').replace(/\\/g, '/').replace(" ",'_').replace("%20",'_');
          var urii = this.configService.config.BASE_API +'?q=/ediController/generateReport/depot/' +
            this.model.vesselCode +
            "/" +
            this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
            "/" +
            this.model.bound +
            "/" +
            this.model.containerOwnership +
            "/" +
            depotTransmittedDate +
            "/" +
            "?filePath=" + failePath;
          console.log("----",urii);

          var self = this;
          $.ajax({
            url: urii,
            method: "GET",
            xhrFields: {
              responseType: "blob",
            },
            beforeSend: function (xhr) {
              xhr.setRequestHeader("Token", localStorage.getItem("token"));
            },
            success: function (data) {
              // PDF
              var formatName = "TransmittedEdiToDepot.pdf";
              var postDatas = {};
              postDatas["fileName"] = formatName;
              postDatas["url"] = urii;
              self.genericService.GET(urii.replace("?q=","")).subscribe((resp) => {
                console.log("-----1,",resp._body);
                console.log("-----2,",resp);
                if (resp._body !=null) {
                  const pathDownload = self.configService.config.pdfUrl + '/download/edi/' + resp._body;
                  self.ediTransmissionList.setPDFURL(pathDownload);
                  self.ediTransmissionList.showPDF();
                  self.loading = false;
                }
              })
            },
          });
          // this.loading = false;
        } else {
          this.message(
            'information',
            'Information',
            'No data transmitted ',
            'okonly',
            { ok: '' }
          );
          this.loading = false;
        }

      } else {
        this.message(
          'information',
          'Information',
          'Error Occurs. Please contact your system administrator.',
          'okonly',
          { ok: '' }
        );
        this.loading = false;
      }
    });

  }

  changeEventBound(event) {}

  changeBound() {
    this.model.bound = (this.model.bound !== '') ? this.model.bound.toUpperCase() : '';
    this.cbVoyage.disableCombo = false;
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterSailingSchedules/findByComboBoxControlVoyage/' +
      this.cookieService.getDefaultLocationCode() +
      '/' +
      this.model.vesselId +
      '/' +
      this.model.bound.toUpperCase() +
      '/voyage_like={query}'
    );
    // this.cbVoyage.setValue("");
    // this.model.voyage = "";
  }

  noneSpace(event) { }
  eventPDFPlugin(event) { }

}
