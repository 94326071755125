<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

 <form class="ui form">
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <fieldset style="border: 1px solid lightgray">
      <legend style="font-weight:bold;color:dodgerblue;">Booking Unlock Selection</legend>
      <div class="field">
        <div class="two fields">

            <div class="field" style="width:26%">   
                <div class="field">     
                  <div class="ui radio checkbox" >
                    <input type="checkbox"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chVessel')" [checked]="isUnlock == 'chVessel'">
                    <label>By Vessel/Voyage</label>
                  </div>
                </div>
                  <br>
                  <div class="field" style="height:325px">
                    <fieldset style="border: 1px solid lightgray;height:325px">

                        <br>
                        <div class="field">                  
                         <br>
                          <div class="field">

                            <label>Vessel</label>
                            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event);model['error-vesselId']=''" style="width:100%;"></combo-plugin>
                            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-vesselId'] != null && model['error-vesselId'] != ''">
                              {{model['error-vesselId']}}
                            </div>
                          </div>
                          <br>
                          <div class="field" >
                            <label>Voyage</label>
                              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event);model['error-voyageCode']=''" style="width:100%;"></combo-plugin>
                              <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-voyageCode'] != null && model['error-voyageCode'] != ''">
                                {{model['error-voyageCode']}}
                              </div>
                          </div>
                          <br>
                          <div class="field">
                            <label>Bound</label>
                              <input #bound type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['varBound']" readonly [disabled]="lockBound == true" style="width:30%">
                                <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
                                  {{model['error-bound']}}
                                </div>
                          </div>
                          <br>
                      </div>
                    </fieldset>
                </div>
            </div>
 
          <div class="field" style="width:84%">
            <div class="field">
                <div class="field">
                  <div class="ui radio checkbox" >
                    <input type="checkbox"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chBooking')" [checked]="isUnlock == 'chBooking'">
                    <label>By Booking Reference</label>
                  </div>
                </div>
                <br>
                <div class="field">
                  <fieldset style="border: 1px solid lightgray;width:98%;">  
                      <button class="tiny ui positive button" [disabled]="cButtonLock" (click) = "addNewRow();" >+</button>
                      <button class="tiny ui negative button" [disabled]="dButtonLock" (click) = "removeRow();">-</button>	    
                      <input type="hidden" name="gridCount" value="0">   
                      <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                     
                  </fieldset>
                </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
 </form>
</form>
<footer-plugin [info]="info"></footer-plugin>
