import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  cllForm,
  cllGrid,
  cllContainer,
  MSailingSchedule,
  officeModel,
  uploadContainer,
  cllHeader,
  perMapping,
  transhipmentModel,
  specialCharUtil,
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  CalendarPluginComponent,
  ConfigService,
  CookieService,
  UploadPluginComponent,
  PdfPluginComponent,
  DatePluginComponent,
  DatetimePluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import FormValidation from "../../utils/formValidation";
declare var $: any;

@Component({
  selector: "app-transaction-cll-page",
  templateUrl: "./transaction-cll-page.component.html",
  styleUrls: ["./transaction-cll-page.component.css"],
})
export class TransactionCLLPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;
  frmDialogMessage: string = "";

  /** identification block */
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("pol") cbPOL: ComboPluginComponent;
  @ViewChild("pod") cbPOD: ComboPluginComponent;
  @ViewChild("fd") cbFD: ComboPluginComponent;
  @ViewChild("newCbVessel") newCbVessel: ComboPluginComponent;
  @ViewChild("newCbVoyage") newCbVoyage: ComboPluginComponent;
  @ViewChild("newPol") newCbPol: ComboPluginComponent;
  @ViewChild("newPod1") newCbPOD1: ComboPluginComponent;
  @ViewChild("newPod2") newCbPOD2: ComboPluginComponent;
  @ViewChild("newPod3") newCbPOD3: ComboPluginComponent;
  @ViewChild("newFd") newCbFD: ComboPluginComponent;
  @ViewChild("pot") newCbPOT: ComboPluginComponent;
  @ViewChild("newCBBooking") newCbBooking: ComboPluginComponent;
  @ViewChild("newShipperName") newCbShipper: ComboPluginComponent;
  @ViewChild("newCommodity") newCbCommodity: ComboPluginComponent;
  @ViewChild("newSpecialDetail") newCbSpecialDetail: ComboPluginComponent;
  @ViewChild("newPackageType") newCbPackageType: ComboPluginComponent;
  @ViewChild("newNextVessel") newNextCbVessel: ComboPluginComponent;
  @ViewChild("newNextVoyage") newNextCbVoyage: ComboPluginComponent;
  @ViewChild("cbVesselUpload") cbVesselUpload: ComboPluginComponent;
  @ViewChild("cbVoyageUpload") cbVoyageUpload: ComboPluginComponent;
  @ViewChild("vslTo") cbVesselTo: ComboPluginComponent;
  @ViewChild("voyTo") cbVoyageTo: ComboPluginComponent;

  @ViewChild("uploadPlg") uploadPlg: UploadPluginComponent;
  @ViewChild("uploadPlgCellLocation")
  uploadPlgCellLocation: UploadPluginComponent;
  @ViewChild("uploadPlgBaplie") uploadPlgBaplie: UploadPluginComponent;

  @ViewChild("etaCal") cllEta: CalendarPluginComponent;
  @ViewChild("etdCal") cllEtd: CalendarPluginComponent;
  @ViewChild("calSDT") cllSDT: CalendarPluginComponent;

  @ViewChild("cllGrid") gridCLL: GridPluginComponent;
  @ViewChild("sumGrid") gridSum: GridPluginComponent;
  @ViewChild("newGrid") gridNew: GridPluginComponent;
  @ViewChild("cllGridUpload") gridCLLUpload: GridPluginComponent;
  @ViewChild("cllGridBatch") gridCllBatch: GridPluginComponent;
  @ViewChild("cllGridBatchHeader") gridCllBatchHeader: GridPluginComponent;
  @ViewChild("cllGridCopy") gridCllCopy: GridPluginComponent;
  @ViewChild("cllGridCopyHeader") gridCllCopyHeader: GridPluginComponent;
  @ViewChild("cllGridTransfer") gridCllTransfer: GridPluginComponent;
  @ViewChild("cllGridUpdateCelLoc") gridCllUpdateCelLoc: GridPluginComponent;
  @ViewChild("cllGridAdditionalDG") gridCllAdditionalDG: GridPluginComponent;

  @ViewChild("remarks") remarkTa: ElementRef;
  @ViewChild("dgCmdDesc") dgCmdDesc: ElementRef;
  @ViewChild("refCmdDesc") refDmdDesc: ElementRef;

  @ViewChild("pdfContainerCLL") pdfContainer: PdfPluginComponent;
  //@ViewChild("dateReeferCollection") dateReeferCollection: DatetimePluginComponent;
  /** end of identification block */

  // * Form Validation
  formValidation = new FormValidation();

  // * Special Char
  sCharUtil = new specialCharUtil();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  bookingNo = "";
  modeToolbar = false;

  loading = false;
  loadingDialog = false;
  loadingUpload = false;
  loadingUpdateCellLocc = false;
  loadingCopy = false;
  loadingBatch = false;
  loadingUploadBaplie = false;
  addContainerLoading = false;

  /* Parameter settings */
  settingToolbar;
  settingToolbar2;
  settingVessel;
  settingVesselNew;
  settingVesselNext;
  settingVesselUpload;
  settingVoyage;
  settingVoyageNew;
  settingVoyageNext;
  settingVoyageUpload;
  settingCalendar;
  settingPOL;
  settingNewPOL;
  settingPOD;
  settingNewPOD1;
  settingNewPOD2;
  settingNewPOD3;
  settingFD;
  settingNewFD;
  settingGrid;
  settingSummaryGrid;
  settingNewGrid;
  settingSDT;
  settingPOT;
  settingBooking;
  settingShipper;
  settingCommodity;
  settingSpecialDetail;
  settingPackageType;
  settingUpload;
  settingUploadCellLocation;
  settingGridUpload;
  settingGridBatch;
  settingGridBatchHeader;
  settingGridCopy;
  settingGridCopyHeader;
  settingGridTransfer;
  settingVesselTo;
  settingVoyageTo;
  settingGridUpdateCelLoc;
  settingTest;
  settingGridAdditionalDG;
  settingUploadBaplie;
  settingPDF;
  settingReeferCollection;
  settingDateReturnToPor;


  newDialogOpt;
  addCntDialogOpt;
  additionalDGDialogOpt;

  //types
  calendarType = "calendar";
  prompterType: string = "information";
  prompterHeader: string = "Information";
  prompterContent: string = "";
  prompterButtons = [{ name: "ok", event: "" }];

  // lock
  vgmLock = true;
  lock: boolean = false;
  reeferlock: boolean = true;
  newDialogStatus: boolean = false;
  trigError: boolean = false;
  errorFormMessage: string = "";
  errorBatchMessage: string = "";
  errorCopyMessage: string = "";
  newDialogFirstInit = false;
  fileName: string = "";
  postData = {};
  sizeComboLoading = false;
  sizeComboDisabled = true;
  addContainerStatus: string = "new";
  bookingRefDisabled: boolean = true;
  copyButtonDisable: boolean = true;
  transferButtonDisable: boolean = true;
  sortField: string = "default";
  direction: string = "*";
  sortMode: boolean = false;
  containerPlusButtonDisabled: boolean = false;
  containerMinusButtonDisabled: boolean = true;
  batchSaveButtonDisabled: boolean = false;
  disableErrorButton: boolean = true;
  disableErrorButton1: boolean = true;
  disableErrorButton2: boolean = true;
  disableChkBoxAllContainer: boolean = true;
  selectAllContainers: boolean = false;
  uploadEventDiscrepancy: boolean = false;
  isAutoCreate: boolean = false;
  bkgRefMode: boolean = false;
  trfButtonDisabled: boolean = false;

  model: any;
  modelForm = new cllForm();
  modelGrid = new cllGrid();
  modelSailing = new MSailingSchedule();
  office = new officeModel();
  modelNew = new cllGrid();
  modelContainer = new cllContainer();
  modelReceiveCLL = new uploadContainer();
  modelTransfer = new cllGrid();
  defaultCllGrid = new cllGrid();
  defaultCopyGrid = new cllGrid();
  uplReplace: boolean = false;
  errorFileName: string = "";
  defaultContainerTypeSize: string = "";
  defaultContainerType: string = "";

  cllDegreeTempSO: String = "C";
  lockReeferTemp = true;
  lockReeferTempSO = true;
  lockVentNumber = true;
  lockNature = true;
  lockHumidity = true;
  lockReefer = true;
  lockReeferDry = true;
  lockBoth = false;
  cllDegreeSO: Number;
  cllVentNumber: Number;
  cllVentOption: String = "O";
  cllDegree: Number;
  cllDegreeTemp: String = "C";
  cllNatureTemp: String = "N/A";
  cllSetOption: String = "";
 // reeferCollectionDate: String="";
  cllVent: String = "";


  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;
  enabl: boolean;

  /** ag Grid Patch */
  nGridStore: any[];
  nGridColumn: any;
  nGridApi;
  nGridColumnApi;
  nGridSelection;
  processDataFromClipboard;
  selectedRows: any[];
  /** en of ag Grid patch */

  validatorRules = {};
  batchColumn = [];
  copyData = [];
  batchData = [];
  cllFlagStatusContainerList = [];
  defaultDgClassList = [];

  /** Form Validation */
  isError: boolean = false;

  containerType = [];
  containerSize = [
    { sizeCode: "20", sizeName: "20" },
    { sizeCode: "40", sizeName: "40" },
    { sizeCode: "45", sizeName: "45" },
  ];
  containerHeight = [
    { heightCode: "80", heightName: "80" },
    { heightCode: "86", heightName: "86" },
    { heightCode: "96", heightName: "96" },
  ];
  containerTypeSize = [
    { typeCode: "D20", typeName: "D20" },
    { typeCode: "D40", typeName: "D40" },
    { typeCode: "D45", typeName: "D45" },
    { typeCode: "FR20", typeName: "FR20" },
    { typeCode: "FR40", typeName: "FR40" },
    { typeCode: "FRH40", typeName: "FRH40" },
    { typeCode: "HC20", typeName: "HC20" },
    { typeCode: "HC40", typeName: "HC40" },
    { typeCode: "HC45", typeName: "HC45" },
    { typeCode: "OT20", typeName: "OT20" },
    { typeCode: "OT40", typeName: "OT40" },
    { typeCode: "OTH45", typeName: "OTH45" },
    { typeCode: "R20", typeName: "R20" },
    { typeCode: "R40", typeName: "R40" },
    { typeCode: "RH40", typeName: "RH40" },
    { typeCode: "TK20", typeName: "TK20" },
    { typeCode: "TK40", typeName: "TK40" },
    { typeCode: "TK45", typeName: "TK45" },
  ];

  perMapping = {
    M20DRY86: "D20",
    M40DRY86: "D40",
    M45DRY86: "D45",
    M20FLR86: "FR20",
    M40FLR86: "FR40",
    M45FLR86: "FR45",
    M20FLR96: "FRH20",
    M40FLR96: "FRH40",
    M45FLR96: "FRH45",
    M20DRY96: "HC20",
    M40DRY96: "HC40",
    M45DRY96: "HC45",
    M20OPT86: "OT20",
    M40OPT86: "OT40",
    M45OPT86: "OT45",
    M20OPT96: "OTH20",
    M40OPT96: "OTH40",
    M45OPT96: "OTH45",
    M20PLT86: "PL20S",
    M40PLT86: "PL40",
    M45PLT86: "PL45",
    M20PLT96: "PLH20",
    M40PLT96: "PLH40",
    M45PLT96: "PLH45",
    M20REF86: "R20",
    M40REF86: "R40",
    M45REF86: "R45",
    M20REF96: "RH20",
    M40REF96: "RH40",
    M45REF96: "RH45",
    M20TNK86: "TK20",
    M40TNK86: "TK40",
    M45TNK86: "TK45",
  };

  addContainerCount: number = 0;

  hitUrl: string = this.configService.config.BASE_API.toString();

  characterMap = {
    A: 10,
    B: 12,
    C: 13,
    D: 14,
    E: 15,
    F: 16,
    G: 17,
    H: 18,
    I: 19,
    J: 20,
    K: 21,
    L: 23,
    M: 24,
    N: 25,
    O: 26,
    P: 27,
    Q: 28,
    R: 29,
    S: 30,
    T: 31,
    U: 32,
    V: 34,
    W: 35,
    X: 36,
    Y: 37,
    Z: 38,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    0: 0,
  };

  weightingFactors = {
    0: 1,
    1: 2,
    2: 4,
    3: 8,
    4: 16,
    5: 32,
    6: 64,
    7: 128,
    8: 256,
    9: 512,
  };

  totalD20: number = 0;
  totalD40: number = 0;
  totalD45: number = 0;
  totalHC20: number = 0;
  totalHC40: number = 0;
  totalHC45: number = 0;
  totalR20: number = 0;
  totalR40: number = 0;
  totalR45: number = 0;
  totalRH20: number = 0;
  totalRH40: number = 0;
  totalRH45: number = 0;
  totalFull: number = 0;
  totalEmpty: number = 0;
  totalDG: number = 0;
  totalRF: number = 0;
  totalOOG: number = 0;
  totalTank: number = 0;
  totalTK20: number = 0;
  totalTK40: number = 0;
  totalTK45: number = 0;
  totalTKH20: number = 0;
  totalTKH40: number = 0;
  totalTKH45: number = 0;
  totalOT20: number = 0;
  totalOT40: number = 0;
  totalOT45: number = 0;
  totalFR20: number = 0;
  totalFR40: number = 0;
  totalFR45: number = 0;
  totalPL20: number = 0;
  totalPL40: number = 0;
  totalPL45: number = 0;
  totalOTH20: number = 0;
  totalOTH40: number = 0;
  totalOTH45: number = 0;
  totalFRH20: number = 0;
  totalFRH40: number = 0;
  totalFRH45: number = 0;
  totalPLH20: number = 0;
  totalPLH40: number = 0;
  totalPLH45: number = 0;
  totalFinal: number = 0;

  defaultColumn = [
    {
      header: "OP",
      field: "operatorCode",
      width: 100,
      editType: "text",
      status: 1,
      table: "operation_cll_header",
      dbfield: "cll_op_code",
    },
    {
      header: "SO",
      field: "inSlot",
      width: 100,
      editType: "text",
      status: 1,
      table: "operation_cll_header",
      dbfield: "cll_so_code",
    },
    {
      header: "Cntr Size/Type",
      field: "typeSize",
      width: 100,
      editType: "select",
      ds: [
        { code: "D20", name: "D20" },
        { code: "D40", name: "D40" },
        { code: "D45", name: "D45" },
        { code: "HC20", name: "HC20" },
        { code: "HC40", name: "HC40" },
        { code: "HC45", name: "HC45" },
        { code: "FR20", name: "FR20" },
        { code: "FR40", name: "FR40" },
        { code: "FR45", name: "FR45" },
        { code: "FRH20", name: "FRH20" },
        { code: "FRH40", name: "FRH40" },
        { code: "FRH45", name: "FRH45" },
        { code: "OT20", name: "OT20" },
        { code: "OT40", name: "OT40" },
        { code: "OT45", name: "OT45" },
        { code: "OTH20", name: "OTH20" },
        { code: "OTH40", name: "OTH40" },
        { code: "OTH45", name: "OTH45" },
        { code: "R20", name: "R20" },
        { code: "R40", name: "R40" },
        { code: "R45", name: "R45" },
        { code: "RH20", name: "RH20" },
        { code: "RH40", name: "RH40" },
        { code: "RH45", name: "RH45" },
        { code: "TK20", name: "TK20" },
        { code: "TK40", name: "TK40" },
        { code: "TK45", name: "TK45" },
        { code: "PL40", name: "PL40" },
        { code: "PL45", name: "PL45" },
        { code: "PLH20", name: "PLH20" },
        { code: "PLH40", name: "PLH40" },
        { code: "PLH45", name: "PLH45" },
        { code: "PL20", name: "PL20" },
      ],
      status: 1,
      table: "operation_cll_details",
      dbfield: "cll_container_type-cll_container_size-cll_container_height",
    },
    {
      header: "Seal No 1",
      field: "sealNo1",
      width: 100,
      editType: "text",
      status: 1,
      table: "operation_cll_details",
      dbfield: "cll_container_seal_no_1",
    },
    {
      header: "Cell Loc.",
      field: "cellLocation",
      width: 100,
      editType: "text",
      status: 1,
      table: "operation_cll_details",
      dbfield: "cll_container_cell_location",
    },
    {
      header: "VGM WT. (KGS)",
      field: "weight",
      width: 100,
      editType: "text",
      status: 1,
      table: "operation_cll_details",
      dbfield: "cll_container_weight",
    },
    {
      header: "Status (F/E)",
      field: "status",
      width: 100,
      editType: "select",
      ds: [
        { code: "F", name: "F" },
        { code: "E", name: "E" },
      ],
      titleSelect: "",
      codeSelect: "",
      status: 1,
      table: "operation_cll_header",
      dbfield: "cll_is_empty",
    },
    {
      header: "T/S (Y/N)",
      field: "tranship",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "operation_cll_header",
      dbfield: "cll_is_tranship",
    },
    {
      header: "POL",
      field: "loadPortName",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "POT",
      field: "transhipPortName",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "POD1",
      field: "discPort1Name",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "POD2",
      field: "discPort2Name",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "POD3",
      field: "discPort3Name",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "Final Dest",
      field: "finalDestName",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      titleCombo: "locationName",
      codeCombo: "locationCode",
      maxChars: 0,
      columnsCombo: [
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Location Name", field: "locationName", width: 200 },
      ],
    },
    {
      header: "Connecting Vsl",
      field: "nextVesselName",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Connecting Voy",
      field: "nextVoyage",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Thru Shpt (Y/N)",
      field: "thruSpt",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Shipper",
      field: "shipperName",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlShipper/customerName={query}",
      titleCombo: "customerName",
      codeCombo: "customerId",
      maxChars: 0,
      columnsCombo: [
        { header: "Shipper Code", field: "customerCode", width: 50 },
        { header: "Shipper Name", field: "customerName", width: 200 },
      ],
    },
    {
      header: "Commodity",
      field: "commodityName",
      width: 200,
      editType: "text",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findGeneral/Y/commodityName/{query}/1/5/*/*",
      titleCombo: "commodityName",
      codeCombo: "commodityId",
      maxChars: 0,
      columnsCombo: [
        { header: "Commodity Code", field: "commodityCode", width: 50 },
        { header: "Commodity Name", field: "commodityName", width: 200 },
      ],
    },
    {
      header: "DG (Y/N)",
      field: "dg_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "DG Class",
      field: "dgClass",
      width: 100,
      editType: "select",
      ds: [
        { code: "N", name: "N/A" },
        { code: "C1", name: "C1" },
        { code: "C2F", name: "C2F" },
        { code: "C2", name: "C2" },
        { code: "C3", name: "C3" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "IMO",
      field: "imo",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UN NO.",
      field: "unno",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Flash PT.",
      field: "flashPoint",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "C/F",
      field: "flashScale",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "C", name: "C" },
        { code: "F", name: "F" },
      ],
    },
    {
      header: "DG PKG Type",
      field: "pkgTypeCode",
      width: 350,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneral/Y/packageTypeName/{query}/1/5/*/*",
      titleCombo: "packageTypeName",
      codeCombo: "packageTypeId",
      maxChars: 0,
      columnsCombo: [
        { header: "Package Type Code", field: "packageTypeCode", width: 50 },
        { header: "Package Type Name", field: "PackageName", width: 200 },
      ],
    },
    {
      header: "RF (Y/N)",
      field: "reefer_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Temp.",
      field: "reeferTemp",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "C/F",
      field: "tempScale",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "C", name: "C" },
        { code: "F", name: "F" },
      ],
    },
    {
      header: "RF as DRY",
      field: "reeferAsDry_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OOG (Y/N)",
      field: "oog_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OH",
      field: "oh",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OWL",
      field: "owl",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OWR",
      field: "owr",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OLF",
      field: "olf",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OLB",
      field: "olb",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "OOG Scale",
      field: "oogScale",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Bundle (Y/N)",
      field: "bundle_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Special Details",
      field: "spDetails",
      width: 200,
      editType: "combo",
      status: 1,
      maxCharsCombo: 0,
      urlCombo:
        this.configService.config.BASE_API.toString() +
        "/MasterSpecialDetails/findGeneral/Y/specialDetailName/{query}/1/5/*/*",
      titleCombo: "specialDetailName",
      codeCombo: "specialDetailId",
      maxChars: 0,
      columnsCombo: [
        {
          header: "Special Detail Code",
          field: "specialDetailCode",
          width: 50,
        },
        {
          header: "Special Detail Name",
          field: "specialDetailName",
          width: 200,
        },
      ],
    },
    {
      header: "Seal No 2",
      field: "sealNo2",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Seal No 3",
      field: "sealNo3",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UC (Y/N)",
      field: "uc_display",
      width: 100,
      editType: "select",
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UC/H",
      field: "uch",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UC/H Scale.",
      field: "uchScale",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "CM", name: "CM" },
        { code: "M", name: "M" },
        { code: "I", name: "I" },
        { code: "F", name: "F" },
      ],
    },
    {
      header: "UC/L",
      field: "ucl",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UC/L Scale.",
      field: "uclScale",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "CM", name: "CM" },
        { code: "M", name: "M" },
        { code: "I", name: "I" },
        { code: "F", name: "F" },
      ],
    },
    {
      header: "UC/W",
      field: "ucw",
      width: 100,
      editType: "number",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "UC/W Scale.",
      field: "ucwScale",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "CM", name: "CM" },
        { code: "M", name: "M" },
        { code: "I", name: "I" },
        { code: "F", name: "F" },
      ],
    },
    {
      header: "Stack Date/Time",
      field: "stackDateTime",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Indicator",
      field: "indicator",
      width: 100,
      editType: "select",
      status: 1,
      ds: [
        { code: "Y", name: "Y" },
        { code: "N", name: "N" },
      ],
    },
    {
      header: "Method",
      field: "method",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Pax",
      field: "pax",
      width: 100,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
    {
      header: "Remarks",
      field: "remarks",
      width: 300,
      editType: "text",
      status: 1,
      table: "",
      dbfield: "",
    },
  ];

  okCancel: string = "";
  userID: string = "";
  globalGridCllNumbers: number = 0;
  globarlGridProcessId: string = "";

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cook: CookieService,
    private cdr: ChangeDetectorRef,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "New", event: "new", icon: "plus" },
        { name: "Receive From Excel", event: "upload", icon: "upload" },
        {
          name: "Receive Cell Location",
          event: "uploadCellLoc",
          icon: "upload",
        },
        {
          name: "Receive From Baplie",
          event: "baplie",
          icon: "download",
          enabled: true,
        },
        { name: "Delete", event: "delete", icon: "trash" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsFront2: [
        {
          name: "Batch",
          event: "batch",
          icon: "file archive",
          enabled: this.setsEnable(),
        },
        {
          name: "Copy",
          event: "copy",
          icon: "copy",
          enabled: this.setsEnable(),
        },
        {
          name: "Update Cell Location",
          event: "updateCellLoc",
          icon: "edit",
          enabled: this.setsEnable(),
        },
        {
          name: "Transfer",
          event: "transfer",
          icon: "exchange alternate",
          enabled: this.setsEnable(),
        },
        {
          name: "Transmit CLL",
          event: "transmit",
          icon: "download",
          enabled: this.setsEnable(),
        },
      ],
      buttonsDetail: [
        /*{name: 'Batch', event: 'batch', icon: 'file archive'},
        {name: 'Copy', event: 'copy', icon: 'copy'},
        {name: 'Update Cell Location', event: 'updateCellLoc', icon: 'edit'},
        {name: 'Transfer', event: 'transfer', icon: 'exchange alternate'},
        {name: 'Back', event: 'back', icon: 'arrow left'},*/
        /*{name: 'Cancel', event: 'cancel2', icon: 'remove'},
        {name: 'Batch', event: 'cancel2', icon: 'remove'},
        {name: 'Copy', event: 'cancel2', icon: 'remove'},
        {name: 'Update Cell Location', event: 'cancel2', icon: 'remove'},
        {name: 'Transfer', event: 'cancel2', icon: 'remove'},
        */
      ],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Container Load List",
    };

    this.settingToolbar2 = {
      buttonsFront: [
        { name: "Batch", event: "retrieve", icon: "search" },
        { name: "Copy", event: "new", icon: "plus" },
        {
          name: "Update Cell Location",
          event: "uploadCellLoc",
          icon: "upload",
        },
        { name: "Transfer", event: "cancel", icon: "remove" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "none",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 250 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };
    this.settingCalendar = {
      modeCal: true,
      type: "datetime",
      returnText: "day/month/year",
      returnTextTime: "hour:minute",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
      showResetButton: true,
      formatInput: false,
      popupOptions: "bottom right",
    };
    this.settingPOL = {
      id: "cbPOL",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu2:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByLocationNameOrLocationCode/{query}/1/5",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of loading",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingPOD = {
      id: "cbPOD",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of discharge",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingTest = {
      id: "cbTest",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of discharge",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingFD = {
      id: "cbFD",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlnu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "final destination",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingGrid = {
      id: "cllGrid",
      url:
        this.configService.config.BASE_API.toString() +
        "/ContainerLoadList/findCllFlagStatus",
      page: 10,
      columns: [
        { header: "No.", field: "seq", width: 50 },
        { header: "BKG Ref.", field: "bNo", width: 200 },
        { header: "BL Ref.", field: "blNo", width: 200 },
        { header: "SO", field: "inSlot", width: 50 },
        { header: "OP", field: "operatorCode", width: 50 },
        { header: "Cntr No.", field: "containerNo", width: 100 },
        { header: "Cntr Size/Type", field: "typeSize", width: 150 },
        { header: "Seal No. 1", field: "sealNo1", width: 150 },
        { header: "Cell Loc.", field: "cellLocation", width: 150 },
        { header: "VGM WT. (KGS)", field: "weight", width: 150 },
        { header: "Status (F/E)", field: "status", width: 150 },
        { header: "T/S (Y/N)", field: "tranship", width: 100 },
        { header: "POL", field: "loadPortName", width: 180 },
        { header: "POT", field: "transhipPortName", width: 180 },
        { header: "POD 1", field: "discPort1Name", width: 180 },
        { header: "POD 2", field: "discPort2Name", width: 180 },
        { header: "POD 3", field: "discPort3Name", width: 180 },
        { header: "Final Dest", field: "finalDestName", width: 180 },
        { header: "Connecting Vsl", field: "nextVesselName", width: 200 },
        { header: "Connecting Voy", field: "nextVoyage", width: 180 },
        { header: "Thru Shpt (Y/N)", field: "thruSpt", width: 150 },
        { header: "Shipper", field: "shipperName", width: 200 },
        { header: "Commodity", field: "commodityName", width: 190 },
        { header: "DG (Y/N)", field: "dg_display", width: 100 },
        { header: "DG Class", field: "dgClass", width: 100 },
        { header: "IMO", field: "imo", width: 100 },
        { header: "UN NO.", field: "unno", width: 100 },
        { header: "Flash Pt.", field: "flashPoint", width: 100 },
        { header: "C/F", field: "flashScale", width: 100 },
        { header: "DG PKG Type", field: "pkgTypeCode", width: 150 },
        { header: "RF (Y/N)", field: "reefer_display", width: 100 },
        { header: "Temp.", field: "reeferTemp_display", width: 100 },
        { header: "C/F", field: "tempScale", width: 100 },
        { header: "RF as DRY", field: "reeferAsDry_display", width: 150 },
        { header: "OOG (Y/N)", field: "oog_display", width: 150 },
        { header: "OH", field: "oh_display", width: 100 },
        { header: "OWL", field: "owl_display", width: 100 },
        { header: "OWR", field: "owr_display", width: 100 },
        { header: "OLF", field: "olf_display", width: 100 },
        { header: "OLB", field: "olb_display", width: 100 },
        { header: "OOG Scale", field: "oogScale", width: 150 },
        { header: "Bundle (Y/N)", field: "bundle_display", width: 170 },
        { header: "Special Details", field: "spDetails", width: 200 },
        { header: "Seal No. 2", field: "sealNo2", width: 140 },
        { header: "Seal No. 3", field: "sealNo3", width: 140 },
        { header: "UC (Y/N)", field: "uc_display", width: 100 },
        { header: "UC/H", field: "uch", width: 100 },
        { header: "S.", field: "uchScale", width: 100 },
        { header: "UC/L", field: "ucl", width: 100 },
        { header: "S.", field: "uclScale", width: 100 },
        { header: "UC/W", field: "ucw", width: 100 },
        { header: "S.", field: "ucwScale", width: 100 },
        { header: "Stack Date/Time", field: "stackDateTime", width: 160 },
        { header: "Indicator", field: "indicator", width: 140 },
        { header: "Method", field: "method", width: 100 },
        { header: "Pax", field: "pax", width: 150 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      auto: false,
      sortingColumns: "default",
      sortingDirection: "ASC",
    };
    this.settingSummaryGrid = {
      id: "sumGrid",
      url: "",
      page: 10,
      columns: [
        { header: "T/S", field: "cntrTS", width: 50 },
        { header: "Total", field: "sumTotal", width: 50 },
      ],
      buttons: [],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };
    this.settingNewGrid = {
      id: "newGrid",
      modalId: "newDialog",
      url: "",
      page: 10,
      columns: [
        { header: "Container No.", field: "containerNo", width: 150 },
        { header: "Type", field: "containerTypeName", width: 100 },
        { header: "Size", field: "typeSize", width: 100 },
        { header: "VGM Wt. (KGS)", field: "containerWeight", width: 100 },
        { header: "Indicator", field: "display_indicator", width: 100 },
        { header: "Method", field: "containerVgmMethod", width: 100 },
        { header: "Seal No. 1", field: "containerSealNo1", width: 100 },
        { header: "Seal No. 2", field: "containerSealNo2", width: 100 },
        { header: "Seal No. 3", field: "containerSealNo3", width: 100 },
        { header: "Pax", field: "pax", width: 100 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      editable: false,
      height: 90,
      minHeight: 130,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };
    this.settingVesselNew = {
      id: "newCbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      url1:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      url2:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselCode/{query}/1/100/*/*",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingVesselNext = {
      id: "newNextCbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      align: "right",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingVoyageNew = {
      id: "newCbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };
    this.settingVoyageNext = {
      id: "newNextCbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      align: "right",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };
    this.settingPOT = {
      id: "cbPOT",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      urlbu2:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of transhipment",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingBooking = {
      id: "cbBooking",
      type: "search enter",
      url: "", //this.configService.config.BASE_API.toString() + '/MasterBookings/CLLFindBookingNo/{query}',
      maxChars: 2,
      template: "grid",
      placeholder: "booking no",
      code: "bNo2",
      title: "bNo2",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "prefix", field: "prefix", width: 50 },
        { header: "bNo", field: "bNo", width: 300 },
        { header: "Vessel", field: "vesselCode", width: 50 },
        { header: "Voyage", field: "vesselVoyage", width: 50 },
        { header: "Bound", field: "vesselBound", width: 50 },
      ],
    };
    this.settingShipper = {
      id: "cbShipper",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlShipper/customerName={query}",
      maxChars: 0,
      template: "grid",
      placeholder: "Search Shipper",
      code: "customerCode",
      title: "customerName",
      description: "",
      flag: "",
      columns: [
        { header: "Code", field: "customerCode", width: 50 },
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCommodity = {
      id: "newCommodity",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findGeneral/Y/commodityName/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Commodity",
      title: "commodityName",
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Code", field: "commodityCode", width: 100 },
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingSpecialDetail = {
      id: "newSpecialDetail",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSpecialDetails/findGeneral/Y/specialDetailName/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Special Detail",
      title: "specialDetailName",
      align: "right",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Special Detail Code",
          field: "specialDetailCode",
          width: 100,
        },
        {
          header: "Special Detail Name",
          field: "specialDetailName",
          width: 300,
        },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPackageType = {
      id: "newPackageType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneral/Y/packageTypeName/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Package Type",
      title: "packageTypeName",
      align: "right",
      description: "",
      isMark: true,
      columns: [
        { header: "Package Type Code", field: "packageTypeCode", width: 100 },
        { header: "Package Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingNewPOL = {
      id: "newCbPOL",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByLocationNameOrLocationCode/{query}/1/5",
      maxChars: 3,
      template: "grid",
      placeholder: "port of loading",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingNewPOD1 = {
      id: "newCbPOD1",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of discharge",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingNewPOD2 = {
      id: "newCbPOD2",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of discharge",
      code: "locationCode",
      title: "locationName",
      align: "right",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingNewPOD3 = {
      id: "newCbPOD3",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "port of discharge",
      code: "locationCode",
      title: "locationName",
      align: "right",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingNewFD = {
      id: "newCbFD",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*",
      urlbu:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "final destination",
      code: "locationCode",
      title: "locationName",
      description: "",
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 50 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingUpload = {
      id: "upload",
      uploadDir: "/CLL",
      maxFiles: 1,
      filterFiles: "xls",
      showUploadButton: true,
      buttonLabel: "Append",
      customButtons: [{ event: "replace", icon: "paste", label: "Replace" }],
    };

    this.settingUploadCellLocation = {
      id: "uploadCellLocations",
      uploadDir: "/CLL",
      maxFiles: 1,
      filterFiles: "xls",
      showUploadButton: true,
    };

    this.settingUploadBaplie = {
      id: "uploadBaplie",
      uploadDir: "/CLL",
      maxFiles: 1,
      filterFiles: "edi",
      showUploadButton: true,
    };

    this.settingVesselUpload = {
      id: "cbVesselUpload",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      url1:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      url2:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselCode/{query}/1/100/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageUpload = {
      id: "cbVoyageUpload",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGridUpload = {
      id: "cllGridUpload",
      url: "",
      page: 10,
      columns: [
        { header: "SO", field: "inSlot", width: 50 },
        { header: "OP", field: "operatorCode", width: 50 },
        { header: "F/E", field: "status", width: 100 },
        { header: "T/S (Y/N)", field: "tranship", width: 100 },
        { header: "POL", field: "loadPort", width: 100 },
        { header: "POT", field: "transhipPort", width: 100 },
        { header: "POD1", field: "discPort1", width: 100 },
        { header: "POD2", field: "discPort2", width: 100 },
        { header: "POD3", field: "discPort3", width: 100 },
        { header: "FDest", field: "finalDest", width: 100 },
        { header: "Connecting Vsl", field: "nextVesselName", width: 100 },
        { header: "Connecting Voy", field: "nextVoyage", width: 100 },
        { header: "Shipper Name", field: "shipperName", width: 100 },
        { header: "VGM Status", field: "vgmStatus_display", width: 100 },
        { header: "VGM Method", field: "string", width: 100 },
        { header: "PIC VGM Shipper", field: "vgmShipper", width: 100 },
        { header: "PIC VGM Terminal", field: "vgmTerminal", width: 100 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridBatch = {
      id: "cllGridBatch",
      url: "",
      page: 10,
      columns: [
        { header: "Container Number", field: "containerNo", width: 50 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridBatchHeader = {
      id: "cllGridBatchHeader",
      url: "",
      page: 10,
      columns: [],
      buttons: [],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      height: 90,
      sortingColumns: "no",
      sortingDirection: "ASC",
      minHeight: 100,
      editable: true,
      auto: false,
    };

    this.settingGridCopy = {
      id: "cllGridCopy",
      url: "",
      page: 10,
      columns: [
        {
          header: "Container Number",
          field: "containerNo",
          width: 100,
          editType: "text",
        },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingGridCopyHeader = {
      id: "cllGridCopyHeader",
      url: "",
      page: 10,
      columns: [],
      buttons: [],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      height: 90,
      sortingColumns: "no",
      sortingDirection: "ASC",
      minHeight: 100,
    };

    this.settingGridTransfer = {
      id: "cllGridTransfer",
      url: "",
      page: 10,
      columns: [
        { header: "Container Number", field: "containerNo", width: 50 },
        { header: "Status Msg", field: "statusMessage", width: 50 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "seq",
      sortingDirection: "ASC",
    };

    this.settingVesselTo = {
      id: "cbVesselTo",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      url1:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*",
      url2:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/vesselCode/{query}/1/100/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageTo = {
      id: "cbVoyageTo",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGridUpdateCelLoc = {
      id: "cllGridUpdateCelLoc",
      url: "",
      page: 10,
      columns: [
        { header: "Container Number", field: "containerNo", width: 50 },
        {
          header: "Cell Locations",
          field: "cellLocations",
          width: 300,
          editType: "text",
        },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "seq",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingGridAdditionalDG = {
      id: "cllGridAdditionalDG",
      url: "",
      page: 10,
      columns: [
        { header: "seq", field: "seq", width: 20 },
        { header: "IMO", field: "imo", width: 40 },
        { header: "UN No", field: "unno", width: 40 },
        { header: "Flash Pt.", field: "flashPoint", width: 40 },
        { header: "Flash Pt. Scale", field: "flashScale", width: 40 },
        { header: "Package Type", field: "pkgTYpeName", width: 40 },
        { header: "No of PKG", field: "noOfPkg", width: 40 },
        { header: "WT", field: "dgWt", width: 40 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingPDF = {
      id: "pdfContainerCLL",
      url: "",
      hidePrint: false,
      tabOptio: false,
    };

    /*this.settingReeferCollection = {
      id: "dateReeferCollection",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "-- Date --",
      customtext: "",
    };*/

    this.nGridColumn = [
      {
        headerName: "No.",
        field: "seq",
        width: 100,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      { headerName: "BKG Ref.", field: "bNo", width: 200 },
      { headerName: "BL Ref.", field: "blNo", width: 200 },
      { headerName: "SO", field: "inSlot", width: 100 },
      { headerName: "OP", field: "operatorCode", width: 100 },
      { headerName: "Cntr No.", field: "containerNo", width: 200 },
      { headerName: "Cntr Size/Type", field: "typeSize", width: 150 },
      { headerName: "Seal No. 1", field: "sealNo1", width: 150 },
      { headerName: "Cell Loc.", field: "cellLocation", width: 150 },
      { headerName: "VGM WT. (KGS)", field: "weight", width: 150 },
      { headerName: "Status (F/E)", field: "status", width: 150 },
      { headerName: "T/S (Y/N)", field: "tranship", width: 100 },
      { headerName: "POL", field: "loadPortName", width: 180 },
      { headerName: "POT", field: "transhipPortName", width: 180 },
      { headerName: "POD 1", field: "discPort1Name", width: 180 },
      { headerName: "POD 2", field: "discPort2Name", width: 180 },
      { headerName: "POD 3", field: "discPort3Name", width: 180 },
      { headerName: "Final Dest", field: "finalDestName", width: 180 },
      {
        headerName: "Connecting Vsl",
        field: "nextVesselName",
        width: 200,
        tooltipField: "nextVesselName"
      },
      {
        headerName: "Connecting Voy",
        field: "nextVoyage",
        width: 180,
        tooltipField: "nextVoyage"
      },
      { headerName: "Thru Shpt (Y/N)", field: "thruSpt", width: 150 },
      { headerName: "Shipper", field: "shipperName", width: 200 },
      { headerName: "Commodity", field: "commodityName", width: 190 },
      { headerName: "DG (Y/N)", field: "dg_display", width: 100 },
      { headerName: "DG Class", field: "dgClass", width: 100 },
      { headerName: "IMO", field: "imo", width: 100 },
      { headerName: "UN NO.", field: "unno", width: 100 },
      { headerName: "Flash Pt.", field: "flashPoint", width: 100 },
      { headerName: "C/F", field: "flashScale", width: 100 },
      { headerName: "DG PKG Type", field: "pkgTypeCode", width: 150 },
      { headerName: "RF (Y/N)", field: "reefer_display", width: 100 },
      { headerName: "Temp.", field: "reeferTemp_display", width: 100 },
      { headerName: "C/F", field: "tempScale", width: 100 },
      { headerName: "RF as DRY", field: "reeferAsDry_display", width: 150 },
      { headerName: "OOG (Y/N)", field: "oog_display", width: 150 },
      { headerName: "OH", field: "oh_display", width: 100 },
      { headerName: "OWL", field: "owl_display", width: 100 },
      { headerName: "OWR", field: "owr_display", width: 100 },
      { headerName: "OLF", field: "olf_display", width: 100 },
      { headerName: "OLB", field: "olb_display", width: 100 },
      { headerName: "OOG Scale", field: "oogScale", width: 150 },
      { headerName: "Bundle (Y/N)", field: "bundle_display", width: 170 },
      { headerName: "Special Details", field: "spDetails", width: 200 },
      { headerName: "Seal No. 2", field: "sealNo2", width: 140 },
      { headerName: "Seal No. 3", field: "sealNo3", width: 140 },
      { headerName: "UC (Y/N)", field: "uc_display", width: 100 },
      { headerName: "UC/H", field: "uch", width: 100 },
      { headerName: "S.", field: "uchScale", width: 100 },
      { headerName: "UC/L", field: "ucl", width: 100 },
      { headerName: "S.", field: "uclScale", width: 100 },
      { headerName: "UC/W", field: "ucw", width: 100 },
      { headerName: "S.", field: "ucwScale", width: 100 },
      { headerName: "Stack Date/Time", field: "stackDateTime", width: 160 },
      { headerName: "Indicator", field: "indicator", width: 140 },
      { headerName: "Method", field: "method", width: 100 },
      { headerName: "Pax", field: "pax", width: 150 },
    ];
    this.nGridSelection = "multiple";

    this.router.events.pairwise().subscribe((e) => {
      //// console.log("preet");
      //// console.log(e);
      if (this.newDialogStatus) {
        this.closeNewContainerLoadList("newDialog");
      }
    });
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.firstInit();
    this.cdr.detectChanges();
    $("#cllBound").on("click", function () {
      $(this).val("");
    });
    $("#cllBound").on("mouseleave", function () {
      if ($(this).val() == "") {
        $(this).val("O");
      }
    });
  }

  firstInit() {
    // console.log("office : "+this.cook.getCookie("defaultLocation"));
    this.office.officeCode = this.cook.getDefaultLocationCode(); //localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook
      .getCookie("defaultLocation")
      .split("|")[0];
    this.userID = this.cook.getName();
    this.viewOnlyFunction();
    this.defaultDgClassList.push("N/A");
    this.defaultDgClassList.push("C1");
    this.defaultDgClassList.push("C2");
    this.defaultDgClassList.push("C2F");
    this.defaultDgClassList.push("C3");

    $("#dgClass").autocomplete({
      source: this.defaultDgClassList,
    });

    this.styleInit();

    $(".ui.radio.checkbox").checkbox();
    /*$(".new-d").dialog({
      autoOpen: false,
    });*/

    //this.newCbBooking.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/CLLFindBookingNo/'+this.office.officeCode+'/{query}');

    var self = this;
    $("#myfile").change(function (e) {
      // console.log("file name : "+$(this).val());
      var fileName = $(this).val();
      if (fileName.split(".").pop().toLowerCase() == "xls") {
        self.modelReceiveCLL.fileName = $(this).val();
      } else {
        self.message(
          "information",
          "Information",
          "Please choose excel file!",
          "okonly",
          { ok: "" }
        );
      }
    });

    this.settingNewPOD1 = this.settingPOD;
    this.settingNewPOD1.id = "newCBPOD1";

    var ctrUri = this.hitUrl + "/MasterContainerTypes/getContainerType/tags/";
    this.genericService.GET(ctrUri).subscribe((resp) => {
      if (resp.ok) {
        var data: any = resp.json()["results"];
        data.forEach((e) => {
          this.containerType.push({
            typeCode: e["value"],
            typeName: e["name"],
          });
        });

        // console.log("container Type : ");
        // console.log(this.containerType);
      }
    });

    this.defaultCllGrid.newCllGrid();

    this.newDialogOpt = {
      title: "New Container",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      width: 900,
      height: 600,
      open: function (event, ui) {
        //hide close button.
        //// console.log($(this).css("z-index","102"));
        $(".ui-dialog").css("z-index", 103);
        $(".ui-widget-overlay").css("z-index", 102);
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
        $("body").first().css("overflow", "hidden");
      },
      close: function (event, ui) {
        $("body").first().css("overflow", " auto");
      },
    };

    this.addCntDialogOpt = {
      title: "Add Container",
      width: 400,
      height: "auto",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      position: {
        my: "center",
        at: "center",
        of: "#newDialog",
      },
      open: function (event, ui) {
        //hide close button.
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
      },
    };

    this.additionalDGDialogOpt = {
      title: "Add DG",
      width: 800,
      height: "auto",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      position: {
        my: "center",
        at: "center",
        of: "#newDialog",
      },
      open: function (event, ui) {
        //hide close button.
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
      },
    };

    $("#checkAllContainer")
      .checkbox()
      .checkbox({
        onChecked: function () {
          self.selectAllContainers = true;
        },
        onUnchecked: function () {
          self.selectAllContainers = false;
        },
      });

    this.nGridStore = [];

    $(".ui.accordion").accordion();

    // test grid
    //this.gridCllCopyHeader._settings = this.settingGridCopyHeader;

    // set default load port
    //this.cbPOL.setValue(this.office.officeCode)
    // this.cbPOL.setForceValue(this.office.officeName);
    // this.modelForm.portLoadCode = this.office.officeCode;
    // this.modelForm.portLoadName = this.office.officeName;
    // this.modelForm.portLoadId = this.office.officeId;

    //disabled button first
  }

  /* functional util here */

  updateVoyageUrl(type: string) {
    switch (type) {
      case "header":
        this.modelSailing.bound = this.modelForm.vesselBound;
        this.updateVoyageUrl1a(this.cbVoyage, this.modelSailing);
        if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
        }

        break;
      case "copy":
        break;
    }
  }

  updateVoyageUrl1a(combo: ComboPluginComponent, dt: MSailingSchedule) {
    if (dt.bound == "") {
      combo.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          dt.vesselId +
          "/*/{query}/" +
          this.office.officeCode
      );
    } else {
      combo.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          dt.vesselId +
          "/" +
          dt.bound.toUpperCase() +
          "/{query}/" +
          this.office.officeCode
      );
    }
  }

  resetCombo(id: string) {
    $("#" + id).combo("clear");
  }

  initCombo(id: string) {
    $("#" + id).combo();
  }

  styleInit() {
    $("calendar-plugin").css("font-size", "12px");
    $("calendar-plugin").find("input").css("font-size", "12px");
    $("combo-plugin").find("input").css("font-size", "12px");
    $("html, body").scrollTop(0);
  }

  vgmCheck(checked: boolean) {
    // console.log("value "+checked);
    this.modelNew.vgmStatus = checked;
    if (checked) {
      this.vgmLock = false;
    } else {
      this.vgmLock = true;
    }
  }



  getData() {
    var data = new cllGrid();
  }

  setData(data: any, type: string) {
    console.log(data);
    if (type == "newForm-booking") {
      this.modelNew.operatorCode = data["soCode"];
      this.modelNew.inSlot = data["opCode"];
      this.modelNew.status = data["bContainerStatus"] == "FULL" ? "F" : "E";
      $("#feCombo").dropdown("set selected", this.modelNew.status);
      this.modelNew.transhipPort = data["bPot1Code"];
      this.modelNew.transhipPortName = data["bPot1LocationName"];
      this.newCbPOT.setForceValue(this.modelNew.transhipPortName);
      this.modelNew.tranship = "N";
      if (this.modelNew.transhipPort != "") {
        this.modelNew.tranship = "Y";
      }
      $("#tsCombo").dropdown("set selected", this.modelNew.tranship);
      this.modelNew.loadPort = data["bPolCode"];
      this.modelNew.loadPortName = data["bPolLocationName"];
      this.newCbPol.setForceValue(this.modelNew.loadPortName);
      this.modelNew.discPort1 = data["bPodCode"];
      this.modelNew.discPort1Name = data["bPodLocationName"];
      this.newCbPOD1.setForceValue(this.modelNew.discPort1Name);
      this.modelNew.finalDest = data["bFdestCode"];
      this.modelNew.finalDestName = data["bFdestLocationName"];
      this.newCbFD.setForceValue(this.modelNew.finalDestName);
      this.modelNew.vesselCode = data["bOceanVesselCode"];
      this.modelNew.vesselId = data["bOceanVesselId"];
      this.modelNew.vesselName = data["vesselName"];
      this.newCbVessel.setForceValue(this.modelNew.vesselName);
      this.modelNew.voyage = data["bOceanVesselVoyage"];
      this.modelNew.bound = data["bOceanVesselBound"];
      this.newCbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageCode/" +
          this.modelNew.vesselCode +
          "/" +
          this.modelNew.bound.toUpperCase() +
          "/{query}/" +
          this.office.officeCode
      );
      this.newCbVoyage.setForceValue(this.modelNew.voyage);
      this.modelNew.shipperID = data["bShipperCode"];
      this.modelNew.shipperName =
        data["shipperName"] == undefined ? "" : data["shipperName"];
      this.newCbShipper.setForceValue(this.modelNew.shipperName);
      this.modelNew.commodityId = data["bCommodityCode"];
      this.modelNew.commodityName =
        data["bCommodityName"] == undefined ? "" : data["bCommodityName"];
      //this.newCbCommodity.setForceValue(this.modelNew.commodityName);
      this.modelNew.spDetails =
        data["bSpDetail"] == undefined ? "" : data["bSpDetail"];
      this.modelNew.spDetailsId = data["bSpecialDetailCode"];
      this.newCbSpecialDetail.setForceValue(this.modelNew.spDetails);
      if (this.modelNew.spDetails == "BUNDLE") {
        this.modelNew.bundle = true;
      }
      this.modelNew.bNo = "SUDU" + data["bNo"];

      //this.modelNew.pkgTypeCode = data["bPackageTypeCode"];
      //this.modelNew.pkgTypeId = data["bPackageTypeId"];
      //this.modelNew.pkgTypeName = data["bPackageTypeName"];
      //this.newCbPackageType.setForceValue(this.modelNew.pkgTypeName);

      if (data["bNextMotherVesselId"] != "") {
        /*this.modelNew.nextVesselID = data["bNextMotherVesselId"];
        this.modelNew.nextVesselCode = (data["nextMotherVesselCode"] == undefined ? "" : data["nextMotherVesselCode"]);
        this.modelNew.nextVesselName = (data["nextMotherVesselName"] == undefined ? "" : data["nextMotherVesselName"]);
        this.modelNew.nextVoyage = data["bNextMotherVesselVoyage"];*/

        this.modelNew.nextVesselID = "";
        this.modelNew.nextVesselCode =
          data["nextMotherVesselCode"] == undefined
            ? ""
            : data["nextMotherVesselName"];
        this.modelNew.nextVesselName = "";
        this.modelNew.nextVoyage = data["bNextMotherVesselVoyage"];

        //this.newNextCbVessel.setForceValue(this.modelNew.nextVesselName);
        //this.newNextCbVoyage.setForceValue(this.modelNew.nextVoyage);
      }

      this.lockReefer= true;
      this.lockReeferDry= true;
      this.modelNew.reefer = false;
      this.modelNew.reeferAsDry = false;
      this.lockBoth = true;
      
      
      console.log("before sapi 1");
      console.log("data[\"bookingExtra\"][0][\"bReeferDegree\"]=" + data["bookingExtra"][0]["bReeferDegree"]);
      if (data["bookingExtra"][0]["bReeferDegree"] != "") {
        var reefer = data["bookingExtra"][0]["bReeferDegree"];
        var temp = "";
        if (reefer.includes("C")) {
          temp = reefer.split("C")[0];
          this.modelNew.tempDegree = "C";
        } else if (reefer.includes("F")) {
          temp = reefer.split("F")[0];
          this.modelNew.tempDegree = "F";
        }

        try {
           console.log("sapi 1");
           console.log("temp=" + temp);
          if (!isNaN(parseFloat(temp))) {
            this.modelNew.tempSign = parseFloat(temp) < 0 ? "-" : "+";
            this.modelNew.reeferTemp = this.formattedString(
              (this.modelNew.tempSign == "+" ? 1 : -1) * parseFloat(temp),
              3,
              2
            );
            this.modelNew.reefer = true;
            this.modelNew.reeferAsDry = false;
            this.lockReefer = false;
            this.lockReeferDry = true;    
            this.lockBoth = false;        
          } else {
            this.modelNew.tempSign = "+";
            this.modelNew.reeferTemp = this.formattedString("0", 3, 2);
            this.modelNew.reefer = false;
          }
        } catch (err) {}
      }

      $("#temp-sign").val(this.modelNew.tempSign);
      $("#temp-Degreee").val(this.modelNew.tempDegree);
       console.log("Masuk dry : "+data["bIsDry"]);

      if (data["bIsDry"] == "Y") {
        console.log("Masuk dry");
        this.modelNew.reeferAsDry = true;
        this.modelNew.reefer = false;
        this.lockReefer = true;
        this.lockReeferDry = false;  
        this.lockBoth = false;   

        this.modelNew.tempSign = "+";
        this.modelNew.reeferTemp = this.formattedString("0", 3, 2);
        this.modelNew.tempDegree = "C";
      }       
      

      //start booking extra
      if (data["bookingExtra"] != undefined) {
        var bookingExtra: any[] = data["bookingExtra"];
          if (bookingExtra.length == 1) {
            var dataExtra = data["bookingExtra"][0];
            console.log("neti cek 0: "+dataExtra['bNatureTemp']);
            this.modelNew.cllNatureTemp = dataExtra['bNatureTemp'];
            /*switch (dataExtra['bNatureTemp']) {
              case "F":
                this.modelNew.cllNatureTemp = "FROZEN";
                break;
              case "C":
                this.modelNew.cllNatureTemp = "CHILLED";
                break;
              default:
                this.modelNew.cllNatureTemp = "N/A";
            }*/
            this.modelNew.cllSetOption = dataExtra["bSetOption"];
            this.cllDegreeTempSO = String(dataExtra['bDegreeSo']).substring(
              String(dataExtra['bDegreeSo']).length + 1,
              String(dataExtra['bDegreeSo']).length - 1
            );

            console.log("neti cek 1: "+dataExtra['bllDegreeSo']);
            console.log("neti cek 2: "+this.cllDegreeTempSO);

            if (dataExtra["bIsDry"] == "Y") {
              console.log("Masuk dry");
              this.modelNew.reeferAsDry = true;
              this.modelNew.reefer = false;
              this.lockReefer = true;
              this.lockReeferDry = false;   
              this.lockBoth = false;  
      
              this.modelNew.tempSign = "+";
              this.modelNew.reeferTemp = this.formattedString("0", 3, 2);
              this.modelNew.tempDegree = "C";
      
             }

            this.cllDegreeSO = Number.parseInt(
              String(dataExtra['bDegreeSo']).replace(
                this.cllDegreeTempSO.toString(),
                ""
              )
            );
            this.modelNew.cllDegreeSo = this.cllDegreeSO.toString() + this.cllDegreeTempSO;

            this.modelNew.cllVent =dataExtra['bVent'];
            this.modelNew.cllVentOption =dataExtra['bVentOption'];
            this.modelNew.cllVentNumber =dataExtra['bVentNumber'];
           // this.reeferCollectionDate = String(dataExtra['bReeferCollectionDate'].substr(8,2)) +"/"+String(dataExtra['bReeferCollectionDate'].substr(5,2))+"/"+String(dataExtra['bReeferCollectionDate'].substr(0,4));
           // $("#rfdTime").val(this.reeferCollectionDate);
            this.modelNew.cllHumidity = dataExtra['bHumidity'];
          }

          this.cllSetOption =this.modelNew.cllSetOption;
          this.cllVent =this.modelNew.cllVent;

      }else{
        //this.modelNew.cllVentOption = data.cllVentOption;
      }


      if (data["bDgClass"] != "N") {
        this.modelNew.dg = true;
        this.modelNew.dgClass = data["bDgClass"];
        $("#dgClass").val(this.modelNew.dgClass);
        if (data["bookingContainerInfo"] != undefined) {
          var containerInfoStore: any[] = data["bookingContainerInfo"];
          if (containerInfoStore.length == 1) {
            var dataContainer = data["bookingContainerInfo"][0];
            if (parseFloat(dataContainer["bImoNo"]) != 0) {
              this.modelNew.imo = dataContainer["bImoNo"];
            }
            if (parseFloat(dataContainer["bUnNo"]) != 0) {
              this.modelNew.unno = dataContainer["bUnNo"];
            }
            if (parseFloat(dataContainer["bFlashPoint"]) != 0) {
              this.modelNew.flashSign =
                parseFloat(dataContainer["bFlashPoint"]) < 0 ? "-" : "+";
              //this.modelNew.flashPoint = this.formattedString((this.modelNew.flashSign == "-"?-1:1) * parseFloat(dataContainer["bFlashPoint"]), 3,2);
              this.modelNew.flashPoint =
                parseFloat(dataContainer["bFlashPoint"]) < 0
                  ? dataContainer["bFlashPoint"].replace("-", "")
                  : dataContainer["bFlashPoint"];
              this.modelNew.flashScale = dataContainer["bFlashPointScale"]; //"";bFlashPointScale

              $("#flash-sign").val(this.modelNew.flashSign);
              $("#flash-Degreee").val(this.modelNew.flashScale);
            }

            if (dataContainer["bDgRef"] != "") {
              this.modelNew.dgCommodityDesc = dataContainer["bDgRef"];
              this.dgCmdDesc.nativeElement.value =
                this.modelNew.dgCommodityDesc;
            }

            this.modelNew.pkgTypeCode = dataContainer["bPackageTypeCode"];
            this.modelNew.pkgTypeId = dataContainer["bPackageTypeId"];
            this.modelNew.pkgTypeName = dataContainer["bPackageTypeName"];
            this.newCbPackageType.setForceValue(this.modelNew.pkgTypeName);
            this.newCbPackageType.disableCombo = false;
          } else {
            var c = 0;
            containerInfoStore.forEach((ci) => {
              if (c == 0) {
                if (parseFloat(ci["bImoNo"]) != 0) {
                  this.modelNew.imo = ci["bImoNo"];
                }
                if (parseFloat(ci["bUnNo"]) != 0) {
                  this.modelNew.unno = ci["bUnNo"];
                }
                if (parseFloat(ci["bFlashPoint"]) != 0) {
                  this.modelNew.flashSign =
                    parseFloat(ci["bFlashPoint"]) < 0 ? "-" : "+";
                  //this.modelNew.flashPoint = this.formattedString((this.modelNew.flashSign == "-"?-1:1) * parseFloat(ci["bFlashPoint"]), 3,2);
                  this.modelNew.flashPoint =
                    parseFloat(ci["bFlashPoint"]) < 0
                      ? ci["bFlashPoint"].replace("-", "")
                      : ci["bFlashPoint"];
                  this.modelNew.flashScale = ci["bFlashPointScale"];

                  $("#flash-sign").val(this.modelNew.flashSign);
                  $("#flash-Degreee").val(this.modelNew.flashScale);
                }

                if (ci["bDgRef"] != "") {
                  this.modelNew.dgCommodityDesc = ci["bDgRef"];
                  this.dgCmdDesc.nativeElement.value =
                    this.modelNew.dgCommodityDesc;
                }

                this.modelNew.pkgTypeCode = ci["bPackageTypeCode"];
                this.modelNew.pkgTypeId = ci["bPackageTypeId"];
                this.modelNew.pkgTypeName = ci["bPackageTypeName"];
                this.newCbPackageType.setForceValue(this.modelNew.pkgTypeName);
                this.newCbPackageType.disableCombo = false;
              } else {
                if (this.modelNew.imo != ci["bImoNo"]) {
                  this.modelNew.imo = "";
                }
                if (this.modelNew.unno != ci["bUnNo"]) {
                  this.modelNew.unno = "";
                }

                if (this.modelNew.dgCommodityDesc != ci["bDgRef"]) {
                  this.modelNew.dgCommodityDesc = "";
                  this.dgCmdDesc.nativeElement.value =
                    this.modelNew.dgCommodityDesc;
                }

                //var flashTemp = this.formattedString(((parseFloat(ci["bFlashPoint"]) < 0 ? "-":"+") == "-"?-1:1) * parseFloat(ci["bFlashPoint"]), 3,2);
                var flashTemp =
                  parseFloat(ci["bFlashPoint"]) < 0
                    ? ci["bFlashPoint"].replace("-", "")
                    : ci["bFlashPoint"];
                if (this.modelNew.flashPoint != flashTemp) {
                  this.modelNew.flashSign = "+";
                  this.modelNew.flashPoint = "0";
                  this.modelNew.flashScale = "C";
                  $("#flash-sign").val(this.modelNew.flashSign);
                  $("#flash-Degreee").val(this.modelNew.flashScale);
                }

                if (this.modelNew.pkgTypeCode != ci["bPackageTypeCode"]) {
                  this.modelNew.pkgTypeCode = "";
                  this.modelNew.pkgTypeId = "";
                  this.modelNew.pkgTypeName = "";
                  this.newCbPackageType.setForceValue(
                    this.modelNew.pkgTypeName
                  );
                  this.newCbPackageType.disableCombo = false;
                }
              }

              c++;
            });
          }
        }
      }

      if (data["bookingDetails"] != undefined) {
        var dataDetails = data["bookingDetails"][0];
        if (
          parseFloat(dataDetails["bOverHeightCm"]) != 0 ||
          parseFloat(dataDetails["bOverWidthRightCm"]) != 0 ||
          parseFloat(dataDetails["bOverWidthLeftCm"]) != 0 ||
          parseFloat(dataDetails["bOverLengthFrontCm"]) != 0 ||
          parseFloat(dataDetails["bOverLengthBackCm"]) != 0
        ) {
          if (
            (data["bContainerOwnership"].toString().substr(0, 3) == "SOC" &&
              this.modelNew.operatorCode == "AX" &&
              this.modelNew.inSlot == "SA") ||
            (data["bContainerOwnership"].toString().substr(0, 3) == "COC" &&
              this.modelNew.operatorCode == "SA" &&
              this.modelNew.inSlot == "SA")
          ) {
            this.modelNew.oh = this.formattedString(
              dataDetails["bOverHeightCm"],
              3,
              1
            );
            this.modelNew.owr = this.formattedString(
              dataDetails["bOverWidthRightCm"],
              3,
              1
            );
            this.modelNew.owl = this.formattedString(
              dataDetails["bOverWidthLeftCm"],
              3,
              1
            );
            this.modelNew.olf = this.formattedString(
              dataDetails["bOverLengthFrontCm"],
              3,
              1
            );
            this.modelNew.olb = this.formattedString(
              dataDetails["bOverLengthBackCm"],
              3,
              1
            );
            this.modelNew.ohScale = "CM";
            $("#oh-Degreee").val(this.modelNew.ohScale);
            this.modelNew.oog = true;
          }
        }

        if (
          dataDetails["bUch"] != 0 ||
          dataDetails["bUcl"] != 0 ||
          dataDetails["bUcw"] != 0
        ) {
          try {
            this.modelNew.uch = parseFloat(dataDetails["bUch"]);
            this.modelNew.ucl = parseFloat(dataDetails["bUcl"]);
            this.modelNew.ucw = parseFloat(dataDetails["bUcw"]);

            $("#uch-Degreee").val(this.modelNew.uchScale);
            $("#ucl-Degreee").val(this.modelNew.uclScale);
            $("#ucw-Degreee").val(this.modelNew.ucwScale);
          } catch (err) {}
          this.modelNew.uc = true;
        }

        var dataDetailsList = data["bookingDetails"];
        //var i = 1;
        var z = 1;
        var clString = "";
        var checkIsReefer = false;
        if (this.modelNew.reefer) {
          checkIsReefer = true;
        }
        dataDetailsList.forEach((fe) => {
          for (var i = 1; i <= parseInt(fe["bQuantity"]); i++) {
            var dC = new cllContainer();
            if (!checkIsReefer) {
              if (fe["bContainerType"] == "REF") {
                checkIsReefer = true;
              }
            }
            dC.containerType = fe["bContainerType"];
            dC.containerTypeName = fe["bCTypeName"];
            dC.containerSize = fe["bContainerSize"];
            dC.containerHeight = fe["bContainerHeight"];
            dC.typeSize = fe["bTypeSize"];
            if (data["bookingContainerInfo"] != undefined) {
              var dCL = data["bookingContainerInfo"];
              dCL.forEach((dl) => {
                if (
                  fe["bTypeSize"] == dl["bContType"] &&
                  dl["isPut"] == undefined &&
                  dC.containerNo == ""
                ) {
                  dC.containerNo = clString.includes(dl["bContainerNo"])
                    ? ""
                    : dl["bContainerNo"];
                  dC.containerSeq = z;
                  if (dl["bVgmMethod"] != "" || dl["bVgmWeight"] != "") {
                    dC.containerVgmIndicator = true;
                    dC["display_indicator"] = "Y";
                  }
                  if (dl["bVgmMethod"].trim() == "") {
                    dC.containerVgmMethod = "SM1";
                  } else {
                    dC.containerVgmMethod = dl["bVgmMethod"];
                  }
                  dC.containerWeight = parseFloat(dl["bVgmWeight"]);

                  dl["isPut"] = true;
                  clString = clString + dC.containerNo;
                  z++;
                }
                if (dC.containerSeq == 0) {
                  dC.containerSeq = z;
                  z++;
                }
              });
            } else {
              dC.containerVgmMethod = "SM1";
              dC.containerSeq = z;
              z++;
            }

            //console.log("container");
            //console.log(dC);

            this.gridNew.listStore.store.push(dC);
          }
        });

        if (checkIsReefer && !this.modelNew.reefer) {
          this.modelNew.reefer = true;
        }

        this.gridNew.loadData();
      }

      // patch remarks populate from booking where exist
      if (data["bookingContainerInfo"] != undefined) {
        var rm = "";
        var bCF = data["bookingContainerInfo"];
        bCF.forEach((b) => {
          if (b["bOtherDetails"] != "") {
            if (rm != "") {
              rm = rm + "\n";
            }
            rm = rm + b["bOtherDetails"] + "\n";
          }
        });

        if (rm != "") {
          this.modelNew.remarks = rm;
          this.remarkTa.nativeElement.value = this.modelNew.remarks;
        }
      }

      this.modelNew.thruSpt = "N";
      $("#tuCombo").dropdown("set selected", this.modelNew.thruSpt);
      //this.newCbPackageType.disableCombo = true;
    } else if (type == "newForm-noBooking") {
      this.modelNew = data;
      this.modelNew.dg = false;
      if (this.modelNew.vesselId != "" && this.modelNew.voyage != "") {
        this.newCbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyageCode/" +
            this.modelNew.vesselCode +
            "/" +
            this.modelNew.bound.toUpperCase() +
            "/{query}/" +
            this.office.officeCode
        );
        this.newCbVessel.setForceValue(this.modelNew.vesselName);
        this.newCbVoyage.setForceValue(this.modelNew.voyage);
        this.newCbVoyage.disableCombo = false;        
      }
      if (this.modelNew.loadPort != "") {
        //this.newCbPol.setValue(this.modelNew.loadPort);
        this.newCbPol.setForceValue(this.modelNew.loadPortName);
      }
      if (this.modelNew.discPort1 != "") {
        //this.newCbPOD1.setValue(this.modelNew.discPort1);
        this.newCbPOD1.setForceValue(this.modelNew.discPort1Name);
      }
      if (this.modelNew.finalDest != "") {
        //this.newCbFD.setValue(this.modelNew.finalDest);
        this.newCbFD.setForceValue(this.modelNew.finalDestName);
      }

      this.newCbPackageType.disableCombo = true;

      $("#feCombo").dropdown("set selected", this.modelNew.status);
      $("#tsCombo").dropdown("set selected", this.modelNew.tranship);
      $("#tuCombo").dropdown("set selected", this.modelNew.thruSpt);
      //this.modelNew.dgClass = data["bDgClass"];
      $("#dgClass").val(this.modelNew.dgClass);
    } else if (type == "newForm-retrieve") {
      this.modelNew = data;

      if (this.modelNew.bNo.trim() != "") {
        this.bkgRefMode = true;
      }

      this.newCbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageCode/" +
          this.modelNew.vesselCode +
          "/" +
          this.modelNew.bound.toUpperCase() +
          "/{query}/" +
          this.office.officeCode
      );
      this.newCbVessel.setUrl(this.settingVesselNew.url2);
      //this.newCbVessel.setValue(this.modelNew.vesselCode);
      this.newCbVessel.setForceValue(this.modelNew.vesselName);
      //this.newCbVoyage.setValue(this.modelNew.voyage);
      this.newCbVoyage.setForceValue(this.modelNew.voyage);
      this.newCbVoyage.disableCombo = false;

      //this.newNextCbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageCode/"+this.modelNew.nextVesselCode+"/"+this.modelNew.nextBound+"/{query}/"+this.office.officeCode);
      //this.newNextCbVessel.setValue(this.modelNew.nextVesselCode);
      //this.newNextCbVessel.setForceValue(this.modelNew.nextVesselName);
      //this.newNextCbVoyage.setValue(this.modelNew.nextVoyage);
      //this.newNextCbVoyage.setForceValue(this.modelNew.nextVoyage);

      //this.newNextCbVoyage.disableCombo = false;

      //this.newCbShipper.setValue(this.modelNew.shipperName);
      this.newCbShipper.setForceValue(this.modelNew.shipperName);

      //this.newCbPol.setValue(this.modelNew.loadPort);
      this.newCbPol.setForceValue(this.modelNew.loadPortName);
      //this.newCbPOD1.setValue(this.modelNew.discPort1);
      this.newCbPOD1.setForceValue(this.modelNew.discPort1Name);
      //this.newCbPOD2.setValue(this.modelNew.discPort2);
      this.newCbPOD2.setForceValue(this.modelNew.discPort2Name);
      //this.newCbPOD3.setValue(this.modelNew.discPort3);
      this.newCbPOD3.setForceValue(this.modelNew.discPort3Name);
      //this.newCbFD.setValue(this.modelNew.finalDest);
      this.newCbFD.setForceValue(this.modelNew.finalDestName);
      //this.newCbPOT.setValue(this.modelNew.transhipPort);
      this.newCbPOT.setForceValue(this.modelNew.transhipPortName);
      //this.newCbCommodity.setValue(this.modelNew.commodityName);
      //this.newCbSpecialDetail.setValue(this.modelNew.spDetails);
      this.newCbSpecialDetail.setForceValue(this.modelNew.spDetails);
      //this.newCbPackageType.setValue(this.modelNew.pkgTypeName);
      this.newCbPackageType.setForceValue(this.modelNew.pkgTypeName);

      this.refDmdDesc.nativeElement.value = this.modelNew.reeferCommodityDesc;
      this.dgCmdDesc.nativeElement.value = this.modelNew.dgCommodityDesc;
      this.remarkTa.nativeElement.value = this.modelNew.remarks;

      if (this.modelNew.stackDateTime != "") {
        //this.cllSDT.setCalendarValue2(this.modelNew.stackDateTime, "YYYY-MM-DD");
        //this.cllSDT.setCalendarValue2(this.modelNew.stackDateTime, "dd/mm/yyyy hh:ii");
        console.log("neti jelek");
        console.log(this.modelNew.stackDateTime);
        //$("#stdTime").inputmask("setvalue", this.modelNew.stackDateTime);
        $("#stdTime").val(this.modelNew.stackDateTime);
       // $("#rfdTime").val(this.modelNew.cllReeferCollectionDate);
      }

      $("#feCombo").dropdown(
        "set selected",
        this.modelNew.status == "N" ? "F" : "E"
      );
      $("#tsCombo").dropdown("set selected", this.modelNew.tranship);
      $("#vgmCombo").dropdown("set selected", this.modelNew.vgmMethod);
      $("#tuCombo").dropdown("set selected", this.modelNew.thruSpt);

      if (this.modelNew.containerList.length > 0) {
        this.modelNew.containerList.forEach((cle) => {
          this.gridNew.listStore.store.push(cle);
        });

        this.gridNew.loadData();
      }

      if (this.modelNew.transmitToFISSI == "N") {
        this.isAutoCreate = true;
      }
         

      console.log("nosix " + this.modelNew.reefer);
      console.log("nosix2 " + this.modelNew.status);
      console.log("nosix3 " + this.modelNew.vgmShipper);
      if (this.modelNew.reefer) {
        this.lockBoth = false;
        if (this.modelNew.status == "EMPTY" || this.modelNew.status == "Y") {
          if (
            this.modelNew.vgmShipper == "blank" ||
            this.modelNew.vgmShipper == ""
          ) {
            this.modelNew.tempSign = "";
            this.modelNew.tempDegree = "";
            this.modelNew.reeferTemp = "";
          } else {
            this.modelNew.reeferTemp = this.formattedString(
              this.modelNew.reeferTemp,
              3,
              2
            );
          }
        } else {
          this.modelNew.reeferTemp = this.formattedString(
            this.modelNew.reeferTemp,
            3,
            2
          );
        }
      }else if (this.modelNew.reeferAsDry) {
        this.lockBoth = false;
      }else{
        this.lockReefer= true;
        this.lockReeferDry= true;
        this.modelNew.reefer = false;
        this.modelNew.reeferAsDry = false;
        this.lockBoth = true;  
      }

      

      $("#temp-sign").val(this.modelNew.tempSign);
      $("#temp-Degreee").val(this.modelNew.tempDegree);
      $("#flash-sign").val(this.modelNew.flashSign);
      $("#flash-Degreee").val(this.modelNew.flashScale);
      $("#oh-Degreee").val(this.modelNew.ohScale);
      $("#uch-Degreee").val(this.modelNew.uchScale);
      $("#ucl-Degreee").val(this.modelNew.uclScale);
      $("#ucw-Degreee").val(this.modelNew.ucwScale);
      $("#dgClass").val(this.modelNew.dgClass);
     // $("#rfdTime").val(this.modelNew.cllReeferCollectionDate);

      switch (this.modelNew.cllNatureTemp) {
        case "F":
          this.cllNatureTemp = "FROZEN";
          break;
        case "C":
          this.cllNatureTemp = "CHILLED";
          break;
        default:
          this.cllNatureTemp = "N/A";
      }
      this.cllDegreeTempSO = String(this.modelNew.cllDegreeSo).substring(
        String(this.modelNew.cllDegreeSo).length + 1,
        String(this.modelNew.cllDegreeSo).length - 1
      );

      console.log("neti cek 1: "+this.modelNew.cllDegreeSo);
      console.log("neti cek 2: "+this.cllDegreeTempSO);

      this.cllDegreeSO = Number.parseInt(
        String(this.modelNew.cllDegreeSo).replace(
          this.cllDegreeTempSO.toString(),
          ""
        )
      );

      this.modelNew.oh = this.formattedString(this.modelNew.oh, 3, 1);
      this.modelNew.owl = this.formattedString(this.modelNew.owl, 3, 1);
      this.modelNew.owr = this.formattedString(this.modelNew.owr, 3, 1);
      this.modelNew.olf = this.formattedString(this.modelNew.olf, 3, 1);
      this.modelNew.olb = this.formattedString(this.modelNew.olb, 3, 1);
      if (this.modelNew.bNo == "") {
        this.bookingRefDisabled = false;
      }
    } else if (type == "newForm-clear") {
      this.modelNew = new cllGrid();
      $("#feCombo").dropdown("clear");
      $("#tsCombo").dropdown("clear");
      this.newCbVessel.setForceValue("");
      this.newCbVoyage.setForceValue("");
      this.newCbPol.setForceValue("");
      this.newCbPOD1.setForceValue("");
      this.newCbPOD2.setForceValue("");
      this.newCbPOD3.setForceValue("");
      this.newCbFD.setForceValue("");
      this.newCbPOT.setForceValue("");
      this.newCbShipper.setForceValue("");
      this.newCbPackageType.setForceValue("");
      //this.newNextCbVessel.setValue("");
      //this.newNextCbVoyage.setValue("");
      //this.cllSDT.resetCalendar();
      $("#stdTime").val("");
      $("#rfdTime").val("");
      //this.newCbCommodity.setValue("");
      this.newCbSpecialDetail.setForceValue("");
      this.gridNew.onClear();
      this.containerPlusButtonDisabled = false;
      this.containerMinusButtonDisabled = true;
      this.errorFormMessage = "";
      $("#dgClass").val(this.modelNew.dgClass);

      this.remarkTa.nativeElement.value = "";
      this.dgCmdDesc.nativeElement.value = "";
      this.refDmdDesc.nativeElement.value = "";
      this.bookingRefDisabled = true;

      $("#feCombo").dropdown(
        "set selected",
        this.modelNew.status == "N" ? "F" : "E"
      );
      $("#tsCombo").dropdown("set selected", this.modelNew.tranship);
      $("#vgmCombo").dropdown("set selected", this.modelNew.vgmMethod);
      $("#tuCombo").dropdown("set selected", this.modelNew.thruSpt);

      $("#temp-sign").val(this.modelNew.tempSign);
      $("#temp-Degreee").val(this.modelNew.tempDegree);
      $("#flash-sign").val(this.modelNew.flashSign);
      $("#flash-Degreee").val(this.modelNew.flashScale);
      $("#oh-Degreee").val(this.modelNew.ohScale);
      $("#uch-Degreee").val(this.modelNew.uchScale);
      $("#ucl-Degreee").val(this.modelNew.uclScale);
      $("#ucw-Degreee").val(this.modelNew.ucwScale);
      $("#dgClass").val(this.modelNew.dgClass);

      $("#newDialog")
        .find(".content")
        .first()
        .find("#firstForm")
        .css("display", "none");
      $("#newDialog")
        .find(".content")
        .first()
        .find("#secondForm")
        .css("display", "none");
    } else if (type == "newForm-addContainer-retrieve") {
      $("#typeCombo").dropdown("set selected", data.containerType);
      $("#sizeCombo").dropdown("set selected", data.typeSize);
      $("#heightCombo").dropdown("set selected", data.containerHeight);
      $("#methodCombo").dropdown("set selected", data.containerVgmMethod);
    }
  }

  loadContainerSizeComboData() {
    var htmlData = "";
    this.addContainerCount = 1;
    this.containerType.forEach((fe) => {
      htmlData =
        htmlData +
        "<div class='item' data-value='" +
        fe.typeCode +
        "' style='font-size:11px'>" +
        fe.typeName +
        "</div>";
    });
    $("#typeCombo").find(".menu").append(htmlData);

    htmlData = "";
    this.containerSize.forEach((fe) => {
      htmlData =
        htmlData +
        "<div class='item' data-value='" +
        fe.sizeCode +
        "' style='font-size:11px'>" +
        fe.sizeName +
        "</div>";
    });
    //$("#sizeCombo").find(".menu").append(htmlData);

    htmlData = "";
    this.containerHeight.forEach((fe) => {
      htmlData =
        htmlData +
        "<div class='item' data-value='" +
        fe.heightCode +
        "' style='font-size:11px'>" +
        fe.heightName +
        "</div>";
    });
    //$("#heightCombo").find(".menu").append(htmlData);
  }

  loadContainerDropDown(value: string) {
    var hitURL =
      this.hitUrl +
      "/MasterPerCodes/getPerCodeByCtype/tags/" +
      value.toUpperCase() +
      "/";
    $("#sizeCombo").dropdown("clear");
    $("#sizeCombo").find(".menu").empty();
    if (!this.sizeComboDisabled) {
      this.sizeComboDisabled = true;
    }
    this.genericService.GET(hitURL).subscribe((resp) => {
      if (resp.ok) {
        var htmlData = "";
        var data = [];
        var cSizeExist: boolean = false;
        data = resp.json()["results"];
        data.forEach((fe) => {
          htmlData =
            htmlData +
            "<div class='item' data-value='" +
            fe.value +
            "' style='font-size:11px'>" +
            fe.name +
            "</div>";
          if (this.modelContainer.typeSize != "") {
            if (fe.value == this.modelContainer.typeSize) {
              cSizeExist = true;
            }
          }
        });
        $("#sizeCombo").find(".menu").append(htmlData);
        this.sizeComboLoading = false;
        this.sizeComboDisabled = false;
        var self = this;

        $("#sizeCombo").dropdown({
          action: "activate",
          onChange: function (value, text, $selectedItem) {
            self.eventDropDown("csize", value);
          },
        });

        if (this.modelContainer.typeSize != "") {
          if (cSizeExist) {
            $("#sizeCombo").dropdown(
              "set selected",
              this.modelContainer.typeSize
            );
          } else {
            this.modelContainer.typeSize = "";
          }
        }
      }
    });
  }

  addContainer2(){
    this.modelContainer = new cllContainer();
    this.modelContainer.no = this.gridNew.listStore.store.length + 1;
    this.addContainer("newAddContainer");
  }
  addContainer(id: string) {
    // console.log('check : '+this.modelNew.vesselId);
    var self = this;
    self.addContainerStatus = "new";
    console.log(this.modelNew.voyage);
    if (this.modelNew.vesselId != "" && this.modelNew.voyage != "") {
      this.showDialog(id, this.addCntDialogOpt);

      if (this.addContainerCount == 0) {
        this.loadContainerSizeComboData();
      }

      this.modelContainer = new cllContainer();

      $("#typeCombo").dropdown({
        action: "activate",
        onChange: function (value, text, $selectedItem) {
          self.eventDropDown("ctype", value);
          self.eventDropDown("ctype2", text);
        },
      });

      $("#methodCombo").dropdown({
        action: "activate",
        onChange: function (value, text, $selectedItem) {
          self.eventDropDown("cmethod", value);
        },
      });

      $("#methodCombo").dropdown("set selected", "SM1");
    } else {
      this.message(
        "Information",
        "information",
        "Please insert Vessel and voyage first!",
        "okonly",
        { ok: "" }
      );
    }
  }

  closeNewContainerLoadList(id: string) {
    // console.log("close dialog");
    //this.newCbBooking.setValue("");
    this.modelNew.bNo = "";
    this.setData(new cllGrid(), "newForm-clear");
    this.newDialogStatus = false;
    this.newDialogFirstInit = false;
    $(".menu .item").tab("change tab", "ci");
    this.isAutoCreate = false;
    this.bkgRefMode = false;

    this.modelNew['reeferTemp'] = '0';
    this.modelNew['tempDegree'] = 'C';
    this.modelNew['tempSign'] = '+';
    this.modelNew['cllVentNumber'] = 0;
    this.modelNew['cllDegreeTempSO'] = 'C';
    this.modelNew['cllHumidity'] = '';
    this.modelNew['cllSetOption'] = '';
    this.modelNew['cllVent'] = '';
    this.modelNew['cllVentOption'] = 'O';
    this.cllDegreeTempSO = 'C';
    this.cllDegreeSO = NaN;
    this.modelNew['reeferCommodityDesc'] = '';
    this.refDmdDesc.nativeElement.value = '';
    this.modelNew['cllNatureTemp'] = '';

    this.closeDialog(id);
  }

  showDialog(id: string, options: any) {
    $("#" + id)
      .dialog(options)
      .dialog("open");
  }

  closeDialog(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  /* end of functional util block */

  /** Main Functional */

  handleNew() {
    this.showDialog("newDialog", this.newDialogOpt);

    /*$('#newGridBox').find('.dataTables_scroll').find('#newGrid_dataTables_scrollBody').css({
      "height":"125px",
      "min-height":"125px"
    });

    $('#newGridBox').find('.dataTables_scroll').find('#newGrid-table-head').find('thead').first().css({
      "font-size":"12px"
    });

    $('#newGridBox').find('.dataTables_scroll').find('.dataTables_scrollBody').find('table').find('tbody').first().css({
      "font-size":"10px"
    });

    $('#newGridBox').find(".ui.segment").css("min-height","100px");*/

    $(".ui.radio.checkbox").checkbox();
    $("#sDT").find(".ui.calendar").css("margin-top", "-5px");
    //this.cllSDT.generateCalendar('');

    $("#newDialog")
      .find(".content")
      .first()
      .find("#firstForm")
      .css("display", "block");
    $("#newDialog")
      .find(".content")
      .first()
      .find("#secondForm")
      .css("display", "none");

    this.newCbVoyage.disableCombo = true;
    this.newDialogStatus = true;
  }

  process(action: string) {
    // console.log("horee "+action);
    this.newDialogFirstInit = true;
    this.handleOk();
  }

  handleOk() {
    // console.log("handleOk run");

    $("#newDialog")
      .find(".content")
      .first()
      .find("#firstForm")
      .css("display", "none");
    $("#newDialog")
      .find(".content")
      .first()
      .find("#secondForm")
      .css("display", "block");

    $("#addContainerBtn").show();

    if (this.modelNew.bNo != "") {
      // console.log("bno exist");
      this.modelNew.bNo = this.modelNew.bNo.toUpperCase();
      this.containerPlusButtonDisabled = false;
      this.containerMinusButtonDisabled = true;
      //load booking data
      //var url = this.hitUrl + "/MasterBookings/getBookingById/"+this.office.officeCode+"/"+this.modelNew.bprefix+"/"+this.modelNew.bNo;
      var url =
        this.hitUrl +
        "/ContainerLoadList/getBookingDataAll/" +
        this.modelNew.bNo +
        "/" +
        this.office.officeCode +
        "/" +
        this.modelForm.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage).trim());
      this.loadingDialog = true;
      this.genericService.GET(url).subscribe(
        (resp) => {
          if (resp.ok) {
            // console.log(resp.json()["result"]);
            if (
              resp.json()["result"] != "Booking not found" &&
              resp.json()["result"] != "Booking already deleted"
            ) {
              var loadedData = resp.json()["result"];
              this.setData(loadedData, "newForm-booking");
              this.loadingDialog = false;
            } else {
              this.modelNew.bNo = "";
              this.loadingDialog = false;
              this.message(
                "Information",
                "information",
                resp.json()["result"],
                "okonly",
                { ok: "this.handleOk();" }
              );
            }
            //$("#secondForm").show();
          }
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error Occured while retreiving data, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          this.loadingDialog = false;
        }
      );
    } else if (this.modelNew.processID != "") {
      if (this.modelNew.processID.startsWith("OC")) {
        this.loadingDialog = true;
        var hitUrl =
          this.hitUrl +
          "/ContainerLoadList/findByID/" +
          this.modelNew.processID;
        this.genericService.GET(hitUrl).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json()["content"];
            var loadedData = new cllGrid();
            loadedData.convertDbToObject(result);
            // console.log(loadedData);

            this.setData(loadedData, "newForm-retrieve");
            this.loadingDialog = false;
          }
        });
      } else {
        this.loadingDialog = true;
        this.gridCLLUpload.listStore.store.forEach((fe) => {
          if (fe["processID"] == this.modelNew.processID) {
            // console.log(fe);
            var result = fe["allData"];
            var loadedData = new cllGrid();
            // console.log(result);
            loadedData.convertDbToObject(result);
            this.setData(loadedData, "newForm-retrieve");
            this.loadingDialog = false;
          }
        });
      }
    } else {
      // console.log("no bno");
      var loadedData = new cllGrid();
      this.containerPlusButtonDisabled = false;
      this.containerMinusButtonDisabled = true;
      loadedData.status = "FULL";
      loadedData.cntrOpr = "SA";
      loadedData.tranship = "N";
      loadedData.commodityName = "";

      if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
        loadedData.vesselId = this.modelForm.vesselId;
        loadedData.vesselCode = this.modelForm.vesselCode;
        loadedData.vesselName = this.modelForm.vesselName;
        loadedData.voyage = this.modelForm.baseVoyage;
        if (this.modelForm.portLoadCode != "") {
          loadedData.loadPort = this.modelForm.portLoadCode;
          loadedData.loadPortName = this.modelForm.portLoadName;
        }
        if (this.modelForm.portDischargeCode != "") {
          loadedData.discPort1 = this.modelForm.portDischargeCode;
          loadedData.discPort1Name = this.modelForm.portDischargeName;
        }
        if (this.modelForm.finalDestinationCode != "") {
          loadedData.finalDest = this.modelForm.finalDestinationCode;
          loadedData.finalDestName = this.modelForm.finalDestinationName;
        }
        if (this.modelForm.op != "") {
          loadedData.operatorCode = this.modelForm.op;
        }
        if (this.modelForm.so != "") {
          loadedData.inSlot = this.modelForm.so;
        }
      }

      this.setData(loadedData, "newForm-noBooking");
      //$("#secondForm").show();
    }

    //this.cllSDT.initCalendar("");
    $("#stdTime").inputmask("99/99/9999 99:99", {
      placeholder: "dd/mm/yyyy hh:mm",
    });

    $("#rfdTime").inputmask("99/99/9999", {
      placeholder: "dd/mm/yyyy",
    });

    $("#nShipper").find(".resultss").css("z-index", "120");

    $(".menu .item").tab();

    var self = this;
    $("#feCombo").dropdown({
      action: "activate",
      onChange: function (value, text, $selectedItem) {
        self.eventDropDown("cstatus", value);
      },
    });

    $("#tsCombo").dropdown({
      action: "activate",
      onChange: function (value, text, $selectedItem) {
        self.eventDropDown("ctranship", value);
      },
    });

    $("#tuCombo").dropdown({
      action: "activate",
      onChange: function (value, text, $selectedItem) {
        self.eventDropDown("cthru", value);
      },
    });
  }

  addNew() {
    this.addContainerLoading = true;
    this.modelContainer.containerNo = this.modelContainer.containerNo.toUpperCase();
    this.modelContainer.containerNo = this.modelContainer.containerNo.replace(/\s/g, "");

      console.log(this.modelContainer.containerNo)
    this.modelContainer.containerSealNo1 =
      this.modelContainer.containerSealNo1.toUpperCase();
    this.modelContainer.containerSealNo2 =
      this.modelContainer.containerSealNo2.toUpperCase();
    this.modelContainer.containerSealNo3 =
      this.modelContainer.containerSealNo3.toUpperCase();
    this.modelContainer["display_indicator"] = this.modelContainer
      .containerVgmIndicator
      ? "Y"
      : "N";
    this.modelContainer.pax = this.modelContainer.pax.toUpperCase();

    if (this.modelContainer.containerWeight == null) {
      this.modelContainer.containerWeight = 0;
    }

    // console.log(this.modelContainer);
    var okCancel: string = "";
    var tempContainerStore = this.cloneStore(this.gridNew.listStore.store);

    // console.log("container seq " + this.modelContainer.containerSeq);

    if (this.checkContainerData(this.modelContainer)) {
      var cnNumber = this.removeSpace(this.modelContainer.containerNo.trim());
      var urlCheck =
        this.hitUrl +
        "/ContainerLoadList/checkContainerNumber/" +
        this.office.officeCode +
        "/" +
        this.modelForm.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage).trim()) +
        "/" +
        cnNumber;
      if (this.addContainerStatus == "edit") {
        urlCheck =
          this.hitUrl +
          "/ContainerLoadList/checkContainerNumber/" +
          this.office.officeCode +
          "/" +
          this.modelForm.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage).trim()) +
          "/" +
          cnNumber +
          "/" +
          this.modelNew.processID;
      }
      this.genericService.GET(urlCheck).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()["message"] == "container exist") {
            if (resp.json()["type"] != undefined) {
              if (resp.json()["type"] != "") {
                this.message(
                  "information",
                  "Information",
                  "container already exist on BL " + resp.json()["type"] + "!",
                  "okonly",
                  { ok: "" }
                );
              } else {
                this.message(
                  "information",
                  "Information",
                  "Duplicate container!",
                  "okonly",
                  { ok: "" }
                );
              }
            }
            this.addContainerLoading = false;
          } else {
            if (this.checkDigitCalculator()) {
              if (this.modelContainer.containerSeq == 0) {
                this.modelContainer.containerSeq =
                  this.gridNew.listStore.store.length + 1;
                //this.gridNew.listStore.addData(this.modelContainer);
                this.gridNew.listStore.store.push(this.modelContainer);
                this.gridNew.loadData();
              } else {
                this.gridNew.listStore.store.splice(
                  0,
                  this.gridNew.listStore.store.length
                );
                tempContainerStore.forEach((fe) => {
                  if (fe.containerSeq == this.modelContainer.containerSeq) {
                    fe = this.modelContainer;
                  }

                  this.gridNew.listStore.store.push(fe);
                });
              }
              this.addContainerLoading = false;
              this.cancelNew();
              //this.gridNew.loadData();
            } else {
              this.message(
                "Information",
                "information",
                "Container Number check digit is invalid, do you want to continue?",
                "okcancel",
                {
                  ok: "this.byPassCheckDigit(1);",
                  cancel: "this.byPassCheckDigit(0)",
                }
              );
            }
          }
        }
      });
    }

    this.gridNew.clearSelectedValues();
  }

  checkContainer(grid: GridPluginComponent, data, field: string) {
    // check duplication
    var result: boolean = false;
    //if(this.addContainerStatus != "edit"){
    grid.listStore.store.forEach((fe) => {
      if (fe[field].replace(/\s+/g, "") == data[field].replace(/\s+/g, "")) {
        if (fe.containerSeq != data.containerSeq) {
          result = true;
        }
      }
    });

    return result;
  }

  checkContainerData(data: cllContainer) {
    var result: boolean = false;
    if (this.modelContainer.containerNo == "") {
      this.message(
        "Information",
        "information",
        "Please insert container no.",
        "okonly",
        { ok: "" }
      );
    } else if (this.modelContainer.containerType == "") {
      this.message(
        "Information",
        "information",
        "Please insert Type.",
        "okonly",
        { ok: "" }
      );
    } else if (
      this.modelContainer.typeSize == "" &&
      this.modelContainer.containerType != "UC"
    ) {
      this.message(
        "Information",
        "information",
        "Please insert size.",
        "okonly",
        { ok: "" }
      );
    } else if (this.modelContainer.containerWeight == 0) {
      this.message(
        "Information",
        "information",
        "Please insert VGM WT.",
        "okonly",
        { ok: "" }
      );
    } else if (
      this.checkContainer(this.gridNew, this.modelContainer, "containerNo")
    ) {
      this.message(
        "Information",
        "information",
        "Duplicate container number detected.",
        "okonly",
        { ok: "" }
      );
    } else if (
      this.modelContainer.containerVgmIndicator &&
      this.modelContainer.containerVgmMethod == ""
    ) {
      this.message(
        "Information",
        "information",
        "Please insert VGM method.",
        "okonly",
        { ok: "" }
      );
    } else if (
      !this.modelContainer.containerVgmIndicator &&
      this.modelContainer.containerVgmMethod != ""
    ) {
      this.message(
        "Information",
        "information",
        "VGM Indicator is set to false, no need to fill VGM Method.",
        "okonly",
        { ok: "" }
      );
    } else {
      result = true;
    }

    if (this.addContainerLoading) {
      this.addContainerLoading = false;
    }

    console.log("reefer validations");
    if(this.modelContainer.containerType == "DRY"){
      console.log("REEFER DRY HERE");
      this.modelNew.reeferAsDry =true;
      this.modelNew.reefer = false;
      this.lockReefer= true;
      this.lockReeferDry = false;            
      this.lockVentNumber = true;
      this.lockBoth = false;
      this.modelNew['reeferTemp'] = '0';
      this.modelNew['tempDegree'] = 'C';
      this.modelNew['tempSign'] = '+';
      this.modelNew['cllVentNumber'] = 0;
      this.modelNew['cllDegreeTempSO'] = 'C';
      this.modelNew['cllHumidity'] = '';
      this.modelNew['cllSetOption'] = 'N';
      this.modelNew['cllVent'] = 'N';
      this.modelNew['cllVentOption'] = 'O';
      this.cllDegreeTempSO = 'C';
      this.cllDegreeSO = NaN;
      this.modelNew['reeferCommodityDesc'] = '';
      this.refDmdDesc.nativeElement.value = '';
      this.modelNew['cllNatureTemp'] = '';
      

    }else if(this.modelContainer.containerType == "REF"){
      console.log("REEFER HERE");
      console.log("CLL Degree SO : "+this.modelNew.cllDegreeSo);
      this.modelNew.reeferAsDry =false;
      this.modelNew.reefer = true;
      this.lockReefer= false;
      this.lockReeferDry = true;   
      this.lockBoth = false;

      if (this.modelNew.cllDegreeSo == undefined || this.modelNew.cllDegreeSo == ''){
        this.cllDegreeSO = NaN;
        this.cllDegreeTempSO = 'C';
      }  

      if(this.modelNew.cllSetOption == "" || this.modelNew.cllSetOption == null){
        this.modelNew.cllSetOption = "C";
        this.lockVentNumber = true;
      }else{
        this.modelNew.cllSetOption = this.cllSetOption;
        this.lockVentNumber = false;
      }
      if(this.modelNew.cllVent == "" || this.modelNew.cllVent == null){
        this.modelNew.cllVent = "C";
        this.lockVentNumber = true;
      }else{
        this.modelNew.cllVent = this.cllVent;
        this.lockVentNumber = false;
      }
      if (!this.modelNew.reefer) {
        this.modelNew.reeferTemp = "0";
        this.modelNew.tempSign = "+";
        this.modelNew.tempDegree = "C";
        $("#temp-sign").val(this.modelNew.tempSign);
        $("#temp-Degreee").val(this.modelNew.tempDegree);
      } else {
        if (this.modelNew.status == "EMPTY") {
          this.modelNew.reeferTemp = "";
          this.modelNew.tempSign = "";
          this.modelNew.tempDegree = "";
          $("#temp-sign").val(this.modelNew.tempSign);
          $("#temp-Degreee").val(this.modelNew.tempDegree);
        } else {
          this.modelNew.reeferTemp =this.modelNew.reeferTemp;
          console.log("temperatur reefer : "+this.modelNew.tempSign);
          if(this.modelNew.tempSign == '' || this.modelNew.tempSign == undefined){
            this.modelNew.tempSign = "+";
          }                   
          this.modelNew.tempDegree = "C";
          $("#temp-sign").val(this.modelNew.tempSign);
          $("#temp-Degreee").val(this.modelNew.tempDegree);
        }
      }

    }else{    
      console.log("EXC REEFER OR REEFER DRY"); 
      this.modelNew.reefer=false;
      this.modelNew.reeferAsDry =false;
      this.lockReefer= true;
      this.lockReeferDry= true;
      this.lockBoth = true;
      this.lockVentNumber = true;
      this.modelNew['reeferTemp'] = '0';
      this.modelNew['tempDegree'] = 'C';
      this.modelNew['tempSign'] = '+';
      this.modelNew['cllVentNumber'] = 0;
      this.modelNew['cllDegreeTempSO'] = 'C';
      this.modelNew['cllHumidity'] = '';
      this.modelNew['cllSetOption'] = 'N';
      this.modelNew['cllVent'] = 'N';
      this.modelNew['cllVentOption'] = 'O';
      this.cllDegreeTempSO = 'C';
      this.cllDegreeSO = NaN;
      this.modelNew['reeferCommodityDesc'] = '';
      this.refDmdDesc.nativeElement.value = '';
      this.modelNew['cllNatureTemp'] = '';

    }
    

    if(this.modelContainer.containerType){

    }else if(this.modelNew.reeferAsDry){            

        this.modelNew['reeferTemp'] = '0';
        this.modelNew['tempDegree'] = 'C';
        this.modelNew['tempSign'] = '+';
        this.modelNew['cllVentNumber'] = 0;
        this.modelNew['cllDegreeTempSO'] = 'C';
        this.modelNew['cllHumidity'] = '';
        this.modelNew['cllSetOption'] = 'N';
        this.modelNew['cllVent'] = 'N';
        this.modelNew['cllVentOption'] = 'O';
        this.cllDegreeTempSO = 'C';
        this.cllDegreeSO = NaN;
        this.modelNew['reeferCommodityDesc'] = '';
        this.refDmdDesc.nativeElement.value = '';
        this.modelNew['cllNatureTemp'] = '';
    }else{
        this.modelNew['reeferTemp'] = '0';
        this.modelNew['tempDegree'] = 'C';
        this.modelNew['tempSign'] = '+';
        this.modelNew['cllVentNumber'] = 0;
        this.modelNew['cllDegreeTempSO'] = 'C';
        this.modelNew['cllHumidity'] = '';
        this.modelNew['cllSetOption'] = 'N';
        this.modelNew['cllVent'] = 'N';
        this.modelNew['cllVentOption'] = 'O';
        this.cllDegreeTempSO = 'C';
        this.cllDegreeSO = NaN;
        this.modelNew['reeferCommodityDesc'] = '';
        this.refDmdDesc.nativeElement.value = '';
        this.modelNew['cllNatureTemp'] = '';

    }

    return result;
  }

  byPassCheckDigit(param) {
    var tempContainerStore = this.cloneStore(this.gridNew.listStore.store);

    if (param == 1) {
      if (this.modelContainer.containerSeq == 0) {
        this.modelContainer.containerSeq = 
          this.gridNew.listStore.store.length + 1;
        //this.gridNew.listStore.addData(this.modelContainer);
        this.gridNew.listStore.store.push(this.modelContainer);
        this.gridNew.loadData();
      } else {
        this.gridNew.listStore.store.splice(
          0,
          this.gridNew.listStore.store.length
        );
        tempContainerStore.forEach((fe) => {
          if (fe.no == this.modelContainer.no) {
            fe = this.modelContainer;
          }

          this.gridNew.listStore.store.push(fe);
        });
        this.gridNew.loadData();
      }
      //this.gridNew.listStore.addData(this.modelContainer);
      this.addContainerLoading = false;
      this.cancelNew();
      //this.gridNew.loadData();
    } else if (param == 0) {
      this.modelContainer.containerNo = "";
      this.addContainerLoading = false;
    }
  }

  cancelNew() {
    this.modelContainer = new cllContainer();

    $("#typeCombo").dropdown("clear");
    $("#sizeCombo").dropdown("clear");
    $("#sizeCombo").find(".menu").empty();
    $("#methodCombo").dropdown("clear");

    this.closeDialog("newAddContainer");
    this.gridNew.loadData();
    this.gridNew.clearSelectedValues();
  }

  removeContainer() {
    if (this.gridNew.getSelectedValues().length > 0) {
      this.gridNew.getSelectedValues().forEach((fe) => {
        this.gridNew.listStore.store.splice(
          this.gridNew.listStore.store.indexOf(fe),
          1
        );
      });

      this.gridNew.clearSelectedValues();
      this.gridNew.loadData();
    }
  }

  indicatorChange(val) {
    if (!val) {
      $("#methodCombo").dropdown("set selected", "");
    }
  }

  checkDigitCalculator() {
    // console.log("check digit start");
    var result: boolean = false;
    if (this.modelContainer.containerNo != "") {
      var containerLength = this.modelContainer.containerNo.length;
      var digit: number = 0;
      digit = this.genericUtil.calculateDigit(
        this.modelContainer.containerNo.trim().replace(" ", "").substr(0, 10)
      );

      if (digit == 10) {
        digit = 0;
      }

      if (
        Number(
          this.modelContainer.containerNo.substr(
            this.modelContainer.containerNo.length - 1
          )
        ) == digit
      ) {
        result = true;
      }
    }
    // console.log('check digit result : '+ result);

    return result;
  }

  calculateDigit(str: string) {
    var sumAllValues: number = 0.0;

    // totaling all values
    for (var i = 0; i < str.length; i++) {
      // console.log('char : '+this.characterMap[str.charAt(i)] * this.weightingFactors[i]);
      sumAllValues =
        sumAllValues +
        this.characterMap[str.charAt(i)] * this.weightingFactors[i];
    }
    // console.log("total all values : " + sumAllValues);

    // devide with 11
    var dev11: number = sumAllValues / 11;

    var mul11: number = Math.trunc(dev11) * 11;

    var digit: number = sumAllValues - mul11;

    // console.log("digit result : "+digit);

    return digit;
  }

  receiveCLLOpenDialog() {
    $("#receiveCLLDialogId")
      .dialog({
        title: "Receive CLL",
        modal: true,
        width: 1020,
        height: "auto",
        open: function (event, ui) {
          //hide close button.
          //// console.log($(this).css("z-index","102"));
          $(".ui-dialog").css("z-index", 103);
          $(".ui-widget-overlay").css("z-index", 102);
          $(this)
            .parent()
            .children()
            .children(".ui-dialog-titlebar-close")
            .hide();
          $("body").first().css("overflow", "hidden");
        },
        close: function (event, ui) {
          $("body").first().css("overflow", " auto");
        },
      })
      .dialog("open");

    this.uploadPlg.setDisabled(true);
  }

  uploadProcess() {
    var x = document.getElementById("myfile");
    x.click();
  }

  uploadCLL() {
    var uploadUrl = this.hitUrl + "/ContainerLoadList/upload";
    this.genericService.POST(uploadUrl, $("#myfile")).subscribe((resp) => {
      if (resp.ok) {
        // console.log("success");
      }
    });
  }

  closeUploadCLL() {
    this.uploadPlg.store = [];
    this.errorFileName = "";
    this.disableErrorButton = true;
    //this.gridCLLUpload.listStore.store.splice(0, this.gridCLLUpload.listStore.store.length);
    //this.cbVesselUpload.setValue("");
    //this.cbVoyageUpload.setValue("");
    this.closeDialog("receiveCLLDialogId");
    //$("#receiveCLLDialogId").dialog("close");
  }z

  saveNewContainerLoadList() {
    console.log("Sapi Grid New : ", this.gridNew.listStore.store);

    this.setNewFormValidation();

    this.isError = this.onValidate(this.modelNew);

    if (!this.isError) {
      if (this.gridNew.listStore.store.length > 0) {
        this.errorFormMessage = "";
        //this.modelNew.stackDateTime = this.cllSDT.getValue();
        this.modelNew.stackDateTime = $("#stdTime").val();
       // this.modelNew.cllReeferCollectionDate= $("#rfdTime").val();
        var saveData: cllHeader = this.populateData();

        var containerDetailsComplete: boolean = true;
        saveData.cllDetails.forEach((fe) => {
          if (fe.containerNo == "") {
            containerDetailsComplete = false;
          } else if (fe.containerWeight == 0 || fe.containerWeight == "") {
            containerDetailsComplete = false;
          } else if (!fe.containerVgmIndicator) {
            //containerDetailsComplete = false;
          }
        });

        if (containerDetailsComplete) {
          this.loadingDialog = true;
          if (this.newCLLSaveValidation(saveData)) {
            if (saveData.bookingRef != "") {
              var checkBookingUri =
                this.configService.config.BASE_API.toString() +
                "/ContainerLoadList/getBookingDataAll/" +
                saveData.bookingRef +
                "/" +
                this.office.officeCode +
                "/" +
                saveData.vesselID +
                "/" +
                this.sCharUtil.htmlEncode(String(saveData.vesselVoyage));
              this.genericService.GET(checkBookingUri).subscribe((resp) => {
                if (resp.ok) {
                  var cBkgResult = resp.json();
                  if (cBkgResult["result"] == "data not found") {
                    this.loadingDialog = false;
                    this.message(
                      "Information",
                      "information",
                      "Booking not found or invalid booking number!",
                      "okonly",
                      { ok: "" }
                    );
                  } else {
                    this.saveProcess(saveData);
                  }
                }
              });
            } else {
              this.saveProcess(saveData);
            }
          } else {
            this.loadingDialog = false;
          }
        } else {
          this.message(
            "Information",
            "information",
            "Please complete container details!",
            "okonly",
            { ok: "" }
          );
        }
      } else {
        this.message(
          "Information",
          "information",
          "Please insert at least 1 container!",
          "okonly",
          { ok: "" }
        );
        //this.errorFormMessage = "Please insert at least 1 container!";
      }
    } else {
      this.message(
        "Information",
        "information",
        "Please check your form error message!",
        "okonly",
        { ok: "" }
      );
    }
  }

  setNewFormValidation() {
    this.validatorRules = {
      nextVesselCode: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      nextVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
    };
  }

  saveProcess(saveData: any) {
    var hitUrl = this.hitUrl + "/ContainerLoadList/save";
    this.genericService.POST(hitUrl, saveData).subscribe(
      (resp) => {
        if (resp.ok) {
          // console.log(resp.json()['message']);
          var message = resp.json()["message"];
          if (message == "SUCCESS") {
            this.message(
              "information",
              "Information",
              "Record save sucessfully.",
              "okonly",
              { ok: "" }
            );
            this.loadingDialog = false;
            this.closeNewContainerLoadList("newDialog");
            //this.gridCLL.onSortDir("default","DESC");
            this.handleRetrieve("default;DESC");
          }
        }
      },
      (error) => {
        this.loadingDialog = false;
        this.message(
          "information",
          "Information",
          "There are some error occur while saving the record, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  checkNumberFormat(numValue, charBefor, charAfter) {
    var result: boolean = true;

    if (numValue < 0) {
      numValue = numValue * -1;
    }

    if (numValue.toString().includes(".")) {
      var dt = numValue.toString().split(".");
      if (dt.length > 2) {
        result = false;
      } else {
        if (dt[0].length > charBefor) {
          result = false;
        }
        if (dt[1].length > charAfter) {
          result = false;
        }
      }
    } else {
      if (numValue.toString().length > charBefor) {
        result = false;
      }
    }

    return result;
  }

  checkNumberLength(numValue, len) {
    var num = numValue.replace("-", "");
    // console.log("brmm");
    // console.log(num);
    var result = true;

    if (num.length != len) {
      result = false;
    }

    return result;
  }

  newCLLSaveValidation(data: cllHeader) {
    var result: boolean = false;
    var reeferStatus = false;
    var reeferDryStatus = false;
    var reeferTempFormat = true;
    var oogFormat = true;
    var oogErrorFormat = [];
    var ucStatus = false;
    var flrStatus = false;
    var pltStatus = false;
    var optStatus = false;
    var stdStatus = false;
    var tnkStatus = false;
    var containerTypeString = "";
    data.cllDetails.forEach((fe) => {
      if (fe.containerType == "REF") {
        console.log(" Reefer save 1 : "+fe.containerType);
        reeferStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "REEFER, ";
        } else {
          containerTypeString = "REEFER, ";
        }
      }
      if (fe.containerType == "DRY") {
        reeferDryStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "REEFER DRY, ";
        } else {
          containerTypeString = "REEFER DRY, ";
        }
      }
      if (fe.containerType == "UC") {
        ucStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "UC, ";
        } else {
          containerTypeString = "UC, ";
        }
      }
      if (fe.containerType == "FLR") {
        flrStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "FLAT RACK, ";
        } else {
          containerTypeString = "FLAT RACK, ";
        }
      }
      if (fe.containerType == "PLT") {
        optStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "PLATFORM, ";
        } else {
          containerTypeString = "PLATFORM, ";
        }
      }
      if (fe.containerType == "OPT") {
        optStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "OPEN TOP, ";
        } else {
          containerTypeString = "OPEN TOP, ";
        }
      }
      if (fe.containerType == "STD") {
        stdStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "NORMAL, ";
        } else {
          containerTypeString = "NORMAL, ";
        }
      }
      if (fe.containerType == "TNK") {
        tnkStatus = true;
        if (containerTypeString != "") {
          containerTypeString = containerTypeString + "TANK, ";
        } else {
          containerTypeString = "TANK, ";
        }
      }
    });

    containerTypeString = containerTypeString
      .trim()
      .substr(0, containerTypeString.length - 2);

    //console.log("saung " + (data.isOOG && (!(flrStatus || pltStatus || optStatus) || (stdStatus || reeferStatus || reeferDryStatus || tnkStatus))) )

    var allReefer: boolean = false;
    if (data.isReefer) {
      console.log(" Data Is Reefer Net : "+data.isReefer);
      allReefer = true;
      data.cllDetails.forEach((fx) => {
        
        if (fx.containerType != "REF") {
          allReefer = false;          
        }

        console.log(" All Is Reefer Net : "+allReefer);
        console.log(" fx.containerType : "+fx.containerType);
      });
    }

    var allDry: boolean = false;
    if (data.reeferAsDry) {
      allDry = true;
      data.cllDetails.forEach((fx) => {
        if (fx.containerType != "DRY") {
          allDry = false;
        }
      });
    }

    var allUC: boolean = false;
    if (data.isUC) {
      allUC = true;
      data.cllDetails.forEach((fx) => {
        if (fx.containerType != "UC") {
          allUC = false;
        }
      });
    }

    console.log("reeferStatus " + reeferStatus + " empty " + !data.isEmpty);
    if (reeferStatus && !data.isEmpty) {
      if (data.reeferTemp != "0") {
        if (!this.checkNumberFormat(data.reeferTemp, 3, 2)) {
          reeferTempFormat = false;
        }
      }
    }

    if (data.isOOG) {
      if (data.oh != "0") {
        if (!this.checkNumberFormat(data.oh, 3, 1)) {
          oogErrorFormat.push("oh");
        }
      }
      if (data.owl != "0") {
        if (!this.checkNumberFormat(data.owl, 3, 1)) {
          oogErrorFormat.push("owl");
        }
      }
      if (data.owr != "0") {
        if (!this.checkNumberFormat(data.owr, 3, 1)) {
          oogErrorFormat.push("owr");
        }
      }
      if (data.olf != "0") {
        if (!this.checkNumberFormat(data.olf, 3, 1)) {
          oogErrorFormat.push("olf");
        }
      }
      if (data.olb != "0") {
        if (!this.checkNumberFormat(data.olb, 3, 1)) {
          oogErrorFormat.push("olb");
        }
      }

      if (oogErrorFormat.length > 0) {
        oogFormat = false;
      }
    }

    if (data.opCode == "") {
      this.message("Information", "information", "Please input OP.", "okonly", {
        ok: "",
      });
    } else if (data.soCode == "") {
      this.message("Information", "information", "Please input SO.", "okonly", {
        ok: "",
      });
    } else if (data.polCode == "") {
      this.message(
        "Information",
        "information",
        "Please input Port of load.",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.pod1Code == "" &&
      data.pod2Code == "" &&
      data.pod3Code == ""
    ) {
      this.message(
        "Information",
        "information",
        "Please input Port of discharge.",
        "okonly",
        { ok: "" }
      );
    } else if (data.isTranship && data.potCode == "") {
      this.message(
        "Information",
        "information",
        "Please input Port of Tranship.",
        "okonly",
        { ok: "" }
      );
    } else if (data.isReefer && !allReefer) {
      this.message(
        "Information",
        "information",
        "Reefer indicator is detected. Please input reefer container type only.",
        "okonly",
        { ok: "" }
      );
    } else if (data.reeferAsDry && !allDry) {
      this.message(
        "Information",
        "information",
        "Reefer DRY indicator is detected. Please input reefer dry container type only.",
        "okonly",
        { ok: "" }
      );
    } else if (reeferStatus && !data.isReefer) {
      this.message(
        "Information",
        "information",
        "Please insert reefer details.",
        "okonly",
        { ok: "" }
      );
    } else if (reeferDryStatus && !data.reeferAsDry) {
      this.message(
        "Information",
        "information",
        "Please input reefer dry indicator.",
        "okonly",
        { ok: "" }
      );
      //} else if(reeferStatus && parseFloat(data.reeferTemp) == 0 && !data.isEmpty){
      //  this.message("Information","information", "Please input reefer temperature.","okonly",{ok:""});
    } else if (
      reeferStatus &&
      (data.reeferScale == "" || data.reeferScale == undefined) &&
      !data.isEmpty
    ) {
      this.message(
        "Information",
        "information",
        "Please input reefer scale.",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isDG &&
      data.imo == "" &&
      data.unno == "" &&
      !data.isEmpty /*|| data.dgClass == ""*/
    ) {
      this.message(
        "Information",
        "information",
        "Please input dg details.",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      parseFloat(data.oh) == 0 &&
      parseFloat(data.owl) == 0 &&
      parseFloat(data.owr) == 0 &&
      parseFloat(data.olf) == 0 &&
      parseFloat(data.olb) == 0 &&
      !data.isEmpty
    ) {
      this.message(
        "Information",
        "information",
        "Please input oog details.",
        "okonly",
        { ok: "" }
      );
      //} else if(data.isUC && (data.uch == 0 && data.ucl == 0 && data.ucw == 0)){
      //this.message("Information","information", "Please input uc details.","okonly",{ok:""});
      //} else if(!reeferTempFormat){
      //this.message("Information","information", "Incorrect reefer temperature format. (Eg. ###.##)","okonly",{ok:""});
    } else if (
      data.isReefer &&
      !this.checkNumberLength(data.reeferTemp, 6) &&
      !data.isEmpty
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect reefer temperature format. (Eg. ###.##)",
        "okonly",
        { ok: "" }
      );
      //} else if(data.isDG && !this.checkNumberLength(data.flashPointTemp,6) && parseFloat(data.flashPointTemp) != 0){
      //this.message("Information","information", "Incorrect Flash Point temperature format.","okonly",{ok:""});
    } else if (
      data.isOOG &&
      parseFloat(data.oh) != 0 &&
      !this.checkNumberLength(data.oh.toString(), 5)
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect OOG format. (Eg. ###.#)",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      parseFloat(data.owl) != 0 &&
      !this.checkNumberLength(data.owl.toString(), 5)
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect OOG format. (Eg. ###.#)",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      parseFloat(data.owr) != 0 &&
      !this.checkNumberLength(data.owr.toString(), 5)
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect OOG format. (Eg. ###.#)",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      parseFloat(data.olf) != 0 &&
      !this.checkNumberLength(data.olf.toString(), 5)
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect OOG format. (Eg. ###.#)",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      parseFloat(data.olb) != 0 &&
      !this.checkNumberLength(data.olb.toString(), 5)
    ) {
      this.message(
        "Information",
        "information",
        "Incorrect OOG format. (Eg. ###.#)",
        "okonly",
        { ok: "" }
      );
    } else if (this.checkDate(data.stackDateTime)) {
      this.message(
        "Information",
        "information",
        "Incorrect stack datetime format. (Eg. dd/mm/yyyy hh:mm)",
        "okonly",
        { ok: "" }
      );
    } else if (!reeferStatus && data.isReefer) {
      this.message(
        "Information",
        "information",
        "Invalid data inputed. No reefer container detected, but reefer indicator is true",
        "okonly",
        { ok: "" }
      );
    } else if (ucStatus && !data.isUC) {
      this.message(
        "Information",
        "information",
        "Please input UC Details",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isUC &&
      (data.uch == null ||
        data.ucw == 0 ||
        data.ucl == null ||
        data.ucl == 0 ||
        data.ucw == null ||
        data.ucw == 0)
    ) {
      this.message(
        "Information",
        "information",
        "Please complete UC Details",
        "okonly",
        { ok: "" }
      );
    } else if (data.isUC && !allUC) {
      this.message(
        "Information",
        "information",
        "UC Indicator is detected. Please input UC container type only",
        "okonly",
        { ok: "" }
      );
    } else if (
      !data.isTranship &&
      ((data.nextVesselCode != null && data.nextVesselCode != "") ||
        (data.nextVesselVoyage != null && data.nextVesselVoyage != ""))
    ) {
      this.message(
        "Information",
        "information",
        "Next vessel detected. Missing port of tranship & tranship indicator",
        "okonly",
        { ok: "" }
      );
    } else if (
      data.isOOG &&
      (!(flrStatus || pltStatus || optStatus) ||
        stdStatus ||
        reeferStatus ||
        reeferDryStatus ||
        tnkStatus)
    ) {
      this.message(
        "Information",
        "information",
        "OOG Indicator is not applicable for " +
          containerTypeString +
          " container(s).<br>OOG Indicator is applicable for FLAT RACK, PLATFORM, or OPEN TOP container(s) only. <br><br>Please check your data again!",
        "okonly",
        { ok: "" }
      );
    } else {
      result = true;
    }

    return result;
  }

  checkDate(data) {
    var invalidDate = false;
    if (data != "") {
      var dateTime = data
        .replace("d", "")
        .replace("m", "")
        .replace("y", "")
        .replace("h", "");

      if (dateTime.length != 16) {
        invalidDate = true;
      } else {
        var checkDate = dateTime.split(" ")[0];
        var checkTime = dateTime.split(" ")[1];

        var datePart = checkDate.split("/");
        var timePart = checkTime.split(":");

        //check date first
        if (datePart[0].length > 2 || datePart[1].length > 2) {
          console.log("#1");
          invalidDate = true;
        } else if (datePart[2].length > 4 || datePart[2].length < 4) {
          console.log("#2");
          invalidDate = true;
        }

        if (
          parseInt(datePart[0]) >
          this.getTotalDayPerMonth(datePart[1], datePart[2])
        ) {
          console.log("#3");
          invalidDate = true;
        } else if (parseInt(datePart[1]) > 12) {
          console.log("#4");
          invalidDate = true;
        }

        if (!invalidDate) {
          if (parseInt(timePart[0]) > 24) {
            console.log("#5");
            invalidDate = true;
          } else if (parseInt(timePart[1]) > 59) {
            console.log("#6");
            invalidDate = true;
          }
        }
      }
    }

    return invalidDate;
  }

  getTotalDayPerMonth(monthIndex, monthYear) {
    var isKabisat = false;
    if (monthYear % 4 == 0) {
      isKabisat = true;
    }

    var monthEndDay = 0;
    switch (parseInt(monthIndex)) {
      case 1:
        monthEndDay = 31;
        break;
      case 2:
        if (isKabisat) {
          monthEndDay = 29;
        } else {
          monthEndDay = 28;
        }
        break;
      case 3:
        monthEndDay = 31;
        break;
      case 4:
        monthEndDay = 30;
        break;
      case 5:
        monthEndDay = 31;
        break;
      case 6:
        monthEndDay = 30;
        break;
      case 7:
        monthEndDay = 31;
        break;
      case 8:
        monthEndDay = 31;
        break;
      case 9:
        monthEndDay = 30;
        break;
      case 10:
        monthEndDay = 31;
        break;
      case 11:
        monthEndDay = 30;
        break;
      case 12:
        monthEndDay = 31;
        break;
    }

    return monthEndDay;
  }

  populateData() {
    var saveData = new cllHeader();
    saveData.processID = this.modelNew.processID;
    saveData.vesselID = this.modelNew.vesselId;
    saveData.vesselCode = this.modelNew.vesselCode;
    saveData.vesselVoyage = this.modelNew.voyage;
    saveData.vesselBound = this.modelNew.bound;
    saveData.isEmpty = this.modelNew.status == "FULL" ? false : true;
    saveData.isTranship = this.modelNew.tranship == "Y" ? true : false;
    saveData.isThruSpt = this.modelNew.thruSpt == "Y" ? true : false;
    saveData.stackDateTime = this.modelNew.stackDateTime; //this.cllSDT.getValue();
    saveData.bookingRef = this.modelNew.bNo.toUpperCase();
    saveData.blRef = this.modelNew.blNo.toUpperCase();
    saveData.polCode = this.modelNew.loadPort;
    saveData.pod1Code = this.modelNew.discPort1;
    saveData.pod2Code = this.modelNew.discPort2;
    saveData.pod3Code = this.modelNew.discPort3;
    saveData.fDestCode = this.modelNew.finalDest;
    saveData.potCode = this.modelNew.transhipPort;
    saveData.nextVesselID = this.modelNew.nextVesselID;
    saveData.nextVesselCode = this.modelNew.nextVesselCode.toUpperCase();
    saveData.nextVesselVoyage = this.modelNew.nextVoyage.toUpperCase();
    saveData.shipperID = this.modelNew.shipperID;
    saveData.shipperName = this.modelNew.shipperName;
    saveData.commodityId = this.modelNew.commodityId;
    saveData.commodityName = this.modelNew.commodityName.toUpperCase();
    saveData.vgmStatus = false; //this.modelNew.vgmStatus;
    saveData.vgmMethod = ""; //$("#vgmCombo").dropdown("get value");
    saveData.vgmShipper = ""; //this.modelNew.vgmShipper.toUpperCase();
    saveData.vgmTerminal = ""; //this.modelNew.vgmTerminal.toUpperCase();
    saveData.officeCode = this.office.officeCode;
    saveData.isValid = this.modelNew.isValid;
    if (saveData.processID == "") {
      saveData.userCreated = this.userID;
      saveData.createdDate = this.genericUtil.getPSIDate();
      saveData.userModified = "";
      saveData.modifiedDate = "";
      saveData.userInvalidate = "";
      saveData.invalidateDate = "";
    } else {
      saveData.userCreated = this.modelNew.userId;
      saveData.createdDate = this.modelNew.dateCreated;
      saveData.userModified = this.userID;
      saveData.modifiedDate = this.genericUtil.getPSIDate();
      saveData.userInvalidate = this.modelNew.userInvalidate;
      saveData.invalidateDate = this.modelNew.invalidateDate;
    }
    saveData.isTransmitted = false;
    saveData.userTransmitted = "";
    console.log("joke");
    console.log(this.isAutoCreate);
    if (this.isAutoCreate) {
      saveData.transmittedDate = "U";
    }
    saveData.other = "";
    saveData.isBundle = this.modelNew.bundle;
    saveData.isReefer = this.modelNew.reefer;
    saveData.reeferAsDry = this.modelNew.reeferAsDry;
    // console.log("kang tahu");
    // console.log(this.modelNew.oh);
    if (!saveData.isEmpty) {
      if (saveData.isReefer) {
        saveData.reeferTemp =
          (this.modelNew.tempSign == "+" ? "" : "-") + this.modelNew.reeferTemp;
        saveData.reeferScale = this.modelNew.tempDegree;
      } else {
        saveData.reeferTemp = "0";
        saveData.reeferScale = "";
      }
    } else {
      if (saveData.isReefer) {
        if (this.modelNew.reeferTemp.toString() != "") {
          saveData.reeferTemp =
            (this.modelNew.tempSign == "+" ? "" : "-") +
            this.modelNew.reeferTemp;
          saveData.reeferScale = this.modelNew.tempDegree;
        } else {
          saveData.reeferTemp = "0";
          saveData.reeferScale = "";
          saveData.vgmShipper = "blank";
        }
      } else {
        saveData.reeferTemp = "0";
        saveData.reeferScale = "";
      }
    }
    //saveData.reeferTemp = (this.modelNew.tempSign == "+" ? "" : "-") + this.modelNew.reeferTemp;
    //saveData.reeferScale = this.modelNew.tempScale;
    saveData.isDG = this.modelNew.dg;
    saveData.dgClass = this.modelNew.dgClass.toUpperCase();
    saveData.imo = this.modelNew.imo;
    saveData.unno = this.modelNew.unno;
    if (saveData.isDG) {
      saveData.flashPointTemp =
        (this.modelNew.flashSign == "+" ? "" : "-") + this.modelNew.flashPoint;
      saveData.flashPointScale = this.modelNew.flashScale;
    } else {
      saveData.flashPointTemp = "0";
      saveData.flashPointScale = "";
    }
    //saveData.flashPointTemp = (this.modelNew.flashSign == "+" ? "" : "-") + this.modelNew.flashPoint;
    //saveData.flashPointScale = this.modelNew.flashScale;
    saveData.packageTypeId = this.modelNew.pkgTypeId;
    saveData.packageType = this.modelNew.pkgTypeName.toUpperCase();
    saveData.isOOG = this.modelNew.oog;
    saveData.oh = this.modelNew.oh;
    saveData.ohScale = this.modelNew.ohScale;
    saveData.owl = this.modelNew.owl;
    saveData.owr = this.modelNew.owr;
    saveData.olf = this.modelNew.olf;
    saveData.olb = this.modelNew.olb;
    saveData.isUC = this.modelNew.uc;
    saveData.uch = this.modelNew.uch;
    saveData.uchScale = this.modelNew.uchScale;
    saveData.ucl = this.modelNew.ucl;
    saveData.uclScale = this.modelNew.uclScale;
    saveData.ucw = this.modelNew.ucw;
    saveData.ucwScale = this.modelNew.ucwScale;
    saveData.cgoDesc = ""; //this.modelNew.cargoDescription.toUpperCase();
    saveData.spDetailsId = this.modelNew.spDetailsId;
    saveData.spDetails = this.modelNew.spDetails.toUpperCase();
    saveData.remarks = this.modelNew.remarks.toUpperCase();
    saveData.opCode = this.modelNew.operatorCode.toUpperCase();
    saveData.soCode = this.modelNew.inSlot.toUpperCase();
    saveData.reeferCommodityDesc =
      this.modelNew.reeferCommodityDesc.toUpperCase();
    saveData.dgCommodityDesc = this.modelNew.dgCommodityDesc.toUpperCase();

    saveData.cllReeferDegree = this.modelNew.cllReeferDegree;
    saveData.cllDegreeSo = this.modelNew.cllDegreeSo;
    saveData.cllNatureTemp = this.modelNew.cllNatureTemp;
    saveData.cllSetOption = this.modelNew.cllSetOption;
   // saveData.cllReeferCollectionDate = this.modelNew.cllReeferCollectionDate;
    saveData.cllIsDry = this.modelNew.cllIsDry;
    saveData.cllVent = this.modelNew.cllVent;
    saveData.cllVentNumber = this.modelNew.cllVentNumber;
    saveData.cllVentOption = this.modelNew.cllVentOption;
    saveData.cllHumidity = this.modelNew.cllHumidity;

    this.gridNew.listStore.store.forEach((each) => {
      saveData.cllDetails.push(each);
    });

    return saveData;
  }

  handleClose() {
    this.modelForm = new cllForm();
    this.modelNew = new cllGrid();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    //this.cllEta.resetCalendar();
    //this.cllEtd.resetCalendar();
    this.cbPOL.setValue("");
    this.cbPOD.setValue("");
    this.cbFD.setValue("");
    //this.gridCLL.onClear();

    this.nGridStore = [];

    this.newDialogFirstInit = false;
    this.selectAllContainers = false;
    $("#checkAllContainer").checkbox("set unchecked");
    this.disableChkBoxAllContainer = true;

    this.totalD20 = 0;
    this.totalR20 = 0;
    this.totalFull = 0;
    this.totalPL20 = 0;
    this.totalTKH45 = 0;
    this.totalD40 = 0;
    this.totalR40 = 0;
    this.totalEmpty = 0;
    this.totalPL40 = 0;
    this.totalTKH20 = 0;
    this.totalD45 = 0;
    this.totalR45 = 0;
    this.totalDG = 0;
    this.totalPL45 = 0;
    this.totalTKH40 = 0;
    this.totalHC20 = 0;
    this.totalRH20 = 0;
    this.totalRF = 0;
    this.totalOT45 = 0;
    this.totalTKH45 = 0;
    this.totalHC40 = 0;
    this.totalRH40 = 0;
    this.totalOOG = 0;
    this.totalFR20 = 0;
    this.totalTank = 0;
    this.totalHC45 = 0;
    this.totalRH45 = 0;
    this.totalTK20 = 0;
    this.totalFR40 = 0;
    this.totalFinal = 0;
    this.totalOT20 = 0;
    this.totalOT40 = 0;
    this.totalTK40 = 0;
    this.totalFR45 = 0;

    this.totalOTH20 = 0;
    this.totalOTH40 = 0;
    this.totalOTH45 = 0;
    this.totalFRH20 = 0;
    this.totalFRH40 = 0;
    this.totalFRH45 = 0;
    this.totalPLH20 = 0;
    this.totalPLH40 = 0;
    this.totalPLH45 = 0;

    this.nGridApi.refreshCells();

    $("#newDialog")
      .find(".content")
      .first()
      .find("#firstForm")
      .css("display", "block");
    $("#newDialog")
      .find(".content")
      .first()
      .find("#secondForm")
      .css("display", "none");

    $("html, body").scrollTop(0);
  }

  handleDelete() {
    this.loading = true;
    var postData = [];
    var actionDate = this.genericUtil.getPSIDate();

    if (this.selectAllContainers) {
      this.cllFlagStatusContainerList.forEach((fe) => {
        var eachPost = {};
        eachPost["processId"] = fe.processId;
        eachPost["containerNo"] = fe.containerNo;
        eachPost["modifiedDate"] = actionDate;
        eachPost["modifiedUserId"] = this.userID;

        postData.push(eachPost);
      });
    } else {
      //if(this.gridCLL.getSelectedValues().length > 0){
      if (this.nGridApi.getSelectedRows().length > 0) {
        this.nGridApi.getSelectedRows().forEach((fe) => {
          var eachPost = {};
          eachPost["processId"] = fe.processID;
          eachPost["containerNo"] = fe.containerNo;
          eachPost["modifiedDate"] = actionDate;
          eachPost["modifiedUserId"] = this.userID;

          postData.push(eachPost);
        });
      }
    }

    // console.log("delete process");
    // console.log(postData);

    var hitUrl = this.hitUrl + "/ContainerLoadList/delete";
    this.genericService.POST(hitUrl, postData).subscribe(
      (resp) => {
        if (resp.ok) {
          if (resp.json()["message"] == "success") {
            this.loading = false;
            //this.gridCLL.clearSelectedValues();
            this.nGridApi.deselectAll();
            this.message(
              "information",
              "Information",
              "Record(s) deleted.",
              "okonly",
              { ok: "this.handleRetrieve();" }
            );
            this.selectAllContainers = false;
            $("#checkAllContainer").checkbox("set unchecked");
          }
        }
      },
      (error) => {
        this.message(
          "Information",
          "information",
          "An Error Occured while retreiving data, please contact your administrator!",
          "okonly",
          { ok: "" }
        );
        this.loading = false;
      }
    );
  }

  retrieveAfterUpload() {
    this.gridCLL.loading = true;

    this.gridCLL.onClear();
    this.gridCLL.clearSelectedValues();

    this.gridCLL.search =
      this.modelForm.vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage).trim()) +
      "/" +
      this.modelForm.vesselBound.toUpperCase() +
      "/" +
      (this.modelForm.portLoadCode != "" ? this.modelForm.portLoadCode : "*") +
      "/" +
      (this.modelForm.portDischargeCode != ""
        ? this.modelForm.portDischargeCode
        : "*") +
      "/" +
      (this.modelForm.finalDestinationCode != ""
        ? this.modelForm.finalDestinationCode
        : "*") +
      "/" +
      (this.modelForm.containerNo != ""
        ? this.modelForm.containerNo.toUpperCase()
        : "*") +
      "/" +
      (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
      "/" +
      (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
      "/" +
      this.office.officeCode;

    this.gridCLL.onSortDir("id", "ASC");
  }

  handleRetrieve(sort?: string) {
    //console.log("this run");
    /*this.gridCLL.loading = true;

    this.gridCLL.onClear();
    this.gridCLL.clearSelectedValues();

    this.gridCLL.search = this.modelForm.vesselId+"/"+this.modelForm.baseVoyage+"/"+this.modelForm.vesselBound.toUpperCase()+"/"+(this.modelForm.portLoadCode != "" ? this.modelForm.portLoadCode : "*")+"/"+(this.modelForm.portDischargeCode != "" ? this.modelForm.portDischargeCode : "*")+"/"+(this.modelForm.finalDestinationCode != "" ? this.modelForm.finalDestinationCode : "*")+"/"+(this.modelForm.containerNo != "" ? this.modelForm.containerNo.toUpperCase() : "*")+"/"+(this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*")+"/"+(this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*")+"/"+this.office.officeCode;

    if(sort != undefined){
      var dt = sort.split(";");
      this.gridCLL.onSortDir(dt[0], dt[1]);
    } else {
      this.gridCLL.loadData();
    }*/

    //var url = this.configService.config.BASE_API.toString() + "/ContainerLoadList/findCllFlagStatus/"+this.modelForm.vesselId+"/"+this.modelForm.baseVoyage+"/"+this.modelForm.vesselBound.toUpperCase()+"/"+(this.modelForm.portLoadCode != "" ? this.modelForm.portLoadCode : "*")+"/"+(this.modelForm.portDischargeCode != "" ? this.modelForm.portDischargeCode : "*")+"/"+(this.modelForm.finalDestinationCode != "" ? this.modelForm.finalDestinationCode : "*")+"/"+(this.modelForm.containerNo != "" ? this.modelForm.containerNo.toUpperCase() : "*")+"/"+(this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*")+"/"+(this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*")+"/"+this.office.officeCode+"/1/10000/ASC/*";
    var url =
      this.configService.config.BASE_API.toString() +
      "/ContainerLoadList/findCllFlagStatus/" +
      this.modelForm.vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage).trim()) +
      "/" +
      this.modelForm.vesselBound.toUpperCase() +
      "/" +
      (this.modelForm.portLoadCode != "" ? this.modelForm.portLoadCode : "*") +
      "/" +
      (this.modelForm.portDischargeCode != ""
        ? this.modelForm.portDischargeCode
        : "*") +
      "/" +
      (this.modelForm.finalDestinationCode != ""
        ? this.modelForm.finalDestinationCode
        : "*") +
      "/" +
      (this.modelForm.containerNo != ""
        ? this.modelForm.containerNo.toUpperCase()
        : "*") +
      "/" +
      (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
      "/" +
      (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
      "/" +
      this.office.officeCode +
      "/" +
      (this.modelForm.bookingNo != "" ? this.modelForm.bookingNo : "*") +
      "/1/10000/ASC/*";
    this.nGridApi.showLoadingOverlay();

    this.genericService.GET(url).subscribe((resp) => {
      if (resp.ok) {
        this.nGridStore = resp.json()["content"];
        // patch cll flag status
        var dataSum = resp.json()["summary"]["summary"];
        var allContainerDataList = resp.json()["summary"]["containerDataList"];
        if (allContainerDataList.length > 0) {
          allContainerDataList.forEach((f) => {
            this.cllFlagStatusContainerList.push(f);
          });
        }

        this.totalD20 = parseInt(dataSum["D20"]);
        this.totalD40 = parseInt(dataSum["D40"]);
        this.totalD45 = parseInt(dataSum["D45"]);
        this.totalHC20 = parseInt(dataSum["HC20"]);
        this.totalHC40 = parseInt(dataSum["HC40"]);
        this.totalHC45 = parseInt(dataSum["HC45"]);

        this.totalR20 = parseInt(dataSum["R20"]);
        this.totalR40 = parseInt(dataSum["R40"]);
        this.totalR45 = parseInt(dataSum["R45"]);
        this.totalRH20 = parseInt(dataSum["RH20"]);
        this.totalRH40 = parseInt(dataSum["RH40"]);
        this.totalRH45 = parseInt(dataSum["RH45"]);

        this.totalTK20 = parseInt(dataSum["TK20"]);
        this.totalTK40 = parseInt(dataSum["TK40"]);
        this.totalTK45 = parseInt(dataSum["TK45"]);
        this.totalTKH20 = parseInt(dataSum["TKH20"]);
        this.totalTKH40 = parseInt(dataSum["TKH40"]);
        this.totalTKH45 = parseInt(dataSum["TKH45"]);

        this.totalFull = parseInt(dataSum["FULL"]);
        this.totalEmpty = parseInt(dataSum["EMPTY"]);
        this.totalDG = parseInt(dataSum["DG"]);
        this.totalRF = parseInt(dataSum["RF"]);
        this.totalOOG = parseInt(dataSum["OOG"]);
        this.totalTank = parseInt(dataSum["TK"]);

        this.totalOT20 = parseInt(
          dataSum["OT20"] != null && dataSum["OT20"] !== undefined
            ? dataSum["OT20"]
            : 0
        );
        this.totalOT40 = parseInt(
          dataSum["OT40"] != null && dataSum["OT40"] !== undefined
            ? dataSum["OT40"]
            : 0
        );
        this.totalOT45 = parseInt(
          dataSum["OT45"] != null && dataSum["OT45"] !== undefined
            ? dataSum["OT45"]
            : 0
        );

        this.totalFR20 = parseInt(
          dataSum["FR20"] != null && dataSum["FR20"] !== undefined
            ? dataSum["FR20"]
            : 0
        );
        this.totalFR40 = parseInt(
          dataSum["FR40"] != null && dataSum["FR40"] !== undefined
            ? dataSum["FR40"]
            : 0
        );
        this.totalFR45 = parseInt(
          dataSum["FR45"] != null && dataSum["FR45"] !== undefined
            ? dataSum["FR45"]
            : 0
        );

        this.totalPL20 = parseInt(
          dataSum["PL20"] != null && dataSum["PL20"] !== undefined
            ? dataSum["PL20"]
            : 0
        );
        this.totalPL40 = parseInt(
          dataSum["PL40"] != null && dataSum["PL40"] !== undefined
            ? dataSum["PL40"]
            : 0
        );
        this.totalPL45 = parseInt(
          dataSum["PL45"] != null && dataSum["PL45"] !== undefined
            ? dataSum["PL45"]
            : 0
        );

        this.totalOTH20 = parseInt(
          dataSum["OTH20"] != null && dataSum["OTH20"] !== undefined
            ? dataSum["OTH20"]
            : 0
        );
        this.totalOTH40 = parseInt(
          dataSum["OTH40"] != null && dataSum["OTH40"] !== undefined
            ? dataSum["OTH40"]
            : 0
        );
        this.totalOTH45 = parseInt(
          dataSum["OTH45"] != null && dataSum["OTH45"] !== undefined
            ? dataSum["OTH45"]
            : 0
        );

        this.totalFRH20 = parseInt(
          dataSum["FRH20"] != null && dataSum["FRH20"] !== undefined
            ? dataSum["FRH20"]
            : 0
        );
        this.totalFRH40 = parseInt(
          dataSum["FRH40"] != null && dataSum["FRH40"] !== undefined
            ? dataSum["FRH40"]
            : 0
        );
        this.totalFRH45 = parseInt(
          dataSum["FRH45"] != null && dataSum["FRH45"] !== undefined
            ? dataSum["FRH45"]
            : 0
        );

        this.totalPLH20 = parseInt(
          dataSum["PLH20"] != null && dataSum["PLH20"] !== undefined
            ? dataSum["PLH20"]
            : 0
        );
        this.totalPLH40 = parseInt(
          dataSum["PLH40"] != null && dataSum["PLH40"] !== undefined
            ? dataSum["PLH40"]
            : 0
        );
        this.totalPLH45 = parseInt(
          dataSum["PLH45"] != null && dataSum["PLH45"] !== undefined
            ? dataSum["PLH45"]
            : 0
        );

        this.totalFinal = parseInt(
          dataSum["totalAll"] != null && dataSum["totalAll"] !== undefined
            ? dataSum["totalAll"]
            : 0
        );

        if (this.nGridStore.length == 0) {
          if (!this.uploadEventDiscrepancy) {
            this.message(
              "Information",
              "information",
              "No record(s) found.",
              "okonly",
              { ok: "" }
            );
          } else {
            this.uploadEventDiscrepancy = false;
          }
        }

        /*var allColumnIds = [];
        this.nGridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });*/

        //this.nGridColumnApi.autoSizeColumns(allColumnIds);
        this.nGridApi.hideOverlay();

        var summaryUri =
          this.hitUrl +
          "/ContainerLoadList/getSummaryCllFlagStatus/" +
          this.modelForm.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
          "/" +
          this.modelForm.vesselBound.toUpperCase() +
          "/" +
          (this.modelForm.portLoadCode != ""
            ? this.modelForm.portLoadCode
            : "*") +
          "/" +
          (this.modelForm.portDischargeCode != ""
            ? this.modelForm.portDischargeCode
            : "*") +
          "/" +
          (this.modelForm.finalDestinationCode != ""
            ? this.modelForm.finalDestinationCode
            : "*") +
          "/" +
          (this.modelForm.containerNo != ""
            ? this.modelForm.containerNo.toUpperCase()
            : "*") +
          "/" +
          (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
          "/" +
          (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
          "/" +
          this.office.officeCode;
        /*this.genericService.GET(summaryUri).subscribe((resp)=>{
          if(resp.ok){
            var dataSum = resp.json()["content"]["summary"];
            var allContainerDataList = resp.json()["content"]["containerDataList"];
            if(allContainerDataList.length > 0){
              allContainerDataList.forEach(f=>{
                this.cllFlagStatusContainerList.push(f);
              });
            }

            this.totalD20 = parseInt(dataSum["D20"]);
            this.totalD40 = parseInt(dataSum["D40"]);
            this.totalD45 = parseInt(dataSum["D45"]);
            this.totalHC20 = parseInt(dataSum["HC20"]);
            this.totalHC40 = parseInt(dataSum["HC40"]);
            this.totalHC45 = parseInt(dataSum["HC45"]);

            this.totalR20 = parseInt(dataSum["R20"]);
            this.totalR40 = parseInt(dataSum["R40"]);
            this.totalR45 = parseInt(dataSum["R45"]);
            this.totalRH20 = parseInt(dataSum["RH20"]);
            this.totalRH40 = parseInt(dataSum["RH40"]);
            this.totalRH45 = parseInt(dataSum["RH45"]);

            this.totalFull = parseInt(dataSum["FULL"]);
            this.totalEmpty = parseInt(dataSum["EMPTY"]);
            this.totalDG = parseInt(dataSum["DG"]);
            this.totalRF = parseInt(dataSum["RF"]);
            this.totalOOG = parseInt(dataSum["OOG"]);
            this.totalTK = parseInt(dataSum["TK"]);

            if(this.nGridStore.length == 0){
              if(!this.uploadEventDiscrepancy){
                this.message("Information","information","No record(s) found.","okonly",{ok:""});
              } else {
                this.uploadEventDiscrepancy = false;
              }
            }

            this.nGridApi.hideOverlay();

          }
        }, error=>{
          if(this.nGridStore.length == 0){
            this.message("Information","information","No record(s) found.","okonly",{ok:""});
          }

          this.nGridApi.hideOverlay();
        });*/
      }
    });
  }

  handleRetrieveOld(uploadSeq?: string) {
    // console.log(this.modelForm);
    var hitURL = "";
    if (uploadSeq != undefined) {
      hitURL =
        this.hitUrl +
        "/ContainerLoadList/findGeneral/" +
        this.modelForm.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
        "/" +
        this.modelForm.vesselBound.toUpperCase() +
        "/" +
        (this.modelForm.portLoadCode != ""
          ? this.modelForm.portLoadCode
          : "*") +
        "/" +
        (this.modelForm.portDischargeCode != ""
          ? this.modelForm.portDischargeCode
          : "*") +
        "/" +
        (this.modelForm.finalDestinationCode != ""
          ? this.modelForm.finalDestinationCode
          : "*") +
        "/" +
        (this.modelForm.containerNo != ""
          ? this.modelForm.containerNo.toUpperCase()
          : "*") +
        "/" +
        (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
        "/" +
        (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
        "/" +
        this.office.officeCode +
        "/" +
        this.sortField +
        "/" +
        this.direction +
        "/" +
        uploadSeq;
    } else {
      hitURL =
        this.hitUrl +
        "/ContainerLoadList/findGeneral/" +
        this.modelForm.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
        "/" +
        this.modelForm.vesselBound.toUpperCase() +
        "/" +
        (this.modelForm.portLoadCode != ""
          ? this.modelForm.portLoadCode
          : "*") +
        "/" +
        (this.modelForm.portDischargeCode != ""
          ? this.modelForm.portDischargeCode
          : "*") +
        "/" +
        (this.modelForm.finalDestinationCode != ""
          ? this.modelForm.finalDestinationCode
          : "*") +
        "/" +
        (this.modelForm.containerNo != ""
          ? this.modelForm.containerNo.toUpperCase()
          : "*") +
        "/" +
        (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
        "/" +
        (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
        "/" +
        this.office.officeCode +
        "/" +
        this.sortField +
        "/" +
        this.direction;
    }
    this.gridCLL.loading = true;
    this.gridCLL.onClear();
    this.gridCLL.clearSelectedValues();
    this.genericService.GET(hitURL).subscribe((resp) => {
      if (resp.ok) {
        // console.log(resp.json());
        var dataresp = [];
        var result = null;
        dataresp = resp.json()["content"];
        var dataSum = resp.json()["summary"];
        var i = 1;
        dataresp.forEach((data) => {
          result = this.setDataMainGrid2(data);
          result["seq"] = i;
          this.gridCLL.listStore.store.push(result);
          i++;
        });

        this.totalD20 = parseInt(dataSum["D20"]);
        this.totalD40 = parseInt(dataSum["D40"]);
        this.totalD45 = parseInt(dataSum["D45"]);
        this.totalHC20 = parseInt(dataSum["HC20"]);
        this.totalHC40 = parseInt(dataSum["HC40"]);
        this.totalHC45 = parseInt(dataSum["HC45"]);

        this.totalR20 = parseInt(dataSum["R20"]);
        this.totalR40 = parseInt(dataSum["R40"]);
        this.totalR45 = parseInt(dataSum["R45"]);
        this.totalRH20 = parseInt(dataSum["RH20"]);
        this.totalRH40 = parseInt(dataSum["RH40"]);
        this.totalRH45 = parseInt(dataSum["RH45"]);

        this.totalFull = parseInt(dataSum["FULL"]);
        this.totalEmpty = parseInt(dataSum["EMPTY"]);
        this.totalDG = parseInt(dataSum["DG"]);
        this.totalRF = parseInt(dataSum["RF"]);
        this.totalOOG = parseInt(dataSum["OOG"]);
        this.totalTank = parseInt(dataSum["TK"]);
        if (this.sortMode) {
          this.sortMode = false;
          this.sortField = "*";
          this.direction = "*";
        }

        this.gridCLL.loadData();
        this.gridCLL.loading = false;
      }
    });
  }

  setDataMainGrid(data: any) {
    var result = new cllGrid();
    result.processID = data["cllProcessId"];
    result.bNo = data["cllBookingRef"];
    result.blNo = data["cllBlRef"];
    result.operatorCode = data["cllOpCode"];
    result.inSlot = data["cllSoCode"];
    result.status = data["cllIsEmpty"] == "Y" ? "F" : "E";
    result.tranship = data["cllIsTranship"];
    result.loadPort = data["cllPolCode"];
    result.transhipPort = data["cllPotCode"];
    result.discPort1 = data["cllPod1Code"];
    result.discPort2 = data["cllPod2Code"];
    result.discPort3 = data["cllPod3Code"];
    result.finalDest = data["cllFdestCode"];
    result.nextVesselName = data["nextVesselName"];
    result.nextVoyage = data["cllNextVesselVoyage"];
    result.shipperName = data["cllShipperName"];
    result.commodityName = data["cllCommodityName"];
    result.dg_display = data["cllIsDg"];
    result.imo = data["cllImo"];
    result.unno = data["cllUnno"];
    result.flashPoint_display =
      data["cllFlashPointTemp"] + " " + data["cllFlashPointScale"];
    result.pkgTypeCode = data["cllPackageType"];
    result.reeferAsDry_display = data["cllIsReefer"];
    result.reeferTemp = data["cllReeferTemp"];
    result.tempScale = data["cllReeferScale"];
    result.bundle_display = data["cllIsBundle"];
    result.oh = data["cllOh"];
    result.owl = data["cllOwl"];
    result.owr = data["cllOwr"];
    result.olf = data["cllOlf"];
    result.olb = data["cllOlb"];
    result.uch = data["cllUch"];
    result.ucw = data["cllUcw"];
    result.ucl = data["cllUcl"];
    result.spDetails = data["cllSpDetails"];
    result.remarks = data["cllRemarks"];
    result.stackDateTime = "";
    result.vgmStatus_display = data["cllVgmStatus"];
    result.vgmMethod = data["cllVgmMethod"];
    result.vgmShipper = data["cllVgmShippper"];
    result.vgmTerminal = data["cllVgmTerminal"];

    if (data["allDataHeader"] != null) {
      result.allData = data["allDataHeader"];
    }

    return result;
  }

  setDataMainGrid2(data: any) {
    var result = new cllGrid();
    result.processID = data["cllProcessId"];
    result.bNo = data["cllBookingRef"];
    result.blNo = data["cllBlRef"];
    result.operatorCode = data["cllOpCode"];
    result.inSlot = data["cllSoCode"];
    result.containerNo = data["cllContainerNo"];
    result.typeSize = data["d4allContainerCode"];
    result.containerType = data["cllContainerType"];
    result.containerSize = data["cllContainerSize"];
    result.sealNo1 = data["cllContainerSealNo1"];
    result.cellLocation = data["cellLocationFDetail"];
    result.weight = data["cllContainerWeight"];
    result.status = data["cllIsEmpty"] == "Y" ? "E" : "F";
    result.tranship = data["cllIsTranship"];
    result.thruSpt = data["cllIsThruSpt"];
    result.loadPort = data["cllPolCode"];
    result.loadPortName = data["loadPortName"];
    result.transhipPort = data["cllPotCode"];
    result.transhipPortName = data["transhipPortName"];
    result.discPort1 = data["cllPod1Code"];
    result.discPort1Name = data["discPortName1"];
    result.discPort2 = data["cllPod2Code"];
    result.discPort2Name = data["discPortName2"];
    result.discPort3 = data["cllPod3Code"];
    result.discPort3Name = data["discPortName3"];
    result.finalDest = data["cllFdestCode"];
    result.finalDestName = data["finalDestName"];
    result.nextVesselName = data["nextVesselName"];
    result.nextVoyage = data["cllNextVesselVoyage"];
    result.shipperName = data["cllShipperName"];
    result.commodityName = data["cllCommodityName"];
    result.dg_display = data["cllIsDg"];
    result.imo = data["cllImo"];
    result.unno = data["cllUnno"];
    result.flashPoint = data["cllFlashPointTemp"];
    result.flashScale = data["cllFlashPointScale"];
    result.flashPoint_display =
      data["cllFlashPointTemp"] + " " + data["cllFlashPointScale"];
    result.pkgTypeCode = data["cllPackageType"];
    result.reefer = data["cllIsReefer"] == "Y" ? true : false;
    result.reefer_display = data["cllIsReefer"];
    result.reeferAsDry = data["cllReeferAsDry"] == "Y" ? true : false;
    result.reeferAsDry_display = data["cllReeferAsDry"];
    // console.log("suee");
    // console.log(data["cllReeferTemp"]);
    if (data["cllReeferTemp"] != undefined) {
      result.reeferTemp = data["cllReeferTemp"];
      var refNum =
        (parseFloat(result.reeferTemp) < 0 ? -1 : 1) *
        parseFloat(result.reeferTemp);
      result.reeferTemp_display =
        (parseFloat(result.reeferTemp) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 2);
    } else {
      result.reeferTemp = data["cllReeferTemp"];
      result.reeferTemp_display = data["cllReeferTemp"];
    }
    result.tempScale = data["cllReeferScale"];
    result.oog_display = data["cllIsOog"];
    result.oogScale = data["cllOhScale"];
    result.bundle_display = data["cllIsBundle"];
    if (data["cllOh"] != undefined) {
      result.oh = data["cllOh"];
      var refNum = (parseFloat(result.oh) < 0 ? -1 : 1) * parseFloat(result.oh);
      result.oh_display =
        (parseFloat(result.oh) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 1);
    } else {
      result.oh = data["cllOh"];
      result.oh_display = data["cllOh"];
    }
    if (data["cllOwl"] != undefined) {
      result.owl = data["cllOwl"];
      var refNum =
        (parseFloat(result.owl) < 0 ? -1 : 1) * parseFloat(result.owl);
      result.owl_display =
        (parseFloat(result.owl) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 1);
    } else {
      result.owl = data["cllOwl"];
      result.owl_display = data["cllOwl"];
    }
    if (data["cllOwr"] != undefined) {
      result.owr = data["cllOwr"];
      var refNum =
        (parseFloat(result.owr) < 0 ? -1 : 1) * parseFloat(result.owr);
      result.owr_display =
        (parseFloat(result.owr) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 1);
    } else {
      result.owr = data["cllOwr"];
      result.owr_display = data["cllOwr"];
    }
    if (data["cllOlf"] != undefined) {
      result.olf = data["cllOlf"];
      var refNum =
        (parseFloat(result.olf) < 0 ? -1 : 1) * parseFloat(result.olf);
      result.olf_display =
        (parseFloat(result.olf) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 1);
    } else {
      result.olf = data["cllOlf"];
      result.olf_display = data["cllOlf"];
    }
    if (data["cllOlb"] != undefined) {
      result.olb = data["cllOlb"];
      var refNum =
        (parseFloat(result.olb) < 0 ? -1 : 1) * parseFloat(result.olb);
      result.olb_display =
        (parseFloat(result.olb) < 0 ? "-" : "") +
        this.formattedString(refNum, 3, 1);
    } else {
      result.olb = data["cllOlf"];
      result.olb_display = data["cllOlf"];
    }
    result.uc_display = data["cllIsUc"];
    result.uch = data["cllUch"];
    result.uchScale = data["cllUchScale"];
    result.ucw = data["cllUcw"];
    result.ucwScale = data["cllUcwScale"];
    result.ucl = data["cllUcl"];
    result.uclScale = data["cllUclScale"];
    result.spDetails = data["cllSpDetails"];
    result.sealNo2 = data["cllContainerSealNo2"];
    result.sealNo3 = data["cllContainerSealNo3"];
    result.remarks = data["cllRemarks"];
    result.stackDateTime = data["cllStackDateTime"];
    result.indicator = data["cllContainerIndicator"];
    result.method = data["cllContainerMethod"];
    result.pax = data["cllContainerPax"];

    return result;
  }

  copyCllGridData(data: any) {
    var result = new cllGrid();
    result.processID = data.processID;
    result.bNo = data.bNo;
    result.blNo = data.blNo;
    result.operatorCode = data.operatorCode;
    result.inSlot = data.inSlot;
    result.containerNo = data.containerNo;
    result.typeSize = data.typeSize;
    result.containerType = data.containerType;
    result.containerSize = data.containerSize;
    result.sealNo1 = data.sealNo1;
    result.cellLocation = data.cellLocation;
    result.weight = data.weight;
    result.status = data.status;
    result.tranship = data.tranship;
    result.thruSpt = data.thruSpt;
    result.loadPort = data.loadPort;
    result.loadPortName = data.loadPortName;
    result.transhipPort = data.transhipPort;
    result.transhipPortName = data.transhipPortName;
    result.discPort1 = data.discPort1;
    result.discPort1Name = data.discPort1Name;
    result.discPort2 = data.discPort2;
    result.discPort2Name = data.discPort2Name;
    result.discPort3 = data.discPort3;
    result.discPort3Name = data.discPort3Name;
    result.finalDest = data.finalDest;
    result.finalDestName = data.finalDestName;
    result.nextVesselName = data.nextVesselName;
    result.nextVoyage = data.nextVoyage;
    result.shipperName = data.shipperName;
    result.commodityName = data.commodityName;
    result.dg_display = data.dg_display;
    result.imo = data.imo;
    result.unno = data.unno;
    result.flashPoint = data.flashPoint;
    result.flashScale = data.flashScale;
    result.flashPoint_display = data.flashPoint_display;
    result.pkgTypeCode = data.pkgTypeCode;
    result.reefer = data.reefer;
    result.reefer_display = data.reefer_display;
    result.reeferAsDry = data.reeferAsDry;
    result.reeferAsDry_display = data.reeferAsDry_display;
    result.reeferTemp = data.reeferTemp;
    result.tempScale = data.tempScale;
    result.oog_display = data.oog_display;
    result.oogScale = data.oogScale;
    result.bundle_display = data.bundle_display;
    result.oh = data.oh;
    result.owl = data.owl;
    result.owr = data.owr;
    result.olf = data.olf;
    result.olb = data.olb;
    result.uc_display = data.uc_display;
    result.uch = data.uch;
    result.uchScale = data.uchScale;
    result.ucw = data.ucw;
    result.ucwScale = data.ucwScale;
    result.ucl = data.ucl;
    result.uclScale = data.uclScale;
    result.spDetails = data.spDetails;
    result.sealNo2 = data.sealNo2;
    result.sealNo3 = data.sealNo3;
    result.remarks = data.remarks;
    result.stackDateTime = data.stackDateTime;
    result.indicator = data.indicator;
    result.method = data.method;
    result.pax = data.pax;

    return result;
  }

  receiveCellLocation() {
    var uplUri = this.hitUrl + "/ContainerLoadList/upload/cll/cellLocation/new";
    this.genericService.POST(uplUri, this.postData).subscribe(
      (resp) => {
        if (resp.ok) {
          // console.log(resp.json());
          this.loadingUpload = false;
          if (resp.json()["error"] != undefined) {
            var errorList = resp.json()["error"];
            if (errorList.length > 0) {
              this.errorFileName = resp.json()["error-file-name"];
              this.message(
                "information",
                "Information",
                "Error(S) detected!<br><br>Please check Error Report.",
                "okonly",
                { ok: "" }
              );
              this.disableErrorButton1 = false;
              //this.sortField = "id";
              //this.direction = "ASC";
              this.sortMode = true;
              this.handleRetrieve("default;DESC");
            }
          } else {
            this.message(
              "information",
              "Information",
              "Data updated",
              "okonly",
              { ok: "" }
            );
            this.closeReceiveCellLocation();
            this.sortField = "id";
            this.direction = "ASC";
            this.sortMode = true;
            this.handleRetrieve("default;DESC");
          }
        }
      },
      (error) => {
        this.message(
          "Information",
          "information",
          "An Error occured, please contact your administrator!",
          "okonly",
          { ok: "" }
        );
        this.loadingUpload = false;
      }
    );
  }

  retrieveUploadedData(stat?: string) {
    var uplUri = this.hitUrl + "/ContainerLoadList/upload/cll/new";
    if (stat != undefined) {
      uplUri = this.hitUrl + "/ContainerLoadList/upload/cll/new/replace";
    }
    this.genericService.POST(uplUri, this.postData).subscribe(
      (resp) => {
        if (resp.ok) {
          this.loadingUpload = false;
          if (resp.json()["error"] != undefined) {
            var errorList = resp.json()["error"];

            if (errorList.length > 0) {
              this.errorFileName = resp.json()["error-file-name"];
              this.message(
                "information",
                "Information",
                "Error(S) detected!<br><br>Please check Discrepancy Report.",
                "okonly",
                { ok: "this.retCustom();" }
              );
              this.disableErrorButton = false;
              this.uploadEventDiscrepancy = true;
              this.sortMode = true;
            }
          } else {
            if (resp.json()["message"] == "SUCCESS") {
              this.message(
                "information",
                "Information",
                "File Uploaded",
                "okonly",
                { ok: "this.retCustom();" }
              );
              this.closeUploadCLL();
              this.sortMode = true;
            } else {
              this.message(
                "information",
                "Information",
                "Wrong file format detected! Unable to upload.",
                "okonly",
                { ok: "this.retCustom();" }
              );
              this.closeUploadCLL();
              this.sortMode = true;
            }
          }
        }
      },
      (error) => {
        this.message(
          "Information",
          "information",
          "An Error occured, please contact your administrator!",
          "okonly",
          { ok: "" }
        );
        this.loadingUpload = false;
      }
    );
  }

  retCustom() {
    //this.gridCLL.onSortDir("id","ASC");
    this.handleRetrieve("id;ASC");
  }

  handleBatch() {
    //if(this.gridCLL.getSelectedValues().length == 0 && !this.selectAllContainers){
    if (
      this.nGridApi.getSelectedRows().length == 0 &&
      !this.selectAllContainers
    ) {
      this.message(
        "information",
        "Information",
        "Please select at least one record to update",
        "okonly",
        { ok: "" }
      );
    } else {
      $("#batchCllId")
        .dialog({
          title: "Batch Operation",
          modal: true,
          width: 1300,
          height: "auto",
          open: function (event, ui) {
            //hide close button.
            //// console.log($(this).css("z-index","102"));
            $(".ui-dialog").css("z-index", 103);
            $(".ui-widget-overlay").css("z-index", 102);
            $(this)
              .parent()
              .children()
              .children(".ui-dialog-titlebar-close")
              .hide();
            $("body").first().css("overflow", "hidden");
          },
          close: function (event, ui) {
            $("body").first().css("overflow", " auto");
          },
        })
        .dialog("open");

      $("#cllGridBatch").find(".ui.segment").css("min-height", "300px");
      //$("#cllGridBatchHeader").find(".ui.segment").css("min-height","100px");
      var i = 1;
      this.settingGridBatchHeader.columns.splice(
        0,
        this.settingGridBatchHeader.columns.length
      );
      this.defaultColumn.forEach((fe) => {
        fe.status = 0;
        //this.gridCllBatchHeader.columns.push(fe);
        this.settingGridBatchHeader.columns.push(fe);
        this.gridCllBatchHeader.hideColumns(true, fe.field);
      });

      if (this.selectAllContainers) {
        this.cllFlagStatusContainerList.forEach((fe) => {
          var containerNumber = {
            containerNo: fe["containerNo"],
            processID: fe["processId"],
            containerType: fe["containerType"],
            containerSize: fe["containerSize"],
          };

          this.gridCllBatch.listStore.store.push(containerNumber);
          //this.batchData.push(fe);
        });
      } else {
        //this.gridCLL.getSelectedValues().forEach(fe=>{
        this.nGridApi.getSelectedRows().forEach((fe) => {
          var containerNumber = {
            containerNo: fe["containerNo"],
            processID: fe["processID"],
            containerType: fe["containerType"],
            containerSize: fe["containerSize"],
          };

          this.gridCllBatch.listStore.store.push(containerNumber);
          //this.batchData.push(fe);
        });
      }

      var gridData = new cllGrid();
      gridData.newCllGrid();
      gridData["no"] = 1;
      this.gridCllBatchHeader.listStore.store.push(gridData);
      //this.batchData.push(gridData);

      this.gridCllBatch.loadData();
      this.gridCllBatchHeader.loadData();

      //this.hideCheckBox('batch','batchCllId',this.settingGridBatchHeader.columns);
    }

    //var gridData = new cllGrid;

    //this.batchData.push(new cllGrid);

    //this.gridCllBatchHeader.listStore.store.push(gridData);
    //this.gridCllBatchHeader.loadData();
  }

  cancelBatch(isRetrieve?: boolean) {
    this.errorBatchMessage = "";
    if (this.gridCllBatch.listStore.store.length > 0) {
      this.gridCllBatch.listStore.store.splice(
        0,
        this.gridCllBatch.listStore.store.length
      );
    }

    this.gridCllBatchHeader.onClear();
    if (this.gridCllBatchHeader.columns.length > 0) {
      this.gridCllBatchHeader.columns.splice(
        0,
        this.gridCllBatchHeader.columns.length
      );
    }
    /*if(this.batchData.length > 0){
      this.batchData.splice(0, this.batchData.length);
    }*/

    // if(this.gridCllCopyHeader.listStore.store.length > 0){
    //   this.gridCllCopyHeader.listStore.store.splice(0, this.gridCllCopyHeader.listStore.store.length);
    // }
    $("#batchCllId")
      .find("#checkboxGroup")
      .find("input[type=checkbox]")
      .prop("checked", false);

    //this.gridCLL.clearSelectedValues();
    //this.gridCLL.loadData();

    this.nGridApi.deselectAll();
    this.nGridApi.refreshView();

    this.closeDialog("batchCllId");
    this.selectAllContainers = false;
    $("#checkAllContainer").checkbox("set unchecked");

    if (isRetrieve != undefined) {
      if (isRetrieve) {
        //this.gridCLL.onSortDir("default","DESC");
        this.handleRetrieve("default;DESC");
      }
    }
  }

  batchCopyValidation(type) {
    var result: boolean = true;
    var settingColumns;
    var store;
    var containerStore;
    var validationMessage = [];
    var storeData = {};
    this.errorBatchMessage = "";
    this.errorCopyMessage = "";

    if (type == "copy") {
      settingColumns = this.settingGridCopyHeader.columns;
      store = this.gridCllCopyHeader.listStore.store[0];
      containerStore = this.gridCllCopy.listStore.store;
    } else if (type == "batch") {
      settingColumns = this.settingGridBatchHeader.columns;
      store = this.gridCllBatchHeader.listStore.store[0];
      containerStore = this.gridCllBatch.listStore.store;
    }

    var avlbField = "";
    settingColumns.forEach((ec) => {
      if (ec.status == 1) {
        storeData[ec.field] = store[ec.field];
        if (avlbField != "") {
          avlbField = avlbField + ";" + ec.field;
        } else {
          avlbField = ec.field;
        }
      }
    });

    //console.log("checked column data");
    //console.log(storeData);
    var message = "";
    if (type == "copy") {
      if (storeData["typeSize"] == undefined) {
        message =
          "Unable to copy data, Cntr Size/Type is mandatory, please check Cntr Size/Type check box";
      } else if (storeData["typeSize"] != undefined) {
        if (storeData["typeSize"].startsWith("R")) {
          if (
            storeData["reefer_display"] == undefined &&
            storeData["reeferAsDry_display"] == undefined
          ) {
            message =
              "Unable to copy data, Cntr Size/Type is " +
              storeData["typeSize"] +
              ", please check RF or RF as DRY check box";
          } else {
            if (store["reefer_display"] == "Y") {
              if (
                storeData["reefer_display"] == undefined ||
                storeData["reeferTemp"] == undefined ||
                storeData["tempScale"] == undefined
              ) {
                if (storeData["reefer_display"] == undefined) {
                  //message = "Unable to copy data, copy data source is reefer, please check RF";
                  message = "Unable to copy data, please check RF details";
                } else if (storeData["reeferTemp"] == undefined) {
                  message = "Unable to copy data, please check RF temp";
                } else if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to copy data, please check reefer C/F option";
                } else {
                  message =
                    "Unable to copy data, please check RF, RF temp & C/F option";
                }
              }
            } else if (store["reeferAsDry_display"] == "Y") {
              if (storeData["reeferAsDry_display"] == undefined) {
                message = "Unable to copy data, please check RF as DRY details";
              }
            }
          }
        }
      } else if (storeData["reefer_display"] == undefined) {
        if (
          storeData["reeferTemp"] != undefined &&
          storeData["reeferTemp"] != 0
        ) {
          message =
            "Unable to copy data, RF temp is not zero, please check RF check box";
        }
      } else if (
        storeData["reefer_display"] != undefined &&
        storeData["reefer_display"] == "Y"
      ) {
        if (storeData["reeferTemp"] == undefined) {
          message =
            "Unable to copy data, RF is Y, please tick RF temp check box";
        }
      } else if (storeData["reeferAsDry_display"] == undefined) {
        if (storeData["typeSize"].startsWith("R")) {
          if (
            storeData["reefer_display"] != undefined &&
            storeData["reeferTemp"] != undefined
          ) {
            if (
              storeData["reefer_display"] != "Y" &&
              storeData["reeferTemp"] == 0
            ) {
              message =
                "Unable to copy data, RF as DRY container detected, please check RF as DRY check box";
            }
          }
        }
      }

      if (message == "") {
        if (storeData["dg_display"] == "Y") {
          if (
            /*storeData["dgClass"] == undefined && */ storeData["imo"] ==
              undefined &&
            storeData["unno"] == undefined
          ) {
            message =
              "unable to copy data, dg indicator detected, IMO or Un No check box";
          } else if (
            storeData["dgClass"] != undefined &&
            /*storeData["dgClass"] != "" &&*/ storeData["imo"] == undefined &&
            storeData["unno"] == undefined
          ) {
            message =
              "unable to copy data, dg indicator detected, please check IMO or Un No check box";
            /*} else if(storeData["dgClass"] == undefined && (storeData["imo"] != undefined || storeData["unno"] != undefined)){
            message = "unable to copy data, dg indicator detected, please check DG Class check box";*/
          }
        }
      }
    }

    if (type == "batch") {
      var column = "";
      var blankInput = "";
      var notEmptyField =
        "inSlot,operatorCode,containerNo,typeSize,status,tranship,loadPortName,discPort1Name,thruSpt,dg_display,reefer_display,reeferAsDry_display,oog_display,bundle_display,uc_display";

      settingColumns.forEach((fe) => {
        for (var key in storeData) {
          if (notEmptyField.includes(key)) {
            if (storeData.hasOwnProperty(key)) {
              if (fe.field == key) {
                // console.log("xxx = "+storeData[key]);
                if (storeData[key].toString() == "") {
                  if (blankInput == "") {
                    blankInput = fe.header;
                  } else {
                    blankInput = blankInput + ", " + fe.header;
                  }
                }
              }
            }
          }
        }
      });

      if (blankInput != "") {
        message = "blank column detected, please insert column " + blankInput;
      }

      containerStore.forEach((fe) => {
        if (message == "") {
          //if(fe.containerType == "REF" || fe.containerType == "DRY"){
          column = "";
          if (
            storeData["typeSize"] != undefined &&
            storeData["typeSize"].startsWith("R") &&
            storeData["status"] == "F"
          ) {
            column = column + "Cntr Size/Type,";
            if (
              storeData["reefer_display"] == undefined &&
              storeData["reeferAsDry_display"] == undefined
            ) {
              if (fe.containerType != "DRY" && fe.containerType != "REF") {
                message =
                  "Unable to update " +
                  column +
                  "please insert RF / RF as DRY details";
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF temp, RF as DRY";
              if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] == "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", please choose only RF or RF as DRY.";
              } else if (
                storeData["reefer_display"] != "Y" &&
                storeData["reeferAsDry_display"] != "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", please check container detail.";
              } else if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] != "Y" &&
                storeData["reeferTemp"] == 0
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", RF container detected, please insert RF temp.";
              } else if (
                storeData["reefer_display"] != "Y" &&
                storeData["reeferAsDry_display"] == "Y" &&
                storeData["reeferTemp"] != 0
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", RF as DRY container detected, does not need to input RF temp.";
              } else if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] != "Y" &&
                storeData["reeferTemp"] != 0
              ) {
                if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check reefer C/F indicator";
                } else if (storeData["tempScale"] == "") {
                  message =
                    "Unable to update " +
                    column +
                    ", please insert reefer C/F indicator";
                }
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined
            ) {
              column = column + " RF, RF temp, C/F ";
              if (fe.containerType == "REF") {
                if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check reefer C/F indicator";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] == 0
                ) {
                  message =
                    "Unable to update " + column + ", please insert RF temp";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] != 0 &&
                  storeData["tempScale"] == ""
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please insert reefer C/F indicator";
                } else if (storeData["reefer_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else if (fe.containerType == "DRY") {
                if (storeData["reefer_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check reefer C/F indicator";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] == 0
                ) {
                  message =
                    "Unable to update " + column + ", please insert RF temp.";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] != 0 &&
                  storeData["tempScale"] == ""
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please insert reefer C/F indicator";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF as DRY";
              if (fe.containerType == "REF") {
                if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please choose only RF or RF as DRY.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please choose only RF or RF as DRY.";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF temp, RF as DRY";
              if (fe.containerType == "REF") {
                if (
                  storeData["reeferAsDry_display"] == "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reeferAsDry_display"] != "Y" &&
                  storeData["reeferTemp"] == 0
                ) {
                  message =
                    "Unable to update " + column + ", please insert RF temp.";
                }
              } else {
                if (
                  storeData["reeferAsDry_display"] == "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (storeData["reeferAsDry_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (storeData["reefer_display"] != undefined) {
              column = column + "RF";
              if (fe.containerType == "REF") {
                if (storeData["reefer_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reefer_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check RF temp and C/F indicator.";
                }
              }
            } else if (storeData["reeferAsDry_display"] != undefined) {
              column = column + "RF as DRY";
              if (fe.containerType == "REF") {
                if (storeData["reeferAsDry_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reeferAsDry_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (storeData["reeferTemp"] != undefined) {
              column = column + "RF temp";
              if (fe.containerType == "REF") {
                if (storeData["reeferTemp"] == 0) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reeferTemp"] != 0) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            }
          } else if (
            storeData["typeSize"] != undefined &&
            !storeData["typeSize"].startsWith("R")
          ) {
            column = column + "Cntr Size/Type,";
            if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF temp, RF as DRY";
              if (
                storeData["reefer_display"] == "Y" ||
                storeData["reeferAsDry_display"] == "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF and/or RF as DRY as Y";
              } else if (storeData["reeferTemp"] != 0) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not need to input RF temp";
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined
            ) {
              column = column + " RF, RF temp";
              if (storeData["reefer_display"] == "Y") {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF";
              } else if (storeData["reeferTemp"] != 0) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not need to input RF temp";
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF as DRY";
              if (
                storeData["reefer_display"] == "Y" ||
                storeData["reeferAsDry_display"] == "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF and/or RF as DRY as Y";
              }
            } else if (
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF temp, RF as DRY";
              if (storeData["reeferAsDry_display"] == "Y") {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF as DRY";
              } else if (storeData["reeferTemp"] != 0) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not need to input RF temp";
              }
            } else if (storeData["reefer_display"] != undefined) {
              column = column + "RF";
              if (storeData["reefer_display"] == "Y") {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF";
              }
            } else if (storeData["reeferAsDry_display"] != undefined) {
              column = column + "RF as DRY";
              if (storeData["reeferAsDry_display"] == "Y") {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not allowed to set RF as DRY";
              }
            } else if (storeData["reeferTemp"] != undefined) {
              column = column + "RF temp";
              if (storeData["reeferTemp"] != 0) {
                message =
                  "Unable to update " +
                  column +
                  ", typeSize is " +
                  storeData["typeSize"] +
                  ", does not need to input RF temp";
              }
            } /* else {
              if(fe.containerType == "REF" || fe.containerType == "DRY"){
                message = "Unable to change "+column+" please";
              }
            }*/
          } else {
            if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF temp, RF as DRY";
              if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] == "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", please choose only RF or RF as DRY.";
              } else if (
                storeData["reefer_display"] != "Y" &&
                storeData["reeferAsDry_display"] != "Y"
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", please check container detail.";
              } else if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] != "Y" &&
                storeData["reeferTemp"] == 0
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", RF container detected, please insert RF temp.";
              } else if (
                storeData["reefer_display"] != "Y" &&
                storeData["reeferAsDry_display"] == "Y" &&
                storeData["reeferTemp"] != 0
              ) {
                message =
                  "Unable to update " +
                  column +
                  ", RF as DRY container detected, does not need to input RF temp.";
              } else if (
                storeData["reefer_display"] == "Y" &&
                storeData["reeferAsDry_display"] != "Y" &&
                storeData["reeferTemp"] != 0
              ) {
                if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check reefer C/F indicator";
                } else if (storeData["tempScale"] == "") {
                  message =
                    "Unable to update " +
                    column +
                    ", please insert reefer C/F indicator";
                }
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferTemp"] != undefined
            ) {
              column = column + " RF, RF temp";
              if (fe.containerType == "REF") {
                if (storeData["tempScale"] == undefined) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check reefer C/F indicator";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] == 0
                ) {
                  message =
                    "Unable to update " + column + ", please insert RF temp";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferTemp"] != 0 &&
                  storeData["tempScale"] == ""
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please insert reefer C/F indicator";
                } else if (storeData["reefer_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reefer_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (
              storeData["reefer_display"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF, RF as DRY";
              if (fe.containerType == "REF") {
                if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please choose only RF or RF as DRY.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else if (fe.containerType == "DRY") {
                if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please choose only RF or RF as DRY.";
                } else if (
                  storeData["reefer_display"] == "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reefer_display"] != "Y" &&
                  storeData["reeferAsDry_display"] != "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (
                  storeData["reefer_display"] == "Y" ||
                  storeData["reeferAsDry_display"] == "Y"
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (
              storeData["reeferTemp"] != undefined &&
              storeData["reeferAsDry_display"] != undefined
            ) {
              column = column + " RF temp, RF as DRY";
              if (fe.containerType == "REF") {
                if (
                  storeData["reeferAsDry_display"] == "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (
                  storeData["reeferAsDry_display"] != "Y" &&
                  storeData["reeferTemp"] == 0
                ) {
                  message =
                    "Unable to update " + column + ", please insert RF temp.";
                }
              } else if (fe.containerType == "DRY") {
                if (
                  storeData["reeferAsDry_display"] == "Y" &&
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                } else if (storeData["reeferAsDry_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (
                  storeData["reeferAsDry_display"] == "Y" ||
                  storeData["reeferTemp"] != 0
                ) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (storeData["reefer_display"] != undefined) {
              column = column + "RF";
              if (fe.containerType == "REF") {
                if (storeData["reefer_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reefer_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (storeData["reeferAsDry_display"] != undefined) {
              column = column + "RF as DRY";
              if (fe.containerType == "REF") {
                if (storeData["reeferAsDry_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else if (fe.containerType == "DRY") {
                if (storeData["reeferAsDry_display"] != "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reeferAsDry_display"] == "Y") {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            } else if (storeData["reeferTemp"] != undefined) {
              column = column + "RF temp";
              if (fe.containerType == "REF") {
                if (storeData["reeferTemp"] == 0) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              } else {
                if (storeData["reeferTemp"] != 0) {
                  message =
                    "Unable to update " +
                    column +
                    ", please check container detail.";
                }
              }
            }
          }

          //}
        }
      });
    }

    // console.log(message);
    if (message != "") {
      validationMessage.push(message);
    }

    // console.log(validationMessage);

    validationMessage.forEach((fe) => {
      if (type == "batch") {
        this.errorBatchMessage = this.errorBatchMessage + fe;
      } else if (type == "copy") {
        this.errorCopyMessage = this.errorCopyMessage + fe;
      }
    });

    if (validationMessage.length > 0) {
      result = false;
    }

    return result;
  }

  getGridValue(store, field, index) {
    var value = "";
    var i = 0;
    store.forEach((fe) => {
      if (i == index) {
        if (fe[field] != "") {
          value = fe[field];
        }
      }
      i++;
    });

    return value;
  }

  batchSave() {
    // console.log("container to update");
    // console.log(this.gridCllBatch.listStore.store);
    // console.log("opt to copy");
    // console.log(this.settingGridBatchHeader.columns);
    // console.log(this.batchData);

    this.loadingBatch = true;

    var postData = {};
    postData["containerToUpdate"] = [];
    this.gridCllBatch.listStore.store.forEach((fe) => {
      var dtContainer = {};
      dtContainer["processId"] = fe.processID;
      dtContainer["containerToUpdate"] = fe.containerNo;
      dtContainer["containerType"] = fe.containerType;

      postData["containerToUpdate"].push(dtContainer);
    });

    postData["columns"] = this.settingGridBatchHeader.columns;
    postData["data"] = this.gridCllBatchHeader.listStore.store; //this.batchData;
    postData["userCreated"] = this.userID;
    postData["createdDate"] = this.genericUtil.getPSIDate();

    // console.log("data post");
    // console.log(postData);

    if (this.batchCopyValidation("batch")) {
      var url = this.hitUrl + "/ContainerLoadList/batchContainer/cll";
      this.genericService.POST(url, postData).subscribe(
        (resp) => {
          if (resp.ok) {
            if (resp.json()["message"] == "SUCCESS") {
              this.message(
                "Information",
                "information",
                "Data has been updated",
                "okonly",
                { ok: "" }
              );
              this.cancelBatch(true);
              this.loadingBatch = false;
            }
          }
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error Occured while retreiving data, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          this.loadingBatch = false;
        }
      );
    } else {
      this.loadingBatch = false;
    }
  }

  noNegativeWithoutDecimal(event) {
    // console.log("fired from no Negative => " + event.keyCode + " --- " + event.target.value);

    if (
      event.keyCode < 49 ||
      event.keyCode > 57 ||
      Number.parseInt(event.target.value) < 0
    ) {
      event.preventDefault();
    }
  }

  handleCopy() {
    //if(this.gridCLL.getSelectedValues().length > 1){
    if (this.nGridApi.getSelectedRows().length > 1) {
      this.message(
        "information",
        "Information",
        "Please select only ONE container record.",
        "okonly",
        { ok: "" }
      );
    } else if (this.nGridApi.getSelectedRows().length == 0) {
      this.message(
        "information",
        "Information",
        "Please chooce at least one record to copy.",
        "okonly",
        { ok: "" }
      );
    } else {
      $("#copyCllId")
        .dialog({
          title: "Copy Operation",
          modal: true,
          width: 1300,
          height: "auto",
          open: function (event, ui) {
            //hide close button.
            //// console.log($(this).css("z-index","102"));
            $(".ui-dialog").css("z-index", 103);
            $(".ui-widget-overlay").css("z-index", 102);
            $(this)
              .parent()
              .children()
              .children(".ui-dialog-titlebar-close")
              .hide();
            $("body").first().css("overflow", "hidden");
          },
          close: function (event, ui) {
            $("body").first().css("overflow", " auto");
          },
        })
        .dialog("open");

      $("#cllGridCopy").find(".ui.segment").css("min-height", "300px");
      //$("#cllGridCopyHeader").find(".dataTables_scroll").find(".dataTables_scrollBody").css("min-height","10px");
      //$("#cllGridCopyHeader").find(".ui.segment").css("min-height","100px");
      //this.gridCllCopyHeader.columns.splice(0, this.gridCllCopyHeader.columns.length);
      this.settingGridCopyHeader.columns.splice(
        0,
        this.settingGridCopyHeader.columns.length
      );
      this.defaultColumn.forEach((fe) => {
        if (fe.field != "inSlot") {
          fe.status = 1;
          //this.gridCllCopyHeader.columns.push(fe);
          this.settingGridCopyHeader.columns.push(fe);
          this.gridCllCopyHeader.hideColumns(false, fe.field);
        }
      });

      $("#copyCllId")
        .find("#checkboxGroupCopy")
        .find("input[type=checkbox]")
        .prop("checked", true);

      this.gridCllCopy.listStore.store.push({ containerNo: "" });
      // console.log("globalnyeee "+this.globalGridCllNumbers);
      var data = {};
      //this.gridCLL.listStore.store.forEach(fe=>{
      this.nGridStore.forEach((fe) => {
        //if(parseFloat(fe["seq"]) == this.globalGridCllNumbers){
        if (fe["processID"] == this.globarlGridProcessId) {
          // console.log(fe.processID);
          data = fe;
          data["no"] = 1;

          this.defaultCopyGrid = this.copyCllGridData(fe);
        }
      });
      this.gridCllCopyHeader.listStore.store.push(data);
      //this.copyData.push(data);
      //this.gridCLL.clearSelectedValues();
      this.nGridApi.deselectAll();

      this.gridCllCopy.loadData();
      this.gridCllCopyHeader.loadData();
    }
  }

  copyProcess() {
    this.errorCopyMessage = "";
    this.loadingCopy = true;
    var postData = {};
    var copyMessage = "";
    postData["containerNoToCopy"] = [];
    this.gridCllCopy.listStore.store.forEach((fe) => {
      if (fe.containerNo != "") {
        var tempCopy = {
          containerNoToCopy: fe.containerNo.toUpperCase(),
        };
        postData["containerNoToCopy"].push(tempCopy);
      }
    });
    //postData["containerNoToCopy"] = this.gridCllCopy.listStore.store[0]["containerNo"].toUpperCase();
    postData["processIDCopy"] =
      this.gridCllCopyHeader.listStore.store[0].processID; //this.copyData[0].processID;
    postData["containerCopy"] =
      this.gridCllCopyHeader.listStore.store[0].containerNo; //this.copyData[0].containerNo;
    postData["columns"] = this.settingGridCopyHeader.columns;
    postData["data"] = this.gridCllCopyHeader.listStore.store;
    postData["userCreated"] = this.userID;
    postData["createdDate"] = this.genericUtil.getPSIDate();

    // console.log("data post");
    // console.log(postData);

    if (this.batchCopyValidation("copy")) {
      var url = this.hitUrl + "/ContainerLoadList/copyContainer/cll";
      this.genericService.POST(url, postData).subscribe(
        (resp) => {
          if (resp.ok) {
            // console.log(resp.json());
            if (resp.json()["message"] == "SUCCESS") {
              copyMessage = "Copied successfully";
              if (resp.json()["duplicateContainer"] != undefined) {
                var exist = resp.json()["duplicateContainer"];
                copyMessage = "Container number ";
                if (exist.length > 1) {
                  copyMessage = "Container number : <br>";
                  var i = 0;
                  exist.forEach((fe) => {
                    i++;
                    copyMessage = copyMessage + i + "." + fe + "<br>";
                  });
                } else {
                  exist.forEach((fe) => {
                    copyMessage = copyMessage + fe;
                  });
                }

                copyMessage = copyMessage + "already exist.";
              }

              this.message(
                "Information",
                "information",
                copyMessage,
                "okonly",
                { ok: "" }
              );
              this.cancelCopy(true);
              this.loadingCopy = false;
            }
          }
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error Occured while retreiving data, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          this.loadingCopy = false;
        }
      );
    } else {
      this.loadingCopy = false;
    }
  }

  cancelCopy(isRetrieve?: boolean) {
    this.errorCopyMessage = "";
    if (this.gridCllCopy.listStore.store.length > 0) {
      this.gridCllCopy.listStore.store.splice(
        0,
        this.gridCllCopy.listStore.store.length
      );
    }

    this.gridCllCopyHeader.onClear();
    /*if(this.copyData.length > 0){
      this.copyData.splice(0, this.copyData.length);
    }*/

    // if(this.gridCllCopyHeader.listStore.store.length > 0){
    //   this.gridCllCopyHeader.listStore.store.splice(0, this.gridCllCopyHeader.listStore.store.length);
    // }

    $("#copyCllId")
      .find("#checkboxGroup")
      .find("input[type=checkbox]")
      .prop("checked", false);
    //this.gridCLL.clearSelectedValues();
    this.nGridApi.deselectAll();

    this.closeDialog("copyCllId");
    this.copyButtonDisable = true;

    //this.gridCLL.loadData();
    this.nGridApi.refreshView();
    if (isRetrieve != undefined) {
      if (isRetrieve) {
        //this.gridCLL.onSortDir("default","DESC");
        this.handleRetrieve("default;DESC");
      }
    }
    //this.handleRetrieve();
  }

  transferProcess() {
    if (this.modelTransfer.vesselId == "") {
      this.message(
        "Information",
        "information",
        "Please insert vessel",
        "okonly",
        { ok: "" }
      );
    } else if (this.modelTransfer.voyage == "") {
      this.message(
        "Information",
        "information",
        "Please insert voyage",
        "okonly",
        { ok: "" }
      );
    } else {
      var postData = {};
      postData["userCreated"] = this.userID;
      postData["createdDate"] = this.genericUtil.getPSIDate();
      postData["vesselId"] = this.modelTransfer.vesselId;
      postData["vesselCode"] = this.modelTransfer.vesselCode;
      postData["vesselVoyage"] = this.modelTransfer.voyage;
      postData["data"] = [];
      this.gridCllTransfer.listStore.store.forEach((fe) => {
        var containers = {
          containerNo: fe.containerNo,
          processId: fe.processId,
        };

        postData["data"].push(containers);
      });

      var url = this.hitUrl + "/ContainerLoadList/transfer/cll";
      this.genericService.POST(url, postData).subscribe(
        (resp) => {
          if (resp.ok) {
            // console.log(resp.json());
            if (resp.json()["result"].length > 0) {
              var res = resp.json()["result"];
              res.forEach((fe) => {
                // console.log("xx "+fe.message);
                this.gridCllTransfer.listStore.store.forEach((fes) => {
                  if (fes.containerNo == fe.containerNo) {
                    fes.statusMessage = fe.message;
                  }
                });
              });

              this.gridCllTransfer.loadData();

              this.trfButtonDisabled = true;
            }
          }
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error Occured while retreiving data, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
    }
  }

  cancelTransfer() {
    //this.gridCllTransfer.listStore.store.splice(0,this.gridCllTransfer.listStore.store.length);
    this.cbVesselTo.setValue("");
    this.cbVoyageTo.setValue("");
  }

  closeTransfer() {
    this.gridCllTransfer.listStore.store.splice(
      0,
      this.gridCllTransfer.listStore.store.length
    );
    this.cbVesselTo.setValue("");
    this.cbVoyageTo.setValue("");
    this.trfButtonDisabled = false;

    this.closeDialog("transferCllId");
    //this.gridCLL.clearSelectedValues();
    this.nGridApi.deselectAll();
    this.selectAllContainers = false;
    $("#checkAllContainer").checkbox("set unchecked");
    this.handleRetrieve("default;DESC");
  }

  handleTransfer() {
    console.log("value " + this.selectAllContainers);
    //if(this.gridCLL.getSelectedValues().length == 0 && !this.selectAllContainers){
    if (
      this.nGridApi.getSelectedRows().length == 0 &&
      !this.selectAllContainers
    ) {
      this.message(
        "information",
        "Information",
        "Please Chooce at least one record to transfer or check All containers check box",
        "okonly",
        { ok: "" }
      );
    } else {
      $("#transferCllId")
        .dialog({
          title: "Transfer Operation",
          modal: true,
          width: 800,
          height: "auto",
          open: function (event, ui) {
            //hide close button.
            //// console.log($(this).css("z-index","102"));
            $(".ui-dialog").css("z-index", 103);
            $(".ui-widget-overlay").css("z-index", 102);
            $(this)
              .parent()
              .children()
              .children(".ui-dialog-titlebar-close")
              .hide();
            $("body").first().css("overflow", "hidden");
          },
          close: function (event, ui) {
            $("body").first().css("overflow", " auto");
          },
        })
        .dialog("open");

      if (this.selectAllContainers) {
        this.cllFlagStatusContainerList.forEach((fe) => {
          var transferData = {
            containerNo: fe.containerNo,
            processId: fe.processId,
            statusMessage: "",
          };

          this.gridCllTransfer.listStore.store.push(transferData);
        });
      } else {
        this.nGridApi.getSelectedRows().forEach((fe) => {
          var transferData = {
            containerNo: fe.containerNo,
            processId: fe.processID,
            statusMessage: "",
          };

          this.gridCllTransfer.listStore.store.push(transferData);
        });
      }

      this.gridCllTransfer.loadData();
    }
  }

  updateCellLocProcess() {
    this.loadingUpdateCellLocc = true;
    var postData = {};
    postData["data"] = [];
    this.gridCllUpdateCelLoc.listStore.store.forEach((fe) => {
      var dt = {};
      dt["containerNo"] = fe.containerNo;
      dt["cellLocation"] = fe.cellLocations.toUpperCase();
      dt["processId"] = fe.processId;

      postData["data"].push(dt);
    });
    postData["userCreated"] = this.userID;
    postData["createdDate"] = this.genericUtil.getPSIDate();

    // console.log(postData);

    var url = this.hitUrl + "/ContainerLoadList/cellLocationUpdate/cll";
    this.genericService.POST(url, postData).subscribe(
      (resp) => {
        if (resp.ok) {
          // console.log(resp.json());
          if (resp.json()["message"] == "SUCCESS") {
            this.message(
              "Information",
              "information",
              "Data has been updated",
              "okonly",
              { ok: "" }
            );
            this.cancelCellLoc();
            this.loadingUpdateCellLocc = false;
            this.handleRetrieve("default;DESC");
          }
        }
      },
      (error) => {
        this.message(
          "Information",
          "information",
          "An Error Occured while retreiving data, please contact your administrator!",
          "okonly",
          { ok: "" }
        );
        this.loadingUpdateCellLocc = false;
      }
    );
  }

  cancelCellLoc() {
    this.gridCllUpdateCelLoc.listStore.store.splice(
      0,
      this.gridCllUpdateCelLoc.listStore.store.length
    );
    //this.gridCLL.clearSelectedValues();
    this.nGridApi.deselectAll();
    this.closeDialog("updateCellLocCllId");
    this.selectAllContainers = false;
    $("#checkAllContainer").checkbox("set unchecked");
    //this.handleRetrieve();
  }

  handleUpdateCellLoc() {
    //if(this.gridCLL.getSelectedValues().length == 0 && !this.selectAllContainers){
    if (
      this.nGridApi.getSelectedRows().length == 0 &&
      !this.selectAllContainers
    ) {
      this.message(
        "information",
        "Information",
        "Please Chooce at least one record to copy",
        "okonly",
        { ok: "" }
      );
    } else {
      $("#updateCellLocCllId")
        .dialog({
          title: "Update Cell Location",
          modal: true,
          width: 950,
          height: "auto",
          open: function (event, ui) {
            //hide close button.
            //// console.log($(this).css("z-index","102"));
            $(".ui-dialog").css("z-index", 103);
            $(".ui-widget-overlay").css("z-index", 102);
            $(this)
              .parent()
              .children()
              .children(".ui-dialog-titlebar-close")
              .hide();
            $("body").first().css("overflow", "hidden");
          },
          close: function (event, ui) {
            $("body").first().css("overflow", " auto");
          },
        })
        .dialog("open");

      if (this.selectAllContainers) {
        this.cllFlagStatusContainerList.forEach((fe) => {
          var containerToUpdate = {
            containerNo: fe.containerNo,
            cellLocations: fe.cellLocation,
            processId: fe.processID,
            typeSize: fe.typeSize,
          };

          this.gridCllUpdateCelLoc.listStore.store.push(containerToUpdate);
        });
      } else {
        this.nGridApi.getSelectedRows().forEach((fe) => {
          var containerToUpdate = {
            containerNo: fe.containerNo,
            cellLocations: fe.cellLocation,
            processId: fe.processID,
            typeSize: fe.typeSize,
          };

          this.gridCllUpdateCelLoc.listStore.store.push(containerToUpdate);
        });
      }

      this.gridCllUpdateCelLoc.loadData();
    }
  }

  handleUploadCellLocations() {
    $("#receiveCellLocationDialogId")
      .dialog({
        title: "Receive Cell Locations",
        modal: true,
        width: 800,
        height: "auto",
        open: function (event, ui) {
          //hide close button.
          //// console.log($(this).css("z-index","102"));
          $(".ui-dialog").css("z-index", 103);
          $(".ui-widget-overlay").css("z-index", 102);
          $(this)
            .parent()
            .children()
            .children(".ui-dialog-titlebar-close")
            .hide();
          $("body").first().css("overflow", "hidden");
        },
        close: function (event, ui) {
          $("body").first().css("overflow", " auto");
        },
      })
      .dialog("open");

    this.uploadPlgCellLocation.setDisabled(true);
  }

  uploadReceiveCellLocation() {}

  closeReceiveCellLocation() {
    this.uploadPlgCellLocation.store = [];
    this.errorFileName = "";
    this.disableErrorButton1 = true;

    this.closeDialog("receiveCellLocationDialogId");
  }

  /** end of main functional */

  /* event block start here
   */
  toolbarEvent(event) {
    // console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        if (
          this.modelForm.vesselId != "" &&
          this.modelForm.baseVoyage != "" &&
          this.modelForm.vesselBound != ""
        ) {
          this.handleRetrieve();
        } else {
          if (this.modelForm.vesselId == "") {
            this.message(
              "information",
              "Information",
              "Please input vessel!",
              "okonly",
              { ok: "" }
            );
          } else if (this.modelForm.baseVoyage == "") {
            this.message(
              "information",
              "Information",
              "Please input voyage!",
              "okonly",
              { ok: "" }
            );
          } else if (this.modelForm.vesselBound == "") {
            this.message(
              "information",
              "Information",
              "Please input bound!",
              "okonly",
              { ok: "" }
            );
          }
        }
        break;
      case "new":
        if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
          this.handleNew();
        } else {
          this.message(
            "information",
            "Information",
            "Please insert vessel and voyage!",
            "okonly",
            { ok: "" }
          );
        }
        break;
      case "upload":
        if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
          this.modelReceiveCLL = new uploadContainer();
          this.receiveCLLOpenDialog();
          this.postData = {};
        } else {
          this.message(
            "information",
            "Information",
            "Please insert vessel and voyage!",
            "okonly",
            { ok: "" }
          );
        }
        break;
      case "batch":
        this.handleBatch();
        //console.log(this.nGridApi.getSelectedRows());
        break;
      case "copy":
        this.handleCopy();
        break;
      case "transfer":
        this.handleTransfer();
        break;
      case "uploadCellLoc":
        if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
          this.modelReceiveCLL = new uploadContainer();
          this.handleUploadCellLocations();
          this.postData = {};
        } else {
          this.message(
            "information",
            "Information",
            "Please insert vessel and voyage!",
            "okonly",
            { ok: "" }
          );
        }
        break;
      case "updateCellLoc":
        this.handleUpdateCellLoc();
        break;
      case "cancel":
        this.handleClose();
        break;
      case "delete":
        if (this.nGridApi.getSelectedRows().length == 0) {
          //this.showPrompterDialog("information","Information","Please select an entry to delete",[{name:"OK",event:""}]);
          this.message(
            "information",
            "information",
            "Please select an entry to delete",
            "okonly",
            { ok: "" }
          );
        } else {
          //this.showPrompterDialog("warning","Confirmation","Delete selected container(s)?",[{name:"YES",event:"this.handleDelete();"},{name:"NO", event:""}]);
          this.message(
            "warning",
            "Confirmation",
            "Delete selected container(s)?",
            "yesno",
            {
              yes: "this.handleDelete()",
              no: '// console.log("button cancel clicked");',
            }
          );
        }
        break;
      case "close":
        // handle close event
        //$("body").dimmer({
        //  closable : false,
        //  transition : "fade"
        //}).dimmer("show");
        //var test = "-10";
        //// console.log("value "+ test);
        //// console.log("convert to number "+ (parseInt(test) < 0 ? true : false));
        this.handleClose();
        this.router.navigateByUrl("/main/home");
        break;
      case "transmit":
        if (
          this.modelForm.vesselId != "" &&
          this.modelForm.baseVoyage != "" &&
          this.modelForm.vesselBound != ""
        ) {
          //this.handleTransmit();
          this.handlePreTransmit();
        } else {
          if (this.modelForm.vesselId == "") {
            this.message(
              "information",
              "Information",
              "Please input vessel!",
              "okonly",
              { ok: "" }
            );
          } else if (this.modelForm.baseVoyage == "") {
            this.message(
              "information",
              "Information",
              "Please input voyage!",
              "okonly",
              { ok: "" }
            );
          } else if (this.modelForm.vesselBound == "") {
            this.message(
              "information",
              "Information",
              "Please input bound!",
              "okonly",
              { ok: "" }
            );
          }
        }
        break;
      case "baplie":
        if (this.modelForm.vesselId != "" && this.modelForm.baseVoyage != "") {
          this.postData = {};
          this.openReceiveBaplieDialog();
        } else {
          this.message(
            "information",
            "Information",
            "Please insert vessel and voyage!",
            "okonly",
            { ok: "" }
          );
        }
        break;
    }
  }

  toolbarEvent2(event) {
    // console.log(event);
  }

  changeEventVessel(event) {
    if (event.vesselId != null) {
      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/" +
          this.modelForm.vesselBound.toUpperCase() +
          "/{query}/" +
          this.office.officeCode
      );
      this.cbVoyage.setForceValue("");
      this.modelForm["error-vesselName"] = "";
      this.modelForm.vesselId = event.vesselId;
      this.modelForm.vesselCode = event.vesselCode;
      this.modelForm.vesselName = event.vesselName;
      this.modelSailing.vesselId = event.vesselId;
    } else {
      this.modelForm["error-vesselName"] = "";
      this.modelForm.vesselId = "";
      this.modelForm.vesselCode = "";
      this.modelForm.vesselName = "";
    }
  }

  changeEventVesselNew(event) {
    if (event.vesselId != null) {
      this.modelNew["error-vesselName"] = "";

      // if (!this.newDialogFirstInit) {
      this.newCbVoyage.disableCombo = false;
      this.newCbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/" +
          this.modelNew.bound.toUpperCase() +
          "/{query}/" +
          this.office.officeCode
      );
      // } else {
      //   this.newDialogFirstInit = false;
      // }

      this.modelNew.vesselId = event.vesselId;
      this.modelNew.vesselCode = event.vesselCode;
      this.modelNew.vesselName = event.vesselName;
      this.modelNew.voyage = "";
      this.newCbVoyage.setValue("");

      if (this.newCbVessel.url.indexOf("vesselCode") > 0) {
        this.newCbVessel.setUrl(this.settingVesselNew.url1);
      }
    } else {
      if (this.formValidation.regVesVoy.test(event.target.value) == true) {
        this.modelNew["error-vesselName"] = "";
      }
      this.modelNew.vesselId = "";
      this.modelNew.vesselCode = "";
      this.modelNew.vesselName = "";
    }
  }

  changeEventVesselNext(event) {
    if (event.vesselId != null) {
      if (!this.newDialogFirstInit) {
        this.newNextCbVoyage.disableCombo = false;
        this.newNextCbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            event.vesselId +
            "/" +
            this.modelNew.bound.toUpperCase() +
            "/{query}/" +
            this.office.officeCode
        );
        this.newNextCbVoyage.setValue("");
      } else {
        this.newDialogFirstInit = false;
      }

      this.modelNew.nextVesselID = event.vesselId;
      this.modelNew.nextVesselCode = event.vesselCode;
      this.modelNew.nextVesselName = event.vesselName;
    } else {
      this.modelNew.nextVesselID = "";
      this.modelNew.nextVesselCode = "";
      this.modelNew.nextVesselName = "";
    }
  }

  changeEventVoyage(event) {
    if (event.voyage != null) {
      this.modelForm[`error-baseVoyage`] = "";
      this.modelForm.baseVoyage = event.voyage;
      this.modelForm.vesselBound = event.bound;
      this.modelSailing.voyage = event.voyage;
      console.log(this.modelForm.baseVoyage);
      var url =
        this.hitUrl +
        "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
        this.modelForm.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
        "/" +
        this.modelForm.vesselBound +
        "/" +
        this.office.officeCode +
        "/DESC/custom";
      this.genericService.GET(url).subscribe(
        (resp) => {
          if (resp.ok) {
            var result = resp.json()["content"][0];
            this.modelForm.etd = result["localETA"];
            this.modelForm.eta = result["localETD"];
          }
        },
        (error) => {}
      );
    } else {
      if (this.formValidation.regVesVoy.test(event.voyage) == true) {
        this.modelForm[`error-baseVoyage`] = "";
      }
      this.modelForm.baseVoyage = "";
    }
  }

  changeEventVoyageNew(event) {
    console.log(this.newCbVoyage.url);
    if (event.voyage != null) {
      this.modelNew["error-baseVoyage"] = "";
      this.modelNew.voyage = event.voyage;
      this.modelNew.bound = event.bound;
      this.modelSailing.voyage = event.voyage;
    } else {
      if (this.formValidation.regVesVoy.test(event.voyage) == true) {
        this.modelNew["error-baseVoyage"] = "";
      }
      this.modelNew.voyage = "";
    }
  }

  changeEventVoyageNext(event) {
    if (event.voyage != null) {
      this.modelNew.nextVoyage = event.voyage;
    } else {
      this.modelNew.nextVoyage = "";
    }
  }

  changeEventBooking(event) {
    // console.log(event);
    if (event.bNo != null && event.bno != "") {
      this.modelNew.bNo = event.prefix + event.bNo;
      this.modelNew.bprefix = event.prefix;
      this.modelNew.bofficeCode = this.office.officeCode;
    } else {
      this.modelNew.bNo = "";
      this.modelNew.bprefix = "";
      this.modelNew.bofficeCode = "";
    }
  }

  getDataCombo(event, value: string) {
    // console.log(event);
    if (value == "POL") {
      if (event.locationCode != null) {
        this.modelNew.loadPort = event.locationCode;
        this.modelNew.loadPortName = event.locationName;

        this.newCbPOD1.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/" +
            this.modelNew.loadPort +
            "/*"
        );
        this.newCbPOD2.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/" +
            this.modelNew.loadPort +
            "/*"
        );
        this.newCbPOD3.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/" +
            this.modelNew.loadPort +
            "/*"
        );
        this.newCbPOT.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/" +
            this.modelNew.loadPort +
            "/*"
        );
        this.newCbFD.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/" +
            this.modelNew.loadPort +
            "/*"
        );

        if (this.modelNew.discPort1 == this.modelNew.loadPort) {
          this.newCbPOD1.setValue("");
        }
        if (this.modelNew.discPort2 == this.modelNew.loadPort) {
          this.newCbPOD2.setValue("");
        }
        if (this.modelNew.discPort3 == this.modelNew.loadPort) {
          this.newCbPOD3.setValue("");
        }
        if (this.modelNew.transhipPort == this.modelNew.loadPort) {
          this.newCbPOT.setValue("");
        }
        if (this.modelNew.finalDest == this.modelNew.loadPort) {
          this.newCbFD.setValue("");
        }
      } else {
        this.modelNew.loadPort = "";
        this.modelNew.loadPortName = "";

        this.newCbPOD1.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*"
        );
        this.newCbPOD2.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*"
        );
        this.newCbPOD3.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*"
        );
        this.newCbPOT.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*"
        );
        this.newCbFD.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/*"
        );
      }
    } else if (value == "POD1") {
      if (event.locationCode != null) {
        this.modelNew.discPort1 = event.locationCode;
        this.modelNew.discPort1Name = event.locationName;
      } else {
        this.modelNew.discPort1 = "";
        this.modelNew.discPort1Name = "";
      }
    } else if (value == "POD2") {
      if (event.locationCode != null) {
        this.modelNew.discPort2 = event.locationCode;
        this.modelNew.discPort2Name = event.locationName;
      } else {
        this.modelNew.discPort2 = "";
        this.modelNew.discPort2Name = "";
      }
    } else if (value == "POD3") {
      if (event.locationCode != null) {
        this.modelNew.discPort3 = event.locationCode;
        this.modelNew.discPort3Name = event.locationName;
      } else {
        this.modelNew.discPort3 = "";
        this.modelNew.discPort3Name = "";
      }
    } else if (value == "FDest") {
      if (event.locationCode != null) {
        this.modelNew.finalDest = event.locationCode;
        this.modelNew.finalDestName = event.locationName;
      } else {
        this.modelNew.finalDest = "";
        this.modelNew.finalDestName = "";
      }
    } else if (value == "POT") {
      if (event.locationCode != null) {
        this.modelNew.transhipPort = event.locationCode;
        this.modelNew.transhipPortName = event.locationName;
        this.modelNew.tranship = "Y";
      } else {
        this.modelNew.transhipPort = "";
        this.modelNew.transhipPortName = "";
        this.modelNew.tranship = "N";
      }
      $("#tsCombo").dropdown("set selected", this.modelNew.tranship);
    } else if (value == "Shipper") {
      if (event.customerId != null) {
        this.modelNew.shipperID = event.customerId;
        this.modelNew.shipperName = event.customerName;
      } else {
        this.modelNew.shipperID = "";
        this.modelNew.shipperName = "";
      }
    } else if (value == "form-pol") {
      if (event.locationCode != null) {
        this.modelForm.portLoadCode = event.locationCode;
        this.modelForm.portLoadName = event.locationName;
        if (
          this.modelForm.vesselId != "" &&
          this.modelForm.baseVoyage != "" &&
          this.modelForm.vesselBound != ""
        ) {
          //var url = this.hitUrl + "/MasterBookings/getSailSchedule/"+this.modelForm.vesselId+"/"+this.modelForm.baseVoyage+"/"+this.modelForm.vesselBound+"/"+this.modelForm.portLoadCode;
          // this.genericService.GET(url).subscribe((resp)=>{
          //   if(resp.ok){
          //     var result = resp.json();
          //     this.modelForm.etd = result["ETD"];
          //   }
          // },error=>{
          // });
          //this.cbPOD.setUrl(this.configService.config.BASE_API.toString()+"/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/"+this.modelForm.portLoadCode+"/5");
          //this.cbFD.setUrl(this.configService.config.BASE_API.toString()+"/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/"+this.modelForm.portLoadCode+"/5");
        }
      } else {
        this.modelForm.portLoadCode = "";
        this.modelForm.portLoadName = "";
        this.modelForm.etd = "";

        //this.cbPOD.setUrl(this.configService.config.BASE_API.toString()+"/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/5");
        //this.cbFD.setUrl(this.configService.config.BASE_API.toString()+"/MasterLocations/findValidLocationsByLocationCodeOrLocationsNameExcludeOnePort/{query}/*/5");
      }
    } else if (value == "form-pod") {
      if (event.locationCode != null) {
        this.modelForm.portDischargeCode = event.locationCode;
        this.modelForm.portDischargeName = event.locationName;
        //this.modelForm.eta = event.arrivalDate;
      } else {
        this.modelForm.portDischargeCode = "";
        this.modelForm.portDischargeName = "";
        //this.modelForm.eta = "";
      }
    } else if (value == "form-fdest") {
      if (event.locationCode != null) {
        this.modelForm.finalDestinationCode = event.locationCode;
        this.modelForm.finalDestinationName = event.locationName;
      } else {
        this.modelForm.finalDestinationCode = "";
        this.modelForm.finalDestinationName = "";
      }
    } else if (value == "transfer-vessel") {
      if (event.vesselId != null) {
        this.modelTransfer.bound = "O";
        this.cbVoyageTo.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            event.vesselId +
            "/" +
            this.modelTransfer.bound.toUpperCase() +
            "/{query}/" +
            this.office.officeCode
        );
        this.modelTransfer.vesselId = event.vesselId;
        this.modelTransfer.vesselCode = event.vesselCode;
        this.modelTransfer.vesselName = event.vesselName;
      } else {
        this.modelTransfer.vesselId = "";
        this.modelTransfer.vesselCode = "";
        this.modelTransfer.vesselName = "";
      }
    } else if (value == "transfer-voyage") {
      if (event.voyage != null) {
        this.modelTransfer.voyage = event.voyage;
      } else {
        this.modelTransfer.voyage = "";
      }
    } else if (value == "PackageType") {
      if (event.packageTypeCode != null) {
        this.modelNew.pkgTypeId = event.packageTypeId;
        this.modelNew.pkgTypeCode = event.packageTypeCode;
        this.modelNew.pkgTypeName = event.packageTypeName;
      } else {
        this.modelNew.pkgTypeId = "";
        this.modelNew.pkgTypeCode = "";
        this.modelNew.pkgTypeName = "";
      }
    } else if (value == "Commodity") {
      if (event.commodityCode != null) {
        this.modelNew.commodityId = event.commodityId;
        this.modelNew.commodityCode = event.commodityCode;
        this.modelNew.commodityName = event.commodityName;
      } else {
        this.modelNew.commodityId = "";
        this.modelNew.commodityCode = "";
        this.modelNew.commodityName = "";
      }
    } else if (value == "SpecialDetail") {
      if (event.specialDetailId != null) {
        this.modelNew.spDetailsId = event.specialDetailId;
        this.modelNew.spDetails = event.specialDetailName;
        if (this.modelNew.spDetails.includes("BUNDLE")) {
          this.modelNew.bundle = true;
        }
        if (this.modelNew.bundle) {
          this.newCbSpecialDetail.disableCombo = true;
        }
      } else {
        this.modelNew.spDetailsId = "";
        this.modelNew.spDetails = "";
      }
    }
  }

  gridEvent(event) {
    console.log(event);
    var list = event.split("-");
    this.globalGridCllNumbers = list[1];
    switch (list[0]) {
      case "dblClick":
        this.gridCLL.listStore.store.forEach((fe) => {
          if (fe["no"] == list[1]) {
            // console.log(fe);
            this.loading = true;
            this.modelNew.processID = fe["processID"];
            this.handleNew();
            this.loading = false;
            this.process("ok");
            this.gridCLL.clearSelectedValues();
          }
        });
        break;
      case "afterLoad":
        if (this.sortMode) {
          this.sortMode = false;
          //this.gridCLL.onSort("default");
        }
        this.disableChkBoxAllContainer = false;

        var summaryUri =
          this.hitUrl +
          "/ContainerLoadList/getSummaryCllFlagStatus/" +
          this.modelForm.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
          "/" +
          this.modelForm.vesselBound.toUpperCase() +
          "/" +
          (this.modelForm.portLoadCode != ""
            ? this.modelForm.portLoadCode
            : "*") +
          "/" +
          (this.modelForm.portDischargeCode != ""
            ? this.modelForm.portDischargeCode
            : "*") +
          "/" +
          (this.modelForm.finalDestinationCode != ""
            ? this.modelForm.finalDestinationCode
            : "*") +
          "/" +
          (this.modelForm.containerNo != ""
            ? this.modelForm.containerNo.toUpperCase()
            : "*") +
          "/" +
          (this.modelForm.op != "" ? this.modelForm.op.toUpperCase() : "*") +
          "/" +
          (this.modelForm.so != "" ? this.modelForm.so.toUpperCase() : "*") +
          "/" +
          this.office.officeCode;
        this.genericService.GET(summaryUri).subscribe(
          (resp) => {
            if (resp.ok) {
              var dataSum = resp.json()["content"]["summary"];
              var allContainerDataList =
                resp.json()["content"]["containerDataList"];
              if (allContainerDataList.length > 0) {
                allContainerDataList.forEach((f) => {
                  this.cllFlagStatusContainerList.push(f);
                });
              }

              this.totalD20 = parseInt(dataSum["D20"]);
              this.totalD40 = parseInt(dataSum["D40"]);
              this.totalD45 = parseInt(dataSum["D45"]);
              this.totalHC20 = parseInt(dataSum["HC20"]);
              this.totalHC40 = parseInt(dataSum["HC40"]);
              this.totalHC45 = parseInt(dataSum["HC45"]);

              this.totalR20 = parseInt(dataSum["R20"]);
              this.totalR40 = parseInt(dataSum["R40"]);
              this.totalR45 = parseInt(dataSum["R45"]);
              this.totalRH20 = parseInt(dataSum["RH20"]);
              this.totalRH40 = parseInt(dataSum["RH40"]);
              this.totalRH45 = parseInt(dataSum["RH45"]);

              this.totalFull = parseInt(dataSum["FULL"]);
              this.totalEmpty = parseInt(dataSum["EMPTY"]);
              this.totalDG = parseInt(dataSum["DG"]);
              this.totalRF = parseInt(dataSum["RF"]);
              this.totalOOG = parseInt(dataSum["OOG"]);
              this.totalTank = parseInt(dataSum["TK"]);

              if (this.gridCLL.listStore.store.length == 0) {
                if (!this.uploadEventDiscrepancy) {
                  this.message(
                    "Information",
                    "information",
                    "No record(s) found.",
                    "okonly",
                    { ok: "" }
                  );
                } else {
                  this.uploadEventDiscrepancy = false;
                }
              }
            }
          },
          (error) => {
            if (this.gridCLL.listStore.store.length == 0) {
              this.message(
                "Information",
                "information",
                "No record(s) found.",
                "okonly",
                { ok: "" }
              );
            }
          }
        );

        break;
      case "sort":
        //this.sortMode = true;
        //this.sortField = list[1].split(";")[0];
        //this.direction = list[1].split(";")[1];
        //this.handleRetrieve();
        break;
      /*
      case "selected.checked" :
        if(this.gridCLL.getSelectedValues().length > 0){
          this.modeToolbar = true;
        }
        break;
      case "selected.unchecked" :
        if(this.gridCLL.getSelectedValues().length == 0){
          this.modeToolbar = false;
        }
        break;
      case "click.checked" :
        if(this.gridCLL.getSelectedValues().length > 0){
          this.modeToolbar = true;
        }
        break;
      case "click.unchecked" :
        if(this.gridCLL.getSelectedValues().length == 0){
          this.modeToolbar = false;
        }
        */
      default:
        break;
    }
  }

  summaryGridEvent(event) {}

  newGridEvent(event) {
    console.log(event);
    var list = event.split("-");
    switch (list[0]) {
      case "dblClick":
        var tempData = this.cloneStore(this.gridNew.listStore.store);
        tempData.forEach((fe) => {
          if (fe["no"] == list[1]) {
            console.log(fe);
            this.addContainer("newAddContainer");
            this.addContainerStatus = "edit";
            this.modelContainer = fe;
            this.setData(this.modelContainer, "newForm-addContainer-retrieve");
          }
        });

        //this.gridNew.listStore.store.forEach(fe=>{
        //})
        break;
      case "afterLoad":
       
        var length = this.gridNew.listStore.store.length;
        if (length == 1) {
          this.containerPlusButtonDisabled = true;
          this.containerMinusButtonDisabled = false;
        } else if (length < 1) {
          this.containerPlusButtonDisabled = false;
          this.containerMinusButtonDisabled = true;
        }

        
        break;
      default:
        switch (event.split(".")[0]) {
          case "selected":
            if (event.split(".")[1] != undefined) {
              var mode = event.split(".")[1].split("-")[0];
              var no = event.split(".")[1].split("-")[1];
              //console.log("kampreeet");
              //console.log(this.gridNew.is_select_all);
            }
            break;
          default:
            break;
        }
        break;
    }
  }

  infoGrid(event) {}

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  selectChange(object, option) {
    var selectedValue = object.options[object.selectedIndex].value;

    switch (option) {
      case "sign":
        this.modelNew.tempSign = selectedValue;
        // console.log("result"+this.modelNew.tempSign);
        break;
      case "degree":
        this.modelNew.tempDegree = selectedValue;
        break;

      case "flashsign":
        this.modelNew.flashSign = selectedValue;
        break;
      case "flashdegree":
        this.modelNew.flashDegree = selectedValue;
        this.modelNew.flashScale = selectedValue;
        break;

      case "uchdegree":
        this.modelNew.uchScale = selectedValue;
        break;
      case "ucldegree":
        this.modelNew.uclScale = selectedValue;
        break;
      case "ucwdegree":
        this.modelNew.ucwScale = selectedValue;
        break;
      case "ohdegree":
        this.modelNew.ohScale = selectedValue;
        break;
      case "dgClass":
        this.modelNew.dgClass = selectedValue;
      default:
        // code...
        break;
    }
  }


  onSelectNatureTemp(val) {
    console.log("kesini");
    this.modelNew.cllNatureTemp = val;
    // console.log(this.model.bReeferDegree);
  }

  changeEventSetOption(event) {
    this.modelNew.cllSetOption = event.target.value;
  }



  changeEventDegreeSO(event) {
    if (event.target.value != null) {
      let val = Number.parseInt(event.target.value);
      console.log("Nilai Val : "+val);
      if (isNaN(val)) {
        this.modelNew.cllDegreeSo = "";
      } else {
        this.modelNew.cllDegreeSo =
          this.cllDegreeSO.toString() + this.cllDegreeTempSO;
      }
    } else {
      this.modelNew.cllDegreeSo = "";
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  onSelectDegreeTempSO(val) {
    this.cllDegreeTempSO = val;
    if (this.modelNew.cllDegreeSo != "") {
      this.modelNew.cllDegreeSo =
        this.modelNew.toString() + this.cllDegreeTempSO;
    }
    // console.log(this.bookingExtra.bReeferDegree);
  }

  changeEventVent(event) {
    this.modelNew.cllVent = event.target.value;
    if (this.modelNew.cllVent == "O") {
      this.lockVentNumber = false;
    } else {
      this.modelNew.cllVent = "";
      this.lockVentNumber = true;
      this.modelNew.cllVentNumber = 0;
    }
  }

  onSelectVentOption(val) {
    this.modelNew.cllVentOption = val;
  }


  setSelectValue(id, value) {
    $("#" + id + " option[value='" + value + "']").prop("selected", true);
  }

  textAreaChange(object, option) {
    var changeValue = object.value;

    switch (option) {
      case "spDetails":
        this.modelNew.spDetails = changeValue;
        break;
      case "dgCommodityDesc":
        this.modelNew.dgCommodityDesc = changeValue;
        break;
      case "reeferCommodityDesc":
        this.modelNew.reeferCommodityDesc = changeValue;
        break;
      case "remarks":
        this.modelNew.remarks = changeValue;
        break;
      default:
        break;
    }
  }

  checkboxEvent(type: string, checked: boolean) {
    console.log("TYPE : "+type);
    if (type == "isSSL") {
      this.modelNew.isSSL = checked;
    } else if (type == "isBundle") {
      this.modelNew.bundle = checked;
      if (this.modelNew.bundle) {
        // console.log("is bundle value is true");
        //this.newCbSpecialDetail.setValue("BUNDLE");
        this.newCbSpecialDetail.setForceValue("BUNDLE");
        this.modelNew.spDetails = "BUNDLE";
        this.modelNew.spDetailsId = "SD00023";
      } else {
        // console.log("is bundle value is false");
        //this.newCbSpecialDetail.setValue("");
        this.newCbSpecialDetail.setForceValue("");
        this.modelNew.spDetails = "";
        this.modelNew.spDetailsId = "";
        this.newCbSpecialDetail.disableCombo = false;
      }
    } else if (type == "isReefer") {
      if(checked){
      console.log("CHECKED NOT CHECKED : "+checked);
        this.modelNew.reefer = checked;
        console.log("ojan " + this.cllSetOption);
        this.lockReefer= false;
        this.lockReeferDry = true;
        if(this.modelNew.cllSetOption == "" || this.modelNew.cllSetOption == null){
          this.modelNew.cllSetOption = "C";
        }else{
          this.modelNew.cllSetOption = this.cllSetOption;
        }
        if(this.modelNew.cllVent == "" || this.modelNew.cllVent == null){
          this.modelNew.cllVent = "C";
        }else{
          this.modelNew.cllVent = this.cllVent;
        }
        if (!this.modelNew.reefer) {
          this.modelNew.reeferTemp = "0";
          this.modelNew.tempSign = "+";
          this.modelNew.tempDegree = "C";
          $("#temp-sign").val(this.modelNew.tempSign);
          $("#temp-Degreee").val(this.modelNew.tempDegree);
        } else {
          if (this.modelNew.status == "EMPTY") {
            this.modelNew.reeferTemp = "";
            this.modelNew.tempSign = "";
            this.modelNew.tempDegree = "";
            $("#temp-sign").val(this.modelNew.tempSign);
            $("#temp-Degreee").val(this.modelNew.tempDegree);
          } else {
            this.modelNew.reeferTemp = "000.00";
            this.modelNew.tempSign = "+";
            this.modelNew.tempDegree = "C";
            $("#temp-sign").val(this.modelNew.tempSign);
            $("#temp-Degreee").val(this.modelNew.tempDegree);
          }
        }
      }else{
        this.modelNew.reefer = checked;
        this.lockReefer= true;
        this.modelNew['reeferTemp'] = '0';
        this.modelNew['tempDegree'] = 'C';
        this.modelNew['tempSign'] = '+';
        this.modelNew['cllVentNumber'] = 0;
        this.modelNew['cllDegreeTempSO'] = 'C';
        this.modelNew['cllHumidity'] = '';
        this.modelNew['cllSetOption'] = '';
        this.modelNew['cllVent'] = '';
        this.modelNew['cllVentOption'] = 'O';
        this.cllDegreeTempSO = 'C';
        this.cllDegreeSO = NaN;
        this.modelNew['reeferCommodityDesc'] = '';
        this.refDmdDesc.nativeElement.value = '';
        this.modelNew['cllNatureTemp'] = '';
      }
    } else if (type == "isDG") {
      this.modelNew.dg = checked;
      if (this.modelNew.dg) {
        this.newCbPackageType.disableCombo = false;
        //this.modelNew.dgClass = "N";
        $("#dgClass").autocomplete({
          source: this.defaultDgClassList,
        });
        //$("#dgClass").val(this.modelNew.dgClass);
      } else {
        this.modelNew.imo = "";
        this.modelNew.unno = "";
        this.modelNew.flashPoint = "0";
        this.newCbPackageType.setForceValue("");
        this.modelNew.pkgTypeName = "";
        this.modelNew.pkgTypeCode = "";
        this.modelNew.pkgTypeId = "";
        this.newCbPackageType.disableCombo = true;
        this.modelNew.flashSign = "+";
        this.modelNew.flashScale = "C";
        this.modelNew.dgClass = "";
        $("#dgClass").val(this.modelNew.dgClass);
        $("#flash-sign").val(this.modelNew.flashSign);
        $("#flash-Degreee").val(this.modelNew.flashScale);
      }
    } else if (type == "isReeferAsDry") {
      this.modelNew.reeferAsDry = checked;
      this.lockReefer= true;
      this.lockReeferDry = false;
      this.modelNew.cllSetOption = "N";
      this.modelNew.cllVent = "N";

    } else if (type == "isUC") {
      this.modelNew.uc = checked;
      if (!this.modelNew.uc) {
        this.modelNew.uch = 0;
        this.modelNew.ucl = 0;
        this.modelNew.ucw = 0;
        this.modelNew.uchScale = "CM";
        this.modelNew.uclScale = "CM";
        this.modelNew.ucwScale = "CM";
        $("#uch-Degreee").val(this.modelNew.uchScale);
        $("#ucl-Degreee").val(this.modelNew.uclScale);
        $("#ucw-Degreee").val(this.modelNew.ucwScale);
      }
    } else if (type == "isOOG") {
      this.modelNew.oog = checked;
      if (!this.modelNew.oog) {
        this.modelNew.oh = "0";
        this.modelNew.owr = "0";
        this.modelNew.owl = "0";
        this.modelNew.olf = "0";
        this.modelNew.olb = "0";
        this.modelNew.ohScale = "CM";
        $("#oh-Degreee").val(this.modelNew.ohScale);
      }
    } else if (type == "multipleDG") {
      //this.modelNew.multipleDGIndicator = checked;
    }
  }

  onCllDialogUploadConfirmYes() {
    this.loadingUpload = true;
    this.uploadPlg.onUpload();
    this.uplReplace = true;
    this.genericUtil.closeDialog("cllDialogUploadConfirm");
  }

  onCllDialogUploadConfirmNo() {
    this.frmDialogMessage = "";
    this.genericUtil.closeDialog("cllDialogUploadConfirm");
  }

  uploadEvent(event) {
    // console.log(event);
    var list;
    var ext;
    if (event.includes(".xls")) {
      list = event.split(".xls");
      ext = ".xls";
    } else if (event.includes(".XLS")) {
      list = event.split(".XLS");
      ext = ".XLS";
    }
    //var list = event.split(".xls");
    //list[0] = list[0].replace('.',' '); //Patch GMO-50

    switch (event) {
      case "success":
        this.loadingUpload = true;
        if (!this.uplReplace) {
          this.retrieveUploadedData();
        } else {
          this.uplReplace = false;
          this.retrieveUploadedData("replace");
        }
        break;
      case "button;replace":
        // console.log("button replace clicked");
        /*
        this.loadingUpload = true;
        this.uploadPlg.onUpload();
        this.uplReplace = true;
        */
        this.frmDialogMessage =
          "Do you really want to replace all existing data?";
        this.genericUtil.showDialog(
          "cllDialogUploadConfirm",
          "Confirm",
          350,
          150
        );
        //this.retrieveUploadedData("replace");
        break;
      default:
        // console.log(list[1]);
        switch (list[1].trim().split("-")[1].trim()) {
          case "uploaded":
            this.fileName = list[0] + ext;
            this.postData["officeCode"] = this.office.officeCode;
            this.postData["userCreated"] = this.userID;
            this.postData["dateCreated"] = this.genericUtil.getPSIDate();
            this.postData["fileName"] = this.fileName;
            this.postData["vesselId"] = this.modelForm.vesselId;
            this.postData["vesselCode"] = this.modelForm.vesselCode;
            this.postData["voyage"] = this.modelForm.baseVoyage;

            // console.log(this.postData);
            this.uploadPlg.setDisabled(false);
            break;
        }
        break;
    }
  }

  uploadEventCellLocations(event) {
    // console.log("uploadEventCellLocations");
    // console.log(event);
    var list = event.split(".xls");
    switch (event) {
      case "success":
        this.loadingUpload = true;
        this.receiveCellLocation();
        break;
      default:
        // console.log(list[1]);
        switch (list[1].trim().split("-")[1].trim()) {
          case "uploaded":
            this.fileName = list[0] + ".xls";
            this.postData["officeCode"] = this.office.officeCode;
            this.postData["userCreated"] = this.userID;
            this.postData["dateCreated"] = this.genericUtil.getPSIDate();
            this.postData["fileName"] = this.fileName;
            this.postData["vesselId"] = this.modelForm.vesselId;
            this.postData["vesselCode"] = this.modelForm.vesselCode;
            this.postData["voyage"] = this.modelForm.baseVoyage;

            // console.log(this.postData);
            this.uploadPlgCellLocation.setDisabled(false);
            break;
        }
        break;
    }
  }

  uploadBaplieProcess() {
    var uri = this.hitUrl + "/ContainerLoadList/bapeli/cll/processData";
    this.genericService.POST(uri, this.postData).subscribe((resp) => {
      if (resp.ok) {
        var message = resp.json()["message"];
        if (message == "success") {
          this.message(
            "information",
            "Information",
            "File Uploaded",
            "okonly",
            { ok: "" }
          );
          this.closeReceiveBabplie();
          this.handleRetrieve("default;DESC");
          //this.gridCLL.onSort("id");
          //this.retrieveAfterUpload();
          //this.gridCLL.loadData();
          this.loadingUploadBaplie = false;
        } else if ((message = "not update")) {
          this.message(
            "information",
            "information",
            "no cell location has been updated",
            "okonly",
            { ok: "" }
          );
          this.closeReceiveBabplie();
          this.loadingUploadBaplie = false;
        } else if (message == "not empty") {
          this.message(
            "information",
            "Information",
            "Cll for vessel " +
              this.modelForm.vesselName +
              " (" +
              this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
              ") is not empty",
            "okonly",
            { ok: "" }
          );
          this.loadingUploadBaplie = false;
        }
      }
    });
  }

  uploadEventBaplie(event) {
    console.log(event);
    var list = event.split("-");
    switch (event) {
      case "success":
        this.loadingUploadBaplie = true;
        this.uploadBaplieProcess();
        break;
      default:
        switch (list[1].trim()) {
          case "uploaded":
            this.fileName = list[0].trim();
            this.postData["officeCode"] = this.office.officeCode;
            this.postData["userCreated"] = this.userID;
            this.postData["dateCreated"] = this.genericUtil.getPSIDate();
            this.postData["fileName"] = this.fileName;
            this.postData["vesselId"] = this.modelForm.vesselId;
            this.postData["vesselCode"] = this.modelForm.vesselCode;
            this.postData["voyage"] = this.modelForm.baseVoyage;

            // console.log(this.postData);
            this.uploadPlgBaplie.setDisabled(false);
            break;
        }
        break;
    }
  }

  openReceiveBaplieDialog() {
    $("#receiveBaplieDialogId")
      .dialog({
        title: "Receive CLL (BAPLIE)",
        modal: true,
        width: 1020,
        height: "auto",
        open: function (event, ui) {
          //hide close button.
          //// console.log($(this).css("z-index","102"));
          $(".ui-dialog").css("z-index", 103);
          $(".ui-widget-overlay").css("z-index", 102);
          $(this)
            .parent()
            .children()
            .children(".ui-dialog-titlebar-close")
            .hide();
          $("body").first().css("overflow", "hidden");
        },
        close: function (event, ui) {
          $("body").first().css("overflow", " auto");
        },
      })
      .dialog("open");

    this.uploadPlgBaplie.setDisabled(true);
  }

  closeReceiveBabplie() {
    this.uploadPlgBaplie.store = [];
    this.disableErrorButton2 = true;

    this.closeDialog("receiveBaplieDialogId");
  }

  errorReportReceiveBaplie() {}

  changeEventVesselUpload(event) {
    // console.log(event);
    //this.cbVoyageUpload.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.modelNew.bound.toUpperCase()+"/{query}/"+this.office.officeCode);
  }

  changeEventVoyageUpload(event) {
    // console.log(event);
  }

  gridEventUpload(event) {
    // console.log(event);
    var list = event.split("-");
    switch (list[0]) {
      case "dblClick":
        this.gridCLLUpload.listStore.store.forEach((fe) => {
          if (fe["no"] == list[1]) {
            this.loading = true;
            this.modelNew.processID = fe["processID"];
            this.handleNew();
            this.loading = false;
            this.process("ok");
          }
        });
        break;
      default:
        break;
    }
  }

  gridEventBatch(event) {
    // console.log(event);
  }

  gridEventBatchHeader(event) {
    console.log("supriit");
    console.log(event);
    this.gridCllBatchHeader.hideGridComboSugestionBox();
    if (event.includes("AfterEdit")) {
      this.errorBatchMessage = "";
      var dataSplit = event.split(";");
      if (dataSplit[1] == "shipperName") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["customerId"] != null) {
            fe.shipperID = fe["customerId"];
            fe.shipperName = fe["customerName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "spDetails") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["specialDetailId"] != null) {
            fe.spDetailsId = fe["specialDetailId"];
            fe.spDetails = fe["specialDetailName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "cellLocation") {
        // console.log("update cell location");
        var typeSize = this.gridCllBatchHeader.listStore.store[0].typeSize;
        // console.log("type size "+typeSize);
        var char2first: number = parseFloat(dataSplit[2].substr(0, 2));
        var cellReset: boolean = false;
        if (dataSplit[2].length > 6) {
          this.message(
            "information",
            "Information",
            "Invalid cell location. Please enter a valid one",
            "okonly",
            { ok: "" }
          );
          cellReset = true;
        } else if (typeSize.includes("20")) {
          if (char2first % 2 == 0) {
            this.message(
              "information",
              "Information",
              "Invalid cell location. Please enter a valid one",
              "okonly",
              { ok: "" }
            );
            cellReset = true;
          }
        } else if (typeSize.includes("40") || typeSize.includes("45")) {
          if (char2first % 2 != 0) {
            this.message(
              "information",
              "Information",
              "Invalid cell location. Please enter a valid one",
              "okonly",
              { ok: "" }
            );
            cellReset = true;
          }
        }

        if (cellReset) {
          this.gridCllBatchHeader.listStore.store.forEach((fe) => {
            if (fe.cellLocation != null) {
              if (fe.cellLocation != "") {
                fe.cellLocation = "";
              }
            }
          });
        }
      } else if (dataSplit[1] == "loadPortName") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.loadPort = fe["locationCode"];
            fe.loadPortName = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "transhipPortName") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.transhipPort = fe["locationCode"];
            fe.transhipPortName = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "discPort1Name") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.discPort1 = fe["locationCode"];
            fe.discPort1Name = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "discPort2Name") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.discPort2 = fe["locationCode"];
            fe.discPort2Name = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "discPort3Name") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.discPort3 = fe["locationCode"];
            fe.discPort3Name = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "finalDestName") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["locationCode"] != null) {
            fe.finalDest = fe["locationCode"];
            fe.finalDestName = fe["locationName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else if (dataSplit[1] == "pkgTypeCode") {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe["packageTypeName"] != null) {
            fe.pkgTypeCode = fe["packageTypeId"];
            fe.pkgTypeName = fe["packageTypeName"];
            this.gridCllBatchHeader.hideGridComboSugestionBox2();
          }
        });
      } else {
        this.gridCllBatchHeader.listStore.store.forEach((fe) => {
          if (fe[dataSplit[1]] != "") {
            fe[dataSplit[1]] = fe[dataSplit[1]].toUpperCase();
          }
        });
      }
    }

    //console.log("test combo");
    //console.log(" "+$("#cllGridBatchHeaderId").find("#resultss-cllGridBatchHeader").length);
    //$("#cllGridBatchHeaderId").find("#resultss-cllGridBatchHeader").attr("style","display:none!important");
    //ythis.gridCllBatchHeader.hideGridComboSugestionBox2();
  }

  eventCheckBox(event, field, name) {
    var srcElement = event.srcElement;
    var addColumn = {
      header: name,
      field: field,
      width: 50,
    };
    if (srcElement.checked) {
      this.gridCllBatchHeader.columns.push(addColumn);
    } else {
      this.gridCllBatchHeader.columns.splice(
        this.getIndexfromColumn(this.gridCllBatchHeader, field),
        1
      );
    }
  }

  eventCheckBoxNew(event, state, field, name) {
    var srcElement = event.srcElement;
    var addColumn = {};
    addColumn["header"] = name;
    addColumn["field"] = field;
    addColumn["width"] = 100;

    // if(srcElement.checked){
    //   this.gridCllCopyHeader.columns.push(addColumn);
    // } else {
    //   this.gridCllCopyHeader.columns.splice(this.getIndexfromColumn(this.gridCllCopyHeader, field),1);
    // }

    var grid: GridPluginComponent =
      state == "batch"
        ? this.gridCllBatchHeader
        : state == "copy"
        ? this.gridCllCopyHeader
        : null;
    if (grid != null) {
      if (srcElement.checked) {
        grid.columns.push(addColumn);
      } else {
        grid.columns.splice(this.getIndexfromColumn(grid, field), 1);
      }
    }
  }

  eventCheckBoxNew1(event, state, field, name) {
    var srcElement = event.srcElement;
    var id = "";
    var grid: GridPluginComponent;
    var col;
    if (state == "copy") {
      id = "copyCllId";
      col = this.settingGridCopyHeader;
      grid = this.gridCllCopyHeader;
    } else if (state == "batch") {
      id = "batchCllId";
      col = this.settingGridBatchHeader;
      grid = this.gridCllBatchHeader;
    }

    col.columns.forEach((fe) => {
      if (fe.field == field) {
        if (srcElement.checked) {
          fe.status = 1;
          /*if(state == "copy"){
            grid.listStore.store[0][field] = this.defaultCopyGrid[field];
          }*/
        } else {
          fe.status = 0;
          if (state == "batch") {
            grid.listStore.store[0][field] = this.defaultCllGrid[field];
          }
        }
      }
    });

    if (srcElement.checked) {
      grid.hideColumns(false, field);
    } else {
      grid.hideColumns(true, field);
    }
  }

  hideCheckBox(type, id, store) {
    var grid = ([] = store);

    var i = 1;
    grid.forEach((fe) => {
      // console.log("hide status : "+fe.status+ " field : "+fe.field);
      if (fe.status == 0) {
        $("#" + id)
          .find("#editable-" + type)
          .find("thead")
          .find("tr")
          .find("th")
          .each(function () {
            if ($(this).attr("id") == "th." + fe.field) {
              $(this).css("display", "none");
            }
          });
        $("#" + id)
          .find("#editable-" + type)
          .find("tbody")
          .find("tr")
          .find("td")
          .each(function () {
            if ($(this).attr("id") == "s." + i + "." + fe.field) {
              $(this).css("display", "none");
            }
          });
      }
    });
  }

  getIndexfromColumn(grid: GridPluginComponent, field) {
    var counter = 0;
    var index = 0;
    grid.columns.forEach((fe) => {
      if (fe["field"] == field) {
        index = counter;
      }
      counter++;
    });

    return index;
  }

  gridEventCopy(event) {
    // console.log("boom = ", event);
    if (event.includes(";")) {
      var split = event.split(";");
      if (split[0].includes("AfterEdit")) {
        this.copyButtonDisable = false;
        var cnNumber = this.removeSpace(split[2].trim());
        // console.log("container number : "+this.removeSpace(split[2].trim()) );
        /*if(split[2].trim().substr(4,1) == " "){
          cnNumber = split[2].trim().split(" ")[0]+split[2].trim().split(" ")[1];
        } else {
          cnNumber = split[2].trim();
        }*/
        if(cnNumber.length > 0 ){
          cnNumber = cnNumber.toUpperCase();
          var urlCheck =
            this.hitUrl +
            "/ContainerLoadList/checkContainerNumber/" +
            this.office.officeCode +
            "/" +
            this.modelForm.vesselId +
            "/" +
            this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
            "/" +
            cnNumber;
          this.gridCllCopy.loading = true;
          this.genericService.GET(urlCheck).subscribe((resp) => {
            if (resp.ok) {
              //var copystore = this.gridCllCopy.listStore.store;
              if (resp.json()["message"] == "container exist") {
                this.message(
                  "information",
                  "Information",
                  "Duplicate container!",
                  "okonly",
                  { ok: "" }
                );
                this.gridCllCopy.listStore.store[split[0].split(".")[1] - 1][
                  "containerNo"
                ] = "";
                this.copyButtonDisable = true;
              }

              var copystore = this.cloneStore(this.gridCllCopy.listStore.store);
              var i = 0;
              copystore.forEach((fe) => {
                if (fe.containerNo == "") {
                  //// console.log("yooo");
                  this.gridCllCopy.listStore.store.splice(i, 1);
                }
                i++;
              });

              var self = this;
              setTimeout(function () {
                self.gridCllCopy.listStore.store.push({ containerNo: "" });
                self.gridCllCopy.loadData();

                self.gridCllCopy.loading = false;
              }, 100);
            }
          });
        }
      } else if (split[0] == "edit") {
        this.copyButtonDisable = true;
      }
    }
  }

  gridEventCopyHeader(event) {
    // console.log(event);
  }

  gridEventTransfer(event) {}

  gridEventUpdateCelLoc(event) {
    // console.log(event);
    if (event.includes("AfterEdit")) {
      var cellLoc: string = event.split(";")[2];
      var gridIndex: number = parseInt(event.split(";")[0].split(".")[1]);
      var typeSize: string = "";
      var char2first: number = parseFloat(cellLoc.substr(0, 2));
      this.gridCllUpdateCelLoc.listStore.store.forEach((fe) => {
        if (fe.no == gridIndex) {
          typeSize = fe.typeSize;
        }
      });
      this.transferButtonDisable = false;
      if (cellLoc.length > 6) {
        this.message(
          "information",
          "Information",
          "Invalid cell location. Please enter a valid one",
          "okonly",
          { ok: "" }
        );
        this.transferButtonDisable = true;
      } else if (typeSize.includes("20")) {
        if (char2first % 2 == 0) {
          this.message(
            "information",
            "Information",
            "Invalid cell location. Please enter a valid one",
            "okonly",
            { ok: "" }
          );
          this.transferButtonDisable = true;
        }
      } else if (typeSize.includes("40") || typeSize.includes("45")) {
        if (char2first % 2 != 0) {
          this.message(
            "information",
            "Information",
            "Invalid cell location. Please enter a valid one",
            "okonly",
            { ok: "" }
          );
          this.transferButtonDisable = true;
        }
      }
    }
  }

  gridEventAdditionalDG(event) {
    // console.log(event);
  }

  eventDropDown(type: string, value: string) {
    // console.log("event drop down");
    // console.log(type);
    console.log(value);
    if (type == "cmethod") {
      if (value == "") {
        value = "---";
      }
    }

    if (value != "") {
      // console.log("thru here");
      if (type == "ctype") {
        console.log("fire type");
        console.log(value);
        this.modelContainer.containerType = value;
        console.log("LOG TYPE : "+this.modelContainer.containerType);
        if (this.modelContainer.containerType == "UC") {
          this.sizeComboLoading = false;
          this.sizeComboDisabled = true;

          this.modelContainer.typeSize = "";
          this.modelContainer.containerSize = "";
          this.modelContainer.containerHeight = "";
        } else {        
         
          
          this.sizeComboLoading = true;
          this.loadContainerDropDown(value);

          

        }
      } else if (type == "csize") {
        // console.log("fire size");
        // console.log(value);
        this.modelContainer.typeSize = value;
        this.modelContainer.containerSize =
          this.modelContainer.typeSize.substr(-2);
        if (this.modelContainer.typeSize.includes("H")) {
          this.modelContainer.containerHeight = "96";
        } else {
          this.modelContainer.containerHeight = "86";
        }
      } else if (type == "cmethod") {
        if (value == "---") {
          this.modelContainer.containerVgmMethod = "";
        } else {
          this.modelContainer.containerVgmMethod = value; //containerVgmMethod
        }
      } else if (type == "cstatus") {
        this.modelNew.status = value;
      } else if (type == "ctranship") {
        this.modelNew.tranship = value;
        // console.log("trannship "+this.modelNew.tranship);
      } else if (type == "cthru") {
        this.modelNew.thruSpt = value;
        if (value == "Y") {
          this.bookingRefDisabled = false;
        } else {
          this.bookingRefDisabled = true;
        }
      } else if (type == "ctype2") {
        this.modelContainer.containerTypeName = value;
      }
    }
  }

  textFieldEvent(type, value) {
    // console.log(type+" - "+ value);
    switch (type) {
      case "op":
        if (value.toUpperCase() == "AX") {
          this.bookingRefDisabled = false;
        } else {
          this.bookingRefDisabled = true;
        }
        break;
      default:
        break;
    }
  }

  onDoubleClick(no, field, value, colType, type) {
    // console.log("dbl click event");
    // console.log("no : "+no);
    // console.log("field : "+field);
    // console.log("value : "+value);
    // console.log("colType "+colType);
    // console.log("Type "+type);
    var self = this;
    var id = "";
    if (type == "copy") {
      id = "copyCllId";
    } else if (type == "batch") {
      id = "batchCllId";
    }

    $("#" + id)
      .find("#editable-" + type)
      .find("tbody")
      .find("tr")
      .find("td")
      .each(function () {
        if ($(this).attr("id") == "s." + no + "." + field) {
          $(this).find("span").hide();
          if (colType == "text") {
            $(this).find("input").first().show();
            $(this).find("input").first().focus();
          } else if (colType == "select") {
            $(this).find("select").show();
            $(this).find("select").val(value);
            $(this).find("select").focus();
            $(this)
              .find("select")
              .blur(function () {
                // console.log("sompreeet");
                self.selectBlur(no, field, type, $(this).find("select").val());
              });
          } else if (colType == "select search") {
            $(this).find("#test").show();
          }
        }
      });
  }

  textBlur(event, no, field, type) {
    // console.log("blur event");
    // console.log(event);
    var srcElement = event.srcElement;
    var self = this;
    var id = "";
    var val: string = srcElement.value;
    if (type == "copy") {
      id = "copyCllId";
    } else if (type == "batch") {
      id = "batchCllId";
    }

    $("#" + id)
      .find("#editable-" + type)
      .find("tbody")
      .find("tr")
      .find("td")
      .each(function () {
        if ($(this).attr("id") == "s." + no + "." + field) {
          $(this).find("span").text(val.toUpperCase());
          $(this).find("span").show();
          $(this).find("input").first().hide();
          self.updateStore(type, no, field, val.toUpperCase());
        }
      });
  }

  selectChanges(event, no, field, type) {
    // console.log("select event");
    // console.log(event);
    var srcElement = event.srcElement;
    var self = this;
    var id = "";
    if (type == "copy") {
      id = "copyCllId";
    } else if (type == "batch") {
      id = "batchCllId";
    }

    $("#" + id)
      .find("#editable-" + type)
      .find("tbody")
      .find("tr")
      .find("td")
      .each(function () {
        if ($(this).attr("id") == "s." + no + "." + field) {
          $(this).find("span").text(srcElement.value);
          $(this).find("span").show();
          $(this).find("select").hide();
          self.updateStore(type, no, field, srcElement.value);
        }
      });
  }

  selectBlur(no, field, type, value) {
    // console.log("blur select event");
    var id = "";
    if (type == "copy") {
      id = "copyCllId";
    } else if (type == "batch") {
      id = "batchCllId";
    }

    $("#" + id)
      .find("#editable-" + type)
      .find("tbody")
      .find("tr")
      .find("td")
      .each(function () {
        if ($(this).attr("id") == "s." + no + "." + field) {
          $(this).find("span").show();
          $(this).find("select").hide();
        }
      });
  }

  updateStore(type, no, field, value) {
    var store = [];
    if (type == "copy") {
      store = this.copyData;
    } else if (type == "batch") {
      store = this.batchData;
    }
    var val: string = value;

    store.forEach((fe) => {
      if (fe.no == no) {
        fe[field] = val.toUpperCase();
      }
    });

    // console.log("store result");
    // console.log(store);
  }

  removeSpace(cNumber: string) {
    var strLen = cNumber.length;
    var result = "";
    for (var i = 0; i < strLen; i++) {
      if (cNumber.substr(i, 1) != " ") {
        result = result + cNumber.substr(i, 1);
      }
    }

    return result;
  }

  cloneStore(storeToCopy: any) {
    //var result = JSON.parse(JSON.stringify( storeToCopy ));
    //return result;
    var copy = storeToCopy,
      k;

    if (storeToCopy && typeof storeToCopy === "object") {
      copy =
        Object.prototype.toString.call(storeToCopy) === "[object Array]"
          ? []
          : {};
      for (k in storeToCopy) {
        copy[k] = this.cloneStore(storeToCopy[k]);
      }
    }

    return copy;
  }

  handlePreTransmit() {
    this.genericUtil.showLoader();
    var postData = {};
    postData["vesselId"] = this.modelForm.vesselId;
    postData["voyage"] = this.modelForm.baseVoyage;
    postData["bound"] = this.modelForm.vesselBound;
    postData["office"] = this.office.officeCode;
    postData["userId"] = this.userID;

    var uri =
      this.hitUrl +
      "/ContainerLoadList/preTransmit/cll/" +
      postData["office"] +
      "/" +
      postData["vesselId"] +
      "/" +
      this.sCharUtil.htmlEncode(String(postData["voyage"]).trim()) +
      "/" +
      postData["bound"] +
      "/" +
      postData["userId"];
    //var ctrUri = this.hitUrl + "/MasterContainerTypes/getContainerType/tags/";
    this.genericService.GET(uri).subscribe(
      (resp) => {
        if (resp.ok) {
          var message = resp.json()["message"];
          if (message == "pass") {
            this.handleTransmit();
          } else {
            this.showPreTransmitCheckReport();
          }
        }
      },
      (error) => {
        this.genericUtil.hideLoader();
      }
    );
  }

  showPreTransmitCheckReport() {
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/cll_duplicate_container_list_report.pdf?officeCode=" +
      this.office.officeCode +
      "&vesselId=" +
      this.modelForm.vesselId +
      "&voyage=" +
      this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
      "&bound=" +
      this.modelForm.vesselBound;
    var randomNumber = this.genericUtil
      .getPSIDate()
      .replace(/-/g, "")
      .replace(/:/g, "")
      .replace(" ", "");
    var fileName =
      this.modelForm.vesselCode +
      "-" +
      this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
      "-" +
      randomNumber +
      ".pdf";

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl, postData).subscribe(
      (resp) => {
        if (resp.ok) {
          var result = resp.json();

          if (result["message"] == "success") {
            this.pdfContainer.setPDFURL(
              this.configService.config.pdfUrl + result["filePath"]
            );
            this.pdfContainer.showPDF();
          }

          this.loading = false;
          this.genericUtil.hideLoader();
        }
      },
      (error) => {
        this.genericUtil.hideLoader();
      }
    );
  }

  handleTransmit() {
    var postData = {};
    postData["vesselId"] = this.modelForm.vesselId;
    postData["voyage"] = this.modelForm.baseVoyage;
    postData["bound"] = this.modelForm.vesselBound;
    postData["office"] = this.office.officeCode;
    postData["date"] = this.genericUtil.getPSIDate();

    var splitUrl = this.configService.config.getPDFUrl.split(":");
    var uri =
      this.hitUrl +
      "?q=/ContainerLoadList/transmit/cll/" +
      postData["office"] +
      "/" +
      postData["vesselId"] +
      "/" +
      this.sCharUtil.htmlEncode(String(postData["voyage"]).trim()) +
      "/" +
      postData["bound"] +
      "/" +
      postData["date"];
    var fileName =
      this.modelForm.vesselCode +
      " " +
      this.sCharUtil.htmlEncode(String(this.modelForm.baseVoyage)) +
      "." +
      this.genericUtil.getPSIDate() +
      ".csv";

    this._http
      .get(uri, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe((res) => {
        FileSaver.saveAs(res, fileName);
        this.genericUtil.hideLoader();
      });

    //window.open(uri);
  }

  errorReportCLL() {
    if (this.errorFileName != "") {
      //window.open(this.configService.config.pdfUrl +  this.errorFileName);
      if (this.errorFileName.includes(".xls")) {
        this.downloadFileFromServer(
          this.errorFileName.split(".xls")[0],
          "xls",
          this.loadingUpload
        );
      } else if (this.errorFileName.includes(".XLS")) {
        this.downloadFileFromServer(
          this.errorFileName.split(".XLS")[0],
          "XLS",
          this.loadingUpload
        );
      }
    }
  }

  errorReportReceiveCellLocation() {
    if (this.errorFileName != "") {
      //window.open(this.configService.config.pdfUrl + this.errorFileName);
      this.downloadFileFromServer(
        this.errorFileName.split(".")[0],
        this.errorFileName.split(".")[1],
        this.loadingUpload
      );
    }
  }

  openAdditionalDG() {
    this.showDialog("multipleDGId", this.additionalDGDialogOpt);
  }

  cancelMultipleDG() {
    this.closeDialog("multipleDGId");
  }

  testFormated(object, cntBfr, cntAfr, type?: string) {
    var formattedNumber = "";
    var value = object.value;
    if (value.toString().includes(".")) {
      var st = value.toString().split(".");
      if (st.length > 2) {
        this.message(
          "information",
          "Information",
          "incorrect numbered format.",
          "okonly",
          { ok: "" }
        );
        formattedNumber = "0";
      } else {
        formattedNumber =
          this.numberPad(st[0], cntBfr) + "." + this.numberDec(st[1], cntAfr);
      }
    } else {
      formattedNumber =
        this.numberPad(value, cntBfr) + "." + this.numberDec("0", cntAfr);
    }

    object.value = formattedNumber;

    if (type != undefined) {
      if (type == "reefer Temp") {
        this.modelNew.reeferTemp = formattedNumber;
      }
    }
  }

  formattedString(value, cntBfr, cntAfr) {
    var formattedNumber = "";
    if (value.toString().includes(".")) {
      var st = value.toString().split(".");
      if (st.length > 2) {
        this.message(
          "information",
          "Information",
          "incorrect numbered format.",
          "okonly",
          { ok: "" }
        );
        formattedNumber = "0";
      } else {
        formattedNumber =
          this.numberPad(st[0], cntBfr) + "." + this.numberDec(st[1], cntAfr);
      }
    } else {
      formattedNumber =
        this.numberPad(value, cntBfr) + "." + this.numberDec("0", cntAfr);
    }

    return formattedNumber;
  }

  numberPad(value, size) {
    var s = value.toString();
    while (s.length < (size || 2)) {
      s = "0" + s;
    }
    return s;
  }

  numberDec(value, size) {
    var s = value.toString().substr(0, size);
    while (s.length < size) {
      s = s + "0";
    }
    return s;
  }

  onGridReady(event) {
    console.log(event);
    this.nGridApi = event.api;
    this.nGridColumnApi = event.columnApi;
    this.nGridApi.hideOverlay();
    this.nGridApi.refreshCells();
  }

  rowDoubleClick(event) {
    console.log(event);

    this.nGridStore.forEach((fe) => {
      if (fe["processID"] == event.data.processID) {
        // console.log(fe);
        this.loading = true;
        this.modelNew.processID = fe["processID"];
        this.handleNew();
        this.loading = false;
        this.process("ok");
        //this.gridCLL.clearSelectedValues();
      }
    });
  }

  onRowSelected(event) {
    console.log(event);
    console.log(event.node.selected);
    if (event.node.selected) {
      this.globalGridCllNumbers = event.rowIndex + 1;
      this.globarlGridProcessId = event.data.processID;
    }
  }

  onCellValueChanged(event) {
    console.log(event);
    this.nGridApi = event.api;
    //console.log(this.nGridApi.getRangeSelections()); ayaaam
  }

  fileDonload(type) {
    this.loading = true;
    var initDwnld = false;
    //var dwnldUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var dwnldUrl = this.hitUrl + "?q=/edi-util/getFile";
    var fileName = "";
    switch (type) {
      case "cll":
        //alert("donload cll ");
        dwnldUrl = dwnldUrl + "/RECEIVE_CLL_TEMPLATE/xls";
        fileName = "RECEIVE_CLL_TEMPLATE.xls";
        initDwnld = true;
        break;
      case "cell":
        //alert("donload cell location");
        dwnldUrl = dwnldUrl + "/RECEIVE_CELL_LOCATION_TEMPLATE/xls";
        fileName = "RECEIVE_CELL_LOCATION_TEMPLATE.xls";
        initDwnld = true;
        break;
      case "cllWithISO":
        dwnldUrl = dwnldUrl + "/RECEIVE_CLL_TEMPLATE_NEW_ISO/xls";
        fileName = "RECEIVE_CLL_TEMPLATE_NEW_ISO.xls";
        initDwnld = true;
        break;
      default:
        break;
    }

    if (initDwnld) {
      this._http
        .get(dwnldUrl, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Token: localStorage.getItem("token"),
          }),
          responseType: "blob",
        })
        .subscribe((res) => {
          FileSaver.saveAs(res, fileName);
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  openPrompterDialog() {
    $("#idPrompter")
      .dialog({
        title: "Information",
        modal: true,
        width: 400,
        height: "auto",
        open: function (event, ui) {
          //hide close button.
          //console.log($(this));
          $(".ui-dialog").css("z-index", 103);
          $(".ui-widget-overlay").css("z-index", 102);
          $(this)
            .parent()
            .children()
            .children(".ui-dialog-titlebar-close")
            .hide();
          $("body").first().css("overflow", "hidden");
          $(".ui-widget-content").css("min-height", "auto");
        },
        close: function (event, ui) {
          $("body").first().css("overflow", " auto");
        },
      })
      .dialog("open");
  }

  closePrompter(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  showPrompterDialog(
    icon: string,
    title: string,
    content: string,
    button: any
  ) {
    this.prompterType = icon;
    this.prompterHeader = title;
    this.prompterContent = content;
    this.prompterButtons = button;

    this.openPrompterDialog();
  }

  clickEvent(event) {
    this.closeDialog("idPrompter");

    if (event != "") {
      eval(event);
    }
  }

  OIMonly(event: any) {
    if (!(event.key == "O" || event.key == "I" || event.key == "M")) {
      event.preventDefault();
    }
  }

  exceptApos(event: any) {
    this.modelContainer.containerNo = event.target.value.replace(/\s/g, "");
  }

  focusOuteventContainerNo(event: any, type: string){
    var val = event.target.value.replace(/\s/g, "");
      if(type == "containerNo"){
        this.modelContainer.containerNo = val;
      }else if(type == "containerSealNo1"){
        this.modelContainer.containerSealNo1 = val;
      }else if(type == "containerSealNo2"){
        this.modelContainer.containerSealNo2 = val;
      }else if(type == "containerSealNo3"){
        this.modelContainer.containerSealNo3 = val;
      }
  }

  keypressEventContainerNo(event: any) {
    var regexPattern = new RegExp("[a-zA-Z0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  keypressEventSealNo(event: any) {
    var regexPattern = new RegExp("[a-zA-Z0-9\/\.-]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteEventContainerNo(event: any, type: string) {
    // console.log(event);
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9\/\.-]/g, "");

    event.preventDefault();

    // this.modelContainer.containerNo = pastedText;
    if(type == "containerNo"){
      pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9]/g, "");
      this.modelContainer.containerNo = pastedText;
    }else if(type == "containerSealNo1"){
      this.modelContainer.containerSealNo1 = pastedText;
    }else if(type == "containerSealNo2"){
      this.modelContainer.containerSealNo2 = pastedText;
    }else if(type == "containerSealNo3"){
      this.modelContainer.containerSealNo3 = pastedText;
    }
  }

  pasteEventContainerNoCopy(event: any) {
    // console.log(event);
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9.]/g, "");
    
    event.preventDefault();
    event.target.value = pastedText;
  }

  focusOuteventContainerPax(event: any){
    var val = event.target.value.replace(/\s/g, "");
    this.modelContainer.pax = val;
  }

  keypressEventContainerPax(event: any) {
    var regexPattern = new RegExp("[a-zA-Z0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteEventContainerPax(event: any) {
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9.]/g, "");

    event.preventDefault();
    this.modelContainer.pax = pastedText;
  }

  CharNumOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumOnly(event: any) {
    var regexPattern = new RegExp("[0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  timeOnly(event: any) {
    var regexPattern = new RegExp("[0-9:]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  NumDotOnly(event: any) {
    var regexPattern = new RegExp("[0-9.]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumSepOnly(event: any) {
    var regexPattern = new RegExp("[0-9:]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  TempOnly(event: any) {
    var regexPattern = new RegExp("[0-9+-.]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  downloadFileFromServer(fileName: string, fileExt: string, loading: boolean) {
    loading = true;
    var dwnldUrl =
      this.hitUrl + "?q=/edi-util/getFile/" + this.sCharUtil.htmlEncode(fileName) + "/" + fileExt;
    this._http
      .get(dwnldUrl, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          var docName = fileName + "." + fileExt;
          FileSaver.saveAs(res, docName);
          loading = false;
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          loading = false;
        }
      );
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event == "close-pdf") {
      //this.clearPDFPlugin();
    }
  }

  viewOnlyFunction() {
    this.currentMenuID = this.cook.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cook.getCookie("currentMenu").split("|")[1];

    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "new,upload,batch,copy,transfer,uploadCellLoc,updateCellLoc,delete,transmit,baplie";
    }
  }

  setsEnable() {
    this.enabl = true;
    console.log("KESINI MALIH 1 :" + this.enabl);
    this.currentMenuID = this.cook.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cook.getCookie("currentMenu").split("|")[1];

    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    console.log("KESINI MALIH 2 :" + this.enabl);
    if (this.viewOnlyStatus) {
      console.log("KESINI MALIH 3 :" + this.enabl);
      this.enabl = false;
    }
    console.log("KESINI MALIH 4 :" + this.enabl);
    return this.enabl;
  }

  errorVesVoy(event: any, field: string, model: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.formValidation.regVesVoy.test(pastedText) == false) {
      return (this[model][`error-${field}`] =
        "Only allowed special characters .-/");
    } else {
      return (this[model][`error-${field}`] = "");
    }
  }

  checkIfError(field: string, model: any) {
    if (this.formValidation.regVesVoy.test(this[model][field]) == true) {
      return (this[model][`error-${field}`] = "");
    }
  }
}
