import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import {
  FreightManagement,
  FreightDetail,
  FreightDetailTotal,
  surchargeDetails,
  specialCharUtil,
  SpecialDetail,
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DatetimePluginComponent,
  ToolbarPluginComponent,
  DialogMultiplePluginComponent,
  TextareaPluginComponent,
  DatePluginComponent,
  md5,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import FormValidation from "../../utils/formValidation";
declare var $: any;

@Component({
  selector: "app-transaction-contract-rate-page",
  templateUrl: "./transaction-contract-rate-page.component.html",
  styleUrls: ["./transaction-contract-rate-page.component.css"],
})
export class TransactionContractRatePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("pfcToolbar") pfcToolbar: ToolbarPluginComponent;
  @ViewChild("cbFreightParty") cbFreightParty: ComboPluginComponent;
  @ViewChild("cbBookingParty") cbBookingParty: ComboPluginComponent;
  @ViewChild("cbShipper") cbShipper: ComboPluginComponent;
  @ViewChild("cbPlaceOfDelivery") cbPlaceOfDelivery: ComboPluginComponent;
  @ViewChild("cbContainerOwnership") cbContainerOwnership: ComboPluginComponent;
  @ViewChild("cbPlaceOfReceipt") cbPlaceOfReceipt: ComboPluginComponent;
  @ViewChild("cbPortOfTransshipment")
  cbPortOfTransshipment: ComboPluginComponent;
  @ViewChild("cbDGClass") cbDGClass: ComboPluginComponent;
  @ViewChild("cbPortOfLoad") cbPortOfLoad: ComboPluginComponent;
  @ViewChild("cbFinalDestination") cbFinalDestination: ComboPluginComponent;
  @ViewChild("cbReceiptType") cbReceiptType: ComboPluginComponent;
  @ViewChild("cbContainerType") cbContainerType: ComboPluginComponent;
  @ViewChild("cbPortOfDischarge") cbPortOfDischarge: ComboPluginComponent;
  @ViewChild("cbContainerStatus") cbContainerStatus: ComboPluginComponent;
  @ViewChild("cbValidInvalid") cbValidInvalid: ComboPluginComponent;
  @ViewChild("cbDeliveryType") cbDeliveryType: ComboPluginComponent;
  @ViewChild("cbPORTerminal") cbPORTerminal: ComboPluginComponent;
  @ViewChild("cbPODLTerminal") cbPODLTerminal: ComboPluginComponent;
  @ViewChild("cbPOLTerminal") cbPOLTerminal: ComboPluginComponent;
  @ViewChild("cbPOT1Terminal") cbPOT1Terminal: ComboPluginComponent;
  @ViewChild("cbPODTerminal") cbPODTerminal: ComboPluginComponent;
  @ViewChild("cbFDESTTerminal") cbFDESTTerminal: ComboPluginComponent;
  @ViewChild("cbPrepaidStatus") cbPrepaidStatus: ComboPluginComponent;
  @ViewChild("cbCollectStatus") cbCollectStatus: ComboPluginComponent;
  @ViewChild("cbShipmentType") cbShipmentType: ComboPluginComponent;

  @ViewChild("txtRemarksPrincipal")
  txtRemarksPrincipal: TextareaPluginComponent;
  @ViewChild("txtRemarksAgency") txtRemarksAgency: TextareaPluginComponent;
  /*
   @ViewChild('dateEffectiveFrom') dateEffectiveFrom:CalendarPluginComponent;
   @ViewChild('dateEffectiveTo') dateEffectiveTo:CalendarPluginComponent;
   */
  @ViewChild("dateEffectiveFrom") dateEffectiveFrom: DatetimePluginComponent;
  @ViewChild("dateEffectiveTo") dateEffectiveTo: DatetimePluginComponent;
  @ViewChild("cbAdhocVessel") cbAdhocVessel: ComboPluginComponent;
  @ViewChild("cbAdhocVoyage") cbAdhocVoyage: ComboPluginComponent;

  @ViewChild("mainGrid") mainGrid: GridPluginComponent;

  /***
   * Component for Freight Maintenance Maintenance
   ***/
  @ViewChild("cbMaintenanceFreightParty")
  cbMaintenanceFreightParty: ComboPluginComponent;
  @ViewChild("cbMaintenanceShipper") cbMaintenanceShipper: ComboPluginComponent;
  @ViewChild("cbMaintenanceBookingParty") cbMaintenanceBookingParty: ComboPluginComponent;
  @ViewChild("cbMaintenancePlaceOfReceipt")
  cbMaintenancePlaceOfReceipt: ComboPluginComponent;
  @ViewChild("cbMaintenancePortOfLoad")
  cbMaintenancePortOfLoad: ComboPluginComponent;
  @ViewChild("cbMaintenancePortOfDischarge")
  cbMaintenancePortOfDischarge: ComboPluginComponent;
  @ViewChild("cbMaintenancePlaceOfDelivery")
  cbMaintenancePlaceOfDelivery: ComboPluginComponent;
  @ViewChild("cbMaintenanceFinalDestination")
  cbMaintenanceFinalDestination: ComboPluginComponent;
  @ViewChild("cbMaintenanceCommodity")
  cbMaintenanceCommodity: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT1") cbMaintenancePOT1: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT2") cbMaintenancePOT2: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT3") cbMaintenancePOT3: ComboPluginComponent;
  @ViewChild("cbMaintenanceReceiptType")
  cbMaintenanceReceiptType: ComboPluginComponent;
  @ViewChild("cbMaintenanceDeliveryType")
  cbMaintenanceDeliveryType: ComboPluginComponent;
  @ViewChild("cbMaintenanceAdhocVessel")
  cbMaintenanceAdhocVessel: ComboPluginComponent;
  @ViewChild("cbMaintenanceAdhocVoyage")
  cbMaintenanceAdhocVoyage: ComboPluginComponent;

  //@ViewChild('dateMaintenanceEffectiveFrom') dateMaintenanceEffectiveFrom: DatetimePluginComponent;
  //@ViewChild('dateMaintenanceEffectiveTo') dateMaintenanceEffectiveTo: DatetimePluginComponent;
  @ViewChild("dateMaintenanceEffectiveFrom2")
  dateMaintenanceEffectiveFrom2: DatePluginComponent;
  @ViewChild("dateMaintenanceEffectiveTo2")
  dateMaintenanceEffectiveTo2: DatePluginComponent;

  @ViewChild("cbMaintenancePORTerminal")
  cbMaintenancePORTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePODLTerminal")
  cbMaintenancePODLTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePOLTerminal")
  cbMaintenancePOLTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT1Terminal")
  cbMaintenancePOT1Terminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePODTerminal")
  cbMaintenancePODTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenanceFDESTTerminal")
  cbMaintenanceFDESTTerminal: ComboPluginComponent;
  @ViewChild("cbAcctPic") cbAcctPic: ComboPluginComponent;
  @ViewChild("cbArchivePfc") cbArchivePfc: ComboPluginComponent;

  //Detail
  @ViewChild("cbMaintenanceDetailCharge")
  cbMaintenanceDetailCharge: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailCurrency")
  cbMaintenanceDetailCurrency: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPC")
  cbMaintenanceDetailPC: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPlaceOfPayment")
  cbMaintenanceDetailPlaceOfPayment: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPayer")
  cbMaintenanceDetailPayer: ComboPluginComponent;

  @ViewChild('maintenanceGeneralTab') maintenanceGeneralTab: ElementRef;

  //@ViewChild('gridPrepaid') gridPrepaid: GridPluginComponent;
  //@ViewChild('gridCollect') gridCollect: GridPluginComponent;

  @ViewChild("nctform_ctrType") nctform_ctrType: ComboPluginComponent;
  //@ViewChild('dialogMultiple') dialogMultiple: DialogMultiplePluginComponent;

  /***
   * End of Freight Management Maintenance
   */
  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "copy";

  modeToolbar = false;
  modeForm: string = "freightFlagStatus";

  sCharUtil = new specialCharUtil();
  formValidation = new FormValidation();

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingCbFreightParty;
  settingCbBookingParty;
  settingCbShipper;
  settingCbPlaceOfDelivery;
  settingCbContainerOwnership;
  settingCbPlaceOfReceipt;
  settingCbPortOfTransshipment;
  settingCbDGClass;
  settingCbPortOfLoad;
  settingCbFinalDestination;
  settingCbReceiptType;
  settingCbContainerType;
  settingCbShipmentType;
  settingCbPortOfDischarge;
  settingCbContainerStatus;
  settingCbValidInvalid;
  settingCbDeliveryType;
  settingCbPORTerminal;
  settingCbPODLTerminal;
  settingCbPOLTerminal;
  settingCbPOT1Terminal;
  settingCbPODTerminal;
  settingCbFDESTTerminal;
  settingCbPrepaidStatus;
  settingCbCollectStatus;
  settingMainGrid;
  settingCbAdhocVessel;
  settingCbAdhocVoyage;

  settingDateEffectiveFrom;
  settingDateEffectiveTo;
  //settingDateMaintenanceEffectiveFrom;
  settingDateMaintenanceEffectiveFrom2;
  //settingDateMaintenanceEffectiveTo;
  settingDateMaintenanceEffectiveTo2;

  settingCbMaintenanceFreightParty;
  settingCbMaintenanceShipper;
  settingCbMaintenanceBookingParty;
  settingCbMaintenancePlaceOfReceipt;
  settingCbMaintenancePortOfLoad;
  settingCbMaintenancePortOfDischarge;
  settingCbMaintenancePlaceOfDelivery;
  settingCbMaintenanceFinalDestination;
  settingCbMaintenanceCommodity;
  settingCbMaintenancePOT1;
  settingCbMaintenancePOT2;
  settingCbMaintenancePOT3;
  settingCbMaintenanceReceiptType;
  settingCbMaintenanceDeliveryType;
  settingCbMaintenanceAdhocVessel;
  settingCbMaintenanceAdhocVoyage;
  settingCbMaintenancePORTerminal;
  settingCbMaintenancePODLTerminal;
  settingCbMaintenancePOLTerminal;
  settingCbMaintenancePOT1Terminal;
  settingCbMaintenancePODTerminal;
  settingCbMaintenanceFDESTTerminal;
  settingCbAcctPIC;
  settingCbArchivePfc;
  settingGridPrepaid;
  settingGridCollect;

  settingCbMaintenanceDetailCharge;
  settingCbMaintenanceDetailCurrency;
  settingCbMaintenanceDetailPC;
  settingCbMaintenanceDetailPlaceOfPayment;
  settingCbMaintenanceDetailPayer;

  setting_nctFormForContainerType;
  settingRemarksAgency;
  settingRemarksPrincipal;

  //setting lock
  lock: boolean = false;
  lockPORTerminal: boolean = true;
  lockPOLTerminal: boolean = false;
  lockPODTerminal: boolean = false;
  lockPODLTerminal: boolean = true;
  lockPOT1Terminal: boolean = false;
  lockFDESTTerminal: boolean = true;
  lockEffectiveFrom: boolean = false;
  lockEffectiveFromMaintenance: boolean = false;
  lockEffectiveTo: boolean = false;
  //lockMaintenanceEffectiveFrom: boolean = false;
  //lockMaintenanceEffectiveTo: boolean = false;
  lockOOGOOG: boolean = false;
  lockOOGDoorOpen: boolean = false;
  lockOOGUC: boolean = false;
  lockRemarksPrincipal: boolean = false;
  lockFullEmpty: boolean = true;
  lockDetention: boolean = false;
  lockDemurrage: boolean = false;
  lockDetentionPol: Boolean = false;
  lockDemurragePol: Boolean = false;

  //Flag active tab
  currentActiveTab = "prepaid";
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";

  currentSelectedContainerType = "normal";

  isWarningFlag: boolean = false;
  checkLock: boolean = false;
  isErrorFlag: boolean = false;
  isError: boolean = false;
  isErrorDetail: boolean = false;
  isErrorDeleted: boolean = false;
  isUpdateDetail: boolean = false;
  isLoad: boolean = false; // used to check there is data / no data in the grid
  isCopy: boolean = false;
  isFAIErrorPrepaid: boolean = false;
  isFAIErrorCollect: boolean = false;

  isChangeEffectiveFrom: boolean = false;
  isChangeEffectiveTo: boolean = false;
  isRetrieve: boolean = false;
  isNew: boolean = false;

  //types
  typeEffectiveFrom = "calendar";
  typeEffectiveTo = "calendar";

  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new FreightManagement();
  modelDetail = new FreightDetail();
  modelDetailList: FreightDetail[] = [];
  modelDelete = new FreightManagement();
  modelDetailListx: FreightDetail[] = [];

  containerTypeList: any[] = [];
  freightManagementKeyRetrieve: string = "";
  freightManagementRetrieve: string = "";
  freightDetailsRetrieve: string = "";

  mainArchiveRetrieve = new FreightManagement();
  responseArchive:any = {};
  isModeArchive = false;
  actFromCbArchive = false;

  fpAcctCode = "";
  fpPayer = "";
  isFreightPartyValidBasedOnCustomerType = "";
  isFreightPartyValidAsRecordLevel ="";
  dateFreightRemoved="";
  dateModifiedFreigthParty = "";
  dateModifiedBookingParty = "";
  dateModifiedShipper = "";

  map = new Map();
  mapCheck = new Map();

  validatorRules = {};
  appStatusLabel: string = "";

  // TOTAL Prepaid
  totD20Prepaid: number = 0;
  totD40Prepaid: number = 0;
  totD45Prepaid: number = 0;
  totH20Prepaid: number = 0;
  totH40Prepaid: number = 0;
  totH45Prepaid: number = 0;

  // TOTAL Collect

  totD20Collect: number = 0;
  totD40Collect: number = 0;
  totD45Collect: number = 0;
  totH20Collect: number = 0;
  totH40Collect: number = 0;
  totH45Collect: number = 0;

  lastRecordId = "";
  lastEffectiveFrom = "";
  lastEffectiveTo = "";

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  mapCtype = new Map();
  indexMapCtype: number = 0;

  multipleCtypeDialogText: string = "";
  msg: string = "";
  messagesFAIErrorPrepaid: string = "";
  messagesFAIErrorCollect: string = "";

  columnDefsMain;
  rowDataMain: any[];

  rowSelection;
  getRowStylePrepaid;
  getRowStyleCollect;

  gridApiPrepaid;
  gridColumnApiPrepaid;
  columnDefsPrepaid;
  rowDataPrepaid: any[];
  pinnedBottomRowDataPrepaid;
  postSort;

  gridApiCollect;
  gridColumnApiCollect;
  columnDefsCollect;
  rowDataCollect: any[];
  pinnedBottomRowDataCollect;

  actions: any[] = [];

  polName: string = "";
  podName: string = "";

  POPFreightGroupCode: string = "";
  freightGroupMember = [];
  custIdForCbPayer = "*";
  dateCreatedForCbPayer = "*";

  apiEffectiveTo : string = "";
  includePayerInvalid : string = "";
  groupAllowInvalidPayer = [];
  listPayerExisting = [];

  searchFpIsValid = "";
  searchFpIsFreightParty = "";

  searchShIsValid = "";
  searchShIsShipper = "";

  searchBkIsValid = "";
  searchBkIsBookingParty = "";

  revisionPfc = "";

  timeModifiedFreightParty = 0;
  timeModifiedBookingParty = 0;
  timeModifiedShipper = 0;
  timeCreatedPfc = 0;

  clickSurcharge = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.rowSelection = "multiple";

    this.settingToolbar = {
      buttonsFront: [
        { name: "NCT", event: "nct", icon: "copy" },
        { name: "Save", event: "save", icon: "save" },
        { name: "Copy", event: "copy", icon: "copy" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        /*{ name: 'Invalid',     event: 'invalid',    icon: 'minus circle' },*/
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Create", event: "create", icon: "plus" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Contract Rate Application",
    };

    this.settingCbFreightParty = {
      id: "cbFreightParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}",
        "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Freight Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isFreightParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbBookingParty = {
      id: "cbBookingParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Booking Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isBookingParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbShipper = {
      id: "cbShipper",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Shipper",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isShipper", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };


    this.settingCbPlaceOfDelivery = {
      id: "cbPlaceOfDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Delivery",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbContainerOwnership = {
      id: "cbContainerOwnership",
      type: "select", // search | select | select input
      url: '[{ "ctrOwnerCode" : "ALL", "ctrOwnerName" : "ALL" },{ "ctrOwnerCode" : "COC", "ctrOwnerName" : "COC" },{ "ctrOwnerCode" : "COCTS", "ctrOwnerName" : "COC T/S" },{ "ctrOwnerCode" : "SOC", "ctrOwnerName" : "SOC" },{ "ctrOwnerCode" : "SOCTS", "ctrOwnerName" : "SOC T/S" },{ "ctrOwnerCode" : "SLOT", "ctrOwnerName" : "SLOT" }]\n',
      urlType: "inject",
      placeholder: "Container OwnerShip",
      code: "ctrOwnerCode",
      title: "ctrOwnerName",
    };

    this.settingCbShipmentType = {
      id: "cbShipmentType",
      type: "select", // search | select | select input
      url: '[{ "shipmentTypeCode" : "ALL", "shipmentTypeName" : "ALL" },{ "shipmentTypeCode" : "N", "shipmentTypeName" : "N/A" },{ "shipmentTypeCode" : "I", "shipmentTypeName" : "IRE" },{ "shipmentTypeCode" : "R", "shipmentTypeName" : "ROB" },{ "shipmentTypeCode" : "T", "shipmentTypeName" : "THRU" },{ "shipmentTypeCode" : "D", "shipmentTypeName" : "RD-TRIP" },{ "shipmentTypeCode" : "S", "shipmentTypeName" : "SPECIAL" },{ "shipmentTypeCode" : "C", "shipmentTypeName" : "CRB" }]\n',
      urlType: "inject",
      placeholder: "Shipment Type",
      code: "shipmentTypeCode",
      title: "shipmentTypeName",
    };

    this.settingCbPlaceOfReceipt = {
      id: "cbPlaceOfReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Place of Receipt",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPortOfTransshipment = {
      id: "cbPortOfTransshipment",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbDGClass = {
      id: "cbDGClass",
      type: "select", // search | select | select input
      url: '[{ "dgClassCode" : "ALL", "dgClassName" : "ALL" },{ "dgClassCode" : "NA", "dgClassName" : "N/A" },{ "dgClassCode" : "C1", "dgClassName" : "C1" },{ "dgClassCode" : "C2", "dgClassName" : "C2" },{ "dgClassCode" : "C2F", "dgClassName" : "C2F" },{ "dgClassCode" : "C3", "dgClassName" : "C3" }]',
      urlType: "inject",
      placeholder: "ALL(DG + NON DG)",
      code: "dgClassCode",
      title: "dgClassName",
    };

    this.settingCbPortOfLoad = {
      id: "cbPortOfLoad",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Port Of Load",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbFinalDestination = {
      id: "cbFinalDestination",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Final Destination",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    /*this.settingCbReceiptType = {
      id: 'cbReceiptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: 'ALL',
      title: 'receiptTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 300},
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }*/

    this.settingCbReceiptType = {
      id: "cbReceiptType",
      type: "select", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok",
      urlType: "dynamic",
      // placeholder: 'ALL',
      code: "receiptTypeCode",
      title: "receiptTypeCode",
    };

    this.settingCbDeliveryType = {
      id: "cbDeliveryType",
      type: "select", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok",
      urlType: "dynamic",
      // placeholder: 'ALL',
      code: "deliveryTypeCode",
      title: "deliveryTypeCode",
    };

    this.settingCbContainerType = {
      id: "cbContainerType",
      type: "select", // search | select | select input
      url: '[{ "containerTypeCode" : "ALL", "containerType" : "ALL" },{ "containerTypeCode" : "FLR", "containerType" : "FLATRACK" },{ "containerTypeCode" : "OPT", "containerType" : "OPEN TOP" },{ "containerTypeCode" : "DRY", "containerType" : "REEFER DRY" },{ "containerTypeCode" : "STD", "containerType" : "NORMAL" },{ "containerTypeCode" : "REF", "containerType" : "REEFER" },{ "containerTypeCode" : "TNK", "containerType" : "TANK" },{ "containerTypeCode" : "PLT", "containerType" : "PLATFORM" },{ "containerTypeCode" : "UC", "containerType" : "UC" }]',
      urlType: "inject",
      placeholder: "ALL",
      code: "containerTypeCode",
      title: "containerType",
    };

    this.settingCbPortOfDischarge = {
      id: "cbPortOfDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port Of Discharge",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbContainerStatus = {
      id: "cbContainerStatus",
      type: "select", // search | select | select input
      url: '[{ "ctrStatusCode" : "ALL", "ctrStatusName" : "ALL" },{ "ctrStatusCode" : "FULL", "ctrStatusName" : "FULL" },{ "ctrStatusCode" : "EMPTY", "ctrStatusName" : "EMPTY" }]',
      urlType: "inject",
      placeholder: "ALL",
      code: "ctrStatusCode",
      title: "ctrStatusName",
    };

    /*this.settingCbDeliveryType = {
      id: 'cbDeliveryType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: 'ALL',
      title: 'deliveryTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Delivery Type Name', field: 'deliveryTypeName', width: 300},
        {header: 'Delivery Type Code', field: 'deliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }*/

    this.settingCbPrepaidStatus = {
      id: "cbPrepaidStatus",
      type: "select", // search | select | select input
      url: '[{ "prepaidStatusCode" : "P", "prepaidStatusName" : "PENDING" },{ "prepaidStatusCode" : "A", "prepaidStatusName" : "APPROVED" },{ "prepaidStatusCode" : "R", "prepaidStatusName" : "REJECTED" }]',
      urlType: "inject",
      placeholder: "ALL",
      code: "prepaidStatusCode",
      title: "prepaidStatusName",
    };

    this.settingCbCollectStatus = {
      id: "cbCollectStatus",
      type: "select", // search | select | select input
      url: '[{ "collectStatusCode" : "P", "collectStatusName" : "PENDING" },{ "collectStatusCode" : "A", "collectStatusName" : "APPROVED" },{ "collectStatusCode" : "R", "collectStatusName" : "REJECTED" }]\n',
      urlType: "inject",
      placeholder: "ALL",
      code: "collectStatusCode",
      title: "collectStatusName",
    };

    this.settingCbAdhocVessel = {
      id: "cbAdhocVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel Adhoc",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCbAdhocVoyage = {
      id: "cbAdhocVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage Adhoc",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingMainGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/PFCFreightApplication/findGeneralContractRate",
      page: 10,
      columns: [
        { header: "S", field: "pfcAppStatus", width: 75 },
        { header: "Freight Ref.", field: "pfcRecordId", width: 200 },
        { header: "F/P", field: "pfcFP", width: 200 },
        { header: "F/C", field: "pfcFC", width: 200 },
        { header: "Freight Party", field: "pfcFreightPartyName", width: 200 },
        { header: "Booking Party", field: "pfcBookingPartyName", width: 200 },
        { header: "Shipper", field: "pfcShipperName", width: 200 },
        { header: "C/Type", field: "pfcCTypeName", width: 200 },
        { header: "C/S", field: "pfcCS", width: 200 },
        {
          header: "Place of Receipt",
          field: "pfcPlaceOfReceiptName",
          width: 200,
        },
        {
          header: "Port of Loading",
          field: "pfcPortOfLoadingName",
          width: 200,
        },
        {
          header: "POL Terminal",
          field: "pfcPOLTerminalName",
          width: 200,
        },
        {
          header: "Port of Discharge",
          field: "pfcPortOfDischargeName",
          width: 200,
        },
        {
          header: "POD Terminal",
          field: "pfcPODTerminalName",
          width: 200,
        },
        {
          header: "Place of Delivery",
          field: "pfcPlaceOfDeliveryName",
          width: 200,
        },
        {
          header: "Final Destination",
          field: "pfcFinalDestinationName",
          width: 200,
        },
        { header: "POT (1)", field: "pfcPortOfTransshipment1Name", width: 200 },
        {
          header: "POT1 Terminal",
          field: "pfcPOT1TerminalName",
          width: 200,
        },
        { header: "Effective From", field: "pfcEffectiveDate", width: 200 },
        { header: "Expiry On", field: "pfcExpiryDate", width: 200 },
        { header: "R/Type", field: "pfcReceiptTypeCode", width: 200 },
        { header: "D/Type", field: "pfcDeliveryTypeCode", width: 200 },
        { header: "DG Class", field: "pfcDGClass", width: 200 },
        { header: "AdHoc Info", field: "pfcAdHocInfo", width: 200 },
        { header: "Vessel", field: "pfcVesselName", width: 200 },
        { header: "Voyage", field: "pfcVoyage", width: 200 },
        { header: "POT (2)", field: "pfcPortOfTransshipment2Name", width: 200 },
        { header: "POT (3)", field: "pfcPortOfTransshipment3Name", width: 200 },
        { header: "Shipment Type Code", field: "pfcShipmentType", width: 200 },
        {
          header: "Shipment Type Name",
          field: "pfcShipmentTypeName",
          width: 200,
        },
        { header: "POL DET", field: "pfcDetentionPOL", width: 200 },
        { header: "POL DEM", field: "pfcDemurragePOL", width: 200 },
        { header: "POD DET", field: "pfcDetention", width: 200 },
        { header: "POD DEM", field: "pfcDemurrage", width: 200 },
        { header: "Commodity", field: "pfcCommodityName", width: 200 },
        { header: "Commissionable", field: "pfcCommission", width: 200 },
        { header: "Requested By", field: "pfcRequestedBy", width: 200 },
        { header: "Requested Date", field: "pfcRequestedDate", width: 200 },
        { header: "Approved By", field: "pfcApprovedBy", width: 200 },
        { header: "Approved Date", field: "pfcApprovedDate", width: 200 },
        { header: "Modified By", field: "pfcModifiedBy", width: 200 },
        { header: "Modified Date", field: "pfcModifiedDate", width: 200 },
        { header: "Valid", field: "pfcIsValid", width: 200 },
        { header: "Invalid By", field: "pfcInvalidBy", width: 200 },
        { header: "Invalid Date", field: "pfcInvalidDate", width: 200 },
        { header: "Deleted", field: "pfcIsDeleted", width: 200 },
        { header: "Deleted By", field: "pfcDeletedBy", width: 200 },
        { header: "Deleted Date", field: "pfcDeletedDate", width: 200 },
        { header: "Deleted Reason", field: "pfcDeletedReason", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "pfcDateModified",
      sortingDirection: "DESC",
    };

    this.columnDefsMain = [
      { headerName: "S", field: "pfcAppStatus", width: 75 },
      { headerName: "Freight Ref.", field: "pfcRecordId", width: 200 },
      { headerName: "F/P", field: "pfcFP", width: 200 },
      { headerName: "F/C", field: "pfcFC", width: 200 },
      { headerName: "Freight Party", field: "pfcFreightPartyName", width: 200 },
      { headerName: "C/Type", field: "pfcCTypeName", width: 200 },
      { headerName: "C/S", field: "pfcCS", width: 200 },
      {
        headerName: "Place of Receipt",
        field: "pfcPlaceOfReceiptName",
        width: 200,
      },
      {
        headerName: "Port of Loading",
        field: "pfcPortOfLoadingName",
        width: 200,
      },
      {
        headerName: "Port of Discharge",
        field: "pfcPortOfDischargeName",
        width: 200,
      },
      {
        headerName: "Place of Delivery",
        field: "pfcPlaceOfDeliveryName",
        width: 200,
      },
      {
        headerName: "Final Destination",
        field: "pfcFinalDestinationName",
        width: 200,
      },
      {
        headerName: "POT (1)",
        field: "pfcPortOfTransshipment1Name",
        width: 200,
      },
      { headerName: "Effective From", field: "pfcEffectiveDate", width: 200 },
      { headerName: "Expiry On", field: "pfcExpiryDate", width: 200 },
      { headerName: "R/Type", field: "pfcReceiptTypeCode", width: 200 },
      { headerName: "D/Type", field: "pfcDeliveryTypeCode", width: 200 },
      { headerName: "DG Class", field: "pfcDGClass", width: 200 },
      { headerName: "AdHoc Info", field: "pfcAdHocInfo", width: 200 },
      { headerName: "Vessel", field: "pfcVesselName", width: 200 },
      { headerName: "Voyage", field: "pfcVoyage", width: 200 },
      {
        headerName: "POT (2)",
        field: "pfcPortOfTransshipment2Name",
        width: 200,
      },
      {
        headerName: "POT (3)",
        field: "pfcPortOfTransshipment3Name",
        width: 200,
      },
      { headerName: "Shipment Type", field: "pfcShipmentType", width: 200 },
      { headerName: "DET", field: "pfcDetention", width: 200 },
      { headerName: "DEM", field: "pfcDemurrage", width: 200 },
      { headerName: "Commodity", field: "pfcCommodityName", width: 200 },
      { headerName: "Commissionable", field: "pfcCommission", width: 200 },
      { headerName: "Requested By", field: "pfcRequestedBy", width: 200 },
      { headerName: "Requested Date", field: "pfcRequestedDate", width: 200 },
      { headerName: "Approved By", field: "pfcApprovedBy", width: 200 },
      { headerName: "Approved Date", field: "pfcApprovedDate", width: 200 },
      { headerName: "Modified By", field: "pfcModifiedBy", width: 200 },
      { headerName: "Modified Date", field: "pfcModifiedDate", width: 200 },
      { headerName: "Valid", field: "pfcIsValid", width: 200 },
      { headerName: "Invalid By", field: "pfcInvalidBy", width: 200 },
      { headerName: "Invalid Date", field: "pfcInvalidDate", width: 200 },
      { headerName: "Deleted", field: "pfcIsDeleted", width: 200 },
      { headerName: "Deleted By", field: "pfcDeletedBy", width: 200 },
      { headerName: "Deleted Date", field: "pfcDeletedDate", width: 200 },
      { headerName: "Deleted Reason", field: "pfcDeletedReason", width: 200 },
    ];

    this.settingCbPORTerminal = {
      id: "cbPORTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POR Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPODLTerminal = {
      id: "cbPODLTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search PODL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPOLTerminal = {
      id: "cbPOLTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPOT1Terminal = {
      id: "cbPOT1Terminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POT1 Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPODTerminal = {
      id: "cbPODTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbFDESTTerminal = {
      id: "cbFDESTTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDateEffectiveFrom = {
      id: "dateEffectiveFrom",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Effective From",
      customtext: "",
      maxlength: 10
    };
    this.settingDateEffectiveTo = {
      id: "dateEffectiveTo",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Expiry On",
      customtext: "",
      maxlength: 10
    };

    this.settingCbValidInvalid = {
      id: "cbValidInValid",
      type: "select", // search | select | select input
      url: '[{ "isValidCode" : "ALL", "isValidName" : "ALL" },{ "isValidCode" : "Y", "isValidName" : "VALID" },{ "isValidCode" : "N", "isValidName" : "INVALID" }]',
      urlType: "inject",
      placeholder: "ALL",
      code: "isValidCode",
      title: "isValidName",
    };

    /***
     * Maintenance section
     */
    this.settingCbMaintenanceFreightParty = {
      id: "cbMaintenanceFreightParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}",
        "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Freight Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isFreightParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbMaintenanceBookingParty = {
      id: "cbMaintenanceBookingParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Booking Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isBookingParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbMaintenanceShipper = {
      id: "cbMaintenanceShipper",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Shipper",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isShipper", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbMaintenancePlaceOfReceipt = {
      id: "cbMaintenancePlaceOfReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Receipt",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePortOfLoad = {
      id: "cbMaintenancePortOfLoad",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Load",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePortOfDischarge = {
      id: "cbMaintenancePortOfDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Discharge",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePlaceOfDelivery = {
      id: "cbMaintenancePlaceOfDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Delivery",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceFinalDestination = {
      id: "cbMaintenanceFinalDestination",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Final Destination",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT1 = {
      id: "cbMaintenancePOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 1",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT2 = {
      id: "cbMaintenancePOT2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 2",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT3 = {
      id: "cbMaintenancePOT3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 3",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceReceiptType = {
      id: "cbMaintenanceReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "CY",
      title: "receiptTypeCode", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Name", field: "receiptTypeName", width: 200 },
        { header: "Receipt Type Code", field: "receiptTypeCode", width: 200 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDeliveryType = {
      id: "cbMaintenanceDeliveryType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "CY",
      title: "deliveryTypeCode", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Name", field: "deliveryTypeName", width: 200 },
        { header: "Receipt Type Code", field: "deliveryTypeCode", width: 200 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceAdhocVessel = {
      id: "cbMaintenanceAdhocVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCbMaintenanceAdhocVoyage = {
      id: "cbMaintenanceAdhocVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingCbMaintenancePORTerminal = {
      id: "cbMaintenancePORTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POR Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePODLTerminal = {
      id: "cbMaintenancePODLTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "PODL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOLTerminal = {
      id: "cbMaintenancePOLTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT1Terminal = {
      id: "cbMaintenancePOT1Terminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POT 1 Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePODTerminal = {
      id: "cbMaintenancePODTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceFDESTTerminal = {
      id: "cbMaintenanceFDESTTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "FDEST Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceCommodity = {
      id: "cbMaintenanceCommodity",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Commodity",
      title: "commodityName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Commodity Code", field: "commodityCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGridPrepaid = {
      id: "gridPrepaid",
      url: "",
      page: 10,
      columns: [
        { header: "Seq.", field: "no", width: 50 },
        { header: "FAI", field: "pfcDetailFAIGroup", width: 75 },
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {
          header: "Charge Name",
          field: "pfcDetailChargeName",
          width: 200,
          type: "string",
        },
        {
          header: "Charge Code",
          field: "pfcDetailChargeCode",
          width: 100,
          type: "string",
        },
        //{header: 'C/Type Code', field: 'pfcDetailContainerType', width: 100},
        { header: "C/Type", field: "pfcDetailContainerTypeName", width: 100 },
        {
          header: "Currency",
          field: "pfcDetailCurrency",
          width: 50,
          type: "string",
        },
        { header: "D20", field: "pfcDetailD20", width: 50 },
        { header: "H20", field: "pfcDetailH20", width: 50 },
        { header: "D40", field: "pfcDetailD40", width: 50 },
        { header: "H40", field: "pfcDetailH40", width: 50 },
        { header: "D45", field: "pfcDetailD45", width: 50 },
        { header: "H45", field: "pfcDetailH45", width: 50 },
        { header: "P/C", field: "pfcDetailPC", width: 50 },
        {
          header: "Place of Payment",
          field: "pfcDetailPlaceOfPaymentName",
          width: 200,
          type: "string",
        },
        {
          header: "Payer",
          field: "pfcDetailPayerName",
          width: 200,
          type: "string",
        },
        { header: "OH", field: "pfcDetailOogOH", width: 50 },
        { header: "OWL", field: "pfcDetailOogOWL", width: 50 },
        { header: "OWR", field: "pfcDetailOogOWR", width: 50 },
        { header: "OLF", field: "pfcDetailOogOLF", width: 50 },
        { header: "OLB", field: "pfcDetailOogOLB", width: 50 },
        { header: "Kill Slot", field: "pfcDetailKillSlot", width: 100 },
        { header: "Door Open", field: "pfcDetailOogDoorOpenName", width: 100 },
        { header: "UCH", field: "pfcDetailOogUcH", width: 50 },
        { header: "UCL", field: "pfcDetailOogUcL", width: 50 },
        { header: "UCW", field: "pfcDetailOogUcW", width: 50 },
        { header: "/M3", field: "pfcDetailM3", width: 50 },
        { header: "/KT", field: "pfcDetailKT", width: 50 },
        { header: "/RT", field: "pfcDetailRT", width: 50 },
        { header: "Min Charge", field: "pfcDetailMinCharge", width: 100 },
        { header: "Surcharge Id", field: "pfcSurchargeId", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      // footer : {
      //   label : 'TOTAL'
      // },
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.columnDefsPrepaid = [
      {
        headerName: "Seq.",
        field: "pfcDetailSeqNo",
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      {
        headerName: "FAI",
        field: "pfcDetailFAIGroup",
        width: 75,
        tooltipField: "pfcDetailFAIGroup",
      },
      {
        headerName: "Charge Name",
        field: "pfcDetailChargeName",
        width: 200,
        tooltipField: "pfcDetailChargeName",
      },
      {
        headerName: "Charge Code",
        field: "pfcDetailChargeCode",
        width: 100,
        tooltipField: "pfcDetailChargeCode",
      },
      {
        headerName: "C/Type",
        field: "pfcDetailContainerTypeName",
        width: 100,
        tooltipField: "pfcDetailContainerTypeName",
      },
      {
        headerName: "Currency",
        field: "pfcDetailCurrency",
        width: 100,
        tooltipField: "pfcDetailCurrency",
      },
      {
        headerName: "D20",
        field: "pfcDetailD20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD20",
      },
      {
        headerName: "H20",
        field: "pfcDetailH20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH20",
      },
      {
        headerName: "D40",
        field: "pfcDetailD40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD40",
      },
      {
        headerName: "H40",
        field: "pfcDetailH40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH40",
      },
      {
        headerName: "D45",
        field: "pfcDetailD45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD45",
      },
      {
        headerName: "H45",
        field: "pfcDetailH45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH45",
      },
      {
        headerName: "P/C",
        field: "pfcDetailPC",
        width: 70,
        tooltipField: "pfcDetailPC",
      },
      {
        headerName: "Place of Payment",
        field: "pfcDetailPlaceOfPaymentName",
        width: 200,
        tooltipField: "pfcDetailPlaceOfPaymentName",
      },
      {
        headerName: "Payer",
        headerTooltip: "Payer",
        field: "pfcDetailPayerName",
        tooltipField: "pfcDetailPayerName",
        width: 200,
      },
      {
        headerName: "OH",
        field: "pfcDetailOogOH",
        width: 70,
        tooltipField: "pfcDetailOogOH",
      },
      {
        headerName: "OWL",
        field: "pfcDetailOogOWL",
        width: 70,
        tooltipField: "pfcDetailOogOWL",
      },
      {
        headerName: "OWR",
        field: "pfcDetailOogOWR",
        width: 70,
        tooltipField: "pfcDetailOogOWR",
      },
      {
        headerName: "OLF",
        field: "pfcDetailOogOLF",
        width: 70,
        tooltipField: "pfcDetailOogOLF",
      },
      {
        headerName: "OLB",
        field: "pfcDetailOogOLB",
        width: 70,
        tooltipField: "pfcDetailOogOLB",
      },
      {
        headerName: "Kill Slot",
        field: "pfcDetailKillSlot",
        width: 100,
        tooltipField: "pfcDetailKillSlot",
      },
      {
        headerName: "Door Open",
        field: "pfcDetailOogDoorOpenName",
        width: 100,
        tooltipField: "pfcDetailOogDoorOpenName",
      },
      {
        headerName: "UCH",
        field: "pfcDetailOogUcH",
        width: 70,
        tooltipField: "pfcDetailOogUcH",
      },
      {
        headerName: "UCL",
        field: "pfcDetailOogUcL",
        width: 70,
        tooltipField: "pfcDetailOogUcL",
      },
      {
        headerName: "UCW",
        field: "pfcDetailOogUcW",
        width: 70,
        tooltipField: "pfcDetailOogUcW",
      },
      {
        headerName: "/M3",
        field: "pfcDetailM3",
        width: 70,
        tooltipField: "pfcDetailM3",
      },
      {
        headerName: "/KT",
        field: "pfcDetailKT",
        width: 70,
        tooltipField: "pfcDetailKT",
      },
      {
        headerName: "/RT",
        field: "pfcDetailRT",
        width: 70,
        tooltipField: "pfcDetailRT",
      },
      {
        headerName: "Min Charge",
        field: "pfcDetailMinCharge",
        width: 100,
        tooltipField: "pfcDetailMinCharge",
      },
      {
        headerName: "Surcharge Id",
        field: "pfcSurchargeId",
        width: 100,
        tooltipField: "pfcSurchargeId",
      },
    ];

    this.settingGridCollect = {
      id: "gridCollect",
      url: "",
      page: 10,
      columns: [
        { header: "Seq.", field: "no", width: 50 },
        { header: "FAI", field: "pfcDetailFAIGroup", width: 75 },
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {
          header: "Charge Name",
          field: "pfcDetailChargeName",
          width: 200,
          type: "string",
        },
        {
          header: "Charge Code",
          field: "pfcDetailChargeCode",
          width: 100,
          type: "string",
        },
        //{header: 'C/Type Code', field: 'pfcDetailContainerType', width: 100},
        { header: "C/Type", field: "pfcDetailContainerTypeName", width: 100 },
        {
          header: "Currency",
          field: "pfcDetailCurrency",
          width: 50,
          type: "string",
        },
        { header: "D20", field: "pfcDetailD20", width: 50 },
        { header: "H20", field: "pfcDetailH20", width: 50 },
        { header: "D40", field: "pfcDetailD40", width: 50 },
        { header: "H40", field: "pfcDetailH40", width: 50 },
        { header: "D45", field: "pfcDetailD45", width: 50 },
        { header: "H45", field: "pfcDetailH45", width: 50 },
        { header: "P/C", field: "pfcDetailPC", width: 50 },
        {
          header: "Place of Payment",
          field: "pfcDetailPlaceOfPaymentName",
          width: 200,
          type: "string",
        },
        {
          header: "Payer",
          field: "pfcDetailPayerName",
          width: 200,
          type: "string",
        },
        { header: "OH", field: "pfcDetailOogOH", width: 50 },
        { header: "OWL", field: "pfcDetailOogOWL", width: 50 },
        { header: "OWR", field: "pfcDetailOogOWR", width: 50 },
        { header: "OLF", field: "pfcDetailOogOLF", width: 50 },
        { header: "OLB", field: "pfcDetailOogOLB", width: 50 },
        { header: "Kill Slot", field: "pfcDetailKillSlot", width: 100 },
        { header: "Door Open", field: "pfcDetailOogDoorOpenName", width: 100 },
        { header: "UCH", field: "pfcDetailOogUcH", width: 50 },
        { header: "UCL", field: "pfcDetailOogUcL", width: 50 },
        { header: "UCW", field: "pfcDetailOogUcW", width: 50 },
        { header: "/M3", field: "pfcDetailM3", width: 50 },
        { header: "/KT", field: "pfcDetailKT", width: 50 },
        { header: "/RT", field: "pfcDetailRT", width: 50 },
        { header: "Min Charge", field: "pfcDetailMinCharge", width: 100 },
        { header: "Surcharge Id", field: "pfcSurchargeId", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      // footer : {
      //   label : 'TOTAL'
      // },
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.columnDefsCollect = [
      {
        headerName: "Seq.",
        field: "pfcDetailSeqNo",
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      {
        headerName: "FAI",
        field: "pfcDetailFAIGroup",
        width: 75,
        tooltipField: "pfcDetailFAIGroup",
      },
      {
        headerName: "Charge Name",
        field: "pfcDetailChargeName",
        width: 200,
        tooltipField: "pfcDetailChargeName",
      },
      {
        headerName: "Charge Code",
        field: "pfcDetailChargeCode",
        width: 100,
        tooltipField: "pfcDetailChargeCode",
      },
      {
        headerName: "C/Type",
        field: "pfcDetailContainerTypeName",
        width: 100,
        tooltipField: "pfcDetailContainerTypeName",
      },
      {
        headerName: "Currency",
        field: "pfcDetailCurrency",
        width: 70,
        tooltipField: "pfcDetailCurrency",
      },
      {
        headerName: "D20",
        field: "pfcDetailD20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD20",
      },
      {
        headerName: "H20",
        field: "pfcDetailH20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH20",
      },
      {
        headerName: "D40",
        field: "pfcDetailD40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD40",
      },
      {
        headerName: "H40",
        field: "pfcDetailH40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH40",
      },
      {
        headerName: "D45",
        field: "pfcDetailD45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailD45",
      },
      {
        headerName: "H45",
        field: "pfcDetailH45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "pfcDetailH45",
      },
      {
        headerName: "P/C",
        field: "pfcDetailPC",
        width: 70,
        tooltipField: "pfcDetailPC",
      },
      {
        headerName: "Place of Payment",
        field: "pfcDetailPlaceOfPaymentName",
        width: 200,
        tooltipField: "pfcDetailPlaceOfPaymentName",
      },
      {
        headerName: "Payer",
        field: "pfcDetailPayerName",
        width: 200,
        headerTooltip: "Payer",
        tooltipField: "pfcDetailPayerName",
      },
      {
        headerName: "OH",
        field: "pfcDetailOogOH",
        width: 70,
        tooltipField: "pfcDetailOogOH",
      },
      {
        headerName: "OWL",
        field: "pfcDetailOogOWL",
        width: 70,
        tooltipField: "pfcDetailOogOWL",
      },
      {
        headerName: "OWR",
        field: "pfcDetailOogOWR",
        width: 70,
        tooltipField: "pfcDetailOogOWR",
      },
      {
        headerName: "OLF",
        field: "pfcDetailOogOLF",
        width: 70,
        tooltipField: "pfcDetailOogOLF",
      },
      {
        headerName: "OLB",
        field: "pfcDetailOogOLB",
        width: 70,
        tooltipField: "pfcDetailOogOLB",
      },
      {
        headerName: "Kill Slot",
        field: "pfcDetailKillSlot",
        width: 100,
        tooltipField: "pfcDetailKillSlot",
      },
      {
        headerName: "Door Open",
        field: "pfcDetailOogDoorOpenName",
        width: 100,
        tooltipField: "pfcDetailOogDoorOpenName",
      },
      {
        headerName: "UCH",
        field: "pfcDetailOogUcH",
        width: 70,
        tooltipField: "pfcDetailOogUcH",
      },
      {
        headerName: "UCL",
        field: "pfcDetailOogUcL",
        width: 70,
        tooltipField: "pfcDetailOogUcL",
      },
      {
        headerName: "UCW",
        field: "pfcDetailOogUcW",
        width: 70,
        tooltipField: "pfcDetailOogUcW",
      },
      {
        headerName: "/M3",
        field: "pfcDetailM3",
        width: 70,
        tooltipField: "pfcDetailM3",
      },
      {
        headerName: "/KT",
        field: "pfcDetailKT",
        width: 70,
        tooltipField: "pfcDetailKT",
      },
      {
        headerName: "/RT",
        field: "pfcDetailRT",
        width: 70,
        tooltipField: "pfcDetailRT",
      },
      {
        headerName: "Min Charge",
        field: "pfcDetailMinCharge",
        width: 100,
        tooltipField: "pfcDetailMinCharge",
      },
      {
        headerName: "Surcharge Id",
        field: "pfcSurchargeId",
        width: 100,
        tooltipField: "pfcSurchargeId",
      },
    ];

    //detail section
    this.settingCbMaintenanceDetailCharge = {
      id: "cbMaintenanceDetailCharge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlNonAdhoc/chargeCodeOrName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Charge",
      title: "chargeName", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Charge Id', field: 'chargeId', width:100},
        { header: "Charge Code", field: "chargeCode", width: 150 },
        { header: "Charge Name", field: "chargeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailCurrency = {
      id: "cbMaintenanceDetailCurrency",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findGeneral/Y/currencyCode/{query}/1/100/ASC/currencyCode",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Curr.",
      title: "currencyCode", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Currency ID', field: 'currencyId', width:100},
        { header: "Currency Code", field: "currencyCode", width: 300 },
        { header: "Currency Name", field: "currencyName", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailPC = {
      id: "cbMaintenanceDetailPC",
      type: "select", // search | select | select input
      url: '[{ "prepaid_collect" : "P", "prepaid_collect_name" : "PREPAID" },{ "prepaid_collect" : "C", "prepaid_collect_name" : "COLLECT" }]',
      urlType: "inject",
      placeholder: "P/C",
      code: "prepaid_collect",
      title: "prepaid_collect",
    };

    this.settingCbMaintenanceDetailPlaceOfPayment = {
      id: "cbMaintenanceDetailPlaceOfPayment",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationNamePOP={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Place of Payment",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailPayer = {
      id: "cbMaintenanceDetailPayers",
      type: "search enter", // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerPayer/{query}/1/100',
      url:
        this.configService.config.BASE_API.toString() +
        //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}",
        // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Payer",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      align: "right",
      columns: [
        //{header: 'Customer Id', field: 'customerId', width:100},
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isPayer", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    /*
    this.settingDateMaintenanceEffectiveFrom = {
      id: 'dateMaintenanceEffectiveFrom',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Effective From',
      customtext: '',
    }
    */

    this.settingDateMaintenanceEffectiveFrom2 = {
      id: "settingDateMaintenanceEffectiveFrom2",
      placeholder: "Effective From",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
      maxlength: 10
    };

    /*
    this.settingDateMaintenanceEffectiveTo = {
      id: 'dateMaintenanceEffectiveTo',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Expiry On',
      customtext: '',
    }
    */

    this.settingDateMaintenanceEffectiveTo2 = {
      id: "dateMaintenanceEffectiveTo2",
      placeholder: "Expiry On",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
      maxlength: 10
    };

    this.setting_nctFormForContainerType = {
      id: "nctform_ctrType",
      type: "select", // search | select | select input
      url: "",
      placeholder: "Select",
      code: "containerTypeName",
      title: "containerTypeName", // variable name
    };

    this.settingRemarksAgency = {
      id: "txtRemarksAgency",
      name: "pfcRemarksAgency",
      placeholder: "Remarks Agency",
      cols: 30,
      rows: 10,
      maxlength: 255,
      border: 500,
    };

    this.settingRemarksPrincipal = {
      id: "txtRemarksPrincipal",
      name: "pfcRemarksPrincipal",
      placeholder: "Remarks Principal",
      cols: 30,
      rows: 10,
      maxlength: 200,
      border: 500,
    };

    this.settingCbAcctPIC = {
      id: "cbAcctPIC",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findAcctPIC/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Acct PIC",
      title: "userName", // variable name
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "User Name", field: "userName", width: 170 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Id", field: "userId", width: 50 },
      ],
    };

    this.settingCbArchivePfc = {
      id: "cbArchivePfc",
      type: "select", // search | select | select input| search enter
      url: "",
        // this.configService.config.BASE_API.toString() +
        // "/PFCGenericAPI/findArchiveVersion/pfcRecordId",
      template: "grid", // default
      placeholder: "Archive PFC",
      code: "version",
      title: "version",
      description: "",
      // isMark: true,
      // allowRemove: true,
    };

    this.getRowStylePrepaid = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }

      // console.log("getRowStylePrepaid");
      // console.log(params.data);

      // if (params.data.pfcDetailPayerIsPayer == "N") {
      //   return { background: 'lightcoral' };
      // }
    };

    this.getRowStyleCollect = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }

      // console.log("getRowStyleCollect");
      // console.log(params.data);

      // if (params.data.pfcDetailPayerIsPayer == "N") {
      //   return { background: 'lightcoral' };
      // }
    };

    /***
     * End of Maintenance Section
     */
  }

  onGridReadyPrepaid(params) {
    this.gridApiPrepaid = params.api;
    this.gridColumnApiPrepaid = params.columnApi;
  }

  onGridReadyCollect(params) {
    this.gridApiCollect = params.api;
    this.gridColumnApiCollect = params.columnApi;
  }

  onRowSelected(event) {}

  onSelectionChanged(event) {}

  sortChangedEvent(event) {
    console.log("** sortChangedEvent **");

    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];

    var itemsToUpdate1 = [];
    let idx1 = 1;
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx1;
      x.no = idx1;
      itemsToUpdate1.push(x);
      prepaidList.push(x);
      idx1++;
    });
    this.gridApiPrepaid.updateRowData({ update: itemsToUpdate1 });

    var itemsToUpdate2 = [];
    let idx2 = 1;
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx2;
      x.no = idx2;
      itemsToUpdate2.push(x);
      collectList.push(x);
      idx2++;
    });
    this.gridApiCollect.updateRowData({ update: itemsToUpdate2 });

    let dets: FreightDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.pfcContainerType, dets);
  }

  ngOnInit() {
    this.rowDataPrepaid = [];
    this.rowDataCollect = [];
  }

  ngAfterViewInit() {
    $(".menu .item").tab();

    this.model.pfcAppStatus = "C";

    this.modelDetailList = [];
    this.modelDetailListx = [];
    this.firstInit();

    /*
     this.model.pfcDateCreated =  this.genericUtil.getPSIDate();
     */

    if (this.modeForm == "freightFlagStatus") {
      this.pfcToolbar.label = "Contract Rate Flag Status";
      this.cbReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
      );
      this.cbReceiptType.setValue("CY");
      this.cbDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
      );
      this.cbDeliveryType.setValue("CY");

      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());

      this.cbContainerStatus.setValue("ALL");
      this.cbContainerOwnership.setValue("ALL");
      this.cbShipmentType.setValue("ALL");
      this.cbDGClass.setValue("ALL");
      this.cbContainerType.setValue("ALL");
      this.cbValidInvalid.setValue("ALL");

      //this.model.pfcEffectiveFrom = theDate;

      //reset isLoad
      this.isLoad = false;
    } else {
      this.map.set(this.model.pfcContainerType, this.modelDetailList);
    }

    this.onPermission();

    //hide button
    this.invisibleToolbarButtons = "save,copy,nct,delete,invalid";
    this.cdr.detectChanges();

    // this.getListArchive("CSGJKTCAA0023");
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "nct,save,copy,update,delete,create";
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "create":
        this.handleCreate();
        break;
      case "save":
        this.model["error-pfcFreightPartyIdInvalid"] = "";
        this.validatorRules = {};
        this.isError = false;

        // if (this.isFreightPartyValidBasedOnCustomerType == 'N') {
        //   this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party";
        //   this.isError = true;
        // } else
        // if (this.isFreightPartyValidAsRecordLevel == 'N') {
        //   this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party (record level)";
        //   this.isError = true;
        // }else {
        //   this.model["error-pfcFreightPartyIdInvalid"] = "";
        //   this.validatorRules = {};
        //   this.isError = false;
        // }

        if (this.isModeArchive) {
          this.message(
            "information",
            "Information",
            "You are in archive mode.",
            "okonly",
            { ok: "" }
          );
          return false;
        }
        console.log( this.model["error-pfcFreightPartyIdInvalid"]);
        if (!this.isError) {
          this.handleSave();
        }
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.handleClose();
        break;
      case "print":
        this.message(
          "information",
          "Information",
          "Not yet implemented.",
          "okonly",
          { ok: "" }
        );
        break;
      case "copy":
        //this.handleSaveCopy();
        this.lastRecordId = this.model.pfcRecordId;
        this.lastEffectiveFrom = this.model.pfcEffectiveFrom;
        this.lastEffectiveTo = this.model.pfcEffectiveTo;
        this.model.pfcRecordId = "";
        this.model.pfcEffectiveFrom = "";
        this.model.pfcEffectiveTo = "";
        //this.dateMaintenanceEffectiveFrom.clearCalendar();
        //this.dateMaintenanceEffectiveTo.clearCalendar();

        this.dateMaintenanceEffectiveFrom2.reset();
        this.dateMaintenanceEffectiveTo2.reset();
        // this.dateCreatedForCbPayer = "*";
        // this.custIdForCbPayer = "*";
        this.apiEffectiveTo = "";
        this.listPayerExisting = [];
        this.groupAllowInvalidPayer = [];
        this.isError = false;
        this.clickSurcharge = false;
        this.clearValidatorError();
        break;
      case "nct":
        this.handleNCT();
        break;
      case "delete":
        this.handleDelete();
        break;
    }
  }

  eventMessage(event: any) {
    // console.log('**event message **')
    // console.log(event);
    if (event != "") {
      eval(event);
    }

    //console.log('current --> ' + this.dialogMultiple.current);
    //console.log('map size --> ' + this.dialogMultiple.settings.length);
    /*
    if (this.dialogMultiple.current == this.dialogMultiple.settings.length) {

      this.dialogMultiple.current = undefined;

      if (this.map.size == 0) {
        // console.log('not save');
        this.Check_MapSize();
        return;

      }
      else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }
    */
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false).modal("show");
  }

  rowDoubleClick(params) {
    let md: FreightDetail = params.data;

    this.isErrorDetail = false;
    this.clearValidatorErrorDetail();

    //let x  = this.gridApiPrepaid.getSelectedRows()
    //console.log(x.length)

    this.isUpdateDetail = true;

    this.modelDetail = new FreightDetail();
    this.modelDetail.pfcDetailChargeCode = md.pfcDetailChargeCode;
    this.modelDetail.pfcDetailChargeName = md.pfcDetailChargeName;
    this.modelDetail.pfcDetailContainerType = md.pfcDetailContainerType;
    switch (this.modelDetail.pfcDetailContainerType) {
      case "STD":
        this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
        break;
      case "DRY":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
        break;
      case "REF":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER";
        break;
      case "TNK":
        this.modelDetail.pfcDetailContainerTypeName = "TANK";
        break;
      case "OPT":
        this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
        break;
      case "PLT":
        this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
        break;
      case "FLR":
        this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
        break;
      case "UC":
        this.modelDetail.pfcDetailContainerTypeName = "UC";
        break;
      default:
        break;
    }
    this.modelDetail.pfcDetailCurrency = md.pfcDetailCurrency;
    this.modelDetail.pfcDetailD20 = md.pfcDetailD20;
    this.modelDetail.pfcDetailD40 = md.pfcDetailD40;
    this.modelDetail.pfcDetailD45 = md.pfcDetailD45;
    this.modelDetail.pfcDetailFAIGroup = md.pfcDetailFAIGroup;
    this.modelDetail.pfcDetailH20 = md.pfcDetailH20;
    this.modelDetail.pfcDetailH40 = md.pfcDetailH40;
    this.modelDetail.pfcDetailH45 = md.pfcDetailH45;
    this.modelDetail.pfcDetailKT = md.pfcDetailKT;
    this.modelDetail.pfcDetailKillSlot = md.pfcDetailKillSlot;
    this.modelDetail.pfcDetailM3 = md.pfcDetailM3;
    this.modelDetail.pfcDetailMinCharge = md.pfcDetailMinCharge;
    this.modelDetail.pfcDetailOogDoorOpen = md.pfcDetailOogDoorOpen;
    if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
      this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
    } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
      this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
    } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
      this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
    } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
      this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
    } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
      this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
    }
    this.modelDetail.pfcDetailOogOH = md.pfcDetailOogOH;
    this.modelDetail.pfcDetailOogOLB = md.pfcDetailOogOLB;
    this.modelDetail.pfcDetailOogOLF = md.pfcDetailOogOLF;
    this.modelDetail.pfcDetailOogOWL = md.pfcDetailOogOWL;
    this.modelDetail.pfcDetailOogOWR = md.pfcDetailOogOWR;
    this.modelDetail.pfcDetailOogUcH = md.pfcDetailOogUcH;
    this.modelDetail.pfcDetailOogUcL = md.pfcDetailOogUcL;
    this.modelDetail.pfcDetailOogUcW = md.pfcDetailOogUcW;
    this.modelDetail.pfcDetailPC = md.pfcDetailPC;
    this.modelDetail.pfcDetailPCTab = md.pfcDetailPCTab;
    this.modelDetail.pfcDetailPayer = md.pfcDetailPayer;
    this.modelDetail.pfcDetailPayerName = md.pfcDetailPayerName;
    this.modelDetail.pfcDetailPlaceOfPayment = md.pfcDetailPlaceOfPayment;
    this.modelDetail.pfcDetailPlaceOfPaymentName =
      md.pfcDetailPlaceOfPaymentName;
    this.modelDetail.pfcDetailRT = md.pfcDetailRT;
    this.modelDetail.pfcDetailSeqNo = md.pfcDetailSeqNo;
    this.modelDetail.pfcDetailStatus = md.pfcDetailStatus;

    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.pfcDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.pfcDetailCurrency
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);

    this.cbMaintenanceDetailPlaceOfPayment.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationCode={query}"
    );
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.pfcDetailPlaceOfPayment
    );

    if (md.pfcSurchargeId !== null && md.pfcSurchargeId !== undefined && md.pfcSurchargeId !== "") {
      var listPyr = [];
      if(md.pfcDetailPayer != ""){
        if (!listPyr.includes(md.pfcDetailPayer)) {
          listPyr.push(md.pfcDetailPayer);
        }
      }
      console.log(listPyr);
      if(listPyr.length > 0){
        var listPyrJoin = listPyr.join("%7C");
        this.custIdForCbPayer = this.custIdForCbPayer +"%7C"+ listPyrJoin;
      }
      console.log("this.custIdForCbPayer: "+this.custIdForCbPayer);
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
      );

    } else {
      console.log("this.custIdForCbPayer: "+this.custIdForCbPayer);
      if (this.listPayerExisting.length > 0) {
        this.custIdForCbPayer = this.listPayerExisting.join("%7C");
      }else{
        this.custIdForCbPayer = "*";
      }
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
      );
    }

    this.fpPayer = '';
    this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

    //OOG Control
    //Infer from selected container type
    if (
      this.modelDetail.pfcDetailContainerType == "STD" ||
      this.modelDetail.pfcDetailContainerType == "DRY"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = false;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.pfcDetailContainerType == "REF" ||
      this.modelDetail.pfcDetailContainerType == "TNK"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.pfcDetailContainerType == "PLT" ||
      this.modelDetail.pfcDetailContainerType == "FLR"
    ) {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.pfcDetailContainerType == "OPT") {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.pfcDetailContainerType == "UC") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    }

    this.genericUtil.showDialog(
      "freightNewChargeForm",
      "Freight Entry Screen",
      1200,
      500
    );
  }

  handleNCT() {
    // console.log(String(this.model.pfcContainerType).toUpperCase());

    var self = this;
    this.nctform_ctrType.listStore.store = [];
    let no: number = 0;
    this.map.forEach((value: any[], key: string) => {
      if (
        String(self.model.pfcContainerType).toUpperCase() != key &&
        value.length > 0
      ) {
        // console.log(key);
        var ct: string;
        switch (key) {
          case "TNK":
            ct = "TANK";
            break;
          case "OPT":
            ct = "OPEN TOP";
            break;
          case "FLR":
            ct = "FLAT RACK";
            break;
          case "PLT":
            ct = "PLATFORM";
            break;
          case "REF":
            ct = "REEFER";
            break;
          case "DRY":
            ct = "REEFER DRY";
            break;
          case "UC":
            ct = "UC";
            break;
          default:
            ct = "NORMAL";
            break;
        }
        self.nctform_ctrType.listStore.addData({
          no: no++,
          code: key,
          title: ct,
        });
      }
    });

    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('show');
    */
    this.genericUtil.showDialog("NCTForm", "NCT Form", 300, 200);
  }

  onNCT_Save() {
    this.message(
      "information",
      "Confirm",
      "Do you really want to CREATE a New Cntr. Type to the current record?",
      "yesno",
      { yes: "this.onNCT_Save_Process()", no: "" }
    );
  }

  onNCT_Save_Process() {
    var ct: any = this.nctform_ctrType.getValue();
    var result: FreightDetail[] = [];
    if (ct && ct.code) {
      var result: FreightDetail[] = this.map.get(ct["code"]);
    }
    var result2: FreightDetail[] = [];
    result.forEach((element) => {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = String(
        this.model.pfcContainerType
      ).toUpperCase();
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

      if (String(this.model.pfcContainerType).toUpperCase() == "TNK") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;
        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "REF") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;
        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "OPT") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;
        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "FLR") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "PLT") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "DRY") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "UC") {
        x.pfcDetailOogDoorOpen = "N";
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        result2.push(x);
      } else if (String(this.model.pfcContainerType).toUpperCase() == "STD") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogUcH = 0;
        x.pfcDetailOogUcL = 0;
        x.pfcDetailOogUcW = 0;

        result2.push(x);
      }
    });

    if (result2.length > 0) {
      let idx = 1;
      let idx2 = 1;
      result2.forEach((element) => {
        var x: FreightDetail = element;
        if (x.pfcDetailPCTab == "P") {
          x.pfcDetailSeqNo = idx;
          x.no = idx;
          idx++;
        }
        if (x.pfcDetailPCTab == "C") {
          x.pfcDetailSeqNo = idx2;
          x.no = idx2;
          idx2++;
        }
      });

      this.map.set(String(this.model.pfcContainerType).toUpperCase(), result2);

      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);

      var detailList: any[] = this.map.get(this.model.pfcContainerType);
      if (detailList != null && detailList != undefined) {
        detailList.forEach((element) => {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          var ct: string;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              ct = "TANK";
              break;
            case "OPT":
              ct = "OPEN TOP";
              break;
            case "FLR":
              ct = "FLAT RACK";
              break;
            case "PLT":
              ct = "PLATFORM";
              break;
            case "REF":
              ct = "REEFER";
              break;
            case "DRY":
              ct = "REEFER DRY";
              break;
            case "UC":
              ct = "UC";
              break;
            default:
              ct = "NORMAL";
              break;
          }
          x.pfcDetailContainerTypeName = ct;
          x.pfcDetailCopyTab = element.pfcDetailCopyTab;
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if (x.pfcDetailOogDoorOpen == "N") {
            x.pfcDetailOogDoorOpenName = "N/A";
          } else if (x.pfcDetailOogDoorOpen == "D") {
            x.pfcDetailOogDoorOpenName = "Detached";
          } else if (x.pfcDetailOogDoorOpen == "B") {
            x.pfcDetailOogDoorOpenName = "Tied Both";
          } else if (x.pfcDetailOogDoorOpen == "L") {
            x.pfcDetailOogDoorOpenName = "Tied Left";
          } else if (x.pfcDetailOogDoorOpen == "R") {
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
          x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          if (x.pfcDetailPCTab == "P") {
            this.gridApiPrepaid.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }

          if (x.pfcDetailPCTab == "C") {
            this.gridApiCollect.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }
        });

        this.calculateTotal();
      }

      this.message(
        "information",
        "Information",
        " Rates Copied Successfully.",
        "okonly",
        { ok: 'this.closeDialog("NCTForm")' }
      );
      return;
    } else {
      this.message("information", "Information", "Unable to copy.", "okonly", {
        ok: "this.closeDialog('NCTForm')",
      });
      return;
    }

    /*
    var ct: any = this.nctform_ctrType.getValue();
    var result: FreightDetail[] = this.map.get(ct['code'])
    var result2: FreightDetail[] = [];
    result.forEach(element => {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = String(this.model.pfcContainerType).toUpperCase();
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;


      if (String(this.model.pfcContainerType).toUpperCase() == 'TNK') {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogDoorOpen = 'N';

        if(element.pfcDetailOogDoorOpen == "N" && (element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0)){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == 'REF') {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogDoorOpen = 'N';

        if(element.pfcDetailOogDoorOpen == "N" && (element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0)){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == 'OPT') {
        x.pfcDetailOogDoorOpen = 'N';

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == 'FLR') {
        x.pfcDetailOogDoorOpen = 'N';

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == 'PLT') {
        x.pfcDetailOogDoorOpen = 'N';

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "DRY") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;


        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }
      } else if (String(this.model.pfcContainerType).toUpperCase() == "UC") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;

        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == 'STD') {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;

        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }
      }

    });

    if(result2.length > 0){
      this.map.set(String(this.model.pfcContainerType).toUpperCase(), result2);

      this.gridPrepaid.onClear();
      this.gridCollect.onClear();
      var detailList: any[] = this.map.get(this.model.pfcContainerType)
      if (detailList != null && detailList != undefined) {
        detailList.forEach(element => {

          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          var ct: string
          switch (x.pfcDetailContainerType) {
            case 'TNK':
              ct = 'TANK';
              break;
            case 'OPT':
              ct = 'OPEN TOP';
              break;
            case 'FLR':
              ct = 'FLAT RACK';
              break;
            case 'PLT':
              ct = 'PLATFORM';
              break;
            case 'REF':
              ct = 'REEFER';
              break;
            case 'DRY':
              ct = 'REEFER DRY';
              break;
            case 'UC':
              ct = 'UC';
              break;
            default:
              ct = 'NORMAL';
              break;
          }
          x.pfcDetailContainerTypeName = ct;
          x.pfcDetailCopyTab = element.pfcDetailCopyTab;
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          if (x.pfcDetailPCTab == 'P') {
            this.gridPrepaid.listStore.store.push(x);
            this.gridPrepaid.loadData();
          }

          if (x.pfcDetailPCTab == 'C') {
            this.gridCollect.listStore.store.push(x);
            this.gridCollect.loadData();
          }

        });
      }

      this.message('information', 'Information', ' Rates Copied Successfully.', 'okonly', {ok: 'this.closeDialog("NCTForm")'});
      return;

    }else{
      this.message('information', "Information", "Unable to copy.", "okonly", {ok: "this.closeDialog('NCTForm')"});
      return;
    }
    */
  }

  onNCT_Cancel(event: any) {
    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('hide');
    */
    this.genericUtil.closeDialog("NCTForm");
  }

  value_nctFormForContainerType(event: any) {
    // console.log('selected nct value : ' + event);
  }

  //used to handle retrieve event
  //When user click button retrieve the action goed to this routine

  handleRetrieve() {
    // console.log('this.model.pfcRecordId --> ' + this.model.pfcRecordId);
    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.pfcEffectiveFrom = this.dateEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateEffectiveTo.getValue();

    this.mainGrid.curPage = 1;
    this.mainGrid.currentPage = 1;
    this.isError = false;
    this.model["error-pfcFreightPartyId"] = "";
    this.model["error-pfcShipperId"] = "";
    this.model["error-pfcBookingPartyId"] = "";

    if (this.model.pfcSerchByRefNo == "Y") {
      this.model["error-pfcRecordId"] = "";
      this.validatorRules = {
        pfcRecordId: {
          rules: [{ type: "empty", prompt: "Please input Reference No." }],
        },
      };
      this.isErrorFlag = this.onValidate(this.model);

      if (!this.isErrorFlag) {
        this.mainGrid.onClear();
        this.mainGrid.setBody(this.model);
        this.isLoad = true;
        this.mainGrid.loadData();
      }
    } else {
      this.model["error-pfcRecordId"] = "";
      this.validatorRules = {};
      this.isErrorFlag = false;

      if (this.model.pfcVesselId === "" && this.model.pfcVoyage !== "") {
        this.model["error-pfcRecordId"] = "Please input Adhoc Vessel";
        this.isErrorFlag = true;
      } else {
        this.model["error-pfcRecordId"] = "";
        this.validatorRules = {};
        this.isErrorFlag = false;
      }

      this.mainGrid.onClear();
      this.mainGrid.setBody(this.model);
      this.isLoad = true;
      this.mainGrid.loadData();
    }
  }

  //user to save the data
  handleSave() {
    this.isCopy = false;
    this.model.isCopy = false;
    this.onSave();
  }

  handleSaveCopy() {
    this.isCopy = true;
    this.model.isCopy = true;
    this.onSave();
  }

  onSave() {
    this.responseArchive = {};
    //set the office code
    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();

    //set effective from and to
    //this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    //this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom2.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo2.getValue();

    //set user created and modified
    // let userId = this.cookieService.getName().toUpperCase();
    // let theDate = this.genericUtil.getPSIDate();
    // if (
    //   this.model.pfcRecordId == null ||
    //   this.model.pfcRecordId == "" ||
    //   this.model.pfcRecordId == undefined
    // ) {
    //   this.model.pfcUserCreated = userId;
    //   this.model.pfcDateCreated = theDate;
    //   this.model.pfcUserModified = userId;
    //   this.model.pfcDateModified = theDate;
    // } else {
    //   this.model.pfcUserModified = userId;
    //   this.model.pfcDateModified = theDate;
    //   // GOS-827  Incorrect Creation ID & Creation Time Stamp Captured for New Creation
    //   this.model.pfcUserCreated = userId;
    //   this.model.pfcDateCreated = theDate;
    //   this.model.pfcUserId = userId;
    // }

    this.model.pfcPrincipalRemarks = this.txtRemarksPrincipal.getValue();
    this.model.pfcAgentRemarks = this.txtRemarksAgency.getValue();

    this.setValidatorSave();
    this.model["effectiveDateExceedValidator"] =
      this.checkEffectiveDateExceedValidator();
    this.model["expiryDateValidator"] = this.checkExpiryDateValidator();
    this.model["samePORValidator"] = this.checkSamePORValidator();
    this.model["samePOLValidator"] = this.checkSamePOLValidator();
    this.model["samePODValidator"] = this.checkSamePODValidator();
    this.model["samePODLValidator"] = this.checkSamePODLValidator();
    this.model["sameFDestValidator"] = this.checkSameFDestValidator();
    this.model["blankFreightChargesValidator"] =
      this.checkBlankFreightChargesValidator();
    this.model["mainChargesOnlyOneValidator"] =
      this.checkMainChargesOnlyOneValidator();
    this.model["blankDetentionRequestValidator"] =
      this.checkBlankDetentionRequestValidator();
    this.model["blankDemurrageRequestValidator"] =
      this.checkBlankDemurrageRequestValidator();
    this.model["blankAdhocVoyageValidator"] =
      this.checkBlankAdhocVoyageValidator();
    this.model["blankPayerValidator"] = this.checkBlankPayerValidator();
    //this.model['exceedPayerPOPValidator'] = this.checkExceedPayerPOPValidator();
    this.model["exceedPayerPOPValidator"] = false;

    this.model["faiGroupValidator"] = this.checkFAIGroupValidator();

    if (!this.model["faiGroupValidator"]) {
      this.model["missingMainChargesValidator"] =
        this.checkMissingMainChargesValidator();
    }

    this.model["checkDuplicateValidator"] = this.checkDuplicateValidator();

    // this.model["invalidPayerValidator"] = this.checkInvalidPayerValidator();

    this.model["allInvalidPayerValidator"] = this.checkAllInvalidPayer();
    // console.log('** before validate **');
    // console.log(this.model);
    // console.log(this.map);

    //begin of main handle save
    // this.lock = false;
    this.isError = this.onValidate(this.model);;
    if (this.isError) {
      window.scrollTo(0, 0);
    } else {
      let modelKey = new FreightManagement();
      modelKey.pfcOfficeCode = this.model.pfcOfficeCode;
      modelKey.pfcFreightPartyId = this.model.pfcFreightPartyId;
      modelKey.pfcBookingPartyId = this.model.pfcBookingPartyId;
      modelKey.pfcShipperId = this.model.pfcShipperId;
      modelKey.pfcPlaceOfReceiptCode = this.model.pfcPlaceOfReceiptCode;
      modelKey.pfcLoadPortCode = this.model.pfcLoadPortCode;
      modelKey.pfcDischargePortCode = this.model.pfcDischargePortCode;
      modelKey.pfcPlaceOfDeliveryCode = this.model.pfcPlaceOfDeliveryCode;
      modelKey.pfcFinalDestinationCode = this.model.pfcFinalDestinationCode;
      modelKey.pfcPortOfTransshipment1Code =
        this.model.pfcPortOfTransshipment1Code;
      modelKey.pfcPortOfTransshipment2Code =
        this.model.pfcPortOfTransshipment2Code;
      modelKey.pfcPortOfTransshipment3Code =
        this.model.pfcPortOfTransshipment3Code;
      modelKey.pfcCommodityId = this.model.pfcCommodityId;
      modelKey.pfcReceiptTypeCode = this.model.pfcReceiptTypeCode;
      modelKey.pfcDeliveryTypeCode = this.model.pfcDeliveryTypeCode;
      modelKey.pfcContainerOwnership = this.model.pfcContainerOwnership;
      modelKey.pfcContainerStatus = this.model.pfcContainerStatus;
      modelKey.pfcShipmentType = this.model.pfcShipmentType;
      modelKey.pfcVesselId = this.model.pfcVesselId;
      modelKey.pfcVoyage = this.model.pfcVoyage;

      modelKey.pfcDetentionPOL = this.model.pfcDetentionPOL;
      modelKey.pfcDetentionRequestPOL = this.model.pfcDetentionRequestPOL;
      modelKey.pfcDemurragePOL = this.model.pfcDemurragePOL;
      modelKey.pfcDemurrageRequestPOL = this.model.pfcDemurrageRequestPOL;

      modelKey.pfcDetention = this.model.pfcDetention;
      modelKey.pfcDetentionRequest = this.model.pfcDetentionRequest;
      modelKey.pfcDemurrage = this.model.pfcDemurrage;
      modelKey.pfcDemurrageRequest = this.model.pfcDemurrageRequest;
      modelKey.pfcIhc = this.model.pfcIhc;
      modelKey.pfcDgClass = this.model.pfcDgClass;
      modelKey.pfcIsValid = this.model.pfcIsValid;
      modelKey.pfcEffectiveFrom = this.model.pfcEffectiveFrom;
      modelKey.pfcEffectiveTo = this.model.pfcEffectiveTo;

      let currentFreightManagementKey =
        this.getFreightManagementKeyString(modelKey);
      let currentFreightManagement = this.getFreightManagementString(
        this.model
      );

      let currentFreightDetails = "";
      let currentContainerTypeList: any[] = [];
      this.model.pfcDetails = [];
      this.map.forEach((value: FreightDetail[], key: string) => {
        // console.log(key);
        currentContainerTypeList.push(key);

        value.forEach((element) => {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          x.pfcDetailCopyTab = element.pfcDetailCopyTab;
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;
          x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

          currentFreightDetails += this.getFreightDetailStringX(x, this.model);
          this.model.pfcDetails.push(x);

          if (this.mapCheck.get(x.pfcDetailContainerType) != undefined) {
            console.log(this.mapCheck.get(x.pfcDetailContainerType));
            console.log(this.getFreightDetailString(x));
            if (
              this.mapCheck.get(x.pfcDetailContainerType) !=
              this.getFreightDetailStringX(x, this.model)
            ) {
              this.mapCheck.delete(x.pfcDetailContainerType);
            }
          } else {
          }

        });
      });

      console.log('** currentContainerTypeList **');
      console.log(currentContainerTypeList);

      console.log('** retrieveContainerTypeList **');
      console.log(this.containerTypeList);

      console.log('** retrieveFreightManagement **');
      console.log(this.freightManagementRetrieve);
      console.log('** currentFreightManagement **');
      console.log(currentFreightManagement);
      console.log('***************************************************************************************************************************************');
      console.log('** retrieveFreightDetails **');
      console.log(this.freightDetailsRetrieve);
      console.log('** currentFreightDetails **');
      console.log(currentFreightDetails);

      this.timeModifiedFreightParty = this.dateToTime(this.dateModifiedFreigthParty);

      if (this.dateModifiedBookingParty != "") {
        this.timeModifiedBookingParty = this.dateToTime(this.dateModifiedBookingParty);
      }

      if (this.dateModifiedShipper != "") {
        this.timeModifiedShipper = this.dateToTime(this.dateModifiedShipper);
      }

      this.timeCreatedPfc = this.dateToTime(this.model.pfcDateCreated);
      console.log("this.dateModifiedFreigthParty : "+this.dateModifiedFreigthParty);
      console.log("this.dateModifiedBookingParty : "+this.dateModifiedBookingParty);
      console.log("this.dateModifiedShipper : "+this.dateModifiedShipper);
      console.log("this.model.pfcDateCreated : "+this.model.pfcDateCreated);

      console.log("timeModifiedFreightParty: "+ this.timeModifiedFreightParty);
      console.log("timeModifiedBookingParty: "+ this.timeModifiedBookingParty);
      console.log("timeModifiedShipper: "+ this.timeModifiedShipper);
      console.log("timeCreatedPfc: "+ this.timeCreatedPfc);
      if (
        this.freightManagementRetrieve == currentFreightManagement &&
        this.freightDetailsRetrieve == currentFreightDetails
      ) {

        this.message(
          "information",
          "Information",
          "No changes has been detected.",
          "okonly",
          { ok: "" }
        );
        return;
      } else {
        if (this.freightManagementRetrieve != currentFreightManagement) {

          //New
          this.genericUtil.showLoader();
          this.genericService
            .POST(
              this.configService.config.BASE_API.toString() +
                "/PFCContractRates/validateSave/",
              this.model
            )
            .subscribe((resp) => {
              //this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/validateSave/', this.model).subscribe((resp) => {
              if (resp.status == 200) {
                let result = resp.json();

                if (result.status == 1 || result.status == 2) {
                  this.genericUtil.hideLoader();
                  this.message(
                    "information",
                    "Information",
                    result.message,
                    "okonly",
                    { ok: "" }
                  );
                  return;
                } else {
                  // console.log('** freightManagementKeyRetrieve **');
                  // console.log(this.freightManagementKeyRetrieve);
                  // console.log('** currentFreightManagementKey **');
                  // console.log(currentFreightManagementKey);
                  if (this.model.pfcRecordId == "") {
                      this.message(
                        "information",
                        "Information",
                        "Do you really want to create a New record?",
                        "okcancel",
                        {
                          // ok: "this.ClearRecordId();this.UpdateMap();",
                          ok:"this.validateAfterSaveNew()",
                          cancel: "this.genericUtil.hideLoader();",
                        }
                      );
                      return;
                  } else {
                    // console.log(
                    //   this.freightManagementKeyRetrieve ==
                    //   currentFreightManagementKey
                    // );
                    // console.log("this.freightManagementKeyRetrieve "+this.freightManagementKeyRetrieve);
                    // console.log("currentFreightManagementKey "+currentFreightManagementKey);
                    if (
                      this.freightManagementKeyRetrieve ==
                      currentFreightManagementKey
                    ) {
                      console.log("modified atas");
                      if (this.timeCreatedPfc > this.timeModifiedFreightParty && this.isFreightPartyValidAsRecordLevel == 'N') {
                        // if (this.isFreightPartyValidAsRecordLevel == 'N') {
                        this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party (record level)";
                        this.isError = true;
                      }

                      if (this.timeModifiedBookingParty != 0 && this.timeCreatedPfc > this.timeModifiedBookingParty && this.searchBkIsValid == 'N') {
                          this.model["error-pfcBookingPartyIdInvalid"] = "Please select a valid Booking Party (record level)";
                          this.isError = true;
                      }

                      if (this.timeModifiedShipper != 0 && this.timeCreatedPfc > this.timeModifiedShipper && this.searchShIsValid == 'N') {
                        this.model["error-pfcShipperIdInvalid"] = "Please select a valid Shipper (record level)";
                        this.isError = true;
                      }

                      if (this.isError) {
                        window.scrollTo(0, 0);
                        this.genericUtil.hideLoader();
                      } else {
                        this.createArchivePfc();
                        if (currentContainerTypeList > this.containerTypeList) {
                          //new CType
                          this.message(
                            "information",
                            "Information",
                            "Do you really want to Create a new cntr type to the existing record?",
                            "okcancel",
                            {
                              ok: "this.UpdateMap();",
                              cancel: "this.genericUtil.hideLoader();",
                            }
                          );
                          return;
                        } else {
                          this.message(
                            "information",
                            "Information",
                            "Are you sure to update the existing Reference No ?",
                            "okcancel",
                            {
                              ok: "this.UpdateMap();", //this.validateUpdate();
                              cancel: "this.genericUtil.hideLoader();",
                            }
                          );
                          return;
                        }
                      }

                    } else {
                      // console.log(
                      //   this.freightManagementKeyRetrieve ==
                      //   currentFreightManagementKey
                      // );
                      // console.log("this.freightManagementKeyRetrieve "+this.freightManagementKeyRetrieve);
                      // console.log("currentFreightManagementKey "+currentFreightManagementKey);

                      //this.model.pfcRecordId = '';
                        this.message(
                          "information",
                          "Information",
                          "Do you really want to create a New record?",
                          "okcancel",
                          {
                            ok:"this.validateAfterSaveNew()",
                            // ok: "this.ClearRecordId();this.UpdateMap();",
                            cancel: "this.genericUtil.hideLoader();",
                          }
                        );
                        return;
                    }
                  }
                }
              }
          });
        } else {
          // console.log(this.containerTypeList.length + ' - ' + currentContainerTypeList.length);

          if (currentContainerTypeList > this.containerTypeList) {
            this.createArchivePfc();
            if (this.model.pfcAppStatus == "A") {
              this.message(
                "information",
                "Information",
                "Do You Want to Re-Request Approved Rates?",
                "okcancel",
                {
                  ok: "this.nextProcessUpdateDetail1()",
                  cancel: "this.genericUtil.hideLoader();",
                }
              );
              return;
            } else {
              console.log("modified bawah");
              if (this.timeCreatedPfc > this.timeModifiedFreightParty && this.isFreightPartyValidAsRecordLevel == 'N') {
              // if (this.isFreightPartyValidAsRecordLevel == 'N') {
                this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party (record level)";
                this.isError = true;
              }

              if (this.timeModifiedBookingParty != 0 && this.timeCreatedPfc > this.timeModifiedBookingParty && this.searchBkIsValid == 'N') {
                this.model["error-pfcBookingPartyIdInvalid"] = "Please select a valid Booking Party (record level)";
                this.isError = true;
              }

              if (this.timeModifiedShipper != 0 && this.timeCreatedPfc > this.timeModifiedShipper && this.searchShIsValid == 'N') {
                this.model["error-pfcShipperIdInvalid"] = "Please select a valid Shipper (record level)";
                this.isError = true;
              }

              if (this.isError) {
                window.scrollTo(0, 0);
                this.genericUtil.hideLoader();
              } else {
                //new CType
                this.message(
                  "information",
                  "Information",
                  "Do you really want to Create a new cntr type to the existing record?",
                  "okcancel",
                  {
                    ok: "this.UpdateMap();",
                    cancel: "this.genericUtil.hideLoader();",
                  }
                );
                return;
              }

            }
          } else {
            this.createArchivePfc();
            // console.log('** details changed **');
            if (this.model.pfcAppStatus == "A") {
              this.message(
                "information",
                "Information",
                "Do You Want to Re-Request Approved Rates?",
                "okcancel",
                {
                  ok: "this.nextProcessUpdateDetail2()",
                  cancel: "this.genericUtil.hideLoader();",
                }
              );
              return;
            } else {
              //Update Details
              // console.log("sini gak?");
              // console.log("this.isFreightPartyValidAsRecordLevel "+this.isFreightPartyValidAsRecordLevel);
              // console.log("this.isFreightPartyValidBasedOnCustomerType "+this.isFreightPartyValidBasedOnCustomerType);
              console.log("bawah banget");
              if (this.timeCreatedPfc > this.timeModifiedFreightParty && this.isFreightPartyValidAsRecordLevel == 'N') {
              // if (this.isFreightPartyValidAsRecordLevel == 'N') {
                this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party (record level)";
                this.isError = true;
                window.scrollTo(0, 0);
                this.genericUtil.hideLoader();
              }

              if (this.timeModifiedBookingParty != 0 && this.timeCreatedPfc > this.timeModifiedBookingParty && this.searchBkIsValid == 'N') {
                  this.model["error-pfcBookingPartyIdInvalid"] = "Please select a valid Booking Party (record level)";
                  this.isError = true;
                  window.scrollTo(0, 0);
                  this.genericUtil.hideLoader();
              }

              if (this.timeModifiedShipper != 0 && this.timeCreatedPfc > this.timeModifiedShipper && this.searchShIsValid == 'N') {
                  this.model["error-pfcShipperIdInvalid"] = "Please select a valid Shipper (record level)";
                  this.isError = true;
              }

              if (this.isError) {
                window.scrollTo(0, 0);
                this.genericUtil.hideLoader();
              } else {
                this.message(
                  "information",
                  "Information",
                  "Do you really want to OVERWRITE the record?",
                  "okcancel",
                  { ok: "this.saveEvent();", cancel: "" }
                );
                return;
              }

            }
          }
        }
      }
    }

    //end of main handle save
  }

  nextProcessUpdateDetail1() {
    this.message(
      "information",
      "Information",
      "Do you really want to Create a new cntr type to the existing record?",
      "okcancel",
      { ok: "this.UpdateMap();", cancel: "this.genericUtil.hideLoader();" }
    );
    return;
  }

  nextProcessUpdateDetail2() {
    this.message(
      "information",
      "Information",
      "Do you really want to OVERWRITE the record?",
      "okcancel",
      { ok: "this.saveEvent();", cancel: "" }
    );
    return;
  }

  saveEvent() {
    //set status to P
    this.model.pfcAppStatus = "C";
    this.model.pfcIsTransmitted = "N";
    this.model.pfcPrincipalRemarks = this.txtRemarksPrincipal.getValue();
    this.model.pfcAgentRemarks = this.txtRemarksAgency.getValue();
    console.log(this.responseArchive);
    // this.freightManagementRetrieve = this.getFreightManagementString(this.model);
    // this.freightManagementKeyRetrieve = this.getFreightManagementKeyString(this.model);
    // this.genericUtil.hideLoader();
    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCFreightApplication/save",
        this.model
      )
      .subscribe(
        (resp) => {
          let data = resp.json();
          if (data.status == 1 || data.status == 2) {
            this.genericUtil.hideLoader();
            this.message("information", "Information", data.message, "okonly", {
              ok: "",
            });
            return;
          } else {
            this.model.pfcRecordId = data.pfcRecordId;
            this.model.pfcEffectiveFrom = data.pfcEffectiveFrom;
            this.model.pfcEffectiveTo = data.pfcEffectiveTo;
            this.model.pfcUserCreated = data.pfcUserCreated;
            this.model.pfcUserModified = data.pfcUserModified;
            this.model.pfcDateCreated = data.pfcDateCreated;
            this.model.pfcDateModified = data.pfcDateModified;

            // console.log(this.model);
            this.genericUtil.hideLoader();
            if (this.isCopy) {
              this.message(
                "information",
                "Information",
                "Saved successfully with Reference No " +
                  this.model.pfcRecordId,
                "okonly",
                { ok: "this.loading=false;this.afterSaveCopy();" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Saved successfully with Reference No " +
                  this.model.pfcRecordId,
                "okonly",
                { ok: "this.loading=false;this.afterSave();this.saveArchivePfc();" }
              );
            }
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterSave() {
    // console.log('** after save **');
    this.isCopy = false;
    this.isNew = false;
    this.dateMaintenanceEffectiveFrom2.setDisable(false);
    this.dateMaintenanceEffectiveTo2.setDisable(false);
    this.lock = true;
    this.isModeArchive = false;
    this.freightManagementRetrieve = this.getFreightManagementString(this.model);
    this.freightManagementKeyRetrieve = this.getFreightManagementKeyString(this.model);

    //this.afterClosedFromMaintenance();
    //do nothing for now
    //this.lock = true;

    /*
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/retrieveFreightApplicationForUpdate/' + this.model.pfcRecordId.trim() + '/' + this.model.pfcContainerType).subscribe((resp) => {
      //// console.log(resp);
      this.handleDoubleClick(resp.json()['content']);
      this.isNew = false;
      this.dateMaintenanceEffectiveFrom2.setDisable(false);
      this.dateMaintenanceEffectiveTo2.setDisable(false);
    });
    */
  }

  afterSaveCopy() {
    // console.log('** after save copy **');
    //do nothing for now
    //this.lock = true;
    this.model.pfcRecordId = "";
    this.isCopy = false;
    this.isNew = false;
    this.dateMaintenanceEffectiveFrom2.setDisable(false);
    this.dateMaintenanceEffectiveTo2.setDisable(false);
    this.isModeArchive = false;

    if (
      this.model.pfcEffectiveFrom !== null &&
      this.model.pfcEffectiveFrom !== "" &&
      this.model.pfcEffectiveFrom !== undefined
    ) {
      //this.dateMaintenanceEffectiveFrom.setValue(this.model.pfcEffectiveFrom);
      this.dateMaintenanceEffectiveFrom2.setValue(this.model.pfcEffectiveFrom);
    }

    //this.dateMaintenanceEffectiveTo.setValue(this.model.pfcEffectiveTo);
    this.dateMaintenanceEffectiveTo2.setValue(this.model.pfcEffectiveTo);
  }

  handleDelete() {
    this.modelDelete = new FreightManagement();
    this.message(
      "information",
      "Information",
      "Are you sure want to delete this record?",
      "yesno",
      {
        yes: 'this.genericUtil.showDialog("DeleteForm", "Delete Form", 300, 300)',
        no: "",
      }
    );
  }

  deleteProcess() {
    //this.modelDelete =  new FreightManagement();
    this.modelDelete.pfcRecordId = this.model.pfcRecordId;
    this.modelDelete.pfcIsDeleted = "Y";
    this.modelDelete.pfcUserDeleted = this.cookieService
      .getName()
      .toUpperCase();
    this.modelDelete.pfcDateDeleted = this.genericUtil.getPSIDate();
    //this.modelDelete.pfcReasonDeleted = document.getElementById('reasonDelete')

    this.modelDelete["error-pfcReasonDeleted"] = "";
    this.validatorRules = {
      pfcReasonDeleted: {
        rules: [{ type: "empty", prompt: "Please input Reason." }],
      },
    };
    this.isErrorDeleted = this.onValidate(this.modelDelete);

    if (!this.isErrorDeleted) {
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/PFCContractRates/delete",
          this.modelDelete
        )
        .subscribe((resp) => {
          // console.log(resp);
          if (resp.statusText == "OK") {
            this.lock = true;
            this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
            this.message(
              "information",
              "Information",
              "Deleted Successfully.",
              "okonly",
              { ok: "" }
            );
          } else {
          }
          this.isErrorDeleted = false;
          this.modelDelete = new FreightManagement();
          this.genericUtil.closeDialog("DeleteForm");
          //this.gridPrepaid.loadData();
          //this.gridCollect.loadData();
        });
    }
  }

  mainGridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "nct,save,copy,update,delete,create";
        } else {
          this.disableToolbarButtons = "copy,delete";
        }
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split("-")[1];

        this.mainGrid.clearSelectedValues();
        this.mainGrid.onSelect(true, Number.parseInt(no));

        break;
      case "afterLoad":
        if (this.mainGrid.listStore.store.length == 0) {
          if (this.isLoad) {
            this.message(
              "information",
              "Information",
              "No record(s) found.",
              "okonly",
              { ok: "this.loading=false;this.isLoad=false;" }
            );
          }
        }
      //this.mainGrid.onSelect(true,1);

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              //handle double Clicked
              let dt = this.mainGrid.getSelectedValues()[0];

              this.genericService
                .GET(
                  this.configService.config.BASE_API.toString() +
                    "/PFCFreightApplication/retrieveFreightApplicationForUpdate/" +
                    dt.pfcRecordId.trim() +
                    "/" +
                    dt.pfcCType
                )
                .subscribe((resp) => {
                  //// console.log(resp);

                  var x: FreightManagement = new FreightManagement();
                  x = resp.json()["content"];
                  // console.log(x);
                  this.handleDoubleClick(x);
                });

              break;
            default:
              break;
          }
        }
        break;
    }
  }

  changeEventPod(event) {}

  infoGrid(event) {}

  initTab1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";

    this.currentActiveTab = "prepaid";

    this.map.forEach((value: FreightDetail[], key: string) => {
      if (key == this.model.pfcContainerType) {
        value.forEach((each) => {
          let mainKey =
            each.pfcDetailPCTab +
            ";;" +
            each.pfcDetailContainerType +
            ";;" +
            (each.pfcDetailD20 != 0 ? "20" : "-") +
            ";;" +
            (each.pfcDetailD40 != 0 ? "40" : "-") +
            ";;" +
            (each.pfcDetailD45 != 0 ? "45" : "-") +
            ";;" +
            (each.pfcDetailH20 != 0 ? "H20" : "-") +
            ";;" +
            (each.pfcDetailH40 != 0 ? "H40" : "-") +
            ";;" +
            (each.pfcDetailH45 != 0 ? "H45" : "-") +
            ";;" +
            each.pfcDetailCurrency +
            ";;" +
            each.pfcDetailPlaceOfPayment +
            ";;" +
            each.pfcDetailPayer +
            ";;" +
            each.pfcDetailPC +
            ";;" +
            (each.pfcDetailOogOH +
              each.pfcDetailOogOLB +
              each.pfcDetailOogOLF +
              each.pfcDetailOogOWL +
              each.pfcDetailOogOWR) +
            ";;" +
            each.pfcDetailOogDoorOpen +
            ";;" +
            (each.pfcDetailOogUcH +
              each.pfcDetailOogUcL +
              each.pfcDetailOogUcW) +
            ";;" +
            each.pfcDetailKillSlot;
        });
      }
    });
  }

  initTab2() {
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";

    this.currentActiveTab = "collect";
  }

  //used when there is change is place of receipt
  changePlaceOfReceipt(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPlaceOfReceiptCode = event["locationCode"];

      //set terminal URL
      this.cbPORTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );

      //Auto set Port of Load and Port of Load Terminal
      this.model.pfcLoadPortCode = event["locationCode"];
      this.cbPortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbPortOfLoad.setValue(event["locationCode"]);
      this.cbPOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcPlaceOfReceiptCode = "";
      this.cbPORTerminal.setUrl("");
    }
  }

  changePortOfLoad(event) {
    //upon changes update the terminal
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcLoadPortCode = event["locationCode"];
      this.cbPOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcLoadPortCode = "";
      this.cbPOLTerminal.setUrl("");
    }

    //revert to search by name
    this.cbPortOfLoad.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changePortOfDischarge(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcDischargePortCode = event["locationCode"];

      //set terminal URL
      this.cbPODTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );

      //Auto set Port of Load and Port of Load Terminal
      this.model.pfcPlaceOfDeliveryCode = event["locationCode"];
      this.cbPlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbPlaceOfDelivery.setValue(event["locationCode"]);
      this.cbPODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcDischargePortCode = "";
      this.cbPODLTerminal.setUrl("");
    }
  }

  changePlaceOfDelivery(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPlaceOfDeliveryCode = event["locationCode"];
      this.cbPODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcPlaceOfDeliveryCode = "";
      this.cbPODLTerminal.setUrl("");
    }
    this.cbPlaceOfDelivery.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changePortOfTransshipment(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPortOfTransshipment1Code = event["locationCode"];
      this.cbPOT1Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcPortOfTransshipment1Code = "";
      this.cbPOT1Terminal.setUrl("");
    }
  }

  changeFinalDestination(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcFinalDestinationCode = event["locationCode"];
      this.cbFDESTTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.pfcFinalDestinationCode = "";
      this.cbFDESTTerminal.setUrl("");
    }
  }

  changePORTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPORTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPORTerminalCode = "";
    }
  }

  changePODLTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPODLTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPODLTerminalCode = "";
    }
  }

  changePOT1Terminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPOT1TerminalCode = event["locationCode"];
    } else {
      this.model.pfcPOT1TerminalCode = "";
    }
  }

  changePOLTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPOLTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPOLTerminalCode = "";
    }
  }

  changePODTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPODTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPODTerminalCode = "";
    }
  }

  changeFDESTTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcFDESTTerminalCode = event["locationCode"];
    } else {
      this.model.pfcFDESTTerminalCode = "";
    }
  }

  //used when there is change in port of transshipment
  handleCreate() {
    // this.model["error-pfcFreightPartyIdInvalid"] = "";
    // this.validatorRules = {};
    // this.isError = false;

    // if (this.isFreightPartyValidBasedOnCustomerType == 'N') {
    //   this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party";
    //   this.isError = true;
    // } else if(this.isFreightPartyValidAsRecordLevel == 'N'){
    //   this.model["error-pfcFreightPartyIdInvalid"] = "Please select a valid Freight Party (record level)";
    //   this.isError = true;
    // }else{
    //   this.model["error-pfcFreightPartyIdInvalid"] = "";
    //   this.validatorRules = {};
    //   this.isError = false;
    // }


    console.log(this.model["error-pfcFreightPartyIdInvalid"]);
    console.log("this.model.pfcFreightPartyId : "+this.model.pfcFreightPartyId);
    console.log("this.searchFpIsValid : "+this.searchFpIsValid);
    console.log("this.searchFpIsFreightParty : "+this.searchFpIsFreightParty);

    this.isError = false;
    this.validatorRules = {};
    this.model["error-pfcFreightPartyId"] = "";
    this.model["error-pfcShipperId"] = "";
    this.model["error-pfcBookingPartyId"] = "";

    if (this.model.pfcFreightPartyId.length > 0 && this.searchFpIsValid == "N") {
      this.model["error-pfcFreightPartyId"] = "Freight Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.pfcFreightPartyId.length > 0 && this.searchFpIsFreightParty == "N") {
      this.model["error-pfcFreightPartyId"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
      this.isError = true;
    }

    if (this.model.pfcShipperId.length > 0 && this.searchShIsValid == "N") {
      this.model["error-pfcShipperId"] = "Shipper is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.pfcShipperId.length > 0 && this.searchShIsShipper == "N") {
      this.model["error-pfcShipperId"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
      this.isError = true;
    }

    if (this.model.pfcBookingPartyId.length > 0 && this.searchBkIsValid == "N") {
      this.model["error-pfcBookingPartyId"] = "Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.pfcBookingPartyId.length > 0 && this.searchBkIsBookingParty == "N") {
      this.model["error-pfcBookingPartyId"] = "Customer record is no longer set as a Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    }

    if (this.isError) {
      window.scrollTo(0, 0);
      return ;
    } else {
      this.isNew = true;
      this.model.pfcIsDeleted = "N";
      this.appStatusLabel = "";
      this.model.pfcEffectiveFrom = this.dateEffectiveFrom.getValue();
      this.model.pfcEffectiveTo = this.dateEffectiveTo.getValue();
      this.cookieService.setCookie("pfcSearchParam", JSON.stringify(this.model));
      this.model.pfcRecordId = "";

      this.lock = false;
      this.modeForm = "freightMaintenance";
      this.pfcToolbar.label = "Contract Rate";
      this.invisibleToolbarButtons = "retrieve,create,copy";
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "delete,print";
      }
      this.map = new Map(); // reset the map upon create

      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);

      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();

      this.cbAcctPic.clearSelect();
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");

      // on create, must keep set the record as valid (Celeste request in whatsapp gorup 25/02/2019
      this.model.pfcIsValid = "Y";

      let currentDate = new Date();

      if (
        this.dateEffectiveFrom.getValue() != null &&
        this.dateEffectiveFrom.getValue() != "" &&
        this.dateEffectiveFrom.getValue() != undefined
      ) {
        //this.dateMaintenanceEffectiveFrom.setValue(this.dateEffectiveFrom.getValue());
        this.dateMaintenanceEffectiveFrom2.setValue(
          this.dateEffectiveFrom.getValue()
        );
      } else {
        //this.dateMaintenanceEffectiveFrom.setValue(this.genericUtil.pad2(currentDate.getFullYear()) + '-' + this.genericUtil.pad2(currentDate.getMonth() + 1) + '-' + this.genericUtil.pad2(currentDate.getDate()));
        this.dateMaintenanceEffectiveFrom2.setValue(
          this.genericUtil.pad2(currentDate.getFullYear()) +
          "-" +
          this.genericUtil.pad2(currentDate.getMonth() + 1) +
          "-" +
          this.genericUtil.pad2(currentDate.getDate())
        );
      }

      // console.log('this.model.pfcContainerType  --> ' + this.model.pfcContainerType );
      if (this.model.pfcContainerType == "ALL") {
        this.model.pfcContainerType = "STD";
      }

      this.model.pfcContainerOwnershipPrefix = "C";

      if (this.model.pfcDgClass == "ALL") {
        this.model.pfcDgClass = "NA";
      }

      if (this.model.pfcIsValid == "ALL") {
        this.model.pfcIsValid = "Y";
      }

      if (this.model.pfcContainerOwnership == "ALL") {
        this.model.pfcContainerOwnership = "COC";
      }

      if (this.model.pfcContainerStatus == "ALL") {
        this.model.pfcContainerStatus = "FULL";
        this.model.pfcWeightClass = "M";
      }

      if (this.model.pfcContainerStatus == "EMPTY") {
        this.model.pfcWeightClass = "N";
      }

      if (this.model.pfcReceiptTypeCode == "ALL") {
        this.model.pfcReceiptTypeCode = "CY";
      }

      if (this.model.pfcDeliveryTypeCode == "ALL") {
        this.model.pfcDeliveryTypeCode = "CY";
      }

      if (this.model.pfcShipmentType == "ALL") {
        this.model.pfcShipmentType = "N";
      }

      this.changeEventPfcContainerOwnershipProcess(
        this.model.pfcContainerOwnership
      );

      //Auto set the field based on field on Flag Status
      //1. Shipper
     /* if (
        this.model.pfcShipperId != null &&
        this.model.pfcShipperId != "" &&
        this.model.pfcShipperId != undefined
      ) {
        this.cbMaintenanceShipper.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlShipper/customerId={query}"
        );
        this.cbMaintenanceShipper.setValue(this.model.pfcFreightPartyId);
      } else {
        this.cbMaintenanceShipper.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlShipper/customerName={query}"
        );
        this.cbMaintenanceShipper.setValue("");
      }*/

      //2. Auto set Place of Receipt
      if (
        this.model.pfcPlaceOfReceiptCode != null &&
        this.model.pfcPlaceOfReceiptCode != "" &&
        this.model.pfcPlaceOfReceiptCode != undefined
      ) {
        this.cbMaintenancePlaceOfReceipt.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfReceipt.setValue(
          this.model.pfcPlaceOfReceiptCode
        );
      } else {
        this.cbMaintenancePlaceOfReceipt.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
        );
        this.cbMaintenancePlaceOfReceipt.setValue("");
      }

      //3. Auto set port of Loading
      if (
        this.model.pfcLoadPortCode != null &&
        this.model.pfcLoadPortCode != "" &&
        this.model.pfcLoadPortCode != undefined
      ) {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfLoad.setValue(this.model.pfcLoadPortCode);
      } else {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
        );
        this.cbMaintenancePortOfLoad.setValue("");
      }

      //4. Auto set port of Discharge
      if (
        this.model.pfcDischargePortCode != null &&
        this.model.pfcDischargePortCode != "" &&
        this.model.pfcDischargePortCode != undefined
      ) {
        this.cbMaintenancePortOfDischarge.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfDischarge.setValue(
          this.model.pfcDischargePortCode
        );
      } else {
        this.cbMaintenancePortOfDischarge.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
        );
        this.cbMaintenancePortOfDischarge.setValue("");
      }

      //5. Auto set Place of Delivery
      if (
        this.model.pfcPlaceOfDeliveryCode != null &&
        this.model.pfcPlaceOfDeliveryCode != "" &&
        this.model.pfcPlaceOfDeliveryCode != undefined
      ) {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue(
          this.model.pfcPlaceOfDeliveryCode
        );
      } else {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue("");
      }

      //6. Auto set Port of Transshipment 1
      if (
        this.model.pfcPortOfTransshipment1Code != null &&
        this.model.pfcPortOfTransshipment1Code != "" &&
        this.model.pfcPortOfTransshipment1Code != undefined
      ) {
        this.cbMaintenancePOT1.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT1.setValue(this.model.pfcPortOfTransshipment1Code);
      } else {
        this.cbMaintenancePOT1.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
        );
        this.cbMaintenancePOT1.setValue("");
      }

      //7. Auto set Port of Transshipment 2
      if (
        this.model.pfcPortOfTransshipment2Code != null &&
        this.model.pfcPortOfTransshipment2Code != "" &&
        this.model.pfcPortOfTransshipment2Code != undefined
      ) {
        this.cbMaintenancePOT2.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT2.setValue(this.model.pfcPortOfTransshipment2Code);
      } else {
        this.cbMaintenancePOT2.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
        );
        this.cbMaintenancePOT2.setValue("");
      }

      //8. Auto set Port of Transshipment 3
      if (
        this.model.pfcPortOfTransshipment3Code != null &&
        this.model.pfcPortOfTransshipment3Code != "" &&
        this.model.pfcPortOfTransshipment3Code != undefined
      ) {
        this.cbMaintenancePOT3.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT3.setValue(this.model.pfcPortOfTransshipment3Code);
      } else {
        this.cbMaintenancePOT3.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
        );
        this.cbMaintenancePOT3.setValue("");
      }

      //9. Auto set Final Destination
      if (
        this.model.pfcFinalDestinationCode != null &&
        this.model.pfcFinalDestinationCode != "" &&
        this.model.pfcFinalDestinationCode != undefined
      ) {
        this.cbMaintenanceFinalDestination.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceFinalDestination.setValue(
          this.model.pfcFinalDestinationCode
        );
      } else {
        this.cbMaintenanceFinalDestination.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
        );
        this.cbMaintenanceFinalDestination.setValue("");
      }

      //10. Auto set Freight Party
      if (
        this.model.pfcFreightPartyId != null &&
        this.model.pfcFreightPartyId != "" &&
        this.model.pfcFreightPartyId != undefined
      ) {
        this.cbMaintenanceFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerId={query}"
        );
        this.cbMaintenanceFreightParty.setValue(this.model.pfcFreightPartyId);
        this.dateCreatedForCbPayer = "*";
      } else {
        this.cbMaintenanceFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerName={query}"
        );
        this.cbMaintenanceFreightParty.setValue("");
        this.dateCreatedForCbPayer = "*";
      }

      //11. Expiry On
      if (
        this.dateEffectiveTo.getValue() != null &&
        this.dateEffectiveTo.getValue() != "" &&
        this.dateEffectiveTo.getValue() != undefined
      ) {
        //this.dateMaintenanceEffectiveTo.setValue(this.dateEffectiveTo.getValue());
        this.dateMaintenanceEffectiveTo2.setValue(
          this.dateEffectiveTo.getValue()
        );
      } else {
        //this.dateMaintenanceEffectiveTo.setValue('');
        this.dateMaintenanceEffectiveTo2.reset();
      }

      //12. Receipt Type and Delivery Type
      if (
        this.model.pfcReceiptTypeCode != null &&
        this.model.pfcReceiptTypeCode != "" &&
        this.model.pfcReceiptTypeCode != undefined
      ) {
        this.cbMaintenanceReceiptType.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
        );
        this.cbMaintenanceReceiptType.setValue(this.model.pfcReceiptTypeCode);
      } else {
        this.cbMaintenanceReceiptType.setValue("");
      }

      if (
        this.model.pfcDeliveryTypeCode != null &&
        this.model.pfcDeliveryTypeCode != "" &&
        this.model.pfcDeliveryTypeCode != undefined
      ) {
        this.cbMaintenanceDeliveryType.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
        );
        this.cbMaintenanceDeliveryType.setValue(this.model.pfcDeliveryTypeCode);
      } else {
        this.cbMaintenanceDeliveryType.setValue("");
      }

      //13. Auto Adhoc
      var self = this;
      if (
        this.model.pfcVesselId != null &&
        this.model.pfcVesselId != "" &&
        this.model.pfcVesselId != undefined
      ) {
        this.cbMaintenanceAdhocVessel.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/VesselId={query}"
        );
        this.cbMaintenanceAdhocVessel.setValue(
          this.model.pfcVesselId,
          function (callbackMaintenanceAdhoc) {
            self.cbMaintenanceAdhocVoyage.setUrl(
              self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              self.model.pfcVesselId +
              "/" +
              "O" +
              "/{query}/*"
            );
            if (
              self.model.pfcVoyage != null &&
              self.model.pfcVoyage != "" &&
              self.model.pfcVoyage != undefined
            ) {
              self.cbMaintenanceAdhocVoyage.setValue(self.model.pfcVoyage);
            }
          }
        );
      } else {
        this.cbMaintenanceAdhocVessel.setValue("");
        this.cbMaintenanceAdhocVoyage.setValue("");
      }

      //14. Auto set Shipper
    if (
      this.model.pfcShipperId != null &&
      this.model.pfcShipperId != "" &&
      this.model.pfcShipperId != undefined
    ) {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerId={query}"
      );
      this.cbMaintenanceShipper.setValue(this.model.pfcShipperId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerName={query}"
      );
      this.cbMaintenanceShipper.setValue("");
      this.dateCreatedForCbPayer = "*";
    }

     //14. Auto set Booking Party
     if (
      this.model.pfcBookingPartyId != null &&
      this.model.pfcBookingPartyId != "" &&
      this.model.pfcBookingPartyId != undefined
    ) {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerId={query}"
      );
      this.cbMaintenanceBookingParty.setValue(this.model.pfcBookingPartyId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerName={query}"
      );
      this.cbMaintenanceBookingParty.setValue("");
      this.dateCreatedForCbPayer = "*";
    }

      //reset created by and date information
      this.model.pfcDateCreated = "";
      this.model.pfcUserCreated = "";
      this.model.pfcDateModified = "";
      this.model.pfcUserModified = "";
      this.dateCreatedForCbPayer = "*";
      this.custIdForCbPayer = "*";
      this.apiEffectiveTo = "";
      this.clickSurcharge = false;
      //user id di ambil dari getName()
      // if (this.model.pfcUserCreated == "") {
      //   this.model.pfcUserId = this.cookieService.getName();
      // }

      this.model.pfcUserId = this.cookieService.getName().toUpperCase();

      // console.log(this.model);

      this.initTab1();

      window.scrollTo(0, 0);
    }
  }

  handleCancel() {
    var self = this;
    if (this.modeForm == "freightFlagStatus") {
      this.pfcToolbar.label = "Contract Rate Flag Status";
      this.model = new FreightManagement();

      this.cbFreightParty.setValue("");
      this.cbBookingParty.setValue("");
      this.cbShipper.setValue("");
      this.cbPlaceOfReceipt.setValue("");
      this.cbPortOfLoad.setValue("");
      this.cbPortOfDischarge.setValue("");
      this.cbPlaceOfDelivery.setValue("");
      this.cbPortOfTransshipment.setValue("");
      this.cbFinalDestination.setValue("");
      this.cbPortOfDischarge.setValue("");

      this.cbContainerStatus.clearSelect();
      this.cbContainerOwnership.clearSelect();
      this.cbDGClass.clearSelect();
      this.cbContainerType.clearSelect();
      this.cbValidInvalid.clearSelect();
      this.cbReceiptType.clearSelect();
      this.cbDeliveryType.clearSelect();
      this.cbShipmentType.clearSelect();

      //GOS-766 Contract rate Do not refresh data under [Terminal] tab after clicking "Cancel"
      this.cbPORTerminal.clearSelect();
      this.cbPODLTerminal.clearSelect();
      this.cbPOLTerminal.clearSelect();
      this.cbPOT1Terminal.clearSelect();
      this.cbPODTerminal.clearSelect();
      this.cbFDESTTerminal.clearSelect();

      this.fpAcctCode = "";
      this.fpPayer = "";
      this.isFreightPartyValidBasedOnCustomerType='';
      this.isFreightPartyValidAsRecordLevel='';
      this.dateModifiedFreigthParty='';
      this.dateModifiedShipper='';
      this.dateModifiedBookingParty='';
      this.isModeArchive = false;
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
      this.model["error-pfcFreightPartyId"] = "";
      this.model["error-pfcShipperId"] = "";
      this.model["error-pfcBookingPartyId"] = "";
      this.isError = false;
      this.dateCreatedForCbPayer = "*";
      this.custIdForCbPayer = "*";
      this.listPayerExisting = [];
      this.groupAllowInvalidPayer = [];
      this.apiEffectiveTo = "";
      this.revisionPfc = "";
      this.clickSurcharge = false;
      setTimeout(() => {
        self.cbContainerStatus.setValue("ALL");
        self.cbContainerOwnership.setValue("ALL");
        self.cbDGClass.setValue("ALL");
        self.cbContainerType.setValue("ALL");
        self.cbValidInvalid.setValue("ALL");
        self.cbReceiptType.setValue("CY");
        self.cbDeliveryType.setValue("CY");
        self.model.pfcReceiptTypeCode = "CY";
        self.model.pfcDeliveryTypeCode = "CY";
        self.model.pfcIsCommission = "N";
        self.model.pfcIsDeleted = "N";
        self.model.pfcAdhocFilter = "N";
        self.cbShipmentType.setValue("ALL");
        self.cbAdhocVessel.setValue("");
        self.cbAdhocVoyage.setValue("");
        self.cbAdhocVoyage.setUrl("");
      }, 10);

      //this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');

      //this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');

      this.dateEffectiveFrom.setValue("");
      this.dateEffectiveTo.setValue("");
      this.checkLock = false;
      this.lockEffectiveFrom = false;
      this.lockEffectiveTo = false;
      this.isError = false;
      this.isErrorFlag = false;
      this.isWarningFlag = false;

      this.mainGrid.curPage = 1;
      this.mainGrid.currentPage = 1;
      this.calculateTotal();
      this.mainGrid.onClear();
      window.scrollTo(0, 0);
    } else if (this.modeForm == "freightMaintenance") {
      //reset the model
      this.model = new FreightManagement();
      this.modelDetail = new FreightDetail();
      this.map = new Map();

      //reset all combo and date picker
      this.cbMaintenanceFreightParty.setValue("");
      this.cbMaintenanceShipper.setValue("");
      this.cbMaintenanceBookingParty.setValue("");
      this.cbMaintenancePlaceOfReceipt.setValue("");
      this.cbMaintenancePortOfLoad.setValue("");
      this.cbMaintenancePortOfDischarge.setValue("");
      this.cbMaintenancePlaceOfDelivery.setValue("");
      this.cbMaintenancePOT1.setValue("");
      this.cbMaintenancePOT2.setValue("");
      this.cbMaintenancePOT3.setValue("");
      this.cbMaintenanceFinalDestination.setValue("");
      this.cbMaintenanceCommodity.setValue("");
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");

      this.clearTerminalTabSurchargeMaintenance();
      this.maintenanceGeneralTab.nativeElement.click();

      this.cbMaintenanceReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
      );
      this.cbMaintenanceReceiptType.setValue("CY");
      this.cbMaintenanceDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
      );
      this.cbMaintenanceDeliveryType.setValue("CY");
      //this.dateMaintenanceEffectiveTo.setValue('');
      this.dateMaintenanceEffectiveTo2.reset();
      this.cbAcctPic.clearSelect();

      //reset effective to
      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());
      //this.dateMaintenanceEffectiveFrom.setValue(theDate);
      this.dateMaintenanceEffectiveFrom2.setValue(theDate);

      //reset container type, status, ownership
      this.model.pfcContainerOwnership = "COC";
      this.changeEventPfcContainerOwnershipProcess(
        this.model.pfcContainerOwnership
      );

      this.model.pfcContainerStatus = "FULL";
      this.model.pfcWeightClass = "M";
      this.model.pfcContainerType = "STD";
      this.model.pfcDgClass = "NA";
      this.model.pfcShipmentType = "N";

      this.invisibleToolbarButtons = "retrieve,create,copy";
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "delete,print";
      }

      this.dateMaintenanceEffectiveFrom2.setDisable(false);
      this.dateMaintenanceEffectiveTo2.setDisable(false);

      //reset grid
      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();

      this.isError = false;
      this.isErrorFlag = false;
      this.isErrorDetail = false;
      this.lock = false;
      this.loading = false;
      this.isNew = false;
      this.isWarningFlag = false;

      this.isFAIErrorPrepaid = false;
      this.isFAIErrorCollect = false;
      this.messagesFAIErrorPrepaid = "";
      this.messagesFAIErrorCollect = "";

      this.fpAcctCode = "";
      this.fpPayer = "";
      this.isFreightPartyValidBasedOnCustomerType='';
      this.isFreightPartyValidAsRecordLevel='';
      this.dateModifiedFreigthParty='';
      this.dateModifiedShipper='';
      this.dateModifiedBookingParty='';
      this.isModeArchive = false;
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
      this.model["error-pfcFreightPartyId"] = "";
      this.model["error-pfcShipperId"] = "";
      this.model["error-pfcBookingPartyId"] = "";
      this.dateCreatedForCbPayer = "*";
      this.custIdForCbPayer = "*";
      this.listPayerExisting = [];
      this.groupAllowInvalidPayer = [];

      this.calculateTotal();
      this.genericUtil.hideLoader();
      window.scrollTo(0, 0);
    }
  }

  clearTerminalTabSurchargeMaintenance(){
    this.cbMaintenancePODTerminal.clearSelect();
    this.cbMaintenancePORTerminal.clearSelect();
    this.cbMaintenancePODLTerminal.clearSelect();
    this.cbMaintenancePOLTerminal.clearSelect();
    this.cbMaintenancePOT1Terminal.clearSelect();
    this.cbMaintenanceFDESTTerminal.clearSelect();
  }

  afterClosedFromMaintenance() {
    //reset grid
    this.gridApiPrepaid.setRowData([]);
    this.gridApiCollect.setRowData([]);
    this.calculateTotal();

    //this.gridPrepaid.onClear();
    //this.gridCollect.onClear();

    this.modeForm = "freightFlagStatus";
    this.pfcToolbar.label = "Contract Rate Flag Status";

    this.model = new FreightManagement();

    this.fpAcctCode = "";
    this.fpPayer = "";
    this.isFreightPartyValidBasedOnCustomerType='';
    this.isFreightPartyValidAsRecordLevel='';
    this.dateModifiedFreigthParty='';
    this.dateModifiedShipper='';
    this.dateModifiedBookingParty='';
    this.isModeArchive = false;
    this.searchFpIsFreightParty = "";
    this.searchFpIsValid = "";
    this.searchShIsShipper = "";
    this.searchShIsValid = "";
    this.searchBkIsBookingParty = "";
    this.searchBkIsValid = "";
    this.model["error-pfcFreightPartyId"] = "";
    this.model["error-pfcShipperId"] = "";
    this.model["error-pfcBookingPartyId"] = "";
    this.isError = false;
    this.dateCreatedForCbPayer = "*";
    this.custIdForCbPayer = "*";
    this.listPayerExisting = [];
    this.groupAllowInvalidPayer = [];
    this.apiEffectiveTo = "";
    this.revisionPfc = "";
    this.clickSurcharge = false;

    //cek cookies
    if (this.cookieService.getCookie("pfcSearchParam") != "") {
      let pfcSearchParam = JSON.parse(
        this.cookieService.getCookie("pfcSearchParam")
      );
      console.log('** pfcSearchParam **');
      console.log(pfcSearchParam);

      this.model.pfcSerchByRefNo = pfcSearchParam.pfcSerchByRefNo.trim();
      this.model.pfcRecordId = pfcSearchParam.pfcRecordId;
      this.model.pfcAppStatus = pfcSearchParam.pfcAppStatus;
      this.model.pfcIsDeleted = pfcSearchParam.pfcIsDeleted;
      this.model.pfcIsCommission = pfcSearchParam.pfcIsCommission;

      //01. Freight Party
      if (
        pfcSearchParam.pfcFreightPartyId != null &&
        pfcSearchParam.pfcFreightPartyId != "" &&
        pfcSearchParam.pfcFreightPartyId != undefined
      ) {
        this.cbFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
            "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"
        );
        //ininih
        var self = this;
        this.cbFreightParty.setValue(
          pfcSearchParam.pfcFreightPartyId,
          function (callbackFreightParty) {
            self.model.pfcFreightPartyId = pfcSearchParam.pfcFreightPartyId;
            self.searchFpIsFreightParty = callbackFreightParty.isFreightParty;
            self.searchFpIsValid = callbackFreightParty.isValid;
            self.cbFreightParty.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerId={query}"+
                self.sCharUtil.htmlEncode("|")+
                "status=E"
            );
          }
        );
      } else {
        this.model.pfcFreightPartyId = "";
        this.cbFreightParty.setValue("");
      }

      if (
        pfcSearchParam.pfcBookingPartyId != null &&
        pfcSearchParam.pfcBookingPartyId != "" &&
        pfcSearchParam.pfcBookingPartyId != undefined
      ) {
        this.cbBookingParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
            "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"
        );
        //ininih
        var self = this;
        this.cbBookingParty.setValue(
          pfcSearchParam.pfcBookingPartyId,
          function (callbackBookingParty) {
            self.model.pfcBookingPartyId = pfcSearchParam.pfcBookingPartyId;
            self.searchBkIsBookingParty = callbackBookingParty.isBookingParty;
            self.searchBkIsValid = callbackBookingParty.isValid;
            self.cbBookingParty.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerId={query}"+
                self.sCharUtil.htmlEncode("|")+
                "status=E"
            );
          }
        );
      } else {
        this.model.pfcBookingPartyId = "";
        this.cbBookingParty.setValue("");
      }

      if (
        pfcSearchParam.pfcShipperId != null &&
        pfcSearchParam.pfcShipperId != "" &&
        pfcSearchParam.pfcShipperId != undefined
      ) {
        this.cbShipper.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
            "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"
        );
        //ininih
        var self = this;
        this.cbShipper.setValue(
          pfcSearchParam.pfcShipperId,
          function (callbackShipper) {
            self.model.pfcShipperId = pfcSearchParam.pfcShipperId;
            self.searchShIsShipper = callbackShipper.isShipper;
            self.searchShIsValid = callbackShipper.isValid;
            self.cbShipper.setUrl(
              self.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerId={query}"+
                self.sCharUtil.htmlEncode("|")+
                "status=E"
            );
          }
        );
      } else {
        this.model.pfcShipperId = "";
        this.cbShipper.setValue("");
      }

      //02. Place of Receipt
      if (
        pfcSearchParam.pfcPlaceOfReceiptCode != null &&
        pfcSearchParam.pfcPlaceOfReceiptCode != "" &&
        pfcSearchParam.pfcPlaceOfReceiptCode != undefined
      ) {
        this.cbPlaceOfReceipt.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbPlaceOfReceipt.setValue(
          pfcSearchParam.pfcPlaceOfReceiptCode,
          function (callbackPOR) {
            self.model.pfcPlaceOfReceiptCode =
              pfcSearchParam.pfcPlaceOfReceiptCode;
            self.cbPlaceOfReceipt.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcPlaceOfReceiptCode = "";
        this.cbPlaceOfReceipt.setValue("");
      }

      //03. Port of Loading
      if (
        pfcSearchParam.pfcLoadPortCode != null &&
        pfcSearchParam.pfcLoadPortCode != "" &&
        pfcSearchParam.pfcLoadPortCode != undefined
      ) {
        this.cbPortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbPortOfLoad.setValue(
          pfcSearchParam.pfcLoadPortCode,
          function (callbackPOL) {
            self.model.pfcLoadPortCode = pfcSearchParam.pfcLoadPortCode;
            self.cbPortOfLoad.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcLoadPortCode = "";
        this.cbPortOfLoad.setValue("");
      }

      //04. Discharge port Code
      if (
        pfcSearchParam.pfcDischargePortCode != null &&
        pfcSearchParam.pfcDischargePortCode != "" &&
        pfcSearchParam.pfcDischargePortCode != undefined
      ) {
        this.cbPortOfDischarge.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbPortOfDischarge.setValue(
          pfcSearchParam.pfcDischargePortCode,
          function (callbackDischarge) {
            self.model.pfcDischargePortCode =
              pfcSearchParam.pfcDischargePortCode;
            self.cbPortOfDischarge.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcDischargePortCode = "";
        this.cbPortOfDischarge.setValue("");
      }

      //05. Place of Delivery
      if (
        pfcSearchParam.pfcPlaceOfDeliveryCode != null &&
        pfcSearchParam.pfcPlaceOfDeliveryCode != "" &&
        pfcSearchParam.pfcPlaceOfDeliveryCode != undefined
      ) {
        this.cbPlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbPlaceOfDelivery.setValue(
          pfcSearchParam.pfcPlaceOfDeliveryCode,
          function (callbackPOD) {
            self.model.pfcPlaceOfDeliveryCode =
              pfcSearchParam.pfcPlaceOfDeliveryCode;
            self.cbPlaceOfDelivery.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcPlaceOfDeliveryCode = "";
        this.cbPlaceOfDelivery.setValue("");
      }

      //06. Final Destination
      if (
        pfcSearchParam.pfcFinalDestinationCode != null &&
        pfcSearchParam.pfcFinalDestinationCode != "" &&
        pfcSearchParam.pfcFinalDestinationCode != undefined
      ) {
        this.cbFinalDestination.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbFinalDestination.setValue(
          pfcSearchParam.pfcFinalDestinationCode,
          function (callbackFDest) {
            self.model.pfcFinalDestinationCode =
              pfcSearchParam.pfcFinalDestinationCode;
            self.cbFinalDestination.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcFinalDestinationCode = "";
        this.cbFinalDestination.setValue("");
      }

      //07. Port of Transshipment1
      if (
        pfcSearchParam.pfcPortOfTransshipment1Code != null &&
        pfcSearchParam.pfcPortOfTransshipment1Code != "" &&
        pfcSearchParam.pfcPortOfTransshipment1Code != undefined
      ) {
        this.cbPortOfTransshipment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        var self = this;
        this.cbPortOfTransshipment.setValue(
          pfcSearchParam.pfcPortOfTransshipment1Code,
          function (callbackPOT1) {
            self.model.pfcPortOfTransshipment1Code =
              pfcSearchParam.pfcPortOfTransshipment1Code;
            self.cbPortOfTransshipment.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationName={query}"
            );
          }
        );
      } else {
        this.model.pfcPortOfTransshipment1Code = "";
        this.cbPortOfTransshipment.setValue("");
      }

      //08. Receipt Type & Delivery Type
      if (
        pfcSearchParam.pfcReceiptTypeCode != null &&
        pfcSearchParam.pfcReceiptTypeCode != "" &&
        pfcSearchParam.pfcReceiptTypeCode != undefined &&
        pfcSearchParam.pfcReceiptTypeCode != "ALL"
      ) {
        this.cbReceiptType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
        );
        this.cbReceiptType.setValue(pfcSearchParam.pfcReceiptTypeCode);
        this.model.pfcReceiptTypeCode = pfcSearchParam.pfcReceiptTypeCode;
      } else {
        this.model.pfcReceiptTypeCode = "";
        this.cbReceiptType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
        );
        this.cbReceiptType.setValue("CY");
      }
      if (
        pfcSearchParam.pfcDeliveryTypeCode != null &&
        pfcSearchParam.pfcDeliveryTypeCode != "" &&
        pfcSearchParam.pfcDeliveryTypeCode != undefined &&
        pfcSearchParam.pfcDeliveryTypeCode != "ALL"
      ) {
        this.cbDeliveryType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
        );
        this.cbDeliveryType.setValue(pfcSearchParam.pfcDeliveryTypeCode);
        this.model.pfcDeliveryTypeCode = pfcSearchParam.pfcDeliveryTypeCode;
      } else {
        this.model.pfcDeliveryTypeCode = "";
        this.cbDeliveryType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
        );
        this.cbDeliveryType.setValue("CY");
      }

      //09. Container Ownership
      if (
        pfcSearchParam.pfcContainerOwnership != null &&
        pfcSearchParam.pfcContainerOwnership != "" &&
        pfcSearchParam.pfcContainerOwnership != undefined
      ) {
        this.cbContainerOwnership.setValue(
          pfcSearchParam.pfcContainerOwnership
        );
        this.model.pfcContainerOwnership = pfcSearchParam.pfcContainerOwnership;
      } else {
        this.model.pfcContainerOwnership = "ALL";
        this.cbContainerOwnership.setValue("ALL");
      }

      //09.1. Additional Shipment Type
      if (
        pfcSearchParam.pfcShipmentType != null &&
        pfcSearchParam.pfcShipmentType != "" &&
        pfcSearchParam.pfcShipmentType != undefined
      ) {
        switch (pfcSearchParam.pfcShipmentType) {
          case "N":
            this.cbShipmentType.setValue("N/A");
            break;
          case "I":
            this.cbShipmentType.setValue("IRE");
            break;
          case "R":
            this.cbShipmentType.setValue("ROB");
            break;
          case "T":
            this.cbShipmentType.setValue("THRU");
            break;
          case "D":
            this.cbShipmentType.setValue("RD-TRIP");
            break;
          case "S":
            this.cbShipmentType.setValue("SPECIAL");
            break;
          case "C":
            this.cbShipmentType.setValue("CRB");
            break;
          default:
            break;
        }
        this.model.pfcShipmentType = pfcSearchParam.pfcShipmentType;
      } else {
        this.model.pfcShipmentType = "ALL";
        this.cbShipmentType.setValue("ALL");
      }

      //09.2. Additional for Adhoc Vessel /Voyage
      var self = this;
      if (
        pfcSearchParam.pfcVesselId != null &&
        pfcSearchParam.pfcVesselId != "" &&
        pfcSearchParam.pfcVesselId != undefined
      ) {
        this.model.pfcVesselId = pfcSearchParam.pfcVesselId;
        this.cbAdhocVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterVessels/findByComboBoxControl/VesselId={query}"
        );
        this.cbAdhocVessel.setValue(
          pfcSearchParam.pfcVesselId,
          function (callbackAdhoc) {
            self.cbAdhocVoyage.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/distinctVoyage/" +
                self.model.pfcVesselId +
                "/" +
                "O" +
                "/{query}/*"
            );
            self.cbAdhocVoyage.setValue(pfcSearchParam.pfcVoyage);
            self.model.pfcVoyage = pfcSearchParam.pfcVoyage;
          }
        );
      } else {
        this.cbAdhocVessel.setValue("");
        this.model.pfcVesselId = "";
      }

      //10. Container Status
      if (
        pfcSearchParam.pfcContainerStatus != null &&
        pfcSearchParam.pfcContainerStatus != "" &&
        pfcSearchParam.pfcContainerStatus != undefined
      ) {
        this.cbContainerStatus.setValue(pfcSearchParam.pfcContainerStatus);
        this.model.pfcContainerStatus = pfcSearchParam.pfcContainerStatus;
      } else {
        this.model.pfcContainerStatus = "ALL";
        this.cbContainerStatus.setValue("ALL");
      }

      //11. Container Type
      if (
        pfcSearchParam.pfcContainerType != null &&
        pfcSearchParam.pfcContainerType != "" &&
        pfcSearchParam.pfcContainerType != undefined
      ) {
        switch (pfcSearchParam.pfcContainerType) {
          case "STD":
            this.cbContainerType.setValue("NORMAL");
            break;
          case "DRY":
            this.cbContainerType.setValue("REEFER DRY");
            break;
          case "REF":
            this.cbContainerType.setValue("REEFER");
            break;
          case "FLR":
            this.cbContainerType.setValue("FLATRACK");
            break;
          case "OPT":
            this.cbContainerType.setValue("OPEN TOP");
            break;
          case "PLT":
            this.cbContainerType.setValue("PLATFORM");
            break;
          case "TNK":
            this.cbContainerType.setValue("TANK");
            break;
          case "UC":
            this.cbContainerType.setValue("UC");
            break;

          default:
            break;
        }
        this.model.pfcContainerType = pfcSearchParam.pfcContainerType;
      } else {
        this.model.pfcContainerType = "ALL";
        this.cbContainerType.setValue("ALL");
      }

      //12. DG Class
      if (
        pfcSearchParam.pfcDgClass != null &&
        pfcSearchParam.pfcDgClass != "" &&
        pfcSearchParam.pfcDgClass != undefined
      ) {
        this.cbDGClass.setValue(pfcSearchParam.pfcDgClass);
        this.model.pfcDgClass = pfcSearchParam.pfcDgClass;
      } else {
        this.model.pfcDgClass = "ALL";
        this.cbDGClass.setValue("ALL");
      }

      //13. Effective From
      // console.log('pfcSearchParam.pfcEffectiveFrom --> ' + pfcSearchParam.pfcEffectiveFrom);
      if (
        pfcSearchParam.pfcEffectiveFrom != null &&
        pfcSearchParam.pfcEffectiveFrom != "" &&
        pfcSearchParam.pfcEffectiveFrom != undefined
      ) {
        this.dateEffectiveFrom.setValue(pfcSearchParam.pfcEffectiveFrom);
      } else {
        this.model.pfcEffectiveFrom = "";
        this.dateEffectiveFrom.setValue("");
      }

      //14. Effective To
      // console.log('pfcSearchParam.pfcEffectiveTo --> ' + pfcSearchParam.pfcEffectiveTo);
      if (
        pfcSearchParam.pfcEffectiveTo != null &&
        pfcSearchParam.pfcEffectiveTo != "" &&
        pfcSearchParam.pfcEffectiveTo != undefined
      ) {
        this.dateEffectiveTo.setValue(pfcSearchParam.pfcEffectiveTo);
      } else {
        this.model.pfcEffectiveTo = "";
        this.dateEffectiveTo.setValue("");
      }

      // 15. Valid / Invalid
      if (
        pfcSearchParam.pfcIsValid != null &&
        pfcSearchParam.pfcIsValid != "" &&
        pfcSearchParam.pfcIsValid != undefined
      ) {
        this.model.pfcIsValid = pfcSearchParam.pfcIsValid;
      } else {
        this.model.pfcIsValid = "ALL";
      }
    }
    //end of check cookies

    this.invisibleToolbarButtons = "save,copy,nct,delete";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "nct,save,copy,update,delete,create";
    } else {
      this.disableToolbarButtons = "";
    }
    this.loading = false;
    this.isError = false;
    this.isErrorFlag = false;
    this.isNew = false;

    //reset combo
    this.cbReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
    );
    this.cbReceiptType.setValue(this.model.pfcReceiptTypeCode);
    this.cbDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
    );
    this.cbDeliveryType.setValue(this.model.pfcDeliveryTypeCode);

    this.isFAIErrorPrepaid = false;
    this.messagesFAIErrorPrepaid = "";
    this.isFAIErrorCollect = false;
    this.messagesFAIErrorCollect = "";
    this.revisionPfc = "";

    this.clearTerminalTabSurchargeMaintenance();
    this.maintenanceGeneralTab.nativeElement.click();

    //remove the cookie
    //this.cookieService.deleteCookie('pfcSearchByRefNo');
    //this.cookieService.deleteCookie('pfcRecordId');
  }

  handleClose() {
    this.isWarningFlag=false;
    if (this.modeForm == "freightMaintenance") {
      this.fpAcctCode = "";
      this.fpPayer = "";
      this.isFreightPartyValidBasedOnCustomerType='';
      this.isFreightPartyValidAsRecordLevel='';
      this.dateModifiedFreigthParty='';
      this.isModeArchive = false;

      this.message(
        "information",
        "Information",
        "Are you sure want to close this?",
        "yesno",
        {
          yes: "this.afterClosedFromMaintenance()",
          no: "",
        }
      );
    } else {
      this.router.navigateByUrl("/main/home");
    }
  }

  changeCbMaintenanceFreightParty(event) {
    // console.log('** changeCbMaintenanceFreightParty **');
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.pfcFreightPartyId = event["customerId"];
      this.model.pfcFreightPartyCountry = event["countryCode"]
        .toString()
        .toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";

      this.fpPayer = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpPayer += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpPayer += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpPayer += " SLOT: " + event["slotAccountCode"];
      }
      this.fpPayer += " >>";

      this.isFreightPartyValidBasedOnCustomerType=event["isFreightParty"];
      this.isFreightPartyValidAsRecordLevel=event['isValid'];
      // this.dateFreightRemoved=event['dateFreightRemoved'];
      this.dateModifiedFreigthParty=event['dateModified'];
      if(this.isFreightPartyValidBasedOnCustomerType=='N'){
        this.model["warning-pfcFreightPartyIdInvalid"] = "This Freight Party has been invalidated";//since " + this.dateFreightRemoved
        this.isWarningFlag = true;

      }else{
        this.isWarningFlag=false;
      }
    } else {
      this.isWarningFlag=false;
      this.model.pfcFreightPartyId = "";
      this.model.pfcFreightPartyCountry = "";
      this.fpAcctCode = "";
      this.dateFreightRemoved='';
    }

    //GOS-135
    //console.log('GOS-135 >> ' + event["dateFreightRemoved"] + ' ' + this.model.pfcDateCreated);
    //console.log(event["dateFreightRemoved"] > this.model.pfcDateCreated);

    //if(event["dateFreightRemoved"] > this.model.pfcDateCreated){
    //  console.log('GOS-135 >> Should allowed to edit')
    //}
    //End of GOS-135

    //revert the search url to use name
    // this.cbMaintenanceFreightParty.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
    // );

    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceFreightParty.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerName={query}"
      );
    } else {
      var uriString = "";
      if (this.model.pfcRecordId == null ||
        this.model.pfcRecordId == "" ||
        this.model.pfcRecordId == undefined) {
          uriString = this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerName={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=N";
      } else {
        uriString = this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerName={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"+
              this.sCharUtil.htmlEncode("|")+
              "recordId="+this.model.pfcRecordId;
      }
      this.cbMaintenanceFreightParty.setUrl(uriString);
    }
  }

  changeCbMaintenanceShipper(event) {
    // console.log('** changeCbMaintenanceShipper **');
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.pfcShipperId = event["customerId"];
      this.searchShIsShipper=event["isShipper"];
      this.searchShIsValid=event['isValid'];
      this.dateModifiedShipper=event['dateModified'];
    } else {
      this.model.pfcShipperId = "";
      this.searchShIsShipper="";
      this.searchShIsValid="";
      this.dateModifiedShipper="";
    }

    //revert the search url to use name
    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerName={query}"
      );
    } else {
      console.log("uriString");

      var uriString = "";
      if (this.model.pfcRecordId == null ||
        this.model.pfcRecordId == "" ||
        this.model.pfcRecordId == undefined) {
          uriString = this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=N";
      } else {
        uriString = this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"+
              this.sCharUtil.htmlEncode("|")+
              "recordId="+this.model.pfcRecordId;
      }
      console.log(uriString);
      this.cbMaintenanceShipper.setUrl(uriString);
    }
  }

  changeCbMaintenanceBookingParty(event) {
    // console.log("** changeCbMaintenanceShipper **");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.pfcBookingPartyId = event["customerId"];
      this.searchBkIsBookingParty=event["isBookingParty"];
      this.searchBkIsValid=event['isValid'];
      this.dateModifiedBookingParty=event['dateModified'];
    } else {
      this.model.pfcBookingPartyId = "";
      this.searchBkIsBookingParty="";
      this.searchBkIsValid="";
      this.dateModifiedBookingParty="";
    }

    //revert the search url to use name

    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerName={query}"
      );
    } else {

      var uriString = "";
      if (this.model.pfcRecordId == null ||
        this.model.pfcRecordId == "" ||
        this.model.pfcRecordId == undefined) {
          uriString = this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerName={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=N";
      } else {
        uriString = this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerName={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"+
              this.sCharUtil.htmlEncode("|")+
              "recordId="+this.model.pfcRecordId;
      }

      this.cbMaintenanceBookingParty.setUrl(uriString);
    }
  }

  changeCbMaintenancePlaceOfReceipt(event) {
    // console.log('** changeCbMaintenancePlaceOfReceipt **');
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.pfcPlaceOfReceiptCode = event["locationCode"];
      if (
        this.model.pfcLoadPortCode == null ||
        this.model.pfcLoadPortCode == "" ||
        this.model.pfcLoadPortCode == undefined
      ) {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfLoad.setValue(event["locationCode"]);
      }
      if (event.hasTerminal == "Y") {
        //GOS-762 Please assists to disable the POR, PODL and FEST field
        // this.lockPORTerminal = false;
      } else {
        this.lockPORTerminal = true;
      }
      this.cbMaintenancePORTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePORTerminal.setValue("");
      this.model.pfcPORTerminalCode = "";
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.pfcPOLTerminalCode = "";
    } else {
      this.model.pfcPlaceOfReceiptCode = "";
      this.cbMaintenancePORTerminal.setUrl("");
      this.model.pfcPORTerminalCode = "";
      this.cbMaintenancePORTerminal.setValue("");
      this.model.pfcPOLTerminalCode = "";
    }

    //revert the url to search by name
    this.cbMaintenancePlaceOfReceipt.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePortOfLoad(event) {
    // console.log('** changeCbMaintenancePortOfLoad **');
    console.log(event);

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.pfcLoadPortCode = event["locationCode"];
      this.polName = event["locationName"];

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPOLTerminal = false;
      } else {
        //lock the terminal back
        //GOS-762 users are allowed to input Terminal info under POL, POD and POT fields only.
        // this.lockPOLTerminal = true;
      }
      this.cbMaintenancePOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.pfcPolPrefix = event["locationBookingRef"];
    } else {
      this.model.pfcLoadPortCode = "";
      this.cbMaintenancePOLTerminal.setUrl("");
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.pfcPolPrefix = "";
      this.polName = "";
    }

    //revert the url to search by name
    this.cbMaintenancePortOfLoad.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePortOfDischarge(event) {
    // console.log('** changeCbMaintenancePortOfDischarge **');
    console.log(event);

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePortOfDischarge.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.pfcDischargePortCode = event["locationCode"];
      this.podName = event["locationName"];

      if (
        this.model.pfcPlaceOfDeliveryCode == null ||
        this.model.pfcPlaceOfDeliveryCode == "" ||
        this.model.pfcPlaceOfDeliveryCode == undefined
      ) {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue(event["locationCode"]);
      }

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPODTerminal = false;
      } else {
        //lock the terminal back
        //GOS-762 users are allowed to input Terminal info under POL, POD and POT fields only.
        // this.lockPODTerminal = true;
      }

      this.cbMaintenancePODTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );

      this.cbMaintenancePODTerminal.setValue("");
      this.model.pfcPODTerminalCode = "";
      this.cbMaintenancePODLTerminal.setValue("");
      this.model.pfcPODLTerminalCode = "";

      this.model.pfcPodPrefix = event["locationCode"]
        .substr(2, 3)
        .toLocaleUpperCase()
        .trim();

      if (event["locationName"].includes("FREIGHT GROUP")) {
        var uriGetFreightMember =
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findFreightGroupMember/" +
          this.model.pfcDischargePortCode +
          "/" +
          this.cookieService.getDefaultLocationCode();

        this.genericService.GET(uriGetFreightMember).subscribe((resp) => {
          if (resp.ok) {
            if (resp.json()["status"]) {
              var resData = resp.json()["member-list"];
              resData.forEach((eL) => {
                this.freightGroupMember.push(eL["port"]);
              });
            }
          }
        });
      }
    } else {
      this.model.pfcDischargePortCode = "";
      this.cbMaintenancePODTerminal.setUrl("");
      this.cbMaintenancePODTerminal.setValue("");
      this.model.pfcPodPrefix = "";
      this.podName = "";
    }

    //revert the url to search by name
    this.cbMaintenancePortOfDischarge.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePlaceOfDelivery(event) {
    // console.log('** changeCbMaintenancePlaceOfDelivery **');

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.pfcPlaceOfDeliveryCode = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        // GOS-762 Please assists to disable the POR, PODL and FEST field
        // this.lockPODLTerminal = false;
      } else {
        //lock the terminal back
        this.lockPODLTerminal = true;
      }

      this.cbMaintenancePODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePODLTerminal.setValue("");
    } else {
      this.model.pfcPlaceOfDeliveryCode = "";
      this.cbMaintenancePODLTerminal.setUrl("");
      this.cbMaintenancePODLTerminal.setValue("");
    }

    //revert the url to search by name
    this.cbMaintenancePlaceOfDelivery.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenanceFinalDestination(event) {
    // console.log('** changeCbMaintenanceFinalDestination **');

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcFinalDestinationCode = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        // GOS-762 Please assists to disable the POR, PODL and FEST field
        // this.lockFDESTTerminal = false;
      } else {
        //lock the terminal back
        this.lockFDESTTerminal = true;
      }
      this.cbMaintenanceFDESTTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenanceFDESTTerminal.setValue("");
      this.model.pfcFDESTTerminalCode = "";
    } else {
      this.model.pfcFinalDestinationCode = "";
      this.cbMaintenanceFDESTTerminal.setUrl("");
      this.cbMaintenanceFDESTTerminal.setValue("");
      this.model.pfcFDESTTerminalCode = "";
    }

    //revert the url to search by name
    this.cbMaintenanceFinalDestination.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePOT1(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPortOfTransshipment1Code = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPOT1Terminal = false;
      } else {
        //lock the terminal back
        //GOS-762 users are allowed to input Terminal info under POL, POD and POT fields only.
        // this.lockPOT1Terminal = true;
      }

      this.cbMaintenancePOT1Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOT1Terminal.setValue("");
      this.model.pfcPOT1TerminalCode = "";
    } else {
      this.model.pfcPortOfTransshipment1Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT1.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenancePOT2(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPortOfTransshipment2Code = event["locationCode"];
    } else {
      this.model.pfcPortOfTransshipment2Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenancePOT3(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPortOfTransshipment3Code = event["locationCode"];
    } else {
      this.model.pfcPortOfTransshipment3Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenanceReceiptType(event) {
    if (
      event["receiptTypeCode"] != null &&
      event["receiptTypeCode"] != undefined &&
      event["receiptTypeCode"] != ""
    ) {
      this.model.pfcReceiptTypeCode = event["receiptTypeCode"];
    } else {
      this.model.pfcReceiptTypeCode = "";
    }
    this.cbMaintenanceReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}"
    );
  }

  changeCbMaintenanceDeliveryType(event) {
    if (
      event["deliveryTypeCode"] != null &&
      event["deliveryTypeCode"] != undefined &&
      event["deliveryTypeCode"] != ""
    ) {
      this.model.pfcDeliveryTypeCode = event["deliveryTypeCode"];
    } else {
      this.model.pfcDeliveryTypeCode = "";
    }
    this.cbMaintenanceDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}"
    );
  }

  changeCbMaintenanceAdhocVessel(event) {
    if (
      event["vesselId"] != null &&
      event["vesselId"] != undefined &&
      event["vesselId"] != ""
    ) {
      this.model.pfcVesselId = event["vesselId"];
      //this.cbMaintenanceAdhocVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' + this.model.pfcVesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode());
      this.cbMaintenanceAdhocVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.pfcVesselId +
          "/" +
          "O" +
          "/{query}/*"
      );
      this.cbMaintenanceAdhocVoyage.setValue("");
    } else {
      this.model.pfcVesselId = "";
      this.model.pfcVoyage = "";
      this.cbMaintenanceAdhocVoyage.setUrl("");
      this.model.pfcLocalEta = "";
      this.model.pfcLocalEtd = "";
      this.model.pfcLocalAta = "";
      this.model.pfcLocalAtd = "";
    }

    //revert the search using vessel name
    this.cbMaintenanceAdhocVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/VesselName={query}"
    );
  }

  changeCbMaintenanceAdhocVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.pfcVoyage = event.voyage;
      this.getEtaEtdAtaAtd(
        this.model.pfcVesselId,
        this.model.pfcVoyage,
        "O",
        this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.model.pfcVoyage = "";
      this.model.pfcLocalEta = "";
      this.model.pfcLocalEtd = "";
      this.model.pfcLocalAta = "";
      this.model.pfcLocalAtd = "";
    }
  }

  changeAdhocVessel(event) {
    if (
      event["vesselId"] != null &&
      event["vesselId"] != undefined &&
      event["vesselId"] != ""
    ) {
      this.model.pfcVesselId = event["vesselId"];
      this.cbAdhocVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.pfcVesselId +
          "/" +
          "O" +
          "/{query}/*"
      );
      this.cbAdhocVoyage.setValue("");
    } else {
      this.model.pfcVesselId = "";
      this.model.pfcVoyage = "";
      this.cbAdhocVoyage.setUrl("");
      this.model.pfcLocalEta = "";
      this.model.pfcLocalEtd = "";
      this.model.pfcLocalAta = "";
      this.model.pfcLocalAtd = "";
      this.cbAdhocVoyage.setUrl("");
      this.cbAdhocVoyage.setValue("");
    }

    //revert the search using vessel name
    this.cbAdhocVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/VesselName={query}"
    );
  }

  changeAdhocVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.pfcVoyage = event.voyage;
      this.getEtaEtdAtaAtd(
        this.model.pfcVesselId,
        this.model.pfcVoyage,
        "O",
        this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.model.pfcVoyage = "";
      this.model.pfcLocalEta = "";
      this.model.pfcLocalEtd = "";
      this.model.pfcLocalAta = "";
      this.model.pfcLocalAtd = "";
    }
  }
  changeCbMaintenancePORTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPORTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPORTerminalCode = "";
    }
  }

  changeCbMaintenancePODLTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPODLTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPODLTerminalCode = "";
    }
  }

  changeCbMaintenancePOLTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPOLTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPOLTerminalCode = "";
    }
  }

  changeCbMaintenancePOT1Terminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPOT1TerminalCode = event["locationCode"];
    } else {
      this.model.pfcPOT1TerminalCode = "";
    }
  }

  changeCbMaintenancePODTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcPODTerminalCode = event["locationCode"];
    } else {
      this.model.pfcPODTerminalCode = "";
    }
  }

  changeCbMaintenanceFDESTTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.pfcFDESTTerminalCode = event["locationCode"];
    } else {
      this.model.pfcFDESTTerminalCode = "";
    }
  }

  changeEventPfcContainerOwnershipProcess(value: string) {
    if (value == "COC") {
      this.model.pfcDetention = "S";
      this.model.pfcDemurrage = "S";
      this.lockDetention = false;
      this.lockDemurrage = false;
      this.model.pfcDemurragePOL="S";
      this.model.pfcDemurrageRequestPOL=0;
      this.model.pfcDetentionPOL="S";
      this.model.pfcDetentionRequestPOL=0;
      this.model.pfcContainerOwnershipPrefix = "C";
    } else {
      this.model.pfcDetention = "N";
      this.model.pfcDemurrage = "N";
      this.lockDetention = true;
      this.lockDemurrage = true;
    }

    if (value == "COCTS") { //value == "COC" ||
      this.model.pfcContainerOwnershipPrefix = "C";
      this.model.pfcDemurragePOL="N";
      this.model.pfcDemurrageRequestPOL=0;
      this.model.pfcDetentionPOL="N";
      this.model.pfcDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    } else if (value == "SOC" || value == "SOCTS") {
      this.model.pfcContainerOwnershipPrefix = "S";
      this.model.pfcDemurragePOL="N";
      this.model.pfcDemurrageRequestPOL=0;
      this.model.pfcDetentionPOL="N";
      this.model.pfcDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    } else if (value == "SLOT") {
      this.model.pfcContainerOwnershipPrefix = "SL";
      this.model.pfcDemurragePOL="N";
      this.model.pfcDemurrageRequestPOL=0;
      this.model.pfcDetentionPOL="N";
      this.model.pfcDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    }

    this.model.pfcDetentionRequest = 0;
    this.model.pfcDemurrageRequest = 0;

    //set model when container ownership changed
    this.model.pfcContainerOwnership = value;
  }

  //Radio button handler
  changeEventPfcContainerOwnership(event) {
    this.changeEventPfcContainerOwnershipProcess(event.target.value);
  }

  changeEventPfcContainerStatus(event) {
    this.model.pfcContainerStatus = event.target.value;
    if (this.model.pfcContainerStatus == "FULL") {
      this.model.pfcWeightClass = "M";
      this.lockFullEmpty = true;
    } else {
      this.model.pfcWeightClass = "N";
      this.lockFullEmpty = false;
    }
  }

  changeEventPfcShipmentType(event) {
    this.model.pfcShipmentType = event.target.value;
  }

  changeEventPfcDgClass(event) {
    this.model.pfcDgClass = event.target.value;
  }

  changeEventPfcWeightClass(event) {
    if (this.model.pfcContainerStatus != "EMPTY") {
      this.model.pfcWeightClass = event.target.value;
    } else {
      this.model.pfcWeightClass = "N";
    }
  }

  changeEventPfcIhc(event) {
    this.model.pfcIhc = event.target.value;
  }
  //End of Radio Button handler

  //FAI Group handler
  changeEventPfcDetailFAIGroup(event) {
    if (event.target.checked) {
      this.modelDetail.pfcDetailFAIGroup = "Y";
    } else {
      this.modelDetail.pfcDetailFAIGroup = "N";
    }
  }

  //Copy freight tab
  changeEventPfcDetailCopyTab(event) {
    if (event.target.checked) {
      this.modelDetail.pfcDetailCopyTab = "Y";
    } else {
      this.modelDetail.pfcDetailCopyTab = "N";
    }
  }

  //Door Open handler
  changeEventPfcDetailDoorOpen(event) {
    this.modelDetail.pfcDetailOogDoorOpen = event.target.value;
  }

  changeEventDetentionPOL(event) {
    this.model.pfcDetentionPOL = event.target.value;
    if (this.model.pfcDetentionPOL == "R") {
      this.lockDetentionPol = false;
    } else {
      this.lockDetentionPol = true;
      this.model.pfcDetentionRequestPOL = 0;
    }
  }

  changeEventDemurragePol(event) {
    this.model.pfcDemurragePOL = event.target.value;
    if (this.model.pfcDemurragePOL == "R") {
      this.lockDemurragePol = false;
    } else {
      this.lockDemurragePol = true;
      this.model.pfcDemurrageRequestPOL = 0;
    }
  }

  //POD detention event
  changeEventPfcDetention(event) {
    this.model.pfcDetention = event.target.value;
    this.model.pfcDetentionRequest = 0;
  }

  //POD demurrage event
  changeEventPfcDemurrage(event) {
    this.model.pfcDemurrage = event.target.value;
    this.model.pfcDemurrageRequest = 0;
  }

  changeCbMaintenanceDetailCharge(event) {
    if (
      event.chargeId != null &&
      event.chargeCode != "" &&
      event.chargeId != undefined
    ) {
      this.modelDetail.pfcDetailChargeCode = event.chargeCode;
      this.modelDetail.pfcDetailChargeName = event.chargeName;
    } else {
      this.modelDetail.pfcDetailChargeCode = "";
      this.modelDetail.pfcDetailChargeName = "";
    }

    //revert to be able to search by core or name
    this.cbMaintenanceDetailCharge.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlNonAdhoc/chargeCodeOrName={query}"
    );
  }

  //handle container type
  changeEventPfcContainerType(event) {
    if (
      event.target.value != null &&
      event.target.value != "" &&
      event.target.value != undefined
    ) {
      this.model.pfcContainerType = event.target.value;
      console.log("** pfcContainerType **");
      console.log(event.target.value);

      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();

      var detailList: any[] = this.map.get(this.model.pfcContainerType);
      console.log("** detail list **");
      console.log(detailList);
      if (detailList != null && detailList != undefined) {
        detailList.forEach((element) => {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;

          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = "OPEN TOP";
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = "FLAT RACK";
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = "PLATFORM";
              break;
            case "REF":
              x.pfcDetailContainerTypeName = "REEFER";
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = "REEFER DRY";
              break;
            case "UC":
              x.pfcDetailContainerTypeName = "UC";
              break;
            case "STD":
              x.pfcDetailContainerTypeName = "NORMAL";
              break;
            default:
              break;
          }

          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if (x.pfcDetailOogDoorOpen == "N") {
            x.pfcDetailOogDoorOpenName = "N/A";
          } else if (x.pfcDetailOogDoorOpen == "D") {
            x.pfcDetailOogDoorOpenName = "Detached";
          } else if (x.pfcDetailOogDoorOpen == "B") {
            x.pfcDetailOogDoorOpenName = "Tied Both";
          } else if (x.pfcDetailOogDoorOpen == "L") {
            x.pfcDetailOogDoorOpenName = "Tied Left";
          } else if (x.pfcDetailOogDoorOpen == "R") {
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
          x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;
          x.no = element.no;

          if (x.pfcDetailPCTab == "P") {
            //this.gridPrepaid.listStore.store.push(x);
            //this.gridPrepaid.loadData();
            this.gridApiPrepaid.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }

          if (x.pfcDetailPCTab == "C") {
            //this.gridCollect.listStore.store.push(x);
            //this.gridCollect.loadData();
            this.gridApiCollect.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }
        });
      }
      this.calculateTotal();
    } else {
      this.model.pfcContainerType = "";
    }
  }

  changeCbMaintenanceDetailCurrency(event) {
    if (
      event.currencyCode != null &&
      event.currencyCode != "" &&
      event.currencyCode != undefined
    ) {
      this.modelDetail.pfcDetailCurrency = event.currencyCode;
    } else {
      this.modelDetail.pfcDetailCurrency = "";
    }
  }

  //godzila
  changeCbMaintenanceDetailPC(event) {
    if (
      event.prepaid_collect != null &&
      event.prepaid_collect != "" &&
      event.prepaid_collect != undefined
    ) {
      this.modelDetail.pfcDetailPC = event.prepaid_collect;

      if (this.modelDetail.pfcDetailPC == "P") {
        //worker for place of payment
        /*
        if (this.model.pfcLoadPortCode != null && this.model.pfcLoadPortCode != undefined && this.model.pfcLoadPortCode != ''  &&
        this.model.pfcLoadPortCode != 'VNHCX' && this.model.pfcLoadPortCode != 'IDJKX' && this.model.pfcLoadPortCode != 'PKKHX' &&
        this.model.pfcLoadPortCode != 'AEDBX' && this.model.pfcLoadPortCode != 'PHMNX' && this.model.pfcLoadPortCode != 'MYPKX' &&
        this.model.pfcLoadPortCode != 'THBKX' && this.model.pfcLoadPortCode != 'CNXGX') {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcLoadPortCode);
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue('');
          this.modelDetail.pfcDetailPlaceOfPayment = '';
          this.modelDetail.pfcDetailPlaceOfPaymentName = '';
        }
        */
        if (
          this.model.pfcLoadPortCode != null &&
          this.model.pfcLoadPortCode != undefined &&
          this.model.pfcLoadPortCode != "" &&
          !this.polName.includes("FREIGHT GROUP")
        ) {
          this.fpPayer = this.fpAcctCode;

          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue(
            this.model.pfcLoadPortCode
          );
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
          this.modelDetail.pfcDetailPlaceOfPayment = "";
          this.modelDetail.pfcDetailPlaceOfPaymentName = "";
        }

        //worker for payer
        if (
          this.model.pfcFreightPartyId != null &&
          this.model.pfcFreightPartyId != undefined &&
          this.model.pfcFreightPartyId != ""
        ) {
          // this.cbMaintenanceDetailPayer.setUrl(
          //   this.configService.config.BASE_API.toString() +
          //     //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          //     // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
          //     "/MasterCustomers/findByComboBoxControlGeneral/payer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
          // );
          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     // self.fpAcctCode = '';

          //   }else{
          //     self.modelDetail.pfcDetailPayer = callbackPayer.customerId;
          //     self.modelDetail.pfcDetailPayerName = callbackPayer.customerName;
          //   }
          // });

          let uri =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId="+
              this.model.pfcFreightPartyId.toString();

            var self = this;
            this.genericService.GET(uri).subscribe((resp) => {
                if (resp.ok) {
                  var datas = resp.json()['content'];
                  if (datas.length != 0) {
                    datas.forEach(function (data) {
                      if (data['isPayer'] != "Y" && self.dateCreatedForCbPayer == '*') {
                        self.cbMaintenanceDetailPayer.setForceValue("");
                        self.fpPayer = "";
                      } else {
                        self.modelDetail.pfcDetailPayer = data.customerId;
                        self.modelDetail.pfcDetailPayerName = data.customerName;
                        self.cbMaintenanceDetailPayer.setForceValue(data.customerName);

                        self.fpPayer = "<<";
                        if (event["cocAccountCode"] !== "") {
                          self.fpPayer += " COC: " + data.cocAccountCode;
                        }
                        if (event["socAccountCode"] !== "") {
                          self.fpPayer += " SOC: " + data.socAccountCode;
                        }
                        if (event["slotAccountCode"] !== "") {
                          self.fpPayer += " SLOT: " +  data.slotAccountCode;
                        }
                          self.fpPayer += " >>";
                      }
                    });
                  } else {
                    self.cbMaintenanceDetailPayer.setForceValue("");
                    self.fpPayer = "";
                  }
                }
            });

        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
          );
          this.cbMaintenanceDetailPayer.setValue("");
          this.modelDetail.pfcDetailPayer = "";
          this.modelDetail.pfcDetailPayerName = "";
          // this.fpAcctCode='';
          this.fpPayer = "";
        }
      } else {
        //worker for place of payment
        /*
        if (this.model.pfcDischargePortCode != null && this.model.pfcDischargePortCode != undefined && this.model.pfcDischargePortCode != ''  &&
        this.model.pfcDischargePortCode != 'VNHCX' && this.model.pfcDischargePortCode != 'IDJKX' && this.model.pfcDischargePortCode != 'PKKHX' &&
        this.model.pfcDischargePortCode != 'AEDBX' && this.model.pfcDischargePortCode != 'PHMNX' && this.model.pfcDischargePortCode != 'MYPKX' &&
        this.model.pfcDischargePortCode != 'THBKX' && this.model.pfcDischargePortCode != 'CNXGX') {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcDischargePortCode);
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue('');
          this.modelDetail.pfcDetailPlaceOfPayment = '';
          this.modelDetail.pfcDetailPlaceOfPaymentName = '';
        }
        */
        if (
          this.model.pfcDischargePortCode != null &&
          this.model.pfcDischargePortCode != undefined &&
          this.model.pfcDischargePortCode != "" &&
          !this.podName.includes("FREIGHT GROUP")
        ) {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue(
            this.model.pfcDischargePortCode
          );
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
          this.modelDetail.pfcDetailPlaceOfPayment = "";
          this.modelDetail.pfcDetailPlaceOfPaymentName = "";
        }

        //worker for payer
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
        );
        this.cbMaintenanceDetailPayer.setValue("");
        this.modelDetail.pfcDetailPayer = "";
        this.modelDetail.pfcDetailPayerName = "";
        // this.fpAcctCode = '';
        this.fpPayer = "";
      }
    } else {
      this.modelDetail.pfcDetailPC = "";
      this.fpPayer = "";
    }
  }

  changeCbMaintenancePlaceOfPayment(event) {
    if (
      event.locationCode != null &&
      event.locationCode != null &&
      event.locationCode != undefined
    ) {
      console.log(event);
      /*
      if(event.locationCode != "VNHCX" && event.locationCode != "IDJKX" && event.locationCode != "PKKHX" && event.locationCode != "AEDBX" && event.locationCode != "PHMNX" && event.locationCode != "MYPKX" && event.locationCode != "THBKX"){
        this.modelDetail.pfcDetailPlaceOfPayment = event.locationCode;
        this.modelDetail.pfcDetailPlaceOfPaymentName = event.locationName
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
      }else{
        this.modelDetail.pfcDetailPlaceOfPayment = '';
        this.modelDetail.pfcDetailPlaceOfPaymentName = '';
      }
      */
      if (!String(event.locationName).includes("FREIGHT GROUP")) {
        this.modelDetail.pfcDetailPlaceOfPayment = event.locationCode;
        this.modelDetail.pfcDetailPlaceOfPaymentName = event.locationName;
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
        );
      } else {
        this.modelDetail.pfcDetailPlaceOfPayment = event.locationCode;
        this.modelDetail.pfcDetailPlaceOfPaymentName = event.locationName;
      }
    } else {
      this.modelDetail.pfcDetailPlaceOfPayment = "";
      this.modelDetail.pfcDetailPlaceOfPaymentName = "";
    }
  }
  changeCbMaintenanceDetailPayer(event) {
    if (
      event.customerId != null &&
      event.customerId != null &&
      event.customerId != undefined
    ) {
      this.modelDetail.pfcDetailPayer = event.customerId;
      this.modelDetail.pfcDetailPayerName = event.customerName;
      this.modelDetail.pfcDetailPayerIsPayer = event.isPayer;
      this.modelDetail.pfcDetailPayerIsValid = event.isValid;


      // if(this.model.pfcFreightPartyId == this.modelDetail.pfcDetailPayer){

      this.fpPayer = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpPayer += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpPayer += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpPayer += " SLOT: " + event["slotAccountCode"];
      }
      this.fpPayer += " >>";

      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
      );
    } else {
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
      );
      this.fpPayer = "";
      this.modelDetail.pfcDetailPayer = "";
      this.modelDetail.pfcDetailPayerName = "";
      this.modelDetail.pfcDetailPayerIsPayer = "";
      this.modelDetail.pfcDetailPayerIsValid = "";
      // this.fpAcctCode = '';
    }
  }

  changeAcctPIC(event) {
    // console.log('change event ACC PIC')
    if (
      event["userName"] != null &&
      event["userName"] != undefined &&
      event["userName"] != ""
    ) {
      this.model.pfcAcctPic = event["userName"];
      this.cbAcctPic.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findAcctPIC/" +
        event["userName"]
      );
    } else {
      this.model.pfcAcctPic = "";
      // this.cbAcctPic.setUrl("");
    }

    //revert to search by name
    this.cbAcctPic.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterAccountPic/findAcctPIC/{query}"
    );
  }

  changeArchivePfc(event) {


    // console.log('change event ACC PIC')
    // this.model.pfcAcctPic = "";
    if (
      event["version"] != null &&
      event["version"] != undefined &&
      event["version"] != ""
    ) {
      console.log(event["version"]);
      if (event["version"] != "Select Archive") {
        this.showSelectedArchive(event["version"]);
      } else {
        this.closeArchive();
      }

      // this.model.pfcAcctPic = event["userName"];
    }
  }

  /*
  onSurcharge()
  {
    // console.log('** on surcharge **');

    this.genericUtil.showLoader();
    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    this.model.pfcDetails = [];
    this.map.forEach((value: FreightDetail[], key: string) => {

      value.forEach(element => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case 'TNK':
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case 'OPT':
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case 'FLR':
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case 'PLT':
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case 'REF':
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case 'DRY':
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case 'UC':
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case 'STD':
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == 'N'){
          x.pfcDetailOogDoorOpenName = 'N/A';
        }else if(x.pfcDetailOogDoorOpen == 'D'){
          x.pfcDetailOogDoorOpenName = 'Detached';
        }else if(x.pfcDetailOogDoorOpen == 'B'){
          x.pfcDetailOogDoorOpenName = 'Tied Both';
        }else if(x.pfcDetailOogDoorOpen == 'L'){
          x.pfcDetailOogDoorOpenName = 'Tied Left';
        }else if(x.pfcDetailOogDoorOpen == 'R'){
          x.pfcDetailOogDoorOpenName = 'Tied Right';
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

          if ((this.currentActiveTab == 'prepaid' && x.pfcDetailPCTab == 'P') || (this.currentActiveTab == 'collect' && x.pfcDetailPCTab == 'C')) {
            this.model.pfcDetails.push(x);
          }

      });
    });

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterSurcharges/gettingSurcharge',this.model).subscribe((resp) => {

      if(resp.json()['status'] == 'OK'){
          var dataSurcharges:FreightDetail[] = [];
          var dt:any[] = [];
          dt = resp.json()['content'];

          dt.forEach(element => {
            // console.log(element);

            var freightDetail:FreightDetail = new FreightDetail();
            freightDetail.pfcDetailChargeCode = element['chargeCode'];
            freightDetail.pfcDetailChargeName = element['chargeName'];
            freightDetail.pfcDetailContainerType = element['containerType'];
            switch (freightDetail.pfcDetailContainerType) {
              case 'TNK':
                freightDetail.pfcDetailContainerTypeName = 'TANK';
                break;
              case 'OPT':
                freightDetail.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case 'FLR':
                freightDetail.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case 'PLT':
                freightDetail.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case 'REF':
                freightDetail.pfcDetailContainerTypeName = 'REEFER';
                break;
              case 'DRY':
                freightDetail.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case 'UC':
                freightDetail.pfcDetailContainerTypeName = 'UC';
                break;
              case 'STD':
                freightDetail.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
            }
            freightDetail.pfcDetailCurrency = element['currencyCode'];
            freightDetail.pfcDetailPC = element['freightType'];
            freightDetail.pfcDetailPCTab = element['pcTab'];
            if(element['payerCode'] == ''){
              freightDetail.pfcDetailPayer = '';
              freightDetail.pfcDetailPayerName = '';
            }else{
              freightDetail.pfcDetailPayer = element['payerCode'];
              freightDetail.pfcDetailPayerName = element['payerName'];
            }

            freightDetail.pfcDetailPlaceOfPayment = element['placeOfPaymentCode'];
            freightDetail.pfcDetailPlaceOfPaymentName = element['placeOfPaymentName'];

            freightDetail.pfcDetailD20 = 0;
            freightDetail.pfcDetailD40 = 0;
            freightDetail.pfcDetailD45 = 0;
            freightDetail.pfcDetailH20 = 0;
            freightDetail.pfcDetailH40 = 0;
            freightDetail.pfcDetailH45 = 0;

            if(element['D20'] != undefined){
              freightDetail.pfcDetailD20 = Number.parseFloat(element['D20']);
            }

            if(element['D40'] != undefined){
              freightDetail.pfcDetailD40 = Number.parseFloat(element['D40']);
            }

            if(element['D45'] != undefined){
              freightDetail.pfcDetailD45 = Number.parseFloat(element['D45']);
            }

            if(element['H20'] != undefined){
              freightDetail.pfcDetailH20 = Number.parseFloat(element['H20']);
            }

            if(element['H40'] != undefined){
              freightDetail.pfcDetailH40 = Number.parseFloat(element['H40']);
            }

            if(element['H45'] != undefined){
              freightDetail.pfcDetailH45 = Number.parseFloat(element['H45']);
            }

            freightDetail.pfcDetailOogDoorOpen = element['doorOpen'];
            if(freightDetail.pfcDetailOogDoorOpen == 'N'){
              freightDetail.pfcDetailOogDoorOpenName = 'N/A';
            }else if(freightDetail.pfcDetailOogDoorOpen == 'D'){
              freightDetail.pfcDetailOogDoorOpenName = 'Detached';
            }else if(freightDetail.pfcDetailOogDoorOpen == 'B'){
              freightDetail.pfcDetailOogDoorOpenName = 'Tied Both';
            }else if(freightDetail.pfcDetailOogDoorOpen == 'L'){
              freightDetail.pfcDetailOogDoorOpenName = 'Tied Left';
            }else if(freightDetail.pfcDetailOogDoorOpen == 'R'){
              freightDetail.pfcDetailOogDoorOpenName = 'Tied Right';
            }
            freightDetail.pfcDetailOogOH = element['oh'];
            freightDetail.pfcDetailOogOLB = element['olb'];
            freightDetail.pfcDetailOogOLF = element['olf'];
            freightDetail.pfcDetailOogOWL = element['owl'];
            freightDetail.pfcDetailOogOWR = element['owr'];
            freightDetail.pfcDetailOogUcH = element['uch'];
            freightDetail.pfcDetailOogUcL = element['ucl'];
            freightDetail.pfcDetailOogUcW = element['ucw'];

            // console.log(freightDetail);
            dataSurcharges.push(freightDetail);



          });

          if (this.map.get(this.model.pfcContainerType) == undefined) {
            // console.log('#1');
            let indexPrepaid:number = 0;
            let indexCollect:number = 0;
            dataSurcharges.forEach(element => {
              if(element.pfcDetailPCTab == 'P'){
                indexPrepaid++;
              }

              if(element.pfcDetailPCTab == 'C'){
                indexCollect++;
              }
              if(element.pfcDetailPCTab == 'P'){
                element.pfcDetailSeqNo = indexPrepaid;
                element.no = indexPrepaid;
              }
              if(element.pfcDetailPCTab == 'C'){
                element.pfcDetailSeqNo = indexCollect;
                element.no = indexCollect;
              }
            });
            this.map.set(this.model.pfcContainerType, dataSurcharges);
          }else{
            // console.log('#2');
            let data:FreightDetail[] = this.map.get(this.model.pfcContainerType);
            // console.log('existing data ');
            // console.log(data);
            let indexPrepaid:number = 0;
            let indexCollect:number = 0;

            let dataList:FreightDetail[] = [];
            data.forEach(element => {
              if(element.pfcDetailPCTab == 'P'){
                indexPrepaid++;
              }

              if(element.pfcDetailPCTab == 'C'){
                indexCollect++;
              }
                var x:FreightDetail= new FreightDetail();
                x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                x.pfcDetailChargeName = element.pfcDetailChargeName;
                x.pfcDetailContainerType = element.pfcDetailContainerType;
                switch (x.pfcDetailContainerType) {
                  case 'TNK':
                    x.pfcDetailContainerTypeName = 'TANK';
                    break;
                  case 'OPT':
                    x.pfcDetailContainerTypeName = 'OPEN TOP';
                    break;
                  case 'FLR':
                    x.pfcDetailContainerTypeName = 'FLAT RACK';
                    break;
                  case 'PLT':
                    x.pfcDetailContainerTypeName = 'PLATFORM';
                    break;
                  case 'REF':
                    x.pfcDetailContainerTypeName = 'REEFER';
                    break;
                  case 'DRY':
                    x.pfcDetailContainerTypeName = 'REEFER DRY';
                    break;
                  case 'UC':
                    x.pfcDetailContainerTypeName = 'UC';
                    break;
                  case 'STD':
                    x.pfcDetailContainerTypeName = 'NORMAL';
                    break;
                  default:
                    break;
                }
                x.pfcDetailCurrency = element.pfcDetailCurrency;
                x.pfcDetailPayer = element.pfcDetailPayer;
                x.pfcDetailPayerName = element.pfcDetailPayerName;
                x.pfcDetailPC = element.pfcDetailPC;
                x.pfcDetailPCTab = element.pfcDetailPCTab;
                if(element.pfcDetailPCTab == 'P'){
                  x.pfcDetailSeqNo = indexPrepaid;
                  x.no = indexPrepaid;
                }
                if(element.pfcDetailPCTab == 'C'){
                  x.pfcDetailSeqNo = indexCollect;
                  x.no = indexCollect;
                }
                x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                x.pfcDetailD20 = element.pfcDetailD20;
                x.pfcDetailD40 = element.pfcDetailD40;
                x.pfcDetailD45 = element.pfcDetailD45;
                x.pfcDetailH20 = element.pfcDetailH20;
                x.pfcDetailH40 = element.pfcDetailH40;
                x.pfcDetailH45 = element.pfcDetailH45;
                x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                x.pfcDetailKT = element.pfcDetailKT;
                x.pfcDetailM3 = element.pfcDetailM3;
                x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                if(x.pfcDetailOogDoorOpen == 'N'){
                  x.pfcDetailOogDoorOpenName = 'N/A';
                }else if(x.pfcDetailOogDoorOpen == 'D'){
                  x.pfcDetailOogDoorOpenName = 'Detached';
                }else if(x.pfcDetailOogDoorOpen == 'B'){
                  x.pfcDetailOogDoorOpenName = 'Tied Both';
                }else if(x.pfcDetailOogDoorOpen == 'L'){
                  x.pfcDetailOogDoorOpenName = 'Tied Left';
                }else if(x.pfcDetailOogDoorOpen == 'R'){
                  x.pfcDetailOogDoorOpenName = 'Tied Right';
                }
                x.pfcDetailOogOH = element.pfcDetailOogOH;
                x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                x.pfcDetailRT = element.pfcDetailRT;
                dataList.push(x);
            });

            let surchargeList:FreightDetail[] = [];

            dataSurcharges.forEach(element => {
              //// console.log(element);

              let snapCodeSurcharge: string = '';
              snapCodeSurcharge += element.pfcDetailPC.trim();
              snapCodeSurcharge += element.pfcDetailPCTab.trim();
              snapCodeSurcharge += element.pfcDetailChargeCode.trim();
              snapCodeSurcharge += element.pfcDetailCurrency.trim();
              snapCodeSurcharge += element.pfcDetailPlaceOfPayment.trim();
              snapCodeSurcharge += element.pfcDetailPayer.trim();
              snapCodeSurcharge += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
              snapCodeSurcharge += element.pfcDetailOogDoorOpen.trim();
              snapCodeSurcharge += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toString();
              snapCodeSurcharge += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toString();
              snapCodeSurcharge += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toString();
              snapCodeSurcharge += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toString();
              snapCodeSurcharge += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toString();

              let chek:boolean = false;
              dataList.forEach(el => {

                let snapCode: string = '';
                snapCode += el.pfcDetailPC.trim();
                snapCode += el.pfcDetailPCTab.trim();
                snapCode += el.pfcDetailChargeCode.trim();
                snapCode += el.pfcDetailCurrency.trim();
                snapCode += el.pfcDetailPlaceOfPayment.trim();
                snapCode += el.pfcDetailPayer.trim();
                snapCode += (el.pfcDetailKillSlot == null) ? '0' : el.pfcDetailKillSlot.toString();
                snapCode += el.pfcDetailOogDoorOpen.trim();
                snapCode += (el.pfcDetailOogOH == null) ? '0' : el.pfcDetailOogOH.toString();
                snapCode += (el.pfcDetailOogOWL == null) ? '0' : el.pfcDetailOogOWL.toString();
                snapCode += (el.pfcDetailOogOWR == null) ? '0' : el.pfcDetailOogOWR.toString();
                snapCode += (el.pfcDetailOogOLF == null) ? '0' : el.pfcDetailOogOLF.toString();
                snapCode += (el.pfcDetailOogOLB == null) ? '0' : el.pfcDetailOogOLB.toString();
                snapCode += (el.pfcDetailOogUcH == null) ? '0' : el.pfcDetailOogUcH.toString();
                snapCode += (el.pfcDetailOogUcL == null) ? '0' : el.pfcDetailOogUcL.toString();
                snapCode += (el.pfcDetailOogUcW == null) ? '0' : el.pfcDetailOogUcW.toString();

                // console.log('** snapCodeSurcharge **');
                // console.log(snapCodeSurcharge);

                // console.log('** snapCode **');
                // console.log(snapCode);

                if(snapCodeSurcharge == snapCode){
                  // console.log('#SAMA');
                  chek = true;
                  var idx = dataList.indexOf(el);
                  dataList[idx].pfcDetailChargeCode = element.pfcDetailChargeCode;
                  dataList[idx].pfcDetailChargeName = element.pfcDetailChargeName;
                  dataList[idx].pfcDetailCurrency = element.pfcDetailCurrency;
                  dataList[idx].pfcDetailPayer = element.pfcDetailPayer;
                  dataList[idx].pfcDetailPayerName = element.pfcDetailPayerName;
                  dataList[idx].pfcDetailPC = element.pfcDetailPC;
                  dataList[idx].pfcDetailPCTab = element.pfcDetailPCTab;
                  dataList[idx].pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                  dataList[idx].pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                  dataList[idx].pfcDetailD20 = element.pfcDetailD20;
                  dataList[idx].pfcDetailD40 = element.pfcDetailD40;
                  dataList[idx].pfcDetailD45 = element.pfcDetailD45;
                  dataList[idx].pfcDetailH20 = element.pfcDetailH20;
                  dataList[idx].pfcDetailH40 = element.pfcDetailH40;
                  dataList[idx].pfcDetailH45 = element.pfcDetailH45;
                  dataList[idx].pfcDetailKillSlot = element.pfcDetailKillSlot;
                  dataList[idx].pfcDetailKT = element.pfcDetailKT;
                  dataList[idx].pfcDetailM3 = element.pfcDetailM3;
                  dataList[idx].pfcDetailMinCharge = element.pfcDetailMinCharge;
                  dataList[idx].pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                  dataList[idx].pfcDetailOogOH = element.pfcDetailOogOH;
                  dataList[idx].pfcDetailOogOLB = element.pfcDetailOogOLB;
                  dataList[idx].pfcDetailOogOLF = element.pfcDetailOogOLF;
                  dataList[idx].pfcDetailOogOWL = element.pfcDetailOogOWL;
                  dataList[idx].pfcDetailOogOWR = element.pfcDetailOogOWR;
                  dataList[idx].pfcDetailOogUcH = element.pfcDetailOogUcH;
                  dataList[idx].pfcDetailOogUcW = element.pfcDetailOogUcW;
                  dataList[idx].pfcDetailOogUcL = element.pfcDetailOogUcL;
                  dataList[idx].pfcDetailRT = element.pfcDetailRT;
                }else{
                  //data.push(element);
                  // console.log('#BEDA');


                }

              });

              // console.log('**chek**');
              // console.log(chek);

              if(!chek){
                if(element.pfcDetailPCTab == 'P'){
                  let ip:number = indexPrepaid;
                  element.pfcDetailSeqNo = (ip + 1);
                  element.no = (ip + 1);
                }

                if(element.pfcDetailPCTab == 'C'){
                  let ip:number = indexCollect;
                  element.pfcDetailSeqNo = (ip + 1);
                  element.no = (ip + 1);
                }
                surchargeList.push(element);
              }
            });


            let dets: FreightDetail[] = [];
            dataList.forEach(element => {
              dets.push(element)
            });
            surchargeList.forEach(element => {
              dets.push(element)
            });

            this.map.set(this.model.pfcContainerType,dets);

          }


          // console.log(this.map);

          this.gridPrepaid.onClear();
          this.gridCollect.onClear();

          var detailList: any[] = this.map.get(this.model.pfcContainerType)
          detailList.forEach(element => {

            // console.log(element);

            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = element.pfcDetailChargeCode;
            x.pfcDetailChargeName = element.pfcDetailChargeName;
            x.pfcDetailContainerType = element.pfcDetailContainerType;
            switch (x.pfcDetailContainerType) {
              case 'TNK':
                x.pfcDetailContainerTypeName = 'TANK';
                break;
              case 'OPT':
                x.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case 'FLR':
                x.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case 'PLT':
                x.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case 'REF':
                x.pfcDetailContainerTypeName = 'REEFER';
                break;
              case 'DRY':
                x.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case 'UC':
                x.pfcDetailContainerTypeName = 'UC';
                break;
              case 'STD':
                x.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
            }
            x.pfcDetailCopyTab = element.pfcDetailCopyTab;
            x.pfcDetailCurrency = element.pfcDetailCurrency;
            x.pfcDetailD20 = element.pfcDetailD20;
            x.pfcDetailD40 = element.pfcDetailD40;
            x.pfcDetailD45 = element.pfcDetailD45;
            x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
            x.pfcDetailH20 = element.pfcDetailH20;
            x.pfcDetailH40 = element.pfcDetailH40;
            x.pfcDetailH45 = element.pfcDetailH45;
            x.pfcDetailKT = element.pfcDetailKT;
            x.pfcDetailKillSlot = element.pfcDetailKillSlot;
            x.pfcDetailM3 = element.pfcDetailM3;
            x.pfcDetailMinCharge = element.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
            if(x.pfcDetailOogDoorOpen == 'N'){
              x.pfcDetailOogDoorOpenName = 'N/A';
            }else if(x.pfcDetailOogDoorOpen == 'D'){
              x.pfcDetailOogDoorOpenName = 'Detached';
            }else if(x.pfcDetailOogDoorOpen == 'B'){
              x.pfcDetailOogDoorOpenName = 'Tied Both';
            }else if(x.pfcDetailOogDoorOpen == 'L'){
              x.pfcDetailOogDoorOpenName = 'Tied Left';
            }else if(x.pfcDetailOogDoorOpen == 'R'){
              x.pfcDetailOogDoorOpenName = 'Tied Right';
            }
            x.pfcDetailOogOH = element.pfcDetailOogOH;
            x.pfcDetailOogOLB = element.pfcDetailOogOLB;
            x.pfcDetailOogOLF = element.pfcDetailOogOLF;
            x.pfcDetailOogOWL = element.pfcDetailOogOWL;
            x.pfcDetailOogOWR = element.pfcDetailOogOWR;
            x.pfcDetailOogUcH = element.pfcDetailOogUcH;
            x.pfcDetailOogUcL = element.pfcDetailOogUcL;
            x.pfcDetailOogUcW = element.pfcDetailOogUcW;
            x.pfcDetailPC = element.pfcDetailPC;
            x.pfcDetailPCTab = element.pfcDetailPCTab;
            x.pfcDetailPayer = element.pfcDetailPayer;
            x.pfcDetailPayerName = element.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = element.pfcDetailRT;
            x.pfcDetailSeqNo = element.pfcDetailSeqNo;
            x.pfcDetailStatus = element.pfcDetailStatus;
            x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

            if (x.pfcDetailPCTab == 'P') {
              this.gridPrepaid.listStore.store.push(x);
              this.gridPrepaid.loadData();
            }

            if (x.pfcDetailPCTab == 'C') {
              this.gridCollect.listStore.store.push(x);
              this.gridCollect.loadData();
            }

          });

      }else{
        this.message('information', 'Information', resp.json()['message'] , 'okonly', {ok: ''});
      }


      this.genericUtil.hideLoader();
    });

  }
  */

  clearSurcharge() {
    var oriList: any[] = [];
    if (this.map.get(this.model.pfcContainerType != null)) {
      this.map.get(this.model.pfcContainerType).forEach((element) => {
        if (element.pfcSurchargeId == "") {
          oriList.push(element);
        }
      });

      this.map.set(this.model.pfcContainerType, oriList);
      // console.log(this.map);

      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();

      var detailList: any[] = this.map.get(this.model.pfcContainerType);
      detailList.forEach((element) => {
        // console.log(element);
        var x: FreightDetail = new FreightDetail();
        x.pfcSurchargeId = element.pfcSurchargeId;
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.pfcDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.pfcDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.pfcDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if (x.pfcDetailOogDoorOpen == "N") {
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
        x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

        if (x.pfcDetailPCTab == "P") {
          //this.gridPrepaid.listStore.store.push(x);
          //this.gridPrepaid.loadData();
          this.gridApiPrepaid.updateRowData({
            add: [JSON.parse(JSON.stringify(x))],
          });
        }

        if (x.pfcDetailPCTab == "C") {
          //this.gridCollect.listStore.store.push(x);
          //this.gridCollect.loadData();
          this.gridApiCollect.updateRowData({
            add: [JSON.parse(JSON.stringify(x))],
          });
        }
      });
    }
  }

  onSurcharge2() {
    // console.log('** on surcharge 2 **');

    this.genericUtil.showLoader();
    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();
    //this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    //this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();
    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom2.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo2.getValue();

    this.setValidatorSurcharge();
    this.model["effectiveDateExceedValidator"] =
      this.checkEffectiveDateExceedValidator();
    this.model["expiryDateValidator"] = this.checkExpiryDateValidator();
    this.model["blankFreightChargesValidator"] =
      this.checkBlankFreightChargesValidator();
    this.model["missingMainChargesValidator"] =
      this.checkMissingMainChargesValidator();
    this.model["checkDuplicateValidator"] = this.checkDuplicateValidator();

    this.isError = this.onValidate(this.model);
    if (this.isError) {
      this.genericUtil.hideLoader();
      window.scrollTo(0, 0);
    } else {
      this.model.pfcDetails = [];
      //this.map.forEach((value: FreightDetail[], key: string) => {

      //value.forEach(element => {
      if (this.map.size > 0) {
        this.clearSurcharge();
        // console.log('this.map.get(this.model.pfcContainerType) => ' + this.map.get(this.model.pfcContainerType));

        this.map.get(this.model.pfcContainerType).forEach((element) => {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = "OPEN TOP";
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = "FLAT RACK";
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = "PLATFORM";
              break;
            case "REF":
              x.pfcDetailContainerTypeName = "REEFER";
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = "REEFER DRY";
              break;
            case "UC":
              x.pfcDetailContainerTypeName = "UC";
              break;
            case "STD":
              x.pfcDetailContainerTypeName = "NORMAL";
              break;
            default:
              break;
          }
          x.pfcDetailCopyTab = element.pfcDetailCopyTab;
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if (x.pfcDetailOogDoorOpen == "N") {
            x.pfcDetailOogDoorOpenName = "N/A";
          } else if (x.pfcDetailOogDoorOpen == "D") {
            x.pfcDetailOogDoorOpenName = "Detached";
          } else if (x.pfcDetailOogDoorOpen == "B") {
            x.pfcDetailOogDoorOpenName = "Tied Both";
          } else if (x.pfcDetailOogDoorOpen == "L") {
            x.pfcDetailOogDoorOpenName = "Tied Left";
          } else if (x.pfcDetailOogDoorOpen == "R") {
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;
          x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

          if (
            (this.currentActiveTab == "prepaid" && x.pfcDetailPCTab == "P") ||
            (this.currentActiveTab == "collect" && x.pfcDetailPCTab == "C")
          ) {
            this.model.pfcDetails.push(x);
          }
        });
        //});

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/SurchargeMaster/gettingSurcharge",
            this.model
          )
          .subscribe((resp) => {
            if (resp.json()["status"] == "OK") {
              var dataSurcharges: FreightDetail[] = [];
              var dt: any[] = [];
              dt = resp.json()["content"];
              var listPyr = [];
              this.clickSurcharge = true;
              dt.forEach((element) => {
                var freightDetail: FreightDetail = new FreightDetail();
                freightDetail.pfcSurchargeId = element["surchargeId"];
                freightDetail.pfcDetailChargeCode = element["chargeCode"];
                freightDetail.pfcDetailChargeName = element["chargeName"];
                freightDetail.pfcDetailContainerType = element["containerType"];
                switch (freightDetail.pfcDetailContainerType) {
                  case "TNK":
                    freightDetail.pfcDetailContainerTypeName = "TANK";
                    break;
                  case "OPT":
                    freightDetail.pfcDetailContainerTypeName = "OPEN TOP";
                    break;
                  case "FLR":
                    freightDetail.pfcDetailContainerTypeName = "FLAT RACK";
                    break;
                  case "PLT":
                    freightDetail.pfcDetailContainerTypeName = "PLATFORM";
                    break;
                  case "REF":
                    freightDetail.pfcDetailContainerTypeName = "REEFER";
                    break;
                  case "DRY":
                    freightDetail.pfcDetailContainerTypeName = "REEFER DRY";
                    break;
                  case "UC":
                    freightDetail.pfcDetailContainerTypeName = "UC";
                    break;
                  case "STD":
                    freightDetail.pfcDetailContainerTypeName = "NORMAL";
                    break;
                  default:
                    break;
                }
                freightDetail.pfcDetailCurrency = element["currencyCode"];
                freightDetail.pfcDetailPC = element["freightType"];
                freightDetail.pfcDetailPCTab = element["pcTab"];
                if (element["payerCode"] == "") {
                  freightDetail.pfcDetailPayer = "";
                  freightDetail.pfcDetailPayerName = "";
                  freightDetail.pfcDetailPayerIsPayer = "";
                  freightDetail.pfcDetailPayerIsValid = "";
                } else {
                  freightDetail.pfcDetailPayer = element["payerCode"];
                  freightDetail.pfcDetailPayerName = element["payerName"];
                  freightDetail.pfcDetailPayerIsPayer = element["payerIsPayer"];
                  freightDetail.pfcDetailPayerIsValid = element["payerIsValid"];

                  if(freightDetail.pfcDetailPayer != ""){
                    if (!listPyr.includes(freightDetail.pfcDetailPayer)) {
                      listPyr.push(freightDetail.pfcDetailPayer);
                    }
                  }
                }

                freightDetail.pfcDetailPlaceOfPayment =
                  element["placeOfPaymentCode"];
                freightDetail.pfcDetailPlaceOfPaymentName =
                  element["placeOfPaymentName"];

                freightDetail.pfcDetailD20 = 0;
                freightDetail.pfcDetailD40 = 0;
                freightDetail.pfcDetailD45 = 0;
                freightDetail.pfcDetailH20 = 0;
                freightDetail.pfcDetailH40 = 0;
                freightDetail.pfcDetailH45 = 0;

                if (element["D20"] != undefined) {
                  freightDetail.pfcDetailD20 = Number.parseFloat(
                    element["D20"]
                  );
                }

                if (element["D40"] != undefined) {
                  freightDetail.pfcDetailD40 = Number.parseFloat(
                    element["D40"]
                  );
                }

                if (element["D45"] != undefined) {
                  freightDetail.pfcDetailD45 = Number.parseFloat(
                    element["D45"]
                  );
                }

                if (element["H20"] != undefined) {
                  freightDetail.pfcDetailH20 = Number.parseFloat(
                    element["H20"]
                  );
                }

                if (element["H40"] != undefined) {
                  freightDetail.pfcDetailH40 = Number.parseFloat(
                    element["H40"]
                  );
                }

                if (element["H45"] != undefined) {
                  freightDetail.pfcDetailH45 = Number.parseFloat(
                    element["H45"]
                  );
                }

                freightDetail.pfcDetailOogDoorOpen = element["doorOpen"];
                if (freightDetail.pfcDetailOogDoorOpen == "N") {
                  freightDetail.pfcDetailOogDoorOpenName = "N/A";
                } else if (freightDetail.pfcDetailOogDoorOpen == "D") {
                  freightDetail.pfcDetailOogDoorOpenName = "Detached";
                } else if (freightDetail.pfcDetailOogDoorOpen == "B") {
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Both";
                } else if (freightDetail.pfcDetailOogDoorOpen == "L") {
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Left";
                } else if (freightDetail.pfcDetailOogDoorOpen == "R") {
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Right";
                }
                freightDetail.pfcDetailOogOH = element["oh"];
                freightDetail.pfcDetailOogOLB = element["olb"];
                freightDetail.pfcDetailOogOLF = element["olf"];
                freightDetail.pfcDetailOogOWL = element["owl"];
                freightDetail.pfcDetailOogOWR = element["owr"];
                freightDetail.pfcDetailOogUcH = element["uch"];
                freightDetail.pfcDetailOogUcL = element["ucl"];
                freightDetail.pfcDetailOogUcW = element["ucw"];

                // console.log(freightDetail);
                dataSurcharges.push(freightDetail);
              });
              console.log(listPyr);

              if (this.map.get(this.model.pfcContainerType) == undefined) {
                // console.log('#1');
                let indexPrepaid: number = 0;
                let indexCollect: number = 0;
                dataSurcharges.forEach((element) => {
                  if (element.pfcDetailPCTab == "P") {
                    indexPrepaid++;
                  }

                  if (element.pfcDetailPCTab == "C") {
                    indexCollect++;
                  }

                  if (element.pfcDetailPCTab == "P") {
                    element.pfcDetailSeqNo = indexPrepaid;
                    element.no = indexPrepaid;
                  }
                  if (element.pfcDetailPCTab == "C") {
                    element.pfcDetailSeqNo = indexCollect;
                    element.no = indexCollect;
                  }
                });
                this.map.set(this.model.pfcContainerType, dataSurcharges);
              } else {
                // console.log('#2');
                let data: FreightDetail[] = this.map.get(
                  this.model.pfcContainerType
                );
                // console.log('existing data ');
                // console.log(data);
                let indexPrepaid: number = 0;
                let indexCollect: number = 0;

                data.forEach((element) => {
                  if (element.pfcDetailPCTab == "P") {
                    indexPrepaid++;
                  }
                  if (element.pfcDetailPCTab == "C") {
                    indexCollect++;
                  }
                });

                let dataList: FreightDetail[] = [];
                data.forEach((element) => {
                  var x: FreightDetail = new FreightDetail();
                  x.pfcSurchargeId = element.pfcSurchargeId;
                  x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                  x.pfcDetailChargeName = element.pfcDetailChargeName;
                  x.pfcDetailContainerType = element.pfcDetailContainerType;
                  switch (x.pfcDetailContainerType) {
                    case "TNK":
                      x.pfcDetailContainerTypeName = "TANK";
                      break;
                    case "OPT":
                      x.pfcDetailContainerTypeName = "OPEN TOP";
                      break;
                    case "FLR":
                      x.pfcDetailContainerTypeName = "FLAT RACK";
                      break;
                    case "PLT":
                      x.pfcDetailContainerTypeName = "PLATFORM";
                      break;
                    case "REF":
                      x.pfcDetailContainerTypeName = "REEFER";
                      break;
                    case "DRY":
                      x.pfcDetailContainerTypeName = "REEFER DRY";
                      break;
                    case "UC":
                      x.pfcDetailContainerTypeName = "UC";
                      break;
                    case "STD":
                      x.pfcDetailContainerTypeName = "NORMAL";
                      break;
                    default:
                      break;
                  }
                  x.pfcDetailCurrency = element.pfcDetailCurrency;
                  x.pfcDetailPayer = element.pfcDetailPayer;
                  x.pfcDetailPayerName = element.pfcDetailPayerName;
                  x.pfcDetailPC = element.pfcDetailPC;
                  x.pfcDetailPCTab = element.pfcDetailPCTab;
                  x.pfcDetailSeqNo = element.pfcDetailSeqNo;

                  x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                  x.pfcDetailPlaceOfPaymentName =
                    element.pfcDetailPlaceOfPaymentName;
                  x.pfcDetailD20 = element.pfcDetailD20;
                  x.pfcDetailD40 = element.pfcDetailD40;
                  x.pfcDetailD45 = element.pfcDetailD45;
                  x.pfcDetailH20 = element.pfcDetailH20;
                  x.pfcDetailH40 = element.pfcDetailH40;
                  x.pfcDetailH45 = element.pfcDetailH45;
                  x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                  x.pfcDetailKT = element.pfcDetailKT;
                  x.pfcDetailM3 = element.pfcDetailM3;
                  x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                  x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                  if (x.pfcDetailOogDoorOpen == "N") {
                    x.pfcDetailOogDoorOpenName = "N/A";
                  } else if (x.pfcDetailOogDoorOpen == "D") {
                    x.pfcDetailOogDoorOpenName = "Detached";
                  } else if (x.pfcDetailOogDoorOpen == "B") {
                    x.pfcDetailOogDoorOpenName = "Tied Both";
                  } else if (x.pfcDetailOogDoorOpen == "L") {
                    x.pfcDetailOogDoorOpenName = "Tied Left";
                  } else if (x.pfcDetailOogDoorOpen == "R") {
                    x.pfcDetailOogDoorOpenName = "Tied Right";
                  }
                  x.pfcDetailOogOH = element.pfcDetailOogOH;
                  x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                  x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                  x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                  x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                  x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                  x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                  x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                  x.pfcDetailRT = element.pfcDetailRT;
                  dataList.push(x);
                });

                let surchargeList: FreightDetail[] = [];

                dataSurcharges.forEach((element) => {
                  //// console.log(element);

                  let snapCodeSurcharge: string = "";
                  snapCodeSurcharge += element.pfcDetailPC.trim();
                  snapCodeSurcharge += element.pfcDetailPCTab.trim();
                  snapCodeSurcharge += element.pfcDetailChargeCode.trim();
                  snapCodeSurcharge += element.pfcDetailCurrency.trim();
                  snapCodeSurcharge += element.pfcDetailPlaceOfPayment.trim();
                  snapCodeSurcharge += element.pfcDetailPayer.trim();
                  snapCodeSurcharge +=
                    element.pfcDetailKillSlot == null
                      ? "0"
                      : element.pfcDetailKillSlot.toString();
                  snapCodeSurcharge += element.pfcDetailOogDoorOpen.trim();
                  snapCodeSurcharge +=
                    element.pfcDetailOogOH == null
                      ? "0"
                      : element.pfcDetailOogOH.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogOWL == null
                      ? "0"
                      : element.pfcDetailOogOWL.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogOWR == null
                      ? "0"
                      : element.pfcDetailOogOWR.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogOLF == null
                      ? "0"
                      : element.pfcDetailOogOLF.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogOLB == null
                      ? "0"
                      : element.pfcDetailOogOLB.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogUcH == null
                      ? "0"
                      : element.pfcDetailOogUcH.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogUcL == null
                      ? "0"
                      : element.pfcDetailOogUcL.toString();
                  snapCodeSurcharge +=
                    element.pfcDetailOogUcW == null
                      ? "0"
                      : element.pfcDetailOogUcW.toString();

                  let chek: boolean = false;
                  dataList.forEach((el) => {
                    let snapCode: string = "";
                    snapCode += el.pfcDetailPC.trim();
                    snapCode += el.pfcDetailPCTab.trim();
                    snapCode += el.pfcDetailChargeCode.trim();
                    snapCode += el.pfcDetailCurrency.trim();
                    snapCode += el.pfcDetailPlaceOfPayment.trim();
                    snapCode += el.pfcDetailPayer.trim();
                    snapCode +=
                      el.pfcDetailKillSlot == null
                        ? "0"
                        : el.pfcDetailKillSlot.toString();
                    snapCode += el.pfcDetailOogDoorOpen.trim();
                    snapCode +=
                      el.pfcDetailOogOH == null
                        ? "0"
                        : el.pfcDetailOogOH.toString();
                    snapCode +=
                      el.pfcDetailOogOWL == null
                        ? "0"
                        : el.pfcDetailOogOWL.toString();
                    snapCode +=
                      el.pfcDetailOogOWR == null
                        ? "0"
                        : el.pfcDetailOogOWR.toString();
                    snapCode +=
                      el.pfcDetailOogOLF == null
                        ? "0"
                        : el.pfcDetailOogOLF.toString();
                    snapCode +=
                      el.pfcDetailOogOLB == null
                        ? "0"
                        : el.pfcDetailOogOLB.toString();
                    snapCode +=
                      el.pfcDetailOogUcH == null
                        ? "0"
                        : el.pfcDetailOogUcH.toString();
                    snapCode +=
                      el.pfcDetailOogUcL == null
                        ? "0"
                        : el.pfcDetailOogUcL.toString();
                    snapCode +=
                      el.pfcDetailOogUcW == null
                        ? "0"
                        : el.pfcDetailOogUcW.toString();

                    // console.log('** snapCodeSurcharge **');
                    // console.log(snapCodeSurcharge);

                    // console.log('** snapCode **');
                    // console.log(snapCode);

                    if (snapCodeSurcharge == snapCode) {
                      // console.log('#SAMA');
                      chek = true;
                      var idx = dataList.indexOf(el);
                      dataList[idx].pfcDetailChargeCode =
                        element.pfcDetailChargeCode;
                      dataList[idx].pfcDetailChargeName =
                        element.pfcDetailChargeName;
                      dataList[idx].pfcDetailCurrency =
                        element.pfcDetailCurrency;
                      dataList[idx].pfcDetailPayer = element.pfcDetailPayer;
                      dataList[idx].pfcDetailPayerName =
                        element.pfcDetailPayerName;
                      dataList[idx].pfcDetailPC = element.pfcDetailPC;
                      dataList[idx].pfcDetailPCTab = element.pfcDetailPCTab;
                      dataList[idx].pfcDetailPlaceOfPayment =
                        element.pfcDetailPlaceOfPayment;
                      dataList[idx].pfcDetailPlaceOfPaymentName =
                        element.pfcDetailPlaceOfPaymentName;
                      dataList[idx].pfcDetailD20 = element.pfcDetailD20;
                      dataList[idx].pfcDetailD40 = element.pfcDetailD40;
                      dataList[idx].pfcDetailD45 = element.pfcDetailD45;
                      dataList[idx].pfcDetailH20 = element.pfcDetailH20;
                      dataList[idx].pfcDetailH40 = element.pfcDetailH40;
                      dataList[idx].pfcDetailH45 = element.pfcDetailH45;
                      dataList[idx].pfcDetailKillSlot =
                        element.pfcDetailKillSlot;
                      dataList[idx].pfcDetailKT = element.pfcDetailKT;
                      dataList[idx].pfcDetailM3 = element.pfcDetailM3;
                      dataList[idx].pfcDetailMinCharge =
                        element.pfcDetailMinCharge;
                      dataList[idx].pfcDetailOogDoorOpen =
                        element.pfcDetailOogDoorOpen;
                      dataList[idx].pfcDetailOogOH = element.pfcDetailOogOH;
                      dataList[idx].pfcDetailOogOLB = element.pfcDetailOogOLB;
                      dataList[idx].pfcDetailOogOLF = element.pfcDetailOogOLF;
                      dataList[idx].pfcDetailOogOWL = element.pfcDetailOogOWL;
                      dataList[idx].pfcDetailOogOWR = element.pfcDetailOogOWR;
                      dataList[idx].pfcDetailOogUcH = element.pfcDetailOogUcH;
                      dataList[idx].pfcDetailOogUcW = element.pfcDetailOogUcW;
                      dataList[idx].pfcDetailOogUcL = element.pfcDetailOogUcL;
                      dataList[idx].pfcDetailRT = element.pfcDetailRT;
                    } else {
                      //data.push(element);
                      // console.log('#BEDA');
                    }
                  });

                  // console.log('**chek**');
                  // console.log(chek);

                  if (!chek) {
                    if (element.pfcDetailPCTab == "P") {
                      indexPrepaid++;
                      element.pfcDetailSeqNo = indexPrepaid;
                      element.no = indexPrepaid;
                    }

                    if (element.pfcDetailPCTab == "C") {
                      indexCollect++;
                      element.pfcDetailSeqNo = indexCollect;
                      element.no = indexCollect;
                    }
                    surchargeList.push(element);
                  }
                });

                let dets: FreightDetail[] = [];
                dataList.forEach((element) => {
                  dets.push(element);
                });
                surchargeList.forEach((element) => {
                  dets.push(element);
                });

                this.map.set(this.model.pfcContainerType, dets);
              }

              // console.log(this.map);
              this.gridApiPrepaid.setRowData([]);
              this.gridApiCollect.setRowData([]);
              //this.gridPrepaid.onClear();
              //this.gridCollect.onClear();

              var detailList: any[] = this.map.get(this.model.pfcContainerType);
              detailList.forEach((element) => {
                // console.log(element);
                var x: FreightDetail = new FreightDetail();
                x.pfcSurchargeId = element.pfcSurchargeId;
                x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                x.pfcDetailChargeName = element.pfcDetailChargeName;
                x.pfcDetailContainerType = element.pfcDetailContainerType;
                switch (x.pfcDetailContainerType) {
                  case "TNK":
                    x.pfcDetailContainerTypeName = "TANK";
                    break;
                  case "OPT":
                    x.pfcDetailContainerTypeName = "OPEN TOP";
                    break;
                  case "FLR":
                    x.pfcDetailContainerTypeName = "FLAT RACK";
                    break;
                  case "PLT":
                    x.pfcDetailContainerTypeName = "PLATFORM";
                    break;
                  case "REF":
                    x.pfcDetailContainerTypeName = "REEFER";
                    break;
                  case "DRY":
                    x.pfcDetailContainerTypeName = "REEFER DRY";
                    break;
                  case "UC":
                    x.pfcDetailContainerTypeName = "UC";
                    break;
                  case "STD":
                    x.pfcDetailContainerTypeName = "NORMAL";
                    break;
                  default:
                    break;
                }
                x.pfcDetailCopyTab = element.pfcDetailCopyTab;
                x.pfcDetailCurrency = element.pfcDetailCurrency;
                x.pfcDetailD20 = element.pfcDetailD20;
                x.pfcDetailD40 = element.pfcDetailD40;
                x.pfcDetailD45 = element.pfcDetailD45;
                x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
                x.pfcDetailH20 = element.pfcDetailH20;
                x.pfcDetailH40 = element.pfcDetailH40;
                x.pfcDetailH45 = element.pfcDetailH45;
                x.pfcDetailKT = element.pfcDetailKT;
                x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                x.pfcDetailM3 = element.pfcDetailM3;
                x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                if (x.pfcDetailOogDoorOpen == "N") {
                  x.pfcDetailOogDoorOpenName = "N/A";
                } else if (x.pfcDetailOogDoorOpen == "D") {
                  x.pfcDetailOogDoorOpenName = "Detached";
                } else if (x.pfcDetailOogDoorOpen == "B") {
                  x.pfcDetailOogDoorOpenName = "Tied Both";
                } else if (x.pfcDetailOogDoorOpen == "L") {
                  x.pfcDetailOogDoorOpenName = "Tied Left";
                } else if (x.pfcDetailOogDoorOpen == "R") {
                  x.pfcDetailOogDoorOpenName = "Tied Right";
                }
                x.pfcDetailOogOH = element.pfcDetailOogOH;
                x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                x.pfcDetailPC = element.pfcDetailPC;
                x.pfcDetailPCTab = element.pfcDetailPCTab;
                x.pfcDetailPayer = element.pfcDetailPayer;
                x.pfcDetailPayerName = element.pfcDetailPayerName;
                x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
                x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
                x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName =
                  element.pfcDetailPlaceOfPaymentName;
                x.pfcDetailRT = element.pfcDetailRT;
                x.pfcDetailSeqNo = element.pfcDetailSeqNo;
                x.pfcDetailStatus = element.pfcDetailStatus;
                x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

                if (x.pfcDetailPCTab == "P") {
                  //this.gridPrepaid.listStore.store.push(x);
                  //this.gridPrepaid.loadData();
                  this.gridApiPrepaid.updateRowData({
                    add: [JSON.parse(JSON.stringify(x))],
                  });
                }

                if (x.pfcDetailPCTab == "C") {
                  //this.gridCollect.listStore.store.push(x);
                  //this.gridCollect.loadData();
                  this.gridApiCollect.updateRowData({
                    add: [JSON.parse(JSON.stringify(x))],
                  });
                }

                this.calculateTotal();
              });
            } else {
              this.clearSurcharge();
              this.message(
                "information",
                "Information",
                resp.json()["message"],
                "okonly",
                { ok: "" }
              );
            }

            this.genericUtil.hideLoader();
          });
      } else {
        this.message(
          "information",
          "Information",
          "Cannot retrieve surcharge. Please fill in the compulsory fields and add main charges (OFT/FAI/FOF).",
          "okonly",
          { ok: "" }
        );
        this.genericUtil.hideLoader();
      }
    }
  }

  onTransmit() {
    this.message(
      "information",
      "Information",
      "Do you wish to transmit freight " + this.model.pfcRecordId + "?",
      "yesno",
      {
        yes: "this.transmitProcess()",
        no: "",
      }
    );
  }

  transmitProcess() {
    this.genericUtil.showLoader();
    let data: FreightManagement = new FreightManagement();
    data.pfcRecordId = this.model.pfcRecordId;
    data.pfcIsTransmitted = "Y";
    data.pfcUserTransmitted = this.cookieService.getName().toUpperCase();
    data.pfcDateTransmitted = this.genericUtil.getPSIDate();

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCFreightApplication/transmit",
        data
      )
      .subscribe((resp) => {
        // console.log(resp);
        this.genericUtil.hideLoader();
        if (resp.statusText == "OK") {
          this.appStatusLabel = ""; // '   ---   PENDING TRASMITTED   ---';
          this.lock = true;
          this.disableToolbarButtons = "nct,save,copy,update,delete,create";
          this.message(
            "information",
            "Information",
            "Transmitted Successfully.",
            "okonly",
            { ok: "" }
          );
        } else {
        }
      });
  }

  onClone() {
    this.message(
      "information",
      "Information",
      "Not yet implemented.",
      "okonly",
      { ok: "" }
    );
  }

  //action when user click on button new
  onDetailNew() {
    this.isUpdateDetail = false;

    // console.log('** on detail new **');
    this.modelDetail = new FreightDetail();
    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.pfcDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.pfcDetailCurrency
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.pfcDetailPlaceOfPayment
    );
    this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

    if (this.currentActiveTab == "prepaid") {
      //this.modelDetail.pfcDetailSeqNo = this.gridPrepaid.listStore.store.length + 1;

      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });
      this.modelDetail.pfcDetailSeqNo = rowData.length + 1;

      //worker for place of payment

      /*
      if (this.model.pfcLoadPortCode != null && this.model.pfcLoadPortCode != undefined && this.model.pfcLoadPortCode != '' &&
      this.model.pfcLoadPortCode != 'VNHCX' && this.model.pfcLoadPortCode != 'IDJKX' && this.model.pfcLoadPortCode != 'PKKHX' &&
      this.model.pfcLoadPortCode != 'AEDBX' && this.model.pfcLoadPortCode != 'PHMNX' && this.model.pfcLoadPortCode != 'MYPKX' &&
      this.model.pfcLoadPortCode != 'THBKX' && this.model.pfcLoadPortCode != 'CNXGX'){
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcLoadPortCode);
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue('');
      }
      */
      if (
        this.model.pfcLoadPortCode != null &&
        this.model.pfcLoadPortCode != undefined &&
        this.model.pfcLoadPortCode != "" &&
        !this.polName.includes("FREIGHT GROUP")
      ) {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue(
          this.model.pfcLoadPortCode
        );
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }

      //worker for payer
      // console.log('this.cookieService.getDefaultLocationCountry().toUpperCase() ==> ' + this.cookieService.getDefaultLocationCountry().toUpperCase());
      // console.log('this.model.pfcFreightPartyCountry.toUpperCase() ==> ' + this.model.pfcFreightPartyCountry.toUpperCase());

      if (
        this.cookieService.getDefaultLocationCountry().toUpperCase() ==
        this.model.pfcFreightPartyCountry.toUpperCase()
      ) {
        if (
          this.model.pfcFreightPartyId != null &&
          this.model.pfcFreightPartyId != undefined &&
          this.model.pfcFreightPartyId != ""
        ) {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
              // "/MasterCustomers/findByComboBoxControl/customerId={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
          );
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';

          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     // self.fpAcctCode = '';
          //   }else{
          //     self.modelDetail.pfcDetailPayer = callbackPayer.customerId;
          //     self.modelDetail.pfcDetailPayerName = callbackPayer.customerName;
          //   }
          // });
        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
          );
          // this.cbMaintenanceDetailPayer.setValue("");
          // this.fpAcctCode = '';
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
        }
        // this.fpPayer = this.fpAcctCode;

      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
        );
        this.cbMaintenanceDetailPayer.setValue("**");
        // this.fpAcctCode = '';
        this.fpPayer = "";
      }
    } else {
      //this.modelDetail.pfcDetailSeqNo = this.gridCollect.listStore.store.length + 1;
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });
      this.modelDetail.pfcDetailSeqNo = rowData.length + 1;

      //worker for place of payment
      /*
      if (this.model.pfcDischargePortCode != null && this.model.pfcDischargePortCode != undefined && this.model.pfcDischargePortCode != ''  &&
      this.model.pfcLoadPortCode != 'VNHCX' && this.model.pfcLoadPortCode != 'IDJKX' && this.model.pfcLoadPortCode != 'PKKHX' &&
      this.model.pfcLoadPortCode != 'AEDBX' && this.model.pfcLoadPortCode != 'PHMNX' && this.model.pfcLoadPortCode != 'MYPKX' &&
      this.model.pfcLoadPortCode != 'THBKX' && this.model.pfcLoadPortCode != 'CNXGX') {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcDischargePortCode);
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue('');
      }
      */
      if (
        this.model.pfcDischargePortCode != null &&
        this.model.pfcDischargePortCode != undefined &&
        this.model.pfcDischargePortCode != "" &&
        !this.podName.includes("FREIGHT GROUP")
      ) {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue(
          this.model.pfcDischargePortCode
        );
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }

      //worker for payer
      if (
        this.cookieService.getDefaultLocationCountry().toUpperCase() ==
        this.model.pfcFreightPartyCountry.toUpperCase()
      ) {
        if (
          this.model.pfcFreightPartyId != null &&
          this.model.pfcFreightPartyId != undefined &&
          this.model.pfcFreightPartyId != ""
        ) {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
          );
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     // self.fpAcctCode = '';
          //   }else{
          //     self.modelDetail.pfcDetailPayer = callbackPayer.customerId;
          //     self.modelDetail.pfcDetailPayerName = callbackPayer.customerName;
          //   }
          // });
        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
          );
          // this.cbMaintenanceDetailPayer.setValue("");
          // this.fpAcctCode='';
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
        }
        // this.fpPayer = this.fpAcctCode;

      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
        );
        this.cbMaintenanceDetailPayer.setValue("**");
        this.fpPayer = "";

        // this.fpAcctCode='';
      }
    }

    //container type worker
    this.modelDetail.pfcDetailContainerType = this.model.pfcContainerType;
    switch (this.modelDetail.pfcDetailContainerType) {
      case "STD":
        this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
        break;
      case "DRY":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
        break;
      case "REF":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER";
        break;
      case "TNK":
        this.modelDetail.pfcDetailContainerTypeName = "TANK";
        break;
      case "OPT":
        this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
        break;
      case "PLT":
        this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
        break;
      case "FLR":
        this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
        break;
      case "UC":
        this.modelDetail.pfcDetailContainerTypeName = "UC";
        break;
      default:
        break;
    }

    //OOG Control
    //Infer from selected container type
    console.log(
      "this.modelDetail.pfcDetailContainerType = " +
        this.modelDetail.pfcDetailContainerType
    );
    if (
      this.modelDetail.pfcDetailContainerType == "STD" ||
      this.modelDetail.pfcDetailContainerType == "DRY"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = false;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.pfcDetailContainerType == "REF" ||
      this.modelDetail.pfcDetailContainerType == "TNK"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.pfcDetailContainerType == "PLT" ||
      this.modelDetail.pfcDetailContainerType == "FLR"
    ) {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.pfcDetailContainerType == "OPT") {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.pfcDetailContainerType == "UC") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    }

    // console.log("this.lockOOGOOG = " + this.lockOOGOOG);
    // console.log("this.lockOOGDoorOpen = " + this.lockOOGDoorOpen);
    // console.log("this.lockOOGUC = " + this.lockOOGUC);

    //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('show');
    // this.fpPayer = this.fpAcctCode;

    this.genericUtil.showDialog(
      "freightNewChargeForm",
      "Freight Entry Screen",
      1200,
      500
    );
  }

  onDetailUpdate() {
    // console.log('** on detail update **')
    this.isErrorDetail = false;
    this.clearValidatorErrorDetail();

    //let x  = this.gridApiPrepaid.getSelectedRows()
    //console.log(x.length)

    if (
      this.gridApiPrepaid.getSelectedRows().length == 0 &&
      this.gridApiCollect.getSelectedRows().length == 0
    ) {
      this.message(
        "information",
        "information",
        "Please select a record to update.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else if (
      this.gridApiPrepaid.getSelectedRows().length > 1 ||
      this.gridApiCollect.getSelectedRows().length > 1
    ) {
      this.message(
        "information",
        "information",
        "Please only select one record to update.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      this.isUpdateDetail = true;
      let md = new FreightDetail();
      if (this.currentActiveTab == "prepaid") {
        md = this.gridApiPrepaid.getSelectedRows()[0];
      } else {
        md = this.gridApiCollect.getSelectedRows()[0];
      }

      this.modelDetail = new FreightDetail();
      this.modelDetail.pfcDetailChargeCode = md.pfcDetailChargeCode;
      this.modelDetail.pfcDetailChargeName = md.pfcDetailChargeName;
      this.modelDetail.pfcDetailContainerType = md.pfcDetailContainerType;
      switch (this.modelDetail.pfcDetailContainerType) {
        case "STD":
          this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          this.modelDetail.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          this.modelDetail.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      this.modelDetail.pfcDetailCurrency = md.pfcDetailCurrency;
      this.modelDetail.pfcDetailD20 = md.pfcDetailD20;
      this.modelDetail.pfcDetailD40 = md.pfcDetailD40;
      this.modelDetail.pfcDetailD45 = md.pfcDetailD45;
      this.modelDetail.pfcDetailFAIGroup = md.pfcDetailFAIGroup;
      this.modelDetail.pfcDetailH20 = md.pfcDetailH20;
      this.modelDetail.pfcDetailH40 = md.pfcDetailH40;
      this.modelDetail.pfcDetailH45 = md.pfcDetailH45;
      this.modelDetail.pfcDetailKT = md.pfcDetailKT;
      this.modelDetail.pfcDetailKillSlot = md.pfcDetailKillSlot;
      this.modelDetail.pfcDetailM3 = md.pfcDetailM3;
      this.modelDetail.pfcDetailMinCharge = md.pfcDetailMinCharge;
      this.modelDetail.pfcDetailOogDoorOpen = md.pfcDetailOogDoorOpen;
      if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
        this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
      } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
      } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
      }
      this.modelDetail.pfcDetailOogOH = md.pfcDetailOogOH;
      this.modelDetail.pfcDetailOogOLB = md.pfcDetailOogOLB;
      this.modelDetail.pfcDetailOogOLF = md.pfcDetailOogOLF;
      this.modelDetail.pfcDetailOogOWL = md.pfcDetailOogOWL;
      this.modelDetail.pfcDetailOogOWR = md.pfcDetailOogOWR;
      this.modelDetail.pfcDetailOogUcH = md.pfcDetailOogUcH;
      this.modelDetail.pfcDetailOogUcL = md.pfcDetailOogUcL;
      this.modelDetail.pfcDetailOogUcW = md.pfcDetailOogUcW;
      this.modelDetail.pfcDetailPC = md.pfcDetailPC;
      this.modelDetail.pfcDetailPCTab = md.pfcDetailPCTab;
      this.modelDetail.pfcDetailPayer = md.pfcDetailPayer;
      this.modelDetail.pfcDetailPayerName = md.pfcDetailPayerName;
      this.modelDetail.pfcDetailPlaceOfPayment = md.pfcDetailPlaceOfPayment;
      this.modelDetail.pfcDetailPlaceOfPaymentName =
        md.pfcDetailPlaceOfPaymentName;
      this.modelDetail.pfcDetailRT = md.pfcDetailRT;
      this.modelDetail.pfcDetailSeqNo = md.pfcDetailSeqNo;
      this.modelDetail.pfcDetailStatus = md.pfcDetailStatus;

      this.cbMaintenanceDetailCharge.setValue(
        this.modelDetail.pfcDetailChargeCode
      );
      this.cbMaintenanceDetailCurrency.setValue(
        this.modelDetail.pfcDetailCurrency
      );
      this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);

      this.cbMaintenanceDetailPlaceOfPayment.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenanceDetailPlaceOfPayment.setValue(
        this.modelDetail.pfcDetailPlaceOfPayment
      );

      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
      );
      // this.fpAcctCode = '';
      this.fpPayer = '';
      this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

      //OOG Control
      //Infer from selected container type
      if (
        this.modelDetail.pfcDetailContainerType == "STD" ||
        this.modelDetail.pfcDetailContainerType == "DRY"
      ) {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = false;
        this.lockOOGUC = true;
      } else if (
        this.modelDetail.pfcDetailContainerType == "REF" ||
        this.modelDetail.pfcDetailContainerType == "TNK"
      ) {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (
        this.modelDetail.pfcDetailContainerType == "PLT" ||
        this.modelDetail.pfcDetailContainerType == "FLR"
      ) {
        this.lockOOGOOG = false;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (this.modelDetail.pfcDetailContainerType == "OPT") {
        this.lockOOGOOG = false;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (this.modelDetail.pfcDetailContainerType == "UC") {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = false;
      }

      this.genericUtil.showDialog(
        "freightNewChargeForm",
        "Freight Entry Screen",
        1200,
        500
      );
    }
    /*
    if (this.gridPrepaid.getSelectedValues().length == 0 && this.gridCollect.getSelectedValues().length == 0) {
      this.message('information', 'information', 'Please select a record to update.', 'okonly', {ok: 'this.loading=false;'})
    } else  if (this.gridPrepaid.getSelectedValues().length > 1 || this.gridCollect.getSelectedValues().length > 1) {
      //console.log("test")
      this.message("information", "information", "Please only select one record to update.", "okonly", {ok: "this.loading=false;"})
    } else {

      //set update flag to true
      this.isUpdateDetail = true;
      let md = new FreightDetail();
      if (this.currentActiveTab == 'prepaid') {
        md = this.gridPrepaid.getSelectedValues()[0];
      } else {
        md = this.gridCollect.getSelectedValues()[0];
      }

      // console.log(md)
      this.modelDetail = new FreightDetail();
      this.modelDetail.pfcDetailChargeCode = md.pfcDetailChargeCode;
      this.modelDetail.pfcDetailChargeName = md.pfcDetailChargeName;
      this.modelDetail.pfcDetailContainerType = md.pfcDetailContainerType;
      switch (this.modelDetail.pfcDetailContainerType) {
        case 'STD':
          this.modelDetail.pfcDetailContainerTypeName = 'NORMAL';
          break;
        case 'DRY':
          this.modelDetail.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'REF':
          this.modelDetail.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'TNK':
          this.modelDetail.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          this.modelDetail.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'PLT':
          this.modelDetail.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'FLR':
          this.modelDetail.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'UC':
          this.modelDetail.pfcDetailContainerTypeName = 'UC';
          break;
        default:
          break;
      }
      this.modelDetail.pfcDetailCurrency = md.pfcDetailCurrency;
      this.modelDetail.pfcDetailD20 = md.pfcDetailD20;
      this.modelDetail.pfcDetailD40 = md.pfcDetailD40;
      this.modelDetail.pfcDetailD45 = md.pfcDetailD45;
      this.modelDetail.pfcDetailFAIGroup = md.pfcDetailFAIGroup;
      this.modelDetail.pfcDetailH20 = md.pfcDetailH20;
      this.modelDetail.pfcDetailH40 = md.pfcDetailH40;
      this.modelDetail.pfcDetailH45 = md.pfcDetailH45;
      this.modelDetail.pfcDetailKT = md.pfcDetailKT;
      this.modelDetail.pfcDetailKillSlot = md.pfcDetailKillSlot;
      this.modelDetail.pfcDetailM3 = md.pfcDetailM3;
      this.modelDetail.pfcDetailMinCharge = md.pfcDetailMinCharge;
      this.modelDetail.pfcDetailOogDoorOpen = md.pfcDetailOogDoorOpen;
      if(this.modelDetail.pfcDetailOogDoorOpen == 'N'){
        this.modelDetail.pfcDetailOogDoorOpenName = 'N/A';
      }else if(this.modelDetail.pfcDetailOogDoorOpen == 'D'){
        this.modelDetail.pfcDetailOogDoorOpenName = 'Detached';
      }else if(this.modelDetail.pfcDetailOogDoorOpen == 'B'){
        this.modelDetail.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(this.modelDetail.pfcDetailOogDoorOpen == 'L'){
        this.modelDetail.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(this.modelDetail.pfcDetailOogDoorOpen == 'R'){
        this.modelDetail.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      this.modelDetail.pfcDetailOogOH = md.pfcDetailOogOH;
      this.modelDetail.pfcDetailOogOLB = md.pfcDetailOogOLB;
      this.modelDetail.pfcDetailOogOLF = md.pfcDetailOogOLF;
      this.modelDetail.pfcDetailOogOWL = md.pfcDetailOogOWL;
      this.modelDetail.pfcDetailOogOWR = md.pfcDetailOogOWR;
      this.modelDetail.pfcDetailOogUcH = md.pfcDetailOogUcH;
      this.modelDetail.pfcDetailOogUcL = md.pfcDetailOogUcL;
      this.modelDetail.pfcDetailOogUcW = md.pfcDetailOogUcW;
      this.modelDetail.pfcDetailPC = md.pfcDetailPC;
      this.modelDetail.pfcDetailPCTab = md.pfcDetailPCTab;
      this.modelDetail.pfcDetailPayer = md.pfcDetailPayer;
      this.modelDetail.pfcDetailPayerName = md.pfcDetailPayerName;
      this.modelDetail.pfcDetailPlaceOfPayment = md.pfcDetailPlaceOfPayment;
      this.modelDetail.pfcDetailPlaceOfPaymentName = md.pfcDetailPlaceOfPaymentName;
      this.modelDetail.pfcDetailRT = md.pfcDetailRT;
      this.modelDetail.pfcDetailSeqNo = md.pfcDetailSeqNo;
      this.modelDetail.pfcDetailStatus = md.pfcDetailStatus;

      this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
      this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailCurrency);
      this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);

      this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
      this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);

      this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
      this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

      //OOG Control
      //Infer from selected container type
      if (this.modelDetail.pfcDetailContainerType == 'STD' || this.modelDetail.pfcDetailContainerType == 'DRY' || this.modelDetail.pfcDetailContainerType == 'UC') {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = false;
        this.lockOOGUC = false;
      } else if (this.modelDetail.pfcDetailContainerType == 'REF' || this.modelDetail.pfcDetailContainerType == 'TNK') {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = false;
      } else if (this.modelDetail.pfcDetailContainerType == 'OPT' || this.modelDetail.pfcDetailContainerType == 'PLT' || this.modelDetail.pfcDetailContainerType == 'FLR') {
        this.lockOOGOOG = false;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = false;
      }

      this.genericUtil.showDialog('freightNewChargeForm', 'Freight Entry Screen', 1200, 500)

    }
    */
  }

  onDetailRemove() {
    // console.log('** on detail remove **');
    if (this.currentActiveTab == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Prepaid Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }
      if (this.gridApiPrepaid.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to delete.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to delete this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailRemovePrepaid();",
            no: "this.gridApiPrepaid.deselectAll();",
          }
        );
      }
      /*
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message('information', 'information', 'Please select an entry to delete.', 'okonly', {ok: 'this.loading=false;'})
      } else {
        this.message('information', 'Information', 'Are you sure you want to delete this charge(s)? ', 'yesno', {
          yes: 'this.onDetailRemovePrepaid();',
          no: 'this.gridPrepaid.clearSelectedValues();'
        });
      }
      */
    }

    if (this.currentActiveTab == "collect") {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Collect Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }
      if (this.gridApiCollect.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to delete.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to delete this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailRemoveCollect();",
            no: "this.gridApiCollect.deselectAll();",
          }
        );
      }
    }
  }

  onDetailRemovePrepaid() {
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach((element) => {
      if (element.pfcDetailPCTab == "C") {
        collectList.push(element);
      }
    });

    var selectedData = this.gridApiPrepaid.getSelectedRows();
    this.gridApiPrepaid.updateRowData({ remove: selectedData });

    let idx = 1;
    var itemsToUpdate = [];
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx;
      x.no = idx;
      itemsToUpdate.push(x);
      prepaidList.push(x);
      idx++;
    });
    this.gridApiPrepaid.updateRowData({ update: itemsToUpdate });

    var self = this;
    setTimeout(() => {
      let dets: FreightDetail[] = [];
      prepaidList.forEach((element) => {
        dets.push(element);
      });
      collectList.forEach((element) => {
        dets.push(element);
      });
      self.map.set(self.model.pfcContainerType, dets);

      self.gridApiPrepaid.deselectAll();

      //check Message "Main Charge is missing."
      let mapFAIGroup = new Map<String,FreightDetail[]>();
      let mapFAI = new Map<String,FreightDetail[]>();
      prepaidList.forEach((element) => {
          var key:String =
            element.pfcDetailPCTab + ";;" +
            element.pfcDetailContainerType + ";;" +
            element.pfcDetailCurrency + ";;" +
            element.pfcDetailPlaceOfPayment + ";;" +
            element.pfcDetailPayer + ";;" +
            element.pfcDetailPC + ";;" +
            (element.pfcDetailOogOH + element.pfcDetailOogOLB + element.pfcDetailOogOLF + element.pfcDetailOogOWL + element.pfcDetailOogOWR) + ";;" +
            element.pfcDetailOogDoorOpen + ";;" +
            (element.pfcDetailOogUcH + element.pfcDetailOogUcL + element.pfcDetailOogUcW) + ";;" +
            element.pfcDetailKillSlot;


          if(mapFAIGroup.get(key) == null){
            let detsx: FreightDetail[] = [];
            detsx.push(element);
            mapFAIGroup.set(key,detsx);
          }else{
            mapFAIGroup.get(key).push(element);
          }
      })

      mapFAIGroup.forEach((value: FreightDetail[], key: string) => {

        value.forEach(element => {

          if(element.pfcDetailFAIGroup == 'Y'){

            if(mapFAI.get(key) == null){
              let detsx: FreightDetail[] = mapFAIGroup.get(key)
              mapFAI.set(key,detsx);
            }else{
              //mapFAI.get(key).push(element);
            }

          }

        });
      });

      if (this.currentActiveTab == "prepaid") {
        this.isFAIErrorPrepaid = false;
        this.messagesFAIErrorPrepaid = "";
      }

      let isMainChargeExistPrepaid: boolean = true;

      mapFAI.forEach((value: FreightDetail[], key: string) => {
        let countMain = 0;
        let countFAI = 0;

        value.forEach((el: FreightDetail) => {

          if (this.currentActiveTab == "prepaid") {
            if (el.pfcDetailPCTab == "P") {
                countFAI = countFAI + 1;
                if(el.pfcDetailChargeCode == 'OFT' || el.pfcDetailChargeCode == 'FOF'){
                  countMain = countMain + 1;
                }
            }
          }

        })

        if (this.currentActiveTab == "prepaid") {
          if (countFAI > 0) {
            if (countMain == 0) {
              isMainChargeExistPrepaid = false;
            }
          }
        }

      })

      if (this.currentActiveTab == "prepaid") {
        if (isMainChargeExistPrepaid) {
          this.isFAIErrorPrepaid = false;
          this.messagesFAIErrorPrepaid = "";
        } else {
          this.isFAIErrorPrepaid = true;
          this.messagesFAIErrorPrepaid = "Main Charge is missing";
        }
      }

      // Hitung TOTAL
      self.calculateTotal();
    }, 10);

    // console.log('** on detail remove prepaid **');
    // console.log('this.model.pfcContainerType ==> ' + this.model.pfcContainerType);
    /*
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'C') {
        collectList.push(element);
      }
    });

    this.gridPrepaid.getSelectedValues().forEach(element=> {
      this.gridPrepaid.listStore.removeData(element);
    });

    let index = 1;
    this.gridPrepaid.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index;
      element.no = index;

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (element.pfcDetailContainerType) {
        case 'STD':
          element.pfcDetailContainerTypeName = 'NORMAL';
          break;
        case 'DRY':
          element.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'REF':
          element.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'TNK':
          element.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          element.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'PLT':
          element.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'FLR':
          element.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'UC':
          element.pfcDetailContainerTypeName = 'UC';
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = index;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.no = index;

      prepaidList.push(x);
      index++;
    });


    var self = this;
    setTimeout(() => {

      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.pfcContainerType, dets);
      self.gridPrepaid.clearSelectedValues();
      // console.log(self.map);

      if(self.gridPrepaid.listStore.store.length == 0){
        self.gridPrepaid.onClear()
      }

      self.gridPrepaid.loadData();
      self.gridCollect.loadData();

    }, 10);
    console.log("update")

     // TOTAL Prepaid
    this.totD20Prepaid = 0;
    this.totD40Prepaid = 0;
    this.totD45Prepaid = 0;
    this.totH20Prepaid = 0;
    this.totH40Prepaid = 0;
    this.totH45Prepaid = 0;

    this.gridPrepaid.listStore.store.forEach(el=>{
      console.log(el.pfcDetailD20)
      this.totD20Prepaid += el.pfcDetailD20,
      this.totD40Prepaid += el.pfcDetailD40,
      this.totD45Prepaid += el.pfcDetailD45,
      this.totH20Prepaid += el.pfcDetailH20,
      this.totH40Prepaid += el.pfcDetailH40,
      this.totH45Prepaid += el.pfcDetailH45
    })

    console.log("D20")
    console.log(this.totD20Prepaid)

    // console.log('** after update **');
    // console.log(this.map);

    this.gridPrepaid.total.forEach(element=>{
      element["PFCDETAILD20"] = this.totD20Prepaid,
      element["PFCDETAILD40"] = this.totD40Prepaid,
      element["PFCDETAILD45"] = this.totD45Prepaid,
      element["PFCDETAILH20"] = this.totH20Prepaid,
      element["PFCDETAILH40"] = this.totH40Prepaid,
      element["PFCDETAILH45"] = this.totH45Prepaid
    })
    */
  }

  onDetailRemoveCollect() {
    // console.log('** on detail remove collect **');
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach((element) => {
      if (element.pfcDetailPCTab == "P") {
        prepaidList.push(element);
      }
    });

    var selectedData = this.gridApiCollect.getSelectedRows();
    this.gridApiCollect.updateRowData({ remove: selectedData });

    let idx = 1;
    var itemsToUpdate = [];
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx;
      x.no = idx;
      itemsToUpdate.push(x);
      collectList.push(x);
      idx++;
    });
    this.gridApiCollect.updateRowData({ update: itemsToUpdate });

    var self = this;
    setTimeout(() => {
      let dets: FreightDetail[] = [];
      prepaidList.forEach((element) => {
        dets.push(element);
      });
      collectList.forEach((element) => {
        dets.push(element);
      });
      self.map.set(self.model.pfcContainerType, dets);

      self.gridApiCollect.deselectAll();

      //check Message "Main Charge is missing."
      let mapFAIGroup = new Map<String,FreightDetail[]>();
      let mapFAI = new Map<String,FreightDetail[]>();
      collectList.forEach((element) => {
          var key:String =
            element.pfcDetailPCTab + ";;" +
            element.pfcDetailContainerType + ";;" +
            element.pfcDetailCurrency + ";;" +
            element.pfcDetailPlaceOfPayment + ";;" +
            element.pfcDetailPayer + ";;" +
            element.pfcDetailPC + ";;" +
            (element.pfcDetailOogOH + element.pfcDetailOogOLB + element.pfcDetailOogOLF + element.pfcDetailOogOWL + element.pfcDetailOogOWR) + ";;" +
            element.pfcDetailOogDoorOpen + ";;" +
            (element.pfcDetailOogUcH + element.pfcDetailOogUcL + element.pfcDetailOogUcW) + ";;" +
            element.pfcDetailKillSlot;


          if(mapFAIGroup.get(key) == null){
            let detsx: FreightDetail[] = [];
            detsx.push(element);
            mapFAIGroup.set(key,detsx);
          }else{
            mapFAIGroup.get(key).push(element);
          }
      })

      mapFAIGroup.forEach((value: FreightDetail[], key: string) => {

        value.forEach(element => {

          if(element.pfcDetailFAIGroup == 'Y'){

            if(mapFAI.get(key) == null){
              let detsx: FreightDetail[] = mapFAIGroup.get(key)
              mapFAI.set(key,detsx);
            }else{
              //mapFAI.get(key).push(element);
            }

          }

        });
      });


      if (this.currentActiveTab == "collect") {
        this.isFAIErrorCollect = false;
        this.messagesFAIErrorCollect = "";
      }

      let isMainChargeExistCollect: boolean = true;

      mapFAI.forEach((value: FreightDetail[], key: string) => {
        let countMain = 0;
        let countFAI = 0;

        value.forEach((el: FreightDetail) => {


            if (el.pfcDetailPCTab == "C") {
                countFAI = countFAI + 1;
                if(el.pfcDetailChargeCode == 'OFT' || el.pfcDetailChargeCode == 'FOF'){
                  countMain = countMain + 1;
                }
            }

        })

        if (this.currentActiveTab == "collect") {
          if (countFAI > 0) {
            if (countMain == 0) {
              isMainChargeExistCollect = false;
            }
          }
        }

      })

      if (this.currentActiveTab == "collect") {
        if (isMainChargeExistCollect) {
          this.isFAIErrorCollect = false;
          this.messagesFAIErrorCollect = "";
        } else {
          this.isFAIErrorCollect = true;
          this.messagesFAIErrorCollect = "Main Charge is missing";
        }
      }

      // Hitung TOTAL
      self.calculateTotal();
    }, 10);

    /*
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'P') {
        prepaidList.push(element);
      }
    });

    this.gridCollect.getSelectedValues().forEach(element=> {
      this.gridCollect.listStore.removeData(element);
    });

    let index = 1;
    this.gridCollect.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index;
      element.no = index;

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (element.pfcDetailContainerType) {
        case 'STD':
          element.pfcDetailContainerTypeName = 'NORMAL';
          break;
        case 'DRY':
          element.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'REF':
          element.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'TNK':
          element.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          element.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'PLT':
          element.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'FLR':
          element.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'UC':
          element.pfcDetailContainerTypeName = 'UC';
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = index;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.no = index;

      collectList.push(x);
      index++;
    });

    var self = this;
    setTimeout(() => {

      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.pfcContainerType, dets);
      self.gridCollect.clearSelectedValues();
      // console.log(self.map);

      if(self.gridCollect.listStore.store.length == 0){
        self.gridCollect.onClear()
      }

      self.gridPrepaid.loadData();
      self.gridCollect.loadData();

    }, 10);

     // TOTAL Collect
     this.totD20Collect = 0;
     this.totD40Collect = 0;
     this.totD45Collect = 0;
     this.totH20Collect = 0;
     this.totH40Collect = 0;
     this.totH45Collect = 0;

     this.gridCollect.listStore.store.forEach(el=>{
       console.log(el.pfcDetailD20)
       this.totD20Collect += el.pfcDetailD20,
       this.totD40Collect += el.pfcDetailD40,
       this.totD45Collect += el.pfcDetailD45,
       this.totH20Collect += el.pfcDetailH20,
       this.totH40Collect += el.pfcDetailH40,
       this.totH45Collect += el.pfcDetailH45
     })

     console.log("D20")
     console.log(this.totD20Collect)

     // console.log('** after update **');
     // console.log(this.map);

     this.gridCollect.total.forEach(element=>{
       element["PFCDETAILD20"] = this.totD20Collect,
       element["PFCDETAILD40"] = this.totD40Collect,
       element["PFCDETAILD45"] = this.totD45Collect,
       element["PFCDETAILH20"] = this.totH20Collect,
       element["PFCDETAILH40"] = this.totH40Collect,
       element["PFCDETAILH45"] = this.totH45Collect
     })
     */
  }

  onDetailCopy() {
    if (this.currentActiveTab == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Prepaid Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }

      if (this.gridApiPrepaid.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to copy prepaid.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to copy this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailCopyPrepaid();",
            no: "this.gridApiPrepaid.deselectAll();this.loading = false;",
          }
        );
      }
    }

    if (this.currentActiveTab == "collect") {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Collect Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }

      if (this.gridApiCollect.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to copy collect.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to copy this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailCopyCollect();",
            no: "this.gridApiCollect.deselectAll();this.loading = false;",
          }
        );
      }
    }

    /*
    if (this.currentActiveTab == 'prepaid') {
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message('information', 'information', 'Please select an entry to copy prepaid.', 'okonly', {ok: 'this.loading=false;'})
      } else {
        this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
          yes: 'this.onDetailCopyPrepaid();',
          no: 'this.gridPrepaid.clearSelectedValues();this.loading = false;'
        });
      }
    }

    if (this.currentActiveTab == 'collect') {
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message('information', 'information', 'Please select an entry to copy collect.', 'okonly', {ok: 'this.loading=false;'})
      } else {
        this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
          yes: 'this.onDetailCopyCollect();',
          no: 'this.gridCollect.clearSelectedValues();this.loading = false;'
        });
      }
    }
    */
  }

  onDetailCopyPrepaid() {
    console.log("** on detail copy prepaid **");
    let mp: Map<any, any> = this.onDetailCopyPrepaidCheckDuplicate();

    mp.forEach((value: string, key: boolean) => {
      if (key) {
        this.message(
          "information",
          "information",
          "Duplicate " + value + " charge code detected.",
          "okonly",
          { ok: "this.gridApiPrepaid.deselectAll();" }
        );
      } else {
        this.onDetailCopyPrepaidProcess();
        this.gridApiPrepaid.deselectAll();
        this.onDetailAfterCopy(true);
      }
    });

    /*
    let mp:Map<any,any> = this.onDetailCopyPrepaidCheckDuplicate()
    // console.log(mp);

    mp.forEach((value: string, key: boolean) => {
      // console.log(key);
      if(key){
        this.message('information', 'information', 'Duplicate ' + value + ' charge code detected.', 'okonly', {ok: 'this.gridPrepaid.clearSelectedValues();'});
      }else{
        this.onDetailCopyPrepaidProcess();
        this.gridPrepaid.clearSelectedValues();
        this.onDetailAfterCopy(true);
      }
    });
    */
  }

  onDetailCopyPrepaidCheckDuplicate(
    dataPrepaid?: FreightDetail
  ): Map<any, any> {
    console.log("** on detail copy prepaid check duplicate **");
    // console.log(dataPrepaid);

    let mp = new Map();

    let isErrorDetail: boolean = false;
    let chargeDuplicate: string = "";

    if (dataPrepaid != undefined) {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
      x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.pfcDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.pfcDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.pfcDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
      x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
      x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
      x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
      x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
      x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
      x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
      x.pfcDetailKT = dataPrepaid.pfcDetailKT;
      x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
      x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
      x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
      x.pfcDetailPC = dataPrepaid.pfcDetailPC;
      x.pfcDetailPCTab = "C";
      x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
      x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataPrepaid.pfcDetailRT;
      x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
      x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy("collect", x);
      if (isErrorDetail) {
        chargeDuplicate = dataPrepaid.pfcDetailChargeCode.toUpperCase().trim();
      }
    } else {
      this.gridApiPrepaid.getSelectedRows().forEach((element) => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.pfcDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.pfcDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.pfcDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if (x.pfcDetailOogDoorOpen == "N") {
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "C";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if (isErrorDetail == false) {
          isErrorDetail = this.checkDuplicateCopy("collect", x);
          if (isErrorDetail) {
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });
    }

    /*
    if(dataPrepaid != undefined){

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
      x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case 'TNK':
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'FLR':
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'PLT':
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'REF':
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'DRY':
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'UC':
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case 'STD':
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
      x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
      x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
      x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
      x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
      x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
      x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
      x.pfcDetailKT = dataPrepaid.pfcDetailKT;
      x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
      x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
      x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
      x.pfcDetailPC = dataPrepaid.pfcDetailPC;
      x.pfcDetailPCTab = 'C';
      x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
      x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataPrepaid.pfcDetailRT;
      x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
      x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy('collect',x);
      if(isErrorDetail){
        chargeDuplicate = dataPrepaid.pfcDetailChargeCode.toUpperCase().trim();
      }

    }else{
      this.gridPrepaid.getSelectedValues().forEach(element=> {

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case 'TNK':
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case 'OPT':
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case 'FLR':
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case 'PLT':
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case 'REF':
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case 'DRY':
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case 'UC':
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case 'STD':
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == 'N'){
          x.pfcDetailOogDoorOpenName = 'N/A';
        }else if(x.pfcDetailOogDoorOpen == 'D'){
          x.pfcDetailOogDoorOpenName = 'Detached';
        }else if(x.pfcDetailOogDoorOpen == 'B'){
          x.pfcDetailOogDoorOpenName = 'Tied Both';
        }else if(x.pfcDetailOogDoorOpen == 'L'){
          x.pfcDetailOogDoorOpenName = 'Tied Left';
        }else if(x.pfcDetailOogDoorOpen == 'R'){
          x.pfcDetailOogDoorOpenName = 'Tied Right';
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = 'C';
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if(isErrorDetail == false){
          isErrorDetail = this.checkDuplicateCopy('collect',x);
          if(isErrorDetail){
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });

    }
    */

    // console.log('isErrorDetail => ' + isErrorDetail);

    mp.set(isErrorDetail, chargeDuplicate);
    return mp;
  }

  onDetailCopyPrepaidProcess(dataPrepaid?: FreightDetail) {
    console.log("** on detail copy prepaid process **");

    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach((element) => {
      if (element.pfcDetailPCTab == "P") {
        prepaidList.push(element);
      }
    });

    if (dataPrepaid == undefined) {
      this.gridApiPrepaid.getSelectedRows().forEach((element) => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.pfcDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.pfcDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.pfcDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if (x.pfcDetailOogDoorOpen == "N") {
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "C";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
        x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        this.gridApiCollect.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      });
    } else {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
      x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.pfcDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.pfcDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.pfcDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
      x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
      x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
      x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
      x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
      x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
      x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
      x.pfcDetailKT = dataPrepaid.pfcDetailKT;
      x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
      x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
      x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
      x.pfcDetailPC = dataPrepaid.pfcDetailPC;
      x.pfcDetailPCTab = "C";
      x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
      x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
      x.pfcDetailPayerIsPayer = dataPrepaid.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = dataPrepaid.pfcDetailPayerIsValid;
      x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataPrepaid.pfcDetailRT;
      x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
      x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

      this.gridApiCollect.updateRowData({
        add: [JSON.parse(JSON.stringify(x))],
      });
    }

    let idx = 1;
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx;
      x.no = idx;
      collectList.push(x);
      idx++;
    });

    let dets: FreightDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.pfcContainerType, dets);

    // GOS-831 incorrect charge sequence captured when using [Copy] function
    this.rowDataCollect = collectList;

    //check Message "Main Charge is missing."
    let mapFAIGroup = new Map<String,FreightDetail[]>();
    let mapFAI = new Map<String,FreightDetail[]>();
    collectList.forEach((element) => {
        var key:String =
          element.pfcDetailPCTab + ";;" +
          element.pfcDetailContainerType + ";;" +
          element.pfcDetailCurrency + ";;" +
          element.pfcDetailPlaceOfPayment + ";;" +
          element.pfcDetailPayer + ";;" +
          element.pfcDetailPC + ";;" +
          (element.pfcDetailOogOH + element.pfcDetailOogOLB + element.pfcDetailOogOLF + element.pfcDetailOogOWL + element.pfcDetailOogOWR) + ";;" +
          element.pfcDetailOogDoorOpen + ";;" +
          (element.pfcDetailOogUcH + element.pfcDetailOogUcL + element.pfcDetailOogUcW) + ";;" +
          element.pfcDetailKillSlot;


        if(mapFAIGroup.get(key) == null){
          let detsx: FreightDetail[] = [];
          detsx.push(element);
          mapFAIGroup.set(key,detsx);
        }else{
          mapFAIGroup.get(key).push(element);
        }
    })

    mapFAIGroup.forEach((value: FreightDetail[], key: string) => {

      value.forEach(element => {

        if(element.pfcDetailFAIGroup == 'Y'){

          if(mapFAI.get(key) == null){
            let detsx: FreightDetail[] = mapFAIGroup.get(key)
            mapFAI.set(key,detsx);
          }else{
            //mapFAI.get(key).push(element);
          }

        }

      });
    });


    //if (this.currentActiveTab == "collect") {
      this.isFAIErrorCollect = false;
      this.messagesFAIErrorCollect = "";
    //}

    let isMainChargeExistCollect: boolean = true;

    mapFAI.forEach((value: FreightDetail[], key: string) => {
      let countMain = 0;
      let countFAI = 0;

      value.forEach((el: FreightDetail) => {


          if (el.pfcDetailPCTab == "C") {
              countFAI = countFAI + 1;
              if(el.pfcDetailChargeCode == 'OFT' || el.pfcDetailChargeCode == 'FOF'){
                countMain = countMain + 1;
              }
          }

      })

      //if (this.currentActiveTab == "collect") {
        if (countFAI > 0) {
          if (countMain == 0) {
            isMainChargeExistCollect = false;
          }
        }
      //}

    })

    //if (this.currentActiveTab == "collect") {
      if (isMainChargeExistCollect) {
        this.isFAIErrorCollect = false;
        this.messagesFAIErrorCollect = "";
      } else {
        this.isFAIErrorCollect = true;
        this.messagesFAIErrorCollect = "Main Charge is missing";
      }
    //}

    // Hitung TOTAL
    this.calculateTotal();

    /*
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'P') {
        prepaidList.push(element);
      }
    });

    if (dataPrepaid == undefined) {
      this.gridPrepaid.getSelectedValues().forEach(element=> {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case 'TNK':
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case 'OPT':
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case 'FLR':
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case 'PLT':
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case 'REF':
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case 'DRY':
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case 'UC':
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case 'STD':
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == 'N'){
          x.pfcDetailOogDoorOpenName = 'N/A';
        }else if(x.pfcDetailOogDoorOpen == 'D'){
          x.pfcDetailOogDoorOpenName = 'Detached';
        }else if(x.pfcDetailOogDoorOpen == 'B'){
          x.pfcDetailOogDoorOpenName = 'Tied Both';
        }else if(x.pfcDetailOogDoorOpen == 'L'){
          x.pfcDetailOogDoorOpenName = 'Tied Left';
        }else if(x.pfcDetailOogDoorOpen == 'R'){
          x.pfcDetailOogDoorOpenName = 'Tied Right';
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = 'C';
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        this.gridCollect.listStore.addData(x);
      });
    } else {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
      x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case 'TNK':
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'FLR':
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'PLT':
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'REF':
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'DRY':
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'UC':
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case 'STD':
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
      x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
      x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
      x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
      x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
      x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
      x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
      x.pfcDetailKT = dataPrepaid.pfcDetailKT;
      x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
      x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
      x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
      x.pfcDetailPC = dataPrepaid.pfcDetailPC;
      x.pfcDetailPCTab = 'C';
      x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
      x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataPrepaid.pfcDetailRT;
      x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
      x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

      this.gridCollect.listStore.addData(x);
    }

    let index = 1;
    this.gridCollect.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (x.pfcDetailContainerType) {
        case 'TANK':
          x.pfcDetailContainerType = 'TNK';
          break;
        case 'OPEN TOP':
          x.pfcDetailContainerType = 'OPT';
          break;
        case 'FLAT RACK':
          x.pfcDetailContainerType = 'FLR';
          break;
        case 'PLATFORM':
          x.pfcDetailContainerType = 'PLT';
          break;
        case 'REEFER':
          x.pfcDetailContainerType = 'REF';
          break;
        case 'REEFER DRY':
          x.pfcDetailContainerType = 'DRY';
          break;
        case 'UC':
          x.pfcDetailContainerType = 'UC';
          break;
        case 'NORMAL':
          x.pfcDetailContainerType = 'STD';
          break;
        default:
          break;
      }

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = 'C';
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailSeqNo = index;
      x.no = index;

      collectList.push(x);
      index++;
    });

    // console.log(prepaidList);
    // console.log(collectList);

    let dets: FreightDetail[] = [];
    prepaidList.forEach(element => {
      dets.push(element)
    });
    collectList.forEach(element => {
      dets.push(element)
    });
    this.map.set(this.model.pfcContainerType, dets);

    // console.log(this.map);
    //this.gridPrepaid.clearSelectedValues();
    //this.onDetailAfterCopy(true);

    // TOTAL Collect
    this.totD20Collect = 0;
    this.totD40Collect = 0;
    this.totD45Collect = 0;
    this.totH20Collect = 0;
    this.totH40Collect = 0;
    this.totH45Collect = 0;

    this.gridCollect.listStore.store.forEach(el=>{
      console.log(el.pfcDetailD20)
      this.totD20Collect += el.pfcDetailD20,
      this.totD40Collect += el.pfcDetailD40,
      this.totD45Collect += el.pfcDetailD45,
      this.totH20Collect += el.pfcDetailH20,
      this.totH40Collect += el.pfcDetailH40,
      this.totH45Collect += el.pfcDetailH45
    })

    console.log("D20")
    console.log(this.totD20Collect)

    // console.log('** after update **');
    // console.log(this.map);

    this.gridCollect.total.forEach(element=>{
      element["PFCDETAILD20"] = this.totD20Collect,
      element["PFCDETAILD40"] = this.totD40Collect,
      element["PFCDETAILD45"] = this.totD45Collect,
      element["PFCDETAILH20"] = this.totH20Collect,
      element["PFCDETAILH40"] = this.totH40Collect,
      element["PFCDETAILH45"] = this.totH45Collect
    })
    */
  }

  onDetailCopyCollect() {
    console.log("** on detail copy collect **");
    let mp: Map<any, any> = this.onDetailCopyCollectCheckDuplicate();

    mp.forEach((value: string, key: boolean) => {
      if (key) {
        this.message(
          "information",
          "information",
          "Duplicate " + value + " charge code detected.",
          "okonly",
          { ok: "this.gridApiCollect.deselectAll();" }
        );
      } else {
        this.onDetailCopyCollectProcess();
        this.gridApiCollect.deselectAll();
        this.onDetailAfterCopy(false);
      }
    });

    /*
    let mp:Map<any,any> = this.onDetailCopyCollectCheckDuplicate()
    // console.log(mp);

    mp.forEach((value: string, key: boolean) => {
      // console.log(key);
      if(key){
        this.message('information', 'information', 'Duplicate ' + value + ' charge code detected.', 'okonly', {ok: 'this.gridCollect.clearSelectedValues();'});
      }else{
        this.onDetailCopyCollectProcess();
        this.gridCollect.clearSelectedValues();
        this.onDetailAfterCopy(false);
      }
    });
    */
  }

  onDetailCopyCollectCheckDuplicate(
    dataCollect?: FreightDetail
  ): Map<any, any> {
    console.log("** on detail copy collect check duplicate **");
    // console.log(dataCollect);

    let mp = new Map();

    let isErrorDetail: boolean = false;
    let chargeDuplicate: string = "";

    if (dataCollect != undefined) {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
      x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.pfcDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.pfcDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.pfcDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
      x.pfcDetailD20 = dataCollect.pfcDetailD20;
      x.pfcDetailD40 = dataCollect.pfcDetailD40;
      x.pfcDetailD45 = dataCollect.pfcDetailD45;
      x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataCollect.pfcDetailH20;
      x.pfcDetailH40 = dataCollect.pfcDetailH40;
      x.pfcDetailH45 = dataCollect.pfcDetailH45;
      x.pfcDetailKT = dataCollect.pfcDetailKT;
      x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
      x.pfcDetailM3 = dataCollect.pfcDetailM3;
      x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
      x.pfcDetailPC = dataCollect.pfcDetailPC;
      x.pfcDetailPCTab = "P";
      x.pfcDetailPayer = dataCollect.pfcDetailPayer;
      x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataCollect.pfcDetailRT;
      x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
      x.pfcDetailStatus = dataCollect.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy("prepaid", x);
      if (isErrorDetail) {
        chargeDuplicate = dataCollect.pfcDetailChargeCode.toUpperCase().trim();
      }
    } else {
      this.gridApiCollect.getSelectedRows().forEach((element) => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.pfcDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.pfcDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.pfcDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if (x.pfcDetailOogDoorOpen == "N") {
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "P";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if (isErrorDetail == false) {
          isErrorDetail = this.checkDuplicateCopy("prepaid", x);
          if (isErrorDetail) {
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });
    }

    /*
    if(dataCollect != undefined){

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
      x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case 'TNK':
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'FLR':
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'PLT':
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'REF':
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'DRY':
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'UC':
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case 'STD':
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
      x.pfcDetailD20 = dataCollect.pfcDetailD20;
      x.pfcDetailD40 = dataCollect.pfcDetailD40;
      x.pfcDetailD45 = dataCollect.pfcDetailD45;
      x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataCollect.pfcDetailH20;
      x.pfcDetailH40 = dataCollect.pfcDetailH40;
      x.pfcDetailH45 = dataCollect.pfcDetailH45;
      x.pfcDetailKT = dataCollect.pfcDetailKT;
      x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
      x.pfcDetailM3 = dataCollect.pfcDetailM3;
      x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
      x.pfcDetailPC = dataCollect.pfcDetailPC;
      x.pfcDetailPCTab = 'P';
      x.pfcDetailPayer = dataCollect.pfcDetailPayer;
      x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataCollect.pfcDetailRT;
      x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
      x.pfcDetailStatus = dataCollect.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy('prepaid',x);
      if(isErrorDetail){
        chargeDuplicate = dataCollect.pfcDetailChargeCode.toUpperCase().trim();
      }

    }else{
      this.gridCollect.getSelectedValues().forEach(element=> {

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case 'TNK':
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case 'OPT':
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case 'FLR':
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case 'PLT':
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case 'REF':
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case 'DRY':
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case 'UC':
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case 'STD':
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == 'N'){
          x.pfcDetailOogDoorOpenName = 'N/A';
        }else if(x.pfcDetailOogDoorOpen == 'D'){
          x.pfcDetailOogDoorOpenName = 'Detached';
        }else if(x.pfcDetailOogDoorOpen == 'B'){
          x.pfcDetailOogDoorOpenName = 'Tied Both';
        }else if(x.pfcDetailOogDoorOpen == 'L'){
          x.pfcDetailOogDoorOpenName = 'Tied Left';
        }else if(x.pfcDetailOogDoorOpen == 'R'){
          x.pfcDetailOogDoorOpenName = 'Tied Right';
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = 'P';
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if(isErrorDetail == false){
          isErrorDetail = this.checkDuplicateCopy('prepaid',x);
          if(isErrorDetail){
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });

    }
    */

    mp.set(isErrorDetail, chargeDuplicate);
    return mp;
  }

  onDetailCopyCollectProcess(dataCollect?: FreightDetail) {
    console.log("** on detail copy collect process **");

    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach((element) => {
      if (element.pfcDetailPCTab == "C") {
        collectList.push(element);
      }
    });

    if (dataCollect == undefined) {
      this.gridApiCollect.getSelectedRows().forEach((element) => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.pfcDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.pfcDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.pfcDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if (x.pfcDetailOogDoorOpen == "N") {
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "P";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
        x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        this.gridApiPrepaid.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      });
    } else {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
      x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.pfcDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.pfcDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.pfcDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
      x.pfcDetailD20 = dataCollect.pfcDetailD20;
      x.pfcDetailD40 = dataCollect.pfcDetailD40;
      x.pfcDetailD45 = dataCollect.pfcDetailD45;
      x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataCollect.pfcDetailH20;
      x.pfcDetailH40 = dataCollect.pfcDetailH40;
      x.pfcDetailH45 = dataCollect.pfcDetailH45;
      x.pfcDetailKT = dataCollect.pfcDetailKT;
      x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
      x.pfcDetailM3 = dataCollect.pfcDetailM3;
      x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
      x.pfcDetailPC = dataCollect.pfcDetailPC;
      x.pfcDetailPCTab = "P";
      x.pfcDetailPayer = dataCollect.pfcDetailPayer;
      x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
      x.pfcDetailPayerIsPayer = dataCollect.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = dataCollect.pfcDetailPayerIsValid;
      x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataCollect.pfcDetailRT;
      x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
      x.pfcDetailStatus = dataCollect.pfcDetailStatus;

      this.gridApiPrepaid.updateRowData({
        add: [JSON.parse(JSON.stringify(x))],
      });
    }

    let idx = 1;
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;
      x.pfcDetailSeqNo = idx;
      x.no = idx;
      prepaidList.push(x);
      idx++;
    });

    let dets: FreightDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.pfcContainerType, dets);

    //check Message "Main Charge is missing."
    let mapFAIGroup = new Map<String,FreightDetail[]>();
    let mapFAI = new Map<String,FreightDetail[]>();
    prepaidList.forEach((element) => {
        var key:String =
          element.pfcDetailPCTab + ";;" +
          element.pfcDetailContainerType + ";;" +
          element.pfcDetailCurrency + ";;" +
          element.pfcDetailPlaceOfPayment + ";;" +
          element.pfcDetailPayer + ";;" +
          element.pfcDetailPC + ";;" +
          (element.pfcDetailOogOH + element.pfcDetailOogOLB + element.pfcDetailOogOLF + element.pfcDetailOogOWL + element.pfcDetailOogOWR) + ";;" +
          element.pfcDetailOogDoorOpen + ";;" +
          (element.pfcDetailOogUcH + element.pfcDetailOogUcL + element.pfcDetailOogUcW) + ";;" +
          element.pfcDetailKillSlot;


        if(mapFAIGroup.get(key) == null){
          let detsx: FreightDetail[] = [];
          detsx.push(element);
          mapFAIGroup.set(key,detsx);
        }else{
          mapFAIGroup.get(key).push(element);
        }
    })

    mapFAIGroup.forEach((value: FreightDetail[], key: string) => {

      value.forEach(element => {

        if(element.pfcDetailFAIGroup == 'Y'){

          if(mapFAI.get(key) == null){
            let detsx: FreightDetail[] = mapFAIGroup.get(key)
            mapFAI.set(key,detsx);
          }else{
            //mapFAI.get(key).push(element);
          }

        }

      });
    });

    // GOS-831 incorrect charge sequence captured when using [Copy] function
    this.rowDataPrepaid = prepaidList;

    //if (this.currentActiveTab == "prepaid") {
      this.isFAIErrorPrepaid = false;
      this.messagesFAIErrorPrepaid = "";
    //}

    let isMainChargeExistPrepaid: boolean = true;

    mapFAI.forEach((value: FreightDetail[], key: string) => {
      let countMain = 0;
      let countFAI = 0;

      value.forEach((el: FreightDetail) => {

        //if (this.currentActiveTab == "prepaid") {
          if (el.pfcDetailPCTab == "P") {
              countFAI = countFAI + 1;
              if(el.pfcDetailChargeCode == 'OFT' || el.pfcDetailChargeCode == 'FOF'){
                countMain = countMain + 1;
              }
          }
        //}

      })

      //if (this.currentActiveTab == "prepaid") {
        if (countFAI > 0) {
          if (countMain == 0) {
            isMainChargeExistPrepaid = false;
          }
        }
      //}

    })

    //if (this.currentActiveTab == "prepaid") {
      if (isMainChargeExistPrepaid) {
        this.isFAIErrorPrepaid = false;
        this.messagesFAIErrorPrepaid = "";
      } else {
        this.isFAIErrorPrepaid = true;
        this.messagesFAIErrorPrepaid = "Main Charge is missing";
      }
    //}

    //Hitung Total
    this.calculateTotal();

    /*
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'C') {
        collectList.push(element);
      }
    });

    if (dataCollect == undefined) {
      this.gridCollect.getSelectedValues().forEach(element=> {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case 'TNK':
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case 'OPT':
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case 'FLR':
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case 'PLT':
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case 'REF':
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case 'DRY':
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case 'UC':
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case 'STD':
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == 'N'){
          x.pfcDetailOogDoorOpenName = 'N/A';
        }else if(x.pfcDetailOogDoorOpen == 'D'){
          x.pfcDetailOogDoorOpenName = 'Detached';
        }else if(x.pfcDetailOogDoorOpen == 'B'){
          x.pfcDetailOogDoorOpenName = 'Tied Both';
        }else if(x.pfcDetailOogDoorOpen == 'L'){
          x.pfcDetailOogDoorOpenName = 'Tied Left';
        }else if(x.pfcDetailOogDoorOpen == 'R'){
          x.pfcDetailOogDoorOpenName = 'Tied Right';
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = 'P';
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        //this.gridPrepaid.listStore.addData(x);
        this.gridPrepaid.listStore.store.push(x);


      });
    } else {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
      x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case 'TNK':
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'FLR':
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'PLT':
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'REF':
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'DRY':
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'UC':
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case 'STD':
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
      x.pfcDetailD20 = dataCollect.pfcDetailD20;
      x.pfcDetailD40 = dataCollect.pfcDetailD40;
      x.pfcDetailD45 = dataCollect.pfcDetailD45;
      x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataCollect.pfcDetailH20;
      x.pfcDetailH40 = dataCollect.pfcDetailH40;
      x.pfcDetailH45 = dataCollect.pfcDetailH45;
      x.pfcDetailKT = dataCollect.pfcDetailKT;
      x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
      x.pfcDetailM3 = dataCollect.pfcDetailM3;
      x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
      x.pfcDetailPC = dataCollect.pfcDetailPC;
      x.pfcDetailPCTab = 'P';
      x.pfcDetailPayer = dataCollect.pfcDetailPayer;
      x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataCollect.pfcDetailRT;
      x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
      x.pfcDetailStatus = dataCollect.pfcDetailStatus;

      this.gridPrepaid.listStore.addData(x);
    }

    let index = 1;
    prepaidList = [];
    this.gridPrepaid.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (x.pfcDetailContainerType) {
        case 'TNK':
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case 'OPT':
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case 'FLR':
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case 'PLT':
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case 'REF':
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case 'DRY':
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case 'UC':
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case 'STD':
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == 'N'){
        x.pfcDetailOogDoorOpenName = 'N/A';
      }else if(x.pfcDetailOogDoorOpen == 'D'){
        x.pfcDetailOogDoorOpenName = 'Detached';
      }else if(x.pfcDetailOogDoorOpen == 'B'){
        x.pfcDetailOogDoorOpenName = 'Tied Both';
      }else if(x.pfcDetailOogDoorOpen == 'L'){
        x.pfcDetailOogDoorOpenName = 'Tied Left';
      }else if(x.pfcDetailOogDoorOpen == 'R'){
        x.pfcDetailOogDoorOpenName = 'Tied Right';
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = 'P';
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailSeqNo = index;
      x.no = index;

      collectList.push(x);
      index++;
    });

    // console.log(prepaidList);
    // console.log(collectList);

    let dets: FreightDetail[] = [];
    prepaidList.forEach(element => {
      dets.push(element)
    });
    collectList.forEach(element => {
      dets.push(element)
    });
    this.map.set(this.model.pfcContainerType, dets);

    // console.log(this.map);
    //this.gridPrepaid.clearSelectedValues();
    //this.onDetailAfterCopy(true);

    // TOTAL Collect
    this.totD20Prepaid = 0;
    this.totD40Prepaid = 0;
    this.totD45Prepaid = 0;
    this.totH20Prepaid = 0;
    this.totH40Prepaid = 0;
    this.totH45Prepaid = 0;

    this.gridCollect.listStore.store.forEach(el=>{
      console.log(el.pfcDetailD20)
      this.totD20Prepaid += el.pfcDetailD20,
      this.totD40Prepaid += el.pfcDetailD40,
      this.totD45Prepaid += el.pfcDetailD45,
      this.totH20Prepaid += el.pfcDetailH20,
      this.totH40Prepaid += el.pfcDetailH40,
      this.totH45Prepaid += el.pfcDetailH45
    })

    console.log("D20")
    console.log(this.totD20Collect)

    // console.log('** after update **');
    // console.log(this.map);

    this.gridPrepaid.total.forEach(element=>{
      element["PFCDETAILD20"] = this.totD20Prepaid,
      element["PFCDETAILD40"] = this.totD40Prepaid,
      element["PFCDETAILD45"] = this.totD45Prepaid,
      element["PFCDETAILH20"] = this.totH20Prepaid,
      element["PFCDETAILH40"] = this.totH40Prepaid,
      element["PFCDETAILH45"] = this.totH45Prepaid
    })
    */
  }

  onDetailAfterCopy(isPrepaid: boolean) {
    if (isPrepaid) {
      this.message(
        "information",
        "Information",
        "Copy prepaid to collect successfully.",
        "okonly",
        { ok: "" }
      );
    } else {
      this.message(
        "information",
        "Information",
        "Copy collect to prepaid successfully.",
        "okonly",
        { ok: "" }
      );
    }
    return;
  }

  onDetailFAI() {}

  async onNewCharge_OK() {
    console.log("** on new charge ok hehe **");
    console.log(this.modelDetail);
    //safety first, replace blank to 0 (zeroes)
    this.modelDetail.pfcDetailD20 =
      this.modelDetail.pfcDetailD20 == null ? 0 : this.modelDetail.pfcDetailD20;
    this.modelDetail.pfcDetailD40 =
      this.modelDetail.pfcDetailD40 == null ? 0 : this.modelDetail.pfcDetailD40;
    this.modelDetail.pfcDetailD45 =
      this.modelDetail.pfcDetailD45 == null ? 0 : this.modelDetail.pfcDetailD45;
    this.modelDetail.pfcDetailH20 =
      this.modelDetail.pfcDetailH20 == null ? 0 : this.modelDetail.pfcDetailH20;
    this.modelDetail.pfcDetailH40 =
      this.modelDetail.pfcDetailH40 == null ? 0 : this.modelDetail.pfcDetailH40;
    this.modelDetail.pfcDetailH45 =
      this.modelDetail.pfcDetailH45 == null ? 0 : this.modelDetail.pfcDetailH45;
    this.modelDetail.pfcDetailM3 =
      this.modelDetail.pfcDetailM3 == null ? 0 : this.modelDetail.pfcDetailM3;
    this.modelDetail.pfcDetailKT =
      this.modelDetail.pfcDetailKT == null ? 0 : this.modelDetail.pfcDetailKT;
    this.modelDetail.pfcDetailRT =
      this.modelDetail.pfcDetailRT == null ? 0 : this.modelDetail.pfcDetailRT;
    this.modelDetail.pfcDetailMinCharge =
      this.modelDetail.pfcDetailMinCharge == null
        ? 0
        : this.modelDetail.pfcDetailMinCharge;
    this.modelDetail.pfcDetailKillSlot =
      this.modelDetail.pfcDetailKillSlot == null
        ? 0
        : this.modelDetail.pfcDetailKillSlot;
    this.modelDetail.pfcDetailOogOH =
      this.modelDetail.pfcDetailOogOH == null
        ? 0
        : this.modelDetail.pfcDetailOogOH;
    this.modelDetail.pfcDetailOogOWR =
      this.modelDetail.pfcDetailOogOWR == null
        ? 0
        : this.modelDetail.pfcDetailOogOWR;
    this.modelDetail.pfcDetailOogOWL =
      this.modelDetail.pfcDetailOogOWL == null
        ? 0
        : this.modelDetail.pfcDetailOogOWL;
    this.modelDetail.pfcDetailOogOLF =
      this.modelDetail.pfcDetailOogOLF == null
        ? 0
        : this.modelDetail.pfcDetailOogOLF;
    this.modelDetail.pfcDetailOogOLB =
      this.modelDetail.pfcDetailOogOLB == null
        ? 0
        : this.modelDetail.pfcDetailOogOLB;
    this.modelDetail.pfcDetailOogUcH =
      this.modelDetail.pfcDetailOogUcH == null
        ? 0
        : this.modelDetail.pfcDetailOogUcH;
    this.modelDetail.pfcDetailOogUcW =
      this.modelDetail.pfcDetailOogUcW == null
        ? 0
        : this.modelDetail.pfcDetailOogUcW;
    this.modelDetail.pfcDetailOogUcL =
      this.modelDetail.pfcDetailOogUcL == null
        ? 0
        : this.modelDetail.pfcDetailOogUcL;
    this.modelDetail.pfcDetailPCTab =
      this.currentActiveTab.toUpperCase() == "PREPAID" ? "P" : "C";

    //calculate charge total value
    this.modelDetail.pfcDetailTotalChargeValue =
      this.modelDetail.pfcDetailD20 +
      this.modelDetail.pfcDetailD40 +
      this.modelDetail.pfcDetailD45 +
      this.modelDetail.pfcDetailH20 +
      this.modelDetail.pfcDetailH40 +
      this.modelDetail.pfcDetailH45;

    // if (this.modelDetail.pfcDetailPC.toUpperCase() == "P") {
    //   this.setValidatorAddNewCharge();
    // } else {
    //   this.setValidatorAddNewChargeCollect();
    // }

    if (this.modelDetail.pfcDetailPlaceOfPayment) {
      if (this.model.pfcDischargePortCode) {
        if (this.podName.includes("FREIGHT GROUP")) {
          console.log("masuk nih");
          this.POPFreightGroupCode = await this.checkFreightGroup(
            this.cookieService.getDefaultLocationCode(),
            this.modelDetail.pfcDetailPlaceOfPayment
          ).then((res: any) => {
            if (res.content.length > 0) {
              return res.content[0].locationCode;
            }

            return "";
          });
        } else {
          this.POPFreightGroupCode = this.modelDetail.pfcDetailPlaceOfPayment;
        }
      }
    }

    if (
      this.model.pfcDischargePortCode !== "" &&
      this.POPFreightGroupCode !== ""
    ) {
      if (this.model.pfcDischargePortCode === this.POPFreightGroupCode) {
        this.setValidatorAddNewChargeCollect();
      } else {
        this.setValidatorAddNewCharge();
      }
    } else {
      this.setValidatorAddNewCharge();
    }

    this.modelDetail["chargesValueValidator"] =
      this.checkChargesValueValidator();
    this.modelDetail["mainChargesValidator"] = this.checkMainChargesValidator();

    this.isErrorDetail = this.onValidate(this.modelDetail);

    console.log("lihat kesini : ", this.modelDetail);

    let chkDuplicate: boolean = false;
    if (this.currentActiveTab == "prepaid") {
      chkDuplicate = this.checkDuplicate("prepaid", this.modelDetail);
    } else if (this.currentActiveTab == "collect") {
      chkDuplicate = this.checkDuplicate("collect", this.modelDetail);
    }

    if (chkDuplicate) {
      this.modelDetail["error-pfcDuplicateChargeCode"] =
        "Duplicate " +
        this.modelDetail.pfcDetailChargeCode.toUpperCase().trim() +
        " charge code detected.";
      this.isErrorDetail = true;
    }

    if (
      this.modelDetail.pfcDetailChargeCode == "OFT" ||
      this.modelDetail.pfcDetailChargeCode == "FAI" ||
      this.modelDetail.pfcDetailChargeCode == "FOF"
    ) {
      let chkDuplicateMain: boolean = false;
      if (this.currentActiveTab == "prepaid") {
        chkDuplicateMain = this.checkDuplicateMainCharges(
          "prepaid",
          this.modelDetail
        );
      } else if (this.currentActiveTab == "collect") {
        chkDuplicateMain = this.checkDuplicateMainCharges(
          "collect",
          this.modelDetail
        );
      }

      if (chkDuplicateMain) {
        this.modelDetail["error-pfcDuplicateChargeCode"] =
          "Duplicate main charge code detected.";
        this.isErrorDetail = true;
      }
    }

    if (this.modelDetail.pfcDetailCopyTab == "Y") {
      if (this.currentActiveTab == "prepaid") {
        // console.log('** copy freight to collect **');
        let mp: Map<boolean, string> = this.onDetailCopyPrepaidCheckDuplicate(
          this.modelDetail
        );
        mp.forEach((value: string, key: boolean) => {
          // console.log(key);
          if (key) {
            this.modelDetail["error-pfcDuplicateChargeCode"] =
              "Duplicate " + value + " charge code detected in collect tab.";
            this.isErrorDetail = true;
          }
        });
      }
      if (this.currentActiveTab == "collect") {
        // console.log('** copy freight to prepaid **');
        let mp: Map<boolean, string> = this.onDetailCopyCollectCheckDuplicate(
          this.modelDetail
        );
        mp.forEach((value: string, key: boolean) => {
          // console.log(key);
          if (key) {
            this.modelDetail["error-pfcDuplicateChargeCode"] =
              "Duplicate " + value + " charge code detected in prepaid tab.";
            this.isErrorDetail = true;
          }
        });
      }
    }

    // ----------- check FAI Group ----------------------------
    console.log("##  check FAI Group  ##");
    //let mapFAI = new Map<String,Map<Number,String>>();
    let mapx = new Map();

    //update dulu ke mapx
    // ------------------------------------------------
    let prepaidListx: FreightDetail[] = [];
    let collectListx: FreightDetail[] = [];

    if (this.isUpdateDetail) {
      //console.log("#3")

      let fdx: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fdx.forEach((element) => {
        if (element.pfcDetailPCTab == "P") {
          prepaidListx.push(element);
        }
        if (element.pfcDetailPCTab == "C") {
          collectListx.push(element);
        }
      });

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
      x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
      x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
      switch (this.modelDetail.pfcDetailContainerType) {
        case "STD":
          this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          this.modelDetail.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          this.modelDetail.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName =
        this.modelDetail.pfcDetailContainerTypeName;

      x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
      x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
      x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
      x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
      x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
      x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
      x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
      x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
      x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
      x.pfcDetailKT = this.modelDetail.pfcDetailKT;
      x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
      x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
      x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;

      if (x.pfcDetailOogDoorOpen == "N") {
        this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }

      x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
      x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
      x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
      x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
      x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
      x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
      x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
      x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
      x.pfcDetailPC = this.modelDetail.pfcDetailPC;
      x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
      x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
      x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
      x.pfcDetailPayerIsPayer = this.modelDetail.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = this.modelDetail.pfcDetailPayerIsValid;
      x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName =
        this.modelDetail.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = this.modelDetail.pfcDetailRT;
      x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
      x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
      x.no = x.pfcDetailSeqNo;

      if (this.currentActiveTab == "prepaid") {
        prepaidListx[x.pfcDetailSeqNo - 1] = x;
      } else {
        // collect
        collectListx[x.pfcDetailSeqNo - 1] = x;
      }

      let detsx: FreightDetail[] = [];
      prepaidListx.forEach((element) => {
        detsx.push(element);
      });
      collectListx.forEach((element) => {
        detsx.push(element);
      });
      mapx.set(this.model.pfcContainerType, detsx);
    } else {
      if (
        this.map.get(this.model.pfcContainerType) == undefined ||
        this.map.get(this.model.pfcContainerType) == null
      ) {
        //console.log("#1")
        this.modelDetailListx = [];

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
        x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
        x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
        switch (this.modelDetail.pfcDetailContainerType) {
          case "STD":
            this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.pfcDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.pfcDetailContainerTypeName =
          this.modelDetail.pfcDetailContainerTypeName;

        x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
        x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
        x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
        x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
        x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
        x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
        x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
        x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
        x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
        x.pfcDetailKT = this.modelDetail.pfcDetailKT;
        x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
        x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
        x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
        if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
          this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

        x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
        x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
        x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
        x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
        x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
        x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
        x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
        x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
        x.pfcDetailPC = this.modelDetail.pfcDetailPC;
        x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
        x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
        x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
        x.pfcDetailPayerIsPayer = this.modelDetail.pfcDetailPayerIsPayer;
        x.pfcDetailPayerIsValid = this.modelDetail.pfcDetailPayerIsValid;
        x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName =
          this.modelDetail.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = this.modelDetail.pfcDetailRT;
        x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
        x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
        x.no = this.modelDetail.pfcDetailSeqNo;

        this.modelDetailListx.push(x);
        mapx.set(this.model.pfcContainerType, this.modelDetailListx);
      } else {
        //console.log("#2")
        let fdx: FreightDetail[] = this.map.get(this.model.pfcContainerType);
        fdx.forEach((element) => {
          if (element.pfcDetailPCTab == "P") {
            prepaidListx.push(element);
          }
          if (element.pfcDetailPCTab == "C") {
            collectListx.push(element);
          }
        });

        let detsx: FreightDetail[] = [];
        prepaidListx.forEach((element) => {
          detsx.push(element);
        });
        collectListx.forEach((element) => {
          detsx.push(element);
        });
        mapx.set(this.model.pfcContainerType, detsx);

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
        x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
        x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
        switch (this.modelDetail.pfcDetailContainerType) {
          case "STD":
            this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.pfcDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.pfcDetailContainerTypeName =
          this.modelDetail.pfcDetailContainerTypeName;

        x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
        x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
        x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
        x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
        x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
        x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
        x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
        x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
        x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
        x.pfcDetailKT = this.modelDetail.pfcDetailKT;
        x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
        x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
        x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
        if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
          this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

        x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
        x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
        x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
        x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
        x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
        x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
        x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
        x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
        x.pfcDetailPC = this.modelDetail.pfcDetailPC;
        x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
        x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
        x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName =
          this.modelDetail.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = this.modelDetail.pfcDetailRT;
        x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
        x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
        x.no = this.modelDetail.pfcDetailSeqNo;

        mapx.get(this.model.pfcContainerType).push(x);
      }
    }
    //console.log("mapx = ")
    //console.log(mapx)
    //-------------------------------------------------
    /*
    mapx.forEach((value: FreightDetail[], key: string) => {
      value.forEach(element => {

        if(element.pfcDetailFAIGroup == 'Y'){

          if(mapFAI.get(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer) == null){
            console.log("#1")
            let mp = new Map<Number, String>();

              let key :string =
              element.pfcDetailContainerType + ";;" +
              element.pfcDetailPCTab + ";;" +
              element.pfcDetailCurrency + ";;" +
              element.pfcDetailPC + ";;" +
              element.pfcDetailPlaceOfPayment + ";;" +
              element.pfcDetailPayer + ";;" +
              element.pfcDetailOogOH + ";;" +
              element.pfcDetailOogOWL + ";;" +
              element.pfcDetailOogOWR + ";;" +
              element.pfcDetailOogOLF + ";;" +
              element.pfcDetailOogOLB + ";;" +
              element.pfcDetailKillSlot + ";;" +
              element.pfcDetailOogDoorOpen + ";;" +
              element.pfcDetailOogUcH + ";;" +
              element.pfcDetailOogUcL + ";;" +
              element.pfcDetailOogUcW;


            mp.set(element.pfcDetailSeqNo,key)

            mapFAI.set(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer, mp);

          }else{

            let key :string =
            element.pfcDetailContainerType + ";;" +
            element.pfcDetailPCTab + ";;" +
            element.pfcDetailCurrency + ";;" +
            element.pfcDetailPC + ";;" +
            element.pfcDetailPlaceOfPayment + ";;" +
            element.pfcDetailPayer + ";;" +
            element.pfcDetailOogOH + ";;" +
            element.pfcDetailOogOWL + ";;" +
            element.pfcDetailOogOWR + ";;" +
            element.pfcDetailOogOLF + ";;" +
            element.pfcDetailOogOLB + ";;" +
            element.pfcDetailKillSlot + ";;" +
            element.pfcDetailOogDoorOpen + ";;" +
            element.pfcDetailOogUcH + ";;" +
            element.pfcDetailOogUcL + ";;" +
            element.pfcDetailOogUcW;

            mapFAI.get(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer).set(element.pfcDetailSeqNo, key)

          }
        }else{
          if(mapFAI.get(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer) != null){
            if(mapFAI.get(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer).get(element.pfcDetailSeqNo) != null){
              mapFAI.get(element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer).delete(element.pfcDetailSeqNo)
            }
          }
        }

      });
    });

    console.log('-- sebelum check #1 --')
    console.log(mapFAI)

    if(this.modelDetail.pfcDetailFAIGroup == 'Y'){

      if (this.modelDetail.pfcDetailChargeCode !== 'FAI'){
        // --------
        mapFAI.forEach((value: Map<Number,String>, key: string) => {
          value.forEach((element: String, k: Number) => {
            //console.log(element)

              var key:String =
              this.modelDetail.pfcDetailContainerType + ';;' + //0
              this.modelDetail.pfcDetailPCTab + ';;' + //1
              this.modelDetail.pfcDetailCurrency + ';;' + //2
              this.modelDetail.pfcDetailPC + ';;' +  //3
              this.modelDetail.pfcDetailPlaceOfPayment + ';;' + //4
              this.modelDetail.pfcDetailPayer + ';;' +  //5
              this.modelDetail.pfcDetailOogOH + ';;' +  //6
              this.modelDetail.pfcDetailOogOWL + ';;' +  //7
              this.modelDetail.pfcDetailOogOWR + ';;' + //8
              this.modelDetail.pfcDetailOogOLF + ';;' +  //9
              this.modelDetail.pfcDetailOogOLB + ';;' +  //10
              this.modelDetail.pfcDetailKillSlot + ';;' +  //11
              this.modelDetail.pfcDetailOogDoorOpen + ';;' +  //12
              this.modelDetail.pfcDetailOogUcH + ';;' + //13
              this.modelDetail.pfcDetailOogUcL + ';;' + //24
              this.modelDetail.pfcDetailOogUcW; //15

              var el:String =
              element.split(';;')[0] + ';;' +
              element.split(';;')[1] + ';;' +
              element.split(';;')[2] + ';;' +
              element.split(';;')[3] + ';;' +
              element.split(';;')[4] + ';;' +
              element.split(';;')[5] + ';;' +
              element.split(';;')[6] + ';;' +
              element.split(';;')[7] + ';;' +
              element.split(';;')[8] + ';;' +
              element.split(';;')[9] + ';;' +
              element.split(';;')[10] + ';;' +
              element.split(';;')[11] + ';;' +
              element.split(';;')[12] + ';;' +
              element.split(';;')[13] + ';;' +
              element.split(';;')[14] + ';;' +
              element.split(';;')[15];

              console.log(key + " - " + el)
              if (el !== key){

                this.isErrorDetail = true;
                // Generate discrepancy message

                if (this.modelDetail.pfcDetailCurrency !== element.split(';;')[2]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Currency.';
                }
                if (this.modelDetail.pfcDetailPC !== element.split(';;')[3]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check P/C.';
                }
                if (this.modelDetail.pfcDetailPlaceOfPayment !== element.split(';;')[4]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check PlaceOfPayment.';
                }
                if (this.modelDetail.pfcDetailPayer !== element.split(';;')[5]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Payer.';
                }
                if (String(this.modelDetail.pfcDetailOogOH) !== element.split(';;')[6]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OH).';
                }
                if (String(this.modelDetail.pfcDetailOogOWL) !== element.split(';;')[7]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OWL).';
                }
                if (String(this.modelDetail.pfcDetailOogOWR) !== element.split(';;')[8]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OWR).';
                }
                if (String(this.modelDetail.pfcDetailOogOLF) !== element.split(';;')[9]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OLF).';
                }
                if (String(this.modelDetail.pfcDetailOogOLB) !== element.split(';;')[10]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OLB).';
                }
                if (String(this.modelDetail.pfcDetailKillSlot) !== element.split(';;')[11]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Kill Slot.';
                }
                if (this.modelDetail.pfcDetailOogDoorOpen !== element.split(';;')[12]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Door Open.';
                }
                if (String(this.modelDetail.pfcDetailOogUcH) !== element.split(';;')[13]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCH.';
                }
                if (String(this.modelDetail.pfcDetailOogUcL) !== element.split(';;')[14]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCL.';
                }
                if (String(this.modelDetail.pfcDetailOogUcW) !== element.split(';;')[15]){
                  this.modelDetail['error-pfcDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCW.';
                }

              }

          });
        });

        // ----
      } else {
        this.isErrorDetail = true;
        this.modelDetail['error-pfcDetailFAIGroup'] = 'FAI is not applicable under FAI Group.';
      }

    }
    */

    /*
    //backup 13/10/2021
    let mapFAI = new Map<String, String[]>();
    let mapMainCharge = new Map<String, FreightDetail[]>();
    let mapFAICharge = new Map<String, FreightDetail[]>();
    console.log("nojan is here");
    console.log(mapx);

    mapx.forEach((value: FreightDetail[], key: string) => {
      if (key == this.model.pfcContainerType) {
        value.forEach((each) => {
          let mainKey =
            each.pfcDetailPCTab +
            ";;" +
            each.pfcDetailContainerType +
            ";;" +
            (each.pfcDetailD20 != 0 ? "20" : "-") +
            ";;" +
            (each.pfcDetailD40 != 0 ? "40" : "-") +
            ";;" +
            (each.pfcDetailD45 != 0 ? "45" : "-") +
            ";;" +
            (each.pfcDetailH20 != 0 ? "H20" : "-") +
            ";;" +
            (each.pfcDetailH40 != 0 ? "H40" : "-") +
            ";;" +
            (each.pfcDetailH45 != 0 ? "H45" : "-") +
            ";;" +
            each.pfcDetailCurrency +
            ";;" +
            each.pfcDetailPlaceOfPayment +
            ";;" +
            each.pfcDetailPayer +
            ";;" +
            each.pfcDetailPC +
            ";;" +
            (each.pfcDetailOogOH +
              each.pfcDetailOogOLB +
              each.pfcDetailOogOLF +
              each.pfcDetailOogOWL +
              each.pfcDetailOogOWR) +
            ";;" +
            each.pfcDetailOogDoorOpen +
            ";;" +
            (each.pfcDetailOogUcH +
              each.pfcDetailOogUcL +
              each.pfcDetailOogUcW) +
            ";;" +
            each.pfcDetailKillSlot;
          if (
            each.pfcDetailChargeCode == "OFT" ||
            each.pfcDetailChargeCode == "FOF"
          ) {
            if (mapMainCharge.get(mainKey) == undefined) {
              mapMainCharge.set(mainKey, []);
            }
            mapMainCharge.get(mainKey).push(each);
          }

          if (each.pfcDetailFAIGroup == "Y") {
            if (mapFAICharge.get(mainKey) == undefined) {
              mapFAICharge.set(mainKey, []);
            }
            mapFAICharge.get(mainKey).push(each);

            if (
              each.pfcDetailChargeCode != "OFT" &&
              each.pfcDetailChargeCode != "FOF"
            ) {
              if (mapMainCharge.get(mainKey) != undefined) {
                mapMainCharge.get(mainKey).forEach((each) => {
                  each.pfcDetailFAIGroup = "Y";
                  mapFAICharge.get(mainKey).push(each);
                });
              }
            }
          }
        });
      }
    });

    let mainKeyFAI = "";
    mapFAICharge.forEach((value: FreightDetail[], key: string) => {
      if (this.currentActiveTab == "prepaid") {
        if (key.startsWith("P")) {
          value.forEach((e) => {
            if (
              e.pfcDetailChargeCode == "OFT" ||
              e.pfcDetailChargeCode == "FOF"
            ) {
              mainKeyFAI = key;
            }
          });
        }
      } else if (this.currentActiveTab == "collect") {
        if (key.startsWith("C")) {
          value.forEach((e) => {
            if (
              e.pfcDetailChargeCode == "OFT" ||
              e.pfcDetailChargeCode == "FOF"
            ) {
              mainKeyFAI = key;
            }
          });
        }
      }
    });

    console.log(" FAI CHARGE ");
    console.log(mapFAICharge);
    console.log(mainKeyFAI);
    if (mainKeyFAI != "") {
      let sKey =
        this.modelDetail.pfcDetailPCTab +
        ";;" +
        this.modelDetail.pfcDetailContainerType +
        ";;" +
        (this.modelDetail.pfcDetailD20 != 0 ? "20" : "-") +
        ";;" +
        (this.modelDetail.pfcDetailD40 != 0 ? "40" : "-") +
        ";;" +
        (this.modelDetail.pfcDetailD45 != 0 ? "45" : "-") +
        ";;" +
        (this.modelDetail.pfcDetailH20 != 0 ? "H20" : "-") +
        ";;" +
        (this.modelDetail.pfcDetailH40 != 0 ? "H40" : "-") +
        ";;" +
        (this.modelDetail.pfcDetailH45 != 0 ? "H45" : "-") +
        ";;" +
        this.modelDetail.pfcDetailCurrency +
        ";;" +
        this.modelDetail.pfcDetailPlaceOfPayment +
        ";;" +
        this.modelDetail.pfcDetailPayer +
        ";;" +
        this.modelDetail.pfcDetailPC +
        ";;" +
        (this.modelDetail.pfcDetailOogOH +
          this.modelDetail.pfcDetailOogOLB +
          this.modelDetail.pfcDetailOogOLF +
          this.modelDetail.pfcDetailOogOWL +
          this.modelDetail.pfcDetailOogOWR) +
        ";;" +
        this.modelDetail.pfcDetailOogDoorOpen +
        ";;" +
        (this.modelDetail.pfcDetailOogUcH +
          this.modelDetail.pfcDetailOogUcL +
          this.modelDetail.pfcDetailOogUcW) +
        ";;" +
        this.modelDetail.pfcDetailKillSlot;
      console.log("existing key " + sKey);

      if (this.modelDetail.pfcDetailFAIGroup == "Y") {
        if (mainKeyFAI != sKey) {
          this.isErrorDetail = true;
          this.modelDetail["error-pfcDetailFAIGroup"] =
            "FAI Grouping Not Allowed.";
        }
      }
    }

    console.log("this.isErrorDetail = " + this.isErrorDetail);
    if (!this.isErrorDetail) {
      if (this.isUpdateDetail) {
        //update
        let prepaidList: FreightDetail[] = [];
        let collectList: FreightDetail[] = [];
        let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);

        fd.forEach((element) => {
          if (element.pfcDetailPCTab == "P") {
            prepaidList.push(element);
          }
          if (element.pfcDetailPCTab == "C") {
            collectList.push(element);
          }
        });

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
        x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
        x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
        switch (this.modelDetail.pfcDetailContainerType) {
          case "STD":
            this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.pfcDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.pfcDetailContainerTypeName =
          this.modelDetail.pfcDetailContainerTypeName;

        x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
        x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
        x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
        x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
        x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
        x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
        x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
        x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
        x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
        x.pfcDetailKT = this.modelDetail.pfcDetailKT;
        x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
        x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
        x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;

        if (x.pfcDetailOogDoorOpen == "N") {
          this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
          x.pfcDetailOogDoorOpenName = "N/A";
        } else if (x.pfcDetailOogDoorOpen == "D") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
          x.pfcDetailOogDoorOpenName = "Detached";
        } else if (x.pfcDetailOogDoorOpen == "B") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
          x.pfcDetailOogDoorOpenName = "Tied Both";
        } else if (x.pfcDetailOogDoorOpen == "L") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
          x.pfcDetailOogDoorOpenName = "Tied Left";
        } else if (x.pfcDetailOogDoorOpen == "R") {
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }

        x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
        x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
        x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
        x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
        x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
        x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
        x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
        x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
        x.pfcDetailPC = this.modelDetail.pfcDetailPC;
        x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
        x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
        x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName =
          this.modelDetail.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = this.modelDetail.pfcDetailRT;
        x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
        x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
        x.no = x.pfcDetailSeqNo;

        if (this.currentActiveTab == "prepaid") {
          prepaidList[x.pfcDetailSeqNo - 1] = x;
        } else {
          // collect
          collectList[x.pfcDetailSeqNo - 1] = x;
        }

        let dets: FreightDetail[] = [];
        prepaidList.forEach((element) => {
          dets.push(element);
        });
        collectList.forEach((element) => {
          dets.push(element);
        });
        this.map.set(this.model.pfcContainerType, dets);

        //udpate the map first
        console.log("nojan is here 2");
        console.log(this.map);
        this.map.forEach((value: FreightDetail[], key: string) => {
          if (key == this.model.pfcContainerType) {
            value.forEach((each) => {
              let mainKey =
                each.pfcDetailPCTab +
                ";;" +
                each.pfcDetailContainerType +
                ";;" +
                (each.pfcDetailD20 != 0 ? "20" : "-") +
                ";;" +
                (each.pfcDetailD40 != 0 ? "40" : "-") +
                ";;" +
                (each.pfcDetailD45 != 0 ? "45" : "-") +
                ";;" +
                (each.pfcDetailH20 != 0 ? "H20" : "-") +
                ";;" +
                (each.pfcDetailH40 != 0 ? "H40" : "-") +
                ";;" +
                (each.pfcDetailH45 != 0 ? "H45" : "-") +
                ";;" +
                each.pfcDetailCurrency +
                ";;" +
                each.pfcDetailPlaceOfPayment +
                ";;" +
                each.pfcDetailPayer +
                ";;" +
                each.pfcDetailPC +
                ";;" +
                (each.pfcDetailOogOH +
                  each.pfcDetailOogOLB +
                  each.pfcDetailOogOLF +
                  each.pfcDetailOogOWL +
                  each.pfcDetailOogOWR) +
                ";;" +
                each.pfcDetailOogDoorOpen +
                ";;" +
                (each.pfcDetailOogUcH +
                  each.pfcDetailOogUcL +
                  each.pfcDetailOogUcW) +
                ";;" +
                each.pfcDetailKillSlot;
              if (mapFAICharge.get(mainKey) != undefined) {
                mapFAICharge.get(mainKey).forEach((eachRow) => {
                  if (each.pfcDetailSeqNo == eachRow.pfcDetailSeqNo) {
                    each.pfcDetailFAIGroup = eachRow.pfcDetailFAIGroup;
                  }
                });
              }
            });
          }
        });

        if (this.currentActiveTab == "prepaid") {
          var self = this;
          var itemsToUpdate = [];
          var updateKey = "";
          this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
            rowNode,
            index
          ) {
            let idx: number = self.modelDetail.pfcDetailSeqNo - 1;
            console.log(index + " - " + idx);
            var x: FreightDetail = rowNode.data;
            if (index == idx) {
              x.pfcDetailChargeCode = self.modelDetail.pfcDetailChargeCode;
              x.pfcDetailChargeName = self.modelDetail.pfcDetailChargeName;
              x.pfcDetailContainerType =
                self.modelDetail.pfcDetailContainerType;
              switch (self.modelDetail.pfcDetailContainerType) {
                case "STD":
                  self.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                  break;
                case "DRY":
                  self.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                  break;
                case "REF":
                  self.modelDetail.pfcDetailContainerTypeName = "REEFER";
                  break;
                case "TNK":
                  self.modelDetail.pfcDetailContainerTypeName = "TANK";
                  break;
                case "OPT":
                  self.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                  break;
                case "PLT":
                  self.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                  break;
                case "FLR":
                  self.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                  break;
                case "UC":
                  self.modelDetail.pfcDetailContainerTypeName = "UC";
                  break;
                default:
                  break;
              }
              x.pfcDetailContainerTypeName =
                self.modelDetail.pfcDetailContainerTypeName;

              x.pfcDetailCopyTab = self.modelDetail.pfcDetailCopyTab;
              x.pfcDetailCurrency = self.modelDetail.pfcDetailCurrency;
              x.pfcDetailD20 = self.modelDetail.pfcDetailD20;
              x.pfcDetailD40 = self.modelDetail.pfcDetailD40;
              x.pfcDetailD45 = self.modelDetail.pfcDetailD45;
              x.pfcDetailFAIGroup = self.modelDetail.pfcDetailFAIGroup;
              x.pfcDetailH20 = self.modelDetail.pfcDetailH20;
              x.pfcDetailH40 = self.modelDetail.pfcDetailH40;
              x.pfcDetailH45 = self.modelDetail.pfcDetailH45;
              x.pfcDetailKT = self.modelDetail.pfcDetailKT;
              x.pfcDetailKillSlot = self.modelDetail.pfcDetailKillSlot;
              x.pfcDetailM3 = self.modelDetail.pfcDetailM3;
              x.pfcDetailMinCharge = self.modelDetail.pfcDetailMinCharge;
              x.pfcDetailOogDoorOpen = self.modelDetail.pfcDetailOogDoorOpen;
              if (self.modelDetail.pfcDetailOogDoorOpen == "N") {
                self.modelDetail.pfcDetailOogDoorOpenName = "N/A";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "D") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Detached";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "B") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "L") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "R") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
              }
              x.pfcDetailOogDoorOpenName =
                self.modelDetail.pfcDetailOogDoorOpenName;

              x.pfcDetailOogOH = self.modelDetail.pfcDetailOogOH;
              x.pfcDetailOogOLB = self.modelDetail.pfcDetailOogOLB;
              x.pfcDetailOogOLF = self.modelDetail.pfcDetailOogOLF;
              x.pfcDetailOogOWL = self.modelDetail.pfcDetailOogOWL;
              x.pfcDetailOogOWR = self.modelDetail.pfcDetailOogOWR;
              x.pfcDetailOogUcH = self.modelDetail.pfcDetailOogUcH;
              x.pfcDetailOogUcL = self.modelDetail.pfcDetailOogUcL;
              x.pfcDetailOogUcW = self.modelDetail.pfcDetailOogUcW;
              x.pfcDetailPC = self.modelDetail.pfcDetailPC;
              x.pfcDetailPCTab = self.modelDetail.pfcDetailPCTab;
              x.pfcDetailPayer = self.modelDetail.pfcDetailPayer;
              x.pfcDetailPayerName = self.modelDetail.pfcDetailPayerName;
              x.pfcDetailPlaceOfPayment =
                self.modelDetail.pfcDetailPlaceOfPayment;
              x.pfcDetailPlaceOfPaymentName =
                self.modelDetail.pfcDetailPlaceOfPaymentName;
              x.pfcDetailRT = self.modelDetail.pfcDetailRT;
              x.pfcDetailSeqNo = self.modelDetail.pfcDetailSeqNo;
              x.pfcDetailStatus = self.modelDetail.pfcDetailStatus;
              x.no = x.pfcDetailSeqNo;

              updateKey =
                x.pfcDetailPCTab +
                ";;" +
                x.pfcDetailContainerType +
                ";;" +
                (x.pfcDetailD20 != 0 ? "20" : "-") +
                ";;" +
                (x.pfcDetailD40 != 0 ? "40" : "-") +
                ";;" +
                (x.pfcDetailD45 != 0 ? "45" : "-") +
                ";;" +
                (x.pfcDetailH20 != 0 ? "H20" : "-") +
                ";;" +
                (x.pfcDetailH40 != 0 ? "H40" : "-") +
                ";;" +
                (x.pfcDetailH45 != 0 ? "H45" : "-") +
                ";;" +
                x.pfcDetailCurrency +
                ";;" +
                x.pfcDetailPlaceOfPayment +
                ";;" +
                x.pfcDetailPayer +
                ";;" +
                x.pfcDetailPC +
                ";;" +
                (x.pfcDetailOogOH +
                  x.pfcDetailOogOLB +
                  x.pfcDetailOogOLF +
                  x.pfcDetailOogOWL +
                  x.pfcDetailOogOWR) +
                ";;" +
                x.pfcDetailOogDoorOpen +
                ";;" +
                (x.pfcDetailOogUcH + x.pfcDetailOogUcL + x.pfcDetailOogUcW) +
                ";;" +
                x.pfcDetailKillSlot;

              if (
                x.pfcDetailChargeCode != "OFT" &&
                x.pfcDetailChargeCode != "FOF"
              ) {
                self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
                  rowNode,
                  index
                ) {
                  var z: FreightDetail = rowNode.data;
                  console.log("nojan is here");
                  console.log(self.modelDetail.pfcDetailFAIGroup);
                  console.log(z.pfcDetailChargeCode);
                  if (self.modelDetail.pfcDetailFAIGroup == "Y") {
                    if (
                      z.pfcDetailChargeCode == "OFT" ||
                      z.pfcDetailChargeCode == "FOF"
                    ) {
                      let mKey =
                        z.pfcDetailPCTab +
                        ";;" +
                        z.pfcDetailContainerType +
                        ";;" +
                        (z.pfcDetailD20 != 0 ? "20" : "-") +
                        ";;" +
                        (z.pfcDetailD40 != 0 ? "40" : "-") +
                        ";;" +
                        (z.pfcDetailD45 != 0 ? "45" : "-") +
                        ";;" +
                        (z.pfcDetailH20 != 0 ? "H20" : "-") +
                        ";;" +
                        (z.pfcDetailH40 != 0 ? "H40" : "-") +
                        ";;" +
                        (z.pfcDetailH45 != 0 ? "H45" : "-") +
                        ";;" +
                        z.pfcDetailCurrency +
                        ";;" +
                        z.pfcDetailPlaceOfPayment +
                        ";;" +
                        z.pfcDetailPayer +
                        ";;" +
                        z.pfcDetailPC +
                        ";;" +
                        (z.pfcDetailOogOH +
                          z.pfcDetailOogOLB +
                          z.pfcDetailOogOLF +
                          z.pfcDetailOogOWL +
                          z.pfcDetailOogOWR) +
                        ";;" +
                        z.pfcDetailOogDoorOpen +
                        ";;" +
                        (z.pfcDetailOogUcH +
                          z.pfcDetailOogUcL +
                          z.pfcDetailOogUcW) +
                        ";;" +
                        z.pfcDetailKillSlot;
                      console.log(mKey + " vs " + updateKey);
                      if (mKey == updateKey) {
                        z.pfcDetailFAIGroup = "Y";

                        itemsToUpdate.push(z);
                      }
                    }
                  }
                });
              } else {
                self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
                  rowNode,
                  index
                ) {
                  var z: FreightDetail = rowNode.data;
                  if (self.modelDetail.pfcDetailFAIGroup != "Y") {
                    if (
                      z.pfcDetailChargeCode != "OFT" &&
                      z.pfcDetailChargeCode != "FOF"
                    ) {
                      let mKey =
                        z.pfcDetailPCTab +
                        ";;" +
                        z.pfcDetailContainerType +
                        ";;" +
                        (z.pfcDetailD20 != 0 ? "20" : "-") +
                        ";;" +
                        (z.pfcDetailD40 != 0 ? "40" : "-") +
                        ";;" +
                        (z.pfcDetailD45 != 0 ? "45" : "-") +
                        ";;" +
                        (z.pfcDetailH20 != 0 ? "H20" : "-") +
                        ";;" +
                        (z.pfcDetailH40 != 0 ? "H40" : "-") +
                        ";;" +
                        (z.pfcDetailH45 != 0 ? "H45" : "-") +
                        ";;" +
                        z.pfcDetailCurrency +
                        ";;" +
                        z.pfcDetailPlaceOfPayment +
                        ";;" +
                        z.pfcDetailPayer +
                        ";;" +
                        z.pfcDetailPC +
                        ";;" +
                        (z.pfcDetailOogOH +
                          z.pfcDetailOogOLB +
                          z.pfcDetailOogOLF +
                          z.pfcDetailOogOWL +
                          z.pfcDetailOogOWR) +
                        ";;" +
                        z.pfcDetailOogDoorOpen +
                        ";;" +
                        (z.pfcDetailOogUcH +
                          z.pfcDetailOogUcL +
                          z.pfcDetailOogUcW) +
                        ";;" +
                        z.pfcDetailKillSlot;
                      if (mKey == updateKey) {
                        if (z.pfcDetailFAIGroup == "Y") {
                          x.pfcDetailFAIGroup = "Y";
                        }
                      }
                    }
                  }
                });
              }

              itemsToUpdate.push(x);
            }
          });

          this.gridApiPrepaid.updateRowData({ update: itemsToUpdate });
        } else {
          // collect
          var self = this;
          var itemsToUpdate = [];
          this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
            rowNode,
            index
          ) {
            let idx: number = self.modelDetail.pfcDetailSeqNo - 1;
            console.log(index + " - " + idx);
            var x: FreightDetail = rowNode.data;
            if (index == idx) {
              x.pfcDetailChargeCode = self.modelDetail.pfcDetailChargeCode;
              x.pfcDetailChargeName = self.modelDetail.pfcDetailChargeName;
              x.pfcDetailContainerType =
                self.modelDetail.pfcDetailContainerType;
              switch (self.modelDetail.pfcDetailContainerType) {
                case "STD":
                  self.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                  break;
                case "DRY":
                  self.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                  break;
                case "REF":
                  self.modelDetail.pfcDetailContainerTypeName = "REEFER";
                  break;
                case "TNK":
                  self.modelDetail.pfcDetailContainerTypeName = "TANK";
                  break;
                case "OPT":
                  self.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                  break;
                case "PLT":
                  self.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                  break;
                case "FLR":
                  self.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                  break;
                case "UC":
                  self.modelDetail.pfcDetailContainerTypeName = "UC";
                  break;
                default:
                  break;
              }
              x.pfcDetailContainerTypeName =
                self.modelDetail.pfcDetailContainerTypeName;

              x.pfcDetailCopyTab = self.modelDetail.pfcDetailCopyTab;
              x.pfcDetailCurrency = self.modelDetail.pfcDetailCurrency;
              x.pfcDetailD20 = self.modelDetail.pfcDetailD20;
              x.pfcDetailD40 = self.modelDetail.pfcDetailD40;
              x.pfcDetailD45 = self.modelDetail.pfcDetailD45;
              x.pfcDetailFAIGroup = self.modelDetail.pfcDetailFAIGroup;
              x.pfcDetailH20 = self.modelDetail.pfcDetailH20;
              x.pfcDetailH40 = self.modelDetail.pfcDetailH40;
              x.pfcDetailH45 = self.modelDetail.pfcDetailH45;
              x.pfcDetailKT = self.modelDetail.pfcDetailKT;
              x.pfcDetailKillSlot = self.modelDetail.pfcDetailKillSlot;
              x.pfcDetailM3 = self.modelDetail.pfcDetailM3;
              x.pfcDetailMinCharge = self.modelDetail.pfcDetailMinCharge;
              x.pfcDetailOogDoorOpen = self.modelDetail.pfcDetailOogDoorOpen;
              if (self.modelDetail.pfcDetailOogDoorOpen == "N") {
                self.modelDetail.pfcDetailOogDoorOpenName = "N/A";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "D") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Detached";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "B") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "L") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
              } else if (self.modelDetail.pfcDetailOogDoorOpen == "R") {
                self.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
              }
              x.pfcDetailOogDoorOpenName =
                self.modelDetail.pfcDetailOogDoorOpenName;

              x.pfcDetailOogOH = self.modelDetail.pfcDetailOogOH;
              x.pfcDetailOogOLB = self.modelDetail.pfcDetailOogOLB;
              x.pfcDetailOogOLF = self.modelDetail.pfcDetailOogOLF;
              x.pfcDetailOogOWL = self.modelDetail.pfcDetailOogOWL;
              x.pfcDetailOogOWR = self.modelDetail.pfcDetailOogOWR;
              x.pfcDetailOogUcH = self.modelDetail.pfcDetailOogUcH;
              x.pfcDetailOogUcL = self.modelDetail.pfcDetailOogUcL;
              x.pfcDetailOogUcW = self.modelDetail.pfcDetailOogUcW;
              x.pfcDetailPC = self.modelDetail.pfcDetailPC;
              x.pfcDetailPCTab = self.modelDetail.pfcDetailPCTab;
              x.pfcDetailPayer = self.modelDetail.pfcDetailPayer;
              x.pfcDetailPayerName = self.modelDetail.pfcDetailPayerName;
              x.pfcDetailPlaceOfPayment =
                self.modelDetail.pfcDetailPlaceOfPayment;
              x.pfcDetailPlaceOfPaymentName =
                self.modelDetail.pfcDetailPlaceOfPaymentName;
              x.pfcDetailRT = self.modelDetail.pfcDetailRT;
              x.pfcDetailSeqNo = self.modelDetail.pfcDetailSeqNo;
              x.pfcDetailStatus = self.modelDetail.pfcDetailStatus;
              x.no = x.pfcDetailSeqNo;

              updateKey =
                x.pfcDetailPCTab +
                ";;" +
                x.pfcDetailContainerType +
                ";;" +
                (x.pfcDetailD20 != 0 ? "20" : "-") +
                ";;" +
                (x.pfcDetailD40 != 0 ? "40" : "-") +
                ";;" +
                (x.pfcDetailD45 != 0 ? "45" : "-") +
                ";;" +
                (x.pfcDetailH20 != 0 ? "H20" : "-") +
                ";;" +
                (x.pfcDetailH40 != 0 ? "H40" : "-") +
                ";;" +
                (x.pfcDetailH45 != 0 ? "H45" : "-") +
                ";;" +
                x.pfcDetailCurrency +
                ";;" +
                x.pfcDetailPlaceOfPayment +
                ";;" +
                x.pfcDetailPayer +
                ";;" +
                x.pfcDetailPC +
                ";;" +
                (x.pfcDetailOogOH +
                  x.pfcDetailOogOLB +
                  x.pfcDetailOogOLF +
                  x.pfcDetailOogOWL +
                  x.pfcDetailOogOWR) +
                ";;" +
                x.pfcDetailOogDoorOpen +
                ";;" +
                (x.pfcDetailOogUcH + x.pfcDetailOogUcL + x.pfcDetailOogUcW) +
                ";;" +
                x.pfcDetailKillSlot;

              if (
                x.pfcDetailChargeCode != "OFT" &&
                x.pfcDetailChargeCode != "FOF"
              ) {
                self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
                  rowNode,
                  index
                ) {
                  var z: FreightDetail = rowNode.data;
                  console.log("nojan is here");
                  console.log(self.modelDetail.pfcDetailFAIGroup);
                  console.log(z.pfcDetailChargeCode);
                  if (self.modelDetail.pfcDetailFAIGroup == "Y") {
                    if (
                      z.pfcDetailChargeCode == "OFT" ||
                      z.pfcDetailChargeCode == "FOF"
                    ) {
                      let mKey =
                        z.pfcDetailPCTab +
                        ";;" +
                        z.pfcDetailContainerType +
                        ";;" +
                        (z.pfcDetailD20 != 0 ? "20" : "-") +
                        ";;" +
                        (z.pfcDetailD40 != 0 ? "40" : "-") +
                        ";;" +
                        (z.pfcDetailD45 != 0 ? "45" : "-") +
                        ";;" +
                        (z.pfcDetailH20 != 0 ? "H20" : "-") +
                        ";;" +
                        (z.pfcDetailH40 != 0 ? "H40" : "-") +
                        ";;" +
                        (z.pfcDetailH45 != 0 ? "H45" : "-") +
                        ";;" +
                        z.pfcDetailCurrency +
                        ";;" +
                        z.pfcDetailPlaceOfPayment +
                        ";;" +
                        z.pfcDetailPayer +
                        ";;" +
                        z.pfcDetailPC +
                        ";;" +
                        (z.pfcDetailOogOH +
                          z.pfcDetailOogOLB +
                          z.pfcDetailOogOLF +
                          z.pfcDetailOogOWL +
                          z.pfcDetailOogOWR) +
                        ";;" +
                        z.pfcDetailOogDoorOpen +
                        ";;" +
                        (z.pfcDetailOogUcH +
                          z.pfcDetailOogUcL +
                          z.pfcDetailOogUcW) +
                        ";;" +
                        z.pfcDetailKillSlot;
                      console.log(mKey + " vs " + updateKey);
                      if (mKey == updateKey) {
                        z.pfcDetailFAIGroup = "Y";

                        itemsToUpdate.push(z);
                      }
                    }
                  }
                });
              } else {
                self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
                  rowNode,
                  index
                ) {
                  var z: FreightDetail = rowNode.data;
                  if (self.modelDetail.pfcDetailFAIGroup != "Y") {
                    if (
                      z.pfcDetailChargeCode != "OFT" &&
                      z.pfcDetailChargeCode != "FOF"
                    ) {
                      let mKey =
                        z.pfcDetailPCTab +
                        ";;" +
                        z.pfcDetailContainerType +
                        ";;" +
                        (z.pfcDetailD20 != 0 ? "20" : "-") +
                        ";;" +
                        (z.pfcDetailD40 != 0 ? "40" : "-") +
                        ";;" +
                        (z.pfcDetailD45 != 0 ? "45" : "-") +
                        ";;" +
                        (z.pfcDetailH20 != 0 ? "H20" : "-") +
                        ";;" +
                        (z.pfcDetailH40 != 0 ? "H40" : "-") +
                        ";;" +
                        (z.pfcDetailH45 != 0 ? "H45" : "-") +
                        ";;" +
                        z.pfcDetailCurrency +
                        ";;" +
                        z.pfcDetailPlaceOfPayment +
                        ";;" +
                        z.pfcDetailPayer +
                        ";;" +
                        z.pfcDetailPC +
                        ";;" +
                        (z.pfcDetailOogOH +
                          z.pfcDetailOogOLB +
                          z.pfcDetailOogOLF +
                          z.pfcDetailOogOWL +
                          z.pfcDetailOogOWR) +
                        ";;" +
                        z.pfcDetailOogDoorOpen +
                        ";;" +
                        (z.pfcDetailOogUcH +
                          z.pfcDetailOogUcL +
                          z.pfcDetailOogUcW) +
                        ";;" +
                        z.pfcDetailKillSlot;
                      if (mKey == updateKey) {
                        if (z.pfcDetailFAIGroup == "Y") {
                          x.pfcDetailFAIGroup = "Y";
                        }
                      }
                    }
                  }
                });
              }

              itemsToUpdate.push(x);
            }
          });

          this.gridApiCollect.updateRowData({ update: itemsToUpdate });
        }

        console.log("update");

        // TOTAL jangan lupa
        this.calculateTotal();
      } else {
        //new

        var itemsToUpdate = [];
        if (
          this.map.get(this.model.pfcContainerType) == undefined ||
          this.map.get(this.model.pfcContainerType) == null
        ) {
          this.modelDetailList = [];

          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
          x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
          x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
          switch (this.modelDetail.pfcDetailContainerType) {
            case "STD":
              this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.pfcDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.pfcDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.pfcDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.pfcDetailContainerTypeName =
            this.modelDetail.pfcDetailContainerTypeName;

          x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
          x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
          x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
          x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
          x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
          x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
          x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
          x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
          x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
          x.pfcDetailKT = this.modelDetail.pfcDetailKT;
          x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
          x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
          x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
          if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
            this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogDoorOpenName =
            this.modelDetail.pfcDetailOogDoorOpenName;

          x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
          x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
          x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
          x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
          x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
          x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
          x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
          x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
          x.pfcDetailPC = this.modelDetail.pfcDetailPC;
          x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
          x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
          x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName =
            this.modelDetail.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = this.modelDetail.pfcDetailRT;
          x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
          x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
          x.no = this.modelDetail.pfcDetailSeqNo;

          this.modelDetailList.push(x);
          this.map.set(this.model.pfcContainerType, this.modelDetailList);
        } else {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
          x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
          x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
          switch (this.modelDetail.pfcDetailContainerType) {
            case "STD":
              this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.pfcDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.pfcDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.pfcDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.pfcDetailContainerTypeName =
            this.modelDetail.pfcDetailContainerTypeName;

          x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
          x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
          x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
          x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
          x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
          x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
          x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
          x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
          x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
          x.pfcDetailKT = this.modelDetail.pfcDetailKT;
          x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
          x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
          x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
          if (this.modelDetail.pfcDetailOogDoorOpen == "N") {
            this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "D") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "B") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "L") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
          } else if (this.modelDetail.pfcDetailOogDoorOpen == "R") {
            this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogDoorOpenName =
            this.modelDetail.pfcDetailOogDoorOpenName;

          x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
          x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
          x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
          x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
          x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
          x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
          x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
          x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
          x.pfcDetailPC = this.modelDetail.pfcDetailPC;
          x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
          x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
          x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName =
            this.modelDetail.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = this.modelDetail.pfcDetailRT;
          x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
          x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
          x.no = this.modelDetail.pfcDetailSeqNo;

          updateKey =
            x.pfcDetailPCTab +
            ";;" +
            x.pfcDetailContainerType +
            ";;" +
            (x.pfcDetailD20 != 0 ? "20" : "-") +
            ";;" +
            (x.pfcDetailD40 != 0 ? "40" : "-") +
            ";;" +
            (x.pfcDetailD45 != 0 ? "45" : "-") +
            ";;" +
            (x.pfcDetailH20 != 0 ? "H20" : "-") +
            ";;" +
            (x.pfcDetailH40 != 0 ? "H40" : "-") +
            ";;" +
            (x.pfcDetailH45 != 0 ? "H45" : "-") +
            ";;" +
            x.pfcDetailCurrency +
            ";;" +
            x.pfcDetailPlaceOfPayment +
            ";;" +
            x.pfcDetailPayer +
            ";;" +
            x.pfcDetailPC +
            ";;" +
            (x.pfcDetailOogOH +
              x.pfcDetailOogOLB +
              x.pfcDetailOogOLF +
              x.pfcDetailOogOWL +
              x.pfcDetailOogOWR) +
            ";;" +
            x.pfcDetailOogDoorOpen +
            ";;" +
            (x.pfcDetailOogUcH + x.pfcDetailOogUcL + x.pfcDetailOogUcW) +
            ";;" +
            x.pfcDetailKillSlot;

          if (
            x.pfcDetailChargeCode != "OFT" &&
            x.pfcDetailChargeCode != "FOF"
          ) {
            //self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
            var self = this;
            this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
              rowNode,
              index
            ) {
              var z: FreightDetail = rowNode.data;
              console.log("nojan is here");
              console.log(self.modelDetail.pfcDetailFAIGroup);
              console.log(z.pfcDetailChargeCode);
              if (self.modelDetail.pfcDetailFAIGroup == "Y") {
                if (
                  z.pfcDetailChargeCode == "OFT" ||
                  z.pfcDetailChargeCode == "FOF"
                ) {
                  let mKey =
                    z.pfcDetailPCTab +
                    ";;" +
                    z.pfcDetailContainerType +
                    ";;" +
                    (z.pfcDetailD20 != 0 ? "20" : "-") +
                    ";;" +
                    (z.pfcDetailD40 != 0 ? "40" : "-") +
                    ";;" +
                    (z.pfcDetailD45 != 0 ? "45" : "-") +
                    ";;" +
                    (z.pfcDetailH20 != 0 ? "H20" : "-") +
                    ";;" +
                    (z.pfcDetailH40 != 0 ? "H40" : "-") +
                    ";;" +
                    (z.pfcDetailH45 != 0 ? "H45" : "-") +
                    ";;" +
                    z.pfcDetailCurrency +
                    ";;" +
                    z.pfcDetailPlaceOfPayment +
                    ";;" +
                    z.pfcDetailPayer +
                    ";;" +
                    z.pfcDetailPC +
                    ";;" +
                    (z.pfcDetailOogOH +
                      z.pfcDetailOogOLB +
                      z.pfcDetailOogOLF +
                      z.pfcDetailOogOWL +
                      z.pfcDetailOogOWR) +
                    ";;" +
                    z.pfcDetailOogDoorOpen +
                    ";;" +
                    (z.pfcDetailOogUcH +
                      z.pfcDetailOogUcL +
                      z.pfcDetailOogUcW) +
                    ";;" +
                    z.pfcDetailKillSlot;
                  console.log(mKey + " vs " + updateKey);
                  if (mKey == updateKey) {
                    z.pfcDetailFAIGroup = "Y";

                    itemsToUpdate.push(z);
                  }
                }
              }
            });
          } else {
            //self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
            this.map.forEach((value: FreightDetail[], key: string) => {
              if (key == this.model.pfcContainerType) {
                value.forEach((eachRow) => {
                  var z: FreightDetail = eachRow;
                  if (this.modelDetail.pfcDetailFAIGroup != "Y") {
                    if (
                      z.pfcDetailChargeCode != "OFT" &&
                      z.pfcDetailChargeCode != "FOF"
                    ) {
                      let mKey =
                        z.pfcDetailPCTab +
                        ";;" +
                        z.pfcDetailContainerType +
                        ";;" +
                        (z.pfcDetailD20 != 0 ? "20" : "-") +
                        ";;" +
                        (z.pfcDetailD40 != 0 ? "40" : "-") +
                        ";;" +
                        (z.pfcDetailD45 != 0 ? "45" : "-") +
                        ";;" +
                        (z.pfcDetailH20 != 0 ? "H20" : "-") +
                        ";;" +
                        (z.pfcDetailH40 != 0 ? "H40" : "-") +
                        ";;" +
                        (z.pfcDetailH45 != 0 ? "H45" : "-") +
                        ";;" +
                        z.pfcDetailCurrency +
                        ";;" +
                        z.pfcDetailPlaceOfPayment +
                        ";;" +
                        z.pfcDetailPayer +
                        ";;" +
                        z.pfcDetailPC +
                        ";;" +
                        (z.pfcDetailOogOH +
                          z.pfcDetailOogOLB +
                          z.pfcDetailOogOLF +
                          z.pfcDetailOogOWL +
                          z.pfcDetailOogOWR) +
                        ";;" +
                        z.pfcDetailOogDoorOpen +
                        ";;" +
                        (z.pfcDetailOogUcH +
                          z.pfcDetailOogUcL +
                          z.pfcDetailOogUcW) +
                        ";;" +
                        z.pfcDetailKillSlot;
                      if (mKey == updateKey) {
                        if (z.pfcDetailFAIGroup == "Y") {
                          x.pfcDetailFAIGroup = "Y";
                          this.modelDetail.pfcDetailFAIGroup = "Y";
                        }
                      }
                    }
                  }
                });
              }
            });
          }

          this.map.get(this.model.pfcContainerType).push(x);
        }

        if (this.currentActiveTab == "prepaid") {
          //this.gridPrepaid.listStore.store.push(this.modelDetail);
          //this.gridPrepaid.loadData();
          this.gridApiPrepaid.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
          if (itemsToUpdate.length > 0) {
            this.gridApiPrepaid.updateRowData({ update: itemsToUpdate });
          }
        }

        if (this.currentActiveTab == "collect") {
          //this.gridCollect.listStore.store.push(this.modelDetail);
          //this.gridCollect.loadData();
          this.gridApiCollect.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
          if (itemsToUpdate.length > 0) {
            this.gridApiCollect.updateRowData({ update: itemsToUpdate });
          }
        }

        this.calculateTotal();
      }

      //// console.log('this.modelDetail.pfcDetailCopyTab => ' + this.modelDetail.pfcDetailCopyTab);
      if (this.modelDetail.pfcDetailCopyTab == "Y") {
        this.msg = "Are you sure you want to copy this charge(s)";
        this.genericUtil.showDialog(
          "confirmCopyDialog",
          "Confirmation",
          350,
          150
        );
      }

      this.isUpdateDetail = false;
      this.gridApiPrepaid.deselectAll();
      this.gridApiCollect.deselectAll();

      this.genericUtil.closeDialog("freightNewChargeForm");

      //MAPFAICHECKING
      let isMainChargeExistPrepaid: boolean = true;
      let isMainChargeExistCollect: boolean = true;
      mapFAICharge.forEach((value: FreightDetail[], key: string) => {
        console.log("key => " + key);
        console.log("size = > " + value.length);
        let countMain = 0;
        let countFAI = 0;
        value.forEach((each) => {
          if (this.currentActiveTab == "prepaid") {
            if (each.pfcDetailPCTab == "P") {
              countFAI = countFAI + 1;
              if (
                each.pfcDetailChargeCode == "OFT" ||
                each.pfcDetailChargeCode == "FOF"
              ) {
                countMain = countMain + 1;
              }
            }
          } else if (this.currentActiveTab == "collect") {
            if (each.pfcDetailPCTab == "C") {
              countFAI = countFAI + 1;
              if (
                each.pfcDetailChargeCode == "OFT" ||
                each.pfcDetailChargeCode == "FOF"
              ) {
                countMain = countMain + 1;
              }
            }
          }
        });

        console.log("Main FAI : " + countMain);
        if (this.currentActiveTab == "prepaid") {
          if (countFAI > 0) {
            if (countMain == 0) {
              isMainChargeExistPrepaid = false;
            }
          }
        } else if (this.currentActiveTab == "collect") {
          if (countFAI > 0) {
            if (countMain == 0) {
              isMainChargeExistCollect = false;
            }
          }
        }
      });

      if (isMainChargeExistPrepaid) {
        this.isFAIErrorPrepaid = false;
        this.messagesFAIErrorPrepaid = "";
      } else {
        this.isFAIErrorPrepaid = true;
        this.messagesFAIErrorPrepaid = "Main Charge is missing";
      }

      if (isMainChargeExistCollect) {
        this.isFAIErrorCollect = false;
        this.messagesFAIErrorCollect = "";
      } else {
        this.isFAIErrorCollect = true;
        this.messagesFAIErrorCollect = "Main Charge is missing";
      }
    }
    */
    let mapFAIGroup = new Map<String, FreightDetail[]>();
    let mapFAI = new Map<String, FreightDetail[]>();

    mapx.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        //if(element.pfcDetailFAIGroup == 'Y'){

        var key: String =
          element.pfcDetailPCTab +
          ";;" +
          element.pfcDetailContainerType +
          ";;" +
          element.pfcDetailCurrency +
          ";;" +
          element.pfcDetailPlaceOfPayment +
          ";;" +
          element.pfcDetailPayer +
          ";;" +
          element.pfcDetailPC +
          ";;" +
          (element.pfcDetailOogOH +
            element.pfcDetailOogOLB +
            element.pfcDetailOogOLF +
            element.pfcDetailOogOWL +
            element.pfcDetailOogOWR) +
          ";;" +
          element.pfcDetailOogDoorOpen +
          ";;" +
          (element.pfcDetailOogUcH +
            element.pfcDetailOogUcL +
            element.pfcDetailOogUcW) +
          ";;" +
          element.pfcDetailKillSlot;

        if (mapFAIGroup.get(key) == null) {
          let detsx: FreightDetail[] = [];
          detsx.push(element);
          mapFAIGroup.set(key, detsx);
        } else {
          mapFAIGroup.get(key).push(element);
        }

        //}
      });
    });

    mapFAIGroup.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        if (element.pfcDetailFAIGroup == "Y") {
          if (mapFAI.get(key) == null) {
            let detsx: FreightDetail[] = mapFAIGroup.get(key);
            mapFAI.set(key, detsx);
          } else {
            //mapFAI.get(key).push(element);
          }
        }
      });
    });

    if (this.modelDetail.pfcDetailFAIGroup == "Y") {
      let isErrorNotAllowed: boolean = true;
      let countFAI = 0;

      mapFAI.forEach((value: FreightDetail[], key: string) => {
        let isErrorNotAllowedx: boolean = true;

        var keyx: String =
          this.modelDetail.pfcDetailPCTab +
          ";;" +
          this.modelDetail.pfcDetailContainerType +
          ";;" +
          this.modelDetail.pfcDetailCurrency +
          ";;" +
          this.modelDetail.pfcDetailPlaceOfPayment +
          ";;" +
          this.modelDetail.pfcDetailPayer +
          ";;" +
          this.modelDetail.pfcDetailPC +
          ";;" +
          (this.modelDetail.pfcDetailOogOH +
            this.modelDetail.pfcDetailOogOLB +
            this.modelDetail.pfcDetailOogOLF +
            this.modelDetail.pfcDetailOogOWL +
            this.modelDetail.pfcDetailOogOWR) +
          ";;" +
          this.modelDetail.pfcDetailOogDoorOpen +
          ";;" +
          (this.modelDetail.pfcDetailOogUcH +
            this.modelDetail.pfcDetailOogUcL +
            this.modelDetail.pfcDetailOogUcW) +
          ";;" +
          this.modelDetail.pfcDetailKillSlot;

        if (key == keyx) {
          if (value.length == 1) {
            if (
              value[0].pfcDetailChargeCode == "OFT" ||
              value[0].pfcDetailChargeCode == "FOF"
            ) {
            } else {
              isErrorNotAllowedx = false;
            }
          }
        } else {
          //countFAI = value.length;
          value.forEach((each) => {
            if (this.currentActiveTab == "prepaid") {
              if (each.pfcDetailPCTab == "P") {
                countFAI = countFAI + 1;
              }
            } else if (this.currentActiveTab == "collect") {
              if (each.pfcDetailPCTab == "C") {
                countFAI = countFAI + 1;
              }
            }
          })
        }

        if (isErrorNotAllowedx == false && countFAI > 0) {
          isErrorNotAllowed = false;
        }
      });

      if( this.isErrorDetail == false){
        if (isErrorNotAllowed) {
        } else {
          this.isErrorDetail = true;
          this.modelDetail["error-pfcDetailFAIGroup"] =
            "FAI Grouping Not Allowed.";
        }
      }
    }

    console.log(mapFAI);
    console.log("this.isErrorDetail = " + this.isErrorDetail);

    if (!this.isErrorDetail) {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
      x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
      x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
      switch (this.modelDetail.pfcDetailContainerType) {
        case "STD":
          this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          this.modelDetail.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          this.modelDetail.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          this.modelDetail.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName =
        this.modelDetail.pfcDetailContainerTypeName;

      x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
      x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
      x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
      x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
      x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
      x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
      x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
      x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
      x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
      x.pfcDetailKT = this.modelDetail.pfcDetailKT;
      x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
      x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
      x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;

      if (x.pfcDetailOogDoorOpen == "N") {
        this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

      x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
      x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
      x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
      x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
      x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
      x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
      x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
      x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
      x.pfcDetailPC = this.modelDetail.pfcDetailPC;
      x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
      x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
      x.pfcDetailPayerIsPayer = this.modelDetail.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = this.modelDetail.pfcDetailPayerIsValid;
      x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName =
        this.modelDetail.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = this.modelDetail.pfcDetailRT;
      x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
      x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
      x.no = x.pfcDetailSeqNo;

      if (this.isUpdateDetail) {
        //update
        let prepaidList: FreightDetail[] = [];
        let collectList: FreightDetail[] = [];
        let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
        fd.forEach((element) => {
          if (element.pfcDetailPCTab == "P") {
            prepaidList.push(element);
          }
          if (element.pfcDetailPCTab == "C") {
            collectList.push(element);
          }
        });

        if (this.currentActiveTab == "prepaid") {
          prepaidList[x.pfcDetailSeqNo - 1] = x;
        } else {
          // collect
          collectList[x.pfcDetailSeqNo - 1] = x;
        }

        let dets: FreightDetail[] = [];
        prepaidList.forEach((element) => {
          dets.push(element);
        });
        collectList.forEach((element) => {
          dets.push(element);
        });
        this.map.set(this.model.pfcContainerType, dets);

        if (this.currentActiveTab == "prepaid") {
          //this.gridPrepaid.listStore.updateData(x.pfcDetailSeqNo - 1, x);
          //this.gridPrepaid.loadData();
          var rowDatax = [];
          this.gridApiPrepaid.forEachNode(function (node: any) {
            //console.log(node)
            if (node.rowIndex == x.pfcDetailSeqNo - 1) {
              node.data = x;
            }
            rowDatax.push(node.data);
          });

          this.gridApiPrepaid.updateRowData({ update: rowDatax });
        } else {
          // collect
          //this.gridCollect.listStore.updateData(x.pfcDetailSeqNo - 1, x);
          //this.gridCollect.loadData()
          var rowDatax = [];
          this.gridApiCollect.forEachNode(function (node: any) {
            //console.log(node)
            if (node.rowIndex == x.pfcDetailSeqNo - 1) {
              node.data = x;
            }
            rowDatax.push(node.data);
          });
          this.gridApiCollect.updateRowData({ update: rowDatax });
        }

        this.calculateTotal();
      } else {
        //new
        console.log("-- new --");
        console.log(this.modelDetail);

        var itemsToUpdate = [];

        if (
          this.map.get(this.model.pfcContainerType) == undefined ||
          this.map.get(this.model.pfcContainerType) == null
        ) {
          this.modelDetailList = [];
          //this.gridPrepaid.total = []
          //this.gridCollect.total = []
          this.modelDetailList.push(x);
          this.map.set(this.model.pfcContainerType, this.modelDetailList);
        } else {
          //this.gridPrepaid.total = []
          //this.gridCollect.total = []
          console.log(this.map.get(this.model.pfcContainerType));
          this.map.get(this.model.pfcContainerType).push(x);
        }

        if (this.currentActiveTab == "prepaid") {
          this.gridApiPrepaid.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
        }

        if (this.currentActiveTab == "collect") {
          this.gridApiCollect.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
        }

        this.calculateTotal();
      }

      if (this.currentActiveTab == "prepaid") {
        this.isFAIErrorPrepaid = false;
        this.messagesFAIErrorPrepaid = "";
      }

      if (this.currentActiveTab == "collect") {
        this.isFAIErrorCollect = false;
        this.messagesFAIErrorCollect = "";
      }

      if (this.modelDetail.pfcDetailFAIGroup == "Y") {
        //this.isErrorDetail = true;
        //this.modelDetail["error-pfcDetailFAIGroup"] = "FAI Grouping Not Allowed.";

        //this.isFAIErrorPrepaid = true;
        //this.messagesFAIErrorPrepaid = "Main Charge is missing";

        //this.isFAIErrorCollect = true;
        //this.messagesFAIErrorCollect = "Main Charge is missing";

        let isMainChargeExistPrepaid: boolean = true;
        let isMainChargeExistCollect: boolean = true;

        // --------
        mapFAI.forEach((value: FreightDetail[], key: string) => {
          console.log(key + " : ");
          let detsPrepaid: FreightDetail[] = [];
          let detsCollect: FreightDetail[] = [];

          let countMain = 0;
          let countFAI = 0;

          if (value.length > 1) {
            value.forEach((el: FreightDetail) => {
              if (this.currentActiveTab == "prepaid") {
                if (el.pfcDetailPCTab == "P") {
                  countFAI = countFAI + 1;
                  if (
                    el.pfcDetailChargeCode == "OFT" ||
                    el.pfcDetailChargeCode == "FOF"
                  ) {
                    countMain = countMain + 1;
                  }
                }
              } else {
                if (el.pfcDetailPCTab == "C") {
                  countFAI = countFAI + 1;
                  if (
                    el.pfcDetailChargeCode == "OFT" ||
                    el.pfcDetailChargeCode == "FOF"
                  ) {
                    countMain = countMain + 1;
                  }
                }
              }

              //console.log(el)
              if (
                el.pfcDetailChargeCode == "OFT" ||
                el.pfcDetailChargeCode == "FOF"
              ) {
                var x: FreightDetail = new FreightDetail();
                x.pfcDetailChargeCode = el.pfcDetailChargeCode;
                x.pfcDetailChargeName = el.pfcDetailChargeName;
                x.pfcDetailContainerType = el.pfcDetailContainerType;
                x.pfcDetailContainerTypeName = el.pfcDetailContainerTypeName;
                x.pfcDetailCopyTab = el.pfcDetailCopyTab;
                x.pfcDetailCurrency = el.pfcDetailCurrency;
                x.pfcDetailD20 = el.pfcDetailD20;
                x.pfcDetailD40 = el.pfcDetailD40;
                x.pfcDetailD45 = el.pfcDetailD45;
                //x.pfcDetailFAIGroup = el.pfcDetailFAIGroup;
                x.pfcDetailFAIGroup = "Y";
                x.pfcDetailH20 = el.pfcDetailH20;
                x.pfcDetailH40 = el.pfcDetailH40;
                x.pfcDetailH45 = el.pfcDetailH45;
                x.pfcDetailKT = el.pfcDetailKT;
                x.pfcDetailKillSlot = el.pfcDetailKillSlot;
                x.pfcDetailM3 = el.pfcDetailM3;
                x.pfcDetailMinCharge = el.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = el.pfcDetailOogDoorOpen;
                x.pfcDetailOogDoorOpenName = el.pfcDetailOogDoorOpenName;
                x.pfcDetailOogOH = el.pfcDetailOogOH;
                x.pfcDetailOogOLB = el.pfcDetailOogOLB;
                x.pfcDetailOogOLF = el.pfcDetailOogOLF;
                x.pfcDetailOogOWL = el.pfcDetailOogOWL;
                x.pfcDetailOogOWR = el.pfcDetailOogOWR;
                x.pfcDetailOogUcH = el.pfcDetailOogUcH;
                x.pfcDetailOogUcL = el.pfcDetailOogUcL;
                x.pfcDetailOogUcW = el.pfcDetailOogUcW;
                x.pfcDetailPC = el.pfcDetailPC;
                x.pfcDetailPCTab = el.pfcDetailPCTab;
                x.pfcDetailPayer = el.pfcDetailPayer;
                x.pfcDetailPayerName = el.pfcDetailPayerName;
                x.pfcDetailPayerIsPayer = el.pfcDetailPayerIsPayer;
                x.pfcDetailPayerIsValid = el.pfcDetailPayerIsValid;
                x.pfcDetailPlaceOfPayment = el.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName = el.pfcDetailPlaceOfPaymentName;
                x.pfcDetailRT = el.pfcDetailRT;
                x.pfcDetailSeqNo = el.pfcDetailSeqNo;
                x.pfcDetailStatus = el.pfcDetailStatus;
                x.no = x.pfcDetailSeqNo;

                if (el.pfcDetailPCTab == "P") {
                  detsPrepaid.push(x);
                }

                if (el.pfcDetailPCTab == "C") {
                  detsCollect.push(x);
                }

                let prepaidList: FreightDetail[] = [];
                let collectList: FreightDetail[] = [];
                let fd: FreightDetail[] = this.map.get(
                  x.pfcDetailContainerType
                );
                //console.log("fd : ")
                //console.log(fd)
                fd.forEach((element) => {
                  if (element.pfcDetailPCTab == "P") {
                    prepaidList.push(element);
                  }
                  if (element.pfcDetailPCTab == "C") {
                    collectList.push(element);
                  }
                });

                if (el.pfcDetailPCTab == "P") {
                  //console.log("P : ")
                  //console.log(x)
                  prepaidList[x.pfcDetailSeqNo - 1] = x;
                }

                if (el.pfcDetailPCTab == "C") {
                  //console.log("C : ")
                  //console.log(x)
                  collectList[x.pfcDetailSeqNo - 1] = x;
                }

                console.log("prepaidList : ");
                console.log(prepaidList);

                //console.log("collectList : ")
                //console.log(collectList)

                let dets: FreightDetail[] = [];
                prepaidList.forEach((element) => {
                  dets.push(element);
                });
                collectList.forEach((element) => {
                  dets.push(element);
                });
                this.map.set(x.pfcDetailContainerType, dets);
              }
            });
          } else if (value.length == 1) {
            value.forEach((el: FreightDetail) => {
              if (this.currentActiveTab == "prepaid") {
                if (el.pfcDetailPCTab == "P") {
                  countFAI = countFAI + 1;
                  if (
                    el.pfcDetailChargeCode == "OFT" ||
                    el.pfcDetailChargeCode == "FOF"
                  ) {
                    countMain = countMain + 1;
                  }
                }
              } else {
                if (el.pfcDetailPCTab == "C" && el.pfcDetailFAIGroup == "Y") {
                  countFAI = countFAI + 1;
                  if (
                    el.pfcDetailChargeCode == "OFT" ||
                    el.pfcDetailChargeCode == "FOF"
                  ) {
                    countMain = countMain + 1;
                  }
                }
              }
            });
          }

          if (this.currentActiveTab == "prepaid") {
            if (countFAI > 0) {
              if (countMain == 0) {
                isMainChargeExistPrepaid = false;
              }
            }
          } else if (this.currentActiveTab == "collect") {
            if (countFAI > 0) {
              if (countMain == 0) {
                isMainChargeExistCollect = false;
              }
            }
          }

          console.log(detsPrepaid.length);

          if (detsPrepaid.length > 0) {
            var rowDatax = [];
            this.gridApiPrepaid.forEachNode(function (node: any) {
              detsPrepaid.forEach((el: FreightDetail) => {
                console.log(el);
                if (node.rowIndex == el.pfcDetailSeqNo - 1) {
                  node.data = el;
                }
                rowDatax.push(node.data);
              });
            });
            this.gridApiPrepaid.updateRowData({ update: rowDatax });
          }

          //console.log(detsCollect.length)

          if (detsCollect.length > 0) {
            var rowDatax = [];
            this.gridApiCollect.forEachNode(function (node: any) {
              detsCollect.forEach((el: FreightDetail) => {
                //console.log(el)
                if (node.rowIndex == el.pfcDetailSeqNo - 1) {
                  node.data = el;
                }
                rowDatax.push(node.data);
              });
            });
            this.gridApiCollect.updateRowData({ update: rowDatax });
          }
        });

        if (this.currentActiveTab == "prepaid") {
          if (isMainChargeExistPrepaid) {
            this.isFAIErrorPrepaid = false;
            this.messagesFAIErrorPrepaid = "";
          } else {
            this.isFAIErrorPrepaid = true;
            this.messagesFAIErrorPrepaid = "Main Charge is missing";
          }
        }

        if (this.currentActiveTab == "collect") {
          if (isMainChargeExistCollect) {
            this.isFAIErrorCollect = false;
            this.messagesFAIErrorCollect = "";
          } else {
            this.isFAIErrorCollect = true;
            this.messagesFAIErrorCollect = "Main Charge is missing";
          }
        }

      }

      if (this.modelDetail.pfcDetailCopyTab == "Y") {
        this.msg = "Are you sure you want to copy this charge(s)";
        this.genericUtil.showDialog(
          "confirmCopyDialog",
          "Confirmation",
          350,
          150
        );
      }

      this.isUpdateDetail = false;

      this.gridApiPrepaid.deselectAll();
      this.gridApiCollect.deselectAll();
      this.genericUtil.closeDialog("freightNewChargeForm");
      if (this.listPayerExisting.length > 0) {
        this.custIdForCbPayer = this.listPayerExisting.join("%7C");
      }else{
        this.custIdForCbPayer = "*";
      }
    }
  }

  onNewCharge_Reset() {
    // console.log('** on new charge reset **');

    let seqNo = this.modelDetail.pfcDetailSeqNo;

    this.modelDetail = new FreightDetail();
    this.modelDetail.pfcDetailSeqNo = seqNo;

    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.pfcDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.pfcDetailChargeCode
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.pfcDetailPlaceOfPayment
    );

    if (
      this.cookieService.getDefaultLocationCountry().toUpperCase() ==
      this.model.pfcFreightPartyCountry.toUpperCase()
    ) {
      if (
        this.model.pfcFreightPartyId != null &&
        this.model.pfcFreightPartyId != undefined &&
        this.model.pfcFreightPartyId != ""
      ) {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
            // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerId={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
        );
        this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId);
      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
        );
        this.cbMaintenanceDetailPayer.setValue("**");
        this.fpPayer = "";
      }
    } else {
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayerWithCustomerType/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
      );
      this.cbMaintenanceDetailPayer.setValue("**");
      this.fpPayer = "";

    }

    // this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);
    this.isErrorDetail = false;
    //this.isUpdateDetail = false;
    this.gridApiPrepaid.deselectAll();
    this.gridApiCollect.deselectAll();
  }

  onNewCharge_Close() {
    // console.log('** on new charge close **');
    this.isErrorDetail = false;
    this.isUpdateDetail = false;
    this.gridApiPrepaid.deselectAll();
    this.gridApiCollect.deselectAll();
    if (this.listPayerExisting.length > 0) {
      this.custIdForCbPayer = this.listPayerExisting.join("%7C");
    }else{
      this.custIdForCbPayer = "*";
    }
    /*
       this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
       this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailChargeCode);
       this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
       this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);
       this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);
       this.isErrorDetail=false;
       */
    //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('hide');
    this.genericUtil.closeDialog("freightNewChargeForm");
  }

  checkDuplicateValidator(): boolean {
    console.log("** checkDuplicateValidator **");
    let isDuplicateCodeDetected = false;
    let msg = "";
    let self = this;

    /*


    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      var element :FreightDetail = rowNode.data;
      let snapCode: string = "";
      snapCode += element.pfcDetailChargeCode.trim();
      snapCode += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
      snapCode += element.pfcDetailOogDoorOpen.trim();
      snapCode += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
      snapCode += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
      snapCode += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
      snapCode += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
      snapCode += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
      snapCode += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
      snapCode += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
      snapCode += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();


      if(isDuplicateCodeDetected){
        console.log(msg)
        self.model['error-checkDuplicateValidator']= msg
        return
      }

      self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
        var el :FreightDetail = rowNode.data;
        let snapCodeComparer: string = "";
        snapCodeComparer += el.pfcDetailChargeCode.trim();
        snapCodeComparer += (el.pfcDetailKillSlot == null) ? "0" : el.pfcDetailKillSlot.toString();
        snapCodeComparer += el.pfcDetailOogDoorOpen.trim();
        snapCodeComparer += (el.pfcDetailOogOH == null) ? "0" : el.pfcDetailOogOH.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogOWL == null) ? "0" : el.pfcDetailOogOWL.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogOWR == null) ? "0" : el.pfcDetailOogOWR.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogOLF == null) ? "0" : el.pfcDetailOogOLF.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogOLB == null) ? "0" : el.pfcDetailOogOLB.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogUcH == null) ? "0" : el.pfcDetailOogUcH.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogUcL == null) ? "0" : el.pfcDetailOogUcL.toLocaleString();
        snapCodeComparer += (el.pfcDetailOogUcW == null) ? "0" : el.pfcDetailOogUcW.toLocaleString();

        if(element.pfcDetailSeqNo != el.pfcDetailSeqNo){
          if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              msg = "Duplicate " + el.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
              console.log(msg)
              return
          }
      }
      });

    });

    if(!isDuplicateCodeDetected){
      this.gridApiCollect.forEachNodeAfterFilterAndSort(function(rowNode, index) {
        var element :FreightDetail = rowNode.data;
        let snapCode: string = "";
        snapCode += element.pfcDetailChargeCode.trim();
        snapCode += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
        snapCode += element.pfcDetailOogDoorOpen.trim();
        snapCode += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
        snapCode += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
        snapCode += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
        snapCode += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
        snapCode += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
        snapCode += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
        snapCode += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
        snapCode += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();


        if(isDuplicateCodeDetected){
          console.log(msg)
          self.model['error-checkDuplicateValidator']= msg
          return
        }

        self.gridApiCollect.forEachNodeAfterFilterAndSort(function(rowNode, index) {
          var el :FreightDetail = rowNode.data;
          let snapCodeComparer: string = "";
          snapCodeComparer += el.pfcDetailChargeCode.trim();
          snapCodeComparer += (el.pfcDetailKillSlot == null) ? "0" : el.pfcDetailKillSlot.toString();
          snapCodeComparer += el.pfcDetailOogDoorOpen.trim();
          snapCodeComparer += (el.pfcDetailOogOH == null) ? "0" : el.pfcDetailOogOH.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogOWL == null) ? "0" : el.pfcDetailOogOWL.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogOWR == null) ? "0" : el.pfcDetailOogOWR.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogOLF == null) ? "0" : el.pfcDetailOogOLF.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogOLB == null) ? "0" : el.pfcDetailOogOLB.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogUcH == null) ? "0" : el.pfcDetailOogUcH.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogUcL == null) ? "0" : el.pfcDetailOogUcL.toLocaleString();
          snapCodeComparer += (el.pfcDetailOogUcW == null) ? "0" : el.pfcDetailOogUcW.toLocaleString();

          if(element.pfcDetailSeqNo != el.pfcDetailSeqNo){
              if (snapCodeComparer == snapCode) {
                  isDuplicateCodeDetected = true;
                  msg = "Duplicate " + el.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
                  console.log(msg)
                  return
              }
          }
        });

      });
    }
    */
    let result: Boolean = false;
    let str: String = "";

    let mapCTTab = new Map();

    this.map.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        let snapCode: string = "";
        snapCode += element.pfcDetailChargeCode.trim();
        snapCode +=
          element.pfcDetailKillSlot == null
            ? "0"
            : element.pfcDetailKillSlot.toString();
        snapCode += element.pfcDetailOogDoorOpen.trim();
        snapCode +=
          element.pfcDetailOogOH == null
            ? "0"
            : element.pfcDetailOogOH.toLocaleString();
        snapCode +=
          element.pfcDetailOogOWL == null
            ? "0"
            : element.pfcDetailOogOWL.toLocaleString();
        snapCode +=
          element.pfcDetailOogOWR == null
            ? "0"
            : element.pfcDetailOogOWR.toLocaleString();
        snapCode +=
          element.pfcDetailOogOLF == null
            ? "0"
            : element.pfcDetailOogOLF.toLocaleString();
        snapCode +=
          element.pfcDetailOogOLB == null
            ? "0"
            : element.pfcDetailOogOLB.toLocaleString();
        snapCode +=
          element.pfcDetailOogUcH == null
            ? "0"
            : element.pfcDetailOogUcH.toLocaleString();
        snapCode +=
          element.pfcDetailOogUcL == null
            ? "0"
            : element.pfcDetailOogUcL.toLocaleString();
        snapCode +=
          element.pfcDetailOogUcW == null
            ? "0"
            : element.pfcDetailOogUcW.toLocaleString();

        let key: string =
          element.pfcDetailContainerType +
          "-" +
          element.pfcDetailPCTab +
          "/" +
          element.pfcDetailChargeCode +
          ":" +
          snapCode;

        if (mapCTTab.get(key) == undefined) {
          let datas: FreightDetail[] = [];
          datas.push(element);
          mapCTTab.set(key, datas);
        } else {
          mapCTTab.get(key).push(element);
        }
      });
    });

    console.log(mapCTTab);

    let mapCTTabStr = new Map();
    mapCTTab.forEach((value: FreightDetail[], key: string) => {
      if (value.length > 1) {
        let strCtLists2: string[] = key.split(":");
        let strCtLists: string[] = strCtLists2[0].split("/");

        strCtLists[0] = strCtLists[0].replace(/TNK/g, "TANK");
        strCtLists[0] = strCtLists[0].replace(/OPT/g, "OPEN TOP");
        strCtLists[0] = strCtLists[0].replace(/FLR/g, "FLAT RACK");
        strCtLists[0] = strCtLists[0].replace(/PLT/g, "PLATFORM");
        strCtLists[0] = strCtLists[0].replace(/REF/g, "REEFER");
        strCtLists[0] = strCtLists[0].replace(/STD/g, "NORMAL");
        strCtLists[0] = strCtLists[0].replace(/UC/g, "UC");
        strCtLists[0] = strCtLists[0].replace(/-P/g, " [PREPAID]");
        strCtLists[0] = strCtLists[0].replace(/-C/g, " [COLLECT]");

        //str += strCtLists[0] + ",";
        //result = true;
        if (mapCTTabStr.get(strCtLists[1]) == undefined) {
          let ctListStr: any[] = [];
          ctListStr.push(strCtLists[0]);
          mapCTTabStr.set(strCtLists[1], ctListStr);
        } else {
          mapCTTabStr.get(strCtLists[1]).push(strCtLists[0]);
        }
      }
    });

    console.log(mapCTTabStr);

    if (mapCTTabStr.size > 0) {
      str += "";
      mapCTTabStr.forEach((value: any[], key: string) => {
        str +=
          "Duplicate " +
          key +
          " charge code detected." +
          value.toString() +
          ",<br/>";
      });
      console.log(str.substring(0, str.length - 6));
      this.model["error-checkDuplicateValidator"] =
        str.substring(0, str.length - 6) + ".";
      isDuplicateCodeDetected = true;
    }

    return isDuplicateCodeDetected;
  }

  checkDuplicate(target: string, modelDetail: FreightDetail): boolean {
    // console.log('** check duplicate **');
    let isDuplicateCodeDetected = false;
    let snapCode: string = "";

    //snapCode += modelDetail.pfcDetailPC.trim();
    snapCode += modelDetail.pfcDetailChargeCode.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode +=
      modelDetail.pfcDetailKillSlot == null
        ? "0"
        : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode +=
      modelDetail.pfcDetailOogOH == null
        ? "0"
        : modelDetail.pfcDetailOogOH.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWL == null
        ? "0"
        : modelDetail.pfcDetailOogOWL.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWR == null
        ? "0"
        : modelDetail.pfcDetailOogOWR.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLF == null
        ? "0"
        : modelDetail.pfcDetailOogOLF.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLB == null
        ? "0"
        : modelDetail.pfcDetailOogOLB.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcH == null
        ? "0"
        : modelDetail.pfcDetailOogUcH.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcL == null
        ? "0"
        : modelDetail.pfcDetailOogUcL.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcW == null
        ? "0"
        : modelDetail.pfcDetailOogUcW.toString();

    if (target == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          if (modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo) {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer +=
              element.pfcDetailKillSlot == null
                ? "0"
                : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.pfcDetailOogOH == null
                ? "0"
                : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWL == null
                ? "0"
                : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWR == null
                ? "0"
                : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLF == null
                ? "0"
                : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLB == null
                ? "0"
                : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcH == null
                ? "0"
                : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcL == null
                ? "0"
                : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcW == null
                ? "0"
                : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          if (modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo) {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer +=
              element.pfcDetailKillSlot == null
                ? "0"
                : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.pfcDetailOogOH == null
                ? "0"
                : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWL == null
                ? "0"
                : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWR == null
                ? "0"
                : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLF == null
                ? "0"
                : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLB == null
                ? "0"
                : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcH == null
                ? "0"
                : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcL == null
                ? "0"
                : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcW == null
                ? "0"
                : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    /*
    //snapCode += modelDetail.pfcDetailPC.trim();
    snapCode += modelDetail.pfcDetailChargeCode.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode += (modelDetail.pfcDetailKillSlot == null) ? '0' : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode += (modelDetail.pfcDetailOogOH == null) ? '0' : modelDetail.pfcDetailOogOH.toString();
    snapCode += (modelDetail.pfcDetailOogOWL == null) ? '0' : modelDetail.pfcDetailOogOWL.toString();
    snapCode += (modelDetail.pfcDetailOogOWR == null) ? '0' : modelDetail.pfcDetailOogOWR.toString();
    snapCode += (modelDetail.pfcDetailOogOLF == null) ? '0' : modelDetail.pfcDetailOogOLF.toString();
    snapCode += (modelDetail.pfcDetailOogOLB == null) ? '0' : modelDetail.pfcDetailOogOLB.toString();
    snapCode += (modelDetail.pfcDetailOogUcH == null) ? '0' : modelDetail.pfcDetailOogUcH.toString();
    snapCode += (modelDetail.pfcDetailOogUcL == null) ? '0' : modelDetail.pfcDetailOogUcL.toString();
    snapCode += (modelDetail.pfcDetailOogUcW == null) ? '0' : modelDetail.pfcDetailOogUcW.toString();

    if (target == 'prepaid') {
      if (this.gridPrepaid.listStore.store.length > 0) {
        this.gridPrepaid.listStore.store.forEach(element => {
          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparer: string = '';
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      if (this.gridCollect.listStore.store.length > 0) {
        this.gridCollect.listStore.store.forEach(element => {
          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparer: string = '';
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }
    */

    return isDuplicateCodeDetected;
  }

  checkDuplicateMainCharges(
    target: string,
    modelDetail: FreightDetail
  ): boolean {
    console.log("** check duplicate **");
    //let checkMap = new Map<String,String>();
    let result: boolean = false;
    let isDuplicateCodeDetected: boolean = false;
    let snapCode: string = "";
    let snapCodeMain: string = "";

    snapCodeMain += modelDetail.pfcDetailChargeCode.trim();

    //snapCode += modelDetail.pfcDetailPC.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode +=
      modelDetail.pfcDetailKillSlot == null
        ? "0"
        : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode +=
      modelDetail.pfcDetailOogOH == null
        ? "0"
        : modelDetail.pfcDetailOogOH.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWL == null
        ? "0"
        : modelDetail.pfcDetailOogOWL.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWR == null
        ? "0"
        : modelDetail.pfcDetailOogOWR.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLF == null
        ? "0"
        : modelDetail.pfcDetailOogOLF.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLB == null
        ? "0"
        : modelDetail.pfcDetailOogOLB.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcH == null
        ? "0"
        : modelDetail.pfcDetailOogUcH.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcL == null
        ? "0"
        : modelDetail.pfcDetailOogUcL.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcW == null
        ? "0"
        : modelDetail.pfcDetailOogUcW.toString();

    if (target == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          if (modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo) {
            let snapCodeComparerMain: string = "";
            let snapCodeComparer: string = "";

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer +=
              element.pfcDetailKillSlot == null
                ? "0"
                : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.pfcDetailOogOH == null
                ? "0"
                : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWL == null
                ? "0"
                : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWR == null
                ? "0"
                : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLF == null
                ? "0"
                : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLB == null
                ? "0"
                : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcH == null
                ? "0"
                : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcL == null
                ? "0"
                : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcW == null
                ? "0"
                : element.pfcDetailOogUcW.toLocaleString();

            if (
              element.pfcDetailChargeCode == "FAI" ||
              element.pfcDetailChargeCode == "OFT" ||
              element.pfcDetailChargeCode == "FOF"
            ) {
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (
                snapCodeMain + snapCode ==
                snapCodeComparerMain + snapCodeComparer
              ) {
                isDuplicateCodeDetected = true;
              }
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          if (modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo) {
            let snapCodeComparerMain: string = "";
            let snapCodeComparer: string = "";

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer +=
              element.pfcDetailKillSlot == null
                ? "0"
                : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.pfcDetailOogOH == null
                ? "0"
                : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWL == null
                ? "0"
                : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOWR == null
                ? "0"
                : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLF == null
                ? "0"
                : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogOLB == null
                ? "0"
                : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcH == null
                ? "0"
                : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcL == null
                ? "0"
                : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.pfcDetailOogUcW == null
                ? "0"
                : element.pfcDetailOogUcW.toLocaleString();

            if (
              element.pfcDetailChargeCode == "FAI" ||
              element.pfcDetailChargeCode == "OFT" ||
              element.pfcDetailChargeCode == "FOF"
            ) {
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (
                snapCodeMain + snapCode ==
                snapCodeComparerMain + snapCodeComparer
              ) {
                isDuplicateCodeDetected = true;
              }
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    /*

    snapCodeMain += modelDetail.pfcDetailChargeCode.trim();

    //snapCode += modelDetail.pfcDetailPC.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode += (modelDetail.pfcDetailKillSlot == null) ? '0' : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode += (modelDetail.pfcDetailOogOH == null) ? '0' : modelDetail.pfcDetailOogOH.toString();
    snapCode += (modelDetail.pfcDetailOogOWL == null) ? '0' : modelDetail.pfcDetailOogOWL.toString();
    snapCode += (modelDetail.pfcDetailOogOWR == null) ? '0' : modelDetail.pfcDetailOogOWR.toString();
    snapCode += (modelDetail.pfcDetailOogOLF == null) ? '0' : modelDetail.pfcDetailOogOLF.toString();
    snapCode += (modelDetail.pfcDetailOogOLB == null) ? '0' : modelDetail.pfcDetailOogOLB.toString();
    snapCode += (modelDetail.pfcDetailOogUcH == null) ? '0' : modelDetail.pfcDetailOogUcH.toString();
    snapCode += (modelDetail.pfcDetailOogUcL == null) ? '0' : modelDetail.pfcDetailOogUcL.toString();
    snapCode += (modelDetail.pfcDetailOogUcW == null) ? '0' : modelDetail.pfcDetailOogUcW.toString();


    if (target == 'prepaid') {
      if (this.gridPrepaid.listStore.store.length > 0) {
        this.gridPrepaid.listStore.store.forEach(element => {

          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparerMain: string = '';
            let snapCodeComparer: string = '';

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if(element.pfcDetailChargeCode == "FAI" || element.pfcDetailChargeCode == "OFT" || element.pfcDetailChargeCode == "FOF"){
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (snapCodeMain+snapCode == snapCodeComparerMain+snapCodeComparer) {
                isDuplicateCodeDetected = true;
              }
            }

          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }

    } else {
      if (this.gridCollect.listStore.store.length > 0) {
        this.gridCollect.listStore.store.forEach(element => {
          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparerMain: string = '';
            let snapCodeComparer: string = '';

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if(element.pfcDetailChargeCode == "FAI" || element.pfcDetailChargeCode == "OFT" || element.pfcDetailChargeCode == "FOF"){
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (snapCodeMain+snapCode == snapCodeComparerMain+snapCodeComparer) {
                isDuplicateCodeDetected = true;
              }
            }

          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }
    */

    return result;
  }

  checkDuplicateCopy(target: string, modelDetail: FreightDetail): boolean {
    // console.log('** check duplicate for copy **');
    let isDuplicateCodeDetected = false;
    let snapCode: string = "";

    //snapCode += modelDetail.pfcDetailPC.trim();
    snapCode += modelDetail.pfcDetailChargeCode.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode +=
      modelDetail.pfcDetailKillSlot == null
        ? "0"
        : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode +=
      modelDetail.pfcDetailOogOH == null
        ? "0"
        : modelDetail.pfcDetailOogOH.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWL == null
        ? "0"
        : modelDetail.pfcDetailOogOWL.toString();
    snapCode +=
      modelDetail.pfcDetailOogOWR == null
        ? "0"
        : modelDetail.pfcDetailOogOWR.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLF == null
        ? "0"
        : modelDetail.pfcDetailOogOLF.toString();
    snapCode +=
      modelDetail.pfcDetailOogOLB == null
        ? "0"
        : modelDetail.pfcDetailOogOLB.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcH == null
        ? "0"
        : modelDetail.pfcDetailOogUcH.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcL == null
        ? "0"
        : modelDetail.pfcDetailOogUcL.toString();
    snapCode +=
      modelDetail.pfcDetailOogUcW == null
        ? "0"
        : modelDetail.pfcDetailOogUcW.toString();

    if (target == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          let snapCodeComparer: string = "";
          //snapCodeComparer += element.pfcDetailPC.trim();
          snapCodeComparer += element.pfcDetailChargeCode.trim();
          //snapCodeComparer += element.pfcDetailCurrency.trim();
          //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.pfcDetailPayer.trim();
          snapCodeComparer +=
            element.pfcDetailKillSlot == null
              ? "0"
              : element.pfcDetailKillSlot.toString();
          snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
          snapCodeComparer +=
            element.pfcDetailOogOH == null
              ? "0"
              : element.pfcDetailOogOH.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOWL == null
              ? "0"
              : element.pfcDetailOogOWL.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOWR == null
              ? "0"
              : element.pfcDetailOogOWR.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOLF == null
              ? "0"
              : element.pfcDetailOogOLF.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOLB == null
              ? "0"
              : element.pfcDetailOogOLB.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcH == null
              ? "0"
              : element.pfcDetailOogUcH.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcL == null
              ? "0"
              : element.pfcDetailOogUcL.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcW == null
              ? "0"
              : element.pfcDetailOogUcW.toLocaleString();

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;
            //this.isErrorDetail = true;
            //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: FreightDetail = rowNode.data;

          let snapCodeComparer: string = "";
          //snapCodeComparer += element.pfcDetailPC.trim();
          snapCodeComparer += element.pfcDetailChargeCode.trim();
          //snapCodeComparer += element.pfcDetailCurrency.trim();
          //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.pfcDetailPayer.trim();
          snapCodeComparer +=
            element.pfcDetailKillSlot == null
              ? "0"
              : element.pfcDetailKillSlot.toString();
          snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
          snapCodeComparer +=
            element.pfcDetailOogOH == null
              ? "0"
              : element.pfcDetailOogOH.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOWL == null
              ? "0"
              : element.pfcDetailOogOWL.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOWR == null
              ? "0"
              : element.pfcDetailOogOWR.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOLF == null
              ? "0"
              : element.pfcDetailOogOLF.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogOLB == null
              ? "0"
              : element.pfcDetailOogOLB.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcH == null
              ? "0"
              : element.pfcDetailOogUcH.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcL == null
              ? "0"
              : element.pfcDetailOogUcL.toLocaleString();
          snapCodeComparer +=
            element.pfcDetailOogUcW == null
              ? "0"
              : element.pfcDetailOogUcW.toLocaleString();

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;
            //this.isErrorDetail = true;
            //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    /*
    //snapCode += modelDetail.pfcDetailPC.trim();
    snapCode += modelDetail.pfcDetailChargeCode.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode += (modelDetail.pfcDetailKillSlot == null) ? '0' : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode += (modelDetail.pfcDetailOogOH == null) ? '0' : modelDetail.pfcDetailOogOH.toString();
    snapCode += (modelDetail.pfcDetailOogOWL == null) ? '0' : modelDetail.pfcDetailOogOWL.toString();
    snapCode += (modelDetail.pfcDetailOogOWR == null) ? '0' : modelDetail.pfcDetailOogOWR.toString();
    snapCode += (modelDetail.pfcDetailOogOLF == null) ? '0' : modelDetail.pfcDetailOogOLF.toString();
    snapCode += (modelDetail.pfcDetailOogOLB == null) ? '0' : modelDetail.pfcDetailOogOLB.toString();
    snapCode += (modelDetail.pfcDetailOogUcH == null) ? '0' : modelDetail.pfcDetailOogUcH.toString();
    snapCode += (modelDetail.pfcDetailOogUcL == null) ? '0' : modelDetail.pfcDetailOogUcL.toString();
    snapCode += (modelDetail.pfcDetailOogUcW == null) ? '0' : modelDetail.pfcDetailOogUcW.toString();

    if (target == 'prepaid') {
      if (this.gridPrepaid.listStore.store.length > 0) {
        this.gridPrepaid.listStore.store.forEach(element => {
          let snapCodeComparer: string = '';
          //snapCodeComparer += element.pfcDetailPC.trim();
          snapCodeComparer += element.pfcDetailChargeCode.trim();
          //snapCodeComparer += element.pfcDetailCurrency.trim();
          //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.pfcDetailPayer.trim();
          snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
          snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
          snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;
            //this.isErrorDetail = true;
            //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
          }

        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      if (this.gridCollect.listStore.store.length > 0) {
        this.gridCollect.listStore.store.forEach(element => {
          let snapCodeComparer: string = '';
          //snapCodeComparer += element.pfcDetailPC.trim();
          snapCodeComparer += element.pfcDetailChargeCode.trim();
          //snapCodeComparer += element.pfcDetailCurrency.trim();
          //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.pfcDetailPayer.trim();
          snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
          snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
          snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
          snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;
            //this.isErrorDetail = true;
            //this.modelDetail['error-pfcDuplicateChargeCode']='Duplicate ' + element.pfcDetailChargeCode.toUpperCase().trim() + ' charge code detected.'
          }

        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }
    */

    return isDuplicateCodeDetected;
  }

  setValidatorSave() {
    this.clearValidatorError();
    this.validatorRules = {
      pfcFreightPartyId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Freight Party.",
          },
        ],
      },
      pfcPlaceOfReceiptCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Receipt.",
          },
        ],
      },
      pfcLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Load.",
          },
        ],
      },
      pfcDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Discharge.",
          },
        ],
      },
      pfcPlaceOfDeliveryCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Delivery.",
          },
        ],
      },
      pfcReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Receipt Type.",
          },
        ],
      },
      pfcDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Delivery Type.",
          },
        ],
      },
      pfcEffectiveFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Effective From.",
          },
        ],
      },
      pfcEffectiveTo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Expiry On.",
          },
        ],
      },
      pfcAcctPic: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Acct PIC.",
          },
        ],
      },
      effectiveDateExceedValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Effective period cannot exceed a year.",
          },
        ],
      },
      expiryDateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Expiry On Date must be later than Effective From.",
          },
        ],
      },
      samePORValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Place Of Receipt should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePOLValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Load should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePODValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Discharge should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePODLValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Place of Delivery should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      sameFDestValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Final Destination should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      blankFreightChargesValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Blank Freight Charge detected.",
          },
        ],
      },
      missingMainChargesValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Missing Main Charges detected.",
          },
        ],
      },
      mainChargesOnlyOneValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Main Charges just allow only one.",
          },
        ],
      },
      blankDetentionRequestValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input detention.",
          },
        ],
      },
      blankDemurrageRequestValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input demurrage.",
          },
        ],
      },
      blankAdhocVoyageValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input AdHoc Voyage.",
          },
        ],
      },
      blankPayerValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Unable to Save Due to MISSING PAYER for PREPAID CHARGE(S).",
          },
        ],
      },
      exceedPayerPOPValidator: {
        rules: [
          {
            type: "custom",
            prompt: "“Place Of Payment” / “Payer” exceeds 3, Unable to Save.",
          },
        ],
      },
      faiGroupValidator: {
        rules: [
          {
            type: "custom",
            prompt: "FAI Group Not Valid, Unable to Save.",
          },
        ],
      },
      checkDuplicateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Duplicate data detected.",
          },
        ],
      },
      cbMaintenanceAdhocVessel: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      cbMaintenanceAdhocVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      invalidPayerValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Unable to Save Due to invalid PAYER for PREPAID CHARGE(S).",
          },
        ],
      },

      allInvalidPayerValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Unable to Save with extend Exipry On Due to invalid PAYER for PREPAID CHARGE(S).",
          },
        ],
      },
    };
  }

  setValidatorSurcharge() {
    this.clearValidatorError();
    this.validatorRules = {
      pfcFreightPartyId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Freight Party.",
          },
        ],
      },
      pfcPlaceOfReceiptCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Receipt.",
          },
        ],
      },
      pfcLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Load.",
          },
        ],
      },
      pfcDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Discharge.",
          },
        ],
      },
      pfcPlaceOfDeliveryCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Delivery.",
          },
        ],
      },
      pfcReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Receipt Type.",
          },
        ],
      },
      pfcDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Delivery Type.",
          },
        ],
      },
      pfcEffectiveFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Effective From.",
          },
        ],
      },
      pfcEffectiveTo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Expiry On.",
          },
        ],
      },
      effectiveDateExceedValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Effective period cannot exceed a year.",
          },
        ],
      },
      expiryDateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Expiry On Date must be later than Effective From.",
          },
        ],
      },
      blankFreightChargesValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Blank Freight Charge detected.",
          },
        ],
      },
      missingMainChargesValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Missing Main Charges detected.",
          },
        ],
      },
    };
  }

  checkEffectiveDateExceedValidator(): Boolean {
    let result: Boolean = false;
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var diffDays = Math.floor(
      Math.abs(
        (new Date(this.model.pfcEffectiveTo).getTime() -
          new Date(this.model.pfcEffectiveFrom).getTime()) /
          oneDay
      )
    );
    if (diffDays > 366) {
      return true;
    }
    return result;
  }

  checkExpiryDateValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.pfcEffectiveTo != "") {
      if (this.model.pfcEffectiveTo <= this.model.pfcEffectiveFrom) {
        this.model["error-expiryDateValidator"] =
          "Expiry On Date (" +
          this.model.pfcEffectiveTo.toString() +
          ") must be later than Effective From.";
        result = true;
      }
    }

    return result;
  }

  checkSamePORValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcDischargePortCode != ""
    ) {
      if (this.model.pfcPlaceOfReceiptCode == this.model.pfcDischargePortCode) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Discharge.";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcPlaceOfDeliveryCode != ""
    ) {
      if (
        this.model.pfcPlaceOfReceiptCode == this.model.pfcPlaceOfDeliveryCode
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Place Of Delivery.";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcFinalDestinationCode != ""
    ) {
      if (
        this.model.pfcPlaceOfReceiptCode == this.model.pfcFinalDestinationCode
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Final Destination.";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.pfcPlaceOfReceiptCode ==
        this.model.pfcPortOfTransshipment1Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.pfcPlaceOfReceiptCode ==
        this.model.pfcPortOfTransshipment2Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfReceiptCode != "" &&
      this.model.pfcPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.pfcPlaceOfReceiptCode ==
        this.model.pfcPortOfTransshipment3Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePOLValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcDischargePortCode != ""
    ) {
      if (this.model.pfcLoadPortCode == this.model.pfcDischargePortCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Discharge.";
        return true;
      }
    }

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcPlaceOfDeliveryCode != ""
    ) {
      if (this.model.pfcLoadPortCode == this.model.pfcPlaceOfDeliveryCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Place Of Delivery.";
        return true;
      }
    }

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcFinalDestinationCode != ""
    ) {
      if (this.model.pfcLoadPortCode == this.model.pfcFinalDestinationCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Final Destination.";
        return true;
      }
    }

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment1Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment2Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.pfcLoadPortCode != "" &&
      this.model.pfcPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment3Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePODValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.pfcDischargePortCode != "" &&
      this.model.pfcPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.pfcDischargePortCode ==
        this.model.pfcPortOfTransshipment1Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.pfcDischargePortCode != "" &&
      this.model.pfcPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.pfcDischargePortCode ==
        this.model.pfcPortOfTransshipment2Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.pfcDischargePortCode != "" &&
      this.model.pfcPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.pfcDischargePortCode ==
        this.model.pfcPortOfTransshipment3Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePODLValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.pfcPlaceOfDeliveryCode != "" &&
      this.model.pfcPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.pfcPlaceOfDeliveryCode ==
        this.model.pfcPortOfTransshipment1Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfDeliveryCode != "" &&
      this.model.pfcPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.pfcPlaceOfDeliveryCode ==
        this.model.pfcPortOfTransshipment2Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.pfcPlaceOfDeliveryCode != "" &&
      this.model.pfcPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.pfcPlaceOfDeliveryCode ==
        this.model.pfcPortOfTransshipment3Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSameFDestValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.pfcFinalDestinationCode != "" &&
      this.model.pfcPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.pfcFinalDestinationCode ==
        this.model.pfcPortOfTransshipment1Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.pfcFinalDestinationCode != "" &&
      this.model.pfcPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.pfcFinalDestinationCode ==
        this.model.pfcPortOfTransshipment2Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.pfcFinalDestinationCode != "" &&
      this.model.pfcPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.pfcFinalDestinationCode ==
        this.model.pfcPortOfTransshipment3Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkBlankFreightChargesValidator(): Boolean {
    console.log("** check blank freight charges **");
    console.log(this.model.pfcContainerType);

    let result: Boolean = false;
    if (
      this.map.get(this.model.pfcContainerType) == null ||
      this.map.get(this.model.pfcContainerType) == undefined
    ) {
      // console.log('#check 1');

      var ct: string;
      switch (this.model.pfcContainerType) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        default:
          ct = "NORMAL";
          break;
      }

      this.model["error-blankFreightChargesValidator"] =
        "Blank Freight Charge detected. Please input at least one record for Container Type " +
        ct;
      result = true;
    } else {
      // console.log('#check 2');
      let dets: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      if (dets.length == 0) {
        var ct: string;
        switch (this.model.pfcContainerType) {
          case "TNK":
            ct = "TANK";
            break;
          case "OPT":
            ct = "OPEN TOP";
            break;
          case "FLR":
            ct = "FLAT RACK";
            break;
          case "PLT":
            ct = "PLATFORM";
            break;
          case "REF":
            ct = "REEFER";
            break;
          case "DRY":
            ct = "REEFER DRY";
            break;
          case "UC":
            ct = "UC";
            break;
          default:
            ct = "NORMAL";
            break;
        }

        this.model["error-blankFreightChargesValidator"] =
          "Blank Freight Charge detected. Please input at least one record for Container Type " +
          ct;
        result = true;
      }
    }
    return result;
  }

  checkMainChargesOnlyOneValidator(): Boolean {
    console.log("** checkMainChargesOnlyOneValidator **");
    let result: Boolean = false;

    let mapCTTab = new Map();
    this.map.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        let key: string =
          element.pfcDetailContainerType +
          "-" +
          element.pfcDetailPCTab +
          ":" +
          element.pfcDetailKT +
          "-" +
          element.pfcDetailKillSlot +
          "-" +
          element.pfcDetailM3 +
          "-" +
          element.pfcDetailMinCharge +
          "-" +
          element.pfcDetailOogDoorOpen +
          "-" +
          element.pfcDetailOogOH +
          "-" +
          element.pfcDetailOogOLB +
          "-" +
          element.pfcDetailOogOLF +
          "-" +
          element.pfcDetailOogOWL +
          "-" +
          element.pfcDetailOogOWR +
          "-" +
          element.pfcDetailOogUcH +
          "-" +
          element.pfcDetailOogUcL +
          "-" +
          element.pfcDetailOogUcW;

        if (mapCTTab.get(key) == undefined) {
          let datas: FreightDetail[] = [];
          datas.push(element);
          mapCTTab.set(key, datas);
        } else {
          mapCTTab.get(key).push(element);
        }
      });
    });

    // let str:String = 'Main charge just allow only 1 on ';
    let str: String =
      "Please Check Main Charge. Only 1 main charge (OFT/FOF/FAI) allowed. ";

    mapCTTab.forEach((value: FreightDetail[], key: string) => {
      let ctList: any[] = [];
      value.forEach((element) => {
        if (
          element.pfcDetailChargeCode.trim() == "OFT" ||
          element.pfcDetailChargeCode.trim() == "FAI" ||
          element.pfcDetailChargeCode.trim() == "FOF"
        ) {
          ctList.push(key);
        }
      });

      console.log(ctList);
      if (ctList.length > 1) {
        var strCtList: string = ctList.toString();
        let strCtLists: string[] = strCtList.split(":");

        strCtLists[0] = strCtLists[0].replace(/TNK/g, "TANK");
        strCtLists[0] = strCtLists[0].replace(/OPT/g, "OPEN TOP");
        strCtLists[0] = strCtLists[0].replace(/FLR/g, "FLAT RACK");
        strCtLists[0] = strCtLists[0].replace(/PLT/g, "PLATFORM");
        strCtLists[0] = strCtLists[0].replace(/REF/g, "REEFER");
        strCtLists[0] = strCtLists[0].replace(/STD/g, "NORMAL");
        strCtLists[0] = strCtLists[0].replace(/UC/g, "UC");
        strCtLists[0] = strCtLists[0].replace(/-P/g, " [PREPAID]");
        strCtLists[0] = strCtLists[0].replace(/-C/g, " [COLLECT]");

        str += strCtLists[0] + ",";
        result = true;
      }
    });

    if (result) {
      this.model["error-mainChargesOnlyOneValidator"] =
        str.substring(0, str.length - 1) + ".";
    }

    return result;
  }

  checkMissingMainChargesValidator(): Boolean {
    console.log("** checkMissingMainChargesValidator **");
    console.log(this.map);

    let result: Boolean = false;
    let ctList: any[] = [];
    let mapCTTab = new Map();
    this.map.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        console.log(element);
        /*
        let key:string = element.pfcDetailContainerType + '-' + element.pfcDetailPCTab + ':' + element.pfcDetailCurrency + '-' + element.pfcDetailKT + '-' +
        element.pfcDetailKillSlot + '-' + element.pfcDetailM3 + '-' + element.pfcDetailMinCharge + '-' + element.pfcDetailOogDoorOpen + '-' + element.pfcDetailOogOH + '-' +
        element.pfcDetailOogOLB + '-' + element.pfcDetailOogOLF + '-' + element.pfcDetailOogOWL + '-' + element.pfcDetailOogOWR + '-' + element.pfcDetailOogUcH + '-' +
        element.pfcDetailOogUcL + '-' + element.pfcDetailOogUcW + '-' + element.pfcDetailPC + '-' + element.pfcDetailPayer + '-' + element.pfcDetailPlaceOfPayment;
        */
        let key: string =
          element.pfcDetailContainerType +
          "-" +
          element.pfcDetailPCTab +
          ":" +
          element.pfcDetailKT +
          "-" +
          element.pfcDetailKillSlot +
          "-" +
          element.pfcDetailM3 +
          "-" +
          element.pfcDetailMinCharge +
          "-" +
          element.pfcDetailOogDoorOpen +
          "-" +
          element.pfcDetailOogOH +
          "-" +
          element.pfcDetailOogOLB +
          "-" +
          element.pfcDetailOogOLF +
          "-" +
          element.pfcDetailOogOWL +
          "-" +
          element.pfcDetailOogOWR +
          "-" +
          element.pfcDetailOogUcH +
          "-" +
          element.pfcDetailOogUcL +
          "-" +
          element.pfcDetailOogUcW;
        /*
        if (mapCTTab.get(element.pfcDetailContainerType + '-' + element.pfcDetailPCTab) == undefined) {
          let datas: FreightDetail[] = [];
          datas.push(element);
          mapCTTab.set(element.pfcDetailContainerType + '-' + element.pfcDetailPCTab, datas);
        } else {
          mapCTTab.get(element.pfcDetailContainerType + '-' + element.pfcDetailPCTab).push(element);
        }
        */
        if (mapCTTab.get(key) == undefined) {
          let datas: FreightDetail[] = [];
          datas.push(element);
          mapCTTab.set(key, datas);
        } else {
          mapCTTab.get(key).push(element);
        }
      });
    });

    //console.log(mapCTTab)

    mapCTTab.forEach((value: FreightDetail[], key: string) => {
      //console.log('Key -> ' + key);
      ctList.push(key);
      value.forEach((element) => {
        if (
          element.pfcDetailChargeCode.trim() == "OFT" ||
          element.pfcDetailChargeCode.trim() == "FAI" ||
          element.pfcDetailChargeCode.trim() == "FOF"
        ) {
          var eek = ctList.indexOf(key);
          ctList.splice(eek, 1);
        } else {
        }
      });
    });

    // console.log(ctList);
    if (ctList.length > 0) {
      var strCtList: string = ctList.toString();
      let strCtLists: string[] = strCtList.split(":");

      strCtLists[0] = strCtLists[0].replace(/TNK/g, "TANK");
      strCtLists[0] = strCtLists[0].replace(/OPT/g, "OPEN TOP");
      strCtLists[0] = strCtLists[0].replace(/FLR/g, "FLAT RACK");
      strCtLists[0] = strCtLists[0].replace(/PLT/g, "PLATFORM");
      strCtLists[0] = strCtLists[0].replace(/REF/g, "REEFER");
      strCtLists[0] = strCtLists[0].replace(/STD/g, "NORMAL");
      strCtLists[0] = strCtLists[0].replace(/DRY/g, "REEFER DRY");
      strCtLists[0] = strCtLists[0].replace(/UC/g, "UC");
      strCtLists[0] = strCtLists[0].replace(/-P/g, " [PREPAID]");
      strCtLists[0] = strCtLists[0].replace(/-C/g, " [COLLECT]");

      // console.log(strCtList);
      this.model["error-missingMainChargesValidator"] =
        "Missing Main Charge Detected on Container Type " +
        strCtLists[0] +
        "! Please check your charges.";
      result = true;
    }

    return result;
  }

  checkBlankDetentionRequestValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.pfcDetention == "R" && this.model.pfcDetentionRequest == 0) {
      result = true;
    }
    return result;
  }

  checkBlankDemurrageRequestValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.pfcDemurrage == "R" && this.model.pfcDemurrageRequest == 0) {
      result = true;
    }
    return result;
  }

  checkBlankAdhocVoyageValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.pfcVesselId != "" && this.model.pfcVoyage == "") {
      result = true;
    }
    return result;
  }

  checkBlankPayerValidator(): Boolean {
    console.log("** check Blank Payer for prepaid **");
    let result: Boolean = false;
    // let popMap = new Map();
    // this.map.forEach((value: FreightDetail[], key: string) => {
    //   value.forEach((element) => {
    //     if(popMap.get(element.pfcDetailPlaceOfPayment) == undefined){
    //       popMap.set(element.pfcDetailPlaceOfPayment,[]);
    //     }
    //     popMap.get(element.pfcDetailPlaceOfPayment).push(element);
    //   });
    // });


    // var isPODFreightGroup = false;
    // if(this.podName.includes("FREIGHT GROUP")){
    //   console.log("nojan is here");
    //   console.log(this.freightGroupMember);

    //   isPODFreightGroup = true;
    // }

    // let chargeMissingPayer = new Map();
    // popMap.forEach((value:FreightDetail[], key:string)=>{
    //   console.log("POP : "+key+"");
    //   if(isPODFreightGroup){
    //     if(this.freightGroupMember.includes(key)){
    //       // payer can be blank
    //     } else {
    //       // payer can not be blank
    //       value.forEach((fD)=>{
    //         if(fD.pfcDetailPayer == ""){
    //           /*
    //           if(chargeMissingPayer.get(fD.pfcDetailChargeCode) == undefined){
    //             chargeMissingPayer.set(fD.pfcDetailChargeCode, []);
    //             chargeMissingPayer.get(fD.pfcDetailChargeCode).push(fD);
    //           }
    //           */
    //           if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
    //             chargeMissingPayer.set(fD.pfcDetailPCTab, []);
    //             chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
    //           }else{
    //             chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
    //           }
    //           //this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for "+fD.pfcDetailChargeCode+fD.pfcDetailPC;
    //           result = true;
    //         }
    //       });
    //     }
    //   } else {
    //     if(this.model.pfcDischargePortCode == key){
    //       // payer can be blank
    //     } else {
    //       // payer can not be blank
    //       value.forEach((fD)=>{
    //         if(fD.pfcDetailPayer == ""){
    //           console.log("hei hei");
    //           /*
    //           if(chargeMissingPayer.get(fD.pfcDetailChargeCode) == undefined){
    //             chargeMissingPayer.set(fD.pfcDetailChargeCode, []);
    //             chargeMissingPayer.get(fD.pfcDetailChargeCode).push(fD);
    //           }
    //           */
    //           if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
    //             chargeMissingPayer.set(fD.pfcDetailPCTab, []);
    //             chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
    //           }else{
    //             chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
    //           }
    //           //this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for "+fD.pfcDetailChargeCode+fD.pfcDetailPC;
    //           result = true;
    //         }
    //       });
    //     }
    //   }
    // });


    let popMap = this.groupPopInMap();
    var isPODFreightGroup = this.checkIsPodFreightGroup();
    var checkIsChargeMissingPayer = this.checkIsChargeMissingPayer(popMap, isPODFreightGroup, "blankPayer");
    let chargeMissingPayer = checkIsChargeMissingPayer.get('chargeMissingPayer');
    result = checkIsChargeMissingPayer.get('result');
    if(result){
      /*
      let chargeText = "";
      if(chargeMissingPayer.size > 0){
        let countKoma = 1;
        chargeMissingPayer.forEach((value:FreightDetail[], key:string)=>{
          chargeText += key;
          if(countKoma == chargeMissingPayer.size - 1){
            chargeText += " and "
          } else {
            chargeText += ", ";
          }
          countKoma++;
        });

        chargeText = chargeText.substr(0, chargeText.length-2);

        this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for "+chargeText;
      }
      */
      let chargeText = ""
      console.log(chargeMissingPayer)
      if(chargeMissingPayer.size > 0){
        if(chargeMissingPayer.size == 1){
          chargeMissingPayer.forEach((value:any[], key:string)=>{
            console.log(key)
            console.log(value)

            if(key==="P"){
              chargeText += "[Prepaid Tab] "
            }else{
              chargeText += "[Collect Tab] "
            }

            chargeText += value;

          })

        }else{
          chargeMissingPayer.forEach((value:any[], key:string)=>{
            console.log(key)
            console.log(value)

            if(key==="P"){
              chargeText += "[Prepaid Tab] "
            }else{
              chargeText += "[Collect Tab] "
            }

            chargeText += value + "<br> ";

          })
        }
      }

      console.log(chargeText)
      this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for <br>"+chargeText;
    }

    console.log(result);
    return result;
  }

  checkExceedPayerPOPValidator(): Boolean {
    console.log("** check Exceed Payer / POP **");
    let result: Boolean = false;

    let mapExceedPayer = new Map<String, Map<String, number>>();
    let mapExceedPOP = new Map<String, Map<String, number>>();

    this.map.forEach((value: FreightDetail[], key: string) => {
      //console.log("key : " + key);
      let mpPrepaidPyr = new Map<String, number>();
      let mpCollectPyr = new Map<String, number>();

      let mpPrepaid = new Map<String, number>();
      let mpCollect = new Map<String, number>();

      value.forEach((element) => {
        let keyPayer: string =
          element.pfcDetailContainerType +
          "|" +
          "A" +
          "-" +
          element.pfcDetailKT +
          "-" +
          element.pfcDetailM3 +
          "-" +
          element.pfcDetailRT +
          "-" +
          element.pfcDetailKillSlot +
          "-" +
          element.pfcDetailMinCharge +
          "-" +
          element.pfcDetailOogDoorOpen +
          "-" +
          element.pfcDetailOogOH +
          "-" +
          element.pfcDetailOogOLB +
          "-" +
          element.pfcDetailOogOLF +
          "-" +
          element.pfcDetailOogOWL +
          "-" +
          element.pfcDetailOogOWR +
          "-" +
          element.pfcDetailOogUcH +
          "-" +
          element.pfcDetailOogUcL +
          "-" +
          element.pfcDetailOogUcW +
          ":" +
          element.pfcDetailPayer;

        let keyPOP: string =
          element.pfcDetailContainerType +
          "|" +
          element.pfcDetailPC +
          "-" +
          element.pfcDetailKT +
          "-" +
          element.pfcDetailM3 +
          "-" +
          element.pfcDetailRT +
          "-" +
          element.pfcDetailKillSlot +
          "-" +
          element.pfcDetailMinCharge +
          "-" +
          element.pfcDetailOogDoorOpen +
          "-" +
          element.pfcDetailOogOH +
          "-" +
          element.pfcDetailOogOLB +
          "-" +
          element.pfcDetailOogOLF +
          "-" +
          element.pfcDetailOogOWL +
          "-" +
          element.pfcDetailOogOWR +
          "-" +
          element.pfcDetailOogUcH +
          "-" +
          element.pfcDetailOogUcL +
          "-" +
          element.pfcDetailOogUcW +
          ":" +
          element.pfcDetailPlaceOfPayment;

        if (element.pfcDetailPayer != "") {
          if (mapExceedPayer.get(key + "-" + "A") == undefined) {
            mpPrepaidPyr.set(keyPayer, 1);
            mapExceedPayer.set(key + "-" + "A", mpPrepaidPyr);
          } else {
            if (
              mapExceedPayer.get(key + "-" + "A").get(keyPayer) == undefined
            ) {
              mpPrepaidPyr.set(keyPayer, 1);
              mapExceedPayer.set(key + "-" + "A", mpPrepaidPyr);
            } else {
              mpPrepaidPyr.set(
                keyPayer,
                mapExceedPayer.get(key + "-" + "A").get(keyPayer) + 1
              );
              mapExceedPayer.set(key + "-" + "A", mpPrepaidPyr);
            }
          }
        }

        //console.log(element);
        if (element.pfcDetailPC == "P") {
          // ----------------------------------

          if (mapExceedPOP.get(key + "-" + element.pfcDetailPC) == undefined) {
            mpPrepaid.set(keyPOP, 1);
            mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpPrepaid);
          } else {
            if (
              mapExceedPOP.get(key + "-" + element.pfcDetailPC).get(keyPOP) ==
              undefined
            ) {
              mpPrepaid.set(keyPOP, 1);
              mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpPrepaid);
            } else {
              mpPrepaid.set(
                keyPOP,
                mapExceedPOP.get(key + "-" + element.pfcDetailPC).get(keyPOP) +
                  1
              );
              mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpPrepaid);
            }
          }
        } else {
          if (mapExceedPOP.get(key + "-" + element.pfcDetailPC) == undefined) {
            mpCollect.set(keyPOP, 1);
            mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpCollect);
          } else {
            if (
              mapExceedPOP.get(key + "-" + element.pfcDetailPC).get(keyPOP) ==
              undefined
            ) {
              mpCollect.set(keyPOP, 1);
              mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpCollect);
            } else {
              mpCollect.set(
                keyPOP,
                mapExceedPOP.get(key + "-" + element.pfcDetailPC).get(keyPOP) +
                  1
              );
              mapExceedPOP.set(key + "-" + element.pfcDetailPC, mpCollect);
            }
          }
        }
      });
    });

    console.log(mapExceedPayer);
    console.log(mapExceedPOP);

    /*
    if( (mapExceedPayer.get("P") != undefined  && mapExceedPayer.get("P").size > 3) ||
        (mapExceedPayer.get("C") != undefined && mapExceedPayer.get("C").size > 3) ||
        (mapExceedPOP.get("P") != undefined && mapExceedPOP.get("P").size > 3) ||
        (mapExceedPOP.get("C") != undefined && mapExceedPOP.get("C").size > 3)) {
      result = true;
    }
    */

    let mapExceedPayer2 = new Map<String, number>();
    let mapExceedPOP2 = new Map<String, number>();

    let mpCC: any[] = [];
    this.map.forEach((value: FreightDetail[], key: string) => {
      console.log("key : " + key);
      /*
      if( (mapExceedPayer.get(key +'-'+"P") != undefined  && mapExceedPayer.get(key +'-'+"P").size > 3) ||
      (mapExceedPayer.get(key +'-'+"C") != undefined && mapExceedPayer.get(key +'-'+"C").size > 3) ||
      (mapExceedPOP.get(key +'-'+"P") != undefined && mapExceedPOP.get(key +'-'+"P").size > 3) ||
      (mapExceedPOP.get(key +'-'+"C") != undefined && mapExceedPOP.get(key +'-'+"C").size > 3)) {
        //result = true;
        key = key.replace(/TNK/g,'TANK');
        key = key.replace(/OPT/g,'OPEN TOP')
        key = key.replace(/FLR/g,'FLAT RACK')
        key = key.replace(/PLT/g,'PLATFORM')
        key = key.replace(/REF/g,'REEFER')
        key = key.replace(/STD/g,'NORMAL')
        mpCC.push(key);
      }
      */

      /*
      if(mapExceedPayer.get(key +'-'+"P") != undefined){
        mapExceedPayer.get(key +'-'+"P").forEach((value: number, keyPayer: string) => {
          console.log(keyPayer + " - " + value);
          let ss:any[] = keyPayer.split(":")
          if(mapExceedPayer2.has(ss[0] + ":P")){
            mapExceedPayer2.set(ss[0] + ":P", mapExceedPayer2.get(ss[0]+ ":P") + 1)
          }else{
            mapExceedPayer2.set(ss[0] + ":P",1);
          }
        })
      }

      if(mapExceedPayer.get(key +'-'+"C") != undefined){
        mapExceedPayer.get(key +'-'+"C").forEach((value: number, keyPayer: string) => {
          console.log(keyPayer + " - " + value);
          let ss:any[] = keyPayer.split(":")
          if(mapExceedPayer2.has(ss[0] + ":C")){
            mapExceedPayer2.set(ss[0] + ":C", mapExceedPayer2.get(ss[0]+ ":C") + 1)
          }else{
            mapExceedPayer2.set(ss[0] + ":C",1);
          }
        })
      }
      */

      if (mapExceedPayer.get(key + "-" + "A") != undefined) {
        mapExceedPayer
          .get(key + "-" + "A")
          .forEach((value: number, keyPayer: string) => {
            console.log(keyPayer + " - " + value);
            let ss: any[] = keyPayer.split(":");
            if (mapExceedPayer2.has(ss[0] + ":A")) {
              mapExceedPayer2.set(
                ss[0] + ":A",
                mapExceedPayer2.get(ss[0] + ":A") + 1
              );
            } else {
              mapExceedPayer2.set(ss[0] + ":A", 1);
            }
          });
      }

      if (mapExceedPOP.get(key + "-" + "P") != undefined) {
        mapExceedPOP
          .get(key + "-" + "P")
          .forEach((value: number, keyPOP: string) => {
            console.log(keyPOP + " - " + value);
            /*
          if(value > 3){
            key = key.replace(/TNK/g,'TANK');
            key = key.replace(/OPT/g,'OPEN TOP')
            key = key.replace(/FLR/g,'FLAT RACK')
            key = key.replace(/PLT/g,'PLATFORM')
            key = key.replace(/REF/g,'REEFER')
            key = key.replace(/STD/g,'NORMAL')
            mpCC.push(key);
          }
          */
            let ss: any[] = keyPOP.split(":");
            if (mapExceedPOP2.has(ss[0] + ":P")) {
              mapExceedPOP2.set(
                ss[0] + ":P",
                mapExceedPOP2.get(ss[0] + ":P") + 1
              );
            } else {
              mapExceedPOP2.set(ss[0] + ":P", 1);
            }
          });
      }

      if (mapExceedPOP.get(key + "-" + "C") != undefined) {
        mapExceedPOP
          .get(key + "-" + "C")
          .forEach((value: number, keyPOP: string) => {
            console.log(keyPOP + " - " + value);
            /*
          if(value > 3){
            key = key.replace(/TNK/g,'TANK');
            key = key.replace(/OPT/g,'OPEN TOP')
            key = key.replace(/FLR/g,'FLAT RACK')
            key = key.replace(/PLT/g,'PLATFORM')
            key = key.replace(/REF/g,'REEFER')
            key = key.replace(/STD/g,'NORMAL')
            mpCC.push(key);
          }
          */
            let ss: any[] = keyPOP.split(":");
            if (mapExceedPOP2.has(ss[0] + ":C")) {
              mapExceedPOP2.set(
                ss[0] + ":C",
                mapExceedPOP2.get(ss[0] + ":C") + 1
              );
            } else {
              mapExceedPOP2.set(ss[0] + ":C", 1);
            }
          });
      }
    });

    console.log(" ---------------- ");
    console.log(mapExceedPayer2);
    //let hit:number = 0;
    mapExceedPayer2.forEach((value: number, keyPayer: string) => {
      //hit += value;
      //if(hit > 3){
      if (value > 3) {
        let key = keyPayer.split("|")[0];
        key = key.replace(/TNK/g, "TANK");
        key = key.replace(/OPT/g, "OPEN TOP");
        key = key.replace(/FLR/g, "FLAT RACK");
        key = key.replace(/PLT/g, "PLATFORM");
        key = key.replace(/REF/g, "REEFER");
        key = key.replace(/STD/g, "NORMAL");
        if (!mpCC.includes(key, 0)) {
          mpCC.push(key);
        }
      }
    });

    console.log(mapExceedPOP2);
    mapExceedPOP2.forEach((value: number, keyPOP: string) => {
      if (value > 3) {
        let key = keyPOP.split("|")[0];
        key = key.replace(/TNK/g, "TANK");
        key = key.replace(/OPT/g, "OPEN TOP");
        key = key.replace(/FLR/g, "FLAT RACK");
        key = key.replace(/PLT/g, "PLATFORM");
        key = key.replace(/REF/g, "REEFER");
        key = key.replace(/STD/g, "NORMAL");
        mpCC.push(key);
      }
    });

    if (mpCC.length > 0) {
      this.model["error-exceedPayerPOPValidator"] =
        '"Place of Payment" / "Payer" exceeds 3 for Container Type [' +
        mpCC +
        "]. Unable to Save";
      result = true;
    }

    return result;
  }

  checkChargesValueValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.modelDetail.pfcDetailD20 == 0 &&
      this.modelDetail.pfcDetailD40 == 0 &&
      this.modelDetail.pfcDetailD45 == 0 &&
      this.modelDetail.pfcDetailH20 == 0 &&
      this.modelDetail.pfcDetailH40 == 0 &&
      this.modelDetail.pfcDetailH45 == 0
    ) {
      if (this.modelDetail.pfcDetailChargeCode != "FOF") {
        this.modelDetail["error-chargesValueValidator"] =
          "Please input charges value.";
        result = true;
      }
    }

    return result;
  }

  checkFAIGroupValidator(): Boolean {
    console.log("** check FAI Group **");
    let result: Boolean = false;

    let mapFAI = new Map<String, String[]>();
    let mapCheckMain = new Map<String, String[]>();

    this.map.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        if (element.pfcDetailFAIGroup == "Y") {
          if (
            mapFAI.get(
              element.pfcDetailContainerType +
                "-" +
                element.pfcDetailPCTab +
                "-" +
                element.pfcDetailOogDoorOpen +
                "-" +
                element.pfcDetailCurrency +
                "-" +
                element.pfcDetailPC +
                "-" +
                element.pfcDetailPlaceOfPayment +
                "-" +
                element.pfcDetailPayer +
                "-" +
                element.pfcDetailOogOH +
                "-" +
                element.pfcDetailOogOWL +
                "-" +
                element.pfcDetailOogOWR +
                "-" +
                element.pfcDetailOogOLF +
                "-" +
                element.pfcDetailOogOLB +
                "-" +
                element.pfcDetailKillSlot +
                "-" +
                element.pfcDetailOogUcH +
                "-" +
                element.pfcDetailOogUcL +
                "-" +
                element.pfcDetailOogUcW
            ) == null
          ) {
            let blfList: String[] = [];
            var key: String =
              element.pfcDetailContainerType +
              ";;" +
              element.pfcDetailPCTab +
              ";;" +
              element.pfcDetailCurrency +
              ";;" +
              element.pfcDetailPC +
              ";;" +
              element.pfcDetailPlaceOfPayment +
              ";;" +
              element.pfcDetailPayer +
              ";;" +
              element.pfcDetailOogOH +
              ";;" +
              element.pfcDetailOogOWL +
              ";;" +
              element.pfcDetailOogOWR +
              ";;" +
              element.pfcDetailOogOLF +
              ";;" +
              element.pfcDetailOogOLB +
              ";;" +
              element.pfcDetailKillSlot +
              ";;" +
              element.pfcDetailOogDoorOpen +
              ";;" +
              element.pfcDetailOogUcH +
              ";;" +
              element.pfcDetailOogUcL +
              ";;" +
              element.pfcDetailOogUcW;

            blfList.push(key);
            mapFAI.set(
              element.pfcDetailContainerType +
                "-" +
                element.pfcDetailPCTab +
                "-" +
                element.pfcDetailOogDoorOpen +
                "-" +
                element.pfcDetailCurrency +
                "-" +
                element.pfcDetailPC +
                "-" +
                element.pfcDetailPlaceOfPayment +
                "-" +
                element.pfcDetailPayer +
                "-" +
                element.pfcDetailOogOH +
                "-" +
                element.pfcDetailOogOWL +
                "-" +
                element.pfcDetailOogOWR +
                "-" +
                element.pfcDetailOogOLF +
                "-" +
                element.pfcDetailOogOLB +
                "-" +
                element.pfcDetailKillSlot +
                "-" +
                element.pfcDetailOogUcH +
                "-" +
                element.pfcDetailOogUcL +
                "-" +
                element.pfcDetailOogUcW,
              blfList
            );
          } else {
            var key: String =
              element.pfcDetailContainerType +
              ";;" +
              element.pfcDetailPCTab +
              ";;" +
              element.pfcDetailCurrency +
              ";;" +
              element.pfcDetailPC +
              ";;" +
              element.pfcDetailPlaceOfPayment +
              ";;" +
              element.pfcDetailPayer +
              ";;" +
              element.pfcDetailOogOH +
              ";;" +
              element.pfcDetailOogOWL +
              ";;" +
              element.pfcDetailOogOWR +
              ";;" +
              element.pfcDetailOogOLF +
              ";;" +
              element.pfcDetailOogOLB +
              ";;" +
              element.pfcDetailKillSlot +
              ";;" +
              element.pfcDetailOogDoorOpen +
              ";;" +
              element.pfcDetailOogUcH +
              ";;" +
              element.pfcDetailOogUcL +
              ";;" +
              element.pfcDetailOogUcW;

            mapFAI
              .get(
                element.pfcDetailContainerType +
                  "-" +
                  element.pfcDetailPCTab +
                  "-" +
                  element.pfcDetailOogDoorOpen +
                  "-" +
                  element.pfcDetailCurrency +
                  "-" +
                  element.pfcDetailPC +
                  "-" +
                  element.pfcDetailPlaceOfPayment +
                  "-" +
                  element.pfcDetailPayer +
                  "-" +
                  element.pfcDetailOogOH +
                  "-" +
                  element.pfcDetailOogOWL +
                  "-" +
                  element.pfcDetailOogOWR +
                  "-" +
                  element.pfcDetailOogOLF +
                  "-" +
                  element.pfcDetailOogOLB +
                  "-" +
                  element.pfcDetailKillSlot +
                  "-" +
                  element.pfcDetailOogUcH +
                  "-" +
                  element.pfcDetailOogUcL +
                  "-" +
                  element.pfcDetailOogUcW
              )
              .push(key);
          }

          if (
            mapCheckMain.get(
              element.pfcDetailContainerType +
                "-" +
                element.pfcDetailPCTab +
                "-" +
                element.pfcDetailOogDoorOpen +
                "-" +
                element.pfcDetailCurrency +
                "-" +
                element.pfcDetailPC +
                "-" +
                element.pfcDetailPlaceOfPayment +
                "-" +
                element.pfcDetailPayer +
                "-" +
                element.pfcDetailOogOH +
                "-" +
                element.pfcDetailOogOWL +
                "-" +
                element.pfcDetailOogOWR +
                "-" +
                element.pfcDetailOogOLF +
                "-" +
                element.pfcDetailOogOLB +
                "-" +
                element.pfcDetailKillSlot +
                "-" +
                element.pfcDetailOogUcH +
                "-" +
                element.pfcDetailOogUcL +
                "-" +
                element.pfcDetailOogUcW
            ) == null
          ) {
            let cList: String[] = [];
            //cList.push("[" + element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer + "-" + element.pfcDetailOogOH + "-" + element.pfcDetailOogOWL + "-" + element.pfcDetailOogOWR + "-" + element.pfcDetailOogOLF + "-" + element.pfcDetailOogOLB + "-" + element.pfcDetailKillSlot + "-" + element.pfcDetailOogUcH + "-" + element.pfcDetailOogUcL + "-" + element.pfcDetailOogUcW + "]")
            cList.push(element.pfcDetailChargeCode);
            mapCheckMain.set(
              element.pfcDetailContainerType +
                "-" +
                element.pfcDetailPCTab +
                "-" +
                element.pfcDetailOogDoorOpen +
                "-" +
                element.pfcDetailCurrency +
                "-" +
                element.pfcDetailPC +
                "-" +
                element.pfcDetailPlaceOfPayment +
                "-" +
                element.pfcDetailPayer +
                "-" +
                element.pfcDetailOogOH +
                "-" +
                element.pfcDetailOogOWL +
                "-" +
                element.pfcDetailOogOWR +
                "-" +
                element.pfcDetailOogOLF +
                "-" +
                element.pfcDetailOogOLB +
                "-" +
                element.pfcDetailKillSlot +
                "-" +
                element.pfcDetailOogUcH +
                "-" +
                element.pfcDetailOogUcL +
                "-" +
                element.pfcDetailOogUcW,
              cList
            );
          } else {
            mapCheckMain
              .get(
                element.pfcDetailContainerType +
                  "-" +
                  element.pfcDetailPCTab +
                  "-" +
                  element.pfcDetailOogDoorOpen +
                  "-" +
                  element.pfcDetailCurrency +
                  "-" +
                  element.pfcDetailPC +
                  "-" +
                  element.pfcDetailPlaceOfPayment +
                  "-" +
                  element.pfcDetailPayer +
                  "-" +
                  element.pfcDetailOogOH +
                  "-" +
                  element.pfcDetailOogOWL +
                  "-" +
                  element.pfcDetailOogOWR +
                  "-" +
                  element.pfcDetailOogOLF +
                  "-" +
                  element.pfcDetailOogOLB +
                  "-" +
                  element.pfcDetailKillSlot +
                  "-" +
                  element.pfcDetailOogUcH +
                  "-" +
                  element.pfcDetailOogUcL +
                  "-" +
                  element.pfcDetailOogUcW
              )
              .push(element.pfcDetailChargeCode); //push("[" + element.pfcDetailContainerType  + "-" + element.pfcDetailPCTab + "-" + element.pfcDetailOogDoorOpen + "-" + element.pfcDetailCurrency + "-" + element.pfcDetailPC + "-" + element.pfcDetailPlaceOfPayment + "-" + element.pfcDetailPayer + "-" + element.pfcDetailOogOH + "-" + element.pfcDetailOogOWL + "-" + element.pfcDetailOogOWR + "-" + element.pfcDetailOogOLF + "-" + element.pfcDetailOogOLB + "-" + element.pfcDetailKillSlot + "-" + element.pfcDetailOogUcH + "-" + element.pfcDetailOogUcL + "-" + element.pfcDetailOogUcW + "]")
          }
        }
      });
    });

    console.log(mapFAI);

    /*
    mapFAI.forEach((value: String[], key: string) => {
      console.log(value.length)
      if(value.length < 2){
        this.model['error-faiGroupValidator'] = 'Please select at least 2 charges to be grouped under FAI.';
        result = true;
      }
    })

    if(result == false){
      let str = "Missing Main Charge detected under FAI Group [";
      let strP: Boolean = true;
      let strC: Boolean = true;
      mapCheckMain.forEach((value: String[], key: string) => {
        let k :string[] = key.split("-")
        if(k[1] == "P"){
          strP = false;
          value.forEach(element => {
            if (element == 'OFT' || element == 'FOF') { // || element == 'FAI' || element == 'FOF'
              strP = true
            }
          })
          if(strP == false){
            str += "Prepaid"
          }
        }

        if(k[1] == "C"){
          strC = false;
          value.forEach(element => {
            if (element == 'OFT' || element == 'FOF') { // || element == 'FAI' || element == 'FOF'
              strC = true
            }
          })

          if(strC == false){
            if(strP == false){
              str += "/"
            }
            str += "Collect"
          }

        }

      })
      str += "]"

      if(strP == true && strC == true) {
      }else{
        this.model['error-faiGroupValidator'] = str;
        result = true;
      }

    }
    */

    mapFAI.forEach((value: String[], key: string) => {
      console.log(value.length);
      if (value.length < 2) {
        result = true;
      }
    });

    if (result == false) {
      let strP: Boolean = true;
      let strC: Boolean = true;
      mapCheckMain.forEach((value: String[], key: string) => {
        let k: string[] = key.split("-");
        if (k[1] == "P") {
          strP = false;
          value.forEach((element) => {
            if (element == "OFT" || element == "FOF") {
              // || element == 'FAI' || element == 'FOF'
              strP = true;
            }
          });
        }

        if (k[1] == "C") {
          strC = false;
          value.forEach((element) => {
            if (element == "OFT" || element == "FOF") {
              // || element == 'FAI' || element == 'FOF'
              strC = true;
            }
          });
        }
      });

      if (strP == true && strC == true) {
      } else {
        result = true;
      }
    }

    if (result) {
      this.model["error-faiGroupValidator"] =
        "Conditions for FAI Grouping fail, unable to proceed.";
    } else {
      if (this.isFAIErrorPrepaid && this.isFAIErrorCollect) {
        this.model["error-faiGroupValidator"] =
          "Conditions for FAI Grouping fail, unable to proceed.";
        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  checkMainChargesValidator(): Boolean {
    let result: Boolean = false;

    if(this.modelDetail.pfcDetailChargeCode == 'FAI' && this.modelDetail.pfcDetailFAIGroup == 'Y'){
      this.modelDetail["error-mainChargesValidator"] = 'FREIGHT ALL IN (charge name) / FAI charge code is not allowed for FAI Grouping'
      return true;
    }

    if (
      this.modelDetail.pfcDetailChargeCode == "FAI" &&
      this.modelDetail.pfcDetailD20 == 0 &&
      this.modelDetail.pfcDetailD40 == 0 &&
      this.modelDetail.pfcDetailD45 == 0 &&
      this.modelDetail.pfcDetailH20 == 0 &&
      this.modelDetail.pfcDetailH40 == 0 &&
      this.modelDetail.pfcDetailH45 == 0
    ) {
      // console.log('#1');
      this.modelDetail["error-mainChargesValidator"] =
        "FAI charge code cannot be 0 value";
      return true;
    }

    if (
      this.modelDetail.pfcDetailChargeCode == "OFT" &&
      this.modelDetail.pfcDetailD20 == 0 &&
      this.modelDetail.pfcDetailD40 == 0 &&
      this.modelDetail.pfcDetailD45 == 0 &&
      this.modelDetail.pfcDetailH20 == 0 &&
      this.modelDetail.pfcDetailH40 == 0 &&
      this.modelDetail.pfcDetailH45 == 0
    ) {
      // console.log('#2');
      this.modelDetail["error-mainChargesValidator"] =
        "OFT charge code cannot be 0 value";
      return true;
    }

    if (
      this.modelDetail.pfcDetailChargeCode == "FOF" &&
      (this.modelDetail.pfcDetailD20 != 0 ||
        this.modelDetail.pfcDetailD40 != 0 ||
        this.modelDetail.pfcDetailD45 != 0 ||
        this.modelDetail.pfcDetailH20 != 0 ||
        this.modelDetail.pfcDetailH40 != 0 ||
        this.modelDetail.pfcDetailH45 != 0)
    ) {
      // console.log('#3');
      this.modelDetail["error-mainChargesValidator"] =
        "FOF charge code must set 0 value";
      return true;
    }

    // console.log(result);
    return result;
  }

  clearValidatorError() {
    this.model["error-pfcFreightPartyId"] = "";
    this.model["error-pfcFreightPartyIdInvalid"] = "";
    this.model["error-pfcPlaceOfReceiptCode"] = "";
    this.model["error-pfcLoadPortCode"] = "";
    this.model["error-pfcDischargePortCode"] = "";
    this.model["error-pfcPlaceOfDeliveryCode"] = "";
    this.model["error-pfcReceiptTypeCode"] = "";
    this.model["error-pfcDeliveryTypeCode"] = "";
    this.model["error-pfcEffectiveFrom"] = "";
    this.model["error-pfcEffectiveTo"] = "";
    this.model["error-pfcAcctPic"] = "";
    this.model["error-effectiveDateExceedValidator"] = "";
    this.model["error-expiryDateValidator"] = "";
    this.model["error-samePORValidator"] = "";
    this.model["error-samePOLValidator"] = "";
    this.model["error-samePODValidator"] = "";
    this.model["error-samePODLValidator"] = "";
    this.model["error-sameFDestValidator"] = "";
    this.model["error-blankFreightChargesValidator"] = "";
    this.model["error-missingMainChargesValidator"] = "";
    this.model["error-mainChargesOnlyOneValidator"] = "";
    this.model["error-blankDetentionRequestValidator"] = "";
    this.model["error-blankDemurrageRequestValidator"] = "";
    this.model["error-blankAdhocVoyageValidator"] = "";
    this.model["error-blankPayerValidator"] = "";
    this.model["error-exceedPayerPOPValidator"] = "";
    this.model["error-faiGroupValidator"] = "";
    this.model["error-checkDuplicateValidator"] = "";
    this.model["error-invalidPayerValidator"] = "";
    this.model["error-allInvalidPayerValidator"] = "";
    this.model["error-pfcBookingPartyIdInvalid"] = "";
    this.model["error-pfcShipperIdInvalid"] = "";
    this.model["error-pfcShipperId"] = "";
    this.model["error-pfcBookingPartyId"] = "";
  }

  setValidatorAddNewCharge() {
    this.clearValidatorErrorDetail();
    this.validatorRules = {
      pfcDetailChargeCode: {
        rules: [{ type: "empty", prompt: "Please input Charge." }],
      },
      pfcDetailCurrency: {
        rules: [{ type: "empty", prompt: "Please input Currency." }],
      },
      pfcDetailPC: { rules: [{ type: "empty", prompt: "Please input P/C." }] },
      pfcDetailPlaceOfPayment: {
        rules: [{ type: "empty", prompt: "Please input Place of Payment." }],
      },
      pfcDetailPayer: {
        rules: [{ type: "empty", prompt: "Please input Payer." }],
      },
      chargesValueValidator: {
        rules: [{ type: "custom", prompt: "Please input charges value." }],
      },
      mainChargesValidator: {
        rules: [{ type: "custom", prompt: "Main Charges problem detected." }],
      },
    };
  }

  setValidatorAddNewChargeCollect() {
    this.clearValidatorErrorDetail();
    this.validatorRules = {
      pfcDetailChargeCode: {
        rules: [{ type: "empty", prompt: "Please input Charge." }],
      },
      pfcDetailCurrency: {
        rules: [{ type: "empty", prompt: "Please input Currency." }],
      },
      pfcDetailPC: { rules: [{ type: "empty", prompt: "Please input P/C." }] },
      pfcDetailPlaceOfPayment: {
        rules: [{ type: "empty", prompt: "Please input Place of Payment." }],
      },
      chargesValueValidator: {
        rules: [{ type: "custom", prompt: "Please input charges value." }],
      },
      mainChargesValidator: {
        rules: [{ type: "custom", prompt: "Main Charges problem detected." }],
      },
    };
  }

  clearValidatorErrorDetail() {
    this.modelDetail["error-pfcDetailChargeCode"] = "";
    this.modelDetail["error-pfcDetailCurrency"] = "";
    this.modelDetail["error-pfcDetailPC"] = "";
    this.modelDetail["error-pfcDetailPlaceOfPayment"] = "";
    this.modelDetail["error-pfcDetailPayer"] = "";
    this.modelDetail["error-chargesValueValidator"] = "";
    this.modelDetail["error-pfcDuplicateChargeCode"] = "";
    this.modelDetail["error-mainChargesValidator"] = "";
    this.modelDetail["error-pfcDetailFAIGroup"] = "";
  }

  getEtaEtdAtaAtd(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getSailSchedule/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage)) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe(
        (resp) => {
          let data = resp.json();
          if(data["ETD"] != undefined){
            this.model.pfcLocalEta = String(data["ETA"])
              .trim()
              .replace(/\//gi, "-");
            this.model.pfcLocalEtd = String(data["ETD"]).replace(/\//g, "-");
            this.model.pfcLocalAta = String(data["ATA"]).replace(/\//g, "-");
            this.model.pfcLocalAtd = String(data["ATD"]).replace(/\//g, "-");
            if(this.model.pfcLocalEtd == undefined)this.model.pfcLocalEtd = ''
            if(this.model.pfcLocalEta == undefined)this.model.pfcLocalEta = ''
            if(this.model.pfcLocalAta == undefined)this.model.pfcLocalAta = ''
            if(this.model.pfcLocalAtd == undefined)this.model.pfcLocalAtd = ''
          }

          // GOS-200 & 201, Value set blank;
          this.model.pfcLocalEta = "";
          this.model.pfcLocalEtd = "";
          this.model.pfcLocalAta = "";
          this.model.pfcLocalAtd = "";
        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  checkFreightGroup(officeCode: string, locationCode: string) {
    return new Promise((resolve, reject) => {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlFreightGroup/" +
            officeCode +
            "/locationCode=" +
            locationCode
        )
        .subscribe(
          (resp) => {
            let data = resp.json();

            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  //Action when tab prepaid / tab collect is selected

  onTabPrepaid() {
    this.currentActiveTab = "prepaid";
    //this.gridPrepaid.loadData();
  }

  onTabCollect() {
    this.currentActiveTab = "collect";
    //this.gridCollect.loadData();
  }

  gridEventPrepaid(event) {
    // console.log('Click Event ' + event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var no = event.split(".")[1].split("-")[1];

        //this.gridPrepaid.clearSelectedValues();
        // console.log('no => ' + no)
        //this.gridPrepaid.onSelect(true, Number.parseInt(no));

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.onDetailUpdate();
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    // console.log('no ' + value);
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach((ff) => {
        if (ff[key] != value) {
          ff["select"] = "";
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
    // console.log('total checked ' + store.getSelectedValues().length);
  }

  changeFreightParty(event) {
    if (
      event["customerId"] != null &&
      event["customerId"] != "" &&
      event["customerId"] !== undefined
    ) {
      this.model.pfcFreightPartyId = event["customerId"].toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";
      // this.isFreightPartyValidBasedOnCustomerType=event["isFreightParty"];
      // this.isFreightPartyValidAsRecordLevel=event['isValid'];
      this.searchFpIsFreightParty = event["isFreightParty"];
      this.searchFpIsValid = event["isValid"];
      this.dateFreightRemoved=event['dateFreightRemoved'];
    } else {
      this.model.pfcFreightPartyId = "";
      this.fpAcctCode = "";
      // this.isFreightPartyValidBasedOnCustomerType='';
      // this.isFreightPartyValidAsRecordLevel='';
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.dateFreightRemoved='';
    }

    //revert the search url to use name
    this.cbFreightParty.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
        "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerName={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E",
    );
  }

  changeShipper(event) {
    // console.log("** changeCbMaintenanceShipper **");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.pfcShipperId = event["customerId"];
      this.searchShIsShipper = event["isShipper"];
      this.searchShIsValid = event["isValid"];
      this.dateModifiedShipper=event['dateModified'];
    } else {
      this.model.pfcShipperId = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.dateModifiedShipper= "";
    }

    //revert the search url to use name
    this.cbShipper.setUrl(
      this.configService.config.BASE_API.toString() +
      "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}"+
      this.sCharUtil.htmlEncode("|")+
      "status=E",
    );
  }


  changeBookingParty(event) {
    // console.log("** changeCbMaintenanceShipper **");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.pfcBookingPartyId = event["customerId"];
      this.searchBkIsBookingParty = event["isBookingParty"];
      this.searchBkIsValid = event["isValid"];
      this.dateModifiedBookingParty=event['dateModified'];
    } else {
      this.model.pfcBookingPartyId = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
      this.dateModifiedBookingParty="";
    }

    //revert the search url to use name
    this.cbBookingParty.setUrl(
      this.configService.config.BASE_API.toString() +
      "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerName={query}"+
      this.sCharUtil.htmlEncode("|")+
      "status=E",
    );
  }

  changeDGClass(event) {
    if (
      event["dgClassCode"] != null &&
      event["dgClassCode"] != "" &&
      event["dgClassCode"] !== undefined
    ) {
      this.model.pfcDgClass = event["dgClassCode"].toUpperCase();
    } else {
      this.model.pfcDgClass = "ALL";
    }
  }

  changeContainerStatus(event) {
    if (
      event["ctrStatusCode"] != null &&
      event["ctrStatusCode"] != "" &&
      event["ctrStatusCode"] !== undefined
    ) {
      this.model.pfcContainerStatus = event["ctrStatusCode"].toUpperCase();
    } else {
      this.model.pfcContainerStatus = "ALL";
    }
  }

  changeValidInvalid(event) {
    // console.log(event);
    if (
      event["isValidCode"] != null &&
      event["isValidCode"] != "" &&
      event["isValidCode"] !== undefined
    ) {
      this.model.pfcIsValid = event["isValidCode"].toUpperCase();
    } else {
      this.model.pfcIsValid = "ALL";
    }
  }

  changeContainerOwnership(event) {
    if (
      event["ctrOwnerCode"] != null &&
      event["ctrOwnerCode"] != "" &&
      event["ctrOwnerCode"] !== undefined
    ) {
      this.model.pfcContainerOwnership = event["ctrOwnerCode"].toUpperCase();
    } else {
      this.model.pfcContainerOwnership = "ALL";
    }
  }

  changeContainerType(event) {
    if (
      event["containerTypeCode"] != null &&
      event["containerTypeCode"] != "" &&
      event["containerTypeCode"] !== undefined
    ) {
      this.model.pfcContainerType = event["containerTypeCode"].toUpperCase();
    } else {
      this.model.pfcContainerType = "ALL";
    }
  }

  changeReceiptType(event) {
    if (
      event["receiptTypeCode"] != null &&
      event["receiptTypeCode"] != "" &&
      event["receiptTypeCode"] !== undefined
    ) {
      this.model.pfcReceiptTypeCode = event["receiptTypeCode"].toUpperCase();
    } else {
      this.model.pfcReceiptTypeCode = "ALL";
    }
    //this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeDeliveryType(event) {
    if (
      event["deliveryTypeCode"] != null &&
      event["deliveryTypeCode"] != "" &&
      event["deliveryTypeCode"] !== undefined
    ) {
      this.model.pfcDeliveryTypeCode = event["deliveryTypeCode"].toUpperCase();
    } else {
      this.model.pfcDeliveryTypeCode = "ALL";
    }
    //this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}');
  }

  changeCbMaintenanceCommodity(event) {
    if (
      event["commodityId"] != null &&
      event["commodityId"] != "" &&
      event["commodityId"] !== undefined
    ) {
      this.model.pfcCommodityId = event["commodityId"].toUpperCase();
    } else {
      this.model.pfcCommodityId = "";
    }

    //revert to search by name
    this.cbMaintenanceCommodity.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}"
    );
  }

  infoGridPrepaid(event) {}

  infoGridCollect(event) {}

  gridEventCollect(event) {
    // console.log('Click Event ' + event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var no = event.split(".")[1].split("-")[1];

        //this.gridCollect.clearSelectedValues();
        // console.log('no => ' + no)
        //this.gridCollect.onSelect(true, Number.parseInt(no));

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.onDetailUpdate();
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  changeEventRetrieveByRefNo(event) {
    if (event.target.checked) {
      this.checkLock = true;
      this.model.pfcSerchByRefNo = "Y";
      this.lockEffectiveFrom = true;
      this.lockEffectiveTo = true;

      this.cookieService.setCookie(
        "pfcSearchByRefNo",
        this.model.pfcSerchByRefNo
      );
    } else {
      this.checkLock = false;
      this.model.pfcSerchByRefNo = "N";
      this.model.pfcRecordId = "";
      this.lockEffectiveFrom = false;
      this.lockEffectiveTo = false;

      this.cookieService.setCookie(
        "pfcSearchByRefNo",
        this.model.pfcSerchByRefNo
      );
    }
  }

  changeIsDeleted(event) {
    if (event.target.checked) {
      this.model.pfcIsDeleted = "Y";
    } else {
      this.model.pfcIsDeleted = "N";
    }
  }

  changeIsCommissionable(event) {
    if (event.target.checked) {
      this.model.pfcIsCommission = "Y";
    } else {
      this.model.pfcIsCommission = "N";
    }
  }

  changeEventPfcIsCommission(event) {
    if (event.target.checked) {
      this.model.pfcIsCommission = "Y";
    } else {
      this.model.pfcIsCommission = "N";
    }
  }

  handleDoubleClick(dt: FreightManagement) {
    // console.log('dt => ');
    // console.log(dt);
    console.log(this.modeForm);
    if (this.modeForm == "freightFlagStatus" || this.isModeArchive == true) {
      this.isRetrieve = true;

      this.modeForm = "freightMaintenance";
      this.pfcToolbar.label = "Contract Rate";
      this.invisibleToolbarButtons = "retrieve,create";
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "copy";
      }

      this.dateMaintenanceEffectiveFrom2.setDisable(false);

      //store buffer id on cookies
      this.cookieService.setCookie(
        "pfcSearchParam",
        JSON.stringify(this.model)
      );
      //end store buffer cookies

      //re-init the model to reset any unwanted values
      this.model = new FreightManagement();

      //reset all combo and date picker
      this.cbMaintenanceFreightParty.setValue("");
      this.cbMaintenanceShipper.setValue("");
      this.cbMaintenanceBookingParty.setValue("");
      this.cbMaintenancePlaceOfReceipt.setValue("");
      this.cbMaintenancePortOfLoad.setValue("");
      this.cbMaintenancePortOfDischarge.setValue("");
      this.cbMaintenancePlaceOfDelivery.setValue("");
      this.cbMaintenancePOT1.setValue("");
      this.cbMaintenancePOT2.setValue("");
      this.cbMaintenancePOT3.setValue("");
      this.cbMaintenanceFinalDestination.setValue("");
      this.cbMaintenanceCommodity.setValue("");
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");
      //this.dateMaintenanceEffectiveTo.setValue('');
      this.dateMaintenanceEffectiveTo2.reset();

      this.txtRemarksAgency.setValue("");
      this.txtRemarksPrincipal.setValue("");

      if (!this.isModeArchive) {
        this.cbArchivePfc.setValue("");
      }

      this.revisionPfc = "";
      this.isError = false;
      let obj = {};
      let myJSON = JSON.stringify(obj);
      console.log(myJSON);
      // this.cbArchivePfc.setData(myJSON);

      //reset effective to
      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());
      //this.dateMaintenanceEffectiveFrom.setValue(theDate);
      this.dateMaintenanceEffectiveFrom2.setValue(theDate);

      // console.log('** 0. Check Record Id **');
      console.log(this.model);
      console.log(dt);
      if (
        dt.pfcRecordId !== null &&
        dt.pfcRecordId !== "" &&
        dt.pfcRecordId !== undefined
      ) {
        this.model.pfcRecordId = dt.pfcRecordId;
        this.revisionPfc = dt.pfcRevision;

        this.getListArchive(dt.pfcRecordId, false);

        console.log(this.cbArchivePfc);
        console.log('sini gak if');
      }

      // console.log('** 1. Check Freight Party **');
      if (
        dt.pfcFreightPartyId !== null &&
        dt.pfcFreightPartyId !== "" &&
        dt.pfcFreightPartyId !== undefined
      ) {
        this.cbMaintenanceFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
            "/PFCGenericAPI/findByComboBoxControlGeneral/freight_party/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"+
            this.sCharUtil.htmlEncode("|")+
            "recordId="+dt.pfcRecordId,
        );
        this.cbMaintenanceFreightParty.setValue(dt.pfcFreightPartyId);
      }

      // console.log('** 1.1. Check Freight Party **');
      if (
        dt.pfcShipperId !== null &&
        dt.pfcShipperId !== "" &&
        dt.pfcShipperId !== undefined
      ) {
        this.cbMaintenanceShipper.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlShipper/customerId={query}"
            "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"+
            this.sCharUtil.htmlEncode("|")+
            "recordId="+dt.pfcRecordId,
        );
        this.cbMaintenanceShipper.setValue(dt.pfcShipperId);
      }

      // console.log('** 2. Check Place of Receipt **');
      if (
        dt.pfcPlaceOfReceiptCode !== null &&
        dt.pfcPlaceOfReceiptCode !== "" &&
        dt.pfcPlaceOfReceiptCode !== undefined
      ) {
        this.cbMaintenancePlaceOfReceipt.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfReceipt.setValue(dt.pfcPlaceOfReceiptCode, ()=>{
          this.cbMaintenancePORTerminal.setForceValue(dt.pfcPORTerminalName);
          this.model.pfcPORTerminalCode = dt.pfcPORTerminalCode;

          this.cbMaintenancePOLTerminal.setForceValue(dt.pfcPOLTerminalName);
          this.model.pfcPOLTerminalCode =  dt.pfcPOLTerminalCode;
        });
        this.model.pfcPlaceOfReceiptCode = dt.pfcPlaceOfReceiptCode;
      }

      // console.log('** 3. Check Port of Loading **');
      if (
        dt.pfcLoadPortCode !== null &&
        dt.pfcLoadPortCode !== "" &&
        dt.pfcLoadPortCode !== undefined
      ) {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfLoad.setValue(dt.pfcLoadPortCode, ()=>{
          this.cbMaintenancePOLTerminal.setForceValue(dt.pfcPOLTerminalName);
          this.model.pfcPOLTerminalCode =  dt.pfcPOLTerminalCode;
        });
        this.model.pfcLoadPortCode = dt.pfcLoadPortCode;
        this.model.pfcPolPrefix = dt.pfcPolPrefix;
      }

      // console.log('** 4. Check Port of Discharge **');
      if (
        dt.pfcDischargePortCode !== null &&
        dt.pfcDischargePortCode !== "" &&
        dt.pfcDischargePortCode !== undefined
      ) {
        this.cbMaintenancePortOfDischarge.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfDischarge.setValue(dt.pfcDischargePortCode,()=>{
          this.cbMaintenancePODTerminal.setForceValue(dt.pfcPODTerminalName);
          this.model.pfcPODTerminalCode = dt.pfcPODTerminalCode;

          this.cbMaintenancePODLTerminal.setForceValue(dt.pfcPODLTerminalName);
          this.model.pfcPODLTerminalCode = dt.pfcPODLTerminalCode;
        });
        this.model.pfcDischargePortCode = dt.pfcDischargePortCode;
        this.model.pfcPodPrefix = dt.pfcPodPrefix;
      }
      //5. Check Place of Delivery
      if (
        dt.pfcPlaceOfDeliveryCode !== null &&
        dt.pfcPlaceOfDeliveryCode !== "" &&
        dt.pfcPlaceOfDeliveryCode !== undefined
      ) {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue(dt.pfcPlaceOfDeliveryCode, ()=>{
          this.cbMaintenancePODLTerminal.setForceValue(dt.pfcPODLTerminalName);
          this.model.pfcPODLTerminalCode = dt.pfcPODLTerminalCode;
        });
        this.model.pfcPlaceOfDeliveryCode =dt.pfcPlaceOfDeliveryCode;
      }
      //6. Check Port of Transshipment 1
      if (
        dt.pfcPortOfTransshipment1Code !== null &&
        dt.pfcPortOfTransshipment1Code !== "" &&
        dt.pfcPortOfTransshipment1Code !== undefined
      ) {
        this.cbMaintenancePOT1.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT1.setValue(dt.pfcPortOfTransshipment1Code, ()=>{
          this.cbMaintenancePOT1Terminal.setForceValue(dt.pfcPOT1TerminalName);
          this.model.pfcPOT1TerminalCode = dt.pfcPOT1TerminalCode;
        });
        this.model.pfcPortOfTransshipment1Code = dt.pfcPortOfTransshipment1Code;
      }

      //Check Port of Transshipment 2
      if (
        dt.pfcPortOfTransshipment2Code !== null &&
        dt.pfcPortOfTransshipment2Code !== "" &&
        dt.pfcPortOfTransshipment2Code !== undefined
      ) {
        this.cbMaintenancePOT2.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT2.setValue(dt.pfcPortOfTransshipment2Code);
        this.model.pfcPortOfTransshipment2Code = dt.pfcPortOfTransshipment2Code;
      }

      //Check Port of Transshipment 3
      if (
        dt.pfcPortOfTransshipment3Code !== null &&
        dt.pfcPortOfTransshipment3Code !== "" &&
        dt.pfcPortOfTransshipment3Code !== undefined
      ) {
        this.cbMaintenancePOT3.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT3.setValue(dt.pfcPortOfTransshipment3Code);
        this.model.pfcPortOfTransshipment3Code = dt.pfcPortOfTransshipment3Code;
      }

      //7. Check Final Destination
      if (
        dt.pfcFinalDestinationCode !== null &&
        dt.pfcFinalDestinationCode !== "" &&
        dt.pfcFinalDestinationCode !== undefined
      ) {
        this.cbMaintenanceFinalDestination.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceFinalDestination.setValue(dt.pfcFinalDestinationCode, ()=>{
          this.cbMaintenanceFDESTTerminal.setForceValue(dt.pfcFDESTTerminalName);
          this.model.pfcFDESTTerminalCode = dt.pfcFDESTTerminalCode;
        });
        this.model.pfcFinalDestinationCode = dt.pfcFinalDestinationCode;
      }
      //8. Check Receipt Type
      // console.log('dt.pfcReceiptTypeCode --> ' + dt.pfcReceiptTypeCode);
      if (
        dt.pfcReceiptTypeCode !== null &&
        dt.pfcReceiptTypeCode !== "" &&
        dt.pfcReceiptTypeCode !== undefined
      ) {
        this.cbMaintenanceReceiptType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
        );
        this.cbMaintenanceReceiptType.setValue(dt.pfcReceiptTypeCode);
      }
      //9. Check Delivery Type
      // console.log('dt.pfcDeliveryTypeCode --> ' + dt.pfcDeliveryTypeCode);
      if (
        dt.pfcDeliveryTypeCode !== null &&
        dt.pfcDeliveryTypeCode !== "" &&
        dt.pfcDeliveryTypeCode !== undefined
      ) {
        this.cbMaintenanceDeliveryType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
        );
        this.cbMaintenanceDeliveryType.setValue(dt.pfcDeliveryTypeCode);
      }
      //10. Check DG Class
      if (
        dt.pfcDgClass !== null &&
        dt.pfcDgClass !== "" &&
        dt.pfcDgClass !== undefined
      ) {
        this.model.pfcDgClass = dt.pfcDgClass;
      }
      //11. Check Shipment Type
      if (
        dt.pfcShipmentType !== null &&
        dt.pfcShipmentType !== "" &&
        dt.pfcShipmentType !== undefined
      ) {
        this.model.pfcShipmentType = dt.pfcShipmentType;
      }
      //12. Check Container Status
      if (
        dt.pfcContainerStatus !== null &&
        dt.pfcContainerStatus !== "" &&
        dt.pfcContainerStatus !== undefined
      ) {
        this.model.pfcContainerStatus = dt.pfcContainerStatus;
      }
      //13. Check IHC
      if (dt.pfcIhc !== null && dt.pfcIhc !== "" && dt.pfcIhc !== undefined) {
        this.model.pfcIhc = dt.pfcIhc;
      }
      //14. Check Adhoc Vessel
      if (
        dt.pfcVesselId !== null &&
        dt.pfcVesselId !== "" &&
        dt.pfcVesselId !== undefined
      ) {
        var self = this;
        this.cbMaintenanceAdhocVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterVessels/findByComboBoxControl/vesselId={query}"
        );
        this.cbMaintenanceAdhocVessel.setValue(
          dt.pfcVesselId,
          function (callbackVessel) {
            self.model.pfcVesselId = dt.pfcVesselId;

            //15. Check Adhoc Voyage
            if (
              dt.pfcVoyage !== null &&
              dt.pfcVoyage !== "" &&
              dt.pfcVoyage !== undefined
            ) {
              //self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+ self.model.pfcVesselId+'/'+ 'O' +'/{query}/' + self.cookieService.getDefaultLocationCode());
              self.cbMaintenanceAdhocVoyage.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                  dt.pfcVesselId +
                  "/O/voyage={query}"
              );
              self.cbMaintenanceAdhocVoyage.setValue(
                dt.pfcVoyage,
                function (callbackVoyage) {
                  self.model.pfcVoyage = dt.pfcVoyage;
                  //self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyage/' + self.cookieService.getDefaultLocationCode() + '/' + dt.pfcVesselId + '/O/voyage_like={query}');
                  self.cbMaintenanceAdhocVoyage.setUrl(
                    self.configService.config.BASE_API.toString() +
                      "/MasterSailingSchedules/distinctVoyage/" +
                      self.model.pfcVesselId +
                      "/" +
                      "O" +
                      "/{query}/*"
                  );
                  self.getEtaEtdAtaAtd(
                    self.model.pfcVesselId,
                    self.model.pfcVoyage,
                    "O",
                    self.cookieService.getDefaultLocationCode()
                  );
                }
              );
            }

            self.cbMaintenanceAdhocVessel.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterVessels/findByComboBoxControl/VesselName={query}"
            );
          }
        );
      }

      //16. Check Container Status
      if (
        dt.pfcContainerStatus !== null &&
        dt.pfcContainerStatus !== "" &&
        dt.pfcContainerStatus !== undefined
      ) {
        this.model.pfcContainerStatus = dt.pfcContainerStatus;
      }
      //17. Check Weight Class
      if (
        dt.pfcWeightClass !== null &&
        dt.pfcWeightClass !== "" &&
        dt.pfcWeightClass !== undefined
      ) {
        this.model.pfcWeightClass = dt.pfcWeightClass;
      }

      //18. Check Commodity
      if (
        dt.pfcCommodityId !== null &&
        dt.pfcCommodityId !== "" &&
        dt.pfcCommodityId !== undefined
      ) {
        this.cbMaintenanceCommodity.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCommodities/findByComboBoxControl/commodityId={query}"
        );
        this.cbMaintenanceCommodity.setValue(dt.pfcCommodityId);
      }
      // console.log('** 19. Check Container Ownership **');
      if (
        dt.pfcContainerOwnership !== null &&
        dt.pfcContainerOwnership !== "" &&
        dt.pfcContainerOwnership !== undefined
      ) {
        this.model.pfcContainerOwnership = dt.pfcContainerOwnership;

        if (
          dt.pfcContainerOwnership == "COC"
        ) {
          this.lockDetention = false;
          this.lockDemurrage = false;
          this.model.pfcContainerOwnershipPrefix = "C";
        } else if (
          dt.pfcContainerOwnership == "COCTS"
        ) {
          this.lockDetention = true;
          this.lockDemurrage = true;
          this.model.pfcContainerOwnershipPrefix = "C";
        } else if (
          dt.pfcContainerOwnership == "SOC" ||
          dt.pfcContainerOwnership == "SOCTS"
        ) {
          this.lockDetention = true;
          this.lockDemurrage = true;
          this.model.pfcContainerOwnershipPrefix = "S";
        } else if (dt.pfcContainerOwnership == "SLOT") {
          this.lockDetention = true;
          this.lockDemurrage = true;
          this.model.pfcContainerOwnershipPrefix = "SL";
        }
      }

      //20. Check Prospects
      /*
         if(dt.pfcProspectD20 !== null && dt.pfcProspectD20 !== '' && dt.pfcProspectD20 !== undefined){this.model.pfcProspectD20=dt.pfcProspectD20;}
         if(dt.pfcProspectD40 !== null && dt.pfcProspectD40 !== '' && dt.pfcProspectD40 !== undefined){this.model.pfcProspectD40=dt.pfcProspectD40;}
         if(dt.pfcProspectD45 !== null && dt.pfcProspectD45 !== '' && dt.pfcProspectD45 !== undefined){this.model.pfcProspectD45=dt.pfcProspectD45;}
         if(dt.pfcProspectH20 !== null && dt.pfcProspectH20 !== '' && dt.pfcProspectH20 !== undefined){this.model.pfcProspectH20=dt.pfcProspectH20;}
         if(dt.pfcProspectH40 !== null && dt.pfcProspectH40 !== '' && dt.pfcProspectH40 !== undefined){this.model.pfcProspectH40=dt.pfcProspectH40;}
         if(dt.pfcProspectH45 !== null && dt.pfcProspectH45 !== '' && dt.pfcProspectH45 !== undefined){this.model.pfcProspectH45=dt.pfcProspectH45;}
         */
      if (dt.pfcProspectD20 !== null && dt.pfcProspectD20 !== undefined) {
        this.model.pfcProspectD20 = dt.pfcProspectD20;
      }
      if (dt.pfcProspectD40 !== null && dt.pfcProspectD40 !== undefined) {
        this.model.pfcProspectD40 = dt.pfcProspectD40;
      }
      if (dt.pfcProspectD45 !== null && dt.pfcProspectD45 !== undefined) {
        this.model.pfcProspectD45 = dt.pfcProspectD45;
      }
      if (dt.pfcProspectH20 !== null && dt.pfcProspectH20 !== undefined) {
        this.model.pfcProspectH20 = dt.pfcProspectH20;
      }
      if (dt.pfcProspectH40 !== null && dt.pfcProspectH40 !== undefined) {
        this.model.pfcProspectH40 = dt.pfcProspectH40;
      }
      if (dt.pfcProspectH45 !== null && dt.pfcProspectH45 !== undefined) {
        this.model.pfcProspectH45 = dt.pfcProspectH45;
      }

      //21. Check for Detention
      if (
        dt.pfcDetentionPOL !== null &&
        dt.pfcDetentionPOL !== "" &&
        dt.pfcDetentionPOL !== undefined
      ) {
        this.model.pfcDetentionPOL = dt.pfcDetentionPOL;
        if (dt.pfcDetentionPOL == "R") {
          this.model.pfcDetentionRequestPOL = dt.pfcDetentionRequestPOL;
        }
      }

      if (
        dt.pfcDetention !== null &&
        dt.pfcDetention !== "" &&
        dt.pfcDetention !== undefined
      ) {
        this.model.pfcDetention = dt.pfcDetention;
        if (dt.pfcDetention == "R") {
          this.model.pfcDetentionRequest = dt.pfcDetentionRequest;
        }
      }

      //22. Check for Demurrage
      if (
        dt.pfcDemurragePOL !== null &&
        dt.pfcDemurragePOL !== "" &&
        dt.pfcDemurragePOL !== undefined
      ) {
        this.model.pfcDemurragePOL = dt.pfcDemurragePOL;
        if (dt.pfcDemurragePOL == "R") {
          this.model.pfcDemurrageRequestPOL = dt.pfcDemurrageRequestPOL;
        }
      }

      if (
        dt.pfcDemurrage !== null &&
        dt.pfcDemurrage !== "" &&
        dt.pfcDemurrage !== undefined
      ) {
        this.model.pfcDemurrage = dt.pfcDemurrage;
        if (dt.pfcDemurrage == "R") {
          this.model.pfcDemurrageRequest = dt.pfcDemurrageRequest;
        }
      }
    }

    //23. Creation Information
    if (
      dt.pfcUserCreated !== null &&
      dt.pfcUserCreated !== "" &&
      dt.pfcUserCreated !== undefined
    ) {
      this.model.pfcUserCreated = dt.pfcUserCreated;
    }
    if (
      dt.pfcDateCreated !== null &&
      dt.pfcDateCreated !== "" &&
      dt.pfcDateCreated !== undefined
    ) {
      this.model.pfcDateCreated = dt.pfcDateCreated;
      this.dateCreatedForCbPayer = this.formatDate(dt.pfcDateCreated);
    }

    //24. Update Information
    if (
      dt.pfcUserModified !== null &&
      dt.pfcUserModified !== "" &&
      dt.pfcUserModified !== undefined
    ) {
      this.model.pfcUserModified = dt.pfcUserModified;
    }
    if (
      dt.pfcDateModified !== null &&
      dt.pfcDateModified !== "" &&
      dt.pfcDateModified !== undefined
    ) {
      this.model.pfcDateModified = dt.pfcDateModified;
    }

    //24. Check Container Type
    if (
      dt.pfcContainerType !== null &&
      dt.pfcContainerType !== "" &&
      dt.pfcContainerType !== undefined
    ) {
      this.model.pfcContainerType = dt.pfcContainerType;
    }

    //25. Check for Effective Date
    if (
      dt.pfcEffectiveFrom !== null &&
      dt.pfcEffectiveFrom !== "" &&
      dt.pfcEffectiveFrom !== undefined
    ) {
      //this.dateMaintenanceEffectiveFrom.setValue(dt.pfcEffectiveFrom);
      this.isChangeEffectiveFrom = true;
      this.dateMaintenanceEffectiveFrom2.setValue(dt.pfcEffectiveFrom);

      this.model.pfcEffectiveFrom = dt.pfcEffectiveFrom;
    }
    if (
      dt.pfcEffectiveTo !== null &&
      dt.pfcEffectiveTo !== "" &&
      dt.pfcEffectiveTo !== undefined
    ) {
      //this.dateMaintenanceEffectiveTo.setValue(dt.pfcEffectiveTo);
      this.isChangeEffectiveTo = true;
      this.dateMaintenanceEffectiveTo2.setValue(dt.pfcEffectiveTo);
      this.model.pfcEffectiveTo = dt.pfcEffectiveTo;
      this.apiEffectiveTo = dt.pfcEffectiveTo;
    }

    this.model.pfcIsCommission = dt.pfcIsCommission;
    this.model.pfcIsDeleted = dt.pfcIsDeleted;
    this.model.pfcIsValid = dt.pfcIsValid;
    this.model.pfcUserInvalid = dt.pfcUserInvalid;
    this.model.pfcDateInvalid = dt.pfcDateInvalid;

    this.model.pfcPrincipalRemarks =
      dt.pfcPrincipalRemarks != null && dt.pfcPrincipalRemarks != undefined
        ? dt.pfcPrincipalRemarks
        : "";
    this.txtRemarksPrincipal.setValue(this.model.pfcPrincipalRemarks);

    this.model.pfcAgentRemarks =
      dt.pfcAgentRemarks != null && dt.pfcAgentRemarks != undefined
        ? dt.pfcAgentRemarks
        : "";
    this.txtRemarksAgency.setValue(this.model.pfcAgentRemarks);

    console.log("user 1");
    console.log(this.model.pfcUserCreated);

    // if (
    //   dt.pfcUserCreated != null &&
    //   dt.pfcUserCreated != "" &&
    //   dt.pfcUserCreated !== undefined
    // ) {
    //   // User ID sama dengan pfc user created
    //   this.model.pfcUserId = dt.pfcUserCreated;
    // }

    this.model.pfcUserId = this.cookieService.getName().toUpperCase();

    //AcctPIC
    // console.log('dt.pfcAcctPic -> ' + dt.pfcAcctPic);
    if (
      dt.pfcAcctPic != null &&
      dt.pfcAcctPic != "" &&
      dt.pfcAcctPic !== undefined
    ) {
      var self = this;
      this.cbAcctPic.setUrl(
        this.configService.config.BASE_API.toString() +
        `/MasterAccountPic/findAcctPIC/${dt.pfcAcctPic}`
      );
      this.cbAcctPic.setValue(dt.pfcAcctPic);
      this.model.pfcAcctPic = dt.pfcAcctPic;
    }

    //29. console.log("** 1.1. Check Booking Party **");
    if (
      dt.pfcBookingPartyId !== null &&
      dt.pfcBookingPartyId !== "" &&
      dt.pfcBookingPartyId !== undefined
    ) {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
        "/PFCGenericAPI/findByComboBoxControlGeneral/booking_party/customerId={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E"+
        this.sCharUtil.htmlEncode("|")+
        "recordId="+dt.pfcRecordId,
      );
      this.cbMaintenanceBookingParty.setValue(dt.pfcBookingPartyId);
    }

    // console.log('** data retrieve **');
    this.model.pfcDetails = dt.pfcDetails;

    let modelKey = new FreightManagement();
    modelKey.pfcOfficeCode = dt.pfcOfficeCode;
    modelKey.pfcFreightPartyId = dt.pfcFreightPartyId;
    modelKey.pfcShipperId = dt.pfcShipperId;
    modelKey.pfcPlaceOfReceiptCode = dt.pfcPlaceOfReceiptCode;
    modelKey.pfcLoadPortCode = dt.pfcLoadPortCode;
    modelKey.pfcDischargePortCode = dt.pfcDischargePortCode;
    modelKey.pfcPlaceOfDeliveryCode = dt.pfcPlaceOfDeliveryCode;
    modelKey.pfcFinalDestinationCode = dt.pfcFinalDestinationCode;
    modelKey.pfcPortOfTransshipment1Code = dt.pfcPortOfTransshipment1Code;
    modelKey.pfcPortOfTransshipment2Code = dt.pfcPortOfTransshipment2Code;
    modelKey.pfcPortOfTransshipment3Code = dt.pfcPortOfTransshipment3Code;
    modelKey.pfcCommodityId = dt.pfcCommodityId;
    modelKey.pfcReceiptTypeCode = dt.pfcReceiptTypeCode;
    modelKey.pfcDeliveryTypeCode = dt.pfcDeliveryTypeCode;
    modelKey.pfcContainerOwnership = dt.pfcContainerOwnership;
    modelKey.pfcContainerStatus = dt.pfcContainerStatus;
    modelKey.pfcShipmentType = dt.pfcShipmentType;
    modelKey.pfcVesselId = dt.pfcVesselId;
    modelKey.pfcVoyage = dt.pfcVoyage;

    modelKey.pfcDetentionPOL = dt.pfcDetentionPOL;
    modelKey.pfcDetentionRequestPOL = dt.pfcDetentionRequestPOL;
    modelKey.pfcDemurragePOL = dt.pfcDemurragePOL;
    modelKey.pfcDemurrageRequestPOL = dt.pfcDemurrageRequestPOL;

    modelKey.pfcDetention = dt.pfcDetention;
    modelKey.pfcDetentionRequest = dt.pfcDetentionRequest;
    modelKey.pfcDemurrage = dt.pfcDemurrage;
    modelKey.pfcDemurrageRequest = dt.pfcDemurrageRequest;
    modelKey.pfcIhc = dt.pfcIhc;
    modelKey.pfcDgClass = dt.pfcDgClass;
    modelKey.pfcEffectiveFrom = dt.pfcEffectiveFrom;
    modelKey.pfcEffectiveTo = dt.pfcEffectiveTo;
    modelKey.pfcBookingPartyId = dt.pfcBookingPartyId;
    this.freightManagementKeyRetrieve =
      this.getFreightManagementKeyString(modelKey);

    this.freightManagementRetrieve = this.getFreightManagementString(dt);
    this.freightDetailsRetrieve = "";
    this.mainArchiveRetrieve = dt;
    // console.log('** Load Detail **');
    this.mapCheck = new Map();
    this.containerTypeList = [];
    this.listPayerExisting = [];
    this.map = new Map(); //reset the map
    dt.pfcDetails.forEach((element) => {
      this.freightDetailsRetrieve += this.getFreightDetailStringX(element, dt);
      console.log(element);
      //ini yg harus diliat
      if (element.pfcDetailPayerIsPayer == "N") {
        // var strPush = element.pfcDetailPayerName+" is invalid payer";
        if (!this.groupAllowInvalidPayer.includes(element.pfcDetailPayer)) {
          this.groupAllowInvalidPayer.push(element.pfcDetailPayer);
        }
      }

      if(element.pfcDetailPayer != ""){
        if (!this.listPayerExisting.includes(element.pfcDetailPayer)) {
          this.listPayerExisting.push(element.pfcDetailPayer);
        }
      }

      if (this.map.get(element.pfcDetailContainerType) == undefined) {
        this.containerTypeList.push(element.pfcDetailContainerType);

        this.modelDetailList = [];
        this.modelDetailList.push(element);
        this.map.set(element.pfcDetailContainerType, this.modelDetailList);
      } else {
        this.map.get(element.pfcDetailContainerType).push(element);
      }

      if (this.mapCheck.get(element.pfcDetailContainerType) == undefined) {
        this.mapCheck.set(
          element.pfcDetailContainerType,
          element.pfcDetailContainerType
        );
      }
    });

    if (this.listPayerExisting.length > 0) {
      this.custIdForCbPayer = this.listPayerExisting.join("%7C");
    }else{
      this.custIdForCbPayer = "*";
    }
    // this.includePayerInvalid = groupInvalidPayer.toString();
    // console.log(groupInvalidPayer);
    // console.log(this.includePayerInvalid);
    // console.log(this.map);
    // console.log(this.model);

    // console.log('** containerTypeList **');
    // console.log(this.containerTypeList);

    // TOTAL Prepaid
    //this.gridPrepaid.total = [];

    // TOTAL Collect
    //this.gridCollect.total = [];

    //this.gridPrepaid.onClear();
    //this.gridCollect.onClear();
    this.gridApiPrepaid.setRowData([]);
    this.gridApiCollect.setRowData([]);

    var detailList: any[] = this.map.get(this.model.pfcContainerType);
    detailList.forEach((element) => {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;
      switch (element.pfcDetailContainerType) {
        case "STD":
          element.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

      if (x.pfcDetailPCTab == "P") {
        this.totD20Prepaid += element.pfcDetailD20;
        this.totD40Prepaid += element.pfcDetailD40;
        this.totD45Prepaid += element.pfcDetailD45;
        this.totH20Prepaid += element.pfcDetailH20;
        this.totH40Prepaid += element.pfcDetailH40;
        this.totH45Prepaid += element.pfcDetailH45;

        //this.gridPrepaid.listStore.store.push(x);
        //this.gridPrepaid.loadData();
        this.gridApiPrepaid.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }

      if (x.pfcDetailPCTab == "C") {
        this.totD20Collect += element.pfcDetailD20;
        this.totD40Collect += element.pfcDetailD40;
        this.totD45Collect += element.pfcDetailD45;
        this.totH20Collect += element.pfcDetailH20;
        this.totH40Collect += element.pfcDetailH40;
        this.totH45Collect += element.pfcDetailH45;

        //this.gridCollect.listStore.store.push(x);
        //this.gridCollect.loadData();
        this.gridApiCollect.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }
    });

    // Hitung Total
    this.calculateTotal();
    /*
    this.gridPrepaid.total.push({
      "PFCDETAILD20" : this.totD20Prepaid,
      "PFCDETAILD40" : this.totD40Prepaid,
      "PFCDETAILD45" : this.totD45Prepaid,
      "PFCDETAILH20" : this.totH20Prepaid,
      "PFCDETAILH40" : this.totH40Prepaid,
      "PFCDETAILH45" : this.totH45Prepaid
    })
    this.gridPrepaid.loadData();

    this.gridCollect.total.push({
      "PFCDETAILD20" : this.totD20Collect,
      "PFCDETAILD40" : this.totD40Collect,
      "PFCDETAILD45" : this.totD45Collect,
      "PFCDETAILH20" : this.totH20Collect,
      "PFCDETAILH40" : this.totH40Collect,
      "PFCDETAILH45" : this.totH45Collect
    })
    this.gridCollect.loadData();
    */

    if (dt.pfcIsValid == "Y") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "delete";
      }
    } else if (dt.pfcIsValid == "N") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "copy";
      }
    }

    this.model.disableInvalid = dt.disableInvalid;
    if (this.model.disableInvalid == undefined) {
      this.model.disableInvalid = "false";
    }
    this.model.pfcAppStatus = dt.pfcAppStatus;
    this.model.pfcIsTransmitted = dt.pfcIsTransmitted;
    this.model.historyList = dt.historyList;

    // console.log('this.model.disableInvalid : ' + this.model.disableInvalid);
    // console.log('this.model.pfcAppStatus : ' + this.model.pfcAppStatus);
    // console.log('this.model.pfcIsTransmitted : ' + this.model.pfcIsTransmitted);
    // console.log('this.lock => ' + this.lock);

    switch (dt.pfcAppStatus) {
      case "A": {
        console.log("---   APPROVED   ---");
        this.appStatusLabel = ""; // '   ---   APPROVED   ---';
        this.lock = false;
        break;
      }
      case "R": {
        console.log("---   REJECTED   ---");
        this.appStatusLabel = ""; // '   ---   REJECTED   ---';
        this.lock = false;
        break;
      }
      default: {
        if (dt.pfcIsTransmitted == "Y") {
          console.log("---   PENDING TRASMITTED   ---");
          this.appStatusLabel = ""; // '   ---   PENDING TRASMITTED   ---';
          this.lock = true;
          this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
          this.message(
            "information",
            "Information",
            'There is an existing record under "PENDING" status and already transmitted. Please wait for Principal action.',
            "okonly",
            { ok: "" }
          );
        } else {
          if (dt.pfcIsDeleted == "N") {
            console.log("---   PENDING UNTRANSMITTED   ---");
            this.appStatusLabel = ""; //'   ---   PENDING UNTRANSMITTED   ---';
            this.lock = false;
          } else if (dt.pfcIsDeleted == "Y") {
            console.log("---   PENDING UNTRANSMITTED (DELETED)   ---");
            this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
            this.appStatusLabel = ""; // '   ---   PENDING UNTRANSMITTED (DELETED)   ---';
            this.lock = true;
          }
        }
        break;
      }
    }

    this.isChangeEffectiveFrom = false;
    this.isChangeEffectiveTo = false;
    this.isRetrieve = false;

    if (dt.pfcIsValid == "N") {
      this.lock = true;
    }

    if (dt.pfcIsDeleted == "Y") {
      this.disableToolbarButtons =
        this.disableToolbarButtons + ",nct,save,cancel,copy,delete,print";

      this.msg = "This rate already deleted.";
      this.genericUtil.showDialog("messageDialog", "Confirmation", 350, 150);
    }

    //this.currentActiveTab = "prepaid";
    this.initTab1();
    window.scrollTo(0, 0);
  }

  onMessageDialogOk() {
    this.genericUtil.closeDialog("messageDialog");
  }

  onHistoryDialogOk() {
    this.genericUtil.closeDialog("historyDialog");
  }

  handleDoubleClickAfterSave(dt: FreightManagement) {
    // console.log('dt => ');
    // console.log(dt);

    //23. Creation Information
    if (
      dt.pfcUserCreated !== null &&
      dt.pfcUserCreated !== "" &&
      dt.pfcUserCreated !== undefined
    ) {
      this.model.pfcUserCreated = dt.pfcUserCreated;
    }
    if (
      dt.pfcDateCreated !== null &&
      dt.pfcDateCreated !== "" &&
      dt.pfcDateCreated !== undefined
    ) {
      this.model.pfcDateCreated = dt.pfcDateCreated;
      this.dateCreatedForCbPayer = this.formatDate(dt.pfcDateCreated);
    }

    //24. Update Information
    if (
      dt.pfcUserModified !== null &&
      dt.pfcUserModified !== "" &&
      dt.pfcUserModified !== undefined
    ) {
      this.model.pfcUserModified = dt.pfcUserModified;
    }
    if (
      dt.pfcDateModified !== null &&
      dt.pfcDateModified !== "" &&
      dt.pfcDateModified !== undefined
    ) {
      this.model.pfcDateModified = dt.pfcDateModified;
    }

    //24. Check Container Type
    if (
      dt.pfcContainerType !== null &&
      dt.pfcContainerType !== "" &&
      dt.pfcContainerType !== undefined
    ) {
      this.model.pfcContainerType = dt.pfcContainerType;
    }

    //25. Check for Effective Date
    if (
      dt.pfcEffectiveFrom !== null &&
      dt.pfcEffectiveFrom !== "" &&
      dt.pfcEffectiveFrom !== undefined
    ) {
      //this.dateMaintenanceEffectiveFrom.setValue(dt.pfcEffectiveFrom);
      this.isChangeEffectiveFrom = true;
      this.dateMaintenanceEffectiveFrom2.setValue(dt.pfcEffectiveFrom);

      this.model.pfcEffectiveFrom = dt.pfcEffectiveFrom;
    }
    if (
      dt.pfcEffectiveTo !== null &&
      dt.pfcEffectiveTo !== "" &&
      dt.pfcEffectiveTo !== undefined
    ) {
      //this.dateMaintenanceEffectiveTo.setValue(dt.pfcEffectiveTo);
      this.isChangeEffectiveTo = true;
      this.dateMaintenanceEffectiveTo2.setValue(dt.pfcEffectiveTo);
      this.model.pfcEffectiveTo = dt.pfcEffectiveTo;
      this.apiEffectiveTo = dt.pfcEffectiveTo;
    }

    this.model.pfcIsCommission = dt.pfcIsCommission;
    this.model.pfcIsDeleted = dt.pfcIsDeleted;
    this.model.pfcIsValid = dt.pfcIsValid;
    this.model.pfcUserInvalid = dt.pfcUserInvalid;
    this.model.pfcDateInvalid = dt.pfcDateInvalid;

    //AcctPIC
    // console.log('dt.pfcAcctPic -> ' + dt.pfcAcctPic);
    if (
      dt.pfcAcctPic != null &&
      dt.pfcAcctPic != "" &&
      dt.pfcAcctPic !== undefined
    ) {
      var self = this;
      this.cbAcctPic.setUrl(
        this.configService.config.BASE_API.toString() +
          `/MasterAccountPic/findAcctPIC/${dt.pfcAcctPic}`
      );
      this.cbAcctPic.setValue(dt.pfcAcctPic);
      this.model.pfcAcctPic = dt.pfcAcctPic;
    }

    // console.log('** data retrieve **');
    this.model.pfcDetails = dt.pfcDetails;

    let modelKey = new FreightManagement();
    modelKey.pfcOfficeCode = dt.pfcOfficeCode;
    modelKey.pfcFreightPartyId = dt.pfcFreightPartyId;
    modelKey.pfcShipperId = dt.pfcShipperId;
    modelKey.pfcPlaceOfReceiptCode = dt.pfcPlaceOfReceiptCode;
    modelKey.pfcLoadPortCode = dt.pfcLoadPortCode;
    modelKey.pfcDischargePortCode = dt.pfcDischargePortCode;
    modelKey.pfcPlaceOfDeliveryCode = dt.pfcPlaceOfDeliveryCode;
    modelKey.pfcFinalDestinationCode = dt.pfcFinalDestinationCode;
    modelKey.pfcPortOfTransshipment1Code = dt.pfcPortOfTransshipment1Code;
    modelKey.pfcPortOfTransshipment2Code = dt.pfcPortOfTransshipment2Code;
    modelKey.pfcPortOfTransshipment3Code = dt.pfcPortOfTransshipment3Code;
    modelKey.pfcCommodityId = dt.pfcCommodityId;
    modelKey.pfcReceiptTypeCode = dt.pfcReceiptTypeCode;
    modelKey.pfcDeliveryTypeCode = dt.pfcDeliveryTypeCode;
    modelKey.pfcContainerOwnership = dt.pfcContainerOwnership;
    modelKey.pfcContainerStatus = dt.pfcContainerStatus;
    modelKey.pfcShipmentType = dt.pfcShipmentType;
    modelKey.pfcVesselId = dt.pfcVesselId;
    modelKey.pfcVoyage = dt.pfcVoyage;
    modelKey.pfcDetentionPOL = dt.pfcDetentionPOL;
    modelKey.pfcDetentionRequestPOL = dt.pfcDetentionRequestPOL;
    modelKey.pfcDemurragePOL = dt.pfcDemurragePOL;
    modelKey.pfcDemurrageRequestPOL = dt.pfcDemurrageRequestPOL;
    modelKey.pfcDetention = dt.pfcDetention;
    modelKey.pfcDetentionRequest = dt.pfcDetentionRequest;
    modelKey.pfcDemurrage = dt.pfcDemurrage;
    modelKey.pfcDemurrageRequest = dt.pfcDemurrageRequest;
    modelKey.pfcIhc = dt.pfcIhc;
    modelKey.pfcDgClass = dt.pfcDgClass;
    modelKey.pfcEffectiveFrom = dt.pfcEffectiveFrom;
    modelKey.pfcEffectiveTo = dt.pfcEffectiveTo;
    modelKey.pfcBookingPartyId = dt.pfcBookingPartyId

    this.freightManagementKeyRetrieve =
      this.getFreightManagementKeyString(modelKey);

    this.freightManagementRetrieve = this.getFreightManagementString(dt);
    this.freightDetailsRetrieve = "";
    this.mainArchiveRetrieve = dt;
    // console.log('** Load Detail **');

    this.containerTypeList = [];
    this.map = new Map(); //reset the map
    dt.pfcDetails.forEach((element) => {
      this.freightDetailsRetrieve += this.getFreightDetailString(element);
      // console.log(element);

      if (this.map.get(element.pfcDetailContainerType) == undefined) {
        this.containerTypeList.push(element.pfcDetailContainerType);

        this.modelDetailList = [];
        this.modelDetailList.push(element);
        this.map.set(element.pfcDetailContainerType, this.modelDetailList);
      } else {
        this.map.get(element.pfcDetailContainerType).push(element);
      }
    });

    // console.log(this.map);
    // console.log(this.model);

    // console.log('** containerTypeList **');
    // console.log(this.containerTypeList);

    //this.gridPrepaid.onClear();
    //this.gridCollect.onClear();
    this.gridApiPrepaid.setRowData([]);
    this.gridApiCollect.setRowData([]);
    var detailList: any[] = this.map.get(this.model.pfcContainerType);
    detailList.forEach((element) => {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;
      switch (element.pfcDetailContainerType) {
        case "STD":
          element.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if (x.pfcDetailOogDoorOpen == "N") {
        x.pfcDetailOogDoorOpenName = "N/A";
      } else if (x.pfcDetailOogDoorOpen == "D") {
        x.pfcDetailOogDoorOpenName = "Detached";
      } else if (x.pfcDetailOogDoorOpen == "B") {
        x.pfcDetailOogDoorOpenName = "Tied Both";
      } else if (x.pfcDetailOogDoorOpen == "L") {
        x.pfcDetailOogDoorOpenName = "Tied Left";
      } else if (x.pfcDetailOogDoorOpen == "R") {
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
      x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

      if (x.pfcDetailPCTab == "P") {
        //this.gridPrepaid.listStore.store.push(x);
        //this.gridPrepaid.loadData();
        this.gridApiPrepaid.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }

      if (x.pfcDetailPCTab == "C") {
        //this.gridCollect.listStore.store.push(x);
        //this.gridCollect.loadData();
        this.gridApiCollect.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }
    });

    if (dt.pfcIsValid == "Y") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "delete";
      }
    } else if (dt.pfcIsValid == "N") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "nct,save,copy,update,delete,create";
      } else {
        this.disableToolbarButtons = "";
      }
    }

    this.model.disableInvalid = dt.disableInvalid;
    if (this.model.disableInvalid == undefined) {
      this.model.disableInvalid = "false";
    }
    this.model.pfcAppStatus = dt.pfcAppStatus;
    this.model.pfcIsTransmitted = dt.pfcIsTransmitted;

    // console.log('this.model.disableInvalid : ' + this.model.disableInvalid);
    // console.log('this.model.pfcAppStatus : ' + this.model.pfcAppStatus);
    // console.log('this.model.pfcIsTransmitted : ' + this.model.pfcIsTransmitted);
    // console.log('this.lock => ' + this.lock);

    switch (dt.pfcAppStatus) {
      case "A": {
        console.log("---   APPROVED   ---");
        this.appStatusLabel = ""; // '   ---   APPROVED   ---';
        this.lock = false;
        break;
      }
      case "R": {
        console.log("---   REJECTED   ---");
        this.appStatusLabel = ""; // '   ---   REJECTED   ---';
        this.lock = false;
        break;
      }
      default: {
        if (dt.pfcIsTransmitted == "Y") {
          console.log("---   PENDING TRASMITTED   ---");
          this.appStatusLabel = ""; // '   ---   PENDING TRASMITTED   ---';
          this.lock = true;
          this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
          this.message(
            "information",
            "Information",
            'There is an existing record under "PENDING" status and already transmitted. Please wait for Principal action.',
            "okonly",
            { ok: "" }
          );
        } else {
          if (dt.pfcIsDeleted == "N") {
            console.log("---   PENDING UNTRANSMITTED   ---");
            this.appStatusLabel = ""; //'   ---   PENDING UNTRANSMITTED   ---';
            this.lock = true;
          } else if (dt.pfcIsDeleted == "Y") {
            console.log("---   PENDING UNTRANSMITTED (DELETED)   ---");
            this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
            this.appStatusLabel = ""; // '   ---   PENDING UNTRANSMITTED (DELETED)   ---';
            this.lock = true;
          }
        }
        break;
      }
    }

    this.isChangeEffectiveFrom = false;
    this.isChangeEffectiveTo = false;
    this.isRetrieve = false;

    window.scrollTo(0, 0);
  }

  //unused method
  changeCbPrepaidStatus(event) {}

  changeCbCollectStatus(event) {}

  getFreightManagementKeyString(dt: FreightManagement): string {
    //return dt.pfcOfficeCode + '/' +
    return (
      dt.pfcFreightPartyId +
      "/" +
      dt.pfcShipperId + "/" +
      dt.pfcPlaceOfReceiptCode +
      "/" +
      dt.pfcLoadPortCode +
      "/" +
      dt.pfcDischargePortCode +
      "/" +
      dt.pfcPlaceOfDeliveryCode +
      "/" +
      dt.pfcFinalDestinationCode +
      "/" +
      dt.pfcPortOfTransshipment1Code +
      "/" +
      dt.pfcPortOfTransshipment2Code +
      "/" +
      dt.pfcPortOfTransshipment3Code +
      "/" +
      dt.pfcCommodityId +
      "/" +
      dt.pfcReceiptTypeCode +
      "/" +
      dt.pfcDeliveryTypeCode +
      "/" +
      //dt.pfcEffectiveFrom + '/' +
      //dt.pfcEffectiveTo + '/' +
      dt.pfcContainerOwnership +
      "/" +
      dt.pfcContainerStatus +
      "/" +
      dt.pfcShipmentType +
      "/" +
      dt.pfcVesselId +
      "/" +
      dt.pfcVoyage +
      "/" +
      dt.pfcDetentionPOL +
      "/" +
      dt.pfcDetentionRequestPOL +
      "/" +
      dt.pfcDemurragePOL +
      "/" +
      dt.pfcDemurrageRequestPOL +
      "/" +
      dt.pfcDetention +
      "/" +
      dt.pfcDetentionRequest +
      "/" +
      dt.pfcDemurrage +
      "/" +
      dt.pfcDemurrageRequest +
      "/" +
      dt.pfcIhc +
      "/" +
      dt.pfcDgClass  +
      "/" +
      dt.pfcBookingPartyId

    );
  }

  getFreightManagementString(dt: FreightManagement): string {
    return (
      dt.pfcOfficeCode +
      "/" +
      dt.pfcRecordId +
      "/" +
      dt.pfcFreightPartyId +
      "/" +
      dt.pfcShipperId + "/" +
      dt.pfcPlaceOfReceiptCode +
      "/" +
      dt.pfcLoadPortCode +
      "/" +
      dt.pfcDischargePortCode +
      "/" +
      dt.pfcPlaceOfDeliveryCode +
      "/" +
      dt.pfcFinalDestinationCode +
      "/" +
      dt.pfcPortOfTransshipment1Code +
      "/" +
      dt.pfcPortOfTransshipment2Code +
      "/" +
      dt.pfcPortOfTransshipment3Code +
      "/" +
      //dt.pfcContainerType + '/' +
      dt.pfcCommodityId +
      "/" +
      dt.pfcReceiptTypeCode +
      "/" +
      dt.pfcDeliveryTypeCode +
      "/" +
      dt.pfcEffectiveFrom +
      "/" +
      dt.pfcEffectiveTo +
      "/" +
      dt.pfcContainerOwnership +
      "/" +
      dt.pfcContainerStatus +
      "/" +
      dt.pfcWeightClass +
      "/" +
      dt.pfcUnno +
      "/" +
      dt.pfcImo +
      "/" +
      dt.pfcFlashPoint +
      "/" +
      dt.pfcShipmentType +
      "/" +
      dt.pfcVesselId +
      "/" +
      dt.pfcVoyage +
      "/" +
      dt.pfcDetentionPOL +
      "/" +
      dt.pfcDetentionRequestPOL +
      "/" +
      dt.pfcDemurragePOL +
      "/" +
      dt.pfcDemurrageRequestPOL +
      "/" +
      dt.pfcDetention +
      "/" +
      dt.pfcDetentionRequest +
      "/" +
      dt.pfcDemurrage +
      "/" +
      dt.pfcDemurrageRequest +
      "/" +
      dt.pfcIhc +
      "/" +
      dt.pfcDgClass +
      "/" +
      dt.pfcPrincipalRemarks +
      "/" +
      dt.pfcAgentRemarks +
      "/" +
      dt.pfcCompetitorDataId +
      "/" +
      dt.pfcAppStatus +
      "/" +
      dt.pfcAgencyId +
      "/" +
      dt.pfcUserCreated +
      "/" +
      dt.pfcDateCreated +
      "/" +
      //dt.pfcUserModified + '/' +
      //dt.pfcDateModified + '/' +
      //dt.pfcUserRequested + '/' +
      //dt.pfcDateRequested + '/' +
      //dt.pfcUserApproved + '/' +
      //dt.pfcDateApproved + '/' +
      //dt.pfcIsTransmitted + '/' +
      //dt.pfcUserTransmitted + '/' +
      //dt.pfcDateTransmitted + '/' +
      dt.pfcPORTerminalCode +
      "/" +
      dt.pfcPOLTerminalCode +
      "/" +
      dt.pfcPODTerminalCode +
      "/" +
      dt.pfcPODLTerminalCode +
      "/" +
      dt.pfcPOT1TerminalCode +
      "/" +
      dt.pfcPOT2TerminalCode +
      "/" +
      dt.pfcPOT3TerminalCode +
      "/" +
      dt.pfcFDESTTerminalCode +
      "/" +
      dt.pfcCustomColumn +
      "/" +
      dt.pfcProspectD20 +
      "/" +
      dt.pfcProspectD40 +
      "/" +
      dt.pfcProspectD45 +
      "/" +
      dt.pfcProspectH20 +
      "/" +
      dt.pfcProspectH40 +
      "/" +
      dt.pfcProspectH45 +
      "/" +
      dt.pfcIsValid +
      "/" +
      dt.pfcUserInvalid +
      "/" +
      dt.pfcDateInvalid +
      "/" +
      dt.pfcIsDeleted +
      "/" +
      dt.pfcUserDeleted +
      "/" +
      dt.pfcDateDeleted +
      "/" +
      dt.pfcReasonDeleted +
      "/" +
      dt.pfcIsCommission +
      "/" +
      dt.pfcAcctPic +
      "/" +
      dt.pfcBookingPartyId
    );
  }

  getFreightDetailString(dt: FreightDetail): string {
    return (
      dt.pfcDetailSeqNo +
      "/" +
      dt.pfcDetailStatus +
      "/" +
      dt.pfcDetailContainerType +
      "/" +
      dt.pfcDetailChargeCode +
      "/" +
      dt.pfcDetailChargeName +
      "/" +
      dt.pfcDetailCurrency +
      "/" +
      dt.pfcDetailPC +
      "/" +
      dt.pfcDetailPlaceOfPayment +
      "/" +
      dt.pfcDetailPlaceOfPaymentName +
      "/" +
      dt.pfcDetailPayer +
      "/" +
      dt.pfcDetailPayerName +
      "/" +
      dt.pfcDetailD20 +
      "/" +
      dt.pfcDetailD40 +
      "/" +
      dt.pfcDetailD45 +
      "/" +
      dt.pfcDetailH20 +
      "/" +
      dt.pfcDetailH40 +
      "/" +
      dt.pfcDetailH45 +
      "/" +
      dt.pfcDetailM3 +
      "/" +
      dt.pfcDetailKT +
      "/" +
      dt.pfcDetailRT +
      "/" +
      dt.pfcDetailMinCharge +
      "/" +
      dt.pfcDetailKillSlot +
      "/" +
      dt.pfcDetailFAIGroup +
      "/" +
      dt.pfcDetailOogOH +
      "/" +
      dt.pfcDetailOogOWL +
      "/" +
      dt.pfcDetailOogOWR +
      "/" +
      dt.pfcDetailOogOLF +
      "/" +
      dt.pfcDetailOogOLB +
      "/" +
      dt.pfcDetailOogDoorOpen +
      "/" +
      dt.pfcDetailOogUcH +
      "/" +
      dt.pfcDetailOogUcL +
      "/" +
      dt.pfcDetailOogUcW +
      "/" +
      //dt.pfcDetailTotalChargeValue + '/' +
      //dt.pfcDetailCopyTab + '/' +
      dt.pfcDetailPCTab
    );
  }

  getFreightDetailStringX(dt: FreightDetail, dtMain: FreightManagement): string {
    return (
      dt.pfcDetailSeqNo +
      "/" +
      dt.pfcDetailStatus +
      "/" +
      dt.pfcDetailContainerType +
      "/" +
      dt.pfcDetailChargeCode +
      "/" +
      dt.pfcDetailChargeName +
      "/" +
      dt.pfcDetailCurrency +
      "/" +
      dt.pfcDetailPC +
      "/" +
      dt.pfcDetailPlaceOfPayment +
      "/" +
      dt.pfcDetailPlaceOfPaymentName +
      "/" +
      dt.pfcDetailPayer +
      "/" +
      dt.pfcDetailPayerName +
      "/" +
      dt.pfcDetailD20 +
      "/" +
      dt.pfcDetailD40 +
      "/" +
      dt.pfcDetailD45 +
      "/" +
      dt.pfcDetailH20 +
      "/" +
      dt.pfcDetailH40 +
      "/" +
      dt.pfcDetailH45 +
      "/" +
      dt.pfcDetailM3 +
      "/" +
      dt.pfcDetailKT +
      "/" +
      dt.pfcDetailRT +
      "/" +
      dt.pfcDetailMinCharge +
      "/" +
      dt.pfcDetailKillSlot +
      "/" +
      dt.pfcDetailFAIGroup +
      "/" +
      dt.pfcDetailOogOH +
      "/" +
      dt.pfcDetailOogOWL +
      "/" +
      dt.pfcDetailOogOWR +
      "/" +
      dt.pfcDetailOogOLF +
      "/" +
      dt.pfcDetailOogOLB +
      "/" +
      dt.pfcDetailOogDoorOpen +
      "/" +
      dt.pfcDetailOogUcH +
      "/" +
      dt.pfcDetailOogUcL +
      "/" +
      dt.pfcDetailOogUcW +
      "/" +
      //dt.pfcDetailTotalChargeValue + '/' +
      //dt.pfcDetailCopyTab + '/' +
      dt.pfcDetailPCTab +
      "/" +
      dtMain.pfcDetentionPOL +
      "/" +
      dtMain.pfcDetentionRequestPOL +
      "/" +
      dtMain.pfcDemurragePOL +
      "/" +
      dtMain.pfcDemurrageRequestPOL
    );
  }

  Check_MapSize() {
    if (this.map.size == 0) {
      this.message(
        "information",
        "Information",
        "There is no container type selected, no freight will be created.",
        "okonly",
        { ok: "this.genericUtil.hideLoader();" }
      );
      //this.dialogMultiple.current = undefined;
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();
      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //return;
    }
  }

  ClearRecordId() {
    this.model.pfcRecordId = "";
  }

  UpdateMap() {
    // console.log('** update map **');
    /*
    this.dialogMultiple.clear();
    var i: number = 1;
    this.map.forEach((value: any[], key: string) => {
      if(value.length > 0){
        var ct: string
        switch (key) {
          case 'TNK':
            ct = 'TANK';
            break;
          case 'OPT':
            ct = 'OPEN TOP';
            break;
          case 'FLR':
            ct = 'FLAT RACK';
            break;
          case 'PLT':
            ct = 'PLATFORM';
            break;
          case 'REF':
            ct = 'REEFER';
            break;
          case 'DRY':
            ct = 'REEFER DRY';
            break;
          case 'UC':
            ct = 'UC';
            break;
          case "STD":
            ct = 'NORMAL';
            break;
          default:
            ct = 'NORMAL';
            break;
        }
        var mp: any = {
          no: i++,
          icon: 'information',
          header: 'information',
          content: 'Do you want to create freight for ' + ct + ' Container ?',
          buttons: 'yesno',
          event: {yes: '', no: 'this.map.delete("' + key + '");'}
        };
        this.dialogMultiple.addMessage(mp);
      }
    });
    this.dialogMultiple.show();
    */
    this.mapCtype.clear();
    var i: number = 1;
    this.map.forEach((value: any[], key: string) => {
      console.log(key + " - " + value.length);
      if (value.length > 0 && this.mapCheck.get(key) == undefined) {
        let ix = i++;
        console.log(ix + " - " + key);
        this.mapCtype.set(ix, key);
      }
    });

    this.indexMapCtype++;
    var ct: string;
    switch (this.mapCtype.get(this.indexMapCtype)) {
      case "TNK":
        ct = "TANK";
        break;
      case "OPT":
        ct = "OPEN TOP";
        break;
      case "FLR":
        ct = "FLAT RACK";
        break;
      case "PLT":
        ct = "PLATFORM";
        break;
      case "REF":
        ct = "REEFER";
        break;
      case "DRY":
        ct = "REEFER DRY";
        break;
      case "UC":
        ct = "UC";
        break;
      case "STD":
        ct = "NORMAL";
        break;
      default:
        ct = "NORMAL";
        break;
    }
    this.multipleCtypeDialogText =
      "Do you want to create freight for " + ct + " Container ? ";
    this.genericUtil.showDialog("multipleCtypeDialog", "Information", 350, 150);
  }

  changeInvalid(event) {
    if (this.model.disableInvalid == "false") {
      if (event.target.checked) {
        this.model.pfcIsValid = "N";
        this.model.pfcDateInvalid = this.genericUtil.getPSIDate();
        this.model.pfcUserInvalid = this.cookieService.getName().toUpperCase();
      } else {
        this.model.pfcIsValid = "Y";
        this.model.pfcDateInvalid = "";
        this.model.pfcUserInvalid = "";
      }
      // console.log(this.model.pfcIsValid);
    } else {
      this.msg = 'Unable to invalid, please check "';
      this.model.historyList.forEach((element) => {
        if (element.after != undefined) {
          this.msg += element.after;
        }
        if (element.current != undefined) {
          //this.msg += "<b>Current : " + element.current + "</b><br/>"
        }
        if (element.before != undefined) {
          //this.msg += ", " + element.before
        }
      });
      this.msg += '"';

      if (event.target.checked == true) {
        event.target.checked = false;
      } else {
        event.target.checked = true;
      }

      this.genericUtil.showDialog("historyDialog", "Information", 350, 150);
    }
  }

  closeDialog(id: string) {
    this.genericUtil.closeDialog(id);
    //this.gridPrepaid.loadData();
    //this.gridCollect.loadData();
  }

  populateData() {
    this.model.pfcDetails = [];

    this.map.forEach((value: FreightDetail[], key: string) => {
      // console.log(key);

      value.forEach((element) => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPayerIsPayer = element.pfcDetailPayerIsPayer;
        x.pfcDetailPayerIsValid = element.pfcDetailPayerIsValid;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

        this.model.pfcDetails.push(x);
      });
    });
  }

  test(event) {}

  changeEventDateFrom(event) {
    console.log("** changeEventDateFrom");
    console.log(event);

    this.isChangeEffectiveFrom = true;
    let e: string = event;
    if (e.replace("datepick-", "") == this.model.pfcEffectiveFrom) {
      this.isChangeEffectiveFrom = false;
    }

    if (this.isModeArchive || this.actFromCbArchive) {
      this.actFromCbArchive = false;
    } else {
      if (!this.isRetrieve && !this.isNew) {
        this.lock = false;
        this.model.pfcRecordId = "";
      }
    }

  }

  changeEventDateTo(event) {
    console.log("** changeEventDateTo");
    console.log(event);

    this.isChangeEffectiveTo = true;
    let e: string = event;
    if (e.replace("datepick-", "") == this.model.pfcEffectiveTo) {
      this.isChangeEffectiveTo = false;
    }

    console.log("this.isRetrieve : " + this.isRetrieve);
    console.log("this.isNew : " + this.isNew);
    console.log("this.isChangeEffectiveFrom : " + this.isChangeEffectiveFrom);

    if (!this.isRetrieve && !this.isChangeEffectiveFrom && !this.isNew) {
      this.dateMaintenanceEffectiveFrom2.setDisable(true);
      this.lock = true;
    }
  }

  onMultipleCtypeDialogYes(event) {
    console.log(this.indexMapCtype);

    if (this.indexMapCtype < this.mapCtype.size) {
      let ix = this.indexMapCtype + 1;
      console.log("ix : " + ix);
      this.indexMapCtype = ix;
      var ct: string;
      switch (this.mapCtype.get(ix)) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        case "STD":
          ct = "NORMAL";
          break;
        default:
          ct = "NORMAL";
          break;
      }
      this.multipleCtypeDialogText =
        "Do you want to create freight for " + ct + " Container ? ";
    } else {
      this.indexMapCtype = 0;
      this.genericUtil.closeDialog("multipleCtypeDialog");

      if (this.map.size == 0) {
        this.Check_MapSize();
        return;
      } else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }
  }

  onMultipleCtypeDialogCancel(event) {
    console.log(this.indexMapCtype);

    if (this.indexMapCtype < this.mapCtype.size) {
      this.map.delete(this.mapCtype.get(this.indexMapCtype));

      let ix = this.indexMapCtype + 1;
      console.log("ix : " + ix);
      this.indexMapCtype = ix;
      var ct: string;
      switch (this.mapCtype.get(ix)) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        case "STD":
          ct = "NORMAL";
          break;
        default:
          ct = "NORMAL";
          break;
      }
      this.multipleCtypeDialogText =
        "Do you want to create freight for " + ct + " Container ? ";
    } else {
      this.map.delete(this.mapCtype.get(this.indexMapCtype));

      this.indexMapCtype = 0;
      this.genericUtil.closeDialog("multipleCtypeDialog");

      if (this.map.size == 0) {
        this.Check_MapSize();
        return;
      } else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }
  }

  onConfirmCopyYes(event) {
    if (this.currentActiveTab == "prepaid") {
      this.onDetailCopyPrepaidProcess(this.modelDetail);
      this.genericUtil.closeDialog("confirmCopyDialog");

      this.msg = "Copy prepaid to collect successfully.";
      this.genericUtil.showDialog("messageDialog", "Confirmation", 350, 150);
    }
    if (this.currentActiveTab == "collect") {
      this.onDetailCopyCollectProcess(this.modelDetail);
      this.genericUtil.closeDialog("confirmCopyDialog");

      this.msg = "Copy collect to prepaid successfully.";
      this.genericUtil.showDialog("messageDialog", "Confirmation", 350, 150);
    }
  }

  onConfirmCopyNo(event) {
    this.genericUtil.closeDialog("confirmCopyDialog");
  }

  calculateTotal() {
    console.log("** calculate TOTAL **");
    let mpPrepaid = new Map();
    let mpCollect = new Map();
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;

      let dt: FreightDetail = mpPrepaid.get(x.pfcDetailCurrency);

      if (dt == undefined) {
        var xx: FreightDetail = new FreightDetail();
        xx.pfcDetailD20 = x.pfcDetailD20;
        xx.pfcDetailD40 = x.pfcDetailD40;
        xx.pfcDetailD45 = x.pfcDetailD45;
        xx.pfcDetailH20 = x.pfcDetailH20;
        xx.pfcDetailH40 = x.pfcDetailH40;
        xx.pfcDetailH45 = x.pfcDetailH45;

        mpPrepaid.set(x.pfcDetailCurrency, xx);
      } else {
        dt.pfcDetailD20 += x.pfcDetailD20;
        dt.pfcDetailD40 += x.pfcDetailD40;
        dt.pfcDetailD45 += x.pfcDetailD45;
        dt.pfcDetailH20 += x.pfcDetailH20;
        dt.pfcDetailH40 += x.pfcDetailH40;
        dt.pfcDetailH45 += x.pfcDetailH45;
      }
    });

    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: FreightDetail = rowNode.data;

      let dt: FreightDetail = mpCollect.get(x.pfcDetailCurrency);

      if (dt == undefined) {
        var xx: FreightDetail = new FreightDetail();
        xx.pfcDetailD20 = x.pfcDetailD20;
        xx.pfcDetailD40 = x.pfcDetailD40;
        xx.pfcDetailD45 = x.pfcDetailD45;
        xx.pfcDetailH20 = x.pfcDetailH20;
        xx.pfcDetailH40 = x.pfcDetailH40;
        xx.pfcDetailH45 = x.pfcDetailH45;

        mpCollect.set(x.pfcDetailCurrency, xx);
      } else {
        dt.pfcDetailD20 += x.pfcDetailD20;
        dt.pfcDetailD40 += x.pfcDetailD40;
        dt.pfcDetailD45 += x.pfcDetailD45;
        dt.pfcDetailH20 += x.pfcDetailH20;
        dt.pfcDetailH40 += x.pfcDetailH40;
        dt.pfcDetailH45 += x.pfcDetailH45;
      }
    });

    let totPrepaid: FreightDetailTotal[] = [];
    mpPrepaid.forEach(function (item, key, mapObj) {
      let dt: FreightDetail = item;

      var x: FreightDetailTotal = new FreightDetailTotal();
      x.pfcDetailChargeName = "TOTAL " + key;
      x.pfcDetailD20 = dt.pfcDetailD20;
      x.pfcDetailD40 = dt.pfcDetailD40;
      x.pfcDetailD45 = dt.pfcDetailD45;
      x.pfcDetailH20 = dt.pfcDetailH20;
      x.pfcDetailH40 = dt.pfcDetailH40;
      x.pfcDetailH45 = dt.pfcDetailH45;
      totPrepaid.push(x);
    });
    this.pinnedBottomRowDataPrepaid = JSON.parse(JSON.stringify(totPrepaid));

    let totCollect: FreightDetailTotal[] = [];
    mpCollect.forEach(function (item, key, mapObj) {
      let dt: FreightDetail = item;

      var x: FreightDetailTotal = new FreightDetailTotal();
      x.pfcDetailChargeName = "TOTAL " + key;
      x.pfcDetailD20 = dt.pfcDetailD20;
      x.pfcDetailD40 = dt.pfcDetailD40;
      x.pfcDetailD45 = dt.pfcDetailD45;
      x.pfcDetailH20 = dt.pfcDetailH20;
      x.pfcDetailH40 = dt.pfcDetailH40;
      x.pfcDetailH45 = dt.pfcDetailH45;
      totCollect.push(x);
    });
    this.pinnedBottomRowDataCollect = JSON.parse(JSON.stringify(totCollect));
  }

  changeShipmentType(event) {
    if (
      event["shipmentTypeCode"] != null &&
      event["shipmentTypeCode"] != "" &&
      event["shipmentTypeCode"] !== undefined
    ) {
      this.model.pfcShipmentType = event["shipmentTypeCode"].toUpperCase();
    } else {
      this.model.pfcShipmentType = "ALL";
    }
  }

  changeEventAdHocFilter(event) {
    if (event.target.checked) {
      this.model.pfcAdhocFilter = "Y";
    } else {
      this.model.pfcAdhocFilter = "N";
    }
  }

  onPermission() {
    //console.log('onPermission')
    //console.log(this.cookieService.getName().toUpperCase())
    this.actions = [];
    if (
      this.cookieService.getName().toUpperCase() == "NOVYAN" ||
      this.cookieService.getName().toUpperCase() == "MIKI" ||
      this.cookieService.getName().toUpperCase() == "CELEST" ||
      this.cookieService.getName().toUpperCase() == "CELINE" ||
      this.cookieService.getName().toUpperCase() == "SHIHERN_LIM"||
      this.cookieService.getName().toUpperCase() == "NURULJUNAIDI" ||
      this.cookieService.getName().toUpperCase() == "YUDHI"||
      this.cookieService.getName().toUpperCase() == "SITI.ZAINUDDIN"
    ) {
      this.actions.push("invalidCheckbox");
    }
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterAccountPic/findAcctPIC/[-42]"
      )
      .subscribe((resp) => {
        //console.log(resp);
        resp.json()["content"].forEach((element) => {
          if (element.userName == this.cookieService.getName().toUpperCase()) {
            this.actions.push("invalidCheckbox");
          }
        });
      });
  }

  async checkPortFreightGroup(portCode: string) {
    this.POPFreightGroupCode = await this.checkFreightGroup(
      this.cookieService.getDefaultLocationCode(),
      portCode
    ).then((res: any) => {
      if (res.content.length > 0) {
        return res.content[0].locationCode;
      }

      return portCode;
    });
  }

  // * Form Validation
  validateFieldSurcharge(event: any) {
    if (this.formValidation.regSurchargeMaster.test(event.key) === false) {
      event.preventDefault();
    }
  }

  CharOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  groupPopInMap (): Map<string, Object> {
    let popMap = new Map();
    this.map.forEach((value: FreightDetail[], key: string) => {
      value.forEach((element) => {
        if(popMap.get(element.pfcDetailPlaceOfPayment) == undefined){
          popMap.set(element.pfcDetailPlaceOfPayment,[]);
        }
        popMap.get(element.pfcDetailPlaceOfPayment).push(element);
      });
    });
    return popMap;
  }

  checkIsPodFreightGroup() {
    var isPODFreightGroup = false;
    if(this.podName.includes("FREIGHT GROUP")){
      console.log(this.freightGroupMember);
      isPODFreightGroup = true;
    }

    return isPODFreightGroup;
  }

  checkIsChargeMissingPayer(popMap, isPODFreightGroup, typeCheck) {
    var result = false;
    let chargeMissingPayer = new Map();
    let msgPayerError = new Map();
    let resultBack = new Map();
    // let groupInvalidPayer = [];
    popMap.forEach((value:FreightDetail[], key:string)=>{
      console.log("POP : "+key+"");
      console.log("isPODFreightGroup : "+isPODFreightGroup+"");
      console.log("this.freightGroupMember");
      console.log(this.freightGroupMember);
      console.log("POD FreightGroup typeCheck : "+typeCheck+"");
      console.log(value);
      if(isPODFreightGroup){
        if(this.freightGroupMember.includes(key)){
          // payer can be blank
        } else {
          // payer can not be blank
          console.log("POD FreightGroup typeCheck : "+typeCheck+"");
          console.log(value);
          value.forEach((fD)=>{

            if (typeCheck == "blankPayer") {
              if(fD.pfcDetailPayer == ""){
                if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                  chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
                }else{
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
                }
                //this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for "+fD.pfcDetailChargeCode+fD.pfcDetailPC;
                result = true;
              }
            } else if (typeCheck == "invalidPayer") {
              if (fD.pfcDetailPayerIsPayer == "N" && this.groupAllowInvalidPayer.includes(fD.pfcDetailPayer)) {
                if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                  chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                }else{
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                }
                result = true;
              }
            } else if (typeCheck == "allInvalidPayer") {
              if (this.clickSurcharge == true && this.model.pfcEffectiveTo == this.apiEffectiveTo) {
                if (fD.pfcDetailPayerIsPayer == "N" && !this.listPayerExisting.includes(fD.pfcDetailPayer)) {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to update.");
                  }

                  result = true;
                } else if (fD.pfcDetailPayerIsValid == "N" && !this.listPayerExisting.includes(fD.pfcDetailPayer)) {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to update.");
                  }
                  result = true;
                }
              } else {
                if (fD.pfcDetailPayerIsPayer == "N") {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to extend.");
                  }

                  result = true;
                } else if (fD.pfcDetailPayerIsValid == "N") {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to extend.");
                  }
                  result = true;
                }
              }
            }

          });
        }
      } else {
        if(this.model.pfcDischargePortCode == key){
          // payer can be blank
        } else {
          // payer can not be blank
          console.log("else typeCheck : "+typeCheck+"");
          console.log(value);
          value.forEach((fD)=>{
            console.log("else typeCheck : "+typeCheck+"");
            if (typeCheck == "blankPayer") {
              if(fD.pfcDetailPayer == ""){
                console.log("hei hei");
                if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                  chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
                }else{
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode);
                }
                //this.model["error-blankPayerValidator"] = "Unable to save due to missing payer for "+fD.pfcDetailChargeCode+fD.pfcDetailPC;
                result = true;
              }
            } else if (typeCheck == "invalidPayer") {
              if (fD.pfcDetailPayerIsPayer == "N" && this.groupAllowInvalidPayer.includes(fD.pfcDetailPayer)) {
                if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                  chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                }else{
                  chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                }
                result = true;
              }
            } else if (typeCheck == "allInvalidPayer") {
              if (this.clickSurcharge == true && this.model.pfcEffectiveTo == this.apiEffectiveTo) {
                if (fD.pfcDetailPayerIsPayer == "N" && !this.listPayerExisting.includes(fD.pfcDetailPayer)) {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to update.");
                  }

                  result = true;
                } else if (fD.pfcDetailPayerIsValid == "N" && !this.listPayerExisting.includes(fD.pfcDetailPayer)) {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to update.");
                  }
                  result = true;
                }
              } else {
                if (fD.pfcDetailPayerIsPayer == "N") {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to extend.");
                  }

                  result = true;
                } else if (fD.pfcDetailPayerIsValid == "N") {
                  if(chargeMissingPayer.get(fD.pfcDetailPCTab) == undefined){
                    chargeMissingPayer.set(fD.pfcDetailPCTab, []);
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  } else {
                    chargeMissingPayer.get(fD.pfcDetailPCTab).push(fD.pfcDetailChargeCode+". "+fD.pfcDetailPayerName+" is invalid payer");
                  }

                  if (msgPayerError.get(fD.pfcDetailPayerName) == undefined) {
                      msgPayerError.set(fD.pfcDetailPayerName, fD.pfcDetailPayerName+" is not flag as Payer, unable to extend.");
                  }
                  result = true;
                }
              }

            }
          });
        }
      }
    });


    resultBack.set('result', result);
    resultBack.set('chargeMissingPayer', chargeMissingPayer);
    resultBack.set('msgPayerError', msgPayerError);
    return resultBack;
  }

  checkInvalidPayerValidator(): Boolean {
    console.log("** check invalid Payer for prepaid **");
    let result: Boolean = false;
    // console.log(result);
    console.log(this.apiEffectiveTo);
    console.log(this.model.pfcEffectiveTo);
    if (this.apiEffectiveTo != "") {
      console.log(this.model.pfcEffectiveTo != this.apiEffectiveTo);
      if (this.model.pfcEffectiveTo != this.apiEffectiveTo) {
        let popMap = this.groupPopInMap();
        var isPODFreightGroup = this.checkIsPodFreightGroup();
        var checkIsChargeMissingPayer = this.checkIsChargeMissingPayer(popMap, isPODFreightGroup, "invalidPayer");
        let chargeMissingPayer = checkIsChargeMissingPayer.get('chargeMissingPayer');
        var results = checkIsChargeMissingPayer.get('result');
        console.log(checkIsChargeMissingPayer);
        if(results){
          var chargeText = "";
          console.log(chargeMissingPayer)
          if(chargeMissingPayer.size > 0){
            if(chargeMissingPayer.size == 1){
              chargeMissingPayer.forEach((value:any[], key:string)=>{
                if(key==="P"){
                  chargeText += "[Prepaid Tab] "
                }else{
                  chargeText += "[Collect Tab] "
                }
                chargeText += value;

              })

            }else{
              chargeMissingPayer.forEach((value:any[], key:string)=>{
                console.log(key)
                console.log(value)

                if(key==="P"){
                  chargeText += "[Prepaid Tab] "
                }else{
                  chargeText += "[Collect Tab] "
                }

                chargeText += value + "<br> ";

              })
            }
            this.model["error-invalidPayerValidator"] = "Please select a valid payer. <br>"+chargeText;
            result = true;
          }
        }
      }
    }
    return result;
  }

  checkAllInvalidPayer(): Boolean {
    let result: Boolean = false;
    let res: Boolean = false;
    console.log(this.apiEffectiveTo);
    console.log(this.model.pfcEffectiveTo);
    if (this.apiEffectiveTo != "") {
      console.log(this.model.pfcEffectiveTo != this.apiEffectiveTo);
      if (this.model.pfcEffectiveTo != this.apiEffectiveTo) {
          res = this.mainCheckInvalidPayer(true);
          result = res;

          if (this.isFreightPartyValidAsRecordLevel == 'N') {
            this.model["error-pfcFreightPartyIdInvalid"] = "Record is not flag as a Freight Party, unable to extend";
          } else if (this.isFreightPartyValidBasedOnCustomerType == 'N') {
            this.model["error-pfcFreightPartyIdInvalid"] = "Freight Party is invalid, unable to extend";
          }

          if (this.searchShIsValid == 'N') {
            this.model["error-pfcShipperIdInvalid"] = "Record is not flag as a Shipper, unable to extend";
          } else if (this.searchShIsShipper == 'N') {
            this.model["error-pfcShipperIdInvalid"] = "Shipper is invalid, unable to extend";
          }

          if (this.searchBkIsValid == 'N') {
            this.model["error-pfcBookingPartyIdInvalid"] = "Record is not flag as a Booking Party, unable to extend";
          } else if (this.searchBkIsBookingParty == 'N') {
            this.model["error-pfcBookingPartyIdInvalid"] = "Booking Party is invalid, unable to extend";
          }

          // if (this.model["error-allInvalidPayerValidator"].length > 0) {
          //   this.model["error-allInvalidPayerValidator"] = "Cannot extend Expiry On. "+this.model["error-allInvalidPayerValidator"];
          // }

          // if (this.model["error-pfcFreightPartyIdInvalid"].length > 0) {
          //   this.model["error-pfcFreightPartyIdInvalid"] = "Cannot extend Expiry On. "+this.model["error-pfcFreightPartyIdInvalid"];
          // }

      } else if (this.clickSurcharge) {
        res = this.mainCheckInvalidPayer(true);
        result = res;
      }
    } else if (this.clickSurcharge) {
      res = this.mainCheckInvalidPayer(true);
      result = res;
    }
    return result;
  }

  mainCheckInvalidPayer(isExt): boolean {
    var result = false;

    let popMap = this.groupPopInMap();
    var isPODFreightGroup = this.checkIsPodFreightGroup();
    var checkIsChargeMissingPayer = this.checkIsChargeMissingPayer(popMap, isPODFreightGroup, "allInvalidPayer");
    let chargeMissingPayer = checkIsChargeMissingPayer.get('chargeMissingPayer');
    let msgPayerError = checkIsChargeMissingPayer.get('msgPayerError');
    var results = checkIsChargeMissingPayer.get('result');
    console.log("checkIsChargeMissingPayer");
    console.log(checkIsChargeMissingPayer);
    if(results){
      var chargeText = "";
      console.log(chargeMissingPayer)
      if (isExt == true) {
        if(chargeMissingPayer.size > 0){
          msgPayerError.forEach((value:string, key:string)=>{
            console.log(key);
            console.log(value);
            chargeText += value + "<br> ";
          });
          result = true;
          this.model["error-allInvalidPayerValidator"] = chargeText;
        }
      } else {
        if(chargeMissingPayer.size > 0){
          if(chargeMissingPayer.size == 1){
            chargeMissingPayer.forEach((value:any[], key:string)=>{
              if(key==="P"){
                chargeText += "[Prepaid Tab] "
              }else{
                chargeText += "[Collect Tab] "
              }

              var chargeDetailText = "";
              for (var x=0;x<value.length; x++) {
                if (x > 0) {
                  chargeDetailText += ", ";
                }

                chargeDetailText += value[x];
              }

              chargeText += chargeDetailText;

            })

          }else{
            chargeMissingPayer.forEach((value:any[], key:string)=>{
              console.log(key)
              console.log(value)

              if(key==="P"){
                chargeText += "[Prepaid Tab] "
              }else{
                chargeText += "[Collect Tab] "
              }

              var chargeDetailText = "";
              for (var x=0;x<value.length; x++) {
                if (x > 0) {
                  chargeDetailText += ", ";
                }

                chargeDetailText += value[x];
              }

              chargeText += chargeDetailText + "<br> ";

            })
          }

          result = true;
          this.model["error-allInvalidPayerValidator"] = "Please select a valid payer. <br>"+chargeText;
        }
      }

      var self = this;
          //ininih
      if (result == true) {
        this.gridApiPrepaid.forEachNode(function (node) {
          console.log(node.rowIndex);
          console.log(node.data);

          if (self.clickSurcharge == true && self.model.pfcEffectiveTo == self.apiEffectiveTo) {
            if ((node.data.pfcDetailPayerIsPayer == "N" || node.data.pfcDetailPayerIsValid == "N") && !self.listPayerExisting.includes(node.data.pfcDetailPayer)) {
              $("#gridPrepaid2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    $(this).css('cssText', function(i, v) {
                      return this.style.cssText + ';background: lightcoral !important;';
                    });
                }

              });
            } else {
              $("#gridPrepaid2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    var styleText= this.style.cssText;
                    var res = styleText.replace('background: lightcoral !important;','');
                    $(this).css('cssText', function(i, v) {
                      return res;
                    });
                }
              });
            }
          } else {
            if (node.data.pfcDetailPayerIsPayer == "N" || node.data.pfcDetailPayerIsValid == "N") {
              $("#gridPrepaid2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    $(this).css('cssText', function(i, v) {
                      return this.style.cssText + ';background: lightcoral !important;';
                    });
                }

              });
            } else {
              $("#gridPrepaid2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    var styleText= this.style.cssText;
                    var res = styleText.replace('background: lightcoral !important;','');
                    $(this).css('cssText', function(i, v) {
                      return res;
                    });
                }
              });
            }
          }
        });


        this.gridApiCollect.forEachNode(function (node) {
          console.log(node.rowIndex);
          console.log(node.data);

          if (self.clickSurcharge == true && self.model.pfcEffectiveTo == self.apiEffectiveTo) {
            if ((node.data.pfcDetailPayerIsPayer == "N" || node.data.pfcDetailPayerIsValid == "N") && !self.listPayerExisting.includes(node.data.pfcDetailPayer)) {
              $("#gridCollect2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    $(this).css('cssText', function(i, v) {
                      return this.style.cssText + ';background: lightcoral !important;';
                    });
                }

              });
            } else {
              $("#gridCollect2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    var styleText= this.style.cssText;
                    var res = styleText.replace('background: lightcoral !important;','');
                    $(this).css('cssText', function(i, v) {
                      return res;
                    });
                }
              });
            }
          } else {
            if (node.data.pfcDetailPayerIsPayer == "N" || node.data.pfcDetailPayerIsValid == "N") {
              $("#gridCollect2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    $(this).css('cssText', function(i, v) {
                      return this.style.cssText + ';background: lightcoral !important;';
                    });
                }

              });
            } else {
              $("#gridCollect2").find('.ag-row').each(function (index, value) {
                var rowIDX = $(this).attr('row-index');
                if (rowIDX == node.rowIndex) {
                    var styleText= this.style.cssText;
                    var res = styleText.replace('background: lightcoral !important;','');
                    $(this).css('cssText', function(i, v) {
                      return res;
                    });
                }
              });
            }
          }
        });
      }
    }
    return result;
  }

  createArchivePfc() {
    // this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCContractRates/createArchive",
        this.mainArchiveRetrieve
      )
      .subscribe(
        (resp) => {
          // let data = resp.json();
          this.responseArchive = resp.json();
          console.log(this.responseArchive);
          this.responseArchive.pfcUserCreated = this.cookieService.getName();
          var dateNow = new Date();
          var yearNow = dateNow.toLocaleString('default', {year: 'numeric'});
          var dayNow = dateNow.toLocaleString('default', {day: '2-digit'})
          var monthNow = dateNow.toLocaleString('default', {month: '2-digit'});
          var timeNow = dateNow.toLocaleTimeString('en-US', { hour12: false });
          var dateCurr = [yearNow, monthNow, dayNow].join('-')+" "+timeNow;
          this.responseArchive.pfcDateCreated = dateCurr;
          // this.genericUtil.hideLoader();

          // this.message(
          //   "information",
          //   "Information",
          //   "Data with Reference No " +
          //     this.model.pfcRecordId + " has been prepared to archive",
          //   "okonly",
          //   { ok: "this.loading=false;" }
          // );
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  saveArchivePfc() {
    // this.genericUtil.showLoader();

    if (Object.keys(this.responseArchive).length !== 0 && this.responseArchive.constructor === Object) {
      this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/PFCContractRates/saveArchive",
        this.responseArchive
      )
      .subscribe(
        (resp) => {
          let data = resp.json();
          // this.responseArchive = resp.json();
          console.log(data);
          this.mainArchiveRetrieve = this.model;
          this.getListArchive(this.model.pfcRecordId, true);

          // this.message(
          //   "information",
          //   "Information",
          //   data.message,
          //   "okonly",
          //   { ok: "this.loading=false;" }
          // );
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
    }

  }

  getListArchive(pfcRecordId, afterSave) {
    this.genericService.GET(this.configService.config.BASE_API.toString() +
    "/PFCGenericAPI/findArchiveVersion/"+pfcRecordId).subscribe((resp) => {
      if (resp.ok) {
        let content = resp.json()["content"];
        // this.responseArchive = resp.json();
        console.log("findArchive");
        console.log(content);
        console.log("afterSave: "+afterSave);
        if (content.length > 0) {
          this.cbArchivePfc.setData(JSON.stringify(content));
          if (afterSave) {
            var str1 = content.length.toString();
            console.log("str1: "+str1);
            this.model.pfcRevision = str1.padStart(3, '0');
            this.revisionPfc = this.model.pfcRevision;
            console.log("this.model.pfcRevision: "+this.model.pfcRevision);
          }
        } else {
          this.cbArchivePfc.setData(JSON.stringify(content));
          this.cbArchivePfc.setValue("");
        }
      }
    });
  }

  showSelectedArchive (versionSelected) {
    this.genericUtil.showLoader();
    let dt = this.mainGrid.getSelectedValues()[0];
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/PFCFreightApplication/retrieveArchiveFreightApplication/" +
          versionSelected+
          "/" +
          dt.pfcCType
      )
      .subscribe((resp) => {
        //// console.log(resp);

        var x: FreightManagement = new FreightManagement();
        x = resp.json()["content"];
        // console.log(x);
        this.genericUtil.hideLoader();
        this.isModeArchive = true;
        this.handleDoubleClick(x);
        this.whenArchiveChoose();
      });
  }

  closeArchive() {
    this.genericUtil.showLoader();
    let dt = this.mainGrid.getSelectedValues()[0];
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/PFCFreightApplication/retrieveFreightApplicationForUpdate/" +
        dt.pfcRecordId.trim() +
        "/" +
        dt.pfcCType
      )
      .subscribe((resp) => {
        //// console.log(resp);

        var x: FreightManagement = new FreightManagement();
        x = resp.json()["content"];
        // console.log(x);
        this.genericUtil.hideLoader();
        this.isModeArchive = false;
        this.actFromCbArchive = true;
        this.handleDoubleClick(x);
      });
  }

  whenArchiveChoose() {
    this.disableToolbarButtons = "nct,save,copy,update,delete,create";

    this.lock = true;
  }

  validateAfterSaveNew() {
    console.log("validateAfterSaveNew");
    this.isError = this.mainCheckInvalidPayer(false);

    if (this.isFreightPartyValidBasedOnCustomerType == 'N') {
      this.model["error-pfcFreightPartyIdInvalid"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
      this.isError = true;
    } else if (this.isFreightPartyValidAsRecordLevel == 'N') {
      this.model["error-pfcFreightPartyIdInvalid"] = "Freight Party is invalid, unable to proceed with creation";
      this.isError = true;
    }

    if (this.searchShIsValid == 'N') {
      this.model["error-pfcShipperIdInvalid"] = "Shipper is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.searchShIsShipper == 'N') {
      this.model["error-pfcShipperIdInvalid"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
      this.isError = true;
    }

    if (this.searchBkIsValid == 'N') {
      this.model["error-pfcBookingPartyIdInvalid"] = "Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.searchBkIsBookingParty == 'N') {
      this.model["error-pfcBookingPartyIdInvalid"] = "Customer record is no longer set as a Booking Party, unable to proceed with creation";
      this.isError = true;
    }

    if (this.isError) {
      window.scrollTo(0, 0);
      this.genericUtil.hideLoader();
    } else {
      this.ClearRecordId();this.UpdateMap();
    }

  }

  // validateUpdate() {
  //   this.isError = this.mainCheckInvalidPayer();
  //   console.log(this.isError);
  //   if (this.isError) {
  //     window.scrollTo(0, 0);
  //     this.genericUtil.hideLoader();
  //   } else {
  //     this.UpdateMap();
  //   }
  // }

  dateToTime(date) {
    var d = new Date(date);
    return d.getTime();
  }
}
