<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
    <div id='container'>
        <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <!--Error Message Block-->
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
         <li [class.hidden]="model['error-lChargeName'] == null || model['error-lChargeName'] == ''">
          {{model['error-lChargeName']}}
        </li>
         <li [class.hidden]="model['error-lChargeCode'] == null || model['error-lChargeCode'] == ''">
          {{model['error-lChargeCode']}}
        </li>
        <li [class.hidden]="model['error-isGstorZeroRated'] == null || model['error-isGstorZeroRated'] == ''">
          {{model['error-isGstorZeroRated']}}
        </li>
      </ul>
    </div>
    <!--End Error Message Block-->
    </div>
    <div [style.display]="modeForm?'none':'block'">
      <!-- Begin of Grid -->
      <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Charge Code Master</h4>
      <grid-plugin #localChargeGrid [settings]="settingGrid" (gridEvent)="localChargeGridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      <!-- End of Grid -->
    </div>
    <div [style.display]="modeForm?'block':'none'">
      <div class="ui segment" style="padding-left:1%;padding-right:20%;">
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id='container'>
          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == ''?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Charge Code Master</h4>

            <div class="field">
              <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                <label>Valid?</label>
              </div>
            </div>

            <div class="ui styled accordion field" style="width:100%;height: 50%;">
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">

                <div class="two fields">
                  <div class="required field" [class.error]="model['error-lChargeName'] != null && model['error-lChargeName'] != ''">
                    <label>Charge Name</label>
                    <combo-plugin #cbCharge [settings]="settingCharge" (change)="valueCharge($event)" ></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-lChargeName'] != null && model['error-lChargeName'] != ''">
                      {{model['error-lChargeName']}}
                    </div>
                  </div>

                  <div class="required field" [class.error]="model['error-lChargeCode'] != null && model['error-lChargeCode'] != ''">
                    <label>Charge Code</label>
                    <input type="text" [value]="valChargeCode"  name="chargeCode"  placeholder="Charge Code" [disabled]="lock == true" maxlength="10" readonly style="background-color: lightgray">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-lChargeCode'] != null && model['error-lChargeCode'] != ''">
                      {{model['error-lChargeCode']}}
                    </div>
                  </div>
                </div>

                  <div class="field">
                    <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                      <input type="checkbox" [(ngModel)]="model['isGst']" name="isGst"  (change)="isGSTChange($event)" tabindex="0" class="hidden">
                      <label>GST</label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                      <input type="checkbox" [(ngModel)]="model['isZeroRated']"  (change)="isZeroRatedChange($event)" name="isZeroRated" tabindex="0" class="hidden">
                      <label>Zero-Rated</label>
                    </div>
                  </div>


                <div class="field">
                  <label>Remarks</label>
                  <textarea [(ngModel)]="model['remarks']" name="remarks" placeholder="Input your remarks..." [disabled]="lock == true" style="text-transform: uppercase"></textarea>
                </div>

              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" readonly style="background-color: lightgray">
                </div>
              </div>
            </div>

          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Local Master Charge</h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Charge</a>

              <div class="active content">
                <div class="one fields" style="margin-top:10px;">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                    <button class="ui button" (click)="generateChargeHistoryReport($event)">Export</button>
                  </div>
                </div>

                <div class="two fields">
                  <div class="required field" [class.error]="model['error-chargeName'] != null && model['error-chargeName'] != ''">
                    <label>Charge Name</label>
                    <combo-plugin #cbLocalChargeNameHistory [settings]="settingLocalChargeHistory"  (change)="valueLocalChargeHistory($event)" ></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-chargeName'] != null && model['error-chargeName'] != ''">
                      {{model['error-chargeName']}}
                    </div>
                  </div>

                  <div class="required field" [class.error]="model['error-chargeCode'] != null && model['error-chargeCode'] != ''">
                    <label>Charge Code</label>
                    <input type="text" [value]="valChargeCodeHistory" name="chargeHistoryCode"  placeholder="Charge Code" [disabled]="lock == true" maxlength="10" readonly style="background-color: lightgray">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-chargeCode'] != null && model['error-chargeCode'] != ''">
                      {{model['error-chargeCode']}}
                    </div>
                  </div>
                </div>
<!--
                <div class="two fields">
                  <div class="field">
                    <label>Log Start Date</label>
                    <calendar-plugin id="logHistoryChargeStartDate" [pluginType]="typeChargeLogStartDate" [settings]="settingChargeLogStartDate"></calendar-plugin>
                  </div>
                  <div class="field">
                    <label>Log End Date</label>
                    <calendar-plugin id="logHistoryChargeEndDate" [pluginType]="typeChargeLogEndDate" [settings]="settingChargeLogEndDate"></calendar-plugin>
                  </div>
                </div>
                -->
                <div class="field">
                  <grid-plugin2 #localChargeGridHistory [settings]="settingGridHistory" (gridEvent)="localChargeGridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin2>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>