<div class="ui raised segment" style="height:700">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
        <li [class.hidden]="model['error-vesselId'] == null || model['error-vesselId'] == ''">
            {{model['error-vesselId']}}
        </li>
        <li [class.hidden]="model['error-voyageCode'] == null || model['error-voyageCode'] == ''">
            {{model['error-voyageCode']}}
        </li>
        <li [class.hidden]="model['error-varBound'] == null || model['error-varBound'] == ''">
            {{model['error-varBound']}}
        </li>
        <li [class.hidden]="model['error-varReason'] == null || model['error-varReason'] == ''">
            {{model['error-varReason']}}
        </li>
    </ul>
</div>


 <form class="ui form">
    <fieldset style="border: 1px solid lightgray;height:700px">
      <legend style="font-weight:bold;color:dodgerblue;">Reinstate BL Selection</legend>
      <div class="field">
        <!--
        <div class="two fields">

            <div class="field">
                <div class="field">
                  <div class="ui radio checkbox" >
                    <input type="checkbox"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chVessel')" [checked]="isUnlock == 'chVessel'">
                    <label>By Vessel/Voyage</label>
                  </div>
                </div>
                  <br>
                  <div class="field" style="height:400px">
                    <fieldset style="border: 1px solid lightgray;height:500px">


                        <div class="field">

                          <div class="field">

                            <label>Vessel </label>
                            <div class="field"  [class.error]="model['error-vesselId'] != null && model['error-vesselId'] != ''"></div>
                            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event);model['error-vesselId']=''" style="width:100%;" ></combo-plugin>

                          </div>
                          <br>
                          <div class="field" >
                              <div class="field"  [class.error]="model['error-voyageCode'] != null && model['error-voyageCode'] != ''"></div>
                            <label>Voyage</label>
                              <combo-plugin #cbVoyage [settings]="settingVoyage"  (change)="changeEventVoyage($event);model['error-voyageCode']=''" style="width:100%;"></combo-plugin>

                          </div>
                          <br>
                          <div class="field">
                              <div class="field"  [class.error]="model['error-varBound'] != null && model['error-varBound'] != ''"></div>
                            <label>Bound</label>
                              <input #bound type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['varBound']" (ngModelChange)="model['error-varBound']=''" [disabled]="lockBound == true" >

                          </div>
                          <br>
                          <div class="field">
                              <div class="required field"  [class.error]="model['error-varReason'] != null && model['error-varReason'] != '' && model['error-varReason'] != undefined"></div>
                            <label>Reason</label>
                              <textarea #reason id="reason" name="reason" rows="4" cols="50" placeholder="Reason" [(ngModel)]="model['varReason']" (ngModelChange)="model['error-varReason']='';changeEventReason($event)" [disabled]="lockReason == true" ></textarea>

                          </div>
                          <br>
                      </div>
                    </fieldset>
                </div>
            </div>
          -->
          <div class="field">
            <div class="field">
                <div class="field">
                  <!--div class="ui radio checkbox" >
                    <input type="checkbox" [checked]="true"  name="isUnlock" tabindex="0" class="hidden" (change)="changeType('chBooking')" [checked]="isUnlock == 'chBooking'" -->
                    <label>By BL Reference</label>
                  <!--/div-->
                </div>
                <br>

                <div class="field">
                  <fieldset style="border: 1px solid lightgray;width:98%;height:500px">
                      <button class="tiny ui positive button"  (click) = "addNewRow();" >+</button>
                      <button class="tiny ui negative button"  (click) = "removeRow();">-</button>
                      <br/>
                      <input type="hidden" name="gridCount" value="0">
                      <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                  </fieldset>
                </div>

            </div>
          </div>

        <!--/div-->
      </div>
    </fieldset>
 </form>
</div>
<footer-plugin [info]="info"></footer-plugin>
