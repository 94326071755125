import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  DialogPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { BLHeader, specialCharUtil } from "../shared/index";
declare var $: any;

@Component({
  selector: "app-report-finance-actual-freight-bill-page",
  templateUrl: "./report-finance-actual-freight-bill-page.component.html",
  styleUrls: ["./report-finance-actual-freight-bill-page.component.css"],
})
export class ReportFinanceActualFreightBillPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbDelivery") cbDelivery: ComboPluginComponent;
  @ViewChild("cbReceipt") cbReceipt: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbTranshipmentPort") cbTranshipmentPort: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("cbOPCode") cbOPCode: ComboPluginComponent;
  @ViewChild("cbSOOPR") cbSOOPR: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridPDFPrev") gridPDFPrev: GridPluginComponent;
  @ViewChild("dialogPlugin") dialogPlugin: DialogPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingDelivery;
  settingReceipt;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingLoadPort;
  settingDischargePort;
  settingTranshipmentPort;
  settingGrid;
  settingOPCode;
  settingSOOPR;
  settingGridPDFPrev;
  settingPDF;
  newDialogOpt;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";
  addRemoveButton = "";
  containerParam = "";

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  userId: string = "";
  userEmail: string = "";
  soOprCode: string = "";
  blOpCode: string = "";
  officeId: string = "";
  printCopy = 1;

  // lock
  cButtonLock = true;
  dButtonLock = true;
  isError: Boolean = false;
  lockOPCode: Boolean = true;
  lockSOOPR: Boolean = true;

  sCharUtil = new specialCharUtil();
  selection: string;
  model = new BLHeader();
  validatorRules = {};
  jasperPath = [];
  dataBLArr = [];
  dataInvoice = [];

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "OK", event: "ok", icon: "print" },
        { name: "OK2", event: "ok2", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Actual Invoice Report",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingReceipt = {
      id: "cbReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "RECEIPT TYPE",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Code", field: "receiptTypeCode", width: 100 },
        { header: "Receipt Type Name", field: "receiptTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingDelivery = {
      id: "cbDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "DELIVERY TYPE",
      title: "deliveryTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Code", field: "deliveryTypeCode", width: 100 },
        { header: "Receipt Type Name", field: "deliveryTypeName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDiscPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingTranshipmentPort = {
      id: "cbTranshipmentPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        // {header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingSOOPR = {
      id: "cbSOOPR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 2,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingOPCode = {
      id: "cbOPCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 2,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGridPDFPrev = {
      id: "gridPDFPrev",
      url: "", //this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: "10",
      columns: [
        {
          header: "Invoice No",
          field: "invoiceNo",
          width: 250,
          editType: "text",
        },
      ],
      buttons: [],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: false,
      height: 500,
      minHeight: 500,
      autoSize: "auto",
    };

    this.settingGrid = {
      id: "grid",
      url: "",
      page: 10,
      columns: [
        { header: "Invoice No.", field: "blNo", width: 180, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      editable: true,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
      tabOptio: false,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    $(".dataTables_scrollBody").css("height", "395px");
    this.defaultBehave();
    this.cbReceipt.setValue("CY");
    this.cbDelivery.setValue("CY");
    this.getJasperPath();
    this.userId = this.cookieService.getName();
    this.userEmail = localStorage.getItem("logged").split("|")[1];
    $("#silentMode").prop("checked", true);
    this.officeId =
      this.cookieService.getDefaultLocationId() == null
        ? "*"
        : this.cookieService.getDefaultLocationId();
  }
  getJasperPath() {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterJasperReport/getPath/" +
      this.officeCode +
      "/BLDraftNS";
    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.json()["message"] == "ok") {
        var rList = resp.json()["data"];

        rList.forEach((rL) => {
          this.jasperPath.push(rL);
        });

        console.log(this.jasperPath);
      }
    });
  }
  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "ok":
        if (this.grid.listStore.store.length > 0) {
          //this.printInvoice();
          this.printInvoice2();
        } else {
          if (this.selection == "BL") {
            this.dialogPlugin.show(
              "information",
              "Information",
              "Please insert bl to print",
              "okonly",
              { ok: "" }
            );
          } else {
            this.dialogPlugin.show(
              "information",
              "Information",
              "Please retrieve data.",
              "okonly",
              { ok: "" }
            );
          }
        }
        break;
      case "ok2":
        if (this.grid.listStore.store.length > 0) {
          if ($("#silentMode").is(":checked")) {
            this.loading = true;
            this.disableToolbarButtons = "ok,ok2,cancel,close";
            //var hitUrl = "http://localhost:16000/ContainerLoadList/UserSpooler/check";
            var hitUrl =
              this.configService.config.BASE_API.toString() +
              "/ContainerLoadList/UserSpooler/check";
            var pData = {};
            pData["userEmail"] = this.userEmail;
            pData["userLocationId"] = this.officeId;
            pData["printerList"] = ["ACTUALINVOICE"];

            this.genericService.POST(hitUrl, pData).subscribe(
              (resp) => {
                if (resp.ok) {
                  if (resp.json()["message"] == "ok") {
                    this.printInvoice();
                    //this.printBLOriginalReport();
                  } else {
                    this.loading = false;
                    this.disableToolbarButtons = "";
                    this.dialogPlugin.show(
                      "information",
                      "Information",
                      "Please Ask Administrator to Register Printer Spooler For Your User Id",
                      "okonly",
                      { ok: "" }
                    );
                  }
                }
              },
              (error) => {
                this.loading = false;
                this.disableToolbarButtons = "";
                this.dialogPlugin.show(
                  "information",
                  "Information",
                  "Error ocurred when trying to print the document, please contact your administrator!",
                  "okonly",
                  { ok: "" }
                );
              }
            );
          } else {
            this.printInvoice();
          }
        } else {
          if (this.selection == "BL") {
            this.dialogPlugin.show(
              "information",
              "Information",
              "Please insert bl to print",
              "okonly",
              { ok: "" }
            );
          } else {
            this.dialogPlugin.show(
              "information",
              "Information",
              "Please retrieve data.",
              "okonly",
              { ok: "" }
            );
          }
        }
        break;
      case "cancel":
        this.defaultBehave();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }
  setValidatorVessel() {
    this.clearValidatorError();

    console.log("eaaaaa apakah " + this.model["error-blOceanVesselBound"]);
    //console.log()
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  setValidatorLoadPort() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Load Port.",
          },
        ],
      },
    };
  }
  setValidatorTranshipmentPort() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blPortOfTransshipment1Code: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Transhipment Port.",
          },
        ],
      },
    };
  }
  setValidatorDischargePort() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Discharge Port.",
          },
        ],
      },
    };
  }

  setValidatorSOOPR() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blSOOperatorCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input SO Operator.",
          },
        ],
      },
    };
  }

  setValidatorOPCode() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      blOperatorCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input OP Code.",
          },
        ],
      },
    };
  }

  changeEventVessel(event) {
    console.log(event);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + event.vesselId + "/" + this.model.blOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          "{query}/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventPrintBy(event) {
    // if(event.target.value == 'FAI'){
    this.dataBLArr = [];
    this.dataInvoice = [];
    this.grid.onClear();
    //}else{

    //}
  }
  changeEventCriteria(event) {
    if (event.target.value == "VESSEL") {
      this.addRemoveButton = "none";
      this.dataBLArr = [];
      this.dataInvoice = [];
      this.clearValidatorError();
      this.isError = false;
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;

      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "VESSEL";
      this.grid.onClear();
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      this.cbDischargePort.setValue("");
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      this.model.blSOOperatorCode = "";
      this.model.blOperatorCode = "";
      this.cbLoadPort.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.lockOPCode = true;
      this.lockSOOPR = true;
      this.model = new BLHeader();
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.model.blOceanVesselBound = "O";
      this.blOpCode = "";
      this.soOprCode = "";
    } else if (event.target.value == "POL") {
      this.addRemoveButton = "none";
      this.isError = false;
      this.clearValidatorError();
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;
      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "POL";
      this.grid.onClear();
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      //this.cbSOOPR.disableCombo=true;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = false;
      // this.cbOPCode.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbDischargePort.setValue("");
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      this.model.blSOOperatorCode = "";
      this.model.blOperatorCode = "";
      this.lockOPCode = true;
      this.lockSOOPR = true;
      this.model = new BLHeader();
      this.model.blOceanVesselBound = "O";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.blOpCode = "";
      this.soOprCode = "";
    } else if (event.target.value == "POD") {
      this.addRemoveButton = "none";
      this.isError = false;
      this.clearValidatorError();
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;
      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "POD";
      this.grid.onClear();
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      // this.cbSOOPR.disableCombo=true;
      this.cbDischargePort.disableCombo = false;
      this.cbLoadPort.disableCombo = true;
      //this.cbOPCode.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      // this.cbDischargePort.setValue('');
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      this.model.blSOOperatorCode = "";
      this.model.blOperatorCode = "";
      this.lockOPCode = true;
      this.lockSOOPR = true;
      this.model = new BLHeader();
      this.model.blOceanVesselBound = "O";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.blOpCode = "";
      this.soOprCode = "";
    } else if (event.target.value == "POT") {
      this.addRemoveButton = "none";
      this.isError = false;
      this.clearValidatorError();
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;
      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "POT";
      this.grid.onClear();
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      // this.cbSOOPR.disableCombo=true;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      //  this.cbOPCode.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = false;
      this.cbDischargePort.setValue("");
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      this.model.blSOOperatorCode = "";
      this.model.blOperatorCode = "";
      this.lockOPCode = true;
      this.lockSOOPR = true;
      this.model = new BLHeader();
      this.model.blOceanVesselBound = "O";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.blOpCode = "";
      this.soOprCode = "";
    } else if (event.target.value == "OPCODE") {
      this.addRemoveButton = "none";
      this.isError = false;
      this.clearValidatorError();
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;
      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "OPCODE";
      this.grid.onClear();
      this.isError = false;
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      //  this.cbSOOPR.disableCombo=true;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      // this.cbOPCode.disableCombo = false;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbDischargePort.setValue("");
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      this.model.blSOOperatorCode = "";
      this.model.blOperatorCode = "";
      this.lockOPCode = false;
      this.lockSOOPR = true;
      this.model = new BLHeader();
      this.model.blOceanVesselBound = "O";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.blOpCode = "";
      this.soOprCode = "";
    } else if (event.target.value == "SOOPR") {
      this.addRemoveButton = "none";
      this.clearValidatorError();
      this.isError = false;
      $("#myContainer").attr("disabled", false);
      $("#fieldSortBy").attr("disabled", false);
      $("#idBound").attr("disabled", false);
      $("#rdinvoiceno").attr("checked", true);
      $("#rdpol").attr("disabled", true);
      $("#rdpot").attr("disabled", true);
      $("#rdpod").attr("disabled", true);
      $("#rdopcode").attr("disabled", true);
      $("#rdsoopr").attr("disabled", true);
      $("#btnRetrieve").attr("disabled", false);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbReceipt.disableCombo = false;
      this.cbDelivery.disableCombo = false;
      this.grid.editable = false;
      this.model.blOceanVesselBound = "O";
      this.selection = "SOOPR";
      this.grid.onClear();
      this.cbReceipt.setValue("CY");
      this.cbDelivery.setValue("CY");
      // this.cbSOOPR.disableCombo=false;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      //this.cbOPCode.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbDischargePort.setValue("");
      this.cbLoadPort.setValue("");
      // this.cbOPCode.setValue('');
      this.cbTranshipmentPort.setValue("");
      // this.model.blSOOperatorCode = ''
      // this.model.blOperatorCode = '';
      this.lockOPCode = true;
      this.lockSOOPR = false;
      this.model = new BLHeader();
      this.model.blOceanVesselBound = "O";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.blOpCode = "";
      this.soOprCode = "";
    } else {
      this.defaultBehave();
    }
  }

  defaultBehave() {
    this.loading = false;
    this.dataBLArr = [];
    this.dataInvoice = [];
    this.lockOPCode = true;
    this.lockSOOPR = true;
    $("#myContainer").attr("disabled", true);
    $("#fieldSortBy").attr("disabled", true);
    $("#idBound").attr("disabled", true);
    $("#btnRetrieve").attr("disabled", true);
    $("#rdinvoiceno").attr("checked", true);
    $("#selVessel").prop("checked", false);
    $("#selInvoice").prop("checked", true);
    $("#printFAI").prop("checked", true);
    $("#printItem").prop("checked", false);
    $("#convertSGD").prop("checked", true);
    $("#cntSOC").prop("checked", true);
    $("#silentMode").prop("checked", true);
    $("#cntCOC").prop("checked", true);
    $("#cntSOCTS").prop("checked", true);
    $("#cntCOCTS").prop("checked", true);
    $("#cntSLOT").prop("checked", false);
    $("#invByCurrency").prop("checked", false);
    $("#notconvertSGD").prop("checked", false);
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbReceipt.disableCombo = true;
    this.cbDelivery.disableCombo = true;
    this.addRemoveButton = "";
    this.grid.editable = true;
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.model.blOceanVesselBound = "O";
    this.cbDischargePort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    //this.cbOPCode.disableCombo = true;
    //this.cbSOOPR.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbDischargePort.setValue("");
    // this.cbOPCode.setValue('');
    this.cbLoadPort.setValue("");
    this.cbTranshipmentPort.setValue("");
    this.model.blSOOperatorCode = "";
    this.model.blOperatorCode = "";
    this.cbDelivery.setValue("CY");
    this.cbReceipt.setValue("CY");
    this.selection = "INVOICE";
    this.clearValidatorError();
    this.isError = false;
    this.grid.onClear();
    this.model.noOfSet = "1";
    this.model = new BLHeader();
    this.blOpCode = "";
    this.soOprCode = "";
  }

  changeBound(event) {
    this.model.blOceanVesselBound = event.target.value;
    this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blOceanVesselId +
        "/" +
        this.model.blOceanVesselBound.toUpperCase() +
        "/voyage_like={query}"
    );
    this.cbVoyage.setValue("");

    this.model.blOceanVesselVoyage = "";
  }

  changeOfSet(event) {
    this.printCopy = event.target.value;
    //this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + this.model.blOceanVesselId + "/" + this.model.blOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
    //this.cbVoyage.setValue("");

    //this.model.blOceanVesselVoyage= '';
  }

  rowEvent(act: string) {
    var tableStore = [];
    this.grid.url = "";
    if (act == "add") {
      this.grid.listStore.store.push({
        id: (this.grid.listStore.store.length + 1).toString(),
        blNo: "",
      });
      this.grid.loadData(
        0,
        $("#grid_dataTables_scrollBody").prop("scrollHeight")
      );
      //this.grid.is_select_all = 'checked';
      this.grid.onSelectAllByValue(true);
      this.grid.is_select_all = "checked";
    } else if (act == "del") {
      console.log("PANJANG GRIDNYA: " + this.grid.onSelect.length);

      console.log("masuk remove");
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck: number;
      var storeTemp = new ListStore();

      this.grid.listStore.store.forEach((xy) => {
        console.log("xy " + xy);
        storeTemp.addData(xy);
      });

      storeTemp.store.forEach((el) => {
        if (el["select"] == "checked") {
          boxCheck = this.grid.listStore.store.indexOf(el);
          this.grid.listStore.store.splice(boxCheck, 1);

          $('input[name="gridCount"]').val(
            parseInt($('input[name="gridCount"]').val()) - 1
          );
        } else {
        }
      });

      this.grid.listStore.store.forEach((col) => {
        seqNo = seqNo + 1;
        col.seqNo = seqNo;
      });

      var yScroll = this.grid.getScrollY();

      this.grid.loadData(0, yScroll);
      this.grid.clearSelectedValues();
    }
  }

  checkContainer() {
    var param = "";
    var socParam = "XXXX";
    var cocParam = "XXXX";
    var soctsParam = "XXXX";
    var coctsParam = "XXXX";
    var slotParam = "XXXX";

    if ($("#cntSOC").is(":checked")) {
      socParam = "SOC";
    }
    if ($("#cntCOC").is(":checked")) {
      cocParam = "COC";
    }
    if ($("#cntSOCTS").is(":checked")) {
      soctsParam = "SOCTS";
    }
    if ($("#cntCOCTS").is(":checked")) {
      coctsParam = "COCTS";
    }
    if ($("#cntSLOT").is(":checked")) {
      slotParam = "SLOT";
    }

    return param;
  }

  actionRetrieve() {
    var socParam = "XXXX";
    var cocParam = "XXXX";
    var soctsParam = "XXXX";
    var coctsParam = "XXXX";
    var slotParam = "XXXX";
    var deliveryCode = "YYYY";
    var receiptCode = "YYYY";
    var byFAIItem = "Y";

    if ($("#printFAI").is(":checked")) {
      byFAIItem = "Y";
    }

    if ($("#printItem").is(":checked")) {
      byFAIItem = "N";
    }

    if ($("#cntSOC").is(":checked")) {
      socParam = "SOC";
    }
    if ($("#cntCOC").is(":checked")) {
      cocParam = "COC";
    }
    if ($("#cntSOCTS").is(":checked")) {
      soctsParam = "SOCTS";
    }
    if ($("#cntCOCTS").is(":checked")) {
      coctsParam = "COCTS";
    }
    if ($("#cntSLOT").is(":checked")) {
      slotParam = "SLOT";
    }

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/blInvoice/grabInvoiceNo/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound +
          "/" +
          this.officeCode +
          "/" +
          this.model.blReceiptTypeCode +
          "/" +
          this.model.blDeliveryTypeCode +
          "/" +
          this.model.blLoadPortCode +
          "/" +
          this.model.blDischargePortCode +
          "/" +
          socParam +
          "/" +
          cocParam +
          "/" +
          soctsParam +
          "/" +
          coctsParam +
          "/" +
          slotParam +
          "/" +
          byFAIItem +
          "/A/" +
          this.soOprCode +
          "/" +
          this.blOpCode +
          "/" +
          this.model.blPortOfTransshipment1Code
      )
      .subscribe((resp) => {
        var ckDob = false;
        let dArr = [];
        let biji = [];
        dArr = resp.json()["content"];
        this.dataBLArr = resp.json()["content"];
        let lengthArray: Number = 0;
        lengthArray = dArr.length;
        console.log(dArr);
        console.log("RESP NYA : " + resp.json().length + " >>> " + dArr.length);

        if (lengthArray > 0) {
          this.loading = false;
          this.disableToolbarButtons = "";

          resp.json()["content"].forEach((idx) => {
            // biji.push(idx);
            // ckDob = this.checkDuplicateInObject('blNo',biji);
            console.log(biji.indexOf(idx.blNo));
            if (biji.indexOf(idx.blNo) < 0) {
              this.grid.listStore.store.push(idx);
            }

            biji.push(idx.blNo);
          });

          console.log("bijii");
          console.log(biji);
          console.log(this.grid.listStore.store);
          //this.grid.listStore.store = resp.json()['content'];
          this.grid.loadData();
          this.grid.onSelectAllByValue(true);
          this.grid.is_select_all = "checked";
        } else {
          this.grid.listStore.store = [];
          this.loading = false;
          this.grid.loadData();
          this.disableToolbarButtons = "";
          this.dialogPlugin.show(
            "information",
            "Information",
            "No record found.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }
      });
  }

  onRetrieve() {
    if (this.selection == "VESSEL") {
      this.setValidatorVessel();
      this.isError = this.onValidate(this.model);
      this.grid.listStore.store = [];
      this.grid.onClear();
      console.log("VALIDASINYA -- > " + this.onValidate(this.model));
      if (!this.isError) {
        this.loading = true;
        this.disableToolbarButtons = "ok,ok2,cancel,close";
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();
        //console.log("VALIDASINYA 2 -- > "+this.onValidate(this.model));
        //this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        //this.grid.url ="http://localhost:15000/blInvoice/findBLByVesselNoPaging";
        // this.grid.search = this.cookieService.getDefaultLocationCode() +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"==Y";
        //this.grid.loadData();
      }
    } else if (this.selection == "POL") {
      // this.setValidatorVessel();
      this.setValidatorLoadPort();

      this.isError = this.onValidate(this.model);
      this.grid.listStore.store = [];
      this.grid.onClear();
      if (!this.isError) {
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();

        //this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        //this.grid.search = this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=POL-"+this.model.blLoadPortCode;
        //this.grid.loadData();
      }
    } else if (this.selection == "POD") {
      //this.setValidatorVessel();
      this.setValidatorDischargePort();

      this.isError = this.onValidate(this.model);
      this.grid.listStore.store = [];
      this.grid.onClear();
      if (!this.isError) {
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();
      }
    } else if (this.selection == "POT") {
      // this.setValidatorVessel();
      this.setValidatorTranshipmentPort();
      this.grid.onClear();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        // this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();
      }
    } else if (this.selection == "OPCODE") {
      // this.setValidatorVessel();
      this.setValidatorOPCode();
      this.grid.onClear();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        // this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();
      }
    } else if (this.selection == "SOOPR") {
      // this.setValidatorVessel();
      this.setValidatorSOOPR();
      this.grid.onClear();
      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        // this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        if (
          this.model.blDischargePortCode == null ||
          this.model.blDischargePortCode == ""
        )
          this.model.blDischargePortCode = "[-42]";
        if (
          this.model.blLoadPortCode == null ||
          this.model.blLoadPortCode == ""
        )
          this.model.blLoadPortCode = "[-42]";
        if (
          this.model.blPortOfTransshipment1Code == null ||
          this.model.blPortOfTransshipment1Code == ""
        )
          this.model.blPortOfTransshipment1Code = "[-42]";
        if (
          this.model.blSOOperatorCode == null ||
          this.model.blSOOperatorCode == ""
        )
          this.soOprCode = "[-42]";
        if (
          this.model.blOperatorCode == null ||
          this.model.blOperatorCode == ""
        )
          this.blOpCode = "[-42]";

        this.containerParam = this.checkContainer();
        if (this.containerParam == "") this.containerParam = "0";
        this.actionRetrieve();
      }
    }
  }
  changeEventDelivery(event) {
    if (
      event.deliveryTypeCode == null ||
      event.deliveryTypeCode == "" ||
      event.deliveryTypeCode == undefined
    ) {
      this.model.blDeliveryTypeCode = "[-42]";
    } else {
      this.model.blDeliveryTypeCode = event.deliveryTypeCode;
    }
  }
  changeEventReceipt(event) {
    if (
      event.receiptTypeCode == null ||
      event.receiptTypeCode == "" ||
      event.receiptTypeCode == undefined
    ) {
      this.model.blReceiptTypeCode = "[-42]";
    } else {
      this.model.blReceiptTypeCode = event.receiptTypeCode;
    }
  }

  changeEventVoyage(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }

  changeEventLoadPort(event) {
    console.log("event Load Port : " + event);

    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blLoadPortCode = "[-42]";
    } else {
      this.model.blLoadPortCode = event.locationCode;
    }
  }

  changeEventDischargePort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blDischargePortCode = "[-42]";
    } else {
      this.model.blDischargePortCode = event.locationCode;
    }
  }
  changeEventTranshipmentPort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPortOfTransshipment1Code = "[-42]";
    } else {
      this.model.blPortOfTransshipment1Code = event.locationCode;
    }
  }
  changeEventSOOPR(event) {
    if (
      event.target.value == null ||
      event.target.value == "" ||
      event.target.value == undefined
    ) {
      this.model.blSOOperatorCode = "[-42]";
      this.soOprCode = "[-42]";
    } else {
      this.model.blSOOperatorCode = event.target.value;
      this.soOprCode = event.target.value;
    }
  }
  changeEventOPCode(event) {
    if (
      event.target.value == null ||
      event.target.value == "" ||
      event.target.value == undefined
    ) {
      this.model.blOperatorCode = "[-42]";
      this.blOpCode = "[-42]";
    } else {
      this.model.blOperatorCode = event.target.value;
      this.blOpCode = event.target.value;
    }
  }
  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log("eventgrid" + event);

    switch (event.split(".")[0]) {
      case "afterLoad":
        console.log(this.grid.listStore.store);

        break;

      case "click":
        this.grid.listStore.store.forEach((yr) => {
          if (yr.select == "checked") {
            console.log("ceki ceki " + yr["blNo"]);
            this.model.blOfficeCode = this.officeCode;
            this.model.blNo = yr["blNo"].toUpperCase();
            flgTool = 1;
          } else {
            this.model.blNo = "";
            this.model.blOfficeCode = "";
          }
        });

        //this.disableToolbarButtons = "retrieve,unlock";
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          console.log("arrr");
          console.log(arr);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              if (strEvent.split(";")[1] == "blNo") {
                this.getInvoiceStatus(
                  strEvent.split(";")[2],
                  Number(strEvent.split(";")[0].split(".")[1])
                );
              }

              this.grid.listStore.store.forEach((yr) => {
                console.log(yr);
                //this.dataBLArr.push(yr);
                soso.push(yr);
                // if(yr.select == 'checked'){
                // flgTool = 1;
                //}
              });

              // if(flgTool > 0)
              //this.disableToolbarButtons = "retrieve";
              // else
              //  this.disableToolbarButtons = "retrieve,reinstate";

              dblFlag = this.checkDuplicateInObject("blNo", soso);

              if (dblFlag == true) {
                this.dialogPlugin.show(
                  "information",
                  "Information",
                  "Duplicate Invoice No. detected.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.grid.listStore.store.splice(
                  parseInt(arr[0].split(".")[1]) - 1,
                  1
                );
                this.grid.loadData();
              }

              break;
          }
        }

        break;
    }
  }
  infoGrid(event) {}

  getInvoiceStatus(blNo: String, idx: number) {
    var flagFaiGroup = "N";
    var flagC99 = "N";
    var faiParam = "Y";
    var flagItemGroup = "N";
    var finishBL = "N";
    var blProformaPrinted = "N";
    var partBL = "";
    var combineFinish = "N";

    //if($('#printItem').is(':checked'))faiParam = 'N';

    if (blNo.trim() == "" || blNo.trim() == undefined) {
      blNo = "blNo";
    } else {
      this.loading = true;
      this.disableToolbarButtons = "ok,ok2,cancel,close";
    }

    var boxCheck: number;
    //this.genericService.GET(this.configService.config.BASE_API.toString() + "/BLReport/findBLNo/"+this.officeCode+"/"+blNo.toUpperCase()).subscribe((resp) => {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/blInvoice/grabInvoiceByNo/" +
          this.officeCode +
          "/" +
          blNo.toUpperCase() +
          "/Y"
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            resp.json()["content"].forEach((gpl) => {
              console.log(gpl);

              if (
                gpl["socAccountCode"] == "CC999" ||
                gpl["cocAccountCode"] == "CC999" ||
                gpl["slotAccountCode"] == "CC999"
              )
                flagC99 = "Y";

              if (gpl["blFaiGroup"] == "Y") flagFaiGroup = "Y";

              if (gpl["blIsFinish"] == "Y") {
                finishBL = "Y";
              }

              if (gpl["blInvPrinted"] == "P") {
                blProformaPrinted = "P";
              }

              if (gpl["blInvPrinted"] == "A") {
                blProformaPrinted = "A";
              }

              if (gpl["partBL"] != "") {
                partBL = gpl["partBL"];
              }

              // if(gpl['blIsFinish'] == 'Y' && gpl['blCombineStatus'] == 'N' && gpl['blIsCombine'] == 'Y'){
              //combineFinish = 'Y';
              //}
              // if(gpl['blFaiGroup'] == 'N')
              //flagItemGroup = 'Y';
            });

            /*
            if(combineFinish == "Y"){
              this.dialogPlugin.show('information','Information','BL combine status changed, please regenerate the invoice from proforma module.','okonly',{ok: 'this.loading=false;'});
              this.grid.listStore.store.splice(boxCheck,1);
                  
                  this.grid.loadData();
                  this.loading = false
                  this.disableToolbarButtons = "";
            }
            */
            if (partBL != "") {
              this.dialogPlugin.show(
                "information",
                "Information",
                "Invoice already generated, please insert invoice no : <br />" +
                  partBL,
                "okonly",
                { ok: "this.loading=false;" }
              );
              this.grid.listStore.store.splice(boxCheck, 1);

              this.grid.loadData();
              this.loading = false;
              this.disableToolbarButtons = "";
            }
            /*
            if(flagC99 == 'N'){
              this.grid.listStore.store.forEach(data=>{
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){

                  this.dialogPlugin.show('information','Information','Unable to generate, Cust. Acct Code is NOT CC999.','okonly',{ok: 'this.loading=false;'});
  
                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;                
                  console.log("BOXCEK --> "+boxCheck);
                  this.grid.listStore.store.splice(boxCheck,1);
                  this.loading = false
                  this.grid.loadData();
                  this.disableToolbarButtons = "";

  
                }
              });
              
            }
            */
            if (finishBL == "N") {
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "The corresponding BL has not been finished.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.grid.loadData();
                  this.disableToolbarButtons = "";
                }
              });
            }

            if (blProformaPrinted == "N") {
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "Proforma Invoice has not been printed.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.grid.loadData();
                  this.disableToolbarButtons = "";
                }
              });
            }

            if (blProformaPrinted == "A") {
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "Invoice has been printed.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.grid.loadData();
                  this.disableToolbarButtons = "";
                }
              });
            }

            if ($("#printFAI").is(":checked") && flagFaiGroup == "N") {
              console.log("masuk else fai");
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "Invoice is not under FAI Group, please check.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.grid.loadData();
                  this.disableToolbarButtons = "";
                }
              });
            } else if (flagFaiGroup == "Y" && $("#printItem").is(":checked")) {
              console.log("masuk else fai");
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "Invoice is not under Itemised, please check.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.grid.loadData();
                  this.disableToolbarButtons = "";
                }
              });
            } else if (flagC99 == "N") {
              console.log("masuk else C99");
              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "Unable to generate, Cust. Acct Code is NOT CC999.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.loading = false;
                  this.disableToolbarButtons = "";
                  this.grid.loadData();
                }
              });
            } else {
              // this.dataBLArr = resp.json()['content'];//untuk sementara
              this.loading = false;
              this.disableToolbarButtons = "";

              resp.json()["content"].forEach((bulk) => {
                if (
                  bulk["socAccountCode"] == "CC999" ||
                  bulk["cocAccountCode"] == "CC999" ||
                  bulk["slotAccountCode"] == "CC999"
                ) {
                  this.dataBLArr.push(bulk); //untuk sementara
                  this.grid.listStore.store.forEach((data) => {
                    if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                      (data["blNoOrigin"] = bulk.blNoOrigin.toUpperCase()),
                        (data["invSplitMethod"] = bulk.invSplitMethod),
                        (data["blFcPayer"] = bulk.blFcPayer),
                        (data["blFcCurrency"] = bulk.blFcCurrency),
                        (data["invHash"] = bulk.invHash),
                        (data["invChanged"] = "N");
                    }
                  });
                }
              });
            }
          } else {
            if (this.grid.listStore.store.length > 0) {
              //console.log("satu: "+Object.keys(resp.json()['content']).length );

              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.dialogPlugin.show(
                    "information",
                    "Information",
                    "No Record Found.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  //xx=data.blFcPop,data.locationName;
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.disableToolbarButtons = "";
                  this.grid.loadData();
                }
              });
              this.disableToolbarButtons = "";
            }
          }
        }
      });
  }

  clearValidatorError() {
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blPlaceOfDeliveryCode"] = "";
    this.model["error-blPlaceOfReceiptCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blOperatorCode"] = "";
    this.model["error-blSOOperatorCode"] = "";
    this.model["error-blPortOfTransshipment1Code"] = "";
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};
    //console.log("1: "+propertyName);

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName].trim().toUpperCase();
      console.log("item property name " + itemPropertyName);
      if (item[propertyName] != "") {
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
        } else {
          testObject[itemPropertyName] = item;
          console.log("test OBject ");
          console.log(testObject[itemPropertyName]);
          delete item.duplicate;
        }
      }
    });

    return seenDuplicate;
  }
  printActualInvoice2() {
    //this.loading = true;
    var postData = {};
    var cleanParam = [];
    var flagMerge = false;
    let grax = [];

    var startDate = new Date(this.genericUtil.getPSIDate());
    var options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    var printedDateJasper = startDate.toLocaleString("en-US", options);

    var splDate = printedDateJasper.split(",");

    var formatD = splDate[0].split("/");

    var newForm = formatD[1] + "/" + formatD[0] + "/" + formatD[2];

    newForm = newForm + "@" + splDate[1].replace(" ", "@");

    newForm = newForm.replace(" ", "@");

    console.log("TIMEEEE " + newForm);

    postData["userid"] = this.userId;
    postData["processData"] = this.genericUtil.getPSIDate();
    postData["documentType"] = "ActualInvoice";
    postData["silent-mode"] = $("#silentMode").is(":checked") ? "Y" : "N";
    //postData["silent-mode"] = 'N'
    postData["officeCode"] = this.officeCode;
    var draftInv = "Y";

    var jasperDefaultPath = "";
    if ($("#silentMode").is(":checked")) {
      draftInv = "N";
      if ($("#notconvertSGD").is(":checked"))
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BLInvoiceSGNewNoSP.pdf";
      else {
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BLInvoiceSGNewSP.pdf";
      }
    } else {
      if ($("#notconvertSGD").is(":checked"))
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BLInvoiceSGNewNon.pdf";
      else {
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BLInvoiceSGNew.pdf";
      }
    }

    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/edi-util/getFile/invoiceNewV2";
    //var hitUrl = "http://localhost:17000/edi-util/getFile/invoiceNew";
    var i = 0;
    var currParam = "ZZZZ";

    console.log("isis data invoice ko  banyak");

    if (this.grid.listStore.store.length > 0) {
      var eDataList = [];
      //this.dataInvoice.forEach(fe=>{
      //clean data before put in parameter
      this.dataInvoice.forEach((plt) => {
        if ($("#invByCurrency").is(":checked")) {
          plt["mergeData"] =
            plt.blNo.toUpperCase() +
            "-" +
            plt.blFcCurrency +
            "-" +
            plt.blFcPayer;
        } else {
          //plt['mergeData'] = plt.blNo.toUpperCase()+"-"+plt.blFcPayer
          plt["mergeData"] = plt.invoiceNo.toUpperCase();
        }
      });

      this.dataInvoice.forEach((grx) => {
        //console.log(grax);
        //flagMerge = this.checkDuplicateInObject('mergeData',grax);
        //console.log(grax)
        //console.log(grx.mergeData);
        //console.log(grax.indexOf(grx.mergeData));
        if (
          grax.indexOf(grx.mergeData) < 0 &&
          grx.blFcPc.substring(0, 1) == "P"
        ) {
          //  if(grx.socAccountCode == 'CC999'||grx.cocAccountCode == 'CC999' || grx.slotAccountCode == 'CC999' ){

          cleanParam.push(grx);

          // }
        }

        grax.push(grx.mergeData);
      });
      //end clean data
      console.log(this.dataInvoice);
      console.log("data clean param");
      console.log(cleanParam);
      cleanParam.forEach((fe) => {
        var eData = {};
        var jasperUrlPath = "";
        i++;

        //if(fe.select == 'checked'){

        if (fe.blNo != "") {
          eData["blNo"] = fe.blNo.toUpperCase().trim();
          eData["invoiceNo"] = fe.invoiceNo.toUpperCase(); //remark sementara
          eData["fileName"] =
            "ActualInvoicenew-" +
            fe.invoiceNo.toUpperCase().trim() +
            "-" +
            this.userId +
            "_" +
            i +
            ".pdf";
          // eData["byCurrency"] = "N";
          eData["attach"] = "N";

          if (fe.blSplitMethod == "C") {
            // eData['byCurrency'] = 'Y';
            currParam = fe.blFcCurrency;
          } else {
            currParam = "ZZZZ";
          }
        }

        jasperUrlPath = jasperDefaultPath;
        //remark buat invoice baru
        // eData["url"]= jasperUrlPath + "?BLNo="+fe.blNo.toUpperCase().trim()+"&placeOfCreation="+this.officeCode+"&userName="+this.userId+"&draft="+draftInv+"&proforma=N&payer="+fe.blFcPayer.toUpperCase().trim()+"&currency="+currParam+"&invoiceNo="+fe.invoiceNo.toUpperCase()+"&printedDate="+newForm+"&page=1";
        eData["url"] =
          jasperUrlPath +
          "?BLNo=" +
          fe.blNo.toUpperCase().trim() +
          "&placeOfCreation=" +
          this.officeCode +
          "&userName=" +
          this.userId +
          "&draft=" +
          draftInv +
          "&proforma=N&payer=" +
          fe.blFcPayer.toUpperCase().trim() +
          "&currency=" +
          currParam +
          "&invoiceNo=" +
          fe.invoiceNo.toUpperCase() +
          "&printedDate=" +
          this.genericUtil.getPSIDate();
        // if($('#invByCurrency').is(':checked'))
        // eData["url"]= jasperUrlPath + "?BLNo="+fe.blNo.toUpperCase().trim()+"&placeOfCreation="+this.officeCode+"&userName="+this.userId+"&draft=Y&proforma=PROFORMA&payer="+fe.blFcPayer.toUpperCase().trim();
        // else
        //  eData["url"]= jasperUrlPath + "?BLNo="+fe.blNo.toUpperCase().trim()+"&placeOfCreation="+this.officeCode+"&userName="+this.userId+"&draft=Y&proforma=PROFORMA&payer="+fe.blFcPayer.toUpperCase().trim()+"&currency=ZZZZ&page=1";

        eDataList.push(eData);
        //}
      });

      //postData["invoiceData"] = eDataList;
      postData["invoiceData"] = eDataList;

      console.log("eaaa");
      console.log(postData);
    }

    this.genericService.POST(hitUrl, postData).subscribe((resp) => {
      if (resp.ok) {
        var result: any = resp.json()["content"];

        if ($("#silentMode").is(":checked")) {
          var prinDataList = [];
          result.forEach((fe) => {
            if (fe["message"] == "success") {
              var invData = {};
              var printDoc = [];
              var printDocDetail = {};
              invData["docNo"] = fe["invoiceNo"];
              invData["printDoc"] = [];

              if (this.configService.config.server == "PRODUCTION") {
                printDocDetail["docName"] = fe["filePath"].replace(
                  "/silentPrinting/",
                  ""
                );
              } else {
                printDocDetail["docName"] = fe["filePath"].replace(
                  "/download/silentPrinting/",
                  ""
                );
              }
              printDocDetail["docAttachName"] = "";
              printDocDetail["spoolerDoc"] = "ACTUALINVOICE";
              printDocDetail["spoolerDocAttach"] = "";
              printDocDetail["totalPrint"] = this.printCopy;
              printDocDetail["hasAttach"] = "N";
              if (fe["filePath-attached"] != undefined) {
                if (fe["filePath-attached"] != "") {
                  if (this.configService.config.server == "PRODUCTION") {
                    printDocDetail["docAttachName"] = fe[
                      "filePath-attached"
                    ].replace("/silentPrinting/", "");
                  } else {
                    printDocDetail["docAttachName"] = fe[
                      "filePath-attached"
                    ].replace("/download/silentPrinting/", "");
                  }
                  printDocDetail["spoolerDocAttach"] = "DRAFTBL";
                  printDocDetail["hasAttach"] = "Y";
                }
              }
              invData["printDoc"].push(printDocDetail);

              prinDataList.push(invData);
            }
            //this.message("information","Information","BL(s) Has been sent to printer, please check nearest printer to collect BL(s)","okonly",{ok:""});
          });

          console.log("horee");
          console.log(prinDataList);

          if (prinDataList.length > 0) {
            postData = {};
            postData["userId"] = this.userId;
            postData["userEmail"] = this.userEmail;
            postData["userLocationId"] = this.officeId;
            postData["printList"] = prinDataList;
            postData["server"] = this.configService.config.server;
          }

          console.log(postData);

          //hitUrl = "http://10.10.100.6:12121/PrintService/print";
          hitUrl = this.configService.config.BASE_API.toString() + "/?q=/PrintService/print";
          //hitUrl = "http://localhost:12121/PrintService/print";
          this.genericService.post(hitUrl, postData).subscribe(
            (resp) => {
              if (resp.ok) {
                this.dialogPlugin.show(
                  "information",
                  "Information",
                  "Document has been sent for printing.",
                  "okonly",
                  { ok: "" }
                );
                this.grid.onClear();
                this.loading = false;
                this.disableToolbarButtons = "";
              }
            },
            (error) => {
              this.dialogPlugin.show(
                "Information",
                "information",
                "An Error Occured while printing document, please contact your administrator!",
                "okonly",
                { ok: "" }
              );
              this.loading = false;
              this.disableToolbarButtons = "";
            }
          );
        } else {
          result.forEach((fe) => {
            if (fe["message"] == "success") {
              var arr = {};
              arr["invoiceNo"] = fe["invoiceNo"];
              arr["pdfUrl"] =
                this.configService.config.pdfUrl +
                fe["filePath"] +
                "#view=FitH";

              this.gridPDFPrev.listStore.store.push(arr);
            }
          });

          if (this.gridPDFPrev.listStore.store.length > 1) {
            //this.setPDF(store);
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
            this.loading = false;
            this.disableToolbarButtons = "";
            this.grid.onClear();
            //this.selectedListMod(1, this.gridPDFPrev, 'no');
          } else if (this.gridPDFPrev.listStore.store.length == 1) {
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
            $("#pdfContainerNewInvoice")
              .find("#pdfContainerNewInvoice-label")
              .hide();
            $("#pdfContainerNewInvoice")
              .find("#pdfContainerNewInvoice-grid-container")
              .hide();
            this.loading = false;
            this.disableToolbarButtons = "";
            this.grid.onClear();
            //this.gridPDFPrev.listStore.store.forEach(fe=>{
            //this.pdfContainer.setPDFURL(fe["pdfUrl"]);
            //this.pdfContainer.showPDF();
            //});
          } else {
            this.dialogPlugin.show(
              "information",
              "Information",
              "Failed to load PDF. Please contact your administrator.",
              "okonly",
              { ok: "" }
            );
          }

          this.loading = false;
          this.disableToolbarButtons = "";
        }
      }
    });
  }

  printActualInvoice() {
    //this.loading = true;
    var postData = {};
    var cleanParam = [];
    var flagMerge = false;
    let grax = [];

    postData["userid"] = this.userId;
    postData["processData"] = this.genericUtil.getPSIDate();
    postData["documentType"] = "ActualBL";
    postData["silent-mode"] = "N";
    postData["officeCode"] = this.officeCode;

    var jasperDefaultPath = "";
    if ($("#invByCurrency").is(":checked"))
      jasperDefaultPath =
        this.configService.config.jasperUrl +
        "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BL_Invoice_SingaporeFAINCur2.pdf";
    else
      jasperDefaultPath =
        this.configService.config.jasperUrl +
        "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/BL_Invoice_Singapore_FA_I_N.pdf";
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/edi-util/getFile/invoiceNew";
    //var hitUrl = "http://localhost:17000/edi-util/getFile/invoiceNew";
    var i = 0;
    var currParam = "ZZZZ";

    if (this.grid.listStore.store.length > 0) {
      var eDataList = [];
      //this.dataInvoice.forEach(fe=>{
      //clean data before put in parameter
      this.dataBLArr.forEach((plt) => {
        // if($('#invByCurrency').is(':checked')){
        //  plt['mergeData'] = plt.blNo.toUpperCase()+"-"+plt.blFcCurrency+"-"+plt.blFcPayer
        //}else{
        plt["mergeData"] = plt.blNo.toUpperCase() + "-" + plt.blFcPayer;
        //}
      });
      console.log("isis data invoice ko  banyak");
      console.log(this.dataBLArr);
      this.dataBLArr.forEach((grx) => {
        //console.log(grax);
        //flagMerge = this.checkDuplicateInObject('mergeData',grax);
        //console.log(grax)
        //console.log(grx.mergeData);
        //console.log(grax.indexOf(grx.mergeData));
        if (grax.indexOf(grx.mergeData) < 0) {
          if (
            grx.socAccountCode == "CC999" ||
            grx.cocAccountCode == "CC999" ||
            grx.slotAccountCode == "CC999"
          ) {
            cleanParam.push(grx);
          }
        }

        grax.push(grx.mergeData);
      });
      //end clean data

      console.log(cleanParam);
      cleanParam.forEach((fe) => {
        var eData = {};
        var jasperUrlPath = "";
        i++;

        //if(fe.select == 'checked'){

        if (fe.blNo != "") {
          eData["blNo"] = fe.blNo.toUpperCase().trim();
          // eData['invoiceNo'] = fe.invoiceNo.toUpperCase(); remark sementara
          eData["fileName"] =
            "ProformaInvoice-" +
            fe.blNo.toUpperCase().trim() +
            "-" +
            this.userId +
            "_" +
            i +
            ".pdf";
          // eData["byCurrency"] = "N";
          eData["attach"] = "N";

          if ($("#invByCurrency").is(":checked")) {
            // eData['byCurrency'] = 'Y';
            currParam = fe.blFcCurrency;
          } else {
            //eData['byCurrency'] = 'N';
          }
        }

        jasperUrlPath = jasperDefaultPath;
        //remark buat invoice baru
        //eData["url"]= jasperUrlPath + "?BLNo="+fe.blNo.toUpperCase().trim()+"&placeOfCreation="+this.officeCode+"&userName="+this.userId+"&draft=Y&proforma=PROFORMA&payer="+fe.blFcPayer.toUpperCase().trim()+"&currency="+currParam+"&invoiceNo="+fe.invoiceNo.toUpperCase()+"&page=1";
        if ($("#invByCurrency").is(":checked"))
          eData["url"] =
            jasperUrlPath +
            "?BLNo=" +
            fe.blNo.toUpperCase().trim() +
            "&placeOfCreation=" +
            this.officeCode +
            "&userName=" +
            this.userId +
            "&draft=Y&proforma=N&payer=" +
            fe.blFcPayer.toUpperCase().trim();
        //eData["url"]= jasperUrlPath + "?BLNo="+fe.blNo.toUpperCase().trim()+"&placeOfCreation="+this.officeCode+"&userName="+this.userId+"&draft=Y&proforma=N&payer="+fe.blFcPayer.toUpperCase().trim()+"&currency=ZZZZ&page=1";
        else
          eData["url"] =
            jasperUrlPath +
            "?BLNo=" +
            fe.blNo.toUpperCase().trim() +
            "&placeOfCreation=" +
            this.officeCode +
            "&userName=" +
            this.userId +
            "&draft=Y&proforma=N&payer=" +
            fe.blFcPayer.toUpperCase().trim() +
            "&currency=ZZZZ";

        eDataList.push(eData);
        //}
      });

      //postData["invoiceData"] = eDataList;
      postData["invoiceData"] = eDataList;

      console.log("eaaa");
      console.log(postData);
    }

    this.genericService.POST(hitUrl, postData).subscribe((resp) => {
      if (resp.ok) {
        var result: any = resp.json()["content"];

        result.forEach((fe) => {
          if (fe["message"] == "success") {
            var arr = {};
            arr["invoiceNo"] = fe["blNo"];
            arr["pdfUrl"] =
              this.configService.config.pdfUrl + fe["filePath"] + "#view=FitH";

            this.gridPDFPrev.listStore.store.push(arr);
          }
        });

        if (this.gridPDFPrev.listStore.store.length > 1) {
          //this.setPDF(store);
          this.showPDF();
          this.gridPDFPrev.loadData();
          this.gridPDFPrev.clearSelectedValues();
          this.gridPDFPrev.onSelect(true, 1);
          //this.selectedListMod(1, this.gridPDFPrev, 'no');
        } else if (this.gridPDFPrev.listStore.store.length == 1) {
          this.showPDF();
          this.gridPDFPrev.loadData();
          this.gridPDFPrev.clearSelectedValues();
          this.gridPDFPrev.onSelect(true, 1);
          $("#pdfContainerNewInvoice")
            .find("#pdfContainerNewInvoice-label")
            .hide();
          $("#pdfContainerNewInvoice")
            .find("#pdfContainerNewInvoice-grid-container")
            .hide();
          //this.gridPDFPrev.listStore.store.forEach(fe=>{
          //this.pdfContainer.setPDFURL(fe["pdfUrl"]);
          //this.pdfContainer.showPDF();
          //});
        } else {
          this.dialogPlugin.show(
            "information",
            "Information",
            "Failed to load PDF. Please contact your administrator.",
            "okonly",
            { ok: "" }
          );
        }

        this.loading = false;
      }
    });
  }

  showPDF() {
    var self = this;
    /*$("#pdfContainerNewBlDraft2")
    .modal('setting', {
      onShow : function () {
        $(this).css({
            'margin' : '5px',
            'position' : 'fixed',
            'top' : '0',
            'bottom' : '0',
            'left' : '0',
            'right' : '0',
            'width' : 'auto',
            'height' : '500px'
        });
        $("body").first().css("overflow","hidden");
      },
      'closable' : false,
      onHide : function(){
        self.clearPDFPlugin();
        $("body").first().css("overflow","auto");
        //self.eventPdf.emit("close-pdf");
      }
    }).modal("show");*/

    this.newDialogOpt = {
      title: "PDF Preview",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function (event, ui) {
        //hide close button.
        //// console.log($(this).css("z-index","102"));
        $(".ui-dialog").css("z-index", 103);
        $(".ui-widget-overlay").css("z-index", 102);
        //$(this).parent().children().children('.ui-dialog-titlebar-close').hide();
        $("body").first().css("overflow", "hidden");
      },
      close: function (event, ui) {
        $("body").first().css("overflow", " auto");
        self.clearPDFPlugin();
      },
    };

    this.showDialog("pdfContainerNewInvoice", this.newDialogOpt);
  }

  gridEventPDFPrev(event) {
    console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        this.selectedEvent(event);
        break;
      case "click":
        this.selectedEvent(event);
        break;
      default:
        break;
    }
  }

  selectedEvent(event) {
    //// // // console.log(event);
    var no: number = 0;
    var opt =
      event.split(".").length > 1 ? event.split(".")[1].split("-")[0] : event;
    var opt2 =
      event.split(".").length > 1 ? event.split(".")[1].split("-")[1] : event;
    switch (opt) {
      case "checked":
        no = opt2;
        this.selectedListMod(no, this.gridPDFPrev, "no");
        this.gridPDFPrev.listStore.store.forEach((fe) => {
          if (fe["no"] == no) {
            //console.log(fe);
            $("#pdfContainerNewInvoice")
              .find("#pdfContainerNewInvoice-viewer-container")
              .empty();
            $("#pdfContainerNewInvoice")
              .find("#pdfContainerNewInvoice-viewer-container")
              .append(
                '<object id="o" data="' +
                  fe["pdfUrl"] +
                  '" type="application/pdf" width="100%" height="100%"></object>'
              );
          }
        });

        break;
      case "unchecked":
        no = opt2;
        //$("#pdfContainerNewBlDraft2").find("#pdfContainerNewBlDraft2-viewer-container").empty();
        break;
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach((ff) => {
        if (ff[key] != value) {
          ff["select"] = "";
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
  }

  showDialog(id: string, options: any) {
    $("#" + id)
      .dialog(options)
      .dialog("open");
  }

  closeDialog(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  clearPDFPlugin() {
    this.gridPDFPrev.listStore.store.splice(
      0,
      this.gridPDFPrev.listStore.store.length
    );
    $("#pdfContainerNewInvoice")
      .find("#pdfContainerNewInvoice-viewer-container")
      .empty();
    $("#pdfContainerNewInvoice").find("#pdfContainerNewInvoice-label").show();
    $("#pdfContainerNewInvoice")
      .find("#pdfContainerNewInvoice-grid-container")
      .show();
    this.gridPDFPrev.clearSelectedValues();
    this.closeDialog("pdfContainerNewInvoice");
  }

  printInvoice2() {
    this.loading = true;

    var postMap = [];
    var byCurr = "N";
    if ($("#invByCurrency").is(":checked")) byCurr = "Y";
    this.grid.listStore.store.forEach((fe) => {
      if (fe.select == "checked") {
        if (fe.blNo != "") {
          //invNo.push(fe.blNo.toUpperCase().trim());
          postMap.push({
            byCurrency: byCurr,
            officeCode: this.officeCode,
            invNo: fe.blNo.toUpperCase().trim(),
            blNoOrigin: fe.blNoOrigin.toUpperCase().trim(),
            invSplitMethod: fe.invSplitMethod,
          });
          //  paramblNo[indx] = fe.blNo.toUpperCase().trim()
          //indx++;
          /*
          if($('#invByCurrency').is(':checked')){
            postMap['byCurrency'] = 'Y';
          }else{
            postMap['byCurrency'] = 'N';
          }
          postMap["officeCode"] = this.officeCode;
          postMap["invNo"] = fe.blNo.toUpperCase().trim();
          */
        }
      }
    });

    if (postMap.length > 0) {
      this.printActualInvoice();
    } else {
      this.loading = false;
      this.dialogPlugin.show(
        "information",
        "Information",
        "Please select Invoice to print.",
        "okonly",
        { ok: "" }
      );
      this.disableToolbarButtons = "";
    }
  }
  printInvoice() {
    this.loading = true;
    this.disableToolbarButtons = "ok,ok2,cancel,close";
    console.log("data akhir grid storenya");
    console.log(this.grid.listStore.store);
    //var uriPostInv = this.configService.config.BASE_API.toString() + "/blInvoice/generateInvoice";
    // var uriPostInv = "http://localhost:15000/blInvoice/generateInvoice";
    var byCurr = "N";
    var postMap = [];
    var paramblNo = [];
    this.dataInvoice = [];
    var indx = 0;
    var paramJoin = "";
    if ($("#invByCurrency").is(":checked")) byCurr = "Y";
    this.grid.listStore.store.forEach((fe) => {
      if (fe.select == "checked") {
        if (fe.blNo != "") {
          //invNo.push(fe.blNo.toUpperCase().trim());
          postMap.push({
            byCurrency: byCurr,
            officeCode: this.officeCode,
            invNo: fe.blNo.toUpperCase().trim(),
            blNoOrigin: fe.blNoOrigin.toUpperCase().trim(),
            invSplitMethod: fe.invSplitMethod,
            invHash: fe.invHash,
          });
          paramblNo[indx] = fe.blNo.toUpperCase().trim();
          indx++;
          /*
          if($('#invByCurrency').is(':checked')){
            postMap['byCurrency'] = 'Y';
          }else{
            postMap['byCurrency'] = 'N';
          }
          postMap["officeCode"] = this.officeCode;
          postMap["invNo"] = fe.blNo.toUpperCase().trim();
          */
        }
      }
    });

    paramJoin = paramblNo.join("@");

    if (postMap.length > 0) {
      // this.printProformaInvoice();
      //this.genericService.POST(uriPostInv, postMap).subscribe((resp)=>{
      // if(resp.ok){
      // var hitRes = resp.json()["content"];
      var ckCurr = "N";
      //if($('#invByCurrency').is(':checked'))ckCurr = 'Y'
      //ckCurr = 'Y'
      console.log("nojan effect");

      //this.grid.listStore.store.forEach(yt=>{

      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/blInvoice/getInvoiceFromFirstTime/" +
            this.officeCode +
            "/" +
            paramJoin +
            "/A"
        )
        .subscribe((resp) => {
          //this.genericService.GET("http://localhost:15000/blInvoice/getInvoiceFromFirstTime/"+this.officeCode+"/"+paramJoin+"/"+ckCurr).subscribe((resp) => {
          //console.log(resp);
          //console.log('uauaua')
          if (resp.ok) {
            if (Object.keys(resp.json()["content"]).length > 0) {
              this.dataInvoice = resp.json()["content"];
              console.log("uauaua");
              this.printActualInvoice2();
              //this.loading = false
            }
          } else {
            this.loading = false;
            this.dialogPlugin.show(
              "information",
              "Information",
              "Error ocurred when trying to generate invoice, please contact your administrator!",
              "okonly",
              { ok: "" }
            );
          }
        });

      // })

      // var self = this;
      // setTimeout(function(){
      //  self.printProformaInvoice();
      // },5000);

      /*
          this.grid.listStore.store.forEach(fe => {
            if(fe.select == 'checked'){
             // fe.hasAttachment = hitRes[fe.blNo.toUpperCase().trim()];
            }
          });
          */

      // }
      //  }, error=>{
      //  this.loading = false;
      //  this.dialogPlugin.show("information","Information","Error ocurred when trying to generate invoice, please contact your administrator!","okonly",{ok:""});
      // });
    } else {
      this.loading = false;
      this.dialogPlugin.show(
        "information",
        "Information",
        "Please select Invoice to print.",
        "okonly",
        { ok: "" }
      );
      this.disableToolbarButtons = "";
    }
  }
}
