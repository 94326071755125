<form class="ui raised segment">
    <button class="ui button" style="display:none;"></button>
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="displayToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" ></toolbar-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="param['error-vesselNo'] == null || param['error-vesselNo'] == '' || param['error-vesselNo']==undefined">
          {{param['error-vesselNo']}}
        </li>
        <li [class.hidden]="param['error-voyageNo'] == null || param['error-voyageNo'] == '' || param['error-voyageNo']==undefined">
          {{param['error-voyageNo']}}
        </li>
      </ul>
    </div>
  
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <div class="ack-content">
        <i class="bell icon ack-icon"></i>
        <div class="ack-column">
          <span class="ack-text ack-number">{{ param['totalAck'] || 0 }}</span>
          <span class="ack-text">Ack Required</span>
        </div>
      </div>
      <div class="field" style="margin-bottom: 0px">
        <!-- 1st line -->
         <div class="fields">
          <div class="four wide field" [class.error]="param['error-from'] != null && param['error-from'] != ''" >
            <label>Transmitted Date From</label>
            <input style = "width : 98%" type="date" name="from"  placeholder="Date" [(ngModel)]="param['from']" >
          </div>
          <div class="four wide field" [class.error]="param['error-to'] != null && param['error-to'] != ''" >
            <label>Transmitted Date To</label>
            <input style = "width : 98%" type="date" name="to"  placeholder="Date" [(ngModel)]="param['to']" >
          </div>
          <div class="four wide field" [class.error]="param['error-vesselNo'] != null && param['error-vesselNo'] != ''" >
            <label>Vessel</label>
            <combo-plugin
            #cbVessel
            [settings]="settingVessel"
            (change)="changeEventVessel($event)"
            (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
            (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselNo', false, 'and -./', 'Vessel')"
            (paste)="
              onPasteValidation(
                $event,
                'vesselNo',
                formValidation.regVesVoy,
                'and -./'
              )
            "
          ></combo-plugin>
            <!-- <input style = "width : 98%" type="text" name="vesselNo"  placeholder="Search" [(ngModel)]="param['vesselNo']" > -->
          </div>
          <div class="four wide field" [class.error]="param['error-voyageNo'] != null && param['error-voyageNo'] != ''" >
            <label>Voyage</label>
            <combo-plugin
            #cbVoyage
            [settings]="settingVoyage"
            (change)="changeEventVoyage($event)"
            (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
            (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageNo', false, 'and -./', 'Voyage')"
            (paste)="
              onPasteValidation(
                $event,
                'voyageNo',
                formValidation.regVesVoy,
                'and -./'
              )
            "
          ></combo-plugin>
            <!-- <input style = "width : 98%" type="text" name="voyageNo"  placeholder="Search" [(ngModel)]="param['voyageNo']" > -->
          </div>
         </div>
         <!-- 2nd line -->
         <div class="fields">
            <div class="four wide field" [class.error]="param['error-pol'] != null && param['error-pol'] != ''" >
              <label>Load Port</label>
              <combo-plugin
                #cbPOL
                [settings]="settingPOL"
                (change)="changeEventPOL($event)"
                (keypress)="formValidation.CharNumOnly($event)"
              ></combo-plugin>
              <!-- <input style = "width : 98%" type="text" name="pol"  placeholder="Port Of Loading" [(ngModel)]="param['pol']" > -->
            </div>
            <div class="four wide field" [class.error]="param['error-pot'] != null && param['error-pot'] != ''" >
              <label>Transhipment Port</label>
              <combo-plugin
                #cbPOT1
                [settings]="settingPOT1"
                (change)="changeEventPOT1($event)"
                (keypress)="formValidation.CharNumOnly($event)"
              ></combo-plugin>
              <!-- <input style = "width : 98%" type="text" name="pot"  placeholder="Port of Transhipment" [(ngModel)]="param['pot']" > -->
            </div>
            <div class="eight wide field">
              <toolbar-plugin [settings]="settingToolbar"  (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
            </div>
         </div>
      </div>
      <div class="fields">
        <div class="sixteen wide field">
          <grid-plugin id="gridHeader" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        </div>
      </div>  
    </form>
  </form>

  <div id="blToFlagStatus"  style="display:none;font-family:Lucida Console, monospace;">
    <form  class="ui form">
      <!-- form start -->
      <div class="field" style="margin-bottom: 5px;">
        <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
          {{frmDialogMessage}}</p>
      </div>
      <div class="field" style="text-align: right">
        <button class="positive ui button" (click)="onDialogYes()" >Yes</button>
        <button class="negative ui button" (click)="onDialogNo()" >No</button>
      </div>
    </form>
  </div>


<footer-plugin [info]="info"></footer-plugin>