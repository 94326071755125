<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Commodity
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Commodity
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-commodityName'] != null &&
                      model['error-commodityName'] != ''
                    "
                  >
                    <label>Commodity Name</label>
                    <input
                      type="text"
                      [(ngModel)]="model['commodityName']"
                      (ngModelChange)="model['error-commodityName'] = ''"
                      name="commodityName"
                      placeholder="Commodity Name"
                      [disabled]="lock == true"
                      maxlength="50"
                      (keypress)="
                        formValidation.ModifiedPattern($event, '-.()/ ')
                      "
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-commodityName'] != null &&
                        model['error-commodityName'] != ''
                      "
                    >
                      {{ model["error-commodityName"] }}
                    </div>
                  </div>
                </div>

                <div
                  class="field"
                  [class.error]="
                    model['error-remarks'] != null &&
                    model['error-remarks'] != ''
                  "
                >
                  <label>Remarks</label>
                  <textarea
                    [(ngModel)]="model['remarks']"
                    (ngModelChange)="model['error-remarks'] = ''"
                    name="remarks"
                    placeholder="Input your remarks..."
                    [disabled]="lock == true"
                    (keypress)="formValidation.AllChar($event)"
                    maxlength="200"
                  ></textarea>
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-remarks'] != null &&
                      model['error-remarks'] != ''
                    "
                  >
                    {{ model["error-remarks"] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Commodity
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Commodity</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateCommodityHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-commodityName'] != null &&
                      model['error-commodityName'] != ''
                    "
                  >
                    <label>Commodity Name</label>
                    <combo-plugin
                      #cbCommodityNameHistory
                      [settings]="settingCommodityHistory"
                      (change)="valueCommodityHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-commodityName'] != null &&
                        model['error-commodityName'] != ''
                      "
                    >
                      {{ model["error-commodityName"] }}
                    </div>
                  </div>

                  <!--<div class="required field" [class.error]="model['error-commodityCode'] != null && model['error-commodityCode'] != ''">
                    <label>Commodity Code</label>
                    <input type="text" [value]="valCommodityCode" name="commodityHistoryCode"  placeholder="Commodity Code" [disabled]="lock == true" maxlength="3" readonly style="background-color: lightgray">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-commodityCode'] != null && model['error-commodityCode'] != ''">
                      {{model['error-commodityCode']}}
                    </div>
                  </div>-->
                </div>
                <!--
                                            <div class="two fields">
                                              <div class="field">
                                                <label>Log Start Date</label>
                                                <calendar-plugin id="logHistoryCommodityStartDate" [pluginType]="typeCommodityLogStartDate" [settings]="settingCommodityLogStartDate"></calendar-plugin>
                                              </div>
                                              <div class="field">
                                                <label>Log End Date</label>
                                                <calendar-plugin id="logHistoryCommodityEndDate" [pluginType]="typeCommodityLogEndDate" [settings]="settingCommodityLogEndDate"></calendar-plugin>
                                              </div>
                                            </div>
                -->
                <div class="field">
                  <grid-plugin2
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin2>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
