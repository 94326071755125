import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import {
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  CookieService,
  ConfigService,
  DialogPluginComponent,
} from 'sibego-ui-library';
import { Router } from '@angular/router';
import { catchError, concatMap, finalize, switchMap } from 'rxjs/operators';
import { EMPTY, of, throwError } from 'rxjs';
import FormValidation from '../../utils/formValidation';
import { FormControl } from '@angular/forms';
declare var $: any;

interface ITransitionConfig {
  header: any;
  headerExtra: any;
  lines: any[];
  status: string;
  message: string;
}

interface IModifiedType {
  status: string;
  msg: string;
}

export class ToggleOption {
  invOfficeCode = '';
  invBlNo = '';
  invNo = '';
  // invPrintByCurrency = 'N';
  // invConvertedToSgd = 'Y';
  invUserName = '';
  invUserDeptCode = '';
  invoiceNo = '';
  addData = '';
  printByCurrency = '';
  convertToSGD = '';
  doNotConvertToSGD = '';
  blNo = '';
  invByCurr = 0;
  convSGD = 0;
  idconvNonSGD = 0;
}
const enum DocumentType {
  INVOICE = 'invoice',
  DEBIT_NOTE = 'dn',
  CREDIT_NOTE = 'cn'
}

export class InvoiceCNDNParam {
  cdDocumentType = 'invoice'; // invoice, dn, cn
  cdContainerOwnership = 'COC';

  // used by invoice UI
  cdBlNumber = '';
  cdInvoiceNumber = '';
  cdInvoicePrintedDate = '';
  cdInvoicePostedToCodaDate = '';

  cdPayerName = '';
  cdPayerId = '';
  cdPayerAcCode = '';
  cdExchangeRate = '';
  cdIsApplyThisExchangRate = 'N';

  cdVesselAcCode = '';
  cdVesselCode = '';
  cdVesselId = '';
  cdVesselName = '';
  cdVesselVoyage = '';
  cdVesselBound = '';
  cdPortOfLoading = '';
  cdPortOfDischarge = '';
  cdPrepaid1Code = '';
  cdPrepaid1Name = '';
  cdPrepaid2Code = '';
  cdPrepaid2Name = '';
  cdPrepaid3Code = '';
  cdPrepaid3Name = '';
  cdPayable1Code = '';
  cdPayable1Name = '';
  cdPayable2Code = '';
  cdPayable2Name = '';
  cdPayable3Code = '';
  cdPayable3Name = '';

  // Used by CN UI
  cdCnNumber = '';
  cdCnCreatedBy = '';
  cdCnCreatedDate = '';
  cdCnPrintedDate = '';
  cdCnPostedDate = '';
  cdCnBlNumber = '';
  cdCnInvoiceNumber = '';
  cdCnInvoicePrintedDate = '';
  cdCnInvoicePostedDate = '';
  cdCnPayerName = '';
  cdCnPayerAcCode = '';
  cdCnPayerId = '';

  // Used by CN UI
  cdDnNumber = '';
  cdDnCreatedBy = '';
  cdDnCreatedDate = '';
  cdDnPrintedDate = '';
  cdDnPostedDate = '';
  cdDnBlNumber = '';
  cdDnInvoiceNumber = '';
  cdDnInvoicePrintedDate = '';
  cdDnInvoicePostedDate = '';
  cdDnPayerId = '';
  cdDnPayerCode = '';
  cdDnPayerName = '';
  cdDnPayerAcCode = '';

  cdReasonCategory = '';
  cdReasonDescription = '';
  cdIsFullCancellationOfInvoice = 'N';
  cdIsFullCancellationOfInvoiceAndReissueNewInvoice = 'N';
  cdIsPartialCancellation = 'N';
  cdIsDebitNote = 'N';
  cdCancelExistingAutoPosting = 'N';
  invIsPrintByCurrency = '';
  invIsConvertedToSgd = '';
  invBlNo = '';
  invNo = '';

  cdPrevInvoiceNumber = '';
  cdPrevInvoicePostedToCodaDate = '';
  cdPrevInvoicePrintedDate = '';
}

export class UserDetails {
  userDeptCode = '';
  userDeptName = '';
  userFullName = '';
  userId = '';
  userName = '';
  userPhone = '';
}

export class InvoiceParam {
  invVesselCode = '';
  invVesselId = '';
  invVesselName = '';
  invVesselVoyage = '';
  invVesselBound = '';
  invPayerCode = '';
  invPayerName = '';
  invLoadPortCode = '';
  invLoadPortName = '';
  invAccCode = '';
  invTransshipmentPortCode = '';
  invTransshipmentPortName = '';
  invPlaceOfPaymentCode = '';
  invPlaceOfPaymentName = '';
  invDischargePortCode = '';
  invDischargePortName = '';
  invIsCoc = 'Y';
  invIsSoc = '';
  invIsSlot = '';
  invIsCocTs = '';
  invIsSocTs = '';
  invDeptcode = '';
  invUserName = '';
  invNumberOfPrintSet = 1;

}

@Component({
  selector: 'app-transaction-debit-credit-note-page',
  templateUrl: './transaction-debit-credit-note-page.component.html',
  styleUrls: ['./transaction-debit-credit-note-page.component.css'],
})
export class TransactionDebitCreditNotePageComponent
  extends Validator
  implements OnInit, AfterViewInit {
  // @ViewChild('gridCNDN') gridCNDN:GridPluginComponent;
  @ViewChild('cbReasonCNDN') cbReasonCNDN: ComboPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
  @ViewChild('gridPDFPrev') gridPDFPrev: GridPluginComponent;

  dialog: any;

  /* Invoice which is retrieved and being shown */
  searchedInvoice: any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = 'retrieve,ok,print';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGridCNDN;
  settingGridCNDNFooter;
  settingCbReasonCNDN;

  // lock /selected setting
  rdOptInvoice = 'Y';
  rdOptDebitNoteReissued = 'N';
  rdOptDebitNote = 'N';
  rdOptCreditNote = 'N';

  rdCoc = 'N';
  rdSoc = 'N';

  rdOptCNDN1 = 'N';
  rdOptCNDN2 = 'N';
  rdOptCNDN3 = 'N';
  rdOptCNDN4 = 'N';
  chkOptCNDN4 = 'N';
  chkOptCNDN9 = 'N';


  gridApi;
  rowDataCNDN2;
  pinnedBottomRowDataCNDN;
  rowSelection;
  getRowStyle;

  columnDefsCNDN;

  labelToolbar = 'Debit/Credit Note Facility';

  model = new InvoiceCNDNParam();
  userDetails = new UserDetails();
  invoiceParam = new InvoiceParam();

  validatorRules = {};
  components: { showCellRenderer: () => void; };
  defaultColDef: { resizable: boolean; width: number; };

  rowDataCNDN = [];
  rawData: ITransitionConfig;
  // Form Validation
  formValidation = new FormValidation();

  financialReason = '';
  reasonDescription = '';
  gridRowData = [];
  newDialogOpt: { title: string; modal: boolean; closeText: string; closeOnEscape: boolean; width: number; height: number; open: (event: any, ui: any) => void; close: (event: any, ui: any) => void; };
  gridApiProforma: any;
  officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
  userEmail = '';
  officeId = '';
  frmDialogMessage: string;

  typeModification = new FormControl('');
  silentMode = new FormControl([true]);
  isDisabelsilentMode = true;
  settingGridPDFPrev: {
    id: string; url: string; page: string; columns: { header: string; field: string; width: number; editType: string; }[]; buttons: any[];
    // store:  this.listStore,
    enableSorting: boolean; enableSelectAll: boolean; enablePagination: boolean; sortingColumns: string; sortingDirection: string; editable: boolean; height: number; minHeight: number; autoSize: string;
  };
  userId: string;
  hitParamList = [];

  messageErrorExchangeRate = '';
  currentTotalChargeAmount: number;
  isRetrieveSuccess: boolean;
  haveCN: boolean;
  isDisabelByCreditNote = false;
  invStatus = "";

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private cookieService: CookieService,
    private http: HttpClient,
    private configService: ConfigService
  ) {
    super();

    const officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? '*'
        : this.cookieService.getDefaultLocationCode();

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Retrieve', event: 'retrieve', icon: 'search' },
        { name: 'Save', event: 'save', icon: 'save' },
        { name: 'Print', event: 'print', icon: 'file outline' },
        { name: 'Cancel', event: 'cancel', icon: 'cancel' },
        { name: 'Close', event: 'close', icon: 'power' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar,
    };

    this.settingGridCNDN = {
      id: 'gridCNDN',
      url: '',
      page: 10,
      columns: [
        { header: 'Per', field: 'no', editType: 'text', width: 100 },
        {
          header: 'Charge Code',
          field: 'chargeName',
          editType: 'text',
          width: 100,
          type: 'string',
        },
        {
          header: 'Charge Desc',
          field: 'containerType',
          editType: 'text',
          width: 200,
          type: 'string',
        },
        {
          header: 'New Charge Desc',
          field: 'currencyCode',
          editType: 'text',
          width: 200,
          type: 'string',
        },
        { header: 'Quantity', field: 'D20Amt', editType: 'number', width: 100 },
        {
          header: 'New Quantity',
          field: 'H20Amt',
          editType: 'number',
          width: 100,
        },
        { header: 'Rate', field: 'D40Amt', editType: 'number', width: 100 },
        { header: 'New Rate', field: 'H40Amt', editType: 'number', width: 100 },
        { header: 'Amount', field: 'D45Amt', editType: 'number', width: 100 },
        {
          header: 'New Amount',
          field: 'H45Amt',
          editType: 'number',
          width: 100,
        },
        { header: 'C/D', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'P/C', field: 'H45Amt', editType: 'number', width: 75 },
        { header: 'New P/C', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'Payer', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'New Payer', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'POP', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'New POP', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'Currency', field: 'D45Amt', editType: 'number', width: 75 },
        {
          header: 'New Currency',
          field: 'D45Amt',
          editType: 'number',
          width: 75,
        },
        { header: 'ExR', field: 'D45Amt', editType: 'number', width: 75 },
        { header: 'New ExR', field: 'D45Amt', editType: 'number', width: 75 },
      ],
      buttons: [{ name: 'Edit', event: 'edit', enabled: false }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
    };

    this.settingGridCNDNFooter = {
      id: 'gridCNDNFooter',
      url: '',
      page: 10,
      columns: [
        {
          header: 'Action(s) Performed',
          field: 'no',
          editType: 'text',
          width: 100,
        },
        {
          header: 'Document No',
          field: 'chargeName',
          editType: 'text',
          width: 100,
        },
        {
          header: 'Document Amt',
          field: 'containerType',
          editType: 'text',
          width: 200,
        },
      ],
      height: 80,
      buttons: [{ name: 'Edit', event: 'edit', enabled: false }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
    };

    this.settingCbReasonCNDN = {
      id: 'cbReasonCNDN',
      type: 'select', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReason/getReducedList/' + officeCode,
      placeholder: '',
      code: 'reasonId',
      title: 'reasonName',
      remarks: 'remarks'
    };

    this.columnDefsCNDN = [
      {
        headerName: 'Seq', field: 'index', editType: 'text', width: 70, cellRenderer: 'showCellRenderer',
        cellClassRules: {
          'cell-span': 'true',
          'check-show': (params) => !params.data.isParent,
        },
        rowSpan: (params) => params.data.isParent ? params.data.lengthRoot : 1
},
      {
        headerName: 'Date',
        field: 'date',
        editType: 'text',
        width: 200,
        type: 'string',
        cellRenderer: 'showCellRenderer',
        cellClassRules: {
          'cell-span': 'true',
          'check-show': (params) => !params.data.isParent,
        },
        rowSpan: (params) => params.data.isParent ? params.data.lengthRoot : 1
      },
      {
        headerName: 'Charge Desc',
        field: 'invlChargeName',
        editType: 'text',
        width: 200,
        type: 'string',
      },
      {
        headerName: 'Charge Code',
        field: 'invlChargeCode',
        editType: 'text',
        width: 120,
        type: 'string',
      },
      {
        headerName: 'Unit(s)',
        field: 'invlQty',
        editType: 'number',
        width: 80,
      },
      {
        headerName: 'Currency',
        field: 'invlCurrency',
        editType: 'string',
        width: 100,
      },
      { headerName: 'Rate', field: 'invlRate', editType: 'number', width: 100 },
      {
        headerName: 'Per',
        field: 'invlPerCode',
        editType: 'string',
        width: 100,
      },
      { headerName: 'Amount', field: 'invlAmount', editType: 'number', width: 100 },
      {
        headerName: 'GST(%)',
        field: 'gst',
        editType: 'number',
        width: 100,
      },
      { headerName: 'Total Amount', field: 'totalAmount', editType: 'number', width: 120 },
      { headerName: 'P/C', field: 'invlPc', editType: 'number', width: 100 },
      // { headerName: 'Payer', field: 'D45Amt', editType: 'number', width: 100 },
      { headerName: 'POP', field: 'invlPop', editType: 'string', width: 100 },
      {
        headerName: 'Ex. Rate',
        field: 'invlExchangeRateAsString',
        editType: 'number',
        width: 100,
      },
      {
        headerName: 'Inv By Curr.',
        field: 'invIsPrintByCurrency',
        editType: 'number',
        width: 120,
      },
      { headerName: 'Do not convert to SGD', field: 'invIsConvertedToSgd', editType: 'number', width: 180 },
      { headerName: 'CN/DN #', field: 'cnDnListAsString', editType: 'number', width: 100 },
      { headerName: 'CNDN', field: 'hasCnDn', editType: 'number', width: 100 },
      { headerName: 'RS', field: 'invIsTransmittedToReceiptSystem', editType: 'number', width: 100 },
      { headerName: 'CODA', field: 'invIsTransmittedToCoda', editType: 'number', width: 100 },
    ];

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
    };

    // this.pinnedBottomRowDataCNDN = this.rowDataCNDN2;

    this.defaultColDef = {
      resizable: true,
      width: 170,
    };

    this.settingGridPDFPrev = {
      id: 'gridPDFPrev',
      url: '',
      page: '10',
      columns: [
        { header: 'Invoice No', field: 'invoiceNo', width: 250, editType: 'text' },
      ],
      buttons: [
      ],
      // store:  this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
      editable: false,
      height: 500,
      minHeight: 500,
      autoSize: 'auto'
    };
  }
  ngOnInit() {
    this.model = new InvoiceCNDNParam();
    this.components = { showCellRenderer: this.createShowCellRenderer() };
    console.log('this.financialReason', this.financialReason);
  }

  ngAfterViewInit() {
    this.disableToolbarButtons = "save,print";
    $('.menu .item').tab();

    // Invoice setup related
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getUserDetails/' +
      this.cookieService.getName().toUpperCase()).subscribe((resp) => {
        if (resp.json() !== null) {
          this.userDetails.userId = resp.json()['userId'];
          this.userDetails.userDeptCode = resp.json()['userDeptCode'];
          this.userDetails.userDeptName = resp.json()['userDeptName'];
          this.userDetails.userPhone = resp.json()['userPhone'];
          this.userDetails.userFullName = resp.json()['userFullName'];
          this.userDetails.userName = resp.json()['userName'];

          // setup radio button on load
          this.setBound();

          this.invoiceParam.invUserName = this.userDetails.userName;
          this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
        }
      });
    this.userEmail = localStorage.getItem('logged').split('|')[1];
    this.officeId = (this.cookieService.getDefaultLocationId() == null) ? '*' : this.cookieService.getDefaultLocationId();
    this.userId = this.cookieService.getName();
  }

  setBound() {
    if (this.userDetails.userDeptCode === '') {
      $('#selBoundO').prop('checked', false);
      $('#selBoundI').prop('checked', true);
      this.invoiceParam.invVesselBound = 'I';
    } else {
      if (this.userDetails.userDeptCode.substr(0, 1) === 'I') {
        $('#selBoundO').prop('checked', false);
        $('#selBoundI').prop('checked', true);
        this.invoiceParam.invVesselBound = 'I';
      } else {
        $('#selBoundO').prop('checked', true);
        $('#selBoundI').prop('checked', false);
        this.invoiceParam.invVesselBound = 'O';
      }
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve':
        this.handleRetrieve();
        break;
      case 'save':
        this.handleSave();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event: any) {
    if(event != ''){
      eval(event);
    }
  }


  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  // CHANGE EVENT GOES HERE
  changeEventRdOptInvoice(event) {
    if (event.target.checked) {
      this.resetForm();
      this.model.cdDocumentType = 'invoice';
      this.disableToolbarButtons = 'print,save';
      this.isDisabelByCreditNote = false;
      console.log(this.model);
    }
  }

  changeEventRdOptDebitNote(event) {
    if (event.target.checked) {
      this.resetForm();
      this.model.cdDocumentType = 'dn';
      this.disableToolbarButtons = 'print,save';
      this.isDisabelByCreditNote = false;
      console.log(this.model);
    }
  }

  changeEventRdOptCreditNote(event) {
    if (event.target.checked) {
      this.resetForm();
      this.model.cdDocumentType = 'cn';
      this.disableToolbarButtons = 'print,save';
      //GSO-914 disabled reason and type of modification when view document type is Credit note
      this.isDisabelByCreditNote = true;
      console.log(this.model);
    }
  }

  changeEventCoc(event) {
    if (event.target.value.toString().trim() === 'coc') {
      this.rdCoc = 'Y';
      this.rdSoc = 'N';
    }
  }
  changeEventSoc(event) {
    if (event.target.value.toString().trim() === 'soc') {
      this.rdCoc = 'N';
      this.rdSoc = 'Y';
    }
  }

  changeEventApplyInvoiceExchangeRate(event) {
    if (event.target.checked) {
      this.model.cdIsApplyThisExchangRate = 'Y';
    }
  }

  // Tabular
  onOldInvoicePrepaid() {
    // console.log('eeq');
  }
  onOldInvoicePayable() {}
  onOldInvoicePayer() {}

  onNewInvoicePrepaid() {
    // console.log('eeq');
  }
  onNewInvoicePayable() {}
  onNewInvoicePayer() {}

  // Event Grid
  eventGridCNDN(event) {}
  eventInfoGrid(event) {}
  eventGridCNDNFooter(event) {}
  eventInfoGridFooter(event) {}

  // Event Combo
  changeEventCbReasonCNDN(event) {
    // this.reasonDescription = event.remarks || '';
    this.financialReason = event.reasonName || '';
  }

  // Radio bottom event
  changeEventRdOptCNDN1(event) {}
  changeEventRdOptCNDN2(event) {}
  changeEventRdOptCNDN3(event) {}
  changeEventRdOptCNDN4(event) {}


  rowDoubleClick(event) {}
  onGridReadyCNDN(params) {
    this.gridApi = params.api;
    this.gridApi.hideOverlay();
  }
  onSelectionChanged(event) {}

  createData(count, prefix) {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push({
        USD: prefix + ' USD ' + i,
      });
    }
    return result;
  }

  getInfoInvoice() {

    const officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? '*'
        : this.cookieService.getDefaultLocationCode();

    // return this.http.get(`assets/json/invoiceSuccess.json`);
    switch (this.model.cdDocumentType) {
      case DocumentType.INVOICE:
        return this.genericService.GET(this.configService.config.BASE_API.toString() +
          `/invoice/getInvoiceForCnDn/${officeCode}/${this.model.cdInvoiceNumber.toUpperCase()}`);

      case DocumentType.DEBIT_NOTE:
        return this.genericService.GET(this.configService.config.BASE_API.toString() +
          `/invoice/getDN/${officeCode}/${this.model.cdDnNumber.toUpperCase()}`);

      case DocumentType.CREDIT_NOTE:
        return this.genericService.GET(this.configService.config.BASE_API.toString() +
          `/invoice/getCN/${officeCode}/${this.model.cdCnNumber.toUpperCase()}`);
    }
  }

  handleBindingData(data) {
    const self = this;

    this.model.cdBlNumber = data.header.invBlNo;
    this.model.cdInvoicePrintedDate = data.headerExtra.invFirstPrintedDate;
    this.model.cdInvoicePostedToCodaDate = data.headerExtra.invFirstPostedToCodaDate;
    this.haveCN = data.headerExtra.haveCN;
    // this.model.cdDnNumber = data.header.invBlNo;
    // this.model.cdDnCreatedBy = data.header.invBlNo;
    this.model.cdPayerName = data.header.invPayerName;
    this.model.cdPayerAcCode = data.header.invPayerAccCode;
    this.model.cdVesselAcCode = data.header.invVesselAcCode;
    this.model.cdVesselVoyage = data.header.invVesselVoyage;
    this.model.cdVesselCode = data.header.invVesselCode;
    this.model.cdVesselName = data.header.invVesselName;
    this.model.cdPortOfLoading = data.header.invPortOfLoadingName;
    this.model.cdPortOfDischarge = data.header.invPortOfDischargeName;
    this.model.cdPrepaid1Name = data.headerExtra.blPrepaidAt1Name;
    this.model.cdPrepaid2Name = data.headerExtra.blPrepaidAt2Name;
    this.model.cdPrepaid3Name = data.headerExtra.blPrepaidAt3Name;
    this.model.cdPayable1Name = data.headerExtra.blPayableAt1Name;
    this.model.cdPayable2Name = data.headerExtra.blPayableAt2Name;
    this.model.cdPayable3Name = data.headerExtra.blPayableAt3Name;
    this.model.invIsPrintByCurrency = data.header.invIsPrintByCurrency;
    this.model.invIsConvertedToSgd = data.header.invIsConvertedToSgd;
    this.model.invNo = data.header.invNo;

    this.model.cdPrevInvoiceNumber = data.headerExtra.cancelledInvoiceNumber;
    this.model.cdPrevInvoicePrintedDate = data.headerExtra.cancelledInvoicePrintedDate;
    this.model.cdPrevInvoicePostedToCodaDate = data.headerExtra.cancelledInvoicePostToCodaDate;

    // Reason (Finance)
    // this.cbReasonCNDN.setValueById(data.header.invFinancialReason || '');
    this.cbReasonCNDN.loadSelect(function () {
      self.cbReasonCNDN.setValue(data.header.invFinancialReason || '');
    });

    // Reason
    this.reasonDescription = data.header.invReason;
    this.rdSoc = data.header.invContainerOwnership === 'SOC' || data.header.invContainerOwnership === 'SOCTS' ? 'Y' : '';
    this.rdCoc = data.header.invContainerOwnership === 'COC' || data.header.invContainerOwnership === 'COCTS' ? 'Y' : '';

    this.model.cdCnCreatedBy = this.model.cdDnCreatedBy = data.header.invCreatedUserid;
    this.model.cdDnCreatedDate = this.model.cdCnCreatedDate = data.header.invDateCreated;
    this.model.cdDnPrintedDate = this.model.cdCnPrintedDate = data.headerExtra.cnPrintedDate;
    this.model.cdDnPostedDate = this.model.cdCnPostedDate = data.headerExtra.invFirstPostedToCodaDate;

    // Exchange rate:
    this.model.cdExchangeRate = data.headerExtra.exchangeRate;

    this.currentTotalChargeAmount = !data.lines || data.lines.length === 0 ? 0 : data.headerExtra.currentTotalChargeAmount;
    // Calculate total

    this.calculateTotal(data.lines);
    /*
      GSO-913: hard code
      Types of Modification select 'Full Cancellation of Invoice' when
      - api returns haveCN = true
      - Retrieve by Credit Note
    */
    if(data.headerExtra.haveCN || this.model.cdDocumentType === 'cn'){
      this.model['cdIsFullCancellationOfInvoice'] = 'Y';
    }else{
      this.typeModification.setValue('');
    }
  }

  calculateTotal(printedTimes) {

    const charges = printedTimes.map(t => t.charges).flatMap(c => c);

    const currencies = charges.map(c => c.invlCurrency)
      .filter((value, index, self) => self.indexOf(value) === index );

    this.pinnedBottomRowDataCNDN = [];
    currencies.forEach(currency => {

      const properCharges = charges.filter(c => c.invlCurrency === currency);

      const invlRate = properCharges.map(c => c.invlRate).reduce((partialSum, a) => partialSum + a, 0);
      const invlAmount = properCharges.map(c => c.invlAmount).reduce((partialSum, a) => partialSum + a, 0);
      const totalAmount = properCharges.map(c => c.totalAmount).reduce((partialSum, a) => partialSum + a, 0);

      this.pinnedBottomRowDataCNDN.push({
        invlChargeName: 'TOTAL (' + (currency || '').toUpperCase() + ')',
        invlRate: this.formatCurrency(invlRate),
        invlAmount: this.formatCurrency(invlAmount),
        totalAmount: this.formatCurrency(totalAmount),
      });
    });
  }

  formatRowDataCNDN(data = []) {
    const arr = [];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].charges.length; j++) {
        if (data.length > 1) {
          arr.push({
            ...data[i],
            ...data[i].charges[j],
            charges: [],
            isParent: data[i].charges.length > 0 && j === 0,
            lengthRoot: data[i].charges.length,
            index: data.length - i,
            // Only show fields with 'Y' value:
            invIsPrintByCurrency: data[i].charges[j].invIsPrintByCurrency === 'Y' ? data[i].charges[j].invIsPrintByCurrency : '',
            invIsConvertedToSgd: data[i].charges[j].invIsConvertedToSgd === 'Y' ? data[i].charges[j].invIsConvertedToSgd : '',
            invIsTransmittedToReceiptSystem: data[i].charges[j].invIsTransmittedToReceiptSystem === 'Y' ? data[i].charges[j].invIsTransmittedToReceiptSystem : '',
            invIsTransmittedToCoda: (i == 0  && data[i].charges[j].hasCnDn !== 'Y') ? '': data[i].charges[j].invIsTransmittedToCoda === 'Y' ? data[i].charges[j].invIsTransmittedToCoda : '',
            cnDnListAsString: data[i].charges[j].cnDnListAsString || '',
            hasCnDn: data[i].charges[j].hasCnDn === 'Y' ? data[i].charges[j].hasCnDn : '',
            gst: data[i].charges[j].gst || "-"
          });
        } else {
          arr.push({
            ...data[i],
            ...data[i].charges[j],
            charges: [],
            isParent: data[i].charges.length > 0 && j === 0,
            lengthRoot: data[i].charges.length,
            index: data.length - i,
            // Only show fields with 'Y' value:
            invIsPrintByCurrency: data[i].charges[j].invIsPrintByCurrency === 'Y' ? data[i].charges[j].invIsPrintByCurrency : '',
            invIsConvertedToSgd: data[i].charges[j].invIsConvertedToSgd === 'Y' ? data[i].charges[j].invIsConvertedToSgd : '',
            invIsTransmittedToReceiptSystem: data[i].charges[j].invIsTransmittedToReceiptSystem === 'Y' ? data[i].charges[j].invIsTransmittedToReceiptSystem : '',
            invIsTransmittedToCoda: data[i].charges[j].invIsTransmittedToCoda === 'Y' ? data[i].charges[j].invIsTransmittedToCoda : '',
            cnDnListAsString: data[i].charges[j].cnDnListAsString || '',
            hasCnDn: data[i].charges[j].hasCnDn === 'Y' ? data[i].charges[j].hasCnDn : '',
            gst: data[i].charges[j].gst || "-"
          });
        }

      }
    }
    return arr;
  }

  createShowCellRenderer() {
    function ShowCellRenderer() { }
    ShowCellRenderer.prototype.init = function (params) {
      var cellBlank = !params.value;
      if (cellBlank) {
        return null;
      }
      this.ui = document.createElement('div');
      const heightRowTable = 28;
      this.ui.innerHTML =
        `<div style="height: ${heightRowTable * params.data.lengthRoot}px; padding: 0px !impotarnt" class="show-name ${!params.data.isParent ? 'check-show' : ''} ${params.data.isParent ? 'show-cell' : ''}">${params.value}</div>`;
    };
    ShowCellRenderer.prototype.getGui = function () {
      return this.ui;
    };
    return ShowCellRenderer;
  }

   // * Field Validation On Paste
   onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = '',
    acctDebtorCode?: string
  ) {
    // let clipboardData = acctDebtorCode ? '' : event.clipboardData;
    // let pastedText = acctDebtorCode ? event : clipboardData.getData("text");
    this.showErrorMessage(regexName, event, field, isAlphabet, validSPChar);
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar) {
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  handleRetrieve() {
    if (this.handleValidateDocumentTypeActive()) {
      return ;
    };
    this.gridApi.showLoadingOverlay();
    this.loading = true;

    this.getInfoInvoice().pipe(
      switchMap((resp: any) => {
        if (resp.ok) {
          const dt: ITransitionConfig = resp.json();
          if (dt.status === 'ok') {
            this.rowDataCNDN = this.formatRowDataCNDN(dt.lines);
            this.handleBindingData(dt);
            this.searchedInvoice = dt;
            this.invStatus =  dt.header.invStatus;
            if(this.model.cdDocumentType === 'cn' || this.model.cdDocumentType === 'dn'){
              this.disableToolbarButtons = 'save';
              this.isDisabelsilentMode = false;
            } else {
              if(this.invStatus === "C" && this.model.cdDocumentType === 'invoice'){
                this.isDisabelsilentMode = false;
                this.disableToolbarButtons = this.haveCN ? "save" : "";
              }else{
                this.isDisabelsilentMode = true;
                this.disableToolbarButtons = this.haveCN ? "save,print" : "print";
              }

              // if is retrieving by invoice and no changes detected => disable print button also
              if (!dt.lines || dt.lines.length === 0) {
                this.disableToolbarButtons = 'save';
              }
            }
            this.isRetrieveSuccess = this.model['cdDocumentType'] == 'invoice' ? false : true;
            return of(dt);
          } else {
            this.showModalMessage(dt.message);
            this.resetForm(true);
            this.isRetrieveSuccess = false;
            return of(null);
          }
        } else {
          this.isRetrieveSuccess = false;
          this.showModalMessage('Error occurred!');
          return of(null);
        }
      }),
      catchError(err => {
        this.showModalMessage('Error occurred!');
        return throwError(err);
      }),
      finalize(() => {
        this.loading = false;
        console.log(this.disableToolbarButtons);
      })
    ).subscribe(
      // (res) => { this.rowDataCNDN2 = res }
    );

    // this.rowDataCNDN2.push({ no: 'Total USD', Amount: 0 });
    // this.pinnedBottomRowDataCNDN = this.rowDataCNDN2;
    // this.gridApi.update
    this.gridApi.hideOverlay();
  }

  handleSave() {
    if (this.model['cdInvoiceNumber'] === '' || this.model['error-invoiceNumber'] != null && this.model['error-invoiceNumber'] != '') {
      this.model[`error-invoiceNumber`] = `Invoice Number is invalid`;
      return;
    }

    if (!this.typeModification.value) {
      this.dialogPlugin.show('information', 'Information', 'Please choose Types of Modification.', 'okonly', {});
      return;
    }

    switch (this.typeModification.value) {
      case 'cdIsFullCancellationOfInvoice':
      case 'cdIsDebitNote':
        break;
      default:
        this.dialogPlugin.show('information', 'Information', 'There is no logic binding with this option.', 'okonly', {});
        return;
    }


    this.loading = true;
    this.postSave(this.model['cdInvoiceNumber']);
    /*
    this.postSave(this.model['cdInvoiceNumber']).pipe(
      switchMap((obj: IModifiedType) => {

        /*
        if (obj.status === 'ok') {
          this.showModalMessage(obj.msg);
          return of(obj);
        } else {
          this.showModalMessage(obj.msg);
          return of(null);
        }

        return of(obj);
      }),
      catchError(err => {
        this.showModalMessage('Error occurred!');
        return throwError(err);
      }),
      finalize(() => this.loading = false)
    ).subscribe()
    */
  }

  postSave(invNo) {
    console.log('masuk yang sini')

    /* Temporarily comment out this section due to this logic is not documented yet.
    if (!this.cbReasonCNDN.getValue()) {
      this.showModalMessage('Please select "Reason" first!');
      return;
    }
    */

    const officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? '*'
        : this.cookieService.getDefaultLocationCode();

    const userId = this.cookieService.getName();

    // return this.http.get(this.configService.config.BASE_API.toString() + `/invoice/getInvoiceForCnDn/${officeCode}/${this.model.cdInvoiceNumber.toUpperCase()}`);
    switch (this.typeModification.value) {
      case 'cdIsFullCancellationOfInvoice':
        var self = this;
        this.genericService.POST(
          this.configService.config.BASE_API.toString() + `/invoice/createCN`,
          {
            "invOfficeCode": officeCode,
            // "invBLNo": "",
            "invNo": invNo.toUpperCase().trim(),
            "financialReason": this.financialReason,
            "userId": userId.toUpperCase().trim()
          }
        ).subscribe((resp) => {
          if(resp.ok) {
            if (resp.json().status == 'ok') {
              self.showModalMessage(resp.json().msg);
              if (this.model.cdDocumentType === 'invoice') {
                this.disableToolbarButtons = "save,print";
              } else {
                this.disableToolbarButtons = "save";
              }
            } else { this.showModalMessage(resp.json().msg); }
          } else {this.showModalMessage('Error occurred!'); }

        });
        break;
      case 'cdIsDebitNote':
        var self = this;
        this.genericService.POST(
          this.configService.config.BASE_API.toString() + `/invoice/createDN`,
          {
            "invOfficeCode": officeCode,
            // "invBLNo": "",
            "invNo": invNo.toUpperCase().trim(),
            "financialReason": this.financialReason,
            "userId": userId.toUpperCase().trim()
          }
        ).subscribe((resp) => {
          console.log(resp.json())
          if(resp.ok) {
            if (resp.json().status == 'ok') {
              self.showModalMessage(resp.json().msg);
            } else {this.showModalMessage('Error occurred!'); }
          } else {this.showModalMessage('Error occurred!'); }
        });
        break;
    }
  }

  showModalMessage(message: string) {
    this.dialogPlugin.show('information', 'Information', message, 'okonly', { ok: 'this.loading=false;' });
  }

  handleValidateDocumentTypeActive() {
    this.model[`error-invoiceNumber`] = '';
    this.model[`error-cdDnNumber`] = '';
    this.model[`error-cdCnNumber`] = '';

    const arr = [
      {
        type: DocumentType.INVOICE,
        conditions: this.model['cdInvoiceNumber'] === '' || this.model['error-invoiceNumber'] != null && this.model['error-invoiceNumber'] != '',
        errorDes: "Invoice Number is invalid",
        field: "error-invoiceNumber"
      },
      {
        type: DocumentType.DEBIT_NOTE,
        conditions: this.model['cdDnNumber'] === '' || this.model['error-cdDnNumber'] != null && this.model['error-cdDnNumber'] != '',
        errorDes: "Debit Note Number is invalid",
        field: `error-cdDnNumber`
      },
      {
        type: DocumentType.CREDIT_NOTE,
        conditions: this.model['cdCnNumber'] === '' || this.model['error-cdCnNumber'] != null && this.model['error-cdCnNumber'] != '',
        errorDes: "Credit Note Number is invalid",
        field: "error-cdCnNumber"
      },
    ]
    const documentType = arr.find(e => e.type === this.model.cdDocumentType);
    if (documentType.conditions) {
      this.model[documentType.field] = documentType.errorDes;
    }
    return documentType.conditions ? true : false;
  }

  resetForm(doNotResetType?: boolean) {
    let documentType = doNotResetType ? this.model['cdDocumentType'] : 'invoice';
    this.model = new InvoiceCNDNParam();
    this.model['cdDocumentType'] = documentType;
    this.rowDataCNDN = [];
    this.pinnedBottomRowDataCNDN = [];
    this.cbReasonCNDN.setValue("");
    this.reasonDescription = '';
    this.typeModification.setValue('');
    this.rdCoc = 'N';
    this.rdSoc = 'N';
    this.isDisabelsilentMode = true;
  }

  handlePrint() {
    //Hit mighty Ok Check
    // if (!this.isRetrieveSuccess) {
    //   return;
    // }
    this.hitParamList = [];
    const rowDt = new ToggleOption();
    rowDt.invBlNo = this.model.invBlNo;
    if (this.model.cdDocumentType === 'cn') {
      rowDt.invNo = this.model.cdCnNumber;
      this.settingGridPDFPrev.columns[0].header = "CN No";
    } else if (this.model.cdDocumentType === 'dn') {
      rowDt.invNo = this.model.cdDnNumber;
      this.settingGridPDFPrev.columns[0].header = "DN No";
    } else {
      rowDt.invNo = this.model.invNo;
      this.settingGridPDFPrev.columns[0].header = "Invoice No";
    }

    rowDt.blNo = 'SSLSGLCHSAA5081';
    // rowDt.invConvertedToSgd = this.model.invIsConvertedToSgd;
    // rowDt.invOfficeCode = this.model.invOfficeCode;
    // rowDt.invPrintByCurrency = this.model.invPrintByCurrency;
    // rowDt.invUserDeptCode = this.model.invUserDeptCode;
    // rowDt.invUserName = '';
    rowDt.invByCurr = this.model.invIsPrintByCurrency === 'Y' ? 1 : 0;
    rowDt.convSGD = this.model.invIsConvertedToSgd === 'Y' ? 1 : 0;
    rowDt.idconvNonSGD = rowDt.convSGD ? 0 : 1;

    this.hitParamList.push(rowDt);

    this.loading = true;
    if (this.silentMode.value) {

      /* GOS-681 To silent print via Backend service (no direct calling to print service from UI)
      check for user spooler first, whether already been configured or not
      const pData = {};
      pData['userEmail'] = this.userEmail;
      pData['userLocationId'] = this.officeId;
      pData['userDocumentType'] = 'PROFORMAINVOICE';
      this.genericService.POST(this.configService.config.BASE_API.toString() +
        '/MasterUsers/checkSpoolers', pData).subscribe((resp) => {
          if (resp.ok) {
            if (resp.json().status.toString() === 'ok') {
              console.log('spooler debug');
              console.log(resp.json());
              this.loading = true;
              this.frmDialogMessage = 'This action will print the document to the nearest printer?';
              this.genericUtil.showDialog(
                'invDialogSilentPrintYesNo',
                'Confirm',
                350,
                150
              );
            } else {
              this.loading = true;
              this.dialogPlugin.show('information', 'Information',
                'Please Ask Administrator to Register Printer Spooler For Your User Id', 'okonly', { ok: 'this.loading=false;' });
            }
          } else {
            this.loading = true;
            this.dialogPlugin.show('information', 'Information',
              'API Call error. Please contact support team.', 'okonly', { ok: 'this.loading=false;' });
          }
        });
      */

      this.frmDialogMessage = 'This action will print the document to the nearest printer?';
      this.genericUtil.showDialog(
        'invDialogSilentPrintYesNo',
        'Confirm',
        350,
        150
      );

      // this.dialogPlugin.show('information', 'Information',
      // 'Silent print not yet supported at the moment.', 'okonly', {ok: 'this.loading=false;'});
    } else {
      this.gridRowData = [];
      this.gridPDFPrev.listStore.store = [];

      if (this.model.cdDocumentType === 'cn') {
        this.gridPDFPrev.listStore.addData(
          {
            'invoiceNo': this.hitParamList[0].invNo, 'blNo': this.hitParamList[0].blNo, 'printByCurrency': this.hitParamList[0].invByCurr,
            'convertToSGD': this.hitParamList[0].convSGD, 'doNotConvertToSGD': this.hitParamList[0].idconvNonSGD
          }
        );
      } else if (this.model.cdDocumentType === 'dn') {
        // fix me dn should be handled differently coz in the form uses diff field
        this.gridPDFPrev.listStore.addData(
          {
            'invoiceNo': this.hitParamList[0].invNo, 'blNo': this.hitParamList[0].blNo, 'printByCurrency': this.hitParamList[0].invByCurr,
            'convertToSGD': this.hitParamList[0].convSGD, 'doNotConvertToSGD': this.hitParamList[0].idconvNonSGD
          }
        );
      }else{
        this.gridPDFPrev.listStore.addData(
          {
            'invoiceNo': this.hitParamList[0].invNo, 'blNo': this.hitParamList[0].blNo, 'printByCurrency': this.hitParamList[0].invByCurr,
            'convertToSGD': this.hitParamList[0].convSGD, 'doNotConvertToSGD': this.hitParamList[0].idconvNonSGD
          }
        );
      }

      this.gridPDFPrev.loadData();
      this.loading = false;
      this.showPDF();
    }
  }

  handleCancel(){
    this.resetForm();
    this.model.cdDocumentType = 'invoice';
    this.disableToolbarButtons = 'print,save';
    this.silentMode = new FormControl([true]);
    this.isDisabelByCreditNote = false;
  }

  showPDF() {
    const self = this;

    this.newDialogOpt = {
      title: 'PDF Preview',
      modal: true,
      closeText: 'hide',
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function (event, ui) {
        // hide close button.
        $('.ui-dialog').css('z-index', 103);
        $('.ui-widget-overlay').css('z-index', 102);

        $('body').first().css('overflow', 'hidden');
      },
      close: function (event, ui) {
        $('body').first().css('overflow', ' auto');
        self.clearPDFPlugin();
      }
    };

    this.showDialog('pdfContainerNewInvoice', this.newDialogOpt);
  }


  clearPDFPlugin() {
    this.gridPDFPrev.listStore.store.splice(0, this.gridPDFPrev.listStore.store.length);
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-label').show();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-grid-container').show();
    this.gridPDFPrev.clearSelectedValues();
    this.closeDialog('pdfContainerNewInvoice');
  }

  showDialog(id: string, options: any) {
    $('#' + id).dialog(options).dialog('open');
  }

  closeDialog(id: string) {
    $('#' + id).dialog('close');
    $('#' + id).dialog('destroy');
  }

  gridEventPDFPrev(event) {
    console.log(event);
    switch (event.split('.')[0]) {
      case 'selected':
        this.selectedEvent(event);
        break;
      case 'click':
        this.selectedEvent(event);
        break;
      default:
        break;
    }
  }

  selectedEvent(event) {
    //// // // console.log(event);
    let no = 0;
    const opt = (event.split('.').length > 1 ? event.split('.')[1].split('-')[0] : event);
    const opt2 = (event.split('.').length > 1 ? event.split('.')[1].split('-')[1] : event);
    console.log('OPT ' + opt);

    switch (opt) {
      case 'checked':
        no = opt2;
        this.selectedListMod(no, this.gridPDFPrev, 'no');
        this.gridPDFPrev.listStore.store.forEach(fe => {
          if (fe['no'] == no) {

            const printByCurrency = fe['printByCurrency'] > 0 ? 'Y' : 'N';
            const convertToSGD = fe['convertToSGD'] > 0 ? 'Y' : 'N';
            const doNotConvertToSGD = fe['doNotConvertToSGD'] > 0 ? 'Y' : 'N';
            let conversionOption = 'N';

            if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
              conversionOption = 'Y';
            } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
              conversionOption = 'N';
            } else {
              conversionOption = 'X';
            }

            const params = {
              blOfficeCode: this.officeCode,
              blNo: this.model.cdBlNumber,
              cndnNoteNumber: fe['invoiceNo'],
              invNo: fe['invoiceNo'],
              userId: this.userDetails.userName,
              printByCurrency: printByCurrency,
              convertToSGD: conversionOption
            }
             // GSO-969 To enable printing of CANCELLED Invoice (PDF & Silent Print)
            let hitUrl = '';

            if (this.model.cdDocumentType === 'invoice') {
              if (this.invStatus === "C") {
                hitUrl = this.configService.config.BASE_API.toString() + '/invoice/getPDFcancelledINV';
                this.genericService.POST(hitUrl, params).subscribe((resp) => {
                  if (resp.ok) {
                    this.downloadInvoive(fe, resp)
                  }
                });
              } else {
                this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getPDFActual/' + this.officeCode + '/' +
                  this.model.cdBlNumber + '/' + fe['invoiceNo'] + '/' + this.userDetails.userName + '/' + printByCurrency + '/' + conversionOption).subscribe((resp) => {
                    if (resp.ok) {
                      this.downloadInvoive(fe, resp)
                    }
                  });
              }
            } else {
              if (this.model.cdDocumentType === 'cn') {
                hitUrl = this.configService.config.BASE_API.toString() + '/invoice/getPDFCN/';
              } else {
                hitUrl = this.configService.config.BASE_API.toString() + '/invoice/getPDFDN/';
              }
              this.genericService.POST(hitUrl, params).subscribe((resp) => {
                if (resp.ok) {
                  this.downloadInvoive(fe, resp)
                }
              });
            }
          }
        });

        break;
      case 'unchecked':
        no = opt2;
        break;
    }
  }


  downloadInvoive(fe,resp){
    if(resp._body != "nok"){
      fe['pdfUrl'] = this.configService.config.pdfUrl + '/download/invoice/' + resp._body;
      $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
      $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').append('<object id="o" data="' + fe['pdfUrl'] + '" type="application/pdf" width="100%" height="100%"></object>');
    }else{
      this.dialogPlugin.show('Error', 'Information', "Unable Generate Invoice for FOF=0", 'okonly', {ok: ''});
      this.closeDialog('pdfContainerNewInvoice');
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach(ff => {
        if (ff[key] != value) {
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
  }

  infoGrid(event) { }

  onDialogSilentPrintYes() {
    const listOfInvoices = [];

    // construct payload to silent print
    let payload = {}; // real payload
    let printDataList = []; // used to hold the document list
    let invData = {}; // not sure what is it used for
    let printDocDetail = {};  // use to hold the document details to be printed

    const self = this;

    this.hitParamList.forEach(fe => {
      const printByCurrency = fe['invByCurr'] > 0 ? 'Y' : 'N';
      const convertToSGD = fe['convSGD'] > 0 ? 'Y' : 'N';
      const doNotConvertToSGD = fe['idconvNonSGD'] > 0 ? 'Y' : 'N';
      let conversionOption = 'N';

      if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
        conversionOption = 'Y';
      } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
        conversionOption = 'N';
      } else {
        conversionOption = 'X';
      }

      const params = {
        blOfficeCode: this.officeCode,
        blNo: this.model.cdBlNumber,
        cndnNoteNumber: fe['invNo'],
        invNo: fe['invNo'],
        userId: this.userDetails.userName,
        printByCurrency: printByCurrency,
        convertToSGD: conversionOption
      };
      let invoiceUrl = "";
      // GSO-969 To enable printing of CANCELLED Invoice (PDF & Silent Print)
      if(this.model['cdDocumentType'] === "invoice"){
        if (this.invStatus === "C"){
          invoiceUrl = '/invoice/getPDFSilentCancel';
          self.genericService.POST(self.configService.config.BASE_API.toString() + invoiceUrl, params).subscribe((resp) => {
            if (resp.ok) {
              invData = {};
              payload = {};
              printDocDetail = {};
              printDataList = [];

              invData['docNo'] = fe['invNo'];
              invData['printDoc'] = [];

              if (self.configService.config.server === 'PRODUCTION') {
                printDocDetail['docName'] = resp._body;
              } else if (self.configService.config.server === 'PRODUCTIONSTG') {
                printDocDetail['docName'] = resp._body;
              } else {
                printDocDetail['docName'] = resp._body;
              }
              printDocDetail['docAttachName'] = '';
              printDocDetail['spoolerDoc'] = 'PROFORMAINVOICE';
              printDocDetail['spoolerDocAttach'] = '';
              printDocDetail['totalPrint'] = 1;
              printDocDetail['hasAttach'] = 'N';
              printDocDetail['proreason'] = fe['reason'];
              printDocDetail['docAttachName'] = '';

              invData['printDoc'].push(printDocDetail);
              // printDataList.push(invData);
              listOfInvoices.push(invData);
              payload['printList'] = listOfInvoices;
            }
          });
        }else{
          self.genericService.GET(self.configService.config.BASE_API.toString() + '/invoice/getPDFSilentActual/' + self.officeCode + '/' +
          fe['blNo'] + '/' + fe['invNo'] + '/' + self.userDetails.userName + '/' + printByCurrency + '/' +
          conversionOption).subscribe((resp) => {
            if (resp.ok) {
              invData = {};
              payload = {};
              printDocDetail = {};
              printDataList = [];

              invData['docNo'] = fe['invNo'];
              invData['printDoc'] = [];

              if (self.configService.config.server === 'PRODUCTION') {
                printDocDetail['docName'] = resp._body;
              } else if (self.configService.config.server === 'PRODUCTIONSTG') {
                printDocDetail['docName'] = resp._body;
              } else {
                printDocDetail['docName'] = resp._body;
              }
              printDocDetail['docAttachName'] = '';
              printDocDetail['spoolerDoc'] = 'PROFORMAINVOICE';
              printDocDetail['spoolerDocAttach'] = '';
              printDocDetail['totalPrint'] = 1;
              printDocDetail['hasAttach'] = 'N';
              printDocDetail['proreason'] = fe['reason'];
              printDocDetail['docAttachName'] = '';

              invData['printDoc'].push(printDocDetail);
              // printDataList.push(invData);
              listOfInvoices.push(invData);
              payload['printList'] = listOfInvoices;
          }
        });
        }
      }else{
        invoiceUrl = '/invoice/getPDFSilentCN';
        self.genericService.POST(self.configService.config.BASE_API.toString() + invoiceUrl, params).subscribe((resp) => {
          if (resp.ok) {
            invData = {};
            payload = {};
            printDocDetail = {};
            printDataList = [];

            invData['docNo'] = fe['invNo'];
            invData['printDoc'] = [];

            if (self.configService.config.server === 'PRODUCTION') {
              printDocDetail['docName'] = resp._body;
            } else if (self.configService.config.server === 'PRODUCTIONSTG') {
              printDocDetail['docName'] = resp._body;
            } else {
              printDocDetail['docName'] = resp._body;
            }
            printDocDetail['docAttachName'] = '';
            printDocDetail['spoolerDoc'] = 'PROFORMAINVOICE';
            printDocDetail['spoolerDocAttach'] = '';
            printDocDetail['totalPrint'] = 1;
            printDocDetail['hasAttach'] = 'N';
            printDocDetail['proreason'] = fe['reason'];
            printDocDetail['docAttachName'] = '';

            invData['printDoc'].push(printDocDetail);
            // printDataList.push(invData);
            listOfInvoices.push(invData);
            payload['printList'] = listOfInvoices;
          }
        });
      }
    });

    setTimeout(() => {
      payload['userId'] = this.userId;
      payload['userEmail'] = this.userEmail;
      payload['userLocationId'] = this.officeId;
      payload['server'] = this.configService.config.server;

      const hitUrl = this.configService.config.BASE_API.toString() + '/PrintService/print';
      this.loading = true;
      this.genericService.POST(hitUrl, payload).subscribe((resp) => {
        // console.log('this is response from printserver');
        if (resp.json()['status'] === "ok") {
          this.dialogPlugin.show(
            'information',
            'Information',
            'Your document has been printed. Please check at your nearest printer to collect them.',
            'okonly', { ok: '' }
          );
          this.loading = false;
        } else {
          this.dialogPlugin.show(
            "warning",
            "Warning",
            resp.json()['message'],
            "okonly",
            { ok: "" }
          );
          this.loading = false;
        }
      }, err=>{
        this.loading = false;
      });
    }, 500);

    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
    // this.loading = true;
    // this.dialogPlugin.show('information', 'Information',
    //   'Your document has been printed. Please check at your nearest printer to collect them.', 'okonly', { ok: 'this.loading=false;' });
    // this.loading = false;
  }

  onDialogSilentPrintNo() {
    this.loading = false;
    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
  }

  onDialogInvYes(){
    this.genericUtil.closeDialog('invDialogYesNo');
    this.pinnedBottomRowDataCNDN = [];
    this.reasonDescription = '';
    this.rdCoc = 'N';
    this.rdSoc = 'N';
  }
  onDialogInvNo() {
    this.genericUtil.closeDialog('invDialogYesNo');
  }

  exchangeRateChange(event) {
    const reg4Digit4Decimal = /^\d{0,4}(?:\.\d{0,4})?$/;
    if (event && event >= 10000) {
      this.messageErrorExchangeRate = "Only accept digits less than 10000";
    }
    else if(event && reg4Digit4Decimal.test(event) === false){
      this.messageErrorExchangeRate = "Only accept 4 digit, with 4 maximum decimal point";
    }
    else {
      this.messageErrorExchangeRate = null;
    }
  }

  formatCurrency(total) {
    return Number.isInteger(total) ? total.toString(): Number(total.toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
  }
}
