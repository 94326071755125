      import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, CalendarPluginComponent, GenericUtil, DatePluginComponent,CookieService,ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $:any;

export class ReportParam{

  fromDate = '';
  toDate = '';

  reportType = 'normal';

  constructor(){}

}

@Component({
  selector: 'app-report-monthly-lifting-page',
  templateUrl: './report-monthly-lifting-page.component.html',
  styleUrls: ['./report-monthly-lifting-page.component.css']
})
export class ReportMonthlyLiftingPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('etaFrom') etaFrom:DatePluginComponent;
  @ViewChild('etaTo') etaTo:DatePluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";

  disableToolbarButtons = '';

  modeToolbar = false;
  isErrorFlag: boolean = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingDatePicker;
  settingDateFrom;
  settingDateTo;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new ReportParam;

  validatorRules = {};


  constructor(private _http: HttpClient,private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router,private cookieService: CookieService,private configService: ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Ok', event: 'ok', icon: 'check'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Monthly Lifting Report',
    }

      this.settingDatePicker = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingDateFrom = {
      id: 'idEtaFrom',
      placeholder : 'ETA FROM',
      autoHide: true,
      type: 'date', // date|custom
      format: 'yyyy-mm-dd',
      customtext: ''
    }

    this.settingDateTo = {
      id: 'idEtaTo',
      placeholder : 'ETA TO',
      autoHide: true,
      type: 'date', // date|custom
      format: 'yyyy-mm-dd',
      customtext: ''
    }

  }
  ngOnInit() {

  }


  ngAfterViewInit() {
    //this.dateLocalETA.initCalendar("");
    //this.dateLocalETD.initCalendar("");
    this.checkedRadio("normal", true);
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'ok' :
        this.model.fromDate = $('#etaFrom').val();
        this.model.toDate =  $('#etaTo').val();
        //console.log('model Data');
        console.log(this.model);
        this.handleOK();
        break;
      case 'cancel':
        //console.log(this.etaFrom.getValue());
        this.handleCancel();
        //this.etaFrom.setCustomText("00:10:00");
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}




  handleOK(){
    console.log("WHATATA: "+this.model.reportType);


    this.setValidator();
    this.model['error-fromDate'] = '';
    this.model['error-toDate'] = '';
    this.model['error-etaToValidator'] = '';
    this.isErrorFlag = false;
    this.model['dateValidator'] = false;
     // this.setValidatorTransferByPOT();
     let isPassedValidation:boolean = this.onValidate(this.model);
     this.model['dateValidator'] = this.dateValidator();
     if(this.model['dateValidator'])this.isErrorFlag = true;

     if(!isPassedValidation&&!this.model['dateValidator']){
      this.isErrorFlag = false;
     // this.disableToolbarButtons="ok,cancel,close";
      this.model['error-fromDate'] = '';
      this.model['error-toDate'] = '';
      this.loading = true;
      var pathUrl = '';
      var fileName = '';
        if(this.model.reportType == 'normal'){
          pathUrl =  this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/monthlyLiftingNormal.xls?etafrom=='+$('#etaFrom').val()+'&etato='+$('#etaTo').val()+'&officecode='+this.cookieService.getDefaultLocationCode();
          fileName = 'monthlyLiftingNormal.xls';
        }else if(this.model.reportType == 'reefer'){
          pathUrl =  this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/monthlyLiftingReefer.xls?etafrom=='+$('#etaFrom').val()+'&etato='+$('#etaTo').val()+'&officecode='+this.cookieService.getDefaultLocationCode();
          fileName = 'monthlyLiftingReefer.xls';
        }else{
          pathUrl =  this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/monthlyLiftingAwkward.xls?etafrom=='+$('#etaFrom').val()+'&etato='+$('#etaTo').val()+'&officecode='+this.cookieService.getDefaultLocationCode();
          fileName = 'monthlyLiftingAwkward.xls';
        }

        var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile";

        var postData = {};
        postData["fileName"] = fileName;
        postData["url"] = pathUrl;
        // window.open(pathUrl);
        // this.loading = false;

        this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
          if(resp.ok){
            var result = resp.json();

            if(result["message"] == "success"){
              window.open(this.configService.config.pdfUrl + result['filePath']);

            }
          }
          this.loading = false;
        });

        //window.open(urlHit);
       /* const urlHit = pathUrl;

        var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile";

        var fileName = "monthly_lifting.xlsx";

        var postData = {};
        postData["fileName"] = fileName;
        postData["url"] = urlHit;

        this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
          if(resp.ok){
            var result = resp.json();

            if(result["message"] == "success"){

            // this.pdfContainer.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
              //this.pdfContainer.showPDF();
              //console.log('PATH');
              var namePath = result['filePath'];
              var partPath = namePath.split("/")[2];
              var nameFile = partPath.split(".")[0];
              var nameExt = partPath.split(".")[1];
            // console.log(nameFile);
            // console.log(nameExt);

              var urlHit = this.configService.config.getPDFUrl  + "/edi-util/getFile/"+nameFile+"/"+nameExt;
              this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'blob'}).subscribe((res)=>{
              FileSaver.saveAs(res, 'monthly_lifting.xlsx');
              //this.loading = false;
              });

            }
            this.disableToolbarButtons="";
            this.loading = false;
          }
        });*/

      //var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime;

    }

  }

  dateValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.toDate != '') {
      if (this.model.toDate <= this.model.fromDate) {
        this.model['error-etaToValidator'] = 'Expirly On Date (' + this.model.toDate.toString() + ') must be later than ETA From.';
        return true;
      }
    }

    return result;
  }

  handleCancel(){
    this.model = new ReportParam;
   // this.etaFrom.reset();
    //this.etaTo.reset();
    this.model['error-fromDate'] = '';
    this.model['error-toDate'] = '';
    this.model['error-dateValidator'] = '';
    $('#etaFrom').val('');
    $('#etaTo').val('');
    $('#rType1').prop('checked',true);
    $('#rType2').prop('checked',false);
    this.isErrorFlag = false;
  }

  setDate(type, value){
    if(type == 'from'){
      this.model.fromDate = value;
    } else if(type == 'to'){
      this.model.toDate = value;
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  checkedRadio(val, checked:boolean){
    $("input[name=rType][value="+val+"]").prop("checked", checked);
    this.model.reportType = val;
  }

  eventRadio(event,val){
    //console.log(event);
    var checked = event.srcElement.checked;

    if(checked){
      this.model.reportType = val;
    }

  }

  changeEventDate(event){
    console.log(event);
  }

  setValidator(){
    this.validatorRules = {
      fromDate: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel ETA From."
        }]
      },
      toDate: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel ETA To."
        }]
      },
      dateValidator: {
        rules: [{
          type: 'custom',
          prompt: 'Eta to Date must be later than Eta From.'
        }]
      },
    }
  }

}
