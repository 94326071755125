import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChangePassword } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  ConfigService,
  GenericUtil,
  GenericService,
} from "sibego-ui-library";
import { toBase64String } from "@angular/compiler/src/output/source_map";

declare var $: any;

export class ChangePasswordX {
  userId: string = "";
  XPWD: string = "";

  constructor() {}
}

@Component({
  selector: "app-change-password-page",
  templateUrl: "./change-password-page.component.html",
  styleUrls: ["./change-password-page.component.css"],
})
export class ChangePasswordPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;

  /* Misc Parameters */

  /* Parameter model */
  model = new ChangePassword();
  //selectList = [];

  loading = false;

  lock = false;
  isError = false;

  /* validatorRules */
  validatorRules = {
    validatorMatchPassword: {
      rules: [
        {
          type: "custom",
          prompt: "New Password and Confirm Password does not Match",
        },
      ],
    },
    passwordValidasi: {
      rules: [
        {
          type: "custom",
          prompt: "",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private router: Router
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Save", event: "save", icon: "checkmark" },
        { name: "Cancel", event: "cancel", icon: "remove" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: true,
      searchBy: [],
      toolbarType: "none",
      urlhelp: "assets/pdf/panduan.pdf",
    };
  }

  ngOnInit() {
    this.modeForm = true;
  }

  ngAfterViewInit() {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  toolbarEvent(event) {
    switch (event) {
      case "save":
        this.handleSave();
        //this.saveEvent();
        break;
      case "cancel":
        this.handleCancel();
        break;
    }
  }

  handleSave() {
    // handle save event

    this.model["error-validatorMatchPassword"] = "";
    this.model["error-passwordValidasi"] = "";

    this.model["validatorMatchPassword"] = this.passwordMatchValidator();
    this.model["passwordValidasi"] = this.passwordValidator();

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      // this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.form = "";
    this.router.navigateByUrl(localStorage.getItem("lastVisit"));
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS

    this.model = new ChangePassword();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    var modelx = new ChangePasswordX();
    modelx.userId = this.model.userId;
    modelx.XPWD = btoa(
      this.model["currentPWD"] +
        "." +
        this.model["newPWD"] +
        "." +
        this.model["confirmPWD"]
    );
    console.log(modelx);

    this.genericService
      .post(
        this.configService.config.BASE_API.toString() + "/changePassword",
        modelx
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            let bd = resp["_body"];
            console.log("_body : ", bd);
            if (bd == "Changed Password Successfully") {
              this.model = new ChangePassword();
              this.loading = false;
              this.message(
                "information",
                "Information",
                "Password changed successfully.",
                "okonly",
                { ok: "this.loading=false;this.afterSave();" }
              );
            } else {
              this.loading = false;
              this.message("warning", "Warning", bd, "okonly", {
                ok: "this.loading=false;",
              });
            }
          } else {
            this.loading = false;
            this.message("warning", "Warning", resp.json(), "okonly", {
              ok: "this.loading=false;",
            });
          }
        },
        (error) => {
          this.loading = false;
          this.loading = false;
        }
      );
  }

  passwordMatchValidator(): boolean {
    let result: boolean = true;

    //console.log('passwordMatch' + this.model.userPassword)
    if (this.model.newPWD == this.model.confirmPWD) {
      result = false;
    }

    return result;
  }

  passwordValidator(): boolean {
    let result: boolean = false;
    let arr: any[] = [];

    var flag = 0;

    if (this.checkLengthPassword() > 0) {
      arr.push("<li>Please input password more than 8 char </li>");
      flag = flag + 1;
    }
    if (this.checkUpperLowerMoreThanOne() > 0) {
      arr.push(
        "<li>Please input Combination of upper case and lower case </li>"
      );
      flag = flag + 1;
    }
    if (this.checkSpecialChar() > 0) {
      arr.push(
        "<li>Please use at least one of following punctuation mark(s) (!,@,#,$,%,^,&,*,?,_,~ </li>"
      );
      flag = flag + 1;
    }
    if (this.checkNumberChar() > 0) {
      arr.push("<li>Please Contains number(s) inside the password </li>");
      flag = flag + 1;
    }
    if (this.checkOldAndNewPassword()) {
      arr.push("<li>New and Old password cannot be same </li>");
      flag = flag + 1;
    }

    this.model["error-passwordValidasi"] = "";
    arr.forEach((element) => {
      this.model["error-passwordValidasi"] += element;
    });

    //console.log('flag' + flag)
    if (flag > 0) {
      result = true;
    }

    return result;
  }

  checkLengthPassword() {
    //check length process

    var hitung = 0;

    //console.log(this.model.userPassword.length)
    if (this.model.newPWD.length <= 8 || this.model.confirmPWD.length <= 8) {
      hitung = hitung + 1;
      console.log("ini length");
    }

    return hitung;
  }

  checkOldAndNewPassword() {
    let result: boolean = false;

    if (
      this.model.currentPWD === this.model.newPWD &&
      this.model.currentPWD === this.model.confirmPWD
    ) {
      result = true;
    }

    return result;
  }

  checkUpperLowerMoreThanOne() {
    var hitungError = 0;

    var lowerCase = /[a-z]/;
    var upperCase = /[A-Z]/;
    if (
      !(
        this.model.newPWD.match(lowerCase) && this.model.newPWD.match(upperCase)
      )
    ) {
      hitungError = hitungError + 1;
      //console.log("ini upper lower")
    }
    if (
      !(
        this.model.confirmPWD.match(lowerCase) &&
        this.model.confirmPWD.match(upperCase)
      )
    ) {
      hitungError = hitungError + 1;
      //console.log("ini upper upper")
    }
    return hitungError;
  }

  checkSpecialChar() {
    var specialChar = /[!@#$%^&*?_~]/;

    var hitungSalah = 0;

    if (!this.model.newPWD.match(specialChar)) {
      hitungSalah = hitungSalah + 1;
      //console.log("ini special char")
    }

    if (!this.model.confirmPWD.match(specialChar)) {
      hitungSalah = hitungSalah + 1;
    }

    return hitungSalah;
  }

  checkNumberChar() {
    var angka = /[0-9]/;

    var salahHitung = 0;

    if (!this.model.newPWD.match(angka)) {
      salahHitung = salahHitung + 1;
      //console.log("ini number")
    }
    if (!this.model.confirmPWD.match(angka)) {
      salahHitung = salahHitung + 1;
    }
    return salahHitung;
  }
}
