<div class="ui raised segment" [style.display]="isExceptionPage === 'EXCEPTION' ? 'block' : 'none'">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 99"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-vesselCode'] == null || model['error-vesselCode'] == ''">
        {{model['error-vesselCode']}}
      </li>
      <li [class.hidden]="model['error-voyage'] == null || model['error-voyage'] == ''">
        {{model['error-voyage']}}
      </li>
      <li [class.hidden]="model['error-bound'] == null || model['error-bound'] == ''">
        {{model['error-bound']}}
      </li>
      <li [class.hidden]="model['error-bPot1Code'] == null || model['error-bPot1Code'] == ''">
        {{model['error-bPot1Code']}}
      </li>
      <li [class.hidden]="model['error-bPolCode'] == null || model['error-bPolCode'] == ''">
        {{model['error-bPolCode']}}
      </li>
      <li [class.hidden]="model['error-podCode'] == null || model['error-podCode'] == ''">
        {{model['error-podCode']}}
      </li>
      <li [class.hidden]="model['error-allocationValidator'] == null || model['error-allocationValidator'] == ''">
        {{model['error-allocationValidator']}}
      </li>
    </ul>
  </div>
  
  <div class="ui error message" *ngIf="isValidbkgNo">
    <ul>
      <li>
        {{isValidbkgNo}}
      </li>
    </ul>
  </div>

  <div id="container" class="transaction-ts-exception">
    <div class="ui form mt-30px">
      <div class="fields">
        <div class="two wide field">
          <div class="one fields" style="width: 100%">
            <div
              class="required field"
              style="width: 100%"
              [class.error]="
                model['error-bPot1Code'] != null &&
                model['error-bPot1Code'] != '' &&
                model['error-bPot1Code'] != undefined
              "
            >
              <label>POT</label>
              <combo-plugin
                #cbPOT1
                [settings]="settingPOT1"
                (change)="changeEventPOT($event)"
                style="width: 100%">
              </combo-plugin>
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div
              class="required field"
              style="width: 100%"
              [class.error]="
                model['error-vesselCode'] != null &&
                model['error-vesselCode'] != '' &&
                model['error-vesselCode'] != undefined
              "
            >
              <label>Status</label>
              <combo-plugin
                #cbContOpr
                [settings]="settingStatus"
                (change)="changeEventStatus($event)"
              ></combo-plugin>
            </div>
          </div>
        </div>

        <div class="two wide field">
          <div class="one fields" style="width: 100%">
            <div
              class="field"
                style="width: 100%" [class.error]="
                model['error-bPolCode'] != null &&
                model['error-bPolCode'] != '' &&
                model['error-bPolCode'] != undefined
              ">
              <label>Load Port</label>
              <combo-plugin
                #cbLoadPort
                [settings]="settingLoadPort"
                (change)="changeEventLoadPort($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div class="field" style="width: 100%" [class.error]="
              model['error-podCode'] != null &&
              model['error-podCode'] != '' &&
              model['error-podCode'] != undefined
            ">
              <label>Discharge Port</label>
              <combo-plugin
                #cbDischargePort
                [settings]="settingDischargePort"
                (change)="changeEventDischargePort($event)"
                style="width: 100%"
                (keypress)="formValidation.keypressEventRegexWith($event, model, 'bPodCode', '[a-zA-Z0-9-() ]')"
                (paste)="formValidation.pasteEventRegexWith($event, model, 'bPodCode', '[^a-zA-Z0-9-() ]', 50)">
              </combo-plugin>
            </div>
          </div>
        </div>

        <div class="two wide field">
          <div class="one fields" style="width: 100%">
            <div class="field" style="width: 100%">
              <label>Discharging Vessel</label>
              <combo-plugin
                #cbDischargingVessel
                [settings]="settingDischargingVessel"
                (change)="changeDischargingVessel($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div class="field" style="width: 100%">
              <label>Connecting Vessel</label>
              <combo-plugin
                #cbConnectingVessel
                [settings]="settingConnectingVessel"
                (change)="changeConnectingVessel($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>
          </div>
        </div>

        <div class="two wide field">
          <div class="one fields" style="width: 100%">
            <div class="field" style="width: 100%">
              <label>Discharging Voyage</label>
              <combo-plugin
                #cbDischargingVoyage
                [settings]="settingDischargingVoyage"
                (change)="changeDischargingVoyage($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div class="field" style="width: 100%">
              <label>Connecting Voyage</label>
              <combo-plugin
                #cbConnectingVoyage
                [settings]="settingConnectingVoyage"
                (change)="changeConnectingVoyage($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>
          </div>
        </div>

        <div class="one wide field">
          <div class="one fields" style="width: 100%">
            <div
              class="required field"
              style="width: 100%">
              <label>Bound</label>
              <input
                type="text"
                name="bound"
                style="text-transform: uppercase"
                placeholder="Bound"
                [(ngModel)]="model.dischargingBound"
                (keypress)="formValidation.BoundValidator($event)"
                maxlength="1"
                disabled
              />
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div
              class="required field"
              style="width: 100%">
              <label>Bound</label>
              <input
                type="text"
                name="bound"
                style="text-transform: uppercase"
                placeholder="Bound"
                [(ngModel)]="model.connectingBound"
                (keypress)="formValidation.BoundValidator($event)"
                maxlength="1"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="one wide field">
          <div class="one fields" style="width: 100%">
            <div
              class="field"
              style="width: 100%" >
              <label>Service</label>
              <input
                type="text"
                name="dischargingService"
                style="text-transform: uppercase"
                placeholder="Service"
                [(ngModel)]="model.dischargingService"
                disabled
              />
            </div>
          </div>
          <div class="one fields" style="width: 100%">
            <div
              class="field"
              style="width: 100%">
              <label>Service</label>
              <input
                type="text"
                name="connectingService"
                style="text-transform: uppercase"
                placeholder="Service"
                [(ngModel)]="model.connectingService"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="three wide field">
          <div class="one fields" style="width: 100%">
            <div class="field flex-text-a" style="width: 100%; padding: 16px;">
              <div>
                <span class="span-text-a">ETA at POL: {{model.tsDischargingEtaPol}}</span>
              </div>
              <div>
                <span class="span-text-a">ETD at POL: {{model.tsDischargingEtdPol}}</span>
              </div>
            </div>
          </div>

          <div class="one fields" style="width: 100%">
            <div class="field flex-text-a" style="width: 100%; padding: 16px;">
              <div>
                <span class="span-text-a">ETA at POT: {{model.tsConnectingEtaPot}}</span>
              </div>
              <div >
                <span class="span-text-a">ETD at POT: {{model.tsConnectingEtdPot}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="three wide field">
          <div class="one fields" style="width: 100%">
            <div class="field flex-text-a" style="width: 100%; padding: 16px;">
              <div>
                <span class="span-text-a">ETA at POT: {{model.tsDischargingEtaPot}}</span>
              </div>
              <div>
                <span class="span-text-a">ETD at POT: {{model.tsDischargingEtdPot}}</span>
              </div>
            </div>
          </div>

          <div class="one fields" style="width: 100%">
            <div class="field flex-text-a" style="width: 100%; padding: 16px;">
              <div>
                <span class="span-text-a">ETA at POD: {{model.tsConnectingEtaPod}}</span>
              </div>
              <div>
                <span class="span-text-a">ETD at POD: {{model.tsConnectingEtdPod}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Retrieve-Thru-BKG">
        <input
          type="checkbox"
          name=""
          id="largerCheckbox"
          class="largerCheckbox"
          [checked]="isRetrieveByBkgNo"
          [(ngModel)]="isRetrieveByBkgNo"
          (ngModelChange)="retrieveByBkgNoChange($event)"
        />
        <label for="largerCheckbox"
          >Retrieve by Thru BKG No. (POL BKG No.)
        </label>
        <div class="input-bkg-no" [ngClass]="{errorBkg: isValidbkgNo}">
          <span>SUDU</span>
          <input type="text" [(ngModel)]="bkgNo" [disabled]="isDisableThruBNo"/>
        </div>
      </div>

      <div style="width: 100%; margin-top: 30px">
        <app-custom-ag-grid-angular
          #topGrid
          id="transaction-ts-exception-1"
          [isKeepPreviousFilterModel]="true"
          (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)"
          (selectionChanged)="onSelectionChanged($event)"
          (cellValueChanged)="onCellValueChanged($event)"
          [getRowStyle]="getRowStyle"
          [rowData]="rowData"
          [rowMultiSelectWithClick]="true"
          [defaultColumnDefs]="defaultColumnDefs"
          [rowSelection]="'multiple'"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize]="true"
          [enableBrowserTooltips]="true"
        >
        </app-custom-ag-grid-angular>
      </div>

      <div style="width: 100%; margin: 50px 0px">
        <div style="padding-bottom: 15px; font-weight: 700">
          Port-Pair Allocation summary of selected Connecting Vessel / Voyage
        </div>
        <app-custom-ag-grid-angular
          #topGridPortPair
          width="60%"
          height="180px"
          id="transaction-ts-exception-2"
          (gridReady)="onGridReadyGridPortPair($event)"
          (rowDoubleClicked)="rowDoubleClickGridPortPair($event)"
          (selectionChanged)="onSelectionChangedGridPortPair($event)"
          [getRowStyle]="getRowStyleGridPortPair"
          [rowData]="rowDataGridPortPair"
          [defaultColumnDefs]="defaultColumnDefsGridPortPair"
          [rowSelection]="'multiple'"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize]="true"
          [rowMultiSelectWithClick]="true"
        >
        </app-custom-ag-grid-angular>
      </div>
    </div>
  </div>
</div>

<dialog-plugin
[settings]="dialog"
(eventDialog)="eventMessage($event)"
></dialog-plugin>

<!-- POT Remarks / Outward Slot /Reason Dialog-->
<div id="DialogConent" class="dialogConent">
  <div class="ui error message" *ngIf="messageValidateDialog">
    <span>{{messageValidateDialog}}</span>
  </div>
  <textarea name="" id="" rows="6" [(ngModel)]="valueDialog" (ngModelChange)="valueDialogChange($event)"
    [maxlength]="maxlengthDialog"></textarea>
  <p>
    {{noteContent}}
  </p>
  <div class="footer-dialogConent">
    <div class="footer-btn-event">
      <button class="ui tiny button" (click)="saveDialog()">Insert</button>
      <button class="ui tiny button" (click)="cancelDialog()">Cancel</button>
      <button class="ui tiny button" (click)="closeDialogRemarksOutReason()">Close</button>
    </div>
  </div>
</div>

<!-- Exception handling dialog -->
<div id="ExceptionHandling" [style.display]="isExceptionPage === 'ExceptionHandling' ? 'block' : 'none'">
  <app-transaction-ts-exception-handling 
    #exceptionHandling
    [bPot1Code]="model.bPot1Code" 
    (redirectToExceptionPage)="backToExceptionpage($event)"
    (emitMessageDialog)="emitMessageDialog($event)"
    (confirmAction)="emitConfirmAction($event)"
    >

  </app-transaction-ts-exception-handling>
</div>