import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionBookingService {

  private containerSource = new BehaviorSubject<any>(null);
  containerState$ = this.containerSource.asObservable();
  filterModel: any;

  constructor() { }

  temporarySaveContainer(container: {isCOC: Boolean, isSOC: Boolean, isSLOT: Boolean}) {
    this.containerSource.next(container);
  }

  saveFilterModel(model) {
    this.filterModel = model;
  }

  getFilterModel() {
    return this.filterModel;
  }
}
