
<div class="ui raised segment">
    <div class="ui inverted dimmer" [class.active]="loadingUpload == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="ui segment">

                <!--div class="ui segment" style="width:70%;margin: 0;margin-left: auto;margin-right: auto"-->
                  <a class="ui blue ribbon label" style="margin-bottom:5px">Select EDI File</a>
                  <upload-plugin #uploadPlg [settings]="settingUpload" (eventUpload)="uploadEvent($event)"></upload-plugin>

    </div>

    <table class="ui basic table">
      <tr>
        <td>
          <b>Vessel : {{vessel}}</b>
          &nbsp;
          &nbsp;
          &nbsp;
        <b>Voyage : {{voyage}}</b>
        </td>

      </tr>
    </table>
    <div class="ui form">
    <div class="fields">

        <div class="four wide field">
          <grid-plugin id="gridBL" #gridBL [settings]="settingGridBL" (gridEvent)="gridEventBL($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        </div>
        <div class="four wide field">
          <grid-plugin id="gridContainer" #gridContainer class='gridContainer' [settings]="settingGridContainer" (gridEvent)="gridEventContainer($event)" (infoGrid)="infoGridContainer($event)"></grid-plugin>
        </div>
        <div class="eight wide field">

          <div class="field">
              <grid-plugin id="gridHSCode" #gridHSCode [settings]="settingGridHSCode" (gridEvent)="gridEventHSCode($event)" (infoGrid)="infoGridHSCode($event)"></grid-plugin>
          </div>

          <div class="field">
              <label>&nbsp;</label>
              <button class="tiny ui positive button" id="addBtn"  (click) = "addNewRow();" >Add</button>
              <button class="tiny ui negative button" id="removeBtn"  (click) = "removeRow();">Remove</button>
          </div>

          <div class="field">
              <label>&nbsp;</label>
              <combo-plugin #cbHScode [settings]="settingHSCode" (change)="changeEventHScode($event)" style="width:100%;"></combo-plugin>
          </div>

        </div>


    </div>
  </div>



</div>






<footer-plugin [info]="info"></footer-plugin>
