import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";
import { SurchargeMaster, SurchargeDetailTotal, specialCharUtil } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DatetimePluginComponent,
  ToolbarPluginComponent,
  DialogMultiplePluginComponent,
  TextareaPluginComponent
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { SurchargeDetail } from "../shared/models/surchargeMaster";
import FormValidation from "../../../src/utils/formValidation";
declare var $: any;

@Component({
  selector: "app-master-surcharge-page",
  templateUrl: "./master-surcharge-page.component.html",
  styleUrls: ["./master-surcharge-page.component.css"],
})
export class MasterSurchargePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("pfcToolbar") pfcToolbar: ToolbarPluginComponent;
  @ViewChild("cbFreightParty") cbFreightParty: ComboPluginComponent;
  @ViewChild("cbBookingParty") cbBookingParty: ComboPluginComponent;
  @ViewChild("cbShipper") cbShipper: ComboPluginComponent;
  @ViewChild("cbPlaceOfDelivery") cbPlaceOfDelivery: ComboPluginComponent;
  @ViewChild("cbContainerOwnership") cbContainerOwnership: ComboPluginComponent;
  @ViewChild("cbPlaceOfReceipt") cbPlaceOfReceipt: ComboPluginComponent;
  @ViewChild("cbPortOfTransshipment")
  cbPortOfTransshipment: ComboPluginComponent;
  @ViewChild("cbDGClass") cbDGClass: ComboPluginComponent;
  @ViewChild("cbPortOfLoad") cbPortOfLoad: ComboPluginComponent;
  @ViewChild("cbFinalDestination") cbFinalDestination: ComboPluginComponent;
  @ViewChild("cbReceiptType") cbReceiptType: ComboPluginComponent;
  @ViewChild("cbContainerType") cbContainerType: ComboPluginComponent;
  @ViewChild("cbPortOfDischarge") cbPortOfDischarge: ComboPluginComponent;
  @ViewChild("cbContainerStatus") cbContainerStatus: ComboPluginComponent;
  @ViewChild("cbValidInvalid") cbValidInvalid: ComboPluginComponent;
  @ViewChild("cbDeliveryType") cbDeliveryType: ComboPluginComponent;
  @ViewChild("cbPORTerminal") cbPORTerminal: ComboPluginComponent;
  @ViewChild("cbPODLTerminal") cbPODLTerminal: ComboPluginComponent;
  @ViewChild("cbPOLTerminal") cbPOLTerminal: ComboPluginComponent;
  @ViewChild("cbPOT1Terminal") cbPOT1Terminal: ComboPluginComponent;
  @ViewChild("cbPODTerminal") cbPODTerminal: ComboPluginComponent;
  @ViewChild("cbFDESTTerminal") cbFDESTTerminal: ComboPluginComponent;
  @ViewChild("cbPrepaidStatus") cbPrepaidStatus: ComboPluginComponent;
  @ViewChild("cbCollectStatus") cbCollectStatus: ComboPluginComponent;

  @ViewChild("txtRemarksPrincipal")
  txtRemarksPrincipal: TextareaPluginComponent;
  @ViewChild("txtRemarksAgency") txtRemarksAgency: TextareaPluginComponent;
  /*
   @ViewChild('dateEffectiveFrom') dateEffectiveFrom:CalendarPluginComponent;
   @ViewChild('dateEffectiveTo') dateEffectiveTo:CalendarPluginComponent;
   */
  @ViewChild("dateEffectiveFrom") dateEffectiveFrom: DatetimePluginComponent;
  @ViewChild("dateEffectiveTo") dateEffectiveTo: DatetimePluginComponent;

  @ViewChild("mainGrid") mainGrid: GridPluginComponent;

  /***
   * Component for Freight Maintenance Maintenance
   ***/
  @ViewChild("cbMaintenanceFreightParty")
  cbMaintenanceFreightParty: ComboPluginComponent;
  @ViewChild("cbMaintenanceBookingParty")
  cbMaintenanceBookingParty: ComboPluginComponent;
  @ViewChild("cbMaintenanceShipper") cbMaintenanceShipper: ComboPluginComponent;
  @ViewChild("cbMaintenancePlaceOfReceipt")
  cbMaintenancePlaceOfReceipt: ComboPluginComponent;
  @ViewChild("cbMaintenancePortOfLoad")
  cbMaintenancePortOfLoad: ComboPluginComponent;
  @ViewChild("cbMaintenancePortOfDischarge")
  cbMaintenancePortOfDischarge: ComboPluginComponent;
  @ViewChild("cbMaintenancePlaceOfDelivery")
  cbMaintenancePlaceOfDelivery: ComboPluginComponent;
  @ViewChild("cbMaintenanceFinalDestination")
  cbMaintenanceFinalDestination: ComboPluginComponent;
  //@ViewChild('cbMaintenanceCommodity') cbMaintenanceCommodity: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT1") cbMaintenancePOT1: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT2") cbMaintenancePOT2: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT3") cbMaintenancePOT3: ComboPluginComponent;
  @ViewChild("cbMaintenanceReceiptType")
  cbMaintenanceReceiptType: ComboPluginComponent;
  @ViewChild("cbMaintenanceDeliveryType")
  cbMaintenanceDeliveryType: ComboPluginComponent;
  @ViewChild("cbMaintenanceAdhocVessel")
  cbMaintenanceAdhocVessel: ComboPluginComponent;
  @ViewChild("cbMaintenanceAdhocVoyage")
  cbMaintenanceAdhocVoyage: ComboPluginComponent;

  @ViewChild("dateMaintenanceEffectiveFrom")
  dateMaintenanceEffectiveFrom: DatetimePluginComponent;
  @ViewChild("dateMaintenanceEffectiveTo")
  dateMaintenanceEffectiveTo: DatetimePluginComponent;

  @ViewChild("cbMaintenancePORTerminal")
  cbMaintenancePORTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePODLTerminal")
  cbMaintenancePODLTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePOLTerminal")
  cbMaintenancePOLTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePOT1Terminal")
  cbMaintenancePOT1Terminal: ComboPluginComponent;
  @ViewChild("cbMaintenancePODTerminal")
  cbMaintenancePODTerminal: ComboPluginComponent;
  @ViewChild("cbMaintenanceFDESTTerminal")
  cbMaintenanceFDESTTerminal: ComboPluginComponent;
  @ViewChild("cbAcctPic") cbAcctPic: ComboPluginComponent;

  //Detail
  @ViewChild("cbMaintenanceDetailCharge")
  cbMaintenanceDetailCharge: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailCurrency")
  cbMaintenanceDetailCurrency: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPC")
  cbMaintenanceDetailPC: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPlaceOfPayment")
  cbMaintenanceDetailPlaceOfPayment: ComboPluginComponent;
  @ViewChild("cbMaintenanceDetailPayer")
  cbMaintenanceDetailPayer: ComboPluginComponent;

  //@ViewChild('gridPrepaid') gridPrepaid: GridPluginComponent;
  //@ViewChild('gridCollect') gridCollect: GridPluginComponent;

  @ViewChild("nctform_ctrType") nctform_ctrType: ComboPluginComponent;
  @ViewChild("dialogMultiple") dialogMultiple: DialogMultiplePluginComponent;

  @ViewChild('maintenanceGeneralTab') maintenanceGeneralTab: ElementRef;
  /***
   * End of Freight Management Maintenance
   */
  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm: string = "surchargeFlagStatus";

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingCbFreightParty;
  settingCbBookingParty;
  settingCbShipper;
  settingCbPlaceOfDelivery;
  settingCbContainerOwnership;
  settingCbPlaceOfReceipt;
  settingCbPortOfTransshipment;
  settingCbDGClass;
  settingCbPortOfLoad;
  settingCbFinalDestination;
  settingCbReceiptType;
  settingCbContainerType;
  settingCbPortOfDischarge;
  settingCbContainerStatus;
  settingCbValidInvalid;
  settingCbDeliveryType;
  settingCbPORTerminal;
  settingCbPODLTerminal;
  settingCbPOLTerminal;
  settingCbPOT1Terminal;
  settingCbPODTerminal;
  settingCbFDESTTerminal;
  settingCbPrepaidStatus;
  settingCbCollectStatus;
  settingMainGrid;

  settingDateEffectiveFrom;
  settingDateEffectiveTo;
  settingDateMaintenanceEffectiveFrom;
  settingDateMaintenanceEffectiveTo;

  settingCbMaintenanceFreightParty;
  settingCbMaintenanceShipper;
  settingCbMaintenanceBookingParty;
  settingCbMaintenancePlaceOfReceipt;
  settingCbMaintenancePortOfLoad;
  settingCbMaintenancePortOfDischarge;
  settingCbMaintenancePlaceOfDelivery;
  settingCbMaintenanceFinalDestination;
  settingCbMaintenanceCommodity;
  settingCbMaintenancePOT1;
  settingCbMaintenancePOT2;
  settingCbMaintenancePOT3;
  settingCbMaintenanceReceiptType;
  settingCbMaintenanceDeliveryType;
  settingCbMaintenanceAdhocVessel;
  settingCbMaintenanceAdhocVoyage;
  settingCbMaintenancePORTerminal;
  settingCbMaintenancePODLTerminal;
  settingCbMaintenancePOLTerminal;
  settingCbMaintenancePOT1Terminal;
  settingCbMaintenancePODTerminal;
  settingCbMaintenanceFDESTTerminal;
  settingCbAcctPIC;
  settingGridPrepaid;
  settingGridCollect;

  settingCbMaintenanceDetailCharge;
  settingCbMaintenanceDetailCurrency;
  settingCbMaintenanceDetailPC;
  settingCbMaintenanceDetailPlaceOfPayment;
  settingCbMaintenanceDetailPayer;

  setting_nctFormForContainerType;
  settingRemarksAgency;
  settingRemarksPrincipal;

  formValidation = new FormValidation();

  sCharUtil = new specialCharUtil();

  //setting lock
  lock: boolean = false;
  lockHeader: boolean = false;
  //lockInvalid: boolean = false;
  lockPORTerminal: boolean = true;
  lockPOLTerminal: boolean = false;
  lockPODTerminal: boolean = false;
  lockPODLTerminal: boolean = true;
  lockPOT1Terminal: boolean = false;
  lockFDESTTerminal: boolean = true;
  lockEffectiveFrom: boolean = false;
  lockEffectiveTo: boolean = false;
  //lockMaintenanceEffectiveFrom: boolean = false;
  //lockMaintenanceEffectiveTo: boolean = false;
  lockOOGOOG: boolean = false;
  lockOOGDoorOpen: boolean = false;
  lockOOGUC: boolean = false;
  lockRemarksPrincipal: boolean = true;
  lockFullEmpty: boolean = true;
  lockDetention: boolean = false;
  lockDemurrage: boolean = false;
  lockDetentionPol: Boolean = false;
  lockDemurragePol: Boolean = false;
  lockSOC: Boolean = true;

  //forInvalid
  isAfterSave: boolean = false;
  surchargeMasterKeyRetrieveExpiryOn: string = "";
  isValidRetrieve: string = "";
  currentSurchargeMasterKeyValidate : string ="";
  currentContainerTypeListValidate: any[] = [];

  //Flag active tab
  currentActiveTab = "prepaid";

  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";

  currentSelectedContainerType = "normal";

  checkLock: boolean = false;
  isError: boolean = false;
  isErrorDetail: boolean = false;
  isErrorDeleted: boolean = false;
  isUpdateDetail: boolean = false;
  isLoad: boolean = false; // used to check there is data / no data in the grid
  isCopy: boolean = false;

  //types
  typeEffectiveFrom = "calendar";
  typeEffectiveTo = "calendar";

  fpAcctCode = "";
  fpPayer = "";

  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new SurchargeMaster();
  modelDetail = new SurchargeDetail();
  modelDetailList: SurchargeDetail[] = [];
  modelDelete = new SurchargeMaster();
  modelDetailListx: SurchargeDetail[] = [];

  containerTypeList: any[] = [];
  surchargeMasterKeyRetrieve: string = "";
  surchargeMasterRetrieve: string = "";
  surchargeDetailsRetrieve: string = "";

  map = new Map();
  mapCheck = new Map();

  // TOTAL Prepaid
  totD20Prepaid: number = 0;
  totD40Prepaid: number = 0;
  totD45Prepaid: number = 0;
  totH20Prepaid: number = 0;
  totH40Prepaid: number = 0;
  totH45Prepaid: number = 0;

  // TOTAL Collect

  totD20Collect: number = 0;
  totD40Collect: number = 0;
  totD45Collect: number = 0;
  totH20Collect: number = 0;
  totH40Collect: number = 0;
  totH45Collect: number = 0;

  validatorRules = {};
  appStatusLabel: string = "";
  lastEffectiveTo: string = "";

  mapCtype = new Map();
  indexMapCtype: number = 0;

  multipleCtypeDialogText: string = "";

  msg: string = "";

  rowSelection;
  getRowStyle;

  gridApiPrepaid;
  gridColumnApiPrepaid;
  columnDefsPrepaid;
  rowDataPrepaid: any[];
  pinnedBottomRowDataPrepaid;

  gridApiCollect;
  gridColumnApiCollect;
  columnDefsCollect;
  rowDataCollect: any[];
  pinnedBottomRowDataCollect;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  custIdForCbPayer = "*";
  dateCreatedForCbPayer = "*";
  listPayerExisting = [];
  polName: string = "";
  podName: string = "";

  searchFpIsValid = "";
  searchFpIsFreightParty = "";

  searchShIsValid = "";
  searchShIsShipper = "";

  searchBkIsValid = "";
  searchBkIsBookingParty = "";

  constructor(
    private cdr: ChangeDetectorRef,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.rowSelection = "multiple";

    this.settingToolbar = {
      buttonsFront: [
        { name: "NCT", event: "nct", icon: "copy" },
        { name: "Save", event: "save", icon: "save" },
        { name: "Save & Copy", event: "copy", icon: "copy" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        /*{ name: 'Invalid',     event: 'invalid',    icon: 'minus circle' },*/
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Create", event: "create", icon: "plus" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Surcharge Application",
    };

    this.settingCbFreightParty = {
      id: "cbFreightParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Freight Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isFreightParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };
    this.settingCbBookingParty = {
      id: "cbBookingParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Booking Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isBookingParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbShipper = {
      id: "cbShipper",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Shipper",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isShipper", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };


    this.settingCbPlaceOfDelivery = {
      id: "cbPlaceOfDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Delivery",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbContainerOwnership = {
      id: "cbContainerOwnership",
      type: "select", // search | select | select input
      url: "assets/json/container_ownership_contract_rate.json",
      placeholder: "Container OwnerShip",
      code: "ctrOwnerCode",
      title: "ctrOwnerName",
    };

    this.settingCbPlaceOfReceipt = {
      id: "cbPlaceOfReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Place of Receipt",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPortOfTransshipment = {
      id: "cbPortOfTransshipment",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbDGClass = {
      id: "cbDGClass",
      type: "select", // search | select | select input
      url: "assets/json/dg_class_contract_rate.json",
      placeholder: "ALL(DG + NON DG)",
      code: "dgClassCode",
      title: "dgClassName",
    };

    this.settingCbPortOfLoad = {
      id: "cbPortOfLoad",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port Of Load",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbFinalDestination = {
      id: "cbFinalDestination",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Final Destination",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    /*this.settingCbReceiptType = {
      id: 'cbReceiptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: '',
      title: 'receiptTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 300},
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }*/

    this.settingCbReceiptType = {
      id: "cbReceiptType",
      type: "select", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok",
      urlType: "dynamic",
      // placeholder: 'ALL',
      code: "receiptTypeCode",
      title: "receiptTypeCode",
    };

    this.settingCbDeliveryType = {
      id: "cbDeliveryType",
      type: "select", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok",
      urlType: "dynamic",
      // placeholder: 'ALL',
      code: "deliveryTypeCode",
      title: "deliveryTypeCode",
    };

    this.settingCbContainerType = {
      id: "cbContainerType",
      type: "select", // search | select | select input
      url: "assets/json/containerType_freight.json",
      placeholder: "ALL",
      code: "containerTypeCode",
      title: "containerType",
    };

    this.settingCbPortOfDischarge = {
      id: "cbPortOfDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port Of Discharge",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbContainerStatus = {
      id: "cbContainerStatus",
      type: "select", // search | select | select input
      url: "assets/json/containerStatusContractRate.json",
      placeholder: "ALL",
      code: "ctrStatusCode",
      title: "ctrStatusName",
    };

    /*this.settingCbDeliveryType = {
      id: 'cbDeliveryType',
      type: 'search enter', // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/Y/receiptAndDeliveryTypeCode/{query}/1/100/ASC/receiptAndDeliveryTypeCode',
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: '',
      title: 'deliveryTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Delivery Type Name', field: 'deliveryTypeName', width: 300},
        {header: 'Delivery Type Code', field: 'deliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }*/

    this.settingCbPrepaidStatus = {
      id: "cbPrepaidStatus",
      type: "select", // search | select | select input
      url: "assets/json/prepaidStatusContractRate.json",
      placeholder: "ALL",
      code: "prepaidStatusCode",
      title: "prepaidStatusName",
    };

    this.settingCbCollectStatus = {
      id: "cbCollectStatus",
      type: "select", // search | select | select input
      url: "assets/json/collectStatusContractRate.json",
      placeholder: "ALL",
      code: "collectStatusCode",
      title: "collectStatusName",
    };

    this.settingMainGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/SurchargeMaster/findGeneral",
      page: 10,
      columns: [
        { header: "Ref No", field: "schSurchargeId", width: 200 },
        { header: "Freight Party", field: "schFreightPartyName", width: 200 },
        { header: "Booking Party", field: "schBookingPartyName", width: 200 },
        { header: "Shipper", field: "schShipperName", width: 200 },
        { header: "C/Type", field: "schCTypeName", width: 200 },
        { header: "C/S", field: "schCS", width: 200 },
        {
          header: "Place of Receipt",
          field: "schPlaceOfReceiptName",
          width: 200,
        },
        {
          header: "Port of Loading",
          field: "schPortOfLoadingName",
          width: 200,
        },
        {
          header: "POL Terminal",
          field: "schPOLTerminalName",
          width: 200,
        },
        {
          header: "Port of Discharge",
          field: "schPortOfDischargeName",
          width: 200,
        },
        {
          header: "POD Terminal",
          field: "schPODTerminalName",
          width: 200,
        },
        {
          header: "Place of Delivery",
          field: "schPlaceOfDeliveryName",
          width: 200,
        },
        {
          header: "Final Destination",
          field: "schFinalDestinationName",
          width: 200,
        },
        { header: "POT (1)", field: "schPortOfTransshipment1Name", width: 200 },
        {
          header: "POT1 Terminal",
          field: "schPOT1TerminalName",
          width: 200,
        },
        { header: "Effective From", field: "schEffectiveDate", width: 200 },
        { header: "Expiry On", field: "schExpiryDate", width: 200 },
        { header: "R/Type", field: "schReceiptTypeCode", width: 200 },
        { header: "D/Type", field: "schDeliveryTypeCode", width: 200 },
        { header: "DG Class", field: "schDGClass", width: 200 },
        { header: "AdHoc Info", field: "schAdHocInfo", width: 200 },
        { header: "POT (2)", field: "schPortOfTransshipment2Name", width: 200 },
        { header: "POT (3)", field: "schPortOfTransshipment3Name", width: 200 },
        { header: "Shipment Type", field: "schShipmentType", width: 200 },
        { header: "Commodity", field: "schCommodityName", width: 200 },
        { header: "User Created", field: "schUserCreated", width: 200 },
        { header: "Date Created", field: "schDateCreated", width: 200 },
        { header: "User Modified", field: "schModifiedBy", width: 200 },
        { header: "Date Modified", field: "schModifiedDate", width: 200 },
        { header: "User Invalid", field: "schInvalidBy", width: 200 },
        { header: "Date Invalid", field: "schInvalidDate", width: 200 },
        { header: "Valid", field: "schIsValid", width: 200 },
        { header: "Commissionable", field: "schCommission", width: 200 },
        { header: "Acct PIC", field: "schAcctPic", width: 200 },
        { header: "Deleted", field: "schIsDeleted", width: 200 },
        { header: "Deleted Date", field: "schDeletedDate", width: 200 },
        { header: "Deleted Reason", field: "schDeletedReason", width: 200 },
        { header: "Deleted User", field: "schDeletedBy", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "schModifiedDate",
      sortingDirection: "DESC",
    };

    this.settingCbPORTerminal = {
      id: "cbPORTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POR Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPODLTerminal = {
      id: "cbPODLTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search PODL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPOLTerminal = {
      id: "cbPOLTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPOT1Terminal = {
      id: "cbPOT1Terminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POT1 Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbPODTerminal = {
      id: "cbPODTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbFDESTTerminal = {
      id: "cbFDESTTerminal",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDateEffectiveFrom = {
      id: "dateEffectiveFrom",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Effective From",
      customtext: "",
      maxlength: 10,
    };
    this.settingDateEffectiveTo = {
      id: "dateEffectiveTo",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Expiry On",
      customtext: "",
      maxlength: 10,
    };

    this.settingCbValidInvalid = {
      id: "cbValidInValid",
      type: "select", // search | select | select input
      url: "assets/json/ValidInValidStatus_freight.json",
      placeholder: "ALL",
      code: "isValidCode",
      title: "isValidName",
    };

    /***
     * Maintenance section
     */
    this.settingCbMaintenanceFreightParty = {
      id: "cbMaintenanceFreightParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Freight Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isFreightParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };


this.settingCbMaintenanceBookingParty = {
      id: "cbMaintenanceBookingParty",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Booking Party",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isBookingParty", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbMaintenanceShipper = {
      id: "cbMaintenanceShipper",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Shipper",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isShipper", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingCbMaintenancePlaceOfReceipt = {
      id: "cbMaintenancePlaceOfReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Receipt",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePortOfLoad = {
      id: "cbMaintenancePortOfLoad",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Load",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePortOfDischarge = {
      id: "cbMaintenancePortOfDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Discharge",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePlaceOfDelivery = {
      id: "cbMaintenancePlaceOfDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Delivery",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceFinalDestination = {
      id: "cbMaintenanceFinalDestination",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Final Destination",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT1 = {
      id: "cbMaintenancePOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 1",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT2 = {
      id: "cbMaintenancePOT2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 2",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT3 = {
      id: "cbMaintenancePOT3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Port of Transshipment 3",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceReceiptType = {
      id: "cbMaintenanceReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "CY",
      title: "receiptTypeCode", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Name", field: "receiptTypeName", width: 200 },
        { header: "Receipt Type Code", field: "receiptTypeCode", width: 200 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDeliveryType = {
      id: "cbMaintenanceDeliveryType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}",
      maxChars: 2,
      template: "grid", // default
      placeholder: "CY",
      title: "deliveryTypeCode", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Name", field: "deliveryTypeName", width: 200 },
        { header: "Receipt Type Code", field: "deliveryTypeCode", width: 200 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceAdhocVessel = {
      id: "cbMaintenanceAdhocVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCbMaintenanceAdhocVoyage = {
      id: "cbMaintenanceAdhocVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingCbMaintenancePORTerminal = {
      id: "cbMaintenancePORTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POR Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePODLTerminal = {
      id: "cbMaintenancePODLTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "PODL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOLTerminal = {
      id: "cbMaintenancePOLTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POL Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePOT1Terminal = {
      id: "cbMaintenancePOT1Terminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POT 1 Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenancePODTerminal = {
      id: "cbMaintenancePODTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "POD Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceFDESTTerminal = {
      id: "cbMaintenanceFDESTTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "FDEST Terminal",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceCommodity = {
      id: "cbMaintenanceCommodity",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Commodity",
      title: "commodityName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Commodity Code", field: "commodityCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGridPrepaid = {
      id: "gridPrepaid",
      url: "",
      page: 10,
      columns: [
        { header: "Seq.", field: "no", width: 50 },
        //{header: 'FAI', field: 'schDetailFAIGroup', width: 75},
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {
          header: "Charge Name",
          field: "schDetailChargeName",
          width: 200,
          type: "string",
        },
        //{header: 'Charge Code', field: 'schDetailChargeCode', width: 100, type: 'string'},
        //{header: 'C/Type Code', field: 'schDetailContainerType', width: 100},
        { header: "C/Type", field: "schDetailContainerTypeName", width: 100 },
        {
          header: "Currency",
          field: "schDetailCurrency",
          width: 50,
          type: "string",
        },
        { header: "D20", field: "schDetailD20", width: 50 },
        { header: "H20", field: "schDetailH20", width: 50 },
        { header: "D40", field: "schDetailD40", width: 50 },
        { header: "H40", field: "schDetailH40", width: 50 },
        { header: "D45", field: "schDetailD45", width: 50 },
        { header: "H45", field: "schDetailH45", width: 50 },
        { header: "P/C", field: "schDetailPC", width: 50 },
        {
          header: "Place of Payment",
          field: "schDetailPlaceOfPaymentName",
          width: 200,
          type: "string",
        },
        {
          header: "Payer",
          field: "schDetailPayerName",
          width: 200,
          type: "string",
        },
        { header: "OH", field: "schDetailOogOH", width: 50 },
        { header: "OWL", field: "schDetailOogOWL", width: 50 },
        { header: "OWR", field: "schDetailOogOWR", width: 50 },
        { header: "OLF", field: "schDetailOogOLF", width: 50 },
        { header: "OLB", field: "schDetailOogOLB", width: 50 },
        { header: "Kill Slot", field: "schDetailKillSlot", width: 100 },
        { header: "Door Open", field: "schDetailOogDoorOpenName", width: 100 },
        { header: "UCH", field: "schDetailOogUcH", width: 50 },
        { header: "UCL", field: "schDetailOogUcL", width: 50 },
        { header: "UCW", field: "schDetailOogUcW", width: 50 },
        { header: "/M3", field: "schDetailM3", width: 50 },
        { header: "/KT", field: "schDetailKT", width: 50 },
        { header: "/RT", field: "schDetailRT", width: 50 },
        { header: "Min Charge", field: "schDetailMinCharge", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      // footer : {
      //   label : 'TOTAL'
      // },
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.columnDefsPrepaid = [
      {
        headerName: "Seq.",
        field: "schDetailSeqNo",
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      //{headerName: 'FAI', field: 'schDetailFAIGroup', width: 75},
      {
        headerName: "Charge Name",
        field: "schDetailChargeName",
        width: 200,
        tooltipField: "schDetailChargeName",
      },
      {
        headerName: "Charge Code",
        field: "schDetailChargeCode",
        width: 100,
        tooltipField: "schDetailChargeCode",
      },
      {
        headerName: "C/Type",
        field: "schDetailContainerTypeName",
        width: 100,
        tooltipField: "schDetailContainerTypeName",
      },
      {
        headerName: "Currency",
        field: "schDetailCurrency",
        width: 100,
        tooltipField: "schDetailCurrency",
      },
      {
        headerName: "D20",
        field: "schDetailD20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD20",
      },
      {
        headerName: "H20",
        field: "schDetailH20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH20",
      },
      {
        headerName: "D40",
        field: "schDetailD40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD40",
      },
      {
        headerName: "H40",
        field: "schDetailH40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH40",
      },
      {
        headerName: "D45",
        field: "schDetailD45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD45",
      },
      {
        headerName: "H45",
        field: "schDetailH45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH45",
      },
      {
        headerName: "P/C",
        field: "schDetailPC",
        width: 70,
        tooltipField: "schDetailPC",
      },
      {
        headerName: "Place of Payment",
        field: "schDetailPlaceOfPaymentName",
        width: 200,
        tooltipField: "schDetailPlaceOfPaymentName",
      },
      {
        headerName: "Payer",
        field: "schDetailPayerName",
        width: 200,
        headerTooltip: "Payer",
        tooltipField: "schDetailPayerName",
      },
      {
        headerName: "OH",
        field: "schDetailOogOH",
        width: 70,
        tooltipField: "schDetailOogOH",
      },
      {
        headerName: "OWL",
        field: "schDetailOogOWL",
        width: 70,
        tooltipField: "schDetailOogOWL",
      },
      {
        headerName: "OWR",
        field: "schDetailOogOWR",
        width: 70,
        tooltipField: "schDetailOogOWR",
      },
      {
        headerName: "OLF",
        field: "schDetailOogOLF",
        width: 70,
        tooltipField: "schDetailOogOLF",
      },
      {
        headerName: "OLB",
        field: "schDetailOogOLB",
        width: 70,
        tooltipField: "schDetailOogOLB",
      },
      {
        headerName: "Kill Slot",
        field: "schDetailKillSlot",
        width: 100,
        tooltipField: "schDetailKillSlot",
      },
      {
        headerName: "Door Open",
        field: "schDetailOogDoorOpenName",
        width: 100,
        tooltipField: "schDetailOogDoorOpenName",
      },
      {
        headerName: "UCH",
        field: "schDetailOogUcH",
        width: 70,
        tooltipField: "schDetailOogUcH",
      },
      {
        headerName: "UCL",
        field: "schDetailOogUcL",
        width: 70,
        tooltipField: "schDetailOogUcL",
      },
      {
        headerName: "UCW",
        field: "schDetailOogUcW",
        width: 70,
        tooltipField: "schDetailOogUcW",
      },
      {
        headerName: "/M3",
        field: "schDetailM3",
        width: 70,
        tooltipField: "schDetailM3",
      },
      {
        headerName: "/KT",
        field: "schDetailKT",
        width: 70,
        tooltipField: "schDetailKT",
      },
      {
        headerName: "/RT",
        field: "schDetailRT",
        width: 70,
        tooltipField: "schDetailRT",
      },
      {
        headerName: "Min Charge",
        field: "schDetailMinCharge",
        width: 100,
        tooltipField: "schDetailMinCharge",
      },
      //{headerName: 'Surcharge Id', field: 'schSurchargeId', width: 100},
    ];

    this.settingGridCollect = {
      id: "gridCollect",
      url: "",
      page: 10,
      columns: [
        { header: "Seq.", field: "no", width: 50 },
        //{header: 'FAI', field: 'schDetailFAIGroup', width: 75},
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {
          header: "Charge Name",
          field: "schDetailChargeName",
          width: 200,
          type: "string",
        },
        //{header: 'Charge Code', field: 'schDetailChargeCode', width: 100, type: 'string'},
        //{header: 'C/Type Code', field: 'schDetailContainerType', width: 100},
        { header: "C/Type", field: "schDetailContainerTypeName", width: 100 },
        {
          header: "Currency",
          field: "schDetailCurrency",
          width: 50,
          type: "string",
        },
        { header: "D20", field: "schDetailD20", width: 50 },
        { header: "H20", field: "schDetailH20", width: 50 },
        { header: "D40", field: "schDetailD40", width: 50 },
        { header: "H40", field: "schDetailH40", width: 50 },
        { header: "D45", field: "schDetailD45", width: 50 },
        { header: "H45", field: "schDetailH45", width: 50 },
        { header: "P/C", field: "schDetailPC", width: 50 },
        {
          header: "Place of Payment",
          field: "schDetailPlaceOfPaymentName",
          width: 200,
          type: "string",
        },
        {
          header: "Payer",
          field: "schDetailPayerName",
          width: 200,
          type: "string",
        },
        { header: "OH", field: "schDetailOogOH", width: 50 },
        { header: "OWL", field: "schDetailOogOWL", width: 50 },
        { header: "OWR", field: "schDetailOogOWR", width: 50 },
        { header: "OLF", field: "schDetailOogOLF", width: 50 },
        { header: "OLB", field: "schDetailOogOLB", width: 50 },
        { header: "Kill Slot", field: "schDetailKillSlot", width: 100 },
        { header: "Door Open", field: "schDetailOogDoorOpenName", width: 100 },
        { header: "UCH", field: "schDetailOogUcH", width: 50 },
        { header: "UCL", field: "schDetailOogUcL", width: 50 },
        { header: "UCW", field: "schDetailOogUcW", width: 50 },
        { header: "/M3", field: "schDetailM3", width: 50 },
        { header: "/KT", field: "schDetailKT", width: 50 },
        { header: "/RT", field: "schDetailRT", width: 50 },
        { header: "Min Charge", field: "schDetailMinCharge", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      // footer : {
      //   label : 'TOTAL'
      // },
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.columnDefsCollect = [
      {
        headerName: "Seq.",
        field: "schDetailSeqNo",
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      //{headerName: 'FAI', field: 'schDetailFAIGroup', width: 75},
      {
        headerName: "Charge Name",
        field: "schDetailChargeName",
        width: 200,
        tooltipField: "schDetailChargeName",
      },
      {
        headerName: "Charge Code",
        field: "schDetailChargeCode",
        width: 100,
        tooltipField: "schDetailChargeCode",
      },
      {
        headerName: "C/Type",
        field: "schDetailContainerTypeName",
        width: 100,
        tooltipField: "schDetailContainerTypeName",
      },
      {
        headerName: "Currency",
        field: "schDetailCurrency",
        width: 100,
        tooltipField: "schDetailCurrency",
      },
      {
        headerName: "D20",
        field: "schDetailD20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD20",
      },
      {
        headerName: "H20",
        field: "schDetailH20",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH20",
      },
      {
        headerName: "D40",
        field: "schDetailD40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD40",
      },
      {
        headerName: "H40",
        field: "schDetailH40",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH40",
      },
      {
        headerName: "D45",
        field: "schDetailD45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailD45",
      },
      {
        headerName: "H45",
        field: "schDetailH45",
        width: 70,
        cellStyle: function (params) {
          if (params.value < 0) {
            return { color: "red" };
          } else {
            return null;
          }
        },
        tooltipField: "schDetailH45",
      },
      {
        headerName: "P/C",
        field: "schDetailPC",
        width: 70,
        tooltipField: "schDetailPC",
      },
      {
        headerName: "Place of Payment",
        field: "schDetailPlaceOfPaymentName",
        width: 200,
        tooltipField: "schDetailPlaceOfPaymentName",
      },
      {
        headerName: "Payer",
        field: "schDetailPayerName",
        width: 200,
        headerTooltip: "Payer",
        tooltipField: "schDetailPayerName",
      },
      {
        headerName: "OH",
        field: "schDetailOogOH",
        width: 70,
        tooltipField: "schDetailOogOH",
      },
      {
        headerName: "OWL",
        field: "schDetailOogOWL",
        width: 70,
        tooltipField: "schDetailOogOWL",
      },
      {
        headerName: "OWR",
        field: "schDetailOogOWR",
        width: 70,
        tooltipField: "schDetailOogOWR",
      },
      {
        headerName: "OLF",
        field: "schDetailOogOLF",
        width: 70,
        tooltipField: "schDetailOogOLF",
      },
      {
        headerName: "OLB",
        field: "schDetailOogOLB",
        width: 70,
        tooltipField: "schDetailOogOLB",
      },
      {
        headerName: "Kill Slot",
        field: "schDetailKillSlot",
        width: 100,
        tooltipField: "schDetailKillSlot",
      },
      {
        headerName: "Door Open",
        field: "schDetailOogDoorOpenName",
        width: 100,
        tooltipField: "schDetailOogDoorOpenName",
      },
      {
        headerName: "UCH",
        field: "schDetailOogUcH",
        width: 70,
        tooltipField: "schDetailOogUcH",
      },
      {
        headerName: "UCL",
        field: "schDetailOogUcL",
        width: 70,
        tooltipField: "schDetailOogUcL",
      },
      {
        headerName: "UCW",
        field: "schDetailOogUcW",
        width: 70,
        tooltipField: "schDetailOogUcW",
      },
      {
        headerName: "/M3",
        field: "schDetailM3",
        width: 70,
        tooltipField: "schDetailM3",
      },
      {
        headerName: "/KT",
        field: "schDetailKT",
        width: 70,
        tooltipField: "schDetailKT",
      },
      {
        headerName: "/RT",
        field: "schDetailRT",
        width: 70,
        tooltipField: "schDetailRT",
      },
      {
        headerName: "Min Charge",
        field: "schDetailMinCharge",
        width: 100,
        tooltipField: "schDetailMinCharge",
      },
      // {headerName: 'Surcharge Id', field: 'schSurchargeId', width: 100},
    ];

    //detail section
    this.settingCbMaintenanceDetailCharge = {
      id: "cbMaintenanceDetailCharge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlNonAdhocNonMainCharge/chargeCodeOrName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Charge",
      title: "chargeName", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Charge Id', field: 'chargeId', width:100},
        { header: "Charge Code", field: "chargeCode", width: 150 },
        { header: "Charge Name", field: "chargeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailCurrency = {
      id: "cbMaintenanceDetailCurrency",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findGeneral/Y/currencyCode/{query}/1/100/ASC/currencyCode",
      maxChars: 0,
      maxlength: 4,
      template: "grid", // default
      placeholder: "Curr.",
      title: "currencyCode", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Currency ID', field: 'currencyId', width:100},
        { header: "Currency Code", field: "currencyCode", width: 300 },
        { header: "Currency Name", field: "currencyName", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailPC = {
      id: "cbMaintenanceDetailPC",
      type: "select", // search | select | select input
      url: "assets/json/prepaidCollect.json",
      placeholder: "P/C",
      code: "prepaid_collect",
      title: "prepaid_collect",
    };

    this.settingCbMaintenanceDetailPlaceOfPayment = {
      id: "cbMaintenanceDetailPlaceOfPayment",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationNamePOP={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Place of Payment",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbMaintenanceDetailPayer = {
      id: "cbMaintenanceDetailPayers",
      type: "search enter", // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerPayer/{query}/1/100',
      url:
        this.configService.config.BASE_API.toString() +
        //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}",
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}",
        // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Payer",
      title: "customerName", // variable name
      description: "",
      isMark: true,
      align: "right",
      columns: [
        //{header: 'Customer Id', field: 'customerId', width:100},
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Customer Code", field: "customerCode", width: 150 },
        { header: "Valid?", field: "isPayer", width: 100 },
        { header: "COC Acct Code", field: "cocAccountCode", width: 150 },
        { header: "SOC Acct Code", field: "socAccountCode", width: 150 },
        { header: "SLOT Acct Code", field: "slotAccountCode", width: 150 },
      ],
    };

    this.settingDateMaintenanceEffectiveFrom = {
      id: "dateMaintenanceEffectiveFrom",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Effective From",
      customtext: "",
      maxlength: 10,
    };

    this.settingDateMaintenanceEffectiveTo = {
      id: "dateMaintenanceEffectiveTo",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "Expiry On",
      customtext: "",
      maxlength: 10,
    };

    this.setting_nctFormForContainerType = {
      id: "nctform_ctrType",
      type: "select", // search | select | select input
      url: "",
      placeholder: "Select",
      code: "containerTypeName",
      title: "containerTypeName", // variable name
    };

    this.settingRemarksAgency = {
      id: "txtRemarksAgency",
      name: "pfcRemarksAgency",
      placeholder: "Remarks Agency",
      cols: 30,
      rows: 10,
      maxlength: 255,
    };

    this.settingRemarksPrincipal = {
      id: "txtRemarksPrincipal",
      name: "pfcRemarksPrincipal",
      placeholder: "Remarks Principal",
      cols: 30,
      rows: 10,
      maxlength: 255,
    };


    this.settingCbAcctPIC = {
      id: "cbAcctPIC",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findAcctPIC/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Acct PIC",
      title: "userName", // variable name
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "User Name", field: "userName", width: 170 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Id", field: "userId", width: 50 },
      ],
    };

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
    };

    /***
     * End of Maintenance Section
     */
  }

  onGridReadyPrepaid(params) {
    this.gridApiPrepaid = params.api;
    this.gridColumnApiPrepaid = params.columnApi;
  }

  onGridReadyCollect(params) {
    this.gridApiCollect = params.api;
    this.gridColumnApiCollect = params.columnApi;
  }

  onRowSelected(event) {}

  onSelectionChanged(event) {}

  sortChangedEvent(event) {
    console.log("** sortChangedEvent **");

    let prepaidList: SurchargeDetail[] = [];
    let collectList: SurchargeDetail[] = [];

    var itemsToUpdate1 = [];
    let idx1 = 1;
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx1;
      x.no = idx1;
      itemsToUpdate1.push(x);
      prepaidList.push(x);
      idx1++;
    });
    this.gridApiPrepaid.updateRowData({ update: itemsToUpdate1 });

    var itemsToUpdate2 = [];
    let idx2 = 1;
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx2;
      x.no = idx2;
      itemsToUpdate2.push(x);
      collectList.push(x);
      idx2++;
    });
    this.gridApiCollect.updateRowData({ update: itemsToUpdate2 });

    let dets: SurchargeDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.schContainerType, dets);
  }

  ngOnInit() {
    this.rowDataPrepaid = [];
    this.rowDataCollect = [];
  }

  ngAfterViewInit() {
    // console.log("** After View Init **");
    // console.log("modeForm => " + this.modeForm);

    $(".menu .item").tab();

    this.modelDetailList = [];
    this.modelDetailListx = [];
    this.firstInit();

    if (this.modeForm == "surchargeFlagStatus") {
      // console.log("#1");

      this.pfcToolbar.label = "Surcharge Flag Status";
      this.cbReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
      );
      this.cbReceiptType.setValue("CY");
      this.cbDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
      );
      this.cbDeliveryType.setValue("CY");

      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());

      this.cbContainerStatus.setValue("ALL");
      this.cbContainerOwnership.setValue("ALL");
      this.cbDGClass.setValue("ALL");
      this.cbContainerType.setValue("ALL");
      this.cbValidInvalid.setValue("ALL");

      //this.model.schEffectiveFrom = theDate;

      //reset isLoad
      this.isLoad = false;
    } else {
      // console.log("#2");

      this.map.set(this.model.schContainerType, this.modelDetailList);
    }

    //hide button
    this.invisibleToolbarButtons = "save,copy,nct,delete,invalid";
    this.cdr.detectChanges();

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "create":
        this.handleCreate();
        break;
      case "save":
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.handleClose();
        break;
      case "print":
        this.message(
          "information",
          "Information",
          "Not yet implemented.",
          "okonly",
          { ok: "" }
        );
        break;
      case "copy":
        this.handleSaveCopy();
        break;
      case "nct":
        this.handleNCT();
        break;
      case "delete":
        this.handleDelete();
        break;
    }
  }

  eventMessage(event: any) {
    // console.log('**event message **')
    // console.log(event);
    if (event != "") {
      eval(event);
    }

    // console.log(this.dialogMultiple);
    // console.log(this.map);
    // console.log('current --> ' + this.dialogMultiple.current);
    // console.log('map size --> ' + this.dialogMultiple.settings.length);
    if (this.dialogMultiple && this.dialogMultiple.current != undefined) {
      if (this.dialogMultiple.current == this.dialogMultiple.settings.length) {
        this.dialogMultiple.current = undefined;

        if (this.map.size == 0) {
          // console.log('not save');
          this.Check_MapSize();
          return;
        } else if (this.map.size > 0) {
          // console.log('save processing');
          // console.log(this.map.size)
          this.populateData();

          if (
            (this.model.lastSurchargeFrom == "" ||
              this.model.lastSurchargeFrom == undefined) &&
            (this.model.lastSurchargeTo == "" ||
              this.model.lastSurchargeTo == undefined)
          ) {
            //if(this.model.lastSurcharge == '' || this.model.lastSurcharge == undefined){

            this.saveEvent();
          } else {
            if (
              this.model.lastSurchargeFrom == "#CREATE#" &&
              this.model.lastSurchargeTo == "#CREATE#"
            ) {
              this.saveEvent();
            } else {
              this.message(
                "information",
                "Information",
                "Do you want to expire the old charges ?",
                "okcancel",
                {
                  ok: "this.saveEvent();",
                  cancel: "this.genericUtil.hideLoader();",
                }
              );
            }
          }
        }
      }
    }

  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false).modal("show");
  }

  rowDoubleClick(params) {
    let md: SurchargeDetail = params.data;

    this.isErrorDetail = false;
    this.clearValidatorErrorDetail();

    //set update flag to true
    this.isUpdateDetail = true;

    this.modelDetail = new SurchargeDetail();
    this.modelDetail.schDetailChargeCode = md.schDetailChargeCode;
    this.modelDetail.schDetailChargeName = md.schDetailChargeName;
    this.modelDetail.schDetailContainerType = md.schDetailContainerType;
    switch (this.modelDetail.schDetailContainerType) {
      case "STD":
        this.modelDetail.schDetailContainerTypeName = "NORMAL";
        break;
      case "DRY":
        this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
        break;
      case "REF":
        this.modelDetail.schDetailContainerTypeName = "REEFER";
        break;
      case "TNK":
        this.modelDetail.schDetailContainerTypeName = "TANK";
        break;
      case "OPT":
        this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
        break;
      case "PLT":
        this.modelDetail.schDetailContainerTypeName = "PLATFORM";
        break;
      case "FLR":
        this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
        break;
      case "UC":
        this.modelDetail.schDetailContainerTypeName = "UC";
        break;
      default:
        break;
    }
    this.modelDetail.schDetailCurrency = md.schDetailCurrency;
    this.modelDetail.schDetailD20 = md.schDetailD20;
    this.modelDetail.schDetailD40 = md.schDetailD40;
    this.modelDetail.schDetailD45 = md.schDetailD45;
    this.modelDetail.schDetailFAIGroup = md.schDetailFAIGroup;
    this.modelDetail.schDetailH20 = md.schDetailH20;
    this.modelDetail.schDetailH40 = md.schDetailH40;
    this.modelDetail.schDetailH45 = md.schDetailH45;
    this.modelDetail.schDetailKT = md.schDetailKT;
    this.modelDetail.schDetailKillSlot = md.schDetailKillSlot;
    this.modelDetail.schDetailM3 = md.schDetailM3;
    this.modelDetail.schDetailMinCharge = md.schDetailMinCharge;
    this.modelDetail.schDetailOogDoorOpen = md.schDetailOogDoorOpen;
    if (this.modelDetail.schDetailOogDoorOpen == "N") {
      this.modelDetail.schDetailOogDoorOpenName = "N/A";
    } else if (this.modelDetail.schDetailOogDoorOpen == "D") {
      this.modelDetail.schDetailOogDoorOpenName = "Detached";
    } else if (this.modelDetail.schDetailOogDoorOpen == "B") {
      this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
    } else if (this.modelDetail.schDetailOogDoorOpen == "L") {
      this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
    } else if (this.modelDetail.schDetailOogDoorOpen == "R") {
      this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
    }
    this.modelDetail.schDetailOogOH = md.schDetailOogOH;
    this.modelDetail.schDetailOogOLB = md.schDetailOogOLB;
    this.modelDetail.schDetailOogOLF = md.schDetailOogOLF;
    this.modelDetail.schDetailOogOWL = md.schDetailOogOWL;
    this.modelDetail.schDetailOogOWR = md.schDetailOogOWR;
    this.modelDetail.schDetailOogUcH = md.schDetailOogUcH;
    this.modelDetail.schDetailOogUcL = md.schDetailOogUcL;
    this.modelDetail.schDetailOogUcW = md.schDetailOogUcW;
    this.modelDetail.schDetailPC = md.schDetailPC;
    this.modelDetail.schDetailPCTab = md.schDetailPCTab;
    this.modelDetail.schDetailPayer = md.schDetailPayer;
    this.modelDetail.schDetailPayerName = md.schDetailPayerName;
    this.modelDetail.schDetailPlaceOfPayment = md.schDetailPlaceOfPayment;
    this.modelDetail.schDetailPlaceOfPaymentName =
      md.schDetailPlaceOfPaymentName;
    this.modelDetail.schDetailRT = md.schDetailRT;
    this.modelDetail.schDetailSeqNo = md.schDetailSeqNo;
    this.modelDetail.schDetailStatus = md.schDetailStatus;

    //OOG Control
    //Infer from selected container type
    if (
      this.modelDetail.schDetailContainerType == "STD" ||
      this.modelDetail.schDetailContainerType == "DRY"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = false;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.schDetailContainerType == "REF" ||
      this.modelDetail.schDetailContainerType == "TNK"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.schDetailContainerType == "PLT" ||
      this.modelDetail.schDetailContainerType == "FLR"
    ) {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.schDetailContainerType == "OPT") {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.schDetailContainerType == "UC") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    }

    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.schDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.schDetailCurrency
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.schDetailPC);

    this.cbMaintenanceDetailPlaceOfPayment.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationCode={query}"
    );
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.schDetailPlaceOfPayment
    );

    this.cbMaintenanceDetailPayer.setUrl(
      this.configService.config.BASE_API.toString() +
        //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
        // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
        // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
        "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
    );
    this.fpPayer = '';
    this.cbMaintenanceDetailPayer.setValue(this.modelDetail.schDetailPayer);

    //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('show');
    this.genericUtil.showDialog(
      "freightNewChargeForm",
      "Surcharge Entry Screen",
      1200,
      500
    );
  }

  handleNCT() {
    // console.log(String(this.model.schContainerType).toUpperCase());

    var self = this;
    this.nctform_ctrType.listStore.store = [];

    let no: number = 0;
    this.map.forEach((value: any[], key: string) => {
      if (
        String(self.model.schContainerType).toUpperCase() != key &&
        value.length > 0
      ) {
        // console.log(key);
        var ct: string;
        switch (key) {
          case "TNK":
            ct = "TANK";
            break;
          case "OPT":
            ct = "OPEN TOP";
            break;
          case "FLR":
            ct = "FLAT RACK";
            break;
          case "PLT":
            ct = "PLATFORM";
            break;
          case "REF":
            ct = "REEFER";
            break;
          case "DRY":
            ct = "REEFER DRY";
            break;
          case "UC":
            ct = "UC";
            break;
          default:
            ct = "NORMAL";
            break;
        }
        self.nctform_ctrType.listStore.addData({
          no: no++,
          code: key,
          title: ct,
        });
      }
    });

    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('show');
    */
    this.genericUtil.showDialog("NCTForm", "NCT Form", 300, 200);
  }

  firstInit() {
    console.log("MASUK SINI");

    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    console.log("MENU ID : " + this.currentMenu);
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
    }
  }

  onHistoryDialogOk() {
    this.genericUtil.closeDialog("historyDialog");
  }

  onNCT_Save() {
    // console.log("** on NCT save **")
    this.message(
      "information",
      "Confirm",
      "Do you really want to CREATE a New Cntr. Type to the current record?",
      "yesno",
      { yes: "this.onNCT_Save_Process()", no: "" }
    );
  }

  onNCT_Save_Process() {
    // console.log("** on NCT save process **")
    var ct: any = this.nctform_ctrType.getValue();
    var result: SurchargeDetail[] = this.map.get(ct["code"]);
    var result2: SurchargeDetail[] = [];
    result.forEach((element) => {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = element.schDetailChargeCode;
      x.schDetailChargeName = element.schDetailChargeName;
      x.schDetailContainerType = String(
        this.model.schContainerType
      ).toUpperCase();
      x.schDetailCopyTab = element.schDetailCopyTab;
      x.schDetailCurrency = element.schDetailCurrency;
      x.schDetailD20 = element.schDetailD20;
      x.schDetailD40 = element.schDetailD40;
      x.schDetailD45 = element.schDetailD45;
      x.schDetailFAIGroup = element.schDetailFAIGroup;
      x.schDetailH20 = element.schDetailH20;
      x.schDetailH40 = element.schDetailH40;
      x.schDetailH45 = element.schDetailH45;
      x.schDetailKT = element.schDetailKT;
      x.schDetailKillSlot = element.schDetailKillSlot;
      x.schDetailM3 = element.schDetailM3;
      x.schDetailMinCharge = element.schDetailMinCharge;
      x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = element.schDetailOogOH;
      x.schDetailOogOLB = element.schDetailOogOLB;
      x.schDetailOogOLF = element.schDetailOogOLF;
      x.schDetailOogOWL = element.schDetailOogOWL;
      x.schDetailOogOWR = element.schDetailOogOWR;
      x.schDetailOogUcH = element.schDetailOogUcH;
      x.schDetailOogUcL = element.schDetailOogUcL;
      x.schDetailOogUcW = element.schDetailOogUcW;
      x.schDetailPC = element.schDetailPC;
      x.schDetailPCTab = element.schDetailPCTab;
      x.schDetailPayer = element.schDetailPayer;
      x.schDetailPayerName = element.schDetailPayerName;
      x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
      x.schDetailRT = element.schDetailRT;
      x.schDetailSeqNo = element.schDetailSeqNo;
      x.schDetailStatus = element.schDetailStatus;
      x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

      if (String(this.model.schContainerType).toUpperCase() == "TNK") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogOH = 0;
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "REF") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogOH = 0;
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "OPT") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "FLR") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "PLT") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "DRY") {
        x.schDetailOogOH = 0;
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "UC") {
        x.schDetailOogDoorOpen = "N";
        x.schDetailOogOH = 0;
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;

        result2.push(x);
      } else if (String(this.model.schContainerType).toUpperCase() == "STD") {
        x.schDetailOogOH = 0;
        x.schDetailOogOWL = 0;
        x.schDetailOogOWR = 0;
        x.schDetailOogOLF = 0;
        x.schDetailOogOLB = 0;
        x.schDetailOogUcH = 0;
        x.schDetailOogUcL = 0;
        x.schDetailOogUcW = 0;

        result2.push(x);
      }
    });

    if (result2.length > 0) {
      let idx = 1;
      let idx2 = 1;
      result2.forEach((element) => {
        var x: SurchargeDetail = element;
        if (x.schDetailPCTab == "P") {
          x.schDetailSeqNo = idx;
          x.no = idx;
          idx++;
        }

        if (x.schDetailPCTab == "C") {
          x.schDetailSeqNo = idx2;
          x.no = idx2;
          idx2++;
        }
      });
      this.map.set(String(this.model.schContainerType).toUpperCase(), result2);

      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();
      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);

      var detailList: any[] = this.map.get(this.model.schContainerType);
      if (detailList != null && detailList != undefined) {
        detailList.forEach((element) => {
          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = element.schDetailChargeCode;
          x.schDetailChargeName = element.schDetailChargeName;
          x.schDetailContainerType = element.schDetailContainerType;
          var ct: string;
          switch (x.schDetailContainerType) {
            case "TNK":
              ct = "TANK";
              break;
            case "OPT":
              ct = "OPEN TOP";
              break;
            case "FLR":
              ct = "FLAT RACK";
              break;
            case "PLT":
              ct = "PLATFORM";
              break;
            case "REF":
              ct = "REEFER";
              break;
            case "DRY":
              ct = "REEFER DRY";
              break;
            case "UC":
              ct = "UC";
              break;
            default:
              ct = "NORMAL";
              break;
          }
          x.schDetailContainerTypeName = ct;
          x.schDetailCopyTab = element.schDetailCopyTab;
          x.schDetailCurrency = element.schDetailCurrency;
          x.schDetailD20 = element.schDetailD20;
          x.schDetailD40 = element.schDetailD40;
          x.schDetailD45 = element.schDetailD45;
          x.schDetailFAIGroup = element.schDetailFAIGroup;
          x.schDetailH20 = element.schDetailH20;
          x.schDetailH40 = element.schDetailH40;
          x.schDetailH45 = element.schDetailH45;
          x.schDetailKT = element.schDetailKT;
          x.schDetailKillSlot = element.schDetailKillSlot;
          x.schDetailM3 = element.schDetailM3;
          x.schDetailMinCharge = element.schDetailMinCharge;
          x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
          if (x.schDetailOogDoorOpen == "N") {
            x.schDetailOogDoorOpenName = "N/A";
          } else if (x.schDetailOogDoorOpen == "D") {
            x.schDetailOogDoorOpenName = "Detached";
          } else if (x.schDetailOogDoorOpen == "B") {
            x.schDetailOogDoorOpenName = "Tied Both";
          } else if (x.schDetailOogDoorOpen == "L") {
            x.schDetailOogDoorOpenName = "Tied Left";
          } else if (x.schDetailOogDoorOpen == "R") {
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = element.schDetailOogOH;
          x.schDetailOogOLB = element.schDetailOogOLB;
          x.schDetailOogOLF = element.schDetailOogOLF;
          x.schDetailOogOWL = element.schDetailOogOWL;
          x.schDetailOogOWR = element.schDetailOogOWR;
          x.schDetailOogUcH = element.schDetailOogUcH;
          x.schDetailOogUcL = element.schDetailOogUcL;
          x.schDetailOogUcW = element.schDetailOogUcW;
          x.schDetailPC = element.schDetailPC;
          x.schDetailPCTab = element.schDetailPCTab;
          x.schDetailPayer = element.schDetailPayer;
          x.schDetailPayerName = element.schDetailPayerName;
          x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
          x.schDetailRT = element.schDetailRT;
          x.schDetailSeqNo = element.schDetailSeqNo;
          x.schDetailStatus = element.schDetailStatus;

          /*
          if (x.schDetailPCTab == 'P') {
            this.gridPrepaid.listStore.store.push(x);
            this.gridPrepaid.loadData();
          }

          if (x.schDetailPCTab == 'C') {
            this.gridCollect.listStore.store.push(x);
            this.gridCollect.loadData();
          }
          */
          if (x.schDetailPCTab == "P") {
            this.gridApiPrepaid.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }

          if (x.schDetailPCTab == "C") {
            this.gridApiCollect.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }
        });

        this.calculateTotal();
      }

      this.message(
        "information",
        "Information",
        " Rates Copied Successfully.",
        "okonly",
        {
          ok: "this.disableToolbarButtons = 'copy,delete';this.closeDialog('NCTForm')",
        }
      );
      return;
    } else {
      this.message("information", "Information", "Unable to copy.", "okonly", {
        ok: "this.closeDialog('NCTForm')",
      });
      return;
    }
  }

  onNCT_Cancel(event: any) {
    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('hide');
    */
    this.genericUtil.closeDialog("NCTForm");
  }

  value_nctFormForContainerType(event: any) {
    // console.log("selected nct value : " + event);
  }

  //used to handle retrieve event
  //When user click button retrieve the action goed to this routine

  handleRetrieve() {
    // console.log("this.model.schSurchargeId --> " + this.model.schSurchargeId);
    this.model.schEffectiveFrom = this.dateEffectiveFrom.getValue();
    this.model.schEffectiveTo = this.dateEffectiveTo.getValue();

    this.mainGrid.curPage = 1;
    this.mainGrid.currentPage = 1;

    this.mainGrid.onClear();
    this.mainGrid.setBody(this.model);
    this.isLoad = true;
    this.mainGrid.loadData();
    this.isError = false;
  }

  //user to save the data
  handleSave() {
    // console.log("** handle Save **")
    this.isCopy = false;
    this.model.isCopy = false;
    this.onSave();
  }

  handleSaveCopy() {
    /*
    this.isCopy = true;
    this.model.isCopy = true;
    this.onSave();
    */
    this.lockHeader = false;
    this.model.schEffectiveTo = "";
    this.dateMaintenanceEffectiveTo.setValue("");

    this.model.schEffectiveFrom = "";

    //reset effective to
    let currentDate = new Date();
    let theDate =
      this.genericUtil.pad2(currentDate.getFullYear()) +
      "-" +
      this.genericUtil.pad2(currentDate.getMonth() + 1) +
      "-" +
      this.genericUtil.pad2(currentDate.getDate());
    this.dateMaintenanceEffectiveFrom.setValue(theDate);

    this.model.schSurchargeId = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
    } else {
      this.disableToolbarButtons = "copy,delete";
    }
    this.dateCreatedForCbPayer = "*";
    this.custIdForCbPayer = "*";
    this.listPayerExisting = [];
  }

  onSave() {
    //set the office code
    this.model.schOfficeCode = this.cookieService.getDefaultLocationCode();

    //set effective from and to
    this.model.schEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.schEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    //set user created and modified
    let userId = this.cookieService.getName().toUpperCase();
    let theDate = this.genericUtil.getPSIDate();
    if (
      this.model.schSurchargeId == null ||
      this.model.schSurchargeId == "" ||
      this.model.schSurchargeId == undefined
    ) {
      this.model.schUserCreated = userId;
      this.model.schDateCreated = theDate;
      this.model.schUserModified = userId;
      this.model.schDateModified = theDate;
    } else {
      this.model.schUserModified = userId;
      this.model.schDateModified = theDate;
    }

    // console.log("this.model.schIsValid => " + this.model.schIsValid);

    if (this.model.schIsValid == "N") {
      this.model.schUserInvalid = userId;
      this.model.schDateInvalid = theDate;
    } else {
      this.model.schUserInvalid = "";
      this.model.schDateInvalid = "";
    }

    this.setValidatorSave();
    this.model["effectiveDateExceedValidator"] =
      this.checkEffectiveDateExceedValidator();
    this.model["expiryDateValidator"] = this.checkExpiryDateValidator();

    if (this.model.schSurchargeId != "") {
      if (this.model["expiryDateValidator"] == false) {
        if (this.model.schEffectiveFrom > this.lastEffectiveTo) {
          this.model.isCreate = "#CREATE#";
        }

        if (this.model.schEffectiveTo < this.lastEffectiveTo) {
          this.model["expiryDateValidator"] = true;
          this.model["error-expiryDateValidator"] =
            "Operation not allowed. Please check existing record or effective/expiry dates.";
        }
      }
    }

    this.model["samePORValidator"] = this.checkSamePORValidator();
    this.model["samePOLValidator"] = this.checkSamePOLValidator();
    this.model["samePODValidator"] = this.checkSamePODValidator();
    this.model["samePODLValidator"] = this.checkSamePODLValidator();
    this.model["sameFDestValidator"] = this.checkSameFDestValidator();
    this.model["blankFreightChargesValidator"] =
      this.checkBlankFreightChargesValidator();
    this.model["blankDetentionRequestValidator"] =
      this.checkBlankDetentionRequestValidator();
    this.model["blankDemurrageRequestValidator"] =
      this.checkBlankDemurrageRequestValidator();
    this.model["blankAdhocVoyageValidator"] =
      this.checkBlankAdhocVoyageValidator();
    this.model["checkDuplicateValidator"] = this.checkDuplicateValidator();
    // console.log("** before validate **");
    // console.log(this.model);
    // console.log(this.map);

    //begin of main handle save

    this.isError = this.onValidate(this.model);
    if (this.isError) {
      window.scrollTo(0, 0);
    } else {
      let modelKey = new SurchargeMaster();
      modelKey.schOfficeCode = this.model.schOfficeCode;
      modelKey.schFreightPartyId = this.model.schFreightPartyId;
      modelKey.schBookingPartyId = this.model.schBookingPartyId;
      modelKey.schShipperId = this.model.schShipperId;
      modelKey.schPlaceOfReceiptCode = this.model.schPlaceOfReceiptCode;
      modelKey.schLoadPortCode = this.model.schLoadPortCode;
      modelKey.schDischargePortCode = this.model.schDischargePortCode;
      modelKey.schPlaceOfDeliveryCode = this.model.schPlaceOfDeliveryCode;
      modelKey.schFinalDestinationCode = this.model.schFinalDestinationCode;

      modelKey.schPortOfTransshipment1Code =
        this.model.schPortOfTransshipment1Code;
      modelKey.schPortOfTransshipment2Code =
        this.model.schPortOfTransshipment2Code;
      modelKey.schPortOfTransshipment3Code =
        this.model.schPortOfTransshipment3Code;
      modelKey.schCommodityId = this.model.schCommodityId;
      modelKey.schReceiptTypeCode = this.model.schReceiptTypeCode;
      modelKey.schDeliveryTypeCode = this.model.schDeliveryTypeCode;
      modelKey.schContainerOwnership = this.model.schContainerOwnership;
      modelKey.schContainerStatus = this.model.schContainerStatus;
      modelKey.schShipmentType = this.model.schShipmentType;
      modelKey.schVesselId = this.model.schVesselId;
      modelKey.schVoyage = this.model.schVoyage;
      modelKey.schDetentionPOL = this.model.schDetentionPOL;
      modelKey.schDetentionRequestPOL = this.model.schDetentionRequestPOL;
      modelKey.schDemurragePOL = this.model.schDemurragePOL;
      modelKey.schDemurrageRequestPOL = this.model.schDemurrageRequestPOL;
      modelKey.schDetention = this.model.schDetention;
      modelKey.schDetentionRequest = this.model.schDetentionRequest;
      modelKey.schDemurrage = this.model.schDemurrage;
      modelKey.schDemurrageRequest = this.model.schDemurrageRequest;
      modelKey.schDgClass = this.model.schDgClass;
      modelKey.schIsValid = this.model.schIsValid;

      let currentSurchargeMasterKeyExpiryOn =
        this.getSurchargeMasterKeyStringExpiryOn(modelKey);
      let currentSurchargeMasterKey =
        this.getSurchargeMasterKeyString(modelKey);
      this.currentSurchargeMasterKeyValidate = currentSurchargeMasterKey;
      let currentSurchargeMaster = this.getSurchargeMasterString(this.model);

      let isValidCurrent = modelKey.schIsValid;

      let currentSurchargeDetails = "";
      let currentContainerTypeList: any[] = [];
      this.model.schSurchargeDetails = [];

      this.map.forEach((value: SurchargeDetail[], key: string) => {
        // console.log(key);
        currentContainerTypeList.push(key);
        this.currentContainerTypeListValidate.push(key);

        value.forEach((element) => {
          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = element.schDetailChargeCode;
          x.schDetailChargeName = element.schDetailChargeName;
          x.schDetailContainerType = element.schDetailContainerType;
          x.schDetailCopyTab = element.schDetailCopyTab;
          x.schDetailCurrency = element.schDetailCurrency;
          x.schDetailD20 = element.schDetailD20;
          x.schDetailD40 = element.schDetailD40;
          x.schDetailD45 = element.schDetailD45;
          x.schDetailFAIGroup = element.schDetailFAIGroup;
          x.schDetailH20 = element.schDetailH20;
          x.schDetailH40 = element.schDetailH40;
          x.schDetailH45 = element.schDetailH45;
          x.schDetailKT = element.schDetailKT;
          x.schDetailKillSlot = element.schDetailKillSlot;
          x.schDetailM3 = element.schDetailM3;
          x.schDetailMinCharge = element.schDetailMinCharge;
          x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
          if (x.schDetailOogDoorOpen == "N") {
            x.schDetailOogDoorOpenName = "N/A";
          } else if (x.schDetailOogDoorOpen == "D") {
            x.schDetailOogDoorOpenName = "Detached";
          } else if (x.schDetailOogDoorOpen == "B") {
            x.schDetailOogDoorOpenName = "Tied Both";
          } else if (x.schDetailOogDoorOpen == "L") {
            x.schDetailOogDoorOpenName = "Tied Left";
          } else if (x.schDetailOogDoorOpen == "R") {
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = element.schDetailOogOH;
          x.schDetailOogOLB = element.schDetailOogOLB;
          x.schDetailOogOLF = element.schDetailOogOLF;
          x.schDetailOogOWL = element.schDetailOogOWL;
          x.schDetailOogOWR = element.schDetailOogOWR;
          x.schDetailOogUcH = element.schDetailOogUcH;
          x.schDetailOogUcL = element.schDetailOogUcL;
          x.schDetailOogUcW = element.schDetailOogUcW;
          x.schDetailPC = element.schDetailPC;
          x.schDetailPCTab = element.schDetailPCTab;
          x.schDetailPayer = element.schDetailPayer;
          x.schDetailPayerName = element.schDetailPayerName;
          x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
          x.schDetailRT = element.schDetailRT;
          x.schDetailSeqNo = element.schDetailSeqNo;
          x.schDetailStatus = element.schDetailStatus;
          x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

          currentSurchargeDetails += this.getSurchargeDetailString(
            x,
            this.model.schIsValid
          );
          this.model.schSurchargeDetails.push(x);
        });
      });

      // console.log("** currentContainerTypeList **");
      // console.log(currentContainerTypeList);

      // console.log("** retrieveSurchargeMaster **");
      // console.log(this.surchargeMasterRetrieve);
      // console.log("** currentSurchargeMaster **");
      // console.log(currentSurchargeMaster);
      // console.log("***************************************************************************************************************************************");
      // console.log("** retrieveSurchargeDetails **");
      // console.log(this.surchargeDetailsRetrieve);
      // console.log("** currentSurchargeDetails **");
      // console.log(currentSurchargeDetails);
  if(((this.model.schBookingPartyId !== "" && this.model.schBookingPartyId !== null) || (this.model.schShipperId !== "" && this.model.schShipperId !== null))
       && (this.model.schFreightPartyId == "" || this.model.schFreightPartyId == null)){

        this.message(
          "information",
          "Information",
          "Please fill in Freight Party.",
          "okonly",
          { ok: "" }
        );
        return;
    }else{

      if (
        this.surchargeMasterRetrieve == currentSurchargeMaster &&
        this.surchargeDetailsRetrieve == currentSurchargeDetails
      ) {
        this.message(
          "information",
          "Information",
          "No changes has been detected.",
          "okonly",
          { ok: "" }
        );
        return;
      } else {
        if (this.surchargeMasterRetrieve != currentSurchargeMaster) {
          // console.log("** header changed **");

          console.log("** is After save **");
          console.log(this.isAfterSave);

          if (this.isAfterSave) {
            this.model.schSurchargeId = "";
          }

          if (this.model.schSurchargeId == "") {

            //New
            this.genericUtil.showLoader();
            this.genericService
              .POST(
                this.configService.config.BASE_API.toString() +
                  "/SurchargeMaster/validateSave/",
                this.model
              )
              .subscribe((resp) => {
                if (resp.status == 200) {
                  let result = resp.json();

                  if (result.status == 1 || result.status == 2) {
                    this.genericUtil.hideLoader();
                    this.message(
                      "information",
                      "Information",
                      result.message,
                      "okonly",
                      { ok: "" }
                    );
                    return;
                  } else {
                    this.model.isCreate = result.isCreate;

                    this.model.lastSurchargeFromStatus =
                      result.lastSurchargeFromStatus;
                    this.model.lastSurchargeFrom = result.lastSurchargeFrom;
                    this.model.lastSurchargeFromStart =
                      result.lastSurchargeFromStart;
                    this.model.lastSurchargeFromEnd = result.lastSurchargeFromEnd;

                    this.model.lastSurchargeToStatus =
                      result.lastSurchargeToStatus;
                    this.model.lastSurchargeTo = result.lastSurchargeTo;
                    this.model.lastSurchargeToStart = result.lastSurchargeToStart;
                    this.model.lastSurchargeToEnd = result.lastSurchargeToEnd;

                    // console.log("** surchargeMasterKeyRetrieve **");
                    // console.log(this.surchargeMasterKeyRetrieve);
                    // console.log("** currentsurchargeMasterKey **");
                    // console.log(currentSurchargeMasterKey);

                    if (this.model.schSurchargeId == "") {
                      this.message(
                        "information",
                        "Information",
                        "Do you really want to create a New record?",
                        "okcancel",
                        {
                          ok: "this.validateAfterSaveNew();",//this.ClearRecordId();this.UpdateMap();
                          cancel: "this.genericUtil.hideLoader();",
                        }
                      );
                      return;
                    } else {
                      if (
                        this.surchargeMasterKeyRetrieve ==
                        currentSurchargeMasterKey
                      ) {
                        if (currentContainerTypeList > this.containerTypeList) {
                          //new CType
                          this.message(
                            "information",
                            "Information",
                            "Do you really want to Create a new cntr type to the existing record?",
                            "okcancel",
                            {
                              ok: "this.UpdateMap();",
                              cancel: "this.genericUtil.hideLoader();",
                            }
                          );
                          return;
                        } else {
                          if (this.model.isCreate == "#CREATE#") {
                            //this.model.schSurchargeId = "";
                            this.message(
                              "information",
                              "Information",
                              "Do you really want to create a New record?",
                              "okcancel",
                              {
                                ok: "this.validateAfterSaveNew();", //this.ClearRecordId();this.UpdateMap();
                                cancel: "this.genericUtil.hideLoader();",
                              }
                            );
                            return;
                          } else {
                            this.message(
                              "information",
                              "Information",
                              "Are you sure to update the existing Reference No ?",
                              "okcancel",
                              {
                                ok: "this.UpdateMap();",
                                cancel: "this.genericUtil.hideLoader();",
                              }
                            );
                            return;
                          }
                        }
                      } else {
                        //this.model.schSurchargeId = "";
                        this.message(
                          "information",
                          "Information",
                          "Do you really want to create a New record?",
                          "okcancel",
                          {
                            ok: "this.validateAfterSaveNew();", //this.ClearRecordId();this.UpdateMap();
                            cancel: "this.genericUtil.hideLoader();",
                          }
                        );
                        return;
                      }
                    }
                  }
                }
              });
              //End New
          }else{
            console.log("invalid kudunya kesini");
            console.log("** retrieve Surcharge **");
            console.log(this.surchargeMasterKeyRetrieve);
            console.log("** current Surcharge **");
            console.log(currentSurchargeMasterKey);
            // console.log("***************************************************************************************************************************************");
            console.log("** surchargeMasterKeyRetrieveExpiryOn **");
            console.log(this.surchargeMasterKeyRetrieveExpiryOn);
            console.log("** currentSurchargeMasterKeyExpiryOn **");
            console.log(currentSurchargeMasterKeyExpiryOn);
            if (
              this.surchargeMasterKeyRetrieve ==
                currentSurchargeMasterKey &&
              this.surchargeMasterKeyRetrieveExpiryOn ==
                currentSurchargeMasterKeyExpiryOn
            ) {

                //check Just Invalid or not
                console.log(this.isValidRetrieve);
                console.log(isValidCurrent);
                if (this.isValidRetrieve != isValidCurrent) {
                  this.message(
                    "information",
                    "Information",
                    "Are you sure to update the existing Reference No ?",
                    "okcancel",
                    {
                      ok: "this.saveEvent();",
                      cancel: "this.genericUtil.hideLoader();",
                    }
                  );
                  return;
                } else {

                  console.log("masuk ke validate process");
                  this.message(
                    "information",
                    "Information",
                    "Are you sure to update the existing Reference No ?",
                    "okcancel",
                    {
                      ok: "this.validateProsess();",
                      cancel: "this.genericUtil.hideLoader();",
                    }
                  );
                  return;
                }

            }else{
              console.log("piye cuk..");

              this.message(
                "information",
                "Information",
                "Please click Save and Copy to proceed new Creation",
                "okonly",
                {
                  ok: "this.genericUtil.hideLoader();",
                }
              );

              this.isError = false;
              this.validatorRules = {};
              this.model["error-schFreightPartyId"] = "";

              if (this.model.schFreightPartyId.length > 0 && this.searchFpIsValid == "N") {
                this.model["error-schFreightPartyId"] = "Freight Party is invalid, unable to proceed with creation";
                this.isError = true;
              }

              if (this.model.schFreightPartyId.length > 0 && this.searchFpIsFreightParty == "N") {
                this.model["error-schFreightPartyId"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
                this.isError = true;
              }

              if (this.isError) {
                window.scrollTo(0, 0);
                return ;
              }


            }
          }

        } else {
          // console.log(this.containerTypeList.length + " - " + currentContainerTypeList.length);

          if (currentContainerTypeList > this.containerTypeList) {
            //new CType
            this.message(
              "information",
              "Information",
              "Do you really want to Create a new cntr type to the existing record?",
              "okcancel",
              {
                ok: "this.UpdateMap();",
                cancel: "this.genericUtil.hideLoader();",
              }
            );
            return;
          } else {
            // console.log("** details changed **");
            //Update Details
            this.message(
              "information",
              "Information",
              "Do you really want to OVERWRITE the record?",
              "okcancel",
              { ok: "this.saveEvent();", cancel: "" }
            );
            return;
          }
        }
      }
    }
    }

    //end of main handle save
  }

  nextProcessUpdateDetail1() {
    this.message(
      "information",
      "Information",
      "Do you really want to Create a new cntr type to the existing record?",
      "okcancel",
      { ok: "this.UpdateMap();", cancel: "this.genericUtil.hideLoader();" }
    );
    return;
  }

  nextProcessUpdateDetail2() {
    this.message(
      "information",
      "Information",
      "Do you really want to OVERWRITE the record?",
      "okcancel",
      { ok: "this.saveEvent();", cancel: "" }
    );
    return;
  }

  saveEvent() {
    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/SurchargeMaster/save",
        this.model
      )
      .subscribe(
        (resp) => {
          let data = resp.json();

          this.model.schSurchargeId = data.schSurchargeId;
          this.model.schEffectiveFrom = data.schEffectiveFrom;
          this.model.schEffectiveTo = data.schEffectiveTo;

          // console.log(this.model);
          this.genericUtil.hideLoader();
          if (this.isCopy) {
            this.message(
              "information",
              "Information",
              "Saved successfully with Reference No " +
                this.model.schSurchargeId,
              "okonly",
              { ok: "this.loading=false;this.afterSaveCopy();" }
            );
          } else {
            this.message(
              "information",
              "Information",
              "Saved successfully with Reference No " +
                this.model.schSurchargeId,
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterSave() {
    // console.log("** after save **");
    this.isCopy = false;
    this.afterClosedFromMaintenance();
    //do nothing for now
    //this.lock = true;

    /*
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/retrieveFreightApplicationForUpdate/' + this.model.schSurchargeId.trim() + '/' + this.model.schContainerType).subscribe((resp) => {
      //// console.log(resp);
      this.handleDoubleClick(resp.json()["content"]);
    });
    */
  }

  afterSaveCopy() {
    // console.log("** after save copy **");
    //do nothing for now
    //this.lock = true;
    this.model.schSurchargeId = "";
    this.isCopy = false;
    this.isAfterSave = true;

    if (
      this.model.schEffectiveFrom !== null &&
      this.model.schEffectiveFrom !== "" &&
      this.model.schEffectiveFrom !== undefined
    ) {
      this.dateMaintenanceEffectiveFrom.setValue(this.model.schEffectiveFrom);
    }

    this.dateMaintenanceEffectiveTo.setValue(this.model.schEffectiveTo);
  }

  handleDelete() {
    this.modelDelete = new SurchargeMaster();
    this.message(
      "information",
      "Information",
      "Are you sure want to delete this record?",
      "yesno",
      {
        yes: "this.genericUtil.showDialog('DeleteForm', 'Delete Form', 300, 300)",
        no: "",
      }
    );
  }

  deleteProcess() {
    // console.log("** delete process **");
    //this.modelDelete =  new SurchargeMaster();
    this.modelDelete.schSurchargeId = this.model.schSurchargeId;
    this.modelDelete.schIsDeleted = "Y";
    this.modelDelete.schUserDeleted = this.cookieService
      .getName()
      .toUpperCase();
    this.modelDelete.schDateDeleted = this.genericUtil.getPSIDate();
    //this.modelDelete.schReasonDeleted = document.getElementById("reasonDelete")

    this.modelDelete["error-schReasonDeleted"] = "";
    this.validatorRules = {
      schReasonDeleted: {
        rules: [{ type: "empty", prompt: "Please input Reason." }],
      },
    };
    this.isErrorDeleted = this.onValidate(this.modelDelete);

    if (!this.isErrorDeleted) {
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/SurchargeMaster/delete",
          this.modelDelete
        )
        .subscribe((resp) => {
          // console.log(resp.json()['status']);
          if (resp.json()["status"] == "OK") {
            this.lock = true;
            if (this.viewOnlyStatus) {
              this.disableToolbarButtons =
                "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
            } else {
              this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
            }
            this.message(
              "information",
              "Information",
              "Deleted Successfully.",
              "okonly",
              { ok: "this.afterClosedFromMaintenance()" }
            );
          } else {
          }
          this.isErrorDeleted = false;
          this.modelDelete = new SurchargeMaster();
          this.genericUtil.closeDialog("DeleteForm");
          //this.gridPrepaid.loadData();
          //this.gridCollect.loadData();
        });
    }
  }

  mainGridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
        } else {
          this.disableToolbarButtons = "delete";
        }
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split("-")[1];

        this.mainGrid.clearSelectedValues();
        this.mainGrid.onSelect(true, Number.parseInt(no));

        break;
      case "afterLoad":
        console.log(this.mainGrid.listStore.store);
        console.log(this.mainGrid.listStore.store.length);
        setTimeout(() => {
          if (this.mainGrid.listStore.store.length == 0) {
            if (this.isLoad) {
              this.message(
                "information",
                "Information",
                "No record(s) found.",
                "okonly",
                { ok: "this.loading=false;this.isLoad=false;" }
              );
            }
          }
        }, 1000);

      //this.mainGrid.onSelect(true,1);

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              //handle double Clicked
              let dt = this.mainGrid.getSelectedValues()[0];

              this.genericService
                .GET(
                  this.configService.config.BASE_API.toString() +
                    "/SurchargeMaster/retrieveSurchargeForUpdate/" +
                    dt.schSurchargeId.trim() +
                    "/" +
                    dt.schCType
                )
                .subscribe((resp) => {
                  //// console.log(resp);

                  var x: SurchargeMaster = new SurchargeMaster();
                  x = resp.json()["content"];

                  let schSurchargeDetails = x.schSurchargeDetails;
                  schSurchargeDetails.forEach(ele => {
                    if (ele.isValidPayer === "N") {
                      let customerIdList = schSurchargeDetails.map(el => el.schDetailPayer);
                      let surchargeList = resp.json()["content"];
                      this.genericService
                        .GET(
                          this.configService.config.BASE_API.toString() +
                          "/MasterCustomers/findByCustomerId/customerId=" +
                          customerIdList.toString()
                        ).subscribe((data) => {
                          let reponseList = data.json();
                          reponseList.content.forEach(el => {
                            surchargeList.schSurchargeDetails.map(surcharge => {
                              if (el.customerId === surcharge.schDetailPayer) {
                                surcharge.schDetailPayer = el.customerName
                              }
                            })
                          })
                          this.handleDoubleClick(surchargeList);
                        })
                    } else {
                      this.handleDoubleClick(x);
                    }
                  })
                });

              break;
            default:
              break;
          }
        }
        break;
    }
  }

  changeEventPod(event) {}

  infoGrid(event) {}

  initTab1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";

    this.currentActiveTab = "prepaid";
  }

  initTab2() {
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";

    this.currentActiveTab = "collect";
  }

  //used when there is change is place of receipt
  changePlaceOfReceipt(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPlaceOfReceiptCode = event["locationCode"];

      //set terminal URL
      this.cbPORTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );

      //Auto set Port of Load and Port of Load Terminal
      this.model.schLoadPortCode = event["locationCode"];
      this.cbPortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbPortOfLoad.setValue(event["locationCode"]);
      this.cbPOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.schPlaceOfReceiptCode = "";
      this.cbPORTerminal.setUrl("");
    }
  }

  changePortOfLoad(event) {
    //upon changes update the terminal
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schLoadPortCode = event["locationCode"];
      this.cbPOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );

    } else {
      this.model.schLoadPortCode = "";
      this.cbPOLTerminal.setUrl("");
    }

    //revert to search by name
    this.cbPortOfLoad.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changePortOfDischarge(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schDischargePortCode = event["locationCode"];
      //set terminal URL
      this.cbPODTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );

      //Auto set Port of Load and Port of Load Terminal
      this.model.schPlaceOfDeliveryCode = event["locationCode"];
      this.cbPlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbPlaceOfDelivery.setValue(event["locationCode"]);
      this.cbPODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.schDischargePortCode = "";
      this.cbPODLTerminal.setUrl("");
    }
  }

  changePlaceOfDelivery(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPlaceOfDeliveryCode = event["locationCode"];
      this.cbPODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.schPlaceOfDeliveryCode = "";
      this.cbPODLTerminal.setUrl("");
    }
    this.cbPlaceOfDelivery.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changePortOfTransshipment(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPortOfTransshipment1Code = event["locationCode"];
      this.cbPOT1Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.schPortOfTransshipment1Code = "";
      this.cbPOT1Terminal.setUrl("");
    }
  }

  changeFinalDestination(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schFinalDestinationCode = event["locationCode"];
      this.cbFDESTTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event["locationId"] +
          "/[-42]"
      );
    } else {
      this.model.schFinalDestinationCode = "";
      this.cbFDESTTerminal.setUrl("");
    }
  }

  changePORTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPORTerminalCode = event["locationCode"];
    } else {
      this.model.schPORTerminalCode = "";
    }
  }

  changePODLTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPODLTerminalCode = event["locationCode"];
    } else {
      this.model.schPODLTerminalCode = "";
    }
  }

  changePOT1Terminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPOT1TerminalCode = event["locationCode"];
    } else {
      this.model.schPOT1TerminalCode = "";
    }
  }

  changePOLTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPOLTerminalCode = event["locationCode"];
    } else {
      this.model.schPOLTerminalCode = "";
    }
  }

  changePODTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPODTerminalCode = event["locationCode"];
    } else {
      this.model.schPODTerminalCode = "";
    }
  }

  changeFDESTTerminal(event) {
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schFDESTTerminalCode = event["locationCode"];
    } else {
      this.model.schFDESTTerminalCode = "";
    }
  }

  initNewInvalid(){
    this.isAfterSave= false;
    this.surchargeMasterKeyRetrieveExpiryOn = "";
    this.isValidRetrieve= "";
    this.currentSurchargeMasterKeyValidate ="";
    this.currentContainerTypeListValidate = [];
  }

  //used when there is change in port of transshipment
  handleCreate() {
    // console.log("** handle create **");
    this.model['schSurchargeId'] = '';
    this.isError = false;
    this.validatorRules = {};
    this.model["error-schFreightPartyId"] = "";
    this.model["error-schShipperId"] = "";
    this.model["error-schBookingPartyId"] = "";

    if (this.model.schFreightPartyId.length > 0 && this.searchFpIsValid == "N") {
      this.model["error-schFreightPartyId"] = "Freight Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.schFreightPartyId.length > 0 && this.searchFpIsFreightParty == "N") {
      this.model["error-schFreightPartyId"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
      this.isError = true;
    }

    if (this.model.schShipperId.length > 0 && this.searchShIsValid == "N") {
      this.model["error-schShipperId"] = "Shipper is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.schShipperId.length > 0 && this.searchShIsShipper == "N") {
      this.model["error-schShipperId"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
      this.isError = true;
    }

    if (this.model.schBookingPartyId.length > 0 && this.searchBkIsValid == "N") {
      this.model["error-schBookingPartyId"] = "Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.model.schBookingPartyId.length > 0 && this.searchBkIsBookingParty == "N") {
      this.model["error-schBookingPartyId"] = "Customer record is no longer set as a Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    }

    // if (this.model.schShipperId.length > 0 && this.searchShIsValid == "N") {
    //   this.model["error-schShipperId"] = "Shipper is invalid, unable to proceed creation";
    //   this.isError = true;
    // }

    // if (this.model.schBookingPartyId.length > 0 && this.searchBkIsValid == "N") {
    //   this.model["error-schBookingPartyId"] = "Booking Party is invalid, unable to proceed creation";
    //   this.isError = true;
    // }

    if (this.isError) {
      window.scrollTo(0, 0);
      return ;
    }

    this.initNewInvalid;
    this.model.schIsDeleted = "N";
    this.appStatusLabel = "";
    this.model.schEffectiveFrom = this.dateEffectiveFrom.getValue();
    this.model.schEffectiveTo = this.dateEffectiveTo.getValue();
    this.cookieService.setCookie("schSearchParam", JSON.stringify(this.model));
    this.isAfterSave = false;
    this.lock = false;
    this.modeForm = "surchargeMaintenance";
    this.pfcToolbar.label = "Surcharge Application";
    this.invisibleToolbarButtons = "retrieve,create";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
    } else {
      this.disableToolbarButtons = "copy,delete,print";
    }
    this.map = new Map(); //reset the map upon create
    this.gridApiPrepaid.setRowData([]);
    this.gridApiCollect.setRowData([]);

    this.cbAcctPic.clearSelect();
    this.cbMaintenanceAdhocVessel.setValue("");
    this.cbMaintenanceAdhocVoyage.setValue("");

    let currentDate = new Date();

    if (
      this.dateEffectiveFrom.getValue() != null &&
      this.dateEffectiveFrom.getValue() != "" &&
      this.dateEffectiveFrom.getValue() != undefined
    ) {
      this.dateMaintenanceEffectiveFrom.setValue(
        this.dateEffectiveFrom.getValue()
      );
    } else {
      this.dateMaintenanceEffectiveFrom.setValue(
        this.genericUtil.pad2(currentDate.getFullYear()) +
          "-" +
          this.genericUtil.pad2(currentDate.getMonth() + 1) +
          "-" +
          this.genericUtil.pad2(currentDate.getDate())
      );
    }

    // console.log("this.model.schContainerType  --> " + this.model.schContainerType );
    if (this.model.schContainerType == "ALL") {
      this.model.schContainerType = "STD";
    }

    this.model.schContainerOwnershipPrefix = "C";

    if (this.model.schDgClass == "ALL") {
      this.model.schDgClass = "NA";
    }

    if (this.model.schIsValid == "ALL") {
      this.model.schIsValid = "Y";
    }

    // console.log("this.model.schContainerOwnership  --> " + this.model.schContainerOwnership );
    if (this.model.schContainerOwnership == "ALL") {
      this.model.schContainerOwnership = "COC";
    }

    if (this.model.schContainerStatus == "ALL") {
      this.model.schContainerStatus = "FULL";
    }

    this.changeEventPfcContainerOwnershipProcess(
      this.model.schContainerOwnership
    );

    //Auto set the field based on field on Flag Status

    //2. Auto set Place of Receipt
    if (
      this.model.schPlaceOfReceiptCode != null &&
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schPlaceOfReceiptCode != undefined
    ) {
      this.cbMaintenancePlaceOfReceipt.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenancePlaceOfReceipt.setValue(
        this.model.schPlaceOfReceiptCode
      );
    } else {
      this.cbMaintenancePlaceOfReceipt.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.cbMaintenancePlaceOfReceipt.setValue("");
    }

    //3. Auto set port of Loading
    if (
      this.model.schLoadPortCode != null &&
      this.model.schLoadPortCode != "" &&
      this.model.schLoadPortCode != undefined
    ) {
      this.cbMaintenancePortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenancePortOfLoad.setValue(this.model.schLoadPortCode);
    } else {
      this.cbMaintenancePortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.cbMaintenancePortOfLoad.setValue("");
    }

    //4. Auto set port of Discharge
    if (
      this.model.schDischargePortCode != null &&
      this.model.schDischargePortCode != "" &&
      this.model.schDischargePortCode != undefined
    ) {
      this.cbMaintenancePortOfDischarge.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenancePortOfDischarge.setValue(
        this.model.schDischargePortCode
      );
    } else {
      this.cbMaintenancePortOfDischarge.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.cbMaintenancePortOfDischarge.setValue("");
    }

    //5. Auto set Place of Delivery
    if (
      this.model.schPlaceOfDeliveryCode != null &&
      this.model.schPlaceOfDeliveryCode != "" &&
      this.model.schPlaceOfDeliveryCode != undefined
    ) {
      this.cbMaintenancePlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenancePlaceOfDelivery.setValue(
        this.model.schPlaceOfDeliveryCode
      );
    } else {
      this.cbMaintenancePlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.cbMaintenancePlaceOfDelivery.setValue("");
    }

    //6. Auto set Port of Transshipment 1
    if (
      this.model.schPortOfTransshipment1Code != null &&
      this.model.schPortOfTransshipment1Code != "" &&
      this.model.schPortOfTransshipment1Code != undefined
    ) {
      this.cbMaintenancePOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );
      this.cbMaintenancePOT1.setValue(this.model.schPortOfTransshipment1Code);
    } else {
      this.cbMaintenancePOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
      );
      this.cbMaintenancePOT1.setValue("");
    }

    //7. Auto set Port of Transshipment 2
    if (
      this.model.schPortOfTransshipment2Code != null &&
      this.model.schPortOfTransshipment2Code != "" &&
      this.model.schPortOfTransshipment2Code != undefined
    ) {
      this.cbMaintenancePOT2.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );
      this.cbMaintenancePOT2.setValue(this.model.schPortOfTransshipment2Code);
    } else {
      this.cbMaintenancePOT2.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
      );
      this.cbMaintenancePOT2.setValue("");
    }

    //8. Auto set Port of Transshipment 3
    if (
      this.model.schPortOfTransshipment3Code != null &&
      this.model.schPortOfTransshipment3Code != "" &&
      this.model.schPortOfTransshipment3Code != undefined
    ) {
      this.cbMaintenancePOT3.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
      );
      this.cbMaintenancePOT3.setValue(this.model.schPortOfTransshipment3Code);
    } else {
      this.cbMaintenancePOT3.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
      );
      this.cbMaintenancePOT3.setValue("");
    }

    //9. Auto set Final Destination
    if (
      this.model.schFinalDestinationCode != null &&
      this.model.schFinalDestinationCode != "" &&
      this.model.schFinalDestinationCode != undefined
    ) {
      this.cbMaintenanceFinalDestination.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenanceFinalDestination.setValue(
        this.model.schFinalDestinationCode
      );
    } else {
      this.cbMaintenanceFinalDestination.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.cbMaintenanceFinalDestination.setValue("");
    }

    //10. Auto set Freight Party
    if (
      this.model.schFreightPartyId != null &&
      this.model.schFreightPartyId != "" &&
      this.model.schFreightPartyId != undefined
    ) {
      this.cbMaintenanceFreightParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerId={query}"
      );
      this.cbMaintenanceFreightParty.setValue(this.model.schFreightPartyId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceFreightParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerName={query}"
      );
      this.cbMaintenanceFreightParty.setValue("");
      this.dateCreatedForCbPayer = "*";
    }

    //11. Expiry On
    if (
      this.dateEffectiveTo.getValue() != null &&
      this.dateEffectiveTo.getValue() != "" &&
      this.dateEffectiveTo.getValue() != undefined
    ) {
      this.dateMaintenanceEffectiveTo.setValue(this.dateEffectiveTo.getValue());
    } else {
      this.dateMaintenanceEffectiveTo.setValue("");
    }

    //12. Receipt Type and Delivery Type
    if (
      this.model.schReceiptTypeCode != null &&
      this.model.schReceiptTypeCode != "" &&
      this.model.schReceiptTypeCode != undefined &&
      this.model.schReceiptTypeCode != "ALL"
    ) {
      this.cbMaintenanceReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
      );
      this.cbMaintenanceReceiptType.setValue(this.model.schReceiptTypeCode);
    } else {
      this.cbMaintenanceReceiptType.setValue("CY");
    }

    if (
      this.model.schDeliveryTypeCode != null &&
      this.model.schDeliveryTypeCode != "" &&
      this.model.schDeliveryTypeCode != undefined &&
      this.model.schDeliveryTypeCode != "ALL"
    ) {
      this.cbMaintenanceDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
      );
      this.cbMaintenanceDeliveryType.setValue(this.model.schDeliveryTypeCode);
    } else {
      this.cbMaintenanceDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
      );
      this.cbMaintenanceDeliveryType.setValue("CY");
    }

    //13. Auto set Shipper
    if (
      this.model.schShipperId != null &&
      this.model.schShipperId != "" &&
      this.model.schShipperId != undefined
    ) {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerId={query}"
      );
      this.cbMaintenanceShipper.setValue(this.model.schShipperId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerName={query}"
      );
      this.cbMaintenanceShipper.setValue("");
      this.dateCreatedForCbPayer = "*";
    }

     //14. Auto set Booking Party
     if (
      this.model.schBookingPartyId != null &&
      this.model.schBookingPartyId != "" &&
      this.model.schBookingPartyId != undefined
    ) {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerId={query}"
      );
      this.cbMaintenanceBookingParty.setValue(this.model.schBookingPartyId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerName={query}"
      );
      this.cbMaintenanceBookingParty.setValue("");
      this.dateCreatedForCbPayer = "*";
    }
    //reset created by and date information
    this.model.schDateCreated = "";
    this.model.schUserCreated = "";
    this.model.schDateModified = "";
    this.model.schUserModified = "";
    this.dateCreatedForCbPayer = "*";
    this.custIdForCbPayer = "*";
    // console.log(this.model);
    //User ID
    if (this.model.schUserCreated == "") {
      console.log(this.cookieService.getName());
      this.model.schUserID = this.cookieService.getName();
    }

    this.calculateTotal();

    this.initTab1();
    window.scrollTo(0, 0);
  }

  handleCancel() {
    var self = this;
    if (this.modeForm == "surchargeFlagStatus") {
      this.pfcToolbar.label = "Surcharge Flag Status";
      this.model = new SurchargeMaster();

      this.cbFreightParty.setValue("");
      this.cbBookingParty.setValue("");
      this.cbShipper.setValue("");
      this.cbPlaceOfReceipt.setValue("");
      this.cbPortOfLoad.setValue("");
      this.cbPortOfDischarge.setValue("");
      this.cbPlaceOfDelivery.setValue("");
      this.cbPortOfTransshipment.setValue("");
      this.cbFinalDestination.setValue("");
      this.cbPortOfDischarge.setValue("");

      this.cbContainerStatus.clearSelect();
      this.cbContainerOwnership.clearSelect();
      this.cbDGClass.clearSelect();
      this.cbContainerType.clearSelect();
      this.cbValidInvalid.clearSelect();
      this.cbReceiptType.clearSelect();
      this.cbDeliveryType.clearSelect();
      this.fpAcctCode = "";
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
      this.model["error-schFreightPartyId"] = "";
      // this.model["error-schShipperId"] = "";
      // this.model["error-schBookingPartyId"] = "";
      this.dateCreatedForCbPayer = "*";
      this.custIdForCbPayer = "*";
      this.listPayerExisting = [];

      //GOS-766 Surcharge Master Do not refresh data under [Terminal] tab after clicking "Cancel"
      this.cbPORTerminal.clearSelect();
      this.cbPODLTerminal.clearSelect();
      this.cbPOLTerminal.clearSelect();
      this.cbPOT1Terminal.clearSelect();
      this.cbPODTerminal.clearSelect();
      this.cbFDESTTerminal.clearSelect();

      this.isError = false;
      setTimeout(() => {
        self.cbContainerStatus.setValue("ALL");
        self.cbContainerOwnership.setValue("ALL");
        self.cbDGClass.setValue("ALL");
        self.cbContainerType.setValue("ALL");
        self.cbValidInvalid.setValue("ALL");
        self.cbReceiptType.setValue("CY");
        self.cbDeliveryType.setValue("CY");
        self.model.schReceiptTypeCode = "CY";
        self.model.schDeliveryTypeCode = "CY";
        self.model.schIsDeleted = "N";
        self.model.schIsCommission = "N";
      }, 10);

      //this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');

      //this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');

      this.dateEffectiveFrom.setValue("");
      this.dateEffectiveTo.setValue("");
      this.checkLock = false;
      this.lockEffectiveFrom = false;
      this.lockEffectiveTo = false;
      this.mainGrid.curPage = 1;
      this.mainGrid.currentPage = 1;
      this.mainGrid.onClear();
      this.calculateTotal();
      window.scrollTo(0, 0);
    } else if (this.modeForm == "surchargeMaintenance") {
      //reset the model
      this.model = new SurchargeMaster();
      this.modelDetail = new SurchargeDetail();
      this.map = new Map();

      //reset all combo and date picker
      this.cbMaintenanceFreightParty.setValue("");
      this.cbMaintenanceShipper.setValue("");
      this.cbMaintenanceBookingParty.setValue("");
      this.cbMaintenancePlaceOfReceipt.setValue("");
      this.cbMaintenancePortOfLoad.setValue("");
      this.cbMaintenancePortOfDischarge.setValue("");
      this.cbMaintenancePlaceOfDelivery.setValue("");
      this.cbMaintenancePOT1.setValue("");
      this.cbMaintenancePOT2.setValue("");
      this.cbMaintenancePOT3.setValue("");
      this.cbMaintenanceFinalDestination.setValue("");
      //this.cbMaintenanceCommodity.setValue("");
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");
      this.cbMaintenanceReceiptType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
      );
      this.cbMaintenanceReceiptType.setValue("CY");
      this.cbMaintenanceDeliveryType.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
      );
      this.cbMaintenanceDeliveryType.setValue("CY");
      this.dateMaintenanceEffectiveTo.setValue("");
      this.cbAcctPic.clearSelect();

      //reset effective to
      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());
      this.dateMaintenanceEffectiveFrom.setValue(theDate);

      //reset container type, status, ownership
      this.model.schContainerOwnership = "COC";
      this.changeEventPfcContainerOwnershipProcess(
        this.model.schContainerOwnership
      );

      this.model.schContainerStatus = "FULL";
      this.model.schContainerType = "STD";
      this.model.schDgClass = "NA";
      this.isAfterSave = false;

      //GOS-766 Surcharge Master Do not refresh data under [Terminal] tab after clicking "Cancel"
      this.clearTerminalTabSurchargeMaintenance();
      this.maintenanceGeneralTab.nativeElement.click();
      
      //reset grid
      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();
      this.isError = false;
      this.isErrorDetail = false;
      this.lock = false;
      this.loading = false;
      this.fpAcctCode = "";
      this.fpPayer = "";
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
      this.model["error-schFreightPartyId"] = "";
      this.model["error-schShipperId"] = "";
      this.model["error-schBookingPartyId"] = "";
      this.dateCreatedForCbPayer = "*";
      this.custIdForCbPayer = "*";
      this.listPayerExisting = [];
      this.calculateTotal();
      this.genericUtil.hideLoader();
      window.scrollTo(0, 0);
    }
  }

  clearTerminalTabSurchargeMaintenance(){
    this.cbMaintenancePORTerminal.clearSelect();
    this.cbMaintenancePODLTerminal.clearSelect();
    this.cbMaintenancePOLTerminal.clearSelect();
    this.cbMaintenancePOT1Terminal.clearSelect();
    this.cbMaintenancePODTerminal.clearSelect();
    this.cbMaintenanceFDESTTerminal.clearSelect();
  }

  afterClosedFromMaintenance() {
    this.modeForm = "surchargeFlagStatus";
    this.pfcToolbar.label = "Surcharge Flag Status";

    this.model = new SurchargeMaster();
    this.lockHeader = false;

    //cek cookies
    if (this.cookieService.getCookie("schSearchParam") != "") {
      let schSearchParam = JSON.parse(
        this.cookieService.getCookie("schSearchParam")
      );
      console.log("** schSearchParam **");
      console.log(schSearchParam);

      this.model.schSerchByRefNo = schSearchParam.schSerchByRefNo.trim();
      if (schSearchParam.schSerchByRefNo.trim() == "Y") {
        this.model.schSurchargeId = schSearchParam.schSurchargeId;
      } else {
        this.model.schIsCommission = schSearchParam.schIsCommission;

        //01. Freight Party
        if (
          schSearchParam.schFreightPartyId != null &&
          schSearchParam.schFreightPartyId != "" &&
          schSearchParam.schFreightPartyId != undefined
        ) {
          this.cbFreightParty.setUrl(
            this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
              "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerId={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"
          );
          //ininih
          var self = this;
          this.cbFreightParty.setValue(
            schSearchParam.schFreightPartyId,
            function (callbackFreightParty) {
              self.model.schFreightPartyId = schSearchParam.schFreightPartyId;
              self.searchFpIsFreightParty = callbackFreightParty.isFreightParty;
              self.searchFpIsValid = callbackFreightParty.isValid;
              self.cbFreightParty.setUrl(
                self.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                  "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerId={query}"+
                  self.sCharUtil.htmlEncode("|")+
                  "status=E"
              );
            }
          );
        } else {
          this.model.schFreightPartyId = "";
          this.cbFreightParty.setValue("");
        }

        if (
          schSearchParam.schBookingPartyId != null &&
          schSearchParam.schBookingPartyId != "" &&
          schSearchParam.schBookingPartyId != undefined
        ) {
          this.cbBookingParty.setUrl(
            this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
              "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerId={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"
          );
          //ininih
          var self = this;
          this.cbBookingParty.setValue(
            schSearchParam.schBookingPartyId,
            function (callbackBookingParty) {
              self.model.schBookingPartyId = schSearchParam.schBookingPartyId;
              self.searchBkIsBookingParty = callbackBookingParty.isBookingParty;
              self.searchBkIsValid = callbackBookingParty.isValid;
              self.cbBookingParty.setUrl(
                self.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                  "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerId={query}"+
                  self.sCharUtil.htmlEncode("|")+
                  "status=E"
              );
            }
          );
        } else {
          this.model.schBookingPartyId = "";
          this.cbBookingParty.setValue("");
        }

        if (
          schSearchParam.schShipperId != null &&
          schSearchParam.schShipperId != "" &&
          schSearchParam.schShipperId != undefined
        ) {
          this.cbShipper.setUrl(
            this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
              "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerId={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"
          );
          //ininih
          var self = this;
          this.cbShipper.setValue(
            schSearchParam.schShipperId,
            function (callbackShipper) {
              self.model.schShipperId = schSearchParam.schShipperId;
              self.searchShIsShipper = callbackShipper.isShipper;
              self.searchShIsValid = callbackShipper.isValid;
              self.cbShipper.setUrl(
                self.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
                  "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerId={query}"+
                  self.sCharUtil.htmlEncode("|")+
                  "status=E"
              );
            }
          );
        } else {
          this.model.schShipperId = "";
          this.cbShipper.setValue("");
        }

        //02. Place of Receipt
        if (
          schSearchParam.schPlaceOfReceiptCode != null &&
          schSearchParam.schPlaceOfReceiptCode != "" &&
          schSearchParam.schPlaceOfReceiptCode != undefined
        ) {
          this.cbPlaceOfReceipt.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbPlaceOfReceipt.setValue(
            schSearchParam.schPlaceOfReceiptCode,
            function (callbackPOR) {
              self.model.schPlaceOfReceiptCode =
                schSearchParam.schPlaceOfReceiptCode;
              self.cbPlaceOfReceipt.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schPlaceOfReceiptCode = "";
          this.cbPlaceOfReceipt.setValue("");
        }

        //03. Port of Loading
        if (
          schSearchParam.schLoadPortCode != null &&
          schSearchParam.schLoadPortCode != "" &&
          schSearchParam.schLoadPortCode != undefined
        ) {
          this.cbPortOfLoad.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbPortOfLoad.setValue(
            schSearchParam.schLoadPortCode,
            function (callbackPOL) {
              self.model.schLoadPortCode = schSearchParam.schLoadPortCode;
              self.cbPortOfLoad.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schLoadPortCode = "";
          this.cbPortOfLoad.setValue("");
        }

        //04. Discharge port Code
        if (
          schSearchParam.schDischargePortCode != null &&
          schSearchParam.schDischargePortCode != "" &&
          schSearchParam.schDischargePortCode != undefined
        ) {
          this.cbPortOfDischarge.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbPortOfDischarge.setValue(
            schSearchParam.schDischargePortCode,
            function (callbackDischarge) {
              self.model.schDischargePortCode =
                schSearchParam.schDischargePortCode;
              self.cbPortOfDischarge.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schDischargePortCode = "";
          this.cbPortOfDischarge.setValue("");
        }

        //05. Place of Delivery
        if (
          schSearchParam.schPlaceOfDeliveryCode != null &&
          schSearchParam.schPlaceOfDeliveryCode != "" &&
          schSearchParam.schPlaceOfDeliveryCode != undefined
        ) {
          this.cbPlaceOfDelivery.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbPlaceOfDelivery.setValue(
            schSearchParam.schPlaceOfDeliveryCode,
            function (callbackPOD) {
              self.model.schPlaceOfDeliveryCode =
                schSearchParam.schPlaceOfDeliveryCode;
              self.cbPlaceOfDelivery.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schPlaceOfDeliveryCode = "";
          this.cbPlaceOfDelivery.setValue("");
        }

        //06. Final Destination
        if (
          schSearchParam.schFinalDestinationCode != null &&
          schSearchParam.schFinalDestinationCode != "" &&
          schSearchParam.schFinalDestinationCode != undefined
        ) {
          this.cbFinalDestination.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbFinalDestination.setValue(
            schSearchParam.schFinalDestinationCode,
            function (callbackFDest) {
              self.model.schFinalDestinationCode =
                schSearchParam.schFinalDestinationCode;
              self.cbFinalDestination.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schFinalDestinationCode = "";
          this.cbFinalDestination.setValue("");
        }

        //07. Port of Transshipment1
        if (
          schSearchParam.schPortOfTransshipment1Code != null &&
          schSearchParam.schPortOfTransshipment1Code != "" &&
          schSearchParam.schPortOfTransshipment1Code != undefined
        ) {
          this.cbPortOfTransshipment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          var self = this;
          this.cbPortOfTransshipment.setValue(
            schSearchParam.schPortOfTransshipment1Code,
            function (callbackPOT1) {
              self.model.schPortOfTransshipment1Code =
                schSearchParam.schPortOfTransshipment1Code;
              self.cbPortOfTransshipment.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControl/locationName={query}"
              );
            }
          );
        } else {
          this.model.schPortOfTransshipment1Code = "";
          this.cbPortOfTransshipment.setValue("");
        }

        //08. Receipt Type & Delivery Type
        // console.log("schSearchParam.schReceiptTypeCode => " + schSearchParam.schReceiptTypeCode);
        // console.log("this.model.schReceiptTypeCode => " + this.model.schReceiptTypeCode);
        if (
          schSearchParam.schReceiptTypeCode != null &&
          schSearchParam.schReceiptTypeCode != "" &&
          schSearchParam.schReceiptTypeCode != undefined &&
          schSearchParam.schReceiptTypeCode != "ALL"
        ) {
          this.cbReceiptType.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
          );
          this.cbReceiptType.setValue(schSearchParam.schReceiptTypeCode);
          this.model.schReceiptTypeCode = schSearchParam.schReceiptTypeCode;
        } else {
          this.model.schReceiptTypeCode = "";
          this.cbReceiptType.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
          );
          this.cbReceiptType.setValue("CY");
        }

        // console.log("schSearchParam.schDeliveryTypeCode => " + schSearchParam.schDeliveryTypeCode);
        // console.log("this.model.schDeliveryTypeCode => " + this.model.schDeliveryTypeCode);
        if (
          schSearchParam.schDeliveryTypeCode != null &&
          schSearchParam.schDeliveryTypeCode != "" &&
          schSearchParam.schDeliveryTypeCode != undefined &&
          schSearchParam.schDeliveryTypeCode != "ALL"
        ) {
          this.cbDeliveryType.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
          );
          this.cbDeliveryType.setValue(schSearchParam.schDeliveryTypeCode);
          this.model.schDeliveryTypeCode = schSearchParam.schDeliveryTypeCode;
        } else {
          this.model.schDeliveryTypeCode = "";
          this.cbDeliveryType.setValue("CY");
        }

        //09. Container Ownership
        // console.log("schSearchParam.schContainerOwnership => " + schSearchParam.schContainerOwnership);
        // console.log("this.model.schContainerOwnership => " + this.model.schContainerOwnership);
        if (
          schSearchParam.schContainerOwnership != null &&
          schSearchParam.schContainerOwnership != "" &&
          schSearchParam.schContainerOwnership != undefined
        ) {
          this.cbContainerOwnership.setValue(
            schSearchParam.schContainerOwnership
          );
          this.model.schContainerOwnership =
            schSearchParam.schContainerOwnership;
        } else {
          this.model.schContainerOwnership = "ALL";
          this.cbContainerOwnership.setValue("ALL");
        }

        //10. Container Status
        // console.log("schSearchParam.schContainerStatus => " + schSearchParam.schContainerStatus);
        // console.log("this.model.schContainerStatus => " + this.model.schContainerStatus);
        if (
          schSearchParam.schContainerStatus != null &&
          schSearchParam.schContainerStatus != "" &&
          schSearchParam.schContainerStatus != undefined
        ) {
          this.cbContainerStatus.setValue(schSearchParam.schContainerStatus);
          this.model.schContainerStatus = schSearchParam.schContainerStatus;
        } else {
          this.model.schContainerStatus = "ALL";
          this.cbContainerStatus.setValue("ALL");
        }

        //11. Container Type
        // console.log("schSearchParam.schContainerType => " + schSearchParam.schContainerType);
        // console.log("this.model.schContainerType => " + this.model.schContainerType);
        if (
          schSearchParam.schContainerType != null &&
          schSearchParam.schContainerType != "" &&
          schSearchParam.schContainerType != undefined
        ) {
          switch (schSearchParam.schContainerType) {
            case "STD":
              this.cbContainerType.setValue("NORMAL");
              break;
            case "DRY":
              this.cbContainerType.setValue("REEFER DRY");
              break;
            case "REF":
              this.cbContainerType.setValue("REEFER");
              break;
            case "FLR":
              this.cbContainerType.setValue("FLATRACK");
              break;
            case "OPT":
              this.cbContainerType.setValue("OPEN TOP");
              break;
            case "PLT":
              this.cbContainerType.setValue("PLATFORM");
              break;
            case "TNK":
              this.cbContainerType.setValue("TANK");
              break;
            case "UC":
              this.cbContainerType.setValue("UC");
              break;

            default:
              break;
          }
          this.model.schContainerType = schSearchParam.schContainerType;
        } else {
          this.model.schContainerType = "ALL";
          this.cbContainerType.setValue("ALL");
        }

        //12. DG Class
        // console.log("schSearchParam.schDgClass => " + schSearchParam.schDgClass);
        // console.log("this.model.schDgClass => " + this.model.schDgClass);
        if (
          schSearchParam.schDgClass != null &&
          schSearchParam.schDgClass != "" &&
          schSearchParam.schDgClass != undefined
        ) {
          this.cbDGClass.setValue(schSearchParam.schDgClass);
          this.model.schDgClass = schSearchParam.schDgClass;
        } else {
          this.model.schDgClass = "ALL";
          this.cbDGClass.setValue("ALL");
        }

        //13. Effective From
        // console.log("schSearchParam.schEffectiveFrom --> " + schSearchParam.schEffectiveFrom);
        if (
          schSearchParam.schEffectiveFrom != null &&
          schSearchParam.schEffectiveFrom != "" &&
          schSearchParam.schEffectiveFrom != undefined
        ) {
          this.dateEffectiveFrom.setValue(schSearchParam.schEffectiveFrom);
        } else {
          this.model.schEffectiveFrom = "";
          this.dateEffectiveFrom.setValue("");
        }

        //14. Effective To
        // console.log("schSearchParam.schEffectiveTo --> " + schSearchParam.schEffectiveTo);
        if (
          schSearchParam.schEffectiveTo != null &&
          schSearchParam.schEffectiveTo != "" &&
          schSearchParam.schEffectiveTo != undefined
        ) {
          this.dateEffectiveTo.setValue(schSearchParam.schEffectiveTo);
        } else {
          this.model.schEffectiveTo = "";
          this.dateEffectiveTo.setValue("");
        }

        //15.  set Shipper
    if (
      this.model.schShipperId != null &&
      this.model.schShipperId != "" &&
      this.model.schShipperId != undefined
    ) {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerId={query}"
      );
      this.cbMaintenanceShipper.setValue(this.model.schShipperId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerName={query}"
      );
      this.cbMaintenanceShipper.setValue("");
      this.dateCreatedForCbPayer = "*";
    }

     //16. set Booking Party
     if (
      this.model.schBookingPartyId != null &&
      this.model.schBookingPartyId != "" &&
      this.model.schBookingPartyId != undefined
    ) {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerId={query}"
      );
      this.cbMaintenanceBookingParty.setValue(this.model.schBookingPartyId);
      this.dateCreatedForCbPayer = "*";
    } else {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerName={query}"
      );
      this.cbMaintenanceBookingParty.setValue("");
      this.dateCreatedForCbPayer = "*";
    }
      }
    }
    //end of check cookies

    this.invisibleToolbarButtons = "save,copy,nct,delete";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
    } else {
      this.disableToolbarButtons = "";
    }
    this.loading = false;
    this.isError = false;

    //reset combo
    this.cbReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/ok=ok"
    );
    this.cbReceiptType.setValue(this.model.schReceiptTypeCode);
    this.cbDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/ok=ok"
    );
    this.cbDeliveryType.setValue(this.model.schDeliveryTypeCode);

    this.calculateTotal();

    //reset selected value in grid
    this.mainGrid.clearSelectedValues();
    //remove the cookie
    //this.cookieService.deleteCookie("pfcSearchByRefNo");
    //this.cookieService.deleteCookie("pfcRecordId");

    this.maintenanceGeneralTab.nativeElement.click();
    this.clearTerminalTabSurchargeMaintenance();

  }

  handleClose() {
    if (this.modeForm == "surchargeMaintenance") {
      this.fpAcctCode = "";
      this.message(
        "information",
        "Information",
        "Are you sure want to close this?",
        "yesno",
        {
          yes: "this.afterClosedFromMaintenance()",
          no: "",
        }
      );
    } else {
      this.router.navigateByUrl("/main/home");
    }
  }

  changeCbMaintenanceFreightParty(event) {
    // console.log("** changeCbMaintenanceFreightParty **");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
      this.model.schFreightPartyId = event["customerId"];
      this.model.schFreightPartyCountry = event["countryCode"]
        .toString()
        .toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";
      this.searchFpIsFreightParty = event["isFreightParty"];
      this.searchFpIsValid = event["isValid"];
    } else {
      this.model.schFreightPartyId = "";
      this.model.schFreightPartyCountry = "";
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
      this.fpAcctCode = "";
    }

    //revert the search url to use name
    // this.cbMaintenanceFreightParty.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
    // );

    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceFreightParty.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/freight_party/*/*/customerName={query}"
      );
    } else {

      var uriString = "";
      if (this.model.schSurchargeId == null ||
        this.model.schSurchargeId == "" ||
        this.model.schSurchargeId == undefined) {
          uriString = this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=N";
      } else {
        uriString = this.configService.config.BASE_API.toString() +
              // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}"+
              this.sCharUtil.htmlEncode("|")+
              "status=E"+
              this.sCharUtil.htmlEncode("|")+
              "recordId="+this.model.schSurchargeId;
      }
      this.cbMaintenanceFreightParty.setUrl(uriString);

      // this.cbMaintenanceFreightParty.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
      //     "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}",
      // );
    }
  }

  changeCbMaintenancePlaceOfReceipt(event) {
    // console.log("** changeCbMaintenancePlaceOfReceipt **");
    if (
      event["locationId"] != null &&
      event["locationId"] != undefined &&
      event["locationId"] != ""
    ) {
      this.model.schPlaceOfReceiptCode = event["locationCode"];
      if (
        this.model.schLoadPortCode == null ||
        this.model.schLoadPortCode == "" ||
        this.model.schLoadPortCode == undefined
      ) {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfLoad.setValue(event["locationCode"]);
      }
      if (event.hasTerminal == "Y") {
        // GOS-760 Please assists to disable the POR, PODL and FEST fields. 
        // this.lockPORTerminal = false;
      } else {
        this.lockPORTerminal = true;
      }
      this.cbMaintenancePORTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePORTerminal.setValue("");
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.schPORTerminalCode = "";
      this.model.schPOLTerminalCode = "";
    } else {
      this.model.schPlaceOfReceiptCode = "";
      this.cbMaintenancePORTerminal.setUrl("");
      this.cbMaintenancePORTerminal.setValue("");
      this.model.schPORTerminalCode = "";
      this.model.schPOLTerminalCode = "";
    }

    //revert the url to search by name
    this.cbMaintenancePlaceOfReceipt.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePortOfLoad(event) {
    // console.log("** changeCbMaintenancePortOfLoad **");

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePortOfLoad.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.schLoadPortCode = event["locationCode"];
      this.polName = event["locationName"];

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPOLTerminal = false;
      } else {
        //lock the terminal back
        // GOS-760 users are allowed to input Terminal info under POL, POD and POT fields only
        // this.lockPOLTerminal = true;
      }
      this.cbMaintenancePOLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.schPolPrefix = event["locationBookingRef"];
    } else {
      this.model.schLoadPortCode = "";
      this.cbMaintenancePOLTerminal.setUrl("");
      this.cbMaintenancePOLTerminal.setValue("");
      this.model.schPolPrefix = "";
      this.polName = "";
    }

    //revert the url to search by name
    this.cbMaintenancePortOfLoad.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePortOfDischarge(event) {
    // console.log("** changeCbMaintenancePortOfDischarge **");

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePortOfDischarge.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.schDischargePortCode = event["locationCode"];
      this.podName = event["locationName"];
      if (
        this.model.schPlaceOfDeliveryCode == null ||
        this.model.schPlaceOfDeliveryCode == "" ||
        this.model.schPlaceOfDeliveryCode == undefined
      ) {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue(event["locationCode"]);
      }

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPODTerminal = false;
      } else {
        //lock the terminal back
        // GOS-760 users are allowed to input Terminal info under POL, POD and POT fields only
        // this.lockPODTerminal = true;
      }

      this.cbMaintenancePODTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );

      this.cbMaintenancePODTerminal.setValue("");
      this.cbMaintenancePODLTerminal.setValue("");
      this.model.schPODLTerminalCode = "";
      this.model.schPODLTerminalCode = "";
      this.model.schPodPrefix = event["locationCode"]
        .substr(2, 3)
        .toLocaleUpperCase()
        .trim();
    } else {
      this.model.schDischargePortCode = "";
      this.cbMaintenancePODTerminal.setUrl("");
      this.cbMaintenancePODTerminal.setValue("");
      this.model.schPODTerminalCode = "";
      this.model.schPodPrefix = "";
      this.podName = "";
    }

    //revert the url to search by name
    this.cbMaintenancePortOfDischarge.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePlaceOfDelivery(event) {
    // console.log("** changeCbMaintenancePlaceOfDelivery **");

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.cbMaintenancePlaceOfDelivery.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationName={query}"
      );
      this.model.schPlaceOfDeliveryCode = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        //GOS-760 Please assists to disable the POR, PODL and FEST fields. 
        // this.lockPODLTerminal = false;
      } else {
        //lock the terminal back
        this.lockPODLTerminal = true;
      }

      this.cbMaintenancePODLTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePODLTerminal.setValue("");
    } else {
      this.model.schPlaceOfDeliveryCode = "";
      this.cbMaintenancePODLTerminal.setUrl("");
      this.cbMaintenancePODLTerminal.setValue("");
    }

    //revert the url to search by name
    this.cbMaintenancePlaceOfDelivery.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenanceFinalDestination(event) {
    // console.log("** changeCbMaintenanceFinalDestination **");

    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schFinalDestinationCode = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        //GOS-760 Please assists to disable the POR, PODL and FEST fields. 
        // this.lockFDESTTerminal = false;
      } else {
        //lock the terminal back
        this.lockFDESTTerminal = true;
      }
      this.cbMaintenanceFDESTTerminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenanceFDESTTerminal.setValue("");
      this.model.schFDESTTerminalCode = "";
    } else {
      this.model.schFinalDestinationCode = "";
      this.cbMaintenanceFDESTTerminal.setUrl("");
      this.cbMaintenanceFDESTTerminal.setValue("");
      this.model.schFDESTTerminalCode = "";
    }

    //revert the url to search by name
    this.cbMaintenanceFinalDestination.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationName={query}"
    );
  }

  changeCbMaintenancePOT1(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPortOfTransshipment1Code = event["locationCode"];

      if (event.hasTerminal == "Y") {
        //open lock
        this.lockPOT1Terminal = false;
      } else {
        //lock the terminal back
        // GOS-760 users are allowed to input Terminal info under POL, POD and POT fields only
        // this.lockPOT1Terminal = true;
      }

      this.cbMaintenancePOT1Terminal.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
      );
      this.cbMaintenancePOT1Terminal.setValue("");
      this.model.schPOT1TerminalCode = "";
    } else {
      this.model.schPortOfTransshipment1Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT1.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenancePOT2(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPortOfTransshipment2Code = event["locationCode"];
    } else {
      this.model.schPortOfTransshipment2Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenancePOT3(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPortOfTransshipment3Code = event["locationCode"];
    } else {
      this.model.schPortOfTransshipment3Code = "";
    }
    //revert the url to search by name
    this.cbMaintenancePOT3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeCbMaintenanceReceiptType(event) {
    if (
      event["receiptTypeCode"] != null &&
      event["receiptTypeCode"] != undefined &&
      event["receiptTypeCode"] != ""
    ) {
      this.model.schReceiptTypeCode = event["receiptTypeCode"];
    } else {
      this.model.schReceiptTypeCode = "";
    }
    this.cbMaintenanceReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}"
    );
  }

  changeCbMaintenanceDeliveryType(event) {
    if (
      event["deliveryTypeCode"] != null &&
      event["deliveryTypeCode"] != undefined &&
      event["deliveryTypeCode"] != ""
    ) {
      this.model.schDeliveryTypeCode = event["deliveryTypeCode"];
    } else {
      this.model.schDeliveryTypeCode = "";
    }
    this.cbMaintenanceDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}"
    );
  }

  changeCbMaintenanceAdhocVessel(event) {
    if (
      event["vesselId"] != null &&
      event["vesselId"] != undefined &&
      event["vesselId"] != ""
    ) {
      this.model.schVesselId = event["vesselId"];
      // this.cbMaintenanceAdhocVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/" + this.model.schVesselId + "/" + "O" + "/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbMaintenanceAdhocVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.schVesselId +
          "/" +
          "O" +
          "/{query}/*"
      );
      this.cbMaintenanceAdhocVoyage.setValue("");
    } else {
      this.model.schVesselId = "";
      this.model.schVoyage = "";
      this.cbMaintenanceAdhocVoyage.setUrl("");
      this.model.schLocalEta = "";
      this.model.schLocalEtd = "";
      this.model.schLocalAta = "";
      this.model.schLocalAtd = "";
    }

    //revert the search using vessel name
    this.cbMaintenanceAdhocVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/VesselName={query}"
    );
  }

  changeCbMaintenanceAdhocVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.schVoyage = event.voyage;
      this.getEtaEtdAtaAtd(
        this.model.schVesselId,
        this.model.schVoyage,
        "O",
        this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.model.schVoyage = "";
      this.model.schLocalEta = "";
      this.model.schLocalEtd = "";
      this.model.schLocalAta = "";
      this.model.schLocalAtd = "";
    }
  }

  changeCbMaintenancePORTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPORTerminalCode = event["locationCode"];
    } else {
      this.model.schPORTerminalCode = "";
    }
  }

  changeCbMaintenancePODLTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPODLTerminalCode = event["locationCode"];
    } else {
      this.model.schPODLTerminalCode = "";
    }
  }

  changeCbMaintenancePOLTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPOLTerminalCode = event["locationCode"];
    } else {
      this.model.schPOLTerminalCode = "";
    }
  }

  changeCbMaintenancePOT1Terminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPOT1TerminalCode = event["locationCode"];
    } else {
      this.model.schPOT1TerminalCode = "";
    }
  }

  changeCbMaintenancePODTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schPODTerminalCode = event["locationCode"];
    } else {
      this.model.schPODTerminalCode = "";
    }
  }

  changeCbMaintenanceFDESTTerminal(event) {
    if (
      event["locationCode"] != null &&
      event["locationCode"] != undefined &&
      event["locationCode"] != ""
    ) {
      this.model.schFDESTTerminalCode = event["locationCode"];
    } else {
      this.model.schFDESTTerminalCode = "";
    }
  }

  changeEventPfcContainerOwnershipProcess(value: string) {
    if (value == "COC") {
      this.model.schDetention = "S";
      this.model.schDemurrage = "S";
      this.lockDetention = false;
      this.lockDemurrage = false;
    } else {
      this.model.schDetention = "N";
      this.model.schDemurrage = "N";
      this.lockDetention = true;
      this.lockDemurrage = true;
    }

    if (value == "COC") {
      this.model.schContainerOwnershipPrefix = "C";
      this.model.schDemurragePOL="S";
      this.model.schDemurrageRequestPOL=0;
      this.model.schDetentionPOL="S";
      this.model.schDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    }else if (value == "COCTS") {
      this.model.schContainerOwnershipPrefix = "C";
      this.model.schDemurragePOL="N";
      this.model.schDemurrageRequestPOL=0;
      this.model.schDetentionPOL="N";
      this.model.schDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    } else if (value == "SOC" || value == "SOCTS") {
      this.model.schContainerOwnershipPrefix = "S";
      this.model.schDemurragePOL="N";
      this.model.schDemurrageRequestPOL=0;
      this.model.schDetentionPOL="N";
      this.model.schDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    } else if (value == "SLOT") {
      this.model.schContainerOwnershipPrefix = "SL";
      this.model.schDemurragePOL="N";
      this.model.schDemurrageRequestPOL=0;
      this.model.schDetentionPOL="N";
      this.model.schDetentionRequestPOL=0;
      this.lockDetentionPol= true;
      this.lockDemurragePol= true;
    }

    this.model.schDetentionRequest = 0;
    this.model.schDemurrageRequest = 0;

    //set model when container ownership changed
    this.model.schContainerOwnership = value;
  }

  //Radio button handler
  changeEventPfcContainerOwnership(event) {
    this.changeEventPfcContainerOwnershipProcess(event.target.value);
    this.lockSOC = false;
  }

  changeEventPfcContainerStatus(event) {
    this.model.schContainerStatus = event.target.value;
    if (this.model.schContainerStatus == "FULL") {
      this.lockFullEmpty = true;
    } else {
      this.lockFullEmpty = false;
    }
  }

  changeEventPfcShipmentType(event) {
    this.model.schShipmentType = event.target.value;
  }

  changeEventPfcDgClass(event) {
    this.model.schDgClass = event.target.value;
  }

  //End of Radio Button handler

  //FAI Group handler
  changeEventPfcDetailFAIGroup(event) {
    if (event.target.checked) {
      this.modelDetail.schDetailFAIGroup = "Y";
    } else {
      this.modelDetail.schDetailFAIGroup = "N";
    }
  }

  //Copy freight tab
  changeEventPfcDetailCopyTab(event) {
    if (event.target.checked) {
      this.modelDetail.schDetailCopyTab = "Y";
    } else {
      this.modelDetail.schDetailCopyTab = "N";
    }
  }

  //Door Open handler
  changeEventPfcDetailDoorOpen(event) {
    this.modelDetail.schDetailOogDoorOpen = event.target.value;
  }

  changeEventDetentionPOL(event) {
    console.log("ah", event.target.value);
    this.model.schDetentionPOL = event.target.value;
    if (this.model.schDetentionPOL == "R") {
      this.lockDetentionPol = false;
    } else {
      this.lockDetentionPol = true;
      this.model.schDetentionRequestPOL = 0;
    }
  }

  changeEventDemurragePol(event) {
    this.model.schDemurragePOL = event.target.value;
    if (this.model.schDemurragePOL == "R") {
      this.lockDemurragePol = false;
    } else {
      this.lockDemurragePol = true;
      this.model.schDemurrageRequestPOL = 0;
    }
  }

  //POD detention event
  changeEventPfcDetention(event) {
    this.model.schDetention = event.target.value;
    this.model.schDetentionRequest = 0;
  }

  //POD demurrage event
  changeEventPfcDemurrage(event) {
    this.model.schDemurrage = event.target.value;
    this.model.schDemurrageRequest = 0;
  }

  changeCbMaintenanceDetailCharge(event) {
    if (
      event.chargeId != null &&
      event.chargeCode != "" &&
      event.chargeId != undefined
    ) {
      this.modelDetail.schDetailChargeCode = event.chargeCode;
      this.modelDetail.schDetailChargeName = event.chargeName;
    } else {
      this.modelDetail.schDetailChargeCode = "";
      this.modelDetail.schDetailChargeName = "";
    }

    //revert to be able to search by core or name
    this.cbMaintenanceDetailCharge.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlNonAdhocNonMainCharge/chargeCodeOrName={query}"
    );
  }

  //handle container type
  changeEventPfcContainerType(event) {
    if (
      event.target.value != null &&
      event.target.value != "" &&
      event.target.value != undefined
    ) {
      this.model.schContainerType = event.target.value;

      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();

      var detailList: any[] = this.map.get(this.model.schContainerType);
      if (detailList != null && detailList != undefined) {
        detailList.forEach((element) => {
          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = element.schDetailChargeCode;
          x.schDetailChargeName = element.schDetailChargeName;
          x.schDetailContainerType = element.schDetailContainerType;

          switch (x.schDetailContainerType) {
            case "TNK":
              x.schDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              x.schDetailContainerTypeName = "OPEN TOP";
              break;
            case "FLR":
              x.schDetailContainerTypeName = "FLAT RACK";
              break;
            case "PLT":
              x.schDetailContainerTypeName = "PLATFORM";
              break;
            case "REF":
              x.schDetailContainerTypeName = "REEFER";
              break;
            case "DRY":
              x.schDetailContainerTypeName = "REEFER DRY";
              break;
            case "UC":
              x.schDetailContainerTypeName = "UC";
              break;
            case "STD":
              x.schDetailContainerTypeName = "NORMAL";
              break;
            default:
              break;
          }

          x.schDetailCurrency = element.schDetailCurrency;
          x.schDetailD20 = element.schDetailD20;
          x.schDetailD40 = element.schDetailD40;
          x.schDetailD45 = element.schDetailD45;
          x.schDetailFAIGroup = element.schDetailFAIGroup;
          x.schDetailH20 = element.schDetailH20;
          x.schDetailH40 = element.schDetailH40;
          x.schDetailH45 = element.schDetailH45;
          x.schDetailKT = element.schDetailKT;
          x.schDetailKillSlot = element.schDetailKillSlot;
          x.schDetailM3 = element.schDetailM3;
          x.schDetailMinCharge = element.schDetailMinCharge;
          x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
          if (x.schDetailOogDoorOpen == "N") {
            x.schDetailOogDoorOpenName = "N/A";
          } else if (x.schDetailOogDoorOpen == "D") {
            x.schDetailOogDoorOpenName = "Detached";
          } else if (x.schDetailOogDoorOpen == "B") {
            x.schDetailOogDoorOpenName = "Tied Both";
          } else if (x.schDetailOogDoorOpen == "L") {
            x.schDetailOogDoorOpenName = "Tied Left";
          } else if (x.schDetailOogDoorOpen == "R") {
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = element.schDetailOogOH;
          x.schDetailOogOLB = element.schDetailOogOLB;
          x.schDetailOogOLF = element.schDetailOogOLF;
          x.schDetailOogOWL = element.schDetailOogOWL;
          x.schDetailOogOWR = element.schDetailOogOWR;
          x.schDetailOogUcH = element.schDetailOogUcH;
          x.schDetailOogUcL = element.schDetailOogUcL;
          x.schDetailOogUcW = element.schDetailOogUcW;
          x.schDetailPC = element.schDetailPC;
          x.schDetailPCTab = element.schDetailPCTab;
          x.schDetailPayer = element.schDetailPayer;
          x.schDetailPayerName = element.schDetailPayerName;
          x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
          x.schDetailRT = element.schDetailRT;
          x.schDetailSeqNo = element.schDetailSeqNo;
          x.schDetailStatus = element.schDetailStatus;
          x.no = element.no;

          if (x.schDetailPCTab == "P") {
            //this.gridPrepaid.listStore.store.push(x);
            //this.gridPrepaid.loadData();
            this.gridApiPrepaid.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }

          if (x.schDetailPCTab == "C") {
            //this.gridCollect.listStore.store.push(x);
            //this.gridCollect.loadData();
            this.gridApiCollect.updateRowData({
              add: [JSON.parse(JSON.stringify(x))],
            });
          }
        });
      }
      this.calculateTotal();
    } else {
      this.model.schContainerType = "";
    }
  }

  changeCbMaintenanceDetailCurrency(event) {
    if (
      event.currencyCode != null &&
      event.currencyCode != "" &&
      event.currencyCode != undefined
    ) {
      this.modelDetail.schDetailCurrency = event.currencyCode;
    } else {
      this.modelDetail.schDetailCurrency = "";
    }
  }

  //godzila
  changeCbMaintenanceDetailPC(event) {
    if (
      event.prepaid_collect != null &&
      event.prepaid_collect != "" &&
      event.prepaid_collect != undefined
    ) {
      this.modelDetail.schDetailPC = event.prepaid_collect;

      if (this.modelDetail.schDetailPC == "P") {
        //worker for place of payment
        // if (
        //   this.model.schLoadPortCode != null &&
        //   this.model.schLoadPortCode != undefined &&
        //   this.model.schLoadPortCode != "" &&
        //   this.model.schLoadPortCode != "VNHCX" &&
        //   this.model.schLoadPortCode != "IDJKX" &&
        //   this.model.schLoadPortCode != "PKKHX" &&
        //   this.model.schLoadPortCode != "AEDBX" &&
        //   this.model.schLoadPortCode != "PHMNX" &&
        //   this.model.schLoadPortCode != "MYPKX" &&
        //   this.model.schLoadPortCode != "THBKX"
        // ) {
          if (
            this.model.schLoadPortCode != null &&
            this.model.schLoadPortCode != undefined &&
            this.model.schLoadPortCode != "" &&
            !this.polName.includes("FREIGHT GROUP")
          ) {
            this.fpPayer = "";

            this.cbMaintenanceDetailPlaceOfPayment.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterLocations/findByComboBoxControl/locationCode={query}"
            );
            this.cbMaintenanceDetailPlaceOfPayment.setValue(
              this.model.schLoadPortCode
            );
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
        }

        //worker for payer
        if (
          this.model.schFreightPartyId != null &&
          this.model.schFreightPartyId != undefined &&
          this.model.schFreightPartyId != ""
        ) {
          // this.cbMaintenanceDetailPayer.setUrl(
          //   this.configService.config.BASE_API.toString() +
          //     //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          //     // "/MasterCustomers/findByComboBoxControl/customerId={query}"
          //     // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
          //     "/MasterCustomers/findByComboBoxControlGeneral/payer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
          // );
          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.schFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     self.fpAcctCode = '';
          //     this.fpPayer = "";
          //   } else {
          //     self.modelDetail.schDetailPayer = callbackPayer.customerId;
          //     self.modelDetail.schDetailPayerName =
          //       callbackPayer.customerName;
          //   }
          // });

          let uri =
              this.configService.config.BASE_API.toString() +
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId="+
              this.model.schFreightPartyId.toString();

            var self = this;
            this.genericService.GET(uri).subscribe((resp) => {
                if (resp.ok) {
                  var datas = resp.json()['content'];
                  if (datas.length != 0) {
                    datas.forEach(function (data) {
                      if (data['isPayer'] != "Y" && self.dateCreatedForCbPayer == '*') {
                        self.cbMaintenanceDetailPayer.setForceValue("");
                        self.fpPayer = "";
                      } else {
                        self.modelDetail.schDetailPayer = data.customerId;
                        self.modelDetail.schDetailPayerName = data.customerName;
                        self.cbMaintenanceDetailPayer.setForceValue(data.customerName);

                        self.fpPayer = "<<";
                        if (event["cocAccountCode"] !== "") {
                          self.fpPayer += " COC: " + data.cocAccountCode;
                        }
                        if (event["socAccountCode"] !== "") {
                          self.fpPayer += " SOC: " + data.socAccountCode;
                        }
                        if (event["slotAccountCode"] !== "") {
                          self.fpPayer += " SLOT: " +  data.slotAccountCode;
                        }
                          self.fpPayer += " >>";
                      }
                    });
                  } else {
                    self.cbMaintenanceDetailPayer.setForceValue("");
                    self.fpPayer = "";
                  }
                }
            });



        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
          );
          this.cbMaintenanceDetailPayer.setValue("");
          this.modelDetail.schDetailPayer = "";
          this.modelDetail.schDetailPayerName = "";
          this.fpPayer = "";
        }
      } else {
        //worker for place of payment
        // if (
        //   this.model.schDischargePortCode != null &&
        //   this.model.schDischargePortCode != undefined &&
        //   this.model.schDischargePortCode != "" &&
        //   this.model.schLoadPortCode != "VNHCX" &&
        //   this.model.schLoadPortCode != "IDJKX" &&
        //   this.model.schLoadPortCode != "PKKHX" &&
        //   this.model.schLoadPortCode != "AEDBX" &&
        //   this.model.schLoadPortCode != "PHMNX" &&
        //   this.model.schLoadPortCode != "MYPKX" &&
        //   this.model.schLoadPortCode != "THBKX"
        // ) {
          if (
            this.model.schDischargePortCode != null &&
            this.model.schDischargePortCode != undefined &&
            this.model.schDischargePortCode != "" &&
            !this.podName.includes("FREIGHT GROUP")
          ) {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationCode={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue(
            this.model.schDischargePortCode
          );
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
          );
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
        }

        //worker for payer
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
        );
        this.cbMaintenanceDetailPayer.setValue("");
        this.modelDetail.schDetailPayer = "";
        this.modelDetail.schDetailPayerName = "";
        // this.fpAcctCode = "";
        this.fpPayer = "";

      }
    } else {
      this.fpPayer = "";
      this.modelDetail.schDetailPC = "";
    }
  }

  changeCbMaintenancePlaceOfPayment(event) {
    if (
      event.locationCode != null &&
      event.locationCode != null &&
      event.locationCode != undefined
    ) {
      this.modelDetail.schDetailPlaceOfPayment = event.locationCode;
      this.modelDetail.schDetailPlaceOfPaymentName = event.locationName;
      this.cbMaintenanceDetailPlaceOfPayment.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
      );
      // if (!String(event.locationName).includes("FREIGHT GROUP")) {
      //   this.modelDetail.schDetailPlaceOfPayment = event.locationCode;
      //   this.modelDetail.schDetailPlaceOfPaymentName = event.locationName;
      //   this.cbMaintenanceDetailPlaceOfPayment.setUrl(
      //     this.configService.config.BASE_API.toString() +
      //       "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
      //   );
      // } else {
      //   this.modelDetail.schDetailPlaceOfPayment = "";
      //   this.modelDetail.schDetailPlaceOfPaymentName = "";
      // }
    } else {
      this.modelDetail.schDetailPlaceOfPayment = "";
      this.modelDetail.schDetailPlaceOfPaymentName = "";
    }
  }
  changeCbMaintenanceDetailPayer(event) {
    if (
      event.customerId != null &&
      event.customerId != null &&
      event.customerId != undefined
    ) {
      this.modelDetail.schDetailPayer = event.customerId;
      this.modelDetail.schDetailPayerName = event.customerName;

      // this.fpAcctCode = "<<";
      // if (event["cocAccountCode"] !== "") {
      //   this.fpAcctCode += " COC: " + event["cocAccountCode"];
      // }
      // if (event["socAccountCode"] !== "") {
      //   this.fpAcctCode += " SOC: " + event["socAccountCode"];
      // }
      // if (event["slotAccountCode"] !== "") {
      //   this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      // }
      // this.fpAcctCode += " >>";
      this.fpPayer = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpPayer += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpPayer += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpPayer += " SLOT: " + event["slotAccountCode"];
      }
      this.fpPayer += " >>";


      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
      );
    } else {
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}",
      );
      this.modelDetail.schDetailPayer = "";
      this.modelDetail.schDetailPayerName = "";
      // this.fpAcctCode = "";
      this.fpPayer = "";

    }
  }

  changeAcctPIC(event) {
    // console.log('change event ACC PIC')
    if (
      event["userName"] != null &&
      event["userName"] != undefined &&
      event["userName"] != ""
    ) {
      this.model.schAcctPic = event["userName"];
      this.cbAcctPic.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterAccountPic/findAcctPIC/" +
        event["userName"]
      );
    } else {
      this.model.schAcctPic = "";
      // this.cbAcctPic.setUrl("");
    }

    //revert to search by name
    this.cbAcctPic.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterAccountPic/findAcctPIC/{query}"
    );
  }

  onSurcharge() {
    // console.log("** on surcharge **");

    this.genericUtil.showLoader();
    this.model.schEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.schEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    this.model.schSurchargeDetails = [];
    this.map.forEach((value: SurchargeDetail[], key: string) => {
      value.forEach((element) => {
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.schDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.schDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.schDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.schDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.schDetailCopyTab = element.schDetailCopyTab;
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = element.schDetailPCTab;
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;
        x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

        if (
          (this.currentActiveTab == "prepaid" && x.schDetailPCTab == "P") ||
          (this.currentActiveTab == "collect" && x.schDetailPCTab == "C")
        ) {
          this.model.schSurchargeDetails.push(x);
        }
      });
    });

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSurcharges/gettingSurcharge",
        this.model
      )
      .subscribe((resp) => {
        if (resp.json()["status"] == "OK") {
          var dataSurcharges: SurchargeDetail[] = [];
          var dt: any[] = [];
          dt = resp.json()["content"];

          dt.forEach((element) => {
            // console.log(element);

            var surchargeDetail: SurchargeDetail = new SurchargeDetail();
            surchargeDetail.schDetailChargeCode = element["chargeCode"];
            surchargeDetail.schDetailChargeName = element["chargeName"];
            surchargeDetail.schDetailContainerType = element["containerType"];
            switch (surchargeDetail.schDetailContainerType) {
              case "TNK":
                surchargeDetail.schDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                surchargeDetail.schDetailContainerTypeName = "OPEN TOP";
                break;
              case "FLR":
                surchargeDetail.schDetailContainerTypeName = "FLAT RACK";
                break;
              case "PLT":
                surchargeDetail.schDetailContainerTypeName = "PLATFORM";
                break;
              case "REF":
                surchargeDetail.schDetailContainerTypeName = "REEFER";
                break;
              case "DRY":
                surchargeDetail.schDetailContainerTypeName = "REEFER DRY";
                break;
              case "UC":
                surchargeDetail.schDetailContainerTypeName = "UC";
                break;
              case "STD":
                surchargeDetail.schDetailContainerTypeName = "NORMAL";
                break;
              default:
                break;
            }

            surchargeDetail.schDetailCurrency = element["currencyCode"];
            surchargeDetail.schDetailPC = element["freightType"];
            surchargeDetail.schDetailPCTab = element["pcTab"];
            if (element["payerCode"] == "") {
              surchargeDetail.schDetailPayer = "";
              surchargeDetail.schDetailPayerName = "";
            } else {
              surchargeDetail.schDetailPayer = element["payerCode"];
              surchargeDetail.schDetailPayerName = element["payerName"];
            }

            surchargeDetail.schDetailPlaceOfPayment =
              element["placeOfPaymentCode"];
            surchargeDetail.schDetailPlaceOfPaymentName =
              element["placeOfPaymentName"];

            surchargeDetail.schDetailD20 = 0;
            surchargeDetail.schDetailD40 = 0;
            surchargeDetail.schDetailD45 = 0;
            surchargeDetail.schDetailH20 = 0;
            surchargeDetail.schDetailH40 = 0;
            surchargeDetail.schDetailH45 = 0;

            if (element["D20"] != undefined) {
              surchargeDetail.schDetailD20 = Number.parseFloat(element["D20"]);
            }

            if (element["D40"] != undefined) {
              surchargeDetail.schDetailD40 = Number.parseFloat(element["D40"]);
            }

            if (element["D45"] != undefined) {
              surchargeDetail.schDetailD45 = Number.parseFloat(element["D45"]);
            }

            if (element["H20"] != undefined) {
              surchargeDetail.schDetailH20 = Number.parseFloat(element["H20"]);
            }

            if (element["H40"] != undefined) {
              surchargeDetail.schDetailH40 = Number.parseFloat(element["H40"]);
            }

            if (element["H45"] != undefined) {
              surchargeDetail.schDetailH45 = Number.parseFloat(element["H45"]);
            }

            surchargeDetail.schDetailOogDoorOpen = element["doorOpen"];
            if (surchargeDetail.schDetailOogDoorOpen == "N") {
              surchargeDetail.schDetailOogDoorOpenName = "N/A";
            } else if (surchargeDetail.schDetailOogDoorOpen == "D") {
              surchargeDetail.schDetailOogDoorOpenName = "Detached";
            } else if (surchargeDetail.schDetailOogDoorOpen == "B") {
              surchargeDetail.schDetailOogDoorOpenName = "Tied Both";
            } else if (surchargeDetail.schDetailOogDoorOpen == "L") {
              surchargeDetail.schDetailOogDoorOpenName = "Tied Left";
            } else if (surchargeDetail.schDetailOogDoorOpen == "R") {
              surchargeDetail.schDetailOogDoorOpenName = "Tied Right";
            }

            surchargeDetail.schDetailOogOH = element["oh"];
            surchargeDetail.schDetailOogOLB = element["olb"];
            surchargeDetail.schDetailOogOLF = element["olf"];
            surchargeDetail.schDetailOogOWL = element["owl"];
            surchargeDetail.schDetailOogOWR = element["owr"];
            surchargeDetail.schDetailOogUcH = element["uch"];
            surchargeDetail.schDetailOogUcL = element["ucl"];
            surchargeDetail.schDetailOogUcW = element["ucw"];

            // console.log(surchargeDetail);
            dataSurcharges.push(surchargeDetail);
          });

          if (this.map.get(this.model.schContainerType) == undefined) {
            // console.log("#1");
            let indexPrepaid: number = 0;
            let indexCollect: number = 0;
            dataSurcharges.forEach((element) => {
              if (element.schDetailPCTab == "P") {
                indexPrepaid++;
              }

              if (element.schDetailPCTab == "C") {
                indexCollect++;
              }
              if (element.schDetailPCTab == "P") {
                element.schDetailSeqNo = indexPrepaid;
                element.no = indexPrepaid;
              }
              if (element.schDetailPCTab == "C") {
                element.schDetailSeqNo = indexCollect;
                element.no = indexCollect;
              }
            });
            this.map.set(this.model.schContainerType, dataSurcharges);
          } else {
            // console.log("#2");
            let data: SurchargeDetail[] = this.map.get(
              this.model.schContainerType
            );
            // console.log("existing data ");
            // console.log(data);
            let indexPrepaid: number = 0;
            let indexCollect: number = 0;

            let dataList: SurchargeDetail[] = [];
            data.forEach((element) => {
              if (element.schDetailPCTab == "P") {
                indexPrepaid++;
              }

              if (element.schDetailPCTab == "C") {
                indexCollect++;
              }
              var x: SurchargeDetail = new SurchargeDetail();
              x.schDetailChargeCode = element.schDetailChargeCode;
              x.schDetailChargeName = element.schDetailChargeName;
              x.schDetailContainerType = element.schDetailContainerType;
              switch (x.schDetailContainerType) {
                case "TNK":
                  x.schDetailContainerTypeName = "TANK";
                  break;
                case "OPT":
                  x.schDetailContainerTypeName = "OPEN TOP";
                  break;
                case "FLR":
                  x.schDetailContainerTypeName = "FLAT RACK";
                  break;
                case "PLT":
                  x.schDetailContainerTypeName = "PLATFORM";
                  break;
                case "REF":
                  x.schDetailContainerTypeName = "REEFER";
                  break;
                case "DRY":
                  x.schDetailContainerTypeName = "REEFER DRY";
                  break;
                case "UC":
                  x.schDetailContainerTypeName = "UC";
                  break;
                case "STD":
                  x.schDetailContainerTypeName = "NORMAL";
                  break;
                default:
                  break;
              }
              x.schDetailCurrency = element.schDetailCurrency;
              x.schDetailPayer = element.schDetailPayer;
              x.schDetailPayerName = element.schDetailPayerName;
              x.schDetailPC = element.schDetailPC;
              x.schDetailPCTab = element.schDetailPCTab;
              if (element.schDetailPCTab == "P") {
                x.schDetailSeqNo = indexPrepaid;
                x.no = indexPrepaid;
              }
              if (element.schDetailPCTab == "C") {
                x.schDetailSeqNo = indexCollect;
                x.no = indexCollect;
              }
              x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
              x.schDetailPlaceOfPaymentName =
                element.schDetailPlaceOfPaymentName;
              x.schDetailD20 = element.schDetailD20;
              x.schDetailD40 = element.schDetailD40;
              x.schDetailD45 = element.schDetailD45;
              x.schDetailH20 = element.schDetailH20;
              x.schDetailH40 = element.schDetailH40;
              x.schDetailH45 = element.schDetailH45;
              x.schDetailKillSlot = element.schDetailKillSlot;
              x.schDetailKT = element.schDetailKT;
              x.schDetailM3 = element.schDetailM3;
              x.schDetailMinCharge = element.schDetailMinCharge;
              x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
              if (x.schDetailOogDoorOpen == "N") {
                x.schDetailOogDoorOpenName = "N/A";
              } else if (x.schDetailOogDoorOpen == "D") {
                x.schDetailOogDoorOpenName = "Detached";
              } else if (x.schDetailOogDoorOpen == "B") {
                x.schDetailOogDoorOpenName = "Tied Both";
              } else if (x.schDetailOogDoorOpen == "L") {
                x.schDetailOogDoorOpenName = "Tied Left";
              } else if (x.schDetailOogDoorOpen == "R") {
                x.schDetailOogDoorOpenName = "Tied Right";
              }
              x.schDetailOogOH = element.schDetailOogOH;
              x.schDetailOogOLB = element.schDetailOogOLB;
              x.schDetailOogOLF = element.schDetailOogOLF;
              x.schDetailOogOWL = element.schDetailOogOWL;
              x.schDetailOogOWR = element.schDetailOogOWR;
              x.schDetailOogUcH = element.schDetailOogUcH;
              x.schDetailOogUcW = element.schDetailOogUcW;
              x.schDetailOogUcL = element.schDetailOogUcL;
              x.schDetailRT = element.schDetailRT;
              dataList.push(x);
            });

            let surchargeList: SurchargeDetail[] = [];

            dataSurcharges.forEach((element) => {
              //// console.log(element);

              let snapCodeSurcharge: string = "";
              snapCodeSurcharge += element.schDetailPC.trim();
              snapCodeSurcharge += element.schDetailPCTab.trim();
              snapCodeSurcharge += element.schDetailChargeCode.trim();
              snapCodeSurcharge += element.schDetailCurrency.trim();
              snapCodeSurcharge += element.schDetailPlaceOfPayment.trim();
              snapCodeSurcharge += element.schDetailPayer.trim();
              snapCodeSurcharge +=
                element.schDetailKillSlot == null
                  ? "0"
                  : element.schDetailKillSlot.toString();
              snapCodeSurcharge += element.schDetailOogDoorOpen.trim();
              snapCodeSurcharge +=
                element.schDetailOogOH == null
                  ? "0"
                  : element.schDetailOogOH.toString();
              snapCodeSurcharge +=
                element.schDetailOogOWL == null
                  ? "0"
                  : element.schDetailOogOWL.toString();
              snapCodeSurcharge +=
                element.schDetailOogOWR == null
                  ? "0"
                  : element.schDetailOogOWR.toString();
              snapCodeSurcharge +=
                element.schDetailOogOLF == null
                  ? "0"
                  : element.schDetailOogOLF.toString();
              snapCodeSurcharge +=
                element.schDetailOogOLB == null
                  ? "0"
                  : element.schDetailOogOLB.toString();
              snapCodeSurcharge +=
                element.schDetailOogUcH == null
                  ? "0"
                  : element.schDetailOogUcH.toString();
              snapCodeSurcharge +=
                element.schDetailOogUcL == null
                  ? "0"
                  : element.schDetailOogUcL.toString();
              snapCodeSurcharge +=
                element.schDetailOogUcW == null
                  ? "0"
                  : element.schDetailOogUcW.toString();

              let chek: boolean = false;
              dataList.forEach((el) => {
                let snapCode: string = "";
                snapCode += el.schDetailPC.trim();
                snapCode += el.schDetailPCTab.trim();
                snapCode += el.schDetailChargeCode.trim();
                snapCode += el.schDetailCurrency.trim();
                snapCode += el.schDetailPlaceOfPayment.trim();
                snapCode += el.schDetailPayer.trim();
                snapCode +=
                  el.schDetailKillSlot == null
                    ? "0"
                    : el.schDetailKillSlot.toString();
                snapCode += el.schDetailOogDoorOpen.trim();
                snapCode +=
                  el.schDetailOogOH == null
                    ? "0"
                    : el.schDetailOogOH.toString();
                snapCode +=
                  el.schDetailOogOWL == null
                    ? "0"
                    : el.schDetailOogOWL.toString();
                snapCode +=
                  el.schDetailOogOWR == null
                    ? "0"
                    : el.schDetailOogOWR.toString();
                snapCode +=
                  el.schDetailOogOLF == null
                    ? "0"
                    : el.schDetailOogOLF.toString();
                snapCode +=
                  el.schDetailOogOLB == null
                    ? "0"
                    : el.schDetailOogOLB.toString();
                snapCode +=
                  el.schDetailOogUcH == null
                    ? "0"
                    : el.schDetailOogUcH.toString();
                snapCode +=
                  el.schDetailOogUcL == null
                    ? "0"
                    : el.schDetailOogUcL.toString();
                snapCode +=
                  el.schDetailOogUcW == null
                    ? "0"
                    : el.schDetailOogUcW.toString();

                // console.log("** snapCodeSurcharge **");
                // console.log(snapCodeSurcharge);

                // console.log("** snapCode **");
                // console.log(snapCode);

                if (snapCodeSurcharge == snapCode) {
                  // console.log("#SAMA");
                  chek = true;
                  var idx = dataList.indexOf(el);
                  dataList[idx].schDetailChargeCode =
                    element.schDetailChargeCode;
                  dataList[idx].schDetailChargeName =
                    element.schDetailChargeName;
                  dataList[idx].schDetailCurrency = element.schDetailCurrency;
                  dataList[idx].schDetailPayer = element.schDetailPayer;
                  dataList[idx].schDetailPayerName = element.schDetailPayerName;
                  dataList[idx].schDetailPC = element.schDetailPC;
                  dataList[idx].schDetailPCTab = element.schDetailPCTab;
                  dataList[idx].schDetailPlaceOfPayment =
                    element.schDetailPlaceOfPayment;
                  dataList[idx].schDetailPlaceOfPaymentName =
                    element.schDetailPlaceOfPaymentName;
                  dataList[idx].schDetailD20 = element.schDetailD20;
                  dataList[idx].schDetailD40 = element.schDetailD40;
                  dataList[idx].schDetailD45 = element.schDetailD45;
                  dataList[idx].schDetailH20 = element.schDetailH20;
                  dataList[idx].schDetailH40 = element.schDetailH40;
                  dataList[idx].schDetailH45 = element.schDetailH45;
                  dataList[idx].schDetailKillSlot = element.schDetailKillSlot;
                  dataList[idx].schDetailKT = element.schDetailKT;
                  dataList[idx].schDetailM3 = element.schDetailM3;
                  dataList[idx].schDetailMinCharge = element.schDetailMinCharge;
                  dataList[idx].schDetailOogDoorOpen =
                    element.schDetailOogDoorOpen;
                  dataList[idx].schDetailOogOH = element.schDetailOogOH;
                  dataList[idx].schDetailOogOLB = element.schDetailOogOLB;
                  dataList[idx].schDetailOogOLF = element.schDetailOogOLF;
                  dataList[idx].schDetailOogOWL = element.schDetailOogOWL;
                  dataList[idx].schDetailOogOWR = element.schDetailOogOWR;
                  dataList[idx].schDetailOogUcH = element.schDetailOogUcH;
                  dataList[idx].schDetailOogUcW = element.schDetailOogUcW;
                  dataList[idx].schDetailOogUcL = element.schDetailOogUcL;
                  dataList[idx].schDetailRT = element.schDetailRT;
                } else {
                  //data.push(element);
                  // console.log("#BEDA");
                }
              });

              // console.log("**chek**");
              // console.log(chek);

              if (!chek) {
                if (element.schDetailPCTab == "P") {
                  let ip: number = indexPrepaid;
                  element.schDetailSeqNo = ip + 1;
                  element.no = ip + 1;
                }

                if (element.schDetailPCTab == "C") {
                  let ip: number = indexCollect;
                  element.schDetailSeqNo = ip + 1;
                  element.no = ip + 1;
                }
                surchargeList.push(element);
              }
            });

            let dets: SurchargeDetail[] = [];
            dataList.forEach((element) => {
              dets.push(element);
            });
            surchargeList.forEach((element) => {
              dets.push(element);
            });

            this.map.set(this.model.schContainerType, dets);
          }

          // console.log(this.map);
          this.gridApiPrepaid.setRowData([]);
          this.gridApiCollect.setRowData([]);
          //this.gridPrepaid.onClear();
          //this.gridCollect.onClear();

          var detailList: any[] = this.map.get(this.model.schContainerType);
          detailList.forEach((element) => {
            // console.log(element);

            var x: SurchargeDetail = new SurchargeDetail();
            x.schDetailChargeCode = element.schDetailChargeCode;
            x.schDetailChargeName = element.schDetailChargeName;
            x.schDetailContainerType = element.schDetailContainerType;
            switch (x.schDetailContainerType) {
              case "TNK":
                x.schDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                x.schDetailContainerTypeName = "OPEN TOP";
                break;
              case "FLR":
                x.schDetailContainerTypeName = "FLAT RACK";
                break;
              case "PLT":
                x.schDetailContainerTypeName = "PLATFORM";
                break;
              case "REF":
                x.schDetailContainerTypeName = "REEFER";
                break;
              case "DRY":
                x.schDetailContainerTypeName = "REEFER DRY";
                break;
              case "UC":
                x.schDetailContainerTypeName = "UC";
                break;
              case "STD":
                x.schDetailContainerTypeName = "NORMAL";
                break;
              default:
                break;
            }
            x.schDetailCopyTab = element.schDetailCopyTab;
            x.schDetailCurrency = element.schDetailCurrency;
            x.schDetailD20 = element.schDetailD20;
            x.schDetailD40 = element.schDetailD40;
            x.schDetailD45 = element.schDetailD45;
            x.schDetailFAIGroup = element.schDetailFAIGroup;
            x.schDetailH20 = element.schDetailH20;
            x.schDetailH40 = element.schDetailH40;
            x.schDetailH45 = element.schDetailH45;
            x.schDetailKT = element.schDetailKT;
            x.schDetailKillSlot = element.schDetailKillSlot;
            x.schDetailM3 = element.schDetailM3;
            x.schDetailMinCharge = element.schDetailMinCharge;
            x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
            if (x.schDetailOogDoorOpen == "N") {
              x.schDetailOogDoorOpenName = "N/A";
            } else if (x.schDetailOogDoorOpen == "D") {
              x.schDetailOogDoorOpenName = "Detached";
            } else if (x.schDetailOogDoorOpen == "B") {
              x.schDetailOogDoorOpenName = "Tied Both";
            } else if (x.schDetailOogDoorOpen == "L") {
              x.schDetailOogDoorOpenName = "Tied Left";
            } else if (x.schDetailOogDoorOpen == "R") {
              x.schDetailOogDoorOpenName = "Tied Right";
            }
            x.schDetailOogOH = element.schDetailOogOH;
            x.schDetailOogOLB = element.schDetailOogOLB;
            x.schDetailOogOLF = element.schDetailOogOLF;
            x.schDetailOogOWL = element.schDetailOogOWL;
            x.schDetailOogOWR = element.schDetailOogOWR;
            x.schDetailOogUcH = element.schDetailOogUcH;
            x.schDetailOogUcL = element.schDetailOogUcL;
            x.schDetailOogUcW = element.schDetailOogUcW;
            x.schDetailPC = element.schDetailPC;
            x.schDetailPCTab = element.schDetailPCTab;
            x.schDetailPayer = element.schDetailPayer;
            x.schDetailPayerName = element.schDetailPayerName;
            x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
            x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
            x.schDetailRT = element.schDetailRT;
            x.schDetailSeqNo = element.schDetailSeqNo;
            x.schDetailStatus = element.schDetailStatus;
            x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

            if (x.schDetailPCTab == "P") {
              //this.gridPrepaid.listStore.store.push(x);
              //this.gridPrepaid.loadData();
              this.gridApiPrepaid.updateRowData({
                add: [JSON.parse(JSON.stringify(x))],
              });
            }

            if (x.schDetailPCTab == "C") {
              //this.gridCollect.listStore.store.push(x);
              //this.gridCollect.loadData();
              this.gridApiCollect.updateRowData({
                add: [JSON.parse(JSON.stringify(x))],
              });
            }
          });
        } else {
          this.message(
            "information",
            "Information",
            resp.json()["message"],
            "okonly",
            { ok: "" }
          );
        }

        this.genericUtil.hideLoader();
      });
  }

  onTransmit() {
    this.message(
      "information",
      "Information",
      "Do you wish to transmit freight " + this.model.schSurchargeId + "?",
      "yesno",
      {
        yes: "this.transmitProcess()",
        no: "",
      }
    );
  }

  onClone() {
    this.message(
      "information",
      "Information",
      "Not yet implemented.",
      "okonly",
      { ok: "" }
    );
  }

  //action when user click on button new
  onDetailNew() {
    // console.log("** on detail new **");
    this.modelDetail = new SurchargeDetail();
    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.schDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.schDetailCurrency
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.schDetailPC);
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.schDetailPlaceOfPayment
    );
    this.cbMaintenanceDetailPayer.setValue(this.modelDetail.schDetailPayer);

    if (this.currentActiveTab == "prepaid") {
      //this.modelDetail.pfcDetailSeqNo = this.gridPrepaid.listStore.store.length + 1;

      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });
      this.modelDetail.schDetailSeqNo = rowData.length + 1;

      //worker for place of payment
      // if (
      //   this.model.schLoadPortCode != null &&
      //   this.model.schLoadPortCode != undefined &&
      //   this.model.schLoadPortCode != "" &&
      //   this.model.schLoadPortCode != "VNHCX" &&
      //   this.model.schLoadPortCode != "IDJKX" &&
      //   this.model.schLoadPortCode != "PKKHX" &&
      //   this.model.schLoadPortCode != "AEDBX" &&
      //   this.model.schLoadPortCode != "PHMNX" &&
      //   this.model.schLoadPortCode != "MYPKX" &&
      //   this.model.schLoadPortCode != "THBKX"
      // ) {
      if (
        this.model.schLoadPortCode != null &&
        this.model.schLoadPortCode != undefined &&
        this.model.schLoadPortCode != "" &&
        !this.polName.includes("FREIGHT GROUP")
      ) {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue(
          this.model.schLoadPortCode
        );
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }

      //worker for payer
      // console.log("this.cookieService.getDefaultLocationCountry().toUpperCase() ==> " + this.cookieService.getDefaultLocationCountry().toUpperCase());
      // console.log("this.model.schFreightPartyCountry.toUpperCase() ==> " + this.model.schFreightPartyCountry.toUpperCase());

      if (
        this.cookieService.getDefaultLocationCountry().toUpperCase() ==
        this.model.schFreightPartyCountry.toUpperCase()
      ) {
        if (
          this.model.schFreightPartyId != null &&
          this.model.schFreightPartyId != undefined &&
          this.model.schFreightPartyId != ""
        ) {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
              // "/MasterCustomers/findByComboBoxControl/customerId={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
          );
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';

          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.schFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     // self.fpAcctCode = '';
          //   }
          // });
        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
          );
          // this.cbMaintenanceDetailPayer.setValue("");
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
        }
        // this.fpPayer = this.fpAcctCode;

      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
        );
        this.cbMaintenanceDetailPayer.setValue("**");
        this.fpPayer = "";

      }
    } else {
      //this.modelDetail.pfcDetailSeqNo = this.gridCollect.listStore.store.length + 1;
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });
      this.modelDetail.schDetailSeqNo = rowData.length + 1;

      //worker for place of payment
      // if (
      //   this.model.schDischargePortCode != null &&
      //   this.model.schDischargePortCode != undefined &&
      //   this.model.schDischargePortCode != "" &&
      //   this.model.schLoadPortCode != "VNHCX" &&
      //   this.model.schLoadPortCode != "IDJKX" &&
      //   this.model.schLoadPortCode != "PKKHX" &&
      //   this.model.schLoadPortCode != "AEDBX" &&
      //   this.model.schLoadPortCode != "PHMNX" &&
      //   this.model.schLoadPortCode != "MYPKX" &&
      //   this.model.schLoadPortCode != "THBKX"
      // ) {
      if (
          this.model.schDischargePortCode != null &&
          this.model.schDischargePortCode != undefined &&
          this.model.schDischargePortCode != "" &&
          !this.podName.includes("FREIGHT GROUP")
        ) {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue(
          this.model.schDischargePortCode
        );
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationNamePOP={query}"
        );
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }

      //worker for payer
      if (
        this.cookieService.getDefaultLocationCountry().toUpperCase() ==
        this.model.schFreightPartyCountry.toUpperCase()
      ) {
        if (
          this.model.schFreightPartyId != null &&
          this.model.schFreightPartyId != undefined &&
          this.model.schFreightPartyId != ""
        ) {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
              // "/MasterCustomers/findByComboBoxControl/customerId={query}"
              // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
          );
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
          // var self = this;
          // this.cbMaintenanceDetailPayer.setValue(this.model.schFreightPartyId, function(callbackPayer){
          //   if(callbackPayer.isPayer != 'Y' && self.dateCreatedForCbPayer == '*'){
          //     self.cbMaintenanceDetailPayer.setValue('');
          //     // self.fpAcctCode = '';
          //   }
          // });
        } else {
          this.cbMaintenanceDetailPayer.setUrl(
            this.configService.config.BASE_API.toString() +
              //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
              // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
              //"/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
              "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
          );
          // this.cbMaintenanceDetailPayer.setValue("");
          this.cbMaintenanceDetailPayer.setValue("**");
          this.fpPayer = '';
        }
        // this.fpPayer = this.fpAcctCode;

      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
        );
        this.cbMaintenanceDetailPayer.setValue("**");
        this.fpPayer = "";
      }

    }

    //container type worker
    // console.log("this.model.schContainerType => " + this.model.schContainerType)
    this.modelDetail.schDetailContainerType = this.model.schContainerType;
    switch (this.modelDetail.schDetailContainerType) {
      case "STD":
        this.modelDetail.schDetailContainerTypeName = "NORMAL";
        break;
      case "DRY":
        this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
        break;
      case "REF":
        this.modelDetail.schDetailContainerTypeName = "REEFER";
        break;
      case "TNK":
        this.modelDetail.schDetailContainerTypeName = "TANK";
        break;
      case "OPT":
        this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
        break;
      case "PLT":
        this.modelDetail.schDetailContainerTypeName = "PLATFORM";
        break;
      case "FLR":
        this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
        break;
      case "UC":
        this.modelDetail.schDetailContainerTypeName = "UC";
        break;
      default:
        break;
    }

    //OOG Control
    //Infer from selected container type
    if (
      this.modelDetail.schDetailContainerType == "STD" ||
      this.modelDetail.schDetailContainerType == "DRY"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = false;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.schDetailContainerType == "REF" ||
      this.modelDetail.schDetailContainerType == "TNK"
    ) {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (
      this.modelDetail.schDetailContainerType == "PLT" ||
      this.modelDetail.schDetailContainerType == "FLR"
    ) {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.schDetailContainerType == "OPT") {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = true;
    } else if (this.modelDetail.schDetailContainerType == "UC") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    }

    this.isUpdateDetail = false;
    // this.fpPayer = this.fpAcctCode;

    //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('show');
    this.genericUtil.showDialog(
      "freightNewChargeForm",
      "Surcharge Entry Screen",
      1200,
      500
    );
  }

  onDetailUpdate() {
    this.isErrorDetail = false;
    this.clearValidatorErrorDetail();

    // console.log("** on detail update **");
    /*
    if (this.gridPrepaid.getSelectedValues().length == 0 && this.gridCollect.getSelectedValues().length == 0) {
      this.message("information", "information", "Please select a record to update.", "okonly", {ok: "this.loading=false;"})
    } else  if (this.gridPrepaid.getSelectedValues().length > 1 || this.gridCollect.getSelectedValues().length > 1) {
      this.message("information", "information", "Please only select one record to update.", "okonly", {ok: "this.loading=false;"})
    } else {
    */
    if (
      this.gridApiPrepaid.getSelectedRows().length == 0 &&
      this.gridApiCollect.getSelectedRows().length == 0
    ) {
      this.message(
        "information",
        "information",
        "Please select a record to update.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else if (
      this.gridApiPrepaid.getSelectedRows().length > 1 ||
      this.gridApiCollect.getSelectedRows().length > 1
    ) {
      this.message(
        "information",
        "information",
        "Please only select one record to update.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      //set update flag to true
      this.isUpdateDetail = true;
      let md = new SurchargeDetail();
      /*
      if (this.currentActiveTab == "prepaid") {
        md = this.gridPrepaid.getSelectedValues()[0];
      } else {
        md = this.gridCollect.getSelectedValues()[0];
      }
      */
      if (this.currentActiveTab == "prepaid") {
        md = this.gridApiPrepaid.getSelectedRows()[0];
      } else {
        md = this.gridApiCollect.getSelectedRows()[0];
      }

      this.modelDetail = new SurchargeDetail();
      this.modelDetail.schDetailChargeCode = md.schDetailChargeCode;
      this.modelDetail.schDetailChargeName = md.schDetailChargeName;
      this.modelDetail.schDetailContainerType = md.schDetailContainerType;
      switch (this.modelDetail.schDetailContainerType) {
        case "STD":
          this.modelDetail.schDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          this.modelDetail.schDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          this.modelDetail.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          this.modelDetail.schDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          this.modelDetail.schDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      this.modelDetail.schDetailCurrency = md.schDetailCurrency;
      this.modelDetail.schDetailD20 = md.schDetailD20;
      this.modelDetail.schDetailD40 = md.schDetailD40;
      this.modelDetail.schDetailD45 = md.schDetailD45;
      this.modelDetail.schDetailFAIGroup = md.schDetailFAIGroup;
      this.modelDetail.schDetailH20 = md.schDetailH20;
      this.modelDetail.schDetailH40 = md.schDetailH40;
      this.modelDetail.schDetailH45 = md.schDetailH45;
      this.modelDetail.schDetailKT = md.schDetailKT;
      this.modelDetail.schDetailKillSlot = md.schDetailKillSlot;
      this.modelDetail.schDetailM3 = md.schDetailM3;
      this.modelDetail.schDetailMinCharge = md.schDetailMinCharge;
      this.modelDetail.schDetailOogDoorOpen = md.schDetailOogDoorOpen;
      if (this.modelDetail.schDetailOogDoorOpen == "N") {
        this.modelDetail.schDetailOogDoorOpenName = "N/A";
      } else if (this.modelDetail.schDetailOogDoorOpen == "D") {
        this.modelDetail.schDetailOogDoorOpenName = "Detached";
      } else if (this.modelDetail.schDetailOogDoorOpen == "B") {
        this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
      } else if (this.modelDetail.schDetailOogDoorOpen == "L") {
        this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
      } else if (this.modelDetail.schDetailOogDoorOpen == "R") {
        this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
      }
      this.modelDetail.schDetailOogOH = md.schDetailOogOH;
      this.modelDetail.schDetailOogOLB = md.schDetailOogOLB;
      this.modelDetail.schDetailOogOLF = md.schDetailOogOLF;
      this.modelDetail.schDetailOogOWL = md.schDetailOogOWL;
      this.modelDetail.schDetailOogOWR = md.schDetailOogOWR;
      this.modelDetail.schDetailOogUcH = md.schDetailOogUcH;
      this.modelDetail.schDetailOogUcL = md.schDetailOogUcL;
      this.modelDetail.schDetailOogUcW = md.schDetailOogUcW;
      this.modelDetail.schDetailPC = md.schDetailPC;
      this.modelDetail.schDetailPCTab = md.schDetailPCTab;
      this.modelDetail.schDetailPayer = md.schDetailPayer;
      this.modelDetail.schDetailPayerName = md.schDetailPayerName;
      this.modelDetail.schDetailPlaceOfPayment = md.schDetailPlaceOfPayment;
      this.modelDetail.schDetailPlaceOfPaymentName =
        md.schDetailPlaceOfPaymentName;
      this.modelDetail.schDetailRT = md.schDetailRT;
      this.modelDetail.schDetailSeqNo = md.schDetailSeqNo;
      this.modelDetail.schDetailStatus = md.schDetailStatus;

      //OOG Control
      //Infer from selected container type
      if (
        this.modelDetail.schDetailContainerType == "STD" ||
        this.modelDetail.schDetailContainerType == "DRY"
      ) {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = false;
        this.lockOOGUC = true;
      } else if (
        this.modelDetail.schDetailContainerType == "REF" ||
        this.modelDetail.schDetailContainerType == "TNK"
      ) {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (
        this.modelDetail.schDetailContainerType == "PLT" ||
        this.modelDetail.schDetailContainerType == "FLR"
      ) {
        this.lockOOGOOG = false;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (this.modelDetail.schDetailContainerType == "OPT") {
        this.lockOOGOOG = false;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = true;
      } else if (this.modelDetail.schDetailContainerType == "UC") {
        this.lockOOGOOG = true;
        this.lockOOGDoorOpen = true;
        this.lockOOGUC = false;
      }

      this.cbMaintenanceDetailCharge.setValue(
        this.modelDetail.schDetailChargeCode
      );
      this.cbMaintenanceDetailCurrency.setValue(
        this.modelDetail.schDetailCurrency
      );
      this.cbMaintenanceDetailPC.setValue(this.modelDetail.schDetailPC);

      this.cbMaintenanceDetailPlaceOfPayment.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterLocations/findByComboBoxControl/locationCode={query}"
      );
      this.cbMaintenanceDetailPlaceOfPayment.setValue(
        this.modelDetail.schDetailPlaceOfPayment
      );

      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}",
      );
      this.fpPayer = "";
      this.cbMaintenanceDetailPayer.setValue(this.modelDetail.schDetailPayer);

      //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('show');
      this.genericUtil.showDialog(
        "freightNewChargeForm",
        "Surcharge Entry Screen",
        1200,
        500
      );
    }
  }

  onDetailRemove() {
    // console.log("** on detail remove **");
    if (this.currentActiveTab == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Prepaid Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }
      if (this.gridApiPrepaid.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to delete.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to delete this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailRemovePrepaid();",
            no: "this.gridApiPrepaid.deselectAll();",
          }
        );
      }
      /*
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to delete.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to delete this charge(s)? ', 'yesno', {
          yes: 'this.onDetailRemovePrepaid();',
          no: 'this.gridPrepaid.clearSelectedValues();'
        });
      }
      */
    }

    if (this.currentActiveTab == "collect") {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Collect Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }
      if (this.gridApiCollect.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to delete.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to delete this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailRemoveCollect();",
            no: "this.gridApiCollect.deselectAll();",
          }
        );
      }

      /*
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to delete.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to delete this charge(s)? ', 'yesno', {
          yes: 'this.onDetailRemoveCollect();',
          no: 'this.gridCollect.clearSelectedValues();'
        });
      }
      */
    }
  }

  onDetailRemovePrepaid() {
    // console.log("** on detail remove prepaid **");
    // console.log("this.model.schContainerType ==> " + this.model.schContainerType);

    let prepaidList: SurchargeDetail[] = [];
    let collectList: SurchargeDetail[] = [];
    let fd: SurchargeDetail[] = this.map.get(this.model.schContainerType);
    fd.forEach((element) => {
      if (element.schDetailPCTab == "C") {
        collectList.push(element);
      }
    });

    var selectedData = this.gridApiPrepaid.getSelectedRows();
    this.gridApiPrepaid.updateRowData({ remove: selectedData });

    let idx = 1;
    var itemsToUpdate = [];
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx;
      x.no = idx;
      itemsToUpdate.push(x);
      prepaidList.push(x);
      idx++;
    });
    this.gridApiPrepaid.updateRowData({ update: itemsToUpdate });

    var self = this;
    setTimeout(() => {
      let dets: SurchargeDetail[] = [];
      prepaidList.forEach((element) => {
        dets.push(element);
      });
      collectList.forEach((element) => {
        dets.push(element);
      });
      self.map.set(self.model.schContainerType, dets);

      self.gridApiPrepaid.deselectAll();

      // Hitung TOTAL
      self.calculateTotal();
    }, 10);

    /*
    this.gridPrepaid.getSelectedValues().forEach(element=> {
      this.gridPrepaid.listStore.removeData(element);
    });

    let index = 1;
    this.gridPrepaid.listStore.store.forEach(element => {
      element.schDetailSeqNo = index;
      element.no = index;

      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = element.schDetailChargeCode;
      x.schDetailChargeName = element.schDetailChargeName;
      x.schDetailContainerType = element.schDetailContainerType;

      switch (element.schDetailContainerType) {
        case "STD":
          element.schDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.schDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.schDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.schDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.schDetailContainerTypeName = element.schDetailContainerTypeName;

      x.schDetailCurrency = element.schDetailCurrency;
      x.schDetailD20 = element.schDetailD20;
      x.schDetailD40 = element.schDetailD40;
      x.schDetailD45 = element.schDetailD45;
      x.schDetailFAIGroup = element.schDetailFAIGroup;
      x.schDetailH20 = element.schDetailH20;
      x.schDetailH40 = element.schDetailH40;
      x.schDetailH45 = element.schDetailH45;
      x.schDetailKT = element.schDetailKT;
      x.schDetailKillSlot = element.schDetailKillSlot;
      x.schDetailM3 = element.schDetailM3;
      x.schDetailMinCharge = element.schDetailMinCharge;
      x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
      if(x.schDetailOogDoorOpen == "N"){
        x.schDetailOogDoorOpenName = "N/A";
      }else if(x.schDetailOogDoorOpen == "D"){
        x.schDetailOogDoorOpenName = "Detached";
      }else if(x.schDetailOogDoorOpen == "B"){
        x.schDetailOogDoorOpenName = "Tied Both";
      }else if(x.schDetailOogDoorOpen == "L"){
        x.schDetailOogDoorOpenName = "Tied Left";
      }else if(x.schDetailOogDoorOpen == "R"){
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = element.schDetailOogOH;
      x.schDetailOogOLB = element.schDetailOogOLB;
      x.schDetailOogOLF = element.schDetailOogOLF;
      x.schDetailOogOWL = element.schDetailOogOWL;
      x.schDetailOogOWR = element.schDetailOogOWR;
      x.schDetailOogUcH = element.schDetailOogUcH;
      x.schDetailOogUcL = element.schDetailOogUcL;
      x.schDetailOogUcW = element.schDetailOogUcW;
      x.schDetailPC = element.schDetailPC;
      x.schDetailPCTab = element.schDetailPCTab;
      x.schDetailPayer = element.schDetailPayer;
      x.schDetailPayerName = element.schDetailPayerName;
      x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
      x.schDetailRT = element.schDetailRT;
      x.schDetailSeqNo = index;
      x.schDetailStatus = element.schDetailStatus;
      x.no = index;

      prepaidList.push(x);
      index++;
    });


    var self = this;
    setTimeout(() => {

      let dets: SurchargeDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.schContainerType, dets);
      self.gridPrepaid.clearSelectedValues();
      // console.log(self.map);

      if(self.gridPrepaid.listStore.store.length == 0){
        self.gridPrepaid.onClear()
      }

      self.gridPrepaid.loadData();
      self.gridCollect.loadData();

    }, 10);

    // TOTAL Prepaid
    this.totD20Prepaid = 0;
    this.totD40Prepaid = 0;
    this.totD45Prepaid = 0;
    this.totH20Prepaid = 0;
    this.totH40Prepaid = 0;
    this.totH45Prepaid = 0;

    this.gridPrepaid.listStore.store.forEach(el=>{
      console.log(el.pfcDetailD20)
      this.totD20Prepaid += el.schDetailD20,
      this.totD40Prepaid += el.schDetailD40,
      this.totD45Prepaid += el.schDetailD45,
      this.totH20Prepaid += el.schDetailH20,
      this.totH40Prepaid += el.schDetailH40,
      this.totH45Prepaid += el.schDetailH45
    })

    console.log("D20")
    console.log(this.totD20Prepaid)

    // console.log('** after update **');
    // console.log(this.map);

    this.gridPrepaid.total.forEach(element=>{
      element["SCHDETAILD20"] = this.totD20Prepaid,
      element["SCHDETAILD40"] = this.totD40Prepaid,
      element["SCHDETAILD45"] = this.totD45Prepaid,
      element["SCHDETAILH20"] = this.totH20Prepaid,
      element["SCHDETAILH40"] = this.totH40Prepaid,
      element["SCHDETAILH45"] = this.totH45Prepaid
    })
    */
  }

  onDetailRemoveCollect() {
    // console.log("** on detail remove collect **");

    let prepaidList: SurchargeDetail[] = [];
    let collectList: SurchargeDetail[] = [];
    let fd: SurchargeDetail[] = this.map.get(this.model.schContainerType);
    fd.forEach((element) => {
      if (element.schDetailPCTab == "P") {
        prepaidList.push(element);
      }
    });

    var selectedData = this.gridApiCollect.getSelectedRows();
    this.gridApiCollect.updateRowData({ remove: selectedData });

    let idx = 1;
    var itemsToUpdate = [];
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx;
      x.no = idx;
      itemsToUpdate.push(x);
      collectList.push(x);
      idx++;
    });
    this.gridApiCollect.updateRowData({ update: itemsToUpdate });

    var self = this;
    setTimeout(() => {
      let dets: SurchargeDetail[] = [];
      prepaidList.forEach((element) => {
        dets.push(element);
      });
      collectList.forEach((element) => {
        dets.push(element);
      });
      self.map.set(self.model.schContainerType, dets);

      self.gridApiCollect.deselectAll();

      // Hitung TOTAL
      self.calculateTotal();
    }, 10);

    /*
    this.gridCollect.getSelectedValues().forEach(element=> {
      this.gridCollect.listStore.removeData(element);
    });

    let index = 1;
    this.gridCollect.listStore.store.forEach(element => {
      element.schDetailSeqNo = index;
      element.no = index;

      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = element.schDetailChargeCode;
      x.schDetailChargeName = element.schDetailChargeName;
      x.schDetailContainerType = element.schDetailContainerType;

      switch (element.schDetailContainerType) {
        case "STD":
          element.schDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.schDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.schDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.schDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.schDetailContainerTypeName = element.schDetailContainerTypeName;

      x.schDetailCurrency = element.schDetailCurrency;
      x.schDetailD20 = element.schDetailD20;
      x.schDetailD40 = element.schDetailD40;
      x.schDetailD45 = element.schDetailD45;
      x.schDetailFAIGroup = element.schDetailFAIGroup;
      x.schDetailH20 = element.schDetailH20;
      x.schDetailH40 = element.schDetailH40;
      x.schDetailH45 = element.schDetailH45;
      x.schDetailKT = element.schDetailKT;
      x.schDetailKillSlot = element.schDetailKillSlot;
      x.schDetailM3 = element.schDetailM3;
      x.schDetailMinCharge = element.schDetailMinCharge;
      x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
      if(x.schDetailOogDoorOpen == "N"){
        x.schDetailOogDoorOpenName = "N/A";
      }else if(x.schDetailOogDoorOpen == "D"){
        x.schDetailOogDoorOpenName = "Detached";
      }else if(x.schDetailOogDoorOpen == "B"){
        x.schDetailOogDoorOpenName = "Tied Both";
      }else if(x.schDetailOogDoorOpen == "L"){
        x.schDetailOogDoorOpenName = "Tied Left";
      }else if(x.schDetailOogDoorOpen == "R"){
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = element.schDetailOogOH;
      x.schDetailOogOLB = element.schDetailOogOLB;
      x.schDetailOogOLF = element.schDetailOogOLF;
      x.schDetailOogOWL = element.schDetailOogOWL;
      x.schDetailOogOWR = element.schDetailOogOWR;
      x.schDetailOogUcH = element.schDetailOogUcH;
      x.schDetailOogUcL = element.schDetailOogUcL;
      x.schDetailOogUcW = element.schDetailOogUcW;
      x.schDetailPC = element.schDetailPC;
      x.schDetailPCTab = element.schDetailPCTab;
      x.schDetailPayer = element.schDetailPayer;
      x.schDetailPayerName = element.schDetailPayerName;
      x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
      x.schDetailRT = element.schDetailRT;
      x.schDetailSeqNo = index;
      x.schDetailStatus = element.schDetailStatus;
      x.no = index;

      collectList.push(x);
      index++;
    });

    var self = this;
    setTimeout(() => {

      let dets: SurchargeDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.schContainerType, dets);
      self.gridCollect.clearSelectedValues();
      // console.log(self.map);

      if(self.gridCollect.listStore.store.length == 0){
        self.gridCollect.onClear()
      }

      console.log("clear **")
      self.gridPrepaid.loadData();
      self.gridCollect.loadData();

    }, 10);

    // TOTAL Collect
    this.totD20Collect = 0;
    this.totD40Collect = 0;
    this.totD45Collect = 0;
    this.totH20Collect = 0;
    this.totH40Collect = 0;
    this.totH45Collect = 0;

    this.gridCollect.listStore.store.forEach(el=>{
      console.log(el.pfcDetailD20)
      this.totD20Collect += el.schDetailD20,
      this.totD40Collect += el.schDetailD40,
      this.totD45Collect += el.schDetailD45,
      this.totH20Collect += el.schDetailH20,
      this.totH40Collect += el.schDetailH40,
      this.totH45Collect += el.schDetailH45
    })

    console.log("D20")
    console.log(this.totD20Collect)

    // console.log('** after update **');
    // console.log(this.map);

    this.gridCollect.total.forEach(element=>{
      element["SCHDETAILD20"] = this.totD20Collect,
      element["SCHDETAILD40"] = this.totD40Collect,
      element["SCHDETAILD45"] = this.totD45Collect,
      element["SCHDETAILH20"] = this.totH20Collect,
      element["SCHDETAILH40"] = this.totH40Collect,
      element["SCHDETAILH45"] = this.totH45Collect
    })
    */
  }

  onDetailCopy() {
    if (this.currentActiveTab == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Prepaid Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }

      if (this.gridApiPrepaid.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to copy prepaid.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to copy this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailCopyPrepaid();",
            no: "this.gridApiPrepaid.deselectAll();this.loading = false;",
          }
        );
      }

      /*
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to copy prepaid.", "okonly", {ok: "this.loading=false;"})
      } else {
          this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
            yes: 'this.onDetailCopyPrepaid();',
            no: 'this.gridPrepaid.clearSelectedValues();this.loading = false;'
          });
      }
      */
    }

    if (this.currentActiveTab == "collect") {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length == 0) {
        this.message(
          "warning",
          "Warning",
          "There is no Collect Charge.",
          "okonly",
          { ok: "" }
        );
        return;
      }

      if (this.gridApiCollect.getSelectedRows().length == 0) {
        this.message(
          "information",
          "information",
          "Please select an entry to copy collect.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Are you sure you want to copy this charge(s)? ",
          "yesno",
          {
            yes: "this.onDetailCopyCollect();",
            no: "this.gridApiCollect.deselectAll();this.loading = false;",
          }
        );
      }
      /*
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to copy collect.", "okonly", {ok: "this.loading=false;"})
      } else {
          this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
            yes: 'this.onDetailCopyCollect();',
            no: 'this.gridCollect.clearSelectedValues();this.loading = false;'
          });
      }
      */
    }
  }

  onDetailCopyPrepaid() {
    // console.log("** on detail copy prepaid **");
    let mp: Map<any, any> = this.onDetailCopyPrepaidCheckDuplicate();

    mp.forEach((value: string, key: boolean) => {
      if (key) {
        //this.message("information", "information", "Duplicate " + value + " charge code detected.", "okonly", {ok: ""});
        this.message(
          "information",
          "information",
          "Duplicate " + value + " charge code detected.",
          "okonly",
          { ok: "this.gridApiPrepaid.deselectAll();" }
        );
      } else {
        this.onDetailCopyPrepaidProcess();
        //this.gridPrepaid.clearSelectedValues();
        this.gridApiPrepaid.deselectAll();
        this.onDetailAfterCopy(true);
      }
    });
  }

  onDetailCopyPrepaidCheckDuplicate(
    dataPrepaid?: SurchargeDetail
  ): Map<any, any> {
    // console.log("** on detail copy prepaid check duplicate **");
    // console.log(dataPrepaid);

    let mp = new Map();

    let isErrorDetail: boolean = false;
    let chargeDuplicate: string = "";

    if (dataPrepaid != undefined) {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = dataPrepaid.schDetailChargeCode;
      x.schDetailChargeName = dataPrepaid.schDetailChargeName;
      x.schDetailContainerType = dataPrepaid.schDetailContainerType;
      switch (x.schDetailContainerType) {
        case "TNK":
          x.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.schDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.schDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.schDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.schDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.schDetailCurrency = dataPrepaid.schDetailCurrency;
      x.schDetailD20 = dataPrepaid.schDetailD20;
      x.schDetailD40 = dataPrepaid.schDetailD40;
      x.schDetailD45 = dataPrepaid.schDetailD45;
      x.schDetailFAIGroup = dataPrepaid.schDetailFAIGroup;
      x.schDetailH20 = dataPrepaid.schDetailH20;
      x.schDetailH40 = dataPrepaid.schDetailH40;
      x.schDetailH45 = dataPrepaid.schDetailH45;
      x.schDetailKT = dataPrepaid.schDetailKT;
      x.schDetailKillSlot = dataPrepaid.schDetailKillSlot;
      x.schDetailM3 = dataPrepaid.schDetailM3;
      x.schDetailMinCharge = dataPrepaid.schDetailMinCharge;
      x.schDetailOogDoorOpen = dataPrepaid.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = dataPrepaid.schDetailOogOH;
      x.schDetailOogOLB = dataPrepaid.schDetailOogOLB;
      x.schDetailOogOLF = dataPrepaid.schDetailOogOLF;
      x.schDetailOogOWL = dataPrepaid.schDetailOogOWL;
      x.schDetailOogOWR = dataPrepaid.schDetailOogOWR;
      x.schDetailOogUcH = dataPrepaid.schDetailOogUcH;
      x.schDetailOogUcL = dataPrepaid.schDetailOogUcL;
      x.schDetailOogUcW = dataPrepaid.schDetailOogUcW;
      x.schDetailPC = dataPrepaid.schDetailPC;
      x.schDetailPCTab = "C";
      x.schDetailPayer = dataPrepaid.schDetailPayer;
      x.schDetailPayerName = dataPrepaid.schDetailPayerName;
      x.schDetailPlaceOfPayment = dataPrepaid.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = dataPrepaid.schDetailPlaceOfPaymentName;
      x.schDetailRT = dataPrepaid.schDetailRT;
      x.schDetailSeqNo = dataPrepaid.schDetailSeqNo;
      x.schDetailStatus = dataPrepaid.schDetailStatus;

      isErrorDetail = this.checkDuplicateCopy("collect", x);
      if (isErrorDetail) {
        chargeDuplicate = dataPrepaid.schDetailChargeCode.toUpperCase().trim();
      }
    } else {
      //this.gridPrepaid.getSelectedValues().forEach(element=> {
      this.gridApiPrepaid.getSelectedRows().forEach((element) => {
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.schDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.schDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.schDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.schDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "C";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;

        if (isErrorDetail == false) {
          isErrorDetail = this.checkDuplicateCopy("collect", x);
          if (isErrorDetail) {
            chargeDuplicate = element.schDetailChargeCode.toUpperCase().trim();
          }
        }
      });
    }

    // console.log("isErrorDetail => " + isErrorDetail);

    mp.set(isErrorDetail, chargeDuplicate);
    return mp;
  }

  onDetailCopyPrepaidProcess(dataPrepaid?: SurchargeDetail) {
    // console.log("** on detail copy prepaid process **");

    let prepaidList: SurchargeDetail[] = [];
    let collectList: SurchargeDetail[] = [];
    let fd: SurchargeDetail[] = this.map.get(this.model.schContainerType);
    fd.forEach((element) => {
      if (element.schDetailPCTab == "P") {
        prepaidList.push(element);
      }
    });

    if (dataPrepaid == undefined) {
      //this.gridPrepaid.getSelectedValues().forEach(element=> {
      this.gridApiPrepaid.getSelectedRows().forEach((element) => {
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.schDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.schDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.schDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.schDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "C";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;

        //this.gridCollect.listStore.addData(x);
        this.gridApiCollect.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      });
    } else {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = dataPrepaid.schDetailChargeCode;
      x.schDetailChargeName = dataPrepaid.schDetailChargeName;
      x.schDetailContainerType = dataPrepaid.schDetailContainerType;
      switch (x.schDetailContainerType) {
        case "TNK":
          x.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.schDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.schDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.schDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.schDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.schDetailCurrency = dataPrepaid.schDetailCurrency;
      x.schDetailD20 = dataPrepaid.schDetailD20;
      x.schDetailD40 = dataPrepaid.schDetailD40;
      x.schDetailD45 = dataPrepaid.schDetailD45;
      x.schDetailFAIGroup = dataPrepaid.schDetailFAIGroup;
      x.schDetailH20 = dataPrepaid.schDetailH20;
      x.schDetailH40 = dataPrepaid.schDetailH40;
      x.schDetailH45 = dataPrepaid.schDetailH45;
      x.schDetailKT = dataPrepaid.schDetailKT;
      x.schDetailKillSlot = dataPrepaid.schDetailKillSlot;
      x.schDetailM3 = dataPrepaid.schDetailM3;
      x.schDetailMinCharge = dataPrepaid.schDetailMinCharge;
      x.schDetailOogDoorOpen = dataPrepaid.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = dataPrepaid.schDetailOogOH;
      x.schDetailOogOLB = dataPrepaid.schDetailOogOLB;
      x.schDetailOogOLF = dataPrepaid.schDetailOogOLF;
      x.schDetailOogOWL = dataPrepaid.schDetailOogOWL;
      x.schDetailOogOWR = dataPrepaid.schDetailOogOWR;
      x.schDetailOogUcH = dataPrepaid.schDetailOogUcH;
      x.schDetailOogUcL = dataPrepaid.schDetailOogUcL;
      x.schDetailOogUcW = dataPrepaid.schDetailOogUcW;
      x.schDetailPC = dataPrepaid.schDetailPC;
      x.schDetailPCTab = "C";
      x.schDetailPayer = dataPrepaid.schDetailPayer;
      x.schDetailPayerName = dataPrepaid.schDetailPayerName;
      x.schDetailPlaceOfPayment = dataPrepaid.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = dataPrepaid.schDetailPlaceOfPaymentName;
      x.schDetailRT = dataPrepaid.schDetailRT;
      x.schDetailSeqNo = dataPrepaid.schDetailSeqNo;
      x.schDetailStatus = dataPrepaid.schDetailStatus;

      //this.gridCollect.listStore.addData(x);
      this.gridApiCollect.updateRowData({
        add: [JSON.parse(JSON.stringify(x))],
      });
    }

    let idx = 1;
    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx;
      x.no = idx;
      collectList.push(x);
      idx++;
    });

    let dets: SurchargeDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.schContainerType, dets);

    // GOS-831 incorrect charge sequence captured when using [Copy] function
    this.rowDataCollect = collectList;

    // Hitung TOTAL
    this.calculateTotal();

    /*
      let index = 1;
      collectList = [];
      this.gridCollect.listStore.store.forEach(element => {
        element.schDetailSeqNo = index

        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;

        switch (x.schDetailContainerType) {
          case "TANK":
            x.schDetailContainerType = 'TNK';
            break;
          case "OPEN TOP":
            x.schDetailContainerType = 'OPT';
            break;
          case "FLAT RACK":
            x.schDetailContainerType = 'FLR';
            break;
          case "PLATFORM":
            x.schDetailContainerType = 'PLT';
            break;
          case "REEFER":
            x.schDetailContainerType = 'REF';
            break;
          case "REEFER DRY":
            x.schDetailContainerType = 'DRY';
            break;
          case "UC":
            x.schDetailContainerType = 'UC';
            break;
          case "NORMAL":
            x.schDetailContainerType = 'STD';
            break;
          default:
            break;
        }

        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if(x.schDetailOogDoorOpen == "N"){
          x.schDetailOogDoorOpenName = "N/A";
        }else if(x.schDetailOogDoorOpen == "D"){
          x.schDetailOogDoorOpenName = "Detached";
        }else if(x.schDetailOogDoorOpen == "B"){
          x.schDetailOogDoorOpenName = "Tied Both";
        }else if(x.schDetailOogDoorOpen == "L"){
          x.schDetailOogDoorOpenName = "Tied Left";
        }else if(x.schDetailOogDoorOpen == "R"){
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "C";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = index;
        x.schDetailStatus = element.schDetailStatus;
        x.no = index;
        collectList.push(x);
        index++;
      });

      let dets: SurchargeDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });

      collectList.forEach(element => {
        dets.push(element)
      });

      this.map.set(this.model.schContainerType, dets);

      // TOTAL Collect
      this.totD20Collect = 0;
      this.totD40Collect = 0;
      this.totD45Collect = 0;
      this.totH20Collect = 0;
      this.totH40Collect = 0;
      this.totH45Collect = 0;

      this.gridCollect.listStore.store.forEach(el=>{
        console.log(el.pfcDetailD20)
        this.totD20Collect += el.schDetailD20,
        this.totD40Collect += el.schDetailD40,
        this.totD45Collect += el.schDetailD45,
        this.totH20Collect += el.schDetailH20,
        this.totH40Collect += el.schDetailH40,
        this.totH45Collect += el.schDetailH45
      })

      console.log("D20")
      console.log(this.totD20Collect)

      // console.log('** after update **');
      // console.log(this.map);

      this.gridCollect.total.forEach(element=>{
        element["SCHDETAILD20"] = this.totD20Collect,
        element["SCHDETAILD40"] = this.totD40Collect,
        element["SCHDETAILD45"] = this.totD45Collect,
        element["SCHDETAILH20"] = this.totH20Collect,
        element["SCHDETAILH40"] = this.totH40Collect,
        element["SCHDETAILH45"] = this.totH45Collect
      })
    */
  }

  onDetailCopyCollect() {
    // console.log("** on detail copy collect **");
    let mp: Map<any, any> = this.onDetailCopyCollectCheckDuplicate();
    mp.forEach((value: string, key: boolean) => {
      if (key) {
        //this.message("information", "information", "Duplicate " + value + " charge code detected.", "okonly", {ok: ""});
        this.message(
          "information",
          "information",
          "Duplicate " + value + " charge code detected.",
          "okonly",
          { ok: "this.gridApiCollect.deselectAll();" }
        );
      } else {
        this.onDetailCopyCollectProcess();
        //this.gridCollect.clearSelectedValues();
        this.gridApiCollect.deselectAll();
        this.onDetailAfterCopy(false);
      }
    });
  }

  onDetailCopyCollectCheckDuplicate(
    dataCollect?: SurchargeDetail
  ): Map<any, any> {
    // console.log("** on detail copy collect check duplicate **");

    let mp = new Map();
    let isErrorDetail: boolean = false;
    let chargeDuplicate: string = "";

    if (dataCollect != undefined) {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = dataCollect.schDetailChargeCode;
      x.schDetailChargeName = dataCollect.schDetailChargeName;
      x.schDetailContainerType = dataCollect.schDetailContainerType;
      switch (x.schDetailContainerType) {
        case "TNK":
          x.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.schDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.schDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.schDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.schDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.schDetailCurrency = dataCollect.schDetailCurrency;
      x.schDetailD20 = dataCollect.schDetailD20;
      x.schDetailD40 = dataCollect.schDetailD40;
      x.schDetailD45 = dataCollect.schDetailD45;
      x.schDetailFAIGroup = dataCollect.schDetailFAIGroup;
      x.schDetailH20 = dataCollect.schDetailH20;
      x.schDetailH40 = dataCollect.schDetailH40;
      x.schDetailH45 = dataCollect.schDetailH45;
      x.schDetailKT = dataCollect.schDetailKT;
      x.schDetailKillSlot = dataCollect.schDetailKillSlot;
      x.schDetailM3 = dataCollect.schDetailM3;
      x.schDetailMinCharge = dataCollect.schDetailMinCharge;
      x.schDetailOogDoorOpen = dataCollect.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = dataCollect.schDetailOogOH;
      x.schDetailOogOLB = dataCollect.schDetailOogOLB;
      x.schDetailOogOLF = dataCollect.schDetailOogOLF;
      x.schDetailOogOWL = dataCollect.schDetailOogOWL;
      x.schDetailOogOWR = dataCollect.schDetailOogOWR;
      x.schDetailOogUcH = dataCollect.schDetailOogUcH;
      x.schDetailOogUcL = dataCollect.schDetailOogUcL;
      x.schDetailOogUcW = dataCollect.schDetailOogUcW;
      x.schDetailPC = dataCollect.schDetailPC;
      x.schDetailPCTab = "P";
      x.schDetailPayer = dataCollect.schDetailPayer;
      x.schDetailPayerName = dataCollect.schDetailPayerName;
      x.schDetailPlaceOfPayment = dataCollect.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = dataCollect.schDetailPlaceOfPaymentName;
      x.schDetailRT = dataCollect.schDetailRT;
      x.schDetailSeqNo = dataCollect.schDetailSeqNo;
      x.schDetailStatus = dataCollect.schDetailStatus;

      isErrorDetail = this.checkDuplicateCopy("prepaid", x);
      if (isErrorDetail) {
        chargeDuplicate = dataCollect.schDetailChargeCode.toUpperCase().trim();
      }
    } else {
      this.gridApiCollect.getSelectedRows().forEach((element) => {
        //this.gridCollect.getSelectedValues().forEach(element=> {

        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.schDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.schDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.schDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.schDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "P";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;

        if (isErrorDetail == false) {
          isErrorDetail = this.checkDuplicateCopy("prepaid", x);
          if (isErrorDetail) {
            chargeDuplicate = element.schDetailChargeCode.toUpperCase().trim();
          }
        }
      });
    }

    // console.log("isErrorDetail => " + isErrorDetail);

    mp.set(isErrorDetail, chargeDuplicate);
    return mp;
  }

  onDetailCopyCollectProcess(dataCollect?: SurchargeDetail) {
    // console.log("** on detail copy collect process **");

    let prepaidList: SurchargeDetail[] = [];
    let collectList: SurchargeDetail[] = [];
    let fd: SurchargeDetail[] = this.map.get(this.model.schContainerType);
    fd.forEach((element) => {
      if (element.schDetailPCTab == "C") {
        collectList.push(element);
      }
    });

    if (dataCollect == undefined) {
      //this.gridCollect.getSelectedValues().forEach(element=> {
      this.gridApiCollect.getSelectedRows().forEach((element) => {
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            x.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "FLR":
            x.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "PLT":
            x.schDetailContainerTypeName = "PLATFORM";
            break;
          case "REF":
            x.schDetailContainerTypeName = "REEFER";
            break;
          case "DRY":
            x.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "UC":
            x.schDetailContainerTypeName = "UC";
            break;
          case "STD":
            x.schDetailContainerTypeName = "NORMAL";
            break;
          default:
            break;
        }
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "P";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;

        //this.gridPrepaid.listStore.addData(x);
        this.gridApiPrepaid.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      });
    } else {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = dataCollect.schDetailChargeCode;
      x.schDetailChargeName = dataCollect.schDetailChargeName;
      x.schDetailContainerType = dataCollect.schDetailContainerType;
      switch (x.schDetailContainerType) {
        case "TNK":
          x.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          x.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "FLR":
          x.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "PLT":
          x.schDetailContainerTypeName = "PLATFORM";
          break;
        case "REF":
          x.schDetailContainerTypeName = "REEFER";
          break;
        case "DRY":
          x.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "UC":
          x.schDetailContainerTypeName = "UC";
          break;
        case "STD":
          x.schDetailContainerTypeName = "NORMAL";
          break;
        default:
          break;
      }
      x.schDetailCurrency = dataCollect.schDetailCurrency;
      x.schDetailD20 = dataCollect.schDetailD20;
      x.schDetailD40 = dataCollect.schDetailD40;
      x.schDetailD45 = dataCollect.schDetailD45;
      x.schDetailFAIGroup = dataCollect.schDetailFAIGroup;
      x.schDetailH20 = dataCollect.schDetailH20;
      x.schDetailH40 = dataCollect.schDetailH40;
      x.schDetailH45 = dataCollect.schDetailH45;
      x.schDetailKT = dataCollect.schDetailKT;
      x.schDetailKillSlot = dataCollect.schDetailKillSlot;
      x.schDetailM3 = dataCollect.schDetailM3;
      x.schDetailMinCharge = dataCollect.schDetailMinCharge;
      x.schDetailOogDoorOpen = dataCollect.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = dataCollect.schDetailOogOH;
      x.schDetailOogOLB = dataCollect.schDetailOogOLB;
      x.schDetailOogOLF = dataCollect.schDetailOogOLF;
      x.schDetailOogOWL = dataCollect.schDetailOogOWL;
      x.schDetailOogOWR = dataCollect.schDetailOogOWR;
      x.schDetailOogUcH = dataCollect.schDetailOogUcH;
      x.schDetailOogUcL = dataCollect.schDetailOogUcL;
      x.schDetailOogUcW = dataCollect.schDetailOogUcW;
      x.schDetailPC = dataCollect.schDetailPC;
      x.schDetailPCTab = "P";
      x.schDetailPayer = dataCollect.schDetailPayer;
      x.schDetailPayerName = dataCollect.schDetailPayerName;
      x.schDetailPlaceOfPayment = dataCollect.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = dataCollect.schDetailPlaceOfPaymentName;
      x.schDetailRT = dataCollect.schDetailRT;
      x.schDetailSeqNo = dataCollect.schDetailSeqNo;
      x.schDetailStatus = dataCollect.schDetailStatus;

      //this.gridPrepaid.listStore.addData(x);
      this.gridApiPrepaid.updateRowData({
        add: [JSON.parse(JSON.stringify(x))],
      });
    }

    let idx = 1;
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;
      x.schDetailSeqNo = idx;
      x.no = idx;
      prepaidList.push(x);
      idx++;
    });

    let dets: SurchargeDetail[] = [];
    prepaidList.forEach((element) => {
      dets.push(element);
    });
    collectList.forEach((element) => {
      dets.push(element);
    });
    this.map.set(this.model.schContainerType, dets);

    // GOS-831 incorrect charge sequence captured when using [Copy] function
    this.rowDataPrepaid = prepaidList;

    //Hitung Total
    this.calculateTotal();

    /*
      let index = 1;
      prepaidList = [];
      this.gridPrepaid.listStore.store.forEach(element => {
        element.schDetailSeqNo = index
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        switch (x.schDetailContainerType) {
          case "TNK":
            x.schDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.schDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.schDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.schDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.schDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.schDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.schDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.schDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }

        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if(x.schDetailOogDoorOpen == "N"){
          x.schDetailOogDoorOpenName = "N/A";
        }else if(x.schDetailOogDoorOpen == "D"){
          x.schDetailOogDoorOpenName = "Detached";
        }else if(x.schDetailOogDoorOpen == "B"){
          x.schDetailOogDoorOpenName = "Tied Both";
        }else if(x.schDetailOogDoorOpen == "L"){
          x.schDetailOogDoorOpenName = "Tied Left";
        }else if(x.schDetailOogDoorOpen == "R"){
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = "P";
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = index;
        x.schDetailStatus = element.schDetailStatus;
        x.no = index;
        collectList.push(x);
        index++;
      });

      // console.log(prepaidList);
      // console.log(collectList);

      let dets: SurchargeDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      this.map.set(this.model.schContainerType, dets);

      // console.log(this.map);
      //this.gridPrepaid.clearSelectedValues();
      //this.onDetailAfterCopy(true);

      // TOTAL Collect
      this.totD20Prepaid = 0;
      this.totD40Prepaid = 0;
      this.totD45Prepaid = 0;
      this.totH20Prepaid = 0;
      this.totH40Prepaid = 0;
      this.totH45Prepaid = 0;

      this.gridPrepaid.listStore.store.forEach(el=>{
        console.log(el.pfcDetailD20)
        this.totD20Prepaid += el.schDetailD20,
        this.totD40Prepaid += el.schDetailD40,
        this.totD45Prepaid += el.schDetailD45,
        this.totH20Prepaid += el.schDetailH20,
        this.totH40Prepaid += el.schDetailH40,
        this.totH45Prepaid += el.schDetailH45
      })

      console.log("D20")
      console.log(this.totD20Collect)

      // console.log('** after update **');
      // console.log(this.map);

      this.gridPrepaid.total.forEach(element=>{
        element["SCHDETAILD20"] = this.totD20Prepaid,
        element["SCHDETAILD40"] = this.totD40Prepaid,
        element["SCHDETAILD45"] = this.totD45Prepaid,
        element["SCHDETAILH20"] = this.totH20Prepaid,
        element["SCHDETAILH40"] = this.totH40Prepaid,
        element["SCHDETAILH45"] = this.totH45Prepaid
      })
      */
  }

  onDetailAfterCopy(isPrepaid: boolean) {
    if (isPrepaid) {
      this.message(
        "information",
        "Information",
        "Copy prepaid to collect successfully.",
        "okonly",
        { ok: 'this.disableToolbarButtons = ""' }
      );
    } else {
      this.message(
        "information",
        "Information",
        "Copy collect to prepaid successfully.",
        "okonly",
        { ok: 'this.disableToolbarButtons = ""' }
      );
    }
    return;
  }

  onDetailFAI() {}

  onNewCharge_OK() {
    // console.log("** on new charge ok **");

    //debug
    // console.log(this.modelDetail)

    //safety first, replace blank to 0 (zeroes)
    this.modelDetail.schDetailD20 =
      this.modelDetail.schDetailD20 == null ? 0 : this.modelDetail.schDetailD20;
    this.modelDetail.schDetailD40 =
      this.modelDetail.schDetailD40 == null ? 0 : this.modelDetail.schDetailD40;
    this.modelDetail.schDetailD45 =
      this.modelDetail.schDetailD45 == null ? 0 : this.modelDetail.schDetailD45;
    this.modelDetail.schDetailH20 =
      this.modelDetail.schDetailH20 == null ? 0 : this.modelDetail.schDetailH20;
    this.modelDetail.schDetailH40 =
      this.modelDetail.schDetailH40 == null ? 0 : this.modelDetail.schDetailH40;
    this.modelDetail.schDetailH45 =
      this.modelDetail.schDetailH45 == null ? 0 : this.modelDetail.schDetailH45;
    this.modelDetail.schDetailM3 =
      this.modelDetail.schDetailM3 == null ? 0 : this.modelDetail.schDetailM3;
    this.modelDetail.schDetailKT =
      this.modelDetail.schDetailKT == null ? 0 : this.modelDetail.schDetailKT;
    this.modelDetail.schDetailRT =
      this.modelDetail.schDetailRT == null ? 0 : this.modelDetail.schDetailRT;
    this.modelDetail.schDetailMinCharge =
      this.modelDetail.schDetailMinCharge == null
        ? 0
        : this.modelDetail.schDetailMinCharge;
    this.modelDetail.schDetailKillSlot =
      this.modelDetail.schDetailKillSlot == null
        ? 0
        : this.modelDetail.schDetailKillSlot;
    this.modelDetail.schDetailOogOH =
      this.modelDetail.schDetailOogOH == null
        ? 0
        : this.modelDetail.schDetailOogOH;
    this.modelDetail.schDetailOogOWR =
      this.modelDetail.schDetailOogOWR == null
        ? 0
        : this.modelDetail.schDetailOogOWR;
    this.modelDetail.schDetailOogOWL =
      this.modelDetail.schDetailOogOWL == null
        ? 0
        : this.modelDetail.schDetailOogOWL;
    this.modelDetail.schDetailOogOLF =
      this.modelDetail.schDetailOogOLF == null
        ? 0
        : this.modelDetail.schDetailOogOLF;
    this.modelDetail.schDetailOogOLB =
      this.modelDetail.schDetailOogOLB == null
        ? 0
        : this.modelDetail.schDetailOogOLB;
    this.modelDetail.schDetailOogUcH =
      this.modelDetail.schDetailOogUcH == null
        ? 0
        : this.modelDetail.schDetailOogUcH;
    this.modelDetail.schDetailOogUcW =
      this.modelDetail.schDetailOogUcW == null
        ? 0
        : this.modelDetail.schDetailOogUcW;
    this.modelDetail.schDetailOogUcL =
      this.modelDetail.schDetailOogUcL == null
        ? 0
        : this.modelDetail.schDetailOogUcL;
    this.modelDetail.schDetailPCTab =
      this.currentActiveTab.toUpperCase() == "PREPAID" ? "P" : "C";

    //calculate charge total value
    this.modelDetail.schDetailTotalChargeValue =
      this.modelDetail.schDetailD20 +
      this.modelDetail.schDetailD40 +
      this.modelDetail.schDetailD45 +
      this.modelDetail.schDetailH20 +
      this.modelDetail.schDetailH40 +
      this.modelDetail.schDetailH45;
    // console.log("total = " + this.modelDetail.schDetailTotalChargeValue);

    this.setValidatorAddNewCharge();
    this.modelDetail["chargesValueValidator"] =
      this.checkChargesValueValidator();
    //this.modelDetail['mainChargesValidator'] = this.checkMainChargesValidator();

    this.isErrorDetail = this.onValidate(this.modelDetail);

    let chkDuplicate: boolean = false;
    if (this.currentActiveTab == "prepaid") {
      chkDuplicate = this.checkDuplicate("prepaid", this.modelDetail);
    } else if (this.currentActiveTab == "collect") {
      chkDuplicate = this.checkDuplicate("collect", this.modelDetail);
    }

    if (chkDuplicate) {
      this.modelDetail["error-schDuplicateChargeCode"] =
        "Duplicate " +
        this.modelDetail.schDetailChargeCode.toUpperCase().trim() +
        " charge code detected.";
      this.isErrorDetail = true;
    }

    if (this.modelDetail.schDetailCopyTab == "Y") {
      if (this.currentActiveTab == "prepaid") {
        // console.log("** copy freight to collect **");
        let mp: Map<boolean, string> = this.onDetailCopyPrepaidCheckDuplicate(
          this.modelDetail
        );
        mp.forEach((value: string, key: boolean) => {
          // console.log(key);
          if (key) {
            this.modelDetail["error-schDuplicateChargeCode"] =
              "Duplicate " + value + " charge code detected in collect tab.";
            this.isErrorDetail = true;
          }
        });
      }
      if (this.currentActiveTab == "collect") {
        // console.log("** copy freight to prepaid **");
        let mp: Map<boolean, string> = this.onDetailCopyCollectCheckDuplicate(
          this.modelDetail
        );
        mp.forEach((value: string, key: boolean) => {
          // console.log(key);
          if (key) {
            this.modelDetail["error-schDuplicateChargeCode"] =
              "Duplicate " + value + " charge code detected in prepaid tab.";
            this.isErrorDetail = true;
          }
        });
      }
    }

    /*
      // ----------- check FAI Group ----------------------------

      let mapFAI = new Map<String,Map<Number,String>>();
      let mapx = new Map();

      //update dulu ke mapx
      // ------------------------------------------------
      let prepaidListx: SurchargeDetail[] = [];
      let collectListx: SurchargeDetail[] = [];

      if(this.isUpdateDetail){
        //console.log("#3")

        let prepaidListx: SurchargeDetail[] = [];
        let collectListx: SurchargeDetail[] = [];
        let fdx: SurchargeDetail[] = this.map.get(this.model.schContainerType);
        fdx.forEach(element => {
          if (element.schDetailPCTab == 'P') {
            prepaidListx.push(element);
          }
          if (element.schDetailPCTab == 'C') {
            collectListx.push(element);
          }
        });

        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
        x.schDetailChargeName = this.modelDetail.schDetailChargeName;
        x.schDetailContainerType = this.modelDetail.schDetailContainerType;

        switch (this.modelDetail.schDetailContainerType) {
          case "STD":
            this.modelDetail.schDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.schDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.schDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.schDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.schDetailContainerTypeName = this.modelDetail.schDetailContainerTypeName;

        x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
        x.schDetailCurrency = this.modelDetail.schDetailCurrency;
        x.schDetailD20 = this.modelDetail.schDetailD20;
        x.schDetailD40 = this.modelDetail.schDetailD40;
        x.schDetailD45 = this.modelDetail.schDetailD45;
        x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
        x.schDetailH20 = this.modelDetail.schDetailH20;
        x.schDetailH40 = this.modelDetail.schDetailH40;
        x.schDetailH45 = this.modelDetail.schDetailH45;
        x.schDetailKT = this.modelDetail.schDetailKT;
        x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
        x.schDetailM3 = this.modelDetail.schDetailM3;
        x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
        x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
        // console.log("x.schDetailOogDoorOpen #1 => " + x.schDetailOogDoorOpen);
        if(x.schDetailOogDoorOpen == "N"){
          this.modelDetail.schDetailOogDoorOpenName = "N/A";
          x.schDetailOogDoorOpenName = "N/A";
        }else if(x.schDetailOogDoorOpen == "D"){
          this.modelDetail.schDetailOogDoorOpenName = "Detached";
          x.schDetailOogDoorOpenName = "Detached";
        }else if(x.schDetailOogDoorOpen == "B"){
          this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
          x.schDetailOogDoorOpenName = "Tied Both";
        }else if(x.schDetailOogDoorOpen == "L"){
          this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
          x.schDetailOogDoorOpenName = "Tied Left";
        }else if(x.schDetailOogDoorOpen == "R"){
          this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = this.modelDetail.schDetailOogOH;
        x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
        x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
        x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
        x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
        x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
        x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
        x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
        x.schDetailPC = this.modelDetail.schDetailPC;
        x.schDetailPCTab = this.modelDetail.schDetailPCTab;
        x.schDetailPayer = this.modelDetail.schDetailPayer;
        x.schDetailPayerName = this.modelDetail.schDetailPayerName;
        x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = this.modelDetail.schDetailPlaceOfPaymentName;
        x.schDetailRT = this.modelDetail.schDetailRT;
        x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
        x.schDetailStatus = this.modelDetail.schDetailStatus;
        x.no = x.schDetailSeqNo;

        if (this.currentActiveTab == "prepaid") {
          prepaidListx[x.schDetailSeqNo - 1] = x;
        } else { // collect
          collectListx[x.schDetailSeqNo - 1] = x;
        }

        let detsx: SurchargeDetail[] = [];
        prepaidListx.forEach(element => {
          detsx.push(element)
        });
        collectListx.forEach(element => {
          detsx.push(element)
        });
        mapx.set(this.model.schContainerType, detsx);

      }else{

        if (this.map.get(this.model.schContainerType) == undefined || this.map.get(this.model.schContainerType) == null) {
          //console.log("#1")
          this.modelDetailListx = [];

          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
          x.schDetailChargeName = this.modelDetail.schDetailChargeName;
          x.schDetailContainerType = this.modelDetail.schDetailContainerType;

          switch (this.modelDetail.schDetailContainerType) {
            case "STD":
              this.modelDetail.schDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.schDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.schDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.schDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.schDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.schDetailContainerTypeName = this.modelDetail.schDetailContainerTypeName;

          x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
          x.schDetailCurrency = this.modelDetail.schDetailCurrency;
          x.schDetailD20 = this.modelDetail.schDetailD20;
          x.schDetailD40 = this.modelDetail.schDetailD40;
          x.schDetailD45 = this.modelDetail.schDetailD45;
          x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
          x.schDetailH20 = this.modelDetail.schDetailH20;
          x.schDetailH40 = this.modelDetail.schDetailH40;
          x.schDetailH45 = this.modelDetail.schDetailH45;
          x.schDetailKT = this.modelDetail.schDetailKT;
          x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
          x.schDetailM3 = this.modelDetail.schDetailM3;
          x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
          x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
          // console.log("x.schDetailOogDoorOpen #1 => " + x.schDetailOogDoorOpen);
          if(x.schDetailOogDoorOpen == "N"){
            this.modelDetail.schDetailOogDoorOpenName = "N/A";
            x.schDetailOogDoorOpenName = "N/A";
          }else if(x.schDetailOogDoorOpen == "D"){
            this.modelDetail.schDetailOogDoorOpenName = "Detached";
            x.schDetailOogDoorOpenName = "Detached";
          }else if(x.schDetailOogDoorOpen == "B"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
            x.schDetailOogDoorOpenName = "Tied Both";
          }else if(x.schDetailOogDoorOpen == "L"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
            x.schDetailOogDoorOpenName = "Tied Left";
          }else if(x.schDetailOogDoorOpen == "R"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = this.modelDetail.schDetailOogOH;
          x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
          x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
          x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
          x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
          x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
          x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
          x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
          x.schDetailPC = this.modelDetail.schDetailPC;
          x.schDetailPCTab = this.modelDetail.schDetailPCTab;
          x.schDetailPayer = this.modelDetail.schDetailPayer;
          x.schDetailPayerName = this.modelDetail.schDetailPayerName;
          x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName = this.modelDetail.schDetailPlaceOfPaymentName;
          x.schDetailRT = this.modelDetail.schDetailRT;
          x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
          x.schDetailStatus = this.modelDetail.schDetailStatus;
          x.no = x.schDetailSeqNo;

          this.modelDetailListx.push(x);
          mapx.set(this.model.schContainerType, this.modelDetailListx);

        } else {
          //console.log("#2")
          let fdx: SurchargeDetail[] = this.map.get(this.model.schContainerType);
          fdx.forEach(element => {
            if (element.schDetailPCTab == 'P') {
              prepaidListx.push(element);
            }
            if (element.schDetailPCTab == 'C') {
              collectListx.push(element);
            }
          });

          let detsx: SurchargeDetail[] = [];
          prepaidListx.forEach(element => {
            detsx.push(element)
          });
          collectListx.forEach(element => {
            detsx.push(element)
          });
          mapx.set(this.model.schContainerType, detsx);

          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
          x.schDetailChargeName = this.modelDetail.schDetailChargeName;
          x.schDetailContainerType = this.modelDetail.schDetailContainerType;

          switch (this.modelDetail.schDetailContainerType) {
            case "STD":
              this.modelDetail.schDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.schDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.schDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.schDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.schDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.schDetailContainerTypeName = this.modelDetail.schDetailContainerTypeName;

          x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
          x.schDetailCurrency = this.modelDetail.schDetailCurrency;
          x.schDetailD20 = this.modelDetail.schDetailD20;
          x.schDetailD40 = this.modelDetail.schDetailD40;
          x.schDetailD45 = this.modelDetail.schDetailD45;
          x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
          x.schDetailH20 = this.modelDetail.schDetailH20;
          x.schDetailH40 = this.modelDetail.schDetailH40;
          x.schDetailH45 = this.modelDetail.schDetailH45;
          x.schDetailKT = this.modelDetail.schDetailKT;
          x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
          x.schDetailM3 = this.modelDetail.schDetailM3;
          x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
          x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
          // console.log("x.schDetailOogDoorOpen #1 => " + x.schDetailOogDoorOpen);
          if(x.schDetailOogDoorOpen == "N"){
            this.modelDetail.schDetailOogDoorOpenName = "N/A";
            x.schDetailOogDoorOpenName = "N/A";
          }else if(x.schDetailOogDoorOpen == "D"){
            this.modelDetail.schDetailOogDoorOpenName = "Detached";
            x.schDetailOogDoorOpenName = "Detached";
          }else if(x.schDetailOogDoorOpen == "B"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
            x.schDetailOogDoorOpenName = "Tied Both";
          }else if(x.schDetailOogDoorOpen == "L"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
            x.schDetailOogDoorOpenName = "Tied Left";
          }else if(x.schDetailOogDoorOpen == "R"){
            this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = this.modelDetail.schDetailOogOH;
          x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
          x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
          x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
          x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
          x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
          x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
          x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
          x.schDetailPC = this.modelDetail.schDetailPC;
          x.schDetailPCTab = this.modelDetail.schDetailPCTab;
          x.schDetailPayer = this.modelDetail.schDetailPayer;
          x.schDetailPayerName = this.modelDetail.schDetailPayerName;
          x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName = this.modelDetail.schDetailPlaceOfPaymentName;
          x.schDetailRT = this.modelDetail.schDetailRT;
          x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
          x.schDetailStatus = this.modelDetail.schDetailStatus;
          x.no = x.schDetailSeqNo;

          mapx.get(this.model.schContainerType).push(x);
        }

      }
      //console.log("mapx = ")
      //console.log(mapx)
      //-------------------------------------------------

      mapx.forEach((value: SurchargeDetail[], key: string) => {
        value.forEach(element => {

          if(element.schDetailFAIGroup == 'Y'){

            if(mapFAI.get(element.schDetailContainerType + "-" + element.schDetailPCTab) == null){

              let mp = new Map<Number, String>();
              var key:String =
              element.schDetailContainerType + ';;' +
              element.schDetailPayer + ';;' +
              element.schDetailCurrency + ';;' +
              element.schDetailPlaceOfPayment + ';;' +
              element.schDetailPC + ';;' +
              element.schDetailChargeCode + ';;' +
              element.schDetailOogDoorOpen + ';;' +
              element.schDetailOogOH + ';;' +
              element.schDetailOogOLB + ';;' +
              element.schDetailOogOLF + ';;' +
              element.schDetailOogOWL + ';;' +
              element.schDetailOogOWR + ';;' +
              element.schDetailOogUcH + ';;' +
              element.schDetailOogUcL + ';;' +
              element.schDetailOogUcW;
              mp.set(element.schDetailSeqNo,key)

              mapFAI.set(element.schDetailContainerType + "-" + element.schDetailPCTab, mp);

            }else{

              var key:String =
              element.schDetailContainerType + ';;' +
              element.schDetailPayer + ';;' +
              element.schDetailCurrency + ';;' +
              element.schDetailPlaceOfPayment + ';;' +
              element.schDetailPC + ';;' +
              element.schDetailChargeCode + ';;' +
              element.schDetailOogDoorOpen + ';;' +
              element.schDetailOogOH + ';;' +
              element.schDetailOogOLB + ';;' +
              element.schDetailOogOLF + ';;' +
              element.schDetailOogOWL + ';;' +
              element.schDetailOogOWR + ';;' +
              element.schDetailOogUcH + ';;' +
              element.schDetailOogUcL + ';;' +
              element.schDetailOogUcW;

              mapFAI.get(element.schDetailContainerType + "-" + element.schDetailPCTab).set(element.schDetailSeqNo, key)

            }
          }else{
            if(mapFAI.get(element.schDetailContainerType + "-" + element.schDetailPCTab) != null){
              if(mapFAI.get(element.schDetailContainerType + "-" + element.schDetailPCTab).get(element.schDetailSeqNo) != null){
                mapFAI.get(element.schDetailContainerType + "-" + element.schDetailPCTab).delete(element.schDetailSeqNo)
              }
            }
          }

        });
      });

      if(this.modelDetail.schDetailFAIGroup == 'Y'){

        if (this.modelDetail.schDetailChargeCode !== 'FAI'){
          // --------
          mapFAI.forEach((value: Map<Number,String>, key: string) => {
            value.forEach((element: String, k: Number) => {
              //console.log(element)

                var key:String =
                this.modelDetail.schDetailContainerType + ';;' +
                this.modelDetail.schDetailPayer + ';;' +
                this.modelDetail.schDetailCurrency + ';;' +
                this.modelDetail.schDetailPlaceOfPayment + ';;' +
                this.modelDetail.schDetailPC + ';;' +
                this.modelDetail.schDetailOogDoorOpen + ';;' +
                this.modelDetail.schDetailOogOH + ';;' +
                this.modelDetail.schDetailOogOLB + ';;' +
                this.modelDetail.schDetailOogOLF + ';;' +
                this.modelDetail.schDetailOogOWL + ';;' +
                this.modelDetail.schDetailOogOWR + ';;' +
                this.modelDetail.schDetailOogUcH + ';;' +
                this.modelDetail.schDetailOogUcL + ';;' +
                this.modelDetail.schDetailOogUcW  ; //+ ';;' + this.modelDetail.pfcDetailChargeCode;

                var el:String =
                element.split(';;')[0] + ';;' +
                element.split(';;')[1] + ';;' +
                element.split(';;')[2] + ';;' +
                element.split(';;')[3] + ';;' +
                element.split(';;')[4] + ';;' +
                element.split(';;')[6] + ';;' +
                element.split(';;')[7] + ';;' +
                element.split(';;')[8] + ';;' +
                element.split(';;')[9] + ';;' +
                element.split(';;')[10] + ';;' +
                element.split(';;')[11] + ';;' +
                element.split(';;')[12] + ';;' +
                element.split(';;')[13] + ';;' +
                element.split(';;')[14];

                if (el !== key){

                  this.isErrorDetail = true;
                  // Generate discrepancy message

                  if (this.modelDetail.schDetailPayer !== element.split(';;')[1]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Payer.';
                  }
                  if (this.modelDetail.schDetailCurrency !== element.split(';;')[2]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Currency.';
                  }
                  if (this.modelDetail.schDetailPlaceOfPayment !== element.split(';;')[3]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check PlaceOfPayment.';
                  }
                  if (this.modelDetail.schDetailPC !== element.split(';;')[4]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check P/C.';
                  }
                  if (this.modelDetail.schDetailOogDoorOpen !== element.split(';;')[6]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check Door Open.';
                  }
                  if (String(this.modelDetail.schDetailOogOH) !== element.split(';;')[7]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OH).';
                  }
                  if (String(this.modelDetail.schDetailOogOLB) !== element.split(';;')[8]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OLB).';
                  }
                  if (String(this.modelDetail.schDetailOogOLF) !== element.split(';;')[9]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OLF).';
                  }
                  if (String(this.modelDetail.schDetailOogOWL) !== element.split(';;')[10]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OWL).';
                  }
                  if (String(this.modelDetail.schDetailOogOWR) !== element.split(';;')[11]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check OOG (OWR).';
                  }
                  if (String(this.modelDetail.schDetailOogUcH) !== element.split(';;')[12]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCH.';
                  }
                  if (String(this.modelDetail.schDetailOogUcL) !== element.split(';;')[13]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCL.';
                  }
                  if (String(this.modelDetail.schDetailOogUcW) !== element.split(';;')[14]){
                    this.modelDetail['error-schDetailFAIGroup'] = 'Invalid FAI Group criteria. Please check UCW.';
                  }

                }

            });
          });

          // ----
        } else {
          this.isErrorDetail = true;
          this.modelDetail['error-schDetailFAIGroup'] = 'FAI is not applicable under FAI Group.';
        }

      }
      */

    if (!this.isErrorDetail) {
      // console.log("this.isUpdateDetail => " + this.isUpdateDetail)
      if (this.isUpdateDetail) {
        //update
        let prepaidList: SurchargeDetail[] = [];
        let collectList: SurchargeDetail[] = [];
        let fd: SurchargeDetail[] = this.map.get(this.model.schContainerType);
        fd.forEach((element) => {
          if (element.schDetailPCTab == "P") {
            prepaidList.push(element);
          }
          if (element.schDetailPCTab == "C") {
            collectList.push(element);
          }
        });

        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
        x.schDetailChargeName = this.modelDetail.schDetailChargeName;
        x.schDetailContainerType = this.modelDetail.schDetailContainerType;

        switch (this.modelDetail.schDetailContainerType) {
          case "STD":
            this.modelDetail.schDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.schDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.schDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.schDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.schDetailContainerTypeName =
          this.modelDetail.schDetailContainerTypeName;

        x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
        x.schDetailCurrency = this.modelDetail.schDetailCurrency;
        x.schDetailD20 = this.modelDetail.schDetailD20;
        x.schDetailD40 = this.modelDetail.schDetailD40;
        x.schDetailD45 = this.modelDetail.schDetailD45;
        x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
        x.schDetailH20 = this.modelDetail.schDetailH20;
        x.schDetailH40 = this.modelDetail.schDetailH40;
        x.schDetailH45 = this.modelDetail.schDetailH45;
        x.schDetailKT = this.modelDetail.schDetailKT;
        x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
        x.schDetailM3 = this.modelDetail.schDetailM3;
        x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
        x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
        // console.log("x.schDetailOogDoorOpen #1 => " + x.schDetailOogDoorOpen);
        if (x.schDetailOogDoorOpen == "N") {
          this.modelDetail.schDetailOogDoorOpenName = "N/A";
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          this.modelDetail.schDetailOogDoorOpenName = "Detached";
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = this.modelDetail.schDetailOogOH;
        x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
        x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
        x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
        x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
        x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
        x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
        x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
        x.schDetailPC = this.modelDetail.schDetailPC;
        x.schDetailPCTab = this.modelDetail.schDetailPCTab;
        x.schDetailPayer = this.modelDetail.schDetailPayer;
        x.schDetailPayerName = this.modelDetail.schDetailPayerName;
        x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName =
          this.modelDetail.schDetailPlaceOfPaymentName;
        x.schDetailRT = this.modelDetail.schDetailRT;
        x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
        x.schDetailStatus = this.modelDetail.schDetailStatus;
        x.no = x.schDetailSeqNo;

        if (this.currentActiveTab == "prepaid") {
          prepaidList[x.schDetailSeqNo - 1] = x;
        } else {
          // collect
          collectList[x.schDetailSeqNo - 1] = x;
        }

        let dets: SurchargeDetail[] = [];
        prepaidList.forEach((element) => {
          dets.push(element);
        });
        collectList.forEach((element) => {
          dets.push(element);
        });
        this.map.set(this.model.schContainerType, dets);

        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
        x.schDetailChargeName = this.modelDetail.schDetailChargeName;
        x.schDetailContainerType = this.modelDetail.schDetailContainerType;
        switch (this.modelDetail.schDetailContainerType) {
          case "STD":
            this.modelDetail.schDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.schDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.schDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.schDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.schDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.schDetailContainerTypeName =
          this.modelDetail.schDetailContainerTypeName;

        x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
        x.schDetailCurrency = this.modelDetail.schDetailCurrency;
        x.schDetailD20 = this.modelDetail.schDetailD20;
        x.schDetailD40 = this.modelDetail.schDetailD40;
        x.schDetailD45 = this.modelDetail.schDetailD45;
        x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
        x.schDetailH20 = this.modelDetail.schDetailH20;
        x.schDetailH40 = this.modelDetail.schDetailH40;
        x.schDetailH45 = this.modelDetail.schDetailH45;
        x.schDetailKT = this.modelDetail.schDetailKT;
        x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
        x.schDetailM3 = this.modelDetail.schDetailM3;
        x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
        x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
        // console.log("x.schDetailOogDoorOpen #2 => " + x.schDetailOogDoorOpen);
        if (x.schDetailOogDoorOpen == "N") {
          this.modelDetail.schDetailOogDoorOpenName = "N/A";
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          this.modelDetail.schDetailOogDoorOpenName = "Detached";
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = this.modelDetail.schDetailOogOH;
        x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
        x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
        x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
        x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
        x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
        x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
        x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
        x.schDetailPC = this.modelDetail.schDetailPC;
        x.schDetailPCTab = this.modelDetail.schDetailPCTab;
        x.schDetailPayer = this.modelDetail.schDetailPayer;
        x.schDetailPayerName = this.modelDetail.schDetailPayerName;
        x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName =
          this.modelDetail.schDetailPlaceOfPaymentName;
        x.schDetailRT = this.modelDetail.schDetailRT;
        x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
        x.schDetailStatus = this.modelDetail.schDetailStatus;
        x.no = x.schDetailSeqNo;

        if (this.currentActiveTab == "prepaid") {
          //this.gridPrepaid.listStore.updateData(x.schDetailSeqNo - 1, x);
          //this.gridPrepaid.loadData();

          var self = this;
          var itemsToUpdate = [];
          this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
            rowNode,
            index
          ) {
            let idx: number = self.modelDetail.schDetailSeqNo - 1;
            console.log(index + " - " + idx);
            if (index == idx) {
              var x: SurchargeDetail = rowNode.data;

              x.schDetailChargeCode = self.modelDetail.schDetailChargeCode;
              x.schDetailChargeName = self.modelDetail.schDetailChargeName;
              x.schDetailContainerType =
                self.modelDetail.schDetailContainerType;
              switch (self.modelDetail.schDetailContainerType) {
                case "STD":
                  self.modelDetail.schDetailContainerTypeName = "NORMAL";
                  break;
                case "DRY":
                  self.modelDetail.schDetailContainerTypeName = "REEFER DRY";
                  break;
                case "REF":
                  self.modelDetail.schDetailContainerTypeName = "REEFER";
                  break;
                case "TNK":
                  self.modelDetail.schDetailContainerTypeName = "TANK";
                  break;
                case "OPT":
                  self.modelDetail.schDetailContainerTypeName = "OPEN TOP";
                  break;
                case "PLT":
                  self.modelDetail.schDetailContainerTypeName = "PLATFORM";
                  break;
                case "FLR":
                  self.modelDetail.schDetailContainerTypeName = "FLAT RACK";
                  break;
                case "UC":
                  self.modelDetail.schDetailContainerTypeName = "UC";
                  break;
                default:
                  break;
              }
              x.schDetailContainerTypeName =
                self.modelDetail.schDetailContainerTypeName;

              x.schDetailCopyTab = self.modelDetail.schDetailCopyTab;
              x.schDetailCurrency = self.modelDetail.schDetailCurrency;
              x.schDetailD20 = self.modelDetail.schDetailD20;
              x.schDetailD40 = self.modelDetail.schDetailD40;
              x.schDetailD45 = self.modelDetail.schDetailD45;
              x.schDetailFAIGroup = self.modelDetail.schDetailFAIGroup;
              x.schDetailH20 = self.modelDetail.schDetailH20;
              x.schDetailH40 = self.modelDetail.schDetailH40;
              x.schDetailH45 = self.modelDetail.schDetailH45;
              x.schDetailKT = self.modelDetail.schDetailKT;
              x.schDetailKillSlot = self.modelDetail.schDetailKillSlot;
              x.schDetailM3 = self.modelDetail.schDetailM3;
              x.schDetailMinCharge = self.modelDetail.schDetailMinCharge;
              x.schDetailOogDoorOpen = self.modelDetail.schDetailOogDoorOpen;
              if (self.modelDetail.schDetailOogDoorOpen == "N") {
                self.modelDetail.schDetailOogDoorOpenName = "N/A";
              } else if (self.modelDetail.schDetailOogDoorOpen == "D") {
                self.modelDetail.schDetailOogDoorOpenName = "Detached";
              } else if (self.modelDetail.schDetailOogDoorOpen == "B") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Both";
              } else if (self.modelDetail.schDetailOogDoorOpen == "L") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Left";
              } else if (self.modelDetail.schDetailOogDoorOpen == "R") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Right";
              }
              x.schDetailOogDoorOpenName =
                self.modelDetail.schDetailOogDoorOpenName;

              x.schDetailOogOH = self.modelDetail.schDetailOogOH;
              x.schDetailOogOLB = self.modelDetail.schDetailOogOLB;
              x.schDetailOogOLF = self.modelDetail.schDetailOogOLF;
              x.schDetailOogOWL = self.modelDetail.schDetailOogOWL;
              x.schDetailOogOWR = self.modelDetail.schDetailOogOWR;
              x.schDetailOogUcH = self.modelDetail.schDetailOogUcH;
              x.schDetailOogUcL = self.modelDetail.schDetailOogUcL;
              x.schDetailOogUcW = self.modelDetail.schDetailOogUcW;
              x.schDetailPC = self.modelDetail.schDetailPC;
              x.schDetailPCTab = self.modelDetail.schDetailPCTab;
              x.schDetailPayer = self.modelDetail.schDetailPayer;
              x.schDetailPayerName = self.modelDetail.schDetailPayerName;
              x.schDetailPlaceOfPayment =
                self.modelDetail.schDetailPlaceOfPayment;
              x.schDetailPlaceOfPaymentName =
                self.modelDetail.schDetailPlaceOfPaymentName;
              x.schDetailRT = self.modelDetail.schDetailRT;
              x.schDetailSeqNo = self.modelDetail.schDetailSeqNo;
              x.schDetailStatus = self.modelDetail.schDetailStatus;
              x.no = x.schDetailSeqNo;

              itemsToUpdate.push(x);
            }
          });

          this.gridApiPrepaid.updateRowData({ update: itemsToUpdate });
        } else {
          // collect
          //this.gridCollect.listStore.updateData(x.schDetailSeqNo - 1, x);
          //this.gridCollect.loadData()

          var self = this;
          var itemsToUpdate = [];
          this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
            rowNode,
            index
          ) {
            let idx: number = self.modelDetail.schDetailSeqNo - 1;
            console.log(index + " - " + idx);
            if (index == idx) {
              var x: SurchargeDetail = rowNode.data;

              x.schDetailChargeCode = self.modelDetail.schDetailChargeCode;
              x.schDetailChargeName = self.modelDetail.schDetailChargeName;
              x.schDetailContainerType =
                self.modelDetail.schDetailContainerType;
              switch (self.modelDetail.schDetailContainerType) {
                case "STD":
                  self.modelDetail.schDetailContainerTypeName = "NORMAL";
                  break;
                case "DRY":
                  self.modelDetail.schDetailContainerTypeName = "REEFER DRY";
                  break;
                case "REF":
                  self.modelDetail.schDetailContainerTypeName = "REEFER";
                  break;
                case "TNK":
                  self.modelDetail.schDetailContainerTypeName = "TANK";
                  break;
                case "OPT":
                  self.modelDetail.schDetailContainerTypeName = "OPEN TOP";
                  break;
                case "PLT":
                  self.modelDetail.schDetailContainerTypeName = "PLATFORM";
                  break;
                case "FLR":
                  self.modelDetail.schDetailContainerTypeName = "FLAT RACK";
                  break;
                case "UC":
                  self.modelDetail.schDetailContainerTypeName = "UC";
                  break;
                default:
                  break;
              }
              x.schDetailContainerTypeName =
                self.modelDetail.schDetailContainerTypeName;

              x.schDetailCopyTab = self.modelDetail.schDetailCopyTab;
              x.schDetailCurrency = self.modelDetail.schDetailCurrency;
              x.schDetailD20 = self.modelDetail.schDetailD20;
              x.schDetailD40 = self.modelDetail.schDetailD40;
              x.schDetailD45 = self.modelDetail.schDetailD45;
              x.schDetailFAIGroup = self.modelDetail.schDetailFAIGroup;
              x.schDetailH20 = self.modelDetail.schDetailH20;
              x.schDetailH40 = self.modelDetail.schDetailH40;
              x.schDetailH45 = self.modelDetail.schDetailH45;
              x.schDetailKT = self.modelDetail.schDetailKT;
              x.schDetailKillSlot = self.modelDetail.schDetailKillSlot;
              x.schDetailM3 = self.modelDetail.schDetailM3;
              x.schDetailMinCharge = self.modelDetail.schDetailMinCharge;
              x.schDetailOogDoorOpen = self.modelDetail.schDetailOogDoorOpen;
              if (self.modelDetail.schDetailOogDoorOpen == "N") {
                self.modelDetail.schDetailOogDoorOpenName = "N/A";
              } else if (self.modelDetail.schDetailOogDoorOpen == "D") {
                self.modelDetail.schDetailOogDoorOpenName = "Detached";
              } else if (self.modelDetail.schDetailOogDoorOpen == "B") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Both";
              } else if (self.modelDetail.schDetailOogDoorOpen == "L") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Left";
              } else if (self.modelDetail.schDetailOogDoorOpen == "R") {
                self.modelDetail.schDetailOogDoorOpenName = "Tied Right";
              }
              x.schDetailOogDoorOpenName =
                self.modelDetail.schDetailOogDoorOpenName;

              x.schDetailOogOH = self.modelDetail.schDetailOogOH;
              x.schDetailOogOLB = self.modelDetail.schDetailOogOLB;
              x.schDetailOogOLF = self.modelDetail.schDetailOogOLF;
              x.schDetailOogOWL = self.modelDetail.schDetailOogOWL;
              x.schDetailOogOWR = self.modelDetail.schDetailOogOWR;
              x.schDetailOogUcH = self.modelDetail.schDetailOogUcH;
              x.schDetailOogUcL = self.modelDetail.schDetailOogUcL;
              x.schDetailOogUcW = self.modelDetail.schDetailOogUcW;
              x.schDetailPC = self.modelDetail.schDetailPC;
              x.schDetailPCTab = self.modelDetail.schDetailPCTab;
              x.schDetailPayer = self.modelDetail.schDetailPayer;
              x.schDetailPayerName = self.modelDetail.schDetailPayerName;
              x.schDetailPlaceOfPayment =
                self.modelDetail.schDetailPlaceOfPayment;
              x.schDetailPlaceOfPaymentName =
                self.modelDetail.schDetailPlaceOfPaymentName;
              x.schDetailRT = self.modelDetail.schDetailRT;
              x.schDetailSeqNo = self.modelDetail.schDetailSeqNo;
              x.schDetailStatus = self.modelDetail.schDetailStatus;
              x.no = x.schDetailSeqNo;

              itemsToUpdate.push(x);
            }
          });

          this.gridApiCollect.updateRowData({ update: itemsToUpdate });
        }

        // TOTAL jangan lupa
        this.calculateTotal();

        /*
            // console.log("** after update **");
            // console.log(this.map);

            // TOTAL Prepaid
            this.totD20Prepaid = 0;
            this.totD40Prepaid = 0;
            this.totD45Prepaid = 0;
            this.totH20Prepaid = 0;
            this.totH40Prepaid = 0;
            this.totH45Prepaid = 0;

            // TOTAL Collect

            this.totD20Collect = 0;
            this.totD40Collect = 0;
            this.totD45Collect = 0;
            this.totH20Collect = 0;
            this.totH40Collect = 0;
            this.totH45Collect = 0;


            this.gridPrepaid.listStore.store.forEach(el=>{
              console.log(el.pfcDetailD20)
              this.totD20Prepaid += el.schDetailD20,
              this.totD40Prepaid += el.schDetailD40,
              this.totD45Prepaid += el.schDetailD45,
              this.totH20Prepaid += el.schDetailH20,
              this.totH40Prepaid += el.schDetailH40,
              this.totH45Prepaid += el.schDetailH45
            })

            this.gridCollect.listStore.store.forEach(ele=>{
              this.totD20Collect += ele.schDetailD20,
              this.totD40Collect += ele.schDetailD40,
              this.totD45Collect += ele.schDetailD45,
              this.totH20Collect += ele.schDetailH20,
              this.totH40Collect += ele.schDetailH40,
              this.totH45Collect += ele.schDetailH45
            })

            // console.log("** after update **");
            // console.log(this.map);

            this.disableToolbarButtons = ""

            console.log("D20 Update")
            console.log(this.totD20Prepaid)

            this.gridPrepaid.total.forEach(element=>{
              element["SCHDETAILD20"] = this.totD20Prepaid,
              element["SCHDETAILD40"] = this.totD40Prepaid,
              element["SCHDETAILD45"] = this.totD45Prepaid,
              element["SCHDETAILH20"] = this.totH20Prepaid,
              element["SCHDETAILH40"] = this.totH40Prepaid,
              element["SCHDETAILH45"] = this.totH45Prepaid
            })

            this.gridCollect.total.forEach(element=>{
              element["SCHDETAILD20"] = this.totD20Collect,
              element["SCHDETAILD40"] = this.totD40Collect,
              element["SCHDETAILD45"] = this.totD45Collect,
              element["SCHDETAILH20"] = this.totH20Collect,
              element["SCHDETAILH40"] = this.totH40Collect,
              element["SCHDETAILH45"] = this.totH45Collect
            })
            */
      } else {
        //new
        if (
          this.map.get(this.model.schContainerType) == undefined ||
          this.map.get(this.model.schContainerType) == null
        ) {
          this.modelDetailList = [];

          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
          x.schDetailChargeName = this.modelDetail.schDetailChargeName;
          x.schDetailContainerType = this.modelDetail.schDetailContainerType;
          switch (this.modelDetail.schDetailContainerType) {
            case "STD":
              this.modelDetail.schDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.schDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.schDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.schDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.schDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.schDetailContainerTypeName =
            this.modelDetail.schDetailContainerTypeName;

          x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
          x.schDetailCurrency = this.modelDetail.schDetailCurrency;
          x.schDetailD20 = this.modelDetail.schDetailD20;
          x.schDetailD40 = this.modelDetail.schDetailD40;
          x.schDetailD45 = this.modelDetail.schDetailD45;
          x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
          x.schDetailH20 = this.modelDetail.schDetailH20;
          x.schDetailH40 = this.modelDetail.schDetailH40;
          x.schDetailH45 = this.modelDetail.schDetailH45;
          x.schDetailKT = this.modelDetail.schDetailKT;
          x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
          x.schDetailM3 = this.modelDetail.schDetailM3;
          x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
          x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
          if (x.schDetailOogDoorOpen == "N") {
            this.modelDetail.schDetailOogDoorOpenName = "N/A";
            x.schDetailOogDoorOpenName = "N/A";
          } else if (x.schDetailOogDoorOpen == "D") {
            this.modelDetail.schDetailOogDoorOpenName = "Detached";
            x.schDetailOogDoorOpenName = "Detached";
          } else if (x.schDetailOogDoorOpen == "B") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
            x.schDetailOogDoorOpenName = "Tied Both";
          } else if (x.schDetailOogDoorOpen == "L") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
            x.schDetailOogDoorOpenName = "Tied Left";
          } else if (x.schDetailOogDoorOpen == "R") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = this.modelDetail.schDetailOogOH;
          x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
          x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
          x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
          x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
          x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
          x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
          x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
          x.schDetailPC = this.modelDetail.schDetailPC;
          x.schDetailPCTab = this.modelDetail.schDetailPCTab;
          x.schDetailPayer = this.modelDetail.schDetailPayer;
          x.schDetailPayerName = this.modelDetail.schDetailPayerName;
          x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName =
            this.modelDetail.schDetailPlaceOfPaymentName;
          x.schDetailRT = this.modelDetail.schDetailRT;
          x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
          x.schDetailStatus = this.modelDetail.schDetailStatus;
          x.no = x.schDetailSeqNo;

          this.modelDetailList.push(x);
          this.map.set(this.model.schContainerType, this.modelDetailList);
        } else {
          var x: SurchargeDetail = new SurchargeDetail();
          x.schDetailChargeCode = this.modelDetail.schDetailChargeCode;
          x.schDetailChargeName = this.modelDetail.schDetailChargeName;
          x.schDetailContainerType = this.modelDetail.schDetailContainerType;
          switch (this.modelDetail.schDetailContainerType) {
            case "STD":
              this.modelDetail.schDetailContainerTypeName = "NORMAL";
              break;
            case "DRY":
              this.modelDetail.schDetailContainerTypeName = "REEFER DRY";
              break;
            case "REF":
              this.modelDetail.schDetailContainerTypeName = "REEFER";
              break;
            case "TNK":
              this.modelDetail.schDetailContainerTypeName = "TANK";
              break;
            case "OPT":
              this.modelDetail.schDetailContainerTypeName = "OPEN TOP";
              break;
            case "PLT":
              this.modelDetail.schDetailContainerTypeName = "PLATFORM";
              break;
            case "FLR":
              this.modelDetail.schDetailContainerTypeName = "FLAT RACK";
              break;
            case "UC":
              this.modelDetail.schDetailContainerTypeName = "UC";
              break;
            default:
              break;
          }
          x.schDetailContainerTypeName =
            this.modelDetail.schDetailContainerTypeName;

          x.schDetailCopyTab = this.modelDetail.schDetailCopyTab;
          x.schDetailCurrency = this.modelDetail.schDetailCurrency;
          x.schDetailD20 = this.modelDetail.schDetailD20;
          x.schDetailD40 = this.modelDetail.schDetailD40;
          x.schDetailD45 = this.modelDetail.schDetailD45;
          x.schDetailFAIGroup = this.modelDetail.schDetailFAIGroup;
          x.schDetailH20 = this.modelDetail.schDetailH20;
          x.schDetailH40 = this.modelDetail.schDetailH40;
          x.schDetailH45 = this.modelDetail.schDetailH45;
          x.schDetailKT = this.modelDetail.schDetailKT;
          x.schDetailKillSlot = this.modelDetail.schDetailKillSlot;
          x.schDetailM3 = this.modelDetail.schDetailM3;
          x.schDetailMinCharge = this.modelDetail.schDetailMinCharge;
          x.schDetailOogDoorOpen = this.modelDetail.schDetailOogDoorOpen;
          if (x.schDetailOogDoorOpen == "N") {
            this.modelDetail.schDetailOogDoorOpenName = "N/A";
            x.schDetailOogDoorOpenName = "N/A";
          } else if (x.schDetailOogDoorOpen == "D") {
            this.modelDetail.schDetailOogDoorOpenName = "Detached";
            x.schDetailOogDoorOpenName = "Detached";
          } else if (x.schDetailOogDoorOpen == "B") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Both";
            x.schDetailOogDoorOpenName = "Tied Both";
          } else if (x.schDetailOogDoorOpen == "L") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Left";
            x.schDetailOogDoorOpenName = "Tied Left";
          } else if (x.schDetailOogDoorOpen == "R") {
            this.modelDetail.schDetailOogDoorOpenName = "Tied Right";
            x.schDetailOogDoorOpenName = "Tied Right";
          }
          x.schDetailOogOH = this.modelDetail.schDetailOogOH;
          x.schDetailOogOLB = this.modelDetail.schDetailOogOLB;
          x.schDetailOogOLF = this.modelDetail.schDetailOogOLF;
          x.schDetailOogOWL = this.modelDetail.schDetailOogOWL;
          x.schDetailOogOWR = this.modelDetail.schDetailOogOWR;
          x.schDetailOogUcH = this.modelDetail.schDetailOogUcH;
          x.schDetailOogUcL = this.modelDetail.schDetailOogUcL;
          x.schDetailOogUcW = this.modelDetail.schDetailOogUcW;
          x.schDetailPC = this.modelDetail.schDetailPC;
          x.schDetailPCTab = this.modelDetail.schDetailPCTab;
          x.schDetailPayer = this.modelDetail.schDetailPayer;
          x.schDetailPayerName = this.modelDetail.schDetailPayerName;
          x.schDetailPlaceOfPayment = this.modelDetail.schDetailPlaceOfPayment;
          x.schDetailPlaceOfPaymentName =
            this.modelDetail.schDetailPlaceOfPaymentName;
          x.schDetailRT = this.modelDetail.schDetailRT;
          x.schDetailSeqNo = this.modelDetail.schDetailSeqNo;
          x.schDetailStatus = this.modelDetail.schDetailStatus;
          x.no = x.schDetailSeqNo;

          this.map.get(this.model.schContainerType).push(x);
        }

        if (this.currentActiveTab == "prepaid") {
          //this.gridPrepaid.listStore.store.push(this.modelDetail);
          //this.gridPrepaid.loadData();
          this.gridApiPrepaid.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
        }

        if (this.currentActiveTab == "collect") {
          //this.gridCollect.listStore.store.push(this.modelDetail);
          //this.gridCollect.loadData();
          this.gridApiCollect.updateRowData({
            add: [JSON.parse(JSON.stringify(this.modelDetail))],
          });
        }

        this.calculateTotal();
      }

      //// console.log("this.modelDetail.schDetailCopyTab => " + this.modelDetail.schDetailCopyTab);
      /*
        if(this.modelDetail.schDetailCopyTab == 'Y'){
          if (this.currentActiveTab == "prepaid") {
            this.onDetailCopyPrepaidProcess(this.modelDetail)
          }
          if (this.currentActiveTab == "collect") {
            this.onDetailCopyCollectProcess(this.modelDetail)
          }

        }
        this.genericUtil.closeDialog('freightNewChargeForm');
        this.gridPrepaid.loadData();
        this.gridCollect.loadData();
        this.gridPrepaid.clearSelectedValues();
        this.gridCollect.clearSelectedValues();

        this.disableToolbarButtons = ""

        // console.log(this.map);
        */

      if (this.modelDetail.schDetailCopyTab == "Y") {
        this.msg = "Are you sure you want to copy this charge(s)";
        this.genericUtil.showDialog(
          "confirmCopyDialog",
          "Confirmation",
          350,
          150
        );
      }

      this.isUpdateDetail = false;
      this.gridApiPrepaid.deselectAll();
      this.gridApiCollect.deselectAll();

      this.genericUtil.closeDialog("freightNewChargeForm");
    }
  }

  onNewCharge_Reset() {
    // console.log("** on new charge reset **");

    let seqNo = this.modelDetail.schDetailSeqNo;

    this.modelDetail = new SurchargeDetail();
    this.modelDetail.schDetailSeqNo = seqNo;

    this.cbMaintenanceDetailCharge.setValue(
      this.modelDetail.schDetailChargeCode
    );
    this.cbMaintenanceDetailCurrency.setValue(
      this.modelDetail.schDetailChargeCode
    );
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.schDetailPC);
    this.cbMaintenanceDetailPlaceOfPayment.setValue(
      this.modelDetail.schDetailPlaceOfPayment
    );

    if (
      this.cookieService.getDefaultLocationCountry().toUpperCase() ==
      this.model.schFreightPartyCountry.toUpperCase()
    ) {
      if (
        this.model.schFreightPartyId != null &&
        this.model.schFreightPartyId != undefined &&
        this.model.schFreightPartyId != ""
      ) {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
            // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerId={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerId={query}"
        );
        this.cbMaintenanceDetailPayer.setValue(this.model.schFreightPartyId);
      } else {
        this.cbMaintenanceDetailPayer.setUrl(
          this.configService.config.BASE_API.toString() +
            //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
            // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
            "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
        );
        this.cbMaintenanceDetailPayer.setValue("");
        this.fpPayer = "";
      }
    } else {
      this.cbMaintenanceDetailPayer.setUrl(
        this.configService.config.BASE_API.toString() +
          //"/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
          // "/MasterCustomers/findByComboBoxControlGeneral/payer/customerName={query}"
          "/MasterCustomers/findByComboBoxControlGeneral/pfcpayer/"+this.dateCreatedForCbPayer+"/"+this.custIdForCbPayer+"/customerName={query}"
      );
      this.cbMaintenanceDetailPayer.setValue("**");
      this.fpPayer = "";

    }

    // this.cbMaintenanceDetailPayer.setValue(this.modelDetail.schDetailPayer);
    this.isErrorDetail = false;
    this.isUpdateDetail = false;
    this.gridApiPrepaid.deselectAll();
    this.gridApiCollect.deselectAll();
  }

  onNewCharge_Close() {
    // console.log("** on new charge close **");
    this.isErrorDetail = false;
    this.isUpdateDetail = false;
    this.gridApiPrepaid.deselectAll();
    this.gridApiCollect.deselectAll();

    this.genericUtil.closeDialog("freightNewChargeForm");
  }

  checkDuplicateValidator(): boolean {
    console.log("## checkDuplicateValidator ##");
    let isDuplicateCodeDetected = false;
    let msg = "";
    let self = this;
    /*
    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
      var element :SurchargeDetail = rowNode.data;
      let snapCode: string = "";
      snapCode += element.schDetailChargeCode.trim();
      snapCode += (element.schDetailKillSlot == null) ? "0" : element.schDetailKillSlot.toString();
      snapCode += element.schDetailOogDoorOpen.trim();
      snapCode += (element.schDetailOogOH == null) ? "0" : element.schDetailOogOH.toLocaleString();
      snapCode += (element.schDetailOogOWL == null) ? "0" : element.schDetailOogOWL.toLocaleString();
      snapCode += (element.schDetailOogOWR == null) ? "0" : element.schDetailOogOWR.toLocaleString();
      snapCode += (element.schDetailOogOLF == null) ? "0" : element.schDetailOogOLF.toLocaleString();
      snapCode += (element.schDetailOogOLB == null) ? "0" : element.schDetailOogOLB.toLocaleString();
      snapCode += (element.schDetailOogUcH == null) ? "0" : element.schDetailOogUcH.toLocaleString();
      snapCode += (element.schDetailOogUcL == null) ? "0" : element.schDetailOogUcL.toLocaleString();
      snapCode += (element.schDetailOogUcW == null) ? "0" : element.schDetailOogUcW.toLocaleString();

      if(isDuplicateCodeDetected){
        console.log(msg)
        self.model['error-checkDuplicateValidator']= msg
        return
      }

      self.gridApiPrepaid.forEachNodeAfterFilterAndSort(function(rowNode, index) {
        var el :SurchargeDetail = rowNode.data;
        let snapCodeComparer: string = "";
        snapCodeComparer += el.schDetailChargeCode.trim();
        snapCodeComparer += (el.schDetailKillSlot == null) ? "0" : el.schDetailKillSlot.toString();
        snapCodeComparer += el.schDetailOogDoorOpen.trim();
        snapCodeComparer += (el.schDetailOogOH == null) ? "0" : el.schDetailOogOH.toLocaleString();
        snapCodeComparer += (el.schDetailOogOWL == null) ? "0" : el.schDetailOogOWL.toLocaleString();
        snapCodeComparer += (el.schDetailOogOWR == null) ? "0" : el.schDetailOogOWR.toLocaleString();
        snapCodeComparer += (el.schDetailOogOLF == null) ? "0" : el.schDetailOogOLF.toLocaleString();
        snapCodeComparer += (el.schDetailOogOLB == null) ? "0" : el.schDetailOogOLB.toLocaleString();
        snapCodeComparer += (el.schDetailOogUcH == null) ? "0" : el.schDetailOogUcH.toLocaleString();
        snapCodeComparer += (el.schDetailOogUcL == null) ? "0" : el.schDetailOogUcL.toLocaleString();
        snapCodeComparer += (el.schDetailOogUcW == null) ? "0" : el.schDetailOogUcW.toLocaleString();

        if(element.schDetailSeqNo != el.schDetailSeqNo){
            console.log(snapCodeComparer + " - " + snapCode)
            if (snapCodeComparer == snapCode) {
                isDuplicateCodeDetected = true;
                msg = "Duplicate " + el.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
                console.log(msg)
                return
            }
        }
      });

    });

    if(!isDuplicateCodeDetected){
      this.gridApiCollect.forEachNodeAfterFilterAndSort(function(rowNode, index) {
        var element :SurchargeDetail = rowNode.data;
        let snapCode: string = "";
        snapCode += element.schDetailChargeCode.trim();
        snapCode += (element.schDetailKillSlot == null) ? "0" : element.schDetailKillSlot.toString();
        snapCode += element.schDetailOogDoorOpen.trim();
        snapCode += (element.schDetailOogOH == null) ? "0" : element.schDetailOogOH.toLocaleString();
        snapCode += (element.schDetailOogOWL == null) ? "0" : element.schDetailOogOWL.toLocaleString();
        snapCode += (element.schDetailOogOWR == null) ? "0" : element.schDetailOogOWR.toLocaleString();
        snapCode += (element.schDetailOogOLF == null) ? "0" : element.schDetailOogOLF.toLocaleString();
        snapCode += (element.schDetailOogOLB == null) ? "0" : element.schDetailOogOLB.toLocaleString();
        snapCode += (element.schDetailOogUcH == null) ? "0" : element.schDetailOogUcH.toLocaleString();
        snapCode += (element.schDetailOogUcL == null) ? "0" : element.schDetailOogUcL.toLocaleString();
        snapCode += (element.schDetailOogUcW == null) ? "0" : element.schDetailOogUcW.toLocaleString();

        if(isDuplicateCodeDetected){
          console.log(msg)
          self.model['error-checkDuplicateValidator']= msg
          return
        }

        self.gridApiCollect.forEachNodeAfterFilterAndSort(function(rowNode, index) {
          var el :SurchargeDetail = rowNode.data;
          let snapCodeComparer: string = "";
          snapCodeComparer += el.schDetailChargeCode.trim();
          snapCodeComparer += (el.schDetailKillSlot == null) ? "0" : el.schDetailKillSlot.toString();
          snapCodeComparer += el.schDetailOogDoorOpen.trim();
          snapCodeComparer += (el.schDetailOogOH == null) ? "0" : el.schDetailOogOH.toLocaleString();
          snapCodeComparer += (el.schDetailOogOWL == null) ? "0" : el.schDetailOogOWL.toLocaleString();
          snapCodeComparer += (el.schDetailOogOWR == null) ? "0" : el.schDetailOogOWR.toLocaleString();
          snapCodeComparer += (el.schDetailOogOLF == null) ? "0" : el.schDetailOogOLF.toLocaleString();
          snapCodeComparer += (el.schDetailOogOLB == null) ? "0" : el.schDetailOogOLB.toLocaleString();
          snapCodeComparer += (el.schDetailOogUcH == null) ? "0" : el.schDetailOogUcH.toLocaleString();
          snapCodeComparer += (el.schDetailOogUcL == null) ? "0" : el.schDetailOogUcL.toLocaleString();
          snapCodeComparer += (el.schDetailOogUcW == null) ? "0" : el.schDetailOogUcW.toLocaleString();

          if(element.schDetailSeqNo != el.schDetailSeqNo){
              console.log(snapCodeComparer + " - " + snapCode)
              if (snapCodeComparer == snapCode) {
                  isDuplicateCodeDetected = true;
                  msg = "Duplicate " + el.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
                  console.log(msg)
                  return
              }
          }
        });

      });
    }
    */

    let result: Boolean = false;
    let str: String = "";

    let mapCTTab = new Map();

    this.map.forEach((value: SurchargeDetail[], key: string) => {
      value.forEach((element) => {
        let snapCode: string = "";
        snapCode += element.schDetailChargeCode.trim();
        snapCode +=
          element.schDetailKillSlot == null
            ? "0"
            : element.schDetailKillSlot.toString();
        snapCode += element.schDetailOogDoorOpen.trim();
        snapCode +=
          element.schDetailOogOH == null
            ? "0"
            : element.schDetailOogOH.toLocaleString();
        snapCode +=
          element.schDetailOogOWL == null
            ? "0"
            : element.schDetailOogOWL.toLocaleString();
        snapCode +=
          element.schDetailOogOWR == null
            ? "0"
            : element.schDetailOogOWR.toLocaleString();
        snapCode +=
          element.schDetailOogOLF == null
            ? "0"
            : element.schDetailOogOLF.toLocaleString();
        snapCode +=
          element.schDetailOogOLB == null
            ? "0"
            : element.schDetailOogOLB.toLocaleString();
        snapCode +=
          element.schDetailOogUcH == null
            ? "0"
            : element.schDetailOogUcH.toLocaleString();
        snapCode +=
          element.schDetailOogUcL == null
            ? "0"
            : element.schDetailOogUcL.toLocaleString();
        snapCode +=
          element.schDetailOogUcW == null
            ? "0"
            : element.schDetailOogUcW.toLocaleString();

        let key: string =
          element.schDetailContainerType +
          "-" +
          element.schDetailPCTab +
          "/" +
          element.schDetailChargeCode +
          ":" +
          snapCode;

        if (mapCTTab.get(key) == undefined) {
          let datas: SurchargeDetail[] = [];
          datas.push(element);
          mapCTTab.set(key, datas);
        } else {
          mapCTTab.get(key).push(element);
        }
      });
    });

    console.log(mapCTTab);

    let mapCTTabStr = new Map();
    mapCTTab.forEach((value: SurchargeDetail[], key: string) => {
      if (value.length > 1) {
        let strCtLists2: string[] = key.split(":");
        let strCtLists: string[] = strCtLists2[0].split("/");

        strCtLists[0] = strCtLists[0].replace(/TNK/g, "TANK");
        strCtLists[0] = strCtLists[0].replace(/OPT/g, "OPEN TOP");
        strCtLists[0] = strCtLists[0].replace(/FLR/g, "FLAT RACK");
        strCtLists[0] = strCtLists[0].replace(/PLT/g, "PLATFORM");
        strCtLists[0] = strCtLists[0].replace(/REF/g, "REEFER");
        strCtLists[0] = strCtLists[0].replace(/STD/g, "NORMAL");
        strCtLists[0] = strCtLists[0].replace(/UC/g, "UC");
        strCtLists[0] = strCtLists[0].replace(/-P/g, " [PREPAID]");
        strCtLists[0] = strCtLists[0].replace(/-C/g, " [COLLECT]");

        //str += strCtLists[0] + ",";
        //result = true;
        if (mapCTTabStr.get(strCtLists[1]) == undefined) {
          let ctListStr: any[] = [];
          ctListStr.push(strCtLists[0]);
          mapCTTabStr.set(strCtLists[1], ctListStr);
        } else {
          mapCTTabStr.get(strCtLists[1]).push(strCtLists[0]);
        }
      }
    });

    console.log(mapCTTabStr);

    if (mapCTTabStr.size > 0) {
      str += "";
      mapCTTabStr.forEach((value: any[], key: string) => {
        str +=
          "Duplicate " +
          key +
          " charge code detected." +
          value.toString() +
          ",<br/>";
      });
      console.log(str.substring(0, str.length - 6));
      this.model["error-checkDuplicateValidator"] =
        str.substring(0, str.length - 6) + ".";
      isDuplicateCodeDetected = true;
    }

    return isDuplicateCodeDetected;
  }

  checkDuplicate(target: string, modelDetail: SurchargeDetail): boolean {
    console.log("** check duplicate **");
    console.log(modelDetail);
    let isDuplicateCodeDetected = false;
    let snapCode: string = "";
    //snapCode += modelDetail.schDetailPC.trim();
    snapCode += modelDetail.schDetailChargeCode.trim();
    //snapCode += modelDetail.schDetailCurrency.trim();
    //snapCode += modelDetail.schDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.schDetailPayer.trim();
    snapCode +=
      modelDetail.schDetailKillSlot == null
        ? "0"
        : modelDetail.schDetailKillSlot.toString();
    snapCode += modelDetail.schDetailOogDoorOpen.trim();
    snapCode +=
      modelDetail.schDetailOogOH == null
        ? "0"
        : modelDetail.schDetailOogOH.toString();
    snapCode +=
      modelDetail.schDetailOogOWL == null
        ? "0"
        : modelDetail.schDetailOogOWL.toString();
    snapCode +=
      modelDetail.schDetailOogOWR == null
        ? "0"
        : modelDetail.schDetailOogOWR.toString();
    snapCode +=
      modelDetail.schDetailOogOLF == null
        ? "0"
        : modelDetail.schDetailOogOLF.toString();
    snapCode +=
      modelDetail.schDetailOogOLB == null
        ? "0"
        : modelDetail.schDetailOogOLB.toString();
    snapCode +=
      modelDetail.schDetailOogUcH == null
        ? "0"
        : modelDetail.schDetailOogUcH.toString();
    snapCode +=
      modelDetail.schDetailOogUcL == null
        ? "0"
        : modelDetail.schDetailOogUcL.toString();
    snapCode +=
      modelDetail.schDetailOogUcW == null
        ? "0"
        : modelDetail.schDetailOogUcW.toString();

    if (target == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: SurchargeDetail = rowNode.data;

          if (modelDetail.schDetailSeqNo != element.schDetailSeqNo) {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.schDetailPC.trim();
            snapCodeComparer += element.schDetailChargeCode.trim();
            //snapCodeComparer += element.schDetailCurrency.trim();
            //snapCodeComparer += element.schDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.schDetailPayer.trim();
            snapCodeComparer +=
              element.schDetailKillSlot == null
                ? "0"
                : element.schDetailKillSlot.toString();
            snapCodeComparer += element.schDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.schDetailOogOH == null
                ? "0"
                : element.schDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOWL == null
                ? "0"
                : element.schDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOWR == null
                ? "0"
                : element.schDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOLF == null
                ? "0"
                : element.schDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOLB == null
                ? "0"
                : element.schDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcH == null
                ? "0"
                : element.schDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcL == null
                ? "0"
                : element.schDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcW == null
                ? "0"
                : element.schDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-schDuplicateChargeCode']="Duplicate " + element.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: SurchargeDetail = rowNode.data;

          if (modelDetail.schDetailSeqNo != element.schDetailSeqNo) {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.schDetailPC.trim();
            snapCodeComparer += element.schDetailChargeCode.trim();
            //snapCodeComparer += element.schDetailCurrency.trim();
            //snapCodeComparer += element.schDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.schDetailPayer.trim();
            snapCodeComparer +=
              element.schDetailKillSlot == null
                ? "0"
                : element.schDetailKillSlot.toString();
            snapCodeComparer += element.schDetailOogDoorOpen.trim();
            snapCodeComparer +=
              element.schDetailOogOH == null
                ? "0"
                : element.schDetailOogOH.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOWL == null
                ? "0"
                : element.schDetailOogOWL.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOWR == null
                ? "0"
                : element.schDetailOogOWR.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOLF == null
                ? "0"
                : element.schDetailOogOLF.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogOLB == null
                ? "0"
                : element.schDetailOogOLB.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcH == null
                ? "0"
                : element.schDetailOogUcH.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcL == null
                ? "0"
                : element.schDetailOogUcL.toLocaleString();
            snapCodeComparer +=
              element.schDetailOogUcW == null
                ? "0"
                : element.schDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-schDuplicateChargeCode']="Duplicate " + element.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
            }
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    return isDuplicateCodeDetected;
  }

  checkDuplicateCopy(target: string, modelDetail: SurchargeDetail): boolean {
    // console.log("** check duplicate for copy **");
    let isDuplicateCodeDetected = false;
    let snapCode: string = "";
    //snapCode += modelDetail.schDetailPC.trim();
    snapCode += modelDetail.schDetailChargeCode.trim();
    //snapCode += modelDetail.schDetailCurrency.trim();
    //snapCode += modelDetail.schDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.schDetailPayer.trim();
    snapCode +=
      modelDetail.schDetailKillSlot == null
        ? "0"
        : modelDetail.schDetailKillSlot.toString();
    snapCode += modelDetail.schDetailOogDoorOpen.trim();
    snapCode +=
      modelDetail.schDetailOogOH == null
        ? "0"
        : modelDetail.schDetailOogOH.toString();
    snapCode +=
      modelDetail.schDetailOogOWL == null
        ? "0"
        : modelDetail.schDetailOogOWL.toString();
    snapCode +=
      modelDetail.schDetailOogOWR == null
        ? "0"
        : modelDetail.schDetailOogOWR.toString();
    snapCode +=
      modelDetail.schDetailOogOLF == null
        ? "0"
        : modelDetail.schDetailOogOLF.toString();
    snapCode +=
      modelDetail.schDetailOogOLB == null
        ? "0"
        : modelDetail.schDetailOogOLB.toString();
    snapCode +=
      modelDetail.schDetailOogUcH == null
        ? "0"
        : modelDetail.schDetailOogUcH.toString();
    snapCode +=
      modelDetail.schDetailOogUcL == null
        ? "0"
        : modelDetail.schDetailOogUcL.toString();
    snapCode +=
      modelDetail.schDetailOogUcW == null
        ? "0"
        : modelDetail.schDetailOogUcW.toString();

    if (target == "prepaid") {
      var rowData = [];
      this.gridApiPrepaid.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: SurchargeDetail = rowNode.data;

          let snapCodeComparer: string = "";
          //snapCodeComparer += element.schDetailPC.trim();
          snapCodeComparer += element.schDetailChargeCode.trim();
          //snapCodeComparer += element.schDetailCurrency.trim();
          //snapCodeComparer += element.schDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.schDetailPayer.trim();
          snapCodeComparer +=
            element.schDetailKillSlot == null
              ? "0"
              : element.schDetailKillSlot.toString();
          snapCodeComparer += element.schDetailOogDoorOpen.trim();
          snapCodeComparer +=
            element.schDetailOogOH == null
              ? "0"
              : element.schDetailOogOH.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOWL == null
              ? "0"
              : element.schDetailOogOWL.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOWR == null
              ? "0"
              : element.schDetailOogOWR.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOLF == null
              ? "0"
              : element.schDetailOogOLF.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOLB == null
              ? "0"
              : element.schDetailOogOLB.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcH == null
              ? "0"
              : element.schDetailOogUcH.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcL == null
              ? "0"
              : element.schDetailOogUcL.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcW == null
              ? "0"
              : element.schDetailOogUcW.toLocaleString();

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;
            //this.isErrorDetail = true;
            //this.modelDetail['error-schDuplicateChargeCode']="Duplicate " + element.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      var rowData = [];
      this.gridApiCollect.forEachNode(function (node) {
        rowData.push(node.data);
      });

      if (rowData.length > 0) {
        this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
          rowNode,
          index
        ) {
          var element: SurchargeDetail = rowNode.data;

          let snapCodeComparer: string = "";
          //snapCodeComparer += element.schDetailPC.trim();
          snapCodeComparer += element.schDetailChargeCode.trim();
          //snapCodeComparer += element.schDetailCurrency.trim();
          //snapCodeComparer += element.schDetailPlaceOfPayment.trim();
          //snapCodeComparer += element.schDetailPayer.trim();
          snapCodeComparer +=
            element.schDetailKillSlot == null
              ? "0"
              : element.schDetailKillSlot.toString();
          snapCodeComparer += element.schDetailOogDoorOpen.trim();
          snapCodeComparer +=
            element.schDetailOogOH == null
              ? "0"
              : element.schDetailOogOH.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOWL == null
              ? "0"
              : element.schDetailOogOWL.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOWR == null
              ? "0"
              : element.schDetailOogOWR.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOLF == null
              ? "0"
              : element.schDetailOogOLF.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogOLB == null
              ? "0"
              : element.schDetailOogOLB.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcH == null
              ? "0"
              : element.schDetailOogUcH.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcL == null
              ? "0"
              : element.schDetailOogUcL.toLocaleString();
          snapCodeComparer +=
            element.schDetailOogUcW == null
              ? "0"
              : element.schDetailOogUcW.toLocaleString();

          // console.log("snapCodeComparer => ")
          // console.log(snapCodeComparer)
          // console.log("snapCode => ")
          // console.log(snapCode)

          if (snapCodeComparer == snapCode) {
            isDuplicateCodeDetected = true;

            //this.isErrorDetail = true;
            //this.modelDetail['error-schDuplicateChargeCode']="Duplicate " + element.schDetailChargeCode.toUpperCase().trim() + " charge code detected."
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    return isDuplicateCodeDetected;
  }

  setValidatorSave() {
    this.clearValidatorError();
    this.validatorRules = {
      schPlaceOfReceiptCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Receipt.",
          },
        ],
      },
      schLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Load.",
          },
        ],
      },
      schDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Discharge.",
          },
        ],
      },
      schPlaceOfDeliveryCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Delivery.",
          },
        ],
      },
      schReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Receipt Type.",
          },
        ],
      },
      schDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Delivery Type.",
          },
        ],
      },
      schEffectiveFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Effective From.",
          },
        ],
      },
      schEffectiveTo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Expiry On.",
          },
        ],
      },
      schAcctPic: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Acct PIC.",
          },
        ],
      },
      effectiveDateExceedValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Effective period cannot exceed a year.",
          },
        ],
      },
      expiryDateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Expiry On Date must be later than Effective From.",
          },
        ],
      },
      samePORValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Place Of Receipt should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePOLValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Load should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePODValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Port of Discharge should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      samePODLValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Place of Delivery should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      sameFDestValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Final Destination should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).",
          },
        ],
      },
      blankFreightChargesValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Blank Freight Charge detected.",
          },
        ],
      },
      blankDetentionRequestValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input detention.",
          },
        ],
      },
      blankDemurrageRequestValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input demurrage.",
          },
        ],
      },
      blankAdhocVoyageValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input AdHoc Voyage.",
          },
        ],
      },
      checkDuplicateValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Duplicate data detected.",
          },
        ],
      },
    };
  }

  checkEffectiveDateExceedValidator(): Boolean {
    let result: Boolean = false;
    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var diffDays = Math.floor(
      Math.abs(
        (new Date(this.model.schEffectiveTo).getTime() -
          new Date(this.model.schEffectiveFrom).getTime()) /
          oneDay
      )
    );
    if (diffDays > 366) {
      return true;
    }
    return result;
  }

  checkExpiryDateValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.schEffectiveTo != "") {
      if (this.model.schEffectiveTo <= this.model.schEffectiveFrom) {
        this.model["error-expiryDateValidator"] =
          "Expiry On Date (" +
          this.model.schEffectiveTo.toString() +
          ") must be later than Effective From.";
        return true;
      }
    }

    return result;
  }

  checkSamePORValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schDischargePortCode != ""
    ) {
      if (this.model.schPlaceOfReceiptCode == this.model.schDischargePortCode) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Discharge.";
        return true;
      }
    }

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schPlaceOfDeliveryCode != ""
    ) {
      if (
        this.model.schPlaceOfReceiptCode == this.model.schPlaceOfDeliveryCode
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Place Of Delivery.";
        return true;
      }
    }

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schFinalDestinationCode != ""
    ) {
      if (
        this.model.schPlaceOfReceiptCode == this.model.schFinalDestinationCode
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Final Destination.";
        return true;
      }
    }

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.schPlaceOfReceiptCode ==
        this.model.schPortOfTransshipment1Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.schPlaceOfReceiptCode ==
        this.model.schPortOfTransshipment2Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.schPlaceOfReceiptCode != "" &&
      this.model.schPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.schPlaceOfReceiptCode ==
        this.model.schPortOfTransshipment3Code
      ) {
        this.model["error-samePORValidator"] =
          "Place Of Receipt should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePOLValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schDischargePortCode != ""
    ) {
      if (this.model.schLoadPortCode == this.model.schDischargePortCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Discharge.";
        return true;
      }
    }

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schPlaceOfDeliveryCode != ""
    ) {
      if (this.model.schLoadPortCode == this.model.schPlaceOfDeliveryCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Place Of Delivery.";
        return true;
      }
    }

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schFinalDestinationCode != ""
    ) {
      if (this.model.schLoadPortCode == this.model.schFinalDestinationCode) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Final Destination.";
        return true;
      }
    }

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.schLoadPortCode == this.model.schPortOfTransshipment1Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.schLoadPortCode == this.model.schPortOfTransshipment2Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.schLoadPortCode != "" &&
      this.model.schPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.schLoadPortCode == this.model.schPortOfTransshipment3Code
      ) {
        this.model["error-samePOLValidator"] =
          "Port Of Load should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePODValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.schDischargePortCode != "" &&
      this.model.schPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.schDischargePortCode ==
        this.model.schPortOfTransshipment1Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.schDischargePortCode != "" &&
      this.model.schPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.schDischargePortCode ==
        this.model.schPortOfTransshipment2Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.schDischargePortCode != "" &&
      this.model.schPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.schDischargePortCode ==
        this.model.schPortOfTransshipment3Code
      ) {
        this.model["error-samePODValidator"] =
          "Port Of Discharge should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSamePODLValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.schPlaceOfDeliveryCode != "" &&
      this.model.schPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.schPlaceOfDeliveryCode ==
        this.model.schPortOfTransshipment1Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.schPlaceOfDeliveryCode != "" &&
      this.model.schPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.schPlaceOfDeliveryCode ==
        this.model.schPortOfTransshipment2Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.schPlaceOfDeliveryCode != "" &&
      this.model.schPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.schPlaceOfDeliveryCode ==
        this.model.schPortOfTransshipment3Code
      ) {
        this.model["error-samePODLValidator"] =
          "Place of Delivery should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkSameFDestValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.model.schFinalDestinationCode != "" &&
      this.model.schPortOfTransshipment1Code != ""
    ) {
      if (
        this.model.schFinalDestinationCode ==
        this.model.schPortOfTransshipment1Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(1).";
        return true;
      }
    }

    if (
      this.model.schFinalDestinationCode != "" &&
      this.model.schPortOfTransshipment2Code != ""
    ) {
      if (
        this.model.schFinalDestinationCode ==
        this.model.schPortOfTransshipment2Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(2).";
        return true;
      }
    }

    if (
      this.model.schFinalDestinationCode != "" &&
      this.model.schPortOfTransshipment3Code != ""
    ) {
      if (
        this.model.schFinalDestinationCode ==
        this.model.schPortOfTransshipment3Code
      ) {
        this.model["error-sameFDestValidator"] =
          "Final Destination should not the same with Port Of Transhipment(3).";
        return true;
      }
    }

    return result;
  }

  checkBlankFreightChargesValidator(): Boolean {
    // console.log("** check blank freight charges **");
    // console.log(this.map.get(this.model.schContainerType))

    let result: Boolean = false;
    if (
      this.map.get(this.model.schContainerType) == null ||
      this.map.get(this.model.schContainerType) == undefined
    ) {
      // console.log("#check 1");

      var ct: string;
      switch (this.model.schContainerType) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        default:
          ct = "NORMAL";
          break;
      }

      this.model["error-blankFreightChargesValidator"] =
        "Blank Freight Charge detected. Please input at least one record for Container Type " +
        ct;
      result = true;
    } else {
      // console.log("#check 2");
      let dets: SurchargeDetail[] = this.map.get(this.model.schContainerType);
      if (dets.length == 0) {
        var ct: string;
        switch (this.model.schContainerType) {
          case "TNK":
            ct = "TANK";
            break;
          case "OPT":
            ct = "OPEN TOP";
            break;
          case "FLR":
            ct = "FLAT RACK";
            break;
          case "PLT":
            ct = "PLATFORM";
            break;
          case "REF":
            ct = "REEFER";
            break;
          case "DRY":
            ct = "REEFER DRY";
            break;
          case "UC":
            ct = "UC";
            break;
          default:
            ct = "NORMAL";
            break;
        }

        this.model["error-blankFreightChargesValidator"] =
          "Blank Freight Charge detected. Please input at least one record for Container Type " +
          ct;
        result = true;
      }
    }
    return result;
  }

  checkMissingMainChargesValidator(): Boolean {
    let result: Boolean = false;
    let ctList: any[] = [];
    let mapCTTab = new Map();
    this.map.forEach((value: SurchargeDetail[], key: string) => {
      value.forEach((element) => {
        if (
          mapCTTab.get(
            element.schDetailContainerType + "-" + element.schDetailPCTab
          ) == undefined
        ) {
          let datas: SurchargeDetail[] = [];
          datas.push(element);
          mapCTTab.set(
            element.schDetailContainerType + "-" + element.schDetailPCTab,
            datas
          );
        } else {
          mapCTTab
            .get(element.schDetailContainerType + "-" + element.schDetailPCTab)
            .push(element);
        }
      });
    });

    // console.log(mapCTTab)

    mapCTTab.forEach((value: SurchargeDetail[], key: string) => {
      // console.log('Key -> ' + key);
      ctList.push(key);
      value.forEach((element) => {
        if (
          element.schDetailChargeCode.trim() == "OFT" ||
          element.schDetailChargeCode.trim() == "FAI" ||
          element.schDetailChargeCode.trim() == "FOF"
        ) {
          var eek = ctList.indexOf(key);
          ctList.splice(eek, 1);
        } else {
        }
      });
    });

    // console.log(ctList);
    if (ctList.length > 0) {
      var strCtList: string = ctList.toString();

      strCtList = strCtList.replace(/TNK/g, "TANK");
      strCtList = strCtList.replace(/OPT/g, "OPEN TOP");
      strCtList = strCtList.replace(/FLR/g, "FLAT RACK");
      strCtList = strCtList.replace(/PLT/g, "PLATFORM");
      strCtList = strCtList.replace(/REF/g, "REEFER");
      strCtList = strCtList.replace(/STD/g, "NORMAL");
      strCtList = strCtList.replace(/UC/g, "UC");
      strCtList = strCtList.replace(/-P/g, " [PREPAID]");
      strCtList = strCtList.replace(/-C/g, " [COLLECT]");

      // console.log(strCtList);
      this.model["error-missingMainChargesValidator"] =
        "Missing Main Charge Detected on Container Type " +
        strCtList +
        "! Please check your charges.";
      result = true;
    }

    return result;
  }

  checkBlankDetentionRequestValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.schDetention == "R" && this.model.schDetentionRequest == 0) {
      result = true;
    }
    return result;
  }

  checkBlankDemurrageRequestValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.schDemurrage == "R" && this.model.schDemurrageRequest == 0) {
      result = true;
    }
    return result;
  }

  checkBlankAdhocVoyageValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.schVesselId != "" && this.model.schVoyage == "") {
      result = true;
    }
    return result;
  }

  checkChargesValueValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.modelDetail.schDetailD20 == 0 &&
      this.modelDetail.schDetailD40 == 0 &&
      this.modelDetail.schDetailD45 == 0 &&
      this.modelDetail.schDetailH20 == 0 &&
      this.modelDetail.schDetailH40 == 0 &&
      this.modelDetail.schDetailH45 == 0
    ) {
      if (this.modelDetail.schDetailChargeCode != "FOF") {
        this.modelDetail["error-chargesValueValidator"] =
          "Please input charges value.";
        result = true;
      }
    }

    return result;
  }

  checkMainChargesValidator(): Boolean {
    let result: Boolean = false;

    if (
      this.modelDetail.schDetailChargeCode == "FAI" &&
      this.modelDetail.schDetailD20 == 0 &&
      this.modelDetail.schDetailD40 == 0 &&
      this.modelDetail.schDetailD45 == 0 &&
      this.modelDetail.schDetailH20 == 0 &&
      this.modelDetail.schDetailH40 == 0 &&
      this.modelDetail.schDetailH45 == 0
    ) {
      // console.log("#1");
      this.modelDetail["error-mainChargesValidator"] =
        "FAI charge code cannot be 0 value";
      return true;
    }

    if (
      this.modelDetail.schDetailChargeCode == "OFT" &&
      this.modelDetail.schDetailD20 == 0 &&
      this.modelDetail.schDetailD40 == 0 &&
      this.modelDetail.schDetailD45 == 0 &&
      this.modelDetail.schDetailH20 == 0 &&
      this.modelDetail.schDetailH40 == 0 &&
      this.modelDetail.schDetailH45 == 0
    ) {
      // console.log("#2");
      this.modelDetail["error-mainChargesValidator"] =
        "OFT charge code cannot be 0 value";
      return true;
    }

    if (
      this.modelDetail.schDetailChargeCode == "FOF" &&
      (this.modelDetail.schDetailD20 != 0 ||
        this.modelDetail.schDetailD40 != 0 ||
        this.modelDetail.schDetailD45 != 0 ||
        this.modelDetail.schDetailH20 != 0 ||
        this.modelDetail.schDetailH40 != 0 ||
        this.modelDetail.schDetailH45 != 0)
    ) {
      // console.log("#3");
      this.modelDetail["error-mainChargesValidator"] =
        "FOF charge code must set 0 value";
      return true;
    }

    // console.log(result);
    return result;
  }

  clearValidatorError() {
    this.model["error-schPlaceOfReceiptCode"] = "";
    this.model["error-schLoadPortCode"] = "";
    this.model["error-schDischargePortCode"] = "";
    this.model["error-schPlaceOfDeliveryCode"] = "";
    this.model["error-schReceiptTypeCode"] = "";
    this.model["error-schDeliveryTypeCode"] = "";
    this.model["error-schEffectiveFrom"] = "";
    this.model["error-schEffectiveTo"] = "";
    this.model["error-schAcctPic"] = "";
    this.model["error-effectiveDateExceedValidator"] = "";
    this.model["error-expiryDateValidator"] = "";
    this.model["error-samePORValidator"] = "";
    this.model["error-samePOLValidator"] = "";
    this.model["error-samePODValidator"] = "";
    this.model["error-samePODLValidator"] = "";
    this.model["error-sameFDestValidator"] = "";
    this.model["error-blankFreightChargesValidator"] = "";
    //this.model['error-missingMainChargesValidator'] = '';
    this.model["error-blankDetentionRequestValidator"] = "";
    this.model["error-blankDemurrageRequestValidator"] = "";
    this.model["error-blankAdhocVoyageValidator"] = "";
    this.model["error-checkDuplicateValidator"] = "";
  }

  setValidatorAddNewCharge() {
    this.clearValidatorErrorDetail();
    this.validatorRules = {
      schDetailChargeCode: {
        rules: [{ type: "empty", prompt: "Please input Charge." }],
      },
      schDetailCurrency: {
        rules: [{ type: "empty", prompt: "Please input Currency." }],
      },
      schDetailPC: { rules: [{ type: "empty", prompt: "Please input P/C." }] },
      schDetailPlaceOfPayment: {
        rules: [{ type: "empty", prompt: "Please input Place of Payment." }],
      },
      chargesValueValidator: {
        rules: [{ type: "custom", prompt: "Please input charges value." }],
      },
      //    mainChargesValidator: {rules: [{type: 'custom', prompt: 'Main Charges problem detected.'}]},
    };
  }

  clearValidatorErrorDetail() {
    this.modelDetail["error-schDetailChargeCode"] = "";
    this.modelDetail["error-schDetailCurrency"] = "";
    this.modelDetail["error-schDetailPC"] = "";
    this.modelDetail["error-schDetailPlaceOfPayment"] = "";
    this.modelDetail["error-chargesValueValidator"] = "";
    this.modelDetail["error-schDuplicateChargeCode"] = "";
  }

  getEtaEtdAtaAtd(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getSailSchedule/" +
          vesselId +
          "/" +
          voyage +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe(
        (resp) => {
          let data = resp.json();
          this.model.schLocalEta = String(data["ETA"])
            .trim()
            .replace(/\//gi, "-");
          this.model.schLocalEtd = String(data["ETD"]).replace(/\//g, "-");
          this.model.schLocalAta = String(data["ATA"]).replace(/\//g, "-");
          this.model.schLocalAtd = String(data["ATD"]).replace(/\//g, "-");

          // GOS-200 & 201, Value set blank;
          this.model.schLocalEta = "";
          this.model.schLocalEtd = "";
          this.model.schLocalAta = "";
          this.model.schLocalAtd = "";
        },
        (error) => {
          // console.log(error.message);
        }
      );
  }

  //Action when tab prepaid / tab collect is selected

  onTabPrepaid() {
    //this.currentActiveTab = "prepaid";
    //this.gridPrepaid.loadData();
    var params = {
      force: true,
    };
    this.gridApiPrepaid.refreshCells(params);
  }

  onTabCollect() {
    //this.currentActiveTab = "collect";
    //this.gridCollect.loadData();
    var params = {
      force: true,
    };
    this.gridApiCollect.refreshCells(params);
  }

  gridEventPrepaid(event) {
    // console.log("Click Event " + event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var no = event.split(".")[1].split("-")[1];

        //this.gridPrepaid.clearSelectedValues();
        //this.gridPrepaid.onSelect(true, Number.parseInt(no));

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.onDetailUpdate();
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    // console.log("no " + value);
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach((ff) => {
        if (ff[key] != value) {
          ff["select"] = "";
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
    // console.log("total checked " + store.getSelectedValues().length);
  }

  changeFreightParty(event) {
    if (
      event["customerId"] != null &&
      event["customerId"] != "" &&
      event["customerId"] !== undefined
    ) {
      this.model.schFreightPartyId = event["customerId"].toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";
      this.searchFpIsFreightParty = event["isFreightParty"];
      this.searchFpIsValid = event["isValid"];
    } else {
      this.model.schFreightPartyId = "";
      this.fpAcctCode = "";
      this.searchFpIsFreightParty = "";
      this.searchFpIsValid = "";
    }

    //revert the search url to use name
    // this.cbFreightParty.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
    //     "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}",
    // );

    this.cbFreightParty.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerName={query}"
        "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerName={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E",
    );
  }


  changeCbBookingParty(event) {
    if (
      event["customerId"] != null &&
      event["customerId"] != "" &&
      event["customerId"] !== undefined
    ) {
      this.model.schBookingPartyId = event["customerId"].toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";
      this.searchBkIsBookingParty = event["isBookingParty"];
      this.searchBkIsValid = event["isValid"];
    } else {
      this.model.schBookingPartyId = "";
      this.fpAcctCode = "";
      this.searchBkIsBookingParty = "";
      this.searchBkIsValid = "";
    }

    //revert the search url to use name
    this.cbBookingParty.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
        "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerName={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E",
    );
  }

  changeCbShipper(event) {
    if (
      event["customerId"] != null &&
      event["customerId"] != "" &&
      event["customerId"] !== undefined
    ) {
      this.model.schShipperId = event["customerId"].toUpperCase();
      this.fpAcctCode = "<<";
      if (event["cocAccountCode"] !== "") {
        this.fpAcctCode += " COC: " + event["cocAccountCode"];
      }
      if (event["socAccountCode"] !== "") {
        this.fpAcctCode += " SOC: " + event["socAccountCode"];
      }
      if (event["slotAccountCode"] !== "") {
        this.fpAcctCode += " SLOT: " + event["slotAccountCode"];
      }
      this.fpAcctCode += " >>";
      this.searchShIsShipper = event["isShipper"];
      this.searchShIsValid = event["isValid"];
    } else {
      this.model.schShipperId = "";
      this.fpAcctCode = "";
      this.searchShIsShipper = "";
      this.searchShIsValid = "";
    }

    //revert the search url to use name
    this.cbShipper.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
        "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerName={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E",
    );
  }

  changeDGClass(event) {
    if (
      event["dgClassCode"] != null &&
      event["dgClassCode"] != "" &&
      event["dgClassCode"] !== undefined
    ) {
      this.model.schDgClass = event["dgClassCode"].toUpperCase();
    } else {
      this.model.schDgClass = "ALL";
    }
  }

  changeContainerStatus(event) {
    if (
      event["ctrStatusCode"] != null &&
      event["ctrStatusCode"] != "" &&
      event["ctrStatusCode"] !== undefined
    ) {
      this.model.schContainerStatus = event["ctrStatusCode"].toUpperCase();
    } else {
      this.model.schContainerStatus = "ALL";
    }
  }

  changeValidInvalid(event) {
    // console.log("** change valid invalid **")
    // console.log(event);
    if (
      event["isValidCode"] != null &&
      event["isValidCode"] != "" &&
      event["isValidCode"] !== undefined
    ) {
      this.model.schIsValid = event["isValidCode"].toUpperCase();
    } else {
      this.model.schIsValid = "ALL";
    }
  }

  changeContainerOwnership(event) {
    if (
      event["ctrOwnerCode"] != null &&
      event["ctrOwnerCode"] != "" &&
      event["ctrOwnerCode"] !== undefined
    ) {
      this.model.schContainerOwnership = event["ctrOwnerCode"].toUpperCase();
    } else {
      this.model.schContainerOwnership = "ALL";
    }
  }

  changeContainerType(event) {
    if (
      event["containerTypeCode"] != null &&
      event["containerTypeCode"] != "" &&
      event["containerTypeCode"] !== undefined
    ) {
      this.model.schContainerType = event["containerTypeCode"].toUpperCase();
    } else {
      this.model.schContainerType = "ALL";
    }
  }

  changeReceiptType(event) {
    if (
      event["receiptTypeCode"] != null &&
      event["receiptTypeCode"] != "" &&
      event["receiptTypeCode"] !== undefined
    ) {
      this.model.schReceiptTypeCode = event["receiptTypeCode"].toUpperCase();
    } else {
      this.model.schReceiptTypeCode = "ALL";
    }
  }

  changeDeliveryType(event) {
    if (
      event["deliveryTypeCode"] != null &&
      event["deliveryTypeCode"] != "" &&
      event["deliveryTypeCode"] !== undefined
    ) {
      this.model.schDeliveryTypeCode = event["deliveryTypeCode"].toUpperCase();
    } else {
      this.model.schDeliveryTypeCode = "ALL";
    }
  }

  /*
  changeCbMaintenanceCommodity(event){
      if (event["commodityId"] != null && event["commodityId"] != "" && event["commodityId"] !== undefined) {
        this.model.schCommodityId = event["commodityId"].toUpperCase();
      } else {
        this.model.schCommodityId = "";
      }

      //revert to search by name
      this.cbMaintenanceCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}');
  }
  */

  infoGridPrepaid(event) {}

  infoGridCollect(event) {}

  gridEventCollect(event) {
    // console.log("Click Event " + event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var no = event.split(".")[1].split("-")[1];

        //this.gridCollect.clearSelectedValues();
        //this.gridCollect.onSelect(true, Number.parseInt(no));

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.onDetailUpdate();
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  changeEventRetrieveByRefNo(event) {
    if (event.target.checked) {
      this.checkLock = true;
      this.model.schSerchByRefNo = "Y";
      this.lockEffectiveFrom = true;
      this.lockEffectiveTo = true;

      this.cookieService.setCookie(
        "schSearchByRefNo",
        this.model.schSerchByRefNo
      );
    } else {
      this.checkLock = false;
      this.model.schSerchByRefNo = "N";
      this.model.schSurchargeId = "";
      this.lockEffectiveFrom = false;
      this.lockEffectiveTo = false;

      this.cookieService.setCookie(
        "schSearchByRefNo",
        this.model.schSerchByRefNo
      );
    }
  }

  changeIsDeleted(event) {
    if (event.target.checked) {
      this.model.schIsDeleted = "Y";
    } else {
      this.model.schIsDeleted = "N";
    }
  }

  changeIsCommissionable(event) {
    if (event.target.checked) {
      this.model.schIsCommission = "Y";
    } else {
      this.model.schIsCommission = "N";
    }
  }

  changeEventPfcIsCommission(event) {
    if (event.target.checked) {
      this.model.schIsCommission = "Y";
    } else {
      this.model.schIsCommission = "N";
    }
  }

  handleDoubleClick(dt: SurchargeMaster) {
    // console.log("** handle Double Click **");
    // console.log('dt => ');
    // console.log(dt);

    if (this.modeForm == "surchargeFlagStatus") {
      this.modeForm = "surchargeMaintenance";
      this.pfcToolbar.label = "Surcharge Application";
      this.invisibleToolbarButtons = "retrieve,create";
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons =
          "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
      } else {
        this.disableToolbarButtons = "save";
      }
      this.lockHeader = true;

      //store buffer id on cookies
      this.cookieService.setCookie(
        "schSearchParam",
        JSON.stringify(this.model)
      );
      //end store buffer cookies

      //re-init the model to reset any unwanted values
      this.model = new SurchargeMaster();

      //reset all combo and date picker
      this.cbMaintenanceFreightParty.setValue("");
      this.cbMaintenanceBookingParty.setValue("");
      this.cbMaintenanceShipper.setValue("");
      this.cbMaintenanceShipper.setValue("");
      this.cbMaintenancePlaceOfReceipt.setValue("");
      this.cbMaintenancePortOfLoad.setValue("");
      this.cbMaintenancePortOfDischarge.setValue("");
      this.cbMaintenancePlaceOfDelivery.setValue("");
      this.cbMaintenancePOT1.setValue("");
      this.cbMaintenancePOT2.setValue("");
      this.cbMaintenancePOT3.setValue("");
      this.cbMaintenanceFinalDestination.setValue("");
      //this.cbMaintenanceCommodity.setValue("");
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");
      this.dateMaintenanceEffectiveTo.setValue("");

      //reset effective to
      let currentDate = new Date();
      let theDate =
        this.genericUtil.pad2(currentDate.getFullYear()) +
        "-" +
        this.genericUtil.pad2(currentDate.getMonth() + 1) +
        "-" +
        this.genericUtil.pad2(currentDate.getDate());
      this.dateMaintenanceEffectiveFrom.setValue(theDate);

      // console.log("** 0. Check Record Id **");
      if (
        dt.schSurchargeId !== null &&
        dt.schSurchargeId !== "" &&
        dt.schSurchargeId !== undefined
      ) {
        this.model.schSurchargeId = dt.schSurchargeId;
      }

      // console.log("** 1. Check Freight Party **");
      if (
        dt.schFreightPartyId !== null &&
        dt.schFreightPartyId !== "" &&
        dt.schFreightPartyId !== undefined
      ) {
        // this.cbMaintenanceFreightParty.setUrl(
        //   this.configService.config.BASE_API.toString() +
        //     // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
        //     "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerId={query}"
        // );
        // this.cbMaintenanceFreightParty.setValue(dt.schFreightPartyId);
        this.cbMaintenanceFreightParty.setUrl(
          this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightPartyWithCustomerType/customerId={query}"
            "/MasterSurcharges/findByComboBoxControlGeneral/freight_party/customerId={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"+
            this.sCharUtil.htmlEncode("|")+
            "recordId="+dt.schSurchargeId,
        );
        this.cbMaintenanceFreightParty.setValue(dt.schFreightPartyId);
      }

      // console.log("** 2. Check Place of Receipt **");
      if (
        dt.schPlaceOfReceiptCode !== null &&
        dt.schPlaceOfReceiptCode !== "" &&
        dt.schPlaceOfReceiptCode !== undefined
      ) {
        this.cbMaintenancePlaceOfReceipt.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfReceipt.setValue(dt.schPlaceOfReceiptCode, ()=>{
          this.cbMaintenancePODTerminal.setForceValue(dt.schPODTerminalName);
          this.model.schPODTerminalCode = dt.schPODTerminalCode;

          this.cbMaintenancePORTerminal.setForceValue(dt.schPORTerminalName);
          this.model.schPORTerminalCode = dt.schPORTerminalCode;
        });
      }

      // console.log("** 3. Check Port of Loading **");
      if (
        dt.schLoadPortCode !== null &&
        dt.schLoadPortCode !== "" &&
        dt.schLoadPortCode !== undefined
      ) {
        this.cbMaintenancePortOfLoad.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfLoad.setValue(dt.schLoadPortCode, ()=>{
          this.cbMaintenancePOLTerminal.setForceValue(dt.schPOLTerminalName);
          this.model.schPOLTerminalCode =  dt.schPOLTerminalCode;
        });
      }

      // console.log("** 4. Check Port of Discharge **");
      if (
        dt.schDischargePortCode !== null &&
        dt.schDischargePortCode !== "" &&
        dt.schDischargePortCode !== undefined
      ) {
        this.cbMaintenancePortOfDischarge.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePortOfDischarge.setValue(dt.schDischargePortCode, ()=>{
          this.cbMaintenancePODTerminal.setForceValue(dt.schPODTerminalName);
          this.model.schPODTerminalCode = dt.schPODTerminalCode;

          this.cbMaintenancePODLTerminal.setForceValue(dt.schPODLTerminalName);
          this.model.schPODLTerminalCode = dt.schPODLTerminalCode;
        });
      }
      //5. Check Place of Delivery
      if (
        dt.schPlaceOfDeliveryCode !== null &&
        dt.schPlaceOfDeliveryCode !== "" &&
        dt.schPlaceOfDeliveryCode !== undefined
      ) {
        this.cbMaintenancePlaceOfDelivery.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenancePlaceOfDelivery.setValue(dt.schPlaceOfDeliveryCode, ()=>{
          this.cbMaintenancePODLTerminal.setForceValue(dt.schPODLTerminalName);
          this.model.schPODLTerminalCode = dt.schPODLTerminalCode;
        });
      }
      //6. Check Port of Transshipment 1
      if (
        dt.schPortOfTransshipment1Code !== null &&
        dt.schPortOfTransshipment1Code !== "" &&
        dt.schPortOfTransshipment1Code !== undefined
      ) {
        this.cbMaintenancePOT1.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT1.setValue(dt.schPortOfTransshipment1Code, ()=>{
          this.cbMaintenancePOT1Terminal.setForceValue(dt.schPOT1TerminalName);
          this.model.schPOT1TerminalCode = dt.schPOT1TerminalCode;
        });
      }

      //Check Port of Transshipment 2
      if (
        dt.schPortOfTransshipment2Code !== null &&
        dt.schPortOfTransshipment2Code !== "" &&
        dt.schPortOfTransshipment2Code !== undefined
      ) {
        this.cbMaintenancePOT2.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT2.setValue(dt.schPortOfTransshipment2Code);
      }

      //Check Port of Transshipment 3
      if (
        dt.schPortOfTransshipment3Code !== null &&
        dt.schPortOfTransshipment3Code !== "" &&
        dt.schPortOfTransshipment3Code !== undefined
      ) {
        this.cbMaintenancePOT3.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
        );
        this.cbMaintenancePOT3.setValue(dt.schPortOfTransshipment3Code);
      }

      //7. Check Final Destination
      if (
        dt.schFinalDestinationCode !== null &&
        dt.schFinalDestinationCode !== "" &&
        dt.schFinalDestinationCode !== undefined
      ) {
        this.cbMaintenanceFinalDestination.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterLocations/findByComboBoxControl/locationCode={query}"
        );
        this.cbMaintenanceFinalDestination.setValue(dt.schFinalDestinationCode, ()=>{
          this.cbMaintenanceFDESTTerminal.setForceValue(dt.schFDESTTerminalName);
          this.model.schFDESTTerminalCode = dt.schFDESTTerminalCode;
        });
      }
      //8. Check Receipt Type
      // console.log("dt.schReceiptTypeCode --> " + dt.schReceiptTypeCode);
      if (
        dt.schReceiptTypeCode !== null &&
        dt.schReceiptTypeCode !== "" &&
        dt.schReceiptTypeCode !== undefined
      ) {
        this.cbMaintenanceReceiptType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
        );
        this.cbMaintenanceReceiptType.setValue(dt.schReceiptTypeCode);
      }
      //9. Check Delivery Type
      // console.log("dt.schDeliveryTypeCode --> " + dt.schDeliveryTypeCode);
      if (
        dt.schDeliveryTypeCode !== null &&
        dt.schDeliveryTypeCode !== "" &&
        dt.schDeliveryTypeCode !== undefined
      ) {
        this.cbMaintenanceDeliveryType.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
        );
        this.cbMaintenanceDeliveryType.setValue(dt.schDeliveryTypeCode);
      }
      //10. Check DG Class
      if (
        dt.schDgClass !== null &&
        dt.schDgClass !== "" &&
        dt.schDgClass !== undefined
      ) {
        this.model.schDgClass = dt.schDgClass;
      }
      //11. Check Shipment Type
      if (
        dt.schShipmentType !== null &&
        dt.schShipmentType !== "" &&
        dt.schShipmentType !== undefined
      ) {
        this.model.schShipmentType = dt.schShipmentType;
      }
      //12. Check Container Status
      if (
        dt.schContainerStatus !== null &&
        dt.schContainerStatus !== "" &&
        dt.schContainerStatus !== undefined
      ) {
        this.model.schContainerStatus = dt.schContainerStatus;
      }

      //14. Check Adhoc Vessel
      if (
        dt.schVesselId !== null &&
        dt.schVesselId !== "" &&
        dt.schVesselId !== undefined
      ) {
        var self = this;
        this.cbMaintenanceAdhocVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterVessels/findByComboBoxControl/vesselId={query}"
        );
        this.cbMaintenanceAdhocVessel.setValue(
          dt.schVesselId,
          function (callbackVessel) {
            self.model.schVesselId = dt.schVesselId;

            //15. Check Adhoc Voyage
            if (
              dt.schVoyage !== null &&
              dt.schVoyage !== "" &&
              dt.schVoyage !== undefined
            ) {
              //self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ self.model.schVesselId+"/"+ "O" +"/{query}/" + self.cookieService.getDefaultLocationCode());
              self.cbMaintenanceAdhocVoyage.setUrl(
                self.configService.config.BASE_API.toString() +
                  "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
                  dt.schVesselId +
                  "/O/voyage={query}"
              );
              self.cbMaintenanceAdhocVoyage.setValue(
                dt.schVoyage,
                function (callbackVoyage) {
                  self.model.schVoyage = dt.schVoyage;
                  //self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + dt.schVesselId + "/O/voyage_like={query}");
                  self.cbMaintenanceAdhocVoyage.setUrl(
                    self.configService.config.BASE_API.toString() +
                      "/MasterSailingSchedules/distinctVoyage/" +
                      self.model.schVesselId +
                      "/" +
                      "O" +
                      "/{query}/*"
                  );
                  self.getEtaEtdAtaAtd(
                    self.model.schVesselId,
                    self.model.schVoyage,
                    "O",
                    self.cookieService.getDefaultLocationCode()
                  );
                }
              );
            }

            self.cbMaintenanceAdhocVessel.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterVessels/findByComboBoxControl/VesselName={query}"
            );
          }
        );
      }

      //16. Check Container Status
      if (
        dt.schContainerStatus !== null &&
        dt.schContainerStatus !== "" &&
        dt.schContainerStatus !== undefined
      ) {
        this.model.schContainerStatus = dt.schContainerStatus;
      }

      //18. Check Commodity
      /*
        if (dt.schCommodityId !== null && dt.schCommodityId !== "" && dt.schCommodityId !== undefined) {
          this.cbMaintenanceCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityId={query}');
          this.cbMaintenanceCommodity.setValue(dt.schCommodityId);
        }
        */
      // console.log("** 19. Check Container Ownership **");
      if (
        dt.schContainerOwnership !== null &&
        dt.schContainerOwnership !== "" &&
        dt.schContainerOwnership !== undefined
      ) {
        this.model.schContainerOwnership = dt.schContainerOwnership;

        if (
          dt.schContainerOwnership == "COC" ||
          dt.schContainerOwnership == "COCTS"
        ) {
          this.model.schContainerOwnershipPrefix = "C";
        } else if (
          dt.schContainerOwnership == "SOC" ||
          dt.schContainerOwnership == "SOCTS"
        ) {
          this.model.schContainerOwnershipPrefix = "S";
        } else if (dt.schContainerOwnership == "SLOT") {
          this.model.schContainerOwnershipPrefix = "SL";
        }
      }

      //21. Check for Detention
      if (
        dt.schDetentionPOL !== null &&
        dt.schDetentionPOL !== "" &&
        dt.schDetentionPOL !== undefined
      ) {
        this.model.schDetentionPOL = dt.schDetentionPOL;
        if (dt.schDetentionPOL == "R") {
          this.model.schDetentionRequestPOL = dt.schDetentionRequestPOL;
        }
      }

      if (
        dt.schDetention !== null &&
        dt.schDetention !== "" &&
        dt.schDetention !== undefined
      ) {
        this.model.schDetention = dt.schDetention;
        if (dt.schDetention == "R") {
          this.model.schDetentionRequest = dt.schDetentionRequest;
        }
      }

      //22. Check for Demurrage
      if (
        dt.schDemurragePOL !== null &&
        dt.schDemurragePOL !== "" &&
        dt.schDemurragePOL !== undefined
      ) {
        this.model.schDemurragePOL = dt.schDemurragePOL;
        if (dt.schDemurragePOL == "R") {
          this.model.schDemurrageRequestPOL = dt.schDemurrageRequestPOL;
        }
      }

      if (
        dt.schDemurrage !== null &&
        dt.schDemurrage !== "" &&
        dt.schDemurrage !== undefined
      ) {
        this.model.schDemurrage = dt.schDemurrage;
        if (dt.schDemurrage == "R") {
          this.model.schDemurrageRequest = dt.schDemurrageRequest;
        }
      }
    }

    //23. Creation Information
    if (
      dt.schUserCreated !== null &&
      dt.schUserCreated !== "" &&
      dt.schUserCreated !== undefined
    ) {
      this.model.schUserCreated = dt.schUserCreated;
    }
    if (
      dt.schDateCreated !== null &&
      dt.schDateCreated !== "" &&
      dt.schDateCreated !== undefined
    ) {
      this.model.schDateCreated = dt.schDateCreated;
      this.dateCreatedForCbPayer = this.formatDate(dt.schDateCreated);
    }

    //24. Update Information
    if (
      dt.schUserModified !== null &&
      dt.schUserModified !== "" &&
      dt.schUserModified !== undefined
    ) {
      this.model.schUserModified = dt.schUserModified;
    }
    if (
      dt.schDateModified !== null &&
      dt.schDateModified !== "" &&
      dt.schDateModified !== undefined
    ) {
      this.model.schDateModified = dt.schDateModified;
    }

    //24. Check Container Type
    if (
      dt.schContainerType !== null &&
      dt.schContainerType !== "" &&
      dt.schContainerType !== undefined
    ) {
      this.model.schContainerType = dt.schContainerType;
    }

    //25. Check for Effective Date
    if (
      dt.schEffectiveFrom !== null &&
      dt.schEffectiveFrom !== "" &&
      dt.schEffectiveFrom !== undefined
    ) {
      this.dateMaintenanceEffectiveFrom.setValue(dt.schEffectiveFrom);
    }
    if (
      dt.schEffectiveTo !== null &&
      dt.schEffectiveTo !== "" &&
      dt.schEffectiveTo !== undefined
    ) {
      this.dateMaintenanceEffectiveTo.setValue(dt.schEffectiveTo);
      this.lastEffectiveTo = dt.schEffectiveTo;
    }

    this.model.schIsCommission = dt.schIsCommission;
    this.model.schIsDeleted = dt.schIsDeleted;
    this.model.schIsValid = dt.schIsValid;
    this.model.disableInvalid = dt.disableInvalid;
    this.model.disableValid = dt.disableValid;
    this.model.historyList = dt.historyList;
    this.model.schSerchByRefNo = dt.schSerchByRefNo;

    //UserID
    if (
      dt.schUserCreated != null &&
      dt.schUserCreated != "" &&
      dt.schUserCreated !== undefined
    ) {
      this.model.schUserID = dt.schUserCreated;
    }

    //AcctPIC
    // console.log("dt.schAcctPic -> " + dt.schAcctPic);
    if (
      dt.schAcctPic != null &&
      dt.schAcctPic != "" &&
      dt.schAcctPic !== undefined
    ) {
      var self = this;
      this.cbAcctPic.setUrl(
        this.configService.config.BASE_API.toString() +
        `/MasterAccountPic/findAcctPIC/${dt.schAcctPic}`
      );
      this.cbAcctPic.setValue(dt.schAcctPic);
      this.model.schAcctPic = dt.schAcctPic;
    }

    //28. Booking Party
    if (
      dt.schBookingPartyId !== null &&
      dt.schBookingPartyId !== "" &&
      dt.schBookingPartyId !== undefined
    ) {
      console.log("Booking Party Id: "+dt.schBookingPartyId);
      // this.cbMaintenanceBookingParty.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
      //     "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerId={query}"
      // );
      // this.cbMaintenanceBookingParty.setValue(dt.schBookingPartyId);
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerId={query}"+
        this.sCharUtil.htmlEncode("|")+
        "status=E"+
        this.sCharUtil.htmlEncode("|")+
        "recordId="+dt.schSurchargeId,
      );
      this.cbMaintenanceBookingParty.setValue(dt.schBookingPartyId);
    }

     //29. Shipper
     if (
      dt.schShipperId !== null &&
      dt.schShipperId !== "" &&
      dt.schShipperId !== undefined
    ) {
      // this.cbMaintenanceShipper.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     // "/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}"
      //     "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerId={query}"
      // );
      // this.cbMaintenanceShipper.setValue(dt.schShipperId);

      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlShipper/customerId={query}"
          "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerId={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=E"+
          this.sCharUtil.htmlEncode("|")+
          "recordId="+dt.schSurchargeId,
      );
      this.cbMaintenanceShipper.setValue(dt.schShipperId);
    }

    // console.log("** data retrieve **");
    this.model.schSurchargeDetails = dt.schSurchargeDetails;

    let modelKey = new SurchargeMaster();
    modelKey.schOfficeCode = dt.schOfficeCode;
    modelKey.schFreightPartyId = dt.schFreightPartyId;
    modelKey.schPlaceOfReceiptCode = dt.schPlaceOfReceiptCode;
    modelKey.schLoadPortCode = dt.schLoadPortCode;
    modelKey.schDischargePortCode = dt.schDischargePortCode;
    modelKey.schPlaceOfDeliveryCode = dt.schPlaceOfDeliveryCode;
    modelKey.schFinalDestinationCode = dt.schFinalDestinationCode;
    modelKey.schPortOfTransshipment1Code = dt.schPortOfTransshipment1Code;
    modelKey.schPortOfTransshipment2Code = dt.schPortOfTransshipment2Code;
    modelKey.schPortOfTransshipment3Code = dt.schPortOfTransshipment3Code;
    modelKey.schCommodityId = dt.schCommodityId;
    modelKey.schReceiptTypeCode = dt.schReceiptTypeCode;
    modelKey.schDeliveryTypeCode = dt.schDeliveryTypeCode;
    modelKey.schContainerOwnership = dt.schContainerOwnership;
    modelKey.schContainerStatus = dt.schContainerStatus;
    modelKey.schShipmentType = dt.schShipmentType;
    modelKey.schVesselId = dt.schVesselId;
    modelKey.schVoyage = dt.schVoyage;
    modelKey.schDetentionPOL = dt.schDetentionPOL;
    modelKey.schDetentionRequestPOL = dt.schDetentionRequestPOL;
    modelKey.schDemurragePOL = dt.schDemurragePOL;
    modelKey.schDemurrageRequestPOL = dt.schDemurrageRequestPOL;
    modelKey.schDetention = dt.schDetention;
    modelKey.schDetentionRequest = dt.schDetentionRequest;
    modelKey.schDemurrage = dt.schDemurrage;
    modelKey.schDemurrageRequest = dt.schDemurrageRequest;
    modelKey.schDgClass = dt.schDgClass;
    modelKey.schBookingPartyId = dt.schBookingPartyId;
    modelKey.schShipperId = dt.schShipperId;
    this.surchargeMasterKeyRetrieveExpiryOn =
    this.getSurchargeMasterKeyStringExpiryOn(modelKey);

    this.surchargeMasterKeyRetrieve =
      this.getSurchargeMasterKeyString(modelKey);

    this.surchargeMasterRetrieve = this.getSurchargeMasterString(dt);
    this.surchargeDetailsRetrieve = "";
    this.isValidRetrieve = dt.schIsValid;

    // console.log("** Load Detail **");

    this.containerTypeList = [];
    this.listPayerExisting = [];
    this.map = new Map(); //reset the map
    dt.schSurchargeDetails.forEach((element) => {
      this.surchargeDetailsRetrieve += this.getSurchargeDetailString(
        element,
        this.model.schIsValid
      );
      // console.log(element);

      if (this.map.get(element.schDetailContainerType) == undefined) {
        this.containerTypeList.push(element.schDetailContainerType);

        this.modelDetailList = [];
        this.modelDetailList.push(element);
        this.map.set(element.schDetailContainerType, this.modelDetailList);
      } else {
        this.map.get(element.schDetailContainerType).push(element);
      }

      if(element.schDetailPayer != ""){
        if (!this.listPayerExisting.includes(element.schDetailPayer)) {
          this.listPayerExisting.push(element.schDetailPayer);
        }
      }
    });

    if (this.listPayerExisting.length > 0) {
    this.custIdForCbPayer = this.listPayerExisting.join("%7C");
    }else{
      this.custIdForCbPayer = "*";
    }

    this.gridApiPrepaid.setRowData([]);
    this.gridApiCollect.setRowData([]);
    var detailList: any[] = this.map.get(this.model.schContainerType);
    detailList.forEach((element) => {
      var x: SurchargeDetail = new SurchargeDetail();
      x.schDetailChargeCode = element.schDetailChargeCode;
      x.schDetailChargeName = element.schDetailChargeName;
      x.schDetailContainerType = element.schDetailContainerType;
      switch (element.schDetailContainerType) {
        case "STD":
          element.schDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.schDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.schDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.schDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.schDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.schDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.schDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.schDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.schDetailContainerTypeName = element.schDetailContainerTypeName;
      x.schDetailCopyTab = element.schDetailCopyTab;
      x.schDetailCurrency = element.schDetailCurrency;
      x.schDetailD20 = element.schDetailD20;
      x.schDetailD40 = element.schDetailD40;
      x.schDetailD45 = element.schDetailD45;
      x.schDetailFAIGroup = element.schDetailFAIGroup;
      x.schDetailH20 = element.schDetailH20;
      x.schDetailH40 = element.schDetailH40;
      x.schDetailH45 = element.schDetailH45;
      x.schDetailKT = element.schDetailKT;
      x.schDetailKillSlot = element.schDetailKillSlot;
      x.schDetailM3 = element.schDetailM3;
      x.schDetailMinCharge = element.schDetailMinCharge;
      x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
      if (x.schDetailOogDoorOpen == "N") {
        x.schDetailOogDoorOpenName = "N/A";
      } else if (x.schDetailOogDoorOpen == "D") {
        x.schDetailOogDoorOpenName = "Detached";
      } else if (x.schDetailOogDoorOpen == "B") {
        x.schDetailOogDoorOpenName = "Tied Both";
      } else if (x.schDetailOogDoorOpen == "L") {
        x.schDetailOogDoorOpenName = "Tied Left";
      } else if (x.schDetailOogDoorOpen == "R") {
        x.schDetailOogDoorOpenName = "Tied Right";
      }
      x.schDetailOogOH = element.schDetailOogOH;
      x.schDetailOogOLB = element.schDetailOogOLB;
      x.schDetailOogOLF = element.schDetailOogOLF;
      x.schDetailOogOWL = element.schDetailOogOWL;
      x.schDetailOogOWR = element.schDetailOogOWR;
      x.schDetailOogUcH = element.schDetailOogUcH;
      x.schDetailOogUcL = element.schDetailOogUcL;
      x.schDetailOogUcW = element.schDetailOogUcW;
      x.schDetailPC = element.schDetailPC;
      x.schDetailPCTab = element.schDetailPCTab;
      x.schDetailPayer = element.schDetailPayer;
      x.schDetailPayerName = element.schDetailPayerName;
      x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
      x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
      x.schDetailRT = element.schDetailRT;
      x.schDetailSeqNo = element.schDetailSeqNo;
      x.schDetailStatus = element.schDetailStatus;
      x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

      // this.totD20Prepaid = 0;
      // this.totD40Prepaid = 0;
      // this.totD45Prepaid = 0;
      // this.totH20Prepaid = 0;
      // this.totH40Prepaid = 0;
      // this.totH45Prepaid = 0;

      // this.totD20Collect = 0;
      // this.totD40Collect = 0;
      // this.totD45Collect = 0;
      // this.totH20Collect = 0;
      // this.totH40Collect = 0;
      // this.totH45Collect = 0;

      if (x.schDetailPCTab == "P") {
        console.log(element.schDetailD20);

        this.totD20Prepaid += element.schDetailD20;
        this.totD40Prepaid += element.schDetailD40;
        this.totD45Prepaid += element.schDetailD45;
        this.totH20Prepaid += element.schDetailH20;
        this.totH40Prepaid += element.schDetailH40;
        this.totH45Prepaid += element.schDetailH45;

        //this.gridPrepaid.listStore.store.push(x);
        //this.gridPrepaid.loadData();
        this.gridApiPrepaid.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }

      if (x.schDetailPCTab == "C") {
        this.totD20Collect += element.schDetailD20;
        this.totD40Collect += element.schDetailD40;
        this.totD45Collect += element.schDetailD45;
        this.totH20Collect += element.schDetailH20;
        this.totH40Collect += element.schDetailH40;
        this.totH45Collect += element.schDetailH45;

        //this.gridCollect.listStore.store.push(x);
        //this.gridCollect.loadData();
        this.gridApiCollect.updateRowData({
          add: [JSON.parse(JSON.stringify(x))],
        });
      }
    });

    // Hitung Total
    this.calculateTotal();

    /*
      this.gridPrepaid.total.forEach(elem=>{
        elem["SCHDETAILD20"] = this.totD20Prepaid,
        elem["SCHDETAILD40"] = this.totD40Prepaid,
        elem["SCHDETAILD45"] = this.totD45Prepaid,
        elem["SCHDETAILH20"] = this.totH20Prepaid,
        elem["SCHDETAILH40"] = this.totH40Prepaid,
        elem["SCHDETAILH45"] = this.totH45Prepaid
     })

      this.gridPrepaid.loadData();

      this.gridCollect.total.forEach(el=>{
        el["SCHDETAILD20"] = this.totD20Collect,
        el["SCHDETAILD40"] = this.totD40Collect,
        el["SCHDETAILD45"] = this.totD45Collect,
        el["SCHDETAILH20"] = this.totH20Collect,
        el["SCHDETAILH40"] = this.totH40Collect,
        el["SCHDETAILH45"] = this.totH45Collect
      })

      this.gridCollect.loadData();
      */

    if (dt.schIsValid == "Y") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons =
          "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
      } else {
        this.disableToolbarButtons = "delete,cancel";
      }
    } else if (dt.schIsValid == "N") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons =
          "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
      } else {
        this.disableToolbarButtons = "";
      }
    }

    if (dt.schIsDeleted == "Y") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons =
          "nct,save,copy,update,delete,create,approve,reject,extend,invalid";
      } else {
        this.disableToolbarButtons = this.disableToolbarButtons + ",delete";
      }
    }

    //this.currentActiveTab = "prepaid";
    this.initTab1();
    window.scrollTo(0, 0);
  }

  //unused method
  changeCbPrepaidStatus(event) {}

  changeCbCollectStatus(event) {}

  getSurchargeMasterKeyStringExpiryOn(dt: SurchargeMaster): string{
    return dt.schEffectiveTo;
  }

  validateProsess() {
    this.genericUtil.showLoader();
    this.genericService
              .POST(
                this.configService.config.BASE_API.toString() +
                  "/SurchargeMaster/validateSave/",
                this.model
              )
              .subscribe((resp) => {
                if (resp.status == 200) {
                  let result = resp.json();

                  if (result.status == 1 || result.status == 2) {
                    this.genericUtil.hideLoader();
                    this.message(
                      "information",
                      "Information",
                      result.message,
                      "okonly",
                      { ok: "" }
                    );
                    return;
                  } else {
                    console.log("validate 2");
                    this.model.isCreate = result.isCreate;

                    this.model.lastSurchargeFromStatus =
                      result.lastSurchargeFromStatus;
                    this.model.lastSurchargeFrom = result.lastSurchargeFrom;
                    this.model.lastSurchargeFromStart =
                      result.lastSurchargeFromStart;
                    this.model.lastSurchargeFromEnd = result.lastSurchargeFromEnd;

                    this.model.lastSurchargeToStatus =
                      result.lastSurchargeToStatus;
                    this.model.lastSurchargeTo = result.lastSurchargeTo;
                    this.model.lastSurchargeToStart = result.lastSurchargeToStart;
                    this.model.lastSurchargeToEnd = result.lastSurchargeToEnd;

                    // console.log("** surchargeMasterKeyRetrieve **");
                    // console.log(this.surchargeMasterKeyRetrieve);
                    // console.log("** currentsurchargeMasterKey **");
                    // console.log(currentSurchargeMasterKey);

                    if (this.model.schSurchargeId == "") {
                      this.message(
                        "information",
                        "Information",
                        "Do you really want to create a New record?",
                        "okcancel",
                        {
                          ok: "this.ClearRecordId();this.UpdateMap();",
                          cancel: "this.genericUtil.hideLoader();",
                        }
                      );
                      return;
                    } else {
                      console.log("validate 3");
                      if (
                        this.surchargeMasterKeyRetrieve ==
                        this.currentSurchargeMasterKeyValidate
                      ) {
                        console.log("validate 4");
                        if (this.currentContainerTypeListValidate > this.containerTypeList) {
                          //new CType
                          this.message(
                            "information",
                            "Information",
                            "Do you really want to Create a new cntr type to the existing record?",
                            "okcancel",
                            {
                              ok: "this.UpdateMap();",
                              cancel: "this.genericUtil.hideLoader();",
                            }
                          );
                          return;
                        } else {
                          console.log("validate 4");
                          if (this.model.isCreate == "#CREATE#") {
                            //this.model.schSurchargeId = "";
                            this.message(
                              "information",
                              "Information",
                              "Do you really want to create a New record?",
                              "okcancel",
                              {
                                ok: "this.ClearRecordId();this.UpdateMap();",
                                cancel: "this.genericUtil.hideLoader();",
                              }
                            );
                            return;
                          } else {
                            console.log("validate 5");
                            this.message(
                              "information",
                              "Information",
                              "Are you sure to update the existing Reference No ?",
                              "okcancel",
                              {
                                ok: "this.UpdateMap();",
                                cancel: "this.genericUtil.hideLoader();",
                              }
                            );
                            return;
                          }
                        }
                      } else {
                        console.log("validate 6");
                        //this.model.schSurchargeId = "";
                        this.message(
                          "information",
                          "Information",
                          "Do you really want to create a New record?",
                          "okcancel",
                          {
                            ok: "this.ClearRecordId();this.UpdateMap();",
                            cancel: "this.genericUtil.hideLoader();",
                          }
                        );
                        return;
                      }
                    }
                  }
                }
              });
  }

  getSurchargeMasterKeyString(dt: SurchargeMaster): string {
    //return dt.schOfficeCode + "/" +
    return (
      dt.schFreightPartyId +
      "/" +
      dt.schPlaceOfReceiptCode +
      "/" +
      dt.schLoadPortCode +
      "/" +
      dt.schDischargePortCode +
      "/" +
      dt.schPlaceOfDeliveryCode +
      "/" +
      dt.schFinalDestinationCode +
      "/" +
      dt.schPortOfTransshipment1Code +
      "/" +
      dt.schPortOfTransshipment2Code +
      "/" +
      dt.schPortOfTransshipment3Code +
      "/" +
      dt.schCommodityId +
      "/" +
      dt.schReceiptTypeCode +
      "/" +
      dt.schDeliveryTypeCode +
      "/" +
      dt.schContainerOwnership +
      "/" +
      dt.schContainerStatus +
      "/" +
      dt.schShipmentType +
      "/" +
      dt.schVesselId +
      "/" +
      dt.schVoyage +
      "/" +
      dt.schDetention +
      "/" +
      dt.schDetentionRequest +
      "/" +
      dt.schDemurrage +
      "/" +
      dt.schDemurrageRequest +
      "/" +
      dt.schDgClass +
      "/" +
      dt.schBookingPartyId +
      "/" +
      dt.schShipperId
    );
  }

  getSurchargeMasterString(dt: SurchargeMaster): string {
    // console.log('schPORTerminalCode => ' + dt.schPORTerminalCode);

    //return dt.schOfficeCode + "/" +
    return (
      dt.schSurchargeId +
      "/" +
      dt.schFreightPartyId +
      "/" +
      dt.schPlaceOfReceiptCode +
      "/" +
      dt.schLoadPortCode +
      "/" +
      dt.schDischargePortCode +
      "/" +
      dt.schPlaceOfDeliveryCode +
      "/" +
      dt.schFinalDestinationCode +
      "/" +
      dt.schPortOfTransshipment1Code +
      "/" +
      dt.schPortOfTransshipment2Code +
      "/" +
      dt.schPortOfTransshipment3Code +
      "/" +
      //dt.schContainerType + "/" +
      dt.schCommodityId +
      "/" +
      dt.schReceiptTypeCode +
      "/" +
      dt.schDeliveryTypeCode +
      "/" +
      dt.schEffectiveFrom +
      "/" +
      dt.schEffectiveTo +
      "/" +
      dt.schContainerOwnership +
      "/" +
      dt.schContainerStatus +
      "/" +
      dt.schShipmentType +
      "/" +
      dt.schVesselId +
      "/" +
      dt.schVoyage +
      "/" +
      dt.schDetention +
      "/" +
      dt.schDetentionRequest +
      "/" +
      dt.schDemurrage +
      "/" +
      dt.schDemurrageRequest +
      "/" +
      dt.schDgClass +
      "/" +
      dt.schUserCreated +
      "/" +
      dt.schDateCreated +
      "/" +
      //dt.schUserModified + "/" +
      //dt.schDateModified + "/" +
      //dt.schUserRequested + "/" +
      //dt.schDateRequested + "/" +
      //dt.schUserApproved + "/" +
      //dt.schDateApproved + "/" +
      //dt.schIsTransmitted + "/" +
      //dt.schUserTransmitted + "/" +
      //dt.schDateTransmitted + "/" +
      dt.schPORTerminalCode +
      "/" +
      dt.schPOLTerminalCode +
      "/" +
      dt.schPODTerminalCode +
      "/" +
      dt.schPODLTerminalCode +
      "/" +
      dt.schPOT1TerminalCode +
      "/" +
      dt.schPOT2TerminalCode +
      "/" +
      dt.schPOT3TerminalCode +
      "/" +
      dt.schFDESTTerminalCode +
      "/" +
      dt.schIsValid +
      "/" +
      dt.schUserInvalid +
      "/" +
      dt.schDateInvalid +
      "/" +
      dt.schIsDeleted +
      "/" +
      dt.schUserDeleted +
      "/" +
      dt.schDateDeleted +
      "/" +
      dt.schReasonDeleted +
      "/" +
      //dt.schIsCommission + "/" +
      dt.schAcctPic +
      "/" +
      dt.schBookingPartyId +
      "/" +
      dt.schShipperId
    );
  }

  getSurchargeDetailString(dt: SurchargeDetail, invalid: string): string {
    return (
      dt.schDetailSeqNo +
      "/" +
      dt.schDetailStatus +
      "/" +
      dt.schDetailContainerType +
      "/" +
      dt.schDetailChargeCode +
      "/" +
      dt.schDetailChargeName +
      "/" +
      dt.schDetailCurrency +
      "/" +
      dt.schDetailPC +
      "/" +
      dt.schDetailPlaceOfPayment +
      "/" +
      dt.schDetailPlaceOfPaymentName +
      "/" +
      dt.schDetailPayer +
      "/" +
      dt.schDetailPayerName +
      "/" +
      dt.schDetailD20 +
      "/" +
      dt.schDetailD40 +
      "/" +
      dt.schDetailD45 +
      "/" +
      dt.schDetailH20 +
      "/" +
      dt.schDetailH40 +
      "/" +
      dt.schDetailH45 +
      "/" +
      dt.schDetailM3 +
      "/" +
      dt.schDetailKT +
      "/" +
      dt.schDetailRT +
      "/" +
      dt.schDetailMinCharge +
      "/" +
      dt.schDetailKillSlot +
      "/" +
      dt.schDetailFAIGroup +
      "/" +
      dt.schDetailOogOH +
      "/" +
      dt.schDetailOogOWL +
      "/" +
      dt.schDetailOogOWR +
      "/" +
      dt.schDetailOogOLF +
      "/" +
      dt.schDetailOogOLB +
      "/" +
      dt.schDetailOogDoorOpen +
      "/" +
      dt.schDetailOogUcH +
      "/" +
      dt.schDetailOogUcL +
      "/" +
      dt.schDetailOogUcW +
      "/" +
      //dt.schDetailTotalChargeValue + "/" +
      //dt.schDetailCopyTab + "/" +
      dt.schDetailPCTab +
      "/" +
      invalid
    );
  }

  ClearRecordId() {
    this.model.schSurchargeId = "";
  }

  Check_MapSize() {
    if (this.map.size == 0) {
      this.message(
        "information",
        "Information",
        "There is no container type selected, no freight will be created.",
        "okonly",
        { ok: "this.genericUtil.hideLoader();" }
      );
      //this.dialogMultiple.current = undefined;
      //this.gridPrepaid.onClear();
      //this.gridCollect.onClear();
      this.gridApiPrepaid.setRowData([]);
      this.gridApiCollect.setRowData([]);
      //return;
    }
  }

  UpdateMap() {
    // console.log('** update map **');
    /*
      this.dialogMultiple.clear();
      var i: number = 1;
      this.map.forEach((value: string, key: string) => {
        var ct: string
        switch (key) {
          case "TNK":
            ct = 'TANK';
            break;
          case "OPT":
            ct = 'OPEN TOP';
            break;
          case "FLR":
            ct = 'FLAT RACK';
            break;
          case "PLT":
            ct = 'PLATFORM';
            break;
          case "REF":
            ct = 'REEFER';
            break;
          case "DRY":
            ct = 'REEFER DRY';
            break;
          case "UC":
            ct = 'UC';
            break;
          default:
            ct = 'NORMAL';
            break;
        }
        var mp: any = {
          no: i++,
          icon: "information",
          header: "information",
          content: 'Do you want to create surcharge for ' + ct + ' Container ?',
          buttons: 'yesno',
          event: {yes: '', no: 'this.map.delete("' + key + '");'}
        };
        this.dialogMultiple.addMessage(mp);
      });
      this.dialogMultiple.show();
    */
    this.mapCtype.clear();
    var i: number = 1;
    this.map.forEach((value: any[], key: string) => {
      console.log(key + " - " + value.length);
      if (value.length > 0 && this.mapCheck.get(key) == undefined) {
        let ix = i++;
        console.log(ix + " - " + key);
        this.mapCtype.set(ix, key);
      }
    });

    this.indexMapCtype++;
    var ct: string;
    switch (this.mapCtype.get(this.indexMapCtype)) {
      case "TNK":
        ct = "TANK";
        break;
      case "OPT":
        ct = "OPEN TOP";
        break;
      case "FLR":
        ct = "FLAT RACK";
        break;
      case "PLT":
        ct = "PLATFORM";
        break;
      case "REF":
        ct = "REEFER";
        break;
      case "DRY":
        ct = "REEFER DRY";
        break;
      case "UC":
        ct = "UC";
        break;
      case "STD":
        ct = "NORMAL";
        break;
      default:
        ct = "NORMAL";
        break;
    }
    this.multipleCtypeDialogText =
      "Do you want to create freight for " + ct + " Container ? ";
    this.genericUtil.showDialog("multipleCtypeDialog", "Information", 350, 150);
  }

  changeInvalid(event) {
    console.log("** CHANGE INVALID **");
    console.log("event.target.checked => " + event.target.checked);
    //console.log("pre this.model.schIsValid ==> " + this.model.schIsValid);
    console.log("this.model.disableInvalid ==> " + this.model.disableInvalid);
    console.log("this.model.disableValid ==> " + this.model.disableValid);

    //console.log("this.lockInvalid ==> " + this.lockInvalid);

    if (event.target.checked) {
      if (this.model.disableInvalid === "true") {
        this.msg = 'Unable to invalid, please check "';
        this.model.historyList.forEach((element) => {
          if (element.after != undefined) {
            this.msg += element.after;
          }
          if (element.current != undefined) {
            //this.msg += "<b>Current : " + element.current + "</b><br/>"
          }
          if (element.before != undefined) {
            //this.msg += "Before : " + element.before
          }
        });
        this.msg += '"';

        if (event.target.checked == true) {
          event.target.checked = false;
        } else {
          event.target.checked = true;
        }

        this.genericUtil.showDialog("historyDialog", "Information", 350, 150);
      } else {
        /*
          // Related to GSO-203
          if (!event.target.checked) {
            if(this.model.schSurchargeId != ""){
              this.lockHeader = false;
              this.disableToolbarButtons = "copy";
            }
            this.model.schIsValid = 'N';
          } else {
            if(this.model.schSurchargeId != ""){
              this.lockHeader = true;
              this.disableToolbarButtons = "save";
            }
            this.model.schIsValid = 'Y';
          }
          */

        if (event.target.checked) {
          this.model.schIsValid = "N";
          this.model.schDateInvalid = this.genericUtil.getPSIDate();
          this.model.schUserInvalid = this.cookieService
            .getName()
            .toUpperCase();
        } else {
          this.model.schIsValid = "Y";
          this.model.schDateInvalid = "";
          this.model.schUserInvalid = "";
        }
      }
    } else {
      if (this.model.disableValid === "true") {
        this.msg = 'Unable to valid, please check "';
        this.model.historyList.forEach((element) => {
          if (element.after != undefined) {
            //this.msg += element.after
          }
          if (element.current != undefined) {
            //this.msg += "<b>Current : " + element.current + "</b><br/>"
          }
          if (element.before != undefined) {
            this.msg += element.before;
          }
        });
        this.msg += '"';

        if (event.target.checked == true) {
          event.target.checked = false;
        } else {
          event.target.checked = true;
        }

        this.genericUtil.showDialog("historyDialog", "Information", 350, 150);
      } else {
        /*
          // Related to GSO-203
          if (!event.target.checked) {
            if(this.model.schSurchargeId != ""){
              this.lockHeader = false;
              this.disableToolbarButtons = "copy";
            }
            this.model.schIsValid = 'N';
          } else {
            if(this.model.schSurchargeId != ""){
              this.lockHeader = true;
              this.disableToolbarButtons = "save";
            }
            this.model.schIsValid = 'Y';
          }
          */

        if (event.target.checked) {
          this.model.schIsValid = "N";
          this.model.schDateInvalid = this.genericUtil.getPSIDate();
          this.model.schUserInvalid = this.cookieService
            .getName()
            .toUpperCase();
        } else {
          this.model.schIsValid = "Y";
          this.model.schDateInvalid = "";
          this.model.schUserInvalid = "";
        }
      }
    }
  }

  closeDialog(id: string) {
    this.genericUtil.closeDialog(id);
    //this.gridPrepaid.loadData();
    //this.gridCollect.loadData();
  }

  changeCbMaintenanceShipper(event) {
    console.log("Shipper ubah kemari");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
    //   this.model.schShipperId = event["customerId"];
    // } else {
    //   this.model.schShipperId = "";
    // }
      this.model.schShipperId = event["customerId"];
      this.searchShIsShipper=event["isShipper"];
      this.searchShIsValid=event['isValid'];
      // this.dateModifiedShipper=event['dateModified'];
    } else {
      this.model.schShipperId = "";
      this.searchShIsShipper="";
      this.searchShIsValid="";
      // this.dateModifiedShipper="";
    }

    //revert the search url to use name
    // this.cbMaintenanceShipper.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //   "/PFCGenericAPI/findByComboBoxControlGeneral/shipper/customerName={query}",
    // );

    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceShipper.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/shipper/*/*/customerName={query}"
      );
    } else {
      console.log("uriString");

      var uriString = "";
      if (this.model.schSurchargeId == null ||
        this.model.schSurchargeId == "" ||
        this.model.schSurchargeId == undefined) {
          uriString = this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
          "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerName={query}"+
          this.sCharUtil.htmlEncode("|")+
          "status=N";
      } else {
        uriString = this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            "/MasterSurcharges/findByComboBoxControlGeneral/shipper/customerName={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=E"+
            this.sCharUtil.htmlEncode("|")+
            "recordId="+this.model.schSurchargeId;
      }
      console.log(uriString);
      this.cbMaintenanceShipper.setUrl(uriString);
    }
  }

  changeCbMaintenanceBookingParty(event) {
    console.log("booking party ubah kemari");
    if (
      event["customerId"] != null &&
      event["customerId"] != undefined &&
      event["customerId"] != ""
    ) {
    //   this.model.schBookingPartyId = event["customerId"];
    // } else {
    //   this.model.schBookingPartyId = "";
    // }
      this.model.schBookingPartyId = event["customerId"];
      this.searchBkIsBookingParty=event["isBookingParty"];
      this.searchBkIsValid=event['isValid'];
      // this.dateModifiedBookingParty=event['dateModified'];
    } else {
      this.model.schBookingPartyId = "";
      this.searchBkIsBookingParty="";
      this.searchBkIsValid="";
      // this.dateModifiedBookingParty="";
    }

    //revert the search url to use name
    if (this.dateCreatedForCbPayer == "*") {
      this.cbMaintenanceBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/booking_party/*/*/customerName={query}"
      );
    } else {

      var uriString = "";
        if (this.model.schSurchargeId == null ||
          this.model.schSurchargeId == "" ||
          this.model.schSurchargeId == undefined) {
            uriString = this.configService.config.BASE_API.toString() +
            // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
            "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerName={query}"+
            this.sCharUtil.htmlEncode("|")+
            "status=N";
        } else {
          uriString = this.configService.config.BASE_API.toString() +
                // "/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}"
                "/MasterSurcharges/findByComboBoxControlGeneral/booking_party/customerName={query}"+
                this.sCharUtil.htmlEncode("|")+
                "status=E"+
                this.sCharUtil.htmlEncode("|")+
                "recordId="+this.model.schSurchargeId;
        }

        this.cbMaintenanceBookingParty.setUrl(uriString);
    }
  }

  populateData() {
    this.model.schSurchargeDetails = [];

    this.map.forEach((value: SurchargeDetail[], key: string) => {
      // console.log(key);

      value.forEach((element) => {
        var x: SurchargeDetail = new SurchargeDetail();
        x.schDetailChargeCode = element.schDetailChargeCode;
        x.schDetailChargeName = element.schDetailChargeName;
        x.schDetailContainerType = element.schDetailContainerType;
        x.schDetailCopyTab = element.schDetailCopyTab;
        x.schDetailCurrency = element.schDetailCurrency;
        x.schDetailD20 = element.schDetailD20;
        x.schDetailD40 = element.schDetailD40;
        x.schDetailD45 = element.schDetailD45;
        x.schDetailFAIGroup = element.schDetailFAIGroup;
        x.schDetailH20 = element.schDetailH20;
        x.schDetailH40 = element.schDetailH40;
        x.schDetailH45 = element.schDetailH45;
        x.schDetailKT = element.schDetailKT;
        x.schDetailKillSlot = element.schDetailKillSlot;
        x.schDetailM3 = element.schDetailM3;
        x.schDetailMinCharge = element.schDetailMinCharge;
        x.schDetailOogDoorOpen = element.schDetailOogDoorOpen;
        if (x.schDetailOogDoorOpen == "N") {
          x.schDetailOogDoorOpenName = "N/A";
        } else if (x.schDetailOogDoorOpen == "D") {
          x.schDetailOogDoorOpenName = "Detached";
        } else if (x.schDetailOogDoorOpen == "B") {
          x.schDetailOogDoorOpenName = "Tied Both";
        } else if (x.schDetailOogDoorOpen == "L") {
          x.schDetailOogDoorOpenName = "Tied Left";
        } else if (x.schDetailOogDoorOpen == "R") {
          x.schDetailOogDoorOpenName = "Tied Right";
        }
        x.schDetailOogOH = element.schDetailOogOH;
        x.schDetailOogOLB = element.schDetailOogOLB;
        x.schDetailOogOLF = element.schDetailOogOLF;
        x.schDetailOogOWL = element.schDetailOogOWL;
        x.schDetailOogOWR = element.schDetailOogOWR;
        x.schDetailOogUcH = element.schDetailOogUcH;
        x.schDetailOogUcL = element.schDetailOogUcL;
        x.schDetailOogUcW = element.schDetailOogUcW;
        x.schDetailPC = element.schDetailPC;
        x.schDetailPCTab = element.schDetailPCTab;
        x.schDetailPayer = element.schDetailPayer;
        x.schDetailPayerName = element.schDetailPayerName;
        x.schDetailPlaceOfPayment = element.schDetailPlaceOfPayment;
        x.schDetailPlaceOfPaymentName = element.schDetailPlaceOfPaymentName;
        x.schDetailRT = element.schDetailRT;
        x.schDetailSeqNo = element.schDetailSeqNo;
        x.schDetailStatus = element.schDetailStatus;
        x.schDetailTotalChargeValue = element.schDetailTotalChargeValue;

        this.model.schSurchargeDetails.push(x);
      });
    });
  }

  OIMonly(event: any) {
    if (!(event.key == "O" || event.key == "I" || event.key == "M")) {
      event.preventDefault();
    }
  }

  PCOnly(event: any) {
    if (!(event.key == "P" || event.key == "C")) {
      event.preventDefault();
    }
  }

  exceptApos(event: any) {
    if (event.key == "'") {
      event.preventDefault();
    }
  }

  CharNumOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z0-9 ]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumOnly(event: any) {
    var regexPattern = new RegExp("[0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumDotOnly(event: any) {
    var regexPattern = new RegExp("[0-9.]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  calculateTotal() {
    console.log("** calculate TOTAL **");
    let mpPrepaid = new Map();
    let mpCollect = new Map();

    this.gridApiPrepaid.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;

      let dt: SurchargeDetail = mpPrepaid.get(x.schDetailCurrency);

      if (dt == undefined) {
        var xx: SurchargeDetail = new SurchargeDetail();
        xx.schDetailD20 = x.schDetailD20;
        xx.schDetailD40 = x.schDetailD40;
        xx.schDetailD45 = x.schDetailD45;
        xx.schDetailH20 = x.schDetailH20;
        xx.schDetailH40 = x.schDetailH40;
        xx.schDetailH45 = x.schDetailH45;

        mpPrepaid.set(x.schDetailCurrency, xx);
      } else {
        dt.schDetailD20 += x.schDetailD20;
        dt.schDetailD40 += x.schDetailD40;
        dt.schDetailD45 += x.schDetailD45;
        dt.schDetailH20 += x.schDetailH20;
        dt.schDetailH40 += x.schDetailH40;
        dt.schDetailH45 += x.schDetailH45;
      }
    });

    this.gridApiCollect.forEachNodeAfterFilterAndSort(function (
      rowNode,
      index
    ) {
      var x: SurchargeDetail = rowNode.data;

      let dt: SurchargeDetail = mpCollect.get(x.schDetailCurrency);

      if (dt == undefined) {
        var xx: SurchargeDetail = new SurchargeDetail();
        xx.schDetailD20 = x.schDetailD20;
        xx.schDetailD40 = x.schDetailD40;
        xx.schDetailD45 = x.schDetailD45;
        xx.schDetailH20 = x.schDetailH20;
        xx.schDetailH40 = x.schDetailH40;
        xx.schDetailH45 = x.schDetailH45;

        mpCollect.set(x.schDetailCurrency, xx);
      } else {
        dt.schDetailD20 += x.schDetailD20;
        dt.schDetailD40 += x.schDetailD40;
        dt.schDetailD45 += x.schDetailD45;
        dt.schDetailH20 += x.schDetailH20;
        dt.schDetailH40 += x.schDetailH40;
        dt.schDetailH45 += x.schDetailH45;
      }
    });

    let totPrepaid: SurchargeDetailTotal[] = [];
    mpPrepaid.forEach(function (item, key, mapObj) {
      let dt: SurchargeDetail = item;

      var x: SurchargeDetailTotal = new SurchargeDetailTotal();
      x.schDetailChargeName = "TOTAL " + key;

      x.schDetailD20 = dt.schDetailD20;
      x.schDetailD40 = dt.schDetailD40;
      x.schDetailD45 = dt.schDetailD45;
      x.schDetailH20 = dt.schDetailH20;
      x.schDetailH40 = dt.schDetailH40;
      x.schDetailH45 = dt.schDetailH45;

      totPrepaid.push(x);
    });
    this.pinnedBottomRowDataPrepaid = JSON.parse(JSON.stringify(totPrepaid));

    let totCollect: SurchargeDetailTotal[] = [];
    mpCollect.forEach(function (item, key, mapObj) {
      let dt: SurchargeDetail = item;

      var x: SurchargeDetailTotal = new SurchargeDetailTotal();
      x.schDetailChargeName = "TOTAL " + key;

      x.schDetailD20 = dt.schDetailD20;
      x.schDetailD40 = dt.schDetailD40;
      x.schDetailD45 = dt.schDetailD45;
      x.schDetailH20 = dt.schDetailH20;
      x.schDetailH40 = dt.schDetailH40;
      x.schDetailH45 = dt.schDetailH45;
      totCollect.push(x);
    });
    this.pinnedBottomRowDataCollect = JSON.parse(JSON.stringify(totCollect));
  }

  onMultipleCtypeDialogYes(event) {
    console.log(this.indexMapCtype);

    if (this.indexMapCtype < this.mapCtype.size) {
      let ix = this.indexMapCtype + 1;
      console.log("ix : " + ix);
      this.indexMapCtype = ix;
      var ct: string;
      switch (this.mapCtype.get(ix)) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        case "STD":
          ct = "NORMAL";
          break;
        default:
          ct = "NORMAL";
          break;
      }
      this.multipleCtypeDialogText =
        "Do you want to create surcharge for " + ct + " Container ? ";
    } else {
      this.indexMapCtype = 0;
      this.genericUtil.closeDialog("multipleCtypeDialog");

      if (this.map.size == 0) {
        // console.log('not save');
        this.Check_MapSize();
        return;
      } else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }
  }

  onMultipleCtypeDialogCancel(event) {
    console.log(this.indexMapCtype);

    if (this.indexMapCtype < this.mapCtype.size) {
      this.map.delete(this.mapCtype.get(this.indexMapCtype));

      let ix = this.indexMapCtype + 1;
      console.log("ix : " + ix);
      this.indexMapCtype = ix;
      var ct: string;
      switch (this.mapCtype.get(ix)) {
        case "TNK":
          ct = "TANK";
          break;
        case "OPT":
          ct = "OPEN TOP";
          break;
        case "FLR":
          ct = "FLAT RACK";
          break;
        case "PLT":
          ct = "PLATFORM";
          break;
        case "REF":
          ct = "REEFER";
          break;
        case "DRY":
          ct = "REEFER DRY";
          break;
        case "UC":
          ct = "UC";
          break;
        case "STD":
          ct = "NORMAL";
          break;
        default:
          ct = "NORMAL";
          break;
      }
      this.multipleCtypeDialogText =
        "Do you want to create surcharge for " + ct + " Container ? ";
    } else {
      this.map.delete(this.mapCtype.get(this.indexMapCtype));

      this.indexMapCtype = 0;
      this.genericUtil.closeDialog("multipleCtypeDialog");

      if (this.map.size == 0) {
        this.Check_MapSize();
        return;
      } else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }
  }

  // * Form Validation
  validateFieldSurcharge(event: any) {
    console.log("klik event key "+event.key);
    console.log(this.formValidation.regSurchargeMaster.test(event.key));
    if (this.formValidation.regSurchargeMaster.test(event.key) === false) {
      event.preventDefault();
    }
  }

  validateOnlyRegName(event:any){
    console.log("klik event key "+event.key);
    console.log(this.formValidation.regName.test(event.key));
    if (this.formValidation.regName.test(event.key) === false) {
      event.preventDefault();
    }
  }

  onlyWholeNum(event: any) {
    const regexPattern = /[0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  onlyWholeNumAndDot(event: any){
    const regexPattern = /[0-9.]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar: string = ""
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      this.model[`error-${field}`] = "";

      return true;
    }

    return false;
  }

  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  // validateAfterSaveNew() {
  //   if (this.searchFpIsFreightParty == 'N') {
  //     this.model["error-schFreightPartyId"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
  //     this.isError = true;
  //     window.scrollTo(0, 0);
  //     this.genericUtil.hideLoader();
  //   } else if (this.searchFpIsValid == 'N') {
  //     this.model["error-schFreightPartyId"] = "Freight Party is invalid, unable to proceed with creation";
  //     this.isError = true;
  //     window.scrollTo(0, 0);
  //     this.genericUtil.hideLoader();
  //   } else {
  //     this.ClearRecordId();this.UpdateMap();
  //   }
  // }

  validateAfterSaveNew() {
    console.log("validateAfterSaveNew");
    // this.isError = this.mainCheckInvalidPayer(false);

    if (this.searchFpIsFreightParty == 'N') {
      this.model["error-schFreightPartyId"] = "Customer record is no longer set as a Freight Party, unable to proceed with creation";
      this.isError = true;
    } else if (this.searchFpIsValid == 'N') {
      this.model["error-schFreightPartyId"] = "Freight Party is invalid, unable to proceed with creation";
      this.isError = true;
    }

    if (this.searchShIsValid == 'N') {
      this.model["error-schShipperId"] = "Shipper is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.searchShIsShipper == 'N') {
      this.model["error-schShipperId"] = "Customer record is no longer set as a Shipper, unable to proceed with creation";
      this.isError = true;
    }

    if (this.searchBkIsValid == 'N') {
      this.model["error-schBookingPartyId"] = "Booking Party is invalid, unable to proceed with creation";
      this.isError = true;
    } else if (this.searchBkIsBookingParty == 'N') {
      this.model["error-schBookingPartyId"] = "Customer record is no longer set as a Booking Party, unable to proceed with creation";
      this.isError = true;
    }

    if (this.isError) {
      window.scrollTo(0, 0);
      this.genericUtil.hideLoader();
    } else {
      this.ClearRecordId();this.UpdateMap();
    }
  }
}
