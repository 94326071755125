import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild }
from '@angular/core';
import {officeModel, china_tally_list,Containers,china_tally_list_edi,
  } from "../shared/index";
import { specialCharUtil } from '../shared';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil,ConfigService,CookieService
  ,TextareaPluginComponent,DialogPluginComponent
}
from 'sibego-ui-library';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare  var $:any;

export class Param {
  vesselName: string = "";
  voyageNo: string = "";
  blNo: string = "";
  containerNo: string = "";
  status: string = "";
  filename: string="";
  nextCheck: string = "";
  constructor() {}
}

@Component({
  selector: 'app-edi-process-edi-booking-page',
  templateUrl: './edi-process-tally-list-page.component.html',
  styleUrls: ['./edi-process-tally-list-page.component.css']
})
export class EdiProcessTallyListPageComponent extends Validator
implements OnInit, AfterViewInit
{

  dialog:any;

  /* Parameter for information into Footer */
  info = '';
  space = "&nbsp;&nbsp;";
  @ViewChild("gridChinaTallyListEDIInfo") gridChinaTallyListEDIInfo: GridPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
  /* Parameter for dialog */
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  appStatusLabel: string ="";
  /* Parameter modeForm (Default : false) */
  modeForm = false;
 // modeForm = "chinaTallyListEDIProcess"; //false;//false;
  modeToolbar =false;
   /*modeForm = 'sailingScheduleFlagStatus';*/
  loading = false;

    /* Parameter for enable/disable Toolbar buttons */
   invisibleToolbarButtons  = "";
   disableToolbarButtons =  "";


   /* Parameter listStore for Grid */
   listStore = new ListStore();

   //parameter continueToMaintenance
   isError: Boolean = false;
   isCOC: Boolean = true;
   isSOC: Boolean = true;
   isSLOT: Boolean = true;
   isNVOCC: Boolean = false;

  /* Parameter settings */
  settingToolbar;
  settingGridChinaTallyListEDIProcessInfo;

  newDialogOpt;
  // * HTML Encode
  sCharUtil = new specialCharUtil();

    /* Parameter model */
  model = new china_tally_list();

  //Default parameter
  unconfirmedList = [];
  isConfirm: Boolean = false;
  isFileTerminateProcess: Boolean =true;

  //Flag active tab
  isCopy: boolean = false;

  // lock
  lock = false;

  lockBtnChinaTallyListEDIDiscard: Boolean = false;

  validatorRules = {

  };

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil,
    private configService: ConfigService, private router: Router, private cookieService:CookieService) {
    super();

    /** Start constructor - China Tally List EDI process **/
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'checkmark'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Process Tally List EDI'
    };

    this.settingGridChinaTallyListEDIProcessInfo = {
     id: "gridChinaTallyListEDIInfo",
      url: this.configService.config.BASE_API.toString() +
        "/shaTallyList/retrieveTallyList",
      //http://localhost:16000/shaTallyList/retrieveTallyList/*/*/*/1/10/DESC/bl_edi_china_datetime
      // url: "http://localhost:16000/shaTallyList/retrieveTallyList",
      // url: this.configService.config.standalone[0].target +
      //      this.configService.config.standalone[0].endpoint +
      //      "/retrieveTallyList",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "File Name", field: "fileName", width: 200 },
        { header: "Vessel Name", field:"vesselName", width: 200 },
        { header: "Voyage No.", field: "voyageNo", width: 100 },
        { header: "Total No. of Containers", field: "noOfContainers", width: 100 },
        { header: "Date Received", field: "dateReceived", width: 100 },
        { header: "Time Received", field: "timeReceived", width: 100 },
        { header: "BL", field: "blProcessed", width: 50 },
        { header: "CLL", field: "cllProcessed", width: 50 },
        { header: "Remarks", field:"remarks", width: 200 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "bl_edi_china_datetime",
      sortingDirection: "DESC",
    };

    /** End constructor - China Tally List EDI process **/
  }

  ngOnInit() {


  }

  ngAfterViewInit() {

    this.init();
    var self = this;
  }

  init(){
    this.modeForm=true;
    this.model.user=this.cookieService.getName(); //this.cookieService.getUserId();
    this.model.officeCode=this.cookieService.getDefaultLocationCode();
    if (this.tabStatus == "tab1"){
      this.disableToolbarButtons = "process";
      this.lockBtnChinaTallyListEDIDiscard=false;
      this.gridChinaTallyListEDIInfo.search = "*/*/*";
      this.gridChinaTallyListEDIInfo.onFirst();
    }
  }

  initTab1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.modeForm=true;
    //this.modeToolbar=true;
    this.disableToolbarButtons = "process";
  }

  toolbarEvent(event) {
    console.log('event : ' + event);
    switch (event) {
      case 'process' :
        this.handleProcess();

        break;
      case 'close':
        // handle close event

        this.router.navigateByUrl("/main/home");
        break;
    }
  }


  eventMessage(event: any){
   if (event != "") {
      eval(event);
    }

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  //start Method - Process the EDI Booking data
  handleProcess() {
     // handle update event

     if (this.gridChinaTallyListEDIInfo.getSelectedValues().length >= 1) {

      this.message(
        "save",
        "Process data",
        "Existing container(s) in CLL and BL Container tab will be overwritten. Confirm to proceed?",
        "yesno",
        { yes: "this.processEvent()", no: "this.loading = false;" }
      );
    } else {
      this.message(
        "information",
        "Information",
        "No Tally List EDI selected, please select a EDI file.",
        "okonly",
        { ok: "this.loading=false;" }
      );
     }

    //let dt = this.gridChinaTallyListEDIInfo.getSelectedValues()[0];
    // console.log(dt);
   // this.LoadBkgEDIFile(dt);


  }

  processEvent(){
    this.loading = true;
    console.log('debugging')
    var self = this;

    self.model.processBtn=false;

    if(this.gridChinaTallyListEDIInfo.getSelectedValues().length>0) {
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
          "/shaTallyList/processAllCheckDigits",
          self.model
          //"http://localhost:16000/shaTallyList/processAllCheckDigits
        )
        .subscribe(
          (resp) => {

            if (resp.status == 200 ) { //return 500

              if (resp._body !=null) {

                try {
                  let body:any[]
                  body=JSON.parse(resp._body)
                  console.log(resp._body);

                  this.unconfirmedList=body;

                  if (this.unconfirmedList.length > 0) {//invalid container number, show invalid container number
                    console.log('handleUnconfirmedList()');
                    this.handleUnconfirmedList();
                   // this.info = "Open pop up form successfully";
                  }

                } catch (error) {//no invalid container number,_body is not JSON string, return report.

                  //if(resp._body.split(".")[1]=="pdf"){
                  if(resp._body.toUpperCase().endsWith('.PDF')){

                    console.log('generate report');

                    var sPdfUrl=this.configService.config.pdfUrl + '/download/' + resp._body;
                    console.log(sPdfUrl);

                    $('#pdfTallyListReport').find('#pdfTallyListReport-viewer').empty();
                    console.log('report empty()');
                    $('#pdfTallyListReport').find('#pdfTallyListReport-viewer').append('<object id="o" data="' + sPdfUrl + '" type="application/pdf" width="100%" height="100%"></object>');
                    console.log('report append');
                    this.showPDF();

                  }else{
                    this.message(
                      "warning",
                      "Warning",
                      resp._body,
                      "okonly",
                      { ok: "this.afterProcess();this.loading=false;" }
                    );

                  }
                }
              }

              this.loading = false;
              console.log('this.loading = false;');

            } else {
              this.loading = false;
              this.message(
                "warning",
                "Warning",
                "Booking EDI failed to processe.",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          },
          (error) => {
            this.loading = false;
            console.log(error.message);
            this.info = error.message;
          }
        );
    } else{ //end if processList.length>0
      this.message(
        "information",
        "Information",
        "No valid Booking EDI for processing now.",
        "okonly",
        { ok: "this.loading=false;" }
      );

    }
  }

  afterProcess() {
    this.modeForm = false;

    this.disableToolbarButtons = "process";
    this.lockBtnChinaTallyListEDIDiscard = true;

    this.gridChinaTallyListEDIInfo.clearSelectedValues();
    this.gridChinaTallyListEDIInfo.loadData();

    // this.gridChinaTallyListEDIInfo.listStore.store.forEach((element) => {
    //   if(this.model.vesselName==element["vesselName"] && this.model.fileName==element["fileName"]
    //     && this.model.voyageNo==element["voyageNo"]){
    //       element['select']='checked';
    //       this.gridChinaTallyListEDIInfo.onSelect(true,element["no"]);
    //     }
    // })

  }

  onChinaTallyListEDIInfo_Discard(event) {

    this.handleDiscard();

  }

  handleDiscard(){
    this.loading = true;
    if (this.gridChinaTallyListEDIInfo.getSelectedValues().length >= 1) {
      this.message(
        "delete",
        "Delete Tally List EDI",
        "Do you want to discard the selected Tally List EDI?",
        "yesno",
        { yes: "this.discardEvent()", no: "this.loading = false;" }
      );
    } else {

      this.message(
        "information",
        "Information",
        "Process denied. No Tally List EDI was selected.",
        "okonly",
        { ok: "this.loading=false;" }
      );

    }
  }

  //Discard Sha booking EDI File
  discardEvent() {
    // console.log('delete event');
    this.loading = true;
    var self = this;



    if(this.model.fileName!='') {

      let discardList_Model : china_tally_list_edi[]=[];
      let discardModel : china_tally_list_edi;
      discardModel= new china_tally_list_edi();

      discardModel.fileName=this.model.fileName;
      discardModel.vesselName=this.model.vesselName;
      discardModel.voyageNo=this.model.voyageNo;

      discardList_Model.push(discardModel);

      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() + "/shaTallyList/discardTallyList",
          //"http://localhost:15000/shaTallyList/discardTallyList",
          discardList_Model
        )
        .subscribe(
          (resp) => {
            if (resp.status == 200) {
                this.loading = false;
                this.message(
                  "information",
                  "Information",
                  "Tally List EDI discarded successfully.",
                  "okonly",
                  { ok: "this.loading=false;this.afterDiscard();" }
                );
            } else {
                this.loading = false;
                this.message(
                  "warning",
                  "Warning",
                  "Tally List EDI failed to discarded.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
            }
          },
          (error) => {
            this.loading = false;
            // console.log(error.message);
            this.info = error.message;
          }
        );
    }else{ //end if deleteList_model.length>0
      this.message(
        "information",
        "Information",
        "No valid file(s) for discardation now.",
        "okonly",
        { ok: "this.loading=false;" }
      );

    }
  }

  afterDiscard() {
    this.modeForm = false;

   //this.modeToolbar= false;
    this.disableToolbarButtons = "process";
    this.lockBtnChinaTallyListEDIDiscard = true;

    this.gridChinaTallyListEDIInfo.clearSelectedValues();
    this.gridChinaTallyListEDIInfo.loadData();
  }

  //End Method - Process the EDI Booking data

  /** Start China Tally List EDI information Handler **/

  gridEventChinaTallyListEDIProcessInfo(event: any) {

    this.modeForm = true;
    // console.log(event);
    // console.log(this.gridChinaTallyListEDIInfo.getSelectedValues().length);
    // console.log(this.gridChinaTallyListEDIInfo);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.gridChinaTallyListEDIInfo.getSelectedValues().length > 0){
          if(event=='selected'){
            if(this.gridChinaTallyListEDIInfo.getSelectedValues().length==1){
              this.lockBtnChinaTallyListEDIDiscard = false;
              this.disableToolbarButtons = "";
            } else{

              this.message(
                "information",
                "Information",
                "Select one file only.",
                "okonly",
                { ok: "this.isConfirm=false;this.gridChinaTallyListEDIInfo.clearSelectedValues();" }
              );
            }

          }

          if(event.includes('.') && event.includes('-') && event.split(".")[1].split("-")[0]=="checked"){
            var sNo=Number(event.split("-")[1]);
            // console.log("checked");
            this.checkOneRowEvent(sNo);

            if( this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["remarks"]!=""){
              this.disableToolbarButtons = "process";
            }else{
              this.disableToolbarButtons = "";
            }

            this.lockBtnChinaTallyListEDIDiscard = false;

          }else if(event.includes('.') && event.includes('-') && event.split(".")[1].split("-")[0]=="unchecked"){
            // var sNo=Number(event.split("-")[1]);
            // this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]['select']='';
            // this.gridChinaTallyListEDIInfo.onSelect(false,sNo);

            this.disableToolbarButtons = "process";
            this.lockBtnChinaTallyListEDIDiscard = true;
          }

        }else{
          this.disableToolbarButtons = "process";
          this.lockBtnChinaTallyListEDIDiscard = true;
        }
        break;
      case "click":
        if (this.gridChinaTallyListEDIInfo.getSelectedValues().length > 0) {
          if(event.includes('.') && event.includes('-') && event.split(".")[1].split("-")[0]=="checked"){
            var sNo=Number(event.split("-")[1]);
            // console.log("checked");
            this.checkOneRowEvent(sNo);

            if( this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["remarks"]!=""){
              this.disableToolbarButtons = "process";
            }else{
              this.disableToolbarButtons = "";
            }
            this.lockBtnChinaTallyListEDIDiscard = false;

          }else if(event.includes('.') && event.includes('-') && event.split(".")[1].split("-")[0]=="unchecked"){

            this.disableToolbarButtons = "process";
            this.lockBtnChinaTallyListEDIDiscard = true;
          }
        } else {
            this.disableToolbarButtons = "process";
            this.lockBtnChinaTallyListEDIDiscard = true;
        }
       break;
      case "afterLoad":
        //  var sDate='';

          if(this.gridChinaTallyListEDIInfo.listStore.store.length>0){
            this.gridChinaTallyListEDIInfo.listStore.store.forEach((element) => {
             // sDate=element["dateReceived"];
              element["dateReceived"]=this.convertToDateString( element["dateReceived"] );
            })
          }
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
          // case "edit":
          //     break;
          case "rowDoubleClicked":
            if (this.gridChinaTallyListEDIInfo.getSelectedValues().length > 0) {

              var sNo=Number(event.split("-")[1]);
              this.checkOneRowEvent(sNo);

              if( this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["remarks"]!=""){
                this.disableToolbarButtons = "process";
              }else{
                this.disableToolbarButtons = "";
              }

              this.lockBtnChinaTallyListEDIDiscard = false;

            } else {
                this.disableToolbarButtons = "process";
                this.lockBtnChinaTallyListEDIDiscard = true;
            }
            break;
          case "dblClick":
            if (this.gridChinaTallyListEDIInfo.getSelectedValues().length > 0) {
              var sNo=Number(event.split("-")[1]);
              this.checkOneRowEvent(sNo);

              if( this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["remarks"]!=""){
                this.disableToolbarButtons = "process";
              }else{
                this.disableToolbarButtons = "";
              }

              this.lockBtnChinaTallyListEDIDiscard = false;

            } else {
                this.disableToolbarButtons = "process";
                this.lockBtnChinaTallyListEDIDiscard = true;
            }
            break;
          default:
            break;
          }
        }
      break;
    }
  }

  infoGridChinaTallyListEDIProcessInfo(event: any) {
    this.info = event;
  }

  handleUnconfirmedList() {
    let heightDialog = 215;

    //set default to No
    if (this.unconfirmedList.length > 0) {
      this.unconfirmedList.forEach((element) => {
        element.isConfirm="N";
      });
    }

    if (this.unconfirmedList.length > 3) {
      heightDialog = 450;
    }
    if (this.unconfirmedList.length == 3) {
      heightDialog = 350;
    }
    if (this.unconfirmedList.length == 2) {
      heightDialog = 300;
    }
    if (this.unconfirmedList.length == 1) {
      heightDialog = 250;
    }


    this.genericUtil.showDialog(
      `UnconfirmedList`,
      "Confirmation!",
      1000,
      heightDialog
    );
  }

  closeUnconfirmedList() {
    if(this.isFileTerminateProcess==true){
      this.message(
        "information",
        "Information",
        "Tally List processing for this file will be terminated.",
        "okonly",
        { ok: "this.genericUtil.closeDialog('UnconfirmedList');this.unconfirmedList = [];" }
      );
    }else{
      this.isFileTerminateProcess=true;
    }
    // this.genericUtil.closeDialog("UnconfirmedList");
    // this.unconfirmedList = [];
  }

  confirmUnconfirmedList() {
    console.log("** click confirm **");
    this.isConfirm = true;
    var self = this;

    self.model.processBtn=true;
    this.model.acceptContainers=[];
    this.model.rejectContainers=[];

    this.unconfirmedList.forEach((element) => {
      let cntr = new Containers();
      cntr.blNo=element.blNo;
      cntr.containerNo=element.containerNo;

      if(element.isConfirm=="Y"){
        this.model.acceptContainers.push(cntr);
      }
      else{
        this.model.rejectContainers.push(cntr);
      }
    });

   let urlReqest = this.configService.config.BASE_API.toString() + "/shaTallyList/processAllCheckDigits";
   //let urlReqest = this.configService.config.standalone[0].target + "/shaTallyList/processAllCheckDigits";
   this.genericService.POST(urlReqest, self.model).subscribe(
      (resp) => {
        this.isConfirm = false;
        if (resp.status == 200 && resp._body !=null) {

          this.isFileTerminateProcess=false;
          this.genericUtil.closeDialog('UnconfirmedList');
          this.unconfirmedList = [];

          if(resp._body.toUpperCase().endsWith('.PDF')){
            console.log(resp._body);
            var sPdfUrl=this.configService.config.pdfUrl + '/download/' + resp._body;
            console.log(sPdfUrl);

            $('#pdfTallyListReport').find('#pdfTallyListReport-viewer').empty();
            $('#pdfTallyListReport').find('#pdfTallyListReport-viewer').append('<object id="o" data="' + sPdfUrl + '" type="application/pdf" width="100%" height="100%"></object>');
            this.showPDF();
          }else{
            this.message(
              "warning",
              "Warning",
              resp._body,
              "okonly",
              { ok: "this.afterProcess();this.loading=false;" }
            );
          }
        } else {
          // this.message("error", "Error", resp.json().message, "okonly", {
          //   ok: "",
          // });
          this.dialogPlugin.show('Error', 'Information', "Unable Generate Tally List Report.", 'okonly', {ok: 'this.loading=false;'});
          this.closeDialog('pdfTallyListReport');
        }
      },
      (error) => {
        this.message(
          "error",
          "Error",
          "An error has occurred. Please report to Administrator!",
          "okonly",
          { ok: "this.isConfirm=false;" }
        );
      }
    );

  }

  showPDF() {
    const self = this;

    this.newDialogOpt = {
      title: 'PDF Preview',
      modal: true,
      closeText: 'hide',
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function(event, ui) {
          // hide close button.
          $('.ui-dialog').css('z-index', 103);
          $('.ui-widget-overlay').css('z-index', 102);

          $('body').first().css('overflow', 'hidden');
      },
      close: function(event, ui) {
        $('body').first().css('overflow', ' auto');
        self.clearPDFPlugin();
        // console.log('this.afterProcess')
        self.afterProcess();
      }
    };

    this.showDialog('pdfTallyListReport', this.newDialogOpt);

  }

  clearPDFPlugin() {
    $('#pdfTallyListReport').find('#pdfTallyListReport-viewer').empty();
    this.closeDialog('pdfTallyListReport');
  }

  showDialog(id: string, options: any) {
    $('#' + id).dialog(options).dialog('open');
  }

  closeDialog(id: string) {
    $('#' + id).dialog('close');
    $('#' + id).dialog('destroy');
  }
   /** End Shanghai Tally List EDI information Handler **/


  showErrorMessage(regexName, pastedText, field){
    const fileName = field === 'ediFileName';
    if (pastedText && regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${fileName} 'Only accept alphanumeric and -./'`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  showMessage(message: string) {
    this.message('information', 'Information', message, 'okonly', { ok: 'this.loading=false;' });
  }

  checkOneRowEvent(sNo:number){
    this.gridChinaTallyListEDIInfo.listStore.store.forEach((el) => {
      if(sNo!=el["no"]  && el['select']=='checked'){
        el['select']='';
        this.gridChinaTallyListEDIInfo.onSelect(false,el["no"]);
      }

      if(sNo==el["no"] && el['select']==''){
        el['select']='checked';
        this.gridChinaTallyListEDIInfo.onSelect(true,el["no"]);
      }


    });

    this.model.fileName=this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["fileName"];
    this.model.vesselName=this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["vesselName"];
    this.model.voyageNo=this.gridChinaTallyListEDIInfo.listStore.store[sNo-1]["voyageNo"];
    this.model.acceptContainers=[];
    this.model.rejectContainers=[];
  }

  convertToDateString(fileDate: string): string {
    return moment(fileDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
  }


}
