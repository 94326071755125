export class BLPrint{
  no : number = 0;
  sessionId    : string = '';
  blOfficeCode  : string = '';
  blNo  : string = '';
  result : string ='';
 



  constructor(){

  }
}
