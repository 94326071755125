<form class="ui raised segment">
  <button class="ui button" style="display:none;"></button>
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <form class="ui form">
    <div class="field">
      <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
    </div>  
  </form>
</form>


<footer-plugin [info]="info"></footer-plugin>
