<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <a class="ui red ribbon label">Stage 1 : Receive Tally List</a>

        <div class="field" style="margin-top: 5px">
        <div *ngIf="loading" class="spinner"></div>

        <ag-grid-angular
              id="grid"
              [getRowStyle]="getRowStyle"
              style="width: 100%; height: 500px; font-family: Lucida Console; font-size: 13px;"
              class="ag-theme-balham"
              [rowData]="rowData"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              (gridReady)="onGridReady($event)">
        </ag-grid-angular>
        </div>

      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
