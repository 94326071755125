import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-report-operation-preliminary-container-loading-list-page',
  templateUrl: './report-operation-preliminary-container-loading-list-page.component.html',
  styleUrls: ['./report-operation-preliminary-container-loading-list-page.component.css']
})
export class ReportOperationPreliminaryContainerLoadingListPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPOL;
  settingPOD;
  settingTrans;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Preliminary Container Loading List Report',
    }

     this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

     this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

     this.settingTrans = {
      id          : 'cbTrans',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

     this.settingPOL = {
      id          : 'cbPOL',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

     this.settingPOD = {
      id          : 'cbPOD',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventVessel(event){
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+"O"+"/{query}/"+this.cookieService.getDefaultLocationCode());
  }

  changeEventVoyage(event){}
  changeEventPOL(event){}
  changeEventPOD(event){}
  changeEventTrans(event){}
}
