<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field" style="width:400px">
       <label>Vessel Name:</label>
       <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Voyage:</label>
        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Of Load:</label>
        <combo-plugin #cbPOL [settings]="settingPOL" (change)="changeEventPOL($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Of Discharge:</label>
        <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Transhipment Code:</label>
        <combo-plugin #cbTrans [settings]="settingTrans" (change)="changeEventTrans($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>In Slot:</label>
        <input type="text" name="inSlot"  placeholder="Please Enter In Slot" [(ngModel)]="model['bound']" >
      </div>
      <div class="field" style="width:400px">
        <label>Operator Code:</label>
        <input type="text" name="opCode"  placeholder="Please Enter Operator Code" [(ngModel)]="model['bound']" >
      </div>
      <div class="field" style="width:400px">
        <label>Report Type :</label>
          <div class = "field">         
            <div class="ui radio checkbox" >
              <input type="checkbox"  name="pdf" tabindex="0"> 
              <label>PDF</label>
            </div> 
          </div>
          <div class="field">    
            <div class="ui radio checkbox" >
              <input type="checkbox"  name="excel" tabindex="0" >
              <label>Excel</label>
            </div>              
          </div>   
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
