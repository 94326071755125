export class BookingTransfer{

  vesselIdOrigin:string='';
  voyageOrigin:string='';
  boundOrigin:string='';

  vesselIdDestination:string='';
  voyageDestination:string='';
  boundDestination:string='';
  vesselCodeDestination:string='';

  potCode:string='';
  podCode:string='';

  bWeight:number=0;
  bMeasure:number=0;

  bDetentionRequest : number=0;
  bDemurrageRequest:number=0;
  bWt:number=0;

  bOrphanFlag:string='';


  constructor(){

  }
}
