

export class Users {
    no : number = 0;
    userId   : string = '';
    userFullName  : string = '';
    userSName   : string = '';
    userEmail  : string = '';
    userPassword  : string = '';
    userFailCount   : number = 0;
    userLastLoginLocation   : string = '';
    userOffice  : string = '';
    userStatus  : string = '';
    isLogin : string='';
    lastLoginDateTime   : string = '';
    lastLoginFailDateTime   : string = '';
    confirmPassword : string = '';
    
    isValid         : boolean = true;
    remarks       : string = '';
    dateCreated  : string ='';
    userCreated  : string ='';
    dateModified : string ='';
    userModified : string ='';
    dateInvalid  : string ='';
    userInvalid  : string ='';

    userLocation : any = [];
    mapGroup : any = [];
    mapMenu : any = [];
    mapAction: any = [];

    validatorMatchPassword : boolean = true;
    passwordValidasi : boolean = true;

    formatEmail : boolean = true;

    to : string = '';
    subject : string = '';
    text : string = '';

    isNotify : boolean = true;
    userPhone : string = '';

    constructor() {

    }
}
