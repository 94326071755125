<div class="ui segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div
    id="container"
    [style.display]="modeForm == 'sailingScheduleFlagStatus' ? 'block' : 'none'"
  >
    <div
      class="ui inverted dimmer"
      [class.active]="loading == true"
      style="z-index: 99"
    >
      <div class="ui medium text loader">Loading</div>
    </div>

    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display: none"></button>

      <div class="field">
        <div class="three fields">
          <div
            class="field"
            [class.error]="
              model['error-vesselId'] != null && model['error-vesselId'] != ''
            "
          >
            <label>Vessel</label>
            <combo-plugin
              #cbVessel
              [settings]="settingVessel"
              (change)="changeEventVessel($event)"
              (keypress)="CharNumOnly($event)"
              style="width: 100%"
              (paste)="
                onPasteValidation(
                  $event,
                  'vesselId',
                  formValidation.regVesVoy,
                  '.-/'
                )
              "
            >
            </combo-plugin>
            <div
              class="
                ui
                basic
                red
                pointing
                prompt
                label
                transition
                error-message-hidden
              "
              [class.error-message-visible]="
                model['error-vesselId'] != null && model['error-vesselId'] != ''
              "
            >
              {{ model["error-vesselId"] }}
            </div>
          </div>
          <div class="field">
            <label>Voyage</label>
            <combo-plugin
              #cbVoyage
              [settings]="settingVoyage"
              (change)="changeEventVoyage($event)"
              (keypress)="CharNumOnly($event)"
              (keyup.enter)="handleEnter()"
              (paste)="
                onPasteValidation(
                  $event,
                  'voyage',
                  formValidation.regVesVoy,
                  '.-/'
                )
              "
              style="width: 100%"
            ></combo-plugin>
            <div
              class="
                ui
                basic
                red
                pointing
                prompt
                label
                transition
                error-message-hidden
              "
              [class.error-message-visible]="
                model['error-voyage'] != null && model['error-voyage'] != ''
              "
            >
              {{ model["error-voyage"] }}
            </div>
          </div>
          <div
            class="field"
            [class.error]="
              model['error-bound'] != null && model['error-bound'] != ''
            "
          >
            <label>Bound</label>
            <input
              #sailingBound
              list="bound"
              name="bound"
              placeholder="Bound"
              [(ngModel)]="model['bound']"
              (change)="changeEventBound($event)"
              (keypress)="OIMonly($event)"
              maxlength="1"
              required
              autocomplete="off"
            />
            <datalist id="bound">
              <option value="O"></option>
              <option value="I"></option>
              <option value="M"></option>
            </datalist>

            <div
              class="
                ui
                basic
                red
                pointing
                prompt
                label
                transition
                error-message-hidden
              "
              [class.error-message-visible]="
                model['error-bound'] != null && model['error-bound'] != ''
              "
            >
              {{ model["error-bound"] }}
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <grid-plugin
          #gridHeader
          [settings]="settingGridHeader"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>

      <div class="field">
        <div class="twelve wide fields">
          <div class="one field" style="width: 68%">
            <grid-plugin
              #gridSailingDetail
              [settings]="settingGridSailingDetail"
              (gridEvent)="gridEventDetails($event)"
              (infoGrid)="infoGridDetails($event)"
            ></grid-plugin>
          </div>

          <div class="one field" style="width: 32%">
            <grid-plugin
              #gridSailingDetail2
              [settings]="settingGridSailingDetail2"
              (gridEvent)="gridEventDetails2($event)"
              (infoGrid)="infoGridDetails2($event)"
            ></grid-plugin>
          </div>
        </div>
      </div>

      <!--
          <div class="field" style="width:100%;">
            <div class="sixteen fields" style="padding-left:15px; padding-right: 15px; width:100%;">
              <div class="ten fields"style="width:80%;">
                  <div>
                   </div>
              </div>
              <div class="three fields" style="width:29%; margin-left: 10px">
                  <div>
                  </div>
              </div>
            </div>
          </div>
          -->
    </form>
  </div>
  <!-- end div container -->

  <!--div id="sailingScheduleMaintenance" class="ui fullscreen modal" style="margin-right:400px;padding:1%;"-->
  <!--Start Modal Popup Sailing Schedule -->
  <div
    id="sailingScheduleMaintenance"
    [style.display]="
      modeForm == 'sailingScheduleMaintenance' ? 'block' : 'none'
    "
  >
    <form class="ui form">
      <fieldset style="border: 1px solid lightgray">
        <legend style="font-weight: bold; color: dodgerblue">New</legend>
        <div class="field">
          <div class="field">
            <button class="ui positive button" (click)="onProceed()">OK</button>
            <button class="ui negative button" (click)="hideMe()">
              Cancel
            </button>
            <!--button class="ui button" (click)="setAllocation()">Set Allocation</button-->
            <button
              class="ui button"
              [class.disabled]="model['bound'] == 'M' || model['bound'] == 'I'"
              (click)="stopBooking()"
            >
              Stop Booking
            </button>
          </div>
        </div>

        <div class="field">
          <div class="four fields">
            <div
              class="field"
              [class.error]="
                model['error-vesselId'] != null && model['error-vesselId'] != ''
              "
            >
              <label>Vessel Name</label>
              <combo-plugin
                #cbNewVessel
                [settings]="settingNewVessel"
                (change)="changeEventNewVessel($event)"
                [disableCombo]="lockForm == true || lockFormBoundM == true"
                style="width: 100%"
                (keypress)="CharNumOnly($event)"
                (paste)="
                  onPasteValidation(
                    $event,
                    'vesselId',
                    formValidation.regVesVoy,
                    '.-/'
                  )
                "
              ></combo-plugin>
              <!--input type="text" name="vesselName"  placeholder=""  [(ngModel)]="model['vesselName']"-->
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselId'] != null &&
                  model['error-vesselId'] != ''
                "
              >
                {{ model["error-vesselId"] }}
              </div>
            </div>
            <div
              class="field"
              [class.error]="
                model['error-voyage'] != null && model['error-voyage'] != ''
              "
            >
              <label>In House Voyage</label>
              <input
                type="text"
                name="voyage"
                placeholder=""
                [disabled]="lockForm == true || lockFormBoundM == true"
                [(ngModel)]="model['voyage']"
                (ngModelChange)="
                  checkIfError('voyage', formValidation.regVesVoy)
                "
                maxlength="20"
                (keypress)="CharNumOnly($event)"
                (paste)="
                  onPasteValidation(
                    $event,
                    'voyage',
                    formValidation.regVesVoy,
                    '.-/'
                  )
                "
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-voyage'] != null && model['error-voyage'] != ''
                "
              >
                {{ model["error-voyage"] }}
              </div>
            </div>
            <div
              class="field"
              [class.error]="
                model['error-psaVoyage'] != null &&
                model['error-psaVoyage'] != ''
              "
            >
              <label>PSA Voyage</label>
              <input
                type="text"
                name="psaVoyage"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['psaVoyage']"
                (ngModelChange)="
                  checkIfError('psaVoyage', formValidation.regVesVoy)
                "
                maxlength="20"
                (keypress)="CharNumOnly($event)"
                (paste)="
                  onPasteValidation(
                    $event,
                    'psaVoyage',
                    formValidation.regVesVoy,
                    '.-/'
                  )
                "
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-psaVoyage'] != null &&
                  model['error-psaVoyage'] != ''
                "
              >
                {{ model["error-psaVoyage"] }}
              </div>
            </div>
            <div class="field">
              <div class="two wide fields">
                <div
                  class="field"
                  [class.error]="
                    model['error-bound'] != null && model['error-bound'] != ''
                  "
                >
                  <label>Bound</label>
                  <input
                    type="text"
                    name="bound"
                    placeholder=""
                    [disabled]="lockForm"
                    [(ngModel)]="model['bound']"
                    readonly
                    style="background-color: lightgray"
                    maxlength="8"
                  />
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-bound'] != null && model['error-bound'] != ''
                    "
                  >
                    {{ model["error-bound"] }}
                  </div>
                </div>

                <div class="field">
                  <div class="ui test toggle checkbox">
                    <input
                      type="checkbox"
                      name="nonSSl"
                      tabindex="0"
                      class="hidden"
                    />
                    <label>NON SSL Service</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="four fields">
            <div
              class="field"
              [class.error]="
                model['error-serviceId'] != null &&
                model['error-serviceId'] != ''
              "
            >
              <label>Service Name</label>
              <!--input type="text" name="serviceName"  placeholder="" [(ngModel)]="model['serviceName']" -->
              <combo-plugin
                #cbNewService
                [settings]="settingNewService"
                [disableCombo]="lockForm == true || lockFormBoundM == true"
                (change)="changeEventNewService($event)"
                style="width: 100%"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-serviceId'] != null &&
                  model['error-serviceId'] != ''
                "
              >
                {{ model["error-serviceId"] }}
              </div>
            </div>
            <div class="field">
              <label>Service Code</label>
              <input
                type="text"
                name="serviceCode"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['serviceCode']"
                readonly
                style="background-color: lightgray"
              />
            </div>
            <div class="field">
              <label>Agent</label>
              <input
                type="text"
                name="agent"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['agent']"
                maxlength="7"
              />
            </div>
            <div class="field">
              <label>Leg</label>
              <input
                type="text"
                name="leg"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['leg']"
                maxlength="1"
              />
            </div>
          </div>
        </div>

        <div class="field">
          <div class="four fields">
            <div class="field">
              <label>Name of Master</label>
              <input
                type="text"
                name="nameOfMaster"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['nameOfMaster']"
                maxlength="30"
              />
            </div>
            <div class="field">
              <label>Nationality</label>
              <input
                type="text"
                name="nationality"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['nationality']"
                maxlength="30"
              />
            </div>
            <div class="field">
              <label>GDV. No.</label>
              <input
                type="text"
                name="gdvNumber"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['gdvNumber']"
                maxlength="6"
              />
            </div>
            <div class="field">
              <label>Calling Seq.</label>
              <input
                type="text"
                name="callingSeq"
                placeholder=""
                [disabled]="lockForm"
                [(ngModel)]="model['callingSeq']"
                maxlength="1"
              />
            </div>
          </div>
        </div>

        <div class="field">
          <div class="four fields">
            <div
              class="field"
              [class.error]="
                model['error-localETA'] != null && model['error-localETA'] != ''
              "
            >
              <label>Local ETA</label>
              <datetime-plugin
                #dateLocalETA
                [settings]="settingDateLocalETA"
                [disableDatetime]="lockLocalETA"
              ></datetime-plugin>
              <!--input type="text" name="localETA"  placeholder="" [(ngModel)]="model['localETA']" maxlength="20"-->
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-localETA'] != null &&
                  model['error-localETA'] != ''
                "
              >
                {{ model["error-localETA"] }}
              </div>
            </div>
            <div
              class="field"
              [class.error]="
                model['error-localETD'] != null && model['error-localETD'] != ''
              "
            >
              <label>Local ETD</label>
              <datetime-plugin
                #dateLocalETD
                [settings]="settingDateLocalETD"
                [disableDatetime]="lockLocalETD"
              ></datetime-plugin>
              <!--input type="text" name="localETD"  placeholder="" [(ngModel)]="model['localETD']" maxlength="20"-->
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-localETD'] != null &&
                  model['error-localETD'] != ''
                "
              >
                {{ model["error-localETD"] }}
              </div>
            </div>
            <div
              class="field"
              [class.error]="
                model['error-blDate'] != null && model['error-blDate'] != ''
              "
            >
              <label>BL Date</label>
              <datetime-plugin
                #blDate
                [settings]="settingBLDate"
                (change)="changeEventLocalETD($event)"
              ></datetime-plugin>
              <!--input type="text" name="blDate"  placeholder=""  [(ngModel)]="model['blDate']" maxlength="20"-->
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-blDate'] != null && model['error-blDate'] != ''
                "
              >
                {{ model["error-blDate"] }}
              </div>
            </div>
            <div
              class="field"
              [class.error]="
                model['error-exchangeRateDate'] != null &&
                model['error-exchangeRateDate'] != ''
              "
            >
              <label>Exch. Rate Date</label>
              <datetime-plugin
                #exchangeRateDate
                [settings]="settingExchangeRateDate"
                (change)="changeEventexchangeRateDate($event)"

              ></datetime-plugin>
              <!--input type="text" name="exchangeRateDate"  placeholder="" [(ngModel)]="model['exchangeRateDate']" maxlength="20"-->
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-exchangeRateDate'] != null &&
                  model['error-exchangeRateDate'] != ''
                "
              >
                {{ model["error-exchangeRateDate"] }}
              </div>
            </div>
          </div>
        </div>

        <div class="four fields">
          <div class="field">&nbsp;</div>
          <div class="field">&nbsp;</div>
          <div class="field" [class.disabled]="model['bound'] == 'I'">
            <div class="ui test checkbox">
              <input
                [checked]="model['lockedBlDate'] == 'Y'"
                value="Y"
                (change)="changeEventLockedBLDate($event)"
                type="checkbox"
                name="lockedBlDate"
                tabindex="0"
              />
              <label>Lock BL Date</label>
            </div>
          </div>
          <div class="field">
            <div class="ui test checkbox">
              <input
                [checked]="model['lockedExchangeRateDate'] == 'Y'"
                value="Y"
                (change)="changeEventLockedExchangeRateDate($event)"
                type="checkbox"
                name="lockedExchangeRateDate"
                tabindex="0"
              />
              <label>Lock Exchange Rate Date</label>
            </div>
          </div>
        </div>

        <div class="field">
          <div
            class="twelve fields"
            style="padding-left: 15px; padding-right: 15px"
          >
            <div class="eight fields">
              <fieldset style="border: 1px solid lightgray">
                <legend style="font-weight: bold; color: dodgerblue">
                  Sailing Details
                </legend>
                <grid-plugin
                  #gridSailingDetailModal
                  [settings]="settingGridSailingDetailModal"
                  (gridEvent)="gridEventDetailsModal($event)"
                  (infoGrid)="infoGridDetails($event)"
                ></grid-plugin>
              </fieldset>
            </div>
            <div class="field">&nbsp;</div>
            <div class="three fields">
              <fieldset style="border: 1px solid lightgray">
                <legend style="font-weight: bold; color: dodgerblue">
                  Special Exchange Rate
                </legend>
                <grid-plugin
                  #gridSailingDetailSpecial
                  [settings]="settingGridSailingDetailSpecial"
                  (gridEvent)="gridEventDetails2($event)"
                  (infoGrid)="infoGridDetails2($event)"
                ></grid-plugin>
              </fieldset>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <!-- End Modal Popup Sailing Schedule -->
</div>
<!-- End of UI Segment -->

<div
  id="sailingScheduleVerification"
  style="display: none; font-family: Lucida Console, monospace"
>
  <!-- Start Modal Verification -->
  <form class="ui form">
    <fieldset style="border: 1px solid lightgray">
      <legend style="font-weight: bold; color: dodgerblue">Verification</legend>
      <div class="field">
        <div
          class="field"
          [class.error]="
            errorStopBookingPassword != null && errorStopBookingPassword != ''
          "
        >
          <label>Password</label>
          <input
            type="password"
            name="stopBookingPassword"
            id="stopBookingPassword"
            placeholder=""
            [(ngModel)]="stopBookingPassword"
            maxlength="20"
          />
          <div
            class="
              ui
              basic
              red
              pointing
              prompt
              label
              transition
              error-message-hidden
            "
            [class.error-message-visible]="
              errorStopBookingPassword != null && errorStopBookingPassword != ''
            "
            (keypress)="triggerbuttonClick($event)"
          >
            {{ errorStopBookingPassword }}
          </div>
        </div>
        <div class="field">
          <button
            id="buttonStopBooking"
            class="ui positive button"
            (click)="openStopBookingWindow()"
          >
            OK
          </button>
          <button class="ui negative button" (click)="hideVerificationWindow()">
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- End Modal Verifivation -->

<div
  id="resumeScheduleVerification"
  style="display: none; font-family: Lucida Console, monospace"
>
  <!-- Start Modal Verification -->
  <form class="ui form">
    <fieldset style="border: 1px solid lightgray">
      <legend style="font-weight: bold; color: dodgerblue">Verification</legend>
      <div class="field">
        <div
          class="field"
          [class.error]="
            errorStopBookingPasswordResume != null &&
            errorStopBookingPasswordResume != ''
          "
        >
          <label>Password</label>
          <input
            type="password"
            name="stopBookingPasswordResume"
            placeholder=""
            [(ngModel)]="stopBookingPasswordResume"
            maxlength="20"
          />
          <div
            class="
              ui
              basic
              red
              pointing
              prompt
              label
              transition
              error-message-hidden
            "
            [class.error-message-visible]="
              errorStopBookingPasswordResume != null &&
              errorStopBookingPasswordResume != ''
            "
          >
            {{ errorStopBookingPasswordResume }}
          </div>
        </div>
        <div class="field">
          <button
            id="okStopBookingResume"
            class="ui positive button"
            (click)="openResumeBookingWindow()"
          >
            OK
          </button>
          <button
            class="ui negative button"
            (click)="hideVerificatioResumenWindow()"
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- End Modal Verifivation -->

<div
  id="stopBookingMaintenance"
  style="display: none; font-family: Lucida Console, monospace"
>
  <!-- Start Modal Stop Booking -->
  <form class="ui form">
    <div class="field">
      <!--fieldset style="border: 1px solid lightgray; width: 100%"> -->
      <legend style="font-weight: bold; color: dodgerblue">Stop Booking</legend>
    </div>
    <div class="field">
      <div class="field">
        <button class="ui button" (click)="addStopBookingRow()">
          <i class="plus icon"></i>New
        </button>
        <button
          class="ui positive button"
          (click)="actionSave()"
          [class.disabled]="rowStop == 0"
        >
          Save
        </button>
        <button class="ui negative button" (click)="hide2()">Close</button>
        <button class="ui button" (click)="actionPrintStop()">
          <i class="file outline icon"></i>Print
        </button>
        <h2 style="float: right; margin-top: 10px; margin-right: 20px">
          {{ voyageVess }}
        </h2>
      </div>

      <div class="field" style="width: 100%">
        <grid-plugin
          #gridStopBooking
          id="detail-section"
          [settings]="settingGridStopBooking"
          (gridEvent)="eventGridStopBooking($event)"
          (infoGrid)="infoGridStopBooking($event)"
        ></grid-plugin>
      </div>
      <!--
        <div class="field" [class.error]="errorStopBookingPassword != null && errorStopBookingPassword != ''" >
          <label>Password</label>
          <input type="password" name="stopBookingPassword"  placeholder=""  [(ngModel)]="stopBookingPassword" maxlength="20" (keyup)="resetErrorPassword($event)">
          <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="errorStopBookingPassword != null && errorStopBookingPassword != ''">
            {{errorStopBookingPassword}}
          </div>
        </div>
-->
    </div>
    <!--/fieldset-->
  </form>
</div>
<!-- End Modal Stop Booking -->

<div
  id="printSailingSchedule"
  class="ui small modal"
  style="margin-right: 400px; padding: 1%"
>
  <!-- Start Modal Print Sailing Shchedule -->
  <form class="ui form">
    <div class="field">
      <!--fieldset style="border: 1px solid lightgray; width: 100%"> -->
      <legend style="font-weight: bold; color: dodgerblue">
        Print Sailing Schedule
      </legend>
    </div>
    <div class="field">
      <div class="field">
        <button class="ui positive button" (click)="printSailingSchedule()">
          OK
        </button>
        <button class="ui negative button" (click)="hidePrintSailingSchedule()">
          Cancel
        </button>
      </div>
    </div>
    <div class="two fields">
      <div class="field">
        <fieldset style="border: 1px solid lightgray">
          <legend style="font-weight: bold; color: dodgerblue">
            Vessel Type
          </legend>
          <div class="grouped field">
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="vesselType" checked="checked" />
                <label>All</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="vesselType" />
                <label>SSL Vessel</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="vesselType" />
                <label>Outside Vesel</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="vesselType" />
                <label>Inward Vesel</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="vesselType" />
                <label>Outward Vesel</label>
              </div>
            </div>
          </div>
        </fieldset>
        <br />
        <fieldset style="border: 1px solid lightgray">
          <legend style="font-weight: bold; color: dodgerblue">Sort By</legend>
          <div class="grouped field">
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="sortBy" checked="checked" />
                <label>Vessel</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="sortBy" />
                <label>Service</label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="field">
        <fieldset style="border: 1px solid lightgray">
          <legend style="font-weight: bold; color: dodgerblue">
            Date Range (dd/mm/yyyy)
          </legend>
          <div class="field">
            <label>From</label>
            <datetime-plugin
              #exchangeRateDate
              [settings]="settingDateLocalETA"
            ></datetime-plugin>
          </div>
          <div class="field">
            <label>To</label>
            <datetime-plugin
              #exchangeRateDate
              [settings]="settingDateLocalETA"
            ></datetime-plugin>
          </div>
        </fieldset>
      </div>
    </div>

    <!--/fieldset-->
  </form>
</div>
<!-- End Modal Stop Booking -->

<!-- Form Schedule Mapping -->
<div
  id="scheduleMappingForm"
  style="display: none; font-family: Lucida Console, monospace"
>

<div class="ui inverted dimmer" [class.active]="isLoadingValidationOfMappingSchedule == true" style="z-index: 99">
  <div class="ui medium text loader">Loading</div>
</div>

  <div class="field">
    <button
      class="ui button green"
      style="font-size: 10px"
      (click)="onScheduleMapping_Save()"
      [class.disabled]="btnSaveMapping == true"
    >
      Save
    </button>
    <button
      class="ui button red"
      style="font-size: 10px"
      (click)="onScheduleMapping_Delete()"
      [class.disabled]="btnDeleteMapping == true"
    >
      Delete
    </button>
    <button
      class="ui button"
      style="font-size: 10px"
      (click)="onScheduleMapping_Cancel()"
    >
      Cancel
    </button>
  </div>

  <div
    class="ui error message"
    [class.hidden]="isErrorScheduleMapping == false"
  >
    <ul>
      <li
        [class.hidden]="
          modelMapping['error-mappingSourceVesselCode'] == null ||
          modelMapping['error-mappingSourceVesselCode'] == ''
        "
      >
        {{ modelMapping["error-mappingSourceVesselCode"] }}
      </li>
      <li
        [class.hidden]="
          modelMapping['error-mappingSourceVoyage'] == null ||
          modelMapping['error-mappingSourceVoyage'] == ''
        "
      >
        {{ modelMapping["error-mappingSourceVoyage"] }}
      </li>
      <li
        [class.hidden]="
          modelMapping['error-mappingFromSource'] == null ||
          modelMapping['error-mappingFromSource'] == ''
        "
      >
        {{ modelMapping["error-mappingFromSource"] }}
      </li>
    </ul>
  </div>

  <div class="ui top attached tabular menu" style="background-color: beige">
    <a
      class="item"
      [class.active]="tabStatus == 'tab1'"
      data-tab="Schedule Mapping"
      (click)="initTab1()"
      >Schedule Mapping</a
    >
  </div>

  <div
    class="ui form bottom attached tab segment"
    [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
    id="ScheduleMappingTab"
    data-tab="Schedule Mapping"
  >
    <div class="field">
      <div class="fields">
        <div class="three wide field">
          <div class="ui radio checkbox" 
            style="margin-right: 20px"
            [class.disabled]="true == true"
          >
            <input
              [checked]="modelMapping['mappingFromSource'] == 'SSS'"
              (change)="changeEventScheduleOption($event)"
              value="SSS"
              name="mapping"
              type="radio"
              tabindex="0"
              class="hidden"
              
            />
            <label style="font-weight: bold">SSS</label>
          </div>
        </div>

        <div class="three wide field">
          <div class="ui radio checkbox">
            <input
              [checked]="modelMapping['mappingFromSource'] == 'D4A'"
              (change)="changeEventScheduleOption($event)"
              value="D4A"
              name="mapping"
              type="radio"
              tabindex="0"
              class="hidden"
            />
            <label style="font-weight: bold">SHA D4A</label>
          </div>
        </div>
        <!-- End Mapping Selection -->
      </div>

      <div class="fields" style="align-items: flex-end">
        <div class="seven wide field">
          <div class="required field">
            <label>Vessel</label>
            <combo-plugin
              #cbVesselScheduleMapping
              [settings]="settingVesselScheduleMapping"
              (change)="changeEventVesselScheduleMapping($event)"
              [disableCombo]="lockMapping == true"
              style="width: 100%"
            ></combo-plugin>
          </div>
        </div>
        <div class="seven wide field">
          <label>Voyage</label>
          <combo-plugin
            #cbVoyageScheduleMapping
            [settings]="settingVoyageScheduleMapping"
            (change)="changeEventVoyageScheduleMapping($event)"
            [disableCombo]="lockMapping == true"
            style="width: 100%"
          ></combo-plugin>
        </div>
        <div class="two wide field">
          <button
            class="ui button"
            [class.disabled]="btnAddMapping == true"
            (click)="onScheduleMapping_Add()"
            style="text-transform: uppercase; font-weight: bold"
          >
            add
          </button>
        </div>
      </div>

      <div class="fields">
        <div class="ten wide field">
          <ag-grid-angular
            #gridPrepaid2
            style="width: 100%; height: 400px"
            class="ag-theme-balham"
            id="gridPrepaid2"
            [enableSorting]="true"
            [enableFilter]="true"
            [enableColResize]="true"
            [rowData]="rowDataScheduleMapping"
            [rowSelection]="rowSelection"
            [rowMultiSelectWithClick]="true"
            [columnDefs]="columnDefsScheduleMapping"
            [pinnedBottomRowData]="pinnedBottomRowDataScheduleMapping"
            [animateRows]="true"
            [enableCellChangeFlash]="true"
            (gridReady)="onGridReadyScheduleMapping($event)"
            (selectionChanged)="onSelectionChanged($event)"
          >
          </ag-grid-angular>
        </div>
        <div class="two wide field">
          <button
            class="ui button"
            [class.disabled]="rowSelected.length === 0"
            style="text-transform: uppercase; font-weight: bold"
            (click)="onScheduleMapping_Remove()"
          >
            remove
          </button>
        </div>
      </div>
    </div>
    <!-- End Form Schedule Mapping -->

    <footer-plugin [info]="info"></footer-plugin>
  </div>
</div>

<!-- Message Dialog -->
<div
  id="msgDialog"
  style="
    display: none;
    font-family: Lucida Console, monospace;
    display: flex;
    flex: 1;
    flex-direction: column;
  "
>
<!--
  <form class="ui form" style="flex: 1; display: flex; flex-direction: column">
    
    <div
      class="field"
      style="
        margin-bottom: 5px;
        flex: 1;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      "
    >
    
      <table border="0">
        <tr>
          <td width="12%" style="text-align: left">
            <i class="info circle large icon"></i>
          </td>
          <td style="font-size: 13px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div
      class="field"
      style="
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      "
      *ngIf="confirmSaved === false"
    >
      <button
        class="ui button small"
        (click)="confirmMappingMsg()"
        style="font-size: 12px"
      >
        YES
      </button>
      <button
        class="ui button small"
        (click)="closeMsgDialog()"
        style="font-size: 12px"
      >
        NO
      </button>
    </div>

    <div
      class="field"
      style="
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      "
      *ngIf="confirmSaved === true"
    >
      <button
        class="ui button small"
        (click)="closeMsgDialog()"
        style="font-size: 12px"
      >
        OK
      </button>
    </div>
  </form>
-->
</div>
