<div class="container">
   <div class="ui grid search-box" style="justify-content: right !important;">
      <!--<div class="computer only row">-->
      <div class="row">
        <div class="column">
          <div class="ui segment">
            <div class="ui fluid action input">
              <input type="text" [(ngModel)]="txt" (keypress)="retrieve($event)" placeholder="Quick Search Vessel / IMO / MMSI">
              <div class="ui button" (click)="searchEvent($event)" >Search</div>
            </div>
          </div>
        </div>
      </div>
   </div>

   <div class="container-meta">
    <iframe
      id="myIframe"
      [src]="iframeUrl"
      frameborder="0"
      allowtransparency
    ></iframe>
   </div>
 <!--nothing here just test --->
  <!-- <div style="width:600px;">
    <div class="ui segment">

      <h3 class="ui header">
        <i class="tasks icon"></i>
        <div class="content">
          To do List
          <div class="sub header">What you need to do today</div>
        </div>
      </h3>

      <div class="ui relaxed list">
        <div class="item">
          <i class="exclamation icon large" style="color:red;"></i>
          <div class="content">
            <a class="header">Freight List</a>
            <div class="description">Please print freight list for vessel <a><b>PAC CERGAS V.147</b></a></div>
          </div>
        </div>
        <div class="item">
          <i class="exclamation icon large" style="color:red;"></i>
          <div class="content">
            <a class="header">Manifest Report</a>
            <div class="description">Manifest for vessel <a><b>PAC CERGAS V.147</b></a> not yet printed</div>
          </div>
        </div>

      </div>
    </div>
  </div> -->





</div>

<footer-plugin></footer-plugin>


