<div class="ui segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
    <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"> 
        <div class="ui medium text loader">Loading</div>
    </div>

    <form class="ui form">
        <div class="sixteen wide fields">
            <div class="four wide field" [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                <label>Vessel</label>
                <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                    [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                    {{model['error-vesselName']}}
                </div>
            </div>
            <div class="four wide field" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
                <label>Voyage</label>
                <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                    [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
                    {{model['error-voyage']}}
                </div>
            </div>
            <div class="eight wide field">
                <!-- label>Test</label>
                <combo-plugin #comboTest [settings]="settingTest" [disableCombo]="testLock"  (change)="testEvent($event)"></combo-plugin -->
                &nbsp;    
            </div>            
        </div>
        <grid-plugin id="gridHeader" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
    </form>

</div>
<footer-plugin [info]="info"></footer-plugin>
