import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService, Validator } from 'sibego-ui-library';

@Component({
  selector: 'app-transaction-receipt-page-export-coda',
  templateUrl: './transaction-receipt-page-export-coda.component.html',
  styleUrls: ['./transaction-receipt-page-export-coda.component.css']
})
export class TransactionReceiptPageExportCodaComponent extends Validator implements OnInit {

  @Output() closeExportContent = new EventEmitter<any>();

  settingToolbar;
  modeToolbar = false;
  isExportContent = false;
  settingGrid;
  settingGridHistory;
  /* Parameter for information into Footer */
  info = '';
  validatorRules = {};

  constructor(
    private router: Router,
    private configService: ConfigService
  ) {
    super();
    this.settingToolbar = {
      buttonsFront: [
        { name: 'Export', event: 'export', icon: 'power' },
        { name: 'Cancel', event: 'cancel', icon: 'power' },
        { name: 'Close', event: 'close', icon: 'power' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: ''
    }
    this.settingGrid = {
      url: '',
      page: 10,
      columns: [
        { header: 'Customer Name', field: '', width: 120 },
        { header: 'Reference No', field: '', width: 100 },
        { header: 'Currency', field: '', width: 120 },
        { header: 'Amount', field: '', width: 120 },
      ],
      buttons: [{ name: 'Edit', event: 'edit', enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: '',
      sortingDirection: 'ASC'
    }
  }
  ngOnInit() {
  }

  toolbarEvent(event) {
    switch (event) {
      case 'export':
        break;
      case 'cancel':
        this.closeExportContent.emit("close");
        break;
      case 'close':
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  handleExport() {
    this.isExportContent = true;
  }

  retrieveHandel() {
    alert("Comming Soon")
  }

  gridEvent(event: any) {
    //// console.log(event);

  }

  infoGrid(event: any) {
    this.info = event;
  }

}
