export class Package{

    cdCode : string = '';
    cdName : string = ''; 
    cdCreatedUserId : string = '';
    cdCreateDate : Date;
    cdLastModifiedUser : string = '';
    cdLastModifiedDate : Date;
    cdIsValid : string = '';

    constructor() { 
      
    }
}