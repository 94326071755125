import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService,CalendarPluginComponent, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-report-operation-daily-booking-progress-page',
  templateUrl: './report-operation-daily-booking-progress-page.component.html',
  styleUrls: ['./report-operation-daily-booking-progress-page.component.css']
})

export class ReportOperationDailyBookingProgressPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  // @ViewChild('dateLocalETA') dateLocalETA:CalendarPluginComponent;
  // @ViewChild('dateLocalETD') dateLocalETD:CalendarPluginComponent;
  @ViewChild('cbPOC') cbPOC:ComboPluginComponent;
  @ViewChild('dailyBookingProcessPDFContainer') pdfContainer: PdfPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPOC;
  settingDatePicker;
  settingPDF;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model:reportParam;
  hitUrl = this.configService.config.BASE_API.toString();
  user = '';

  validatorRules = {};

  officeCode = '';

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Daily Booking Progress Report'
    }

    this.settingPOC = {
      id          : 'cbPOC',
      type        : 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + "/MasterLocations/findByComboBoxControl/locationName={query}",
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingDatePicker = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingPDF = {
      id : 'dbProgressReportPDFCntry',
      url : '',
      hidePrint : false,
    };

  }
  ngOnInit() {
    this.officeCode = this.cookieService.getDefaultLocationCode().substring(0, 2);
    this.checkedReportTypeByOfficeCode();
  }

  // GOS-452 To Disable PDF Function, Applicable for SIN Site only.
  checkedReportTypeByOfficeCode(){
    this.model = new reportParam;
    if(this.officeCode === 'SG'){
      this.checkedRadio("xls", true);
      this.model.reportType = 'XLS';
    }else{
      this.checkedRadio("pdf", true);
      this.model.reportType = 'PDF';
    }
  }

  ngAfterViewInit() {
    // this.dateLocalETA.initCalendar("");
    // this.dateLocalETD.initCalendar("");
    this.user = this.cookieService.getName();
    this.model = new reportParam;
    this.checkedReportTypeByOfficeCode();

    var self = this;
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    }).on('pick.datepicker', function(e){
      //console.log('calendar hide');
      //console.log(e);
      if(e.target.id == 'fromDate'){
        //console.log('fromdate ' + $(this).datepicker('getDate', true));
        self.setDate('from', $(this).datepicker('getDate', true));
      } else if (e.target.id == 'toDate') {
        //console.log('toDate ' + $(this).datepicker('getDate', true));
        self.setDate('to', $(this).datepicker('getDate', true));
      }
    });
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'print' :
        this.printDailyBookingProgress();
        break;
      case "cancel" :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventPOC(event){
    if(event.locationId != ""){
      this.model.placeOfCreation = event.locationCode;
    } else {
      this.model.placeOfCreation = '';
    }
  }

  eventRadio(event,val){
    //console.log(event);
    var checked = event.srcElement.checked;

    if(checked){
      this.model.reportType = val;
    }

  }

  setDate(type, val){
    if(type == "from"){
      this.model.fromDate = val + " 00:12:00";
    } else if(type == "to"){
      this.model.toDate = val + " 00:12:00";
    }
  }

  printDailyBookingProgress(){
    console.log("print");
    console.log(this.model);

    if(this.model.placeOfCreation == ''){
      this.message("information","Information","Please insert Place of creation", "okonly",{ok:""});
    } else if(this.model.fromDate == ""){
      this.message("information","Information","Please insert Vessel ETA From", "okonly",{ok:""});
    } else if(this.model.toDate == ""){
      this.message("information","Information","Please insert Vessel ETA TO", "okonly",{ok:""});
    } else {
      this.loading = true;
      var hitUri = this.hitUrl + "/BookingReport/GenBookingProgressReport/"+this.model.placeOfCreation+"/"+this.model.fromDate+"/"+this.model.toDate+"/"+this.user;
      this.genericService.GET(hitUri).subscribe((resp)=>{
        if(resp.ok){
          var result = resp.json();
          if(parseInt(result["total-data"]) > 0){
              var hitUrl = this.hitUrl  + '/edi-util/getFile';
              var jasperURL = this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/dailyBookingProgressReport.pdf?FromDate='+result['fromDate']+"&ToDate="+result['toDate']+"&keyCode="+this.user;
              var fileName = 'Daily-Booking-Progress-Report-'+this.user+'.pdf';

              var postData = {};
              postData["fileName"] = fileName;
              postData["url"] = jasperURL;

            if(this.model.reportType == "PDF"){
              this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
                if(resp.ok){
                  var result = resp.json();

                  if(result["message"] == "success"){
                    this.pdfContainer.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
                    this.pdfContainer.showPDF();
                  }

                  this.loading = false;
                }
              });
            } else if(this.model.reportType == "XLS"){
              jasperURL = jasperURL.replace(".pdf",".xls");
              window.open(jasperURL);
              this.loading = false;
            }
          } else {
            this.message('information','Information','No Data Found.', 'okonly',{ok:''});
            this.loading = false;
          }
        }
      });
    }
  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      //this.disableToolbarButtons = 'print';
    }
  }

  handleCancel(){
    this.model = new reportParam;
    this.cbPOC.setForceValue("");
    $("#fromDate").val("");
    $("#toDate").val("");
    this.checkedReportTypeByOfficeCode();
  };

  checkedRadio(val, checked:boolean){
    $("#rType[value="+val+"]").prop('checked', checked);
  }

}


export class reportParam{
  placeOfCreation:string = '';
  fromDate:string = '';
  toDate:string = '';
  userId:string = '';

  reportType:string = '';

  constructor(){
  }
}
