import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-setting-silent-printing-page',
  templateUrl: './setting-silent-printing-page.component.html',
  styleUrls: ['./setting-silent-printing-page.component.css']
})
export class SettingSilentPrintingPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingModule;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;
  lock:boolean=false;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'New', event: 'new', icon: 'add circle'},
        {name: 'Modify', event: 'modify', icon: 'edit'},
        {name: 'Delete', event: 'delete', icon: 'minus circle'},
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Lock Period'
    }

    this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral',
      page: 10,
      columns: [
        {header: 'Spooler Name', field: 'spooler', width: 200},
        {header: 'IP Address', field: 'ip', width: 200},
        {header: 'Remarks', field: 'reamrks', width: 300},
        {header: 'Module', field: 'module', width: 150},
        {header: 'Last Modified', field: 'lastModified', width: 150},
        {header: 'Deleted By', field: 'deletedBy', width: 150},
        {header: 'Date Deleted', field: 'dateDeleted', width: 150},
        {header: 'Is Deleted', field: 'isDeleted', width: 100},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'locationName',
      sortingDirection: 'ASC'
    }

     this.settingModule = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Module Name', field: 'moduleName', width: 100},
      ]
    }
  }
  ngOnInit() {
     $('.menu .item')
      .tab()
    ;
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  initTab1(){}
  initTab2(){}
  gridEvent(event){}
  infoGrid(event){}
  changeEventModule(event){}

}
