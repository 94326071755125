<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  >
  </toolbar-plugin>

  <div class="ui raised segment">
    <label for="" class="title-ts-config">Minimum Connection Limit</label>
    <div class="form-ts-config">
      <input
        type="number"
        [(ngModel)]="limitValue"
        (keypress)="validateNumberOnly($event)"
      />
      <span>Hours</span>
    </div>
  </div>

  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 99"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <dialog-plugin
    [settings]="dialog"
    (eventDialog)="eventMessage($event)"
  ></dialog-plugin>
</div>
