<div class="ui segment">
    <div class="ui divided items">
       <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

       <div [style.display]="modeForm?'none':'block'">
           <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Service</h4>
            <!-- Begin of Grid -->
            <grid-plugin #hgrid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
            <!-- End of Grid -->
        </div>
        <div [style.display]="modeForm?'block':'none'">
             <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master Service</h4>
            <div class="ui segment" style="padding-left:1%;padding-right:1%;">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                    <div class="ui medium text loader">Loading</div>
                </div>

                <div id='container'>
                    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
                    <!-- Begin of Form -->
                    <form class="ui form" [style.display]="form == ''?'block':'none'" >
                      <!--  <h4 class="ui dividing header">Master Service</h4>-->
                         <div class="field">
                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                                <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                                <label>Valid?</label>
                            </div>
                        </div>
                        <div class="ui styled accordion field" style="width:100%">
                            <div class="active title">
                                <i class="icon dropdown"></i>
                                General
                            </div>
                            <div class="active content">
                                <div class="four fields">
                                   <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('isSsl')" [checked]="isType == 'isSsl'">
                                                <label>SSL</label>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('isSlot')" [checked]="isType == 'isSlot'">
                                                <label>Slot</label>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('is3Rd')" [checked]="isType == 'is3Rd'">
                                                <label>3rd Party</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="two fields">
                                    <div class="required field" [class.error]="model['error-serviceName'] != null && model['error-serviceName'] != ''">
                                        <label>Service Name</label>
                                        <combo-plugin  #serName [settings]="settingService"  [disableCombo]="lock"  (change)="valueService($event)"
                                        [required]="{name:'error-serviceName',field:model['error-serviceName'],model:model}" style="width:100%;"></combo-plugin>
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-serviceName'] != null && model['error-serviceName'] != ''">
                                            <!--<p [innerHtml]="model['error-serviceName']"></p>-->
                                            {{model['error-serviceName']}}
                                        </div>
                                    </div>

                                    <div class="required field" [class.error]="model['error-serviceCode'] != null && model['error-serviceCode'] != ''">
                                        <label>Service Code</label>
                                        <input type="text" [(ngModel)]="model['serviceCode']" (ngModelChange)="model['error-serviceCode']=''" name="serviceCode" placeholder="Service Code" maxlength="8" [disabled]="lock == true" >
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-serviceCode'] != null && model['error-serviceCode'] != ''">
                                            <!--<p [innerHtml]="model['error-serviceCode']"></p>-->
                                            {{model['error-serviceCode']}}
                                        </div>
                                    </div>
                                    <button  class = "ui button" style="margin-top:19px"  (click)="retrieve();" tabindex="0">Retrieve </button>
                                </div>
                                <div class="three fields" style="width:91%">
                                    <div class="required field" [class.error]="model['error-psaServiceCode'] != null && model['error-psaServiceCode'] != ''">
                                        <label>PSA Service Code</label>
                                        <input type="text" [(ngModel)]="model['psaServiceCode']" (ngModelChange)="model['error-psaServiceCode']=''" name="psaServiceCode" [disabled]="lock == true" maxlength="8" placeholder="PSA Service Code">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-psaServiceCode'] != null && model['error-psaServiceCode'] != ''">
                                            <!--<p [innerHtml]="model['error-servicePsaCode']"></p>-->
                                            {{model['error-psaServiceCode']}}
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label>Seq</label>
                                        <input type="number" min="0" [(ngModel)]="model['serviceSequence']"  name="serviceSequence" [disabled]="lock == true" placeholder="Seq">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-serviceSequence'] != null && model['error-serviceSequence'] != ''">
                                            {{model['error-serviceSequence']}}
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label >Bound</label>
                                        <input type="text" maxlength="1" [(ngModel)]="model['sBound']" 
                                            (ngModelChange)="changesBound($event)" name="Bound"
                                            (keypress)="formValidation.AlphabetsOnlyWOSpace($event)"
                                            placeholder="Bound" [disabled]="lock == true"
                                        >
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="model['error-sBound'] != null && model['error-sBound'] != ''">
                                            {{model['error-sBound']}}
                                        </div>
                                    </div>

                                    <input type="hidden"  [(ngModel)]="model['exchRateDays']" name="exchRateDays" value="0">
                                </div>

                                <div class="field">
                                        <label>Remarks</label>
                                    <input style="width:91%" type="text" [(ngModel)]="model['remarks']"  name="remarks" [disabled]="lock == true" maxlength="200" placeholder="Remarks">
                                </div>

                            </div>
                        </div>
                        <div class="ui styled accordion field" style="width:100%">
                            <div class="title">
                                <i class="icon dropdown"></i>
                                Details
                            </div>
                            <div class="content">
                                <div id="trans"></div>
                                    <div id="detailsService"  >

                                    <!-- start of novyan ui patch -->

                                    <div [style.display]="modeDetail?'block':'none'" style="padding-left:2%;padding-right:2%;">
                                    <!-- fieldset [style.display]="modeDetail?'block':'none'" -->
                                        <div class="five fields"  style ="margin-bottom:18px;">
                                            <div class="field" style ="margin-left:-15px;width:280px">
                                                <div class="required field">
                                                <label style = "font-size:10px">Port Of Call</label >
                                                    <div class="fields">
                                                        <div class="twelve wide field" [class.error]="modelDetail['error-portName'] != null && modelDetail['error-portName'] != ''">
                                                                <combo-plugin  #serPoc [settings]="settingPortOfCall" [disableCombo]="lock"  (change)="valuePortOfCall($event)"
                                                                [required]="{name:'error-portName',field:modelDetail['error-portName'],model:modelDetail}" style="width:100%;"></combo-plugin>
                                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                    [class.error-message-visible]="modelDetail['error-portName'] != null && modelDetail['error-portName'] != ''">
                                                                    {{modelDetail['error-portName']}}
                                                                </div>
                                                        </div>
                                                        <div class="five wide field">
                                                            <input type="text" name="model[detail][portCode]" placeholder="Port" value="{{modelDetail.portCode}}"  readonly style ="background-color: lightgray">
                                                        </div>
                                                    </div>
                                                 </div>
                                            </div>
                                          <div class="field" style="width:280px">
                                            <label style = "font-size:10px">Terminal</label>
                                            <div class="fields">
                                              <div class="twelve wide field" [class.error]="modelDetail['error-terminalName'] != null && modelDetail['error-terminalName'] != ''">
                                                <combo-plugin #serTerminal [settings]="settingTerminal" [disableCombo]="lockTerminal" (change)="valueTerminal($event)"
                                                              [required]="{name:'error-terminalName',field:modelDetail['error-terminalName'],model:modelDetail}" style="width:100%;"></combo-plugin>
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                     [class.error-message-visible]="modelDetail['error-terminalName'] != null && modelDetail['error-terminalName'] != ''">
                                                  {{modelDetail['error-terminalName']}}
                                                </div>
                                              </div>
                                              <div class="five wide field">
                                                <input type="text" name="model[detail][terminalCode]" placeholder="Terminal" value="{{modelDetail.terminalCode}}"  readonly style ="background-color: lightgray">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="field" style='width:240px'>
                                                <label style = "font-size:10px">Transit Time From Previous Port</label>
                                                    <div class="fields">
                                                        <div class="field" [class.error]="modelDetail['error-fmPortHours'] != null && modelDetail['error-fmPortHours'] != ''">
                                                            <!--   [(ngModel)]="modelDetail['fmPortHours']" name="transitDay"-->
                                                            <input type="number" min="0" [(ngModel)]="modelDetail['fmPortHours']" name="fmPortHours"  value="{{modelDetail.fmPortHourTemp}}" [disabled]="lock == true">
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-fmPortHours'] != null && modelDetail['error-fmPortHours'] != ''">
                                                                {{modelDetail['error-fmPortHours']}}
                                                            </div>
                                                        </div>
                                                        <div class="field" style="margin-left:-8px; margin-top:8px; font-size:10px">
                                                            <label>D</label>
                                                        </div>
                                                        <div class="field" [class.error]="modelDetail['error-fmPortDaysHours'] != null && modelDetail['error-fmPortDaysHours'] != ''">
                                                            <!--  [(ngModel)]="modelDetail['fmPortDaysHours']" name="transitTime"-->
                                                            <input type="number" min="0" [(ngModel)]="modelDetail['fmPortDaysHours']" name="fmPortDaysHours"  value="{{modelDetail.fmPortDaysHours}}" [disabled]="lock == true">
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-fmPortDaysHours'] != null && modelDetail['error-fmPortDaysHours'] != ''">
                                                                {{modelDetail['error-fmPortDaysHours']}}
                                                            </div>
                                                        </div>
                                                        <div class="field" style="margin-left:-8px; margin-top:8px; font-size:10px">
                                                            <label>H</label>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div class="field"  style='width:240px'>
                                                <label style = "font-size:10px">Port Stay</label>
                                                    <div class="fields">
                                                        <div class="field" [class.error]="modelDetail['error-atPortHours'] != null && modelDetail['error-atPortHours'] != ''">
                                                            <!--  [(ngModel)]="modelDetail['atPortDays']" name="portDay"-->
                                                            <input type="number" min="0" [(ngModel)]="modelDetail['atPortHours']"  name="atPortHours"  value="{{modelDetail.atPortHourTemp}}"   [disabled]="lock == true">
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-atPortHours'] != null && modelDetail['error-atPortHours'] != ''">
                                                                {{modelDetail['error-atPortHours']}}
                                                            </div>
                                                        </div>
                                                        <div class="field" style="margin-left:-8px; margin-top:8px; font-size:10px">
                                                            <label>D</label>
                                                        </div>
                                                        <div class="field" [class.error]="modelDetail['error-atPortDaysHours'] != null && modelDetail['error-atPortDaysHours'] != ''">
                                                            <!-- [(ngModel)]="modelDetail['atPortDaysHours']" name="portTime" -->
                                                            <input type="number" min="0" [(ngModel)]="modelDetail['atPortDaysHours']"  name="atPortDaysHours"  value="{{modelDetail.atPortDaysHours}}"  [disabled]="lock == true">
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-atPortDaysHours'] != null && modelDetail['error-atPortDaysHours'] != ''">
                                                                {{modelDetail['error-atPortDaysHours']}}
                                                            </div>
                                                        </div>
                                                        <div class="field" style="margin-left:-8px; margin-top:8px; font-size:10px">
                                                            <label>H</label>
                                                        </div>
                                                    </div>
                                            </div>
                                             <div class="field" style="margin-right:-70px;width:280px">
                                                <label style = "font-size:10px">Purpose of Call Port</label>
                                                    <div class="fields" >
                                                        <div class="twelve wide field" [class.error]="modelDetail['error-callReason'] != null && modelDetail['error-callReason'] != ''">
                                                            <combo-plugin #serPurpose [settings]="settingPurpose" [disableCombo]="lock"  (change)="valuePurpose($event)"
                                                            [required]="{name:'error-callReason',field:modelDetail['error-callReason'],model:modelDetail}" style="width:100%;" ></combo-plugin>
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-callReason'] != null && modelDetail['error-callReason'] != ''">
                                                                {{modelDetail['error-callReason']}}
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                       <!-- <div class="three fields" style="margin-top: 13px">
                                            <div class="field">
                                                <label>Terminal</label>
                                                    <div class="fields">
                                                        <div class="twelve wide field" [class.error]="modelDetail['error-terminalName'] != null && modelDetail['error-terminalName'] != ''">
                                                            <combo-plugin #serTerminal [settings]="settingTerminal" [disableCombo]="lockTerminal" (change)="valueTerminal($event)"
                                                            [required]="{name:'error-terminalName',field:modelDetail['error-terminalName'],model:modelDetail}" style="width:100%;"></combo-plugin>
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-terminalName'] != null && modelDetail['error-terminalName'] != ''">
                                                                {{modelDetail['error-terminalName']}}
                                                            </div>
                                                        </div>
                                                        <div class="four wide field">
                                                            <input type="text" name="model[detail][terminalCode]" placeholder="Terminal" value="{{modelDetail.terminalCode}}"  readonly style ="background-color: lightgray">
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="three fields" style="margin-top: -10px">
                                            <div class="field">
                                                <label>Purpose of Call Port</label>
                                                    <div class="fields">
                                                        <div class="twelve wide field" [class.error]="modelDetail['error-callReason'] != null && modelDetail['error-callReason'] != ''">
                                                            <combo-plugin #serPurpose [settings]="settingPurpose" [disableCombo]="lock"  (change)="valuePurpose($event)"
                                                            [required]="{name:'error-callReason',field:modelDetail['error-callReason'],model:modelDetail}" style="width:100%;" ></combo-plugin>
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelDetail['error-callReason'] != null && modelDetail['error-callReason'] != ''">
                                                                {{modelDetail['error-callReason']}}
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>-->
                                        <div class="ui tiny right floated buttons" style="margin-top:-25px">
                                                <button [style.display]="modeButton?'none':'block'" style="font-size: 10px;" class="ui button detail" data-content="Add" (click)="detailsProcess('OK');">Add</button>
                                                <button [style.display]="modeButton?'block':'none'" style="font-size: 10px;" class="ui button detail" data-content="Update" (click)="detailsProcess('UPDATE');">Update</button>
                                                    <div class="or"></div>
                                                <button class="ui button detail" style="font-size: 10px;" data-content="cancel" (click)="cancelDetail()">Cancel</button>
                                        </div>

                                    </div>
                                    <!-- /fieldset -->

                                    <!-- end of novyan ui patch -->

                                    <div class="ui icon tiny buttons">
                                        <button class="ui button addDetail" [disabled]="lock == true" data-content="Add" (click)="addNewRow();"><i class="plus icon"></i></button>
                                        <button class="ui button addDetail" [disabled]="lock == true" data-content="Remove" (click)="remove();"><i class="minus icon"></i></button>
                                        <input type="hidden" name="gridCount" value="0">
                                        <input type="hidden" name="seqNo" value={{modelDetail.seqNo}}>
                                    </div>
                                    <div class="field"  >
                                        <!-- Begin of Grid style="margin-top:-35px"-->
                                        <grid-plugin id="detailgrid" #dtlgrid [settings]="settingGrid2" (gridEvent)="gridEvent2($event)" (infoGrid)="infoGrid2($event)"></grid-plugin>
                                        <!-- End of Grid -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui raised segment">
                            <div class="two fields">
                                <div class="field">
                                    <label>Created By</label>
                                        <input type="text"  [(ngModel)]="model['userCreated']"  name="userCreated" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                                <div class="field">
                                    <label>Created Date</label>
                                        <input type="text" [(ngModel)]="model['dateCreated']"  name="dateCreated" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Last Modified By</label>
                                        <input type="text" [(ngModel)]="model['userModified']"  name="userModified" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                                <div class="field">
                                    <label>Last Modified Date</label>
                                        <input type="text" [(ngModel)]="model['dateModified']"  name="dateModified" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Invalidated By</label>
                                        <input type="text" [(ngModel)]="model['userInvalid']"  name="userInvalid" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                                <div class="field">
                                    <label>Invalidated Date</label>
                                        <input type="text" [(ngModel)]="model['dateInvalid']"  name="dateInvalidfpo" placeholder="" readonly style ="background-color: lightgray">
                                </div>
                            </div>
                         </div>

                    <!-- Start of Modal -->
                    <div id="copyDialog" class="ui small modal" style="margin-top:-150px" [style.display]="modeCopy?'block':'none'">
                        <div class="ui raised segment" style="padding-left:1%;padding-right:1%;">
                             <div class="ui tiny right floated buttons">
                                <button  class="ui button addCopy" data-content="Close" (click)="closeCopy();"><i class="remove icon"></i></button>
                            </div>
                            <h4 class="ui dividing header">Service Copy Function</h4>
                            <fieldset>
                                <div id="transCopy"></div>
                                <div [style.display]="modeAddCopy?'block':'none'">
                                <div class="fields">
                                    <div class="field" [class.error]="modelCopy['error-copyServiceName'] != null && modelCopy['error-copyServiceName'] != ''">
                                        <label>Service Name</label>
                                        <input type="text" name="copyServiceName" (keypress)="none($event)"  [(ngModel)]="modelCopy['copyServiceName']">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="modelCopy['error-copyServiceName'] != null && modelCopy['error-copyServiceName'] != ''">
                                            <!--<p [innerHtml]="model['error-copyServiceName']"></p>-->
                                            {{modelCopy['error-copyServiceName']}}
                                        </div>
                                    </div>
                                    <div class="field" [class.error]="modelCopy['error-copyServiceCode'] != null && modelCopy['error-copyServiceCode'] != ''">
                                        <label>Service Code</label>
                                        <input type="text" name="copyServiceCode" (keypress)="none($event)" [(ngModel)]="modelCopy['copyServiceCode']"  maxlength="8">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="modelCopy['error-copyServiceCode'] != null && modelCopy['error-copyServiceCode'] != ''">
                                            <!--<p [innerHtml]="model['error-copyServiceCode']"></p>-->
                                            {{modelCopy['error-copyServiceCode']}}
                                        </div>
                                    </div>
                                </div>
                                <div class="fields">
                                    <div class="field" [class.error]="modelCopy['error-copyPsaServiceCode'] != null && modelCopy['error-copyPsaServiceCode'] != ''">
                                        <label>Psa Service Code</label>
                                        <input type="text" name="copyPsaServiceCode" (keypress)="none($event)" [(ngModel)]="modelCopy['copyPsaServiceCode']">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="modelCopy['error-copyPsaServiceCode'] != null && modelCopy['error-copyPsaServiceCode'] != ''">
                                            <!--<p [innerHtml]="model['error-copyServiceCode']"></p>-->
                                            {{modelCopy['error-copyPsaServiceCode']}}
                                        </div>
                                    </div>
                                    <div class="field" [class.error]="modelCopy['error-copySeq'] != null && modelCopy['error-copySeq'] != ''">
                                        <label>Seq</label>
                                        <input type="number"  min="0"  name="copySeq" [(ngModel)]="modelCopy['copySeq']">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                            [class.error-message-visible]="modelCopy['error-copySeq'] != null && modelCopy['error-copySeq'] != ''">
                                            <!--<p [innerHtml]="model['error-copyServiceCode']"></p>-->
                                            {{modelCopy['error-copySeq']}}
                                        </div>
                                    </div>
                                </div>

                                <div class="ui tiny right floated buttons">
                                    <button [style.display]="modeButtonCopy?'none':'block'" class="ui button addCopy" data-content="AddCopy" (click)="detailsProcessCopy('OK');">Ok</button>
                                    <button [style.display]="modeButtonCopy?'block':'none'" class="ui button Copy" data-content="Update" (click)="detailsProcessCopy('Update');">Update</button>
                                    <div class="or"></div>
                                    <button class="ui button addCopy" data-content="cancel" (click)="cancelCopy();">Cancel</button>
                                </div>
                                </div>
                                <div class="ui icon tiny buttons">
                                    <button  [style.display]="modeHGrid?'block':'none'"  class="ui button addCopy" data-content="AddCopy" (click)="addCopyDetails();"><i class="plus icon"></i></button>
                                    <button  [style.display]="modeHGrid?'block':'none'" class="ui button addCopy" data-content="RemoveCopy" (click)="removeCopy();"><i class="minus icon"></i></button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <button  [style.display]="modeHGrid?'block':'none'" style="margin-left:15px" class="ui button addCopy" data-content="Do Copy" (click)="doCopy();"><i class="copy icon"></i>Do Copy</button>
                                    <input type="hidden" name="gridCopy" value="0">
                                    <input type="hidden" name="seqCop" value={{modelCopy.seqCop}}>
                                </div>
                                <div class="field" >
                                    <!-- Begin of Grid style="margin-top:-35px"-->
                                    <grid-plugin  #dtlcopy [settings]="settingGridCopy" (gridEvent)="gridEventCopy($event)" (infoGrid)="infoGridCopy($event)"></grid-plugin>
                                    <!-- End of Grid -->
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <!-- End of Modal -->

                   <!-- Start of Port Rotation -->
                    <div id="addRowDialog" class="ui small modal" style="margin-right:400px;padding:1%;">
                        <label>Insert port of rotation</label>
                        <br>
                        <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
                            <table width = "100%">
                                <tbody>
                                    <tr valign="top">
                                        <td width="10%">
                                            <i class="huge help circle outline icon"></i>
                                        </td>
                                        <td width="90%">
                                            <div class="ui form">
                                                <div class="grouped fields">
                                                    <label>Where to insert the record?</label>
                                                    <div class="field">
                                                        <div class="ui radio checkbox">
                                                            <input #bfrSeq type="radio" name="rowChoose" value="{{before}}" (change)="setInsertSeqRadio(bfrSeq.checked,'before',bfrSeq.value)">
                                                            <label>Before seq. {{before}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="field">
                                                        <div class="ui radio checkbox">
                                                            <input #aftSeq type="radio" name="rowChoose" value="{{after}}" (change)="setInsertSeqRadio(aftSeq.checked,'after',aftSeq.value)">
                                                            <label>After seq. {{after}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr align="center">
                                        <td colspan="2">
                                            <button class="mini ui button" (click)="rowChoose('yes');">Yes</button>
                                            <button class="mini ui button" (click)="rowChoose('no');">No</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- End of Port Rotation -->

                    </form>
                    <!-- End of Form -->

                    <!-- Begin of Form History -->
                    <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
                         <div class="ui raised segment">
                             <a class="ui red ribbon label">SELECT SERVICE</a>
                             <div class="fields">
                                <div class="field">
                                    <div class="ui button" style="height:65%;margin-top:22px"  tabindex="0" (click)="retrieveHistory($event)">Retrieve</div>
                                </div>
                                <div class="field">
                                    <div class="ui button" style="height:65%;margin-top:22px" (click)='generateServiceHistoryReport($event);' tabindex="0">Export</div>
                                </div>
                             </div>
                             <div class="two fields">
                                 <div class="field">
                                    <label>Service Name</label>
                                    <combo-plugin #hisSer [settings]="settingHistoryService" (change)="valueHistoryService($event)" style="width:100%;"></combo-plugin>
                                 </div>
                                 <div class="field">
                                    <label>Service Code</label>
                                    <input type="text" name="hisServiceCode" value="{{modelHistory.serviceCode}}" [disabled]="lock==true" placeholder="Service Code" readonly style ="background-color: lightgray">
                                 </div>
                             </div>
                             <!-- Begin of Grid style="margin-top:-35px"-->
                                <grid-plugin  #gridH [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
                             <!-- End of Grid -->

                             <!--<div class="two fields">
                                 <div class="field">
                                    <calendar-plugin id="startDate" [pluginType]="serStartDate" [settings]="settingLogStartDate"></calendar-plugin>
                                 </div>
                                 <div class="field">
                                    <calendar-plugin id="endDate" [pluginType]="serEndDate" [settings]="settingLogStartDate" ></calendar-plugin>
                                 </div>
                             </div>-->
                         </div>
                         <br><br><br><br><br><br>
                    </form>
                    <!-- End of Form History -->

                </div>
            </div>
        </div>
    </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
