import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, ConfigService, CookieService, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, DatetimePluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-pfc-bulk-update-contract-rate-page',
  templateUrl: './pfc-bulk-update-contract-rate-page.component.html',
  styleUrls: ['./pfc-bulk-update-contract-rate-page.component.css']
})
export class PFCBulkUpdateContractRatePageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbFreightParty') cbFreightParty:ComboPluginComponent;
  @ViewChild('cbPlaceOfDelivery') cbPlaceOfDelivery:ComboPluginComponent;
  @ViewChild('cbContainerOwnership') cbContainerOwnership:ComboPluginComponent;
  @ViewChild('cbUpdateMethod') cbUpdateMethod:ComboPluginComponent;
  @ViewChild('cbPlaceOfReceipt') cbPlaceOfReceipt:ComboPluginComponent;
  @ViewChild('cbPortOfTransshipment1') cbPortOfTransshipment1:ComboPluginComponent;
  @ViewChild('cbPortOfTransshipment2') cbPortOfTransshipment2:ComboPluginComponent;
  @ViewChild('cbPortOfTransshipment3') cbPortOfTransshipment3:ComboPluginComponent;
  @ViewChild('cbCommodity') cbCommodity:ComboPluginComponent;
  @ViewChild('cbDGClass') cbDGClass:ComboPluginComponent;
  @ViewChild('cbPortOfLoad') cbPortOfLoad:ComboPluginComponent;
  @ViewChild('cbFinalDestination') cbFinalDestination:ComboPluginComponent;
  @ViewChild('cbReceiptType') cbReceiptType:ComboPluginComponent;
  @ViewChild('cbContainerType') cbContainerType:ComboPluginComponent;
  @ViewChild('cbPortOfDischarge') cbPortOfDischarge:ComboPluginComponent;
  @ViewChild('cbContainerStatus') cbContainerStatus:ComboPluginComponent;
  @ViewChild('cbDeliveryType') cbDeliveryType:ComboPluginComponent;
  @ViewChild('cbPORTerminal') cbPORTerminal:ComboPluginComponent;
  @ViewChild('cbPODLTerminal') cbPODLTerminal:ComboPluginComponent;
  @ViewChild('cbPOLTerminal') cbPOLTerminal:ComboPluginComponent;
  @ViewChild('cbPOT1Terminal') cbPOT1Terminal:ComboPluginComponent;
  @ViewChild('cbPODTerminal') cbPODTerminal:ComboPluginComponent;
  @ViewChild('cbFDESTTerminal') cbFDESTTerminal:ComboPluginComponent;
  @ViewChild('cbPrepaidStatus') cbPrepaidStatus:ComboPluginComponent;
  @ViewChild('cbCollectStatus') cbCollectStatus:ComboPluginComponent;
  @ViewChild('dateEffectiveFrom') dateEffectiveFrom:DatetimePluginComponent;
  @ViewChild('dateEffectiveTo') dateEffectiveTo:DatetimePluginComponent;
  @ViewChild('mainGrid') mainGrid:GridPluginComponent;
  @ViewChild('cbAdhocVessel') cbAdhocVessel:ComboPluginComponent;
  @ViewChild('cbAdhocVoyage') cbAdhocVoyage:ComboPluginComponent;

  dialog:any;


  /* Declare setting */
  settingCbFreightParty;
  settingCbPlaceOfDelivery;
  settingCbContainerOwnership;
  settingCbUpdateMethod;

  settingCbPlaceOfReceipt;
  settingCbPortOfTransshipment1;
  settingCbPortOfTransshipment2;
  settingCbPortOfTransshipment3;
  settingCbCommodity;
  settingCbDGClass;
  settingCbPortOfLoad;
  settingCbFinalDestination;
  settingCbReceiptType;
  settingCbContainerType;
  settingCbPortOfDischarge;
  settingCbContainerStatus;
  settingCbDeliveryType;

  settingCbPrepaidStatus;
  settingCbCollectStatus;
  settingMainGrid;

  settingDateEffectiveFrom;
  settingDateEffectiveTo;
  settingCbAdhocVessel;
  settingCbAdhocVoyage;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'bulkUpdateFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;

  checkLock:boolean = false;
  lock:boolean =false;
  lockEffectiveFrom:boolean=false;
  lockEffectiveTo:boolean=false;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  isError:boolean =false;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Retrieve',    event: 'retrieve',  icon: 'search'       },
        { name: 'Save',        event: 'save',      icon: 'save'         },
        { name: 'Cancel',      event: 'cancel',    icon: 'cancel'       },
        { name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Bulk Update Contract Rate'
    }


    this.settingCbPlaceOfReceipt = {
      id        : 'cbPlaceOfReceipt',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Place of Receipt',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPortOfLoad = {
      id          : 'cbPortOfLoad',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Port Of Load',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns     : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPortOfDischarge = {
      id         : 'cbPortOfDischarge',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Port Of Discharge',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPlaceOfDelivery = {
      id         : 'cbPlaceOfDelivery',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Place of Delivery',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbFreightParty = {
      id          : 'cbFreightParty',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() +'/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Freight Party',
      title       : 'customerName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Customer Name', field: 'customerName', width: 300},
        {header: 'Customer Code', field: 'customerCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbFinalDestination = {
      id         : 'cbFinalDestination',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Final Destination',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPortOfTransshipment1 = {
      id        : 'cbPortOfTransshipment',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Port of Transshipment 1',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }


    this.settingCbPortOfTransshipment2 = {
      id        : 'cbPortOfTransshipment2',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Port of Transshipment 2',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPortOfTransshipment3 = {
      id        : 'cbPortOfTransshipment3',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Port of Transshipment 3',
      title       : 'locationName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbCommodity = {
      id         : 'cbCommodity',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Commodity',
      title       : 'commodityName', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Commodity Name', field: 'commodityName', width: 300},
        {header: 'Commodity Code', field: 'commodityCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbReceiptType = {
      id         : 'cbReceiptType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/RT/Y/receiptAndDeliveryTypeCode/{query}/1/100/ASC/receiptAndDeliveryTypeCode',
      maxChars    : 1,
      template    : 'grid', // default
      placeholder : 'CY',
      title       : 'receiptAndDeliveryTypeCode', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Receipt Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Receipt Type Code', field: 'receiptAndDeliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbDeliveryType = {
      id         : 'cbDeliveryType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral/DT/Y/receiptAndDeliveryTypeCode/{query}/1/100/ASC/receiptAndDeliveryTypeCode',
      maxChars    : 1,
      template    : 'grid', // default
      placeholder : 'CY',
      title       : 'receiptAndDeliveryTypeCode', // variable name
      description : '',
      isMark      : true,
      columns    : [
        {header: 'Delivery Type Name', field: 'receiptAndDeliveryTypeName', width: 300},
        {header: 'Delivery Type Code', field: 'receiptAndDeliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingDateEffectiveFrom = {
      id:'dateEffectiveFrom',
      type : '',
      format : 'yyyy-mm-dd',
      placeholder : 'Effective From',
    }

    this.settingDateEffectiveTo = {
      id:'dateEffectiveTo',
      type : '',
      format : 'yyyy-mm-dd',
      placeholder : 'Expiry On',
    }

    this.settingCbContainerOwnership = {
      id          : 'cbContainerOwnership',
      type        : 'select', // search | select | select input
      url         : 'assets/json/container_ownership_contract_rate.json',
      placeholder : 'Container OwnerShip',
      code        : 'ctrOwnerCode',
      title       : 'ctrOwnerName'

    }

    this.settingCbUpdateMethod = {
      id          : 'cbContainerOwnership',
      type        : 'select', // search | select | select input
      url         : 'assets/json/container_ownership_contract_rate.json',
      placeholder : 'Container OwnerShip',
      code        : 'ctrOwnerCode',
      title       : 'ctrOwnerName'

    }

    this.settingCbAdhocVessel = {
      id          : 'cbAdhocVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 200},
        {header: 'Vessel Name', field: 'vesselName', width: 350},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingCbAdhocVoyage = {
      id          : 'cbAdhocVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }



  changePlaceOfReceipt(event){}
  changeFinalDestination(event){}
  changePortOfLoad(event){}
  changePortOfTransshipment1(event){}
  changePortOfDischarge(event){}
  changePortOfTransshipment2(event){}
  changePlaceOfDelivery(event){}
  changePortOfTransshipment3(event){}
  changeFreightParty(event){}
  changeCbCommodity(event){}
  changeContainerOwnership(event){}
  changeReceiptType(event){}
  changeDeliveryType(event){}
  changeCbMaintenanceAdhocVessel(event){}
  changeCbMaintenanceAdhocVoyage(event){}
  mainGridEvent(event){}
  infoGrid(event){}
  onProceed(){}
}
