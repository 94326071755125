export class ExceptionModel {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyageDischarging: string = "";
  voyageConnecting: string = "";
  dischargingBound: string = "O";
  connectingBound: string = "O";
  eta: string = "";
  coc: number = 0;
  cocLocal: number = 0;
  cocTS: number = 0;
  bPOT = "";
  bPot1Code = "SGSIN";
  bPot1Name = "";
  containerOwnerShip = "COC";
  dischargingService = "";
  connectingService = "";
  tsServiceId = "";
  tsTotal = "";
  tsLocal = "";
  tsTS = "";
  tsEta = "";
  tsEtd = "";
  tsRotation = "";
  vesselName: string = "";
  totalAllocated: string = "";
  totalBooked: string = "";
  totalConfirmed: string = "";
  totalAvailable: string = "";
  tsServiceCode: string = "";
  bPolCode = "";
  bPolName: string;
  podId: string = "";
  podCode: string = "";
  podName: string = "";
  vesselIdDischarging: string = "";
  vesselCodeDischarging: string = "";
  vesselNameDischarging: string = "";
  vesselIdConnecting: string = "";
  vesselCodeConnecting: string = "";
  vesselNameConnecting: string = "";
  status: string = "ALL";
  serviceConnecting = "";
  serviceDischarging = "";
  tsConnectingEtaPod: any;
  tsConnectingEtaPot: any;
  tsConnectingEtdPod: any;
  tsConnectingEtdPot: any;
  tsDischargingEtaPol: any;
  tsDischargingEtaPot: any;
  tsDischargingEtdPol: any;
  tsDischargingEtdPot: any;
  tsPolBookingNo: any;
  tsPotBookingNo: any;

  constructor() {}
}

export class ExceptionSplitModel {
  isChecked: boolean;
  tsPolFullBNoOrigin: string = "";
  tsPotFullBNoOrigin: string = "";
  tsNewBNoSplit: string = "";
  tsSplitContainerType: string = "";
  tsBSplitQuantity20 = 0;
  tsBSplitQuantity40 = 0;
  tsBSplitQuantity45 = 0;
  tsBSplitQuantityH20 = 0;
  tsBSplitQuantityH40 = 0;
  tsBSplitQuantityH45 = 0;
  tsNewConnectingVesselName: string = "";
  tsNewConnectingVesselVoyage: string = "";
  tsNewConnectingVesselBound: string = "";
  tsNewConnectingServiceCode: string = "";
  constructor() {}
}
