

export class Reason  {
  no : number = 0;
  reasonId    : string = '';
  reasonName  : string = '';
  remarks     : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  officeCode  : string ='';

  constructor () { }
}
