<div class="ui segment">
    <div class="ui divided items">

        <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
        <div >
            <div class="ui segment" style="padding-left:1%;padding-right:20%;">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                    <div class="ui medium text loader">Loading</div>
                </div>

                <div id='container'>
                    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

                    <!-- Begin of Form -->
                    <form class="ui form" [style.display]="form == ''?'block':'none'" >
                     
                        <a class="ui red ribbon label">Change Password</a>
                        <div class="ui raised segment">

                          <div class="three fields">
                            <div class="field">
                              <label>New Password</label>
                              <div class="ui left icon input">
                                <input type="password"  [(ngModel)]="model['newPWD']" name="New Password"  placeholder="New Password">
                                <i class="lock icon"></i>
                              </div>
                            </div>                           
                          </div>
                          
                          <div class="three fields">
                            <div class="field">
                              <label>Confirm Password</label>
                              <div class="ui left icon input">
                                <input type="password"  [(ngModel)]="model['confirmPWD']" name="Confirm Password"  placeholder="Confirm password">
                                <i class="lock icon"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                    </form>
                    <!-- End of Form -->
                </div>
            </div>
        </div>

    </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
