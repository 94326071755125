import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { specialCharUtil } from "../shared";
import FormValidation from "../../utils/formValidation";
declare var $: any;

export class UnlockInvoice{
  invNo:string='';
  varReason:string='';
  varRequest:string='';
  varRequestTime:string='';
  constructor(){

  }
}

@Component({
  selector: "app-transaction-unlock-invoice-page",
  templateUrl: "./transaction-unlock-invoice-page.component.html",
  styleUrls: ["./transaction-unlock-invoice-page.component.css"],
})
export class TransactionUnlockInvoicePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  dialog: any;

  // * Special Char
  sCharUtil = new specialCharUtil();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm = "";

  model = new UnlockInvoice();

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingVessel;
  settingVoyage;

  //variable hold
  boundVar: string = "";
  officeCode: string = "";

  // lock
  cButtonLock = true;
  dButtonLock = true;
  isError: Boolean = false;

  isUnlock = "chVessel";
  lockBound: Boolean = false;

  validatorRules = {};
  gridStore = [];
  duplicateList = [];

  modeUnlock = "";

  lockReason: boolean = false;
  formValidation = new FormValidation();

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();


    this.settingToolbar = {
      buttonsFront: [
        // {name: 'Retrieve', event: 'retrieve', icon: 'download'},
        { name: "Unlock", event: "unlock", icon: "unlock alternate" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Inv Unlock",
    };

    this.settingGrid = {
      id: "grid",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral', */,
      page: 5,
      head: 200,
      columns: [
        { header: "Invoice No", field: "invNo", width: 150, editType: "text" },
        {
          header: "Reason For Unlocking",
          field: "varReason",
          width: 300,
          editType: "text",
        },
        {
          header: "Requested by",
          field: "varRequest",
          width: 150,
          editType: "text",
        },
        {
          header: "Requested Date/Time",
          field: "varRequestTime",
          width: 180,
        },
        { header: "Result Message", field: "rMessage", width: 300 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enableSelectedRows: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      editable: true,
    };

  }

  ngOnInit() {
    this.isError = false;
  }

  ngAfterViewInit() {
    // $('grid-plugin').find('tbody').css('font-size','12px');
    $(".dataTables_scrollBody").css("height", "200px");
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();
    this.disableToolbarButtons = "unlock";
    this.cButtonLock = true;
    this.dButtonLock = true;
    this.modeUnlock = "V";

  }

  addNewRow() {
    this.isError = false;
    this.grid.listStore.store.push({
      id: (this.grid.listStore.store.length + 1).toString(),
      invNo: "",
      rMessage: "",
      valid: "",
      varReason: "",
      varRequest: "",
      varRequestTime: "",
    });
    this.grid.listStore.store.forEach((tt) => {
      // // // // console.log(tt);
    });

    this.grid.loadData();
    //this.grid.is_select_all = 'checked';
    this.grid.onSelectAllByValue(true);
    this.grid.is_select_all = "checked";
  }


  handleUnlock() {
    /*
    // // // // console.log('masuk');
    var pTool = 0;
    var tempVar = [];

    
    if (this.modeUnlock == "V") {
      this.gridStore = [];
      this.grid.listStore.store.forEach((ute) => {
     
        if (ute.valid == "N") {
          this.gridStore.push({
            id: ute.id,
            invNo: ute.invNo,
            rMessage: ute.rMessage.toUpperCase(),
            valid: ute.valid,
            office: this.officeCode,
            userid: this.cookieService.getName(),
            varReason: ute.varReason.toUpperCase(),
            varRequest: ute.varRequest.toUpperCase(),
            varRequestTime: ute.varRequestTime,
          });
        } else {
          pTool = 1;
        }

        //}
      });

      this.message(
        "information",
        "Information",
        "Confirm to unlock Invoice(s)?",
        "yesno",
        {
          yes: "this.actionUnlock()",
          no: "// // // // console.log('cancel clicked')",
        }
      );
    } else {
      this.gridStore = [];
      this.grid.listStore.store.forEach((ute) => {
        // // // // console.log(ute);
        if (ute.select == "checked") {
          if (ute.valid == "N") {
            this.gridStore.push({
              id: ute.id,
              invNo: ute.invNo,
              rMessage: ute.rMessage.toUpperCase(),
              valid: ute.valid,
              office: this.officeCode,
              userid: this.cookieService.getName(),
              varReason: ute.varReason.toUpperCase(),
              varRequest: ute.varRequest.toUpperCase(),
              varRequestTime: ute.varRequestTime,
            });
          } else {
            pTool = 1;
          }
        }
      });

      if (pTool > 0) {
        this.message(
          "information",
          "Information",
          "Unable to unlock Invoice(s)! Please check details.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        //this.actionUnlock();
        this.message(
          "information",
          "Information",
          "Confirm to unlock Invoice(s)?",
          "yesno",
          {
            yes: "this.actionUnlock()",
            no: "// // // // console.log('cancel clicked')",
          }
        );
      }
    }

    //}
    */
    
    /*
    document.getElementById(`grid.span.1.varReason`).style.color = 'red';
    document.getElementById(`grid.td.1.varReason`).style.border = '1px solid red';
    this.isError = true;
    this.model['error-varReason'] = "Please input reason."
    */

    //check validasi
    var isErrorInvNo:Boolean = false;
    var isErrorVarReason:Boolean = false;
    var isErrorVarRequest:Boolean = false;
    this.model['error-invNo'] = "";
    this.model['error-varReason'] = "";
    this.model['error-varRequest'] = "";
    this.grid.listStore.store.forEach((ute) => {
    
      if (ute.select == "checked") {
        if(ute.rMessage.toUpperCase() == "AVAILABLE TO UNLOCK."){
          if(ute.invNo == ""){
            isErrorInvNo = true;
            document.getElementById('grid.span.'+ute.id+'.invNo').style.color = 'red';
            document.getElementById('grid.td.'+ute.id+'.invNo').style.border = '1px solid red';
          }
          if(ute.varReason == ""){
            isErrorVarReason = true;
            document.getElementById('grid.span.'+ute.id+'.varReason').style.color = 'red';
            document.getElementById('grid.td.'+ute.id+'.varReason').style.border = '1px solid red';
          }
          if(ute.varRequest == ""){
            isErrorVarRequest = true;
            document.getElementById('grid.span.'+ute.id+'.varRequest').style.color = 'red';
            document.getElementById('grid.td.'+ute.id+'.varRequest').style.border = '1px solid red';
          }
        }
      }
    });

    if(isErrorInvNo == true || isErrorVarReason == true || isErrorVarRequest == true){
      this.isError = true;
      if(isErrorInvNo == true){
        this.model['error-invNo'] = "Please input Invoice No."
      }
      if(isErrorVarReason == true){
        this.model['error-varReason'] = "Please input reason."
      }
      if(isErrorVarRequest == true){
        this.model['error-varRequest'] = "Please input requestor."
      }
    }else{
      this.isError = false;

      this.grid.listStore.store.forEach((ute) => {
    
        if (ute.select == "checked") {
          if(ute.rMessage.toUpperCase() == "AVAILABLE TO UNLOCK."){
            if(ute.invNo != ""){
              document.getElementById('grid.span.'+ute.id+'.invNo').style.color = 'black';
              document.getElementById('grid.td.'+ute.id+'.invNo').style.border = '1px solid rgba(34, 36, 38, .1)';
            }
            if(ute.varReason != ""){
              document.getElementById('grid.span.'+ute.id+'.varReason').style.color = 'black';
              document.getElementById('grid.td.'+ute.id+'.varReason').style.border = '1px solid rgba(34, 36, 38, .1)';
            }
            if(ute.varRequest != ""){
              document.getElementById('grid.span.'+ute.id+'.varRequest').style.color = 'black';
              document.getElementById('grid.td.'+ute.id+'.varRequest').style.border = '1px solid rgba(34, 36, 38, .1)';
            }
          }
        }
      });
    }

    console.log(this.model);

    if(this.isError == false){
      this.gridStore = [];
      this.grid.listStore.store.forEach((ute) => {
      
        if (ute.select == "checked") {
          if(ute.rMessage.toUpperCase() == "AVAILABLE TO UNLOCK."){
            this.gridStore.push({
              id: ute.id,
              invNo: ute.invNo,
              rMessage: ute.rMessage.toUpperCase(),
              valid: ute.valid,
              office: this.officeCode,
              userid: this.cookieService.getName(),
              varReason: ute.varReason.toUpperCase(),
              varRequest: ute.varRequest.toUpperCase(),
              varRequestTime: ute.varRequestTime,
            });
          }
        }
      });

      if(this.gridStore.length > 0) {
        this.message(
          "information",
          "Information",
          "Confirm to unlock Invoice(s)?",
          "yesno",
          {
            yes: "this.actionUnlock()",
            no: "// // // // console.log('cancel clicked')",
          }
        );
      }else{
        this.message(
          "information",
          "Information",
          "Unable to unlock Invoice(s)! Please check details.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      }
    }
  }

  actionUnlock() {
    
    var datas = "";
    
    var url = this.configService.config.BASE_API.toString() + "/invoice/doUnlock";
   
    this.genericService
      .POST(url,
        this.gridStore
      )
      .subscribe(
        (resp) => {
          datas = resp.json()["result"];
         
          if (datas == "success") {
            this.message(
              "information",
              "Information",
              "Invoice(s) unlocked successfully.",
              "okonly",
              { ok: "// // // // console.log('ok click')" }
            );
            this.loading = false;
            this.disableToolbarButtons = "unlock";

            // load data
            this.grid.listStore.store = [];
            resp.json()["data"].forEach(el => {
              this.grid.listStore.addData(el);
            });
            this.grid.loadData();
          }
        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "There are some error occur while saving the record, error message : " +
              datas +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
    
  }

  removeRow() {
    // // // // console.log("PANJANG GRIDNYA: "+this.grid.onSelect.length);

    // // // // console.log("masuk remove");
    this.isError = false;
    var seqNo = 0;
    var countCheck = 0;
    var boxCheck: number;
    var storeTemp = new ListStore();

    this.grid.listStore.store.forEach((xy) => {
      // // // // console.log("xy "+xy);
      storeTemp.addData(xy);
    });

    storeTemp.store.forEach((el) => {
      if (el["select"] == "checked") {
        boxCheck = this.grid.listStore.store.indexOf(el);
        this.grid.listStore.store.splice(boxCheck, 1);

        $('input[name="gridCount"]').val(
          parseInt($('input[name="gridCount"]').val()) - 1
        );
      } else {
        this.disableToolbarButtons = "unlock";
      }
    });

    if (this.grid.listStore.store.length == 0) {
      $('input[name="gridCount"]').val(0);
      this.disableToolbarButtons = "unlock";
    }

    this.grid.listStore.store.forEach((col) => {
      seqNo = seqNo + 1;
      col.seqNo = seqNo;
    });

    this.grid.loadData();
    this.grid.clearSelectedValues();
  }

  setValidatorReason() {
    this.validatorRules = {
      varReason: {
        rules: [
          {
            type: "empty",
            prompt: "Please Input Reason. ",
          },
        ],
      },
    };
  }

  toolbarEvent(event) {
    //// // // // console.log('event : ' + event);
    switch (event) {
  
      case "unlock":
        this.handleUnlock();
        break;

      case "cancel":
        this.handleCancel();
        break;

      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    this.duplicateList = [];
    // // // // console.log('eventgrid'+event);
    switch (event.split(".")[0]) {
      case "selected":
        this.grid.getSelectedValues().forEach((element) => {
          if (element.select == "checked") {
            flgTool = 1;
          }
        });
        if (flgTool > 0) this.disableToolbarButtons = "";
        //// // // // console.log(flgTool);
        else this.disableToolbarButtons = "unlock";
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case "click":
        this.grid.listStore.store.forEach((yr) => {
          // // // // console.log(yr);
          if (yr.select == "checked") {
            flgTool = 1;
          }
        });

        if (flgTool > 0) console.log(flgTool);
        // this.disableToolbarButtons = "retrieve";
        else this.disableToolbarButtons = "retrieve,unlock";
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          console.log(arr)
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              if (strEvent.split(";")[1] == "invNo") {
               
                
                this.grid.listStore.store.forEach((yr) => {
                  soso.push(yr);
                  if (yr.id == arr[0].split(".")[1]) {
                    yr.invNo = strEvent.split(";")[2].toUpperCase();

                    if (yr.select == "checked") {
                      this.disableToolbarButtons = "";

                      console.log("cek logic")
                      // check blank
                      if(yr.invNo === ""){
                        this.message(
                          "information",
                          "Information",
                          "Blank Invoice Reference(s) detected.",
                          "okonly",
                          { ok: "" }
                        );
                        this.grid.listStore.removeData(yr);
                        this.grid.loadData();
                    
                        return;
                      }
                      
                      //check char only 10
                      if(yr.invNo.length > 10){
                        this.message(
                          "information",
                          "Information",
                          "Invoice Reference(s) More than 10 Characters detected.",
                          "okonly",
                          { ok: "" }
                        );
                        this.grid.listStore.removeData(yr);
                        this.grid.loadData();
                    
                        return;
                      }

                      //check char alphanumeric only
                      if(!this.formValidation.regAlphaNumeric.test(yr.invNo)){
                        this.message(
                          "information",
                          "Information",
                          "Invoice Reference(s) Non AlphaNumeric detected.",
                          "okonly",
                          { ok: "" }
                        );
                        this.grid.listStore.removeData(yr);
                        this.grid.loadData();
                    
                        return;
                      }

                      //check duplicate
                      dblFlag = this.checkDuplicateInObject("invNo", soso);

                      if (dblFlag == true) {
                        console.log(yr);
                        this.duplicateList.push(yr);

                        this.message(
                          "information",
                          "Information",
                          "Duplicate Invoice Reference(s) detected.",
                          "okonly",
                          { ok: "this.removeDuplicate()" }
                        );
                       
                      }else{
                        this.getInvStatus(yr)
                      }
                     
                    }else{
                      console.log("NOT cek logic")
                    }

                  }
                });
              }

              this.grid.listStore.store.forEach((yr) => {
                if (yr.select == "checked") {
                  flgTool = 1;
                }
              });

              console.log("flgTool = " + flgTool)

              if (flgTool > 0)
                this.disableToolbarButtons = "";
              else this.disableToolbarButtons = "unlock";

              break;
            case "dblClick":
              break;
            default:
              break;
          }
          
        }

        break;
    }
  }

  removeDuplicate() {
    this.loading=false;
    var self = this;
    this.duplicateList.forEach((data) => {
      console.log(data.id)
      self.grid.listStore.removeData(data);
      self.grid.loadData();
      
    });
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  infoGrid(event) {
    this.info = event;
  }

  handleCancel() {
    this.grid.onClear();
    this.gridStore = [];
    this.disableToolbarButtons = "unlock";
    
  }

  changeEventReason(event) {
   // this.model.varReason = $("textarea#reason").val();
  }

  getInvStatus(data:any) {
    console.log("** getInvStatus **")
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/invoice/checkInvNo/" +
          this.officeCode +
          "/" +
          data.invNo.toUpperCase()
      )
      .subscribe((resp) => {
        console.log(resp);
        if (resp.ok) {
          const resBody = JSON.parse(resp._body);
          if(resBody.status == "ok"){
            data.rMessage = resBody.msg
            
            this.grid.listStore.updateData(data.id-1,data)
            this.grid.loadData();
          }else{
            this.grid.listStore.removeData(data)
            this.grid.loadData();

            let flgTool: number = 0;
            this.grid.listStore.store.forEach((yr) => {
              if (yr.select == "checked") {
                flgTool = 1;
              }
            });
            if (flgTool > 0)
              this.disableToolbarButtons = "";
            else this.disableToolbarButtons = "unlock";

            this.message(
              "information",
              "Information",
              resBody.msg,
              "okonly",
              { ok: "" }
            );
          }
        }else{
          this.grid.listStore.removeData(data)
          this.grid.loadData();

          this.message(
            "information",
            "Information",
            resp.statusText,
            "okonly",
            { ok: "" }
          );
        }
      })
  }

}
