<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <!--
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-periodFrom'] == null || param['error-periodFrom'] == '' || param['error-periodFrom'] == undefined ">
        {{param['error-periodFrom']}}
      </li>
      <li [class.hidden]="param['error-periodTo'] == null || param['error-periodTo'] == '' || param['error-periodTo'] == undefined ">
        {{param['error-periodTo']}}
      </li>

    </ul>
  </div>
  -->
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">Daily Empty Releasing Order Report</label>
        <br />
        <br />

        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="two wide field">
              <label>Period From</label>
              <div class="ui left icon input">
                <input id="datePeriodFrom" readonly type="text" class="form-control" data-toggle="datepicker" placeholder="date">
                <i class="calendar icon"></i>
              </div>
            </div>

            <div class="two wide field">
              <label>Period To</label>
              <div class="ui left icon input">
                <input id="datePeriodTo" readonly type="text" class="form-control" data-toggle="datepicker" placeholder="date">
                <i class="calendar icon"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="field" style="margin-bottom: 5px">
          <div class="fields" style="margin-bottom: 0px">
            <div class="two wide field">
              <label>Release At</label>
              <div class="ui left icon input">
                <combo-plugin #cbReleaseAt [settings]="settingReleaseAt" (change)="changeEventReleaseAt($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>

            <div class="two wide field">
              <label>Stuff At (POR)</label>
              <div class="ui left icon input">

                <combo-plugin #cbStuffAt [settings]="settingStuffAt" (change)="changeEventStuffAt($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
          </div>
        </div>

        <div class="field">

            <div id="prevx">
                <iframe id="myIframe" src=""></iframe>
            </div>


        </div>
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
      </div>  <!-- end of raised segment -->
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
