export class LocalMasterCharge {
    no : number = 0;
    lChargeId   : string = '';
    lChargeCode  : string = '';
    lChargeName  : string = '';
    isGst : boolean = false;
    isZeroRated :Boolean = false;
    isValid :Boolean = true;
    remarks       : string = '';
    dateCreated  : string ='';
    userCreated  : string ='';
    dateModified : string ='';
    userModified : string ='';
    dateInvalid  : string ='';
    userInvalid  : string ='';
    lOfficeCode : string ='';
  
    isGstorZeroRated: Boolean = false;
    constructor() {
  
    }
  }
  