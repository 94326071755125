import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  DatePluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { BLHeader } from "../shared/index";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-report-finance-approved-list-summary-page",
  templateUrl: "./report-finance-approved-list-summary-page.component.html",
  styleUrls: ["./report-finance-approved-list-summary-page.component.css"],
})
export class ReportFinanceApprovedListSummaryPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("datePeriodTo") datePeriodTo: DatePluginComponent;
  @ViewChild("datePeriodFrom") datePeriodFrom: DatePluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbTransPort") cbTransPort: ComboPluginComponent;
  @ViewChild("cbPlaceDelivery") cbPlaceDelivery: ComboPluginComponent;
  @ViewChild("cbFinalDest") cbFinalDest: ComboPluginComponent;
  @ViewChild("cbPlaceReceipt") cbPlaceReceipt: ComboPluginComponent;
  @ViewChild("cbWeeks") cbWeeks: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingLoadPort;
  settingDischargePort;
  settingTransPort;
  settingPlaceDelivery;
  settingFinalDest;
  settingPlaceReceipt;
  settingDatePicker;
  settingDateFrom;
  settingDateTo;
  settingWeeks;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";
  typePeriodFrom: string = "calendarPeriodFrom";
  typePeriodTo: string = "calendarPeriodTo";

  isContOwn: String;
  isType: String;
  isPrinted: String;

  year: String = "";
  week: String = "";
  flagPrint: String = "";

  isError: Boolean = false;
  disabledPeriod: Boolean = false;
  disabledByWeek: Boolean = false;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  model = new BLHeader();

  validatorRules = {};

  constructor(
    private auth: Authorize,
    private _http: HttpClient,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Approved List Summary Report",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischarge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPlaceDelivery = {
      id: "cbPlaceDelivery",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingTransPort = {
      id: "cbTransPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinalDest = {
      id: "cbFinalDest",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPlaceReceipt = {
      id: "cbPlaceReceipt",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };
    this.settingWeeks = {
      id: "cbWeeks",
      type: "select", // search | select | select input
      url: "assets/json/weeks.json",
      placeholder: "Weeks",
      code: "weekCode",
      title: "weekName",
    };

    this.settingDateFrom = {
      id: "idPeriodFrom",
      placeholder: "Period From",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingDateTo = {
      id: "idPeriodTo",
      placeholder: "Period To",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.isContOwn = "ALL";
    this.isType = "ALL";
    this.isPrinted = "byPeriod";
    this.disabledPeriod = true;
    this.disabledByWeek = false;

    /*$('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });*/
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        break;

      case "print":
        this.handleprint();
        break;

      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventLoadPort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blLoadPortCode = "*";
    } else {
      this.model.blLoadPortCode = event.locationCode;
    }
  }

  setValidatorARS() {
    this.clearValidatorError();
    this.validatorRules = {
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port Of Loading.",
          },
        ],
      },
      periodFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Period From.",
          },
        ],
      },
      periodTo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Period To.",
          },
        ],
      },
    };
  }

  setValidatorARSByWeek() {
    this.clearValidatorError();
    this.validatorRules = {
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port Of Loading.",
          },
        ],
      },
      year: {
        rules: [
          {
            type: "empty",
            prompt: "Please input year.",
          },
        ],
      },
      weekName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input weeks.",
          },
        ],
      },
    };
  }

  clearValidatorError() {
    this.model["error-blLoadPortCode"] = "";
    this.model["error-periodFrom"] = "";
    this.model["error-periodTo"] = "";
    this.model["error-year"] = "";
    this.model["error-weekName"] = "";
  }

  changeEventDischargePort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blDischargePortCode = "*";
    } else {
      this.model.blDischargePortCode = event.locationCode;
    }
  }
  changeEventTransPort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      console.log("here transhipment");
      this.model.blPortOfTransshipment1Code = "*";
    } else {
      console.log("here transhipment 2");
      this.model.blPortOfTransshipment1Code = event.locationCode;
    }
  }
  changeEventFinalDest(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blFinalDestinationCode = "*";
    } else {
      this.model.blFinalDestinationCode = event.locationCode;
    }
  }
  changeEventPlaceReceipt(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfReceiptCode = "*";
    } else {
      this.model.blPlaceOfReceiptCode = event.locationCode;
    }
  }
  changeEventPlaceDelivery(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfDeliveryCode = "*";
    } else {
      this.model.blPlaceOfDeliveryCode = event.locationCode;
    }
  }

  changeNULL() {
    if (
      this.model.blLoadPortCode == null ||
      this.model.blLoadPortCode == "" ||
      this.model.blLoadPortCode == undefined
    ) {
      this.model.blLoadPortCode = "*";
    }

    if (
      this.model.blDischargePortCode == null ||
      this.model.blDischargePortCode == "" ||
      this.model.blDischargePortCode == undefined
    ) {
      this.model.blDischargePortCode = "*";
    }

    if (
      this.model.blPortOfTransshipment1Code == null ||
      this.model.blPortOfTransshipment1Code == "" ||
      this.model.blPortOfTransshipment1Code == undefined
    ) {
      this.model.blPortOfTransshipment1Code = "*";
    }

    if (
      this.model.blFinalDestinationCode == null ||
      this.model.blFinalDestinationCode == "" ||
      this.model.blFinalDestinationCode == undefined
    ) {
      this.model.blFinalDestinationCode = "*";
    }

    if (
      this.model.blPlaceOfReceiptCode == null ||
      this.model.blPlaceOfReceiptCode == "" ||
      this.model.blPlaceOfReceiptCode == undefined
    ) {
      this.model.blPlaceOfReceiptCode = "*";
    }

    if (
      this.model.blPlaceOfDeliveryCode == null ||
      this.model.blPlaceOfDeliveryCode == "" ||
      this.model.blPlaceOfDeliveryCode == undefined
    ) {
      this.model.blPlaceOfDeliveryCode = "*";
    }
  }

  handleprint() {
    console.log("BY PERIOD NYA APE:" + this.isPrinted);
    if (this.flagPrint == "byWeek") {
      this.setValidatorARSByWeek();
    } else {
      this.setValidatorARS();
    }
    this.isError = this.onValidate(this.model);
    //console.log("VALIDASINYA -- > "+this.onValidate(this.model));
    if (!this.isError) {
      this.changeNULL();
      /*const urlHit = 'http://localhost:15000' + '/ARS/ARSReport/'+this.officeCode+'/'+this.model.blLoadPortCode+'/'+this.model.blPortOfTransshipment1Code+'/'+this.model.blFinalDestinationCode+'/'+this.model.blDischargePortCode+'/'+this.model.blPlaceOfDeliveryCode+'/'+this.model.blPlaceOfReceiptCode+'/'+this.isContOwn+'/'+this.isType+'/'+ this.model.periodFrom+'/'+this.model.periodTo;
      this.genericService.get(urlHit).subscribe((resp)=>{
        console.log ("RESP : "+resp);
      });*/

      this.loading = true;
      const urlHit =
        this.configService.config.BASE_API.toString() +
        "?q=/ARS/ARSReport/" +
        this.officeCode +
        "/" +
        this.model.blLoadPortCode +
        "/" +
        this.model.blPortOfTransshipment1Code +
        "/" +
        this.model.blFinalDestinationCode +
        "/" +
        this.model.blDischargePortCode +
        "/" +
        this.model.blPlaceOfDeliveryCode +
        "/" +
        this.model.blPlaceOfReceiptCode +
        "/" +
        this.isContOwn +
        "/" +
        this.isType +
        "/" +
        this.model.periodFrom +
        "/" +
        this.model.periodTo;
      //const urlHit = this.configService.config.BASE_API.toString() + '/ARS/ARSReport/'+this.officeCode+'/'+this.model.blLoadPortCode+'/'+this.model.blPortOfTransshipment1Code+'/'+this.model.blFinalDestinationCode+'/'+this.model.blDischargePortCode+'/'+this.model.blPlaceOfDeliveryCode+'/'+this.model.blPlaceOfReceiptCode+'/'+this.isContOwn+'/'+this.isType+'/'+ this.model.periodFrom+'/'+this.model.periodTo;

      const urlHitData =
        this.configService.config.BASE_API.toString() +
        "/ARS/ARSDataReport/" +
        this.officeCode +
        "/" +
        this.model.blLoadPortCode +
        "/" +
        this.model.blPortOfTransshipment1Code +
        "/" +
        this.model.blFinalDestinationCode +
        "/" +
        this.model.blDischargePortCode +
        "/" +
        this.model.blPlaceOfDeliveryCode +
        "/" +
        this.model.blPlaceOfReceiptCode +
        "/" +
        this.isContOwn +
        "/" +
        this.isType +
        "/" +
        this.model.periodFrom +
        "/" +
        this.model.periodTo;
      this.genericService.GET(urlHitData).subscribe((resp) => {
        console.log("RESP Data : " + resp.json()["DATA"]);
        if (resp.json()["DATA"] == "NOT OK") {
          this.message(
            "information",
            "Information",
            "No record(s) found.",
            "okonly",
            { ok: "this.loading=true;" }
          );
          this.loading = false;
        } else {
          this._http
            .get(urlHit, {
              headers: new HttpHeaders({ "Content-Type": "application/json" }),
              responseType: "blob",
            })
            .subscribe((res) => {
              FileSaver.saveAs(res, "Approve Rate Summary Report.xlsx");
              this.loading = false;
            });
          //window.open(urlHit);
        }
      });

      // window.open(urlHit);
    }
  }

  changePeriodFrom(event) {
    if (
      this.datePeriodFrom.getValue() == null ||
      this.datePeriodFrom.getValue() == "" ||
      this.datePeriodFrom.getValue() == undefined
    ) {
      this.model.periodFrom = "";
    } else {
      this.model.periodFrom = this.datePeriodFrom.getValue();
    }
  }
  changePeriodTo(event) {
    if (
      this.datePeriodTo.getValue() == null ||
      this.datePeriodTo.getValue() == "" ||
      this.datePeriodTo.getValue() == undefined
    ) {
      this.model.periodTo = "";
    } else {
      this.model.periodTo = this.datePeriodTo.getValue();
    }
  }

  changeContOwn(event) {
    if (event.target.value == "ALL") {
      this.isContOwn = "ALL";
    } else if (event.target.value == "SOC") {
      this.isContOwn = "SOC";
    } else if (event.target.value == "COC") {
      this.isContOwn = "COC";
    } else if (event.target.value == "SLOT") {
      this.isContOwn = "SLOT";
    }
  }

  changeType(event) {
    if (event.target.value == "ALL") {
      this.isType = "ALL";
    } else if (event.target.value == "FULL") {
      this.isType = "FULL";
    } else if (event.target.value == "EMPTY") {
      this.isType = "EMPTY";
    }
  }

  gridEvent(event) {}
  infoGrid(event) {}

  changeYear(event) {
    console.log("ON CHANGE YEAR: " + event.target.value);
    // this.year = event.target.value;
    this.model.year = event.target.value;
  }
  changeWeeks(event) {
    this.model.weekName = event.weekCode;
    this.model.periodFrom = this.getStartAndEndDate(
      this.week,
      this.year
    ).dateFrom;
    this.model.periodTo = this.getStartAndEndDate(this.week, this.year).dateTo;

    console.log("ON CHANGE WEEK: " + this.year);
    console.log("ON CHANGE WEEK: " + this.week);
    //console.log(this.getStartAndEndDate(1, 2017));
    //console.log(this.getStartAndEndDate(53, 2016));
  }

  getStartAndEndDate(week, y) {
    var d = new Date("Jan 01, " + y + " 01:00:00");
    var dayMs = 24 * 60 * 60 * 1000;
    var offSetTimeStart = dayMs * (d.getDay() - 1);
    var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
    var n1 = new Date(w).toISOString().slice(0, 10);
    var n2 = new Date(w + 518400000).toISOString().slice(0, 10);
    return {
      dateFrom: n1,
      dateTo: n2,
    };
  }

  changePrinted(event) {
    console.log(event.target.value);
    if (event.target.value == "byPeriod") {
      this.flagPrint = event.target.value;
      this.disabledPeriod = true;
      this.disabledByWeek = false;
    } else {
      this.flagPrint = event.target.value;
      this.disabledPeriod = false;
      this.disabledByWeek = true;
    }
  }

  //$week_array = getStartAndEndDate(52,2013);
  //print_r($week_array);
}
