<div class="transfer-pod-Dialog">
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index: 99">
    <div class="ui medium text loader">Loading</div>
  </div>
  <div class="transfer-pod-header">
    <label>POT <b>: {{model.bPot1Name}}</b></label>
    <label>Rotation <b>: {{model.tsRotation}}</b></label>
  </div>
  <div class="transfer-pod-form">
    <div class="transfer-pod-field" [class.error]="">
      <label>New Connecting Vessel <span class="red-color">*</span> </label>
      <combo-plugin #cbNewVessel [settings]="settingNewVessel" (change)="changeEventNewVessel($event)"
        style="width: 100%">
      </combo-plugin>
    </div>
    <div class="transfer-pod-field" [class.error]="">
      <label>New Connecting Voyage <span class="red-color">*</span></label>
      <combo-plugin #cbNewVoyage [settings]="settingNewVoyage" (change)="changeEventNewVoyage($event)"
        style="width: 100%">
      </combo-plugin>
    </div>
    <div class="transfer-pod-field-small" [class.error]="">
      <label>Bound <span class="red-color">*</span></label>
      <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound" maxlength="1"
        [(ngModel)]="model.bound" disabled />
    </div>
    <div class="transfer-pod-field-small">
      <label>Service</label>
      <input type="text" name="bound" style="text-transform: uppercase" placeholder="" maxlength="1"
        [(ngModel)]="model.newConnectingService" disabled />
    </div>
  </div>
  <div class="transfer-pod-gird">
    <label for="">Port Pair Allocation Summary (of New Connecting Vessel/Voyage)</label>
    <ag-grid-angular style="width: 100%; height: 180px" #topGridPortPair class="ag-theme-balham"
      (gridReady)="onGridPortReady($event)" [rowData]="rowDataPortPair" [columnDefs]="columnDefsGridPortPair"
      [rowSelection]="'single'" [enableSorting]="true" [enableFilter]="true" [enableColResize]="true"
      (rowDoubleClicked)="rowDoubleClick($event)" (selectionChanged)="onSelectionChanged($event)"
      [rowMultiSelectWithClick]="false" >
    </ag-grid-angular>
  </div>
  <div class="transfer-pod-btn">
    <button class="ui tiny button" (click)="saveDialog()">Transfer</button>
    <button class="ui tiny button" (click)="cancelDialog()">Cancel</button>
    <button class="ui tiny button" (click)="closeDialog()">Close</button>
  </div>
</div>

<div id="unRenomList" class="dialogUnconfirmedList">
  <table>
    <tr>
      <th>POL Booking No</th>
      <th>Discharging Vessel</th>
      <th>Discharging Voyage</th>
      <th>ETD POT</th>
      <th>Connecting Vessel</th>
      <th>Connecting Voyage</th>
      <th>ETA POT</th>
      <th>Difference</th>
      <th>Proceed connection?</th>
    </tr>
    <tr *ngFor="let item of unRenomList">
      <td>{{item.tsPolBookingPrefix}}{{item.tsPolBookingNo}}</td>
      <td>{{item.tsDischargingVesselName}}</td>
      <td>{{item.tsDischargingVesselVoyage}}</td>
      <td>{{item.tsDischargingEtdPot}}</td>
      <td>{{item.tsConnectingVesselName}}</td>
      <td>{{item.tsConnectingVesselVoyage}}</td>
      <td>{{item.tsConnectingEtaPot}}</td>
      <td>
        {{returnTime(item.tsConnectingEtaPot, item.tsDischargingEtdPot)}}
      </td>
      <td>
        <div class="d-flex-checkbox">
          <input type="radio" id="yes{{item.tsPolBookingNo}}" name="yes{{item.tsPolBookingNo}}"
            [(ngModel)]="item.isConfirm" value="Y" (click)="onClickProcessConnection('Y',item)">
          <label for="yes{{item.tsPolBookingNo}}" class="mr-20px">Yes</label>

          <input type="radio" id="no{{item.tsPolBookingNo}}" name="no{{item.tsPolBookingNo}}"
            [(ngModel)]="item.isConfirm" value="N" (click)="onClickProcessConnection('N',item)">
          <label for="no{{item.tsPolBookingNo}}">No</label>
        </div>
      </td>
    </tr>
  </table>
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-no" (click)="closeUnRenomList()">Close</button>
    <button class="ui button btn-yes" (click)="confirmUnRenomList()">Transfer</button>
  </div>
</div>