<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:12"><div class="ui medium text loader">Loading</div></div>

  <div id='container'>
    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

      <!--<Error Message Block-->
      <div class="ui error message" [class.hidden]="isError == false">
        {{invoiceParam['error-invVesselCode']}}
        <ul>
          <li [class.hidden]="invoiceParam['error-invVesselCode'] == null || invoiceParam['error-invVesselCode'] == ''">
            {{invoiceParam['error-invVesselCode']}}
          </li>
          <li [class.hidden]="invoiceParam['error-invVesselVoyage'] == null || invoiceParam['error-invVesselVoyage'] == ''">
            {{invoiceParam['error-invVesselVoyage']}}
          </li>
          <li [class.hidden]="invoiceParam['error-invVesselBound'] == null || invoiceParam['error-invVesselBound'] == ''">
            {{invoiceParam['error-invVesselBound']}}
          </li>
          <!--
          <li [class.hidden]="invoiceParam['error-invPlaceOfPaymentCode'] == null || invoiceParam['error-invPlaceOfPaymentCode'] == ''">
            {{invoiceParam['error-invPlaceOfPaymentCode']}}
          </li>
          <li [class.hidden]="invoiceParam['error-invAccCode'] == null || invoiceParam['error-invAccCode'] == ''">
            {{invoiceParam['error-invAccCode']}}
          </li>
          -->
          <li [class.hidden]="invoiceParam['error-invContainerOwnership'] == null || invoiceParam['error-invContainerOwnership'] == ''">
            {{invoiceParam['error-invContainerOwnership']}}
          </li>
        </ul>
      </div>
      <!-- End of Error Message Block-->

      <form class="ui form">

      <!-- field selection criteria -->
      <br>
        <div class="field">
          <fieldset style="border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria By</legend>
             <div class="three fields">
                 <div class="ui radio checkbox" >
                   <input type="radio" id="selVessel"  name="selectionBy" tabindex="0" class="hidden"  (change)="changeEventCriteria($event)" value="VESSEL">
                   <label>Vessel/Voyage &nbsp;</label>
                 </div>
                 <div class="ui radio checkbox" >
                    <input type="radio" id="selInvoice"  name="selectionBy" tabindex="0" class="hidden" (change)="changeEventCriteria($event)" value="INVOICE">
                    <label>Invoice No.&nbsp;</label>
                 </div>
                 <div class="ui radio checkbox" >
                    <input type="radio" id="selBL"  name="selectionBy" tabindex="0" class="hidden" checked (change)="changeEventCriteria($event)" value="BL">
                    <label>B/L No.&nbsp;</label>
                  </div>
             </div>
          </fieldset>
      </div>
      <div class="two fields">
        <!-- KIRI -->
        <div class="field">
          <!-- field kiri baris 1 -->
          <div class="field">
            <div class="two fields">
              <div class="field">

                <div class="field">
                  <div class=" field"  [class.error]="invoiceParam['error-invVesselCode'] != null && invoiceParam['error-invVesselCode'] != ''">
                    <label>Vessel </label>
                    <combo-plugin
                      #cbVessel
                      [settings]="settingVessel"
                      (change)="changeEventVessel($event)"
                      (keypress)="formValidation.validateVesselVoyage($event)"
                      (keyup)="backspaceValue($event,formValidation.regVesVoy, 'invVesselCode', false, 'and -./')"
                      (paste)="onPasteValidation(
                        $event,
                        'invVesselCode',
                        formValidation.regVesVoy,
                        false, 'and -./')"
                     style="width:1400px;">
                    </combo-plugin>
                    <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invVesselCode'] != null && invoiceParam['error-invVesselCode'] != ''">
                        {{invoiceParam['error-invVesselCode']}}
                    </div>
                  </div>
                </div>
                <div class="field" style = "margin-top:8px">
                  <label>Payer</label>
                  <combo-plugin
                    #cbPayer
                    [settings]="settingPayer"
                    (change)="changeEventPayer($event)"
                    (keypress)="formValidation.AlphabetsOnly($event)"
                    (keyup)="backspaceValue($event,formValidation.regStringOnly, 'invPayerName',  true, '')"
                    (paste)="onPasteValidation(
                      $event,
                      'invPayerName',
                      formValidation.regStringOnly,
                      true, '')"
                    style="width:100%;">
                  </combo-plugin>
                  <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invPayerName'] != null && invoiceParam['error-invPayerName'] != ''">
                    {{invoiceParam['error-invPayerName']}}
                  </div>
                </div>
                <div class="field" style = "margin-top:8px" >
                  <div  class=" field"  [class.error]="invoiceParam['error-invAccCode'] != null && invoiceParam['error-invAccCode'] != ''">
                    <label>Acct/Debtor Code </label>
                    <input
                      type="text"
                      name="accCode"
                      [(ngModel)]="invoiceParam.invAccCode"
                      placeholder="Account Code"
                      maxlength="8"
                      (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                      (ngModelChange)="onPasteValidation(
                        invoiceParam.invAccCode,
                        'invAccCode',
                        formValidation.regAlphaNumeric,
                        false, '', 'AcctDebtorCode')"
                      (keydown.enter)="$event.preventDefault()"

                    />
                     <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invAccCode'] != null && invoiceParam['error-invAccCode'] != ''">
                        {{invoiceParam['error-invAccCode']}}
                    </div>
                  </div>
                </div>
                <div class="field" style = "margin-top:8px" >
                  <!--div  class="field" [class.error]="invoiceParam['error-invPlaceOfPaymentCode'] != null && invoiceParam['error-invPlaceOfPaymentCode'] != ''" -->
                  <div class="field">
                    <label>Place of Payment </label>
                    <combo-plugin
                      #cbPlacePayment
                      [settings]="settingPlacePayment"
                      (change)="changeEventPlacePayment($event)"
                      (keypress)="formValidation.AlphabetsIncludeSpace($event)"
                      (keyup)="handleBackspaceValueRegex($event,formValidation.regStringOnly, 'invPlaceOfPaymentCode', true, '')"
                      (paste)="onPasteValidation(
                        $event,
                        'invPlaceOfPaymentCode',
                        formValidation.regStringOnly,
                        true, '')"
                      style="width:100%;">
                    </combo-plugin>
                    <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invPlaceOfPaymentCode'] != null && invoiceParam['error-invPlaceOfPaymentCode'] != ''">
                      {{invoiceParam['error-invPlaceOfPaymentCode']}}
                    </div>
                  </div>
                </div>
              </div>

              <!-- start with voyage -->
              <div class="field">
                <div class="field">
                  <div class=" field"  [class.error]="invoiceParam['error-invVesselVoyage'] != null && invoiceParam['error-invVesselVoyage'] != ''">
                      <label>Voyage </label>
                      <combo-plugin
                        #cbVoyage
                        [settings]="settingVoyage"
                        (change)="changeEventVoyage($event)"
                        (keypress)="formValidation.validateVesselVoyage($event)"
                        (keyup)="backspaceValue($event,formValidation.regVesVoy, 'invVesselVoyage', false, 'and -./')"
                        (paste)="onPasteValidation(
                          $event,
                          'invVesselVoyage',
                          formValidation.regVesVoy,
                          false, 'and -./')"
                        style="width:100%;">
                      </combo-plugin>
                      <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invVesselVoyage'] != null && invoiceParam['error-invVesselVoyage'] != ''">
                        {{invoiceParam['error-invVesselVoyage']}}
                      </div>
                  </div>
                </div>
                <div class="field" style = "margin-top:8px" >
                      <label>Load Port</label>
                      <combo-plugin
                        #cbLoadPort
                        [settings]="settingLoadPort"
                        (change)="changeEventLoadPort($event)"
                        (keypress)="formValidation.AlphabetsIncludeSpace($event)"
                        (keyup)="handleBackspaceValueRegex($event,formValidation.regStringOnly, 'invPortOfLoadingName', true, '')"
                        (paste)="onPasteValidation(
                          $event,
                          'invPortOfLoadingName',
                          formValidation.regStringOnly,
                          true, '')"
                        style="width:100%;">
                      </combo-plugin>
                      <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invPortOfLoadingName'] != null && invoiceParam['error-invPortOfLoadingName'] != ''">
                        {{invoiceParam['error-invPortOfLoadingName']}}
                      </div>
                </div>
                <div class="field" style = "margin-top:8px" >
                      <label>Transshipment Port</label>
                      <combo-plugin
                        #cbTranshipmentPort
                        [settings]="settingTranshipmentPort"
                        (change)="changeEventTranshipmentPort($event)"
                        (keypress)="formValidation.AlphabetsIncludeSpace($event)"
                        (keyup)="handleBackspaceValueRegex($event,formValidation.regStringOnly, 'invPortOfTransshipmentName', true, '')"
                        (paste)="onPasteValidation(
                          $event,
                          'invPortOfTransshipmentName',
                          formValidation.regStringOnly,
                          true, '')"
                        style="width:100%;">
                      </combo-plugin>
                      <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invPortOfTransshipmentName'] != null && invoiceParam['error-invPortOfTransshipmentName'] != ''">
                        {{invoiceParam['error-invPortOfTransshipmentName']}}
                      </div>
                </div>
                <div class="field" style = "margin-top:8px" >
                      <label>Discharge Port</label>
                      <combo-plugin
                        #cbDischargePort
                        [settings]="settingDischargePort"
                        (change)="changeEventDischargePort($event)"
                        (keypress)="formValidation.AlphabetsIncludeSpace($event)"
                        (keyup)="handleBackspaceValueRegex($event,formValidation.regStringOnly, 'invPortOfDischargeName', true, '')"
                        (paste)="onPasteValidation(
                          $event,
                          'invPortOfDischargeName',
                          formValidation.regStringOnly,
                          true, '')"
                        style="width:100%;">
                      </combo-plugin>
                      <div class = "ui basic red pointing prompt label transition" *ngIf="invoiceParam['error-invPortOfDischargeName'] != null && invoiceParam['error-invPortOfDischargeName'] != ''">
                        {{invoiceParam['error-invPortOfDischargeName']}}
                      </div>
                </div>
              </div>
            </div>
          </div>
          <!-- field kiri baris 6 -->
          <div class="field">
                <div class="field" style="width:30%;align-self:center;">
                  <div class="ui test checkbox" >
                      <input type="checkbox" name="silentMode" tabindex="0" id="silentMode">
                      <label>Silent Mode</label>
                  </div>
                </div>
          </div>
        </div>
        <!-- END KIRI -->

        <!-- KANAN -->
        <div class="field">
          <!-- field kanan baris 1 -->
          <div class="two fields" >
           <div class="field" >
              <div class=" field"  >
                  <label>Bound: </label>
                  <!-- <input type="text" id="idBound" name="bound"  style="text-transform:uppercase" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" (change)="changeBound($event)"> -->
              </div>
                  <div class="ui radio checkbox" >
                      <input type="radio" id="selBoundI"  name="selectBy" tabindex="0" class="hidden"  (change)="changeBound($event)" value="I">
                      <label>I &nbsp;</label>
                  </div>

                  <div class="ui radio checkbox" >
                      <input type="radio" id="selBoundO"  name="selectBy" tabindex="0" class="hidden" checked (change)="changeBound($event)" value="O">
                      <label>O &nbsp;</label>
                  </div>
            </div>
            <div class="field">
              <label>No. of Set:</label>
              <input type="text" name="noofset"   [(ngModel)]="invoiceParam['invNumberOfPrintSet']" (change)="changeOfSet($event)">
            </div>
          </div>
          <div class="field" [class.error]="invoiceParam['error-invContainerOwnership'] != null && invoiceParam['error-invContainerOwnership'] != ''">
            <fieldset style="border: 1px solid lightgray;" id="myContainer">
              <legend style="font-weight:bold;color:dodgerblue;">Container Ownership </legend>
                <div class="five fields">
                  <div class="field">
                    <div class="ui test checkbox" >
                      <input type="checkbox"  name="containerOwner" id="cntCOC" tabindex="0" class="hidden" checked
                             (change)="changeContainerOwnershipCOC($event)">
                        <label>COC</label>
                    </div>
                  </div>
                  <div class="field">
                      <div class="ui test checkbox" >
                        <input type="checkbox"  name="containerOwner" id="cntCOCTS" tabindex="0" class="hidden"
                               (change)="changeContainerOwnershipCOCTS($event)">
                        <label>COCTS</label>
                      </div>
                  </div>
                  <div class="field">
                      <div class="ui test checkbox" >
                        <input type="checkbox"  name="containerOwner" id="cntSOC" tabindex="0" class="hidden"
                               (change)="changeContainerOwnershipSOC($event)">
                           <label>SOC</label>
                      </div>
                  </div>
                  <div class="field">
                      <div class="ui test checkbox" >
                        <input type="checkbox"  name="containerOwner" id="cntSOCTS" tabindex="0" class="hidden"
                               (change)="changeContainerOwnershipSOCTS($event)">
                          <label>SOCTS</label>
                      </div>
                  </div>
                  <div class="field">
                      <div class="ui test checkbox" >
                        <input type="checkbox"  name="containerOwner" id="cntSLOT" tabindex="0" class="hidden"
                               (change)="changeContainerOwnershipSLOT($event)">
                        <label >Slot</label>
                      </div>
                  </div>
                </div>
            </fieldset>
          </div>
          <div id="pdfContainerNewInvoice" style="display:none;">
            <div class="ui form">
              <div class="field" id="pdfContainerNewInvoice-label">
                  <label>&nbsp;please click on the Invoice No. to view.</label>
              </div>
              <div class="two fields">
                <div id='pdfContainerNewInvoice-grid-container' class="field" style="width:30%;">
                    <grid-plugin #gridPDFPrev [settings]="settingGridPDFPrev" (gridEvent)="gridEventPDFPrev($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                </div>
                <div id='pdfContainerNewInvoice-viewer-container' class="field" style="width:100%;height:570px;">
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END KANAN -->
      </div>
      <div class = "field">
            <div class="field" [style.display]="addRemoveButton" id="addRemoveButton">
              <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
              <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
            </div>

            <div class="field">
             <!-- <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                      -->
              <ag-grid-angular
                #gridProforma
                style = "width: 100%; height: 400px;"
                [getRowStyle]="getRowStyle"
                [isRowSelectable]="isRowSelectable"
                id="gridProforma"
                class = "ag-theme-balham"
                [enableSorting]="true"
                [enableFilter]="true"
                [enableColResize] = "true"
                [rowData]="rowDataProforma"
                [columnDefs]="columnDefs"
                rowSelection="multiple"
                [suppressRowClickSelection]="true"
                (gridReady)="onGridReady($event)"
                (cellValueChanged)="onCellValueChanged($event)"
                (cellKeyPress)="onCellKeyPress($event)">
              </ag-grid-angular>
            </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<div
  id="invDialogYesNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="question circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogInvYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogInvNo()">
        No
      </button>
    </div>
  </form>
</div>



<div
  id="invDialogSilentPrintYesNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="question circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogSilentPrintYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogSilentPrintNo()">
        No
      </button>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
