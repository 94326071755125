import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { url } from "inspector";
import { specialCharUtil } from "../shared";
declare var $: any;

export class EdiPortMapping {
  vesselId: String = "";
  vesselCode: String = "";
  vesselName: String = "";
  voyage: String = "";
  bound: String = "O";
  containerOwnership: String = "";

  dischargePortCode: String = "";
  replacementPortCode: String = "";

  ediPortMappingId: String = "";
  constructor() {}
}

@Component({
  selector: "app-edi-port-mapping-page",
  templateUrl: "./edi-port-mapping-page.component.html",
  styleUrls: ["./edi-port-mapping-page.component.css"],
})
export class EDIPortMappingPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbNewVessel") cbNewVessel: ComboPluginComponent;
  @ViewChild("cbNewVoyage") cbNewVoyage: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbReplacementPort") cbReplacementPort: ComboPluginComponent;
  @ViewChild("gridHeader") gridHeader: GridPluginComponent;

  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm = "sailingScheduleFlagStatus";

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGridHeader;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = true;
  dButtonLock = true;

  lock = false;

  model = new EdiPortMapping();
  sCharUtil = new specialCharUtil();

  settingVessel;
  settingVoyage;
  settingDischargePort;
  settingReplacementPort;
  settingNewVessel;
  settingNewVoyage;

  searchVesselCode: String = "";
  searchVoyage: String = "";
  searchBound: String = "";

  //previous search
  prevVesselId: String = "";
  prevVesselCode: String = "";
  prevVoyage: String = "";
  prevBound: String = "";

  radioContainerOwnerSOC: String = "N";
  radioContainerOwnerCOC: String = "N";

  prevSelectedRecord: number = 0;

  validatorRules = {};

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Create", event: "create", icon: "plus" },
        { name: "Save", event: "save", icon: "plus" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "EDI Port Mapping",
    };

    this.settingGridHeader = {
      id: "gridHeader",
      url: "", //this.configService.config.BASE_API.toString() + '/EdiPortMapping/findEdiPortMapping',
      page: 10,
      columns: [
        { header: "No", field: "no", width: 10 },
        { header: "Discharge Port", field: "dischargePortCode", width: 10 },
        { header: "Replacement Port", field: "replacementPortCode", width: 10 },
        {
          header: "Container Ownership",
          field: "containerOwnership",
          width: 10,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      store: "",
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingDirection: "ASC",
      sortingColumns: "dischargePort",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingNewVessel = {
      id: "cbNewVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingNewVoyage = {
      id: "cbNewVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Discharge Port",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
      ],
    };

    this.settingReplacementPort = {
      id: "cbReplacementPort",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Replacement Port",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };
  }
  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    // this.gridHeader.search = "*/*/*";
    // this.gridHeader.onFirst()

    console.log("ngAfter");
    console.log(this.radioContainerOwnerSOC);

    this.radioContainerOwnerCOC = "Y";
    this.model.containerOwnership = "COC";

    this.gridHeader.search = "*/*";
    this.setValidatorRetrieve();

    this.invisibleToolbarButtons = "save";
    this.disableToolbarButtons = "delete,create";
    this.gridHeader.loadData();
  }

  toolbarEvent(event) {
    console.log("event : " + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "close":
        // handle close event
        if (this.modeForm === "sailingScheduleFlagStatus") {
          this.router.navigateByUrl("/main/home");
        } else {
          this.modeForm = "sailingScheduleFlagStatus";
          this.disableToolbarButtons = "delete";
          this.invisibleToolbarButtons = "save";
        }

        break;
      case "cancel":
        this.onCancel();
        //this.hideMe()
        break;
      case "create":
        this.handleCreate();
        break;
      case "save":
        this.handleSave();
        break;
      case "delete":
        this.handleDelete();
        break;
    }
  }

  gridEvent(event) {
    console.log(event);
    console.log(this.gridHeader.getSelectedValues().length);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.gridHeader.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "retrieve";
        } else {
          this.disableToolbarButtons = "retrieve,update,delete";
        }
        break;
      case "click":
        if (this.gridHeader.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "retrieve";
        } else {
          this.disableToolbarButtons = "retrieve,update,delete";
        }

        console.log("length");
        console.log(this.gridHeader.getSelectedValues().length);
        console.log(this.gridHeader.listStore.store.length);
        if (
          this.gridHeader.getSelectedValues().length ==
          this.gridHeader.listStore.store.length
        ) {
          this.gridHeader.onSelectAllByValue(true);
        } else {
          this.gridHeader.is_select_all = "";
        }

        break;
      case "afterLoad":
        this.gridHeader.clearSelectedValues();
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          console.log("2");
          console.log(arr[0]);
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              //store previous search profile
              console.log(this.model);
              this.prevVesselId = this.model.vesselId;
              this.prevVesselCode = this.model.vesselCode;
              this.prevVoyage = this.model.voyage;
              this.prevBound = this.model.bound.toUpperCase();

              this.modeForm = "sailingScheduleMaintenance";
              this.invisibleToolbarButtons = "retrieve,create,delete";

              this.setData();
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false);
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    console.log("test");
    console.log(event);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.vesselId = "";
    } else {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.bound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
      this.gridHeader.onClear();
    }
  }

  changeEventNewVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.vesselId = "";
    } else {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbNewVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.bound +
          "/voyage_like={query}"
      );
      //this.cbNewVoyage.setValue("");
    }
  }

  changeNewEventVoyage(event) {
    console.log(event);
    this.model.voyage = event.voyage;
  }

  changeEventVoyage(event) {
    this.model.voyage = event.voyage;
  }

  changeEventBound(event) {
    this.model.bound = event.bound;
  }

  changeEventDischargePort(event) {
    console.log(event);
    this.model.dischargePortCode = event.locationCode;
  }

  changeEventReplacementPort(event) {
    console.log(event);
    this.model.replacementPortCode = event.locationCode;

    this.cbReplacementPort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlReplacementPort/locationName={query}"
    );
  }

  changeEventTransmit(event) {
    console.log(event.target.value);
    console.log("after changeEventTransmit");
    console.log(this.radioContainerOwnerSOC);

    this.model.containerOwnership = event.target.value;
    if (event.target.value.toString().trim().toUpperCase() == "COC") {
      this.radioContainerOwnerCOC = "Y";
      this.radioContainerOwnerSOC = "N";
    } else {
      this.radioContainerOwnerSOC = "Y";
      this.radioContainerOwnerCOC = "N";
    }
  }

  hideMe() {
    this.modeForm = "sailingScheduleFlagStatus";
    this.invisibleToolbarButtons = "save,cancel";
    this.disableToolbarButtons = "create,delete";

    this.cbDischargePort.setValue("");
    this.cbReplacementPort.setValue("");

    this.radioContainerOwnerCOC = "Y";
    this.model.containerOwnership = "COC";

    // this.model = new EdiPortMapping
    // this.cbNewVessel.setValue("")
    // this.cbVessel.setValue("")
    // this.cbVoyage.setValue("")
    // this.cbNewVoyage.setValue("")

    // this.setValidatorRetrieve();

    // this.gridHeader.onClear()
    // this.gridHeader.clearSelectedValues()
  }

  handleCreate() {
    console.log("before create");
    console.log(this.radioContainerOwnerSOC);

    this.modeForm = "sailingScheduleMaintenance";
    this.invisibleToolbarButtons = "retrieve,create,delete";
    this.cbNewVessel.disableCombo = true;
    // GSO-1092 Wrong Vessel Code Processed Upon Saving
    this.cbNewVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselCode={query}"
    );
    this.cbNewVessel.setValue(String(this.model.vesselCode), ()=>{
      this.cbNewVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselName={query}"
      );
    });

    this.cbNewVoyage.disableCombo = true;
    this.cbNewVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.vesselId +
        "/" +
        this.model.bound +
        "/voyage_like={query}"
    );
    this.cbNewVoyage.setValue(String(this.model.voyage));

    this.cbDischargePort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/EdiPortMapping/findDischargePort/" +
        this.model.vesselCode +
        "/" +
        this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
        "/" +
        this.model.bound +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/1/5/ASC/locationName_like={query}"
    );
    this.cbDischargePort.setValue("");

    this.cbReplacementPort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlReplacementPort/locationName={query}"
    );
    this.cbReplacementPort.setValue("");

    console.log("after create");
    console.log(this.radioContainerOwnerSOC);

    this.radioContainerOwnerCOC = "Y";
    this.model.containerOwnership = "COC";

    this.model["error-dischargePort"] = "";
    this.model["error-replacementPort"] = "";
    this.model.dischargePortCode = "";
    this.model.replacementPortCode = "";
  }

  handleRetrieve() {
    console.log(this.model);
    this.model["error-vesselName"] = "";
    this.model["error-voyage"] = "";
    this.model["error-bound"] = "";

    let isPassedValidation: boolean = this.onValidate(this.model);
    console.log(isPassedValidation);
    if (!isPassedValidation) {
      this.searchVoyage = this.model.voyage != "" ? this.model.voyage : "*";
      this.searchVesselCode = this.model.vesselId;
      this.searchBound = this.model.bound.toUpperCase();

      this.gridHeader.search = "*";
      let hitUrl =
        this.configService.config.BASE_API.toString() +
        "/EdiPortMapping/findByVesselVoyageBound/" +
        this.model.vesselCode +
        "/" +
        this.sCharUtil.htmlEncode(String(this.searchVoyage).trim()) +
        "/" +
        this.model.bound.toUpperCase();
      this.gridHeader.url = hitUrl;
      this.gridHeader.loadData();

      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/EdiPortMapping/findSailingVessel/" +
            this.model.vesselCode +
            "/" +
            this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
            "/" +
            this.model.bound +
            "/" +
            this.cookieService.getDefaultLocationCode()
        )
        .subscribe((resp) => {
          console.log(resp);
          if (resp.json()["status"] == "OK") {
            this.disableToolbarButtons = "delete";
          } else {
            this.message(
              "information",
              "Information",
              "No Record Data on Sailing schedule.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        });

      // console.log(this.gridHeader)

      // if(this.gridHeader.totalPage != 0){
      //   this.loading = false
      // }else{
      //   this.message('information','Information','No Record Data.', 'okonly',{ok: 'this.loading=false;'});
      // }
    }
  }

  handleSave() {
    this.model["error-dischargePort"] = "";
    this.model["error-replacementPort"] = "";

    console.log(this.model);

    let isPassedValidation: boolean = this.onValidate(this.model);

    if (
      this.model.dischargePortCode === "" ||
      this.model.dischargePortCode == undefined
    ) {
      isPassedValidation = true;
      this.model["error-dischargePort"] = "Please input Discharge Port.";
      console.log("masuk");
    }

    if (
      this.model.replacementPortCode === "" ||
      this.model.replacementPortCode == undefined
    ) {
      isPassedValidation = true;
      this.model["error-replacementPort"] = "Please input Replacement Port.";
      console.log("masuk juga");
    }

    // if(this.model.dischargePortCode === this.model.replacementPortCode && this.model.dischargePortCode != ""
    // && this.model.replacementPortCode != "" && this.model.replacementPortCode != undefined && this.model.dischargePortCode != undefined){
    //   console.log("masuk sini dong")
    //   this.message('warning','Warning','Similar Discharge Port and Replacement Port detected.','okonly',{ok: 'this.loading=false;'});
    // }

    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      ////console.log("PSI Date => " + this.genericUtil.getPSIDate());
      if (
        this.model.dischargePortCode === this.model.replacementPortCode &&
        this.model.dischargePortCode != "" &&
        this.model.replacementPortCode != "" &&
        this.model.replacementPortCode != undefined &&
        this.model.dischargePortCode != undefined
      ) {
        console.log("masuk sini dong");
        this.message(
          "warning",
          "Warning",
          "Similar Discharge Port and Replacement Port detected.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        console.log("sini");
        //this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          { yes: "this.saveEvent()", no: "this.loading = false;" }
        );
      }
    } else {
      this.loading = false;
      console.log("error");
    }
  }

  handleDelete() {
    // handle delete event
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  saveEvent() {
    //this.loading = true;
    console.log("sini 2");
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/EdiPortMapping/save",
        this.model
      )
      .subscribe(
        (resp) => {
          console.log(resp.json()["status"]);
          //console.log(resp.json()['message'])
          if (resp.status == 200 && resp.json()["status"] == "Create") {
            //this.model = new EdiPortMapping;
            this.loading = false;
            this.info = "save successfully";
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.afterSave();" }
            );
          } else if (resp.status == 200 && resp.json()["status"] == "Update") {
            //this.model = new EdiPortMapping;
            this.model.dischargePortCode = "";
            this.model.replacementPortCode = "";
            this.model.ediPortMappingId = "";
            this.loading = false;
            this.info = "save successfully";
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.afterSave();" }
            );
          } else if (resp.status == 200 && resp.json()["status"] == "invalid") {
            this.loading = false;
            this.message(
              "information",
              "Warning",
              "Invalid port detected.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              "Duplicate Port Mapping detected.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    console.log("Delete Event");
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.gridHeader.getSelectedValues().forEach((element) => {
      console.log(element);
      deleteList.push(element.ediPortMappingId);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/EdiPortMapping/delete",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            //this.model = new EdiPortMapping;

            //reset model
            this.model.dischargePortCode = "";
            this.model.replacementPortCode = "";
            this.model.containerOwnership = "COC";
            this.radioContainerOwnerCOC = "Y";
            this.radioContainerOwnerSOC = "N";

            this.loading = false;
            this.gridHeader.clearSelectedValues();
            this.gridHeader.onFirst();
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterSave() {
    this.modeToolbar = false;
    this.disableToolbarButtons = "delete";
    this.invisibleToolbarButtons = "save";

    this.gridHeader.search = "*";
    this.setValidatorRetrieve();

    this.gridHeader.clearSelectedValues();
    this.gridHeader.onFirst();

    //this.model = new EdiPortMapping;
    this.modeForm = "sailingScheduleFlagStatus";

    this.gridHeader.loadData();

    console.log("after save");
    console.log(this.radioContainerOwnerSOC);
  }

  afterDelete() {
    this.modeToolbar = false;
    this.disableToolbarButtons = "delete";
    this.invisibleToolbarButtons = "save";

    //reset model
    this.model.dischargePortCode = "";
    this.model.replacementPortCode = "";
    this.model.containerOwnership = "COC";
    this.radioContainerOwnerCOC = "Y";
    this.radioContainerOwnerSOC = "N";

    //this.model = new EdiPortMapping

    this.gridHeader.clearSelectedValues();
    this.gridHeader.loadData();
  }

  onProceed() {
    this.handleSave();
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    var self = this;
    let data = this.gridHeader.getSelectedValues()[0];

    console.log(data);
    this.model = new EdiPortMapping();

    this.model.vesselName = data.vesselName;
    this.cbNewVessel.setValue(data.vesselName);

    this.model.voyage = data.voyage;
    this.cbNewVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        data.vesselId +
        "/" +
        data.bound +
        "/voyage_like={query}"
    );
    this.cbNewVoyage.setValue(String(this.model.voyage));

    console.log("discharger" + data.dischargePortCode);
    this.model.dischargePortCode = data.dischargePortCode;
    //this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*');
    this.model.vesselCode = data.vesselCode;
    //this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + "/EdiPortMapping/findDischargePort/" + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.cookieService.getDefaultLocationCode() + "/1/5/ASC/locationNameAndLocationCode={query}");
    this.cbDischargePort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationCode={query}"
    );
    this.cbDischargePort.setValue(
      String(this.model.dischargePortCode),
      function (func) {
        self.cbDischargePort.setUrl(
          self.configService.config.BASE_API.toString() +
            "/EdiPortMapping/findDischargePort/" +
            self.model.vesselCode +
            "/" +
            self.sCharUtil.htmlEncode(String(self.model.voyage).trim()) +
            "/" +
            self.model.bound +
            "/" +
            self.cookieService.getDefaultLocationCode() +
            "/1/5/ASC/locationNameAndLocationCode={query}"
        );
      }
    );

    this.model.replacementPortCode = data.replacementPortCode;
    //this.cbReplacementPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*');
    this.cbReplacementPort.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlReplacementPort/locationCode={query}"
    );
    this.cbReplacementPort.setValue(String(this.model.replacementPortCode));

    console.log(data.containerOwnership);
    if (data.containerOwnership != "COC") {
      this.radioContainerOwnerSOC = "Y";
      this.radioContainerOwnerCOC = "N";
    } else {
      console.log("keluar");
      this.radioContainerOwnerCOC = "Y";
      this.radioContainerOwnerSOC = "N";
    }
    console.log(this.radioContainerOwnerSOC);
    console.log(this.radioContainerOwnerCOC);
    this.model.containerOwnership = data.containerOwnership;

    this.model.ediPortMappingId = data.ediPortMappingId;
  }

  setValidatorRetrieve() {
    this.validatorRules = {
      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input voyage.",
          },
        ],
      },
      vesselName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
    };
  }

  infoGrid(event) {}

  onCancel() {
    if (this.modeForm === "sailingScheduleFlagStatus") {
      this.modeToolbar = false;
      this.lock = false;

      this.gridHeader.url = "";
      this.gridHeader.onClear();
      this.gridHeader.clearSelectedValues();
      this.gridHeader.loadData();

      this.invisibleToolbarButtons = "save";
      this.disableToolbarButtons = "delete,create";

      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.cbNewVessel.setValue("");
      this.cbNewVoyage.setValue("");

      this.model = new EdiPortMapping();
    } else {
      console.log("masuk sini");
      console.log(this.model.containerOwnership);
      this.model["error-vesselName"] = "";
      this.model["error-voyage"] = "";
      this.model["error-dischargePort"] = "";
      this.model["error-replacementPort"] = "";

      this.cbDischargePort.setValue("");
      this.model.dischargePortCode = "";
      this.cbReplacementPort.setValue("");
      this.model.replacementPortCode = "";
      if (this.model.containerOwnership == "COC") {
        this.radioContainerOwnerCOC = "Y";
        this.radioContainerOwnerSOC = "N";
      } else {
        this.radioContainerOwnerCOC = "N";
        this.radioContainerOwnerSOC = "Y";
      }
      // this.disableToolbarButtons = "retrieve,delete";
      // this.invisibleToolbarButtons = "save,cancel";
    }
  }
}
