<div class="ui raised segment">
  <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
    </ul>
  </div>

  <!--<div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-bOceanShipper'] == null || param['error-bOceanShipper'] == ''">
        {{param['error-bOceanVesselCode']}}
      </li>
    </ul>
  </div>-->

  <div id='container'>
    <form class="ui form">
      <div class="field">
        <fieldset style="border: 1px solid lightgray;height:70px">
          <legend style="font-weight:bold;color:dodgerblue;">B/L Search</legend>
          <div class="three fields">
            <div class="one field inline" style="width:100%" [class.error]="modelM['error-blNo'] != null && modelM['error-blNo'] != ''">
              <label>BL Ref. </label>
              <input type="text" name="BlNo" placeholder="Bl Number" [(ngModel)]="modelM['blNo']" (ngModelChange)="modelM['error-blNo']=''"  >
              <div class = "ui basic red pointing prompt label transition error-message-hidden"
                   [class.error-message-visible]="modelM['error-blNo'] != null && modelM['error-blNo'] != ''">
                {{modelM['error-blNo']}}
              </div>
            </div>
            <div class="one field inline" style="width:100%">
              <label>BKG Ref.</label>
              <input type="text" name="BKG" placeholder="BKG" [(ngModel)]="param['BKG']" >
            </div>
            <div class="three field inline" style="width:100%">
              <label>Shipper Draft BL. Ref.</label>
              <input type="text" name="Shipper" placeholder="Shipper" [(ngModel)]="modelM['ShipperDraftBlNo']" >
            </div>
          </div>
          <!--/div-->
        </fieldset>
      </div>

      <div class="ui top attached tabular menu">
        <a class="item active" data-tab="first">Party</a>
        <a class="item" data-tab="second">Place</a>
        <a class="item" data-tab="third">Container</a>
        <a class="item" data-tab="fourth">Mark No.</a>
        <a class="item" data-tab="fiveth">Description</a>
        <a class="item" data-tab="sixth">Nett Charge</a>
        <a class="item" data-tab="seventh">Gross Charge</a>
      </div>

      <div class="ui bottom attached tab segment active" data-tab="first">
        <!-- Bagian Pertama -->
        <div class="two wide fields">
          <fieldset style="width: 100%;border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Shipper</legend>
            <div class="two wide fields">
              <div class="one field">
                <div class="fields" style="width:100%;">
                  <div class="one field" style="width:60%" [class.error]="modelM['error-blShipperId'] != null && modelM['error-blShipperId'] != ''">
                    <combo-plugin #cbShipper [settings]="settingShipper" (change)="changeEventShipper($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blShipperId'] != null && modelM['error-blShipperId'] != ''">
                      {{modelM['error-blShipperId']}}
                    </div>
                  </div>
                </div>
                <div class="fields" style="width:100%;" [class.error]="param['error-txtShipper'] != null && param['error-txtShipper'] != ''">
                  <div class="one field" style="width:60%" [class.error]="param['error-txtShipper'] != null && param['error-txtShipper'] != ''">
                    <textarea-plugin #txtShipper [settings]="settingTxtShipper"></textarea-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="param['error-txtShipper'] != null && param['error-txtShipper'] != ''">
                      {{param['error-txtShipper']}}
                    </div>
                    <!-- <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin> -->
                  </div>
                </div>
              </div>

              <div class="one field">

                <div class="fields" style="width: 100%">
                  <div class="one field" style="width:60%" >
                    <label>Shipper BL. No.</label>
                    <input type="text" name="ShipperBlNo" placeholder="ShipperBlNo" [(ngModel)]="modelM['ShipperBlNo']"
                    >
                  </div>
                </div>

                <div class="fields" style="width:100%">
                  <div class="three field" style="width:60%">
                    <label>Shipper BKG No.</label>
                    <textarea name="BKGNo" style="font-size:12px;" [(ngModel)]="modelM['BKGNo']" placeholder=""></textarea>
                  </div>
                </div>

                <div class="field">
                  <div class="ui checkbox">
                    <input type="checkbox" [checked]="isCom == true" [(ngModel)]="modelM['isCom']" name="isCom" (change)="changeContainerCom($event)" tabindex="0">
                    <label style="font-size:12px">Skip print Shipper Draft BL No. </label>
                  </div>
                </div>

                <div class="field">
                  <label> </label>
                </div>

                <div class="fields" style="width:100%;">
                  <div class="one field" style="width:60%" >
                    <label>Shipper EDI Ref.</label>
                    <input type="text" name="ShipperEDI" placeholder="ShipperEDI" [(ngModel)]="param['ShipperEDI']" >
                  </div>
                </div>

              </div>

            </div>

          </fieldset>
        </div>
        <!-- Bagian ke dua -->

        <div class="two wide fields">
          <fieldset style="width: 100%; border: 0px;">

            <div class="one field">
              <div class="two wide fields" style="width: 100%">
                <fieldset style="width: 100%;">
                  <legend style="font-weight:bold;color:dodgerblue;">Consignee</legend>
                  <div class="fields" style="width:70%; float: left">
                    <div class="one field" style="width:60%" [class.error]="modelM['error-blConsigneeId'] != null && modelM['error-blConsigneeId'] != ''">
                      <combo-plugin #cbConsignee [settings]="settingConsignee" (change)="changeEventConsignee($event)" style="width:100%;"></combo-plugin>
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelM['error-blConsigneeId'] != null && modelM['error-blConsigneeId'] != ''">
                        {{modelM['error-blConsigneeId']}}
                      </div>
                    </div>
                  </div>
                  <div class="fields" style="width:70%; float: left">
                    <div class="one field" style="width:60%" [class.error]="param['error-txtConsignee'] != null && param['error-txtConsignee'] != ''">
                      <textarea-plugin #txtConsignee [settings]="settingTxtConsignee" (change)="changeConsigneeTxt($event)" ></textarea-plugin>
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="param['error-txtConsignee'] != null && param['error-txtConsignee'] != ''">
                        {{param['error-txtConsignee']}}
                      </div>
                      <!-- <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin> -->
                    </div>
                  </div>

                  <div class="fields" style="width: 30%; float: right">
                    <div class="one field">
                      <button style="width: 220px" class="ui button" (click)="toOrder($event)">To Order</button>
                    </div>
                  </div>
                  <div class="fields" style="width: 30%; float: right">
                    <div class="one field">
                      <button style="width: 220px"  class="ui button" (click)="toOrderShipper($event)">To Order of Shipper</button>
                    </div>
                  </div>
                </fieldset>
                <div class="one field">

                <fieldset style="width: 100%; ">
                  <legend style="font-weight:bold;color:dodgerblue;"> BL Type </legend>

                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isMemo == true" (change)="changeEventMemo($event)"  checked="checked"  >
                      <label> Memo BL </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isSW == true" checked="checked" (change)="changeEventSw($event)">
                      <label> &nbsp;SW &nbsp; </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency"[checked]="isCRB == true" checked="checked">
                      <label> CRB </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isNA == true" checked="checked">
                      <label> N/A </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isRFS == true" checked="checked">
                      <label> RFS &nbsp; &nbsp; </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isOIS == true" checked="checked">
                      <label> OIS &nbsp; </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="frequency" [checked]="isIRE == true"  checked="checked">
                      <label> IRE </label>
                    </div>
                  </div>
                </fieldset>
                <br>
                <fieldset style="width: 100%; ">
                  <legend style="font-weight:bold;color:dodgerblue;"> Container Ownership </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="ownership" checked="checked">
                      <label> COC </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="ownership" checked="checked">
                      <label> COC T/S </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="ownership" checked="checked">
                      <label> SLOT </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="ownership" checked="checked">
                      <label> SOC </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="ownership" checked="checked">
                      <label> SOC T/S </label>
                    </div>

                  </div>
                  <div class="field">
                    <label>OP Code </label>
                    <input type="text" name="OPCode" placeholder="OP Code" style="width:100%;" [(ngModel)]="param['OPCode']" (change)="changeEventOPCode($event)" style="width: 50%;">
                  </div>
                </fieldset>
                <br>
                <fieldset style="width: 100%; ">
                  <legend style="font-weight:bold;color:dodgerblue;"> Container Status </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="containerstatus" checked="checked">
                      <label> Empty </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="containerstatus" checked="checked">
                      <label> Full </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              </div>
              <div class="two wide fields" style="width: 100%">
                <fieldset style="width: 100%;">
                  <legend style="font-weight:bold;color:dodgerblue;">Notify Party</legend>
                  <div class="fields" style="width:70%; float: left">
                    <div class="one field" style="width:60%" [class.error]="modelM['error-blNotifyPartyId'] != null && modelM['error-blNotifyPartyId'] != ''">
                      <combo-plugin #cbNotify [settings]="settingNotify" (change)="changeEventNotify($event)" style="width:100%;"></combo-plugin>
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelM['error-blNotifyPartyId'] != null && modelM['error-blNotifyPartyId'] != ''">
                        {{modelM['error-blNotifyPartyId']}}
                      </div>
                    </div>
                  </div>
                  <div class="fields" style="width:70%; float: left">
                    <div class="one field" style="width:60%" [class.error]="param['error-txtNotify'] != null && param['error-txtNotify'] != ''">
                      <textarea-plugin #txtNotify [settings]="settingTxtNotify"></textarea-plugin>

                      <!-- <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin> -->
                    </div>
                  </div>
                  <div class="fields" style="width: 30%; float: right">
                    <div class="one field">
                      <button style="width: 220px" class="ui button" (click)="toOrderCSGN($event)">As CSGN</button>
                    </div>
                  </div>
                  <div class="fields" style="width: 30%; float: right">
                    <div class="one field">
                      <button style="width: 220px"  class="ui button" (click)="toOrderSameAsCSGN($event)"> Same AS CSGN</button>
                    </div>
                  </div>

                </fieldset>
                <div class="one field">
                <fieldset style="width: 100%; ">
                  <legend style="font-weight:bold;color:dodgerblue;"> DG Class </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="DG" checked="checked">
                      <label> C3 </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="DG" checked="checked">
                      <label> C2 </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="DG" checked="checked">
                      <label> C2F </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="DG" checked="checked">
                      <input type="radio" name="DG" checked="checked">
                      <label> C1 </label>
                    </div>
                    <div class="ui radio checkbox">
                      <input type="radio" name="DG" checked="checked">
                      <label> N/A </label>
                    </div>
                  </div>
                </fieldset>
                <br>
                <fieldset style="width: 100%; ">
                  <legend style="font-weight:bold;color:dodgerblue;"> Detention </legend>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="Detention" checked="checked">
                      <label> N/A </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="Detention" checked="checked">
                      <label> Standard </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input type="radio" name="Detention" checked="checked">
                      <label> Request </label>
                    </div>
                    <div class="field" style="width: 30%">
                      <!--
                      <input type="text" name="DetentionCode" placeholder=" - " style="width:100%;" [(ngModel)]="param['DetentionCode']" (change)="changeEventDetentionCode($event)">
                    -->
                    </div>
                  </div>
                </fieldset>
                  <br>
                  <fieldset style="width: 100%; ">
                    <legend style="font-weight:bold;color:dodgerblue;"> Demurrage </legend>
                    <div class="field">
                      <div class="ui radio checkbox">
                        <input type="radio" name="Demurrage" checked="checked">
                        <label> N/A </label>
                      </div>
                    </div>
                    <div class="field">
                      <div class="ui radio checkbox">
                        <input type="radio" name="Demurrage" checked="checked">
                        <label> Standard </label>
                      </div>
                    </div>
                    <div class="field">
                      <div class="ui radio checkbox">
                        <input type="radio" name="Demurrage" checked="checked">
                        <label> Request </label>
                      </div>
                      <div class="field" style="width: 30%">
                        <!--
                        <input type="text" name="DemurrageCode" placeholder=" - " style="width:100%;" [(ngModel)]="param['DemurrageCode']" (change)="changeEventDemurrageCode($event)">
                      -->
                      </div>
                    </div>
                  </fieldset>
              </div>
              </div>
              <div class="one field" style="width: 40%">
                <fieldset style="height: 5%; border: 0px;"></fieldset>

              </div>
            </div>
          </fieldset>
        </div>

        <!-- bagian ke tiga -->

        <div class="two wide fields">
          <fieldset style="width: 100%; border: 0px;">

            <div class="two wide fields">
              <div class="one field" style="width: 40%">
              </div>

            </div>

          </fieldset>
        </div>

      </div>

      <div class="ui bottom attached tab segment" data-tab="second">
        <div class="two wide fields">
          <div class="one field">

            <!--
            <fieldset style="width: 100%">
            <div class="two wide field">
              <div class="one field">
                <div class="field" style="width:100%">
                  <label> Delivery Type </label>
                  <combo-plugin #cbDeliveryType [settings]="settngDeliveryType" (change)="changeEventDeliveryType($event)" style="width:100%;"></combo-plugin>
                </div>
              </div>
              <div class="one field">
                <div class="field" style="width:100%">
                  <label> Recipt Type </label>
                  <combo-plugin #cbReciptType [settings]="settingReciptType" (change)="changeEventReciptType($event)" style="width:100%;"></combo-plugin>
                </div>
              </div>
            </div>
            </fieldset>
            -->

            <div class="two wide fields">
              <div class="one field">
                <fieldset style="width: 100%; border: 1px;">
                  <div class="field" style="width:100%" >
                    <label> Receipt Type </label>
                    <combo-plugin #cbReceiptType [settings]="settingReceiptType" (change)="changeEventReceiptType($event)" style="width:100%;"></combo-plugin>

                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blPlaceOfReceipt'] != null && modelM['error-blPlaceOfReceipt'] != ''">
                    <label> Place Of Receipt </label>
                    <combo-plugin #cbReceiptPlace [settings]="settingReceiptPlace" (change)="changeEventReceiptPlace($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blPlaceOfReceipt'] != null && modelM['error-blPlaceOfReceipt'] != ''">
                      {{modelM['error-blPlaceOfReceipt']}}
                    </div>
                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blLoadPort'] != null && modelM['error-blLoadPort'] != ''">
                    <label> Load Port </label>
                    <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blLoadPort'] != null && modelM['error-blLoadPort'] != ''">
                      {{modelM['error-blLoadPort']}}
                    </div>
                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blDischargePort'] != null && modelM['error-blDischargePort'] != ''">
                    <label> Discharge Port </label>
                    <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blDischargePort'] != null && modelM['error-blDischargePort'] != ''">
                      {{modelM['error-blDischargePort']}}
                    </div>
                    </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blPlaceOfDelivery'] != null && modelM['error-blPlaceOfDelivery'] != ''">
                    <label> Place Of Delivery </label>
                    <combo-plugin #cbPODelivery [settings]="settingPODelivery" (change)="changeEventPODelivery($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blPlaceOfReceipt'] != null && modelM['error-blPlaceOfDelivery'] != ''">
                      {{modelM['error-blPlaceOfDelivery']}}
                    </div>
                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blFinalDestination'] != null && modelM['error-blFinalDestination'] != ''">
                    <label> Final Destination </label>
                    <combo-plugin #cbFinalDestination [settings]="settingFinalDestination" (change)="changeEventFinalDestination($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blFinalDestination'] != null && modelM['error-blFinalDestination'] != ''">
                      {{modelM['error-blFinalDestination']}}
                    </div>
                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-POT1'] != null && modelM['error-POT1'] != ''">
                    <label> Transhipment Port 1 </label>
                    <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="param['error-POT1'] != null && param['error-POT1'] != ''">
                      {{param['error-POT1']}}
                    </div>
                  </div>

                  <div class="field" style="width:100%">
                    <label> Transhipment Port 2 </label>
                    <combo-plugin #cbPOT2 [settings]="settingPOT2" (change)="changeEventPOT2($event)" style="width:100%;"></combo-plugin>

                  </div>

                  <div class="field" style="width:100%">
                    <label> Transhipment Port 3 </label>
                    <combo-plugin #cbPOT3 [settings]="settingPOT3" (change)="changeEventPOT3($event)" style="width:100%;"></combo-plugin>
                  </div>

                  <div class="field" style="width:100%" [class.error]="modelM['error-blServiceId'] != null && modelM['error-blServiceId'] != ''" >
                    <label> Service </label>
                    <combo-plugin #cbService [settings]="settingService" (change)="changeEventService($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelM['error-blServiceId'] != null && modelM['error-blServiceId'] != ''">
                      {{modelM['error-blServiceId']}}
                    </div>
                  </div>

                </fieldset>
              </div>
              <div class="one field">
                <fieldset style="width: 100%; border: 1px;">
                  <div class="two field" style="width:100%" >
                    <label>Delivery Type </label>
                    <combo-plugin #cbDeliveryType [settings]="settingDeliveryType" (change)="changeEventDeliveryType($event)" style="width:100%;"></combo-plugin>

                  </div>

                  <div class="field" >
                    <label>B/L Print</label>
                    <input type="text" maxlength="50" name="ReceiptPlacePrint" placeholder="Receipt Place" style="width:100%;" [(ngModel)]="modelM['blPlaceOfPrint']" (change)="changeEventReceiptPlace($event)">
                  </div>

                  <div class="field" >
                    <label> &nbsp; </label>
                    <input type="text" maxlength="50" name="LoadPort" placeholder="Load Port" style="width:100%;" [(ngModel)]="modelM['blLoadPortPrint']" (change)="changeEventLoadPort($event)">
                  </div>

                  <div class="field" >
                    <label> &nbsp; </label>
                    <input type="text" maxlength="50" name="DischargePort" placeholder="Discharge Port" style="width:100%;" [(ngModel)]="modelM['blDischargePortPrint']" (change)="changeEventDischargePort($event)">
                  </div>

                  <div class="field" >
                    <label> &nbsp; </label>
                    <input type="text" maxlength="50" name="PODelivery" placeholder="Port Of Delivery" style="width:100%;" [(ngModel)]="modelM['blPlaceOfDeliveryPrint']" (change)="changeEventPODelivery($event)">
                  </div>

                  <div class="field" >
                    <label> &nbsp; </label>
                    <input type="text" maxlength="50" name="FinalDestination" placeholder="Final Destination" style="width:100%;" [(ngModel)]="modelM['blFinalDestinationPrint']" (change)="changeEventFinalDestination($event)">
                  </div>

                  <div class="two field" style="width:100%" [class.error]="param['error-Principal'] != null && param['error-Principal'] != ''">
                    <label>Principal </label>
                    <combo-plugin #cbPrincipal [settings]="settingPrincipal" (change)="changeEventPrincipal($event)" style="width:100%;"></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="param['error-Principal'] != null && param['error-Principal'] != ''">
                      {{param['error-Principal']}}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div class="one field">

            <fieldset style="width: 100%;border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Pre Vessel</legend>

              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheck == true" [(ngModel)]="modelM['skipCheckPreVessel']" name="skipCheck" (change)="changeSkipCheck($event)">
                  <label style="font-size:12px">Skip check</label>
                </div>
              </div>
              <div class="three wide fields">

                <div class="ui field" [class.error]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:30%;"></combo-plugin>

                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
                    {{param['error-bOceanVesselCode']}}
                  </div>
                </div>

                <div class="ui field" [class.error]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:30%;"></combo-plugin>

                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
                    {{param['error-bOceanVesselVoyage']}}
                  </div>
                </div>

                <div class="ui field" [class.error]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
                  <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="modelM['blPreVesselBound']" (change)="param['error-bound'] = '';updateVoyageUrl('header');" [disabled] = "retLock" style="width: 30%">

                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
                    {{param['error-bound']}}
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheckPrintPreVessel == true" [(ngModel)]="modelM['printPreVessel']" name="skipCheckPrintPreVessel" (change)="changeskipCheckPrintPreVessel($event)" >
                  <label style="font-size:12px">Print Pre-Vessel Clause on BL</label>
                </div>
              </div>


            </fieldset>
            <fieldset style="width: 100%;border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Ocean Vessel</legend>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheckOceanVessel == true" [(ngModel)]="modelM['skipCheckOceanVessel']" name="skipCheckOceanVessel" (change)="changeSkipCheckOceanVessel($event)" >
                  <label style="font-size:12px">Skip check</label>
                </div>
              </div>
              <div class="three wide fields">
                <div class="ui field" [class.error]="modelM['error-blOceanVesselId'] != null && modelM['error-blOceanVesselId'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbOceanVessel [settings]="settingOceanVessel" (change)="changeEventOceanVessel($event)" style="width:30%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="modelM['error-blOceanVesselId'] != null && modelM['error-blOceanVesselId'] != ''">
                    {{modelM['error-blOceanVesselId']}}
                  </div>
                </div>
                <div class="ui field" [class.error]="modelM['error-blOceanVesselVoyage'] != null && modelM['error-blOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbOceanVoyage [settings]="settingOceanVoyage" (change)="changeOceanEventVoyage($event)" style="width:30%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="modelM['error-blOceanVesselVoyage'] != null && modelM['error-blOceanVesselVoyage'] != ''">
                    {{modelM['error-blOceanVesselVoyage']}}
                  </div>
                </div>
                <div class="ui field" [class.error]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
                  <label>Bound</label>
                  <input type="text" name="Oceanbound" placeholder="Bound" [(ngModel)]="modelM['blOceanVesselBound']" (change)="param['error-bound'] = '';updateVoyageUrl('header');"  [disabled] = "retLock" style="width: 30%">
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
                    {{param['error-bOceanVesselBound']}}
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheckPrintOceanVessel == true" [(ngModel)]="modelM['printOceanVessel']" name="skipCheckPrintOceanVessel" (change)="changeSkipCheckPrintOceanVessel($event)" >
                  <label style="font-size:12px">Print Ocean-Vessel Clause on BL</label>
                </div>
              </div>
            </fieldset>
            <fieldset style="width: 100%;border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Next Vessel</legend>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipNextCheckVessel == true" [(ngModel)]="modelM['skipCheckNextVessel']" name="skipNextCheckVessel" (change)="changeSkipCheckNextVessel($event)" >
                  <label style="font-size:12px">Skip check</label>
                </div>
              </div>
              <div class="three wide fields">
                <div class="ui field" [class.error]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVesselNext [settings]="settingVesselNext" (change)="changeEventVesselNext($event)" style="width:30%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselCode'] != null && param['error-bOceanVesselCode'] != ''">
                    {{param['error-bOceanVesselCode']}}
                  </div>
                </div>
                <div class="ui field" [class.error]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyageNext [settings]="settingVoyageNext" (change)="changeEventVoyageNext($event)" style="width:30%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bOceanVesselVoyage'] != null && param['error-bOceanVesselVoyage'] != ''">
                    {{param['error-bOceanVesselVoyage']}}
                  </div>
                </div>
                <div class="ui field" [class.error]="param['error-bOceanVesselBound'] != null && param['error-bOceanVesselBound'] != ''">
                  <label>Bound</label>
                  <input type="text" name="boundNext" placeholder="Bound" [(ngModel)]="modelM['blNextVesselBound']" (change)="param['error-bound'] = '';updateVoyageUrl('header');" [disabled] = "retLock" style="width: 30%">
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="param['error-bound'] != null && param['error-bound'] != ''">
                    {{param['error-bound']}}
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheckPrintOceanVessel == true" [(ngModel)]="modelM['printOceanVessel']" name="skipCheckPrintOceanVessel" (change)="changeSkipCheckPrintOceanVessel($event)">
                  <label style="font-size:12px">Print Ocean-Vessel Clause on BL</label>
                </div>
              </div>
              <div class="field"></div>
              <div class="field">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="skipCheckPrintNextVessel == true" [(ngModel)]="modelM['printNextVessel']" name="skipCheckPrintNextVessel" (change)="changeSkipCheckPrintNextVesselMother($event)" tabindex="0">
                  <label style="font-size:12px">Print Next-Vessel (Mother) Clause on BL</label>
                </div>
              </div>

            </fieldset>
          </div>
        </div>
      </div>

      <!-- container -->
      <div class="ui bottom attached tab segment" data-tab="third">
        <div class="sixteen wide field">
          <div class="two field" style="width:100%">
            <grid-plugin id="gridHeader" #gridcontainer [settings]="settingGridContainer" (gridEvent)="gridEventContainer($event)" (infoGrid)="infoGridContainer($event)"></grid-plugin>
          </div>
        </div>
        <div class="sixteen wide field">
          <fieldset style="width: 100%;border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">BL Container Entry</legend>
            <div class="one field">
              <button style="width: 110px" class="ui button" [class.disabled]="lockNewContainer ==true"  (click)="toNewContainer()">New</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockSaveContainer ==true" (click)="toSaveContainer()">Save</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockUpdateContainer ==true" (click)="toUpdateContainer()">Update</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockDeleteContainer ==true" (click)="toDeleteContainer()">Delete</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockCancelContainer ==true" (click)="toCancelContainer()">Cancel</button>
            </div>

            <div class="two wide fields">
              <div class="one field" style="width:20%;">
                <label>Seq</label>
                <input type="number" [(ngModel)]="modelDetail['seqNo']" name="Seq"  placeholder="0" maxlength="4" min="0" [disabled]="lockCon ==true" >
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelDetail['error-seqNo'] != null && modelDetail['error-seqNo'] != ''">
                  {{modelDetail['error-seqNo']}}
                </div>


              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div class="one field "style =" width:65%;">
                <label>&nbsp;</label>
                <fieldset style="width: 100%;border: 1px solid lightgray;">
                  <div class="twelve fields inline">

                    <div class="four field">
                      <div class="ui checkbox">
                        <input type="checkbox" [checked]="SkipCheckContainerNo == true" [(ngModel)]="modelDetail['skipcheck']" name="SkipCheckContainerNo" (change)="changeSkipCheckContainerNo($event)" tabindex="0">
                        <label style="font-size:12px">Skip Check Container No</label>
                      </div>
                    </div>

                    <div class="one field">
                      <div class="ui checkbox">
                        <input type="checkbox" [checked]="EmptyContainer == true" [(ngModel)]="modelDetail['Empty']" name="EmptyContainer" (change)="changeEmptyContainerNo($event)" tabindex="0">
                        <label style="font-size:12px">Empty</label>
                      </div>
                    </div>

                    <div class="one field">
                      <div class="ui checkbox">
                        <input type="checkbox" [checked]="DryContainer == true" [(ngModel)]="modelDetail['dry']" name="DryContainer" (change)="changeDryContainer($event)" tabindex="0">
                        <label style="font-size:12px">Dry</label>
                      </div>
                    </div>

                    <div class="three field right">
                      <div class="ui checkbox">
                        <input type="checkbox" [checked]="ShortShipmentContainer == true" [(ngModel)]="modelDetail['ShortShip']" name="ShortShipmentContainer" (change)="changeShortShipmentContainer($event)" tabindex="0">
                        <label style="font-size:12px">Short Shipment</label>
                      </div>
                    </div>

                  </div>

                </fieldset>
              </div>

            </div>

            <div class="two wide fields">
              <div class="one field" style="width: 50%;" [class.error]="modelDetail['error-ContainerNo'] != null && modelDetail['error-ContainerNo'] != ''" >
                <label>Container No.</label>
                <input type="text" name="ContainerNo" placeholder="..." [(ngModel)]="modelDetail['ContainerNo']" [disabled]="lock ==true" >
				<div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelDetail['error-ContainerNo'] != null && modelDetail['error-ContainerNo'] != ''">
                  {{modelDetail['error-ContainerNo']}}
                </div>
                <label>&nbsp;</label>
                <div class="one field" style="width: 100%;" [class.error]="modelDetail['error-SealNo1'] != null && modelDetail['error-SealNo1'] != ''" >
                <label>Seal No.(1)</label>
                <input type="text" name="SealNo1" placeholder="..." [(ngModel)]="modelDetail['SealNo1']" [disabled]="lock ==true" >
				        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelDetail['error-SealNo1'] != null && modelDetail['error-SealNo1'] != ''">
                  {{modelDetail['error-SealNo1']}}
                </div>
                </div>

                <label>&nbsp;</label>
                <label>Seal No.(2)</label>
                <input type="text" name="SealNo2" placeholder="..." [(ngModel)]="modelDetail['SealNo2']"[disabled]="lock ==true" >
                <label>&nbsp;</label>

                <label>Seal No.(3)(Cont.type)</label>
                <input type="text" name="SealNo3" placeholder="..." [(ngModel)]="modelDetail['SealNo3']" [disabled]="lock ==true" >
              </div>

              <div class="one field" style =" width :50%;">
                <label>Qty.</label>
                <input type="number" min="0" name="Qty" placeholder="0" [(ngModel)]="modelDetail['Qty']" [disabled]="lock ==true" >
                <label>&nbsp;</label>
                <div class="field" style="width:100% ;">
                  <label>Package Type </label>
                  <combo-plugin #cbbContainerPackageType [settings]="settingbContainerPackageType" (change)="changeEventbContainerPackageType($event)" style="width:100%;"></combo-plugin>
                  <label>&nbsp;</label>
                </div>
                <label>Gross Weight (KGS)</label>
                <input type="number" min="0" name="GrossWeight" placeholder="0" [(ngModel)]="modelDetail['GrossWt']" [disabled]="lock ==true" >
                <label>&nbsp;</label>
                <label>Gross Meas (CBM)</label>
                <input type="number" min="0" name="GrossMeas" placeholder="0" [(ngModel)]="modelDetail['GrossMeas']"[disabled]="lock ==true" >
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelDetail['error-GrossMeas'] != null && modelDetail['error-GrossMeas'] != ''">
                  {{modelDetail['error-GrossMeas']}}
                </div>
              </div>

              <label>&nbsp;</label>
              <div class="two wide fields">
                <div class="one field"style =" width :95%;">
                  <div class="two wide fields">
                    <div class="one field">
                      <label>Receipt Type  &nbsp;</label>
                      <combo-plugin #cbbContainerReceiptType [settings]="settingbContainerReceiptType" (change)="changeEventbContainerReceiptType($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="one field">
                      <label>Delivery Type </label>
                      <combo-plugin #cbbContainerDeliveryType [settings]="settingbContainerDeliveryType" (change)="changeEventbContainerDeliveryType($event)" style="width:100%;"></combo-plugin>
                    </div>
                  </div>
                  <div class="one field" >

                    <label>Type </label>
                    <combo-plugin #cbTypeContainer [settings]="settingTypeContainer" (change)="changeTypeContainer($event)" style="width:100%;"></combo-plugin>
                    <label>&nbsp;</label>

                    <div class="required field" [class.error]="modelDetail['error-Size'] != null && modelDetail['error-Size'] != ''">
                    <label>Size</label>
                    <combo-plugin #cbSizeContainer [settings]="settingSizeContainer" (change)="changeSizeContainer($event)" style="width:100%;"></combo-plugin>
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelDetail['error-Size'] != null && modelDetail['error-Size'] != ''">
                        {{modelDetail['error-Size']}}
                      </div>
                    </div>
                    <label>&nbsp;</label>

                    <label>Height </label>
                    <combo-plugin #cbHeightContainer [settings]="settingHeightContainer" (change)="changeHeightContainer($event)" style="width:100%;"></combo-plugin>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>



        <!-- Mark No-->
      <div class="ui bottom attached tab segment" data-tab="fourth">
        <div class="one field">
          <div class="sixteen wide field">
            <div class="two field" style="width:100%">
              <grid-plugin #gridmarkno [settings]="settingGridMarkNo" (gridEvent)="gridEventMarkNo($event)" (infoGrid)="infoGridMarkNo($event)"></grid-plugin>
            </div>
          </div>
        </div>
        <div class="one field">
          <fieldset style="width: 100%;border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;"> BL Mark & No. Entry </legend>
            <div class="one field">

              <button style="width: 110px" class="ui button" [class.disabled]="lockNewMarkNo ==true" (click)="toNewMarkNo()">New</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockSaveMarkNo ==true" (click)="toSaveMarkNo()">Save</button>

              <button style="width: 110px" class="ui button" [class.disabled]="lockUpdateMarkNo ==true" (click)="toUpdateMarkNo()">Update</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockDeleteMarkNo ==true"(click)="toDeleteMarkNo()">Delete</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockCancelMarkNo ==true"(click)="toCancelMarkNo()">Cancel</button>
              <button style="width: 110px" class="ui button" (click)="toNoMark()">No Mark</button>
            </div>

            <div class="one field" style="width:20%;">
              <label>Seq</label>
              <input type="number" min="0" name="bMarkSeq" [(ngModel)]="modelMark['bMarkSeq']" [disabled]="lockM ==true" >
            </div>

            <div class="one field">
              <div class="fields" style="width:100%;">
                <div class="required field" [class.error]="modelMark['error-bMarkAndNo'] != null && modelMark['error-bMarkAndNo'] != ''" style="width:100%">
                  <textarea-plugin #txtMarkNo [settings]="settingTxtMarkNo" (change)="changeTxtMarkNo($event)"></textarea-plugin>
				  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="modelMark['error-bMarkAndNo'] != null && modelMark['error-bMarkAndNo'] != ''">
                    {{modelMark['error-bMarkAndNo']}}
                  </div>

                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- akhir mark no. -->

      <!-- Description -->
      <div class="ui bottom attached tab segment" data-tab="fiveth">

        <div [style.display]="modeFormDesc?'none':'block'">
          <div class="one field">
            <div class="sixteen wide field">
              <div class="two field" style="width:100%">
                <grid-plugin id="gridHeader" #griddescription [settings]="settingGridDescription" (gridEvent)="descgridEvent($event)" (infoGrid)="infoGridDescription($event)"></grid-plugin>
              </div>
            </div>
          </div>
          <div class="one field">
            <button style="width: 110px" class="ui button" (click)="forCreate()">New</button>
          </div>
          <div class="two wide fields">
            <div class="one field">
              <div class="three field">
                <div class="one field">
                  <label>Total No. of Container Package Words</label>
                  <textarea style=" width :70% ; height: 55%;" name="TotalNo" placeholder=" " [(ngModel)]="param['...']" ></textarea>
                  <label> &nbsp; </label>
                </div>
                <div class="one field" >
                  <div class="two wide fields">
                    <div class="one field" >
                      <div class="ui checkbox">
                        <input type="checkbox" [checked]="SkipPrintAgentInfo == true" [(ngModel)]="SkipPrintAgentInfo" name="SkipPrintAgentInfo" (change)="changeSkipPrintAgentInfo($event)" tabindex="0">
                        <label style="font-size:12px">Print Agent Info On BL</label>
                      </div>
                    </div>
                    <div class="one field inline">
                      <label>Agent's Adress ID</label>
                      <div style="width: 30%">
                        <combo-plugin #cbAgentAdd [settings]="settingAgentAdd" (change)="changeAgentAdd($event)"></combo-plugin>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" one field">
                  <label>Agent's Name & Address</label>
                  <textarea style=" width :70% ; height: 55%;" name="Seq" placeholder=" Agent's Name & Address " ></textarea>
                </div>
              </div>
            </div>
            <div class="one field">
              <div class="three wide fields">
                <div class="one field" style="width:30%;">
                  <label>Declared Value (US$)</label>
                  <input type="number" min="0" name="blDeclaredValue" placeholder="..." [(ngModel)]="modelM['blDeclaredValue']" >
                </div>
                <div class="one field">
                  <label> &nbsp; </label>
                  <div class="ui checkbox inline">
                    <input type="checkbox" [checked]="SkipCheckIrePermit == true" [(ngModel)]="modelM['blIsIrePermit']" name="CheckIrePermit" (change)="changeSkipCheckIrePermit($event)" tabindex="0" >
                    <label style="font-size:12px">IRE Permit</label>
                  </div>
                </div>
                <div class="one field">
                  <label> &nbsp; </label>
                  <div class="ui checkbox">
                    <input type="checkbox" [checked]="CheckConsigneePermit == true" [(ngModel)]="modelM['blIsSingleConsigneePermit']" name="CheckConsigneePermit" (change)="changeCheckConsigneePermit($event)" tabindex="0">
                    <label style="font-size:12px">Single Consignee Permit</label>
                  </div>
                </div>
              </div>
              <div class="one field">
                <div class="sixteen wide field">
                  <div class="two field" style="width:100%">
                    <grid-plugin id="gridHeader" #griddescription2 [settings]="descMiniSettingGrid" (gridEvent)="descMiniGridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                  </div>
                </div>
              </div>
              &nbsp;&nbsp;&nbsp;
              <fieldset style="width: 100%;">
                <legend style="font-weight:bold;color:dodgerblue;">BL Clause</legend>
                <div class="one field">
                  <button style="width: 110px" class="ui button" (click)="toNew()">New</button>
                  <button style="width: 110px" class="ui button" (click)="toDelete()">Update</button>
                  <button style="width: 110px" class="ui button" (click)="toCancel()">Delete</button>
                  <button style="width: 110px" class="ui button" (click)="toDelete()">Cancel</button>
                </div>
                &nbsp;
                <div class="one field" style="width:50%;">
                  <label>Seq</label>
                  <input type="text" name="bDescSeq2" placeholder="..." [(ngModel)]="param['bDescSeq2']"  >
                </div>
                &nbsp;
                <div class="one field" style="width:100%;">
                  <label>Clause</label>
                  <input type="text" name="bDescClause" placeholder="..." [(ngModel)]="param['bDescClause']" >
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div [style.display]="modeFormDesc?'block':'none'">

          <div class="sixteen wide field">
            <div class="two field" style="width:100%">
              <grid-plugin id="gridHeader" #griddesc2 [settings]="descSettingGrid" (gridEvent)="descGridEvent2($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
            </div>
          </div>
          <fieldset style="width: 100%;">
            <legend style="font-weight:bold;color:dodgerblue;"> BL Description Entity </legend>
            <div class="one field">
              <button class="ui button" (click)="toSaveDesc()" [class.disabled]="lockSaveDesc==true"> Save </button>
              <button class="ui button" (click)="toUpdateDesc()" [class.disabled]="lockUpdateDesc==true"> Update </button>
              <button class="ui button" (click)="toDeleteDesc()" [class.disabled]="lockDeleteDesc==true"> Delete </button>
              <button class="ui button" (click)="toCancelDesc()" [class.disabled]="lockCancelDesc==true"> Cancel </button>
            </div>

            <div class="one field" style="width: 10%">
              <label>Seq.</label>
              <input type="number" min="0" name="blDescSeq" [(ngModel)]="modelD['blDescSeq']" [disabled]="lockD == true" />
            </div>

            <div class="three wide fields">
              <div class="one field">
                  <div class="required field" style="width: 50%" [class.error]="modelD['error-blDescInnerQty'] != null && modelD['error-blDescInnerQty'] != ''">
                    <label> Inner Qty  </label>
                       <input type="number" min="0" name="blDescInnerQty" [(ngModel)]="modelD['blDescInnerQty']" [disabled]="lockD == true" />
					          <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelD['error-blDescInnerQty'] != null && modelD['error-blDescInnerQty'] != ''">
                      {{modelD['error-blDescInnerQty']}}
                    </div>
                    </div>

                <div class="one field" style="width: 50%">
                  <div class="one field">
                    <label> Inner Package Type </label>
                  </div>
                  <div class="one field">
                    <combo-plugin #cbInnerPackageType [settings]="settingInnerPackageType" (change)="changeEventInnerPackageType($event)" style="width:100%;"></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <div class="required field" style="width: 50%" [class.error]="modelD['error-blDescOuterQty'] != null && modelD['error-blDescOuterQty'] != ''">
                    <label> Outer Qty </label>
                    <input type="number" min="0" name="blDescOuterQty" [(ngModel)]="modelD['blDescOuterQty']" [disabled]="lockD ==true" />
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelD['error-blDescOuterQty'] != null && modelD['error-blDescOuterQty'] != ''">
                        {{modelD['error-blDescOuterQty']}}
                      </div>
                    </div>

                    <div class="one field inline">
                      <input type="checkbox" [checked]="isLock1 == true" [(ngModel)]="isLock1" name="isLock1" (change)="changeContainerLock1($event)" tabindex="0">
                      <label> lock </label>
                    </div>
                  </div>

                <div class="one field">
                  <div class="one field">
                    <label> Outer Package Type </label>
                  </div>
                  <div class="two fields">
                    <div class="one field">
                      <combo-plugin #cbOuterPackageType [settings]="settingOuterPackageType" (change)="changeEventOuterPackageType($event)" style="width:100%;"></combo-plugin>
                    </div>
                    <div class="one field inline">
                      <input type="checkbox" [checked]="isLock1 == true" [(ngModel)]="isLock1" name="isLock1" (change)="changeContainerLock1($event)" tabindex="0">
                      <label> lock </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="one field">
                <div class="one field" style="width: 50%">
                  <label>PSA Package Type</label>
                  <combo-plugin #cbPSA [settings]="settingPSA" (change)="changePSA($event)" style="width:100%;"></combo-plugin>
                </div>

                <div class="required field" style="width: 50%" [class.error]="modelD['error-blDescGrossW'] != null && modelD['error-blDescGrossW'] != ''">
                    <label> Gross Weight </label>
                      <input type="number" name="blDescGrossW" min="0" [(ngModel)]="modelD['blDescGrossW']" [disabled]="lockD == true" />
					           <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelD['error-blDescGrossW'] != null && modelD['error-blDescGrossW'] != ''">
                        {{modelD['error-blDescGrossW']}}
                      </div>
                    </div>

                  <div class="required field"  style="width: 50%" [class.error]="modelD['error-blDescNettW'] != null && modelD['error-blDescNettW'] != ''">
                    <label> Nett Weight </label>
                    <input type="number"  name="blDescNettW" min="0"[(ngModel)]="modelD['blDescNettW']" [disabled]="lockD == true" />
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelD['error-blDescNettW'] != null && modelD['error-blDescNettW'] != ''">
                      {{modelD['error-blDescNettW']}}
                    </div>
                  </div>

                <div class="two fields">
                  <div class="required field" style="width: 50%" [class.error]="modelD['error-blDescGrossMeas'] != null && modelD['error-blDescGrossMeas'] != ''">
                    <label> Gross Measurement </label>
                      <input type="number" name="blDescGrossMeas" min="0" [(ngModel)]="modelD['blDescGrossMeas']" [disabled]="lockD == true" />
					   <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="modelD['error-blDescGrossMeas'] != null && modelD['error-blDescGrossMeas'] != ''">
                        {{modelD['error-blDescGrossMeas']}}
                      </div>
                    </div>

                    <div class="one field inline">
                      <input type="checkbox" [checked]="isLock1 == true" [(ngModel)]="isLock1" name="isLock1" (change)="changeContainerLock1($event)" tabindex="0">
                      <label> lock </label>
                    </div>
                  </div>
                </div>

              <div class="one field">
                <div class="four field">
                  <div class="required field"  [class.error]="modelD['error-blDescNettMeas'] != null && modelD['error-blDescNettMeas'] != ''">
                    <label> Nett Measure </label>
                    <input type="number" name="blDescNettMeas" min="0" [(ngModel)]="modelD['blDescNettMeas']" [disabled]="lockD == true"/>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="modelD['error-blDescNettMeas'] != null && modelD['error-blDescNettMeas'] != ''">
                      {{modelD['error-blDescNettMeas']}}
                    </div>
                  </div>
                </div>
                <br>

                <div class="two wide fields">
                  <div class="one field">
                    <label> Receipt Type </label>
                    <combo-plugin #cbReceiptTypeDesc [settings]="settingReceiptTypeDesc" (change)="changeEventReceiptTypeDesc($event)" style="width:100%;"></combo-plugin>
                  </div>
                  <div class="two field">
                    <label> Delivery Type </label>
                    <combo-plugin #cbDeliveryTypeDesc [settings]="settingDeliveryTypeDesc" (change)="changeEventDeliveryTypeDesc($event)" style="width:100%;"></combo-plugin>
                  </div>
                </div>
                <div class="one field">
                  <label> Commodity Type </label>
                  <combo-plugin #cbCommodityType [settings]="settingCommodityType" (change)="changeCommodityType($event)" style="width:100%;"></combo-plugin>
                </div>
              </div>
            </div>

            <div class="one field" style="width:30%; height: 120%">
              <label>Description of Goods</label>
              <textarea-plugin #txtDescription [settings]="settingTxtDescription" (change)="changeTxtDescription($event)" ></textarea-plugin>
            </div>

          </fieldset>
        </div>

      </div>
      <!-- akhir Description -->

      <div class="ui bottom attached tab segment" data-tab="sixth">

        <div [style.display]="modeFormNett?'none':'block'">
          <div class="five wide fields">
            <div class="three field">
              <div class="checkbox">
                <input type="checkbox" [checked]="FreightAsArranged == false" [(ngModel)]="modelM['blIsFreightAsArranged']" name="FreightAsArranged" (change)="changeFreightAsArranged($event)" tabindex="0">
                <label style="font-size:12px"> Freight As Arranged </label>
              </div>
              <div class="checkbox">
                <input type="checkbox" [checked]="PrintLoadingVessel == false" [(ngModel)]="PrintLoadingVessel" name="PrintLoadingVessel" (change)="changePrintLoadingVessel($event)" tabindex="0">
                <label style="font-size:12px">Print Loading Vessel/Voyage Clause</label>
              </div>
              <div class="checkbox">
                <input type="checkbox" [checked]="PrintShippeeOnBoard == true" checked="checked" [(ngModel)]="modelM['blIsPrintShippedBoard']" name="PrintShippeeOnBoard" (change)="changePrintShippedOnBoard($event)" tabindex="0">
                <label style="font-size:12px"> Print Shipped On Board </label>
              </div>

            </div>
            <div class="one field">
              <div class="one field">
                <label> No. of OBLS </label>
                <input type="number" min="0"   name="OBLS" placeholder="..." [(ngModel)]="modelM['OBLS']" >
              </div>
            </div>
            <div class="one field">
              <div class="one field">
                <label> Freight Covered in BL </label>
                <input type="text" name="freightCovered" placeholder="" [(ngModel)]="modelM['blFreightCoveredInBl']" >
              </div>
            </div>
            <div class="one field">
              <div class="one field">
                <label> Place of Issue </label>
                <combo-plugin #cbNettPlaceOfIssue [settings]="settingNettPlaceOfIssue" (change)="changeEventNettPlaceOfIssue($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one field">
              <div class="one field">
                <label> Date of Issue </label>
                <input type="text" name="DateOfIssue" placeholder="" [(ngModel)]="modelM['blIssueDate']" >
              </div>
            </div>
          </div>
          <div class="four wide fields">
            <div class="one field" style="width: 30%">
              <fieldset style="width: 100%;border: 1px solid lightgray;">
                <legend style="font-weight:bold;color:dodgerblue;">Freight Type</legend>
                <div class="ui radio checkbox">
                  <input type="radio" name="freighttypec" checked="checked" [checked]="freighttypec == false" >
                  <label> Nett </label>
                </div>
                <div class="ui radio checkbox">
                  <input type="radio" name="freighttypec" checked="checked" [checked]="freighttypec == true">
                  <label> Gross </label>
                </div>
              </fieldset>
            </div>
            <div class="one field inline" style="width: 60%">
              <fieldset style="width: 100%;border: 1px solid lightgray;">
                <legend style="font-weight:bold;color:dodgerblue;">Exchange Rate</legend>
                <div class="ui radio checkbox">
                  <input type="radio" name="ExchangeRate" checked="checked" [checked]="isNormalRate == true" (change)="changeEventNormalRate($event)">
                  <label> Normal Rate </label>
                </div>
                <div class="ui radio checkbox">
                  <input type="radio" name="ExchangeRate" checked="checked" [checked]="isSpecialRate == true" (change)="changeEventSpecialRate($event)">
                  <label> Special Rate </label>
                </div>
                <div class="ui radio checkbox">
                  <input type="radio" name="ExchangeRate" checked="checked" [checked]="isBLRate == true" (change)="changeEventBLRate($event)">
                  <label> BL Rate </label>
                </div>
              </fieldset>
            </div>
            <div class="one field">
              <div class="one field">
                <label> Exchange Rate Date </label>
                <input type="text" name="ExchangeRateDate" placeholder="" [(ngModel)]="modelM['blExchangeRateDate']" >
              </div>
            </div>
            <div class="one field">
              <label>&nbsp;</label>
              <div class="inline">
                <div class="ui checkbox">
                  <input type="checkbox" [checked]="lockNettRate == true" [(ngModel)]="lockNettRate" name="lockNettRate" (change)="changeSkipExchangeRate($event)" tabindex="0">
                  <label style="font-size:12px">Lock</label>
                </div>
              </div>

            </div>
          </div>
          <div class="three wide fields">
            <div class="one field">
              <div class="one field" [class.error]="modelM['error-blIsPrepaidAt1Code'] != null && modelM['error-blIsPrepaidAt1Code'] != ''">
                <label> Prepaid At (1) </label>
                <combo-plugin #cbPrepaid1 [settings]="settingPrepaid1" (change)="changeEventPrepaid1($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelM['error-blIsPrepaidAt1Code'] != null && modelM['error-blIsPrepaidAt1Code'] != ''">
                  {{modelM['error-blIsPrepaidAt1Code']}}
                </div>
              </div>
              <div class="one field">
                <label> Prepaid At (2) </label>
                <combo-plugin #cbPrepaid2 [settings]="settingPrepaid2" (change)="changeEventPrepaid2($event)" style="width:100%;"></combo-plugin>
              </div>
              <div class="one field">
                <label> Prepaid At (3) </label>
                <combo-plugin #cbPrepaid3 [settings]="settingPrepaid3" (change)="changeEventPrepaid3($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>

            <div class="one field">
              <div class="one field" [class.error]="modelM['error-blIsPayableAt1Code'] != null && modelM['error-blIsPayableAt1Code'] != ''">
                <label> Payable At (1) </label>
                <combo-plugin #cbPayable1 [settings]="settingPayable1" (change)="changeEventPayable1($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelM['error-blIsPayableAt1Code'] != null && modelM['error-blIsPayableAt1Code'] != ''">
                  {{modelM['error-blIsPayableAt1Code']}}
                </div>
              </div>
              <div class="one field">
                <label> Payable At (2) </label>
                <combo-plugin #cbPayable2 [settings]="settingPayable2" (change)="changeEventPayable2($event)" style="width:100%;"></combo-plugin>
              </div>
              <div class="one field">
                <label> Payable At (3) </label>
                <combo-plugin #cbPayable3 [settings]="settingPayable3" (change)="changeEventPayable3($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>

            <div class="one field">
              <div class="one field" [class.error]="modelM['error-blPayer1Code'] != null && modelM['error-blPayer1Code'] != ''">
                <label> Billing Party (1) </label>
                <combo-plugin #cbBillingParty1 [settings]="settingBillingParty1" (change)="changeEventBillingParty1($event)" style="width:100%;"></combo-plugin>
				        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelM['error-blPayer1Code'] != null && modelM['error-blPayer1Code'] != ''">
                  {{modelM['error-blPayer1Code']}}
                </div>
              </div>
              <div class="one field">
                <label> Billing Party At (2) </label>
                <combo-plugin #cbBillingParty2 [settings]="settingBillingParty2" (change)="changeEventBillingParty2($event)" style="width:100%;"></combo-plugin>
				<div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelM['error-blPayer2Code'] != null && modelM['error-blPayer2Code'] != ''">
                  {{modelM['error-blPayer2Code']}}
                </div>
              </div>
              <div class="one field">
                <label> Billing Party At (3) </label>
                <combo-plugin #cbBillingParty3 [settings]="settingBillingParty3" (change)="changeEventBillingParty3($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>

          </div>

          <div class="one field">
            <label> Remarks </label>
            <textarea></textarea>

          </div>

          <div class="one field">
            <button style="width: 110px" class="ui button" [class.disabled]="lockNewFc == true" (click)="toNettNew()">New</button>
          </div>

          <div class="one field">

            <grid-plugin id="gridHeader" #gridnettcharge [settings]="settingGridNettCharge" (gridEvent)="gridEventNettCharge($event)" (infoGrid)="infoGrid($event)"> </grid-plugin>

          </div>


        </div>

        <div [style.display]="modeFormNett?'block':'none'">
          <fieldset style="width: 100%;border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">BL Charge Entity</legend>

            <div class="one field">
              <button style="width: 110px" class="ui button" [class.disabled]="lockSaveFc ==true" (click)="toInsert()">Insert</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockUpdateFc ==true"(click)="toUpdate()">Update</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockDeleteFc ==true"(click)="toDelete()">Delete</button>
              <button style="width: 110px" class="ui button" [class.disabled]="lockCancelFc ==true"(click)="toNettCancel()">Cancel</button>
            </div>

            <div class="one field" style="width:20%;">
              <label>Seq</label>
              <input type="number" min="0"   name="blFcSeq"  [(ngModel)]="modelF['blFcSeq']"  [disabled]="lockF ==true" >
            </div>

            <div class="three wide fields">
              <div class="field" style="width: 30%;" [class.error]="modelM['error-blFchargeCode'] != null && modelM['error-blFchargeCode'] != ''">
                <label>Charge Code</label>
                <combo-plugin #cbNettCharge [settings]="settingNettCharge" (change)="changeEventNettCharge($event)" style="width:100%;"></combo-plugin>
				 <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelF['error-blFchargeCode'] != null && modelF['error-blFchargeCode'] != ''">
                  {{modelF['error-blFchargeCode']}}
                </div>
                <label>&nbsp;</label>
                <label>Quantity</label>
                <input type="number" min="0" name="QuantityNett" placeholder="..." [(ngModel)]="modelF['blFcQuantity']"  [disabled]="lockF ==true" >
                <label>&nbsp;</label>
                <label>Rate</label>
                <input type="number" min="0" name="RateNett" placeholder="..." [(ngModel)]="modelF['blFcRate']"  (change)="changeEventTotal($event)"  [disabled]="lockF ==true" >
                <label>&nbsp;</label>

                <div class="field" style="width:100%" [class.error]="modelF['error-blFcPer'] != null && modelF['error-blFcCurrency'] != ''">
                <label>Per</label>
                <combo-plugin #cbPer [settings]="settingPer" (change)="changeEventPer($event)" style="width:100%;"></combo-plugin>
				<div class = "ui basic red pointing prompt label transition error-message-hidden"
                       [class.error-message-visible]="modelF['error-blFcPer'] != null && modelF['error-blFcPer'] != ''">
                    {{modelF['error-blFcPer']}}
                  </div>
                  </div>

                <div class="field" style="width:100%" [class.error]="modelF['error-blFcCurrency'] != null && modelF['error-blFcCurrency'] != ''">
                <label>Currency</label>
                <combo-plugin #cbCurrency [settings]="settingCurrency" (change)="changeEventCurrency($event)" style="width:100%;"></combo-plugin>
				<div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="modelF['error-blFcCurrency'] != null && modelF['error-blFcCurrency'] != ''">
                  {{modelF['error-blFcCurrency']}}
                </div>
              </div>
              </div>

              <div class="one field" style =" width :30%;">
                <label>Total Amount</label>
                <input type="number" min="0" name="TotalAmount"  [(ngModel)]="modelF['blFcTotalMount']" [disabled]="lockF ==true"   >
                <label>&nbsp;</label>
                <div class="field" style="width:100% ;">
                  <label>P/C</label>
                  <combo-plugin #cbPC [settings]="settingPC" (change)="changePC($event)" style="width:100%;"></combo-plugin>
                  <label>&nbsp;</label>
                </div>

                <label>PFC Reference </label>
                <input type="text" name="PfcReference" [(ngModel)]="modelF['blPfcReference']"  [disabled]="lockF ==true" >
                <label>&nbsp;</label>

                <label>Payer</label>
                <combo-plugin #cbPayer [settings]="settingPayer" (change)="changePayer($event)" style="width:100%;"></combo-plugin>

                <label>Place Of Payment </label>
                <input type="text"  name="PlaceOfPayment"  [(ngModel)]="modelF['blFcPlaceOfPaymentCode']"  [disabled]="lockF ==true" >
              </div>

              <label>  &nbsp; </label>
              <label>  &nbsp; </label>
              <label>  &nbsp; </label>

              <div class="two wide fields">
                <div class="one field">
                  <div class="ui checkbox">
                    <input type="checkbox" [checked]="skipCheckNettManifestedFreight == true" [(ngModel)]="modelF['blFcIsManifestedFreight']" name="skipCheckNettManifestedFreight" (change)="changeSkipCheckManifestedFreight($event)" tabindex="0">
                    <label style="font-size:12px">Manifested Freight</label>
                  </div>
                  <label>  &nbsp; </label>
                  <div class="one field">
                    <div class="ui checkbox">
                      <input type="checkbox" [checked]="printNettCharge == true" [(ngModel)]="modelF['blFcIsChargePrinted']" name="printNettCharge" (change)="changePrintNett($event)" tabindex="0">
                      <label style="font-size:12px">Print</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sixteen wide field">
              <div class="two field" style="width:100%">
                <grid-plugin id="gridHeader" #gridnettcharge2 [settings]="settingGridNettCharge2" (gridEvent)="gridEventNettCharge2($event)" (infoGrid)="infoGrid($event)"> </grid-plugin>
              </div>
            </div>
          </fieldset>
        </div>

      </div>

      <div class="ui bottom attached tab segment" data-tab="seventh">
        <div class="ui inverted dimmer active" style="z-index:9;opacity:0.7;" [class.hidden]="lock == false">
          <i class="lockicon circular inverted large lock icon"></i>
        </div>
        <div class="five wide fields">
          <div class="three field">
            <div class="checkbox">
              <input type="checkbox" [checked]="FreightAsArranged == true" [(ngModel)]="modelM['blIsFreightAsArranged']" name="FreightAsArranged" (change)="changeFreightAsArranged($event)" tabindex="0">
              <label style="font-size:12px"> Freight As Arranged </label>
            </div>
            <div class="checkbox">
              <input type="checkbox" [checked]="PrintLoadingVessel == true" [(ngModel)]="modelM['PrintLoadingVessel']" name="PrintLoadingVessel" (change)="changePrintLoadingVessel($event)" tabindex="0">
              <label style="font-size:12px">Print Loading Vessel/Voyage Clause</label>
            </div>
            <div class="checkbox">
              <input type="checkbox" [checked]="PrintShippeeOnBoard == true" [(ngModel)]="modelM['blIsPrintShippedBoard']" name="PrintShippeeOnBoard" (change)="changePrintShippedOnBoard($event)" tabindex="0">
              <label style="font-size:12px"> Print Shipped On Board </label>
            </div>

          </div>
          <div class="one field">
            <div class="one field">
              <label> No. of OBLS </label>
              <input type="text" name="OBLS" placeholder="..." [(ngModel)]="param['OBLS']" >
            </div>
          </div>
          <div class="one field">
            <div class="one field">
              <label> Freight Covered in BL </label>
              <input type="text" name="freightCovered" placeholder="" [(ngModel)]="modelM['blFreightCoveredInBl']" >
            </div>
          </div>
          <div class="one field">
            <div class="one field">
              <label> Place of Issue </label>
              <input type="text" name="laseOfIssue" placeholder="" [(ngModel)]="param['PlaseOfIssue']" >
            </div>
          </div>
          <div class="one field">
            <div class="one field">
              <label> Date of Issue </label>
              <input type="text" name="DateOfIssue" placeholder="" [(ngModel)]="param['DateOfIssue']" >
            </div>
          </div>
        </div>
        <div class="four wide fields">
          <div class="one field" style="width: 30%">
            <fieldset style="width: 100%;border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Freight Type</legend>
              <div class="ui radio checkbox">
                <input type="radio" name="freighttypec" checked="checked">
                <label> Nett </label>
              </div>
              <div class="ui radio checkbox">
                <input type="radio" name="freighttypec" checked="checked">
                <label> Gross </label>
              </div>
            </fieldset>
          </div>
          <div class="one field inline" style="width: 60%">
            <fieldset style="width: 100%;border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Freight Type</legend>
              <div class="ui radio checkbox">
                <input type="radio" name="ExchangeRate" checked="checked">
                <label> Normal Rate </label>
              </div>
              <div class="ui radio checkbox">
                <input type="radio" name="ExchangeRate" checked="checked">
                <label> Special Rate </label>
              </div>
              <div class="ui radio checkbox">
                <input type="radio" name="ExchangeRate" checked="checked">
                <label> BL Rate </label>
              </div>
            </fieldset>
          </div>
          <div class="one field">
            <div class="one field">
              <label> Exchange Rate Date </label>
              <input type="text" name="DateOfIssue" placeholder="" [(ngModel)]="param['DateOfIssue']" >
            </div>
          </div>
          <div class="one field">
            <label>&nbsp;</label>
            <div class="inline">
              <div class="ui checkbox">
                <input type="checkbox" [checked]="lockNettRate == true" [(ngModel)]="lockNettRate" name="lockNettRate" (change)="changeSkipExchangeRate($event)" tabindex="0">
                <label style="font-size:12px">Lock </label>
              </div>
            </div>

          </div>
        </div>
        <div class="three wide fields">
          <div class="one field">
            <div class="one field">
              <label> Prepaid At (1) </label>
              <combo-plugin #cbGrossPrepaid1 [settings]="settingGrossPrepaid1" (change)="changeEventGrossPrepaid1($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Prepaid At (2) </label>
              <combo-plugin #cbGrossPrepaid2 [settings]="settingGrossPrepaid2" (change)="changeEventGrossPrepaid2($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Prepaid At (3) </label>
              <combo-plugin #cbGrossPrepaid3 [settings]="settingGrossPrepaid3" (change)="changeEventGrossPrepaid3($event)" style="width:100%;"></combo-plugin>
            </div>
          </div>

          <div class="one field">
            <div class="one field">
              <label> Payable At (1) </label>
              <combo-plugin #cbGrossPayable1 [settings]="settingGrossPayable1" (change)="changeEventGrossPayable1($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Payable At (2) </label>
              <combo-plugin #cbGrossPayable2 [settings]="settingGrossPayable2" (change)="changeEventGrossPayable2($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Payable At (3) </label>
              <combo-plugin #cbGrossPayable3 [settings]="settingGrossPayable3" (change)="changeEventGrossPayable3($event)" style="width:100%;"></combo-plugin>
            </div>
          </div>

          <div class="one field">
            <div class="one field">
              <label> Billing Party (1) </label>
              <combo-plugin #cbGrossBillingParty1 [settings]="settingGrossBillingParty1" (change)="changeEventGrossBillingParty1($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Billing Party At (2) </label>
              <combo-plugin #cbGrossBillingParty2 [settings]="settingGrossBillingParty2" (change)="changeEventGrossBillingParty2($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="one field">
              <label> Billing Party At (3) </label>
              <combo-plugin #cbGrossBillingParty3 [settings]="settingGrossBillingParty3" (change)="changeEventGrossBillingParty3($event)" style="width:100%;"></combo-plugin>
            </div>
          </div>

        </div>

        <div class="one field">
          <label> Remarks </label>
          <textarea></textarea>
        </div>

        <div class="one field">
          <button style="width: 110px" class="ui button" (click)="toNettNew()">New</button>
        </div>

        <div class="one field">

          <grid-plugin id="gridHeader" #gridgrosscharge [settings]="settingGridGrossCharge" (gridEvent)="gridEventGrossCharge($event)" (infoGrid)="infoGrid($event)"> </grid-plugin>

        </div>
      </div>

    </form>
  </div>
</div>

<footer-plugin [info]="info"></footer-plugin>
