<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <form class="ui form">
    <fieldset style="border: 1px solid lightgray">
      <legend style="font-weight:bold;color:dodgerblue;">B/L Freight Match</legend>
      <div class="inline fields">
      <div class="one field">
              <div class="ui radio checkbox" >
                <input type="radio" name="freight"  [checked]="isVessel == true" (change)="changeEventisVessel($event)">

                <label>Vessel Wise</label>
              </div>
        </div>
      <div class="one field">
        <div class="ui radio checkbox" >
          <input type="radio" name="freight"  [checked]="isNo == true" (change)="changeEventIsNo($event)">
          <label>B/L No</label>
        </div>
      </div>
      </div>

      <div class="three fields">
          <div class="field" style="width:40%" [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" [disableCombo]="transferFromLock" style="width:100%;" ></combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                 [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
              {{model['error-vesselName']}}
            </div>
          </div>
          <div class="field" style="width:40%" [class.error]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''" >
            <label>Voyage</label>
              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" [disableCombo]="transferFromLock" style="width:100%; color: #4d7198"></combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                 [class.error-message-visible]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''">
              {{model['error-bOceanVesselVoyage']}}
            </div>
          </div>
        <div class="one field" style="width:40%">
          <label>Bound *</label>
          <input type="text" name="bound" placeholder="Bound"    [(ngModel)]="model['bound']" (change)="model['error-bound'] = '';updateVoyageUrl('header');" [disabled] = "retLock== true">
          <div class = "ui basic red pointing prompt label transition error-message-hidden"
               [class.error-message-visible]="model['error-bound'] != null && model['error-bOceanVesselBound'] != ''">
            {{model['error-bOceanVesselBound']}}
          </div>
        </div>

        <div class="one field inline" style="width: 60%">
          <fieldset style="width: 100%;border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Container OwnerShip</legend>
            <div class="ui radio checkbox">
              <input type="radio" name="ExchangeRate" [checked]="isAll == true" checked="checked">
              <label> ALL </label>
            </div>
            <div class="ui radio checkbox">
              <input type="radio" name="ExchangeRate" [checked]="isCOC == true" checked="checked">
              <label> COC </label>
            </div>
            <div class="ui radio checkbox">
              <input type="radio" name="ExchangeRate" [checked]="isSOC == true" checked="checked">
              <label> SOC </label>
            </div>
          </fieldset>
        </div>
        </div>

        <div class="field">
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)" ></grid-plugin>
        </div>


    </fieldset>

    <div [style.display]="modeFormNett?'block':'none'">
      <fieldset style="width: 100%;border: 1px solid lightgray;">
        <legend style="font-weight:bold;color:dodgerblue;">BL Charge Entity</legend>

      </fieldset>
    </div>
 </form>
</form>



<div  id="printSailingSchedule" class="ui small modal" style="margin-right:400px;padding:1%;"> <!-- Start Modal Print Sailing Shchedule -->
  <form class="ui form">
    <div class="field">
      <!--fieldset style="border: 1px solid lightgray; width: 100%"> -->
      <legend style="font-weight:bold;color:dodgerblue;">Freight Match BL NO</legend>
    </div>
    <div class="field">
      <div class="field">
        <button class="ui positive button" (click)="printSailingSchedule()">Yes</button>
        <button class="ui negative button" (click)="hidePrintSailingSchedule()">No</button>
      </div>
    </div>
    <div class="field">
      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue;">BL NO</legend>
          <div class="grouped field">



            <div class="field">
              <grid-plugin id="gridvessel" #gridvessel [settings]="settingGridVessel" (gridEvent)="gridEventVessel($event)" (infoGrid)="infoGridVessel($event)" ></grid-plugin>

            </div>
          </div>
        </fieldset>
        <br />

      </div>


    </div>

    <!--/fieldset-->
  </form>
</div> <!-- End Modal Stop Booking -->

<footer-plugin [info]="info"></footer-plugin>
