

export class Charge {
  no : number = 0;
  chargeId   : string = '';
  chargeCode  : string = '';
  chargeName  : string = '';
  isManifested : boolean = false;
  isAdhoc    : boolean = false;
  isShownInReport : boolean = false;
  isSurcharge     : boolean = false;
  isMainCharge    : boolean = false;
  isValid         : boolean = true;
  remarks       : string = '';
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid: string = '';


  constructor() {

  }
}
