import { Component, OnInit, EventEmitter, AfterViewInit , ViewChild } from '@angular/core';
import { MService, MServiceDetail } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import FormValidation from '../../utils/formValidation';
declare  var $:any;

@Component({
  selector: 'app-master-service-page',
  templateUrl: './master-service-page.component.html',
  styleUrls: ['./master-service-page.component.css']
})
export class MasterServicePageComponent extends Validator  implements  OnInit, AfterViewInit {

  @ViewChild('hgrid') headgrid:GridPluginComponent;
  @ViewChild('dtlgrid') dtgrid:GridPluginComponent;
  @ViewChild('dtlcopy') dtcopy:GridPluginComponent;
  @ViewChild('gridH') gridHistory:GridPluginComponent;


  @ViewChild('serName') sName:ComboPluginComponent;
  @ViewChild('serPoc') sPoc:ComboPluginComponent;
  @ViewChild('serTerminal') sTerminal:ComboPluginComponent;
  @ViewChild('serPurpose') sPurpose:ComboPluginComponent;
  @ViewChild('hisSer') sHistory:ComboPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  /* Parameter modeForm (Default : false) */
  form='';
  modeForm = false;
  modeToolbar = false;

  modeDetail = false;
  modeButton = false;
  modeButtonCopy = false;
  modeAddCopy = false;
  modeServiceCopy = false;

  modeCopy = false;
  modeHGrid = true;
  modeRetrieve = false;

  serStartDate = 'calendar'; // for calendar
  serEndDate = 'calendar'; // for calendar



  /* Parameter listStore for Grid */
  listStore = new ListStore;
  listStoreDetails = new ListStore;
  mServiceDetailStore = new ListStore;
  listStoreCopy = new ListStore;
  listCopy = new ListStore;
  listSave = new ListStore;


  setGridEvent:string = '';
  setGridEvent2:string = '';


  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGrid2;
  settingGridCopy;
  settingGridHistory;


  settingService;
  settingPortOfCall;
  settingTerminal;
  settingPurpose;
  settingLogStartDate;
  settingLogEndDate;
  settingHistoryService;


  valPurpose: string='';
  valServiceId: String = '';
  valSerId: String='';
  valPortId: String = '';
  valTerminalId: String = '';
  valHistoryServiceId: String = '';
  valHistoryStartDate: String = '';
  valHistoryEndDate: String = '';
  valHasTerminal : String = '';

  detailSeqMode : String ='';

   /* Parameter model */
  model = new MService;
  modelDetail = new MServiceDetail ;
  modelHistory = new MService;
  modelCopy = new MService;

  getTransit =  {day:0,time:0, all:0};
  getPort =  {day:0,time:0, all:0};

  setTransit =  {day:'',time:''};
  setPort =  {day:'',time:''};
  isType = 'isSsl';

  selectList = [];

  loading = false;
  lock = false;
  lockTerminal = true;
  lockRetrieve=true;
  flagUpdate=false;

  before:number = 0;
  after:number = 0;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  sBound: '';
  formValidation = new FormValidation();

  /* validatorRules */
  validatorRules = {
      /*serviceName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter service name',
          }
        ]
      },
      serviceCode: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please enter service code',
          }
        ]
      },
      psaServiceCode: {
        rules: [
          {
            type  : 'empty',
            prompt: 'Please enter PSA code',
          }
        ]
      },
      serviceSequence: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },*/

  }

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cook:CookieService) {
    super();

    this.settingLogStartDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }


    this.settingLogEndDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Create',  event: 'create', icon : 'plus' },
        { name : 'Update',  event: 'update', icon : 'write' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Print' ,  event: 'print' , icon : 'file outline' },
        { name : 'History' ,  event: 'history' , icon : 'history' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
       ],
      buttonsDetail         : [
        { name : 'Save',  event: 'save', icon : 'save' },
        { name : 'Copy',  event: 'copy', icon : 'copy', enabled : false},
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
      ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        { display: 'Service Name', value: 'serviceName'},
        { display: 'Service Code', value: 'serviceCode'},
        { display: 'Psa Service Code', value: 'psaServiceCode'},
        { display: 'Seq.', value: 'serviceSequence'},
        { display: 'Remarks', value: 'remarks'},
        { display: 'SSL', value: 'isSsl'},
        { display: 'Slot', value: 'isSlot'},
        { display: '3rd Party', value: 'is3RdParty'},
        { display: 'Bound', value: 'sBound'},
        { display: 'User Created', value: 'userCreated'},
        { display: 'Date Created', value: 'dateCreated'},
        { display: 'User Modified', value: 'userModified'},
        { display: 'Date Modified', value: 'dateModified'},
        { display: 'User Invalid', value: 'userInvalid'},
        { display: 'Date Invalid', value: 'dateInvalid'}

      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }


    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Service Name', field: 'serviceName', width: 100},
                    {header: 'Service Code', field: 'serviceCode', width: 100},
                    {header: 'PSA Service Code', field: 'psaServiceCode', width: 100},
                    {header: 'Seq', field: 'serviceSequence', width: 50},
                    {header: 'Remarks', field: 'remarks', width: 100},
                    {header: 'SSL', field: 'isSsl', width: 60},
                    {header: 'Slot', field: 'isSlot', width: 60},
                    {header: '3rd Party', field: 'is3RdParty', width: 100},
                    {header: 'Bound', field: 'sBound', width: 100},
                    {header: 'User Created', field: 'userCreated', width: 100},
                    {header: 'Date Created', field: 'dateCreated', width: 100},
                    {header: 'User Modified', field: 'userModified', width: 100},
                    {header: 'Date Modified', field: 'dateModified', width: 100},
                    {header: 'User Invalid', field: 'userInvalid', width: 100},
                    {header: 'Date Invalid', field: 'dateInvalid', width: 100},
                    {header: 'Valid', field: 'isValid', width: 100}

        ],
        buttons: [ ],
        //store: this.listStore,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'serviceName',
        sortingDirection: 'ASC'
    }

     this.settingGrid2 = {
        url: '',//BASE_API + '/MasterServices/findById/',
        page: 20,
        id: 'detailGrid',
        columns: [
                    {header: 'No', field: 'seqNo', width: 50},
                    {header: 'Port Name', field: 'portName', width: 200},
                    {header: 'Port Code', field: 'portCode', width:100},
                    {header: 'Terminal Name', field: 'terminalName', width:200},
                    {header: 'Terminal Code', field: 'terminalCode', width: 120},
                    {header: 'FmPort (H)', field: 'fmPortHourTemp', width: 100},
                    {header: 'FmPort (D+H)', field: 'fmPortDaysHours', width:100},
                    {header: 'AtPort (H)', field: 'atPortHourTemp', width: 100},
                    {header: 'AtPort (D+H)', field: 'atPortDaysHours', width: 100},
                    {header: 'Purpose', field: 'callReason', width: 290}

        ],
        buttons: [ ],
        store: this.listStoreDetails,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: false,
        auto:false
    }

    this.settingGridCopy = {
        url: '',//BASE_API + '/MasterServices/findById/',
        page: 5,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Service Name', field: 'copyServiceName', width: 100},
                    {header: 'Service Code', field: 'copyServiceCode', width: 50},
                    {header: 'PSA Service Code', field: 'copyPsaServiceCode', width: 50},
                    {header: 'Seq', field: 'copySeq', width: 50},

        ],
        buttons: [ ],
        store: this.listStoreCopy,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: false
    }

    this.settingGridHistory = {
        url:this.configService.config.BASE_API.toString() + '/MasterServices/findGeneralHistoryById',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Service Code', field: 'serviceCode', width: 100},
                    {header: 'PSA Service Code', field: 'psaServiceCode', width: 150},
                    {header: 'Seq', field: 'serviceSequence', width: 50},
                    {header: 'Service Name', field: 'serviceName', width: 200},
                    {header: 'SSL', field: 'isSsl', width: 60},
                    {header: 'Slot', field: 'isSlot', width: 60},
                    {header: '3rd Party', field: 'is3RdParty', width: 100},
                    {header: 'Bound', field: 'sBound', width: 100},
                    {header: 'User Created', field: 'userCreated', width: 100},
                    {header: 'Date Created', field: 'dateCreated', width: 100},
                    {header: 'User Modified', field: 'userModified', width: 100},
                    {header: 'Date Modified', field: 'dateModified', width: 100},
                    {header: 'Valid', field: 'isValid', width: 100},
                    {header: 'Action Log', field: 'action', width: 100}

        ],
        buttons: [ ],
        enableSorting: true,

        enablePagination: true,
        sortingColumns:'serviceName',
        sortingDirection: 'ASC'
    }



    this.settingService = {
        id          : 'serName',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral/*/*/{query}/1/100/*/*',
        maxChars    : 3,
        template    : 'grid',
        placeholder : 'Search ...',
        description : '',
        title       : 'serviceName',
        flag        : '',// for template : 2
        columns      :[
            {header: 'Service Name', field: 'serviceName', width: 200},
            {header: 'Service Code', field: 'serviceCode', width: 100},
            {header: 'Psa Service Code', field: 'psaServiceCode', width: 100},
            {header: 'Seq', field: 'serviceSequence', width: 100},
            {header: 'Remarks', field: 'remarks', width: 100},
            {header: 'Is Valid?', field: 'isValid', width: 125},
        ]
    }

    this.settingPortOfCall = {
        id          : 'poc',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/100/*/*',
        maxChars    : 3,
        template    : 'grid',
        placeholder : 'Search ...',
        title       : 'locationName',
        description  : '',
        isMark      : true,
        flag        : '',// for template : 2
        columns      :[
            {header: 'Location Id', field: 'locationId', width: 100},
            {header: 'Location Name', field: 'locationName', width: 300},
            {header: 'Location Code', field: 'locationCode', width: 100},
            {header: 'Country Name', field: 'countryName', width: 150},
            {header: 'Remarks', field: 'remarks', width: 100},
            {header: 'Is Valid?', field: 'isValid', width: 100},
        ]
    }

    this.settingTerminal = {
        id          : 'terminal',
        type        : 'search enter', // search | select | select input
        url         : '',//BASE_API + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
        maxChars    : 0,
        template    : 'grid',
        placeholder : 'Search ...',
        title       : 'locationName',
        description  : '',
        isMark      : true,
        flag        : '',// for template : 2
        columns      :[
            {header: 'Location Id', field: 'locationId', width: 100},
            {header: 'Location Name', field: 'locationName', width: 225},
            {header: 'Location Code', field: 'locationCode', width: 150},
            {header: 'Country Name', field: 'countryName', width: 150},
            {header: 'Remarks', field: 'remarks', width: 100},
            {header: 'Is Valid?', field: 'isValid', width: 100},
        ]
    }


    this.settingPurpose = {
       id          : 'cbPurpose',
       type        : 'select', // search | select | select input
       url         : 'assets/json/callreason.json',
       placeholder : 'Select Purpose',
       code        : 'callReasonCode',
       title       : 'callReason'
    };


    this.settingHistoryService = {
        id          : 'historyService',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterServices/findGeneralHistory/{query}',
        maxChars    : 3,
        template    : 'grid',
        placeholder : 'Search ...',
        title       : 'serviceName',
        description  : '',
        isMark      : true,
        flag        : '',// for template : 2
        columns      :[
            {header: 'Service Code', field: 'serviceCode', width: 50},
            {header: 'Service Name', field: 'serviceName', width: 50},
            {header: 'PSA Service Code', field: 'psaServiceCode', width: 50},
            {header: 'SSL', field: 'isSsl', width: 50},
            {header: 'SLOT', field: 'isSlot', width: 50},
            {header: '3rd Party', field: 'is3RdParty', width: 50},
            {header: 'Remarks', field: 'remarks', width: 50},
        ]
    }


  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    this.setValidatorHeader();
    $('.test.checkbox').checkbox();

    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.headgrid.search = '*/*/*';
    //this.headgrid.onFirst();

    this.firstInit();

    /*$('#detailgrid').find('#container').css({
      'width' : '100%',
      'height' : '210px',
      'overflow-x' : 'scroll',
      'overflow-y' : 'scroll',
    });*/

  }

  firstInit(){
    this.currentMenuID = this.cook.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cook.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.headgrid.search = '*/*/*';
    this.headgrid.onFirst();
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  /*message(txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }*/

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGrid2(event:any) {
    this.info = event;
  }

  infoGridCopy(event:any){
    this.info = event;
  }

  infoGridHistory(event:any){
    this.info = event;
  }


  setToolbarButtons(editButton, editTo, eventEditTo, icon, enableStatEditTo){
    this.settingToolbar.buttonsDetail.forEach(ds=>{
      if(ds.name == editButton){
        ds.name = editTo;
        ds.event = eventEditTo;
		    ds.icon = icon;
        ds.enabled = enableStatEditTo;
      }
    });
  }

  gridEvent(event:any) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.headgrid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case 'click' :
      if(this.headgrid.getSelectedValues().length > 0){
        if(this.viewOnlyStatus){
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
      }else{
        if(this.viewOnlyStatus){
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "update,delete";
        }
      }
      break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              this.handleUpdate();
            break;

            case 'dblClick':
            if(this.viewOnlyStatus){
              this.disableToolbarButtons = "update,delete";
            } else {
              this.disableToolbarButtons = "";
            }
              this.invisibleToolbarButtons = "create,history,print";
              this.modeForm = true;
              this.dtgrid.clearSelectedValues();
              this.setData();
              this.dtgrid.loadData();
              this.lock = true;
              this.lockTerminal = true;
            break;

            default:
            break;
          }

        }

        break;
    }
  }

  gridEvent2(event:any){
    this.setGridEvent2 = "";
    switch (event.split("-")[0]){
      case 'dblClick' :
      this.modeDetail=true;
      this.modeButton = true;
      this.lockTerminal=true;
      this.setValidatorDetails();
      this.setDataDetail(this.dtgrid.getSelectedValues()[0]);
      console.log("Berapa sih nilainya : "+this.dtgrid.getSelectedValues[0]);

        break;
      case 'select' :
      this.dtgrid.clearSelectedValues();
      break;
      default :
        break;
    }

    this.listStoreDetails.store.forEach(sd=>{
        if(sd['select'] == 'checked'){
        // console.log('seq = '+sd.seq);
      }
    });
  }

  gridEventCopy(event:any){
    switch (event.split("-")[0]){
      case 'dblClick' :
      // console.log("masuk Grid Copy");
       this.modeAddCopy=true;
       this.modeHGrid = false;
       this.modeButtonCopy=true;
       this.setCopy(this.dtcopy.getSelectedValues()[0]);
       break;
      default :
        break;
    }
  }

  gridEventHistory(event:any){
    switch (event.split("-")[0]){
      case 'dblClick' :
       break;
      default :
        break;
    }
  }



  toolbarEvent(event:any) {
    // console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      case 'copy':
        this.handleCopy();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.headgrid.search = str.replace("search:","");
          this.headgrid.clearSelectedValues();
          this.headgrid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  retrieveHistory(event:any){
    // console.log("masuk retrieve history");
    this.gridHistory.loadData();
  }

  retrieve(){

      let data = this.valServiceId;
      this.valSerId = this.valServiceId;
      //this.lockRetrieve=true;
      //this.modeToolbar=false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,history,print, update, delete, copy";
      this.modeRetrieve = true;

      var hiturl = this.configService.config.BASE_API.toString() + '/MasterServices/findById/';
      var resultStore = new ListStore;
      var valueStore = new ListStore;
      this.model = new MService;
      this.modelDetail= new MServiceDetail;
      var i : number =1;
      if(data != null){
        hiturl = hiturl + data;
        this.dtgrid.search=hiturl;
        this.genericService.GET(hiturl).subscribe((resp) =>{
          if(resp.ok){
            if(resp.json()){
                this.model.serviceId = resp.json().serviceId.trim();
                if(resp.json().isValid.trim() == 'Y'){
                  this.model.isValid = true;
                }else{
                  this.model.isValid = false;
                }

                if(String(resp.json().isSsl).trim() == 'Y'){
                  this.isType='isSsl';
                }else if(String(resp.json().isSlot).trim() == 'Y'){
                  this.isType='isSlot';
                }else if(String(resp.json().is3RdParty).trim() == 'Y'){
                  this.isType='is3Rd';
                }

                this.model.serviceCode = String(resp.json().serviceCode).trim().toUpperCase();
                this.model.serviceName = String(resp.json().serviceName).trim().toUpperCase()
                this.model.psaServiceCode = String(resp.json().psaServiceCode).trim().toUpperCase();
                this.model.serviceSequence = resp.json().serviceSequence;
                this.model.dateCreated = String(resp.json().dateCreated).trim().toUpperCase();
                this.model.userCreated = String(resp.json().userCreated).trim().toUpperCase();
                this.model.dateModified = String(resp.json().dateModified).trim().toUpperCase();
                if(resp.json().userModified != '' && resp.json().userModified != null){
                  this.model.userModified = String(resp.json().userModified).trim().toUpperCase();
                }
                if(resp.json().dateInvalid != '' && resp.json().dateInvalid != null){
                  this.model.dateInvalid = String(resp.json().dateInvalid).trim().toUpperCase();
                }
                if(resp.json().userInvalid != '' && resp.json().userInvalid != null){
                  this.model.userInvalid = String(resp.json().userInvalid).trim().toUpperCase();
                }
                if(resp.json().remarks != '' && resp.json().remarks != null){
                  this.model.remarks = String(resp.json().remarks).trim().toUpperCase();
                }
            } else {

            }

            if(resp.json()['masterServicesDetails']){
              // console.log("LOG 2 : "+resp.json()['masterServicesDetails']);
              resultStore.store = resp.json()['masterServicesDetails'];
              resultStore.store.forEach(each=>{
                // console.log("LOG 3: "+each.fmPortHours+"---"+each.terminalId);
                //each['seqNo']=parseInt($('input[name="gridCount"]').val())+i;
                //ini di set untuk save copy
                this.valPortId=each.portId;
                if(each.terminalId == 'LO0000007358'){
                  each['terminalCode'] = '';
                  each['terminalName'] =String('');
                  this.valTerminalId='';
                }else{
                  this.valTerminalId=each.terminalId;
                }
                each['portName'] = String(each.portName);
                each['terminalName'] = String(each.terminalName);
                each['fmPortHourTemp'] = each.fmPortHours;
                each['atPortHourTemp'] = each.atPortHours;
                each['fmPortHours'] = this.goneH(each.fmPortHours);
                each['atPortHours'] = this.goneH(each.atPortHours);

                this.dtgrid.listStore.addData(each);
                this.dtgrid.loadData();
                i++;
              });
              $('input[name="gridCount"]').val(i-1);
            } else {

            }
          }
      },error => {
        this.loading = false;
        // console.log(error.message);
        this.info = error.message;
        this.message('warning','Warning','Data Not Found!','okonly',{ok: 'this.loading=false;'});
        this.cleared();
      });
      }
      this.removeAll();
      this.dtgrid.loadData();
  }



  setData(){
    // console.log("setdata1: "+this.headgrid.getSelectedValues()[0]);
      if(this.headgrid.getSelectedValues()[0] != null){
          let data = this.headgrid.getSelectedValues()[0];

          this.model = new MService;

          if(String(data.isValid).trim() == 'Y'){
            this.model.isValid = true;
          }else{
            this.model.isValid = false;
          }


          if(String(data.isSsl).trim() == 'Y'){
            this.isType = 'isSsl';
          }else if(String(data.isSlot).trim() == 'Y'){
            this.isType = 'isSlot';
          }else if(String(data.is3RdParty).trim() == 'Y'){
            this.isType = 'is3Rd';
          }
          this.model.sBound = data.sBound;
          this.model.serviceId=data.serviceId;
          this.valSerId = this.valServiceId;
          this.sName.setUrl(this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral/*/serviceId/{query}/1/100/*/*');
          this.sName.setValue( String(data.serviceId).trim().toUpperCase());
          this.model.serviceCode = String(data.serviceCode).trim().toUpperCase();
          this.model.serviceName = String(data.serviceName).trim().toUpperCase();
          this.model.psaServiceCode = String(data.psaServiceCode).trim().toUpperCase();
          this.model.serviceSequence = data.serviceSequence;
          this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
          this.model.userCreated = String(data.userCreated).trim().toUpperCase();
          this.model.dateModified = String(data.dateModified).trim().toUpperCase();
          if(data.userModified != '' && data.userModified != null){
            this.model.userModified = String(data.userModified).trim().toUpperCase();
          }
          if(data.dateInvalid != '' && data.dateInvalid != null){
            this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
          }
          if(data.userInvalid != '' && data.userInvalid != null){
            this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
          }
          if(data.remarks != '' && data.remarks != null){
            this.model.remarks = String(data.remarks).trim().toUpperCase();
          }

          var hiturl = this.configService.config.BASE_API.toString() + '/MasterServices/findById/';
          var resultStore = new ListStore;
          this.modelDetail= new MServiceDetail;
          var i : number =1;
          if(data.serviceId != null){
            hiturl = hiturl + data.serviceId;
            this.dtgrid.search=hiturl;
            this.genericService.GET(hiturl).subscribe((resp) =>{
              if(resp.ok){
                if(resp.json()['masterServicesDetails']){
                  // console.log("LOG 2 : "+resp.json()['masterServicesDetails']);
                  resultStore.store = resp.json()['masterServicesDetails'];
                  resultStore.store.forEach(each=>{
                    // console.log("LOG 3: "+each.portName+"---"+each.portName);
                    // console.log("LOG 3.1: "+each.portCode+"---"+each.atPortHours);
                    //each['seqNo']=parseInt($('input[name="gridCount"]').val())+i;
                    each['portId'] = each.portId;
                    each['atPortHourTemp']=each.atPortHours;
                    each['fmPortHourTemp']=each.fmPortHours;
                    each['fmPortHours'] = this.goneH(each.fmPortHours);
                    each['atPortHours'] = this.goneH(each.atPortHours);

                    //ini di set untuk save copy
                    this.valPortId=each.portId;
                    if(each.terminalId == 'LO0000007358'){
                      each['terminalCode'] = '';
                      each['terminalName'] ='';
                      this.valTerminalId='';
                    }else{
                      this.valTerminalId=each.terminalId;
                    }
                    each['portName'] = String(each.portName);
                    each['terminalName'] = String(each.terminalName);
                    this.dtgrid.listStore.addData(each);
                    this.dtgrid.loadData();
                    i++;

                  });

                } else {

                }
              }
          });
          }
          // console.log("DATA SERVICE : "+data.serviceCode);
          // this.settingService.url = BASE_API + '/MasterServices/findGeneral/*/*/{query}/1/5/*/*';
      }


  }

  setCopy(param){
    this.modelCopy = new MService;
    // console.log("cek data copy service name : "+param.copyServiceName);

    this.modelCopy.seqCop = param.seqCop;
    this.modelCopy.copyServiceName = param.copyServiceName;
    this.modelCopy.copyServiceCode = param.copyServiceCode;
    this.modelCopy.copyPsaServiceCode = param.copyPsaServiceCode;
    this.modelCopy.copySeq = param.copySeq;

    this.dtcopy.clearSelectedValues();

  }

  setDataDetail(param){

    // console.log("Double Klick Grid Detail");
    //this.modelDetail = new MServiceDetail;
    this.setTransitDayAll();
    this.setPortDayAll()

    // console.log("cek data seqno : "+param.seqNo);
    // console.log("cek data portName : "+ param.portName);
    // console.log("cek data hasTerminal : "+ param.hasTerminal);

    this.modelDetail.seqNo = param.seqNo;

    this.sPoc.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/locationCode/{query}/1/100/*/*');
    this.sPoc.setValue(param.portCode);
    // this.sPoc.setValue(param.portName);
    this.modelDetail.portCode = param.portCode;
    this.modelDetail.portName = param.portName;
    this.modelDetail.portId = param.portId;

    if(param.terminalId == "LO0000007358"){
      this.lockTerminal = true;
    }else if(this.flagUpdate==true && param.termilaId != ''){
      this.lockTerminal = false;
    }

    this.sTerminal.setValue(param.terminalName);
    this.modelDetail.terminalCode = param.terminalCode;
    this.modelDetail.terminalName = param.terminalName;
    this.modelDetail.terminalId = param.terminalId;

    this.sPurpose.setValue(param.callReason);
    $("#cbPurpose").dropdown("set selected", param.callReason);
    this.modelDetail.callReason = param.callReason;
    this.modelDetail.callReasonCode = param.callReasonCode;


    this.modelDetail.fmPortHourTemp = String(this.setTransit.day);
    this.modelDetail.fmPortDaysHours = String(this.setTransit.time);

    this.modelDetail.atPortHourTemp = String(this.setPort.day);
    this.modelDetail.atPortDaysHours = String(this.setPort.time);

    this.dtgrid.clearSelectedValues();
    this.sPoc.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/100/*/*');
  }

  getDetailData(){
    // console.log("terminalIDnya: "+this.modelDetail.terminalId);
    var md = new MServiceDetail;
    var dt = new Date();

    this.getTransitDayAll();
    this.getPortDayAll();

    md.seqNo = this.modelDetail.seqNo;
    md.portCode = this.modelDetail.portCode;
    md.portName = this.modelDetail.portName;
    md.portId = this.modelDetail.portId;
    md.terminalCode = this.modelDetail.terminalCode;
    md.terminalName = this.modelDetail.terminalName;
    md.terminalId= this.modelDetail.terminalId;

    if(this.getTransit.all != 0){
      md.fmPortHourTemp= "H"+this.getTransit.all;
      md.fmPortHours= this.getTransit.all;
    }else{
      md.fmPortHourTemp = "H0";
    }

    if(this.getTransit.day != 0){
      md.fmPortDaysHours= "D"+this.getTransit.day+" "+"H"+this.getTransit.time;
    }else if(this.getTransit.day == 0 && this.getTransit.time != 0){
      md.fmPortDaysHours=  "D0"+" "+"H"+this.getTransit.time;
    }else{
      md.fmPortDaysHours="D0 H0";
    }


    if(this.getPort.all != 0){
      md.atPortHourTemp= "H"+this.getPort.all;
      md.atPortHours= this.getPort.all;
    }else{
      md.atPortHourTemp= "H0";
    }

    if(this.getPort.day != 0){
      md.atPortDaysHours= "D"+this.getPort.day+" "+"H"+this.getPort.time;
    }else if(this.getPort.day == 0 && this.getPort.time != 0){
      md.atPortDaysHours=  "D0"+" "+"H"+this.getPort.time;
    }else{
      md.atPortDaysHours="D0 H0";
    }
    md.callReason = this.modelDetail.callReason;

    return md;
  }

  setDataCopy(){
    this.dtcopy.listStore.store.forEach(dtCopy=>{
      this.modelCopy = new MService;
      this.modelCopy.serviceName = String(dtCopy.copyServiceName).toUpperCase();
      this.modelCopy.serviceCode = String(dtCopy.copyServiceCode).toUpperCase();
      this.modelCopy.psaServiceCode = String(dtCopy.copyPsaServiceCode).toUpperCase();
      this.modelCopy.serviceSequence = dtCopy.copySeq;

      if(this.isType == 'isSsl'){
        this.modelCopy.isSsl = true;
      }else if (this.isType == 'isSlot'){
        this.modelCopy.isSlot = true;
      }else if (this.isType == 'is3Rd'){
        this.modelCopy.is3RdParty = true;
      }

      this.modelCopy.dateCreated= this.genericUtil.getPSIDate();
      this.modelCopy.dateModified = this.genericUtil.getPSIDate();
      if(this.modelCopy.isValid == false){
        this.modelCopy.dateInvalid = this.genericUtil.getPSIDate();
      }
      this.saveDataCopyAll();
      this.listCopy.store.push(this.modelCopy);
    });

  }

  getDataCopy(){
    var modelCopy = new MService;
    modelCopy.seqCop = this.modelCopy.seqCop;
    modelCopy.copyServiceName = String($('input[name="copyServiceName"]').val()).trim().toUpperCase();
    modelCopy.copyServiceCode = String($('input[name="copyServiceCode"]').val()).trim().toUpperCase();
    modelCopy.copyPsaServiceCode = String($('input[name="copyPsaServiceCode"]').val()).trim().toUpperCase();
    modelCopy.copySeq = $('input[name="copySeq"]').val();

    return modelCopy;
  }

  addCopyDetails(){
    this.setValidatorCopy();
    this.modeAddCopy=true;
    this.modeHGrid=false;

    var seq = parseInt($('input[name="gridCopy"]').val())+1;
    var mServiceDetailModel = new MServiceDetail;
    mServiceDetailModel.seqNo = seq;

      this.modelDetail = mServiceDetailModel;

      var mServiceModel = new MService;
      mServiceModel.seqCop = seq;
      mServiceModel.copySeq = this.model.serviceSequence;
      this.modelCopy = mServiceModel;

    $('input[name="gridCopy"]').val(parseInt($('input[name="gridCopy"]').val())+1);
    // console.log("Seq: "+seq);
  }

  checkHeader(){
    return true;
  }

  addNewRow(){
    if(this.checkHeader()){
      if(this.dtgrid.listStore.store.length > 0){
        if(this.dtgrid.getSelectedValues().length > 0){
          var seqList = [];
          this.dtgrid.getSelectedValues().forEach(dtl=>{
            seqList.push(dtl.seqNo);
          });
          seqList.sort(function(a, b){return a-b});
          this.before = seqList[0];
          this.after = seqList[seqList.length-1];
          $('#addRowDialog')
            .modal('setting', 'closable', false)
            .modal('show');
        } else {
          this.addDetails();
        }
      } else {
        this.addDetails();
      }
    }
  }

  addDetails(){
    if(!this.modeDetail){
      var mrgin = 33;
      var gridCount = 0;
      this.setValidatorDetails();
      if(this.dtgrid.listStore.store.length>0){
        gridCount = this.dtgrid.listStore.store.length;
      }else{
        gridCount = parseInt($('input[name="gridCount"]').val());
      }
      var seqNo = gridCount+1;
      var mServiceDetailModel = new MServiceDetail;
      mServiceDetailModel.seqNo = seqNo;

      this.modelDetail = mServiceDetailModel;


      //this.cleared();
      this.modeDetail = true;
      $('input[name="gridCount"]').val(seqNo);
      $('html, body').animate({ scrollTop: $("#trans").offset().top-180 }, 'slow');
    }
  }

  setTransitDayAll(){
    var transAll :string='';
    transAll= (this.dtgrid.getSelectedValues()[0]).fmPortDaysHours;
    if(transAll != '' && transAll != null){

      //let dtTransAll=  (transAll.replace(/D/g,"")).split(' ');

      let dtTransAll = transAll.split(" ");
      console.log("SET TRANSIT : "+String(dtTransAll[0]));
      let dtTransDay = String(dtTransAll[0]).replace(/D/g,"");
      console.log("SET TRANSI3 : "+dtTransDay);
      let dtTransHour = String(dtTransAll[1]).replace(/H/g,"");
      if(dtTransAll.length > 1){
        this.setTransit.day = dtTransDay;
        this.setTransit.time = dtTransHour;
      }else{
        this.setTransit.day = '0';
        this.setTransit.time = dtTransHour;
      }
    }else{
      this.setTransit.day = '0';
      this.setTransit.time = '0';
    }

  }

   setPortDayAll(){
    var portAll :string='';
    portAll= (this.dtgrid.getSelectedValues()[0]).atPortDaysHours;
    if(portAll != '' && portAll != null){

      //let dtPortAll=  (portAll.replace(/D/g,"")).split(' ');
      let dtPortAll = portAll.split(" ");
      let dtPortDay = String(dtPortAll[0]).replace(/D/g,"");
      let dtPortHour = String(dtPortAll[1]).replace(/H/g,"");
      if(dtPortAll.length > 1){
        this.setPort.day = dtPortDay;
        this.setPort.time = dtPortHour;
      }else{
        this.setPort.day = '0';
        this.setPort.time = dtPortHour;
      }
    }else{
      this.setPort.day = '0';
      this.setPort.time = '0';
    }

  }

  getTransitDayAll(){
    var transitDay : number = 0;
    transitDay= parseInt($('input[name="fmPortHours"]').val());
    var transitHour : number = 0;
    transitHour = parseInt($('input[name="fmPortDaysHours"]').val());
    var transitTime : number = 0;

    if(transitDay > 0){
      transitTime = (transitDay*24)+ transitHour;
    }else if(transitHour > 0){
      transitTime = transitHour;
    }else{
      transitTime = 0;
    }

    if(transitTime >= 24){
        this.getTransit.day = Math.floor(transitTime/24);
    }else{
        this.getTransit.day = 0;
    }

    if(transitTime > (this.getTransit.day*24)){
        this.getTransit.time = transitTime -(this.getTransit.day*24);
    }else{
    this.getTransit.all= transitTime;
        this.getTransit.time = 0
    }

    this.getTransit.all= transitTime;


  }

  getPortDayAll(){
    var portDay : number = 0;
    portDay= parseInt($('input[name="atPortHours"]').val());
    var portHour : number = 0;
    portHour = parseInt($('input[name="atPortDaysHours"]').val());
    var portTime : number = 0;

    if(portDay > 0 ){
      portTime = (portDay*24)+ portHour;
    }else if(portHour > 0){
      portTime = portHour;
    }else{
      portTime = 0;
    }

    if(portTime >= 24){
        this.getPort.day = Math.floor(portTime/24);
    }else{
        this.getPort.day = 0;
    }

    if(portTime > (this.getPort.day*24)){
        this.getPort.time = portTime -(this.getPort.day*24);
    }else{
        this.getPort.time = 0
    }

    this.getPort.all= portTime;

  }

  detailsProcess(button){
    // console.log("sampai ke button");
    if(!this.onValidate(this.modelDetail)){
      if(button == 'OK'){
        var mServiceDetailModel = this.getDetailData();
        mServiceDetailModel['select'] = '';
        mServiceDetailModel['seqNo'] = mServiceDetailModel.seqNo;
        //mServiceDetailModel['seqNo'] = mServiceDetailModel.seqNo;

        // console.log('Nilai Seq: '+mServiceDetailModel.seqNo);
        // console.log('NIlai Grid: '+$('input[name="gridCount"]').val());

        if(this.detailSeqMode == ''){
            this.dtgrid.listStore.addData(mServiceDetailModel);
          } else {
            var tmpStore = new ListStore;
            var tempSeq = 0;
            this.dtgrid.listStore.store.forEach(ls=>{
              tmpStore.addData(ls);
            });
            this.dtgrid.listStore.store.splice(0, this.dtgrid.listStore.store.length);
            tmpStore.store.forEach(ts=>{
              if(ts.seqNo == parseInt(this.detailSeqMode.split("-")[1])){
                if(this.detailSeqMode.split("-")[0] == 'before'){
                  tempSeq = tempSeq + 1;
                  mServiceDetailModel.seqNo = tempSeq;
                  mServiceDetailModel['no'] = mServiceDetailModel.seqNo;
                  this.dtgrid.listStore.addData(mServiceDetailModel);
                  tempSeq = tempSeq + 1;
                  ts.seqNo = tempSeq;
                  ts['no'] = ts.seqNo;
                  this.dtgrid.listStore.addData(ts);
                } else if(this.detailSeqMode.split("-")[0] == 'after'){
                  tempSeq = tempSeq + 1;
                  ts.seqNo = tempSeq;
                  ts['no'] = ts.seqNo;
                  this.dtgrid.listStore.addData(ts);
                  tempSeq = tempSeq + 1;
                  mServiceDetailModel.seqNo = tempSeq;
                  mServiceDetailModel['no'] = mServiceDetailModel.seqNo;
                  this.dtgrid.listStore.addData(mServiceDetailModel);
                }
              } else {
                tempSeq = tempSeq + 1;
                ts.seqNo = tempSeq;
                ts['no'] = ts.seq;
                this.dtgrid.listStore.addData(ts);
              }

            });
          }


        this.canceled();
        this.dtgrid.clearSelectedValues();
        this.dtgrid.loadData();
        this.detailSeqMode='';

        $('html, body').animate({ scrollTop: $("#trans").offset().top-130 }, 'slow');
      }else if (button == 'UPDATE'){
        // console.log("masuk update");
        this.modeButton=false;

        var seqNo = parseInt($('input[name="seqNo"]').val());
        var MServiceDetailModel = this.getDetailData();
        MServiceDetailModel['select'] = '';
        MServiceDetailModel['seqNo']=seqNo;
        this.dtgrid.listStore.store.forEach(dtGrid=>{
          if(dtGrid.seqNo == seqNo){
            // console.log('SERVICESEQ GRID DETAIL: ' + dtGrid.seqNo);
            // console.log('GRID '+this.dtgrid.listStore.store.indexOf(dtGrid) );
            this.dtgrid.listStore.updateData(this.dtgrid.listStore.store.indexOf(dtGrid), MServiceDetailModel);
          }
        });

        $('input[name="gridCount"]').val(seqNo);

        this.dtgrid.loadData();
        this.canceled();

      }
    }
  }

  checkNewCopyData(data:MService){
    var result = false;
    this.dtcopy.listStore.store.forEach(fe=>{
      if(fe.copyServiceName == data.copyServiceName && fe.copyServiceCode == data.copyServiceCode && fe.copyPsaServiceCode == data.copyPsaServiceCode && fe.copySeq == data.copySeq){
        result = true;
        this.message("Information","information","Identical data detected, please check your data!","okonly",{ok:""});
      }
    });
  }

  detailsProcessCopy(state){

    //ojan hack
    if(this.modelCopy.copySeq == null){
      this.modelCopy.copySeq = 0;
    }

    if(!this.onValidate(this.modelCopy)){
      if(state=='OK'){

          var mServiceModel = this.getDataCopy();
          mServiceModel['select'] = '';
          //mServiceDetailModel['seqNo'] = mServiceDetailModel.seqNo;
          this.dtcopy.listStore.addData(mServiceModel);

          this.dtcopy.loadData();
          this.clearCopy();
          this.modeAddCopy=false;
          this.modeHGrid=true;



      }else if (state == 'Update'){
        this.modeButtonCopy=false;
        this.modeAddCopy=false;
        this.modeHGrid=true;
        var mServiceModel = this.getDataCopy();
        mServiceModel['select'] = '';
        var index = 0;
        this.dtcopy.listStore.store.forEach(dtCopy=>{
          if(dtCopy.no == mServiceModel.seqCop){
            // console.log('SERVICESEQ GRID DETAIL: ' + dtCopy.no);
             // console.log('INDEX: ' + this.dtcopy.listStore.store.indexOf(dtCopy));
             index=this.dtcopy.listStore.store.indexOf(dtCopy);
            //this.dtcopy.listStore.updateData(this.dtcopy.listStore.store.indexOf(dtCopy), mServiceModel);
          }


        });
        this.dtcopy.listStore.store[index] = mServiceModel;
        this.dtcopy.loadData();

      }
    }
  }

  clearCopy(){
    $('input[name="copyServiceName"]').val("");
    $('input[name="copyServiceCode"]').val("");
    $('input[name="copyPsaServiceCode"]').val("");
    $('input[name="copySeq"]').val(this.model.serviceSequence);
    this.modeButtonCopy=false;
  }


  cleared(){
    $('input[name="remarks').val("");
    $('input[name="serviceCode').val("");
    $('input[name="psaServiceCode').val("");
    $('input[name="serviceSequence').val(1);
    this.valServiceId ='';
    this.valHistoryServiceId='';
    this.sName.setValue('');
    this.canceled();
    this.isType='isSsl';
    this.model = new MService;

  }

  removeAll(){
    this.dtgrid.listStore.store.splice(0,this.dtgrid.listStore.store.length);
    $('input[name="gridCount"]').val(0);
    this.dtgrid.loadData();
  }

  removeAllCopy(){
   // console.log("kesini");

    this.dtcopy.listStore.store.splice(0,this.dtcopy.listStore.store.length);
    $('input[name="gridCopy"]').val(0);
    //this.setGridEvent2 = "refresh2";
    this.dtcopy.loadData();
  }

  remove(){
    // console.log("PANJANG SELECTED VALUE : "+this.dtgrid.getSelectedValues().length );
    if(this.dtgrid.getSelectedValues().length > 0){
      this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.removeDetail()', no: '' });
    }else{
      if(this.dtgrid.listStore.store.length>0){
        this.message('delete','Deletion Record','Please select at least one (1) option.', 'okonly', { ok: ''});
      }
    }
  }

   removeDetail(){
    // console.log("masuk remove");
    var seqNo = 0;
    var countCheck = 0;
    var boxCheck : number;
    var storeTemp = new ListStore;

    this.dtgrid.listStore.store.forEach(xy=>{
      // console.log("xy "+xy);
      storeTemp.addData(xy);
    })


    storeTemp.store.forEach(el=>{
      if(el['select'] == 'checked') {
        boxCheck = this.dtgrid.listStore.store.indexOf(el);
        this.dtgrid.listStore.store.splice(boxCheck,1);

        $('input[name="gridCount"]').val(parseInt($('input[name="gridCount"]').val())-1);
      }
    });

    if(this.dtgrid.listStore.store.length == 0){
      $('input[name="gridCount"]').val(0);
    }


    this.dtgrid.listStore.store.forEach(col=>{
        seqNo = seqNo + 1;
        col.seqNo=seqNo;
    });

    this.canceled();
    this.isType='isSsl';
    this.dtgrid.loadData();
    this.dtgrid.clearSelectedValues();

  }

   removeCopy(){
     // console.log("Remove Copy");
     this.modeAddCopy=false;
     this.modeHGrid=true;

      var seq = 0;
      var countCheck = 0;
      var boxCheck : number;
      var storeTemp = new ListStore;

      this.dtcopy.listStore.store.forEach(xy=>{
        // console.log("xy "+xy);
        storeTemp.addData(xy);
      })


      storeTemp.store.forEach(el=>{
        // console.log("seq "+el.no+" checked "+el['select']);
        if(el['select'] == 'checked') {
          boxCheck = this.dtcopy.listStore.store.indexOf(el);
          this.dtcopy.listStore.store.splice(boxCheck,1);

          $('input[name="gridCopy"]').val(parseInt($('input[name="gridCopy"]').val())-1);
        }
      });


      this.dtcopy.listStore.store.forEach(col=>{
          seq = seq + 1;
          col.no=seq;
      });

      this.cleared();
      this.dtcopy.loadData();

    }
  cancelCopy(){
    this.modelCopy = new MService;
    this.clearCopy();
    this.modeAddCopy=false;
    this.modeHGrid=true;
  }

  closeCopy(){
    this.modeCopy=false;
    this.modeAddCopy=false;
    this.modeHGrid=true;
    this.removeAllCopy();
    this.clearCopy();
  }

  cancelDetail(){
    this.canceled();
    $('input[name="gridCount"]').val(this.dtgrid.listStore.store.length);
    this.modeButton=false;
  }

  canceled(){
    // console.log("Masuk clear log");
    $('input[name="model[detail][portCode]"]').val("");
    $('input[name="model[detail][terminalCode]"]').val("");
    $('input[name="fmPortHours"]').val(0);
    $('input[name="fmPortDaysHours').val("0");
    $('input[name="atPortHours"]').val(0);
    $('input[name="atPortDaysHours"]').val("0");
    this.sPoc.setValue("");
    $("#cbPurpose").dropdown("clear");
    this.sTerminal.setValue('');
    this.sPurpose.setValue('');
    this.modeDetail = false;
    this.modelDetail = new MServiceDetail;
    this.lockTerminal=true;

  }

  cancelHistory(){
    // console.log("masuk cancel history");
    $('input[name="hisServiceCode').val("");
    this.sHistory.setValue("");
    this.gridHistory.listStore.store.splice(0,this.gridHistory.listStore.store.length);
  }

  modalshow(){
    $('#copyDialog')
        .modal('setting', 'closable', false)
        .modal('show');
  }

  goneH(data){
    var a = parseInt(data.split("H")[1]);
    return a;
  }

  saveDataCopyAll(){
    var paramDetail;
    this.setValidatorHeader();
    this.dtgrid.listStore.store.forEach(dtGrid=>{
      // console.log("Port Id: "+dtGrid.portId);
      // console.log("Terminal Id: "+dtGrid.terminalId);
      // console.log("USER 3");

      if(dtGrid.terminalId == ''){
        dtGrid.terminalId = 'LO0000007358';
      }else{
       dtGrid.terminalId = dtGrid.terminalId;
      }
      this.dtcopy.listStore.store.forEach(dtCopy=>{
        dtGrid.serviceSeq = dtCopy.serviceSequence;
        dtGrid.serviceCode = dtCopy.serviceCode;
      });
      this.modelCopy.masterServicesByServiceId.push(dtGrid);
    });
  }

  saveDataAll(){
    var paramDetail;
    this.setValidatorHeader();
    this.dtgrid.listStore.store.forEach(dtGrid=>{
      // console.log("Port Name: "+dtGrid.portName);
      // console.log("Terminal Id: "+dtGrid.terminalId);
      // console.log("USER 3 : "+this.model.serviceCode);

      if(dtGrid.terminalId == ''){
        dtGrid.terminalId = 'LO0000007358';
      }else{
       dtGrid.terminalId = dtGrid.terminalId;
      }

      /*if(dtGrid.portId ==""){
        dtGrid.portId = dtGrid.portId;
      }
      if(this.valTerminalId == ''){
        dtGrid.terminalId = 'LO0000007358';
      }else{
       dtGrid.terminalId = dtGrid.terminalId;
      }

      if(dtGrid.fmPortHours == ""){
        dtGrid.fmPortHours = this.getTransit.all;
      }else{
        dtGrid.fmPortHours = this.goneH(dtGrid.fmPortHours);
      }

     if(dtGrid.atPortHours == ""){
        dtGrid.atPortHours = this.getPort.all;
      }else{
        dtGrid.atPortHours = this.goneH(dtGrid.atPortHours);
      }*/
      if(this.model.copyServiceCode != ""){
        dtGrid.serviceSeq = this.model.copySeq;
        dtGrid.serviceCode = this.model.copyServiceCode;
      }else{
        dtGrid.serviceSeq = this.model.serviceSequence;
        dtGrid.serviceCode = this.model.serviceCode;
      }
      this.model.masterServicesByServiceId.push(dtGrid);
    });
  }

  setValidatorHeader() {
    this.validatorRules = {
      serviceName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter service name',
          }
        ]
      },
      serviceCode: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please enter service code',
          }
        ]
      },
      psaServiceCode: {
        rules: [
          {
            type  : 'empty',
            prompt: 'Please enter PSA code',
          }
        ]
      },
      serviceSequence: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
    }
  }

  setValidatorDetails() {
    // console.log("Masuk Validator Detail");
    this.validatorRules = {
      portName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please input port of call',
          }
        ]
      },
      fmPortHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      fmPortDaysHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      atPortHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      atPortDaysHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      callReason: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter purpose of call port',
          }
        ]
      },
    }
  }

  setValidatorCopy() {
    // console.log("Masuk Validator Copy");
    this.validatorRules = {
      copyServiceName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter service name',
          }
        ]
      },
      copyServiceCode: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter service code',
          }
        ]
      },
      copyPsaServiceCode: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter psa service code',
          }
        ]
      },
      copySeq: {
        rules: [

          {
            type  : 'emptyZero',
            prompt: 'Zero is not allowed.',
          },
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
    }
  }

  setValidatorTerminal() {
    this.validatorRules = {
      terminalName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please input terminal',
          }
        ]
      },
       portName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please input port of call',
          }
        ]
      },
      fmPortHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      fmPortDaysHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      atPortHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      atPortDaysHours: {
        rules: [
          {
            type  : 'positiveInteger',
            prompt: 'No negative value!',
          }
        ]
      },
      callReason: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please enter purpose of call port',
          }
        ]
      },
    }
  }



  handleCreate()
  {
    // handle create event
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.setToolbarButtons('Copy','Copy','copy','copy',false);
    this.model = new MService;
    this.cleared();
    this.sName.setUrl(this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral/*/*/{query}/1/100/*/*');
  }


  handleUpdate()
  {
    // handle update event
    if(this.modeRetrieve == false){
      this.removeAll();
    }
    this.flagUpdate = true;
    this.modeRetrieve = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.lock=false;

    this.lockTerminal=false;
    this.disableToolbarButtons = "";
    this.setToolbarButtons('Copy','Copy','copy', 'copy', true);
    this.invisibleToolbarButtons = "create,print";
    this.removeAll();
    this.canceled();
    this.setData();
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.modeRetrieve = false;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    var formatName = "report-Service-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/37/Service-Export-Reports/*" ;
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // // handle print event
    // window.open(this.configService.config.getPDFUrl.toString().replace(splitUrl[2], "10000") + '/Mreport/runReport/37/Service-Export-Reports/*');
  }

  handleSave()
  {
    // handle save event
    this.beforeSave();
    this.saveDataAll();

    // console.log('handle save event');
    let isValid:boolean = this.onValidate(this.model);

    if(!isValid){
      if(this.dtgrid.listStore.store.length <= 0){
        this.message('warning','Warning','Please input at least 1 port of rotation.','okonly',{ok: 'this.loading=false;'});
      }else{
        /* nojan patch */

        if(this.model.serviceId == ''){
          this.model.userCreated = this.cook.getName();
          this.model.dateCreated = this.genericUtil.getPSIDate();
        } else {
          this.model.userModified = this.cook.getName();
          this.model.dateModified = this.genericUtil.getPSIDate();
        }

        /* end of nojan patch */

        this.listSave.store.push(this.model);
        this.message('save','Saving Data','Confirm to save record ? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.noSave()'});
    }
  }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeRetrieve = false;
    this.modeButton = false;
    this.flagUpdate=false;
    this.form = '';
    this.modeToolbar = false;
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    this.lock = false;
    this.lockTerminal = true;
    this.cleared();
    this.cancelHistory();
    this.removeAll();
    this.removeAllCopy();
  }

  handleHistory(){
    this.modeForm = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "create,update,delete,print,history";
  }

  handleCopy(){
    //this.modalshow();
      this.modeCopy=true;
      this.clearCopy();
      this.removeAllCopy();
  }

  doCopy(){
    var saveCopyAll = ListStore;

    this.modeCopy=false;
    this.setDataCopy();
    this.message('save','Information','Confirm to save copy record ? ', 'yesno', { yes: 'this.saveCopy()', no: 'this.loading = false;'});
  }

  saveCopy(){
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterServices/save',this.listCopy['store']).subscribe((resp) =>{
          if(resp.status == 200){
              this.modelCopy = new MService;
              this.modelDetail = new MServiceDetail;
              this.loading = false;
              this.info = 'Record successfully saved';
              this.listCopy.store.splice(0,this.listCopy.store.length);
              this.headgrid.loadData();
              this.removeAll();
              this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSaveCopy();'});
          }else if(resp.status == 200 && resp.json()['message'] == 'Duplicate Data. Identical Service Code Detected.'){
              this.listSave.removeData(this.model);
              this.loading = false;
              this.message('warning','Warning',resp.json()['message'],'okonly',{ok: 'this.loading=false;'});
          }else{
            this.listSave.removeData(this.model);
            this.loading = false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;
      this.listCopy.store.splice(0,this.listCopy.store.length);
      // console.log(error.message);
      this.info = error.message;
      this.message('warning','Error','An error occur during save the data with error code: ' +error.status+', Please contact your administrator','okonly',{ok:'// console.log("errro occur")'});
    });
  }

  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";


    if(this.headgrid.getSelectedValues().length >= 1){
      this.headgrid.onSortDir("dateModified","DESC");
    }else{
      this.headgrid.onSortDir("dateCreated","DESC");
    }
    this.canceled();
    this.headgrid.clearSelectedValues();
    this.headgrid.onFirst();
  }

  afterSaveCopy(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";
    this.headgrid.onSortDir("dateCreated","DESC");
    this.canceled();
    this.headgrid.clearSelectedValues();
    this.headgrid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.headgrid.getSelectedValues().length);

    this.headgrid.clearSelectedValues();
    this.headgrid.loadData();
  }

  noSave(){
    // console.log("nosave");
    this.listSave.store.splice(0,this.listSave.store.length);
    this.loading = false;

  }

  saveEvent(){
    this.loading = true;
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterServices/save',this.listSave['store']).subscribe((resp) =>{
       // console.log("Responnya "+this.model.serviceName);
       // console.log('STATUS=' + resp.status);
       // console.log('MESSAGE=' + resp.json()['message']);
          if(resp.status == 200 && resp.json()['message'] == 'SUCCESS'){
              //this.model = new MService;
              //this.modelDetail = new MServiceDetail;
              this.loading = false;
              this.info = 'Record successfully saved';
              this.setToolbarButtons('Copy','Copy','copy', 'copy', true);
              this.listSave.store.splice(0,this.listSave.store.length);
              this.headgrid.loadData();
              this.dtgrid.loadData();
              this.flagUpdate=false;
              this.removeAll();
              this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
          }else if(resp.status == 200 && resp.json()['message'] == 'Duplicate Data. Identical Service Code Detected.'){
              this.listSave.removeData(this.model);
              this.listSave.store.splice(0,this.listSave.store.length);
              this.loading = false;
              this.flagUpdate=false;
              this.message('warning','Warning',resp.json()['message'],'okonly',{ok: 'this.loading=false;'});
          }else{
            this.listSave.removeData(this.model);
            this.listSave.store.splice(0,this.listSave.store.length);
            this.loading = false;
            this.flagUpdate=false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      // console.log("ERRORNYA: "+error.json()['status']);
      // console.log("ERRORNYA: "+error.json()['message']);
      this.listSave.store.splice(0,this.listSave.store.length);
      this.loading = false;

      this.info = error.message;

    });
  }

  beforeSave(){
    this.setValidatorHeader();
    // console.log("BeFire");
    this.model.serviceName = String(this.model.serviceName).toUpperCase();
    this.model.serviceCode = String(this.model.serviceCode).toUpperCase();
    this.model.psaServiceCode = String(this.model.psaServiceCode).toUpperCase();
    this.model.dateCreated= this.genericUtil.getPSIDate();
    // console.log("Date Created: "+this.model.dateCreated);
    this.model.dateModified = this.genericUtil.getPSIDate();

    if(this.isType == 'isSsl'){
      this.model.isSsl = true;
    }else if (this.isType == 'isSlot'){
      this.model.isSlot = true;
    }else if (this.isType == 'is3Rd'){
      this.model.is3RdParty = true;
    }

    if(this.model.isValid == false){
      this.model.dateInvalid = this.genericUtil.getPSIDate();
    }

  }


  deleteEvent(){
    // console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.headgrid.getSelectedValues().forEach(element => {
      deleteList.push(element.serviceId);
    });
    // console.log("DELETE : "+deleteList);

    this.genericService.POST(this.configService.config.BASE_API.toString() +'/MasterServices/delete',deleteList).subscribe((resp) =>{
          if(resp.status == 200){
            if(resp.json()["status"] == "SUCCESS"){
              this.model = new MService;
              this.modelDetail = new MServiceDetail;
              this.selectList = [];
              this.loading = false;
              this.headgrid.clearSelectedValues();
              this.headgrid.loadData;
              this.headgrid.onFirst();
              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});
            }else{
              this.message('information','Information',resp.json()["message"], 'okonly', { ok: 'this.loading=false;'});
            }
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });
  }

    valuePortOfCall(event:any) {
      this.setValidatorDetails();
      if(event['locationCode'] != null){
        this.modelDetail.portCode = event['locationCode'];
      }else{
          this.modelDetail.portCode = "";
      }

      if(event['locationName'] != null){
        this.modelDetail.portName = event['locationName'];
      }else{
          this.modelDetail.portName ="";
      }

      if(event['locationId'] != null){
        this.valPortId = event['locationId'];
        this.modelDetail.portId = event['locationId'];
      }else{
        this.valPortId = "";
        this.modelDetail.portId ="";
      }

      if(event['hasTerminal'] != null){
        this.valHasTerminal = event['hasTerminal'];
        this.lockingTerminal(this.valHasTerminal);
      }else{
        // console.log("has terminal null");
        this.valHasTerminal ="";
        this.modelDetail.terminalName ="";
        this.modelDetail.terminalCode = "";
        this.modelDetail.terminalId = "LO0000007358";
      }
      this.sTerminal.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.valPortId+'/{query}';
    }

    valueTerminal(event:any) {
      // console.log(event);
      if(event['locationName'] != null){
        this.modelDetail.terminalCode = event['locationCode'];
      }else{
        this.modelDetail.terminalCode ="";
      }
      if(event['locationName'] != null){
        this.modelDetail.terminalName = event['locationName'];
      }else{
        this.modelDetail.terminalName="";
      }
      this.valTerminalId = event['locationId'];
      this.modelDetail.terminalId = event['locationId'];
      // console.log("LOCATION TERMINAL: "+this.modelDetail.terminalId);
    }

    valueService(event:any) {
      // console.log(event);
      //event di cek : event servicename ga null, pake yg dipilih, kalo null,
      if(event.serviceName != '' && event.serviceName != null){
        this.model.serviceCode = event['serviceCode'];
        this.model.serviceName = event['serviceName'];
      }else{
        if(event.target != null){
          this.model.serviceName = event.target.value;
        }
      }
      this.valServiceId = event['serviceId'];

    }

    valueHistoryService(event:any) {
      // console.log(event);
      this.modelHistory.serviceCode = event['serviceCode'];
      this.modelHistory.serviceName = event['serviceName'];
      this.valHistoryServiceId = event['serviceId'];
      this.gridHistory.onHistory(String(this.valHistoryServiceId));

    }

    valuePurpose(event:any){
    // console.log(event);
    // console.log(event.callReason); //text

    this.modelDetail.callReasonCode = event.callReasonCode;
    this.modelDetail.callReason = event.callReason;
    this.valPurpose = this.modelDetail.callReason;
    }

    lockingTerminal(terminal){
      if(terminal == 'N'){
        this.lockTerminal = true;
        this.sTerminal.setValue("");
        $('input[name="model[detail][terminalCode]"]').val("");
      }else{
        this.lockTerminal = false;
        this.setValidatorTerminal();
      }
    }

    changeType(val:string){
      this.isType = val;
    }

    setInsertSeqRadio(status:boolean, state:string, val){
      if(status){
        switch(state){
          case 'before' :
            this.detailSeqMode = state+'-'+val;
            break;
          case 'after' :
            this.detailSeqMode = state+'-'+val;
            break;
          default :
            this.detailSeqMode = '';
            break;
        }
      } else {
        this.detailSeqMode = '';
      }
    }

    rowChoose(state:any){
      $('#addRowDialog').find('input[name=rowChoose]').prop('checked', false);
      $('#addRowDialog').modal('hide');
      if(state == 'yes'){
        this.addDetails();
        var mServiceDetailModel = new MServiceDetail;
        mServiceDetailModel.seqNo = 0;
        this.modelDetail = mServiceDetailModel;
      } else if(state == 'no'){
        // console.log("class rowchoose no")
        this.detailSeqMode = '';
        this.modeDetail=false;
        this.modeButton=false;
      }
    }

    none(event:any){
    if(event.keyCode == 13){
        event.preventDefault();
    }
  }

    generateServiceHistoryReport(event:any){

      //this.valHistoryStartDate= $('#startDate').find('#datePicker').val();
      //this.valHistoryEndDate= $('#endDate').find('#datePicker').val();
      // console.log("val history :"+this.valHistoryServiceId);
    if(this.valHistoryServiceId == '' || this.valHistoryServiceId == 'undefined'){
      this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    }else {
      var formatName = "report-History-Service-"+this.valHistoryServiceId+"-"+this.modelHistory.serviceCode+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/5/History-Service-"
      + this.valHistoryServiceId + '-'
      + this.modelHistory.serviceCode +'/'
      +this.valHistoryServiceId + '@'
      + this.valHistoryStartDate + '@'
      + this.valHistoryEndDate
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
      //  window.open(this.configService.config.BASE_API.toString().replace('9090','10000').replace("5001","10000") + '/Mreport/runReport/5/History-Service-' + this.valHistoryServiceId + '-' + this.modelHistory.serviceCode +'/'+this.valHistoryServiceId + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);
    }


    }

    changesBound(value){
      if (value && this.formValidation.regStringOnly.test(value) == false) {
        this.model['error-sBound'] = "Only allow alphabets (eg. a-z, A-Z)";
      }else{
        this.model['error-sBound'] = ""
      }
    }

}
