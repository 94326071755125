export class RenominationBookingModel {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyage: string = "";
  voyageDischarging: string = "";
  voyageConnecting: string = "";
  bound: string = "O";
  eta: string = "";
  coc: number = 0;
  cocLocal: number = 0;
  cocTS: number = 0;
  bPOT = "";
  bPot1Code = "";
  bPot1Name = "";
  containerOwnerShip = "COC";
  service = "";
  tsServiceId = "";
  tsTotal = "";
  tsLocal = "";
  tsTS = "";
  tsEta = "";
  tsEtd = "";
  tsRotation = "";
  vesselName: string = "";
  totalAllocated: string = "";
  totalBooked: string = "";
  totalConfirmed: string = "";
  totalAvailable: string = "";
  tsServiceCode: string = "";
  bPolCode = "";
  bPolName: string;
  podId: string = "";
  podCode: string = "";
  podName: string = "";
  vesselIdDischarging: string = "";
  vesselCodeDischarging: string = "";
  vesselNameDischarging: string = "";
  vesselIdConnecting: string = "";
  vesselCodeConnecting: string = "";
  vesselNameConnecting: string = "";
  status: string = "ALL";
  serviceConnecting = "";
  serviceDischarging = "";
  newConnectingService = "";
  tsConnectingEtaPod: any;
  tsConnectingEtaPot: any;
  tsConnectingEtdPod: any;
  tsConnectingEtdPot: any;
  tsDischargingEtaPol: any;
  tsDischargingEtaPot: any;
  tsDischargingEtdPol: any;
  tsDischargingEtdPot: any;
  selectedRows = [];
  rowDataPortPair: any;
  isCombinedRecord: any;
  combinedBundleKey: any;

  constructor() { }
}
