import { GenericService, ConfigService } from 'sibego-ui-library';

export class jasperModel {
  jasperPathList:any[] = [];

  constructor(private genericService:GenericService,
    private configService:ConfigService) {

  };

  getJasperPath(officeCode:string, type:string){
      this.jasperPathList = [];
      var hitUrl = this.configService.config.BASE_API.toString() + "/MasterJasperReport/getPath/"+officeCode+"/"+type;
      this.genericService.GET(hitUrl).subscribe((resp)=>{
        if(resp.json()["message"] == "ok"){
          var rList = resp.json()["data"];
  
          rList.forEach(rL=>{
            this.jasperPathList.push(rL);
          });
        }
      }, error=>{
      });
  };
}