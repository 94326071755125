import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MSailingSchedule,
  MSailingScheduleDetail,
  AllocationModel,
  BLHeader,
  specialCharUtil,
} from "../shared/index";
import { Router } from "@angular/router";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DialogPluginComponent,
} from "sibego-ui-library";
import { log } from "util";
import get = Reflect.get;
import { combineBL, gridCombine } from "../shared/models/combineBL";

import FormValidation from "../../utils/formValidation";
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs-compat";
import { finalize } from "rxjs/operators";

declare var $: any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselId: string = "";
  blOceanVesselCode: string = "";
  blOceanVesselVoyage: string = "";
  blOceanVesselBound: string = "";
  blLoadPortCode: string = "";
  blPot1Code: string = "";
  blPodCode: string = "";
  blFinalDestCode: string = "";
  opCode: string = "";
  status: string = "";
}

@Component({
  selector: "app-transaction-bl-combine-latest-page",
  templateUrl: "./transaction-bl-combine-latest-page.component.html",
  styleUrls: ["./transaction-bl-combine-latest-page.component.css"],
})
export class TransactionBLCombineLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbFinalDest") cbFinalDest: ComboPluginComponent;
  @ViewChild("cbFullEmpty") cbFullEmpty: ComboPluginComponent;

  @ViewChild("grid") grid: GridPluginComponent;
  //@ViewChild("gridDetails") gridDetails: GridPluginComponent;
  //@ViewChild("gridBlMain") gridBlMain: GridPluginComponent;
  //@ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
  @ViewChild("gridMainSelection") gridMainSelection: any;

  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter fgridBlMainor enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;
  checkExceed = 0;

  // * Special Char Utils
  sCharUtil = new specialCharUtil();

  formValidation = new FormValidation();

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  settingPOT1;
  settingDischargePort;
  settingGrid;
  settingFinalDest;
  settingFullEmpty;

  model = new Param();

  isError: Boolean = false;
  msg: string = "";

  validatorRules = {};
  isCnOffice: boolean;

  gridApi;
  gridColumnApi;

  gridApiMain;
  gridColumnApiMain;

  rowData = [];
  columnDefs: any;
  rowSelection = "multiple";
  rowMultiSelectWithClick = true;
  //settingGridBl: any;
  getRowStyle;

  rowSelectionMain = "single";
  rowSelected: any[] = [];
  rowDataMain:any[] = [];
  columnDefsMain: any;
  isDisabledOK:boolean = true;

  //blSource = new Subject();
  //blSource$ = this.blSource.asObservable();
  //isSelectMultipleMainBl = false;
  //dataBLGrid = [];

  //selectedBlList :any[]= [];

  constructor(
    private auth: Authorize,
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.columnDefsMain = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: false,
        
        cellRenderer: function cellTitle(params) {
          //console.log(params);
          var eDiv = document.createElement('div');
          eDiv.className = "ngSelectionCell";
          eDiv.innerHTML = '<input class="pilih" name="selected" type="radio">';

          //let cellValue = '<div class="ngSelectionCell"><input name="selected" type="radio"></div>';
          //console.log(cellValue);
          let hehe = eDiv.querySelectorAll('.pilih')[0];
          hehe.addEventListener('click', function() {
            //console.log('button was clicked!!');
            params.node.setSelected(true);

          });
          return eDiv;
        },
        
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: 'BL Reference', field: 'blNo',  width: 200, headerClass: 'custom-header' },
      { headerName: 'Freight Party', field: 'blBookingFreightPartyName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Shipper', field: 'blShipperName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Consignee', field: 'blConsigneeName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Notify Party', field: 'blNotifyPartyName', width: 200, headerClass: 'custom-header' },
      { headerName: 'RT', field: 'blReceiptTypeCode', width: 100 , headerClass: 'custom-header' },
      { headerName: 'DT', field: 'blDeliveryTypeCode', width: 100 , headerClass: 'custom-header' },
      { headerName: 'DET', field: 'blBookingDetentionName', width: 100 , headerClass: 'custom-header' },
      { headerName: 'REQ', field: 'blBookingDetentionRequest', width: 100 , headerClass: 'custom-header' },
      { headerName: 'DEM', field: 'blBookingDemurrageName', width: 100 , headerClass: 'custom-header' },
      { headerName: 'REQ', field: 'blBookingDemurrageRequest', width: 100 , headerClass: 'custom-header' },
      { headerName: 'Port Of Discharge', field: 'blDischargePortName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Place Of Delivery', field: 'blPlaceOfDeliveryName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Final Destination', field: 'blFinalDestinationName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'P/C', field: 'blBookingFreightType', width: 100 , headerClass: 'custom-header'},
      { headerName: 'Pre Vsl', field: 'preVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Pre Vsl Voy No.', field: 'preVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Ocean Vsl', field: 'oceanVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Ocean Vsl Voy No.', field: 'oceanVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Next Vsl', field: 'nextVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Next Vsl Voy No.', field: 'nextVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Container Status', field: 'blBookingContainerStatus', width: 200 , headerClass: 'custom-header'},
    ];

    this.columnDefs = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: "C", field: "confirmedStatus", width: 100 },
      { headerName: 'BL Reference', field: 'blNo',  width: 200 },
      { headerName: 'Freight Party', field: 'blBookingFreightPartyName', width: 200 },
      { headerName: 'Shipper', field: 'blShipperName', width: 200 },
      { headerName: 'Consignee', field: 'blConsigneeName', width: 200 },
      { headerName: 'Notify Party', field: 'blNotifyPartyName', width: 200 },
      { headerName: 'RT', field: 'blReceiptTypeCode', width: 100  },
      { headerName: 'DT', field: 'blDeliveryTypeCode', width: 100  },
      { headerName: 'DET', field: 'blBookingDetentionName', width: 100 },
      { headerName: 'REQ', field: 'blBookingDetentionRequest', width: 100 },
      { headerName: 'DEM', field: 'blBookingDemurrageName', width: 100 , headerClass: 'custom-header' },
      { headerName: 'REQ', field: 'blBookingDemurrageRequest', width: 100 , headerClass: 'custom-header' },
      { headerName: 'Port Of Discharge', field: 'blDischargePortName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Place Of Delivery', field: 'blPlaceOfDeliveryName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Final Destination', field: 'blFinalDestinationName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'P/C', field: 'blBookingFreightType', width: 100 , headerClass: 'custom-header'},
      { headerName: 'Pre Vsl', field: 'preVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Pre Vsl Voy No.', field: 'preVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Ocean Vsl', field: 'oceanVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Ocean Vsl Voy No.', field: 'oceanVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Next Vsl', field: 'nextVessel', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Next Vsl Voy No.', field: 'nextVesselVoyage', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Container Status', field: 'blBookingContainerStatus', width: 200 , headerClass: 'custom-header'},
    ];

    this.getRowStyle = function (params) {
      
    };

    this.settingToolbar = {
      buttonsFront: [ 
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Combine", event: "combine", icon: "checkmark" },
        //{ name: "Combine2", event: "combine2", icon: "checkmark" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Combine",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/10/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchLoadPort/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchTranshipmentPort/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchDischargePort/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinalDest = {
      id: "cbFinalDest",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchFinalDestination/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url:
        this.configService.config.BASE_API.toString() + "/BLCombine/searchBL",
      page: 10,
      columns: [
        { header: "C", field: "confirmedStatus", width: 200 },
        { header: "BL Reference", field: "blNo", width: 200 },
        {
          header: "Freight Party",
          field: "blBookingFreightPartyName",
          width: 200,
        },
        { header: "Shipper", field: "blShipperName", width: 200 },
        { header: "Consignee", field: "blConsigneeName", width: 200 },
        { header: "Notify Party", field: "blNotifyPartyName", width: 200 },
        { header: "RT", field: "blReceiptTypeCode", width: 200 },
        { header: "DT", field: "blDeliveryTypeCode", width: 200 },
        { header: "DET", field: "blBookingDetentionName", width: 200 },
        { header: "REQ", field: "blBookingDetentionRequest", width: 200 },
        { header: "DEM", field: "blBookingDemurrageName", width: 200 },
        { header: "REQ", field: "blBookingDemurrageRequest", width: 200 },
        {
          header: "Port Of Discharge",
          field: "blDischargePortName",
          width: 200,
        },
        {
          header: "Place Of Delivery",
          field: "blPlaceOfDeliveryName",
          width: 200,
        },
        {
          header: "Final Destination",
          field: "blFinalDestinationName",
          width: 200,
        },
        { header: "P/C", field: "blBookingFreightType", width: 100 },
        { header: "Pre Vsl", field: "preVessel", width: 200 },
        { header: "Pre Vsl Voy No.", field: "preVesselVoyage", width: 200 },
        { header: "Ocean Vsl", field: "oceanVessel", width: 200 },
        { header: "Ocean Vsl Voy No.", field: "oceanVesselVoyage", width: 200 },
        { header: "Next Vsl", field: "nextVessel", width: 200 },
        { header: "Next Vsl Voy No.", field: "nextVesselVoyage", width: 200 },
        {
          header: "Container Status",
          field: "blBookingContainerStatus",
          width: 200,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      height: 500,
      minHeight: 500,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };

    /*
    this.settingGridBl = {
      id: "grid",
      url:
        "",
      page: 10,
      columns: [
        { header: "BL Reference", field: "blNo", width: 200 },
        {
          header: "Freight Party",
          field: "blBookingFreightPartyName",
          width: 200,
        },
        { header: "Shipper", field: "blShipperName", width: 200 },
        { header: "Consignee", field: "blConsigneeName", width: 200 },
        { header: "Notify Party", field: "blNotifyPartyName", width: 200 },
        { header: "RT", field: "blReceiptTypeCode", width: 200 },
        { header: "DT", field: "blDeliveryTypeCode", width: 200 },
        { header: "DET", field: "blBookingDetentionName", width: 200 },
        { header: "REQ", field: "blBookingDetentionRequest", width: 200 },
        { header: "DEM", field: "blBookingDemurrageName", width: 200 },
        { header: "REQ", field: "blBookingDemurrageRequest", width: 200 },
        {
          header: "Port Of Discharge",
          field: "blDischargePortName",
          width: 200,
        },
        {
          header: "Place Of Delivery",
          field: "blPlaceOfDeliveryName",
          width: 200,
        },
        {
          header: "Final Destination",
          field: "blFinalDestinationName",
          width: 200,
        },
        { header: "P/C", field: "blBookingFreightType", width: 100 },
        { header: "Pre Vsl", field: "preVessel", width: 200 },
        { header: "Ocean Vsl", field: "oceanVessel", width: 200 },
        { header: "Next Vsl", field: "nextVessel", width: 200 },
        {
          header: "Container Status",
          field: "blBookingContainerStatus",
          width: 200,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      height: 500,
      minHeight: 500,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      enableSelectedRows: true,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };
    */

    this.settingFullEmpty = {
      id: "cbFullEmpty",
      type: "select", // search | select | select input
      // url         : 'assets/json/bl/fullEmpty.json',
      url: '[ { "fullEmptyCode" : "ALL", "fullEmptyName" : "All/Full/Empty" }, { "fullEmptyCode" : "FULL", "fullEmptyName" : "Full" }, { "fullEmptyCode" : "EMPTY", "fullEmptyName" : "Empty" }]',
      urlType: "inject",
      placeholder: "All/Full/Empty",
      code: "fullEmptyCode",
      title: "fullEmptyName",
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit(): void {
    $("#gridHeader").find("th.collapsing").find("div.checkbox").remove();
    $(".dataTables_scrollBody").css("height", "200px");
    $("#sailingBound").on("click", function () {
      $(this).val("");
    });
    $("#sailingBound").on("mouseleave", function () {
      if ($(this).val() == "") {
        $(this).val("O");
      }
    });

    this.model.blOceanVesselBound = "O";
    this.cbFullEmpty.setValue("All/Full/Empty");
    this.disableToolbarButtons = "combine";
    this.cdr.detectChanges();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "combine":
        this.handleCombine();
        break;
      case "combine2":
        this.handleCombine2();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case "cancel":
        this.handleCancel();
        break;
    }
  }

  clearValidatorError() {
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blLoadPortCode"] = "";
  }

  
  onSelectionChanged(event) {
    const selectedRows = this.gridApi.getSelectedRows();
    console.log(selectedRows);
    //this.rowSelected = selectedRows;
    if (selectedRows.length > 1) {
      this.disableToolbarButtons ="";
    } else {
      this.disableToolbarButtons ="combine";
    }
  }

  onSelectionChangedMain(event) {
    const selectedRows = this.gridApiMain.getSelectedRows();
    console.log(selectedRows);
    //this.rowSelected = selectedRows;
    if (selectedRows.length > 0) {
      this.isDisabledOK = false;
    } else {
      this.isDisabledOK = true;
    }
  }
  

  setValidator() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input vessel.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input voyage.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input load port.",
          },
        ],
      },
    };
  }

  handleRetrieve() {
    this.setValidator();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.isCnOffice = this.model.blOfficeCode.substring(0, 2) === 'CN';

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      /*
      this.grid.setBody(this.model);
      this.grid.loadData();
      this.grid.clearSelectedValues();
      */
      this.genericUtil.showLoader();
      this.rowData = [];
      this.rowDataMain = [];
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLCombine/searchBL/*/*", this.model)
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp)=>{
        console.log(resp);
        this.rowData = resp.json()['content'];
      })
      
    }
  }

  async handleCombine() {
    let result: any[] = [];
    //let data: any[] = this.grid.getSelectedValues();
    let data: any[] = this.gridApi.getSelectedRows();
    console.log(data);

    if (data.length == 0 || data.length == 1) {
      this.msg = "You must select at least 2 BLs in order to combine!";
      this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
    } else if (data.length > 1) {
      
      let checkSimilar: boolean = true;
      let checkOBLPrinted: boolean = false;

      let currentBL = new BLHeader();

      let mpPrepaid = new Map();
      let mpCollect = new Map();
      let mpPayer = new Map();

      data.forEach((element) => {
        element.mapPayer.forEach((el) => {
          console.log("payer ==> " + el);
          if (el != "") {
            if (!mpPayer.has(el)) {
              mpPayer.set(el, el);
            }
          }
        });

        element.mapPrepaid.forEach((el) => {
          console.log("prepaid ==> " + el);
          if (el != "") {
            if (!mpPrepaid.has(el)) {
              mpPrepaid.set(el, el);
            }
          }
        });

        element.mapCollect.forEach((el) => {
          console.log("collect ==> " + el);
          if (el != "") {
            if (!mpCollect.has(el)) {
              mpCollect.set(el, el);
            }
          }
        });

        console.log(element.blNo + " - " + element.blIsBlPrinted);
        element["user"] = this.cookieService.getName().toUpperCase();
        element["date"] = this.genericUtil.getPSIDate();
        element["exceed"] = this.checkExceed;

        if (element.blIsBlPrinted == "Y") {
          checkOBLPrinted = true;
        }

        result.push(element);

        if (currentBL.blNo == "") {
          currentBL.blNo = element.blNo;
          currentBL.blBookingContainerStatus = element.blBookingContainerStatus;
          currentBL.blBookingFreightParty = element.blBookingFreightParty;
          currentBL.blShipperId = element.blShipperId;
          currentBL.blConsigneeId = element.blConsigneeId;
          currentBL.blNotifyPartyId = element.blNotifyPartyId;
          currentBL.blReceiptTypeCode = element.blReceiptTypeCode;
          currentBL.blDeliveryTypeCode = element.blDeliveryTypeCode;
          currentBL.blBookingDetention = element.blBookingDetention;
          currentBL.blBookingDetentionRequest =
            element.blBookingDetentionRequest;
          currentBL.blBookingDemurrage = element.blBookingDemurrage;
          currentBL.blBookingDemurrageRequest =
            element.blBookingDemurrageRequest;
          currentBL.blDischargePortCode = element.blDischargePortCode;
          currentBL.blPlaceOfDeliveryCode = element.blPlaceOfDeliveryCode;
          currentBL.blFinalDestinationCode = element.blFinalDestinationCode;
          currentBL.oceanVessel = element.oceanVessel;
          currentBL.nextVessel = element.nextVessel;
          currentBL.preVessel = element.preVessel;
          currentBL.blBookingFreightType = element.blBookingFreightType;
          currentBL.blIsBlPrinted = element.blIsBlPrinted;
          currentBL.blContainerOwnership = element.blBookingContainerOwnership;
          currentBL.preVesselId = element.preVesselId;
          currentBL.oceanVesselId = element.oceanVesselId;
          currentBL.nextVesselId = element.nextVesselId;
          currentBL.nextVesselVoyage = element.nextVesselVoyage;
          currentBL.confirmedStatus = element.confirmedStatus;
        }

        let currentBLx = new BLHeader();
        currentBLx.blNo = element.blNo;
        currentBLx.blBookingContainerStatus = element.blBookingContainerStatus;
        currentBLx.blBookingFreightParty = element.blBookingFreightParty;
        currentBLx.blShipperId = element.blShipperId;
        currentBLx.blConsigneeId = element.blConsigneeId;
        currentBLx.blNotifyPartyId = element.blNotifyPartyId;
        currentBLx.blReceiptTypeCode = element.blReceiptTypeCode;
        currentBLx.blDeliveryTypeCode = element.blDeliveryTypeCode;
        currentBLx.blBookingDetention = element.blBookingDetention;
        currentBLx.blBookingDetentionRequest =
          element.blBookingDetentionRequest;
        currentBLx.blBookingDemurrage = element.blBookingDemurrage;
        currentBLx.blBookingDemurrageRequest =
          element.blBookingDemurrageRequest;
        currentBLx.blDischargePortCode = element.blDischargePortCode;
        currentBLx.blPlaceOfDeliveryCode = element.blPlaceOfDeliveryCode;
        currentBLx.blFinalDestinationCode = element.blFinalDestinationCode;
        currentBLx.oceanVessel = element.oceanVessel;
        currentBLx.nextVessel = element.nextVessel;
        currentBLx.preVessel = element.preVessel;
        currentBLx.blBookingFreightType = element.blBookingFreightType;
        currentBLx.blIsBlPrinted = element.blIsBlPrinted;
        currentBLx.blContainerOwnership = element.blBookingContainerOwnership;
        currentBLx.preVesselId = element.preVesselId;
        currentBLx.oceanVesselId = element.oceanVesselId;
        currentBLx.nextVesselId = element.nextVesselId;
        currentBLx.nextVesselVoyage = element.nextVesselVoyage;
        currentBLx.confirmedStatus = element.confirmedStatus;

        if (
          currentBL.blBookingContainerStatus !=
          currentBLx.blBookingContainerStatus
        ) {
          checkSimilar = false;
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Container Status.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        } else if (
          currentBL.blBookingFreightParty != currentBLx.blBookingFreightParty
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Freight Party.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Freight Party.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blContainerOwnership != currentBLx.blContainerOwnership
        ) {
          checkSimilar = false;
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Container Ownership.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blShipperId != currentBLx.blShipperId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Shipper.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Shipper.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blConsigneeId != currentBLx.blConsigneeId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Consignee.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Consignee.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blNotifyPartyId != currentBLx.blNotifyPartyId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Notify Party.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Notify Party.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blReceiptTypeCode != currentBLx.blReceiptTypeCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Receipt Type.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Receipt Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blDeliveryTypeCode != currentBLx.blDeliveryTypeCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Delivery Type.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Delivery Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingFreightType != currentBLx.blBookingFreightType
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Freight Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDetention != currentBLx.blBookingDetention
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Detention.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Detention (" +
            currentBL.blBookingDetentionRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDetentionRequest !=
          currentBLx.blBookingDetentionRequest
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Detention.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Detention (" +
            currentBL.blBookingDetentionRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDemurrageRequest !=
          currentBLx.blBookingDemurrageRequest
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Demurrage.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Demurrage (" +
            currentBL.blBookingDemurrageRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDemurrage != currentBLx.blBookingDemurrage
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Demurrage.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Demurrage (" +
            currentBL.blBookingDemurrageRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blDischargePortCode != currentBLx.blDischargePortCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Discharge Port.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Discharge Port.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blPlaceOfDeliveryCode != currentBLx.blPlaceOfDeliveryCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Place of Delivery.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Place of Delivery.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blFinalDestinationCode != currentBLx.blFinalDestinationCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Final Destination.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.preVesselId != currentBLx.preVesselId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Pre Vessel.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.oceanVesselId != currentBLx.oceanVesselId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Connecting Vessel.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.nextVesselId != currentBLx.nextVesselId || currentBL.nextVesselVoyage !== currentBLx.nextVesselVoyage) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg = "Different connecting vessel/voyage detected, unable to combine.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.confirmedStatus != currentBLx.confirmedStatus) {
          checkSimilar = false;
          this.msg = "Different &lt;Confirmed&gt; status detected, please check with Traffic team.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        }
      });

      console.log("similar ? : " + checkSimilar);
      if (checkSimilar) {
        console.log("checkOBLPrinted = " + checkOBLPrinted);
        if (checkOBLPrinted) {
          this.msg = "Unable to combine due to OBL printed.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else {
          /*
            console.log("mpPrepaid.size = " + mpPrepaid.size)
            console.log("mpCollect.size = " + mpCollect.size)
            console.log("mpPayer.size = " + mpPayer.size)

            if(mpPrepaid.size > 3){
              this.msg = "Unable to combine. Prepaid At is exceeding 3 records.";
              this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
              return;
            }else if(mpCollect.size > 3){
              this.msg = "Unable to combine. Payable At is exceeding 3 records.";
              this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
              return;
            }else if(mpPayer.size > 3){
              this.msg = "Unable to combine. Payer is exceeding 3 records.";
              this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
              return;
            }else{
               this.genericUtil.showLoaderPage();
              //console.log(result);
              this.combineProcess(result);
            }
            */
          //this.genericUtil.showLoaderPage();
          //this.combineProcess(result);
          if (this.isCnOffice) {
            console.log(result);
            this.rowSelected = result;
            this.rowDataMain = data;
            await this.handleSelectMainBl();
          }else{
            this.genericUtil.showLoaderPage();
            this.combineProcess(result);
          }
        }
      }
    }
  }

  handleCombine2() {
    let result: any[] = [];
    let data: any[] = this.grid.getSelectedValues();
    console.log(data);

    if (data.length == 0 || data.length == 1) {
      this.msg = "You must select at least 2 BLs in order to combine!";
      this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
    } else if (data.length > 1) {
      let checkSimilar: boolean = true;
      let checkOBLPrinted: boolean = false;

      let currentBL = new BLHeader();

      let mpPrepaid = new Map();
      let mpCollect = new Map();
      let mpPayer = new Map();

      data.forEach((element) => {
        element.mapPayer.forEach((el) => {
          console.log("payer ==> " + el);
          if (el != "") {
            if (!mpPayer.has(el)) {
              mpPayer.set(el, el);
            }
          }
        });

        element.mapPrepaid.forEach((el) => {
          console.log("prepaid ==> " + el);
          if (el != "") {
            if (!mpPrepaid.has(el)) {
              mpPrepaid.set(el, el);
            }
          }
        });

        element.mapCollect.forEach((el) => {
          console.log("collect ==> " + el);
          if (el != "") {
            if (!mpCollect.has(el)) {
              mpCollect.set(el, el);
            }
          }
        });

        console.log(element.blNo + " - " + element.blIsBlPrinted);
        element["user"] = this.cookieService.getName().toUpperCase();
        element["date"] = this.genericUtil.getPSIDate();
        element["exceed"] = this.checkExceed;

        if (element.blIsBlPrinted == "Y") {
          checkOBLPrinted = true;
        }

        result.push(element);

        if (currentBL.blNo == "") {
          currentBL.blNo = element.blNo;
          currentBL.blBookingContainerStatus = element.blBookingContainerStatus;
          currentBL.blBookingFreightParty = element.blBookingFreightParty;
          currentBL.blShipperId = element.blShipperId;
          currentBL.blConsigneeId = element.blConsigneeId;
          currentBL.blNotifyPartyId = element.blNotifyPartyId;
          currentBL.blReceiptTypeCode = element.blReceiptTypeCode;
          currentBL.blDeliveryTypeCode = element.blDeliveryTypeCode;
          currentBL.blBookingDetention = element.blBookingDetention;
          currentBL.blBookingDetentionRequest =
            element.blBookingDetentionRequest;
          currentBL.blBookingDemurrage = element.blBookingDemurrage;
          currentBL.blBookingDemurrageRequest =
            element.blBookingDemurrageRequest;
          currentBL.blDischargePortCode = element.blDischargePortCode;
          currentBL.blPlaceOfDeliveryCode = element.blPlaceOfDeliveryCode;
          currentBL.blFinalDestinationCode = element.blFinalDestinationCode;
          currentBL.oceanVessel = element.oceanVessel;
          currentBL.nextVessel = element.nextVessel;
          currentBL.preVessel = element.preVessel;
          currentBL.blBookingFreightType = element.blBookingFreightType;
          currentBL.blIsBlPrinted = element.blIsBlPrinted;
          currentBL.blContainerOwnership = element.blBookingContainerOwnership;
        }

        let currentBLx = new BLHeader();
        currentBLx.blNo = element.blNo;
        currentBLx.blBookingContainerStatus = element.blBookingContainerStatus;
        currentBLx.blBookingFreightParty = element.blBookingFreightParty;
        currentBLx.blShipperId = element.blShipperId;
        currentBLx.blConsigneeId = element.blConsigneeId;
        currentBLx.blNotifyPartyId = element.blNotifyPartyId;
        currentBLx.blReceiptTypeCode = element.blReceiptTypeCode;
        currentBLx.blDeliveryTypeCode = element.blDeliveryTypeCode;
        currentBLx.blBookingDetention = element.blBookingDetention;
        currentBLx.blBookingDetentionRequest =
          element.blBookingDetentionRequest;
        currentBLx.blBookingDemurrage = element.blBookingDemurrage;
        currentBLx.blBookingDemurrageRequest =
          element.blBookingDemurrageRequest;
        currentBLx.blDischargePortCode = element.blDischargePortCode;
        currentBLx.blPlaceOfDeliveryCode = element.blPlaceOfDeliveryCode;
        currentBLx.blFinalDestinationCode = element.blFinalDestinationCode;
        currentBLx.oceanVessel = element.oceanVessel;
        currentBLx.nextVessel = element.nextVessel;
        currentBLx.preVessel = element.preVessel;
        currentBLx.blBookingFreightType = element.blBookingFreightType;
        currentBLx.blIsBlPrinted = element.blIsBlPrinted;
        currentBLx.blContainerOwnership = element.blBookingContainerOwnership;

        if (
          currentBL.blBookingContainerStatus !=
          currentBLx.blBookingContainerStatus
        ) {
          checkSimilar = false;
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Container Status.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
        } else if (
          currentBL.blBookingFreightParty != currentBLx.blBookingFreightParty
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Freight Party.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Freight Party.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blContainerOwnership != currentBLx.blContainerOwnership
        ) {
          checkSimilar = false;
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Container Ownership.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blShipperId != currentBLx.blShipperId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Shipper.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Shipper.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blConsigneeId != currentBLx.blConsigneeId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Consignee.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Consignee.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.blNotifyPartyId != currentBLx.blNotifyPartyId) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Notify Party.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Notify Party.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blReceiptTypeCode != currentBLx.blReceiptTypeCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Receipt Type.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Receipt Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blDeliveryTypeCode != currentBLx.blDeliveryTypeCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Delivery Type.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Delivery Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingFreightType != currentBLx.blBookingFreightType
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Freight Type.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDetention != currentBLx.blBookingDetention
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Detention.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Detention (" +
            currentBL.blBookingDetentionRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDetentionRequest !=
          currentBLx.blBookingDetentionRequest
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Detention.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Detention (" +
            currentBL.blBookingDetentionRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDemurrageRequest !=
          currentBLx.blBookingDemurrageRequest
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Demurrage.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Demurrage (" +
            currentBL.blBookingDemurrageRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blBookingDemurrage != currentBLx.blBookingDemurrage
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Demurrage.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Demurrage (" +
            currentBL.blBookingDemurrageRequest +
            " days).";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blDischargePortCode != currentBLx.blDischargePortCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Discharge Port.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Discharge Port.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blPlaceOfDeliveryCode != currentBLx.blPlaceOfDeliveryCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Place of Delivery.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Place of Delivery.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (
          currentBL.blFinalDestinationCode != currentBLx.blFinalDestinationCode
        ) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Final Destination.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.preVessel != currentBLx.preVessel) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Pre Vessel.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.oceanVessel != currentBLx.oceanVessel) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Connecting Vessel.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else if (currentBL.nextVessel != currentBLx.nextVessel) {
          checkSimilar = false;
          //this.message('information', "Information", "All the B/Ls chosen for the combine function must have the same Final Destination.", "okonly", {ok: ""});
          this.msg =
            "All the B/Ls chosen for the combine function must have the same Next Vessel.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        }
      });

      console.log("similar ? : " + checkSimilar);
      if (checkSimilar) {
        console.log("checkOBLPrinted = " + checkOBLPrinted);
        if (checkOBLPrinted) {
          this.msg = "Unable to combine due to OBL printed.";
          this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          return;
        } else {
          /*
          console.log("mpPrepaid.size = " + mpPrepaid.size)
          console.log("mpCollect.size = " + mpCollect.size)
          console.log("mpPayer.size = " + mpPayer.size)

          if(mpPrepaid.size > 3){
            this.msg = "Unable to combine. Prepaid At is exceeding 3 records.";
            this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
            return;
          }else if(mpCollect.size > 3){
            this.msg = "Unable to combine. Payable At is exceeding 3 records.";
            this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
            return;
          }else if(mpPayer.size > 3){
            this.msg = "Unable to combine. Payer is exceeding 3 records.";
            this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
            return;
          }else{
             this.genericUtil.showLoaderPage();
            //console.log(result);
            this.combineProcess(result);
          }
          */
          this.genericUtil.showLoaderPage();
          this.combineProcess2(result);
        }
      }
    }
  }

  handleCancel() {
    this.isError = false;
    this.genericUtil.hideLoader();
    this.model = new Param();
    this.model.blOceanVesselBound = "O";

    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbLoadPort.setValue("");
    this.cbPOT1.setValue("");
    this.cbDischargePort.setValue("");
    this.cbFinalDest.setValue("");
    //this.grid.onClear();
    this.rowData = [];
    this.rowDataMain = [];
  }

  eventMessage(event: any) {
    if (event !== '') {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchLoadPort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbLoadPort.setValue("");
      this.model.blLoadPortCode = "";

      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchDischargePort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbDischargePort.setValue("");
      this.model.blPodCode = "";

      this.cbFinalDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchFinalDestination/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFinalDest.setValue("");
      this.model.blFinalDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchTranshipmentPort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.model.blPot1Code = "";
    }
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchLoadPort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbLoadPort.setValue("");
      this.model.blLoadPortCode = "";

      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchDischargePort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbDischargePort.setValue("");
      this.model.blPodCode = "";

      this.cbFinalDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchFinalDestination/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFinalDest.setValue("");
      this.model.blFinalDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchTranshipmentPort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.model.blPot1Code = "";
    }
  }

  changeEventLoadPort(event) {
    if (event.locationCode != null) {
      this.model.blLoadPortCode = event.locationCode;
    } else {
      this.model.blLoadPortCode = "";
    }
  }

  changeEventPOT1(event) {
    if (event.locationCode != null) {
      this.model.blPot1Code = event.locationCode;
    } else {
      this.model.blPot1Code = "";
    }
  }

  changeEventDischargePort(event) {
    if (event.locationCode != null) {
      this.model.blPodCode = event.locationCode;
    } else {
      this.model.blPodCode = "";
    }
  }

  changeEventFinalDest(event) {
    if (event.locationCode != null) {
      this.model.blFinalDestCode = event.locationCode;
    } else {
      this.model.blFinalDestCode = "";
    }
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "combine2";
        } else {
          this.disableToolbarButtons = "update,delete,combine2";
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "combine2";
        } else {
          this.disableToolbarButtons = "update,delete,combine2";
        }

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleCombine();
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  getBLString(dt: BLHeader): string {
    return (
      dt.blOfficeCode +
      "/" +
      dt.blOceanVesselCode +
      "/" +
      this.sCharUtil.htmlEncode(String(dt.blOceanVesselVoyage).trim()) +
      "/" +
      dt.blOceanVesselBound +
      "/" +
      dt.blLoadPortCode +
      "/" +
      dt.blBookingFreightParty +
      "/" +
      dt.blShipperId +
      "/" +
      dt.blConsigneeId +
      "/" +
      dt.blNotifyPartyId +
      "/" +
      dt.blReceiptTypeCode +
      "/" +
      dt.blDeliveryTypeCode +
      "/" +
      dt.blBookingDetention +
      "/" +
      dt.blBookingDemurrage +
      "/" +
      dt.blDischargePortCode +
      "/" +
      dt.blPlaceOfDeliveryCode +
      "/" +
      dt.blFinalDestinationCode
    );
  }

  combineProcess(data: any[]) {
    data.sort(function (a, b) {
      var x = a.blNo.toLowerCase();
      var y = b.blNo.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    console.log("** after sort **");
    console.log(data);

    console.log("checkExceed : " + this.checkExceed);
    console.log(this.gridApiMain.getSelectedRows()[0]);
    //if(this.checkExceed == 0){
    
    
    this.genericUtil.showLoader();
    let dataBL = this.gridApiMain.getSelectedRows();
    
    this.genericService
      .POST(
        this.isCnOffice ? this.configService.config.BASE_API.toString() + `/BLCombine/combine?mainBL=${dataBL[0].blNo}` : this.configService.config.BASE_API.toString() + "/BLCombine/combine",
        data
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        if (resp.json()["status"] == "OK") {
          //this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.handleRetrieve()"});
          this.genericUtil.hideLoader();
          this.message(
            "information",
            "Information",
            resp.json()["message"].toString(),
            "okonly",
            { ok: "this.handleRetrieve()" }
          );
        } else {
          if (Number.parseInt(resp.json()["exceed"]) == 0) {
            this.checkExceed = 0;
            this.genericUtil.hideLoader();

            this.msg = resp.json()["message"];
            this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          } else {
            this.checkExceed = Number.parseInt(resp.json()["exceed"]);
            if (this.checkExceed == 7) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At, Payable At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 6) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Payable At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 5) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 4) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At and Payable At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 3) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 2) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg = "Unable to combine. Payable At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 1) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg = "Unable to combine. Prepaid At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          }
        }
      });
    


    //}else{
    /*
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLCombine/combineExceed",data).subscribe((resp) => {
        if(resp.json()['status'] == "OK"){
          //this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.handleRetrieve()"});
          this.genericUtil.hideLoader();

          this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.afterOnDialogExceedYes()"});
        }else{

            this.checkExceed = 0;
            this.genericUtil.hideLoader();

            this.msg = resp.json()['message'];
            this.genericUtil.showDialog('msgDialog', "Information", 420, 160);

        }
      })
      */
    //}
  }

  combineProcess2(data: any[]) {
    data.sort(function (a, b) {
      var x = a.blNo.toLowerCase();
      var y = b.blNo.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    console.log("** after sort **");
    console.log(data);

    console.log("checkExceed : " + this.checkExceed);
    //if(this.checkExceed == 0){
    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/BLCombine/combine2",
        data
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        if (resp.json()["status"] == "OK") {
          //this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.handleRetrieve()"});
          this.genericUtil.hideLoader();
          /*
          this.msg = resp.json()['message'];
          this.genericUtil.showDialog('msgDialog', "Information", 420, 160);
          */
          this.message(
            "information",
            "Information",
            resp.json()["message"].toString(),
            "okonly",
            { ok: "this.handleRetrieve()" }
          );
        } else {
          if (Number.parseInt(resp.json()["exceed"]) == 0) {
            this.checkExceed = 0;
            this.genericUtil.hideLoader();

            this.msg = resp.json()["message"];
            this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
          } else {
            this.checkExceed = Number.parseInt(resp.json()["exceed"]);

            if (this.checkExceed == 7) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At, Payable At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 6) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Payable At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 5) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At and Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 4) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Prepaid At and Payable At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 3) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg =
                "Unable to combine. Billing Party more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 2) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg = "Unable to combine. Payable At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            } else if (this.checkExceed == 1) {
              this.checkExceed = 0;
              this.genericUtil.hideLoader();

              this.msg = "Unable to combine. Prepaid At more than 3 records.";
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          }
        }
      });

    //}else{
    /*
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLCombine/combineExceed",data).subscribe((resp) => {
        if(resp.json()['status'] == "OK"){
          //this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.handleRetrieve()"});
          this.genericUtil.hideLoader();

          this.message('information', "Information", resp.json()['message'].toString(), "okonly", {ok: "this.afterOnDialogExceedYes()"});
        }else{

            this.checkExceed = 0;
            this.genericUtil.hideLoader();

            this.msg = resp.json()['message'];
            this.genericUtil.showDialog('msgDialog', "Information", 420, 160);

        }
      })
      */
    //}
  }

  afterOnDialogExceedYes() {
    this.checkExceed = 0;
    this.genericUtil.closeDialog("exceedDialog");
    this.handleRetrieve();
  }

  onDialogExceedYes() {
    let result: any[] = [];
    console.log(this.checkExceed);
    let data: any[] = this.grid.getSelectedValues();
    console.log(data);
    data.forEach((element) => {
      console.log(element.blNo);
      element["user"] = this.cookieService.getName().toUpperCase();
      element["exceed"] = this.checkExceed;
      result.push(element);
    });

    /*
    this.checkExceed = 0;
    this.genericUtil.hideLoader();
    this.genericUtil.closeDialog('exceedDialog');
    */
    this.combineProcess(result);
  }

  onDialogExceedCancel() {
    this.checkExceed = 0;
    this.genericUtil.hideLoader();
    this.genericUtil.closeDialog("exceedDialog");
  }

  closeMsgDialog() {
    this.genericUtil.closeDialog("msgDialog");
  }

  closeSaveDialog() {
    this.genericUtil.closeDialog("saveDialog");
    this.handleRetrieve();
  }

  changeEventFullEmpty(event) {
    if (
      event.fullEmptyCode != null &&
      event.fullEmptyCode !== "" &&
      event.fullEmptyCode !== undefined
    ) {
      if (event.fullEmptyCode == "ALL") {
        this.model.status = "";
      } else {
        this.model.status = event.fullEmptyCode;
      }
    } else {
      this.model.status = "";
    }
  }

  OIMonly(event: any) {
    if (!(event.key == "O" || event.key == "I" || event.key == "M")) {
      event.preventDefault();
    }
  }

  ExceptApostrophe(event: any) {
    if (event.key == "'") {
      event.preventDefault();
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar?: string
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  handleSelectMainBl() {
    this.gridApiMain.deselectAll();
    /*
    return new Promise((resolve, reject) => {
      if (this.isCnOffice || true) {
        this.genericUtil.showDialog(
          'selectMainBLDialog',
          'Information',
          900,
          700
        );
        setTimeout(() => {
          this.gridBlMain.listStore.store.splice(0, this.gridBlMain.listStore.store.length);
          this.gridBlMain.listStore.store = [];
          this.gridBlMain.listStore.store = dataSelected;
          this.dataBLGrid = dataSelected;
          this.gridBlMain.modeRadio = true;
          this.gridBlMain.setBody(this.model);
          this.gridBlMain.loadData();
          this.gridBlMain.clearSelectedValues();
          this.disableCheckbox();
        }, 100);

        this.blSource$.pipe().subscribe(
          bl => bl ? resolve(true) : ''
        );
      }
    });
    */
    let heightDialog = 450;
    /*
    if (this.selectedBlList.length > 3) {
      heightDialog = 450;
    }
    if (this.selectedBlList.length == 3) {
      heightDialog = 350;
    }
    if (this.selectedBlList.length == 2) {
      heightDialog = 300;
    }
    if (this.selectedBlList.length == 1) {
      heightDialog = 250;
    }
    */
    this.genericUtil.showDialog(
      `selectMainBLDialog`,
      "Information",
      1000,
      heightDialog
    );
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onGridReadyMain(params) {
    this.gridApiMain = params.api;
    this.gridColumnApiMain = params.columnApi;
    this.gridApiMain.hideOverlay();
  }

  continueCombine() {
    this.genericUtil.closeDialog('selectMainBLDialog');
    console.log(this.rowSelected);

    this.combineProcess(this.rowSelected);

    /*
    if (this.gridBlMain.getSelectedValues().length === 1) {
      this.blSource.next(true);
      this.loading = false;
      this.genericUtil.closeDialog('selectMainBLDialog');
    } else {
      this.isSelectMultipleMainBl = true;
    }
    */
  }

  cancelCombine() {
    this.loading = false;
    /*
    this.gridBlMain.modeRadio = false;
    this.gridBlMain.listStore.store.splice(0, this.gridBlMain.listStore.store.length);
    this.gridBlMain.listStore.store = [];
    this.gridBlMain.store = [];
    this.grid.clearSelectedValues();
    this.gridBlMain.loadData();
    */
    this.genericUtil.closeDialog('selectMainBLDialog');
  }

  /*
  gridEventBl(event) {
    switch (event.split(".")[0]) {
      case "selected":
        console.log(event.split(".")[0]);

        if (this.gridBlMain.getSelectedValues().length === 1) {
          // this.blSource.next(true);
          this.disableToolbarButtons = "combine2";
          this.isSelectMultipleMainBl = false;
        }

        if (this.gridBlMain.getSelectedValues().length > 1) {
          this.isSelectMultipleMainBl = true;
        }
        break;

      case "click":
        if (this.gridBlMain.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        } else {
          this.disableToolbarButtons = "update,delete,combine2";
        }

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleCombine();
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGridBl(event) {
    this.info = event;
  }
  */

  disableCheckbox() {
    const tb = document.getElementById('grid-bl');
    const th = tb.querySelector('table').querySelectorAll('th')[1];
    th.querySelector('div').classList.add("custom-display-checkbox");
  }

}
