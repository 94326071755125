import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ConfigService,
  CookieService,
  GenericService,
} from "sibego-ui-library";
import { ExceptionModel } from "../../shared/models/exceptionModel";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ExceptionBookingService {
  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.201.201:14000';
  userID = this.cookieService.getName();
  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  model = new ExceptionModel();

  private modelSource = new BehaviorSubject<ExceptionModel>(null);
  modelSource$ = this.modelSource.asObservable();

  constructor(
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericService: GenericService,
    private _http: HttpClient
  ) {}

  getExceptionBooking(model) {
    const url =
      this.BASE_URL +
      `/TsExceptionBooking/retrieve?pot=${model.bPot1Code}&status=${model.status}&pol=${model.bPolCode}&pod=${model.podCode}&dischargingVesselId=${model.vesselIdDischarging}&dischargingVoyage=${model.voyageDischarging}&dischargingVesselBound=${model.dischargingBound}&connectingVesselId=${model.vesselIdConnecting}&connectingVoyage=${model.voyageConnecting}&connectingVesselBound=${model.connectingBound}`;
    return this.genericService.GET(url);
  }

  getExceptionDataHandling(bkgPOT) {
    const url =
      this.BASE_URL +
      `/TsExceptionBooking/exceptionHandling/retrieve/${bkgPOT}`;
    return this.genericService.GET(url);
  }

  getExceptionBookingByBkgNo(bkgNo){
    const url =
    this.BASE_URL +
    `/TsExceptionBooking/retrieveByThruBkgNo/${bkgNo}`;
  return this.genericService.GET(url);
  }

  saveExceptionHandleLink(body){
    const url =
    this.BASE_URL +
    `/TsExceptionBooking/exceptionHandling/save`;
  return this.genericService.POST(url, body);
  }

  setModel(model: ExceptionModel) {
    this.modelSource.next(model);
  }
}
