<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Account PIC
        </h4>
        <!-- Begin of Grid -->
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <div class="ui raised segment">
              <div class="one field" style="width: 100%">
                <div class="ten wide fields">
                  <div
                    class="ui test toggle checkbox"
                    [class.disabled]="lock == true"
                  >
                    <input
                      type="checkbox"
                      [(ngModel)]="model['isValid']"
                      name="isValid"
                      tabindex="0"
                      class="hidden"
                    />
                    <label>Valid?</label>
                  </div>
                </div>
                <div class="ten wide fields">
                  <div
                    class="required field"
                    style="width: 50%; margin-top: 10px"
                    [class.error]="
                      model['error-accountName'] != null &&
                      model['error-accountName'] != ''
                    "
                  >
                    <label>Name</label>
                    <input
                      type="text"
                      [(ngModel)]="model['accountName']"
                      (ngModelChange)="fieldChanged($event, 'error-accountName')"
                      name="accountName"
                      placeholder="Name"
                      [disabled]="lock == true"
                      maxlength="50"
                      (keypress)="azAzAlpha($event)"
                      style="text-transform: uppercase;"
                    />
                    <div
                      class="ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="
                        model['error-accountName'] != null &&
                        model['error-accountName'] != ''
                      "
                    >
                      {{ model["error-accountName"] }}
                    </div>
                  </div>
                </div>
                <div class="ten wide fields">
                  <div
                    class="required field"
                    style="width: 50%"
                    [class.error]="
                      model['error-department'] != null &&
                      model['error-department'] != ''
                    "
                  >
                    <label>Department</label>
                    <input
                      type="text"
                      [(ngModel)]="model['department']"
                      (ngModelChange)="fieldChanged($event, 'error-department')"
                      name="department"
                      placeholder="Department"
                      [disabled]="lock == true"
                      maxlength="50"
                      (keypress)="azAzAlphaWithException($event)"
                      style="text-transform: uppercase;"
                    />
                    <div
                      class="ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="
                        model['error-department'] != null &&
                        model['error-department'] != ''
                      "
                    >
                      {{ model["error-department"] }}
                    </div>
                  </div>
                </div>
                <div class="ten wide fields">
                  <div
                    class="required five field"
                    style="width: 50%"
                    [class.error]="
                      model['error-countryName'] != null &&
                      model['error-countryName'] != ''
                    "
                  >
                    <label>Country</label>
                    <combo-plugin
                      #cbCountry
                      [settings]="settingCountry"
                      [disableCombo]="lock"
                      (change)="changeEventCountry($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="
                        model['error-countryName'] != null &&
                        model['error-countryName'] != ''
                      "
                    >
                      {{ model["error-countryName"] }}
                    </div>
                  </div>
                </div>
                <div class="ten wide fields">
                  <div
                    class="required field"
                    style="width: 50%"
                    [class.error]="
                      model['error-designation'] != null &&
                      model['error-designation'] != ''
                    "
                  >
                    <label>Designation</label>
                    <input
                      type="text"
                      [(ngModel)]="model['designation']"
                      (ngModelChange)="fieldChanged($event, 'error-designation')"
                      name="designation"
                      placeholder="Designation"
                      [disabled]="lock == true"
                      maxlength="50"
                      (keypress)="azAzSpace($event)"
                      style="text-transform: uppercase;"
                    />
                    <div
                      class="ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="
                        model['error-designation'] != null &&
                        model['error-designation'] != ''
                      "
                    >
                      {{ model["error-designation"] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Account PIC
            </h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select PIC</a>

              <div class="one fields" style="margin-top: 10px">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">
                    Retrieve
                  </button>
                  <button class="ui button" (click)="generateQlecHistoryReport($event)">Export</button>
                  <!-- <button class="ui button" (click)="generateCustomerHistoryReport($event)">Export</button> -->
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>PIC Name</label>
                  <combo-plugin
                    #cbCustomerHistroy
                    [settings]="settingCustomerHistory"
                    (change)="changeEventCustomerHistory($event)"
                  ></combo-plugin>
                </div>
                <!--
                <div class="field">
                    <label>Customer Code</label>
                    <input type="text"  [value]="customerCodeHistory" name="customerCodeHistory"  placeholder="Customer History Code" disabled readonly style="background-color: lightgray">
                </div>
                -->
              </div>

              <div class="field">
                <grid-plugin
                  #gridHistory
                  [settings]="settingGridHistory"
                  (gridEvent)="gridEventHistory($event)"
                  (infoGrid)="infoGridHistory($event)"
                ></grid-plugin>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
