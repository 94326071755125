

export class Principal  {
  no : number = 0;
  principalId    : string = '';
  principalCode  : string = '';
  principalName  : string = '';
  remarks       : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  userDeleted : string = '';
  dateDeleted : string = '';
  isDeleted      : boolean = false;

  constructor () { }
}
