export class surchargeOOGDetails{
     surchargeId: string='';
     seqNo:number=1;
     pcTab:string='';
     m3: number=0;
    kt: number=0;
    rt: number=0;
    minCharge: number=0;
    oh: number=0;
    owl: number=0;
    owr: number=0;
    olf: number=0;
    olb: number=0;
    killslot: number=0;
    dooropen:string='';
    uch: number=0;
    ucl: number=0;
    ucw: number=0;
    userCreated:string='';
    dateCreated:string='';
    userModified:string='';
    dateModified:string='';
    containerType:string='';
    containerSize:string='';
    containerHeight:string='';
    constructor() { }
}
