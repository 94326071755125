<form class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <!--<Error Message Block-->
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-blLoadPortCode'] == null ||
            model['error-blLoadPortCode'] == ''
          "
        >
          {{ model["error-blLoadPortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-periodFrom'] == null || model['error-periodFrom'] == ''
          "
        >
          {{ model["error-periodFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-periodTo'] == null || model['error-periodTo'] == ''
          "
        >
          {{ model["error-periodTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-year'] == null || model['error-year'] == ''
          "
        >
          {{ model["error-year"] }}
        </li>
        <li
          [class.hidden]="
            model['error-weekName'] == null || model['error-weekName'] == ''
          "
        >
          {{ model["error-weekName"] }}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <fieldset style="border: 1px solid lightgray">
        <div class="field">
          <div class="two fields">
            <div class="field">
              <div
                class="required one field"
                [class.error]="
                  model['error-blLoadPortCode'] != null &&
                  model['error-blLoadPortCode'] != ''
                "
              >
                <label>Loading Port</label>
                <combo-plugin
                  #cbLoadPort
                  [settings]="settingLoadPort"
                  (change)="changeEventLoadPort($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Transhipment Port</label>
                <combo-plugin
                  #cbTransPort
                  [settings]="settingTransPort"
                  (change)="changeEventTransPort($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Final Destination</label>
                <combo-plugin
                  #cbFinalDest
                  [settings]="settingFinalDest"
                  (change)="changeEventFinalDest($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
            </div>
            <div class="field">
              <div class="field">
                <label>Discharge Port</label>
                <combo-plugin
                  #cbDischargePort
                  [settings]="settingDischargePort"
                  (change)="changeEventDischargePort($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Place Of Delivery</label>
                <combo-plugin
                  #cbPlaceDelivery
                  [settings]="settingPlaceDelivery"
                  (change)="changeEventPlaceDelivery($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Place of Receipt</label>
                <combo-plugin
                  #cbPlaceReceipt
                  [settings]="settingPlaceReceipt"
                  (change)="changeEventPlaceReceipt($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="field">
        <div class="four fields">
          <div class="field" style="margin-top: 40px">
            <fieldset style="border: 1px solid lightgray; height: 160px">
              <legend style="font-weight: bold; color: dodgerblue">
                Container Ownership
              </legend>
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isContOwn == 'ALL'"
                    value="ALL"
                    name="isContOwn"
                    (change)="changeContOwn($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>ALL</label>
                </div>
              </div>
              <br />
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isContOwn == 'SOC'"
                    value="SOC"
                    name="isContOwn"
                    (change)="changeContOwn($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>SOC</label>
                </div>
              </div>
              <br />
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isContOwn == 'COC'"
                    value="COC"
                    name="isContOwn"
                    (change)="changeContOwn($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>COC</label>
                </div>
              </div>
              <br />
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isContOwn == 'SLOT'"
                    value="SLOT"
                    name="isContOwn"
                    (change)="changeContOwn($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>SLOT</label>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field" style="margin-top: 40px">
            <fieldset style="border: 1px solid lightgray; height: 160px">
              <legend style="font-weight: bold; color: dodgerblue">Type</legend>
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isType == 'ALL'"
                    value="ALL"
                    name="isType"
                    (change)="changeType($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>ALL</label>
                </div>
              </div>
              <br />
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isType == 'FULL'"
                    value="FULL"
                    name="isType"
                    (change)="changeType($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>FULL</label>
                </div>
              </div>
              <br />
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    [checked]="isType == 'EMPTY'"
                    value="EMPTY"
                    name="isType"
                    (change)="changeType($event)"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>EMPTY</label>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field" style="margin-top: 30px">
            <div class="ui radio checkbox">
              <input
                type="radio"
                [checked]="isPrinted == 'byPeriod'"
                value="byPeriod"
                name="isPrinted"
                (change)="changePrinted($event)"
                tabindex="0"
                class="hidden"
              />
              <label>Print By Period</label>
            </div>
            <fieldset
              style="border: 1px solid lightgray; height: 150px"
              [disabled]="disabledPeriod == false"
            >
              <legend style="font-weight: bold; color: dodgerblue"></legend>
              <div
                class="required one field"
                [class.error]="
                  model['error-periodFrom'] != null &&
                  model['error-periodFrom'] != ''
                "
              >
                <label>Period From</label>
                <date-plugin
                  #datePeriodFrom
                  [settings]="settingDateFrom"
                  (change)="changePeriodFrom($event)"
                ></date-plugin>
              </div>

              <div
                class="required one field"
                [class.error]="
                  model['error-periodTo'] != null &&
                  model['error-periodTo'] != ''
                "
              >
                <label>Period To</label>
                <date-plugin
                  #datePeriodTo
                  [settings]="settingDateTo"
                  (change)="changePeriodTo($event)"
                ></date-plugin>
              </div>
            </fieldset>
          </div>

          <div class="field" style="margin-top: 30px">
            <div class="ui radio checkbox">
              <input
                type="radio"
                [checked]="isPrinted == 'byWeek'"
                value="byWeek"
                name="isPrinted"
                (change)="changePrinted($event)"
                tabindex="0"
                class="hidden"
              />
              <label>Print By Week</label>
            </div>
            <fieldset
              style="border: 1px solid lightgray; height: 150px"
              [disabled]="disabledByWeek == false"
            >
              <legend style="font-weight: bold; color: dodgerblue"></legend>
              <div
                class="required one field"
                [class.error]="
                  model['error-year'] != null && model['error-year'] != ''
                "
              >
                <label>Year</label>
                <input
                  type="text"
                  name="year"
                  style="text-transform: uppercase"
                  placeholder="Year"
                  (change)="changeYear($event)"
                />
              </div>

              <div
                class="required one field"
                [class.error]="
                  model['error-weekName'] != null &&
                  model['error-weekName'] != ''
                "
              >
                <label>Weeks</label>
                <combo-plugin
                  #cbWeeks
                  [settings]="settingWeeks"
                  (change)="changeWeeks($event)"
                  style="width: 100%"
                ></combo-plugin>
              </div>
            </fieldset>
          </div>
          <div
            class="ui inverted dimmer"
            [class.active]="loading == true"
            style="z-index: 1"
          >
            <div class="ui medium text loader">Loading</div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- end div container -->
</form>

<footer-plugin [info]="info"></footer-plugin>
