import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { blfreightmatch } from "../shared/models/blfreightmatch";
declare var $: any;

export class Helper {
  createBoundM: string = "N";
  constructor() {}
}

@Component({
  selector: "app-transaction-bl-freight-match-page",
  templateUrl: "./transaction-bl-freight-match-page.component.html",
  styleUrls: ["./transaction-bl-freight-match-page.component.css"],
})
export class TransactionBLFreightMatchPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  get gridStore() {
    return this.gridStore;
  }
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridvessel") gridvessel: GridPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeFormNett = false;
  loading = false;
  prevSelectedRecord: number = 0;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGrid;
  settingGridVessel;
  isLoad: boolean = false;
  transferFromLock: boolean = true;
  retLock: boolean = false;
  lockBound: boolean = false;

  lockgrid: boolean = false;
  lockvessel: boolean = false;
  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = true;
  dButtonLock = true;
  model = new blfreightmatch();
  isVessel: boolean = false;
  isNo: boolean = false;
  isAll: boolean = false;
  isCOC: boolean = false;
  isSOC: boolean = false;
  helper = new Helper();

  searchVesselId = "";
  searchVoyage = "";
  searchBound = "";

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  validatorRules = {
    vesselName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Vessel.",
        },
      ],
    },
    bOceanVesselVoyage: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Voyage.",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Ok", event: "retrieve", icon: "checkmark" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Freight Match",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGrid = {
      url: "", //al',
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 250, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingGridVessel = {
      url: "", //al',
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 250, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.transferFromLock = true;
    this.isVessel = true;
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case "print":
        this.printEvent();
        break;
    }
  }
  eventMessage(event) {}

  handleCancel() {
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.model.bound = "";
    this.isVessel = false;
    this.isNo = false;
  }

  handleRetrieve() {
    // console.log('retrieve event');
    // console.log(this.model);
    let isPassedValidation: boolean = this.onValidate(this.model);
    if (!isPassedValidation) {
      if (this.isVessel == true) {
        var URL_Retrieve =
          "http://localhost:16000/BLFreight/GetFreightVessel/" +
          this.model.vesselId +
          "/" +
          this.model.bOceanVesselVoyage +
          "/" +
          this.model.bound +
          "/" +
          "1/10/";

        this.genericService.GET(URL_Retrieve).subscribe((resp) => {
          let i;
          for (i = 0; i < resp.ok; i++) {
            this.model.blNo = resp.json()["content"][0]["blNo"];
            this.model.blStatus = resp.json()["content"][0]["blStatus"];
            this.model.blIsFinished = resp.json()["content"][0]["blIsFinished"];
            this.model.blLoadPortCode =
              resp.json()["content"][0]["blLoadPortCode"];
            this.model.blDischargePortCode =
              resp.json()["content"][0]["blDischargePortCode"];
            this.model.blPortOfTransshipment1code =
              resp.json()["content"][0]["blPortOfTransshipment1code"];
            this.model.blShipperName =
              resp.json()["content"][0]["blShipperName"];
            this.model.blConsigneeName =
              resp.json()["content"][0]["blConsigneeName"];
            this.model.blNotifyPartyName =
              resp.json()["content"][0]["blNotifyPartyName"];
            this.model.blIsLocked = resp.json()["content"][0]["blIsLocked"];
            this.model.blUserCreated =
              resp.json()["content"][0]["blUserCreated"];
            this.model.blDateCreated =
              resp.json()["content"][0]["blDateCreated"];
            this.model.blUserModified =
              resp.json()["content"][0]["blUserModified"];
            this.model.blDateModified =
              resp.json()["content"][0]["blDateModified"];
            //  this.model.TK45=resp.json()['content'][0]['TK45'];

            // console.log(this.model);
          }

          //this.modeFormNett=true;
        });
        $("#printSailingSchedule")
          .modal("setting", "closable", false)
          .modal("show");
        localStorage.removeItem("createBoundM");
        this.helper.createBoundM = "N";

        let isPassedValidation: boolean = this.onValidate(this.model);
        if (!isPassedValidation) {
          this.prevSelectedRecord = 0;

          //VS00287/TESTE1/I/CCU;

          this.gridvessel.url = URL_Retrieve;
          this.gridvessel.loadData();
          this.gridvessel.onClear();
          this.gridvessel.clearSelectedValues();
          this.gridvessel.onClear();
          this.fillGrid();

          //set the toolbar to activate export button
          this.disableToolbarButtons = "delete,allotment";
          //localStorage.removeItem("createBoundM");
        }
      } else {
      }
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  saveEvent() {
    // console.log("tes save event")
    // this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
  }
  changeEventVessel(event) {
    this.model["error-vesselName"] = "";

    //this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
    if (event.vesselId != null) {
      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/" +
          this.model.bound.toUpperCase() +
          "/{query}/SGSIN"
      );
      this.cbVoyage.setValue("");
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      if (event.capacity != null) {
        // this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
    }
  }

  changeEventVoyage(event) {
    this.model["error-bOceanVesselVoyage"] = "";

    if (event.voyage != null) {
      this.model.bOceanVesselVoyage = event.voyage;
      this.model.bound = event.bound;
    } else {
      this.model.bOceanVesselVoyage = "";
    }
  }

  changeEventisVessel(event) {
    if (event.target.checked) {
      this.isVessel = true;
      this.grid.disable();
      this.grid.onClear();
      this.transferFromLock = false;
      this.isAll = true;
      this.retLock = false;
    } else {
      this.isVessel = false;
    }
  }

  fillGrid() {
    this.isLoad = true;
    this.gridvessel.loadData();
  }

  changeEventIsNo(event) {
    if (event.target.checked) {
      this.isNo = true;
      this.grid.lock = false;
      this.model = new blfreightmatch();
      let data = this.model;
      data.blNo = String(this.model.blNo);
      this.grid.listStore.addData(data);
      this.grid.loadData();
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.model.bound = "";
      this.transferFromLock = true;
      this.isAll = false;
      this.retLock = true;
      // console.log("test");
    } else {
      this.isNo = false;
    }
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        } else {
          this.disableToolbarButtons = "update,delete";
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        } else {
          this.disableToolbarButtons = "update,delete";
        }

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleRetrieve();
              break;
            case "dblClick":
              this.disableToolbarButtons = "";
              //this.invisibleToolbarButtons = "create,history,print";

              //this.modeForm = true;
              this.modeToolbar = false;

              let dt = this.grid.getSelectedValues()[0];
              let custId: string = String(dt.customerId).trim();

              //this.onRetrieve(custId);

              // this.retLock = true;
              this.grid.disable();
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridEventVessel(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.gridvessel.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        } else {
          this.disableToolbarButtons = "update,delete";
        }
        break;

      case "click":
        if (this.gridvessel.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "";
        } else {
          this.disableToolbarButtons = "update,delete";
        }

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleRetrieve();
              break;
            case "dblClick":
              this.disableToolbarButtons = "";
              //this.invisibleToolbarButtons = "create,history,print";

              //this.modeForm = true;
              this.modeToolbar = false;

              let dt = this.gridvessel.getSelectedValues()[0];
              let custId: string = String(dt.customerId).trim();

              //this.onRetrieve(custId);

              // this.retLock = true;
              this.gridvessel.disable();
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridVessel(event) {
    this.info = event;
  }

  printEvent() {
    /*
     this.loading = true;
     this.message('warning','SSL Sailing Schedule','Print function not implemented yet!','okonly',{ok: 'this.loading = false;' });
     */

    //open modal for printing
    $("#printSailingSchedule")
      .modal("setting", "closable", false)
      .modal("show");
  }

  hidePrintSailingSchedule() {
    //close current verificaiton window
    $("#printSailingSchedule")
      .modal("setting", "closable", false)
      .modal("hide");
  }

  printSailingSchedule() {}

  updateVoyageUrl(x: any) {}
}
