<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
        <div id='container'>

          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form">

            <div class="ui raised segment">
              <div class="one field" style="width:100%">
                  <div class="ten wide fields">
                      <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-placeOfCreation'] != null && model['error-placeOfCreation'] != ''">
                          <label>Place Of Creation:</label>
                          <combo-plugin #cbPOC [settings]="settingPOC" [disableCombo]="lock" (change)="changeEventPOC($event)" style="width:100%;"></combo-plugin>
                          <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="model['error-placeOfCreation'] != null && model['error-placeOfCreation'] != ''">
                            {{model['error-placeOfCreation']}}
                          </div>
                      </div>
                  </div>
                  <div class="ten wide fields">                                            
                      <div class="required field" style="width:50%;" [class.error]="model['error-fromDate'] != null && model['error-fromDate'] != ''">
                        <label>Vessel ETA FROM : </label>
                      <div class="ui left icon input">
                        <input id="fromDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date">
                        <i class="calendar icon"></i>
                      </div>                          
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="model['error-fromDate'] != null && model['error-fromDate'] != ''">
                              {{model['error-fromDate']}}
                          </div> 
                      </div>
                  </div>
                  <div class="ten wide fields">
                    <div class="required five field" style="width:50%" [class.error]="model['error-toDate'] != null && model['error-toDate'] != ''">
                      <label>Vessel ETA TO : </label>
                      <div class="ui left icon input">
                        <input id="toDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date">
                        <i class="calendar icon"></i>
                      </div>                    
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="model['error-toDate'] != null && model['error-toDate'] != ''">
                        {{model['error-toDate']}}
                      </div>
                    </div>                  
                  </div>
                      <div class="field">
                          <label>Container Ownership :</label>
                          <div class="ui radio checkbox" >
                            <input type="radio" id="containerOwner" name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'" (change)="changeEventTransmit($event)" value="COC">
                            <label>COC</label>
                          </div>
            
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <div class="ui radio checkbox" >
                            <input type="radio" id="containerOwner" name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'" (change)="changeEventTransmit($event)" value="SOC">
                            <label>SOC</label>
                          </div>
            
            
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;            
                        <div class="ui radio checkbox" >
                            <input type="radio" id="containerOwner" name="containerOwner" [checked]="radioContainerOwnerSLOT == 'Y'" (change)="changeEventTransmit($event)" value="SLOT">
                            <label>SLOT</label>
                        </div>
            
                        <div class="field"  style="width:100%; margin-top:10px;">
                          
                                                <div class="ui  checkbox" >
                                                  <input type="checkbox"  id="ckCOCLOCAL" name="ckCOCLOCAL" value="COCLOCAL" [checked]="ckCOCLOCAL == true" (change)="changeEventCOCLOCAL($event)">
                                                  <label>COC LOCAL</label>
                                              </div>
                                              &nbsp;&nbsp;
                                              <div class="ui  checkbox" >
                                                  <input type="checkbox"  id="ckCOCTS" name="ckCOCTS" value="COCTS" [checked]="ckCOCTS == true" (change)="changeEventCOCTS($event)">
                                                  <label>COC T/S</label>
                                              </div>
            
                                              &nbsp;&nbsp;
            
                                              <div class="ui  checkbox" >
                                                  <input type="checkbox"  id="ckSOCLOCAL" name="ckSOCLOCAL" value="SOCLOCAL" [checked]="ckSOCLOCAL == true" (change)="changeEventSOCLOCAL($event)">
                                                  <label>SOC LOCAL</label>
                                              </div>
                                              &nbsp;&nbsp;
                                              <div class="ui  checkbox" >
                                                  <input type="checkbox"  id="ckSOCTS" name="ckSOCTS" value="SOCTS" [checked]="ckSOCTS == true" (change)="changeEventSOCTS($event)">
                                                  <label>SOC T/S</label>
                                              </div>
                        </div>
                      </div>
              </div>
              <div class="field" style="width:400px">
                  <label>Report Type :</label>
                      <div class="ui radio checkbox">
                        <input id="rType" type="radio"  name="rType" value="pdf" (change)="eventRadio($event,'PDF')" checked>
                        <label>PDF</label>
                      </div>
          
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <div class="ui radio checkbox">
                        <input id="rType" type="radio"  name="rType" value="xlx" (change)="eventRadio($event,'XLS')">
                        <label>Excel</label>
                      </div>
                </div>
            
            </div>
          </form>
          <!-- End of Form -->
        </div>
  </div>
</div>

<pdf-plugin #dailyBookingProcessPDFContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin>

