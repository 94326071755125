import { Component, OnInit } from '@angular/core';
declare  var $:any;

@Component({
  selector: 'app-blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.css']
})
export class BlankPageComponent implements OnInit {


  info = '';
  
  ngOnInit(){
  }
 
}
