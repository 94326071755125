export class MasterVesselSchedule {
  vesselScheduleId: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  vesselName: string = "";
  baseVoyage: string = "";
  psaVesselCode: string = "";
  docSysCode: string = "";
  vesselIsSsl: boolean = false;
  vesselIsSlot: boolean = false;
  vesselIs3rdParty: boolean = false;
  isValid: boolean = true;
  serviceId: string = "";
  serviceCode: string = "";
  serviceSequence: string = "";
  serviceIsSsl: boolean = false;
  serviceIsSlot: boolean = false;
  serviceIs3rdParty: boolean = false;
  remarks: string = "";
  servicePreviousVoyage: string = "";
  serviceNextVoyage: string = "";
  psaInVoyage: string = "";
  psaOutVoyage: string = "";
  previousVoyage: string = "";
  nextVoyage: string = "";
  createdBy: string = "";
  createdDate: string = "";
  lastModifiedBy: string = "";
  lastModifiedDate: string = "";
  invalidateBy: string = "";
  invalidateDate: string = "";
  vesselSlotOwner1: string = "";
  vesselSlotOwner2: string = "";
  vesselSlotOwner3: string = "";
  baseEta: string = "";
  baseEtd: string = "";
  sslOperated: boolean = false;
  transmitToPsa: boolean = false;
  dateToPsa: string = "";
  transmitToAgent: boolean = false;
  recordStatus: string = "";
  groupVoyage: string = "";
  noCallFromPrePort: string = "";
  weekName: string = "";
  year: string = "";
  //gsm45
  tempServicePrevVoyage : string = "";

  MasterVesselScheduleDetails: any = [];
  MasterVesselScheduleDetail: any = [];

  doNotCalculate: boolean = true;
  gmtData: any =
    "GMT" + new Date().toString().split("GMT")[1].split("(")[0].trim();

  oldMasterVesselScheduleDetails: any = [];

  bookAffected: number = 0;
  bookAffectedDetails: any = [];

  sBound: string = ''

  constructor() {}
}
