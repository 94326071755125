export class qlec {
    
        no : number = 0;
        qlecId  : string = '';
        length  : string = '';
        comparison : string = '';
        weight : string = '';
        status : string = '';
        type : string = '';
        isValid        : boolean = false;
        userCreated : string = '';
        dateCreated : string = '';
        userModified : string = '';
        dateModified : string = '';
        userInvalid : string = '';
        dateInvalid : string = '';
    
    
        constructor(){
    
        }
    }