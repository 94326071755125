<div class="ui inverted dimmer" [class.active]="loading" style="z-index:12;">
  <div class="ui medium text loader" style="top: 30%;">Loading</div>
</div>
<form class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <dialog-plugin
    #dialogPlugin
    [settings]="dialog"
    (eventDialog)="eventMessage($event)"
  ></dialog-plugin>

  <!-- Start drawing UI from here -->
  <form class="ui form">
    <div class="field">
      <!-- draw option radio (1st row) -->
      <div class="fields">
        <div class="twelve wide field">
          <fieldset style="border: 1px solid lightgray; height: 50px">
            <legend style="font-weight: bold; color: dodgerblue">
              View Document Type
            </legend>
            <div class="inline fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="rdCdDocumentTypeInvoice"
                    [checked]="model['cdDocumentType'] == 'invoice'"
                    (change)="changeEventRdOptInvoice($event)"
                  />
                  <label>Invoice</label>
                </div>
              </div>

              <div class="field">
                <div class="ui radio checkbox disabled">
                  <input
                    type="radio"
                    name="rdCdDocumentTypeDN"
                    disabled
                    [checked]="model['cdDocumentType'] == 'dn'"
                    (change)="changeEventRdOptDebitNote($event)"
                  />
                  <label>Debit Note</label>
                </div>
              </div>

              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="rdCdDocumentTypeCN"
                    [checked]="model['cdDocumentType'] == 'cn'"
                    (change)="changeEventRdOptCreditNote($event)"
                  />
                  <label>Credit Note</label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="four wide field">
          <fieldset style="border: 1px solid lightgray; height: 50px">
            <legend style="font-weight: bold; color: dodgerblue">
              Print option
            </legend>
            <div class="inline fields">
              <div class="field">
                <div class="ui checkbox" [ngClass]="{'radio disabled': isDisabelsilentMode}">
                  <input type="checkbox" name="silentMode" tabindex="0" [readonly]="isDisabelsilentMode" [formControl]="silentMode">
                  <label>Silent Mode</label>
                </div>
              </div>
            </div>
          </fieldset>

          <div id="pdfContainerNewInvoice" style="display:none;">
            <div class="ui form">
              <div class="field" id="pdfContainerNewInvoice-label">
                <label>&nbsp;please click on the Invoice No. to view.</label>
              </div>
              <div class="two fields">
                <div id='pdfContainerNewInvoice-grid-container' class="field" style="width:30%;">
                  <grid-plugin #gridPDFPrev [settings]="settingGridPDFPrev" (gridEvent)="gridEventPDFPrev($event)"
                    (infoGrid)="infoGrid($event)"></grid-plugin>
                </div>
                <div id='pdfContainerNewInvoice-viewer-container' class="field" style="width:100%;height:570px;">
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="four wide field">
          <fieldset style="border: 1px solid lightgray; height: 50px">
            <legend style="font-weight: bold; color: dodgerblue">
              Container Ownership
            </legend>
            <div class="inline fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="coc"
                    id="coc"
                    [checked]="rdCoc == 'Y'"
                    (change)="changeEventCoc($event)"
                    value="coc"
                    disabled
                  />
                  <label>COC</label>
                </div>
              </div>

              <div class="field">
                <div class="ui radio checkbox">
                  <input
                    type="radio"
                    name="soc"
                    id="soc"
                    [checked]="rdSoc == 'Y'"
                    (change)="changeEventSoc($event)"
                    value="soc"
                    disabled
                  />
                  <label>SOC</label>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <!-- End  draw option radio (1st row) -->

    <!-- INVOICE BLOCK -->
    <div [style.display] = "model['cdDocumentType'] == 'invoice' ? 'block' : 'none'" >
      <div class="field" > <!-- Invoice BL Number -->
        <div class="fields">
          <div class="six wide field">
            <div class="field">
              <label>BL Number</label>
              <input
                maxlength="17"
                type="text"
                name="blNumber"
                placeholder="BL Number"
                readonly = "true"
                style="background-color: lightgray"
                [(ngModel)]="model['cdBlNumber']"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field" > <!-- Invoice 1st Row -->
        <div class="fields">
          <div class="six wide field">
            <div
              class="required field"
              [class.error]="
              model['error-invoiceNumber'] != null &&
              model['error-invoiceNumber'] != '' &&
              model['error-invoiceNumber'] != undefined
            "
            >
              <label>Invoice Number</label>
              <input
                maxlength="20"
                type="text"
                name="invoiceNumber"
                placeholder="Invoice Number"
                [(ngModel)]="model['cdInvoiceNumber']"
                onkeydown="return (event.keyCode!=13);"
                (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                (ngModelChange)="onPasteValidation(
                  model.cdInvoiceNumber,
                  'cdInvoiceNumber',
                  formValidation.regAlphaNumeric,
                  false, '', 'cdInvoiceNumber')"
              />
              <div class = "ui basic red pointing prompt label transition" *ngIf="model['error-cdInvoiceNumber'] != null && model['error-cdInvoiceNumber'] != ''">
                {{model['error-cdInvoiceNumber']}}
              </div>
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Printed Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePrintedDate"
                [(ngModel)]="model['cdInvoicePrintedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Posted Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePostedDate"
                [(ngModel)]="model['cdInvoicePostedToCodaDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF INVOICE BLOCK --->


    <!-- DN BLOCK -->
    <div [style.display] = "model['cdDocumentType'] == 'dn' ? 'block' : 'none'" >
      <div class="field" > <!-- DN 1st Row -->
        <div class="fields">
          <div class="six wide field">
            <div
              class="required field"
              [class.error]="
              model['error-cdDnNumber'] != null &&
              model['error-cdDnNumber'] != '' &&
              model['error-cdDnNumber'] != undefined
            "
            >
              <label>Debit Note Number</label>
              <input
                maxlength="20"
                type="text"
                name="debitNoteNumber"
                placeholder="Debit Note Number"
                [(ngModel)]="model['cdDnNumber']"
                onkeydown="return (event.keyCode!=13);"
                (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                (ngModelChange)="onPasteValidation(
                  model.cdDnNumber,
                  'cdDnNumber',
                  formValidation.regAlphaNumeric,
                  false, '', 'cdDnNumber')"
              />
              <div class="ui basic red pointing prompt label transition"
                *ngIf="model['error-cdDnNumber'] != null && model['error-cdDnNumber'] != ''">
                {{model['error-cdDnNumber']}}
              </div>
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Debit Note Created By</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="dnCreatedBy"
                [(ngModel)]="model['cdDnCreatedBy']"
                style="background-color: lightgray"
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Debit Note Created Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="dnCreatedDate"
                [(ngModel)]="model['cdDnCreatedDate']"
                style="background-color: lightgray"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field"> <!-- DN 2nd Row -->
        <div class="fields">
          <div class="six wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Debit Note Printed Date</label>
              <input
                maxlength="10"
                type="text"
                readonly="true"
                name="dnPrintedDate"
                [(ngModel)]="model['cdDnPrintedDate']"
                style="background-color: lightgray"
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Debit Note Posted Date</label>
              <input
                maxlength="10"
                type="text"
                readonly="true"
                name="dnPostedDate"
                [(ngModel)]="model['cdDnPostedDate']"
                style="background-color: lightgray"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field"> <!-- DN 3rd Row -->
        <div class="fields">
          <div class="six wide field">
            <div class="field">
              <label>BL Number</label>
              <input
                maxlength="17"
                type="text"
                name="blNumber"
                placeholder="BL Number"
                readonly = "true"
                style="background-color: lightgray"
                [(ngModel)]="model['cdBlNumber']"
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
        </div>
      </div>

      <div class="field" > <!-- DN 4th Row -->
        <div class="fields">
          <div class="six wide field">
            <div
              class="required field"
              [class.error]="
              model['error-invoiceNumber'] != null &&
              model['error-invoiceNumber'] != '' &&
              model['error-invoiceNumber'] != undefined
            "
            >
              <label>Invoice Number</label>
              <input
                maxlength="20"
                type="text"
                name="invoiceNumber"
                placeholder="Invoice Number"
                readonly="true"
                style="background-color: lightgray"
                [(ngModel)]="model['cdPrevInvoiceNumber']"
                onkeydown="return (event.keyCode!=13);"
                (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                (ngModelChange)="onPasteValidation(
                  model.cdInvoiceNumber,
                  'cdInvoiceNumber',
                  formValidation.regAlphaNumeric,
                  false, '', 'cdInvoiceNumber')"
              />
              <div class = "ui basic red pointing prompt label transition" *ngIf="model['error-cdInvoiceNumber'] != null && model['error-cdInvoiceNumber'] != ''">
                {{model['error-cdInvoiceNumber']}}
              </div>
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Printed Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePrintedDate"
                [(ngModel)]="model['cdPrevInvoicePrintedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Posted Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePostedDate"
                [(ngModel)]="model['cdPrevInvoicePostedToCodaDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF DN BLOCK -->

    <!-- CN BLOCK -->
    <div [style.display] = "model['cdDocumentType'] == 'cn' ? 'block' : 'none'" >
      <div class="field" > <!-- CN 1st Row -->
        <div class="fields">
          <div class="six wide required field"
               [class.error]="
              model['error-cdCnNumber'] != null &&
              model['error-cdCnNumber'] != '' &&
              model['error-cdCnNumber'] != undefined
            "
          >

              <label>Credit Note Number</label>
              <input
                maxlength="17"
                type="text"
                name="creditNoteNumber"
                placeholder="Credit Note Number"
                onkeydown="return (event.keyCode!=13);"
                [(ngModel)]="model['cdCnNumber']"
                (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                (ngModelChange)="onPasteValidation(
                  model.cdCnNumber,
                  'cdCnNumber',
                  formValidation.regAlphaNumeric,
                  false, '', 'cdCnNumber')"
              />
            <div class = "ui basic red pointing prompt label transition" *ngIf="model['error-cdCnNumber'] != null && model['error-cdCnNumber'] != ''">
              {{model['error-cdCnNumber']}}
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Credit Note Created By</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="cnCreatedBy"
                [(ngModel)]="model['cdCnCreatedBy']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Credit Note Created Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="cnCreatedDate"
                [(ngModel)]="model['cdCnCreatedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field"> <!-- CN 2nd Row -->
        <div class="fields">
          <div class="six wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Credit Note Printed Date</label>
              <input
                maxlength="10"
                type="text"
                readonly="true"
                name="cnPrintedDate"
                [(ngModel)]="model['cdCnPrintedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Credit Note Posted Date</label>
              <input
                maxlength="10"
                type="text"
                readonly="true"
                name="cnPostedDate"
                [(ngModel)]="model['cdCnPostedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field"> <!-- CN 3rd Row -->
        <div class="fields">
          <div class="six wide field">
            <div class="field">
              <label>BL Number</label>
              <input
                maxlength="17"
                type="text"
                name="blNumber"
                placeholder="BL Number"
                readonly = "true"
                style="background-color: lightgray"
                [(ngModel)]="model['cdBlNumber']"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <!-- Blank Space -->
            </div>
          </div>
        </div>
      </div>
      <div class="field" > <!-- DN 4th Row -->
        <div class="fields">
          <div class="six wide field">
              <label>Invoice Number</label>
              <input
                maxlength="20"
                type="text"
                name="invoiceNumber"
                placeholder="Invoice Number"
                readonly="true"
                style="background-color: lightgray"
                [(ngModel)]="model['cdInvoiceNumber']"
                onkeydown="return (event.keyCode!=13);"
                (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                (ngModelChange)="onPasteValidation(
                  model.cdInvoiceNumber,
                  'cdInvoiceNumber',
                  formValidation.regAlphaNumeric,
                  false, '', 'cdInvoiceNumber')"
              />
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Printed Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePrintedDate"
                [(ngModel)]="model['cdPrevInvoicePrintedDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
          <div class="five wide field">
            <div class="field">
              <label>Invoice Posted Date</label>
              <input
                maxlength="20"
                type="text"
                readonly="true"
                name="invoicePostedDate"
                [(ngModel)]="model['cdPrevInvoicePostedToCodaDate']"
                style="background-color: lightgray"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF CN BLOCK -->

    <div class="field">
      <div class="fields">
        <div class="six wide field">
          <div class="field">
            <label>Payer Name</label>
            <input
              maxlength="100"
              type="text"
              readonly="true"
              [(ngModel)]="model['cdPayerName']"
              name="payerName"
              style="background-color: lightgray"
              disabled
            />
          </div>
        </div>
        <div class="five wide field">
          <div class="field">
            <label>Payer A/C Code</label>
            <input
              maxlength="10"
              type="text"
              readonly="true"
              [(ngModel)]="model['cdPayerAcCode']"
              name="payerCode"
              style="background-color: lightgray"
              disabled
            />
          </div>
        </div>
        <div class="two wide field">
          <div class="field">
            <label>Exchange Rate</label>
            <input
              maxlength="18,4"
              (keypress)="formValidation.NumWDot($event)"
              name="invoiceExchangeRate"
              [(ngModel)]="model['cdExchangeRate']"
              placeholder=""
              (ngModelChange)="exchangeRateChange($event)"
            />
            <div class = "ui basic red pointing prompt label transition" *ngIf="messageErrorExchangeRate">
              {{messageErrorExchangeRate}}
          </div>
          </div>
        </div>
        <div class="three wide field">
          <div class="ui checkbox" style="margin-top: 30px">
            <input
              type="checkbox"
              name="chkApplyInvoiceExchangeRate"
              [checked]="model['cdInvoiceIsApplyThisExchangeRate'] == 'Y'"
              (change)="changeEventApplyInvoiceExchangeRate($event)"
              value=""
              onkeydown="return (event.keyCode!=13);"
            />
            <label>Apply this Exchange Rate</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Start draw 4th row -->
    <div class="field">
      <div class="fields">
        <div class="eight wide field">
          <fieldset style="border: 1px solid lightgray">
            <legend style="font-weight: bold; color: dodgerblue">
              Invoice Details
            </legend>
            <div class="fields">
              <div class="eight wide field">
                <div class="field">
                  <label>Vessel A/C Code</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="txtVesselAcCode"
                    readonly="true"
                    [(ngModel)]="model['cdVesselAcCode']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
                <div class="field">
                  <label>Vessel Code</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="txtVesselCode"
                    readonly="true"
                    [(ngModel)]="model['cdVesselCode']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
                <div class="field">
                  <label>Port of Loading</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="cdPortOfLoading"
                    readonly="true"
                    [(ngModel)]="model['cdPortOfLoading']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
              </div>
              <div class="eight wide field">
                <div class="field">
                  <label>Voyage</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="cdVoyage"
                    readonly="true"
                    [(ngModel)]="model['cdVesselVoyage']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
                <div class="field">
                  <label>Vessel Name</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="txtVesselName"
                    readonly="true"
                    [(ngModel)]="model['cdVesselName']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
                <div class="field">
                  <label>Port of Discharge</label>
                  <input
                    maxlength="10"
                    type="text"
                    name="txtPortOfDischarge"
                    readonly="true"
                    [(ngModel)]="model['cdPortOfDischarge']"
                    style="background-color: lightgray"
                    disabled
                  />
                </div>
              </div>
            </div>
          </fieldset>
        </div>


        <div class="eight wide field">
          <fieldset style="border: 1px solid lightgray">
            <legend style="font-weight: bold; color: dodgerblue">
              Invoice Details
            </legend>
            <div
              id="oldInvoiceTab"
              class="ui top attached tabular menu"
              style="background-color: beige"
            >
              <a
                class="item active"
                data-tab="oldInvoicePrepaid"
                (click)="onOldInvoicePrepaid()"
              >Prepaid</a>
              <a
                class="item"
                data-tab="oldInvoicePayable"
                (click)="onOldInvoicePayable()"
              >Payable</a>
            </div>

            <div
              class="ui bottom attached tab segment active"
              id="oldInvoicePrepaid"
              data-tab="oldInvoicePrepaid"
            >
              <div class="field">
                <div class="fields">
                  <div class="five wide field">
                    <label>[1]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="txtPrepaid1Name"
                      readonly="true"
                      [(ngModel)]="model['cdPrepaid1Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                  <div class="five wide field">
                    <label>[2]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="txtPrepaid2Name"
                      readonly="true"
                      [(ngModel)]="model['cdPrepaid2Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                  <div class="five wide field">
                    <label>[3]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="txtPrepaid3Name"
                      readonly="true"
                      [(ngModel)]="model['cdPrepaid3Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="ui bottom attached tab segment"
              id="oldInvoicePayable"
              data-tab="oldInvoicePayable"
            >
              <div class="field">
                <div class="fields">
                  <div class="five wide field">
                    <label>[1]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="cdPayable1Name"
                      readonly="true"
                      [(ngModel)]="model['cdPayable1Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                  <div class="five wide field">
                    <label>[2]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="cdPayable2Name"
                      readonly="true"
                      [(ngModel)]="model['cdPayable2Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                  <div class="five wide field">
                    <label>[3]</label>
                    <input
                      maxlength="10"
                      type="text"
                      name="cdPayable3Name"
                      readonly="true"
                      [(ngModel)]="model['cdPayable3Name']"
                      style="background-color: lightgray"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>


      </div>
    </div>
    <!-- End of drawing 4th row -->

    <!-- 5th row is blank -->

    <div class="field">
      <!-- Start draw 6th row -->
      <div class="fields">
        <div class="eight wide field">
          Freight Details: Current Total Charge Amount: {{currentTotalChargeAmount}}
        </div>
        <div class="eight wide field">
          Legend: N[New], R[Removed], C[Credit], D[Debit]
        </div>
      </div>
    </div>
    <!--End of drawing 6th row -->

    <div class="field">
      <!-- Start draw 7th row -->
      <!-- <grid-plugin #gridCNDN [settings]="settingGridCNDN"  (infoGrid)="eventInfoGrid($event)" (gridEvent)="eventGridCNDN($event)"  > </grid-plugin> -->
      <!--
      <ag-grid-angular
        #gridCNDN
        style="width: 100%; height: 400px;"
        [getRowStyle]="getRowStyle"
        class="ag-theme-balham"
        id="gridCNDN"
        [enableSorting]="true"
        [enableFilter]="true"
        [enableColResize] = "true"
        [rowData]="rowDataCNDN"
        [rowSelection]="rowSelection"
        [rowMultiSelectWithClick]="true"
        [columnDefs]="columnDefsCNDN"
        [pinnedBottomRowData]="pinnedBottomRowDataCNDN"
        [animateRows]="true"
        [enableCellChangeFlash]="true"
        (rowDoubleClicked)="rowDoubleClick($event)"
        (gridReady)="onGridReadyCNDN($event)"
        (selectionChanged)="onSelectionChanged($event)"
        >
      </ag-grid-angular>
    -->

      <ag-grid-angular
        style="width: 100%; height: 400px"
        [getRowStyle]="getRowStyle"
        class="ag-theme-balham"
        [rowData]="rowDataCNDN"
        [columnDefs]="columnDefsCNDN"
        [pinnedBottomRowData]="pinnedBottomRowDataCNDN"
        (gridReady)="onGridReadyCNDN($event)"
        [components]="components"
        [suppressRowTransform]="true"
        [enableFilter]="true"
        [enableColResize]="true"
      >
      </ag-grid-angular>
    </div>
    <!--End of drawing 7th row -->

    <div class="field">
      <!-- Start draw 8th row -->
      <div class="fields">
        <fieldset class="eight wide field" [disabled]="isDisabelByCreditNote">
          <label>Reason (Finance)</label>
          <combo-plugin
            #cbReasonCNDN
            [settings]="settingCbReasonCNDN"
            (change)="changeEventCbReasonCNDN($event)"
          ></combo-plugin>
        </fieldset>

        <div class="eight wide field">
          <label>Reason Description</label>
          <textarea
            name="txtReasonDescCNDN"
            placeholder=""
            style="text-transform: uppercase; background-color: lightgray"
            rows="5"
            [readOnly]="true"
            [(ngModel)]="reasonDescription"
            disabled
          ></textarea>
        </div>
      </div>
    </div>
    <!--End of drawing 8th row -->

    <fieldset class="field" [disabled]="isDisabelByCreditNote">
      <!-- Start draw 9th row -->
      <div class="fields">
        <div class="eight wide field">
          <fieldset style="border: 1px solid lightgray">
            <legend style="font-weight: bold; color: dodgerblue">
              Types of Modification
            </legend>

            <div class="field">
              <div class="ui radio checkbox">
                <input
                  type="radio"
                  name="rdIsFullCancellation"
                  value="cdIsFullCancellationOfInvoice"
                  [checked]="model['cdIsFullCancellationOfInvoice'] == 'Y'"
                  (change)="changeEventRdOptCNDN1($event)"
                  [formControl]="typeModification"
                  onkeydown="return (event.keyCode!=13);"
                />
                <label>Full Cancellation of Invoice</label>
              </div>
            </div>

            <div class="field">
              <div class="ui radio checkbox">
                <input
                  type="radio"
                  name="rdIsFullCancellation"
                  [checked]="model['cdIsFullCancellationOfInvoiceAndReissueNewInvoice'] == 'Y'"
                  value="cdIsFullCancellationOfInvoiceAndReissueNewInvoice"
                  (change)="changeEventRdOptCNDN2($event)"
                  [formControl]="typeModification"
                  onkeydown="return (event.keyCode!=13);"
                />
                <label
                  >Full Cancellation of Invoice + Re-Issue of a new
                  Invoice</label
                >
              </div>
            </div>

            <div class="field">
              <div class="ui radio checkbox disabled">
                <input
                  type="radio"
                  name="rdIsFullCancellation"
                  [checked]="model['cdIsPartialCancellation'] == 'Y'"
                  (change)="changeEventRdOptCNDN3($event)"
                  [formControl]="typeModification"
                  disabled
                  value="cdIsPartialCancellation"
                />
                <label>Partial Cancellation</label>
              </div>
            </div>

            <div class="field">
              <div class="ui radio checkbox disabled">
                <input
                  type="radio"
                  name="rdIsFullCancellation"
                  [checked]="model['cdIsDebitNote'] == 'Y'"
                  (change)="changeEventRdOptCNDN3($event)"
                  [formControl]="typeModification"
                  disabled
                  value="cdIsDebitNote"
                />
                <label>Debit Note</label>
              </div>
            </div>

            <div class="field mt1">
              <div class="ui checkbox disabled">
                <input
                  type="checkbox"
                  name="isCancelExistingAutoPosting"
                  [checked]="model['cdCancelExistingAutoPosting'] == 'Y'"
                  (change)="changeEventApplyInvoiceExchangeRate($event)"
                  disabled
                />
                <label>Cancel Existing Auto Posting</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="eight wide field">
          <grid-plugin
            id="gridCNDNFooter"
            #gridCNDNFooter
            [settings]="settingGridCNDNFooter"
            (infoGrid)="eventInfoGridFooter($event)"
            (gridEvent)="eventGridCNDNFooter($event)"
          >
          </grid-plugin>
        </div>
      </div>
    </fieldset>
    <!--End of drawing 9th row -->
  </form>
</form>

<div id="invDialogYesNo" style="display: none; font-family: Lucida Console, monospace">
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i class="question circle big icon" style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogInvYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogInvNo()">
        No
      </button>
    </div>
  </form>
</div>



<div id="invDialogSilentPrintYesNo" style="display: none; font-family: Lucida Console, monospace">
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i class="question circle big icon" style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogSilentPrintYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogSilentPrintNo()">
        No
      </button>
    </div>
  </form>
</div>
<!-- End of Form -->
<footer-plugin [info]="info"></footer-plugin>
