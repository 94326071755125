import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { cllForm, cllGrid, cllContainer,cllDGForm, MSailingSchedule, officeModel, uploadContainer, cllHeader, perMapping, transhipmentModel } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { error } from 'selenium-webdriver';
import { validateConfig } from '@angular/router/src/config';

declare  var $:any;


@Component({
  selector: 'app-transaction-cll-dg-page',
  templateUrl: './transaction-cll-dg-page.component.html',
  styleUrls: ['./transaction-cll-dg-page.component.css']
})

export class TransactionCLLDGPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cllGrid') gridCLL:GridPluginComponent;
  @ViewChild('packageType') cbPackageType:ComboPluginComponent;
  @ViewChild('cllGridDG') gridCLLDG:GridPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGrid;
  settingPackageType;
  settingGridDG

  /* Misc Parameters */


  /* Parameter model */
  model = new cllForm;
  modelForm = new cllDGForm;
  office = new officeModel;


  loading = false;
  lock = false;
  userID = "";
  hitUrl = "";
  sortField = "*";
  direction = "*";
  cllProcessID = "";
  disIndicator = false;

  /* validatorRules */
  validatorRules = {
    chargeName: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Charge Name.'
        }
      ]
    },
    chargeCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Charge Code.'
        },
      ]
    }
  }

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService, private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsFront2: [
      ],
      buttonsDetail: [
        {name: 'Save', event: 'saveData', icon: 'save'},
        {name: 'Add', event: 'addData', icon: 'add'},
        {name: 'Remove', event: 'removeData', icon: 'minus'},
        {name: 'cancel', event: 'cancelData', icon: 'remove'},
        {name: 'Add', event: 'addDataToGRid', icon: 'add'},
        {name: 'cancel', event: 'cancelAddData', icon: 'remove'},
      ],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Container Load List DG'
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      columns     : [
                   {header: 'Vessel Code', field: 'vesselCode', width: 250},
                   {header: 'Vessel Name', field: 'vesselName', width: 300},
                   {header: 'Valid', field: 'isValid', width: 50}
      ]
   }

   this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      columns     : [
                  {header: 'Voyage', field: 'voyage', width: 100},
                  {header: 'Bound', field: 'bound', width: 50},
                  {header: 'Service', field: 'serviceCode', width: 100},
      ]
   }

   this.settingGrid = {
    id: 'cllGrid',
    url: '',
    page: 10,
    columns: [
      {header: 'No.', field: 'no', width: 50},
      {header: 'BKG Ref.', field: 'bNo', width: 100},
      {header: 'BL Ref.', field: 'blNo', width: 100},
      {header: 'SO', field: 'inSlot', width: 50},
      {header: 'OP', field: 'operatorCode', width: 50},
      {header: 'Cntr No.', field: 'containerNo', width: 50},
      {header: 'Cntr Size/Type', field: 'typeSize', width: 50},
      {header: 'Seal No. 1', field: 'sealNo1', width: 100},
      {header: 'Cell Loc.', field: 'cellLocation', width: 100},
      {header: 'VGM WT. (KGS)', field: 'weight', width: 100},
      {header: 'Status (F/E)', field: 'status', width: 100},
      {header: 'T/S (Y/N)', field: 'tranship', width: 100},
      {header: 'POL', field: 'loadPortName', width: 100},
      {header: 'POT', field: 'transhipPortName', width: 100},
      {header: 'POD 1', field: 'discPort1Name', width: 100},
      {header: 'POD 2', field: 'discPort2Name', width: 100},
      {header: 'POD 3', field: 'discPort3Name', width: 100},
      {header: 'Final Dest', field: 'finalDestName', width: 100},
      {header: 'Connecting Vsl', field: 'nextVesselName', width: 100},
      {header: 'Connecting Voy', field: 'nextVoyage', width: 100},
      {header: 'Thru Shpt (Y/N)', field: 'thruSpt', width: 100},
      {header: 'Shipper', field: 'shipperName', width: 100},
      {header: 'Commodity', field: 'commodityName', width: 100},
      {header: 'DG (Y/N)', field: 'dg_display', width: 100},
      {header: 'IMO', field: 'imo', width: 100},
      {header: 'UN NO.', field: 'unno', width: 100},
      {header: 'Flash Pt.', field: 'flashPoint', width: 100},
      {header: 'C/F', field: 'flashScale', width: 100},
      {header: 'PKG Type', field: 'pkgTypeCode', width: 100},
      {header: 'RF (Y/N)', field: 'reefer_display', width: 100},
      {header: 'Temp.', field: 'reeferTemp', width: 100},
      {header: 'C/F', field: 'tempScale', width: 100},
      {header: 'RF as DRY', field: 'reeferAsDry_display', width: 100},
      {header: 'OOG (Y/N)', field: 'oog_display', width: 100},
      {header: 'OH', field: 'oh', width: 100},
      {header: 'OWL', field: 'owl', width: 100},
      {header: 'OWR', field: 'owr', width: 100},
      {header: 'OLF', field: 'olf', width: 100},
      {header: 'OLB', field: 'olb', width: 100},
      {header: 'OOG Scale', field: 'oogScale', width: 100},
      {header: 'Bundle (Y/N)', field: 'bundle_display', width: 100},
      {header: 'Special Details', field: 'spDetails', width: 100},
      {header: 'Seal No. 2', field: 'sealNo2', width: 100},
      {header: 'Seal No. 3', field: 'sealNo3', width: 100},
      {header: 'UC (Y/N)', field: 'uc_display', width: 100},
      {header: 'UC/H', field: 'uch', width: 100},
      {header: 'S.', field: 'uchScale', width: 100},
      {header: 'UC/L', field: 'ucl', width: 100},
      {header: 'S.', field: 'uclScale', width: 100},
      {header: 'UC/W', field: 'ucw', width: 100},
      {header: 'S.', field: 'ucwScale', width: 100},
      {header: 'Stack Date/Time', field: 'stackDateTime', width: 100},
      {header: 'Indicator', field: 'indicator', width: 100},
      {header: 'Method', field: 'method', width: 100},
      {header: 'Pax', field: 'pax', width: 100},
    ],
    buttons: [],
    enableSorting: true,
    enableSelectAll: true,
    enablePagination: false,
    sortingColumns:'no',
    sortingDirection: 'ASC'
   }

   this.settingPackageType = {
    id          : 'newPackageType',
    type        : 'search enter', // search | select | select input
    url         : this.configService.config.BASE_API.toString() + '/MasterPackageTypes/findGeneral/Y/packageTypeName/{query}/1/5/*/*',
    maxChars    : 0,
    template    : 'grid', // default
    placeholder : 'Search Package Type',
    title       : 'packageTypeName',
    align       : 'left',
    description  : '',
    isMark      : true,
    columns     : [
      {header: 'Package Type Code', field: 'packageTypeCode', width: 100},
      {header: 'Package Type Name', field: 'packageTypeName', width: 300},
      {header: 'Valid', field: 'isValid', width: 50}
    ]
   }

   this.settingGridDG = {
    id: 'cllGrid',
    url: '',
    page: 10,
    columns: [
      {header: 'No.', field: 'seq', width: 50},
      {header: 'IMO', field: 'imo', width: 50},
      {header: 'Un No', field: 'unno', width: 50},
      {header: 'Flash Point', field: 'flashPoint_display', width: 50},
      {header: 'Package Type', field: 'packageTypeName', width: 50},
      {header: 'No of PKG', field: 'noOfPackage', width: 50},
      {header: 'WT', field: 'dgWt', width: 50},
      {header: 'Product Name', field: 'productName', width: 50},
      {header: 'Prop Shipping Name', field: 'properShippingName', width: 50},

    ],
    buttons: [],
    enableSorting: true,
    enableSelectAll: true,
    enablePagination: false,
    sortingColumns:'no',
    sortingDirection: 'ASC'
   }

  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit(){
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userID = this.cookieService.getName();

    this.hitUrl = this.configService.config.BASE_API.toString();
    this.invisibleToolbarButtons = "addDataToGRid,cancelAddData";
    //this.modeToolbar = true;
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  setDataMainGrid(data:any){
    var result = new cllGrid;
    result.processID = data["cllProcessId"];
    result.bNo = data["cllBookingRef"];
    result.blNo = data["cllBlRef"];
    result.operatorCode = data["cllOpCode"];
    result.inSlot = data["cllSoCode"];
    result.containerNo = data["cllContainerNo"];
    result.typeSize = data["d4allContainerCode"];
    result.containerType = data["cllContainerType"];
    result.containerSize = data["cllContainerSize"];
    result.sealNo1 = data["cllContainerSealNo1"];
    result.cellLocation = data["cellLocationFDetail"];
    result.weight = data["cllContainerWeight"];
    result.status = (data["cllIsEmpty"] == "Y" ? "E" : "F");
    result.tranship = data["cllIsTranship"];
    result.thruSpt = data["cllIsThruSpt"];
    result.loadPort = data["cllPolCode"];
    result.loadPortName = data["loadPortName"];
    result.transhipPort = data["cllPotCode"];
    result.transhipPortName = data["transhipPortName"];
    result.discPort1 = data["cllPod1Code"];
    result.discPort1Name = data["discPortName1"];
    result.discPort2 = data["cllPod2Code"];
    result.discPort2Name = data["discPortName2"];
    result.discPort3 = data["cllPod3Code"];
    result.discPort3Name = data["discPortName3"];
    result.finalDest = data["cllFdestCode"];
    result.finalDestName = data["finalDestName"];
    result.nextVesselName = data["nextVesselName"];
    result.nextVoyage = data["cllNextVesselVoyage"];
    result.shipperName = data["cllShipperName"];
    result.commodityName = data["cllCommodityName"];
    result.dg_display = data["cllIsDg"];
    result.imo = data["cllImo"];
    result.unno = data["cllUnno"];
    result.flashPoint = data["cllFlashPointTemp"];
    result.flashScale = data["cllFlashPointScale"];
    result.flashPoint_display = data["cllFlashPointTemp"] + " " + data["cllFlashPointScale"] ;
    result.pkgTypeCode = data["cllPackageType"];
    result.reefer = data["cllIsReefer"] == "Y"? true : false;
    result.reefer_display = data["cllIsReefer"];
    result.reeferAsDry = data["cllReeferAsDry"] == "Y" ? true : false;
    result.reeferAsDry_display = data["cllReeferAsDry"];
    result.reeferTemp = data["cllReeferTemp"];
    result.tempScale = data["cllReeferScale"];
    result.oog_display = data["cllIsOog"];
    result.oogScale = data["cllOhScale"];
    result.bundle_display = data["cllIsBundle"];
    result.oh = data["cllOh"];
    result.owl = data["cllOwl"];
    result.owr = data["cllOwr"];
    result.olf = data["cllOlf"];
    result.olb = data["cllOlb"];
    result.uc_display = data["cllIsUc"];
    result.uch = data["cllUch"];
    result.uchScale = data["cllUchScale"];
    result.ucw = data["cllUcw"];
    result.ucwScale = data["cllUcwScale"];
    result.ucl = data["cllUcl"];
    result.uclScale = data["cllUclScale"];
    result.spDetails = data["cllSpDetails"];
    result.sealNo2 = data["cllContainerSealNo2"];
    result.sealNo3 = data["cllContainerSealNo3"];
    result.remarks = data["cllRemarks"];
    result.stackDateTime = data["cllStackDateTime"];
    result.indicator = data["cllContainerIndicator"];
    result.method = data["cllContainerMethod"];
    result.pax = data["cllContainerPax"];

    return result;
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  toolbarEvent(event) {
    // console.log(event);
    switch(event){
      case "retrieve":
        this.handleRetrieve();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.handleCancel();
        this.router.navigateByUrl("/main/home");
        break;
      case "cancelData":
        this.modeToolbar = false;
        $("#searchForm").show();
        $("#resultGrid").show();
        $("#editFormGrid").hide();
        $("#editForm").hide();
        this.cllProcessID = "";

        this.handleRetrieve();
        break;
      case "addData":
        //editForm editFormGrid
        this.invisibleToolbarButtons = "saveData,addData,cancelData,removeData";
        this.modelForm = new cllDGForm;
        this.modelForm.seq = this.gridCLLDG.listStore.store.length + 1;
        this.modelForm.processId = this.cllProcessID;
        $("#editFormGrid").hide();
        $("#editForm").show();

        this.setSelectValue("flash-sign", this.modelForm.flashPointSign);
        this.setSelectValue("flash-Degreee", this.modelForm.flashPointScale);
        this.cbPackageType.setForceValue(this.modelForm.packageTypeName);
        break;
      case "addDataToGRid":
        this.modelForm.flashPoint_display = (this.modelForm.flashPointSign == "-"?-1:1) * this.modelForm.flashPointTemp + " " + this.modelForm.flashPointScale;

        var cllDG = this.copyStore(this.gridCLLDG.listStore.store);
        var editMode:boolean = false;
        cllDG.forEach(fe=>{
          if(fe.seq == this.modelForm.seq){
            fe = this.modelForm;
            editMode = true;
          }
        });

        if(editMode){
          this.gridCLLDG.listStore.store.splice(1,this.gridCLLDG.listStore.store.length);
          this.gridCLLDG.listStore.store = this.copyStore(cllDG);
        } else {
          this.gridCLLDG.listStore.store.push(this.modelForm);
        }
        this.modelForm = new cllDGForm;
        $("#editFormGrid").show();
        $("#editForm").hide();
        this.gridCLLDG.loadData();
        this.invisibleToolbarButtons = "addDataToGRid,cancelAddData";

        if(this.disIndicator){
          this.disIndicator = false;
          this.cbPackageType.disableCombo = false;
        }
        break;
      case "cancelAddData":
        this.modelForm = new cllDGForm;
        $("#editFormGrid").show();
        $("#editForm").hide();
        this.gridCLLDG.loadData();
        this.invisibleToolbarButtons = "addDataToGRid,cancelAddData";
        break;
      case "removeData":
        if(this.gridCLLDG.getSelectedValues().length > 0){
          var cllDG = this.copyStore(this.gridCLLDG.getSelectedValues());

          cllDG.forEach(fe=>{
            if(fe.seq != 1){
              var index = this.gridCLLDG.listStore.store.indexOf(fe);
              this.gridCLLDG.listStore.store.splice(index,1);
            }
          });

          var seq = 1;
          this.gridCLLDG.listStore.store.forEach(fe=>{
            fe.seq = seq;
            seq++;
          });

          this.gridCLLDG.clearSelectedValues();
          this.gridCLLDG.loadData();
        } else {
          this.message("information","Information","Please select one or more data to remove","okonly",{ok:""});
        }
        break;
      case "saveData":
        var postData = [];
        this.gridCLLDG.listStore.store.forEach(fe=>{
          fe.flashPointTemp = (fe.flashPointSign == "+"?1:-1)*fe.flashPointTemp;
          postData.push(this.modelForm.setPostData(fe));
        });

        this.handleSave(postData);

        break;
    }
  }

  handleRetrieve(){
    var uri = this.hitUrl + "/ContainerLoadList/find/cll/dg/"+this.office.officeCode+"/"+this.model.vesselId+"/"+this.model.baseVoyage+"/"+this.model.vesselBound.toUpperCase()+"/"+this.sortField+"/"+this.direction;

    this.gridCLL.loading = true;
    this.gridCLL.listStore.store.splice(0, this.gridCLL.listStore.store.length);
    // console.log("url :"+uri);
    this.genericService.GET(uri).subscribe((resp)=>{
      if(resp.ok){
        var rData = resp.json()["data-result-list"];
        rData.forEach(fe=>{
          this.gridCLL.listStore.store.push(this.setDataMainGrid(fe));
        });


        this.gridCLL.loadData();
        this.gridCLL.loading = false;
      }
    }, error=>{

    });
  }

  handleSave(dataList){
    var hitUrl = this.hitUrl + "/ContainerLoadList/saveContainerDG";
    var mapData = {};
    mapData["data-to-save"] = dataList;

    // console.log("post lagi");
    // console.log(mapData);
    this.loading = true;
    this.genericService.POST(hitUrl, mapData).subscribe((resp)=>{
      if(resp.ok){
        this.message("information","Information","Data saved.","okonly",{ok:""});

        this.loading = false;
        this.toolbarEvent("cancelData");
      }
    });
  }

  handleCancel(){
    this.model = new cllForm;
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");

    this.gridCLL.clearSelectedValues();
    this.gridCLL.listStore.store.splice(0, this.gridCLL.listStore.store.length);
    this.gridCLL.loadData();

    $("html, body").scrollTop(0);
  }

  changeEventVessel(event){
    if(event.vesselId != null){
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.model.vesselId+"/"+this.model.vesselBound.toUpperCase()+"/{query}/"+this.office.officeCode);
      this.cbVoyage.setValue("");
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
    }
  }

  changeEventVoyage(event){
    if(event.voyage != null){
      this.model.baseVoyage = event.voyage;
    } else {
      this.model.baseVoyage = "";
    }
  }

  gridEvent(event){
    // console.log(event);
    var stat = event.split("-");
    if(stat[0] == "dblClick"){
      this.modeToolbar = true;
      $("#searchForm").hide();
      $("#resultGrid").hide();
      $("#editFormGrid").show();
      $("#editForm").hide();

      this.gridCLL.listStore.store.forEach(fe=>{
        if(fe.no == stat[1]){
          // console.log("process id"+fe.processID);
          var hitUrl = this.hitUrl + "/ContainerLoadList/findByID/"+fe.processID;
          this.loading = true;
          this.genericService.GET(hitUrl).subscribe((resp)=>{
            if(resp.ok){
              var result = resp.json()["content"];
              this.gridCLLDG.listStore.store.splice(0, this.gridCLLDG.listStore.store.length);

              this.cllProcessID = fe.processID;
              var dt = new cllDGForm;
              dt.seq = 1;
              dt.processId = fe.processID;
              dt.imo = result["cllImo"];
              dt.unno = result["cllUnno"];
              dt.flashPointSign = parseInt(result["cllFlashPointTemp"]) < 0 ? "-":"+";
              dt.flashPointTemp = parseInt(result["cllFlashPointTemp"]);
              dt.flashPointScale = result["cllFlashPointScale"];
              dt.packageTypeId = result["cllPackageTypeId"];
              dt.packageTypeName = result["cllPackageType"];
              dt.noOfPackage = 0;
              dt.dgWt = 0;
              dt.productName = "";
              dt.properShippingName = "";
              dt.flashPoint_display = dt.flashPointTemp + " " + dt.flashPointScale;

              var uri = this.hitUrl + "/ContainerLoadList/find/cll/dg/id/"+this.cllProcessID;
              this.genericService.GET(uri).subscribe((resp)=>{
                if(resp.ok){
                  var retData = resp.json();
                  if(retData["message"] == "success") {
                    if(retData["data-result-list"].length > 0){
                      retData["data-result-list"].forEach(fe=>{
                        dt = new cllDGForm;
                        dt.seq = fe["cllDgSeq"];
                        dt.processId = fe["cllProcessId"];
                        dt.imo = fe["cllDgImo"];
                        dt.unno = fe["cllDgUnno"];
                        dt.flashPointSign = parseInt(fe["cllDgFlashPointTemp"]) < 0 ? "-":"+";
                        dt.flashPointTemp = parseInt(fe["cllDgFlashPointTemp"]);
                        dt.flashPointScale = fe["cllDgFlashPointScale"];
                        dt.packageTypeId = fe["cllDgPackageTypeId"];
                        dt.packageTypeName = fe["cllDgPackageType"];
                        dt.noOfPackage = fe["cllDgNoOfPackage"];
                        dt.dgWt = fe["cllDgWeight"];
                        dt.productName = fe["cllDgProductName"];
                        dt.properShippingName = fe["cllDgProperShippingName"];
                        dt.flashPoint_display = dt.flashPointTemp + " " + dt.flashPointScale;

                        this.gridCLLDG.listStore.store.push(dt);
                      });
                    } else {
                      this.gridCLLDG.listStore.store.push(dt);
                    }
                  } else {
                    this.gridCLLDG.listStore.store.push(dt);
                  }

                  this.gridCLLDG.loadData();
                  this.loading = false;
                }
              });
            }
          });
        }
      });

    }
  }

  gridEventDG(event){
    var stat = event.split("-");
    if(stat[0] == "dblClick"){
      this.gridCLLDG.listStore.store.forEach(fe=>{
        if(fe.seq == stat[1]){
          fe.flashPointTemp = (fe.flashPointSign == "+"?1:-1)*fe.flashPointTemp;
          this.modelForm = fe;

          if(fe.seq == 1){
            this.disIndicator = true;
          }
        }
      });

      this.invisibleToolbarButtons = "saveData,addData,cancelData,removeData";
      $("#editFormGrid").hide();
      $("#editForm").show();

      this.setSelectValue("flash-sign", this.modelForm.flashPointSign);
      this.setSelectValue("flash-Degreee", this.modelForm.flashPointScale);
      this.cbPackageType.setForceValue(this.modelForm.packageTypeName);

      if(this.disIndicator){
        this.cbPackageType.disableCombo = true;
      } else {
        this.cbPackageType.disableCombo = false;
      }
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  eventSelect(object, option){
    var selectedValue = object.options[object.selectedIndex].value;
    switch(option){
      case "flashSign":
        this.modelForm.flashPointSign = selectedValue;
        break;
      case "flashScale":
        this.modelForm.flashPointScale = selectedValue;
        break;
    }
  }

  comboEvent(event, value:string){
    if(value == "PackageType"){
      if(event.packageTypeCode != null){
        this.modelForm.packageTypeId = event.packageTypeId;
        this.modelForm.packageTypeCode = event.packageTypeCode;
        this.modelForm.packageTypeName = event.packageTypeName;
      } else {
        this.modelForm.packageTypeId = "";
        this.modelForm.packageTypeCode = "";
        this.modelForm.packageTypeName = "";
      }
    }
  }

  setSelectValue(id:string, value:string){
    $("#"+id).val(value);
  }

  copyStore(storeToCopy){
    var result = [];
    storeToCopy.forEach(fe=>{
      result.push(fe);
    });

    return result;
  }

}
