<div class="ui segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
      <div class="ui medium text loader">Loading</div>
  </div>
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
        <div class="sixteen wide fields">
            <div class="four wide field">
              <div class="required field"  [class.error]="model['error-invoiceNo'] != null && model['error-invoiceNo'] != ''">
              <label style="color:red">Invoice No. </label>
              <input type="text" name="invoiceNo"  placeholder="Invoice No." (change)="changeEventInvoiceNo($event)" >
              <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-invoiceNo'] != null && model['error-invoiceNo'] != ''">
                {{model['error-invoiceNo']}}
              </div>
              </div>
            </div>
            <div class="four wide field">
                <label>Vessel</label>
                <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                <input type="text" name="vesselId"  readonly >
            </div>
            <div class="four wide field" >
                <label>Voyage</label>
                <!--combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin-->
                <input type="text" name="voyage"  readonly >
            </div>
            <div class="field" >
                  <label>Bound</label>
                  <input #sailingBound list="bound" name="bound"  placeholder="Bound" (change)="changeEventBound($event)"

                        maxlength="1"
                        required
                        autocomplete="off"
                        >
                        <datalist id="bound">
                            <option value="O">
                            <option value="I">
                            <option value="M">
                        </datalist>
            </div>
        </div>
        
        <grid-plugin id="gridHeader" #gridHeader [settings]="settingGridHeader" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        
    </form>
  </div>
</div>

<footer-plugin [info]="info"></footer-plugin>