import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ConfigService, CookieService } from 'sibego-ui-library';
declare var $: any;

@Component({
  selector: 'app-login-news-page',
  templateUrl: './login-news-page.component.html',
  styleUrls: ['./login-news-page.component.css']
})
export class LoginNewsPageComponent implements OnInit, AfterViewInit {

  base:string = ''

  constructor(private configService:ConfigService, private cookieService:CookieService) {

  }

  ngOnInit() {

    this.base = this.configService.config.BASE_API.toString()
    console.log(this.base)

  }

  ngAfterViewInit(){
    $("body").css({"overflow-y":"hidden"});

    if($('#m_header').css('display') === 'block')
    {
      //do something 
    }
    else
    {
      //something else
      console.log("this.cookieService.getName() = " + localStorage.getItem("logged"));
      if(localStorage.getItem("logged") == undefined){
        $('#mobile-menu2')
          .sidebar({
            dimPage: false,
            transition: 'overlay',
            exclusive: false,
            closable: false
          }).sidebar('toggle');
      }
    }

  }

  menuSidebar(){
    $('#mobile-menu2')
      .sidebar({
        dimPage: false,
        transition: 'overlay',
        exclusive: false,
        closable: false
      }).sidebar('toggle');
  }


}
