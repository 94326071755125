import { Component, OnInit, AfterViewInit, } from '@angular/core';
import { Router } from '@angular/router'
import { ChangePassword } from '../shared/index';
import { ListStore, Authorize, Validator, ConfigService, GenericUtil, GenericService } from 'sibego-ui-library';
import { toBase64String } from '@angular/compiler/src/output/source_map';

declare  var $:any;

export class ChangePasswordX {
    userId   : string = '';
    XPWD  : string = '';

    constructor() {

    }
}

@Component({
    selector: 'app-reset-password-page',
    templateUrl: './reset-password-page.component.html',
    styleUrls: ['./reset-password-page.component.css']
})

export class ResetPasswordPageComponent extends Validator  implements OnInit, AfterViewInit {

    /* Parameter for information into Footer */
    info = '';

    /* Parameter for dialog */
    dialog:any;

    /* Parameter for disable & invisible Toolbar buttons */
    disableToolbarButtons = "";
    invisibleToolbarButtons = "";

    /* Parameter modeForm (Default : false) */
    modeForm = false;

    /* Parameter modeToolbar (Default : false) */
    modeToolbar = false;

    /* Parameter form (Default : '') */
    form = '';

    /* Parameter listStore for Grid */
    listStore = new ListStore;

    //setGridEvent:string = '';

    /* Parameter settings */
    settingToolbar;

    /* Misc Parameters */

    /* Parameter model */
    model = new ChangePassword;
    //selectList = [];

    loading = false;

    lock = false;

    /* validatorRules */
    validatorRules = {
        currentPassword: {
            rules:[
                {
                    type  : 'empty',
                    prompt: 'Please input ChangePassword Name.'
                },
                {
                    type  : 'length[3]',
                    prompt: 'Password must be 3 characters long.'
                }
            ]
        },
        newPassword: {
            rules: [
                {
                    type  : 'empty',
                    prompt: 'Please input New Password.'
                },
                {
                    type  : 'length[3]',
                    prompt: 'Password must be 3 characters long.'
                }
            ]
        },
        confirmPassword: {
            rules: [
                {
                    type  : 'empty',
                    prompt: 'Please input Confirm Password.'
                },
                {
                    type  : 'length[3]',
                    prompt: 'Password must be 3 characters long.'
                }
            ]
        }
    }

    constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private router: Router) {
        super();

        this.settingToolbar = {
            buttonsFront          : [
              {name: 'Save',  event: 'save',   icon: 'checkmark'},
              {name: 'Cancel',event: 'cancel', icon: 'remove' }
            ],
            buttonsDetail         : [ ],
            createDefaultFront    : false,
            createDefaultDetail   : true,
            searchBy              : [],
            toolbarType           : 'none',
            urlhelp               : 'assets/pdf/panduan.pdf'
        }

    }

    ngOnInit(){
        this.modeForm=true;
    }

    ngAfterViewInit() {
    }

    message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
        this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
        $('#dialog').modal("setting", "closable", false).modal('show');
    }

    eventMessage(event) {
        if(event != ''){
            eval(event);
        }
    }

    toolbarEvent(event) {
        switch (event) {
            case 'save':
                //this.handleSave();
                this.saveEvent();
                break;
            case 'cancel':
                this.handleCancel();
                break;
        }
    }

    handleSave()
    {
        // handle save event
        // // console.log('handleSave');
        let isPassedValidation:boolean = this.onValidate(this.model);
        // console.log('isPassedValidation ' + isPassedValidation);
        if(!isPassedValidation){
            /*Patch for local timing
             * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
             * 2. System should use local timing instead of server timing
             * 3. Format should be YYYY-MM-DD hh:mm:ss
             */
            // console.log("PSI Date => " + this.genericUtil.getPSIDate());
            this.model["dateCreated"] = this.genericUtil.getPSIDate();
            this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
        }else{
            this.loading = false;
        }
    }

    handleCancel(){
        // handle cancel event
        this.form = '';
        this.router.navigateByUrl(localStorage.getItem("lastVisit"));
    }

    afterSave(){
        this.modeForm = false;
        this.modeToolbar = false;
        this.form = '';
    }

    setData(){
        //SET MODEL DATA FOR UPDATE PROCESS

        this.model = new ChangePassword;

    }


    saveEvent(){
        // console.log('saveEvent');
        this.loading = true;
        var modelx = new ChangePasswordX;
        modelx.userId = this.model.userId;
        modelx.XPWD = btoa(this.model['currentPWD'] + "."+this.model['newPWD']+"."+this.model['confirmPWD']);

        this.genericService.post(this.configService.config.BASE_API.toString() + '/changePassword',modelx).subscribe((resp) =>{

            if(resp.status == 200){
                let bd = resp["_body"];
                console.log("_body : ", bd)
                if(bd == "Changed Password Successfully"){
                    this.model = new ChangePassword;
                    this.loading = false;
                    this.message('information','Information','Changed password successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
                }else{
                    this.loading = false;
                    this.message('warning','Warning',bd,'okonly',{ok: 'this.loading=false;'});
                }
            }else{
                this.loading = false;
                this.message('warning','Warning',resp.json(),'okonly',{ok: 'this.loading=false;'});
            }

        },error => {
            this.loading = false;
            this.loading = false;
        });
    }

}
