import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFourDigitDecimalNumber]',
})
export class FourDigitDecimalNumberDirective {
  // Allow decimal numbers and negative values
  private regex: RegExp = new RegExp(/^[0-9]{0,10}(\.\d{0,4})?$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    // 'Delete',
    // 'Control',
    // 'v',
    // 'V',
    // 'a',
    // 'A'
    'Delete'
  ];

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1 || 
      ( 
        event.ctrlKey === true && (
        event.keyCode === 88 ||
        event.keyCode === 67 ||
        event.keyCode === 65 ||
        event.keyCode === 86)
      ) 
    ) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [
      current.slice(0, position),
      event.key == 'Decimal' ? '.' : event.key,
      current.slice(position),
    ].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
