<form class="ui raised segment">

  <div id='ShaBookingEDI'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <form class="ui form">
     
      <!-- Menu - tab-->
      <div class="ui top attached tabular menu" style="background-color:beige;">
        <a class="item" [class.active]="tabStatus == 'tab1'" data-tab="ShaBookingEDIProcess" (click)="initTab1()">Process Booking EDI &nbsp; 
          <span [style.display]="tabStatus=='tab1'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span>
        </a>
        <a class="item" [class.active]="tabStatus == 'tab2'" data-tab="AccpRejBkg" (click)="initTab2()">Acceptance / Rejection Booking(s) &nbsp; 
          <span [style.display]="tabStatus=='tab2'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span>
        </a>
      </div> <!--End Menu - tab-->

      <!-- Process Sha Booking EDI -->
      <div class="ui bottom attached tab segment"
        [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
        id="ShaBookingEDIProcessTab"
        data-tab="ShaBookingEDIProcess">
        
      <!--Menu Button - Process Sha Booking EDI -->
       <toolbar-plugin [modeToolbar]="modeToolbar" 
        [settings]="settingToolbar" 
        [disableButtons]="disableToolbarButtons" 
        [invisibleButtons]="invisibleToolbarButtons"
        (eventEmitterClick)="toolbarEvent($event)" >
       </toolbar-plugin>
       <br/>
        <div [style.display]="modeForm ? 'block' : 'none'">
          <a class="ui red ribbon label">Stage 2 : Process Booking EDI</a>
          <!--GridView - Process Sha Booking EDI -->
          <grid-plugin
            #gridShaBookingEDIInfo
            [settings]="settingGridShaBookingEDIProcessInfo"
            (gridEvent)="gridEventShaBookingEDIProcessInfo($event)"
            (infoGrid)="infoGridShaBookingEDIProcessInfo($event)">
          </grid-plugin>
        </div>
        <br/>
        <!--Bottom Button - Process Sha Booking EDI -->
        <div class="field" style="text-align: right">
          <button class="tiny ui negative button"
          (click)="onShaBookingEDIInfo__Discard($event)"
          [class.disabled]="lockBtnShaBookingEDIDiscard == true">
          Discard
          </button>
        </div>
        
      </div> 
      <!-- End Process Sha Booking EDI -->
      <br/>
      <!-- Acceptance / Rejection Booking(s) -->
      <div class="ui bottom attached tab segment"
      [class.active]="tabStatus2 == 'active' && tabStatus == 'tab2'"
      id="AccpRejBkgTab" data-tab="AccpRejBkg" >

      <a class="ui red ribbon label">Stage 3 : Acceptance / Rejection of Booking(s)</a>
      <br/>
        <!--Buttons -- Acceptance / Rejection Booking(s) -->
        <toolbar-plugin [modeToolbar]="modeToolbar_AccpRejBkg" 
        [settings]="settingToolbar_AccpRejBkg" 
        [disableButtons]="disableToolbarButtons_AccpRejBkg" 
        [invisibleButtons]="invisibleToolbarButtons_AccpRejBkg" 
        (eventEmitterClick)="toolbarEvent_AccpRejBkg($event)" >
        </toolbar-plugin>
        
        <br/>
        <!--File Name field -- Acceptance / Rejection Booking(s) -->
        <div class="two fields">
          <div class="field" [class.error]="model['error-ediFileName'] != null && model['error-ediFileName'] != ''">  
            <label>File Name</label>
              <combo-plugin #cbEDIFileName [settings]="settingEDIFileName" 
            (change)="changeEventEDIFileName($event)" 
            (keypress)="formValidation.validateFileName($event)" 
                (keyup)="backspaceValue($event,formValidation.regFileName, 'ediFileName')"
                (paste)="onPasteValidation($event,'ediFileName',formValidation.regFileName)"
                style="width: 100px;" >
            </combo-plugin>
         

          <div class = "ui basic red pointing prompt label transition error-message-hidden" 
              [class.error-message-visible]="model['error-ediFileName'] != null && model['error-ediFileName'] != ''">
              {{model['error-ediFileName']}}
          </div>
        </div>

       </div>
       <br/>
        <div [style.display]="modeForm ? 'block' : 'none'">
          
          <!--Grid View -- Acceptance / Rejection Booking(s) -->
          <grid-plugin
            #gridShaBookingAccRejInfo
            [settings]="settingGridShaBookingAccRejInfo"
            (gridEvent)="gridEventShaBookingAccRejInfo($event)"
            (infoGrid)="infoGridShaBookingAccRejInfo($event)">
          </grid-plugin>
        </div>
     
        <br/>
        <!-- Start rejection reason row -->
        <div class="field">  
          
            <!-- <fieldset class="eight wide field" [disabled]="isDisableByRejectionReason"> -->
              <!-- <label>Rejection Reason</label> -->
          <fieldset style="border: 1px solid lightgray;height:100px">
            <legend style="font-weight:bold;color:dodgerblue;">Rejection Reason</legend>
            <div class="two fields">
              <div class="field">
              <!-- <div class="required field"
              [class.error]="
                model['error-BookingRejectionReason'] != null &&
                model['error-BookingRejectionReason'] != ''
              ">   -->
              <br/>
                <combo-plugin
                  #cbBookingRejectionReason
                  [settings]="settingBookingRejectionReason"
                  (change)="changeEventBookingRejectionReason($event)">
                </combo-plugin>
              </div>
      
                <div class="eight wide field">
                  <label>Others</label>
                  <textarea
                    name="otherRejectionReason"
                    style="font-size:12px;"
                    [(ngModel)]="otherRejectionReasonFormat"
                    placeholder=""
                    readonly
                    rows="1"
                    [ngStyle]="{ 'display': !isModify ? '' : 'none' }">
                  </textarea>
                  <textarea-plugin
                  #txtOtherRejectionReason
                  [settings]="settingtxtOtherRejectionReason"
                  [disabled]="!isModify"
                  [ngStyle]="{ 'display': isModify ? '' : 'none' }">
                </textarea-plugin>
                </div>

            </div>

          </fieldset>
        </div>
        <!--End of rejection reason row -->

        <br/>
        <!-- Start Reject Button - Acceptance / Rejection Booking(s) tab -->
        <div class="field" style="text-align: right">
          <button class="tiny ui negative button"
          (click)="onShaBookingReject($event)"
          [class.disabled]="lockBtnShaBookingReject == true">
          Reject
          </button>
        </div> <!--End Reject Button - Acceptance / Rejection Booking(s) tab -->


      </div> <!--End Acceptance / Rejection Booking(s) -->
    </form>
  </div>  
</form>


<footer-plugin [info]="info"></footer-plugin>
