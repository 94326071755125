import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,CalendarPluginComponent,GenericService, GenericUtil, ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-report-monthly-lifting-tank-page',
  templateUrl: './report-monthly-lifting-tank-page.component.html',
  styleUrls: ['./report-monthly-lifting-tank-page.component.css']
})
export class ReportMonthlyLiftingTankPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  @ViewChild('dateLocalETA') dateLocalETA:CalendarPluginComponent;
  @ViewChild('dateLocalETD') dateLocalETD:CalendarPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPOC;
  settingDatePicker;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Monthly Lifting Report By Tank'
    }

     this.settingDatePicker = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }


     this.settingPOC = {
        id          : 'cbPOC',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/100/*/*',
        maxChars    : 3,
        template    : 'grid',
        placeholder : 'Search ...',
        title       : 'locationName',
        desciption  : '',
        flag        : '',// for template : 2
        columns      :[
            {header: 'Location Id', field: 'locationId', width: 100},
            {header: 'Location Name', field: 'locationName', width: 300},
            {header: 'Country Name', field: 'countryName', width: 100},
            {header: 'Remarks', field: 'remarks', width: 100},
            {header: 'Is Valid?', field: 'isValid', width: 100},
        ]
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dateLocalETA.initCalendar("");
    this.dateLocalETD.initCalendar("");

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventPOD(event){}
}
