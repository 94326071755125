export class MasterVesselTransmitEdi  {
    seq : number = 0;
    vesselId : string = '';
    vesselCode : string = '';
    vesselName: string = '';
    voyage  : string = '';
    scheduleId : string = '';
    serviceName  : string = '';
    dateCreated  : string ='';
    userCreated  : string ='';
    constructor () { }
}
  