<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <div [style.display]="modeForm?'none':'block'">
      <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Master GST (Good & Service Tax)</h4>
      <!-- Begin of Grid -->
      <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm?'block':'none'">
      <div class="ui segment" style="padding-left:1%;padding-right:20%;">
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id='container'>

          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == ''?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Master GST (Good & Service Tax)</h4>

            <div class="ui raised segment">
              <div class="one field" style="width:100%">
                <div class="field">
                  <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                    <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                    <label>Valid?</label>
                  </div>
                </div>
                <div class="three fields">
                  <div class="eleven wide field">
                    <div class="required field" style="margin-top:10px;" [class.error]="model['error-gstPercentage'] != null && model['error-gstPercentage'] != ''">
                      <label>GST %</label>
                      <input type="text" [(ngModel)]="model['gstPercentage']" name="gstPercentage"  [disabled]="lock == true" autonumeric [options]="myOptions">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="model['error-gstPercentage'] != null && model['error-gstPercentage'] != ''">
                        {{model['error-gstPercentage']}}
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="required field" style="margin-top:10px;" [class.error]="model['error-gstEffectiveFrom'] != null && model['error-gstEffectiveFrom'] != ''">
                      <label>Effective From</label><!--settingEffectiveFrom-->
                      <date-plugin
                        #gstEffectiveFrom
                        [settings]="settingDateEffectiveFrom"
                        (change)="changeDateTimeFrom($event)"
                      ></date-plugin>
                      <!-- <div class="ui left icon input">
                        <input id="gstEffectiveFrom" type="text"  class="form-control" data-toggle="datepicker" placeholder="Expiry On" [disabled]="lock == true">
                           <i class="calendar icon"></i>
                      </div> -->
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="model['error-gstEffectiveFrom'] != null && model['error-gstEffectiveFrom'] != ''">
                          {{model['error-gstEffectiveFrom']}}
                      </div>
                    </div>
                  </div>
                  <div class="field" style="padding-right:5px;">
                    <div class="required field" style="margin-top:10px;" [class.error]="model['error-gstEffectiveTo'] != null && model['error-gstEffectiveTo'] != ''">
                      <div class="field">
                        <label>Expiry On</label>
                        <!-- <div class="ui left icon input">
                          <input id="gstEffectiveTo" type="text"  class="form-control" data-toggle="datepicker" format = "yyyy-mm-dd" placeholder="Expiry On" [disabled]="lock == true">
                             <i class="calendar icon"></i>
                        </div> -->
                        <date-plugin
                        #gstEffectiveTo
                        [settings]="settingDateEffectiveTo"
                        (change)="changeDateTimeTo($event)"
                      ></date-plugin>
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="model['error-gstEffectiveTo'] != null && model['error-gstEffectiveTo'] != ''">
                            {{model['error-gstEffectiveTo']}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label>Remarks</label>
                  <textarea [(ngModel)]="model['gstRemarks']" name="gstRemarks" placeholder="Input your remarks..." [disabled]="lock == true" style="text-transform: uppercase"></textarea>
                </div>


              </div>
            </div>
            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" disabled readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" disabled readonly style="background-color: lightgray">
                </div>
              </div>
            </div>

          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Local Master GST (Good & Service Tax)</h4>
            <div class="ui raised segment">
              <div class="one fields" style="margin-top:10px;">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                  <button class="ui button" (click)="generateGstHistoryReport($event)">Export</button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>GST Percentage</label>
                  <combo-plugin #cbGstHistory [settings]="settingCbHistory" (change)="changeEventGstHistory($event)"></combo-plugin>
                </div>
              </div>

              <div class="field">
                <grid-plugin2 #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventGstHistory($event)" (infoGrid)="infoGridGstHistory($event)"></grid-plugin2>
              </div>

            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>

  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
