

export class LocalExchangeRate {
  no : number = 0;
  exchangeRateId   : string = '';
  exchangeRateDate  : string = '';
  homeCurrency  : string = '';
  destinationCurrency : string = '';
  exchangeRate : number = 0.0;
  isValid         : boolean = true;
  remarks       : string = '';
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  officeCode : string='';


  constructor() {

  }
}
