export class MSailingScheduleDetail{
idSailingDetail: string='';
seqNo : string='';
portOfCall : string='';
arrivalDate : string='';
sailDate : string='';
createdUserid : string='';
createDate: string='';
modifiedUserid : string='';
modifiedDate : string ='';
}