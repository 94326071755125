import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Authorize, Validator, GenericService, GenericUtil, ConfigService,
  CookieService, UploadPluginComponent, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $: any;

export class UploadContainer {
  fileName = '';
  messageValue = '';
  userCreated = '';
  dateCreated = '';
  officeCode = '';
  constructor() {

  }
}
@Component({
  selector: 'app-transaction-booking-upload-container-page',
  templateUrl: './transaction-booking-upload-container-page.component.html',
  styleUrls: ['./transaction-booking-upload-container-page.component.css']
})
export class TransactionBookingUploadContainerPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;
  @ViewChild('bookingContainerFileUpload') bookingContainerFileUpload: UploadPluginComponent;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;
  labelToolbar = 'Upload Container';

  /* Parameter settings */
  settingToolbar;
  settingUploadContainerFile;
  settingPDF;

  filePath = '';


  // lock

  // model declaration
  model = new UploadContainer();
  hitUrl: string = this.configService.config.BASE_API.toString();
  isSASTExist = false;
  postData: any = {};

  validatorRules = {};


  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient) {
    super();
    const officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    this.model.officeCode = officeCode;

    // {name: 'Print Report', event: 'pReport', icon: 'power'},
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'power'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    },

    this.settingUploadContainerFile = {
      id   : 'bookingContainerFileUpload',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    },

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : false,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initForm();
    const self = this;
    $('#myfile').change(function(e) {
      console.log('file name : ' + $(this).val());
      const fileName = $(this).val();
      if (fileName.split('.').pop().toLowerCase() === 'csv') {
        self.model.fileName = $(this).val();
        self.disableToolbarButtons = '';
      } else {
        self.message('information', 'Information', 'Please choose CSV file!', 'okonly', {ok: ''});
      }
    });
  }



  initModel() {
    this.model.fileName = '';
    this.model.messageValue = 'TO BEGIN, PLEASE BROWSE FOR THE FILE TO BE PROCESSED.\nTHEN ' +
      'CHOOSE A FILE TYPE: QLEC/SAST AND CLICK ON THE PROCESS BUTTON.';
    this.disableToolbarButtons = 'process';
    this.model.userCreated = this.cookieService.getName();
    this.model.officeCode = this.cookieService.getDefaultLocationCode();

    this.postData['officeCode'] = this.model.officeCode;
    this.postData['userId'] = this.model.userCreated;
    this.postData['fileName'] = '';
    this.bookingContainerFileUpload.store = [];

    this.isSASTExist = false;
    this.filePath = '';
  }

  initForm() {
    this.initModel();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'process' :
        this.uploadSastFileToServer();
        break;
      case 'cancel' :
        this.initModel();
        this.loading = false;
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  uploadEventBookingContainerFile(event) {
    console.log(event);
    if (event.includes('-')) {
      const temp = event.split('-');
      switch (temp[1].trim()) {
        case 'uploaded':
          this.disableToolbarButtons = 'process';
          this.model.fileName = temp[0].trim();
          this.isSASTExist = true;
          this.bookingContainerFileUpload.onUpload();
          break;
        case 'deleted':
          this.model.fileName = '';
          this.postData['fileName'] = '';
          break;
      }
    } else {
      switch (event) {
        case 'success':
          this.postData['fileName'] = this.model.fileName;
          this.disableToolbarButtons = '';

          break;
        default:
          break;
      }
    }
  }



  uploadSastFileToServer() {
    this.loading = true;
    this.postData['createdDate'] = this.genericUtil.getPSIDate();

    console.log('postData');
    console.log(this.postData);
    this.filePath = '';
    this.processingUploadContainer(this.postData);

  }


  processingUploadContainer(postData) {
    let uri = this.hitUrl + '?q=/MasterBookings/bookingUploadContainer';
    //uri = 'http://localhost:14000/MasterBookings/bookingUploadContainer'; // fix me later, prod this should be not used
    this._http.post(uri, postData, { headers: new HttpHeaders(
      {'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}),
      responseType: 'json'}).subscribe((resp) => {

        console.log(resp);
        const result = resp;
        if (result['status'] === 'ok') {
          console.log('Processing Done');
          this.filePath = result['filePath'];
          this.message('Information', 'information', 'Upload Container Completed.', 'okonly', {ok: 'this.loadPDF();'});
          this.loading = false;
        } else {
          this.message('Information', 'information', result['message'], 'okonly', {ok: ''});
          this.loading = false;
        }
    });

      /*
      var uri = this.hitUrl + '?q=/ContainerLoadList/upload/SAST';
      this._http.post(uri,postData,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
        console.log(resp);
        var result = resp;
        if (result['status'] == 'ok') {
          var reportDate = this.postData['createdDate'].split(' ')[0].split('-')[2] + '-' + this.postData['createdDate'].split(' ')[0].split('-')[1] + '-' + this.postData['createdDate'].split(' ')[0].split('-')[0] + 'X' + this.postData['createdDate'].split(' ')[1];
          var hitUrl = this.configService.config.getPDFUrl + '/edi-util/getFile';
          var jasperURL = this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/SastDiscrepancyReport.pdf?sessionId='+result['session-id']+'&Date='+reportDate+'&userId='+this.model.userCreated;
          var fileName = 'SAST-Discrepancy-Report-'+this.model.userCreated+'.pdf';

          var postDataX = {};
          postDataX['fileName'] = fileName;
          postDataX['url'] = jasperURL;

          this.genericService.POST(hitUrl,postDataX).subscribe((resp)=>{
            if (resp.ok) {
              var result = resp.json();

              if (result['message'] == 'success') {
                this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result['filePath']);
                this.pdfContainer.showPDF();
              }

            }

            this.loading = false;
          });

        } else {
          this.message('Information','information',result['message'],'okonly',{ok:''});
          this.loading = false;
        }
      });
      */
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event === 'close-pdf') {
      this.initModel();
    }
  }

  loadPDF(){
    console.log(this.filePath)
    if(this.filePath != undefined){
      this.filePath = this.filePath.replace("/mnt","");
      console.log("* load PDF * " + this.configService.config.pdfUrl + this.filePath);
      var self = this;
      setTimeout(() => {
        self.pdfContainer.setPDFURL(self.configService.config.pdfUrl + self.filePath);
        self.pdfContainer.showPDF();
      }, 100);
    }
  }


}
