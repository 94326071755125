import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, CookieService, GenericService } from 'sibego-ui-library';

@Injectable({
  providedIn: 'root'
})
export class ProspectBookingService {
  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.193.245:13000';
  userID = this.cookieService.getName();
  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  constructor(private configService: ConfigService, private cookieService: CookieService, private genericService: GenericService, private _http: HttpClient) { }

  getProspectBooking(model) {
    const url =
      this.BASE_URL +
      `/TsBookingProspect/retrieve?pot=${model.bPot1Code}&status=${model.status}&pol=${model.bPolCode}&pod=${model.podCode}&dischargingVesselId=${model.vesselIdDischarging}&dischargingVoyage=${model.voyageDischarging}&dischargingVesselBound=${model.bound}&connectingVesselId=${model.vesselIdConnecting}&connectingVoyage=${model.voyageConnecting}&connectingVesselBound=${model.bound}&dischargingServiceCode=${model.serviceDischarging}&connectingServiceCode=${model.serviceConnecting}`
    return this.genericService.GET(url);
  }

  postInsert(modelDetail) {
    const url = this.BASE_URL + `/PortPairAllocation/prepareInsertedData?tslLoadPortCode=${modelDetail.polCode}&tslDischargePortCode=${modelDetail.podId}&tslAllocatedTeus=${modelDetail.alloted}&tslCreatedUserid=${this.userID}&tsPotCode=${modelDetail.officeCode}`
    return this.genericService.GET(url)
  }

  postCopy(model) {
    const url = this.BASE_URL + `/PortPairAllocation/findByVesselIDVoy/${model.vesselId}/${model.voyage}/O/${model.bPot1Code}`
    return this.genericService.GET(url)
  }

  rejectAllocation(data) {
    const url = this.BASE_URL + '/TsBookingProspect/reject';
    return this.genericService.POST(url, data);
  }

  saveAllocation(body) {
    const url = this.BASE_URL + '/PortPairAllocation/save';
    return this.genericService.POST(url, body);
  }

  exportProspectBooking(model) {
    const url =
      this.BASE_URL +
      `/Mreport/runReport/75/fileName/${model.bPot1Code}@${model.bPolCode}@${model.status}@${model.vesselCodeDischarging}@${model.voyageDischarging}@${model.podCode}@${model.vesselCodeConnecting}@${model.voyageConnecting}`
    
      this._http.get(url, { headers: new HttpHeaders({'Token': localStorage.getItem('token') }), responseType: 'blob' })
    return this.genericService.GET(url);
  }
}
