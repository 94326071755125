

export class ChangePassword {
    userId   : string = '';
    currentPWD   : string = '';
    newPWD  : string = '';
    confirmPWD  : string = '';

    validatorMatchPassword : boolean = true;
    passwordValidasi : boolean = true;
    
    constructor() {

    }
}


