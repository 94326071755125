import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $: any;

export class Param {
  containerNo = '';
  selected = '';
}

@Component({
  selector: 'app-transaction-bl-locked-monitoring-page',
  templateUrl: './transaction-bl-locked-monitoring-page.component.html',
  styleUrls: ['./transaction-bl-locked-monitoring-page.component.css']
})
export class TransactionBLLockedMonitoringPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: GridPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';
  frmDialogMessage = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;

  msg:string = "";
  eventToolbar:string = "";

  validatorRules = {

  };


  constructor(private router: Router, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Locked Monitoring'
    }

    this.settingGrid = {
     url: this.configService.config.BASE_API.toString() + '/BL/getLockAll/THBKK',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200},
        {header: 'Locked By', field: 'blUserId', width: 200},
        {header: '',field:'btnRemove', width:100, type:'button', labelButton: 'Remove'}
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'blNo',
      sortingDirection: 'DESC',
      editable: true,
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
   // this.grid.search = "*/*"
    this.grid.loadData();

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    this.eventToolbar = event.toString();
    switch (event) {
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }

  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  gridEvent(event) {
     //// console.log(event);
     switch (event.split('.')[0]) {
      case 'selected':
        break;
        case 'click' :
          var id:string = event.split(".")[1].split("-")[0];
          var no = Number.parseInt(event.split(".")[1].split("-")[1]);
          console.log(id + " - " + no)
          if(id == 'btnRemove'){
              //this.grid.listStore.store.splice(no-1,1);
              //this.grid.loadData();
              //console.log(this.grid.listStore.store)
              console.log("blNo = " + this.grid.listStore.store[no-1]["blNo"]);
              this.doUnlock(this.cookieService.getDefaultLocationCode(),this.grid.listStore.store[no-1]["blNo"]);
          }

          break;
      default:
        const strEvent: string = event;
        if (strEvent !== '') {
          const arr: string[] = strEvent.split('-');
          switch (arr[0]) {
            case 'edit':
              break;
            case 'dblClick':
              const dt = this.grid.getSelectedValues()[0];

              break;

            default:
              break;
          }
        }
        break;
    }
  }

  infoGrid(event){}

  doUnlock(officeCode:String, blNo: String){
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/BL/setUnlockBLById/' + officeCode + '/' + blNo).subscribe((resp)=> {
      this.grid.loadData();
    })
  }

}
