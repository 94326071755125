<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  
  <div class="ui error message" [class.hidden]="isError == false">
  
  </div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <br />
      <div class="field">
        <div class="six fields">
            <div class="five wide field">
                
                    <div class="ui raised segment">
                        <div class="two fields">
                            <div class="field">
                                <label>Container No</label>
                                <input type="text" name="containerNo" placeholder="Container No" >
                            </div>
                         
                        </div>
                    </div>

           </div>
         
         
        </div>
      </div>
      
      <div class="field" style="width:100%">
          <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
      

    </form>
  </div>  <!-- end div container -->
</form>

 
 


<footer-plugin [info]="info"></footer-plugin>
