<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 99"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-vesselCode'] == null || model['error-vesselCode'] == ''">
        {{model['error-vesselCode']}}
      </li>
      <li [class.hidden]="model['error-voyage'] == null || model['error-voyage'] == ''">
        {{model['error-voyage']}}
      </li>
      <li [class.hidden]="model['error-bound'] == null || model['error-bound'] == ''">
        {{model['error-bound']}}
      </li>
      <li [class.hidden]="model['error-bPot1Code'] == null || model['error-bPot1Code'] == ''">
        {{model['error-bPot1Code']}}
      </li>
      <li [class.hidden]="model['error-bPolCode'] == null || model['error-bPolCode'] == ''">
        {{model['error-bPolCode']}}
      </li>
      <li [class.hidden]="model['error-podCode'] == null || model['error-podCode'] == ''">
        {{model['error-podCode']}}
      </li>
      <li [class.hidden]="model['error-allocationValidator'] == null || model['error-allocationValidator'] == ''">
        {{model['error-allocationValidator']}}
      </li>
    </ul>
  </div>

  <div id="container" class="transaction-ts-transfer-pod">
    <dialog-plugin
      #dialogTransfer
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>
    <div class="ui form mt-30px">
      <div>
        <div class="four fields">
          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;" [class.error]="
                      model['error-bPot1Code'] != null &&
                      model['error-bPot1Code'] != '' &&
                      model['error-bPot1Code'] != undefined
                    ">
                <label>POT</label>
                <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)" [disableCombo]="lockPOT">
                </combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;" [class.error]="
                                  model['error-vesselCode'] != null &&
                                  model['error-vesselCode'] != '' &&
                                  model['error-vesselCode'] != undefined
                                ">
                <label>Status</label>
                <combo-plugin #cbContOpr [settings]="settingContOpr"
                  (change)="changeEventContOpr($event)"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;" [class.error]="
                                          model['error-bPolCode'] != null &&
                                          model['error-bPolCode'] != '' &&
                                          model['error-bPolCode'] != undefined
                                        ">
                <label>Load Port</label>
                <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)"
                  style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;" [class.error]="
                model['error-podCode'] != null &&
                model['error-podCode'] != '' &&
                model['error-podCode'] != undefined
              ">
                <label>Discharge Port</label>
                <combo-plugin #cbDischargePort [settings]="settingDischargePort"
                  (change)="changeEventDischargePort($event)" style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'bPodCode', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'bPodCode', '[^a-zA-Z0-9-() ]', 50)">
                </combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Discharging Vessel</label>
                <combo-plugin #cbVesselDischarging [settings]="settingVesselDischarging"
                  (change)="changeEventVesselDischarging($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Connecting Vessel</label>
                <combo-plugin #cbVesselConnecting [settings]="settingVesselConnecting"
                  (change)="changeEventVesselConnecting($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="two wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Discharging Voyage</label>
                <combo-plugin #cbVoyageDischarging [settings]="settingVoyageDischarging"
                  (change)="changeEventVoyageDischarging($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Connecting Voyage</label>
                <combo-plugin #cbVoyageConnecting [settings]="settingVoyageConnecting"
                  (change)="changeEventVoyageConnecting($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>
          </div>

          <div class="one wide field">
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;">
                <label>Bound</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                  [value]="model.bound" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="required field" style="width: 100%;">
                <label>Bound</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                  [value]="model.bound" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
          </div>

          <div class="one wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Service</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Service"
                  [value]="model.serviceDischarging" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%;">
                <label>Service</label>
                <input type="text" name="bound" style="text-transform: uppercase" placeholder="Service"
                  [value]="model.serviceConnecting" (change)="changeBound($event)"
                  (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
              </div>
            </div>
          </div>

          <div class="three wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POL: {{model.tsDischargingEtaPol}}</span>
                </div>
                <div>
                  <span>ETD at POL: {{model.tsDischargingEtdPol}}</span>
                </div>
              </div>
            </div>

            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POT: {{model.tsConnectingEtaPot}}</span>
                </div>
                <div>
                  <span>ETD at POT: {{model.tsConnectingEtdPot}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="three wide field">
            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POT: {{model.tsDischargingEtaPot}}</span>
                </div>
                <div>
                  <span>ETD at POT: {{model.tsDischargingEtdPot}}</span>
                </div>
              </div>
            </div>

            <div class="one fields" style="width: 100%;">
              <div class="field" style="width: 100%; padding: 16px;">
                <div>
                  <span>ETA at POD: {{model.tsConnectingEtaPod}}</span>
                </div>
                <div>
                  <span>ETD at POD: {{model.tsConnectingEtdPod}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div style="width: 100%; margin-top: 30px">
        <app-custom-ag-grid-angular
          #topGrid
          id="transaction-ts-transfer-pod-1"
          [isKeepPreviousFilterModel]="true"
          (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)"
          (selectionChanged)="onSelectionChanged($event)"
          [getRowStyle]="getRowStyle"
          [rowData]="rowData"
          [rowMultiSelectWithClick]="true"
          [defaultColumnDefs]="defaultColumnDefs"
          [rowSelection]="'multiple'"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize]="true"
          [isRowSelectable]="isRowSelectable"
          (cellValueChanged)="onCellValueChanged($event)"
          [isRowSelectable]="isRowSelectable"
          (rowSelected)="handleRowSelected($event)"
          [enableBrowserTooltips]="true"
        >
        </app-custom-ag-grid-angular>
      </div>

      <div style="width: 100%; margin: 50px 0px">
        <div style="padding-bottom: 15px; font-weight: 700">
          Port-Pair Allocation summary of selected Connecting Vessel / Voyage
        </div>
        <app-custom-ag-grid-angular
          #topGridPortPair
          width="60%"
          height="180px"
          id="transaction-ts-transfer-pod-2"
          (gridReady)="onGridPortReady($event)"
          [rowData]="rowDataPortPair"
          [defaultColumnDefs]="defaultColumnDefsGridPortPair"
          [rowSelection]="'multiple'"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize]="true"
          [rowMultiSelectWithClick]="true"
        >
        </app-custom-ag-grid-angular>
      </div>
    </div>
  </div>
</div>

<!-- POT Remarks / Outward Slot /Reason Dialog-->
<div id="DialogContent" class="dialogContent">
  <div class="ui error message" *ngIf="messageValidateDialog">
    <span>{{messageValidateDialog}}</span>
  </div>
  <textarea name="" id="" rows="6" [(ngModel)]="valueDialog" (ngModelChange)="valueDialogChange($event)"
    [maxlength]="maxlengthDialog">
  </textarea>
  <p>
    {{noteContent}}
  </p>
  <div class="footer-dialogContent">
    <div class="footer-btn-event">
      <button class="ui tiny button" (click)="saveDialog()">Insert</button>
      <button class="ui tiny button" (click)="cancelDialog()">Cancel</button>
      <button class="ui tiny button" (click)="closeDialogRemarksOutReason()">Close</button>
    </div>
  </div>
</div>


<!-- TransferPodDialog -->
<div id="TransferPodDialog" class="d-none">
  <app-transfer-pod-dialog (closeRenominateDialog)="closeRenominateDialog($event)"></app-transfer-pod-dialog>
</div>
