<div class="ui segment"> <div class="ui divided items">
  <toolbar-plugin #pfcToolbar [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <!--Error Message Block-->
  <div class="ui error message" [class.hidden]="isErrorFlag == false">
    <ul>
      <li [class.hidden]="model['error-pfcRecordId'] == null || model['error-pfcRecordId'] == ''">
        {{model['error-pfcRecordId']}}
      </li>
    </ul>
  </div>
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-pfcFreightPartyId'] == null || model['error-pfcFreightPartyId'] == ''">
        {{model['error-pfcFreightPartyId']}}
      </li>
      <li [class.hidden]="model['error-pfcPlaceOfReceiptCode'] == null || model['error-pfcPlaceOfReceiptCode'] == ''">
        {{model['error-pfcPlaceOfReceiptCode']}}
      </li>
      <li [class.hidden]="model['error-pfcLoadPortCode'] == null || model['error-pfcLoadPortCode'] == ''">
        {{model['error-pfcLoadPortCode']}}
      </li>
      <li [class.hidden]="model['error-pfcDischargePortCode'] == null || model['error-pfcDischargePortCode'] == ''">
        {{model['error-pfcDischargePortCode']}}
      </li>
      <li [class.hidden]="model['error-pfcPlaceOfDeliveryCode'] == null || model['error-pfcPlaceOfDeliveryCode'] == ''">
        {{model['error-pfcPlaceOfDeliveryCode']}}
      </li>
      <li [class.hidden]="model['error-pfcReceiptTypeCode'] == null || model['error-pfcReceiptTypeCode'] == ''">
        {{model['error-pfcReceiptTypeCode']}}
      </li>
      <li [class.hidden]="model['error-pfcDeliveryTypeCode'] == null || model['error-pfcDeliveryTypeCode'] == ''">
        {{model['error-pfcDeliveryTypeCode']}}
      </li>
      <li [class.hidden]="model['error-pfcEffectiveFrom'] == null || model['error-pfcEffectiveFrom'] == ''">
        {{model['error-pfcEffectiveFrom']}}
      </li>
      <li [class.hidden]="model['error-pfcEffectiveTo'] == null || model['error-pfcEffectiveTo'] == ''">
        {{model['error-pfcEffectiveTo']}}
      </li>
      <li [class.hidden]="model['error-pfcAcctPic'] == null || model['error-pfcAcctPic'] == ''">
          {{model['error-pfcAcctPic']}}
        </li>
      <li [class.hidden]="model['error-effectiveDateExceedValidator'] == null || model['error-effectiveDateExceedValidator'] == ''">
          {{model['error-effectiveDateExceedValidator']}}
      </li>
      <li [class.hidden]="model['error-expiryDateValidator'] == null || model['error-expiryDateValidator'] == ''">
          {{model['error-expiryDateValidator']}}
      </li>
      <li [class.hidden]="model['error-samePORValidator'] == null || model['error-samePORValidator'] == ''">
          {{model['error-samePORValidator']}}
      </li>
      <li [class.hidden]="model['error-samePOLValidator'] == null || model['error-samePOLValidator'] == ''">
          {{model['error-samePOLValidator']}}
      </li>
      <li [class.hidden]="model['error-samePODValidator'] == null || model['error-samePODValidator'] == ''">
          {{model['error-samePODValidator']}}
      </li>
      <li [class.hidden]="model['error-samePODLValidator'] == null || model['error-samePODLValidator'] == ''">
          {{model['error-samePODLValidator']}}
      </li>
      <li [class.hidden]="model['error-sameFDestValidator'] == null || model['error-sameFDestValidator'] == ''">
          {{model['error-sameFDestValidator']}}
      </li>
      <li [class.hidden]="model['error-blankFreightChargesValidator'] == null || model['error-blankFreightChargesValidator'] == ''">
          {{model['error-blankFreightChargesValidator']}}
      </li>
      <li [class.hidden]="model['error-missingMainChargesValidator'] == null || model['error-missingMainChargesValidator'] == ''">
        {{model['error-missingMainChargesValidator']}}
      </li>
      <li [class.hidden]="model['error-mainChargesOnlyOneValidator'] == null || model['error-mainChargesOnlyOneValidator'] == ''">
        {{model['error-mainChargesOnlyOneValidator']}}
      </li>
      <li [class.hidden]="model['error-blankDetentionRequestValidator'] == null || model['error-blankDetentionRequestValidator'] == ''">
          {{model['error-blankDetentionRequestValidator']}}
      </li>
      <li [class.hidden]="model['error-blankDemurrageRequestValidator'] == null || model['error-blankDemurrageRequestValidator'] == ''">
          {{model['error-blankDemurrageRequestValidator']}}
      </li>
      <li [class.hidden]="model['error-blankAdhocVoyageValidator'] == null || model['error-blankAdhocVoyageValidator'] == ''">
          {{model['error-blankAdhocVoyageValidator']}}
      </li>
    </ul>
  </div>
  <!--End of Error Message Block-->

  <form class="ui form">
    <button class="ui button" style="display:none;"></button>

    <div [style.display]="modeForm=='freightFlagStatus'?'block':'none'" style="width:100%"  >
      <div id='container'>
        <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div class="ui top attached tabular menu" style="background-color:beige;">
          <a class="item active"  data-tab="General" (click)=initTab1()>General</a>
          <a class="item"   data-tab="Terminal" (click)=initTab2()>Terminal</a>
        </div>

        <!-- data for tab general must goes here -->
        <div class="ui bottom attached tab segment active" id="GeneralTab" data-tab="General" >
          <a class="ui red ribbon label">Search Criteria</a>
          <!-- Row 1 start -->
          <div class="four fields" style="margin-bottom:0px;">
            <div class="field">
              <label>Freight Party</label>
              <combo-plugin #cbFreightParty [settings]="settingCbFreightParty"   (change)="changeFreightParty($event);" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Container Ownership</label>
              <combo-plugin  #cbContainerOwnership [settings]="settingCbContainerOwnership"   (change)="changeContainerOwnership($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
                <label>Effective From</label><!--settingEffectiveFrom-->
                <datetime-plugin  #dateEffectiveFrom [settings]="settingDateEffectiveFrom"   [disableDatetime]="lockEffectiveFrom"></datetime-plugin>
              </div>
              <div class="field" style="padding-right:5px;">
                <div class="field">
                  <label>Expiry On</label>
                  <datetime-plugin  #dateEffectiveTo [settings]="settingDateEffectiveTo"   [disableDatetime]="lockEffectiveTo"></datetime-plugin>
                </div>
              </div>
          </div>
          <!-- Row 1 end -->

          <!-- Row 2 start -->
          <div class="four fields" style="margin-bottom:0px;">
            <div class="field">
              <label>Place of Receipt</label>
              <combo-plugin  #cbPlaceOfReceipt [settings]="settingCbPlaceOfReceipt"   (change)="changePlaceOfReceipt($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Port of Load</label>
              <combo-plugin #cbPortOfLoad [settings]="settingCbPortOfLoad"  (change)="changePortOfLoad($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Container Type</label>
              <combo-plugin #cbContainerType [settings]="settingCbContainerType" (change)="changeContainerType($event)"    [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field" >
              <label>Container Status</label>
              <combo-plugin #cbContainerStatus [settings]="settingCbContainerStatus"   (change)="changeContainerStatus($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
          </div>
          <!-- Row 2 end -->

          <!-- Row 3 start -->
          <div class="four fields" style="margin-bottom:0px;">
            <div class="field">
              <label>Port of Discharge</label>
              <combo-plugin #cbPortOfDischarge [settings]="settingCbPortOfDischarge"    (change)="changePortOfDischarge($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Place of Delivery</label>
              <combo-plugin #cbPlaceOfDelivery [settings]="settingCbPlaceOfDelivery"   (change)="changePlaceOfDelivery($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Receipt Type</label>
              <combo-plugin #cbReceiptType [settings]="settingCbReceiptType"   (change)="changeReceiptType($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field" >
              <label>DG Class</label>
              <combo-plugin  #cbDGClass [settings]="settingCbDGClass"   (change)="changeDGClass($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
          </div>
          <!-- Row 3 end -->

          <!-- Row 4 start -->
          <div class="four fields" style="margin-bottom:0px;">
            <div class="field">
              <label>Port of Transshipment (1)</label>
              <combo-plugin  #cbPortOfTransshipment [settings]="settingCbPortOfTransshipment"   (change)="changePortOfTransshipment($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Final Destination</label>
              <combo-plugin  #cbFinalDestination [settings]="settingCbFinalDestination"   (change)="changeFinalDestination($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Delivery Type</label>
              <combo-plugin #cbDeliveryType [settings]="settingCbDeliveryType"  value="CY" (change)="changeDeliveryType($event)" [disableCombo]="checkLock==true"></combo-plugin>
            </div>
            <div class="field">
              <label>Valid/Invalid</label>
              <combo-plugin #cbValidInvalid [settings]="settingCbValidInvalid" (change)="changeValidInvalid($event)" [disableCombo]="checkLock==true"></combo-plugin>

              <!--
                -->
            </div>
          </div>
          <!-- Row 4 end -->
          <!-- Row 5 start -->
          <div class="four fields" style="margin-bottom:0px;">
            <div class="field">
                <div class="ui checkbox" style="margin-bottom: 10px;" >
                    <input [checked]="model['pfcSerchByRefNo'] == 'Y'" value="Y"  type="checkbox" (change)="changeEventRetrieveByRefNo($event)"  name="checkbox" >
                    <label>Retrieve by Reference Number</label>
                </div>
                <input type="text" [(ngModel)]="model['pfcRecordId']" name="pfcRecordId" placeholder="Ref No." [disabled]="checkLock == false" maxlength="30">
            </div>
            <div class="field" style="margin-top:15px">
                <label class="hidden">Test</label>
                <div class="ui checkbox" >
                    <input id="isDeleted" type="checkbox" (change)="changeIsDeleted($event)" >
                    <label>Deleted&nbsp;&nbsp;&nbsp;</label>
                </div>
                <div class="ui checkbox" >
                    <input id="isCommissionable" type="checkbox" (change)="changeIsCommissionable($event)" >
                    <label>Commissionable</label>
                </div>
            </div>
            <div class="field">
              <div class="field">
                <label>Pending/Rejected/Approved</label>
                <combo-plugin #cbAppStatus [settings]="settingCbAppStatus" (change)="changeEventCbAppStatus($event)" [disableCombo]="checkLock==true"></combo-plugin>
              </div>
            </div>
            <div class="field"></div>
          </div>
          <!-- Row 5 end -->
        </div><!-- end of tab general -->

        <!-- Start of Tab Terminnal -->
        <div class="ui bottom attached tab segment" id="TerminalTab" data-tab="Terminal">
          <a class="ui red ribbon label">Search Criteria</a>

          <!-- Row 1 start -->
          <div class="two fields">
            <div class="field">
              <label>POR Terminal</label>
              <combo-plugin #cbPORTerminal [settings]="settingCbPORTerminal"   (change)="changePORTerminal($event)"></combo-plugin>
            </div>
            <div class="field">
              <label>PODL Terminal</label>
              <combo-plugin #cbPODLTerminal [settings]="settingCbPODLTerminal"   (change)="changePODLTerminal($event)" ></combo-plugin>
            </div>
          </div>
          <!-- Row 1 end-->

          <!-- Row 2 start -->
          <div class="two fields">
            <div class="field">
              <label>POL Terminal</label>
              <combo-plugin #cbPOLTerminal [settings]="settingCbPOLTerminal"   (change)="changePOLTerminal($event)" ></combo-plugin>
            </div>
            <div class="field">
              <label>POT1 Terminal</label>
              <combo-plugin #cbPOT1Terminal [settings]="settingCbPOT1Terminal"   (change)="changePOT1Terminal($event)" ></combo-plugin>
            </div>
          </div>
          <!-- Row 2 end-->

          <!-- Row 3 start -->
          <div class="two fields">
            <div class="field">
              <label>POD Terminal</label>
              <combo-plugin #cbPODTerminal [settings]="settingCbPODTerminal"   (change)="changePODTerminal($event)"></combo-plugin>
            </div>
            <div class="field">
              <label>FDEST Terminal</label>
              <combo-plugin #cbFDESTTerminal [settings]="settingCbFDESTTerminal"   (change)="changeFDESTTerminal($event)" ></combo-plugin>
            </div>
          </div>
          <!-- Row 3 end-->
        </div>


        <a class="ui red ribbon label">Search Result</a>
        <div class="field">
          <grid-plugin #mainGrid [settings]="settingMainGrid" (gridEvent)="mainGridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        </div>
      </div> <!-- End of container -->
    </div> <!-- End of flag status form control -->




    <div [style.display]="modeForm=='freightMaintenance'?'block':'none'" >
      <div class="ui top attached tabular menu" style="background-color:beige;">
        <a class="item active" data-tab="maintenanceGeneralTab">General  &nbsp; <span style="color: red;text-transform: uppercase">{{appStatusLabel}}</span></a>
        <a class="item"   data-tab="maintenanceTerminalTab" >Terminal</a>
      </div>


      <!-- Maintenance General Tab Start -->
      <div class="ui bottom attached tab segment active" id="maintenanceGeneralTab" data-tab="maintenanceGeneralTab" >

      <div class="field">

          <div class="ui inverted dimmer active" style="z-index:1;opacity:0.5;padding-top:15%;" [class.hidden]="lock == false">
             <i class="lockicon circular inverted large lock icon"></i>
          </div>

          <div class="fields"> <!-- divide into 4 sections -->
            <div class="three wide field">               <!-- 1st Pane from Left Start -->

              <div class="required field" [class.error]="model['error-pfcFreightPartyId'] != null &&  model['error-pfcFreightPartyId'] != ''">
                <label style="color:red">Freight Party</label>
                <combo-plugin class="toDisable" #cbMaintenanceFreightParty  [settings]="settingCbMaintenanceFreightParty" (change)="changeCbMaintenanceFreightParty($event)"  [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field">
                <label>Shipper</label>
                <combo-plugin class="toDisable" #cbMaintenanceShipper  [settings]="settingCbMaintenanceShipper" (change)="changeCbMaintenanceShipper($event)"   [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="required field" [class.error]="model['error-pfcPlaceOfReceiptCode'] != null && model['error-pfcPlaceOfReceiptCode'] != ''">
                <label style="color:red">Place Of Receipt</label>
                <combo-plugin class="toDisable" #cbMaintenancePlaceOfReceipt [settings]="settingCbMaintenancePlaceOfReceipt"   (change)="changeCbMaintenancePlaceOfReceipt($event)"  [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="required field" [class.error]="model['error-pfcLoadPortCode'] != null && model['error-pfcLoadPortCode'] != ''">
                <label style="color:red">Port Of Load</label>
                <combo-plugin class="toDisable" #cbMaintenancePortOfLoad  [settings]="settingCbMaintenancePortOfLoad"   (change)="changeCbMaintenancePortOfLoad($event)"   [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="required field" [class.error]="model['error-pfcDischargePortCode'] != null && model['error-pfcDischargePortCode'] != ''">
                <label style="color:red">Port Of Discharge</label>
                <combo-plugin class="toDisable" #cbMaintenancePortOfDischarge    [settings]="settingCbMaintenancePortOfDischarge"   (change)="changeCbMaintenancePortOfDischarge($event)"  [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="required field" [class.error]="model['error-pfcPlaceOfDeliveryCode'] != null && model['error-pfcPlaceOfDeliveryCode'] != ''">
                <label style="color:red">Place Of Delivery</label>
                <combo-plugin class="toDisable" #cbMaintenancePlaceOfDelivery [settings]="settingCbMaintenancePlaceOfDelivery"   (change)="changeCbMaintenancePlaceOfDelivery($event)" [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field">
                <label>Final Destination</label>
                <combo-plugin class="toDisable" #cbMaintenanceFinalDestination [settings]="settingCbMaintenanceFinalDestination"   (change)="changeCbMaintenanceFinalDestination($event)" [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field">
                <label>Port Of Transshipment(1) </label>
                <combo-plugin #cbMaintenancePOT1 [settings]="settingCbMaintenancePOT1"   (change)="changeCbMaintenancePOT1($event)" [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field" style="margin-bottom: 5px;" >
                <label>Port Of Transshipment(2) </label>
                <combo-plugin #cbMaintenancePOT2  [settings]="settingCbMaintenancePOT2"   (change)="changeCbMaintenancePOT2($event)" [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field" style="margin-bottom: 5px;" >
                <label>Port Of Transshipment(3) </label>
                <combo-plugin #cbMaintenancePOT3   [settings]="settingCbMaintenancePOT3"   (change)="changeCbMaintenancePOT3($event)" [disableCombo]="lock==true"></combo-plugin>
              </div>

              <div class="field">
                <fieldset style="border: 1px solid lightgray;margin-bottom:5px; margin-top:10px">
                  <legend style="font-weight:bold;">Misc</legend>
                  <div class="field" style="margin-bottom: 5px;" >
                    <div class="field">
                      <label>Commodity</label>
                      <combo-plugin  #cbMaintenanceCommodity [settings]="settingCbMaintenanceCommodity"   (change)="changeCbMaintenanceCommodity($event)" ></combo-plugin>
                    </div>
                  </div>
                  <div class="field" style="margin-bottom: 5px;">
                    <label>Weight</label>
                    <input  type="number" [(ngModel)]="model['pfcWeight']" name="pfcWeight" placeholder="Weight" maxlength="50" style="border:1px solid gray;text-align: right" disabled >
                  </div>
                </fieldset>
              </div>

            </div>      <!-- 1st Pane from Left End -->

            <div class="four wide field">    <!-- 2nd Pane from Left Start -->
              <div class="field">
                <div class="fields">
                  <div class="eight wide field">
                    <div class="required field" [class.error]="model['error-pfcReceiptTypeCode'] != null && model['error-pfcReceiptTypeCode'] != ''">
                      <label style="font-size: 13px">Receipt Type</label>
                      <combo-plugin #cbMaintenanceReceiptType [settings]="settingCbMaintenanceReceiptType"  (change)="changeCbMaintenanceReceiptType($event)" [disableCombo]="lock==true"></combo-plugin>
                    </div>
                  </div>
                  <div class="eight wide field">
                    <div class="required field" [class.error]="model['error-pfcDeliveryTypeCode'] != null && model['error-pfcDeliveryTypeCode'] != ''">
                      <label style="font-size: 13px">Delivery Type</label>
                      <combo-plugin #cbMaintenanceDeliveryType [settings]="settingCbMaintenanceDeliveryType"  (change)="changeCbMaintenanceDeliveryType($event)" [disableCombo]="lock==true"></combo-plugin>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <fieldset style="border: 1px solid lightgray">
                  <legend style="font-weight:bold;color:red">Container Ownership*</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerOwnership'] == 'COC'" value="COC" (change)="changeEventPfcContainerOwnership($event)" name="pfcContainerOwnership" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>COC</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerOwnership'] == 'COCTS'" value="COCTS" (change)="changeEventPfcContainerOwnership($event)" name="pfcContainerOwnership" type="radio" tabindex="0"  class="hidden" [disabled]="lock == true">
                          <label>COC T/S</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerOwnership'] == 'SOC'" value="SOC" (change)="changeEventPfcContainerOwnership($event)" name="pfcContainerOwnership" type="radio" tabindex="0"  class="hidden" [disabled]="lock == true">
                          <label>SOC</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerOwnership'] == 'SOCTS'" value="SOCTS" (change)="changeEventPfcContainerOwnership($event)" name="pfcContainerOwnership" type="radio" tabindex="0"   class="hidden" [disabled]="lock == true">
                          <label>SOC T/S</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerOwnership'] == 'SLOT'" value="SLOT" (change)="changeEventPfcContainerOwnership($event)" name="pfcContainerOwnership" type="radio" tabindex="0"  class="hidden" [disabled]="lock == true">
                          <label>SLOT</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui checkbox">
                          <input [checked]="model['pfcIsCommission'] == 'Y'" type="checkbox" (change)="changeEventPfcIsCommission($event)" [disabled]="lock == true"  >
                          <label>Commissionable</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="field" style="margin-top: 10px">
                <fieldset style="border: 1px solid lightgray">
                  <legend style="font-weight:bold;color:red;">Container Status*</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerStatus'] == 'EMPTY'" value="EMPTY"   (change)="changeEventPfcContainerStatus($event)" name="pfcContainerStatus" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>Empty</label>
                        </div>
                      </div>

                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerStatus'] == 'FULL'" value="FULL"   (change)="changeEventPfcContainerStatus($event)" name="pfcContainerStatus" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>Full</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field" >
                    <fieldset style="border: 1px solid lightgray">
                      <legend style="font-weight:bold;font-size: 13px">Weight Class</legend>
                      <div class="field">
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="field">
                            <div class="ui radio checkbox" >
                              <input [checked]="model['pfcWeightClass'] == 'N'" value="N"   (change)="changeEventPfcWeightClass($event)" name="pfcWeightClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockFullEmpty == true">
                              <label style="font-size: 12px">N/A</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field" >
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="eight wide field">
                            <div class="ui radio checkbox" >
                              <input [checked]="model['pfcWeightClass'] == 'L'" value="L"   (change)="changeEventPfcWeightClass($event)" name="pfcWeightClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockFullEmpty == false">
                              <label style="font-size: 12px">Light (10000 KGS)</label>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcWeightClass'] == 'H'" value="H"   (change)="changeEventPfcWeightClass($event)" name="pfcWeightClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockFullEmpty == false">
                              <label style="font-size: 12px">Heavy (20000 KGS)</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field" >
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcWeightClass'] == 'M'" value="M"   (change)="changeEventPfcWeightClass($event)" name="pfcWeightClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockFullEmpty == false">
                              <label style="font-size: 12px">Medium (15000 KGS)</label>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcWeightClass'] == 'X'" value="X"   (change)="changeEventPfcWeightClass($event)" name="pfcWeightClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockFullEmpty == false">
                              <label style="font-size: 12px">Extra Heavy (24000)</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </fieldset>
              </div>

              <div class="field" style="margin-top: 10px">
                <fieldset style="border: 1px solid lightgray">
                  <legend style="font-weight:bold;">Shipment Type</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'N'" value="N"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>N/A</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'D'" value="D"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>RD-TRIP</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'I'" value="I"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>IRE</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'S'" value="S"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>SPECIAL</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'R'" value="R"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>ROB</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcShipmentType'] == 'C'" value="C"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>CRB</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox">
                      <input [checked]="model['pfcShipmentType'] == 'T'" value="T"   (change)="changeEventPfcShipmentType($event)" name="pfcShipmentType" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                      <label>THRU</label>
                    </div>
                  </div>
                </fieldset><!--Shipment Type -->
              </div>

              <div class="field" style="margin-top: 10px">
                <fieldset style="border: 1px solid lightgray" >
                  <legend style="font-weight:bold;" disabled=true >Ad-HOC Shipment</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="field">
                          <label>Vessel</label>
                          <combo-plugin  #cbMaintenanceAdhocVessel [settings]="settingCbMaintenanceAdhocVessel" (change)="changeCbMaintenanceAdhocVessel($event)" [disableCombo]="lock == true"></combo-plugin>
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="field" [class.error]="model['error-blankAdhocVoyageValidator'] != null && model['error-blankAdhocVoyageValidator'] != ''" >
                          <label>Voyage</label>
                          <combo-plugin class="toDisable" #cbMaintenanceAdhocVoyage [settings]="settingCbMaintenanceAdhocVoyage" (change)="changeCbMaintenanceAdhocVoyage($event)" [disableCombo]="lock == true"></combo-plugin>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="field" >
                          <label>ETA</label>
                          <input type="text" [(ngModel)]="model['pfcLocalEta']" name="pfcLocalEta" placeholder="ETA" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray" >
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="field" >
                          <label>ETD</label>
                          <input  type="text"  [(ngModel)]="model['pfcLocalEtd']" name="pfcLocalEtd" placeholder="ETD" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray"  >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <div class="field" >
                          <label>ATB</label>
                          <input  type="text" [(ngModel)]="model['pfcLocalAta']" name="pfcLocalAta" placeholder="ATA" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray" >
                        </div>
                      </div>
                      <div class="eight wide field">
                        <div class="field" >
                          <label>ATD</label>
                          <input   type="text" [(ngModel)]="model['pfcLocalAtd']" name="pfcLocalAtd" placeholder="ATD" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray" >
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset><!--Ado-HOC Shipment -->
              </div>
            </div>     <!-- 2nd Pane from Left End -->

            <div class="five wide field">    <!-- 3rd Pane from Left Start -->
              <div class="field">
                <div class="fields">
                  <div class="eight wide field">
                    <div class="required field" [class.error]="model['error-pfcEffectiveFrom'] != null && model['error-pfcEffectiveFrom'] != ''">
                      <label style="color:red">Effective From</label>
                      <datetime-plugin  #dateMaintenanceEffectiveFrom [settings]="settingDateMaintenanceEffectiveFrom"  [disableDatetime]="lock == true" ></datetime-plugin>
                    </div>
                  </div>
                  <div class="eight wide field">
                    <div class="required field" [class.error]="model['error-pfcEffectiveTo'] != null && model['error-pfcEffectiveTo'] != ''">
                      <label style="color:red">Expiry On</label>
                      <datetime-plugin  class="toDisable" #dateMaintenanceEffectiveTo [settings]="settingDateMaintenanceEffectiveTo" [disableDatetime]="lock == true" ></datetime-plugin>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;color:red;">Container Type*</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input  [checked]="model['pfcContainerType'] == 'STD'" value="STD"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden" > <!--[disabled]="lock == true"-->
                          <label>Normal</label>
                        </div>
                      </div>
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'TNK'" value="TNK"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden" > <!-- [disabled]="lock == true"-->
                          <label>Tank</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'REF'" value="REF"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden" > <!--[disabled]="lock == true"-->
                          <label>Reefer</label>
                        </div>
                      </div>
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'DRY'" value="DRY"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden" > <!--[disabled]="lock == true"-->
                          <label>Reefer Dry</label>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'OPT'" value="OPT"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden" > <!--[disabled]="lock == true"-->
                          <label>Open Top</label>
                        </div>
                      </div>
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'UC'" value="UC"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType" type="radio" tabindex="0" class="hidden"> <!--[disabled]="lock == true"-->
                          <label>UC</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>InG</label>
                        </div>
                      </div>
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>OutG</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'FLR'" value="FLR"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType"  type="radio" tabindex="0" class="hidden"> <!--[disabled]="lock == true"-->
                          <label>Flat Rack</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                       &nbsp;
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>InG</label>
                        </div>
                      </div>
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>OutG</label>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field" style="z-index:2;">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcContainerType'] == 'PLT'" value="PLT"   (change)="changeEventPfcContainerType($event)" name="pfcContainerType"  type="radio" tabindex="0" class="hidden"> <!--[disabled]="lock == true"-->
                          <label>Platform</label>
                        </div>
                      </div>
                      <div class="eight wide field">
                        &nbsp;
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>InG</label>
                        </div>
                      </div>
                      <div class="four wide field">
                        <div class="ui test checkbox">
                          <input name="containerType" type="checkbox" tabindex="0" class="hidden" disabled=true>
                          <label>OutG</label>
                        </div>
                      </div>
                    </div>
                  </div>


                </fieldset><!--Container Type-->
              </div>

              <div class="field">
                <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;">DG Details</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="four wide field">
                        <label style="font-size: 13px">UNNO</label>
                        <input  type="text"  maxlength="10" disabled style="background-color: lightgray">
                      </div>
                      <div class="four wide field">
                        <label style="font-size: 12px">Flash Pt.</label>
                        <input type="text"  maxlength="10"  disabled style="background-color: lightgray">
                      </div>
                      <div class="four wide field">
                        <label style="font-size: 13px">IMO</label>
                        <input type="text"  maxlength="10"  disabled style="background-color: lightgray">
                      </div>
                      <div class="four wide field">
                        <label style="font-size: 13px">DGDesk</label>
                        <input type="text"  maxlength="10"  disabled style="background-color: lightgray">
                      </div>
                    </div>
                  </div>

                  <div class="field" style="margin-top: 10px">
                    <fieldset>
                      <legend style="font-weight:bold;">DG Class</legend>
                      <div class="field">
                        <div class="fields" style="margin-bottom:0px;">
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDgClass'] == 'NA'" value="NA"   (change)="changeEventPfcDgClass($event)" name="pfcDgClass"  type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>N/A</label>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDgClass'] == 'C3'" value="C3"   (change)="changeEventPfcDgClass($event)" name="pfcDgClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Class 3</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="fields" style="margin-bottom:0px;">
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDgClass'] == 'C2'" value="C2"   (change)="changeEventPfcDgClass($event)" name="pfcDgClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Class 2</label>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDgClass'] == 'C2F'" value="C2F"   (change)="changeEventPfcDgClass($event)" name="pfcDgClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Class 2F</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="fields" style="margin-bottom:0px;">
                          <div class="eight wide field">
                            <div class="ui radio checkbox">
                              <input  [checked]="model['pfcDgClass'] == 'C1'" value="C1"   (change)="changeEventPfcDgClass($event)" name="pfcDgClass" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Class 1</label>
                            </div>
                          </div>
                          <div class="eight wide field">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                </fieldset><!--DGClass -->
              </div>

              <div class="field" style="margin-top: 10px">
                <fieldset style="border: 1px solid lightgray">
                  <legend style="font-weight:bold;">IHC</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px;">
                      <div class="three wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcIhc'] == 'N'" value="N"   (change)="changeEventPfcIhc($event)" name="pfcIhc"  type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>N/A</label>
                        </div>
                      </div>
                      <div class="five wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcIhc'] == 'S'" value="S"   (change)="changeEventPfcIhc($event)" name="pfcIhc" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>Shipper</label>
                        </div>
                      </div>
                      <div class="five wide field">
                        <div class="ui radio checkbox">
                          <input [checked]="model['pfcIhc'] == 'C'" value="C"   (change)="changeEventPfcIhc($event)" name="pfcIhc" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                          <label>Consignee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset><!--IHC -->
              </div>

              <div class="field" style="margin-top: 10px">
                <fieldset style="border: 1px solid lightgray;" >
                  <legend style="font-weight:bold;" disabled=true >Prospect Volume (Per MTH)</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px;">
                      <div class="five wide field">
                        <label>D20</label>
                        <input  type="number" min="0" [(ngModel)]="model['pfcProspectD20']" name="pfcProspectD20" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10"  style="text-align: right" [disabled]="lock == true">
                      </div>
                      <div class="five wide field">
                        <label>D40</label>
                        <input type="number" min="0" [(ngModel)]="model['pfcProspectD40']" name="pfcProspectD40"  onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true" >
                      </div>
                      <div class="five wide field">
                        <label>D45</label>
                        <input type="number" min="0" [(ngModel)]="model['pfcProspectD45']" name="pfcProspectD45"  onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true" >
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px;">
                        <div class="five wide field">
                          <label>H20</label>
                          <input type="number" min="0" [(ngModel)]="model['pfcProspectH20']" name="pfcProspectH20" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true" >
                        </div>
                        <div class="five wide field">
                          <label>H40</label>
                          <input type="number" min="0" [(ngModel)]="model['pfcProspectH40']" name="pfcProspectH40" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true" >
                        </div>
                        <div class="five wide field">
                          <label>H45</label>
                          <input type="number" min="0" [(ngModel)]="model['pfcProspectH45']" name="pfcProspectH45" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true" >
                        </div>
                      </div>
                    </div>
                  </div>

                </fieldset><!--Prospect Booking -->
              </div>
            </div>         <!-- 3rd Pane from Left End -->

            <div class="four wide field">   <!-- 4th Pane from Left Start -->
              <div class="field">
                <div class="two fields" style="margin-bottom: 0px">
                  <div class="field">
                    <label>Reference No</label>
                    <input  type="text" [(ngModel)]="model['pfcRecordId']" name="pfcRecordId" placeholder="Reference Number" maxlength="30" style="border:1px solid gray;" readonly>
                  </div>

                  <div  class="field" style="z-index:2;">
                      <label class="hidden">T</label>
                     <div class="ui checkbox" >
                      <input id="invalidcheckbox" type="checkbox" [checked]="model['pfcIsValid']== 'N'" (change)="changeInvalid($event)" [disabled]="model['disableInvalid'] == 'true'"> <!--lock == true || -->
                      <label>Invalid</label>
                     </div>
                  </div>
                </div>
              </div>


              <div class="field">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="eight wide field">
                    <label>User ID</label>
                    <input type="text"  placeholder="User ID" maxlength="50" style="border:1px solid gray;" disabled>
                  </div>
                  <div class="eight wide required field" [class.error]="model['error-pfcAcctPic'] != null && model['error-pfcAcctPic'] != ''" style="margin-bottom: 5px;">
                    <label >Acct PIC</label>
                    <combo-plugin #cbAcctPic [settings]="settingCbAcctPIC" (change)="changeAcctPIC($event);" [disableCombo]="lock == true"></combo-plugin>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="eight wide field">
                    <label>Created By</label>
                    <input  type="text" [(ngModel)]="model['pfcUserCreated']" name="pfcUserCreated" placeholder="Created By" maxlength="50" style="border:1px solid gray;" readonly>
                  </div>
                  <div class="eight wide field">
                    <label>Created Date</label>
                    <input  type="text" [(ngModel)]="model['pfcDateCreated']" name="pfcDateCreated" placeholder="Created Date" maxlength="50" style="border:1px solid gray;" readonly>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="fields" style="margin-bottom: 0px">
                  <div class="eight wide field">
                    <label style="font-size: 11px">Last Modified By</label>
                    <input   type="text" [(ngModel)]="model['pfcUserModified']" name="pfcUserModified" placeholder="Last Modified By" maxlength="50" style="border:1px solid gray;" readonly>
                  </div>

                  <div class="eight wide field">
                    <label style="font-size: 11px">Last Modified Date</label>
                    <input  type="text" [(ngModel)]="model['pfcDateModified']" name="pfcDateModified" placeholder="Last Modified Date" maxlength="50" style="border:1px solid gray;" readonly>
                  </div>
                </div>
              </div>

              <div class="field" style="margin-top: 10px;">
                <fieldset style="border: 1px solid lightgray;" disabled=true>
                  <legend style="font-weight:bold">POL (For Info Only)</legend>
                  <div class="field">
                    <div class="fields">
                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray"><!---->
                          <legend style="font-weight:bold">Detention</legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input checked="checked" name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input  name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>Standard</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox" style="width:10%;margin-top:1px;">
                                  <input  name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>Request</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <input  id="maintenance_podDetentionRequest" type="number"  name="poddetentionRequest" placeholder="0" maxlength="20" [disabled]="lock == false">
                              </div>
                            </div>
                          </div>
                        </fieldset>    <!--pod detention group-->
                      </div>
                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray;" ><!--width: 56%;-->
                          <legend style=" font-weight:bold;">Demurrage</legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input checked="checked" name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input  name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>Standard</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox" style="margin-top:1px;">
                                  <input  name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>Request</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <input  id="maintenance_podDemRequest" type="number"  name="poddemurrageRequest" placeholder="0" maxlength="20" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);" [disabled]="lock == false">
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </fieldset> <!-- for POL-->
              </div>

              <div class="field" style="margin-top: 10px;">
                <fieldset style="border: 1px solid lightgray;" >
                  <legend style="font-weight:bold">POD</legend>
                  <div class="field">
                    <div class="fields" style="margin-bottom: 0px">
                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray">
                          <legend style=" font-weight:bold">Detention</legend>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDetention'] == 'N'" value="N"   (change)="changeEventPfcDetention($event)" name="pfcDetention"   type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockDetention == false">
                              <label>N/A</label>
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDetention'] == 'S'" value="S"   (change)="changeEventPfcDetention($event)" name="pfcDetention"  type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Standard</label>
                            </div>
                          </div>

                          <div class="field">
                            <div class="ui radio checkbox" style="margin-top:1px;">
                              <input [checked]="model['pfcDetention'] == 'R'" value="R"   (change)="changeEventPfcDetention($event)" name="pfcDetention" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Request</label>
                            </div>
                          </div>

                          <div class="field">
                            <input  type="number" [(ngModel)]="model['pfcDetentionRequest']" name="pfcDetentionRequest" placeholder="0" maxlength="20" oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);" [disabled]="lock == true || model['pfcDetention'] != 'R'" style="border:1px solid gray">
                          </div>

                        </fieldset>
                      </div>

                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray"><!--width: 56%;-->
                          <legend style=" font-weight:bold">Demurrage</legend>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDemurrage'] == 'N'" value="N"   (change)="changeEventPfcDemurrage($event)" name="pfcDemurrage"  type="radio" tabindex="0" class="hidden" [disabled]="lock == true || lockDemurrage == false">
                              <label>N/A</label>
                            </div>
                          </div>

                          <div class="field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDemurrage'] == 'S'" value="S"   (change)="changeEventPfcDemurrage($event)" name="pfcDemurrage" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Standard</label>
                            </div>
                          </div>

                          <div class="field">
                            <div class="ui radio checkbox">
                              <input [checked]="model['pfcDemurrage'] == 'R'" value="R"   (change)="changeEventPfcDemurrage($event)" name="pfcDemurrage" type="radio" tabindex="0" class="hidden" [disabled]="lock == true">
                              <label>Request</label>
                            </div>
                          </div>

                          <div class="field">
                              <input  type="number" [(ngModel)]="model['pfcDemurrageRequest']" name="pfcDemurrageRequest" placeholder="0" maxlength="20" [disabled]="lock == true || model['pfcDemurrage'] != 'R'" style="border:1px solid gray;" >
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div class="three wide fields" style="margin-top: 20px">
                    <!--
                    <div class="field" style="margin-left: 5px; margin-right: 5px">
                      <button style="font-size:1.1em; height: 100px" class="large ui button" (click)="onSurcharge()" [disabled]="lock == true">Surcharge</button>
                    </div>
                    -->
                    <div class="field" style="margin-left: 5px; margin-right: 5px">
                      <button style="font-size:1.1em; height: 100px" class="large ui button" (click)="onSurcharge2()" [disabled]="lock == true">Surcharge</button>
                    </div>

                    <div class="field" style="margin-left: 5px; margin-right: 5px">
                      <button style="font-size:1.1em; height: 100px; margin-left: 43px" class="large ui button" (click)="onTransmit()" [disabled]="lock == true || model['pfcAppStatus'] == 'A' || model['pfcAppStatus'] == 'R' || model['pfcAppStatus'] == 'P' && model['pfcIsTransmitted'] == 'Y' || model['pfcRecordId'] == ''">Transmit</button>
                    </div>

                    <div class="field">
                      &nbsp;
                    </div>



                </div>

              </div>
            </div>        <!-- 4th Pane from Left End -->
          </div>
        </div>

      </div> <!-- End of Maintenance General Tab -->

      <!-- Start of Tab Terminal -->
      <div class="ui bottom attached tab segment" id="maintenanceTerminalTab" data-tab="maintenanceTerminalTab">
        <!-- Row 1 start -->
        <div class="two fields">
          <div class="field">
            <label>POR Terminal</label>
            <combo-plugin #cbMaintenancePORTerminal [settings]="settingCbMaintenancePORTerminal"   (change)="changeCbMaintenancePORTerminal($event)" [disableCombo]="lockPORTerminal" ></combo-plugin>
          </div>
          <div class="field">
            <label>PODL Terminal</label>
            <combo-plugin #cbMaintenancePODLTerminal [settings]="settingCbMaintenancePODLTerminal"   (change)="changeCbMaintenancePODLTerminal($event)" [disableCombo]="lockPODLTerminal"></combo-plugin>
          </div>
        </div>
        <!-- Row 1 end-->

        <!-- Row 2 start -->
        <div class="two fields">
          <div class="field">
            <label>POL Terminal</label>
            <combo-plugin #cbMaintenancePOLTerminal [settings]="settingCbMaintenancePOLTerminal"   (change)="changeCbMaintenancePOLTerminal($event)" [disableCombo]="lockPOLTerminal"></combo-plugin>
          </div>
          <div class="field">
            <label>POT1 Terminal</label>
            <combo-plugin #cbMaintenancePOT1Terminal [settings]="settingCbMaintenancePOT1Terminal"   (change)="changeCbMaintenancePOT1Terminal($event)" [disableCombo]="lockPOT1Terminal"></combo-plugin>
          </div>
        </div>
        <!-- Row 2 end-->

        <!-- Row 3 start -->
        <div class="two fields">
          <div class="field">
            <label>POD Terminal</label>
            <combo-plugin #cbMaintenancePODTerminal [settings]="settingCbMaintenancePODTerminal"   (change)="changeCbMaintenancePODTerminal($event)" [disableCombo]="lockPODTerminal"></combo-plugin>
          </div>
          <div class="field">
            <label>FDEST Terminal</label>
            <combo-plugin #cbMaintenanceFDESTTerminal [settings]="settingCbMaintenanceFDESTTerminal"   (change)="changeCbMaintenanceFDESTTerminal($event)" [disableCombo]="lockFDESTTerminal"></combo-plugin>
          </div>
        </div>
        <!-- Row 3 end-->
      </div>
      <!-- End of Maintenance terminal tab -->


      <div class="ui styled accordion field"  style="width:100%"> <!-- Grid Charge Detail Start -->
        <div class="title"><i class="icon dropdown"></i>Details</div>

        <div class="three fields" style="margin-left: 10px;">
          <div class=field>
            <button class="ui button" style="font-size: 10px;" (click)="onDetailNew()" [disabled]="lock == true">New</button>
            <button class="ui button" style="font-size: 10px;" (click)="onDetailUpdate()" [disabled]="lock == true">Update</button>
            <button class="ui button" style="font-size: 10px;" (click)="onDetailRemove()" [disabled]="lock == true">Remove</button>
            <button class="ui button" style="font-size: 10px;" (click)="onDetailCopy()" [disabled]="lock == true">Copy</button>
            <!--<button class="ui button" style="font-size: 10px;" (click)="onDetailFAI()" [disabled]="lock == true">FAI</button>
            <button class="ui button" style="font-size: 10px;" (click)="onDetailClearFAI()" [disabled]="lock == true">Clear FAI</button>-->
          </div>
          <div class=field> </div>
          <div class=field></div>
        </div>
        <div class="ui top attached tabular menu" style="background-color:beige;">
          <a class="item active"  data-tab="Prepaid" (click)="onTabPrepaid()">Prepaid</a>
          <a class="item"   data-tab="Collect" (click)="onTabCollect()">Collect</a>
        </div>

        <div class="ui bottom attached tab segment active" id="PrepaidTab" data-tab="Prepaid" >
          <div class="field">
            <grid-plugin id='gridPrepaid'  #gridPrepaid [settings]="settingGridPrepaid"  (gridEvent)="gridEventPrepaid($event)" (infoGrid)="infoGridPrepaid($event)"  >
            </grid-plugin>
          </div>    <!--field-->
        </div> <!-- Prepaid-->

        <div class="ui bottom attached tab segment" id="CollectTab" data-tab="Collect" >
          <div class="field" >
            <grid-plugin id='gridCollect' #gridCollect [settings]="settingGridCollect"  (gridEvent)="gridEventCollect($event)" (infoGrid)="infoGridCollect($event)"></grid-plugin>
          </div><!--field-->
        </div>

        <div class="field">
          <div class="two wide fields">
            <div class="field">
              <label>Remarks Principal</label>
              <textarea-plugin #txtRemarksPrincipal [settings]="settingRemarksPrincipal" [disabled]="lockRemarksPrincipal"></textarea-plugin>
            </div>
            <div class="field">
              <label>Remarks Agency</label>
              <textarea-plugin #txtRemarksAgency [settings]="settingRemarksAgency" [disabled]="lock == true"></textarea-plugin>
            </div>
          </div>
        </div>
      </div> <!-- Grid Charge Detail Stop -->

    </div> <!-- End of Freight Maintenance Form Control -->
  </form>
</div></div>


<!-- START, For NCT Form -->
<div id="NCTForm" style="width:25%;padding:0.5%;display:none;">
  <form  class="ui form" style="width:100%;" >
    <fieldset style=" border: 1px solid lightgray;margin-left:0.5px;"><!--margin-right:15px;-->
      <legend style="font-weight:bold;color:dodgerblue;margin-bottom:0.1%;">Copy Rate From Container Type</legend>
      <div  class="field" style="margin-bottom:2%;">
        <label style="font-size: 11px;">Which cntr type do you want to copy from?</label>

        <combo-plugin  #nctform_ctrType [settings]="setting_nctFormForContainerType" (change)="value_nctFormForContainerType($event);" [disableCombo]="lock"></combo-plugin>

      </div>
      <div class="two fields">
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;"  (click)="onNCT_Save()" >Save</button>

        </div>
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;"  (click)="onNCT_Cancel($event)">Cancel</button>
        </div>
      </div>
    </fieldset>

  </form>
</div>
<!-- END, For NCT Form -->

<!-- START, For Delete Form -->
<div id="DeleteForm" style="width:25%;padding:0.5%;display:none;">
  <div class="ui error message" [class.hidden]="isErrorDeleted == false">
      <ul>
          <li [class.hidden]="modelDelete['error-pfcReasonDeleted'] == null || modelDelete['error-pfcReasonDeleted'] == ''">
            {{modelDelete['error-pfcReasonDeleted']}}
          </li>
      </ul>
  </div>
  <form  class="ui form" style="width:100%;" >
    <fieldset style=" border: 1px solid lightgray;margin-left:0.5px;"><!--margin-right:15px;-->
      <div  class="field" style="margin-bottom:2%;">
        <label style="font-size: 11px;">Please entry the reason of delete</label>
        <textarea id="reasonDelete" name="reason" style="font-size:12px;" [(ngModel)]="modelDelete['pfcReasonDeleted']" placeholder="reason delete"></textarea>


      </div>
      <div class="two fields">
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;"  (click)="this.deleteProcess()" >Process</button>

        </div>
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;"  (click)="this.closeDialog('DeleteForm');modelDelete['pfcReasonDeleted'] = '';this.isErrorDeleted = false;">Cancel</button>
        </div>
      </div>
    </fieldset>

  </form>
</div>
<!-- END, For Delete Form -->




<!-- charge management section -->
<div id="freightNewChargeForm"  style="display:none;font-family:Lucida Console, monospace;"> <!--class="ui fullscreen modal"  style="width:90%;padding:1%;">-->

  <!--Error Message Block-->
  <div class="ui error message" [class.hidden]="isErrorDetail == false">
    <ul>
      <li [class.hidden]="modelDetail['error-pfcDetailChargeCode'] == null || modelDetail['error-pfcDetailChargeCode'] == ''">
        {{modelDetail['error-pfcDetailChargeCode']}}
      </li>
      <li [class.hidden]="modelDetail['error-pfcDetailCurrency'] == null || modelDetail['error-pfcDetailCurrency'] == ''">
        {{modelDetail['error-pfcDetailCurrency']}}
      </li>
      <li [class.hidden]="modelDetail['error-pfcDetailPC'] == null || modelDetail['error-pfcDetailPC'] == ''">
        {{modelDetail['error-pfcDetailPC']}}
      </li>
      <li [class.hidden]="modelDetail['error-pfcDetailPlaceOfPayment'] == null || modelDetail['error-pfcDetailPlaceOfPayment'] == ''">
        {{modelDetail['error-pfcDetailPlaceOfPayment']}}
      </li>
      <li [class.hidden]="modelDetail['error-pfcDetailPayer'] == null || modelDetail['error-pfcDetailPayer'] == ''">
        {{modelDetail['error-pfcDetailPayer']}}
      </li>
      <li [class.hidden]="modelDetail['error-chargesValueValidator'] == null || modelDetail['error-chargesValueValidator'] == ''">
        {{modelDetail['error-chargesValueValidator']}}
      </li>
      <li [class.hidden]="modelDetail['error-pfcDuplicateChargeCode'] == null || modelDetail['error-pfcDuplicateChargeCode'] == ''">
        {{modelDetail['error-pfcDuplicateChargeCode']}}
      </li>
      <li [class.hidden]="modelDetail['error-mainChargesValidator'] == null || modelDetail['error-mainChargesValidator'] == ''">
        {{modelDetail['error-mainChargesValidator']}}
      </li>
     </ul>
  </div>
  <!--End of Error Message Block-->

  <form  class="ui form" style="width:100%;"> <!-- form start -->
    <h4 class="ui dividing header" style="width: 100%;text-align:left;margin-top:10px;"></h4>
    <div class="field">
      <button class="ui button" style="font-size: 12px;" (click)="onNewCharge_OK()" [disabled]="lock == true" >OK</button>
      <button class="ui button" style="font-size: 12px;" (click)="onNewCharge_Reset()" [disabled]="lock == true" >Reset</button>
      <button class="ui button" style="font-size: 12px;" (click)="onNewCharge_Close()"> Close</button>
    </div>

    <fieldset style="width: 100%;border: 1px solid lightgray;margin-bottom:1px">
      <div class="nine wide fields" style="margin-left: 0.5px;">
        <div class="field" style="margin-bottom: 3px;width:10%">
          <label>Seq No.</label>
          <input type="number" [(ngModel)]="modelDetail['pfcDetailSeqNo']" name="pfcDetailSeqNo" placeholder="0" maxlength="3" readonly>
        </div>
        <div class="required field" [class.error]="modelDetail['error-pfcDetailChargeCode'] != null && modelDetail['error-pfcDetailChargeCode'] != ''" style="width:35%">
          <label style="color:red">Charge</label>
          <combo-plugin  #cbMaintenanceDetailCharge [settings]="settingCbMaintenanceDetailCharge"   (change)="changeCbMaintenanceDetailCharge($event)" [disableCombo]="lock == true" ></combo-plugin>
        </div>
        <div class="required field" [class.error]="modelDetail['error-pfcDetailCurrency'] != null && modelDetail['error-pfcDetailCurrency'] != ''" style="width:15%">
          <label style="color:red">Currency</label>
          <combo-plugin  #cbMaintenanceDetailCurrency [settings]="settingCbMaintenanceDetailCurrency"   (change)="changeCbMaintenanceDetailCurrency($event)" [disableCombo]="lock == true"></combo-plugin>
        </div>
        <div class="required field" [class.error]="modelDetail['error-pfcDetailPC'] != null && modelDetail['error-pfcDetailPC'] != ''" style="width:9%">
          <label style="color:red">P/C</label>
          <combo-plugin  #cbMaintenanceDetailPC [settings]="settingCbMaintenanceDetailPC" (change)= "changeCbMaintenanceDetailPC($event)" [disableCombo]="lock==true" [disableCombo]="lock == true"></combo-plugin>
        </div>
        <div class="required field" [class.error]="modelDetail['error-pfcDetailPlaceOfPayment'] != null && modelDetail['error-pfcDetailPlaceOfPayment'] != ''" style="width:35%">
          <label style="color:red">Place of Payment</label>
          <combo-plugin  #cbMaintenanceDetailPlaceOfPayment [settings]="settingCbMaintenanceDetailPlaceOfPayment" (change)="changeCbMaintenancePlaceOfPayment($event)"  [disableCombo]="lock == true" ></combo-plugin>
        </div>
        <div class="required field" [class.error]="modelDetail['error-pfcDetailPayer'] != null && modelDetail['error-pfcDetailPayer'] != ''" style="width:35%">
          <label style="color:red">Payer</label>
          <combo-plugin #cbMaintenanceDetailPayer [settings]="settingCbMaintenanceDetailPayer" (change)="changeCbMaintenanceDetailPayer($event)" [disableCombo]="lock == true"></combo-plugin>
        </div>

      </div> <!--End for nine wide fields-->

      <div class="two wide fields">
        <div class="field">
          <div class="nine wide fields" style="margin-left: 2px;">
            <!--div class="six wide fields"-->
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''"  style="margin-bottom: 3px;width:16%;">
              <label>D20</label>
              <input type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailD20']" name="pfcDetailD20" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''" style="margin-bottom: 3px;width:16%;">
              <label>H20</label>
              <input  type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailH20']" name="pfcDetailH20" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''" style="margin-bottom: 3px;width:16%;">
              <label>D40</label>
              <input type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailD40']" name="pfcDetailD40" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''" style="margin-bottom: 3px;width:16%;">
              <label>H40</label>
              <input type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailH40']" name="pfcDetailH40" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''" style="margin-bottom: 3px;width:16%;">
              <label>D45</label>
              <input type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailD45']" name="pfcDetailD45" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <div class="field" [class.error]="modelDetail['error-chargesValueValidator'] != null && modelDetail['error-chargesValueValidator'] != ''" style="margin-bottom: 3px;width:16%;">
              <label>H45</label>
              <input type="number" step="0.001" min="0" [(ngModel)]="modelDetail['pfcDetailH45']" name="pfcDetailH45" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" style="text-align: right" [disabled]="lock == true">
            </div>
            <!--/div-->
          </div>
          <div class="nine wide fields" style="margin-left: 2px;">
            <!--div class="six wide fields"-->
            <div class="field" style="margin-bottom: 3px;width:16%;">
              <label>M3</label>
              <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailM3']" name="pfcDetailM3" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" disabled style="text-align: right;background-color: lightgray">
            </div>

            <div class="field"  style="margin-bottom: 3px;width:16%;"  >
              <label>KT</label>
              <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailKT']" name="pfcDetailKT" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" disabled style="text-align: right;background-color: lightgray">
            </div>

            <div class="field"  style="margin-bottom: 3px;width:16%;">
              <label>RT</label>
              <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailRT']" name="pfcDetailRT" onkeydown="return (event.keyCode!=13);"  placeholder="0" maxlength="10" disabled style="text-align: right;background-color: lightgray">
            </div>

            <div class="field"  style="margin-bottom: 3px;width:19%;">
              <label>Min Charge</label>
              <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailMinCharge']" name="pfcDetailMinCharge" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" disabled style="text-align: right;background-color: lightgray">
            </div>

            <div class="field"  style="margin-bottom: 3px;width:20%;">
              <label>Kill Slot</label>
              <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailKillSlot']" name="pfcDetailKillSlot" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="10" disabled style="text-align: right;background-color: lightgray">
            </div>
            <!--/div-->
          </div>
          <div class="three wide fields" style="margin-bottom: 3px;margin-left: 7.2px;">
            <div class="field">
              <div class="ui test checkbox" >
                <input type="checkbox" name="pfcDetailFAIGroup" tabindex="0" [checked]="modelDetail['pfcDetailFAIGroup'] == 'Y'"  (change)="changeEventPfcDetailFAIGroup($event)">
                <label style="margin-left: 3px;font-weight: bold" >FAI Group</label>
              </div>
            </div>
            <div class="field" style="width:100%;">
              <div class="ui test checkbox">
                <input [checked]="modelDetail['pfcDetailCopyTab'] == 'Y'" value="Y" type="checkbox"   name="pfcDetailCopyTab" tabindex="0" (change)="changeEventPfcDetailCopyTab($event)">
                <label style="margin-left: 3px;font-weight: bold" >Copy Freight to {{currentActiveTab == "prepaid"? "Collect" : "Prepaid"}}</label>
              </div>
            </div>
          </div>
          <div class="field">&nbsp;</div>
        </div>
        <div class="field">
          <div class="two wide fields">
            <div class="field" [class.disabled]="lockOOGOOG==true" style="width:70%;">
              <fieldset id="oogDetailCMGroup"  style="border: 1px solid lightgray;margin-bottom:1px;" >
                <legend  style=" font-weight:bold;color:dodgerblue;margin-bottom:1px;">OOG Details(CM)</legend>
                <div class="field" style="width:36%;margin-bottom: 3px;">
                  <label>OH</label>
                  <input type="number" min="0" [disabled]="lockOOGOOG==true" [(ngModel)]="modelDetail['pfcDetailOogOH']" name="pfcDetailOogOH" onkeydown="return (event.keyCode!=13);" placeholder="0" maxlength="3" style="text-align: right">
                </div>

                <div class="two fields">
                  <div class="field" style="width:40%;margin-bottom: 3px;">
                    <label>OWL</label>
                    <input type="number" min="0" [disabled]="lockOOGOOG==true" [(ngModel)]="modelDetail['pfcDetailOogOWL']" name="pfcDetailOogOWL" onkeydown="return (event.keyCode!=13);"  placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                  <div class="field" style="width:40%;margin-bottom: 3px;">
                    <label>OWR</label>
                    <input  type="number" min="0" [disabled]="lockOOGOOG==true" [(ngModel)]="modelDetail['pfcDetailOogOWR']" name="pfcDetailOogOWR" onkeydown="return (event.keyCode!=13);" placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                </div>
                <div class="two fields">
                  <div class="field" style="width:40%;margin-bottom: 3px;">
                    <label>OLF</label>
                    <input  type="number" min="0" [disabled]="lockOOGOOG==true" [(ngModel)]="modelDetail['pfcDetailOogOLF']" name="pfcDetailOogOLF" onkeydown="return (event.keyCode!=13);"  placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                  <div class="field" style="width:40%;margin-bottom: 3px;">
                    <label>OLB</label>
                    <input type="number" min="0" [disabled]="lockOOGOOG==true" [(ngModel)]="modelDetail['pfcDetailOogOLB']" name="pfcDetailOogOLB" onkeydown="return (event.keyCode!=13);" placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                </div>
              </fieldset>
            </div>

            <div  class="field" [class.disabled]="lockOOGDoorOpen==true">
              <fieldset id="doorOpenGroup"  style="width: 100%;border: 1px solid lightgray;margin-bottom:1px" >
                <legend style=" font-weight:bold;color:dodgerblue;margin-bottom:1px">Door Open</legend>
                <div class="one wide fields" style="margin-bottom: 1px;">
                  <div class="field" style="margin-top:1px;">
                    <div class="ui radio checkbox">
                      <input [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'N'" value="N"   (change)="changeEventPfcDetailDoorOpen($event)" name="pfcDetailOogDoorOpen" type="radio" tabindex="0" class="hidden">
                      <label>N/A</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px;">
                  <div class="field" style="margin-top:1px;">
                    <div class="ui radio checkbox">
                      <input [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'D'" value="D"   (change)="changeEventPfcDetailDoorOpen($event)" name="pfcDetailOogDoorOpen" type="radio" tabindex="0" class="hidden">
                      <label>Detached</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px;">
                  <div class="field" style="margin-top:1px;">
                    <div class="ui radio checkbox">
                      <input  [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'B'" value="B"   (change)="changeEventPfcDetailDoorOpen($event)" name="pfcDetailOogDoorOpen" type="radio" tabindex="0" class="hidden" >
                      <label>Tied Both</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px;">
                  <div class="field" style="margin-top:1px;">
                    <div class="ui radio checkbox">
                      <input  [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'L'" value="L"   (change)="changeEventPfcDetailDoorOpen($event)" name="pfcDetailOogDoorOpen" type="radio" tabindex="0" class="hidden">
                      <label>Tied Left</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px;">
                  <div class="field" style="margin-top:1px;">
                    <div class="ui radio checkbox">
                      <input  [checked]="modelDetail['pfcDetailOogDoorOpen'] == 'R'" value="R"   (change)="changeEventPfcDetailDoorOpen($event)" name="pfcDetailOogDoorOpen" type="radio" tabindex="0" class="hidden">
                      <label>Tied Right</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div> <!--Door Open-->

            <div class="field" [class.disabled]="lockOOGUC==true">
              <fieldset id="ucCMGroup"  style="width: 80%;border: 1px solid lightgray;margin-bottom:1px" >
                <legend style=" font-weight:bold;color:dodgerblue;margin-bottom:1px">UC(CM)</legend>
                <div class="two fields">
                  <div class="field" style="width:9%;margin-bottom: 3px;">
                    <label>H</label>
                  </div>
                  <div class="field" style="width:71%;margin-bottom: 3px;">
                    <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailOogUcH']" name="pfcDetailOogUcH"  onkeydown="return (event.keyCode!=13);" placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                </div> <!-- for first row //two fields width:50%;-->
                <div class="two fields">
                  <div class="field" style="width:9%;margin-bottom: 3px;">
                    <label>L</label>
                  </div>
                  <div class="field" style="width:71%;margin-bottom: 3px;">
                    <input  type="number" min="0" [(ngModel)]="modelDetail['pfcDetailOogUcL']" name="pfcDetailOogUcL" onkeydown="return (event.keyCode!=13);" placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                </div> <!-- for second row //two fields -->

                <div class="two fields">
                  <div class="field" style="width:9%;margin-bottom: 3px;">
                    <label>W</label>
                  </div>
                  <div class="field" style="width:71%;margin-bottom: 3px;">
                    <input type="number" min="0" [(ngModel)]="modelDetail['pfcDetailOogUcW']" name="pfcDetailOogUcW" onkeydown="return (event.keyCode!=13);"  placeholder="0"  maxlength="3" style="text-align: right">
                  </div>
                </div> <!-- for 3rd row //two fields -->
              </fieldset>
            </div> <!--UCM-->
          </div>
        </div>
      </div>
    </fieldset>
  </form> <!-- form end -->
</div>

<!-- end of charge management section -->

<dialog-multiple-plugin #dialogMultiple (eventDialog)="eventMessage($event)"></dialog-multiple-plugin>

<footer-plugin [info]="info"></footer-plugin>
