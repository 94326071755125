import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  ConfigService,
  CookieService,
  GenericUtil,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { specialCharUtil } from "../shared";
import FormValidation from "../../utils/formValidation";
import { finalize } from "rxjs/operators";
declare var $: any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselIdFrom: string = "";
  blOceanVesselCodeFrom: string = "";
  blOceanVesselVoyageFrom: string = "";
  blOceanVesselBoundFrom: string = "";

  blOceanVesselIdTo: string = "";
  blOceanVesselCodeTo: string = "";
  blOceanVesselVoyageTo: string = "";
  blOceanVesselBoundTo: string = "";

  user: string = "";

  blNoValidator: Boolean = false;

  constructor() {}
}

export class ParamBL {
  blNo: string = "";
  bOceanVesselId: string = "";
  bOceanVesselVoyage: string = "";
  blType: string = "";
  blIsSplit: string = "";
  blSplitKey: string = "";
  blIsCombine: string = "";
  blCombineKey: string = "";
  blIsBlPrinted: string = "";
  constructor() {}
}

@Component({
  selector: "app-transaction-bl-transfer-latest",
  templateUrl: "./transaction-bl-transfer-latest-page.component.html",
  styleUrls: ["./transaction-bl-transfer-latest-page.component.css"],
})
export class TransactionBlTransferLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVesselFrom") cbVesselFrom: ComboPluginComponent;
  @ViewChild("cbVoyageFrom") cbVoyageFrom: ComboPluginComponent;
  @ViewChild("cbVesselTo") cbVesselTo: ComboPluginComponent;
  @ViewChild("cbVoyageTo") cbVoyageTo: ComboPluginComponent;
  @ViewChild("gridBLTransferByVessel")
  gridBLTransferByVessel: GridPluginComponent;
  @ViewChild("gridBLTransferByBL") gridBLTransferByBL: GridPluginComponent;

  dialog: any;

  // * Special Char
  sCharUtil = new specialCharUtil();
  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVesselFrom;
  settingVoyageFrom;
  settingVesselTo;
  settingVoyageTo;
  settingGridBLTransferByVessel;
  settingGridBLTransferByBL;

  /* Model */
  model = new Param();
  modelAdd = new ParamBL();

  radioTransferByVessel = "Y";

  isError: Boolean = false;
  isErrorAdd: boolean = false;
  validatorRules = {};
  portOfCallTo = "";
  portOfCallFrom = "";

  msg: string = "";
  store: any[] = [];
  isEnter: boolean = false;

  mpDuplicate = new Map<number, String>();
  mpSameVessels = new Map<number, String>();
  mpMainVessels = new Map<number, String>();
  mpCombineChild = new Map<number, String>();

  constructor(
    private auth: Authorize,
    private cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private configService: ConfigService,
    private cookieService: CookieService,
    private genericUtil: GenericUtil,
    private router: Router
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Transfer", event: "transfer", icon: "exchange" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Transfer",
    };

    this.settingVesselFrom = {
      id: "cbVesselFrom",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageFrom = {
      id: "cbVoyageFrom",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVesselTo = {
      id: "cbVesselTo",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageTo = {
      id: "cbVoyageTo",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    /*
    this.settingGridBLTransferByVessel = {
      id : 'gridBLTransferByVessel',
      url: this.configService.config.BASE_API.toString() + '/BLTransfer/searchBL',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 300},
        {header: '',field:'btnRemove', width:100, type:'button', labelButton: 'Remove'},

      ],
      buttons: [
        { }
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
      sortingColumns:'blNo',
      editable:true,
      sortingDirection: 'ASC'
    }
    */

    this.settingGridBLTransferByVessel = {
      id: "gridBLTransferByVessel",
      url: "",
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 300, editType: "select" },
        {
          header: "",
          field: "btnRemove",
          width: 100,
          type: "button",
          labelButton: "Remove",
        },
      ],
      buttons: [{}],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      editable: true,
      sortingDirection: 'ASC'
    };

    this.settingGridBLTransferByBL = {
      id: "gridBLTransferByBL",
      url: "",
      page: 10,
      columns: [
        { header: "B/L No", field: "blNo", width: 300, editType: "text" },
        {
          header: "",
          field: "btnRemove",
          width: 100,
          type: "button",
          labelButton: "Remove",
        },
      ],
      buttons: [
        {
          /*name: 'Edit', event: 'edit', enabled: true */
        },
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "blNo",
      editable: true,
      sortingDirection: "ASC",
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.setValidatorRetrieveTransferByVessel();
    this.model.blOceanVesselBoundFrom = "O";
    this.model.blOceanVesselBoundTo = "O";
    this.cdr.detectChanges();
    this.mpSameVessels.clear();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "transfer":
        this.handleTransfer();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorRetrieveTransferByVessel() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-blOceanVesselCodeTo"] = "";
    this.model["error-blOceanVesselVoyageTo"] = "";
    this.model["error-blOceanVesselBoundTo"] = "";

    this.validatorRules = {
      blOceanVesselCodeFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel should not be empty.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      blOceanVesselVoyageFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage should not be empty.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      blOceanVesselBoundFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
    };
  }

  setValidatorTransferByVessel() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-blOceanVesselCodeTo"] = "";
    this.model["error-blOceanVesselVoyageTo"] = "";
    this.model["error-blOceanVesselBoundTo"] = "";
    this.model["error-blNoValidator"] = "";

    this.validatorRules = {
      blOceanVesselCodeFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselVoyageFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselBoundFrom: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
      blOceanVesselCodeTo: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel Destination should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselVoyageTo: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage Destination should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselBoundTo: {
        rules: [
          {
            type: "empty",
            prompt: "Bound Destination should not be empty.",
          },
        ],
      },
      blNoValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input data.",
          },
        ],
      },
    };
  }

  setValidatorTransferByBL() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-blOceanVesselCodeTo"] = "";
    this.model["error-blOceanVesselVoyageTo"] = "";
    this.model["error-blOceanVesselBoundTo"] = "";
    this.model["error-blNoValidator"] = "";

    this.validatorRules = {
      blOceanVesselCodeTo: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel Destination should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselVoyageTo: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage Destination should not be empty.",
          },
          {
            type: 'modifiedPattern',
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          }
        ],
      },
      blOceanVesselBoundTo: {
        rules: [
          {
            type: "empty",
            prompt: "Bound Destination should not be empty.",
          },
        ],
      },
      blNoValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Please input data.",
          },
        ],
      },
    };
  }

  changeEventVesselFrom(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselIdFrom = "";
      this.model.blOceanVesselCodeFrom = "";
    } else {
      this.model.blOceanVesselIdFrom = event.vesselId;
      this.model.blOceanVesselCodeFrom = event.vesselCode;

      this.cbVoyageFrom.disableCombo = false;
      this.cbVoyageFrom.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBoundFrom +
          "/voyage_like={query}"
      );
      this.cbVoyageFrom.setValue("");
    }
  }

  changeEventVoyageFrom(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyageFrom = "";
    } else {
      this.model.blOceanVesselVoyageFrom = event.voyage;

      this.portOfCallFrom = "";
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/getPortOfRotationForBookingTransfer/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.blOceanVesselIdFrom +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.blOceanVesselVoyageFrom).trim()
            ) +
            "/" +
            this.model.blOceanVesselBoundFrom
        )
        .subscribe((resp) => {
          if (resp.json()["content"].length > 0) {
            this.portOfCallFrom = resp.json()["content"][0]["rotation"];
          }
        });

      /*this.checkValidSchedule(
          this.model.blOceanVesselIdFrom,
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyageFrom).trim()
          ) ,
          this.model.blOceanVesselBoundFrom,
          this.cookieService.getDefaultLocationCode());  */
    }
  }

  changeEventVesselTo(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselIdTo = "";
      this.model.blOceanVesselCodeTo = "";
    } else {
      this.model.blOceanVesselIdTo = event.vesselId;
      this.model.blOceanVesselCodeTo = event.vesselCode;

      this.cbVoyageTo.disableCombo = false;
      this.cbVoyageTo.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBoundTo +
          "/voyage_like={query}"
      );

      this.cbVoyageTo.setValue("");
    }
  }

  changeEventVoyageTo(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyageTo = "";
    } else {
      this.model.blOceanVesselVoyageTo = event.voyage;

      this.portOfCallTo = "";
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/getPortOfRotationForBookingTransfer/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.model.blOceanVesselIdTo +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.blOceanVesselVoyageTo).trim()
            ) +
            "/" +
            this.model.blOceanVesselBoundTo
        )
        .subscribe((resp) => {
          if (resp.json()["content"].length > 0) {
            this.portOfCallTo = resp.json()["content"][0]["rotation"];
          }
        });

      this.checkValidSchedule(
        this.model.blOceanVesselIdTo,
        this.sCharUtil.htmlEncode(
          String(this.model.blOceanVesselVoyageTo).trim()
        ),
        this.model.blOceanVesselBoundTo,
        this.cookieService.getDefaultLocationCode()
      );
    }
  }

  changeEventTransferByVessel(event) {
    console.log(event);
    if (event.target.checked) {
      this.radioTransferByVessel = "Y";
      this.disableToolbarButtons = "";
      this.gridBLTransferByVessel.onClear();
    }
  }
  changeEventTransferByBL(event) {
    console.log(event);
    if (event.target.checked) {
      this.model.blOceanVesselIdFrom = "";
      this.model.blOceanVesselCodeFrom = "";
      this.model.blOceanVesselVoyageFrom = "";
      this.model.blOceanVesselBoundFrom = "O";
      this.cbVesselFrom.setValue("");
      this.cbVoyageFrom.setValue("");

      this.radioTransferByVessel = "N";
      this.disableToolbarButtons = "retrieve";
      this.gridBLTransferByBL.listStore.store = [];
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.addData(modelAdd);
      this.gridBLTransferByBL.loadData();
    }
  }
  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  rowEvent(act: string) {
    if (act == "add") {
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.addData(modelAdd);
      this.gridBLTransferByBL.loadData();
      //this.genericUtil.showDialog('AddForm', 'Add BL', 300, 300)
    }
  }

  closeDialog(id: string) {
    this.genericUtil.closeDialog(id);
    this.gridBLTransferByBL.loadData();
  }

  infoGrid(event) {
    this.info = event;
  }

  gridEventByVessel(event) {
    console.log(event);
    let arr: string[] = event.split("-");
    let sortType = event.slice(10);
    if (arr[0].split(".")[0] === "sort") {
      let byVesselListSort = [];
      if (sortType === 'DESC') {
        byVesselListSort = this.store.sort((a, b) => {
          if (a.blNo > b.blNo) { return -1; }
          if (a.blNo < b.blNo) { return 1; }
          return 0;
        });
      } else {
        byVesselListSort = this.store.sort((a, b) => {
          if (a.blNo < b.blNo) { return -1; }
          if (a.blNo > b.blNo) { return 1; }
          return 0;
        });;
      }
      this.gridBLTransferByVessel.listStore.store = byVesselListSort;
      this.gridBLTransferByVessel.loadData();
    }
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var id: string = event.split(".")[1].split("-")[0];
        var no = Number.parseInt(event.split(".")[1].split("-")[1]);
        console.log(id + " - " + no);
        if (id == "btnRemove") {
          this.gridBLTransferByVessel.listStore.store.splice(no - 1, 1);
          this.gridBLTransferByVessel.loadData();
          console.log(this.gridBLTransferByVessel.listStore.store);
        }

        break;
      default:
        break;
    }
  }

  gridEventByBL(event) {
    console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var id: string = event.split(".")[1].split("-")[0];
        var no = Number.parseInt(event.split(".")[1].split("-")[1]);
        console.log(id + " - " + no);
        if (id == "btnRemove") {
          if (this.gridBLTransferByBL.listStore.store.length != no) {
            this.gridBLTransferByBL.listStore.store.splice(no - 1, 1);
            this.gridBLTransferByBL.loadData();
            this.mpSameVessels.delete(no - 1);
          }
        }

        break;

      case "AfterEnter":
        console.log("** AfterEnter Event **");
        console.log(event);

        this.isEnter = true;
        var no = Number.parseInt(event.split(".")[1]);
        var field: string = event.split(";")[1];
        var value: string = event.split(";")[2];
        console.log("value => " + value);

        var vesselTo: string = "*";
        if (this.model.blOceanVesselIdTo != "") {
          vesselTo = this.model.blOceanVesselIdTo;
        }

        var voyageTo: string = "*";
        if (this.model.blOceanVesselVoyageTo != "") {
          voyageTo = this.model.blOceanVesselVoyageTo;
        }

        if (value != "SSL") {
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BL/checkBlTransferByBlNo/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                value +
                "/" +
                vesselTo +
                "/" +
                this.sCharUtil.htmlEncode(String(voyageTo).trim())
            )
            .subscribe((resp) => {
              if (
                resp.json()["status"] == "OK" &&
                resp.json()["code"] == "MODE1"
              ) {
                console.log("RESP checkBlTransferByBlNo :");
                console.log(resp.json());

                let vsl = resp.json()["bOceanVesselId"];
                let vyg = resp.json()["bOceanVesselVoyage"];
                let blType = resp.json()["blType"];
                let blCombineKey = resp.json()["blCombineKey"];
                let blIsCombine = resp.json()["blIsCombine"];
                let blIsSplit = resp.json()["blIsSplit"];
                let blSplitKey = resp.json()["blSplitKey"];
                let blIsBlPrinted = resp.json()["blIsBlPrinted"];

                console.log(vsl + " - " + vyg);

                this.mpSameVessels.set(no, vsl + "-" + vyg);

                if (this.gridBLTransferByBL.listStore.store.length == 1) {
                  console.log("#1");

                  //let modelAdd = new ParamBL;
                  //modelAdd.blNo = "SSL"
                  //this.gridBLTransferByBL.listStore.addData(modelAdd)
                  //this.gridBLTransferByBL.loadData();

                  let storeTemp: any[] = [];
                  for (
                    let index = 0;
                    index < this.gridBLTransferByBL.listStore.store.length;
                    index++
                  ) {
                    let modelAdd = new ParamBL();
                    modelAdd.blNo =
                      this.gridBLTransferByBL.listStore.store[index].blNo;
                    if (value === modelAdd.blNo) {
                      modelAdd.bOceanVesselId = vsl;
                      modelAdd.bOceanVesselVoyage = vyg;
                      modelAdd.blType = blType;
                      modelAdd.blIsSplit = blIsSplit;
                      modelAdd.blSplitKey = blSplitKey;
                      modelAdd.blIsCombine = blIsCombine;
                      modelAdd.blCombineKey = blCombineKey;
                      modelAdd.blIsBlPrinted = blIsBlPrinted;
                    } else {
                      modelAdd.bOceanVesselId =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].bOceanVesselId;
                      modelAdd.bOceanVesselVoyage =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].bOceanVesselVoyage;
                      modelAdd.blType =
                        this.gridBLTransferByBL.listStore.store[index].blType;
                      modelAdd.blIsSplit =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsSplit;
                      modelAdd.blSplitKey =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blSplitKey;
                      modelAdd.blIsCombine =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsCombine;
                      modelAdd.blCombineKey =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blCombineKey;
                      modelAdd.blIsBlPrinted =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsBlPrinted;
                    }

                    console.log(modelAdd);

                    if (modelAdd.blNo != "SSL") {
                      storeTemp.push(modelAdd);
                    }
                  }

                  this.gridBLTransferByBL.listStore.store = [];

                  let modelAdd = new ParamBL();
                  modelAdd.blNo = "SSL";
                  storeTemp.push(modelAdd);

                  this.gridBLTransferByBL.listStore.store = storeTemp;
                  this.gridBLTransferByBL.loadData();

                  // ------------------- check combine child
                  this.mpCombineChild.clear();
                  for (
                    let index = 0;
                    index < this.gridBLTransferByBL.listStore.store.length;
                    index++
                  ) {
                    let blNo = String(
                      this.gridBLTransferByBL.listStore.store[index].blNo
                    ).toUpperCase();
                    let blIsCombine = String(
                      this.gridBLTransferByBL.listStore.store[index].blIsCombine
                    ).toUpperCase();
                    let blCombineKey = String(
                      this.gridBLTransferByBL.listStore.store[index]
                        .blCombineKey
                    ).toUpperCase();
                    console.log(
                      blNo + " - " + blIsCombine + " - " + blCombineKey
                    );
                    if (blIsCombine === "Y" && blCombineKey != "") {
                      this.mpCombineChild.set(index, blCombineKey);
                    }
                  }

                  if (this.mpCombineChild.size == 0) {
                  } else {
                    for (let entry of Array.from(
                      this.mpCombineChild.entries()
                    )) {
                      console.log(entry[0] + " - " + entry[1]);
                      this.msg =
                        "This is BL Combine, please use " + entry[1] + ".";
                      this.genericUtil.showDialog(
                        "msgDialogCombine",
                        "Information",
                        420,
                        160
                      );
                    }
                  }
                } else {
                  console.log("#2");

                  let storeTemp: any[] = [];
                  for (
                    let index = 0;
                    index < this.gridBLTransferByBL.listStore.store.length;
                    index++
                  ) {
                    let modelAdd = new ParamBL();
                    modelAdd.blNo =
                      this.gridBLTransferByBL.listStore.store[index].blNo;
                    if (value === modelAdd.blNo) {
                      modelAdd.bOceanVesselId = vsl;
                      modelAdd.bOceanVesselVoyage = vyg;
                      modelAdd.blType = blType;
                      modelAdd.blIsSplit = blIsSplit;
                      modelAdd.blSplitKey = blSplitKey;
                      modelAdd.blIsCombine = blIsCombine;
                      modelAdd.blCombineKey = blCombineKey;
                      modelAdd.blIsBlPrinted = blIsBlPrinted;
                    } else {
                      modelAdd.bOceanVesselId =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].bOceanVesselId;
                      modelAdd.bOceanVesselVoyage =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].bOceanVesselVoyage;
                      modelAdd.blType =
                        this.gridBLTransferByBL.listStore.store[index].blType;
                      modelAdd.blIsSplit =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsSplit;
                      modelAdd.blSplitKey =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blSplitKey;
                      modelAdd.blIsCombine =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsCombine;
                      modelAdd.blCombineKey =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blCombineKey;
                      modelAdd.blIsBlPrinted =
                        this.gridBLTransferByBL.listStore.store[
                          index
                        ].blIsBlPrinted;
                    }

                    if (modelAdd.blNo != "SSL") {
                      storeTemp.push(modelAdd);
                    }
                  }

                  this.gridBLTransferByBL.listStore.store = [];

                  let modelAdd = new ParamBL();
                  modelAdd.blNo = "SSL";
                  storeTemp.push(modelAdd);

                  this.gridBLTransferByBL.listStore.store = storeTemp;
                  this.gridBLTransferByBL.loadData();

                  // ------------------- check combine child
                  this.mpCombineChild.clear();
                  for (
                    let index = 0;
                    index < this.gridBLTransferByBL.listStore.store.length;
                    index++
                  ) {
                    let blNo = String(
                      this.gridBLTransferByBL.listStore.store[index].blNo
                    ).toUpperCase();
                    let blIsCombine = String(
                      this.gridBLTransferByBL.listStore.store[index].blIsCombine
                    ).toUpperCase();
                    let blCombineKey = String(
                      this.gridBLTransferByBL.listStore.store[index]
                        .blCombineKey
                    ).toUpperCase();
                    console.log(
                      blNo + " - " + blIsCombine + " - " + blCombineKey
                    );
                    if (blIsCombine === "Y" && blCombineKey != "") {
                      this.mpCombineChild.set(index, blCombineKey);
                    }
                  }

                  // ------------------- check duplicate
                  this.mpDuplicate.clear();
                  let mpCheck = new Map<String, String>();
                  for (
                    let index = 0;
                    index < this.gridBLTransferByBL.listStore.store.length;
                    index++
                  ) {
                    let blNo = String(
                      this.gridBLTransferByBL.listStore.store[index].blNo
                    ).toUpperCase();
                    if (mpCheck.has(blNo)) {
                      this.mpDuplicate.set(index, blNo);
                    } else {
                      mpCheck.set(blNo, blNo);
                    }
                  }

                  let vslvyg: String = "";
                  this.mpMainVessels.clear();
                  for (let entry of Array.from(this.mpSameVessels.entries())) {
                    console.log(entry[0] + " - " + entry[1]);
                    if (vslvyg == "") {
                      vslvyg = entry[1];
                    } else {
                      if (vslvyg != entry[1]) {
                        this.mpMainVessels.set(entry[0], entry[1]);
                      }
                    }
                  }

                  if (this.mpCombineChild.size == 0) {
                    if (this.mpDuplicate.size == 0) {
                      if (this.mpMainVessels.size == 0) {
                      } else {
                        this.msg = "BL(s) from different vessels not allowed.";
                        this.genericUtil.showDialog(
                          "msgDialogVessels",
                          "Information",
                          420,
                          160
                        );
                      }
                    } else {
                      this.msg = "Duplicate BL Reference(s) detected.";
                      this.genericUtil.showDialog(
                        "msgDialogDuplicate",
                        "Information",
                        420,
                        160
                      );
                    }
                  } else {
                    for (let entry of Array.from(
                      this.mpCombineChild.entries()
                    )) {
                      console.log(entry[0] + " - " + entry[1]);
                      this.msg =
                        "This is BL Combine, please use " + entry[1] + ".";
                      this.genericUtil.showDialog(
                        "msgDialogCombine",
                        "Information",
                        420,
                        160
                      );
                    }
                  }

                  // ---------------------------------------------------------
                }
              } else {
                if (resp.json()["code"] == "MODE2") {
                  this.msg = resp.json()["message"];
                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                } else if (resp.json()["code"] == "MODE3") {
                  this.msg = resp.json()["message"];
                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                } else {
                  this.msg = "Invalid B/L No.";

                  if (this.gridBLTransferByBL.listStore.store.length != no) {
                    this.gridBLTransferByBL.listStore.store.splice(no - 1, 1);
                    this.gridBLTransferByBL.loadData();
                    this.mpSameVessels.delete(no - 1);
                  } else {
                    this.gridBLTransferByBL.setValue(no, field, "SSL");
                    this.gridBLTransferByBL.loadData();
                  }
                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                }
              }
            });
        }

        break;
      case "AfterEdit":
        if (this.isEnter == false) {
          console.log("** AfterEdit Event **");
          console.log(event);

          var no = Number.parseInt(event.split(".")[1]);
          var field: string = event.split(";")[1];
          var value: string = event.split(";")[2];
          console.log("value => " + value);

          var vesselTo: string = "*";
          if (this.model.blOceanVesselIdTo != "") {
            vesselTo = this.model.blOceanVesselIdTo;
          }

          var voyageTo: string = "*";
          if (this.model.blOceanVesselVoyageTo != "") {
            voyageTo = this.model.blOceanVesselVoyageTo;
          }

          if (value != "SSL") {
            this.genericService
              .GET(
                this.configService.config.BASE_API.toString() +
                  "/BL/checkBlTransferByBlNo/" +
                  this.cookieService.getDefaultLocationCode() +
                  "/" +
                  value +
                  "/" +
                  vesselTo +
                  "/" +
                  this.sCharUtil.htmlEncode(String(voyageTo).trim())
              )
              .subscribe((resp) => {
                if (
                  resp.json()["status"] == "OK" &&
                  resp.json()["code"] == "MODE1"
                ) {
                  /*this.checkValidSchedule(
                    resp.json()["blOceanVesselIdFrom"],
                    this.sCharUtil.htmlEncode(
                      String(resp.json()["blOceanVesselVoyageFrom"]).trim()
                    ),
                    resp.json()["blOceanVesselBound"],
                    this.cookieService.getDefaultLocationCode());  */

                  console.log("RESP checkBlTransferByBlNo :");
                  console.log(resp.json());

                  let vsl = resp.json()["bOceanVesselId"];
                  let vyg = resp.json()["bOceanVesselVoyage"];
                  let blType = resp.json()["blType"];
                  let blCombineKey = resp.json()["blCombineKey"];
                  let blIsCombine = resp.json()["blIsCombine"];
                  let blIsSplit = resp.json()["blIsSplit"];
                  let blSplitKey = resp.json()["blSplitKey"];
                  let blIsBlPrinted = resp.json()["blIsBlPrinted"];

                  console.log(vsl + " - " + vyg);
                  this.mpSameVessels.set(no, vsl + "-" + vyg);

                  if (this.gridBLTransferByBL.listStore.store.length == 1) {
                    console.log("#1");

                    //let modelAdd = new ParamBL;
                    //modelAdd.blNo = "SSL"
                    //this.gridBLTransferByBL.listStore.addData(modelAdd)
                    //this.gridBLTransferByBL.loadData();

                    let storeTemp: any[] = [];
                    for (
                      let index = 0;
                      index < this.gridBLTransferByBL.listStore.store.length;
                      index++
                    ) {
                      let modelAdd = new ParamBL();
                      modelAdd.blNo =
                        this.gridBLTransferByBL.listStore.store[index].blNo;
                      if (value === modelAdd.blNo) {
                        modelAdd.bOceanVesselId = vsl;
                        modelAdd.bOceanVesselVoyage = vyg;
                        modelAdd.blType = blType;
                        modelAdd.blIsSplit = blIsSplit;
                        modelAdd.blSplitKey = blSplitKey;
                        modelAdd.blIsCombine = blIsCombine;
                        modelAdd.blCombineKey = blCombineKey;
                        modelAdd.blIsBlPrinted = blIsBlPrinted;
                      } else {
                        modelAdd.bOceanVesselId =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].bOceanVesselId;
                        modelAdd.bOceanVesselVoyage =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].bOceanVesselVoyage;
                        modelAdd.blType =
                          this.gridBLTransferByBL.listStore.store[index].blType;
                        modelAdd.blIsSplit =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsSplit;
                        modelAdd.blSplitKey =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blSplitKey;
                        modelAdd.blIsCombine =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsCombine;
                        modelAdd.blCombineKey =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blCombineKey;
                        modelAdd.blIsBlPrinted =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsBlPrinted;
                      }

                      if (modelAdd.blNo != "SSL") {
                        storeTemp.push(modelAdd);
                      }
                    }

                    this.gridBLTransferByBL.listStore.store = [];

                    let modelAdd = new ParamBL();
                    modelAdd.blNo = "SSL";
                    storeTemp.push(modelAdd);

                    this.gridBLTransferByBL.listStore.store = storeTemp;
                    this.gridBLTransferByBL.loadData();

                    // ------------------- check combine child
                    this.mpCombineChild.clear();
                    for (
                      let index = 0;
                      index < this.gridBLTransferByBL.listStore.store.length;
                      index++
                    ) {
                      let blNo = String(
                        this.gridBLTransferByBL.listStore.store[index].blNo
                      ).toUpperCase();
                      let blIsCombine = String(
                        this.gridBLTransferByBL.listStore.store[index]
                          .blIsCombine
                      ).toUpperCase();
                      let blCombineKey = String(
                        this.gridBLTransferByBL.listStore.store[index]
                          .blCombineKey
                      ).toUpperCase();

                      if (blIsCombine === "Y" && blCombineKey != "") {
                        this.mpCombineChild.set(index, blCombineKey);
                      }
                    }

                    if (this.mpCombineChild.size == 0) {
                    } else {
                      for (let entry of Array.from(
                        this.mpCombineChild.entries()
                      )) {
                        console.log(entry[0] + " - " + entry[1]);
                        this.msg =
                          "This is BL Combine, please use " + entry[1] + ".";
                        this.genericUtil.showDialog(
                          "msgDialogCombine",
                          "Information",
                          420,
                          160
                        );
                      }
                    }
                  } else {
                    console.log("#2");

                    let storeTemp: any[] = [];
                    for (
                      let index = 0;
                      index < this.gridBLTransferByBL.listStore.store.length;
                      index++
                    ) {
                      let modelAdd = new ParamBL();
                      modelAdd.blNo =
                        this.gridBLTransferByBL.listStore.store[index].blNo;

                      if (value === modelAdd.blNo) {
                        modelAdd.bOceanVesselId = vsl;
                        modelAdd.bOceanVesselVoyage = vyg;
                        modelAdd.blType = blType;
                        modelAdd.blIsSplit = blIsSplit;
                        modelAdd.blSplitKey = blSplitKey;
                        modelAdd.blIsCombine = blIsCombine;
                        modelAdd.blCombineKey = blCombineKey;
                        modelAdd.blIsBlPrinted = blIsBlPrinted;
                      } else {
                        modelAdd.bOceanVesselId =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].bOceanVesselId;
                        modelAdd.bOceanVesselVoyage =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].bOceanVesselVoyage;
                        modelAdd.blType =
                          this.gridBLTransferByBL.listStore.store[index].blType;
                        modelAdd.blIsSplit =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsSplit;
                        modelAdd.blSplitKey =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blSplitKey;
                        modelAdd.blIsCombine =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsCombine;
                        modelAdd.blCombineKey =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blCombineKey;
                        modelAdd.blIsBlPrinted =
                          this.gridBLTransferByBL.listStore.store[
                            index
                          ].blIsBlPrinted;
                      }

                      if (modelAdd.blNo != "SSL") {
                        storeTemp.push(modelAdd);
                      }
                    }

                    this.gridBLTransferByBL.listStore.store = [];

                    let modelAdd = new ParamBL();
                    modelAdd.blNo = "SSL";
                    storeTemp.push(modelAdd);

                    this.gridBLTransferByBL.listStore.store = storeTemp;
                    this.gridBLTransferByBL.loadData();

                    this.mpCombineChild.clear();
                    for (
                      let index = 0;
                      index < this.gridBLTransferByBL.listStore.store.length;
                      index++
                    ) {
                      let blNo = String(
                        this.gridBLTransferByBL.listStore.store[index].blNo
                      ).toUpperCase();
                      let blIsCombine = String(
                        this.gridBLTransferByBL.listStore.store[index]
                          .blIsCombine
                      ).toUpperCase();
                      let blCombineKey = String(
                        this.gridBLTransferByBL.listStore.store[index]
                          .blCombineKey
                      ).toUpperCase();

                      if (blIsCombine === "Y" && blCombineKey != "") {
                        this.mpCombineChild.set(index, blCombineKey);
                      }
                    }

                    // ------------------- check duplicate
                    this.mpDuplicate.clear();
                    let mpCheck = new Map<String, String>();
                    for (
                      let index = 0;
                      index < this.gridBLTransferByBL.listStore.store.length;
                      index++
                    ) {
                      let blNo = String(
                        this.gridBLTransferByBL.listStore.store[index].blNo
                      ).toUpperCase();
                      if (mpCheck.has(blNo)) {
                        this.mpDuplicate.set(index, blNo);
                      } else {
                        mpCheck.set(blNo, blNo);
                      }
                    }

                    let vslvyg: String = "";
                    this.mpMainVessels.clear();
                    for (let entry of Array.from(
                      this.mpSameVessels.entries()
                    )) {
                      console.log(entry[0] + " - " + entry[1]);
                      if (vslvyg == "") {
                        vslvyg = entry[1];
                      } else {
                        if (vslvyg != entry[1]) {
                          this.mpMainVessels.set(entry[0], entry[1]);
                        }
                      }
                    }

                    if (this.mpCombineChild.size == 0) {
                      if (this.mpDuplicate.size == 0) {
                        if (this.mpMainVessels.size == 0) {
                        } else {
                          this.msg =
                            "BL(s) from different vessels not allowed.";
                          this.genericUtil.showDialog(
                            "msgDialogVessels",
                            "Information",
                            420,
                            160
                          );
                        }
                      } else {
                        this.msg = "Duplicate BL Reference(s) detected.";
                        this.genericUtil.showDialog(
                          "msgDialogDuplicate",
                          "Information",
                          420,
                          160
                        );
                      }
                    } else {
                      for (let entry of Array.from(
                        this.mpCombineChild.entries()
                      )) {
                        console.log(entry[0] + " - " + entry[1]);
                        this.msg =
                          "This is BL Combine, please use " + entry[1] + ".";
                        this.genericUtil.showDialog(
                          "msgDialogCombine",
                          "Information",
                          420,
                          160
                        );
                      }
                    }

                    // ---------------------------------------------------------
                  }
                } else {
                  if (resp.json()["code"] == "MODE2") {
                    this.msg = resp.json()["message"];
                  } else if (resp.json()["code"] == "MODE3") {
                    this.msg = resp.json()["message"];
                  } else {
                    this.msg = "Invalid B/L No.";

                    if (this.gridBLTransferByBL.listStore.store.length != no) {
                      this.gridBLTransferByBL.listStore.store.splice(no, 1);
                      this.gridBLTransferByBL.loadData();
                      this.mpSameVessels.delete(no);
                    } else {
                      this.gridBLTransferByBL.setValueNoEvent(no, field, "SSL");
                      this.gridBLTransferByBL.loadData();
                    }
                  }

                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                }
              });
          }
        } else {
          this.isEnter = false;
        }
        break;
      default:
        break;
    }
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          voyage +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to transfer B/L.",
            "okonly",
            {
              /*ok: "this.handleCancel();"*/
            }
          );
          this.handleCancel();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  handleRetrieve() {
    /*
    this.store = [];
    this.setValidatorRetrieveTransferByVessel()
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.blOceanVesselCodeFrom = this.model.blOceanVesselCodeFrom.trim();

    this.isError = this.onValidate(this.model);
    if(!this.isError){

      this.genericService.POST(this.configService.config.BASE_API.toString() + "/BLTransfer/searchBLAll",this.model).subscribe((resp) => {
        this.store = resp.json()['content'];
        console.log("** ALL **")
        console.log(this.store);

        this.gridBLTransferByVessel.setBody(this.model);
        this.gridBLTransferByVessel.loadData();
      })
    }
    */
    this.store = [];
    this.setValidatorRetrieveTransferByVessel();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.blOceanVesselCodeFrom = this.model.blOceanVesselCodeFrom.trim();

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      this.genericUtil.showLoader();
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/BLTransfer/searchBLAll",
          this.model
        )
        .pipe(finalize(() => this.genericUtil.hideLoader()))
        .subscribe((resp) => {
          this.store = resp.json()["content"];
          console.log("** ALL **");
          console.log(this.store);
          this.gridBLTransferByVessel.listStore.store = this.store;
          this.gridBLTransferByVessel.loadData();
        });
    }
  }

  handleCancel() {
    this.isError = false;
    this.model["error-blOceanVesselCodeFrom"] = "";
    this.model["error-blOceanVesselVoyageFrom"] = "";
    this.model["error-blOceanVesselBoundFrom"] = "";
    this.model["error-blOceanVesselCodeTo"] = "";
    this.model["error-blOceanVesselVoyageTo"] = "";
    this.model["error-blOceanVesselBoundTo"] = "";
    this.model["error-blNoValidator"] = "";

    this.model.blOceanVesselIdTo = "";
    this.model.blOceanVesselVoyageTo = "";

    this.model = new Param();
    this.model.blOceanVesselBoundFrom = "O";
    this.model.blOceanVesselBoundTo = "O";
    this.model.blOceanVesselIdTo = "";
    this.model.blOceanVesselVoyageTo = "";
    this.cbVesselFrom.setValue("");
    this.cbVoyageFrom.setValue("");
    this.cbVesselTo.setValue("");
    this.cbVoyageTo.setValue("");
    this.portOfCallFrom = "";
    this.portOfCallTo = "";

    if (this.radioTransferByVessel == "Y") {
      this.disableToolbarButtons = "";
      this.gridBLTransferByVessel.onClear();
    }

    if (this.radioTransferByVessel == "N") {
      this.disableToolbarButtons = "retrieve";
      this.gridBLTransferByBL.listStore.store = [];
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.addData(modelAdd);
      this.gridBLTransferByBL.loadData();
    }

    this.mpSameVessels.clear();
  }

  handleTransfer() {
    /*
    if(this.radioTransferByVessel == 'Y'){
      
      this.setValidatorTransferByVessel();
      this.model['blNoValidator'] = this.checkBLNoValidator();

      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.isError = this.onValidate(this.model);
      if(!this.isError){
        this.msg = "Do you really want to transfer the BL?";
        this.genericUtil.showDialog('beforeSaveDialog', "Information", 450, 160);
      }

    }

    if(this.radioTransferByVessel == 'N'){
      this.mpDuplicate.clear()
      let mpCheck = new Map<String,String>();
      for (let index = 0; index < this.gridBLTransferByBL.listStore.store.length; index++) {
        let blNo = this.gridBLTransferByBL.listStore.store[index].blNo;
        if(mpCheck.has(blNo)){
          this.mpDuplicate.set(index,blNo);
        }else{
          mpCheck.set(blNo,blNo);
        }
      }

    
      let vslvyg :String = "";
      this.mpMainVessels.clear()
      for(let entry of Array.from(this.mpSameVessels.entries())){
        console.log(entry[0] + " - " + entry[1]);
        if(vslvyg == ""){
          vslvyg = entry[1];
        }else{
          if(vslvyg != entry[1]){
            this.mpMainVessels.set(entry[0],entry[1]);
          }
        }
      }
      
      if(this.mpDuplicate.size == 0){
        this.setValidatorTransferByBL();
        this.model['blNoValidator'] = false;
        let storeTemp :any[] = []
        for (let index = 0; index < this.gridBLTransferByBL.listStore.store.length; index++) {
          let modelAdd = new ParamBL;
          modelAdd.blNo = this.gridBLTransferByBL.listStore.store[index].blNo;
          if(modelAdd.blNo != 'SSL'){
            storeTemp.push(modelAdd);
          }
        }
        console.log(storeTemp.length);
        if(storeTemp.length == 0){
          this.model['blNoValidator'] = true;
        }
      }


      if(this.mpDuplicate.size == 0){
        if(this.mpMainVessels.size == 0){
          console.log(this.model);
          this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
          this.isError = this.onValidate(this.model);
          if(!this.isError){
            this.msg = "Do you really want to transfer the BL?";
            this.genericUtil.showDialog('beforeSaveDialog', "Information", 450, 160);
          }
        }else{
          this.msg = "BL(s) from different vessels not allowed.";
          this.genericUtil.showDialog('msgDialogVessels', "Information", 420, 160);   
        }
      }else{
        this.msg = "Duplicate BL Reference(s) detected.";
        this.genericUtil.showDialog('msgDialogDuplicate', "Information", 420, 160);    
      }

    }
    */

    if (this.radioTransferByVessel == "Y") {
      this.setValidatorTransferByVessel();
      this.model["blNoValidator"] = this.checkBLNoValidator();
    }

    if (this.radioTransferByVessel == "N") {
      this.setValidatorTransferByBL();
      this.model["blNoValidator"] = false;
      let storeTemp: any[] = [];
      for (
        let index = 0;
        index < this.gridBLTransferByBL.listStore.store.length;
        index++
      ) {
        let modelAdd = new ParamBL();
        modelAdd.blNo = this.gridBLTransferByBL.listStore.store[index].blNo;
        if (modelAdd.blNo != "SSL") {
          storeTemp.push(modelAdd);
        }
      }
      console.log(storeTemp.length);
      if (storeTemp.length == 0) {
        this.model["blNoValidator"] = true;
      }
    }

    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      this.msg = "Do you really want to transfer the BL?";
      this.genericUtil.showDialog("beforeSaveDialog", "Information", 450, 160);
    }
  }

  onTransfer() {
    console.log("** onTransfer **");

    this.closeBeforeSaveDialog();
    this.model.user = this.cookieService.getName();

    if (this.radioTransferByVessel == "N") {
      this.setValidatorTransferByBL();
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.model["blNoValidator"] = this.checkBLNoValidator();
      this.model["transferByVessel"] = "N";

      this.isError = this.onValidate(this.model);

      if (!this.isError) {
        console.log("#1");
        console.log(this.gridBLTransferByBL.listStore.store.length);
        let idx2: number = 0;
        this.gridBLTransferByBL.listStore.store.forEach((element) => {
          if (element["blNo"] != "SSL") {
            idx2++;
          }
        });

        console.log(idx2);

        let idx: number = 0;

        this.mpCombineChild.clear();
        for (
          let index = 0;
          index < this.gridBLTransferByBL.listStore.store.length;
          index++
        ) {
          let blNo = String(
            this.gridBLTransferByBL.listStore.store[index].blNo
          ).toUpperCase();

          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BL/checkBlTransferByBlNo/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                blNo +
                "/" +
                this.model.blOceanVesselIdTo +
                "/" +
                this.sCharUtil.htmlEncode(
                  String(this.model.blOceanVesselVoyageTo).trim()
                )
            )
            .subscribe((resp) => {
              if (
                resp.json()["status"] == "OK" &&
                resp.json()["code"] == "MODE1"
              ) {
                let vsl = resp.json()["bOceanVesselId"];
                let vyg = resp.json()["bOceanVesselVoyage"];
                let blType = resp.json()["blType"];
                let blCombineKey = resp.json()["blCombineKey"];
                let blIsCombine = resp.json()["blIsCombine"];
                let blIsSplit = resp.json()["blIsSplit"];
                let blSplitKey = resp.json()["blSplitKey"];
                let blIsBlPrinted = resp.json()["blIsBlPrinted"];
                if (blIsCombine === "Y" && blCombineKey != "") {
                  this.mpCombineChild.set(index, blCombineKey);
                }
              }

              if (blNo != "SSL") {
                idx++;
              }

              console.log(
                "this.mpCombineChild.size = " + this.mpCombineChild.size
              );
              if (idx == idx2) {
                if (this.mpCombineChild.size == 0) {
                  console.log("** proses transfer **");

                  this.gridBLTransferByBL.listStore.store.forEach((element) => {
                    element["select"] = "true";
                  });
                  this.model["bls"] = this.gridBLTransferByBL.listStore.store;
                  console.log(this.model);

                  this.genericService
                    .POST(
                      this.configService.config.BASE_API.toString() +
                        "/BLTransfer/transfer",
                      this.model
                    )
                    .subscribe((resp) => {
                      if (resp.json()["status"] == "OK") {
                        this.msg = resp.json()["message"];

                        if (this.msg == "") {
                          this.genericUtil.showDialog(
                            "afterSaveDialog",
                            "Information",
                            420,
                            160
                          );
                        } else {
                          this.genericUtil.showDialog(
                            "msgDialog",
                            "Information",
                            860,
                            260
                          );
                        }
                      } else {
                        this.msg = resp.json()["message"];
                        this.genericUtil.showDialog(
                          "msgDialog",
                          "Information",
                          420,
                          160
                        );
                      }
                    });
                } else {
                  for (let entry of Array.from(this.mpCombineChild.entries())) {
                    console.log(entry[0] + " - " + entry[1]);
                    this.msg =
                      "This is BL Combine, please use " + entry[1] + ".";
                    this.genericUtil.showDialog(
                      "msgDialog2",
                      "Information",
                      420,
                      160
                    );
                  }
                }
              }
            });
        }

        /*
        
        */
      }
    } else {
      this.setValidatorTransferByVessel();
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.model["blNoValidator"] = this.checkBLNoValidator();
      this.model["transferByVessel"] = "Y";

      this.isError = this.onValidate(this.model);
      if (!this.isError) {
        //let datas:any[] = []

        this.gridBLTransferByVessel.listStore.store.forEach((element) => {
          element["select"] = "true";
          //datas.push(element);
        });
        this.model["bls"] = this.gridBLTransferByVessel.listStore.store;
        //this.model['bls'] = datas
        console.log(this.model);

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/BLTransfer/transfer",
            this.model
          )
          .subscribe((resp) => {
            if (resp.json()["status"] == "OK") {
              this.msg = resp.json()["message"];
              console.log(this.msg);

              if (this.msg == "") {
                this.genericUtil.showDialog(
                  "afterSaveDialog",
                  "Information",
                  420,
                  160
                );
              } else {
                if (
                  this.msg ==
                  "BL(s) not transferred. Similar vessel & voyage detected."
                ) {
                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    420,
                    160
                  );
                } else {
                  this.genericUtil.showDialog(
                    "msgDialog",
                    "Information",
                    860,
                    260
                  );
                }
              }
            } else {
              this.msg = resp.json()["message"];
              this.genericUtil.showDialog("msgDialog", "Information", 420, 160);
            }
          });
      }
    }
  }

  checkBLNoValidator(): Boolean {
    console.log("** check BL No Validator **");
    let result: Boolean = false;
    if (this.radioTransferByVessel == "Y") {
      if (this.gridBLTransferByVessel.listStore.store.length == 0) {
        this.model["error-blNoValidator"] =
          "BL(s) should not be empty. Please input BL at least one BL.";
        result = true;
      } else {
        this.gridBLTransferByVessel.listStore.store.forEach((element) => {
          console.log(element);
        });
      }
    } else {
      if (this.gridBLTransferByBL.listStore.store.length == 0) {
        this.model["error-blNoValidator"] =
          "BL(s) should not be empty. Please input BL at least one BL.";
        result = true;
      } else {
        this.gridBLTransferByBL.listStore.store.forEach((element) => {
          console.log(element);
        });
      }
    }
    return result;
  }

  closeMsgDialog() {
    this.genericUtil.closeDialog("msgDialog");
  }

  closeMsgDialog2() {
    let hit = 0;
    for (let entry of Array.from(this.mpCombineChild.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      if (hit > 0) {
        i = entry[0] - 1;
      }
      this.gridBLTransferByBL.listStore.removeData(
        this.gridBLTransferByBL.listStore.store[i]
      );
      this.gridBLTransferByBL.loadData();
      hit++;
    }

    if (this.gridBLTransferByBL.listStore.store.length == 0) {
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.store.push(modelAdd);
      this.gridBLTransferByBL.loadData();
    }
    this.genericUtil.closeDialog("msgDialog2");
  }

  closeMsgDialogDuplicate() {
    let hit = 0;
    for (let entry of Array.from(this.mpDuplicate.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      if (hit > 0) {
        i = entry[0] - 1;
      }
      this.gridBLTransferByBL.listStore.removeData(
        this.gridBLTransferByBL.listStore.store[i]
      );
      this.gridBLTransferByBL.loadData();
      hit++;
    }

    this.genericUtil.closeDialog("msgDialogDuplicate");
  }

  closeMsgDialogVessels() {
    let hit = 0;
    for (let entry of Array.from(this.mpMainVessels.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      //if(hit > 0){
      i = entry[0] - 1;
      //}
      this.gridBLTransferByBL.listStore.removeData(
        this.gridBLTransferByBL.listStore.store[i]
      );
      this.gridBLTransferByBL.loadData();

      this.mpSameVessels.delete(entry[0]);
      hit++;
    }

    this.genericUtil.closeDialog("msgDialogVessels");
  }

  closeMsgDialogCombine() {
    let hit = 0;
    for (let entry of Array.from(this.mpCombineChild.entries())) {
      console.log(entry[0] + " - " + entry[1]);
      let i = entry[0];
      if (hit > 0) {
        i = entry[0] - 1;
      }
      this.gridBLTransferByBL.listStore.removeData(
        this.gridBLTransferByBL.listStore.store[i]
      );
      this.gridBLTransferByBL.loadData();
      hit++;
    }

    this.genericUtil.closeDialog("msgDialogCombine");
  }

  closeBeforeSaveDialog() {
    this.genericUtil.closeDialog("beforeSaveDialog");
  }

  closeAfterSaveDialog() {
    if (this.radioTransferByVessel == "Y") {
      //this.handleRetrieve();
      this.disableToolbarButtons = "transfer";
      this.gridBLTransferByVessel.listStore.store = [];
      this.gridBLTransferByVessel.loadData();
    } else {
      this.disableToolbarButtons = "retrieve";
      this.gridBLTransferByBL.listStore.store = [];
      let modelAdd = new ParamBL();
      modelAdd.blNo = "SSL";
      this.gridBLTransferByBL.listStore.addData(modelAdd);
      this.gridBLTransferByBL.loadData();
    }
    this.genericUtil.closeDialog("afterSaveDialog");
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar?: string
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }
}
