import { TransactionBookingService } from './transaction-booking.service';
import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  HostListener,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Booking, specialCharUtil } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  TextareaPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { HttpClient } from "@angular/common/http";
import { Location, LocationStrategy } from "@angular/common";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnApi,
  IsRowSelectable,
} from "ag-grid-community";

import FormValidation from "../../utils/formValidation";

declare var $: any;
declare var window: any;

export class Param {
  bOfficeCode: string = "";
  bOceanVesselId: string = "";
  bOceanVesselCode: string = "";
  bOceanVesselVoyage: string = "";
  bOceanVesselBound: string = "";
  bPot1Code: string = "";
  //bPot1TerminalCode : string='';
  bPorCode: string = "";
  bPolCode: string = "";
  bPodCode: string = "";
  bFDestCode: string = "";
  bContainerOwnership: string = "";
  bTsbooking: string = "";
  bContainerStatus: string = "";
  direction: string = "";
  selected: string = "";
  ETA: string = "";
  ETD: string = "";
  ATA: string = "";
  ATD: string = "";

  allocationValidator: Boolean = false;
  lock7daysValidator: Boolean = false;
  constructor() {}
}

export class GridTotal {
  blIndicator = "";
  bStatus = "";
  bIsFinished = "";
  csp = "";
  bFreightPartyName = "T O T A L";
  bFullNumber = "";
  bPorName = "";
  bPolName = "";
  bPodName = "";
  bPodlName = "";
  bFdestName = "";
  bBookingPartyName = "";
  depotAckStatus = "";
  depotAckTimeStamp = "";
  d20 = 0;
  h20 = 0;
  d40 = 0;
  h40 = 0;
  d45 = 0;
  h45 = 0;
  r20 = 0;
  rh20 = 0;
  r40 = 0;
  rh40 = 0;
  r45 = 0;
  rh45 = 0;
  t20 = 0;
  t40 = 0;
  t45 = 0;

  mt20 = 0;
  mth20 = 0;
  mt40 = 0;
  mth40 = 0;
  mt45 = 0;
  mth45 = 0;
  mtr20 = 0;
  mtrh20 = 0;
  mtr40 = 0;
  mtrh40 = 0;
  mtr45 = 0;
  mtrh45 = 0;
  mtt20 = 0;
  mtt40 = 0;
  mtt45 = 0;

  killslot = 0;

  constructor() {}
}

export class BookingDelete {
  bReason: String = "";
}

@Component({
  selector: "app-transaction-booking-flag",
  templateUrl: "./transaction-booking-flag-page.component.html",
  styleUrls: ["./transaction-booking-flag-page.component.css"],
})
export class TransactionBookingFlagPageComponent
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLocalTranshipment") cbLocalTranshipment: ComboPluginComponent;
  @ViewChild("cbFullEmpty") cbFullEmpty: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("cbFDest") cbFDest: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOL") cbPOL: ComboPluginComponent;
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("txtReason") txtReason: TextareaPluginComponent;

  /* Parameter for information into Footer */
  info = "";
  defaultColumnDefs;
  gridApi;
  columnApi;
  rowData: any;
  rowData2 = [];
  pinnedBottomRowData;
  getRowStyle;

  dataGrid: any;

  formValidation = new FormValidation();

  // * Encode
  sCharUtil = new specialCharUtil();

  // column changes
  pinnedColumns = [];
  sortedColumns: any = undefined;
  filterColumns: any = undefined;
  resizedColumns: any = undefined;

  // progressBar
  progressBarValue = 0;
  progressBarMessage = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;
  currentMenu;
  viewOnlyStatus = false;
  viewSOCStatus = false;
  viewCOCStatus = false;
  disableSOC = false;
  disableCOC = false;
  disableSlot = false;


  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLocalTranshipment;
  settingFullEmpty;
  settingPOD;
  settingFDest;
  settingPOT1;
  settingPOR;
  settingPOL;
  settingGrid;
  settingTxtReason;

  isLoad: boolean = false;

  //GOS-874 & GOS-869
  isChina:boolean = false;
  isErrorDel: Boolean = false;
  model = new BookingDelete();

  validatorRules = {};

  param = new Param();
  isError: Boolean = false;
  isCOC: Boolean = true;
  isSOC: Boolean = true;
  isSLOT: Boolean = true;
  isNVOCC: Boolean = false;

  tsBooking: string = "All";
  // model = new MasterBooking();

  total_soc_allocated: number = 0;
  total_soc_ts: number = 0;
  total_soc_local: number = 0;
  total_soc_ts_deleted: number = 0;
  total_soc_local_deleted: number = 0;
  total_soc_deleted: number = 0;
  total_soc_laden_booked: number = 0;
  total_soc_empty_booked: number = 0;
  total_soc_available: number = 0;
  total_coc_allocated: number = 0;
  total_coc_ts: number = 0;
  total_coc_local: number = 0;
  total_coc_ts_deleted: number = 0;
  total_coc_local_deleted: number = 0;
  total_coc_deleted: number = 0;
  total_coc_laden_booked: number = 0;
  total_coc_empty_booked: number = 0;
  total_coc_available: number = 0;

  total_slot_ts: number = 0;
  total_slot_ts_full: number = 0;
  total_slot_ts_empty: number = 0;
  total_slot_local: number = 0;
  total_slot_local_full: number = 0;
  total_slot_local_empty: number = 0;
  total_slot_allocated: number = 0;
  total_slot_booked: number = 0;
  total_slot_booked_deleted: number = 0;
  total_slot_available: number = 0;
  total_slot_laden_booked: number = 0;
  total_slot_empty_booked: number = 0;

  total_vsl_teus: number = 0;
  total_laden: number = 0;
  total_empty: number = 0;
  total_resv_laden: number = 0;
  total_resv_empty: number = 0;
  total_resv_killslot: number = 0;
  total_resv: number = 0;

  total_soc_killslot: number = 0;
  total_coc_killslot: number = 0;
  total_slot_killslot: number = 0;

  total_laden_reserve_coc: number = 0;
  total_empty_reserve_coc: number = 0;
  total_laden_reserve_soc: number = 0;
  total_empty_reserve_soc: number = 0;
  total_laden_reserve_slot: number = 0;
  total_empty_reserve_slot: number = 0;
  total_reserve: number = 0;
  totalUC: Number = 0;

  eta: string = "";
  etd: string = "";
  ata: string = "";
  atd: string = "";
  labelMenu : String ="";

  fullBookingNo: string = "";
  containerPreSave: any;
  filterModel: any;

  isRowSelectable: IsRowSelectable = (
    params: any
  ) => {
    return params.data.bStatus !== "D";
  };

  get isSingaporeSite() {
    const siteOffice =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
    return siteOffice === 'SGSIN';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private http: HttpClient,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private transactionBookingService: TransactionBookingService
  ) {
    super();

    if (!router.navigated) {
      localStorage.removeItem("pinned-columns");
    }

    locationStrategy.onPopState(() => {
      localStorage.removeItem("pinned-columns");
    });

    // GOS-874 & GOS-869
    if(this.cookieService.getDefaultLocationCode().substring(0,2) === "CN") {
      this.isChina = true;
    }

    this.defaultColumnDefs = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, hide: !this.isChina, // only available from AG Grid 28.1
      },
      { headerName: "BLS", field: "blIndicator", width: 80 },
      { headerName: "S", field: "bStatus", width: 80 },
      { headerName: "F", field: "bIsFinished", width: 80 },
      { headerName: "C/S/P", field: "csp", width: 100 },
      {
        headerName: "Freight Party",
        field: "bFreightPartyName",
        tooltipField: "bFreightPartyName",
      },
      { headerName: "Booking Ref", field: "bFullNumber" },
      { headerName: "Booking Party", field: "bBookingPartyName" },
      {
        headerName: "Shipper",
        field: "bShipperName",
        tooltipField: "bShipperName",
      },
      { headerName: "POR", field: "bPorName" },
      { headerName: "POL", field: "bPolName" },
      { headerName: "POD", field: "bPodName" },
      { headerName: "PODL", field: "bPodlName" },
      { headerName: "POT1", field: "bPot1Name" },
      { headerName: "FDEST", field: "bFdestName" },
      { headerName: "20", field: "d20", width: 80 },
      { headerName: "H20", field: "h20", width: 80 },
      { headerName: "40", field: "d40", width: 80 },
      { headerName: "H40", field: "h40", width: 80 },
      { headerName: "45", field: "d45", width: 80 },
      { headerName: "H45", field: "h45", width: 80 },
      { headerName: "R20", field: "r20", width: 80 },
      { headerName: "RH20", field: "rh20", width: 80 },
      { headerName: "R40", field: "r40", width: 80 },
      { headerName: "RH40", field: "rh40", width: 80 },
      { headerName: "R45", field: "r45", width: 80 },
      { headerName: "RH45", field: "rh45", width: 80 },
      { headerName: "TK20", field: "t20", width: 80 },
      { headerName: "TK40", field: "t40", width: 80 },
      { headerName: "TK45", field: "t45", width: 80 },

      { headerName: "MT 20", field: "mt20", width: 80 },
      { headerName: "MT H20", field: "mth20", width: 80 },
      { headerName: "MT 40", field: "mt40", width: 80 },
      { headerName: "MT H40", field: "mth40", width: 80 },
      { headerName: "MT 45", field: "mt45", width: 80 },
      { headerName: "MT H45", field: "mth45", width: 80 },
      { headerName: "MT R20", field: "mtr20", width: 80 },
      { headerName: "MT RH20", field: "mtrh20", width: 80 },
      { headerName: "MT R40", field: "mtr40", width: 80 },
      { headerName: "MT RH40", field: "mtrh40", width: 80 },
      { headerName: "MT R45", field: "mtr45", width: 80 },
      { headerName: "MT RH45", field: "mtrh45", width: 80 },
      { headerName: "MT TK20", field: "mtt20", width: 80 },
      { headerName: "MT TK40", field: "mtt40", width: 80 },
      { headerName: "MT TK45", field: "mtt45", width: 80 },

      { headerName: "Kill Slot", field: "killslot" },
      {
        headerName: "Mother Vessel",
        field: "motherVessel",
        tooltipField: "motherVessel",
      },
      {
        headerName: "Mother Voyage",
        field: "motherVoyage",
        tooltipField: "motherVoyage",
      },
      {
        headerName: "Next Vessel",
        field: "nextMotherVessel",
        tooltipField: "nextMotherVessel",
      },
      {
        headerName: "Next Voyage",
        field: "nextMotherVoyage",
        tooltipField: "nextMotherVoyage",
      },
      {
        headerName: "(C)",
        field: "vesselVoyageConfirmStatus",
        tooltipField: "vesselVoyageConfirmStatus",
      },
      { headerName: "Remarks", field: "remarks" },
      { headerName: "Created By", field: "userCreated" },
      { headerName: "Created Date", field: "dateCreated" },
      { headerName: "Modified By", field: "userModified" },
      { headerName: "Modified Date", field: "dateModified" },
      { headerName: "Deleted By", field: "userDeleted" },
      { headerName: "Deleted Date", field: "dateDeleted" },
      { headerName: "To PSA", field: "toPSA" },
      { headerName: "Depot", field: "depot" },
      { headerName: "Depot Transmission", field: "depotDate" },
      { headerName: "Depot ACK", field: "depotAckStatus" },
      { headerName: "Depot ACK Time Stamp", field: "depotAckTimeStamp" },
      { headerName: "Release At", field: "releaseAt" },
      { headerName: "Release Date", field: "releaseDate" },
      { headerName: "Depot/Terminal Remarks", field: "bDepotRemarks" },
      { headerName: "CTC PAX", field: "contactPerson" },

      { headerName: "DG Class", field: "bDgClass" },
      { headerName: "R/Type Container", field: "bReceiptTypeExtra" },
      { headerName: "Payment Term", field: "bFreightType" },
      { headerName: "Approved By", field: "bApprovedBy" },
      { headerName: "Approved Date", field: "bApprovedDate" },
      { headerName: "Account PIC", field: "bAccountPic" },
      { headerName: "Commodity", field: "bCommodityName" },
      { headerName: "EDI Booking Commodity", field: "bEdiBookingCommodity", hide: !this.isChina}, // GOS-874 & GOS-869
      { headerName: "OP Code", field: "bSOCode" },
      { headerName: "SO Code", field: "bOPCode" }, //bOPCode
    ];

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.bStatus === "D") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.pinnedBottomRowData = this.rowData2;
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "New Booking", event: "new", icon: "plus" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "remove circle" },
        { name: "Export", event: "export", icon: "file" },
        { name: "CMD", event: "cmd", icon: "file" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: this.labels(),
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLocalTranshipment = {
      id: "cbLocalTranshipment",
      type: "select", // search | select | select input
      url: "assets/json/localTranshipment.json",
      placeholder: "All/Local/Transhipment",
      code: "localTranshipmentCode",
      title: "localTranshipmentName",
    };

    this.settingFullEmpty = {
      id: "cbFullEmpty",
      type: "select", // search | select | select input
      url: "assets/json/fullEmpty.json",
      placeholder: "All/Full/Empty",
      code: "fullEmptyCode",
      title: "fullEmptyName",
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchPOD/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingFDest = {
      id: "cbPODTerminal",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchFDest/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchPOT1/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchPOR/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingPOL = {
      id: "cbPOL",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchPOL/{query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/searchFlagStatus",
      page: 10,
      columns: [
        /*{header: 'No', field: 'no', width: 50},*/
        { header: "BLS", field: "blIndicator", width: 50 },
        { header: "S", field: "bStatus", width: 50 },
        { header: "F", field: "bIsFinished", width: 50 },
        { header: "C/S/P", field: "csp", width: 50 },
        {
          header: "Freight Party",
          field: "bFreightPartyName",
          width: 100,
          type: "string",
        },
        { header: "Booking Ref", field: "bFullNumber", width: 100 },
        { header: "Booking Party", field: "bBookingPartyName", width: 100 },
        {
          header: "Shipper",
          field: "bShipperName",
          width: 100,
          type: "string",
        },
        { header: "POR", field: "bPorName", width: 100, type: "string" },
        { header: "POL", field: "bPolName", width: 100, type: "string" },
        { header: "POD", field: "bPodName", width: 100, type: "string" },
        { header: "PODL", field: "bPodlName", width: 100, type: "string" },
        { header: "POT1", field: "bPot1Name", width: 100, type: "string" },
        { header: "FDEST", field: "bFdestName", width: 100, type: "string" },
        { header: "20", field: "d20", width: 50, bold: "true" },
        { header: "H20", field: "h20", width: 50, bold: "true" },
        { header: "40", field: "d40", width: 50, bold: "true" },
        { header: "H40", field: "h40", width: 50, bold: "true" },
        { header: "45", field: "d45", width: 50, bold: "true" },
        { header: "H45", field: "h45", width: 50, bold: "true" },
        { header: "R20", field: "r20", width: 50, bold: "true" },
        { header: "RH20", field: "rh20", width: 50, bold: "true" },
        { header: "R40", field: "r40", width: 50, bold: "true" },
        { header: "RH40", field: "rh40", width: 50, bold: "true" },
        { header: "R45", field: "r45", width: 50, bold: "true" },
        { header: "RH45", field: "rh45", width: 50, bold: "true" },
        { header: "TK20", field: "t20", width: 50, bold: "true" },
        { header: "TK40", field: "t40", width: 50, bold: "true" },
        { header: "TK45", field: "t45", width: 50, bold: "true" },

        { header: "MT 20", field: "mt20", width: 50, bold: "true" },
        { header: "MT H20", field: "mth20", width: 50, bold: "true" },
        { header: "MT 40", field: "mt40", width: 50, bold: "true" },
        { header: "MT H40", field: "mth40", width: 50, bold: "true" },
        { header: "MT 45", field: "mt45", width: 50, bold: "true" },
        { header: "MT H45", field: "mth45", width: 50, bold: "true" },
        { header: "MT R20", field: "mtr20", width: 50, bold: "true" },
        { header: "MT RH20", field: "mtrh20", width: 50, bold: "true" },
        { header: "MT R40", field: "mtr40", width: 50, bold: "true" },
        { header: "MT RH40", field: "mtrh40", width: 50, bold: "true" },
        { header: "MT R45", field: "mtr45", width: 50, bold: "true" },
        { header: "MT RH45", field: "mtrh45", width: 50, bold: "true" },
        { header: "MT TK20", field: "mtt20", width: 50, bold: "true" },
        { header: "MT TK40", field: "mtt40", width: 50, bold: "true" },
        { header: "MT TK45", field: "mtt45", width: 50, bold: "true" },
        { header: "Kill Slot", field: "killslot", width: 50 },
        { header: "Mother Vessel", field: "motherVessel", width: 50 },
        { header: "Mother Voyage", field: "motherVoyage", width: 50 },
        { header: "Remarks", field: "remarks", width: 500, type: "string" },

        { header: "Created By", field: "userCreated", width: 110 },
        { header: "Created Date", field: "dateCreated", width: 110 },
        { header: "Modified By", field: "userModified", width: 110 },
        { header: "Modified Date", field: "dateModified", width: 110 },
        { header: "Deleted By", field: "userDeleted", width: 110 },
        { header: "Deleted Date", field: "dateDeleted", width: 110 },
        { header: "To PSA", field: "toPSA", width: 100 },
        { header: "Depot", field: "depot", width: 100 },
        { headerName: "Depot Transmission", field: "depotDate", width: 100 },
        { headerName: "Depot ACK", field: "depotAckStatus", width: 100 },
        { headerName: "Depot ACK Time Stamp", field: "depotAckTimeStamp", width: 100 },
        { header: "CTC PAX", field: "contactPerson", width: 100 },

        { header: "DG Class", field: "bDgClass", width: 100 },
        { header: "R/Type Container", field: "bReceiptTypeExtra", width: 100 },
        { header: "Payment Term", field: "bFreightType", width: 100 },
        /*{header: 'ACCT PIC', field: 'a', width: 100},
                    {header: 'Approved By', field: 'a', width: 100},
                    {header: 'Approved Date', field: 'a', width: 100},
                    */
        /*
                    {header: 'T/L/O', field: 't45', width: 50},
                    {header: 'To PSA', field: 't45', width: 50},
                    {header: 'To SDS', field: 't45', width: 50},
                    {header: 'CTC PAX', field: 't45', width: 50}
                    */
      ],
      footer: {
        label: "TOTAL",
      },
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
      enableHideColumns: true,
      sortingColumns: "dateModified",
      sortingDirection: "DESC",
    };

    this.settingTxtReason = {
      id: "txtReason",
      name: "reason",
      placeholder: "-- REASON --",
      cols: 30,
      rows: 7,
      maxlength: 320,
      border: 350,
    };
  }

  ngOnInit() {
    this.rowData = [];
    this.transactionBookingService.containerState$.subscribe(res => {
      if (res) {
        this.containerPreSave = res;
      }
    })
  }

  ngAfterViewInit() {
    this.genericUtil.hideLoader();
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.cbLocalTranshipment.setValue("All/Local/Transshipment");
    this.cbFullEmpty.setValue("All/Full/Empty");
    this.param.bOceanVesselBound = "O";
    this.param.selected = "";
    this.param.bTsbooking = this.tsBooking;

    this.isLoad = false;
    this.setValidatorInit();

    if(this.isChina == false){
      this.invisibleToolbarButtons = "delete";
    }else{
      this.disableToolbarButtons = "delete";
    }

    // console.log("localStorage.getItem('close-booking-to-flag') --> ");
    // console.log(localStorage.getItem("close-booking-to-flag"));
    // console.log("***************************************************************")
    if (localStorage.getItem("close-booking-to-flag") != null) {
      this.param = JSON.parse(localStorage.getItem("close-booking-to-flag"));

      console.log("PARAMS"+this.param);

      var self = this;
      this.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselId={query}"
      );
      this.cbVessel.setValue(
        String(this.param.bOceanVesselId).trim(),
        function (callbackVessel) {
          self.param.bOceanVesselId = self.param.bOceanVesselId;

          //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ self.param.bOceanVesselId +"/"+self.param.bOceanVesselBound+"/" + self.param.bOceanVesselVoyage + "/" + self.param.bOfficeCode );
          self.cbVoyage.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
              self.param.bOfficeCode +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.param.bOceanVesselBound +
              "/voyage_like={query}"
          );
          //self.cbVoyage.setValue(self.param.bOceanVesselVoyage);
          self.cbVoyage.setForceValue(self.param.bOceanVesselVoyage);


          self.cbPOD.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterBookings/searchPOD/" +
              self.param.bPodCode +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.sCharUtil.htmlEncode(
                String(self.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              self.param.bOceanVesselBound +
              "/" +
              self.param.bOfficeCode
          );
          self.cbPOD.setValue(self.param.bPodCode);

          self.cbPOR.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterBookings/searchPOR/" +
              self.param.bPorCode +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.sCharUtil.htmlEncode(
                String(self.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              self.param.bOceanVesselBound +
              "/" +
              self.param.bOfficeCode
          );
          self.cbPOR.setValue(self.param.bPorCode);

          self.cbFDest.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterBookings/searchFDest/" +
              self.param.bFDestCode +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.sCharUtil.htmlEncode(
                String(self.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              self.param.bOceanVesselBound +
              "/" +
              self.param.bOfficeCode
          );
          self.cbFDest.setValue(self.param.bFDestCode);

          self.cbPOT1.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterBookings/searchPOT1/" +
              self.param.bPot1Code +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.sCharUtil.htmlEncode(
                String(self.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              self.param.bOceanVesselBound +
              "/" +
              self.param.bOfficeCode
          );
          self.cbPOT1.setValue(self.param.bPot1Code);

          self.cbPOL.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterBookings/searchPOL/" +
              self.param.bPolCode +
              "/" +
              self.param.bOceanVesselId +
              "/" +
              self.sCharUtil.htmlEncode(
                String(self.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              self.param.bOceanVesselBound +
              "/" +
              self.param.bOfficeCode
          );
          self.cbPOL.setValue(self.param.bPolCode);

          //set soc / coc / slot

          // if (self.param.bContainerOwnership == "SOC|SOCTS|COC|COCTS|SLOT") {
          //   self.isSOC = true;
          //   self.isCOC = true;
          //   self.isSLOT = true;
          // }

          // if (self.param.bContainerOwnership == "SOC|SOCTS|COC|COCTS") {
          //   self.isSOC = true;
          //   self.isCOC = true;
          //   self.isSLOT = false;
          // }

          // if (self.param.bContainerOwnership == "COC|COCTS|SLOT") {
          //   self.isSOC = false;
          //   self.isCOC = true;
          //   self.isSLOT = true;
          // }

          // if (self.param.bContainerOwnership == "SOC|SOCTS|SLOT") {
          //   self.isSOC = true;
          //   self.isCOC = false;
          //   self.isSLOT = true;
          // }

          // if (self.param.bContainerOwnership == "SOC|SOCTS") {
          //   self.isSOC = true;
          //   self.isCOC = false;
          //   self.isSLOT = false;
          // }

          // if (self.param.bContainerOwnership == "COC|COCTS") {
          //   self.isSOC = false;
          //   self.isCOC = true;
          //   self.isSLOT = false;
          // }

          // if (self.param.bContainerOwnership == "SLOT") {
          //   self.isSOC = false;
          //   self.isCOC = false;
          //   self.isSLOT = true;
          // }

          // console.log('self.param.bContainerStatus = ' + self.param.bContainerStatus);

          if (
            self.param.bContainerStatus == "All" ||
            self.param.bContainerStatus == ""
          ) {
            self.cbFullEmpty.setValue("All/Full/Empty");
            self.param.bContainerStatus = "";
          } else {
            self.cbFullEmpty.setValue(self.param.bContainerStatus);
          }

          if (self.param.bTsbooking == "All") {
            self.cbLocalTranshipment.setValue("All/Local/Transshipment");
          } else {
            self.cbLocalTranshipment.setValue(self.param.bTsbooking);
          }

          self.tsBooking = self.param.bTsbooking;

          if (
            self.param.bOceanVesselCode != "" &&
            self.param.bOceanVesselVoyage != ""
          ) {
            self.isLoad = true;
            // console.log('localStorage.getItem("booking-flag-status-current-page") == ' + localStorage.getItem("booking-flag-status-current-page"));
            self.grid.setCurrentPage(
              Number.parseInt(
                localStorage.getItem("booking-flag-status-current-page")
              )
            );
            self.grid.setBody(self.param);
            //self.grid.loadData();
            //self.gridApi.showLoadingOverlay();
            // self.handleRetrieve();


            self.rowData = JSON.parse(
              localStorage.getItem("booking-flag-status-row-data")
            );
            self.rowData2 = JSON.parse(
              localStorage.getItem("booking-flag-status-row-data2")
            );
            self.pinnedBottomRowData = self.rowData2;
            if (
              localStorage.getItem("booking-flag-status-data-grid") !=
                undefined &&
              localStorage.getItem("booking-flag-status-data-grid") !=
                "undefined"
            ) {
              self.dataGrid = JSON.parse(
                localStorage.getItem("booking-flag-status-data-grid")
              );
              self.calculateFooter(self.dataGrid);
            }

            localStorage.removeItem("booking-flag-status-row-data");
            localStorage.removeItem("booking-flag-status-row-data2");
            localStorage.removeItem("booking-flag-status-data-grid");
            localStorage.removeItem("booking-flag-status-data-column-state");
          }
        }
      );

      self.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselName={query}"
      );

      setTimeout(() => {
        self.restoreState();
      }, 10);
      self.isCOC = self.containerPreSave.isCOC;
      self.isSOC = self.containerPreSave.isSOC;
      self.isSLOT = self.containerPreSave.isSLOT;


      //localStorage.removeItem("booking-flag-status-current-page");
      localStorage.removeItem("view-only-mode");
      localStorage.removeItem("close-booking-to-flag");

    }

    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.currentMenu.includes("(SOC)")) {
      this.viewSOCStatus = true;
      /*this.disableCOC = true;
      this.isCOC = false;
      this.disableSlot = true;
      this.isSLOT = false;*/
    }

    if (this.currentMenu.includes("(COC)")) {
      this.viewCOCStatus = true;
      /*this.disableSOC = true;
      this.isSOC = false;
      this.disableSlot = true;
      this.isSLOT = false;*/
    }

    //hardcode
    if (this.cookieService.getName() == "muchtar" || this.viewOnlyStatus) {
      this.disableToolbarButtons = "new";
    }

    //reset the scroll to 0.0
    window.scrollTo(0, 0);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    localStorage.removeItem("pinnedColumns");
  }

  labels(){

    this.labelMenu = "";
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];

    if (this.currentMenu.includes("SOC")) {
      this.labelMenu = "Booking Flag Status SOC";
    } else if  (this.currentMenu.includes("COC")) {
      this.labelMenu = "Booking Flag Status COC";
    }else{
      this.labelMenu = "Booking Flag Status";
    }


    return this.labelMenu;

  }


  changeEventVessel(event) {
    // console.log(event);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.param.bOceanVesselId = "";
      this.param.bOceanVesselCode = "";

      this.cbPOD.setUrl("");
      this.cbPOR.setUrl("");
      this.cbPOL.setUrl("");
      this.cbPOT1.setUrl("");
      this.cbFDest.setUrl("");
      this.param.bPodCode = "";
      this.param.bPot1Code = "";
      this.param.bFDestCode = "";
      this.param.bPolCode = "";
    } else {
      this.param.bOceanVesselId = event.vesselId;
      this.param.bOceanVesselCode = event.vesselCode;
      this.param[`error-bOceanVesselCode`] = "";
      // this.param[`error-bOceanVesselVoyage`] = "Please input Voyage."
      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.param.bOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");

      this.cbPOD.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOD/{query}/" +
          event.vesselId +
          "/*/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOD.setValue("");
      this.param.bPodCode = "";

      this.cbPOR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOR/{query}/" +
          event.vesselId +
          "/*/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOR.setValue("");
      this.param.bPorCode = "";

      this.cbFDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchFDest/{query}/" +
          event.vesselId +
          "/*/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFDest.setValue("");
      this.param.bFDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOT1/{query}/" +
          event.vesselId +
          "/*/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.param.bPot1Code = "";

      this.cbPOL.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOL/{query}/" +
          event.vesselId +
          "/*/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOL.setValue("");
      this.param.bPolCode = "";
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.param.bOceanVesselVoyage = "";
    } else {
      this.param.bOceanVesselVoyage = event.voyage;
      this.param["error-bOceanVesselVoyage"] = "";
      this.cbPOD.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOD/{query}/" +
          this.param.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.param.bOceanVesselVoyage) +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOD.setValue("");
      this.param.bPodCode = "";

      this.cbPOR.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOR/{query}/" +
          this.param.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.param.bOceanVesselVoyage) +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOR.setValue("");
      this.param.bPorCode = "";

      this.cbFDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchFDest/{query}/" +
          this.param.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.param.bOceanVesselVoyage) +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFDest.setValue("");
      this.param.bFDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOT1/{query}/" +
          this.param.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.param.bOceanVesselVoyage) +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.param.bPot1Code = "";

      this.cbPOL.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/searchPOL/{query}/" +
          this.param.bOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.param.bOceanVesselVoyage) +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOL.setValue("");
      this.param.bPolCode = "";

      this.getAllocationAndEstimateArrivalDate(
        this.param.bOceanVesselId,
        this.param.bOceanVesselVoyage,
        this.param.bOceanVesselBound,
        this.cookieService.getDefaultLocationCode()
      );
    }
    this.checkErrorblVesselIdblVoyage();
  }

  getAllocationAndEstimateArrivalDate(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getSailSchedule/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        // console.log(resp.json());
        let data = resp.json();

        this.param.ETA = String(data["ETA"]).trim().replace(/\//gi, "-");
        // console.log('ETA : ' +  this.model.ETA);

        this.param.ETD = String(data["ETD"]).replace(/\//g, "-");
        // console.log('ETD : ' +  this.model.ETD);

        this.param.ATA = String(data["ATA"]).replace(/\//g, "-");
        // console.log('ATA : ' +  this.model.ATA);

        this.param.ATD = String(data["ATD"]).replace(/\//g, "-");
        // console.log('ATD : ' +  this.model.ATD);
      });
  }

  changeBound(event) {
    console.log("before");
    console.log(this.param.bOceanVesselVoyage);
    if (event.target.value == "") {
      this.param.bOceanVesselBound = "";
    } else {
      this.param.bOceanVesselBound = event.target.value.toUpperCase();
      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.param.bOceanVesselId +
          "/" +
          this.param.bOceanVesselBound.toUpperCase() +
          "/voyage_like={query}"
      );
      //this.cbVoyage.setValue("");

      //this.param.bOceanVesselVoyage = '';
      console.log("after");
      console.log(this.param.bOceanVesselVoyage);
    }
  }

  changeContainerSOC(event) {
    if (event.target.checked) {
      this.isSOC = true;
    } else {
      this.isSOC = false;
      /*
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.cbLocalTranshipment.setValue('All');
      }
      */
    }
  }
  changeContainerCOC(event) {
    if (event.target.checked) {
      this.isCOC = true;
    } else {
      this.isCOC = false;
      /*
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.cbLocalTranshipment.setValue('All');
      }
      */
    }
  }
  changeContainerSLOT(event) {
    /*
    if(event.target.checked){
      if(this.tsBooking === 'Transhipment'){
        this.isSLOT = true;
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }else{
        this.isSLOT = true;
      }
    }else{
      this.isSLOT = false;
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }
    }
    */
    if (event.target.checked) {
      this.isSLOT = true;
    } else {
      this.isSLOT = false;
    }
  }

  changeContainerNVOCC(event) {
    /*
    if(event.target.checked){
      if(this.tsBooking === 'Transhipment'){
        this.isNVOCC = true;
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }else{
        this.isNVOCC = true;
      }
    }else{
      this.isNVOCC = false;
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }
    }
    */
    if (event.target.checked) {
      this.isNVOCC = true;
    } else {
      this.isNVOCC = false;
    }
  }
  changeEventLocalTranshipment(event) {
    // console.log(event.title);
    if (event.title == "All/Local/Transshipment") {
      this.tsBooking = "All";
    } else {
      this.tsBooking = event.title;
    }

    this.param.bTsbooking = this.tsBooking;

    /*
    if((event.title === 'Transhipment' || event.title === 'Local' ) && !this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC) {
      // console.log("Please choose container type");
      if(this.tsBooking != ''){
          this.cbLocalTranshipment.setValue(this.tsBooking);
          if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
            this.tsBooking = 'All';
            this.cbLocalTranshipment.setValue('All');
          }
      }else{
          this.tsBooking = 'All';
          this.cbLocalTranshipment.setValue('All');
      }
    }else{
      if(event.title === 'Transhipment' && (this.isSLOT || this.isNVOCC )){
        // console.log("SLOT / NVOCC doesnt have Transhipment");
        if(this.tsBooking != ''){
          this.cbLocalTranshipment.setValue(this.tsBooking);
          if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
            this.tsBooking = 'All';
            this.cbLocalTranshipment.setValue('All');
          }
        }else{
          this.tsBooking = 'All';
          this.cbLocalTranshipment.setValue('All');
        }
      }else{
        this.tsBooking = event.title;
      }
    }
    */
  }
  changeEventFullEmpty(event) {
    // console.log(event);
    if (event.title != "All/Full/Empty") {
      this.param.bContainerStatus = event.title;
    } else {
      this.param.bContainerStatus = "";
    }
  }
  changeEventPOD(event) {
    console.log('pod change fired');
    console.log(event);
    console.log(typeof event.locationCode);
    if (event.locationCode == null || event.locationCode == undefined || typeof event.locationCode === 'undefined') {
      this.param.bPodCode = '';
    } else {
      console.log('sini dong');
      this.param.bPodCode = event.locationCode;
    }

    // re set the url to avoid errors
    this.cbPOD.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterBookings/searchPOD/{query}' +
      '/' +
      this.param.bOceanVesselId +
      '/' +
      this.sCharUtil.htmlEncode(
        String(this.param.bOceanVesselVoyage).trim()
      ) +
      '/' +
      this.param.bOceanVesselBound +
      '/' +
      this.param.bOfficeCode
    );
  }
  changeEventFDest(event) {
    if (event.locationCode == null || event.locationCode == undefined || typeof event.locationCode === 'undefined') {
      this.param.bFDestCode = '';
    } else {
      this.param.bFDestCode = event.locationCode;
    }

    this.cbFDest.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterBookings/searchFDest/{query}' +
      '/' +
      this.param.bOceanVesselId +
      '/' +
      this.sCharUtil.htmlEncode(
        String(this.param.bOceanVesselVoyage).trim()
      ) +
      '/' +
      this.param.bOceanVesselBound +
      '/' +
      this.param.bOfficeCode
    );
  }


  changeEventPOT1(event) {
    if (event.locationCode == null || event.locationCode == undefined || typeof event.locationCode === 'undefined') {
      this.param.bPot1Code = '';
    } else {
      this.param.bPot1Code = event.locationCode;
    }

    this.cbPOT1.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterBookings/searchPOT1/{query}' +
      '/' +
      this.param.bOceanVesselId +
      '/' +
      this.sCharUtil.htmlEncode(
        String(this.param.bOceanVesselVoyage).trim()
      ) +
      '/' +
      this.param.bOceanVesselBound +
      '/' +
      this.param.bOfficeCode
    );


  }

  changeEventPOR(event) {
    if (event.locationCode == null || event.locationCode == undefined || typeof event.locationCode === 'undefined') {
      this.param.bPorCode = '';
    } else {
      this.param.bPorCode = event.locationCode;
    }

    this.cbPOL.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterBookings/searchPOR/{query}' +
      '/' +
      this.param.bOceanVesselId +
      '/' +
      this.sCharUtil.htmlEncode(
        String(this.param.bOceanVesselVoyage).trim()
      ) +
      '/' +
      this.param.bOceanVesselBound +
      '/' +
      this.param.bOfficeCode
    );

  }

  changeEventPOL(event) {
    if (event.locationCode == null || event.locationCode == undefined || typeof event.locationCode === 'undefined') {
      this.param.bPolCode = '';
    } else {
      this.param.bPolCode = event.locationCode;
    }

    this.cbPOL.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterBookings/searchPOL/{query}' +
      '/' +
      this.param.bOceanVesselId +
      '/' +
      this.sCharUtil.htmlEncode(
        String(this.param.bOceanVesselVoyage).trim()
      ) +
      '/' +
      this.param.bOceanVesselBound +
      '/' +
      this.param.bOfficeCode
    );

  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  gridEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        var mode = event.split(".")[1].split("-")[0];
        // console.log(mode);
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          // console.log(arr[0]);
          // console.log(arr[1]);
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.fullBookingNo = "";
              let dt = this.grid.getSelectedValues()[0];
              if (dt.bStatus == "D") {
                this.message(
                  "warning",
                  "Information",
                  "Booking already deleted.",
                  "okonly",
                  { ok: "" }
                );
              } else {
                if (dt.bIsUnlocked == "N") {
                  this.fullBookingNo = dt.bPrefix + "-" + dt.bNo;
                  this.message(
                    "information",
                    "Confirmation",
                    "Current booking is locked! View only?",
                    "yesno",
                    {
                      yes: "this.continueToMaintenance(this.fullBookingNo);",
                      no: "",
                    }
                  );
                } else {
                  this.fullBookingNo = dt.bPrefix + "-" + dt.bNo;
                  this.continueToMaintenance(this.fullBookingNo);
                  /*
                  this.genericUtil.showLoaderPage();
                  var self = this;
                  setTimeout(function(){

                    self.param.selected = dt.bPrefix + "-" + dt.bNo;

                    localStorage.setItem("retrieve-booking-from-flag",JSON.stringify(self.param));
                    localStorage.setItem("booking-flag-status-current-page",self.grid.getCurrentPage().toString());
                    self.router.navigate(['/main/transaction-booking-maintenance']);
                  },100);
                  */
                }
              }

              break;
            case "footer":
              // console.log(JSON.parse(arr[1].toString()));

              //GSO-1068 new field for slot
              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTLOCAL"] != null
              ) {
                this.total_slot_local = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTLOCAL"];
              } else {
                this.total_slot_local = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTLOCALFull"] != null
              ) {
                this.total_slot_local_full = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTLOCALFull"];
              } else {
                this.total_slot_local_full = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTLOCALEmpty"] != null
              ) {
                this.total_slot_local_empty = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTLOCALEmpty"];
              } else {
                this.total_slot_local_empty = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTTS"] != null
              ) {
                this.total_slot_ts = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTTS"];
              } else {
                this.total_slot_ts = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTTSFull"] != null
              ) {
                this.total_slot_ts_full = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTTSFull"];
              } else {
                this.total_slot_ts_full = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTTSEmpty"] != null
              ) {
                this.total_slot_ts_empty = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTTSEmpty"];
              } else {
                this.total_slot_ts_empty = 0;
              }

              //new section to calculate the kill slot
              if (
                JSON.parse(arr[1].toString())["total"][0]["killSlotCOC"] != null
              ) {
                this.total_coc_killslot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["killSlotCOC"];
              } else {
                this.total_coc_killslot = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["killSlotSOC"] != null
              ) {
                this.total_soc_killslot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["killSlotSOC"];
              } else {
                this.total_soc_killslot = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["killSlotSLOT"] !=
                null
              ) {
                this.total_slot_killslot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["killSlotSLOT"];
              } else {
                this.total_slot_killslot = 0;
              }

              if (JSON.parse(arr[1].toString())["allotment"]["SOC"] != null) {
                this.total_soc_allocated = JSON.parse(arr[1].toString())[
                  "allotment"
                ]["SOC"];
              } else {
                this.total_soc_allocated = 0;
              }

              if (JSON.parse(arr[1].toString())["total"][0]["SOCTS"] != null) {
                this.total_soc_ts = JSON.parse(arr[1].toString())["total"][0][
                  "SOCTS"
                ];
              } else {
                this.total_soc_ts = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["SOCTSDeleted"] !=
                null
              ) {
                this.total_soc_ts_deleted = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SOCTSDeleted"];
              } else {
                this.total_soc_ts_deleted = 0;
              }

              if (JSON.parse(arr[1].toString())["total"][0]["SOC"] != null) {
                this.total_soc_local = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SOC"];
              } else {
                this.total_soc_local = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["SOCDeleted"] != null
              ) {
                this.total_soc_local_deleted = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SOCDeleted"];
              } else {
                this.total_soc_local_deleted = 0;
              }

              this.total_soc_deleted =
                this.total_soc_local_deleted + this.total_soc_ts_deleted;

              if (
                JSON.parse(arr[1].toString())["total"][0]["LadenSOC"] != null
              ) {
                this.total_soc_laden_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["LadenSOC"];
              } else {
                this.total_soc_laden_booked = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["EmptySOC"] != null
              ) {
                this.total_soc_empty_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["EmptySOC"];
              } else {
                this.total_soc_empty_booked = 0;
              }

              this.total_soc_available =
                this.total_soc_allocated -
                (this.total_soc_local + this.total_soc_ts) -
                this.total_soc_killslot;

              if (JSON.parse(arr[1].toString())["allotment"]["COC"] != null) {
                this.total_coc_allocated = JSON.parse(arr[1].toString())[
                  "allotment"
                ]["COC"];
              } else {
                this.total_coc_allocated = 0;
              }

              if (JSON.parse(arr[1].toString())["total"][0]["COCTS"] != null) {
                this.total_coc_ts = JSON.parse(arr[1].toString())["total"][0][
                  "COCTS"
                ];
              } else {
                this.total_coc_ts = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["COCTSDeleted"] !=
                null
              ) {
                this.total_coc_ts_deleted = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["COCTSDeleted"];
              } else {
                this.total_coc_ts_deleted = 0;
              }

              if (JSON.parse(arr[1].toString())["total"][0]["COC"] != null) {
                this.total_coc_local = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["COC"];
              } else {
                this.total_coc_local = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["COCDeleted"] != null
              ) {
                this.total_coc_local_deleted = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["COCDeleted"];
              } else {
                this.total_coc_local_deleted = 0;
              }

              this.total_coc_deleted =
                this.total_coc_local_deleted + this.total_coc_ts_deleted;

              if (
                JSON.parse(arr[1].toString())["total"][0]["LadenCOC"] != null
              ) {
                this.total_coc_laden_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["LadenCOC"];
              } else {
                this.total_coc_laden_booked = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["EmptyCOC"] != null
              ) {
                this.total_coc_empty_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["EmptyCOC"];
              } else {
                this.total_coc_empty_booked = 0;
              }

              this.total_coc_available =
                this.total_coc_allocated -
                (this.total_coc_local + this.total_coc_ts) -
                this.total_coc_killslot;

              if (JSON.parse(arr[1].toString())["allotment"]["SLOT"] != null) {
                this.total_slot_allocated = JSON.parse(arr[1].toString())[
                  "allotment"
                ]["SLOT"];
              } else {
                this.total_slot_allocated = 0;
              }

              if (JSON.parse(arr[1].toString())["total"][0]["SLOT"] != null) {
                this.total_slot_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOT"];
              } else {
                this.total_slot_booked = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["SLOTDeleted"] != null
              ) {
                this.total_slot_booked_deleted = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["SLOTDeleted"];
              } else {
                this.total_slot_booked_deleted = 0;
              }

              this.total_slot_available =
                this.total_slot_allocated -
                this.total_slot_booked -
                this.total_slot_killslot;

              if (JSON.parse(arr[1].toString())["allotment"]["VS"] != null) {
                this.total_vsl_teus = JSON.parse(arr[1].toString())[
                  "allotment"
                ]["VS"];
              } else {
                this.total_vsl_teus = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["ladenSLOT"] != null
              ) {
                this.total_slot_laden_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["ladenSLOT"];
              } else {
                this.total_slot_laden_booked = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["emptySLOT"] != null
              ) {
                this.total_slot_empty_booked = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["emptySLOT"];
              } else {
                this.total_slot_empty_booked = 0;
              }

              this.total_laden =
                this.total_coc_laden_booked +
                this.total_soc_laden_booked +
                this.total_slot_laden_booked;

              this.total_empty =
                this.total_coc_empty_booked +
                this.total_soc_empty_booked +
                this.total_slot_empty_booked;

              if (
                JSON.parse(arr[1].toString())["sailSchedule"]["ETA"] != null
              ) {
                this.eta = String(
                  JSON.parse(arr[1].toString())["sailSchedule"]["ETA"]
                ).replace(/\//g, "-");
              } else {
                this.eta = "";
              }

              if (
                JSON.parse(arr[1].toString())["sailSchedule"]["ETD"] != null
              ) {
                this.etd = String(
                  JSON.parse(arr[1].toString())["sailSchedule"]["ETD"]
                ).replace(/\//g, "-");
              } else {
                this.etd = "";
              }

              if (
                JSON.parse(arr[1].toString())["sailSchedule"]["ATA"] != null
              ) {
                this.ata = String(
                  JSON.parse(arr[1].toString())["sailSchedule"]["ATA"]
                ).replace(/\//g, "-");
              } else {
                this.ata = "";
              }

              if (
                JSON.parse(arr[1].toString())["sailSchedule"]["ATD"] != null
              ) {
                this.atd = String(
                  JSON.parse(arr[1].toString())["sailSchedule"]["ATD"]
                ).replace(/\//g, "-");
              } else {
                this.atd = "";
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["ladenReserve"] !=
                null
              ) {
                this.total_resv_laden = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["ladenReserve"];
              } else {
                this.total_resv_laden = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["emptyReserve"] !=
                null
              ) {
                this.total_resv_empty = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["emptyReserve"];
              } else {
                this.total_resv_empty = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["totalKillslot"] !=
                null
              ) {
                this.total_resv_killslot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["totalKillslot"];
              } else {
                this.total_resv_killslot = 0;
              }

              this.total_resv = this.total_resv_laden + this.total_resv_empty;

              if (
                JSON.parse(arr[1].toString())["total"][0]["ladenReserveCOC"] !=
                null
              ) {
                this.total_laden_reserve_coc = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["ladenReserveCOC"];
              } else {
                this.total_laden_reserve_coc = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["emptyReserveCOC"] !=
                null
              ) {
                this.total_empty_reserve_coc = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["emptyReserveCOC"];
              } else {
                this.total_empty_reserve_coc = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["ladenReserveSOC"] !=
                null
              ) {
                this.total_laden_reserve_soc = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["ladenReserveSOC"];
              } else {
                this.total_laden_reserve_soc = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["emptyReserveSOC"] !=
                null
              ) {
                this.total_empty_reserve_soc = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["emptyReserveSOC"];
              } else {
                this.total_empty_reserve_soc = 0;
              }

              if (
                JSON.parse(arr[1].toString())["total"][0]["ladenReserveSLOT"] !=
                null
              ) {
                this.total_laden_reserve_slot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["ladenReserveSLOT"];
              } else {
                this.total_laden_reserve_slot = 0;
              }
              if (
                JSON.parse(arr[1].toString())["total"][0]["emptyReserveSLOT"] !=
                null
              ) {
                this.total_empty_reserve_slot = JSON.parse(arr[1].toString())[
                  "total"
                ][0]["emptyReserveSLOT"];
              } else {
                this.total_empty_reserve_slot = 0;
              }

              this.total_reserve =
                this.total_laden_reserve_coc +
                this.total_empty_reserve_coc +
                this.total_laden_reserve_soc +
                this.total_empty_reserve_soc +
                this.total_laden_reserve_slot +
                this.total_empty_reserve_slot;

              this.totalUC = JSON.parse(arr[1].toString())["total"][0][
                "totalUC"
              ];

              break;

            case "afterLoad":
              // console.log("size : " + this.grid.listStore.store.length);
              if (this.grid.listStore.store.length == 0) {
                if (this.isLoad) {
                  this.message(
                    "information",
                    "Information",
                    "No record(s) found.",
                    "okonly",
                    { ok: "this.loading=false;this.isLoad=false;" }
                  );
                }
              }
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  toolbarEvent(event: any) {
    // console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "new":
        //this.handleNewBooking();
        this.isError = false;
        this.param["lock7daysValidator"] = false;
        let currentDate = new Date();
        let etdDate = new Date();
        etdDate = new Date(this.param.ETD.toString());

        console.log("ETD awal = " + etdDate);
        console.log("ETD = " + this.addDays(etdDate, 9));
        console.log("NOW = " + currentDate);
        currentDate = new Date(this.genericUtil.getPSIDate());
        if (
          this.param.ETD != null &&
          this.param.ETD !== undefined &&
          this.param.ETD !== ""
        ) {
          //if(this.addDays(etdDate,7) > currentDate){
          this.handleNewBooking();
          /*
          }else{
            //this.dialogPlugin.show('information','Information', 'Unable to create booking because vessel ETD already exceeds 7 days from the current date.', 'okonly', { ok: 'this.loading=false;'});
            this.isError = true;
            this.param['error-lock7daysValidator'] = 'Unable to create booking because vessel ETD already exceeds 7 days from the current date.'
          }
          */
        } else {
          this.handleNewBooking();
        }

        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
      case "export":
        this.isError = this.onValidate(this.param);

        console.log("store");
        console.log(this.rowData);
        console.log(this.rowData2);
        if (this.rowData != 0) {
          if (!this.isError) {
            //   var splitUrl = this.configService.config.getPDFUrl.split(':');
            //   const urlHit = this.configService.config.getPDFUrl.replace(splitUrl[2], '14000')  + '/MasterBookings/bookingFlagStatusExport/' + this.cookieService.getDefaultLocationCode() + '/' + this.param.bOceanVesselId + '/' + this.param.bOceanVesselVoyage + '/' + this.param.bOceanVesselBound + '/' +
            //                   this.cookieService.getName() + '/' + ((this.param.bPodCode == null || this.param.bPodCode === '' ) ? '*' : this.param.bPodCode) +
            //                   '/' + ((this.param.bPot1Code == null || this.param.bPot1Code === '' ) ? '*' : this.param.bPot1Code) +
            //                   '/' + ((this.param.bPolCode == null || this.param.bPolCode === '' ) ? '*' : this.param.bPolCode) +
            //                   '/' + ((this.param.bFDestCode == null || this.param.bFDestCode === '' ) ? '*' : this.param.bFDestCode) +
            //                   '/' + ((this.param.bContainerOwnership == null || this.param.bContainerOwnership === '' ) ? '*' : this.param.bContainerOwnership) +
            //                   '/' + ((this.isNVOCC === true ) ? 'Y' : 'N') +
            //                   '/' + ((this.param.bContainerStatus == null || this.param.bContainerStatus === '' ) ? '*' : this.param.bContainerStatus);
            //   console.log(urlHit);
            //   window.open(urlHit);
            // }

            //without open window

            var objToday = new Date();
            var curHour =
              objToday.getHours() > 12
                ? objToday.getHours() - 12
                : objToday.getHours() < 10
                ? "0" + objToday.getHours()
                : objToday.getHours();
            var curMinute =
              objToday.getMinutes() < 10
                ? "0" + objToday.getMinutes()
                : objToday.getMinutes();

            var curDate =
              objToday.getDate() +
              (objToday.getMonth() + 1) +
              objToday.getFullYear();

            var formatName =
              "Booking_Flag_Status_" + curDate + curHour + curMinute + ".xlsx";
            var uri =
              this.configService.config.BASE_API.toString() +
              "?q=" +
              "/MasterBookings/bookingFlagStatusExport/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.param.bOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              this.param.bOceanVesselBound +
              "/" +
              this.cookieService.getName() +
              "/" +
              (this.param.bPodCode == null || this.param.bPodCode === ""
                ? "*"
                : this.param.bPodCode) +
              "/" +
              (this.param.bPot1Code == null || this.param.bPot1Code === ""
                ? "*"
                : this.param.bPot1Code) +
              "/" +
              (this.param.bPolCode == null || this.param.bPolCode === ""
                ? "*"
                : this.param.bPolCode) +
              "/" +
              (this.param.bFDestCode == null || this.param.bFDestCode === ""
                ? "*"
                : this.param.bFDestCode) +
              "/" +
              (this.param.bContainerOwnership == null ||
              this.param.bContainerOwnership === ""
                ? "*"
                : this.param.bContainerOwnership).replace(/\|/g, '%7C') +
              "/" +
              (this.isNVOCC === true ? "Y" : "N") +
              "/" +
              (this.param.bContainerStatus == null ||
              this.param.bContainerStatus === ""
                ? "*"
                : this.param.bContainerStatus);
            var self = this;
            this.loading = true;

            $.ajax({
              url: uri,
              method: "GET",
              beforeSend: function(request) {
                request.setRequestHeader("Token", localStorage.getItem("token"));
              },
              xhrFields: {
                responseType: "blob",
              },
              success: function (data) {
                console.log("#3");
                console.log(data);

                var a = document.createElement("a");
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = formatName;
                a.click();
                window.URL.revokeObjectURL(url);
                self.loading = false;
              },
            });
          }
        } else {
          this.message(
            "warning",
            "Information",
            "There is no Record.",
            "okonly",
            { ok: "" }
          );
        }
        break;
      case "cmd":
        this.isError = this.onValidate(this.param);
        if (this.rowData != 0) {
          if (!this.isError) {
            var objToday = new Date();
            var curHour =
              objToday.getHours() > 12
                ? objToday.getHours() - 12
                : objToday.getHours() < 10
                  ? "0" + objToday.getHours()
                  : objToday.getHours();
            var curMinute =
              objToday.getMinutes() < 10
                ? "0" + objToday.getMinutes()
                : objToday.getMinutes();

            var curDate =
              objToday.getDate() +
              (objToday.getMonth() + 1) +
              objToday.getFullYear();

            var formatName = "CMD_Report_" + curDate + curHour + curMinute + ".xlsx";

            var uri =
              this.configService.config.BASE_API.toString() +
              "?q=" +
              "/MasterBookings/generateCMDReport/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.param.bOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.param.bOceanVesselVoyage).trim()
              ) +
              "/" +
              this.param.bOceanVesselBound +
              "/" +
              this.cookieService.getName() +
              "/" +
              (this.param.bPodCode == null || this.param.bPodCode === ""
                ? "*"
                : this.param.bPodCode) +
              "/" +
              (this.param.bPot1Code == null || this.param.bPot1Code === ""
                ? "*"
                : this.param.bPot1Code) +
              "/" +
              (this.param.bPolCode == null || this.param.bPolCode === ""
                ? "*"
                : this.param.bPolCode) +
              "/" +
              (this.param.bFDestCode == null || this.param.bFDestCode === ""
                ? "*"
                : this.param.bFDestCode) +
              "/" +
              (this.param.bContainerOwnership == null ||
                this.param.bContainerOwnership === ""
                ? "*"
                : this.param.bContainerOwnership).replace(/\|/g, '%7C') +
              "/" +
              (this.isNVOCC === true ? "Y" : "N") +
              "/" +
              (this.param.bContainerStatus == null ||
                this.param.bContainerStatus === ""
                ? "*"
                : this.param.bContainerStatus);
            var self = this;
            this.loading = true;

            $.ajax({
              url: uri,
              method: "GET",
              beforeSend: function(request) {
                request.setRequestHeader("Token", localStorage.getItem("token"));
              },
              xhrFields: {
                responseType: "blob",
              },
              success: function (data) {
                var a = document.createElement("a");
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = formatName;
                a.click();
                window.URL.revokeObjectURL(url);
                self.loading = false;
              },
            });
          }
        } else {
          this.message(
            "warning",
            "Information",
            "There is no Record.",
            "okonly",
            { ok: "" }
          );
        }
        break;
      default:
        let str: string = event;
        // console.log('str = ' + str);
        break;
    }
  }

  handleRetrieve() {
    // console.log('retrieve event');
    this.saveFilterModel();

    var goErr = 0;
    if(this.isCOC ==  false && this.isSOC == false && this.isSLOT == false && this.isNVOCC == false){

      goErr = 1;

    }else{
      goErr = 0;
    }


    if(goErr == 0){
      this.param["error-bOceanVesselVoyage"] = "";
      this.param["error-bOceanVesselBound"] = "";
      this.param["error-bOceanVesselCode"] = "";

      this.isError = false;
      this.param["allocationValidator"] = false;

      this.grid.curPage = 1;
      this.grid.currentPage = 1;

      this.param.selected = "";
      this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
      this.param.bContainerOwnership = "";

      if (this.tsBooking === "All" || this.tsBooking === "") {
        if (this.isSOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SOC|SOCTS|";
        }
        if (this.isCOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "COC|COCTS|";
        }
        if (this.isSLOT) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SLOT|";
        }
        if (this.isNVOCC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "NVOCC|";
        }
      } else if (this.tsBooking === "Local") {
        if (this.isSOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SOC|";
        }
        if (this.isCOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "COC|";
        }
        if (this.isSLOT) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SLOT|";
        }
        if (this.isNVOCC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "NVOCC|";
        }
      } else if (this.tsBooking === "Transhipment") {
        if (this.isSOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SOCTS|";
        }
        if (this.isCOC) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "COCTS|";
        }
        // GSO-1065 Segregate SLOT Count Based On SLOT LC & T/S
        if (this.isSLOT) {
          this.param.bContainerOwnership =
            this.param.bContainerOwnership + "SLOT|";
        }
      }

      this.param.bTsbooking = this.tsBooking;

      this.param.bContainerOwnership = this.param.bContainerOwnership.substring(
        0,
        this.param.bContainerOwnership.length - 1
      );
      // console.log(this.param.bContainerOwnership);

      this.isError = this.onValidate(this.param);
      if (!this.isError) {
        // console.log(this.param);
        this.isLoad = true;
        this.grid.setBody(this.param);
        // this.grid.loadData();
      }

      if(this.param['error-bOceanVesselCode'] || this.param['error-bOceanVesselVoyage']){
        this.loading = false;
        return;
      }

      // ag-grid load
      this.gridApi.showLoadingOverlay();

      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/searchFlagStatus/1/1000/DESC/dateModified",
          this.param
        )
        .subscribe((resp) => {
          this.dataGrid = resp.json();

          // todo
          // if content -> bOrphanFlag = 'Y' then popup

          const response = resp.json();
          let orphanFlag = "N";
          if(response && response.content && response.content.length > 0) {
            orphanFlag = response.content[0].bOrphanFlag;
          }

          if(orphanFlag === "Y") {
          console.log("🚀 ~ .subscribe ~ orphanFlag:", orphanFlag)
          this.message(
            "warning",
            "Warning",
            "This vsl/voy has cargoes that need to be transferred out due to a change in vsl rotation.",
            "okonly",
            { ok: "" }
          );
          }
          
          
          this.rowData =resp.json()["content"]; //GOS-841 removing the invocation of transformResult
          this.calculateFooter(resp.json());
          setTimeout(() => {
            this.restoreFilterModel();
            this.gridApi.hideOverlay();
          }, 50);
        });
    }else{

      this.message(
        "information",
        "Information",
        "Please select at least one [COC/SOC/SLOT/NVOCC].",
        "okonly",
        { ok: "this.loading=false;this.isLoad=false;" }
      )

    }
  }

  private transformResult(lst: any[]) {
    return lst.map(item => {
      // BKG confirmation status:
      item.vesselVoyageConfirmStatus = (item.bPendingRequest == 'N' && item.bPot1Code == 'SGSIN' && !!item.nextMotherVessel && !!item.nextMotherVoyage) ? 'Y' : 'N';
      return item;
    });
  }

  continueToMaintenance(bno: string) {
    this.genericUtil.showLoaderPage();
    var self = this;
    // console.log("Above set time out ");
    setTimeout(function () {
      self.param.selected = bno;

      //localStorage.setItem("retrieve-booking-from-flag",JSON.stringify(self.param));
      //localStorage.setItem("booking-flag-status-current-page",self.grid.getCurrentPage().toString());

      // trial
      console.log("row data");
      console.log(self.rowData);
      console.log(self.param);
      console.log(self.rowData2);
      console.log(self.dataGrid);
      localStorage.setItem(
        "booking-flag-status-row-data",
        JSON.stringify(self.rowData)
      );
      localStorage.setItem(
        "booking-flag-status-row-data2",
        JSON.stringify(self.rowData2)
      );
      localStorage.setItem(
        "booking-flag-status-data-grid",
        JSON.stringify(self.dataGrid)
      );

      localStorage.setItem(
        "retrieve-booking-from-flag",
        JSON.stringify(self.param)
      );
      if (this.viewOnlyStatus) {
        localStorage.setItem("view-only-mode", "Y");
      }
      localStorage.setItem(
        "booking-flag-status-current-page",
        self.grid.getCurrentPage().toString()
      );

      if (self.sortedColumns !== undefined) {
        const colIndex = self.pinnedColumns.findIndex(
          (item) => item.colId === self.sortedColumns.colId
        );

        if (colIndex != -1) {
          self.pinnedColumns[colIndex].sort = self.sortedColumns.sort;
        } else {
          self.pinnedColumns = [
            ...self.pinnedColumns,
            {
              colId: self.sortedColumns.colId,
              sort: self.sortedColumns.sort,
            },
          ];
        }
      } else {
        self.pinnedColumns.map((it) => {
          const colIndex = self.pinnedColumns.findIndex(
            (item) => item.colId === it.colId
          );
          self.pinnedColumns[colIndex].sort = "";
        });
      }

      if (self.filterColumns !== undefined) {
        self.pinnedColumns = [
          ...self.pinnedColumns,
          {
            colId: "",
            sort: "",
            pinned: "",
            filter: self.filterColumns,
          },
        ];
      }

      if (self.resizedColumns !== undefined) {
        let dumpArr = [];

        for (const key in self.resizedColumns) {
          dumpArr.push(self.resizedColumns[key]);
        }

        self.pinnedColumns = [
          ...self.pinnedColumns,
          {
            colId: "",
            sort: null,
            pinned: "",
            filter: null,
            resized: {
              columnWidths: dumpArr,
              finished: true,
              source: "uiColumnDragged",
            },
          },
        ];
      }

      localStorage.setItem(
        "pinned-columns",
        JSON.stringify(self.pinnedColumns)
      );

      self.transactionBookingService.temporarySaveContainer({
        isCOC: self.isCOC,
        isSOC: self.isSOC,
        isSLOT: self.isSLOT
      });
      self.router.navigate(["/main/transaction-booking-maintenance"]);
    }, 100);
  }

  handleCancel() {
    //to reset the isError (dismiss error message section)
    this.isError = false;

    this.param = new Param();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbVoyage.setUrl("");
    this.cbPOD.setValue("");
    this.cbPOR.setValue("");
    this.cbFDest.setValue("");
    this.cbPOT1.setValue("");
    this.cbPOL.setValue("");

    var self = this;
    this.cbLocalTranshipment.clearSelect();
    this.cbFullEmpty.clearSelect();
    setTimeout(() => {
      self.cbFullEmpty.setValue("All/Full/Empty");
      self.param.bContainerStatus = "";
      self.cbLocalTranshipment.setValue("All/Local/Transshipment");
      self.param.bTsbooking = "All";
    }, 10);

    this.tsBooking = "";

    this.isCOC = true;
    this.isSOC = true;
    this.isSLOT = true;
    this.isNVOCC = false;

    if (this.currentMenu.includes("(SOC)")) {
      this.viewSOCStatus = true;
      /*this.disableCOC = true;
      this.isCOC = false;
      this.disableSlot = true;
      this.isSLOT = false;*/
    }

    if (this.currentMenu.includes("(COC)")) {
      this.viewCOCStatus = true;
      /*this.disableSOC = true;
      this.isSOC = false;
      this.disableSlot = true;
      this.isSLOT = false;*/
    }

    //this.tsBooking = '';
    this.isLoad = false;
    this.param.bOceanVesselBound = "O";
    this.grid.setBody(this.param);
    this.grid.loadData();

    this.cbPOD.setUrl("");
    this.cbPOR.setUrl("");
    this.cbPOT1.setUrl("");
    this.cbPOL.setUrl("");
    this.cbFDest.setUrl("");

    //ag-grid reset
    this.rowData = [];

    //reset window scroll to 0.0
    window.scrollTo(0, 0);
  }

  getAllocation(callback?: (data) => void) {
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    if (
      this.param.bOceanVesselId != "" &&
      this.param.bOceanVesselVoyage != "" &&
      this.param.bOceanVesselBound != ""
    ) {
      //this.genericService.GET(this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail/' + this.param.bOceanVesselId + '/' + this.param.bOceanVesselVoyage + '/' + this.param.bOceanVesselBound + '/' + this.param.bOfficeCode).subscribe((resp) =>{
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/VesselAllocation/getAllotmentDetail/" +
            this.param.bOceanVesselId +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.param.bOceanVesselVoyage).trim()
            ) +
            "/" +
            this.param.bOceanVesselBound
        )
        .subscribe((resp) => {
          let data = resp.json();
          // console.log(data.size);

          callback(data.size);
        });
    }
  }

  handleNewBooking() {
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.param.bContainerOwnership = "";

    this.param["allocationValidator"] = false;

    if (this.tsBooking === "All" || this.tsBooking === "") {
      if (this.isSOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "SOC|SOCTS|";
      }
      if (this.isCOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "COC|COCTS|";
      }
      if (this.isSLOT) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "SLOT|";
      }
      if (this.isNVOCC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "NVOCC|";
      }
    } else if (this.tsBooking === "Local") {
      if (this.isSOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "SOC|";
      }
      if (this.isCOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "COC|";
      }
      if (this.isSLOT) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "SLOT|";
      }
      if (this.isNVOCC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "NVOCC|";
      }
    } else if (this.tsBooking === "Transhipment") {
      if (this.isSOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "SOCTS|";
      }
      if (this.isCOC) {
        this.param.bContainerOwnership =
          this.param.bContainerOwnership + "COCTS|";
      }
    }

    this.param.bContainerOwnership = this.param.bContainerOwnership.substring(
      0,
      this.param.bContainerOwnership.length - 1
    );
    // console.log(this.param.bContainerOwnership);

    var self = this;
    self.isError = false;
    // console.log(self.isError);
    this.param["error-allocationValidator"] = "";
    this.getAllocation(function (callback) {
      console.log("callback : " + callback, typeof callback);
      //if (callback === 0 || callback === "0") {
      if (callback == 0) {
        console.log("masuk ga ada allocation");
        self.param["allocationValidator"] = true;
      }
    });
    console.log("nih param alloc validationr ");
    console.log(self.param["allocationValidator"]);

    setTimeout(function () {
      // console.log('param --> ');
      // console.log(self.param);
      self.isError = self.onValidate(self.param);
      if (!self.isError) {
        self.genericUtil.showLoaderPage();

        setTimeout(function () {
          self.param.selected = "";
          localStorage.setItem(
            "new-booking-from-flag",
            JSON.stringify(self.param)
          );
          localStorage.setItem(
            "booking-flag-status-current-page",
            self.grid.getCurrentPage().toString()
          );

          localStorage.setItem(
            "booking-flag-status-row-data",
            JSON.stringify(self.rowData)
          );
          localStorage.setItem(
            "booking-flag-status-row-data2",
            JSON.stringify(self.rowData2)
          );
          localStorage.setItem(
            "booking-flag-status-data-grid",
            JSON.stringify(self.dataGrid)
          );
          self.saveState();
          self.saveFilterModel();

          self.transactionBookingService.temporarySaveContainer({
            isCOC: self.isCOC,
            isSOC: self.isSOC,
            isSLOT: self.isSLOT
          });

          self.router.navigate(["/main/transaction-booking-maintenance"]);
        }, 150);
      }
    }, 150);
  }

  rowDoubleClick(params) {
    this.fullBookingNo = "";
    let dt = params.data;
    if (dt.bStatus == "D") {
      // this.message(
      //   "warning",
      //   "Information",
      //   "Booking already deleted.",
      //   "okonly",
      //   { ok: "" }
      // );
      // GSO-1048 Regardless keystrokes, system must allow Deleted entry to be transmitted over.
      this.fullBookingNo = dt.bPrefix + "-" + dt.bNo;
      this.saveState();
      this.saveFilterModel();
      this.continueToMaintenance(this.fullBookingNo);
    } else {
      if (dt.bIsUnlocked === "N") {
        this.fullBookingNo = dt.bPrefix + "-" + dt.bNo;
        this.message(
          "information",
          "Confirmation",
          "Current booking is locked! View only?",
          "yesno",
          { yes: "this.continueToMaintenance(this.fullBookingNo);", no: "" }
        );
      } else {
        this.fullBookingNo = dt.bPrefix + "-" + dt.bNo;
        this.saveState();
        this.saveFilterModel();
        this.continueToMaintenance(this.fullBookingNo);
      }
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();

    // *****Checking If There's any Pinned Columns***** //
    if (localStorage.getItem("pinned-columns") != null) {
      this.pinnedColumns = JSON.parse(localStorage.getItem("pinned-columns"));
    }
  }

  onFirstDataRendered(params) {
    console.log("** onFirstDataRendered **");
    this.pinnedColumns.map((item) => {
      this.columnApi.setColumnPinned(item.colId, item.pinned);

      console.log("item.sort = ");
      console.log(item.sort);

      if (item.sort) {
        this.gridApi.setSortModel([item]);
      }

      if (item.filter) {
        this.gridApi.setFilterModel(item.filter);
      }

      if (item.resized) {
        const { columnWidths, finished, source } = item.resized;

        columnWidths.map((item) => {
          this.columnApi.setColumnWidth(
            item.key,
            item.newWidth,
            finished,
            source
          );
        });
      }
    });
  }

  recalculatedFooter() {
    const gridTotal = new GridTotal();

    this.gridApi.forEachNodeAfterFilter(node => {
      const nodeData = node.data;
      console.log('nodeData', nodeData);
      if (nodeData.bStatus !== 'D') {
        gridTotal.d20 += nodeData.d20;
        gridTotal.h20 += nodeData.h20;
        gridTotal.d40 += nodeData.d40;
        gridTotal.h40 += nodeData.h40;
        gridTotal.d45 += nodeData.d45;
        gridTotal.h45 += nodeData.h45;
        gridTotal.r20 += nodeData.r20;
        gridTotal.rh20 += nodeData.rh20;
        gridTotal.r40 += nodeData.r40;
        gridTotal.rh40 += nodeData.rh40;
        gridTotal.r45 += nodeData.r45;
        gridTotal.rh45 += nodeData.rh45;
        gridTotal.t20 += nodeData.t20;
        gridTotal.t40 += nodeData.t40;
        gridTotal.t45 += nodeData.t45;

        gridTotal.mt20 += nodeData.mt20;
        gridTotal.mth20 += nodeData.mth20;
        gridTotal.mt40 += nodeData.mt40;
        gridTotal.mth40 += nodeData.mth40;
        gridTotal.mt45 += nodeData.mt45;
        gridTotal.mth45 += nodeData.mth45;
        gridTotal.mtr20 += nodeData.mtr20;
        gridTotal.mtrh20 += nodeData.mtrh20;
        gridTotal.mtr40 += nodeData.mtr40;
        gridTotal.mtrh40 += nodeData.mtrh40;
        gridTotal.mtr45 += nodeData.mtr45;
        gridTotal.mtrh45 += nodeData.mtrh45;
        gridTotal.mtt20 += nodeData.mtt20;
        gridTotal.mtt40 += nodeData.mtt40;
        gridTotal.mtt45 += nodeData.mtt45;

        gridTotal.killslot += nodeData.killslot;
      }
    });

    let x = [];

    x.push(gridTotal);
    this.rowData2 = x;
    this.pinnedBottomRowData = this.rowData2;
  }

  calculateFooter(data: any) {
    const footerData = data["footer"]["total"][0];
    const footerSailSchedule = data["footer"]["sailSchedule"];
    const footerDataAllotment = data["footer"]["allotment"];
    console.log("debug footer data");
    console.log(footerData);
    const gridTotal = new GridTotal();

    gridTotal.d20 = footerData.D20;
    gridTotal.h20 = footerData.H20;
    gridTotal.d40 = footerData.D40;
    gridTotal.h40 = footerData.H40;
    gridTotal.d45 = footerData.D45;
    gridTotal.h45 = footerData.H45;
    gridTotal.r20 = footerData.R20;
    gridTotal.rh20 = footerData.RH20;
    gridTotal.r40 = footerData.R40;
    gridTotal.rh40 = footerData.RH40;
    gridTotal.r45 = footerData.R45;
    gridTotal.rh45 = footerData.RH45;
    gridTotal.t20 = footerData.T20;
    gridTotal.t40 = footerData.T40;
    gridTotal.t45 = footerData.T45;

    gridTotal.mt20 = footerData.MT20;
    gridTotal.mth20 = footerData.MTH20;
    gridTotal.mt40 = footerData.MT40;
    gridTotal.mth40 = footerData.MTH40;
    gridTotal.mt45 = footerData.MT45;
    gridTotal.mth45 = footerData.MTH45;
    gridTotal.mtr20 = footerData.MTR20;
    gridTotal.mtrh20 = footerData.MTRH20;
    gridTotal.mtr40 = footerData.MTR40;
    gridTotal.mtrh40 = footerData.MTRH40;
    gridTotal.mtr45 = footerData.MTR45;
    gridTotal.mtrh45 = footerData.MTRH45;
    gridTotal.mtt20 = footerData.MTT20;
    gridTotal.mtt40 = footerData.MTT40;
    gridTotal.mtt45 = footerData.MTT45;

    gridTotal.killslot = footerData.KILLSLOT;
    let x = [];

    x.push(gridTotal);
    this.rowData2 = x;
    this.pinnedBottomRowData = this.rowData2;

    // footer processor

    if (footerData["SLOTTS"] != null) {
      this.total_slot_ts = footerData["SLOTTS"];
    } else {
      this.total_slot_ts = 0;
    }
    if (footerData["SLOTTSFull"] != null) {
      this.total_slot_ts_full = footerData["SLOTTSFull"];
    } else {
      this.total_slot_ts_full = 0;
    }
    if (footerData["SLOTTSEmpty"] != null) {
      this.total_slot_ts_empty = footerData["SLOTTSEmpty"];
    } else {
      this.total_slot_ts_empty = 0;
    }
    if (footerData["SLOTLOCAL"] != null) {
      this.total_slot_local = footerData["SLOTLOCAL"];
    } else {
      this.total_slot_local = 0;
    }
    if (footerData["SLOTLOCALFull"] != null) {
      this.total_slot_local_full = footerData["SLOTLOCALFull"];
    } else {
      this.total_slot_local_full = 0;
    }
    if (footerData["SLOTLOCALEmpty"] != null) {
      this.total_slot_local_empty = footerData["SLOTLOCALEmpty"];
    } else {
      this.total_slot_local_empty = 0;
    }

    if (footerData["killSlotCOC"] != null) {
      this.total_coc_killslot = footerData["killSlotCOC"];
    } else {
      this.total_coc_killslot = 0;
    }

    if (footerData["killSlotSOC"] != null) {
      this.total_soc_killslot = footerData["killSlotSOC"];
    } else {
      this.total_soc_killslot = 0;
    }

    if (footerData["killSlotSLOT"] != null) {
      this.total_slot_killslot = footerData["killSlotSLOT"];
    } else {
      this.total_slot_killslot = 0;
    }

    if (footerDataAllotment["SOC"] != null) {
      this.total_soc_allocated = footerDataAllotment["SOC"];
    } else {
      this.total_soc_allocated = 0;
    }

    if (footerData["SOCTS"] != null) {
      this.total_soc_ts = footerData["SOCTS"];
    } else {
      this.total_soc_ts = 0;
    }

    if (footerData["SOCTSDeleted"] != null) {
      this.total_soc_ts_deleted = footerData["SOCTSDeleted"];
    } else {
      this.total_soc_ts_deleted = 0;
    }

    if (footerData["SOC"] != null) {
      this.total_soc_local = footerData["SOC"];
    } else {
      this.total_soc_local = 0;
    }

    if (footerData["SOCDeleted"] != null) {
      this.total_soc_local_deleted = footerData["SOCDeleted"];
    } else {
      this.total_soc_local_deleted = 0;
    }

    this.total_soc_deleted =
      this.total_soc_local_deleted + this.total_soc_ts_deleted;

    if (footerData["LadenSOC"] != null) {
      this.total_soc_laden_booked = footerData["LadenSOC"];
    } else {
      this.total_soc_laden_booked = 0;
    }

    if (footerData["EmptySOC"] != null) {
      this.total_soc_empty_booked = footerData["EmptySOC"];
    } else {
      this.total_soc_empty_booked = 0;
    }

    this.total_soc_available =
      this.total_soc_allocated -
      (this.total_soc_local + this.total_soc_ts) -
      this.total_soc_killslot;

    if (footerDataAllotment["COC"] != null) {
      this.total_coc_allocated = JSON.parse(footerDataAllotment["COC"]);
    } else {
      this.total_coc_allocated = 0;
    }

    if (footerData["COCTS"] != null) {
      this.total_coc_ts = footerData["COCTS"];
    } else {
      this.total_coc_ts = 0;
    }

    if (footerData["COCTSDeleted"] != null) {
      this.total_coc_ts_deleted = footerData["COCTSDeleted"];
    } else {
      this.total_coc_ts_deleted = 0;
    }

    if (footerData["COC"] != null) {
      this.total_coc_local = footerData["COC"];
    } else {
      this.total_coc_local = 0;
    }

    if (footerData["COCDeleted"] != null) {
      this.total_coc_local_deleted = footerData["COCDeleted"];
    } else {
      this.total_coc_local_deleted = 0;
    }

    this.total_coc_deleted =
      this.total_coc_local_deleted + this.total_coc_ts_deleted;

    if (footerData["LadenCOC"] != null) {
      this.total_coc_laden_booked = footerData["LadenCOC"];
    } else {
      this.total_coc_laden_booked = 0;
    }

    if (footerData["EmptyCOC"] != null) {
      this.total_coc_empty_booked = footerData["EmptyCOC"];
    } else {
      this.total_coc_empty_booked = 0;
    }

    this.total_coc_available =
      this.total_coc_allocated -
      (this.total_coc_local + this.total_coc_ts) -
      this.total_coc_killslot;

    if (footerDataAllotment["SLOT"] != null) {
      this.total_slot_allocated = footerDataAllotment["SLOT"];
    } else {
      this.total_slot_allocated = 0;
    }

    if (footerData["SLOT"] != null) {
      this.total_slot_booked = footerData["SLOT"];
    } else {
      this.total_slot_booked = 0;
    }

    if (footerData["SLOTDeleted"] != null) {
      this.total_slot_booked_deleted = footerData["SLOTDeleted"];
    } else {
      this.total_slot_booked_deleted = 0;
    }

    this.total_slot_available =
      this.total_slot_allocated -
      this.total_slot_booked -
      this.total_slot_killslot;

    if (footerDataAllotment["VS"] != null) {
      this.total_vsl_teus = footerDataAllotment["VS"];
    } else {
      this.total_vsl_teus = 0;
    }

    if (footerData["ladenSLOT"] != null) {
      this.total_slot_laden_booked = footerData["ladenSLOT"];
    } else {
      this.total_slot_laden_booked = 0;
    }

    if (footerData["emptySLOT"] != null) {
      this.total_slot_empty_booked = footerData["emptySLOT"];
    } else {
      this.total_slot_empty_booked = 0;
    }

    this.total_laden =
      this.total_coc_laden_booked +
      this.total_soc_laden_booked +
      this.total_slot_laden_booked;

    this.total_empty =
      this.total_coc_empty_booked +
      this.total_soc_empty_booked +
      this.total_slot_empty_booked;

    if (footerSailSchedule["ETA"] != null) {
      this.eta = String(footerSailSchedule["ETA"]).replace(/\//g, "-");
    } else {
      this.eta = "";
    }

    if (footerSailSchedule["ETD"] != null) {
      this.etd = String(footerSailSchedule["ETD"]).replace(/\//g, "-");
    } else {
      this.etd = "";
    }

    if (footerSailSchedule["ATA"] != null) {
      this.ata = String(footerSailSchedule["ATA"]).replace(/\//g, "-");
    } else {
      this.ata = "";
    }

    if (footerSailSchedule["ATD"] != null) {
      this.atd = String(footerSailSchedule["ATD"]).replace(/\//g, "-");
    } else {
      this.atd = "";
    }

    if (footerData["ladenReserve"] != null) {
      this.total_resv_laden = footerData["ladenReserve"];
    } else {
      this.total_resv_laden = 0;
    }

    if (footerData["emptyReserve"] != null) {
      this.total_resv_empty = footerData["emptyReserve"];
    } else {
      this.total_resv_empty = 0;
    }

    if (footerData["totalKillslot"] != null) {
      this.total_resv_killslot = footerData["totalKillslot"];
    } else {
      this.total_resv_killslot = 0;
    }

    this.total_resv = this.total_resv_laden + this.total_resv_empty;

    if (footerData["ladenReserveCOC"] != null) {
      this.total_laden_reserve_coc = footerData["ladenReserveCOC"];
    } else {
      this.total_laden_reserve_coc = 0;
    }
    if (footerData["emptyReserveCOC"] != null) {
      this.total_empty_reserve_coc = footerData["emptyReserveCOC"];
    } else {
      this.total_empty_reserve_coc = 0;
    }

    if (footerData["ladenReserveSOC"] != null) {
      this.total_laden_reserve_soc = footerData["ladenReserveSOC"];
    } else {
      this.total_laden_reserve_soc = 0;
    }
    if (footerData["emptyReserveSOC"] != null) {
      this.total_empty_reserve_soc = footerData["emptyReserveSOC"];
    } else {
      this.total_empty_reserve_soc = 0;
    }

    if (footerData["ladenReserveSLOT"] != null) {
      this.total_laden_reserve_slot = footerData["ladenReserveSLOT"];
    } else {
      this.total_laden_reserve_slot = 0;
    }
    if (footerData["emptyReserveSLOT"] != null) {
      this.total_empty_reserve_slot = footerData["emptyReserveSLOT"];
    } else {
      this.total_empty_reserve_slot = 0;
    }

    this.total_reserve =
      this.total_laden_reserve_coc +
      this.total_empty_reserve_coc +
      this.total_laden_reserve_soc +
      this.total_empty_reserve_soc +
      this.total_laden_reserve_slot +
      this.total_empty_reserve_slot;

    this.totalUC = footerData["totalUC"];
  }

  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  onColumnPinned(params: ColumnPinnedEvent) {
    if (this.pinnedColumns.length > 0) {
      const colIndex = this.pinnedColumns.findIndex(
        (item) => item.colId === params.column.getColId()
      );

      if (colIndex != -1) {
        return (this.pinnedColumns[colIndex] = {
          ...this.pinnedColumns[colIndex],
          pinned: params.pinned,
        });
      }
    }

    return this.pinnedColumns.push({
      colId: params.column.getColId(),
      pinned: params.pinned,
    });
  }

  sortChanged(params: any) {
    console.log("** sortChanged **");
    console.log(params.api.getSortModel());
    const selectedColumns = params.api.getSortModel()[0];
    console.log(selectedColumns);

    this.sortedColumns = selectedColumns;
  }

  filterChanged(params: any) {
    this.filterColumns = params.api.getFilterModel();
    if (this.isSingaporeSite) {
      this.recalculatedFooter();
    }
  }

  columnResized(params: ColumnResizedEvent) {
    const columnWidths = {
      key: params.column.getColId(),
      newWidth: params.column.getActualWidth(),
    };

    this.resizedColumns = {
      ...this.resizedColumns,
      [params.column.getColId()]: columnWidths,
    };
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field);
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.param[field]) == true) {
      return (this.param[`error-${field}`] = "");
    }

    return false;
  }

  checkErrorblVesselIdblVoyage() {
    if (!this.param[`error-bOceanVesselCode`] && !this.param[`error-bOceanVesselVoyage`]) {
      this.isError = false;
    } else {
      this.isError = true;
    }
  }

  backspaceValue(event: any, regexName: RegExp, field: string) {
    if(field === 'bOceanVesselCode' && !event.target.value){
      this.cbVoyage.setValue("");
      this.param[`error-bOceanVesselVoyage`] = "";
      this.param.bOceanVesselVoyage = "";
    }
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field){
    const fileName = field === 'bOceanVesselCode' ? 'Vessel' : 'Voyage';
    if (pastedText && regexName.test(pastedText) == false) {
      this.isError = true;
      this.param[`error-${field}`] = `${fileName} 'Only accept alphanumeric and -./'`;
    } else {
      this.param[`error-${field}`] = "";
    }
    this.checkErrorblVesselIdblVoyage();
  }

  saveState() {
    localStorage.setItem(
      "booking-flag-status-data-column-state",
      JSON.stringify(this.columnApi.getColumnState())
    );
  }

  restoreState() {
    const state = JSON.parse(localStorage.getItem("booking-flag-status-data-column-state"));
    if (!state) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    this.columnApi.setColumnState(state);
    setTimeout(() => {
      this.restoreFilterModel();
    }, 100);
  }

  saveFilterModel() {
    this.filterModel = this.gridApi.getFilterModel();
    this.transactionBookingService.saveFilterModel(this.filterModel);
  }

  restoreFilterModel() {
    const model = this.transactionBookingService.getFilterModel();
    this.gridApi.setFilterModel(model);
  }

  onSelectionChanged(params) {
    this.disableToolbarButtons = "delete";
    if(this.gridApi.getSelectedRows().length > 0){
      this.disableToolbarButtons = "";
      //if (this.rowData.find(x => x.tslIsDeleted != 'Y')) {
      //}
    }
  }

  setValidatorInit() {
    this.validatorRules = {
      bOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      bOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and .-/",
          },
        ],
      },
      bOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and .-/",
          },
        ],
      },
      bContainerOwnership: {
        rules: [
          {
            type: "empty",
            prompt: "Please select min 1 container ownership (SOC/COC/SLOT)",
          },
          {
            type: "modifiedPattern",
            prompt: "",
          },
        ],
      },
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create booking.",
          },
        ],
      },
      lock7daysValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Unable to create booking because vessel ETD already exceeds 9 days from the current date.",
          },
        ],
      },
    };
  }

  setValidatorReasonBeforeDelete() {
    this.validatorRules = {
      bReason: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Reason.",
          },
        ],
      },
    };
  }


  handleDelete() {
    if(this.gridApi.getSelectedRows().length > 0){
      let mp = new Map<String, any>();
      this.gridApi.getSelectedRows().forEach((element) => {
        console.log(element);
        if(element.blIndicator === 'D' || element.blIndicator === ''){

        }else{
          if(mp.get("SSL"+element.bNo) === undefined){
            mp.set("SSL"+element.bNo, element);
          }
        }
      });

      console.log(mp.keys());
      if(mp.size > 0 ){
        let strKeys = "";
        mp.forEach((value: any, key: string) => {
          strKeys +=  "BL No. " + key + "<br>";
        });
        this.message(
          "information",
          "Information",
          "Unable to delete Booking because the BL is still active. <br> Active BL(s):<br> " + strKeys,
          "okonly",
          { ok: "" }
        );
      }else{
        this.txtReason.setValue("");
        this.genericUtil.showDialog(
          "bookingDeleteDialogYesNo",
          "Do you want to delete the record?",
          425,
          425
        );
      }
    }
  }

  onDialogBookingYesDelete() {
    this.model.bReason = this.txtReason.getValue();
    this.setValidatorReasonBeforeDelete();
    let isPassedDel: boolean = this.onValidate(this.model);
    if (!isPassedDel) {
      this.isErrorDel = true;
      console.log("** delete process **");
      this.genericUtil.closeDialog("bookingDeleteDialogYesNo");
      let data = [];
      this.gridApi.getSelectedRows().forEach((element) => {
        data.push({"bOfficeCode": element.bOfficeCode, "bPrefix": element.bPrefix, "bNo": element.bNo, "bReason": this.model.bReason, "bModifiedUserid": this.cookieService.getName(), "bModifiedDate": this.genericUtil.getPSIDate()});
      });

      console.log(data);
      this.gridApi.showLoadingOverlay();
      this.genericService.POST(
              this.configService.config.BASE_API.toString() +
                "/MasterBookings/deleteBatch",
              data
            )
            .subscribe(
              (resp) => {
                this.handleRetrieve();
              }
            );
          

    }
  }

  onDialogBookingNoDelete() {
    this.txtReason.setValue("");
    this.model = new BookingDelete();
    this.genericUtil.closeDialog("bookingDeleteDialogYesNo");
    this.setValidatorInit();
  }
}
