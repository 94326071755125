import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { specialCharUtil } from "../shared";
import get = Reflect.get;
import { finalize } from "rxjs/operators";

declare var $: any;

export class Param {
  blOfficeCode: string = "";
  blOceanVesselId: string = "";
  blOceanVesselCode: string = "";
  blOceanVesselVoyage: string = "";
  blOceanVesselBound: string = "";
  blLoadPortCode: string = "";
  blPot1Code: string = "";
  blPodCode: string = "";
  blFinalDestCode: string = "";
}

@Component({
  selector: "app-transaction-bl-uncombine-latest-page",
  templateUrl: "./transaction-bl-uncombine-latest-page.component.html",
  styleUrls: ["./transaction-bl-uncombine-latest-page.component.css"],
})
export class TransactionBLUncombineLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbFinalDest") cbFinalDest: ComboPluginComponent;

  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridDetails") gridDetails: GridPluginComponent;

  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  settingPOT1;
  settingDischargePort;
  settingGrid;
  settingFinalDest;

  model = new Param();
  sCharUtil = new specialCharUtil();

  isError: Boolean = false;

  validatorRules = {};

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Uncombine", event: "uncombine", icon: "checkmark" },
        { name: "Uncombine2", event: "uncombine2", icon: "checkmark" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Uncombine",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchLoadPort/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchTranshipmentPort/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchDischargePort/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinalDest = {
      id: "cbFinalDest",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/BL/searchFinalDestination/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url:
        this.configService.config.BASE_API.toString() + "/BLUncombine/searchBL",
      page: 10,
      columns: [
        { header: "BL Reference", field: "blNo", width: 200 },
        {
          header: "Freight Party",
          field: "blBookingFreightPartyName",
          width: 200,
        },
        { header: "Shipper", field: "blShipperName", width: 200 },
        { header: "Consignee", field: "blConsigneeName", width: 200 },
        { header: "Notify Party", field: "blNotifyPartyName", width: 200 },
        { header: "RT", field: "blReceiptTypeCode", width: 200 },
        { header: "DT", field: "blDeliveryTypeCode", width: 200 },
        { header: "DET", field: "blBookingDetentionName", width: 200 },
        { header: "REQ", field: "blBookingDetentionRequest", width: 200 },
        { header: "DEM", field: "blBookingDemurrageName", width: 200 },
        { header: "REQ", field: "blBookingDemurrageRequest", width: 200 },
        {
          header: "Port Of Discharge",
          field: "blDischargePortName",
          width: 200,
        },
        {
          header: "Place Of Delivery",
          field: "blPlaceOfDeliveryName",
          width: 200,
        },
        {
          header: "Final Destination",
          field: "blFinalDestinationName",
          width: 200,
        },
        { header: "P/C", field: "blBookingFreightType", width: 100 },
        { header: "Ocean Vsl", field: "oceanVessel", width: 200 },
        { header: "Next Vsl", field: "nextVessel", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
    };
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.model.blOceanVesselBound = "O";
    this.disableToolbarButtons = "uncombine2"
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;
      case "uncombine":
        this.handleUncombine();
        break;
      case "uncombine2":
        this.handleUncombine2();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case "cancel":
        this.handleCancel();
        break;
    }
  }

  clearValidatorError() {
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blLoadPortCode"] = "";
  }

  setValidator() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input load port.",
          },
        ],
      },
    };
  }

  handleRetrieve() {
    this.setValidator();
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      this.grid.setBody(this.model);
      this.grid.loadData();
      this.grid.clearSelectedValues();
    }
  }

  handleUncombine() {
    let data: any[] = this.grid.getSelectedValues();
    console.log(data);

    if (data.length == 0) {
      this.message(
        "information",
        "Information",
        "Please input select data at least one data to process uncombine.",
        "okonly",
        { ok: "" }
      );
    } else {
      this.uncombineProcess(data);
    }
  }

  handleUncombine2() {
    let data: any[] = this.grid.getSelectedValues();
    console.log(data);

    if (data.length == 0) {
      this.message(
        "information",
        "Information",
        "Please input select data at least one data to process uncombine.",
        "okonly",
        { ok: "" }
      );
    } else {
      this.uncombineProcess2(data);
    }
  }

  handleCancel() {
    this.model = new Param();
    this.model.blOceanVesselBound = "O";

    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbLoadPort.setValue("");
    this.cbPOT1.setValue("");
    this.cbDischargePort.setValue("");
    this.cbFinalDest.setValue("");
    this.grid.onClear();
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchLoadPort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbLoadPort.setValue("");
      this.model.blLoadPortCode = "";

      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchDischargePort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbDischargePort.setValue("");
      this.model.blPodCode = "";

      this.cbFinalDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchFinalDestination/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFinalDest.setValue("");
      this.model.blFinalDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchTranshipmentPort/{query}/" +
          event.vesselId +
          "/*/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.model.blPot1Code = "";
    }
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;

      this.cbLoadPort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchLoadPort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbLoadPort.setValue("");
      this.model.blLoadPortCode = "";

      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchDischargePort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbDischargePort.setValue("");
      this.model.blPodCode = "";

      this.cbFinalDest.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchFinalDestination/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbFinalDest.setValue("");
      this.model.blFinalDestCode = "";

      this.cbPOT1.setUrl(
        this.configService.config.BASE_API.toString() +
          "/BL/searchTranshipmentPort/{query}/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.blOceanVesselVoyage).trim()) +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/" +
          this.cookieService.getDefaultLocationCode()
      );
      this.cbPOT1.setValue("");
      this.model.blPot1Code = "";
    }
  }

  changeEventLoadPort(event) {
    if (event.locationCode != null) {
      this.model.blLoadPortCode = event.locationCode;
    } else {
      this.model.blLoadPortCode = "";
    }
  }

  changeEventPOT1(event) {
    if (event.locationCode != null) {
      this.model.blPot1Code = event.locationCode;
    } else {
      this.model.blPot1Code = "";
    }
  }

  changeEventDischargePort(event) {
    if (event.locationCode != null) {
      this.model.blPodCode = event.locationCode;
    } else {
      this.model.blPodCode = "";
    }
  }

  changeEventFinalDest(event) {
    if (event.locationCode != null) {
      this.model.blFinalDestCode = event.locationCode;
    } else {
      this.model.blFinalDestCode = "";
    }
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "uncombine2";
        } else {
          this.disableToolbarButtons = "update,delete,uncombine2";
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          this.disableToolbarButtons = "uncombine2";
        } else {
          this.disableToolbarButtons = "update,delete,uncombine2";
        }

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              //this.handleCombine();
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  uncombineProcess(data: any[]) {
    //this.cookieService.getName().toUpperCase()
    data.forEach((element) => {
      element["user"] = this.cookieService.getName().toUpperCase();
      element["date"] = this.genericUtil.getPSIDate();
      console.log(element);
    });

    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/BLUncombine/uncombine",
        data
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        if (resp.json()["status"] == "OK") {
          this.message(
            "information",
            "Information",
            resp.json()["message"].toString(),
            "okonly",
            { ok: "this.handleRetrieve()" }
          );
        } else {
          this.message(
            "information",
            "Information",
            resp.json()["message"].toString(),
            "okonly",
            { ok: "this.handleRetrieve()" }
          );
        }
      });
  }

  uncombineProcess2(data: any[]) {
    //this.cookieService.getName().toUpperCase()
    data.forEach((element) => {
      element["user"] = this.cookieService.getName().toUpperCase();
      element["date"] = this.genericUtil.getPSIDate();
      console.log(element);
    });

    this.genericUtil.showLoader();
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/BLUncombine/uncombine2",
        data
      )
      .pipe(finalize(() => {
        this.genericUtil.hideLoader();
      }))
      .subscribe((resp) => {
        if (resp.json()["status"] == "OK") {
          this.message(
            "information",
            "Information",
            resp.json()["message"].toString(),
            "okonly",
            { ok: "this.handleRetrieve()" }
          );
        }
      });
  }
}
