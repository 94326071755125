import { Component, OnInit,  AfterViewInit, ViewChild , ElementRef} from '@angular/core';
import { china_tally_list_edi} from "../shared/index";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { ListStore,ConfigService, CookieService, Authorize, Validator,GenericService, GenericUtil, GenericService_V3 }
from 'sibego-ui-library';
import { Router } from '@angular/router';
import 'ag-grid-enterprise';
declare  var $:any;

@Component({
  selector: 'app-edi-receive-tally-list-page',
  templateUrl: './edi-receive-tally-list-page.component.html',
  styleUrls: ['./edi-receive-tally-list-page.component.css']
})
export class EDIReceiveTallyListPageComponent extends Validator  implements OnInit, AfterViewInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild("grid") agGrid: any;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';


  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;
  //isLoading: boolean = false;

  gridApi: GridApi;
  gridColumnApi: ColumnApi;

  /* Parameter settings */
  settingToolbar: any;
  columnDefs: any[];
  defaultColDef: any;
  rowData: any[];

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  tallyListEdiList_Model : china_tally_list_edi[]=[];

  validatorRules = {};
  getRowStyle;

  constructor( private genericService:GenericService, private genericUtil:GenericUtil,private genericServiceV3: GenericService_V3,
    private configService: ConfigService, private router: Router,private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Receive Tally List', event: 'receiveTallyList', id: 'receiveTlList', icon: 'upload' },
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Receive Tally List'
    }


  }
  ngOnInit() {
    this.columnDefs =[
      { headerName: 'No.', field: 'no',  width: 100, headerClass: 'custom-header' },
      { headerName: 'Uploaded', field: 'uploaded', width: 100 , headerClass: 'custom-header'},
      { headerName: 'File Name', field: 'fileName', width: 300 , headerClass: 'custom-header',sortingOrder:["asc"]},//
      { headerName: 'Vessel Name', field: 'vesselName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Voyage No.', field: 'voyageNo', width: 200, headerClass: 'custom-header' },
      { headerName: 'Remarks', field: 'remarks',width: 400 ,  headerClass: 'custom-header'}
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowData = [];
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'receiveTallyList' :
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = '.ASC';

        input.onchange = (event) => {
          const files = (event.target as HTMLInputElement).files;

          if (files) {
            const formData = new FormData();
            let validFileFound = true;

            for (let i = 0; i < files.length; i++) {
              if (files[i].name.toUpperCase().endsWith('.ASC')) {
                formData.append('files', files[i]);
                console.log('File:', files[i].name);

              }else {
              console.warn(files[i].name + ' is not a valid .ASC file.');
              validFileFound = false;
                this.message(
                  "Information",
                  "information",
                  "Please select only .ASC file(s).",
                  "okonly",
                  { ok: "this.loading = false;" }
                );
             }
            }//end for loop

            if (validFileFound) {

              this.loading = true;
              formData.append('userID', this.cookieService.getUserId());
              const url = this.configService.config.BASE_API.toString() +
              //const url = this.configService.config.standalone[0].target +
                          "/shaTallyList/receiveTallyListEdi/" + this.cookieService.getUserId();
              //this._http.post(url, formData, { reportProgress: true, observe: 'events', responseType: 'json' })
              this.genericServiceV3.POST(url,formData, {reportProgress: true, observe: 'events', responseType: 'json' },true)
              .subscribe((data: any) => {
                if (data.type === HttpEventType.UploadProgress) {
                  console.log('Upload progress: ', Math.round((100 * data.loaded) / data.total));

                } else if (data.type === HttpEventType.Response) {
                  if(data.status==200){
                  console.log('Upload complete: ', data.body);
                  this.UploadTallyList();
                  }
                  else{

                    this.message(
                      "warning",
                      "Warning",
                      "No file move to upload folder.",
                      "okonly",
                      { ok: "this.loading=false;" }
                    );

                  }

                }
              });
            }//end upload ASC files
          }//end if (files)
        };

        input.click(); // Simulate click to open file dialog
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  UploadTallyList(){

    this.genericService
    .GET(
       //this.configService.config.standalone[0].target +
      // this.configService.config.standalone[0].endpoint +
      this.configService.config.BASE_API.toString() +
      "/shaTallyList/processTallyListEdi?userId=" + this.cookieService.getUserId()
    )
    .subscribe(
      (resp) => {
        if(resp.status == 200 ){
          if (resp._body !=null) {
            let dtx:any[]
            dtx=JSON.parse(resp._body)
              //console.log('Upload complete: ', dtx[0]["fileName"]);
            dtx.forEach((element) => {
              this.tallyListEdiList_Model.push(element);
            })
            this.processData(this.tallyListEdiList_Model);
          }
        }
      },
      (error)=>{
          this.message(
            "information",
            "Information",
            "Unable to upload tally list data.",
            "okonly",
            { ok: "this.loading = false;" }
          );

      }
    );

  }

  processData(data: any[]) {

    if (Array.isArray(data)) {
      this.rowData = data.map((item, index)=> this.mapItem(item, index));
    } else if (typeof data === 'object' && data !== null) {
      this.rowData = [this.mapItem(data,0)];
    } else {
      console.error('Unexpected data format:', data);
      this.loading = false;
    }
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
      this.loading = false;
    }
  }

  mapItem(item: any, index: number): any {
    return {
      no: index + 1, // Assign sequence number starting from 1
      uploaded: item.uploaded || '',
      fileName: item.fileName || '',
      vesselName: item.vesselName || '',
      voyageNo: item.voyageNo || '',
      remarks: item.remarks || ''
    };
  }

  eventMessage(event){}

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }


}
