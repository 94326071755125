<div class="ui raised segment">

  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:12"><div class="ui medium text loader">Loading</div></div>

  <div id="container">
    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>


    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-invoiceVesselId'] == null || model['error-invoiceVesselId'] == '' || model['error-invoiceVesselId']==undefined">
          {{model['error-invoiceVesselId']}}
        </li>
        <li [class.hidden]="model['error-invoiceVoyage'] == null || model['error-invoiceVoyage'] == '' || model['error-invoiceVoyage']==undefined">
          {{model['error-invoiceVoyage']}}
        </li>
        <li [class.hidden]="model['error-invoiceBound'] == null || model['error-invoiceBound'] == '' || model['error-invoiceBound']==undefined">
          {{model['error-invoiceBound']}}
        </li>
        <li
          [class.hidden]="model['error-invoiceContainer'] == null || model['error-invoiceContainer'] == '' || model['error-invoiceContainer']==undefined">
          {{model['error-invoiceContainer']}}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <button class="ui button" style="display:none;"></button>

      <div class="field" style="margin-bottom: 0px;"> <!-- 1st line -->
        <div class="fields" style="margin-bottom: 5px;">
          <div class="required two wide field" [class.error]="model['error-invoiceVesselId'] != null && model['error-invoiceVesselId'] != ''" >
            <label style="font-size: 10px;">Vessel</label>
            <combo-plugin
              #cbVessel
              [settings]="settingVessel"
              (change)="changeEventVessel($event);"
              (keypress)="formValidation.validateVesselVoyage($event)"
              (keyup)="backspaceValue($event,formValidation.regVesVoy, 'invoiceVesselId', false, '')"
              (paste)="
                onPasteValidation(
                  $event,
                  'invoiceVesselId',
                  formValidation.regVesVoy,
                  false,
                  'and -./'
                )
            "></combo-plugin>
            <div class = "ui basic red pointing prompt label transition" *ngIf="model['error-invoiceVesselId'] != null && model['error-invoiceVesselId'] != ''">
                {{model['error-invoiceVesselId']}}
            </div>
          </div>
          <div class="required two wide field" [class.error]="model['error-invoiceVoyage'] != null && model['error-invoiceVoyage'] != ''" >
            <label style="font-size: 10px;">Voyage</label>
            <combo-plugin #cbVoyage
              [settings]="settingVoyage"
              (keypress)="formValidation.validateVesselVoyage($event)"
              (keyup)="backspaceValue($event,formValidation.regVesVoy, 'invoiceVoyage', false, '')"
              (change)="changeEventVoyage($event);"
              (paste)="onPasteValidation(
                    $event,
                    'invoiceVoyage',
                    formValidation.regVesVoy,
                    false,
                    'and -./'
              )
            "></combo-plugin>
            <div class="ui basic red pointing prompt label transition"
              *ngIf="model['error-invoiceVoyage'] != null && model['error-invoiceVoyage'] != ''">
              {{model['error-invoiceVoyage']}}
            </div>
          </div>
          <div class="required one wide field" [class.error]="model['error-invoiceBound'] != null && model['error-invoiceBound'] != ''">
            <label style="font-size: 10px;">Bound</label>
            <!--
            <input type="text" name="txtBound"  placeholder="Bound" [(ngModel)]="model['invoiceBound']" (keyup)="changeEventBound($event)" (keypress)="blockEnter($event)" >
            -->
            <combo-plugin #cbBound [settings]="settingBound"(change)="changeEventBound($event);"></combo-plugin>
          </div>
          <div class="two wide field" [class.error]="model['error-invoicePOLCode'] != null && model['error-invoicePOLCode'] != ''">
            <label style="font-size: 10px;">Port of Load</label>
            <combo-plugin #cbPOL [settings]="settingPOL"(change)="changeEventPOL($event);" (keypress)="CharOnly($event)"
              (keyup)="backspaceValue($event,formValidation.regStringSpace, 'invoicePOLCode', true, '')"
              (paste)="
                  onPasteValidation(
                    $event,
                    'invoicePOLCode',
                    formValidation.regStringSpace,
                    true,
                    ''
                  )
              "></combo-plugin>
            <div class="ui basic red pointing prompt label transition"
              *ngIf="model['error-invoicePOLCode'] != null && model['error-invoicePOLCode'] != ''">
              {{model['error-invoicePOLCode']}}
            </div>
          </div>
          <div class="three wide field" [class.error]="model['error-invoicePODCode'] != null && model['error-invoicePODCode'] != ''">
            <label style="font-size: 10px;">Port of Discharge</label>
            <combo-plugin #cbPOD [settings]="settingPOD"(change)="changeEventPOD($event);" (keypress)="CharOnly($event)"
              (keyup)="backspaceValue($event,formValidation.regStringSpace, 'invoicePODCode', true, '')"
              (paste)="
                  onPasteValidation(
                    $event,
                    'invoicePODCode',
                    formValidation.regStringSpace,
                    true,
                    ''
                  )
            "></combo-plugin>
            <div class="ui basic red pointing prompt label transition"
              *ngIf="model['error-invoicePODCode'] != null && model['error-invoicePODCode'] != ''">
              {{model['error-invoicePODCode']}}
            </div>
          </div>
          <div class="three wide field" [class.error]="model['error-invoicePOT1Code'] != null && model['error-invoicePOT1Code'] != ''">
            <label style="font-size: 10px;">Port of Transshipment 1</label>
            <combo-plugin #cbPOT1 [settings]="settingPOT1"(change)="changeEventPOT1($event);" (keypress)="CharOnly($event)"
              (keyup)="backspaceValue($event,formValidation.regStringSpace, 'invoicePOT1Code', true, '')"
              (paste)="
                onPasteValidation(
                  $event,
                  'invoicePOT1Code',
                  formValidation.regStringSpace,
                  true,
                  ''
                )
            "></combo-plugin>
            <div class="ui basic red pointing prompt label transition"
              *ngIf="model['error-invoicePOT1Code'] != null && model['error-invoicePOT1Code'] != ''">
              {{model['error-invoicePOT1Code']}}
            </div>
          </div>
          <div class="three wide field" [class.error]="model['error-invoicePayerCode'] != null && model['error-invoicePayerCode'] != ''">
            <label style="font-size: 10px;">Payer</label>
            <combo-plugin #cbPayer
              [settings]="settingPayer"
              (change)="changeEventPayer($event);"
              (keyup)="backspaceValue($event,formValidation.regStringSpace, 'invoicePayerCode', true, '')"
              (keypress)="CharOnly($event)"
              (paste)="onPasteValidation(
                $event,
                'invoicePayerCode',
                formValidation.regStringSpace,
                true,
                ''
              )
            "></combo-plugin>
              <div class="ui basic red pointing prompt label transition"
                *ngIf="model['error-invoicePayerCode'] != null && model['error-invoicePayerCode'] != ''">
                {{model['error-invoicePayerCode']}}
              </div>
          </div>
          <div class="three wide field" [class.error]="model['error-invoiceDebtorCode'] != null && model['error-invoiceDebtorCode'] != ''">
            <label style="font-size: 10px;">Acct/Debtor Code</label>
            <combo-plugin
              #cbAcctDebtorCode
              [settings]="settingAcctDebtorCode"
              (change)="changeEventAcctDebtorCode($event);"
              (keypress)="CharNumOnly($event)"
              (keyup)="backspaceValue($event,formValidation.regAlphaNumeric, 'invoiceDebtorCode', true, '')"
              (paste)="onPasteValidation(
                $event,
                'invoiceDebtorCode',
                formValidation.regAlphaNumeric,
                false,
                ''
              )"></combo-plugin>
              <div class="ui basic red pointing prompt label transition"
                *ngIf="model['error-invoiceDebtorCode'] != null && model['error-invoiceDebtorCode'] != ''">
                {{model['error-invoiceDebtorCode']}}
              </div>
          </div>
          <div class="three wide field" [class.error]="model['error-invoicePlacePayment'] != null && model['error-invoicePlacePayment'] != ''">
            <label style="font-size: 10px;">Place of Payment</label>
            <combo-plugin #cbPlacePayment [settings]="settingPlacePayment"
              (change)="changeEventPlacePayment($event);"
              (keypress)="CharOnly($event)"
              (keyup)="backspaceValue($event,formValidation.regStringSpace, 'invoicePlacePayment', true, '')"
                (paste)="onPasteValidation(
                  $event,
                  'invoicePlacePayment',
                  formValidation.regStringSpace,
                  true,
                  ''
              )"></combo-plugin>
              <div class="ui basic red pointing prompt label transition"
                *ngIf="model['error-invoicePlacePayment'] != null && model['error-invoicePlacePayment'] != ''">
                {{model['error-invoicePlacePayment']}}
              </div>
          </div>


        </div>
      </div>              <!-- end of 1st line -->

      <div class="field" style="margin-bottom: 0px"> <!-- 2nd line -->
        <div class="fields" style="margin-bottom: 5px">
          <div class="four wide field" style="margin-top: 10px;">
            <div class="ui test checkbox" >
              <input type="checkbox" [checked]="model['invoiceIsCOC'] == true " [(ngModel)]="model['invoiceIsCOC']" name="isCOC" (change)="changeEventCheckBoxIsCOC($event)" tabindex="0">
              <label style="font-size: 10px;">COC&nbsp;&nbsp;</label>
            </div>
            <div class="ui test checkbox" >
              <input type="checkbox" [checked]="model['invoiceIsSOC'] == true "  [(ngModel)]="model['invoiceIsSOC']" name="isSOC" (change)="changeEventCheckBoxIsSOC($event)" tabindex="0">
              <label style="font-size: 10px;">SOC&nbsp;&nbsp;</label>
            </div>

            <div class="ui test checkbox" >
              <input type="checkbox" [checked]="model['invoiceIsSLOT'] == true " [(ngModel)]="model['invoiceIsSLOT']" name="isSLOT" (change)="changeEventCheckBoxIsSLOT($event)" tabindex="0">
              <label style="font-size: 10px;">SLOT&nbsp;&nbsp;</label>
            </div>

            <div class="ui test checkbox" >
              <input type="checkbox" [checked]="model['invoiceIsNVOCC'] == true " [(ngModel)]="model['invoiceIsNVOCC']" name="isNVOCC" (change)="changeEventCheckBoxIsNVOCC($event)" tabindex="0">
              <label style="font-size: 10px;">NVOCC&nbsp;&nbsp;</label>
            </div>
          </div>
          <div class="two wide field">
            <label style="font-size: 10px;">Collection</label>
            <combo-plugin #cbChequeStatus [settings]="settingChequeStatus" (change)="changeEventChequeStatus($event)" ></combo-plugin>
          </div>
          <div class="three wide field">
            <label style="font-size: 10px;">Transshipment Status</label>
            <combo-plugin #cbTransshipmentStatus [settings]="settingTransshipmentStatus" (change)="changeEventTransshipmentStatus($event)" ></combo-plugin>
          </div>
          <div class="three wide field">
            <label style="font-size: 10px;">Container Status</label>
            <combo-plugin #cbContainerStatus [settings]="settingContainerStatus" (change)="changeEventContainerStatus($event)" ></combo-plugin>
          </div>
          <div class="three wide field">
            <label style="font-size: 10px;">Invoice Status</label>
            <combo-plugin #cbInvoiceStatus [settings]="settingInvoiceStatus" (change)="changeEventInvoiceStatus($event)" ></combo-plugin>
          </div>
          <div class="two wide field" [class.error]="model['error-invoiceBlRef'] != null && model['error-invoiceBlRef'] != ''">
            <label style="font-size: 10px;">BL Ref.</label>
            <!-- <input type="text" name="invoiceBlRef" placeholder="BL Ref." maxlength="20" [(ngModel)]="model['invoiceBlRef']" (keypress)="CharNumOnly($event)"> -->
            <combo-plugin #cbBlRef 
              [settings]="settingBlRef" 
              (change)="changeEventBlRef($event);"
              (keypress)="CharNumOnlyNoSpace($event)"
              (keyup)="backspaceValue($event,formValidation.regAlphaNumeric, 'invoiceBlRef', false, '')"
              (paste)="
                onPasteValidation(
                  $event,
                  'invoiceBlRef',
                  formValidation.regAlphaNumeric,
                  false,
                  ''
                )"></combo-plugin>
            <div class="ui basic red pointing prompt label transition"
              *ngIf="model['error-invoiceBlRef'] != null && model['error-invoiceBlRef'] != ''">
              {{model['error-invoiceBlRef']}}
            </div>
            </div>
          </div>
        </div>
            <!-- <input
              type="text"
              name="invoiceBlRef"
              placeholder="BL Ref."
              maxlength="20"
              [(ngModel)]="model['invoiceBlRef']"
              (keypress)="CharNumOnly($event)">
              <div class="ui basic red pointing prompt label transition"
                *ngIf="model['error-invoiceBlRef'] != null && model['error-invoiceBlRef'] != ''">
                {{model['error-invoiceBlRef']}}
              </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>-->
      <!-- end of 2nd line -->

      <div class="field"> <!-- 3rd line -->
        <div class="fields">
          <!--
          <div class="four wide field">
            <label>BL Ref.</label>
            <input type="text" name="txtShipperDraft"  placeholder="Shipper Draft BL Number" [(ngModel)]="model['blShipperDraftBLNumber']" (keypress)="blockEnter($event)">
          </div>
          -->
        </div>
      </div>              <!-- end of 3rd line -->

      <div style="width:100%">
        <ag-grid-angular
          style="width: 100%; height: 400px;"
          #topGrid
          class="ag-theme-balham"
          [getRowStyle]="getRowStyle"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize] = "true"
          (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)"
        >
        </ag-grid-angular>
      </div>

      <br />
    </form>
  </div>
</div>

<div id="blDeleteDialogYesNo"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
        {{frmDialogMessage}}</p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlYesDelete()" >Yes</button>
      <button class="negative ui button" (click)="onDialogBlNoDelete()" >No</button>
    </div>
  </form>
</div>

<div id="blLockedDialogYesNo"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
        {{frmDialogMessage}}</p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlYesLocked()" >Yes</button>
      <button class="negative ui button" (click)="onDialogBlNoLocked()" >No</button>
    </div>
  </form>
</div>



<div id="not-found"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <p style="margin-top: 10px"><i class="info circle big icon" style="width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;"></i>
        {{frmDialogMessage}}</p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDismiss()" >Ok</button>
    </div>
  </form>
</div>


<div id="syncReceiptsFromRS"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
        {{messagesyncReceiptsFromRS}}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="negative ui button" (click)="offsyncReceiptsFromRS()" >Ok</button>
    </div>
  </form>
</div>