import { HttpParams } from "@angular/common/http";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Authorize, GenericService, GenericUtil, GridPluginComponent, Validator, ConfigService, Location, CookieService, ComboPluginComponent } from 'sibego-ui-library';
import FormValidation from "../../utils/formValidation";
declare  var $:any;

export class Param {
    vesselNo = '';
    vesselName = '';
    voyageNo = '';
    voyageName = '';
    pol = '';
    polName = '';
    pot = '';
    potName = '';
    from = '';
    to = '';
    selected = '';
    totalAck = 0;
    bound = 'I';
}

@Component({
    selector: 'app-transaction-in-inward-dashboard-page',
    templateUrl: './transaction-in-inward-dashboard-page.component.html',
    styleUrls: ['./transaction-in-inward-dashboard-page.component.css']
})
export class TransactionINInwardDashboard extends Validator implements OnInit, AfterViewInit {
    @ViewChild('grid') grid: GridPluginComponent;
    @ViewChild('gridDetails') gridDetails:GridPluginComponent;
    @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
    @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
    @ViewChild('cbPOL') cbPOL: ComboPluginComponent;
    @ViewChild('cbPOT1') cbPOT1: ComboPluginComponent;

    settingGrid;
    settingToolbar;
    displayToolbar;
    settingVessel;
    settingVoyage;
    settingPOL;
    settingPOT1;
    param = new Param();
    isError = false;

    info = '';
    frmDialogMessage = '';

    /** Parameter for enable/disable Toolbar buttons */
    invisibleToolbarButtons = "";
    disableToolbarButtons = "";

    modeToolbar = false;

    eventToolbar:string = "";

    formValidation = new FormValidation();

    validatorRules = {};

    constructor(private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private configService: ConfigService, private router: Router, private cookieService: CookieService) {
        super();
        this.router.events.pairwise().subscribe((e)=>{
          })

        this.settingToolbar = {
            buttonsFront          : [
                { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
                { name : 'Clear',  event: 'cancel', icon : 'remove' },
                { name : 'Close',  event: 'close', icon : 'power' },
        
              ],
              buttonsDetail         : [ ],
              createDefaultFront    : false,
              createDefaultDetail   : false,
              toolbarType           : 'label',
              label                 : ''
        }
        this.displayToolbar = {
            buttonsFront          : [
              ],
              buttonsDetail         : [ ],
              createDefaultFront    : false,
              createDefaultDetail   : false,
              toolbarType           : 'label',
              label                 : 'Inward Dashboard Analytics'
        }

        this.settingGrid = {
            url: this.configService.config.BASE_API.toString() +'/inward/findAnalyticsInDashboard/'+ this.cookieService.getDefaultLocationCode(),
            // url: 'http://localhost:20000/inward/findAnalyticsInDashboard/'+this.cookieService.getDefaultLocationCode(),
            page: 10,
            columns: [
              { header: 'Transmitted Type', field: 'transmittedTypeString', width: 50},
              {header: 'Vessel Name', field: 'vesselName', width: 100},
              {header: 'Voyage No', field: 'voyageNo', width: 100},
              {header: 'Load Port Name', field: 'blPOLName', width: 100},
              {header: 'Transhipment Port Name', field: 'blPOT1Name', width: 100},
              {header: 'Total Number of BL', field: 'total', width: 100},
              {header: 'Transmitted Date', field: 'transmittedDate', width: 100},
              {header: 'Transmitted By', field: 'transmittedUser', width: 100},
              {header: '', field: 'transmittedUser', labelButton: 'Ack', type: 'button', width: 100},
            ],
            buttons: [],
            enableSorting: true,
            enableSelectAll: false,
            enablePagination: true,
            sortingColumns:'transmittedDate',
            sortingDirection: 'DESC',
        }
        this.settingVessel = {
          id: 'cbVessel',
          type: 'search enter', // search | select | select input
          url:
            this.configService.config.BASE_API.toString() +
            '/MasterVessels/findByComboBoxControl/vesselName={query}',
          maxChars: 3,
          maxlength: 50,
          template: 'grid', // default
          placeholder: '-- Vessel --',
          title: 'vesselName',
          description: '',
          isMark: true,
          columns: [
            { header: 'Vessel Code', field: 'vesselCode', width: 175 },
            { header: 'Vessel Name', field: 'vesselName', width: 200 },
            { header: 'Valid', field: 'isValid', width: 50 },
          ],
        };
        this.settingVoyage = {
          id: 'cbVoyage',
          type: 'search enter', // search | select | select input
          url: '',
          maxChars: 0,
          maxlength: 20,
          template: 'grid', // default
          placeholder: '-- Voyage --',
          title: 'voyage',
          description: '',
          isMark: true,
          columns: [
            { header: 'Voyage', field: 'voyage', width: 100 },
            { header: 'Bound', field: 'bound', width: 100 },
            { header: 'Service', field: 'serviceCode', width: 100 },
          ],
        };
        this.settingPOL = {
          id: 'cbPOL',
          type: 'search enter', // search | select | select input
          url: 
            this.configService.config.BASE_API.toString() +
            '/MasterLocations/findByComboBoxControl/LocationName={query}',
          maxChars: 0,
          maxlength: 50,
          template: 'grid', // default
          placeholder: 'Search ...',
          title: 'locationName',
          description: '',
          isMark: true,
          columns: [
            { header: 'Location Code', field: 'locationCode', width: 175 },
            { header: 'Location Name', field: 'locationName', width: 300 },
            { header: 'Valid?', field: 'isValid', width: 50 },
          ],
        };
        this.settingPOT1 = {
          id: 'cbPOT1',
          type: 'search enter', // search | select | select input
          url: 
            this.configService.config.BASE_API.toString() +
            '/MasterLocations/findByComboBoxControl/LocationName={query}',
          maxChars: 0,
          maxlength: 50,
          template: 'grid', // default
          placeholder: 'Search ...',
          title: 'locationName',
          description: '',
          isMark: true,
          columns: [
            { header: 'Location Code', field: 'locationCode', width: 175 },
            { header: 'Location Name', field: 'locationName', width: 300 },
            { header: 'Valid?', field: 'isValid', width: 50 },
          ],
        };
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        $('#gridHeader').find('th.collapsing').find('div.checkbox').remove();
        $('.dataTables_scrollBody').css('height','150px');
        var self = this;
        if (localStorage.getItem('dashboard-bl-to-flag') != null) {
          this.param = JSON.parse(localStorage.getItem('dashboard-bl-to-flag'));
          this.retrieveData();
          localStorage.removeItem('dashboard-bl-to-flag');
        } 


        const resultVessel = document.getElementById('resultss-cbVessel');
        const resultVoyage = document.getElementById('resultss-cbVoyage');
        if (resultVessel) {
          resultVessel.style.zIndex = '99';
        }
        if (resultVoyage) {
          resultVoyage.style.zIndex = '99';
        }
        this.retrieveData();
    }

    toolbarEvent(event) {
      //// console.log('event : ' + event);
      this.eventToolbar = event.toString();
      switch (event) {
        
        case 'retrieve' :
          this.param['error-vesselNo'] = '';
          this.isError = this.onValidate(this.param);
          if (!this.isError) {
            this.retrieveData();
          }
          break;
        case 'cancel' :
          this.param = new Param(); 
          this.param['error-vesselNo'] = '';
        
          this.isError = false;
          this.grid.body = {};
  
          this.grid.onClear();
          this.grid.is_disabled_next = true;
          this.grid.is_disabled_prev = true;
          
          break;
        case 'close':
          // handle close event
          this.router.navigateByUrl('/main/home');
          break;
      }
    }

    retrieveData() {
      this.grid.sortingColumns = 'vesselName';
      this.grid.is_sort_direction='DESC';
      this.grid.body = {
        vesselNo: this.param.vesselNo.toUpperCase().trim(),
        voyageNo: this.param.voyageNo.toUpperCase().trim(),
        pol: this.param.pol.toUpperCase().trim(),
        pot: this.param.pot.toUpperCase().trim(),
        from: this.param.from,
        to: this.param.to,
      };
      
      this.grid.loadData();

      const baseQuery = this.configService.config.BASE_API.toString() + '/inward/findAnalyticsTotalBL/'+this.cookieService.getDefaultLocationCode();
      // const baseQuery = 'http://localhost:20000/inward/findAnalyticsTotalBL/' + this.cookieService.getDefaultLocationCode();

      let params = new HttpParams()
        .set('vesselNo', this.param.vesselNo)
        .set('voyageNo', this.param.voyageNo)
        .set('pol', this.param.pol)
        .set('pot', this.param.pot)
        .set('from', this.param.from)
        .set('to', this.param.to);

      const fullUrl = baseQuery + '?' + params.toString();


      this.genericService.GET(fullUrl).subscribe((resp) => {
        if(resp.ok){
          const data = resp.json();
          this.param.totalAck = data.totalBL;
        }
      });
    }

    getPaginationValue(no) {
      if (no >= 10 && no % 10 === 0) {
          return 10;
      }
      return no % 10;
    }

    gridEvent(event) {
      console.log('events', event);
        switch (event.split(".")[0]) {
          case 'selected':
            break;
          case 'click' :
            var mode = event.split(".")[1].split("-")[0];
            var no = event.split(".")[1].split("-")[1];

            switch(mode){
              case 'checked' :
                this.selectedListMod(no, this.grid,'seqno');
                break;
              case 'unchecked' :
                break;
              case 'transmittedUser': 
                let paginateNumber = this.getPaginationValue(parseInt(no))
                const dt = this.grid.store[paginateNumber - 1];
                
                this.frmDialogMessage = 'Go to BL Inward Flag Status with vessel '+dt.vesselName+'/ '+dt.voyageNo;
                this.genericUtil.showDialog('blToFlagStatus', 'Yes', 350, 150);
                break;
            }
    
            break;
    
          default:
            let strEvent:string = event;
            if(strEvent != ''){
              let arr:string[] = strEvent.split('-');
              switch(arr[0]){
                case 'dblClick':
                  break;
                default:
                  break;
              }
    
            }
    
            break;
        }
    
      }

      testRefresh(no, field, value){
        this.gridDetails.store.forEach(fe=>{
          if(fe.no == no){
            fe[field] = value;
          }
        });
        this.gridDetails.loadData();
      }

      gridEventDetails(event) {
        //// // // console.log(event);
        switch (event.split(".")[0]) {
          case 'selected':
            this.selectedEvent(event);
            break;
          case 'click' :
            this.selectedEvent(event);
            break;
          case 'AfterEdit' :
            var no = event.split(".")[1].split(";")[0];
            var field = event.split(".")[1].split(";")[1];
            var value = this.toFixed(parseFloat(event.split(".")[1].split(";")[2]));
            // console.log("====================", value));
    
            this.testRefresh(no, field, value);
            //this.updateGridDetails(no, this.gridDetails, value);
            this.gridDetails.loadData();
            this.gridDetails.setRenderValue(parseInt(no), field, "number",this.gridDetails.listStore.store[parseInt(no)-1].allocated)
            break;
          default:
            let strEvent:string = event;
            if(strEvent != ''){
              let arr:string[] = strEvent.split('-');
              switch(arr[0]){
                case 'edit':
                  break;
                case 'dblClick':
                  break;
    
                default:
                  break;
              }
            }
            break;
        }
    
      }

      infoGrid(event){}

      selectedListMod(value, store:GridPluginComponent, key){
        // // // console.log("no "+value);
        if(store.getSelectedValues().length > 1){
          store.getSelectedValues().forEach(ff=>{
            if(ff[key] != value){
              ff['select'] = '';
            }
          });
    
          store.getSelectedValues().splice(0,1);
        }
        // // // console.log("total checked "+store.getSelectedValues().length);
      }

      selectedEvent(event){
        //// // // console.log(event);
        var no:number = 0;
        var opt = (event.split(".").length > 1 ? event.split(".")[1].split("-")[0] : event );
        var opt2 = (event.split(".").length > 1 ? event.split(".")[1].split("-")[1] : event );
        switch(opt){
          case 'checked':
            no = opt2;
            this.selectedListMod(no, this.gridDetails, 'seqNo');
            break;
          case 'unchecked':
            no = opt2;
            break;
        }
      }

      toFixed(x) {
        if (Math.abs(x) < 1.0) {
          var e = parseInt(x.toString().split('e-')[1]);
          if (e) {
              x *= Math.pow(10,e-1);
              x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
          }
        } else {
          var e = parseInt(x.toString().split('+')[1]);
          if (e > 20) {
              e -= 20;
              x /= Math.pow(10,e);
              x += (new Array(e+1)).join('0');
          }
        }
        return x;
      }

      doUnlockByUser(officeCode: String, user: String) {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
            '/BLInward/setUnlockBLByUser/' +
            officeCode +
            '/' +
            user
          )
          .subscribe((resp) => {
          });
      }

      continueToBLFlagStatus(data: any) {
        const self = this;
        this.doUnlockByUser(
          this.cookieService.getDefaultLocationCode(),
          this.cookieService.getName().toUpperCase()
        );
        setTimeout(() => {
          const newParam = {
            blOfficeCode: this.cookieService.getDefaultLocationCode(),
            blVesselId: data.vesselId,
            blVesselName: data.vesselName,
            blVoyage: data.voyageNo,
            blBound: data.bound,
            blPOLCode: self.param.pol,
            blPOLName: self.param.polName,
            blPODCode: data.countOfPOD > 1 ? '' : data.blPOD,
            blPODName: data.countOfPOD > 1 ? '' : data.blPODName,
            blPOT1Code: self.param.pot,
            blPOT1Name: self.param.potName,
            blFDESTCode: "",
            blFDESTName: "",
            blIsCOC: true,
            blIsSOC: true,
            blIsSLOT : true,
            blIsNVOCC : false,
            blLocalTransshipment : 'ALL',
            blFullEmpty : 'ALL',
            blShipperDraftBLNumber : '',
            blIsFinished : false,
            blIsNotFinished : false,
            blIsDeleted : false,
            blIsWithoutContainer : false,
            blIsWithoutCharge : false,
            blTotalSOC : 0,
            blTotalCOC : 0,
            blTotalSLOT : 0,
            blTotalAll : 0,
            blTotalNVOCC : 0,
            blTotalDeleted : 0,
            selected: "",
            "error-blVesselId": "",
            "error-blVoyage": "",
            blNumbers: data.blNoList.split(",")
          };
          localStorage.setItem('dashboard-bl-to-flag', JSON.stringify(this.param));
          localStorage.setItem('close-bl-to-flag', JSON.stringify(newParam));
          const rowDataTotal = {
            totalCoc: 0,
            totalSoc: 0,
            totalSlot: 0,
            totalNvocc: 0,
            totalDeleted: 0,
          }
          this.setColumnState();
          localStorage.setItem('bl-flag-status-row-data-total', JSON.stringify(rowDataTotal));
          localStorage.setItem('bl-flag-status-current-page', '1');
          this.router.navigateByUrl('/main/transaction-in-bl-flag-status');
        }, 100);
      }

      onDialogYes() {
        
        const dt = this.grid.getSelectedValues()[0];

        // const fullUrl = 'http://localhost:20000/inward/updateAckUser';
        const fullUrl = this.configService.config.BASE_API.toString() + "/inward/updateAckUser";

        const body = {
          userName: this.cookieService.getName(),
          vesselId: dt.vesselId,
          voyageNo: dt.voyageNo,
          transmittedDate: dt.transmittedDate,
          officeCode: this.cookieService.getDefaultLocationCode(),
        }
        this.genericService.POST(fullUrl, body).subscribe((resp) => {
          if(resp.ok){
            const data = resp.json();
          }
        });
        this.continueToBLFlagStatus(dt);
        this.genericUtil.closeDialog('blToFlagStatus');
      }
    
      onDialogNo() {
        this.genericUtil.closeDialog('blToFlagStatus');
      }

      /** Combo Change Event Block **/
      changeEventVessel(event) {
        // console.log('Masuk event vessel');
        // console.log(event);
        if (
          event.vesselId != null &&
          event.vesselId !== '' &&
          event.vesselId !== undefined
        ) {
          this.param.vesselNo = event.vesselId;
          this.param.vesselName = event.vesselName;
          this.param[`error-blVesselId`] = '';
          // reset and set voyage url
          this.param.voyageNo = '';
          this.cbVoyage.setValue('');
          this.cbVoyage.setUrl(
            this.configService.config.BASE_API.toString() +
              '/MasterSailingSchedules/findByComboBoxControlVoyageInward/' +
              event.vesselId +
              '/' +
              this.param.bound +
              '/voyage_like={query}'
          );
          console.log('#2');
        } else {
          this.param.vesselNo = '';
          this.param.vesselName = '';
          this.param.voyageNo = '';
          this.cbVoyage.setValue('');
          this.cbVoyage.setUrl('');
          console.log('#3');
        }

        this.cbVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            '/MasterVessels/findByComboBoxControl/vesselName={query}'
        );
      }

      backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string, fieldName?: string) {
        switch (event.code) {
          case 'Backspace':
          case 'ControlLeft':
            this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar, fieldName);
            break;
          default:
            return;
        }
      }

      showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar, fieldName) {
        if (regexName.test(pastedText) == false) {
          this.param[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
        } else {
          this.param[`error-${field}`] = '';
        }
      }

      // * Field Validation On Paste
      onPasteValidation(
        event: any,
        field: string,
        regexName: RegExp,
        validSPChar?: string
      ) {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData('text');
        if (regexName.test(pastedText) == false) {
          this.param[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
        } else {
          this.param[`error-${field}`] = '';
        }
        this.checkErrorblVesselIdblVoyage();
      }

      checkErrorblVesselIdblVoyage() {
        if (!this.param[`error-blVesselId`] && !this.param[`error-blVoyage`]) {
          this.isError = false;
        } else {
          this.isError = true;
        }
      }

      changeEventVoyage(event) {
        if (
          event.voyage != null &&
          event.voyage !== '' &&
          event.voyage !== undefined
        ) {
          console.log('#4');
          this.param.voyageNo = event.voyage;
          this.param[`error-blVoyage`] = '';
          this.cbVoyage.setUrl(
            this.configService.config.BASE_API.toString() +
              '/MasterSailingSchedules/findByComboBoxControlVoyageInward/' +
              this.param.vesselNo +
              '/' +
              this.param.bound +
              '/voyage_like={query}'
          );

          this.isError = false;
        } else {
          console.log('#5');
          this.param.voyageNo = '';
        }
        this.checkErrorblVesselIdblVoyage();
      }
      changeEventPOL(event) {
        if (
          event.locationCode != null &&
          event.locationCode !== '' &&
          event.locationCode !== undefined
        ) {
          this.param.pol = event.locationCode;
          this.param.polName = event.locationName;
        } else {
          this.param.pol = '';
          this.param.polName = '';
        }
      }
      changeEventPOT1(event) {
        if (
          event.locationCode != null &&
          event.locationCode !== '' &&
          event.locationCode !== undefined
        ) {
          this.param.pot = event.locationCode;
          this.param.potName = event.locationName;
        } else {
          this.param.pot = '';
          this.param.potName = '';
        }
      }

      setColumnState() {
        const flagStatusColumnState = [
          {"colId":"blNo","hide":false,"aggFunc":null,"width":150,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blStatus","hide":false,"aggFunc":null,"width":75,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blIsFinished","hide":false,"aggFunc":null,"width":75,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blPlaceOfReceiptName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blLoadPortName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blDischargePortName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blPlaceOfDeliveryName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blFinalDestinationName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blPortOfTransshipment1Name","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blFreightPartyName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blShipperName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blConsigneeName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blNotifyPartyName","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blUserCreated","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blDateCreated","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blUserModified","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blDateModified","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blUserDeleted","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blDateDeleted","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blUserPrinted","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null},
          {"colId":"blDatePrinted","hide":false,"aggFunc":null,"width":200,"pivotIndex":null,"pinned":null,"rowGroupIndex":null}
        ];

        localStorage.setItem('bl-flag-status-data-column-state', JSON.stringify(flagStatusColumnState));
      }
}