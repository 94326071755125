<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <!-- div class="ui progress">
          <div class="bar">
            <div class="progress"></div>
          </div>
          <div class="label">Progress</div>
        </div-->
      </div>      
      <div class="field">
        <div class="field" style="width:400px">
            <label>Vessel </label>
              <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
        </div>
        <div class="field" style="width:400px">
          <label>Voyage </label>
              <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
        </div>
      </div>
      <div class="field" style = "width:400px">
        <fieldset style="width: 100%;border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
          <div class="three fields">
            <div class="field">
              <div class="ui radio checkbox">
                <input #rSoc type="radio" name="cO" checked="checked" (change)="radioEvent(rSoc.checked, 'SOC');">
                  <label>SOC</label>
              </div>
            </div><br>
            <div class="field">
              <div class="ui radio checkbox" >
                <input #rCoc type="radio"  name="cO" (change)="radioEvent(rCoc.checked, 'COC');">
                  <label>COC</label>
              </div>
            </div><br>
            <div class="field">
              <div class="ui radio checkbox">
                <input #rAll type="radio"  name="cO" (change)="radioEvent(rAll.checked, 'ALL');">
                  <label>ALL</label>
              </div>
            </div><br>
          </div>
          <!-- <div class="two fields">
            <!-- <div class="field">
              <div class="ui radio checkbox" >
                <input #rSlot type="radio"  name="cO" (change)="radioEvent(rSlot.checked, 'SLOT');">
                  <label>SLOT</label>
              </div>
            </div><br> -->
            <!-- <div class="field">
              <div class="ui radio checkbox">
                <input #rAll type="radio"  name="cO" (change)="radioEvent(rAll.checked, 'ALL');">
                  <label>ALL</label>
              </div>
            </div><br> -->
          <!-- </div> -->
        </fieldset>
      </div><br>
    </form>
  </div>  <!-- end div container -->

</form>

<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>

<div id="idPrompter" style="display:none;">
  <!-- div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:0px;" -->
    <table border="0" style="width:100%;">
      <tbody>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td style="vertical-align: top;width:12%">
            <div [ngSwitch]="prompterType">
              <div *ngSwitchCase="'information'"><i class="info circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'warning'"><i class="warning sign big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'announcement'"><i class="announcement big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'error'"><i class="minus circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'delete'"><i class="remove circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'save'"><i class="database big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchDefault>{{prompterHeader}}&nbsp;&nbsp;</div>
            </div>
          </td>
          <td>
            <span [innerHTML]="prompterContent"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:center;">
            <div *ngFor="let btn of prompterButtons" class="ui mini buttons" style="position:inherit;padding-left:0;padding-right:0;">
              <div class="ui mini button" style="margin-left:0px;margin-right:5px;" (click)="clickEvent(btn.event)" >                  
                  <span [innerHTML]="btn.name"></span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  <!-- /div -->
</div>

<footer-plugin [info]="info"></footer-plugin>
