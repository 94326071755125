<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

     <!--<Error Message Block-->
      <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-bOceanVesselCode'] == null || model['error-bOceanVesselCode'] == ''">
          {{model['error-bOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-bOceanVesselVoyage'] == null || model['error-bOceanVesselVoyage'] == ''">
          {{model['error-bOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-bOceanVesselBound'] == null || model['error-bOceanVesselBound'] == ''">
          {{model['error-bOceanVesselBound']}}
        </li>
        <li [class.hidden]="model['error-bPolCode'] == null || model['error-bPolCode'] == ''">
          {{model['error-bPolCode']}}
        </li>
      </ul>
    </div>
    <!-- End of Error Message Block-->

    <form class="ui form">
      <div class="fields">
        <!-- KIRI -->
        <div class="field"  style="width:400px;">
          <!-- field kiri baris 1 -->
          
              <div class="field">
                <div class="required one field"  [class.error]="model['error-bOceanVesselCode'] != null && model['error-bOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel  [settings]="settingVessel"   (change)="changeEventVessel($event)" ></combo-plugin>
                </div>             
              </div>             
            <div class="field">
              <div class="required one field"  [class.error]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''">
                <label>Voyage</label>
                <combo-plugin #cbVoyage [settings]="settingVoyage"  (change)="changeEventVoyage($event)"  style="width:100%;"></combo-plugin>
              </div>
            </div>
            <div class="field">
              <div class="required one field"    [class.error]="model['error-bOceanVesselBound'] != null && model['error-bOceanVesselBound'] != ''">
                <label>Bound</label>
                <input type="text" name="bound"  style="text-transform:uppercase" placeholder="Bound" [(ngModel)]="model['bOceanVesselBound']" (change)="changeBound($event)">
              </div>
            </div>  
            <div class="field" >
              <label>Load Port</label>
              <div  class="required one field"  [class.error]="model['error-bPolCode'] != null && model['error-bPolCode'] != ''">
                <combo-plugin #cbLoadPort    [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
              </div>
            </div>    
       
        </div>
        <!-- END KIRI -->


      </div>
    </form>
  </div>  <!-- end div container -->
</form>

<footer-plugin [info]="info"></footer-plugin>
