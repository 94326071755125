<div class="ui raised segment">
<div class="ui divided items">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <div [style.display]="modeForm?'none':'block'">
        <!-- Begin of Grid -->
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm?'block':'none'">
        <div class="ui segment" style="padding-left:1%;padding-right:1%;">
            <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                <div class="ui medium text loader">Loading</div>
            </div>

            <div id='container'>
                <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

                <!-- Begin of Form -->
                <form class="ui form" [style.display]="form == ''?'block':'none'" >
                    <div class="sixteen wide fields">

                            <div class="one field" style="width:100%">
                                <div class="ten wide fields">
                                  
                                    <div class="one field" style="width:100%">
                                        <div class="ten wide fields">
                                            <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                                                    <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                                                    <label>Valid?</label>
                                            </div>
                                            <div class="ui checkbox" style="margin-left:20px;margin-top:2px;">
                                                <input type="checkbox" [(ngModel)]="model['isNotify']" name="isNotify" tabindex="0" class="hidden">
                                                <label>Notify Me</label>
                                            </div>
                                        </div>
                                        <div class="ten wide fields">
                                            <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-userFullName'] != null && model['error-userFullName'] != ''">
                                                <label>Full Name</label>
                                                <input type="text" [(ngModel)]="model['userFullName']" name="userFullName"  placeholder="Full Name" [disabled]="lock == true">                                                        
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-userFullName'] != null && model['error-userFullName'] != ''">
                                                     {{model['error-userFullName']}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ten wide fields">                                            
                                            <div class="required field" style="width:50%;" [class.error]="model['error-userPhone'] != null && model['error-userPhone'] != ''">
                                                <label>Phone</label>
                                                <input type="text" [(ngModel)]="model['userPhone']" name="userPhone"  placeholder="Phone" [disabled]="lock == true">                                                        
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-userPhone'] != null && model['error-userPhone'] != ''">
                                                    {{model['error-userPhone']}}
                                                </div> 
                                            </div>
                                        </div>
                                        <div class="ten wide fields" [style.display]="passwordForm?'none':'block'" [class.disabled]="lockDetail == true">
                                            <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-userEmail'] != null && model['error-userEmail'] != ''">
                                                <label>Email</label>
                                                <input type="text" [(ngModel)]="model['userEmail']" name="userEmail"  placeholder="Email" [disabled]="lock == true">                                                        
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-userEmail'] != null && model['error-userEmail'] != ''">
                                                     {{model['error-userEmail']}}
                                                </div>
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-formatEmail'] != null && model['error-formatEmail'] != ''">
                                                     {{model['error-formatEmail']}}
                                                </div>
                                            </div>
                                            <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-userPassword'] != null && model['error-userPassword'] != ''">
                                                <label>Password</label>
                                                <input type="password" [(ngModel)]="model['userPassword']" name="userPassword" placeholder="Password">                                                     
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-userPassword'] != null && model['error-userPassword'] != ''">
                                                     {{model['error-userPassword']}}
                                                </div>
                                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                [class.error-message-visible]="model['error-passwordValidasi'] != null && model['error-passwordValidasi'] != ''">
                                                    <p [innerHTML]="model['error-passwordValidasi']"></p>
                                                </div>
                                            </div>
                                            <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-confirmPassword'] != null && model['error-confirmPassword'] != ''">
                                                    <label>Confirm Password</label>
                                                    <input type="password" name="confirmpass" [(ngModel)]="model['confirmPassword']" placeholder="Confirm Password">                                                            
                                                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                    [class.error-message-visible]="model['error-confirmPassword'] != null && model['error-confirmPassword'] != ''">
                                                         {{model['error-confirmPassword']}}
                                                    </div>
                                                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                    [class.error-message-visible]="model['error-validatorMatchPassword'] != null && model['error-validatorMatchPassword'] != ''">
                                                         {{model['error-validatorMatchPassword']}}
                                                    </div>
                                            </div>
                                            <div class="field" style="width:50%;margin-top:10px;">
                                                    <label style="font-size: 9px; color: navy; font-weight: 200" >
                                                        * At least 8 characters long<br/>
                                                        * Combination of upper case and lower case<br/>
                                                        * Contains number(s) inside the password<br/>
                                                        * Contains punctuation mark(s) (!,@,#,$,%,^,&,*,?,_,~)<br/>
                                                    </label>
                                            </div>
                                        </div>                      
                                    </div>
                                
                                </div>

                                <div class="ui equal width grid">
                                    <fieldset style="width: 36%;border: 1px solid gray; margin-left:10px;">
                                        <legend style="font-weight:bold;color:dodgerblue;">Location</legend>
                 
                                        <div [style.display]="detailFormMode?'block':'none'">
                                            <form class="ui form">
                                                <div class="three fields" >
                                                    <div class="required field" style="width:50%" [class.error]="modelLocation['error-location_name'] != null && modelLocation['error-location_name'] != ''">
                                                        <label>Location</label>
                                                        <combo-plugin #cbLocation [settings]="settingLocation" [disableCombo]="lock == true" (change)="changeEventCountry($event)" ></combo-plugin>
                                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                            [class.error-message-visible]="modelLocation['error-location_name'] != null && modelLocation['error-location_name'] != ''">
                                                                {{modelLocation['error-location_name']}}
                                                        </div>
                                                    </div>
                                                    <!-- <div class="one field" style="width:100%" [class.error]="modelLocation['error-default'] != null && modelLocation['error-default'] != ''">
                                                            <label>Default</label>
                                                            <div class="ui checkbox">
                                                                <input type="checkbox" [(ngModel)]="modelLocation['default']" name="example">
                                                            </div>
                                                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                                                [class.error-message-visible]="modelLocation['error-default'] != null && modelLocation['error-default'] != ''">
                                                                {{modelLocation['error-default']}}
                                                            </div>
                                                        </div> -->
                                                    <div class="ui tiny right floated buttons" style="margin-top:25px;margin-right:10px">
                                                        <button [style.display]="modeButton?'none':'block'" style="font-size: 10px;" class="ui button detail" data-content="Add" (click)="addLocation();">Add</button>
                                                        <button [style.display]="modeButton?'block':'none'" style="font-size: 10px;" class="ui button detail" data-content="Update" (click)="detailsProcess('UPDATE');">Update</button>
                                                            <div class="or"></div>
                                                        <button class="ui button detail" style="font-size: 10px;" data-content="cancel" (click)="cancelDetail()">Cancel</button>
                                                    </div>
                                                </div> 
                                            </form>
                                     <!-- End of Form  -->
                                        </div>

                                        <div id="displayGrid">
                                                <grid-plugin id='detail-customer' #gridDetails [settings]="settingGridDetails" (gridEvent)="gridEventDetails($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>
                                           
                                        </div>
                                        <!-- <div class="required field">
                                                <combo-plugin #cbLocation [settings]="settingLocation" [disableCombo]="lock" (change)="changeEventCountry($event)" ></combo-plugin>                              
                                        </div> -->
                                        <!-- <div class="one wide fields" style="width:100%;margin-bottom: 8px;margin-top: 2px; margin-left: 2px; " *ngFor="let ct of storeLocation">
                                            <div class="ui checkbox" [class.disabled]="lock == true">
                                                <input type="checkbox">
                                                <label for="{{ct.location_id}}" >{{ct.location_name}}</label>
                                            </div>
                                        </div> -->
                                     </fieldset>
                                     <fieldset style="width: 25%;border: 1px solid gray;">
                                        <legend style="font-weight:bold;color:dodgerblue;">Group</legend>
                                        <a class="ui red ribbon label">Select Group</a>
                                            <div class="one wide fields" style="width:100%;margin-bottom: 8px;margin-top: 8px; margin-left: 2px; " *ngFor="let gp of storeGroup;"> <!--  let i=index" -->
                                                <div class="ui checkbox" [class.disabled]="lockCheckBox == true">
                                                    <input #select type="checkbox" [disabled]="lockCheckBox == true" (change)="onChange(gp, select.checked)" checked='{{gp.isSelect}}'> <!--  name="group_{{i}}"  [value]="group" (change)="onChange(gp, $event)" [ngModel]="isSelected(gp)"-->
                                                    <label for="{{gp.group_id}}" >{{gp.group_name}}</label>
                                                </div>
                                                
                                            </div>
                                            <div class="field">
                                                <button class="ui button" (click)="copyTemplateButton()">Copy Template</button>
                                              </div>
                                                <!-- <br/> Selected Group:
                                            <div *ngFor="let gp of storeGroup">{{gp.group_name}}</div> -->
                                     </fieldset>
                                     <fieldset style="width: 20%;border: 1px solid gray;">
                                        <legend style="font-weight:bold;color:dodgerblue;">Privileges</legend>
                                        <a class="ui red ribbon label">Select Privileges</a>
                                        <ol style="list-style-type:none;margin-left:-25px;" class="sortable ui-sortable mjs-nestedSortable-branch mjs-nestedSortable-expanded">
                                           <div style="border:1px; width: 362px; height: 400px; overflow-y: auto; overflow-x:hidden;">
                                            <li style="display: list-item;" class="mjs-nestedSortable-branch mjs-nestedSortable-expanded" *ngFor="let data of menuList" id="menuItem_{{data.menuId}}">
                                                 <div class="menuDiv">
                                                    <div class="ui checkbox" (click)="onAction(data.menuId)">
                                                        <input #select type="checkbox" name="example" checked='{{data.isSelect}}' (change)="onChangeMenu(data, select.checked)">
                                                            <label></label>
                                                            <i class="{{data.menuIconString}} icon" style="margin-left:25px;"></i>
                                                            <span class="itemTitle">{{data.menuName}}</span>
                                                    </div>                                                            
                                                </div>
                                               <ol style="list-style-type: none;" *ngIf="data.children.length > 0">
                                                    <li style="display: list-item;" class="mjs-nestedSortable-branch mjs-nestedSortable-expanded" *ngFor="let data2 of data.children" id="menuItem_{{data2.menuId}}">
                                                        <div class="menuDiv">
                                                            <div class="ui checkbox" (click)="onAction(data2.menuId)">
                                                                <input #select type="checkbox" name="example" checked='{{data2.isSelect}}' (change)="onChangeMenu(data2, select.checked)">
                                                                <label></label>
                                                                <i class="{{data2.menuIconString}} icon" style="margin-left:25px;"></i>
                                                                <span class="itemTitle">{{data2.menuName}}</span>
                                                            </div>                                                                    
                                                        </div>                                                  
                                                    <ol style="list-style-type: none;" *ngIf="data2.children.length > 0">
                                                        <li style="display: list-item;" class="mjs-nestedSortable-branch mjs-nestedSortable-expanded" *ngFor="let data3 of data2.children" id="menuItem_{{data3.menuId}}">
                                                            <div class="menuDiv">
                                                                <div class="ui checkbox" (click)="onAction(data3.menuId)" >
                                                                    <input #select type="checkbox" name="example" checked='{{data3.isSelect}}' (change)="onChangeMenu(data3, select.checked)">
                                                                    <label></label>
                                                                    <i class="{{data3.menuIconString}} icon" style="margin-left:25px;"></i>
                                                                    <span class="itemTitle">{{data3.menuName}}</span>
                                                                </div>
                                                            </div>                                                                        
                                                        </li>
                                                    </ol>                                                                       
                                                    </li>
                                                </ol> 
                                            </li>   
                                            </div>             
                                        </ol> 
                                     </fieldset>
                                </div>

                                <div class="ten wide fields">

                                    <div class="ui raised segment" style="width:100%;margin-left:8px;margin-right:8px;margin-top:20px;">
                                        <div class="two fields">
                                            <div class="field">
                                                <label>Created By</label>
                                                <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" readonly style="background-color: lightgray">
                                            </div>
                                            <div class="field">
                                                <label>Created Date</label>
                                                <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" readonly style="background-color: lightgray">
                                            </div>
                                        </div>
                                        <div class="two fields">
                                            <div class="field">
                                                <label>Last Modified By</label>
                                                <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" readonly style="background-color: lightgray">
                                            </div>
                                            <div class="field">
                                                <label>Last Modified Date</label>
                                                <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" readonly style="background-color: lightgray">
                                            </div>
                                        </div>
                                        <div class="two fields">
                                            <div class="field">
                                                <label>Invalid By</label>
                                                <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" readonly style="background-color: lightgray">
                                            </div>
                                            <div class="field">
                                                <label>Invalid Date</label>
                                                <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" readonly style="background-color: lightgray">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </form>
                <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
                    <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History User</h4>
                    
                    <div class="ui raised segment">
                        <a class="ui red ribbon label">Select User</a>
                        <div class="active content">
                            <div class="one field" style="margin-top:10px;">
                                <div class="field">
                                  <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                                </div>
                            </div>
                            <div class="one field" style="margin-top:10px;">
                                <div class="required field" style="width:50%" [class.error]="model['error-userFullName'] != null && model['error-userFullName'] != ''">
                                    <label>Name</label>
                                    <combo-plugin #cbUserNameHistory [settings]="settingUserHistory" (change)="valueUserHistory($event)"></combo-plugin>
                                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                         [class.error-message-visible]="model['error-userFullName'] != null && model['error-userFullName'] != ''">
                                      {{model['error-userFullName']}}
                                    </div>
                                  </div>
                            </div>
                        </div>  
                    </div>

                    <div class="field">
                        <grid-plugin #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
                    </div>
                </form>
                <!-- End of Form -->

                <!-- component -->
                <div id="componentActionForm"  style="display:none;font-family:Lucida Console, monospace;"> <!--class="ui fullscreen modal"  style="width:90%;padding:1%;">-->
                    
                    <form  class="ui form" style="width:100%;"> <!-- form start -->
                        <h4 class="ui dividing header" style="width: 100%;text-align:left;margin-top:10px;"></h4>
                       
                        <fieldset style="width: 100%;border: 1px solid gray;">
                                <legend style="font-weight:bold;color:dodgerblue;">Component Action</legend>
                                <a class="ui red ribbon label">Select Action</a>
                                    <div class="one wide fields" style="width:100%;margin-bottom: 8px;margin-top: 8px; margin-left: 2px; " *ngFor="let sc of storeComponent;">
                                        <div class="ui checkbox" [class.disabled]="lockCheckBox == true">
                                            <input #select type="checkbox" [disabled]="lockCheckBox == true" (change)="onChangeComponent(sc, select.checked)" checked='{{sc.isSelect}}'>
                                            <label for="{{sc.component_id}}" >{{sc.component_name}}</label>
                                        </div>
                                    </div>
                                    <div class="field">
                                            <button class="ui button" style="font-size: 12px;" (click)="onSaveAction()" [disabled]="lockAction == true" >Save</button>
                                            <button class="ui button" style="font-size: 12px;" (click)="onCloseAction()"> Close</button>
                                    </div>
                         </fieldset>
                    </form> <!-- form end -->
                 </div>
                <!-- end of component -->

            </div>
        </div>
    </div>

</div>
</div>
