import { escapeChars, urlChars } from "../globals";

export class specialCharUtil{
    specialChar = [];

    constructor(){
        this.specialChar["."] = "@titik@";
        this.specialChar["-"] = "@hypen@";
        this.specialChar["&"] = "@and@";
        this.specialChar["\\"] = "@backslashes@";
    }

    htmlEncode(text:string){
        console.log(escapeChars);
        var result = "";
        if(text.length > 0){
            for(var i=0;i<text.length;i++){
                let char = text.charAt(i);
                if(char in escapeChars){
                    console.log("sapi");
                    console.log(escapeChars[char]);
                    char = "[" + escapeChars[char] + "]";
                }

                result = result + char;
            }
        }

        return result;
    }

    htmlDecode(text:string){
        var result = text;
        if(text.length > 0){
            while(result.indexOf("[amp]") != -1){
                result = result.replace("[amp]","&");
            }
            while(result.indexOf("[-45]") != -1){
                result = result.replace("[-45]","-");
            }
            while(result.indexOf("[-46]") != -1){
                result = result.replace("[-46]",".");
            }
            while(result.indexOf("[-92]") != -1){
                result = result.replace("[-92]","\\");
            }
            while(result.indexOf("[lsquo]") != -1){
                result = result.replace("[lsquo]","‘");
            }
            while(result.indexOf("[rsquo]") != -1){
                result = result.replace("[rsquo]","’");
            }
            while(result.indexOf("[sbquo]") != -1){
                result = result.replace("[sbquo]","‚");
            }
            while(result.indexOf("[ldquo]") != -1){
                result = result.replace("[ldquo]","“");
            }
            while(result.indexOf("[rdquo]") != -1){
                result = result.replace("[rdquo]","”");
            }
            while(result.indexOf("[bdquo]") != -1){
                result = result.replace("[bdquo]","„");
            }
            while(result.indexOf("[dagger]") != -1){
                result = result.replace("[dagger]","†");
            }
            while(result.indexOf("[Dagger]") != -1){
                result = result.replace("[Dagger]","‡");
            }
            while(result.indexOf("[cent]") != -1){
                result = result.replace("[cent]","¢");
            }
            while(result.indexOf("[pound]") != -1){
                result = result.replace("[pound]","£");
            }
            while(result.indexOf("[yen]") != -1){
                result = result.replace("[yen]","¥");
            }
            while(result.indexOf("[euro]") != -1){
                result = result.replace("[euro]","€");
            }
            while(result.indexOf("[copy]") != -1){
                result = result.replace("[copy]","©");
            }
            while(result.indexOf("[reg]") != -1){
                result = result.replace("[reg]","®");
            }
            while(result.indexOf("[-33]") != -1){
                result = result.replace("[-33]","!");
            }
            while(result.indexOf("[quot]") != -1){
                result = result.replace("[quot]","\"");
            }
            while(result.indexOf("[-35]") != -1){
                result = result.replace("[-35]","#");
            }
            while(result.indexOf("[-36]") != -1){
                result = result.replace("[-36]","$");
            }
            while(result.indexOf("[-37]") != -1){
                result = result.replace("[-37]","%");
            }
            while(result.indexOf("[-39]") != -1){
                result = result.replace("[-39]","\"");
            }
            while(result.indexOf("[-40]") != -1){
                result = result.replace("[-40]","(");
            }
            while(result.indexOf("[-41]") != -1){
                result = result.replace("[-41]",")");
            }
            while(result.indexOf("[-42]") != -1){
                result = result.replace("[-42]","*");
            }
            while(result.indexOf("[-43]") != -1){
                result = result.replace("[-43]","+");
            }
            while(result.indexOf("[-44]") != -1){
                result = result.replace("[-44]",",");
            }
            while(result.indexOf("[-47]") != -1){
                result = result.replace("[-47]","/");
            }
            while(result.indexOf("[-58]") != -1){
                result = result.replace("[-58]",":");
            }
            while(result.indexOf("[-59]") != -1){
                result = result.replace("[-59]",";");
            }
            while(result.indexOf("[lt]") != -1){
                result = result.replace("[lt]","<");
            }
            while(result.indexOf("[-61]") != -1){
                result = result.replace("[-61]","=");
            }
            while(result.indexOf("[gt]") != -1){
                result = result.replace("[gt]",">");
            }
            while(result.indexOf("[-63]") != -1){
                result = result.replace("[-63]","?");
            }
            while(result.indexOf("[-64]") != -1){
                result = result.replace("[-64]","@");
            }
            while(result.indexOf("[-91]") != -1){
                result = result.replace("[-91]","[");
            }
            while(result.indexOf("[-93]") != -1){
                result = result.replace("[-93]","]");
            }
            while(result.indexOf("[-94]") != -1){
                result = result.replace("[-94]","^");
            }
            while(result.indexOf("[space]") != -1){
                result = result.replace("[space]"," ");
            }
            while(result.indexOf("[-124]") != -1){
                result = result.replace("[-124]","|");
            }
        }

        return result;
    }

    textEncode(text:string){
        var result = "";
        if(text.length > 0){
            for(var i=0;i<text.length;i++){
                let char = text.charAt(i);
                if(this.specialChar[char] != undefined){
                    char = this.specialChar[char];
                }

                result = result + char;
            }
        }

        return result;
    }

    textDecode(text:string){
        var result = text;
        if(text.length > 0){
            while(result.indexOf("@titik@") != -1){
                result = result.replace("@titik@",".");
            }

            while(result.indexOf("@hypen@") != -1){
                result = result.replace("@hypen@","-");
            }

            while(result.indexOf("@and@") != -1){
                result = result.replace("@and@","&");
            }

            while(result.indexOf("@backslashes@") != -1){
                result = result.replace("@backslashes@","\\");
            }
        }

        return result;
    }


    decodeLongTextUriComponentSafe(text){
        var out = "",
            i = 0,
            x,
            totalRow,
            rowArr;

        rowArr = text.split("↵");
        for(totalRow = rowArr.length; i < totalRow; i++){
            try{
                x = this.decodeURIComponentSafe(rowArr[i],1);
            } catch (e){
                x = rowArr[i];
            }

            if(i == totalRow-1){
                out += x;
            } else {
                out += x + "↵";
            }
        }

        return out;
    }

    decodeURIComponentSafe(uri, mod) {
        var out = "",
            arr,
            i = 0,
            l,
            x;
        typeof mod === "undefined" ? mod = 0 : 0;
        //arr = uri.split(/(%(?:d0|d1)%.{2})/);
        arr = uri.split(/&nbsp;|\b\s+/g);
        for (l = arr.length; i < l; i++) {
            try {
                x = decodeURIComponent(arr[i]);
            } catch (e) {
                //x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
                x = mod ? arr[i].replace(/%(?!\d|[ABCDEF]+)/g, '%25') : arr[i];
            }
            
            if(i == (l-1)){
              out += x;
            } else {
              out += x + " ";
            }
        }
        return out;
    }

    utf8Encode(strUni) {
        return String(strUni).replace(
            /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
            function (c) {
                var cc = c.charCodeAt(0);
                return String.fromCharCode(0xc0 | cc >> 6, 0x80 | cc & 0x3f);
            }
        ).replace(
            /[\ud800-\udbff][\udc00-\udfff]/g,  // surrogate pair
            function (c) {
                var high = c.charCodeAt(0);
                var low = c.charCodeAt(1);
                var cc = ((high & 0x03ff) << 10 | (low & 0x03ff)) + 0x10000;
                // U+10000 - U+10FFFF => 4 bytes 11110www 10xxxxxx, 10yyyyyy, 10zzzzzz
                return String.fromCharCode(0xf0 | cc >> 18, 0x80 | cc >> 12 & 0x3f, 0x80 | cc >> 6 & 0x3f, 0x80 | cc & 0x3f);
            }
        ).replace(
            /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
            function (c) {
                var cc = c.charCodeAt(0);
                return String.fromCharCode(0xe0 | cc >> 12, 0x80 | cc >> 6 & 0x3f, 0x80 | cc & 0x3f);
            }
        );
    }

    utf8Decode(strUtf) {
        // note: decode 2-byte chars last as decoded 2-byte strings could appear to be 3-byte or 4-byte char!
        return String(strUtf).replace(
            /[\u00f0-\u00f7][\u0080-\u00bf][\u0080-\u00bf][\u0080-\u00bf]/g,  // 4-byte chars
            function (c) {  // (note parentheses for precedence)
                var cc = ((c.charCodeAt(0) & 0x07) << 18) | ((c.charCodeAt(1) & 0x3f) << 12) | ((c.charCodeAt(2) & 0x3f) << 6) | ( c.charCodeAt(3) & 0x3f);
                var tmp = cc - 0x10000;
                // TODO: throw error(invalid utf8) if tmp > 0xfffff
                return String.fromCharCode(0xd800 + (tmp >> 10), 0xdc00 + (tmp & 0x3ff)); // surrogate pair
            }
        ).replace(
            /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
            function (c) {  // (note parentheses for precedence)
                var cc = ((c.charCodeAt(0) & 0x0f) << 12) | ((c.charCodeAt(1) & 0x3f) << 6) | ( c.charCodeAt(2) & 0x3f);
                return String.fromCharCode(cc);
            }
        ).replace(
            /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
            function (c) {  // (note parentheses for precedence)
                var cc = (c.charCodeAt(0) & 0x1f) << 6 | c.charCodeAt(1) & 0x3f;
                return String.fromCharCode(cc);
            }
        );
    }

    encodeUrl(text:string){
        console.log(urlChars);
        var result = "";
        var newMapping = {};
        if(text.length > 0){
		    for (var key in urlChars) {
                newMapping[urlChars[key]] = key;
            }

            for(var i=0;i<text.length;i++){
                let char = text.charAt(i);
                if (char in newMapping) {
                    char = newMapping[char];
                }
		        result = result + char;		   
            }
        }
        return result;
    }
    
}