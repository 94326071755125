import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  Authorize,
  ComboPluginComponent,
  ConfigService,
  CookieService,
  GenericService,
  GenericUtil,
  GridPluginComponent,
  Validator,
} from "sibego-ui-library";
import { ExceptionModel } from "../../shared/models/exceptionModel";
import FormValidation from "../../../utils/formValidation";
import { ExceptionBookingService } from "./transaction-ts-exception.service";
import { finalize } from "rxjs/operators";
import { TransactionTsExceptionHandlingComponent } from "./transaction-ts-exception-handling/transaction-ts-exception-handling.component";
import { TransactionTsPortHomeService } from "../transaction-ts-port-home.service";
declare var $: any;

@Component({
  selector: "app-transaction-ts-exception",
  templateUrl: "./transaction-ts-exception.component.html",
  styleUrls: ["./transaction-ts-exception.component.css"],
})
export class TransactionTsExceptionComponent
  extends Validator
  implements OnInit
{
  @Output() redirectToExceptionHandel = new EventEmitter<string>();

  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbContOpr") cbContOpr: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbDischargingVessel") cbDischargingVessel: ComboPluginComponent;
  @ViewChild("cbConnectingVessel") cbConnectingVessel: ComboPluginComponent;
  @ViewChild("cbDischargingVoyage") cbDischargingVoyage: ComboPluginComponent;
  @ViewChild("cbConnectingVoyage") cbConnectingVoyage: ComboPluginComponent;
  @ViewChild("exceptionHandling") exceptionHandling!: TransactionTsExceptionHandlingComponent;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  valueDialog = "";
  dialogFrom = "";
  messageValidateDialog = "";
  noteContent = "";

  isExceptionPage = "EXCEPTION";
  modeToolbar = false;
  loading = false;
  isError: Boolean = false;

  dialog: any;
  settingToolbar;
  settingGrid;
  topGridPortPair: any;

  settingPOT1: any;
  settingLoadPort: any;
  settingDischargePort: any;
  settingDischargingVessel: any;
  settingConnectingVessel: any;
  settingDischargingVoyage: any;
  settingConnectingVoyage: any;
  defaultColumnDefs: any;
  getRowStyle: any;
  getRowStyleGridPortPair: any;

  model = new ExceptionModel();

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  settingStatus: {
    id: string;
    type: string;
    url: string;
    urlType: string;
    placeholder: string;
    code: string;
    title: string;
  };

  defaultColumnDefsGridPortPair: (
    | {
        headerName: string;
        width: number;
        headerCheckboxSelection: boolean;
        headerCheckboxSelectionFilteredOnly: boolean;
        checkboxSelection: boolean;
        showDisabledCheckboxes: boolean;
        field?: undefined;
      }
    | {
        headerName: string;
        field: string;
        width: number;
        headerCheckboxSelection?: undefined;
        headerCheckboxSelectionFilteredOnly?: undefined;
        checkboxSelection?: undefined;
        showDisabledCheckboxes?: undefined;
      }
    | {
        headerName: string;
        field: string;
        width?: undefined;
        headerCheckboxSelection?: undefined;
        headerCheckboxSelectionFilteredOnly?: undefined;
        checkboxSelection?: undefined;
        showDisabledCheckboxes?: undefined;
      }
  )[];

  validatorRules = {};
  rowData = [];
  rowDataGridPortPair = [];
  gridApi: any;
  columnApi: any;
  bkgNo = "";
  isRetrieveByBkgNo = false;
  isValidbkgNo = "";
  labelConfirmCancelClose = "";
  maxlengthDialog = 4;
  rowIndexW: any;

  // Form Validation
  formValidation = new FormValidation();

  isDisableThruBNo = true;

  constructor(
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private exceptionBookingService: ExceptionBookingService,
    private transactionTsPortHomeService: TransactionTsPortHomeService
  ) {
    super();
    this.handleSetting();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "search", icon: "search" },
        { name: "POT Remarks", event: "potRemarks", icon: "plus" },
        { name: "Outward Slot", event: "outwardSlot", icon: "save" },
        { name: "Reason", event: "reason", icon: "copy" },
        {
          name: "Exception Handling",
          event: "exceptionHandling",
          icon: "save",
        },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
        { name: "Export", event: "export", icon: "save" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Exception",
    };

    this.settingStatus = {
      id: "cbContOpr",
      type: "select",
      url: '[ { "contOprCode" : "ALL", "contOprName" : "All" }, { "contOprCode" : "CONFIRMED", "contOprName" : "CONFIRMED" }, { "contOprCode" : "RENOMINATED", "contOprName" : "RENOMINATED" }]',
      urlType: "inject",
      placeholder: "All",
      code: "contOprCode",
      title: "contOprName",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "ETD", field: "sailDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50,
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingDischargingVessel = {
      id: "cbDischargingVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingConnectingVessel = {
      id: "cbConnectingVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingDischargingVoyage = {
      id: "cbDischargingVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingConnectingVoyage = {
      id: "cbConnectingVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.defaultColumnDefs = [
      {
        headerName: "",
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: 'Status', field: 'tsStatus', width: 100 },
      {
        headerName: "Priority Booking",
        field: "tsIsPriorityBooking",
        width: 150,
      },
      { headerName: "POL B/L No.", field: "tsPolBlNo", width: 150 },
      { headerName: "POL BKG No.", field: "tsPolBookingNo", width: 150 },
      { headerName: "BKG Party", field: "tsPolBookingPartyName", width: 200 },
      { headerName: "Shipper", field: "tsPolShipperName", tooltipField: 'tsPolShipperName' },
      { headerName: "Load Port", field: "tsPolLoadPortName" },
      { headerName: "Terminal (POL)", field: "tsPolLoadPortTerminalName" },
      { headerName: "Discharge Port", field: "tsPolDischargePortName" },
      { headerName: "Terminal (POD)", field: "tsPolDischargePortTerminalName" },
      { headerName: "Place of Delivery", field: "tsPolPodlName" },

      { headerName: "Final Destination", field: "tsPolFdestName" },
      { headerName: "Sp Dtls", field: "tsPolSpecialDetailsName" },
      { headerName: "Ctr Type", field: "tsPolContainerType" },
      { headerName: "DG", field: "tsPolDg" },

      { headerName: "20", field: "tsPol20" },
      { headerName: "40", field: "tsPol40" },
      { headerName: "45", field: "tsPol45" },
      { headerName: "H20", field: "tsPolH20" },
      { headerName: "H40", field: "tsPolH40" },
      { headerName: "H45", field: "tsPolH45" },
      { headerName: "POL BKG Remarks", field: "tsPolBookingRemarks", tooltipField: 'tsPolBookingRemarks' },

      { headerName: "Discharging Vessel", field: "tsDischargingVesselName" },
      { headerName: "Discharging Voyage", field: "tsDischargingVesselVoyage" },
      { headerName: "Bound", field: "tsDischargingVesselBound" },
      { headerName: "Service", field: "tsDischargingServiceCode" },
      { headerName: "ETA POL", field: "tsDischargingEtaPol" },
      { headerName: "ETD POL", field: "tsDischargingEtdPol" },
      { headerName: "ETA POT", field: "tsDischargingEtaPot" },
      { headerName: "ETD POT", field: "tsDischargingEtdPot" },

      { headerName: "Connecting Vessel", field: "tsConnectingVesselName" },
      { headerName: "Connecting Voyage", field: "tsConnectingVesselVoyage" },
      { headerName: "Bound", field: "tsConnectingVesselBound" },
      { headerName: "Service", field: "tsConnectingServiceCode" },
      { headerName: "ETA POT", field: "tsConnectingEtaPot" },
      { headerName: "ETD POT", field: "tsConnectingEtdPot" },
      { headerName: "ETA POD", field: "tsConnectingEtaPod" },
      { headerName: "ETD POD", field: "tsConnectingEtdPod" },

      {
        headerName: 'Outward Slot', field: 'tsOutwardSlotOperator',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 4, rows: 1, cols: 20 }
      },
      {
        headerName: "POT Remarks",
        field: "tsPotRemarks",
        tooltipField: 'tsPotRemarks',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: "agLargeTextCellEditor",
        cellEditorParams: { maxLength: 320 },
      },
      {
        headerName: "Reason for Exception Handling",
        field: "tsReasonForExceptionHandling",
        tooltipField: 'tsReasonForExceptionHandling',
        editable: params => {
          return params.data.tsStatus === "Rejected" || params.data.tsStatus === "Deleted" || params.data.tsStatus === "Archived" ? false : true
        },
        cellEditor: "agLargeTextCellEditor",
        cellEditorParams: { maxLength: 320 },
      },
      { headerName: 'POT BKG No.', field: 'tsPotBookingNo', tooltipField: 'tsPotBookingNo' },
      { headerName: 'POT B/L No.', field: 'tsPotBlNo', tooltipField: 'tsPotBlNo' },
      { headerName: "Confirmed By", field: "tsConfirmedUserId" },
      { headerName: "Confirmed Date", field: "tsConfirmedDate" },
      { headerName: "Renominated By", field: "tsRenominatedUserId" },
      { headerName: "Renominated Date", field: "tsRenominatedDate" },
      { headerName: "Rejected By", field: "tsRejectedUserId" },
      { headerName: "Rejected Date", field: "tsRejectedDate" },
    ];

    this.defaultColumnDefsGridPortPair = [
      { headerName: "Load Port", field: "tslLoadPortName" },
      { headerName: "Terminal(POL)", field: "tslLoadPortTerminalName" },
      { headerName: "DischargePort", field: "tslDischargePortName" },
      { headerName: "Terminal(POD)", field: "tslDischargePortTerminalName" },
      { headerName: "Allocated(Teus)", field: "tslAllocatedTeus" },
      { headerName: "Booked(Teus)", field: "tslBookedTeus" },
      { headerName: "Confirmed(Teus)", field: "tslConfirmedTeus" },
      { headerName: "Available(Teus)", field: "tslAvailableTeus" },
    ];

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        //{header: 'ID', field: 'id', width: 50},
        //{header: 'Index', field: 'Index', width: 20},
        { header: "Load Port", field: "polName", width: 200 },
        { header: "Discharge Port", field: "podName", width: 200 },
        //{header: 'Container Type', field: 'contType', width: 200},
        { header: "Allocated (Teus)", field: "alloted", width: 200 },
        { header: "Booked (Teus)", field: "booked", width: 200 },
        { header: "Confirmed (Teus)", field: "confirm", width: 200 },
        { header: "Available (Teus)", field: "available", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      /*
      footer : {
        label : 'Total Allocation'
      },
      */
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "locationName",
      sortingDirection: "ASC",
    };

    this.getRowStyle = function (params) {
      console.log(params);

      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tslIsDeleted === "Y") {
        return { "background-color": "lightcoral !important;" };
      }
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.cbPOT1.setValue("SGSIN");
    this.setValidatorRetrieve();
    this.model.officeCode = this.officeCode;
    setTimeout(() => {
      this.assignValueStage();
      this.disableToolbarButtons = "potRemarks,outwardSlot,reason,exceptionHandling,export";
    }, 100);
  }

  assignValueStage(){
    this.transactionTsPortHomeService.getDataExceptionBooking().subscribe(data=>{
      if(data.model && data.gird){
        this.model = data.model;  

        this.cbDischargingVessel.setForceValue(data.model.vesselNameDischarging || "");
        this.cbDischargingVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findVesselListForProspectBkg/" +
            this.officeCode +
            "/" +
            this.model.bPolCode +
            "/{query}"
        );

        this.cbDischargingVoyage.setForceValue(data.model.voyageDischarging || "");
        this.cbDischargingVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + 
          this.officeCode + '/' + 
          this.model.vesselIdDischarging + "/" + 
          this.model.dischargingBound + "/voyage_like=" + "{query}" + "?portOfCall=" + 
          this.model.bPolCode + "&potCode=" + 
          this.model.bPot1Code
        );

        this.cbConnectingVessel.setForceValue(data.model.vesselNameConnecting || "");
        this.cbConnectingVessel.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findVesselListForProspectBkg/" +
            this.officeCode +
            "/" +
            this.model.bPot1Code +
            "/{query}"
        );

        this.cbConnectingVoyage.setForceValue(data.model.voyageConnecting || "");
        this.cbConnectingVoyage.setUrl(
          this.configService.config.BASE_API.toString() + 
          "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
           this.officeCode + '/' + this.model.vesselIdConnecting + "/" +
            this.model.connectingBound + 
            "/voyage_like=" + "{query}" + "?portOfCall=" + 
          this.model.bPot1Code
        );

        this.cbLoadPort.setForceValue(data.model.bPolName);
        this.cbDischargePort.setForceValue(data.model.podName);
        this.rowData = data.gird;

        this.gridApi.setPinnedBottomRowData([
          {
            tsStatus: 'Total Unit :',
            tsPol20: data.totalD20,
            tsPol40: data.totalD40,
            tsPol45: data.totalD45,
            tsPolH20: data.totalH20,
            tsPolH40: data.totalH40,
            tsPolH45: data.totalH45,
          }
        ]);
    
      }
    })
  }

  setValidatorRetrieve() {
    this.model["error-bPot1Code"] = "";
    this.model["error-bPolCode"] = "";
    this.model["error-allocationValidator"] = "";
    this.validatorRules = {
      bPot1Code: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      }
    };
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "search":
        this.handleSearch();
        break;
      case "potRemarks":
        this.handlePotRemarks();
        break;
      case "outwardSlot":
        this.handleOutwardSlot();
        break;
      case "exceptionHandling":
        this.comfirmHandleExceptionHandling();
        break;
      case "reason":
        this.handleReason();
        break;
      case "cancel":
        this.confirmCancelClose("cancel");
        break;
      case "export":
        this.handleExport();
        break;
      case "close":
        this.confirmCancelClose("close");
        break;
    }
  }

  changeEventPOT(event) {
    if (event.locationCode) {
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;
      this.cbConnectingVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findVesselListForProspectBkg/" +
          this.officeCode +
          "/" +
          event.locationCode +
          "/{query}"
      );
      this.model["error-bPot1Code"] = "";
      if (this.model.bPolCode && this.model.bPot1Code) {
        this.isError = false;
      }
    } else {
      this.model.bPot1Code = "";
      this.model.bPot1Name = "";
    }
  }

  retrieveByBkgNoChange(event) {
    this.bkgNo = "";
    this.isValidbkgNo = "";
    this.clearFormSearch();

    if(event){
      this.isDisableThruBNo = false;
      this.cbPOT1.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbDischargingVessel.disableCombo = true;
      this.cbConnectingVessel.disableCombo = true;
      this.cbDischargingVoyage.disableCombo = true;
      this.cbConnectingVoyage.disableCombo = true;
    }else{
      this.isDisableThruBNo = true;
      this.cbPOT1.disableCombo = false;
      this.cbLoadPort.disableCombo = false;
      this.cbDischargePort.disableCombo = false;
      this.cbDischargingVessel.disableCombo = false;
      this.cbConnectingVessel.disableCombo = false;
      this.cbDischargingVoyage.disableCombo = false;
      this.cbConnectingVoyage.disableCombo = false;
    }
  }

  clearFormSearch(){
    this.cbLoadPort.setValue("");
    this.model.bPolCode = "";
    this.model.bPolName = "";
    this.cbDischargePort.setValue("");
    this.model.podId = "";
    this.model.podCode = "";
    this.model.podName = "";
    this.cbDischargingVessel.setValue("");
    this.model.vesselIdDischarging = "";
    this.model.vesselCodeDischarging = "";
    this.model.vesselNameDischarging = "";
    this.cbConnectingVessel.setValue("");
    this.model.vesselIdConnecting = "";
    this.model.vesselCodeConnecting = "";
    this.model.vesselNameConnecting = "";
    this.cbDischargingVoyage.setValue("");
    this.model.voyageDischarging = "";
    this.cbConnectingVoyage.setValue("");
    this.model.voyageConnecting = "";

    this.model.tsConnectingEtaPod = "";
    this.model.tsConnectingEtaPot = "";
    this.model.tsConnectingEtdPod = "";
    this.model.tsConnectingEtdPot = "";
    this.model.tsDischargingEtaPol = "";
    this.model.tsDischargingEtaPot = "";
    this.model.tsDischargingEtdPol = "";
    this.model.tsDischargingEtdPot = "";

    this.isError = false;
    this.model["allocationValidator"] = false;
    this.model["error-allocationValidator"] = "";
    this.model["error-podCode"] = "";
    this.model["error-bPolCode"] = "";
    this.model["error-vesselCode"] = "";
    this.model["error-voyage"] = "";
    this.model["error-bound"] = "";
    this.model["error-bPot1Code"] = "";
  }

  handleSearch() {
    this.isError = false;
    this.model["allocationValidator"] = false;
    this.model["error-allocationValidator"] = "";
    this.model["error-podCode"] = "";
    this.model["error-bPolCode"] = "";
    if (this.isRetrieveByBkgNo) {
      if (!this.bkgNo) {
        this.isValidbkgNo = "Please input BKG No";
        return;
      } else {
        this.isValidbkgNo = "";
      }

      this.searchEventByBkgNo();
    } else {
      this.isError = this.onValidate(this.model);

      if (!this.model.bPolCode && !this.model.podCode && !this.isError) {
        this.model["error-bPolCode"] = "Please input POL";
        this.model["error-podCode"] = "Please input POD";
        this.isError = true;
      }
      if (!this.isError) {

        // GST-188  Missing Prompter for POD when Connecting Vessel Voy = Y
        if (this.model.bPolCode && !this.model.podCode && this.model.voyageConnecting && this.model.vesselIdConnecting) {
          this.model["error-podCode"] = "Please input POD.";
          this.isError = true;
          return;
        }

        if (this.model.bPolCode && this.model.podCode && !this.model.vesselIdConnecting && !this.model.vesselIdDischarging) {
          this.model["error-allocationValidator"] = "Please input at least 1 vessel/voyage.";
          this.isError = true;
          return;
        }
  
        if (this.model.bPolCode && !this.model.vesselIdDischarging && !this.model.vesselIdConnecting && !this.model.voyageConnecting) {
          this.model["error-allocationValidator"] = "Please input Discharging Vessel.";
          this.isError = true;
          return;
        }
        if (!this.model.voyageDischarging && this.model.vesselIdDischarging) {
          this.model["error-allocationValidator"] = "Please input Discharging Voyage.";
          this.isError = true;
          return;
        }
        if (!this.model.vesselIdConnecting && !this.model.vesselIdDischarging && !this.model.voyageDischarging && !this.model.bPolCode) {
          this.model["error-allocationValidator"] = "Please input Connecting Vessel.";
          this.isError = true;
          return;
        }
        if (!this.model.voyageConnecting && this.model.vesselIdConnecting && !this.model.bPolCode) {
          this.model["error-allocationValidator"] = "Please input Connecting Voyage.";
          this.isError = true;
          return;
        }
        this.searchEvent();
      }
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false);
    $("#dialog").modal("show");
  }

  searchEvent(selectedRows?) {
    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",search");
    this.disableToolbarButtons = disableToolbarButtons;
    this.exceptionBookingService
      .getExceptionBooking(this.model)
      .pipe(finalize(() => {
        const disableToolbarButtons = this.disableToolbarButtons.replace(',search','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.genericUtil.hideLoader();
      }))
      .subscribe(
        (res) => {
          if (res) {
            const data = res.json();
            this.handleAfterSearch(data, selectedRows);
          } else {
            this.message(
              "Information",
              "information",
              "Something went wrong",
              "okonly",
              { ok: "" }
            );
          }
        },
        () => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  searchEventByBkgNo() {
    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",search");
    this.disableToolbarButtons = disableToolbarButtons;
    this.exceptionBookingService
      .getExceptionBookingByBkgNo(this.bkgNo)
      .pipe(finalize(() => {
        const disableToolbarButtons = this.disableToolbarButtons.replace(',search','');
        this.disableToolbarButtons = disableToolbarButtons;
        this.genericUtil.hideLoader();
      }))
      .subscribe(
        (res) => {
          if (res) {
            const data = res.json();
            this.handleAfterSearch(data);
          } else {
            this.message(
              "Information",
              "information",
              "Something went wrong",
              "okonly",
              { ok: "" }
            );
            this.saveStage(this.model, [], 0,0,0,0,0,0);
          }
        },
        () => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
          this.saveStage(this.model, [], 0,0,0,0,0,0);
        }
      );
  }

  handleAfterSearch(data, selectedRows?) {
    if (data.status !== "ok") {
      this.message("Information", "information", data.message, "okonly", {
        ok: "",
      });
      this.rowData = [];
      this.rowDataGridPortPair = [];
      this.saveStage(this.model, [], 0,0,0,0,0,0);
      return;
    }
    this.disableToolbarButtons = "";
    this.cbPOT1.disableCombo = true;

    this.rowData = data.content.tsBkgProspectList ? data.content.tsBkgProspectList : [];
    this.rowDataGridPortPair = data.content.tsPortPairAllocations;

    this.model.serviceConnecting = data.content.tsProspectHeader.tsConnectingServiceCode;
    this.model.serviceDischarging = data.content.tsProspectHeader.tsDischargingServiceCode;

    this.model.tsConnectingEtaPod = data.content.tsProspectHeader.tsConnectingEtaPod;
    this.model.tsConnectingEtaPot = data.content.tsProspectHeader.tsConnectingEtaPot;
    this.model.tsConnectingEtdPod = data.content.tsProspectHeader.tsConnectingEtdPod;
    this.model.tsConnectingEtdPot = data.content.tsProspectHeader.tsConnectingEtdPot;
    this.model.tsDischargingEtaPol = data.content.tsProspectHeader.tsDischargingEtaPol;
    this.model.tsDischargingEtaPot = data.content.tsProspectHeader.tsDischargingEtaPot;
    this.model.tsDischargingEtdPol = data.content.tsProspectHeader.tsDischargingEtdPol;
    this.model.tsDischargingEtdPot = data.content.tsProspectHeader.tsDischargingEtdPot;

    this.gridApi.setPinnedBottomRowData([
      {
        tsStatus: 'Total Unit :',
        tsPol20: data.content.totalD20,
        tsPol40: data.content.totalD40,
        tsPol45: data.content.totalD45,
        tsPolH20: data.content.totalH20,
        tsPolH40: data.content.totalH40,
        tsPolH45: data.content.totalH45,
      }
    ]);
  
    this.saveStage(this.model, data.content.tsBkgProspectList ? data.content.tsBkgProspectList : [], data.content.totalD20, data.content.totalD40, data.content.totalD45, data.content.totalH20, data.content.totalH40, data.content.totalH45);
    
    setTimeout(() => {
      if(selectedRows != undefined){
        this.gridApi.forEachNode((node)=> {
          selectedRows.forEach(selectRow => {
            if(selectRow.id ===  node.data.id){
              node.setSelected(true);
            }  
          });
        })
        this.gridApi.redrawRows()
      }
    }, 200);
  
  }

  saveStage(model, gird, totalD20, totalD40, totalD45, totalH20, totalH40, totalH45){
    this.gridApi
    let paramData = {
      model: model,
      gird: gird,
      totalD20: totalD20,
      totalD40: totalD40,
      totalD45: totalD45,
      totalH20: totalH20,
      totalH40: totalH40,
      totalH45: totalH45,
    }
    this.transactionTsPortHomeService.setDataExceptionBooking(paramData);
  }


  changeEventStatus(event) {
    if (
      event.contOprCode != null &&
      event.contOprCode !== "" &&
      event.contOprCode !== undefined
    ) {
      this.model.status = event.contOprCode;
    } else {
      this.model.status = "";
    }
  }

  changeEventLoadPort(event) {
    if (event.locationCode != null) {
      this.model.bPolCode = event.locationCode;
      this.model.bPolName = event.locationName;
      this.cbDischargingVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findVesselListForProspectBkg/" +
          this.officeCode +
          "/" +
          event.locationCode +
          "/{query}"
      );
      this.model["error-bPolCode"] = "";
      this.model["error-podCode"] = "";
      if (this.model.bPolCode && this.model.bPot1Code) {
        this.isError = false;
      }
      this.cbDischargingVessel.setValue("");
      this.cbDischargingVoyage.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    } else {
      this.model.bPolCode = "";
      this.model.bPolName = "";
      this.cbDischargingVessel.setValue("");
      this.cbDischargingVoyage.setValue("");
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
      this.model.voyageDischarging = "";
    }
  }

  changeEventDischargePort(event) {
    if (event.locationCode != null) {
      this.model.podId = event.locationId;
      this.model.podCode = event.locationCode;
      this.model.podName = event.locationName;
      this.cbConnectingVessel.setValue("");
      this.cbConnectingVoyage.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
    } else {
      this.model.podCode = "";
      this.model.podName = "";
      this.cbConnectingVessel.setValue("");
      this.cbConnectingVoyage.setValue("");
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
      this.model.voyageConnecting = "";
    }
  }

  changeDischargingVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdDischarging = event.vesselId;
      this.model.vesselCodeDischarging = event.vesselCode;
      this.model.vesselNameDischarging = event.vesselName;
      this.cbDischargingVoyage.setValue("");
      this.cbDischargingVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findVoyagesListForProspectBkg/discharging/" + 
        this.officeCode + '/' + 
        this.model.vesselIdDischarging + "/" + 
        this.model.dischargingBound + "/voyage_like=" + "{query}" + "?portOfCall=" + 
        this.model.bPolCode + "&potCode=" + 
        this.model.bPot1Code
      );
    } else {
      this.model.vesselIdDischarging = "";
      this.model.vesselCodeDischarging = "";
      this.model.vesselNameDischarging = "";
    }
  }

  changeConnectingVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselIdConnecting = event.vesselId;
      this.model.vesselCodeConnecting = event.vesselCode;
      this.model.vesselNameConnecting = event.vesselName;
      this.cbConnectingVoyage.setValue("");
      this.cbConnectingVoyage.setUrl(
        this.configService.config.BASE_API.toString() + 
        "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
         this.officeCode + '/' + this.model.vesselIdConnecting + "/" +
          this.model.connectingBound + 
          "/voyage_like=" + "{query}" + "?portOfCall=" + 
        this.model.bPot1Code
      );
    } else {
      this.model.vesselIdConnecting = "";
      this.model.vesselCodeConnecting = "";
      this.model.vesselNameConnecting = "";
    }
  }

  changeDischargingVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageDischarging = event.voyage;
    } else {
      this.model.voyageDischarging = event.voyage;
    }
  }

  changeConnectingVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.voyageConnecting = event.voyage;
    } else {
      this.model.voyageConnecting = event.voyage;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onCellValueChanged(params) {
    this.rowIndexW = params.rowIndex;
    const colId = params.column.getId();

    let url;

    switch (colId) {
      case "tsPotRemarks":
        url = "/TsBookingProspect/saveField/tsPotRemarks";
        break;
      case "tsOutwardSlotOperator":
        url = "/TsBookingProspect/saveField/tsOutwardSlotOperator";
        break;
      case "tsReasonForRejection":
        url = "/TsBookingProspect/saveField/tsReasonForRejection";
        break;
      case "tsReasonForExceptionHandling":
        url = "/TsBookingProspect/saveField/tsReasonForExceptionHandling";
        break;
      default:
        break;
    }

    let body = {
      updatedContent: params.newValue,
      ids: [params.data.id],
    };
    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService
      .POST(urlReqest, body)
      .pipe(
        finalize(() => {
          this.genericUtil.hideLoader();
        })
      )
      .subscribe(
        (resp) => {
          if (resp.json()["status"] == "ok") {
            // this.searchEvent();
          } else {
            this.message("error", "Error", resp.json().message, "okonly", {
              ok: "",
            });
          }
        },
        (error) => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  rowDoubleClick(event) {}

  onSelectionChanged(event) {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.disableToolbarButtons = "";
    } else {
      this.disableToolbarButtons = "";
    }
  }

  confirmCancelClose(type) {
    this.labelConfirmCancelClose = type;
    let message =
      type === "cancel"
        ? "No data will be saved. Proceed to cancel?"
        : "No data will be saved. Proceed to close?";
    this.message("delete", "Confirm", message, "yesno", {
      yes: "this.confirmYes()",
      no: "",
    });
  }

  confirmYes() {
    if (this.labelConfirmCancelClose === "cancel") {
      this.handleCancel();
    } else {
      this.router.navigateByUrl("/main/home");
    }
  }

  handleCancel() {
    this.cbConnectingVessel.setValue("");
    this.cbDischargingVessel.setValue("");
    this.cbConnectingVoyage.setValue("");
    this.cbDischargingVoyage.setValue("");
    this.cbPOT1.setValue("SGSIN");
    this.cbLoadPort.setValue("");
    this.cbDischargePort.setValue("");
    this.bkgNo = "";
    this.isExceptionPage = "EXCEPTION";
    this.cbConnectingVessel.disableCombo = false;
    this.cbDischargingVessel.disableCombo = false;
    this.cbConnectingVoyage.disableCombo = false;
    this.cbDischargingVoyage.disableCombo = false;
    this.isRetrieveByBkgNo = false;
    this.model = new ExceptionModel();
    this.invisibleToolbarButtons = "insert";
    this.disableToolbarButtons = "save";
    this.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    this.model.officeCode = this.officeCode;
    this.rowData = [];
    this.rowDataGridPortPair = [];
    this.saveStage(this.model, [], 0,0,0,0,0,0);
  }

  onGridReadyGridPortPair(params) {
    this.topGridPortPair = params.api;
    this.topGridPortPair.hideOverlay();
  }

  rowDoubleClickGridPortPair(event) {}

  onSelectionChangedGridPortPair(event) {}

  defaultForm() {
    // reset form/page to default
  }

  handlePotRemarks() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.dialogFrom = "POTREMARKS";
      this.noteContent = "Note: This remark will apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogConent", "POT Remarks", 500, 270);
      this.maxlengthDialog = 320;
    } else {
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert POT remarks',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleOutwardSlot() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.dialogFrom = "OUTWARDSLOT";
      this.noteContent = "Note: This Outward Slot apply to ALL SELECTED records.";
      this.genericUtil.showDialog("DialogConent", "Outward Slot", 500, 270);
      this.maxlengthDialog = 4;
    } else {
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to change Outward Slot.',
        "okonly",
        { ok: "" }
      );
    }
  }

  handleReason() {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.dialogFrom = "REASON";
      this.noteContent = "Note: This reason will apply to ALL SELECTED records.";
      this.genericUtil.showDialog(
        "DialogConent",
        "Reason for Exception Handling",
        500,
        270
      );
      this.maxlengthDialog = 320;
    } else {
      this.message(
        "Information",
        "Information",
        'Please select at least 1 record to insert reason.',
        "okonly",
        { ok: "" }
      );
    }
  }

  valueDialogChange(event) {
    if (event) {
      this.messageValidateDialog = "";
    } else {
      switch (this.dialogFrom) {
        case "POTREMARKS":
          this.messageValidateDialog = "Please input remarks";
          break;
        case "OUTWARDSLOT":
          this.messageValidateDialog = "Please input Outward Slot";
          break;
        // case "REASON":
        //   this.messageValidateDialog = "Please input reason";
        //   break;
        default:
          break;
      }
    }
  }

  cancelDialog() {
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  closeDialog() {
    this.genericUtil.closeDialog("DialogConent");
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  saveDialog() {
    if (!this.valueDialog) {
      switch (this.dialogFrom) {
        case "POTREMARKS":
          this.messageValidateDialog = "Please input remarks";
          return;
        case "OUTWARDSLOT":
          this.messageValidateDialog = "Please input Outward Slot";
          return;
        // case "REASON":
        //   this.messageValidateDialog = "Please input reason";
        //   break;
        default:
          break;
      }
    }
    this.messageValidateDialog = "";

    let url = "";
    switch (this.dialogFrom) {
      case "POTREMARKS":
        url = "/TsExceptionBooking/saveField/tsPotRemarks";
        break;
      case "OUTWARDSLOT":
        url = "/TsExceptionBooking/saveField/tsOutwardSlotOperator";
        break;
      case "REASON":
        url = "/TsExceptionBooking/saveField/tsReasonForExceptionHandling";
        break;
      default:
        break;
    }
    const selectedRows = this.gridApi.getSelectedRows();
    console.log(
      "IDs",
      selectedRows.map((item) => item.id)
    );
    let body = {
      updatedContent: this.valueDialog,
      ids: selectedRows.map((item) => item.id),
    };

    let urlReqest = this.configService.config.BASE_API.toString() + url;
    this.genericUtil.showLoader();
    this.genericService
      .POST(urlReqest, body)
      .pipe(
        finalize(() => {
          this.genericUtil.hideLoader();
        })
      )
      .subscribe(
        (resp) => {
          if (resp.json()["status"] == "ok") {
            this.closeDialogRemarksOutReason();
            if (this.isRetrieveByBkgNo) {
              this.searchEventByBkgNo();
            } else {
              this.searchEvent(selectedRows);
            }
          } else {
            this.message("error", "Error", resp.json().message, "okonly", {
              ok: "",
            });
          }
        },
        (error) => {
          this.message(
            "error",
            "Error",
            "An error has occurred. Please report to Administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  closeDialogRemarksOutReason() {
    this.genericUtil.closeDialog("DialogConent");
    this.valueDialog = "";
    this.messageValidateDialog = "";
  }

  comfirmHandleExceptionHandling() {
    const selectedRows = this.gridApi.getSelectedRows();
    if(selectedRows.length > 0 && selectedRows[0].isChild === "Y"){
      return this.message(
        "Information",
        "Information",
        'A child of a split record can not be split.',
        "okonly",
        { ok: "" }
      );
    }

    if (selectedRows.length < 1 || selectedRows.length > 1) {
      return this.message(
        "Information",
        "Information",
        'Please select only 1 record for Exception Handling.',
        "okonly",
        { ok: "" }
      );
    }

    if (!selectedRows[0].tsReasonForExceptionHandling) {
      return this.message(
        "Information",
        "Information",
        'Please input reason for Exception Handling.',
        "okonly",
        { ok: "" }
      );
    }
    

    this.message(
      "save",
      "Confirm",
      "Proceed Exception Handling for selected record?",
      "yesno",
      { yes: "this.handleExceptionHandling()", no: "" }
    );
  }

  handleExceptionHandling(){
    const selectedRows = this.gridApi.getSelectedRows();
    // let widthWindow = window.innerWidth;
    // let widthDialog = 1550; //From Full HD and above
    // // HD+	1600x900
    // if (widthWindow < 1700) {
    //   widthDialog = 1200;
    // }
    // // HD 1366x768
    // if (widthWindow < 1400) {
    //   widthDialog = 900;
    // }
    // if (widthWindow < 1000) {
    //   widthDialog = 600;
    // }
    this.model.tsPolBookingNo = selectedRows[0].tsPolBookingNo;
    this.model.tsPotBookingNo = selectedRows[0].tsPotBookingNo;
    this.exceptionBookingService.setModel(this.model);
    this.isExceptionPage = "ExceptionHandling";
  }

  backToExceptionpage(event) {
    this.isExceptionPage = "EXCEPTION";
    this.model.bPot1Code = "SGSIN";
    this.cbPOT1.setValue("SGSIN");
    if(event === "CLOSE_SAVE"){
      this.handleSearch();
    }
  }

  handleExport() {
    this.genericUtil.showLoader();
    let vesselName = "";
    let voyageName = "";

    if(this.model.vesselNameConnecting){
      vesselName = this.model.vesselNameConnecting;
      voyageName = this.model.voyageConnecting;
    }

    if(this.model.vesselNameDischarging || (this.model.vesselNameDischarging && this.model.vesselNameConnecting)){
      vesselName = this.model.vesselNameDischarging;
      voyageName = this.model.voyageDischarging;
    }
    var formatName = `${vesselName} V.${voyageName} Exception Handling.xls`;
    var uri =
      this.configService.config.BASE_API.toString() +
      "?q=" + `/Mreport/exportTranshipmentReport?pot=${this.model.bPot1Code}&status=${this.model.status}&pol=${this.model.bPolCode}&pod=${this.model.podCode}&dischargingVesselId=${this.model.vesselIdDischarging}&dischargingVoyage=${this.model.voyageDischarging}&dischargingVesselBound=${this.model.dischargingBound}&connectingVesselId=${this.model.vesselIdConnecting}&connectingVoyage=${this.model.voyageConnecting}&connectingVesselBound=${this.model.connectingBound}&dischargingServiceCode=${this.model.serviceDischarging}&connectingServiceCode=${this.model.serviceConnecting}&reportName=&reportType=Excep`
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function (request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
        self.genericUtil.hideLoader();
      },
    });
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  emitMessageDialog(event){
    this.message(
      "information",
      "Information",
      event,
      "okonly",
      { ok: "" }
    );
  }

  emitConfirmAction(type){
    if(type === "cancel"){
      this.message("delete", "Confirm", "No data will be saved. Proceed to cancel?", "yesno", {
        yes: "this.agreeCancleExceptionHandling()",
        no: "",
      });
    }
    if(type === "close"){
      this.message("delete", "Confirm", "No data will be saved. Proceed to close?", "yesno", {
        yes: "this.agreeCloseExceptionHandling()",
        no: "",
      });
    }
  }

  agreeCancleExceptionHandling(){
    this.exceptionHandling && this.exceptionHandling.handleCancel();
  }

  agreeCloseExceptionHandling(){
    this.exceptionHandling && this.exceptionHandling.handleClose();
  }

  ngOnDestroy() {
    console.log("---------ngOnDestroy Exception page");
  }
}
