export class dgClass  {
    
    no : number = 0;
    dgClassId  : string = '';
    psaGroup  : string = '';
    bkgClass : string = '';
    isValid        : boolean = false;
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid : string = '';

    constructor() {

    }


}