import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Authorize, Validator, ComboPluginComponent, GenericService,
  GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { officeModel } from '../shared/index';
import { Router } from '@angular/router';
declare  var $: any;


export class Parameter {
  vesselId = '';
  vesselName = '';
  voyage = '';
  bound = 'O';
  cOwner = 'ALL';

  constructor() { }
}


@Component({
  selector: 'app-report-matching-cll-bl-page',
  templateUrl: './report-matching-cll-bl-page.component.html',
  styleUrls: ['./report-matching-cll-bl-page.component.css']
})
export class ReportMatchingCllBlPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;

  @ViewChild('rSoc') radioSoc: ElementRef;
  @ViewChild('rCoc') radioCoc: ElementRef;
  @ViewChild('rSlot') radioSlot: ElementRef;
  @ViewChild('rAll') radioAll: ElementRef;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';
  autoCreationResultMesssage = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPDF;


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new Parameter();
  office = new officeModel;
  userId = '';
  postData: any = {};
  hitUrl: string = this.configService.config.BASE_API.toString();

  validatorRules = {};


  constructor(private auth: Authorize,
              private genericService: GenericService,
              private genericUtil: GenericUtil,
              private router: Router,
              private configService: ConfigService,
              private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Generate', event: 'generate', icon: 'download'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Matching of CLL and BL'
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    };

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : true,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit() {
    this.office.officeCode = this.cookieService.getDefaultLocationCode();
    this.office.officeId = this.cookieService.getCookie('defaultLocation').split('|')[3];
    this.office.officeName = this.cookieService.getCookie('defaultLocation').split('|')[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  toolbarEvent(event) {
    switch (event) {
      case 'generate' :
        if (this.model.vesselId !== '' && this.model.voyage !== '' && this.model.cOwner !== '') {
          this.postData = {};
          this.postData['officeCode'] = this.office.officeCode;
          this.postData['userId'] = this.userId;
          this.postData['dateCreated'] = this.genericUtil.getPSIDate();
          this.postData['vesselId'] = this.model.vesselId;
          this.postData['voyage'] = this.model.voyage;
          this.postData['cOwnerShip'] = this.model.cOwner;
          this.postData['bound'] = 'O';
          this.loading = true;
          this.printMatchingCllBlDiscrepancies();
        } else {
          this.message('information', 'Information', 'please insert vessel & voyage', 'okonly', {ok: ''});
        }
        break;
      case 'cancel' :
        this.model = new Parameter;
        this.cbVessel.setForceValue('');
        this.cbVoyage.setForceValue('');
        this.radioAll.nativeElement.checked = '';
        this.radioSoc.nativeElement.checked = 'checked';
        this.radioCoc.nativeElement.checked = '';
        this.radioSlot.nativeElement.checked = '';
        this.autoCreationResultMesssage = '';
        this.disableToolbarButtons = 'print';
        this.postData = {};

        break;
      case 'close' :
        // handle close event
        this.autoCreationResultMesssage = '';
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = '';
    }
  }

  radioEvent(check: boolean, type: string) {
    if (check) {
      this.model.cOwner = type;
    }
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event === 'close-pdf') {
      this.disableToolbarButtons = 'print';
    }
  }

  printMatchingCllBlDiscrepancies() {
    if (this.model.vesselId !== '') {
      this.loading = true;
      const hitUrl = this.configService.config.BASE_API.toString()  + '/edi-util/getFile';
      var jasperURL = '';

      jasperURL =  this.configService.config.jasperUrl.toString() + '/jasperserver/rest_v2/reports/reports/GLOSSYS/THBKK/MatchingCLLToBL.pdf?officeCode='+this.postData['officeCode']+'&vesselId='+this.postData['vesselId']+'&voyage='+this.postData['voyage']+'&bound='+this.postData['bound']+'&containerOwnership='+this.postData['cOwnerShip'];


      const fileName = 'MatchingCllBl.pdf';

      const postData = {};
      postData['fileName'] = fileName;
      postData['url'] = jasperURL;

      this.genericService.POST(hitUrl, postData).subscribe((resp) => {
        if (resp.ok) {
          const result = resp.json();

          if(result['message'] === 'success') {
            this.pdfContainer.setPDFURL('http://glossys.samudera.com' + result['filePath']);
            this.pdfContainer.showPDF();
          }

          this.loading = false;
        }
      });
    } else {
      console.log('No data.');
    }

  }
}
