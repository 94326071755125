import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  TextareaPluginComponent,
  TextareaV2PluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";
import { Reason } from "../shared/models/reason";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as FileSaver from "file-saver";

declare var $: any;

@Component({
  selector: "app-master-reason-page",
  templateUrl: "./master-reason-page.component.html",
  styleUrls: ["./master-reason-page.component.css"],
})
export class MasterReasonPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbReasonNameHistory") cbReasonNameHistory: ComboPluginComponent;
  //@ViewChild("txtReasonName") txtReasonName: TextareaPluginComponent;
  @ViewChild("txtReasonName") txtReasonName: TextareaV2PluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = true;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // * Form Validation
  formValidation = new FormValidation();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingReasonLogStartDate;
  //settingReasonLogEndDate;
  settingReasonHistory;
  settingReasonName;
  settingReasonsName;
  settingReasonsNameHistory;

  /* Misc Parameters */
  valReasonName: string = "";
  valReasonId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  officeCode: string = "";

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  typeReasonLogStartDate = "calendar";
  typeReasonLogEndDate = "calendar";

  hitUrl: string = this.configService.config.BASE_API.toString();

  /* Parameter model */
  model = new Reason();
  //selectList = [];

  loading = false;

  lock = false;

  reasonLock: Boolean = true;

  /* validatorRules */
  validatorRules = {
    reasonName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Reason Name.",
        },
        {
          // type: "modifiedPattern",
          // pattern: /^[a-zA-Z0-9-(.)/,&' ]*$/,
          // prompt: "Invalid Reason Name, only allow character -()/,.&',",
          type: "modifiedPattern",
          pattern: this.formValidation.regAlphaNumericWSpaceEnter,
          prompt: "Only accept alphanumeric with spaces",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAllChar,
          prompt: "Invalid Remarks",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Reason Name", value: "reasonName" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      //url: this.configService.config.BASE_API.toString() + '/MasterReason/findGeneral',
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {
          header: "Reason Name",
          field: "reasonName",
          width: 200,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 200 },
        { header: "Date Created", field: "dateCreated", width: 200 },
        { header: "User Modified", field: "userModified", width: 200 },
        { header: "Date Modified", field: "dateModified", width: 200 },
        { header: "User Invalid", field: "userInvalid", width: 200 },
        { header: "Date Invalid", field: "dateInvalid", width: 200 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "reasonName",
      sortingDirection: "ASC",
      auto: false
    };

    this.settingGridHistory = {
      id: "reasonHistory",
      url:
        this.configService.config.BASE_API.toString() + "/MasterReason/findGeneralHistoryById",
         ///"http://localhost:10000/MasterReason/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Reason Id", field: "reasonId", width: 100 },
        {
          header: "Reason Name",
          field: "reasonName",
          width: 250,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 150 },
        { header: "Date Created", field: "dateCreated", width: 150 },
        { header: "User Modified", field: "userModified", width: 150 },
        { header: "Date Modified", field: "dateModified", width: 150 },
        { header: "User Invalid", field: "userInvalid", width: 150 },
        { header: "Date Invalid", field: "dateInvalid", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
        { header: "Action Log", field: "action", width: 150 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enablePagination: true,
      auto:false
    };

    this.settingReasonHistory = {
      id: "cbReasonHistory",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterReason/findGeneralHistory/{query}',
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "reasonName",
      description: "",
      isMark: true,
      columns: [
        { header: "Reason Id", field: "reasonId", width: 100 },
        {
          header: "Reason Name",
          field: "reasonName",
          width: 230,
          type: "string",
        },
        { header: "Remarks", field: "remarks", width: 150, type: "string" },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    /*this.settingReasonName = {
      id: "txtReasonName",
      name: "reasonName",
      placeholder: "Reason Name",
      cols: 30,
      rows: 10,
      maxlength: 255,
      border: 400,
    };*/

    this.settingReasonsName = {
      id: "txtReasonsName",
      name: "reasonsName",
      placeholder: "Reason Name",
      cols: 30,
      rows: 10,
      border: "30,200",
    };

    this.settingReasonsNameHistory = {
      id: "txtReasonsNameHistory",
      name: "reasonsNameHistory",
      placeholder: "Reason Name",
      cols: 30,
      rows: 10,
      border: "30,200",
    };

    /*
     this.settingReasonLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingReasonLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search=this.officeCode+'/*/*/*';
    this.grid.url = this.configService.config.BASE_API.toString() + "/MasterReason/findGeneral/" +this.officeCode;
    this.grid.loadData();

    this.cbReasonNameHistory.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReason/findGeneralHistory/" +
        this.officeCode +
        "/{query}"
    );
    //this.grid.onFirst();

    this.modeForm = false;

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  onInput(event) {
    this.model["error-reasonName"] = null;
    //this.txtReasonName.error = false;
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      // case 'afterLoad':
      //   this.grid.url = this.configService.config.BASE_API.toString() + '/MasterReason/findGeneral';
      //   break;
      break;
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            // this.txtReasonName.disabled = true;
              this.reasonLock = true;
              //this.txtReasonName.error = false;
            }
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.reasonId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          //this.grid.search = this.officeCode+'/'+str.replace("search:","");
          this.grid.search = str.replace("search:", "");

          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    //this.txtReasonName.disabled = false;
    this.reasonLock = false;
    //this.txtReasonName.error = false;
    this.txtReasonName.setValue("");
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Reason();
    //this.model.reasonName = '12345678901234567890123456789012\r34567890123456789012345678901234\r567\r\r234 345234 234123 12312 121 123 \r1231';
    this.valReasonName = "";
  }

  handleUpdate() {
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      // handle update event
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      //this.txtReasonName.disabled = false;
      this.reasonLock = false;
      //this.txtReasonName.error = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event

    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"reasonId":"","reasonCode":""};
    //   deleteObj.reasonId = element.reasonId;
    //   deleteObj.reasonCode = element.reasonCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBooking/checkingListExchangeRateUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );

    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  handlePrint() {
    var formatName = "report-Reason-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/66/Reason-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // // window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/11/Reason-Export-Reports/*');
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +"/Mreport/runReport/66/Reason-Export-Reports/*"
    // );
    // handle print event
  }

  /*
  insert(index:number, char:string, data:string) {
    var idx:number = index;
    if (idx > 0) {
      return data.substring(0, idx) + char + data.substring(idx, data.length);
    } else {
      return char + data;
    }
  };
  */

  handleSave() {
    this.model.reasonName = this.txtReasonName.getValue();
    //console.log(this.model);
    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log(this.model['error-reasonName']);
    if (this.model["error-reasonName"] != "") {
      //this.txtReasonName.error = true;
    } else {
      //this.txtReasonName.error = false;
    }
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new Reason();
    //this.txtReasonName.error = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    // if(this.valReasonId != '') {
     this.valReasonId = "";

    this.cbReasonNameHistory.setValue("");
    this.cbReasonNameHistory.close();

    this.txtReasonName.setValue("");
    this.gridHistory.onHistory("NaN");
    this.gridHistory.loadData();
    // }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.url =
      this.configService.config.BASE_API.toString() +
      "/MasterReason/findGeneral/" +
      this.officeCode;
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new Reason();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.reasonId = String(data.reasonId).trim().toUpperCase();
    this.model.reasonName = String(data.reasonName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.txtReasonName.setValue(this.model.reasonName);
    this.model.remarks = String(data.remarks)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.officeCode = this.officeCode;
  }

  valueReasonHistory(event) {
    this.valReasonId = event["reasonId"];
    this.gridHistory.onHistory(this.valReasonId);
  }

  generateReasonHistoryReport(event: any) {
    //this.valHistoryStartDate= $('#logHistoryReasontartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryReasonEndDate').find('#datePicker').val();
    // var flagCheck = 0;
    // this.gridHistory.listStore.store.forEach(pla=>{

    //   if(pla.select == 'checked'){
    //     flagCheck = 1;
    //   }
    // })

    const gridHistory = this.gridHistory.listStore.store;
    console.log(gridHistory.length)
    if (gridHistory.length === 0) {
      this.message(
        "warning",
        "Warning",
        "No records to export.",
        "okonly",
        { ok: "" }
      );
    } else {
      // window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/12/History-Reason-' + this.valReasonId  + '/'+this.valReasonId);
      var path =
        "67/History-Reason-" +
        this.valReasonId +
        "/" +
        this.valReasonId;
      var filename = "History-Reason-" + this.valReasonId;
      this.downloadFileFromServer(path, filename, "xls", true);
    }
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    this.model.officeCode = this.officeCode;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterReason/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new Reason();
            this.txtReasonName.setValue("");
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.reasonId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"reasonId":[]}
    paramsDelete.reasonId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        // "/MasterReason/delete",
        // deleteList
        "/MasterReason/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            const resBody = JSON.parse(resp._body);
            if (resBody.status === 'nok') {
              this.message(
                "information",
                "Information",
                resBody.message,
                "okonly",
                { ok: "this.loading=false;" }
              );
              return;
            }

            this.model = new Reason();
            this.txtReasonName.setValue("");
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  onPasteReasonName(event: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    // const regexPattern = /^[a-zA-Z0-9-()/,.&' ]*$/;
    const regexPattern = /^[a-zA-Z0-9 ]*$/;

    if (regexPattern.test(pastedText) == false) {
      event.preventDefault();
    }
  }

  downloadFileFromServer(
    path: string,
    fileName: string,
    fileExt: string,
    loading: boolean
  ) {
    loading = true;
    var dwnldUrl = this.hitUrl + "?q=/Mreport/runReport/" + path;
    this._http
      .get(dwnldUrl, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          var docName = fileName + "." + fileExt;
          FileSaver.saveAs(res, docName);
          loading = false;
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          loading = false;
        }
      );
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
