import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Booking } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import {HttpClient}  from '@angular/common/http';

declare  var $:any;

export class Param {
  bOfficeCode: string='';
  bOceanVesselId: string='';
  bOceanVesselCode: string='';
  bOceanVesselVoyage: string='';
  bOceanVesselBound: string = '';
  bPot1Code: string='';
  //bPot1TerminalCode : string='';
  bPolCode: string='';
  bPodCode: string='';
  bFDestCode: string='';
  bContainerOwnership : string='';
  bTsbooking: string = '';
  bContainerStatus : string = '';
  direction: string = '';
  selected: string = '';

  allocationValidator:Boolean = false;

  constructor() {

  }
}

export class GridTotal{
  blIndicator = '';
  bStatus = '';
  bIsFinished = '';
  csp = '';
  bFreightPartyName = 'T O T A L';
  bFullNumber = '';
  bPorName = '';
  bPolName = '';
  bPodName = '';
  bPodlName = '';
  bFdestName = '';
  d20 = 0; h20 = 0; d40 = 0; h40 = 0; d45 = 0; h45 = 0;
  r20 = 0; rh20 = 0; r40 = 0; rh40 = 0; r45 = 0; rh45 = 0;
  t20 = 0; t40 = 0; t45 = 0;

  mt20 = 0; mth20 = 0; mt40 = 0; mth40 = 0; mt45 = 0; mth45 = 0;
  mtr20 = 0; mtrh20 = 0; mtr40 = 0; mtrh40 = 0; mtr45 = 0; mtrh45 = 0;
  mtt20 = 0; mtt40 = 0; mtt45 = 0;

  killslot = 0;

  constructor() {}
}

@Component({
  selector: 'app-transaction-booking-orphanage',
  templateUrl: './transaction-booking-orphanage-page.component.html',
  styleUrls: ['./transaction-booking-orphanage-page.component.css']
})
export class TransactionBookingOrphanagePageComponent extends Validator  implements OnInit, AfterViewInit {
  topOptions = {alignedGrids: []};
  bottomOptions = {alignedGrids: []};

  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbLocalTranshipment') cbLocalTranshipment:ComboPluginComponent;
  @ViewChild('cbFullEmpty') cbFullEmpty:ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD:ComboPluginComponent;
  @ViewChild('cbFDest') cbFDest:ComboPluginComponent;
  @ViewChild('cbPOT1') cbPOT1:ComboPluginComponent;
  @ViewChild('cbPOL') cbPOL:ComboPluginComponent;
  @ViewChild('grid') grid:GridPluginComponent;

  @ViewChild('topGrid') topGrid;
  @ViewChild('bottomGrid') bottomGrid;


  /* Parameter for information into Footer */
  info = '';

  columnDefs;
  gridApi;
  rowData: any;
  rowData2 = [];
  pinnedBottomRowData;
  getRowStyle;


  overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';

  loading = false;

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLocalTranshipment;
  settingFullEmpty;
  settingPOD;
  settingFDest;
  settingPOT1;
  settingPOL;
  settingGrid;

  isLoad : boolean = false;

  validatorRules = {
    bOceanVesselBound: {
      rules: [{
        type: 'empty',
        prompt: 'Please input bound.'
      }]
    },
    bOceanVesselCode: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Vessel.'
      }]
    },
    bOceanVesselVoyage: {
      rules:[{
        type  : 'empty',
        prompt: 'Please input Voyage.'
      }]
    },
    allocationValidator: {
      rules:[{
        type  : 'custom',
        prompt: 'Allocation has not been set for this vessel, unable to create booking.'
      }]
    },

  }

  param = new Param();
  isError: Boolean = false;
  isCOC:Boolean = true;
  isSOC:Boolean = true;
  isSLOT:Boolean = true;
  isNVOCC:Boolean = false;

  tsBooking:string = 'All';
  // model = new MasterBooking();

  total_soc_allocated:number = 0;
  total_soc_ts:number = 0;
  total_soc_local: number = 0;
  total_soc_laden_booked: number = 0;
  total_soc_empty_booked: number = 0;
  total_soc_available: number = 0;
  total_coc_allocated: number = 0;
  total_coc_ts: number = 0;
  total_coc_local: number = 0;
  total_coc_laden_booked: number = 0;
  total_coc_empty_booked: number = 0;
  total_coc_available: number = 0;
  total_slot_allocated: number = 0;
  total_slot_booked: number = 0;
  total_slot_available: number = 0;
  total_slot_laden_booked: number = 0;
  total_slot_empty_booked: number = 0;
  total_vsl_teus: number = 0;
  total_laden: number = 0;
  total_empty: number = 0;
  total_resv_laden: number = 0;
  total_resv_empty:number = 0;
  total_resv_killslot:number =0;
  total_resv:number = 0;

  total_soc_killslot:number=0;
  total_coc_killslot:number=0;
  total_slot_killslot:number=0;

  total_laden_reserve_coc:number=0;
  total_empty_reserve_coc:number=0;
  total_laden_reserve_soc:number=0;
  total_empty_reserve_soc:number=0;
  total_laden_reserve_slot:number=0;
  total_empty_reserve_slot:number=0;
  total_reserve:number=0;
  totalUC: Number = 0;


  eta: string = '';
  etd: string = '';
  ata: string = '';
  atd: string = '';

  fullBookingNo:string='';

  constructor(private cdr: ChangeDetectorRef, private router: Router, private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private configService:ConfigService, private cookieService:CookieService, private http: HttpClient) {
    super();

    this.columnDefs = [
      {headerName: 'BLS', field: 'blIndicator',
      width: 80,
      pinnedRowCellRenderer: "customPinnedRowRenderer",
      pinnedRowCellRendererParams: { style: { color: "blue" } }},
      {headerName: 'S', field: 'bStatus', width: 80 },
      {headerName: 'F', field: 'bIsFinished', width: 80 },
      {headerName: 'C/S/P', field: 'csp', width: 100 },
      {headerName: 'Freight Party', field: 'bFreightPartyName' },
      {headerName: 'Booking Ref', field: 'bFullNumber' },
      {headerName: 'Shipper', field: 'bShipperName' },
      {headerName: 'POR', field: 'bPorName' },
      {headerName: 'POL', field: 'bPolName' },
      {headerName: 'POD', field: 'bPodName' },
      {headerName: 'PODL', field: 'bPod1Name' },
      {headerName: 'POT1', field: 'bPot1Name' },
      {headerName: 'FDEST', field: 'bFdestName' },
      {headerName: '20', field: 'd20', width: 80 },
      {headerName: 'H20', field: 'h20', width: 80 },
      {headerName: '40', field: 'd40', width: 80 },
      {headerName: 'H40', field: 'h40', width: 80 },
      {headerName: '45', field: 'd45', width: 80 },
      {headerName: 'H45', field: 'h45', width: 80 },
      {headerName: 'R20', field: 'r20', width: 80 },
      {headerName: 'RH20', field: 'rh20', width: 80 },
      {headerName: '40', field: 'r40', width: 80 },
      {headerName: 'RH40', field: 'rh40', width: 80 },
      {headerName: 'R45', field: 'r45', width: 80 },
      {headerName: 'RH45', field: 'rh45', width: 80 },
      {headerName: 'TK20', field: 't20', width: 80 },
      {headerName: 'TK40', field: 't40', width: 80 },
      {headerName: 'TK45', field: 't45', width: 80 },

      {headerName: 'MT 20', field: 'mt20', width: 80 },
      {headerName: 'MT H20', field: 'mth20', width: 80 },
      {headerName: 'MT 40', field: 'mt40', width: 80 },
      {headerName: 'MT H40', field: 'mth40', width: 80 },
      {headerName: 'MT 45', field: 'mt45', width: 80 },
      {headerName: 'MT H45', field: 'mth45', width: 80 },
      {headerName: 'MT R20', field: 'mtr20', width: 80 },
      {headerName: 'MT RH20', field: 'mtrh20', width: 80 },
      {headerName: 'MT R40', field: 'mtr40', width: 80 },
      {headerName: 'MT RH40', field: 'mtrh40', width: 80 },
      {headerName: 'MT R45', field: 'mtr45', width: 80 },
      {headerName: 'MT RH45', field: 'mtrh45', width: 80 },
      {headerName: 'MT TK20', field: 'mtt20', width: 80 },
      {headerName: 'MT TK40', field: 'mtt40', width: 80 },
      {headerName: 'MT TK45', field: 'mtt45', width: 80 },

      {headerName: 'Kill Slot', field: 'killslot' },
      {headerName: 'Mother Vessel', field: 'motherVessel' },
      {headerName: 'Mother Voyage', field: 'motherVoyage' },
      {headerName: 'Remarks', field: 'remarks' },
      {headerName: 'Created By', field: 'userCreated' },
      {headerName: 'Created Date', field: 'dateCreated' },
      {headerName: 'Modified By', field: 'userModified' },
      {headerName: 'Modified Date', field: 'dateModified' },
      {headerName: 'Deleted By', field: 'userDeleted' },
      {headerName: 'Deleted Date', field: 'dateDeleted' },
      {headerName: 'To PSA', field: 'toPSA' },
      {headerName: 'CTC PAX', field: 'contactPerson' },


      {headerName: 'DG Class', field: 'bDgClass' },
      {headerName: 'R/Type Container', field: 'bReceiptTypeExtra' },
      {headerName: 'Payment Term', field: 'bFreightType' },
      {headerName: 'Approved By', field: 'a' },
      {headerName: 'Approved Date', field: 'a' },


    ];
    this.getRowStyle = function(params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
    };
    this.pinnedBottomRowData = this.rowData2;

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'New Booking',  event: 'new', icon : 'plus' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Close',  event: 'close', icon : 'remove circle' },
        { name : 'Export',  event: 'export', icon : 'file' },

      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType : 'label',
      label : 'Booking Flag Status'
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 175},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }


    this.settingLocalTranshipment = {
      id          : 'cbLocalTranshipment',
      type        : 'select', // search | select | select input
      url         : 'assets/json/localTranshipment.json',
      placeholder : 'All/Local/Transhipment',
      code        : 'localTranshipmentCode',
      title       : 'localTranshipmentName'
    }

    this.settingFullEmpty = {
      id          : 'cbFullEmpty',
      type        : 'select', // search | select | select input
      url         : 'assets/json/fullEmpty.json',
      placeholder : 'All/Full/Empty',
      code        : 'fullEmptyCode',
      title       : 'fullEmptyName'
    }

    this.settingPOD = {
      id          : 'cbPOD',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOD/{query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 175},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }

    this.settingFDest = {
      id          : 'cbPODTerminal',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterBookings/searchFDest/{query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      align       : 'right',
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 175},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }

    this.settingPOT1 = {
      id          : 'cbPOT1',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOT1/{query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 175},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }

    this.settingPOL = {
      id          : 'cbPOL',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOL/{query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 175},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }

    this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + "/MasterBookings/searchFlagStatus",
      page: 10,
      columns: [
        /*{header: 'No', field: 'no', width: 50},*/
        {header: 'BLS', field: 'blIndicator', width: 50},
        {header: 'S', field: 'bStatus', width: 50},
        {header: 'F', field: 'bIsFinished', width: 50},
        {header: 'C/S/P', field: 'csp', width: 50},
        {header: 'Freight Party', field: 'bFreightPartyName', width: 100, type:'string'},
        {header: 'Booking Ref', field: 'bFullNumber', width: 100},
        {header: 'Shipper', field: 'bShipperName', width: 100, type:'string'},
        {header: 'POR', field: 'bPorName', width: 100, type:'string'},
        {header: 'POL', field: 'bPolName', width: 100, type:'string'},
        {header: 'POD', field: 'bPodName', width: 100, type:'string'},
        {header: 'PODL', field: 'bPodlName', width: 100, type:'string'},
        {header: 'POT1', field: 'bPot1Name', width: 100, type:'string'},
        {header: 'FDEST', field: 'bFdestName', width: 100, type:'string'},
        {header: '20', field: 'd20', width: 50, bold: 'true'},
        {header: 'H20', field: 'h20', width: 50, bold: 'true'},
        {header: '40', field: 'd40', width: 50, bold: 'true'},
        {header: 'H40', field: 'h40', width: 50, bold: 'true'},
        {header: '45', field: 'd45', width: 50, bold: 'true'},
        {header: 'H45', field: 'h45', width: 50, bold: 'true'},
        {header: 'R20', field: 'r20', width: 50, bold: 'true'},
        {header: 'RH20', field: 'rh20', width: 50, bold: 'true'},
        {header: 'R40', field: 'r40', width: 50, bold: 'true'},
        {header: 'RH40', field: 'rh40', width: 50, bold: 'true'},
        {header: 'R45', field: 'r45', width: 50, bold: 'true'},
        {header: 'RH45', field: 'rh45', width: 50, bold: 'true'},
        {header: 'TK20', field: 't20', width: 50, bold: 'true'},
        {header: 'TK40', field: 't40', width: 50, bold: 'true'},
        {header: 'TK45', field: 't45', width: 50, bold: 'true'},

        {header: 'MT 20', field: 'mt20', width: 50, bold: 'true'},
        {header: 'MT H20', field: 'mth20', width: 50, bold: 'true'},
        {header: 'MT 40', field: 'mt40', width: 50, bold: 'true'},
        {header: 'MT H40', field: 'mth40', width: 50, bold: 'true'},
        {header: 'MT 45', field: 'mt45', width: 50, bold: 'true'},
        {header: 'MT H45', field: 'mth45', width: 50, bold: 'true'},
        {header: 'MT R20', field: 'mtr20', width: 50, bold: 'true'},
        {header: 'MT RH20', field: 'mtrh20', width: 50, bold: 'true'},
        {header: 'MT R40', field: 'mtr40', width: 50, bold: 'true'},
        {header: 'MT RH40', field: 'mtrh40', width: 50, bold: 'true'},
        {header: 'MT R45', field: 'mtr45', width: 50, bold: 'true'},
        {header: 'MT RH45', field: 'mtrh45', width: 50, bold: 'true'},
        {header: 'MT TK20', field: 'mtt20', width: 50, bold: 'true'},
        {header: 'MT TK40', field: 'mtt40', width: 50, bold: 'true'},
        {header: 'MT TK45', field: 'mtt45', width: 50, bold: 'true'},
        {header: 'Kill Slot', field: 'killslot', width: 50},
        {header: 'Mother Vessel', field: 'motherVessel', width: 50},
        {header: 'Mother Voyage', field: 'motherVoyage', width: 50},
        {header: 'Remarks', field: 'remarks', width: 500, type:'string'},

        {header: 'Created By', field: 'userCreated', width: 110},
        {header: 'Created Date', field: 'dateCreated', width: 110},
        {header: 'Modified By', field: 'userModified', width: 110},
        {header: 'Modified Date', field: 'dateModified', width: 110},
        {header: 'Deleted By', field: 'userDeleted', width: 110},
        {header: 'Deleted Date', field: 'dateDeleted', width: 110},
        {header: 'To PSA', field: 'toPSA', width: 100},
        {header: 'CTC PAX', field: 'contactPerson', width: 100},

        {header: 'DG Class', field: 'bDgClass', width: 100},
        {header: 'R/Type Container', field: 'bReceiptTypeExtra', width: 100},
        {header: 'Payment Term', field: 'bFreightType', width: 100},
        /*{header: 'ACCT PIC', field: 'a', width: 100},
        {header: 'Approved By', field: 'a', width: 100},
        {header: 'Approved Date', field: 'a', width: 100},
        */
        /*
        {header: 'T/L/O', field: 't45', width: 50},
        {header: 'To PSA', field: 't45', width: 50},
        {header: 'To SDS', field: 't45', width: 50},
        {header: 'CTC PAX', field: 't45', width: 50}
        */
      ],
      footer : {
        label : 'TOTAL'
      },
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
      enableHideColumns: true,
      sortingColumns:'dateModified',
      sortingDirection: 'DESC',
    }


  }

  ngOnInit() {
    //this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.hideOverlay();

  }

  ngAfterViewInit() {

    this.genericUtil.hideLoader();
    this.cbLocalTranshipment.setValue('All/Local/Transshipment');
    this.cbFullEmpty.setValue('All/Full/Empty');
    this.param.bOceanVesselBound = 'O';
    this.param.selected = '';
    this.param.bTsbooking = this.tsBooking;

    this.isLoad=false;
    // console.log("localStorage.getItem('close-booking-to-flag') --> ");
    // console.log(localStorage.getItem("close-booking-to-flag"));
    // console.log("***************************************************************")
    if(localStorage.getItem("close-booking-to-flag") != null){

      this.param = JSON.parse(localStorage.getItem("close-booking-to-flag"));

      // console.log(this.param);

      var self = this;
      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
      this.cbVessel.setValue(String(this.param.bOceanVesselId).trim(), function (callbackVessel) {

        self.param.bOceanVesselId = self.param.bOceanVesselId;

        //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ self.param.bOceanVesselId +"/"+self.param.bOceanVesselBound+"/" + self.param.bOceanVesselVoyage + "/" + self.param.bOfficeCode );
        self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.param.bOfficeCode + "/" + self.param.bOceanVesselId + "/" + self.param.bOceanVesselBound  + "/voyage_like={query}");
        self.cbVoyage.setValue(self.param.bOceanVesselVoyage);

        self.cbPOD.setUrl(self.configService.config.BASE_API.toString() + '/MasterBookings/searchPOD/'+ self.param.bPodCode + '/' + self.param.bOceanVesselId + '/' + self.param.bOceanVesselVoyage + '/' + self.param.bOceanVesselBound + "/" + self.param.bOfficeCode );
        self.cbPOD.setValue(self.param.bPodCode);

        self.cbFDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterBookings/searchFDest/' + self.param.bFDestCode + '/' + self.param.bOceanVesselId + '/' + self.param.bOceanVesselVoyage + '/'+ self.param.bOceanVesselBound + "/" + self.param.bOfficeCode);
        self.cbFDest.setValue(self.param.bFDestCode);

        self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterBookings/searchPOT1/' + self.param.bPot1Code + '/' + self.param.bOceanVesselId + '/' + self.param.bOceanVesselVoyage + '/'+self.param.bOceanVesselBound + "/" + self.param.bOfficeCode);
        self.cbPOT1.setValue(self.param.bPot1Code);

        self.cbPOL.setUrl(self.configService.config.BASE_API.toString() + '/MasterBookings/searchPOL/' + self.param.bPolCode + '/' + self.param.bOceanVesselId + '/'+ self.param.bOceanVesselVoyage + '/' + self.param.bOceanVesselBound + "/" + self.param.bOfficeCode);
        self.cbPOL.setValue(self.param.bPolCode);


        //set soc / coc / slot

        if(self.param.bContainerOwnership == 'SOC|SOCTS|COC|COCTS|SLOT'){
          self.isSOC = true;
          self.isCOC = true;
          self.isSLOT = true;
        }

        if(self.param.bContainerOwnership == 'SOC|SOCTS|COC|COCTS'){
          self.isSOC = true;
          self.isCOC = true;
          self.isSLOT = false;
        }

        if(self.param.bContainerOwnership == 'COC|COCTS|SLOT'){
          self.isSOC = false;
          self.isCOC = true;
          self.isSLOT = true;
        }

        if(self.param.bContainerOwnership == 'SOC|SOCTS|SLOT'){
          self.isSOC = true;
          self.isCOC = false;
          self.isSLOT = true;
        }

        if(self.param.bContainerOwnership == 'SOC|SOCTS'){
          self.isSOC = true;
          self.isCOC = false;
          self.isSLOT = false;
        }

        if(self.param.bContainerOwnership == 'COC|COCTS'){
          self.isSOC = false;
          self.isCOC = true;
          self.isSLOT = false;
        }

        if(self.param.bContainerOwnership == 'SLOT'){
          self.isSOC = false;
          self.isCOC = false;
          self.isSLOT = true;
        }

        // console.log('self.param.bContainerStatus = ' + self.param.bContainerStatus);

        if(self.param.bContainerStatus == 'All' || self.param.bContainerStatus == ''){
          self.cbFullEmpty.setValue('All/Full/Empty');
          self.param.bContainerStatus = '';
        }else{
          self.cbFullEmpty.setValue(self.param.bContainerStatus);
        }

        if(self.param.bTsbooking == 'All'){
          self.cbLocalTranshipment.setValue('All/Local/Transshipment');
        }else{
          self.cbLocalTranshipment.setValue(self.param.bTsbooking);
        }

        self.tsBooking = self.param.bTsbooking;

        if(self.param.bOceanVesselCode != "" && self.param.bOceanVesselVoyage != ""){
          self.isLoad = true;
          // console.log('localStorage.getItem("booking-flag-status-current-page") == ' + localStorage.getItem("booking-flag-status-current-page"));
          self.grid.setCurrentPage(Number.parseInt(localStorage.getItem("booking-flag-status-current-page")));
          self.grid.setBody(self.param);
          self.grid.loadData();
        }
      })

      self.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

      //localStorage.removeItem("booking-flag-status-current-page");
      localStorage.removeItem("close-booking-to-flag");
    }

    //reset the scroll to 0.0
    window.scrollTo(0,0);
    this.cdr.detectChanges();
  }

  changeEventVessel(event) {
    // console.log(event);
    if(event.vesselId == null || event.vesselId == "" || event.vesselId == undefined){
      this.param.bOceanVesselId = '';
      this.param.bOceanVesselCode = '';

      this.cbPOD.setUrl("");
      this.cbPOL.setUrl("");
      this.cbPOT1.setUrl("");
      this.cbFDest.setUrl("");
      this.param.bPodCode="";this.param.bPot1Code="";this.param.bFDestCode="";this.param.bPolCode="";
    }else{
      this.param.bOceanVesselId = event.vesselId;
      this.param.bOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + event.vesselId + "/" + this.param.bOceanVesselBound  + "/voyage_like={query}");
      this.cbVoyage.setValue("");

      this.cbPOD.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOD/{query}/' +event.vesselId+"/*/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOD.setValue("");
      this.param.bPodCode = '';

      this.cbFDest.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchFDest/{query}/' +event.vesselId+"/*/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbFDest.setValue("");
      this.param.bFDestCode = '';

      this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOT1/{query}/' +event.vesselId+"/*/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOT1.setValue("");
      this.param.bPot1Code = '';

      this.cbPOL.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOL/{query}/' +event.vesselId+"/*/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOL.setValue("");
      this.param.bPolCode = '';

    }

    this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
  }

  changeEventVoyage(event) {
    if(event.voyage == null){
      this.param.bOceanVesselVoyage = '';
    }else{
      this.param.bOceanVesselVoyage = event.voyage;

      this.cbPOD.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOD/{query}/' + this.param.bOceanVesselId +"/" + this.param.bOceanVesselVoyage + "/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOD.setValue("");
      this.param.bPodCode = '';

      this.cbFDest.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchFDest/{query}/' + this.param.bOceanVesselId +"/" + this.param.bOceanVesselVoyage + "/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbFDest.setValue("");
      this.param.bFDestCode = '';

      this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOT1/{query}/' + this.param.bOceanVesselId +"/" + this.param.bOceanVesselVoyage + "/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOT1.setValue("");
      this.param.bPot1Code = '';

      this.cbPOL.setUrl(this.configService.config.BASE_API.toString() + '/MasterBookings/searchPOL/{query}/' + this.param.bOceanVesselId +"/" + this.param.bOceanVesselVoyage + "/"+this.param.bOceanVesselBound.toUpperCase() + "/" + this.cookieService.getDefaultLocationCode());
      this.cbPOL.setValue("");
      this.param.bPolCode = '';
    }

  }

  changeBound(event){
    this.param.bOceanVesselBound = event.target.value;
    this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + this.param.bOceanVesselId + "/" + this.param.bOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
    this.cbVoyage.setValue("");

    this.param.bOceanVesselVoyage = '';
  }

  changeContainerSOC(event){
    if(event.target.checked){
      this.isSOC = true;
    }else{
      this.isSOC = false;
      /*
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.cbLocalTranshipment.setValue('All');
      }
      */
    }
  }
  changeContainerCOC(event){
    if(event.target.checked){
      this.isCOC = true;
    }else{
      this.isCOC = false;
      /*
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.cbLocalTranshipment.setValue('All');
      }
      */
    }
  }
  changeContainerSLOT(event){
    /*
    if(event.target.checked){
      if(this.tsBooking === 'Transhipment'){
        this.isSLOT = true;
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }else{
        this.isSLOT = true;
      }
    }else{
      this.isSLOT = false;
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }
    }
    */
    if(event.target.checked){
      this.isSLOT = true;
    }else{
      this.isSLOT = false;
    }
  }

  changeContainerNVOCC(event){
    /*
    if(event.target.checked){
      if(this.tsBooking === 'Transhipment'){
        this.isNVOCC = true;
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }else{
        this.isNVOCC = true;
      }
    }else{
      this.isNVOCC = false;
      if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
        this.tsBooking = 'All';
        this.cbLocalTranshipment.setValue('All');
      }
    }
    */
    if(event.target.checked){
      this.isNVOCC = true;
    }else{
      this.isNVOCC = false;
    }
  }
  changeEventLocalTranshipment(event) {
    // console.log(event.title);
    if(event.title == "All/Local/Transshipment"){
      this.tsBooking = 'All';
    }else{
      this.tsBooking = event.title;
    }

    this.param.bTsbooking = this.tsBooking;

    /*
    if((event.title === 'Transhipment' || event.title === 'Local' ) && !this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC) {
      // console.log("Please choose container type");
      if(this.tsBooking != ''){
          this.cbLocalTranshipment.setValue(this.tsBooking);
          if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
            this.tsBooking = 'All';
            this.cbLocalTranshipment.setValue('All');
          }
      }else{
          this.tsBooking = 'All';
          this.cbLocalTranshipment.setValue('All');
      }
    }else{
      if(event.title === 'Transhipment' && (this.isSLOT || this.isNVOCC )){
        // console.log("SLOT / NVOCC doesnt have Transhipment");
        if(this.tsBooking != ''){
          this.cbLocalTranshipment.setValue(this.tsBooking);
          if(!this.isSOC && !this.isCOC && !this.isSLOT && !this.isNVOCC && (this.tsBooking === 'Local' || this.tsBooking === 'Transhipment') ){
            this.tsBooking = 'All';
            this.cbLocalTranshipment.setValue('All');
          }
        }else{
          this.tsBooking = 'All';
          this.cbLocalTranshipment.setValue('All');
        }
      }else{
        this.tsBooking = event.title;
      }
    }
    */
  }
  changeEventFullEmpty(event) {
    // console.log(event);
    if(event.title != 'All/Full/Empty'){
      this.param.bContainerStatus = event.title
    }else{
      this.param.bContainerStatus = '';
    }
  }
  changeEventPOD(event) {
    // console.log("** change event POD **");
    // console.log(event);

    if(event.locationCode == null){
      this.param.bPodCode = '';
    }else{
      this.param.bPodCode = event.locationCode;
    }
  }
  changeEventFDest(event) {
    if(event.locationCode == null){
      this.param.bFDestCode = '';
    }else{
      this.param.bFDestCode = event.locationCode;
    }
  }
  changeEventPOT1(event) {
    if(event.locationCode == null){
      this.param.bPot1Code = '';
    }else{
      this.param.bPot1Code = event.locationCode;
    }
  }
  changeEventPOL(event) {
    if(event.locationCode == null){
      this.param.bPolCode = '';
    }else{
      this.param.bPolCode = event.locationCode;
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  gridEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        var mode = event.split(".")[1].split("-")[0];
        // console.log(mode);
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          // console.log(arr[0]);
          // console.log(arr[1]);
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              this.fullBookingNo='';
              let dt = this.grid.getSelectedValues()[0];
              if(dt.bStatus == 'D'){
                this.message('warning','Information','Booking already deleted.','okonly',{ok: ''});
              }else{
                if(dt.bIsUnlocked == 'N'){
                  this.fullBookingNo =  dt.bPrefix + "-" + dt.bNo;
                  this.message('information','Confirmation','Current booking is locked! View only?','yesno',{yes: 'this.continueToMaintenance(this.fullBookingNo);',no:''});
                }else{
                  this.fullBookingNo =  dt.bPrefix + "-" + dt.bNo;
                  this.continueToMaintenance(this.fullBookingNo);
                  /*
                  this.genericUtil.showLoaderPage();
                  var self = this;
                  setTimeout(function(){

                    self.param.selected = dt.bPrefix + "-" + dt.bNo;

                    localStorage.setItem("retrieve-booking-from-flag",JSON.stringify(self.param));
                    localStorage.setItem("booking-flag-status-current-page",self.grid.getCurrentPage().toString());
                    self.router.navigate(['/main/transaction-booking-maintenance']);
                  },100);
                  */
                }


              }

              break;
            case 'footer':
              // console.log(JSON.parse(arr[1].toString()));

              //new section to calculate the kill slot
              if(JSON.parse(arr[1].toString())['total'][0]['killSlotCOC'] != null){
                this.total_coc_killslot = JSON.parse(arr[1].toString())['total'][0]['killSlotCOC'];
              }else{
                this.total_coc_killslot = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['killSlotSOC'] != null){
                this.total_soc_killslot = JSON.parse(arr[1].toString())['total'][0]['killSlotSOC'];
              }else{
                this.total_soc_killslot = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['killSlotSLOT'] != null){
                this.total_slot_killslot = JSON.parse(arr[1].toString())['total'][0]['killSlotSLOT'];
              }else{
                this.total_slot_killslot = 0;
              }

              if(JSON.parse(arr[1].toString())['allotment']['SOC'] != null){
                this.total_soc_allocated = JSON.parse(arr[1].toString())['allotment']['SOC'];
              }else{
                this.total_soc_allocated = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['SOCTS'] != null){
                this.total_soc_ts = JSON.parse(arr[1].toString())['total'][0]['SOCTS'];
              }else{
                this.total_soc_ts = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['SOC'] != null){
                this.total_soc_local = JSON.parse(arr[1].toString())['total'][0]['SOC'];
              }else{
                this.total_soc_local = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['LadenSOC'] != null){
                this.total_soc_laden_booked = JSON.parse(arr[1].toString())['total'][0]['LadenSOC'];
              }else{
                this.total_soc_laden_booked = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['EmptySOC'] != null){
                this.total_soc_empty_booked = JSON.parse(arr[1].toString())['total'][0]['EmptySOC'];
              }else{
                this.total_soc_empty_booked = 0;
              }

              this.total_soc_available =  this.total_soc_allocated - (this.total_soc_local + this.total_soc_ts) - this.total_soc_killslot;


              if(JSON.parse(arr[1].toString())['allotment']['COC'] != null){
                this.total_coc_allocated = JSON.parse(arr[1].toString())['allotment']['COC'];
              }else{
                this.total_coc_allocated = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['COCTS'] != null){
                this.total_coc_ts = JSON.parse(arr[1].toString())['total'][0]['COCTS'];
              }else{
                this.total_coc_ts = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['COC'] != null){
                this.total_coc_local = JSON.parse(arr[1].toString())['total'][0]['COC'];
              }else{
                this.total_coc_local = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['LadenCOC'] != null){
                this.total_coc_laden_booked = JSON.parse(arr[1].toString())['total'][0]['LadenCOC'];
              }else{
                this.total_coc_laden_booked = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['EmptyCOC'] != null){
                this.total_coc_empty_booked = JSON.parse(arr[1].toString())['total'][0]['EmptyCOC'];
              }else{
                this.total_coc_empty_booked = 0;
              }

              this.total_coc_available =  this.total_coc_allocated - (this.total_coc_local + this.total_coc_ts) - this.total_coc_killslot;

              if(JSON.parse(arr[1].toString())['allotment']['SLOT'] != null){
                this.total_slot_allocated = JSON.parse(arr[1].toString())['allotment']['SLOT'];
              }else{
                this.total_slot_allocated = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['SLOT'] != null){
                this.total_slot_booked = JSON.parse(arr[1].toString())['total'][0]['SLOT'];
              }else{
                this.total_slot_booked = 0;
              }

              this.total_slot_available =  this.total_slot_allocated - this.total_slot_booked - this.total_slot_killslot;

              if(JSON.parse(arr[1].toString())['allotment']['VS'] != null){
                this.total_vsl_teus = JSON.parse(arr[1].toString())['allotment']['VS'];
              }else{
                this.total_vsl_teus = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['ladenSLOT'] != null){
                this.total_slot_laden_booked = JSON.parse(arr[1].toString())['total'][0]['ladenSLOT'];
              }else{
                this.total_slot_laden_booked = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['emptySLOT'] != null){
                this.total_slot_empty_booked = JSON.parse(arr[1].toString())['total'][0]['emptySLOT'];
              }else{
                this.total_slot_empty_booked = 0;
              }

              this.total_laden = this.total_coc_laden_booked + this.total_soc_laden_booked + this.total_slot_laden_booked;

              this.total_empty = this.total_coc_empty_booked + this.total_soc_empty_booked + this.total_slot_empty_booked;

              if(JSON.parse(arr[1].toString())['sailSchedule']['ETA'] != null){
                this.eta = String(JSON.parse(arr[1].toString())['sailSchedule']['ETA']).replace(/\//g,"-");
              }else{
                this.eta = '';
              }

              if(JSON.parse(arr[1].toString())['sailSchedule']['ETD'] != null){
                this.etd = String(JSON.parse(arr[1].toString())['sailSchedule']['ETD']).replace(/\//g,"-");
              }else{
                this.etd = '';
              }

              if(JSON.parse(arr[1].toString())['sailSchedule']['ATA'] != null){
                this.ata = String(JSON.parse(arr[1].toString())['sailSchedule']['ATA']).replace(/\//g,"-");
              }else{
                this.ata = '';
              }

              if(JSON.parse(arr[1].toString())['sailSchedule']['ATD'] != null){
                this.atd = String(JSON.parse(arr[1].toString())['sailSchedule']['ATD']).replace(/\//g,"-")
              }else{
                this.atd = '';
              }

              if(JSON.parse(arr[1].toString())['total'][0]['ladenReserve'] != null){
                this.total_resv_laden = JSON.parse(arr[1].toString())['total'][0]['ladenReserve'];
              }else{
                this.total_resv_laden = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['emptyReserve'] != null){
                this.total_resv_empty = JSON.parse(arr[1].toString())['total'][0]['emptyReserve'];
              }else{
                this.total_resv_empty = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['totalKillslot'] != null){
                this.total_resv_killslot = JSON.parse(arr[1].toString())['total'][0]['totalKillslot'];
              }else{
                this.total_resv_killslot = 0;
              }

              this.total_resv =  this.total_resv_laden + this.total_resv_empty;


              if(JSON.parse(arr[1].toString())['total'][0]['ladenReserveCOC'] != null){
                this.total_laden_reserve_coc = JSON.parse(arr[1].toString())['total'][0]['ladenReserveCOC'];
              }else{
                this.total_laden_reserve_coc = 0;
              }
              if(JSON.parse(arr[1].toString())['total'][0]['emptyReserveCOC'] != null){
                this.total_empty_reserve_coc = JSON.parse(arr[1].toString())['total'][0]['emptyReserveCOC'];
              }else{
                this.total_empty_reserve_coc = 0;
              }


              if(JSON.parse(arr[1].toString())['total'][0]['ladenReserveSOC'] != null){
                this.total_laden_reserve_soc = JSON.parse(arr[1].toString())['total'][0]['ladenReserveSOC'];
              }else{
                this.total_laden_reserve_soc = 0;
              }
              if(JSON.parse(arr[1].toString())['total'][0]['emptyReserveSOC'] != null){
                this.total_empty_reserve_soc = JSON.parse(arr[1].toString())['total'][0]['emptyReserveSOC'];
              }else{
                this.total_empty_reserve_soc = 0;
              }

              if(JSON.parse(arr[1].toString())['total'][0]['ladenReserveSLOT'] != null){
                this.total_laden_reserve_slot = JSON.parse(arr[1].toString())['total'][0]['ladenReserveSLOT'];
              }else{
                this.total_laden_reserve_slot = 0;
              }
              if(JSON.parse(arr[1].toString())['total'][0]['emptyReserveSLOT'] != null){
                this.total_empty_reserve_slot = JSON.parse(arr[1].toString())['total'][0]['emptyReserveSLOT'];
              }else{
                this.total_empty_reserve_slot = 0;
              }

              this.total_reserve = this.total_laden_reserve_coc + this.total_empty_reserve_coc + this.total_laden_reserve_soc + this.total_empty_reserve_soc + this.total_laden_reserve_slot + this.total_empty_reserve_slot;


              this.totalUC = JSON.parse(arr[1].toString())['total'][0]['totalUC'];










              break;

            case 'afterLoad':
              // console.log("size : " + this.grid.listStore.store.length);
              if(this.grid.listStore.store.length == 0){
                if(this.isLoad){
                  this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=false;this.isLoad=false;'});
                }
              }
              break;
            default:
              break;
          }

        }

        break;
    }

  }

  infoGrid(event) {
    this.info = event;
  }

  toolbarEvent(event: any) {
    // console.log('event : ' + event);
    switch (event) {
      case 'retrieve':
        this.handleRetrieve();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'new':
        this.handleNewBooking();
        break;
      case 'close':
        this.router.navigateByUrl('/main/home');
        break;
      case 'export':
        this.isError = this.onValidate(this.param);
        if(!this.isError){
          var formatName = "report-bookingFlagStatusExport"+".xls";
          var uri = this.configService.config.BASE_API.toString()+
          "?q=" +
            "/MasterBookings/bookingFlagStatusExport/"
            +
            this.cookieService.getDefaultLocationCode() + '/' +
            this.param.bOceanVesselId + '/' +
            this.param.bOceanVesselVoyage + '/' +
            this.param.bOceanVesselBound + '/' +
            this.cookieService.getName()
          var self = this;
          this.loading = true;
          $.ajax({
            url: uri,
            method: "GET",
            beforeSend: function(request) {
              request.setRequestHeader("Token", localStorage.getItem("token"));
            },
            xhrFields: {
              responseType: "blob",
            },
            success: function (data) {
              var a = document.createElement("a");
              var url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = formatName;
              a.click();
              window.URL.revokeObjectURL(url);
              this.loading = false;
            },
          });
          // const urlHit = 'http://glossys.samudera.com:14000' + '/MasterBookings/bookingFlagStatusExport/' + this.cookieService.getDefaultLocationCode() + '/' +  this.param.bOceanVesselId + '/' + this.param.bOceanVesselVoyage + '/' + this.param.bOceanVesselBound + '/' + this.cookieService.getName();
          // window.open(urlHit);
        }
        break;
      default:
        let str:string = event;
        // console.log('str = ' + str);
        break;
    }
  }


  handleRetrieve() {
    // console.log('retrieve event');
    this.isError = false;
    this.param['allocationValidator'] = false;

    this.grid.curPage = 1;
    this.grid.currentPage = 1;

    this.param.selected = '';
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.param.bContainerOwnership = '';

    if(this.tsBooking === 'All' || this.tsBooking === '' ){

      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOC|SOCTS|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COC|COCTS|';
      }
      if(this.isSLOT){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SLOT|';
      }
      if(this.isNVOCC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'NVOCC|';
      }

    }else if(this.tsBooking === 'Local'){
      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOC|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COC|';
      }
      if(this.isSLOT){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SLOT|';
      }
      if(this.isNVOCC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'NVOCC|';
      }

    }else if(this.tsBooking === 'Transhipment'){
      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOCTS|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COCTS|';
      }
    }

    this.param.bTsbooking = this.tsBooking;

    this.param.bContainerOwnership = this.param.bContainerOwnership.substring(0,this.param.bContainerOwnership.length-1);
    // console.log(this.param.bContainerOwnership);

    this.isError = this.onValidate(this.param);
    if(!this.isError){
      // console.log(this.param);
      this.isLoad = true;
      this.grid.setBody(this.param);
      //this.grid.loadData();
    }


    this.gridApi.showLoadingOverlay();

    this.genericService.POST(this.configService.config.BASE_API.toString() +'/MasterBookings/searchFlagStatus/1/1000/DESC/dateModified', this.param).subscribe((resp) => {
      console.log('dbeug');
      console.log(resp.json()['content']);
      this.rowData = resp.json()['content'];

      let x = [];
           //x.push(resp.json()['footer']['total'][0]);

      const footerData = resp.json()['footer']['total'][0];
      console.log('debug footer data');
      console.log(footerData);
      const gridTotal = new GridTotal();
      gridTotal.d20 = footerData.D20; gridTotal.h20 = footerData.H20; gridTotal.d40 = footerData.D40;
      gridTotal.h40 = footerData.H40; gridTotal.d45 = footerData.D45; gridTotal.h45 = footerData.H45;
      gridTotal.r20 = footerData.R20; gridTotal.rh20 = footerData.RH20; gridTotal.r40 = footerData.R40;
      gridTotal.rh40 = footerData.RH40; gridTotal.r45 = footerData.R45; gridTotal.rh45 = footerData.RH45;
      gridTotal.t20 = footerData.T20; gridTotal.t40 = footerData.T40; gridTotal.t45 = footerData.T45;

      gridTotal.mt20 = footerData.MT20; gridTotal.mth20 = footerData.MTH20; gridTotal.mt40 = footerData.MT40;
      gridTotal.mth40 = footerData.MTH40; gridTotal.mt45 = footerData.MT45; gridTotal.mth45 = footerData.MTH45;
      gridTotal.mtr20 = footerData.MTR20; gridTotal.mtrh20 = footerData.MTRH20; gridTotal.mtr40 = footerData.MTR40;
      gridTotal.mtrh40 = footerData.MTRH40; gridTotal.mtr45 = footerData.MTR45; gridTotal.mtrh45 = footerData.MTRH45;
      gridTotal.mtt20 = footerData.MTT20; gridTotal.mtt40 = footerData.MTT40; gridTotal.mtt45 = footerData.MTT45;

      gridTotal.killslot = footerData.KILLSLOT;

      x.push(gridTotal);
      this.rowData2 = x;

      this.pinnedBottomRowData = this.rowData2;

      this.gridApi.hideOverlay();
    });

  }


  continueToMaintenance(bno:string){

    this.genericUtil.showLoaderPage();
    var self = this;
    // console.log("Above set time out ");
    setTimeout(function(){

      self.param.selected = bno;

      localStorage.setItem("retrieve-booking-from-flag",JSON.stringify(self.param));
      localStorage.setItem("booking-flag-status-current-page",self.grid.getCurrentPage().toString());
      self.router.navigate(['/main/transaction-booking-maintenance']);
    },100);
  }

  handleCancel() {
    //to reset the isError (dismiss error message section)
    this.isError=false;


    this.param = new Param;
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbVoyage.setUrl('');
    this.cbPOD.setValue('');
    this.cbFDest.setValue('');
    this.cbPOT1.setValue('');
    this.cbPOL.setValue('');

    var self = this;
    this.cbLocalTranshipment.clearSelect();
    this.cbFullEmpty.clearSelect();
    setTimeout(() => {
      self.cbFullEmpty.setValue('All/Full/Empty');
      self.param.bContainerStatus = '';
      self.cbLocalTranshipment.setValue('All/Local/Transshipment');
      self.param.bTsbooking = 'All';
    }, 10);

    this.tsBooking="";

    this.isCOC = true;
    this.isSOC = true;
    this.isSLOT = true;
    this.isNVOCC = false;
    //this.tsBooking = '';
    this.isLoad = false;
    this.param.bOceanVesselBound = 'O';
    this.grid.setBody(this.param);
    this.grid.loadData();

    this.cbPOD.setUrl("");
    this.cbPOT1.setUrl("");
    this.cbPOL.setUrl("");
    this.cbFDest.setUrl("");

    //reset window scroll to 0.0
    window.scrollTo(0,0);
  }

  getAllocation(callback?: (data) => void){
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    if(this.param.bOceanVesselId != '' && this.param.bOceanVesselVoyage != ''&& this.param.bOceanVesselBound != '') {
      this.genericService.GET(this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail/' + this.param.bOceanVesselId + '/' + this.param.bOceanVesselVoyage + '/' + this.param.bOceanVesselBound + '/' + this.param.bOfficeCode).subscribe((resp) =>{
        let data = resp.json();
        // console.log(data.size);

        callback(data.size);
      });
    }
  }

  handleNewBooking() {
    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.param.bContainerOwnership = '';

    if(this.tsBooking === 'All' || this.tsBooking === '' ){

      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOC|SOCTS|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COC|COCTS|';
      }
      if(this.isSLOT){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SLOT|';
      }
      if(this.isNVOCC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'NVOCC|';
      }

    }else if(this.tsBooking === 'Local'){
      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOC|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COC|';
      }
      if(this.isSLOT){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SLOT|';
      }
      if(this.isNVOCC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'NVOCC|';
      }

    }else if(this.tsBooking === 'Transhipment'){
      if(this.isSOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'SOCTS|';
      }
      if(this.isCOC){
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COCTS|';
      }
    }

    this.param.bContainerOwnership = this.param.bContainerOwnership.substring(0,this.param.bContainerOwnership.length-1);
    // console.log(this.param.bContainerOwnership);

    var self = this;
    self.isError = false;
    // console.log(self.isError);
    this.param['error-allocationValidator'] = '';
    this.getAllocation(function (callback) {
      // console.log('callback : ' + callback);
      if(callback == '0'){
        self.param['allocationValidator'] = true;
      }
    });

    setTimeout(function(){
      // console.log('param --> ');
      // console.log(self.param);
      self.isError = self.onValidate(self.param);
      if(!self.isError){
        self.genericUtil.showLoaderPage();

        setTimeout(function(){
          self.param.selected = '';
          localStorage.setItem("new-booking-from-flag",JSON.stringify(self.param))
          localStorage.setItem("booking-flag-status-current-page",self.grid.getCurrentPage().toString());

          self.router.navigate(['/main/transaction-booking-maintenance']);
        },100);

      }
    },100);


  }

  rowDoubleClick(params){
    this.fullBookingNo='';
    let dt = params.data;
    if (dt.bStatus == 'D'){
      this.message('warning', 'Information', 'Booking already deleted.', 'okonly',{ok: ''});
    } else {
      if (dt.bIsUnlocked === 'N'){
        this.fullBookingNo =  dt.bPrefix + '-' + dt.bNo;
        this.message('information', 'Confirmation', 'Current booking is locked! View only?', 'yesno',{yes: 'this.continueToMaintenance(this.fullBookingNo);',no:''});
      } else {
        this.fullBookingNo =  dt.bPrefix + '-' + dt.bNo;
        this.continueToMaintenance(this.fullBookingNo);
      }


    }

  }


}
