<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <form class="ui form">
    <div class="field">
      <div class="three fields">
        <div class="field">
          <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%" >
            <legend style="font-weight:bold;color:dodgerblue;">Transfer From</legend>
            <div class="three fields">
              <div class="required field"  [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                <label>Vessel</label>
                <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                  {{model['error-vesselName']}}
                </div>
              </div>
              <div class="required field"  [class.error]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''">
                <label>Voyage</label>
                <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="model['error-bOceanVesselVoyage'] != null && model['error-bOceanVesselVoyage'] != ''">
                  {{model['error-bOceanVesselVoyage']}}
                </div>
              </div>
              <div class="required field">
                <label>Bound</label>
                <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['bound']" (change)="changeEventVoyage($event)" style="width:100%;" >
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="model['error-bound'] != null && model['error-bOceanVesselBound'] != ''">
                  {{model['error-bOceanVesselBound']}}
                </div>
              </div>
            </div>
            <div class="twelve wide field">
              <div class="one field" style="width:100%">
                <label style="float: left;">Port Of Calls</label><label style="float: left;">&nbsp;&nbsp;&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{portOfCallFrom}}</label>
              </div>
            </div>
          </fieldset>
        </div>


        <div class="field">
          <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%" >
            <legend style="font-weight:bold;color:dodgerblue;">Transfer To</legend>
            <div class="three fields">
              <div class="required field" [class.error]="model['error-bOceanVesselDes'] != null && model['error-bOceanVesselDes'] != ''">
                <label>Vessel</label>
                <combo-plugin #cbVesselDes [settings]="settingVesselDes" (change)="changeEventVesselDes($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="model['error-bOceanVesselDes'] != null && model['error-bOceanVesselDes'] != ''">
                  {{model['error-bOceanVesselDes']}}
                </div>
              </div>
              <div class="required field" [class.error]="model['error-bOceanVoyageDes'] != null && model['error-bOceanVoyageDes'] != ''">
                <label>Voyage</label>
                <combo-plugin #cbVoyageDes [settings]="settingVoyageDes" (change)="changeEventVoyageDes($event)" style="width:100%;"></combo-plugin>
                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                     [class.error-message-visible]="model['error-bOceanVoyageDes'] != null && model['error-bOceanVoyageDes'] != ''">
                  {{model['error-bOceanVoyageDes']}}
                </div>
              </div>
              <div class="required field">
                <label>Bound</label>
                <input type="text" name="boundDes"  placeholder="Bound"  [(ngModel)]="model['boundDes']" >
                <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-boundDes'] != null && model['error-boundDes'] != ''">
                  {{model['error-boundDes']}}
                </div>
              </div>
            </div>
            <div class="twelve wide field">
              <div class="one field" style="width:100%">
                <label style="float: left;">Port Of Calls</label><label style="float: left;">&nbsp;&nbsp;&nbsp;:&nbsp;</label><label style="float: left; font-weight: normal;color: cornflowerblue;">{{portOfCallTo}}</label>
              </div>
            </div>
          </fieldset>
        </div>
        <br>
        <div class="field">
          <fieldset style="border: 1px solid lightgray; height: 100%; width: 60%">
            <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria</legend>
            <div class="field">
              <div class="ui radio checkbox" >
                <input type="radio"  name="rdTransferBy"   [checked]="radioTransferByVessel == 'Y'" (change)="changeEventTransferBy($event)" value="vessel">

                <label>Vessel Wise</label>
              </div>
            </div>
            <br>
            <div class="field">
              <div class="ui radio checkbox" >
                <input type="radio"  name="rdTransferBy"  [checked]="radioTransferByBNo == 'Y'" (change)="changeEventTransferBy($event)" value="bookingNumber">

                <label>B/L Wise</label>
              </div>
            </div>
            <br>
          </fieldset>
        </div>
      </div>
    </div>

       <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
          <div class="field" [style.display]="addRemoveGrid">
            <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
            <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
            <!--button  class="ui button split" data-content="addGrid" (click)="rowEvent('add')" id="addGrid" ><i class="plus icon"></i></button>
            <button  class="ui button split" data-content="remGrid" (click)="rowEvent('del')" id="remGrid" ><i class="remove icon"></i></button>
            -->
          </div>
          <div class="field" >
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>

          <!--
                    <div class="field" [style.display]="modeForm == 'bookingTransferDefault2'?'block':'none'">
                      <grid-plugin id="gridBookingTransferByNo" #gridBookingTransferByNo [settings]="settingGridBookingTransferByNo" (gridEvent)="gridBookingTransferEventByNo($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </div>
           -->
                    <div class="field" [style.display]="modeForm == 'bookingTransferAllocation'?'block':'none'">
                      <grid-plugin id="gridBookingTransferAllocation" #gridBookingTransferAllocation [settings]="settingGridBookingTransferAllocation" (gridEvent)="gridBookingTransferAllocationEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                    </div>

                          </fieldset>
                 </div>
          </form>
          </form>


          <footer-plugin [info]="info"></footer-plugin>
