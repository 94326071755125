import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { officeModel } from '../shared/index';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare  var $:any;

export class ParamTransmitToEMAS {
  vesselId: String = '';
  vesselName: String = '';
  voyage: String = '';
  bound: String = 'O';
  constructor() {}
}

@Component({
  selector: 'app-edi-transmit-to-emas-page',
  templateUrl: './edi-transmit-to-emas-page.component.html',
  styleUrls: ['./edi-transmit-to-emas-page.component.css']
})
export class EDITransmitToEMASPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  model = new ParamTransmitToEMAS();
  office = new officeModel;
  userId = '';

  validatorRules = {};

  settingVessel;
  settingVoyage;

  hitUrl = this.configService.config.BASE_API.toString();

  constructor(private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService,private _http: HttpClient) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Transmit', event: 'transmit', icon: 'exchange'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Transmit to EMAS'
    };
    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 200},
        {header: 'Vessel Name', field: 'vesselName', width: 350},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
    this.model = new ParamTransmitToEMAS();
  }

  transmitProcess(){
    var createDate = this.genericUtil.getPSIDate();
    var hitUrl = this.configService.config.BASE_API.toString() + "/ediController/transmit/emas/"+this.office.officeCode+"/"+this.office.officeName+"/"+this.model.vesselId+"/"+this.model.voyage.replace('/', '[slash]')+"/"+this.model.bound+"/"+createDate+"/"+this.userId;
    this.loading = true;

    this.genericService.GET(hitUrl).subscribe((resp)=>{
      console.log(resp);
      if(resp.status == 200){
        var data = resp.json();
        var urlDownload = this.configService.config.getPDFUrl + "?q=/edi-util/getFile";
        if(data["status"] == "SUCCESS"){
          urlDownload = urlDownload + "/" + data["fileName"].split(".")[0] + "/" + data["fileName"].split(".")[1] ;
          this._http.get(urlDownload,{ headers: new HttpHeaders({'Content-Type':  'application/json','Token': localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{
            FileSaver.saveAs(res, data["fileName"]);
            this.loading = false;
          });
        } else {
          this.message("information","Information","Error has been detected when generating the report. Please report to Administrator!","okonly",{ok:""});
          this.loading = false;
        }
      } else {
        this.message("information","Information","Error has been detected when generating the report. Please report to Administrator!","okonly",{ok:""});
        this.loading = false;
      }
    }, error=>{
      this.loading = false;
      this.message("information","Information","Error has been detected when generating the report. Please report to Administrator!","okonly",{ok:""});
    });
  }

  firstInit(){
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'transmit' :
        if(this.model.vesselId !== '' && this.model.voyage !== '' && this.model.bound !== ''){
          this.transmitProcess();
        } else {
          this.message("information","Information","please insert vessel & voyage","okonly",{ok:""});
        }
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }

  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = '';
    }
  }
  changeEventBound(event) {}
  noneSpace(event: any) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
}
