import {Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {FreightManagement, FreightDetail, surchargeDetails} from '../shared/index';
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DatetimePluginComponent,
  ToolbarPluginComponent,
  DialogMultiplePluginComponent,
  TextareaPluginComponent
} from 'sibego-ui-library';
import {Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-transaction-freight-management-mrg-page',
  templateUrl: './transaction-freight-management-mrg-page.component.html',
  styleUrls: ['./transaction-freight-management-mrg-page.component.css']
})
export class TransactionFreightManagementMRGPageComponent extends Validator implements OnInit, AfterViewInit {
  @ViewChild('pfcToolbar') pfcToolbar: ToolbarPluginComponent;
  @ViewChild('cbFreightParty') cbFreightParty: ComboPluginComponent;
  @ViewChild('cbPlaceOfDelivery') cbPlaceOfDelivery: ComboPluginComponent;
  @ViewChild('cbContainerOwnership') cbContainerOwnership: ComboPluginComponent;
  @ViewChild('cbPlaceOfReceipt') cbPlaceOfReceipt: ComboPluginComponent;
  @ViewChild('cbPortOfTransshipment') cbPortOfTransshipment: ComboPluginComponent;
  @ViewChild('cbDGClass') cbDGClass: ComboPluginComponent;
  @ViewChild('cbPortOfLoad') cbPortOfLoad: ComboPluginComponent;
  @ViewChild('cbFinalDestination') cbFinalDestination: ComboPluginComponent;
  @ViewChild('cbReceiptType') cbReceiptType: ComboPluginComponent;
  @ViewChild('cbContainerType') cbContainerType: ComboPluginComponent;
  @ViewChild('cbPortOfDischarge') cbPortOfDischarge: ComboPluginComponent;
  @ViewChild('cbContainerStatus') cbContainerStatus: ComboPluginComponent;
  @ViewChild('cbValidInvalid') cbValidInvalid: ComboPluginComponent;
  @ViewChild('cbDeliveryType') cbDeliveryType: ComboPluginComponent;
  @ViewChild('cbPORTerminal') cbPORTerminal: ComboPluginComponent;
  @ViewChild('cbPODLTerminal') cbPODLTerminal: ComboPluginComponent;
  @ViewChild('cbPOLTerminal') cbPOLTerminal: ComboPluginComponent;
  @ViewChild('cbPOT1Terminal') cbPOT1Terminal: ComboPluginComponent;
  @ViewChild('cbPODTerminal') cbPODTerminal: ComboPluginComponent;
  @ViewChild('cbFDESTTerminal') cbFDESTTerminal: ComboPluginComponent;
  @ViewChild('cbPrepaidStatus') cbPrepaidStatus: ComboPluginComponent;
  @ViewChild('cbCollectStatus') cbCollectStatus: ComboPluginComponent;
  @ViewChild('cbAppStatus') cbAppStatus: ComboPluginComponent;

  @ViewChild('txtRemarksPrincipal') txtRemarksPrincipal: TextareaPluginComponent;
  @ViewChild('txtRemarksAgency') txtRemarksAgency: TextareaPluginComponent;
  /*
   @ViewChild('dateEffectiveFrom') dateEffectiveFrom:CalendarPluginComponent;
   @ViewChild('dateEffectiveTo') dateEffectiveTo:CalendarPluginComponent;
   */
  @ViewChild('dateEffectiveFrom') dateEffectiveFrom: DatetimePluginComponent;
  @ViewChild('dateEffectiveTo') dateEffectiveTo: DatetimePluginComponent;


  @ViewChild('mainGrid') mainGrid: GridPluginComponent;


  /***
   * Component for Freight Maintenance Maintenance
   ***/
  @ViewChild('cbMaintenanceFreightParty') cbMaintenanceFreightParty: ComboPluginComponent;
  @ViewChild('cbMaintenanceShipper') cbMaintenanceShipper: ComboPluginComponent;
  @ViewChild('cbMaintenancePlaceOfReceipt') cbMaintenancePlaceOfReceipt: ComboPluginComponent;
  @ViewChild('cbMaintenancePortOfLoad') cbMaintenancePortOfLoad: ComboPluginComponent;
  @ViewChild('cbMaintenancePortOfDischarge') cbMaintenancePortOfDischarge: ComboPluginComponent;
  @ViewChild('cbMaintenancePlaceOfDelivery') cbMaintenancePlaceOfDelivery: ComboPluginComponent;
  @ViewChild('cbMaintenanceFinalDestination') cbMaintenanceFinalDestination: ComboPluginComponent;
  @ViewChild('cbMaintenanceCommodity') cbMaintenanceCommodity: ComboPluginComponent;
  @ViewChild('cbMaintenancePOT1') cbMaintenancePOT1: ComboPluginComponent;
  @ViewChild('cbMaintenancePOT2') cbMaintenancePOT2: ComboPluginComponent;
  @ViewChild('cbMaintenancePOT3') cbMaintenancePOT3: ComboPluginComponent;
  @ViewChild('cbMaintenanceReceiptType') cbMaintenanceReceiptType: ComboPluginComponent;
  @ViewChild('cbMaintenanceDeliveryType') cbMaintenanceDeliveryType: ComboPluginComponent;
  @ViewChild('cbMaintenanceAdhocVessel') cbMaintenanceAdhocVessel: ComboPluginComponent;
  @ViewChild('cbMaintenanceAdhocVoyage') cbMaintenanceAdhocVoyage: ComboPluginComponent;

  @ViewChild('dateMaintenanceEffectiveFrom') dateMaintenanceEffectiveFrom: DatetimePluginComponent;
  @ViewChild('dateMaintenanceEffectiveTo') dateMaintenanceEffectiveTo: DatetimePluginComponent;

  @ViewChild('cbMaintenancePORTerminal') cbMaintenancePORTerminal: ComboPluginComponent;
  @ViewChild('cbMaintenancePODLTerminal') cbMaintenancePODLTerminal: ComboPluginComponent;
  @ViewChild('cbMaintenancePOLTerminal') cbMaintenancePOLTerminal: ComboPluginComponent;
  @ViewChild('cbMaintenancePOT1Terminal') cbMaintenancePOT1Terminal: ComboPluginComponent;
  @ViewChild('cbMaintenancePODTerminal') cbMaintenancePODTerminal: ComboPluginComponent;
  @ViewChild('cbMaintenanceFDESTTerminal') cbMaintenanceFDESTTerminal: ComboPluginComponent;
  @ViewChild('cbAcctPic') cbAcctPic: ComboPluginComponent;

  // Detail
  @ViewChild('cbMaintenanceDetailCharge') cbMaintenanceDetailCharge: ComboPluginComponent;
  @ViewChild('cbMaintenanceDetailCurrency') cbMaintenanceDetailCurrency: ComboPluginComponent;
  @ViewChild('cbMaintenanceDetailPC') cbMaintenanceDetailPC: ComboPluginComponent;
  @ViewChild('cbMaintenanceDetailPlaceOfPayment') cbMaintenanceDetailPlaceOfPayment: ComboPluginComponent;
  @ViewChild('cbMaintenanceDetailPayer') cbMaintenanceDetailPayer: ComboPluginComponent;


  @ViewChild('gridPrepaid') gridPrepaid: GridPluginComponent;
  @ViewChild('gridCollect') gridCollect: GridPluginComponent;

  @ViewChild('nctform_ctrType') nctform_ctrType: ComboPluginComponent;
  @ViewChild('dialogMultiple') dialogMultiple: DialogMultiplePluginComponent;

  /***
   * End of Freight Management Maintenance
   */
  dialog: any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm: string = 'freightFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingCbFreightParty;
  settingCbPlaceOfDelivery;
  settingCbContainerOwnership;
  settingCbPlaceOfReceipt;
  settingCbPortOfTransshipment;
  settingCbDGClass;
  settingCbPortOfLoad;
  settingCbFinalDestination;
  settingCbReceiptType;
  settingCbContainerType;
  settingCbPortOfDischarge;
  settingCbContainerStatus;
  settingCbValidInvalid;
  settingCbDeliveryType;
  settingCbPORTerminal;
  settingCbPODLTerminal;
  settingCbPOLTerminal;
  settingCbPOT1Terminal;
  settingCbPODTerminal;
  settingCbFDESTTerminal;
  settingCbPrepaidStatus;
  settingCbCollectStatus;
  settingMainGrid;
  settingCbAppStatus;

  settingDateEffectiveFrom;
  settingDateEffectiveTo;
  settingDateMaintenanceEffectiveFrom;
  settingDateMaintenanceEffectiveTo;

  settingCbMaintenanceFreightParty;
  settingCbMaintenanceShipper;
  settingCbMaintenancePlaceOfReceipt;
  settingCbMaintenancePortOfLoad;
  settingCbMaintenancePortOfDischarge;
  settingCbMaintenancePlaceOfDelivery;
  settingCbMaintenanceFinalDestination;
  settingCbMaintenanceCommodity;
  settingCbMaintenancePOT1;
  settingCbMaintenancePOT2;
  settingCbMaintenancePOT3;
  settingCbMaintenanceReceiptType;
  settingCbMaintenanceDeliveryType;
  settingCbMaintenanceAdhocVessel;
  settingCbMaintenanceAdhocVoyage;
  settingCbMaintenancePORTerminal;
  settingCbMaintenancePODLTerminal;
  settingCbMaintenancePOLTerminal;
  settingCbMaintenancePOT1Terminal;
  settingCbMaintenancePODTerminal;
  settingCbMaintenanceFDESTTerminal;
  settingCbAcctPIC;
  settingGridPrepaid;
  settingGridCollect;

  settingCbMaintenanceDetailCharge;
  settingCbMaintenanceDetailCurrency;
  settingCbMaintenanceDetailPC;
  settingCbMaintenanceDetailPlaceOfPayment;
  settingCbMaintenanceDetailPayer;

  setting_nctFormForContainerType
  settingRemarksAgency;
  settingRemarksPrincipal;


  //setting lock
  lock: boolean = false;
  lockPORTerminal: boolean = true;
  lockPOLTerminal: boolean = true;
  lockPODTerminal: boolean = true;
  lockPODLTerminal: boolean = true;
  lockPOT1Terminal: boolean = true;
  lockFDESTTerminal: boolean = true;
  lockEffectiveFrom: boolean = false;
  lockEffectiveTo: boolean = false;
  //lockMaintenanceEffectiveFrom: boolean = false;
  //lockMaintenanceEffectiveTo: boolean = false;
  lockOOGOOG: boolean = false;
  lockOOGDoorOpen: boolean = false;
  lockOOGUC: boolean = false;
  lockRemarksPrincipal:boolean = true;
  lockFullEmpty: boolean = true;
  lockDetention:boolean = false;
  lockDemurrage:boolean = false;

  //Flag active tab
  currentActiveTab = "prepaid";
  currentSelectedContainerType = "normal";



  checkLock: boolean = false;
  isErrorFlag: boolean = false;
  isError: boolean = false;
  isErrorDetail: boolean = false;
  isErrorDeleted: boolean = false;
  isUpdateDetail: boolean = false;
  isLoad: boolean = false; // used to check there is data / no data in the grid
  isCopy:boolean = false;
  isAfterSave:boolean = false;

  //types
  typeEffectiveFrom = 'calendar';
  typeEffectiveTo = 'calendar';

  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new FreightManagement();
  modelDetail = new FreightDetail();
  modelDetailList: FreightDetail[] = [];
  modelDelete = new FreightManagement();

  containerTypeList: any[] = [];
  freightManagementKeyRetrieveExpiryOn: string = "";
  freightManagementKeyRetrieve: string = "";
  freightManagementRetrieve: string = "";
  freightDetailsRetrieve: string = "";
  isValidRetrieve:string = "";

  map = new Map();

  validatorRules = {};
  appStatusLabel:string="";

  constructor(private cdr: ChangeDetectorRef, private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'NCT', event: 'nct', icon: 'copy'},
        {name: 'Save', event: 'save', icon: 'save'},
        {name: 'Save & Copy', event: 'copy', icon: 'copy'},
        {name: 'Delete', event: 'delete', icon: 'minus circle'},
        /*{ name: 'Invalid',     event: 'invalid',    icon: 'minus circle' },*/
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Create', event: 'create', icon: 'plus'},
        {name: 'Print', event: 'print', icon: 'file outline'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'}
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Freight(MRG) Application'
    }

    this.settingCbFreightParty = {
      id: 'cbFreightParty',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Freight Party',
      title: 'customerName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Customer Name', field: 'customerName', width: 300},
        {header: 'Customer Code', field: 'customerCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbPlaceOfDelivery = {
      id: 'cbPlaceOfDelivery',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Place of Delivery',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbContainerOwnership = {
      id: 'cbContainerOwnership',
      type: 'select', // search | select | select input
      url: 'assets/json/container_ownership_contract_rate.json',
      placeholder: 'Container OwnerShip',
      code: 'ctrOwnerCode',
      title: 'ctrOwnerName'

    }

    this.settingCbPlaceOfReceipt = {
      id: 'cbPlaceOfReceipt',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Place of Receipt',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPortOfTransshipment = {
      id: 'cbPortOfTransshipment',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Transshipment',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbDGClass = {
      id: 'cbDGClass',
      type: 'select', // search | select | select input
      url: 'assets/json/dg_class_contract_rate.json',
      placeholder: 'ALL(DG + NON DG)',
      code: 'dgClassCode',
      title: 'dgClassName'
    }


    this.settingCbPortOfLoad = {
      id: 'cbPortOfLoad',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Load',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbFinalDestination = {
      id: 'cbFinalDestination',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Final Destination',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbReceiptType = {
      id: 'cbReceiptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: 'ALL',
      title: 'receiptTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 300},
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbContainerType = {
      id: 'cbContainerType',
      type: 'select', // search | select | select input
      url: 'assets/json/containerType_freight.json',
      placeholder: 'ALL',
      code: 'containerTypeCode',
      title: 'containerType'
    }


    this.settingCbPortOfDischarge = {
      id: 'cbPortOfDischarge',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port Of Discharge',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbContainerStatus = {
      id: 'cbContainerStatus',
      type: 'select', // search | select | select input
      url: 'assets/json/containerStatusContractRate.json',
      placeholder: 'ALL',
      code: 'ctrStatusCode',
      title: 'ctrStatusName',
    }

    this.settingCbDeliveryType = {
      id: 'cbDeliveryType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}',
      maxChars: 1,
      template: 'grid', // default
      placeholder: 'ALL',
      title: 'deliveryTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Delivery Type Name', field: 'deliveryTypeName', width: 300},
        {header: 'Delivery Type Code', field: 'deliveryTypeCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbPrepaidStatus = {
      id: 'cbPrepaidStatus',
      type: 'select', // search | select | select input
      url: 'assets/json/prepaidStatusContractRate.json',
      placeholder: 'ALL',
      code: 'prepaidStatusCode',
      title: 'prepaidStatusName',
    }

    this.settingCbCollectStatus = {
      id: 'cbCollectStatus',
      type: 'select', // search | select | select input
      url: 'assets/json/collectStatusContractRate.json',
      placeholder: 'ALL',
      code: 'collectStatusCode',
      title: 'collectStatusName',
    }

    this.settingMainGrid = {
      url: this.configService.config.BASE_API.toString() + '/PFCFreightApplication/findGeneral',
      page: 10,
      columns: [
        {header: 'S', field: 'pfcAppStatus', width: 75},
        {header: 'Freight Ref.', field: 'pfcRecordId', width: 200},
        {header: 'F/P', field: 'pfcFP', width: 200},
        {header: 'F/C', field: 'pfcFC', width: 200},
        {header: 'Freight Party', field: 'pfcFreightPartyName', width: 200},
        {header: 'C/Type', field: 'pfcCTypeName', width: 200},
        {header: 'C/S', field: 'pfcCS', width: 200},
        {header: 'Place of Receipt', field: 'pfcPlaceOfReceiptName', width: 200},
        {header: 'Port of Loading', field: 'pfcPortOfLoadingName', width: 200},
        {header: 'Port of Discharge', field: 'pfcPortOfDischargeName', width: 200},
        {header: 'Place of Delivery', field: 'pfcPlaceOfDeliveryName', width: 200},
        {header: 'Final Destination', field: 'pfcFinalDestinationName', width: 200},
        {header: 'POT (1)', field: 'pfcPortOfTransshipment1Name', width: 200},
        {header: 'Effective From', field: 'pfcEffectiveDate', width: 200},
        {header: 'Expiry On', field: 'pfcExpiryDate', width: 200},
        {header: 'R/Type', field: 'pfcReceiptTypeCode', width: 200},
        {header: 'D/Type', field: 'pfcDeliveryTypeCode', width: 200},
        {header: 'DG Class', field: 'pfcDGClass', width: 200},
        {header: 'AdHoc Info', field: 'pfcAdHocInfo', width: 200},
        {header: 'Vessel', field: 'pfcVesselName', width: 200},
        {header: 'Voyage', field: 'pfcVoyage', width: 200},
        {header: 'POT (2)', field: 'pfcPortOfTransshipment2Name', width: 200},
        {header: 'POT (3)', field: 'pfcPortOfTransshipment3Name', width: 200},
        {header: 'Shipment Type', field: 'pfcShipmentType', width: 200},
        {header: 'DET', field: 'pfcDetention', width: 200},
        {header: 'DEM', field: 'pfcDemurrage', width: 200},
        {header: 'Commodity', field: 'pfcCommodityName', width: 200},
        {header: 'Commissionable', field: 'pfcCommission', width: 200},
        {header: 'Requested By', field: 'pfcUserTransmitted', width: 200},
        {header: 'Requested Date', field: 'pfcDateTransmitted', width: 200},
        {header: 'Approved By', field: 'pfcApprovedBy', width: 200},
        {header: 'Approved Date', field: 'pfcApprovedDate', width: 200},
        {header: 'Modified By', field: 'pfcModifiedBy', width: 200},
        {header: 'Modified Date', field: 'pfcModifiedDate', width: 200},
        {header: 'Valid', field: 'pfcIsValid', width: 200},
        {header: 'Invalid By', field: 'pfcInvalidBy', width: 200},
        {header: 'Invalid Date', field: 'pfcInvalidDate', width: 200},
        {header: 'Deleted', field: 'pfcIsDeleted', width: 200},
        {header: 'Deleted By', field: 'pfcDeletedBy', width: 200},
        {header: 'Deleted Date', field: 'pfcDeletedDate', width: 200},
        {header: 'Deleted Reason', field: 'pfcDeletedReason', width: 200},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: 'pfcDateModified',
      sortingDirection: 'DESC'
    }


    this.settingCbPORTerminal = {
      id: 'cbPORTerminal',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search POR Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbPODLTerminal = {
      id: 'cbPODLTerminal',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search PODL Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbPOLTerminal = {
      id: 'cbPOLTerminal',
      type: 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search POL Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbPOT1Terminal = {
      id: 'cbPOT1Terminal',
      type: 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search POT1 Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbPODTerminal = {
      id: 'cbPODTerminal',
      type: 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search POD Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbFDESTTerminal = {
      id: 'cbFDESTTerminal',
      type: 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/Y/locationName/{query}/1/100/ASC/locationName',
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search POD Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingDateEffectiveFrom = {
      id: 'dateEffectiveFrom',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Effective From',
      customtext: '',
    }
    this.settingDateEffectiveTo = {
      id: 'dateEffectiveTo',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Expiry On',
      customtext: '',
    }

    this.settingCbValidInvalid = {
      id: 'cbValidInValid',
      type: 'select', // search | select | select input
      url: 'assets/json/ValidInValidStatus_freight.json',
      placeholder: 'ALL',
      code: 'isValidCode',
      title: 'isValidName'
    }

    this.settingCbAppStatus={
      id          : 'cbAppStatus',
      type        : 'select', // search | select | select input| search enter
      url         : 'assets/json/pfc-approval/enumAppStatus.json',
      placeholder : 'ALL',
      code        : 'appStatusCode',
      title       : 'appStatusName',
    }
    /***
     * Maintenance section
     */
    this.settingCbMaintenanceFreightParty = {
      id: 'cbMaintenanceFreightParty',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Freight Party',
      title: 'customerName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Customer Name', field: 'customerName', width: 300},
        {header: 'Customer Code', field: 'customerCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbMaintenanceShipper = {
      id: 'cbMaintenanceShipper',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Shipper',
      title: 'customerName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Customer Name', field: 'customerName', width: 300},
        {header: 'Customer Code', field: 'customerCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbMaintenancePlaceOfReceipt = {
      id: 'cbMaintenancePlaceOfReceipt',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Place of Receipt',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePortOfLoad = {
      id: 'cbMaintenancePortOfLoad',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Load',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePortOfDischarge = {
      id: 'cbMaintenancePortOfDischarge',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Discharge',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePlaceOfDelivery = {
      id: 'cbMaintenancePlaceOfDelivery',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Place of Delivery',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceFinalDestination = {
      id: 'cbMaintenanceFinalDestination',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Final Destination',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePOT1 = {
      id: 'cbMaintenancePOT1',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Transshipment 1',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePOT2 = {
      id: 'cbMaintenancePOT2',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Transshipment 2',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePOT3 = {
      id: 'cbMaintenancePOT3',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Port of Transshipment 3',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceReceiptType = {
      id: 'cbMaintenanceReceiptType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars: 2,
      template: 'grid', // default
      placeholder: 'CY',
      title: 'receiptTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 200},
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 200},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceDeliveryType = {
      id: 'cbMaintenanceDeliveryType',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}',
      maxChars: 2,
      template: 'grid', // default
      placeholder: 'CY',
      title: 'deliveryTypeCode', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Receipt Type Name', field: 'deliveryTypeName', width: 200},
        {header: 'Receipt Type Code', field: 'deliveryTypeCode', width: 200},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceAdhocVessel = {
      id: 'cbMaintenanceAdhocVessel',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Vessel',
      title: 'vesselName',
      description: '',
      isMark: true,
      columns: [
        {header: 'Vessel Code', field: 'vesselCode', width: 200},
        {header: 'Vessel Name', field: 'vesselName', width: 350},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingCbMaintenanceAdhocVoyage = {
      id: 'cbMaintenanceAdhocVoyage',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search Voyage',
      title: 'voyage',
      description: '',
      isMark: true,
      columns: [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }


    this.settingCbMaintenancePORTerminal = {
      id: 'cbMaintenancePORTerminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'POR Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePODLTerminal = {
      id: 'cbMaintenancePODLTerminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'PODL Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePOLTerminal = {
      id: 'cbMaintenancePOLTerminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'POL Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePOT1Terminal = {
      id: 'cbMaintenancePOT1Terminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'POT 1 Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenancePODTerminal = {
      id: 'cbMaintenancePODTerminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'POD Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceFDESTTerminal = {
      id: 'cbMaintenanceFDESTTerminal',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'FDEST Terminal',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }


    this.settingCbMaintenanceCommodity = {
      id: 'cbMaintenanceCommodity',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Commodity',
      title: 'commodityName', // variable name
      description: '',
      isMark: true,
      columns: [
        {header: 'Commodity Name', field: 'commodityName', width: 300},
        {header: 'Commodity Code', field: 'commodityCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }


    this.settingGridPrepaid = {
      id: 'gridPrepaid',
      url: '',
      page: 10,
      columns: [
        {header: 'Seq.', field: 'no', width: 50},
        {header: 'FAI', field: 'pfcDetailFAIGroup', width: 75},
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {header: 'Charge Name', field: 'pfcDetailChargeName', width: 200, type: 'string'},
        {header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type: 'string'},
        //{header: 'C/Type Code', field: 'pfcDetailContainerType', width: 100},
        {header: 'C/Type', field: 'pfcDetailContainerTypeName', width: 100},
        {header: 'Currency', field: 'pfcDetailCurrency', width: 50, type: 'string'},
        {header: 'D20', field: 'pfcDetailD20', width: 50},
        {header: 'H20', field: 'pfcDetailH20', width: 50},
        {header: 'D40', field: 'pfcDetailD40', width: 50},
        {header: 'H40', field: 'pfcDetailH40', width: 50},
        {header: 'D45', field: 'pfcDetailD45', width: 50},
        {header: 'H45', field: 'pfcDetailH45', width: 50},
        {header: 'P/C', field: 'pfcDetailPC', width: 50},
        {header: 'Place of Payment', field: 'pfcDetailPlaceOfPaymentName', width: 200, type: 'string'},
        {header: 'Payer', field: 'pfcDetailPayerName', width: 200, type: 'string'},
        {header: 'OH', field: 'pfcDetailOogOH', width: 50},
        {header: 'OWL', field: 'pfcDetailOogOWL', width: 50},
        {header: 'OWR', field: 'pfcDetailOogOWR', width: 50},
        {header: 'OLF', field: 'pfcDetailOogOLF', width: 50},
        {header: 'OLB', field: 'pfcDetailOogOLB', width: 50},
        {header: 'Kill Slot', field: 'pfcDetailKillSlot', width: 100},
        {header: 'Door Open', field: 'pfcDetailOogDoorOpenName', width: 100},
        {header: 'UCH', field: 'pfcDetailOogUcH', width: 50},
        {header: 'UCL', field: 'pfcDetailOogUcL', width: 50},
        {header: 'UCW', field: 'pfcDetailOogUcW', width: 50},
        {header: '/M3', field: 'pfcDetailM3', width: 50},
        {header: '/KT', field: 'pfcDetailKT', width: 50},
        {header: '/RT', field: 'pfcDetailRT', width: 50},
        {header: 'Min Charge', field: 'pfcDetailMinCharge', width: 100},
        {header: 'Surcharge Id', field: 'pfcSurchargeId', width: 100},
      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'no',
      sortingDirection: 'ASC'
    }

    this.settingGridCollect = {
      id: 'gridCollect',
      url: '',
      page: 10,
      columns: [
        {header: 'Seq.', field: 'no', width: 50},
        {header: 'FAI', field: 'pfcDetailFAIGroup', width: 75},
        //{header: 'Status', field: 'pfcDetailStatus', width: 75},
        //{header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type:'string'},
        {header: 'Charge Name', field: 'pfcDetailChargeName', width: 200, type: 'string'},
        {header: 'Charge Code', field: 'pfcDetailChargeCode', width: 100, type: 'string'},
        //{header: 'C/Type Code', field: 'pfcDetailContainerType', width: 100},
        {header: 'C/Type', field: 'pfcDetailContainerTypeName', width: 100},
        {header: 'Currency', field: 'pfcDetailCurrency', width: 50, type: 'string'},
        {header: 'D20', field: 'pfcDetailD20', width: 50},
        {header: 'H20', field: 'pfcDetailH20', width: 50},
        {header: 'D40', field: 'pfcDetailD40', width: 50},
        {header: 'H40', field: 'pfcDetailH40', width: 50},
        {header: 'D45', field: 'pfcDetailD45', width: 50},
        {header: 'H45', field: 'pfcDetailH45', width: 50},
        {header: 'P/C', field: 'pfcDetailPC', width: 50},
        {header: 'Place of Payment', field: 'pfcDetailPlaceOfPaymentName', width: 200, type: 'string'},
        {header: 'Payer', field: 'pfcDetailPayerName', width: 200, type: 'string'},
        {header: 'OH', field: 'pfcDetailOogOH', width: 50},
        {header: 'OWL', field: 'pfcDetailOogOWL', width: 50},
        {header: 'OWR', field: 'pfcDetailOogOWR', width: 50},
        {header: 'OLF', field: 'pfcDetailOogOLF', width: 50},
        {header: 'OLB', field: 'pfcDetailOogOLB', width: 50},
        {header: 'Kill Slot', field: 'pfcDetailKillSlot', width: 100},
        {header: 'Door Open', field: 'pfcDetailOogDoorOpenName', width: 100},
        {header: 'UCH', field: 'pfcDetailOogUcH', width: 50},
        {header: 'UCL', field: 'pfcDetailOogUcL', width: 50},
        {header: 'UCW', field: 'pfcDetailOogUcW', width: 50},
        {header: '/M3', field: 'pfcDetailM3', width: 50},
        {header: '/KT', field: 'pfcDetailKT', width: 50},
        {header: '/RT', field: 'pfcDetailRT', width: 50},
        {header: 'Min Charge', field: 'pfcDetailMinCharge', width: 100},
        {header: 'Surcharge Id', field: 'pfcSurchargeId', width: 100},

      ],
      buttons: [
        {name: 'Edit', event: 'edit', enabled: true}
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: 'no',
      sortingDirection: 'ASC'
    }


    //detail section
    this.settingCbMaintenanceDetailCharge = {
      id: 'cbMaintenanceDetailCharge',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCharges/findByComboBoxControlNonAdhoc/chargeCodeOrName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Charge',
      title: 'chargeName', // variable name
      description: '',
      isMark: true,
      columns: [
        //{header: 'Charge Id', field: 'chargeId', width:100},
        {header: 'Charge Code', field: 'chargeCode', width: 150},
        {header: 'Charge Name', field: 'chargeName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbMaintenanceDetailCurrency = {

      id: 'cbMaintenanceDetailCurrency',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneral/Y/currencyCode/{query}/1/100/ASC/currencyCode',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Curr.',
      title: 'currencyCode', // variable name
      description: '',
      isMark: true,
      columns: [
        //{header: 'Currency ID', field: 'currencyId', width:100},
        {header: 'Currency Code', field: 'currencyCode', width: 300},
        {header: 'Currency Name', field: 'currencyName', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingCbMaintenanceDetailPC = {
      id: 'cbMaintenanceDetailPC',
      type: 'select', // search | select | select input
      url: 'assets/json/prepaidCollect.json',
      placeholder: 'P/C',
      code: 'prepaid_collect',
      title: 'prepaid_collect'


    }

    this.settingCbMaintenanceDetailPlaceOfPayment = {
      id: 'cbMaintenanceDetailPlaceOfPayment',
      type: 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}',
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Place of Payment',
      title: 'locationName', // variable name
      description: '',
      isMark: true,
      columns: [
        //{header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]
    }

    this.settingCbMaintenanceDetailPayer = {
      id: 'cbMaintenanceDetailPayer',
      type: 'search enter', // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerPayer/{query}/1/100',
      url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search Payer',
      title: 'customerName', // variable name
      description: '',
      isMark: true,
      columns: [
        //{header: 'Customer Id', field: 'customerId', width:100},
        {header: 'Customer Name', field: 'customerName', width: 300},
        {header: 'Customer Code', field: 'customerCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100}]

    }

    this.settingDateMaintenanceEffectiveFrom = {
      id: 'dateMaintenanceEffectiveFrom',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Effective From',
      customtext: '',
    }

    this.settingDateMaintenanceEffectiveTo = {
      id: 'dateMaintenanceEffectiveTo',
      type: 'date',
      format: 'yyyy-mm-dd',
      placeholder: 'Expiry On',
      customtext: '',
    }

    this.setting_nctFormForContainerType = {
      id: 'nctform_ctrType',
      type: 'select', // search | select | select input
      url: '',
      placeholder: 'Select',
      code: 'containerTypeName',
      title: 'containerTypeName' // variable name
    };

    this.settingRemarksAgency = {
      id: 'txtRemarksAgency',
      name: 'pfcRemarksAgency',
      placeholder: 'Remarks Agency',
      cols: 30,
      rows: 10,
      maxlength: 255,
      border: 500,
    }

    this.settingRemarksPrincipal = {
      id: 'txtRemarksPrincipal',
      name: 'pfcRemarksPrincipal',
      placeholder: 'Remarks Principal',
      cols: 30,
      rows: 10,
      maxlength: 255,
      border: 500,
    }

    this.settingCbAcctPIC = {
      id: 'cbAcctPIC',
      type: 'select', // search | select | select input| search enter
      urlType: 'dynamic',
      url: this.configService.config.BASE_API.toString() + '/MasterAccountPic/findAcctPIC',
      template: 'grid', // default
      placeholder: 'Acct PIC',
      code: 'userName',
      title: 'userName',
      description: '',
      isMark: true,
      allowRemove: true,
    }


    /***
     * End of Maintenance Section
     */
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('.menu .item').tab();

    this.modelDetailList = [];


    /*
     this.model.pfcUserCreated = this.cookieService.getName();
     this.model.pfcDateCreated =  this.genericUtil.getPSIDate();
     */

    if (this.modeForm == "freightFlagStatus") {
      this.pfcToolbar.label = "Freight Application MRG Flag Status";
      this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
      this.cbReceiptType.setValue("CY");
      this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
      this.cbDeliveryType.setValue("CY");

      let currentDate = new Date();
      let theDate = this.genericUtil.pad2(currentDate.getFullYear()) + '-' + this.genericUtil.pad2(currentDate.getMonth() + 1) + '-' + this.genericUtil.pad2(currentDate.getDate());

      this.cbContainerStatus.setValue("ALL");
      this.cbContainerOwnership.setValue("ALL");
      this.cbDGClass.setValue("ALL");
      this.cbContainerType.setValue("ALL");
      this.cbValidInvalid.setValue("ALL");
      this.cbAppStatus.setValue("ALL");
      this.model.pfcAppStatus = "ALL";

      //this.model.pfcEffectiveFrom = theDate;

      //reset isLoad
      this.isLoad = false;

    } else {
      this.map.set(this.model.pfcContainerType, this.modelDetailList);
    }

    //hide button
    this.invisibleToolbarButtons = "save,copy,nct,delete,invalid";
    this.cdr.detectChanges();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.handleRetrieve();
        break;
      case 'create' :
        this.handleCreate();
        break;
      case 'save' :
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        this.handleClose();
        break;
      case 'print':
        this.message("information", "Information", "Not yet implemented.", "okonly", {ok: ''});
        break;
      case 'copy':
        this.handleSaveCopy();
        break;
      case 'nct':
        this.handleNCT();
        break;
      case 'delete' :
        this.handleDelete();
        break;
    }
  }

  eventMessage(event: any) {
    // console.log('**event message **')
    // console.log(event);
    if (event != '') {
      eval(event);
    }

    // console.log('current --> ' + this.dialogMultiple.current);
    // console.log('map size --> ' + this.dialogMultiple.settings.length);

    if (this.dialogMultiple.current == this.dialogMultiple.settings.length) {

      this.dialogMultiple.current = undefined;

      if (this.map.size == 0) {
        // console.log('not save');
        this.Check_MapSize();
        return;

      }
      else if (this.map.size > 0) {
        // console.log('save processing');
        this.populateData();
        this.saveEvent();
      }
    }

  }

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event: eve};
    $('#dialog').modal('setting', 'closable', false).modal('show');
  }

  handleNCT() {
    // console.log(String(this.model.pfcContainerType).toUpperCase());

    var self = this;
    this.nctform_ctrType.listStore.store = [];

    let no: number = 0;
    this.map.forEach(function (item, key, mapObj) {
      if (String(self.model.pfcContainerType).toUpperCase() != key) {
        // console.log(key);
        var ct: string
        switch (key) {
          case "TNK":
            ct = 'TANK';
            break;
          case "OPT":
            ct = 'OPEN TOP';
            break;
          case "FLR":
            ct = 'FLAT RACK';
            break;
          case "PLT":
            ct = 'PLATFORM';
            break;
          case "REF":
            ct = 'REEFER';
            break;
          case "DRY":
            ct = 'REEFER DRY';
            break;
          case "UC":
            ct = 'UC';
            break;
          default:
            ct = 'NORMAL';
            break;
        }
        self.nctform_ctrType.listStore.addData({"no": no++, "code": key, "title": ct});
      }
    });

    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('show');
    */
    this.genericUtil.showDialog('NCTForm', "NCT Form", 300, 200)
  }

  onNCT_Save() {
    this.message('information', 'Confirm', 'Do you really want to CREATE a New Cntr. Type to the current record?', 'yesno', {yes: 'this.onNCT_Save_Process()', no: ''});
  }

  onNCT_Save_Process() {
    var ct: any = this.nctform_ctrType.getValue();
    // console.log(ct['title']); //NORMAL
    // console.log('container from NCT : ' + String(this.model.pfcContainerType).toUpperCase()); //TANK

    var result: FreightDetail[] = this.map.get(ct['code'])
    // console.log("result : ");
    // console.log(result);

    var result2: FreightDetail[] = [];
    result.forEach(element => {
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = String(this.model.pfcContainerType).toUpperCase();
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;


      if (String(this.model.pfcContainerType).toUpperCase() == "TNK") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogDoorOpen = "N";

        if(element.pfcDetailOogDoorOpen == "N" && (element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0)){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "REF") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;
        x.pfcDetailOogDoorOpen = "N";

        if(element.pfcDetailOogDoorOpen == "N" && (element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0)){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "OPT") {
        x.pfcDetailOogDoorOpen = "N";

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "FLR") {
        x.pfcDetailOogDoorOpen = "N";

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "PLT") {
        x.pfcDetailOogDoorOpen = "N";

        if(element.pfcDetailOogDoorOpen == "N"){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "DRY") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;

        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }
      } else if (String(this.model.pfcContainerType).toUpperCase() == "UC") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;

        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }

      } else if (String(this.model.pfcContainerType).toUpperCase() == "STD") {
        x.pfcDetailOogOH = 0;
        x.pfcDetailOogOWL = 0;
        x.pfcDetailOogOWR = 0;
        x.pfcDetailOogOLF = 0;
        x.pfcDetailOogOLB = 0;

        if(element.pfcDetailOogOH == 0 &&
          x.pfcDetailOogOWL == 0 &&
          x.pfcDetailOogOWR == 0 &&
          x.pfcDetailOogOLF == 0 &&
          x.pfcDetailOogOLB == 0){
          result2.push(x);
        }
      }

      //result2.push(x);
    });

    // console.log("result 1 : ");
    // console.log(result);
    // console.log("result 2 : ");
    // console.log(result2);

    if(result2.length > 0){
      this.map.set(String(this.model.pfcContainerType).toUpperCase(), result2);

      this.gridPrepaid.onClear();
      this.gridCollect.onClear();
      var detailList: any[] = this.map.get(this.model.pfcContainerType)
      if (detailList != null && detailList != undefined) {
        detailList.forEach(element => {

          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          var ct: string
          switch (x.pfcDetailContainerType) {
            case "TNK":
              ct = 'TANK';
              break;
            case "OPT":
              ct = 'OPEN TOP';
              break;
            case "FLR":
              ct = 'FLAT RACK';
              break;
            case "PLT":
              ct = 'PLATFORM';
              break;
            case "REF":
              ct = 'REEFER';
              break;
            case "DRY":
              ct = 'REEFER DRY';
              break;
            case "UC":
              ct = 'UC';
              break;
            default:
              ct = 'NORMAL';
              break;
          }
          x.pfcDetailContainerTypeName = ct;
          x.pfcDetailCopyTab = element.pfcDetailCopyTab;
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          if (x.pfcDetailPCTab == 'P') {
            this.gridPrepaid.listStore.store.push(x);
            this.gridPrepaid.loadData();
          }

          if (x.pfcDetailPCTab == 'C') {
            this.gridCollect.listStore.store.push(x);
            this.gridCollect.loadData();
          }

        });
      }

      this.message('information', "Information", " Rates Copied Successfully.", "okonly", {ok: "this.closeDialog('NCTForm')"});
      return;

    }else{
      this.message('information', "Information", "Unable to copy.", "okonly", {ok: "this.closeDialog('NCTForm')"});
      return;
    }

  }

  onNCT_Cancel(event: any) {
    /*
    $('#NCTForm')
      .modal('setting', 'closable', false)
      .modal('hide');
    */
   this.genericUtil.closeDialog('NCTForm');
  }

  value_nctFormForContainerType(event: any) {
    // console.log("selected nct value : " + event);
  }


  //used to handle retrieve event
  //When user click button retrieve the action goed to this routine

  handleRetrieve() {

    // console.log("this.model.pfcRecordId --> " + this.model.pfcRecordId);

    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.pfcEffectiveFrom = this.dateEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateEffectiveTo.getValue();

    this.mainGrid.curPage = 1;
    this.mainGrid.currentPage = 1;


    if(this.model.pfcSerchByRefNo == 'Y'){
      this.model['error-pfcRecordId'] = '';
      this.validatorRules = {
        pfcRecordId: {rules: [{type: 'empty', prompt: 'Please input Reference No.'}]},
      }
      this.isErrorFlag = this.onValidate(this.model);

      if(!this.isErrorFlag){
        this.mainGrid.onClear();
        this.mainGrid.setBody(this.model);
        this.isLoad = true;
        this.mainGrid.loadData();
      }
    }else{
        this.model['error-pfcRecordId'] = '';
        this.validatorRules = {}
        this.isErrorFlag = false;

        this.mainGrid.onClear();
        this.mainGrid.setBody(this.model);
        this.isLoad = true;
        this.mainGrid.loadData();
    }
  }

  //user to save the data
  handleSave() {
    this.isCopy = false;
    this.model.isCopy = false;
    this.onSave();
  }

  handleSaveCopy() {
    this.isCopy = true;
    this.model.isCopy = true;
    this.onSave();
 }

 validateProsess(){
    this.genericUtil.showLoader();
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/validateSave/', this.model).subscribe((resp) => {
      let result = resp.json();
      if (result.status == 1 || result.status == 2 || result.status == 3) {
        this.genericUtil.hideLoader();
        this.message('information', "Information", result.message, "okonly", {ok: ""});
        return;
      } else {
        this.UpdateMap();
      }
    })

  }

  onSave(){
    //set the office code
    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();

    //set effective from and to
    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    //set user created and modified
    let userId = this.cookieService.getName().toUpperCase();
    let theDate = this.genericUtil.getPSIDate();
    if (this.model.pfcRecordId == null || this.model.pfcRecordId == "" || this.model.pfcRecordId == undefined) {
      this.model.pfcUserCreated = userId;
      this.model.pfcDateCreated = theDate;
      this.model.pfcUserModified = userId;
      this.model.pfcDateModified = theDate;
    } else {
      this.model.pfcUserModified = userId;
      this.model.pfcDateModified = theDate;
    }

    this.model.pfcPrincipalRemarks = this.txtRemarksPrincipal.getValue();
    this.model.pfcAgentRemarks = this.txtRemarksAgency.getValue();

    this.setValidatorSave();
    this.model['effectiveDateExceedValidator'] = this.checkEffectiveDateExceedValidator();
    this.model['expiryDateValidator'] = this.checkExpiryDateValidator();
    this.model['samePORValidator'] = this.checkSamePORValidator();
    this.model['samePOLValidator'] = this.checkSamePOLValidator();
    this.model['samePODValidator'] = this.checkSamePODValidator();
    this.model['samePODLValidator'] = this.checkSamePODLValidator();
    this.model['sameFDestValidator'] = this.checkSameFDestValidator();
    this.model['blankFreightChargesValidator'] = this.checkBlankFreightChargesValidator();
    this.model['missingMainChargesValidator'] = this.checkMissingMainChargesValidator();
     this.model['mainChargesOnlyOneValidator'] = this.checkMainChargesOnlyOneValidator();
    this.model['blankDetentionRequestValidator'] = this.checkBlankDetentionRequestValidator();
    this.model['blankDemurrageRequestValidator'] = this.checkBlankDemurrageRequestValidator();
    this.model['blankAdhocVoyageValidator'] = this.checkBlankAdhocVoyageValidator();

     console.log("** before validate **");
    // console.log(this.model);
     console.log(this.map);

    //begin of main handle save

    this.isError = this.onValidate(this.model);
    if (this.isError) {
    window.scrollTo(0,0);
    }else{


    let modelKey = new FreightManagement;
    modelKey.pfcOfficeCode = this.model.pfcOfficeCode;
    modelKey.pfcFreightPartyId = this.model.pfcFreightPartyId;
    // modelKey.pfcShipperId = this.model.pfcShipperId;
    modelKey.pfcPlaceOfReceiptCode = this.model.pfcPlaceOfReceiptCode;
    modelKey.pfcLoadPortCode = this.model.pfcLoadPortCode;
    modelKey.pfcDischargePortCode = this.model.pfcDischargePortCode;
    modelKey.pfcPlaceOfDeliveryCode = this.model.pfcPlaceOfDeliveryCode;
    modelKey.pfcFinalDestinationCode = this.model.pfcFinalDestinationCode;
    modelKey.pfcPortOfTransshipment1Code = this.model.pfcPortOfTransshipment1Code;
    modelKey.pfcPortOfTransshipment2Code = this.model.pfcPortOfTransshipment2Code;
    modelKey.pfcPortOfTransshipment3Code = this.model.pfcPortOfTransshipment3Code;
    modelKey.pfcCommodityId = this.model.pfcCommodityId;
    modelKey.pfcReceiptTypeCode = this.model.pfcReceiptTypeCode;
    modelKey.pfcDeliveryTypeCode = this.model.pfcDeliveryTypeCode;
    modelKey.pfcContainerOwnership = this.model.pfcContainerOwnership;
    modelKey.pfcContainerStatus = this.model.pfcContainerStatus;
    modelKey.pfcShipmentType = this.model.pfcShipmentType;
    modelKey.pfcVesselId = this.model.pfcVesselId;
    modelKey.pfcVoyage = this.model.pfcVoyage;
    modelKey.pfcDetention = this.model.pfcDetention;
    modelKey.pfcDetentionRequest = this.model.pfcDetentionRequest;
    modelKey.pfcDemurrage = this.model.pfcDemurrage;
    modelKey.pfcDemurrageRequest = this.model.pfcDemurrageRequest;
    modelKey.pfcIhc = this.model.pfcIhc;
    modelKey.pfcDgClass = this.model.pfcDgClass;
    modelKey.pfcIsValid = this.model.pfcIsValid;
    modelKey.pfcEffectiveFrom = this.model.pfcEffectiveFrom;
    modelKey.pfcEffectiveTo = this.model.pfcEffectiveTo;

    let currentFreightManagementKeyExpiryOn = this.getFreightManagementKeyStringExpiryOn(modelKey);
    let currentFreightManagementKey = this.getFreightManagementKeyString(modelKey);

    let currentFreightManagement = this.getFreightManagementString(this.model);
    let isValidCurrent = modelKey.pfcIsValid;

    let currentFreightDetails = "";
    let currentContainerTypeList: any[] = [];
    this.model.pfcDetails = [];

    this.map.forEach((value: FreightDetail[], key: string) => {
      // console.log(key);
      currentContainerTypeList.push(key);

      value.forEach(element => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

        currentFreightDetails += this.getFreightDetailString(x);
        this.model.pfcDetails.push(x);
      });
    });

    // console.log("** currentContainerTypeList **");
    // console.log(currentContainerTypeList);

    // console.log("** retrieveFreightManagement **");
    // console.log(this.freightManagementRetrieve);
    // console.log("** currentFreightManagement **");
    // console.log(currentFreightManagement);
    // console.log("***************************************************************************************************************************************");
    // console.log("** retrieveFreightDetails **");
    // console.log(this.freightDetailsRetrieve);
    // console.log("** currentFreightDetails **");
    // console.log(currentFreightDetails);

    if (this.freightManagementRetrieve == currentFreightManagement && this.freightDetailsRetrieve == currentFreightDetails) {
      this.message('information', "Information", "No changes has been detected.", "okonly", {ok: ""});
      return;

    } else {

        if (this.freightManagementRetrieve != currentFreightManagement) {
          console.log("** header changed **");


          console.log(this.model)
          console.log("** is After save **")
          console.log(this.isAfterSave)

          if(this.isAfterSave){
            this.model.pfcRecordId = "";
          }

          // **************** Update Flow dari sini **************************************
          console.log("** freightManagementKeyRetrieve **");
          console.log(this.freightManagementKeyRetrieve);
          console.log("** currentFreightManagementKey **");
          console.log(currentFreightManagementKey);

          console.log("** freightManagementKeyRetrieve Expiry **");
          console.log(this.freightManagementKeyRetrieveExpiryOn);
          console.log("** currentFreightManagementKey Expiry **");
          console.log(currentFreightManagementKeyExpiryOn);

          if (this.model.pfcRecordId == "") {
            console.log("#1")

            this.message('information', "Information", "Do you really want to create a New record?", "okcancel", { ok: "this.ClearRecordId();this.validateProsess();", cancel: "this.genericUtil.hideLoader();" });
            return;


          } else {

              if (this.freightManagementKeyRetrieve == currentFreightManagementKey && this.freightManagementKeyRetrieveExpiryOn == currentFreightManagementKeyExpiryOn) {

                  if(currentContainerTypeList > this.containerTypeList){
                    //new CType
                    this.message('information', "Information","Do you really want to Create a new cntr type to the existing record?", "okcancel",{ok:"this.validateProsess();", cancel:"this.genericUtil.hideLoader();"});
                    return;

                  }else{
                      //check Just Invalid or not
                      console.log(this.isValidRetrieve);
                      console.log(isValidCurrent);
                      if(this.isValidRetrieve != isValidCurrent){
                        this.message('information', "Information","Are you sure to update the existing Reference No ?", "okcancel",{ok:"this.invalidEvent();", cancel:"this.genericUtil.hideLoader();"});
                        return;
                      }else{
                        this.message('information', "Information","Are you sure to update the existing Reference No ?", "okcancel",{ok:"this.validateProsess();", cancel:"this.genericUtil.hideLoader();"});
                        return;
                      }
                  }

              } else {


                  if(this.model.pfcAppStatus == "A"){
                      if(this.freightManagementKeyRetrieve == currentFreightManagementKey && this.freightManagementKeyRetrieveExpiryOn != currentFreightManagementKeyExpiryOn) {
                        console.log("#A1")
                        this.message('information', "Information", "Not Allowed", "okonly", { ok: "this.genericUtil.hideLoader();" });
                        return;
                      }else{
                        console.log("#A2")

                        this.message('information', "Information","Do you really want to create a New record?", "okcancel",{ok:"this.ClearRecordId();this.validateProsess();", cancel:"this.genericUtil.hideLoader();"});
                        return;
                      }
                  }else{
                    console.log("#2")
                    this.message('information', "Information","Do you really want to create a New record?", "okcancel",{ok:"this.ClearRecordId();this.validateProsess();", cancel:"this.genericUtil.hideLoader();"});
                    return;
                  }

              }

          }

            /*
            //New
            this.genericUtil.showLoader();
            this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/validateSave/', this.model).subscribe((resp) => {

              if (resp.status == 200) {
                  let result = resp.json();

                  if (result.status == 1 || result.status == 2) {
                      this.genericUtil.hideLoader();
                      this.message('information', "Information", result.message, "okonly", {ok: ""});
                      return;
                  } else {

                       console.log("** freightManagementKeyRetrieve **");
                       console.log(this.freightManagementKeyRetrieve);
                       console.log("** currentFreightManagementKey **");
                       console.log(currentFreightManagementKey);

                       console.log("** freightManagementKeyRetrieve Expiry **");
                       console.log(this.freightManagementKeyRetrieveExpiryOn);
                       console.log("** currentFreightManagementKey Expiry **");
                       console.log(currentFreightManagementKeyExpiryOn);

                      if (this.model.pfcRecordId == "") {
                        console.log("#1")
                        this.message('information', "Information", "Do you really want to create a New record?", "okcancel", { ok: "this.ClearRecordId();this.UpdateMap();", cancel: "this.genericUtil.hideLoader();" });
                        return;

                      } else {


                            if (this.freightManagementKeyRetrieve == currentFreightManagementKey && this.freightManagementKeyRetrieveExpiryOn == currentFreightManagementKeyExpiryOn) {

                                if(currentContainerTypeList > this.containerTypeList){
                                  //new CType
                                  this.message('information', "Information","Do you really want to Create a new cntr type to the existing record?", "okcancel",{ok:"this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
                                  return;
                                }else{
                                  //check Just Invalid or not
                                  console.log(this.isValidRetrieve);
                                  console.log(isValidCurrent);
                                  if(this.isValidRetrieve != isValidCurrent){
                                    this.message('information', "Information","Are you sure to update the existing Reference No ?", "okcancel",{ok:"this.invalidEvent();", cancel:"this.genericUtil.hideLoader();"});
                                    return;
                                  }else{
                                    this.message('information', "Information","Are you sure to update the existing Reference No ?", "okcancel",{ok:"this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
                                    return;
                                  }
                                }

                            } else {

                              if(this.model.pfcAppStatus == "A"){
                                  if(this.freightManagementKeyRetrieve == currentFreightManagementKey && this.freightManagementKeyRetrieveExpiryOn != currentFreightManagementKeyExpiryOn) {
                                    console.log("#A1")
                                    this.message('information', "Information", "Not Allowed", "okonly", { ok: "this.genericUtil.hideLoader();" });
                                    return;
                                  }else{
                                    console.log("#A2")

                                    //this.model.pfcRecordId = "";
                                    this.message('information', "Information","Do you really want to create a New record?", "okcancel",{ok:"this.ClearRecordId();this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
                                    return;
                                  }
                              }else{
                                console.log("#2")
                                //this.model.pfcRecordId = "";
                                this.message('information', "Information","Do you really want to create a New record?", "okcancel",{ok:"this.ClearRecordId();this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
                                return;
                              }

                            }




                      }

                  }


              }

          });
          */


        }else{
          console.log("** details changed **");
          // console.log(this.containerTypeList.length + " - " + currentContainerTypeList.length);

          if(currentContainerTypeList > this.containerTypeList){
            if(this.model.pfcAppStatus == 'A'){
              this.message('information', "Information","Do You Want to Re-Request Approved Rates?", "okcancel",{ok:"this.nextProcessUpdateDetail1()", cancel:"this.genericUtil.hideLoader();"});
              return;
            }else if(this.model.pfcAppStatus == 'R'){
              this.message('information', "Information","Do You Want to Re-Request Rejected Rates?", "okcancel",{ok:"this.nextProcessUpdateDetail1()", cancel:"this.genericUtil.hideLoader();"});
              return;
            }else{
              //new CType
              this.message('information', "Information","Do you really want to Create a new cntr type to the existing record?", "okcancel",{ok:"this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
              return;
            }
          }else{
            // console.log("** details changed **");
            if(this.model.pfcAppStatus == 'A'){
              this.message('information', "Information","Do You Want to Re-Request Approved Rates?", "okcancel",{ok:"this.nextProcessUpdateDetail2()", cancel:"this.genericUtil.hideLoader();"});
              return;
            }if(this.model.pfcAppStatus == 'R'){
              this.message('information', "Information","Do You Want to Re-Request Rejected Rates?", "okcancel",{ok:"this.nextProcessUpdateDetail2()", cancel:"this.genericUtil.hideLoader();"});
              return;
            }else{
              //Update Details
              this.message('information', "Information","Do you really want to OVERWRITE the record?", "okcancel",{ok:"this.saveEvent();", cancel:""});
              return;
            }
          }
        }

      }

    }

    //end of main handle save

  }

  nextProcessUpdateDetail1(){
    setTimeout(() => {
      this.message('information', "Information","Do you really want to Create a new cntr type to the existing record?", "okcancel",{ok:"this.UpdateMap();", cancel:"this.genericUtil.hideLoader();"});
      return;
    }, 50);
  }

  nextProcessUpdateDetail2(){
    setTimeout(() => {
      this.message('information', "Information","Do you really want to OVERWRITE the record?", "okcancel",{ok:"this.saveEvent();", cancel:""});
      return;
    }, 50);
  }

  invalidEvent(){


    this.genericUtil.showLoader();
    this.genericService.POST(this.configService.config.BASE_API.toString() + "/PFCFreightApplication/save", this.model).subscribe((resp) => {

      let data = resp.json();

      if (data.status == 1 || data.status == 2) {
        this.genericUtil.hideLoader();
        this.message('information', "Information", data.message, "okonly", {ok: ""});
        return;
      }else{
        this.model.pfcRecordId = data.pfcRecordId;
        this.model.pfcEffectiveFrom = data.pfcEffectiveFrom;
        this.model.pfcEffectiveTo = data.pfcEffectiveTo;

        // console.log(this.model);
        this.genericUtil.hideLoader();
        if(this.isCopy){
          this.message('information', 'Information', "Saved successfully with Reference No " + this.model.pfcRecordId, 'okonly', {ok: 'this.loading=false;this.afterSaveCopy();'});
        }else{
          this.message('information', 'Information', "Saved successfully with Reference No " + this.model.pfcRecordId, 'okonly', {ok: 'this.loading=false;this.afterSave();'});
        }
      }
    }, error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });
}

  saveEvent(){

      //if(this.model.pfcAppStatus == 'ALL' || this.model.pfcAppStatus == 'P'){
        //set status to P
      this.model.pfcAppStatus = 'P';
      this.model.pfcIsTransmitted = 'Y';
      //}

      this.genericUtil.showLoader();
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/PFCFreightApplication/save", this.model).subscribe((resp) => {

        let data = resp.json();
        if (data.status == 1 || data.status == 2) {
          this.genericUtil.hideLoader();
          this.message('information', "Information", data.message, "okonly", {ok: ""});
          return;
        }else{

          this.model.pfcRecordId = data.pfcRecordId;
          this.model.pfcEffectiveFrom = data.pfcEffectiveFrom;
          this.model.pfcEffectiveTo = data.pfcEffectiveTo;

          // console.log(this.model);
          this.genericUtil.hideLoader();
          if(this.isCopy){
            this.message('information', 'Information', "Saved successfully with Reference No " + this.model.pfcRecordId, 'okonly', {ok: 'this.loading=false;this.afterSaveCopy();'});
          }else{
            this.message('information', 'Information', "Saved successfully with Reference No " + this.model.pfcRecordId, 'okonly', {ok: 'this.loading=false;this.afterSave();'});
          }
        }
      }, error => {
        this.loading = false;
        // console.log(error.message);
        this.info = error.message;
      });
  }

  afterSave(){
    // console.log("** after save **");
    this.isCopy = false;
    //this.afterClosedFromMaintenance();
    //do nothing for now
    //this.lock = true;


    if(this.model.pfcAppStatus == "A"){
      this.appStatusLabel = "   ---   APPROVED   ---";
    }else if(this.model.pfcAppStatus == "R"){
      this.appStatusLabel = "   ---   REJECTED   ---";
    }else{
      this.appStatusLabel = "   ---   PENDING APPROVAL   ---";
    }
    this.lock = true;
    this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
    /*
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/retrieveFreightApplicationForUpdate/' + this.model.pfcRecordId.trim() + '/' + this.model.pfcContainerType).subscribe((resp) => {
      //// console.log(resp);
      this.handleDoubleClick(resp.json()["content"]);
      this.isAfterSave = true;
    });
    */
  }

  afterSaveCopy(){
    // console.log("** after save copy **");
    //do nothing for now
    //this.lock = true;
    this.model.pfcRecordId = "";
    this.isCopy = false;
    this.isAfterSave = true;

    if (this.model.pfcEffectiveFrom !== null && this.model.pfcEffectiveFrom !== "" && this.model.pfcEffectiveFrom !== undefined) {
      this.dateMaintenanceEffectiveFrom.setValue(this.model.pfcEffectiveFrom);
    }

    this.dateMaintenanceEffectiveTo.setValue(this.model.pfcEffectiveTo);

  }


  handleDelete(){
    this.modelDelete =  new FreightManagement();
      this.message('information', 'Information', 'Are you sure want to delete this record?', 'yesno',
        {
          yes: "this.genericUtil.showDialog('DeleteForm', 'Delete Form', 300, 300)",
          no: ''
        });
  }

  deleteProcess(){

    //this.modelDelete =  new FreightManagement();
    this.modelDelete.pfcRecordId = this.model.pfcRecordId;
    this.modelDelete.pfcIsDeleted = 'Y';
    this.modelDelete.pfcUserDeleted = this.cookieService.getName().toUpperCase();
    this.modelDelete.pfcDateDeleted = this.genericUtil.getPSIDate();
    //this.modelDelete.pfcReasonDeleted = document.getElementById("reasonDelete")

    this.modelDelete['error-pfcReasonDeleted'] = '';
    this.validatorRules = {
      pfcReasonDeleted: {rules: [{type: 'empty', prompt: 'Please input Reason.'}]},
    }
    this.isErrorDeleted = this.onValidate(this.modelDelete);

    if(!this.isErrorDeleted){
      this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/delete',this.modelDelete).subscribe((resp) => {
        // console.log(resp);
        if(resp.statusText == "OK"){
          this.lock = true;
          this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
          this.message("information", "Information", "Deleted Successfully.", "okonly", {ok: ''});
        }else{

        }
        this.isErrorDeleted = false;
        this.modelDelete =  new FreightManagement();
        this.genericUtil.closeDialog('DeleteForm');
        this.gridPrepaid.loadData();
        this.gridCollect.loadData();
      });
    }
  }

  mainGridEvent(event) {
      switch (event.split(".")[0]) {
        case 'selected':
          break;
        case 'click' :
          this.disableToolbarButtons = 'delete,copy';
          var mode = event.split(".")[1].split("-")[0];
          var no = event.split(".")[1].split("-")[1];

          this.mainGrid.clearSelectedValues();
          this.mainGrid.onSelect(true, Number.parseInt(no));

          break;
        case 'afterLoad':
          if (this.mainGrid.listStore.store.length == 0) {
            if (this.isLoad) {
              this.message('information', 'Information', 'No record(s) found.', 'okonly', {ok: 'this.loading=false;this.isLoad=false;'});
            }
          }
        //this.mainGrid.onSelect(true,1);

        default:
          let strEvent: string = event;
          if (strEvent != '') {
            let arr: string[] = strEvent.split('-');
            switch (arr[0]) {
              case 'edit':
                break;
              case 'dblClick':
                //handle double Clicked
                let dt = this.mainGrid.getSelectedValues()[0];

                this.genericService.GET(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/retrieveFreightApplicationForUpdate/' + dt.pfcRecordId.trim() + '/' + dt.pfcCType).subscribe((resp) => {
                  //// console.log(resp);

                  var x:FreightManagement = new FreightManagement();
                  x = resp.json()["content"];
                  // console.log(x);
                  this.handleDoubleClick(x);
                });

                break;
              default:
                break;
            }
          }
          break;
      }
  }

  changeEventPod(event){
  }

  infoGrid(event){
  }

  initTab1(){
  }

  initTab2(){
  }

  //used when there is change is place of receipt
  changePlaceOfReceipt(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPlaceOfReceiptCode = event["locationCode"];

        //set terminal URL
        this.cbPORTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');

        //Auto set Port of Load and Port of Load Terminal
        this.model.pfcLoadPortCode = event["locationCode"];
        this.cbPortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbPortOfLoad.setValue(event["locationCode"]);
        this.cbPOLTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');

      } else {
        this.model.pfcPlaceOfReceiptCode = "";
        this.cbPORTerminal.setUrl("");
      }
  }

  changePortOfLoad(event){
      //upon changes update the terminal
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcLoadPortCode = event["locationCode"];
        this.cbPOLTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');
      } else {
        this.model.pfcLoadPortCode = "";
        this.cbPOLTerminal.setUrl("");
      }

      //revert to search by name
      this.cbPortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changePortOfDischarge(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcDischargePortCode = event["locationCode"];

        //set terminal URL
        this.cbPODTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');

        //Auto set Port of Load and Port of Load Terminal
        this.model.pfcPlaceOfDeliveryCode = event["locationCode"];
        this.cbPlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbPlaceOfDelivery.setValue(event["locationCode"]);
        this.cbPODLTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');

      } else {
        this.model.pfcDischargePortCode = "";
        this.cbPODLTerminal.setUrl("");
      }
  }

  changePlaceOfDelivery(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPlaceOfDeliveryCode = event["locationCode"];
        this.cbPODLTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');
      } else {
        this.model.pfcPlaceOfDeliveryCode = "";
        this.cbPODLTerminal.setUrl("");
      }
      this.cbPlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changePortOfTransshipment(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPortOfTransshipment1Code = event["locationCode"];
        this.cbPOT1Terminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');
      } else {
        this.model.pfcPortOfTransshipment1Code = "";
        this.cbPOT1Terminal.setUrl("");
      }
  }

  changeFinalDestination(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcFinalDestinationCode = event["locationCode"];
        this.cbFDESTTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event["locationId"] + '/[-42]');
      } else {
        this.model.pfcFinalDestinationCode = "";
        this.cbFDESTTerminal.setUrl("");
      }
  }

  changePORTerminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPORTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPORTerminalCode = "";
      }
  }

  changePODLTerminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPODLTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPODLTerminalCode = "";
      }
  }

  changePOT1Terminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPOT1TerminalCode = event["locationCode"];
      } else {
        this.model.pfcPOT1TerminalCode = "";
      }
  }

  changePOLTerminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPOLTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPOLTerminalCode = "";
      }
  }

  changePODTerminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPODTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPODTerminalCode = "";
      }
  }

  changeFDESTTerminal(event){
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcFDESTTerminalCode = event["locationCode"];
      } else {
        this.model.pfcFDESTTerminalCode = "";
      }
  }

  //used when there is change in port of transshipment
  handleCreate() {
      // console.log("** handle create **");
      this.model.pfcIsDeleted = 'N';
      this.appStatusLabel = "";
      this.model.pfcEffectiveFrom = this.dateEffectiveFrom.getValue();
      this.model.pfcEffectiveTo = this.dateEffectiveTo.getValue();
      this.cookieService.setCookie("pfcSearchParam", JSON.stringify(this.model));
      this.model.pfcRecordId = "";
      this.isAfterSave = false;
      this.lock = false;
      this.modeForm = "freightMaintenance";
      this.pfcToolbar.label = "Freight Application MRG";
      this.invisibleToolbarButtons = "retrieve,create";
      this.disableToolbarButtons = "delete,print,copy";
      this.map = new Map();//reset the map upon create
      this.gridPrepaid.onClear();
      this.gridCollect.onClear();

      this.cbAcctPic.clearSelect();
      this.cbMaintenanceAdhocVessel.setValue("");
      this.cbMaintenanceAdhocVoyage.setValue("");

      // on create, must keep set the record as valid (Celeste request in whatsapp gorup 25/02/2019
      this.model.pfcIsValid = 'Y';

      let currentDate = new Date();

      if (this.dateEffectiveFrom.getValue() != null && this.dateEffectiveFrom.getValue() != "" && this.dateEffectiveFrom.getValue() != undefined) {
        this.dateMaintenanceEffectiveFrom.setValue(this.dateEffectiveFrom.getValue());
      } else {
        this.dateMaintenanceEffectiveFrom.setValue(this.genericUtil.pad2(currentDate.getFullYear()) + '-' + this.genericUtil.pad2(currentDate.getMonth() + 1) + '-' + this.genericUtil.pad2(currentDate.getDate()));
      }

      // console.log("this.model.pfcContainerType  --> " + this.model.pfcContainerType );
      if (this.model.pfcContainerType == "ALL") {
        this.model.pfcContainerType = "STD";
      }

      this.model.pfcContainerOwnershipPrefix = "C";

      if (this.model.pfcDgClass == "ALL") {
        this.model.pfcDgClass = "NA";
      }

      if(this.model.pfcIsValid == 'ALL'){
        this.model.pfcIsValid = 'Y';
      }

      if (this.model.pfcContainerOwnership == "ALL") {
        this.model.pfcContainerOwnership = "COC";
      }

      if (this.model.pfcContainerStatus == "ALL") {
        this.model.pfcContainerStatus = "FULL";
        this.model.pfcWeightClass = "M";
      }

      if (this.model.pfcContainerStatus == "EMPTY") {
        this.model.pfcWeightClass = "N";
      }

    if(this.model.pfcReceiptTypeCode == 'ALL'){
      this.model.pfcReceiptTypeCode = 'CY';
    }

    if(this.model.pfcDeliveryTypeCode == 'ALL'){
      this.model.pfcDeliveryTypeCode = 'CY';
    }

      this.changeEventPfcContainerOwnershipProcess(this.model.pfcContainerOwnership);

      //Auto set the field based on field on Flag Status
      //1. Shipper
      if (this.model.pfcShipperId != null && this.model.pfcShipperId != "" && this.model.pfcShipperId != undefined) {
        this.cbMaintenanceShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerId={query}');
        this.cbMaintenanceShipper.setValue(this.model.pfcFreightPartyId);
      } else {
        this.cbMaintenanceShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
        this.cbMaintenanceShipper.setValue("");
      }

      //2. Auto set Place of Receipt
      if (this.model.pfcPlaceOfReceiptCode != null && this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcPlaceOfReceiptCode != undefined) {
        this.cbMaintenancePlaceOfReceipt.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePlaceOfReceipt.setValue(this.model.pfcPlaceOfReceiptCode);
      } else {
        this.cbMaintenancePlaceOfReceipt.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePlaceOfReceipt.setValue("");
      }

      //3. Auto set port of Loading
      if (this.model.pfcLoadPortCode != null && this.model.pfcLoadPortCode != "" && this.model.pfcLoadPortCode != undefined) {
        this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePortOfLoad.setValue(this.model.pfcLoadPortCode);
      } else {
        this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePortOfLoad.setValue("");
      }

      //4. Auto set port of Discharge
      if (this.model.pfcDischargePortCode != null && this.model.pfcDischargePortCode != "" && this.model.pfcDischargePortCode != undefined) {
        this.cbMaintenancePortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePortOfDischarge.setValue(this.model.pfcDischargePortCode);
      } else {
        this.cbMaintenancePortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePortOfDischarge.setValue("");
      }

      //5. Auto set Place of Delivery
      if (this.model.pfcPlaceOfDeliveryCode != null && this.model.pfcPlaceOfDeliveryCode != "" && this.model.pfcPlaceOfDeliveryCode != undefined) {
        this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePlaceOfDelivery.setValue(this.model.pfcPlaceOfDeliveryCode);
      } else {
        this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePlaceOfDelivery.setValue("");
      }

      //6. Auto set Port of Transshipment 1
      if (this.model.pfcPortOfTransshipment1Code != null && this.model.pfcPortOfTransshipment1Code != "" && this.model.pfcPortOfTransshipment1Code != undefined) {
        this.cbMaintenancePOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePOT1.setValue(this.model.pfcPortOfTransshipment1Code);
      } else {
        this.cbMaintenancePOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePOT1.setValue("");
      }

      //7. Auto set Port of Transshipment 2
      if (this.model.pfcPortOfTransshipment2Code != null && this.model.pfcPortOfTransshipment2Code != "" && this.model.pfcPortOfTransshipment2Code != undefined) {
        this.cbMaintenancePOT2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePOT2.setValue(this.model.pfcPortOfTransshipment2Code);
      } else {
        this.cbMaintenancePOT2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePOT2.setValue("");
      }

      //8. Auto set Port of Transshipment 3
      if (this.model.pfcPortOfTransshipment3Code != null && this.model.pfcPortOfTransshipment3Code != "" && this.model.pfcPortOfTransshipment3Code != undefined) {
        this.cbMaintenancePOT3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenancePOT3.setValue(this.model.pfcPortOfTransshipment3Code);
      } else {
        this.cbMaintenancePOT3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenancePOT3.setValue("");
      }

      //9. Auto set Final Destination
      if (this.model.pfcFinalDestinationCode != null && this.model.pfcFinalDestinationCode != "" && this.model.pfcFinalDestinationCode != undefined) {
        this.cbMaintenanceFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceFinalDestination.setValue(this.model.pfcFinalDestinationCode);
      } else {
        this.cbMaintenanceFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.cbMaintenanceFinalDestination.setValue("");
      }

      //10. Auto set Freight Party
      if (this.model.pfcFreightPartyId != null && this.model.pfcFreightPartyId != "" && this.model.pfcFreightPartyId != undefined) {
        this.cbMaintenanceFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
        this.cbMaintenanceFreightParty.setValue(this.model.pfcFreightPartyId);
      } else {
        this.cbMaintenanceFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
        this.cbMaintenanceFreightParty.setValue("");
      }

      //11. Expiry On
      if (this.dateEffectiveTo.getValue() != null && this.dateEffectiveTo.getValue() != "" && this.dateEffectiveTo.getValue() != undefined) {
        this.dateMaintenanceEffectiveTo.setValue(this.dateEffectiveTo.getValue());
      } else {
        this.dateMaintenanceEffectiveTo.setValue("");
      }

      //12. Receipt Type and Delivery Type
      if (this.model.pfcReceiptTypeCode != null && this.model.pfcReceiptTypeCode != "" && this.model.pfcReceiptTypeCode != undefined) {
        this.cbMaintenanceReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
        this.cbMaintenanceReceiptType.setValue(this.model.pfcReceiptTypeCode);
      } else {
        this.cbMaintenanceReceiptType.setValue("");
      }

      if (this.model.pfcDeliveryTypeCode != null && this.model.pfcDeliveryTypeCode != "" && this.model.pfcDeliveryTypeCode != undefined) {
        this.cbMaintenanceDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
        this.cbMaintenanceDeliveryType.setValue(this.model.pfcDeliveryTypeCode);
      } else {
        this.cbMaintenanceDeliveryType.setValue("");
      }

      //reset created by and date information
      this.model.pfcDateCreated = "";
      this.model.pfcUserCreated = "";
      this.model.pfcDateModified = "";
      this.model.pfcUserModified = "";

      // console.log(this.model);

      this.txtRemarksPrincipal.setValue(this.model.pfcPrincipalRemarks);
      this.txtRemarksAgency.setValue(this.model.pfcAgentRemarks);

      window.scrollTo(0,0);

  }


  handleCancel(){
    var self = this;
      if (this.modeForm == "freightFlagStatus") {
        this.pfcToolbar.label = "Freight Application Flag Status";
        this.model = new FreightManagement;

        this.cbFreightParty.setValue("");
        this.cbPlaceOfReceipt.setValue("");
        this.cbPortOfLoad.setValue("");
        this.cbPortOfDischarge.setValue("");
        this.cbPlaceOfDelivery.setValue("");
        this.cbPortOfTransshipment.setValue("");
        this.cbFinalDestination.setValue("");
        this.cbPortOfDischarge.setValue("");

        this.cbContainerStatus.clearSelect();
        this.cbContainerOwnership.clearSelect();
        this.cbDGClass.clearSelect();
        this.cbContainerType.clearSelect();
        this.cbValidInvalid.clearSelect();
        this.cbAppStatus.clearSelect();

        setTimeout(() => {
          self.cbContainerStatus.setValue("ALL");
          self.cbContainerOwnership.setValue("ALL");
          self.cbDGClass.setValue("ALL");
          self.cbContainerType.setValue("ALL");
          self.cbValidInvalid.setValue("ALL");
          self.cbAppStatus.setValue('ALL');
          self.model.pfcAppStatus = 'ALL';
        }, 10);


        this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
        this.cbReceiptType.setValue("CY");
        this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
        this.cbDeliveryType.setValue("CY");
        this.dateEffectiveFrom.setValue("");
        this.dateEffectiveTo.setValue("");
        this.checkLock = false;

        this.isError = false;
        this.isErrorFlag = false;

        this.mainGrid.curPage = 1;
        this.mainGrid.currentPage = 1;
        this.mainGrid.onClear();
        window.scrollTo(0,0);

      } else if (this.modeForm == "freightMaintenance") {
        //reset the model
        this.model = new FreightManagement();
        this.modelDetail = new FreightDetail();
        this.map = new Map();

        //reset all combo and date picker
        this.cbMaintenanceFreightParty.setValue("");
        this.cbMaintenanceShipper.setValue("");
        this.cbMaintenancePlaceOfReceipt.setValue("");
        this.cbMaintenancePortOfLoad.setValue("");
        this.cbMaintenancePortOfDischarge.setValue("");
        this.cbMaintenancePlaceOfDelivery.setValue("");
        this.cbMaintenancePOT1.setValue("");
        this.cbMaintenancePOT2.setValue("");
        this.cbMaintenancePOT3.setValue("");
        this.cbMaintenanceFinalDestination.setValue("");
        this.cbMaintenanceCommodity.setValue("");
        this.cbMaintenanceAdhocVessel.setValue("");
        this.cbMaintenanceAdhocVoyage.setValue("");
        this.cbMaintenanceReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
        this.cbMaintenanceReceiptType.setValue("CY");
        this.cbMaintenanceDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
        this.cbMaintenanceDeliveryType.setValue("CY");
        this.dateMaintenanceEffectiveTo.setValue("");
        this.cbAcctPic.clearSelect();

        //reset effective to
        let currentDate = new Date();
        let theDate = this.genericUtil.pad2(currentDate.getFullYear()) + '-' + this.genericUtil.pad2(currentDate.getMonth() + 1) + '-' + this.genericUtil.pad2(currentDate.getDate());
        this.dateMaintenanceEffectiveFrom.setValue(theDate);

        //reset container type, status, ownership
        this.model.pfcContainerOwnership = "COC";
        this.changeEventPfcContainerOwnershipProcess(this.model.pfcContainerOwnership);

        this.model.pfcContainerStatus = "FULL";
        this.model.pfcWeightClass = "M";
        this.model.pfcContainerType = "STD";
        this.model.pfcDgClass = "NA";

        // reset the appstatus
        this.appStatusLabel = '';

        //reset grid
        this.gridPrepaid.onClear();
        this.gridCollect.onClear();
        this.isError = false;
        this.isErrorFlag = false;
        this.isErrorDetail = false;
        this.lock = false;
        this.isAfterSave = false;
        this.loading = false;
        this.genericUtil.hideLoader();
        window.scrollTo(0,0);

      }
  }


  afterClosedFromMaintenance() {
    //reset grid
    this.gridPrepaid.onClear();
    this.gridCollect.onClear();

      this.modeForm = "freightFlagStatus";
      this.pfcToolbar.label = "Freight Application Flag Status";

      let buff = this.model.pfcAppStatus;

      this.model = new FreightManagement();
      this.model.pfcAppStatus=buff;

      //cek cookies
      if (this.cookieService.getCookie("pfcSearchParam") != "") {
        let pfcSearchParam = JSON.parse(this.cookieService.getCookie("pfcSearchParam"));
         //console.log('** pfcSearchParam **');
         //console.log(pfcSearchParam);

          this.model.pfcSerchByRefNo = pfcSearchParam.pfcSerchByRefNo.trim();
          this.model.pfcRecordId = pfcSearchParam.pfcRecordId;
          this.model.pfcAppStatus = pfcSearchParam.pfcAppStatus;
          this.model.pfcIsDeleted=pfcSearchParam.pfcIsDeleted;

          //01. Freight Party
          if (pfcSearchParam.pfcFreightPartyId != null && pfcSearchParam.pfcFreightPartyId != "" && pfcSearchParam.pfcFreightPartyId != undefined) {
            this.cbFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
            var self = this;
            this.cbFreightParty.setValue(pfcSearchParam.pfcFreightPartyId, function (callbackFreightParty) {
              self.model.pfcFreightPartyId = pfcSearchParam.pfcFreightPartyId;
              self.cbFreightParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
            });
          } else {
            this.model.pfcFreightPartyId = "";
            this.cbFreightParty.setValue("");
          }

          //02. Place of Receipt
          if (pfcSearchParam.pfcPlaceOfReceiptCode != null && pfcSearchParam.pfcPlaceOfReceiptCode != "" && pfcSearchParam.pfcPlaceOfReceiptCode != undefined) {
            this.cbPlaceOfReceipt.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbPlaceOfReceipt.setValue(pfcSearchParam.pfcPlaceOfReceiptCode, function (callbackPOR) {
              self.model.pfcPlaceOfReceiptCode = pfcSearchParam.pfcPlaceOfReceiptCode;
              self.cbPlaceOfReceipt.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcPlaceOfReceiptCode = "";
            this.cbPlaceOfReceipt.setValue("");
          }

          //03. Port of Loading
          if (pfcSearchParam.pfcLoadPortCode != null && pfcSearchParam.pfcLoadPortCode != "" && pfcSearchParam.pfcLoadPortCode != undefined) {
            this.cbPortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbPortOfLoad.setValue(pfcSearchParam.pfcLoadPortCode, function (callbackPOL) {
              self.model.pfcLoadPortCode = pfcSearchParam.pfcLoadPortCode;
              self.cbPortOfLoad.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcLoadPortCode = "";
            this.cbPortOfLoad.setValue("");
          }

          //04. Discharge port Code
          if (pfcSearchParam.pfcDischargePortCode != null && pfcSearchParam.pfcDischargePortCode != "" && pfcSearchParam.pfcDischargePortCode != undefined) {
            this.cbPortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbPortOfDischarge.setValue(pfcSearchParam.pfcDischargePortCode, function (callbackDischarge) {
              self.model.pfcDischargePortCode = pfcSearchParam.pfcDischargePortCode;
              self.cbPortOfDischarge.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcDischargePortCode = "";
            this.cbPortOfDischarge.setValue("");
          }

          //05. Place of Delivery
          if (pfcSearchParam.pfcPlaceOfDeliveryCode != null && pfcSearchParam.pfcPlaceOfDeliveryCode != "" && pfcSearchParam.pfcPlaceOfDeliveryCode != undefined) {
            this.cbPlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbPlaceOfDelivery.setValue(pfcSearchParam.pfcPlaceOfDeliveryCode, function (callbackPOD) {
              self.model.pfcPlaceOfDeliveryCode = pfcSearchParam.pfcPlaceOfDeliveryCode;
              self.cbPlaceOfDelivery.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcPlaceOfDeliveryCode = "";
            this.cbPlaceOfDelivery.setValue("");
          }

          //06. Final Destination
          if (pfcSearchParam.pfcFinalDestinationCode != null && pfcSearchParam.pfcFinalDestinationCode != "" && pfcSearchParam.pfcFinalDestinationCode != undefined) {
            this.cbFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbFinalDestination.setValue(pfcSearchParam.pfcFinalDestinationCode, function (callbackFDest) {
              self.model.pfcFinalDestinationCode = pfcSearchParam.pfcFinalDestinationCode;
              self.cbFinalDestination.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcFinalDestinationCode = "";
            this.cbFinalDestination.setValue("");
          }

          //07. Port of Transshipment1
          if (pfcSearchParam.pfcPortOfTransshipment1Code != null && pfcSearchParam.pfcPortOfTransshipment1Code != "" && pfcSearchParam.pfcPortOfTransshipment1Code != undefined) {
            this.cbPortOfTransshipment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
            var self = this;
            this.cbPortOfTransshipment.setValue(pfcSearchParam.pfcPortOfTransshipment1Code, function (callbackPOT1) {
              self.model.pfcPortOfTransshipment1Code = pfcSearchParam.pfcPortOfTransshipment1Code;
              self.cbPortOfTransshipment.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
            });
          } else {
            this.model.pfcPortOfTransshipment1Code = "";
            this.cbPortOfTransshipment.setValue("");
          }

          //08. Receipt Type & Delivery Type
          if (pfcSearchParam.pfcReceiptTypeCode != null && pfcSearchParam.pfcReceiptTypeCode != "" && pfcSearchParam.pfcReceiptTypeCode != undefined && pfcSearchParam.pfcReceiptTypeCode != 'ALL') {
            this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
            this.cbReceiptType.setValue(pfcSearchParam.pfcReceiptTypeCode);
            this.model.pfcReceiptTypeCode = pfcSearchParam.pfcReceiptTypeCode;
          } else {
            this.model.pfcReceiptTypeCode = "";
            this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
            this.cbReceiptType.setValue("CY");
          }
          if (pfcSearchParam.pfcDeliveryTypeCode != null && pfcSearchParam.pfcDeliveryTypeCode != "" && pfcSearchParam.pfcDeliveryTypeCode != undefined && pfcSearchParam.pfcDeliveryTypeCode != 'ALL') {
            this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
            this.cbDeliveryType.setValue(pfcSearchParam.pfcDeliveryTypeCode);
            this.model.pfcDeliveryTypeCode = pfcSearchParam.pfcDeliveryTypeCode;
          } else {
            this.model.pfcDeliveryTypeCode = "";
            this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
            this.cbDeliveryType.setValue("CY");
          }


          //09. Container Ownership
          if (pfcSearchParam.pfcContainerOwnership != null && pfcSearchParam.pfcContainerOwnership != "" && pfcSearchParam.pfcContainerOwnership != undefined) {
            this.cbContainerOwnership.setValue(pfcSearchParam.pfcContainerOwnership);
            this.model.pfcContainerOwnership = pfcSearchParam.pfcContainerOwnership;
          } else {
            this.model.pfcContainerOwnership = "ALL";
            this.cbContainerOwnership.setValue("ALL");
          }

          //10. Container Status
          if (pfcSearchParam.pfcContainerStatus != null && pfcSearchParam.pfcContainerStatus != "" && pfcSearchParam.pfcContainerStatus != undefined) {
            this.cbContainerStatus.setValue(pfcSearchParam.pfcContainerStatus);
            this.model.pfcContainerStatus = pfcSearchParam.pfcContainerStatus;
          } else {
            this.model.pfcContainerStatus = "ALL";
            this.cbContainerStatus.setValue("ALL");
          }

          //11. Container Type
          if (pfcSearchParam.pfcContainerType != null && pfcSearchParam.pfcContainerType != "" && pfcSearchParam.pfcContainerType != undefined) {
            switch (pfcSearchParam.pfcContainerType) {
              case "STD":
                this.cbContainerType.setValue("NORMAL");
                break;
              case "DRY":
                this.cbContainerType.setValue("REEFER DRY");
                break;
              case "REF":
                this.cbContainerType.setValue("REEFER");
                break;
              case "FLR":
                this.cbContainerType.setValue("FLATRACK");
                break;
              case "OPT":
                this.cbContainerType.setValue("OPEN TOP");
                break;
              case "PLT":
                this.cbContainerType.setValue("PLATFORM");
                break;
              case "TNK":
                this.cbContainerType.setValue("TANK");
                break;
              case "UC":
                this.cbContainerType.setValue("UC");
                break;

              default:
                break;
            }
            this.model.pfcContainerType = pfcSearchParam.pfcContainerType;
          } else {
            this.model.pfcContainerType = "ALL";
            this.cbContainerType.setValue("ALL");
          }

          //12. DG Class
          if (pfcSearchParam.pfcDgClass != null && pfcSearchParam.pfcDgClass != "" && pfcSearchParam.pfcDgClass != undefined) {
            this.cbDGClass.setValue(pfcSearchParam.pfcDgClass);
            this.model.pfcDgClass = pfcSearchParam.pfcDgClass;
          } else {
            this.model.pfcDgClass = "ALL";
            this.cbDGClass.setValue("ALL");
          }

          //13. Effective From
          // console.log("pfcSearchParam.pfcEffectiveFrom --> " + pfcSearchParam.pfcEffectiveFrom);
          if (pfcSearchParam.pfcEffectiveFrom != null && pfcSearchParam.pfcEffectiveFrom != "" && pfcSearchParam.pfcEffectiveFrom != undefined) {
            this.dateEffectiveFrom.setValue(pfcSearchParam.pfcEffectiveFrom);
          } else {
            this.model.pfcEffectiveFrom = "";
            this.dateEffectiveFrom.setValue("");
          }

          //14. Effective To
          // console.log("pfcSearchParam.pfcEffectiveTo --> " + pfcSearchParam.pfcEffectiveTo);
          if (pfcSearchParam.pfcEffectiveTo != null && pfcSearchParam.pfcEffectiveTo != "" && pfcSearchParam.pfcEffectiveTo != undefined) {
            this.dateEffectiveTo.setValue(pfcSearchParam.pfcEffectiveTo);
          } else {
            this.model.pfcEffectiveTo = "";
            this.dateEffectiveTo.setValue("");
          }

          // 15. Valid / Invalid
          if (pfcSearchParam.pfcIsValid != null && pfcSearchParam.pfcIsValid != "" && pfcSearchParam.pfcIsValid != undefined) {
            this.model.pfcIsValid = pfcSearchParam.pfcIsValid;
          } else {
            this.model.pfcIsValid = 'ALL';
          }
      }
      //end of check cookies

      this.invisibleToolbarButtons = "save,copy,nct,delete";
      this.disableToolbarButtons = "copy";
      this.loading = false;
      this.isError = false;
      this.isErrorFlag = false;

      //reset combo
      this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
      this.cbReceiptType.setValue(this.model.pfcReceiptTypeCode);
      this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
      this.cbDeliveryType.setValue(this.model.pfcDeliveryTypeCode);

      //remove the cookie
      //this.cookieService.deleteCookie("pfcSearchByRefNo");
      //this.cookieService.deleteCookie("pfcRecordId");
  }

  handleClose(){
      if (this.modeForm == "freightMaintenance") {



        this.message('information', 'Information', 'Are you sure want to close this?', 'yesno',
          {
            yes: 'this.afterClosedFromMaintenance()',
            no: ''
          });
      } else {
        this.router.navigateByUrl("/main/home");
      }
  }


  changeCbMaintenanceFreightParty(event){
      // console.log("** changeCbMaintenanceFreightParty **");
      if (event["customerId"] != null && event["customerId"] != undefined && event["customerId"] != "") {
        this.model.pfcFreightPartyId = event["customerId"];
        this.model.pfcFreightPartyCountry=event["countryCode"].toString().toUpperCase();
      } else {
        this.model.pfcFreightPartyId = "";
        this.model.pfcFreightPartyCountry = "";
      }
      //revert the search url to use name
      this.cbMaintenanceFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
  }

  changeCbMaintenanceShipper(event){
      // console.log("** changeCbMaintenanceShipper **");
      if (event["customerId"] != null && event["customerId"] != undefined && event["customerId"] != "") {
        this.model.pfcShipperId = event["customerId"];
      } else {
        this.model.pfcShipperId = "";
      }

      //revert the search url to use name
      this.cbMaintenanceShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
  }

  changeCbMaintenancePlaceOfReceipt(event){
      // console.log("** changeCbMaintenancePlaceOfReceipt **");
      if (event["locationId"] != null && event["locationId"] != undefined && event["locationId"] != "") {
        this.model.pfcPlaceOfReceiptCode = event["locationCode"];
        if (this.model.pfcLoadPortCode == null || this.model.pfcLoadPortCode == '' || this.model.pfcLoadPortCode == undefined) {
          this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePortOfLoad.setValue(event["locationCode"]);
        }
        if (event.hasTerminal == "Y") {
          this.lockPORTerminal = false;
        } else {
          this.lockPORTerminal = true;
        }
        this.cbMaintenancePORTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePOLTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePORTerminal.setValue("");
        this.cbMaintenancePOLTerminal.setValue("");
      } else {
        this.model.pfcPlaceOfReceiptCode = "";
        this.cbMaintenancePORTerminal.setUrl("");
        this.cbMaintenancePORTerminal.setValue("");
      }

      //revert the url to search by name
      this.cbMaintenancePlaceOfReceipt.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePortOfLoad(event){
      // console.log("** changeCbMaintenancePortOfLoad **");

      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}')
        this.model.pfcLoadPortCode = event["locationCode"];

        if (event.hasTerminal == "Y") {
          //open lock
          this.lockPOLTerminal = false;
        } else {
          //lock the terminal back
          this.lockPOLTerminal = true;
        }
        this.cbMaintenancePOLTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePOLTerminal.setValue("");
        this.model.pfcPolPrefix = event["locationBookingRef"];
      } else {
        this.model.pfcLoadPortCode = "";
        this.cbMaintenancePOLTerminal.setUrl("");
        this.cbMaintenancePOLTerminal.setValue("");
        this.model.pfcPolPrefix = "";
      }

      //revert the url to search by name
      this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePortOfDischarge(event){
      // console.log("** changeCbMaintenancePortOfDischarge **");

      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.cbMaintenancePortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.model.pfcDischargePortCode = event["locationCode"];

        if (this.model.pfcPlaceOfDeliveryCode == null || this.model.pfcPlaceOfDeliveryCode == '' || this.model.pfcPlaceOfDeliveryCode == undefined) {
          this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePlaceOfDelivery.setValue(event["locationCode"]);
        }

        if (event.hasTerminal == "Y") {
          //open lock
          this.lockPODTerminal = false;
        } else {
          //lock the terminal back
          this.lockPODTerminal = true;
        }

        this.cbMaintenancePODTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePODLTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");

        this.cbMaintenancePODTerminal.setValue("");
        this.cbMaintenancePODLTerminal.setValue("");

        this.model.pfcPodPrefix = event["locationCode"].substr(2, 3).toLocaleUpperCase().trim();
      } else {
        this.model.pfcDischargePortCode = "";
        this.cbMaintenancePODTerminal.setUrl("");
        this.cbMaintenancePODTerminal.setValue("");
        this.model.pfcPodPrefix = "";
      }

      //revert the url to search by name
      this.cbMaintenancePortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePlaceOfDelivery(event){
      // console.log("** changeCbMaintenancePlaceOfDelivery **");

      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
        this.model.pfcPlaceOfDeliveryCode = event["locationCode"];

        if (event.hasTerminal == "Y") {
          //open lock
          this.lockPODLTerminal = false;
        } else {
          //lock the terminal back
          this.lockPODLTerminal = true;
        }

        this.cbMaintenancePODLTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePODLTerminal.setValue("");

      } else {
        this.model.pfcPlaceOfDeliveryCode = "";
        this.cbMaintenancePODLTerminal.setUrl("");
        this.cbMaintenancePODLTerminal.setValue("");

      }

      //revert the url to search by name
      this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenanceFinalDestination(event){
      // console.log("** changeCbMaintenanceFinalDestination **");

      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcFinalDestinationCode = event["locationCode"];

        if (event.hasTerminal == "Y") {
          //open lock
          this.lockFDESTTerminal = false;
        } else {
          //lock the terminal back
          this.lockFDESTTerminal = true;
        }
        this.cbMaintenanceFDESTTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenanceFDESTTerminal.setValue("");

      } else {
        this.model.pfcFinalDestinationCode = "";
        this.cbMaintenanceFDESTTerminal.setUrl("");
        this.cbMaintenanceFDESTTerminal.setValue("");
      }

      //revert the url to search by name
      this.cbMaintenanceFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePOT1(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPortOfTransshipment1Code = event["locationCode"];

        if (event.hasTerminal == "Y") {
          //open lock
          this.lockPOT1Terminal = false;
        } else {
          //lock the terminal back
          this.lockPOT1Terminal = true;
        }

        this.cbMaintenancePOT1Terminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
        this.cbMaintenancePOT1Terminal.setValue("");

      } else {
        this.model.pfcPortOfTransshipment1Code = "";
      }
      //revert the url to search by name
      this.cbMaintenancePOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePOT2(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPortOfTransshipment2Code = event["locationCode"];
      } else {
        this.model.pfcPortOfTransshipment2Code = "";
      }
      //revert the url to search by name
      this.cbMaintenancePOT2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenancePOT3(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPortOfTransshipment3Code = event["locationCode"];
      } else {
        this.model.pfcPortOfTransshipment3Code = "";
      }
      //revert the url to search by name
      this.cbMaintenancePOT3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationName={query}');
  }

  changeCbMaintenanceReceiptType(event){
      if (event["receiptTypeCode"] != null && event["receiptTypeCode"] != undefined && event["receipTypeCode"] != "") {
        this.model.pfcReceiptTypeCode = event["receiptTypeCode"];
      } else {
        this.model.pfcReceiptTypeCode = "";
      }
      this.cbMaintenanceReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeCbMaintenanceDeliveryType(event){
      if (event["deliveryTypeCode"] != null && event["deliveryTypeCode"] != undefined && event["deliveryTypeCode"] != "") {
        this.model.pfcDeliveryTypeCode = event["deliveryTypeCode"];
      } else {
        this.model.pfcDeliveryTypeCode = "";
      }
      this.cbMaintenanceDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}');
  }

  changeCbMaintenanceAdhocVessel(event){
      if (event["vesselId"] != null && event["vesselId"] != undefined && event["vesselId"] != "") {
        this.model.pfcVesselId = event["vesselId"];
        this.cbMaintenanceAdhocVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/" + this.model.pfcVesselId + "/" + "O" + "/{query}/" + this.cookieService.getDefaultLocationCode());
        this.cbMaintenanceAdhocVoyage.setValue("");
      } else {
        this.model.pfcVesselId = "";
        this.model.pfcVoyage = "";
        this.cbMaintenanceAdhocVoyage.setUrl("");
        this.model.pfcLocalEta = "";
        this.model.pfcLocalEtd = "";
        this.model.pfcLocalAta = "";
        this.model.pfcLocalAtd = "";
      }

      //revert the search using vessel name
      this.cbMaintenanceAdhocVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/VesselName={query}');
  }

  changeCbMaintenanceAdhocVoyage(event){
      if (event.voyage != null && event.voyage != "" && event.voyage != undefined) {
        this.model.pfcVoyage = event.voyage;
        this.getEtaEtdAtaAtd(this.model.pfcVesselId, this.model.pfcVoyage, "O", this.cookieService.getDefaultLocationCode());
      } else {
        this.model.pfcVoyage = "";
        this.model.pfcLocalEta = "";
        this.model.pfcLocalEtd = "";
        this.model.pfcLocalAta = "";
        this.model.pfcLocalAtd = "";
      }
  }

  changeCbMaintenancePORTerminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPORTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPORTerminalCode = "";
      }
  }

  changeCbMaintenancePODLTerminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPODLTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPODLTerminalCode = "";
      }
  }

  changeCbMaintenancePOLTerminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPOLTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPOLTerminalCode = "";
      }
  }

  changeCbMaintenancePOT1Terminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPOT1TerminalCode = event["locationCode"];
      } else {
        this.model.pfcPOT1TerminalCode = "";
      }
  }

  changeCbMaintenancePODTerminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcPODTerminalCode = event["locationCode"];
      } else {
        this.model.pfcPODTerminalCode = "";
      }
  }

  changeCbMaintenanceFDESTTerminal(event){
      if (event["locationCode"] != null && event["locationCode"] != undefined && event["locationCode"] != "") {
        this.model.pfcFDESTTerminalCode = event["locationCode"];
      } else {
        this.model.pfcFDESTTerminalCode = "";
      }
  }

  changeEventPfcContainerOwnershipProcess(value:string){
    if (value == "COC") {
      this.model.pfcDetention = "S";
      this.model.pfcDemurrage = "S";
      this.lockDetention = false;
      this.lockDemurrage = false;
    } else {
      this.model.pfcDetention = "N";
      this.model.pfcDemurrage = "N";
      this.lockDetention = true;
      this.lockDemurrage = true;

    }

    if (value == "COC" || value == "COCTS") {
      this.model.pfcContainerOwnershipPrefix = "C";
    } else if (value == "SOC" || value == "SOCTS") {
      this.model.pfcContainerOwnershipPrefix = "S";
    } else if (value == "SLOT") {
      this.model.pfcContainerOwnershipPrefix = "SL";
    }

    this.model.pfcDetentionRequest = 0;
    this.model.pfcDemurrageRequest = 0;

    //set model when container ownership changed
    this.model.pfcContainerOwnership = value;
}

  //Radio button handler
  changeEventPfcContainerOwnership(event){
      this.changeEventPfcContainerOwnershipProcess(event.target.value);
  }

  changeEventPfcContainerStatus(event){
      this.model.pfcContainerStatus = event.target.value;
      if (this.model.pfcContainerStatus == "FULL") {
        this.model.pfcWeightClass = "M";
        this.lockFullEmpty = true;
      } else {
        this.model.pfcWeightClass = "N";
        this.lockFullEmpty = false;
      }
  }

  changeEventPfcShipmentType(event){
      this.model.pfcShipmentType = event.target.value;
  }

  changeEventPfcDgClass(event){
      this.model.pfcDgClass = event.target.value;
  }

  changeEventPfcWeightClass(event){
      if (this.model.pfcContainerStatus != "EMPTY") {
        this.model.pfcWeightClass = event.target.value;
      } else {
        this.model.pfcWeightClass = "N";
      }
  }

  changeEventPfcIhc(event){
      this.model.pfcIhc = event.target.value;
  }
  //End of Radio Button handler


  //FAI Group handler
  changeEventPfcDetailFAIGroup(event){
      if (event.target.checked) {
        this.modelDetail.pfcDetailFAIGroup = "Y";
      } else {
        this.modelDetail.pfcDetailFAIGroup = "N";
      }
  }

  //Copy freight tab
  changeEventPfcDetailCopyTab(event)
  {
    if (event.target.checked) {
      this.modelDetail.pfcDetailCopyTab = "Y";
    } else {
      this.modelDetail.pfcDetailCopyTab = "N";
    }
  }

  //Door Open handler
  changeEventPfcDetailDoorOpen(event)
  {
    this.modelDetail.pfcDetailOogDoorOpen = event.target.value;
  }


  //POD detention event
  changeEventPfcDetention(event)
  {
    this.model.pfcDetention = event.target.value;
    this.model.pfcDetentionRequest = 0;
  }

  //POD demurrage event
  changeEventPfcDemurrage(event)
  {
    this.model.pfcDemurrage = event.target.value;
    this.model.pfcDemurrageRequest = 0;
  }

  changeCbMaintenanceDetailCharge(event)
  {
    if (event.chargeId != null && event.chargeCode != "" && event.chargeId != undefined) {
      this.modelDetail.pfcDetailChargeCode = event.chargeCode;
      this.modelDetail.pfcDetailChargeName = event.chargeName;
    } else {
      this.modelDetail.pfcDetailChargeCode = "";
      this.modelDetail.pfcDetailChargeName = "";
    }

    //revert to be able to search by core or name
    this.cbMaintenanceDetailCharge.setUrl(this.configService.config.BASE_API.toString() + '/MasterCharges/findByComboBoxControlNonAdhoc/chargeCodeOrName={query}');
  }

  //handle container type
  changeEventPfcContainerType(event)
  {
    if (event.target.value != null && event.target.value != "" && event.target.value != undefined) {
      this.model.pfcContainerType = event.target.value;

      this.gridPrepaid.onClear();
      this.gridCollect.onClear();
      var detailList: any[] = this.map.get(this.model.pfcContainerType)
      if (detailList != null && detailList != undefined) {
        detailList.forEach(element => {


           var x: FreightDetail = new FreightDetail();
           x.pfcDetailChargeCode = element.pfcDetailChargeCode;
           x.pfcDetailChargeName = element.pfcDetailChargeName;
           x.pfcDetailContainerType = element.pfcDetailContainerType;

           switch (x.pfcDetailContainerType) {
              case "TNK":
                x.pfcDetailContainerTypeName = 'TANK';
                break;
              case "OPT":
                x.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case "FLR":
                x.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case "PLT":
                x.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case "REF":
                x.pfcDetailContainerTypeName = 'REEFER';
                break;
              case "DRY":
                x.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case "UC":
                x.pfcDetailContainerTypeName = 'UC';
                break;
              case "STD":
                x.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
           }

           x.pfcDetailCurrency = element.pfcDetailCurrency;
           x.pfcDetailD20 = element.pfcDetailD20;
           x.pfcDetailD40 = element.pfcDetailD40;
           x.pfcDetailD45 = element.pfcDetailD45;
           x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
           x.pfcDetailH20 = element.pfcDetailH20;
           x.pfcDetailH40 = element.pfcDetailH40;
           x.pfcDetailH45 = element.pfcDetailH45;
           x.pfcDetailKT = element.pfcDetailKT;
           x.pfcDetailKillSlot = element.pfcDetailKillSlot;
           x.pfcDetailM3 = element.pfcDetailM3;
           x.pfcDetailMinCharge = element.pfcDetailMinCharge;
           x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
           if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
           }else if(x.pfcDetailOogDoorOpen == "D"){
              x.pfcDetailOogDoorOpenName = "Detached";
           }else if(x.pfcDetailOogDoorOpen == "B"){
              x.pfcDetailOogDoorOpenName = "Tied Both";
           }else if(x.pfcDetailOogDoorOpen == "L"){
              x.pfcDetailOogDoorOpenName = "Tied Left";
           }else if(x.pfcDetailOogDoorOpen == "R"){
              x.pfcDetailOogDoorOpenName = "Tied Right";
           }
           x.pfcDetailOogOH = element.pfcDetailOogOH;
           x.pfcDetailOogOLB = element.pfcDetailOogOLB;
           x.pfcDetailOogOLF = element.pfcDetailOogOLF;
           x.pfcDetailOogOWL = element.pfcDetailOogOWL;
           x.pfcDetailOogOWR = element.pfcDetailOogOWR;
           x.pfcDetailOogUcH = element.pfcDetailOogUcH;
           x.pfcDetailOogUcL = element.pfcDetailOogUcL;
           x.pfcDetailOogUcW = element.pfcDetailOogUcW;
           x.pfcDetailPC = element.pfcDetailPC;
           x.pfcDetailPCTab = element.pfcDetailPCTab;
           x.pfcDetailPayer = element.pfcDetailPayer;
           x.pfcDetailPayerName = element.pfcDetailPayerName;
           x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
           x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
           x.pfcDetailRT = element.pfcDetailRT;
           x.pfcDetailSeqNo = element.pfcDetailSeqNo;
           x.pfcDetailStatus = element.pfcDetailStatus;
           x.no = element.no;


          if (x.pfcDetailPCTab == 'P') {
            this.gridPrepaid.listStore.store.push(x);
            this.gridPrepaid.loadData();
          }

          if (x.pfcDetailPCTab == 'C') {
            this.gridCollect.listStore.store.push(x);
            this.gridCollect.loadData();
          }

        });
      }
    } else {
      this.model.pfcContainerType = "";
    }
  }


  changeCbMaintenanceDetailCurrency(event)
  {
    if (event.currencyCode != null && event.currencyCode != "" && event.currencyCode != undefined) {
      this.modelDetail.pfcDetailCurrency = event.currencyCode
    } else {
      this.modelDetail.pfcDetailCurrency = "";
    }
  }


  //godzila
  changeCbMaintenanceDetailPC(event)
  {
    if (event.prepaid_collect != null && event.prepaid_collect != "" && event.prepaid_collect != undefined) {
      this.modelDetail.pfcDetailPC = event.prepaid_collect;

      if (this.modelDetail.pfcDetailPC == "P") {
        //worker for place of payment
        if (this.model.pfcLoadPortCode != null && this.model.pfcLoadPortCode != undefined && this.model.pfcLoadPortCode != ""  && this.model.pfcLoadPortCode != "VNHCX" && this.model.pfcLoadPortCode != "IDJKX" && this.model.pfcLoadPortCode != "PKKHX" && this.model.pfcLoadPortCode != "AEDBX" && this.model.pfcLoadPortCode != "PHMNX" && this.model.pfcLoadPortCode != "MYPKX" && this.model.pfcLoadPortCode != "THBKX") {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcLoadPortCode);
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
        }

        //worker for payer
        if (this.model.pfcFreightPartyId != null && this.model.pfcFreightPartyId != undefined && this.model.pfcFreightPartyId != "") {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
          this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId);
        } else {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("");
        }

      } else {
        //worker for place of payment
        if (this.model.pfcDischargePortCode != null && this.model.pfcDischargePortCode != undefined && this.model.pfcDischargePortCode != ""  && this.model.pfcDischargePortCode != "VNHCX" && this.model.pfcDischargePortCode != "IDJKX" && this.model.pfcDischargePortCode != "PKKHX" && this.model.pfcDischargePortCode != "AEDBX" && this.model.pfcDischargePortCode != "PHMNX" && this.model.pfcDischargePortCode != "MYPKX" && this.model.pfcDischargePortCode != "THBKX") {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcDischargePortCode);
        } else {
          this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
          this.cbMaintenanceDetailPlaceOfPayment.setValue("");
        }

        //worker for payer
        this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
        this.cbMaintenanceDetailPayer.setValue("");
        this.modelDetail.pfcDetailPayer = "";
        this.modelDetail.pfcDetailPayerName = "";
      }
    } else {
      this.modelDetail.pfcDetailPC = "";
    }

  }

  changeCbMaintenancePlaceOfPayment(event)
  {
    if (event.locationCode != null && event.locationCode != null && event.locationCode != undefined) {
      if(event.locationCode != "VNHCX" && event.locationCode != "IDJKX" && event.locationCode != "PKKHX" && event.locationCode != "AEDBX" && event.locationCode != "PHMNX" && event.locationCode != "MYPKX" && event.locationCode != "THBKX"){
        this.modelDetail.pfcDetailPlaceOfPayment = event.locationCode;
        this.modelDetail.pfcDetailPlaceOfPaymentName = event.locationName
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
      }else{
        this.modelDetail.pfcDetailPlaceOfPayment = "";
        this.modelDetail.pfcDetailPlaceOfPaymentName = "";
      }
    } else {
    }

  }
  changeCbMaintenanceDetailPayer(event)
  {
    if (event.customerId != null && event.customerId != null && event.customerId != undefined) {
      this.modelDetail.pfcDetailPayer = event.customerId;
      this.modelDetail.pfcDetailPayerName = event.customerName
      this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');

    } else {
      this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
      this.modelDetail.pfcDetailPayer = "";
      this.modelDetail.pfcDetailPayerName = "";
    }
  }

  changeAcctPIC(event)
  {
    // console.log('change event ACC PIC')
    this.model.pfcAcctPic = "";
    if (event["userId"] != null && event["userId"] != undefined && event["userId"] != '') {
      this.model.pfcAcctPic = event["userName"];
    }
  }

  /*
  onSurcharge()
  {
    // console.log("** on surcharge **");

    this.genericUtil.showLoader();
    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    this.model.pfcDetails = [];
    this.map.forEach((value: FreightDetail[], key: string) => {

      value.forEach(element => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

          if ((this.currentActiveTab == "prepaid" && x.pfcDetailPCTab == 'P') || (this.currentActiveTab == "collect" && x.pfcDetailPCTab == 'C')) {
            this.model.pfcDetails.push(x);
          }

      });
    });

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterSurcharges/gettingSurcharge',this.model).subscribe((resp) => {

      if(resp.json()["status"] == "OK"){
          var dataSurcharges:FreightDetail[] = [];
          var dt:any[] = [];
          dt = resp.json()["content"];

          dt.forEach(element => {
            // console.log(element);

            var freightDetail:FreightDetail = new FreightDetail();
            freightDetail.pfcDetailChargeCode = element['chargeCode'];
            freightDetail.pfcDetailChargeName = element['chargeName'];
            freightDetail.pfcDetailContainerType = element['containerType'];
            switch (freightDetail.pfcDetailContainerType) {
              case "TNK":
                freightDetail.pfcDetailContainerTypeName = 'TANK';
                break;
              case "OPT":
                freightDetail.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case "FLR":
                freightDetail.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case "PLT":
                freightDetail.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case "REF":
                freightDetail.pfcDetailContainerTypeName = 'REEFER';
                break;
              case "DRY":
                freightDetail.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case "UC":
                freightDetail.pfcDetailContainerTypeName = 'UC';
                break;
              case "STD":
                freightDetail.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
            }
            freightDetail.pfcDetailCurrency = element['currencyCode'];
            freightDetail.pfcDetailPC = element['freightType'];
            freightDetail.pfcDetailPCTab = element['pcTab'];
            if(element['payerCode'] == ''){
              freightDetail.pfcDetailPayer = "";
              freightDetail.pfcDetailPayerName = "";
            }else{
              freightDetail.pfcDetailPayer = element['payerCode'];
              freightDetail.pfcDetailPayerName = element['payerName'];
            }

            freightDetail.pfcDetailPlaceOfPayment = element['placeOfPaymentCode'];
            freightDetail.pfcDetailPlaceOfPaymentName = element['placeOfPaymentName'];

            freightDetail.pfcDetailD20 = 0;
            freightDetail.pfcDetailD40 = 0;
            freightDetail.pfcDetailD45 = 0;
            freightDetail.pfcDetailH20 = 0;
            freightDetail.pfcDetailH40 = 0;
            freightDetail.pfcDetailH45 = 0;

            if(element['D20'] != undefined){
              freightDetail.pfcDetailD20 = Number.parseFloat(element['D20']);
            }

            if(element['D40'] != undefined){
              freightDetail.pfcDetailD40 = Number.parseFloat(element['D40']);
            }

            if(element['D45'] != undefined){
              freightDetail.pfcDetailD45 = Number.parseFloat(element['D45']);
            }

            if(element['H20'] != undefined){
              freightDetail.pfcDetailH20 = Number.parseFloat(element['H20']);
            }

            if(element['H40'] != undefined){
              freightDetail.pfcDetailH40 = Number.parseFloat(element['H40']);
            }

            if(element['H45'] != undefined){
              freightDetail.pfcDetailH45 = Number.parseFloat(element['H45']);
            }

            freightDetail.pfcDetailOogDoorOpen = element['doorOpen'];
            if(freightDetail.pfcDetailOogDoorOpen == "N"){
              freightDetail.pfcDetailOogDoorOpenName = "N/A";
            }else if(freightDetail.pfcDetailOogDoorOpen == "D"){
              freightDetail.pfcDetailOogDoorOpenName = "Detached";
            }else if(freightDetail.pfcDetailOogDoorOpen == "B"){
              freightDetail.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(freightDetail.pfcDetailOogDoorOpen == "L"){
              freightDetail.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(freightDetail.pfcDetailOogDoorOpen == "R"){
              freightDetail.pfcDetailOogDoorOpenName = "Tied Right";
            }
            freightDetail.pfcDetailOogOH = element['oh'];
            freightDetail.pfcDetailOogOLB = element['olb'];
            freightDetail.pfcDetailOogOLF = element['olf'];
            freightDetail.pfcDetailOogOWL = element['owl'];
            freightDetail.pfcDetailOogOWR = element['owr'];
            freightDetail.pfcDetailOogUcH = element['uch'];
            freightDetail.pfcDetailOogUcL = element['ucl'];
            freightDetail.pfcDetailOogUcW = element['ucw'];

            // console.log(freightDetail);
            dataSurcharges.push(freightDetail);



          });

          if (this.map.get(this.model.pfcContainerType) == undefined) {
            // console.log("#1");
            let indexPrepaid:number = 0;
            let indexCollect:number = 0;
            dataSurcharges.forEach(element => {
              if(element.pfcDetailPCTab == 'P'){
                indexPrepaid++;
              }

              if(element.pfcDetailPCTab == 'C'){
                indexCollect++;
              }
              if(element.pfcDetailPCTab == 'P'){
                element.pfcDetailSeqNo = indexPrepaid;
                element.no = indexPrepaid;
              }
              if(element.pfcDetailPCTab == 'C'){
                element.pfcDetailSeqNo = indexCollect;
                element.no = indexCollect;
              }
            });
            this.map.set(this.model.pfcContainerType, dataSurcharges);
          }else{
            // console.log("#2");
            let data:FreightDetail[] = this.map.get(this.model.pfcContainerType);
            // console.log("existing data ");
            // console.log(data);
            let indexPrepaid:number = 0;
            let indexCollect:number = 0;

            let dataList:FreightDetail[] = [];
            data.forEach(element => {
              if(element.pfcDetailPCTab == 'P'){
                indexPrepaid++;
              }

              if(element.pfcDetailPCTab == 'C'){
                indexCollect++;
              }
                var x:FreightDetail= new FreightDetail();
                x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                x.pfcDetailChargeName = element.pfcDetailChargeName;
                x.pfcDetailContainerType = element.pfcDetailContainerType;
                switch (x.pfcDetailContainerType) {
                  case "TNK":
                    x.pfcDetailContainerTypeName = 'TANK';
                    break;
                  case "OPT":
                    x.pfcDetailContainerTypeName = 'OPEN TOP';
                    break;
                  case "FLR":
                    x.pfcDetailContainerTypeName = 'FLAT RACK';
                    break;
                  case "PLT":
                    x.pfcDetailContainerTypeName = 'PLATFORM';
                    break;
                  case "REF":
                    x.pfcDetailContainerTypeName = 'REEFER';
                    break;
                  case "DRY":
                    x.pfcDetailContainerTypeName = 'REEFER DRY';
                    break;
                  case "UC":
                    x.pfcDetailContainerTypeName = 'UC';
                    break;
                  case "STD":
                    x.pfcDetailContainerTypeName = 'NORMAL';
                    break;
                  default:
                    break;
                }
                x.pfcDetailCurrency = element.pfcDetailCurrency;
                x.pfcDetailPayer = element.pfcDetailPayer;
                x.pfcDetailPayerName = element.pfcDetailPayerName;
                x.pfcDetailPC = element.pfcDetailPC;
                x.pfcDetailPCTab = element.pfcDetailPCTab;
                if(element.pfcDetailPCTab == 'P'){
                  x.pfcDetailSeqNo = indexPrepaid;
                  x.no = indexPrepaid;
                }
                if(element.pfcDetailPCTab == 'C'){
                  x.pfcDetailSeqNo = indexCollect;
                  x.no = indexCollect;
                }
                x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                x.pfcDetailD20 = element.pfcDetailD20;
                x.pfcDetailD40 = element.pfcDetailD40;
                x.pfcDetailD45 = element.pfcDetailD45;
                x.pfcDetailH20 = element.pfcDetailH20;
                x.pfcDetailH40 = element.pfcDetailH40;
                x.pfcDetailH45 = element.pfcDetailH45;
                x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                x.pfcDetailKT = element.pfcDetailKT;
                x.pfcDetailM3 = element.pfcDetailM3;
                x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                if(x.pfcDetailOogDoorOpen == "N"){
                  x.pfcDetailOogDoorOpenName = "N/A";
                }else if(x.pfcDetailOogDoorOpen == "D"){
                  x.pfcDetailOogDoorOpenName = "Detached";
                }else if(x.pfcDetailOogDoorOpen == "B"){
                  x.pfcDetailOogDoorOpenName = "Tied Both";
                }else if(x.pfcDetailOogDoorOpen == "L"){
                  x.pfcDetailOogDoorOpenName = "Tied Left";
                }else if(x.pfcDetailOogDoorOpen == "R"){
                  x.pfcDetailOogDoorOpenName = "Tied Right";
                }
                x.pfcDetailOogOH = element.pfcDetailOogOH;
                x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                x.pfcDetailRT = element.pfcDetailRT;
                dataList.push(x);
            });

            let surchargeList:FreightDetail[] = [];

            dataSurcharges.forEach(element => {
              //// console.log(element);

              let snapCodeSurcharge: string = "";
              snapCodeSurcharge += element.pfcDetailPC.trim();
              snapCodeSurcharge += element.pfcDetailPCTab.trim();
              snapCodeSurcharge += element.pfcDetailChargeCode.trim();
              snapCodeSurcharge += element.pfcDetailCurrency.trim();
              snapCodeSurcharge += element.pfcDetailPlaceOfPayment.trim();
              snapCodeSurcharge += element.pfcDetailPayer.trim();
              snapCodeSurcharge += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
              snapCodeSurcharge += element.pfcDetailOogDoorOpen.trim();
              snapCodeSurcharge += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toString();
              snapCodeSurcharge += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toString();
              snapCodeSurcharge += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toString();
              snapCodeSurcharge += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toString();
              snapCodeSurcharge += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toString();
              snapCodeSurcharge += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toString();

              let chek:boolean = false;
              dataList.forEach(el => {

                let snapCode: string = "";
                snapCode += el.pfcDetailPC.trim();
                snapCode += el.pfcDetailPCTab.trim();
                snapCode += el.pfcDetailChargeCode.trim();
                snapCode += el.pfcDetailCurrency.trim();
                snapCode += el.pfcDetailPlaceOfPayment.trim();
                snapCode += el.pfcDetailPayer.trim();
                snapCode += (el.pfcDetailKillSlot == null) ? "0" : el.pfcDetailKillSlot.toString();
                snapCode += el.pfcDetailOogDoorOpen.trim();
                snapCode += (el.pfcDetailOogOH == null) ? "0" : el.pfcDetailOogOH.toString();
                snapCode += (el.pfcDetailOogOWL == null) ? "0" : el.pfcDetailOogOWL.toString();
                snapCode += (el.pfcDetailOogOWR == null) ? "0" : el.pfcDetailOogOWR.toString();
                snapCode += (el.pfcDetailOogOLF == null) ? "0" : el.pfcDetailOogOLF.toString();
                snapCode += (el.pfcDetailOogOLB == null) ? "0" : el.pfcDetailOogOLB.toString();
                snapCode += (el.pfcDetailOogUcH == null) ? "0" : el.pfcDetailOogUcH.toString();
                snapCode += (el.pfcDetailOogUcL == null) ? "0" : el.pfcDetailOogUcL.toString();
                snapCode += (el.pfcDetailOogUcW == null) ? "0" : el.pfcDetailOogUcW.toString();

                // console.log("** snapCodeSurcharge **");
                // console.log(snapCodeSurcharge);

                // console.log("** snapCode **");
                // console.log(snapCode);

                if(snapCodeSurcharge == snapCode){
                  // console.log("#SAMA");
                  chek = true;
                  var idx = dataList.indexOf(el);
                  dataList[idx].pfcDetailChargeCode = element.pfcDetailChargeCode;
                  dataList[idx].pfcDetailChargeName = element.pfcDetailChargeName;
                  dataList[idx].pfcDetailCurrency = element.pfcDetailCurrency;
                  dataList[idx].pfcDetailPayer = element.pfcDetailPayer;
                  dataList[idx].pfcDetailPayerName = element.pfcDetailPayerName;
                  dataList[idx].pfcDetailPC = element.pfcDetailPC;
                  dataList[idx].pfcDetailPCTab = element.pfcDetailPCTab;
                  dataList[idx].pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                  dataList[idx].pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                  dataList[idx].pfcDetailD20 = element.pfcDetailD20;
                  dataList[idx].pfcDetailD40 = element.pfcDetailD40;
                  dataList[idx].pfcDetailD45 = element.pfcDetailD45;
                  dataList[idx].pfcDetailH20 = element.pfcDetailH20;
                  dataList[idx].pfcDetailH40 = element.pfcDetailH40;
                  dataList[idx].pfcDetailH45 = element.pfcDetailH45;
                  dataList[idx].pfcDetailKillSlot = element.pfcDetailKillSlot;
                  dataList[idx].pfcDetailKT = element.pfcDetailKT;
                  dataList[idx].pfcDetailM3 = element.pfcDetailM3;
                  dataList[idx].pfcDetailMinCharge = element.pfcDetailMinCharge;
                  dataList[idx].pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                  dataList[idx].pfcDetailOogOH = element.pfcDetailOogOH;
                  dataList[idx].pfcDetailOogOLB = element.pfcDetailOogOLB;
                  dataList[idx].pfcDetailOogOLF = element.pfcDetailOogOLF;
                  dataList[idx].pfcDetailOogOWL = element.pfcDetailOogOWL;
                  dataList[idx].pfcDetailOogOWR = element.pfcDetailOogOWR;
                  dataList[idx].pfcDetailOogUcH = element.pfcDetailOogUcH;
                  dataList[idx].pfcDetailOogUcW = element.pfcDetailOogUcW;
                  dataList[idx].pfcDetailOogUcL = element.pfcDetailOogUcL;
                  dataList[idx].pfcDetailRT = element.pfcDetailRT;
                }else{
                  //data.push(element);
                  // console.log("#BEDA");


                }

              });

              // console.log("**chek**");
              // console.log(chek);

              if(!chek){
                if(element.pfcDetailPCTab == 'P'){
                  let ip:number = indexPrepaid;
                  element.pfcDetailSeqNo = (ip + 1);
                  element.no = (ip + 1);
                }

                if(element.pfcDetailPCTab == 'C'){
                  let ip:number = indexCollect;
                  element.pfcDetailSeqNo = (ip + 1);
                  element.no = (ip + 1);
                }
                surchargeList.push(element);
              }
            });


            let dets: FreightDetail[] = [];
            dataList.forEach(element => {
              dets.push(element)
            });
            surchargeList.forEach(element => {
              dets.push(element)
            });

            this.map.set(this.model.pfcContainerType,dets);

          }


          // console.log(this.map);

          this.gridPrepaid.onClear();
          this.gridCollect.onClear();

          var detailList: any[] = this.map.get(this.model.pfcContainerType)
          detailList.forEach(element => {

            // console.log(element);

            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = element.pfcDetailChargeCode;
            x.pfcDetailChargeName = element.pfcDetailChargeName;
            x.pfcDetailContainerType = element.pfcDetailContainerType;
            switch (x.pfcDetailContainerType) {
              case "TNK":
                x.pfcDetailContainerTypeName = 'TANK';
                break;
              case "OPT":
                x.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case "FLR":
                x.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case "PLT":
                x.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case "REF":
                x.pfcDetailContainerTypeName = 'REEFER';
                break;
              case "DRY":
                x.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case "UC":
                x.pfcDetailContainerTypeName = 'UC';
                break;
              case "STD":
                x.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
            }
            x.pfcDetailCopyTab = element.pfcDetailCopyTab;
            x.pfcDetailCurrency = element.pfcDetailCurrency;
            x.pfcDetailD20 = element.pfcDetailD20;
            x.pfcDetailD40 = element.pfcDetailD40;
            x.pfcDetailD45 = element.pfcDetailD45;
            x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
            x.pfcDetailH20 = element.pfcDetailH20;
            x.pfcDetailH40 = element.pfcDetailH40;
            x.pfcDetailH45 = element.pfcDetailH45;
            x.pfcDetailKT = element.pfcDetailKT;
            x.pfcDetailKillSlot = element.pfcDetailKillSlot;
            x.pfcDetailM3 = element.pfcDetailM3;
            x.pfcDetailMinCharge = element.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
            if(x.pfcDetailOogDoorOpen == "N"){
              x.pfcDetailOogDoorOpenName = "N/A";
            }else if(x.pfcDetailOogDoorOpen == "D"){
              x.pfcDetailOogDoorOpenName = "Detached";
            }else if(x.pfcDetailOogDoorOpen == "B"){
              x.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(x.pfcDetailOogDoorOpen == "L"){
              x.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(x.pfcDetailOogDoorOpen == "R"){
              x.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogOH = element.pfcDetailOogOH;
            x.pfcDetailOogOLB = element.pfcDetailOogOLB;
            x.pfcDetailOogOLF = element.pfcDetailOogOLF;
            x.pfcDetailOogOWL = element.pfcDetailOogOWL;
            x.pfcDetailOogOWR = element.pfcDetailOogOWR;
            x.pfcDetailOogUcH = element.pfcDetailOogUcH;
            x.pfcDetailOogUcL = element.pfcDetailOogUcL;
            x.pfcDetailOogUcW = element.pfcDetailOogUcW;
            x.pfcDetailPC = element.pfcDetailPC;
            x.pfcDetailPCTab = element.pfcDetailPCTab;
            x.pfcDetailPayer = element.pfcDetailPayer;
            x.pfcDetailPayerName = element.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = element.pfcDetailRT;
            x.pfcDetailSeqNo = element.pfcDetailSeqNo;
            x.pfcDetailStatus = element.pfcDetailStatus;
            x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

            if (x.pfcDetailPCTab == 'P') {
              this.gridPrepaid.listStore.store.push(x);
              this.gridPrepaid.loadData();
            }

            if (x.pfcDetailPCTab == 'C') {
              this.gridCollect.listStore.store.push(x);
              this.gridCollect.loadData();
            }

          });

      }else{
        this.message("information", "Information", resp.json()["message"] , "okonly", {ok: ''});
      }


      this.genericUtil.hideLoader();
    });

  }
  */

  clearSurcharge(){

    var oriList:any[] = [];
    if(this.map.get(this.model.pfcContainerType != null)){
      this.map.get(this.model.pfcContainerType).forEach(element => {
        if(element.pfcSurchargeId == ""){
          oriList.push(element)
        }
      });

      this.map.set(this.model.pfcContainerType,oriList)
      // console.log(this.map);

      this.gridPrepaid.onClear();
              this.gridCollect.onClear();

              var detailList: any[] = this.map.get(this.model.pfcContainerType)
              detailList.forEach(element => {

                // console.log(element);
                var x: FreightDetail = new FreightDetail();
                x.pfcSurchargeId = element.pfcSurchargeId;
                x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                x.pfcDetailChargeName = element.pfcDetailChargeName;
                x.pfcDetailContainerType = element.pfcDetailContainerType;
                switch (x.pfcDetailContainerType) {
                  case "TNK":
                    x.pfcDetailContainerTypeName = 'TANK';
                    break;
                  case "OPT":
                    x.pfcDetailContainerTypeName = 'OPEN TOP';
                    break;
                  case "FLR":
                    x.pfcDetailContainerTypeName = 'FLAT RACK';
                    break;
                  case "PLT":
                    x.pfcDetailContainerTypeName = 'PLATFORM';
                    break;
                  case "REF":
                    x.pfcDetailContainerTypeName = 'REEFER';
                    break;
                  case "DRY":
                    x.pfcDetailContainerTypeName = 'REEFER DRY';
                    break;
                  case "UC":
                    x.pfcDetailContainerTypeName = 'UC';
                    break;
                  case "STD":
                    x.pfcDetailContainerTypeName = 'NORMAL';
                    break;
                  default:
                    break;
                }
                x.pfcDetailCopyTab = element.pfcDetailCopyTab;
                x.pfcDetailCurrency = element.pfcDetailCurrency;
                x.pfcDetailD20 = element.pfcDetailD20;
                x.pfcDetailD40 = element.pfcDetailD40;
                x.pfcDetailD45 = element.pfcDetailD45;
                x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
                x.pfcDetailH20 = element.pfcDetailH20;
                x.pfcDetailH40 = element.pfcDetailH40;
                x.pfcDetailH45 = element.pfcDetailH45;
                x.pfcDetailKT = element.pfcDetailKT;
                x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                x.pfcDetailM3 = element.pfcDetailM3;
                x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                if(x.pfcDetailOogDoorOpen == "N"){
                  x.pfcDetailOogDoorOpenName = "N/A";
                }else if(x.pfcDetailOogDoorOpen == "D"){
                  x.pfcDetailOogDoorOpenName = "Detached";
                }else if(x.pfcDetailOogDoorOpen == "B"){
                  x.pfcDetailOogDoorOpenName = "Tied Both";
                }else if(x.pfcDetailOogDoorOpen == "L"){
                  x.pfcDetailOogDoorOpenName = "Tied Left";
                }else if(x.pfcDetailOogDoorOpen == "R"){
                  x.pfcDetailOogDoorOpenName = "Tied Right";
                }
                x.pfcDetailOogOH = element.pfcDetailOogOH;
                x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                x.pfcDetailPC = element.pfcDetailPC;
                x.pfcDetailPCTab = element.pfcDetailPCTab;
                x.pfcDetailPayer = element.pfcDetailPayer;
                x.pfcDetailPayerName = element.pfcDetailPayerName;
                x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                x.pfcDetailRT = element.pfcDetailRT;
                x.pfcDetailSeqNo = element.pfcDetailSeqNo;
                x.pfcDetailStatus = element.pfcDetailStatus;
                x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;


                if (x.pfcDetailPCTab == 'P') {
                  this.gridPrepaid.listStore.store.push(x);
                  this.gridPrepaid.loadData();
                }

                if (x.pfcDetailPCTab == 'C') {
                  this.gridCollect.listStore.store.push(x);
                  this.gridCollect.loadData();
                }

              });
    }
  }

  onSurcharge2()
  {
    // console.log("** on surcharge 2 **");



    this.genericUtil.showLoader();
    this.model.pfcOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.pfcEffectiveFrom = this.dateMaintenanceEffectiveFrom.getValue();
    this.model.pfcEffectiveTo = this.dateMaintenanceEffectiveTo.getValue();

    this.model.pfcDetails = [];
    //this.map.forEach((value: FreightDetail[], key: string) => {

      //value.forEach(element => {
    if(this.map.size > 0){

      this.clearSurcharge();

          this.map.get(this.model.pfcContainerType).forEach(element => {
            var x: FreightDetail = new FreightDetail();
            x.pfcSurchargeId = element.pfcSurchargeId;
            x.pfcDetailChargeCode = element.pfcDetailChargeCode;
            x.pfcDetailChargeName = element.pfcDetailChargeName;
            x.pfcDetailContainerType = element.pfcDetailContainerType;
            switch (x.pfcDetailContainerType) {
              case "TNK":
                x.pfcDetailContainerTypeName = 'TANK';
                break;
              case "OPT":
                x.pfcDetailContainerTypeName = 'OPEN TOP';
                break;
              case "FLR":
                x.pfcDetailContainerTypeName = 'FLAT RACK';
                break;
              case "PLT":
                x.pfcDetailContainerTypeName = 'PLATFORM';
                break;
              case "REF":
                x.pfcDetailContainerTypeName = 'REEFER';
                break;
              case "DRY":
                x.pfcDetailContainerTypeName = 'REEFER DRY';
                break;
              case "UC":
                x.pfcDetailContainerTypeName = 'UC';
                break;
              case "STD":
                x.pfcDetailContainerTypeName = 'NORMAL';
                break;
              default:
                break;
            }
            x.pfcDetailCopyTab = element.pfcDetailCopyTab;
            x.pfcDetailCurrency = element.pfcDetailCurrency;
            x.pfcDetailD20 = element.pfcDetailD20;
            x.pfcDetailD40 = element.pfcDetailD40;
            x.pfcDetailD45 = element.pfcDetailD45;
            x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
            x.pfcDetailH20 = element.pfcDetailH20;
            x.pfcDetailH40 = element.pfcDetailH40;
            x.pfcDetailH45 = element.pfcDetailH45;
            x.pfcDetailKT = element.pfcDetailKT;
            x.pfcDetailKillSlot = element.pfcDetailKillSlot;
            x.pfcDetailM3 = element.pfcDetailM3;
            x.pfcDetailMinCharge = element.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
            if(x.pfcDetailOogDoorOpen == "N"){
              x.pfcDetailOogDoorOpenName = "N/A";
            }else if(x.pfcDetailOogDoorOpen == "D"){
              x.pfcDetailOogDoorOpenName = "Detached";
            }else if(x.pfcDetailOogDoorOpen == "B"){
              x.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(x.pfcDetailOogDoorOpen == "L"){
              x.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(x.pfcDetailOogDoorOpen == "R"){
              x.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogOH = element.pfcDetailOogOH;
            x.pfcDetailOogOLB = element.pfcDetailOogOLB;
            x.pfcDetailOogOLF = element.pfcDetailOogOLF;
            x.pfcDetailOogOWL = element.pfcDetailOogOWL;
            x.pfcDetailOogOWR = element.pfcDetailOogOWR;
            x.pfcDetailOogUcH = element.pfcDetailOogUcH;
            x.pfcDetailOogUcL = element.pfcDetailOogUcL;
            x.pfcDetailOogUcW = element.pfcDetailOogUcW;
            x.pfcDetailPC = element.pfcDetailPC;
            x.pfcDetailPCTab = element.pfcDetailPCTab;
            x.pfcDetailPayer = element.pfcDetailPayer;
            x.pfcDetailPayerName = element.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = element.pfcDetailRT;
            x.pfcDetailSeqNo = element.pfcDetailSeqNo;
            x.pfcDetailStatus = element.pfcDetailStatus;
            x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

            if ((this.currentActiveTab == "prepaid" && x.pfcDetailPCTab == 'P') || (this.currentActiveTab == "collect" && x.pfcDetailPCTab == 'C')) {
              this.model.pfcDetails.push(x);
            }
          });
        //});

        this.genericService.POST(this.configService.config.BASE_API.toString() + '/SurchargeMaster/gettingSurcharge',this.model).subscribe((resp) => {

          if(resp.json()["status"] == "OK"){

              var dataSurcharges:FreightDetail[] = [];
              var dt:any[] = [];
              dt = resp.json()["content"];

              dt.forEach(element => {
                // console.log("** dt ==> ")
                // console.log(element);

                var freightDetail:FreightDetail = new FreightDetail();
                freightDetail.pfcSurchargeId = element['surchargeId'];
                freightDetail.pfcDetailChargeCode = element['chargeCode'];
                freightDetail.pfcDetailChargeName = element['chargeName'];
                freightDetail.pfcDetailContainerType = element['containerType'];
                switch (freightDetail.pfcDetailContainerType) {
                  case "TNK":
                    freightDetail.pfcDetailContainerTypeName = 'TANK';
                    break;
                  case "OPT":
                    freightDetail.pfcDetailContainerTypeName = 'OPEN TOP';
                    break;
                  case "FLR":
                    freightDetail.pfcDetailContainerTypeName = 'FLAT RACK';
                    break;
                  case "PLT":
                    freightDetail.pfcDetailContainerTypeName = 'PLATFORM';
                    break;
                  case "REF":
                    freightDetail.pfcDetailContainerTypeName = 'REEFER';
                    break;
                  case "DRY":
                    freightDetail.pfcDetailContainerTypeName = 'REEFER DRY';
                    break;
                  case "UC":
                    freightDetail.pfcDetailContainerTypeName = 'UC';
                    break;
                  case "STD":
                    freightDetail.pfcDetailContainerTypeName = 'NORMAL';
                    break;
                  default:
                    break;
                }
                freightDetail.pfcDetailCurrency = element['currencyCode'];
                freightDetail.pfcDetailPC = element['freightType'];
                freightDetail.pfcDetailPCTab = element['pcTab'];
                if(element['payerCode'] == ''){
                  freightDetail.pfcDetailPayer = "";
                  freightDetail.pfcDetailPayerName = "";
                }else{
                  freightDetail.pfcDetailPayer = element['payerCode'];
                  freightDetail.pfcDetailPayerName = element['payerName'];
                }

                freightDetail.pfcDetailPlaceOfPayment = element['placeOfPaymentCode'];
                freightDetail.pfcDetailPlaceOfPaymentName = element['placeOfPaymentName'];

                freightDetail.pfcDetailD20 = 0;
                freightDetail.pfcDetailD40 = 0;
                freightDetail.pfcDetailD45 = 0;
                freightDetail.pfcDetailH20 = 0;
                freightDetail.pfcDetailH40 = 0;
                freightDetail.pfcDetailH45 = 0;

                if(element['D20'] != undefined){
                  freightDetail.pfcDetailD20 = Number.parseFloat(element['D20']);
                }

                if(element['D40'] != undefined){
                  freightDetail.pfcDetailD40 = Number.parseFloat(element['D40']);
                }

                if(element['D45'] != undefined){
                  freightDetail.pfcDetailD45 = Number.parseFloat(element['D45']);
                }

                if(element['H20'] != undefined){
                  freightDetail.pfcDetailH20 = Number.parseFloat(element['H20']);
                }

                if(element['H40'] != undefined){
                  freightDetail.pfcDetailH40 = Number.parseFloat(element['H40']);
                }

                if(element['H45'] != undefined){
                  freightDetail.pfcDetailH45 = Number.parseFloat(element['H45']);
                }

                freightDetail.pfcDetailOogDoorOpen = element['doorOpen'];
                if(freightDetail.pfcDetailOogDoorOpen == "N"){
                  freightDetail.pfcDetailOogDoorOpenName = "N/A";
                }else if(freightDetail.pfcDetailOogDoorOpen == "D"){
                  freightDetail.pfcDetailOogDoorOpenName = "Detached";
                }else if(freightDetail.pfcDetailOogDoorOpen == "B"){
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Both";
                }else if(freightDetail.pfcDetailOogDoorOpen == "L"){
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Left";
                }else if(freightDetail.pfcDetailOogDoorOpen == "R"){
                  freightDetail.pfcDetailOogDoorOpenName = "Tied Right";
                }
                freightDetail.pfcDetailOogOH = element['oh'];
                freightDetail.pfcDetailOogOLB = element['olb'];
                freightDetail.pfcDetailOogOLF = element['olf'];
                freightDetail.pfcDetailOogOWL = element['owl'];
                freightDetail.pfcDetailOogOWR = element['owr'];
                freightDetail.pfcDetailOogUcH = element['uch'];
                freightDetail.pfcDetailOogUcL = element['ucl'];
                freightDetail.pfcDetailOogUcW = element['ucw'];

                // console.log("** freightDetail ==> ")
                // console.log(freightDetail);
                dataSurcharges.push(freightDetail);



              });

              if (this.map.get(this.model.pfcContainerType) == undefined) {
                // console.log("#1");
                let indexPrepaid:number = 0;
                let indexCollect:number = 0;

                dataSurcharges.forEach(element => {

                  if(element.pfcDetailPCTab == 'P'){
                    indexPrepaid++;
                  }

                  if(element.pfcDetailPCTab == 'C'){
                    indexCollect++;
                  }

                  if(element.pfcDetailPCTab == 'P'){
                    element.pfcDetailSeqNo = indexPrepaid;
                    element.no = indexPrepaid;
                  }

                  if(element.pfcDetailPCTab == 'C'){
                    element.pfcDetailSeqNo = indexCollect;
                    element.no = indexCollect;
                  }

                });

                this.map.set(this.model.pfcContainerType, dataSurcharges);
              }else{

                // console.log("#2");
                let data:FreightDetail[] = this.map.get(this.model.pfcContainerType);

                let indexPrepaid:number = 1;
                let indexCollect:number = 1;

                let dataList:FreightDetail[] = [];
                data.forEach(element => {

                    var x:FreightDetail= new FreightDetail();
                    x.pfcSurchargeId = element.pfcSurchargeId;
                    x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                    x.pfcDetailChargeName = element.pfcDetailChargeName;
                    x.pfcDetailContainerType = element.pfcDetailContainerType;
                    switch (x.pfcDetailContainerType) {
                      case "TNK":
                        x.pfcDetailContainerTypeName = 'TANK';
                        break;
                      case "OPT":
                        x.pfcDetailContainerTypeName = 'OPEN TOP';
                        break;
                      case "FLR":
                        x.pfcDetailContainerTypeName = 'FLAT RACK';
                        break;
                      case "PLT":
                        x.pfcDetailContainerTypeName = 'PLATFORM';
                        break;
                      case "REF":
                        x.pfcDetailContainerTypeName = 'REEFER';
                        break;
                      case "DRY":
                        x.pfcDetailContainerTypeName = 'REEFER DRY';
                        break;
                      case "UC":
                        x.pfcDetailContainerTypeName = 'UC';
                        break;
                      case "STD":
                        x.pfcDetailContainerTypeName = 'NORMAL';
                        break;
                      default:
                        break;
                    }
                    x.pfcDetailCurrency = element.pfcDetailCurrency;
                    x.pfcDetailPayer = element.pfcDetailPayer;
                    x.pfcDetailPayerName = element.pfcDetailPayerName;
                    x.pfcDetailPC = element.pfcDetailPC;
                    x.pfcDetailPCTab = element.pfcDetailPCTab;
                    x.pfcDetailSeqNo = element.pfcDetailSeqNo;

                    x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                    x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                    x.pfcDetailD20 = element.pfcDetailD20;
                    x.pfcDetailD40 = element.pfcDetailD40;
                    x.pfcDetailD45 = element.pfcDetailD45;
                    x.pfcDetailH20 = element.pfcDetailH20;
                    x.pfcDetailH40 = element.pfcDetailH40;
                    x.pfcDetailH45 = element.pfcDetailH45;
                    x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                    x.pfcDetailKT = element.pfcDetailKT;
                    x.pfcDetailM3 = element.pfcDetailM3;
                    x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                    x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                    if(x.pfcDetailOogDoorOpen == "N"){
                      x.pfcDetailOogDoorOpenName = "N/A";
                    }else if(x.pfcDetailOogDoorOpen == "D"){
                      x.pfcDetailOogDoorOpenName = "Detached";
                    }else if(x.pfcDetailOogDoorOpen == "B"){
                      x.pfcDetailOogDoorOpenName = "Tied Both";
                    }else if(x.pfcDetailOogDoorOpen == "L"){
                      x.pfcDetailOogDoorOpenName = "Tied Left";
                    }else if(x.pfcDetailOogDoorOpen == "R"){
                      x.pfcDetailOogDoorOpenName = "Tied Right";
                    }
                    x.pfcDetailOogOH = element.pfcDetailOogOH;
                    x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                    x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                    x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                    x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                    x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                    x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                    x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                    x.pfcDetailRT = element.pfcDetailRT;
                    dataList.push(x);

                });

                let surchargeList:FreightDetail[] = [];

                dataSurcharges.forEach(element => {

                  let snapCodeSurcharge: string = "";
                  snapCodeSurcharge += element.pfcDetailPC.trim();
                  snapCodeSurcharge += element.pfcDetailPCTab.trim();
                  snapCodeSurcharge += element.pfcDetailChargeCode.trim();
                  snapCodeSurcharge += element.pfcDetailCurrency.trim();
                  snapCodeSurcharge += element.pfcDetailPlaceOfPayment.trim();
                  snapCodeSurcharge += element.pfcDetailPayer.trim();
                  snapCodeSurcharge += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
                  snapCodeSurcharge += element.pfcDetailOogDoorOpen.trim();
                  snapCodeSurcharge += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toString();
                  snapCodeSurcharge += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toString();
                  snapCodeSurcharge += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toString();
                  snapCodeSurcharge += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toString();
                  snapCodeSurcharge += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toString();
                  snapCodeSurcharge += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toString();
                  snapCodeSurcharge += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toString();
                  snapCodeSurcharge += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toString();

                  let chek:boolean = false;
                  dataList.forEach(el => {

                    let snapCode: string = "";
                    snapCode += el.pfcDetailPC.trim();
                    snapCode += el.pfcDetailPCTab.trim();
                    snapCode += el.pfcDetailChargeCode.trim();
                    snapCode += el.pfcDetailCurrency.trim();
                    snapCode += el.pfcDetailPlaceOfPayment.trim();
                    snapCode += el.pfcDetailPayer.trim();
                    snapCode += (el.pfcDetailKillSlot == null) ? "0" : el.pfcDetailKillSlot.toString();
                    snapCode += el.pfcDetailOogDoorOpen.trim();
                    snapCode += (el.pfcDetailOogOH == null) ? "0" : el.pfcDetailOogOH.toString();
                    snapCode += (el.pfcDetailOogOWL == null) ? "0" : el.pfcDetailOogOWL.toString();
                    snapCode += (el.pfcDetailOogOWR == null) ? "0" : el.pfcDetailOogOWR.toString();
                    snapCode += (el.pfcDetailOogOLF == null) ? "0" : el.pfcDetailOogOLF.toString();
                    snapCode += (el.pfcDetailOogOLB == null) ? "0" : el.pfcDetailOogOLB.toString();
                    snapCode += (el.pfcDetailOogUcH == null) ? "0" : el.pfcDetailOogUcH.toString();
                    snapCode += (el.pfcDetailOogUcL == null) ? "0" : el.pfcDetailOogUcL.toString();
                    snapCode += (el.pfcDetailOogUcW == null) ? "0" : el.pfcDetailOogUcW.toString();

                    // console.log("** snapCodeSurcharge **");
                    // console.log(snapCodeSurcharge);

                    // console.log("** snapCode **");
                    // console.log(snapCode);

                    if(snapCodeSurcharge == snapCode){
                      // console.log("#SAMA");
                      chek = true;
                      var idx = dataList.indexOf(el);
                      dataList[idx].pfcSurchargeId = element.pfcSurchargeId;
                      dataList[idx].pfcDetailChargeCode = element.pfcDetailChargeCode;
                      dataList[idx].pfcDetailChargeName = element.pfcDetailChargeName;
                      dataList[idx].pfcDetailCurrency = element.pfcDetailCurrency;
                      dataList[idx].pfcDetailPayer = element.pfcDetailPayer;
                      dataList[idx].pfcDetailPayerName = element.pfcDetailPayerName;
                      dataList[idx].pfcDetailPC = element.pfcDetailPC;
                      dataList[idx].pfcDetailPCTab = element.pfcDetailPCTab;
                      dataList[idx].pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                      dataList[idx].pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                      dataList[idx].pfcDetailD20 = element.pfcDetailD20;
                      dataList[idx].pfcDetailD40 = element.pfcDetailD40;
                      dataList[idx].pfcDetailD45 = element.pfcDetailD45;
                      dataList[idx].pfcDetailH20 = element.pfcDetailH20;
                      dataList[idx].pfcDetailH40 = element.pfcDetailH40;
                      dataList[idx].pfcDetailH45 = element.pfcDetailH45;
                      dataList[idx].pfcDetailKillSlot = element.pfcDetailKillSlot;
                      dataList[idx].pfcDetailKT = element.pfcDetailKT;
                      dataList[idx].pfcDetailM3 = element.pfcDetailM3;
                      dataList[idx].pfcDetailMinCharge = element.pfcDetailMinCharge;
                      dataList[idx].pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                      dataList[idx].pfcDetailOogOH = element.pfcDetailOogOH;
                      dataList[idx].pfcDetailOogOLB = element.pfcDetailOogOLB;
                      dataList[idx].pfcDetailOogOLF = element.pfcDetailOogOLF;
                      dataList[idx].pfcDetailOogOWL = element.pfcDetailOogOWL;
                      dataList[idx].pfcDetailOogOWR = element.pfcDetailOogOWR;
                      dataList[idx].pfcDetailOogUcH = element.pfcDetailOogUcH;
                      dataList[idx].pfcDetailOogUcW = element.pfcDetailOogUcW;
                      dataList[idx].pfcDetailOogUcL = element.pfcDetailOogUcL;
                      dataList[idx].pfcDetailRT = element.pfcDetailRT;
                    }else{
                      //data.push(element);
                      // console.log("#BEDA");


                    }

                  });

                  // console.log("**chek**");
                  // console.log(chek);

                  if(!chek){
                    if(element.pfcDetailPCTab == 'P'){
                      indexPrepaid++
                      element.pfcDetailSeqNo = indexPrepaid;
                      element.no = indexPrepaid;
                    }

                    if(element.pfcDetailPCTab == 'C'){
                      indexCollect++
                      element.pfcDetailSeqNo = indexCollect;
                      element.no = indexCollect;
                    }
                    surchargeList.push(element);
                  }
                });


                let dets: FreightDetail[] = [];
                dataList.forEach(element => {
                  dets.push(element)
                });
                surchargeList.forEach(element => {
                  dets.push(element)
                });

                this.map.set(this.model.pfcContainerType,dets);

             }


              // console.log("** RESULT SURCHARGE **")
              // console.log(this.map);

              this.gridPrepaid.onClear();
              this.gridCollect.onClear();

              var detailList: any[] = this.map.get(this.model.pfcContainerType)
              detailList.forEach(element => {

                // console.log(element);
                var x: FreightDetail = new FreightDetail();
                x.pfcSurchargeId = element.pfcSurchargeId;
                x.pfcDetailChargeCode = element.pfcDetailChargeCode;
                x.pfcDetailChargeName = element.pfcDetailChargeName;
                x.pfcDetailContainerType = element.pfcDetailContainerType;
                switch (x.pfcDetailContainerType) {
                  case "TNK":
                    x.pfcDetailContainerTypeName = 'TANK';
                    break;
                  case "OPT":
                    x.pfcDetailContainerTypeName = 'OPEN TOP';
                    break;
                  case "FLR":
                    x.pfcDetailContainerTypeName = 'FLAT RACK';
                    break;
                  case "PLT":
                    x.pfcDetailContainerTypeName = 'PLATFORM';
                    break;
                  case "REF":
                    x.pfcDetailContainerTypeName = 'REEFER';
                    break;
                  case "DRY":
                    x.pfcDetailContainerTypeName = 'REEFER DRY';
                    break;
                  case "UC":
                    x.pfcDetailContainerTypeName = 'UC';
                    break;
                  case "STD":
                    x.pfcDetailContainerTypeName = 'NORMAL';
                    break;
                  default:
                    break;
                }
                x.pfcDetailCopyTab = element.pfcDetailCopyTab;
                x.pfcDetailCurrency = element.pfcDetailCurrency;
                x.pfcDetailD20 = element.pfcDetailD20;
                x.pfcDetailD40 = element.pfcDetailD40;
                x.pfcDetailD45 = element.pfcDetailD45;
                x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
                x.pfcDetailH20 = element.pfcDetailH20;
                x.pfcDetailH40 = element.pfcDetailH40;
                x.pfcDetailH45 = element.pfcDetailH45;
                x.pfcDetailKT = element.pfcDetailKT;
                x.pfcDetailKillSlot = element.pfcDetailKillSlot;
                x.pfcDetailM3 = element.pfcDetailM3;
                x.pfcDetailMinCharge = element.pfcDetailMinCharge;
                x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
                if(x.pfcDetailOogDoorOpen == "N"){
                  x.pfcDetailOogDoorOpenName = "N/A";
                }else if(x.pfcDetailOogDoorOpen == "D"){
                  x.pfcDetailOogDoorOpenName = "Detached";
                }else if(x.pfcDetailOogDoorOpen == "B"){
                  x.pfcDetailOogDoorOpenName = "Tied Both";
                }else if(x.pfcDetailOogDoorOpen == "L"){
                  x.pfcDetailOogDoorOpenName = "Tied Left";
                }else if(x.pfcDetailOogDoorOpen == "R"){
                  x.pfcDetailOogDoorOpenName = "Tied Right";
                }
                x.pfcDetailOogOH = element.pfcDetailOogOH;
                x.pfcDetailOogOLB = element.pfcDetailOogOLB;
                x.pfcDetailOogOLF = element.pfcDetailOogOLF;
                x.pfcDetailOogOWL = element.pfcDetailOogOWL;
                x.pfcDetailOogOWR = element.pfcDetailOogOWR;
                x.pfcDetailOogUcH = element.pfcDetailOogUcH;
                x.pfcDetailOogUcL = element.pfcDetailOogUcL;
                x.pfcDetailOogUcW = element.pfcDetailOogUcW;
                x.pfcDetailPC = element.pfcDetailPC;
                x.pfcDetailPCTab = element.pfcDetailPCTab;
                x.pfcDetailPayer = element.pfcDetailPayer;
                x.pfcDetailPayerName = element.pfcDetailPayerName;
                x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
                x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
                x.pfcDetailRT = element.pfcDetailRT;
                x.pfcDetailSeqNo = element.pfcDetailSeqNo;
                x.pfcDetailStatus = element.pfcDetailStatus;
                x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;



                if (x.pfcDetailPCTab == 'P') {
                  this.gridPrepaid.listStore.store.push(x);
                  this.gridPrepaid.loadData();
                }

                if (x.pfcDetailPCTab == 'C') {
                  this.gridCollect.listStore.store.push(x);
                  this.gridCollect.loadData();
                }

              });

          }else{
            this.clearSurcharge();
            this.message("information", "Information", resp.json()["message"] , "okonly", {ok: ''});
          }

          this.genericUtil.hideLoader();
        });

      }else{
        this.message("information","Information","Surcharge not found. Please fill in the compulsory fields.", "okonly",{ ok: ''})
        this.genericUtil.hideLoader();
      }

  }

  onTransmit()
  {
    this.message('information', 'Information', 'Do you wish to transmit freight ' + this.model.pfcRecordId + '?', 'yesno',
    {
      yes: 'this.transmitProcess()',
      no: ''
    });


  }

  transmitProcess(){
    this.genericUtil.showLoader();
    let data:FreightManagement =  new FreightManagement();
    data.pfcRecordId = this.model.pfcRecordId;
    data.pfcIsTransmitted = 'Y';
    data.pfcUserTransmitted = this.cookieService.getName().toUpperCase();
    data.pfcDateTransmitted = this.genericUtil.getPSIDate();

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCFreightApplication/transmit',data).subscribe((resp) => {
      // console.log(resp);
      this.genericUtil.hideLoader();
      if(resp.statusText == "OK"){
        this.appStatusLabel = "   ---   PENDING APPROVAL   ---";
        this.lock = true;
        this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
        this.message("information", "Information", "Transmitted Successfully.", "okonly", {ok: ''});
      }else{

      }
    });
  }

  onClone()
  {
    this.message("information", "Information", "Not yet implemented.", "okonly", {ok: ''});
  }


  //action when user click on button new
  onDetailNew(){
    this.isUpdateDetail = false;

    // console.log("** on detail new **");
    this.modelDetail = new FreightDetail();
    this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
    this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailCurrency);
    this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
    this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);
    this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

    if (this.currentActiveTab == "prepaid") {
      this.modelDetail.pfcDetailSeqNo = this.gridPrepaid.listStore.store.length + 1;

      //worker for place of payment
      if (this.model.pfcLoadPortCode != null && this.model.pfcLoadPortCode != undefined && this.model.pfcLoadPortCode != "" && this.model.pfcLoadPortCode != "VNHCX" && this.model.pfcLoadPortCode != "IDJKX" && this.model.pfcLoadPortCode != "PKKHX" && this.model.pfcLoadPortCode != "AEDBX" && this.model.pfcLoadPortCode != "PHMNX" && this.model.pfcLoadPortCode != "MYPKX" && this.model.pfcLoadPortCode != "THBKX"){
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcLoadPortCode);
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }
      //worker for payer
      // console.log("this.cookieService.getDefaultLocationCountry().toUpperCase() ==> " + this.cookieService.getDefaultLocationCountry().toUpperCase());
      // console.log("this.model.pfcFreightPartyCountry.toUpperCase() ==> " + this.model.pfcFreightPartyCountry.toUpperCase());

      if(this.cookieService.getDefaultLocationCountry().toUpperCase() == this.model.pfcFreightPartyCountry.toUpperCase()){
        if (this.model.pfcFreightPartyId != null && this.model.pfcFreightPartyId != undefined && this.model.pfcFreightPartyId != "") {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
          this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId);
        } else {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("");
        }
      }else{
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("**");
      }
    } else {
      this.modelDetail.pfcDetailSeqNo = this.gridCollect.listStore.store.length + 1;

      //worker for place of payment
      if (this.model.pfcDischargePortCode != null && this.model.pfcDischargePortCode != undefined && this.model.pfcDischargePortCode != ""  && this.model.pfcLoadPortCode != "VNHCX" && this.model.pfcLoadPortCode != "IDJKX" && this.model.pfcLoadPortCode != "PKKHX" && this.model.pfcLoadPortCode != "AEDBX" && this.model.pfcLoadPortCode != "PHMNX" && this.model.pfcLoadPortCode != "MYPKX" && this.model.pfcLoadPortCode != "THBKX") {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue(this.model.pfcDischargePortCode);
      } else {
        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationNamePOP={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue("");
      }
      //worker for payer
      if(this.cookieService.getDefaultLocationCountry().toUpperCase() == this.model.pfcFreightPartyCountry.toUpperCase()){
        if (this.model.pfcFreightPartyId != null && this.model.pfcFreightPartyId != undefined && this.model.pfcFreightPartyId != "") {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
          this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId);

        } else {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("");
        }
      }else{
        this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
        this.cbMaintenanceDetailPayer.setValue("**");
      }
    }

    //container type worker
    this.modelDetail.pfcDetailContainerType = this.model.pfcContainerType;
    switch (this.modelDetail.pfcDetailContainerType) {
      case "STD":
        this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
        break;
      case "DRY":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
        break;
      case "REF":
        this.modelDetail.pfcDetailContainerTypeName = "REEFER";
        break;
      case "TNK":
        this.modelDetail.pfcDetailContainerTypeName = "TANK";
        break;
      case "OPT":
        this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
        break;
      case "PLT":
        this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
        break;
      case "FLR":
        this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
        break;
      case "UC":
        this.modelDetail.pfcDetailContainerTypeName = "UC";
        break;
      default:
        break;
    }

    //OOG Control
    //Infer from selected container type
    if (this.modelDetail.pfcDetailContainerType == "STD" || this.modelDetail.pfcDetailContainerType == "DRY" || this.modelDetail.pfcDetailContainerType == "UC") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = false;
      this.lockOOGUC = false;
    } else if (this.modelDetail.pfcDetailContainerType == "REF" || this.modelDetail.pfcDetailContainerType == "TNK") {
      this.lockOOGOOG = true;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    } else if (this.modelDetail.pfcDetailContainerType == "OPT" || this.modelDetail.pfcDetailContainerType == "PLT" || this.modelDetail.pfcDetailContainerType == "FLR") {
      this.lockOOGOOG = false;
      this.lockOOGDoorOpen = true;
      this.lockOOGUC = false;
    }

    //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('show');
    this.genericUtil.showDialog('freightNewChargeForm', "Freight Entry Screen", 1200, 500)
  }


  onDetailUpdate()
  {
    // console.log("** on detail update **")


    this.isErrorDetail = false;
    this.clearValidatorErrorDetail();

    if (this.gridPrepaid.getSelectedValues().length == 0 && this.gridCollect.getSelectedValues().length == 0) {
      this.message("information", "information", "Please select a record to update.", "okonly", {ok: "this.loading=false;"})
    } else {
       //set update flag to true
       this.isUpdateDetail = true;
       let md = new FreightDetail();
       if (this.currentActiveTab == "prepaid") {
         // console.log("** prepaid data")
         this.gridPrepaid.getSelectedValues().forEach(col=> {
            // console.log(col)
         });

         md = this.gridPrepaid.getSelectedValues()[0];
       } else {
          // console.log("** collect data")
          this.gridCollect.getSelectedValues().forEach(col=> {
            // console.log(col)
          });

          md = this.gridCollect.getSelectedValues()[0];
       }

        this.modelDetail = new FreightDetail();
        this.modelDetail.pfcDetailChargeCode = md.pfcDetailChargeCode;
        this.modelDetail.pfcDetailChargeName = md.pfcDetailChargeName;
        this.modelDetail.pfcDetailContainerType = md.pfcDetailContainerType;
        switch (this.modelDetail.pfcDetailContainerType) {
          case "STD":
            this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            this.modelDetail.pfcDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            this.modelDetail.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            this.modelDetail.pfcDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        this.modelDetail.pfcDetailCurrency = md.pfcDetailCurrency;
        this.modelDetail.pfcDetailD20 = md.pfcDetailD20;
        this.modelDetail.pfcDetailD40 = md.pfcDetailD40;
        this.modelDetail.pfcDetailD45 = md.pfcDetailD45;
        this.modelDetail.pfcDetailFAIGroup = md.pfcDetailFAIGroup;
        this.modelDetail.pfcDetailH20 = md.pfcDetailH20;
        this.modelDetail.pfcDetailH40 = md.pfcDetailH40;
        this.modelDetail.pfcDetailH45 = md.pfcDetailH45;
        this.modelDetail.pfcDetailKT = md.pfcDetailKT;
        this.modelDetail.pfcDetailKillSlot = md.pfcDetailKillSlot;
        this.modelDetail.pfcDetailM3 = md.pfcDetailM3;
        this.modelDetail.pfcDetailMinCharge = md.pfcDetailMinCharge;
        this.modelDetail.pfcDetailOogDoorOpen = md.pfcDetailOogDoorOpen;
        if(this.modelDetail.pfcDetailOogDoorOpen == "N"){
          this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
        }else if(this.modelDetail.pfcDetailOogDoorOpen == "D"){
          this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
        }else if(this.modelDetail.pfcDetailOogDoorOpen == "B"){
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(this.modelDetail.pfcDetailOogDoorOpen == "L"){
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(this.modelDetail.pfcDetailOogDoorOpen == "R"){
          this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
        }
        this.modelDetail.pfcDetailOogOH = md.pfcDetailOogOH;
        this.modelDetail.pfcDetailOogOLB = md.pfcDetailOogOLB;
        this.modelDetail.pfcDetailOogOLF = md.pfcDetailOogOLF;
        this.modelDetail.pfcDetailOogOWL = md.pfcDetailOogOWL;
        this.modelDetail.pfcDetailOogOWR = md.pfcDetailOogOWR;
        this.modelDetail.pfcDetailOogUcH = md.pfcDetailOogUcH;
        this.modelDetail.pfcDetailOogUcL = md.pfcDetailOogUcL;
        this.modelDetail.pfcDetailOogUcW = md.pfcDetailOogUcW;
        this.modelDetail.pfcDetailPC = md.pfcDetailPC;
        this.modelDetail.pfcDetailPCTab = md.pfcDetailPCTab;
        this.modelDetail.pfcDetailPayer = md.pfcDetailPayer;
        this.modelDetail.pfcDetailPayerName = md.pfcDetailPayerName;
        this.modelDetail.pfcDetailPlaceOfPayment = md.pfcDetailPlaceOfPayment;
        this.modelDetail.pfcDetailPlaceOfPaymentName = md.pfcDetailPlaceOfPaymentName;
        this.modelDetail.pfcDetailRT = md.pfcDetailRT;
        this.modelDetail.pfcDetailSeqNo = md.pfcDetailSeqNo;
        this.modelDetail.pfcDetailStatus = md.pfcDetailStatus;

        this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
        this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailCurrency);
        this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);

        this.cbMaintenanceDetailPlaceOfPayment.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);

        this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
        this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);

        //OOG Control
        //Infer from selected container type
        if (this.modelDetail.pfcDetailContainerType == "STD" || this.modelDetail.pfcDetailContainerType == "DRY" || this.modelDetail.pfcDetailContainerType == "UC") {
          this.lockOOGOOG = true;
          this.lockOOGDoorOpen = false;
          this.lockOOGUC = false;
        } else if (this.modelDetail.pfcDetailContainerType == "REF" || this.modelDetail.pfcDetailContainerType == "TNK") {
          this.lockOOGOOG = true;
          this.lockOOGDoorOpen = true;
          this.lockOOGUC = false;
        } else if (this.modelDetail.pfcDetailContainerType == "OPT" || this.modelDetail.pfcDetailContainerType == "PLT" || this.modelDetail.pfcDetailContainerType == "FLR") {
          this.lockOOGOOG = false;
          this.lockOOGDoorOpen = true;
          this.lockOOGUC = false;
        }

        this.genericUtil.showDialog('freightNewChargeForm', "Freight Entry Screen", 1200, 500)

    }
  }

  onDetailRemove() {
    // console.log("** on detail remove **");
    if (this.currentActiveTab == "prepaid") {
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to delete.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to delete this charge(s)? ', 'yesno', {
          yes: 'this.onDetailRemovePrepaid();',
          no: 'this.gridPrepaid.clearSelectedValues();'
        });
      }
    }

    if (this.currentActiveTab == "collect") {
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to delete.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to delete this charge(s)? ', 'yesno', {
          yes: 'this.onDetailRemoveCollect();',
          no: 'this.gridCollect.clearSelectedValues();'
        });
      }
    }

  }

  onDetailRemovePrepaid() {

    // console.log("** on detail remove prepaid **");
    // console.log("this.model.pfcContainerType ==> " + this.model.pfcContainerType);

    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'C') {
        collectList.push(element);
      }
    });

    this.gridPrepaid.getSelectedValues().forEach(element=> {
      this.gridPrepaid.listStore.removeData(element);
    });

    let index = 1;
    this.gridPrepaid.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index;
      element.no = index;

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (element.pfcDetailContainerType) {
        case "STD":
          element.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = index;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.no = index;

      prepaidList.push(x);
      index++;
    });


    var self = this;
    setTimeout(() => {

      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.pfcContainerType, dets);
      self.gridPrepaid.clearSelectedValues();
      // console.log(self.map);

      if(self.gridPrepaid.listStore.store.length == 0){
        self.gridPrepaid.onClear()
      }

      self.gridPrepaid.loadData();
      self.gridCollect.loadData();
    }, 10);

  }


  onDetailRemoveCollect() {
    // console.log("** on detail remove collect **");

    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];
    let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
    fd.forEach(element => {
      if (element.pfcDetailPCTab == 'P') {
        prepaidList.push(element);
      }
    });

    this.gridCollect.getSelectedValues().forEach(element=> {
      this.gridCollect.listStore.removeData(element);
    });

    let index = 1;
    this.gridCollect.listStore.store.forEach(element => {
      element.pfcDetailSeqNo = index;
      element.no = index;

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;

      switch (element.pfcDetailContainerType) {
        case "STD":
          element.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;

      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = index;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.no = index;

      collectList.push(x);
      index++;
    });

    var self = this;
    setTimeout(() => {

      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      self.map.set(self.model.pfcContainerType, dets);
      self.gridCollect.clearSelectedValues();
      // console.log(self.map);

      if(self.gridCollect.listStore.store.length == 0){
        self.gridCollect.onClear()
      }

      self.gridPrepaid.loadData();
      self.gridCollect.loadData();
    }, 10);


  }

  onDetailCopy() {
    if (this.currentActiveTab == "prepaid") {
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to copy prepaid.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
          yes: 'this.onDetailCopyPrepaid();',
          no: 'this.gridPrepaid.clearSelectedValues();this.loading = false;'
        });
      }
    }

    if (this.currentActiveTab == "collect") {
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to copy collect.", "okonly", {ok: "this.loading=false;"})
      } else {
        this.message('information', 'Information', 'Are you sure you want to copy this charge(s)? ', 'yesno', {
          yes: 'this.onDetailCopyCollect();',
          no: 'this.gridCollect.clearSelectedValues();this.loading = false;'
        });
      }
    }

  }

  onDetailCopyPrepaid(){
    // console.log("** on detail copy prepaid **");
      let mp:Map<any,any> = this.onDetailCopyPrepaidCheckDuplicate()
      // console.log(mp);

      mp.forEach((value: string, key: boolean) => {
        // console.log(key);
        if(key){
          this.message("information", "information", "Duplicate " + value + " charge code detected.", "okonly", {ok: "this.gridPrepaid.clearSelectedValues();"});
        }else{
          this.onDetailCopyPrepaidProcess();
          this.gridPrepaid.clearSelectedValues();
          this.onDetailAfterCopy(true);
        }
      });


  }

  onDetailCopyPrepaidCheckDuplicate(dataPrepaid ? : FreightDetail) :Map<any,any>{
    // console.log("** on detail copy prepaid check duplicate **");
    // console.log(dataPrepaid);

    let mp = new Map();

    let isErrorDetail: boolean = false;
    let chargeDuplicate:string = "";

    if(dataPrepaid != undefined){

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
      x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case "REF":
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case "UC":
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case "STD":
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
      x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
      x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
      x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
      x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
      x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
      x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
      x.pfcDetailKT = dataPrepaid.pfcDetailKT;
      x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
      x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
      x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
      x.pfcDetailPC = dataPrepaid.pfcDetailPC;
      x.pfcDetailPCTab = "C";
      x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
      x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataPrepaid.pfcDetailRT;
      x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
      x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy('collect',x);
      if(isErrorDetail){
        chargeDuplicate = dataPrepaid.pfcDetailChargeCode.toUpperCase().trim();
      }

    }else{
      this.gridPrepaid.getSelectedValues().forEach(element=> {

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "C";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if(isErrorDetail == false){
          isErrorDetail = this.checkDuplicateCopy('collect',x);
          if(isErrorDetail){
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });

    }

    // console.log("isErrorDetail => " + isErrorDetail);

    mp.set(isErrorDetail,chargeDuplicate);
    return mp;
  }


  onDetailCopyPrepaidProcess(dataPrepaid ? : FreightDetail){
    // console.log("** on detail copy prepaid process **");


      let prepaidList: FreightDetail[] = [];
      let collectList: FreightDetail[] = [];
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if (element.pfcDetailPCTab == 'P') {
          prepaidList.push(element);
        }
      });

      if (dataPrepaid == undefined) {
        this.gridPrepaid.getSelectedValues().forEach(element=> {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = 'TANK';
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = 'OPEN TOP';
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = 'FLAT RACK';
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = 'PLATFORM';
              break;
            case "REF":
              x.pfcDetailContainerTypeName = 'REEFER';
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = 'REEFER DRY';
              break;
            case "UC":
              x.pfcDetailContainerTypeName = 'UC';
              break;
            case "STD":
              x.pfcDetailContainerTypeName = 'NORMAL';
              break;
            default:
              break;
          }
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = "C";
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          this.gridCollect.listStore.store.push(x);


        });
      } else {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = dataPrepaid.pfcDetailChargeCode;
        x.pfcDetailChargeName = dataPrepaid.pfcDetailChargeName;
        x.pfcDetailContainerType = dataPrepaid.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = dataPrepaid.pfcDetailCurrency;
        x.pfcDetailD20 = dataPrepaid.pfcDetailD20;
        x.pfcDetailD40 = dataPrepaid.pfcDetailD40;
        x.pfcDetailD45 = dataPrepaid.pfcDetailD45;
        x.pfcDetailFAIGroup = dataPrepaid.pfcDetailFAIGroup;
        x.pfcDetailH20 = dataPrepaid.pfcDetailH20;
        x.pfcDetailH40 = dataPrepaid.pfcDetailH40;
        x.pfcDetailH45 = dataPrepaid.pfcDetailH45;
        x.pfcDetailKT = dataPrepaid.pfcDetailKT;
        x.pfcDetailKillSlot = dataPrepaid.pfcDetailKillSlot;
        x.pfcDetailM3 = dataPrepaid.pfcDetailM3;
        x.pfcDetailMinCharge = dataPrepaid.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = dataPrepaid.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = dataPrepaid.pfcDetailOogOH;
        x.pfcDetailOogOLB = dataPrepaid.pfcDetailOogOLB;
        x.pfcDetailOogOLF = dataPrepaid.pfcDetailOogOLF;
        x.pfcDetailOogOWL = dataPrepaid.pfcDetailOogOWL;
        x.pfcDetailOogOWR = dataPrepaid.pfcDetailOogOWR;
        x.pfcDetailOogUcH = dataPrepaid.pfcDetailOogUcH;
        x.pfcDetailOogUcL = dataPrepaid.pfcDetailOogUcL;
        x.pfcDetailOogUcW = dataPrepaid.pfcDetailOogUcW;
        x.pfcDetailPC = dataPrepaid.pfcDetailPC;
        x.pfcDetailPCTab = "C";
        x.pfcDetailPayer = dataPrepaid.pfcDetailPayer;
        x.pfcDetailPayerName = dataPrepaid.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = dataPrepaid.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = dataPrepaid.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = dataPrepaid.pfcDetailRT;
        x.pfcDetailSeqNo = dataPrepaid.pfcDetailSeqNo;
        x.pfcDetailStatus = dataPrepaid.pfcDetailStatus;

        this.gridCollect.listStore.addData(x);
      }

      let index = 1;
      collectList = [];
      this.gridCollect.listStore.store.forEach(element => {
        element.pfcDetailSeqNo = index

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;

        switch (x.pfcDetailContainerType) {
          case "TANK":
            x.pfcDetailContainerType = 'TNK';
            break;
          case "OPEN TOP":
            x.pfcDetailContainerType = 'OPT';
            break;
          case "FLAT RACK":
            x.pfcDetailContainerType = 'FLR';
            break;
          case "PLATFORM":
            x.pfcDetailContainerType = 'PLT';
            break;
          case "REEFER":
            x.pfcDetailContainerType = 'REF';
            break;
          case "REEFER DRY":
            x.pfcDetailContainerType = 'DRY';
            break;
          case "UC":
            x.pfcDetailContainerType = 'UC';
            break;
          case "NORMAL":
            x.pfcDetailContainerType = 'STD';
            break;
          default:
            break;
        }

        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "C";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailSeqNo = index;
        x.no = index;

        collectList.push(x);
        index++;
      });


      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        // console.log(element)
        dets.push(element)
      });

      collectList.forEach(element => {
        // console.log(element)
        dets.push(element)
      });

      this.map.set(this.model.pfcContainerType, dets);
      // console.log(this.map);
      //this.gridPrepaid.clearSelectedValues();
      //this.onDetailAfterCopy(true);

  }

  onDetailCopyCollect(){
    // console.log("** on detail copy collect **");
      let mp:Map<any,any> = this.onDetailCopyCollectCheckDuplicate()
      // console.log(mp);

      mp.forEach((value: string, key: boolean) => {
        // console.log(key);
        if(key){
          this.message("information", "information", "Duplicate " + value + " charge code detected.", "okonly", {ok: "this.gridCollect.clearSelectedValues();"});
        }else{
          this.onDetailCopyCollectProcess();
          this.gridCollect.clearSelectedValues();
          this.onDetailAfterCopy(false);
        }
      });


  }

  onDetailCopyCollectCheckDuplicate(dataCollect ? : FreightDetail) :Map<any,any>{
    // console.log("** on detail copy collect check duplicate **");
    // console.log(dataCollect);

    let mp = new Map();

    let isErrorDetail: boolean = false;
    let chargeDuplicate:string = "";

    if(dataCollect != undefined){

      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
      x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
      x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
      switch (x.pfcDetailContainerType) {
        case "TNK":
          x.pfcDetailContainerTypeName = 'TANK';
          break;
        case "OPT":
          x.pfcDetailContainerTypeName = 'OPEN TOP';
          break;
        case "FLR":
          x.pfcDetailContainerTypeName = 'FLAT RACK';
          break;
        case "PLT":
          x.pfcDetailContainerTypeName = 'PLATFORM';
          break;
        case "REF":
          x.pfcDetailContainerTypeName = 'REEFER';
          break;
        case "DRY":
          x.pfcDetailContainerTypeName = 'REEFER DRY';
          break;
        case "UC":
          x.pfcDetailContainerTypeName = 'UC';
          break;
        case "STD":
          x.pfcDetailContainerTypeName = 'NORMAL';
          break;
        default:
          break;
      }
      x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
      x.pfcDetailD20 = dataCollect.pfcDetailD20;
      x.pfcDetailD40 = dataCollect.pfcDetailD40;
      x.pfcDetailD45 = dataCollect.pfcDetailD45;
      x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
      x.pfcDetailH20 = dataCollect.pfcDetailH20;
      x.pfcDetailH40 = dataCollect.pfcDetailH40;
      x.pfcDetailH45 = dataCollect.pfcDetailH45;
      x.pfcDetailKT = dataCollect.pfcDetailKT;
      x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
      x.pfcDetailM3 = dataCollect.pfcDetailM3;
      x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
      x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
      x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
      x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
      x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
      x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
      x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
      x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
      x.pfcDetailPC = dataCollect.pfcDetailPC;
      x.pfcDetailPCTab = "P";
      x.pfcDetailPayer = dataCollect.pfcDetailPayer;
      x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = dataCollect.pfcDetailRT;
      x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
      x.pfcDetailStatus = dataCollect.pfcDetailStatus;

      isErrorDetail = this.checkDuplicateCopy('prepaid',x);
      if(isErrorDetail){
        chargeDuplicate = dataCollect.pfcDetailChargeCode.toUpperCase().trim();
      }

    }else{
      this.gridCollect.getSelectedValues().forEach(element=> {

        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "P";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;

        if(isErrorDetail == false){
          isErrorDetail = this.checkDuplicateCopy('prepaid',x);
          if(isErrorDetail){
            chargeDuplicate = element.pfcDetailChargeCode.toUpperCase().trim();
          }
        }
      });

    }

    // console.log("isErrorDetail => " + isErrorDetail);

    mp.set(isErrorDetail,chargeDuplicate);
    return mp;
  }


  onDetailCopyCollectProcess(dataCollect ? : FreightDetail){
    // console.log("** on detail copy collect process **");


      let prepaidList: FreightDetail[] = [];
      let collectList: FreightDetail[] = [];
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if (element.pfcDetailPCTab == 'C') {
          collectList.push(element);
        }
      });

      if (dataCollect == undefined) {
        this.gridCollect.getSelectedValues().forEach(element=> {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = 'TANK';
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = 'OPEN TOP';
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = 'FLAT RACK';
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = 'PLATFORM';
              break;
            case "REF":
              x.pfcDetailContainerTypeName = 'REEFER';
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = 'REEFER DRY';
              break;
            case "UC":
              x.pfcDetailContainerTypeName = 'UC';
              break;
            case "STD":
              x.pfcDetailContainerTypeName = 'NORMAL';
              break;
            default:
              break;
          }
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = "P";
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          this.gridPrepaid.listStore.addData(x);


        });
      } else {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = dataCollect.pfcDetailChargeCode;
        x.pfcDetailChargeName = dataCollect.pfcDetailChargeName;
        x.pfcDetailContainerType = dataCollect.pfcDetailContainerType;
        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }
        x.pfcDetailCurrency = dataCollect.pfcDetailCurrency;
        x.pfcDetailD20 = dataCollect.pfcDetailD20;
        x.pfcDetailD40 = dataCollect.pfcDetailD40;
        x.pfcDetailD45 = dataCollect.pfcDetailD45;
        x.pfcDetailFAIGroup = dataCollect.pfcDetailFAIGroup;
        x.pfcDetailH20 = dataCollect.pfcDetailH20;
        x.pfcDetailH40 = dataCollect.pfcDetailH40;
        x.pfcDetailH45 = dataCollect.pfcDetailH45;
        x.pfcDetailKT = dataCollect.pfcDetailKT;
        x.pfcDetailKillSlot = dataCollect.pfcDetailKillSlot;
        x.pfcDetailM3 = dataCollect.pfcDetailM3;
        x.pfcDetailMinCharge = dataCollect.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = dataCollect.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = dataCollect.pfcDetailOogOH;
        x.pfcDetailOogOLB = dataCollect.pfcDetailOogOLB;
        x.pfcDetailOogOLF = dataCollect.pfcDetailOogOLF;
        x.pfcDetailOogOWL = dataCollect.pfcDetailOogOWL;
        x.pfcDetailOogOWR = dataCollect.pfcDetailOogOWR;
        x.pfcDetailOogUcH = dataCollect.pfcDetailOogUcH;
        x.pfcDetailOogUcL = dataCollect.pfcDetailOogUcL;
        x.pfcDetailOogUcW = dataCollect.pfcDetailOogUcW;
        x.pfcDetailPC = dataCollect.pfcDetailPC;
        x.pfcDetailPCTab = "P";
        x.pfcDetailPayer = dataCollect.pfcDetailPayer;
        x.pfcDetailPayerName = dataCollect.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = dataCollect.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = dataCollect.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = dataCollect.pfcDetailRT;
        x.pfcDetailSeqNo = dataCollect.pfcDetailSeqNo;
        x.pfcDetailStatus = dataCollect.pfcDetailStatus;

        this.gridPrepaid.listStore.addData(x);
      }

      let index = 1;
      prepaidList = [];
      this.gridPrepaid.listStore.store.forEach(element => {
        element.pfcDetailSeqNo = index
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;

        switch (x.pfcDetailContainerType) {
          case "TNK":
            x.pfcDetailContainerTypeName = 'TANK';
            break;
          case "OPT":
            x.pfcDetailContainerTypeName = 'OPEN TOP';
            break;
          case "FLR":
            x.pfcDetailContainerTypeName = 'FLAT RACK';
            break;
          case "PLT":
            x.pfcDetailContainerTypeName = 'PLATFORM';
            break;
          case "REF":
            x.pfcDetailContainerTypeName = 'REEFER';
            break;
          case "DRY":
            x.pfcDetailContainerTypeName = 'REEFER DRY';
            break;
          case "UC":
            x.pfcDetailContainerTypeName = 'UC';
            break;
          case "STD":
            x.pfcDetailContainerTypeName = 'NORMAL';
            break;
          default:
            break;
        }

        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = "P";
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.no = index;
        x.pfcDetailSeqNo = index;

        collectList.push(x);
        index++;
      });

      // console.log(prepaidList);
      // console.log(collectList);

      let dets: FreightDetail[] = [];
      prepaidList.forEach(element => {
        dets.push(element)
      });
      collectList.forEach(element => {
        dets.push(element)
      });
      this.map.set(this.model.pfcContainerType, dets);

      // console.log(this.map);
      //this.gridPrepaid.clearSelectedValues();
      //this.onDetailAfterCopy(true);

  }

  onDetailAfterCopy(isPrepaid:boolean){
      if (isPrepaid) {
        this.message('information', 'Information', 'Copy prepaid to collect successfully.', 'okonly', {ok: ''});
      } else {
        this.message('information', 'Information', 'Copy collect to prepaid successfully.', 'okonly', {ok: ''});
      }
      return;
  }

  onDetailClearFAI(){
    console.log("** on clear FAI **")
    let isChk:boolean = false;

    if (this.currentActiveTab == "prepaid") {
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if(element.pfcDetailPCTab == 'P') {
          console.log(element)
          if(element.pfcDetailFAIGroup == "Y"){
            isChk = true
            return
          }
        }
      });
    }

    if (this.currentActiveTab == "prepaid") {
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if(element.pfcDetailPCTab == 'C') {
          if(element.pfcDetailFAIGroup == "Y"){
            isChk = true
            return
          }
        }
      });

    }

    console.log(isChk)

    if(isChk){
      this.message("information", "information", "Do you want to remove FAI group marking?", 'yesno', {yes: 'this.onDetailClearFAIProcess()', no: ''})
    }else{
      this.onDetailClearFAIProcess()
    }

  }

  onDetailClearFAIProcess(){
    let prepaidList: FreightDetail[] = [];
    let collectList: FreightDetail[] = [];

    if (this.currentActiveTab == "prepaid") {
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if(element.pfcDetailPCTab == 'P') {
          if(element.pfcDetailFAIGroup == "Y"){
            element.pfcDetailFAIGroup = "N"
          }
          prepaidList.push(element);
        }

        if (element.pfcDetailPCTab == 'C') {
          collectList.push(element);
        }
      });

    }

    if (this.currentActiveTab == "collect") {
      let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
      fd.forEach(element => {
        if (element.pfcDetailPCTab == 'P') {
          prepaidList.push(element);
        }

        if(element.pfcDetailPCTab == 'C') {
          if(element.pfcDetailFAIGroup == "Y"){
            element.pfcDetailFAIGroup = "N"
          }
          collectList.push(element);
        }
      });

    }

    let dets: FreightDetail[] = [];
    prepaidList.forEach(element => {
      dets.push(element)
    });
    collectList.forEach(element => {
      dets.push(element)
    });
    this.map.set(this.model.pfcContainerType, dets);

    console.log(this.map)

    this.gridPrepaid.onClear();
    this.gridCollect.onClear();
    var detailList: any[] = this.map.get(this.model.pfcContainerType)
    // console.log(this.map)

    detailList.forEach(element => {

      // console.log(element)
      var x: FreightDetail = new FreightDetail();
      x.pfcDetailChargeCode = element.pfcDetailChargeCode;
      x.pfcDetailChargeName = element.pfcDetailChargeName;
      x.pfcDetailContainerType = element.pfcDetailContainerType;
      switch (element.pfcDetailContainerType) {
        case "STD":
          element.pfcDetailContainerTypeName = "NORMAL";
          break;
        case "DRY":
          element.pfcDetailContainerTypeName = "REEFER DRY";
          break;
        case "REF":
          element.pfcDetailContainerTypeName = "REEFER";
          break;
        case "TNK":
          element.pfcDetailContainerTypeName = "TANK";
          break;
        case "OPT":
          element.pfcDetailContainerTypeName = "OPEN TOP";
          break;
        case "PLT":
          element.pfcDetailContainerTypeName = "PLATFORM";
          break;
        case "FLR":
          element.pfcDetailContainerTypeName = "FLAT RACK";
          break;
        case "UC":
          element.pfcDetailContainerTypeName = "UC";
          break;
        default:
          break;
      }
      x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
      x.pfcDetailCopyTab = element.pfcDetailCopyTab;
      x.pfcDetailCurrency = element.pfcDetailCurrency;
      x.pfcDetailD20 = element.pfcDetailD20;
      x.pfcDetailD40 = element.pfcDetailD40;
      x.pfcDetailD45 = element.pfcDetailD45;
      x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
      x.pfcDetailH20 = element.pfcDetailH20;
      x.pfcDetailH40 = element.pfcDetailH40;
      x.pfcDetailH45 = element.pfcDetailH45;
      x.pfcDetailKT = element.pfcDetailKT;
      x.pfcDetailKillSlot = element.pfcDetailKillSlot;
      x.pfcDetailM3 = element.pfcDetailM3;
      x.pfcDetailMinCharge = element.pfcDetailMinCharge;
      x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
      if(x.pfcDetailOogDoorOpen == "N"){
        x.pfcDetailOogDoorOpenName = "N/A";
      }else if(x.pfcDetailOogDoorOpen == "D"){
        x.pfcDetailOogDoorOpenName = "Detached";
      }else if(x.pfcDetailOogDoorOpen == "B"){
        x.pfcDetailOogDoorOpenName = "Tied Both";
      }else if(x.pfcDetailOogDoorOpen == "L"){
        x.pfcDetailOogDoorOpenName = "Tied Left";
      }else if(x.pfcDetailOogDoorOpen == "R"){
        x.pfcDetailOogDoorOpenName = "Tied Right";
      }
      x.pfcDetailOogOH = element.pfcDetailOogOH;
      x.pfcDetailOogOLB = element.pfcDetailOogOLB;
      x.pfcDetailOogOLF = element.pfcDetailOogOLF;
      x.pfcDetailOogOWL = element.pfcDetailOogOWL;
      x.pfcDetailOogOWR = element.pfcDetailOogOWR;
      x.pfcDetailOogUcH = element.pfcDetailOogUcH;
      x.pfcDetailOogUcL = element.pfcDetailOogUcL;
      x.pfcDetailOogUcW = element.pfcDetailOogUcW;
      x.pfcDetailPC = element.pfcDetailPC;
      x.pfcDetailPCTab = element.pfcDetailPCTab;
      x.pfcDetailPayer = element.pfcDetailPayer;
      x.pfcDetailPayerName = element.pfcDetailPayerName;
      x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
      x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
      x.pfcDetailRT = element.pfcDetailRT;
      x.pfcDetailSeqNo = element.pfcDetailSeqNo;
      x.pfcDetailStatus = element.pfcDetailStatus;
      x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

      if (x.pfcDetailPCTab == 'P') {
        this.gridPrepaid.listStore.store.push(x);
        this.gridPrepaid.loadData();
        this.gridPrepaid.clearSelectedValues()
      }

      if (x.pfcDetailPCTab == 'C') {
        this.gridCollect.listStore.store.push(x);
        this.gridCollect.loadData();
        this.gridCollect.clearSelectedValues()
      }

    });


  }

  onDetailFAI(){
    console.log("** on detail FAI **")
    console.log(this.map)

    if (this.currentActiveTab == "prepaid") {
      if (this.gridPrepaid.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Prepaid Charge.', 'okonly', {ok: ''});
        return;
      }

      if (this.gridPrepaid.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to set group FAI.", "okonly", {ok: "this.loading=false;"})

      } else if (this.gridPrepaid.getSelectedValues().length == 1) {
        this.message("information", "information", "Please select at least 2 records to set group FAI.", "okonly", {ok: "this.loading=false;"})

      } else {

        let chkStr:string = '';
        let isChk:boolean = false;
        let prepaidList: FreightDetail[] = [];
        let collectList: FreightDetail[] = [];

        this.gridPrepaid.getSelectedValues().forEach(element=> {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = 'TANK';
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = 'OPEN TOP';
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = 'FLAT RACK';
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = 'PLATFORM';
              break;
            case "REF":
              x.pfcDetailContainerTypeName = 'REEFER';
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = 'REEFER DRY';
              break;
            case "UC":
              x.pfcDetailContainerTypeName = 'UC';
              break;
            case "STD":
              x.pfcDetailContainerTypeName = 'NORMAL';
              break;
            default:
              break;
          }
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = "Y";
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          //this.gridCollect.listStore.store.push(x);
          if(chkStr == ""){
            chkStr = this.getFreightFAIString(x)
          }else{
            let compareStr:string = this.getFreightFAIString(x)
            if(compareStr != chkStr){
              isChk = true;
              return
            }
          }

        });

        console.log("this.model.pfcContainerType ==> " + this.model.pfcContainerType)
        let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
        fd.forEach(element => {
          if(element.pfcDetailPCTab == 'P') {
            element.pfcDetailFAIGroup = "N"

            let chk1:string = this.getFreightDetailString(element);

            this.gridPrepaid.getSelectedValues().forEach(el=> {
              let chk2:string = this.getFreightDetailString(el);
              if(chk1 == chk2){
                element.pfcDetailFAIGroup = "Y"
              }
            })

            prepaidList.push(element);
          }

          if (element.pfcDetailPCTab == 'C') {
            collectList.push(element);
          }
        });

        console.log("** isChk => " + isChk)

        if(isChk){
            this.message("information", "information", "Unable to combine to FAI, please check charges.", "okonly", {ok: "this.loading=false;"})
        }else{

          let dets: FreightDetail[] = [];
          prepaidList.forEach(element => {
            dets.push(element)
          });
          collectList.forEach(element => {
            dets.push(element)
          });
          this.map.set(this.model.pfcContainerType, dets);

          console.log(this.map)


          this.gridPrepaid.onClear();
          this.gridCollect.onClear();
          var detailList: any[] = this.map.get(this.model.pfcContainerType)
          // console.log(this.map)

          detailList.forEach(element => {

            // console.log(element)
            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = element.pfcDetailChargeCode;
            x.pfcDetailChargeName = element.pfcDetailChargeName;
            x.pfcDetailContainerType = element.pfcDetailContainerType;
            switch (element.pfcDetailContainerType) {
              case "STD":
                element.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                element.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                element.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                element.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                element.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                element.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                element.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                element.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
            x.pfcDetailCopyTab = element.pfcDetailCopyTab;
            x.pfcDetailCurrency = element.pfcDetailCurrency;
            x.pfcDetailD20 = element.pfcDetailD20;
            x.pfcDetailD40 = element.pfcDetailD40;
            x.pfcDetailD45 = element.pfcDetailD45;
            x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
            x.pfcDetailH20 = element.pfcDetailH20;
            x.pfcDetailH40 = element.pfcDetailH40;
            x.pfcDetailH45 = element.pfcDetailH45;
            x.pfcDetailKT = element.pfcDetailKT;
            x.pfcDetailKillSlot = element.pfcDetailKillSlot;
            x.pfcDetailM3 = element.pfcDetailM3;
            x.pfcDetailMinCharge = element.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
            if(x.pfcDetailOogDoorOpen == "N"){
              x.pfcDetailOogDoorOpenName = "N/A";
            }else if(x.pfcDetailOogDoorOpen == "D"){
              x.pfcDetailOogDoorOpenName = "Detached";
            }else if(x.pfcDetailOogDoorOpen == "B"){
              x.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(x.pfcDetailOogDoorOpen == "L"){
              x.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(x.pfcDetailOogDoorOpen == "R"){
              x.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogOH = element.pfcDetailOogOH;
            x.pfcDetailOogOLB = element.pfcDetailOogOLB;
            x.pfcDetailOogOLF = element.pfcDetailOogOLF;
            x.pfcDetailOogOWL = element.pfcDetailOogOWL;
            x.pfcDetailOogOWR = element.pfcDetailOogOWR;
            x.pfcDetailOogUcH = element.pfcDetailOogUcH;
            x.pfcDetailOogUcL = element.pfcDetailOogUcL;
            x.pfcDetailOogUcW = element.pfcDetailOogUcW;
            x.pfcDetailPC = element.pfcDetailPC;
            x.pfcDetailPCTab = element.pfcDetailPCTab;
            x.pfcDetailPayer = element.pfcDetailPayer;
            x.pfcDetailPayerName = element.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = element.pfcDetailRT;
            x.pfcDetailSeqNo = element.pfcDetailSeqNo;
            x.pfcDetailStatus = element.pfcDetailStatus;
            x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

            if (x.pfcDetailPCTab == 'P') {
              this.gridPrepaid.listStore.store.push(x);
              this.gridPrepaid.loadData();
              this.gridPrepaid.clearSelectedValues()
            }

            if (x.pfcDetailPCTab == 'C') {
              this.gridCollect.listStore.store.push(x);
              this.gridCollect.loadData();
              this.gridCollect.clearSelectedValues();
            }

          });


        }


      }
    }

    if (this.currentActiveTab == "collect") {
      if (this.gridCollect.listStore.store.length == 0) {
        this.message('warning', 'Warning', 'There is no Collect Charge.', 'okonly', {ok: ''});
        return;
      }
      if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select an entry to set group FAI.", "okonly", {ok: "this.loading=false;"})

      } else if (this.gridCollect.getSelectedValues().length == 0) {
        this.message("information", "information", "Please select at least 2 records to set group FAI.", "okonly", {ok: "this.loading=false;"})

      } else {

        let chkStr:string = '';
        let isChk:boolean = false;
        let prepaidList: FreightDetail[] = [];
        let collectList: FreightDetail[] = [];

        this.gridCollect.getSelectedValues().forEach(element=> {
          var x: FreightDetail = new FreightDetail();
          x.pfcDetailChargeCode = element.pfcDetailChargeCode;
          x.pfcDetailChargeName = element.pfcDetailChargeName;
          x.pfcDetailContainerType = element.pfcDetailContainerType;
          switch (x.pfcDetailContainerType) {
            case "TNK":
              x.pfcDetailContainerTypeName = 'TANK';
              break;
            case "OPT":
              x.pfcDetailContainerTypeName = 'OPEN TOP';
              break;
            case "FLR":
              x.pfcDetailContainerTypeName = 'FLAT RACK';
              break;
            case "PLT":
              x.pfcDetailContainerTypeName = 'PLATFORM';
              break;
            case "REF":
              x.pfcDetailContainerTypeName = 'REEFER';
              break;
            case "DRY":
              x.pfcDetailContainerTypeName = 'REEFER DRY';
              break;
            case "UC":
              x.pfcDetailContainerTypeName = 'UC';
              break;
            case "STD":
              x.pfcDetailContainerTypeName = 'NORMAL';
              break;
            default:
              break;
          }
          x.pfcDetailCurrency = element.pfcDetailCurrency;
          x.pfcDetailD20 = element.pfcDetailD20;
          x.pfcDetailD40 = element.pfcDetailD40;
          x.pfcDetailD45 = element.pfcDetailD45;
          x.pfcDetailFAIGroup = "Y";
          x.pfcDetailH20 = element.pfcDetailH20;
          x.pfcDetailH40 = element.pfcDetailH40;
          x.pfcDetailH45 = element.pfcDetailH45;
          x.pfcDetailKT = element.pfcDetailKT;
          x.pfcDetailKillSlot = element.pfcDetailKillSlot;
          x.pfcDetailM3 = element.pfcDetailM3;
          x.pfcDetailMinCharge = element.pfcDetailMinCharge;
          x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
          if(x.pfcDetailOogDoorOpen == "N"){
            x.pfcDetailOogDoorOpenName = "N/A";
          }else if(x.pfcDetailOogDoorOpen == "D"){
            x.pfcDetailOogDoorOpenName = "Detached";
          }else if(x.pfcDetailOogDoorOpen == "B"){
            x.pfcDetailOogDoorOpenName = "Tied Both";
          }else if(x.pfcDetailOogDoorOpen == "L"){
            x.pfcDetailOogDoorOpenName = "Tied Left";
          }else if(x.pfcDetailOogDoorOpen == "R"){
            x.pfcDetailOogDoorOpenName = "Tied Right";
          }
          x.pfcDetailOogOH = element.pfcDetailOogOH;
          x.pfcDetailOogOLB = element.pfcDetailOogOLB;
          x.pfcDetailOogOLF = element.pfcDetailOogOLF;
          x.pfcDetailOogOWL = element.pfcDetailOogOWL;
          x.pfcDetailOogOWR = element.pfcDetailOogOWR;
          x.pfcDetailOogUcH = element.pfcDetailOogUcH;
          x.pfcDetailOogUcL = element.pfcDetailOogUcL;
          x.pfcDetailOogUcW = element.pfcDetailOogUcW;
          x.pfcDetailPC = element.pfcDetailPC;
          x.pfcDetailPCTab = element.pfcDetailPCTab;
          x.pfcDetailPayer = element.pfcDetailPayer;
          x.pfcDetailPayerName = element.pfcDetailPayerName;
          x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
          x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
          x.pfcDetailRT = element.pfcDetailRT;
          x.pfcDetailSeqNo = element.pfcDetailSeqNo;
          x.pfcDetailStatus = element.pfcDetailStatus;

          //this.gridCollect.listStore.store.push(x);
          if(chkStr == ""){
            chkStr = this.getFreightFAIString(x)
          }else{
            let compareStr:string = this.getFreightFAIString(x)
            if(compareStr != chkStr){
              isChk = true;
              return
            }
          }

        });

        let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
        fd.forEach(element => {
          if(element.pfcDetailPCTab == 'C') {
            element.pfcDetailFAIGroup = "N"

            let chk1:string = this.getFreightDetailString(element);

            this.gridPrepaid.getSelectedValues().forEach(el=> {
              let chk2:string = this.getFreightDetailString(el);
              if(chk1 == chk2){
                element.pfcDetailFAIGroup = "Y"
              }
            })

            collectList.push(element);
          }

          if (element.pfcDetailPCTab == 'P') {
            prepaidList.push(element);
          }
        });

        if(isChk){
            this.message("information", "information", "Unable to combine to FAI, please check charges.", "okonly", {ok: "this.loading=false;"})
        }else{

          let dets: FreightDetail[] = [];
          prepaidList.forEach(element => {
            dets.push(element)
          });
          collectList.forEach(element => {
            dets.push(element)
          });
          this.map.set(this.model.pfcContainerType, dets);

          console.log(this.map)

          this.gridPrepaid.onClear();
          this.gridCollect.onClear();
          var detailList: any[] = this.map.get(this.model.pfcContainerType)
          // console.log(this.map)

          detailList.forEach(element => {

            // console.log(element)
            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = element.pfcDetailChargeCode;
            x.pfcDetailChargeName = element.pfcDetailChargeName;
            x.pfcDetailContainerType = element.pfcDetailContainerType;
            switch (element.pfcDetailContainerType) {
              case "STD":
                element.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                element.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                element.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                element.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                element.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                element.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                element.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                element.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
            x.pfcDetailCopyTab = element.pfcDetailCopyTab;
            x.pfcDetailCurrency = element.pfcDetailCurrency;
            x.pfcDetailD20 = element.pfcDetailD20;
            x.pfcDetailD40 = element.pfcDetailD40;
            x.pfcDetailD45 = element.pfcDetailD45;
            x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
            x.pfcDetailH20 = element.pfcDetailH20;
            x.pfcDetailH40 = element.pfcDetailH40;
            x.pfcDetailH45 = element.pfcDetailH45;
            x.pfcDetailKT = element.pfcDetailKT;
            x.pfcDetailKillSlot = element.pfcDetailKillSlot;
            x.pfcDetailM3 = element.pfcDetailM3;
            x.pfcDetailMinCharge = element.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
            if(x.pfcDetailOogDoorOpen == "N"){
              x.pfcDetailOogDoorOpenName = "N/A";
            }else if(x.pfcDetailOogDoorOpen == "D"){
              x.pfcDetailOogDoorOpenName = "Detached";
            }else if(x.pfcDetailOogDoorOpen == "B"){
              x.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(x.pfcDetailOogDoorOpen == "L"){
              x.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(x.pfcDetailOogDoorOpen == "R"){
              x.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogOH = element.pfcDetailOogOH;
            x.pfcDetailOogOLB = element.pfcDetailOogOLB;
            x.pfcDetailOogOLF = element.pfcDetailOogOLF;
            x.pfcDetailOogOWL = element.pfcDetailOogOWL;
            x.pfcDetailOogOWR = element.pfcDetailOogOWR;
            x.pfcDetailOogUcH = element.pfcDetailOogUcH;
            x.pfcDetailOogUcL = element.pfcDetailOogUcL;
            x.pfcDetailOogUcW = element.pfcDetailOogUcW;
            x.pfcDetailPC = element.pfcDetailPC;
            x.pfcDetailPCTab = element.pfcDetailPCTab;
            x.pfcDetailPayer = element.pfcDetailPayer;
            x.pfcDetailPayerName = element.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = element.pfcDetailRT;
            x.pfcDetailSeqNo = element.pfcDetailSeqNo;
            x.pfcDetailStatus = element.pfcDetailStatus;
            x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

            if (x.pfcDetailPCTab == 'P') {
              this.gridPrepaid.listStore.store.push(x);
              this.gridPrepaid.loadData();
            }

            if (x.pfcDetailPCTab == 'C') {
              this.gridCollect.listStore.store.push(x);
              this.gridCollect.loadData();
            }

          });


          this.message("information", "information", "Charges combined as FAI successfully.", "okonly", {ok: "this.loading=false;"})


        }




      }
    }

  }

  onNewCharge_OK(){
      // console.log("** on new charge ok **");

      //debug
      // console.log(this.modelDetail)

      //safety first, replace blank to 0 (zeroes)
      this.modelDetail.pfcDetailD20 = (this.modelDetail.pfcDetailD20 == null) ? 0 : this.modelDetail.pfcDetailD20;
      this.modelDetail.pfcDetailD40 = (this.modelDetail.pfcDetailD40 == null) ? 0 : this.modelDetail.pfcDetailD40;
      this.modelDetail.pfcDetailD45 = (this.modelDetail.pfcDetailD45 == null) ? 0 : this.modelDetail.pfcDetailD45;
      this.modelDetail.pfcDetailH20 = (this.modelDetail.pfcDetailH20 == null) ? 0 : this.modelDetail.pfcDetailH20;
      this.modelDetail.pfcDetailH40 = (this.modelDetail.pfcDetailH40 == null) ? 0 : this.modelDetail.pfcDetailH40;
      this.modelDetail.pfcDetailH45 = (this.modelDetail.pfcDetailH45 == null) ? 0 : this.modelDetail.pfcDetailH45;
      this.modelDetail.pfcDetailM3 = (this.modelDetail.pfcDetailM3 == null) ? 0 : this.modelDetail.pfcDetailM3;
      this.modelDetail.pfcDetailKT = (this.modelDetail.pfcDetailKT == null) ? 0 : this.modelDetail.pfcDetailKT;
      this.modelDetail.pfcDetailRT = (this.modelDetail.pfcDetailRT == null) ? 0 : this.modelDetail.pfcDetailRT;
      this.modelDetail.pfcDetailMinCharge = (this.modelDetail.pfcDetailMinCharge == null) ? 0 : this.modelDetail.pfcDetailMinCharge;
      this.modelDetail.pfcDetailKillSlot = (this.modelDetail.pfcDetailKillSlot == null) ? 0 : this.modelDetail.pfcDetailKillSlot;
      this.modelDetail.pfcDetailOogOH = (this.modelDetail.pfcDetailOogOH == null) ? 0 : this.modelDetail.pfcDetailOogOH;
      this.modelDetail.pfcDetailOogOWR = (this.modelDetail.pfcDetailOogOWR == null) ? 0 : this.modelDetail.pfcDetailOogOWR;
      this.modelDetail.pfcDetailOogOWL = (this.modelDetail.pfcDetailOogOWL == null) ? 0 : this.modelDetail.pfcDetailOogOWL;
      this.modelDetail.pfcDetailOogOLF = (this.modelDetail.pfcDetailOogOLF == null) ? 0 : this.modelDetail.pfcDetailOogOLF;
      this.modelDetail.pfcDetailOogOLB = (this.modelDetail.pfcDetailOogOLB == null) ? 0 : this.modelDetail.pfcDetailOogOLB;
      this.modelDetail.pfcDetailOogUcH = (this.modelDetail.pfcDetailOogUcH == null) ? 0 : this.modelDetail.pfcDetailOogUcH;
      this.modelDetail.pfcDetailOogUcW = (this.modelDetail.pfcDetailOogUcW == null) ? 0 : this.modelDetail.pfcDetailOogUcW;
      this.modelDetail.pfcDetailOogUcL = (this.modelDetail.pfcDetailOogUcL == null) ? 0 : this.modelDetail.pfcDetailOogUcL;
      this.modelDetail.pfcDetailPCTab = (this.currentActiveTab.toUpperCase() == "PREPAID") ? "P" : "C";


      //calculate charge total value
      this.modelDetail.pfcDetailTotalChargeValue = this.modelDetail.pfcDetailD20 + this.modelDetail.pfcDetailD40 + this.modelDetail.pfcDetailD45 + this.modelDetail.pfcDetailH20 + this.modelDetail.pfcDetailH40 + this.modelDetail.pfcDetailH45;
      // console.log("total = " + this.modelDetail.pfcDetailTotalChargeValue);

    console.log("aaaa");
    console.log(this.modelDetail);
      if(this.modelDetail.pfcDetailPC.toUpperCase() == "P"){
        this.setValidatorAddNewCharge();
      }else{
        this.setValidatorAddNewChargeCollect();
      }
    console.log("bbbbb");
    console.log(this.modelDetail);
      this.modelDetail['chargesValueValidator'] = this.checkChargesValueValidator();
      this.modelDetail['mainChargesValidator'] = this.checkMainChargesValidator();
    console.log("ccccc");
    console.log(this.modelDetail);
      this.isErrorDetail = this.onValidate(this.modelDetail);
    console.log("dddd");
    console.log(this.modelDetail);
      let chkDuplicate:boolean = false;
      if (this.currentActiveTab == "prepaid") {
        chkDuplicate = this.checkDuplicate("prepaid",this.modelDetail);
      }else if (this.currentActiveTab == "collect") {
        chkDuplicate = this.checkDuplicate("collect",this.modelDetail);
      }

      if(chkDuplicate){
        this.modelDetail['error-pfcDuplicateChargeCode'] = "Duplicate " + this.modelDetail.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected.";
        this.isErrorDetail = true;
      }

      if(this.modelDetail.pfcDetailChargeCode == 'OFT' || this.modelDetail.pfcDetailChargeCode == 'FAI' || this.modelDetail.pfcDetailChargeCode == 'FOF'){
        let chkDuplicateMain:boolean = false;
        if (this.currentActiveTab == 'prepaid') {
          chkDuplicateMain = this.checkDuplicateMainCharges('prepaid',this.modelDetail);
        }else if (this.currentActiveTab == 'collect') {
          chkDuplicateMain = this.checkDuplicateMainCharges('collect',this.modelDetail);
        }

        console.log("chkDuplicateMain ==> " + chkDuplicateMain)
        if(chkDuplicateMain){
          this.modelDetail['error-pfcDuplicateChargeCode'] = 'Duplicate main charge code detected.';
          this.isErrorDetail = true;
        }
      }


      if(this.modelDetail.pfcDetailCopyTab == 'Y'){
        if (this.currentActiveTab == "prepaid") {
          // console.log("** copy freight to collect **");
          let mp:Map<boolean,string> = this.onDetailCopyPrepaidCheckDuplicate(this.modelDetail);
          mp.forEach((value: string, key: boolean) => {
            // console.log(key);
            if(key){
              this.modelDetail['error-pfcDuplicateChargeCode'] = "Duplicate " + value + " charge code detected in collect tab.";
              this.isErrorDetail = true;
            }
          });
        }
        if (this.currentActiveTab == "collect") {
          // console.log("** copy freight to prepaid **");
          let mp:Map<boolean,string> = this.onDetailCopyCollectCheckDuplicate(this.modelDetail);
          mp.forEach((value: string, key: boolean) => {
            // console.log(key);
            if(key){
              this.modelDetail['error-pfcDuplicateChargeCode'] = "Duplicate " + value + " charge code detected in prepaid tab.";
              this.isErrorDetail = true;
            }
          });
        }
      }


      if (!this.isErrorDetail) {
        if (this.isUpdateDetail) {
          //update
            let prepaidList: FreightDetail[] = [];
            let collectList: FreightDetail[] = [];
            let fd: FreightDetail[] = this.map.get(this.model.pfcContainerType);
            fd.forEach(element => {
              if (element.pfcDetailPCTab == 'P') {
                prepaidList.push(element);
              }
              if (element.pfcDetailPCTab == 'C') {
                collectList.push(element);
              }
            });

            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
            x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
            x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
            switch (this.modelDetail.pfcDetailContainerType) {
              case "STD":
                this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                this.modelDetail.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                this.modelDetail.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = this.modelDetail.pfcDetailContainerTypeName;

            x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
            x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
            x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
            x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
            x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
            x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
            x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
            x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
            x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
            x.pfcDetailKT = this.modelDetail.pfcDetailKT;
            x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
            x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
            x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;

            if(x.pfcDetailOogDoorOpen == "N"){
              this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
              x.pfcDetailOogDoorOpenName = "N/A";
            }else if(x.pfcDetailOogDoorOpen == "D"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
              x.pfcDetailOogDoorOpenName = "Detached";
            }else if(x.pfcDetailOogDoorOpen == "B"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
              x.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(x.pfcDetailOogDoorOpen == "L"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
              x.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(x.pfcDetailOogDoorOpen == "R"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
              x.pfcDetailOogDoorOpenName = "Tied Right";
            }

            x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
            x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
            x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
            x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
            x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
            x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
            x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
            x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
            x.pfcDetailPC = this.modelDetail.pfcDetailPC;
            x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
            x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
            x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = this.modelDetail.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = this.modelDetail.pfcDetailRT;
            x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
            x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
            x.no = x.pfcDetailSeqNo;

            if (this.currentActiveTab == "prepaid") {
              prepaidList[x.pfcDetailSeqNo - 1] = x;
            } else { // collect
              collectList[x.pfcDetailSeqNo - 1] = x;
            }

            let dets: FreightDetail[] = [];
            prepaidList.forEach(element => {
              dets.push(element)
            });
            collectList.forEach(element => {
              dets.push(element)
            });
            this.map.set(this.model.pfcContainerType, dets);

            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
            x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
            x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
            switch (this.modelDetail.pfcDetailContainerType) {
              case "STD":
                this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                this.modelDetail.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                this.modelDetail.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = this.modelDetail.pfcDetailContainerTypeName;

            x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
            x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
            x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
            x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
            x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
            x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
            x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
            x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
            x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
            x.pfcDetailKT = this.modelDetail.pfcDetailKT;
            x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
            x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
            x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
            if(this.modelDetail.pfcDetailOogDoorOpen == "N"){
              this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "D"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "B"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "L"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "R"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

            x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
            x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
            x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
            x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
            x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
            x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
            x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
            x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
            x.pfcDetailPC = this.modelDetail.pfcDetailPC;
            x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
            x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
            x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = this.modelDetail.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = this.modelDetail.pfcDetailRT;
            x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
            x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
            x.no = x.pfcDetailSeqNo;

            if (this.currentActiveTab == "prepaid") {
              this.gridPrepaid.listStore.updateData(x.pfcDetailSeqNo - 1, x);
              this.gridPrepaid.loadData();
            } else { // collect
              this.gridCollect.listStore.updateData(x.pfcDetailSeqNo - 1, x);
              this.gridCollect.loadData()
            }

            // console.log("** after update **");
            // console.log(this.map);

        } else {
          //new
          console.log("-- new --")
          console.log(this.modelDetail);

          if (this.map.get(this.model.pfcContainerType) == undefined || this.map.get(this.model.pfcContainerType) == null) {
            this.modelDetailList = [];
            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
            x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
            x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
            switch (this.modelDetail.pfcDetailContainerType) {
              case "STD":
                this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                this.modelDetail.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                this.modelDetail.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = this.modelDetail.pfcDetailContainerTypeName;

            x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
            x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
            x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
            x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
            x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
            x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
            x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
            x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
            x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
            x.pfcDetailKT = this.modelDetail.pfcDetailKT;
            x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
            x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
            x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
            if(this.modelDetail.pfcDetailOogDoorOpen == "N"){
              this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "D"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "B"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "L"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "R"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

            x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
            x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
            x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
            x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
            x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
            x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
            x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
            x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
            x.pfcDetailPC = this.modelDetail.pfcDetailPC;
            x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
            x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
            x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
            x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
            x.pfcDetailPlaceOfPaymentName = this.modelDetail.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = this.modelDetail.pfcDetailRT;
            x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
            x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
            x.no = x.pfcDetailSeqNo;

            this.modelDetailList.push(x);
            this.map.set(this.model.pfcContainerType, this.modelDetailList);
          } else {
            var x: FreightDetail = new FreightDetail();
            x.pfcDetailChargeCode = this.modelDetail.pfcDetailChargeCode;
            x.pfcDetailChargeName = this.modelDetail.pfcDetailChargeName;
            x.pfcDetailContainerType = this.modelDetail.pfcDetailContainerType;
            switch (this.modelDetail.pfcDetailContainerType) {
              case "STD":
                this.modelDetail.pfcDetailContainerTypeName = "NORMAL";
                break;
              case "DRY":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER DRY";
                break;
              case "REF":
                this.modelDetail.pfcDetailContainerTypeName = "REEFER";
                break;
              case "TNK":
                this.modelDetail.pfcDetailContainerTypeName = "TANK";
                break;
              case "OPT":
                this.modelDetail.pfcDetailContainerTypeName = "OPEN TOP";
                break;
              case "PLT":
                this.modelDetail.pfcDetailContainerTypeName = "PLATFORM";
                break;
              case "FLR":
                this.modelDetail.pfcDetailContainerTypeName = "FLAT RACK";
                break;
              case "UC":
                this.modelDetail.pfcDetailContainerTypeName = "UC";
                break;
              default:
                break;
            }
            x.pfcDetailContainerTypeName = this.modelDetail.pfcDetailContainerTypeName;

            x.pfcDetailCopyTab = this.modelDetail.pfcDetailCopyTab;
            x.pfcDetailCurrency = this.modelDetail.pfcDetailCurrency;
            x.pfcDetailD20 = this.modelDetail.pfcDetailD20;
            x.pfcDetailD40 = this.modelDetail.pfcDetailD40;
            x.pfcDetailD45 = this.modelDetail.pfcDetailD45;
            x.pfcDetailFAIGroup = this.modelDetail.pfcDetailFAIGroup;
            x.pfcDetailH20 = this.modelDetail.pfcDetailH20;
            x.pfcDetailH40 = this.modelDetail.pfcDetailH40;
            x.pfcDetailH45 = this.modelDetail.pfcDetailH45;
            x.pfcDetailKT = this.modelDetail.pfcDetailKT;
            x.pfcDetailKillSlot = this.modelDetail.pfcDetailKillSlot;
            x.pfcDetailM3 = this.modelDetail.pfcDetailM3;
            x.pfcDetailMinCharge = this.modelDetail.pfcDetailMinCharge;
            x.pfcDetailOogDoorOpen = this.modelDetail.pfcDetailOogDoorOpen;
            if(this.modelDetail.pfcDetailOogDoorOpen == "N"){
              this.modelDetail.pfcDetailOogDoorOpenName = "N/A";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "D"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Detached";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "B"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Both";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "L"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Left";
            }else if(this.modelDetail.pfcDetailOogDoorOpen == "R"){
              this.modelDetail.pfcDetailOogDoorOpenName = "Tied Right";
            }
            x.pfcDetailOogDoorOpenName = this.modelDetail.pfcDetailOogDoorOpenName;

            x.pfcDetailOogOH = this.modelDetail.pfcDetailOogOH;
            x.pfcDetailOogOLB = this.modelDetail.pfcDetailOogOLB;
            x.pfcDetailOogOLF = this.modelDetail.pfcDetailOogOLF;
            x.pfcDetailOogOWL = this.modelDetail.pfcDetailOogOWL;
            x.pfcDetailOogOWR = this.modelDetail.pfcDetailOogOWR;
            x.pfcDetailOogUcH = this.modelDetail.pfcDetailOogUcH;
            x.pfcDetailOogUcL = this.modelDetail.pfcDetailOogUcL;
            x.pfcDetailOogUcW = this.modelDetail.pfcDetailOogUcW;
            x.pfcDetailPC = this.modelDetail.pfcDetailPC;
            x.pfcDetailPCTab = this.modelDetail.pfcDetailPCTab;
            x.pfcDetailPayer = this.modelDetail.pfcDetailPayer;
            x.pfcDetailPayerName = this.modelDetail.pfcDetailPayerName;
            console.log("-- inside --")
            console.log(this.modelDetail.pfcDetailPlaceOfPayment);

            x.pfcDetailPlaceOfPayment = this.modelDetail.pfcDetailPlaceOfPayment;
            console.log(this.modelDetail.pfcDetailPlaceOfPayment);
            console.log( x.pfcDetailPlaceOfPayment);
            x.pfcDetailPlaceOfPaymentName = this.modelDetail.pfcDetailPlaceOfPaymentName;
            x.pfcDetailRT = this.modelDetail.pfcDetailRT;
            x.pfcDetailSeqNo = this.modelDetail.pfcDetailSeqNo;
            x.pfcDetailStatus = this.modelDetail.pfcDetailStatus;
            x.no = x.pfcDetailSeqNo;

            this.map.get(this.model.pfcContainerType).push(x);
          }

          if (this.currentActiveTab == "prepaid") {
            this.gridPrepaid.listStore.store.push(this.modelDetail);
            this.gridPrepaid.loadData();
          }

          if (this.currentActiveTab == "collect") {
            this.gridCollect.listStore.store.push(this.modelDetail);
            this.gridCollect.loadData();
          }

        }

        //// console.log("this.modelDetail.pfcDetailCopyTab => " + this.modelDetail.pfcDetailCopyTab);
        if(this.modelDetail.pfcDetailCopyTab == 'Y'){
          if (this.currentActiveTab == "prepaid") {
            this.onDetailCopyPrepaidProcess(this.modelDetail)
          }
          if (this.currentActiveTab == "collect") {
            this.onDetailCopyCollectProcess(this.modelDetail)
          }

        }

        this.isUpdateDetail = false;
        this.gridPrepaid.clearSelectedValues()
        this.gridCollect.clearSelectedValues()

        this.genericUtil.closeDialog('freightNewChargeForm');
        this.gridPrepaid.loadData();
        this.gridCollect.loadData();
      }

  }

  onNewCharge_Reset(){
      // console.log("** on new charge reset **");

      let seqNo = this.modelDetail.pfcDetailSeqNo;

      this.modelDetail = new FreightDetail();
      this.modelDetail.pfcDetailSeqNo = seqNo;

      this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
      this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailChargeCode);
      this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
      this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);

      if(this.cookieService.getDefaultLocationCountry().toUpperCase() == this.model.pfcFreightPartyCountry.toUpperCase()){
        if (this.model.pfcFreightPartyId != null && this.model.pfcFreightPartyId != undefined && this.model.pfcFreightPartyId != "") {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
          this.cbMaintenanceDetailPayer.setValue(this.model.pfcFreightPartyId);
        } else {
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("");
        }
      }else{
          this.cbMaintenanceDetailPayer.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
          this.cbMaintenanceDetailPayer.setValue("**");
      }

     // this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);
      this.isErrorDetail = false;
      this.isUpdateDetail = false;
      this.gridPrepaid.clearSelectedValues();
      this.gridCollect.clearSelectedValues();
  }

  onNewCharge_Close(){
      // console.log("** on new charge close **");
      this.isErrorDetail = false;
      this.isUpdateDetail = false;
      this.gridPrepaid.clearSelectedValues();
      this.gridCollect.clearSelectedValues();
      /*
       this.cbMaintenanceDetailCharge.setValue(this.modelDetail.pfcDetailChargeCode);
       this.cbMaintenanceDetailCurrency.setValue(this.modelDetail.pfcDetailChargeCode);
       this.cbMaintenanceDetailPC.setValue(this.modelDetail.pfcDetailPC);
       this.cbMaintenanceDetailPlaceOfPayment.setValue(this.modelDetail.pfcDetailPlaceOfPayment);
       this.cbMaintenanceDetailPayer.setValue(this.modelDetail.pfcDetailPayer);
       this.isErrorDetail=false;
       */
      //$('#freightNewChargeForm').modal('setting', 'closable', false).modal('hide');
      this.genericUtil.closeDialog('freightNewChargeForm');
      this.gridPrepaid.loadData();
      this.gridCollect.loadData();
  }

  checkDuplicate(target:string, modelDetail:FreightDetail):boolean {
      // console.log("** check duplicate **");
      let isDuplicateCodeDetected = false;
      let snapCode: string = "";
      //snapCode += modelDetail.pfcDetailPC.trim();
      snapCode += modelDetail.pfcDetailChargeCode.trim();
      //snapCode += modelDetail.pfcDetailCurrency.trim();
      //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
      //snapCode += modelDetail.pfcDetailPayer.trim();
      snapCode += (modelDetail.pfcDetailKillSlot == null) ? "0" : modelDetail.pfcDetailKillSlot.toString();
      snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
      snapCode += (modelDetail.pfcDetailOogOH == null) ? "0" : modelDetail.pfcDetailOogOH.toString();
      snapCode += (modelDetail.pfcDetailOogOWL == null) ? "0" : modelDetail.pfcDetailOogOWL.toString();
      snapCode += (modelDetail.pfcDetailOogOWR == null) ? "0" : modelDetail.pfcDetailOogOWR.toString();
      snapCode += (modelDetail.pfcDetailOogOLF == null) ? "0" : modelDetail.pfcDetailOogOLF.toString();
      snapCode += (modelDetail.pfcDetailOogOLB == null) ? "0" : modelDetail.pfcDetailOogOLB.toString();
      snapCode += (modelDetail.pfcDetailOogUcH == null) ? "0" : modelDetail.pfcDetailOogUcH.toString();
      snapCode += (modelDetail.pfcDetailOogUcL == null) ? "0" : modelDetail.pfcDetailOogUcL.toString();
      snapCode += (modelDetail.pfcDetailOogUcW == null) ? "0" : modelDetail.pfcDetailOogUcW.toString();

      if (target == "prepaid") {
        if (this.gridPrepaid.listStore.store.length > 0) {
          this.gridPrepaid.listStore.store.forEach(element => {
            if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
              let snapCodeComparer: string = "";
              //snapCodeComparer += element.pfcDetailPC.trim();
              snapCodeComparer += element.pfcDetailChargeCode.trim();
              //snapCodeComparer += element.pfcDetailCurrency.trim();
              //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
              //snapCodeComparer += element.pfcDetailPayer.trim();
              snapCodeComparer += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
              snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
              snapCodeComparer += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();

              if (snapCodeComparer == snapCode) {
                isDuplicateCodeDetected = true;
                //this.isErrorDetail = true;
                //this.modelDetail['error-pfcDuplicateChargeCode']="Duplicate " + element.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
              }
            }
          });
        } else {
          isDuplicateCodeDetected = false;
        }
      } else {
        if (this.gridCollect.listStore.store.length > 0) {
          this.gridCollect.listStore.store.forEach(element => {
            if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
              let snapCodeComparer: string = "";
              //snapCodeComparer += element.pfcDetailPC.trim();
              snapCodeComparer += element.pfcDetailChargeCode.trim();
              //snapCodeComparer += element.pfcDetailCurrency.trim();
              //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
              //snapCodeComparer += element.pfcDetailPayer.trim();
              snapCodeComparer += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
              snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
              snapCodeComparer += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
              snapCodeComparer += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();

              if (snapCodeComparer == snapCode) {
                isDuplicateCodeDetected = true;
                //this.isErrorDetail = true;
                //this.modelDetail['error-pfcDuplicateChargeCode']="Duplicate " + element.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
              }
            }
          });
        } else {
          isDuplicateCodeDetected = false;
        }
      }

      return isDuplicateCodeDetected;
  }

  checkDuplicateMainCharges(target:string, modelDetail:FreightDetail):boolean {
    // console.log('** check duplicate **');
    let checkMap = new Map<String,String>();
    let result:boolean = false;
    let isDuplicateCodeDetected:boolean = false;
    let snapCode: string = '';
    let snapCodeMain: string = '';

    snapCodeMain += modelDetail.pfcDetailChargeCode.trim();

    //snapCode += modelDetail.pfcDetailPC.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode += (modelDetail.pfcDetailKillSlot == null) ? '0' : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode += (modelDetail.pfcDetailOogOH == null) ? '0' : modelDetail.pfcDetailOogOH.toString();
    snapCode += (modelDetail.pfcDetailOogOWL == null) ? '0' : modelDetail.pfcDetailOogOWL.toString();
    snapCode += (modelDetail.pfcDetailOogOWR == null) ? '0' : modelDetail.pfcDetailOogOWR.toString();
    snapCode += (modelDetail.pfcDetailOogOLF == null) ? '0' : modelDetail.pfcDetailOogOLF.toString();
    snapCode += (modelDetail.pfcDetailOogOLB == null) ? '0' : modelDetail.pfcDetailOogOLB.toString();
    snapCode += (modelDetail.pfcDetailOogUcH == null) ? '0' : modelDetail.pfcDetailOogUcH.toString();
    snapCode += (modelDetail.pfcDetailOogUcL == null) ? '0' : modelDetail.pfcDetailOogUcL.toString();
    snapCode += (modelDetail.pfcDetailOogUcW == null) ? '0' : modelDetail.pfcDetailOogUcW.toString();


    if (target == 'prepaid') {
      if (this.gridPrepaid.listStore.store.length > 0) {
        this.gridPrepaid.listStore.store.forEach(element => {

          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparerMain: string = '';
            let snapCodeComparer: string = '';

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if(element.pfcDetailChargeCode == "FAI" || element.pfcDetailChargeCode == "OFT" || element.pfcDetailChargeCode == "FOF"){
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (snapCodeMain+snapCode == snapCodeComparerMain+snapCodeComparer) {
                isDuplicateCodeDetected = true;
              }
            }
            /*
            if (snapCodeMain != snapCodeComparerMain && snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
            }
            */
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }

    } else {
      if (this.gridCollect.listStore.store.length > 0) {
        this.gridCollect.listStore.store.forEach(element => {
          if(modelDetail.pfcDetailSeqNo != element.pfcDetailSeqNo){
            let snapCodeComparerMain: string = '';
            let snapCodeComparer: string = '';

            snapCodeComparerMain += element.pfcDetailChargeCode.trim();

            //snapCodeComparer += element.pfcDetailPC.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? '0' : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? '0' : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? '0' : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? '0' : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? '0' : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? '0' : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? '0' : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? '0' : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? '0' : element.pfcDetailOogUcW.toLocaleString();

            if(element.pfcDetailChargeCode == "FAI" || element.pfcDetailChargeCode == "OFT" || element.pfcDetailChargeCode == "FOF"){
              //checkMap.set(element.pfcDetailChargeCode,element.pfcDetailChargeCode);
              if (snapCodeMain+snapCode == snapCodeComparerMain+snapCodeComparer) {
                isDuplicateCodeDetected = true;
              }
            }

            /*
            if (snapCodeMain != snapCodeComparerMain && snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
            }
            */
          }
        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }


    console.log(isDuplicateCodeDetected);
    /*
    if(isDuplicateCodeDetected){
      if(checkMap.size > 0){
        result = true;
      }
    }
    console.log(checkMap);
    */
    return result;
  }

  checkDuplicateCopy(target:string, modelDetail:FreightDetail):boolean {
    // console.log("** check duplicate for copy **");
    let isDuplicateCodeDetected = false;
    let snapCode: string = "";
    //snapCode += modelDetail.pfcDetailPC.trim();
    snapCode += modelDetail.pfcDetailChargeCode.trim();
    //snapCode += modelDetail.pfcDetailCurrency.trim();
    //snapCode += modelDetail.pfcDetailPlaceOfPayment.trim();
    //snapCode += modelDetail.pfcDetailPayer.trim();
    snapCode += (modelDetail.pfcDetailKillSlot == null) ? "0" : modelDetail.pfcDetailKillSlot.toString();
    snapCode += modelDetail.pfcDetailOogDoorOpen.trim();
    snapCode += (modelDetail.pfcDetailOogOH == null) ? "0" : modelDetail.pfcDetailOogOH.toString();
    snapCode += (modelDetail.pfcDetailOogOWL == null) ? "0" : modelDetail.pfcDetailOogOWL.toString();
    snapCode += (modelDetail.pfcDetailOogOWR == null) ? "0" : modelDetail.pfcDetailOogOWR.toString();
    snapCode += (modelDetail.pfcDetailOogOLF == null) ? "0" : modelDetail.pfcDetailOogOLF.toString();
    snapCode += (modelDetail.pfcDetailOogOLB == null) ? "0" : modelDetail.pfcDetailOogOLB.toString();
    snapCode += (modelDetail.pfcDetailOogUcH == null) ? "0" : modelDetail.pfcDetailOogUcH.toString();
    snapCode += (modelDetail.pfcDetailOogUcL == null) ? "0" : modelDetail.pfcDetailOogUcL.toString();
    snapCode += (modelDetail.pfcDetailOogUcW == null) ? "0" : modelDetail.pfcDetailOogUcW.toString();

    if (target == "prepaid") {
      if (this.gridPrepaid.listStore.store.length > 0) {
        this.gridPrepaid.listStore.store.forEach(element => {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']="Duplicate " + element.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
            }

        });
      } else {
        isDuplicateCodeDetected = false;
      }
    } else {
      if (this.gridCollect.listStore.store.length > 0) {
        this.gridCollect.listStore.store.forEach(element => {
            let snapCodeComparer: string = "";
            //snapCodeComparer += element.pfcDetailPC.trim();
            snapCodeComparer += element.pfcDetailChargeCode.trim();
            //snapCodeComparer += element.pfcDetailCurrency.trim();
            //snapCodeComparer += element.pfcDetailPlaceOfPayment.trim();
            //snapCodeComparer += element.pfcDetailPayer.trim();
            snapCodeComparer += (element.pfcDetailKillSlot == null) ? "0" : element.pfcDetailKillSlot.toString();
            snapCodeComparer += element.pfcDetailOogDoorOpen.trim();
            snapCodeComparer += (element.pfcDetailOogOH == null) ? "0" : element.pfcDetailOogOH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWL == null) ? "0" : element.pfcDetailOogOWL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOWR == null) ? "0" : element.pfcDetailOogOWR.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLF == null) ? "0" : element.pfcDetailOogOLF.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogOLB == null) ? "0" : element.pfcDetailOogOLB.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcH == null) ? "0" : element.pfcDetailOogUcH.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcL == null) ? "0" : element.pfcDetailOogUcL.toLocaleString();
            snapCodeComparer += (element.pfcDetailOogUcW == null) ? "0" : element.pfcDetailOogUcW.toLocaleString();

            if (snapCodeComparer == snapCode) {
              isDuplicateCodeDetected = true;
              //this.isErrorDetail = true;
              //this.modelDetail['error-pfcDuplicateChargeCode']="Duplicate " + element.pfcDetailChargeCode.toUpperCase().trim() + " charge code detected."
            }

        });
      } else {
        isDuplicateCodeDetected = false;
      }
    }

    return isDuplicateCodeDetected;
}

  setValidatorSave(){
      this.clearValidatorError();
      this.validatorRules = {
        pfcFreightPartyId: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Freight Party.'
          }]
        },
        pfcPlaceOfReceiptCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Place of Receipt.'
          }]
        },
        pfcLoadPortCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Port of Load.'
          }]
        },
        pfcDischargePortCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Port of Discharge.'
          }]
        },
        pfcPlaceOfDeliveryCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Place of Delivery.'
          }]
        },
        pfcReceiptTypeCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Receipt Type.'
          }]
        },
        pfcDeliveryTypeCode: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Delivery Type.'
          }]
        },
        pfcEffectiveFrom: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Effective From.'
          }]
        },
        pfcEffectiveTo: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Expiry On.'
          }]
        },
        pfcAcctPic: {
          rules: [{
            type: 'empty',
            prompt: 'Please input Acct PIC.'
          }]
        },
        effectiveDateExceedValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Effective period cannot exceed a year.'
          }]
        },
        expiryDateValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Expirly On Date must be later than Effective From.'
          }]
        },
        samePORValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Place Of Receipt should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).'
          }]
        },
        samePOLValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Port of Load should not the same with Port Of Discharge / Place Of Delivery / Final Destination / Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).'
          }]
        },
        samePODValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Port of Discharge should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).'
          }]
        },
        samePODLValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Place of Delivery should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).'
          }]
        },
        sameFDestValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Final Destination should not the same with Port of Transhipment(1) / Port of Transhipment(2) / Port of Transhipment(3).'
          }]
        },
        blankFreightChargesValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Blank Freight Charge detected.'
          }]
        },
        missingMainChargesValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Missing Main Charges detected.'
          }]
        },
        mainChargesOnlyOneValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Main Charges just allow only one.'
          }]
        },
        blankDetentionRequestValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Please input detention.'
          }]
        },
        blankDemurrageRequestValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Please input demurrage.'
          }]
        },
        blankAdhocVoyageValidator: {
          rules: [{
            type: 'custom',
            prompt: 'Please input AdHoc Voyage.'
          }]
        },
      }
  }

  checkEffectiveDateExceedValidator():Boolean {
      let result: Boolean = false;
      var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      var diffDays = Math.floor(Math.abs((new Date(this.model.pfcEffectiveTo).getTime() - new Date(this.model.pfcEffectiveFrom).getTime()) / (oneDay)));
      if (diffDays > 366) {
        return true;
      }
      return result;
  }

  checkExpiryDateValidator(): Boolean {
      let result: Boolean = false;
      if (this.model.pfcEffectiveTo != "") {
        if (this.model.pfcEffectiveTo <= this.model.pfcEffectiveFrom) {
          this.model['error-expiryDateValidator'] = "Expirly On Date (" + this.model.pfcEffectiveTo.toString() + ") must be later than Effective From.";
          return true;
        }
      }

      return result;
  }

  checkSamePORValidator(): Boolean{
      let result: Boolean = false;

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcDischargePortCode != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcDischargePortCode) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Port Of Discharge.";
          return true;
        }
      }

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcPlaceOfDeliveryCode != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcPlaceOfDeliveryCode) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Place Of Delivery.";
          return true;
        }
      }

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcFinalDestinationCode != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcFinalDestinationCode) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Final Destination.";
          return true;
        }
      }

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcPortOfTransshipment1Code != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcPortOfTransshipment1Code) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Port Of Transhipment(1).";
          return true;
        }
      }

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcPortOfTransshipment2Code != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcPortOfTransshipment2Code) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Port Of Transhipment(2).";
          return true;
        }
      }

      if (this.model.pfcPlaceOfReceiptCode != "" && this.model.pfcPortOfTransshipment3Code != "") {
        if (this.model.pfcPlaceOfReceiptCode == this.model.pfcPortOfTransshipment3Code) {
          this.model['error-samePORValidator'] = "Place Of Receipt should not the same with Port Of Transhipment(3).";
          return true;
        }
      }

      return result;
  }

  checkSamePOLValidator():Boolean {
      let result: Boolean = false;

      if (this.model.pfcLoadPortCode != "" && this.model.pfcDischargePortCode != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcDischargePortCode) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Port Of Discharge.";
          return true;
        }
      }

      if (this.model.pfcLoadPortCode != "" && this.model.pfcPlaceOfDeliveryCode != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcPlaceOfDeliveryCode) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Place Of Delivery.";
          return true;
        }
      }

      if (this.model.pfcLoadPortCode != "" && this.model.pfcFinalDestinationCode != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcFinalDestinationCode) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Final Destination.";
          return true;
        }
      }

      if (this.model.pfcLoadPortCode != "" && this.model.pfcPortOfTransshipment1Code != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment1Code) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Port Of Transhipment(1).";
          return true;
        }
      }

      if (this.model.pfcLoadPortCode != "" && this.model.pfcPortOfTransshipment2Code != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment2Code) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Port Of Transhipment(2).";
          return true;
        }
      }

      if (this.model.pfcLoadPortCode != "" && this.model.pfcPortOfTransshipment3Code != "") {
        if (this.model.pfcLoadPortCode == this.model.pfcPortOfTransshipment3Code) {
          this.model['error-samePOLValidator'] = "Port Of Load should not the same with Port Of Transhipment(3).";
          return true;
        }
      }


      return result;
  }

  checkSamePODValidator():Boolean{
      let result: Boolean = false;

      if (this.model.pfcDischargePortCode != "" && this.model.pfcPortOfTransshipment1Code != "") {
        if (this.model.pfcDischargePortCode == this.model.pfcPortOfTransshipment1Code) {
          this.model['error-samePODValidator'] = "Port Of Discharge should not the same with Port Of Transhipment(1).";
          return true;
        }
      }

      if (this.model.pfcDischargePortCode != "" && this.model.pfcPortOfTransshipment2Code != "") {
        if (this.model.pfcDischargePortCode == this.model.pfcPortOfTransshipment2Code) {
          this.model['error-samePODValidator'] = "Port Of Discharge should not the same with Port Of Transhipment(2).";
          return true;
        }
      }

      if (this.model.pfcDischargePortCode != "" && this.model.pfcPortOfTransshipment3Code != "") {
        if (this.model.pfcDischargePortCode == this.model.pfcPortOfTransshipment3Code) {
          this.model['error-samePODValidator'] = "Port Of Discharge should not the same with Port Of Transhipment(3).";
          return true;
        }
      }

      return result;

  }

  checkSamePODLValidator():Boolean{
      let result: Boolean = false;

      if (this.model.pfcPlaceOfDeliveryCode != "" && this.model.pfcPortOfTransshipment1Code != "") {
        if (this.model.pfcPlaceOfDeliveryCode == this.model.pfcPortOfTransshipment1Code) {
          this.model['error-samePODLValidator'] = "Place of Delivery should not the same with Port Of Transhipment(1).";
          return true;
        }
      }

      if (this.model.pfcPlaceOfDeliveryCode != "" && this.model.pfcPortOfTransshipment2Code != "") {
        if (this.model.pfcPlaceOfDeliveryCode == this.model.pfcPortOfTransshipment2Code) {
          this.model['error-samePODLValidator'] = "Place of Delivery should not the same with Port Of Transhipment(2).";
          return true;
        }
      }

      if (this.model.pfcPlaceOfDeliveryCode != "" && this.model.pfcPortOfTransshipment3Code != "") {
        if (this.model.pfcPlaceOfDeliveryCode == this.model.pfcPortOfTransshipment3Code) {
          this.model['error-samePODLValidator'] = "Place of Delivery should not the same with Port Of Transhipment(3).";
          return true;
        }
      }

      return result;

  }

  checkSameFDestValidator():Boolean {
      let result: Boolean = false;

      if (this.model.pfcFinalDestinationCode != "" && this.model.pfcPortOfTransshipment1Code != "") {
        if (this.model.pfcFinalDestinationCode == this.model.pfcPortOfTransshipment1Code) {
          this.model['error-sameFDestValidator'] = "Final Destination should not the same with Port Of Transhipment(1).";
          return true;
        }
      }

      if (this.model.pfcFinalDestinationCode != "" && this.model.pfcPortOfTransshipment2Code != "") {
        if (this.model.pfcFinalDestinationCode == this.model.pfcPortOfTransshipment2Code) {
          this.model['error-sameFDestValidator'] = "Final Destination should not the same with Port Of Transhipment(2).";
          return true;
        }
      }

      if (this.model.pfcFinalDestinationCode != "" && this.model.pfcPortOfTransshipment3Code != "") {
        if (this.model.pfcFinalDestinationCode == this.model.pfcPortOfTransshipment3Code) {
          this.model['error-sameFDestValidator'] = "Final Destination should not the same with Port Of Transhipment(3).";
          return true;
        }
      }

      return result;
  }

  checkBlankFreightChargesValidator():Boolean {
      // console.log("** check blank freight charges **");
      // console.log(this.map.get(this.model.pfcContainerType))

      let result: Boolean = false;
      if (this.map.get(this.model.pfcContainerType) == null || this.map.get(this.model.pfcContainerType) == undefined) {
        // console.log("#check 1");

        var ct: string
        switch (this.model.pfcContainerType) {
          case "TNK":
            ct = 'TANK';
            break;
          case "OPT":
            ct = 'OPEN TOP';
            break;
          case "FLR":
            ct = 'FLAT RACK';
            break;
          case "PLT":
            ct = 'PLATFORM';
            break;
          case "REF":
            ct = 'REEFER';
            break;
          case "DRY":
            ct = 'REEFER DRY';
            break;
          case "UC":
            ct = 'UC';
            break;
          default:
            ct = 'NORMAL';
            break;
        }

        this.model['error-blankFreightChargesValidator'] = "Blank Freight Charge detected. Please input at least one record for Container Type " + ct;
        result = true;
      } else {
        // console.log("#check 2");
        let dets: FreightDetail[] = this.map.get(this.model.pfcContainerType);
        if (dets.length == 0) {

          var ct: string
          switch (this.model.pfcContainerType) {
            case "TNK":
              ct = 'TANK';
              break;
            case "OPT":
              ct = 'OPEN TOP';
              break;
            case "FLR":
              ct = 'FLAT RACK';
              break;
            case "PLT":
              ct = 'PLATFORM';
              break;
            case "REF":
              ct = 'REEFER';
              break;
            case "DRY":
              ct = 'REEFER DRY';
              break;
            case "UC":
              ct = 'UC';
              break;
            default:
              ct = 'NORMAL';
              break;
          }

          this.model['error-blankFreightChargesValidator'] = "Blank Freight Charge detected. Please input at least one record for Container Type " + ct;
          result = true;

        }
      }
      return result;
  }

  checkMainChargesOnlyOneValidator():Boolean {
    console.log("** checkMainChargesOnlyOneValidator **")
    let result: Boolean = false;

    let mapCTTab = new Map();
    this.map.forEach((value: FreightDetail[], key: string) => {

      value.forEach(element => {

        let key:string = element.pfcDetailContainerType + '-' + element.pfcDetailPCTab + ':' + element.pfcDetailKT + '-' +
        element.pfcDetailKillSlot + '-' + element.pfcDetailM3 + '-' + element.pfcDetailMinCharge + '-' + element.pfcDetailOogDoorOpen + '-' + element.pfcDetailOogOH + '-' +
        element.pfcDetailOogOLB + '-' + element.pfcDetailOogOLF + '-' + element.pfcDetailOogOWL + '-' + element.pfcDetailOogOWR + '-' + element.pfcDetailOogUcH + '-' +
        element.pfcDetailOogUcL + '-' + element.pfcDetailOogUcW;

        if (mapCTTab.get(key) == undefined) {
          let datas: FreightDetail[] = [];
          datas.push(element);
          mapCTTab.set(key, datas);
        } else {
          mapCTTab.get(key).push(element);
        }

      });

    });

    // let str:String = 'Main charge just allow only 1 on ';
    let str: String = 'Please Check Main Charge. Only 1 main charge (OFT/FOF/FAI) allowed. ';


    mapCTTab.forEach((value: FreightDetail[], key: string) => {
      let ctList: any[] = [];
      value.forEach(element => {
        if (element.pfcDetailChargeCode.trim() == "OFT" || element.pfcDetailChargeCode.trim() == "FAI" || element.pfcDetailChargeCode.trim() == "FOF") {
          ctList.push(key);
        }
      });

      console.log(ctList);
      if(ctList.length > 1){
        var strCtList:string = ctList.toString();
        let strCtLists:string[] = strCtList.split(":")

        strCtLists[0] = strCtLists[0].replace(/TNK/g,'TANK');
        strCtLists[0] = strCtLists[0].replace(/OPT/g,'OPEN TOP')
        strCtLists[0] = strCtLists[0].replace(/FLR/g,'FLAT RACK')
        strCtLists[0] = strCtLists[0].replace(/PLT/g,'PLATFORM')
        strCtLists[0] = strCtLists[0].replace(/REF/g,'REEFER')
        strCtLists[0] = strCtLists[0].replace(/STD/g,'NORMAL')
        strCtLists[0] = strCtLists[0].replace(/UC/g,'UC')
        strCtLists[0] = strCtLists[0].replace(/-P/g,' [PREPAID]')
        strCtLists[0] = strCtLists[0].replace(/-C/g,' [COLLECT]')

        str += strCtLists[0] + ",";
        result = true;
      }

    });

    if(result){
      this.model['error-mainChargesOnlyOneValidator'] = str.substring(0,str.length-1) + ".";
    }

    return result;
  }

  checkMissingMainChargesValidator():Boolean {
      let result: Boolean = false;
      let ctList: any[] = [];
      let mapCTTab = new Map();
      this.map.forEach((value: FreightDetail[], key: string) => {

        value.forEach(element => {

          /*
          let key:string = element.pfcDetailContainerType + '-' + element.pfcDetailPCTab + ':' + element.pfcDetailCurrency + '-' + element.pfcDetailKT + '-' +
          element.pfcDetailKillSlot + '-' + element.pfcDetailM3 + '-' + element.pfcDetailMinCharge + '-' + element.pfcDetailOogDoorOpen + '-' + element.pfcDetailOogOH + '-' +
          element.pfcDetailOogOLB + '-' + element.pfcDetailOogOLF + '-' + element.pfcDetailOogOWL + '-' + element.pfcDetailOogOWR + '-' + element.pfcDetailOogUcH + '-' +
          element.pfcDetailOogUcL + '-' + element.pfcDetailOogUcW + '-' + element.pfcDetailPC + '-' + element.pfcDetailPayer + '-' + element.pfcDetailPlaceOfPayment;
          */
          let key:string = element.pfcDetailContainerType + '-' + element.pfcDetailPCTab + ':' + element.pfcDetailKT + '-' +
          element.pfcDetailKillSlot + '-' + element.pfcDetailM3 + '-' + element.pfcDetailMinCharge + '-' + element.pfcDetailOogDoorOpen + '-' + element.pfcDetailOogOH + '-' +
          element.pfcDetailOogOLB + '-' + element.pfcDetailOogOLF + '-' + element.pfcDetailOogOWL + '-' + element.pfcDetailOogOWR + '-' + element.pfcDetailOogUcH + '-' +
          element.pfcDetailOogUcL + '-' + element.pfcDetailOogUcW;

          /*
          if (mapCTTab.get(element.pfcDetailContainerType + "-" + element.pfcDetailPCTab) == undefined) {
            let datas: FreightDetail[] = [];
            datas.push(element);
            mapCTTab.set(element.pfcDetailContainerType + "-" + element.pfcDetailPCTab, datas);
          } else {
            mapCTTab.get(element.pfcDetailContainerType + "-" + element.pfcDetailPCTab).push(element);
          }
          */
          if (mapCTTab.get(key) == undefined) {
            let datas: FreightDetail[] = [];
            datas.push(element);
            mapCTTab.set(key, datas);
          } else {
            mapCTTab.get(key).push(element);
          }

        });

      });


      // console.log(mapCTTab)

      mapCTTab.forEach((value: FreightDetail[], key: string) => {

        // console.log('Key -> ' + key);
        ctList.push(key);
        value.forEach(element => {
          if (element.pfcDetailChargeCode.trim() == "OFT" || element.pfcDetailChargeCode.trim() == "FAI" || element.pfcDetailChargeCode.trim() == "FOF") {
            var eek = ctList.indexOf(key);
            ctList.splice(eek, 1);
          } else {
          }
        });


      });

      // console.log(ctList);
      if (ctList.length > 0) {
        var strCtList:string = ctList.toString();
        let strCtLists:string[] = strCtList.split(":")

        strCtLists[0] = strCtLists[0].replace(/TNK/g,'TANK');
        strCtLists[0] = strCtLists[0].replace(/OPT/g,'OPEN TOP')
        strCtLists[0] = strCtLists[0].replace(/FLR/g,'FLAT RACK')
        strCtLists[0] = strCtLists[0].replace(/PLT/g,'PLATFORM')
        strCtLists[0] = strCtLists[0].replace(/REF/g,'REEFER')
        strCtLists[0] = strCtLists[0].replace(/STD/g,'NORMAL')
        strCtLists[0] = strCtLists[0].replace(/UC/g,'UC')
        strCtLists[0] = strCtLists[0].replace(/-P/g,' [PREPAID]')
        strCtLists[0] = strCtLists[0].replace(/-C/g,' [COLLECT]')

        // console.log(strCtList);
        this.model['error-missingMainChargesValidator'] = 'Missing Main Charge Detected on Container Type ' + strCtLists[0] + '! Please check your charges.';
        result = true;
      }

      return result;
  }

  checkBlankDetentionRequestValidator():Boolean {
    let result: Boolean = false;
    if(this.model.pfcDetention == 'R' && this.model.pfcDetentionRequest == 0){
      result = true;
    }
    return result;
  }

  checkBlankDemurrageRequestValidator():Boolean {
    let result: Boolean = false;
    if(this.model.pfcDemurrage == 'R' && this.model.pfcDemurrageRequest == 0){
      result = true;
    }
    return result;
  }

  checkBlankAdhocVoyageValidator():Boolean {
    let result: Boolean = false;
    if(this.model.pfcVesselId != '' && this.model.pfcVoyage == ""){
      result = true;
    }
    return result;
  }

  checkChargesValueValidator():Boolean {
      let result: Boolean = false;

      if (this.modelDetail.pfcDetailD20 == 0 && this.modelDetail.pfcDetailD40 == 0 && this.modelDetail.pfcDetailD45 == 0 &&
        this.modelDetail.pfcDetailH20 == 0 && this.modelDetail.pfcDetailH40 == 0 && this.modelDetail.pfcDetailH45 == 0) {
        if (this.modelDetail.pfcDetailChargeCode != "FOF") {
          this.modelDetail['error-chargesValueValidator'] = 'Please input charges value.';
          result = true;
        }
      }

      return result;

  }

  checkMainChargesValidator():Boolean {
      let result: Boolean = false;

      if (this.modelDetail.pfcDetailChargeCode == "FAI" &&
        this.modelDetail.pfcDetailD20 == 0 && this.modelDetail.pfcDetailD40 == 0 && this.modelDetail.pfcDetailD45 == 0 &&
        this.modelDetail.pfcDetailH20 == 0 && this.modelDetail.pfcDetailH40 == 0 && this.modelDetail.pfcDetailH45 == 0) {
        // console.log("#1");
        this.modelDetail['error-mainChargesValidator'] = 'FAI charge code cannot be 0 value';
        return true;
      }

      if (this.modelDetail.pfcDetailChargeCode == "OFT" &&
        this.modelDetail.pfcDetailD20 == 0 && this.modelDetail.pfcDetailD40 == 0 && this.modelDetail.pfcDetailD45 == 0 &&
        this.modelDetail.pfcDetailH20 == 0 && this.modelDetail.pfcDetailH40 == 0 && this.modelDetail.pfcDetailH45 == 0) {
        // console.log("#2");
        this.modelDetail['error-mainChargesValidator'] = 'OFT charge code cannot be 0 value';
        return true;
      }

      if (this.modelDetail.pfcDetailChargeCode == "FOF" && (
        this.modelDetail.pfcDetailD20 != 0 || this.modelDetail.pfcDetailD40 != 0 || this.modelDetail.pfcDetailD45 != 0 ||
        this.modelDetail.pfcDetailH20 != 0 || this.modelDetail.pfcDetailH40 != 0 || this.modelDetail.pfcDetailH45 != 0 )) {
        // console.log("#3");
        this.modelDetail['error-mainChargesValidator'] = 'FOF charge code must set 0 value';
        return true;
      }

      // console.log(result);
      return result;
  }


  clearValidatorError(){
      this.model['error-pfcFreightPartyId'] = '';
      this.model['error-pfcPlaceOfReceiptCode'] = '';
      this.model['error-pfcLoadPortCode'] = '';
      this.model['error-pfcDischargePortCode'] = '';
      this.model['error-pfcPlaceOfDeliveryCode'] = '';
      this.model['error-pfcReceiptTypeCode'] = '';
      this.model['error-pfcDeliveryTypeCode'] = '';
      this.model['error-pfcEffectiveFrom'] = '';
      this.model['error-pfcEffectiveTo'] = '';
      this.model['error-pfcAcctPic'] = '';
      this.model['error-effectiveDateExceedValidator'] = '';
      this.model['error-expiryDateValidator'] = '';
      this.model['error-samePORValidator'] = '';
      this.model['error-samePOLValidator'] = '';
      this.model['error-samePODValidator'] = '';
      this.model['error-samePODLValidator'] = '';
      this.model['error-sameFDestValidator'] = '';
      this.model['error-blankFreightChargesValidator'] = '';
      this.model['error-missingMainChargesValidator'] = '';
      this.model['error-mainChargesOnlyOneValidator'] = '';
      this.model['error-blankDetentionRequestValidator'] = '';
      this.model['error-blankDemurrageRequestValidator'] = '';
      this.model['error-blankAdhocVoyageValidator'] = '';
  }

   setValidatorAddNewCharge(){
      this.clearValidatorErrorDetail();
      this.validatorRules = {
        pfcDetailChargeCode: {rules: [{type: 'empty', prompt: 'Please input Charge.'}]},
        pfcDetailCurrency: {rules: [{type: 'empty', prompt: 'Please input Currency.'}]},
        pfcDetailPC: {rules: [{type: 'empty', prompt: 'Please input P/C.'}]},
        pfcDetailPlaceOfPayment: {rules: [{type: 'empty', prompt: 'Please input Place of Payment.'}]},
        pfcDetailPayer: {rules: [{type: 'empty', prompt: 'Please input Payer.'}]},
        chargesValueValidator: {rules: [{type: 'custom', prompt: 'Please input charges value.'}]},
        mainChargesValidator: {rules: [{type: 'custom', prompt: 'Main Charges problem detected.'}]},
      }
  }

  setValidatorAddNewChargeCollect(){
    this.clearValidatorErrorDetail();
    this.validatorRules = {
      pfcDetailChargeCode: {rules: [{type: 'empty', prompt: 'Please input Charge.'}]},
      pfcDetailCurrency: {rules: [{type: 'empty', prompt: 'Please input Currency.'}]},
      pfcDetailPC: {rules: [{type: 'empty', prompt: 'Please input P/C.'}]},
      pfcDetailPlaceOfPayment: {rules: [{type: 'empty', prompt: 'Please input Place of Payment.'}]},
      chargesValueValidator: {rules: [{type: 'custom', prompt: 'Please input charges value.'}]},
      mainChargesValidator: {rules: [{type: 'custom', prompt: 'Main Charges problem detected.'}]},
    }
  }
  clearValidatorErrorDetail(){
      this.modelDetail['error-pfcDetailChargeCode'] = '';
      this.modelDetail['error-pfcDetailCurrency'] = '';
      this.modelDetail['error-pfcDetailPC'] = '';
      this.modelDetail['error-pfcDetailPlaceOfPayment'] = '';
      this.modelDetail['error-pfcDetailPayer'] = '';
      this.modelDetail['error-chargesValueValidator'] = '';
      this.modelDetail['error-pfcDuplicateChargeCode'] = '';
      this.modelDetail['error-mainChargesValidator'] = '';
  }

  getEtaEtdAtaAtd(vesselId:String, voyage:String, bound:String, officeCode:String){
      this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getSailSchedule/' + vesselId + '/' + voyage + '/' + bound + '/' + officeCode).subscribe((resp) => {

        let data = resp.json();
        this.model.pfcLocalEta = String(data['ETA']).trim().replace(/\//gi, "-");
        this.model.pfcLocalEtd = String(data['ETD']).replace(/\//g, "-");
        this.model.pfcLocalAta = String(data['ATA']).replace(/\//g, "-");
        this.model.pfcLocalAtd = String(data['ATD']).replace(/\//g, "-");

      }, error => {
        // console.log(error.message);
      });
  }


  //Action when tab prepaid / tab collect is selected

  onTabPrepaid(){
      this.currentActiveTab = "prepaid";
      this.gridPrepaid.loadData();
  }

  onTabCollect(){
      this.currentActiveTab = "collect";
      this.gridCollect.loadData();
  }

  gridEventPrepaid(event){
      // console.log("Click Event " + event);
      switch (event.split(".")[0]) {
        case 'selected':
          break;
        case 'click' :
          var no = event.split(".")[1].split("-")[1];

          this.gridPrepaid.clearSelectedValues();
          // console.log("no => " + no)
          this.gridPrepaid.onSelect(true, Number.parseInt(no));

          break;
        default:
          let strEvent: string = event;
          if (strEvent != '') {
            let arr: string[] = strEvent.split('-');
            switch (arr[0]) {
              case 'edit':
                break;
              case 'dblClick':
                this.onDetailUpdate();
                break;
              default:
                break;
            }
          }
          break;
      }
  }

  selectedListMod(value, store:GridPluginComponent, key) {
      // console.log("no " + value);
      if (store.getSelectedValues().length > 1) {
        store.getSelectedValues().forEach(ff=> {
          if (ff[key] != value) {
            ff['select'] = '';
          }
        });

        store.getSelectedValues().splice(0, 1);
      }
      // console.log("total checked " + store.getSelectedValues().length);
  }

  changeFreightParty(event){
      if (event["customerId"] != null && event["customerId"] != "" && event["customerId"] !== undefined) {
        this.model.pfcFreightPartyId = event["customerId"].toUpperCase();
      } else {
        this.model.pfcFreightPartyId = "";
      }

      //revert the search url to use name
      this.cbFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
  }

  changeDGClass(event){
      if (event["dgClassCode"] != null && event["dgClassCode"] != "" && event["dgClassCode"] !== undefined) {
        this.model.pfcDgClass = event["dgClassCode"].toUpperCase();
      } else {
        this.model.pfcDgClass = "ALL";
      }
  }

  changeContainerStatus(event){
      if (event["ctrStatusCode"] != null && event["ctrStatusCode"] != "" && event["ctrStatusCode"] !== undefined) {
        this.model.pfcContainerStatus = event["ctrStatusCode"].toUpperCase();
      } else {
        this.model.pfcContainerStatus = "ALL";
      }
  }

  changeValidInvalid(event){
      // console.log(event);
      if (event["isValidCode"] != null && event["isValidCode"] != "" && event["isValidCode"] !== undefined) {
        this.model.pfcIsValid = event["isValidCode"].toUpperCase();
      } else {
        this.model.pfcIsValid = "ALL";
      }
  }

  changeContainerOwnership(event){
      if (event["ctrOwnerCode"] != null && event["ctrOwnerCode"] != "" && event["ctrOwnerCode"] !== undefined) {
        this.model.pfcContainerOwnership = event["ctrOwnerCode"].toUpperCase();
      } else {
        this.model.pfcContainerOwnership = "ALL";
      }
  }

  changeContainerType(event){
      if (event["containerTypeCode"] != null && event["containerTypeCode"] != "" && event["containerTypeCode"] !== undefined) {
        this.model.pfcContainerType = event["containerTypeCode"].toUpperCase();
      } else {
        this.model.pfcContainerType = "ALL";
      }
  }

  changeReceiptType(event){
      if (event["receiptTypeCode"] != null && event["receiptTypeCode"] != "" && event["receiptTypeCode"] !== undefined) {
        this.model.pfcReceiptTypeCode = event["receiptTypeCode"].toUpperCase();
      } else {
        this.model.pfcReceiptTypeCode = "ALL";
      }
    this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeDeliveryType(event){
      if (event["deliveryTypeCode"] != null && event["deliveryTypeCode"] != "" && event["deliveryTypeCode"] !== undefined) {
        this.model.pfcDeliveryTypeCode = event["deliveryTypeCode"].toUpperCase();
      } else {
        this.model.pfcDeliveryTypeCode = "ALL";
      }
      this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}');
  }

  changeCbMaintenanceCommodity(event){
      if (event["commodityId"] != null && event["commodityId"] != "" && event["commodityId"] !== undefined) {
        this.model.pfcCommodityId = event["commodityId"].toUpperCase();
      } else {
        this.model.pfcCommodityId = "";
      }

      //revert to search by name
      this.cbMaintenanceCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}');
  }

  infoGridPrepaid(event){
  }

  infoGridCollect(event){
  }

  gridEventCollect(event){
    // console.log("Click Event " + event);
      switch (event.split(".")[0]) {
        case 'selected':
          break;
        case 'click' :
          var no = event.split(".")[1].split("-")[1];

          this.gridCollect.clearSelectedValues();
          // console.log("no => " + no)
          this.gridCollect.onSelect(true, Number.parseInt(no));

          break;
        default:
          let strEvent: string = event;
          if (strEvent != '') {
            let arr: string[] = strEvent.split('-');
            switch (arr[0]) {
              case 'edit':
                break;
              case 'dblClick':
                this.onDetailUpdate();
                break;
              default:
                break;
            }
          }
          break;
      }
  }

  changeEventRetrieveByRefNo(event){
      if (event.target.checked) {
        this.checkLock = true;
        this.model.pfcSerchByRefNo = "Y";
        this.lockEffectiveFrom = true;
        this.lockEffectiveTo = true;

        this.cookieService.setCookie("pfcSearchByRefNo", this.model.pfcSerchByRefNo);
      } else {
        this.checkLock = false;
        this.model.pfcSerchByRefNo = "N";
        this.model.pfcRecordId = "";
        this.lockEffectiveFrom = false;
        this.lockEffectiveTo = false;

        this.cookieService.setCookie("pfcSearchByRefNo", this.model.pfcSerchByRefNo);
      }
  }

  changeIsDeleted(event){
      if (event.target.checked) {
        this.model.pfcIsDeleted = 'Y'
      } else {
        this.model.pfcIsDeleted = 'N'
      }
  }

  changeIsCommissionable(event){
      if (event.target.checked) {
        this.model.pfcIsCommission = 'Y'
      } else {
        this.model.pfcIsCommission = 'N'
      }
  }

  changeEventPfcIsCommission(event){
      if (event.target.checked) {
        this.model.pfcIsCommission = 'Y'
      } else {
        this.model.pfcIsCommission = 'N'
      }
  }

  handleDoubleClick(dt: FreightManagement){
      // console.log('dt => ');
      // console.log(dt);

      if (this.modeForm == "freightFlagStatus") {
        this.modeForm = "freightMaintenance";
        this.pfcToolbar.label = "Freight Application";
        this.invisibleToolbarButtons = "retrieve,create";
        this.disableToolbarButtons = "copy";


        //store buffer id on cookies
        this.cookieService.setCookie("pfcSearchParam", JSON.stringify(this.model));
        //end store buffer cookies

        //re-init the model to reset any unwanted values
        this.model = new FreightManagement;

        //reset all combo and date picker
        this.cbMaintenanceFreightParty.setValue("");
        this.cbMaintenanceShipper.setValue("");
        this.cbMaintenancePlaceOfReceipt.setValue("");
        this.cbMaintenancePortOfLoad.setValue("");
        this.cbMaintenancePortOfDischarge.setValue("");
        this.cbMaintenancePlaceOfDelivery.setValue("");
        this.cbMaintenancePOT1.setValue("");
        this.cbMaintenancePOT2.setValue("");
        this.cbMaintenancePOT3.setValue("");
        this.cbMaintenanceFinalDestination.setValue("");
        this.cbMaintenanceCommodity.setValue("");
        this.cbMaintenanceAdhocVessel.setValue("");
        this.cbMaintenanceAdhocVoyage.setValue("");
        this.dateMaintenanceEffectiveTo.setValue("");

        //reset effective to
        let currentDate = new Date();
        let theDate = this.genericUtil.pad2(currentDate.getFullYear()) + '-' + this.genericUtil.pad2(currentDate.getMonth() + 1) + '-' + this.genericUtil.pad2(currentDate.getDate());
        this.dateMaintenanceEffectiveFrom.setValue(theDate);

        // console.log("** 0. Check Record Id **");
        if (dt.pfcRecordId !== null && dt.pfcRecordId !== "" && dt.pfcRecordId !== undefined) {
          this.model.pfcRecordId = dt.pfcRecordId;
        }

        // console.log("** 1. Check Freight Party **");
        if (dt.pfcFreightPartyId !== null && dt.pfcFreightPartyId !== "" && dt.pfcFreightPartyId !== undefined) {
          this.cbMaintenanceFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
          this.cbMaintenanceFreightParty.setValue(dt.pfcFreightPartyId);
        }

        // console.log("** 1.1. Check Freight Party **");
        if (dt.pfcShipperId !== null && dt.pfcShipperId !== "" && dt.pfcShipperId !== undefined) {
          this.cbMaintenanceShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerId={query}');
          this.cbMaintenanceShipper.setValue(dt.pfcShipperId);
        }

        // console.log("** 2. Check Place of Receipt **");
        if (dt.pfcPlaceOfReceiptCode !== null && dt.pfcPlaceOfReceiptCode !== "" && dt.pfcPlaceOfReceiptCode !== undefined) {
          this.cbMaintenancePlaceOfReceipt.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePlaceOfReceipt.setValue(dt.pfcPlaceOfReceiptCode);
        }

        // console.log("** 3. Check Port of Loading **");
        if (dt.pfcLoadPortCode !== null && dt.pfcLoadPortCode !== "" && dt.pfcLoadPortCode !== undefined) {
          this.cbMaintenancePortOfLoad.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePortOfLoad.setValue(dt.pfcLoadPortCode);
        }

        // console.log("** 4. Check Port of Discharge **");
        if (dt.pfcDischargePortCode !== null && dt.pfcDischargePortCode !== "" && dt.pfcDischargePortCode !== undefined) {
          this.cbMaintenancePortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePortOfDischarge.setValue(dt.pfcDischargePortCode);
        }
        //5. Check Place of Delivery
        if (dt.pfcPlaceOfDeliveryCode !== null && dt.pfcPlaceOfDeliveryCode !== "" && dt.pfcPlaceOfDeliveryCode !== undefined) {
          this.cbMaintenancePlaceOfDelivery.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePlaceOfDelivery.setValue(dt.pfcPlaceOfDeliveryCode);
        }
        //6. Check Port of Transshipment 1
        if (dt.pfcPortOfTransshipment1Code !== null && dt.pfcPortOfTransshipment1Code !== "" && dt.pfcPortOfTransshipment1Code !== undefined) {
          this.cbMaintenancePOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePOT1.setValue(dt.pfcPortOfTransshipment1Code);
        }

        //Check Port of Transshipment 2
        if (dt.pfcPortOfTransshipment2Code !== null && dt.pfcPortOfTransshipment2Code !== "" && dt.pfcPortOfTransshipment2Code !== undefined) {
          this.cbMaintenancePOT2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePOT2.setValue(dt.pfcPortOfTransshipment2Code);
        }

        //Check Port of Transshipment 3
        if (dt.pfcPortOfTransshipment3Code !== null && dt.pfcPortOfTransshipment3Code !== "" && dt.pfcPortOfTransshipment3Code !== undefined) {
          this.cbMaintenancePOT3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenancePOT3.setValue(dt.pfcPortOfTransshipment3Code);
        }

        //7. Check Final Destination
        if (dt.pfcFinalDestinationCode !== null && dt.pfcFinalDestinationCode !== "" && dt.pfcFinalDestinationCode !== undefined) {
          this.cbMaintenanceFinalDestination.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          this.cbMaintenanceFinalDestination.setValue(dt.pfcFinalDestinationCode);
        }
        //8. Check Receipt Type
        // console.log("dt.pfcReceiptTypeCode --> " + dt.pfcReceiptTypeCode);
        if (dt.pfcReceiptTypeCode !== null && dt.pfcReceiptTypeCode !== "" && dt.pfcReceiptTypeCode !== undefined) {
          this.cbMaintenanceReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
          this.cbMaintenanceReceiptType.setValue(dt.pfcReceiptTypeCode);
        }
        //9. Check Delivery Type
        // console.log("dt.pfcDeliveryTypeCode --> " + dt.pfcDeliveryTypeCode);
        if (dt.pfcDeliveryTypeCode !== null && dt.pfcDeliveryTypeCode !== "" && dt.pfcDeliveryTypeCode !== undefined) {
          this.cbMaintenanceDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
          this.cbMaintenanceDeliveryType.setValue(dt.pfcDeliveryTypeCode);
        }
        //10. Check DG Class
        if (dt.pfcDgClass !== null && dt.pfcDgClass !== "" && dt.pfcDgClass !== undefined) {
          this.model.pfcDgClass = dt.pfcDgClass;
        }
        //11. Check Shipment Type
        if (dt.pfcShipmentType !== null && dt.pfcShipmentType !== "" && dt.pfcShipmentType !== undefined) {
          this.model.pfcShipmentType = dt.pfcShipmentType;
        }
        //12. Check Container Status
        if (dt.pfcContainerStatus !== null && dt.pfcContainerStatus !== "" && dt.pfcContainerStatus !== undefined) {
          this.model.pfcContainerStatus = dt.pfcContainerStatus;
        }
        //13. Check IHC
        if (dt.pfcIhc !== null && dt.pfcIhc !== "" && dt.pfcIhc !== undefined) {
          this.model.pfcIhc = dt.pfcIhc;
        }
        //14. Check Adhoc Vessel
        if (dt.pfcVesselId !== null && dt.pfcVesselId !== "" && dt.pfcVesselId !== undefined) {
          var self = this;
          this.cbMaintenanceAdhocVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
          this.cbMaintenanceAdhocVessel.setValue(dt.pfcVesselId, function (callbackVessel) {
            self.model.pfcVesselId = dt.pfcVesselId;

            //15. Check Adhoc Voyage
            if (dt.pfcVoyage !== null && dt.pfcVoyage !== "" && dt.pfcVoyage !== undefined) {
              //self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ self.model.pfcVesselId+"/"+ "O" +"/{query}/" + self.cookieService.getDefaultLocationCode());
              self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + dt.pfcVesselId + "/O/voyage={query}");
              self.cbMaintenanceAdhocVoyage.setValue(dt.pfcVoyage, function (callbackVoyage) {
                self.model.pfcVoyage = dt.pfcVoyage;
                self.cbMaintenanceAdhocVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + dt.pfcVesselId + "/O/voyage_like={query}");
                self.getEtaEtdAtaAtd(self.model.pfcVesselId, self.model.pfcVoyage, "O", self.cookieService.getDefaultLocationCode());

              });
            }

            self.cbMaintenanceAdhocVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/VesselName={query}');
          });
        }

        //16. Check Container Status
        if (dt.pfcContainerStatus !== null && dt.pfcContainerStatus !== "" && dt.pfcContainerStatus !== undefined) {
          this.model.pfcContainerStatus = dt.pfcContainerStatus;
        }
        //17. Check Weight Class
        if (dt.pfcWeightClass !== null && dt.pfcWeightClass !== "" && dt.pfcWeightClass !== undefined) {
          this.model.pfcWeightClass = dt.pfcWeightClass;
        }

        //18. Check Commodity
        if (dt.pfcCommodityId !== null && dt.pfcCommodityId !== "" && dt.pfcCommodityId !== undefined) {
          this.cbMaintenanceCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityId={query}');
          this.cbMaintenanceCommodity.setValue(dt.pfcCommodityId);
        }
        // console.log("** 19. Check Container Ownership **");
        if (dt.pfcContainerOwnership !== null && dt.pfcContainerOwnership !== "" && dt.pfcContainerOwnership !== undefined) {
          this.model.pfcContainerOwnership = dt.pfcContainerOwnership;

          if (dt.pfcContainerOwnership == "COC" || dt.pfcContainerOwnership == "COCTS") {
            this.model.pfcContainerOwnershipPrefix = "C";
          } else if (dt.pfcContainerOwnership == "SOC" || dt.pfcContainerOwnership == "SOCTS") {
            this.model.pfcContainerOwnershipPrefix = "S";
          } else if (dt.pfcContainerOwnership == "SLOT") {
            this.model.pfcContainerOwnershipPrefix = "SL";
          }
        }

        //20. Check Prospects
        /*
         if(dt.pfcProspectD20 !== null && dt.pfcProspectD20 !== "" && dt.pfcProspectD20 !== undefined){this.model.pfcProspectD20=dt.pfcProspectD20;}
         if(dt.pfcProspectD40 !== null && dt.pfcProspectD40 !== "" && dt.pfcProspectD40 !== undefined){this.model.pfcProspectD40=dt.pfcProspectD40;}
         if(dt.pfcProspectD45 !== null && dt.pfcProspectD45 !== "" && dt.pfcProspectD45 !== undefined){this.model.pfcProspectD45=dt.pfcProspectD45;}
         if(dt.pfcProspectH20 !== null && dt.pfcProspectH20 !== "" && dt.pfcProspectH20 !== undefined){this.model.pfcProspectH20=dt.pfcProspectH20;}
         if(dt.pfcProspectH40 !== null && dt.pfcProspectH40 !== "" && dt.pfcProspectH40 !== undefined){this.model.pfcProspectH40=dt.pfcProspectH40;}
         if(dt.pfcProspectH45 !== null && dt.pfcProspectH45 !== "" && dt.pfcProspectH45 !== undefined){this.model.pfcProspectH45=dt.pfcProspectH45;}
         */
        if (dt.pfcProspectD20 !== null && dt.pfcProspectD20 !== undefined) {
          this.model.pfcProspectD20 = dt.pfcProspectD20;
        }
        if (dt.pfcProspectD40 !== null && dt.pfcProspectD40 !== undefined) {
          this.model.pfcProspectD40 = dt.pfcProspectD40;
        }
        if (dt.pfcProspectD45 !== null && dt.pfcProspectD45 !== undefined) {
          this.model.pfcProspectD45 = dt.pfcProspectD45;
        }
        if (dt.pfcProspectH20 !== null && dt.pfcProspectH20 !== undefined) {
          this.model.pfcProspectH20 = dt.pfcProspectH20;
        }
        if (dt.pfcProspectH40 !== null && dt.pfcProspectH40 !== undefined) {
          this.model.pfcProspectH40 = dt.pfcProspectH40;
        }
        if (dt.pfcProspectH45 !== null && dt.pfcProspectH45 !== undefined) {
          this.model.pfcProspectH45 = dt.pfcProspectH45;
        }


        //21. Check for Detention
        if (dt.pfcDetention !== null && dt.pfcDetention !== "" && dt.pfcDetention !== undefined) {
          this.model.pfcDetention = dt.pfcDetention;
          if (dt.pfcDetention == "R") {
            this.model.pfcDetentionRequest = dt.pfcDetentionRequest;
          }
        }

        //22. Check for Demurrage
        if (dt.pfcDemurrage !== null && dt.pfcDemurrage !== "" && dt.pfcDemurrage !== undefined) {
          this.model.pfcDemurrage = dt.pfcDemurrage;
          if (dt.pfcDemurrage == "R") {
            this.model.pfcDemurrageRequest = dt.pfcDemurrageRequest;
          }
        }

      }

      //23. Creation Information
      if (dt.pfcUserCreated !== null && dt.pfcUserCreated !== "" && dt.pfcUserCreated !== undefined) {
        this.model.pfcUserCreated = dt.pfcUserCreated
      }
      if (dt.pfcDateCreated !== null && dt.pfcDateCreated !== "" && dt.pfcDateCreated !== undefined) {
        this.model.pfcDateCreated = dt.pfcDateCreated
      }

      //24. Update Information
      if (dt.pfcUserModified !== null && dt.pfcUserModified !== "" && dt.pfcUserModified !== undefined) {
        this.model.pfcUserModified = dt.pfcUserModified
      }
      if (dt.pfcDateModified !== null && dt.pfcDateModified !== "" && dt.pfcDateModified !== undefined) {
        this.model.pfcDateModified = dt.pfcDateModified
      }

      //24. Check Container Type
      if (dt.pfcContainerType !== null && dt.pfcContainerType !== "" && dt.pfcContainerType !== undefined) {
        this.model.pfcContainerType = dt.pfcContainerType;
      }

      //25. Check for Effective Date
      if (dt.pfcEffectiveFrom !== null && dt.pfcEffectiveFrom !== "" && dt.pfcEffectiveFrom !== undefined) {
        this.dateMaintenanceEffectiveFrom.setValue(dt.pfcEffectiveFrom);
        this.model.pfcEffectiveFrom = dt.pfcEffectiveFrom;
      }
      if (dt.pfcEffectiveTo !== null && dt.pfcEffectiveTo !== "" && dt.pfcEffectiveTo !== undefined) {
        this.dateMaintenanceEffectiveTo.setValue(dt.pfcEffectiveTo);
        this.model.pfcEffectiveTo = dt.pfcEffectiveTo;
      }

      this.model.pfcIsCommission = dt.pfcIsCommission;
      this.model.pfcIsDeleted = dt.pfcIsDeleted;
      this.model.pfcIsValid = dt.pfcIsValid;

      console.log("Principal Remarks : " + dt.pfcPrincipalRemarks);
      this.txtRemarksPrincipal.setValue(dt.pfcPrincipalRemarks);
      console.log("Agency Remarks : " + dt.pfcAgentRemarks)
      this.txtRemarksAgency.setValue(dt.pfcAgentRemarks);
     // this.txtRemarksPrincipal.setValue(this.model.pfcPrincipalRemarks);

    //AcctPIC
      // console.log("dt.pfcAcctPic -> " + dt.pfcAcctPic);
      if (dt.pfcAcctPic != null && dt.pfcAcctPic != "" && dt.pfcAcctPic !== undefined) {
        var self = this;
        this.cbAcctPic.setUrl(this.configService.config.BASE_API.toString() + '/MasterAccountPic/findAcctPIC');
        this.cbAcctPic.setValue(dt.pfcAcctPic);
        this.model.pfcAcctPic = dt.pfcAcctPic;
      }


      // console.log("** data retrieve **");
      this.model.pfcDetails = dt.pfcDetails;

      let modelKey = new FreightManagement;
      modelKey.pfcOfficeCode = dt.pfcOfficeCode;
      modelKey.pfcFreightPartyId = dt.pfcFreightPartyId;
      modelKey.pfcShipperId = dt.pfcShipperId;
      modelKey.pfcPlaceOfReceiptCode = dt.pfcPlaceOfReceiptCode;
      modelKey.pfcLoadPortCode = dt.pfcLoadPortCode;
      modelKey.pfcDischargePortCode = dt.pfcDischargePortCode;
      modelKey.pfcPlaceOfDeliveryCode = dt.pfcPlaceOfDeliveryCode;
      modelKey.pfcFinalDestinationCode = dt.pfcFinalDestinationCode;
      modelKey.pfcPortOfTransshipment1Code = dt.pfcPortOfTransshipment1Code;
      modelKey.pfcPortOfTransshipment2Code = dt.pfcPortOfTransshipment2Code;
      modelKey.pfcPortOfTransshipment3Code = dt.pfcPortOfTransshipment3Code;
      modelKey.pfcCommodityId = dt.pfcCommodityId;
      modelKey.pfcReceiptTypeCode = dt.pfcReceiptTypeCode;
      modelKey.pfcDeliveryTypeCode = dt.pfcDeliveryTypeCode;
      modelKey.pfcContainerOwnership = dt.pfcContainerOwnership;
      modelKey.pfcContainerStatus = dt.pfcContainerStatus;
      modelKey.pfcShipmentType = dt.pfcShipmentType;
      modelKey.pfcVesselId = dt.pfcVesselId;
      modelKey.pfcVoyage = dt.pfcVoyage;
      modelKey.pfcDetention = dt.pfcDetention;
      modelKey.pfcDetentionRequest = dt.pfcDetentionRequest;
      modelKey.pfcDemurrage = dt.pfcDemurrage;
      modelKey.pfcDemurrageRequest = dt.pfcDemurrageRequest;
      modelKey.pfcIhc = dt.pfcIhc;
      modelKey.pfcDgClass = dt.pfcDgClass;
      modelKey.pfcEffectiveFrom = dt.pfcEffectiveFrom;
      modelKey.pfcEffectiveTo = dt.pfcEffectiveTo;

      this.freightManagementKeyRetrieveExpiryOn = this.getFreightManagementKeyStringExpiryOn(modelKey);
      this.freightManagementKeyRetrieve = this.getFreightManagementKeyString(modelKey);

      this.freightManagementRetrieve = this.getFreightManagementString(dt);
      this.isValidRetrieve = dt.pfcIsValid;
      this.freightDetailsRetrieve = "";


      // console.log("** Load Detail **");

      this.containerTypeList = [];
      this.map = new Map(); //reset the map
      dt.pfcDetails.forEach(element => {
        this.freightDetailsRetrieve += this.getFreightDetailString(element);
        // console.log(element);

        if (this.map.get(element.pfcDetailContainerType) == undefined) {
          this.containerTypeList.push(element.pfcDetailContainerType);

          this.modelDetailList = [];
          this.modelDetailList.push(element);
          this.map.set(element.pfcDetailContainerType, this.modelDetailList);
        } else {
          this.map.get(element.pfcDetailContainerType).push(element);
        }

      });

      // console.log(this.map);
      // console.log(this.model);

      // console.log("** containerTypeList **");
      // console.log(this.containerTypeList);

      this.gridPrepaid.onClear();
      this.gridCollect.onClear();
      var detailList: any[] = this.map.get(this.model.pfcContainerType)
      // console.log(this.map)

      detailList.forEach(element => {

        // console.log(element)
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        switch (element.pfcDetailContainerType) {
          case "STD":
            element.pfcDetailContainerTypeName = "NORMAL";
            break;
          case "DRY":
            element.pfcDetailContainerTypeName = "REEFER DRY";
            break;
          case "REF":
            element.pfcDetailContainerTypeName = "REEFER";
            break;
          case "TNK":
            element.pfcDetailContainerTypeName = "TANK";
            break;
          case "OPT":
            element.pfcDetailContainerTypeName = "OPEN TOP";
            break;
          case "PLT":
            element.pfcDetailContainerTypeName = "PLATFORM";
            break;
          case "FLR":
            element.pfcDetailContainerTypeName = "FLAT RACK";
            break;
          case "UC":
            element.pfcDetailContainerTypeName = "UC";
            break;
          default:
            break;
        }
        x.pfcDetailContainerTypeName = element.pfcDetailContainerTypeName;
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        if(x.pfcDetailOogDoorOpen == "N"){
          x.pfcDetailOogDoorOpenName = "N/A";
        }else if(x.pfcDetailOogDoorOpen == "D"){
          x.pfcDetailOogDoorOpenName = "Detached";
        }else if(x.pfcDetailOogDoorOpen == "B"){
          x.pfcDetailOogDoorOpenName = "Tied Both";
        }else if(x.pfcDetailOogDoorOpen == "L"){
          x.pfcDetailOogDoorOpenName = "Tied Left";
        }else if(x.pfcDetailOogDoorOpen == "R"){
          x.pfcDetailOogDoorOpenName = "Tied Right";
        }
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

        if (x.pfcDetailPCTab == 'P') {
          this.gridPrepaid.listStore.store.push(x);
          this.gridPrepaid.loadData();
        }

        if (x.pfcDetailPCTab == 'C') {
          this.gridCollect.listStore.store.push(x);
          this.gridCollect.loadData();
        }

      });

      if(dt.pfcIsValid == 'Y'){
        this.disableToolbarButtons = "delete,copy";
      }else if(dt.pfcIsValid == 'N'){
        this.disableToolbarButtons = "copy";
      }

      this.model.disableInvalid = dt.disableInvalid;
      if(this.model.disableInvalid == undefined){
        this.model.disableInvalid = "false";
      }
      this.model.pfcAppStatus = dt.pfcAppStatus;
      this.model.pfcIsTransmitted = dt.pfcIsTransmitted;

      // console.log("this.model.disableInvalid : " + this.model.disableInvalid);
      // console.log("this.model.pfcAppStatus : " + this.model.pfcAppStatus);
      // console.log("this.model.pfcIsTransmitted : " + this.model.pfcIsTransmitted);
      // console.log("this.lock => " + this.lock);


      switch(dt.pfcAppStatus){
        case "A":{
          this.appStatusLabel = "   ---   APPROVED   ---";
          this.lock = false;
          this.isAfterSave = false;
          break;
        }
        case "R":{
          this.appStatusLabel = "   ---   REJECTED   ---";
          this.lock = false;
          this.isAfterSave = false;
          break;
        }
        default:{
          if(dt.pfcIsTransmitted == 'Y'){
            this.appStatusLabel = "   ---   PENDING APPROVAL   ---";
            this.lock = true;
            this.isAfterSave = false;
            this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
            this.message('information', 'Information', "There is an existing record under 'PENDING' status and already transmitted. Please wait for Principal's action.", 'okonly', {ok: ""});

          }else{
            if(dt.pfcIsDeleted == 'N'){
              this.appStatusLabel = "   ---   PENDING TRANSMISSION   ---";
              this.lock = false;
              this.isAfterSave = false;
            }else if(dt.pfcIsDeleted == 'Y'){
              this.disableToolbarButtons = "nct,save,copy,delete,print,cancel";
              this.appStatusLabel = "   ---   PENDING UNTRANSMITTED (DELETED)   ---";
              this.lock = true;
              this.isAfterSave = false;
            }
          }
          break;
        }
      }

      if(dt.pfcIsValid == 'N'){
        this.lock = true;
      }

      if(dt.pfcIsDeleted == 'Y'){
        this.disableToolbarButtons = this.disableToolbarButtons + ",nct,save,cancel,copy,delete,print"
      }

      window.scrollTo(0,0);

  }

  //unused method
  changeCbPrepaidStatus(event){

  }

  changeCbCollectStatus(event){
  }

  getFreightManagementKeyString(dt:FreightManagement):string {
      //return dt.pfcOfficeCode + "/" +
      return dt.pfcFreightPartyId + "/" +
        //dt.pfcShipperId + "/" +
        dt.pfcPlaceOfReceiptCode + "/" +
        dt.pfcLoadPortCode + "/" +
        dt.pfcDischargePortCode + "/" +
        dt.pfcPlaceOfDeliveryCode + "/" +
        dt.pfcFinalDestinationCode + "/" +
        dt.pfcPortOfTransshipment1Code + "/" +
        dt.pfcPortOfTransshipment2Code + "/" +
        dt.pfcPortOfTransshipment3Code + "/" +
        dt.pfcCommodityId + "/" +
        dt.pfcReceiptTypeCode + "/" +
        dt.pfcDeliveryTypeCode + "/" +
        dt.pfcEffectiveFrom + "/" +
        //dt.pfcEffectiveTo + "/" +
        dt.pfcContainerOwnership + "/" +
        dt.pfcContainerStatus + "/" +
        dt.pfcShipmentType + "/" +
        dt.pfcVesselId + "/" +
        dt.pfcVoyage + "/" +
        dt.pfcDetention + "/" +
        dt.pfcDetentionRequest + "/" +
        dt.pfcDemurrage + "/" +
        dt.pfcDemurrageRequest + "/" +
        dt.pfcIhc + "/" +
        dt.pfcDgClass;

  }

  getFreightManagementKeyStringExpiryOn(dt:FreightManagement):string {
    return dt.pfcEffectiveTo;
  }

  getFreightManagementString(dt: FreightManagement): string {
      return dt.pfcOfficeCode + "/" +
        dt.pfcRecordId + "/" +
        dt.pfcFreightPartyId + "/" +
        //dt.pfcShipperId + "/" +
        dt.pfcPlaceOfReceiptCode + "/" +
        dt.pfcLoadPortCode + "/" +
        dt.pfcDischargePortCode + "/" +
        dt.pfcPlaceOfDeliveryCode + "/" +
        dt.pfcFinalDestinationCode + "/" +
        dt.pfcPortOfTransshipment1Code + "/" +
        dt.pfcPortOfTransshipment2Code + "/" +
        dt.pfcPortOfTransshipment3Code + "/" +
        //dt.pfcContainerType + "/" +
        dt.pfcCommodityId + "/" +
        dt.pfcReceiptTypeCode + "/" +
        dt.pfcDeliveryTypeCode + "/" +
        dt.pfcEffectiveFrom + "/" +
        dt.pfcEffectiveTo + "/" +
        dt.pfcContainerOwnership + "/" +
        dt.pfcContainerStatus + "/" +
        dt.pfcWeightClass + "/" +
        dt.pfcUnno + "/" +
        dt.pfcImo + "/" +
        dt.pfcFlashPoint + "/" +
        dt.pfcShipmentType + "/" +
        dt.pfcVesselId + "/" +
        dt.pfcVoyage + "/" +
        dt.pfcDetention + "/" +
        dt.pfcDetentionRequest + "/" +
        dt.pfcDemurrage + "/" +
        dt.pfcDemurrageRequest + "/" +
        dt.pfcIhc + "/" +
        dt.pfcDgClass + "/" +
        dt.pfcPrincipalRemarks + "/" +
        dt.pfcAgentRemarks + "/" +
        dt.pfcCompetitorDataId + "/" +
        dt.pfcAppStatus + "/" +
        dt.pfcAgencyId + "/" +
        dt.pfcUserCreated + "/" +
        dt.pfcDateCreated + "/" +
        //dt.pfcUserModified + "/" +
        //dt.pfcDateModified + "/" +
        //dt.pfcUserRequested + "/" +
        //dt.pfcDateRequested + "/" +
        //dt.pfcUserApproved + "/" +
        //dt.pfcDateApproved + "/" +
        //dt.pfcIsTransmitted + "/" +
        //dt.pfcUserTransmitted + "/" +
        //dt.pfcDateTransmitted + "/" +
        dt.pfcPORTerminalCode + "/" +
        dt.pfcPOLTerminalCode + "/" +
        dt.pfcPODTerminalCode + "/" +
        dt.pfcPODLTerminalCode + "/" +
        dt.pfcPOT1TerminalCode + "/" +
        dt.pfcPOT2TerminalCode + "/" +
        dt.pfcPOT3TerminalCode + "/" +
        dt.pfcFDESTTerminalCode + "/" +
        dt.pfcCustomColumn + "/" +
        dt.pfcProspectD20 + "/" +
        dt.pfcProspectD40 + "/" +
        dt.pfcProspectD45 + "/" +
        dt.pfcProspectH20 + "/" +
        dt.pfcProspectH40 + "/" +
        dt.pfcProspectH45 + "/" +
        dt.pfcIsValid + "/" +
        dt.pfcUserInvalid + "/" +
        dt.pfcDateInvalid + "/" +
        dt.pfcIsDeleted + "/" +
        dt.pfcUserDeleted + "/" +
        dt.pfcDateDeleted + "/" +
        dt.pfcReasonDeleted + "/" +
        dt.pfcIsCommission + "/" +
        dt.pfcAcctPic;

  }

  getFreightDetailString(dt:FreightDetail): string {

      return dt.pfcDetailSeqNo + "/" +
        dt.pfcDetailStatus + "/" +
        dt.pfcDetailContainerType + "/" +
        dt.pfcDetailChargeCode + "/" +
        dt.pfcDetailChargeName + "/" +
        dt.pfcDetailCurrency + "/" +
        dt.pfcDetailPC + "/" +
        dt.pfcDetailPlaceOfPayment + "/" +
        dt.pfcDetailPlaceOfPaymentName + "/" +
        dt.pfcDetailPayer + "/" +
        dt.pfcDetailPayerName + "/" +
        dt.pfcDetailD20 + "/" +
        dt.pfcDetailD40 + "/" +
        dt.pfcDetailD45 + "/" +
        dt.pfcDetailH20 + "/" +
        dt.pfcDetailH40 + "/" +
        dt.pfcDetailH45 + "/" +
        dt.pfcDetailM3 + "/" +
        dt.pfcDetailKT + "/" +
        dt.pfcDetailRT + "/" +
        dt.pfcDetailMinCharge + "/" +
        dt.pfcDetailKillSlot + "/" +
        dt.pfcDetailFAIGroup + "/" +
        dt.pfcDetailOogOH + "/" +
        dt.pfcDetailOogOWL + "/" +
        dt.pfcDetailOogOWR + "/" +
        dt.pfcDetailOogOLF + "/" +
        dt.pfcDetailOogOLB + "/" +
        dt.pfcDetailOogDoorOpen + "/" +
        dt.pfcDetailOogUcH + "/" +
        dt.pfcDetailOogUcL + "/" +
        dt.pfcDetailOogUcW + "/" +
        //dt.pfcDetailTotalChargeValue + "/" +
        //dt.pfcDetailCopyTab + "/" +
        dt.pfcDetailPCTab;

    }

    getFreightFAIString(dt:FreightDetail): string {

      return   dt.pfcDetailCurrency + "/" +
        dt.pfcDetailPC + "/" +
        dt.pfcDetailPlaceOfPayment + "/" +
        dt.pfcDetailPayer + "/" +
        dt.pfcDetailOogDoorOpen + "/" +
        dt.pfcDetailOogUcH + "/" +
        dt.pfcDetailOogUcL + "/" +
        dt.pfcDetailOogUcW + "/" +
        dt.pfcDetailOogOH + "/" +
        dt.pfcDetailOogOWL + "/" +
        dt.pfcDetailOogOWR + "/" +
        dt.pfcDetailOogOLF + "/" +
        dt.pfcDetailOogOLB + "/" +
        dt.pfcDetailContainerType + "/" +
        dt.pfcDetailPCTab;
    }

    Check_MapSize(){
      if (this.map.size == 0) {
        this.message('information', 'Information', 'There is no container type selected, no freight will be created.', 'okonly', {ok: "this.genericUtil.hideLoader();"});
        //this.dialogMultiple.current = undefined;
        this.gridPrepaid.onClear();
        this.gridCollect.onClear();
        //return;
      }
    }

    ClearRecordId(){
      this.model.pfcRecordId = "";
    }

    UpdateMap(){
       console.log('** update map **');
      this.dialogMultiple.clear();
      var i: number = 1;
      this.map.forEach((value: any[], key: string) => {
        if(value.length > 0){
          var ct: string
          switch (key) {
            case "TNK":
              ct = 'TANK';
              break;
            case "OPT":
              ct = 'OPEN TOP';
              break;
            case "FLR":
              ct = 'FLAT RACK';
              break;
            case "PLT":
              ct = 'PLATFORM';
              break;
            case "REF":
              ct = 'REEFER';
              break;
            case "DRY":
              ct = 'REEFER DRY';
              break;
            case "UC":
              ct = 'UC';
              break;
            case "STD":
              ct = 'NORMAL';
              break;
            default:
              ct = 'NORMAL';
              break;
          }

          var mp: any = {
            no: i++,
            icon: "information",
            header: "information",
            content: 'Do you want to create freight for ' + ct + ' Container ?',
            buttons: 'yesno',
            event: {yes: '', no: 'this.map.delete("' + key + '");'}
          };
          this.dialogMultiple.addMessage(mp);
        }
      });
      this.dialogMultiple.show();
  }



  changeInvalid(event){
    // console.log("** change invalid **");
      if (event.target.checked) {
        this.model.pfcIsValid = 'N';
        this.model.pfcDateInvalid = this.genericUtil.getPSIDate();
        this.model.pfcUserInvalid = this.cookieService.getName().toUpperCase();
      } else {
        this.model.pfcIsValid = 'Y';
        this.model.pfcDateInvalid = '';
        this.model.pfcUserInvalid = '';
      }
      // console.log(this.model.pfcIsValid);
  }

  closeDialog(id:string){
    this.genericUtil.closeDialog(id);
    this.gridPrepaid.loadData();
    this.gridCollect.loadData();
  }

  populateData(){
    this.model.pfcDetails = [];

    this.map.forEach((value: FreightDetail[], key: string) => {
      // console.log(key);

      value.forEach(element => {
        var x: FreightDetail = new FreightDetail();
        x.pfcDetailChargeCode = element.pfcDetailChargeCode;
        x.pfcDetailChargeName = element.pfcDetailChargeName;
        x.pfcDetailContainerType = element.pfcDetailContainerType;
        x.pfcDetailCopyTab = element.pfcDetailCopyTab;
        x.pfcDetailCurrency = element.pfcDetailCurrency;
        x.pfcDetailD20 = element.pfcDetailD20;
        x.pfcDetailD40 = element.pfcDetailD40;
        x.pfcDetailD45 = element.pfcDetailD45;
        x.pfcDetailFAIGroup = element.pfcDetailFAIGroup;
        x.pfcDetailH20 = element.pfcDetailH20;
        x.pfcDetailH40 = element.pfcDetailH40;
        x.pfcDetailH45 = element.pfcDetailH45;
        x.pfcDetailKT = element.pfcDetailKT;
        x.pfcDetailKillSlot = element.pfcDetailKillSlot;
        x.pfcDetailM3 = element.pfcDetailM3;
        x.pfcDetailMinCharge = element.pfcDetailMinCharge;
        x.pfcDetailOogDoorOpen = element.pfcDetailOogDoorOpen;
        x.pfcDetailOogOH = element.pfcDetailOogOH;
        x.pfcDetailOogOLB = element.pfcDetailOogOLB;
        x.pfcDetailOogOLF = element.pfcDetailOogOLF;
        x.pfcDetailOogOWL = element.pfcDetailOogOWL;
        x.pfcDetailOogOWR = element.pfcDetailOogOWR;
        x.pfcDetailOogUcH = element.pfcDetailOogUcH;
        x.pfcDetailOogUcL = element.pfcDetailOogUcL;
        x.pfcDetailOogUcW = element.pfcDetailOogUcW;
        x.pfcDetailPC = element.pfcDetailPC;
        x.pfcDetailPCTab = element.pfcDetailPCTab;
        x.pfcDetailPayer = element.pfcDetailPayer;
        x.pfcDetailPayerName = element.pfcDetailPayerName;
        x.pfcDetailPlaceOfPayment = element.pfcDetailPlaceOfPayment;
        x.pfcDetailPlaceOfPaymentName = element.pfcDetailPlaceOfPaymentName;
        x.pfcDetailRT = element.pfcDetailRT;
        x.pfcDetailSeqNo = element.pfcDetailSeqNo;
        x.pfcDetailStatus = element.pfcDetailStatus;
        x.pfcDetailTotalChargeValue = element.pfcDetailTotalChargeValue;

        this.model.pfcDetails.push(x);
      });
    });

  }


  changeEventCbAppStatus(event){
    if(event["appStatusCode"] != null && event["appStatusCode"] != "" && event["appStatusCode"] != undefined){
      this.model.pfcAppStatus = event["appStatusCode"].toUpperCase();
    }else{
      this.model.pfcAppStatus="ALL";
    }
  }

}
