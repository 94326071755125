<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li
        [class.hidden]="
          model['error-invNo'] == null ||
          model['error-invNo'] == ''
        "
      >
        {{ model["error-invNo"] }}
      </li>
      <li
        [class.hidden]="
          model['error-varReason'] == null ||
          model['error-varReason'] == ''
        "
      >
        {{ model["error-varReason"] }}
      </li>
      <li
        [class.hidden]="
          model['error-varRequest'] == null ||
          model['error-varRequest'] == ''
        "
      >
        {{ model["error-varRequest"] }}
      </li>
    </ul>
  </div>

 <form class="ui form">
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <fieldset style="border: 1px solid lightgray">
        <button class="tiny ui positive button" (click) = "addNewRow();" >+</button>
        <button class="tiny ui negative button" (click) = "removeRow();">-</button>	    
        <input type="hidden" name="gridCount" value="0">   
        <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
    </fieldset>
 </form>
</form>
<footer-plugin [info]="info"></footer-plugin>
