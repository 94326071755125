import { Injectable } from '@angular/core';
import { ConfigService, CookieService, GenericService } from 'sibego-ui-library';

@Injectable({
  providedIn: 'root'
})
export class AllocationService {
  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.193.245:13000';
  userID = this.cookieService.getName();
  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  constructor(private configService: ConfigService, private cookieService: CookieService, private genericService: GenericService) { }

  getAllocation(model) {
    const url =
      this.BASE_URL +
      `/PortPairAllocation/retrieve?vesselId=${model.vesselId}&voyage=${model.voyage}&bound=${model.bound}&pot=${model.potCode}&containerOwnership=${model.containerOwnership}&officeCode=${this.officeCode}`
    return this.genericService.GET(url);
  }

  getConnectingVessel(model) {
    const url =
      this.BASE_URL +
      `/PortPairAllocation/summary?periodFrom=${model.periodFrom}&periodTo=${model.periodTo}&pot=${model.potCode}&terminal=${model.terminalCode}`
    return this.genericService.GET(url);
  }

  postInsert(modelDetail) {
    const url = this.BASE_URL + `/PortPairAllocation/prepareInsertedData?tslLoadPortCode=${modelDetail.polCode}&tslDischargePortCode=${modelDetail.podCode}&tslAllocatedTeus=${modelDetail.alloted}&tslCreatedUserid=${this.userID.toUpperCase()}&tsPotCode=${modelDetail.bPot1Code}&tslConnectingVesselId=${modelDetail.vesselId}&tslConnectingVesselVoyage=${modelDetail.voyage}`
    return this.genericService.GET(url)
  }

  postCopy(model) {
    const url = this.BASE_URL + `/PortPairAllocation/findByVesselIDVoy/${model.vesselId}/${model.voyage}/${model.newVesselId}/${model.newVoyage}/O/${model.bPot1Code}`
    return this.genericService.GET(url)
  }

  deleteAllocation(data) {
    const url = this.BASE_URL + '/PortPairAllocation/delete';
    return this.genericService.POST(url, data);
  }

  saveAllocation(body) {
    const url = this.BASE_URL + '/PortPairAllocation/save';
    return this.genericService.POST(url, body);
  }
}
