<!--
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
-->

<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == '' || model['error-blOceanVesselCode'] == undefined ">
          {{model['error-blOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == '' || model['error-blOceanVesselVoyage'] == undefined ">
          {{model['error-blOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == '' || model['error-blOceanVesselBound'] == undefined ">
          {{model['error-blOceanVesselBound']}}
        </li>
        <li [class.hidden]="model['error-blNoValidator'] == null || model['error-blNoValidator'] == ''">
          {{model['error-blNoValidator']}}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <div class="field">
        <div class="field" style="margin-top:10px;">
          <div class="ui radio checkbox" >
            <input type="radio"  name="rdFreightMatchBy" [checked]="radioFreightMatchByVessel == 'Y'" (change)="changeEventFreightMatchByVessel($event)" value="vessel">
            <label>Vessel Wise</label>
          </div>
        </div>
      </div>



      <div class="field">
        <div class="three fields">


          <div class="field">

            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%">


              <div class="four wide fields">

                <div class="required two field" style="width:100%" [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" [disableCombo]="radioFreightMatchByVessel == 'N'" style="width:100%;"></combo-plugin>
                </div>

                <div class="required one field" style="width:70%" [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" [disableCombo]="radioFreightMatchByVessel == 'N'" style="width:100%;"></combo-plugin>
                </div>

                <div class="required one field" style="width:45%" [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''">
                  <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" [disabled]="radioFreightMatchByVessel == 'N'" >
                </div
                >
              </div>

            </fieldset>

          </div>

          <div class="field" style="width:20%;">
            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%; padding-top:35px;" [disabled]="radioFreightMatchByVessel == 'N'? true:false">

              <div class="three fields">
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'ALL'" (change)="changeEventContainerOwnership($event)" value="ALL" >
                    <label>ALL</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'SOC'" (change)="changeEventContainerOwnership($event)" value="SOC">
                    <label>SOC</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'COC'" (change)="changeEventContainerOwnership($event)" value="COC">
                    <label>COC</label>
                  </div>
                </div>
                <div class="field" style="margin-top:10px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdContainerOwnership" [checked]="radioContainerOwnership == 'SLOT'" (change)="changeEventContainerOwnership($event)" value="SLOT">
                    <label>SLOT</label>
                  </div>
                </div>
              </div>

            </fieldset>
          </div>


        </div>
      </div>

      <div class="field">
        <div class="field" style="margin-top:10px;">
          <div class="ui radio checkbox" >
            <input type="radio"  name="rdFreightMatchBy" [checked]="radioFreightMatchByVessel == 'N'" (change)="changeEventFreightMatchByBL($event)" value="bookingNumber">
            <label>B/L Wise</label>
          </div>
        </div>
      </div>

      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <div class="field">
            <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEventByBL($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>
        </fieldset>
      </div>

    </form>

  </div>  <!-- end div container -->
</div>

<div id="msgDialog"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <table border="0"><tr>
        <td width="15%" style="text-align: center">
          <i class="info circle huge icon"></i>
        </td>
        <td style="font-size: 14px">
          <div [innerHTML]="msg | safe: 'html'"></div>
        </td>
      </tr></table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog()" >Ok</button>
    </div>
  </form>
</div>

<div id="msgDialogDuplicate"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <table border="0"><tr>
        <td width="15%" style="text-align: center">
          <i class="info circle huge icon"></i>
        </td>
        <td style="font-size: 14px">
          <div [innerHTML]="msg | safe: 'html'"></div>
        </td>
      </tr></table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialogDuplicate()" >Ok</button>
    </div>
  </form>
</div>

<div id="msgDialogVessels"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <table border="0"><tr>
        <td width="15%" style="text-align: center">
          <i class="info circle huge icon"></i>
        </td>
        <td style="font-size: 14px">
          <div [innerHTML]="msg | safe: 'html'"></div>
        </td>
      </tr></table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialogVessels()" >Ok</button>
    </div>
  </form>
</div>

<!--
<div id="progressDialog"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> 
    <div class="field" style="margin-bottom: 5px;">
      <div class="ui indicating progress" data-value="0" data-total="100" id="recap-progress-bar">
        <div class="bar"></div>
        <div class="label">{{progressBarValue}}% Completed {{progressBarMessage}}</div>
      </div>
    </div>
  </form>
</div>
-->

<div id="blFMDialog"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <table border="0"><tr>
        <td width="20%" style="text-align: center;">
          <i class="info circle huge icon"></i>
        </td>
        <td>
          <div [innerHTML]="blFMDialogMessage">
          </div>
        </td>
      </tr></table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="blFMDialogOkButton();" >OK</button>
    </div>
  </form>
</div>


<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<footer-plugin [info]="info"></footer-plugin>
