import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ANALYZE_FOR_ENTRY_COMPONENTS,
} from "@angular/core";
import {
  BookingTransfer,
  Booking,
  BookingDetail,
  specialCharUtil,
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DialogMultiplePluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { Promise } from "q";
import FormValidation from "../../utils/formValidation";
import { finalize } from "rxjs/operators";

declare var $: any;

export class BookingTransferPayload {
  destinationVesselId: string = "";
  destinationVoyage: string = "";
  destinationBound: string = "";
  payloads: Payload[] = [];

  constructor() {}
}

export class Payload {
  bPrefix: string = "";
  bNo: string = "";

  constructor() {}
}

@Component({
  selector: "app-transaction-booking-transfer-page",
  templateUrl: "./transaction-booking-transfer-page.component.html",
  styleUrls: ["./transaction-booking-transfer-page.component.css"],
})
export class TransactionBoookingTransferPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVesselOrigin") cbVesselOrigin: ComboPluginComponent;
  @ViewChild("cbVesselDestination") cbVesselDestination: ComboPluginComponent;
  @ViewChild("cbVoyageOrigin") cbVoyageOrigin: ComboPluginComponent;
  @ViewChild("cbVoyageDestination") cbVoyageDestination: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("cbPOT") cbPOT: ComboPluginComponent;
  @ViewChild("cbPOD2") cbPOD2: ComboPluginComponent;
  @ViewChild("cbPOT2") cbPOT2: ComboPluginComponent;
  @ViewChild("gridBookingTransfer") gridBookingTransfer: GridPluginComponent;
  @ViewChild("gridBookingTransferByNo")
  gridBookingTransferByNo: GridPluginComponent;
  @ViewChild("gridBookingTransferAllocation")
  gridBookingTransferAllocation: GridPluginComponent;
  @ViewChild("dialogMultiple") dialogMultiple: DialogMultiplePluginComponent;

  dialog: any;

  // * Special Character
  sCharUtil = new specialCharUtil();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  loading = false;

  modeToolbar = false;
  modeForm = "bookingTransferDefault";

  /* Parameter settings */
  settingToolbar;
  settingVesselOrigin;
  settingVoyageOrigin;
  settingVesselDestination;
  settingVoyageDestination;
  settingPOD;
  settingPOT;
  settingPOD2;
  settingPOT2;
  settingGridBookingTransfer;
  settingGridBookingTransferByNo;
  settingGridBookingTransferAllocation;

  defaultBooked2: String = "";

  //common variables
  radioTransferByVessel: string = "N";
  radioTransferByPOT: string = "N";
  radioTransferByBNo: string = "N";
  radioTransferByPOD: string = "N";
  radioTransferByPOD2: string = "N";
  radioTransferByPOT2: string = "N";
  transferToLock: boolean = true;
  transferFromLock: boolean = true;
  addRemoveGrid: string = "none";
  potLock: boolean = true;
  podLock: boolean = true;
  potLock2: boolean = true;
  podLock2: boolean = true;

  availableTeus: number = 0;
  availableTeusTemp: number = 0;
  requiredTeus: number = 0;
  ckFlag: number = 0;
  ckPsa: number = 0;
  ckTrfAll: number = 0;
  pdFlag: number = 0;
  valFlag: number = 0;
  valOwn: number = 0;
  flagSimBook: number = 0;
  eachSim: number = 0;

  errAlloc =
    "<br/>Alocation has not been set on vessel destination for below booking(s): <br/>";
  errPort = "<br/>Missing Port of Call detected: <br/>";
  errLock =
    "<br/>Selected Booking(s) is/are locked for below booking(s): <br/>";
  msgFooter = "<br/><br/>Unable to transfer.";

  sendvartemp = [];
  allCheckVar = [];
  sortVar1 = [];
  sortVar2 = [];
  sendvar = [];
  gridColl = [];
  invalidBook = [];
  invalidBookPrefix = [];
  invalidPol = [];
  falseBook = [];
  fullBookPrefixloc = [];
  allocationArray = [];
  exceedBook = [];
  lockBook = [];
  lockBookPrefix = [];
  validBook = [];
  invalidLoc = [];
  blIsPrinted = [];
  blOriginalPrint = [];
  blCombineKey = [];
  blSourceCombine = [];

  alloclevel1 = [];
  alloclevel2 = [];
  alloclevel3 = [];
  alloclevel4 = [];
  alloclevel5 = [];
  flatalloc = [];

  portOfCallFrom: string = "";
  portOfCallTo: string = "";
  portOfCallToName: string = "";

  localEtaFrom: string = "";
  localEtaTo: string = "";

  localEtdFrom: string = "";
  isValid: string = "";

  userIdTrf: string = "";

  locationRef: string = "";

  newBno: string = "";
  newPrefix: string = "";

  modeGet: string = "";

  afterTransfer = 0;

  lenVartemp = 0;

  exmsg = "";
  messg = "";
  messg2 = "";
  messg3 = "";

  vessName = "";
  vessNameDest = "";

  officeCode: string = ""; //localStorage.getItem("defaultLocationCode").toString().trim();

  // lock
  cButtonLock = true;
  dButtonLock = true;

  modelBooking = new Booking();
  modelBookingDet = new BookingDetail();
  model = new BookingTransfer();
  modelTransfer = new BookingTransferPayload();

  formValidation = new FormValidation();

  validatorRules = {};

  canals: any[] = [];

  isDisabledMaintainBookingRef = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Transfer", event: "transfer", icon: "exchange" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Booking Transfer",
    };

    this.settingGridBookingTransfer = {
      id: "gridBookingTransfer",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        {
          header: "S",
          field: "bookStatus",
          width: 80,
          editType: "text",
        },
        {
          header: "Old Prefix",
          field: "bPrefix",
          width: 130,
          editType: "select",
          ds: [
            { code: "SUDU", name: "SUDU" },
            { code: "RESV", name: "RESV" },
          ],
          titleSelect: "bPrefix",
          codeSelect: "code",
        },
        {
          header: "Old Booking Ref.",
          field: "bNo",
          width: 170,
          editType: "text",
        },
        { header: "Result Message", field: "rMessage", width: 230 },
        { header: "New Prefix", field: "NbPrefix", width: 120 },
        { header: "New Booking Ref.", field: "NbNo", width: 170 },
        { header: "POD", field: "pod_name", width: 180 },
        { header: "POT", field: "pot_name", width: 180 },
        { header: "Freight Party", field: "bFreightPartyName", width: 250 },
        { header: "Shipper      ", field: "bShipperName", width: 250 },
        { header: "Required Teus", field: "bookTeus", width: 140 },
        { header: "Place Of Creation", field: "bLocationName", width: 160 },
        { header: "Valid", field: "rValid", width: 50 },
      ],
      buttons: [
        /*{ name: 'Edit', event: 'edit', enabled: true }*/
      ],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      editable: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      auto: false,
    };

    this.settingGridBookingTransferByNo = {
      id: "gridBookingTransferByNo",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 8,
      columns: [
        {
          header: "Old Prefix",
          field: "bPrefix",
          width: 170,
          editType: "select",
          ds: [
            { code: "SUDU", name: "SUDU" },
            { code: "RESV", name: "RESV" },
          ],
          titleSelect: "bPrefix",
          codeSelect: "code",
        },
        {
          header: "Old Booking No.",
          field: "bNo",
          width: 200,
          editType: "text",
        },
        { header: "New Prefix", field: "NbPrefix", width: 75 },
        { header: "New Booking Reference.", field: "NbNo", width: 200 },
        { header: "POD", field: "podcodevar", width: 100 },
        { header: "POT", field: "potcodevar", width: 100 },
        { header: "Freight Party", field: "bFreightPartyName", width: 200 },
        { header: "Shipper", field: "bShipperName", width: 250 },
        { header: "Required Teus", field: "reqTeus", width: 140 },
        { header: "Place Of Creation", field: "bLocationName", width: 200 },
      ],
      buttons: [
        /*{ name: 'Edit', event: 'edit', enabled: true }*/
      ],
      enableSorting: false,
      enableSelectAll: true,
      //enableSelectedRows : false,
      enablePagination: false,
      editable: true,
      //sortingColumns:'no',
      //sortingDirection: 'ASC',
    };

    this.settingGridBookingTransferAllocation = {
      id: "gridBookingTransferAllocation",
      // url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/calculateAllocation3new",
      page: 5,
      columns: [
        { header: "Level", field: "allotmentLevel", width: 100 },
        { header: "Allotment Code", field: "allotmentCode", width: 100 },
        { header: "Alloted", field: "alloted", width: 200 },
        { header: "Booked", field: "booked", width: 200 },
        { header: "Available (New Vsl)", field: "available", width: 200 },
        { header: "Required (Old Vsl)", field: "required", width: 200 },
        { header: "Difference", field: "difference", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingVesselOrigin = {
      id: "cbVesselOrigin",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
      // url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/*/vesselName/{query}/1/100/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Is valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingVesselDestination = {
      id: "cbVesselDestination",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/*/vesselName/{query}/1/100/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Is valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageOrigin = {
      id: "cbVoyageOrigin",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageDestination = {
      id: "cbVoyageDestination",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 200 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Is Valid?", field: "isValid", width: 125 },
      ],
    };

    this.settingPOD2 = {
      id: "cbPOD2",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POD",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 200 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Is Valid?", field: "isValid", width: 125 },
      ],
    };

    this.settingPOT = {
      id: "cbPOT",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POT",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 200 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Is valid?", field: "isValid", width: 125 },
      ],
    };

    this.settingPOT2 = {
      id: "cbPOT2",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search POT",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 200 },
        { header: "Location Name", field: "locationName", width: 200 },
        { header: "Is valid?", field: "isValid", width: 125 },
      ],
    };

    //set default value of bound to O
    this.model.boundOrigin = "O";
    this.model.boundDestination = "O";
  }

  ngOnInit() {
    const officeCode = this.cookieService.getDefaultLocationCode().substring(0, 2);
    this.isDisabledMaintainBookingRef = officeCode === 'CN' ? true : false; //GCHO-28 & GCHO-54
  }

  ngAfterViewInit() {
    //$('#gridBookingTransfer').find('th.collapsing').find('div.checkbox').remove();
    $(".dataTables_scrollBody").css("height", "300px");
    $("#gridBookingTransfer.text.1.bNo").css("width", "60px");

    //this.grid.setModeRadio(true);
    this.gridBookingTransfer.search = "*/*/*";
    this.gridBookingTransfer.onFirst();
    // this.cbVoyage.disableCombo = true;

    this.disableToolbarButtons = "retrieve,transfer,cancel";

    //get current location
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();
    this.userIdTrf = this.cookieService.getName();
    this.gridBookingTransfer.hideColumns(true, "rValid");
    // console.log(this.officeCode);
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.retrieveEvent();
        //// console.log("url grid "+this.grid.url);
        break;
      case "close":
        this.handleClose();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "transfer":
        this.handleTransfer();
        break;
    }
  }
  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  gridBookingTransferEvent(event) {
    // console.log('eventgrid'+event);
    //this.gridBookingTransfer.onSelectAll(true);
    var flagCk = 0;
    var selGrid = [];
    var soso = [];
    var dblFlag = false;

    // console.log(event.split(".")[0]);
    switch (event.split(".")[0]) {
      case "afterLoad":
        $("#gridBookingTransfer.text.1.bNo").css("width", "60px");
        this.gridBookingTransfer.hideColumns(true, "rValid");
        // console.log(this.gridBookingTransfer.listStore.store)
        // console.log('after load gridbookingtransfer 2')
        // console.log(this.gridBookingTransfer.listStore.store)
        this.gridBookingTransfer.onSelectAll(true);
        this.requiredTeus = 0;
        // this.availableTeus = this.availableTeusTemp
        this.gridBookingTransfer.listStore.store.forEach((ea) => {
          if (ea.select == "checked") {
            // console.log('masuk ceked teus--> '+ea.bookTeus);
            this.requiredTeus = this.requiredTeus + parseInt(ea.bookTeus);
            //  this.availableTeus = this.availableTeus - this.requiredTeus;

            flagCk = 1;
          }
        });

        // GOS-815 Deleted bkg highlight in RED
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.gridBookingTransfer.listStore.store.forEach((element) => {
            if(element["bookStatus"] == 'D'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);

        break;
      case "selected":
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        // console.log('selected masuk')
        $("#gridBookingTransfer.text.1.bNo").css("width", "60px");
        this.requiredTeus = 0;
        this.availableTeus = this.availableTeusTemp;
        this.gridBookingTransfer.listStore.store.forEach((ea) => {
          if (ea.select == "checked") {
            // console.log('masuk ceked teus2--> '+ea.bookTeus);
            this.requiredTeus = this.requiredTeus + parseInt(ea.bookTeus);
            //this.availableTeus = this.availableTeus - this.requiredTeus;
            this.availableTeus = this.availableTeusTemp - this.requiredTeus;
            flagCk = 1;
          }

          if (flagCk > 0 && this.radioTransferByBNo.toUpperCase() != "Y") {
            this.disableToolbarButtons = "";
          } else if (
            flagCk > 0 &&
            this.radioTransferByBNo.toUpperCase() == "Y"
          ) {
            this.disableToolbarButtons = "retrieve";
          } else {
            this.disableToolbarButtons = "transfer";
          }
        });
        break;
      case "click":
        //this.selectedListMod(event);
        this.requiredTeus = 0;
        $("#gridBookingTransfer.text.1.bNo").css("width", "60px");
        let dt = this.gridBookingTransfer.getSelectedValues();

        // console.log(dt);

        //this.gridBookingTransfer.clearSelectedValues();
        this.availableTeus = this.availableTeusTemp;
        //// console.log('nilai awal teus');
        //// console.log(this.availableTeus);
        this.gridBookingTransfer.listStore.store.forEach((ea) => {
          // console.log(ea);

          if (ea.select == "checked") {
            // console.log('masuk ceked teus '+ea.bookTeus);
            this.requiredTeus = this.requiredTeus + parseInt(ea.bookTeus);
            // // console.log('dis availabel teus start');
            //// console.log(this.availableTeus);
            //// console.log(this.requiredTeus);
            this.availableTeus = this.availableTeusTemp - this.requiredTeus;
            //// console.log('dis availabel teus');
            //// console.log(this.availableTeus);
            flagCk = 1;
          }
        });

        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          // console.log("before split transfer evenet -> " + strEvent);
          console.log("arr -> " + arr[0].split("."));
          // console.log("arr-split -> " + arr[0]);
          // console.log("arr-split -> " + arr[1]);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              console.log("wekano after after");
              this.gridBookingTransfer.listStore.store.forEach((wk) => {
                soso.push(wk);
              });

              dblFlag = this.checkDuplicateInObject("bNo", soso);

              if (dblFlag == true) {
                this.message(
                  "information",
                  "Information",
                  "Duplicate Booking Reference(s) detected.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.gridBookingTransfer.listStore.store.splice(
                  parseInt(arr[0].split(".")[1]) - 1,
                  1
                );
                this.gridBookingTransfer.loadData();
              }

              // console.log('param');
              // console.log(strEvent.split(';')[2]);
              // console.log(parseInt(arr[0].split('.')[1]));
              var index = parseInt(arr[0].split(".")[1]);
              var fieldIndex = strEvent.split(";")[1].trim();
              console.log("event");
              console.log(fieldIndex);
              console.log(strEvent);

              if (fieldIndex == "bPrefix") {
                // console.log('masuk bprefix');
                this.gridBookingTransfer.listStore.store.forEach((key) => {
                  if (
                    key.no == index &&
                    key.bNo != "" &&
                    key.bNo != null &&
                    key.bNo != undefined
                  ) {
                    // console.log('posisi sama' + key.bNo);
                    this.getFreightShipperTeus(
                      key.bPrefix,
                      strEvent.split(";")[2].trim(),
                      parseInt(arr[0].split(".")[1])
                    );
                  }
                  // else{
                  // this.message('information', "Information","No data(s) found","okonly",{ok:"// console.log('ok click')"})
                  //}
                });
              } else {
                this.gridBookingTransfer.listStore.store.forEach((key) => {
                  console.log("BNO " + key.bNo);
                  if (
                    key.no == index &&
                    key.bNo != "" &&
                    key.bNo != null &&
                    key.bNo != undefined
                  ) {
                    this.getFreightShipperTeus(
                      key.bPrefix,
                      strEvent.split(";")[2].trim(),
                      parseInt(arr[0].split(".")[1])
                    );
                  } //else{
                  // this.message('information', "Information","No data(s) found","okonly",{ok:"// console.log('ok click')"})
                  //}
                });
              }

              break;

            case "edit":
              break;
            case "dblClick":
              this.modeForm == "sailingScheduleMaintenance";
              this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  markRedRow(dataNo){
    var i = this.gridBookingTransfer.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.gridBookingTransfer.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }

  gridBookingTransferEventByNo(event) {
    // console.log('eventgrid'+event);
    // // console.log('logafterloadbyno')
    let gt = this.gridBookingTransferByNo.getSelectedValues()[0];
    switch (event.split(".")[0]) {
      case "afterLoad":
        // console.log('after load gridbookingtransfer by nooo')
        // console.log(this.gridBookingTransfer.listStore.store)
        this.gridBookingTransfer.onSelectAll(true);
        break;

      case "selected":
        // console.log('selected actions')
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case "click":
        //this.selectedListMod(event);
        var no = event.split(".")[1].split("-")[1];
        this.disableToolbarButtons = "retrieve";
        this.gridBookingTransferByNo.clearSelectedValues();
        this.gridBookingTransferByNo.onSelect(true, Number.parseInt(no));
        // this.gridBookingTransferByNo.listStore.removeData()
        // console.log(gt);

        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          // console.log("before split4 -> " + strEvent);
          // console.log("arr3 -> " + arr);
          // console.log("arr-split1 -> " + arr[0]);
          // console.log("arr-split2 -> " + arr[1]);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              //  this.getFreightShipperTeus(strEvent.split(';')[2]);
              break;
            case "dblClick":
              this.modeForm == "sailingScheduleMaintenance";
              this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetails(event) {
    //// console.log(event);

    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridDetails(event) {
    this.info = event;
  }
  infoGridDetails2(event) {
    this.info = event;
  }

  changeEventVesselOrigin(event) {
    // console.log('aaaaaarrr');
    // console.log(this.officeCode);
    if (event.vesselId == null) {
      this.model.vesselIdOrigin = "";
      this.portOfCallFrom = "";
      this.localEtaFrom = "";
      this.localEtdFrom = "";
    } else {
      this.model.vesselIdOrigin = event.vesselId;
      this.cbVoyageOrigin.disableCombo = false;
      this.vessName = event.vesselName;
      //this.cbVesselDestination.disableCombo = true;
      //this.cbVoyageOrigin.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageByVesselBound/"+this.model.vesselIdOrigin+"/"+this.model.boundOrigin+"/"+this.officeCode);
      this.cbVoyageOrigin.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselIdOrigin +
          "/" +
          this.model.boundOrigin +
          "/" +
          "{query}/" +
          this.officeCode
      );
      // console.log(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.model.vesselIdOrigin+"/"+this.model.boundOrigin+"/"+"{query}/"+this.officeCode);
      this.cbVoyageOrigin.setValue("");
    }
    if (
      this.model.voyageOrigin != "" &&
      this.model.voyageDestination != "" &&
      this.model.vesselIdOrigin != "" &&
      this.model.vesselIdDestination != ""
    )
      this.disableToolbarButtons = "transfer";
    else this.disableToolbarButtons = "transfer,retrieve";
  }

  changeEventVesselDestination(event) {
    // console.log('eaeaeaea');
    // console.log(event);
    this.disableToolbarButtons = "transfer,retrieve";
    var flagror = 0;

    if (this.radioTransferByPOT == "Y") {
      this.setValidatorTransferByPOT();
      let isPassedPot: boolean = this.onValidate(this.model);
      // console.log('masuk');
      // console.log(this.cbPOT.getValue());
      // console.log(isPassedPot)
      if (isPassedPot == true) {
        this.message(
          "information",
          "Information",
          "Please select Transshipment Port.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        this.cbVesselDestination.setValue("");
        this.availableTeus = 0;
        flagror = 1;
      }
    }
    if (this.radioTransferByPOD == "Y") {
      this.setValidatorTransferByPOD();
      let isPassedPod: boolean = this.onValidate(this.model);
      // console.log('masuk');
      // console.log(this.cbPOT.getValue());
      // console.log(isPassedPod)
      if (isPassedPod == true) {
        this.message(
          "information",
          "Information",
          "Please select Discharge Port.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        this.cbVesselDestination.setValue("");
        this.availableTeus = 0;
        flagror = 1;
      }
    }

    if (flagror == 0) {
      // console.log('masuk url else')
      if (event.vesselId == null) {
        this.model.vesselIdDestination = "";
        this.portOfCallTo = "";
        this.portOfCallToName = "";
      } else {
        this.model.vesselIdDestination = event.vesselId;
        this.model.vesselCodeDestination = event.vesselCode;
        this.cbVoyageDestination.disableCombo = false;
        this.vessNameDest = event.vesselName;
        //this.cbVoyageDestination.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageByVesselBound/"+this.model.vesselIdDestination+"/"+this.model.boundDestination+"/"+this.officeCode);
        this.cbVoyageDestination.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            this.model.vesselIdDestination +
            "/" +
            this.model.boundDestination +
            "/" +
            "{query}/" +
            this.officeCode
        );
        // console.log("/MasterSailingSchedules/distinctVoyageByVesselBound/"+this.model.vesselIdDestination+"/"+this.model.boundDestination+"/"+this.officeCode);
        this.cbVoyageDestination.setValue("");
      }
      /*
          if(this.model.voyageOrigin != '' && this.model.voyageDestination != '' && this.model.vesselIdOrigin != '' && this.model.vesselIdDestination != '')
                  this.disableToolbarButtons = 'transfer';
                else
                  this.disableToolbarButtons = 'transfer,retrieve';
                  */
    }
  }

  changeEventVoyageOrigin(event) {
    var sailFlag = "";
    var byPass = 0;
    if (event.voyage == null) {
      //this.model.voyageOrigin='*';
      this.model.voyageOrigin = "";
    } else {
      this.model.voyageOrigin = event.voyage;

      //set POT
      if (this.radioTransferByPOT.toString() == "Y") {
        if (this.model.vesselIdOrigin != "" && this.model.voyageOrigin != "") {
          this.cbPOT.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/distinctPOTByOfficeCodeVesselIdVoyageBound/" +
              this.officeCode +
              "/" +
              this.model.vesselIdOrigin +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.voyageOrigin).trim()
              ) +
              "/" +
              this.model.boundOrigin +
              "/" +
              "{query}"
          );
          this.cbVoyageOrigin.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              this.model.vesselIdOrigin +
              "/" +
              this.model.boundOrigin +
              "/" +
              "{query}/" +
              this.officeCode
          );
          this.cbPOT.setValue("");
          this.potLock = false;
        } else {
          this.cbPOT.setValue("");
          this.potLock = true;
        }
      }
      //set POD
      if (this.radioTransferByPOD.toString() == "Y") {
        if (this.model.vesselIdOrigin != "" && this.model.voyageOrigin != "") {
          this.cbPOD.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/distinctPODByOfficeCodeVesselIdVoyageBound/" +
              this.officeCode +
              "/" +
              this.model.vesselIdOrigin +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.voyageOrigin).trim()
              ) +
              "/" +
              this.model.boundOrigin +
              "/" +
              "{query}"
          );
          this.cbPOD.setValue("");
          this.podLock = false;
        } else {
          this.cbPOD.setValue("");
          this.podLock = true;
        }
      }

      //fill-in the port of rotation
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/getPortOfRotationForBookingMaintenance2/" +
            this.officeCode +
            "/" +
            this.model.vesselIdOrigin +
            "/" +
            this.sCharUtil.htmlEncode(String(this.model.voyageOrigin).trim()) +
            "/" +
            this.model.boundOrigin +
            "/" +
            this.cookieService.getUserId()
        )
        .subscribe((resp) => {
          //// console.log("rotation orig => " + resp.json()["content"][0]["rotation"].toString());
          //console.log(resp.json()["content"]);
          if (
            resp.json()["content"][0]["rotation"] != null &&
            resp.json()["content"][0]["rotation"] != undefined &&
            resp.json()["content"][0]["rotation"].toString() != ""
          ) {
            this.portOfCallFrom = resp
              .json()
              ["content"][0]["rotation"].toString();
            this.localEtaFrom = resp
              .json()
              ["content"][0]["localETA"].toString();
            this.localEtdFrom = resp
              .json()
              ["content"][0]["localETD"].toString();
            sailFlag = resp.json()["content"][0]["sailFlag"].toString();
            byPass = resp.json()["content"][0]["byPass"].toString();
            console.log("SAIL FLAGG " + sailFlag);

            if (sailFlag == "off" && byPass == 0) {
              this.message(
                "information",
                "Information",
                "Unable to transfer due to Vsl " +
                  this.vessName +
                  " " +
                  this.model.voyageOrigin +
                  " has sailed off",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              this.model.voyageOrigin = "";
              this.model.vesselIdOrigin = "";
              this.cbVesselOrigin.setValue("");
              this.cbVoyageOrigin.setValue("");
              this.cbVoyageOrigin.setUrl("");
              this.portOfCallFrom = "";
              return false;
            }
          } else {
            this.portOfCallFrom = "Not Available";
            this.loading = false;
          }

          //check valid schedule
          /* this.checkValidSchedule(
            this.model.vesselIdOrigin,
            this.sCharUtil.htmlEncode(String(this.model.voyageOrigin).trim()),
            this.model.boundOrigin,
            this.officeCode);  */

          console.log("** model **");
          console.log(this.model);
          console.log(this.model.vesselIdDestination);
          console.log(this.model.vesselCodeDestination);
          console.log(this.model.voyageDestination);

          if (this.model.voyageOrigin && this.model.voyageDestination && this.model.vesselIdOrigin && this.model.vesselIdDestination) {
            this.disableToolbarButtons = "transfer"
          } else {
            this.disableToolbarButtons = "transfer,retrieve"
          };

          /*
        if(self.model.voyageOrigin != '' && self.model.voyageDestination != '' && self.model.vesselIdOrigin != '' && self.model.vesselIdDestination != ''){
          console.log("#1")
          self.disableToolbarButtons = 'transfer';
        }else{
            console.log("#2")
            self.disableToolbarButtons = 'transfer,retrieve';
        }
        */
        });
    }
  }

  calculateReqTeus(bookNo: string, callback?: (data) => void) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getShipperFreightTeus/" +
          this.officeCode +
          "/" +
          bookNo.toUpperCase()
      )
      .subscribe((resp) => {
        //this.genericService.GET("http://localhost:14000/MasterBookings/getShipperFreightTeus/"+this.officeCode+"/"+bookNo.toUpperCase()).subscribe((resp) => {

        if (resp.ok) {
          this.requiredTeus = 0;
          this.requiredTeus =
            this.requiredTeus + resp.json()["content"][0].req_teus;
        }
      });

    if (callback) callback("");
  }

  changeEventPOT2(event) {}

  getFreightShipperTeus(pprefix: string, param: string, nogrid: number) {
    var tempTab = [];
    var frgName: string = "";
    var shpName: string = "";
    var bookTeus: number = 0;
    var ves_id: string = "";
    var voy_code: string = "";
    var bound_code: string = "";
    var psaflag: string = "";
    var bPodCode: string = "";
    var bPolCode: string = "";
    var bPotCode: string = "";
    var locHref: string = "";
    var bCntOwner: string = "";
    var bLocationName: string = "";
    var pod_name: string = "";
    var pot_name: string = "";
    var bIsUnlocked: string = "";
    var sameFlag = 0;
    var bIsUnlocked = "";
    var bookStatus = "";
    var rValid = 0;
    var rMessage = "";
    var bSlotOperatorCode = "";
    var blIsPrinted = "";
    var blIsCombine = "";
    var blOriginalPrint = "";
    var blCombineKey = "";
    var blIsSplit = "";
    var blOriginalPrintSplit = "";
    var blSplitKey = "";
    var pol_name = "";
    var sailOver = "";
    var byPass = 0;
    param = param.trim();
    this.gridBookingTransfer.loading = true;
    this.disableToolbarButtons = "transfer,retrieve";

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getShipperFreightTeus/" +
          this.officeCode +
          "/" +
          param.toUpperCase() +
          "/" +
          pprefix +
          "/" +
          this.cookieService.getUserId()
      )
      .subscribe((resp) => {
        // this.genericService.GET("http://localhost:14000/MasterBookings/getShipperFreightTeus/"+this.officeCode+"/"+param.toUpperCase()+"/"+pprefix).subscribe((resp) => {
        //// console.log("rotation orig => " + resp.json()["content"][0]["rotation"].toString());

        if (resp.ok) {
          //console.log('length respon:')
          //console.log(resp.json()['content'].length);
          //         console.log(resp.json()['content'][0].pod_name);
          //       console.log(resp.json()['content'][0].pot_name);
          this.requiredTeus = 0;
          this.gridBookingTransfer.loading = false;
          this.disableToolbarButtons = "transfer";
          if (resp.json()["content"].length > 0) {
            console.log(resp.json()["content"][0].b_pod_code);
            if (this.canals.includes(resp.json()["content"][0].b_pod_code)) {
              this.message(
                "information",
                "Information",
                "Unable to transfer this booking due to Vsl " +
                  this.vessNameDest +
                  " " +
                  this.model.voyageDestination +
                  " is Canal Passing",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              this.gridBookingTransfer.listStore.store.splice(nogrid - 1, 1);
              //this.gridBookingTransfer.listStore.removeData()
              this.gridBookingTransfer.listStore.store.forEach((cnt) => {
                this.requiredTeus = this.requiredTeus + cnt.bookTeus;
              });
              this.availableTeus = this.availableTeusTemp - this.requiredTeus;
              this.loading = false;
              this.gridBookingTransfer.loadData();
              return false;
            }
            /*
          let isCheckCanal:boolean = false;
            this.gridBookingTransfer.listStore.store.forEach(ytr=>{
                console.log(ytr.bPodCode);
                if(this.canals.includes(ytr.bPodCode)){
                  isCheckCanal = true;
                  return false;
                }
            });

            if(isCheckCanal){
              this.message('information', "Information","Unable to transfer due to Vsl "+this.vessNameDest+" "+this.model.voyageDestination+" due to Canal Passing","okonly",{ok:"// console.log('ok click')"});
              this.model.voyageDestination = ''
              this.model.vesselIdDestination = '';
              this.cbVesselDestination.setValue('');
              this.cbVoyageDestination.setValue('');
              this.availableTeus = 0;
              this.requiredTeus = 0;
              this.availableTeusTemp = 0;
              this.portOfCallTo = '';
              this.portOfCallToName = '';
              this.loading = false;
              return false;
            }
          */

            // console.log('masuk length');
            this.localEtaFrom = resp.json()["content"][0].localETA;
            this.localEtdFrom = resp.json()["content"][0].localETD;
            this.isValid = resp.json()["content"][0].isValid;
            sailOver = resp.json()["content"][0].sailFlag;
            byPass = resp.json()["content"][0].byPass;
            console.log("bypass " + byPass);
            console.log("isValid " + this.isValid);
            /*if(this.isValid == "N"){
              this.message(
                "information",
                "Information",
                "Unable to transfer " +
                  pprefix +
                  param.toUpperCase() +
                  ". Schedule is invalid.",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              this.gridBookingTransfer.listStore.store.splice(nogrid - 1, 1);
              //this.gridBookingTransfer.listStore.removeData()
              this.gridBookingTransfer.listStore.store.forEach((cnt) => {
                this.requiredTeus = this.requiredTeus + cnt.bookTeus;
              });
              this.availableTeus = this.availableTeusTemp - this.requiredTeus;
              this.loading = false;
              this.gridBookingTransfer.loadData();
              this.handleCancel();
              return false;
            }
            else*/
            if (sailOver == "off" && byPass == 0) {
              this.message(
                "information",
                "Information",
                "Unable to transfer " +
                  pprefix +
                  param.toUpperCase() +
                  " Vessel has sailed.",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              this.gridBookingTransfer.listStore.store.splice(nogrid - 1, 1);
              //this.gridBookingTransfer.listStore.removeData()
              this.gridBookingTransfer.listStore.store.forEach((cnt) => {
                this.requiredTeus = this.requiredTeus + cnt.bookTeus;
              });
              this.availableTeus = this.availableTeusTemp - this.requiredTeus;
              this.loading = false;
              this.gridBookingTransfer.loadData();
              return false;
            } else {
              this.gridBookingTransfer.listStore.store.forEach((tr) => {
                //comment
                if (
                  resp.json()["content"][0].b_no == tr.bNo.toUpperCase().trim()
                ) {
                  // console.log('masuk');// console.log(tr.bNo);// console.log(resp.json()['content'][0].b_no);
                  frgName = resp.json()["content"][0].freight_name;
                  shpName = resp.json()["content"][0].shipper_name;
                  bookTeus = resp.json()["content"][0].req_teus;
                  ves_id = resp.json()["content"][0].b_ocean_vessel_id;
                  voy_code = resp.json()["content"][0].b_ocean_vessel_voyage;
                  bound_code = resp.json()["content"][0].b_ocean_vessel_bound;
                  psaflag = resp.json()["content"][0].b_is_transmitted_to_psa;
                  bPodCode = resp.json()["content"][0].b_pod_code;
                  locHref = resp.json()["content"][0].locHref;
                  bCntOwner = resp.json()["content"][0].bCntOwner;
                  bPotCode = resp.json()["content"][0].b_pot1_code;
                  bLocationName = resp.json()["content"][0].bLocationName;
                  pod_name = resp.json()["content"][0].pod_name;
                  pot_name = resp.json()["content"][0].pot_name;
                  bIsUnlocked = resp.json()["content"][0].bIsUnlocked;
                  bookStatus = resp.json()["content"][0].bookStatus;
                  bSlotOperatorCode =
                    resp.json()["content"][0].b_slot_operator_code;
                  bPolCode = resp.json()["content"][0].b_pol_code;
                  rMessage = "";
                  rValid = 0;
                  blIsPrinted = resp.json()["content"][0].blIsPrinted;
                  blIsCombine = resp.json()["content"][0].blIsCombine;
                  blCombineKey = resp.json()["content"][0].blCombineKey;
                  blOriginalPrint = resp.json()["content"][0].blOriginalPrint;
                  blIsSplit = resp.json()["content"][0].blIsSplit;
                  blOriginalPrintSplit =
                    resp.json()["content"][0].blOriginalPrintSplit;
                  blSplitKey = resp.json()["content"][0].blSplitKey;
                  pol_name = resp.json()["content"][0].pol_name;
                } else {
                  frgName = tr.bFreightPartyName;
                  shpName = tr.bShipperName;
                  bookTeus = tr.bookTeus;
                  ves_id = tr.ves_id;
                  voy_code = tr.voy_code;
                  bound_code = tr.bound_code;
                  psaflag = tr.psaflag;
                  bPodCode = tr.bPodCode;
                  bPolCode = tr.bPolCode;
                  bPotCode = tr.bPotCode;
                  locHref = tr.locHref;
                  bCntOwner = tr.bCntOwner;
                  bLocationName = tr.bLocationName;
                  pod_name = tr.pod_name;
                  pot_name = tr.pot_name;
                  bIsUnlocked = tr.bIsUnlocked;
                  bookStatus = tr.bookStatus;
                  rMessage = tr.rMessage;
                  rValid = tr.rValid;
                  bSlotOperatorCode = tr.bSlotOperatorCode;
                  blIsPrinted = tr.blIsPrinted;
                  blIsCombine = tr.blIsCombine;
                  blOriginalPrint = tr.blOriginalPrint;
                  blCombineKey = tr.blCombineKey;
                  blSplitKey = tr.blSplitKey;
                  blIsSplit = tr.blIsSplit;
                  blOriginalPrintSplit = tr.blOriginalPrintSplit;
                  pol_name = tr.pol_name;
                }
                this.requiredTeus = this.requiredTeus + bookTeus;

                // console.log('REQ TEUS + '+this.requiredTeus);
                tempTab.push({
                  bFreightPartyName: frgName,
                  bNo: tr.bNo.trim(),
                  NbNo: "",
                  NbPrefix: "",
                  bPrefix: tr.bPrefix,
                  bShipperName: shpName,
                  nbPrefix: tr.nbPrefix,
                  no: tr.no,
                  bookTeus: bookTeus,
                  ves_id: ves_id,
                  voy_code: voy_code,
                  bound_code: bound_code,
                  psaflag: psaflag,
                  bPodCode: bPodCode,
                  bPotCode: bPotCode,
                  bPolCode: bPolCode,
                  locHref: locHref,
                  bCntOwner: bCntOwner,
                  bLocationName: bLocationName,
                  pod_name: pod_name,
                  pot_name: pot_name,
                  bIsUnlocked: bIsUnlocked,
                  bookStatus: bookStatus,
                  rMessage: rMessage,
                  rValid: rValid,
                  bSlotOperatorCode: bSlotOperatorCode,
                  blIsPrinted: blIsPrinted,
                  blIsCombine: blIsCombine,
                  blOriginalPrint: blOriginalPrint,
                  blCombineKey: blCombineKey,
                  blIsSplit: blIsSplit,
                  blOriginalPrintSplit: blOriginalPrintSplit,
                  blSplitKey: blSplitKey,
                  pol_name: pol_name,
                  //select:tr.select
                  select: "checked",
                });
              });

              // this.requiredTeus = 0;
              // console.log('ini lhooo eaaaa');
              // console.log(this.model.voyageDestination);
              if (
                this.model.voyageDestination == "" ||
                this.model.vesselIdDestination == ""
              ) {
                this.availableTeus = 0;
              } else {
                this.availableTeus = this.availableTeusTemp;
              }
              this.gridBookingTransfer.listStore.store.forEach((ea) => {
                // if(ea.select == 'checked'){
                // console.log('masuk ceked teus3--> '+this.requiredTeus);
                // / this.requiredTeus = this.requiredTeus + parseInt(ea.reqTeus);
                //this.availableTeus = this.availableTeus - this.requiredTeus;
                this.availableTeus = this.availableTeusTemp - this.requiredTeus;
                // console.log('masuk ceked teus2--> '+ this.requiredTeus);
                //// console.log()
                //flagCk = 1;

                // }
              });

              this.gridBookingTransfer.listStore.store = [];

              tempTab.forEach((lt) => {
                // console.log('ltltltltl');
                // console.log(lt);
                this.gridBookingTransfer.listStore.addData(lt);
              });

              this.gridBookingTransfer.loadData();
            } //else sail flag
          } //end else > 1
          else {
            console.log("masuk else kurang dari 0" + nogrid);
            this.message(
              "information",
              "Information",
              " No data(s) found",
              "okonly",
              { ok: "// console.log('ok click')" }
            );
            this.gridBookingTransfer.listStore.store.splice(nogrid - 1, 1);
            //this.gridBookingTransfer.listStore.removeData()
            this.gridBookingTransfer.listStore.store.forEach((cnt) => {
              this.requiredTeus = this.requiredTeus + cnt.bookTeus;
            });
            this.availableTeus = this.availableTeusTemp - this.requiredTeus;
            this.loading = false;
            this.gridBookingTransfer.loadData();
            return false;
          }
        }
      });
  }

  rowEvent(act: string) {
    var tableStore = [];
    if (act == "add") {
      tableStore.push({
        bPrefix: "SUDU",
        bNo: "",
        NbPrefix: "",
        NbNo: "",
        bFreightPartyName: "",
        bShipperName: "",
        //reqTeus : 0,
        bookTeus: 0,
        pod_name: "",
        pot_name: "",
        pol_name: "",
        //spotCreate:'',
        bLocationName: "",
        ves_id: "",
        voy_code: "",
        bound_code: "",
        select: "checked",
      });

      tableStore.forEach((yt) => {
        // this.gridBookingTransferByNo.listStore.addData(yt);
        this.gridBookingTransfer.listStore.addData(yt);
      });

      this.gridBookingTransfer.loadData();
      this.gridBookingTransfer.onSelectAllByValue(true);
      this.gridBookingTransfer.is_select_all = "checked";
      this.disableToolbarButtons = "retrieve";
      //this.gridBookingTransferByNo.loadData();
      //this.gridBookingTransferByNo.onSelectAllByValue(true);
      //this.gridBookingTransferByNo.is_select_all = 'checked';
    } else {
      console.log("masuk delete");
      this.gridBookingTransfer.getSelectedValues().forEach((element) => {
        console.log("elemen string");
        console.log(element);
        this.gridBookingTransfer.listStore.removeData(element);

        this.requiredTeus = this.requiredTeus - element.reqTeus;
      });

      if (this.gridBookingTransferByNo.listStore.store.length > 0) {
        this.gridBookingTransferByNo.listStore.store.forEach((grh) => {
          this.availableTeus = this.availableTeusTemp - this.requiredTeus;
        });
      } else {
        this.availableTeus = this.availableTeusTemp;
        this.disableToolbarButtons = "retrieve,transfer";
      }

      this.gridBookingTransfer.loadData();
      this.gridBookingTransfer.clearSelectedValues();
    }
  }

  changeEventVoyageDestination(event) {
    if (event.voyage == null) {
      //this.model.voyageDestination='*';
      this.model.voyageDestination = "";
    } else {
      this.model.voyageDestination = event.voyage;

      this.modelBooking.bOceanVesselId = this.model.vesselIdDestination;
      this.modelBooking.bOceanVesselVoyage = this.model.voyageDestination;
      this.modelBooking.bOceanVesselBound = this.model.boundDestination;
      this.modelBooking.bOfficeCode = this.officeCode;

      $("#cekDetailsShown").prop("checked", false);

      this.loading = true;

      var destFlag = "";
      var passBy = 0;

      //fill-in the port of rotation
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/getPortOfRotationForBookingMaintenance/" +
            this.officeCode +
            "/" +
            this.model.vesselIdDestination +
            "/" +
            this.sCharUtil.htmlEncode(
              String(this.model.voyageDestination).trim()
            ) +
            "/" +
            this.model.boundDestination +
            "/" +
            this.cookieService.getUserId()
        )
        .subscribe((resp) => {
          console.log(resp.json());
          if (
            resp.json()["content"][0]["rotation"] != null &&
            resp.json()["content"][0]["rotation"] != undefined &&
            resp.json()["content"][0]["rotation"].toString() != ""
          ) {
            this.portOfCallTo = resp
              .json()
              ["content"][0]["rotation"].toString();
            this.portOfCallToName = resp
              .json()
              ["content"][0]["rotationName"].toString();
            this.localEtaTo = resp.json()["content"][0]["localETA"].toString();
            destFlag = resp.json()["content"][0]["sailFlag"].toString();
            passBy = resp.json()["content"][0]["byPass"].toString();
            this.canals = [];
            resp.json()["content"][0]["canals"].forEach((element) => {
              this.canals.push(element);
            });

            if (this.gridBookingTransfer.listStore.store.length > 0) {
              let isCheckCanal: boolean = false;
              this.gridBookingTransfer.listStore.store.forEach((ytr) => {
                console.log(ytr.bPodCode);
                if (this.canals.includes(ytr.bPodCode)) {
                  isCheckCanal = true;
                  return false;
                }
              });

              if (isCheckCanal) {
                this.message(
                  "information",
                  "Information",
                  "Unable to transfer due to Vsl " +
                    this.vessNameDest +
                    " " +
                    this.model.voyageDestination +
                    " is Canal Passing",
                  "okonly",
                  { ok: "// console.log('ok click')" }
                );
                this.model.voyageDestination = "";
                this.model.vesselIdDestination = "";
                this.cbVesselDestination.setValue("");
                this.cbVoyageDestination.setValue("");
                this.cbVoyageDestination.setUrl("");
                this.availableTeus = 0;
                this.requiredTeus = 0;
                this.availableTeusTemp = 0;
                this.portOfCallTo = "";
                this.portOfCallToName = "";
                this.loading = false;
                this.disableToolbarButtons = "transfer,retrieve";
                return false;
              }
            }

            if (destFlag == "off" && passBy == 0) {
              this.message(
                "information",
                "Information",
                "Unable to transfer due to Vsl " +
                  this.vessNameDest +
                  " " +
                  this.model.voyageDestination +
                  " has sailed off",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              this.model.voyageDestination = "";
              this.model.vesselIdDestination = "";
              this.cbVesselDestination.setValue("");
              this.cbVoyageDestination.setValue("");
              this.cbVoyageDestination.setUrl("");
              this.availableTeus = 0;
              this.requiredTeus = 0;
              this.availableTeusTemp = 0;
              this.portOfCallTo = "";
              this.portOfCallToName = "";
              this.loading = false;
              return false;
            } else {
              this.genericService
                .POST(
                  this.configService.config.BASE_API.toString() +
                    "/MasterBookings/calculateAllocation3new/",
                  this.modelBooking
                )
                .subscribe((resp) => {
                  this.allocationArray = resp.json()["content"];

                  if (Object.keys(resp.json()["content"]).length > 0) {
                    var idxall = 0;
                    this.defaultBooked2 = "";
                    this.allocationArray.forEach((oo) => {
                      if (oo.allotmentCode != "VS") {
                        this.defaultBooked2 =
                          this.defaultBooked2 + oo.booked + ";";
                        oo.required = 0;
                        oo.difference = oo.available - oo.required;
                      } else {
                        oo.required = this.requiredTeus;
                        oo.difference =
                          parseInt(oo.available) - this.requiredTeus;
                      }

                      idxall++;

                      if (oo["allotmentCode"] == "VS") {
                        this.availableTeusTemp = oo["available"];
                      }
                    });

                    this.availableTeus = this.availableTeusTemp;
                    this.requiredTeus = 0;
                    this.gridBookingTransfer.listStore.store.forEach((ea) => {
                      if (ea.select == "checked") {
                        this.requiredTeus =
                          this.requiredTeus + parseInt(ea.bookTeus);
                        this.availableTeus =
                          this.availableTeusTemp - this.requiredTeus;
                      }
                    });

                    this.loading = false;
                    if (this.radioTransferByBNo.toUpperCase() != "Y") {
                      if (
                        this.model.voyageOrigin != "" &&
                        this.model.voyageDestination != "" &&
                        this.model.vesselIdOrigin != "" &&
                        this.model.vesselIdDestination != ""
                      ) {
                        this.disableToolbarButtons = "transfer";
                      } else {
                        this.disableToolbarButtons = "transfer,retrieve";
                      }
                    }

                    if (this.radioTransferByBNo.toUpperCase() == "Y") {
                      if (
                        this.model.voyageDestination != "" &&
                        this.model.vesselIdDestination != "" &&
                        this.gridBookingTransfer.listStore.store.length > 0
                      ) {
                        this.disableToolbarButtons = "retrieve";
                      }
                    }
                  } else {
                    this.availableTeus = 0;
                    this.message(
                      "information",
                      "Information",
                      "Allocation has not been set for ‘Transfer To’ vessel & voyage. Unable to transfer.",
                      "okonly",
                      { ok: "// console.log('ok click')" }
                    );
                    this.loading = false;
                    this.cbVesselDestination.setValue("");
                    this.cbVoyageDestination.setValue("");
                    this.cbVoyageDestination.setUrl("");
                    this.portOfCallTo = "";
                    this.portOfCallToName = "";
                    return false;
                  }
                });
            } //end else sail on
          } else {
            this.portOfCallTo = "Not Available";
            this.loading = false;
          }

          //check valid schedule
          this.checkValidSchedule(
            this.model.vesselIdDestination,
            this.sCharUtil.htmlEncode(
              String(this.model.voyageDestination).trim()
            ),
            this.model.boundDestination,
            this.officeCode
          );

          if (this.portOfCallTo == "Not Available") {
            this.message(
              "information",
              "Information",
              "No loading call for Vsl " +
                this.vessNameDest +
                " " +
                this.model.voyageDestination +
                ".",
              "okonly",
              { ok: "// console.log('ok click')" }
            );
            this.model.voyageDestination = "";
            this.model.vesselIdDestination = "";
            this.cbVesselDestination.setValue("");
            this.cbVoyageDestination.setValue("");
            this.cbVoyageDestination.setUrl("");
            this.availableTeus = 0;
            this.requiredTeus = 0;
            this.availableTeusTemp = 0;
            this.portOfCallTo = "";
            this.portOfCallToName = "";
            this.loading = false;
            return false;
          }

          var bbsplit = this.portOfCallTo.trim().split(" - ");
          // console.log('dunkdunkdunk')
          // console.log(bbsplit);
          // console.log(this.portOfCallTo);

          if (this.radioTransferByPOT == "Y") {
            var paramx: string = this.cbPOT.getValue().locationCode;
            var arrPOT = [];
            if (this.cbPOT.getValue().locationCode.toUpperCase() == "ALL")
              paramx = "ALLS";
            this.genericService
              .GET(
                this.configService.config.BASE_API.toString() +
                  "/MasterBookings/distinctPOTByOfficeCodeVesselIdVoyageBound/" +
                  this.officeCode +
                  "/" +
                  this.model.vesselIdOrigin +
                  "/" +
                  this.sCharUtil.htmlEncode(
                    String(this.model.voyageOrigin).trim()
                  ) +
                  "/" +
                  this.model.boundOrigin +
                  "/" +
                  paramx
              )
              .subscribe((pesp) => {
                // console.log('hit lagi')
                if (pesp.json()["content"].length > 0) {
                  for (var x = 0; x < pesp.json()["content"].length; x++) {
                    arrPOT.push({
                      locCode: pesp.json()["content"][x].locationCode,
                    });
                  }
                }
                var flagPot = 0;
                arrPOT.forEach((xy) => {
                  // console.log('yayayaya');
                  // console.log(bbsplit.indexOf(xy.locCode));
                  if (bbsplit.indexOf(xy.locCode) > 0) {
                    flagPot = 1;
                  }
                });

                if (flagPot == 0) {
                  this.message(
                    "information",
                    "Information",
                    " No such POT.",
                    "okonly",
                    { ok: "// console.log('ok click')" }
                  );
                  this.loading = false;
                  this.cbVesselDestination.setValue("");
                  this.cbVoyageDestination.setValue("");
                  this.cbVoyageDestination.setUrl("");
                  this.portOfCallTo = "";
                  this.portOfCallToName = "";
                  this.gridBookingTransferAllocation.listStore.store = [];
                  this.availableTeus = 0;
                  this.disableToolbarButtons = "retrieve,transfer";
                  this.allocationArray = [];
                  this.model.voyageDestination = "";
                  this.model.vesselIdDestination = "";
                  return false;
                }
              });
          } //end if pot

          if (this.radioTransferByPOD == "Y") {
            // console.log('coco')
            // console.log(this.cbPOD.getValue())

            var paramy: string = this.cbPOD.getValue().locationCode;
            var arrPOD = [];

            if (this.cbPOD.getValue().locationCode == "ALL") paramy = "ALLS";
            this.genericService
              .GET(
                this.configService.config.BASE_API.toString() +
                  "/MasterBookings/distinctPODByOfficeCodeVesselIdVoyageBound/" +
                  this.officeCode +
                  "/" +
                  this.model.vesselIdOrigin +
                  "/" +
                  this.sCharUtil.htmlEncode(
                    String(this.model.voyageOrigin).trim()
                  ) +
                  "/" +
                  this.model.boundOrigin +
                  "/" +
                  paramy
              )
              .subscribe((tesp) => {
                // console.log('hit lagi')
                if (tesp.json()["content"].length > 0) {
                  for (var x = 0; x < tesp.json()["content"].length; x++) {
                    arrPOD.push({
                      locCode: tesp.json()["content"][x].locationCode,
                    });
                  }
                }

                var flagPod = 0;
                arrPOD.forEach((xy) => {
                  // console.log('yayayaya');
                  // console.log(bbsplit.indexOf(xy.locCode));
                  if (bbsplit.indexOf(xy.locCode) > 0) {
                    flagPod = 1;
                  }
                });

                if (flagPod == 0) {
                  this.message(
                    "information",
                    "Information",
                    " No such POD.",
                    "okonly",
                    { ok: "// console.log('ok click')" }
                  );
                  this.loading = false;
                  this.cbVesselDestination.setValue("");
                  this.cbVoyageDestination.setValue("");
                  this.cbVoyageDestination.setUrl("");
                  this.portOfCallTo = "";
                  this.portOfCallToName = "";
                  this.gridBookingTransferAllocation.listStore.store = [];
                  this.availableTeus = 0;
                  this.disableToolbarButtons = "retrieve,transfer";
                  this.allocationArray = [];
                  this.model.voyageDestination = "";
                  this.model.vesselIdDestination = "";
                  return false;
                }
              });
            //}//end else
          } //end if POD
        });

      //  console.log('destflag '+destFlag)
    }
  }

  changeEventPOD(event) {
    //FIX-ME
    if (event.locationCode == null) {
      this.model.podCode = "";
    } else {
      this.model.podCode = event.locationCode;
      this.model["error-podCode"] = "";
      if (this.cbPOD.getValue().locationCode != "ALL") {
        this.cbPOD2.setForceValue(this.cbPOD.getValue().locationName);
      } else {
        this.cbPOD2.setForceValue("");
      }
    }
  }
  changeEventPOT(event) {
    // console.log('pot valueee');
    // // console.log(event);

    //FIX-ME
    if (event.locationCode == null || event.locationCode == undefined) {
      this.model.potCode = "";
    } else {
      this.model.potCode = event.locationCode;
      this.model["error-potCode"] = "";

      if (this.cbPOT.getValue().locationCode != "ALL") {
        this.cbPOT2.setForceValue(this.cbPOT.getValue().locationName);
      } else {
        this.cbPOT2.setForceValue("");
      }
    }
    //// console.log(this.cbPOT.listStore.store)
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          voyage +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to transfer booking.",
            "okonly",
            { ok: "/*this.handleCancelInvalid();*/" }
          );
          this.handleCancel();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  retrieveEvent() {
    var varPOT = "*";
    var varPOD = "*";
    this.blIsPrinted = [];
    this.gridBookingTransfer.url = "";
    // console.log('another despacito => ' + this.radioTransferByVessel.toUpperCase());
    // console.log(this.allocationArray)
    //// console.log(this.cbPOT.getValue().locationCode);
    this.gridBookingTransfer.clearSelectedValues();

    if (this.radioTransferByVessel.toUpperCase() == "Y") {
      this.setValidatorTransferByVessel();
      varPOD = "def";
      varPOT = "def";
    } else if (this.radioTransferByPOT.toUpperCase() == "Y") {
      this.setValidatorTransferByPOT();
      // varPOT = 'ALL';
      //varPOT = this.cbPOT.getValue().locationCode;
    } else if (this.radioTransferByBNo.toUpperCase() == "Y") {
      this.setValidatorTransferByBNo();
    } else if (this.radioTransferByPOD.toUpperCase() == "Y") {
      this.setValidatorTransferByPOD();
      //varPOT = this.cbPOD.getValue().locationCode;
      // varPOD = 'ALL';
    }

    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log('ispassed');
    // console.log(isPassedValidation);
    if (!isPassedValidation) {
      // console.log("retrieve Event");
      if (this.radioTransferByPOT.toUpperCase() == "Y") {
        varPOT = this.cbPOT.getValue().locationCode;
      }

      if (this.radioTransferByPOD.toUpperCase() == "Y") {
        varPOD = this.cbPOD.getValue().locationCode;
      }

      var hitUrl =
        this.configService.config.BASE_API.toString() +
        "/MasterBookings/getBookingsByOfficeVesselVoyageBound"; //VS00287/TESTE1/I/CCU;
      this.gridBookingTransfer.search =
        this.officeCode +
        "/" +
        this.model.vesselIdOrigin +
        "/" +
        this.sCharUtil.htmlEncode(String(this.model.voyageOrigin).trim()) +
        "/" +
        this.model.boundOrigin +
        "/" +
        varPOT +
        "/" +
        varPOD +
        "/" +
        this.modeGet;

      this.gridBookingTransfer.is_select_all = "checked";
      //this.gridBookingTransfer.onSelectAll(true);
      this.gridBookingTransfer.url = hitUrl;
      this.fillGrid();
      /*
      if(this.gridBookingTransfer.listStore.store.length ==0){
        this.message('information', "Information"," No data(s) found.","okonly",{ok:"// console.log('ok click')"})
        this.loading = false
        return false;
      }
      */

      console.log(this.gridBookingTransfer.listStore.store);
      // this.gridBookingTransferByNo.onSelect(true,Number.parseInt(no));

      //this.gridBookingTransfer.is_select_all = 'checked';
      //this.gridBookingTransfer.onSelectAll(true);

      this.disableToolbarButtons = "";

      this.cbVesselDestination.disableCombo = true;
      this.cbVesselOrigin.disableCombo = true;
      this.cbVoyageDestination.disableCombo = true;
      this.cbVoyageOrigin.disableCombo = true;
    }
  }

  fillGrid() {
    this.gridBookingTransfer.loadData();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  message2(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog")
    .modal("setting", "closable", false)
    .modal("show");
  }

  handleDoubleClickedSailingSchedule() {
    // console.log('modal routine');
    $("#sailingScheduleMaintenance")
      .modal("setting", "closable", false)
      .modal("show");
    //this.model.voyage = $('input[name="model[baseVoyage]"]').val();
    /*
     if(this.model.vesselId == '' || this.model.voyage == ''){
     this.message('warning', 'Alert','Random error!', 'okcancel', { ok: '// console.log("ok button clicked")'});
     } else {
     if(this.modeForm == 'sailingScheduleMaintenance'){
     $('#sailingScheduleMaintenance')
     .modal('setting', 'closable', false)
     .modal('show');
     } else if (this.modeForm == 'batch'){
     //this.copyProcess(this.model);
     }
     }
     */
  }

  hideMe() {
    $("#sailingScheduleMaintenance")
      .modal("setting", "closable", false)
      .modal("hide");
  }

  selectedListMod(value) {
    this.gridBookingTransfer.getSelectedValues().forEach((gs) => {
      if (value.split("-")[1] != gs.seqno) {
        gs["select"] = "";
        this.gridBookingTransfer
          .getSelectedValues()
          .splice(this.gridBookingTransfer.getSelectedValues().indexOf(gs), 1);
      }
    });
    if (value.split(".")[1].split("-")[0] == "checked") {
      // console.log("size "+this.gridBookingTransfer.getSelectedValues().length);
      // console.log("name "+this.gridBookingTransfer.getSelectedValues()[0].portofLoadingName);
    }
  }
  /*
   loadAllocation(data){
   this.cButtonLock = false;
   var store = [], storeDetails = [];
   var hitUrl = BASE_API_ALLOT + "/VesselAllocation/findByVesselVoy/" + data.vesselCode + "/" + data.voyage + "/" + data.bound;
   var details = null;
   this.genericService.GET(hitUrl).subscribe((resp) =>{
   if(resp.ok){
   store = resp.json()['content'];
   store.forEach(ss=>{
   // console.log("size details : "+ss['vesselAllocationDetails'].length);
   if(ss['vesselAllocationDetails'].length > 0){
   storeDetails = ss['vesselAllocationDetails'];
   storeDetails.forEach(sd=>{
   details = new AllocationModel;
   details.vesselCode = ss['vesselCode'];
   details.voyage = ss['voyage'];
   details.bound = ss['bound'];
   details.allocationId = ss['recordId'];
   details.seqNo = sd['recordSeq'];
   details.level = sd['allotmentLevel'];
   details.allotedBy = sd['allotmentCode'];
   details.allocated = parseInt(sd['alloted']);
   details.booked = parseInt(sd['booked']);
   details.reserved = parseInt(sd['reserved']);
   details.available = details.allocated - (details.booked + details.reserved);
   this.gridDetails.listStore.store.push(details);
   });
   }
   });
   this.gridDetails.loadData();
   }
   });
   }
   */
  addAllocation() {
    // console.log("bruray");
  }

  handleClose() {
    // handle close event
    this.router.navigateByUrl("/main/home");
  }

  changeEventTransferBy(event) {
    // console.log("Radio debug despacito => " + event.target.value.toString().trim().toUpperCase());

    if (event.target.value.toString().trim().toUpperCase() == "VESSEL") {
      this.handleCancel();
      this.gridBookingTransfer.enableSelectAll = true;
      this.radioTransferByVessel = "Y";
      this.radioTransferByPOT = "D";
      this.radioTransferByBNo = "D";
      this.radioTransferByPOD = "D";
      this.transferFromLock = false;
      this.transferToLock = false;
      this.disableToolbarButtons = "transfer,retrieve";
      this.addRemoveGrid = "none";
      this.modeForm = "bookingTransferDefault";
      this.modeGet = "VESSEL";
    } else if (event.target.value.toString().trim().toUpperCase() == "POT") {
      this.handleCancel();
      this.radioTransferByVessel = "D";
      this.radioTransferByPOT = "Y";
      this.radioTransferByPOT2 = "D";
      this.radioTransferByBNo = "D";
      this.radioTransferByPOD = "D";
      this.transferFromLock = false;
      this.transferToLock = false;
      this.addRemoveGrid = "none";
      this.modeGet = "POT";
      this.disableToolbarButtons = "transfer,retrieve";
      this.modeForm = "bookingTransferDefault";
    } else if (
      event.target.value.toString().trim().toUpperCase() == "BOOKINGNUMBER"
    ) {
      this.handleCancel();
      this.radioTransferByVessel = "D";
      this.radioTransferByPOT = "D";
      this.radioTransferByBNo = "Y";
      this.radioTransferByPOD = "D";
      this.transferFromLock = true;
      this.transferToLock = false;
      this.addRemoveGrid = "block";
      this.disableToolbarButtons = "transfer,retrieve";
      this.modeGet = "BOOKING";
      //this.modeForm ="bookingTransferDefault2";
      //this.gridBookingTransfer.enableSelectAll=false;
      this.gridBookingTransfer.editable = true;
    } else if (event.target.value.toString().trim().toUpperCase() == "POD") {
      this.handleCancel();
      this.radioTransferByVessel = "D";
      this.radioTransferByPOT = "D";
      this.radioTransferByBNo = "D";
      this.radioTransferByPOD = "Y";
      this.transferFromLock = false;
      this.transferToLock = false;
      this.radioTransferByPOD2 = "D";
      this.addRemoveGrid = "none";
      this.disableToolbarButtons = "transfer,retrieve";
      this.modeForm = "bookingTransferDefault";
      this.modeGet = "POD";
    }
  }

  handleCancel() {
    //reset the radio button / re-activate to selection
    this.ckFlag = 0;
    this.blIsPrinted = [];
    this.blSourceCombine = [];
    this.canals = [];
    this.lenVartemp = 0;
    this.afterTransfer = 0;
    this.ckTrfAll = 0;
    this.pdFlag = 0;
    this.valFlag = 0;
    this.valOwn = 0;
    this.eachSim = 0;
    this.model = new BookingTransfer();
    this.radioTransferByVessel = "N";
    this.radioTransferByPOT = "N";
    this.radioTransferByBNo = "N";
    this.radioTransferByPOD = "N";
    this.potLock = true;
    this.podLock = true;
    this.potLock2 = true;
    this.podLock2 = true;
    this.addRemoveGrid = "none";
    this.gridBookingTransfer.enableSelectAll = true;
    this.modeForm = "bookingTransferDefault";
    this.requiredTeus = 0;

    this.model["error-vesselIdOrigin"] = "";
    this.model["error-voyageOrigin"] = "";
    this.model["error-vesselIdDestination"] = "";
    this.model["error-voyageDestination"] = "";
    this.model["error-podCode"] = "";
    this.model["error-potCode"] = "";

    this.cbVesselOrigin.setValue("");
    this.cbVoyageOrigin.setValue("");
    this.cbVoyageOrigin.setUrl("");
    this.cbVesselDestination.setValue("");
    this.cbVoyageDestination.setValue("");
    this.cbVoyageDestination.setUrl("");
    this.model.boundOrigin = "O";
    this.model.boundDestination = "O";
    this.cbPOD.setValue("");
    this.cbPOT.setValue("");
    this.cbPOD2.setValue("");
    this.cbPOT2.setValue("");

    this.cbVesselDestination.disableCombo = false;
    this.cbVesselOrigin.disableCombo = false;
    this.cbVoyageDestination.disableCombo = false;
    this.cbVoyageOrigin.disableCombo = false;

    $("#maintainBook").prop("checked", true);
    $("#cekDetailsShown").prop("checked", false);

    this.transferToLock = true;
    this.transferFromLock = true;

    this.disableToolbarButtons = "retrieve,transfer,cancel";

    this.availableTeus = 0;
    this.availableTeusTemp = 0;
    this.portOfCallFrom = "";
    this.portOfCallTo = "";
    this.portOfCallToName = "";

    this.gridBookingTransfer.enable();
    this.gridBookingTransfer.editable = false;
    this.gridBookingTransfer.url = "";

    this.localEtaFrom = "";
    this.localEtdFrom = "";
    this.localEtaTo = "";

    this.invalidBook = [];
    this.invalidPol = [];
    this.invalidBookPrefix = [];
    this.falseBook = [];
    this.fullBookPrefixloc = [];
    this.exceedBook = [];
    this.lockBook = [];
    this.lockBookPrefix = [];
    this.validBook = [];
    this.invalidLoc = [];
    this.blIsPrinted = [];
    this.blOriginalPrint = [];
    this.blSourceCombine = [];

    //clear the grid
    this.gridBookingTransfer.onClear();

    this.gridBookingTransfer.clearSelectedValues();

    this.gridBookingTransferAllocation.onClear();
    this.gridBookingTransferByNo.onClear();

    this.gridBookingTransferByNo.clearSelectedValues();
    this.gridBookingTransferByNo.editable = true;
  }

  setValidatorTransferByVessel() {
    this.validatorRules = {
      vesselIdOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel origin.",
          },
        ],
      },
      voyageOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage origin.",
          },
        ],
      },
      vesselIdDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel destination.",
          },
        ],
      },
      voyageDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage destination.",
          },
        ],
      },
      boundOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  setValidatorTransferByPOT() {
    this.validatorRules = {
      vesselIdOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel origin.",
          },
        ],
      },
      voyageOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage origin.",
          },
        ],
      },
      boundOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      potCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      },
    };
  }

  setValidatorTransferByPOD() {
    this.validatorRules = {
      vesselIdOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel origin.",
          },
        ],
      },
      voyageOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage origin.",
          },
        ],
      },
      boundOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      podCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POD.",
          },
        ],
      },
    };
  }

  setValidatorBeforeDoTransfer() {
    this.validatorRules = {
      vesselIdOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel origin.",
          },
        ],
      },
      voyageOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage origin.",
          },
        ],
      },
      boundOrigin: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      vesselIdDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please insert `Transfer To` Vessel.",
          },
        ],
      },
      voyageDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please insert `Transfer To` Voyage.",
          },
        ],
      },
      boundDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  setValidatorTransferByBNo() {
    this.validatorRules = {
      vesselIdDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel destination.",
          },
        ],
      },
      voyageDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage destination.",
          },
        ],
      },
      boundDestination: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  eventMessage(event: any) {
    // console.log('**event message **')
    // console.log(event);
    if (event != "") {
      eval(event);
    }

    // console.log('current --> ' + this.dialogMultiple.current);
    // console.log('map size --> ' + this.dialogMultiple.settings.length);

    if (this.dialogMultiple.current == this.dialogMultiple.settings.length) {
      this.dialogMultiple.current = undefined;
      /*
      if (this.map.size == 0) {
        // console.log('not save');
        this.Check_MapSize();
        return;

      }
      else if(this.map.size>0)
      {  // console.log('save processing');
        this.populateData();
      }
      */
    }
  }

  gridBookingTransferAllocationEvent(event) {
    switch (event.split(".")[0]) {
      case "afterLoad":
        var totCOC = 0;
        var totSOC = 0;
        var totSS = 0;
        var totPD = 0;

        var totCOCLocal = 0;
        var totSOCLocal = 0;
        var totCOCTS = 0;
        var totSOCtS = 0;

        this.gridBookingTransferAllocation.listStore.store.forEach((gak) => {
          gak.required = 0;
          gak.difference = gak.available - gak.required;
        });

        // console.log('wakwawawawawawq')
        // console.log(this.gridBookingTransferAllocation.listStore.store)

        this.gridBookingTransfer.store.forEach((ct) => {
          if (
            (ct.bCntOwner == "COC" || ct.bCntOwner == "COCTS") &&
            ct.select == "checked"
          )
            totCOC = totCOC + parseInt(ct.bookTeus);

          if (
            (ct.bCntOwner == "SOC" || ct.bCntOwner == "SOCTS") &&
            ct.select == "checked"
          )
            totSOC = totSOC + parseInt(ct.bookTeus);
          /*
           if(ct.bCntOwner == 'SLOT'){
             // console.log('masuk slot')
           totSS = totSS + parseInt(ct.bookTeus);
           ct.bCntOwner = 'SS-'+ct.bSlotOperatorCode;
           }
           ct.pdAllotment = 'PD-'+ct.bPodCode;
           */
        });

        this.gridBookingTransfer.store.forEach((xt) => {
          // console.log('new');
          // console.log(xt);
        });

        this.gridBookingTransfer.getSelectedValues().forEach((fetch) => {
          this.checkNojan(
            "1",
            fetch,
            this.gridBookingTransferAllocation.listStore.store,
            1
          );
        });

        this.gridBookingTransferAllocation.listStore.store.forEach((gg) => {
          if (gg.allotmentCode == "VS") {
            gg.required = this.requiredTeus;
            gg.difference = parseInt(gg.available) - this.requiredTeus;
          }
        });
        //this.gridBookingTransferAllocation.loadData();

        // console.log(this.gridBookingTransfer.listStore.store)
        // console.log(this.gridBookingTransferAllocation.listStore.store)

        break;
    }
  }
  ckTransferValidation(param, ckval) {
    var mntBook = 0;
    var arrIndex = 0;
    this.blIsPrinted = [];

    if ($("#maintainBook").is(":checked")) {
      mntBook = 1;
    } else {
      mntBook = 0;
    }
    var codeparam = "";
    var house: boolean;
    this.gridBookingTransfer.getSelectedValues().forEach((get) => {
      console.log("isi get");
      console.log(get);
      // console.log(param);
      // console.log(param.indexOf(get.bPodCode))

      if (get.bIsUnlocked == "N" && get.bookStatus != "D") {
        this.lockBook[arrIndex] = get.bNo;
        this.lockBookPrefix[arrIndex] = " " + get.bPrefix + get.bNo;
      }

      if (get.blIsPrinted == "Y" && mntBook == 0) {
        this.blIsPrinted[arrIndex] = get.bNo;
      }

      if (
        get.blOriginalPrint == "Y" &&
        get.blIsCombine == "Y" &&
        mntBook == 0
      ) {
        this.blOriginalPrint[arrIndex] = get.bNo;
      }

      console.log("log validation");
      console.log(get.blIsCombine);
      console.log(get.blCombineKey);
      console.log("SSL" + get.bNo.toUpperCase().trim());

      if (
        get.blIsCombine == "Y" &&
        get.blCombineKey != "SSL" + get.bNo.toUpperCase().trim()
      ) {
        this.blSourceCombine[arrIndex] = get.bNo.toUpperCase().trim();
      }
      /*
      if(get.blIsCombine == 'Y'){
        this.blIsCombine[arrIndex]=get.bNo;
      }
      */
      if (get.blCombineKey != "") {
        this.blCombineKey[arrIndex] = get.blCombineKey;
      }
      // this.valFlag = 0;
      console.log("ramparahamam");
      console.log(get.pot_name);
      console.log(param);

      if (ckval == "vessel") {
        //validasi rotasi sequence check nya POL - POT - POD
        if (param.indexOf(get.pol_name) < 0) {
          this.ckFlag = this.ckFlag + 1;

          this.invalidLoc[arrIndex] = get.pod_name;
          this.invalidBook[arrIndex] = get.bNo.toUpperCase();
          this.invalidBookPrefix[arrIndex] =
            " " + get.bPrefix + get.bNo.toUpperCase();
        } else {
          //else WILL check POT & POD

          if (get.bPotCode == "") {
            codeparam = get.bPodCode;
            //if(param.indexOf(get.bPodCode) < 0){
            if (param.indexOf(get.pod_name) < 0) {
              console.log("masuk kondisi POD");
              console.log(param.indexOf(get.pod_name));
              this.ckFlag = this.ckFlag + 1;

              this.invalidLoc[arrIndex] = get.pod_name;
              this.invalidBook[arrIndex] = get.bNo.toUpperCase();
              this.invalidBookPrefix[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              //arrIndex ++;
            } else {
              //this.ckTransferAllocation(get.bPrefix+get.bNo,get.bNo,get.bPodCode,get.bCntOwner,get.bContainerStatus,arrIndex,get.bSlotOperatorCode);
              /*
                  var house = this.checkAllocationValidator(get);
                  // console.log('housee');
                  // console.log(house);

                  if(house == true){
                    this.falseBook[arrIndex] = get.bNo;
                    this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                    arrIndex++;
                  }
                  */
              if (
                this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
              ) {
                this.falseBook[arrIndex] = get.bNo.toUpperCase();
                this.fullBookPrefixloc[arrIndex] =
                  " " + get.bPrefix + get.bNo.toUpperCase();
                //arrIndex ++;
              }
            }
          } else {
            codeparam = get.bPotCode;
            //if(param.indexOf(get.bPotCode) < 0){
            if (param.indexOf(get.pot_name) < 0) {
              // console.log('masuk kondisi');
              // console.log(param.indexOf(get.bPotCode));
              this.ckFlag = this.ckFlag + 1;

              this.invalidLoc[arrIndex] = get.pot_name;
              this.invalidBook[arrIndex] = get.bNo.toUpperCase();
              this.invalidBookPrefix[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              //arrIndex ++;
            } else {
              //this.ckTransferAllocation(get.bPrefix+get.bNo,get.bNo,get.bPotCode,get.bCntOwner,get.bContainerStatus,arrIndex,get.bSlotOperatorCode);
              //arrIndex++;
              /*
                    var house = this.checkAllocationValidator(get);
                    // console.log('housee');
                    // console.log(house);

                    if(house == true){
                      this.falseBook[arrIndex] = get.bNo;
                      this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                      arrIndex++;
                    }
                    */
              if (
                this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
              ) {
                this.falseBook[arrIndex] = get.bNo.toUpperCase();
                this.fullBookPrefixloc[arrIndex] =
                  " " + get.bPrefix + get.bNo.toUpperCase();
                //  arrIndex ++;
              }
            }
          }
        }
      } //end vessel

      if (ckval == "pot") {
        if (param.indexOf(get.pol_name) < 0) {
          //CHECK POL FIRST

          this.ckFlag = this.ckFlag + 1;
          this.invalidLoc[arrIndex] = get.pot_name;
          this.invalidBook[arrIndex] = get.bNo.toUpperCase();
          this.invalidBookPrefix[arrIndex] =
            get.bPrefix + get.bNo.toUpperCase();
        } else {
          //CHECK POT AND POD

          if (param.indexOf(get.pot_name) < 0) {
            // console.log('masuk kondisi');
            // console.log(param);
            // console.log(get.bPotCode);
            this.ckFlag = this.ckFlag + 1;
            this.invalidLoc[arrIndex] = get.pot_name;
            this.invalidBook[arrIndex] = get.bNo.toUpperCase();
            this.invalidBookPrefix[arrIndex] =
              get.bPrefix + get.bNo.toUpperCase();
            //arrIndex ++;
          } else {
            // console.log('isi get');
            // console.log(get);
            //var house = this.checkAllocationValidator(get);
            // console.log('housee');
            // console.log(house);

            /*
              if(house == true){
                this.falseBook[arrIndex] = get.bNo;
                this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                arrIndex++;
              }
              */
            if (
              this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
            ) {
              this.falseBook[arrIndex] = get.bNo.toUpperCase();
              this.fullBookPrefixloc[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              // arrIndex ++;
            }
          }
        }

        //if(param.indexOf(get.bPotCode) < 0){

        /*
          if(this.ckFlag == 0){

            this.ckTransferAllocation(get.bPrefix+get.bNo,get.bNo,get.bPotCode,get.bCntOwner,get.bContainerStatus,arrIndex,get.bSlotOperatorCode);
            arrIndex++;

          }
          */
      }

      if (ckval == "pod") {
        //if(param.indexOf(get.bPodCode) < 0){
        if (param.indexOf(get.pol_name) < 0) {
          //CHECK POL FIRST

          this.ckFlag = this.ckFlag + 1;

          this.invalidLoc[arrIndex] = get.pod_name;
          this.invalidBook[arrIndex] = get.bNo.toUpperCase();
          this.invalidBookPrefix[arrIndex] =
            get.bPrefix + get.bNo.toUpperCase();
        } else {
          if (param.indexOf(get.pod_name) < 0) {
            // console.log('masuk kondisi');
            // console.log(param);
            // console.log(get.bPodCode);
            this.ckFlag = this.ckFlag + 1;

            this.invalidLoc[arrIndex] = get.pod_name;
            this.invalidBook[arrIndex] = get.bNo.toUpperCase();
            this.invalidBookPrefix[arrIndex] =
              get.bPrefix + get.bNo.toUpperCase();
            // arrIndex ++;
          } else {
            /*
              var house = this.checkAllocationValidator(get);
              // console.log('housee');
              // console.log(house);

              if(house == true){
                this.falseBook[arrIndex] = get.bNo;
                this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                arrIndex++;
              }
              */
            if (
              this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
            ) {
              this.falseBook[arrIndex] = get.bNo.toUpperCase();
              this.fullBookPrefixloc[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              //arrIndex ++;
            }
          }
        }
      }

      if (get.bIsPsa == "Y") {
        this.ckPsa = 1;
      }

      arrIndex++;
    });
    var mergeTmp2 = this.invalidBook.concat(this.falseBook);
    var goes = 0;
    mergeTmp2 = mergeTmp2.concat(this.lockBook);
    mergeTmp2 = mergeTmp2.concat(this.exceedBook);
    mergeTmp2 = mergeTmp2.concat(this.blIsPrinted);
    mergeTmp2 = mergeTmp2.concat(this.blOriginalPrint);
    mergeTmp2 = mergeTmp2.concat(this.blSourceCombine);

    this.lenVartemp = 0;
    //this.lenY = 0;
    this.lenVartemp = this.gridBookingTransfer.getSelectedValues().length;

    this.gridBookingTransfer.getSelectedValues().forEach((gs) => {      
      //// console.log(gs);
      var tPayload = new Payload();
      tPayload.bPrefix = gs.bPrefix;
      tPayload.bNo = gs.bNo.toUpperCase();
      //this.modelTransfer.payloads.push(tPayload);
      this.allCheckVar.push(gs);

      if (mergeTmp2.indexOf(gs.bNo.toUpperCase()) < 0 || gs.bOrphanFlag === "Y") {
        // if((gs.blIsPrinted == 'N' && mntBook == 1)||(gs.blIsPrinted == 'Y' && mntBook == 1)||(gs.blIsPrinted == '' && gs.blIsPrinted == null)){

        this.validBook[goes] = gs.bNo.toUpperCase();
        goes++;

        this.sendvartemp.push({
          originVesselId: this.model.vesselIdOrigin,
          originVoyageCode: this.model.voyageOrigin,
          destinationVesselId: this.model.vesselIdDestination,
          destinationVoyage: this.model.voyageDestination,
          destinationBound: this.model.boundDestination,
          destinationVesselCode: this.model.vesselCodeDestination,
          bPrefix: gs.bPrefix,
          bNo: gs.bNo.toUpperCase(),
          NbPrefix: gs.bPrefix,
          NbNo: "",
          bShipperName: gs.bShipperName,
          bFreightPartyName: gs.bFreightPartyName,
          bPodCode: gs.bPodCode,
          bPotCode: gs.bPotCode,
          bPolCode: gs.bPolCode,
          bIsPsa: gs.bIsPsa,
          mntBook: mntBook,
          locHref: gs.locHref,
          bCntOwner: gs.bCntOwner,
          bLocationName: gs.bLocationName,
          bookTeus: gs.bookTeus,
          userIdTrf: this.userIdTrf,
          pod_name: gs.pod_name,
          pot_name: gs.pot_name,
          localEta: this.localEtaTo,
          officeCode: this.officeCode,
          bookStatus: gs.bookStatus,
          blIsPrinted: gs.blIsPrinted,
          blIsCombine: gs.blIsCombine,
          blCombineKey: gs.blCombineKey,
          blOriginalPrint: gs.blOriginalPrint,
          blIsSplit: gs.blIsSplit,
          blSplitKey: gs.blSplitKey,
          blOriginalPrintSplit: gs.blOriginalPrintSplit,
          pol_name: gs.pol_name,
        });

        //  }
      }
    });

    // return this.ckFlag;
  }

  isSameVessel(el, index, arr) {
    // Do not test the first array element, as you have nothing to compare to
    if (index === 0) {
      return true;
    } else {
      //do each array element value match the value of the previous array element
      return el.ves_id === arr[index - 1].ves_id;
    }
  }

  isSameVoyage(el, index, arr) {
    // Do not test the first array element, as you have nothing to compare to
    if (index === 0) {
      return true;
    } else {
      //do each array element value match the value of the previous array element
      return el.voy_code === arr[index - 1].voy_code;
    }
  }

  ckTransferValidationByNo(param) {
    this.blIsPrinted = [];
    var mntBook = 0;
    var arrIndex = 0;
    this.flagSimBook = 0;
    this.eachSim = 0;
    this.blIsPrinted = [];

    if ($("#maintainBook").is(":checked")) {
      mntBook = 1;
    } else {
      mntBook = 0;
    }

    //this.gridBookingTransferByNo.getSelectedValues().forEach(get=>{
    console.log(param);
    console.log("validation byno");
    var paramy = "";
    var xbook = [];

    // Get the selected records in the grid
    let gridBookingTransferList = this.gridBookingTransfer.listStore.store.filter(x=>x.select === "checked");

    var ckVessel = gridBookingTransferList.every(
      this.isSameVessel
    );
    var ckVoyage = gridBookingTransferList.every(
      this.isSameVoyage
    );

    // console.log('CKVESSEL'+ckVessel);
    // console.log('CK VOYAGE'+ckVoyage);

    if (ckVessel == false || ckVoyage == false) {
      this.eachSim = 1;
    }

    //this.gridBookingTransfer.listStore.store.forEach(get=>{
    this.gridBookingTransfer.getSelectedValues().forEach((get) => {
      //console.log('isi get by bno');
      //console.log(get);
      //console.log('isi table lock');
      //console.log(this.blIsPrinted);
      // console.log(get.voy_code);
      // console.log(this.cbVesselDestination.getValue().vesselId);
      // console.log(this.cbVoyageDestination.getValue().voyage);

      if (get.bIsUnlocked == "N" && get.bookStatus != "N") {
        this.lockBook[arrIndex] = get.bNo.toUpperCase();
        this.lockBookPrefix[arrIndex] =
          " " + get.bPrefix + get.bNo.toUpperCase();
      }

      if (get.blIsPrinted == "Y" && mntBook == 0) {
        this.blIsPrinted[arrIndex] = get.bNo.toUpperCase();
      }

      if (
        get.blOriginalPrint == "Y" &&
        get.blIsCombine == "Y" &&
        mntBook == 0
      ) {
        this.blOriginalPrint[arrIndex] = get.bNo.toUpperCase();
      }

      console.log("log validation");
      console.log(get.blIsCombine);
      console.log(get.blCombineKey);
      console.log("SSL" + get.bNo.toUpperCase().trim());

      if (
        get.blIsCombine == "Y" &&
        get.blCombineKey != "SSL" + get.bNo.toUpperCase()
      ) {
        this.blSourceCombine[arrIndex] = get.bNo.toUpperCase();
      }
      /*
        if(get.blIsCombine == 'Y'){
          this.blIsCombine[arrIndex]=get.bNo;
        }
        */
      if (get.blCombineKey != "") {
        this.blCombineKey[arrIndex] = get.blCombineKey;
      }

      if (
        get.ves_id == this.cbVesselDestination.getValue().vesselId &&
        get.voy_code == this.cbVoyageDestination.getValue().voyage
      ) {
        this.flagSimBook = 1;
      }

      if (param.indexOf(get.pol_name) < 0) {
        this.ckFlag = this.ckFlag + 1;
        xbook[arrIndex] = get.bNo.toUpperCase();

        this.invalidLoc[arrIndex] = get.pot_name;
        this.invalidBook[arrIndex] = get.bNo.toUpperCase();
        this.invalidBookPrefix[arrIndex] =
          " " + get.bPrefix + get.bNo.toUpperCase();
      } else {
        console.log("masuk ELSE POT validation");

        if (get.bPodCode != "" && get.bPotCode != "") {
          paramy = get.bPotCode;
          //          if(param.indexOf(get.bPotCode)<0){
          if (param.indexOf(get.pot_name) < 0) {
            this.ckFlag = this.ckFlag + 1;
            xbook[arrIndex] = get.bNo.toUpperCase();

            this.invalidLoc[arrIndex] = get.pot_name;
            this.invalidBook[arrIndex] = get.bNo.toUpperCase();
            this.invalidBookPrefix[arrIndex] =
              " " + get.bPrefix + get.bNo.toUpperCase();
            // arrIndex ++;
          } else {
            /*
                        var house = this.checkAllocationValidator(get);
                        // console.log('housee');
                        // console.log(house);

                        if(house == true){
                          this.falseBook[arrIndex] = get.bNo;
                          this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                          arrIndex++;
                        }
                        */
            if (
              this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
            ) {
              this.falseBook[arrIndex] = get.bNo.toUpperCase();
              this.fullBookPrefixloc[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              //arrIndex ++;
            }
          }
        }

        if (get.bPodCode != "" && get.bPotCode == "") {
          // console.log('balalla');
          // console.log(get.bPodCode);
          // console.log(param);
          //if(param.indexOf(get.bPodCode)<0){
          console.log("masuk ELSE POD validation");
          if (param.indexOf(get.pod_name) < 0) {
            this.ckFlag = this.ckFlag + 1;
            this.invalidLoc[arrIndex] = get.pod_name;
            this.invalidBook[arrIndex] = get.bNo.toUpperCase();
            this.invalidBookPrefix[arrIndex] =
              " " + get.bPrefix + get.bNo.toUpperCase();
            xbook[arrIndex] = get.bNo.toUpperCase();
            //arrIndex ++;
          } else {
            /*
                    var house = this.checkAllocationValidator(get);
                    // console.log('housee');
                    // console.log(house);

                    if(house == true){
                      this.falseBook[arrIndex] = get.bNo;
                      this.fullBookPrefixloc[arrIndex] =' '+get.bPrefix+get.bNo;
                      arrIndex++;
                    }
                    */
            if (
              this.checkNojan("1", get, this.allocationArray, arrIndex) == 0
            ) {
              this.falseBook[arrIndex] = get.bNo.toUpperCase();
              this.fullBookPrefixloc[arrIndex] =
                " " + get.bPrefix + get.bNo.toUpperCase();
              //arrIndex ++;
            }
          }
        }
      }

      /*
        if(this.ckFlag == 0 ){
          this.ckTransferAllocation(get.bPrefix+get.bNo,get.bNo,paramy,get.bCntOwner,get.bContainerStatus,arrIndex,get.bSlotOperatorCode);
         arrIndex++;
        }

      if(get.bPodCode == ''){
        if(param.indexOf(get.bPodCode) < 0){
          // console.log('masuk kondisi');
          // console.log(get.bPodCode);
          // console.log(param.indexOf(get.bPodCode));
        this.ckFlag = 1;
        }
      }

      else{
        if(param.indexOf(get.bPotCode) < 0){
          // console.log('masuk kondisi pot');
          // console.log(get.potcodevar);
          // console.log(param.indexOf(get.potcodevar));
        this.ckFlag = 1;
        }
      }
      */
      if (get.psaflag == "Y") {
        this.ckPsa = 1;
      }

      arrIndex++;
    });
    var gone = 0;
    var mergeTmp = this.invalidBook.concat(this.falseBook);
    mergeTmp = mergeTmp.concat(this.lockBook);
    mergeTmp = mergeTmp.concat(this.exceedBook);
    mergeTmp = mergeTmp.concat(this.blIsPrinted);
    mergeTmp = mergeTmp.concat(this.blSourceCombine);
    mergeTmp = mergeTmp.concat(this.blOriginalPrint);

    gridBookingTransferList.forEach((gs) => {
      // console.log('gaes');// console.log(gs);
      this.allCheckVar.push(gs);

      if (mergeTmp.indexOf(gs.bNo.toUpperCase()) < 0) {
        this.validBook[gone] = gs.bNo.toUpperCase();
        gone++;
        this.sendvartemp.push({
          originVesselId: gs.ves_id,
          originVoyageCode: gs.voy_code,
          destinationVesselId: this.model.vesselIdDestination,
          destinationVoyage: this.model.voyageDestination,
          destinationBound: this.model.boundDestination,
          destinationVesselCode: this.model.vesselCodeDestination,
          bPrefix: gs.bPrefix,
          bNo: gs.bNo.toUpperCase(),
          NbPrefix: gs.bPrefix,
          NbNo: "",
          bShipperName: gs.bShipperName,
          bFreightPartyName: gs.bFreightPartyName,
          bPodCode: gs.bPodCode,
          bPotCode: gs.bPotCode,
          bPolCode: gs.bPolCode,
          bIsPsa: gs.bIsPsa,
          mntBook: mntBook,
          locHref: gs.locHref,
          bCntOwner: gs.bCntOwner,
          bLocationName: gs.bLocationName,
          bookTeus: gs.bookTeus,
          userIdTrf: this.userIdTrf,
          pod_name: gs.pod_name,
          pot_name: gs.pot_name,
          localETA: this.localEtaTo,
          officeCode: this.officeCode,
          bookStatus: gs.bookStatus,
          blIsPrinted: gs.blIsPrinted,
          blIsCombine: gs.blIsCombine,
          blCombineKey: gs.blCombineKey,
          blOriginalPrint: gs.blOriginalPrint,
          blIsSplit: gs.blIsSplit,
          blSplitKey: gs.blSplitKey,
          blOriginalPrintSplit: gs.blOriginalPrintSplit,
        });
      }
    });
  }

  getChildList(level, store: any[]) {
    var result = [];
    store.forEach((ss) => {
      if (
        ss.allotmentLevel.startsWith(level) &&
        ss.allotmentLevel.length > level.length
      ) {
        result.push(ss);
      }
    });

    return result;
  }

  ckTransferAllocation(
    booknoprefix: string = "",
    bnumb: string = "",
    podCodevar: string = "",
    owner: string = "",
    bstatus: string = "",
    idx,
    opcode: string = ""
  ) {
    //cek main PD nya dulu..klo lolos otomatis bisa
    // klo ada PD tapi tidak ada di old vessel booking gagal semua
    var idxx = 0;
    var flagown = 0;
    // console.log('minimal masuk sini')
    if (owner == "SLOT") owner = "SS-" + opcode;
    if (bstatus != "EMPTY") bstatus = "LADEN";
    var flagPD = [];
  }
  checkIsTeusDetailsShown(event) {
    if (event.target.checked == true) {
      this.modeForm = "bookingTransferAllocation";

      this.gridBookingTransferAllocation.search =
        this.model.vesselIdDestination +
        "/" +
        this.sCharUtil.htmlEncode(String(this.model.voyageDestination).trim()) +
        "/" +
        this.model.boundDestination;
      this.modelBooking.bOceanVesselId = this.model.vesselIdDestination;
      this.modelBooking.bOceanVesselVoyage = this.model.voyageDestination;
      this.modelBooking.bOceanVesselBound = this.model.boundDestination;
      this.modelBooking.bOfficeCode = this.officeCode;

      var req_grid = [];
      this.gridBookingTransferAllocation.setBody(this.modelBooking);

      this.gridBookingTransferAllocation.loadData();

      //this.gridBookingTransferAllocation.listStore.addData(req_grid);
      // this.gridBookingTransferAllocation.loadData();
    } else {
      //if($("#rdTransferBy[value='bookingNumber']").is(':checked'))
      // this.modeForm ="bookingTransferDefault2";
      // else
      this.modeForm = "bookingTransferDefault";
    }
  }
  setvalvar(bnovar) {
    // console.log('bnovar');
    // console.log(bnovar);
    this.newBno = bnovar;
  }
  generateBookingNumber(
    locref: string,
    podref: string,
    cntown: string,
    callback?: (data) => void
  ) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingNo/" +
          locref +
          "/" +
          podref +
          "/" +
          cntown
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (Object.keys(resp.json()["bNo"]).length > 0) {
            var bbno = resp.json()["bNo"];
            this.setvalvar(bbno);
            /*
            this.splitDetails = new BookingSplit;
            this.splitDetails.seqnoNew = iteration+1;
            this.splitDetails.bNoNew = resp.json()['bNo'];
            this.splitDetails.containerTypeNew = this.typeSize[0];
            this.splitDetails.containerSizeNew = this.cntSz[0];
            this.splitDetails.qtyNew = 0;
            this.splitDetails.bSeqPrefix = 0;
            this.splitStore.push(this.splitDetails);
              */
          }

          if (callback) callback(bbno);
        }
      });
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  handleTransfer() {
    this.sendvartemp = [];
    this.allCheckVar = [];
    this.sortVar1 = [];
    this.sortVar2 = [];
    this.afterTransfer = 0;
    this.ckFlag = 0;
    this.ckPsa = 0;
    this.ckTrfAll = 0;
    this.pdFlag = 0;
    this.valFlag = 0;
    this.valOwn = 0;
    this.invalidBook = [];
    this.invalidBookPrefix = [];
    this.falseBook = [];
    this.fullBookPrefixloc = [];
    this.exceedBook = [];
    this.lockBook = [];
    this.lockBookPrefix = [];
    this.validBook = [];
    this.invalidLoc = [];
    this.blOriginalPrint = [];
    this.blSourceCombine = [];

    var msgHeader = "Unable to transfer, Plese check details below : <br/>";
    var msgBox = "";

    let isPassedValidation: boolean;

    var splPortTo = this.portOfCallTo.trim().split(" - ");
    var splPortToName = this.portOfCallToName.trim().split(" - ");

    // console.log('PORT TO NAME');
    // console.log(splPortToName);

    if (this.radioTransferByVessel.toUpperCase() == "Y") {
      this.setValidatorBeforeDoTransfer();
      //this.setValidatorTransferByVessel();
      isPassedValidation = this.onValidate(this.model);
      var ckBt = this.ckTransferValidation(splPortToName, "vessel");
    } else if (this.radioTransferByPOT.toUpperCase() == "Y") {
      this.setValidatorBeforeDoTransfer();
      // this.setValidatorTransferByPOT();
      isPassedValidation = this.onValidate(this.model);
      //  varPOT = this.cbPOT.getValue().locationCode;
      var ckBt = this.ckTransferValidation(splPortToName, "pot");
    } else if (this.radioTransferByBNo.toUpperCase() == "Y") {
      this.setValidatorTransferByBNo();
      // this.setValidatorTransferByBNo();
      isPassedValidation = this.onValidate(this.model);
      var ckBt = this.ckTransferValidationByNo(splPortToName);
    } else if (this.radioTransferByPOD.toUpperCase() == "Y") {
      this.setValidatorBeforeDoTransfer();
      //this.setValidatorTransferByPOD();
      isPassedValidation = this.onValidate(this.model);
      var ckBt = this.ckTransferValidation(splPortToName, "pod");

      //varPOT = this.cbPOD.getValue().locationCode;
    }

    var ckCeked = 0;
    this.gridBookingTransferByNo.listStore.store.forEach((ytr) => {
      // console.log('ye te er');// console.log(ytr);
      if (ytr.select == "checked") {
        ckCeked = 1;
      }
    });

    this.gridBookingTransfer.listStore.store.forEach((ytr) => {
      if (ytr.select == "checked") {
        ckCeked = 1;
      }
    });

    // console.log('keular return');
    if (!isPassedValidation) {
      // usage example:

      var ckMaintain = 0;

      if ($("#maintainBook").is(":checked")) {
        ckMaintain = 1;
      } else {
        ckMaintain = 0;
      }

      this.invalidBook = this.invalidBook.filter(this.onlyUnique);
      this.invalidBookPrefix = this.invalidBookPrefix.filter(this.onlyUnique);

      this.lockBook = this.lockBook.filter(this.onlyUnique);
      this.lockBookPrefix = this.lockBookPrefix.filter(this.onlyUnique);

      this.falseBook = this.falseBook.filter(this.onlyUnique);
      this.fullBookPrefixloc = this.fullBookPrefixloc.filter(this.onlyUnique);
      this.exceedBook = this.exceedBook.filter(this.onlyUnique);

      this.invalidLoc = this.invalidLoc.filter(this.onlyUnique);

      //var strX = this.invalidBookPrefix.join();
      var strX = this.invalidLoc.join();
      var strD = this.fullBookPrefixloc.join();
      var strEc = this.exceedBook.join();
      var strLoc = this.lockBookPrefix.join();

      this.exmsg =
        "Exceeded new vessel allocation for booking(s) below : <br/>" + strEc; //+'<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will not be transferred. <br/> &lt;No&gt; to edit.';
      this.messg =
        "Missing Port of Call detected for below booking(s): <br/>" + strX; //+'<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will not be transferred. <br/> &lt;No&gt; to edit.';
      this.messg2 =
        "Alocation has not been set on vessel destination for below booking(s): <br/>" +
        strD; //+'<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will not be transferred. <br/> &lt;No&gt; to edit.';
      this.messg3 =
        "Selected Booking(s) is/are locked for below booking(s): <br/>" +
        this.lockBookPrefix.join(); //+'<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will not be transferred. <br/> &lt;No&gt; to edit.';

      console.log("original print");
      console.log(this.blSourceCombine);
      console.log(this.blOriginalPrint);
      console.log(this.sendvartemp);
      // console.log(this.blIsCombine);

      // console.log(this.gridBookingTransfer.getSelectedValues().length);
      // console.log(this.validBook.length);
      // console.log(this.invalidBook.length);
      // console.log(this.falseBook.length)
      // console.log(this.lockBook.length);
      // console.log(this.invalidLoc);
      // console.log(this.lockBook);
      // console.log(this.exceedBook);
      // console.log(this.allCheckVar)
      // console.log('LOCAL ETA FROM'+this.localEtaFrom);
      // console.log('LOCAL ETA TO'+this.localEtaTo);
      // console.log('end komparasi');

      var trueBook =
        this.gridBookingTransfer.getSelectedValues().length -
        this.validBook.length;

      if (
        this.radioTransferByBNo.toUpperCase() != "Y" &&
        this.cbVesselDestination.getValue().vesselId ==
          this.cbVesselOrigin.getValue().vesselId &&
        this.cbVoyageDestination.getValue().voyage ==
          this.cbVoyageOrigin.getValue().voyage
      ) {
        this.message(
          "information",
          "Information",
          " Booking(s) not transferred. Similiar vessel & voyage detected.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        return false;
      } else if (
        this.radioTransferByBNo.toUpperCase() == "Y" &&
        this.flagSimBook > 0
      ) {
        this.message(
          "information",
          "Information",
          " Booking(s) not transferred. Similiar vessel & voyage detected.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        return false;
      } else if (
        this.radioTransferByBNo.toUpperCase() == "Y" &&
        this.eachSim > 0
      ) {
        this.message(
          "information",
          "Information",
          "Bookings from different vessels not allowed.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        return false;
      } /*else if ((this.radioTransferByBNo.toUpperCase() == "Y") && this.blIsPrinted.length > 0 && ckMaintain == 0){
        this.message('information', "Information","Bl already printed, please maintain booking ref.","okonly",{ok:"// console.log('ok click')"})
        this.loading = false
        return false;
      }*/
      //else if (this.availableTeus < this.requiredTeus) {
      // GOS-504 >> Validation booking transfer
      else if (this.availableTeusTemp < this.requiredTeus) {


          this.message(
          "information",
          "Information",
          " Available teus is less than required teus.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        return false;
      } //remove from jira 456
      /*else if(this.localEtaFrom  > this.localEtaTo){
        this.message('information', "Information"," ETA of 'New' vessel must be the same or later than ETA of 'Old' vessel.","okonly",{ok:"// console.log('ok click')"})
        this.loading = false
        return false;
      }*/
      else if (this.ckPsa == 1) {
        // console.log('masuk kondisi psa');

        this.message(
          "information",
          "Information",
          "  Booking already transmitted to PSA, unable to retain Booking reference.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;
        return false;
      } else if (this.sendvartemp.length == 0) {
        //klo list bookingnya ga ada yang valid

        this.message(
          "information",
          "Information",
          "Unable to transfer please check details.",
          "okonly",
          { ok: "// console.log('ok click')" }
        );
        this.loading = false;

        this.allCheckVar.forEach((init) => {
          init.rMessage = "";
        });

        this.allCheckVar.forEach((one) => {
          if (one.rMessage == undefined) one.rMessage = "";

          if (this.lockBook.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            one.rMessage = "<font color=red>- Booking is locked</font> <br/>";
            one.rValid = 1;
          }

          if (this.invalidBook.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            one.rMessage =
              one.rMessage +
              "<font color=red>- Missing port of call </font><br/>";
            one.rValid = 1;
          }

          console.log(one.blOriginalPrint);

          if (
            this.blIsPrinted.indexOf(one.bNo.toUpperCase().trim()) > -1 &&
            one.blIsCombine != "Y"
          ) {
            //ditambah validasi untuk bl combine
            one.rMessage =
              one.rMessage +
              "<font color=red>- Bl already printed, please maintain booking ref. </font><br/>";
            one.rValid = 1;
          }

          if (this.blSourceCombine.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            one.rMessage =
              one.rMessage +
              "<font color=red>- This is a combined BL.<br/>Please use " +
              one.bPrefix +
              one.blCombineKey.replace("SSL", "") +
              ". </font><br/>";
            one.rValid = 1;
          }

          if (this.blOriginalPrint.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            //ditambah validasi untuk bl combine
            one.rMessage =
              one.rMessage +
              "<font color=red>- Unable to transfer due to OBL printed. </font><br/>";
            one.rValid = 1;
          }

          if (this.falseBook.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            one.rMessage =
              one.rMessage +
              "<font color=red>- Missing Allocation(s)</font> <br/>";
            one.rValid = 1;
          }

          if (this.exceedBook.indexOf(one.bNo.toUpperCase().trim()) > -1) {
            one.rMessage =
              one.rMessage +
              "<font color=red>- Alloted figure is missing</font> <br/>";
            one.rValid = 1;
          }
        });

        this.gridBookingTransfer.listStore.store.splice(
          0,
          this.gridBookingTransfer.listStore.store.length
        );
        // console.log('all check var')
        // console.log(this.allCheckVar)

        this.allCheckVar.sort(this.compare);

        this.allCheckVar.forEach((two) => {
          this.gridBookingTransfer.listStore.addData(two);
        });

        this.gridBookingTransfer.is_select_all = "checked";
        // console.log('all wrong');
        // console.log(this.gridBookingTransfer.listStore.store)

        this.gridBookingTransfer.url = "";
        //this.gridBookingTransfer.sort('rMessage');

        this.gridBookingTransfer.loadData();

        /*
        $('#gridBookingTransfer-table').each(function(i) {
          var rowGrid = $('#gridBookingTransfer-table tr').length-1;
          for(var k=1;k<=rowGrid;k++){
         //   var valRow = $(this).find("span[id='gridBookingTransfer.span."+k+".rValid']").text();
           // if( valRow == '1'){
             // // console.log('masukkk')
               $(this).find("span[id='gridBookingTransfer.span."+k+".rMessage']").css('color','red')
         //   }
            // console.log('Row '+(k)+$(this).find("span[id='gridBookingTransfer.span."+k+".rValid']").text()+' '+$(this).find("span[id='gridBookingTransfer.span."+k+".rMessage']").text());
          }
        });
        */

        this.disableToolbarButtons = "retrieve,transfer";

        return false;

        /*

        if(this.lockBook.length > 0){
          this.message('information', "Information","Bookings are LOCKED, thus NOT transferred (except Deleted Bookings).", "okonly",{ok:"// console.log('click ok')"});
          this.loading = false
          return false;
        }

        else if(this.invalidBook.length > 0){
          this.message('information', "Information","Unable to transfer, missing Port of Call detected.", "okonly",{ok:"// console.log('click ok')"});
          this.loading = false
          return false;
        }

        else if(this.falseBook.length > 0){
          this.message('information', "Information","Allocation has not been set for ‘Transfer To’ vessel & voyage. Unable to transfer.", "okonly",{ok:"// console.log('click ok')"});
          this.loading = false
          return false;
        }else{

          this.actionTransfer();

        }
      */
      } else if (this.sendvartemp.length > 0) {
        this.allCheckVar.forEach((onit) => {
          onit.rMessage = "";
        });

        this.allCheckVar.forEach((pick) => {
          if (pick.rMessage == undefined) pick.rMessage = "";

          if (this.lockBook.indexOf(pick.bNo.toUpperCase().trim()) > -1) {
            pick.rMessage = "<font color=red>- Booking is locked</font> <br/>";
            pick.rValid = 1;
          }

          if (this.invalidBook.indexOf(pick.bNo.toUpperCase().trim()) > -1) {
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- Missing port of call</font> <br/>";
            pick.rValid = 1;
          }

          if (this.falseBook.indexOf(pick.bNo.toUpperCase().trim()) > -1) {
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- Missing Allocation(s)</font> <br/>";
            pick.rValid = 1;
          }

          if (
            this.blIsPrinted.indexOf(pick.bNo.toUpperCase().trim()) > -1 &&
            pick.blIsCombine != "Y"
          ) {
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- Bl already printed, please maintain booking ref </font><br/>";
            pick.rValid = 1;
          }
          console.log("lalalala");
          console.log(
            this.blSourceCombine.indexOf(pick.bNo.toUpperCase().trim())
          );
          console.log(pick.blOriginalPrint);
          if (
            this.blOriginalPrint.indexOf(pick.bNo.toUpperCase().trim()) > -1
          ) {
            //ditambah validasi untuk bl combine
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- Unable to transfer due to OBL printed. </font><br/>";
            pick.rValid = 1;
          }

          if (
            this.blSourceCombine.indexOf(pick.bNo.toUpperCase().trim()) > -1
          ) {
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- This is a combined BL.<br/>Please use " +
              pick.bPrefix +
              pick.blCombineKey.replace("SSL", "") +
              ". </font><br/>";
            pick.rValid = 1;
          }

          if (this.exceedBook.indexOf(pick.bNo.toUpperCase().trim()) > -1) {
            pick.rMessage =
              pick.rMessage +
              "<font color=red>- Alloted figure is missing</font> <br/>";
            pick.rValid = 1;
          }
        });

        this.actionTransfer();
      }
    }
  } //end function

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const genreA = a.bNo.toUpperCase();
    const genreB = b.bNo.toUpperCase();

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }


  actionTransfer() {
    this.disableToolbarButtons = "retrieve,transfer";
    // console.log('masuk else kudu di transfer')
    //setup the payload before AJAX Call
    this.modelTransfer.destinationVesselId = this.model.vesselIdDestination;
    this.modelTransfer.destinationVoyage = this.model.voyageDestination;
    this.modelTransfer.destinationBound = this.model.boundDestination;
    this.loading = true;
    // this.modelTransfer.payloads = [];

    //// console.log(sendvartemp);
    var datas = "";
    var finreqTeus = 0;
    var responJson = "";
    //bikin sekalian aja di function do transferbooking, di bagian service nya..
    
    this.genericService
    .POST(
      this.configService.config.BASE_API.toString() +
        "/MasterBookings/checkPendingBooking ",
      this.sendvartemp
    )
      .subscribe(res => {
        if (res.json().status == 'NOK') {
          this.message2(
            "information",
            "Information",
            res.json().message,
            "okonly",
            { ok: "this.transferProcess()" }
          );
          //this.loading = false;
          let invalidBNos = res.json().invalidBNos;
          
          invalidBNos.forEach(bNo=>{
            this.sendvartemp.forEach(data=>{
              if(data.bNo === bNo){
                const idx = this.sendvartemp.indexOf(data);
                if (idx > -1) {
                  this.sendvartemp.splice(idx, 1);
                }
              }
            });
          });

          if(this.sendvartemp.length == 0){
            this.loading = false;
            let gridBookingTransfer = this.gridBookingTransfer.listStore.store;
            gridBookingTransfer.forEach(gird=>{
              invalidBNos.forEach(bNo=>{
                if(gird.bNo === bNo){
                    gird.rMessage = "<font color=red>- Transferred Failed</font>";
                }
              })
            })
            this.gridBookingTransfer.listStore.store = gridBookingTransfer;
          }
        }else{
          this.transferProcess();
        }
        
        //console.log("** after **")
        //this.sendvartemp.forEach(data=>{
        //  console.log(data)
        //});
          /*
          // GOS-838
         
          let gridBookingTransfer = this.gridBookingTransfer.listStore.store;
          gridBookingTransfer.forEach(gird=>{
            invalidBNos.forEach(bNo=>{
              if(gird.bNo === bNo){
                  gird.rMessage = "<font color=red>- Transferred Failed</font>";
              }
            })
          })
          this.gridBookingTransfer.listStore.store = gridBookingTransfer;
          return;
          */
       // } else {
        
      })
        

    //}
  }

  transferProcess() {
    var datas = "";
    var finreqTeus = 0;
    var responJson = "";

    if(this.sendvartemp.length > 0){
          
      this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/doTransferBooking",
        this.sendvartemp
      )
      .pipe(finalize(()=>{this.loading = false;}))
      .subscribe(
        (resp) => {
          //   // console.log(resp.json()[0]);
          // // console.log(resp.json());
          datas = resp.json()[0] ? resp.json()[0]["status"] : "";
          responJson = resp.json();

          if (datas == "OK") {
            // console.log(this.modeForm);// console.log(this.sendvartemp);
            // this.disableToolbarButtons="transfer,retrieve";

            //this.gridBookingTransfer.onClear();

            // this.gridBookingTransfer.listStore.store = [];
            // this.gridBookingTransferByNo.listStore.store = [];
            //  this.gridBookingTransfer.is_select_all='checked'
            //this.sendvartemp.forEach(yg=>{
            this.gridBookingTransfer.enableSelectAll = true;

            resp.json().forEach((yg) => {
              // console.log(yg);
              finreqTeus = finreqTeus + parseInt(yg.bookTeus);
              this.allCheckVar.forEach((lolo) => {
                if (
                  yg.bNo.toUpperCase().trim() == lolo.bNo.toUpperCase().trim()
                ) {
                  lolo.NbPrefix = yg.NbPrefix;
                  lolo.NbNo = yg.NbNo.toUpperCase();
                }
              });
              //this.gridBookingTransfer.listStore.addData(yg);
              //this.gridBookingTransferByNo.listStore.addData(yg);
            });
            this.gridBookingTransfer.listStore.store.splice(
              0,
              this.gridBookingTransfer.listStore.store.length
            );
            this.allCheckVar.sort(this.compare);
            this.allCheckVar.forEach((gogo) => {
              //this.gridBookingTransfer.listStore.addData(gogo);
              if (gogo.rValid == 1) {
                this.sortVar1.push(gogo);
              } else {
                this.sortVar2.push(gogo);
              }
            });

            this.sortVar1.forEach((yoyo) => {
              this.gridBookingTransfer.listStore.addData(yoyo);
            });

            this.sortVar2.forEach((koko) => {
              this.gridBookingTransfer.listStore.addData(koko);
            });

            this.afterTransfer = 1;
            this.gridBookingTransfer.is_select_all = "checked";
            // console.log('wkwkwkw');
            // console.log(this.gridBookingTransfer.listStore.store)

            this.gridBookingTransfer.url = "";
            // this.gridBookingTransfer.onSort('rMessage');
            this.gridBookingTransfer.loadData();

            // console.log('wkwkwkw');
            // console.log(this.gridBookingTransfer.listStore.store)
            //this.gridBookingTransferByNo.loadData();
            //this.gridBookingTransfer.enableSelectAll = false;
            this.gridBookingTransferByNo.editable = false;
            //this.gridBookingTransfer.disable();
            this.requiredTeus = finreqTeus;
            this.availableTeus = this.availableTeusTemp - finreqTeus;
            this.loading = false;
            this.disableToolbarButtons = "retrieve,transfer";
            // this.lenY = 0;
            this.lenVartemp = 0;
          }

          // GOS-838
          let countSuccess:number = 0;
          let responBnoList = resp.json();
          let isAllRecordTransferSuccess: boolean = true;
          const successBNoList = responBnoList.map(item => item.bNo);
          this.gridBookingTransfer.listStore.store.forEach((gridRow, index) => {
            if (successBNoList.includes(gridRow.bNo)) {
              countSuccess++;
              this.gridBookingTransfer.listStore.store[index]["rMessage"] = "- Transferred Successfully";
            } else {
              isAllRecordTransferSuccess = false;
              this.gridBookingTransfer.listStore.store[index]["rMessage"] = "<font color=red>- Transferred Failed</font>";
            }
          });

          if(!isAllRecordTransferSuccess){
            if(countSuccess > 0){
              this.message(
                "information",
                "Information",
                "Booking(s) transferred partially. Please refer to 'Result Message' for details.",
                "okonly",
                { ok: "" }
              );
            }
          }else{
            this.message(
              "information",
              "Information",
              "Booking(s) transferred successfully.",
              "okonly",
              { ok: "" }
            );
          }

        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "There are some error occur while saving the record, error message : " +
              datas +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
      
      
    }
  }

  checkParent(
    parentList: string[],
    allotmentCode: string,
    masterBookings: any
  ) {
    let result: Boolean = false;

    if ("VS" != allotmentCode) {
      let hit: Number = -1;

      $(parentList).each(function (i, parent) {
        //// console.log("#parent : " + parent);

        if (parent != "") {
          if (hit == 0) {
            return false;
          }

          if ("VS" == parent) {
            hit = 1;
          } else if (
            parent.startsWith("PD") &&
            (parent.replace("PD-", "") == masterBookings.bPodCode ||
              parent.replace("PD-", "") == masterBookings.bPotCode)
          ) {
            //// console.log("check PD- " + parent + " - " + masterBookings.bPodCode);
            hit = 1;
          } else if (
            "EMPTY" == parent &&
            "EMPTY" == masterBookings.bContainerStatus
          ) {
            hit = 1;
          } else if (
            "LADEN" == parent &&
            "FULL" == masterBookings.bContainerStatus
          ) {
            hit = 1;
          } else if (
            "SOC" == parent &&
            ("SOC" == masterBookings.bCntOwner ||
              "SOCTS" == masterBookings.bCntOwner)
          ) {
            hit = 1;
          } else if (
            "SOC LOCAL" == parent &&
            "SOC" == masterBookings.bCntOwner
          ) {
            hit = 1;
          } else if (
            "SOC T/S" == parent &&
            "SOCTS" == masterBookings.bCntOwner
          ) {
            hit = 1;
          } else if (
            "COC" == parent &&
            ("COC" == masterBookings.bCntOwner ||
              "COCTS" == masterBookings.bCntOwner)
          ) {
            hit = 1;
          } else if (
            "COC LOCAL" == parent &&
            "COC" == masterBookings.bCntOwner
          ) {
            hit = 1;
          } else if (
            "COC T/S" == parent &&
            "COCTS" == masterBookings.bCntOwner
          ) {
            hit = 1;
          } else if (
            parent.startsWith("SS") &&
            parent.replace("SS-", "") == masterBookings.bSlotOperatorCode &&
            "SLOT" == masterBookings.bCntOwner
          ) {
            hit = 1;
          } else {
            hit = 0;
          }
        }
      });

      if (hit == 1) {
        result = true;
      }
    } else {
      result = true;
    }
    //// console.log("result : " + result);
    return result;
  }

  checkNojan(parent: any, data: any, store: any, idx) {
    //get allocation list level 2
    // console.log("Parent :"+parent);
    // console.log(store);
    var mainAllocation = ([] = this.getChildListNew(parent, store));
    var childStore = mainAllocation;
    var checker = 0;

    //if(parent == '1')
    //store.required = parseInt(store.required)+parseInt(data.bookTeus);
    var chkOwner = "";

    if (data.bCntOwner == "SOC" || data.bCntOwner == "COC") {
      chkOwner = data.bCntOwner + " LOCAL";
    } else if (data.bCntOwner == "SOCTS") {
      chkOwner = "SOC T/S";
    } else if (data.bCntOwner == "COCTS") {
      chkOwner = "COC T/S";
    }
    // console.log("container owner "+data.bCntOwner);
    // console.log("container owner SLOT"+data.bSlotOperatorCode);
    var checkPD = "";
    if (data.bPotCode != "") {
      checkPD = data.bPotCode;
    } else {
      checkPD = data.bPodCode;
    }
    var statusCnt = "";
    if (data.bContainerStatus == "FULL") {
      statusCnt = "LADEN";
    } else {
      statusCnt = "EMPTY";
    }

    mainAllocation.forEach((fe) => {
      if (checker == 0) {
        // console.log("level : "+fe.alloted + " code : "+ fe.allotmentCode);
        if (this.countChildNew(fe.allotmentLevel, store) == 0) {
          // console.log(fe.allotmentCode + " : allocation nggak ada anaknya");
          if (fe.allotmentCode.substring(0, 3) == "SOC") {
            if (chkOwner == fe.allotmentCode) {
              // console.log("allocaton ada");
              checker = 1;
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              // console.log('difference soc'+fe.difference);
              if (fe.alloted == 0 && fe.difference < 0) {
                this.exceedBook[idx] = data.bNo;
                // console.log(this.exceedBook)
              }
            } else if (fe.allotmentCode == "SOC") {
              if (fe.allotmentCode == chkOwner.substring(0, 3)) {
                checker = 1;
                fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
                fe.difference = parseInt(fe.available) - parseInt(fe.required);
                if (fe.alloted == 0 && fe.difference < 0)
                  this.exceedBook[idx] = data.bNo;
              }
            }
          } else if (fe.allotmentCode.substring(0, 3) == "COC") {
            // console.log('masuk nih coc hayo')
            if (chkOwner == fe.allotmentCode) {
              // console.log("allocaton ada COC1");
              checker = 1;
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
            } else if (fe.allotmentCode == "COC") {
              if (fe.allotmentCode == chkOwner.substring(0, 3)) {
                checker = 1;
                fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
                fe.difference = parseInt(fe.available) - parseInt(fe.required);
                if (fe.alloted == 0 && fe.difference < 0)
                  this.exceedBook[idx] = data.bNo;
              }
            }
          } else if (fe.allotmentCode.startsWith("PD")) {
            if (checkPD == fe.allotmentCode.split("-")[1]) {
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              // console.log("allocaton ada");
              checker = 1;
            }
          } else if (fe.allotmentCode.startsWith("SS")) {
            if (data.bSlotOperatorCode == fe.allotmentCode.split("-")[1]) {
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              // console.log("allocaton ada");
              checker = 1;
            }
          } else if (
            fe.allotmentCode == "LADEN" ||
            fe.allotmentCode == "EMPTY"
          ) {
            if (statusCnt == fe.allotmentCode) {
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              // console.log("allocaton ada");
              checker = 1;
            }
          }
        } else {
          // console.log(fe.allotmentCode + " : allocation ada anaknya");
          if (fe.allotmentCode.substring(0, 3) == "SOC") {
            if (chkOwner.substring(0, 3) == "SOC") {
              //teusonsole.log('req teus final'+fe.requiredTeus);
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              // console.log('parent fe'+fe.required)
              // console.log('difference soc'+fe.difference);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              // console.log('parent fe'+fe.required)
              if (this.checkNojan(fe.allotmentLevel, data, store, idx) == 1) {
                checker = 1;
              }
            }
          } else if (fe.allotmentCode.substring(0, 3) == "COC") {
            if (chkOwner.substring(0, 3) == "COC") {
              //teusonsole.log('req teus final'+fe.requiredTeus);
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;

              if (this.checkNojan(fe.allotmentLevel, data, store, idx) == 1) {
                checker = 1;
              }
            }
          } else if (fe.allotmentCode.startsWith("PD")) {
            if (checkPD == fe.allotmentCode.split("-")[1]) {
              //teus
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              // console.log('difference soc'+fe.difference);
              if (fe.alloted == 0 && fe.difference < 0) {
                // console.log('masuk diff');
                // console.log(data.bNo)
                this.exceedBook[idx] = data.bNo;
                // console.log(this.exceedBook)
              }
              if (this.checkNojan(fe.allotmentLevel, data, store, idx) == 1) {
                checker = 1;
              }
            }
          } else if (fe.allotmentCode.startsWith("SS")) {
            if (data.bSlotOperatorCode == fe.allotmentCode.split("-")[1]) {
              //teus
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              if (this.checkNojan(fe.allotmentLevel, data, store, idx) == 1) {
                checker = 1;
              }
            }
          } else if (
            fe.allotmentCode == "LADEN" ||
            fe.allotmentCode == "EMPTY"
          ) {
            if (statusCnt == fe.allotmentCode) {
              //teus
              fe.required = parseInt(fe.required) + parseInt(data.bookTeus);
              fe.difference = parseInt(fe.available) - parseInt(fe.required);
              if (fe.alloted == 0 && fe.difference < 0)
                this.exceedBook[idx] = data.bNo;
              if (this.checkNojan(fe.allotmentLevel, data, store, idx) == 1) {
                checker = 1;
              }
            }
          }
        }
      }
    });

    return checker;
  }

  getChildListNew(parentLevel: string, store: any) {
    var result = [];
    store.forEach((fe) => {
      if (
        fe.allotmentLevel.startsWith(parentLevel) &&
        fe.allotmentLevel.split(".").length == parentLevel.split(".").length + 1
      ) {
        result.push(fe);
      }
    });

    return result;
  }

  countChildNew(parentLevel: string, store: any) {
    var total = 0;
    store.forEach((fe) => {
      if (
        fe.allotmentLevel.startsWith(parentLevel) &&
        fe.allotmentLevel.split(".").length == parentLevel.split(".").length + 1
      ) {
        total++;
      }
    });

    return total;
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field);
  }

  backspaceValue(event: any, regexName: RegExp, field: string) {
    if(event.target.value === null || !event.target.value){
      switch(field){
        case 'vesselIdOrigin':
          this.cbVoyageOrigin.setValue("");
          this.cbVoyageOrigin.setUrl("");
          break;
        case 'vesselIdDestination':
          this.cbVoyageDestination.setValue("");
          this.cbVoyageDestination.setUrl("");
          break;
      }
    }
    this.showErrorMessage(regexName, event.target.value, field);
  }

  showErrorMessage(regexName, pastedText, field) {
    const fileName = (field === 'vesselIdOrigin' || field === 'vesselIdDestination') ? 'Vessel' : 'Voyage';
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${fileName} 'Only accept alphanumeric and .-/`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

}
