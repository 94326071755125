<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      id="tPlug"
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div class="ui error message" [class.hidden]="isError == false">
      <ul
        [style.display]="
          errorDuplicatePreLoop == ''
            ? 'none'
            : errorDuplicatePreLoop == null
            ? 'none'
            : errorDuplicatePreLoop == undefined
            ? 'none'
            : 'block'
        "
      >
        <li>
          {{ errorDuplicatePreLoop }}
        </li>
      </ul>
      <ul [style.display]="isErrorSpcChar ? 'block' : 'none'">
        <li *ngFor="let err of errorSpecialChar">
          {{ err }}
        </li>
      </ul>
    </div>

    <div [style.display]="modeForm == 'flagStatus' ? 'block' : 'none'">
      <h4
        class="ui dividing header"
        style="text-align: center; margin-top: 10px"
      >
        Master Schedule
      </h4>
      <!-- Begin of Grid -->
      <grid-plugin2
        id="mainGrid"
        #grid
        [settings]="settingGrid"
        [gridEvent]="setGridEvent"
        (gridEvent)="gridEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin2>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm == 'schedule' ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 5%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="myDialog" class="ui small first modal">
          <div class="header">
            <div><i class="info circle big icon"></i> &nbsp; Information</div>
          </div>
          <div class="content">
            <p>{{ messageDialog }}</p>
          </div>
          <div class="actions">
            <div
              class="ui positive right labeled icon yes button"
              (click)="resetService(true)"
            >
              Yes
              <i class="checkmark icon"></i>
            </div>
            <div class="ui negative button" (click)="resetService(false)">
              No
            </div>
          </div>
        </div>

        <div id="myDialog2" class="ui small first modal">
          <div class="header">
            <div><i class="info circle big icon"></i> &nbsp; Information</div>
          </div>
          <div class="content">
            <span [innerHTML]="messageDialog2"></span>
          </div>
          <div class="actions">
            <div class="ui positive right labeled icon button">
              OK
              <i class="checkmark icon"></i>
            </div>
          </div>
        </div>

        <!-- div id="testDialog" style="margin-right:400px" [style.display]="modeSequence?'block':'none'" -->
        <div id="testDialog" class="ui small modal" style="margin-right: 400px">
          <div
            class="ui raised segment"
            style="padding-left: 1%; padding-right: 1%"
          >
            <label>Sequence Editor</label>
            <br /><br />
            <div class="ui icon tiny buttons">
              <button
                id="moveUp"
                class="ui button"
                data-content="move up"
                (click)="moveUpSeq()"
              >
                <i class="arrow circle up icon"></i>
              </button>
              <button
                id="moveDown"
                class="ui button"
                data-content="move down"
                (click)="moveDownSeq()"
              >
                <i class="arrow circle down icon"></i>
              </button>
            </div>
            <input type="hidden" id="sequenceChecked" value="0" />
            <grid-plugin2
              id="gridSequenceEditor"
              #gridSequence
              [settings]="settingSequence"
              [gridEvent]="setSeqEvent"
              (gridEvent)="seqEvent($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin2>
            <br />
            <button class="mini ui button" (click)="updateSequence()">
              OK
            </button>
            <button class="mini ui button" (click)="closeSequence()">
              Cancel
            </button>
          </div>
        </div>

        <div
          id="addRowDialogVessel"
          class="ui small modal"
          style="margin-right: 400px; padding: 1%"
        >
          <label>Insert port of rotation</label>
          <br />
          <div
            class="ui raised segment"
            style="padding-left: 2%; padding-right: 2%"
          >
            <table width="100%">
              <tbody>
                <tr valign="top">
                  <td width="10%">
                    <i class="huge help circle outline icon"></i>
                  </td>
                  <td width="90%">
                    <div class="ui form">
                      <div class="grouped fields">
                        <label>Where to insert the record?</label>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              #bfrSeq
                              type="radio"
                              name="rowChoose"
                              value="{{ before }}"
                              (change)="
                                setInsertSeqRadio(
                                  bfrSeq.checked,
                                  'before',
                                  bfrSeq.value
                                )
                              "
                            />
                            <label>Before seq. {{ before }}</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui radio checkbox">
                            <input
                              #aftSeq
                              type="radio"
                              name="rowChoose"
                              value="{{ after }}"
                              (change)="
                                setInsertSeqRadio(
                                  aftSeq.checked,
                                  'after',
                                  aftSeq.value
                                )
                              "
                            />
                            <label>After seq. {{ after }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr align="center">
                  <td colspan="2">
                    <button class="mini ui button" (click)="rowChoose('no')">
                      No
                    </button>
                    <button class="mini ui button" (click)="rowChoose('yes')">
                      Yes
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          id="copyDialogVessel"
          class="ui small modal"
          style="margin-right: 400px; padding: 1%"
        >
          <!-- now working -->
          <label>Vessel Schedule Copy Function</label>
          <br /><br />
          <div
            class="ui raised segment"
            style="padding-left: 2%; padding-right: 2%"
          >
            <div class="ui form">
              <div class="ui grid">
                <div class="row">
                  <div class="fourteen wide column">
                    <div class="field">
                      <label>Vessel Name</label>
                      <input
                        placeholder="Vessel Name"
                        type="text"
                        name="vscVesselName"
                        value="{{ model.vesselName }}"
                        readonly=""
                      />
                    </div>
                    <div class="field">
                      <label>Voyage</label>
                      <input
                        placeholder="Voyage"
                        type="text"
                        name="vscVesselVoyage"
                        value="{{ model.baseVoyage }}"
                        readonly=""
                      />
                    </div>
                    <div class="ui icon tiny buttons">
                      <button
                        class="ui button"
                        data-content="Add"
                        (click)="addCopyRow('')"
                      >
                        <i class="plus icon"></i>
                      </button>
                      <button
                        class="ui button"
                        data-content="Remove"
                        (click)="removeCopyRow('')"
                      >
                        <i class="minus icon"></i>
                      </button>
                    </div>
                    <br />
                    <div *ngFor="let msg of dCopyMessage">
                      <div
                        [style.display]="msg.status == '1' ? 'block' : 'none'"
                        class="
                          ui
                          basic
                          green
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          msg.status != null &&
                          msg.status != '' &&
                          msg.status == '1'
                        "
                      >
                        <span [innerHTML]="msg.message"></span>
                      </div>
                      <div
                        [style.display]="msg.status == '1' ? 'none' : 'block'"
                        class="
                          ui
                          basic
                          red
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          msg.status != null &&
                          msg.status != '' &&
                          msg.status == '0'
                        "
                      >
                        <span [innerHTML]="msg.message"></span>
                      </div>
                    </div>
                    <br />
                    <div
                      id="editable"
                      style="
                        width: 100%;
                        height: 200px;
                        overflow-y: scroll;
                        border: solid;
                        border-width: 0.5px;
                      "
                    >
                      <table
                        class="
                          ui
                          sortable
                          selectable
                          compact
                          celled
                          definition
                          striped
                          table
                        "
                        [class.disabled]="lock"
                      >
                        <thead class="full-width" style="font-size: 12px">
                          <tr>
                            <th width="5%"></th>
                            <th
                              *ngFor="let col of gridCopySetting.columns"
                              width="{{ col.width }}%"
                            >
                              {{ col.header }}
                            </th>
                          </tr>
                        </thead>
                        <tbody style="font-size: 12px">
                          <tr
                            style="cursor: pointer"
                            *ngFor="let data of copyStore.store"
                          >
                            <td>
                              <input
                                #select
                                name="copyCheckbox"
                                value="{{ data.id }}"
                                type="checkbox"
                              />
                            </td>
                            <td
                              *ngFor="let col of gridCopySetting.columns"
                              id="s.{{ data.id }}.{{ col.field }}"
                              (dblclick)="
                                onDoubleClick(
                                  data.id,
                                  col.field,
                                  data[col.field],
                                  col.type,
                                  'copyDialogVessel'
                                )
                              "
                            >
                              <span>{{ data[col.field] }}</span>
                              <input
                                type="text"
                                value="{{ data[col.field] }}"
                                style="display: none"
                                (blur)="
                                  textBlur(
                                    data.id,
                                    col.field,
                                    data[col.field],
                                    'copyDialogVessel'
                                  )
                                "
                                (keypress)="
                                  textKeyPress(
                                    $event,
                                    data.id,
                                    col.field,
                                    data[col.field],
                                    'copyDialogVessel'
                                  )
                                "
                              />
                              <div class="fields" style="display: none">
                                <div class="field">
                                  <select
                                    class="syear"
                                    style="
                                      font-size: 10px;
                                      padding: 3px;
                                      width: inherit;
                                    "
                                  >
                                    <option
                                      *ngFor="let year of yearList"
                                      value="{{ year }}"
                                    >
                                      {{ year }}
                                    </option>
                                  </select>
                                  <button
                                    class="mini ui button"
                                    (click)="
                                      divBlur(
                                        data.id,
                                        col.field,
                                        data[col.field],
                                        'copyDialogVessel'
                                      )
                                    "
                                    style="
                                      font-size: 10px;
                                      width: 43px;
                                      padding: 3px;
                                      margin-top: 5px;
                                    "
                                  >
                                    ok
                                  </button>
                                </div>
                                <div class="field">
                                  <select
                                    class="smonth"
                                    style="
                                      font-size: 10px;
                                      padding: 3px;
                                      width: inherit;
                                    "
                                  >
                                    <option
                                      *ngFor="let mnth of monthsList2"
                                      value="{{ mnth }}"
                                    >
                                      {{ mnth }}
                                    </option>
                                  </select>
                                </div>
                                <div class="field">
                                  <select
                                    class="sday"
                                    style="
                                      font-size: 10px;
                                      padding: 3px;
                                      width: inherit;
                                    "
                                  >
                                    <option
                                      *ngFor="let day of dayList"
                                      value="{{ day }}"
                                    >
                                      {{ day }}
                                    </option>
                                  </select>
                                </div>
                                <div class="field">
                                  <select
                                    class="shour"
                                    style="
                                      font-size: 10px;
                                      padding: 3px;
                                      width: inherit;
                                    "
                                  >
                                    <option
                                      *ngFor="let hh of hourList"
                                      value="{{ hh }}"
                                    >
                                      {{ hh }}
                                    </option>
                                  </select>
                                </div>
                                <div class="field">
                                  <select
                                    class="sminute"
                                    style="
                                      font-size: 10px;
                                      padding: 3px;
                                      width: inherit;
                                    "
                                  >
                                    <option
                                      *ngFor="let mm of minuteList"
                                      value="{{ mm }}"
                                    >
                                      {{ mm }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="two wide column">
                    <div class="field">
                      <label [innerHTML]="space"></label>
                      <button
                        class="mini ui button"
                        (click)="doCopy()"
                        style="width: 80px"
                      >
                        Do Copy
                      </button>
                      <button
                        class="mini ui button"
                        (click)="doCancel()"
                        style="width: 80px"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="container">
          <!-- Begin of Form -->
          <form class="ui mini form" (keypress)="sombre($event)">
            <!-- h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Vessel Schedule Master</h4 -->
            <dialog-plugin
              [settings]="dialog"
              (eventDialog)="eventMessage($event)"
            ></dialog-plugin>

            <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->

            <!-- div class="ui toggle checkbox">
                            <input type="checkbox" name="model[isValid]" [checked]="model.isValid" [(ngModel)]="model['isValid']" [disabled]="lock">
                            <label class="ui tiny label" style="Background-color:transparent">Valid</label>
                        </div -->
            <div
              class="ui raised segment"
              style="
                height: 230px;
                margin-bottom: 0px;
                margin-right: -59px;
                margin-top: -10px;
              "
            >
              <label class="ui red ribbon mini label">Schedule Details</label>

              <div class="ui equal width padded grid">
                <div class="row">
                  <div class="column">
                    <!-- div class="two fields">
                                            <div class="ten wide field">
                                                <button id="retriveBtn" class="mini ui button" (click)="retrieveData();" [class.disabled]="createLock">Retrieve</button>
                                            </div>
                                            <div class="six wide field">

                                            </div>
                                        </div -->
                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-top: -10px;
                        margin-bottom: 4px;
                        margin-left: -21px;
                        margin-right: 100px;
                      "
                    >
                      <div class="ten wide field">
                        <div
                          class="required field"
                          [class.error]="
                            model['error-vesselName'] != null &&
                            model['error-vesselName'] != ''
                          "
                        >
                          <label style="font-size: 10px">Vessel Name</label>
                          <!-- input placeholder="Vessel Name" type="text" name="model[vesselName]" -->
                          <combo-plugin
                            #sopan
                            [settings]="settingVName"
                            (change)="getVName($event)"
                            style="width: 100%"
                            [disableCombo]="createLock"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'vesselName')"
                          ></combo-plugin>
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-vesselName'] != null &&
                              model['error-vesselName'] != ''
                            "
                          >
                            {{ model["error-vesselName"] }}
                          </div>
                          <!-- div class = "ui basic red pointing prompt label transition error-message-hidden" id="errTxt-vesselName" [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                                                        <p [innerHtml]="model['error-vesselName']"></p>
                                                    </div -->
                          <!-- <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-vesselName'] != null &&
                              model['error-vesselName'] != ''
                            "
                          >
                            {{ model["error-vesselName"] }}
                          </div> -->
                        </div>
                      </div>
                      <div class="six wide field">
                        <label style="font-size: 10px">PSA Abbr.Name</label>
                        <input
                          placeholder="PSA Abbr.Name"
                          type="text"
                          name="model[PSAAbbrName]"
                          value="{{ model.psaVesselCode }}"
                          readonly=""
                        />
                      </div>
                      <!-- div class="six wide field">
                                                <label style="font-size:9px">Vessel Code</label>
                                                <input placeholder="Vessel Code" type="text" name="model[vesselCode]" value="{{model.vesselCode}}" readonly="">
                                            </div -->
                    </div>

                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-bottom: -11px;
                        margin-left: -21px;
                        margin-right: 43px;
                      "
                    >
                      <div class="ten wide field" style="margin-top: 12px">
                        <div class="inline fields">
                          <div class="field">
                            <div class="ui radio checkbox">
                              <!--input #visssl id="ssl" type="radio" name="model[vesselTypeV]" [disabled]="lock" (change)="radioChange('ssl', visssl.checked)"-->
                              <input
                                #visssl
                                id="ssl"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeV]"
                                (change)="radioChange('ssl', visssl.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >SSL</label
                              >
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <!--input #visslot id="slot" type="radio" name="model[vesselTypeV]" [disabled]="lock" (change)="radioChange('slot', visslot.checked)"-->
                              <input
                                #visslot
                                id="slot"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeV]"
                                (change)="radioChange('slot', visslot.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >Slot</label
                              >
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox" style="width: 79px">
                              <!--input #vis3p id="3rd" type="radio" name="model[vesselTypeV]" [disabled]="lock" (change)="radioChange('3p', vis3p.checked)"-->
                              <input
                                #vis3p
                                id="3rd"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeV]"
                                (change)="radioChange('3p', vis3p.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >3rd Party</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="six wide field">
                        <label style="font-size: 10px">Slot Owner</label>
                        <div class="three fields">
                          <div class="field">
                            <input
                              type="text"
                              name="model[slotOwner1]"
                              [readonly]="modeSlot"
                              [(ngModel)]="model['vesselSlotOwner1']"
                              maxlength="2"
                              autocomplete="off"
                              style="font-size: 9px; width: 30px"
                            />
                          </div>
                          <div class="field">
                            <input
                              type="text"
                              name="model[slotOwner2]"
                              [readonly]="modeSlot"
                              [(ngModel)]="model['vesselSlotOwner2']"
                              maxlength="2"
                              autocomplete="off"
                              style="font-size: 9px; width: 30px"
                            />
                          </div>
                          <div class="field">
                            <input
                              type="text"
                              name="model[slotOwner3]"
                              [readonly]="modeSlot"
                              [(ngModel)]="model['vesselSlotOwner3']"
                              maxlength="2"
                              autocomplete="off"
                              style="font-size: 9px; width: 30px"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-top: -31px;
                        margin-left: -21px;
                        margin-right: 19px;
                      "
                    >
                      <div class="fifteen wide field" style="padding-top: 5%">
                        <label style="font-size: 10px">Remarks PSA</label>
                        <input
                          placeholder="remarks PSA"
                          type="text"
                          name="model[remarkPSA]"
                          [readonly]="connectionLock"
                          [(ngModel)]="model['remarks']"
                          autocomplete="off"
                          maxlength="200"
                        />
                      </div>
                      <div class="one wide field"></div>
                    </div>
                    <div
                      class="ui toggle checkbox"
                      style="margin-top: 7px; margin-left: -16px"
                    >
                      <input
                        type="checkbox"
                        name="model[isValid]"
                        [checked]="model.isValid"
                        [(ngModel)]="model['isValid']"
                        [disabled]="connectionLock"
                      />
                      <label
                        class="ui tiny label"
                        style="background-color: transparent"
                        >Valid</label
                      >
                    </div>
                  </div>
                  <div class="column">
                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-top: -10px;
                        margin-left: -120px;
                        margin-right: 175px;
                      "
                    >
                      <div class="six wide field">
                        <label style="font-size: 9px">Vessel Code</label>
                        <input
                          placeholder="Vessel Code"
                          type="text"
                          name="model[vesselCode]"
                          value="{{ model.vesselCode }}"
                          readonly=""
                        />
                      </div>
                      <div
                        class="required field"
                        [class.error]="
                          model['error-baseVoyage'] != null &&
                          model['error-baseVoyage'] != ''
                        "
                      >
                        <!-- div
                          class="required field"
                          [class.error]="
                            model['error-baseVoyage'] != null &&
                            model['error-baseVoyage'] != ''
                          "
                        -->
                        <label style="font-size: 10px">Base Voyage</label>
                        <table border="0" width="100%">
                          <tr>
                            <td style="width: 90%">
                              <input
                                [style.display]="
                                  bsVoyMode == 'new' ? 'block' : 'none'
                                "
                                placeholder="Base Voyage"
                                type="text"
                                name="model[baseVoyage]"
                                [(ngModel)]="model['baseVoyage']"
                                (ngModelChange)="checkIfError('baseVoyage')"
                                (blur)="baseVoyCheck()"
                                [readonly]="createLock"
                                autocomplete="off"
                                maxlength="20"
                                (keypress)="
                                  formValidation.ModifiedPattern($event, '-./ ')
                                "
                                (paste)="errorVesVoy($event, 'baseVoyage')"
                              />
                              <div
                                [style.display]="
                                  bsVoyMode == 'ext' ? 'block' : 'none'
                                "
                                class="ui selection dropdown"
                                id="idBsVoyage"
                                [class.disabled]="createLock"
                                style="font-size: 9px"
                              >
                                <input
                                  #cbBaseVoy
                                  type="hidden"
                                  name="model[baseVoyage]"
                                  [(ngModel)]="model['baseVoyage']"
                                  (ngModelChange)="checkIfError('baseVoyage')"
                                  (change)="bsVoyageAction(cbBaseVoy.value)"
                                />
                                <i class="dropdown icon"></i>
                                <div class="default text">Base Voyage</div>
                                <div class="menu"></div>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <div
                          class="
                            ui
                            basic
                            red
                            pointing
                            prompt
                            label
                            transition
                            error-message-hidden
                          "
                          [class.error-message-visible]="
                            model['error-baseVoyage'] != null &&
                            model['error-baseVoyage'] != ''
                          "
                        >
                          {{ model["error-baseVoyage"] }}
                        </div>
                        <!-- div class = "ui basic red pointing prompt label transition error-message-hidden" id="errTxt-baseVoyage" [class.error-message-visible]="model['error-baseVoyage'] != null && model['error-baseVoyage'] != ''">
                                                        <p [innerHtml]="model['error-baseVoyage']"></p>
                                                    </div -->
                        <!-- div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-baseVoyage'] != null &&
                              model['error-baseVoyage'] != ''
                            "
                          >
                            
                            {{ model["error-baseVoyage"] }}
                          </div-->
                        <!-- /div -->
                      </div>
                      <!-- div class="six wide field">
                                                <label style="font-size:10px">PSA Abbr.Name</label>
                                                <input placeholder="PSA Abbr.Name" type="text" name="model[PSAAbbrName]" value="{{model.psaVesselCode}}" readonly="">
                                            </div -->
                      <div class="new-field-bound">
                        <label class="bound-label">Bound</label>
                        <input type="text" maxlength="1" [(ngModel)]="model['sBound']" [readOnly]="isReadOnlyBound"
                        (ngModelChange)="changesBound($event)" name="Bound" placeholder="Bound"
                        (keypress)="formValidation.AlphabetsOnlyWOSpace($event)">
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            model['error-sBound'] != null &&
                            model['error-sBound'] != ''">
                          {{ model["error-sBound"] }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-left: -73px;
                        margin-right: 100px;
                      "
                    >
                      <div
                        class="ten wide field"
                        [class.error]="
                          model['error-serviceCode'] != null &&
                          model['error-serviceCode'] != ''
                        "
                      >
                        <label style="font-size: 10px">Service</label>
                        <!-- input placeholder="Service" type="text" name="model[service]" -->
                        <combo-plugin
                          #cbservice
                          [settings]="settingService"
                          [disableCombo]="serviceLock"
                          (change)="getService($event)"
                          style="width: 100%"
                        ></combo-plugin>
                      </div>
                      <div class="six wide field">
                        <label style="font-size: 10px">Seq</label>
                        <input
                          placeholder="Seq"
                          type="text"
                          name="model[Seq]"
                          [(ngModel)]="model['serviceSequence']"
                          readonly=""
                        />
                        <!-- div class="ui selection dropdown" id="idSeq" [class.disabled]="serviceLock">
                                                    <input #cbSrvSeq type="hidden" name="model[Seq]" [(ngModel)]="model['serviceSequence']" (change)="onOuw(cbSrvSeq.value)">
                                                    <i class="dropdown icon"></i>
                                                    <div class="default text">select seq</div>
                                                    <div class="menu">
                                                    </div>
                                                </div -->
                      </div>
                    </div>
                    <div
                      class="two fields"
                      style="
                        margin: auto;
                        margin-top: 8px;
                        margin-left: -73px;
                        margin-right: 100px;
                      "
                    >
                      <div class="ten wide field" style="padding-top: 5%">
                        <div class="inline fields">
                          <div class="field">
                            <div class="ui radio checkbox">
                              <!-- input #sissl id="Sssl" type="radio" name="model[vesselTypeS]" [disabled]="lock" (change)="radioChange2('sssl', sissl.checked)" -->
                              <input
                                #sissl
                                id="Sssl"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeS]"
                                (change)="radioChange2('sssl', sissl.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >SSL</label
                              >
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <!-- input #sislot id="Sslot" type="radio" name="model[vesselTypeS]" [disabled]="lock" (change)="radioChange2('sslot', sislot.checked)" -->
                              <input
                                #sislot
                                id="Sslot"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeS]"
                                (change)="radioChange2('sslot', sislot.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >Slot</label
                              >
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox" style="width: 79px">
                              <!-- input #s3p id="S3rd" type="radio" name="model[vesselTypeS]" [disabled]="lock" (change)="radioChange2('s3p', s3p.checked)" -->
                              <input
                                #s3p
                                id="S3rd"
                                type="radio"
                                [readonly] = "modeViewOnly"
                                name="model[vesselTypeS]"
                                (change)="radioChange2('s3p', s3p.checked)"
                              />
                              <label
                                class="ui mini label"
                                style="background-color: transparent"
                                >3rd Party</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="six wide field"></div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="three fields">
                      <div
                        class="field"
                        style="
                          margin-left: -117px;
                          margin-right: 7px;
                          width: 135px;
                        "
                      >
                        <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->
                        <div
                          class="ui raised segment"
                          style="margin-top: -45px; width: 127px; height: 161px"
                        >
                          <label class="ui red ribbon mini label"
                            >Service Loop</label
                          ><br />
                          <label style="font-size: 10px">Prev Loop No</label>
                          <input
                            id="prevLoopId"
                            placeholder="Prev loop No"
                            type="text"
                            name="model[prevLoop]"
                            [readonly]="connectionLock"
                            [(ngModel)]="model['servicePreviousVoyage']"
                            (ngModelChange)="
                              checkIfError('servicePreviousVoyage')
                            "
                            maxlength="20"
                            (change)="updateConnection('servicePreviousVoyage')"
                            (blur)="updateDetails('servicePreviousVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="
                              errorVesVoy($event, 'servicePreviousVoyage')
                            "
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-servicePreviousVoyage'] != null &&
                              model['error-servicePreviousVoyage'] != ''
                            "
                          >
                            {{ model["error-servicePreviousVoyage"] }}
                          </div>
                          <!-- combo-plugin id="pln" #cbpLp [settings]="settingPLoop" (change)="getPLoop($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin -->
                          <!-- div class="ui selection mini dropdown" id="idPrevLoop" [class.disabled]="createLock" style="width:100%;font-size:9px">
                                                        <input #cbPrevLoop type="hidden" name="model[prevLoop]" [(ngModel)]="model['servicePreviousVoyage']" (change)="bsSloopAction(cbPrevLoop.value, 'prev')">
                                                        <i class="dropdown icon"></i>
                                                        <div class="default text">Prev Loop No</div>
                                                        <div class="menu">
                                                        </div>
                                                    </div -->

                          <label style="font-size: 10px">Next Loop No</label>
                          <input
                            id="nextLoopId"
                            placeholder="Next Loop No"
                            type="text"
                            name="model[nextLoop]"
                            [readonly]="nextConnectionLock"
                            [(ngModel)]="model['serviceNextVoyage']"
                            (ngModelChange)="checkIfError('serviceNextVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'serviceNextVoyage')"
                            maxlength="20"
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label label-abs
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-serviceNextVoyage'] != null &&
                              model['error-serviceNextVoyage'] != ''
                            "
                          >
                            {{ model["error-serviceNextVoyage"] }}
                          </div>
                          <!-- div class="ui selection dropdown" id="idNextLoop" [class.disabled]="createLock" style="width:100%;font-size:9px">
                                                        <input #cbNextLoop type="hidden" name="model[nextLoop]" [(ngModel)]="model['serviceNextVoyage']" (change)="bsSloopAction(cbNextLoop.value, 'next')">
                                                        <i class="dropdown icon"></i>
                                                        <div class="default text">Next Loop No</div>
                                                        <div class="menu">
                                                        </div>
                                                    </div -->
                          <!-- /fieldset -->
                        </div>
                      </div>
                      <div class="field" style="width: 175px">
                        <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->
                        <div
                          class="ui raised segment"
                          style="margin-top: -45px; width: 127px"
                        >
                          <label class="ui red ribbon mini label"
                            >PSA Voyage</label
                          ><br />

                          <label style="font-size: 10px">In Voyage</label>
                          <input
                            maxlength="20"
                            placeholder="In Voyage"
                            type="text"
                            name="model[inVoyage]"
                            [readonly]="connectionLock"
                            [(ngModel)]="model['psaInVoyage']"
                            (ngModelChange)="checkIfError('psaInVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'psaInVoyage')"
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-psaInVoyage'] != null &&
                              model['error-psaInVoyage'] != ''
                            "
                          >
                            {{ model["error-psaInVoyage"] }}
                          </div>

                          <label style="font-size: 10px">Out Voyage</label>
                          <input
                            maxlength="20"
                            placeholder="Out Voyage"
                            type="text"
                            name="model[outVoyage]"
                            [readonly]="connectionLock"
                            [(ngModel)]="model['psaOutVoyage']"
                            (ngModelChange)="checkIfError('psaOutVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'psaOutVoyage')"
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label label-abs
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-psaOutVoyage'] != null &&
                              model['error-psaOutVoyage'] != ''
                            "
                          >
                            {{ model["error-psaOutVoyage"] }}
                          </div>
                          <i style="color: gray; font-size: 9px"
                            >Applicable only in Singapore</i
                          >
                        </div>
                        <!-- /fieldset -->
                      </div>
                      <div class="field" style="width: 225px">
                        <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->
                        <div
                          class="ui raised segment"
                          style="margin-top: -45px; height: 160px"
                        >
                          <label class="ui red ribbon mini label"
                            >Connection Flow</label
                          ><br />

                          <label style="font-size: 10px"
                            >Prev Base Voyage</label
                          >
                          <input
                            maxlength="20"
                            id="prevBaseVoyId"
                            placeholder="Prev Base Voy"
                            type="text"
                            name="model[prevBase]"
                            [readonly]="connectionLock"
                            [(ngModel)]="model['previousVoyage']"
                            (ngModelChange)="checkIfError('previousVoyage')"
                            (blur)="updateDetails('PreviousVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'previousVoyage')"
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-previousVoyage'] != null &&
                              model['error-previousVoyage'] != ''
                            "
                          >
                            {{ model["error-previousVoyage"] }}
                          </div>

                          <label style="font-size: 10px"
                            >Next Base Voyage</label
                          >
                          <input
                            maxlength="20"
                            placeholder="Next Base Voy"
                            type="text"
                            name="model[nextBase]"
                            [readonly]="nextConnectionLock"
                            [(ngModel)]="model['nextVoyage']"
                            (ngModelChange)="checkIfError('nextVoyage')"
                            autocomplete="off"
                            (keypress)="
                              formValidation.ModifiedPattern($event, '-./ ')
                            "
                            (paste)="errorVesVoy($event, 'nextVoyage')"
                          />
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label label-abs
                              transition
                              error-message-hidden
                            "
                            [class.error-message-visible]="
                              model['error-nextVoyage'] != null &&
                              model['error-nextVoyage'] != ''
                            "
                          >
                            {{ model["error-nextVoyage"] }}
                          </div>
                        </div>
                        <!-- /fieldset -->
                      </div>
                    </div>
                    <div class="two fields">
                      <div
                        class="field"
                        style="margin-left: -115px; margin-top: -8px"
                      >
                        <div class="ui toggle checkbox">
                          <input
                            type="checkbox"
                            name="model[isCalculateDate]"
                            [checked]="doNotCalculate"
                            [(ngModel)]="doNotCalculate"
                            [readonly]="connectionLock"
                          />
                          <label style="font-size: 10px; width: 350px"
                            >Do not calculate date based on previous
                            voyage</label
                          >
                        </div>
                      </div>
                      <div
                        class="field"
                        style="margin-top: -11px; margin-left: 115px"
                      >
                        <button
                          class="mini ui right floated button"
                          (click)="openSequenceDialog()"
                          [class.disabled]="connectionLock"
                        >
                          S
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /fieldset -->
            </div>
            <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);margin:auto" id='blezak' -->
            <div
              class="ui raised segment"
              style="
                margin: auto;
                padding-top: 4px;
                padding-left: 14px;
                padding-bottom: 4px;
                padding-right: 4px;
                margin-right: -59px;
              "
              id="blezak"
            >
              <div
                class="ui vertical icon mini buttons"
                style="margin-left: -11px"
              >
                <button
                  class="ui button sapi"
                  data-content="Add"
                  (click)="addRow()"
                  [class.disabled]="verticalButtonLock"
                >
                  <i class="plus icon"></i>
                </button>
                <button
                  class="ui button sapi"
                  data-content="Remove"
                  (click)="remove()"
                  [class.disabled]="verticalButtonLock"
                >
                  <i class="minus icon"></i>
                </button>
                <input type="hidden" name="gridCount" value="0" />
              </div>
              <!-- grid-plugin [settings]="settingGrid" [gridEvent]="setGridEvent" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
              <grid-plugin2
                id="detail-section"
                #gridCustom
                [settings]="gridCustomSetting"
                (gridEvent)="gridEventCustom($event)"
                (infoGrid)="infoGridCustom($event)"
              ></grid-plugin2>
            </div>
            <!-- /fieldset -->
            <div
              [style.display]="modeDetail ? 'block' : 'block'"
              style="margin-right: -60px; margin-bottom: -44px"
            >
              <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->
              <div class="ui raised segment">
                <label class="ui red ribbon mini label"
                  >Port of Calling Detail</label
                >

                <div class="ui equal width padded grid">
                  <div class="row" style="padding-top: 5px">
                    <div class="column">
                      <input
                        type="hidden"
                        name="model[detail][seq]"
                        value="{{ modelDetail.seqNo }}"
                        readonly=""
                      />
                      <!-- div class="two wide field">
                                            <label style="font-size:10px">Seq</label>
                                            <input placeholder="seq" type="text" name="model[detail][seq]" value="{{modelDetail.seqNo}}" readonly="">
                                        </div -->
                      <div class="two fields" style="margin-bottom: 3px">
                        <div class="ten wide field">
                          <label style="font-size: 10px">Port of Call</label>
                          <!-- input placeholder="Port of Call" type="text" name="model[detail][portofcall]" -->
                          <combo-plugin
                            #cbpc
                            [settings]="settingPC"
                            (change)="getPC($event)"
                            style="width: 100%"
                            [disableCombo]="lock"
                          ></combo-plugin>
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            id="errTxt-portOfCallName"
                            [class.error-message-visible]="
                              modelDetail['error-portOfCallName'] != null &&
                              modelDetail['error-portOfCallName'] != ''
                            "
                          >
                            <p
                              [innerHtml]="modelDetail['error-portOfCallName']"
                            ></p>
                          </div>
                        </div>
                        <div class="six wide field">
                          <label style="font-size: 10px">Port Code</label>
                          <input
                            placeholder="Port Code"
                            type="text"
                            name="model[detail][portcode]"
                            readonly=""
                            value="{{ modelDetail.portOfCall }}"
                          />
                        </div>
                      </div>
                      <div class="two fields" style="margin-bottom: 8px">
                        <div class="ten wide field">
                          <label style="font-size: 10px">Terminal</label>
                          <!-- input placeholder="Terminal" type="text" name="model[detail][terminal]" -->
                          <combo-plugin
                            #cbtml
                            [settings]="settingTml"
                            (change)="getTml($event)"
                            style="width: 100%"
                            [disableCombo]="terminalLock"
                          ></combo-plugin>
                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            id="errTxt-terminalName"
                            [class.error-message-visible]="
                              modelDetail['error-terminalName'] != null &&
                              modelDetail['error-terminalName'] != ''
                            "
                          >
                            <p
                              [innerHtml]="modelDetail['error-terminalName']"
                            ></p>
                          </div>
                        </div>
                        <div class="six wide field">
                          <label style="font-size: 10px">Terminal Code</label>
                          <input
                            placeholder="Terminal Code"
                            type="text"
                            name="model[detail][terminalcode]"
                            readonly=""
                            value="{{ modelDetail.terminalCode }}"
                          />
                        </div>
                      </div>
                      <div class="ui mini right floated buttons">
                        <button
                          [style.display]="modeButton ? 'none' : 'block'"
                          [style.background-color]="lock ? '#cacbcd' : 'green'"
                          [style.color]="lock ? 'black' : 'white'"
                          class="ui button"
                          data-content="ok"
                          (click)="detailsProcess('OK')"
                          [disabled]="lock"
                        >
                          Ok
                        </button>
                        <button
                          [style.display]="modeButton ? 'block' : 'none'"
                          [style.background-color]="lock ? '#cacbcd' : 'green'"
                          [style.color]="lock ? 'black' : 'white'"
                          class="ui button"
                          data-content="update"
                          (click)="detailsProcess('UPDATE')"
                          [disabled]="lock"
                        >
                          Update
                        </button>
                        <div class="or"></div>
                        <button
                          class="ui button"
                          data-content="cancel"
                          (click)="detailsProcess('CANCEL')"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div class="column">
                      <div class="two fields" style="margin-bottom: 3px">
                        <div class="ten wide field">
                          <label style="font-size: 10px">Purpose of Call</label>
                          <!-- input placeholder="Purpose of Call" type="text" name="model[detail][poc]" -->
                          <!-- combo-plugin id="dpc" #cbpoc [settings]="settingPOC" (change)="getPOC($event)" style="width:100%;" [disableCombo]="lock"></combo-plugin -->

                          <div
                            id="purposeoc"
                            class="ui selection dropdown"
                            [class.disabled]="lock"
                            style="font-size: 10px; margin-top: -5px"
                          >
                            <input type="hidden" name="oy" />
                            <i class="dropdown icon"></i>
                            <div class="default text" style="font-size: 10px">
                              Select
                            </div>
                            <div class="menu">
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="LOADING"
                              >
                                LOADING
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="DISCHARGING"
                              >
                                DISCHARGING
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="LOADING/DISCHARGING"
                              >
                                LOADING/DISCHARGING
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="DRY DOCK"
                              >
                                DRY DOCK
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="BUNKERING ONLY"
                              >
                                BUNKERING ONLY
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="CANAL PASSING"
                              >
                                CANAL PASSING
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="START CHARTER"
                              >
                                START CHARTER
                              </div>
                              <div
                                class="item"
                                style="font-size: 10px"
                                data-value="END CHARTER"
                              >
                                END CHARTER
                              </div>
                            </div>
                          </div>

                          <div
                            class="
                              ui
                              basic
                              red
                              pointing
                              prompt
                              label
                              transition
                              error-message-hidden
                            "
                            id="errTxt-purpose"
                            [class.error-message-visible]="
                              modelDetail['error-purpose'] != null &&
                              modelDetail['error-purpose'] != ''
                            "
                          >
                            <p [innerHtml]="modelDetail['error-purpose']"></p>
                          </div>
                        </div>
                        <div class="six wide field"></div>
                      </div>
                      <fieldset
                        style="
                          padding-top: 0px;
                          padding-bottom: 0px;
                          padding-right: 0px;
                          padding-left: 5px;
                          width: 226px;
                        "
                      >
                        <legend style="font-size: 8px">
                          For PSA Only (Applicable only in Singapore)
                        </legend>
                        <div class="field">
                          <label style="font-size: 10px">Terminal</label>
                          <div class="fields">
                            <div class="two wide field">
                              <input
                                type="text"
                                name="model[detail][terminal][0]"
                                [(ngModel)]="modelDetail['term1']"
                                [readonly]="lock"
                                maxlength="2"
                                autocomplete="off"
                              />
                            </div>
                            <div class="two wide field">
                              <input
                                type="text"
                                name="model[detail][terminal][1]"
                                [(ngModel)]="modelDetail['term2']"
                                [readonly]="lock"
                                maxlength="2"
                                autocomplete="off"
                              />
                            </div>
                            <div class="two wide field">
                              <input
                                type="text"
                                name="model[detail][terminal][2]"
                                [(ngModel)]="modelDetail['term3']"
                                [readonly]="lock"
                                maxlength="2"
                                autocomplete="off"
                              />
                            </div>
                            <div class="two wide field">
                              <input
                                type="text"
                                name="model[detail][terminal][3]"
                                [(ngModel)]="modelDetail['term4']"
                                [readonly]="lock"
                                maxlength="2"
                                autocomplete="off"
                              />
                            </div>
                            <div class="two wide field">
                              <input
                                type="text"
                                name="model[detail][terminal][4]"
                                [(ngModel)]="modelDetail['term5']"
                                [readonly]="lock"
                                maxlength="2"
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div class="column">
                      <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);margin:auto" -->
                      <div
                        class="ui raised segment"
                        style="
                          margin-top: -37px;
                          margin-left: -149px;
                          margin-right: 122px;
                          height: 75px;
                        "
                      >
                        <label class="ui red ribbon mini label">Proforma</label>

                        <div
                          class="fields"
                          style="
                            margin-top: -34px;
                            margin-left: 58px;
                            margin-right: -32px;
                            margin-bottom: 6px;
                          "
                        >
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >PETA</label
                            >
                            <!-- calendar-plugin id='PETA' [pluginType]="typeCalendar" [settings]="settingCalendarPeta" [lock]="calLock"></calendar-plugin -->
                            <div id="PETA" style="margin-left: -1px">
                              <input
                                #cPetaD
                                type="number"
                                id="date"
                                name="petaDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="calLock"
                                (blur)="
                                  preCheckDate(cPetaD); calcDate('peta.date')
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="petaTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="calLock"
                                (blur)="calcDate('peta.time')"
                              />
                            </div>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              id="errTxt-peta"
                              [class.error-message-visible]="
                                modelDetail['error-peta'] != null &&
                                modelDetail['error-peta'] != ''
                              "
                            >
                              <p [innerHtml]="modelDetail['error-peta']"></p>
                            </div>
                          </div>
                          <!-- div class="three wide field">
                                                    <label [innerHTML]="space"></label>
                                                    <input type="text" name="model[detail][peta][dateHour]" id="kancil">
                                                    <calendar-plugin id='PETD' [pluginType]="typeTime" [settings]="settingTime"></calendar-plugin>
                                                </div -->
                          <div
                            class="three wide field"
                            style="margin-left: -10px"
                          >
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >Frm</label
                            >
                            <input
                              type="text"
                              name="model[detail][peta][days]"
                              [(ngModel)]="modelDetail['petaDays']"
                              min="0"
                              id="petaDays"
                              style="text-align: right"
                              (blur)="countHour('petaHour', 'petaDays')"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field">
                            <label
                              style="font-size: 10px; margin-bottom: -7px"
                              [innerHTML]="space"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                                margin-left: -9px;
                              "
                              >D</label
                            >
                          </div>
                          <div
                            class="three wide field"
                            style="margin-left: -17px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -2px"
                            ></label>
                            <input
                              type="text"
                              name="model[detail][peta][hour]"
                              [(ngModel)]="modelDetail['petaHour']"
                              style="text-align: right"
                              (blur)="countHour('petaHour', 'petaDays')"
                              id="petaHour"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field">
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                                margin-left: -10px;
                              "
                              >H</label
                            >
                          </div>
                        </div>
                        <div
                          class="fields"
                          style="
                            margin-top: -24px;
                            margin-left: 57px;
                            margin-right: -34px;
                          "
                        >
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >PETD</label
                            >
                            <!-- calendar-plugin id='PETD' [pluginType]="typeCalendar" [settings]="settingCalendarPetd" [lock]="calLock"></calendar-plugin -->
                            <div id="PETD" style="margin-left: -1px">
                              <input
                                #cPetdD
                                type="number"
                                id="date"
                                name="petdDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="calLock"
                                (blur)="
                                  preCheckDate(cPetdD); calcDate('petd.date')
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="petdTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="calLock"
                                (blur)="calcDate('petd.time')"
                              />
                            </div>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              id="errTxt-petd"
                              [class.error-message-visible]="
                                modelDetail['error-petd'] != null &&
                                modelDetail['error-petd'] != ''
                              "
                            >
                              <p [innerHtml]="modelDetail['error-petd']"></p>
                            </div>
                          </div>
                          <!-- div class="three wide field">
                                                    <label [innerHTML]="space"></label>
                                                    <input type="text" name="model[detail][petd][dateHour]" id="petdTime">
                                                </div -->
                          <div
                            class="three wide field"
                            style="margin-left: -11px"
                          >
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >At</label
                            >
                            <input
                              type="text"
                              id="petdDays"
                              min="0"
                              [(ngModel)]="modelDetail['petdDays']"
                              style="text-align: right"
                              name="model[detail][petd][days]"
                              (blur)="countHour('petdHour', 'petdDays')"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field">
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                                margin-left: -9px;
                              "
                              >D</label
                            >
                          </div>
                          <div
                            class="three wide field"
                            style="margin-left: -17px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -2px"
                            ></label>
                            <input
                              type="text"
                              id="petdHour"
                              min="0"
                              [(ngModel)]="modelDetail['petdHour']"
                              style="text-align: right"
                              (blur)="countHour('petdHour', 'petdDays')"
                              name="model[detail][petd][hour]"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field">
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                                margin-left: -10px;
                              "
                              >H</label
                            >
                          </div>
                        </div>
                      </div>
                      <!-- /fieldset -->

                      <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);margin:auto" -->
                      <div
                        class="ui raised segment"
                        style="
                          margin-top: -13px;
                          margin-left: -149px;
                          margin-right: 122px;
                          height: 75px;
                        "
                      >
                        <label class="ui red ribbon mini label"
                          >Estimated</label
                        >

                        <div
                          class="fields"
                          style="
                            margin-top: -35px;
                            margin-left: 59px;
                            margin-right: -33px;
                            margin-bottom: 6px;
                          "
                        >
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >ETA</label
                            >
                            <!-- calendar-plugin id='ETA' [pluginType]="typeCalendar" [settings]="settingCalendarEta" [lock]="calLock"></calendar-plugin -->
                            <div id="ETA" style="margin-left: -1px">
                              <input
                                #cEtaD
                                type="number"
                                id="date"
                                name="etaDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="calLock"
                                (blur)="
                                  preCheckDate(cEtaD); calcDate('eta.date')
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="etaTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="calLock"
                                (blur)="calcDate('eta.time')"
                              />
                            </div>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              id="errTxt-eta"
                              [class.error-message-visible]="
                                modelDetail['error-eta'] != null &&
                                modelDetail['error-eta'] != ''
                              "
                            >
                              <p [innerHtml]="modelDetail['error-eta']"></p>
                            </div>
                            <!-- calendar-plugin id='PETA' [pluginType]="typeCalendar" [settings]="settingCalendarEta"></calendar-plugin -->
                          </div>
                          <!-- div class="three wide field">
                                                    <label [innerHTML]="space"></label>
                                                    <input type="text" name="model[detail][eta][dateHour]" id="etaTime">
                                                </div -->
                          <div
                            class="three wide field"
                            style="margin-left: -11px"
                          >
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >Frm</label
                            >
                            <input
                              type="text"
                              id="etaDays"
                              min="0"
                              [(ngModel)]="modelDetail['etaDays']"
                              style="text-align: right"
                              name="model[detail][eta][days]"
                              (blur)="countHour('etaHour', 'etaDays')"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field" style="margin-left: -8px">
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                              "
                              >D</label
                            >
                          </div>
                          <div
                            class="three wide field"
                            style="margin-left: -9px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -2px"
                            ></label>
                            <input
                              type="text"
                              id="etaHour"
                              min="0"
                              [(ngModel)]="modelDetail['etaHour']"
                              style="text-align: right"
                              (blur)="countHour('etaHour', 'etaDays')"
                              name="model[detail][eta][hour]"
                              [readonly]="lock"
                            />
                          </div>
                          <div
                            class="one wide field"
                            style="margin-left: -10px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                              "
                              >H</label
                            >
                          </div>
                        </div>
                        <div
                          class="fields"
                          style="
                            margin-top: -25px;
                            margin-left: 58px;
                            margin-right: -36px;
                          "
                        >
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >ETD</label
                            >
                            <!-- calendar-plugin id='ETD' [pluginType]="typeCalendar" [settings]="settingCalendarEtd" [lock]="calLock"></calendar-plugin -->
                            <div id="ETD" style="margin-left: -1px">
                              <input
                                #cEtdD
                                type="number"
                                id="date"
                                name="etdDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="calLock"
                                (blur)="
                                  preCheckDate(cEtdD); calcDate('etd.date')
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="etdTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="calLock"
                                (blur)="calcDate('etd.time')"
                              />
                            </div>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              id="errTxt-etd"
                              [class.error-message-visible]="
                                modelDetail['error-etd'] != null &&
                                modelDetail['error-etd'] != ''
                              "
                            >
                              <p [innerHtml]="modelDetail['error-etd']"></p>
                            </div>
                            <!-- calendar-plugin id='PETA' [pluginType]="typeCalendar" [settings]="settingCalendarEtd"></calendar-plugin -->
                          </div>
                          <!-- div class="three wide field">
                                                    <label [innerHTML]="space"></label>
                                                    <input type="text" name="model[detail][etd][dateHour]" id="etdTime">
                                                </div -->
                          <div
                            class="three wide field"
                            style="margin-left: -11px"
                          >
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >At</label
                            >
                            <input
                              type="text"
                              id="etdDays"
                              min="0"
                              [(ngModel)]="modelDetail['etdDays']"
                              style="text-align: right"
                              name="model[detail][etd][days]"
                              (blur)="countHour('etdHour', 'etdDays')"
                              [readonly]="lock"
                            />
                          </div>
                          <div class="one wide field" style="margin-left: -9px">
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                              "
                              >D</label
                            >
                          </div>
                          <div
                            class="three wide field"
                            style="margin-left: -10px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -2px"
                            ></label>
                            <input
                              type="text"
                              id="etdHour"
                              min="0"
                              [(ngModel)]="modelDetail['etdHour']"
                              style="text-align: right"
                              (blur)="countHour('etdHour', 'etdDays')"
                              name="model[detail][etd][hour]"
                              [readonly]="lock"
                            />
                          </div>
                          <div
                            class="one wide field"
                            style="margin-left: -10px"
                          >
                            <label
                              [innerHTML]="space"
                              style="margin-bottom: -7px"
                            ></label>
                            <label
                              style="
                                font-size: 10px;
                                line-height: 3em;
                                vertical-align: middle;
                              "
                              >H</label
                            >
                          </div>
                        </div>
                      </div>
                      <!-- /fieldset -->

                      <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);margin:auto" -->
                      <div
                        class="ui raised segment"
                        style="
                          margin-top: -13px;
                          margin-left: -149px;
                          margin-right: 143px;
                          width: 390px;
                          height: 42px;
                        "
                      >
                        <label class="ui red ribbon mini label">Actual</label>

                        <div
                          class="fields"
                          style="
                            margin-top: -36px;
                            margin-left: 58px;
                            margin-right: -13px;
                            margin-bottom: 6px;
                          "
                        >
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >ATB</label
                            >
                            <!--calendar-plugin id='ATA' [pluginType]="typeCalendar" [settings]="settingCalendarAta" [lock]="lock"></calendar-plugin -->
                            <div id="ATA" style="margin-left: -1px">
                              <input
                                #cAtbD
                                type="number"
                                id="date"
                                name="etaDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="lock"
                                (blur)="
                                  preCheckDate(cAtbD);
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="etaTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="lock"
                                (blur)="calcDate('atb.time')"
                              />
                            </div>
                            <!-- calendar-plugin id='PETA' [pluginType]="typeCalendar" [settings]="settingCalendarAta"></calendar-plugin -->
                          </div>
                          <div class="eight wide field">
                            <label style="font-size: 10px; margin-bottom: -2px"
                              >ATD</label
                            >
                            <!-- calendar-plugin id='ATD' [pluginType]="typeCalendar" [settings]="settingCalendarAtd" [lock]="lock"></calendar-plugin -->
                            <div id="ATD" style="margin-left: -1px">
                              <input
                                #cAtdD
                                type="number"
                                id="date"
                                name="etaDate"
                                style="width: 92px"
                                placeholder="Date"
                                [readonly]="lock"
                                (blur)="
                                  preCheckDate(cAtdD);
                                "
                              />
                              <input
                                type="number"
                                id="time"
                                name="etaTime"
                                placeholder="Time"
                                style="
                                  margin-top: -20px;
                                  margin-left: 98px;
                                  width: 54px;
                                "
                                [readonly]="lock"
                                (blur)="calcDate('atd.time')"
                              />
                            </div>
                            <!-- calendar-plugin id='PETA' [pluginType]="typeCalendar" [settings]="settingCalendarAtd"></calendar-plugin -->
                          </div>
                        </div>
                      </div>
                      <!-- /fieldset -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /fieldset -->
              <br />
            </div>

            <div
              class="ui raised segment"
              style="margin-right: -60px; margin-top: -30px"
            >
              <div class="two fields">
                <div class="eight wide field">
                  <label style="font-size: 10px">Created By</label>
                  <input
                    placeholder="Created By"
                    type="text"
                    name="model[createdBy]"
                    value="{{ model.createdBy }}"
                    readonly=""
                  />
                </div>
                <div class="eight wide field">
                  <label style="font-size: 10px">Created date</label>
                  <input
                    placeholder="Created Date"
                    type="text"
                    name="model[createdDate]"
                    value="{{ model.createdDate }}"
                    readonly=""
                  />
                  <!-- calendar-plugin #createdDate id='testdoang' #crdate [pluginType]="typeCalendar" [settings]="settingTest"></calendar-plugin -->
                </div>
              </div>
              <div class="two fields">
                <div class="eight wide field">
                  <label style="font-size: 10px">Last Modified By</label>
                  <input
                    placeholder="Last Modified By"
                    type="text"
                    name="model[lastModifiedBy]"
                    value="{{ model.lastModifiedBy }}"
                    readonly=""
                  />
                </div>
                <div class="eight wide field">
                  <label style="font-size: 10px">Last Modified date</label>
                  <input
                    placeholder="Last Modified Date"
                    type="text"
                    name="model[lastModifiedDate]"
                    value="{{ model.lastModifiedDate }}"
                    readonly=""
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="eight wide field">
                  <label style="font-size: 10px">Invalidate By</label>
                  <input
                    placeholder="Invalidate By"
                    type="text"
                    name="model[invalidateBy]"
                    value="{{ model.invalidateBy }}"
                    readonly=""
                  />
                </div>
                <div class="eight wide field">
                  <label style="font-size: 10px">Invalidate date</label>
                  <input
                    placeholder="Invalidate Date"
                    type="text"
                    name="model[invalidateDate]"
                    value="{{ model.invalidateDate }}"
                    readonly=""
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
        </div>
      </div>
    </div>

    <!-- Start of Form History -->
    <form
      class="ui form"
      [style.display]="modeForm == 'history' ? 'block' : 'none'"
    >
      <h4 class="ui dividing header" align="center">Vessel Schedule Master</h4>

      <div class="ui raised segment">
        <a class="ui red ribbon label">SELECT VESSEL SCHEDULE</a>
        <div class="fields">
          <div class="field">
            <div
              class="ui button"
              style="height: 65%; margin-top: 22px"
              tabindex="0"
              (click)="retrieveHistory()"
            >
              Retrieve
            </div>
          </div>
          <div class="field">
            <div
              class="ui button"
              style="height: 65%; margin-top: 22px"
              (click)="generateServiceHistoryReport()"
              [class.disabled]="historyLock"
              tabindex="0"
            >
              Export
            </div>
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Vessel Name</label>
            <combo-plugin
              #sopin
              [settings]="settingHVName"
              (change)="getHVName($event)"
              style="width: 100%"
            ></combo-plugin>
          </div>
          <div class="field">
            <label>Base Voyage</label>
            <!-- input placeholder="Base Voyage" type="text" name="model[baseVoyage]" [(ngModel)]="model['baseVoyage']" autocomplete="off" -->
            <div
              class="ui selection dropdown"
              id="idHistoryVoyage"
              style="font-size: 8px"
            >
              <input
                #cbHVoyage
                type="hidden"
                name="baseVoyage"
                [(ngModel)]="model['baseVoyage']"
                (change)="setHVoyage(cbHVoyage.value)"
              />
              <i class="dropdown icon"></i>
              <div class="default text" style="font-size: 10px">
                base voyage
              </div>
              <div class="menu" style="font-size: 10px"></div>
            </div>
          </div>
        </div>
        <!-- Begin of Grid style="margin-top:-35px"-->
        <grid-plugin2
          #gridH
          [settings]="settingGridHistory"
          (gridEvent)="gridEventHistory($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin2>
        <!-- End of Grid -->
      </div>
      <br /><br /><br /><br /><br /><br />
    </form>
    <!-- End of Form History -->

    <!-- Start of Form Batch -->
    <form
      class="ui form"
      id="batchCopy"
      [style.display]="modeForm == 'batch' ? 'block' : 'none'"
    >
      <h4 class="ui dividing header" align="center">Vessel Schedule Master</h4>

      <!-- fieldset style="border:1px solid rgba(34, 36, 38, 0.14902);" -->
      <div class="ui raised segment" style="padding-right: 50%">
        <label class="ui red ribbon label">Schedule Detail</label>
        <br /><br /><br />
        <div class="field">
          <label>Vessel Name</label>
          <combo-plugin
            #sopon
            [settings]="settingBVName"
            (change)="getBVName($event)"
            style="width: 100%"
          ></combo-plugin>
        </div>
        <div class="field">
          <label>Voyage</label>
          <div
            class="ui selection dropdown"
            id="idBatchVoyage"
            style="font-size: 8px"
          >
            <input
              #cbBVoyage
              type="hidden"
              name="baseVoyage"
              [(ngModel)]="model['baseVoyage']"
              (change)="setBVoyage(cbBVoyage.value)"
            />
            <i class="dropdown icon"></i>
            <div class="default text" style="font-size: 10px">base voyage</div>
            <div class="menu" style="font-size: 10px"></div>
          </div>
        </div>
        <div class="ui icon tiny buttons">
          <button
            class="ui button"
            data-content="Add"
            (click)="addBatchCopyRow()"
          >
            <i class="plus icon"></i>
          </button>
          <button
            class="ui button"
            data-content="Remove"
            (click)="removeCopyRow('batchCopy')"
          >
            <i class="minus icon"></i>
          </button>
        </div>
        <br /><br />
        <div
          id="editable"
          style="
            width: 800px;
            height: 200px;
            overflow-y: scroll;
            border: solid;
            border-width: 0.5px;
          "
        >
          <table
            class="
              ui
              sortable
              selectable
              compact
              celled
              definition
              striped
              table
            "
            [class.disabled]="lock"
          >
            <thead class="full-width" style="font-size: 12px">
              <tr>
                <th width="5%"></th>
                <th
                  *ngFor="let col of gridBatchCopySetting.columns"
                  width="{{ col.width }}%"
                >
                  {{ col.header }}
                </th>
              </tr>
            </thead>
            <tbody style="font-size: 12px">
              <tr
                style="cursor: pointer"
                *ngFor="let data of batchCopyStore.store"
              >
                <td>
                  <input
                    #selectC
                    name="copyCheckbox"
                    type="checkbox"
                    (change)="
                      clickCheckBox(selectC.checked, data.id, 'batchCopy')
                    "
                  />
                </td>
                <td
                  *ngFor="let col of gridBatchCopySetting.columns"
                  id="s.{{ data.id }}.{{ col.field }}"
                  (dblclick)="
                    onDoubleClick(
                      data.id,
                      col.field,
                      data[col.field],
                      col.type,
                      'batchCopy'
                    )
                  "
                >
                  <span>{{ data[col.field] }}</span>
                  <input
                    type="text"
                    value="{{ data[col.field] }}"
                    style="display: none"
                    (blur)="
                      textBlur(data.id, col.field, data[col.field], 'batchCopy')
                    "
                    (keypress)="
                      textKeyPress(
                        $event,
                        data.id,
                        col.field,
                        data[col.field],
                        'batchCopy'
                      )
                    "
                  />
                  <div class="fields" style="display: none">
                    <div class="field">
                      <select
                        class="syear"
                        style="font-size: 10px; padding: 3px; width: inherit"
                      >
                        <option
                          *ngFor="let year of yearList"
                          value="{{ year }}"
                        >
                          {{ year }}
                        </option>
                      </select>
                      <button
                        class="mini ui button"
                        (click)="
                          divBlur(
                            data.id,
                            col.field,
                            data[col.field],
                            'batchCopy'
                          )
                        "
                        style="
                          font-size: 10px;
                          width: 43px;
                          padding: 3px;
                          margin-top: 5px;
                        "
                      >
                        ok
                      </button>
                    </div>
                    <div class="field">
                      <select
                        class="smonth"
                        style="font-size: 10px; padding: 3px; width: inherit"
                      >
                        <option
                          *ngFor="let mnth of monthsList2"
                          value="{{ mnth }}"
                        >
                          {{ mnth }}
                        </option>
                      </select>
                    </div>
                    <div class="field">
                      <select
                        class="sday"
                        style="font-size: 10px; padding: 3px; width: inherit"
                      >
                        <option *ngFor="let day of dayList" value="{{ day }}">
                          {{ day }}
                        </option>
                      </select>
                    </div>
                    <div class="field">
                      <select
                        class="shour"
                        style="font-size: 10px; padding: 3px; width: inherit"
                      >
                        <option *ngFor="let hh of hourList" value="{{ hh }}">
                          {{ hh }}
                        </option>
                      </select>
                    </div>
                    <div class="field">
                      <select
                        class="sminute"
                        style="font-size: 10px; padding: 3px; width: inherit"
                      >
                        <option *ngFor="let mm of minuteList" value="{{ mm }}">
                          {{ mm }}
                        </option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="cloneCopy" style="display: none">
  <!-- now working -->
  <div class="ui raised segment" style="padding-left: 2%; padding-right: 2%">
    <div class="ui form">
      <div class="ui grid">
        <div class="row">
          <div class="fourteen wide column">
            <div class="field">
              <label>Vessel Name</label>
              <input
                placeholder="Vessel Name"
                type="text"
                name="vscVesselName"
                value="{{ model.vesselName }}"
                readonly=""
              />
            </div>
            <div class="field">
              <label>Voyage</label>
              <input
                placeholder="Voyage"
                type="text"
                name="vscVesselVoyage"
                value="{{ model.baseVoyage }}"
                readonly=""
              />
            </div>
            <div class="ui icon tiny buttons">
              <button
                class="ui button"
                data-content="Add"
                (click)="addCopyRow('aiai')"
                [class.disabled]="copy2Lock"
              >
                <i class="plus icon"></i>
              </button>
              <button
                class="ui button"
                data-content="Remove"
                (click)="removeCopyRow('sairing')"
                [class.disabled]="copy2Lock"
              >
                <i class="minus icon"></i>
              </button>
            </div>
            <br />
            <div *ngFor="let msg of dCopyMessage1">
              <div
                [style.display]="msg.status == '1' ? 'block' : 'none'"
                class="
                  ui
                  basic
                  green
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  msg.status != null && msg.status != '' && msg.status == '1'
                "
              >
                <span [innerHTML]="msg.message"></span>
              </div>
              <div
                [style.display]="msg.status == '1' ? 'none' : 'block'"
                class="
                  ui
                  basic
                  red
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  msg.status != null && msg.status != '' && msg.status == '0'
                "
              >
                <span [innerHTML]="msg.message"></span>
              </div>
            </div>
          </div>
          <div class="two wide column">
            <div class="field">
              <label [innerHTML]="space"></label>
              <button
                class="mini ui button"
                (click)="doCopy2()"
                style="width: 80px"
                [class.disabled]="copy2Lock"
              >
                Do Copy
              </button>
              <button
                class="mini ui button"
                (click)="doCancel()"
                style="width: 80px"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div
        id="editable"
        style="
          width: 100%;
          height: 400px;
          overflow-y: scroll;
          border: solid;
          border-width: 0.5px;
        "
      >
        <div
          class="ui inverted dimmer"
          [class.active]="loadingCopy == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <table
          class="ui sortable selectable compact celled definition striped table"
          [class.disabled]="lock"
        >
          <thead class="full-width" style="font-size: 12px">
            <tr>
              <th width="5%"></th>
              <th
                *ngFor="let col of gridCopy2Setting.columns"
                width="{{ col.width }}%"
              >
                {{ col.header }}
              </th>
            </tr>
          </thead>
          <tbody style="font-size: 10px">
            <tr style="cursor: pointer" *ngFor="let data of copy2Store.store">
              <td>
                <input
                  #selectc2
                  name="copyCheckbox"
                  value="{{ data.id }}"
                  type="checkbox"
                  (change)="clickCheckBox(selectc2.checked, data.id, 'sotooy')"
                />
              </td>
              <td
                *ngFor="let col of gridCopy2Setting.columns"
                id="s.{{ data.id }}.{{ col.field }}"
                (dblclick)="
                  onDoubleClick(
                    data.id,
                    col.field,
                    data[col.field],
                    col.type,
                    'cloneCopy'
                  )
                "
              >
                <span
                  [style.color]="
                    col.field == 'status'
                      ? data.statusCode == '1'
                        ? 'red'
                        : 'green'
                      : 'black'
                  "
                  >{{ data[col.field] }}</span
                >
                <input
                  type="text"
                  value="{{ data[col.field] }}"
                  style="display: none"
                  (blur)="
                    textBlur(data.id, col.field, data[col.field], 'cloneCopy')
                  "
                  (keypress)="
                    textKeyPress(
                      $event,
                      data.id,
                      col.field,
                      data[col.field],
                      'cloneCopy'
                    )
                  "
                />
                <div class="fields" style="display: none">
                  <div class="field">
                    <select
                      class="syear"
                      style="font-size: 10px; padding: 3px; width: inherit"
                    >
                      <option *ngFor="let year of yearList" value="{{ year }}">
                        {{ year }}
                      </option>
                    </select>
                    <button
                      class="mini ui button"
                      (click)="
                        divBlur(
                          data.id,
                          col.field,
                          data[col.field],
                          'cloneCopy'
                        )
                      "
                      style="
                        font-size: 10px;
                        width: 43px;
                        padding: 3px;
                        margin-top: 5px;
                      "
                    >
                      ok
                    </button>
                  </div>
                  <div class="field">
                    <select
                      class="smonth"
                      style="font-size: 10px; padding: 3px; width: inherit"
                    >
                      <option
                        *ngFor="let mnth of monthsList2"
                        value="{{ mnth }}"
                      >
                        {{ mnth }}
                      </option>
                    </select>
                  </div>
                  <div class="field">
                    <select
                      class="sday"
                      style="font-size: 10px; padding: 3px; width: inherit"
                    >
                      <option *ngFor="let day of dayList" value="{{ day }}">
                        {{ day }}
                      </option>
                    </select>
                  </div>
                  <div class="field">
                    <select
                      class="shour"
                      style="font-size: 10px; padding: 3px; width: inherit"
                    >
                      <option *ngFor="let hh of hourList" value="{{ hh }}">
                        {{ hh }}
                      </option>
                    </select>
                  </div>
                  <div class="field">
                    <select
                      class="sminute"
                      style="font-size: 10px; padding: 3px; width: inherit"
                    >
                      <option *ngFor="let mm of minuteList" value="{{ mm }}">
                        {{ mm }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="combo" style="display: none">
                  <div class="ui fluid search selection dropdown cmb-combo">
                    <input
                      type="hidden"
                      style="font-size: 10px"
                      name="Vessel"
                    />
                    <i class="dropdown icon"></i>
                    <div class="default text" style="font-size: 10px">
                      Select Vessel
                    </div>
                    <div class="menu"></div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<!-- FORM TRANSMIT -->
<div
id="transmitForm"
style="display: none; font-family: Lucida Console, monospace"
> 
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div class="field">
    <!-- <button
      class="ui button green"
      style="text-transform: uppercase; font-weight: bold"
      (click)="onTransmitEdi_Generate()"
      [class.disabled]="btnGenerate == true"
      [class.hidden]="tabStatus == 'tab2'"
    >
      Generate
    </button> -->

    <button
      class="ui button green"
      style="font-weight: bold"
      (click)="onTransmitEdi_Generate2()"
      [class.disabled]="btnGenerate == true"
      [class.hidden]="tabStatus == 'tab2'"
    >
    <i class="download icon"></i>Generate
    </button>

    <button
      class="ui button green"
      style="font-weight: bold"
      (click)="onTransmitEdi_Upload()"
      [class.disabled]="btnEdiUpload == true"
      [class.hidden]="tabStatus == 'tab1'"
    >
    <i class="upload icon"></i>Update
    </button>

    <button
      class="ui button"
      style="font-weight: bold"
      (click)="onTransmitEdi_Cancel()"
    >
    <i class="remove icon"></i>Cancel
    </button>

  </div>

  <div
    class="ui error message"
    [class.hidden]="isErrorTransmitEdi == false"
  >
    <ul>
      <li
        [class.hidden]="
          modelTransmitEdi['error-vesselId'] == null ||
          modelTransmitEdi['error-vesselId'] == '' ||
          modelTransmitEdi['error-vesselId'] == undefined
        "
      >
        {{ modelTransmitEdi["error-vesselId"] }}
      </li>
      <li
        [class.hidden]="
          modelTransmitEdi['error-voyage'] == null ||
          modelTransmitEdi['error-voyage'] == '' ||
          modelTransmitEdi['error-voyage'] == undefined
        "
      >
        {{ modelTransmitEdi["error-voyage"] }}
      </li>
    </ul>
  </div>

  <div class="ui top attached tabular menu" style="background-color: beige">
    <a
      class="item"
      [class.active]="tabStatus == 'tab1'"
      data-tab="transmit edi"
      (click)="initTabTransmit1()"
      >Generate IFTSAI EDI</a
    >

    <a
      class="item"
      [class.active]="tabStatus == 'tab2'"
      data-tab="transmit edi"
      (click)="initTabTransmit2()"
      >Upload IFTSAI EDI</a
    >

  </div>

  <div
    class="ui form bottom attached tab segment"
    [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
    id="transmitEdiTab"
    data-tab="transmit edi"
  > 
    <div class="field">
      <div class="fields">
        <!-- <div class="seven wide field">
          <div class="required field">
            <label>Vessel</label>
            <combo-plugin
              #cbVesselTransmit
              [settings]="settingVesselTransmit"
              (change)="changeEventVesselTransmit($event)"
              style="width: 100%"
            ></combo-plugin>
          </div>
        </div>
        <div class="seven wide field">
          <label>Voyage</label>
          <combo-plugin
            #cbVoyageTransmit
            [settings]="settingVoyageTransmit"
            (change)="changeEventVoyageTransmit($event)"
            style="width: 100%"
          ></combo-plugin>
        </div>
        <div class="two wide field">
          <button
            class="ui button"
            style="text-transform: uppercase; font-weight: bold"
            (click)="onTransmitEdi_Add()"
          >
            add
          </button>
        </div> -->
        <div class="ten wide field">
          <button
            class="ui button blue"
            style="font-weight: bold"
            (click)="onTransmitEdi_Retrieve()"
          >
          <i class="search icon"></i>Retrieve
          </button>
        </div>

        <div class="six wide field">
          <input
            style="width: 100%;height: 100%;"
            type="text"
            id="filter-text-box"
            placeholder="Search..."
            (input)="onFilterTextBoxChanged()"
          />
        </div>
      </div>
    
      <div class="fields">
        <div class="sixteen wide field">
          <ag-grid-angular
            #gridPrepaid2
            style="width: 100%; height: 250px"
            class="ag-theme-balham"
            id="gridPrepaid2"
            [enableSorting]="false"
            [enableFilter]="false"
            [enableColResize]="true"
            [rowData]="rowDataTransmit"
            [rowSelection]="rowSelectionTransmit"
            [rowMultiSelectWithClick]="true"
            [columnDefs]="columnDefsTransmit"
            [pinnedBottomRowData]="pinnedBottomRowDataTransmit"
            [animateRows]="true"
            [enableCellChangeFlash]="true"
            (gridReady)="onGridReadyTransmit($event)"
            (selectionChanged)="onSelectionChanged($event)"
          >
          </ag-grid-angular>
        </div>
        <!-- <div class="two wide field">
          <button
            class="ui button red"
            style="text-transform: uppercase; font-weight: bold"
            (click)="onTransmitEdi_Remove()"
            [class.disabled]="btnGenerate == true"
          >
            remove
          </button>
        </div> -->
      </div>
    </div>
  </div>

  <div
    class="ui form bottom attached tab segment"
    [class.active]="tabStatus2 == 'active' && tabStatus == 'tab2'"
    id="ScheduleMappingTab"
    data-tab="Schedule Mapping"
  >
    <form class="ui form">
      <div class="ui equal width padded grid">
        <div class="twelve wide field">
          <label style="font-size:12px">FILE to be Processed</label>
          <input type="file" id="myfile" style="display:none">
          <div class="inline field">
            <upload-plugin #ediFileUpload [settings]="settingEdiUploadFile" (eventUpload)="uploadEventEdiFile($event)"></upload-plugin>
          </div>                        
        </div>
      </div>
    </form>
  </div>
</div>
<!-- END FORM TRANSMIT -->

<new-dialog
  #customDialog
  style="margin-left: 3px"
  [settings]="settingCustomDialog"
  (eventDialogs)="eventCustomDialog($event)"
></new-dialog>

<footer-plugin [info]="info"></footer-plugin>
