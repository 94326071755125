export class Booking {
  ATA: String = "";
  ATD: String = "";
  ETA: String = "";
  ETD: String = "";
  allocationValidator: Boolean = false;
  unitContainerValidator: Boolean = false;
  operatorCodeValidator: Boolean = false;
  slotOperatorCodeValidator: Boolean = false;
  coctsExceedValidator: Boolean = false;
  cocLocalExceedValidator: Boolean = false;
  podAllocationValidator: Boolean = false;
  rotationValidator: Boolean = false;
  feederValidator: Boolean = false;
  ExVoyageValidator: Boolean = false;
  NextVoyageValidator: Boolean = false;
  ReeferDegreeValidator: Boolean = false;
  ReeferDegreeSOValidator: Boolean = false;
  VentNumberValidator: Boolean = false;
  ReeferCollectionDateValidator: Boolean = false;
  POLPODValidator: Boolean = false;
  POLPODDetDemValidator: Boolean = false;
  POLPOTValidator: Boolean = false;
  PODPOTValidator: Boolean = false;
  bookingPartyValidator: Boolean = false;
  difUnitContainerValidator: Boolean = false;
  humidityValidator: Boolean = false;

  bOfficeCode: String = "";
  bPrefix: String = "";
  bNo: String = "";
  bOfficeCodeTs: String = "";
  bPrefixTs: String = "";
  bNoTs: String = "";
  bOceanVesselId: String = "";
  bOceanVesselCode: String = "";
  bOceanVesselName: String = "";
  bOceanVesselVoyage: String = "";
  bOceanVesselBound: String = "";
  bCreatedDate: String = "";
  bCreatedUserid: String = "";
  bModifiedDate: String = "";
  bModifiedUserid: String = "";
  bDeletedDate: String = "";
  bDeletedUserid: String = "";
  bReleaseOrderPrinted: String = "";
  bReleaseOrderPrintedUserid: String = "";
  bReleaseOrderPrintedDate: String = "";
  bStatus: String = "";
  bIsFinished: String = "";
  bTsBooking: String = "";
  bIsReserve: String = "";
  bIsSlotCommission: String = "";
  bIsTransmittedToPsa: String = "";
  bTransmittedToPsaDate: String = "";
  bTransmittedToPsaUserid: String = "";
  bPrincipalCode: String = "";
  bTransmitToSds: String = "";
  bIsUnlocked: String = "Y";
  bReason: String = "";

  // booking note 1
  bBookingPartyCode: String = "";
  bBookingPartyName: String = "";
  bShipperCode: String = "";
  bShipperName: String = "";
  bFreightPartyCode: String = "";
  bFreightPartyName: String = "";
  bPorCode: String = "";
  bPolCode: String = "";
  bPolName: String = "";
  bPolBooking: String = "";
  bPodCode: String = "";
  bPodName: String = "";
  bPodBooking: String = "";
  bPodlCode: String = "";
  bFdestCode: String = "";
  bPot1Code: String = "";
  bPot1Name: String = "";
  bPot2Code: String = "";
  bPot3Code: String = "";
  bCommodityCode: String = "";
  bCommodityId: String = "";
  bSpecialDetailCode: String = "";
  bShipmentType: String = "";
  bContainerOwnership: String = "";
  bContainerStatus: String = "";
  bDgClass: String = "";
  bFreightType: String = "";
  bIhc: String = "";
  bContactPerson: String = "";
  bTelephone: String = "";
  bRemarks: String = "";
  bWeightClass: String = "";
  bWeight: Number = 0;
  bCocOperatorCode: String = "";
  bCocCntrOperatorCode: String = "";
  bSocOperatorCode: String = "";
  bSocCntrOperatorCode: String = "";
  bSlotOperatorCode: String = "";
  bOutwardSlotOperatorCode: String = "";
  bReceiptType: String = "";
  bDeliveryType: String = "";
  bPsaReferenceNo: String = "";

  // booking note 2
  bFeederVesselId: String = "";
  bFeederVesselCode: String = "";
  bFeederVesselVoyage: String = "";
  bFeederVesselBound: String = "";
  bSupplierCode: String = "";
  bSupplierName: String = "";
  bExMotherVesselId: String = "";
  bExMotherVesselCode: String = "";
  bExMotherVesselVoyage: String = "";
  bExMotherVesselBound: String = "";
  bExMotherVesselName: String = "";
  bNextMotherVesselId: String = "";
  bNextMotherVesselCode: String = "";
  bNextMotherVesselVoyage: String = "";
  bNextMotherVesselBound: String = "";
  bReeferDegree: String = "";
  bReeferCollectionDate: String = "";
  bVent: String = "O";
  bIsDry: String = "";
  bDetention: String = "";
  bDetentionRequest: Number = 0;
  bDemurrage: String = "";
  bDemurrageRequest: Number = 0;
  bDetentionPol: String = "";
  bDetentionRequestPol: Number = 0;
  bDemurragePol: String = "";
  bDemurrageRequestPol: Number = 0;
  bHaulierCode: String = "";
  bHaulierName: String = "";
  bReturnToCode: String = "";
  bPackedAtCode: String = "";
  bReleaseAtCode: String = "";
  bRequestReleaseDate: String = "";
  bFirstReleaseDate: String = "";
  bExpectedReturnDate: String = "";
  bLastReleaseDate: String = "";
  bHaulierContact: String = "";
  bHaulierContactNo: String = "";
  bWt: Number = 0;
  bMeasure: Number = 0;
  bPackageNo: String = "";
  bPackageTypeCode: String = "";

  // terminal info
  bPorTerminalCode: String = "";
  bPolTerminalCode: String = "";
  bPodTerminalCode: String = "";
  bPodlTerminalCode: String = "";
  bPot1TerminalCode: String = "";
  bPot2TerminalCode: String = "";
  bPot3TerminalCode: String = "";
  bFdestTerminalCode: String = "";

  bookingDetails: BookingDetail[] = [];
  bookingFreightCharges: BookingFreightCharge[] = [];
  bookingContainerInfo: BookingContainerInfo[] = [];
  shaBookingEDIInfo: ShaBookingEDIInfo[] = [];
  bookingMisc: BookingMisc;
  bookingExtra: BookingExtra;


  etdPOL: String = "";
  etdMaster: String= "";
  isCreateNewBL: String = "";
  terminalNamePOL: any;
  bPot1TerminalName: any;
  bPodTerminalName = "";
  bPolTerminalName: any;
  etaNextVessel: any;
  etdNextVessel: any;
  vesselVoyageConfirmStatus = false;
  confirmVoyage = false;
  bPendingRequest: string;
  bNextVesselVoyagePriority = 'N';
  bDepotTransmitDate: any;
  depoId: any;
  reeferDryValidator: boolean = false;

  // GSO-1063 Add SLOT LC Checkbox
  bSlotLC: String = "";

  // GOS-874 & GOS-869
  bEdiBookingCommodity: String = "";

  shaEdiId:String = "";


  constructor() {}
}

export class BookingDetail {
  bOfficeCode: String = "";
  bPrefix: String = "";
  bNo: String = "";
  bContainerType: String = "";
  bContainerSize: String = "";
  bContainerHeight: String = "";
  bQuantity: Number = 0;
  bKillSlot: Number = 0;
  bUch: Number = 0;
  bUcl: Number = 0;
  bUcw: Number = 0;
  bOverHeight: String = "";
  bOverHeightCm: Number = 0;
  bOverWidth: String = "";
  bOverWidthLeftCm: Number = 0;
  bOverWidthRightCm: Number = 0;
  bOverLength: String = "";
  bOverLengthFrontCm: Number = 0;
  bOverLengthBackCm: Number = 0;
  bDoorOpen: String = "";

  constructor() {}
}

export class BookingFreightCharge {
  bOfficeCode: String = "";
  bPrefix: String = "";
  bNo: String = "";
  bContainerType: String = "";
  bContainerSize: String = "";
  bContainerHeight: String = "";
  bFrtSeqNo: Number = 0;
  bChargeCode: String = "";
  bRevenue: Number = 0;
  bRate: Number = 0;
  bPerCode: String = "";
  bCurrencyCode: String = "";
  bAmount: Number = 0;
  bFreightType: String = "";
  bPayerCode: String = "";
  bIsFaiGroup: String = "";
  createdDate: String = "";
  createdUserid: String = "";
  modifiedDate: String = "";
  modifiedUserid: String = "";
  deletedDate: String = "";
  deletedUserid: String = "";

  bRecordId: String = "";
  bAccountPIC: String = "";
  bApprovedUserId: String = "";
  bApprovedDate: String = "";
  popCode: String = "";
  popName: String = "";

  constructor() {}
}

export class BookingContainerInfo {
  bOfficeCode: String = "";
  bPrefix: String = "";
  bNo: String = "";
  bSeqNo: Number = 0;
  bPageNo: String = "";
  bVent: String = "";
  bVentNumber: Number;
  bVentOption: String = "O";
  bNoOfPkg: Number = 0;
  bPkgGroup: String = "";
  bReleaseDate: String = "";
  bReleaseTime: String = "";
  bPackageType: String = "";
  bPackageTypeName: String = "";
  bPSAClass: String = "";
  bContOperator: String = "";
  bIsPartLot: Boolean = false;
  bTrueShippingName: String = "";
  bContType: String = "";
  bChemicalName: String = "";
  bGrossWeight: Number = 0;
  bGrossMeas: Number = 0;
  bNettWeight: Number = 0;
  bLabel: String = "";
  bIMO: String = "";
  bUNNO: String = "";
  bFlashPoint: Number = 0.0;
  bFlashPointScale: String = "";
  bEMS: String = "";
  bMFAG: String = "";
  bContainerNo: String = "";
  bCellLocation: String = "";
  bMark: String = "";
  bReceiptType: String = "";
  bVGMEnabled: Boolean = false;
  bVGMEnabledFlag: String = "N";
  bVGMType: String = "";
  bVGMWeight: Number = 0.0;
  bOtherDetails: String = "";
  bOtherDetailsClean: String = "";
  bDGRef: String = "";

  constructor() {}
}

export class ShaBookingEDIInfo {
  edi_is_processed: String = "";
  edi_filename: String = "";
  edi_vessel_id: String = "";
  edi_vessel_code: String = "";
  edi_vessel_name: String = "";
  edi_vessel_voyage: String = "";
  edi_total_bkg_counts: Number=0;
  edi_total_bkg_processed: Number = 0;
  edi_date_upload: String = "";
  edi_time_upload: String = "";
  edi_received_userid: String = "";
  edi_type: String = "";

  constructor() {}
}

export class BookingMisc {
  bOfficeCode: String = "";
  bPrefix: String = "";
  bNo: String = "";
  bFreightRecordId: String = "";
  bFreightApprovedUserId: String = "";
  bFreightApprovedDate: String = "";
  bFreightAccountPic: String = "";
  bHaulierId: String = "";
  bContactPerson: String = "";
  bReleaseAt: String = "";
  bReleaseAtDate: String = "";
  bReleaseAtTime: String = "";
  bReturnToPor: String = "";
  bReturnToPorGateInDate: String = "";
  bReturnToPorGateInTime: String = "";
  bReturnToPorClosingDate: String = "";
  bReturnToPorClosingTime: String = "";
  bReturnToPorVgmDate: String = "";
  bReturnToPorVgmTime: String = "";
  bReturnToPol: String = "";
  bReturnToPolGateInDate: String = "";
  bReturnToPolGateInTime: String = "";
  bReturnToPolClosingDate: String = "";
  bReturnToPolClosingTime: String = "";
  bReturnToPolVgmDate: String = "";
  bReturnToPolVgmTime: String = "";
  bStuffAtPor: String = "";
  bStuffAtPorGateInDate: String = "";
  bStuffAtPorGateInTime: String = "";
  bStuffAtPorClosingDate: String = "";
  bStuffAtPorClosingTime: String = "";
  bStuffAtPorVgmDate: String = "";
  bStuffAtPorVgmTime: String = "";
  bAdditionalRemarks: String = "";
  bReceiptTypeExtra: String = "";

  bHaulierName: String = "";
  bReleaseAtName: String = "";
  bReturnToPorName: String = "";
  bReturnToPolName: String = "";
  bStuffAtPorName: String = "";
  bStuffAtPolName: String = "";
  bPrint: any = "";

  constructor() {}
}

export class BookingExtra {
  bPrefix: String = "";
  bOfficeCode: String = "";
  bReeferDegree: String = "";
  bNatureTemp: String = "N/A";
  bNo: String = "";
  bReeferCollectionDate: String = "";
  bRemarkIsPrinted: String = "";
  bDetentionPol: String = "";
  bDetentionRequestPol: Number = 0;
  bDemurragePol: String = "";
  bDemurrageRequestPol: Number = 0;
  bDetentionDemurragePodIsPrinted: String = "Y";
  bDetentionDemurragePolIsPrinted: String = "Y";
  bExtra1: String = "";
  bDegreeSo: String = "";
  bSetOption: String = "";
  bIsDry: String = "";
  bVent: String = "";
  bVentNumber: Number = 0;
  bVentOption: String = "O";
  bHumidity: String = "";
  bNextVesselVoyagePriority: string;

  constructor() {}
}
