

export class MenuManagement {
    no : number = 0;
    id: string = '';
    menuId   : string = '';
    menuLink  : string = '';
    menuName  : string = '';
    menuIconString  : string = '';
    menuOrder  : number = 0;
    menuParent  : string = '';
    isValid         : boolean = true;
    remarks       : string = '';
    dateCreated  : string ='';
    userCreated  : string ='';
    dateModified : string ='';
    userModified : string ='';
    dateInvalid  : string ='';
    userInvalid  : string ='';

    children = [];

    details = [];

    isSelect: string = '';

    constructor() {

    }
}

export class MenuDetail {
    no:number = 0;
    seqNo : number = 0;
    menuId   : string = '';
    menuName  : string = '';
    button: string = '';
}
