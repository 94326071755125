<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <!-- <input type="file" (change)="receiveBookingEDI($event)" multiple class="hidden" accept=".txt"> -->

      <!-- <div class="one field">
      <!-- <input type="file" (change)="receiveBookingEDI($event)" multiple class="hidden" accept=".txt"> -->

      <!-- <div class="one field">
        <label style="float: left;">File Path : </label>
          <div class="field" >
              <div class="ui action input">
                  <input  style ="width:50px;"  type="text" id="_attachmentName">
                  <label for="attachmentName" class="ui primary mini button btn-file">
                      <i class="attach icon"></i> &nbsp; Browse
                      <input type="file" id="attachmentName" name="attachmentName" style="display: none">
                  </label>
              </div>
          </div>
      </div>    --> 
    
      <div class="field">

         <a class="ui red ribbon label">Stage 1 : Receive Booking EDI</a>

         <!--<grid-plugin id="gridHeader" #gridHeader [settings]="settingGridHeader"  (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)">

         </grid-plugin>-->
         <div class="field" style="margin-top: 5px">
          <div *ngIf="isLoading" class="spinner"></div>

          <ag-grid-angular
                id="gridHeader"
                [getRowStyle]="getRowStyle"
                style="width: 100%; height: 500px; font-family: Lucida Console; font-size: 13px;"
                class="ag-theme-balham"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)">
          </ag-grid-angular>
         </div>
      </div>
      <div class="field">

        <div class="four fields">
          <div class="field">
          <!--   <div class="ui test checkbox" >
              <input type="checkbox"  name="chAll" tabindex="0" class="hidden">
                <label>Select All</label>
            </div>                -->
          </div>
          <!-- <div class="one field" style ="margin-left:-185px" >
            <label style="float:left">Total Selected File : </label>
            <input style="float:left; width: 150px; margin-top:-6px; " type="text" name="bound"  placeholder="Total" [(ngModel)]="model['total']" >
          </div>
          </div-->
        </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
