export class VesselAllocation{
    recordId : string='';
    vesselId : string = '';
    vesselCode : string = '';
    voyage : string = '';
    bound : string = '';
    userCreated : string = '';
    dateCreated : string ='';
    userModified : string = '';
    dateModified : string = '';

    booked : string = '';
    reserved : string = '';
    alloted: string = '';
    allotmentLevel : string = '';
    allotmentCode : string ='';
    available : string ='';

    constructor() { 
      
    }
}