import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef, 
  Renderer2
} from "@angular/core";
import { Customer, CustomerDetail } from "../shared/index";
import FormValidation from "../../utils/formValidation";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InternalNgModuleRef } from "@angular/core/src/linker/ng_module_factory";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnApi,
  GridApi,
  ICellRenderer, 
  ICellRendererParams
} from "ag-grid-community";
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

export class Param {
}

@Component({
  selector: "app-master-customer-new-page",
  templateUrl: "./master-customer-new-page.component.html",
  styleUrls: ["./master-customer-new-page.component.css"],
})
export class MasterCustomerNewPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridDetails") gridDetails: GridPluginComponent;
  @ViewChild("cbCountry") cbCountry: ComboPluginComponent;
  @ViewChild("cbCustomerHistroy") cbCustomerHistory: ComboPluginComponent;
  @ViewChild('cbVessel') cbCustName:ComboPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("gridSide") gridSide: GridPluginComponent;
  @ViewChild('inputSearch') inputSearch!: ElementRef;
  @ViewChild('listSelect') listSelect!: ElementRef;
  @ViewChild('inputSearchDN') inputSearchDN!: ElementRef;
  @ViewChild('listSelectDN') listSelectDN!: ElementRef;
  @ViewChild('inputSearchCN') inputSearchCN!: ElementRef;
  @ViewChild('listSelectCN') listSelectCN!: ElementRef;

  /* Parameter for information into Footer */
  info = "";

  dataGrid: any;

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = "";

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingCountry;
  settingCustomerHistory;
  settingGridHistory;
  settingCustName;
  settingDsplyNm;
  settingCntry;
  settingContOpr;
  settingAuCustName;
  settingAuDisName;
  settingAuCountry;
  checkboxAdvanceSearch;
  getRowStyle;
  rowData: any;
  rowData2 = [];
  pinnedBottomRowData;
  columnDefs;
  columnFinanceDefs;
  columnGeneralDefs;
  columnPartyDefs;
  columnAuditLogDefs;
  gridApi;
  gridSideApi: GridApi;
  columnApi;
  columnSideApi : ColumnApi;
  rowMultiSelectWithClickP = true;
  rowSelectionP = 'multiple';
  searchValid;

  // column changes
  pinnedColumns = [];
  sortedColumns: any = undefined;
  filterColumns: any = undefined;
  resizedColumns: any = undefined;


  typeCustomerLogStartDate = "calendar";
  typeCustomerLogEndDate = "calendar";

  /* Parameter model */
  model = new Customer();
  modelDetail = new CustomerDetail();
  formValidation = new FormValidation();
  paramRetrieve = {"customerName":"","displayName":"","country":"","isValid":"*","isDeleted":"*","category":"*","search":"*","advanceSearch": {}};
  allowRetrieve = false;
  advanceSearch = {
    "containerOwnership":"",
    "isFreightParty":"",
    "isPayer":"",
    "isBookingParty":"",
    "isShipper":"",
    "isConsignee":"",
    "isNotifyParty":"",
    "isNvocc":"",
    "isHaulier":""
  }

  loading = false;

  lock = false;
  customerIdHistory: string = "";
  customerCodeHistory: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  isSOC: boolean = false;
  isCOC: boolean = false;
  isSLOT: boolean = false;

  appStatusLabel: String = "";
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";
  tabStatus3: string = "active";
  tabStatus4: string = "active";
  
  hitUrl: string = this.configService.config.BASE_API.toString();

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {};

  custNameSearch = '/*/*/*';
  paramRetrieveEncode = '';
  
  pageOnScroll = 2;
  totalPages = 0;
  isLoadPageDone = true;
  isMsgShow = true;

  selectedItem: any;
  rowPredictive: any;
  // currentPage = 1;
  showList: boolean = false;
  noRecord: boolean = true;
  filterRows: any;
  loadingCustName: boolean = false;
  lastLengthSearch: any;

  selectedItemDN: any;
  rowPredictiveDN: any;
  showListDN: boolean = false;
  noRecordDN: boolean = true;
  filterRowsDN: any;
  loadingDN: boolean = false;

  selectedItemCN: any;
  rowPredictiveCN: any;
  showListCN: boolean = false;
  noRecordCN: boolean = true;
  filterRowsCN: any;
  loadingCN: boolean = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      toolbarType: "label",
      label: "",
      // searchBy: [
      //   { display: "Customer Name", value: "customerName" },
      //   { display: "Country", value: "countryName" },
      //   { display: "Container Ownership", value: "containerOwnership" },
      //   { display: "COC Op Code", value: "cocOperatorCode" },
      //   { display: "SOC Op Code", value: "socOperatorCode" },
      //   { display: "SLOT Op Code", value: "slotOperatorCode" },
      //   { display: "Shipper", value: "isShipper" },
      //   { display: "Consignee", value: "isConsignee" },
      //   { display: "Notify Party", value: "isNotifyParty" },
      //   { display: "Payer", value: "isPayer" },
      //   { display: "Agency", value: "isAgency" },
      //   { display: "Booking Party", value: "isBookingParty" },
      //   { display: "Haulier", value: "isHaulier" },
      //   { display: "NVOCC", value: "isNvocc" },
      //   { display: "Freight Party", value: "isFreightParty" },
      //   { display: "Supplier", value: "isSupplier" },
      //   { display: "Co. Reg. No", value: "companyRegNo" },
      //   { display: "Remarks", value: "remarks" },
      //   { display: 'COC Credit Limit', value: 'cocCreditLimit'},
      //   { display: 'COC Tolerance', value: 'cocTolerance'},
      //   { display: 'SOC Credit Limit', value: 'socCreditLimit'},
      //   { display: 'SOC Tolerance', value: 'socTolerance'},
      //   { display: 'SLOT Credit Limit', value: 'slotCreditLimit'},
      //   { display: 'SLOT Tolerance', value: 'slotTolerance'}
      // ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {
          header: "Customer Name",
          field: "customerName",
          width: 200,
          type: "string",
        },
        //General
        { header: "Valid", field: "isValid", width: 80 },
        {
          header: "Country",
          field: "countryName",
          width: 150,
          render: {
            value:
              "<i class=\"' + element['countryCode'] + ' flag\"></i> element['countryName']",
          },
        },
        {
          header: "Container Ownership",
          field: "containerOwnership",
          width: 160,
        },
        //      {header: 'Operator Code', field: 'operatorCode', width: 150},
              
        { header: "COC Op Code", field: "cocOperatorCode", width: 100 },
        { header: "SOC Op Code", field: "socOperatorCode", width: 100 },
        { header: "SLOT Op Code", field: "slotOperatorCode", width: 100 },
        { header: "Co. Reg. No", field: "companyRegNo", width: 120 },
        //END General

        //Party
        { header: "Booking Party", field: "isBookingParty", width: 110 },
        { header: "Shipper", field: "isShipper", width: 100 },
        { header: "Consignee", field: "isConsignee", width: 100 },
        { header: "Notify Party", field: "isNotifyParty", width: 100 },
        { header: "Agency", field: "isAgency", width: 100 },
        { header: "Haulier", field: "isHaulier", width: 100 },
        { header: "NVOCC", field: "isNvocc", width: 100 },
        { header: "Freight Party", field: "isFreightParty", width: 100  },
        { header: "Supplier", field: "isSupplier", width: 100 },
        //END Party

        //Finance
        { header: "Payer", field: "isPayer", width: 100 },
        { header: "COC Credit Limit", field: "cocCreditLimit", width: 80 },
        { header: "COC Tolerance (%)", field: "cocTolerance", width: 80 },
        { header: "SOC Credit Limit", field: "socCreditLimit", width: 80 },
        { header: "SOC Tolerance (%)", field: "socTolerance", width: 80 },
        { header: "SLOT Credit Limit", field: "slotCreditLimit", width: 80 },
        { header: "SLOT Tolerance (%)", field: "slotTolerance", width: 80 },
        //END Finance

        //Audit Log
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Remarks", field: "remarks", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
    };

    this.columnFinanceDefs = [
      //Finance
      {headerName: "No", field: "no", width: 100,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: hashValueGetter,
      pinned: true
      },
      {headerName: "Customer Name",field: "customerName", width: 250, pinned: true},
      {headerName: "Display Name",field: "displayName", width: 170, pinned: true},
      {headerName: "Payer", field: "isPayer", width: 100 },
      {headerName: "Frt Pty", field: "isFreightParty", width: 100  },
      {headerName: "COC A/C", field: "cocAccountCode", width: 100 },
      {headerName: "COC Cr Dy", field: "cocCreditDays", width: 100 },
      {headerName: "COC Cr Lm", field: "cocCreditLimit", width: 100 },
      {headerName: "COC Tolr%", field: "cocTolerance", width: 100 },
      {headerName: "SOC A/C", field: "socAccountCode", width: 100 },
      {headerName: "SOC Cr Dy", field: "socCreditDays", width: 100 },
      {headerName: "SOC Cr Lm", field: "socCreditLimit", width: 100 },
      {headerName: "SOC Tolr%", field: "socTolerance", width: 100 },
      {headerName: "SOC A/C", field: "slotAccountCode", width: 100 },
      {headerName: "SOC Cr Dy", field: "slotCreditDays", width: 100 },
      {headerName: "SLOT Cr Lm", field: "slotCreditLimit", width: 110 },
      {headerName: "SLOT Tolr%", field: "slotTolerance", width: 110 },
      //END Finance
    ];

    this.columnGeneralDefs = [
      //General
      {headerName: "No", field: "no", width: 100,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: hashValueGetter,
      pinned: true
      },
      {headerName: "Customer Name",field: "customerName",
       width: 250, pinned: true},
      {headerName: "Display Name",field: "displayName", width: 170, pinned: true},
        { headerName: "Valid", field: "isValid", width: 70 },
        { headerName: "Delete", field: "isDeleted", width: 80 },
        {
          headerName: "Country",
          field: "countryName",
          width: 130,
          cellRenderer: CountryIndicator
        },
        {
          headerName: "Container Ownership",
          field: "containerOwnership",
          width: 160,
        },
        //      {headerName: 'Operator Code', field: 'operatorCode', width: 150},
              
        { headerName: "COC Op Code", field: "cocOperatorCode", width: 120 },
        { headerName: "SOC Op Code", field: "socOperatorCode", width: 120 },
        { headerName: "SLOT Op Code", field: "slotOperatorCode", width: 120 },
        { headerName: "Co. Reg. No", field: "companyRegNo", width: 120 },
        //END General
    ];

    this.columnPartyDefs = [
      //Party
      {headerName: "No", field: "no", width: 100,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: hashValueGetter,
      pinned: true
      },
      {headerName: "Customer Name",field: "customerName", width: 250, pinned: true},
      {headerName: "Display Name",field: "displayName", width: 170, pinned: true},
      { headerName: "Bkg Pty", field: "isBookingParty", width: 110 },
      { headerName: "Shipper", field: "isShipper", width: 100 },
      { headerName: "Consignee", field: "isConsignee", width: 100 },
      { headerName: "Notify Pty", field: "isNotifyParty", width: 100 },
      { headerName: "Haulier", field: "isHaulier", width: 100 },
      { headerName: "NVOCC", field: "isNvocc", width: 100 },
      { headerName: "Agency", field: "isAgency", width: 100 },
      { headerName: "Supplier", field: "isSupplier", width: 100 },
        //END Party
    ];

    this.columnAuditLogDefs = [
      //Audit Log
      {headerName: "No", field: "no", width: 100,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: hashValueGetter,
      pinned: true
      },
      {headerName: "Customer Name",field: "customerName",width: 250, pinned: true},
      {headerName: "Display Name",field: "displayName", width: 170, pinned: true},
      { headerName: "User Created", field: "userCreated",},
      { headerName: "Date Created", field: "dateCreated",},
      { headerName: "User Modified", field: "userModified",},
      { headerName: "Date Modified", field: "dateModified",},
      { headerName: "User Invalid", field: "userInvalid",},
      { headerName: "Date Invalid", field: "dateInvalid",},
      { headerName: "User Delete", field: "userDeleted",},
      { headerName: "Date Delete", field: "dateDeleted",},
      { headerName: "Remarks", field: "remarks", width: 100 },
      //END Audit Log
    ];

    this.pinnedBottomRowData = this.rowData2;

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.bStatus === "D") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {
          header: "Customer Name",
          field: "customerName",
          width: 200,
          type: "string",
        },
        {
          header: "Country",
          field: "countryName",
          width: 150,
          render: {
            value:
              "<i class=\"' + element['countryCode'] + ' flag\"></i> element['countryName']",
          },
        },
        {
          header: "Container Ownership",
          field: "containerOwnership",
          width: 160,
        },
        //   {header: 'Operator Code', field: 'operatorCode', width: 150},
        { header: "COC Op Code", field: "cocOperatorCode", width: 150 },
        { header: "SOC Op Code", field: "socOperatorCode", width: 150 },
        { header: "SLOT Op Code", field: "slotOperatorCode", width: 150 },
        { header: "Shipper", field: "isShipper", width: 100 },
        { header: "Consignee", field: "isConsignee", width: 100 },
        { header: "Notify Party", field: "isNotifyParty", width: 100 },
        { header: "Payer", field: "isPayer", width: 100 },
        { header: "Agency", field: "isAgency", width: 100 },
        { header: "Booking Party", field: "isBookingParty", width: 110 },
        { header: "Haulier", field: "isHaulier", width: 100 },
        { header: "NVOCC", field: "isNvocc", width: 100 },
        { header: "Freight Party", field: "isFreightParty", width: 100  },
        { header: "Supplier", field: "isSupplier", width: 100 },
        { header: "Co. Reg. No", field: "companyRegNo", width: 120 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "Action Log", field: "action", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
    };

    this.settingGridDetails = {
      url: "",
      page: 5,
      columns: [
        { header: "Seq", field: "seqNo", width: 50 },
        { header: "Display Name", field: "customerDisplayName", width: 150 },
        { header: "Address", field: "fullAddress", width: 200 },
        { header: "Contact Person", field: "contactPerson", width: 150 },
        { header: "Tel. No(s)", field: "telno", width: 100 },
        { header: "Email", field: "email", width: 100 },
        { header: "Fax. No(s)", field: "fax", width: 100 },
        { header: "Valid", field: "isValidDetail", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "customerDisplayName",
      sortingDirection: "ASC",
    };

    this.settingCountry = {
      id: "cbCountry",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Country",
      title: "countryName",
      description: "",
      isMark: true,
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCustomerHistory = {
      id: "cbCustomerHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        { header: "Customer ID", field: "customerId", width: 50 },
        {
          header: "Customer Name",
          field: "customerName",
          width: 50,
          type: "string",
        },
        {
          header: "Container Ownership",
          field: "containerOwnership",
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCustName = {
      id          : 'cbCustName',
      type        : 'search enter', // search | select | select input
      url         :this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlAll/customerName={query}",
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Customer Name',
      title       : 'customerName',
      columns     : [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 125 },
      ]
    }

    this.settingDsplyNm = {
      id          : 'cbDslpyNm',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlDisplayName/customerDisplayName={query}",
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Display Name',
      title       : 'customerDisplayName',
      description  : '',
      columns     : [
        {header: 'Seq No', field: 'seqNo', width: 150},
        {header: 'Display Name', field: 'customerDisplayName', width: 300},
        {header: 'Customer Id', field: 'customerId', width: 75}
      ]
    }

    this.settingCntry = {
      id: "cbCntry",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Country",
      title: "countryName",
      description: "",
      isMark: true,
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingContOpr = {
      id: "cbContOpr",
      type: "select",
      url: '[ { "contOprCode" : "ALL", "contOprName" : "All" }, { "contOprCode" : "SOC", "contOprName" : "SOC" }, { "contOprCode" : "COC", "contOprName" : "COC" }, { "contOprCode" : "SLOT", "contOprName" : "SLOT" }]',
      urlType: "inject",
      placeholder: "All",
      code: "contOprCode",
      title: "contOprName",
    };

    this.checkboxAdvanceSearch = [
      {"label":"Freight Party","name":"isFreightParty","labelStyle":"font-size:0.8rem;","style":"width:10% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Payer","name":"isPayer","labelStyle":"font-size:0.8rem;","style":"width:5% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Booking Party","name":"isBookingParty","labelStyle":"font-size:0.8rem;","style":"width:10% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Shipper","name":"isShipper","labelStyle":"font-size:0.8rem;","style":"width:5% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Consignee","name":"isConsignee","labelStyle":"font-size:0.8rem;","style":"width:8% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Notify Party","name":"isNotifyParty","labelStyle":"font-size:0.8rem;","style":"width:10% !important;font-size:0.8rem;","isChecked":""},
      {"label":"NVOCC","name":"isNvocc","labelStyle":"font-size:0.8rem;","style":"width:5% !important;font-size:0.8rem;","isChecked":""},
      {"label":"Haulier","name":"isHaulier","labelStyle":"font-size:0.8rem;","style":"width:5% !important;font-size:0.8rem;","isChecked":""},
    ];

    this.searchValid = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted|Y' },
    ];

    this.settingAuCustName = {
      id          : 'auCustName',
      url         :this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlAll/customerName={query}",
      maxChars    : 3,
      placeholder : 'Search Customer Name',
      title       : 'customerName',
      valueSelect : 'customerName',
      // boxStyle    : 'width:240px !important;',
      columns     : [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 125 },
      ]
    }

    this.settingAuDisName = {
      id          : 'auDisName',
      url         : this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlDisplayName/customerDisplayName={query}",
      maxChars    : 3,
      placeholder : 'Search Display Name',
      title       : 'customerDisplayName',
      valueSelect : 'customerDisplayName',
      // boxStyle    : 'width:240px !important;',
      columns     : [
        {header: 'Seq No', field: 'seqNo', width: 150},
        {header: 'Display Name', field: 'customerDisplayName', width: 300},
        {header: 'Customer Id', field: 'customerId', width: 75}
      ]
    }

    this.settingAuCountry = {
      id          : 'auCountry',
      url         : this.configService.config.BASE_API.toString() +"/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars    : 3,
      placeholder : "Search Country",
      title       : "countryName",
      valueSelect : "countryName",
      // boxStyle    : 'width:240px !important;',
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    }
    /**
         * This events get called by all clicks on the page
         */
     this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      if (this.inputSearch != null && this.listSelect != null) {
        if(e.target !== this.inputSearch.nativeElement && e.target!==this.listSelect.nativeElement){
          this.showList=false;
        }
      }

      if (this.inputSearchDN != null && this.listSelectDN != null) {
        if(e.target !== this.inputSearchDN.nativeElement && e.target!==this.listSelectDN.nativeElement){
          this.showListDN=false;
        }
      }

      if (this.inputSearchCN != null && this.listSelectCN != null) {
        if(e.target !== this.inputSearchCN.nativeElement && e.target!==this.listSelectCN.nativeElement){
          this.showListCN=false;
        }
      }
    });
    
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // this.grid.search = "*/*/*";
    // this.grid.onFirst();

    // this.gridSide.search = "*/*/*";
    // this.gridSide.onFirst();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    $(".special.cards .image").dimmer({
      on: "hover",
    });
    this.firstInit();

    this.setValidatorHeader();

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // this.grid.search = "*/*/*";
    // this.grid.onFirst();
    
    // this.gridSide.search = "*/*/*";
    // this.gridSide.onFirst();
    this.genericUtil.setBreadcrumb(
      "Master,Entity,Master-Customer:/main/master-customer"
    );

    $(".menu .item").tab();
    
    setTimeout(() => {
      $('#rightmenu').hide();
      // this.initTab2();
      // console.log(this.grid);
    }, 100);
    
  }

  changeEventCustName(event) {
    var actRetrieve = false;
    if (event != null) {
      if (event.type == 'change') {
        this.allowRetrieve = true;
      }
    }
    
    if (event["customerName"] != null) {
      if (event["title"] != this.paramRetrieve.customerName) {
        this.allowRetrieve = true;
      }
      this.paramRetrieve.customerName = this.replaceNbsps(event["title"]);
      actRetrieve = true;
    } else {
      this.paramRetrieve.customerName = "";
    }

    if ($('#cbCustName').val() == '') {
      actRetrieve = true;
      this.allowRetrieve = true;
    }

    this.buildParamEncode();
    if (this.allowRetrieve == true && actRetrieve == true) {
      this.handleRetrieve();
      this.allowRetrieve = false;
    }
  }

  changeEventDsplyNm(event) {
    var actRetrieve = false;
    if (event != null) {
      if (event.type == 'change') {
        this.allowRetrieve = true;
      }
    }

    if (event["customerDisplayName"] != null) {
      if (event["title"] != this.paramRetrieve.displayName) {
        this.allowRetrieve = true;
      }
      this.paramRetrieve.displayName = this.replaceNbsps(event["title"]);
      actRetrieve = true;
    } else {
      this.paramRetrieve.displayName = "";
    }

    if ($('#cbDslpyNm').val() == '') {
      actRetrieve = true;
      this.allowRetrieve = true;
    }
    
    this.buildParamEncode();
    if (this.allowRetrieve == true && actRetrieve == true) {
      this.handleRetrieve();
      this.allowRetrieve = false;
    }
  }

  changeEventCntry(event) {
    var actRetrieve = false;
    if (event != null) {
      if (event.type == 'change') {
        this.allowRetrieve = true;
      }
    }

    if (event["countryName"] != null) {
      if (event["title"] != this.paramRetrieve.country) {
        this.allowRetrieve = true;
      }
      this.paramRetrieve.country = this.replaceNbsps(event["title"]);
      actRetrieve = true;
    } else {
      this.paramRetrieve.country = "";
    }

    if ($('#cbCntry').val() == '') {
      actRetrieve = true;
      this.allowRetrieve = true;
    }

    this.buildParamEncode();
    if (this.allowRetrieve == true && actRetrieve == true) {
      this.handleRetrieve();
      this.allowRetrieve = false;
    }
  }

  buildParamEncode() {
    var paramCustom = '';
    paramCustom += "customerName:"+this.paramRetrieve.customerName+"|";
    paramCustom += "displayName:"+this.paramRetrieve.displayName+"|";
    paramCustom += "countryName:"+this.paramRetrieve.country+"|";
    paramCustom += "isValid:"+this.paramRetrieve.isValid+"|";
    paramCustom += "isDeleted:"+this.paramRetrieve.isDeleted+"|";
    paramCustom += "category:"+this.paramRetrieve.category+"|";
    paramCustom += "containerOwnership:"+this.advanceSearch.containerOwnership+"|";
    paramCustom += "isFreightParty:"+this.advanceSearch.isFreightParty+"|";
    paramCustom += "isPayer:"+this.advanceSearch.isPayer+"|";
    paramCustom += "isBookingParty:"+this.advanceSearch.isBookingParty+"|";
    paramCustom += "isShipper:"+this.advanceSearch.isShipper+"|";
    paramCustom += "isConsignee:"+this.advanceSearch.isConsignee+"|";
    paramCustom += "isNotifyParty:"+this.advanceSearch.isNotifyParty+"|";
    paramCustom += "isNvocc:"+this.advanceSearch.isNvocc+"|";
    paramCustom += "isHaulier:"+this.advanceSearch.isHaulier+"|";
    paramCustom += "search:"+this.paramRetrieve.search;
    // console.log(this.paramRetrieve);
    this.paramRetrieveEncode = btoa(paramCustom);
  }

  replaceNbsps(str) {
    var re = new RegExp(String.fromCharCode(160), "g");
    return str.replace(re, " ");
  }

  // onShowList(state: boolean) {
  //   this.showList = state;
  // }

  // onChooseItem(row: any) {
  //   this.selectedItem = row;
  //   this.showList = false;
  //   this.inputSearch.nativeElement.value = row.customerName;
    
    
  //   if (row.customerName != null) {
  //     this.paramRetrieve.customerName = this.replaceNbsps(row.customerName);
  //   } else {
  //     this.paramRetrieve.customerName = "";
  //   }

  //   this.buildParamEncode();
  //   this.handleRetrieve();
  // }

  // onSearchRowPredictive(event) {
  //   // console.log(event.target.value);
  //   var value = (event.target.value || '').toUpperCase();
    
  //   if (value.length >= 3) {
  //     this.noRecord = true;
  //     this.prepareData('customerName='+value);
  //     this.showList = true;
  //   } else if (value.length == 0 && this.paramRetrieve.customerName != '') {
  //     this.paramRetrieve.customerName = '';
  //     this.buildParamEncode();
  //     this.handleRetrieve();
  //   } else {
  //     this.showList = false;
  //   }
    
  // }

  // prepareData(query: string) {
  //   this.loadingCustName = true;
  //   // let vThis = this;
  //   this.genericService
  //     .GET(this.configService.config.BASE_API.toString() +
  //     "/MasterCustomers/findByComboBoxControlAll/"+query
  //     ).subscribe(
  //       (resp) => {
  //         this.loadingCustName = false;
  //         this.rowPredictive = resp.json()["content"];
  //         this.filterRows = this.rowPredictive;
  //         if (this.filterRows.length > 0) {
  //           this.noRecord = false;
  //         } else {
  //           this.noRecord = true;
  //         }
  //       },
  //       err => console.log('HTTP Error', err),
  //       () => this.loadingCustName = false
  //     );
  // }


  // onShowListDN(state: boolean) {
  //   this.showListDN = state;
  // }

  // onChooseItemDN(row: any) {
  //   this.selectedItemDN = row;
  //   this.showListDN = false;
  //   this.inputSearchDN.nativeElement.value = row.customerDisplayName;
    
  //   if (row.customerDisplayName != null) {
  //     this.paramRetrieve.displayName = this.replaceNbsps(row.customerDisplayName);
  //   } else {
  //     this.paramRetrieve.displayName = "";
  //   }

  //   this.buildParamEncode();
  //   this.handleRetrieve();
  // }

  // onSearchRowPredictiveDN(event) {
  //   // console.log(event.target.value);
  //   var value = (event.target.value || '').toUpperCase();
  //   if (value.length >= 3) {
  //     this.noRecordDN = true;
  //     this.prepareDataDN('customerDisplayName='+value);
  //     this.showListDN = true;
  //   } else if (value.length == 0 && this.paramRetrieve.displayName != '') {
  //     this.paramRetrieve.displayName = '';
  //     this.buildParamEncode();
  //     this.handleRetrieve();
  //   } else {
  //     this.showListDN = false;
  //   }
    
  // }

  // prepareDataDN(query: string) {
  //   this.loadingDN = true;
  //   // let vThis = this;
  //   this.genericService
  //     .GET(this.configService.config.BASE_API.toString() +
  //     "/MasterCustomers/findByComboBoxControlDisplayName/"+query
  //     ).subscribe(
  //       (resp) => {
  //         this.loadingDN = false;
  //         this.rowPredictiveDN = resp.json()["content"];
  //         this.filterRowsDN = this.rowPredictiveDN;
  //         if (this.filterRowsDN.length > 0) {
  //           this.noRecordDN = false;
  //         } else {
  //           this.noRecordDN = true;
  //         }
  //       },
  //       err => console.log('HTTP Error', err),
  //       () => this.loadingDN = false
  //     );
  // }


  // onShowListCN(state: boolean) {
  //   this.showListCN = state;
  // }

  // onChooseItemCN(row: any) {
  //   this.selectedItemCN = row;
  //   this.showListCN = false;
  //   this.inputSearchCN.nativeElement.value = row.countryName;
    
  //   if (row.countryName != null) {
  //     this.paramRetrieve.country = this.replaceNbsps(row.countryName);
  //   } else {
  //     this.paramRetrieve.country = "";
  //   }

  //   this.buildParamEncode();
  //   this.handleRetrieve();
  // }

  // onSearchRowPredictiveCN(event) {
  //   // console.log(event.target.value);
  //   var value = (event.target.value || '').toUpperCase();
  //   if (value.length >= 3) {
  //     this.noRecordCN = true;
  //     this.prepareDataCN(value);
  //     this.showListCN = true;
  //   } else if (value.length == 0 && this.paramRetrieve.country != '') {
  //     this.paramRetrieve.country = '';
  //     this.buildParamEncode();
  //     this.handleRetrieve();
  //   } else {
  //     this.showListCN = false;
  //   }
    
  // }

  // prepareDataCN(query: string) {
  //   this.loadingCN = true;
  //   // let vThis = this;
  //   this.genericService
  //     .GET(this.configService.config.BASE_API.toString() +
  //     "/MasterCountries/findGeneral/*/*/"+query+"/1/5/*/*"
  //     ).subscribe(
  //       (resp) => {
  //         this.loadingCN = false;
  //         this.rowPredictiveCN = resp.json()["content"];
  //         this.filterRowsCN = this.rowPredictiveCN;
  //         if (this.filterRowsCN.length > 0) {
  //           this.noRecordCN = false;
  //         } else {
  //           this.noRecordCN = true;
  //         }
  //       },
  //       err => console.log('HTTP Error', err),
  //       () => this.loadingCN = false
  //     );
  // }

  onCheckboxClick(data) {
    if (data.name == 'isFreightParty') {
      if (data.isChecked == true) {
        this.advanceSearch.isFreightParty = 'Y';
      } else {
        this.advanceSearch.isFreightParty = '';
      }
    } else if (data.name == 'isPayer') {
      if (data.isChecked == true) {
        this.advanceSearch.isPayer = 'Y';
      } else {
        this.advanceSearch.isPayer = '';
      }
    } else if (data.name == 'isBookingParty') {
      if (data.isChecked == true) {
        this.advanceSearch.isBookingParty = 'Y';
      } else {
        this.advanceSearch.isBookingParty = '';
      }
    } else if (data.name == 'isShipper') {
      if (data.isChecked == true) {
        this.advanceSearch.isShipper = 'Y';
      } else {
        this.advanceSearch.isShipper = '';
      }
    } else if (data.name == 'isConsignee') {
      if (data.isChecked == true) {
        this.advanceSearch.isConsignee = 'Y';
      } else {
        this.advanceSearch.isConsignee = '';
      }
    } else if (data.name == 'isNvocc') {
      if (data.isChecked == true) {
        this.advanceSearch.isNvocc = 'Y';
      } else {
        this.advanceSearch.isNvocc = '';
      }
    }  else if (data.name == 'isNotifyParty') {
      if (data.isChecked == true) {
        this.advanceSearch.isNotifyParty = 'Y';
      } else {
        this.advanceSearch.isNotifyParty = '';
      }
    } else if (data.name == 'isHaulier') {
      if (data.isChecked == true) {
        this.advanceSearch.isHaulier = 'Y';
      } else {
        this.advanceSearch.isHaulier = '';
      }
    }
    
    this.paramRetrieve.advanceSearch = this.advanceSearch;
    this.buildParamEncode();
    this.handleRetrieve();
  }

  changeEventContOpr(event) {
    if (
      event.contOprCode != null &&
      event.contOprCode !== "" &&
      event.contOprCode !== undefined
    ) {

      if (event.contOprCode == "ALL") {
        this.advanceSearch.containerOwnership = "";
        this.paramRetrieve.advanceSearch = this.advanceSearch;
      } else {
        this.advanceSearch.containerOwnership = event.contOprCode;
        this.paramRetrieve.advanceSearch = this.advanceSearch;
      }
      this.buildParamEncode();
      this.handleRetrieve();
    } else {
      this.advanceSearch.containerOwnership = "";
      this.paramRetrieve.advanceSearch = this.advanceSearch;
    }
  }

  onSelectValid(val) {
    if (val.includes("isDeleted")) {
      this.paramRetrieve.isValid = '*';
      this.paramRetrieve.isDeleted = "Y";
    } else {
      this.paramRetrieve.isValid = val;
      this.paramRetrieve.isDeleted = "*";
    }
    
    this.buildParamEncode();
    this.handleRetrieve();
  }

  sanitize(style: string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  inputTypedCustName(value){
    if (value.length == 0 && this.paramRetrieve.customerName != '') {
      this.paramRetrieve.customerName = '';
      this.buildParamEncode();
      this.handleRetrieve();
    };
  }

  selectItemCustName(row){
    if (row.valueSelect != null) {
      this.paramRetrieve.customerName = this.replaceNbsps(row.valueSelect);
    } else {
      this.paramRetrieve.customerName = "";
    }
    this.buildParamEncode();
    this.handleRetrieve();
  }

  inputTypedDisName(value){
    if (value.length == 0 && this.paramRetrieve.displayName != '') {
      this.paramRetrieve.displayName = '';
      this.buildParamEncode();
      this.handleRetrieve();
    };
  }

  selectItemDisName(row){
    if (row.valueSelect != null) {
      this.paramRetrieve.displayName = this.replaceNbsps(row.valueSelect);
    } else {
      this.paramRetrieve.displayName = "";
    }
    this.buildParamEncode();
    this.handleRetrieve();
  }

  inputTypedCountry(value){
    if (value.length == 0 && this.paramRetrieve.country != '') {
      this.paramRetrieve.country = '';
      this.buildParamEncode();
      this.handleRetrieve();
    };
  }

  selectItemCountry(row){
    if (row.valueSelect != null) {
      this.paramRetrieve.country = this.replaceNbsps(row.valueSelect);
    } else {
      this.paramRetrieve.country = "";
    }
    this.buildParamEncode();
    this.handleRetrieve();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridDetails(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        case "afterLoad":
          
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleUpdate();
              break;
            case "dblClick":
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;

              let dt = this.grid.getSelectedValues()[0];
              let custId: string = String(dt.customerId).trim();
              this.onRetrieve(custId);

              if (this.model.isCoc == true) {
                this.isCOC = true;
              } else {
                this.isCOC = false;
              }

              if (this.model.isSoc == true) {
                this.isSOC = true;
              } else {
                this.isSOC = false;
              }

              if (this.model.isSlot == true) {
                this.isSLOT = true;
              } else {
                this.isSLOT = false;
              }

              this.lock = true;
              if(this.viewOnlyStatus){
                $("#display").transition("hide");
                $("#displayGrid").transition();
              } else {
                this.gridDetails.disable();
              }
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetails(event: any) {
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.setValidatorDetails();
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "save,cancel";
              }

              if (this.model["error-customerName"] != null) {
                this.model["error-customerName"] = "";
              }

              let dt = this.gridDetails.getSelectedValues()[0];
              console.log(dt)
              this.gridDetails.listStore.store.forEach((element) => {
                if (dt.no == element["no"]) {
                  this.modelDetail = new CustomerDetail();
                  this.modelDetail = element;
                  
                  if(element["isValidDetail"] == 'Y'){
                    console.log("isValidDetail : "+element["isValidDetail"]);
                    this.modelDetail.isValidD = true;
                  }else{
                    console.log("isValidDetail2 : "+element["isValidDetail"]);
                    this.modelDetail.isValidD = false;
                    console.log("isValidDetail21 : "+ this.modelDetail.isValidD);
                  }
                  
                  // console.log(this.modelDetail);
                }
              });

              $("#display").transition();
              $("#displayGrid").transition("hide");

              break;

            default:
              break;
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    // console.log(event);
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.customerId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
            // console.log(str);
            this.custNameSearch = str.replace("search:", "");
            var searchToolbar = this.custNameSearch.split('/');
            this.paramRetrieve.isValid = searchToolbar[0];
            this.paramRetrieve.category = searchToolbar[1];
            this.paramRetrieve.search = searchToolbar[2];
            this.handleRetrieve();
          // this.grid.search = str.replace("search:", "");
          // this.grid.clearSelectedValues();
          // this.grid.onFirst();
          this.gridDetails.search = str.replace("search:", "");
          this.gridDetails.clearSelectedValues();
          this.gridDetails.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
    this.handleRetrieve();
    // *****Checking If There's any Pinned Columns***** //
    if (localStorage.getItem("pinned-columns") != null) {
      this.pinnedColumns = JSON.parse(localStorage.getItem("pinned-columns"));
    }
  }

  // onGridSideReady(params) {
  //   console.log(params);
  //   this.gridSideApi = params.api;
  //   this.columnSideApi = params.columnApi;
  //   this.gridSideApi.hideOverlay();
  //   this.gridSideApi.sizeColumnsToFit();
  //   // this.handleRetrieve();
  //   $('#sideGrid').find('.ag-body-viewport').css('overflow','hidden');

  //   // *****Checking If There's any Pinned Columns***** //
  //   if (localStorage.getItem("pinned-columns") != null) {
  //     this.pinnedColumns = JSON.parse(localStorage.getItem("pinned-columns"));
  //   }
  // }

  onFirstDataRendered(params) {
    console.log("** onFirstDataRendered **");
    this.pinnedColumns.map((item) => {
      this.columnApi.setColumnPinned(item.colId, item.pinned);

      console.log("item.sort = ");
      console.log(item.sort);

      if (item.sort) {
        this.gridApi.setSortModel([item]);
      }

      if (item.filter) {
        this.gridApi.setFilterModel(item.filter);
      }

      if (item.resized) {
        const { columnWidths, finished, source } = item.resized;

        columnWidths.map((item) => {
          this.columnApi.setColumnWidth(
            item.key,
            item.newWidth,
            finished,
            source
          );
        });
      }
    });
  }

  onRowClicked(event){
    if(event.node.isSelected()){
      // console.log("deselected");
      event.node.setSelected(false, false);
    } else {
      event.node.setSelected(true);
      // console.log("selected, add");
    }
  
  }

  rowDoubleClick(params) {
    // this.fullBookingNo = "";
    let dt = params.data;
    if (params.data.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";
      
      console.log(dt);
      let custId: string = String(dt.customerId).trim();
      this.onRetrieve(custId);

      if (this.model.isCoc == true) {
        this.isCOC = true;
      } else {
        this.isCOC = false;
      }

      if (this.model.isSoc == true) {
        this.isSOC = true;
      } else {
        this.isSOC = false;
      }

      if (this.model.isSlot == true) {
        this.isSLOT = true;
      } else {
        this.isSLOT = false;
      }

      this.lock = false;
      this.gridDetails.enable();
    }
    
  }

  handleRetrieve() {
    // ag-grid load
    this.gridApi.showLoadingOverlay();
    this.buildParamEncode();
    // customerName:|countryName:|isValid:*|category:*|search:*
    // "/MasterCustomers/findCustomGeneral/1/100/ASC/CustomerName/"+this.paramRetrieveEncode
    this.genericService
      .GET(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findCustomGeneral/1/100/ASC/CustomerName/"+this.paramRetrieveEncode
      // "/MasterCustomers/findGeneral/"+this.custNameSearch+"/1/100/ASC/CustomerName"
      )
      .subscribe((resp) => {
        this.dataGrid = resp.json();
        // console.log(resp.json()["content"]);
        this.rowData = resp.json()["content"];
        this.totalPages = resp.json()["totalPages"] * 1;
        this.pageOnScroll = 2;
        this.isMsgShow = false;
        this.gridApi.hideOverlay();
      });
  }

  onSelectionChanged(params) {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.disableToolbarButtons ="";
    } else {
      this.disableToolbarButtons ="update,delete";
    }
  }

  handleScroll(params) {
    // console.log(this.gridApi.getDisplayedRowCount());
    // console.log(this.gridApi.getLastDisplayedRow());
    // console.log(this.isLoadPageDone);
    // console.log(this.pageOnScroll);
    // console.log(this.totalPages);
    if (this.gridApi.getLastDisplayedRow() + 1 >= this.gridApi.getDisplayedRowCount()) {
      if (this.pageOnScroll > this.totalPages) {
        if (this.isMsgShow == true) {
          this.message(
            "warning",
            "Error Input",
            "All data is displayed",
            "okonly",
            { ok: "this.loading=false;" }
          );
          this.isMsgShow = false;
        }
      } else {
        if (this.isLoadPageDone == true) {
          this.isLoadPageDone = false;
          this.gridApi.showLoadingOverlay();
          this.genericService
          .GET(this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findCustomGeneral/"+this.pageOnScroll+"/100/ASC/CustomerName/"+this.paramRetrieveEncode
          // "/MasterCustomers/findGeneral/"+this.custNameSearch+"/"+this.pageOnScroll+"/100/ASC/CustomerName"
          )
          .subscribe((resp) => {
            this.dataGrid = resp.json();
            // console.log(resp.json()["content"]);
            var rowDataRes = [];
            rowDataRes = resp.json()["content"];
            this.rowData = this.rowData.concat(rowDataRes);
            this.gridApi.hideOverlay();
            this.totalPages = resp.json()["totalPages"] * 1;
            this.pageOnScroll = this.pageOnScroll + 1;
            this.isLoadPageDone = true;
            this.isMsgShow = true;
          });
        } 
      }
    }
  }

  handleRowDataChanged(params) {
    // console.log(params);
  }

  sortChanged(params: any) {
    // console.log("** sortChanged **");
    // console.log(params.api.getSortModel());
    const selectedColumns = params.api.getSortModel()[0];
    // console.log(selectedColumns);
    
    this.sortedColumns = selectedColumns;
  }

  handleCreate() {
    // handle create event

    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Customer();
    this.cbCountry.setValue("");
    this.model.isValid = true;
    this.modelDetail = new CustomerDetail();
    this.gridDetails.listStore.store = [];
    this.gridDetails.loadData();

    this.genericUtil.setBreadcrumb(
      "Master,Entity,Master-Customer:/main/master-customer,Form"
    );
  }

  handleUpdate() {
    // handle update event

    let dt = this.gridApi.getSelectedRows()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";

      // let dt = this.grid.getSelectedValues()[0];
      
      let custId: string = String(dt.customerId).trim();
      this.onRetrieve(custId);

      if (this.model.isCoc == true) {
        this.isCOC = true;
      } else {
        this.isCOC = false;
      }

      if (this.model.isSoc == true) {
        this.isSOC = true;
      } else {
        this.isSOC = false;
      }

      if (this.model.isSlot == true) {
        this.isSLOT = true;
      } else {
        this.isSLOT = false;
      }

      this.lock = false;
      this.gridDetails.enable();
    }
  }

  handleDelete() {
    this.loading = true;
    let deleteList = [];
    this.gridApi.getSelectedRows().forEach((element) => {
      deleteList.push(element.customerId);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListCustomerIdUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            
            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
            
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );

    // handle delete event
    
  }

  handlePrint() {
    // handle print event
    var splitUrl = this.configService.config.getPDFUrl.split(":");
    window.open(
      this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
        "/Mreport/runReport/39/Customers-Export-Reports/*"
    );
  }

  validationOperatorCode(opCode) {
    const splitCode = this.model[opCode].split(",");
    splitCode.forEach((item) => {
      if (item.length < 2 || item.length > 4) {
        this.model[`error-${opCode}`] =
          "OP character should be max has 4 char min has 2 char.";
      }
    });
  }

 checkValidGridDetails():number{
  let x:number;
  x=0;
  this.gridDetails.listStore.store.forEach((element) => {      
   
   
      if(element["isValidDetail"] == 'Y'){
        x+=1;
      }    
  });
  return x;
 }

  handleSave() {
    // handle save event
    this.model.dateCreated = this.genericUtil.getPSIDate();
    this.model.masterCustomerDetails = [];
    this.model.masterCustomerDetails = this.gridDetails.listStore.store;    
    

    let isValid: boolean = this.onValidate(this.model);

    //additional validation
    if (this.model.isBookingParty) {
      if (this.model.isCoc) {
        // this.validationOperatorCode("cocOperatorCode");
        if (this.model.cocOperatorCode.trim() == "") {
          this.model["error-cocOperatorCode"] = "Please input OP Code(s).";
          isValid = true;
        }
      }
      if (this.model.isSoc && this.model.socOperatorCode.trim() == "") {
        // this.validationOperatorCode("socOperatorCode");
        this.model["error-socOperatorCode"] = "Please input OP Code(s).";
        isValid = true;
      }
      if (this.model.isSlot && this.model.slotOperatorCode.trim() == "") {
        // this.validationOperatorCode("slotOperatorCode");
        this.model["error-slotOperatorCode"] = "Please input OP Code(s).";
        isValid = true;
      }
    }

    if (!isValid) {
      if (
        !this.model.isShipper &&
        !this.model.isConsignee &&
        !this.model.isNotifyParty &&
        !this.model.isPayer &&
        !this.model.isAgency &&
        !this.model.isBookingParty &&
        !this.model.isHaulier &&
        !this.model.isNvocc &&
        !this.model.isSupplier &&
        !this.model.isFreightParty
      ) {
        this.message(
          "warning",
          "Error Input",
          "Please select at least one (1) option for Customer Type.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else if (!this.model.isSoc && !this.model.isCoc && !this.model.isSlot) {
        this.message(
          "warning",
          "Error Input",
          "Please select at least one (1) option for Container Ownership.",
          "okonly",
          { ok: "this.loading=false;" }
        );
        /*
      }else if(this.model.masterCustomerDetails.length == 0){
        this.message('warning','Error Input', 'Please insert customer detail.', 'okonly',{ok:'this.loading=false;'});
      */
      }else if(this.checkValidGridDetails() < 1){
        this.message(
          "warning",
          "Error Input",
          "There must be at least 1 Set of Valid Details. No saving is allowed.",
          "okonly",
          { ok: "this.loading=false;" }
        );

      } else {
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          { yes: "this.saveEvent()", no: "this.loading = false;" }
        );
      }
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.model = new Customer();
    this.modelDetail = new CustomerDetail();
    this.gridDetails.onClear();

    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;

    this.cbCustomerHistory.setValue("");
    this.cbCustomerHistory.close();
    this.customerIdHistory = "";
    this.customerCodeHistory = "";
    this.gridHistory.onClear();
    this.gridHistory.onHistory("");
    if(this.viewOnlyStatus){
      this.cancelDetail();
    }
    this.gridApi.deselectAll();

  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  onRetrieve(customerId: string) {
    //// console.log('OnRetrieve : ' + customerId)
    this.gridDetails.onClear();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findById/" +
          customerId
      )
      .subscribe((resp) => {
        let data = resp.json();
        this.setData(data);
      });
  }

  setData(data: any) {
    this.model = new Customer();
    this.model.customerId = String(data.customerId).trim().toUpperCase();
    this.model.customerCode = String(data.customerCode).trim();
    this.model.customerName = String(data.customerName)
      .trim()
      .replace(/&NBSP;/g, " ");
    this.model.countryCode = String(data.countryCode).trim().toUpperCase();
    this.model.countryName = String(data.countryName).trim().toUpperCase();
    this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());
    this.model.companyRegNo = String(data.companyRegNo).trim().toUpperCase();
    this.model.remarks = String(data.remarks).trim().toUpperCase();

    if (String(data.isValid).trim().toUpperCase() == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    if (String(data.isShipper).trim().toUpperCase() == "Y") {
      this.model.isShipper = true;
    } else {
      this.model.isShipper = false;
    }

    if (String(data.isConsignee).trim().toUpperCase() == "Y") {
      this.model.isConsignee = true;
    } else {
      this.model.isConsignee = false;
    }

    if (String(data.isNotifyParty).trim().toUpperCase() == "Y") {
      this.model.isNotifyParty = true;
    } else {
      this.model.isNotifyParty = false;
    }

    if (String(data.isPayer).trim().toUpperCase() == "Y") {
      this.model.isPayer = true;
    } else {
      this.model.isPayer = false;
    }

    if (String(data.isAgency).trim().toUpperCase() == "Y") {
      this.model.isAgency = true;
    } else {
      this.model.isAgency = false;
    }

    if (String(data.isBookingParty).trim().toUpperCase() == "Y") {
      this.model.isBookingParty = true;
    } else {
      this.model.isBookingParty = false;
    }

    if (String(data.isHaulier).trim().toUpperCase() == "Y") {
      this.model.isHaulier = true;
    } else {
      this.model.isHaulier = false;
    }

    if (String(data.isNvocc).trim().toUpperCase() == "Y") {
      this.model.isNvocc = true;
    } else {
      this.model.isNvocc = false;
    }

    if (String(data.isSupplier).trim().toUpperCase() == "Y") {
      this.model.isSupplier = true;
    } else {
      this.model.isSupplier = false;
    }

    if (String(data.isFreightParty).trim().toUpperCase() == "Y") {
      this.model.isFreightParty = true;
    } else {
      this.model.isFreightParty = false;
    }

    if (String(data.isCoc).trim().toUpperCase() == "Y") {
      this.model.isCoc = true;
    } else {
      this.model.isCoc = false;
    }

    if (String(data.isSoc).trim().toUpperCase() == "Y") {
      this.model.isSoc = true;
    } else {
      this.model.isSoc = false;
    }

    if (String(data.isSlot).trim().toUpperCase() == "Y") {
      this.model.isSlot = true;
    } else {
      this.model.isSlot = false;
    }

    if (data.cocOperatorCode != null) {
      this.model.cocOperatorCode = String(data.cocOperatorCode)
        .trim()
        .toUpperCase();
    }
    if (data.socOperatorCode != null) {
      this.model.socOperatorCode = String(data.socOperatorCode)
        .trim()
        .toUpperCase();
    }
    if (data.slotOperatorCode != null) {
      this.model.slotOperatorCode = String(data.slotOperatorCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocAccountCode != null) {
      this.model.cocAccountCode = String(data.cocAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.socAccountCode != null) {
      this.model.socAccountCode = String(data.socAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.slotAccountCode != null) {
      this.model.slotAccountCode = String(data.slotAccountCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocCreditDays != null) {
      this.model.cocCreditDays = Number.parseInt(data.cocCreditDays);
    }
    if (data.socCreditDays != null) {
      this.model.socCreditDays = Number.parseInt(data.socCreditDays);
    }
    if (data.slotCreditDays != null) {
      this.model.slotCreditDays = Number.parseInt(data.slotCreditDays);
    }

    if (data.cocCreditLimit != null) {
      this.model.cocCreditLimit = data.cocCreditLimit;
    }

    if (data.cocTolerance != null) {
      this.model.cocTolerance = Number.parseInt(data.cocTolerance);
    }

    if (data.socCreditLimit != null) {
      this.model.socCreditLimit = data.socCreditLimit;
    }

    if (data.socTolerance != null) {
      this.model.socTolerance = Number.parseInt(data.socTolerance);
    }

    if (data.slotCreditLimit != null) {
      this.model.slotCreditLimit = data.slotCreditLimit;
    }

    if (data.slotTolerance != null) {
      this.model.slotTolerance = Number.parseInt(data.slotTolerance);
    }

    // console.log(data);
    if (data["masterCustomerDetails"] != null) {
      //this.settingGridDetails.listStore.store = [];
      this.model.masterCustomerDetails = [];
      this.model.masterCustomerDetails = data["masterCustomerDetails"];
      //this.gridDetails.listStore.store = this.model.masterCustomerDetails;
      this.model.masterCustomerDetails.forEach((fe)=>{
        // console.log(fe);
        this.gridDetails.listStore.addData(fe);
      });

      this.gridDetails.loadData();
    }

    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
  }

  changeEventOwnership(event: any) {
    //// console.log(event);
    let e: string = event;
    let ee: string[] = e.split("-");
    switch (ee[0]) {
      case "COC":
        if (ee[1] == "true") {
          this.isCOC = true;
        } else {
          this.isCOC = false;
          this.model.cocOperatorCode = "";
          /*
          this.model.cocOperatorCode = '';
          this.model.cocAccountCode = '';
          this.model.cocCreditDays = 0;
          */
        }

        break;

      case "SOC":
        if (ee[1] == "true") {
          this.isSOC = true;
        } else {
          this.isSOC = false;
          this.model.socOperatorCode = "";
          /*
          this.model.socOperatorCode = '';
          this.model.socAccountCode = '';
          this.model.socCreditDays = 0;
          */
        }
        break;

      case "SLOT":
        if (ee[1] == "true") {
          this.isSLOT = true;
        } else {
          this.isSLOT = false;
          this.model.slotOperatorCode = "";
          /*
          this.model.slotOperatorCode = '';
          this.model.slotAccountCode = '';
          this.model.slotCreditDays = 0;
          */
        }
        break;

      default:
        break;
    }
  }

  changeEventCountry(event: any) {
    this.model["error-countryName"] = null;

    if (event["countryCode"] != null) {
      this.model.countryCode = event["countryCode"];
    } else {
      this.model.countryCode = "";
    }

    if (event["countryName"] != null) {
      this.model.countryName = event["countryName"];
    } else {
      this.model.countryName = "";
    }
  }

  changeEventCustomerHistory(event: any) {
    this.customerIdHistory = "";
    this.customerCodeHistory = "";
    // console.log(event);
    // if(event.customerId == ''){
    //   if(event.target != ''){

    //   }

    // }else{
    this.customerIdHistory = String(event.customerId).trim();
    this.customerCodeHistory = String(event.customerCode).trim();
    this.gridHistory.onHistory(this.customerIdHistory);
    // }
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;

    // if (this.grid.getSelectedValues().length >= 1) {
    //   this.grid.onSortDir("dateModified", "DESC");
    // } else {
    //   this.grid.onSortDir("dateCreated", "DESC");
    // }

    // this.grid.clearSelectedValues();
    //  this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  saveEvent() {
    this.loading = true;
    //// console.log(this.model);

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterCustomers/save",
        this.model
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //clear all
            this.model = new Customer();
            this.cbCountry.setValue("");
            this.modelDetail = new CustomerDetail();
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();
            this.handleRetrieve();
            this.loading = false;
            this.info = "save successfully";

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  deleteEvent() {
    this.loading = true;
    let deleteList = [];
    this.gridApi.getSelectedRows().forEach((element) => {
      deleteList.push(element.customerId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"customerId":[]}
    paramsDelete.customerId = deleteList;
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          // "/MasterCustomers/delete",
          // deleteList
          "/MasterCustomers/softDelete",
          paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new Customer();
            this.cbCountry.setValue("");
            this.modelDetail = new CustomerDetail();
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();

            this.loading = false;

            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false;this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  setValidatorHeader() {
    this.validatorRules = {
      customerName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Customer Name.",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'@,^&*(\)\+[\]/:;"\.  ]*$/,
            prompt: "Invalid Customer Name",
          },
        ],
      },

      companyRegNo: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9]*$/,
            prompt: "Invalid Company Registration Number",
          },
        ],
      },

      countryName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Country.",
          },
        ],
      },

      cocOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      socOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      slotOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      cocCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },

      socCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },

      slotCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },
    };
  }

  setValidatorDetails() {
    this.validatorRules = {
      customerDisplayName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Display Name.",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'@,^&*(\)\+[\]/:;"\. ]*$/,
            prompt: "Invalid Customer Display Name",
          },
        ],
      },
      contactPerson: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'&(\.) ]*$/,
            prompt: "Invalid Contact Person",
          },
        ],
      },
      fullAddress: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Display Address.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regAllChar,
            prompt:
              "Please input valid characters (alphanumeric and -#&@^*()_+';:\"\\/,.)",
          },
        ],
      },
      email: {
        rules: [
          {
            type: "emailMultiple",
            prompt: "Invalid email format.",
          },
        ],
      },
    };
  }

  addDetail() {
    this.setValidatorDetails();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "save,cancel";
    }

    if (this.model["error-customerName"] != null) {
      this.model["error-customerName"] = "";
    }

    this.modelDetail = new CustomerDetail();
    this.modelDetail.isValidD = true;
    $("#display").transition();
    $("#displayGrid").transition("hide");
  }

  removeDetail() {
    if (this.gridDetails.getSelectedValues().length > 0) {
      this.message(
        "delete",
        "Deletion Record",
        "Confirm to delete record(s)?",
        "yesno",
        { yes: "this.removeDetailEvent()", no: "" }
      );
    } else {
      if (this.gridDetails.listStore.store.length > 0) {
        this.message(
          "delete",
          "Deletion Record",
          "Please select at least one (1) option.",
          "okonly",
          { ok: "" }
        );
      }
    }
  }

  removeDetailEvent() {
    this.gridDetails.getSelectedValues().forEach((element) => {
      this.gridDetails.listStore.removeData(element);
    });
    this.gridDetails.loadData();
    this.gridDetails.clearSelectedValues();
    this.updateSeqNo();
  }

  cancelDetail() {
    this.gridDetails.clearSelectedValues();
    this.setValidatorHeader();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "";
    }
    $("#display").transition("hide");
    $("#displayGrid").transition();
  }

  saveDetail() {
    if (!this.onValidate(this.modelDetail)) {
      if (this.modelDetail.no == 0) {
        let data = new CustomerDetail();
        data.customerDisplayName = String(this.modelDetail.customerDisplayName)
          .toUpperCase()
          .trim();
        data.fullAddress = String(this.modelDetail.fullAddress)
          .toUpperCase()
          .trim();
        data.contactPerson = String(this.modelDetail.contactPerson)
          .toUpperCase()
          .trim();
        data.telno = String(this.modelDetail.telno);
        data.email = String(this.modelDetail.email).trim();
        data.fax = String(this.modelDetail.fax);
        if(this.modelDetail.isValidD){
          data.isValidDetail = 'Y';
        }else{
          data.isValidDetail = 'N';
        }
        this.gridDetails.listStore.addData(data);
        this.gridDetails.loadData();
        this.updateSeqNo();

        this.gridDetails.clearSelectedValues();
        this.modelDetail = new CustomerDetail();

        this.setValidatorHeader();
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
        $("#display").transition("hide");
        $("#displayGrid").transition();
      } else {
        let data = new CustomerDetail();
        data.no = this.modelDetail.no;
        data.seqNo = this.modelDetail.seqNo;
        data.customerDisplayName = String(this.modelDetail.customerDisplayName)
          .toUpperCase()
          .trim();
        data.fullAddress = String(this.modelDetail.fullAddress)
          .toUpperCase()
          .trim();
        data.contactPerson = String(this.modelDetail.contactPerson)
          .toUpperCase()
          .trim();
        data.telno = String(this.modelDetail.telno);
        data.email = String(this.modelDetail.email).trim();
        data.fax = String(this.modelDetail.fax);
        if(this.modelDetail.isValidD){
          data.isValidDetail = 'Y';
        }else{
          data.isValidDetail = 'N';
        }

        this.gridDetails.listStore.updateData(this.modelDetail.no - 1, data);
        this.gridDetails.loadData();
        this.gridDetails.clearSelectedValues();
        this.modelDetail = new CustomerDetail();

        this.setValidatorHeader();
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
        $("#display").transition("hide");
        $("#displayGrid").transition();
      }
    }
  }

  updateSeqNo() {
    let index: number = 0;
    this.gridDetails.listStore.store.forEach((element) => {
      element["seqNo"] = element["no"];
      this.gridDetails.listStore.updateData(index, element);
      index++;
    });
  }

  generateCustomerHistoryReport(event: any) {
    // console.log("customerIdHistory => " + this.customerIdHistory);
    if (this.customerIdHistory == "" || this.customerIdHistory == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      // if(this.gridHistory.listStore.store.length > 0){
      //window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/40/History-Customer-' + this.customerIdHistory +'/'+this.customerIdHistory);
      var path =
        "40/History-Customer-" +
        this.customerIdHistory +
        "/" +
        this.customerIdHistory;
      var filename = "History-Customer-" + this.customerIdHistory;
      this.downloadFileFromServer(path, filename, "xls", true);
      // }
    }
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  infoGridHistory(event: any) {
    // this.info=event;
  }

  none(event: any) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

  noneNum(event: any) {
    const regexPattern = /[0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  downloadFileFromServer(
    path: string,
    fileName: string,
    fileExt: string,
    loading: boolean
  ) {
    loading = true;
    var dwnldUrl = this.hitUrl + "?q=/Mreport/runReport/" + path;
    this._http
      .get(dwnldUrl, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          var docName = fileName + "." + fileExt;
          FileSaver.saveAs(res, docName);
          loading = false;
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          loading = false;
        }
      );
  }

  initTab1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.gridApi.setColumnDefs(this.columnFinanceDefs);
    // this.gridApi.sizeColumnsToFit();
  }

  initTab2() {
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.gridApi.setColumnDefs(this.columnGeneralDefs);
    // this.gridApi.sizeColumnsToFit();
  }

  initTab3() {
    this.tabStatus = "tab3";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "active";
    this.tabStatus4 = "";
    this.gridApi.setColumnDefs(this.columnPartyDefs);
    // this.gridApi.sizeColumnsToFit();
  }

  initTab4() {
    this.tabStatus = "tab4";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "active";
    this.gridApi.setColumnDefs(this.columnAuditLogDefs);
    // this.gridApi.sizeColumnsToFit();
  }
}

var hashValueGetter = function (params) {
  return params.node ? params.node.rowIndex + 1 : null;
};

class CountryIndicator implements ICellRenderer {
  private eGui!: HTMLElement;
  init(params: ICellRendererParams) {
      const element = document.createElement('span');
      const elementIcon = document.createElement('i');
      var cntryName= params.data.countryCode.trim().toLowerCase();
      
      elementIcon.className=cntryName+' flag';
      element.appendChild(elementIcon);
      element.appendChild(document.createTextNode(' '+params.value.trim()));
      this.eGui = element;
  }
  getGui() {
      return this.eGui;
  }
  refresh() { return false; }
}



function selectAllRenderer(params) {
  var cb = document.createElement('input');
  cb.setAttribute('type', 'checkbox');

  var eHeader = document.createElement('label');
  var eTitle = document.createTextNode(params.colDef.headerName);
  eHeader.appendChild(cb);
  eHeader.appendChild(eTitle);

  cb.addEventListener('change', function (e) {
      if ($(this)[0].checked) {
          params.api.selectAll();
      } else {
          params.api.deselectAll();
      } 
  });
  return eHeader; 
}