import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
} from "sibego-ui-library";
import { officeModel, specialCharUtil } from "../shared/index";
import { Router } from "@angular/router";
declare var $: any;

export class ReportParam {
  vesselId = "";
  vesselName = "";
  voyage = "";
  bound = "O";
  pol = "";
  pod = "";
  pot = "";
  so = "";
  op = "";
  reportType = "PDF";

  constructor() {}
}

@Component({
  selector: "app-report-operation-container-loading-list-coc-proforma-page",
  templateUrl:
    "./report-operation-container-loading-list-coc-proforma-page.component.html",
  styleUrls: [
    "./report-operation-container-loading-list-coc-proforma-page.component.css",
  ],
})
export class ReportOperationContainerLoadingListCocProformaPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbPOL") cbPol: ComboPluginComponent;
  @ViewChild("cbPOD") cbPod: ComboPluginComponent;
  @ViewChild("cbPOT") cbPot: ComboPluginComponent;

  @ViewChild("cllPdfContainer") cllPdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPOL;
  settingPOD;
  settingPOT;
  settingPDF;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = true;
  dButtonLock = true;

  sCharUtil = new specialCharUtil();
  model = new ReportParam();
  office = new officeModel();
  userID = "";
  hitUrl = this.configService.config.BASE_API.toString();

  validatorRules = {};

  constructor(
    private genericService: GenericService,
    private router: Router,
    private configService: ConfigService,
    private cook: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Proforma Container Loading List Report",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingPOL = {
      id: "cbPOL",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOT = {
      id: "cbPOT",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPDF = {
      id: "cllPdfContainer",
      url: "",
      hidePrint: false,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.office.officeCode = this.cook.getDefaultLocationCode(); //localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook
      .getCookie("defaultLocation")
      .split("|")[0];
    this.userID = this.cook.getName();

    this.checkedRadio("pdf", true);
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "print":
        if (
          this.model.vesselId != "" &&
          this.model.voyage != "" &&
          this.model.bound != ""
        ) {
          this.handleprint();
        } else {
          if (this.model.vesselId == "") {
            this.message(
              "information",
              "Information",
              "Please input vessel!",
              "okonly",
              { ok: "" }
            );
          } else if (this.model.voyage == "") {
            this.message(
              "information",
              "Information",
              "Please input voyage!",
              "okonly",
              { ok: "" }
            );
          } else if (this.model.bound == "") {
            this.message(
              "information",
              "Information",
              "Please input bound!",
              "okonly",
              { ok: "" }
            );
          }
        }
        break;
      case "cancel":
        // cancel
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyage/" +
        event.vesselId +
        "/" +
        "O" +
        "/{query}/" +
        this.cook.getDefaultLocationCode()
    );

    console.log(event);

    if (event.vesselId != "") {
      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;
    } else {
      this.model.vesselId = "";
      this.model.vesselName = "";
    }
  }

  changeEventVoyage(event) {
    if (event.voyage != undefined && event.voyage != "") {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = "";
    }
  }

  changeEventPOL(event) {
    //console.log(event);
    if (event.locationId != undefined && event.locationId != "") {
      this.model.pol = event.locationCode;
    } else {
      this.model.pol = "";
    }
  }

  changeEventPOD(event) {
    //console.log(event);
    if (event.locationId != undefined && event.locationId != "") {
      this.model.pod = event.locationCode;
    } else {
      this.model.pod = "";
    }
  }

  changeEventPOT(event) {
    //console.log(event);
    if (event.locationId != undefined && event.locationId != "") {
      this.model.pot = event.locationCode;
    } else {
      this.model.pot = "";
    }
  }

  eventRadio(event, val) {
    console.log(event);
    var checked = event.srcElement.checked;

    if (checked) {
      this.model.reportType = val;
    }
  }

  handleprint() {
    this.loading = true;
    console.log("yomaan");
    console.log(this.model);

    if (this.model.pol == "") {
      this.model.pol = "*";
    }

    if (this.model.pod == "") {
      this.model.pod = "*";
    }

    if (this.model.pot == "") {
      this.model.pot = "*";
    }

    if (this.model.so == "") {
      this.model.so = "*";
    }

    if (this.model.op == "") {
      this.model.op = "*";
    }

    var uri =
      this.hitUrl +
      "/CLLReportProforma/cllFinalLoadingReportProformaGen/" +
      this.office.officeCode +
      "/" +
      this.model.vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
      "/" +
      this.model.bound +
      "/" +
      this.model.pol +
      "/" +
      this.model.pod +
      "/" +
      this.model.pot +
      "/" +
      this.model.so +
      "/" +
      this.model.op +
      "/" +
      this.userID;

    this.genericService.GET(uri).subscribe(
      (resp) => {
        if (resp.ok) {
          var result: any = resp.json();
          var pol = "";
          var so = "";
          var op = "";
          var key = result["key"];
          if (result["total-data"] > 0) {
            if (result["pol"] != undefined) {
              pol = this.model.pol;
            }
            if (result["so"] != undefined) {
              so = result["so"];
            }
            if (result["op"] != undefined) {
              op = result["op"];
            }

            var hitUrl =
              this.configService.config.getPDFUrl + "/edi-util/getFile";
            var jasperURL =
              this.configService.config.jasperUrl +
              "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/FinalContainerLoadingListProformaReport.pdf?key=" +
              key +
              "&pol=" +
              pol +
              "&so=" +
              so +
              "&op=" +
              op;
            var fileName =
              "Final-Container-Loading-List-Proforma" +
              this.model.vesselName +
              ".pdf";

            var postData = {};
            postData["fileName"] = fileName;
            postData["url"] = jasperURL;

            if (this.model.reportType == "PDF") {
              this.genericService.POST(hitUrl, postData).subscribe((resp) => {
                if (resp.ok) {
                  var result = resp.json();

                  if (result["message"] == "success") {
                    this.cllPdfContainer.setPDFURL(
                      this.configService.config.pdfUrl + result["filePath"]
                    );
                    this.cllPdfContainer.showPDF();
                  }

                  this.loading = false;
                  if (this.model.so == "*") {
                    this.model.so = "";
                  }

                  if (this.model.op == "*") {
                    this.model.op = "";
                  }
                }
              });
            } else {
              jasperURL = jasperURL.replace(".pdf", ".xls");
              window.open(jasperURL);
              this.loading = false;
              if (this.model.so == "*") {
                this.model.so = "";
              }

              if (this.model.op == "*") {
                this.model.op = "";
              }
            }
          } else {
            this.loading = false;
            this.message(
              "information",
              "Information",
              "No Data Found.",
              "okonly",
              { ok: "" }
            );
            if (this.model.so == "*") {
              this.model.so = "";
            }

            if (this.model.op == "*") {
              this.model.op = "";
            }
          }
        }
      },
      (error) => {}
    );
  }

  handleCancel() {
    this.model = new ReportParam();
    this.cbVessel.setForceValue("");
    this.cbVoyage.setForceValue("");
    this.cbPol.setForceValue("");
    this.cbPod.setForceValue("");
    this.cbPot.setForceValue("");

    this.checkedRadio("pdf", true);
  }

  eventPDFPlugin(event) {
    console.log(event);
    if (event == "close-pdf") {
      //this.disableToolbarButtons = 'print';
    }
  }

  checkedRadio(val, checked: boolean) {
    $("#rType[value=" + val + "]").prop("checked", checked);
  }
}
