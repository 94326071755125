import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { finalize } from "rxjs/operators";
import { ConfigService, GenericService } from "sibego-ui-library";

declare var $: any;

@Component({
  selector: "app-transaction-ts-config",
  templateUrl: "./transaction-ts-config.component.html",
  styleUrls: ["./transaction-ts-config.component.css"],
})
export class TransactionTsConfigComponent implements OnInit {

  dialog: any;
  loading = false;
  modeToolbar = false;
  /* Parameter for information into Footer */
  info = "";
  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  limitValue = 0;

  settingToolbar;

  constructor(
    private router: Router,
    private genericService: GenericService,
    private configService: ConfigService,
    private datePipe: DatePipe
  ) {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Save", event: "save", icon: "save" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "T/S Config",
    };
  }

  ngOnInit() {
    this.getLimitValue();
  }

  toolbarEvent(event) {
    switch (event) {
      case "save":
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  getLimitValue(){
    this.loading = true;
    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() + "/TsConfig/getLimit"
    )
    .pipe(finalize(() => {
      this.loading = false;
    }))
    .subscribe(
      (resp) => {
       this.limitValue = resp.json()["tsMinConnectionLimit"];
      },
      (err) => {
        this.message("error", "Error", "An error has occurred", "okonly", {
          ok: "",
        });
      }
    );
  }

  handleSave() {
    let body = {
      timeLimit: this.limitValue,
      modifiedDate: this.datePipe.transform(new Date(), "yyyy-MM-dd hh:mm:ss"),
      modifiedBy: localStorage.getItem("logged").split("|")[0],
    };
    this.loading = true;
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/TsConfig/update",
        body
      )
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        (resp) => {
          this.message(
            "information",
            "Information",
            "Save successfully",
            "okonly",
            { ok: "" }
          );
        },
        (err) => {
          this.message("error", "Error", "An error has occurred", "okonly", {
            ok: "",
          });
        }
      );
  }

  handleCancel() {
    this.limitValue = 6;
  }

  validateNumberOnly(event: any) {
    const regexPattern = /[0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }
}
