<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin
      [settings]="dialog"
      (eventDialog)="eventMessage($event)"
    ></dialog-plugin>

    <form class="ui form">
      <button class="ui button" style="display: none"></button>
      <fieldset style="border: 1px solid lightgray">
        <div class="two fields" style="margin-bottom: 0px">
          <div
            class="required inline field"
            style="width: 100%"
            [class.error]="
              model['error-blNo'] != null && model['error-blNo'] != ''
            "
          >
            <label>B/L No.</label>
            <input
              type="text"
              name="BlNo"
              placeholder="B/L"
              [(ngModel)]="model['blNo']"
              (ngModelChange)="model['error-blNo'] = ''"
              (keypress)="formValidation.CharNumOnlyWOSpace($event)"
              [disabled]="lockQty == false"
              maxlength="20"
            />
          </div>
        </div>
      </fieldset>
      <br />
      <fieldset style="border: 1px solid lightgray">
        <legend style="font-weight: bold; color: dodgerblue">
          Generate B/L (Same Booking Number)
        </legend>
        <div class="two fields">
          <div class="field" style="width: 60%">
            <fieldset style="border: 1px solid lightgray">
              <legend style="font-weight: bold; color: dodgerblue; size: 60px">
                Please select Method: Number of additional B/L needed
              </legend>
              <div class="two fields">
                <div class="field">
                  <div
                    class="ui radio checkbox"
                    [class.disabled]="lock == true"
                  >
                    <input
                      name="splitType"
                      type="radio"
                      value="SUFFIX"
                      [checked]="model['generateType'] == 'SUFFIX'"
                      (change)="changeEventGenerateType($event)"
                    />
                    <label>Generate B/L with suffix (A-Z)</label>
                  </div>
                </div>
                <div class="one field">
                  <label style="float: left">Quantity &nbsp;</label>
                  <!-- <input
                    type="number"
                    name="qty"
                    min="0"
                    style="width: 60%"
                    placeholder="Quantity"
                    [(ngModel)]="model['qty']"
                    [disabled]="lockQty == true"
                    (change)="changeEventQty($event)"
                    maxlength="10"
                    (keypress)="formValidation.keypressEventRegexWith($event, model, 'principalCode', '[a-zA-Z0-9]')"
                      (paste)="formValidation.pasteEventRegexWith($event, model, 'principalCode', '[^a-zA-Z0-9]', 10)"
                  /> -->

                  <input
                    type="number"
                    name="qty"
                    min="0"
                    style="width: 60%"
                    placeholder="Quantity"
                    [(ngModel)]="model['qty']"
                    [disabled]="lockQty == true"
                    maxlength="10"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    (keypress)="formValidation.keypressEventRegexWith($event, model, 'qty', '[0-9]')"
                  />

                </div>
              </div>
              <div class="field">
                <div class="ui radio checkbox" [class.disabled]="lock == true">
                  <input
                    name="splitType"
                    type="radio"
                    value="NEW"
                    [checked]="model['generateType'] == 'NEW'"
                    (change)="changeEventGenerateType($event)"
                  />
                  <label>Generate B/L with New Number</label>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field" style="width: 40%">
            <fieldset style="border: 1px solid lightgray">
              <legend style="font-weight: bold; color: dodgerblue">
                Please select Method to use
              </legend>
              <div class="field">
                <div class="ui radio checkbox" [class.disabled]="lock == true">
                  <input
                    value="FREIGHT"
                    name="methodType"
                    type="radio"
                    (change)="changeEventMethodType($event)"
                    [checked]="model['methodType'] == 'FREIGHT'"
                  />
                  <label>Freight in Main B/L</label>
                </div>
              </div>
              <div style="height: 30px"></div>
              <div class="field">
                <div class="ui radio checkbox" [class.disabled]="lock == true">
                  <input
                    value="PRORATE"
                    name="methodType"
                    type="radio"
                    (change)="changeEventMethodType($event)"
                    [checked]="model['methodType'] == 'PRORATE'"
                  />
                  <label>Prorate per B/L Basis</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <fieldset style="border: 1px solid lightgray">
          <legend style="font-weight: bold; color: dodgerblue">
            Quantity in Main Booking Reference
          </legend>
          <div class="field" style="width: 100%; height: 40%">
            <grid-plugin
              id="gridBooking"
              #gridBooking
              [settings]="settingGridBooking"
              (gridEvent)="gridBookingEvent($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
        </fieldset>
        <br />
        <fieldset style="border: 1px solid lightgray">
          <legend style="color: dodgerblue; margin-right: 300px">
            After Split
          </legend>
          <div class="inline field">
            <div class="inline fields" align="right">
              <label style="color: dodgerblue" align="right"
                >Total B/Ls Generated (Including Main B/L)</label
              >
              <input
                readonly
                type="number"
                name="aftersplitQty"
                style="width: auto"
                placeholder="Quantity"
                [(ngModel)]="model['aftersplitQty']"
              />
            </div>
            <br />
            <div class="field">
              <grid-plugin
                id="gridMain"
                #gridMain
                [settings]="settingGridMain"
                (gridEvent)="gridMainEvent($event)"
                (infoGrid)="infoGrid($event)"
                style="height: 20px"
              ></grid-plugin>
            </div>
          </div>

          <div class="field" id="grid-plugin" style="display: none;">
            <grid-plugin
              id="gridSplit"
              #gridSplit
              [settings]="settingGridSplit"
              (gridEvent)="gridSplitEvent($event)"
              (infoGrid)="infoGrid($event)"
              (dblclick)="editColumn($event)"
              style="height: 20px"
            ></grid-plugin>
          </div>
          
          <div class = "field">
            <fieldset>
              <div class="field" style="overflow-y: scroll; height: 250px">
                <table
                  class="
                    ui
                    sortable
                    selectable
                    compact
                    celled
                    definition
                    striped
                    table
                  "
                  cellspacing="0"
                  cellpadding="0"
                  id="tblSplit"
                  #tblSplit
                >
                  <thead class="full-width" style="font-size: 12px">
                    <tr>
                      <th
                        *ngFor="let col of settingGridSplit2.columns"
                        width="{{ col.width }}%"
                      >
                        {{ col.header }}
                      </th>
                    </tr>
                  </thead>
                  
                  <tbody style="font-size: 12px">
                    <tr *ngFor="let data of splitStore" class="tr-bookingReferences-a">
                      <td *ngFor="let col of settingGridSplit2.columns" style="background: unset;padding:5px;"> 
                        <input
                          type="hidden"
                          id="seqId_{{ data.seqnoNew }}"
                          name="seqId_{{ data.seqnoNew }}"
                        />
                        <span *ngIf="col.editType == undefined">
                          <span *ngIf="this.model.generateType != 'NEW'">
                            <input type="text" name="blNo_{{ data.seqnoNew }}" id="blNo_{{ data.seqnoNew }}" value="{{ data.blNo }}" class="custom-input"
                            [(ngModel)]="data.blNo" readonly/>
                          </span>

                          <span *ngIf="this.model.generateType == 'NEW' && this.codeCN == false">
                            <input type="text" name="blNo_{{ data.seqnoNew }}" id="blNo_{{ data.seqnoNew }}" value="{{ data.blNo }}" class="custom-input"
                            [(ngModel)]="data.blNo" readonly/>
                          </span>

                          <span *ngIf="this.model.generateType == 'NEW' && this.codeCN == true" class="flex-bookingReferences-a">
                            <span class="span-bookingReferences-a">{{blNumberdefault}}</span>
                            <input type="text" name="blNo_{{ data.seqnoNew }}"  maxlength="6" id="blNo_{{ data.seqnoNew }}" value="{{ data.blNo }}" class="custom-input"
                            [(ngModel)]="data.blNo" [disabled]="data.isNew === ''"/>
                          </span>

                          <span *ngIf="data.blNo && !regexSuffixBlNumber.test(data.blNo) && this.model.generateType == 'NEW' && this.codeCN == true"
                            class="message-ErrorSuffixBookingNumber">Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits
                          </span>
                         
                        </span>
                        <span *ngIf="col.editType != undefined">
                          <input 
                            type="number"
                            min="0"
                            maxlength="5"
                            oninput="this.value = Math.abs(this.value); if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                            class="quantyclass"
                            onkeydown="return (event.keyCode!=13);"
                            name="{{col.field}}_{{ data.seqnoNew }}"
                            id="{{col.field}}_{{ data.seqnoNew }}"
                            value="{{ data[col.field] }}"
                            (blur)="qtyBlur(data.seqnoNew, col.field)"
                            
                          />
                        </span>
                      </td>
                    
                      <!--
                      (keyup)="qtyBlur(data.seqnoNew, col.field)"
                      (change)="qtyBlur(data.seqnoNew, col.field)"
                      -->
                    </tr>
                  </tbody>
                  
                </table>
              </div>
            </fieldset>
          </div>
        </fieldset>
      </fieldset>
    </form>
  </div>
</div>

<div
  id="splitDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onSplit()">Yes</button>
      <button class="negative ui button" (click)="closeSplitDialog()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="saveDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onSave()">Yes</button>
      <button class="negative ui button" (click)="closeSaveDialog()">No</button>
    </div>
  </form>
</div>

<div
  id="saveDialog2"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onSave2()">Yes</button>
      <button class="negative ui button" (click)="closeSaveDialog2()">
        No
      </button>
    </div>
  </form>
</div>

<div
  id="msgDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog()">Ok</button>
    </div>
  </form>
</div>

<div
  id="compareDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="0%" style="text-align: center">
            <i class="info circle big icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button class="positive ui button" (click)="closeCompareDialog()">
        Ok
      </button>
    </div>
  </form>
</div>

<div
  id="afterSaveDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeAfterSaveDialog()">
        Ok
      </button>
    </div>
  </form>
</div>

<div
  id="afterSaveDialog2"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeAfterSaveDialog2()">
        Ok
      </button>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
