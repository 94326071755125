<!--
<div class="ui aligned center aligned grid" style="margin-top:100px;">
  <div class="column" style="width:35%;">
    <login-v2-plugin *ngIf="this.base == 'http://localhost:9090' || this.base == 'http://glossys.samudera.com:9090' "></login-v2-plugin>
    <login-v3-plugin *ngIf="this.base == 'http://localhost:8090' || this.base == 'http://glossys.samudera.com:8090' "></login-v3-plugin>
  </div>
  <div class="column" style="width:35%;margin-top:23px;">
      <whats-news-plugin></whats-news-plugin>
  </div>
</div>
-->

<!--
<div class="ui middle aligned center aligned grid" style="margin-top:150px;">
  <div class="column">
      <login-v2-plugin *ngIf="this.base == 'http://localhost:9090' || this.base == 'http://glossys.samudera.com:9090' "></login-v2-plugin>
      <login-v3-plugin *ngIf="this.base == 'http://localhost:8090' || this.base == 'http://glossys.samudera.com:8090' "></login-v3-plugin>
      
  </div>
</div>
-->

<div class="ui bottom attached segment" style="background-color:transparent;border:none;height: 100%;">

  <img class="animfadebg" src="assets/images/bg-samudera.jpg" style="position: absolute;">

  <div class="contentexpedition">
    <div class="row" align="right" style="position: fixed; bottom:151px; right:0px;"><!-- row -->
      <!-- col -->
      <div style="right:20px; position: relative; margin-bottom:0px"><img src="assets/images/kapal.png" width="66%" alt=""></div><br>
      <!-- col end -->
    </div><!-- row end -->
  </div>

  <div id="m_header" class="ui left inverted ">
    <div class="column" style="width:10%;">
      <div class="ui icon button white" style="margin-top: 10px;float: right;" id="m_btn" (click)="menuSidebar()">
        <i class="icon list layout"></i>
      </div>
    </div>
  </div>

  <div id="mobile-menu2" class="ui left vertical inverted sidebar menu" style="width:300px;background: #2185d0;">

    <div style="height: 100%;width: 100%;padding-top: 15px;padding-bottom: 15px;padding-left: 20px;padding-right: 20px;">
      <h2 class="ui header" style="color: ghostwhite;">
        Welcome to Glossys
        <p></p>
        <p></p>
        <div class="sub header" style="color: ghostwhite;">Getting Started</div>

      </h2>

      <ul class="ui list" style="color: ghostwhite;font-size: 12px;padding-bottom: 7px;">
        <li style="padding-bottom: 7px;">
          <a class="item" routerLink="/login"> <i class="angle right icon" style="float: left;margin-left: -33px"></i> Glossys Quick Start Guide</a>
        </li>
        <li style="padding-bottom: 7px;">
          <a class="item" routerLink="/login"> <i class="angle right icon" style="float: left;margin-left: -33px"></i> Free Glossys Documentation</a>
        </li>
        <li style="padding-bottom: 7px;">
          <a class="item" routerLink="/login"> <i class="angle right icon" style="float: left;margin-left: -33px"></i> Contact Us</a>
        </li>
      </ul>

      <whats-news-plugin></whats-news-plugin>

    </div>



  </div>

  <div class="ui middle aligned center aligned grid" >
    <div class="column">
      <login-v3-plugin></login-v3-plugin>
    </div>
  </div>

  <!--
  <div class="ui segment" style="float: right;
    width: 100%;
    margin-top: -16px;
    left: 0;
    margin-right: -15px;
    padding-left: 19%;
    min-height:1000px;background: transparent;">
   
    <div class="ui middle aligned center aligned grid" style="margin-top:150px;">
        <div class="column">
            <login-v3-plugin></login-v3-plugin>   
          
        </div>
      </div>

  </div>
-->
</div>


