import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { ReportPrint, specialCharUtil } from "../shared/index";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as FileSaver from "file-saver";
declare var $: any;

export class ReportParam {
  vesselId = "";
  vesselCode = "";
  voyage = "";
  bound = "O";
  pol = "";
  opCode = "";
  tType = "tranship";
  constructor() {}
}

@Component({
  selector: "app-report-cll-dg-list-page",
  templateUrl: "./report-cll-dg-list-page.component.html",
  styleUrls: ["./report-cll-dg-list-page.component.css"],
})
export class ReportCllDGListPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  isError = false;
  loading = false;

  // * Html Encode
  sCharUtil = new specialCharUtil();

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  validatorRules = {
    vesselId: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Vessel.",
        },
      ],
    },
    voyage: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Voyage.",
        },
      ],
    },
    bound: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Bound.",
        },
      ],
    },
    pol: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Loading Port.",
        },
      ],
    },
  };

  param = new ReportParam();
  hitUrl: string = this.configService.config.BASE_API.toString();

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Report CLL DG List",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.param = new ReportParam();
    this.checkedRadio("tranship", true);
  }

  toolbarEvent(event) {
    // // console.log('event : ' + event);
    switch (event) {
      case "print":
        this.handlePrint();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.vesselId = event.vesselId;
      this.param.vesselCode = event.vesselCode;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.param.vesselId +
          "/" +
          this.param.bound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.param.vesselId = "";
      this.param.vesselCode = "";
      this.cbVoyage.setUrl("");
      this.cbVoyage.setValue("");
    }
  }

  changeEventVoyage(event) {
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.voyage = event.voyage;
    } else {
      this.param.voyage = "";
    }
  }

  changeEventLoadPort(event) {
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.pol = event.locationCode;
    } else {
      this.param.pol = "";
    }
  }

  handleCancel() {
    this.isError = false;
    this.param = new ReportParam();
    //this.cbVessel.setUrl('');
    this.cbVessel.setForceValue("");
    //this.cbVoyage.setUrl('');
    this.cbVoyage.setForceValue("");
    this.cbLoadPort.setForceValue("");
    this.checkedRadio("tranship", true);
  }

  handlePrint() {
    this.resetError();
    this.isError = this.onValidate(this.param);
    // console.log('this.iserror => ' + this.isError);
    if (!this.isError) {
      var splitUrl = this.configService.config.getPDFUrl.split(":");
      const urlHit =
        this.hitUrl +
        "?q=/CLLReport/cllDgReport/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.param.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.param.voyage).trim()) +
        "/" +
        this.param.bound +
        "/" +
        this.param.pol +
        "/" +
        this.cookieService.getName() +
        "/" +
        (this.param.opCode === "" ? "*" : this.param.opCode.toUpperCase())+
        "/" +
        this.param.tType;


      console.log("urlHit : " + urlHit);

      var fileName =
        "DGList-" + this.param.vesselCode + "-" + this.param.voyage + ".xls";

      this._http
        .get(urlHit, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Token: localStorage.getItem("token"),
          }),
          responseType: "blob",
        })
        .subscribe((res) => {
          FileSaver.saveAs(res, fileName);
        });

      //window.open(urlHit);
    }
  }

  resetError() {
    this.param["error-vesselId"] = "";
    this.param["error-voyage"] = "";
    this.param["error-bound"] = "";
    this.param["error-pol"] = "";
  }

  checkedRadio(val, checked: boolean) {
    $("#tType[value=" + val + "]").prop("checked", checked);
  }
}
