<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

     <!--<Error Message Block-->
      <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == ''">
          {{model['error-blOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == ''">
          {{model['error-blOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
          {{model['error-blOceanVesselBound']}}
        </li>
        <li [class.hidden]="model['error-blLoadPortCode'] == null || model['error-blLoadPortCode'] == ''">
          {{model['error-blLoadPortCode']}}
        </li>
        <li [class.hidden]="model['error-blPlaceOfDeliveryCode'] == null || model['error-blPlaceOfDeliveryCode'] == ''">
          {{model['error-blPlaceOfDeliveryCode']}}
        </li>
        <li [class.hidden]="model['error-blPlaceOfReceiptCode'] == null || model['error-blPlaceOfReceiptCode'] == ''">
          {{model['error-blPlaceOfReceiptCode']}}
        </li>
        <li [class.hidden]="model['error-blDischargePortCode'] == null || model['error-blDischargePortCode'] == ''">
          {{model['error-blDischargePortCode']}}
        </li>
      </ul>
    </div>
    <!-- End of Error Message Block-->

    <form class="ui form">
      <div class="two fields">
        <!-- KIRI -->
        <div class="field">
          <!-- field kiri baris 1 -->
          <div class="field">
            <div class="two fields">
              <div class="field">
                <div class="required one field"  [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel  [settings]="settingVessel" [disableCombo]="lockHeader==false"  (change)="changeEventVessel($event)" style="width:1400px;"></combo-plugin>
                </div>
                <div class="field">
                  <label>Receipt Type</label>
                  <combo-plugin #cbReceipt [settings]="settingReceipt" [disableCombo]="lockHeader==false" (change)="changeEventReceipt($event)" style="width:100%;" ></combo-plugin>
                </div>
                <div class="field">
                  <label>Delivery Type</label>
                  <combo-plugin #cbDelivery [settings]="settingDelivery" [disableCombo]="lockHeader==false" (change)="changeEventDelivery($event)" style="width:100%;" ></combo-plugin>
                </div>
              </div>

              <div class="field" style = "padding-top:10%">
                <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                  <div class="field">
                    <div class="three fields">
                      <div class="field">
                        <div class="ui test checkbox" >
                          <input type="checkbox" [checked]="isSOC == true " name="isSOC"  (change)="changeContainerSOC($event)" tabindex="0" class="hidden">
                            <label>SOC</label>
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui test checkbox" >
                          <input type="checkbox"  [checked]="isCOC == true "name="isCOC" (change)="changeContainerCOC($event)" tabindex="0" class="hidden">
                            <label>COC</label>
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui test checkbox" >
                          <input type="checkbox"  [checked]="isSLOT == true "  name="isSLOT"  (change)="changeContainerSLOT($event)" tabindex="0" class="hidden">
                            <label>Slot</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="three fields">
                      <div class="field">
                        <div class="ui test checkbox" >
                          <input type="checkbox"   [checked]="isSOCTS == true "  name="isSOCTS"  (change)="changeContainerSOCTS($event)" tabindex="0" class="hidden">
                            <label>SOCTS</label>
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui test checkbox" >
                          <input type="checkbox"  [checked]="isCOCTS == true "  name="isCOCTS"  (change)="changeContainerCOCTS($event)" tabindex="0" class="hidden">
                            <label>COCTS</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
        </div>


        <!-- field kiri baris 2 -->
        <div class="field">
          <fieldset style="border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Sort By</legend>
              <div class="field">
                <div class="three fields">
                  <div class="field">
                    <div class="ui radio checkbox" >
                      <input type="radio"  [checked]="isSort == 'LP'"  value ="LP" name="isSort" (change)="changeSort($event)"  tabindex="0" class="hidden">
                      <label>Load Port</label>
                    </div>
                  </div>
                  <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"   [checked]="isSort == 'DP'"  value="DP" name="isSort" (change)="changeSort($event)"  tabindex="0" class="hidden">
                     <label>Discharge Port</label>
                   </div>
                  </div>
                  <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"  [checked]="isSort == 'POR'"  value="POR" name="isSort" (change)="changeSort($event)" tabindex="0" class="hidden">
                     <label>Place of Receipt</label>
                   </div>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="three fields">
                  <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"  [checked]="isSort == 'POD'" value="POD"  name="isSort" (change)="changeSort($event)" tabindex="0" class="hidden">
                     <label>Place of Delivery</label>
                   </div>
                  </div>
                  <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"  [checked]="isSort == 'BL'"  value="BL" name="isSort" (change)="changeSort($event)" tabindex="0" class="hidden">
                     <label>B/L No</label>
                   </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- field kiri baris 3 -->
          <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria By</legend>
               <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox"  >
                     <input type="radio" [checked]="isSelection == 'Vessel'"  value = "Vessel" (change)="changeSelection($event)" name="isSelection" tabindex="0" class="hidden" [disabled] = "disableOpt">
                     <label>Vessel</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui tiny right floated buttons"  >
                     <button  class="ui button retrieve" [class.disabled]="lockButtonRetrieve==true" (click)="onRetrieve()" data-content="Retrieve" >Retrieve</button>
                  </div>
                </div>
               </div>
               <div class="field" style = "margin-top:-20px">
                 <div class="ui radio checkbox" >
                   <input type="radio"  [checked]="isSelection == 'BL' "  value = "BL" (change)="changeSelection($event)" name="isSelection" tabindex="0" class="hidden">
                   <label>B/L</label>
                 </div>
               </div>
               <div class="field" style = "margin-top:8px" >
                 <div class="ui radio checkbox" >
                  <input type="radio"  [checked]="isSelection == 'LP' " name="isSelection"  value = "LP" (change)="changeSelection($event)" tabindex="0" class="hidden" [disabled] = "disableOpt"> <label>Load Port</label>
                 </div>
                 <div  class="required one field"  [class.error]="model['error-blLoadPortCode'] != null && model['error-blLoadPortCode'] != ''">
                   <combo-plugin #cbLoadPort  [disableCombo]="lock==false"  [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                 </div>
                </div>
               <div class="field" style = "margin-top:8px" >
                  <div class="ui radio checkbox" >
                    <input type="radio" [checked]="isSelection == 'DP' " name="isSelection"  value = "DP" (change)="changeSelection($event)" tabindex="0" class="hidden" [disabled] = "disableOpt">
                    <label>Discharge Port</label>
                  </div >
                  <div  class="required one field" [class.error]="model['error-blDischargePortCode'] != null && model['error-blDischargePortCode'] != ''" >
                    <combo-plugin #cbDischargePort  [disableCombo]="lock==false"  [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                  </div>

               </div>
               <div class="field" style = "margin-top:8px" >
                 <div class="ui radio checkbox" >
                    <input type="radio"  [checked]="isSelection == 'POR' " name="isSelection"  value = "POR" (change)="changeSelection($event)" tabindex="0" class="hidden" [disabled] = "disableOpt">
                    <label>Place of Receipt</label>
                 </div>
                  <div  class="required one field" [class.error]="model['error-blPlaceOfReceiptCode'] != null && model['error-blPlaceOfReceiptCode'] != ''">
                    <combo-plugin #cbPOR  [disableCombo]="lock==false"   [settings]="settingPOR" (change)="changeEventPOR($event)" style="width:100%;"></combo-plugin>
                  </div>
                </div>
               <div class="field" style = "margin-top:8px" >
                 <div class="ui radio checkbox" >
                    <input type="radio"  [checked]="isSelection == 'POD' "  value = "POD" (change)="changeSelection($event)" name="isSelection" tabindex="0" class="hidden" [disabled] = "disableOpt">
                    <label>Place of Delivery</label>
                 </div>
                  <div  class="required one field" [class.error]="model['error-blPlaceOfDeliveryCode'] != null && model['error-blPlaceOfDeliveryCode'] != ''" >
                    <combo-plugin #cbPOD [disableCombo]="lock==false"  [settings]="settingPOD"  (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
                  </div>
                </div>
            </fieldset>
          </div>

          <!-- field kiri baris 4-->
           <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Print Place Of Payment Selection</legend>
              <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isPrint == 'Without'" value="Without" name="isPrint"  (change)="changePrint($event)" tabindex="0" class="hidden">
                     <label>Without Surcharge</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isPrint == 'With'" value="With"  name="isPrint" tabindex="0" (change)="changePrint($event)" class="hidden">
                     <label>With Surcharge</label>
                   </div>
                 </div>
              </div>
            </fieldset>
          </div>

          <!-- field kiri baris 5-->
          <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Exchange Rate</legend>
              <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isExch == 'WithoutExch'" value="WithoutExch" name="isExch"  (change)="changeExch($event)" tabindex="0" class="hidden">
                     <label>Without Exchange Rate</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isExch == 'WithExch'" value="WithExch"  name="isExch" tabindex="0" (change)="changeExch($event)" class="hidden">
                     <label>With Exchange Rate</label>
                   </div>
                 </div>
              </div>
            </fieldset>
          </div>

        </div>
        <!-- END KIRI -->

        <!-- KANAN -->
        <div class="field">
          <!-- field kanan baris 1 -->
          <div class="field" >
            <div class="two fields">
              <div class="required one field" style ="margin-left:-210px"  [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                <label>Voyage</label>
          <!-- field kanan baris 2 -->
                <combo-plugin #cbVoyage [settings]="settingVoyage" [disableCombo]="lockHeader==false" (change)="changeEventVoyage($event)"  style="width:100%;"></combo-plugin>
              </div>
              <div class="required one field" style ="margin-left:128px" [class.disabled]="lockHeader==false"  [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''">
                <label>Bound</label>
                <input type="text" name="bound"  style="text-transform:uppercase" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" (change)="changeBound($event)">
              </div>
            </div>
          </div>

          <!-- field kanan baris 2 -->
          <div class="field">
            <div class="four fields">
              <div class="field" style="width:60%;align-self:center;">
                <div class="ui test checkbox" >
                    <input type="checkbox" [checked]="isNonNego == true " (change)="changeNonNego($event)"  name="nonNego" tabindex="0" [disabled]="disableNN">
                    <label>Non-Negotiable Copy</label>
                </div>
              </div>
              <div class="field" style="width:25%;margin-left:-20px">
                <input type="text" (keypress)="TempOnly($event)" name="printCopy" [(ngModel)]="printCopy" (change)="noNegativeWithoutDecimal($event);">
              </div>
              <div class="field" style="width:50%;align-self:center;">
                <div class="ui test checkbox" [class.disabled]="lock==false" >
                    <input type="checkbox" [checked]="isSilent == true "  name="silentMode" tabindex="0" (change)="checkBoxEvent($event,'silent-mode');" [disabled]="isIna == true">
                    <label>Silent Mode</label>
                </div>
              </div>
              <div class="field" style="width:50%;align-self:center;">
                <div class="ui test checkbox" >
                    <input type="checkbox" [checked]="isSortPrint == true " name="sortPrint"  (change)="changeSortPrint($event)" tabindex="0">
                    <label>Sort Print</label>
                </div>
              </div>
            </div>
          </div>

          <!-- tombol add and remove -->
          <div class="field" [style.display]="addRemoveButton">
            <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
            <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
          </div>
          <!-- end tombol -->

          <!-- field kanan baris 3 -->
          <div class="field">
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>

          <!-- field kanan baris 4 -->
           <br>
          <div class="field" style="margin-top:2px">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Weight / Measurement Unit</legend>
              <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isWeight == 'KG'" value = "KG" name="isWeight" (change)="changeWeight($event)" tabindex="0" class="hidden">
                     <label>KGS/CBM</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isWeight == 'MT'" value = "MT" name="isWeight" (change)="changeWeight($event)" tabindex="0" class="hidden">
                     <label>MT/M3</label>
                   </div>
                 </div>
              </div>
            </fieldset>
          </div>

           <!-- field kanan baris 5 -->
            <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Package / Unit Selection</legend>
              <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isPackage == 'Cargo' " value="Cargo" name="isPackage"(change)="changePackage($event)" tabindex="0" class="hidden">
                     <label>Cargo Package</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" [checked]="isPackage == 'Cont' " value="Cont" name="isPackage" (change)="changePackage($event)" tabindex="0" class="hidden">
                     <label>Container Unit</label>
                   </div>
                 </div>
              </div>
            </fieldset>
          </div>

        </div>
        <!-- END KANAN -->


      </div>
    </form>
  </div>  <!-- end div container -->
</form>
<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>

<div class="ui fullscreen modal" id="pdfContainerNewBlOriginal">  
  <i class="close icon"></i>

  <div id="pdfContainerNewBlOriginal-tab-header" class="ui top attached tabular menu">
  </div>

</div>

<div id="pdfContainerNewBlOriginal2" style="display:none;">    
  <div class="ui form">
    <div class="field" id="pdfContainerNewBlOriginal2-label">
        <label>&nbsp;please click on the B/L No. to view.</label>
    </div>
    <div class="two fields">
      <div id='pdfContainerNewBlOriginal2-grid-container' class="field" style="width:30%;">
          <grid-plugin #gridPDFPrev [settings]="settingGridPDFPrev" (gridEvent)="gridEventPDFPrev($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
      <div id='pdfContainerNewBlOriginal2-viewer-container' class="field" style="width:100%;height:570px;">
      </div>
    </div>
  </div>

</div>

<footer-plugin [info]="info"></footer-plugin>
