<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
  <div class="ui medium text loader">Loading</div>
</div>
<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <!-- div class="ui progress">
          <div class="bar">
            <div class="progress"></div>
          </div>
          <div class="label">Progress</div>
        </div-->
      </div>
      <div class="field">
        <div class="field" style="width:400px">
          <label>Vessel </label>
          <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;">
          </combo-plugin>
        </div>
        <div class="field" style="width:400px">
          <label>Voyage </label>
          <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;">
          </combo-plugin>
        </div>
      </div>
      <div class="field" style="width:400px">
        <fieldset style="width: 100%;border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>

          <div class="two fields">
            <div class="field">
              <div class="ui radio checkbox">
                <input #rSoc type="radio" name="cO" checked="checked" (change)="radioEvent(rSoc.checked, 'SOC');">
                <label>SOC</label>
              </div>
            </div><br>
            <div class="field">
              <div class="ui radio checkbox">
                <input #rCoc type="radio" name="cO" (change)="radioEvent(rCoc.checked, 'COC');">
                <label>COC</label>
              </div>
            </div><br>
          </div>
          <div class="two fields">
            <div class="field">
              <div class="ui radio checkbox">
                <input #rSlot type="radio" name="cO" (change)="radioEvent(rSlot.checked, 'SLOT');">
                <label>SLOT</label>
              </div>
            </div><br>
            <div class="field">
              <div class="ui radio checkbox">
                <input #rAll type="radio" name="cO" (change)="radioEvent(rAll.checked, 'ALL');">
                <label>ALL</label>
              </div>
            </div><br>
          </div>
        </fieldset>
      </div><br>
    </form>

  </div>

  <!-- end div container -->

</form>

<pdf-plugin #pdfBLSameContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);">
</pdf-plugin>

<new-dialog #dialogBLSameContainer style="margin-left:3px;" [settings]="settingDialog"
  (eventDialogs)="eventdialog($event)"></new-dialog>

<footer-plugin [info]="info"></footer-plugin>