

export class AccessLevel {
    no : number = 0;
    accessLevelId   : string = '';
    accessLevelName : string = '';
    accessLevelCode : string = '';
    isValid         : boolean = true;
    remarks       : string = '';
    dateCreated  : string ='';
    userCreated  : string ='';
    dateModified : string ='';
    userModified : string ='';
    dateInvalid  : string ='';
    userInvalid  : string ='';


    constructor() {

    }
}
