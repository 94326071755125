export class Customer  {
    no : number = 0;
    customerId  : string = '';
    customerCode : string = '';
    customerName  : string = '';
    countryCode:string = '';
    countryName:string = '';
    companyRegNo: string = '';
    remarks: string = '';
    isValid        : boolean = false;
    isShipper        : boolean = false;
    isAgency        : boolean = false;
    isConsignee        : boolean = false;
    isBookingParty        : boolean = false;
    isNotifyParty        : boolean = false;
    isHaulier        : boolean = false;
    isPayer        : boolean = false;
    isNvocc : boolean = false;
    isSupplier: boolean = false;
    isFreightParty: boolean = false;
    isCoc        : boolean = false;
    isSoc        : boolean = false;
    isSlot        : boolean = false;
    cocOperatorCode : string = '';
    socOperatorCode : string = '';
    slotOperatorCode : string = '';
    cocOperatorCodeNew : string = '';
    socOperatorCodeNew : string = '';
    slotOperatorCodeNew : string = '';
    cocAccountCode : string = '';
    socAccountCode : string = '';
    slotAccountCode : string = '';
    cocCreditDays : number = 0;
    socCreditDays : number = 0;
    slotCreditDays : number = 0;
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid : string = '';
    cocCreditLimit : number = 0;
    socCreditLimit : number = 0;
    slotCreditLimit : number = 0;
    cocTolerance : number = 0;
    socTolerance : number = 0;
    slotTolerance : number = 0;


    masterCustomerDetails = [];

    constructor() {

    }
}

export class CustomerDetail {
    no:number = 0;
    seqNo : number = 0;
    customerDisplayName: string = '';
    contactPerson: string = '';
    fullAddress: string = '';
    telno:string = '';
    email:string = '';
    fax:string = '';
    isValidD : boolean = false;
    isValidDetail : string = '';
}
