import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { LocalExchangeRate, GridPluginComponent2 } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { merge } from "rxjs";
import FormValidation from "../../utils/formValidation";
import * as moment from "moment";
//import { FileSaver } from '../../assets/js/FileSaver.min.js'

declare var $: any;

@Component({
  selector: "app-local-master-exchange-rate-page",
  templateUrl: "./local-master-exchange-rate-page.component.html",
  styleUrls: ["./local-master-exchange-rate-page.component.css"],
})
export class LocalMasterExchangeRatePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent2;
  @ViewChild("cbExchangeRateDestinationCurrency")
  cbExchangeRateDestinationCurrency: ComboPluginComponent;
  @ViewChild("cbExchangeRateHistory")
  cbExchangeRateHistory: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingExchangeRateHistory;
  settingExchangeRateDate;
  settingExchangeRateDestinationCurrency;

  /* Misc Parameters */
  valExchangeRateDate: string = "";
  valExchangeRateName: string = "";
  valExchangeRateId: string = "";
  valHistoryStartDate: string = "*";
  valHistoryEndDate: string = "*";

  oldValid = true;
  oldCode = "-";

  typeExchangeRateDate = "calendar";

  /* Parameter model */
  model = new LocalExchangeRate();
  exchangeRateVal: string;
  //selectList = [];

  loading = false;

  lock = false;
  isError: boolean = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    exchangeRateDate: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Exchange Rate Date.",
        },
      ],
    },
    destinationCurrency: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Destination Currency.",
        },
      ],
    },
    exchangeRate: {
      rules: [
        {
          type: "positiveInteger",
          prompt: "No negative value!",
        },
      ],
    },
  };

  myOptions = {
    allowDecimalPadding: false,
    decimalPlaces: 4,
    maximumValue: "10000000",
    digitGroupSeparator: "",
    leadingZero: "keep",
  };
  isInvalidExchangeRate: boolean;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      toolbarType: "dateIntervalV2",
      searchBy: [
        { display: "Exchange Rate Date", value: "exchangeRateDate" },
        { display: "Local Currency", value: "homeCurrency" },
        { display: "Destination Currency", value: "destinationCurrency" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
      emitWithErrorStatus: true
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterExchangeRates/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Exchange Rate Date", field: "exchangeRateDate", width: 200 },
        { header: "Local Currency", field: "homeCurrency", width: 50 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 50,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 4,
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "ExchangeRateDate",
      sortingDirection: "DESC",
    };

    this.settingGridHistory = {
      id: "gridHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterExchangeRates/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "ExchangeRate Id", field: "exchangeRateId", width: 200 },
        { header: "ExchangeRate Date", field: "exchangeRateDate", width: 200 },
        { header: "Local Currency", field: "homeCurrency", width: 50 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 50,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 5,
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true,
    };

    this.settingExchangeRateHistory = {
      id: "cbExchangeRateDateHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterExchangeRates/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      code: "exchangeRateDate",
      title: "exchangeRateDate",
      description: "",
      isMark: true,
      columns: [
        { header: "ExchangeRate Id", field: "exchangeRateId", width: 150 },
        { header: "ExchangeRate Date", field: "exchangeRateDate", width: 150 },
        { header: "Local Currency", field: "homeCurrency", width: 150 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 150,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 5,
          width: 100,
        },
        //{header: 'Remarks', field: 'remarks', width: 50},
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingExchangeRateDestinationCurrency = {
      id: "cbExchangeRateDestinationCurrency",
      type: "search enter", // search | select | select input
      //url         : 'assets/json/currencies.json',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findGeneralEnum/{query}",
      // urlType     : 'dynamic',
      maxChars: 3,
      template: "grid",
      placeholder: "Select Currency",
      code: "currencyCode",
      title: "currencyCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Currency Name", field: "currencyName", width: 200 },
        { header: "Currency Code", field: "currencyCode", width: 150 },
      ],
    };

    this.settingExchangeRateDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    $(".test.checkbox").checkbox();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.model.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    //this.setGridEvent = 'search:*/*/* + refresh';
    this.grid.search =
      this.cookieService.getDefaultLocationCode().toString().trim() + "/*/*/*";
    this.grid.onFirst();
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: "yyyy-mm-dd",
      zIndex: 2048,
    });
    console.log(this.model);

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  handleDateChange() {
    console.log("date changed");
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      break;
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";
              $("#exchangeRateDate").find("#datePicker").prop("disabled", true);

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();

        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.exchangeRateId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        console.log(str);
        
        if (str === 'search-error-date') {
          this.message(
            "warning",
            "Date error",
            "'Period From' must not be later than 'Period To'",
            "okonly",
            { ok: "this.loading = false;" }
          );
          return;
        }
        if (str.indexOf("search") > -1) {
          this.grid.search =
            this.cookieService.getDefaultLocationCode().toString().trim() +
            "/" +
            str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new LocalExchangeRate();
    this.valExchangeRateDate = "";
    this.valExchangeRateName = "";
    this.exchangeRateVal = "0";
    $("#exchangeRateDate").find("#datePicker").prop("disabled", false);
  }

  handleUpdate() {
    // handle update event
    //gos-299
    this.model["error-invalidDelete"] = "";
    this.isError = false;

    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";
      $("#exchangeRateDate").find("#datePicker").prop("disabled", false);
    }
  }

  handleDelete() {
    // handle delete event
    this.model["error-invalidDelete"] = "";

    if (this.model.isValid) {
      this.model["error-invalidDelete"] = "Please invalid record prior deletion";
      this.isError = true;
    }else{

      this.loading = true;
      let deleteList = [];
      this.grid.getSelectedValues().forEach((element) => {
        var deleteObj = {"exchangeRateId":""};
        deleteObj.exchangeRateId = element.exchangeRateId;
        deleteList.push(deleteObj);
      });
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/checkingListExchangeRateUsed",
            deleteList
        )
        .subscribe(
          (resp) => {
            if (resp.status == 200) {

              let dataRes = resp.json();
              console.log(dataRes['totalUsed']);
              if (dataRes['totalUsed'] == 0) {
                this.loading = false;
                this.message(
                  "delete",
                  "Deletion Record",
                  "Confirm to delete record(s)?",
                  "yesno",
                  { yes: "this.deleteEvent()", no: "this.loading = false;" }
                );
              } else {
                this.message(
                  "information",
                  "Information",
                  "Record being utilized, cannot be deleted. ",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
              }

            }
          },
          (error) => {
            this.loading = false;
            this.info = error.message;
          }
        );
    }
    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    var formatName = "report-Exchange-Rates-xport-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/60/Exchange-Rates-xport-Reports/" + this.cookieService.getDefaultLocationCode().toString().trim();
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");

    // window.open(
    //   //this.configService.config.BASE_API.toString().replace("9090", "10000") +
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000")+
    //     "/Mreport/runReport/60/Exchange-Rates-xport-Reports/" +
    //     this.cookieService.getDefaultLocationCode().toString().trim()
    // );
    // handle print eventeports/*');
    // handle print event
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    this.model.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();
    //set value
    this.model["error-exchangeRateDate"] = "";
    this.model.exchangeRateDate = $("#exchangeRateDate").val();

    if (!moment(this.model.exchangeRateDate, "YYYY-MM-DD", true).isValid()) {
      this.model["error-exchangeRateDate"] = "Please input valid date";
    }

    this.model.exchangeRate = Number.parseFloat(parseFloat(this.exchangeRateVal).toFixed(4));
    if (this.model.exchangeRate <= 0 || isNaN(this.model.exchangeRate)) {
      this.model["error-exchangeRate"] = "Please input Exchange Rate";
      this.isInvalidExchangeRate = true;
    } else {
      this.isInvalidExchangeRate = false;
      this.model["error-exchangeRate"] = "";
    }

    let isPassedValidation: boolean = this.onValidate(this.model);

    if (!isPassedValidation && !this.isInvalidExchangeRate && !this.model["error-exchangeRateDate"]) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */

      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/LocalMasterExchangeRates/getDefaultCurrency/" +
            this.model.officeCode
        )
        .subscribe((resp) => {
          if (resp.ok) {
            console.log(resp.json()["content"][0]);

            if (this.model.exchangeRate < 0) {
              this.message(
                "warning",
                "Input error",
                "No negative value!",
                "okonly",
                { ok: "this.loading = false;" }
              );
            } else if (isNaN(this.model.exchangeRate)) {
              this.model["error-exchangeRate"] = "Please input Exchange Rate";
            } else {
              this.model.homeCurrency = resp.json()["content"][0];
              // console.log("PSI Date => " + this.genericUtil.getPSIDate());
              if (this.model.exchangeRateId == "")
                this.model.dateCreated = this.genericUtil.getPSIDate();
              else this.model.dateModified = this.genericUtil.getPSIDate();

              this.message(
                "save",
                "Saving data",
                "Do you want to save the record? ",
                "yesno",
                { yes: "this.savePreEvent()", no: "this.loading = false;" }
              );
            }
          }

          //if()
          this.oldCode =
            this.model.exchangeRateDate +
            this.model.homeCurrency +
            this.model.destinationCurrency;
          if (this.model.exchangeRateId != "") {
            if (this.oldValid && !this.model.isValid) {
              this.model.dateInvalid = this.genericUtil.getPSIDate();
              this.model.userInvalid = this.cookieService.getName();
            } /*else if(!this.oldValid && this.model.isValid){

            this.model.dateInvalid = '';
            this.model.userInvalid= '';

          }*/
          } else {
            if (!this.model.isValid) {
              this.model.dateInvalid = this.genericUtil.getPSIDate();
              this.model.userInvalid = this.cookieService.getName();
            }
          }
        });
    } else {
      this.loading = false;
    }

  }

  handleCancel() {
    // handle cancel event
    //gos-299
    this.model["error-invalidDelete"] = "";
    this.isError = false;
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    if (this.valExchangeRateId != "") {
      this.valExchangeRateDate = "";
      this.valExchangeRateId = "";
      this.cbExchangeRateHistory.setValue("");
      this.cbExchangeRateHistory.close();
      this.gridHistory.onHistory("NaN");
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new LocalExchangeRate();

    if (data.isValid == "Y") {
      this.model.isValid = true;
      this.oldValid = true;
    } else {
      this.model.isValid = false;
      this.oldValid = false;
    }
    // console.log(String(data.exchangeRateId).trim().toUpperCase());
    this.model.exchangeRateId = String(data.exchangeRateId)
      .trim()
      .toUpperCase();
    this.model.exchangeRateDate = String(data.exchangeRateDate)
      .trim()
      .toUpperCase();
    this.model.homeCurrency = String("SGD").trim().toUpperCase();
    this.model.destinationCurrency = String(data.destinationCurrency)
      .trim()
      .toUpperCase();
    //this.model.exchangeRate = Number.parseFloat(data.exchangeRate);
    this.exchangeRateVal = String(parseFloat(data.exchangeRate).toFixed(4));
    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.oldCode =
      this.model.exchangeRateDate +
      this.model.homeCurrency +
      this.model.destinationCurrency;
    //this.oldCode = this.model.exchangeRateId;

    this.cbExchangeRateDestinationCurrency.setValue(
      String(data.destinationCurrency).trim().toUpperCase()
    );
    $("#exchangeRateDate").find("#datePicker").val(this.model.exchangeRateDate);

    console.log(this.model);
    console.log(this.oldValid);
    console.log(this.oldCode);
  }

  refreshed() {
    // console.log("masuk refresh");
    //this.dtgrid.loadData();
  }

  valueCbExchangeRateDestinationCurrency(event) {
    this.model["error-destinationCurrency"] = null;
    this.model.destinationCurrency = event["currencyCode"];
    // console.log(event.toString);
  }
  valueExchangeRateHistory(event) {
    this.valExchangeRateDate = event["exchangeRateDate"];
    this.valExchangeRateId = event["exchangeRateId"];
    // console.log("curr code= " + event["ExchangeRateCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valExchangeRateId);
  }

  generateExchangeRateHistoryReport(event) {
    //this.valHistoryStartDate= $('#logHistoryExchangeRateStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryExchangeRateEndDate').find('#datePicker').val();
    if (this.valExchangeRateId == "" || this.valExchangeRateId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-History-Exchange-Rate-"+this.valExchangeRateId+"-"+this.valExchangeRateDate+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/61/History-Exchange-Rate-"
      +
      this.valExchangeRateId +
      "-" +
      this.valExchangeRateDate +
      "/" +
      this.valExchangeRateId +
      "@" +
      this.valHistoryStartDate +
      "@" +
      this.valHistoryEndDate
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // window.open(
    //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
    //     "/Mreport/runReport/61/History-Exchange-Rate-" +
    //     this.valExchangeRateId +
    //     "-" +
    //     this.valExchangeRateDate +
    //     "/" +
    //     this.valExchangeRateId +
    //     "@" +
    //     this.valHistoryStartDate +
    //     "@" +
    //     this.valHistoryEndDate
    // );
  }

  retrieveHistory(event) {
    this.gridHistory.loadData();
  }

  savePreEvent() {
    var valValid = "Y";

    if (this.model.isValid == false) {
      valValid = "N";
      if (this.model.isValid == false) {
        console.log("SAVE PRE EVENT NIH");
        valValid = "N";
        //gos299
        this.loading = true;
        let rateId = {exchangeRateId:this.model.exchangeRateId};

        let invalidList = [];
        invalidList.push(rateId);
        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/checkingListExchangeRateUsed",
              invalidList
          )
          .subscribe(
            (resp) => {
              if (resp.status == 200) {

                let dataRes = resp.json();
                console.log(dataRes['totalUsed']);
                if (dataRes['totalUsed'] == 0) {
                  console.log("gos2991")
                  this.loading = false;
                  this.preSaveSub(valValid);

                } else {
                  console.log("gos2992")
                  this.message(
                    "information",
                    "Information",
                    "Record being utilized, unable to invalid. ",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );
                }

              }
            },
            (error) => {
              this.loading = false;
              this.info = error.message;
            }
          );
        //end 299
      }
    }else{
      this.preSaveSub(valValid);

    }

  }

  preSaveSub(valValid){

    var mergeCode =
    this.model.exchangeRateDate +
    this.model.homeCurrency +
    this.model.destinationCurrency;

  this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterExchangeRates/getValidExchangeRates/" +
        this.model.officeCode +
        "/" +
        this.oldCode.toUpperCase()
    )
    .subscribe((kesp) => {
      console.log("hohoh" + kesp.json()["content"][0].cnt);
      console.log(
        "old valid" + this.oldValid + " valid new" + this.model.isValid
      );
      console.log(
        "old vessel" +
          this.oldCode +
          " new vessel" +
          this.model.exchangeRateId
      );
      if (kesp.ok) {
        var lenResp = Object.keys(kesp.json()["content"]).length;
        /*
      console.log('-----');
      console.log(parseInt(kesp.json()['content'][0].cnt));
      console.log(valValid);
      console.log(mergeCode);
      console.log(this.oldCode);
      console.log('--endconsole--');
      */
        if (
          parseInt(kesp.json()["content"][0].cnt) == 0 ||
          (parseInt(kesp.json()["content"][0].cnt) > 0 && valValid == "N") ||
          (parseInt(kesp.json()["content"][0].cnt) > 0 &&
            this.oldCode == mergeCode &&
            this.oldValid == this.model.isValid &&
            this.model.exchangeRateId != "")
        ) {
          this.saveEvent();
        } else {
          this.message(
            "information",
            "Information",
            "Identical exchange Rate Date, Home Currency, and Destination Currency detected. Please select other combination.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }
      }
    });
  }

  saveEvent() {
    // console.log('saveEvent');

    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterExchangeRates/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new LocalExchangeRate();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.exchangeRateId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"exchangeRateId":[]}
    paramsDelete.exchangeRateId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/LocalMasterExchangeRates/delete",
        // deleteList
        "/LocalMasterExchangeRates/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new LocalExchangeRate();
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  fourDigitOnly(event: any) {
    console.log(event.key);
    console.log(this.model.exchangeRate);
    console.log(event.key);

    console.log(
      "to string -> " + (this.model.exchangeRate + event.key).toString()
    );
    if ((this.model.exchangeRate + event.key).toString().includes(".")) {
      if (
        (this.model.exchangeRate + event.key).toString().split(".")[1].length >
        4
      ) {
        console.log("have dot, greater than 4");
        event.preventDefault();
      } else {
        console.log("have dot, less than 4");
      }
    } else {
      console.log("dun have dot");
    }
    /*
    var regexPattern = new RegExp("^\d(\.\d{0,4})?$");
    console.log('input -> ' +  event.key);

    if (regexPattern.test(event.key) == false) {
      console.log('match regex');
      //event.preventDefault();
    }else{
      console.log('unmatch regex');
    }
    */
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
