export class MasterVesselScheduleDetail  {
    vesselScheduleId : string = '';
    seqNo : number;
    portOfCallName : string = '';
    portOfCall : string = '';
    terminalName : string = '';
    terminalCode : string = '';
    callReason : string = '';
    psaTerminal : string = '';
    peta : string = '';
    petaDays : number = 0;
    petaHour : number = 0;
    petd : string = '';
    petdDays : number = 0;
    petdHour : number = 0;
    eta : string = '';
    etaDays : number = 0;
    etaHour : number = 0;
    etd : string = '';
    etdDays : number = 0;
    etdHour : number = 0;    
    ata : string = '';
    aetaDays : number = 0;
    aetaHour : number = 0;    
    atd : string = '';
    aetdDays : number = 0;
    aetdHour : number = 0;

    efmh : number = 0;
    eath : number = 0;
    pfmh : number = 0;
    path : number = 0;

    term1 : string = '';
    term2 : string = '';
    term3 : string = '';
    term4 : string = '';
    term5 : string = '';
    
    callIndicator : string = '';
    inIndicator : string = '';
    outIndicator : string = '';

    voyage : string = ''
    vesselId : string = '';
    vesselCode : string = '';
    vesselName : string = '';
    hasTerminal : string = 'N';
    portCode : string = '';

    constructor() { 
      
    }
}