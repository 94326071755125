<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">Booking - Container Upload</label>
        <br /><br />
        <div class="fields">
          <div class="eight wide field">
            <label style="font-size:12px">FILE to be Processed</label>
            <input type="file" id="myfile" style="display:none">
            <div class="inline field">
              <!-- button class="ui primary mini button" id="iduploadFile" (click)="uploadProcess();">Upload</button>
              <label style="font-size:12px">{{model.fileName}}</label -->
              <upload-plugin #bookingContainerFileUpload [settings]="settingUploadContainerFile" (eventUpload)="uploadEventBookingContainerFile($event)"></upload-plugin>
            </div>
          </div>
        </div>
        <div class="fields">
          <div class="eight wide field">
            <label style="font-size:12px">Instruction</label>
            <textarea name="instruction" style="font-size:12px">{{model.messageValue}}
            </textarea>
          </div>
        </div>


      </div>
    </form>
  </div>  <!-- end div container -->
</form>

<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>

<footer-plugin [info]="info"></footer-plugin>
