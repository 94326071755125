<form class="ui raised segment">

  <div id='cnTallyList'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <form class="ui form">
     
      <!-- Menu - tab-->
      <div class="ui top attached tabular menu" style="background-color:beige;">
        <a class="item" [class.active]="tabStatus == 'tab1'" data-tab="cnTallyListEdiProcess" (click)="initTab1()">Process Tally List EDI &nbsp; 
          <span [style.display]="tabStatus=='tab1'?'block':'none'" style="color: red;text-transform: uppercase">{{appStatusLabel}}</span>
        </a>
      </div> 
      <!--End Menu - tab-->

      <!-- Process China Tally List EDI -->
      <div class="ui bottom attached tab segment"
        [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
        id="chinaTallyListEDIProcessTab"
        data-tab="chinaTallyListEDIProcess">
        
      <!--Menu Button - Process Sha Booking EDI -->
       <toolbar-plugin 
       [modeToolbar]="modeToolbar" 
        [settings]="settingToolbar" 
        [disableButtons]="disableToolbarButtons" 
        [invisibleButtons]="invisibleToolbarButtons"
        (eventEmitterClick)="toolbarEvent($event)" >
       </toolbar-plugin>
       <br/>
        <div [style.display]="modeForm ? 'block' : 'none'">
          <a class="ui red ribbon label">Stage 2 : Process Tally List EDI</a>
          <!--GridView - Process Sha Booking EDI -->
          <grid-plugin
            #gridChinaTallyListEDIInfo
            [settings]="settingGridChinaTallyListEDIProcessInfo"
            (gridEvent)="gridEventChinaTallyListEDIProcessInfo($event)"
            (infoGrid)="infoGridChinaTallyListEDIProcessInfo($event)">
          </grid-plugin>
        </div>
        <br/>
        <!--Bottom Button - Process Sha Booking EDI -->
        <div class="field" style="text-align: right">
          <button class="tiny ui negative button"
          (click)="onChinaTallyListEDIInfo_Discard($event)"
          [class.disabled]="lockBtnChinaTallyListEDIDiscard == true">
          Discard
          </button>
        </div>
        
      </div> 
      <!-- Process China Tally List EDI -->
      <br/>
    </form>
  </div>  
</form>

<div id="UnconfirmedList" class="dialogUnconfirmedList">
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-yes" (click)="confirmUnconfirmedList()" [disabled]="isConfirm">Proceed</button>
    <button class="ui button btn-no" (click)="closeUnconfirmedList()">Cancel</button>
  </div>
  <br/>
  <table>
    <tr>
      <th>Vessel Name</th>
      <th>Voyage No.</th>
      <th>BL No.</th>
      <th>Container No.</th>
      <th>Status</th>
      <th>Allow system to proceed next check action?</th>
    </tr>
    <tr *ngFor="let item of unconfirmedList">
      <td>{{item.vesselName}}</td>
      <td>{{item.voyageNo}}</td>
      <td>{{item.blNo}}</td>
      <td>{{item.containerNo}}</td>
      <td>{{item.status}}</td>
      <td>
        <div class="d-flex-checkbox">
          <input type="radio" id="yes{{item.containerNo}}" name="yes{{item.containerNo}}" 
            [(ngModel)]="item.isConfirm" value="Y">
          <label for="yes{{item.containerNo}}" class="mr-20px">Yes</label>

          <input type="radio" id="no{{item.containerNo}}" name="no{{item.containerNo}}"
            [(ngModel)]="item.isConfirm" value="N">
          <label for="no{{item.containerNo}}">No</label>
        </div>
      </td>
    </tr>
  </table>
</div>

<div id="pdfTallyListReport" style="display:none;">
  <div class="ui form">   
    <div class="field">
      <div id='pdfTallyListReport-viewer' class="field" style="width:100%;height:570px;">
      </div>
    </div>
  </div>

</div>

<footer-plugin [info]="info"></footer-plugin>
