
export class Region  {
    no : number = 0;
    regionId    : string = '';
    regionName  : string = '';
    regionCode  : string = '';
    regionPosition: number=0 ;
    linerTradeCode : string = '';
    exception : boolean = false;
    isValid        : boolean = true;
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userValid : string = '';
    dateValid : string = '';
    action : string = '';
    userDeleted : string = '';
    dateDeleted : string = '';
    isDeleted      : boolean = false;
    constructor() {

    }
}
