

export class Terminal {
  no : number = 0 ;
  terminalId   : string = '';
  locationId   : string = '';
  locationCode  : string = '';
  locationName  : string = '';
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  
  userModified : string ='';

  constructor() {

  }
}
