
export class MService{
    no : number;
    serviceId : string = '';
    serviceCode : string = '';
    serviceSequence : number = 1;
    psaServiceCode : string = '';
    serviceName : string = '';
    exchRateDays : number = 0;
    nonSslInd : boolean = false;
    expired : boolean = false;
    slotBill : boolean = false;
    isSsl : boolean = false;
    isSlot : boolean = false;
    is3RdParty : boolean = false;
    isValid : boolean = true;
    userCreated : string = '';
    dateCreated :  string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid :  string = '';
    remarks : string ='';


    copyServiceName : string = '';
    copyServiceCode : string = '';
    copyPsaServiceCode : string = '';
    copySeq : number = 1;
    seqCop : number;

    sBound: string = '';

    masterServicesByServiceId:any=[];
    
    constructor() { 
      
    }
}