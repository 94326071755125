<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
    <div class="ui medium text loader">Loading</div>
  </div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <form class="ui form">
      <!-- <br />
      <div class="field">
        <h1><b>TRANSMIT BOOKING DATA TO PSA IS CURRENTLY UNDER CONSTRUCTION !</b></h1>
        <p>Please come back again later. :)</p>
        <br/>
        <p><i>GLOSSYS Team</i></p>
      </div> -->

      <div class="field" style="margin-top: 50px;">
        <div class="fields">
          <div class="five wide field">
            <div class="ui checkbox">
              <input name="transmitViaSFTP" type="checkbox" [(ngModel)]="transmitViaSFTP">
              <label>Transmit directly via SFTP</label>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field"
            [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"
              style="width:100%;"></combo-plugin>
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
              {{model['error-vesselName']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
            <label>Voyage</label>
            <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)"
              style="width:100%;"></combo-plugin>
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
              {{model['error-voyage']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <label>Bound</label>
            <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['bound']"
              (change)="changeEventBound($event)" (keypress)="noneSpace($event)">
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
              {{model['error-bound']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <fieldset style="border: 1px solid lightgray; height: 100%; width: 30%" id="myfieldset3">

          <legend style="font-weight:bold;color:dodgerblue;">Container Ownnership</legend>

          <div class="ui radio checkbox">
            <input type="radio" name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'"
              (change)="changeEventTransmit($event)" value="SOC">
            <label>SOC</label>
          </div>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="ui radio checkbox">
            <input type="radio" name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'"
              (change)="changeEventTransmit($event)" value="COC">
            <label>COC</label>
          </div>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="ui radio checkbox">
            <input type="radio" name="containerOwner" [checked]="radioContainerOwnerSLOT == 'Y'"
              (change)="changeEventTransmit($event)" value="SLOT">
            <label>SLOT</label>
          </div>
        </fieldset>
      </div>

      <div class="field" style="width: 30%;">
        <div class="three field"
          [class.error]="model['error-bBookingPartyCode'] != null && model['error-bBookingPartyCode'] != ''"
          style="width:100%">
          <label>Booking Party</label>
          <combo-plugin #cbBookingParty [settings]="settingBookingParty" (change)="changeEventBookingParty($event)"
            style="width:100%;"></combo-plugin>
        </div>
      </div>
    
      <div class="one wide fields" style="width: 15%">
        <div
          class="one field"
          style="width: 50%"
          [class.error]="
            (model['bContainerOwnership'] == 'SOC' ||
              model['bContainerOwnership'] == 'SOCTS') &&
            model['error-SlotOperatorCodeValidator'] != null &&
            model['error-SlotOperatorCodeValidator'] != ''
          "
        >
          <label>CNTR OPR</label>
          <combo-plugin
            #cbSlotCntrOPR
            [settings]="settingSlotCntrOPR"
            (change)="changeEventSlotCntrOPR($event)"
          ></combo-plugin>
        </div>
        <div
          class="one field"
          style="width: 50%"
          [class.error]="
            model['bContainerOwnership'] == 'SLOT' &&
            model['error-operatorCodeValidator'] != null &&
            model['error-operatorCodeValidator'] != ''
          "
        >
         <div [style.display]="!isShowSOOPRInputOfSOCCOC ? 'block' : 'none'">
          <label class="title-opr-al">SO OPR</label>
          <combo-plugin
            #cbSlotOPR
            [settings]="settingSlotOPR"
            (change)="changeEventSlotOPR($event)"
          ></combo-plugin>
         </div>

         <div class="one field"  [style.display]="isShowSOOPRInputOfSOCCOC ? 'block' : 'none'">
            <label>SO OPR</label>
            <input
              type="text"
              name="soOprSoc"
              [(ngModel)]="model['bSlotOperatorCode']"
              placeholder=""
              class="soOprSoc-ct-al"
            />
          </div>
        </div>
      </div>
    </form>
  </div> <!-- end div container -->
</form>

<pdf-plugin #ediTransmissionList style="margin-left:3px;" [settings]="settingPDF"
  (change)="eventPDFPlugin($event);"></pdf-plugin>
<footer-plugin [info]="info"></footer-plugin>