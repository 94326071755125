<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field" style="width:400px">
       <label>Vessel Name:</label>
       <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:1400px;"></combo-plugin>
      </div>
      
      <div class="field" style="width:400px">
        <label>Voyage:</label>
        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Of Load:</label>
        <combo-plugin #cbPOL [settings]="settingPOL" (change)="changeEventPOL($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Of Discharge:</label>
        <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:1400px;"></combo-plugin>
      </div>
      <div class="field" style="width:400px">
        <label>Port Of Transhipment:</label>
        <combo-plugin #cbPOT [settings]="settingPOT" (change)="changeEventPOT($event)" style="width:1400px;"></combo-plugin>
      </div>
       <div class="field" style="width:400px">
        <label>Bound:</label>
        <input type="text" name="bound"  placeholder="Please Enter Bound" [(ngModel)]="model['bound']" >
      </div>
      <div class="field" style="width:400px">
        <label>In Slot:</label>
        <input type="text" name="inSlot"  placeholder="Please Enter In Slot" [(ngModel)]="model['so']" >
      </div>
      <div class="field" style="width:400px">
        <label>Operator Code:</label>
        <input type="text" name="opCode"  placeholder="Please Enter Operator Code" [(ngModel)]="model['op']" >
      </div>
      <div class="field" style="width:400px">
        <label>Report Type :</label>
          <div class = "field">         
            <div class="ui radio checkbox" (change)="eventRadio($event,'PDF')">
              <input id="rType" type="radio" value='pdf' name="rType">
              <label>PDF</label>
            </div> 
          </div>
          <div class="field">    
            <div class="ui radio checkbox" (change)="eventRadio($event,'EXCEL')">
              <input id="rType" type="radio" value='xls'  name="rType" >
              <label>Excel</label>
            </div>              
          </div>   
      </div>
      
    </form>

  </div>  <!-- end div container -->

</form>


<pdf-plugin #cllPdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin> 
