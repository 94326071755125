export class cllForm {
    vesselId:string = "";
    vesselCode:string = "";
    vesselName:string = "";
    baseVoyage:string = "";
    vesselBound:string = "O";

    portLoadId:string = "";
    portLoadCode:string = "";
    portLoadName:string = "";

    portDischargeId:string = "";
    portDischargeCode:string = "";
    portDischargeName:string = "";

    finalDestinationId:string = "";
    finalDestinationCode:string = "";
    finalDestinationName:string = "";

    containerNo:string = "";
    op:string = "";
    so:string = "";

    eta:string = "";
    etd:string = "";

    bookingNo = "";

    constructor(){}
}

export class cllGrid {
    processID:string = "";
    operatorCode:string = "";
    containerNo:string = "";
    typeSize:string = "";
    weight:number = 0;
    sealNo1:string = "";
    sealNo2:string = "";
    sealNo3:string = "";
    status:string = "";
    tranship:string = "";
    vesselCode:string = "";
    vesselId:string = "";
    vesselName:string = "";
    voyage:string = "";
    bound:string = "O";
    blNo:string = "";
    loadPort:string = "";
    loadPortName:string = "";
    transhipPort:string = "";
    transhipPortName:string = "";
    discPort1:string = "";
    discPort1Name:string = "";
    discPort2:string = "";
    discPort2Name:string = "";
    discPort3:string = "";
    discPort3Name:string = "";
    finalDest:string = "";
    finalDestName:string = "";
    nextVesselID:string = "";
    nextVesselCode:string = "";
    nextVesselName:string = "";
    nextVoyage:string = "";
    nextBound:string = "";
    shipperID:string = "";
    shipperName:string = "";
    commodityId:string = "";
    commodityCode:string = "";
    commodityName:string = "";
    reefer:boolean = false;
    reefer_display:string = "N";
    reeferAsDry:boolean = false;
    reeferAsDry_display:string = "N";
    reeferTemp = "0";
    reeferTemp_display:string = "";
    tempScale:string = "C";
    dg:boolean = false;
    dg_display:string = "N";
    imo:string = "";
    unno:string = "";
    flashSign:string = "+";
    flashDegree:string = "";
    flashPoint:string = "0";
    flashPoint_display:string = "";
    flashScale:string = "C";
    oog:boolean = false;
    oog_display:string = "N";
    oogScale:string = "C";
    oh:string = "0";
    oh_display:string = "";
    ohScale:string = "CM";
    owl:string = "0";
    owl_display:string = "";
    owr:string = "0";
    owr_display:string = "";
    olf:string = "0";
    olf_display:string = "";
    olb:string = "0";
    olb_display:string = "";

    spDetailsId:string = "";
    spDetails:string = "";
    bNo:string = "";
    inSlot:string = "";
    cellLocation:string = "";
    pkgTypeId:string = "";
    pkgTypeCode:string = "";
    pkgTypeName:string = "";
    bundle:boolean = false;
    bundle_display:string = "N";
    remarks:string = "";
    uc:boolean = false;
    uc_display:string = "N";

    transmitToFISSI:string = "N";
    transmitToFISSIDate:string = "";
    transmittoFISSIUSer:string = "";
    userId:string = "";
    dateCreated:string = "";
    lastModified:string = "";
    stackDateTime:string = "";

    uch:number = 0;
    uchScale:string = "CM";
    ucl:number = 0;
    uclScale:string = "CM";
    ucw:number = 0;
    ucwScale:string = "CM";

    tsStatusAtPOD:string = "";
    grossWtShipper:number = 0;

    vgmShipper:string = "";
    vgmTerminal:string = "";
    vgmStatus:boolean = false;
    vgmStatus_display:string = "N";
    vgmMethod:string = "";
    isSSL:boolean = false;
    isSSL_display:string = "N";

    bprefix:string = "SUDU";
    bofficeCode:string = "";
    cntrOpr:string = "";

    tempSign:string = "+";
    tempNumber:number = 0;
    tempDegree:string = "";

    cargoDescription:string = "";

    containerList = [];

    userModified:string = "";
    modifiedDate:string = "";

    isValid:boolean = true;
    userInvalidate:string = "";
    invalidateDate:string = "";

    thruSpt:string = "N";

    indicator:string = "Y";
    method:string = "";
    pax:string = "";
    containerType:string = "";
    containerSize:string = "";

    multipleDGIndicator:boolean = false;
    noOfPkg:number = 0;
    dgWT:number = 0;
    dgProductName:string = "";
    dgProperShippingName:string = "";

    reeferCommodityDesc:string = "";
    dgCommodityDesc:string = "";

    dgClass:string = "";

    cllReeferDegree: String = "";
    cllNatureTemp: String = "N/A";
    cllReeferCollectionDate: String = "";
    cllDegreeSo: String = "";
    cllSetOption: String = "";
    cllIsDry: String = "";
    cllVent: String = "";
    cllVentNumber: Number = 0;
    cllVentOption: String = "O";
    cllHumidity: String = "";

    

    allData = [];

    newCllGrid(){
        this.processID = "";
        this.operatorCode = "";
        this.status = "";
        this.tranship = "";
        this.vesselCode = "";
        this.vesselId = "";
        this.vesselName = "";
        this.voyage = "";
        this.bound = "";
        this.blNo = "";
        this.loadPort = "";
        this.loadPortName = "";
        this.transhipPort = "";
        this.transhipPortName = "";
        this.discPort1 = "";
        this.discPort1Name = "";
        this.discPort2 = "";
        this.discPort2Name = "";
        this.discPort3 = "";
        this.discPort3Name = "";
        this.finalDest = "";
        this.finalDestName = "";
        this.nextVesselID = "";
        this.nextVesselCode = "";
        this.nextVesselName = "";
        this.nextVoyage = "";
        this.nextBound = "";
        this.shipperID = "";
        this.shipperName = "";
        this.commodityId = "";
        this.commodityName = "";
        this.reefer = false;
        this.reefer_display = "";
        this.reeferAsDry = false;
        this.reeferAsDry_display = "";
        this.reeferTemp = "";
        this.tempScale = "";
        this.dg = false;
        this.dg_display = "";
        this.imo = "";
        this.unno = "";
        this.flashSign = "";
        this.flashDegree = "";
        this.flashPoint = "0";
        this.flashScale = "";
        this.oog = false;
        this.oog_display = "";
        this.oogScale = "";
        this.oh = "";
        this.ohScale = "";
        this.owl = "";
        this.owr = "";
        this.olf = "";
        this.olb = "";
        this.spDetailsId = "";
        this.spDetails = "";
        this.bNo = "";
        this.inSlot = "";
        this.pkgTypeId = "";
        this.pkgTypeCode = "";
        this.pkgTypeName = "";
        this.bundle = false;
        this.bundle_display = "";
        this.remarks = "";
        this.uc = false;
        this.uc_display = "";
        this.transmitToFISSI = "";
        this.transmitToFISSIDate = "";
        this.transmittoFISSIUSer = "";
        this.userId = "";
        this.dateCreated = "";
        this.stackDateTime = "";
        this.uch = 0;
        this.uchScale = "";
        this.ucl = 0;
        this.uclScale = "";
        this.ucw = 0;
        this.ucwScale = "";
        this.vgmShipper = "";
        this.vgmTerminal = "";
        this.vgmStatus = false;
        this.vgmStatus_display = "";
        this.vgmMethod = "";
        this.isSSL = false;
        this.bofficeCode = "";
        this.cntrOpr = this.operatorCode;
        this.tempSign = "";
        this.tempDegree = this.tempScale;
        this.cargoDescription = "";
        this.userModified = "";
        this.modifiedDate = "";
        this.isValid = false;
        this.userInvalidate = "";
        this.invalidateDate = "";
        this.thruSpt = "";
        this.cellLocation = "";
        this.indicator = "";
        this.multipleDGIndicator = false;
        this.noOfPkg = 0;
        this.reeferCommodityDesc = "";
        this.dgCommodityDesc = "";
        this.dgClass = "";

        this.cllReeferDegree= "";
        this.cllNatureTemp = "N/A";
        this.cllReeferCollectionDate= "";
        this.cllDegreeSo = "";
        this.cllSetOption = "";
        this.cllIsDry = "";
        this.cllVent= "";
        this.cllVentNumber= 0;
        this.cllVentOption = "O";
        this.cllHumidity = "";
    }

    convertDbToObject(data:any){
        this.processID = data["cllProcessId"];
        this.operatorCode = data["cllOpCode"];
        this.status = data["cllIsEmpty"];
        this.tranship = data["cllIsTranship"];
        this.vesselCode = data["cllVesselCode"];
        this.vesselId = data["cllVesselId"];
        this.vesselName = data["cllVesselName"];
        this.voyage = data["cllVesselVoyage"];
        this.bound = data["cllVesselBound"];
        this.blNo = data["cllBlRef"];
        this.loadPort = data["cllPolCode"];
        this.loadPortName = data["loadPortName"]
        this.transhipPort = data["cllPotCode"];
        this.transhipPortName = data["transhipPortName"];
        this.discPort1 = data["cllPod1Code"];
        this.discPort1Name = data["discPortName1"];
        this.discPort2 = data["cllPod2Code"];
        this.discPort2Name = data["discPortName2"];
        this.discPort3 = data["cllPod3Code"];
        this.discPort3Name = data["discPortName3"];
        this.finalDest = data["cllFdestCode"];
        this.finalDestName = data["finalDestName"];
        this.nextVesselID = data["cllNextVesselId"];
        this.nextVesselCode = data["cllNextVesselCode"];
        this.nextVesselName = data["cllNextVesselName"];
        this.nextVoyage = data["cllNextVesselVoyage"];
        this.nextBound = "O";
        this.shipperID = data["cllShipperId"];
        this.shipperName = data["cllShipperName"];
        this.commodityId = data["cllCommodityId"];
        this.commodityName = data["cllCommodityName"];
        this.reefer = (data["cllIsReefer"] == "Y" ?  true : false);
        this.reeferAsDry = (data["cllReeferAsDry"] == "Y" ?  true : false);
        this.reeferTemp = (parseFloat(data["cllReeferTemp"]) < 0 ? (parseFloat(data["cllReeferTemp"])*-1).toString() : data["cllReeferTemp"]);
        this.tempScale = data["cllReeferScale"];
        this.dg = (data["cllIsDg"] == "Y" ? true : false);
        this.imo = data["cllImo"];
        this.unno = data["cllUnno"];
        this.flashSign = (parseFloat(data["cllFlashPointTemp"]) < 0 ? "-" : "+" );
        this.flashDegree = data["cllFlashPointScale"];
        this.flashPoint = (parseFloat(data["cllFlashPointTemp"]) < 0 ? (-1 * parseFloat(data["cllFlashPointTemp"])).toString() : data["cllFlashPointTemp"] );
        this.flashScale = data["cllFlashPointScale"];
        this.oog = (data["cllIsOog"] == "Y" ? true : false);
        this.oh = data["cllOh"];
        this.ohScale = data["cllOhScale"];
        this.owl = data["cllOwl"];
        this.owr = data["cllOwr"];
        this.olf = data["cllOlf"];
        this.olb = data["cllOlb"];
        this.spDetailsId = data["cllSpDetailsId"];
        this.spDetails = data["cllSpDetails"];
        this.bNo = data["cllBookingRef"];
        this.inSlot = data["cllSoCode"];
        this.pkgTypeId = data["cllPackageTypeId"];
        this.pkgTypeCode = data["cllPackageType"];
        this.pkgTypeName = data["cllPackageType"];
        this.bundle = (data["cllIsBundle"] == "Y" ? true : false);
        this.remarks = data["cllRemarks"];
        this.uc = (data["cllIsUc"] == "Y" ? true : false);
        this.transmitToFISSI = data["cllIsTransmited"];
        this.transmitToFISSIDate = data["cllTransmitedDate"];
        this.transmittoFISSIUSer = data["cllUserTransmited"];
        this.userId = data["cllUserCreated"];
        this.dateCreated = data["cllCreatedDate"];
        this.stackDateTime = data["cllStackDateTime"];
        this.uch = parseFloat(data["cllUch"]);
        this.uchScale = data["cllUchScale"];
        this.ucl = parseFloat(data["cllUcl"]);
        this.uclScale = data["cllUclScale"];
        this.ucw = parseFloat(data["cllUcw"]);
        this.ucwScale = data["cllUcwScale"];
        this.vgmShipper = data["cllVgmShippper"];
        this.vgmTerminal = data["cllVgmTerminal"];
        this.vgmStatus = (data["cllVgmStatus"] == "Y" ? true : false);
        this.vgmMethod = data["cllVgmMethod"];
        this.isSSL = false;
        this.bofficeCode = data["cllOfficeCode"];
        this.cntrOpr = this.operatorCode;
        this.tempSign = (parseFloat(data["cllReeferTemp"]) < 0 ? "-" : "+" );;
        this.tempDegree = this.tempScale;
        this.cargoDescription = data["cllCgoDesc"];
        this.userModified = data["cllUserModified"];
        this.modifiedDate = data["cllModifiedDate"];
        this.isValid = (data["cllIsValid"] == "Y" ? true : false);
        this.userInvalidate = data["cllUserInvalidate"];
        this.invalidateDate = data["cllInvalidateDate"];
        this.thruSpt = data["cllIsThruSpt"];
        this.cellLocation = data["cllCellLocation"];
        this.reeferCommodityDesc = data["cllReeferCommodityDesc"];
        this.dgCommodityDesc = data["cllDgCommodityDesc"];
        this.dgClass = data["cllDgClass"];

        this.cllReeferDegree= data["cllReeferDegree"];
        this.cllNatureTemp= data["cllNatureTemp"];
        this.cllReeferCollectionDate= data["cllReeferCollectionDate"];
        this.cllDegreeSo= data["cllDegreeSo"];
        this.cllSetOption= data["cllSetOption"];
        this.cllIsDry= data["cllIsDry"];
        this.cllVent= data["cllVent"];
        this.cllVentNumber= data["cllVentNumber"];
        this.cllVentOption= data["cllVentOption"];
        this.cllHumidity= data["cllHumidity"];

        if(data["operationCLLDetails"] != null){
            var details = [];
            details = data["operationCLLDetails"];

            details.forEach(de=>{
                var det = new cllContainer;
                det.convertDbToObject(de);
                det["display_indicator"] = (det.containerVgmIndicator?"Y":"N");

                this.containerList.push(det);
            });
        }

        if(data["operationCllDetailsList"] != null){
            var details = [];
            details = data["operationCllDetailsList"];
            //// console.log("details");
            details.forEach(de=>{
                var det = new cllContainer;
                det.convertDbToObject(de);
                //// console.log(det);
                this.containerList.push(det);
            });
        }

    }


    constructor(){}
}

export class cllContainer{
    processID:string = "";
    containerNo:string = "";
    containerSize:string = "";
    containerHeight:string = "";
    containerWeight:number = 0;
    containerType:string = "";
    containerSealNo1:string="";
    containerSealNo2:string="";
    containerSealNo3:string="";
    typeSize:string = "";
    containerVgmWeight:number=0;
    containerVgmIndicator:boolean=true;
    containerVgmMethod:string="";
    pax:string="";
    cellLocation:string = "";
    containerSeq:number = 0;
    containerTypeName:string = "";
    no:number=0;

    convertDbToObject(data:any){
        this.processID = data["cllProcessId"];
        this.containerNo = data["cllContainerNo"];
        this.containerSize = data["cllContainerSize"];
        this.containerWeight = parseFloat(data["cllContainerWeight"]);
        this.containerHeight = data["cllContainerHeight"];
        this.containerType = data["cllContainerType"];
        this.containerSealNo1 = data["cllContainerSealNo1"];
        this.containerSealNo2 = data["cllContainerSealNo2"];
        this.containerSealNo3 = data["cllContainerSealNo3"];
        this.containerVgmIndicator = (data["cllContainerIndicator"] == "Y" ? true : false);
        this.containerVgmMethod = data["cllContainerMethod"];
        this.pax = data["cllContainerPax"];
        this.cellLocation = data["cllContainerCellLocation"];
        this.containerSeq = parseInt(data["cllContainerSeq"]);
        this.containerTypeName = data["containerTypeName"];

        var mapping = new perMapping;
        //this.typeSize = mapping.getPerMapping(this.containerSize, this.containerType, this.containerHeight);
        this.typeSize = data["d4allContainerCode"];

        //return this;
    }

    constructor(){}
}

export class uploadContainer{
    fileName:string = "";

    constructor(){}
}

export class cllHeader{
    vesselID:string = "";
    vesselCode:string = "";
    vesselVoyage:string = "";
    vesselBound:string = "O";
    isEmpty:boolean = false;
    isTranship:boolean = false;
    stackDateTime:string = "";
    bookingRef:string = "";
    blRef:string = "";
    polCode:string = "";
    pod1Code:string = "";
    pod2Code:string = "";
    pod3Code:string = "";
    fDestCode:string = "";
    potCode:string = "";
    nextVesselID:string = "";
    nextVesselCode:string = "";
    nextVesselVoyage:string = "";
    shipperID:string = "";
    shipperName:string = "";
    commodityId:string = "";
    commodityCode:string = "";
    commodityName:string = "";
    vgmStatus:boolean = false;
    vgmMethod:string = "";
    vgmShipper:string = "";
    vgmTerminal:string = "";
    officeCode:string = "";
    userCreated:string = "";
    createdDate:string = "";
    userModified:string = "";
    modifiedDate:string = "";
    isValid:boolean = false;
    userInvalidate:string = "";
    invalidateDate:string = "";
    isTransmitted:boolean = false;
    userTransmitted:string = "";
    transmittedDate:string = "";
    other:string = "";
    processID:string = "";
    isBundle:boolean = false;
    isReefer:boolean = false;
    reeferAsDry:boolean = false;
    reeferTemp:string = "0";
    reeferScale:string = "C";
    isDG:boolean = false;
    imo:string = "";
    unno:string = "";
    flashPointTemp:string = "0";
    flashPointScale:string = "C";
    packageTypeId:string = "";
    packageType:string = "";
    isOOG:boolean = false;
    oh:string = "0";
    ohScale:string = "CM";
    owl:string = "0";
    owr:string = "0";
    olf:string = "0";
    olb:string = "0";
    isUC:boolean = false;
    uch:number = 0;
    uchScale:string = "CM";
    ucl:number = 0;
    uclScale:string = "CM";
    ucw:number = 0;
    ucwScale:string = "CM";
    cgoDesc:string = "";
    spDetailsId:string = "";
    spDetails:string = "";
    remarks:string = "";
    opCode:string = "";
    soCode:string = "";
    cellLocation:string = "";

    //additionalField
    isThruSpt:boolean = false;

    reeferCommodityDesc:string = "";
    dgCommodityDesc:string = "";

    dgClass:String = "";

    cllReeferDegree: String = "";
    cllNatureTemp: String = "N/A";
    cllReeferCollectionDate: String = "";
    cllDegreeSo: String = "";
    cllSetOption: String = "";
    cllIsDry: String = "";
    cllVent: String = "";
    cllVentNumber: Number = 0;
    cllVentOption: String = "O";
    cllHumidity: String = "";

    cllDetails : any = [];


    convertDbToObject(data:any){
        this.processID = data["cllProcessId"];
        this.vesselID = data["cllVesselId"];
        this.vesselCode = data["cllVesselCode"];
        this.vesselVoyage = data["cllVesselVoyage"];
        this.vesselBound = data["cllVesselBound"];
        this.isEmpty = (data["cllIsEmpty"] == "Y" ? true : false);
        this.isTranship = (data["cllIsTranship"] == "Y" ? true : false);
        this.stackDateTime = data["cllStackDateTime"];
        this.bookingRef = data["cllBookingRef"];
        this.blRef = data["cllBlRef"];
        this.polCode = data["cllPolCode"];
        this.pod1Code = data["cllPod1Code"];
        this.pod2Code = data["cllPod2Code"];
        this.pod3Code = data["cllPod3Code"];
        this.fDestCode = data["cllFdestCode"];
        this.potCode = data["cllPotCode"];
        this.nextVesselID = data["cllNextVesselId"];
        this.nextVesselCode = data["cllNextVesselCode"];
        this.nextVesselVoyage = data["cllNextVesselVoyage"];
        this.shipperID = data["cllShipperId"];
        this.shipperName = data["cllShipperName"];
        this.commodityName = data["cllCommodityName"];
        this.vgmStatus = (data["cllVgmStatus"] == "Y" ? true : false);
        this.vgmMethod = data["cllVgmMethod"];
        this.vgmShipper = data["cllVgmShippper"];
        this.vgmTerminal = data["cllVgmTerminal"];
        this.officeCode = data["cllOfficeCode"];
        this.userCreated = data["cllUserCreated"];
        this.createdDate = data["cllCreatedDate"];
        this.userModified = data["cllUserModified"];
        this.modifiedDate = data["cllModifiedDate"];
        this.isValid = (data["cllIsValid"] == "Y" ? true : false);
        this.userInvalidate = data["cllUserInvalidate"];
        this.invalidateDate = data["cllInvalidateDate"];
        this.isTransmitted = (data["cllIsTransmited"] == "Y" ? true : false);
        this.userTransmitted = data["cllUserTransmited"];
        this.transmittedDate = data["cllTransmitedDate"];
        this.other = data["other"];
        this.isBundle = (data["cllIsBundle"] == "Y" ? true : false);
        this.isReefer = (data["cllIsReefer"] == "Y" ? true : false);
        this.reeferAsDry = (data["cllReeferAsDry"] == "Y" ? true : false);
        this.reeferTemp = data["cllReeferTemp"];
        this.reeferScale = data["cllReeferScale"];
        this.isDG = (data["cllIsDg"] == "Y" ? true : false);
        this.imo = data["cllImo"];
        this.unno = data["cllUnno"];
        this.flashPointTemp = data["cllFlashPointTemp"];
        this.flashPointScale = data["cllFlashPointScale"];
        this.packageType = data["cllPackageType"];
        this.isOOG = (data["cllIsOog"] == "Y" ? true : false);
        this.oh = data["cllOh"];
        this.owl = data["cllOwl"];
        this.owr = data["cllOwr"];
        this.olf = data["cllOlf"];
        this.olb = data["cllOlb"];
        this.isUC = (data["cllIsUc"] == "Y" ? true : false);
        this.uch = parseFloat(data["cllUch"]);
        this.uchScale = data["cllUchScale"];
        this.ucl = parseFloat(data["cllUcl"]);
        this.uclScale = data["cllUclScale"];
        this.ucw = parseFloat(data["cllUcw"]);
        this.ucwScale = data["cllUcwScale"];
        this.cgoDesc = data["cllCgoDesc"];
        this.spDetails = data["cllSpDetails"];
        this.remarks = data["cllRemarks"];
        this.opCode = data["cllOpCode"];
        this.soCode = data["cllSoCode"];
        this.reeferCommodityDesc = data["cllReeferCommodityDesc"];
        this.dgCommodityDesc = data["cllDgCommodityDesc"];
        this.dgClass = data["cllDgClass"];

        this.cllReeferDegree= data["cllReeferDegree"];
        this.cllNatureTemp= data["cllNatureTemp"];
        this.cllReeferCollectionDate= data["cllReeferCollectionDate"];
        this.cllDegreeSo= data["cllDegreeSo"];
        this.cllSetOption= data["cllSetOption"];
        this.cllIsDry= data["cllIsDry"];
        this.cllVent= data["cllVent"];
        this.cllVentNumber= data["cllVentNumber"];
        this.cllVentOption= data["cllVentOption"];
        this.cllHumidity= data["cllHumidity"];

        var details = [];
        details = data["operationCLLDetails"];
        details.forEach(de=>{
            var det = new cllContainer;
            det.convertDbToObject(de);

            this.cllDetails.push(det);

        });

        return this;
    }

    constructor(){}
}

export class perMapping{
    perMapping = {
        M20STD86 : "D20",
        M40STD86 : "D40",
        M45STD86 : "D45",
        M20STD96 : "HC20",
        M40STD96 : "HC40",
        M45STD96 : "HC45",
        M20DRY86 : "R20",
        M40DRY86 : "R40",
        M45DRY86 : "R45",
        M20FLR86 : "FR20",
        M40FLR86 : "FR40",
        M45FLR86 : "FR45",
        M20FLR96 : "FRH20",
        M40FLR96 : "FRH40",
        M45FLR96 : "FRH45",
        M20DRY96 : "RH20",
        M40DRY96 : "RH40",
        M45DRY96 : "RH45",
        M20OPT86 : "OT20",
        M40OPT86 : "OT40",
        M45OPT86 : "OT45",
        M20OPT96 : "OTH20",
        M40OPT96 : "OTH40",
        M45OPT96 : "OTH45",
        M20PLT86 : "PL20S",
        M40PLT86 : "PL40",
        M45PLT86 : "PL45",
        M20PLT96 : "PLH20",
        M40PLT96 : "PLH40",
        M45PLT96 : "PLH45",
        M20REF86 : "R20",
        M40REF86 : "R40",
        M45REF86 : "R45",
        M20REF96 : "RH20",
        M40REF96 : "RH40",
        M45REF96 : "RH45",
        M20TNK86 : "TK20",
        M40TNK86 : "TK40",
        M45TNK86 : "TK45"
    }

    getPerMapping(containerSize:string, containerType:string, containerHeight:string){
        return this.perMapping["M"+containerSize+containerType+containerHeight];
    }

    constructor(){}
}

export class cllDGForm{
    processId:string = "";
    seq:number;
    imo:string = "";
    unno:string = "";
    flashPointSign:string = "+";
    flashPointTemp:number = 0;
    flashPointScale:string = "C";
    flashPoint_display:string = "";
    packageTypeId:string = "";
    packageTypeCode:string = "";
    packageTypeName:string = "";
    noOfPackage:number = 0;
    dgWt:number = 0;
    productName:string = "";
    properShippingName:string = "";

    reset(){
        this.imo = "";
        this.unno = "";
        this.flashPointSign = "+";
        this.flashPointTemp = 0;
        this.flashPointScale = "C";
        this.flashPoint_display = "";
        this.packageTypeId = "";
        this.packageTypeCode = "";
        this.packageTypeName = "";
        this.noOfPackage = 0;
        this.dgWt = 0;
        this.productName = "";
        this.properShippingName = "";
    }

    setPostData(data){
        var postData = {};
        postData["processId"] = data.processId;
        postData["seq"] = data.seq;
        postData["imo"] = data.imo;
        postData["unno"] = data.unno;
        postData["flashPoint"] = (data.flashPointSign == "+"? 1:-1) * data.flashPointTemp;
        postData["flashPointScale"] = data.flashPointScale;
        postData["packageTypeId"] = data.packageTypeId;
        postData["packageTypeName"] = data.packageTypeName;
        postData["dgWt"] = data.dgWt;
        postData["noOfPkg"] = data.noOfPackage;
        postData["productName"] = data.productName;
        postData["propShippingName"] = data.properShippingName;

        return postData;
    }


    constructor(){}
}
