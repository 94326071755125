export class ediVesselScheduleDetails{
    functionIndicator:string = '';
    type:string = 'DT';
    vesselName:string = '';
    voyageNumber:string = '';
    portOfCall:string = '';
    sequence:string = '';
    eta:string = '';
    etd:string = '';
    beforeAfterIndicator:string = '';
    abbrVoyage1:string = '';
    abbrVoyage2:string = '';
    abbrVoyage3:string = '';
    abbrVoyage4:string = '';
    abbrVoyage5:string = '';
    ocp1:string = '';
    ocp2:string = '';
    ocp3:string = '';
    term1:string = '';
    term2:string = '';
    term3:string = '';
    term4:string = '';
    term5:string = '';
    berthingVoyage1:string = '';
    berthingVoyage2:string = '';
    berthingVoyage3:string = '';
    berthingVoyage4:string = '';
    berthingVoyage5:string = '';
    filler:string = '';

    constructor() { 
      
    }
}