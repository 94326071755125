<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>
  <dialog-plugin
    [settings]="dialog"
    (eventDialog)="eventMessage($event)"
  ></dialog-plugin>
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 1000"
  >
    <div class="ui medium text loader">Loading</div>
  </div>
  <div class="ui form">
    <div class="field">
      <fieldset style="border: 1px solid lightgray; height: 50px">
        <legend style="font-weight: bold; color: dodgerblue">
          Transfer By
        </legend>
        <!--div class="four fields"-->
        <div class="inline fields">
          <div class="field">
            <div class="ui radio checkbox">
              <!-- [disabled]="radioTransferByVessel == 'D'" -->
              <input
                type="radio"
                name="rdTransferBy"
                id="rdTransferBy"
                [checked]="radioTransferByVessel == 'Y'"
                (change)="changeEventTransferBy($event)"
                value="vessel"
              />
              <label>Vessel</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <!-- [disabled]="radioTransferByPOT == 'D'" -->
              <input
                type="radio"
                name="rdTransferBy"
                id="rdTransferBy"
                [checked]="radioTransferByPOT == 'Y'"
                (change)="changeEventTransferBy($event)"
                value="pot"
              />
              <label>Transshipment Port</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <!-- [disabled]="radioTransferByBNo == 'D'" -->
              <input
                type="radio"
                name="rdTransferBy"
                id="rdTransferBy"
                [checked]="radioTransferByBNo == 'Y'"
                (change)="changeEventTransferBy($event)"
                value="bookingNumber"
              />
              <label>Booking Number</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <!-- [disabled]="radioTransferByPOD == 'D'" -->
              <input
                type="radio"
                name="rdTransferBy"
                id="rdTransferBy"
                [checked]="radioTransferByPOD == 'Y'"
                (change)="changeEventTransferBy($event)"
                value="pod"
              />
              <label>Discharge Port (Direct Call)</label>
            </div>
          </div>
        </div>
        <!--/div-->
      </fieldset>
    </div>
    <div class="field">
      <div class="two fields">
        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="transferFromLock"
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Transfer From
            </legend>
            <div class="three fields">
              <div
                class="required field"
                [class.error]="
                  model['error-vesselIdOrigin'] != null &&
                  model['error-vesselIdOrigin'] != ''
                "
              >
                <label>Vessel</label>
                <combo-plugin
                  #cbVesselOrigin
                  [settings]="settingVesselOrigin"
                  (change)="
                    changeEventVesselOrigin($event);
                    model['error-vesselIdOrigin'] = ''
                  "
                  [disableCombo]="transferFromLock"
                  (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                  style="width: 100%"
                  (keypress)="formValidation.validateVesselVoyage($event)"
                  (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselIdOrigin')"
                  (paste)="onPasteValidation(
                    $event,
                    'vesselIdOrigin',
                    formValidation.regVesVoy)"
                ></combo-plugin>
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-vesselIdOrigin'] != null &&
                    model['error-vesselIdOrigin'] != ''
                  "
                >
                  {{ model["error-vesselIdOrigin"] }}
                </div>
              </div>
              <div
                class="required field"
                [class.error]="
                  model['error-voyageOrigin'] != null &&
                  model['error-voyageOrigin'] != ''
                "
              >
                <label>Voyage</label>
                <combo-plugin
                  #cbVoyageOrigin
                  [settings]="settingVoyageOrigin"
                  (change)="
                    changeEventVoyageOrigin($event);
                    model['error-voyageOrigin'] = ''
                  "
                  [disableCombo]="transferFromLock"
                  (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                  style="width: 100%"
                  (keypress)="formValidation.validateVesselVoyage($event)"
                  (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageOrigin')"
                  (paste)="onPasteValidation(
                    $event,
                    'voyageOrigin',
                    formValidation.regVesVoy)
                  "
                ></combo-plugin>
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-voyageOrigin'] != null &&
                    model['error-voyageOrigin'] != ''
                  "
                >
                  {{ model["error-voyageOrigin"] }}
                </div>
              </div>
              <div class="field">
                <label>Bound</label>
                <input
                  type="text"
                  name="boundOrigin"
                  readonly
                  placeholder="Bound"
                  [disabled]="transferFromLock"
                  [(ngModel)]="model['boundOrigin']"
                />
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-boundOrigin'] != null &&
                    model['error-boundOrigin'] != ''
                  "
                >
                  {{ model["error-boundOrigin"] }}
                </div>
              </div>
            </div>
            <div class="twelve wide field">
              <div class="field" style="width: 100%">
                <label style="float: left">Ports Of Call</label
                ><label style="float: left">&nbsp;:&nbsp;</label>

                <div class="field" style="width: 100%">
                  <label
                    style="
                      float: left;
                      font-weight: normal;
                      color: cornflowerblue;
                    "
                    >{{ portOfCallFrom }}</label
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="transferToLock"
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Transfer To
            </legend>
            <div class="three fields">
              <div class="required field">
                <label>Vessel</label>
                <combo-plugin
                  #cbVesselDestination
                  [settings]="settingVesselDestination"
                  (change)="
                    changeEventVesselDestination($event);
                    model['error-vesselIdDestination'] = ''
                  "
                  [disableCombo]="transferToLock"
                  style="width: 100%"
                  (keypress)="formValidation.validateVesselVoyage($event)"
                  (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselIdDestination')"
                  (paste)="onPasteValidation(
                    $event,
                    'vesselIdDestination',
                    formValidation.regVesVoy)"
                ></combo-plugin>
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-vesselIdDestination'] != null &&
                    model['error-vesselIdDestination'] != ''
                  "
                >
                  {{ model["error-vesselIdDestination"] }}
                </div>
              </div>
              <div class="required field">
                <label>Voyage</label>
                <combo-plugin
                  #cbVoyageDestination
                  [settings]="settingVoyageDestination"
                  (change)="
                    changeEventVoyageDestination($event);
                    model['error-voyageDestination'] = ''
                  "
                  [disableCombo]="transferToLock"
                  style="width: 100%"
                  (keypress)="formValidation.validateVesselVoyage($event)"
                  (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyageDestination')"
                  (paste)="onPasteValidation(
                    $event,
                    'voyageDestination',
                    formValidation.regVesVoy)"
                ></combo-plugin>
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-voyageDestination'] != null &&
                    model['error-voyageDestination'] != ''
                  "
                >
                  {{ model["error-voyageDestination"] }}
                </div>
              </div>
              <div class="field">
                <label>Bound</label>
                <input
                  type="text"
                  name="boundDestination"
                  readonly
                  placeholder="Bound"
                  [disabled]="transferToLock"
                  [(ngModel)]="model['boundDestination']"
                />
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    model['error-boundDestination'] != null &&
                    model['error-boundDestination'] != ''
                  "
                >
                  {{ model["error-boundDestination"] }}
                </div>
              </div>
            </div>
            <div class="twelve wide field">
              <div class="field" style="width: 100%">
                <label style="float: left">Ports Of Call</label
                ><label style="float: left">&nbsp;:&nbsp;</label>
                <br />
                <div class="field">
                  <label
                    style="
                      float: left;
                      font-weight: normal;
                      color: cornflowerblue;
                    "
                    >{{ portOfCallTo }}</label
                  >
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="two fields">
        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="radioTransferByPOD == 'D' || radioTransferByPOD == 'N'"
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Discharge Port
            </legend>
            <!--div class="twelve wide field"-->
            <div
              class="twelve wide field"
              [class.error]="
                model['error-podCode'] != null && model['error-podCode'] != ''
              "
            >
              <combo-plugin
                #cbPOD
                [settings]="settingPOD"
                (change)="changeEventPOD($event)"
                [disableCombo]="podLock == true"
                (keypress)="formValidation.ModifiedPattern($event, '- ')"
                style="width: 100%"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-podCode'] != null && model['error-podCode'] != ''
                "
              >
                {{ model["error-podCode"] }}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="
              radioTransferByPOD2 == 'D' || radioTransferByPOD2 == 'N'
            "
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Discharge Port
            </legend>
            <div class="twelve wide field">
              <!--[settings]="settingPOD2" (change)="changeEventPOD2($event)" -->
              <combo-plugin
                #cbPOD2
                [settings]="settingPOD2"
                [disableCombo]="podLock2 == true"
                (keypress)="formValidation.ModifiedPattern($event, '- ')"
                style="width: 100%"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-podCode2'] != null &&
                  model['error-podCode2'] != ''
                "
              >
                {{ model["error-podCode2"] }}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="two fields">
        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="radioTransferByPOT == 'D' || radioTransferByPOT == 'N'"
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Transshipment Port
            </legend>
            <!--div class="twelve wide field"-->
            <div
              class="twelve wide field"
              [class.error]="
                model['error-potCode'] != null && model['error-potCode'] != ''
              "
            >
              <combo-plugin
                #cbPOT
                [settings]="settingPOT"
                (change)="changeEventPOT($event)"
                [disableCombo]="potLock == true"
                (keypress)="formValidation.ModifiedPattern($event, '- ')"
                style="width: 100%"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-potCode'] != null && model['error-potCode'] != ''
                "
              >
                {{ model["error-potCode"] }}
              </div>
            </div>
          </fieldset>
        </div>
        <div class="field">
          <fieldset
            style="border: 1px solid lightgray"
            [disabled]="
              radioTransferByPOT2 == 'D' || radioTransferByPOT2 == 'N'
            "
          >
            <legend style="font-weight: bold; color: dodgerblue">
              Transshipment Port
            </legend>
            <div class="twelve wide field">
              <combo-plugin
                #cbPOT2
                [settings]="settingPOT2"
                (change)="changeEventPOT2($event)"
                [disableCombo]="potLock2 == true"
                (keypress)="formValidation.ModifiedPattern($event, '- ')"
                style="width: 100%"
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-potCode2'] != null &&
                  model['error-potCode2'] != ''
                "
              >
                {{ model["error-potCode2"] }}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <div class="field">
      <div class="two fields">
        <div class="field">
          <div class="one field" style="width: 100%">
            <label style="float: left">Gross Available Teus</label
            ><label style="float: left">&nbsp;&nbsp;&nbsp;:&nbsp;</label
          ><label
            style="float: left; font-weight: normal; color: cornflowerblue"
          >{{ availableTeusTemp }}</label
          >
          </div>
          <div class="one field" style="width: 100%">
            <label style="float: left">Available Teus</label
            ><label style="float: left">&nbsp;&nbsp;&nbsp;:&nbsp;</label
            ><label
              style="float: left; font-weight: normal; color: cornflowerblue"
              >{{ availableTeus }}</label
            >
          </div>
          <div class="one field" style="width: 100%">
            <label style="float: left">Required Teus</label
            ><label style="float: left">&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;</label
            ><label
              style="float: left; font-weight: normal; color: cornflowerblue"
              >{{ requiredTeus }}</label
            >
          </div>
        </div>
        <div class="field">
          <div class="field" style="width: 48%; align-self: center">
            <div class="ui test checkbox">
              <input
                type="checkbox"
                name="isTeusDetailsShown"
                (change)="checkIsTeusDetailsShown($event)"
                id="cekDetailsShown"
                tabindex="0"
              />
              <label>Show Teus Details</label>
            </div>
          </div>
          <div class="field" style="width: 48%; align-self: center">
            <div class="ui test checkbox">
              <input
                type="checkbox"
                name="isBookingNumberMaintained"
                id="maintainBook"
                tabindex="0"
                checked="true"
                [disabled]="isDisabledMaintainBookingRef"
              />
              <label>Maintain Booking Ref.</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field" [style.display]="addRemoveGrid">
      <button
        class="tiny ui positive button"
        data-content="addGrid"
        (click)="rowEvent('add')"
        id="addGrid"
      >
        +
      </button>
      <button
        class="tiny ui negative button"
        data-content="remGrid"
        (click)="rowEvent('del')"
        id="remGrid"
      >
        -
      </button>
      <!--button  class="ui button split" data-content="addGrid" (click)="rowEvent('add')" id="addGrid" ><i class="plus icon"></i></button>
    <button  class="ui button split" data-content="remGrid" (click)="rowEvent('del')" id="remGrid" ><i class="remove icon"></i></button>
    --></div>

    <div
      class="field"
      [style.display]="modeForm == 'bookingTransferDefault' ? 'block' : 'none'"
    >
      <grid-plugin
        id="gridBookingTransfer"
        #gridBookingTransfer
        [settings]="settingGridBookingTransfer"
        (gridEvent)="gridBookingTransferEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>
    </div>

    <div
      class="field"
      [style.display]="modeForm == 'bookingTransferDefault2' ? 'block' : 'none'"
    >
      <grid-plugin
        id="gridBookingTransferByNo"
        #gridBookingTransferByNo
        [settings]="settingGridBookingTransferByNo"
        (gridEvent)="gridBookingTransferEventByNo($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>
    </div>

    <div
      class="field"
      [style.display]="
        modeForm == 'bookingTransferAllocation' ? 'block' : 'none'
      "
    >
      <grid-plugin
        id="gridBookingTransferAllocation"
        #gridBookingTransferAllocation
        [settings]="settingGridBookingTransferAllocation"
        (gridEvent)="gridBookingTransferAllocationEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>
    </div>
  </div>
</div>

<dialog-multiple-plugin
  #dialogMultiple
  (eventDialog)="eventMessage($event)"
></dialog-multiple-plugin>

<footer-plugin [info]="info"></footer-plugin>
