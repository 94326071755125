<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui error message" [class.hidden]="isErrorFlag == false">
    <ul>
      <li [class.hidden]="model['error-etaToValidator'] == null || model['error-etaToValidator'] == ''">
        {{model['error-etaToValidator']}}
      </li>
    </ul>
  </div>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <div class="field">      
            <div class="two fields">
              <div class="field">
                <div class="two fields">
                  <div class="field">
                    <label>Vessel ETA FROM</label>
                    <div class="ui left icon input">
                    <input id="etaFrom" readonly type="text" class="form-control" data-toggle="datepicker" placeholder="Date From">
                    <i class="calendar icon"></i>
                  </div>
                      <!-- div class="ui left icon input">
                        <input id="fromDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date"> 
                        <i class="calendar icon"></i>
                      </div -->
                      <!--ccalendar-plugin #dateLocalETA [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin -->
                      <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
                      <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-fromDate'] != null && model['error-fromDate'] != ''">
                        {{model['error-fromDate']}}
                      </div>            
                  </div>
                  <div class="field">
                    <label>Vessel ETA TO</label>
                    <div class="ui left icon input">
                    <input id="etaTo" readonly type="text"  class="form-control" data-toggle="datepicker" placeholder="Date To">
                       <i class="calendar icon"></i>
                      </div>
                    <!--div class="ui left icon input">
                      <input id="toDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date">
                      <i class="calendar icon"></i>
                    </div -->
                      <!-- calendar-plugin #dateLocalETD [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin -->
                      <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
                      <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-toDate'] != null && model['error-toDate'] != ''">
                        {{model['error-toDate']}}
                      </div>            
                  </div>
                </div>
              </div>
            </div>
          </div>  

          <div class="field"> 
            <div class = "field">         
              <div class="ui radio checkbox" >
                <input type="checkbox"  name="coc" tabindex="0" checked>
                <label>COC</label>
              </div> 
            </div>
            <div class="field">    
              <div class="ui radio checkbox" >
                <input type="checkbox"  name="soc" tabindex="0" disabled >
                <label>SOC</label>
              </div>              
            </div>      
          </div>
          <br>

          <div class="field">
            <div class="two fields">
              <div class="field">
                <div class="three fields">
                  <div class="field" style="width:60%;align-self:center;">
                    <div class="ui radio checkbox" >
                        <input type="radio" name="rType" id="rType1" value="normal" checked (change)="eventRadio($event,'normal');">
                        <label style="cursor:pointer" (click)="checkedRadio('normal', true);">Normal</label>
                    </div>
                  </div>  
                  <div class="field" style="width:50%;align-self:center;">
                    <div class="ui radio checkbox" >
                        <input type="radio" name="rType" id="rType2" value="reefer" (change)="eventRadio($event,'reefer');">
                        <label style="cursor:pointer" (click)="checkedRadio('reefer', true);">Reefer</label>
                    </div>
                  </div>  
                  <div class="field" style="width:50%;align-self:center;">
                    <div class="ui radio checkbox" >
                        <input #awk type="radio" name="rType" value="awkward" (change)="eventRadio($event,'awkward');" disabled>
                        <label style="cursor:pointer" (click)="checkedRadio('awkward', true);">Awkward</label>
                    </div>
                  </div>  
                </div>
              </div>
            </div>        
          </div> 
        </fieldset> 
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
