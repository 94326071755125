export class Vessel  {
    
    no : number = 0;
    vesselId  : string = '';
    vesselCode  : string = '';
    vesselName  : string = '';
    psaVesselCode : string = '';
    docsysCode : string = '';
    vesselType : string = 'default';
    isSsl         : boolean = false;
    isSlot        : boolean = false;
    is3RdParty        : boolean = false;
    nameOfMaster : string = '';  
    isValid        : boolean = false;
    capacity   :number = 0;
    gtiInternational : number = 0;
    ntiInternational : number = 0;
    gtsuezCanal : number = 0;
    ntsuezCanal : number = 0;
    ntpanamaCanal : number = 0;
    deadWeight : number = 0;
    lightWeight : number = 0;
    loa : number = 0;
    lpp : number = 0;
    breadthMould : number = 0;
    depthMould : number = 0;
    draftDesign : number = 0;
    draftExtreme : number = 0;
    type : string = '';
    clazz : string = '';
    callSign : string = '';
    flag : string = '';
    owner : string = '';
    officialNumber : string = '';
    shipManagement : string = '';
    dateLaunch : string = '';
    portRegistry : string = '';
    dateDelivery : string = '';
    navigationArea : string = '';
    dateKeel : string = '';    
    builder : string = '';
    yearBuild : number = 0;
    max20OnHatch20 : number = 0;
    max20InHold20 : number = 0;
    max20OnHatch40 : number = 0;
    max20InHold40 : number = 0;
    max20OnHatch45 : number = 0;
    max20InHold45 : number = 0;
    max40OnHatch20 : number = 0;
    max40InHold20 : number = 0;
    max40OnHatch40 : number = 0;
    max40InHold40 : number = 0;
    max40OnHatch45 : number = 0;
    max40InHold45 : number = 0;
    reeferOnHatch : number = 0;
    reeferInHatch : number = 0;
    sbaseOnHatch20 : number = 0;
    sbaseInHold20 : number = 0;
    sbaseOnHatch40 : number = 0;
    sbaseInHold40 : number = 0;
    sbaseOnHatch45 : number = 0;
    sbaseInHold45 : number = 0;
    serviceSpeed : number = 0;
    trialMaxSpeed : number = 0;
    foConsumptionRate : number = 0;
    waterBallastTank : number = 0;
    freshWaterTank : number  = 0;
    fuelOilTank : number = 0;
    dieselOilTank : number = 0;
    imoNo: String = '';
    mainEngine : string = '';
    bowThruster : string = '';
    //vesselImagePath : any;
    
    vesselImagePath : any = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA8AAAAMcAQMAAACl5F6MAAAABlBMVEW8vsDn6OnyCdevAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4AezBMQEAAADCoPVP7WsIoAcAAAAAAAAAAAAAAAAAAAAAAIydO0iOk4eCAGyVXNEuukF0FB1NHE1H0RG0ZEHRf5A99WYMk1T+Kvolw+vVjBf+CkMLoRCFWQkuqCquB3QOOQKrCpwBKJ1i6Jxk/EzTubaArgAHQOeyjhu8aMEafUrYolLjLRMfLrpw5cMYadeBnTLcrwN7ZXi+DhyuCi8GG/xy8Gyw3SRe7n7cLzfnajah5z87keFJ//lYf0WAAq+vv+rDX+fir+zpr2Xqr95qrle/ywr9OxX+/nF19fGRmR/yrzCJCudRqNHiNDHhIlquTBgCF2aX3V2BwYT9nUYdNoNojnqjiKJ56q0xiRaok4EsWqQO2EW0RJ3wQbTMvDc6iFaYswEvkx5Hnf8E0Tx1xhcfj3gh1lhOLHVWnx8vLvBq/FAnYpGBhwEElVfjhyETjVjj5bFanATpLrHIcpDkIstpJRdZLmRykaW63CLLMZKLLGeVXGS5jvlFluZKq8k1lnGMXGMZuck1lnsVu8ZydybXWOYj5BrLDIxcY5lz8mrML/JupPIC02osf3tyjeVqY9dYikys8QlTAUIsFovFYrFYLJaIP8hq8D4GG2ywwQYbbLDBBhtssMEGG2ywwQYb3N9+G/ePwQYbbLDBBhtssMEGG5yhA3ugqcARmFXgDKwqcAGgAsu+J1zYy94UXDjIRxosH+crwUm2euHCWX43Fy7YciUYI9N1YIeRavDpsMdIM9jgs+BOh4PBavA3ras6aMFRC06TEpyrLsy/H5emBKOrwvxZpsOsA3ssOk8SQQ9edZ4WI6DzfJwU4Ym/FCE7ofFXfQoJlnUugRsHdl9GTKBzlxRF6vzVW9kngr5eLTB5hT6MUhPgLfdDRhwQCQ79TQXe756tAWcp10XgIuMYF8aWxoedLtz5sB/wzIeDLrzw4QiRqHBSgOvnwLWFCrc7eCLCbnwrXFh2ZMdHKhEOyx3ciHBch8OHE2TtCZ0IZ0wCz1S4jhGTDhc0ecN9IcLja/qEVwIswqwC+/H3zfgMDw7j95cbPBFg+YEKnIaGWyoNzpvmcEujws0rwGX7LnCnwWOgDLhlZsFuaJEP+zFCC7yw4DCAhFtWFhwHl/lwwpeowRMJzlpw+QpXEgwl2O3gxoH9Du4cOOzgmQNHLTjt4OXF4byDVw5csAsHhhLssM/EgD32qQw4aMER+zQtuDPgpAVn7DMz4IJ9FgYMJdgdwSsB9jgKAQ5acDyEJy24ng8nLTgfwu18uBzC/XwYSrA7hmfCeyCHWU6Hw18Gr4R3fY5zOpy04PwEngj/KfYw9WwYSrB7BjfCW4qH6a8Kh2fwTHgF9jDLyXDSgvMzeKW8UX6Uk2Eowe45PL0m7J/D9VQ4aMG/isH/JwYbbLDBBhtssMEGG2ywtz3K/2tvDmQAAAAABvlbn+NbCSQWi8VisVgsFovFYrFYLBYvAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHVJjR5bJLd8AAAAASUVORK5CYII=';
    
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid : string = '';
    remarks : string = '';
    cbValue : string = '';
    

    /*
    no : number = 0;
    vesselId  : string = 'A';
    vesselCode  : string = 'B';
    vesselName  : string = 'C';
    psaVesselCode : string = 'D';
    docsysCode : string = 'E';
    vesselType : string = 'F';
    isSsl         : boolean = true;
    isSlot        : boolean = true;
    is3RdParty        : boolean = true;
    nameOfMaster : string = 'G';  
    isValid        : boolean = true;
    gtiInternational : number = 1;
    ntiInternational : number = 2;
    gtsuezCanal : number = 3;
    ntsuezCanal : number = 4;
    ntpanamaCanal : number = 5;
    deadWeight : number = 6;
    lightWeight : number = 7;
    loa : number = 8;
    lpp : number = 9;
    breadthMould : number = 10;
    depthMould : number = 11;
    draftDesign : number = 12;
    draftExtreme : number = 13;
    type : string = 'H';
    clazz : string = 'I';
    callSign : string = 'J';
    flag : string = 'K';
    owner : string = 'L';
    officialNumber : string = 'M';
    shipManagement : string = 'N';
    dateLaunch : string = '2016/12/20';
    portRegistry : string = 'O';
    dateDelivery : string = '2016/12/21';
    navigationArea : string = 'P';
    dateKeel : string = '2016/12/22';    
    builder : string = 'Q';
    yearBuild : string = '2016';
    max20OnHatch20 : number = 14;
    max20InHold20 : number = 15;
    max20OnHatch40 : number = 16;
    max20InHold40 : number = 17;
    max20OnHatch45 : number = 18;
    max20InHold45 : number = 19;
    max40OnHatch20 : number = 20;
    max40InHold20 : number = 21;
    max40OnHatch40 : number = 22;
    max40InHold40 : number = 23;
    max40OnHatch45 : number = 24;
    max40InHold45 : number = 25;
    reeferOnHatch : number = 26;
    reeferInHatch : number = 27;
    sbaseOnHatch20 : number = 28;
    sbaseInHold20 : number = 29;
    sbaseOnHatch40 : number = 30;
    sbaseInHold40 : number = 31;
    sbaseOnHatch45 : number = 32;
    sbaseInHold45 : number = 33;
    serviceSpeed : number = 34;
    trialMaxSpeed : number = 35;
    foConsumptionRate : number = 36;
    waterBallastTank : number = 37;
    freshWaterTank : number  = 38;
    fuelOilTank : number = 39;
    dieselOilTank : number = 40;
    mainEngine : string = 'J';
    bowThruster : string = 'K';
    vesselImagePath : string = 'assets/images/sinar_sabang.jpg';
    userCreated : string = '';
    dateCreated : string = '';
    userModified : string = '';
    dateModified : string = '';
    userInvalid : string = '';
    dateInvalid : string = '';
    remarks : string = 'XXX';
    */
        
    constructor() { 
      
    }
}