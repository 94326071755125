<form class="ui raised segment">
  <button class="ui button" style="display:none;"></button>
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="param['error-containerNo'] == null || param['error-containerNo'] == '' || param['error-containerNo']==undefined">
        {{param['error-containerNo']}}
      </li>
    </ul>
  </div>

  <form class="ui form">
    <button class="ui button" style="display:none;"></button>
    <fieldset style="border: 1px solid lightgray;">
       <div class="two fields">
         
         <div class="required field" [class.error]="param['error-containerNo'] != null && param['error-containerNo'] != ''" >
           <label>Container No :</label>
           <input style = "width : 50%" type="text" name="contNo"  placeholder="Container" [(ngModel)]="param['containerNo']" >
         </div>
          
       </div>
    </fieldset>
    <div class="field">
      <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
    </div>  
  </form>
</form>

<div id="msgNoRecord"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <table border="0"><tr>
        <td width="15%" style="text-align: center">
          <i class="info circle huge icon"></i>
        </td>
        <td style="font-size: 14px">
          <div [innerHTML]="msg | safe: 'html'"></div>
        </td>
      </tr></table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog()" >Ok</button>
    </div>
  </form>
</div>

<div id="blDeleteDialogYesNo"  style="display:none;font-family:Lucida Console, monospace;">
  <form  class="ui form"> <!-- form start -->
    <div class="field" style="margin-bottom: 5px;">
      <p><i class="question circle big icon" style="float:left;width:1.3em;height:1.0em;text-align: justify;padding-left: .2em;padding-top: .2em"></i>
        {{frmDialogMessage}}</p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlYesDelete()" >Yes</button>
      <button class="negative ui button" (click)="onDialogBlNoDelete()" >No</button>
    </div>
  </form>
</div>



<footer-plugin [info]="info"></footer-plugin>
