import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  Booking,
  BookingDetail,
  AllocationModel,
  MSailingSchedule,
  officeModel,
  specialCharUtil,
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";

import FormValidation from "../../utils/formValidation";
import { type } from "os";
import { finalize } from "rxjs/operators";

declare var $: any;

export class BookingSplit {
  bookingNo: string = "";
  splitQty: string = "";
  lblQty: string = "";
  sumGridQty = 0;
  bNoNew: string = "";
  containerTypeNew: string = "";
  containerSizeNew = 0;
  qtyNew = 0;
  aftersplitQty = 0;
  brootbno = "";

  //bookingmsg:boolean=false;
  //destinationVoyage:string='';
  //destinationBound:string='';

  constructor() {}
}

export class ParamCheckBooking {
  officeCode: string = "";
  bookings: any[] = [];
  defaultBookingNumber: string = "";
}

@Component({
  selector: "app-transaction-booking-split-page",
  templateUrl: "./transaction-booking-split-page.component.html",
  styleUrls: ["./transaction-booking-split-page.component.css"],
})
export class TransactionBookingSplitPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  //@ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  //@ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild("gridHeader") gridHeader: GridPluginComponent;
  @ViewChild("gridSplitDetail") gridSplitDetail: GridPluginComponent;
  @ViewChild("gridSailingDetail") gridSailingDetail: GridPluginComponent;
  @ViewChild("gridSailingDetail2") gridSailingDetail2: GridPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";
  //disableCombo = '';

  loading = false;

  trimPrefix = "";
  typeSize = [
    "NORMAL",
    "REEFER",
    "PLATFORM",
    "FLATRACK",
    "REEFER DRY",
    "OPEN TOP",
    "TANK",
  ];
  combineSlot = ["NORMAL", "REEFER", "REEFER DRY", "TANK"];
  combineSlot2 = ["PLATFORM", "FLATRACK", "OPEN TOP"];

  modeToolbar = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGridHeader;
  settingGridSplitDetail;
  settingGridSailingDetail2;
  splitStore = [];
  cntSz = [];
  oKeys;
  store2;
  splitDetails = null;
  tempSplit = null;
  locationRef;
  podCode;
  cntOwner;
  bookingObjDetail = null;
  bookingObjDetailSplit = null;
  baseContainerType = "";
  baseSlotQty = 0;
  modeCancel = 0;
  modeAppr = 0;

  idDel = [];
  valPrev20 = [];
  valPrev40 = [];
  valPrev45 = [];
  valPrevH20 = [];
  valPrevH40 = [];
  valPrevH45 = [];

  gridCol = [];
  storeHead = 0;
  flagConf = 0;

  sz20;
  sz40;
  sz45;
  szH20;
  szH40;
  szH45;

  sumz20;
  sumz40;
  sumz45;
  sumzH20;
  sumzH40;
  sumzH45;

  /* Parameter for dialog */
  dialog: any;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new MSailingSchedule();
  modelBooking = new BookingSplit();
  office = new officeModel();
  sCharUtil = new specialCharUtil();

  formValidation = new FormValidation();

  bookingSplitDialogMessage = "";

  validatorRules = {};
  isSuccessSave: boolean = false;

  isBookingReferences = false;
  bookingNumberdefault = '';
  regexSuffixBookingNumber = /[a-zA-Z]{2}[0-9]{4}/;
  splitStorelength = 0;

  officeCode = "";
  isChinaSite:boolean = false;
  isAfterSave:boolean = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cook: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        //{ name : 'Export',  event: 'export', icon : 'print' },
        //{ name : 'Split',  event: 'split', icon : 'unlinkify' },
        { name: "Save", event: "save", icon: "save" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Booking Split",
    };

    this.settingGridHeader = {
      id: "gridHeader",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        {
          header: "Booking Reference",
          field: "bNo",
          width: 200,
          type: "string",
        },
        { header: "Container Type", field: "containerType", width: 100 },
        {
          header: "Unit",
          field: "containerSize",
          width: 50,
          editType: "combo",
          maxCharsCombo: 0,
        },
        { header: "Quantity", field: "qty", width: 100, editType: "text" },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: false,
      enableSelectedRows: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridSplitDetail = {
      id: "gridSplitDetail",
      //modalId : 'modalGridSplitDetail',
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 7,
      columns: [
        { header: "No", field: "seqnoNew", width: 1 },
        {
          header: "Booking Reference",
          field: "bNoNew",
          width: 2,
          type: "string",
        },
        { header: "Container Type", field: "containerTypeNew", width: 2 },
        {
          header: "Unit",
          field: "containerSizeNew",
          width: 1,
          maxCharsCombo: 1,
        },
        { header: "Quantity", field: "qtyNew", width: 1 },
        { header: "Actions", field: "actNew", width: 5 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridSailingDetail2 = {
      id: "gridSailingDetail2",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Currency", field: "currency", width: 100 },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          width: 150,
          type: "string",
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    //this.settingVessel = {
    //id          : 'cbVessel',
    //type        : 'search enter', // search | select | select input
    // url         : this.configService.config.BASE_API.toString() + slashMasterVesselsslashfindGeneralslashYslash*slash|query|slash1slash5slash*slash*,
    //maxChars    : 3,
    //template    : 'grid', // default
    //placeholder : 'Search Vessel',
    //title       : 'vesselName',
    //description  : '',
    //columns     : [
    //{header: 'Vessel Code', field: 'vesselCode', width: 200},
    //{header: 'Vessel Name', field: 'vesselName', width: 350},
    //{header: 'Valid', field: 'isValid', width: 50}
    //]
    //}

    /*
        this.settingVoyage = {
          id          : 'cbVoyage',
          type        : 'search enter', // search | select | select input
          url         : '',
          maxChars    : 0,
          template    : 'grid', // default
          placeholder : 'Search Voyage',
          title       : 'voyage',
          description  : '',
          columns     : [
            {header: 'Voyage', field: 'voyage', width: 100},
            {header: 'Bound', field: 'bound', width: 100},
            {header: 'Service', field: 'service', width: 100}
          ]
        }
        */
    //set default value of bound to O
    this.modelBooking.splitQty = "0";
    this.modelBooking.aftersplitQty = 0;
    this.modelBooking.bookingNo = "";
    this.modelBooking.lblQty = "";
    this.modelBooking.sumGridQty = 0;
    //set default voyage to *
    this.model.voyage = "*";
    //this.modelBooking.bookingmsg = false;
    this.setValidatorSplitByBooking();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGridSplitDetail(event) {}
  eventGridSplitDetail(event) {}
  ngOnInit() {
    this.officeCode = this.cook.getDefaultLocationCode();
    this.isChinaSite = false;
    if(this.officeCode.substring(0, 2) ==='CN'){
      this.isChinaSite = true;
      this.setValidatorSplitByBookingChina();
    }
  }

  ngAfterViewInit() {
    $("#gridHeader").find("th.collapsing").find("div.checkbox").remove();
    $(".dataTables_scrollBody").css("height", "150px");
    this.disableToolbarButtons = "save,cancel";
    this.firstInit();
    //this.grid.setModeRadio(true);
    this.gridHeader.search = "*/*/*";
    this.gridHeader.onFirst();
    $("#sptQty").prop("disabled", true);

    $(".quantyclass").keypress(function (e) {
      // console.log('masuk');
      if (e.which === 13) return false;
      //or...
      if (e.which === 13) e.preventDefault();
    });

    var data = [
      { id: 1, qty: 100, qt: 20 },
      { id: 1, qty: 200, qt: 20 },
      { id: 2, qty: 100, qt: 10 },
      { id: 2, qty: 200, qt: 20 },
    ];
    var sum = [];

    data.forEach(function (o) {
      var existing = sum.filter(function (i) {
        return i.id === o.id && i.qt === o.qt;
      })[0];

      if (!existing) sum.push(o);
      else existing.qty += o.qty;
    });

    // console.log(sum);

    //this.cbVoyage.disableCombo = true;
    window.scrollTo(0, 0);
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        // before retrieval, check the BL
        let isPassedValidation: boolean = this.onValidate(this.modelBooking);
        if (isPassedValidation) {
          return;
        }

        var self = this;
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/BL/checkBlByBlNoV2/" +
              this.cook.getDefaultLocationCode() +
              "/" +
              this.modelBooking.bookingNo.trim().replace("SUDU", "SSL")
          )
          .subscribe((resp) => {
            if (
              resp.json()["status"] === "OK" &&
              resp.json()["code"] === "MODE1"
            ) {
              this.isSuccessSave = false;
              this.isAfterSave = false;
              self.bookingSplitDialogMessage =
                "Splitting is not allowed for this particular booking number (" +
                self.modelBooking.bookingNo.trim() +
                " as bill of lading (B/L) has already been generated.";
              this.message(
                "information",
                "Information",
                self.bookingSplitDialogMessage,
                "okonly",
                { ok: "" }
              );
              // self.genericUtil.showDialog('bookingSplitCheckBlDialog', 'Confirm', 380, 180);
            } else if (
              resp.json()["status"] === "OK" &&
              resp.json()["code"] === "MODE4"
            ) {
              this.isSuccessSave = false;
              self.bookingSplitDialogMessage =
                "Next Vessel and Voyage has been confirmed, unable to split booking.";
              this.message(
                "information",
                "Information",
                self.bookingSplitDialogMessage,
                "okonly",
                { ok: "" }
              );

            } else {
              // GOS-839 System should not allow bookings with 'confirmed' Next Vessel/Voyage to be split
              self.checkIfNextVesselConfirmed();
            }
          });

        //// console.log("url grid "+this.grid.url);
        //this.fillGrid();
        // this.loadAllocation(this.model);
        //this.gridSailingDetail.loadData();
        break;
      case "save":
        this.handleSave();
        break;
      case "cancel":
        this.cancelEvent();
        break;
      case "close":
        this.handleClose();
        break;
    }
  }

  checkIfNextVesselConfirmed(){
    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/MasterBookings/checkIfNextVesselConfirmed/" +
        this.office.officeCode +
        "/" +
        String(this.modelBooking.bookingNo).toUpperCase().trim()
    ).subscribe(data=>{
      if(data.json().status === "ok"){
        this.retrieveEvent();
      }else{
        this.message(
          "error",
          "Information",
          data.json().message,
          "okonly",
          { ok: "" }
        );
      }
    })
  }


  gridEvent(event) {
    // console.log('grid event')
    // console.log(event);

    var cntSplt = [];
    cntSplt = event.split(".")[0];
    // console.log(cntSplt)
    // console.log('length event'+cntSplt.length);
    if (this.modeCancel > 0) {
      switch (event.split(".")[0]) {
        case "selected":
          var mode = event.split(".")[1].split("-")[0];
          var no = event.split(".")[1].split("-")[1];

          switch (mode) {
            case "checked":
              // console.log('masuk sini selected');
              if (this.modeAppr == 0)
                this.selectedListMod(no, this.gridHeader, "no");

              //this.message(this.gridHeader.getSelectedValues().bNo)
              break;
            case "unchecked":
              this.storeHead = 0;
              this.baseContainerType = "";
              break;
          }

          //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
          break;

        case "click":
          var mode = event.split(".")[1].split("-")[0];
          var no = event.split(".")[1].split("-")[1];

          switch (mode) {
            case "checked":
              // this.selectedListMod(no, this.gridHeader,'no');

              //this.message(this.gridHeader.getSelectedValues().bNo)
              break;
            case "unchecked":
              this.storeHead = 0;
              this.baseContainerType = "";
              break;
          }
          //this.selectedListMod(event);
          // this.gridSailingDetail.onClear();
          //let dt = this.gridHeader.getSelectedValues()[0];
          //this.getSailingDetail(dt.vesselId.trim(),dt.voyage,dt.bound,dt.serviceCode);
          //this.gridHeader.clearSelectedValues();
          //// console.log("Vessel Code nya :"+dt.vesselCode);

          break;

        default:
          let strEvent: string = event;
          if (strEvent != "") {
            let arr: string[] = strEvent.split("-");
            // console.log("before split -> " + strEvent);
            // console.log("arr -> " + arr);
            // console.log("arr-split -> " + arr[0]);
            // console.log("arr-split -> " + arr[1]);
            switch (arr[0]) {
              case "edit":
                break;
              case "afterLoad":
                // console.log('cntowner'  +this.cntOwner)

                if (
                  this.gridHeader.enableSelectAll == true &&
                  this.storeHead > 0
                ) {
                  this.gridHeader.onSelect(true, this.storeHead);
                }

                break;
              default:
                break;
            }
          }

          break;
      }
    }
  }

  gridEventDetails(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;

            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetails2(event) {}

  infoGrid(event) {
    this.info = event;
  }

  infoGridDetails(event) {
    this.info = event;
  }
  infoGridDetails2(event) {
    this.info = event;
  }

  changeEventVessel(event) {
    // console.log("vessel event");
    // console.log(event);
    //this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageByVesselBound/"+event.vesselId+"/"+this.model.bound);
    //this.cbVoyage.setValue("");

    this.model.vesselId = event.vesselId;
    this.model.vesselCode = event.vesselCode;
    this.model.vesselName = event.vesselName;
  }

  changeEventVoyage(event) {
    // console.log("voyage event");
    // console.log(event);

    this.model.voyage = event.voyage;
    //this.model.bound = event.bound;
    this.model.serviceCode = event.service;
    this.model.localETA = event.localEta;
    this.model.localETD = event.localEtd;
  }

  doUpdateHeaderTable() {
    // $('#gridHeader-table').each(function(i) {

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingByNo/" +
          this.office.officeCode +
          "/" +
          this.modelBooking.bookingNo.toUpperCase()
      )
      .subscribe((resp) => {
        if (resp.ok) {
          var store3 = [];
          var rowGrid = $("#gridHeader-table tr").length - 1;
          var rowSplitore = $("#tblSplit tr").length - 1;

          var objsb = {};
          var qtyNew = [];
          var lsz = 0;
          var csz = 0;
          var attrGrid = [];

          // console.log('rowsplit length '+rowSplitore+'rowGrid'+rowGrid);

          if (Object.keys(resp.json()["content"]).length > 0) {
            // console.log('After Clear');
            store3 = resp.json()["content"];
            // console.log(resp.json()['content']);
            store3.forEach((sb) => {
              //this.gridHeader.listStore.addData(sb);
              // console.log(sb);
            });
            //// console.log(store3[1].qty);
            for (var t = 0; t < rowGrid; t++) {
              // console.log('Bagian yang eror  ' +resp.json()['content'][t].bNo);
              attrGrid.push({
                bNo: resp.json()["content"][t].bNo,
                containerSize: resp.json()["content"][t].containerSize,
                containerType: resp.json()["content"][t].containerType,
                qty: resp.json()["content"][t].qty,
              });
            }
            //JIRA 72
            //if(this.cntOwner =='SLOT'){

            //for(var a = 1;a<=rowSplitore;a++){
            //csz = csz + parseInt($('#quanty_'+a).val());//1

            //} JIRA 72 END

            // console.log('jumlah quantity'+  + this.baseSlotQty)
            if (lsz > 0) {
              this.gridHeader.listStore.store.forEach((uu) => {
                if (uu.select == "checked") {
                  uu.qty = this.baseSlotQty - csz;
                  // console.log('pengurangan')
                  // console.log(this.baseSlotQty);
                  // console.log(csz)
                  // console.log(this.baseSlotQty - csz);
                }
              });
              //}
            } else {
              for (var g = 1; g <= rowSplitore; g++) {
                lsz = $("#quanty_" + g).val(); //1
                // handel Quantity following Container Type
                // Ticket GOS-416
                let spanContainerType = $("#typeSize_" + g).val();
                var tableStore = [];
                for (var i = 0; i < rowGrid; i++) {
                  // console.log('loop yang ke'+lsz);
                  var spanValQty = attrGrid[i]["qty"]; // console.log('spanvalQty :'+spanValQty)//resp.json()['content'][i].qty;
                  var spanValSz = attrGrid[i]["containerSize"]; // console.log('spanvalsz :'+spanValSz)//resp.json()['content'][i].containerSize;
                  var spanCtnType = attrGrid[i]["containerType"]; // console.log('spanvalsz :'+spanValSz)//resp.json()['content'][i].containerSize;
                  qtyNew[i] = resp.json()["content"][i].qty; // console.log('qty ke'+i+qtyNew[i])
                  // console.log('nilai i : ' +i+' nilai g : '+g )
                  if (lsz > 0) {
                    if (
                      (this.officeCode != 'SGSIN' &&
                        spanCtnType === spanContainerType &&
                        spanValSz == $("#cnSize_" + g).val()
                        ||
                        this.officeCode === 'SGSIN' &&
                        spanValSz == $("#cnSize_" + g).val()
                      )
                    ) {
                      attrGrid[i]["qty"] =
                        parseInt(attrGrid[i]["qty"]) - Math.abs(lsz);
                      lsz = Math.abs(lsz) - parseInt(spanValQty);

                      // console.log('flagGrid '+attrGrid[i]['qty']+':'+lsz);

                      if (attrGrid[i]["qty"] < 0) {
                        attrGrid[i]["qty"] = 0;
                        $("#quanty_" + g).val(0);
                        this.message(
                          "information",
                          "Information",
                          "Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows.",
                          "okonly",
                          { ok: "" }
                        );
                        return;
                        // console.log('masuk ke kondisi 0');
                        // self.gridHeader.listStore.updateData(x,{})
                      } else {
                        attrGrid[i]["qty"] = Math.abs(lsz);
                      }
                    }
                  }

                  tableStore.push({
                    bNo: resp.json()["content"][i].bNo,
                    containerType: resp.json()["content"][i].containerType,
                    containerSize: resp.json()["content"][i].containerSize,
                    qty: Math.abs(attrGrid[i]["qty"]),
                  });

                  // console.log('nilai tablestore awal')
                  tableStore.forEach((each) => {
                    // console.log(each);
                  });
                }
              }

              // console.log('After Calculate');
              this.gridHeader.onClear();
              if (tableStore != undefined) {
                tableStore.forEach((sz) => {
                  this.gridHeader.listStore.addData(sz);
                  // console.log(sz);
                });
              }
              this.gridHeader.loadData();
            } //end else
          }
        }
      });
  }

  absent(arr) {
    var mia = [],
      min = 0,
      max = Math.max.apply("", arr);
    while (min < max) {
      if (arr.indexOf(++min) == -1) mia.push(min);
    }
    return mia;
  }

  convertToNumberingScheme(number) {
    var baseChar = "A".charCodeAt(0),
      letters = "";

    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0;
    } while (number > 0);

    return letters.toUpperCase();
  }

  doSplit() {
    this.checkSplitTypeAndShangHai();
    // console.log('Start Split');
    // console.log(this.baseContainerType)
    //this.genericUtil.showLoader();
    //this.gridSplitDetail.onClear();

    // $('#tblSplit').empty();
    this.isAfterSave = false;
    this.splitStore = [];
    // console.log('storehead'+this.storeHead +  this.cntOwner)

    /*
    if(this.cntOwner == 'SLOT'){
      this.gridHeader.listStore.store.forEach(rr=>{
        if(rr.select == 'checked' && rr.containerSize == '20'){

        }
      })
    }
    */
    //if(this.cntOwner != 'SLOT')
    //this.typeSize = ["NORMAL","REEFER","PLATFORM","REEFER DRY","OPEN TOP","TANK"];
    //else
    //this.typeSize = ["NORMAL","REEFER","TANK"];

    if ($("#tblSplit tr").length > 0)
      this.getBookingNo(this.modelBooking.bookingNo, "SUDU");

    // console.log(this.modelBooking.aftersplitQty);

    $("#gridHeader-table").each(function (i) {
      var rowGrid = $("#gridHeader-table tr").length - 1;
      for (var k = 1; k <= rowGrid; k++) {
        // console.log('Row '+(k)+$(this).find("span[id='gridHeader.span."+k+".containerType']").text()+' '+$(this).find("span[id='gridHeader.span."+k+".containerSize']").text());
      }
    });

    this.setValidatorSplitByBooking();
    if(this.isChinaSite === true){
      this.setValidatorSplitByBookingChina();
    }
    let isPassedValidationT: boolean = this.onValidate(this.modelBooking);
    if (!isPassedValidationT) {
      if (
        parseInt(this.modelBooking.splitQty) == 0 ||
        parseInt(this.modelBooking.splitQty) < 0
      ) {
        this.message(
          "information",
          "Information",
          "Please enter the number of booking(s) to be split into.",
          "okonly",
          { ok: "" }
        );
      }

      /* JIRA 72 */
      //else if(this.cntOwner == 'SLOT' && this.storeHead == 0){
      //this.message('information','Information','Please select the container type for new booking(s) to deduct quantity from.','okonly',{ok:''});
      //}

      /* JIRA 72
      else if(this.cntOwner == 'SLOT' && (parseInt(this.modelBooking.splitQty) > 0 && parseInt(this.modelBooking.splitQty) >= this.baseSlotQty)){

          this.message('information','Information','Number of bookings split should not exceed or equal to the total unit of selected containers booked','okonly',{ok:''});
          this.modelBooking.splitQty ='0';
          //this.gridHeader.enable()


      }
      */
      else if (
        parseInt(this.modelBooking.splitQty) > 0 &&
        parseInt(this.modelBooking.splitQty) >= this.modelBooking.sumGridQty
      ) {
        // console.log('masuk else 1')
        this.message(
          "information",
          "Information",
          "Number of bookings split should not exceed or equal to the total unit of containers booked",
          "okonly",
          { ok: "" }
        );
        this.modelBooking.splitQty = "0";
      } else {
        this.gridHeader.disable();
        // console.log('masuk else')
        this.disableToolbarButtons = "retrieve";
        this.modelBooking.aftersplitQty =
          parseFloat(this.modelBooking.splitQty) + 1;
        // console.log('SplitValue '+$('#splitType').val())

        if ($("#splitType[value=0]").is(":checked")) {
          this.generateBookingNumberByPrefix(function (callback) {
            if (callback == "0") {
            }
          });

          //  this.gridSplitDetail.loadData();
        } else {
          for (var y = 0; y < parseInt(this.modelBooking.splitQty); y++) {
            // console.log('Yang ke'+y);

            /*  this.generateBookingNumber(y,function (callback) {
                //b// console.log('callback : ' + callback);
                if(callback == '0'){

                }
              });

            */

            this.generateBookingNumberTemp(y);
          }
        }
      }
    }

    //this.afterSave();

    this.genericUtil.hideLoader();
  }
  //This is not a SLOT booking.
  //Splitting accross container type is not allowed
  generateBookingNumberTemp(numb: number) {
    var output = [],
      n,
      padded;
    /*JIRA 72 */
    //if(this.cntOwner == 'SLOT')
    //this.baseContainerType = this.gridHeader.getSelectedValues()[0].containerType
    console.log("NUMB: "+numb);
    // for (n=0; n<=numb; n++) {
    padded = ("000" + numb).slice(-4);
    this.splitDetails = new BookingSplit();
    this.splitDetails.seqnoNew = numb + 1;

    if(this.isChinaSite === true){
      this.splitDetails.bNoNew = "";
    } else{
      this.splitDetails.bNoNew = "SUDUXXXXXXXX" + padded;
    }

    //this.splitDetails.containerTypeNew = this.typeSize[0];
    this.splitDetails.containerTypeNew = this.baseContainerType;
    this.splitDetails.containerSizeNew = this.cntSz[0];
    this.splitDetails.qtyNew = 0;
    this.splitDetails.bSeqPrefix = 0;
    this.splitDetails.brootbno = "";
    this.splitStore.push(this.splitDetails);
    // }
  }

  generateBookingNumberAfterSave() {
    console.log("KEMARI AFTER SAVE 2")
    var output = [],
      n,
      padded;
    /*JIRA 72 */
    //if(this.cntOwner == 'SLOT')
    //this.baseContainerType = this.gridHeader.getSelectedValues()[0].containerType

    // for (n=0; n<=numb; n++) {

    // }


    //neti coba didieu
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkBookingNoAfterSplit/" +
          this.office.officeCode +
          "/" +
          this.modelBooking.bookingNo.toUpperCase() +
          "/" +
          this.splitStorelength
          // this.modelBooking.splitQty
      )
      .subscribe((resp) => {
        if (resp.ok) {
          this.isAfterSave = true;

          if (Object.keys(resp.json()["content"]).length > 0) {
            for(var i = 0;
              i < Object.keys(resp.json()["content"]).length;
              i++){
                this.splitDetails = new BookingSplit();
                this.splitDetails.seqnoNew = i+1;
                const bNoNewReference = resp.json()["content"][i].bNo.substr(10);
                this.splitDetails.bNoNew = this.isBookingReferences === true && this.isChinaSite === true ? bNoNewReference  : resp.json()["content"][i].bNo;
                //this.splitDetails.containerTypeNew = this.typeSize[0];
                this.splitDetails.containerTypeNew = resp.json()["content"][i].bSplitContType;
                this.splitDetails.containerSizeNew = resp.json()["content"][i].bSplitContSize;
                this.splitDetails.qtyNew = resp.json()["content"][i].bSplitQty;;
                this.splitDetails.bSeqPrefix = 0;
                this.splitDetails.brootbno = "";
                this.splitStore.push(this.splitDetails);


            }



          }


        }
      });

  }


  generateBookingNumber(iteration: number, callback?: (data) => void) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingNo/" +
          this.locationRef +
          "/" +
          this.podCode +
          "/" +
          this.cntOwner
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (Object.keys(resp.json()["bNo"]).length > 0) {
            this.splitDetails = new BookingSplit();
            this.splitDetails.seqnoNew = iteration + 1;
            this.splitDetails.bNoNew =
              this.modelBooking.bookingNo.substring(0, 4) + resp.json()["bNo"];
            this.splitDetails.containerTypeNew = this.baseContainerType;
            //this.splitDetails.containerTypeNew = this.typeSize[0];
            this.splitDetails.containerSizeNew = this.cntSz[0];
            this.splitDetails.qtyNew = 0;
            this.splitDetails.bSeqPrefix = 0;
            this.splitDetails.brootbno = "";
            this.splitStore.push(this.splitDetails);
          }

          if (callback) callback("");
        }
      });
  }

  generateBookingNumberByPrefix(callback?: (data) => void) {
    var maxSeq = 0;
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingNoByPrefix/" +
          this.office.officeCode +
          "/" +
          this.modelBooking.bookingNo.toUpperCase()
      )
      .subscribe((kesp) => {
        if (kesp.ok) {
          var kesult = [];
          var totSeq = 0;

          // console.log('length cntsz'+Object.keys(kesp.json()['content']).length );
          // console.log(this.cntSz)

          /*JIRA 72*/
          //if(this.cntOwner == 'SLOT')
          // this.baseContainerType = this.gridHeader.getSelectedValues()[0].containerType

          // console.log(this.baseContainerType)

          if (Object.keys(kesp.json()["content"]).length > 0) {
            // console.log('wajkajja');
            //// console.log(kesp.json()['content'][0].b_sequence_prefix);
            // // console.log(kesp.json()['content'][1].b_sequence_prefix);

            for (
              var i = 0;
              i < Object.keys(kesp.json()["content"]).length;
              i++
            ) {
              //// console.log('kenapa' + kesp.json()['content'][i].b_sequence_prefix);
              kesult.push(kesp.json()["content"][i].b_sequence_prefix);
            }

            // console.log(kesult);
            kesult = kesult.sort();

            var maxKesult = Math.max.apply(Math, kesult);
            // console.log('maxkesult'+maxKesult)
            var cKesult = this.absent(kesult);
            var lnKesult = cKesult.length;
            // console.log('len ckesult'+lnKesult);
            // console.log(cKesult);

            // console.log('after geneerarte prefix'+this.baseContainerType);

            if (lnKesult > 0) {
              for (var j = 0; j < parseInt(this.modelBooking.splitQty); j++) {
                // console.log('masuk yang pertama'+j);
                var addSeq = cKesult[j];

                if (j + 1 > lnKesult) {
                  addSeq = j + 1 - lnKesult + maxKesult;
                } //end if

                this.splitDetails = new BookingSplit();
                this.splitDetails.seqnoNew = j + 1;
                //  this.splitDetails.bNoNew = this.modelBooking.bookingNo+this.convertToNumberingScheme(addSeq);//String.fromCharCode(j+65);
                this.splitDetails.bNoNew =
                  kesp.json()["content"][0].beno +
                  this.convertToNumberingScheme(addSeq); //String.fromCharCode(j+65);
                //this.splitDetails.containerTypeNew = this.typeSize[0];
                this.splitDetails.containerTypeNew = this.baseContainerType;
                this.splitDetails.containerSizeNew = this.cntSz[0];
                this.splitDetails.qtyNew = 0;
                this.splitDetails.bSeqPrefix = addSeq;
                this.splitDetails.brootbno = kesp.json()["content"][0].beno;
                this.splitStore.push(this.splitDetails);
              } //end for
            } //end if lnkesult
            else {
              totSeq = maxKesult + 1;

              for (var j = 0; j < parseInt(this.modelBooking.splitQty); j++) {
                this.splitDetails = new BookingSplit();
                this.splitDetails.seqnoNew = j + 1;
                //this.splitDetails.bNoNew = this.modelBooking.bookingNo+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
                this.splitDetails.bNoNew =
                  kesp.json()["content"][0].beno +
                  this.convertToNumberingScheme(totSeq); //String.fromCharCode(j+65);
                //this.splitDetails.containerTypeNew = this.typeSize[0];
                this.splitDetails.containerTypeNew = this.baseContainerType;
                this.splitDetails.containerSizeNew = this.cntSz[0];
                this.splitDetails.qtyNew = 0;
                this.splitDetails.bSeqPrefix = totSeq;
                this.splitDetails.brootbno = kesp.json()["content"][0].beno;
                this.splitStore.push(this.splitDetails);
                totSeq++;
              } //end for
            } //end else
          } else {
            totSeq = 1;
            for (var j = 0; j < parseInt(this.modelBooking.splitQty); j++) {
              // console.log(this.modelBooking.bookingNo+String.fromCharCode(j+65));

              // console.log('masuk else sini')
              this.splitDetails = new BookingSplit();
              this.splitDetails.seqnoNew = j + 1;
              this.splitDetails.bNoNew =
                this.modelBooking.bookingNo.toUpperCase() +
                this.convertToNumberingScheme(totSeq); //String.fromCharCode(j+65);
              //this.splitDetails.bNoNew = kesp.json()['content'][0].beno+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
              // this.splitDetails.containerTypeNew = this.typeSize[0];
              this.splitDetails.containerTypeNew = this.baseContainerType;
              this.splitDetails.containerSizeNew = this.cntSz[0];
              this.splitDetails.qtyNew = 0;
              this.splitDetails.bSeqPrefix = totSeq;
              this.splitDetails.brootbno = this.modelBooking.bookingNo;
              this.splitStore.push(this.splitDetails);
              totSeq++;
            }
          }
        }

        if (callback) callback("");
      });
  }

  /*
  onRetrieve(){
   // this.setValidatorRetrieve();
    //// console.log(this.model);
    this.retrieveEvent();
  }

  retrieve(event){
    // console.log(event);
    if(event.keyCode == 13){
      this.onRetrieve();
      //event.preventDefault();
    }
  }
  */

  retrieveEvent() {
    var result = true;
    this.gridHeader.clearSelectedValues();
    this.modeCancel = 1;

    var warnError =
      "The booking number you have supplied (44) doesn`t seems to be a valid booking number.<br/>";
    warnError =
      warnError +
      "A valid booking number (min. 16 characters, max. 17 characters in total) <br/>will consist od the following fields: <br/>----------------------------------------------------------------------------- ";
    warnError = warnError + "<br/>1) Prefix: 4 characters";
    warnError = warnError + "<br/>2) Port of Loading: 2 characters";
    warnError = warnError + "<br/>3) Port of Discharge: 3 characters";
    warnError = warnError + "<br/>4) Booking Type: 1 characters";
    warnError = warnError + "<br/>5) A-Z alphabet: 2 characters";
    warnError = warnError + "<br/>6) Running Number: 4 characters";
    warnError = warnError + "<br/>7) Suffix (Optional): 1 characters";

    // console.log("retrieve Event");
    this.storeHead = 0;

    //var hitUrl = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBoundHeader"; //VS00287/TESTE1/I/CCU;
    //this.gridHeader.search = this.model.vesselId + "/" + this.model.voyage + "/" + this.model.bound;

    //this.gridHeader.url = hitUrl;
    this.modelBooking.bookingNo = this.modelBooking.bookingNo.trim();
    let isPassedValidation: boolean = this.onValidate(this.modelBooking);
    if (!isPassedValidation) {
      this.trimPrefix = this.modelBooking.bookingNo;
      this.trimPrefix = this.trimPrefix.substring(0, 4);
      // console.log(this.trimPrefix);

      if(this.isChinaSite === false){
        if (
          this.modelBooking.bookingNo.length < 16 &&
          this.modelBooking.bookingNo.length > 0
        ) {
          // console.log('Cek Length');
          this.message("information", "Information", warnError, "okonly", {
            ok: "",
          });
          result = false;
        }
      }
      this.disableToolbarButtons = "save,retrieve";
      this.splitStore = [];
      this.modelBooking.splitQty = "0";
      this.modelBooking.aftersplitQty = 0;
      $("input[name=splitType][value=0]").prop("checked", true);
      this.getBookingNo(this.modelBooking.bookingNo, "RET");
    }
  }

  cancelEvent() {
    // console.log('Cancel Event');
    this.modelBooking.splitQty = "0";
    this.modeAppr = 0;
    this.disableToolbarButtons = "save,cancel";
    this.modeCancel = 0;
    this.splitStore = [];
    this.modelBooking.bookingNo = "";
    this.modelBooking.splitQty = "0";
    this.modelBooking.lblQty = "";
    this.modelBooking.aftersplitQty = 0;
    this.gridHeader.enable();
    this.gridHeader.listStore.store = [];
    this.gridHeader.clearSelectedValues();
    this.gridHeader.onClear();
    this.baseContainerType = "";
    this.storeHead = 0;
    $("#sptQty").prop("disabled", true);
    $("input[name=splitType][value=0]").prop("checked", true);
    this.isSuccessSave = false;
    this.isAfterSave = false;

    window.scrollTo(0, 0);
  }

  saveEvent() {
    console.log("#Save Event")
    // console.log('Save' + $('#tblSplit tr').length);
    this.genericUtil.showLoader();
    var rowGridx = $("#gridHeader-table tr").length - 1;
    var bookingObj = new Booking();
    var bookingObjSpltemp = [];
    var bookingObjSpl = [];
    var Tprefix = "";
    var typeSplit = "";
    var totSplit = 0;
    /*
    if(this.flagConf > 0){
      this.idDel.forEach(uy=>{
        this.rowEvent('del',uy.seqdel);
      })
    }
    */
    if ($("#splitType[value=0]").is(":checked")) typeSplit = "PREFIX";
    else typeSplit = "BOOKING";

    this.gridHeader.listStore.store.forEach((xt) => {
      //// console.log(xt);
      /*
      this.bookingObjDetail = new BookingDetail;


      this.bookingObjDetail.bOfficeCode = this.office.officeCode;
      this.bookingObjDetail.bPrefix = xt.bNo.substring(0,4);
      this.bookingObjDetail.bContainerSize = xt.containerSize;
      this.bookingObjDetail.bContainerType = xt.containerType;
      this.bookingObjDetail.bQuantity = xt.qty;
      this.bookingObjDetail.bNo = xt.bNo.substring(4,17);

      bookingObj.bookingDetails.push(this.bookingObjDetail);
      */
      Tprefix = xt.bNo.substring(0, 4);
      bookingObjSpl.push({
        bType: "Master",
        bOfficeCode: this.office.officeCode,
        bPrefix: xt.bNo.substring(0, 4),
        bContainerSize: xt.containerSize,
        bContainerType: xt.containerType,
        bQuantity: Number(xt.qty),
        bModifiedUserId: this.cook.getName(),
        bNo: xt.bNo.substring(4, 17),
      });
    });
    var splitstoretemp = [];

    console.log(this.splitStore);

    this.splitStore.forEach((pt) => {
      if (pt.qtyNew > 0) {
        bookingObjSpltemp.push({
          bType: "Child",
          bOfficeCode: this.office.officeCode,
          bPrefix: Tprefix,
          bContainerSize: pt.containerSizeNew,
          bContainerType: pt.containerTypeNew,
          bQuantity: Number(pt.qtyNew),
          bNo: pt.bNoNew.toLocaleUpperCase(),
          bRootBno: pt.brootbno,
          bSplitMethod: typeSplit,
          bCreatedUserid: this.cook.getName(),
          bSequencePrefix: pt.bSeqPrefix,
        });

        splitstoretemp.push(pt);
      }
    });
    //reset and refill store split store (remove 0 quantity)
    this.splitStorelength = this.splitStore.length;
    this.splitStore = [];
    splitstoretemp.forEach((tr) => {
      this.splitStore.push(tr);
    });
    //end

    //grouping Array
    var sumz = [];

    bookingObjSpltemp.forEach(function (o) {
      if (o.bQuantity > 0) {
        var existing = sumz.filter(function (i) {
          return (
            i.bNo === o.bNo &&
            i.bContainerSize === o.bContainerSize &&
            i.bContainerType === o.bContainerType
          );
        })[0];

        if (!existing) {
          sumz.push(o);
        } else
          existing.bQuantity =
            parseInt(existing.bQuantity) + parseInt(o.bQuantity);
      }
    });

    //console.log("sz --->");
    sumz.forEach((sz) => {
      //console.log(sz);
      //totSplit = totSplit + 1
      bookingObjSpl.push({
        bType: "Child",
        bOfficeCode: sz.bOfficeCode,
        bPrefix: sz.bPrefix,
        bContainerSize: sz.bContainerSize,
        bContainerType: sz.bContainerType,
        bQuantity: Number(sz.bQuantity),
        bNo: this.isBookingReferences === true && this.isChinaSite === true ? this.bookingNumberdefault.substring(4,17).toLocaleUpperCase() + sz.bNo.toLocaleUpperCase() : sz.bNo.substring(4, 17).toLocaleUpperCase(),
        bSplitMethod: sz.bSplitMethod,
        bCreatedUserid: sz.bCreatedUserid,
        bSequencePrefix: sz.bSequencePrefix,
        bRootBno: this.isBookingReferences === true && this.isChinaSite === true ? sz.bRootBno :sz.bRootBno.substring(4, 17),
        locRef: this.locationRef,
        bPodCode: this.podCode,
        bCntOwner: this.cntOwner,
      });
    });
    // console.log('sumz nih child')
    // console.log(sumz);

    //end grouping

    var uniqueNames = [];
    for (var i = 0; i < bookingObjSpl.length; i++) {
      if (
        uniqueNames.indexOf(bookingObjSpl[i].bNo) === -1 &&
        bookingObjSpl[i].bType == "Child"
      ) {
        totSplit = totSplit + 1;
        uniqueNames.push(bookingObjSpl[i].bNo);
      }
      // GOS-532 Option "Split by new BL number" does not maintence seq prefix & bkg root number
      /*
      if(typeSplit === "BOOKING" && bookingObjSpl[i].bType === "Child"){
        bookingObjSpl[i].bSequencePrefix = i;
        bookingObjSpl[i].brootbno = this.modelBooking['bookingNo'].substring(4, 17);
      }
      */
    }
    //console.log(bookingObjSpl)
    // console.log('uniquesNames')
    // console.log(uniqueNames);
    
    this.loading = true;
    var datas = "";
    //totSplit = totSplit + 1;//including master
    
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/saveSplit",
        bookingObjSpl
      )
      .pipe(finalize(() => {
        this.loading = false;
        this.genericUtil.hideLoader()
      }))
      .subscribe(
        (resp) => {
          if(resp.json().length > 0 && resp.json()[0].status === 'nok'){
            let messageError = resp.json()[0].invalidMsg[0] ? resp.json()[0].invalidMsg[0] : "An error has occurred."
            this.message(
              "error",
              "Error",
              messageError,
              "okonly",
              { ok: "" }
            );

            if(this.isBookingReferences){
              this.splitStore = this.splitStore.map(item=>{
                return {
                  ...item,
                  bNoNew: ''
                }
              })
            }

            this.genericUtil.hideLoader();
            return;
          }

          datas = resp.json()["result"];

          //console.log(resp.json());

          for (var x = 0; x < Object.keys(resp.json()).length; x++) {
            this.splitStore.forEach((ea) => {
             // console.log('nih ya');
             // console.log(resp.json()[x].oldBno);
             // console.log(ea.bNo);
              if (ea.bNoNew == resp.json()[x].bPrefix + resp.json()[x].oldBno){
                //console.log("this.isBookingReferences after save :  " + this.isBookingReferences);
                if(this.isBookingReferences){
                  const bNoNew = resp.json()[x].bPrefix + resp.json()[x].bNoNew;
                  //console.log("bNoNew : " + bNoNew)
                  //console.log("this.isChinaSite : " + this.isChinaSite)
                  if(this.isChinaSite === true){
                    ea.bNoNew = bNoNew;//.substr(10);
                  }else{
                    ea.bNoNew = bNoNew;
                  }
                } else{
                  ea.bNoNew = resp.json()[x].bPrefix + resp.json()[x].bNoNew;
                }

              }
            });
          }

          //// console.log(data.bNo);
          //if(datas == "success"){
          totSplit = totSplit + 1; //include master
          // console.log('totall splittt'+totSplit)

          this.message(
            "information",
            "Information",
            "Changes saved sucessfully. <br/> Total no. of Bookings (Including Main Booking) : " +
              totSplit,
            "okonly",
            { ok: "// console.log('ok click')" }
          );

          if(typeSplit != "PREFIX"){
            this.afterSave();
          }
          this.isSuccessSave = true;
          this.loading = false;
          this.genericUtil.hideLoader();
          this.modeAppr = 0;
          this.modeCancel = 0;
          //this.gridHeader.clearSelectedValues()
          this.disableToolbarButtons = "save";
          this.gridHeader.enable();
          // this.cancelEvent();

          //}
        },
        (error) => {
          if(this.isBookingReferences){
            this.splitStore = this.splitStore.map(item=>{
              return {
                ...item,
                bNoNew: ""
              }
            })
          }
          this.loading = false;
          this.genericUtil.hideLoader();
          this.message(
            "information",
            "Information",
            "Some error(s) had occured while saving the record." +
              datas +
              " <br/> Please contact your administrator.",
            "okonly",
            { ok: "" }
          );
        }
      );
    
    /*
    if($('#tblSplit tr').length - 1 == 0){
      this.message('information','Information','There is no record for saving.','okonly',{ok: 'this.loading=false;'});
    }
    */
  }

  fillGrid() {
    this.gridHeader.loadData();
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    // console.log(value);

    /*
    this.gridHeader.getSelectedValues().forEach(gs=>{
      if(value.split("-")[1] !=  gs.seqno){
        gs['select'] = '';
        this.gridHeader.getSelectedValues().splice(this.gridHeader.getSelectedValues().indexOf(gs),1);
      }
    });
    if(value.split(".")[1].split("-")[0] == "checked"){
      // console.log("size "+this.gridHeader.getSelectedValues().length);
     // // console.log("name "+this.gridHeader.getSelectedValues()[0].portofLoadingName);
    }
    */
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach((ff) => {
        if (ff[key] != value) {
          ff["select"] = "";
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
    // console.log(this.cntSz)
    this.cntSz = [];
    // console.log('after reset')
    // console.log(this.cntSz)

    //    this.gridHeader.listStore.store.forEach(re=>{
    // console.log('list mod');
    //// console.log(re)
    //   if(re.select == "checked"){
    //this.baseContainerType = this.gridHeader.getSelectedValues()[0].qty;//re.containerType

    //remark JIRA --72 --//
    // this.storeHead = this.gridHeader.getSelectedValues()[0].no;//re.no
    //this.baseSlotQty = this.gridHeader.getSelectedValues()[0].qty;//re.qty
    //this.cntSz[0]=this.gridHeader.getSelectedValues()[0].containerSize;//re.containerSize

    //END REMARK JIRA
    //   }

    //  })
    // console.log(this.storeHead);
    // console.log(this.baseSlotQty);
    // console.log(this.cntSz[0]);
    this.message(
      "information",
      "Information",
      this.gridHeader.getSelectedValues()[0].containerType +
        " selected for quantity deduction. Proceed? ",
      "okcancel",
      {
        ok: "this.gridHeader.disable();this.modeAppr = 1",
        cancel: "this.modeAppr=0",
      }
    );
    // console.log(this.cntSz[0])
  }
  /*
   loadAllocation(data){
   this.cButtonLock = false;
   var store = [], storeDetails = [];
   var hitUrl = BASE_API_ALLOT + "/VesselAllocation/findByVesselVoy/" + data.vesselCode + "/" + data.voyage + "/" + data.bound;
   var details = null;
   this.genericService.GET(hitUrl).subscribe((resp) =>{
   if(resp.ok){
   store = resp.json()['content'];
   store.forEach(ss=>{
   // console.log("size details : "+ss['vesselAllocationDetails'].length);
   if(ss['vesselAllocationDetails'].length > 0){
   storeDetails = ss['vesselAllocationDetails'];
   storeDetails.forEach(sd=>{
   details = new AllocationModel;
   details.vesselCode = ss['vesselCode'];
   details.voyage = ss['voyage'];
   details.bound = ss['bound'];
   details.allocationId = ss['recordId'];
   details.seqNo = sd['recordSeq'];
   details.level = sd['allotmentLevel'];
   details.allotedBy = sd['allotmentCode'];
   details.allocated = parseInt(sd['alloted']);
   details.booked = parseInt(sd['booked']);
   details.reserved = parseInt(sd['reserved']);
   details.available = details.allocated - (details.booked + details.reserved);
   this.gridDetails.listStore.store.push(details);
   });
   }
   });
   this.gridDetails.loadData();
   }
   });
   }
   */

  setValidatorSplitByBooking() {
    this.validatorRules = {
      bookingNo: {
        rules: [
          {
            type: "min-length[16]",
            prompt:
              "Minimum length booking number is 16 characters, Maximum 17 Characters in Total.",
          },
          {
            type: "empty",
            prompt: "Please input Booking Number.",
          },
        ],
      },
    };
  }

  setValidatorSplitByBookingChina() {
    this.validatorRules = {
      bookingNo: {
        rules: [
          {
            type: "min-length[15]",
            prompt:
              "Minimum length booking number is 15 characters, Maximum 17 Characters in Total.",
          },
          {
            type: "empty",
            prompt: "Please input Booking Number.",
          },
        ],
      },
    };
  }

  addAllocation() {
    // console.log("bruray");
  }

  handleSave() {
    
    console.log('# Handle save');
    console.log(this.isBookingReferences)
    let books:any[] = [];
    this.isAfterSave = false;
    if(this.isBookingReferences){
      let errorNumber = 0;

      
      if(this.splitStore.length > 1){
        let valueArr = this.splitStore.map(function(item){ return item.bNoNew.toLowerCase() + item.containerTypeNew.toLowerCase() + item.containerSizeNew.toLowerCase() });
        let isDuplicate = valueArr.some(function(item, idx){
            return valueArr.indexOf(item) != idx
        });
        if(isDuplicate){
          errorNumber = 3;
        }
      }
      

      this.splitStore.forEach(item=>{
        console.log(item);
        if(!item.bNoNew){
          errorNumber = 1;
        }
        if(item.bNoNew && this.regexSuffixBookingNumber.test(item.bNoNew) === false){
          errorNumber = 2;
        }
        
        if(this.isChinaSite === true){
          books.push(this.bookingNumberdefault+item.bNoNew);
        }
        
      })

      if(errorNumber === 1){
        this.message(
          "warning",
          "Warning",
          "Please input Booking Number.",
          "okonly",
          { ok: "this.loading=false;" }
        );
        return
      }

      if(errorNumber === 2){
        this.message(
          "warning",
          "Warning",
          "Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits",
          "okonly",
          { ok: "this.loading=false;" }
        );
        return
      }

      
      if(errorNumber === 3){
        this.message(
          "warning",
          "Warning",
          "Booking Reference is duplicated, please try again!",
          "okonly",
          { ok: "this.loading=false;" }
        );
        return
      }
      

      /*
      this.splitStore = this.splitStore.map(item=>{
        const bookingNumber = this.modelBooking['bookingNo'].substring(0, 10) + item.bNoNew;
        console.log("#bookingNumber : " + bookingNumber);
        return {
          ...item,
          bNoNew: bookingNumber.toLocaleUpperCase()
        }
      })
      */

      if(this.isChinaSite === true){
        if(books.length > 0){
          let paramCheckBooking = new ParamCheckBooking();
          paramCheckBooking.bookings = books;
          paramCheckBooking.officeCode = this.office.officeCode;
          paramCheckBooking.defaultBookingNumber = this.bookingNumberdefault;
          
          this.genericService
          .POST(
            this.configService.config.BASE_API.toString() + "/MasterBookings/checkBookingExistSplitChina",paramCheckBooking
          )
          .subscribe((resp) => {
            if(resp.json()['message'] !== 'SUCCESS'){
                this.message(
                  "warning",
                  "Warning",
                  resp.json()['message'],
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return
            }else{
              this.checkBeforeSave();
            }
          });
        }else{
          this.checkBeforeSave();
        }

      }else{
        this.checkBeforeSave();
      }


    }else{
        this.checkBeforeSave();
    }

    
  }

  checkBeforeSave(){
    var ckZero = [];
    this.idDel = [];
    var strX: any;
    var tr = 0;
    this.flagConf = 0;

    this.splitStore.forEach((yt) => {
      if (yt.qtyNew == 0) {
        this.flagConf = 1;

        this.idDel.push({
          seqdel: yt.seqnoNew,
        });

        let ck = this.isBookingReferences === true && this.isChinaSite === true ? this.bookingNumberdefault + yt.bNoNew : yt.bNoNew;
        if(!ckZero.includes(ck)){
          ckZero[tr] = ck; 
          tr++;
        }
      }
    });

    strX = ckZero.join();
    // console.log(strX);

    var messg =
      "No quantity detected for below booking(s): <br/>" +
      strX +
      "<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will be removed. <br/> &lt;No&gt; to edit.";

    if (this.flagConf == 1) {
      this.message("information", "Information", messg, "yesno", {
        yes: "this.saveEvent()",
        no: "this.closeAlert()",
      });
    } else {
      this.saveEvent();
    }
  }

  closeAlert(){
    this.splitStore = this.splitStore.map(item=>{
      return {
        ...item,
        bNoNew: item.bNoNew
      }
    })
  }

  afterSave(){
    console.log("KEMARI AFTER SAVE");
    this.splitStore = [];
    //$("input[name=splitType][value=0]").prop("checked", true);
    this.getBookingNo(this.modelBooking.bookingNo, "RET");
    //for (var y = 0; y < parseInt(this.modelBooking.splitQty); y++) {
      this.generateBookingNumberAfterSave();

    //}

  }

  handleClose() {
    //handle close event
    this.router.navigateByUrl("/main/home");
  }

  firstInit() {
    // console.log("office : "+this.cook.getCookie("defaultLocation"));
    this.office.officeCode = this.cook.getDefaultLocationCode(); //localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook
      .getCookie("defaultLocation")
      .split("|")[0];
  }

  rowEvent(act: String, inx: number) {
    var tempStore = [];
    var delStore = [];
    var m = 0;

    if (act == "add") {
      // this.splitDetails.bNoNew.splice(1,0,'SUDUSGNSHPAA0001A')
      let isErrorValue = 0;
      this.splitStore.forEach((pt) => {
        // this.gridHeader.listStore.addData(pt);
        //// console.log(pt);
        if(!this.regexSuffixBookingNumber.test(pt.bNoNew)){
          isErrorValue = 1;
        }

        tempStore.push({
          bnotemp: pt.bNoNew,
          seqnotemp: pt.seqnoNew,
          contypetemp: pt.containerTypeNew,
          contsizetemp: pt.containerSizeNew,
          qtytemp: pt.qtyNew,
          seqprefixtemp: pt.bSeqPrefix,
          brootbnotemp: pt.brootbno,
        });
      });

      if(isErrorValue === 1){
        this.message(
          "error",
          "Error",
          "Please input Booking Number",
          "okonly",
          { ok: "" }
        );
        return;
      }

      this.splitStore = [];

      //// console.log('tempostore');
      //// console.log(tempStore);

      for (var h = 0; h < tempStore.length + 1; h++) {
        this.splitDetails = new BookingSplit();

        if (h == inx) {
          this.splitDetails.bNoNew = tempStore[h - 1].bnotemp;
          this.splitDetails.seqnoNew = inx + 1;
          this.splitDetails.qtyNew = 0;
          this.splitDetails.containerSizeNew = this.cntSz[0];
          this.splitDetails.containerTypeNew = tempStore[h - 1].contypetemp;
          this.splitDetails.bSeqPrefix = tempStore[h - 1].seqprefixtemp;
          this.splitDetails.brootbno = tempStore[h - 1].brootbnotemp;
          //$('#quanty_'+inx+1).val(0);
          //$('#typeSize_'+inx+1).val(this.typeSize[0]);
          //$('#cntSize_'+inx+1).val(this.cntSz[0]);
        } else if (h > inx) {
          this.splitDetails.bNoNew = tempStore[h - 1].bnotemp;
          this.splitDetails.seqnoNew = h + 1;
          this.splitDetails.qtyNew = tempStore[h - 1].qtytemp;
          this.splitDetails.containerSizeNew = tempStore[h - 1].contsizetemp;
          this.splitDetails.containerTypeNew = tempStore[h - 1].contypetemp;
          this.splitDetails.bSeqPrefix = tempStore[h - 1].seqprefixtemp;
          this.splitDetails.brootbno = tempStore[h - 1].brootbnotemp;
          //$('#quanty_'+h+1).val(tempStore[h].qtytemp);
          //$('#typeSize_'+h+1).val(tempStore[h].contypetemp);
          //$('#cntSize_'+h+1).val(tempStore[h].contsizetemp);
        } else {
          // console.log('masuk else');
          //// console.log(tempStore[h].qtytemp);
          this.splitDetails.bNoNew = tempStore[h].bnotemp;
          this.splitDetails.seqnoNew = h + 1;
          this.splitDetails.qtyNew = tempStore[h].qtytemp;
          this.splitDetails.containerSizeNew = tempStore[h].contsizetemp;
          this.splitDetails.containerTypeNew = tempStore[h].contypetemp;
          this.splitDetails.bSeqPrefix = tempStore[h].seqprefixtemp;
          this.splitDetails.brootbno = tempStore[h].brootbnotemp;
          // console.log(this.splitDetails.seqnoNew);
          //$('#quanty_'+this.splitDetails.seqnoNew).val(this.splitDetails.qtyNew);
          //$('#typeSize_'+this.splitDetails.seqnoNew).val(this.splitDetails.containerTypeNew);
          //$('#cntSize_'+this.splitDetails.seqnoNew).val(this.splitDetails.containerSizeNew);
          // console.log($('#quanty_'+(h+1)).val());
        }

        this.splitStore.push(this.splitDetails);
      }

      this.splitStore.forEach((wt) => {
        // console.log(wt);
      });
    }

    if (act == "del") {
      this.splitStore.forEach((pt) => {
        tempStore.push({
          bnotemp: pt.bNoNew,
          seqnotemp: pt.seqnoNew,
          contypetemp: pt.containerTypeNew,
          contsizetemp: pt.containerSizeNew,
          qtytemp: pt.qtyNew,
          seqprefixtemp: pt.bSeqPrefix,
          brootbnotemp: pt.brootbno,
        });
      });

      this.splitStore = [];

      for (var h = 0; h < tempStore.length; h++) {
        // console.log('posisi'+h+'dan'+inx);
        if (h + 1 != inx) {
          // console.log('masuk bno nya'+tempStore[h].bnotemp);
          delStore.push({
            bnotemp: tempStore[h].bnotemp,
            seqnotemp: h + 1,
            contypetemp: tempStore[h].contypetemp,
            contsizetemp: tempStore[h].contsizetemp,
            qtytemp: tempStore[h].qtytemp,
            seqprefixtemp: tempStore[h].seqprefixtemp,
            brootbnotemp: tempStore[h].brootbnotemp,
          });
        }
      }
      // console.log(delStore);
      for (var g = 0; g < delStore.length; g++) {
        this.splitDetails = new BookingSplit();
        this.splitDetails.bNoNew = delStore[g].bnotemp;
        this.splitDetails.seqnoNew = g + 1;
        this.splitDetails.qtyNew = delStore[g].qtytemp;
        this.splitDetails.containerSizeNew = delStore[g].contsizetemp;
        this.splitDetails.containerTypeNew = delStore[g].contypetemp;
        this.splitDetails.bSeqPrefix = delStore[g].seqprefixtemp;
        this.splitDetails.brootbno = delStore[g].brootbnotemp;
        this.splitStore.push(this.splitDetails);
      }

      this.splitStore.forEach((wt) => {
        // console.log(wt);
      });

      if (delStore.length > 0) {
        this.doUpdateHeaderTable();
      } else {
        this.getBookingNo(this.modelBooking.bookingNo, "SUDU");
      }
    }
  }

  chkContainer(typC: String, bookingNoNew) {
    console.log("jenisnya " + this.cntOwner);

    var cntCount = $("#tblSplit tr").length - 1;
    //GOS-416 Each time you change the Container Type, assign Container Type = 0
    $("#quanty_" + typC).val(0);
    for (var v = 1; v <= cntCount; v++) {
      // console.log('jenisnya V '+v+'sebelumnya '+ cntCount);// console.log('book atas' + $('#bookNo_'+v).val());

      if (v > 1 && $("#bookNo_" + v).val() == $("#bookNo_" + (v - 1)).val()) {
        if (
          this.cntOwner != "SLOT" &&
          $("#typeSize_" + v).val() != $("#typeSize_" + (v - 1)).val()
        ) {
          if (this.officeCode != 'SGSIN') {
            this.message(
              "information",
              "Information",
              "This is not a SLOT booking.<br/>Splitting accross container type is not allowed.",
              "okonly",
              { ok: "this.loading=false;" }
            );
            $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
          } else {
            if (Number(typC) == v) {
              this.splitStore.forEach((pt) => {
                if (pt.bNoNew === bookingNoNew) {
                  pt.containerTypeNew = $("#typeSize_" + typC).val();
                }
              });
            } else {
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            }
          }
        }

        if (this.cntOwner == "SLOT") {
          // console.log('index of '+this.combineSlot.indexOf($('#typeSize_'+v).val()));
          if (this.combineSlot.indexOf($("#typeSize_" + v).val()) > -1) {
            //klo slot 1

            if (
              this.combineSlot.indexOf($("#typeSize_" + v).val()) > -1 &&
              this.combineSlot.indexOf($("#typeSize_" + (v - 1)).val()) < 0
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Normal, Reefer, Reefer Dry and Tank are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            } else if (
              this.combineSlot.indexOf($("#typeSize_" + v).val()) < 0 &&
              this.combineSlot.indexOf($("#typeSize_" + (v - 1)).val()) > -1
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Normal, Reefer, Reefer Dry and Tank are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            } else if (
              this.combineSlot.indexOf($("#typeSize_" + v).val()) < 0 &&
              this.combineSlot.indexOf($("#typeSize_" + (v - 1)).val()) < 0 &&
              $("#typeSize_" + v).val() != $("#typeSize_" + (v - 1)).val()
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Normal, Reefer, Reefer Dry and Tank are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            }
          } //end klo slot 1

          if (this.combineSlot2.indexOf($("#typeSize_" + v).val()) > -1) {
            //klo slot 1

            if (
              this.combineSlot2.indexOf($("#typeSize_" + v).val()) > -1 &&
              this.combineSlot2.indexOf($("#typeSize_" + (v - 1)).val()) < 0
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Platform, Flatrack and Open Top are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            } else if (
              this.combineSlot2.indexOf($("#typeSize_" + v).val()) < 0 &&
              this.combineSlot2.indexOf($("#typeSize_" + (v - 1)).val()) > -1
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Platform, Flatrack and Open Top are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            } else if (
              this.combineSlot2.indexOf($("#typeSize_" + v).val()) < 0 &&
              this.combineSlot2.indexOf($("#typeSize_" + (v - 1)).val()) < 0 &&
              $("#typeSize_" + v).val() != $("#typeSize_" + (v - 1)).val()
            ) {
              this.message(
                "information",
                "Information",
                "Invalid container type.<br/>Only Platform, Flatrack and Open Top are allowed to co-exist in SLOT Booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              $("#typeSize_" + v).val($("#typeSize_" + (v - 1)).val());
            }
          } //end klo slot 1
        }
      }

      this.splitStore.forEach((pt) => {
        // this.gridHeader.listStore.addData(pt);

        if (pt.seqnoNew == v) {
          pt.containerSizeNew = $("#cnSize_" + v).val();
          pt.qtyNew = $("#quanty_" + v).val();
          pt.containerTypeNew = $("#typeSize_" + v).val();
        }

        // // console.log(pt);
      });
    }
  }

  qtyBlur(idx: number) {
    /*JIRA 72 */
    //if(this.cntOwner == 'SLOT'){
    //this.doUpdateQtySlot(idx);
    //}else{
    this.doUpdateQty(idx);
    //}
  }

  doUpdateQtySlot(idx: number) {
    var rowCount = $("#tblSplit tr").length - 1;

    var reor = 0;
    this.sumz20 = 0;
    this.sumz40 = 0;
    this.sumz45 = 0;
    this.sumzH20 = 0;
    this.sumzH40 = 0;
    this.sumzH45 = 0;
    var quantyFlag = 0;
    var flagQty = this.modelBooking.sumGridQty;
    // console.log('After Blur2');
    var maxLimit = 0;

    // console.log('rowcount'+rowCount)

    for (var k = 1; k <= rowCount; k++) {
      //// console.log('Row '+(k+1)+$('#seqId_'+k).val()+' '+$('#bookNo_'+k).val()+ ' '+$('#typeSize_'+k).val()+' '+$('#cnSize_'+k).val());
      quantyFlag = quantyFlag + parseInt($("#quanty_" + k).val());

      // console.log(this.baseSlotQty);
      //console.log(quantyFlag);
      /* JIRA 72 */
      //if(this.baseSlotQty - quantyFlag < 1){
      //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows.','okonly',{ok: 'this.loading=false;'});
      //$('#quanty_'+k).val(this.valPrev20[k]);
      // reor = 1;
      //}else{
      this.valPrev20[k] = $("#quanty_" + k).val();
      //}
    }

    // console.log('this SZ'+this.sz20);
    //check new quantity with previous booking's quantity
    if (reor == 0) {
      // console.log('ReBlur '+this.modelBooking.bookingNo);
      this.splitStore.forEach((pt) => {
        // this.gridHeader.listStore.addData(pt);

        if (pt.seqnoNew == idx) {
          pt.containerSizeNew = $("#cnSize_" + idx).val();
          pt.qtyNew = $("#quanty_" + idx).val();
          pt.containerTypeNew = $("#typeSize_" + idx).val();
        }

        // console.log(pt);
      });
      this.doUpdateHeaderTable();
    } else {
      this.message(
        "information",
        "Information",
        "Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows.",
        "okonly",
        { ok: "this.loading=false;" }
      );
      for (var k = 1; k <= rowCount; k++) {
        $("#quanty_" + k).val(0);
      }
      this.splitStore.forEach((pt) => {
        // this.gridHeader.listStore.addData(pt);

        // if(pt.seqnoNew == idx){

        //pt.containerSizeNew = $('#cnSize_'+idx).val();
        pt.qtyNew = 0;
        // pt.containerTypeNew = $('#typeSize_'+idx).val();
        // }
      });
    }

    //if(maxLimit == 0)
    //this.doUpdateHeaderTable();

    // console.log(this.sumz20 + ' ' + this.sumz40+' '+ this.sumz45);
  }

  doUpdateQty(idx: number) {
    var rowCount = $("#tblSplit tr").length - 1;

    var reor = 0;
    this.sumz20 = 0;
    this.sumz40 = 0;
    this.sumz45 = 0;
    this.sumzH20 = 0;
    this.sumzH40 = 0;
    this.sumzH45 = 0;
    var quantyFlag = 0;
    var flagQty = this.modelBooking.sumGridQty;
    // console.log('After Blur2');
    var maxLimit = 0;

    // console.log('rowcount'+rowCount)

    for (var k = 1; k <= rowCount; k++) {
      //// console.log('Row '+(k+1)+$('#seqId_'+k).val()+' '+$('#bookNo_'+k).val()+ ' '+$('#typeSize_'+k).val()+' '+$('#cnSize_'+k).val());
      quantyFlag = quantyFlag + parseInt($("#quanty_" + k).val());

      switch ($("#cnSize_" + k).val()) {
        case "20":
          this.sumz20 = this.sumz20 + parseInt($("#quanty_" + k).val());
          if (this.sumz20 > this.sz20) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrev20[k]);
            reor = 1;
          } else {
            this.valPrev20[k] = $("#quanty_" + k).val();
          }
          break;

        case "40":
          this.sumz40 = this.sumz40 + parseInt($("#quanty_" + k).val());
          if (this.sumz40 > this.sz40) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrev40[k]);
            reor = 1;
          } else {
            this.valPrev40[k] = $("#quanty_" + k).val();
          }
          break;

        case "45":
          this.sumz45 = this.sumz45 + parseInt($("#quanty_" + k).val());
          if (this.sumz45 > this.sz45) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrev45[k]);
            reor = 1;
            //$('#cnSize_'+k).val(previous);
          } else {
            this.valPrev45[k] = $("#quanty_" + k).val();
          }
          break;

        case "H20":
          this.sumzH20 = this.sumzH20 + parseInt($("#quanty_" + k).val());
          if (this.sumzH20 > this.szH20) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrevH20[k]);
            reor = 1;
          } else {
            this.valPrevH20[k] = $("#quanty_" + k).val();
          }
          break;

        case "H40":
          this.sumzH40 = this.sumzH40 + parseInt($("#quanty_" + k).val());
          if (this.sumzH40 > this.szH40) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrevH40[k]);
            reor = 1;
          } else {
            this.valPrevH40[k] = $("#quanty_" + k).val();
          }
          break;

        case "H45":
          this.sumzH45 = this.sumzH45 + parseInt($("#quanty_" + k).val());
          if (this.sumzH45 > this.szH45) {
            //this.message('information','Information','Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows','okonly',{ok: 'this.loading=false;'});
            //$('#quanty_'+k).val(this.valPrevH45[k]);
            reor = 1;
          } else {
            this.valPrevH45[k] = $("#quanty_" + k).val();
          }
          break;
      }
    }

    if (this.modelBooking.sumGridQty - quantyFlag == 0 && reor == 0) {
      this.message(
        "information",
        "Information",
        "Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows.",
        "okonly",
        { ok: "this.loading=false;" }
      );
      $("#quanty_" + k).val("0");
      maxLimit = 1;
      for (var k = 1; k <= rowCount; k++) {
        $("#quanty_" + k).val(0);
      }
      this.splitStore.forEach((pt) => {
        pt.qtyNew = 0;
      });
    }
    // console.log('this SZ'+this.sz20);
    //check new quantity with previous booking's quantity
    if (reor == 0) {
      // console.log('ReBlur '+this.modelBooking.bookingNo);
      this.splitStore.forEach((pt) => {
        // this.gridHeader.listStore.addData(pt);

        if (pt.seqnoNew == idx) {
          pt.containerSizeNew = $("#cnSize_" + idx).val();
          pt.qtyNew = $("#quanty_" + idx).val();
          pt.containerTypeNew = $("#typeSize_" + idx).val();
        }

        // console.log(pt);
      });
    } else {
      this.message(
        "information",
        "Information",
        "Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. <br/>Do amend the quantity or remove unnecessary rows.",
        "okonly",
        { ok: "this.loading=false;" }
      );
      for (var k = 1; k <= rowCount; k++) {
        $("#quanty_" + k).val(0);
      }
      this.splitStore.forEach((pt) => {
        pt.qtyNew = 0;
      });
    }
    console.log(maxLimit + " maxlimit");
    if (maxLimit == 0) this.doUpdateHeaderTable();

    // console.log(this.sumz20 + ' ' + this.sumz40+' '+ this.sumz45);
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to split booking.",
            "okonly",
            { ok: "/*this.cancelEvent();*/" }
          );
          this.cancelEvent();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  getBookingNo(bookNo: string, trPrefix: string) {
    this.genericUtil.showLoader();
    this.sz20 = 0;
    this.sz40 = 0;
    this.sz45 = 0;
    this.szH20 = 0;
    this.szH45 = 0;
    this.szH40 = 0;

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/getBookingByNo/" +
          this.office.officeCode +
          "/" +
          bookNo.toUpperCase()
      )
      .subscribe((resp) => {
        if (resp.ok) {
          this.isSuccessSave = false;
          this.store2 = [];

          this.genericUtil.hideLoader();
          if (Object.keys(resp.json()["content"]).length > 0) {
            this.baseContainerType = resp.json()["content"][0].containerType;
            // GOS-416 get list of Container type from Api
            // GOS-485 only SGSIN remove limitation and allow Booking Split (for SOC & SOC T/S ownerships) across 7 different container types with conditions
            if(this.officeCode != 'SGSIN'){
              let containerTypeList = resp.json()["content"].map(x=> x.containerType);
              // remove duplicates in array
              this.typeSize = containerTypeList.filter((element, index) => {
                return containerTypeList.indexOf(element) === index;
              });
            }
            //validation for unvalid schedule
            this.checkValidSchedule(
              resp.json()["content"][0].bOceanVesselId,
              resp.json()["content"][0].bOceanVesselVoyage,
              resp.json()["content"][0].bOceanVesselBound,
              resp.json()["content"][0].bofficeCode
            );

            if (resp.json()["content"][0].bIsUnlocked == "N") {
              this.message(
                "information",
                "Information",
                "Current booking is locked.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else if (resp.json()["content"][0].bStatus == "D") {
              this.message(
                "information",
                "Information",
                "Current booking is deleted.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            }
            /* GMO-72
          else if(resp.json()['content'][0].b_container_ownership == 'SLOT'){

            this.message('information','Information','Booking Split is not allowed for SLOT booking.','okonly',{ok: 'this.loading=false;'});
            return false;

          }*/

            //GSO-504
            else if (resp.json()["content"][0].b_is_transmitted_to_psa != "N") {
              this.message(
                "information",
                "Information",
                "Splitting is not allowed for this particular booking number (" +
                  bookNo +
                  ") <br/> As this booking has already been transmitted to PSA.",
                "okonly",
                { ok: "this.loading=false;" }
              );

              this.store2 = resp.json()["content"];
              //this.store2.forEach(st=>{
              //this.gridHeader.listStore.addData(st);
              //// console.log(st);

              //});
            } else {
              /*else if(resp.json()['content'][0].spl > 0){
            this.message('information','Information','Unable to split a split Booking.','okonly',{ok: 'this.loading=false;'});
          }*/
              this.gridHeader.onClear();

              // console.log('After Clear 2');

              this.store2 = resp.json()["content"];
              this.store2.forEach((st) => {
                this.gridHeader.listStore.addData(st);
                // console.log(st);
              });

              // this.gridHeader.listStore.store= [];
              //this.gridHeader.listStore.store= resp.json()['content'];
              this.modelBooking.lblQty = bookNo.toUpperCase();

              //this.gridHeader.loadData();
              // console.log('After Load Data');
              //if(callback)
              //callback('');

              //// console.log('Isi store'+this.gridHeader.listStore.store);

              $("#sptQty").prop("disabled", false);

              var qtyCnt = 0;

              // console.log('ini yang' + resp.json()['content'][0].b_container_ownership);
              // console.log(resp.json()['content'][0].bNo);

              this.locationRef = resp.json()["content"][0].location_booking_ref;
              this.podCode = resp.json()["content"][0].b_pod_code;
              this.cntOwner = resp.json()["content"][0].b_container_ownership;
              this.oKeys = Object.keys(resp.json()["content"]).length;

              // if (this.cntOwner == 'SLOT')
              // this.gridHeader.enableSelectAll = true
              //else

              //BUG JIRA GMO-72
              this.gridHeader.enableSelectAll = false;

              this.gridHeader.loadData();

              for (
                var i = 0;
                i < Object.keys(resp.json()["content"]).length;
                i++
              ) {
                qtyCnt = qtyCnt + parseInt(resp.json()["content"][i].qty);
                // console.log('container size ' + resp.json()['content'][i].containerSize);
                //REMARK IF JIRA 72
                //if(this.cntSz.indexOf(resp.json()['content'][i].containerSize)<0 && this.cntOwner != 'SLOT'){
                if (
                  this.cntSz.indexOf(resp.json()["content"][i].containerSize) <
                  0
                ) {
                  this.cntSz[i] = resp.json()["content"][i].containerSize;
                }

                switch (resp.json()["content"][i].containerSize) {
                  case "20":
                    this.sz20 = this.sz20 + resp.json()["content"][i].qty;
                    break;

                  case "40":
                    this.sz40 = this.sz40 + resp.json()["content"][i].qty;
                    break;

                  case "45":
                    this.sz45 = this.sz45 + resp.json()["content"][i].qty;
                    break;

                  case "H20":
                    this.szH20 = this.szH20 + resp.json()["content"][i].qty;
                    break;

                  case "H40":
                    this.szH40 = this.szH40 + resp.json()["content"][i].qty;
                    break;

                  case "H45":
                    this.szH45 = this.szH45 + resp.json()["content"][i].qty;
                    break;
                }
              }
              // console.log('VARSIZE+ '+this.sz20+' '+this.sz40+' '+this.sz45);
              this.cntSz = this.cntSz.filter(Boolean);
              //this.cntSz.push(resp.json()['content'].containerSize);
              //// console.log('size container'+this.cntSz);
              this.modelBooking.sumGridQty = qtyCnt;
              // console.log('Containter Size2 '+qtyCnt);
            }
          } else {
            this.genericUtil.hideLoader();
            this.message(
              "information",
              "Information",
              "No record(s) found.",
              "okonly",
              { ok: "this.loading=false;" }
            );
            this.gridHeader.onClear();
            this.modelBooking.lblQty = "";
            //this.message('information','Information','No record(s) found.','okonly',{ok:''});
            //alert('No Records Found');
          }
          //this.fillGrid();
        }
      });

    //GET MASTER DATA
    /*
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingMasterByNo/'+this.office.officeCode+'/'+bookNo).subscribe((sesp) =>{

      if(sesp.ok){

      }

    });
    */
  }

  getSailingDetail(vesselId, voyage, bound, service) {
    var hiturl = ""; //this.configService.config.BASE_API.toString() +'/MasterSailingSchedules/findByVesselVoyageBound/'+vesselId+'/'+voyage+'/'+bound+'/'+service;
    // console.log("HIT URL: "+hiturl);
    //this.gridSailingDetail.search = '*';
    this.gridSailingDetail.url = hiturl;
    this.gridSailingDetail.loadData();
    /*
     this.genericService.GET(hiturl).subscribe((resp) =>{
     if(resp.ok){
     if(resp.json()){
     //this.modelDetail.available = String(resp.json()['content']['alloted']-(resp.json()['content']['booked']+resp.json()['content']['reserved']));
     //// console.log("Alloted : "+resp.json()['content'][0]['vesselAllocationDetails'][0]['alloted']);
     //this.gridSailingDetail.listStore.store= resp.json()['content'][0]['sailingDetails'];
     //this.gridSailingDetail.listStore.store.forEach(dataAllot=>{
     //  dataAllot['available'] = (parseInt(dataAllot['alloted'])-(parseInt(dataAllot['booked'] + parseInt(dataAllot['reserved'])))).toString();
     //});

     this.gridSailingDetail.loadData();
     }
     }


     });*/
  }

  bookingSplitCheckBlDialogOkButton() {
    this.bookingSplitDialogMessage = "";
    this.genericUtil.closeDialog("bookingSplitCheckBlDialog");
  }

  checkSplitTypeAndShangHai(){

    if ($("#splitType[value=0]").is(":checked")) this.isBookingReferences = false;
    else this.isBookingReferences = true;

    const officeCode = this.cook.getDefaultLocationCode().substring(0, 2);
    if (officeCode === 'CN' && this.isBookingReferences) {
      //this.isBookingReferences = true;
      this.bookingNumberdefault = this.modelBooking['bookingNo'].substring(0,10).toLocaleUpperCase();
    }else{
      //this.isBookingReferences = false;
    }
  }

}
