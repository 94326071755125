import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import {
  Authorize,
  Validator,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { interval } from "rxjs/observable/interval";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { specialCharUtil } from "../shared";

declare var $: any;

export class ReportParam {
  vesselId = "";
  voyage = "";
  bound = "O";
  pol = "";
  pod = "ALL";
  serviceId = "";
  constructor() {}
}

@Component({
  selector: "app-report-cll-recapitulation-list-page",
  templateUrl: "./report-cll-recapitulation-list-page.component.html",
  styleUrls: ["./report-cll-recapitulation-list-page.component.css"],
})
export class ReportCllRecapitulationListPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  isError = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  settingDischargePort;

  validatorRules = {
    vesselId: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Vessel.",
        },
      ],
    },
    voyage: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Voyage.",
        },
      ],
    },
    bound: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Bound.",
        },
      ],
    },
    pol: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Loading Port.",
        },
      ],
    },
  };

  progressBarValue = 0;
  progressBarMessage = "";

  sCharUtil = new specialCharUtil();
  param = new ReportParam();

  hitUrl: string = this.configService.config.BASE_API.toString();

  constructor(
    private _http: HttpClient,
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Report CLL Recapitulation List",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 150 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        //{header: 'Location Id', field: 'locationId', width: 100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        //{header: 'Location Id', field: 'locationId', width: 100},
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.param = new ReportParam();
  }

  toolbarEvent(event) {
    // // console.log('event : ' + event);
    switch (event) {
      case "print":
        this.validateHandlePrint();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {}

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.vesselId = event.vesselId;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.param.vesselId +
          "/" +
          this.param.bound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.param.vesselId = "";
      this.cbVoyage.setUrl("");
      this.cbVoyage.setValue("");
    }
  }

  changeEventVoyage(event) {

    console.log("service Id : "+event.serviceId);
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.voyage = event.voyage;
      this.param.serviceId = event.serviceId;
    } else {
      this.param.voyage = "";
      this.param.serviceId = "";
    }
  }

  changeEventLoadPort(event) {
    if (
      event != null &&
      event != "" &&
      event != undefined &&
      String(event) != "NaN"
    ) {
      this.param.pol = event.locationCode;

      this.cbDischargePort.setValue("");
      this.cbDischargePort.setUrl(
        this.configService.config.BASE_API.toString() +
          "/CLLReport/findComboBoxControlPodRecapitulation/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.param.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.param.voyage).trim()) +
          "/" +
          this.param.bound +
          "/" +
          event.locationCode +
          "/{query}"
      );
    } else {
      this.param.pol = "";
      this.param.pod = "ALL";
      this.cbDischargePort.setUrl("");
    }
  }

  changeEventDischargePort(event) {
    if (
      event.locationCode != null &&
      event.locationCode != "" &&
      event.locationCode != undefined &&
      String(event.locationCode) != "NaN"
    ) {
      this.param.pod = event.locationCode;
    } else {
      this.param.pod = "ALL";
    }
  }

  handleCancel() {
    this.isError = false;
    this.param = new ReportParam();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbLoadPort.setValue("");
    this.cbDischargePort.setValue("");
    this.progressBarValue = 0;
    this.progressBarMessage = "";
    $("#recap-progress-bar").addClass("default-color");
    this.onProgress();
  }

  validateHandlePrint() {
    this.resetError();
    this.isError = this.onValidate(this.param);

    if (!this.isError) {
      var splitUrl = this.configService.config.getPDFUrl.split(":");
      //const urlHit = this.configService.config.getPDFUrl.replace(splitUrl[2],"16000") + '/CLLReport/cllCheckRecap/' + this.cookieService.getDefaultLocationCode() + '/' +  this.param.vesselId + '/' + this.param.voyage + '/' + this.param.bound + '/' + this.param.pol + '/' + this.param.pod + '/' + this.cookieService.getName().toUpperCase()
      const urlHit =
        this.hitUrl +
        "/CLLReport/cllCheckRecap/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.param.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.param.voyage).trim()) +
        "/" +
        this.param.bound +
        "/" +
        this.param.pol +
        "/" +
        this.param.pod +
        "/" +
        this.cookieService.getName().toUpperCase();

      var self = this;
      this.genericService.GET(urlHit).subscribe((resp) => {
        if (resp.json() === true) {
          self.handlePrint();
        } else {
          self.message(
            "information",
            "Information",
            "No data found!",
            "okonly",
            { ok: "" }
          );
        }
      });
    }
  }

  handlePrint() {
    // console.log('this.iserror => ' + this.isError);
    var splitUrl = this.configService.config.getPDFUrl.split(":");
    //const urlHit = this.configService.config.getPDFUrl.replace(splitUrl[2],"16000") + '/CLLReport/cllRecapitulationReport/' + this.cookieService.getDefaultLocationCode() + '/' +  this.param.vesselId + '/' + this.param.voyage + '/' + this.param.bound + '/' + this.param.pol + '/' + this.param.pod + '/' + this.cookieService.getName().toUpperCase()
    //console.log("urlHit : " + urlHit)
    // window.open(urlHit);

    this.progressBarValue = 0;
    this.progressBarMessage = "";

    const urlHit =
      this.hitUrl +
      "?q=/CLLReport/cllRecapitulationReport/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      this.param.vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(this.param.voyage).trim()) +
      "/" +
      this.param.bound +
      "/" +
      this.param.pol +
      "/" +
      this.param.pod +
      "/" +
      this.param.serviceId +
      "/" +
      this.cookieService.getName().toUpperCase();
    console.log(urlHit);
    // hit async the report
    this._http
      .get(urlHit, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe((res) => {
        FileSaver.saveAs(res, "recap.xlsx");
      });

    // progress bar worker
    let self = this;
    const source = interval(500);
    const subscribe = source.subscribe((val) => {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/CLLReport/updateProgressBar/" +
            this.cookieService.getName() +
            "/CLL_RECAP_ALL_POD"
        )
        .subscribe((resp) => {
          self.progressBarValue = resp.json()["progress"];
          self.progressBarMessage =  resp.json()["message"] ?
            "(Processing for POD = " + resp.json()["message"] + ")" : '';

          console.log("progress value = > " + this.progressBarValue);
          // Handle when api returns {} in ticket GOS-403
          if (self.progressBarValue >= 100) {

            self.progressBarMessage = "";
            $("#recap-progress-bar").removeClass("default-color");
            subscribe.unsubscribe();
          }/*else{
             this.message(
              "error",
              "Error",
              "Processing for POD failed",
              "okonly",
              { ok: "" }
            );
          }
          subscribe.unsubscribe();
          $("#recap-progress-bar").removeClass("default-color");
          */
          self.onProgress();
        }, error =>{
          this.message(
            "error",
            "Error",
            "Processing for POD failed",
            "okonly",
            { ok: "" }
          );
          subscribe.unsubscribe();
        });
    });
  }

  resetError() {
    this.param["error-vesselId"] = "";
    this.param["error-voyage"] = "";
    this.param["error-bound"] = "";
    this.param["error-pol"] = "";
    this.param["error-pod"] = "";
  }

  onProgress() {
    $("#recap-progress-bar").progress({
      percent: this.progressBarValue,
    });
  }
}
