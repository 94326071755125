import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { HSCode, specialCharUtil } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  UploadPluginComponent,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";
import { throwError } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";


declare var $: any;

@Component({
  selector: "app-master-hs-code-page",
  templateUrl: "./master-hs-code-page.component.html",
  styleUrls: ["./master-hs-code-page.component.css"],
})
export class MasterHSCodePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;
  @ViewChild("uploadHsCode") uploadHsCode: UploadPluginComponent;
  @ViewChild("cbHsDescriptionHistory") cbHsDescriptionHistory: ComboPluginComponent;
  // @ViewChild("uploadPlg") uploadPlg: UploadPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for validator */
  validatorRules: any;


  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  postData = {};
  fileUrl;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter settings */
  settingToolbar;
  gridHSCode;
  columnHSCode;
  settingGrid;
  settingGridHistory;
  settingUploadHsCode;
  settingHsDescriptionHistory

  columnDefsHSCode;
  rowDataHSCode: any = [];
  userID: string = "";
  valHsCode: string= "";
  valHsId: string = "0";

  /* Parameter model */
  model = new HSCode();

  loading = false;
  fileName: string = "";
  hitUrl: string = this.configService.config.BASE_API.toString();
  sCharUtil = new specialCharUtil();

  lock = false;

  viewOnlyStatus = false;
  loadingUploadHsCode = false;

  currentMenuID;
  currentMenu;
  
  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private sanitizer: DomSanitizer,
    private httpClient : HttpClient

  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "HS Code", value: "hsCode" },
        { display: "HS/Product Description", value: "hsDescription" },
      ],
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "HS Code", field: "hsCode", width: 150 },
        {
          header: "HS/Product Description",
          field: "hsDescription",
          width: 750,
        },
        { header: "HS UOM", field: "hsUom", width: 150 },
        { header: "ReferenceID", field: "referenceId", width: 250 },
        { header: "Uploaded By", field: "hsUploadBy", width: 150 },
        { header: "Date & Time", field: "hsDateTime", width: 150 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "hsDescription",
      sortingDirection: "ASC",
    };

    this.settingUploadHsCode = {
      id: "upload",
      uploadDir: "/HSCode",
      maxFiles: 1,
      filterFiles: "xls",
      showUploadButton: true,
      buttonLabel: "Upload",
    };

    this.settingHsDescriptionHistory = {
      id: "cbHsDescriptionHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "hsDescription",
      description: "",
      isMark: true,
      columns: [
        { header: "HS Description", field: "hsDescription", width: 450 },
        {
          header: "HS Code",
          field: "hsCode",
          width: 100,
        },
      ],
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "HS Description",
          field: "hsDescription",
          width: 750,
        },
        { header: "hs Code", field: "hsCode", width: 150 },
        { header: "hs uom", field: "hsUom", width: 150 },
        { header: "User Upload", field: "hsUploadBy", width: 150 },
        { header: "Time Upload", field: "hsDateTime", width: 150 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enablePagination: true,
    };

  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
  }

  firstInit() {
    this.userID = this.cookieService.getName();

    this.toolbarSetting.buttonsFront = [
      { name: 'Upload', event: 'upload', icon: 'upload' },
      { name: 'History', event: 'history', icon: 'history' },
      { name: 'Cancel', event: 'cancel', icon: 'remove' },
    ];

    this.toolbarSetting.searchBy2 = [];
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "upload,history";
    } else {
      this.disableToolbarButtons = "";
    }
    this.invisibleToolbarButtons = "";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridHistory(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
       break;
      case "afterLoad": 
       break
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            // this.handleUpdate();
          } else if (arr[0] == "dblClick") {
              let dt = this.grid.getSelectedValues()[0];
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "upload,history";
  
              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
          }
        }
        break;
    }
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  toolbarEvent(event: any) {
    switch (event) {
      case "upload":
        this.uploadDialog();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  uploadEventHsCode(event) {
    var list;
    var ext;
    if (event.includes(".xls")) {
      list = event.split(".xls");
      ext = ".xls";
    } else if (event.includes(".XLS")) {
      list = event.split(".XLS");
      ext = ".XLS";
    }

    switch (event) {
      case "success":
        this.loadingUploadHsCode = true;
        this.uploadProcess();
        break;
      default:
        switch (list[1].trim().split("-")[1].trim()) {
          case "deleted":
            this.uploadHsCode.store = [];
            this.uploadHsCode.setDisabled(true);
            break;
          case "uploaded":
            this.fileName = list[0].trim()+".xls";
            this.postData["officeCode"] = this.cookieService.getDefaultLocationCode();
            this.postData["userCreated"] = this.userID;
            this.postData["dateCreated"] = this.genericUtil.getPSIDate();
            this.postData["fileName"] = this.fileName;
            this.uploadHsCode.setDisabled(false);
            break;
        }
        break;
    }
  }

  uploadDialog() {
    $("#uploadDialog")
      .dialog({
        title: "Upload HS Code",
        modal: true,
        width: 1020,
        height: "auto",
        open: function (event, ui) {
          //hide close button.
          $(".ui-dialog").css("z-index", 103);
          $(".ui-widget-overlay").css("z-index", 102);
          $(this)
            .parent()
            .children()
            .children(".ui-dialog-titlebar-close")
            .hide();
          $("body").first().css("overflow", "hidden");
        },
        close: function (event, ui) {
          $("body").first().css("overflow", " auto");
        },
      })
      .dialog("open");

    this.uploadHsCode.setDisabled(true);

  }

  uploadProcess(){
    var token = localStorage.getItem('token') // Will return if it is not set 
    console.log(token);
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'token': token
      })
    // }

    // httpOptions.headers.append('observe','response');
    // httpOptions.headers.append('responseType','blob');
    // const requestOption = {httpOptions, responseType:'blob'}
    // var uri = this.hitUrl + "/MasterHSCodes/upload";

    var uri = this.hitUrl + "/?q=/MasterHSCodes/upload";

    // var uri = this.configService.config.BASE_API.toString()  ; + "/MasterHSCodes/upload";
    this.httpClient.post(uri, this.postData, {headers:headers,observe:'response', responseType:'blob'})
    // this.genericService.post(uri, this.postData, {observe:'response', responseType:'blob'})
    .subscribe((resp) => {
      if (resp.ok) {
        var message = resp.statusText;
        if (message == "OK") {
          let fileName2 = this.fileName + ".xls";
          let blob:Blob = resp.body as Blob;
          var link = document.createElement('a');
          link.download = fileName2;
          link.href = window.URL.createObjectURL(blob);
          link.click();

          this.message(
            "information",
            "Information",
            "File Uploaded and please check file download",
            "okonly",
            { ok: "" }
          );

          this.loadingUploadHsCode = false;
          this.closeUploadHsCode();
        } else if ((message = "NOT OK")) {
          console.log("sini not ok");

          this.message(
            "information",
            "information",
            "no cell location has been updated, check format field",
            "okonly",
            { ok: "" }
          );

          this.loadingUploadHsCode = false;

        }else{
          console.log("sini else");

        }
      }
    },err=>{
      this.loadingUploadHsCode = false;
      // this.postData = {};
      this.message(
        "error",
        "Error",
        "An error has occurred",
        "okonly",
        { ok: "" }
      );
    });
  }

  errorReportHsCode(){
    console.log("2");
  }

  closeUploadHsCode(){
    this.uploadHsCode.store = [];
    this.closeDialog("uploadDialog");
  }
  
  closeDialog(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "";
    } else {
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "";
    }
    this.grid.clearSelectedValues();

  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  eventDoubleClickHSCode(event: any){
    console.log("anu");
  }
  
  onGridReady(event: any){
    console.log("jancok", event);
    this.gridHSCode = event.api;
    this.columnHSCode = event.columnApi;
    this.gridHSCode.showLoadingOverlay();

    this.genericService
      .GET(this.configService.config.BASE_API.toString() +
      "/MasterHSCodes/findByHsCodeOrHsDescription/paper/1/10"
      )
      .subscribe((resp) => {
        console.log(resp.json()["content"]);
      }
    );
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];
    console.log(data);
    this.model = new HSCode();
    this.model.hsCode = String(data.hsCode).trim().toUpperCase();
    this.model.hsDescription = String(data.hsDescription).trim().toUpperCase();
    this.model.hsUom = String(data.hsUom).trim().toUpperCase();
    this.model.refrenceId = String(data.referenceId).trim().toUpperCase();
    this.model.uploadBy = String(data.hsUploadBy).trim().toUpperCase() == "UNDEFINED" ? "": String(data.hsUploadBy).trim().toUpperCase();
    this.model.dateAndTime = String(data.hsDateTime).trim().toUpperCase() == "UNDEFINED" ? "": String(data.hsDateTime).trim().toUpperCase();
  }

  retrieveHistory() {
    this.gridHistory.loadData();
  }
  
  valueHsDescriptionHistory(event: any) {
    this.valHsId = null;
    this.valHsCode = null;
    if (event.hsId == null) {
      if (event.target != null) {
      }
    } else {
      this.valHsId = String(event.hsId).trim();
      this.valHsCode = String(event.hsCode).trim();
      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valHsId);
    }
  }

  generatePrincipalHistoryReport(event: any){
     if (this.valHsCode == "" || this.valHsCode == null) {
      $("#dialogHsform").modal("show");
    } else {
      var formatName = "report-History-HSCode-1-"+this.valHsCode+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/68/History-HSCode-"
        +
        "1" +
        "-" +
        this.valHsCode +
        "/" +
        this.valHsId +
        // "1" +
        "@" +
        "@" 
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000")  +
      //     "/Mreport/runReport/68/History-HSCode-" +
      //     "1" +
      //     "-" +
      //     this.valHsCode +
      //     "/" +
      //     this.valHsId +
      //     // "1" +
      //     "@" +
      //     "@" 
      // );
    }
  }

  onOk() {
    $("#dialogvesselform").modal("hide");
  }
}
