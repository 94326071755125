<toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
  [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>

<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index: 99">
  <div class="ui medium text loader">Loading</div>
</div>

<div class="typeSplit">
  <div class="typeSplit-Bno">
    <div style="margin-bottom: 10px;">
      <b>POT: </b>
      <span>{{model.bPot1Name}}</span>
    </div>
    <div>
      <b>POT BKG No: </b>
      <span>SUDU{{model.tsPotBookingNo}}</span>
    </div>
  </div>
  <div class="typeSplit-type">
    <div class="w-100-alex">
      <div class="typeSplit-radio">
        <input type="radio" id="SUFFIX" name="typeSplit" value="SUFFIX" [(ngModel)]="typeSplitValue"
          [disabled]="typeSplitMethod === 'NEWREF'">
        <label for="Suffix">Suffix(A-Z)</label>
      </div>
      <div class="typeSplit-radio">
        <input type="radio" id="NEWREF" name="typeSplit" value="NEWREF" [disabled]="typeSplitMethod === 'SUFFIX'"
          [(ngModel)]="typeSplitValue">
        <label for="Suffix">New Reference</label>
      </div>
    </div>
    <div class="w-100-alex">
      <span>Quantity</span>: <input type="number" min="0" (keypress)="noneNum($event)" autocomplete="off" max="100"
        class="typeSplit-input" [(ngModel)]="quantityValue" name="quantityValue" [disabled]="isDisableQuantity" (change)="handleQtyChange($event)"/>
    </div>
  </div>
</div>

<div class="quantity-content">
  <div class="text-header">
    <b>Quantity in Main POT BKG No: </b>
    <span>QTY value will decrease as value in after split section increase
      simultaneously & "O" is allowed - Non Editable</span>
  </div>

  <div class="quantity-table">
    <table class="
      ui
      sortable
      selectable
      compact
      celled
      definition
      striped
      table
    ">
      <tr>
        <th>POL BKG No.</th>
        <th>POT BKG No.</th>
        <th>Ctr Type</th>
        <th>20</th>
        <th>40</th>
        <th>45</th>
        <th>H2O</th>
        <th>H40</th>
        <th>H45</th>
        <th>Connecting Vessel</th>
        <th>Connecting Voyage</th>
        <th>POD</th>
        <th>PODL</th>
        <th>FDest</th>
        <th>Bound</th>
        <th>Service</th>
      </tr>
      <tr>
        <td>SUDU{{mainBkgProspect.polBkgNo}}</td>
        <td>SUDU{{mainBkgProspect.potBkgNo}}</td>
        <td>{{mainBkgProspect.containerTypeName}}</td>
        <td>{{mainBkgProspect.ts20}}</td>
        <td>{{mainBkgProspect.ts40}}</td>
        <td>{{mainBkgProspect.ts45}}</td>
        <td>{{mainBkgProspect.tsH20}}</td>
        <td>{{mainBkgProspect.tsH40}}</td>
        <td>{{mainBkgProspect.tsH45}}</td>
        <td>{{mainBkgProspect.connectingVessel}}</td>
        <td>{{mainBkgProspect.connectingVoyage}}</td>
        <td>{{mainBkgProspect.podName}}</td>
        <td>{{mainBkgProspect.podlName}}</td>
        <td>{{mainBkgProspect.fdestName}}</td>
        <td>{{mainBkgProspect.bound}}</td>
        <td>{{mainBkgProspect.service}}</td>
      </tr>
      <tr>
        <td colspan="3">
          <span class="text-center">Original POT BKG QTY:</span>
        </td>
        <td>{{totalContainerUnit.ts20}}</td>
        <td>{{totalContainerUnit.ts40}}</td>
        <td>{{totalContainerUnit.ts45}}</td>
        <td>{{totalContainerUnit.tsH20}}</td>
        <td>{{totalContainerUnit.tsH40}}</td>
        <td>{{totalContainerUnit.tsH45}}</td>
        <td colspan="7"></td>
      </tr>
    </table>
  </div>
</div>

<div class="after-split">
  <div class="text-header">
    <b>After Split </b>
    <span>Max sum per ctr type & size = value as per Original POT BKG QTY</span>
  </div>
  <table class="
      table
      scroll 
    " cellspacing="0" cellpadding="0" id="tblSplit" #tblSplit>
    <tr>
      <th>
        <button (click)="deleteAfterSplitExceptionItem()">X </button>
      </th>
      <th>POL BKG No.</th>
      <th>POT BKG No.</th>
      <th>New POT BKG No.</th>
      <th>Ctr Type</th>
      <th>20</th>
      <th>40</th>
      <th>45</th>
      <th>H2O</th>
      <th>H40</th>
      <th>H45</th>
      <th>New Connecting Vessel</th>
      <th>New Connecting Voyage</th>
      <th>POD</th>
      <th>PODL</th>
      <th>FDest</th>
      <th>Bound</th>
      <th>Service</th>
    </tr>
    <tr *ngFor="let item of splitStore;let i=index">
      <td class="text-center">
        <input class="input-checkbox-delete" *ngIf="item.isEdit === true" [(ngModel)]="item.isChecked"
          (ngModelChange)="selecteItemToDelete($event,item)" type="checkbox" name="" id="">
      </td>
      <td>{{item.polBkgNo}}</td>
      <td>{{item.potBkgNo}}</td>
      <td>
        {{item.newPotBkgNo}}
      </td>
      <td>{{item.bContainerType}}</td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.ts20}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.ts20" name="ts20_{{ i }}"
          (ngModelChange)="calculateSplit($event, 'ts20', i)" id="ts20_{{ i}}" [disabled]="item.ts20Disabled">
      </td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.ts40}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.ts40" name="ts40_{{ i }}"
          (ngModelChange)="calculateSplit($event,'ts40', i)" id="ts40_{{ i }}" [disabled]="item.ts40Disabled">
      </td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.ts45}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.ts45" name="ts45_{{ i }}"
          (ngModelChange)="calculateSplit($event, 'ts45', i)" id="ts45_{{ i }}" [disabled]="item.ts45Disabled">
      </td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.tsH20}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.tsH20" name="item.tsH20_{{ i }}"
          (ngModelChange)="calculateSplit($event, 'tsH20', i)"
          id="item.tsH20_{{ i }}" [disabled]="item.tsH20Disabled">
      </td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.tsH40}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.tsH40" name="tsH40_{{ i }}"
          (ngModelChange)="calculateSplit($event, 'tsH40',i)" id="tsH40_{{ i }}" [disabled]="item.tsH40Disabled">
      </td>
      <td>
        <span *ngIf="item.isEdit === false">{{item.tsH45}}</span>
        <input *ngIf="item.isEdit === true" type="number" min="0" (keypress)="noneNum($event)" autocomplete="off"
          class="typeSplit-input w-80px" [(ngModel)]="item.tsH45" name="tsH45_{{ i }}"
          (ngModelChange)="calculateSplit($event, 'tsH45',i)" id="tsH45_{{ i }}" [disabled]="item.tsH45Disabled">
      </td>
      <td>
        <div class="table-search-al">
          <input type="text" autocomplete="off" class="typeSplit-input" id="searchVesselName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchVesselName($event, i)" (keyup)="keypressInputsearchVesselName($event, i)"
            value="{{item.newConnectingVesselName}}">
            <i class="search icon"></i>  
          <div class="valueVesselName d-none" id="valueVesselName_{{ i }}">
            <table *ngIf="valueVesselNameList.length > 0">
              <tr>
                <th>Vessel Code</th>
                <th>Vessel Name</th>
                <th>Valid</th>
              </tr>
              <tr *ngFor="let item of valueVesselNameList" (click)="selectedVesselname(item, i)">
                <td>{{item.vesselCode}}</td>
                <td>{{item.vesselName}}</td>
                <td>{{item.isValid}}</td>
              </tr>
            </table>
            <div *ngIf="valueVesselNameList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="table-search-al">
          <input type="text" autocomplete="off" disabled class="typeSplit-input" id="searchVoyageName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchVoyageName($event, i)" (keyup)="keypressInputSearchVoyageName($event, i)"
            value="{{item.newConnectingVoyage}}">
          <i class="search icon"></i>
          <div class="valueVoyageName d-none" id="valueVoyageName_{{ i }}">
            <table *ngIf="valueVoyageList.length > 0">
              <tr>
                <th>Voyage</th>
                <th>Bound</th>
                <th>Service</th>
              </tr>
              <tr *ngFor="let item of valueVoyageList" (click)="selectedVoyage(item, i)">
                <td>{{item.voyage}}</td>
                <td>{{item.bound}}</td>
                <td>{{item.serviceCode}}</td>
              </tr>
            </table>
            <div *ngIf="valueVoyageList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div class="table-search-al">
          <input type="text" autocomplete="off" class="typeSplit-input" id="searchPodName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchPodName($event, i)" (keyup)="keypressInputsearchPodName($event, i)"
            value="{{item.newPodName}}">
            <i class="search icon"></i>  
          <div class="valueVesselName d-none" id="valuePodName_{{ i }}">
            <table *ngIf="valuePodNameList.length > 0">
              <tr>
                <th>Location Code</th>
                <th>Location Name</th>
                <th>Valid</th>
              </tr>
              <tr *ngFor="let item of valuePodNameList" (click)="selectedPodName(item, i)">
                <td>{{item.locationCode}}</td>
                <td>{{item.locationName}}</td>
                <td>{{item.isValid}}</td>
              </tr>
            </table>
            <div *ngIf="valuePodNameList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div class="table-search-al">
          <input type="text" autocomplete="off" class="typeSplit-input" id="searchPodlName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchPodlName($event, i)" (keyup)="keypressInputsearchPodlName($event, i)"
            value="{{item.newPodlName}}">
            <i class="search icon"></i>  
          <div class="valueVesselName d-none" id="valuePodlName_{{ i }}">
            <table *ngIf="valuePodlNameList.length > 0">
              <tr>
                <th>Location Code</th>
                <th>Location Name</th>
                <th>Valid</th>
              </tr>
              <tr *ngFor="let item of valuePodlNameList" (click)="selectedPodlName(item, i)">
                <td>{{item.locationCode}}</td>
                <td>{{item.locationName}}</td>
                <td>{{item.isValid}}</td>
              </tr>
            </table>
            <div *ngIf="valuePodlNameList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>

      <td>
        <div class="table-search-al">
          <input type="text" autocomplete="off" class="typeSplit-input" id="searchFdestName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchFdestName($event, i)" (keyup)="keypressInputsearchFdestName($event, i)"
            value="{{item.newFdestName}}">
            <i class="search icon"></i>  
          <div class="valueVesselName d-none" id="valueFdestName_{{ i }}">
            <table *ngIf="valueFdestNameList.length > 0">
              <tr>
                <th>Location Code</th>
                <th>Location Name</th>
                <th>Valid</th>
              </tr>
              <tr *ngFor="let item of valueFdestNameList" (click)="selectedFdestName(item, i)">
                <td>{{item.locationCode}}</td>
                <td>{{item.locationName}}</td>
                <td>{{item.isValid}}</td>
              </tr>
            </table>
            <div *ngIf="valueFdestNameList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>

      <td>{{item.bound}}</td>
      <td>{{item.newConnectingVesselService}}</td>
    </tr>
  </table>
</div>

<div class="footer-exception-split">
  <div class="footer-exception">
    <label for="">Port-Pair Allocation summary of selected Connecting Vessel / Voyage</label>
    <!-- <div class="footer-exception-select">
      <combo-plugin #cbVesselFooter [settings]="settingVesselFooter"
        (change)="changeEventVesselFooter($event)"></combo-plugin>
    </div> -->
  </div>
  <ag-grid-angular style="width: 60%; height: 180px" #topGridPortPair class="ag-theme-balham"
    (gridReady)="onGridReadyGridPortPair($event)" (rowDoubleClicked)="rowDoubleClickGridPortPair($event)"
    (selectionChanged)="onSelectionChangedGridPortPair($event)" [getRowStyle]="getRowStyleGridPortPair"
    [rowData]="rowDataGridPortPair" [columnDefs]="columnDefsGridPortPair" [rowSelection]="'multiple'"
    [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" [rowMultiSelectWithClick]="true">
  </ag-grid-angular>
</div>

<div
  id="handleErrorWhenSave"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form mt-15px">
    <div [innerHTML]="msg | safe: 'html'"></div>

    <div class="btn-al" *ngIf="resultRes?.responseCode === '1'">
      <button class="positive ui button" (click)="handleResponseCode1()">Yes</button>
      <button class="negative ui button" (click)="closeDialog()">No</button>
    </div>

    <div class="btn-al" *ngIf="resultRes?.responseCode === '4'">
      <button class="positive ui button" (click)="handleResponseCode4()">OK</button>
      <button class="negative ui button" (click)="closeDialog()">Cancel</button>
    </div>
  </form>
</div>

<div id="UnconfirmedList" class="dialogUnconfirmedList">
  <table>
    <tr>
      <th>POL Booking No</th>
      <th>Discharging Vessel</th>
      <th>Discharging Voyage</th>
      <th>ETD POT</th>
      <th>Connecting Vessel</th>
      <th>Connecting Voyage</th>
      <th>ETA POT</th>
      <th>Difference</th> 
      <th>Proceed connection?</th>
      <th>New Connecting Vessel</th>
      <th>New Connecting Voyage</th>
      <th>New ETA POT</th>
      <th>New Difference</th> 
    </tr>
    <tr *ngFor="let item of unconfirmedList;let i = index">
      <td>{{item.polBkgNo}}</td>
      <td>{{item.dischargingVessel}}</td>
      <td>{{item.dischargingVoyage}}</td>
      <td>{{item.dischargingVesselPotEtd}}</td>
      <td>{{item.newConnectingVesselName}}</td>
      <td>{{item.newConnectingVoyage}}</td>
      <td>{{item.connectingVesselPotEta}}</td>
      <td>
        {{returnTime(item.connectingVesselPotEta, item.dischargingVesselPotEtd)}}
      </td>
      <td>
        <div class="d-flex-checkbox">
          <input type="radio" id="yes{{item.newPotBkgNo}}" name="yes{{item.newPotBkgNo}}" [(ngModel)]="item.isConfirm" value="Y" (ngModelChange)="proceedConnectChange($event, i)">
          <label for="yes{{item.newPotBkgNo}}" class="mr-20px">Yes</label>

          <input type="radio" id="no{{item.newPotBkgNo}}" name="no{{item.newPotBkgNo}}" [(ngModel)]="item.isConfirm" value="N" (ngModelChange)="proceedConnectChange($event, i)">
          <label for="no{{item.newPotBkgNo}}">No</label>
        </div>
      </td>
      <td>
        <div class="table-search-al newConnecttingVessel">
          <input type="text" autocomplete="off" class="typeSplit-input" id="valueNewConnecttingVessel_{{ i }}"
            placeholder="Search..." (keyup.enter)="newConnecttingVessel($event, i)" (keyup)="keypressInputNewConnecttingVessel($event, i)"
            value="{{item.newConnectingVesselName2}}">
            <i class="search icon"></i>  
          <div class="valueVesselName d-none" id="newConnecttingVessel_{{ i }}">
            <table *ngIf="newConnecttingVesselList.length > 0">
              <tr>
                <th>Vessel Code</th>
                <th>Vessel Name</th>
                <th>Valid</th>
              </tr>
              <tr *ngFor="let item of newConnecttingVesselList" (click)="selectedNewVesselname(item, i)">
                <td>{{item.vesselCode}}</td>
                <td>{{item.vesselName}}</td>
                <td>{{item.isValid}}</td>
              </tr>
            </table>
            <div *ngIf="newConnecttingVesselList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div class="table-search-al newValueVoyageName">
          <input type="text" autocomplete="off" disabled class="typeSplit-input" id="ValueNewVoyageName_{{ i }}"
            placeholder="Search..." (keyup.enter)="searchNewVoyageName($event, i)" (keyup)="keypressInputNewVoyageName($event, i)"
            value="{{item.newConnectingVoyage2}}">
          <i class="search icon"></i>
          <div class="valueVoyageName d-none" id="newValueVoyageName_{{ i }}">
            <table *ngIf="newConnecttingVoyageList.length > 0">
              <tr>
                <th>Voyage</th>
                <th>Bound</th>
                <th>Service</th>
              </tr>
              <tr *ngFor="let item of newConnecttingVoyageList" (click)="selectedNewVoyage(item, i)">
                <td>{{item.voyage}}</td>
                <td>{{item.bound}}</td>
                <td>{{item.serviceCode}}</td>
              </tr>
            </table>
            <div *ngIf="newConnecttingVoyageList.length === 0" class="no-results-search">
              <b>No Results</b>
              <span>Your search returned no results</span>
            </div>
          </div>
        </div>
      </td>
      <td>{{item.vesselPortOfCallEta}}</td>
      <td>{{item.vesselPortOfCallEta ? returnTime(item.vesselPortOfCallEta, item.dischargingVesselPotEtd) : ""}}</td>
    </tr>
  </table>
  <div class="btn-UnconfirmedList">
    <button class="ui button btn-no" (click)="closeUnconfirmedList()">Close</button>
    <button class="ui button btn-yes" (click)="confirmUnconfirmedList()">Confirm</button>
  </div>
</div>