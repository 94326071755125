import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { MSailingSchedule, MSailingScheduleDetail, AllocationModel, officeModel, specialCharUtil } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import 'rxjs/add/operator/pairwise';
import { count } from 'rxjs/operator/count';
import { leave } from '@angular/core/src/profile/wtf_impl';
import FormValidation from '../../utils/formValidation';
declare  var $:any;

@Component({
  selector: 'app-transaction-vessel-allocation',
  templateUrl: './transaction-vessel-allocation-page.component.html',
  styleUrls: ['./transaction-vessel-allocation-page.component.css']
})
export class TransactionVesselAllocationPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbpod') cbPortOfDischarge:ComboPluginComponent;
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridDetails') gridDetails:GridPluginComponent;
  @ViewChild('cbVesselCopy') cbVesselCopy:ComboPluginComponent;
  @ViewChild('cbVoyageCopy') cbVoyageCopy:ComboPluginComponent;
  @ViewChild('cbpoc') cbAllotmentType:ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGrid;
  settingGridDetails;
  settingAlloment;
  settingPOD;
  settingVesselCopy;
  settingVoyageCopy;

  // lock
  cButtonLock = true;
  dButtonLock = true;
  retLock = false;

  model = new MSailingSchedule;
  modelDetails = new AllocationModel;
  modelCopy = new MSailingSchedule;
  copyStructure:boolean = false;
  formLabel = "";
  firstForm = false;
  operator:string = "";
  allocationRecordId:string = "";
  poDischarge:string = "";
  dialog:any;
  loading = false;
  extVesselCapacity:number = 0;
  srcVesselCapacity:number = 0;
  hgridDetailsStore = [];
  modelOffice = new officeModel;
  sCharUtil = new specialCharUtil();
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  validatorRules = {
    vesselName : {
      rules:[
        {type:'empty', prompt:'Please input vessel name!'}
      ]
    },
    voyage : {
      rules:[
        {type:'empty', prompt:'Please input base voyage!'}
      ]
    },
    bound : {
      rules:[
        {type:'empty', prompt:'Please input Bound!'}
      ]
    }
  };

  formValidation = new FormValidation();

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private location:Location, private router:Router, private cook:CookieService) {
    super();
    this.router.events.pairwise().subscribe((e)=>{
      this.clearDialog();
    })


    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Save',  event: 'save', icon : 'checkmark' },
        { name : 'Copy',  event: 'copy', icon : 'copy' },
        { name : 'Clear',  event: 'clear', icon : 'remove' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Back',  event: 'back', icon : 'arrow left' },
        { name : 'Close',  event: 'close', icon : 'power' },

      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType           : 'label',
      label                 : 'Vessel Allocation'
    }

    this.settingGrid = {
      id : 'aHeaderGrid',
      url: '',//this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound",/*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/
      page: 5,
      columns: [
        {header: 'Seq No', field: 'seqno', width: 50},
        {header: 'Port Name', field: 'portofLoadingName', width: 200},
        {header: 'Port Code', field: 'portofLoading', width: 100},
        {header: 'Terminal Name', field: 'terminalCodeDischargeName', width: 200},
        {header: 'Terminal Code', field: 'terminalCodeDischarge', width: 100},
        {header: 'ETA', field: 'arrivalDateLoading', width: 200},
        {header: 'ETD', field: 'sailDateLoading', width: 200},
        {header: 'Call Reason', field: 'callReason', width: 200},

      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'no',
      sortingDirection: 'ASC',
    }

    this.settingGridDetails = {
      id : 'aDetailsGrid',
      url: '',
      page: 5,
      columns: [
        {header: 'Seq', field: 'seqNo', width: 50},
        {header: 'Level', field: 'level', width: 150},
        {header: 'Alloted By', field: 'allotedBy', width: 150,},
        {header: 'Allocated', field: 'allocated', width: 100, editType: 'number'},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Reserved', field: 'reserved', width: 100},
        {header: 'Available', field: 'available', width: 100}
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'seqNo',
      sortingDirection: 'ASC',
      editable: true
    }


    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 75}
      ],
      maxlength: 50
    }

    this.settingVesselCopy = {
      id          : 'cbVesselCopy',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 75}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 150},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150},
      ],
      maxlength: 20
    }

    this.settingVoyageCopy = {
      id          : 'cbVoyageCopy',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 150},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150}
      ]
    }

    this.settingAlloment = {
      id          : 'cAllot',
      type        : 'select input', // search | select | select input
      url         : 'assets/json/allotmentType.json',//this.configService.config.BASE_API.toString() + '/MasterLocation/searchLocation?q={query}',
      maxChars    : 3,
      placeholder : 'Allotment Type',
      code        : 'allotmentCode',
      title       : 'allotmentType',
      description  : '',
      flag        : ''
    }

    this.settingPOD = {
      id          : 'cbPortOfDischarge',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Port of Discharge',
      title       : 'portName',
      description  : '',
      columns     : [
        {header: 'Port Code', field: 'portCode', width: 200},
        {header: 'Port Name', field: 'portName', width: 200},
      ]
    }


  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('#gridHeader').find('th.collapsing').find('div.checkbox').remove();
    $('.dataTables_scrollBody').css('height','150px');
    var self = this;
    //this.grid.setModeRadio(true);
    //this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";

    this.currentMenuID = this.cook.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cook.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "save,copy,delete";
    }

    this.grid.search = '*/*/*';
    this.grid.onFirst();

    this.cbVoyage.disableCombo = true;
    this.cbVoyageCopy.disableCombo = true;
    this.disableToolbarButtons = "save,copy,delete";
    this.invisibleToolbarButtons = "back";

    this.modelOffice.officeCode = this.cook.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.modelOffice.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.modelOffice.officeName = this.cook.getCookie("defaultLocation").split("|")[0];

    if(localStorage.getItem("extVesselCapacity")!= null && localStorage.getItem("extVesselCapacity")!= undefined && localStorage.getItem("extVesselCapacity")!= '' && localStorage.getItem("extVesselCapacity")!= "0" ){
      let strParmer: string = localStorage.getItem("extVesselCapacity").replace('"','');
      this.extVesselCapacity = parseInt(strParmer);
    }else{
      this.extVesselCapacity = 0;
    }



    if(localStorage.getItem("allocation-from-sailing-schedule") != null && localStorage.getItem("allocation-from-sailing-schedule") != undefined && localStorage.getItem("allocation-from-sailing-schedule") != ""){
      this.disableToolbarButtons = "save,copy,delete";
      this.invisibleToolbarButtons = "";
      let strParams: string = localStorage.getItem("allocation-from-sailing-schedule");
      if(strParams != null){
        let par:any = JSON.parse(strParams);

        this.model.vesselId = par.vesselId;
        this.model.vesselCode = par.vesselCode;
        this.model.vesselName = par.vesselName;
        this.model.voyage =  localStorage.getItem("ssVoyage");
        this.model.bound = par.bound;

        this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
        //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findLikeVesselNameOrVesselIdAndIsValid/'+self.model.vesselId+'/*');
        //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
        this.cbVessel.setValue(String(this.model.vesselId).trim(),function(callbackVessel){
          self.model.vesselId = par.vesselId;
          self.model.vesselCode = String(par.vesselCode).trim();
          self.model.vesselName = par.vesselName;


          //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
          self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
          self.cbVoyage.disableCombo = false;
          //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage2/"+ self.model.vesselId+"/"+ self.model.bound.toUpperCase()+"/{query}");
          self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+ self.model.vesselId+"/"+ self.model.bound.toUpperCase()+"/"+self.modelOffice.officeCode+"/{query}");
          self.cbVoyage.setValue(self.model.voyage);

          setTimeout(function(){
            self.resetGrid();
            //self.retrieveEvent();
          },100);

        });

        //remove the local storage
        localStorage.removeItem("allocation-from-sailing-schedule");
        //localStorage.removeItem("ssVoyage");
        /*
        localStorage.removeItem("ssVesselCode");
        localStorage.removeItem("ssBound");
        */

      }



    }

    if(localStorage.getItem("set-allocation") != null){
      this.invisibleToolbarButtons = "";
      let strParams: string = localStorage.getItem("set-allocation");
      if(strParams != null){

        let par:any = JSON.parse(strParams);

        this.model.vesselId = par.vesselId;
        this.model.vesselCode = par.vesselCode;
        this.model.voyage = par.voyage;
        this.model.bound = par.bound;

        this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
        this.cbVessel.setValue(String(this.model.vesselCode).trim(),function(callbackVessel){
          self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

          self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+ self.model.vesselId+"/"+ self.model.bound.toUpperCase()+"/"+self.modelOffice.officeCode+"/{query}");
          self.cbVoyage.setValue(self.model.voyage);
        });


        localStorage.removeItem("set-allocation");
      }
    }
  }

  toolbarEvent(event) {
    //// // // console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.resetGrid();
        this.retrieveEvent();
        break;
      case 'save' :
        this.saveEvent();
        break;
      case 'clear' :
        this.clearEvent();
        break;
      case 'back' :
        this.backEvent();
        break;
      case 'close' :
        this.closeEvent();
        break;
      case 'copy':
        this.copyEvent();
        break;
    }
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case 'selected':
        break;
      case 'click' :
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split("-")[1];

        switch(mode){
          case 'checked' :
            this.selectedListMod(no, this.grid,'seqno');
            break;
          case 'unchecked' :
            break;
        }

        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':

              break;
            default:
              break;
          }

        }

        break;
    }

  }

  testRefresh(no, field, value){
    this.gridDetails.store.forEach(fe=>{
      if(fe.no == no){
        fe[field] = value;
      }
    });
    this.gridDetails.loadData();
  }

  gridEventDetails(event) {
    //// // // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        this.selectedEvent(event);
        break;
      case 'click' :
        this.selectedEvent(event);
        break;
      case 'AfterEdit' :
        var no = event.split(".")[1].split(";")[0];
        var field = event.split(".")[1].split(";")[1];
        var value = this.toFixed(parseFloat(event.split(".")[1].split(";")[2]));
        // console.log("====================", value));

        this.testRefresh(no, field, value);
        //this.updateGridDetails(no, this.gridDetails, value);
        this.updateGridDetails1a(no, this.gridDetails, value);
        this.gridDetails.loadData();
        this.gridDetails.setRenderValue(parseInt(no), field, "number",this.gridDetails.listStore.store[parseInt(no)-1].allocated)
        break;
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
              break;
          }
        }
        break;
    }

  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridDetails(event) {
    // // // // console.log(event);
    this.info = event;
  }

  updateVoyageUrl(type:string){
    switch(type){
      case "header" :
        this.updateVoyageUrl1a(this.cbVoyage, this.model);
        break;
      case "copy" :
        this.updateVoyageUrl1a(this.cbVoyageCopy, this.modelCopy);
        break;
    }
  }

  updateVoyageUrl1a(combo:ComboPluginComponent, dt:MSailingSchedule){
    if(dt.bound == ''){
      //combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage2/"+dt.vesselId+"/*/{query}");
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+dt.vesselId+"/*/"+this.modelOffice.officeCode+"/{query}");
    }else{
      //combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage2/"+dt.vesselId+"/"+dt.bound.toUpperCase()+"/{query}");
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+dt.vesselId+"/"+dt.bound.toUpperCase()+"/"+this.modelOffice.officeCode+"/{query}");
    }
  }

  changeEventVessel(event) {
    this.model['error-vesselName'] = "";
    this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";

    if(event.vesselId != null && event.vesselId != undefined && event.vesselId != ""){
      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage2/"+event.vesselId+"/"+this.model.bound.toUpperCase()+"/{query}");
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+event.vesselId+"/"+this.model.bound.toUpperCase()+"/"+this.modelOffice.officeCode+"/{query}");
      this.cbVoyage.setValue("");

      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      // // // console.log("eeq ultra");
      // // // console.log(this.model);



      if(event.capacity != null){
        this.extVesselCapacity = parseFloat(event.capacity);
      }

    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
    }

    //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
    this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
  }

  changeEventVesselCopy(event) {
    this.modelCopy['error-vesselName'] = "";

    if(event.vesselId != null){
      this.cbVoyageCopy.disableCombo = false;
      //this.cbVoyageCopy.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage2/"+event.vesselId+"/"+this.modelCopy.bound.toUpperCase()+"/{query}");
      this.cbVoyageCopy.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/allocationDistinctVoyage/"+event.vesselId+"/"+this.modelCopy.bound.toUpperCase()+"/"+this.modelOffice.officeCode+"/{query}");
      //this.cbVoyageCopy.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/{query}/SGSIN");
      this.cbVoyageCopy.setValue("");

      this.modelCopy.vesselId = event.vesselId;
      this.modelCopy.vesselCode = event.vesselCode;
      this.modelCopy.vesselName = event.vesselName;
      this.modelCopy.voyage = "";
    } else {
      this.modelCopy.vesselId = "";
      this.modelCopy.vesselCode = "";
      this.modelCopy.vesselName = "";
      this.modelCopy.voyage = "";
    }

    if(event.capacity != null){
      this.srcVesselCapacity = parseFloat(event.capacity);
    }
  }

  changeEventVoyage(event) {
    this.model['error-voyage'] = "";

    if(event.voyage != null){
      this.model.voyage = event.voyage;
      this.model.bound = event.bound;
    } else {
      this.model.voyage = "";
    }

  }

  retrieveHeader(){
    if(this.model.vesselId != '' && this.model.voyage != '' && this.model.bound != ''){

      var hitUrl = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctBound2/"+this.model.vesselId+"/"+this.sCharUtil.htmlEncode(this.model.voyage)+"/"+this.model.bound.toUpperCase()+"/"+this.modelOffice.officeCode;
      //var hitUrlHeader = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
      this.genericService.GET(hitUrl).subscribe((resp) =>{
        if(resp.ok){
          if(parseInt(resp.json()['size']) > 0){
            var result = resp.json()['content'][0];
            this.model.serviceCode = result['serviceCode'];
            this.model.localETA = result['localETA'];
            this.model.localETD = result['localETD'];

            //VS00287/TESTE1/I/CCU;
            //this.grid.url = hitUrlHeader;
            this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound2";
            this.grid.search = this.model.vesselId + "/" + this.sCharUtil.htmlEncode(this.model.voyage) + "/" + this.model.bound.toUpperCase() + "/" + this.model.serviceCode + "/"+this.modelOffice.officeCode;

            this.fillGrid();
            this.gridDetails.loading=true;
            this.gridDetails.onClear();
            this.loadAllocation(this.model, false, false);
            this.gridDetails.loadData();

            this.cbPortOfDischarge.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/getAllocationPOD2/"+this.model.vesselId + "/" + this.sCharUtil.htmlEncode(this.model.voyage) + "/" + this.model.bound.toUpperCase() + "/" + this.model.serviceCode + "/{query}/"+this.modelOffice.officeCode);
            this.cbPortOfDischarge.setValue("");
            //this.disableToolbarButtons = "save,copy,delete";
            this.retLock = true;
            this.cbVessel.disableCombo = true;
            this.cbVoyage.disableCombo = true;
          } else {
            this.model.serviceCode = '';
            this.model.localETA = '';
            this.model.localETD = '';
            //this.disableToolbarButtons = "retrieve,save,copy,cancel,delete";

            this.message('information', "Information","Bound "+this.model.bound.toUpperCase()+" is not found in vessel "+this.model.vesselName+" V."+this.model.voyage,"okonly",{ok:"// // // console.log('ok click')"});
          }
        }
      });
    }
  }

  changeEventVoyageCopy(event) {
    this.modelCopy['error-voyage'] = "";

    if(event.voyage != null){
      this.modelCopy.voyage = event.voyage;
      this.modelCopy.bound = event.bound;
    } else {
      this.modelCopy.voyage = "";
    }
  }

  getAllotment(event){
    // // // console.log(event);

    this.modelDetails.allotedBy = event.allotmentCode;

    switch(event.allotmentCode){
      case "SS-" :
        this.formLabel = "Operator";
        break;
      case "PD-" :
        this.formLabel = "Port of Discharge";
        break;
      default :
        this.formLabel = "";
        break;
    }
  }

  getPOD(event){
    // // // console.log(event);
    this.poDischarge = event.portCode;
  }

  selectedEvent(event){
    //// // // console.log(event);
    var no:number = 0;
    var opt = (event.split(".").length > 1 ? event.split(".")[1].split("-")[0] : event );
    var opt2 = (event.split(".").length > 1 ? event.split(".")[1].split("-")[1] : event );
    switch(opt){
      case 'checked':
        if(this.model.bound == "O"){
          this.cButtonLock = false;
          this.dButtonLock = false;
        }
        no = opt2;
        this.selectedListMod(no, this.gridDetails, 'seqNo');
        break;
      case 'unchecked':
        this.resetButton();
        no = opt2;
        break;
    }
  }

  retrieveEvent(){
    // // // console.log("retrieve Event");
    // // // console.log(this.model);
    let isValid:boolean = this.onValidate(this.model);
    // // // console.log("isValid => " + isValid);
    if(!isValid){
      this.retrieveHeader();
    }
  }

  fillGrid(){
    this.grid.loadData();
  }

  selectedListMod(value, store:GridPluginComponent, key){
    // // // console.log("no "+value);
    if(store.getSelectedValues().length > 1){
      store.getSelectedValues().forEach(ff=>{
        if(ff[key] != value){
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0,1);
    }
    // // // console.log("total checked "+store.getSelectedValues().length);
  }

  saveEvent(){
    var saveStore = {};
    saveStore['recordId'] = this.allocationRecordId;
    saveStore['vesselId'] = this.model.vesselId;
    saveStore['bound'] = this.model.bound.toUpperCase();
    saveStore['vesselCode'] = this.model.vesselCode;
    saveStore['voyage'] = this.model.voyage;
    saveStore['vesselAllocationDetails'] = [];
    saveStore['officeCode'] = this.modelOffice.officeCode;
    this.gridDetails.listStore.store.forEach(gl=>{
      var saveStoreDetails = {};
      saveStoreDetails['recordId'] = saveStore['recordId'];
      saveStoreDetails['recordSeq'] = gl.seqNo;
      saveStoreDetails['allotmentLevel'] = gl.level;
      saveStoreDetails['allotmentCode'] = (gl.level == "1" ? "VS" : gl.allotedBy);
      saveStoreDetails['alloted'] = gl.allocated;
      saveStoreDetails['booked'] = gl.booked;
      saveStoreDetails['reserved'] = gl.reserved;
      saveStoreDetails['userResumed'] = '';
      saveStoreDetails['dateResumed'] = '';
      saveStoreDetails['userStopped'] = '';
      saveStoreDetails['dateStopped'] = '';
      saveStoreDetails['isStopped'] = 'N';
      saveStore['vesselAllocationDetails'].push(saveStoreDetails);
    });

    console.log(saveStore);
    /*
    if(this.gridDetails.listStore.store.length == 1){
      // add SOC
      var saveStoreDetails = {};
      saveStoreDetails['recordId'] = saveStore['recordId'];
      saveStoreDetails['recordSeq'] = saveStore['vesselAllocationDetails'].length + 1;
      saveStoreDetails['allotmentLevel'] = "1.1";
      saveStoreDetails['allotmentCode'] = "SOC";
      saveStoreDetails['alloted'] = 0;
      saveStoreDetails['booked'] = 0;
      saveStoreDetails['reserved'] = 0;
      saveStoreDetails['userResumed'] = '';
      saveStoreDetails['dateResumed'] = '';
      saveStoreDetails['userStopped'] = '';
      saveStoreDetails['dateStopped'] = '';
      saveStoreDetails['isStopped'] = 'N';
      saveStore['vesselAllocationDetails'].push(saveStoreDetails);

      // add COC
      var saveStoreDetails = {};
      saveStoreDetails['recordId'] = saveStore['recordId'];
      saveStoreDetails['recordSeq'] = saveStore['vesselAllocationDetails'].length + 1;
      saveStoreDetails['allotmentLevel'] = "1.2";
      saveStoreDetails['allotmentCode'] = "COC";
      saveStoreDetails['alloted'] = 0;
      saveStoreDetails['booked'] = 0;
      saveStoreDetails['reserved'] = 0;
      saveStoreDetails['userResumed'] = '';
      saveStoreDetails['dateResumed'] = '';
      saveStoreDetails['userStopped'] = '';
      saveStoreDetails['dateStopped'] = '';
      saveStoreDetails['isStopped'] = 'N';
      saveStore['vesselAllocationDetails'].push(saveStoreDetails);

      // add SLOT-XX
      var saveStoreDetails = {};
      saveStoreDetails['recordId'] = saveStore['recordId'];
      saveStoreDetails['recordSeq'] = saveStore['vesselAllocationDetails'].length + 1;
      saveStoreDetails['allotmentLevel'] = "1.3";
      saveStoreDetails['allotmentCode'] = "SS-XX";
      saveStoreDetails['alloted'] = 0;
      saveStoreDetails['booked'] = 0;
      saveStoreDetails['reserved'] = 0;
      saveStoreDetails['userResumed'] = '';
      saveStoreDetails['dateResumed'] = '';
      saveStoreDetails['userStopped'] = '';
      saveStoreDetails['dateStopped'] = '';
      saveStoreDetails['isStopped'] = 'N';
      saveStore['vesselAllocationDetails'].push(saveStoreDetails);

    }
    */
    //// // // console.log("ojan ganteng "+this.checkAllocationValidationNewLogic("1", saveStore['vesselAllocationDetails']));

    // hit save url
    if(this.validatingData()){
      if(this.veryNewValidationLogic(saveStore['vesselAllocationDetails'])){
        if(this.checkAllocationValidationNewLogic("1", saveStore['vesselAllocationDetails'])){
          this.saveProcess(saveStore);
        }
      }
    }
  }

  validatingData(){
    var result = true;
    var pdList = [];
    var rMessage = "";
    if(this.gridDetails.listStore.store.length > 0){

      // checking port of call
      this.gridDetails.listStore.store.forEach(vad=>{
        if(vad.allotedBy.substr(0,3) == ("PD-")){
          if(!this.checkPort(vad.allotedBy)){
            pdList.push(vad.allotedBy.split("-")[1]);
          }
        }
      });

      if(pdList.length > 0){
        result = false;
        rMessage = this.model.vesselName + " " + this.model.voyage + " does not call : <br>" ;
        var i = 1;
        pdList.forEach(pp=>{
          rMessage = rMessage + i + ". " + pp + " <br>";
          i++;
        });
      }

      if(rMessage == ""){
        // checking entered value
        this.gridDetails.listStore.store.forEach(gl=>{
          if(rMessage == ""){
            switch(gl.seqNo){
              case "1":
                if(this.getChildAllocated(gl.level) > gl.allocated){
                  result = false;
                  rMessage = "Allocation exceed on Level "+ gl.level+" . Please check your data again!";
                }
                break;
              default :
                if(this.countChild2(gl.level, this.gridDetails.listStore.store) > 0){
                  if(this.getChildAllocated(gl.level) > gl.allocated){
                    result = false;
                    rMessage = "Allocation exceed on Level "+ gl.level+" . Please check your data again!";
                  }
                }
                break;
            }
          }
        });
      }

      if(rMessage == ""){
        var rz = [];
        this.gridDetails.listStore.store.forEach(gl=>{
          if(gl.allotedBy.substring(0,3) == "SOC" || gl.allotedBy.substring(0,3) == "COC" || gl.allotedBy.startsWith("SS-")){
            if (parseInt(gl.allocated) < parseInt("0")){
              rz.push(gl.level);
            }
          } else {
            if (parseInt(gl.allocated) < parseInt("1")){
              rz.push(gl.level);
            }
          }
        });

        if(rz.length > 0){
          result = false;
          rMessage = "level : <br>"
          var i = 1;
          rz.forEach(r=>{
            rMessage = rMessage + r + "<br>";
          });

          rMessage = rMessage + "Alloted amount must be greater than zero. Please check data.";
        }
        // // // console.log(rMessage);
      }

    }

    if(rMessage != ""){
      this.message("information","Information",rMessage, "okonly", {ok:""});
    }

    return result;
  }

  checkPort(allotmentCode:string){
    var isExist = false;
    this.grid.listStore.store.forEach(sl=>{
      if(allotmentCode.split("-")[1] == sl.portofLoading){
        isExist = true;
      }
    });
    return isExist;
  }

  saveProcess(data){
    this.loading = true;
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/VesselAllocation/save',data).subscribe((resp) =>{
      if(resp.status == 200){
        //clear all
        this.message('information', "Information","Record save sucessfully.","okonly",{ok:"this.saveSuccessFull()"});
        this.loading = false;
        this.info = 'save successfully';
        if(this.gridDetails.listStore.store.length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "save,copy,delete";
          }else{
            this.disableToolbarButtons = "retrieve,copy,delete";
          }
        } else {
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "save,copy,delete";
          }else{
            this.disableToolbarButtons = "retrieve,delete";
          }
        }

        //this.grid.onSortDir('dateCreated','DESC');
        //this.grid.onFirst();
      }
    },error => {
      this.loading = false;
      this.message('information', "Information", "There are some error occur while saving the record, error message : "+error.json()['message']+". Please contact your administrator!", "okonly", {ok:""});
    });
  }

  saveSuccessFull(){
    this.clearEvent2();
  }

  loadAllocation(data, copy: boolean, copyStruct: boolean) {

    //this.cButtonLock = false;
    var store = [], storeDetails = [], bookedAllocation = [];
    var hitUrl = this.configService.config.BASE_API.toString() + "/VesselAllocation/findByVesselIDVoy/" + data.vesselId + "/" + this.sCharUtil.htmlEncode(data.voyage) + "/" + data.bound + "/" + this.modelOffice.officeCode;
    var details = null;
    var bookedValue = {}, reservedValue = {};
    this.genericService.GET(hitUrl).subscribe((resp) =>{
      if(resp.ok){
        store = resp.json()['content'];

        //noyan patch add bookied allocation

        if(!copy && !copyStruct){
          var bookURL = this.configService.config.BASE_API.toString() + "/MasterBookings/calculateAllocation3new"
          var body = {};

          body["bOceanVesselId"] = data.vesselId;
          body["bOceanVesselVoyage"] = data.voyage;
          body["bOceanVesselBound"] = data.bound;
          body["bOfficeCode"] = this.modelOffice.officeCode;

          this.genericService.POST(bookURL, body).subscribe((resp) => {
            if(resp.ok){
              // // // console.log("ok");
              this.gridDetails.onClear();
              bookedAllocation = resp.json()['content'];
              bookedAllocation.forEach(fe=>{
                bookedValue[fe['allotmentLevel'] ] = fe['booked'];
                reservedValue[fe['allotmentLevel']] = fe['reserved'];
              });

              store.forEach(ss=>{
                this.allocationRecordId = ss['recordId'];
                if(ss['vesselAllocationDetails'].length > 0){
                  storeDetails = ss['vesselAllocationDetails'];
                  storeDetails.forEach(sd=>{
                    details = new AllocationModel;
                    details.vesselCode = ss['vesselCode'];
                    details.voyage = ss['voyage'];
                    details.bound = ss['bound'];
                    details.allocationId = ss['recordId'];
                    details.seqNo = sd['recordSeq'];
                    details.level = sd['allotmentLevel'];
                    details.allotedBy = (sd['allotmentLevel'] == "1" ? this.model.vesselName : sd['allotmentCode']);
                    details.allocated = (copyStruct ? (sd['allotmentLevel'] == "1" ? this.extVesselCapacity : 0 ) : parseInt(sd['alloted']));
                    details.booked = (copy ? 0 : /*parseInt(sd['booked'])*/ parseInt(bookedValue[ sd['allotmentLevel'] ]) );
                    details.reserved = (copy ? 0 : /*parseInt(sd['reserved'])*/ parseInt(reservedValue[ sd['allotmentLevel'] ]) );

                    details.available = details.allocated - (details.booked + details.reserved);
                    details.allocatedH = details.allocated;
                    this.gridDetails.listStore.store.push(details);
                  });
                }
              });
              this.gridDetails.loadData();
              this.gridDetails.loading = false;
              if(this.gridDetails.listStore.store.length == 0){
                if(this.model.bound == "O"){
                  this.cButtonLock = false;
                }
                if(this.model.bound != "O"){
                  this.disableToolbarButtons = "retrieve,save,copy,delete";
                } else {
                  if(this.viewOnlyStatus){
                    this.disableToolbarButtons = "save,copy,delete";
                  }else{
                    this.disableToolbarButtons = "retrieve,delete";
                  }
                }
              } else {
                if(this.viewOnlyStatus){
                  this.disableToolbarButtons = "save,copy,delete";
                }else{
                  this.disableToolbarButtons = "retrieve,copy,delete";
                }
              }


            }
          });
        } else {
          store.forEach(ss=>{
            // // // console.log("size details : "+ss['vesselAllocationDetails'].length);
            if(!copy && !copyStruct){
              this.allocationRecordId = ss['recordId'];
            }
            if(ss['vesselAllocationDetails'].length > 0){
              storeDetails = ss['vesselAllocationDetails'];
              storeDetails.forEach(sd=>{
                details = new AllocationModel;
                details.vesselCode = ss['vesselCode'];
                details.voyage = ss['voyage'];
                details.bound = ss['bound'];
                details.allocationId = ss['recordId'];
                details.seqNo = sd['recordSeq'];
                details.level = sd['allotmentLevel'];
                details.allotedBy = (sd['allotmentLevel'] == "1" ? this.model.vesselName : sd['allotmentCode']);
                details.allocated = (copyStruct ? (sd['allotmentLevel'] == "1" ? this.extVesselCapacity : 0 ) : parseInt(sd['alloted']));
                details.booked = (copy ? 0 : parseInt(sd['booked']) );
                details.reserved = (copy ? 0 : parseInt(sd['reserved']));

                details.available = details.allocated - (details.booked + details.reserved);
                details.allocatedH = details.allocated;
                this.gridDetails.listStore.store.push(details);
              });
            }
          });
          this.gridDetails.loadData();
          this.gridDetails.loading = false;
          if(this.gridDetails.listStore.store.length == 0){
            if(this.model.bound == "O"){
              this.cButtonLock = false;
            }
            if(this.model.bound != "O"){
              this.disableToolbarButtons = "retrieve,save,copy,delete";
            } else {
              if(this.viewOnlyStatus){
                this.disableToolbarButtons = "save,copy,delete";
              }else{
                this.disableToolbarButtons = "retrieve,delete";
              }
            }
          } else {
            if(this.viewOnlyStatus){
              this.disableToolbarButtons = "save,copy,delete";
            }else{
             this.disableToolbarButtons = "retrieve,copy,delete";
            }
          }
        }

        // end patch

      }
    });
  }

  addAllocation(){
    this.firstForm = false;

    this.modelDetails = new AllocationModel;
    if(this.gridDetails.listStore.store.length == 0){
      this.firstForm = true;
      this.modelDetails.vesselCode = this.model.vesselCode;
      this.modelDetails.voyage = this.model.voyage;
      this.modelDetails.bound = this.model.bound;
      this.modelDetails.allocationId = "";
      this.modelDetails.seqNo = "1";
      this.modelDetails.level = "1";
      this.model.vesselName= this.model.vesselName.replace('&nbsp;',' ');
      this.modelDetails.allotedBy = this.model.vesselName;
      if(this.extVesselCapacity != 0){
        this.modelDetails.allocated = this.extVesselCapacity;
      } else {
        this.modelDetails.allocated = 0;
      }
      this.modelDetails.booked = 0;
      this.modelDetails.reserved = 0;
      this.modelDetails.available = this.modelDetails.allocated - (this.modelDetails.booked + this.modelDetails.reserved);

      this.showDialog('#addAllocDialog');
    } else if(this.gridDetails.listStore.store.length != 0){
      if(this.gridDetails.getSelectedValues().length == 0){
        this.message("information","information","Please choose 1 record to add allocation row!","okonly",{ok:""});
      } else {
        var parent = this.gridDetails.getSelectedValues()[0].level;
        // // // console.log('selected allocated '+this.gridDetails.getSelectedValues()[0].allocated + ' getChildAllocated '+this.getChildAllocated(parent));
        this.modelDetails.groupAvailable = this.gridDetails.getSelectedValues()[0].allocated - this.getChildAllocated(parent);
        this.modelDetails.parent = (this.getParentChildSeq(parent) == "0" ? this.gridDetails.getSelectedValues()[0].seqNo : this.getParentChildSeq(parent));
        this.modelDetails.parentLevel = parent;
        // // // console.log("group availbale "+this.modelDetails.groupAvailable);
        //count parent child
        this.modelDetails.level = parent + "." + (this.countChild(parent) + 1).toString();
        this.modelDetails.vesselCode = this.model.vesselCode;
        this.modelDetails.voyage = this.model.voyage;
        this.modelDetails.bound = this.model.bound;
        this.modelDetails.allocationId = this.allocationRecordId;
        this.modelDetails.seqNo = (this.gridDetails.listStore.store.length + 1).toString();
        this.cbAllotmentType.setValue("");
        this.formLabel = '';

        this.showDialog('#addAllocDialog');
      }
    }
  }

  countChild(parent){
    var childCount = 0;
    this.gridDetails.listStore.store.forEach(gs=>{
      if(this.getParentLevel(gs.level) == parent){
        childCount++;
      }

      // if(gs.level.substr(0, parent.length) == parent){
      //   if(gs.level.split(".").length == (parent.split(".").length + 1)){
      //     childCount++;
      //   }
      // }
    });
    // // // console.log("parent : "+parent+" child : "+childCount);
    return childCount;
  }

  countChild2(level, store:any[]){
    var result = 0;
    store.forEach(ss=>{
      if(this.getParentLevel(ss.level) == level){
        result++;
      }

      // if(ss.level.substr(0, level.length) == level){
      //   if(ss.level.split(".").length == (level.split(".").length + 1)){
      //     result++;
      //   }
      // }
    });

    return result;
  }

  getChildList(level, store:any[]){
    var result = [];
    store.forEach(ss=>{
      if(ss.level == level){
        result.push(ss);
      }
      if(this.getParentLevel(ss.level) == level){
        result.push(ss);
      }

      // if(ss.level.startsWith(level)){
      //   result.push(ss);
      // }
    });

    return result;
  }

  getParentChildSeq(parent){
    var seq = "0";
    this.gridDetails.listStore.store.forEach(gs=>{
      if(this.getParentLevel(gs.level) == parent){
        seq = gs.seqNo;
      }

      // if(gs.level.substr(0, parent.length) == parent){
      //   if(gs.level.split(".").length == (parent.split(".").length + 1)){
      //     seq = gs.seqNo;
      //   }
      // }
    });

    if(parent == "1"){
      seq = this.gridDetails.listStore.store.length.toString();
    }
    return seq;
  }

  getChildAllocated(parent){
    var totalAlloted = 0;
    this.gridDetails.listStore.store.forEach(gs=>{
      if(this.getParentLevel(gs.level) == parent){
        totalAlloted = parseFloat(totalAlloted.toString()) + parseFloat(gs.allocated.toString());
      }

      // if(gs.level.substr(0, parent.length) == parent){
      //   if(gs.level.split(".").length == (parent.split(".").length + 1)){
      //     totalAlloted = parseFloat(totalAlloted.toString()) + parseFloat(gs.allocated.toString());
      //   }
      // }
    });

    return totalAlloted;
  }

  allocationChecking(){
    var result:string = "";
    //// // // console.log('amount allocated '+this.modelDetails.allocated+' group available '+this.modelDetails.groupAvailable+' result '+(this.modelDetails.groupAvailable - this.modelDetails.allocated).toString());
    if(this.modelDetails.level == "1"){
    } else {
      if(this.modelDetails.allotedBy == ""){
        result = "Please insert allotment type.";
      } else if(this.modelDetails.allotedBy == "SS-" && this.operator.trim() == ""){
        result = "Please insert Slot Operator code.";
      } else if(this.modelDetails.allotedBy == "SS-" && this.operator.trim() != "" && this.operator.length < 2){
        result = "Invalid Slot Operator code, minimum length of Slot Operator code is 2 character";
      } else if(this.modelDetails.allotedBy == "PD-" && this.poDischarge == ""){
        result = "please insert port of discharge";
      } else if((this.modelDetails.groupAvailable - this.modelDetails.allocated) < 0){
        result = "Amount Entered Exceeds Allocated";
      }
    }

    return result;
  }

  checkLevelStruct(level, data){
    var result = true;
    var mapSource = {};
    mapSource["SOC"] = 1;
    mapSource["SOC LOCAL"] = 1;
    mapSource["SOC T/S"] = 1;
    mapSource["SS-"] = 1;
    mapSource["COC"] = 1;
    mapSource["COC LOCAL"] = 1;
    mapSource["COC T/S"] = 1;
    mapSource["PD-"] = 3;
    mapSource["LADEN"] = 5;
    mapSource["EMPTY"] = 5;

    var sData = this.copyStore(data);

    var childList = this.getChildListNew2(level, sData,"level");
    var check = -1;
    childList.forEach(x=>{
      var allotedByNew = "";
      if(x.allotedBy.startsWith("PD") || x.allotedBy.startsWith("SS")){
        allotedByNew = (x.allotedBy.startsWith("PD") ? "PD-" : (x.allotedBy.startsWith("SS")? "SS-" : "") );
      } else {
        allotedByNew = x.allotedBy;
      }

      if(mapSource[allotedByNew] != null){
        if(check == -1){
          check = mapSource[allotedByNew];
        } else {
          if(mapSource[allotedByNew] != check){
            result = false;
          }

          if(result){
            check = mapSource[allotedByNew];
          }
        }
      } else {
        result = false;
        check = 0;
      }

      if(result){
        if(this.countChildNew2(x.level, sData, "level") > 1){
          result = this.checkLevelStruct(x.level, data);
        }
      }

    });

    return result;
  }

  checkAllotmentFromCore(seq, data){
    var result = 0;
    var mapSource = {};
    var mapCheck = {};
    var mapIndex = {};
    mapSource["SOC"] = 1;
    mapSource["SOC LOCAL"] = 1;
    mapSource["SOC T/S"] = 1;
    mapSource["SS-"] = 1;
    mapSource["COC"] = 1;
    mapSource["COC LOCAL"] = 1;
    mapSource["COC T/S"] = 1;
    mapSource["PD-"] = 3;
    mapSource["LADEN"] = 5;
    mapSource["EMPTY"] = 5;

    mapCheck["3"] = [];
    mapCheck["5"] = [];
    mapCheck["7"] = [];
    mapCheck["9"] = [];

    mapIndex["2"] = "3";
    mapIndex["3"] = "5";
    mapIndex["4"] = "7";
    mapIndex["5"] = "9";

    var mapLevel = {};
    var mapCheckLevel = {};

    this.gridDetails.listStore.store.forEach(gl=>{
      mapLevel[gl.allotedBy] = gl.level;
      mapCheckLevel[gl.level] = gl.allotedBy;
    });

    var mapCheckDouble = [];

    if(this.gridDetails.listStore.store.length > 2){
      var penambah = 1;
      this.gridDetails.listStore.store.forEach(gl=>{
        console.log(gl);
        if(gl.level.length > 1){
          //mapCheck[mapIndex[(gl.level.split(".").length).toString()]].push(gl.allotedBy);
          if(mapCheckDouble[this.getParentLevel(gl.level)] == null){
            mapCheckDouble[this.getParentLevel(gl.level)] = [];
          }
          mapCheckDouble[this.getParentLevel(gl.level)].push(gl.allotedBy);
        }
      });
    }

    console.log("sompreto");
    console.log(mapCheckDouble);
    var message = "";
    for(var key in mapCheckDouble){
      console.log("key "+key);
      var mapCheckLoop = {};
      for(var i = 0;i < mapCheckDouble[key].length;i++){
        // // // console.log("i "+i);
        // // // console.log("value "+mapCheckDouble[key][i]);
        if(mapCheckLoop[mapCheckDouble[key][i]] == null){
          mapCheckLoop[mapCheckDouble[key][i]] = mapCheckDouble[key][i];
        } else {
          /*
          message = message + "The next level of(";
          message = message + key;
          message = message + ") already has a grouping, addition not allowed.";
          */
          message = message + mapCheckDouble[key][i].replace("PD-","POD-") + " already exist, please check allocation.";
          break;
        }
      }
    }

    var levelCheck = "";

    console.log("map check level");
    console.log(mapCheckLevel);
    for(var key in mapCheckLevel){
      var level = key;
      //var levelLength = level.length;
      var levelLength = level.split(".").length;
      if(level.length != 1){
        var pengurang = 2;
        while(levelLength > 1){
          //console.log("value check "+mapCheckLevel[key]+" value before/parent "+mapCheckLevel[level.substr(0,level.length-pengurang)] + " res " + mapCheckLevel[key].startsWith("SOC "));
          /*if(mapCheckLevel[key] == "LADEN" && (mapCheckLevel[level.substr(0,level.length-pengurang)] == "EMPTY" || mapCheckLevel[level.substr(0,level.length-pengurang)] == "LADEN" )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "EMPTY" && (mapCheckLevel[level.substr(0,level.length-pengurang)] == "EMPTY" || mapCheckLevel[level.substr(0,level.length-pengurang)] == "LADEN") ){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("PD-") && mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("PD-") ){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "COC" && (mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("SS-") && (mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "SOC" && (mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("COC ") && (mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("COC ") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("SOC ") && (mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("SOC ") || mapCheckLevel[level.substr(0,level.length-pengurang)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          }*/

          if(mapCheckLevel[key] == "LADEN" && (mapCheckLevel[this.getParentLevel(level)] == "EMPTY" || mapCheckLevel[this.getParentLevel(level)] == "LADEN" )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "EMPTY" && (mapCheckLevel[this.getParentLevel(level)] == "EMPTY" || mapCheckLevel[this.getParentLevel(level)] == "LADEN") ){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("PD-") && mapCheckLevel[this.getParentLevel(level)].startsWith("PD-") ){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "COC" && (mapCheckLevel[this.getParentLevel(level)].startsWith("SS-") || mapCheckLevel[this.getParentLevel(level)].startsWith("SOC") || mapCheckLevel[this.getParentLevel(level)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("SS-") && (mapCheckLevel[this.getParentLevel(level)].startsWith("SS-") || mapCheckLevel[this.getParentLevel(level)].startsWith("SOC") || mapCheckLevel[this.getParentLevel(level)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key] == "SOC" && (mapCheckLevel[this.getParentLevel(level)].startsWith("SS-") || mapCheckLevel[this.getParentLevel(level)].startsWith("SOC") || mapCheckLevel[this.getParentLevel(level)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("COC ") && (mapCheckLevel[this.getParentLevel(level)].startsWith("SS-") || mapCheckLevel[this.getParentLevel(level)].startsWith("SOC") || mapCheckLevel[this.getParentLevel(level)].startsWith("COC ") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          } else if( mapCheckLevel[key].startsWith("SOC ") && (mapCheckLevel[this.getParentLevel(level)].startsWith("SS-") || mapCheckLevel[this.getParentLevel(level)].startsWith("SOC ") || mapCheckLevel[this.getParentLevel(level)].startsWith("COC") )){
            //levelCheck = level.substr(0, level.length - 2);
            levelCheck = mapCheckLevel[key];
          }

          levelLength = levelLength - 1;
          pengurang = pengurang + 2;
        }
      }
    }



    /*for(var key in mapCheckLevel){
      var level = key;
      if(level.length > 3){
        // // // console.log("level "+level);
        console.log("value check "+mapCheckLevel[key]+" value before/parent "+mapCheckLevel[level.substr(0,level.length-2)] + " res " + mapCheckLevel[key].startsWith("SOC "));
        if(mapCheckLevel[key] == "LADEN" && (mapCheckLevel[level.substr(0,level.length-2)] == "EMPTY" || mapCheckLevel[level.substr(0,level.length-2)] == "LADEN" )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key] == "EMPTY" && (mapCheckLevel[level.substr(0,level.length-2)] == "EMPTY" || mapCheckLevel[level.substr(0,level.length-2)] == "LADEN") ){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key].startsWith("PD-") && mapCheckLevel[level.substr(0,level.length-2)].startsWith("PD-") ){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key] == "COC" && (mapCheckLevel[level.substr(0,level.length-2)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("COC") )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key].startsWith("SS-") && (mapCheckLevel[level.substr(0,level.length-2)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("COC") )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key] == "SOC" && (mapCheckLevel[level.substr(0,level.length-2)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("COC") )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key].startsWith("COC ") && (mapCheckLevel[level.substr(0,level.length-2)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("SOC") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("COC ") )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        } else if( mapCheckLevel[key].startsWith("SOC ") && (mapCheckLevel[level.substr(0,level.length-2)].startsWith("SS-") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("SOC ") || mapCheckLevel[level.substr(0,level.length-2)].startsWith("COC") )){
          //levelCheck = level.substr(0, level.length - 2);
          levelCheck = mapCheckLevel[key];
        }
      }
    }*/

    console.log("sapii");
    console.log(levelCheck);
    if(!(levelCheck == null || levelCheck == "")){
      /*
      message = message + "The next level of(";
      message = message + levelCheck;
      message = message + ") already has a grouping, addition not allowed.";
      */
      //message = message + levelCheck + " already has a grouping, addition not allowed."
      message = message + "Incompatible allotment type, please check allocation.";
    }

    console.log("cek map");
    console.log(mapCheck);
    console.log(mapSource);
    console.log("oi oi "+this.checkLevelStruct("1", this.gridDetails.listStore.store));

    if(message == null || message == ""){
      if(!this.checkLevelStruct("1", this.gridDetails.listStore.store)){
        message = "Incompatible allotment type, please check allocation.";
      }
    }

    /*if(message == null || message == ""){
      for(var key in mapCheck){
        for(var i = 0; i<mapCheck[key].length; i++){
          if(mapCheck[key][i].startsWith("PD-")){
            mapSource[mapCheck[key][i]] = 3;
          }

          if(mapCheck[key][i].startsWith("SS-")){
            mapSource[mapCheck[key][i]] = 1;
          }

          if(i != 0){
            if( mapSource[mapCheck[key][i]] != mapSource[mapCheck[key][i-1]] ){
              console.log("key "+key+" i "+i);
              message = message + "Unable to proceed with addition as new allotment code (";
              message = message + "";
              message = message + ") does not belong to this grouping:";
              break;
            }
          }
        }
        if(!(message == null || message == "")){
          console.log(message);
          console.log("kk");
          for(var i = 0; i< mapCheck[key].length;i++){
            message = message + "[";
            message = message + mapLevel[mapCheck[key][i]];
            message = message + "]";
            message = message + mapCheck[key][i];
          }
          message = "Incompatible allotment type, please check allocation.";
          break;
        }

        console.log("key" +key);
        console.log(message);
      }
    }*/

    // // // console.log("message "+message);
    if(message != ""){
      this.gridDetails.listStore.store.splice(0, this.gridDetails.listStore.store.length);
      this.gridDetails.listStore.store = data;
      this.message("information","information",message,"okonly",{ok:"this.openDialogAddRow()"});
      result = 1;
    }

    return result;
  }

  getChildListNew3(parentLevel:string, store:any, levelNameField:string){
    var result = [];
    store.forEach(fe=>{
      if(this.getParentLevel(fe[levelNameField]) == parentLevel){
        result.push(fe);
      }

      // if(fe[levelNameField].startsWith(parentLevel) && fe[levelNameField].split(".").length == parentLevel.split(".").length+1){
      //   result.push(fe);
      // }
    });

    return result;
  }

  nojanTest(level:string, store:any, label:string, storeDestination:any, modelDetails){
    var result = this.copyStore(storeDestination);
    console.log(result);
    var arr = this.getChildListNew2(level, store, label);
    console.log("hoom");
    console.log(arr);
    arr.forEach(fe=>{
      result.push(fe);
      console.log("fe level " + fe.level + " model detail parent "+this.getParentLevel(modelDetails.level));
      if(this.countChildNew2(fe.level, store, label) > 0){
        result = this.nojanTest(fe.level, store, label, result, modelDetails);
      }
    });

    return result;
  }

  addAllotmentRow(){
    this.modelDetails.available = this.modelDetails.allocated - (this.modelDetails.booked + this.modelDetails.reserved);
    this.modelDetails.allocatedH = this.modelDetails.allocated;

    var msg = this.allocationChecking();
    var res = 0;
    if(msg == ""){
      if(this.modelDetails.allocated > 0 || (this.modelDetails.allotedBy.startsWith("SS-") || this.modelDetails.allotedBy.substring(0,3) == "SOC" || this.modelDetails.allotedBy.substring(0,3) == "COC" )){
        if(this.modelDetails.level == "1"){
          this.gridDetails.listStore.store.push(this.modelDetails);
          this.modelDetails = new AllocationModel();
          this.modelDetails.seqNo = String(this.gridDetails.listStore.store.length + 1)
          this.modelDetails.level = "1.1"
          this.modelDetails.allotedBy = "SOC"
          this.modelDetails.allocated = 0;
          this.gridDetails.listStore.store.push(this.modelDetails);

          this.modelDetails = new AllocationModel();
          this.modelDetails.seqNo = String(this.gridDetails.listStore.store.length + 1)
          this.modelDetails.level = "1.2"
          this.modelDetails.allotedBy = "COC"
          this.modelDetails.allocated = 0;
          this.gridDetails.listStore.store.push(this.modelDetails);

          this.modelDetails = new AllocationModel();
          this.modelDetails.seqNo = String(this.gridDetails.listStore.store.length + 1)
          this.modelDetails.level = "1.3"
          this.modelDetails.allotedBy = "SS-XX"
          this.modelDetails.allocated = 0;
          this.gridDetails.listStore.store.push(this.modelDetails);


          this.cButtonLock = true;
        } else {

          switch(this.modelDetails.allotedBy.substring(0,3)){
            case 'SS-' :
              this.modelDetails.allotedBy = this.modelDetails.allotedBy.substring(0,3) + this.operator.toUpperCase();
              break;
            case 'PD-' :
              this.modelDetails.allotedBy = this.modelDetails.allotedBy.substring(0,3) + this.poDischarge;
          }
          var tempStore = [];
          var buStore = [];
          var nseq = 1;
          var mdSeq = "";

          var cr = this.copyStore(this.gridDetails.listStore.store);
          buStore = this.copyStore(this.gridDetails.listStore.store);
          cr.push(this.modelDetails);
          mdSeq = this.modelDetails.seqNo;

          var sompreTo = [];
          cr.forEach(fe=>{
            if(fe.level == "1"){
              sompreTo.push(fe);
              console.log("first run");
              sompreTo = this.nojanTest(fe.level, cr, "level", sompreTo, this.modelDetails);
            }
          });
          console.log("menjadi");
          console.log(sompreTo);

          tempStore = this.copyStore(sompreTo);

          /*this.gridDetails.listStore.store.forEach(ff=>{
            buStore.push(ff);
            //ff.seqNo = (nseq).toString();
            tempStore.push(ff);
            if(ff.seqNo == this.modelDetails.parent){
              mdSeq = this.modelDetails.seqNo;
              tempStore.push(this.modelDetails);
            }
          });*/

          this.gridDetails.listStore.store.splice(0, this.gridDetails.listStore.store.length);
          //this.gridDetails.listStore.store = tempStore;
          tempStore.forEach(fe=>{
            this.gridDetails.listStore.store.push(fe);
          })

          var buSeq = 1;
          buStore.forEach(bu=>{
            bu.seqNo = buSeq;
            buSeq = buSeq + 1;
          });

          res = this.checkAllotmentFromCore(mdSeq, buStore);

          //tidy the seqno
          buSeq = 1;
          this.gridDetails.listStore.store.forEach(gl=>{
            gl.seqNo = buSeq;
            buSeq = buSeq + 1;
          });
        }

        this.gridDetails.loadData();
        if(this.gridDetails.listStore.store.length == 0){
          this.cButtonLock = false;
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "save,copy,delete";
          }else{
            this.disableToolbarButtons = "retrieve,delete";
          }
        } else {
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "save,copy,delete";
          }else{
            this.disableToolbarButtons = "retrieve,copy,delete";
          }
        }
        //this.gridDetails.clearSelectedValues();
        //this.closeDialog("#addAllocDialog");
        if(res == 0){
          this.cancelAddAllotment();
        }
      } else {
        this.message("information","information","Amount Entered must greater than zero","okonly",{ok:'this.openDialogAddRow()'});
      }
    } else {
      this.message("information","information",msg,"okonly",{ok:'this.openDialogAddRow()'});
    }

  }

  openDialogAddRow(){
    this.showDialog('#addAllocDialog');
    // // // console.log("kol nggak jalan idiot "+$("#addAllocDialog").find("#iAlloted").length);
    $("#addAllocDialog").find("#iAlloted").focus();
  }

  removeRow(){
    var tempStore = [];
    if(this.gridDetails.getSelectedValues().length == 0){
      this.message("information","information","Please choose 1 record to remove a row!","okonly",{ok:""});
    } else {

      if(this.gridDetails.getSelectedValues()[0].booked > 0){
        this.message("information","information","Booking already taken for " + this.gridDetails.getSelectedValues()[0].level + " " +this.gridDetails.getSelectedValues()[0].allotedBy + ". Unable to remove this allocation.","okonly",{ok:""});
      }else{
        this.gridDetails.listStore.store.forEach(gl=>{
          gl["allotmentLevel"] = gl.level;
          tempStore.push(gl);
        });

        var rSeqNo = this.gridDetails.getSelectedValues()[0].seqNo;
        var parent = "";
        var childList = [];
        if(this.countChild(this.gridDetails.getSelectedValues()[0].level) > 0){
          childList = this.getChildList(this.gridDetails.getSelectedValues()[0].level, tempStore);
          childList.forEach(cl=>{
            tempStore.splice(tempStore.indexOf(cl),1);
          });
        } else {
          tempStore.splice(tempStore.indexOf(this.gridDetails.getSelectedValues()[0]),1);
        }

        // fix level
        if(tempStore.length > 0){
          var newTempStore = [];
          var vesselChildLevel = this.getChildListNew("1", tempStore);
          // // // console.log("vessel child");
          // // // console.log(vesselChildLevel);
          newTempStore.push(tempStore[0]);
          var i = 1;
          vesselChildLevel.forEach(fe=>{
            if(this.countChildNew(fe.level, tempStore) > 0){
              var childList = this.getChildListNew(fe.level, tempStore);
              // fe.level = fe.level.substr(0, fe.level.length-2) + "." + i;
              fe.level = this.getParentLevel(fe.level) + "." + i;
              newTempStore.push(fe);
              var y = 1;
              childList.forEach(cl=>{
                if(this.countChildNew(cl.level, tempStore) > 0){
                  var childList2 = this.getChildListNew(cl.level, tempStore);
                  cl.level = fe.level + "." + y;
                  newTempStore.push(cl);
                  var z = 1;
                  childList2.forEach(cl2=>{
                    cl2.level = cl.level + "." + z;
                    newTempStore.push(cl2);
                  });
                } else {
                  cl.level = fe.level + "." + y;
                  newTempStore.push(cl);
                }
                y++;
              });

            } else {
              // fe.level = fe.level.substr(0, fe.level.length-2) + "." + i;
              fe.level = this.getParentLevel(fe.level) + "." + i
              newTempStore.push(fe);
            }
            i++;
          });

          // // // console.log("new temp Store after fix");
          // // // console.log(newTempStore);

          /*
          for(var i=0;i<tempStore.length;i++){
            if(parseInt(tempStore[i].seqNo) > 1){
              // // // console.log("fix level "+tempStore[i].level+"->"+tempStore[i].level.substr(0,tempStore[i].level.length-2));
              tempStore[i].level = tempStore[i].level.substr(0,tempStore[i].level.length-2) + "." + (this.countChild2(tempStore[i].level.substr(0,tempStore[i].level.length-2), newTempStore) + 1).toString();
              newTempStore.push(tempStore[i]);
            } else {
              newTempStore.push(tempStore[i]);
            }
          }*/

          this.gridDetails.listStore.store.splice(0, this.gridDetails.listStore.store.length);
          this.gridDetails.listStore.store = newTempStore;
        } else {
          this.gridDetails.listStore.store.splice(0, this.gridDetails.listStore.store.length);
        }
      }
    }

    //fix sequence
    var nseq = 1;
    this.hgridDetailsStore.splice(0,this.hgridDetailsStore.length);
    this.gridDetails.listStore.store.forEach(ls=>{
      ls.seqNo = nseq;
      nseq = nseq + 1;
      this.hgridDetailsStore.push(ls);
    });

    this.gridDetails.loadData();
    this.gridDetails.clearSelectedValues();
    this.resetButton();
    if(this.gridDetails.listStore.store.length == 0){
      this.cButtonLock = false;
      if(this.viewOnlyStatus){
        this.disableToolbarButtons = "save,copy,delete";
      }else{
        this.disableToolbarButtons = "retrieve,delete";
      }
    } else {
      if(this.viewOnlyStatus){
        this.disableToolbarButtons = "save,copy,delete";
      }else{
        this.disableToolbarButtons = "retrieve,copy,delete";
      }
    }
  }

  allocationValidation(no:string, store:GridPluginComponent, value:number){
    var result = true;
    var tempStore = store.listStore.store;
    // // // console.log(tempStore[parseInt(no)-1].allotedBy);
    if(!tempStore[parseInt(no)-1].allotedBy.startsWith("SS-") && tempStore[parseInt(no)-1].allotedBy.substring(0,3) != "SOC" && tempStore[parseInt(no)-1].allotedBy.substring(0,3) != "COC"){
      if(value < 1){
        this.message("information","information","Amount entered must greater than zero","okonly",{ok:""});
        tempStore[parseInt(no)-1].allocated = 0;
        result = false;
      }else if(Math.ceil(Math.log10(value + 1)) > 9){
        // console.log("allocated greather than" , value.toString().length);
        this.message("information","information","Amount entered must less than 10 digits","okonly",{ok:""});
        tempStore[parseInt(no)-1].allocated = 0;
        tempStore[parseInt(no)-1].available = 0;
        result = false;
      }
    }

    return result
  }

  updateGridDetails1b(no:string, store:GridPluginComponent, value){
    var tempStore = store.listStore.store;
    if(tempStore[parseInt(no)-1].allocated == 0){
      tempStore[parseInt(no)-1].allocated = tempStore[parseInt(no)-1].allocatedH;
      tempStore[parseInt(no)-1].available = tempStore[parseInt(no)-1].allocated - (tempStore[parseInt(no)-1].booked + tempStore[parseInt(no)-1].reserved);
    }
    store.loadData();
  }

  updateGridDetails1a(no:string, store:GridPluginComponent, value){
    var tempStore = store.listStore.store;
    var parent = "";
    var parentValue = 0;
    var parentChildValue = 0;
    var total = 0;
    var hasParent:boolean = false;
    var hasChild:boolean = false;
    var level = tempStore[parseInt(no)-1].level;
    var childValue = 0;
    var lastValue = tempStore[parseInt(no)-1].allocatedH;

    if(no != "1"){
      // parent = tempStore[parseInt(no)-1].level.substr(0, (tempStore[parseInt(no)-1].level.length-2) );
      parent = this.getParentLevel(level);
    } else {
      parent = level;
    }

    tempStore.forEach(ts=>{
      if(ts.level == parent){
        parentValue = ts.allocated;
      }
    });
    parentChildValue = this.getChildAllocated(parent);

    // check level, has parent and child
    hasParent = (parent != "" ? true : false);
    hasChild = (this.countChild2(level, this.gridDetails.listStore.store) == 0 ? false : true);
    if(hasChild){
      childValue = this.getChildAllocated(level);
    }

    if(this.allocationValidation(no, store, value)){
      // check available allocation
      // // // console.log("parent value "+parentValue+" parentChildValue "+parentChildValue+" childValue "+childValue);
      switch (no){
        case "1" :
          if(parentChildValue > value){
            this.message("information","information","Amount Entered is not acceptable","okonly",{ok:""});
            tempStore[parseInt(no)-1].allocated = this.toFixed(lastValue);
          }
          break;
        default :
          // check input value against parent
          if(hasParent){
            if(parentValue < parentChildValue){
              this.message("information","information","Amount Entered Exceeds Allocated","okonly",{ok:""});
              tempStore[parseInt(no)-1].allocated = this.toFixed(lastValue);
            }
          }

          // check edited value against it's child if edited value is a parent
          if(hasChild){
            if(parentValue < childValue){
              this.message("information","information","Amount Entered is not acceptable","okonly",{ok:""});
              tempStore[parseInt(no)-1].allocated = this.toFixed(lastValue);
            }
          }
          break;
      }

      tempStore[parseInt(no)-1].available = this.toFixed(tempStore[parseInt(no)-1].allocated - (tempStore[parseInt(no)-1].booked + tempStore[parseInt(no)-1].reserved));
      tempStore[parseInt(no)-1].allocatedH = tempStore[parseInt(no)-1].allocated;
    }

  }

  updateGridDetails(no:string, store:GridPluginComponent, value){
    var tempStore = store.listStore.store;
    var parent = "";
    var parentValue = 0;var total = 0;
    var tempObj:any;
    if(no != "1"){
      // parent = tempStore[parseInt(no)-1].level.substr(0, (tempStore[parseInt(no)-1].level.length-2) );
      parent = this.getParentLevel(tempStore[parseInt(no)-1].level);
    }

    tempStore.forEach(ts=>{
      if(ts.level == parent){
        parentValue = ts.allocated;
      }
    });

    // get level
    var eLevel = tempStore[parseInt(no)-1].level;
    var hasChild:boolean = (this.countChild2(eLevel, this.gridDetails.listStore.store) == 0 ? false : true);
    var eChildAmount = 0;

    if(this.allocationValidation(no, store, value)){
      // // // console.log("has child "+hasChild);
      if(hasChild){
        eChildAmount = this.getChildAllocated(eLevel);
        // // // console.log("child amount "+eChildAmount+" value "+value);
        if(parseFloat(value) < parseFloat(eChildAmount.toString())){
          //this.message("information","information","Amount Entered Exceeds Allocated","okonly",{ok:""});
          tempStore.forEach(ts=>{
            if(ts.level.substr(0,eLevel.length) == eLevel){
              ts.allocated = 0;
              ts.available = ts.allocated - (ts.booked + ts.reserved);
            }
          });
        } else {
          tempStore[parseInt(no)-1].available = tempStore[parseInt(no)-1].allocated - (tempStore[parseInt(no)-1].booked + tempStore[parseInt(no)-1].reserved);
        }
      } else {
        if(no != "1"){
          total = parseFloat(parentValue.toString()) - parseFloat(this.getChildAllocated(parent).toString());
          if(total < 0){
            tempStore[parseInt(no)-1].allocated = 0;
            this.message("information","information","Amount Entered Exceeds Allocated","okonly",{ok:"this.refreshGrid();"});
          }
        }

        tempStore[parseInt(no)-1].available = tempStore[parseInt(no)-1].allocated - (tempStore[parseInt(no)-1].booked + tempStore[parseInt(no)-1].reserved);
      }
    }
  }

  test(){
    this.gridDetails.store.forEach(egs=>{
      // // // console.log(egs);
    });

    this.gridDetails.loadData();
  }

  refreshGrid(){
    // // // console.log("refresh");
    this.gridDetails.loadData();
  }

  updateStore(no, keyValue, store:GridPluginComponent, updateKey, value){
    store.listStore.store.forEach(fe=>{
      if(fe[keyValue] == no){
        fe[updateKey] = value;
      }
    });
  }

  doCopy(){
    let isValid:boolean = this.onValidate(this.modelCopy);
    if(!isValid){
      this.loading = true;
      // check capacity
      if(this.model.vesselCode == this.modelCopy.vesselCode && this.model.voyage == this.modelCopy.voyage && this.model.bound == this.modelCopy.bound){
        this.message("information","Information","Invalid vessel selection, please input different vessel/voyage/bound to copy allocation!", "okonly",{ok:""});
        this.showDialog('#copyAllocDialog');
      } else if(this.model.vesselCode == this.modelCopy.vesselCode){
        this.copyStructure = false;
        this.doCopyProcess();
        this.loading = false;
        this.closeDialog("#copyAllocDialog");
        this.copyCancel();
      } else {
        this.message("information","Information","Different vessel capacity detected. Copy allocation structure only?", "yesno",{yes:"this.copyYesOpt();",no:"this.copyNoOpt();"});
      }
    }
  }

  copyYesOpt(){
    this.copyStructure = true;
    this.doCopyProcess();
  }

  copyNoOpt(){
    this.loading = false;
    this.showDialog("#copyAllocDialog");
  }

  doCopyProcess(){
    if(this.copyStructure){
      this.loadAllocation(this.modelCopy, true, true);
    } else {
      this.loadAllocation(this.modelCopy, true, false);
    }
    this.loading = false;
    this.closeDialog("#copyAllocDialog");
    this.copyCancel();
  }

  copyCancel(){
    this.modelCopy = new MSailingSchedule;
    this.cbVesselCopy.setValue("");
    this.cbVoyageCopy.setValue("");
  }

  copyClose(){
    this.copyCancel();
    this.loading = false;
    this.closeDialog("#copyAllocDialog");
  }

  cancelAddAllotment(){
    this.firstForm = false;
    this.gridDetails.clearSelectedValues();
    this.modelDetails = new AllocationModel;
    this.cbAllotmentType.clearSelectInput();
    this.cbAllotmentType.setValue("");
    this.operator = '';
    this.cbPortOfDischarge.setValue("")
    this.closeDialog("#addAllocDialog");
    this.resetButton();

    if(this.gridDetails.listStore.store.length == 0){
      this.cButtonLock = false;
      if(this.viewOnlyStatus){
        this.disableToolbarButtons = "save,copy,delete";
      }else{
        this.disableToolbarButtons = "retrieve,delete";
      }
    } else {
      if(this.viewOnlyStatus){
        this.disableToolbarButtons = "save,copy,delete";
      }else{
        this.disableToolbarButtons = "retrieve,copy,delete";
      }
    }
  }

  copyEvent(){
    this.modelCopy = new MSailingSchedule;
    this.showDialog('#copyAllocDialog');
  }

  clearEvent(){
    console.log("BERSIH KESINI");
    this.model = new MSailingSchedule;
    this.modelDetails = new AllocationModel;
    this.cbVessel.setValue("");
    //ubah disini
    this.cbVessel.close();
    this.cbVoyage.setValue("");
    this.cbVoyage.setUrl('');
    this.retLock = false;
    this.cbVessel.disableCombo = false;
    this.cbVoyage.disableCombo = false;
    this.resetGrid();
    this.resetButton();
    this.disableToolbarButtons = "save,copy,delete";
    this.allocationRecordId = "";
  }

  clearEvent2(){
    //this.model = new MSailingSchedule;
    this.resetMode();
    this.modelDetails = new AllocationModel;
    //this.cbVessel.setValue("");
    //this.cbVoyage.setValue("");
    this.retLock = false;
    this.cbVessel.disableCombo = false;
    this.cbVoyage.disableCombo = false;
    this.resetGrid();
    this.resetButton();
    this.disableToolbarButtons = "save,copy,delete";
    this.allocationRecordId = "";
  }

  resetMode(){
    this.model.serviceCode = "";
    this.model.serviceId = "";
    this.model.serviceName = "";
    this.model.localETA = "";
    this.model.localETD = "";
  }


  resetGrid(){
    this.grid.onClear();
    this.gridDetails.onClear();
    this.hgridDetailsStore.splice(0,this.hgridDetailsStore.length);
  }

  resetButton(){
    this.cButtonLock = true;
    this.dButtonLock = true;
  }
  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  showDialog(id){
    $(id)
      .modal({
        closable : false,
        autofocus : false
      })
      .modal('show');
  }

  closeDialog(id){
    $(id).modal('hide');
  }

  resetAllocated(){
    $('#iAlloted').val('');
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  backEvent(){
    if(localStorage.getItem("prevSailingSchedule") == "Y"){
      localStorage.setItem("prevSailingSchedule", "N");

      this.location.back();

    }else{
      this.router.navigateByUrl("/main/home");
    }
  }

  closeEvent(){
    this.router.navigateByUrl("/main/home");
  }

  clearDialog(){
    $("body").find(".ui.dimmer.modals.page").find("div").each(function(index){
      if($(this).attr("id") == "copyAllocDialog"){
        $(this).remove();
      }
      if($(this).attr("id") == "addAllocDialog"){
        $(this).remove();
      }
    });
  }

  veryNewValidationLogic(dataStore:any){
    var result:boolean = true;
    var checkStore = this.copyStore(dataStore);
    var message = "";

    checkStore.forEach(fe=>{
      if(result){
        var parentData:any = this.getParentDetailsData(this.getParentLevel(fe.allotmentLevel), dataStore);
        if(fe.allotmentCode == "SOC LOCAL" || fe.allotmentCode == "SOC T/S"){
          if(parentData["allotmentCode"] != "SOC"){
            result = false;
            message = "Not allowed to save, please check level.";
          }
        } else if(fe.allotmentCode == "COC LOCAL" || fe.allotmentCode == "COC T/S") {
          if(parentData["allotmentCode"] != "COC"){
            result = false;
            message = "Not allowed to save, please check level.";
          }
        }
      }
    });

    if(message != ""){
      this.message("information","Information",message, "okonly", {ok:""});
    }

    return result;
  }

  getParentLevel(exstLevel:string){
    var result:string = "";
    var levelSplit:any;
    if(exstLevel != "1"){
      if(exstLevel.includes(".")){
        //result = exstLevel.substr(0, exstLevel.length-2);
        var levels = exstLevel.split(".");
        if(levels.length > 0){
          for(var i=1;i<levels.length;i++){
            result = result + levels[i-1];
            if(i < levels.length-1){
              result = result + ".";
            }
          }
        }
      }
    }
    return result;
  }

  checkAllocationValidationNewLogic(parentLevel:string, dataStore:any){
    var result:boolean = false;
    // childLevelCheck
    var childLevelCheck = this.checkAllocationTest(parentLevel, dataStore);
    // child check if child has child
    if(childLevelCheck){
      result = true;
      var firstLevelAllotment = [] = this.getChildListNew(parentLevel, dataStore);
      firstLevelAllotment.forEach(fe=>{
        // // // console.log("zzz "+fe.allotmentCode);
        if(fe.allotmentCode == "COC"){
          // // // console.log("ohyee");
          //if(this.modelOffice.officeCode == "THBKK" || this.modelOffice.officeCode == "THSGZ"){
          if(this.modelOffice.officeCode !== 'SGSIN'){
            // do nothing
          } else {
            if(!this.checkAllocationCOC(fe.allotmentLevel, dataStore)){
              result = false;
            }
          }
        }
        if(this.countChildNew(fe.allotmentLevel, dataStore) > 0){
          if(fe.allotmentCode.substring(0,3) != "SOC" && fe.allotmentCode.substring(0,3) != "COC" && !fe.allotmentCode.startsWith("SS")){
            if(!this.checkAllocationValidationNewLogic(fe.allotmentLevel, dataStore)){
              result = false;
            }
          }
        }
      });
    }

    return result;

  }

  checkAllocationCOC(parent, dataStore:any){
    var result:boolean = false;
    var allocationChild = [];
    var countCOCLocal = 0;var countCOCTS = 0;

    if(this.countChildNew(parent, dataStore) > 0){
      allocationChild = this.getChildListNew(parent, dataStore);
      allocationChild.forEach(fe=>{
        // // // console.log("oeee "+fe.allotmentCode);
        if(fe.allotmentCode == "COC LOCAL"){
          countCOCLocal++;
        } else if(fe.allotmentCode == "COC T/S"){
          countCOCTS++;
        }
      });
    }

    if(countCOCLocal != 0 && countCOCTS != 0){
      result = true;
    } else {
      this.message("information","Information","Unable to save allocation, Container Ownership (COC LOCAL & COC T/S) must co-exist for COC.","okonly",{ok:""});
    }

    return result;
  }

  checkAllocationTest(parent, dataStore:any){
    var result:boolean = false;
    var allocationChild = [];
    var countSOC = 0;var countCOC = 0;var countSlot = 0;var countCOCLocal = 0;var countCOCTS = 0;

    if(this.countChildNew(parent, dataStore) > 0){
      allocationChild = this.getChildListNew(parent, dataStore);
      allocationChild.forEach(fe=>{
        if(fe.allotmentCode.substring(0,3) == "SOC"){
          countSOC++;
        } else if(fe.allotmentCode.substring(0,3) == "COC"){
          countCOC++;
        } else if(fe.allotmentCode.startsWith("SS")){
          countSlot++;
        }
      });
    }

    // // // console.log("soc "+countSOC+" coc "+countCOC+" slot "+countSlot);

    if((countSOC > 0 && countCOC > 0 && countSlot > 0) || (countSOC+countCOC+countSlot == 0)){
      result = true;
    }

    if(countSOC+countCOC+countSlot != 0){
      if(countSOC > 0 && countCOC == 0 && countSlot == 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      } else if (countSOC > 0 && countCOC > 0 && countSlot == 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      } else if(countSOC == 0 && countCOC > 0 && countSlot > 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      } else if(countSOC == 0 && countCOC > 0 && countSlot == 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      } else if(countSOC == 0 && countCOC == 0 && countSlot > 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      } else if(countSOC > 0 && countCOC == 0 && countSlot > 0){
        this.message("information","Information","Unable to save allocation. Container Ownership (COC, SOC & SLOT) must co-exist.","okonly",{ok:""});
      }
    }

    if(result){
      var allotmentList = [];
      var countSOCLocal = 0, countSOCTS = 0, countCOCLocal = 0, countCOCTS = 0;
      if(this.countChildNew(parent, dataStore) > 0){
        allotmentList = this.getChildListNew(parent, dataStore);
        console.log(allotmentList);
        allotmentList.forEach(fx=>{
          if(fx.allotmentCode.substring(0,3) == "SOC"){
            var socAllotment = this.getChildListNew(fx.allotmentLevel, dataStore);
            socAllotment.forEach(fsoc=>{
              if(fsoc.allotmentCode == "SOC LOCAL"){
                countSOCLocal++;
              } else if(fsoc.allotmentCode == "SOC T/S"){
                countSOCTS++;
              }
            });
          } else if(fx.allotmentCode.substring(0,3) == "COC"){
            var cocAllotment = this.getChildListNew(fx.allotmentLevel, dataStore);
            cocAllotment.forEach(fcoc=>{
              if(fcoc.allotmentCode == "COC LOCAL"){
                countCOCLocal++;
              } else if(fcoc.allotmentCode == "COC T/S"){
                countCOCTS++;
              }
            });
          }
        });

        if(countSOCLocal > 0 && countSOCTS == 0){
          result = false;
          this.message("information","Information","Unable to save allocation. Container Ownership (SOC T/S) must co-exist.","okonly",{ok:""});
        } else if(countSOCLocal == 0 && countSOCTS > 0){
          result = false;
          this.message("information","Information","Unable to save allocation. Container Ownership (SOC Local) must co-exist.","okonly",{ok:""});
        } else if(countCOCLocal > 0 && countCOCTS == 0){
          result = false;
          this.message("information","Information","Unable to save allocation. Container Ownership (COC T/S) must co-exist.","okonly",{ok:""});
        } else if(countCOCLocal == 0 && countCOCTS > 0){
          result = false;
          this.message("information","Information","Unable to save allocation. Container Ownership (COC Local) must co-exist.","okonly",{ok:""});
        }
      }
    }

    return result;
  }

  checkNojan(parent:any, data:any, store:any){
    //get allocation list level 2
    // // // console.log("Parent :"+parent);
    var mainAllocation = [] = this.getChildListNew(parent, store);
    var childStore = mainAllocation;
    var checker = 0;
    if(data.bCntOwner == "SOC" || data.bCntOwner == "COC"){
      data.bCntOwner = data.bCntOwner + " LOCAL";
    } else if (data.bCntOwner == "SOCTS" || data.bCntOwner == "COCTS"){
      data.bCntOwner = data.bCntOwner + " T/S";
    }
    var checkPD = "";
    if(data.bPotCode != ""){
      checkPD = data.bPotCode;
    } else {
      checkPD = data.bPodCode;
    }
    var statusCnt = "";
    if(data.bContainerStatus == "FULL"){
      statusCnt = "LADEN";
    } else {
      statusCnt = "EMPTY";
    }

    mainAllocation.forEach(fe=>{
      if(checker == 0){
        if(this.countChildNew(fe.allotmentLevel, store) == 0){
          if(fe.allotmentCode.substring(0,3) == "SOC" || fe.allotmentCode.substring(0,3) == "COC"){
            if(data.bCntOwner == fe.allotmentCode){
              checker = 1;
            }
          } else if(fe.allotmentCode.startsWith("PD")) {
            if(checkPD == fe.allotmentCode.split("-")[1]){
              checker = 1;
            }
          } else if(fe.allotmentCode.startsWith("SS")){
            if(data.bSlotOperatorCode == fe.allotmentCode.split("-")[1]){
              checker = 1;
            }
          } else if(fe.allotmentCode == "LADEN" || fe.allotmentCode == "EMPTY"){
            if(statusCnt == fe.allotmentCode){
              checker = 1;
            }
          }
        } else {
          if(fe.allotmentCode.substring(0,3) == "SOC" || fe.allotmentCode.substring(0,3) == "COC"){
            if(data.bCntOwner.substring(0,3) == "SOC" || data.bCntOwner.substring(0,3) == "COC"){
              if(this.checkNojan(fe.allotmentLevel, data, store) == 1){
                checker = 1;
              }
            }
          } else if(fe.allotmentCode.startsWith("PD")){
            if(checkPD == fe.allotmentCode.split("-")[1]){
              if(this.checkNojan(fe.allotmentLevel, data, store) == 1){
                checker = 1;
              }
            }
          } else if(fe.allotmentCode.startsWith("SS")){
            if(data.bSlotOperatorCode == fe.allotmentCode.split("-")[1]){
              if(this.checkNojan(fe.allotmentLevel, data, store) == 1){
                checker = 1;
              }
            }
          } else if(fe.allotmentCode == "LADEN" || fe.allotmentCode == "EMPTY"){
            if(statusCnt == fe.allotmentCode){
              if(this.checkNojan(fe.allotmentLevel, data, store) == 1){
                checker = 1;
              }
            }
          }
        }
      }
    });

    return checker;
  }

  getParentDetailsData(level:string, store:any){
    var result:any;
    store.forEach(fe=>{
      if(fe.allotmentLevel == level){
        result = fe;
      }
    });

    return result;
  }

  copyStore(dataStoreToCopy){
    var store = [];
    dataStoreToCopy.forEach(fe=>{
      store.push(fe);
    });

    return store;
  }

  getChildListNew(parentLevel:string, store:any){
    var result = [];
    store.forEach(fe=>{
      if(this.getParentLevel(fe["allotmentLevel"]) == parentLevel){
        result.push(fe);
      }

      // if(fe["allotmentLevel"].startsWith(parentLevel) && fe["allotmentLevel"].split(".").length == parentLevel.split(".").length+1){
      //   result.push(fe);
      // }
    });

    return result;
  }

  countChildNew(parentLevel:string, store:any){
    var total = 0;
    store.forEach(fe=>{
      if(this.getParentLevel(fe["allotmentLevel"]) == parentLevel){
        total++;
      }

      // if(fe["allotmentLevel"].startsWith(parentLevel) && fe["allotmentLevel"].split(".").length == parentLevel.split(".").length+1){
      //   total++
      // }
    });

    return total;
  }

  getChildListNew2(parentLevel:string, store:any, levelNameField:string){
    var result = [];
    store.forEach(fe=>{
      if(this.getParentLevel(fe[levelNameField]) == parentLevel){
        result.push(fe);
      }

      /*if(fe[levelNameField].startsWith(parentLevel) && fe[levelNameField].split(".").length == parentLevel.split(".").length+1){
        result.push(fe);
      }*/
    });

    return result;
  }

  countChildNew2(parentLevel:string, store:any, levelNameField:string){
    var total = 0;
    store.forEach(fe=>{
      if(this.getParentLevel(fe[levelNameField]) == parentLevel){
        total++;
      }

      /*if(fe[levelNameField].startsWith(parentLevel) && fe[levelNameField].split(".").length == parentLevel.split(".").length+1){
        total++
      }*/
    });

    return total;
  }

  opEvent(event){
    var element = event.srcElement;
    this.operator = element.value;
  }

  toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }

  changesBound(value){
    if (value && this.formValidation.regOnlyIMO.test(value) == false) {
      this.model['error-bound'] = "Only allow I,M,O";
    }else{
      this.model['error-bound'] = ""
    }
  }

  blurBound(event){
    let value = event.target.value;
    if(value && this.formValidation.regOnlyIMO.test(value) == false){
      this.model['bound'] = "";
    }
  }

  backspaceValue(event: any, regexName: RegExp, field: string) {
    if(field === 'vesselName' && !event.target.value){
      this.cbVoyage.setValue("");
      this.model[`error-voyage`] = "";
      this.model.voyage = "";
    }
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field);
        break;
      default:
        return
    }
  }

  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field);
  }

  showErrorMessage(regexName, pastedText, field){
    const fileName = field === 'vesselName' ? 'Vessel' : 'Voyage';
    if (pastedText && regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${fileName} 'Only accept alphanumeric and -./'`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

}
