

export class MasterNewsPage  {
  newsId : string = '';
  title   : string = '';
  subtitle  : string = '';
  content  : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';

  constructor () { }
}
