export class Surcharge  {

                surchargeId    : string = '';
                 officeCode    : string = '';

         placeOfReceiptCode    : string='';
               loadPortCode    : string='';
          dischargePortCode    : string='';
      dischargeTerminalCode    : string='';
        placeOfDeliveryCode    : string='';
       finalDestinationCode    : string='';
   portOfTransshipment1Code    : string='';
   portOfTransshipment2Code    : string='';
   portOfTransshipment3Code    : string='';
               customerId      : string='';
               customerCountryCode:string='';

              commodityName    : string='';
                receiptType    : string='CY';
               deliveryType    : string='CY';
              effectiveFrom    : string='';
                effectiveTo    : string='';
         containerOwnership    : string='';

            containerStatus    : string='';
                    dgClass    : string='';

               shipmentType    : string='';
            adhocVesselId    : string='';
            adhocVesselCode    : string='';
                adhocVoyage    : string='';
                  detention    : string='';
           detentionRequest    : number=0;
                  demurrage    : string='';
           demurrageRequest    : number=0;
                    remarks    : string='';
                dateCreated    : string='';
                userCreated    : string='';
               dateModified    : string='';
               userModified    : string='';
                dateInvalid    : string='';
                userInvalid    : string='';
                    isValid    : string='';

//added on 02-Jun-2016
            porTerminalCode    : string='';
            polTerminalCode    : string='';
            podTerminalCode    : string='';
           podlTerminalCode    : string='';
           pot1TerminalCode    : string='';
           pot2TerminalCode    : string='';
           pot3TerminalCode    : string='';
          fdestTerminalCode    : string='';
//end added on 02-Jun-2016

//added on 28-11-2017
              deletedReason    : string='';
              dateDeleted      : string='';
              userDeleted      : string='' ;
              deletedStatus    : string ='';
              commission       : string='';
              acctPic          : string='';
//end added on 28-11-2017

//about detail table
       /*         containerType  : string='';
                containerSize  : string='';
                containerHeight  : string='';
                seqNo   : number;
                chargeCode     : string='';

                currencyCode :string='';
                chargeAmount : number=0;
                m3            : number=0;
                kt: number=0;
                rt: number=0;

                minCharge:number=0;
                oh:number=0;
                owl:number=0;
                owr:number=0;
                olf:number=0;
                olb:number=0;

                killslot:number=0;
                dooropen:string='';
                uch:number=0;
                ucl:number=0;
                ucw:number=0;

                freightType:string='';
                payerCode:string='';
                pcTab:string='';*/
                //masterSurchargeBySurchargeId:any=[];
                MasterSurchargeDetailsRevised:any=[];

  constructor() {

    }


}

export class SurchargeRetrieve  {

  surchargeId    : string = '';
  placeOfReceiptCode    : string='';
  loadPortCode    : string='';
  dischargePortCode    : string='';
  dischargeTerminalCode    : string='';
  placeOfDeliveryCode    : string='';
  finalDestinationCode    : string='';
  portOfTransshipment1Code    : string='';
  customerId      : string='';

  receiptType    : string='CY';
  deliveryType    : string='CY';
  effectiveFrom    : string='';
  effectiveTo    : string='';
  containerOwnership    : string='';
  containerStatus    : string='';

  containerType    : string='';

  dgClass    : string='';

  porTerminalCode    : string='';
  polTerminalCode    : string='';
  podTerminalCode    : string='';
  podlTerminalCode    : string='';
  pot1TerminalCode    : string='';
  pot2TerminalCode    : string='';
  pot3TerminalCode    : string='';
  fdestTerminalCode    : string='';

  isValid:string='';
  deletedStatus    : string ='';
  commission       : string='';
  constructor() { }

  }

  export class surchargeDeleteForValidator  {
    deletedReason    : string='';
    constructor() { }
  }

  export class surchargeHeader {
     OfficeCode       :string="";
     RecordId         :string="";
     FreightPartyId      :string="";
     ShipperId   :string="";
     PlaceOfReceiptCode :string="";
     LoadPortCode :string="";
     DischargePortCode  :string="";
     PlaceOfDeliveryCode  :string="";
     FinalDestinationCode :string="";
     PortOfTransshipment1Code  :string="";
     PortOfTransshipment2Code  :string="";
     PortOfTransshipment3Code  :string="";
     ContainerType   :string="ALL";
     CommodityId  :string="";
     ReceiptTypeCode  :string="CY";
     DeliveryTypeCode :string="CY";
     EffectiveFrom  :string="";
     EffectiveTo  :string="";
     ContainerOwnership :string="ALL";
     ContainerStatus  :string="ALL";
   
     ShipmentType :string="N";
     VesselId :string="";
     Voyage :string="";
     Detention  :string="S";
     DetentionRequest :number=0;
     Demurrage  :string="S";
     DemurrageRequest :number=0;
    
     DgClass  : string="ALL";
     Remarks :string="";
     UserCreated  :string="";
     DateCreated  :string="";
     UserModified :string="";
     DateModified :string="";
     UserRequested  :string="";
     DateRequested  :string="";
     UserApproved :string="";
     DateApproved :string="";
    
     UserTransmitted: string="";
     DateTransmitted: string="";
     PORTerminalCode  :string="";
     POLTerminalCode  :string="";
     PODTerminalCode  :string="";
     PODLTerminalCode  :string="";
     POT1TerminalCode  :string="";
     POT2TerminalCode  :string="";
     POT3TerminalCode  :string="";
     FDESTTerminalCode  :string="";
     IsValid:string='Y';
     UserInvalid:string='';
     DateInvalid:string='';
     IsDeleted:string='N';
     UserDeleted:string='';
     DateDeleted:string='';
     ReasonDeleted:string='';
     IsCommission:string='N';
     AcctPic:string='';

    constructor(){}
}