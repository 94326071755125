import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService,ConfigService, GenericUtil, DatePluginComponent, CookieService,TextareaPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { Invoice } from '../shared/index';
import { runInThisContext } from 'vm';
declare  var $:any;



@Component({
  selector: 'app-edi-transmit-unposted-credit-debit-to-coda-page',
  templateUrl: './edi-transmit-unposted-credit-debit-to-coda-page.component.html',
  styleUrls: ['./edi-transmit-unposted-credit-debit-to-coda-page.component.css']
})
export class EDITransmitUnpostedCreditDebitToCodaPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('dateTime') dateTime:DatePluginComponent;
  @ViewChild('txtRemarksProcess') txtRemarksProcess:TextareaPluginComponent;
  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingRemarksProcess;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  //setting
  settingDateTime

  model = Invoice;

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private cookieService:CookieService,private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Transmit', event: 'transmit', icon: 'exchange'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Transmit Unposted Credit/Debit Note(s) to CODA'
    },

    this.settingDateTime = {
      id: 'dateTime',
      placeholder : 'Date Time',
      autoHide: true,
      type: 'date', // date|custom
      format: 'yyyy-mm-dd',
      customtext: ''
    }

    this.settingRemarksProcess = {
      id: 'txtRemarksProcess',
      name: 'codaRemarksProcess',
      placeholder: '',
      cols: 30,
      rows: 5,
      maxlength: 255,
      border: 500,
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
  this.dateTime.setDisable(true);
  this.dateTime.setValue(this.genericUtil.getPSIDate().toString());
  // this.model['user']= this.cookieService.getName();
  console.log("NAMANYE : "+this.cookieService.getName());
  this.model['userCreated'] = this.cookieService.getName();

  }



  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'transmit' :

        this.transmitToCoda();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  transmitToCoda(){
    this.loading = true;
    this.txtRemarksProcess.setValue('')
    var urii = this.configService.config.BASE_API.toString()+'/invoice/transmitCreditDebitToCODA';
    // var urii = 'http://localhost:12345/invoice/transmitCreditDebitToCODA';
    var transmittedData = {
      userId: this.cookieService.getName().toUpperCase(),
      siteCode: ""
    };

    this.genericService.POST(urii, transmittedData).subscribe(resp => {

      console.log('transmitToCoda', resp);
      if (resp.ok) {
        this.txtRemarksProcess.setValue(resp.json()['message']);
      } else {
        this.txtRemarksProcess.setValue('Error occurred!');
      }

    }, err => {
      this.txtRemarksProcess.setValue('Error occurred!');
    }, () => {
      this.loading = false;
    });
  }

  changeEventDateFrom(event){}
}
