export class combineBL {
  no: number = 0;
  clauseId: string = '';
  clauseCode: string = '';
  clauseName: string = '';
  remarks: string = '';
  isValid: boolean = true;
  dateCreated: string = '';
  userCreated: string = '';
  dateModified: string = '';
  userModified: string = '';
  dateInvalid: string = '';
  userInvalid: string = '';
  vesselId: string = '';
  vesselCode: string = '';
  vesselName: string = '';
  voyage: string = '';
  bound: string = '';
  bOceanVesselId: string = '';
  bPolCode: string = '';
  bPodCode: string = '';
  bPot1Code: string = '';
  fd: string = '';
  bOceanVesselVoyage: string = '';
  bOceanVesselBound: string = '';
  bFinalDestinationCode: string = '';
  baseVoyage: string = '';
  psaVesselCode: string = '';
  functionIndicator: string = '';
  voyageNumber: string = '';
  customerServiceCode: string = '';
  portOfCall: string = '';
  sequence: string = '';
  blPartyAddress: string = '';
  blNo: string = '';

  gridCombine: gridCombine[] = [];

  constructor() {
  }
}

export class BlCombine {
  bOceanVesselCode: string = '';
  vesselName: string = '';
  bOceanVesselId: string = '';

}

export class gridCombine {
  blPartyAddress: string = '';
  blShipperName: string = '';
  blConsigneeName: string = '';
  blNotifyParty: string = '';
  blReceiptType: string = '';
  blDeliveryType: string = '';
  blDischargePort: string = '';
  blPortOfDelivery: string = '';
  blFinalDestination: string = '';
  blNo:string='';
  no: number = 0;
}
