<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:12"><div class="ui medium text loader">Loading</div></div>

  <div id='container'>
    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    
       <!--<Error Message Block-->
        <div class="ui error message" [class.hidden]="isError == false">
          <ul>
            <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == ''">
              {{model['error-blOceanVesselCode']}}
            </li>
            <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == ''">
              {{model['error-blOceanVesselVoyage']}}
            </li>
            <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
              {{model['error-blOceanVesselBound']}}
            </li>
            <li [class.hidden]="model['error-blLoadPortCode'] == null || model['error-blLoadPortCode'] == ''">
              {{model['error-blLoadPortCode']}}
            </li>
            <li [class.hidden]="model['error-blPlaceOfDeliveryCode'] == null || model['error-blPlaceOfDeliveryCode'] == ''">
              {{model['error-blPlaceOfDeliveryCode']}}
            </li>
            <li [class.hidden]="model['error-blPortOfTransshipment1Code'] == null || model['error-blPortOfTransshipment1Code'] == ''">
              {{model['error-blPortOfTransshipment1Code']}}
            </li>
            <li [class.hidden]="model['error-blPlaceOfReceiptCode'] == null || model['error-blPlaceOfReceiptCode'] == ''">
              {{model['error-blPlaceOfReceiptCode']}}
            </li>
            <li [class.hidden]="model['error-blDischargePortCode'] == null || model['error-blDischargePortCode'] == ''">
              {{model['error-blDischargePortCode']}}
            </li>
            <li [class.hidden]="model['error-blOperatorCode'] == null || model['error-blOperatorCode'] == ''">
              {{model['error-blOperatorCode']}}
            </li>
            <li [class.hidden]="model['error-blSOOperatorCode'] == null || model['error-blSOOperatorCode'] == ''">
              {{model['error-blSOOperatorCode']}}
            </li>
          </ul>
        </div>
        <!-- End of Error Message Block-->

       <form class="ui form">
      <div class="two fields">
        <!-- KIRI -->
        
        <div class="field">
          
          <!-- field kiri baris 1 -->
          <div class="field">
            <div class="two fields">
              <div class="field">
                
                <div class="field">
                  <div class="required one field"  [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                    <label>Vessel</label>
                    <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:1400px;" ></combo-plugin>
                  </div>
                </div>
                <div class="field">
                  <label>Receipt Type</label>
                  <combo-plugin #cbReceipt [settings]="settingReceipt" (change)="changeEventReceipt($event)" style="width:100%;"></combo-plugin>
                </div>
                <div class="field">
                  <label>Delivery Type</label>
                  <combo-plugin #cbDelivery [settings]="settingDelivery" (change)="changeEventDelivery($event)" style="width:100%;"></combo-plugin>
                </div>                
              </div>
              <div class="field">
                <div class="field">
                  <div class="required one field"   [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                      <label>Voyage</label>
                      <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                  </div> 
                </div>
                
                <div class="field" >
                  <fieldset style="border: 1px solid lightgray;" id="myContainer">
                    <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                      <div class="three fields">
                        <div class="field">   
                          
                          
                          <div class="ui test checkbox" >
                          
                            <input type="checkbox"  name="containerOwner" id="cntSOC" tabindex="0" class="hidden" checked>
                              <label>SOC</label>
                          </div>                      
                        </div>
                        <div class="field">            
                          <div class="ui test checkbox" >
                          
                            
                            <input type="checkbox"  name="containerOwner" id="cntCOC" tabindex="0" class="hidden" checked>
                              <label>COC</label>
                          </div>                      
                        </div>

                        <div class="field">            
                          <div class="ui test checkbox" >
                            <input type="checkbox"  name="containerOwner" id="cntSLOT" tabindex="0" class="hidden">
                              <label >Slot</label>
                            </div>                   
                        </div> 

                      </div>

                      <div class="three fields">
                        <div class="field">   
                          
                          
                          <div class="ui test checkbox" >
                          
                            <input type="checkbox"  name="containerOwner" id="cntSOCTS" tabindex="0" class="hidden" checked>
                              <label>SOCTS</label>
                          </div>                      
                        </div>
                        <div class="field">            
                          <div class="ui test checkbox" >
                          
                            
                            <input type="checkbox"  name="containerOwner" id="cntCOCTS" tabindex="0" class="hidden" checked>
                              <label>COCTS</label>
                          </div>                      
                        </div>

                      </div>
                  </fieldset>  
                </div> 


              </div>
            
              
            </div>
        </div> 

        <!-- field kiri baris 2 
        <div class="field">
          <div class="two fields">
            <div class="field">
               <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;color:dodgerblue;">Shipment</legend>
                    <div class="field">            
                      <div class="ui radio checkbox" >
                        <input type="checkbox"  name="chLocal" tabindex="0" class="hidden">
                          <label>Local</label>
                      </div>                      
                    </div>   
                    <div class="field">            
                      <div class="ui radio checkbox" >
                        <input type="checkbox"  name="chTranshipment" tabindex="0" class="hidden">
                          <label>Trasnhipment</label>
                      </div>                      
                    </div>  
                    <div class="field">            
                      <div class="ui radio checkbox" >
                        <input type="checkbox"  name="chAll" tabindex="0" class="hidden">
                          <label>All</label>
                      </div>                      
                    </div>         
               </fieldset>
            </div>
            <div class="field" >
                <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                      <div class="field">            
                        <div class="ui radio checkbox" >
                          <input type="checkbox"  name="chSoc" tabindex="0" class="hidden">
                            <label>SOC</label>
                        </div>                      
                      </div>
                      <div class="field">            
                        <div class="ui radio checkbox" >
                          <input type="checkbox"  name="chCoc" tabindex="0" class="hidden">
                            <label>COC</label>
                        </div>                      
                      </div>
                      <div class="field">            
                        <div class="ui radio checkbox" >
                          <input type="checkbox"  name="chAll" tabindex="0" class="hidden">
                            <label>All</label>
                        </div>                      
                      </div> 
                </fieldset>  
              </div>
          </div>
        </div>
        -->
        <!-- field kiri baris 3 -->
        <div class="field">
          <fieldset style="border: 1px solid lightgray;" id="fieldSortBy">
            <legend style="font-weight:bold;color:dodgerblue;">Sort By</legend>
              <div class="field">
                <div class="four fields">
                  <div class="field">
                    <div class="ui radio checkbox" >
                      <!-- <input type="radio"  name="chLoadPort" tabindex="0" class="hidden"> -->
                      <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdinvoiceno">
                      <label>Invoice No.</label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="ui radio checkbox" >
                      <!-- <input type="radio"  name="chLoadPort" tabindex="0" class="hidden"> -->
                      <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdpol">
                      <label style="color:grey;">Load Port</label>
                    </div>
                  </div>
                  <div class="field" style="margin-right:-10px">
                   <div class="ui radio checkbox" >
                     <!-- <input type="radio"  name="chLoadPort" tabindex="0" class="hidden"> -->
                     <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdpot">
                     <label style="color:grey;">Transshipment Port</label>
                   </div>
                  </div>
                  <div class="field">
                   <div class="ui radio checkbox" >
                     <!-- <input type="radio"  name="chBlNo" tabindex="0" class="hidden"> -->
                     <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdpod">
                     <label style="color:grey;">Discharge Port</label>
                   </div>
                  </div> 
                  
                  

                </div> 
              </div>     
              
              <div class="field">
                <div class="four fields">
                  <div class="field">
                    <div class="ui radio checkbox" >
                      <!-- <input type="radio"  name="chBlNo" tabindex="0" class="hidden"> -->
                      <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdopcode">
                      <label style="color:grey;">OP Code</label>
                    </div>
                   </div> 

                   <div class="field">
                    <div class="ui radio checkbox" >
                      <!-- <input type="radio"  name="chBlNo" tabindex="0" class="hidden"> -->
                      <input type="radio"  name="sortBy" tabindex="0" class="hidden" id="rdsoopr">
                      <label style="color:grey;">SO OPR</label>
                    </div>
                   </div> 
                </div>
              </div>
            </fieldset>  
          </div>

          <!-- field kiri baris 3 -->
          <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria By</legend>
               <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio" id="selVessel"  name="selectBy" tabindex="0" class="hidden"  (change)="changeEventCriteria($event)" value="VESSEL">
                     <label>Vessel</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui tiny right floated buttons" >
                     <button  class="ui button retrieve" data-content="Retrieve" (click)="onRetrieve()" id="btnRetrieve" disabled>Retrieve</button>
                  </div>           
                </div>   
               </div>
               <div class="field" style = "margin-top:-10px">
                <div class="field">
                  <div class="ui radio checkbox" >
                    <!-- <input type="checkbox"  name="chVessel" tabindex="0" class="hidden"> -->
                    <input type="radio" id="selInvoice"  name="selectBy" tabindex="0" class="hidden" checked (change)="changeEventCriteria($event)" value="INVOICE">
                    <label>Invoice No.</label>
                  </div>
                </div>
               </div>
               <!--div class="field" style = "margin-top:15px">
                 <div class="two fields">
                   <div class="field">
                    <div class="ui radio checkbox" >
                      <input type="checkbox"  name="chBL" tabindex="0" class="hidden" [checked]="isSelection=='BL' ">
                      <label>B/L</label>
                    </div>
                   </div>
                   <div class="field" style="width:50%;align-self:center;">
                     <div class="ui test checkbox" >
                       <input type="checkbox" name="chOriginal" tabindex="0">
                       <label>Use Original B/L No.</label>
                     </div>
                    </div>  
                 </div>
               </div-->
               <div class="field" style = "margin-top:8px" >
                <div class="ui radio checkbox" >
                 <input type="radio"  name="selectBy"  value = "POL" (change)="changeEventCriteria($event)" tabindex="0" class="hidden"> <label>Load Port</label>
                </div>
                <div  class="required one field"  [class.error]="model['error-blLoadPortCode'] != null && model['error-blLoadPortCode'] != ''">
                  <combo-plugin #cbLoadPort  [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                </div>
               </div>

               <div class="field" style = "margin-top:8px" >
                <div class="ui radio checkbox" >
                 <input type="radio" name="selectBy"  value = "POT" (change)="changeEventCriteria($event)" tabindex="0" class="hidden"> <label>Transshipment Port</label>
                </div>
                <div  class="required one field"  [class.error]="model['error-blPortOfTransshipment1Code'] != null && model['error-blPortOfTransshipment1Code'] != ''">
                  <combo-plugin #cbTranshipmentPort   [settings]="settingTranshipmentPort" (change)="changeEventTranshipmentPort($event)" style="width:100%;"></combo-plugin>
                </div>
               </div>

               <div class="field" style = "margin-top:8px" >
                <div class="ui radio checkbox" >
                 <input type="radio"  name="selectBy"  value = "POD" (change)="changeEventCriteria($event)" tabindex="0" class="hidden"> <label>Discharge Port</label>
                </div>
                <div  class="required one field" [class.error]="model['error-blDischargePortCode'] != null && model['error-blDischargePortCode'] != ''" >

                  <combo-plugin #cbDischargePort   [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                </div>
               </div>

               <div class="field" style = "margin-top:8px" >
                <div class="ui radio checkbox" >
                 <input type="radio"  name="selectBy"  value = "OPCODE" (change)="changeEventCriteria($event)" tabindex="0" class="hidden"> <label>OP Code</label>
                </div>
                <div class="required one field" [class.disabled]="lockOPCode==true"  [class.error]="model['error-blOperatorCode'] != null && model['error-blOperatorCode'] != ''">
                  <!--combo-plugin #cbOPCode   [settings]="settingOPCode" (change)="changeEventOPCode($event)" style="width:100%;"></combo-plugin-->
                  <input type="text" id="idOPCode" name="OPCode" [(ngModel)]="model['blOperatorCode']" (change)="changeEventOPCode($event)"   style="text-transform:uppercase" placeholder="OP Code" >
                </div>
               </div>

               <div class="field" style = "margin-top:8px" >
                <div class="ui radio checkbox" >
                 <input type="radio" name="selectBy"  value = "SOOPR" (change)="changeEventCriteria($event)" tabindex="0" class="hidden"> <label>SO OPR</label>
                </div>
                <div class="required one field" [class.disabled]="lockSOOPR==true"  [class.error]="model['error-blSOOperatorCode'] != null && model['error-blSOOperatorCode'] != ''">
                  <!--combo-plugin #cbSOOPR  [settings]="settingSOOPR" (change)="changeEventSOOPR($event)" style="width:100%;"></combo-plugin-->
                  <input type="text" id="idSOOPR" name="SOOPR" [(ngModel)]="model['blSOOperatorCode']" (change)="changeEventSOOPR($event)"    style="text-transform:uppercase" placeholder="SO OPR" >
                </div>
               </div>
              
        
            </fieldset>     
          </div>

          <!-- field kiri baris 4-->
           <br>
          <div class="field">
            <fieldset style="border: 1px solid lightgray;">
              <legend style="font-weight:bold;color:dodgerblue;">B/L Printing Selection</legend>
              <div class="two fields">
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"  name="printSel" id="printFAI" (change)="changeEventPrintBy($event)" tabindex="0" value="FAI" class="hidden" checked>
                     <label>Print By FAI Group</label>
                   </div>
                 </div>
                 <div class="field">
                   <div class="ui radio checkbox" >
                     <input type="radio"  name="printSel" id="printItem"  (change)="changeEventPrintBy($event)" value = "ITEM" tabindex="0" class="hidden">
                     <label>Print By Itemised</label>
                   </div>
                 </div>           
              </div>   
            </fieldset>
          </div>
          
        </div>
        <!-- END KIRI -->

        <!-- KANAN -->
        <div class="field">
          <!-- field kanan baris 1 -->
          <div class="field" >
            <div class="two fields">
              <div class="field" >
                <div class="required one field"  [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''">
                  <label>Bound:</label>
                  <input type="text" id="idBound" name="bound"  style="text-transform:uppercase" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" (change)="changeBound($event)">
                </div>
              </div>
              <div class="field">
                <label>No. of Set:</label>
                <input type="text" name="noofset"   [(ngModel)]="model['noOfSet']" (change)="changeOfSet($event)">
              </div>
            </div>
          </div>

          <!-- field kanan baris 2 -->
          <div class="field">
            <div class="three fields">
              <div class="field" style="width:30%;align-self:center;">
                <div class="ui test checkbox" >
                    <input type="checkbox" name="silentMode" tabindex="0" id="silentMode">
                    <label>Silent Mode</label>
                </div>
              </div>                
              <div class="field" style="width:30%;align-self:center;">
                <div class="ui test checkbox" >
                    <input type="checkbox" name="invoiceByCurrency" id="invByCurrency"  tabindex="0" disabled>
                    <label style="color: grey;">Invoice By Currency</label>
                </div>
              </div>  
              <div class="field">
                <div class="field">
                  <div class="ui radio checkbox" >
                    <input type="radio" id="convertSGD"  name="shipment" tabindex="0" class="hidden" checked>
                    
                      <label>Convert to SGD</label>
                  </div>
              </div> 
                <div class="field">
                  <div class="ui radio checkbox" >
                    <input type="radio" id="notconvertSGD"  name="shipment" tabindex="0" class="hidden">
                    
                      <label>Do not convert to SGD</label>
                  </div>
              </div>                     
              </div>   
            </div>
          </div>
          
          <div id="pdfContainerNewInvoice" style="display:none;">  
            <div class="ui form">
              <div class="field" id="pdfContainerNewInvoice-label">
                  <label>&nbsp;please click on the Invoice No. to view.</label>
              </div> 
              <div class="two fields">      
                <div id='pdfContainerNewInvoice-grid-container' class="field" style="width:30%;">
                    <grid-plugin #gridPDFPrev [settings]="settingGridPDFPrev" (gridEvent)="gridEventPDFPrev($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                </div>
                <div id='pdfContainerNewInvoice-viewer-container' class="field" style="width:100%;height:570px;">          
                </div>
              </div>
            </div>
          
          </div>


          <div class="field" [style.display]="addRemoveButton" id="addRemoveButton">
            <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
            <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
          </div>

          <!-- field kanan baris 3 -->
          <div class="field" style="height:395px">            
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                     
          </div>  

        </div>
        <!-- END KANAN --> 
        
        
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
