export class splitBL{
  blNo  : string='';
  conf: string='';
  blShipperId: string='';
  bookingNo: string='';
  typeSize: string='';
  splitQty: number=0;
  aftersplitQty: number=0;
  sumGridQty: number=0;
  bGenerate: string='';
  blType: string='';
  ok: string='';
  blNo1:  string='';
  char:  string='';
  target:  string='';
  cars:  string='';
  D20:number=0;
  R20:number=0;
  HC45:number=0;
  HC20:number=0;
  RH40:number=0;
  R45:number=0;
  D45:number=0;
  TK20:number=0;
  TK40:number=0;
  TK45:number=0;
  D40:number=0;
  RH20:number=0;
  R40:number=0;
  HC40:number=0;
  RH45:number=0;

  gridheaderSplit:gridheaderSplit[] = [];
  gridAfterSplit:gridAfterSplit[]=[];

  addBl(bl:splitBL){
    this.D20 += bl.D20;
    this.R20 += bl.R20;
    this.HC20 += bl.HC20;
    this.RH20 += bl.RH20;
    this.D40 += bl.D40;
    this.R40 += bl.R40;
    this.HC40 += bl.HC40;
    this.RH40 += bl.RH40;
    this.D45 += bl.D45;
    this.R45 += bl.R45;
    this.HC45 += bl.HC45;
    this.RH45 += bl.RH45;
    this.TK20 += bl.TK20;
    this.TK40 += bl.TK40;
    this.TK45 += bl.TK45;
  }
  subtractionBl(bl:splitBL){
    this.D20 -= bl.D20;
    this.R20 -= bl.R20;
    this.HC20 -= bl.HC20;
    this.RH20 -= bl.RH20;
    this.D40 -= bl.D40;
    this.R40 -= bl.R40;
    this.HC40 -= bl.HC40;
    this.RH40 -= bl.RH40;
    this.D45 -= bl.D45;
    this.R45 -= bl.R45;
    this.HC45 -= bl.HC45;
    this.RH45 -= bl.RH45;
    this.TK20 -= bl.TK20;
    this.TK40 -= bl.TK40;
    this.TK45 -= bl.TK45;
  }
  copyBl(bl:splitBL){
    this.blNo = bl.blNo;
    this.D20 = bl.D20;
    this.R20 = bl.R20;
    this.HC20 = bl.HC20;
    this.RH20 = bl.RH20;
    this.D40 = bl.D40;
    this.R40 = bl.R40;
    this.HC40 = bl.HC40;
    this.RH40 = bl.RH40;
    this.D45 = bl.D45;
    this.R45 = bl.R45;
    this.HC45 = bl.HC45;
    this.RH45 = bl.RH45;
    this.TK20 = bl.TK20;
    this.TK40 = bl.TK40;
    this.TK45 = bl.TK45;
  }
  isValid(){
    return ((this.D20 >=0 && this.R20 >=0 && this.HC20 >=0 && this.RH20 >=0 &&
    this.D40 >=0 && this.R40 >=0 && this.HC40 >=0 && this.RH40 >=0 &&
    this.D45 >=0 && this.R45 >=0 && this.HC45 >=0 && this.RH45 >=0 &&
    this.TK20 >=0 && this.TK40 >=0 && this.TK45 >=0)
    &&
    ((this.D20 + this.R20 + this.HC20 + this.RH20 +
    this.D40 + this.R40 + this.HC40 + this.RH40 +
    this.D45 + this.R45 + this.HC45 + this.RH45 +
    this.TK20 + this.TK40 + this.TK45) >0));
  }

  constructor() {

  }

}
export class gridheaderSplit{
  blNo  : string='';
  D20:number=0;
  R20:number=0;
  HC45:number=0;
  HC20:number=0;
  RH40:number=0;
  R45:number=0;
  D45:number=0;
  TK20:number=0;
  TK40:number=0;
  TK45:number=0;
  D40:number=0;
  RH20:number=0;
  R40:number=0;
  HC40:number=0;
  RH45:number=0;
  aftersplitQty:number=0;
}
export class gridAfterSplit{
  blNo  : string='';
  aftersplitQty:number=0;
  D20:number=0;
  R20:number=0;
  HC45:number=0;
  HC20:number=0;
  RH40:number=0;
  R45:number=0;
  D45:number=0;
  TK20:number=0;
  TK40:number=0;
  TK45:number=0;
  D40:number=0;
  RH20:number=0;
  R40:number=0;
  HC40:number=0;
  RH45:number=0;
}
