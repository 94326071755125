import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { ExchangeRate } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";
//import { FileSaver } from '../../assets/js/FileSaver.min.js'

declare var $: any;

@Component({
  selector: "app-master-exchange-rate-page",
  templateUrl: "./master-exchange-rate-page.component.html",
  styleUrls: ["./master-exchange-rate-page.component.css"],
})
export class MasterExchangeRatePageComponent
  extends Validator
  implements OnInit, AfterViewInit {
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbExchangeRateDestinationCurrency")
  cbExchangeRateDestinationCurrency: ComboPluginComponent;
  @ViewChild("cbExchangeRateHistory")
  cbExchangeRateHistory: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  //setGridEvent:string = '';
  formValidation = new FormValidation()

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingExchangeRateHistory;
  settingExchangeRateDate;
  settingExchangeRateDestinationCurrency;

  /* Misc Parameters */
  valExchangeRateDate: string = "";
  valExchangeRateName: string = "";
  valExchangeRateId: string = "";
  valHistoryStartDate: string = "*";
  valHistoryEndDate: string = "*";

  typeExchangeRateDate = "calendar";

  /* Parameter model */
  model = new ExchangeRate();
  //selectList = [];

  loading = false;

  lock = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    exchangeRateDate: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Exchange Rate Date.",
        },
      ],
    },
    destinationCurrency: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Destination Currency.",
        },
      ],
    },
    exchangeRate: {
      rules: [
        {
          type: "positiveInteger",
          prompt: "No negative value!",
        },
        {
          type: "emptyZero",
          prompt: "Please input Container Weight.",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: 'modifiedPattern',
          pattern: this.formValidation.regAllChar,
          prompt: 'Invalid remarks'
        }
      ]
    }
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      toolbarType: "dateInterval",
      searchBy: [
        { display: "Exchange Rate Date", value: "exchangeRateDate" },
        { display: "Local Currency", value: "homeCurrency" },
        { display: "Destination Currency", value: "destinationCurrency" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterExchangeRates/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "ExchangeRate Date", field: "exchangeRateDate", width: 200 },
        { header: "Local Currency", field: "homeCurrency", width: 50 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 50,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 5,
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "ExchangeRateDate",
      sortingDirection: "DESC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterExchangeRates/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "ExchangeRate Id", field: "exchangeRateId", width: 200 },
        { header: "ExchangeRate Date", field: "exchangeRateDate", width: 200 },
        { header: "Local Currency", field: "homeCurrency", width: 50 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 50,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 5,
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      
      enablePagination: true,
    };

    this.settingExchangeRateHistory = {
      id: "cbExchangeRateDateHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterExchangeRates/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      code: "exchangeRateDate",
      title: "exchangeRateDate",
      description: "",
      isMark: true,
      columns: [
        { header: "ExchangeRate Id", field: "exchangeRateId", width: 50 },
        { header: "ExchangeRate Date", field: "exchangeRateDate", width: 50 },
        { header: "Local Currency", field: "homeCurrency", width: 50 },
        {
          header: "Destination Currency",
          field: "destinationCurrency",
          width: 50,
        },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          type: "number",
          decimal: 5,
          width: 50,
        },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingExchangeRateDestinationCurrency = {
      id: "cbExchangeRateDestinationCurrency",
      type: "search enter", // search | select | select input
      //url         : 'assets/json/currencies.json',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findGeneralEnum/{query}",
      // urlType     : 'dynamic',
      maxChars: 3,
      template: "grid",
      placeholder: "Select Currency",
      code: "currencyCode",
      title: "currencyCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Currency Name", field: "currencyName", width: 50 },
        { header: "Currency Code", field: "currencyCode", width: 50 },
      ],
    };

    this.settingExchangeRateDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            if (this.viewOnlyStatus) {
              this.disableToolbarButtons = "update,delete";
            } else {
              this.disableToolbarButtons = "";
            }
            this.invisibleToolbarButtons = "create,history,print";

            this.modeForm = true;
            this.modeToolbar = false;
            this.setData();
            this.lock = true;
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.exchangeRateId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new ExchangeRate();
    this.valExchangeRateDate = "";
    this.valExchangeRateName = "";
  }

  handleUpdate() {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;
    this.setData();
    this.lock = false;
    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";
  }

  handleDelete() {
    // handle delete event
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  handlePrint() {
    window.open(
      this.configService.config.BASE_API.toString().replace("9090", "10000") +
        "/Mreport/runReport/33/Exchange-Rates-Export-Reports/*"
    );
    // handle print event
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');

    //set value
    this.model.exchangeRateDate = $("#exchangeRateDate")
      .find("#datePicker")
      .val();

    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      if (this.model.exchangeRate <= 0) {
        return this.message(
          "warning",
          "Input error",
          "No negative value!",
          "okonly",
          {
            ok: "this.loading = false;",
          }
        );
      }

      if (this.model.exchangeRate === 0) {
        return this.message(
          "warning",
          "Input error",
          "No zero value!",
          "okonly",
          {
            ok: "this.loading = false;",
          }
        );
      }

      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model.dateCreated = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    if (this.valExchangeRateId != "") {
      this.valExchangeRateDate = "";
      this.valExchangeRateId = "";
      this.cbExchangeRateHistory.setValue("");
      this.cbExchangeRateHistory.close();
      this.gridHistory.onHistory("NaN");
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    $("#exchangeRateDate").find("#datePicker").val(null);
    this.cbExchangeRateDestinationCurrency.setValue("");

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new ExchangeRate();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }
    // console.log(String(data.exchangeRateId).trim().toUpperCase());
    this.model.exchangeRateId = String(data.exchangeRateId)
      .trim()
      .toUpperCase();
    this.model.exchangeRateDate = String(data.exchangeRateDate)
      .trim()
      .toUpperCase();
    this.model.homeCurrency = String("SGD").trim().toUpperCase();
    this.model.destinationCurrency = String(data.destinationCurrency)
      .trim()
      .toUpperCase();
    this.model.exchangeRate = Number.parseFloat(data.exchangeRate);
    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();

    this.cbExchangeRateDestinationCurrency.setValue(
      String(data.destinationCurrency).trim().toUpperCase()
    );
    $("#exchangeRateDate").find("#datePicker").val(this.model.exchangeRateDate);
  }

  refreshed() {
    // console.log("masuk refresh");
    //this.dtgrid.loadData();
  }

  valueCbExchangeRateDestinationCurrency(event) {
    this.model.destinationCurrency = event["currencyCode"];
    // console.log(event.toString);
  }
  valueExchangeRateHistory(event) {
    this.valExchangeRateDate = event["exchangeRateDate"];
    this.valExchangeRateId = event["exchangeRateId"];
    // console.log("curr code= " + event["ExchangeRateCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valExchangeRateId);
  }

  generateExchangeRateHistoryReport(event) {
    //this.valHistoryStartDate= $('#logHistoryExchangeRateStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryExchangeRateEndDate').find('#datePicker').val();
    window.open(
      this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
        "/Mreport/runReport/34/History-Exchange-Rate-" +
        this.valExchangeRateId +
        "-" +
        this.valExchangeRateDate +
        "/" +
        this.valExchangeRateId +
        "@" +
        this.valHistoryStartDate +
        "@" +
        this.valHistoryEndDate
    );
  }

  retrieveHistory(event) {
    this.gridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    console.log('Exchange Rate : ', typeof this.model.exchangeRate, this.model.exchangeRate);

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterExchangeRates/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new ExchangeRate();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.exchangeRateId);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterExchangeRates/delete",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new ExchangeRate();
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  handleChangeExchangeRate() {
    console.log(this.model.exchangeRate)
    if (this.model.exchangeRate <= 0) {
      return (this.model["error-exchangeRate"] =
        "Please input positive number");
    }

    return (this.model["error-exchangeRate"] = "");
  }
}
