import { finalize } from 'rxjs/operators';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { BLHeader, jasperModel, specialCharUtil } from '../shared/index';
import {
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  CalendarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  TextareaPluginComponent,
  TextareaV2PluginComponent,
  DatetimePluginComponent,
  DatePluginComponent,
  PdfPluginComponent,
  DialogPluginComponent,
} from 'sibego-ui-library';
import {
  BLContainer,
  BLContainerHSCode,
  BLDesc,
  BLDescClause,
  BLErrorList,
  BLExchangeRate,
  BLFreightCharge,
  BLMarkNo,
  BLContainerExtra,
  BLAdContainer,
} from '../shared/models/billOfLading';
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnApi,
  GridApi,
  ICellRenderer,
  ICellRendererParams
} from 'ag-grid-community';
var numeral = require('numeral');

import FormValidation from '../../utils/formValidation';
import {forEach} from '@angular/router/src/utils/collection';
import { ReportMatchingCllBlPageComponent } from '../report-matching-cll-bl-page/report-matching-cll-bl-page.component';
import { takeUntil } from 'rxjs-compat/operator/takeUntil';
import { e } from '@angular/core/src/render3';
//import { timeStamp } from 'console';

declare var $: any;

@Component({
  selector: 'app-transaction-bl-maintenance-latest',
  templateUrl: './transaction-bl-maintenance-latest-page.component.html',
  styleUrls: ['./transaction-bl-maintenance-latest-page.component.css'],
})
export class TransactionBlMaintenanceLatestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  // *** 01. Party Tab ***//
  @ViewChild('cbBlPartyShipper') cbBlPartyShipper: ComboPluginComponent;
  @ViewChild('cbBlPartyShipperAddress')
  cbBlPartyShipperAddress: ComboPluginComponent;
  @ViewChild('txtBlPartyShipperAddress')
  txtBlPartyShipperAddress: TextareaV2PluginComponent;
  @ViewChild('cbBlPartyConsignee') cbBlPartyConsignee: ComboPluginComponent;
  @ViewChild('cbBlPartyConsigneeAddress')
  cbBlPartyConsigneeAddress: ComboPluginComponent;
  @ViewChild('txtBlPartyConsigneeAddress')
  txtBlPartyConsigneeAddress: TextareaV2PluginComponent;
  @ViewChild('cbBlPartyNotifyParty') cbBlPartyNotifyParty: ComboPluginComponent;
  @ViewChild('cbBlPartyNotifyPartyAddress')
  cbBlPartyNotifyPartyAddress: ComboPluginComponent;
  @ViewChild('txtBlPartyNotifyPartyAddress')
  txtBlPartyNotifyPartyAddress: TextareaV2PluginComponent;

  // *** 02. Place ***//
  @ViewChild('cbBlPlaceReceiptType') cbBlPlaceReceiptType: ComboPluginComponent;
  @ViewChild('cbBlPlaceDeliveryType')
  cbBlPlaceDeliveryType: ComboPluginComponent;
  @ViewChild('cbBlPlacePOR') cbBlPlacePOR: ComboPluginComponent;
  @ViewChild('cbBlPlacePOL') cbBlPlacePOL: ComboPluginComponent;
  @ViewChild('cbBlPlacePOD') cbBlPlacePOD: ComboPluginComponent;
  @ViewChild('cbBlPlacePODL') cbBlPlacePODL: ComboPluginComponent;
  @ViewChild('cbBlPlacePOT1') cbBlPlacePOT1: ComboPluginComponent;
  @ViewChild('cbBlPlacePOT2') cbBlPlacePOT2: ComboPluginComponent;
  @ViewChild('cbBlPlacePOT3') cbBlPlacePOT3: ComboPluginComponent;
  @ViewChild('cbBlPlaceFDEST') cbBlPlaceFDEST: ComboPluginComponent;
  @ViewChild('cbBlPlacePrincipal') cbBlPlacePrincipal: ComboPluginComponent;
  @ViewChild('cbBlPlaceService') cbBlPlaceService: ComboPluginComponent;
  @ViewChild('cbBlPlacePreVessel') cbBlPlacePreVessel: ComboPluginComponent;
  // @ViewChild('cbBlPlacePreVoyage') cbBlPlacePreVoyage: ComboPluginComponent;
  @ViewChild('cbBlPlaceOceanVessel') cbBlPlaceOceanVessel: ComboPluginComponent;
  @ViewChild('cbBlPlaceOceanVoyage') cbBlPlaceOceanVoyage: ComboPluginComponent;
  @ViewChild('cbBlPlaceNextVessel') cbBlPlaceNextVessel: ComboPluginComponent;
  // @ViewChild('cbBlPlaceNextVoyage') cbBlPlaceNextVoyage: ComboPluginComponent;

  // *** 03. Container ***//
  @ViewChild('cbBlContainerReceiptType')
  cbBlContainerReceiptType: ComboPluginComponent;
  @ViewChild('cbBlContainerDeliveryType')
  cbBlContainerDeliveryType: ComboPluginComponent;
  @ViewChild('cbBlContainerPackageType')
  cbBlContainerPackageType: ComboPluginComponent;
  @ViewChild('cbBlContainerContainerType')
  cbBlContainerContainerType: ComboPluginComponent;
  @ViewChild('cbBlContainerContainerSize')
  cbBlContainerContainerSize: ComboPluginComponent;
  @ViewChild('cbBlContainerContainerHeight')
  cbBlContainerContainerHeight: ComboPluginComponent;
  @ViewChild('cbBlContainerContainerDGClass')
  cbBlContainerContainerDGClass: ComboPluginComponent;
  @ViewChild('cbBlContainerInnerPkgType')
  cbBlContainerInnerPkgType: ComboPluginComponent;
  @ViewChild('cbBlContainerCommodity')
  cbBlContainerCommodity: ComboPluginComponent;
  @ViewChild('gridBlContainer') gridBlContainer: GridPluginComponent;
  @ViewChild('gridBlContainerHS') gridBlContainerHS: GridPluginComponent;
  @ViewChild('cbBlContainerHS') cbBlContainerHS: ComboPluginComponent;
  @ViewChild('cbBlContainerFlashPointScale')
  cbBlContainerFlashPointScale: ComboPluginComponent;
  @ViewChild('cbBlContainerOogScale')
  cbBlContainerOogScale: ComboPluginComponent;
  @ViewChild('cbBlContainerReeferScale')
  cbBlContainerReeferScale: ComboPluginComponent;
  @ViewChild('cbBlContainerShortShipmentVessel')
  cbBlContainerShortShipmentVessel: ComboPluginComponent;
  @ViewChild('cbBlContainerShortShipmentVoyage')
  cbBlContainerShortShipmentVoyage: ComboPluginComponent;
  // yg-sbhd
  @ViewChild('gridBlAdContainer') gridBlAdContainer: GridPluginComponent;
  @ViewChild('cbBlAdContainerCommodity')
  cbBlAdContainerCommodity: ComboPluginComponent;
  @ViewChild('cbBlAdContainerInnerPkgType')
  cbBlAdContainerInnerPkgType: ComboPluginComponent;
  @ViewChild('cbBlAdContainerOuterPkgType')
  cbBlAdContainerOuterPkgType: ComboPluginComponent;
  @ViewChild('cbBlAdContainerDGClass')
  cbBlAdContainerDGClass: ComboPluginComponent;
  @ViewChild('cbBlAdContainerFlashPointScale')
  cbBlAdContainerFlashPointScale: ComboPluginComponent;
  @ViewChild("cbBlAdContainerHsCode") cbBlAdContainerHsCode: ComboPluginComponent;
  @ViewChild("cbBlAdContainerHsName") cbBlAdContainerHsName: ComboPluginComponent;
  @ViewChild("txtBlContainerDesc") txtBlContainerDesc: TextareaPluginComponent;

  // *** 04. Mark No. ***//
  @ViewChild('gridBlMarkNo') gridBlMarkNo: GridPluginComponent;
  @ViewChild('gridBlNettChargeExchangeRate')
  gridBlNettChargeExchangeRate: GridPluginComponent;
  @ViewChild('txtBlMarkNoDesc') txtBlMarkNoDesc: TextareaV2PluginComponent;

  // *** 05. Desc. ***//
  @ViewChild('gridBlDesc') gridBlDesc: GridPluginComponent;
  @ViewChild('txtBlDescTotalContainerPackageInWord')
  txtBlDescTotalContainerPackageInWord: TextareaV2PluginComponent;
  @ViewChild('cbBlDescAgentId') cbBlDescAgentId: ComboPluginComponent;
  @ViewChild('txtBlDescAgentNameAddress')
  txtBlDescAgentNameAddress: TextareaV2PluginComponent;
  @ViewChild('gridBlDescClause') gridBlDescClause: GridPluginComponent;
  // @ViewChild('txtBlDescClauseDescription') txtBlDescClauseDescription: TextareaPluginComponent;
  @ViewChild('cbBlDescClauses') cbBlDescClauses: ComboPluginComponent;

  @ViewChild('cbBlDescPSAPackageType')
  cbBlDescPSAPackageType: ComboPluginComponent;
  @ViewChild('cbBlDescInnerPkgType') cbBlDescInnerPkgType: ComboPluginComponent;
  @ViewChild('cbBlDescOuterPkgType') cbBlDescOuterPkgType: ComboPluginComponent;
  @ViewChild('cbBlDescReceiptType') cbBlDescReceiptType: ComboPluginComponent;
  @ViewChild('cbBlDescDeliveryType') cbBlDescDeliveryType: ComboPluginComponent;
  @ViewChild('cbBlDescCommodityCode')
  cbBlDescCommodityCode: ComboPluginComponent;
  @ViewChild('txtBlDescDescriptionOfGoods')
  txtBlDescDescriptionOfGoods: TextareaV2PluginComponent;

  // *** 06. Nett Charge ***//
  @ViewChild('cbBlNettChargePrepaid1')
  cbBlNettChargePrepaid1: ComboPluginComponent;
  @ViewChild('cbBlNettChargePayableAt1')
  cbBlNettChargePayableAt1: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty1')
  cbBlNettChargeBillingParty1: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty1Address')
  cbBlNettChargeBillingParty1Address: ComboPluginComponent;
  @ViewChild('cbBlNettChargePrepaid2')
  cbBlNettChargePrepaid2: ComboPluginComponent;
  @ViewChild('cbBlNettChargePayableAt2')
  cbBlNettChargePayableAt2: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty2')
  cbBlNettChargeBillingParty2: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty2Address')
  cbBlNettChargeBillingParty2Address: ComboPluginComponent;
  @ViewChild('cbBlNettChargePrepaid3')
  cbBlNettChargePrepaid3: ComboPluginComponent;
  @ViewChild('cbBlNettChargePayableAt3')
  cbBlNettChargePayableAt3: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty3')
  cbBlNettChargeBillingParty3: ComboPluginComponent;
  @ViewChild('cbBlNettChargeBillingParty3Address')
  cbBlNettChargeBillingParty3Address: ComboPluginComponent;
  @ViewChild('txtBlNettChargeRemarks')
  txtBlNettChargeRemarks: TextareaPluginComponent;
  @ViewChild('txtBlNettChargeRemarksConsole')
  txtBlNettChargeRemarksConsole: TextareaPluginComponent;
  @ViewChild('txtBlNettChargeNewRemarks')
  txtBlNettChargeNewRemarks: TextareaPluginComponent;
  @ViewChild('gridBlNettCharge') gridBlNettCharge: GridPluginComponent;
  @ViewChild('gridBlNettChargeConsole')
  gridBlNettChargeConsole: GridPluginComponent;

  //@ViewChild('dateBlNettChargeDateOfIssue') dateBlNettChargeDateOfIssue: DatetimePluginComponent;
  @ViewChild('dateBlNettChargeDateOfIssue')
  dateBlNettChargeDateOfIssue: DatePluginComponent;
  @ViewChild('dateBlNettChargeDateOfIssueConsole')
  dateBlNettChargeDateOfIssueConsole: DatePluginComponent;

  //@ViewChild('dateBlNettChargeExchangeRate') dateBlNettChargeExchangeRate: DatetimePluginComponent;
  @ViewChild('dateBlNettChargeExchangeRate')
  dateBlNettChargeExchangeRate: DatePluginComponent;
  @ViewChild('dateBlNettChargeExchangeRateConsole')
  dateBlNettChargeExchangeRateConsole: DatePluginComponent;

  @ViewChild('dateBlNettChargeShippedOnBoard')
  dateBlNettChargeShippedOnBoard: DatetimePluginComponent;

  @ViewChild('cbBlNettChargeInputCharge')
  cbBlNettChargeInputCharge: ComboPluginComponent;
  @ViewChild('cbBlNettChargeInputChargeConsole')
  cbBlNettChargeInputChargeConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeInputPC')
  cbBlNettChargeInputPC: ComboPluginComponent;
  @ViewChild('cbBlNettChargeInputPCConsole')
  cbBlNettChargeInputPCConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeInputPerCode')
  cbBlNettChargeInputPerCode: ComboPluginComponent;
  @ViewChild('cbBlNettChargeInputPerCodeConsole')
  cbBlNettChargeInputPerCodeConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeInputPayer')
  cbBlNettChargeInputPayer: ComboPluginComponent;
  @ViewChild('cbBlNettChargeInputPayerConsole')
  cbBlNettChargeInputPayerConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeInputPlaceOfPaymentConsole')
  cbBlNettChargeInputPlaceOfPaymentConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeInputCurrency')
  cbBlNettChargeInputCurrency: ComboPluginComponent;
  @ViewChild('cbBlNettChargeInputCurrencyConsole')
  cbBlNettChargeInputCurrencyConsole: ComboPluginComponent;

  @ViewChild('cbBlNettChargeExchangeRateCurrency')
  cbBlNettChargeExchangeRateCurrency: ComboPluginComponent;
  @ViewChild('cbBlNettChargePlaceOfIssue')
  cbBlNettChargePlaceOfIssue: ComboPluginComponent;
  @ViewChild('cbBlNettChargePlaceOfIssueConsole')
  cbBlNettChargePlaceOfIssueConsole: ComboPluginComponent;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  // GSO-992 GSO-993 Additional Checkbox for "Reworked Container"
  @ViewChild('cbBlContainerReworkedVessel') cbBlContainerReworkedVessel: ComboPluginComponent;
  @ViewChild('cbBlContainerReworkedVoyage') cbBlContainerReworkedVoyage: ComboPluginComponent;

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog: any;
  window: Window;

  loading = false;
  lock = true;
  lockSOB = false;
  IsLockPayer1 = false;
  IsLockPayer2 = false;
  IsLockPayer3 = false;
  isLoad: Boolean = false;
  isError: Boolean = false;
  isFullSaveClicked: Boolean = false;
  isInsideRetrieveMode: Boolean = false;
  isRefreightButtonClicked: Boolean = false;
  isRefreightProcess: Boolean = false;
  isRefreightButtonClickedConsole: Boolean = false;
  isRefreightProcessConsole: Boolean = false;
  isDiscrepancyExisted: Boolean = false;
  refreightData: any;
  cancelButtonClickedCount: number = 0;
  isOpenedByOtherUser;
  Boolean = false;
  headerExtraFromBL:Boolean=false;

  isRefreightInvokedFromFinal: Boolean = false;
  isRefreightInvokedFromFinalConsole: Boolean = false;

  isOpenedFromFlagStatus: Boolean = false;
  isOpenedFromCrossRef: Boolean = false;
  isOpenedFromCrossBookRef: Boolean = false;
  isOpenedFromInvoiceFlagStatus: Boolean = false;
  isOpenedFromChinaBlProcess: Boolean=false;
  validatorRules = {};

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = 'back';
  disableToolbarButtons = '';

  modeToolbar = false;
  currentMenu;
  viewOnlyStatus = false;
  showTooltip = '';

  /* Parameter settings */
  settingToolbar;
  // blDegreeTemp: String = 'C';

  // *** 01. Party ***//
  settingTxtBlPartyShipperAddress;
  settingCbBlPartyShipperAddress;
  settingCbNettChargeBillingParty1Address;
  settingCbNettChargeBillingParty2Address;
  settingCbNettChargeBillingParty3Address;
  settingCbBlPartyShipper;
  settingTxtBlPartyConsigneeAddress;
  settingCbBlPartyConsigneeAddress;
  settingCbBlPartyConsignee;
  settingTxtBlPartyNotifyPartyAddress;
  settingCbBlPartyNotifyPartyAddress;
  settingCbBlPartyNotifyParty;

  isLockBLTypeOBL = false;
  // chkSkipPrintShipperDraftBlRef: Boolean = false;

  // *** 02. Place ***//
  settingCbBlPlaceReceiptType;
  settingCbBlPlaceDeliveryType;
  settingCbBlPlacePOR;
  settingCbBlPlacePOL;
  settingCbBlPlacePOD;
  settingCbBlPlacePODL;
  settingCbBlPlacePOT1;
  settingCbBlPlacePOT2;
  settingCbBlPlacePOT3;
  settingCbBlPlaceFDEST;
  settingCbBlPlaceService;
  settingCbBlPlacePrincipal;
  settingCbBlPlacePreVessel;
  // settingCbBlPlacePreVoyage;
  settingCbBlPlaceOceanVessel;
  settingCbBlPlaceOceanVoyage;
  settingCbBlPlaceNextVessel;
  // settingCbBlPlaceNextVoyage;
  chkBlPlacePreVesselSkipCheck: Boolean = false;
  chkBlPlacePreVesselPrintOceanVesselClauseOnBl: Boolean = false;
  chkBlPlaceOceanVesselPrintPreVesselClauseOnBl: Boolean = false;
  chkBlPlaceNextVesselSkipCheck: Boolean = false;
  chkBlPlaceNextVesselPrintOceanVesselClauseOnBl: Boolean = false;
  chkBlPlaceNextVesselPrintClauseOnBl: Boolean = false;

  // frmPlaceFinalDestinationLock: Boolean = true;
  blPlacePlaceOfReceiptPrintLock: Boolean = false;
  blPlaceLoadPortPrintLock: Boolean = false;
  blPlaceDischargePortPrintLock: Boolean = false;
  blPlacePlaceOfDeliveryPrintLock: Boolean = false;
  blPlacePrintBlFinalDestination: Boolean = false;
  blPlacePrintBlFinalDestination2: Boolean = false;
  blPlaceDataFromBooking: Boolean = false;

  // *** 03. Container ***//
  settingCbBlContainerReceiptType;
  settingCbBlContainerDeliveryType;
  settingCbBlContainerPackageType;
  settingCbBlContainerContainerType;
  settingCbBlContainerContainerSize;
  settingCbBlContainerContainerHeight;
  settingCbBlContainerContainerDGClass;
  settingGridBlContainerHS;
  settingCbBlContainerHS;
  settingGridBlContainer;
  btnBlContainerNew: Boolean = true;
  btnBlContainerSave: Boolean = false;
  btnBlContainerDelete: Boolean = false;
  btnBlContainerCancel: Boolean = false;
  btnBlContainerHSNew: Boolean = false;
  btnBlContainerHSSave: Boolean = false;
  btnBlContainerHSDelete: Boolean = false;
  btnBlContainerHSCancel: Boolean = false;
  frmContainerLock: Boolean = true;
  frmContainerHSLock: Boolean = true;
  blContainerUpdateMode: String = 'insert';
  blContainerHSUpdateMode: String = 'insert';
  isErrorContainer: Boolean = false;
  txtBlContainerSaveUpdate: String = 'Save';
  txtBlContainerHSSaveUpdate: String = 'Save';
  settingCbBlContainerFlashPointScale;
  settingCbBlContainerOogScale;
  settingCbBlContainerReeferScale;
  dgClassLock: Boolean = true;
  settingCbBlContainerShortShipmentVessel;
  settingCbBlContainerShortShipmentVoyage;

  // GSO-992 GSO-993
  settingCbBlContainerReworkedVessel;
  settingCbBlContainerReworkedVoyage;

  // yg-sbhd
  columnDefsADContainer;
  rowDataADContainer: any = [];
  getRowStyleADContainer;

  columnDefsContainerHs;
  rowDataContainerHs: any = [];

  allowSpacingContainerNo: Boolean = false;
  settingCbBlAdContainerCommodity
  settingCbBlAdContainerInnerPkgType
  settingCbBlAdContainerOuterPkgType
  settingGridBlAdContainer;
  settingCbBlAdContainerDGClass;
  settingCbBlAdContainerFlashPointScale;
  settingCbBlAdContainerHsCode;
  settingCbBlAdContainerHsName;
  settingTxtBlContainerDesc;

  btnBlAdContainerNew: Boolean = false;
  btnBlAdContainerSave: Boolean = false;
  btnBlAdContainerDelete: Boolean = false;
  btnBlAdContainerCancel: Boolean = false;
  frmAdContainerLock: Boolean = true;
  isErrorAdCode: Boolean = false;
  blAdContainerUpdateMode: String = 'insert';
  txtBlAdContainerSaveUpdate: String = 'Save';

  blContainerQuantityLock: Boolean = true;
  blContainerPackageTypeLock: Boolean = true;
  blContainerGrossWeightLock: Boolean = true;
  blContainerGrossMeasureLock: Boolean = true;
  blContainerNettWeightLock: Boolean = true;
  blContainerNettMeasureLock: Boolean = true;

  // GOS-662 Container Tab - Add "VGM" field
  blContainerVGMLock: Boolean = true;
  disableBlContainerVGMLock: Boolean = true;

  blDescVgmLock: Boolean = true;

  disableBlContainerQuantityLock: Boolean = true;
  disableBlContainerPackageTypeLock: Boolean = true;
  disableBlContainerGrossWeightLock: Boolean = true;
  disableBlContainerGrossMeasureLock: Boolean = true;
  disableBlContainerNettWeightLock: Boolean = true;
  disableBlContainerNettMeasureLock: Boolean = true;

  blContainerDescPrint: Boolean = false;
  blContainerDescLock: Boolean = false;
  blContainerAdPrint: Boolean = false;
  disableBlContainerDescPrint: Boolean = true;
  disableBlContainerDescLock: Boolean = true;
  disableBlContainerAdPrint: Boolean = true;

  tempBlContainerCommodityTypeId: string = "";
  tempBlContainerCommodityTypeName: string = "";
  tempBlContainerDesc: string = "";

  // *** 04. Mark No. ***//
  settingGridBlMarkNo;
  settingTxtBlMarkNoDesc;
  btnBlMarkNoNew: Boolean = true;
  btnBlMarkNoSave: Boolean = false;
  btnBlMarkNoDelete: Boolean = false;
  btnBlMarkNoCancel: Boolean = false;
  btnBlMarkNoNoMark: Boolean = true;
  frmMarkNoLock: Boolean = true;
  blMarkNoUpdateMode: String = 'insert';
  isErrorMarkNo: Boolean = false;
  txtBlMarkNoSaveUpdate: String = 'Save';

  isErrorHSCode: Boolean = false;
  blDescUpdate: Boolean = false;
  blDescDelete: Boolean = false;
  blDescCancel: Boolean = false;
  disableNewUpdateChargeButton: Boolean = false;

  lockParty: Boolean = false;
  lockPlace: Boolean = false;
  lockContainer: Boolean = false;
  lockMarkNo: Boolean = false;
  lockDesc: Boolean = false;
  lockNettCharge: Boolean = false;
  lockGrossCharge: Boolean = false;
  lockNettChargeConsole: Boolean = false;
  lockGrossChargeConsole: Boolean = false;
  validSched: Boolean = false;
  // *** 05. Desc***//
  settingGridBlDesc;
  settingTxtBlDescTotalContainerPackageInWord;
  settingCbBlDescAgentId;
  settingTxtBlDescAgentNameAddress;
  settingGridBlDescClause;
  // settingTxtBlDescClauseDescription;
  settingCbBlDescClauses;

  settingCbBlDescPSAPackageType;
  settingCbBlDescReceiptType;
  settingCbBlDescDeliveryType;
  settingCbBlDescInnerPkgType;
  settingCbBlContainerInnerPkgType;
  settingCbBlContainerCommodity;
  settingCbBlDescOuterPkgType;
  settingCbBlDescCommodityCode;
  settingTxtBlDescDescriptionOfGoods;
  isBlDescAgentIdPrinted: Boolean = false;
  isErrorDesc: Boolean = false;
  isErrorDescClause: Boolean = false;
  btnBlDescClauseNew: Boolean = true;
  btnBlDescClauseSave: Boolean = false;
  btnBlDescClauseDelete: Boolean = false;
  btnBlDescClauseCancel: Boolean = false;
  blDescClauseUpdateMode: String = 'insert';
  frmDescClauseLock: Boolean = true;

  isDisableInnerPkg: Boolean = false; // to disable the inner pkg on INA
  isDisableContainerInnerPkg: Boolean = false;

  chkBlDescOuterQuantityPrint: Boolean = true;
  chkBlDescOuterPkgTypePrint: Boolean = true;

  // *** 06. Nett Charge***//
  settingCbBlNettChargePrepaid1;
  settingCbBlNettChargePayableAt1;
  settingCbBlNettChargeBillingParty1;
  settingCbBlNettChargePrepaid2;
  settingCbBlNettChargePayableAt2;
  settingCbBlNettChargeBillingParty2;
  settingCbBlNettChargePrepaid3;
  settingCbBlNettChargePayableAt3;
  settingCbBlNettChargeBillingParty3;
  settingTxtBlNettChargeRemarks;
  settingTxtBlNettChargeRemarksConsole;
  settingTxtBlNettChargeNewRemarks;
  settingGridBlNettCharge;
  settingGridBlNettChargeConsole;
  settingDateNettChargeDateOfIssue;
  settingDateNettChargeDateOfIssueConsole;
  settingDateNettChargeExchangeRate;
  settingDateNettChargeExchangeRateConsole;
  settingDateNettChargeShippedOnBoard;
  isErrorNettCharge: Boolean = false;
  isErrorNettChargeConsole: Boolean = false;
  isErrorNettChargeEntry: Boolean = false;
  isErrorNettChargeEntryConsole: Boolean = false;
  txtBlContainerInsertUpdate = 'Insert';
  txtBlContainerInsertUpdateConsole = 'Insert';
  blNettChargeUpdateMode = 'insert';
  blNettChargeUpdateModeConsole = 'insert';
  nettChargeDisableDelete: Boolean = false;
  nettChargeDisableDeleteConsole: Boolean = false;

  btnBlExchRateClauseNew: Boolean = true;
  btnBlExchRateClauseUpdate: Boolean = false;
  btnBlExchRateClauseDelete: Boolean = false;
  btnBlExchRateClauseCancel: Boolean = false;
  blExchRateUpdateMode: String = 'insert';
  blExchRateUpdateLock: Boolean = true;
  isErrorBlExchRate: Boolean = false;

  settingCbBlNettChargeInputCharge;
  settingCbBlNettChargeInputChargeConsole;

  settingCbBlNettChargeInputPC;
  settingCbBlNettChargeInputPCConsole;

  settingCbBlNettChargeInputPerCode;
  settingCbBlNettChargeInputPerCodeConsole;

  settingCbBlNettChargeInputPayer;
  settingCbBlNettChargeInputPayerConsole;

  settingCbBlNettChargeInputPlaceOfPaymentConsole;

  settingCbBlNettChargeInputCurrency;
  settingCbBlNettChargeInputCurrencyConsole;

  settingGridBlNettChargeExchangeRate;
  settingCbBlNettChargeExchangeRateCurrency;
  settingCbBlNettChargePlaceOfIssue;
  settingCbBlNettChargePlaceOfIssueConsole;

  // ** Form Control **//
  modeFormBlContainer = ''; // view
  modeFormBlDesc = ''; // view
  modeFormBlNettCharge = '';
  modeFormBlNettChargeConsole = ''; // view
  modeFormCoba = 'ELSE';
  adminZen = 'ELSE';

  valTareWeight:number = 0;

  // This form model
  model = new BLHeader();

  paramContainer = new BLContainer();
  paramMarkNo = new BLMarkNo();
  paramDesc = new BLDesc();
  paramDescClause = new BLDescClause();
  paramDescClauseTemp = new BLDescClause();
  paramFreightCharge = new BLFreightCharge();
  paramFreightChargeConsole = new BLFreightCharge();
  paramContainerHSCode = new BLContainerHSCode();
  paramBlAdContainer = new BLAdContainer();
  paramContainerExtra = new BLContainerExtra();
  paramExchangeRate = new BLExchangeRate();

  frmDialogMessage = '';
  strParams: string = '';
  blPrintDialogMessage = '';

  tabStatus = 'tab1';
  tabStatus1 = 'active';
  tabStatus2 = 'active';
  tabStatus3 = 'active';
  tabStatus4 = 'active';
  tabStatus5 = 'active';
  tabStatus6 = 'active';
  tabStatus7 = 'active';
  tabStatus8 = 'active';
  tabStatus9 = 'active';

  // Tab Lock Control
  lockTab1 = false;
  lockTab2 = false;
  lockTab3 = false;
  lockTab4 = false;
  lockTab5 = false;
  lockTab6 = false;
  lockTab8 = false;

  lockAddressRetrieve: Boolean = false;
  lockAddressRetrieve1: Boolean = false;
  lockAddressRetrieve2: Boolean = false;

  blLockUserid = '';
  blLock = '';
  blmode = '';
  blIsDeleted = '';

  netChargeLock = false;

  // NVOCC
  nvoccIndicator: String = '';

  // PRINT
  printFrom: string = '';
  printType: string = '';
  userId: string = '';
  userIdCode: string = '';
  officeCode: string = '';
  BLPrint: string = 'N';
  hasAttch: boolean = false;
  settingPDF;
  printLoading = false;
  isFinished: boolean = false;
  hasOuter: boolean = false;

  // This is stupid
  tmpDateOfIssue: String = '';
  tmpDateOfIssueConsole: String = '';
  tmpExchangeRateDate: String = '';
  tmpExchangeRateDateConsole: String = '';
  tmpShippedOnBoard : String ='';
  dgNos : string = '[{ "blContainerDgClassCode" : "NA", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

  isBLFNewEntry: Boolean = false;
  isBLFNewEntryConsole: Boolean = false;

  isExch: String = 'WithExch';

  // must store the value of ocean vessel upon retrieval, to be compared upon refreight
  // bizapp request 10X tester (duh)
  originalOceanVesselName: String = '';
  originalOceanVesselId: String = '';
  originalOceanVesselCode: String = '';
  originalOceanVesselVoyage: String = '';
  originalOceanVesselBound: String = '';

  blGrossWeightNum: number = 0;
  blNettWeightNum: number = 0;
  blGrossMeasureNum: number = 0;

  //New var
  blNettChargeCheckBoxOIS = 'Print Loading Vessel/Voyage Clause';

  blPlaceServiceName = '';

  //exchangeRateBlVal:Number = 0
  myOptions = {
    allowDecimalPadding: true,
    decimalPlaces: 4,
    maximumValue: '10000000',
    digitGroupSeparator: '',
    leadingZero: 'keep',
    decimalPlacesRawValue: 4
  };

  isPreVesselPrinted: Boolean = true;

  jasper: jasperModel;

  printOnlyOnceList = [];

  inaMode: Boolean = false;

  sCharUtil = new specialCharUtil();

  hsDialogOpt;
  adDialogOpt;

  custIdforCbShipper = '*';
  dateIssueforCbShipper = '*';

  cneeIdforCbCnee = '*';
  partyIdforCbParty = '*';

  custIdForCbPayer1 = '*';
  custIdForCbPayer2 = '*';
  custIdForCbPayer3 = '*';

  payer1IsPayer = '';
  payer1IsValid = '';
  payer2IsPayer = '';
  payer2IsValid = '';
  payer3IsPayer = '';
  payer3IsValid = '';
  listPyrFromRefreight = [];

  temporaryBlAdContainerAdditionalGrossWeight = 0;
  temporaryBlAdContainerAdditionalGrossMeasure = 0;
  blContainerGrossWeightAdditionalString = "";
  blContainerGrossMeasureAdditionalString = "";
  totalGrossMeas = 0;
  totalGrossWt = 0;
  totalGrossMeasblDescAwkward = "0";
  totalblDescGrossWeightLock = "0";
  totalVGM = 0;
  totalblDescNettWeightLock = "0";
  totalblDescNettMeasureLock = "0";

  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
  isErrorSeq: boolean;
  wordInvalid = [];
  lineInvalid = [];
  paramDescValidateMessage = [];
  totalNettMeasure = 0;
  totalNettWeight = 0;
  temporaryBlAdContainerAdditionalNettWeight: number;
  blContainerNettWeightAdditionalString: any;

  bookingDataDetail: any = [];
  containerQuantityDGClass: any = [];
  changeDataFirstTime = "";

   site=this.cookieService.getDefaultLocationCode(); //added for CHINA site

  regexPatternAlphanumeric = /^[a-zA-Z0-9#:,./-]*$/; //(Alphanumeric) – No special characters expect ( # - / : , . )

  get isOfficeInIndia() {
    const officeCode = this.cookieService.getDefaultLocationCode();
    return officeCode.substring(0, 2) === 'IN';
  }

  // GSO-992 - GSO-993 Logic, Additional Checkbox for "Reworked Container"
  @ViewChild('containerNoReworked') containerNoReworked: ElementRef;
  isReworkedChecked = false;
  isDisabledReworked = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();
    console.log('** constructor **');

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Retrieve', event: 'retrieve', icon: 'search' },
        { name: 'Partial Save', event: 'partialSave', icon: 'save' },
        { name: 'Partial Save2', event: 'partialSave2', icon: 'save' },
        { name: 'Save', event: 'save', icon: 'save' },
        { name: 'Save2', event: 'save2', icon: 'save' },
        { name: 'Print', event: 'print', icon: 'print' },
        { name: 'Delete', event: 'delete', icon: 'remove' },
        { name: 'Back', event: 'back', icon: 'left arrow' },
        { name: 'Cancel', event: 'cancel', icon: 'remove' },
        { name: 'Close', event: 'close', icon: 'remove circle' },
        { name: 'Transmit to IAS', event: 'ias', icon: 'exchange' },
        { name: "Sync", event: "sync", icon: "" },
        /*
        { name: 'Lock', event: 'lock', icon: 'lock' },
        { name: 'Unlock', event: 'unlock', icon: 'unlock' },
        */
        // { name: 'Print Invoice', event: 'printInvoice', icon: 'print' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Maintenances',
    };

    // *** 01. Party Tab *** //
    this.settingCbBlPartyShipper = {
      id: 'cbBlPartyShipper',
      type: 'search enter', // search | select | select input
      url: '',
        // this.configService.config.BASE_API.toString() +
        // '/MasterCustomers/findByComboBoxControlShipper/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {
          header: 'Customer Name',
          field: 'customerName',
          width: 300,
          type: 'string',
        },
        { header: 'NVOCC?', field: 'isNvocc', width: 125 },
        { header: 'Valid?', field: 'isShipper', width: 125 },
      ],
    };
    this.settingCbBlPartyShipperAddress = {
      id: 'cbBlPartyShipperAddress',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqno',
      title: 'customerSeqno',
      allowRemove: false,
    };

    this.settingCbNettChargeBillingParty1Address = {
      id: 'cbBlNettChargeBillingParty1Address',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqAndDisplay',
      title: 'customerSeqAndDisplay',
      allowRemove: false,
      textOverflow:true,
    };
    this.settingCbNettChargeBillingParty2Address = {
      id: 'cbBlNettChargeBillingParty2Address',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqAndDisplay',
      title: 'customerSeqAndDisplay',
      allowRemove: false,
      textOverflow:true,
    };
    this.settingCbNettChargeBillingParty3Address = {
      id: 'cbBlNettChargeBillingParty3Address',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqAndDisplay',
      title: 'customerSeqAndDisplay',
      allowRemove: false,
      textOverflow:true,
    };


    /*
    this.settingTxtBlPartyShipperAddress = {
      id: 'txtBlPartyShipperAddress',
      name: 'clauseName',
      placeholder: 'Shipper Address',
      cols: 30,
      rows: 6,
      maxlength: 255,
      border: 300,
    };
    */
    this.settingTxtBlPartyShipperAddress = {
      id: 'txtBlPartyShipperAddress',
      name: 'clauseName',
      placeholder: 'Shipper Address',
      cols: 35,
      rows: 6,
      border: '35,20',
    };
    this.settingCbBlPartyConsignee = {
      id: 'cbBlPartyConsignee',
      type: 'search enter', // search | select | select input
      url: '',
        // this.configService.config.BASE_API.toString() +
        // '/MasterCustomers/findByComboBoxControlConsignee/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {
          header: 'Customer Name',
          field: 'customerName',
          width: 300,
          type: 'string',
        },
        { header: 'NVOCC?', field: 'isNvocc', width: 125 },
        { header: 'Valid?', field: 'isConsignee', width: 125 },
      ],
    };
    this.settingCbBlPartyConsigneeAddress = {
      id: 'cbBlPartyConsigneeAddress',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqno',
      title: 'customerSeqno',
      allowRemove: false,
    };
    /*
    this.settingTxtBlPartyConsigneeAddress = {
      id: 'txtBlPartyConsigneeAddress',
      name: 'clauseName',
      placeholder: 'Consignee Address',
      cols: 30,
      rows: 6,
      maxlength: 255,
      border: 300,
    };
    */
    this.settingTxtBlPartyConsigneeAddress = {
      id: 'txtBlPartyConsigneeAddress',
      name: 'clauseName',
      placeholder: 'Consignee Address',
      cols: 35,
      rows: 6,
      border: '35,20',
    };
    this.settingCbBlPartyNotifyParty = {
      id: 'cbBlPartyNotifyParty',
      type: 'search enter', // search | select | select input
      url: '',
        // this.configService.config.BASE_API.toString() +
        // '/MasterCustomers/findByComboBoxControlNotifyParty/customerName={query}',
      maxChars: 3,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'customerName',
      description: '',
      isMark: true,
      columns: [
        {
          header: 'Customer Name',
          field: 'customerName',
          width: 300,
          type: 'string',
        },
        { header: 'NVOCC?', field: 'isNvocc', width: 125 },
        { header: 'Valid?', field: 'isNotifyParty', width: 125 },
      ],
    };
    this.settingCbBlPartyNotifyPartyAddress = {
      id: 'cbBlPartyNotifyPartyAddress',
      type: 'select', // search | select | select input
      url: '',
      urlType: 'dynamic',
      placeholder: '',
      code: 'customerSeqno',
      title: 'customerSeqno',
      allowRemove: false,
    };
    /*
    this.settingTxtBlPartyNotifyPartyAddress = {
      id: 'txtBlPartyNotifyPartyAddress',
      name: 'clauseName',
      placeholder: 'Notify Party Address',
      cols: 30,
      rows: 6,
      maxlength: 255,
      border: 300,
    };
    */
    this.settingTxtBlPartyNotifyPartyAddress = {
      id: 'txtBlPartyNotifyPartyAddress',
      name: 'clauseName',
      placeholder: 'Notify Party Address',
      cols: 35,
      rows: 6,
      border: '35,20',
    };

    // *** 02. Place Tab *** //
    this.settingCbBlPlaceReceiptType = {
      id: 'cbBlPlaceReceiptType',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'receiptTypeCode',
      description: '',
      isMark: true,
      columns: [
        { header: 'Receipt Type Code', field: 'receiptTypeCode', width: 200 },
        { header: 'Receipt Type Name', field: 'receiptTypeName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlaceDeliveryType = {
      id: 'cbBlPlaceDeliveryType',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeLike={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'deliveryTypeCode',
      description: '',
      isMark: true,
      columns: [
        { header: 'Delivery Type Code', field: 'deliveryTypeCode', width: 200 },
        { header: 'Delivery Type Name', field: 'deliveryTypeName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOR = {
      id: 'cbBlPlacePOR',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOL = {
      id: 'cbBlPlacePOL',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOD = {
      id: 'cbBlPlacePOD',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePODL = {
      id: 'cbBlPlacePODL',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOT1 = {
      id: 'cbBlPlacePOT1',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOT2 = {
      id: 'cbBlPlacePOT2',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePOT3 = {
      id: 'cbBlPlacePOT3',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlaceFDEST = {
      id: 'cbBlPlaceFDEST',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'locationName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'locationCode', width: 175 },
        { header: 'Location Name', field: 'locationName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePrincipal = {
      id: 'cbBlPlacePrincipal',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterPrincipals/findByComboBoxControlPrincipal/principalName={query}',
      maxChars: 0,
      maxlength: 50,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'principalName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'principalCode', width: 175 },
        { header: 'Location Name', field: 'principalName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlaceService = {
      id: 'cbBlPlaceService',
      type: 'search enter', // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        '/MasterServices/findByComboBoxControlService/serviceName={query}',
      maxChars: 0,
      maxlength: 50,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'serviceCode',
      description: '',
      isMark: true,
      columns: [
        { header: 'Location Code', field: 'serviceCode', width: 175 },
        { header: 'Location Name', field: 'serviceName', width: 300 },
        { header: 'Valid?', field: 'isValid', width: 50 },
      ],
    };
    this.settingCbBlPlacePreVessel = {
      id: "cbBlPlacePreVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    /*
     this.settingCbBlPlacePreVoyage = {
     id          : 'cbBlPlacePreVoyage',
     type        : 'search enter', // search | select | select input
     url         : '',
     maxChars    : 0,
     template    : 'grid', // default
     placeholder : '-- Voyage --',
     title       : 'voyage',
     description  : '',
     isMark      : true,
     columns     : [
     {header: 'Voyage', field: 'voyage', width: 100},
     {header: 'Bound', field: 'bound', width: 100},
     {header: 'Service', field: 'serviceCode', width: 100}
     ]
     };
     */
    this.settingCbBlPlaceOceanVessel = {
      id: "cbBlPlaceOceanVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlPlaceOceanVoyage = {
      id: "cbBlPlaceOceanVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };
    this.settingCbBlPlaceNextVessel = {
      id: "cbBlPlaceNextVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    /*
     this.settingCbBlPlaceNextVoyage = {
     id          : 'cbBlPlaceNextVoyage',
     type        : 'search enter', // search | select | select input
     url         : '',
     maxChars    : 0,
     template    : 'grid', // default
     placeholder : '-- Voyage --',
     title       : 'voyage',
     description  : '',
     isMark      : true,
     columns     : [
     {header: 'Voyage', field: 'voyage', width: 100},
     {header: 'Bound', field: 'bound', width: 100},
     {header: 'Service', field: 'serviceCode', width: 100}
     ]
     };
     */

    // *** 03. Container Tab *** //
    this.settingGridBlContainer = {
      id: "gridBlContainer",
      url: "",
      page: 25,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        {
          header: "Skip Check",
          field: "blContainerSkipCheckString",
          width: 75,
        },
        { header: "Container Number", field: "blContainerNumber", width: 75 },
        {
          header: "Seal No. (1)",
          field: "blContainerSealNo1",
          width: 100,
          type: "string",
        },
        {
          header: "Seal No. (2)",
          field: "blContainerSealNo2",
          width: 100,
          type: "string",
        },
        {
          header: "Seal No. (3)(Cont. Type)",
          field: "blContainerSealNo3",
          width: 200,
          type: "string",
        },
        {
          header: "Qty",
          field: "blContainerQuantity",
          width: 100,
          type: "string",
        },
        {
          header: "Pkg Type",
          field: "blContainerPackageTypeName",
          width: 100,
          type: "string",
        },
        {
          header: "Gross Wt.",
          field: "blContainerGrossWeightString",
          width: 100,
          type: "string",
        },
        {
          header: "Gross Meas.",
          field: "blContainerGrossMeasureString",
          width: 100,
          type: "string",
        },
        { header: "Nett Weight (KGS)", field: "blContainerNettWeightString", width: 110 },
        { header: "Nett Measurement (CMB)", field: "blContainerNettMeasureString", width: 110 },
        {
          header: "Receipt Type",
          field: "blContainerReceiptTypeCode",
          width: 100,
          type: "string",
        },
        {
          header: "Delivery Type",
          field: "blContainerDeliveryTypeCode",
          width: 100,
          type: "string",
        },
        {
          header: "Empty",
          field: "blContainerIsEmptyString",
          width: 100,
          type: "string",
        },
        {
          header: "Dry",
          field: "blContainerIsDryString",
          width: 100,
          type: "string",
        },
        {
          header: "Short Ship",
          field: "blContainerShortShipContainerString",
          width: 110,
        },
        { header: "Type", field: "blContainerTypeName", width: 110 },
        { header: "Size", field: "blContainerSizeName", width: 110 },
        { header: "Height", field: "blContainerHeightName", width: 110 },
        {
          header: "Special Details",
          field: "blContainerSpecialDetailCode",
          width: 110,
        },
        {
          header: "Special Type",
          field: "blContainerSpecialTypeName",
          width: 110,
        },
        { header: "DG Class", field: "blContainerDGClass", width: 110 },
        { header: "IMO", field: "blContainerIMO", width: 110 },
        { header: "UNNO", field: "blContainerUNNO", width: 110 },
        {
          header: "F/P",
          field: "blContainerFlashPointTemperature",
          width: 110,
        },
        { header: "Scale", field: "blContainerFlashPointScale", width: 110 },
        {
          header: "Reefer Temp",
          field: "blContainerReeferTemperature",
          width: 110,
        },
        { header: "Scale", field: "blContainerReeferScale", width: 110 },
        { header: "OH", field: "blContainerOogOH", width: 110 },
        { header: "OWL", field: "blContainerOogOWL", width: 110 },
        { header: "OWR", field: "blContainerOogOWR", width: 110 },
        { header: "OLF", field: "blContainerOogOLF", width: 110 },
        { header: "OLB", field: "blContainerOogOLB", width: 110 },
        { header: "Scale", field: "blContainerOogScale", width: 110 },
        { header: "VGM (KGS)", field: "blContainerVgm", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
      enableHideColumns: false,
      sortingColumns: "dateModified",
      sortingDirection: "DESC",
      minHeight: 200,
      height: 200,
    };

    this.settingCbBlContainerReceiptType = {
      id: "cbBlContainerReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "R/Type Code", field: "receiptTypeCode", width: 175 },
        { header: "R/Type Name", field: "receiptTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlContainerDeliveryType = {
      id: "cbBlContainerDeliveryType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "deliveryTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "D/Type Code", field: "deliveryTypeCode", width: 175 },
        { header: "D/Type Name", field: "deliveryTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlContainerPackageType = {
      id: "cbBlContainerPackageType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlContainerContainerType = {
      id: "cbBlContainerContainerType",
      type: "select", // search | select | select input
      url: '[{ "blContainerTypeCode" : "STD", "blContainerTypeName" : "NORMAL"},{ "blContainerTypeCode" : "REF", "blContainerTypeName" : "REEFER" },{ "blContainerTypeCode" : "TNK", "blContainerTypeName" : "TANK" },{ "blContainerTypeCode" : "OPT", "blContainerTypeName" : "OPEN TOP" },{ "blContainerTypeCode" : "FLR", "blContainerTypeName" : "FLAT RACK" },{ "blContainerTypeCode" : "PLT", "blContainerTypeName" : "PLATFORM" },{ "blContainerTypeCode" : "UC", "blContainerTypeName" : "UC" }]',
      urlType: "inject",
      placeholder: "Search ...",
      code: "blContainerTypeCode",
      title: "blContainerTypeName",
    };
    this.settingCbBlContainerContainerSize = {
      id: "cbBlContainerContainerSize",
      type: "select", // search | select | select input
      url: '[{ "blContainerSizeCode" : "20", "blContainerSizeName" : "20" },{ "blContainerSizeCode" : "40", "blContainerSizeName" : "40" },{ "blContainerSizeCode" : "45", "blContainerSizeName" : "45" }]',
      urlType: "inject",
      placeholder: "SEARCH ...",
      code: "blContainerSizeCode",
      title: "blContainerSizeName",
    };
    this.settingCbBlContainerContainerHeight = {
      id: "cbBlContainerContainerHeight",
      type: "select", // search | select | select input
      url: '[{ "blContainerHeightCode" : "86", "blContainerHeightName" : "86" },{ "blContainerHeightCode" : "96", "blContainerHeightName" : "96" }]',
      urlType: "inject",
      placeholder: "Search ...",
      code: "blContainerHeightCode",
      title: "blContainerHeightName",
    };
    /*
     this.settingCbBlContainerContainerSize = {
     id          : 'cbBlContainerContainerSize',
     type        : 'select', // search | select | select input
     url         : 'assets/json/bl/blContainerSize.json',
     urlType     : 'inject',
     placeholder : 'SEARCH ...',
     code        : 'blContainerSizeCode',
     title       : 'blContainerSizeName',
     };
     */
    this.settingCbBlContainerContainerDGClass = {
      id: "cbBlContainerContainerDGClass",
      type: "select", // search | select | select input
      url: '[{ "blContainerDgClassCode" : "NA", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]',
      urlType: "inject",
      placeholder: "Search ...",
      code: "blContainerDgClassCode",
      title: "blContainerDgClassName",
    };

    this.settingGridBlContainerHS = {
      id: "gridBlContainerHS",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "HS Code", field: "blContainerHSCode", width: 100 },
        { header: "HS Desc", field: "blContainerHSDescription", width: 300 },
        { header: "Print", field: "blContainerHSPrintString", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      minHeight: 100,
      height: 100,
    };

    this.settingCbBlContainerHS = {
      id: "cbBlContainerHS",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "hsDescription",
      description: "",
      isMark: true,
      columns: [
        { header: "HS Code", field: "hsCode", width: 175 },
        { header: "HS Description", field: "hsDescription", width: 300 },
      ],
    };

    // yg-sbhd
    this.settingCbBlAdContainerHsCode = {
      id: "cbBlAdContainerHsCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "hsCode",
      description: "",
      isMark: true,
      columns: [
        { header: "HS Code", field: "hsCode", width: 175 },
        { header: "HS Description", field: "hsDescription", width: 300 },
      ],
    };

    this.settingCbBlAdContainerHsName = {
      id: "cbBlAdContainerHsName",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "hsDescription",
      description: "",
      isMark: true,
      columns: [
        { header: "HS Code", field: "hsCode", width: 175 },
        { header: "HS Description", field: "hsDescription", width: 300 },
      ],
    };

    this.settingGridBlAdContainer = {
      id: "gridBlAdContainer",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 10 },
        { header: "Commodity", field: "blAdContainerCommodityTypeName", width: 20 },
        { header: "Inner Quantity", field: "blAdContainerInnerQty", width: 20 },
        { header: "Inner Package Type", field: "blAdContainerInnerPkgTypeName", width: 20 },
        { header: "DG CLS", field: "blAdContainerDGClassName", width: 20 },
        { header: "IMO", field: "blAdContainerIMO", width: 20 },
        { header: "UNNO", field: "blAdContainerUNNO", width: 20 },
        { header: "F/P", field: "blAdContainerFlashPoint", width: 20 },
        { header: "Scale", field: "blAdContainerFlashPointScale", width: 20 },
        { header: "Weight (KGS)", field: "blAdContainerGrossWeightString", width: 20 },
        { header: "Measurement (CBM)", field: "blAdContainerGrossMeasureString", width: 20 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      minHeight: 100,
      height: 100,
    };

    this.columnDefsContainerHs = [
      { headerName: "Seq", field: "blContainerHSSeq", width: 50 },
      { headerName: "HS Code", field: "blContainerHSCode" },
      { headerName: "HS Desc", field: "blContainerHSDescription" },
      { headerName: "Print", field: "blContainerHSPrintString" }
    ];

    this.columnDefsADContainer = [
      { headerName: "Seq", field: "blAdContainerSeq", width: 100 },
      { headerName: "Commodity", field: "blAdContainerCommodityTypeName", cellStyle: { 'text-transform': 'uppercase' } },
      { headerName: "HS Code", field: "blAdContainerHsCode" },
      { headerName: "HS/Product Desc.", field: "blAdContainerHsName" },
      { headerName: "Inner Quantity", field: "blAdContainerInnerQty" },
      { headerName: "Inner Package Type", field: "blAdContainerInnerPkgTypeName" },
      { headerName: "DG CLS", field: "blAdContainerDGClassName" },
      { headerName: "IMO", field: "blAdContainerIMO" },
      { headerName: "UNNO", field: "blAdContainerUNNO" },
      { headerName: "F/P", field: "blAdContainerFlashPoint" },
      { headerName: "Scale", field: "blAdContainerFlashPointScale" },
      { headerName: "Weight (KGS)", field: "blAdContainerGrossWeightString" },
      { headerName: "Measurement (CBM)", field: "blAdContainerGrossMeasureString" },
    ];

    this.settingCbBlAdContainerDGClass = {
      id: "cbBlAdContainerDGClass",
      type: "select", // search | select | select input
      url: '[{ "blContainerDgClassCode" : "NA", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]',
      urlType: "inject",
      placeholder: "Search ...",
      code: "blContainerDgClassCode",
      title: "blContainerDgClassName",
    };

    this.settingCbBlAdContainerCommodity = {
      id: "cbBlAdContainerCommodity",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Commodity",
      title: "commodityName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Commodity Code", field: "commodityCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbBlAdContainerInnerPkgType = {
      id: "cbBlAdContainerInnerPkgType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlAdContainerOuterPkgType = {
      id: "cbBlAdContainerOuterPkgType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlAdContainerFlashPointScale = {
      id: "cbBlAdContainerFlashPointScale",
      type: "select", // search | select | select input
      url: '[{ "flashPointScaleCode" : "", "flashPointScaleName" : "" },{ "flashPointScaleCode" : "C", "flashPointScaleName" : "C" },{ "flashPointScaleCode" : "F", "flashPointScaleName" : "F" }]',
      urlType: "inject",
      placeholder: "",
      code: "flashPointScaleCode",
      title: "flashPointScaleName",
    };
    // yg-sbhd


    this.settingCbBlContainerFlashPointScale = {
      id: "cbBlContainerFlashPointScale",
      type: "select", // search | select | select input
      url: '[{ "flashPointScaleCode" : "", "flashPointScaleName" : "" },{ "flashPointScaleCode" : "C", "flashPointScaleName" : "C" },{ "flashPointScaleCode" : "F", "flashPointScaleName" : "F" }]',
      urlType: "inject",
      placeholder: "",
      code: "flashPointScaleCode",
      title: "flashPointScaleName",
    };
    this.settingCbBlContainerOogScale = {
      id: "cbBlContainerOogScale",
      type: "select", // search | select | select input
      url: '[{ "oogScaleCode" : "CM", "oogScaleName" : "CM" },{ "oogScaleCode" : "M", "oogScaleName" : "M" },{ "oogScaleCode" : "I", "oogScaleName" : "I" },{ "oogScaleCode" : "F", "oogScaleName" : "F" }]',
      urlType: "inject",
      placeholder: "",
      code: "oogScaleCode",
      title: "oogScaleName",
    };
    this.settingCbBlContainerReeferScale = {
      id: "cbBlContainerReeferScale",
      type: "select", // search | select | select input
      url: '[ { "reeferScaleCode" : "", "reeferScaleName" : "" },  { "reeferScaleCode" : "C", "reeferScaleName" : "C" },  { "reeferScaleCode" : "F", "reeferScaleName" : "F" }]',
      urlType: "inject",
      placeholder: "",
      code: "reeferScaleCode",
      title: "reeferScaleName",
    };

    this.settingCbBlContainerShortShipmentVessel = {
      id: "cbBlContainerShortShipmentVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      showOnTop: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlContainerShortShipmentVoyage = {
      id: "cbBlContainerShortShipmentVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      showOnTop: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    // GSO-992 GSO-993 Logic for "Reworked Container" (Change Of Container Number)
    this.settingCbBlContainerReworkedVessel = {
      id: "cbBlContainerReworkedVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Vessel --",
      title: "vesselName",
      description: "",
      isMark: true,
      showOnTop: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 175 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlContainerReworkedVoyage = {
      id: "cbBlContainerReworkedVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "-- Voyage --",
      title: "voyage",
      description: "",
      isMark: true,
      showOnTop: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingTxtBlContainerDesc = {
      id: "txtBlContainerDesc",
      name: "blContainerDesc",
      placeholder: "DESCRIPTION",
      cols: 25,
      rows: 6,
      border: 500,
    };

    // this.settingTxtBlAdContainerHsRemarks = {
    //   id: "txtBlAdContainerHsRemarks",
    //   name: "blAdContainerHsRemarks",
    //   placeholder: "Remark",
    //   cols: 35,
    //   rows: 9,
    //   border: 500,
    // };

    // *** 04. Mark No *** //
    this.settingGridBlMarkNo = {
      id: "gridBlMarkNo",
      url: "",
      page: 5,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        { header: "Mark & No", field: "blMarkNoDescriptionClean", width: 300 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "markNoSeq",
      sortingDirection: "ASC",
      minHeight: 130,
      height: 130,
    };

    /*
    this.settingTxtBlMarkNoDesc = {
      id: 'txtBlMarkNoDesc',
      name: 'clauseName',
      placeholder: 'MARK DESCRIPTION',
      cols: 30,
      rows: 6,
      maxlength: 999999,
      border: 300
    };
    */
    this.settingTxtBlMarkNoDesc = {
      id: "txtBlMarkNoDesc",
      name: "clauseName",
      placeholder: "MARK DESCRIPTION",
      cols: 35,
      rows: 6,
      border: "30,200",
    };

    // *** 05. Desc *** //
    this.settingGridBlDesc = {
      id: "gridBlDesc",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "blDescSeq", width: 75 },
        { header: "Inner Qty", field: "blDescInnerQuantity", width: 75 },
        { header: "Inner Pkg", field: "blDescInnerPackageTypeName", width: 75 },
        { header: "Outer Qty", field: "blDescOuterQuantity", width: 75 },
        { header: "Outer", field: "blDescOuterPackageTypeName", width: 75 },
        { header: "PSA Pkg", field: "blDescPsaPackageTypeName", width: 150 },
        {
          header: "Description of Goods",
          field: "blDescDescriptionTextClean",
          width: 300,
        },
        { header: "Gross Wt.", field: "blDescDg2", width: 100 },
        { header: "Nett Wt.", field: "blDescDg2R", width: 100 },
        { header: "Gross Meas.", field: "blDescAwkward", width: 100 },
        { header: "Nett Meas.", field: "blDescNettMeasure", width: 100 },
        { header: "Receipt Type", field: "blDescReceiptTypeCode", width: 100 },
        {
          header: "Delivery Type",
          field: "blDescDeliveryTypeCode",
          width: 100,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "blDescSeq",
      sortingDirection: "ASC",
      minHeight: 100,
      height: 100,
    };
    /*
     this.settingTxtBlDescTotalContainerPackageInWord = {
      id: 'txtBlDescTotalContainerPackageInWord',
      name: 'clauseName',
      placeholder: '',
      cols: 30,
      rows: 3,
      maxlength: 999999,
      border: 500,
    };
    */
    this.settingTxtBlDescTotalContainerPackageInWord = {
      id: "txtBlDescTotalContainerPackageInWord",
      name: "clauseName",
      placeholder: "",
      cols: 35,
      rows: 3,
      maxlength: 60,
      border: "30,6",
    };

    this.settingCbBlDescAgentId = {
      id: "cbBlDescAgentId",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "agencySeqNo",
      title: "agencySeqNo",
      allowRemove: false,
    };

    /*
    this.settingTxtBlDescAgentNameAddress = {
      id: 'txtBlDescAgentNameAddress',
      name: 'clauseName',
      placeholder: '',
      cols: 30,
      rows: 6,
      maxlength: 999999,
      border: 500,
    };
    */
    this.settingTxtBlDescAgentNameAddress = {
      id: "txtBlDescAgentNameAddress",
      name: "clauseName",
      placeholder: "",
      cols: 35,
      rows: 6,
      border: "32,10",
    };
    this.settingGridBlDescClause = {
      id: "gridBlDescClause",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        { header: "Clause Code", field: "blDescClauseCode", width: 175 },
        { header: "Clause Name", field: "blDescClauseName", width: 300 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "blDescClauseSeq",
      sortingDirection: "ASC",
      minHeight: 100,
      height: 100,
    };
    /*
     this.settingTxtBlDescClauseDescription = {
     id          : 'txtBlDescClauseDescription',
     name        : 'clauseName',
     placeholder : '',
     cols        : 30,
     rows        : 3,
     maxlength   : 255,
     border      : 500,
     };
     */
    this.settingCbBlDescClauses = {
      id: "cbBlDescClauses",
      type: "search enter", // search | select | select input
      //url: this.configService.config.BASE_API.toString() + '/MasterClauses/findByComboBoxControl/clauseCodeOrName={query}',
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "clauseName",
      description: "",
      isMark: true,
      showOnTop: true,
      columns: [
        { header: "Clause Code", field: "clauseCode", width: 175 },
        { header: "Clause Name", field: "clauseName", width: 300 },
      ],
      maxlength: 254
    };

    this.settingCbBlDescPSAPackageType = {
      id: "cbBlDescPSAPackageType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlDescReceiptType = {
      id: "cbBlDescReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "receiptTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "R/Type Code", field: "receiptTypeCode", width: 175 },
        { header: "R/Type Name", field: "receiptTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 5
    };
    this.settingCbBlDescDeliveryType = {
      id: "cbBlDescDeliveryType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "deliveryTypeCode",
      description: "",
      isMark: true,
      columns: [
        { header: "D/Type Code", field: "deliveryTypeCode", width: 175 },
        { header: "D/Type Name", field: "deliveryTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 5
    };

    this.settingCbBlDescInnerPkgType = {
      id: "cbBlDescInnerPkgType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlContainerInnerPkgType = {
      id: "cbBlContainerInnerPkgType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlContainerCommodity = {
      id: "cbBlContainerCommodity",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Commodity",
      title: "commodityName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Commodity Code", field: "commodityCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingCbBlDescOuterPkgType = {
      id: "cbBlDescOuterPkgType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Pkg. Type Code", field: "packageTypeCode", width: 175 },
        { header: "Pkg. Type Name", field: "packageTypeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlDescCommodityCode = {
      id: "cbBlDescCommodityCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "commodityName",
      description: "",
      isMark: true,
      columns: [
        { header: "Commodity Code", field: "commodityCode", width: 175 },
        { header: "Commodity Name", field: "commodityName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 35
    };

    this.settingTxtBlDescDescriptionOfGoods = {
      id: "txtBlDescDescriptionOfGoods",
      name: "clauseName",
      placeholder: "",
      cols: 35,
      rows: 10,
      maxlength: 999999,
      border: 306,
    };

    // *** 06. Nett Charge *** //
    this.settingCbBlNettChargePlaceOfIssue = {
      id: "cbBlNettChargePlaceOfIssue",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlNettChargePlaceOfIssueConsole = {
      id: "cbBlNettChargePlaceOfIssueConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlNettChargePrepaid1 = {
      id: "cbBlNettChargePrepaid1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargePayableAt1 = {
      id: "cbBlNettChargePayableAt1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargeBillingParty1 = {
      id: "cbBlNettChargeBillingParty1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "NVOCC?", field: "isNvocc", width: 75 },
        { header: "Valid?", field: "isValid", width: 75 },
      ],
      maxlength: 50,
    };
    this.settingCbBlNettChargeInputPayerConsole = {
      id: "cbBlNettChargeInputPayerConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlPayer/customerName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerName",
      description: "",
      isMark: true,
      align: "right",
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "NVOCC?", field: "isNvocc", width: 75 },
        { header: "Valid?", field: "isValid", width: 75 },
      ],
    };

    this.settingCbBlNettChargeInputPlaceOfPaymentConsole = {
      id: "cbBlNettChargeInputPlaceOfPaymentConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargePrepaid2 = {
      id: "cbBlNettChargePrepaid2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargePayableAt2 = {
      id: "cbBlNettChargePayableAt2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargeBillingParty2 = {
      id: "cbBlNettChargeBillingParty2",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "NVOCC?", field: "isNvocc", width: 75 },
        { header: "Valid?", field: "isValid", width: 75 },
      ],
      maxlength: 50,
    };
    this.settingCbBlNettChargePrepaid3 = {
      id: "cbBlNettChargePrepaid3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargePayableAt3 = {
      id: "cbBlNettChargePayableAt3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 0,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 175 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    this.settingCbBlNettChargeBillingParty3 = {
      id: "cbBlNettChargeBillingParty3",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}",
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerName",
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "NVOCC?", field: "isNvocc", width: 75 },
        { header: "Valid?", field: "isValid", width: 75 },
      ],
      maxlength: 50,
    };
    this.settingTxtBlNettChargeRemarks = {
      id: "txtBlNettChargeRemarks",
      name: "clauseName",
      placeholder: "",
      cols: 30,
      rows: 3,
      maxlength: 999999,
      border: 500,
    };

    this.settingTxtBlNettChargeRemarksConsole = {
      id: "txtBlNettChargeRemarksConsole",
      name: "clauseName",
      placeholder: "",
      cols: 30,
      rows: 3,
      maxlength: 999999,
      border: 500,
    };

    this.settingTxtBlNettChargeNewRemarks = {
      id: "txtBlNettChargeNewRemarks",
      name: "newClauseName",
      placeholder: "",
      cols: 30,
      rows: 3,
      maxlength: 200,
      border: 500,
    };

    this.settingGridBlNettCharge = {
      id: "gridBlNettCharge",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        { header: "Print", field: "blFCIsChargePrintedString", width: 100 },
        { header: "FAI Grp", field: "blFCFaiGroupString", width: 100 },
        {
          header: "Manifested Frt",
          field: "blFCIsManifestedFreightString",
          width: 75,
        },
        { header: "Charge", field: "blFCChargeCode", width: 100 },
        { header: "Qty", field: "blFCRevenue", width: 75 },
        { header: "Rate", field: "blFCRate", width: 150 },
        { header: "Per", field: "blFCPerCode", width: 75 },
        { header: "Cur.", field: "blFCCurrencyCode", width: 75 },
        { header: "Total Amount", field: "blFCAmount", width: 150 },
        { header: "P/C", field: "blFCPc", width: 75 },
        { header: "Payer", field: "blFCPayer", width: 75 },
        {
          header: "Place of Payment",
          field: "blFCPlaceOfPaymentName",
          width: 150,
        },
        {
          header: "Freight Reference",
          field: "blFCFreightReference",
          width: 150,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "seq",
      sortingDirection: "ASC",
      minHeight: 150,
      height: 150,
    };

    this.settingGridBlNettChargeConsole = {
      id: "gridBlNettChargeConsole",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        { header: "Print", field: "blFCIsChargePrintedString", width: 100 },
        { header: "FAI Grp", field: "blFCFaiGroupString", width: 100 },
        {
          header: "Manifested Frt",
          field: "blFCIsManifestedFreightString",
          width: 75,
        },
        { header: "Charge", field: "blFCChargeCode", width: 100 },
        { header: "Qty", field: "blFCRevenue", width: 75 },
        { header: "Rate", field: "blFCRate", width: 150 },
        { header: "Per", field: "blFCPerCode", width: 75 },
        { header: "Cur.", field: "blFCCurrencyCode", width: 75 },
        { header: "Total Amount", field: "blFCAmount", width: 150 },
        { header: "P/C", field: "blFCPc", width: 75 },
        { header: "Payer", field: "blFCPayerName", width: 150 },
        {
          header: "Place of Payment",
          field: "blFCPlaceOfPaymentName",
          width: 150,
        },
        {
          header: "Freight Reference",
          field: "blFCFreightReference",
          width: 150,
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "seq",
      sortingDirection: "ASC",
      minHeight: 300,
      height: 300,
    };

    this.settingGridBlNettChargeExchangeRate = {
      id: "gridBlNettChargeExchangeRate",
      url: "",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 75 },
        {
          header: "Home Currency",
          field: "blExchangeRateHomeCurrency",
          width: 100,
        },
        {
          header: "Destination Currency",
          field: "blExchangeRateDestinationCurrency",
          width: 100,
        },
        { header: "ExchangeRate", field: "blExchangeRate", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      enableHideColumns: false,
      sortingColumns: "seq",
      sortingDirection: "ASC",
      minHeight: 120,
      height: 120,
    };

    this.settingDateNettChargeDateOfIssue = {
      id: "dateBlNettChargeDateOfIssue",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "BL Date",
      customtext: "",
    };

    this.settingDateNettChargeDateOfIssueConsole = {
      id: "dateBlNettChargeDateOfIssueConsole",
      type: "date",
      format: "yyyy-mm-dd",
      placeholder: "BL Date",
      customtext: "",
    };
    /*
     this.settingDateNettChargeExchangeRate = {
     id: 'dateBlNettChargeExchangeRate',
     type : 'date',
     format : 'yyyy-mm-dd',
     placeholder : 'Exchange Rate Date',
     customtext : '',
     };
     */

    this.settingDateNettChargeExchangeRate = {
      id: "dateBlNettChargeExchangeRate",
      placeholder: "Exchange Rate Date",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingDateNettChargeExchangeRateConsole = {
      id: "dateBlNettChargeExchangeRateConsole",
      placeholder: "Exchange Rate Date",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };


    this.settingDateNettChargeShippedOnBoard = {
      id: "dateBlNettChargeShippedOnBoard",
      placeholder: "Shipped On Board Date",
      autoHide: true,
      type: "date", // date|custom
      format: "yyyy-mm-dd",
      customtext: "",
    };

    this.settingCbBlNettChargeInputCharge = {
      id: "cbBlNettChargeInputCharge",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlAll/chargeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "chargeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Charge Code", field: "chargeCode", width: 175 },
        { header: "Charge Name", field: "chargeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingCbBlNettChargeInputChargeConsole = {
      id: "cbBlNettChargeInputChargeConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findByComboBoxControlAll/chargeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "chargeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Charge Code", field: "chargeCode", width: 175 },
        { header: "Charge Name", field: "chargeName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlNettChargeInputPC = {
      id: "cbBlNettChargeInputPC",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "blPCCode",
      title: "blPCCode",
      allowRemove: false,
    };

    this.settingCbBlNettChargeInputPCConsole = {
      id: "cbBlNettChargeInputPCConsole",
      type: "select", // search | select | select input
      url: '[{ "blPCCode" : "P", "blPCName" : "PREPAID" },{ "blPCCode" : "C", "blPCName" : "COLLECT" }]',
      urlType: "inject",
      placeholder: "P/C",
      code: "blPCCode",
      title: "blPCCode",
      allowRemove: false,
    };

    this.settingCbBlNettChargeInputPayer = {
      id: "cbBlNettChargeInputPayer",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "blPCPayerCode",
      title: "blPCPayerCode",
      allowRemove: false,
    };

    this.settingCbBlNettChargeInputPerCode = {
      id: "cbBlNettChargeInputPerCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPerCodes/findByComboBoxControl/perCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "perCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Per Code", field: "perCode", width: 175 },
        { header: "Per Name", field: "perName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 4
    };

    this.settingCbBlNettChargeInputPerCodeConsole = {
      id: "cbBlNettChargeInputPerCodeConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPerCodes/findByComboBoxControl/perCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "perCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Per Code", field: "perCode", width: 175 },
        { header: "Per Name", field: "perName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlNettChargeInputCurrency = {
      id: "cbBlNettChargeInputCurrency",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findByComboBoxControl/currencyCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "currencyName",
      description: "",
      isMark: true,
      columns: [
        { header: "Currency Code", field: "currencyCode", width: 175 },
        { header: "Currency Name", field: "currencyName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.settingCbBlNettChargeInputCurrencyConsole = {
      id: "cbBlNettChargeInputCurrencyConsole",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findByComboBoxControl/currencyCode={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "currencyCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Currency Code", field: "currencyCode", width: 175 },
        { header: "Currency Name", field: "currencyName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingCbBlNettChargeExchangeRateCurrency = {
      id: "cbBlNettChargeExchangeRateCurrency",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCurrencies/findByComboBoxControl/currencyCodeLike={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "currencyName",
      description: "",
      isMark: true,
      columns: [
        { header: "Currency Code", field: "currencyCode", width: 175 },
        { header: "Currency Name", field: "currencyName", width: 300 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
      maxlength: 50,
    };

    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
      tabOptio: false,
    };
  }

  ngOnInit() {
    console.log("** onInit **");
    this.lockAddressRetrieve = this.lockAddressRetrieve; //yg-sbhd

    $(".num").keypress(function () {
      if ($(this).val().length == $(this).attr("maxlength")) {
        return false;
      }
    });

  }

  init() {
    this.lockAddressRetrieve = this.lockAddressRetrieve; //yg-sbhd

    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (!this.viewOnlyStatus) {
      if (localStorage.getItem("view-only-mode") != undefined) {
        this.viewOnlyStatus = true;
      }
    }

    localStorage.removeItem("view-only-mode");
    this.printOnlyOnceList = ["MY", "IN", "ID", "SG", "TH", "PH", "BD", "LK", "VN", "KH", "MM"];
    this.lockAddressRetrieve = false;
    this.lockAddressRetrieve1 = false;
    this.lockAddressRetrieve2 = false;
  }

  SgMode() {
    if (this.officeCode == "SGSIN") {
      this.model.blGST = true;
      this.modeFormCoba = "SGMODE";
    } else {
      this.modeFormCoba = "ELSE";
    }

    if (this.officeCode.startsWith("ID")) {
      this.inaMode = true;
    }
  }

  AdminZEN() {
    if (
      (this.cookieService.getName() == "novyan" &&
        this.officeCode == "SGSIN") ||
      (this.cookieService.getName() == "celine" &&
        this.officeCode == "SGSIN") ||
      (this.cookieService.getName() == "miki" && this.officeCode == "SGSIN") ||
      (this.cookieService.getName() == "celest" &&
        this.officeCode == "SGSIN") ||
      (this.cookieService.getName() == "fiazhassan" &&
        this.officeCode == "SGSIN")
    ) {
      this.adminZen = "ADMINZEN";
    } else {
      this.adminZen = "ELSE";
      this.invisibleToolbarButtons = "save2,partialSave2";
    }
  }

  ngAfterViewInit() {
    //PRINT Patch

    //yg-sbhd
    this.rowDataADContainer = [];
    this.rowDataContainerHs = [];
    //yg-sbhd

    this.userId = this.cookieService.getName();
    this.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    this.userIdCode = localStorage.getItem("logged").split("|")[3];
    this.SgMode();
    this.AdminZEN();
    console.log("** afterViewInit **");
    $(".menu .item").tab();
    //document.getElementById('txtBlDescAgentNameAddress-textarea2').style.opacity = '0';
    $("#tb_delete").css("background", "red");
    $("#tb_delete i").css("color", "white");
    $("#tb_delete span").css("color", "white");

    this.disableToolbarButtons =
      "partialSave,partialSave2,save,save2,printInvoice,delete,cancel,ias,print,sync";

    this.invisibleToolbarButtons = "";

    //For SIN open both
    if (this.adminZen !== "ADMINZEN") {
      console.log("ADMINZEN : " + this.adminZen);
      this.invisibleToolbarButtons = "partialSave2,save2,back";
    } else {
      //this.invisibleToolbarButtons = 'partialSave,save'
      this.invisibleToolbarButtons = "back";
    }

    // Init place of issue url
    this.cbBlNettChargePlaceOfIssue.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );

    this.cbBlNettChargePlaceOfIssueConsole.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );

    if (
      this.officeCode === "SGSIN" ||
      this.officeCode == "MYKUA" ||
      this.officeCode == "MYPKG" ||
      this.officeCode == "MYPGU" ||
      this.officeCode == "MYPEN"
    ) {
      this.blNettChargeCheckBoxOIS = "Print SWB/OIS Clause";
    }

    //set url for local master clause
    this.cbBlDescClauses.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterClauses/findByComboBoxControl2/" +
        this.officeCode +
        "/clauseCodeOrName={query}"
    );

    // Init Model
    this.model = new BLHeader();
    this.jasper = new jasperModel(this.genericService, this.configService);
    // this.model.blType = 'N';   // reset BL Type to NA
    this.model.blType = "MEMO"; // reset BL Type to MEMO
    this.model.blContainerOwnership = "COC";

    if (this.officeCode == "MMRGN") {
      this.model.blType = "MEMO";
      this.model.blContainerOwnership = "SOC";
    } else if (this.officeCode == "MMYGN") {
      this.model.blType = "N";
      this.model.blContainerOwnership = "COC";
    }

    this.model.blIsPrintAgentInfoOnBl = false;

    this.paramContainer = new BLContainer();
    this.paramDesc = new BLDesc();
    this.paramMarkNo = new BLMarkNo();
    this.paramFreightCharge = new BLFreightCharge();
    this.paramExchangeRate = new BLExchangeRate();

    // lock the tab
    this.lockParty = false;
    this.lockPlace = true;
    this.lockContainer = true;
    this.lockMarkNo = true;
    this.lockDesc = true;
    this.lockNettCharge = true;
    this.lockGrossCharge = true;
    this.lockNettChargeConsole = true;
    this.lockGrossChargeConsole = true;

    window.scrollTo(0, 0);
    const self = this;
    setTimeout(() => {
      self.tabPartyClicked();
      self.modeFormBlDesc = "view";
      self.modeFormBlContainer = "view";
      self.modeFormBlNettCharge = "view";
      self.modeFormBlNettChargeConsole = "view";

      if (localStorage.getItem('retrieve-bl-from-invoice-flag') != null) {
        window.scrollTo(0, 0);
        this.strParams = localStorage.getItem('retrieve-bl-from-invoice-flag');
        localStorage.removeItem('retrieve-bl-from-invoice-flag');
        if (this.strParams != null) {
          const par: any = JSON.parse(this.strParams);
          console.log(par);
          this.model.blNo = par.selected;

          this.invisibleToolbarButtons = '';

          // For SIN open both
          if (this.adminZen !== 'ADMINZEN') {
            this.invisibleToolbarButtons = 'partialSave2,save2';
          } else {
            // this.invisibleToolbarButtons = 'partialSave,save'
            this.invisibleToolbarButtons = '';
          }
          this.isOpenedFromInvoiceFlagStatus = true;
          this.handleRetrieve();
        }
      }

      if (localStorage.getItem("retrieve-bl-from-flag") != null) {
        window.scrollTo(0, 0);
        this.strParams = localStorage.getItem("retrieve-bl-from-flag");
        localStorage.removeItem("retrieve-bl-from-flag");
        if (this.strParams != null) {
          const par: any = JSON.parse(this.strParams);
          this.model.blNo = par.selected;

          this.invisibleToolbarButtons = "";

          //For SIN open both
          if (this.adminZen !== "ADMINZEN") {
            this.invisibleToolbarButtons = "partialSave2,save2";
          } else {
            //this.invisibleToolbarButtons = 'partialSave,save'
            this.invisibleToolbarButtons = "";
          }
          /*
           localStorage.removeItem('retrieve-bl-from-flag');
           self.genericUtil.hideLoader();
           this.retrieveByBlDataEvent()
           */
          this.isOpenedFromFlagStatus = true;
          this.handleRetrieve();
        }
      }
//navigate from China bl process page 10/10/2024
      if (localStorage.getItem("create-bl-from-china-blprocess") != null) {
        window.scrollTo(0, 0);
        this.strParams = localStorage.getItem("create-bl-from-china-blprocess");
        localStorage.removeItem("create-bl-from-china-blprocess");
        if (this.strParams != null) {
          const par: any = JSON.parse(this.strParams);
          this.model.blNo = par.selected;

          this.invisibleToolbarButtons = "";

          //For SIN open both
          if (this.adminZen !== "ADMINZEN") {
            this.invisibleToolbarButtons = "partialSave2,save2";
          } else {
            //this.invisibleToolbarButtons = 'partialSave,save'
            this.invisibleToolbarButtons = "";
          }

          this.isOpenedFromChinaBlProcess = true;
          this.handleRetrieve();
        }
      }

      if (localStorage.getItem("retrieve-bl-from-cross-ref") != null) {
        window.scrollTo(0, 0);
        this.strParams = localStorage.getItem("retrieve-bl-from-cross-ref");
        localStorage.removeItem("retrieve-bl-from-cross-ref");
        if (this.strParams != null) {
          const par: any = JSON.parse(this.strParams);
          this.model.blNo = par.selected;

          this.invisibleToolbarButtons = "";
          //For SIN open both
          if (this.adminZen !== "ADMINZEN") {
            this.invisibleToolbarButtons = "partialSave2,save2";
          } else {
            //this.invisibleToolbarButtons = 'partialSave,save'
            this.invisibleToolbarButtons = "";
          }
          /*
           localStorage.removeItem('retrieve-bl-from-flag');
           self.genericUtil.hideLoader();
           this.retrieveByBlDataEvent()
           */
          this.isOpenedFromCrossRef = true;
          this.handleRetrieve();
        }
      }

      if (localStorage.getItem("retrieve-bl-from-book-ref") != null) {
        window.scrollTo(0, 0);
        this.strParams = localStorage.getItem("retrieve-bl-from-book-ref");
        localStorage.removeItem("retrieve-bl-from-book-ref");
        if (this.strParams != null) {
          const par: any = JSON.parse(this.strParams);
          this.model.blNo = par.selected;

          this.invisibleToolbarButtons = "";
          //For SIN open both
          if (this.adminZen !== "ADMINZEN") {
            this.invisibleToolbarButtons = "partialSave2,save2";
          } else {
            //this.invisibleToolbarButtons = 'partialSave,save'
            this.invisibleToolbarButtons = "";
          }
          /*
           localStorage.removeItem('retrieve-bl-from-flag');
           self.genericUtil.hideLoader();
           this.retrieveByBlDataEvent()
           */
          this.isOpenedFromCrossBookRef = true;
          this.handleRetrieve();
        }
      }
    }, 500);

    window.scrollTo(0, 0);
    this.cdr.detectChanges();
    this.init();
  }

  gridEvent(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  toolbarEvent(event: any) {
    switch (event) {
      case 'ias':
        // GOS-346
        console.log('transmit to ias invoked');

        if (this.model.blIsFinished == true) {
          this.frmDialogMessage = 'BL: ' + this.model.blNo + ' transmitted successfully.';
          this.genericUtil.showDialog('dialogIas', 'Confirm', 350, 150);
        } else {
          this.frmDialogMessage = 'BL: ' + this.model.blNo + ' has not been finished, unable to transmit.';
          this.genericUtil.showDialog('dialogIasError', 'Error', 350, 150);
        }

        break;
      case "retrieve":
        this.isFullSaveClicked = false;
        //this.isOpenedFromFlagStatus = false;
        //this.isOpenedFromCrossRef = false;
        //this.isOpenedFromCrossBookRef = false;
        this.cleanUpBeforeRetrieval();
        this.handleRetrieve();
        /*
        this.genericService.GET(this.configService.config.BASE_API.toString() + '/BL/checkBlByBlNo/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.blNo.trim()).subscribe((resp) => {
          if (resp.json()['status'] === 'OK') {
            this.blLock = resp.json()['blLock'];
            this.blIsDeleted = resp.json()['blStatus'];
            this.blIsDeleted = resp.json()['blStatus'];
            this.blLockUserid = resp.json()['blLockByUser']['blUserId'];
            this.blmode = resp.json()['code'].toString().toUpperCase();


            console.log("** BL LOCK : " + this.blLock);
            console.log("** MODE : " + this.blmode)
            console.log("** User LOCK : " + this.blLockUserid)
            console.log("** ETD : " + resp.json()['blEtd'])

            if(resp.json()['blStatus'] === 'D') {
              this.frmDialogMessage = 'Current B/L is Deleted by ' + resp.json()['blUserDeleted'] + ', View Only?';
              this.genericUtil.showDialog('blDialogForBLDeleted', 'Confirm', 350, 150);
            }else{

              this.handleRetrieve();
            }
          }
        });
        */
        break;
      case "cancel":
        this.cancelButtonClickedCount += 1;
        this.handleCancel();
        //localStorage.removeItem('retrieve-bl-from-flag');
        break;
      case "partialSave":
        window.scrollTo(0, 0);
        this.isFullSaveClicked = false;
        if (this.model.blIsFinished === true) {
          //GOS-26 after bl fully saved, bl can partialy save and status back to unfinished.
          this.model.blIsFinished = false;
          /*this.frmDialogMessage =
            "This BL has been fully saved previously, unable to Partial Save.";
          this.genericUtil.showDialog("blDialogSave", "Confirmation", 350, 150);*/
          this.handleSave();
        } else {
          // date changes check
          //console.log('presave --- date of issue --- ' + this.dateBlNettChargeDateOfIssue.getValue().toString().trim() + ' ' + this.tmpDateOfIssue.split(' ')[0].toString().trim());
          //console.log('pre-test -> ' + this.dateBlNettChargeDateOfIssue.getValue().toString().trim() === this.tmpDateOfIssue.split(' ')[0].toString().trim());
          //console.log('pre-test2 -> ' + this.dateBlNettChargeDateOfIssue.getValue().toString().trim() == this.tmpDateOfIssue.split(' ')[0].toString().trim());
          //var checkDiff = (this.dateBlNettChargeDateOfIssue.getValue().toString().trim() == this.tmpDateOfIssue.split(' ')[0].toString().trim())
          //console.log("hehe : " + checkDiff);

          // console.log('pre-test3 -> ' + this.dateBlNettChargeDateOfIssue.getValue().toString().trim().equals(this.tmpDateOfIssue.split(' ')[0].toString().trim()));

          //if (this.dateBlNettChargeDateOfIssue.getValue().toString().trim() != this.tmpDateOfIssue.split(' ')[0].toString().trim()){
          //this.isRefreightButtonClicked = false;
          //if(!checkDiff){
          //  this.isRefreightButtonClicked = true;
          //}

          //var checkDiffExchangeRate = (this.dateBlNettChargeExchangeRate.getValue().toString().trim() == this.tmpExchangeRateDate.split(' ')[0].toString().trim())
          //this.isRefreightButtonClicked = false;
          //if (!checkDiffExchangeRate){
          //  this.isRefreightButtonClicked = true;
          //}

          /*
           console.log('test -> ' + this.dateBlNettChargeDateOfIssue.getValue().toString().trim() != this.tmpDateOfIssue.split(' ')[0].toString().trim());
           console.log('    ----     ');
           console.log('presave --- exchange rate date --- ' + this.dateBlNettChargeExchangeRate.getValue().toString().trim() + ' ' + this.tmpExchangeRateDate.split(' ')[0].toString().trim());
           if (this.dateBlNettChargeExchangeRate.getValue() !== this.tmpExchangeRateDate.split(' ')[0].trim()){
           this.isRefreightButtonClicked = false;
           }
           console.log('test -> ' + this.dateBlNettChargeExchangeRate.getValue() !== this.tmpExchangeRateDate.split(' ')[0].trim())
           */
          console.log(
            "is Refreight Button Clicked -> " + this.isRefreightButtonClicked
          );

          /*GOS 26
          if (
            this.isRefreightButtonClicked !== true &&
            this.model.blType !== "OIS" &&
            this.model.blType !== "SW"
          ) {
            this.frmDialogMessage = "You haven't refreight this BL.";
            this.genericUtil.showDialog(
              "clickRefreightDialog",
              "Confirmation",
              350,
              150
            );
          } else {*/
          this.checkDateOfIssue();
          //this.handleSave();
          //}
        }
        break;

      case "partialSave2":
        window.scrollTo(0, 0);
        this.isFullSaveClicked = false;
        if (this.model.blIsFinished === true) {
          this.frmDialogMessage =
            "This BL has been fully saved previously, unable to Partial Save.";
          this.genericUtil.showDialog("blDialogSave", "Confirmation", 350, 150);
        } else {
          if (
            this.isRefreightButtonClickedConsole !== true &&
            this.model.blType !== "OIS" &&
            this.model.blType !== "SW"
          ) {
            this.frmDialogMessage = "You haven't refreight this BL.";
            this.genericUtil.showDialog(
              "clickRefreightDialog",
              "Confirmation",
              350,
              150
            );
          } else {
            this.handleSave2();
          }
        }
        break;
      case "save":
        window.scrollTo(0, 0);
        this.isFullSaveClicked = true;

        if (
          this.isRefreightButtonClicked !== true &&
          this.model.blType !== "OIS" &&
          this.model.blType !== "SW"
        ) {
          this.frmDialogMessage = "You haven't refreight this BL.";
          this.genericUtil.showDialog(
            "clickRefreightDialog",
            "Confirmation",
            350,
            150
          );
        } else {
          // date changes check
          // var checkDiff = (this.dateBlNettChargeDateOfIssue.getValue().toString().trim() == this.tmpDateOfIssue.split(' ')[0].toString().trim())

          //console.log('presave --- date of issue --- ' + this.dateBlNettChargeDateOfIssue.getValue() + ' ' + this.tmpDateOfIssue.split(' ')[0].trim());
          //if (this.dateBlNettChargeDateOfIssue.getValue() !== this.tmpDateOfIssue.split(' ')[0].trim()){
          // if(!checkDiff){
          //   this.isRefreightButtonClicked = true;
          // }

          //console.log('presave --- exchange rate date --- ' + this.dateBlNettChargeExchangeRate.getValue() + ' ' + this.tmpExchangeRateDate.split(' ')[0].trim());
          //if (this.dateBlNettChargeExchangeRate.getValue() !== this.tmpExchangeRateDate.split(' ')[0].trim()){
          // var checkDiffExchangeRate = (this.dateBlNettChargeExchangeRate.getValue().toString().trim() == this.tmpExchangeRateDate.split(' ')[0].toString().trim())
          // if (!checkDiffExchangeRate){
          //   this.isRefreightButtonClicked = true;
          // }

          if (
            this.isRefreightButtonClicked !== true &&
            this.model.blType !== "OIS" &&
            this.model.blType !== "SW"
          ) {
            this.frmDialogMessage = "You haven't refreight this BL.";
            this.genericUtil.showDialog(
              "clickRefreightDialog",
              "Confirmation",
              350,
              150
            );
          } else {
            this.checkDateOfIssue();
            //this.handleSave();
          }
        }
        break;
      case "save2":
        window.scrollTo(0, 0);
        this.isFullSaveClicked = true;

        if (
          this.isRefreightButtonClickedConsole !== true &&
          this.model.blType !== "OIS" &&
          this.model.blType !== "SW"
        ) {
          this.frmDialogMessage = "You haven't refreight this BL.";
          this.genericUtil.showDialog(
            "clickRefreightDialog",
            "Confirmation",
            350,
            150
          );
        } else {
          if (
            this.isRefreightButtonClickedConsole !== true &&
            this.model.blType !== "OIS" &&
            this.model.blType !== "SW"
          ) {
            this.frmDialogMessage = "You haven't refreight this BL.";
            this.genericUtil.showDialog(
              "clickRefreightDialog",
              "Confirmation",
              350,
              150
            );
          } else {
            this.handleSave2();
          }
        }
        break;
      case "delete":
        this.handleDelete();
        break;
      case "back":
        this.handleBack();
        break;
      case "close":
        // this.doUnlockByUser(this.cookieService.getDefaultLocationCode(),this.cookieService.getName().toUpperCase())    Requested by miki on 19 march 2019, to force user using cancle in order to exit bl
        localStorage.removeItem("retrieve-bl-from-flag");
        this.genericUtil.hideLoader();
        this.router.navigateByUrl("/main/home");
        break;
      case "lock":
        this.lockTab1 = true;
        this.lockTab2 = true;
        this.lockTab3 = true;
        this.lockTab4 = true;
        this.lockTab5 = true;
        this.lockTab6 = true;
        this.lockTab8 = true;
        break;
      case "unlock":
        this.lockTab1 = false;
        this.lockTab2 = false;
        this.lockTab3 = false;
        this.lockTab4 = false;
        this.lockTab5 = false;
        this.lockTab6 = false;
        this.lockTab8 = false;
        break;
      case "print":
        if (this.model.blNo != "") {
          var self = this;
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BLReport/findBLNo/" +
                this.officeCode +
                "/" +
                this.model.blNo.toUpperCase()
            )
            .subscribe((resp) => {
              console.log(resp);
              if (resp.ok) {
                if (resp.json()["content"][0] != undefined) {
                  this.BLPrint = resp.json()["content"][0].blIsBlPrinted;
                  this.hasAttch = resp.json()["content"][0].hasAttachment;
                  this.hasOuter =
                    resp.json()["content"][0].hasOuter == "Y" ? true : false;
                  if (resp.json()["content"][0].blIsFinished == "Y") {
                    this.isFinished = true;
                  }
                  self.genericUtil.showDialog(
                    "printDialog",
                    "B/L Print List",
                    350,
                    200
                  );
                }
                //self.genericUtil.showDialog('printDialog', 'B/L Print List', 350, 200);
              }
            });
          //self.genericUtil.showDialog('printDialog', 'B/L Print List', 350, 200);
        }
        break;
      case "sync":
        this.dialogPlugin.show(
          "information",
          "Confirmation",
          "Confirm to synchronize new BL data?",
          "yesno",
          { yes: "this.handleSync()", no: "this.loading = false;" }
        );
        break;
      default:
        break;
    }
  }

  handleBack() {
    console.log("** handle back **");

    this.doUnlockByUser(
      this.cookieService.getDefaultLocationCode(),
      this.cookieService.getName().toUpperCase()
    );
    console.log("isOpenedFromFlagStatus = " + this.isOpenedFromFlagStatus);
    if (this.isOpenedFromFlagStatus) {
      if (this.strParams != "") {
        console.log(this.strParams);
        localStorage.setItem("close-bl-to-flag", this.strParams);
        localStorage.removeItem("retrieve-bl-from-flag");
        this.router.navigateByUrl("/main/transaction-bl-flag-status-latest");
      } else {
        localStorage.removeItem("retrieve-bl-from-flag");
        this.router.navigateByUrl("/main/transaction-bl-flag-status-latest");
      }
    }

    if (this.isOpenedFromCrossRef) {
      if (this.strParams != "") {
        localStorage.setItem("close-bl-to-cross-ref", this.strParams);
        this.router.navigateByUrl(
          "/main/transaction-bl-cross-reference-by-container"
        );
      } else {
        this.router.navigateByUrl(
          "/main/transaction-bl-cross-reference-by-container"
        );
      }
    }

    if (this.isOpenedFromCrossBookRef) {
      if (this.strParams != "") {
        localStorage.setItem("close-bl-to-cross-book-ref", this.strParams);
        this.router.navigateByUrl(
          "/main/transaction-bl-cross-reference-by-booking"
        );
      } else {
        this.router.navigateByUrl(
          "/main/transaction-bl-cross-reference-by-booking"
        );
      }
    }

    if (this.isOpenedFromInvoiceFlagStatus) {
      if (this.strParams != '') {
        localStorage.setItem('close-bl-to-invoice-flag-status', this.strParams);
        this.router.navigateByUrl(
          '/main/transaction-invoice-flag-status-page'
        );
      } else {
        this.router.navigateByUrl(
          '/main/transaction-invoice-flag-status-page'
        );
      }
    }


    if (this.isOpenedFromChinaBlProcess) {
      if (this.strParams != "") {
        console.log(this.strParams);
        localStorage.setItem("close-bl-to-flag", this.strParams);
        localStorage.removeItem("retrieve-bl-from-flag");
        this.router.navigateByUrl("/main/transaction-bl-flag-status-latest");
      } else {
        localStorage.removeItem("retrieve-bl-from-flag");
        this.router.navigateByUrl("/main/transaction-bl-flag-status-latest");
      }
    }
  }

  partialSaveEvent() {}

  fullSaveEvent() {
    this.model.blIsFinished = true;
    this.handleSave();
  }

  /** Combo Change Event Block **/

  /** End of Combo Change Event Block **/

  /** Text change event block **/
  changeEventTxtBlPartyOperatorCode(event) {}

  /** End of change event block **/

  /** Misc Event Block **/

  handleCancel() {
    console.log("cancelButtonClickedCount = " + this.cancelButtonClickedCount);
    this.doUnlock(
      this.cookieService.getDefaultLocationCode().toUpperCase(),
      this.model.blNo,
      this.cookieService.getName().toUpperCase()
    );
    this.headerExtraFromBL = false;
    this.isError = false;
    this.lockAddressRetrieve = false;
    this.lockAddressRetrieve1 = false;
    this.lockAddressRetrieve2 = false;
    this.IsLockPayer1 = false;
    this.IsLockPayer2 = false;
    this.IsLockPayer3 = false;
    localStorage.removeItem("navigateFromTransactionBlFlagStatus");

    this.bookingDataDetail = [];
    if (this.cancelButtonClickedCount === 1) {
      this.lockTab1 = true;
      this.lockTab2 = true;
      this.lockTab3 = true;
      this.lockTab4 = true;
      this.lockTab5 = true;
      this.lockTab6 = true;
      this.lockTab8 = true;
      /*
       this.lockParty = false;
       this.lockPlace = true;
       this.lockContainer = true;
       this.lockMarkNo = true;
       this.lockDesc = true;
       this.lockNettCharge = true;
       this.lockGrossCharge = true;
       */

      // Asked by miki on whatsapp group, when cancel must lock all tab, she said already mentioned countless times.
      // whatever
      this.lockParty = true;
      this.lockPlace = true;
      this.lockContainer = true;
      this.lockMarkNo = true;
      this.lockDesc = true;
      this.lockNettCharge = true;
      this.lockGrossCharge = true;
      this.lockNettChargeConsole = true;
      this.lockGrossChargeConsole = true;

      this.isInsideRetrieveMode = false;

      this.disableToolbarButtons =
        "partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
      this.tabPartyClicked();

      this.gridBlNettChargeConsole.onClear();
    } else if (this.cancelButtonClickedCount === 2) {
      this.cancelButtonClickedCount = 0;
      this.isFullSaveClicked = false;
      this.isInsideRetrieveMode = false;
      this.isRefreightButtonClicked = false;
      this.isDiscrepancyExisted = false;
      //this.isOpenedFromFlagStatus = false;
      //this.isOpenedFromCrossRef = false;
      //this.isOpenedFromCrossBookRef = false;
      this.refreightData = [];

      // reset all is error
      this.isInsideRetrieveMode = false;
      this.isError = false;
      this.isErrorContainer = false;
      this.isErrorHSCode = false;
      this.isErrorAdCode = false
      this.isErrorMarkNo = false;
      this.isErrorDesc = false;
      this.isErrorDescClause = false;
      this.isErrorNettCharge = false;
      this.isErrorNettChargeEntry = false;
      this.isErrorBlExchRate = false;

      this.tabPartyClicked();

      // reset all model;
      this.model = new BLHeader();
      this.paramContainer = new BLContainer();
      this.paramMarkNo = new BLMarkNo();
      this.paramDesc = new BLDesc();
      this.paramDescClause = new BLDescClause();
      this.paramFreightCharge = new BLFreightCharge();
      this.paramExchangeRate = new BLExchangeRate();

      // reset grid
      this.gridBlMarkNo.onClear();
      this.gridBlContainer.onClear();
      this.gridBlContainerHS && this.gridBlContainerHS.onClear();
      this.gridBlDesc.onClear();
      this.gridBlDescClause.onClear();
      this.gridBlNettCharge.onClear();
      this.gridBlNettChargeExchangeRate.onClear();
      // this.gridBlAdContainer.onClear();


      // reset party
      this.cbBlPartyShipper.setValue("");
      this.cbBlPartyShipperAddress.setValue("");
      this.cbBlPartyShipperAddress.listStore.store = [];
      this.cbBlPartyShipperAddress.loadSelect();
      this.txtBlPartyShipperAddress.setValue("");
      this.cbBlPartyConsignee.setValue("");
      this.cbBlPartyConsigneeAddress.setValue("");
      this.cbBlPartyConsigneeAddress.listStore.store = [];
      this.cbBlPartyConsigneeAddress.loadSelect();
      this.txtBlPartyConsigneeAddress.setValue("");
      this.cbBlPartyNotifyParty.setValue("");
      this.cbBlPartyNotifyPartyAddress.setValue("");
      this.cbBlPartyNotifyPartyAddress.listStore.store = [];
      this.cbBlPartyNotifyPartyAddress.loadSelect();
      this.txtBlPartyNotifyPartyAddress.setValue("");

      // place
      this.cbBlPlaceReceiptType.setValue("");
      this.cbBlPlaceDeliveryType.setValue("");
      this.cbBlPlacePOR.setValue("");
      this.cbBlPlacePOL.setValue("");
      this.cbBlPlacePOD.setValue("");
      this.cbBlPlacePODL.setValue("");
      this.cbBlPlaceFDEST.setValue("");
      this.cbBlPlacePOT1.setValue("");
      this.cbBlPlacePOT2.setValue("");
      this.cbBlPlacePOT3.setValue("");
      this.cbBlPlaceService.setValue("");
      this.cbBlPlacePrincipal.setValue("");
      this.cbBlPlacePreVessel.setValue("");
      // this.cbBlPlacePreVoyage.setValue('');
      this.cbBlPlaceOceanVessel.setValue("");
      this.cbBlPlaceOceanVoyage.setValue("");
      this.cbBlPlaceNextVessel.setValue("");
      // this.cbBlPlaceNextVoyage.setValue('');

      // desc
      this.cbBlDescAgentId.setValue("");
      this.txtBlDescTotalContainerPackageInWord.setValue("");
      this.txtBlDescAgentNameAddress.setValue("");

      // nett charge
      this.cbBlNettChargePrepaid1.setValue("");
      this.cbBlNettChargePrepaid2.setValue("");
      this.cbBlNettChargePrepaid3.setValue("");
      this.cbBlNettChargePayableAt1.setValue("");
      this.cbBlNettChargePayableAt2.setValue("");
      this.cbBlNettChargePayableAt3.setValue("");
      this.cbBlNettChargeBillingParty1.setValue("");
      this.cbBlNettChargeBillingParty2.setValue("");
      this.cbBlNettChargeBillingParty3.setValue("");
      this.cbBlNettChargeBillingParty1Address.listStore.store = [];
      this.cbBlNettChargeBillingParty1Address.loadSelect();
      this.cbBlNettChargeBillingParty1Address.setValue("");
      this.cbBlNettChargeBillingParty2Address.listStore.store = [];
      this.cbBlNettChargeBillingParty2Address.loadSelect();
      this.cbBlNettChargeBillingParty2Address.setValue("");
      this.cbBlNettChargeBillingParty3Address.listStore.store = [];
      this.cbBlNettChargeBillingParty3Address.loadSelect();
      this.cbBlNettChargeBillingParty3Address.setValue("");

      this.gridBlNettChargeConsole.onClear();

      this.disableToolbarButtons =
        "partialSave,partialSave2,save,save2,printInvoice,delete,ias,cancel,print,sync";
      this.invisibleToolbarButtons = "back";

      if (this.adminZen !== "ADMINZEN") {
        this.invisibleToolbarButtons = "partialSave2,save2,back";
      } else {
        //this.invisibleToolbarButtons = 'partialSave,save,back'
        if(this.isOpenedFromFlagStatus){
          this.invisibleToolbarButtons = "";
        }else{
          this.invisibleToolbarButtons = "back";
        }
      }

      // lock the tab
      this.lockParty = false;
      this.lockPlace = true;
      this.lockContainer = true;
      this.lockMarkNo = true;
      this.lockDesc = true;
      this.lockNettCharge = true;
      this.lockGrossCharge = true;
      this.lockNettChargeConsole = true;
      this.lockGrossChargeConsole = true;

      // relese the tab lock
      this.lockTab1 = false;
      this.lockTab2 = false;
      this.lockTab3 = false;
      this.lockTab4 = false;
      this.lockTab5 = false;
      this.lockTab6 = false;
      this.lockTab8 = false;

      // reset scroll position
      window.scrollTo(0, 0);
    }
  }

  preHandleRetrieve() {
    this.genericUtil.closeDialog("blDialogForBLDeleted");
    this.handleRetrieve();
  }

  handleRetrieveEnter(){
    this.cleanUpBeforeRetrieval();
    this.handleRetrieve();
  }

  handleRetrieve() {
    // bug request by miki, refer to wa chat on 9/1/2019 (no response after clicking back button, after retrieval)
    if (this.cancelButtonClickedCount === 1) {
      this.invisibleToolbarButtons = "back";
      if (this.adminZen !== "ADMINZEN") {
        this.invisibleToolbarButtons = "partialSave2,save2,back";
      } else {
        //this.invisibleToolbarButtons = 'partialSave,save,back';
        this.invisibleToolbarButtons = "";
      }
    }
    // end bug fix

    // check whether INA/NINA
    console.log(
      "checking INA - NINA " +
        this.officeCode +
        " - " +
        this.officeCode.toUpperCase().substr(0, 2)
    );
    if (this.officeCode.toUpperCase().substr(0, 2) === "ID") {
      console.log("inner package locked");
      this.isDisableInnerPkg = true;
    } else {
      console.log("inner package unlocked");
      this.isDisableInnerPkg = false;
    }
    // end bug fix romy (PLM trial)

    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    this.cancelButtonClickedCount = 0;

    this.isRefreightButtonClicked = false;
    this.isRefreightButtonClickedConsole = false;
    this.isFullSaveClicked = false;
    this.isRefreightButtonClicked = false;
    this.isRefreightButtonClickedConsole = false;
    this.isDiscrepancyExisted = false;
    this.refreightData = [];

    /**
     const bufferBlNo = new BufferBlNo();
     bufferBlNo.blOfficeCodeRetrieval = this.model.blOfficeCode;
     bufferBlNo.blNoRetrieval = this.model.blNo;
     this.cleanUpBeforeRetrieval();
     this.model.blNo = bufferBlNo.blNo;
     this.model.blOfficeCode = bufferBlNo.blOfficeCodeRetrieval
     **/
    console.log ("thruBL and BL",this.model.blThruBLRef,this.model.blNo);
    if (!this.model.blThruBLRef && !this.model.blNo) {
      this.setValidatorBlRetrieval();
      this.isError = this.onValidate(this.model);
    }
    if (!this.isError) {
      this.isInsideRetrieveMode = true;
      this.genericUtil.showLoaderPage();
      const url = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
        "/BL/checkTHruBlByBlNo/" +
        this.cookieService.getDefaultLocationCode() +
        "/" + this.model.blThruBLRef.trim()
        :
        this.configService.config.BASE_API.toString() +
        "/BL/checkBlByBlNo/" +
        this.cookieService.getDefaultLocationCode() +
        "/" + this.model.blNo.trim();

      this.genericService
        .GET(url)
        .subscribe((resp) => {
          if (resp.json()["status"] === "OK") {
            this.blLock = resp.json()["blLock"];
            this.blIsDeleted = resp.json()["blStatus"];
            this.blIsDeleted = resp.json()["blStatus"];
            this.blLockUserid = resp.json()["blLockByUser"]["blUserId"];
            this.blmode = resp.json()["code"].toString().toUpperCase();
            this.model.thruOfficeCode = resp.json()["thruOfficeCode"];
            const blPermanentLocked = resp.json()["blPermanentLocked"];

            console.log("** BL LOCK : " + this.blLock);
            console.log("** MODE : " + this.blmode);
            console.log("** User LOCK : " + this.blLockUserid);
            console.log("** ETD : " + resp.json()["blEtd"]);
            if (blPermanentLocked == 'Y') {
              this.frmDialogMessage =
                "Vessel departed more than 1 month. View only?"
                ", View Only?";
              this.genericUtil.showDialog(
                "blDialogViewOnly",
                "Confirm",
                350,
                150
              );
              return;
            }
            if (this.blLock === "Y" && !localStorage.getItem("navigateFromTransactionInvoiceFlagStatus") && !localStorage.getItem("navigateFromTransactionBlFlagStatus"))
            {
                if (resp.json()['blStatus'] === "D") {
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    resp.json()['blUserDeleted'] +
                    ", View Only?";
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                } else {
                  this.frmDialogMessage = "Current BL is locked! View only?";
                  //this.frmDialogMessage = 'Current B/L ETD is ' + resp.json()['blEtd'] + ' which is more than 7 days and you do not have the access right to do changes. View only?';
                  this.genericUtil.showDialog(
                    "blDialogForBLLock",
                    "Confirm",
                    350,
                    150
                  );
                }
            }
            else
            {
              localStorage.removeItem("navigateFromTransactionInvoiceFlagStatus");

             if (!localStorage.getItem("navigateFromTransactionBlFlagStatus"))
              {
                if (resp.json()['blStatus'] === 'D')
                  {
                  this.blLock = "Y";
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    resp.json()['blUserDeleted'] +
                    ", View Only?";
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                }else{
                  this.preRetrieve();
                }
              }else if (!localStorage.getItem("navigateFromTransactionBlFlagStatus"))
              {
                if (resp.json()['blStatus'] === 'D') {
                  this.blLock = "Y";
                }
                this.preRetrieve();
              }
              else
              {
                if (resp.json()['blStatus'] === 'D')
                {
                  this.blLock = "Y";
                }
              this.preRetrieve();
            }

            }

          }


        });
    }
  }

  preRetrieve() {
    console.log("*** preRetrieve ***");
    console.log("this.blmode ", this.blmode );
    console.log("this.blLock ", this.blLock );


    if (this.blmode === "MODE1") {
      if(this.site.startsWith("CN")){  //changed implementation from CNSHA to be CN%
         this.retrieveByBlDataSHAEvent();
       }
       else{
            this.retrieveByBlDataEvent();
       }
      if (this.blLock === "Y") {
        this.genericUtil.closeDialog("blDialogForBLLock");
      }

      /*else{
       if(this.blIsDeleted === 'D'){
       this.genericUtil.closeDialog('blDialogForBLDeleted');
       }

       }
       */
    } else if (this.blmode === "MODE2") {
      if(this.site.startsWith("CN")){  //changed implementation from CNSHA to be CN%
          this.retrieveByBlBookingSHAEvent();
        }
        else{
            this.retrieveByBlBookingEvent();
        }
      if (this.blLock === "Y") {
        this.genericUtil.closeDialog("blDialogForBLLock");
      }
    } else if (this.blmode === "MODE3") {
      this.genericUtil.hideLoader();
      this.frmDialogMessage =
        "No Record was found in B/L or booking table, " +
        "or booking number was deleted. Do you want to create as a new B/L (SW/OIS)?";
      this.genericUtil.showDialog("blRetrievalDialog", "Confirm", 350, 180);
      if (this.blLock === "Y") {
        this.genericUtil.closeDialog("blDialogForBLLock");
      }
    }

    this.model.blDateCreated = this.genericUtil.getPSIDate();

    const myArray = this.model.blDateCreated.split(" ");
    this.dateIssueforCbShipper = myArray[0];

    console.log("this.model.blDateCreated :"+this.model.blDateCreated);
    console.log("this.dateIssueforCbShipper :"+this.dateIssueforCbShipper);
    this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/shipper/"+this.dateIssueforCbShipper+"/"+this.custIdforCbShipper+"/customerName={query}");

    this.cbBlPartyConsignee.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/consignee/"+this.dateIssueforCbShipper+"/"+this.cneeIdforCbCnee+"/customerName={query}");

    this.cbBlPartyNotifyParty.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/notify_party/"+this.dateIssueforCbShipper+"/"+this.partyIdforCbParty+"/customerName={query}");

  }

  closePreRetrieve() {
    console.log("** closePreRetrieve **");
    this.genericUtil.hideLoader();
    this.isInsideRetrieveMode = false;
    this.invisibleToolbarButtons = "back";
    if (this.adminZen !== "ADMINZEN") {
      this.invisibleToolbarButtons = "partialSave2,save2,back";
    } else {
      //this.invisibleToolbarButtons = 'partialSave,save,back'
      this.invisibleToolbarButtons = "";
    }
    this.genericUtil.closeDialog("blDialogForBLLock");
  }

  closePreRetrieveDeleted() {
    console.log("** closePreRetrieveDeleted **");
    this.genericUtil.hideLoader();
    this.invisibleToolbarButtons = "back";
    if (this.adminZen !== "ADMINZEN") {
      this.invisibleToolbarButtons = "partialSave2,save2,back";
    } else {
      //this.invisibleToolbarButtons = 'partialSave,save,back'
      this.invisibleToolbarButtons = "";
    }
    this.genericUtil.closeDialog("blDialogForBLDeleted");
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to create B/L.",
            "okonly",
            { ok: "this.handleCancelInvalid();" }
          );
          this.handleCancelInvalid();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  handleCancelInvalid() {
    console.log("KE CANCEL INVALID GA YA");
    this.validSched = true;
    this.cbBlPlaceOceanVessel.setValue("");
    this.cbBlPlaceOceanVoyage.setValue("");
    this.disableToolbarButtons = "partialSave,partialSave2,save,save2";
  }

  retrieveByBlBookingEvent() {
    const urlBK = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
    "/BL/findBLDataFromBooking/" +
    this.model.thruOfficeCode +
    "/" + this.model.blThruBLRef.trim()
    :
    this.configService.config.BASE_API.toString() +
    "/BLBooking/findBLDataFromBooking/" +
    this.cookieService.getDefaultLocationCode() +
    "/" + this.model.blNo.trim();
    this.genericService
      .GET(urlBK)
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          this.checkValidSchedule(
            data["blData"].blOceanVesselId,
            data["blData"].blOceanVesselVoyage,
            data["blData"].blOceanVesselBound,
            data["blData"].blOfficeCode
          );

          this.disableToolbarButtons =
            "retrieve,delete,ias,printInvoice,print,partialSave2,save2";
          this.setData(data, false);
          console.log("retrieveByBlBookingEvent", data);

          this.setDataToBookingDataDetail(data);

          // release the tab
          this.lockParty = false;
          this.lockPlace = false;
          this.lockContainer = false;
          this.lockMarkNo = false;
          this.lockDesc = false;
          this.lockNettCharge = false;
          this.lockGrossCharge = false;
          this.lockNettChargeConsole = false;
          this.lockGrossChargeConsole = false;

          this.lockTab1 = false;
          this.lockTab2 = false;
          this.lockTab3 = false;
          this.lockTab4 = false;
          this.lockTab5 = false;
          this.lockTab6 = false;
          this.lockTab8 = false;

          if (this.blLock == "Y") {
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;
          } else if (this.blLock == "T1") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          } else if (this.blLock == "T2") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPlaceClicked();
          } else if (this.blLock == "T3") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = false;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabContainerClicked();
          } else if (this.blLock == "T4") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabMarkClicked();
          } else if (this.blLock == "T5") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabDescClicked();
          } else if (this.blLock == "T6") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T7") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T8") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = false;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          }
        }
        this.genericUtil.hideLoader();
      });
  }

  retrieveByBlBookingSHAEvent() {
    const urlBK = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
    "/BL/findBLDataFromBooking/" +
    this.model.thruOfficeCode +
    "/" + this.model.blThruBLRef.trim()
    :
    this.configService.config.BASE_API.toString() +
    "/BLBooking/findBLDataFromBooking/" +
    this.cookieService.getDefaultLocationCode() +
    "/" + this.model.blNo.trim();
    this.genericService
      .GET(urlBK)
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          this.checkValidSchedule(
            data["blData"].blOceanVesselId,
            data["blData"].blOceanVesselVoyage,
            data["blData"].blOceanVesselBound,
            data["blData"].blOfficeCode
          );

          this.disableToolbarButtons =
            "retrieve,delete,ias,printInvoice,print,partialSave2,save2";
          this.setData(data, false);
          console.log("retrieveByBlBookingEvent", data);

          this.setDataToBookingDataDetail(data);

          // release the tab
          this.lockParty = false;
          this.lockPlace = false;
          this.lockContainer = false;
          this.lockMarkNo = false;
          this.lockDesc = false;
          this.lockNettCharge = false;
          this.lockGrossCharge = false;
          this.lockNettChargeConsole = false;
          this.lockGrossChargeConsole = false;

          this.lockTab1 = false;
          this.lockTab2 = false;
          this.lockTab3 = false;
          this.lockTab4 = false;
          this.lockTab5 = false;
          this.lockTab6 = false;
          this.lockTab8 = false;

          if (this.blLock == "Y") {
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;
          } else if (this.blLock == "T1") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          } else if (this.blLock == "T2") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPlaceClicked();
          } else if (this.blLock == "T3") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = false;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabContainerClicked();
          } else if (this.blLock == "T4") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabMarkClicked();
          } else if (this.blLock == "T5") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabDescClicked();
          } else if (this.blLock == "T6") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T7") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T8") {
            this.disableToolbarButtons = "retrieve,ias,printInvoice,print,partialSave2,save2";
            /*
           this.lockParty = false;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          }
        }
        this.genericUtil.hideLoader();
      });
  }

  retrieveByBlDataEvent() {
    console.log(this.model.blNo);
    const url = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
      "/BL/findThruBlByOfficeCodeAndBlNo/" +
      this.model.thruOfficeCode + "/" +
      "/" + this.model.blThruBLRef.trim()
      :
      this.configService.config.BASE_API.toString() +
      "/BL/findBlByOfficeCodeAndBlNo/" +
      this.cookieService.getDefaultLocationCode() +
      "/" + this.model.blNo.trim();
    this.genericService
      .GET(url)
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          this.checkValidSchedule(
            data["blData"].blOceanVesselId,
            data["blData"].blOceanVesselVoyage,
            data["blData"].blOceanVesselBound,
            data["blData"].blOfficeCode
          );

          this.lockParty = false;
          this.lockPlace = false;
          this.lockContainer = false;
          this.lockMarkNo = false;
          this.lockDesc = false;
          this.lockNettCharge = false;
          this.lockGrossCharge = false;
          this.lockNettChargeConsole = false;
          this.lockGrossChargeConsole = false;

          this.lockTab1 = false;
          this.lockTab2 = false;
          this.lockTab3 = false;
          this.lockTab4 = false;
          this.lockTab5 = false;
          this.lockTab6 = false;
          this.lockTab8 = false;

          if (this.blLock == "Y") {
            this.disableToolbarButtons =
              "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;
          } else if (this.blLock == "T1") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = false;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false; // to show icon and clickable tab
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          } else if (this.blLock == "T2") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPlaceClicked();
          } else if (this.blLock == "T3") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = false;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = false;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabContainerClicked();
          } else if (this.blLock == "T4") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabMarkClicked();
          } else if (this.blLock == "T5") {
            this.disableToolbarButtons = "printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabDescClicked();
          } else if (this.blLock == "T6") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T7") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T8") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = false;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          }

          //cek view only menu button new container/markno/desc disable
          if (this.viewOnlyStatus){
            this.btnBlContainerNew = false;
            this.btnBlMarkNoNew = false;
            this.disableNewUpdateChargeButton = false;

          }

          if (
            this.blLock == "T1" ||
            this.blLock == "T2" ||
            this.blLock == "T3" ||
            this.blLock == "T4" ||
            this.blLock == "T5" ||
            this.blLock == "T6" ||
            this.blLock == "T7" ||
            this.blLock == "T8" ||
            this.blLock == "N" ||
            this.blLock == ""
          ) {
            console.log(
              "this.blLockUserid = " +
                this.blLockUserid +
                " - " +
                this.cookieService.getName().toUpperCase()
            );

            if (this.blLockUserid == undefined) {
              if (this.blLockUserid == undefined) {
                this.doLock(
                  this.cookieService.getDefaultLocationCode(),
                  this.model.blNo,
                  this.cookieService.getName().toUpperCase()
                );
              }

              if (data.blData.blStatus === "D") {
                if (
                  this.isOpenedFromFlagStatus ||
                  this.isOpenedFromCrossRef ||
                  this.isOpenedFromCrossBookRef
                ) {
                  this.onBlDialogViewOnlyYes();
                } else {
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    data.blData.blUserDeleted +
                    ", View Only?";
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                }
              } else {
                if (this.officeCode == "THBKK") {
                  this.disableToolbarButtons = "retrieve,printInvoice,partialSave2,save2";
                } else {
                  // GOS-345
                  if (this.officeCode.substr(0, 2) === 'IN') {
                    // unlock
                    this.disableToolbarButtons = "retrieve,partialSave2,save2";
                  } else {
                    // keep lock
                    this.disableToolbarButtons = "retrieve,partialSave2,save2,ias";
                  }
                }

                this.lockAddressRetrieve = true;
                this.lockAddressRetrieve1 = true;
                this.lockAddressRetrieve2 = true;
                this.setData(data, false);

                // release the tab
                if (this.blLock == "N") {
                  this.lockParty = false;
                  this.lockPlace = false;
                  this.lockContainer = false;
                  this.lockMarkNo = false;
                  this.lockDesc = false;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = false;
                  this.lockNettChargeConsole = false;
                  this.lockGrossChargeConsole = false;

                  this.lockTab1 = false;
                  this.lockTab2 = false;
                  this.lockTab3 = false;
                  this.lockTab4 = false;
                  this.lockTab5 = false;
                  this.lockTab6 = false;
                  this.lockTab8 = false;
                }
              }
            } else {
              // GOS-400 fix Inconsistent prompting of Deleted BL
              if (data.blData.blStatus === "D") {
                if (!localStorage.getItem("navigateFromTransactionBlFlagStatus")) {
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    data.blData.blUserDeleted +
                    ", View Only?";
                  this.isOpenedByOtherUser = true;
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                } else {
                  this.isOpenedByOtherUser = true;
                  this.onBlDialogViewOnlyYes();
                }
              } else {
                this.frmDialogMessage =
                  "Current B/L is Locked by " +
                  this.blLockUserid +
                  ", View Only?";
                this.isOpenedByOtherUser = true;
                this.genericUtil.showDialog(
                  "blDialogViewOnly",
                  "Confirm",
                  350,
                  150
                );
              }
            }
          } else {
            /*
           if(this.officeCode == 'THBKK'){
           this.disableToolbarButtons = 'retrieve,printInvoice';
           } else {
           this.disableToolbarButtons = 'retrieve';
           }
           this.setData(data);
           */
            this.lockAddressRetrieve = true;
            this.lockAddressRetrieve1 = true;
            this.lockAddressRetrieve2 = true;
            this.setData(data, false);
          }
        }

        this.genericUtil.hideLoader();
      });
  }

  retrieveByBlDataSHAEvent() {
    console.log(this.model.blNo);
    const url = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
      "/BL/findThruBlByOfficeCodeAndBlNo/" +
      this.model.thruOfficeCode + "/" +
      "/" + this.model.blThruBLRef.trim()
      :
      this.configService.config.BASE_API.toString() +
      "/BL/findBlByOfficeCodeAndBlNo/" +
      this.cookieService.getDefaultLocationCode() +
      "/" + this.model.blNo.trim();
    this.genericService
      .GET(url)
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          this.checkValidSchedule(
            data["blData"].blOceanVesselId,
            data["blData"].blOceanVesselVoyage,
            data["blData"].blOceanVesselBound,
            data["blData"].blOfficeCode
          );

          this.lockParty = false;
          this.lockPlace = false;
          this.lockContainer = false;
          this.lockMarkNo = false;
          this.lockDesc = false;
          this.lockNettCharge = false;
          this.lockGrossCharge = false;
          this.lockNettChargeConsole = false;
          this.lockGrossChargeConsole = false;

          this.lockTab1 = false;
          this.lockTab2 = false;
          this.lockTab3 = false;
          this.lockTab4 = false;
          this.lockTab5 = false;
          this.lockTab6 = false;
          this.lockTab8 = false;

          if (this.blLock == "Y") {
            this.disableToolbarButtons =
              "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;
          } else if (this.blLock == "T1") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = false;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false; // to show icon and clickable tab
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          } else if (this.blLock == "T2") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPlaceClicked();
          } else if (this.blLock == "T3") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = false;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = false;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabContainerClicked();
          } else if (this.blLock == "T4") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = true;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = true;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabMarkClicked();
          } else if (this.blLock == "T5") {
            this.disableToolbarButtons = "printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabDescClicked();
          } else if (this.blLock == "T6") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T7") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = true;
           this.lockPlace = true;
           this.lockContainer = true;
           this.lockMarkNo = true;
           this.lockDesc = true;
           this.lockNettCharge = false;
           this.lockGrossCharge = true;
           */

            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = true;
            this.lockTab2 = true;
            this.lockTab3 = true;
            this.lockTab4 = true;
            this.lockTab5 = true;
            this.lockTab6 = false;
            this.lockTab8 = false;

            this.tabNettClicked();
          } else if (this.blLock == "T8") {
            this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
            //hardcode
            if (
              this.cookieService.getName() == "muchtar" ||
              this.viewOnlyStatus
            ) {
              this.disableToolbarButtons =
                "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
            }
            /*
           this.lockParty = false;
           this.lockPlace = false;
           this.lockContainer = true;
           this.lockMarkNo = false;
           this.lockDesc = false;
           this.lockNettCharge = true;
           this.lockGrossCharge = true;
           */
            this.lockParty = false;
            this.lockPlace = false;
            this.lockContainer = false;
            this.lockMarkNo = false;
            this.lockDesc = false;
            this.lockNettCharge = false;
            this.lockGrossCharge = false;
            this.lockNettChargeConsole = false;
            this.lockGrossChargeConsole = false;

            this.lockTab1 = false;
            this.lockTab2 = false;
            this.lockTab3 = true;
            this.lockTab4 = false;
            this.lockTab5 = false;
            this.lockTab6 = true;
            this.lockTab8 = true;

            this.tabPartyClicked();
          }

          //cek view only menu button new container/markno/desc disable
          if (this.viewOnlyStatus){
            this.btnBlContainerNew = false;
            this.btnBlMarkNoNew = false;
            this.disableNewUpdateChargeButton = false;

          }

          if (
            this.blLock == "T1" ||
            this.blLock == "T2" ||
            this.blLock == "T3" ||
            this.blLock == "T4" ||
            this.blLock == "T5" ||
            this.blLock == "T6" ||
            this.blLock == "T7" ||
            this.blLock == "T8" ||
            this.blLock == "N" ||
            this.blLock == ""
          ) {
            console.log(
              "this.blLockUserid = " +
                this.blLockUserid +
                " - " +
                this.cookieService.getName().toUpperCase()
            );

            if (this.blLockUserid == undefined) {
              if (this.blLockUserid == undefined) {
                this.doLock(
                  this.cookieService.getDefaultLocationCode(),
                  this.model.blNo,
                  this.cookieService.getName().toUpperCase()
                );
              }

              if (data.blData.blStatus === "D") {
                if (
                  this.isOpenedFromFlagStatus ||
                  this.isOpenedFromCrossRef ||
                  this.isOpenedFromCrossBookRef
                ) {
                  this.onBlDialogViewOnlyYes();
                } else {
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    data.blData.blUserDeleted +
                    ", View Only?";
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                }
              } else {
                if (this.officeCode == "THBKK") {
                  this.disableToolbarButtons = "retrieve,printInvoice,partialSave2,save2";
                } else {
                  // GOS-345
                  if (this.officeCode.substr(0, 2) === 'IN') {
                    // unlock
                    this.disableToolbarButtons = "retrieve,partialSave2,save2";
                  } else {
                    // keep lock
                    this.disableToolbarButtons = "retrieve,partialSave2,save2,ias";
                  }
                }

                this.lockAddressRetrieve = true;
                this.lockAddressRetrieve1 = true;
                this.lockAddressRetrieve2 = true;
                this.setData(data, false);

                // release the tab
                if (this.blLock == "N") {
                  this.lockParty = false;
                  this.lockPlace = false;
                  this.lockContainer = false;
                  this.lockMarkNo = false;
                  this.lockDesc = false;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = false;
                  this.lockNettChargeConsole = false;
                  this.lockGrossChargeConsole = false;

                  this.lockTab1 = false;
                  this.lockTab2 = false;
                  this.lockTab3 = false;
                  this.lockTab4 = false;
                  this.lockTab5 = false;
                  this.lockTab6 = false;
                  this.lockTab8 = false;
                }
              }
            } else {
              // GOS-400 fix Inconsistent prompting of Deleted BL
              if (data.blData.blStatus === "D") {
                if (!localStorage.getItem("navigateFromTransactionBlFlagStatus")) {
                  this.frmDialogMessage =
                    "Current B/L is Deleted by " +
                    data.blData.blUserDeleted +
                    ", View Only?";
                  this.isOpenedByOtherUser = true;
                  this.genericUtil.showDialog(
                    "blDialogViewOnly",
                    "Confirm",
                    350,
                    150
                  );
                } else {
                  this.isOpenedByOtherUser = true;
                  this.onBlDialogViewOnlyYes();
                }
              } else {
                this.frmDialogMessage =
                  "Current B/L is Locked by " +
                  this.blLockUserid +
                  ", View Only?";
                this.isOpenedByOtherUser = true;
                this.genericUtil.showDialog(
                  "blDialogViewOnly",
                  "Confirm",
                  350,
                  150
                );
              }
            }
          } else {
            /*
           if(this.officeCode == 'THBKK'){
           this.disableToolbarButtons = 'retrieve,printInvoice';
           } else {
           this.disableToolbarButtons = 'retrieve';
           }
           this.setData(data);
           */
            this.lockAddressRetrieve = true;
            this.lockAddressRetrieve1 = true;
            this.lockAddressRetrieve2 = true;
            this.setData(data, false);
          }
        }

        this.genericUtil.hideLoader();
      });
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event !== '') {
      eval(event);
    }
  }

  blockEnter(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

  /** End of Misc Event Block **/

  /********  01. TAB PARTY EVENT ********/
  changeEventCbPartyShipper(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blShipperId = event.customerId;
      this.model.blShipperName = event.customerName;
      this.cbBlPartyShipperAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );

      const self = this;
      this.cbBlPartyShipperAddress.loadSelect(function () {
        if (self.cbBlPartyShipperAddress.listStore.store.length > 0) {
          self.cbBlPartyShipperAddress.setValue(
            self.cbBlPartyShipperAddress.listStore.store[0]["customerSeqno"]
          );
          let fullAddress =
            self.cbBlPartyShipperAddress.listStore.store[0][
              "customerDisplayName"
            ] +
            "\n" +
            self.cbBlPartyShipperAddress.listStore.store[0][
              "customerFullAddress"
            ];
          if (
            self.cbBlPartyShipperAddress.listStore.store[0]["customerTelNo"] !==
            ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyShipperAddress.listStore.store[0]["customerTelNo"];
          }
          if (
            self.cbBlPartyShipperAddress.listStore.store[0]["customerFax1"] !==
            ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyShipperAddress.listStore.store[0]["customerFax1"];
          }

          // self.txtBlPartyShipperAddress.setValue(self.cbBlPartyShipperAddress.listStore.store[0]['customerFullAddress']);
          self.txtBlPartyShipperAddress.setValue(fullAddress);
          self.model.blShipperAddressOption =
            self.cbBlPartyShipperAddress.listStore.store[0]["customerSeqno"];
        } else {
          self.txtBlPartyShipperAddress.setValue("");
        }
      });

      /*
       if (this.model.blShipperAddressText != null &&
       this.model.blShipperAddressText !== '' &&
       this.model.blShipperAddressText !== undefined) {
       const self = this;
       this.cbBlPartyShipperAddress.loadSelect( function() {
       self.cbBlPartyShipperAddress.setValue((String(self.model.blShipperAddressOption).toString() === '0') ? '' : String(self.model.blShipperAddressOption).toString());
       self.txtBlPartyShipperAddress.setValue(self.model.blShipperAddressText);
       });
       } else {
       const self = this;
       this.cbBlPartyShipperAddress.loadSelect(function() {
       if (self.cbBlPartyShipperAddress.listStore.store.length > 0) {
       self.cbBlPartyShipperAddress.setValue(self.cbBlPartyShipperAddress.listStore.store[0]['customerSeqno']);
       let fullAddress = self.cbBlPartyShipperAddress.listStore.store[0]['customerDisplayName'] + '\n' +
       self.cbBlPartyShipperAddress.listStore.store[0]['customerFullAddress'];
       if (self.cbBlPartyShipperAddress.listStore.store[0]['customerTelNo'] !== '') {
       fullAddress += '\n' + self.cbBlPartyShipperAddress.listStore.store[0]['customerTelNo'];
       }
       if (self.cbBlPartyShipperAddress.listStore.store[0]['customerFax1'] !== '') {
       fullAddress += '\n' + self.cbBlPartyShipperAddress.listStore.store[0]['customerFax1'];
       }

       // self.txtBlPartyShipperAddress.setValue(self.cbBlPartyShipperAddress.listStore.store[0]['customerFullAddress']);
       self.txtBlPartyShipperAddress.setValue(fullAddress);
       self.model.blShipperAddressOption = self.cbBlPartyShipperAddress.listStore.store[0]['customerSeqno'];
       } else {
       self.txtBlPartyShipperAddress.setValue('');
       }
       });
       }
       */
    } else {
      this.model.blShipperId = "";
      this.model.blShipperName = "";
      this.cbBlPartyShipperAddress.setUrl("");
      this.cbBlPartyShipperAddress.clearSelect();
      this.cbBlPartyShipperAddress.listStore.store = [];
    }
    // this.cbBlPartyShipper.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findByComboBoxControlShipper/customerName={query}"
    // );

    this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/shipper/"+this.dateIssueforCbShipper+"/"+this.custIdforCbShipper+"/customerName={query}");

    this.lockAddressRetrieve=false;
  }

  changeEventCbPartyShipperAddress(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      let fullAddress =
        event.customerDisplayName + "\n" + event.customerFullAddress;

      if (event.customerTelNo !== "") {
        fullAddress += "\n" + event.customerTelNo;
      }

      if (event.customerFax1 !== "") {
        fullAddress += "\n" + event.customerFax1;
      }
      this.txtBlPartyShipperAddress.setValue(fullAddress);
      this.model.blShipperAddressOption = event.customerSeqno;
    }
  }

  changeEventCbNettChargeBillingParty1Address(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blExtraPayer1nameAddressOption = event.customerSeqno;
      this.model.blExtraPayer1nameAddressName = event.customerDisplayName;

    }else{
      this.model.blExtraPayer1nameAddressOption =0;
      this.model.blExtraPayer1nameAddressName ="";
      this.cbBlNettChargeBillingParty1Address.setUrl("");
      this.cbBlNettChargeBillingParty1Address.loadSelect();
      this.cbBlNettChargeBillingParty1Address.listStore.store = [];
    }
  }
  changeEventCbNettChargeBillingParty2Address(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blExtraPayer2nameAddressOption = event.customerSeqno;
      this.model.blExtraPayer2nameAddressName = event.customerDisplayName;
    }else{
      this.model.blExtraPayer2nameAddressOption =0;
      this.model.blExtraPayer2nameAddressName ="";
      this.cbBlNettChargeBillingParty2Address.setUrl("");
      this.cbBlNettChargeBillingParty2Address.loadSelect();
      this.cbBlNettChargeBillingParty2Address.listStore.store = [];
    }
  }
  changeEventCbNettChargeBillingParty3Address(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blExtraPayer3nameAddressOption = event.customerSeqno;
      this.model.blExtraPayer3nameAddressName = event.customerDisplayName;
    }else{
      this.model.blExtraPayer3nameAddressOption =0;
      this.model.blExtraPayer3nameAddressName ="";
      this.cbBlNettChargeBillingParty3Address.setUrl("");
      this.cbBlNettChargeBillingParty3Address.loadSelect();
      this.cbBlNettChargeBillingParty3Address.listStore.store = [];
    }
  }


  changeEventCbPartyConsignee(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blConsigneeAddressText =
        this.txtBlPartyConsigneeAddress.getValue();
      this.model.blConsigneeId = event.customerId;
      this.model.blConsigneeName = event.customerName;
      console.log("--- consignee -- fired--");
      this.cbBlPartyConsigneeAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );

      const self = this;
      this.cbBlPartyConsigneeAddress.loadSelect(function (cbConsignee) {
        if (self.cbBlPartyConsigneeAddress.listStore.store.length > 0) {
          self.cbBlPartyConsigneeAddress.setValue(
            self.cbBlPartyConsigneeAddress.listStore.store[0]["customerSeqno"]
          );
          // self.txtBlPartyConsigneeAddress.setValue(self.cbBlPartyConsigneeAddress.listStore.store[0]['customerFullAddress']);
          let fullAddress =
            self.cbBlPartyConsigneeAddress.listStore.store[0][
              "customerDisplayName"
            ] +
            "\n" +
            self.cbBlPartyConsigneeAddress.listStore.store[0][
              "customerFullAddress"
            ];
          if (
            self.cbBlPartyConsigneeAddress.listStore.store[0][
              "customerTelNo"
            ] !== ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyConsigneeAddress.listStore.store[0][
                "customerTelNo"
              ];
          }
          if (
            self.cbBlPartyConsigneeAddress.listStore.store[0][
              "customerFax1"
            ] !== ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyConsigneeAddress.listStore.store[0]["customerFax1"];
          }

          self.txtBlPartyConsigneeAddress.setValue(fullAddress);
          self.model.blConsigneeAddressOption =
            self.cbBlPartyConsigneeAddress.listStore.store[0]["customerSeqno"];
          self.model.blConsigneeName = event.customerName;
        } else {
          self.txtBlPartyConsigneeAddress.setValue("");
        }
      });

      /*
       if (this.model.blConsigneeAddressText != null &&
       this.model.blConsigneeAddressText !== '' &&
       this.model.blConsigneeAddressText !== undefined) {
       const self = this;
       this.cbBlPartyConsigneeAddress.loadSelect( function(cbShipper) {
       self.cbBlPartyConsigneeAddress.setValue((String(self.model.blConsigneeAddressOption).toString() === '0') ? '' : String(self.model.blConsigneeAddressOption).toString());
       self.txtBlPartyConsigneeAddress.setValue(self.model.blConsigneeAddressText);
       });
       } else {
       const self = this;
       this.cbBlPartyConsigneeAddress.loadSelect(function(blPartyConsigneeAddressCallback) {
       if (self.cbBlPartyConsigneeAddress.listStore.store.length > 0) {
       self.cbBlPartyConsigneeAddress.setValue(self.cbBlPartyConsigneeAddress.listStore.store[0]['customerSeqno']);
       // self.txtBlPartyConsigneeAddress.setValue(self.cbBlPartyConsigneeAddress.listStore.store[0]['customerFullAddress']);
       let fullAddress = self.cbBlPartyConsigneeAddress.listStore.store[0]['customerDisplayName'] + '\n' +
       self.cbBlPartyConsigneeAddress.listStore.store[0]['customerFullAddress'];
       if (self.cbBlPartyConsigneeAddress.listStore.store[0]['customerTelNo'] !== '') {
       fullAddress += '\n' + self.cbBlPartyConsigneeAddress.listStore.store[0]['customerTelNo'];
       }
       if (self.cbBlPartyConsigneeAddress.listStore.store[0]['customerFax1'] !== '') {
       fullAddress += '\n' + self.cbBlPartyConsigneeAddress.listStore.store[0]['customerFax1'];
       }

       self.txtBlPartyConsigneeAddress.setValue(fullAddress);
       self.model.blConsigneeAddressOption = self.cbBlPartyConsigneeAddress.listStore.store[0]['customerSeqno'];
       self.model.blConsigneeName = event.customerName;
       } else {
       self.txtBlPartyConsigneeAddress.setValue('');
       }
       });
       }
       */
    } else {

      this.model.blConsigneeId = "";
      this.model.blConsigneeName = "";

      this.cbBlPartyConsigneeAddress.setUrl("");
      this.cbBlPartyConsigneeAddress.clearSelect();
      this.cbBlPartyConsigneeAddress.listStore.store = [];
    }
    // this.cbBlPartyConsignee.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findByComboBoxControlConsignee/customerName={query}"
    // );

    this.cbBlPartyConsignee.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/consignee/"+this.dateIssueforCbShipper+"/"+this.cneeIdforCbCnee+"/customerName={query}");

    this.lockAddressRetrieve1 = false;
  }

  changeEventCbPartyConsigneeAddress(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      let fullAddress =
        event.customerDisplayName + "\n" + event.customerFullAddress;

      if (event.customerTelNo !== "") {
        fullAddress += "\n" + event.customerTelNo;
      }

      if (event.customerFax1 !== "") {
        fullAddress += "\n" + event.customerFax1;
      }
      // this.txtBlPartyConsigneeAddress.setValue(event.customerFullAddress);
      this.txtBlPartyConsigneeAddress.setValue(fullAddress);

      this.model.blConsigneeAddressOption = event.customerSeqno;
    }
  }

  changeEventCbPartyNotifyParty(event) {
    console.log("** changeEventCbPartyNotifyParty **");
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.model.blNotifyPartyAddressText =
        this.txtBlPartyNotifyPartyAddress.getValue();
      console.log("------> " + this.model.blNotifyPartyAddressText);
      this.model.blNotifyPartyId = event.customerId;
      this.model.blNotifyPartyName = event.customerName;
      this.cbBlPartyNotifyPartyAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );

      const self = this;
      this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
        if (self.cbBlPartyNotifyPartyAddress.listStore.store.length > 0) {
          self.cbBlPartyNotifyPartyAddress.setValue(
            self.cbBlPartyNotifyPartyAddress.listStore.store[0]["customerSeqno"]
          );
          // self.txtBlPartyNotifyPartyAddress.setValue(self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerFullAddress']);
          let fullAddress =
            self.cbBlPartyNotifyPartyAddress.listStore.store[0][
              "customerDisplayName"
            ] +
            "\n" +
            self.cbBlPartyNotifyPartyAddress.listStore.store[0][
              "customerFullAddress"
            ];
          if (
            self.cbBlPartyNotifyPartyAddress.listStore.store[0][
              "customerTelNo"
            ] !== ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyNotifyPartyAddress.listStore.store[0][
                "customerTelNo"
              ];
          }
          if (
            self.cbBlPartyNotifyPartyAddress.listStore.store[0][
              "customerFax1"
            ] !== ""
          ) {
            fullAddress +=
              "\n" +
              self.cbBlPartyNotifyPartyAddress.listStore.store[0][
                "customerFax1"
              ];
          }

          self.txtBlPartyNotifyPartyAddress.setValue(fullAddress);
          self.model.blNotifyPartyAddressOption =
            self.cbBlPartyNotifyPartyAddress.listStore.store[0][
              "customerSeqno"
            ];
          self.model.blNotifyPartyName = event.customerName;
        } else {
          self.txtBlPartyNotifyPartyAddress.setValue("");
        }
      });

      /*
       if (this.model.blNotifyPartyAddressText != null &&
       this.model.blNotifyPartyAddressText !== '' &&
       this.model.blNotifyPartyAddressText !== undefined) {
       const self = this;
       this.cbBlPartyNotifyPartyAddress.loadSelect( function() {
       self.cbBlPartyNotifyPartyAddress.setValue((String(self.model.blNotifyPartyAddressOption).toString() === '0') ? '' : String(self.model.blNotifyPartyAddressOption).toString());
       self.txtBlPartyNotifyPartyAddress.setValue(self.model.blNotifyPartyAddressText);
       });
       } else {
       const self = this;
       this.cbBlPartyNotifyPartyAddress.loadSelect(function() {
       if (self.cbBlPartyNotifyPartyAddress.listStore.store.length > 0) {
       self.cbBlPartyNotifyPartyAddress.setValue(self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerSeqno']);
       // self.txtBlPartyNotifyPartyAddress.setValue(self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerFullAddress']);
       let fullAddress = self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerDisplayName'] + '\n' +
       self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerFullAddress'];
       if (self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerTelNo'] !== '') {
       fullAddress += '\n' + self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerTelNo'];
       }
       if (self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerFax1'] !== '') {
       fullAddress += '\n' + self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerFax1'];
       }

       self.txtBlPartyNotifyPartyAddress.setValue(fullAddress);
       self.model.blNotifyPartyAddressOption = self.cbBlPartyNotifyPartyAddress.listStore.store[0]['customerSeqno'];
       self.model.blNotifyPartyName = event.customerName;
       } else {
       self.txtBlPartyNotifyPartyAddress.setValue('');
       }
       });
       }
       */
    } else {
      this.model.blNotifyPartyId = "";
      this.model.blNotifyPartyName = "";

      this.cbBlPartyNotifyPartyAddress.setUrl("");
      this.cbBlPartyNotifyPartyAddress.clearSelect();
      this.cbBlPartyNotifyPartyAddress.listStore.store = [];
    }
    // this.cbBlPartyNotifyParty.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findByComboBoxControlNotifyParty/customerName={query}"
    // );

    this.cbBlPartyNotifyParty.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/notify_party/"+this.dateIssueforCbShipper+"/"+this.partyIdforCbParty+"/customerName={query}");

    this.lockAddressRetrieve2 = false;
  }

  changeEventCbPartyNotifyPartyAddress(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      let fullAddress =
        event.customerDisplayName + "\n" + event.customerFullAddress;

      if (event.customerTelNo !== "") {
        fullAddress += "\n" + event.customerTelNo;
      }

      if (event.customerFax1 !== "") {
        fullAddress += "\n" + event.customerFax1;
      }
      this.txtBlPartyNotifyPartyAddress.setValue(fullAddress);
      this.model.blNotifyPartyAddressOption = event.customerSeqno;
    }
  }

  btnBlPartyConsigneeToOrder() {
    var self = this;
    this.model.blConsigneeId = "CS0000090987";
    this.model.blConsigneeName = "***";

    this.cbBlPartyConsignee.setForceValue(this.model.blConsigneeName);
    this.model.blConsigneeAddressText = "";
    this.model.blConsigneeAddressOption = 0;

    this.cbBlPartyConsigneeAddress.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCustomerAddressByCustomerId/" +
        this.model.blConsigneeId.trim()
    );
    this.cbBlPartyConsigneeAddress.loadSelect(function () {
      self.cbBlPartyConsigneeAddress.setValue(
        String(self.model.blConsigneeAddressOption).toString() === "0"
          ? ""
          : String(self.model.blConsigneeAddressOption).toString()
      );
      self.txtBlPartyConsigneeAddress.setValue("TO ORDER");
    });
  }

  btnBlPartyConsigneeToOrderShipper() {
    var self = this;
    this.model.blConsigneeId = "CS0000090987";
    this.model.blConsigneeName = "***";
    this.cbBlPartyConsignee.setForceValue(this.model.blConsigneeName);
    this.model.blConsigneeAddressText = "";
    this.model.blConsigneeAddressOption = 0;

    this.cbBlPartyConsigneeAddress.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCustomerAddressByCustomerId/" +
        this.model.blConsigneeId.trim()
    );
    this.cbBlPartyConsigneeAddress.loadSelect(function () {
      self.cbBlPartyConsigneeAddress.setValue(
        String(self.model.blConsigneeAddressOption).toString() === "0"
          ? ""
          : String(self.model.blConsigneeAddressOption).toString()
      );
      self.txtBlPartyConsigneeAddress.setValue("TO ORDER OF SHIPPER");
    });
  }

  btnBlPartyNotifyPartyAsConsigneeAsCnee() {
    this.frmDialogMessage = "Overwrite current Notify Party Address?";
    this.genericUtil.showDialog(
      "blOverwriteNotifyPartyAddressAsCnee",
      "Confirmation",
      350,
      150
    );
  }

  btnBlPartyNotifyPartySameAsConsignee() {
    this.frmDialogMessage = "Overwrite current Notify Party Address?";
    this.genericUtil.showDialog(
      "blOverwriteNotifyPartyAddress2",
      "Confirmation",
      350,
      150
    );
  }

  changeEventRbBlContainerOwnershipCOC(event) {
    if (event.target.checked) {
      this.model.blContainerOwnership = "COC";
    }
  }

  changeEventRbBlContainerOwnershipCOCTS(event) {
    if (event.target.checked) {
      this.model.blContainerOwnership = "COCTS";
    }
  }

  changeEventRbBlContainerOwnershipSOC(event) {
    if (event.target.checked) {
      this.model.blContainerOwnership = "SOC";
    }
  }

  changeEventRbBlContainerOwnershipSOCTS(event) {
    if (event.target.checked) {
      this.model.blContainerOwnership = "SOCTS";
    }
  }

  changeEventRbBlContainerOwnershipSLOT(event) {
    if (event.target.checked) {
      this.model.blContainerOwnership = "SLOT";
    }
  }

  changeEventRbBlContainerStatusEmpty(event) {
    if (event.target.checked) {
      this.model.blContainerStatus = "EMPTY";
    }
  }

  changeEventRbBlContainerStatusFull(event) {
    if (event.target.checked) {
      this.model.blContainerStatus = "FULL";
    } else {
      this.model.blContainerStatus = "EMPTY";
    }
  }

  changeEventRbBlDgClassC3(event) {
    if (event.target.checked) {
      this.model.blDgClass = "C3";
    }
  }

  changeEventRbBlDgClassC2(event) {
    if (event.target.checked) {
      this.model.blDgClass = "C2";
    }
  }

  changeEventRbBlDgClassC2F(event) {
    if (event.target.checked) {
      this.model.blDgClass = "C2F";
    }
  }

  changeEventRbBlDgClassC1(event) {
    if (event.target.checked) {
      this.model.blDgClass = "C1";
    }
  }

  changeEventRbBlDgClassNA(event) {
    if (event.target.checked) {
      this.model.blDgClass = "N";
    }
  }

  changeEventChBlPartySkipPrintShipperDraftBlNo(event) {
    if (event.target.checked) {
      this.model.blShipperDraftBlNoSkipPrint = true;
      // this.chkSkipPrintShipperDraftBlRef = true;
    } else {
      this.model.blShipperDraftBlNoSkipPrint = false;
      // this.chkSkipPrintShipperDraftBlRef = false;
    }
  }

  changeEventRbBlPartyMemo(event) {
    if (event.target.checked) {
      this.model.blType = "MEMO";
      this.model.blNoOfOBL = 0;
      this.model.blNoOfOBLConsole = 0;
    }
  }

  changeEventRbBlPartySW(event) {
    if (event.target.checked) {
      this.model.blType = "SW";
      if (
        this.officeCode == "SGSIN" ||
        this.officeCode == "MYKUA" ||
        this.officeCode == "MYPKG" ||
        this.officeCode == "MYPGU" ||
        this.officeCode == "MYPEN"
      ) {
        this.model.blNoOfOBL = 3;
        this.model.blNoOfOBLConsole = 3;
      }
    }
  }

  changeEventRbBlPartyCRB(event) {
    if (event.target.checked) {
      this.model.blType = "CRB";
      if (
        this.officeCode == "SGSIN" ||
        this.officeCode == "MYKUA" ||
        this.officeCode == "MYPKG" ||
        this.officeCode == "MYPGU" ||
        this.officeCode == "MYPEN"
      ) {
        this.model.blNoOfOBL = 3;
        this.model.blNoOfOBLConsole = 3;
      }
    }
  }

  changeEventRbBlPartyRFS(event) {
    if (event.target.checked) {
      this.model.blType = "RFS";
      if (
        this.officeCode == "SGSIN" ||
        this.officeCode == "MYKUA" ||
        this.officeCode == "MYPKG" ||
        this.officeCode == "MYPGU" ||
        this.officeCode == "MYPEN"
      ) {
        this.model.blNoOfOBL = 3;
        this.model.blNoOfOBLConsole = 3;
      }

      this.model.blIsPrintShippedOnBoard = false;
    }
  }

  changeEventRbBlPartyOIS(event) {
    if (event.target.checked) {
      this.model.blType = "OIS";
      if (
        this.officeCode == "SGSIN" ||
        this.officeCode == "MYKUA" ||
        this.officeCode == "MYPKG" ||
        this.officeCode == "MYPGU" ||
        this.officeCode == "MYPEN"
      ) {
        this.model.blNoOfOBL = 3;
        this.model.blNoOfOBLConsole = 3;
      }
    }
  }

  changeEventRbBlPartyIRE(event) {
    if (event.target.checked) {
      this.model.blType = "IRE";
      if (
        this.officeCode == "SGSIN" ||
        this.officeCode == "MYKUA" ||
        this.officeCode == "MYPKG" ||
        this.officeCode == "MYPGU" ||
        this.officeCode == "MYPEN"
      ) {
        this.model.blNoOfOBL = 3;
        this.model.blNoOfOBLConsole = 3;
      }
    }
  }

  changeEventRbBlPartyNA(event) {
    if (event.target.checked) {
      this.model.blType = "N";
      this.model.blNoOfOBL = 3;
      this.model.blNoOfOBLConsole = 3;
      this.model.blIsPrintShippedOnBoard = true;
    }
  }

  changeEventRbBlDetentionNA(event) {
    if (event.target.checked) {
      this.model.blDetention = "N";
    }
  }

  changeEventRbBlDetentionStandard(event) {
    if (event.target.checked) {
      this.model.blDetention = "S";
    }
  }

  changeEventRbBlDetentionRequest(event) {
    if (event.target.checked) {
      this.model.blDetention = "R";
    }
  }

  changeEventRbBlDemurrageNA(event) {
    if (event.target.checked) {
      this.model.blDetention = "N";
    }
  }

  changeEventRbBlDemurrageStandard(event) {
    if (event.target.checked) {
      this.model.blDetention = "S";
    }
  }

  changeEventRbBlDemurrageRequest(event) {
    if (event.target.checked) {
      this.model.blDetention = "R";
    }
  }

  /********  02. TAB PLACE ********/
  changeEventCbBlPlaceReceiptType(event) {
    if (
      event.receiptTypeId != null &&
      event.receiptTypeId !== "" &&
      event.receiptTypeId !== undefined
    ) {
      this.model.blReceiptTypeCode = event.receiptTypeCode;
    } else {
      this.model.blReceiptTypeCode = "";
    }
    this.cbBlPlaceReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}"
    );
  }

  changeEventCbBlPlaceDeliveryType(event) {
    if (
      event.deliveryTypeId != null &&
      event.deliveryTypeId !== "" &&
      event.deliveryTypeId !== undefined
    ) {
      this.model.blDeliveryTypeCode = event.deliveryTypeCode;
    } else {
      this.model.blDeliveryTypeCode = "";
    }
    this.cbBlPlaceDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeLike={query}"
    );
  }

  changeEventCbBlPlacePOR(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPlaceOfReceiptCode = event.locationCode;
      // if (this.model.blPlaceOfReceiptPrint == null ||
      // this.model.blPlaceOfReceiptPrint === '' ||
      // this.model.blPlaceOfReceiptPrint === undefined ) {
      //    this.model.blPlaceOfReceiptPrint = (this.model.blPlaceOfReceiptPrint === '') ? event.locationName : '';
      this.model.blPlaceOfReceiptPrint = event.locationName;
      this.blPlacePlaceOfReceiptPrintLock = false;
      // }
    } else {
      this.model.blPlaceOfDeliveryCode = "";
      this.model.blPlaceOfReceiptPrint = "";
      this.blPlacePlaceOfReceiptPrintLock = true;

    }
    this.cbBlPlacePOR.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePOL(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blLoadPortCode = event.locationCode;
      // if (this.model.blLoadPortPrint == null ||
      // this.model.blLoadPortPrint === '' ||
      // this.model.blLoadPortPrint === undefined ) {
      // this.model.blLoadPortPrint = (this.model.blLoadPortPrint === '') ? event.locationName : '';
      this.model.blLoadPortPrint = event.locationName;
      this.blPlaceLoadPortPrintLock = false;
      /*
       // set the Place of issue
       if (this.model.blType === 'SW' || this.model.blType === 'OIS') {
       this.model.blPlaceOfIssueCode = event.locationCode;
       this.model.blPlaceOfIssueName = event.locationName;
       this.cbBlNettChargePlaceOfIssue.setForceValue(this.model.blPlaceOfIssueName);

       } else {
       console.log('goes here, and the bltype=' + this.model.blType);
       this.cbBlNettChargePlaceOfIssue.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlPlaceOfIssue/locationCode={query}');
       this.cbBlNettChargePlaceOfIssue.setValue(event.locationCode);
       }
       */

      // }
    } else {
      this.model.blLoadPortCode = "";
      this.model.blLoadPortPrint = "";
      this.blPlaceLoadPortPrintLock = true;
    }
    this.cbBlPlacePOL.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePOD(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blDischargePortCode = event.locationCode;
      // if (this.model.blDischargePortPrint == null ||
      // this.model.blDischargePortPrint === '' || this.model.blDischargePortPrint === undefined ) {
      //  this.model.blDischargePortPrint = (this.model.blDischargePortPrint === '') ? event.locationName : '';
      this.model.blDischargePortPrint = event.locationName;
      this.blPlaceDischargePortPrintLock = false;
      // }
    } else {
      this.model.blDischargePortCode = "";
      this.model.blDischargePortPrint = "";
      this.blPlaceDischargePortPrintLock = true;
    }
    this.cbBlPlacePOD.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePODL(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPlaceOfDeliveryCode = event.locationCode;
      // if (this.model.blPlaceOfDeliveryPrint == null ||
      // this.model.blPlaceOfDeliveryPrint === '' ||
      // this.model.blDischargePortPrint === undefined ) {
      // this.model.blPlaceOfDeliveryPrint = (this.model.blPlaceOfDeliveryPrint === '') ? event.locationName : '';
      this.model.blPlaceOfDeliveryPrint = event.locationName;
      this.blPlacePlaceOfDeliveryPrintLock = false;
      // }
    } else {
      this.model.blPlaceOfDeliveryCode = "";
      this.model.blPlaceOfDeliveryPrint = "";
      this.blPlacePlaceOfDeliveryPrintLock = true;
    }
    this.cbBlPlacePODL.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePOT1(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPortOfTransshipment1Code = event.locationCode;
      this.model.blPortOfTransshipment1Name = event.locationName;
    } else {
      this.model.blPortOfTransshipment1Code = "";
      this.model.blPortOfTransshipment1Name = event.locationName;
    }
    this.cbBlPlacePOT1.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePOT2(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPortOfTransshipment2Code = event.locationCode;
    } else {
      this.model.blPortOfTransshipment2Code = "";
    }
    this.cbBlPlacePOT2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePOT3(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPortOfTransshipment3Code = event.locationCode;
    } else {
      this.model.blPortOfTransshipment3Code = "";
    }
    this.cbBlPlacePOT3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlaceFDEST(event) {
    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blFinalDestinationCode = event.locationCode;
      // if (this.model.blFinalDestinationPrint == null ||
      // this.model.blFinalDestinationPrint === '' ||
      // this.model.blFinalDestinationPrint === undefined ) {
      // this.model.blFinalDestinationPrint = (this.model.blFinalDestinationPrint === '') ? event.locationName : '';
      this.model.blFinalDestinationPrint = event.locationName;
      this.blPlacePrintBlFinalDestination2 = false;
      // }
    } else {
      this.model.blFinalDestinationCode = "";
      this.model.blFinalDestinationPrint = "";
      this.blPlacePrintBlFinalDestination2 = true;
    }
    this.cbBlPlaceFDEST.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlPlacePrincipal(event) {
    if (
      event.principalId != null &&
      event.principalId !== "" &&
      event.principalId !== undefined
    ) {
      this.model.blPrincipalId = event.principalId;
    } else {
      this.model.blPrincipalId = "";
    }
    this.cbBlPlacePrincipal.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPrincipals/findByComboBoxControlPrincipal/principalName={query}"
    );
  }

  changeEventCbBlPlaceOceanVessel(event) {
    console.log(
      "-----------------------change event ocean fire --------------------------"
    );
    console.log(event);
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.model["error-blOceanVesselId"] = "";
      this.model["error-blOceanVesselVoyage"] = "";
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;
      this.model.blOceanVesselName = event.vesselName;
      console.log("masuk check assign ocean name ");

      this.cbBlPlaceOceanVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode()
      );
    } else {
      this.model["error-blOceanVesselId"] = "";
      this.model["error-blOceanVesselVoyage"] = "";
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
      this.model.blOceanVesselName = "";
      this.model.blOceanVesselVoyage = "";
      this.cbBlPlaceOceanVoyage.setUrl("");
      this.cbBlPlaceOceanVoyage.setValue("");
      this.model.blOceanVesselBound = "";
    }
    this.cbBlPlaceOceanVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );

    this.validSched = false;
  }

  changeEventCbBlPlaceOceanVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage !== "" &&
      event.voyage !== undefined
    ) {
      let boundUppercase: String = event.bound.toUpperCase();
      this.model["error-blOceanVesselVoyage"] = "";
      this.model.blOceanVesselVoyage = event.voyage;
      this.model.blOceanVesselBound = event.bound;
      this.cbBlPlaceOceanVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode()
      );

      // set service
      this.cbBlPlaceService.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterServices/findByComboBoxControlService/serviceCode={query}"
      );
      this.cbBlPlaceService.setValue(event.serviceCode);

      //check valid schedule
      this.checkValidSchedule(
        this.model.blOceanVesselId,
        this.model.blOceanVesselVoyage,
        boundUppercase,
        this.model.blOfficeCode
      );

      if (!this.validSched) {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      }

      this.setBlIsShippedOnBoardDateRetrieved();
    } else {
      this.model.blOceanVesselVoyage = "";
    }
  }

  changeBlOceanBound() {
    this.cbBlPlaceOceanVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyage/" +
        this.model.blOceanVesselId +
        "/" +
        this.model.blOceanVesselBound.toUpperCase() +
        "/{query}/" +
        this.cookieService.getDefaultLocationCode()
    );
    this.cbBlPlaceOceanVoyage.setValue("");
    this.model.blOceanVesselVoyage = "";
  }

  //gos-263

  findSOBPreVessel(){
    let hitUrl = "";
    //cek master vessel schedule go
    hitUrl =
    this.configService.config.BASE_API.toString() +
    "/MasterVesselSchedules/findSOB/" +
    this.model.blPreVesselId +
    "/" +
    this.sCharUtil.htmlEncode(
      String(this.model.blPreVesselVoyage).trim()
    ) ;

    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        console.log("MASUK FIND SOB ");
        const dm = resp.json()["content"];
        console.log("Vessel Id nye : "+dm[0].ETD);
        if (dm[0] != null && dm[0] !== "" && dm[0] !== undefined) {
          if (this.model.blExtraShippedOnBoardLock !== true) {
            if(dm[0].ETD !== ""){
              var date = dm[0].ETD.substr(0,11).split("-");
              console.log("Date nye : "+date);
              var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              for(var j=0;j<months.length;j++){
                  if(date[1]==months[j]){
                      date[1]=months.indexOf(months[j])+1;
                  }
              }
              if(date[1]<10){
                  date[1]='0'+date[1];
              }

              console.log("Date2 nye : "+date);
              var localETD = date[2]+"-"+date[1]+"-"+date[0];

              let dtt: string[] = String(localETD).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeShippedOnBoard.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );

              //this.model.blExtraShippedOnBoard = dm[0].ETD;
              //this.tmpShippedOnBoard = dm[0].ETD;

            }else{
              hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blPreVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blPreVesselVoyage).trim()
              ) +
              "/" +
              this.model.blPreVesselBound +
              "/" +
             this.model.blOfficeCode +
             //blOfficeNextVessel +
              "/DESC/custom";

              this.genericService.GET(hitUrl).subscribe((resp) => {
                if (resp.status === 200) {
                  const dt = resp.json()["content"];
                  if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                    if (this.model.blExtraShippedOnBoardLock !== true) {

                      let dtt: string[] = String(dt[0].localETD).split(" ");
                      let tmpDate: string[] = String(dtt[0]).split("-");

                      this.dateBlNettChargeShippedOnBoard.setValue(
                        tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                      );

                       this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                      //this.tmpShippedOnBoard = dt[0].localETD;
                    }
                  }else{
                    if (this.model.blExtraShippedOnBoardLock !== true) {
                      this.dateBlNettChargeShippedOnBoard.setValue("");
                      this.model.blExtraShippedOnBoard = "";
                    }
                  }
                }
              });

            }
          }else{
            if (this.model.blExtraShippedOnBoardLock !== true) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }

          }
        }else{
          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }

        }
        }
    });
   hitUrl =
     this.configService.config.BASE_API.toString() +
     "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
     this.model.blPreVesselId +
     "/" +
     this.sCharUtil.htmlEncode(
       String(this.model.blPreVesselVoyage).trim()
     ) +
     "/" +
     this.model.blPreVesselBound +
     "/" +
     this.model.blOfficeCode +
     //blOfficeNextVessel
     "/DESC/custom";

   this.genericService.GET(hitUrl).subscribe((resp) => {
     if (resp.status === 200) {
       const dt = resp.json()["content"];
       if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
         if (this.model.blDateOfIssueLock !== true) {
           let dtt: string[] = String(dt[0].blDate).split(" ");
           let tmpDate: string[] = String(dtt[0]).split("-");

           this.dateBlNettChargeDateOfIssue.setValue(
             tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
           );

           this.model.blDateOfIssue = dt[0].blDate;
           this.tmpDateOfIssue = dt[0].blDate;
         }

         if (this.model.blDateOfIssueLockConsole !== true) {
           let dtt: string[] = String(dt[0].blDate).split(" ");
           let tmpDate: string[] = String(dtt[0]).split("-");

           this.dateBlNettChargeDateOfIssueConsole.setValue(
             tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
           );

           this.model.blDateOfIssueConsole = dt[0].blDate;
           this.tmpDateOfIssue = dt[0].blDate;
         }

         if (this.model.blIsExchangeRateLock !== true) {
           let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
           let tmpDate: string[] = String(dtt[0]).split("-");

           this.dateBlNettChargeExchangeRate.setValue(
             tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
           );

           this.model.blExchangeRateDate = dt[0].exchangeRateDate;
           this.tmpExchangeRateDate = dt[0].exchangeRateDate;
         }

         if (this.model.blIsExchangeRateLockConsole !== true) {
           let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
           let tmpDate: string[] = String(dtt[0]).split("-");

           this.dateBlNettChargeExchangeRateConsole.setValue(
             tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
           );

           this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
           this.tmpExchangeRateDate = dt[0].exchangeRateDate;
         }

         /*if (this.model.blExtraShippedOnBoardLock !== true) {
           let dtt: string[] = String(dt[0].localETD).split(" ");
           let tmpDate: string[] = String(dtt[0]).split("-");

           this.dateBlNettChargeShippedOnBoard.setValue(
             tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
           );

            this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
           this.tmpShippedOnBoard = dt[0].blDate;
         }*/
       } else {
         if (this.model.blDateOfIssueLock !== true) {
           this.dateBlNettChargeDateOfIssue.setValue("");
           this.model.blDateOfIssue = "";
         }

         if (this.model.blDateOfIssueLockConsole !== true) {
           this.dateBlNettChargeDateOfIssueConsole.setValue("");
           this.model.blDateOfIssueConsole = "";
         }

         if (this.model.blIsExchangeRateLock !== true) {
           this.dateBlNettChargeExchangeRate.setValue("");
           this.model.blExchangeRateDate = "";
         }

         if (this.model.blIsExchangeRateLockConsole !== true) {
           this.dateBlNettChargeExchangeRateConsole.setValue("");
           this.model.blExchangeRateDateConsole = "";
         }

         /*if (this.model.blExtraShippedOnBoardLock !== true) {
           this.dateBlNettChargeShippedOnBoard.setValue("");
           this.model.blExtraShippedOnBoard = "";
         }*/
       }
     }
   });

  }

  findSOBPreVesselRetrieved(){
    let hitUrl = "";
    //cek master vessel schedule go
    hitUrl =
    this.configService.config.BASE_API.toString() +
    "/MasterVesselSchedules/findSOB/" +
    this.model.blPreVesselId +
    "/" +
    this.sCharUtil.htmlEncode(
      String(this.model.blPreVesselVoyage).trim()
    ) ;

    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        console.log("MASUK FIND SOB ");
        const dm = resp.json()["content"];
        console.log("Vessel Id nye : "+dm[0].ETD);
        if (dm[0] != null && dm[0] !== "" && dm[0] !== undefined) {
          if (this.model.blExtraShippedOnBoardLock !== false) {
            if(dm[0].ETD !== ""){
              var date = dm[0].ETD.substr(0,11).split("-");
              console.log("Date nye : "+date);
              var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              for(var j=0;j<months.length;j++){
                  if(date[1]==months[j]){
                      date[1]=months.indexOf(months[j])+1;
                  }
              }
              if(date[1]<10){
                  date[1]='0'+date[1];
              }

              console.log("Date2 nye : "+date);
              var localETD = date[2]+"-"+date[1]+"-"+date[0];

              let dtt: string[] = String(localETD).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeShippedOnBoard.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );

              //this.model.blExtraShippedOnBoard = dm[0].ETD;
              //this.tmpShippedOnBoard = dm[0].ETD;

            }else{
              hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blPreVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blPreVesselVoyage).trim()
              ) +
              "/" +
              this.model.blPreVesselBound +
              "/" +
              this.model.blOfficeCode +
              //blOfficeNextVessel +
              "/DESC/custom";

              this.genericService.GET(hitUrl).subscribe((resp) => {
                if (resp.status === 200) {
                  const dt = resp.json()["content"];
                  if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                    if (this.model.blExtraShippedOnBoardLock !== false) {

                      let dtt: string[] = String(dt[0].localETD).split(" ");
                      let tmpDate: string[] = String(dtt[0]).split("-");

                      this.dateBlNettChargeShippedOnBoard.setValue(
                        tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                      );

                       this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                      //this.tmpShippedOnBoard = dt[0].localETD;
                    }
                  }else{
                    if (this.model.blExtraShippedOnBoardLock !== false) {
                      this.dateBlNettChargeShippedOnBoard.setValue("");
                      this.model.blExtraShippedOnBoard = "";
                    }
                  }
                }
              });

            }
          }else{
            if (this.model.blExtraShippedOnBoardLock !== false) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }

          }
        }else{
          if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }

        }
      }
    });

    hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
      this.model.blPreVesselId +
      "/" +
      this.sCharUtil.htmlEncode(
        String(this.model.blPreVesselVoyage).trim()
      ) +
      "/" +
      this.model.blPreVesselBound +
      "/" +
      this.model.blOfficeCode +
      //blOfficeNextVessel +
      "/DESC/custom";

    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        const dt = resp.json()["content"];
        if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
          if (this.model.blDateOfIssueLock !== false) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssue.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

            this.model.blDateOfIssue = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blDateOfIssueLockConsole !== false) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssueConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

            this.model.blDateOfIssueConsole = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blIsExchangeRateLock !== false) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRate.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

            this.model.blExchangeRateDate = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          if (this.model.blIsExchangeRateLockConsole !== false) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRateConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

            this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          if (this.model.blExtraShippedOnBoardLock !== false) {
            let dtt: string[] = String(dt[0].localETD).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeShippedOnBoard.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

             this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
            //this.tmpShippedOnBoard = dt[0].blDate;
          }
        } else {
          if (this.model.blDateOfIssueLock !== false) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== false) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== false) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== false) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        }
      }
    });

  }

  findSOBNextVessel(blOfficeNextVessel){
    let hitUrl = "";
    //cek master vessel schedule go
    hitUrl =
    this.configService.config.BASE_API.toString() +
    "/MasterVesselSchedules/findSOBNext/" +
    this.model.blNextVesselId +
    "/" +
    this.sCharUtil.htmlEncode(
      String(this.model.blNextVesselVoyage).trim()
    ) +
    "/"+
    this.sCharUtil.htmlEncode(
      String(this.model.blPortOfTransshipment1Code).trim()
    ) ;

    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        console.log("MASUK FIND SOB NEXT ");
        const dm = resp.json()["content"];
        console.log("Vessel Id nye : "+dm[0].ETD);
        if (dm[0] != null && dm[0] !== "" && dm[0] !== undefined) {
          if (this.model.blExtraShippedOnBoardLock !== true) {
            if(dm[0].ETD !== ""){
              var date = dm[0].ETD.substr(0,11).split("-");
              console.log("Date nye : "+date);
              var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              for(var j=0;j<months.length;j++){
                  if(date[1]==months[j]){
                      date[1]=months.indexOf(months[j])+1;
                  }
              }
              if(date[1]<10){
                  date[1]='0'+date[1];
              }

              console.log("Date2 nye : "+date);
              var localETD = date[2]+"-"+date[1]+"-"+date[0];

              let dtt: string[] = String(localETD).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeShippedOnBoard.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );


              this.dateBlNettChargeShippedOnBoard.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );

               this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();


              //this.model.blExtraShippedOnBoard = dm[0].ETD;
              //this.tmpShippedOnBoard = dm[0].ETD;

            }else{
              hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blNextVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blNextVesselVoyage).trim()
              ) +
              "/" +
              this.model.blNextVesselBound +
              "/" +
              //this.model.blOfficeCode +
              blOfficeNextVessel +
              "/DESC/custom";

              this.genericService.GET(hitUrl).subscribe((resp) => {
                if (resp.status === 200) {
                  const dt = resp.json()["content"];
                  if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                    if (this.model.blExtraShippedOnBoardLock !== true) {

                      let dtt: string[] = String(dt[0].localETD).split(" ");
                      let tmpDate: string[] = String(dtt[0]).split("-");

                      this.dateBlNettChargeShippedOnBoard.setValue(
                        tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                      );

                       this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                      //this.tmpShippedOnBoard = dt[0].localETD;
                    }
                  }else{
                    if (this.model.blExtraShippedOnBoardLock !== true) {
                      this.dateBlNettChargeShippedOnBoard.setValue("");
                      this.model.blExtraShippedOnBoard = "";
                    }
                  }
                }
              });

            }
          }else{
            if (this.model.blExtraShippedOnBoardLock !== true) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }

          }
        }else{
          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }

        }
        }
    });

    hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
      this.model.blNextVesselId +
      "/" +
      this.sCharUtil.htmlEncode(
        String(this.model.blNextVesselVoyage).trim()
      ) +
      "/" +
      this.model.blNextVesselBound +
      "/" +
     // this.model.blOfficeCode +
     blOfficeNextVessel +
      "/DESC/custom";
    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        const dt = resp.json()["content"];
        if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
          if (this.model.blDateOfIssueLock !== true) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssue.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blDateOfIssue = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blDateOfIssueLockConsole !== true) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssueConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blDateOfIssueConsole = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blIsExchangeRateLock !== true) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRate.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blExchangeRateDate = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRateConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          /*if (this.model.blExtraShippedOnBoardLock !== true) {
            let dtt: string[] = String(dt[0].localETD).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeShippedOnBoard.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

             this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
            //this.tmpShippedOnBoard = dt[0].blDate;
          }*/
        } else {
          if (this.model.blDateOfIssueLock !== true) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== true) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== true) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          /*if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }*/
        }
      }
    });

  }

  findSOBNextVesselRetrieved(blOfficeNextVessel){
    let hitUrl='';
    //cek master vessel schedule go
    hitUrl =
    this.configService.config.BASE_API.toString() +
    "/MasterVesselSchedules/findSOBNext/" +
    this.model.blNextVesselId +
    "/" +
    this.sCharUtil.htmlEncode(
      String( this.model.blNextVesselVoyage ).trim()
    )+"/" +
    this.sCharUtil.htmlEncode(
      String(this.model.blPortOfTransshipment1Code).trim()
    ) ;

    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        console.log("MASUK FIND SOB ");
        const dm = resp.json()["content"];
        console.log("Vessel Id nye : "+dm[0].ETD);
        if (dm[0] != null && dm[0] !== "" && dm[0] !== undefined) {
          if (this.model.blExtraShippedOnBoardLock !== false) {
            if(dm[0].ETD !== ""){
              var date = dm[0].ETD.substr(0,11).split("-");
              console.log("Date nye : "+date);
              var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              for(var j=0;j<months.length;j++){
                  if(date[1]==months[j]){
                      date[1]=months.indexOf(months[j])+1;
                  }
              }
              if(date[1]<10){
                  date[1]='0'+date[1];
              }

              console.log("Date2 nye : "+date);
              var localETD = date[2]+"-"+date[1]+"-"+date[0];

              let dtt: string[] = String(localETD).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeShippedOnBoard.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );

              //this.model.blExtraShippedOnBoard = dm[0].ETD;
              //this.tmpShippedOnBoard = dm[0].ETD;

            }else{
              hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blNextVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blNextVesselVoyage).trim()
              ) +
              "/" +
              this.model.blNextVesselBound +
              "/" +
              //this.model.blOfficeCode +
              blOfficeNextVessel +
              "/DESC/custom";

              this.genericService.GET(hitUrl).subscribe((resp) => {
                if (resp.status === 200) {
                  const dt = resp.json()["content"];
                  if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                    if (this.model.blExtraShippedOnBoardLock !== false) {

                      let dtt: string[] = String(dt[0].localETD).split(" ");
                      let tmpDate: string[] = String(dtt[0]).split("-");

                      this.dateBlNettChargeShippedOnBoard.setValue(
                        tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                      );

                       this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                      //this.tmpShippedOnBoard = dt[0].localETD;
                    }
                  }else{
                    if (this.model.blExtraShippedOnBoardLock !== false) {
                      this.dateBlNettChargeShippedOnBoard.setValue("");
                      this.model.blExtraShippedOnBoard = "";
                    }
                  }
                }
              });

            }
          }else{
            if (this.model.blExtraShippedOnBoardLock !== false) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }

          }
        }else{
          if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }

        }
        }
    });
    hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
      this.model.blNextVesselId +
      "/" +
      this.sCharUtil.htmlEncode(
        String(this.model.blNextVesselVoyage).trim()
      ) +
      "/" +
      this.model.blNextVesselBound +
      "/" +
      //this.model.blOfficeCode +
      blOfficeNextVessel +
      "/DESC/custom";
    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status === 200) {
        const dt = resp.json()["content"];
        if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
          if (this.model.blDateOfIssueLock !== false) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssue.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blDateOfIssue = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blDateOfIssueLockConsole !== false) {
            let dtt: string[] = String(dt[0].blDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeDateOfIssueConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blDateOfIssueConsole = dt[0].blDate;
            this.tmpDateOfIssue = dt[0].blDate;
          }

          if (this.model.blIsExchangeRateLock !== false) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRate.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blExchangeRateDate = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          if (this.model.blIsExchangeRateLockConsole !== false) {
            let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeExchangeRateConsole.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );
            this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
            this.tmpExchangeRateDate = dt[0].exchangeRateDate;
          }

          /*if (this.model.blExtraShippedOnBoardLock !== false) {
            let dtt: string[] = String(dt[0].localETD).split(" ");
            let tmpDate: string[] = String(dtt[0]).split("-");

            this.dateBlNettChargeShippedOnBoard.setValue(
              tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
            );

             this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
            this.tmpShippedOnBoard = dt[0].blDate;
          }*/
        } else {
          if (this.model.blDateOfIssueLock !== false) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== false) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== false) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== false) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          /*if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }*/
        }
      }
    });

  }

  setBlIssueDateExchangeRateDate() {
    console.log("** setBlIssueDateExchangeRateDate **")
    let blOfficeNextVessel: string;
    // get BL Date and Exchange Rate date
    if(this.model.blPortOfTransshipment1Code !== '' &&
      this.model.blPortOfTransshipment1Code !== null &&
      this.model.blPortOfTransshipment1Code !== undefined
    ){
      blOfficeNextVessel =
        this.sCharUtil.htmlEncode(
          String(this.model.blPortOfTransshipment1Code).trim()
        );
    }else{
      blOfficeNextVessel = this.officeCode;
    }

    console.log("blNextVesselPrintedAsOceanVessel = " + this.model.blNextVesselPrintedAsOceanVessel)
    console.log("blPreVesselIsClausePrintedOnBl = " + this.model.blPreVesselIsClausePrintedOnBl)

    if (this.model.blNextVesselPrintedAsOceanVessel === true && this.model.blPreVesselIsClausePrintedOnBl === true) {
      console.log("condition #1");
      //this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;

      if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeDateOfIssue.setValue("");
        this.model.blDateOfIssue = "";
      }

      if (this.model.blDateOfIssueLockConsole !== true) {
        this.dateBlNettChargeDateOfIssueConsole.setValue("");
        this.model.blDateOfIssueConsole = "";
      }

      //this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
      if (this.model.blIsExchangeRateLock !== true) {
        this.dateBlNettChargeExchangeRate.setValue("");
        this.model.blExchangeRateDate = "";
      }

      if (this.model.blIsExchangeRateLockConsole !== true) {
        this.dateBlNettChargeExchangeRateConsole.setValue("");
        this.model.blExchangeRateDateConsole = "";
      }

      if (this.model.blExtraShippedOnBoardLock !== true) {
        this.dateBlNettChargeShippedOnBoard.setValue("");
        this.model.blExtraShippedOnBoard = "";
      }

    } else if (this.model.blNextVesselPrintedAsOceanVessel === false && this.model.blPreVesselIsClausePrintedOnBl === true) {
      console.log("condition #2");

      if( this.model.blPreVesselSkipCheck){
        if (this.model.blExtraShippedOnBoardLock !== true) {
          this.dateBlNettChargeShippedOnBoard.setValue("");
          this.model.blExtraShippedOnBoard = "";
        }

      }else{
        if (
          this.model.blPreVesselBound != null &&
          this.model.blPreVesselBound !== "" &&
          this.model.blPreVesselBound !== undefined
        ) {
          if(this.model.blType == "RFS" && !this.isOfficeInIndia){
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
            this.lockSOB = true;
          }else{
            this.lockSOB = false;
            this.findSOBPreVessel();
          }



        } else {
          if (this.model.blDateOfIssueLock !== true) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== true) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== true) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        }

      }


    } else if ( this.model.blNextVesselPrintedAsOceanVessel === true && this.model.blPreVesselIsClausePrintedOnBl === false) {
      console.log("condition #3");

      console.log("pertama dia kesini ga 1");
      console.log("blNextVesselSkipCheck = " + this.model.blNextVesselSkipCheck)
      if( this.model.blNextVesselSkipCheck){
        this.skipNextVessel();
      }else{

        let hitUrl = "";
        if (
            (this.model.blNextVesselBound != null &&
            this.model.blNextVesselBound !== "" &&
            this.model.blNextVesselBound !== undefined)
            &&
            (this.model.blPortOfTransshipment1Code != null &&
            this.model.blPortOfTransshipment1Code !== "" &&
            this.model.blPortOfTransshipment1Code !== undefined)
        ) {

          console.log("pertama dia kesini ga 2");
          if (this.model.blType == "RFS" && !this.isOfficeInIndia){
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
            this.lockSOB = true;
          }else{
            this.lockSOB = false;
            this.findSOBNextVessel(blOfficeNextVessel);
          }


        } else {
          if (this.model.blDateOfIssueLock !== true) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== true) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== true) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        }
      }

    } else {
      let hitUrl = "";
      if (
        this.model.blOceanVesselBound != null &&
        this.model.blOceanVesselBound !== "" &&
        this.model.blOceanVesselBound !== undefined
      ) {
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound +
          "/" +
          this.model.blOfficeCode +
          //blOfficeNextVessel +
          "/DESC/custom";
      }
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.status === 200) {
          const dt = resp.json()["content"];
          if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
            if (this.model.blDateOfIssueLock !== true) {
              let dtt: string[] = String(dt[0].blDate).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeDateOfIssue.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );
              this.model.blDateOfIssue = dt[0].blDate;
              this.tmpDateOfIssue = dt[0].blDate;
            }

            if (this.model.blDateOfIssueLockConsole !== true) {
              let dtt: string[] = String(dt[0].blDate).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeDateOfIssueConsole.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );
              this.model.blDateOfIssueConsole = dt[0].blDate;
              this.tmpDateOfIssue = dt[0].blDate;
            }

            if (this.model.blIsExchangeRateLock !== true) {
              let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeExchangeRate.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );
              this.model.blExchangeRateDate = dt[0].exchangeRateDate;
              this.tmpExchangeRateDate = dt[0].exchangeRateDate;
            }

            if (this.model.blIsExchangeRateLockConsole !== true) {
              let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
              let tmpDate: string[] = String(dtt[0]).split("-");

              this.dateBlNettChargeExchangeRateConsole.setValue(
                tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
              );
              this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
              this.tmpExchangeRateDate = dt[0].exchangeRateDate;
            }

            if (this.model.blType == "RFS" && !this.isOfficeInIndia) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
              this.lockSOB = true;
            } else {
              this.lockSOB = false;

              if (this.model.blExtraShippedOnBoardLock !== true) {
                let dtt: string[] = String(dt[0].localETD).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeShippedOnBoard.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );

                this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                this.tmpShippedOnBoard = dt[0].blDate;
              }
            }
          } else {
            if (this.model.blDateOfIssueLock !== true) {
              this.dateBlNettChargeDateOfIssue.setValue("");
              this.model.blDateOfIssue = "";
            }

            if (this.model.blDateOfIssueLockConsole !== true) {
              this.dateBlNettChargeDateOfIssueConsole.setValue("");
              this.model.blDateOfIssueConsole = "";
            }

            if (this.model.blIsExchangeRateLock !== true) {
              this.dateBlNettChargeExchangeRate.setValue("");
              this.model.blExchangeRateDate = "";
            }

            if (this.model.blIsExchangeRateLockConsole !== true) {
              this.dateBlNettChargeExchangeRateConsole.setValue("");
              this.model.blExchangeRateDateConsole = "";
            }

            if (this.model.blExtraShippedOnBoardLock !== true) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }
          }
        }
      });
    }
  }

  rfsBL(){
    if (this.model.blType == "RFS" && !this.isOfficeInIndia){
      this.dateBlNettChargeShippedOnBoard.setValue("");
      this.model.blExtraShippedOnBoard = "";
      this.lockSOB = true;
    }else{
      this.lockSOB = false;
    }
  }

  skipNextVessel(){
    console.log("** skipNextVessel **")

    if(this.model.blExtraShippedOnBoard == ""){
      if (this.model.blExtraShippedOnBoardLock !== true) {
        this.dateBlNettChargeShippedOnBoard.setValue("");
        this.model.blExtraShippedOnBoard = "";
      }
    }else{
      if (this.model.blExtraShippedOnBoardLock !== true) {
        this.dateBlNettChargeShippedOnBoard.setValue("");
        this.model.blExtraShippedOnBoard = "";
        //this.dateBlNettChargeShippedOnBoard.setValue(this.model.blExtraShippedOnBoard);
      }else{

      }

    }

    console.log(this.model.blDateOfIssue)
    console.log(this.model.blDateOfIssueLock)

    if(this.model.blDateOfIssue == ""){
      console.log("#doi1")
      if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeDateOfIssue.setValue("");
        this.model.blDateOfIssue = "";
      }
    }else{
      console.log("#doi2")
      if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeDateOfIssue.setValue(this.model.blDateOfIssue);
      }
    }

    if(this.model.blExchangeRateDate == ""){
      if (this.model.blIsExchangeRateLock !== true) {
        this.dateBlNettChargeExchangeRate.setValue("");
        this.model.blExchangeRateDate = "";
      }
    }else{
      if (this.model.blIsExchangeRateLock !== true) {
        this.dateBlNettChargeExchangeRate.setValue(this.model.blExchangeRateDate);
      }
    }

  }

//gos-263
  setBlIsShippedOnBoardDateRetrieved(){
    console.log("NAH INI DIA 2 : "+this.model.blType);

    let blOfficeNextVessel: string;
    // get BL Date and Exchange Rate date
    if(this.model.blPortOfTransshipment1Code !== '' &&
      this.model.blPortOfTransshipment1Code !== null &&
      this.model.blPortOfTransshipment1Code !== undefined
    ){
      blOfficeNextVessel =
        this.sCharUtil.htmlEncode(
          String(this.model.blPortOfTransshipment1Code).trim()
        );
    }else{
      blOfficeNextVessel = this.model.blOfficeCode;
    }

    console.log("NAH INI DIA 2 : "+ blOfficeNextVessel);
    console.log("NAH INI DIA 3 : "+ this.model.blOfficeCode);
    console.log("lanjutan ingin menangis 6.1" + this.model.blPreVesselIsClausePrintedOnBl);
    console.log("lanjutan ingin menangis 6.2" +  this.model.blPreVesselSkipCheck);
    console.log("lanjutan ingin menangis 6.3" +this.model.blNextVesselPrintedAsOceanVessel);
        // get BL Date and Exchange Rate date
        if (
          this.model.blNextVesselPrintedAsOceanVessel === true &&
          this.model.blPreVesselIsClausePrintedOnBl === true
        ) {

          console.log("###satu");
          //this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;

          if (this.model.blDateOfIssueLock !== false) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== false) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          //this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
          if (this.model.blIsExchangeRateLock !== false) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== false) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        } else if (
          this.model.blNextVesselPrintedAsOceanVessel === false &&
          this.model.blPreVesselIsClausePrintedOnBl === true
        ) {
          if( this.model.blPreVesselSkipCheck){
            if (this.model.blExtraShippedOnBoardLock !== false) {
              if(this.model.blExtraShippedOnBoard == ""){
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }else{
                this.dateBlNettChargeShippedOnBoard.setValue(this.model.blExtraShippedOnBoard);
              }
            }
          }else{
            console.log("###dua");
            let hitUrl = "";
            if (
              this.model.blPreVesselBound != null &&
              this.model.blPreVesselBound !== "" &&
              this.model.blPreVesselBound !== undefined
            ) {
              if (this.model.blType == "RFS" && !this.isOfficeInIndia){
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
                this.lockSOB = true;
              }else{
                this.lockSOB = false;
                this.findSOBPreVesselRetrieved();
              }

            } else {
              if (this.model.blDateOfIssueLock !== false) {
                this.dateBlNettChargeDateOfIssue.setValue("");
                this.model.blDateOfIssue = "";
              }

              if (this.model.blDateOfIssueLockConsole !== false) {
                this.dateBlNettChargeDateOfIssueConsole.setValue("");
                this.model.blDateOfIssueConsole = "";
              }

              if (this.model.blIsExchangeRateLock !== false) {
                this.dateBlNettChargeExchangeRate.setValue("");
                this.model.blExchangeRateDate = "";
              }

              if (this.model.blIsExchangeRateLockConsole !== false) {
                this.dateBlNettChargeExchangeRateConsole.setValue("");
                this.model.blExchangeRateDateConsole = "";
              }

              if (this.model.blExtraShippedOnBoardLock !== false) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }
            }
          }


        } else if (
          this.model.blNextVesselPrintedAsOceanVessel === true &&
          this.model.blPreVesselIsClausePrintedOnBl === false
        ) {

          if( this.model.blNextVesselSkipCheck){
            this.skipNextVessel();
          }else{
            console.log("###tiga");
            let hitUrl = "";
            if (
                (this.model.blNextVesselBound != null &&
                this.model.blNextVesselBound !== "" &&
                this.model.blNextVesselBound !== undefined)
                &&
                (this.model.blPortOfTransshipment1Code != null &&
                this.model.blPortOfTransshipment1Code !== "" &&
                this.model.blPortOfTransshipment1Code !== undefined)
            ) {

              if (this.model.blType == "RFS" && !this.isOfficeInIndia){
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
                this.lockSOB = true;
              }else{
                this.lockSOB = false;
                this.findSOBNextVesselRetrieved(blOfficeNextVessel);
              }

            } else {
              if (this.model.blDateOfIssueLock !== false) {
                this.dateBlNettChargeDateOfIssue.setValue("");
                this.model.blDateOfIssue = "";
              }

              if (this.model.blDateOfIssueLockConsole !== false) {
                this.dateBlNettChargeDateOfIssueConsole.setValue("");
                this.model.blDateOfIssueConsole = "";
              }

              if (this.model.blIsExchangeRateLock !== false) {
                this.dateBlNettChargeExchangeRate.setValue("");
                this.model.blExchangeRateDate = "";
              }

              if (this.model.blIsExchangeRateLockConsole !== false) {
                this.dateBlNettChargeExchangeRateConsole.setValue("");
                this.model.blExchangeRateDateConsole = "";
              }

              if (this.model.blExtraShippedOnBoardLock !== false) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }
            }
          }
        } else {
          console.log("###empat");
          let hitUrl = "";
          if (
            this.model.blOceanVesselBound != null &&
            this.model.blOceanVesselBound !== "" &&
            this.model.blOceanVesselBound !== undefined
          ) {
            hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blOceanVesselVoyage).trim()
              ) +
              "/" +
              this.model.blOceanVesselBound +
              "/" +
              this.model.blOfficeCode +
              //blOfficeNextVessel +
              "/DESC/custom";
          }
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if (resp.status === 200) {
              const dt = resp.json()["content"];
              if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                console.log("Retrieve date of issue problem when lock here : "+this.model.blDateOfIssueLock);
                if (this.model.blDateOfIssueLock !== false) {
                  let dtt: string[] = String(dt[0].blDate).split(" ");
                  let tmpDate: string[] = String(dtt[0]).split("-");

                  this.dateBlNettChargeDateOfIssue.setValue(
                    tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                  );
                  this.model.blDateOfIssue = dt[0].blDate;
                  this.tmpDateOfIssue = dt[0].blDate;
                }

                if (this.model.blDateOfIssueLockConsole !== false) {
                  let dtt: string[] = String(dt[0].blDate).split(" ");
                  let tmpDate: string[] = String(dtt[0]).split("-");

                  this.dateBlNettChargeDateOfIssueConsole.setValue(
                    tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                  );
                  this.model.blDateOfIssueConsole = dt[0].blDate;
                  this.tmpDateOfIssue = dt[0].blDate;
                }

                if (this.model.blIsExchangeRateLock !== false) {
                  let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
                  let tmpDate: string[] = String(dtt[0]).split("-");

                  this.dateBlNettChargeExchangeRate.setValue(
                    tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                  );
                  this.model.blExchangeRateDate = dt[0].exchangeRateDate;
                  this.tmpExchangeRateDate = dt[0].exchangeRateDate;
                }

                if (this.model.blIsExchangeRateLockConsole !== false) {
                  let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
                  let tmpDate: string[] = String(dtt[0]).split("-");

                  this.dateBlNettChargeExchangeRateConsole.setValue(
                    tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                  );
                  this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
                  this.tmpExchangeRateDate = dt[0].exchangeRateDate;
                }

                if (this.model.blType == "RFS" && !this.isOfficeInIndia) {
                  this.dateBlNettChargeShippedOnBoard.setValue("");
                  this.model.blExtraShippedOnBoard = "";
                  this.lockSOB = true;
                } else {
                  this.lockSOB = false;
                  if (this.model.blExtraShippedOnBoardLock !== false) {
                    let dtt: string[] = String(dt[0].localETD).split(" ");
                    let tmpDate: string[] = String(dtt[0]).split("-");

                    this.dateBlNettChargeShippedOnBoard.setValue(
                      tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                    );

                    this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                    //this.tmpShippedOnBoard = dt[0].blDate;
                  }
                }

              } else {
                if (this.model.blDateOfIssueLock !== false) {
                  this.dateBlNettChargeDateOfIssue.setValue("");
                  this.model.blDateOfIssue = "";
                }

                if (this.model.blDateOfIssueLockConsole !== false) {
                  this.dateBlNettChargeDateOfIssueConsole.setValue("");
                  this.model.blDateOfIssueConsole = "";
                }

                if (this.model.blIsExchangeRateLock !== false) {
                  this.dateBlNettChargeExchangeRate.setValue("");
                  this.model.blExchangeRateDate = "";
                }

                if (this.model.blIsExchangeRateLockConsole !== false) {
                  this.dateBlNettChargeExchangeRateConsole.setValue("");
                  this.model.blExchangeRateDateConsole = "";
                }

                if (this.model.blExtraShippedOnBoardLock !== false) {
                  this.dateBlNettChargeShippedOnBoard.setValue("");
                  this.model.blExtraShippedOnBoard = "";
                }
              }
            }
          });
        }
  }

  setBlIsShippedOnBoardDateOnly(){
    console.log("NAH INI DIA 1");
        let blOfficeNextVessel: string;
        // get BL Date and Exchange Rate date
        if(this.model.blPortOfTransshipment1Code !== '' &&
          this.model.blPortOfTransshipment1Code !== null &&
          this.model.blPortOfTransshipment1Code !== undefined
        ){
          blOfficeNextVessel =
            this.sCharUtil.htmlEncode(
              String(this.model.blPortOfTransshipment1Code).trim()
            );
        }else{
          blOfficeNextVessel = this.model.blOfficeCode;
        }

        if (
          this.model.blNextVesselPrintedAsOceanVessel === true &&
          this.model.blPreVesselIsClausePrintedOnBl === true
        ) {

          console.log("###satu");
          //this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;


          if (this.model.blExtraShippedOnBoardLock !== false) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        } else if (
          this.model.blNextVesselPrintedAsOceanVessel === false &&
          this.model.blPreVesselIsClausePrintedOnBl === true
        ) {

          console.log("###dua");
          let hitUrl = "";

          if( this.model.blPreVesselSkipCheck){
            if (this.model.blExtraShippedOnBoardLock !== false) {
              this.dateBlNettChargeShippedOnBoard.setValue("");
              this.model.blExtraShippedOnBoard = "";
            }

          }else{
            if (
              this.model.blPreVesselBound != null &&
              this.model.blPreVesselBound !== "" &&
              this.model.blPreVesselBound !== undefined
            ) {

              if (this.model.blType == "RFS" && !this.isOfficeInIndia){
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
                this.lockSOB = true;
              }else{
                this.lockSOB = false;
                this.findSOBPreVesselRetrieved();
              }


            } else {

              if (this.model.blExtraShippedOnBoardLock !== false) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }
            }
          }
        } else if (
          this.model.blNextVesselPrintedAsOceanVessel === true &&
          this.model.blPreVesselIsClausePrintedOnBl === false
        ) {
          console.log("###tiga");
          let hitUrl = "";
          if( this.model.blNextVesselSkipCheck){
            this.skipNextVessel();
          }else{
            if (
              (this.model.blNextVesselBound != null &&
              this.model.blNextVesselBound !== "" &&
              this.model.blNextVesselBound !== undefined)
              &&
              (this.model.blPortOfTransshipment1Code != null &&
              this.model.blPortOfTransshipment1Code !== "" &&
              this.model.blPortOfTransshipment1Code !== undefined)
            ) {
              if (this.model.blType == "RFS" && !this.isOfficeInIndia){
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
                this.lockSOB = true;
              }else{
                this.lockSOB = false;
                this.findSOBNextVesselRetrieved(blOfficeNextVessel);
              }


            } else {


              if (this.model.blExtraShippedOnBoardLock !== false) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }
            }
          }
        } else {
          console.log("###empat");
          let hitUrl = "";
          if (
            this.model.blOceanVesselBound != null &&
            this.model.blOceanVesselBound !== "" &&
            this.model.blOceanVesselBound !== undefined
          ) {
            hitUrl =
              this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
              this.model.blOceanVesselId +
              "/" +
              this.sCharUtil.htmlEncode(
                String(this.model.blOceanVesselVoyage).trim()
              ) +
              "/" +
              this.model.blOceanVesselBound +
              "/" +
              this.model.blOfficeCode +
              //blOfficeNextVessel +
              "/DESC/custom";
          }
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if (resp.status === 200) {
              const dt = resp.json()["content"];
              console.log('shanghai 1')
              if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
                console.log('shanghai 2');
                if (this.model.blType == "RFS" && !this.isOfficeInIndia) {
                  this.dateBlNettChargeShippedOnBoard.setValue("");
                  this.model.blExtraShippedOnBoard = "";
                  this.lockSOB = true;
                } else {
                  console.log('shanghai 3');
                  this.lockSOB = false;
                  if (this.model.blExtraShippedOnBoardLock !== false) {
                    console.log('shanghai 4');
                    let dtt: string[] = String(dt[0].localETD).split(" ");
                    let tmpDate: string[] = String(dtt[0]).split("-");

                    this.dateBlNettChargeShippedOnBoard.setValue(
                      this.model.blExtraShippedOnBoard
                    );

                    this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                    //this.tmpShippedOnBoard = dt[0].blDate;
                  } else {

                    let dtt: string[] = String(dt[0].localETD).split(" ");
                    let tmpDate: string[] = String(dtt[0]).split("-");

                    this.dateBlNettChargeShippedOnBoard.setValue(
                      tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                    );

                    this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                    //this.tmpShippedOnBoard = dt[0].blDate;

                  }
                }
              } else {

                if (this.model.blExtraShippedOnBoardLock !== false) {
                  this.dateBlNettChargeShippedOnBoard.setValue("");
                  this.model.blExtraShippedOnBoard = "";
                }
              }
            }
          });
        }
  }

  setBlIsShippedOnBoardDate() {
    let blOfficeNextVessel: string;
    // get BL Date and Exchange Rate date
    if(this.model.blPortOfTransshipment1Code !== '' &&
      this.model.blPortOfTransshipment1Code !== null &&
      this.model.blPortOfTransshipment1Code !== undefined
    ){
      blOfficeNextVessel =
        this.sCharUtil.htmlEncode(
          String(this.model.blPortOfTransshipment1Code).trim()
        );
    }else{
      blOfficeNextVessel = this.model.blOfficeCode;
    }

    if (
      this.model.blNextVesselPrintedAsOceanVessel === true &&
      this.model.blPreVesselIsClausePrintedOnBl === true
    ) {
      //this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;

      if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeDateOfIssue.setValue("");
        this.model.blDateOfIssue = "";
      }

      if (this.model.blDateOfIssueLockConsole !== true) {
        this.dateBlNettChargeDateOfIssueConsole.setValue("");
        this.model.blDateOfIssueConsole = "";
      }

      //this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
      if (this.model.blIsExchangeRateLock !== true) {
        this.dateBlNettChargeExchangeRate.setValue("");
        this.model.blExchangeRateDate = "";
      }

      if (this.model.blIsExchangeRateLockConsole !== true) {
        this.dateBlNettChargeExchangeRateConsole.setValue("");
        this.model.blExchangeRateDateConsole = "";
      }

      if (this.model.blExtraShippedOnBoardLock !== true) {
        this.dateBlNettChargeShippedOnBoard.setValue("");
        this.model.blExtraShippedOnBoard = "";
      }

    } else if (
      this.model.blNextVesselPrintedAsOceanVessel === false &&
      this.model.blPreVesselIsClausePrintedOnBl === true
    ) {
      let hitUrl = "";

      if( this.model.blPreVesselSkipCheck){
        if (this.model.blExtraShippedOnBoardLock !== true) {
          this.dateBlNettChargeShippedOnBoard.setValue("");
          this.model.blExtraShippedOnBoard = "";
        }

      }else{

        if (
          this.model.blPreVesselBound != null &&
          this.model.blPreVesselBound !== "" &&
          this.model.blPreVesselBound !== undefined
        ) {

          if (this.model.blType == "RFS" && !this.isOfficeInIndia){
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
            this.lockSOB = true;
          }else{
            this.lockSOB = false;
            this.findSOBPreVessel();
          }

        } else {
          if (this.model.blDateOfIssueLock !== true) {
            this.dateBlNettChargeDateOfIssue.setValue("");
            this.model.blDateOfIssue = "";
          }

          if (this.model.blDateOfIssueLockConsole !== true) {
            this.dateBlNettChargeDateOfIssueConsole.setValue("");
            this.model.blDateOfIssueConsole = "";
          }

          if (this.model.blIsExchangeRateLock !== true) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        }
      }
    } else if (
      this.model.blNextVesselPrintedAsOceanVessel === true &&
      this.model.blPreVesselIsClausePrintedOnBl === false
    ) {
      let hitUrl = "";

      if( this.model.blNextVesselSkipCheck){
        this.skipNextVessel();
      }else{
        if (
            (this.model.blNextVesselBound != null &&
            this.model.blNextVesselBound !== "" &&
            this.model.blNextVesselBound !== undefined)
            &&
            (this.model.blPortOfTransshipment1Code != null &&
            this.model.blPortOfTransshipment1Code !== "" &&
            this.model.blPortOfTransshipment1Code !== undefined)
        ) {
          if (this.model.blType == "RFS" && !this.isOfficeInIndia){
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
            this.lockSOB = true;
          }else{
            this.lockSOB = false;
            this.findSOBNextVessel(blOfficeNextVessel);
          }


          if (this.model.blIsExchangeRateLock !== true) {
            this.dateBlNettChargeExchangeRate.setValue("");
            this.model.blExchangeRateDate = "";
          }

          if (this.model.blIsExchangeRateLockConsole !== true) {
            this.dateBlNettChargeExchangeRateConsole.setValue("");
            this.model.blExchangeRateDateConsole = "";
          }

          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
        }
      }
    } else {
      let hitUrl = "";
      if (
        this.model.blOceanVesselBound != null &&
        this.model.blOceanVesselBound !== "" &&
        this.model.blOceanVesselBound !== undefined
      ) {
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
          this.model.blOceanVesselId +
          "/" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "/" +
          this.model.blOceanVesselBound +
          "/" +
          this.model.blOfficeCode +
          //blOfficeNextVessel +
          "/DESC/custom";

        this.genericService.GET(hitUrl).subscribe((resp) => {
          if (resp.status === 200) {
            const dt = resp.json()["content"];
            if (dt[0] != null && dt[0] !== "" && dt[0] !== undefined) {
              if (this.model.blDateOfIssueLock !== true) {
                let dtt: string[] = String(dt[0].blDate).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeDateOfIssue.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );
                this.model.blDateOfIssue = dt[0].blDate;
                this.tmpDateOfIssue = dt[0].blDate;
              }

              if (this.model.blDateOfIssueLockConsole !== true) {
                let dtt: string[] = String(dt[0].blDate).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeDateOfIssueConsole.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );
                this.model.blDateOfIssueConsole = dt[0].blDate;
                this.tmpDateOfIssue = dt[0].blDate;
              }

              if (this.model.blIsExchangeRateLock !== true) {
                let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeExchangeRate.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );
                this.model.blExchangeRateDate = dt[0].exchangeRateDate;
                this.tmpExchangeRateDate = dt[0].exchangeRateDate;
              }

              if (this.model.blIsExchangeRateLockConsole !== true) {
                let dtt: string[] = String(dt[0].exchangeRateDate).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeExchangeRateConsole.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );
                this.model.blExchangeRateDateConsole = dt[0].exchangeRateDate;
                this.tmpExchangeRateDate = dt[0].exchangeRateDate;
              }
              if (this.model.blType == "RFS" && !this.isOfficeInIndia) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
                this.lockSOB = true;
              } else {
                this.lockSOB = false;
              }

              if (this.model.blExtraShippedOnBoardLock !== true) {
                let dtt: string[] = String(dt[0].localETD).split(" ");
                let tmpDate: string[] = String(dtt[0]).split("-");

                this.dateBlNettChargeShippedOnBoard.setValue(
                  tmpDate[0] + "-" + tmpDate[1] + "-" + tmpDate[2]
                );

                this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
                //this.tmpShippedOnBoard = dt[0].blDate;
              }
            } else {
              if (this.model.blDateOfIssueLock !== true) {
                this.dateBlNettChargeDateOfIssue.setValue("");
                this.model.blDateOfIssue = "";
              }

              if (this.model.blDateOfIssueLockConsole !== true) {
                this.dateBlNettChargeDateOfIssueConsole.setValue("");
                this.model.blDateOfIssueConsole = "";
              }

              if (this.model.blIsExchangeRateLock !== true) {
                this.dateBlNettChargeExchangeRate.setValue("");
                this.model.blExchangeRateDate = "";
              }

              if (this.model.blIsExchangeRateLockConsole !== true) {
                this.dateBlNettChargeExchangeRateConsole.setValue("");
                this.model.blExchangeRateDateConsole = "";
              }

              if (this.model.blExtraShippedOnBoardLock !== true) {
                this.dateBlNettChargeShippedOnBoard.setValue("");
                this.model.blExtraShippedOnBoard = "";
              }
            }
          }
        });
      }

    }
  }


  changeEventChBlPlacePrintPreVesselClauseOnBL(event) {
    console.log("kecini one : "+this.model.blNextVesselPrintedAsOceanVessel);
    if (event.target.checked) {
      if(this.model.blNextVesselPrintedAsOceanVessel){
        this.frmDialogMessage = "Only 1 selection of “Print as Ocean Vessel” is allowed.";
            this.genericUtil.showDialog(
              "clickPreVesselAsOceanVessel",
              "Confirmation",
              350,
              150
            );
            this.genericUtil.hideLoader();
      }else{
        this.model.blPreVesselIsClausePrintedOnBl = true;
        this.setBlIssueDateExchangeRateDate();
      }
    } else {
      this.model.blPreVesselIsClausePrintedOnBl = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  onBlDialogClickPreVesselAsOceanVesselOk(){
    this.model.blPreVesselIsClausePrintedOnBl = false;
    this.genericUtil.closeDialog("clickPreVesselAsOceanVessel");
  }
  onBlDialogClickNextVesselAsOceanVesselOk(){
    this.model.blNextVesselPrintedAsOceanVessel = false;
    this.genericUtil.closeDialog("clickNextVesselAsOceanVessel");
  }

  changeEventChBlPlacePrintOceanVesselOnBL(event) {
    if (event.target.checked) {
      this.model.blOceanVesselIsClausePrintedOnBl = true;
    } else {
      this.model.blOceanVesselIsClausePrintedOnBl = false;
    }
  }

  changeEventChBlPlaceNextVesselSkipCheck(event) {
    if (event.target.checked) {
      this.model.blNextVesselSkipCheck = true;
      if (
        this.model.blNextVesselPrintedAsOceanVessel === true &&
        this.model.blPreVesselIsClausePrintedOnBl === false
      ) {
          this.skipNextVessel();
       }
    } else {
      this.model.blNextVesselSkipCheck = false;
      this.checkNextVesselVoyage();
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventChBlPlacePreVesselSkipCheck(event) {
    if (event.target.checked) {
      this.model.blPreVesselSkipCheck = true;
      //gos-263
      if (
        this.model.blNextVesselPrintedAsOceanVessel === false &&
        this.model.blPreVesselIsClausePrintedOnBl === true
      ) {
          if (this.model.blExtraShippedOnBoardLock !== true) {
            this.dateBlNettChargeShippedOnBoard.setValue("");
            this.model.blExtraShippedOnBoard = "";
          }
       }

    } else {
      this.model.blPreVesselSkipCheck = false;
      this.checkPreVesselVoyage();
    }
  }
  changeEventChBlPlacePrint(event) {
    /*
    if (event.target.checked) {
      this.model.blPreVesselIsPrinted = true;
    } else {;
      this.model.blPreVesselIsPrinted = false;
    }
    */
    console.log(event);
    if (event.target.checked) {
      this.model.blCommodityId = "Y";
      this.isPreVesselPrinted = true;
    } else {
      this.model.blCommodityId = "N";
      this.isPreVesselPrinted = false;
    }
  }

  changeEventCbBlPlaceService(event) {
    if (
      event.serviceId != null &&
      event.serviceId !== "" &&
      event.serviceId !== undefined
    ) {
      this.model.blServiceId = event.serviceId;
      this.blPlaceServiceName = event.serviceName;
    } else {
      this.model.blServiceId = "";
      this.blPlaceServiceName = "";
    }
    this.cbBlPlaceService.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterServices/findByComboBoxControlService/serviceName={query}"
    );
  }

  changeEventCbBlPlacePreVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.model["error-blPreVesselName"] = "";
      this.model["error-blPreVesselCode"] = "";
      this.model.blPreVesselId = event.vesselId;
      this.model.blPreVesselCode = event.vesselCode;
      this.model.blPreVesselName = event.vesselName;

      // this.cbBlPlacePreVoyage.setUrl(this.configService.config.BASE_API.toString() +
      // '/MasterSailingSchedules/distinctVoyage/' + this.model.blPreVesselId + '/' +
      // this.model.blPreVesselBound.toUpperCase() + '/{query}/' + this.cookieService.getDefaultLocationCode());
    } else {
      this.model["error-blPreVesselName"] = "";
      this.model["error-blPreVesselCode"] = "";
      this.model.blPreVesselId = "";
      this.model.blPreVesselCode = "";
      this.model.blPreVesselName = "";

      // reset the voyage and bound
      this.model.blPreVesselVoyage = "";
      this.model.blPreVesselBound = "";
      // this.cbBlPlacePreVoyage.setUrl('');
      // this.cbBlPlacePreVoyage.setValue('');
    }
    this.cbBlPlacePreVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  /**
   changeEventCbBlPlacePreVoyage(event) {
    if (event.voyage == null || event.voyage=='' || event.voyage==undefined) {
      this.model.blPreVesselVoyage=event.voyage;
      this.model.blPreVesselBound=event.bound;
      this.cbBlPlacePreVoyage.setUrl(this.configService.config.BASE_API.toString() +
      '/MasterSailingSchedules/distinctVoyage/' + this.model.blPreVesselId + '/' +
      this.model.blPreVesselBound.toUpperCase() + '/{query}/' + this.cookieService.getDefaultLocationCode());
    } else {
      this.model.blPreVesselVoyage='';
    }
  }
   */

  changeBlPreVesselBound() {
    // this.cbBlPlacePreVoyage.setUrl(this.configService.config.BASE_API.toString() +
    // '/MasterSailingSchedules/distinctVoyage/' + this.model.blPreVesselId + '/' +
    // this.model.blPreVesselBound.toUpperCase() + '/{query}/' + this.cookieService.getDefaultLocationCode());
  }

  checkPreVesselVoyage() {
    if (this.model.blPreVesselSkipCheck === false) {
      if (
        this.model.blPreVesselId !== "" &&
        this.model.blPreVesselVoyage !== "" &&
        this.model.blPreVesselBound !== ""
      ) {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/checkSailingSchedule/" +
              this.model.blPreVesselId +
              "/" +
              this.model.blPreVesselVoyage +
              "/" +
              this.model.blPreVesselBound +
              "/" +
              this.cookieService.getDefaultLocationCode()
          )
          .subscribe((resp) => {
            const dt = resp.json();
            if (dt.status === "NOK") {
              this.frmDialogMessage =
                "This Vessel / Voyage [" +
                this.model.blPreVesselName.toUpperCase() +
                " " +
                this.model.blPreVesselVoyage.toUpperCase() +
                " " +
                this.model.blPreVesselBound.toUpperCase() +
                "] is invalid.<br>Selection is unavailable.";
              this.genericUtil.showDialog(
                "blPreVesselValidationDialog",
                "Information",
                400,
                150
              );
            } else {
              this.setBlIssueDateExchangeRateDate();
            }
          });
      }
    }
  }

  checkNextVesselVoyage() {
    if (this.model.blNextVesselSkipCheck === false) {
      if (
        this.model.blNextVesselId !== "" &&
        this.model.blNextVesselVoyage !== "" &&
        this.model.blNextVesselBound !== ""
      ) {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/checkSailingSchedule/" +
              this.model.blNextVesselId +
              "/" +
              this.model.blNextVesselVoyage +
              "/" +
              this.model.blNextVesselBound +
              "/" +
              (this.model.blType === "SW" || this.model.blType === "OIS"
                ? this.cookieService.getDefaultLocationCode()
                : this.model.blPortOfTransshipment1Code)
          )
          .subscribe((resp) => {
            const dt = resp.json();
            if (dt.status === "NOK") {
              this.frmDialogMessage =
                "This Vessel / Voyage [" +
                this.model.blNextVesselName.toUpperCase() +
                " " +
                this.model.blNextVesselVoyage.toUpperCase() +
                " " +
                this.model.blNextVesselBound.toUpperCase() +
                "] is invalid.<br>Selection is unavailable.";
              this.genericUtil.showDialog(
                "blNextVesselValidationDialog",
                "Information",
                400,
                150
              );
            } else {
              this.setBlIssueDateExchangeRateDate();
            }
          });
      }
    }
  }

  changeEventCbBlPlaceNextVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.model["error-blNextVesselName"] = "";
      this.model.blNextVesselId = event.vesselId;
      this.model.blNextVesselCode = event.vesselCode;
      this.model.blNextVesselName = event.vesselName;
      // this.cbBlPlaceNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
      // this.model.blNextVesselId + '/' + this.model.blNextVesselBound.toUpperCase() + '/{query}/'
      // + this.cookieService.getDefaultLocationCode());
    } else {
      this.model["error-blNextVesselName"] = "";
      this.model.blNextVesselId = "";
      this.model.blNextVesselCode = "";
      this.model.blNextVesselName = "";

      // reset the next vessel
      this.model.blNextVesselVoyage = "";
      this.model.blNextVesselBound = "";
      // this.cbBlPlaceNextVoyage.setUrl('');
      // this.cbBlPlaceNextVoyage.setValue('');
    }
    this.cbBlPlaceNextVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  /**
   changeEventCbBlPlaceNextVoyage(event) {
    if (event.voyage == null || event.voyage=='' || event.voyage==undefined) {
      this.model.blNextVesselVoyage=event.voyage;
      this.model.blNextVesselBound=event.bound;
      this.cbBlPlaceNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
      this.model.blNextVesselId + '/' + this.model.blNextVesselBound.toUpperCase() +
      '/{query}/' + this.cookieService.getDefaultLocationCode());
    } else {
      this.model.blNextVesselVoyage='';
    }
  }
   */

  changeBlNextVesselBound() {
    this.setBlIssueDateExchangeRateDate();
    // this.cbBlPlaceNextVoyage.setUrl(this.configService.config.BASE_API.toString() +
    // '/MasterSailingSchedules/distinctVoyage/' + this.model.blNextVesselId + '/' +
    // this.model.blNextVesselBound.toUpperCase() + '/{query}/' + this.cookieService.getDefaultLocationCode());
  }

  changeEventCbBlPlacePreVesselIsClausePrintedOnBl(event) {
    if (event.target.checked) {
      this.model.blPreVesselIsClausePrintedOnBl = true;
      this.setBlIssueDateExchangeRateDate();
    } else {
      this.model.blPreVesselIsClausePrintedOnBl = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventCbBlPlaceOceanVesselIsClausePrintedOnBl(event) {
    if (event.target.checked) {
      this.model.blOceanVesselIsClausePrintedOnBl = true;
    } else {
      this.model.blOceanVesselIsClausePrintedOnBl = false;
    }
  }


  changeEventChBlPlacePrintNextVesselAsOceanVessel(event) {
    console.log("kecini two : "+this.model.blPreVesselIsClausePrintedOnBl);
    if (event.target.checked) {
      if(this.model.blPreVesselIsClausePrintedOnBl){
        this.frmDialogMessage = "Only 1 selection of “Print as Ocean Vessel” is allowed.";
            this.genericUtil.showDialog(
              "clickNextVesselAsOceanVessel",
              "Confirmation",
              350,
              150
            );
            this.genericUtil.hideLoader();
      }else{
        this.model.blNextVesselPrintedAsOceanVessel = true;
        this.setBlIssueDateExchangeRateDate();
      }
    } else {
      this.model.blNextVesselPrintedAsOceanVessel = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  /********  03. TAB CONTAINER EVENT ********/
  gridEventBlContainer(event) {
    switch (event.split(".")[0]) {
      case "selected":
        /*
         this.btnBlContainerNew = false; this.btnBlContainerSave = true;
         this.btnBlContainerDelete = true; this.btnBlContainerCancel = true;
         this.frmContainerLock = false; // to release container form lock
         this.dgClassLock = true;
         */
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              // console.log(
              //   "container double click debug --- containerCancel : " +
              //     this.btnBlContainerCancel
              // );
              // console.log(this.gridBlContainer.getSelectedValues());
              // console.log(this.paramContainer);
              if (!this.btnBlContainerCancel) {
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print,sync";

                // if (this.blLock == "N") {
                //   this.btnBlContainerNew = false;
                //   this.btnBlContainerSave = true;
                //   this.btnBlContainerDelete = true;
                //   this.btnBlContainerCancel = true;
                //   this.btnBlContainerHSNew = true;
                //   this.frmContainerLock = false; // to release container form lock
                //   this.dgClassLock = true;
                //   this.blContainerUpdateMode = "update";
                //   this.txtBlContainerSaveUpdate = "Update";
                // }
                // else if(this.blLock == 'Y' || this.blLock == 'T1'|| this.blLock == 'T2'|| this.blLock == 'T3'|| this.blLock == 'T4'|| this.blLock == 'T5'|| this.blLock == 'T6'|| this.blLock == 'T7'|| this.blLock == 'T8') {
                //   this.btnBlContainerNew = false;
                //   this.btnBlContainerSave = false;
                //   this.btnBlContainerDelete = false;
                //   this.btnBlContainerCancel = true;
                //   this.btnBlContainerHSNew = false;
                //   this.frmContainerLock = false; // to release container form lock
                //   this.dgClassLock = true;
                //   this.lockTab3 = false;
                // }else{
                //   this.blContainerUpdateMode = 'update';
                //   this.txtBlContainerSaveUpdate = 'Update';

                // this.btnBlContainerNew = false;
                // this.btnBlContainerSave = true;
                // this.btnBlContainerDelete = true;
                // this.btnBlContainerCancel = true;
                // this.btnBlContainerHSNew = true;
                // this.frmContainerLock = false; // to release container form lock
                // this.dgClassLock = true;
                // }

                if (
                  this.blLock == "T7" ||
                  this.blLock == "T6" ||
                  this.blLock == "T4" ||
                  this.blLock == "T8" ||
                  this.blLock == "T2" ||
                  this.blLock == "T1" ||
                  this.blLock == "Y"
                ) {
                  this.btnBlContainerNew = false;
                  this.btnBlContainerSave = false;
                  this.btnBlContainerDelete = false;
                  this.btnBlContainerCancel = true;
                  this.btnBlContainerHSNew = false;
                  this.btnBlAdContainerNew = false;

                  this.frmContainerLock = false; // to release container form lock
                  this.dgClassLock = false;
                  this.lockTab3 = false;
                  //yg-sbhd

                  this.disableBlContainerQuantityLock = true;
                  this.disableBlContainerVGMLock =  true;
                  this.disableBlContainerPackageTypeLock = true;
                  this.disableBlContainerGrossWeightLock = true;
                  this.disableBlContainerGrossMeasureLock = true;
                  this.disableBlContainerDescPrint = true;
                  this.disableBlContainerDescLock = true;
                  this.disableBlContainerAdPrint = true;
                  this.disableBlContainerNettWeightLock = true;
                  this.disableBlContainerNettMeasureLock = true;
                } else {
                  this.blContainerUpdateMode = "update";
                  this.txtBlContainerSaveUpdate = "Update";
                  this.btnBlContainerNew = false;
                  this.btnBlContainerSave = true;
                  this.btnBlContainerDelete = true;
                  this.btnBlContainerCancel = true;
                  this.btnBlContainerHSNew = true;
                  this.btnBlAdContainerNew = true;
                  this.frmContainerLock = false; // to release container form lock
                  this.dgClassLock = false;
                  //yg-sbhd
                  this.disableBlContainerQuantityLock = false;
                  this.disableBlContainerVGMLock = false;
                  this.disableBlContainerPackageTypeLock = false;
                  this.disableBlContainerGrossWeightLock = false;
                  this.disableBlContainerGrossMeasureLock = false;
                  this.disableBlContainerDescPrint = false;
                  this.disableBlContainerDescLock = false;
                  this.disableBlContainerAdPrint = false;
                  this.disableBlContainerNettWeightLock = false;
                  this.disableBlContainerNettMeasureLock = false;
                }

                this.isErrorContainer = false;
                this.paramContainer = new BLContainer();
                this.paramContainer.no = this.gridBlContainer.getSelectedValues()[0].no;
                this.paramContainer.blContainerOfficeCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOfficeCode;
                this.paramContainer.blContainerBlNo =
                  this.gridBlContainer.getSelectedValues()[0].blContainerBlNo;
                this.paramContainer.blContainerSeq =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSeq;
                this.paramContainer.blContainerNumber =
                  this.gridBlContainer.getSelectedValues()[0].blContainerNumber;
                this.paramContainer.blContainerSealNo1 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSealNo1;
                this.paramContainer.blContainerSealNo2 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSealNo2;
                this.paramContainer.blContainerSealNo3 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSealNo3;
                this.paramContainer.blContainerQuantity =
                  this.gridBlContainer.getSelectedValues()[0].blContainerQuantity;
                this.paramContainer.blContainerPackageTypeCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerPackageTypeCode;
                this.paramContainer.blContainerPackageTypeName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerPackageTypeName;
                this.paramContainer.blContainerGrossWeight =
                  this.gridBlContainer.getSelectedValues()[0].blContainerGrossWeight;
                  this.paramContainer.blContainerGrossWeightString =
                  this.gridBlContainer.getSelectedValues()[0].blContainerGrossWeightString;
                this.paramContainer.blContainerGrossMeasure =
                  this.gridBlContainer.getSelectedValues()[0].blContainerGrossMeasure;
                this.paramContainer.blContainerGrossMeasureString =
                  this.gridBlContainer.getSelectedValues()[0].blContainerGrossMeasureString;
                this.paramContainer.blContainerReceiptTypeCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerReceiptTypeCode;
                this.paramContainer.blContainerDeliveryTypeCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerDeliveryTypeCode;
                this.paramContainer.blContainerQ1 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerQ1;
                this.paramContainer.blContainerQ2 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerQ2;
                this.paramContainer.blContainerQ3 =
                  this.gridBlContainer.getSelectedValues()[0].blContainerQ3;
                this.paramContainer.blContainerType =
                  this.gridBlContainer.getSelectedValues()[0].blContainerType;

                this.paramContainer.blContainerVgm =
                  this.gridBlContainer.getSelectedValues()[0].blContainerVgm;
                this.paramContainer.blContainerNettWeight=
                  this.gridBlContainer.getSelectedValues()[0].blContainerNettWeight;
                  this.paramContainer.blContainerNettWeightString=
                  this.gridBlContainer.getSelectedValues()[0].blContainerNettWeightString;
                this.paramContainer.blContainerNettMeasure=
                  this.gridBlContainer.getSelectedValues()[0].blContainerNettMeasure;
                this.paramContainer.blContainerNettMeasureString=
                this.gridBlContainer.getSelectedValues()[0].blContainerNettMeasureString;

                this.paramContainer.blContainerTypeName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerTypeName;
                this.paramContainer.blContainerSize =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSize;
                this.paramContainer.blContainerSizeName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSizeName;
                this.paramContainer.blContainerHeight =
                  this.gridBlContainer.getSelectedValues()[0].blContainerHeight;
                this.paramContainer.blContainerHeightName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerHeightName;
                this.paramContainer.blContainerIsEmpty =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIsEmpty;
                this.paramContainer.blContainerIsEmptyString =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIsEmptyString;
                this.paramContainer.blContainerSkipCheck =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSkipCheck;

                this.paramContainer.blContainerShortShipContainer =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipContainer;

                this.paramContainer.blContainerShortShipContainerString =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipContainerString;

                this.paramContainer.blContainerIsDry =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIsDry;
                this.paramContainer.blContainerIsDryString =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIsDryString;
                this.paramContainer.blContainerLotNo =
                  this.gridBlContainer.getSelectedValues()[0].blContainerLotNo;
                this.paramContainer.blContainerToDesc =
                  this.gridBlContainer.getSelectedValues()[0].blContainerToDesc;
                this.paramContainer.blContainerSpecialDetailCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSpecialDetailCode;
                this.paramContainer.blContainerSpecialDetailName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSpecialDetailName;
                this.paramContainer.blContainerCreatedUserid =
                  this.gridBlContainer.getSelectedValues()[0].blContainerCreatedUserid;
                this.paramContainer.blContainerCreatedDate =
                  this.gridBlContainer.getSelectedValues()[0].blContainerCreatedDate;
                this.paramContainer.blContainerModifiedUserid =
                  this.gridBlContainer.getSelectedValues()[0].blContainerModifiedUserid;
                this.paramContainer.blContainerModifiedDate =
                  this.gridBlContainer.getSelectedValues()[0].blContainerModifiedDate;

                this.paramContainer.blContainerDGClass =
                  this.gridBlContainer.getSelectedValues()[0].blContainerDGClass;
                this.paramContainer.blContainerIMO =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIMO;
                this.paramContainer.blContainerUNNO =
                  this.gridBlContainer.getSelectedValues()[0].blContainerUNNO;
                this.paramContainer.blContainerFlashPointTemperature =
                  this.gridBlContainer.getSelectedValues()[0].blContainerFlashPointTemperature;
                this.paramContainer.blContainerFlashPointScale =
                  this.gridBlContainer.getSelectedValues()[0].blContainerFlashPointScale;
                this.paramContainer.blContainerReeferTemperature =
                  this.gridBlContainer.getSelectedValues()[0].blContainerReeferTemperature;
                this.paramContainer.blContainerReeferScale =
                  this.gridBlContainer.getSelectedValues()[0].blContainerReeferScale;
                this.paramContainer.blContainerOogOH =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogOH;
                this.paramContainer.blContainerOogOWL =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogOWL;
                this.paramContainer.blContainerOogOWR =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogOWR;
                this.paramContainer.blContainerOogOLF =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogOLF;
                this.paramContainer.blContainerOogOLB =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogOLB;
                this.paramContainer.blContainerOogScale =
                  this.gridBlContainer.getSelectedValues()[0].blContainerOogScale;

                this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL =
                  this.gridBlContainer.getSelectedValues()[0].blContainerIsPrintShortShipmentInfoOnBL;
                this.paramContainer.blContainerShortShipmentFrom =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentFrom;
                this.paramContainer.blContainerShortShipmentTo =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentTo;
                this.paramContainer.blContainerShortShipmentBlRef =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentBlRef;
                this.paramContainer.blContainerShortShipmentVesselId =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentVesselId;
                this.paramContainer.blContainerShortShipmentVesselCode =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentVesselCode;
                this.paramContainer.blContainerShortShipmentVesselVoyage =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentVesselVoyage;
                this.paramContainer.blContainerShortShipmentVesselBound =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentVesselBound;
                this.paramContainer.blContainerShortShipmentVesselName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerShortShipmentVesselName;
                this.paramContainer.blContainerSpecialTypeName =
                  this.gridBlContainer.getSelectedValues()[0].blContainerSpecialTypeName;

                // GSO-992 GSO-993
                this.isReworkedChecked = this.gridBlContainer.getSelectedValues()[0].blContainerIsReworkedContainer;
                this.paramContainer.blContainerIsPrintReworkedInfoOnBL = this.gridBlContainer.getSelectedValues()[0].blContainerIsPrintReworkedInfoOnBL;
                this.paramContainer.blContainerReworkedTo = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedTo;
                this.paramContainer.blContainerReworkedFrom = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedFrom;
                this.paramContainer.blContainerIsReworkedContainer = this.gridBlContainer.getSelectedValues()[0].blContainerIsReworkedContainer;
                this.paramContainer.blContainerReworkedContainerNumber = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedContainerNumber;
                this.paramContainer.blContainerReworkedBlRef = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedBlRef;
                this.paramContainer.blContainerReworkedVesselId = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselId;
                this.paramContainer.blContainerReworkedVesselCode = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselCode;
                this.paramContainer.blContainerReworkedVesselName = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselName;
                this.paramContainer.blContainerReworkedVesselVoyage = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselVoyage;
                this.paramContainer.blContainerReworkedBound = this.gridBlContainer.getSelectedValues()[0].blContainerReworkedBound;

                if(this.isReworkedChecked){
                  this.cbBlContainerReworkedVessel.setForceValue(this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselName);
                  this.cbBlContainerReworkedVoyage.setForceValue(this.gridBlContainer.getSelectedValues()[0].blContainerReworkedVesselVoyage);
                }

                this.isDisabledReworked = false;

                if (
                  this.gridBlContainer.getSelectedValues()[0].blContainerExtra
                ) {
                  this.paramContainerExtra =
                    this.gridBlContainer.getSelectedValues()[0].blContainerExtra;
                  this.paramContainer.blContainerExtra =
                    this.gridBlContainer.getSelectedValues()[0].blContainerExtra;
                }

                //yg-sbhd
                if (
                  this.gridBlContainer.getSelectedValues()[0].blAdContainer
                ) {
                  this.paramBlAdContainer =
                    this.gridBlContainer.getSelectedValues()[0].blAdContainer;
                  this.paramContainer.blAdContainer =
                    this.gridBlContainer.getSelectedValues()[0].blAdContainer;
                }
                //yg-sbhd


                // set combo
                if (
                  this.paramContainer.blContainerShortShipmentVesselId !=
                    null &&
                  this.paramContainer.blContainerShortShipmentVesselId !== "" &&
                  this.paramContainer.blContainerShortShipmentVesselId !=
                    undefined
                ) {
                  const self = this;
                  this.cbBlContainerShortShipmentVessel.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterVessels/findByComboBoxControl/vesselId={query}"
                  );
                  this.cbBlContainerShortShipmentVessel.setValue(
                    this.paramContainer.blContainerShortShipmentVesselId,
                    function () {
                      self.cbBlContainerShortShipmentVoyage.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterSailingSchedules/distinctVoyage/" +
                          self.paramContainer.blContainerShortShipmentVesselId +
                          "/" +
                          self.paramContainer.blContainerShortShipmentVesselBound.toUpperCase() +
                          "/{query}/" +
                          self.cookieService.getDefaultLocationCode()
                      );
                      self.cbBlContainerShortShipmentVoyage.setValue(
                        self.paramContainer.blContainerShortShipmentVesselVoyage
                      );
                    }
                  );
                }

                if (
                  this.paramContainer.blContainerPackageTypeCode != null &&
                  this.paramContainer.blContainerPackageTypeCode !== "" &&
                  this.paramContainer.blContainerPackageTypeCode != undefined
                ) {
                  this.cbBlContainerPackageType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
                  );
                  // this.cbBlContainerPackageType.setValue(
                  //   this.paramContainer.blContainerPackageTypeCode.replace(
                  //     "/",
                  //     "[-47]"
                  //   )
                  // );
                  this.cbBlContainerPackageType.setForceValue(
                    this.paramContainer.blContainerPackageTypeName
                  );
                }

                if (
                  this.paramContainerExtra.blContainerInnerPackageTypeCode !==
                    null &&
                  this.paramContainerExtra.blContainerInnerPackageTypeCode !==
                    "" &&
                  this.paramContainerExtra.blContainerInnerPackageTypeCode !==
                    undefined
                ) {
                  this.cbBlContainerInnerPkgType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
                  );

                  this.cbBlContainerInnerPkgType.setForceValue(
                    this.paramContainerExtra.blContainerInnerPackageTypeName
                  );
                }

                //yg-sbhd
                console.log("Double click extra: "+BLContainerExtra.length);
                //gos-783
                //if bl container extra didnt exist
                if(Object.keys(this.paramContainerExtra).length>0){
                  if(this.paramContainerExtra.blContainerQuantityLock == "Y"){
                    this.blContainerQuantityLock = true;
                  }else{
                    this.blContainerQuantityLock = false;
                  }

                  if(this.paramContainerExtra.blContainerPackageTypeLock == "Y"){
                    this.blContainerPackageTypeLock = true;
                  }else{
                    this.blContainerPackageTypeLock = false;
                  }

                  if(this.paramContainerExtra.blContainerGrossWeightLock == "Y"){
                    this.blContainerGrossWeightLock = true;
                  }else{
                    this.blContainerGrossWeightLock = false;
                  }

                  if(this.paramContainerExtra.blContainerGrossMeasureLock == "Y"){
                    this.blContainerGrossMeasureLock = true;
                  }else{
                    this.blContainerGrossMeasureLock = false;
                  }

                  if(this.paramContainerExtra.blContainerVgmLock == "Y"){
                    this.blContainerVGMLock = true;
                  }else{
                    this.blContainerVGMLock = false;
                  }

                  if(this.paramContainerExtra.blContainerNettWeightLock == "Y"){
                    this.blContainerNettWeightLock = true;
                  }else{
                    this.blContainerNettWeightLock = false;
                  }

                  if(this.paramContainerExtra.blContainerNettMeasureLock == "Y"){
                    this.blContainerNettMeasureLock = true;
                  }else{
                    this.blContainerNettMeasureLock = false;
                  }

                 }else{
                  this.blContainerQuantityLock = true;
                  this.blContainerPackageTypeLock = true;
                  this.blContainerGrossWeightLock = true;
                  this.blContainerGrossMeasureLock = true;
                  this.blContainerVGMLock = true;
                  this.blContainerNettWeightLock = true;
                  this.blContainerNettMeasureLock = false;
                }


                if(this.paramContainerExtra.blContainerDescPrint == "Y"){
                  this.blContainerDescPrint = true;
                }else{
                  this.blContainerDescPrint = false;
                }

                if(this.paramContainerExtra.blContainerDescLock == "Y"){
                  this.blContainerDescLock = true;
                }else{
                  this.blContainerDescLock = false;
                }

                if(this.paramContainerExtra.blContainerAdPrint == "Y"){
                  this.blContainerAdPrint = true;
                }else{
                  this.blContainerAdPrint = false;
                }

                this.tempBlContainerDesc = this.paramContainerExtra.blContainerDesc ;
                this.txtBlContainerDesc.setValue(
                  // this.paramContainerExtra.blContainerDesc
                  this.tempBlContainerDesc
                );
                //yg-sbhd

                if (
                  this.paramContainerExtra.blContainerCommodityTypeName !==
                    null &&
                  this.paramContainerExtra.blContainerCommodityTypeName !==
                    "" &&
                  this.paramContainerExtra.blContainerCommodityTypeName !==
                    undefined
                ) {
                  this.tempBlContainerCommodityTypeId = this.paramContainerExtra.blContainerCommodityTypeId;
                  this.tempBlContainerCommodityTypeName = this.paramContainerExtra.blContainerCommodityTypeName;
                  this.cbBlContainerCommodity.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCommodities/findByComboBoxControl/commodityName={query}"
                  );

                  this.cbBlContainerCommodity.setForceValue(
                    this.tempBlContainerCommodityTypeName
                  );
                }

                if (
                  this.paramContainer.blContainerReceiptTypeCode != null &&
                  this.paramContainer.blContainerReceiptTypeCode !== "" &&
                  this.paramContainer.blContainerReceiptTypeCode != undefined
                ) {
                  this.cbBlContainerReceiptType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
                  );
                  this.cbBlContainerReceiptType.setValue(
                    this.paramContainer.blContainerReceiptTypeCode
                  );
                }

                if (
                  this.paramContainer.blContainerDeliveryTypeCode != null &&
                  this.paramContainer.blContainerDeliveryTypeCode !== "" &&
                  this.paramContainer.blContainerDeliveryTypeCode != undefined
                ) {
                  this.cbBlContainerDeliveryType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
                  );
                  this.cbBlContainerDeliveryType.setValue(
                    this.paramContainer.blContainerDeliveryTypeCode
                  );
                }

                if (
                  this.paramContainer.blContainerIsDry === true &&
                  this.paramContainer.blContainerType === "DRY"
                ) {
                  this.paramContainer.blContainerTypeName = "REEFER";
                }
                this.cbBlContainerContainerType.setValue(
                  this.paramContainer.blContainerTypeName
                );
                this.cbBlContainerContainerSize.setValue(
                  this.paramContainer.blContainerSizeName
                );
                this.cbBlContainerContainerHeight.setValue(
                  this.paramContainer.blContainerHeightName
                );
                this.cbBlContainerContainerDGClass.setValue(
                  this.paramContainer.blContainerDGClass
                );
                this.cbBlContainerFlashPointScale.setValue(
                  this.paramContainer.blContainerFlashPointScale
                );
                this.cbBlContainerOogScale.setValue(
                  this.paramContainer.blContainerOogScale
                );
                this.cbBlContainerReeferScale.setValue(
                  this.paramContainer.blContainerReeferScale
                );
                //yg-sbhd
                if (
                  this.gridBlContainer.getSelectedValues()[0].blContainerHSCode
                    .length > 0
                ) {
                  this.rowDataContainerHs = [];
                  let newRowDataHs = [];
                  for (
                    let i = 0;
                    i <
                    this.gridBlContainer.getSelectedValues()[0]
                      .blContainerHSCode.length;
                    i++
                  ) {
                    newRowDataHs.push(
                      this.gridBlContainer.getSelectedValues()[0]
                        .blContainerHSCode[i]
                    );
                    // this.paramContainer.blContainerHSCode.push(this.gridBlContainerHS.listStore.store[i]);
                  }
                  this.rowDataContainerHs = newRowDataHs;
                }

                if (
                  this.gridBlContainer.getSelectedValues()[0].blAdContainer
                    .length > 0
                ) {
                  this.rowDataADContainer = [];
                  let newRowData = [];

                  for (
                    let i = 0;
                    i <
                    this.gridBlContainer.getSelectedValues()[0]
                      .blAdContainer.length;
                    i++
                  ) {
                    newRowData.push(
                      this.gridBlContainer.getSelectedValues()[0]
                        .blAdContainer[i]
                    );
                    // this.paramContainer.blAdContainer.push(this.gridBlAdContainer.listStore.store[i]);
                  }
                  this.rowDataADContainer = newRowData;
                }
                //yg-sbhd

                // lock the tab
                this.lockParty = true;
                this.lockPlace = true;
                this.lockContainer = false;
                this.lockMarkNo = true;
                this.lockDesc = true;
                this.lockNettCharge = true;
                this.lockGrossCharge = true;
                this.lockNettChargeConsole = true;
                this.lockGrossChargeConsole = true;
              }
              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGridBlContainer(event) {}

  changeEventChBlContainerSkipCheckBlContainerNo(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerSkipCheck = true;
      this.paramContainer.blContainerSkipCheckString = "Y";
    } else {
      this.paramContainer.blContainerSkipCheck = false;
      this.paramContainer.blContainerSkipCheckString = "N";
    }
  }

  changeEventChBlContainerIsEmpty(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerIsEmpty = true;
      this.paramContainer.blContainerIsEmptyString = "Y";
    } else {
      this.paramContainer.blContainerIsEmpty = false;
      this.paramContainer.blContainerIsEmptyString = "N";
    }
  }

  focusOuteventBlContainer(event: any, type: string){
    var val = event.target.value.replace(/\s/g, "");
    if(type == "blContainerNumber"){
      this.paramContainer.blContainerNumber = val;
    }else if(type == "blContainerSealNo1"){
      this.paramContainer.blContainerSealNo1 = val;
    }else if(type == "blContainerSealNo2"){
      this.paramContainer.blContainerSealNo2 = val;
    }else if(type == "blContainerSealNo3"){
      this.paramContainer.blContainerSealNo3 = val;
    }
  }

  keypressEventBlContainer(event: any) {
    var regexPattern = new RegExp("[a-zA-Z0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  keypressEventSealNo(event: any) {
    var regexPattern = new RegExp("[a-zA-Z0-9*\/\.-]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteEventBlContainer(event: any, type: string) {
    // console.log(event);
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9\/\.-]/g, "");

    event.preventDefault();

    if (type == "blContainerNumber") {
      pastedText = event.clipboardData
      .getData("text")
      .replace(/[^a-zA-Z0-9]/g, "");
      this.paramContainer.blContainerNumber = pastedText;
    }else if(type == "blContainerSealNo1"){
      this.paramContainer.blContainerSealNo1 = pastedText;
    }else if(type == "blContainerSealNo2"){
      this.paramContainer.blContainerSealNo2 = pastedText;
    }else if(type == "blContainerSealNo3"){
      this.paramContainer.blContainerSealNo3 = pastedText;
    }
    console.log("DISNI,== ", this.paramContainer.blContainerBlNo)
  }


  changeEventChBlContainerIsDry(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerIsDry = true;
      this.paramContainer.blContainerIsDryString = "Y";

      if (this.paramContainer.blContainerType === "REF") {
        this.paramContainer.blContainerType = "DRY";
        this.paramContainer.blContainerTypeName = "REEFER";
      }
    } else {
      this.paramContainer.blContainerIsDry = false;
      this.paramContainer.blContainerIsDryString = "N";

      if (this.paramContainer.blContainerType === "DRY") {
        this.paramContainer.blContainerType = "REF";
        this.paramContainer.blContainerTypeName = "REEFER";
      }
    }
  }

  changeEventChBlContainerShortShipContainer(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerShortShipContainer = true;
      this.paramContainer.blContainerShortShipContainerString = "Y";
      this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL = false; // set to false 20/03/2024
    } else {
      this.paramContainer.blContainerShortShipContainer = false;
      this.paramContainer.blContainerShortShipContainerString = "N";
      this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL = false;

      this.paramContainer.blContainerShortShipmentBlRef = "";
      this.paramContainer.blContainerShortShipmentVesselId = "";
      this.paramContainer.blContainerShortShipmentVesselCode = "";
      this.paramContainer.blContainerShortShipmentVesselName = "";
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
      this.paramContainer.blContainerShortShipmentVesselBound = "";

      this.cbBlContainerShortShipmentVessel.setValue("");
      this.cbBlContainerShortShipmentVoyage.setValue("");

      // also reset from and to option both to false
      this.paramContainer.blContainerShortShipmentFrom = false;
      this.paramContainer.blContainerShortShipmentTo = false;

      // loop through blcontainer grid, to check whether other container with SS present or not
      // if not present, must force reset the freight covered in bl indicator
      let ssIndicator: Boolean = false;
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (const entry of this.gridBlContainer.listStore.store) {
          if (entry.blContainerSeq !== this.paramContainer.blContainerSeq) {
            if (this.paramContainer.blContainerShortShipContainer) {
              ssIndicator = true;
            }
          }
        }
      }

      if (!ssIndicator) {
        this.model.blFreightCoveredInBlNo = "";
      }
    }
  }

  changeEventChblContainerIsReworkedContainer(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerIsReworkedContainer = true;
      this.isReworkedChecked = true;
      this.paramContainer.blContainerIsPrintReworkedInfoOnBL = true;
    }else{
      this.paramContainer.blContainerIsReworkedContainer = false;

      this.paramContainer.blContainerIsPrintReworkedInfoOnBL = false;
      this.isReworkedChecked = false;
      this.resetReworkedInfoContainer();
    }
  }

  resetReworkedInfoContainer(){
    this.paramContainer.blContainerReworkedTo = false;
    this.paramContainer.blContainerReworkedFrom = false;
    this.paramContainer.blContainerReworkedContainerNumber = "";
    this.paramContainer.blContainerReworkedBlRef = "";
    this.paramContainer.blContainerReworkedContainerNumber = "";
    this.paramContainer.blContainerReworkedVesselId = "";
    this.paramContainer.blContainerReworkedVesselCode = "";
    this.paramContainer.blContainerReworkedVesselName = "";
    this.paramContainer.blContainerReworkedVesselVoyage= "";
    this.paramContainer.blContainerReworkedBound = "";
    this.cbBlContainerReworkedVessel.setForceValue("");
    this.cbBlContainerReworkedVoyage.setForceValue("");
  }

  changeEventChBlContainerPrintReworkedInfoOnBL(event){
    if(event.target.checked){
      this.paramContainer.blContainerIsPrintReworkedInfoOnBL = true;
    }else{
      this.paramContainer.blContainerIsPrintReworkedInfoOnBL = false;
    }

  }

  // checkContainerNoReworked(){
  //   let containerNumberList = this.gridBlContainer.listStore.store.map(x=> x.blContainerNumber);
  //   if(containerNumberList.includes(this.paramContainer.blContainerReworkedContainerNumber)){
  //     this.message(
  //       "error",
  //       "Error",
  //       "Container No already exists.",
  //       "okonly",
  //       { ok: "" }
  //     );
  //   }
  // }

  changeEventblContainerReworkedTo(event){
    if (event.target.checked) {
      this.paramContainer.blContainerReworkedTo = true;
      this.paramContainer.blContainerReworkedFrom = false;
      this.paramContainer.blContainerReworkedContainerNumber = "";
      this.paramContainer.blContainerReworkedBlRef = "";
      this.paramContainer.blContainerReworkedVesselId = "";
      this.paramContainer.blContainerReworkedVesselCode = "";
      this.paramContainer.blContainerReworkedVesselName = "";
      this.paramContainer.blContainerReworkedVesselVoyage = "";
      this.paramContainer.blContainerReworkedBound = "O";
      this.cbBlContainerReworkedVessel.setForceValue("");
      this.cbBlContainerReworkedVoyage.setForceValue("");
    } else {
      this.paramContainer.blContainerReworkedTo = false;
    }
  }

  changeEventblContainerReworkedFrom(event){
    if (event.target.checked) {
      this.paramContainer.blContainerReworkedFrom = true;
      this.paramContainer.blContainerReworkedTo = false;
      this.paramContainer.blContainerReworkedContainerNumber = "";
      this.paramContainer.blContainerReworkedBlRef = "";
      this.paramContainer.blContainerReworkedVesselId = "";
      this.paramContainer.blContainerReworkedVesselCode = "";
      this.paramContainer.blContainerReworkedVesselName = "";
      this.paramContainer.blContainerReworkedVesselVoyage = "";
      this.paramContainer.blContainerReworkedBound = "";
      this.cbBlContainerReworkedVessel.setForceValue("");
      this.cbBlContainerReworkedVoyage.setForceValue("");
    } else {
      this.paramContainer.blContainerReworkedFrom = false;
    }
  }

  checkReworkedFrom(){
    if(this.paramContainer.blContainerReworkedBlRef){
      // similar with shortshipped
      if (this.paramContainer.blContainerReworkedFrom === true) {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/checkReworkedBooking/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.paramContainer.blContainerReworkedBlRef
                .toUpperCase()
                .trim()
          )
          .subscribe((resp) => {
            const data = resp.json();
            if (data.status === "OK") {
              this.paramContainer.blContainerReworkedVesselId =
                data.vesselId;
              this.paramContainer.blContainerReworkedVesselCode =
                data.vesselCode;
              this.paramContainer.blContainerReworkedVesselName =
                data.vesselName;
              this.paramContainer.blContainerReworkedVesselVoyage =
                data.vesselVoyage;
              this.paramContainer.blContainerReworkedBound =
                data.vesselBound;
              this.cbBlContainerReworkedVessel.setForceValue(
                data.vesselName
              );
              this.cbBlContainerReworkedVoyage.setForceValue(
                data.vesselVoyage
              );

            } else {
              this.frmDialogMessage = "Invalid Booking/BL.";
              this.genericUtil.showDialog(
                "blDialogShortshipEtd",
                "Information",
                350,
                180
              );
            }
          });
      } else {
        if (this.paramContainer.blContainerReworkedTo === true) {
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BL/checkReworkedFrom/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                this.paramContainer.blContainerReworkedBlRef +
                "/" +
                this.paramContainer.blContainerNumber
            )
            .subscribe((resp) => {
              const dt = resp.json();
              if (dt.status !== "OK") {
                this.frmDialogMessage = "Invalid Reworked linkage.";
                this.genericUtil.showDialog(
                  "blDialogReworkedEtd",
                  "Information",
                  350,
                  180
                );
              } else {
                this.genericService
                  .GET(
                    this.configService.config.BASE_API.toString() +
                      "/MasterBookings/checkReworkedBooking/" +
                      this.cookieService.getDefaultLocationCode() +
                      "/" +
                      this.paramContainer.blContainerReworkedBlRef
                        .toUpperCase()
                        .trim()
                  )
                  .subscribe((resp) => {
                    const data = resp.json();
                    if (data.status === "OK") {
                      this.paramContainer.blContainerReworkedVesselId =
                        data.vesselId;
                      this.paramContainer.blContainerReworkedVesselCode =
                        data.vesselCode;
                      this.paramContainer.blContainerReworkedVesselName =
                        data.vesselName;
                      this.paramContainer.blContainerReworkedVesselVoyage =
                        data.vesselVoyage;
                      this.paramContainer.blContainerReworkedBound =
                        data.vesselBound;
                      this.cbBlContainerReworkedVessel.setForceValue(
                        data.vesselName
                      );
                      this.cbBlContainerReworkedVoyage.setForceValue(
                        data.vesselVoyage
                      );

                      // set freight covered information
                      // this.model.blFreightCoveredInBlNo = "";
                    }
                  });
              }
            });
        } else {
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/MasterBookings/checkReworkedBooking/" +
                this.cookieService.getDefaultLocationCode() +
                "/" +
                this.paramContainer.blContainerReworkedBlRef
                  .toUpperCase()
                  .trim()
            )
            .subscribe((resp) => {
              const dt = resp.json();
              if (dt.status === "OK") {
                this.paramContainer.blContainerReworkedVesselId =
                  dt.vesselId;
                this.paramContainer.blContainerReworkedVesselCode =
                  dt.vesselCode;
                this.paramContainer.blContainerReworkedVesselName =
                  dt.vesselName;
                this.paramContainer.blContainerReworkedVesselVoyage =
                  dt.vesselVoyage;
                this.paramContainer.blContainerReworkedBound =
                  dt.vesselBound;

                  this.genericService
                  .GET(
                    this.configService.config.BASE_API.toString() +
                      "/MasterSailingSchedules/distinctVoyageWithEtd/" +
                      this.paramContainer.blContainerReworkedVesselId +
                      "/" +
                      this.paramContainer.blContainerReworkedBound.toUpperCase() +
                      "/" +
                      this.paramContainer.blContainerReworkedVesselVoyage +
                      "/" +
                      this.cookieService.getDefaultLocationCode() +
                      "/" +
                      this.cookieService.getDefaultLocationCode() +
                      "/" +
                      this.model.blOceanVesselId +
                      "/" +
                      this.model.blOceanVesselVoyage +
                      "/" +
                      this.model.blOceanVesselBound
                  )
                  .subscribe((resp2) => {
                    const dt2 = resp2.json();
                    if (dt2.content.length > 0) {
                      this.frmDialogMessage = "Overwrite Details?";
                      this.genericUtil.showDialog(
                        "blDialogSReworkedOverwrite",
                        "Confirm",
                        350,
                        180
                      );
                    } else {
                      this.frmDialogMessage = "Invalid Etd.";
                      this.genericUtil.showDialog(
                        "blDialogShortshipEtd",
                        "Information",
                        350,
                        180
                      );
                    }
                  });

              } else {
                this.paramContainer.blContainerReworkedBlRef = "";
                this.paramContainer.blContainerReworkedVesselId = "";
                this.paramContainer.blContainerReworkedVesselCode = "";
                this.paramContainer.blContainerReworkedVesselName = "";
                this.paramContainer.blContainerReworkedVesselVoyage = "";
                this.paramContainer.blContainerReworkedBound = "";
                this.cbBlContainerReworkedVessel.setForceValue("");
                this.cbBlContainerReworkedVoyage.setForceValue("");
              }
            });
        }
      }
    }
  }

  onBlDialogReworkedEtdOk() {
    this.paramContainer.blContainerReworkedBlRef = "";
    this.paramContainer.blContainerReworkedVesselId = "";
    this.paramContainer.blContainerReworkedVesselCode = "";
    this.paramContainer.blContainerReworkedVesselName = "";
    this.paramContainer.blContainerReworkedVesselVoyage = "";
    this.paramContainer.blContainerReworkedBound = "";
    this.cbBlContainerReworkedVessel.setForceValue("");
    this.cbBlContainerReworkedVoyage.setForceValue("");
    this.genericUtil.closeDialog("blDialogReworkedEtd");
  }

  onBlDialogSReworkedOverwriteYes(){
    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/MasterBookings/checkReworkedBooking/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.paramContainer.blContainerReworkedBlRef.toUpperCase().trim()
    )
    .subscribe((resp) => {
      const dt = resp.json();
      if (dt.status === "OK") {
        this.paramContainer.blContainerReworkedVesselId = dt.vesselId;
        this.paramContainer.blContainerReworkedVesselCode =
          dt.vesselCode;
        this.paramContainer.blContainerReworkedVesselName =
          dt.vesselName;
        this.paramContainer.blContainerReworkedVesselVoyage =
          dt.vesselVoyage;
        this.paramContainer.blContainerReworkedBound =
          dt.vesselBound;
        this.cbBlContainerReworkedVessel.setForceValue(
          this.paramContainer.blContainerReworkedVesselName
        );
        this.cbBlContainerReworkedVoyage.setForceValue(
          this.paramContainer.blContainerReworkedVesselVoyage
        );
      } else {
        this.paramContainer.blContainerReworkedBlRef = "";
        this.paramContainer.blContainerReworkedVesselId = "";
        this.paramContainer.blContainerReworkedVesselCode = "";
        this.paramContainer.blContainerReworkedVesselName = "";
        this.paramContainer.blContainerReworkedVesselVoyage = "";
        this.paramContainer.blContainerReworkedBound = "";
        this.cbBlContainerReworkedVessel.setForceValue("");
        this.cbBlContainerReworkedVoyage.setForceValue("");
      }
    });
    this.genericUtil.closeDialog("blDialogSReworkedOverwrite");
  }

  onBlDialogSReworkedOverwriteNo(){
    this.genericUtil.closeDialog("blDialogSReworkedOverwrite");
  }

  changeEventCbBlContainerReworkedVessel(event){
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.paramContainer.blContainerReworkedVesselId = event.vesselId;
      this.paramContainer.blContainerReworkedVesselCode = event.vesselCode;
      this.paramContainer.blContainerReworkedVesselName = event.vesselName;
      this.paramContainer.blContainerReworkedVesselVoyage = "";
      this.paramContainer.blContainerReworkedBound = "O";
      this.cbBlContainerReworkedVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageWithEtd/" +
          this.paramContainer.blContainerReworkedVesselId +
          "/" +
          this.paramContainer.blContainerReworkedBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselVoyage +
          "/" +
          this.model.blOceanVesselBound
      );
    } else {
      this.paramContainer.blContainerReworkedVesselId = "";
      this.paramContainer.blContainerReworkedVesselCode = "";
      this.paramContainer.blContainerReworkedVesselName = "";
      this.paramContainer.blContainerReworkedVesselVoyage = "";
      this.paramContainer.blContainerReworkedBound = "";
      this.cbBlContainerReworkedVoyage.setUrl("");
      this.cbBlContainerReworkedVoyage.setValue("");
    }
    this.cbBlContainerReworkedVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventCbBlContainerReworkedVoyage(event){
    if (
      event.voyage != null &&
      event.voyage !== "" &&
      event.voyage !== undefined
    ) {
      this.paramContainer.blContainerReworkedVesselVoyage = event.voyage;
      this.paramContainer.blContainerReworkedBound = event.bound;
      this.cbBlContainerReworkedVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageWithEtd/" +
          this.paramContainer.blContainerReworkedVesselId +
          "/" +
          this.paramContainer.blContainerReworkedBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselVoyage +
          "/" +
          this.model.blOceanVesselBound
      );
    } else {
      this.paramContainer.blContainerReworkedVesselVoyage = "";
    }
  }

  changeBlReworkedOceanBound(){
    this.cbBlContainerReworkedVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyageWithEtd/" +
        this.paramContainer.blContainerReworkedVesselId +
        "/" +
        this.paramContainer.blContainerReworkedBound.toUpperCase() +
        "/{query}/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blOceanVesselId +
        "/" +
        this.model.blOceanVesselVoyage +
        "/" +
        this.model.blOceanVesselBound
    );
  }

  resetBlContainerForm() {
    this.blContainerUpdateMode = "insert";
    this.txtBlContainerSaveUpdate = "Save";
    this.isErrorContainer = false;
    this.paramContainer = new BLContainer();
    this.paramContainerExtra = new BLContainerExtra(); //yg-sbhd
    this.paramContainer.blContainerOfficeCode =
      this.cookieService.getDefaultLocationCode();
    this.paramContainer.blContainerBlNo = this.model.blNo;
    this.paramContainer.blContainerSeq = 0;
    this.paramContainer.blContainerReceiptTypeCode =
      this.model.blReceiptTypeCode !== "" ? this.model.blReceiptTypeCode : "CY";
    this.cbBlContainerReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    this.cbBlContainerReceiptType.setValue(
      this.paramContainer.blContainerReceiptTypeCode
    );

    this.paramContainer.blContainerDeliveryTypeCode =
      this.model.blDeliveryTypeCode !== ""
        ? this.model.blDeliveryTypeCode
        : "CY";
    this.cbBlContainerDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
    );
    this.cbBlContainerDeliveryType.setValue(
      this.paramContainer.blContainerDeliveryTypeCode
    );

    this.paramContainer.blContainerPackageTypeName = "";
    this.paramContainer.blContainerPackageTypeCode = "";
    this.cbBlContainerPackageType.setValue("");

    this.paramContainer.blContainerType = "STD";
    this.paramContainer.blContainerTypeName = "NORMAL";
    this.paramContainer.blContainerSize = "";
    this.paramContainer.blContainerHeight = "86";
    this.paramContainer.blContainerHeightName = "86";
    this.paramContainer.blContainerIsEmpty = false;
    this.paramContainer.blContainerSkipCheck = true;
    this.paramContainer.blContainerShortShipContainer = false;
    this.paramContainer.blContainerShortShipContainerString = "N";
    this.paramContainer.blContainerDGClass = "N/A";

    // yg-sbhd
    this.paramContainerExtra.blContainerQuantityLock = "Y";
    this.paramContainerExtra.blContainerPackageTypeLock = "Y";
    this.paramContainerExtra.blContainerGrossWeightLock = "Y";
    this.paramContainerExtra.blContainerGrossMeasureLock = "Y";
    this.paramContainerExtra.blContainerNettWeightLock = "Y";
    this.paramContainerExtra.blContainerNettMeasureLock = "Y";
    this.blContainerQuantityLock = true;
    this.blContainerPackageTypeLock = true;
    this.blContainerGrossWeightLock = true;
    this.blContainerGrossMeasureLock = true;
    this.blContainerNettWeightLock = true;
    this.blContainerNettMeasureLock = true;
    this.blContainerVGMLock = true;
    this.paramContainerExtra.blContainerDescPrint = "N";
    this.paramContainerExtra.blContainerDescLock = "N";
    this.paramContainerExtra.blContainerAdPrint = "N";
    this.blContainerDescPrint = false;
    this.blContainerDescLock = false;
    this.blContainerAdPrint = false;

    // GSO-992 / GSO-993
    this.isReworkedChecked = false;
    this.cbBlContainerReworkedVessel.setValue("");
    this.cbBlContainerReworkedVoyage.setValue("");
    // yg-sbhd

    this.cbBlContainerContainerType.setValue("NORMAL");
    this.cbBlContainerContainerSize.setValue("");
    this.cbBlContainerContainerHeight.setValue("86");
    this.cbBlContainerOogScale.setValue("");
    this.cbBlContainerContainerDGClass.setValue("");
    this.cbBlContainerFlashPointScale.setValue("");
    this.cbBlContainerReeferScale.setValue("");
    this.cbBlContainerShortShipmentVessel.setValue("");
    this.cbBlContainerShortShipmentVoyage.setValue("");
    this.txtBlContainerDesc.setValue("");

    // this.btnBlContainerHSNew = false;
    // this.btnBlContainerHSCancel = false;
    // this.btnBlContainerHSSave = false;
    // this.btnBlContainerHSDelete = false;
    this.isErrorHSCode = false;
    // this.gridBlContainerHS.onClear();
    this.paramContainerHSCode = new BLContainerHSCode();
    //yg-sbhd
    this.paramBlAdContainer = new BLAdContainer();
    this.cbBlContainerHS.setValue("");
    this.rowDataADContainer = [];
    this.rowDataContainerHs = [];

    this.cbBlContainerHS.setValue("");

    // this.btnBlAdContainerNew = false;
    // this.btnBlAdContainerCancel = false;
    // this.btnBlAdContainerSave = false;
    // this.btnBlAdContainerDelete = false;
    this.isErrorAdCode = false;
    // this.gridBlAdContainer.onClear();
    // this.paramBlAdContainer = new BLAdContainer();
    // end yg-sbhd

    // * Remove Value Container Extra
    // this.paramContainerExtra = new BLContainerExtra();
    // this.cbBlContainerInnerPkgType.setValue("");
    this.cbBlContainerCommodity.setValue("");

    // release the tab
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    // yg-sbhd
    this.disableBlContainerQuantityLock = true;
    this.disableBlContainerVGMLock = true;
    this.disableBlContainerPackageTypeLock = true;
    this.disableBlContainerGrossWeightLock = true;
    this.disableBlContainerGrossMeasureLock = true;
    this.disableBlContainerDescPrint = true;
    this.disableBlContainerDescLock = true;
    this.disableBlContainerAdPrint = true;
    this.disableBlContainerNettWeightLock = true;
    this.disableBlContainerNettMeasureLock = true;
  }

  eventBtnBlContainerNew() {
    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print,sync";
    this.btnBlContainerNew = false;
    this.btnBlContainerSave = true;
    this.btnBlContainerDelete = false;
    this.btnBlContainerCancel = true;
    this.frmContainerLock = false; // to release container form lock
    this.dgClassLock = false;
    // yg-sbhd
    this.btnBlContainerHSNew = true;
    this.btnBlAdContainerNew = true;


    this.resetBlContainerForm();

    //added to uncheck as default once click New button

    if(this.site.startsWith("CN"))  //changed implementation from CNSHA to be CN%
      {this.blContainerQuantityLock = false;
       this.blContainerPackageTypeLock = false;
       this.blContainerGrossWeightLock = false;
       this.blContainerGrossMeasureLock = false;
       this.blContainerVGMLock = false;
       this.blContainerNettWeightLock = false;
       this.blContainerNettMeasureLock = false;
     }
    // yg-sbhd
    this.disableBlContainerQuantityLock = false;
    this.disableBlContainerVGMLock = false;
    this.disableBlContainerPackageTypeLock = false;
    this.disableBlContainerGrossWeightLock = false;
    this.disableBlContainerGrossMeasureLock = false;
    this.disableBlContainerDescPrint = false;
    this.disableBlContainerDescLock = false;
    this.disableBlContainerAdPrint = false;
    this.disableBlContainerNettWeightLock = false;
    this.disableBlContainerNettMeasureLock = false;
    this.blContainerDescLock = false;
    this.paramContainerExtra.blContainerDescLock = "N";

    this.cbBlContainerOogScale.setValue("CM");
    this.paramContainer.blContainerOogScale = "CM";
    this.paramContainer.blContainerSeq =
      this.gridBlContainer.listStore.store.length + 1;

    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      if (this.model.blContainerStatus === "EMPTY") {
        this.paramContainer.blContainerIsEmpty = true;
        this.paramContainer.blContainerIsEmptyString = "Y";
      }

      if (this.model.blContainerType === "DRY") {
        this.paramContainer.blContainerIsDry = true;
        this.paramContainer.blContainerIsDryString = "Y";
      }
    }

    this.isDisabledReworked = false;

    //gos130
    // GOS-734 Container Tab - To Enable/Open Up DG Class Field
    if(this.model.blIsCombine == "Y"){
      this.cbBlContainerContainerDGClass.clearSelect();
      this.cbBlContainerContainerDGClass.setData(this.dgNosCombine("C1,C2,C2F,C3,N"));
      let blDgClass = this.model.blDgClass === "N" ? "N/A" : this.model.blDgClass;
      if(this.model.blDgNos.length >= 2) {
        setTimeout(() => {
          this.paramContainer.blContainerDGClass = "N/A";
          this.cbBlContainerContainerDGClass.setValue("N/A");
        }, 0);
      }else{
        setTimeout(() => {
          this.paramContainer.blContainerDGClass = blDgClass;
          this.cbBlContainerContainerDGClass.setValue(blDgClass);
        }, 0);
      }
    }else{
      this.cbBlContainerContainerDGClass.setData(this.dgNosCombine("C1,C2,C2F,C3,N"));
      if(this.model.blDgClass != '' && this.model.blDgClass != null){
        if(this.model.blDgClass == "N"){
          this.paramContainer.blContainerDGClass = "N/A";
        }else{
          this.paramContainer.blContainerDGClass = this.model.blDgClass;
        }
        // this.cbBlContainerContainerDGClass.setData(this.dgNosCombine(this.model.blDgClass));
        setTimeout(() => {
          this.cbBlContainerContainerDGClass.setValue(this.paramContainer.blContainerDGClass);
        }, 0);
      } else {
        console.log("HERE");
        this.paramContainer.blContainerDGClass = "N/A";
        // this.cbBlContainerContainerDGClass.setData(this.dgNos);
        this.cbBlContainerContainerDGClass.setValue(this.paramContainer.blContainerDGClass);

      }
    //this.dgClassLock = true;
    //this.cbBlContainerContainerDGClass.clearSelect();


  }
  //end gos130

    // yg-sbhd
    // this.btnBlContainerHSNew = true;
    // this.btnBlAdContainerNew = true;

    // lock the tab
    this.lockParty = true;
    this.lockPlace = true;
    this.lockContainer = false;
    this.lockMarkNo = true;
    this.lockDesc = true;
    this.lockNettCharge = true;
    this.lockGrossCharge = true;
    this.lockNettChargeConsole = true;
    this.lockGrossChargeConsole = true;
  }

  dgNosCombine(param):string{
    let result = '';
    let paramS = String(param).split(",").sort().join(",");
    console.log("param :"+paramS);
    if(paramS == 'C1'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" }]';

    }else if(paramS == 'C2'){
      result= '[{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" }]';

    }else if(paramS == 'C2F'){
      result= '[{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';

    }else if(paramS == 'C3'){
      console.log("kemarih boy")
      result= '[{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" }]';
    }else if(paramS == 'C1,C2F'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';

    }else if(paramS == 'C1,C3'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" }]';
    }else if(paramS == 'C2,C2F'){
      result= '[{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';

    }else if(paramS == 'C2,C3'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C2,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" }]';
    }else if(paramS == 'C2F,C3'){
      result= '[{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C2F,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';
    }else if(paramS == 'C1,C2,C2F'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2"},{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';

    }else if(paramS == 'C1,C2,C3'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2"},{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" }]';

    }else if(paramS == 'C1,C2F,C3'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F"},{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2F,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F"}]';

    }else if(paramS == 'C2,C2F,C3'){
      result= '[{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F",{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2F,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F"}]';

    }else if(paramS == 'C1,C3,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" }{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2,C2F,C3'){
      result= '[{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2,C2F,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" }]';

    }else if(paramS == 'C2,C2F,C3,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2,C3,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else if(paramS == 'C1,C2,C2F,C3,N'){
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" },{ "blContainerDgClassCode" : "C1", "blContainerDgClassName" : "C1" },{ "blContainerDgClassCode" : "C2", "blContainerDgClassName" : "C2" },{ "blContainerDgClassCode" : "C2F", "blContainerDgClassName" : "C2F" },{ "blContainerDgClassCode" : "C3", "blContainerDgClassName" : "C3" }]';

    }else{
      result= '[{ "blContainerDgClassCode" : "N/A", "blContainerDgClassName" : "N/A" }]';
    }
    return result;
  }

  checkUchMeasurements(): boolean {
    let result = false;

    let decimal = "";

    if (
      String(this.paramContainerExtra.blContainerUch).includes(",") ||
      String(this.paramContainerExtra.blContainerUch).includes(".")
    ) {
      decimal = String(this.paramContainerExtra.blContainerUch).split(
        /[,.]/
      )[1];
    }

    if (decimal.length > 2) {
      this.paramContainer["error-UchMeasurementsValidator"] =
        "Height only allow upto 2 decimal points";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUch > 999999) {
      this.paramContainer["error-UchMeasurementsValidator"] =
        "Height should not be greater than 999999";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUch < 0) {
      result = true;
    }

    return result;
  }

  checkUclMeasurements(): boolean {
    let result = false;

    let decimal = "";

    if (
      String(this.paramContainerExtra.blContainerUcl).includes(",") ||
      String(this.paramContainerExtra.blContainerUcl).includes(".")
    ) {
      decimal = String(this.paramContainerExtra.blContainerUcl).split(
        /[,.]/
      )[1];
    }

    if (decimal.length > 2) {
      this.paramContainer["error-UclMeasurementsValidator"] =
        "Length only allow upto 2 decimal points";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUcl > 999999) {
      this.paramContainer["error-UclMeasurementsValidator"] =
        "Length should not be greater than 999999";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUcl < 0) {
      result = true;
    }

    return result;
  }

  checkUcwMeasurements(): boolean {
    let result = false;

    let decimal = "";

    if (
      String(this.paramContainerExtra.blContainerUcw).includes(",") ||
      String(this.paramContainerExtra.blContainerUcw).includes(".")
    ) {
      decimal = String(this.paramContainerExtra.blContainerUcw).split(
        /[,.]/
      )[1];
    }

    if (decimal.length > 2) {
      this.paramContainer["error-UcwMeasurementsValidator"] =
        "Width only allow upto 2 decimal points";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUcw > 999999) {
      this.paramContainer["error-UcwMeasurementsValidator"] =
        "Width should not be greater than 999999";
      result = true;
    }

    if (this.paramContainerExtra.blContainerUcw < 0) {
      result = true;
    }

    return result;
  }

  eventBtnBlContainerSave() {
    // check for undefined value first
    if (
      this.paramContainer.blContainerQuantity == null ||
      this.paramContainer.blContainerQuantity === undefined
    ) {
      this.paramContainer.blContainerQuantity = 0;
    }
    if (
      this.paramContainer.blContainerGrossWeight == null ||
      this.paramContainer.blContainerGrossWeight === undefined
    ) {
      this.paramContainer.blContainerGrossWeight = 0;
    }
    if (
      this.paramContainer.blContainerGrossMeasure == null ||
      this.paramContainer.blContainerGrossMeasure === undefined
    ) {
      this.paramContainer.blContainerGrossMeasure = 0;
    }
    if (
      this.paramContainer.blContainerFlashPointTemperature == null ||
      this.paramContainer.blContainerFlashPointTemperature === undefined
    ) {
      this.paramContainer.blContainerFlashPointTemperature = 0.0;
    }
    if (
      this.paramContainer.blContainerReeferTemperature == null ||
      this.paramContainer.blContainerReeferTemperature === undefined
    ) {
      this.paramContainer.blContainerReeferTemperature = 0.0;
    }
    if (
      this.paramContainer.blContainerOogOH == null ||
      this.paramContainer.blContainerOogOH === undefined
    ) {
      this.paramContainer.blContainerOogOH = 0;
    }
    if (
      this.paramContainer.blContainerOogOWL == null ||
      this.paramContainer.blContainerOogOWL === undefined
    ) {
      this.paramContainer.blContainerOogOWL = 0;
    }
    if (
      this.paramContainer.blContainerOogOWR == null ||
      this.paramContainer.blContainerOogOWR === undefined
    ) {
      this.paramContainer.blContainerOogOWR = 0;
    }
    if (
      this.paramContainer.blContainerOogOLF == null ||
      this.paramContainer.blContainerOogOLF === undefined
    ) {
      this.paramContainer.blContainerOogOLF = 0;
    }
    if (
      this.paramContainer.blContainerOogOLB == null ||
      this.paramContainer.blContainerOogOLB === undefined
    ) {
      this.paramContainer.blContainerOogOLB = 0;
    }
    if (
      this.paramContainer.blContainerQ1 == null ||
      this.paramContainer.blContainerQ1 === undefined
    ) {
      this.paramContainer.blContainerQ1 = "";
    }
    if (
      this.paramContainer.blContainerQ2 == null ||
      this.paramContainer.blContainerQ2 === undefined
    ) {
      this.paramContainer.blContainerQ2 = "N";
    }
    if (
      this.paramContainerExtra.blContainerUch == null ||
      this.paramContainerExtra.blContainerUch === undefined
    ) {
      this.paramContainerExtra.blContainerUch = 0;
    }
    if (
      this.paramContainerExtra.blContainerUcw == null ||
      this.paramContainerExtra.blContainerUcw === undefined
    ) {
      this.paramContainerExtra.blContainerUcw = 0;
    }
    if (
      this.paramContainerExtra.blContainerUcl == null ||
      this.paramContainerExtra.blContainerUcl === undefined
    ) {
      this.paramContainerExtra.blContainerUcl = 0;
    }
    if (
      this.paramContainerExtra.blContainerInnerQuantity == null ||
      this.paramContainerExtra.blContainerInnerQuantity === undefined
    ) {
      this.paramContainerExtra.blContainerInnerQuantity = 0;
    }

    // yg-sbhd

    this.paramContainerExtra.blContainerBlNo =
      this.paramContainer.blContainerBlNo;
    this.paramContainerExtra.blContainerNumber =
      this.paramContainer.blContainerNumber;
    this.paramContainerExtra.blContainerOfficeCode =
      this.paramContainer.blContainerOfficeCode;
    this.paramContainerExtra.blContainerSeq =
      this.paramContainer.blContainerSeq;

    this.paramContainerExtra.blContainerCommodityTypeId   = this.tempBlContainerCommodityTypeId;
    this.paramContainerExtra.blContainerCommodityTypeName = this.tempBlContainerCommodityTypeName;
    this.paramContainerExtra.blContainerDesc              = this.tempBlContainerDesc;
    this.paramContainerExtra.blContainerQuantityLock      = this.blContainerQuantityLock ? 'Y' : 'N';
    this.paramContainerExtra.blContainerPackageTypeLock   = this.blContainerPackageTypeLock  ? 'Y' : 'N';
    this.paramContainerExtra.blContainerGrossWeightLock   = this.blContainerGrossWeightLock  ? 'Y' : 'N';
    this.paramContainerExtra.blContainerGrossMeasureLock  = this.blContainerGrossMeasureLock  ? 'Y' : 'N';
    this.paramContainerExtra.blContainerDescPrint         = this.blContainerDescPrint  ? 'Y' : 'N';
    this.paramContainerExtra.blContainerDescLock          = this.blContainerDescLock ? 'Y' : 'N';
    this.paramContainerExtra.blContainerAdPrint = this.blContainerAdPrint ? 'Y' : 'N';

    this.paramContainerExtra.blContainerVgmLock = this.blContainerVGMLock  ? 'Y' : 'N';
    this.paramContainerExtra.blContainerNettMeasureLock  = this.blContainerNettMeasureLock ? 'Y' : 'N';
    this.paramContainerExtra.blContainerNettWeightLock = this.blContainerNettWeightLock ? 'Y' : 'N';
    // this.paramContainerExtra.blContainerVgm = this.paramContainer.blContainerVgm;


    // * Add validation for UC Measurements

    this.paramContainerExtra.blContainerDesc = this.txtBlContainerDesc
    .getValue()
    .toString();

    this.paramContainer.blContainerExtra = this.paramContainerExtra;

    this.paramContainer.blContainerGrossWeightString = this.paramContainer.blContainerGrossWeightString ? this.paramContainer.blContainerGrossWeightString : 0;
    this.paramContainer.blContainerGrossWeight = this.paramContainer.blContainerGrossWeightString ? this.paramContainer.blContainerGrossWeightString : 0;
    this.paramContainer.blContainerGrossMeasureString = this.paramContainer.blContainerGrossMeasureString ? this.paramContainer.blContainerGrossMeasureString : 0;
    this.paramContainer.blContainerGrossMeasure = this.paramContainer.blContainerGrossMeasureString ? this.paramContainer.blContainerGrossMeasureString : 0;
    this.paramContainer.blContainerNettWeightString = this.paramContainer.blContainerNettWeightString ? this.paramContainer.blContainerNettWeightString : 0;
    this.paramContainer.blContainerNettWeight = this.paramContainer.blContainerNettWeightString ? this.paramContainer.blContainerNettWeightString : 0;
    this.paramContainer.blContainerNettMeasure = this.paramContainer.blContainerNettWeightString ? this.paramContainer.blContainerNettWeightString : 0;
    this.paramContainer.blContainerNettMeasureString = this.paramContainer.blContainerNettMeasureString ? this.paramContainer.blContainerNettMeasureString : 0;

    if (this.blContainerUpdateMode === "insert") {
      this.setValidatorContainerInsert();

      this.paramContainer.UchMeasurementsValidator =
        this.checkUchMeasurements();
      this.paramContainer.UclMeasurementsValidator =
        this.checkUclMeasurements();
      this.paramContainer.UcwMeasurementsValidator =
        this.checkUcwMeasurements();

      this.isErrorContainer = this.onValidate(this.paramContainer);

      /*
       if (!this.isErrorContainer) {
       if (this.paramContainer.blContainerGrossMeasure > this.paramContainer.blContainerGrossWeight) {
       this.isErrorContainer = true;
       this.paramContainer['error-checkWtMeasValidator']='Gross Meas cannot be bigger than Gross Weight.';
       } else {
       this.isErrorContainer = false;
       this.paramContainer['error-checkWtMeasValidator']='';
       }
       }
       */

      if (
        this.paramContainer.blContainerShortShipContainer === true &&
        this.paramContainer.blContainerShortShipmentFrom === true
      ) {
        if (this.paramContainer.blContainerShortShipmentBlRef === "") {
          this.isErrorContainer = true;
          this.paramContainer["error-blContainerShortshipment"] =
            "Missing Shortshipment BL Ref.";
        }
      }

      if (this.paramContainer.blContainerQ2 == "") {
        this.paramContainer.blContainerQ2 = "N";
      }

      // check duplicate
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (
            this.gridBlContainer.listStore.store[i].blContainerNumber
              .trim()
              .toUpperCase() ===
            this.paramContainer.blContainerNumber.trim().toUpperCase()
          ) {
            this.isErrorContainer = true;
            this.paramContainer["error-blContainerNumber"] =
              "Duplicate container number " +
              this.paramContainer.blContainerNumber.toUpperCase();
          }

          if (
            this.gridBlContainer.listStore.store[i].blContainerSealNo1
              .trim()
              .toUpperCase() ===
            this.paramContainer.blContainerSealNo1.trim().toUpperCase() && this.paramContainer.blContainerSealNo1
          ) {
            this.isErrorContainer = true;
            this.paramContainer["error-blContainerSeqNo"] =
              "Duplicate Seal No." +
            this.paramContainer.blContainerSealNo1.toUpperCase();
          }
        }
      }

      // to block saving when hs code not closed
      if (!this.btnBlContainerHSNew) {
        this.isErrorContainer = true;
        this.paramContainer["error-blContainerHSCodeDetection"] =
          "Please input HS Code.";
      }

      // console.log(this.paramContainer);
      // console.log('isErrorContainer: ' + this.isErrorContainer);
      if (!this.isErrorContainer) {
        // console.log(this.paramContainer);
        this.gridBlContainer.url = "";

        // assign data to blContainerExtra

        // this.paramContainer.blContainerHSCode = this.gridBlContainerHS.listStore.store;

        // if (this.gridBlContainerHS.listStore.store.length > 0) {
        // yg-sbhd
        if (this.rowDataContainerHs.length > 0) {
          this.paramContainer.blContainerHSCode = [];
          for (
            let i = 0;
            i < this.rowDataContainerHs.length;
            i++
          ) {
            this.paramContainer.blContainerHSCode.push(
              this.rowDataContainerHs[i]
            );
          }
        }

        // if (this.gridBlAdContainer.listStore.store.length > 0) {
        //   this.paramContainer.blAdContainer = [];
        //   for (
        //     let i = 0;
        //     i < this.gridBlAdContainer.listStore.store.length;
        //     i++
        //   ) {
        //     this.paramContainer.blAdContainer.push(
        //       this.gridBlAdContainer.listStore.store[i]
        //     );
        //   }
        // }

        // yg-sbhd
        if (this.rowDataADContainer.length > 0) {
          this.paramContainer.blAdContainer = [];
          for (
            let i = 0;
            i < this.rowDataADContainer.length;
            i++
          ) {
            this.paramContainer.blAdContainer.push(
              this.rowDataADContainer[i]
            );
          }
        }

        this.gridBlContainer.listStore.store.push(this.paramContainer);
        this.gridBlContainer.loadData();
        this.paramContainer = new BLContainer(); // reset param

        console.log("List Store : ", this.gridBlContainer.listStore);

        this.btnBlContainerNew = true;
        this.btnBlContainerSave = false;
        this.btnBlContainerDelete = false;
        this.btnBlContainerCancel = false;
        this.frmContainerLock = true; // to lock back container form
        this.dgClassLock = true;
        // yg-sbhd
        this.btnBlContainerHSNew = false;
        this.btnBlAdContainerNew = false;

        // reset form
        this.resetBlContainerForm();
      }
    } else if ((this.blContainerUpdateMode = "update")) {
      // reset the error flag
      this.isErrorContainer = false;
      this.paramContainer["error-blContainerNumber"] = "";
      this.paramContainer["error-blContainerShortshipment"] = "";
      this.paramContainer["error-blContainerHSCodeDetection"] = "";
      this.paramContainer["error-blContainerSeqNo"] = "";
      this.paramContainer.blContainerQ2 = "U";

      if (
        this.paramContainer.blContainerNumber == "" ||
        this.paramContainer.blContainerNumber == null
      ) {

          this.isErrorContainer = true;
          this.paramContainer["error-blContainerNumber"] =
            "Please Input Container No.";

      }

      if (
        this.paramContainer.blContainerShortShipContainer === true &&
        this.paramContainer.blContainerShortShipmentFrom === true
      ) {
        if (this.paramContainer.blContainerShortShipmentBlRef === "") {
          this.isErrorContainer = true;
          this.paramContainer["error-blContainerShortshipment"] =
            "Missing Shortshipment BL Ref.";
        }
      }

      // check duplicate
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          console.log("duplication check");
          console.log(this.paramContainer.blContainerSeq);
          console.log(this.gridBlContainer.listStore.store[i].blContainerSeq);
          if (
            this.paramContainer.blContainerSeq !==
            this.gridBlContainer.listStore.store[i].blContainerSeq
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerNumber
                .trim()
                .toUpperCase() ===
              this.paramContainer.blContainerNumber.trim().toUpperCase()
            ) {
              this.isErrorContainer = true;
              this.paramContainer["error-blContainerNumber"] =
                "Duplicate container number " +
                this.paramContainer.blContainerNumber.toUpperCase();
            }

            if (
              this.gridBlContainer.listStore.store[i].blContainerSealNo1
                .trim()
                .toUpperCase() ===
              this.paramContainer.blContainerSealNo1.trim().toUpperCase() && this.paramContainer.blContainerSealNo1
            ) {
              this.isErrorContainer = true;
              this.paramContainer["error-blContainerSeqNo"] =
                "Duplicate Seal No." +
                this.paramContainer.blContainerSealNo1.toUpperCase();
            }
          }
        }
      }

      // block the save container
      if (!this.btnBlContainerHSNew) {
        this.isErrorContainer = true;
        this.paramContainer["error-blContainerHSCodeDetection"] =
          "Please input HS Code.";
      }

      if (this.paramContainer.blContainerFlashPointTemperature !== 0.0 &&
        this.paramContainer.blContainerFlashPointScale == '' ) {
        this.isErrorContainer = true;
        this.paramContainer["error-blContainerFlashPointScale"] =
          "Please select temperature F/P";
      }else{
        this.paramContainer["error-blContainerFlashPointScale"] = "";
      }

      if (this.paramContainer.blContainerReeferTemperature !== 0.0 &&
        this.paramContainer.blContainerReeferScale == '' ) {
        this.isErrorContainer = true;
        this.paramContainer["error-blContainerReeferScale"] =
          "Please select reefer temperature";
      }else{
        this.paramContainer["error-blContainerReeferScale"] = "";
      }

      if (!this.isErrorContainer) {
        // if (this.gridBlContainerHS.listStore.store.length > 0) {
        //   this.paramContainer.blContainerHSCode = [];
        //   for (
        //     let i = 0;
        //     i < this.gridBlContainerHS.listStore.store.length;
        //     i++
        //   ) {
        //     this.paramContainer.blContainerHSCode.push(
        //       this.gridBlContainerHS.listStore.store[i]
        //     );
        //   }
        // }

        // yg-sbhd
        this.paramContainer.blContainerHSCode = [];
        if (this.rowDataContainerHs.length > 0) {
          for (
            let i = 0;
            i < this.rowDataContainerHs.length;
            i++
          ) {
            this.paramContainer.blContainerHSCode.push(
              this.rowDataContainerHs[i]
            );
          }
        }

        this.paramContainer.blAdContainer = [];
        if (this.rowDataADContainer.length > 0) {
          for (
            let i = 0;
            i < this.rowDataADContainer.length;
            i++
          ) {
            this.paramContainer.blAdContainer.push(
              this.rowDataADContainer[i]
            );
          }
        }

        // if (this.gridBlAdContainer.listStore.store.length > 0) {
        //   this.paramContainer.blAdContainer = [];
        //   for (
        //     let i = 0;
        //     i < this.gridBlAdContainer.listStore.store.length;
        //     i++
        //   ) {
        //     this.paramContainer.blAdContainer.push(
        //       this.gridBlAdContainer.listStore.store[i]
        //     );
        //   }
        // }
        // this.gridBlContainer.listStore.updateData((this.gridBlContainer.getSelectedValues()[0].blContainerSeq - 1), this.paramContainer);
        this.gridBlContainer.listStore.updateData(
          this.paramContainer.no - 1,
          this.paramContainer
        );

        this.gridBlContainer.clearSelectedValues();
        this.gridBlContainer.loadData();

        this.btnBlContainerNew = true;
        this.btnBlContainerSave = false;
        this.btnBlContainerDelete = false;
        this.btnBlContainerCancel = false;
        this.frmContainerLock = true; // to lock back container form
        this.dgClassLock = true;
        // yg-sbhd
        this.btnBlAdContainerNew = false;
        this.btnBlContainerHSNew = false;

        // reset form
        this.resetBlContainerForm();
      }
    }
    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }

    this.isRefreightButtonClicked = false;
  }

  eventBtnBlContainerDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteContainer",
      "Confirmation",
      350,
      150
    );
  }

  eventBtnBlContainerCancel() {
    this.btnBlContainerNew = true;
    this.btnBlContainerSave = false;
    this.btnBlContainerDelete = false;
    this.btnBlContainerCancel = false;
    this.frmContainerLock = true; // to lock back container form
    // yg-sbhd
    this.blContainerQuantityLock = true;
    this.blContainerPackageTypeLock = true;
    this.blContainerGrossWeightLock = true;
    this.blContainerGrossMeasureLock = true;
    this.blContainerVGMLock = true;
    this.blContainerDescPrint = false;
    this.blContainerDescLock = false;
    this.blContainerAdPrint = false;
    this.blContainerNettWeightLock = true;
    this.blContainerNettMeasureLock = true;
    this.dgClassLock = true;
    this.blContainerUpdateMode = "insert";
    this.txtBlContainerSaveUpdate = "Save";

    this.isErrorContainer = false;

    this.paramContainer = new BLContainer();
    this.paramContainerExtra = new BLContainerExtra();
    // yg-sbhd
    this.paramContainerHSCode = new BLContainerHSCode();
    this.paramBlAdContainer = new BLAdContainer();

    this.cbBlContainerPackageType.setValue("");
    // this.cbBlContainerInnerPkgType.setValue("");
    this.cbBlContainerContainerType.setValue("");
    this.cbBlContainerContainerSize.setValue("");
    this.cbBlContainerContainerHeight.setValue("");
    this.cbBlContainerOogScale.setValue("");
    this.cbBlContainerCommodity.setValue(""); // yg-sbhd
    this.cbBlContainerContainerDGClass.setValue("");
    this.cbBlContainerFlashPointScale.setValue("");
    this.cbBlContainerReeferScale.setValue("");
    this.cbBlContainerShortShipmentVessel.setValue("");
    this.cbBlContainerShortShipmentVoyage.setValue("");
    this.txtBlContainerDesc.setValue("");

    // release the tab
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockNettChargeConsole = false;

    // yg-sbhd
    this.disableBlContainerQuantityLock = true;
    this.disableBlContainerVGMLock = true;
    this.disableBlContainerPackageTypeLock = true;
    this.disableBlContainerGrossWeightLock = true;
    this.disableBlContainerGrossMeasureLock = true;
    this.disableBlContainerDescPrint = true;
    this.disableBlContainerDescLock = true;
    this.disableBlContainerAdPrint = true;
    this.disableBlContainerNettWeightLock = true;
    this.disableBlContainerNettMeasureLock = true;

    // reset the HS Code just in case
    this.btnBlContainerHSNew = false;
    this.btnBlContainerHSCancel = false;
    this.btnBlContainerHSSave = false;
    this.btnBlContainerHSDelete = false;
    // this.gridBlContainerHS.onClear();
    this.isErrorHSCode = false;
    // this.paramContainerHSCode = new BLContainerHSCode();
    this.cbBlContainerHS.setValue("");

     // reset the Additional Details Code just in case
     this.btnBlAdContainerNew = false;
     this.btnBlAdContainerCancel = false;
     this.btnBlAdContainerSave = false;
     this.btnBlAdContainerDelete = false;
    //  this.gridBlAdContainer.onClear();
     this.isErrorAdCode = false;

    this.lockGrossCharge = false;
    // yg-sbhd
    this.rowDataADContainer = [];
    this.rowDataContainerHs = [];

    //GSO-992 Logic for "Reworked Container"
    this.isReworkedChecked = false;
    this.isDisabledReworked = true;
    this.cbBlContainerReworkedVessel.setValue("");
    this.cbBlContainerReworkedVoyage.setValue("");

    // console.log("this.model.blstatus === > " + this.model.blStatus);
    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }

  //cek view only
    if (this.viewOnlyStatus){
      console.log("masuk cancel container");
      this.disableToolbarButtons =
          "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
      this.btnBlContainerNew = false;
      // yg-sbhd
      this.btnBlContainerHSNew = false;
      this.btnBlAdContainerNew = false;
    }else{
      if (this.blLock == "N") {
        // When the status is cleared or locked, only view
        if(this.model.blStatus === "D" || this.isOpenedByOtherUser === true){
          this.lockTab3 = true;
        }else{
          this.lockTab3 = false;
        }
      } else if (this.blLock == "Y") {
        this.disableToolbarButtons =
          "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
        this.lockTab3 = true;
      } else if (this.blLock == "T1") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T2") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T3") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T4") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T5") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T6") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T7") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      } else if (this.blLock == "T8") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
        this.lockTab3 = true;
      }
    }

  }

  chBlContainerSkipCheckBlContainerNo(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerSkipCheck = true;
    } else {
      this.paramContainer.blContainerSkipCheck = false;
    }
  }

  changeEventCbBlContainerPackageType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramContainer.blContainerPackageTypeCode = event.packageTypeCode;
      this.paramContainer.blContainerPackageTypeName = event.packageTypeName;
    } else {
      this.paramContainer.blContainerPackageTypeCode = "";
      this.paramContainer.blContainerPackageTypeName = "";
    }
    this.cbBlContainerPackageType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlContainerReceiptType(event) {
    // console.log('******RT*******')
    // console.log(event);
    if (
      event.receiptTypeCode != null &&
      event.receiptTypeCode !== "" &&
      event.receiptTypeCode !== undefined
    ) {
      this.paramContainer.blContainerReceiptTypeCode = event.receiptTypeCode;
    } else {
      this.paramContainer.blContainerReceiptTypeCode = "";
    }
    this.cbBlContainerReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}"
    );
  }

  changeEventCbBlContainerDeliveryType(event) {
    if (
      event.deliveryTypeId != null &&
      event.deliveryTypeId !== "" &&
      event.deliveryTypeId !== undefined
    ) {
      this.paramContainer.blContainerDeliveryTypeCode = event.deliveryTypeCode;
    } else {
      this.paramContainer.blContainerDeliveryTypeCode = "";
    }
    this.cbBlContainerDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeLike={query}"
    );
  }

  changeEventGrossWeight(event){

    //this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight; //original

//if ((this.site!=="CNSHA") || ((this.site==="CNSHA") && (this.blContainerVGMLock === false)))
if ((!this.site.startsWith("CN")) || ((this.site.startsWith("CN")) && (this.blContainerVGMLock === false))) //added condition for VGM calculation added by No No 6/12/2024, change to "CN" during implemantion 9/12/2024
    {
      this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;

    }

  }

  changeEventCbBlContainerContainerType(event) {
    if (
      event.blContainerTypeCode != null &&
      event.blContainerTypeCode !== "" &&
      event.blContainerTypeCode !== undefined
    ) {
      if (
        this.paramContainer.blContainerIsDry === true &&
        event.blContainerTypeCode === "REF"
      ) {
        this.paramContainer.blContainerType = "DRY";
        this.paramContainer.blContainerTypeName = "REEFER";
      } else {
        this.paramContainer.blContainerType = event.blContainerTypeCode;
        this.paramContainer.blContainerTypeName = event.blContainerTypeName;
      }
      console.log("this.blContainerVGMLock:",this.blContainerVGMLock);
      if((this.site.startsWith("CN")) && (this.blContainerVGMLock === false))  //added for VGM calculation by No No on 6/12/24
        {
          //const paramsContainer = {"type": this.paramContainer.blContainerType ,"size":this.paramContainer.blContainerSize,"height":this.paramContainer.blContainerHeight }
          //console.log("container type:" ,paramsContainer );
          this.genericService.GET(this.configService.config.BASE_API.toString() +"/shaBL/getTareWeight" +
          "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight )

          .subscribe((resp) => {
              this.valTareWeight = Math.floor(resp._body);
              this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
            });
        }
      else if ((!this.site.startsWith("CN")) )
        {
            this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/LocalMasterTareWeight/getTareWeightKGS/" +
                this.officeCode + "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight
            )
            .subscribe((resp) => {
              this.valTareWeight = Math.floor(resp.json()['value']);
              this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
            });
        }
    }
  }

  changeEventCbBlContainerContainerSize(event) {
    if (
      event.blContainerSizeCode != null &&
      event.blContainerSizeCode !== "" &&
      event.blContainerSizeCode !== undefined
    ) {
      this.paramContainer.blContainerSize = event.blContainerSizeCode;
      this.paramContainer.blContainerSizeName = event.blContainerSizeName;

      if((this.site.startsWith("CN")) && (this.blContainerVGMLock === false))  //added for VGM calculation by No No on 6/12/24
        {
          this.genericService.GET(this.configService.config.BASE_API.toString() +"/shaBL/getTareWeight" +
          "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight )

          .subscribe((resp) => {
              this.valTareWeight = Math.floor(resp._body);
              this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
            });
        }
    else if ((!this.site.startsWith("CN")))
        {
          this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/LocalMasterTareWeight/getTareWeightKGS/" +
              this.officeCode + "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight
          )
          .subscribe((resp) => {
            this.valTareWeight = Math.floor(resp.json()['value']);
            this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
          });
      }
    }
  }

  changeEventCbBlContainerContainerHeight(event) {
    if (
      event.blContainerHeightCode != null &&
      event.blContainerHeightCode !== "" &&
      event.blContainerHeightCode !== undefined
    ) {
      this.paramContainer.blContainerHeight = event.blContainerHeightCode;
      this.paramContainer.blContainerHeightName = event.blContainerHeightName;

      if((this.site.startsWith("CN")) && (this.blContainerVGMLock === false))  //added for VGM calculation by No No on 6/12/24
        {
          this.genericService.GET(this.configService.config.BASE_API.toString() +"/shaBL/getTareWeight" +
          "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight )

            .subscribe((resp) => {
              this.valTareWeight = Math.floor(resp._body);
              this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
            });
        }
    else if ((!this.site.startsWith("CN")) )
        {
        this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/LocalMasterTareWeight/getTareWeightKGS/" +
            this.officeCode + "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight
        )
        .subscribe((resp) => {
          this.valTareWeight = Math.floor(resp.json()['value']);
          this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
        });
      }
    }
  }

  changeEventCbBlContainerContainerDgClass(event) {
    if (
      event.blContainerDgClassCode != null &&
      event.blContainerDgClassCode !== "" &&
      event.blContainerDgClassCode !== undefined
    ) {
      this.paramContainer.blContainerDGClass = event.blContainerDgClassCode;
    }
  }

  // yg-sbhd
  eventClickRowAdContainer(event){
    console.log(event);
  }

  changeEventCbBlAdContainerDgClass(event) {
    if (
      event.blContainerDgClassCode != null &&
      event.blContainerDgClassCode !== "" &&
      event.blContainerDgClassCode !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerDGClassCode = event.blContainerDgClassCode;
      this.paramBlAdContainer.blAdContainerDGClassName = event.blContainerDgClassName;
    }else{
      this.paramBlAdContainer.blAdContainerDGClassCode = "";
      this.paramBlAdContainer.blAdContainerDGClassName = "";
    }
  }

  changeEventBlAdContainerFlashPointScale(event) {
    if (
      event.flashPointScaleCode != null &&
      event.flashPointScaleCode !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerFlashPointScale =
        event.flashPointScaleCode;
    }
  }

  changeEventBlContainerQuantityLock(val) {
    if(val){
      this.blContainerQuantityLock = true;
      // this.paramContainerExtra.blContainerQuantityLock = "Y";
    }else{
      this.blContainerQuantityLock = false;
      // this.paramContainerExtra.blContainerQuantityLock = "N";
      if(this.rowDataADContainer.length > 0){
        let temporaryTotalInnerQty = 0;
        for (let i = 0; i < this.rowDataADContainer.length; i++) {
          temporaryTotalInnerQty = temporaryTotalInnerQty + Number.parseFloat(
            this.rowDataADContainer[i].blAdContainerInnerQty
          )
        }
        this.paramContainer.blContainerQuantity = temporaryTotalInnerQty;
      }else{
        this.paramContainer.blContainerQuantity = 0;
      }
    }
  }

  changeEventBlContainerPackageTypeLock(val) {
    if(val){
      this.blContainerPackageTypeLock = true;
      // this.paramContainerExtra.blContainerPackageTypeLock = "Y";
    }else{
      this.blContainerPackageTypeLock = false;
      // this.paramContainerExtra.blContainerPackageTypeLock = "N";
      if(this.rowDataADContainer.length > 0){
        let temporaryCountInnerPkgType = 0;
        let temporaryInnerPkgTypeCode = '';
        let temporaryInnerPkgTypeName = '';
        let temporaryInnerPkgTypeArray = [];
        const allEqual = arr => arr.every( v => v === arr[0] )

        for (let i = 0; i < this.rowDataADContainer.length; i++) {
          if(this.rowDataADContainer.blAdContainerInnerPkgTypeCode !== ""){
            temporaryCountInnerPkgType = temporaryCountInnerPkgType + 1;
            temporaryInnerPkgTypeCode = this.rowDataADContainer[i].blAdContainerInnerPkgTypeCode;
            temporaryInnerPkgTypeName = this.rowDataADContainer[i].blAdContainerInnerPkgTypeName;
            temporaryInnerPkgTypeArray.push(temporaryInnerPkgTypeCode);

          }
        }

        if(temporaryCountInnerPkgType <= 0) {
          this.cbBlContainerPackageType.clearSelect();
        }else if(temporaryCountInnerPkgType == 1){
          temporaryInnerPkgTypeCode    = temporaryInnerPkgTypeCode;
          temporaryInnerPkgTypeName    = temporaryInnerPkgTypeName;
          this.paramContainer.blContainerPackageTypeCode = temporaryInnerPkgTypeCode;
          this.paramContainer.blContainerPackageTypeName = temporaryInnerPkgTypeName;
          this.cbBlContainerPackageType.setForceValue(temporaryInnerPkgTypeName);
        }else{
          if(allEqual(temporaryInnerPkgTypeArray)){
            temporaryInnerPkgTypeCode    = temporaryInnerPkgTypeCode;
            temporaryInnerPkgTypeName    = temporaryInnerPkgTypeName;
            this.paramContainer.blContainerPackageTypeCode = temporaryInnerPkgTypeCode;
            this.paramContainer.blContainerPackageTypeName = temporaryInnerPkgTypeName;
            this.cbBlContainerPackageType.setForceValue(temporaryInnerPkgTypeName);
          }else{
            temporaryInnerPkgTypeCode    = "PKGS";
            temporaryInnerPkgTypeName    = "PACKAGES";
            this.paramContainer.blContainerPackageTypeCode = temporaryInnerPkgTypeCode;
            this.paramContainer.blContainerPackageTypeName = temporaryInnerPkgTypeName;
            this.cbBlContainerPackageType.setForceValue('PACKAGES');
          }
        }
      }else{
        this.paramContainer.blContainerPackageTypeCode = "";
        this.paramContainer.blContainerPackageTypeName = "";
        this.cbBlContainerPackageType.setForceValue("");
      }
    }
  }

  changeEventBlContainerGrossWeightLock(val) {
    if(val){
      this.blContainerGrossWeightLock = true;
      // this.paramContainerExtra.blContainerGrossWeightLock = "Y";
    }else{
      this.blContainerGrossWeightLock = false;
      // this.paramContainerExtra.blContainerGrossWeightLock = "N";

      if(this.rowDataADContainer.length > 0){
        this.calculateGrossWeightAdditionalWhenUnLock();
        this.paramContainer.blContainerGrossWeight = this.temporaryBlAdContainerAdditionalGrossWeight;
        this.paramContainer.blContainerGrossWeightString = this.blContainerGrossWeightAdditionalString;
      }else{
        this.paramContainer.blContainerGrossWeight = 0;
        this.paramContainer.blContainerGrossWeightString = '0';
      }
    }
  }

  changeEventBlContainerNettWeightLock(e) {
    if(e){
      this.blContainerNettWeightLock = true;
    }else {
      this.blContainerNettWeightLock = false;
    }
  }

  changeEventBlContainerNettMeasurementLock(e) {
    if(e){
      this.blContainerNettMeasureLock = true;
    }else{
      this.blContainerNettMeasureLock = false;
    }
  }

  calculateGrossWeightAdditionalWhenUnLock(){
    this.temporaryBlAdContainerAdditionalGrossWeight = 0;
    this.blContainerGrossWeightAdditionalString = '0';
    let blCtnTotalLength = 0;
    let blContainerGrossWeightList = this.rowDataADContainer.map(x => x.blAdContainerGrossWeightString);
    const lengthAfterTheDotElement = blContainerGrossWeightList.map(item=>{
      return item.includes('.') ? item.split('.')[1].length : [0];
    })
    blCtnTotalLength =  Math.max(...lengthAfterTheDotElement);
    let isDecimalElementGrossWeightList = blContainerGrossWeightList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.rowDataADContainer.length; i++) {
      this.temporaryBlAdContainerAdditionalGrossWeight = this.temporaryBlAdContainerAdditionalGrossWeight + Number.parseFloat(this.rowDataADContainer[i].blAdContainerGrossWeight)
    }
    let totalGross = this.parseDecimal(this.temporaryBlAdContainerAdditionalGrossWeight);
    if (isDecimalElementGrossWeightList === true) {
      this.blContainerGrossWeightAdditionalString = this.temporaryBlAdContainerAdditionalGrossWeight.toString();
    } else {
      if (this.temporaryBlAdContainerAdditionalGrossWeight === 0) {
        this.blContainerGrossWeightAdditionalString = "0";
      } else if (totalGross.toString().includes(".") === true) {
        let totalGrossDecimalLength = totalGross.toString().split(".")[1].length;
        if (blCtnTotalLength > totalGrossDecimalLength) {
          this.blContainerGrossWeightAdditionalString = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.blContainerGrossWeightAdditionalString = totalGross;
        }
      } else if (totalGross.toString().includes(".") === false) {
        if (blCtnTotalLength > 1) {
          this.blContainerGrossWeightAdditionalString = Number(this.temporaryBlAdContainerAdditionalGrossWeight).toFixed(blCtnTotalLength);
        } else {
          this.blContainerGrossWeightAdditionalString = String(this.temporaryBlAdContainerAdditionalGrossWeight.toString() + '.0')
        }
      }
    }
  }

  changeEventBlContainerGrossMeasureLock(val) {
    if(val){
      this.blContainerGrossMeasureLock = true;
      // this.paramContainerExtra.blContainerGrossMeasureLock = "Y";
    }else{
      this.blContainerGrossMeasureLock = false;
      // this.paramContainerExtra.blContainerGrossMeasureLock = "N";

      if(this.rowDataADContainer.length > 0){
        this.calculateGrossMeasureAdditionalWhenUnLock();
        this.paramContainer.blContainerGrossMeasure = this.temporaryBlAdContainerAdditionalGrossMeasure;
        this.paramContainer.blContainerGrossMeasureString = this.blContainerGrossMeasureAdditionalString;
      }else{
        this.paramContainer.blContainerGrossMeasure = 0;
        this.paramContainer.blContainerGrossMeasureString = '0';
      }
    }
  }

  changeEventBlContainerVGMLock(val) {
    if(val) {
      this.blContainerVGMLock = true;
    }else{
      this.blContainerVGMLock = false;

      //need to recalculate
      if(this.site.startsWith("CN")) {
        this.genericService.GET(this.configService.config.BASE_API.toString() +"/shaBL/getTareWeight" +
          "/" + this.paramContainer.blContainerType + "/" + this.paramContainer.blContainerSize + "/" + this.paramContainer.blContainerHeight )

          .subscribe((resp) => {
            this.valTareWeight = Math.floor(resp._body);
            this.paramContainer['blContainerVgm'] = Number(this.paramContainer.blContainerGrossWeightString) + this.valTareWeight;
          });
      }

    }
  }

  calculateVgm(){
    this.totalVGM = 0;
    this.gridBlContainer.listStore.store.forEach(num => {
      this.totalVGM += Number(num.blContainerVgm);
    })
  }

  calculateGrossMeasureAdditionalWhenUnLock(){
    this.temporaryBlAdContainerAdditionalGrossMeasure = 0;
    this.blContainerGrossMeasureAdditionalString = "0";
    let blCtnTotalLength = 0;
    let blContainerGrossMeasureList = this.rowDataADContainer.map(x => x.blAdContainerGrossMeasureString);
    const lengthAfterTheDotElement = blContainerGrossMeasureList.map(item=>{
      return item.includes('.') ? item.split('.')[1].length : [0];
    })
    blCtnTotalLength = Math.max(...lengthAfterTheDotElement);
    let isDecimalElementGrossMeasureList = blContainerGrossMeasureList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.rowDataADContainer.length; i++) {
      this.temporaryBlAdContainerAdditionalGrossMeasure = this.temporaryBlAdContainerAdditionalGrossMeasure + Number.parseFloat(this.rowDataADContainer[i].blAdContainerGrossMeasure)
    }
    let totalGross = this.parseDecimal(this.temporaryBlAdContainerAdditionalGrossMeasure);
    if (isDecimalElementGrossMeasureList === true) {
      this.blContainerGrossMeasureAdditionalString = this.temporaryBlAdContainerAdditionalGrossMeasure.toString();
    } else {
      if (this.temporaryBlAdContainerAdditionalGrossMeasure === 0) {
        this.blContainerGrossMeasureAdditionalString = "0";
      } else if (totalGross.toString().includes(".") === true) {
        let totalGrossDecimalLength = totalGross.toString().split(".")[1].length;
        if (blCtnTotalLength > totalGrossDecimalLength) {
          this.blContainerGrossMeasureAdditionalString = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.blContainerGrossMeasureAdditionalString = totalGross;
        }
      } else if (totalGross.toString().includes(".") === false) {
        if (blCtnTotalLength > 1) {
          this.blContainerGrossMeasureAdditionalString = Number(this.temporaryBlAdContainerAdditionalGrossMeasure).toFixed(blCtnTotalLength);
        } else {
          this.blContainerGrossMeasureAdditionalString = String(this.temporaryBlAdContainerAdditionalGrossMeasure.toString() + '.0')
        }
      }
    }
  }

  changeEventBlContainerCheckbox(name, val) {
    switch (name) {
      case "blContainerDescPrint":
        if(val){
          this.blContainerDescPrint = true;
          // this.paramContainerExtra.blContainerDescPrint = "Y";
        }else{
          this.blContainerDescPrint = false;
          // this.paramContainerExtra.blContainerDescPrint = "N";
        }
        break;
      case "blContainerDescLock":
        if(val){
          this.blContainerDescLock = true;
          // this.paramContainerExtra.blContainerDescLock = "Y";
        }else{
          this.blContainerDescLock = false;
          // this.paramContainerExtra.blContainerDescLock = "N";
          this.blBlAdContainerAutoUpdateHS();
        }
        break;
      case "blContainerAdPrint":
        if(val){
          this.blContainerAdPrint = true;
          // this.paramContainerExtra.blContainerAdPrint = "Y";
        }else{
          this.blContainerAdPrint = false;
          // this.paramContainerExtra.blContainerAdPrint = "N";
        }
        break;
      default:
        break;
    }
  }


  // end yg-sbhd

  // HS Code Portion

  gridEventBlContainerHS(event) {
    switch (event.type) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event.type;
        if (strEvent !== "") {
          // const arr: string[] = strEvent.split("-");
          // switch (arr[0]) {
          switch(strEvent){
            case "edit":
              break;
            case "rowDoubleClicked":
              if (this.btnBlContainerHSCancel !== true) {
                this.setHSDialogOtion();
                this.showDialog("hsDialog", this.hsDialogOpt);

                this.frmContainerHSLock = false;
                this.blContainerHSUpdateMode = "update";
                this.txtBlContainerHSSaveUpdate = "Update";
                this.btnBlContainerHSNew = false;
                this.btnBlContainerHSSave = true;
                this.btnBlContainerHSDelete = true;
                this.btnBlContainerHSCancel = true;
                this.btnBlContainerNew = false;

                this.paramContainerHSCode = new BLContainerHSCode();
                this.paramContainerHSCode.blContainerHSOfficeCode       = event.data.blContainerHSOfficeCode;
                this.paramContainerHSCode.blContainerHSBlNo             = event.data.blContainerHSBlNo;
                this.paramContainerHSCode.blContainerHSSeq              = event.data.blContainerHSSeq;
                this.paramContainerHSCode.blContainerHSContainerNumber  = event.data.blContainerHSContainerNumber;
                this.paramContainerHSCode.blContainerHSSeq              = event.data.blContainerHSSeq;
                this.paramContainerHSCode.blContainerHSCode             = event.data.blContainerHSCode;
                this.paramContainerHSCode.blContainerHSDescription      = event.data.blContainerHSDescription;
                this.paramContainerHSCode.blContainerHSPrint            = event.data.blContainerHSPrint;
                this.paramContainerHSCode.blContainerHSPrintString      = event.data.blContainerHSPrintString;




                // set combo
                this.cbBlContainerHS.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterHSCodes/findByComboBoxControl/hsCode={query}"
                );
                this.cbBlContainerHS.setValue(
                  this.paramContainerHSCode.blContainerHSCode
                );
              }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGridBlContainerHS(event) {}

  setHSDialogOtion(){
    this.hsDialogOpt = {
      title: "Harmonised System Dialog",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      width: 900,
      height: "auto",
      open: function (event, ui) {
        //hide close button.
        //// console.log($(this).css("z-index","102"));
        $(".ui-dialog").css("z-index", 103);
        $(".ui-widget-overlay").css("z-index", 102);
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
        $("body").first().css("overflow", "hidden");
      },
      close: function (event, ui) {
        $("body").first().css("overflow", " auto");
      },
    };
  }

  // yg-sbhd
  blBlContainerHSNew() {
    this.setHSDialogOtion();
    this.showDialog("hsDialog", this.hsDialogOpt);
    this.frmContainerHSLock = false;
    this.blContainerHSUpdateMode = "insert";
    this.txtBlContainerHSSaveUpdate = "Save";
    this.paramContainerHSCode = new BLContainerHSCode();
    // this.paramContainerHSCode.blContainerHSSeq = this.gridBlContainerHS.listStore.store.length + 1;
    this.paramContainerHSCode.blContainerHSSeq = this.rowDataContainerHs.length === 0 ? 1 : this.rowDataContainerHs.length + 1;

    this.btnBlContainerHSNew = false;
    this.btnBlContainerHSSave = true;
    this.btnBlContainerHSDelete = false;
    this.btnBlContainerHSCancel = true;
    this.btnBlContainerNew = false;
  }


  // blBlContainerHSNew() {
  //   this.setHSDialogOtion();
  //   this.showDialog("hsDialog", this.hsDialogOpt);
  //   this.frmContainerHSLock = false;
  //   this.blContainerHSUpdateMode = "insert";
  //   this.txtBlContainerHSSaveUpdate = "Save";
  //   this.paramContainerHSCode = new BLContainerHSCode();
  //   this.paramContainerHSCode.blContainerHSSeq =
  //     this.gridBlContainerHS.listStore.store.length + 1;
  //   this.btnBlContainerHSNew = false;
  //   this.btnBlContainerHSSave = true;
  //   this.btnBlContainerHSDelete = false;
  //   this.btnBlContainerHSCancel = true;
  //   this.btnBlContainerNew = false;
  // }

  blBlContainerHSUpdate() {
    this.isErrorHSCode = false;
    this.paramContainerHSCode["error-blHSCode"] = "";

    if (this.paramContainerHSCode.blContainerHSDescription === "") {
      this.isErrorHSCode = true;
      this.paramContainerHSCode["error-blHSCode"] =
        "Please input HS Description.";
    }

    if (!this.isErrorHSCode) {
      this.paramContainerHSCode.blContainerHSOfficeCode =
        this.paramContainer.blContainerOfficeCode;
      this.paramContainerHSCode.blContainerHSBlNo =
        this.paramContainer.blContainerBlNo;
      this.paramContainerHSCode.blContainerHSContainerSeq =
        this.paramContainer.blContainerSeq;
      this.paramContainerHSCode.blContainerHSContainerNumber =
        this.paramContainer.blContainerNumber;

      if (this.blContainerHSUpdateMode === "insert") {
        // this.gridBlContainerHS.url = "";
        // this.gridBlContainerHS.listStore.store.push(this.paramContainerHSCode);
        // this.gridBlContainerHS.loadData();
        // this.paramContainerHSCode = new BLContainerHSCode();
        // should add lock

        let newRowDataHs = this.rowDataContainerHs.slice();
        newRowDataHs.push(this.paramContainerHSCode);
        this.rowDataContainerHs = newRowDataHs;


        this.blBlContainerHSCancel();
        this.btnBlContainerHSNew = true;
        this.btnBlContainerHSSave = false;
        this.btnBlContainerHSDelete = false;
        this.btnBlContainerHSCancel = false;
        this.frmContainerHSLock = true;
      } else {
        // this.gridBlContainerHS.listStore.updateData(
        //   this.paramContainerHSCode.blContainerHSSeq - 1,
        //   this.paramContainerHSCode
        // );

        let newRowDataHs = this.rowDataContainerHs.slice();
        newRowDataHs.map((item, i) => {
          if(item.blContainerHSSeq == this.paramContainerHSCode.blContainerHSSeq){
            newRowDataHs[i] = this.paramContainerHSCode;
          }
        })

      this.rowDataContainerHs = newRowDataHs;

        // this.gridBlContainerHS.clearSelectedValues();
        // this.gridBlContainerHS.loadData();
        this.blBlContainerHSCancel();
        this.btnBlContainerHSNew = true;
        this.btnBlContainerHSSave = false;
        this.btnBlContainerHSDelete = false;
        this.btnBlContainerHSCancel = false;
        this.frmContainerHSLock = true;
      }
    }
  }

  blBlContainerHSDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteContainerHS",
      "Confirmation",
      350,
      150
    );
  }

  // blBlContainerHSCancel() {
  //   this.blContainerHSUpdateMode = "insert";
  //   this.txtBlContainerHSSaveUpdate = "Save";
  //   this.isErrorHSCode = false;
  //   this.paramContainerHSCode = new BLContainerHSCode();
  //   this.cbBlContainerHS.setValue("");
  //   this.btnBlContainerHSNew = true;
  //   this.btnBlContainerHSSave = false;
  //   this.btnBlContainerHSDelete = false;
  //   this.btnBlContainerHSCancel = false;
  //   this.frmContainerHSLock = true;
  //   this.closeDialog("hsDialog");
  // }

  blBlContainerHSCancel() {
    this.blContainerHSUpdateMode = "insert";
    this.txtBlContainerHSSaveUpdate = "Save";
    this.isErrorHSCode = false;
    this.paramContainerHSCode = new BLContainerHSCode();
    this.cbBlContainerHS.setValue("");
    this.btnBlContainerHSNew = true;
    this.btnBlContainerHSSave = false;
    this.btnBlContainerHSDelete = false;
    this.btnBlContainerHSCancel = false;
    this.frmContainerHSLock = true;
    this.closeDialog("hsDialog");
  }

  changeEventCbBlContainerHS(event) {
    if (
      event.hsCode != null &&
      event.hsCode !== "" &&
      event.hsCode !== undefined
    ) {
      this.paramContainerHSCode.blContainerHSCode = event.hsCode;
      this.paramContainerHSCode.blContainerHSDescription = event.hsDescription;
    } else {
      this.paramContainerHSCode.blContainerHSCode = "";
      this.paramContainerHSCode.blContainerHSDescription = "";
    }
    this.cbBlContainerHS.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}"
    );
  }

  changeEventCbBlAdContainerHsCode(event) {
    if (
      event.hsCode != null &&
      event.hsCode !== "" &&
      event.hsCode !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerHsCode = event.hsCode;
      this.paramBlAdContainer.blAdContainerHsName = event.hsDescription;
    } else {
      this.paramBlAdContainer.blAdContainerHsCode = "";
      this.paramBlAdContainer.blAdContainerHsName = "";
    }
    this.cbBlAdContainerHsCode.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}"
    );
    this.cbBlAdContainerHsName.setForceValue(this.paramBlAdContainer.blAdContainerHsName);
  }

  changeEventCbBlAdContainerHsName(event) {
    if (
      event.hsCode != null &&
      event.hsCode !== "" &&
      event.hsCode !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerHsCode = event.hsCode;
      this.paramBlAdContainer.blAdContainerHsName = event.hsDescription;
    } else {
      this.paramBlAdContainer.blAdContainerHsCode = "";
      this.paramBlAdContainer.blAdContainerHsName = "";
    }
    this.cbBlAdContainerHsName.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}"
    );
    this.cbBlAdContainerHsCode.setForceValue(this.paramBlAdContainer.blAdContainerHsCode);
  }

  changeEventChBlContainerHSPrint(event) {
    if (event.target.checked) {
      this.paramContainerHSCode.blContainerHSPrint = true;
      this.paramContainerHSCode.blContainerHSPrintString = "Y";
    } else {
      this.paramContainerHSCode.blContainerHSPrint = false;
      this.paramContainerHSCode.blContainerHSPrintString = "N";
    }
  }
  // end yg-sbhd
  // End of HS Code Portion

  // additional container detail
  gridEventBlAdContainer(event) {
    switch (event.type) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event.type;
        if (strEvent !== "") {
          // const arr: string[] = strEvent.split("-");
          // switch (arr[0]) {
          switch(strEvent){
            case "edit":
              break;
            case "rowDoubleClicked":
              console.log("--DB CLICK");
              if(this.btnBlAdContainerCancel !== true) {
                this.setValidatorAdContainer();
                this.setValidatorAdContainerGrossWeight();
                this.setValidatorAdContainerGrossMeasure();
                this.setValidatorAdContainerFlashPointScale();

                this.setAdDialogOption();
                this.showDialog("blBLAdContainerAddDialog", this.adDialogOpt);

                this.blAdContainerUpdateMode    = "update";
                this.txtBlAdContainerSaveUpdate = "Update";
                if (
                  this.blLock == "T7" ||
                  this.blLock == "T6" ||
                  this.blLock == "T4" ||
                  this.blLock == "T8" ||
                  this.blLock == "T2" ||
                  this.blLock == "T1" ||
                  this.blLock == "Y"
                ) {
                  this.btnBlAdContainerSave = false;
                  this.btnBlAdContainerDelete = false;
                  this.btnBlAdContainerNew = false;
                }else{
                  this.btnBlAdContainerSave = true;
                  this.btnBlAdContainerDelete = true;
                  this.btnBlAdContainerNew = true;
                }
                this.btnBlAdContainerCancel = true;
                this.btnBlContainerNew = false;
                this.frmAdContainerLock = false;
                this.paramBlAdContainer = new BLAdContainer();

                /*
                UPDATE GOS-59 6-DEC-2022 OFF
                if(this.model.blIsCombine == "Y"){
                  if(this.model.blDgNos.length > 0) {
                    this.paramBlAdContainer.blAdContainerDGClassCode = this.model.blDgNos;
                    this.paramBlAdContainer.blAdContainerDGClassName = this.model.blDgNos;
                  }

                  // this.cbBlAdContainerDGClass.clearSelect();
                  // this.cbBlAdContainerDGClass.setData(this.dgNosCombine(this.model.blDgNos));
                }else{
                  if(this.model.blDgClass != '' && this.model.blDgClass != null){
                    if(this.model.blDgClass == "N"){
                      this.paramBlAdContainer.blAdContainerDGClassName = "N/A";
                      this.paramBlAdContainer.blAdContainerDGClassCode = "N/A";
                    }else{
                      this.paramBlAdContainer.blAdContainerDGClassName = this.model.blDgClass;
                      this.paramBlAdContainer.blAdContainerDGClassCode = this.model.blDgClass;
                    }
                    // this.cbBlAdContainerDGClass.setData(this.dgNosCombine(this.model.blDgClass));
                    // this.cbBlAdContainerDGClass.setValue(this.paramBlAdContainer.blAdContainerDGClassCode);
                  } else {
                    this.paramBlAdContainer.blAdContainerDGClassName = "N/A";
                    this.paramBlAdContainer.blAdContainerDGClassCode = "N/A";
                    // this.cbBlAdContainerDGClass.setData(this.dgNos);
                    // this.cbBlAdContainerDGClass.setValue(this.paramBlAdContainer.blAdContainerDGClassCode);
                  }
                }
                */

                // console.log("----before", this.paramBlAdContainer);
                // this.paramBlAdContainer.blAdContainerSeq                = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerSeq;
                this.paramBlAdContainer.blAdContainerSeq                = event.data.blAdContainerSeq;
                // this.paramBlAdContainer.blAdContainerCommodityTypeId    = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerCommodityTypeId;
                this.paramBlAdContainer.blAdContainerCommodityTypeId    = event.data.blAdContainerCommodityTypeId;

                // this.paramBlAdContainer.blAdContainerCommodityTypeName  = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerCommodityTypeName;
                this.paramBlAdContainer.blAdContainerInnerPkgTypeCode   = event.data.blAdContainerInnerPkgTypeCode; //event.data.blAdContainerInnerPkgTypeName;
                // this.paramBlAdContainer.blAdContainerInnerPkgTypeName   = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerInnerPkgTypeName;
                // this.paramBlAdContainer.blAdContainerOuterPkgTypeCode   = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerOuterPkgTypeName;
                // this.paramBlAdContainer.blAdContainerOuterPkgTypeName   = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerOuterPkgTypeName;
                this.paramBlAdContainer.blAdContainerInnerQty           = event.data.blAdContainerInnerQty;
                // this.paramBlAdContainer.blAdContainerOuterQty           = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerOuterQty;
                this.paramBlAdContainer.blAdContainerGrossWeight        = event.data.blAdContainerGrossWeight;
                this.paramBlAdContainer.blAdContainerGrossWeightString  = event.data.blAdContainerGrossWeightString;
                this.paramBlAdContainer.blAdContainerGrossMeasure       = event.data.blAdContainerGrossMeasure;
                this.paramBlAdContainer.blAdContainerGrossMeasureString = event.data.blAdContainerGrossMeasureString;
                this.paramBlAdContainer.blAdContainerDGClassCode        = event.data.blAdContainerDGClassCode;
                this.paramBlAdContainer.blAdContainerDGClassName        = event.data.blAdContainerDGClassName;

                this.paramBlAdContainer.blAdContainerIMO                = event.data.blAdContainerIMO;
                this.paramBlAdContainer.blAdContainerUNNO               = event.data.blAdContainerUNNO;
                this.paramBlAdContainer.blAdContainerFlashPoint         = event.data.blAdContainerFlashPoint;
                this.paramBlAdContainer.blAdContainerFlashPointScale    = event.data.blAdContainerFlashPointScale;

                this.paramBlAdContainer.blAdContainerHsCode             = event.data.blAdContainerHsCode;
                this.paramBlAdContainer.blAdContainerHsName             = event.data.blAdContainerHsName;
                // this.txtBlAdContainerHsRemarks.setValue(
                //   this.paramBlAdContainer.blAdContainerHsRemarks
                // )
                this.paramBlAdContainer.blAdContainerHsRemarks          = event.data.blAdContainerHsRemarks;
                this.paramBlAdContainer.blAdContainerCommodityTypeName  = event.data.blAdContainerCommodityTypeName;
                // this.paramBlAdContainer.blAdContainerUcHeight           = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerUcHeight;
                // this.paramBlAdContainer.blAdContainerUcLength           = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerUcLength;
                // this.paramBlAdContainer.blAdContainerUcWidth            = this.gridBlAdContainer.getSelectedValues()[0].blAdContainerUcWidth;
              }
              //   this.blContainerHSUpdateMode = "update";
              //   this.txtBlContainerHSSaveUpdate = "Update";
              //   this.btnBlContainerHSNew = false;
              //   this.btnBlContainerHSSave = true;
              //   this.btnBlContainerHSDelete = true;
              //   this.btnBlContainerHSCancel = true;
              //   this.btnBlContainerNew = false;

              //   this.paramContainerHSCode = new BLContainerHSCode();
              //   this.paramContainerHSCode.blContainerHSOfficeCode =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSOfficeCode;
              //   this.paramContainerHSCode.blContainerHSBlNo =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSBlNo;
              //   this.paramContainerHSCode.blContainerHSSeq =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSSeq;
              //   this.paramContainerHSCode.blContainerHSContainerNumber =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSContainerNumber;

              //   this.paramContainerHSCode.blContainerHSSeq =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSSeq;
              //   this.paramContainerHSCode.blContainerHSCode =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSCode;
              //   this.paramContainerHSCode.blContainerHSDescription =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSDescription;
              //   this.paramContainerHSCode.blContainerHSPrint =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSPrint;
              //   this.paramContainerHSCode.blContainerHSPrintString =
              //     this.gridBlContainerHS.getSelectedValues()[0].blContainerHSPrintString;

              //   // set combo
              //   this.cbBlContainerHS.setUrl(
              //     this.configService.config.BASE_API.toString() +
              //       "/MasterHSCodes/findByComboBoxControl/hsCode={query}"
              //   );
              //   this.cbBlContainerHS.setValue(
              //     this.paramContainerHSCode.blContainerHSCode
              //   );
                    // this.cbBlAdContainerCommodity.setUrl(
                    //   this.configService.config.BASE_API.toString() +
                    //   "/MasterCommodities/findByComboBoxControl/commodityId={query}"
                    // )
                    // this.cbBlAdContainerCommodity.setValue(
                    //   this.paramBlAdContainer.blAdContainerCommodityTypeId
                    // )

                    this.cbBlAdContainerInnerPkgType.setUrl(
                      this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
                    )
                    this.cbBlAdContainerInnerPkgType.setValue(
                      this.paramBlAdContainer.blAdContainerInnerPkgTypeCode
                    )

                    // this.cbBlAdContainerOuterPkgType.setUrl(
                    //   this.configService.config.BASE_API.toString() +
                    //   "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
                    // )
                    // this.cbBlAdContainerOuterPkgType.setValue(
                    //   this.paramBlAdContainer.blAdContainerOuterPkgTypeCode
                    // )

                    this.cbBlAdContainerDGClass.setValue(
                      this.paramBlAdContainer.blAdContainerDGClassCode
                    )

                    this.cbBlAdContainerFlashPointScale.setValue(
                      this.paramBlAdContainer.blAdContainerFlashPointScale
                    )

                    this.cbBlAdContainerHsCode.setValue(
                      this.paramBlAdContainer.blAdContainerHsCode
                    );

                    this.cbBlAdContainerHsName.setValue(
                      this.paramBlAdContainer.blAdContainerHsName
                    );
              // }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGridBlAdContainer(event) {}

  blBLAdContainerAdd(){
    this.setValidatorAdContainer();
    this.setValidatorAdContainerGrossWeight();
    this.setValidatorAdContainerGrossMeasure();
    this.setValidatorAdContainerFlashPointScale();

    this.setAdDialogOption();
    this.showDialog("blBLAdContainerAddDialog", this.adDialogOpt)

    this.frmAdContainerLock = false;
    this.blAdContainerUpdateMode = "insert";
    this.txtBlAdContainerSaveUpdate = "Save";

    this.paramBlAdContainer = new BLAdContainer();
    // this.paramBlAdContainer.blAdContainerSeq = this.gridBlAdContainer.listStore.store.length + 1;
    this.paramBlAdContainer.blAdContainerSeq = this.rowDataADContainer.length === 0 ? 1 : this.rowDataADContainer.length + 1;

    /*
    UPDATE GOS-59 6-DEC-2022 OFF
    if(this.model.blIsCombine == "Y"){
      if(this.model.blDgNos.length > 0) {
        this.paramBlAdContainer.blAdContainerDGClassCode = this.model.blDgNos;
        this.paramBlAdContainer.blAdContainerDGClassName = this.model.blDgNos;
      }

      // this.cbBlAdContainerDGClass.clearSelect();
      // this.cbBlAdContainerDGClass.setData(this.dgNosCombine(this.model.blDgNos));
    }else{
      if(this.model.blDgClass != '' && this.model.blDgClass != null){
        if(this.model.blDgClass == "N"){
          this.paramBlAdContainer.blAdContainerDGClassName = "N/A";
          this.paramBlAdContainer.blAdContainerDGClassCode = "N/A";

        }else{
          this.paramBlAdContainer.blAdContainerDGClassName = this.model.blDgClass;
          this.paramBlAdContainer.blAdContainerDGClassCode = this.model.blDgClass;
        }
        // this.cbBlAdContainerDGClass.setData(this.dgNosCombine(this.model.blDgClass));
        // this.cbBlAdContainerDGClass.setValue(this.paramBlAdContainer.blAdContainerDGClassCode);
      } else {
        this.paramBlAdContainer.blAdContainerDGClassName = "N/A";
        this.paramBlAdContainer.blAdContainerDGClassCode = "N/A";
        // this.cbBlAdContainerDGClass.setData(this.dgNos);
        // this.cbBlAdContainerDGClass.setValue(this.paramBlAdContainer.blAdContainerDGClassCode);
      }
    }
    */

    this.btnBlAdContainerNew = false;
    this.btnBlAdContainerSave = true;
    this.btnBlAdContainerDelete = false;
    this.btnBlAdContainerCancel = true;
    this.btnBlContainerNew = false;
  }

  setAdDialogOption(){
    this.adDialogOpt = {
      title: "Additional Detail Dialog",
      modal: true,
      closeText: "hide",
      closeOnEscape: false,
      width: 900,
      height: "auto",
      open: function (event, ui) {
        //hide close button.
        //// console.log($(this).css("z-index","102"));
        $(".ui-dialog").css("z-index", 103);
        $(".ui-widget-overlay").css("z-index", 102);
        $(this)
          .parent()
          .children()
          .children(".ui-dialog-titlebar-close")
          .hide();
        $("body").first().css("overflow", "hidden");
      },
      close: function (event, ui) {
        $("body").first().css("overflow", " auto");
      },
    };
  }

  blAdContainerUpdate(){
    // yg-sbhd
    console.log(this.paramBlAdContainer);

    let setValidatorAdContainerInnerQty = this.setValidatorAdContainerInnerQty();
    let setValidatorAdContainerInnerPkg = this.setValidatorAdContainerInnerPkg();
    let setValidatorAdContainerCommodity = this.setValidatorAdContainerCommodity();
    let setValidatorAdContainerGrossWeight = this.setValidatorAdContainerGrossWeight();
    let setValidatorAdContainerGrossMeasure = this.setValidatorAdContainerGrossMeasure();
    let setValidatorAdContainerFlashPointScale = this.setValidatorAdContainerFlashPointScale();

    let isError = this.onValidate(this.paramBlAdContainer);

    // if (this.paramBlAdContainer.blAdContainerFlashPoint !== 0.0 &&
    //   this.paramBlAdContainer.blAdContainerFlashPointScale == '' ) {
    //   this.isError = true;
    //   this.paramBlAdContainer["error-blAdContainerFlashPointScale"] =
    //     "Please select temperature F/P";
    // }else{
    //   this.paramBlAdContainer["error-blAdContainerFlashPointScale"] = "";
    // }
    // GOS-519 No need to check the paramBlAdContainer when adding details
    if (
        !setValidatorAdContainerInnerQty &&
        !setValidatorAdContainerInnerPkg &&
        !setValidatorAdContainerCommodity &&
        !setValidatorAdContainerGrossWeight &&
        !setValidatorAdContainerGrossMeasure &&
        !setValidatorAdContainerFlashPointScale
      ) {
      this.paramBlAdContainer.blContainerOfficeCode = this.paramContainer.blContainerOfficeCode;
      this.paramBlAdContainer.blContainerBlNo = this.paramContainer.blContainerBlNo;
      this.paramBlAdContainer.blContainerSeq = this.paramContainer.blContainerSeq;
      this.paramBlAdContainer.blContainerNumber = this.paramContainer.blContainerNumber;

      this.paramBlAdContainer.blAdContainerGrossWeightString = this.paramBlAdContainer.blAdContainerGrossWeightString;
      this.paramBlAdContainer.blAdContainerGrossMeasureString = this.paramBlAdContainer.blAdContainerGrossMeasureString;
      this.paramBlAdContainer.blAdContainerGrossWeight = Number.parseFloat(this.paramBlAdContainer.blAdContainerGrossWeightString);
      this.paramBlAdContainer.blAdContainerGrossMeasure = Number.parseFloat(this.paramBlAdContainer.blAdContainerGrossMeasureString);

      if (this.blAdContainerUpdateMode === "insert") {
        let newRowData = this.rowDataADContainer.slice();
        newRowData.push(this.paramBlAdContainer);
        this.rowDataADContainer = newRowData;
        // console.log("this.paramBlAdContainer = " , this.paramBlAdContainer);
        // console.log("rowDataADContainer = ", this.rowDataADContainer)
        // this.gridBlAdContainer.listStore.store.push(this.paramBlAdContainer);
        // this.gridBlAdContainer.loadData();
        this.blBlAdContainerAutoUpdate();
        this.blBlAdContainerCancelDialog();
        this.btnBlAdContainerNew = true;
        this.btnBlAdContainerSave = false;
        this.btnBlAdContainerDelete = false;
        this.btnBlAdContainerCancel = false;
        this.frmAdContainerLock = true;
      }else{
        // const self = this;
        // console.log("UPDATE AD")
        // this.gridBlAdContainer.listStore.updateData(
        //   this.paramBlAdContainer.blAdContainerSeq - 1,
        //   this.paramBlAdContainer
        // );
        // let newRowData = this.rowDataADContainer;
        let newRowData = this.rowDataADContainer.slice();

        newRowData.map((item, i) => {
          if(item.blAdContainerSeq == this.paramBlAdContainer.blAdContainerSeq){
            newRowData[i] = this.paramBlAdContainer;
          }
        })

       this.rowDataADContainer = newRowData;
        // console.log(this.rowDataADContainer)

        // this.gridBlAdContainer.loadData();

        this.blBlAdContainerAutoUpdate();
        this.blBlAdContainerCancelDialog();
        this.btnBlAdContainerNew = true;
        this.btnBlAdContainerSave = false;
        this.btnBlAdContainerDelete = false;
        this.btnBlAdContainerCancel = false;
        this.frmAdContainerLock = true;
      }
    }
  }

  blBlAdContainerAutoUpdate(){
    var self = this;
    let temporaryInnerPkgTypeArray      = [];
    let temporaryCommodityIdArray       = [];

    let temporaryCountInnerPkgType          = 0;
    // let temporaryCountOuterPkgType          = 0;
    let temporaryCountCommodityId           = 0;
    let temporaryTotalInnerQty              = 0;
    // let temporaryTotalOuterQty              = 0;
    let temporaryBlAdContainerGrossWeight   = 0;
    let temporaryBlAdContainerGrossMeasure  = 0;
    // let temporaryBlAdContainerUcHeight      = 0;
    // let temporaryBlAdContainerUcLength      = 0;
    // let temporaryBlAdContainerUcWidth       = 0;
    let temporaryCommodityId                = "";
    let temporaryCommodityName              = "";
    let temporaryInnerPkgTypeCode           = "";
    let temporaryInnerPkgTypeName           = "";
    // let temporaryOuterPkgTypeCode           = "";
    // let temporaryOuterPkgTypeName           = "";
    let temporaryDGClass                  = "";
    let temporaryDGClassArray             = [];
    let temporaryCountDGClass             = 0;

    let temporaryIMO                      = "";
    let temporaryIMOArray                 = [];
    let temporaryCountIMO                 = 0;

    let temporaryUNNO                     = "";
    let temporaryUNNOArray                = [];
    let temporaryCountUNNO                = 0;

    let temporaryFlashPoint               = 0;
    let temporaryFlashPointArray          = [];
    let temporaryCountFlashPoint          = "";

    let temporaryFlashPointScale          = "";
    let temporaryCountFlashPointScale     = 0;
    let temporaryFlashPointScaleArray     = [];

    const allEqual = arr => arr.every( v => v === arr[0] )
    console.log("data asli ",);
    console.log("data asli ", this.rowDataADContainer);


    if(this.rowDataADContainer.length > 0){
      for (let i = 0; i < this.rowDataADContainer.length; i++) {
        // console.log("----INNER ODE", this.gridBlAdContainer.listStore.store[i]);
        if(this.rowDataADContainer[i].blAdContainerCommodityTypeId !== ""){
          temporaryCountCommodityId = temporaryCountCommodityId + 1;
        // }else{
        //   temporaryCountInnerPkgType = temporaryCountInnerPkgType;
          temporaryCommodityId   = this.rowDataADContainer[i].blAdContainerCommodityTypeId;
          temporaryCommodityName = this.rowDataADContainer[i].blAdContainerCommodityTypeName;
          temporaryCommodityIdArray.push(temporaryCommodityId);
        }

        if(this.rowDataADContainer[i].blAdContainerInnerPkgTypeCode !== ""){
          temporaryCountInnerPkgType = temporaryCountInnerPkgType + 1;
        // }else{
        //   temporaryCountInnerPkgType = temporaryCountInnerPkgType;

          // if(temporaryInnerPkgTypeCode == this.rowDataADContainer[i].blAdContainerInnerPkgTypeCode){
          //   temporaryCountInnerPkgTypeBool = true;
          // }else{
          //   temporaryCountInnerPkgTypeBool = false;
          // }

          temporaryInnerPkgTypeCode = this.rowDataADContainer[i].blAdContainerInnerPkgTypeCode;
          temporaryInnerPkgTypeName = this.rowDataADContainer[i].blAdContainerInnerPkgTypeName;
          temporaryInnerPkgTypeArray.push(temporaryInnerPkgTypeCode);
        }

        if(this.rowDataADContainer[i].blAdContainerDGClass !== "" ||
          this.rowDataADContainer[i].blAdContainerDGClass !== null ||
          this.rowDataADContainer[i].blAdContainerDGClass !== 0)
        {
          temporaryDGClass = this.rowDataADContainer[i].blAdContainerDGClassCode;
          temporaryDGClassArray.push(temporaryDGClass);
          // console.log("data = " + i,  this.rowDataADContainer[i].blAdContainerDGClassCode)
          // switch (this.rowDataADContainer[i].blAdContainerDGClassCode){
          //   case "N/A":
          //     this.cbBlContainerContainerDGClass.setValue("N/A");
          //     break;
          //   case "C1":
          //     this.cbBlContainerContainerDGClass.setValue("C1");
          //     break;
          //   case "C2":
          //     this.cbBlContainerContainerDGClass.setValue("C2");
          //     break;
          //   case "C2F":
          //     this.cbBlContainerContainerDGClass.setValue("C2F");
          //     break;
          //   case "C3":
          //     this.cbBlContainerContainerDGClass.setValue("C3");
          //     break;
          //   default:
          //     break;
          // }




        }

        if(this.rowDataADContainer[i].blAdContainerIMO !== "" ||
          this.rowDataADContainer[i].blAdContainerIMO !== null ||
          this.rowDataADContainer[i].blAdContainerIMO !== 0)
        {
          temporaryIMO = this.rowDataADContainer[i].blAdContainerIMO;
          temporaryIMOArray.push(temporaryIMO);
        }

        if(this.rowDataADContainer[i].blAdContainerUNNO !== "" ||
          this.rowDataADContainer[i].blAdContainerUNNO !== null ||
          this.rowDataADContainer[i].blAdContainerUNNO !== 0)
        {
          temporaryUNNO = this.rowDataADContainer[i].blAdContainerUNNO;
          temporaryUNNOArray.push(temporaryUNNO);
        }

        if(this.rowDataADContainer[i].blAdContainerFlashPoint !== "" ||
          this.rowDataADContainer[i].blAdContainerFlashPoint !== null ||
          this.rowDataADContainer[i].blAdContainerFlashPoint !== 0)
        {
          temporaryFlashPoint = this.rowDataADContainer[i].blAdContainerFlashPoint;
          temporaryFlashPointArray.push(temporaryFlashPoint);
        }

        // if(this.rowDataADContainer[i].blAdContainerOuterPkgTypeCode !== ""){
        //   temporaryCountInnerPkgType = temporaryCountInnerPkgType + 1;
        //   temporaryInnerPkgTypeCode = this.rowDataADContainer[i].blAdContainerInnerPkgTypeCode;
        //   temporaryInnerPkgTypeName = this.rowDataADContainer[i].blAdContainerInnerPkgTypeName;
        // }

        temporaryTotalInnerQty = temporaryTotalInnerQty + Number.parseFloat(
          this.rowDataADContainer[i].blAdContainerInnerQty
        )

        // temporaryTotalOuterQty = temporaryTotalOuterQty + Number.parseFloat(
        //   this.rowDataADContainer[i].blAdContainerOuterQty
        // )
        temporaryBlAdContainerGrossWeight = temporaryBlAdContainerGrossWeight + Number.parseFloat(
          this.rowDataADContainer[i].blAdContainerGrossWeight
        )
        temporaryBlAdContainerGrossMeasure = temporaryBlAdContainerGrossMeasure + Number.parseFloat(
          this.rowDataADContainer[i].blAdContainerGrossMeasure
        )
        // temporaryBlAdContainerUcHeight = temporaryBlAdContainerUcHeight + Number.parseFloat(
        //   this.rowDataADContainer[i].blAdContainerUcHeight
        // )
        // temporaryBlAdContainerUcLength = temporaryBlAdContainerUcLength + Number.parseFloat(
        //   this.rowDataADContainer[i].blAdContainerUcLength
        // )
        // temporaryBlAdContainerUcWidth = temporaryBlAdContainerUcWidth + Number.parseFloat(
        //   this.rowDataADContainer[i].blAdContainerUcWidth
        // )
      }

      console.log("ARRAY NYA = ", temporaryInnerPkgTypeArray)
      if(temporaryCountCommodityId <= 0) {
        // this.cbBlContainerCommodity.clearSelect();
        this.cbBlDescCommodityCode.clearSelect();
      }else if(temporaryCountCommodityId == 1){
        temporaryCommodityId    = temporaryCommodityId;
        temporaryCommodityName  = temporaryCommodityName;
        this.cbBlContainerCommodity.setForceValue(temporaryCommodityName);
        if(this.rowDataADContainer.length == 1){
          this.cbBlDescCommodityCode.setForceValue(temporaryCommodityName);
        }
      }else{
        if(allEqual(temporaryCommodityIdArray)){
          temporaryCommodityId    = temporaryCommodityId;
          temporaryCommodityName  = temporaryCommodityName;
          this.cbBlContainerCommodity.setForceValue(temporaryCommodityName);
          if(this.rowDataADContainer.length == 1){
            this.cbBlDescCommodityCode.setForceValue(temporaryCommodityName);
          }
        }else{
          temporaryCommodityId    = "CD00174";
          temporaryCommodityName  = "GENERAL";
          this.cbBlContainerCommodity.setForceValue('GENERAL');
          if(this.rowDataADContainer.length == 1){
            this.cbBlDescCommodityCode.setForceValue('GENERAL');
          }
        }
      }

      // if(temporaryCountInnerPkgType <= 0) {
      //   this.cbBlContainerInnerPkgType.clearSelect();
      // }else if(temporaryCountInnerPkgType == 1){
      //   this.cbBlContainerInnerPkgType.setForceValue(temporaryInnerPkgTypeName);
      //   temporaryInnerPkgTypeCode    = temporaryInnerPkgTypeCode;
      //   temporaryInnerPkgTypeName    = temporaryInnerPkgTypeName;
      // }else{
      //   temporaryInnerPkgTypeCode    = "PKGS";
      //   temporaryInnerPkgTypeName    = "PACKAGES";
      //   this.cbBlContainerInnerPkgType.setForceValue('PACKAGES');
      // }

      console.log("Masuk asu");
      console.log(this.blContainerPackageTypeLock)
      console.log(temporaryCountInnerPkgType)
      if(temporaryCountInnerPkgType <= 0) {
        if(this.blContainerPackageTypeLock == false) {
          this.cbBlContainerPackageType.clearSelect();
        }
      }else if(temporaryCountInnerPkgType == 1){
        temporaryInnerPkgTypeCode    = temporaryInnerPkgTypeCode;
        temporaryInnerPkgTypeName    = temporaryInnerPkgTypeName;
        if(this.blContainerPackageTypeLock == false){
          this.cbBlContainerPackageType.setForceValue(temporaryInnerPkgTypeName);
        }
      }else{
        if(allEqual(temporaryInnerPkgTypeArray)){
          temporaryInnerPkgTypeCode    = temporaryInnerPkgTypeCode;
          temporaryInnerPkgTypeName    = temporaryInnerPkgTypeName;

          if(this.blContainerPackageTypeLock == false){
            this.cbBlContainerPackageType.setForceValue(temporaryInnerPkgTypeName);
          }
        }else{
          temporaryInnerPkgTypeCode    = "PKGS";
          temporaryInnerPkgTypeName    = "PACKAGES";
          if(this.blContainerPackageTypeLock == false){
            this.cbBlContainerPackageType.setForceValue('PACKAGES');
          }
        }

      }

      // if(temporaryCountOuterPkgType <= 0) {
      //   this.cbBlContainerPackageType.clearSelect();
      // }else if(temporaryCountOuterPkgType == 1){
      //   this.cbBlContainerPackageType.setForceValue(temporaryOuterPkgTypeName);
      //   temporaryOuterPkgTypeCode    = temporaryOuterPkgTypeCode;
      //   temporaryOuterPkgTypeName    = temporaryOuterPkgTypeName;
      // }else{
      //   this.cbBlContainerPackageType.setForceValue('PACKAGES');
      //   temporaryOuterPkgTypeCode    = "PKGS";
      //   temporaryOuterPkgTypeName    = "PACKAGES";
      // }
    }else{
      // this.cbBlAdContainerCommodity.clearSelect();
      this.paramBlAdContainer['blAdContainerCommodityTypeName'] = "";

      // this.cbBlContainerPackageType.clearSelect();
      this.cbBlAdContainerInnerPkgType.clearSelect();

      if(this.blContainerPackageTypeLock == false){
        temporaryInnerPkgTypeCode    = "";
        temporaryInnerPkgTypeName    = "";
        this.cbBlContainerPackageType.setForceValue("");
      }

      temporaryCommodityId = "";
      temporaryCommodityName = "";
      this.cbBlContainerCommodity.setForceValue("");

      temporaryTotalInnerQty = 0;
      temporaryBlAdContainerGrossWeight = 0;
    }

    if(this.blContainerDescLock == false){
      this.blBlAdContainerAutoUpdateHS();
    }

    if(this.blContainerQuantityLock == false){
      this.paramContainer.blContainerQuantity           = temporaryTotalInnerQty;
    }

    // Calculate total when Additional Details has these fields (AdditionalWhenUnLock)
    if(this.blContainerGrossWeightLock == false){
      this.calculateGrossWeightAdditionalWhenUnLock();
      this.paramContainer.blContainerGrossWeight = this.temporaryBlAdContainerAdditionalGrossWeight;
      this.paramContainer.blContainerGrossWeightString = this.blContainerGrossWeightAdditionalString;
    }

    if(this.blContainerGrossMeasureLock == false){
      this.calculateGrossMeasureAdditionalWhenUnLock();
      this.paramContainer.blContainerGrossMeasure = this.temporaryBlAdContainerAdditionalGrossMeasure;
      this.paramContainer.blContainerGrossMeasureString = this.blContainerGrossMeasureAdditionalString;
    }

    // this.paramContainerExtra.blContainerUch           = temporaryBlAdContainerUcHeight;
    // this.paramContainerExtra.blContainerUcl           = temporaryBlAdContainerUcLength;
    // this.paramContainerExtra.blContainerUcw           = temporaryBlAdContainerUcWidth;
    this.paramContainerExtra.blContainerInnerQuantity        = temporaryTotalInnerQty;
    // this.paramContainerExtra.blContainerCommodityTypeId      = temporaryCommodityId;
    // this.paramContainerExtra.blContainerCommodityTypeName    = temporaryCommodityName;
    // this.tempBlContainerCommodityTypeName = temporaryCommodityName;
    // this.tempBlContainerCommodityTypeId = temporaryCommodityId;
    // this.paramContainerExtra.blContainerInnerPackageTypeCode = temporaryInnerPkgTypeCode;
    // this.paramContainerExtra.blContainerInnerPackageTypeName = temporaryInnerPkgTypeName;
    if(this.blContainerPackageTypeLock == false){
      this.paramContainer.blContainerPackageTypeCode           = temporaryInnerPkgTypeCode;
      this.paramContainer.blContainerPackageTypeName           = temporaryInnerPkgTypeName;
    }

    // temporaryCountIMO = Math.max(...temporaryIMOArray);
    // this.paramContainer.blContainerIMO = temporaryCountIMO.toString().trim();

    // temporaryCountUNNO = Math.max(...temporaryUNNOArray);
    // this.paramContainer.blContainerUNNO = temporaryCountUNNO.toString().trim();

    // temporaryCountFlashPoint = Math.max(...temporaryFlashPointArray);
    // this.paramContainer.blContainerFlashPointTemperature =  Math.max(...temporaryFlashPointArray);

    // if(temporaryDGClassArray.includes("N/A")){ this.cbBlContainerContainerDGClass.setValue("N/A"); }
    // if(temporaryDGClassArray.includes("C1")){ this.cbBlContainerContainerDGClass.setValue("C1"); }
    // if(temporaryDGClassArray.includes("C2")){ this.cbBlContainerContainerDGClass.setValue("C2"); }
    // if(temporaryDGClassArray.includes("C2F")){ this.cbBlContainerContainerDGClass.setValue("C2F"); }
    // if(temporaryDGClassArray.includes("C3")){ this.cbBlContainerContainerDGClass.setValue("C3"); }
  }

  blBlAdContainerAutoUpdateHS(){
    let sliceDescription = "";
    let oldDescription = "";

    let oldDesc = this.txtBlContainerDesc
    .getValue()
    .split("HS Code List:");

    if(this.rowDataADContainer.length > 0){
      for (let i = 0; i < this.rowDataADContainer.length; i++) {
        if(
          this.rowDataADContainer[i].blAdContainerHsCode !== "" &&
          this.rowDataADContainer[i].blAdContainerHsName !== ""
        ){
          sliceDescription = "↵" + "HS Code List: " + "↵";
          oldDescription = oldDescription +
          this.rowDataADContainer[i].blAdContainerHsCode +
          " " +
          this.rowDataADContainer[i].blAdContainerHsName +
          "↵";
        }
      }

      // this.paramContainerExtra.blContainerDesc =
      this.tempBlContainerDesc =
      oldDesc[0].replace(/↵$/, "") +
      sliceDescription +
      oldDescription;

      let descNew1 = this.utf8Decode(this.tempBlContainerDesc);
      let descNew2 = this.sCharUtil.decodeURIComponentSafe(descNew1, 1);

      // this.paramContainerExtra.blContainerDesc = descNew2
      this.tempBlContainerDesc = descNew2;
      this.txtBlContainerDesc.setValue(
        // this.paramContainerExtra.blContainerDesc
        this.tempBlContainerDesc
      );
    }else{
      // this.paramContainerExtra.blContainerDesc = oldDesc[0].replace(/↵$/, "");
      // this.txtBlContainerDesc.setValue(this.paramContainerExtra.blContainerDesc);
      this.tempBlContainerDesc = oldDesc[0].replace(/↵$/, "");
      this.txtBlContainerDesc.setValue(this.tempBlContainerDesc);
    }
  }

  blBlAdContainerCancelDialog() {
    this.isErrorAdCode = false;
    this.paramBlAdContainer = new BLAdContainer();
    this.paramBlAdContainer.blAdContainerSeq = 0;
    // this.cbBlAdContainerCommodity.setValue("");
    this.cbBlAdContainerInnerPkgType.setValue("");
    this.cbBlAdContainerHsCode.setValue("");
    this.cbBlAdContainerHsName.setValue("");
    // this.txtBlAdContainerHsRemarks.setValue("");
    // this.cbBlAdContainerOuterPkgType.setValue("");
    this.paramBlAdContainer.blAdContainerInnerQty = 0;
    // this.paramBlAdContainer.blAdContainerOuterQty = 0;
    this.paramBlAdContainer.blAdContainerGrossWeight = 0;
    this.paramBlAdContainer.blAdContainerGrossMeasure = 0;
    // this.paramBlAdContainer.blAdContainerUcHeight = 0;
    // this.paramBlAdContainer.blAdContainerUcLength = 0;
    // this.paramBlAdContainer.blAdContainerUcWidth = 0;
    this.paramBlAdContainer.blAdContainerDGClassCode = "";
    this.paramBlAdContainer.blAdContainerDGClassName = "";
    this.cbBlAdContainerDGClass.setValue("");
    this.cbBlContainerFlashPointScale.setValue("");
    this.paramBlAdContainer.blAdContainerIMO = "";
    this.paramBlAdContainer.blAdContainerUNNO = "";
    this.paramBlAdContainer.blAdContainerFlashPoint = 0;
    this.paramBlAdContainer.blAdContainerFlashPointScale = "";
    this.cbBlAdContainerFlashPointScale.setValue("");
    this.btnBlAdContainerNew = true;
    this.btnBlAdContainerSave = false;
    this.btnBlAdContainerDelete = false;
    this.btnBlAdContainerCancel = false;
    this.frmAdContainerLock = true;
    // this.closeDialog("hsDialog");
    this.closeDialog("blBLAdContainerAddDialog");
  }

  blBlAdContainerDelete() {
    // console.log("DELETE WOY");
    this.genericUtil.showDialog(
      "blConfirmDeleteAdContainer",
      "Confirmation",
      350,
      150
    );
  }

  // end addtional detail container

  setValidatorContainerInsert() {
    this.paramContainer["error-blContainerNumber"] = "";
    this.paramContainer["error-blContainerSize"] = "";
    this.paramContainer["error-blContainerShortshipment"] = "";
    this.paramContainer["error-checkWtMeasValidator"] = "";
    this.paramContainer["error-blContainerHSCodeDetection"] = "";
    this.paramContainer["error-UchMeasurementsValidator"] = "";
    this.paramContainer["error-UclMeasurementsValidator"] = "";
    this.paramContainer["error-UcwMeasurementsValidator"] = "";
    this.paramContainer["error-blContainerSeqNo"] = "";

    this.paramContainer.UchMeasurementsValidator = false;
    this.paramContainer.UclMeasurementsValidator = false;
    this.paramContainer.UcwMeasurementsValidator = false;

    this.validatorRules = {
      blContainerNumber: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Container No.",
          },
        ],
      },
      blContainerSize: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Container Size.",
          },
        ],
      },
      UchMeasurementsValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Height should positive number",
          },
        ],
      },
      UclMeasurementsValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Length should positive number",
          },
        ],
      },
      UcwMeasurementsValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Width should positive number",
          },
        ],
      },
    };
  }

  /********  04. TAB MARK NO ********/
  gridEventBlMarkNo(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              if (this.btnBlMarkNoCancel !== true) {
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print,sync";

                // if (this.blLock == "N") {
                //   this.btnBlMarkNoNew = false;
                //   this.btnBlMarkNoSave = true;
                //   this.btnBlMarkNoDelete = true;
                //   this.btnBlMarkNoCancel = true;
                //   this.btnBlMarkNoNoMark = false;
                //   this.frmMarkNoLock = false; // to release container form lock
                //   this.blMarkNoUpdateMode = "update";
                //   this.txtBlMarkNoSaveUpdate = "Update";
                // } else if(this.blLock == 'Y' || this.blLock == 'T1'|| this.blLock == 'T2'|| this.blLock == 'T3'|| this.blLock == 'T4'|| this.blLock == 'T5'|| this.blLock == 'T6'|| this.blLock == 'T7'|| this.blLock == 'T8') {
                //   this.btnBlMarkNoNew = false;
                //   this.btnBlMarkNoSave = false;
                //   this.btnBlMarkNoDelete = false;
                //   this.btnBlMarkNoCancel = true;
                //   this.btnBlMarkNoNoMark = false;
                //   this.frmMarkNoLock = false;
                //   this.lockTab4 = false;
                // }else{
                //   this.btnBlMarkNoNew = false;
                //   this.btnBlMarkNoSave = true;
                //   this.btnBlMarkNoDelete = true;
                //   this.btnBlMarkNoCancel = true;
                //   this.btnBlMarkNoNoMark = false;
                //   this.frmMarkNoLock = false; // to release container form lock
                //   this.blMarkNoUpdateMode = "update";
                //   this.txtBlMarkNoSaveUpdate = "Update";
                // }

                if (
                  this.blLock == "T7" ||
                  this.blLock == "T6" ||
                  this.blLock == "T3" ||
                  this.blLock == "T2" ||
                  this.blLock == "T1" ||
                  this.blLock == "Y"
                ) {
                  this.btnBlMarkNoNew = false;
                  this.btnBlMarkNoSave = false;
                  this.btnBlMarkNoDelete = false;
                  this.btnBlMarkNoCancel = true;
                  this.btnBlMarkNoNoMark = false;
                  this.frmMarkNoLock = false;
                  this.lockTab4 = false;
                } else {
                  this.blMarkNoUpdateMode = "update";
                  this.txtBlMarkNoSaveUpdate = "Update";
                  this.btnBlMarkNoNew = false;
                  this.btnBlMarkNoSave = true;
                  this.btnBlMarkNoDelete = true;
                  this.btnBlMarkNoCancel = true;
                  this.btnBlMarkNoNoMark = false;
                  this.frmMarkNoLock = false;
                }

                const dt = this.gridBlMarkNo.getSelectedValues()[0];
                this.paramMarkNo.blMarkNoOfficeCode = dt.blMarkNoOfficeCode;
                this.paramMarkNo.blMarkNoBlNo = dt.blMarkNoBlNo;
                this.paramMarkNo.blMarkNoSeq = dt.blMarkNoSeq;
                this.paramMarkNo.blMarkNoDescription = dt.blMarkNoDescription;
                this.paramMarkNo.blMarkNoDescriptionClean =
                  dt.blMarkNoDescriptionClean;

                this.txtBlMarkNoDesc.setValue(
                  this.paramMarkNo.blMarkNoDescription
                );

                // lock the tab
                this.lockParty = true;
                this.lockPlace = true;
                this.lockContainer = true;
                this.lockMarkNo = false;
                this.lockDesc = true;
                this.lockNettCharge = true;
                this.lockGrossCharge = true;
                this.lockNettChargeConsole = true;
                this.lockGrossChargeConsole = true;
              }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGridBlMarkNo(event) {}

  eventBtnBlMarkNoNew() {
    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print,sync";
    this.btnBlMarkNoNew = false;
    this.btnBlMarkNoSave = true;
    this.btnBlMarkNoDelete = false;
    this.btnBlMarkNoCancel = true;
    this.btnBlMarkNoNoMark = false;
    this.frmMarkNoLock = false; // to release container form lock

    this.paramMarkNo = new BLMarkNo();
    this.paramMarkNo.blMarkNoOfficeCode =
      this.cookieService.getDefaultLocationCode();
    this.paramMarkNo.blMarkNoBlNo = this.model.blNo;
    this.paramMarkNo.blMarkNoSeq = this.gridBlMarkNo.listStore.store.length + 1;
    this.paramMarkNo.blMarkNoDescription = "";
    this.txtBlMarkNoDesc.setValue("");

    // lock the tab
    this.lockParty = true;
    this.lockPlace = true;
    this.lockContainer = true;
    this.lockMarkNo = false;
    this.lockDesc = true;
    this.lockNettCharge = true;
    this.lockGrossCharge = true;
    this.lockNettChargeConsole = true;
    this.lockGrossChargeConsole = true;
  }

  eventBtnBlMarkNoSave() {
    if (this.blMarkNoUpdateMode === "insert") {
      this.paramMarkNo.blMarkNoDescription = this.txtBlMarkNoDesc
        .getValue()
        .toString();
      let markClean: String = "";
      if (
        this.txtBlMarkNoDesc.getValue().toString().length <= 1 &&
        this.txtBlMarkNoDesc.getValue().toString() === "↵"
      ) {
        markClean = "";
      } else {
        for (
          let i = 0;
          i < this.paramMarkNo.blMarkNoDescription.split("↵").length;
          i++
        ) {
          if (i <= 2) {
            if (i === 0) {
              markClean += this.paramMarkNo.blMarkNoDescription.split("↵")[i];
            } else {
              if (
                this.paramMarkNo.blMarkNoDescription.split("↵")[i].length > 0
              ) {
                markClean +=
                  "<br />" + this.paramMarkNo.blMarkNoDescription.split("↵")[i];
              }
            }
          }
        }
      }

      this.paramMarkNo.blMarkNoDescriptionClean = markClean.toString();

      this.setValidatorMarkNoInsert();
      this.isErrorMarkNo = this.onValidate(this.paramMarkNo);
      if (!this.isErrorMarkNo) {
        this.gridBlMarkNo.url = "";
        this.gridBlMarkNo.listStore.store.push(this.paramMarkNo);
        this.gridBlMarkNo.loadData();
        this.paramMarkNo = new BLMarkNo(); // reset param
        this.frmMarkNoLock = true;

        this.btnBlMarkNoNew = true;
        this.btnBlMarkNoSave = false;
        this.btnBlMarkNoDelete = false;
        this.btnBlMarkNoCancel = false;
        this.frmMarkNoLock = true; // to lock back container form
        this.txtBlMarkNoDesc.setValue("");

        this.btnBlMarkNoNew = true;
        this.btnBlMarkNoSave = false;
        this.btnBlMarkNoDelete = false;
        this.btnBlMarkNoCancel = false;
        this.btnBlMarkNoNoMark = true;
        this.frmMarkNoLock = true; // to lock back container form
      }
    } else if ((this.blMarkNoUpdateMode = "update")) {
      this.paramMarkNo.blMarkNoDescription = this.txtBlMarkNoDesc
        .getValue()
        .toString();

      let markClean: String = "";
      if (
        this.txtBlMarkNoDesc.getValue().toString().length <= 1 &&
        this.txtBlMarkNoDesc.getValue().toString() === "↵"
      ) {
        markClean = "";
      } else {
        for (
          let i = 0;
          i < this.paramMarkNo.blMarkNoDescription.split("↵").length;
          i++
        ) {
          if (i <= 2) {
            if (i === 0) {
              markClean += this.paramMarkNo.blMarkNoDescription.split("↵")[i];
            } else {
              if (
                this.paramMarkNo.blMarkNoDescription.split("↵")[i].length > 0
              ) {
                markClean +=
                  "<br />" + this.paramMarkNo.blMarkNoDescription.split("↵")[i];
              }
            }
          }
        }
      }
      this.paramMarkNo.blMarkNoDescriptionClean = markClean.toString();

      // this.gridBlMarkNo.listStore.updateData((this.gridBlMarkNo.getSelectedValues()[0].blMarkNoSeq - 1), this.paramMarkNo);
      this.gridBlMarkNo.listStore.updateData(
        this.paramMarkNo.blMarkNoSeq - 1,
        this.paramMarkNo
      );

      this.gridBlMarkNo.clearSelectedValues();
      this.gridBlMarkNo.loadData();

      this.btnBlMarkNoNew = true;
      this.btnBlMarkNoSave = false;
      this.btnBlMarkNoDelete = false;
      this.btnBlMarkNoCancel = false;
      this.btnBlMarkNoNoMark = true;
      this.frmMarkNoLock = true; // to lock back container form



    }
    this.eventBtnBlMarkNoCancel(); // reset form
    this.isRefreightButtonClicked = false;
  }

  eventBtnBlMarkNoDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteMarkNo",
      "Confirmation",
      350,
      150
    );
  }

  eventBtnBlMarkNoCancel() {
    this.btnBlMarkNoNew = true;
    this.btnBlMarkNoSave = false;
    this.btnBlMarkNoDelete = false;
    this.btnBlMarkNoCancel = false;
    this.btnBlMarkNoNoMark = true;
    this.frmMarkNoLock = true; // to lock back container form
    this.blMarkNoUpdateMode = "insert";
    this.txtBlMarkNoSaveUpdate = "Save";

    this.isErrorMarkNo = false;

    this.paramMarkNo = new BLMarkNo();
    this.txtBlMarkNoDesc.setValue("");

    // release the lock
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }

  if(this.viewOnlyStatus){
    console.log("masuk cancel mark no");
    this.disableToolbarButtons =
    "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
    this.btnBlMarkNoNew = false;
  }else{
    if (this.blLock == "N") {
      // When the status is cleared or locked, only view
      if(this.model.blStatus === "D" || this.isOpenedByOtherUser === true){
        this.lockTab4 = true;
      }else{
        this.lockTab4 = false;
      }
    } else if (this.blLock == "Y") {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,sync";
      this.lockTab4 = true;
    } else if (this.blLock == "T1") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T2") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T3") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T4") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
    } else if (this.blLock == "T5") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T6") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T7") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      this.lockTab4 = true;
    } else if (this.blLock == "T8") {
      this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
    }

  }

  }

  eventBtnBlMarkNoNoMark() {
    if (this.gridBlMarkNo.listStore.store.length === 0) {
      this.gridBlMarkNo.url = "";
      this.gridBlMarkNo.listStore.store = [];

      this.paramMarkNo = new BLMarkNo();
      this.paramMarkNo.blMarkNoOfficeCode =
        this.cookieService.getDefaultLocationCode();
      this.paramMarkNo.blMarkNoBlNo = this.model.blNo;
      this.paramMarkNo.blMarkNoSeq =
        this.gridBlMarkNo.listStore.store.length + 1;
      this.paramMarkNo.blMarkNoDescription = "*****";
      this.paramMarkNo.blMarkNoDescriptionClean = "*****";
      this.gridBlMarkNo.listStore.store.push(this.paramMarkNo);
      this.gridBlMarkNo.loadData();

      this.isRefreightButtonClicked = false;
    }
  }

  setValidatorMarkNoInsert() {
    this.validatorRules = {
      blMarkNoDescription: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Mark Description.",
          },
        ],
      },
    };
  }

  /********  05 TAB DESC ********/
  infoGridBlDesc(event) {}

  gridEventBlDesc(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              console.log("blolickdesc " + this.blLock);
              if (
                this.modeFormBlDesc !== "edit" &&
                this.btnBlDescClauseNew !== false
              ) {
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
                const data = this.gridBlDesc.listStore.store[0];

                // if (this.blLock == "N") {
                //   this.blDescCancel = true;
                //   this.blDescUpdate = true;
                //   this.blDescDelete = true;
                // }else if(this.blLock == 'Y' || this.blLock == 'T1'|| this.blLock == 'T2'|| this.blLock == 'T3'|| this.blLock == 'T4'|| this.blLock == 'T5'|| this.blLock == 'T6'|| this.blLock == 'T7'|| this.blLock == 'T8') {
                //   this.blDescCancel = true;
                //   this.blDescUpdate = false;
                //   this.blDescDelete = false;
                //   this.lockTab5 = false;
                // }else{
                //   this.blDescCancel = true;
                //   this.blDescUpdate = true;
                //   this.blDescDelete = true;
                // }

                if (
                  this.blLock == "T7" ||
                  this.blLock == "T6" ||
                  this.blLock == "T4" ||
                  this.blLock == "T3" ||
                  this.blLock == "T2" ||
                  this.blLock == "T1" ||
                  this.blLock == "Y"
                ) {
                  this.blDescCancel = true;
                  this.blDescUpdate = false;
                  this.blDescDelete = false;
                  this.lockTab5 = false;
                } else {
                  this.blDescCancel = true;
                  this.blDescUpdate = true;
                  this.blDescDelete = true;
                }

                this.paramDesc = new BLDesc();
                this.paramDesc.blDescOfficeCode = data.blDescOfficeCode;
                this.paramDesc.blDescBlNo = data.blDescBlNo;
                this.paramDesc.blDescSeq = data.blDescSeq;
                this.paramDesc.blDescInnerQuantity = data.blDescInnerQuantity;
                this.paramDesc.blDescInnerPackageTypeCode =
                  data.blDescInnerPackageTypeCode;
                this.paramDesc.blDescInnerPackageTypeName =
                  data.blDescInnerPackageTypeName;
                this.paramDesc.blDescOuterQuantity = data.blDescOuterQuantity;
                this.paramDesc.blDescOuterPackageTypeCode =
                  data.blDescOuterPackageTypeCode;
                this.paramDesc.blDescOuterPackageTypeName =
                  data.blDescOuterPackageTypeName;
                this.paramDesc.blDescPsaPackageTypeCode =
                  data.blDescPsaPackageTypeCode;
                this.paramDesc.blDescPsaPackageTypeName =
                  data.blDescPsaPackageTypeName;
                this.paramDesc.blDescDescriptionText =
                  data.blDescDescriptionText;
                this.paramDesc.blDescDescriptionLock =
                  data.blDescDescriptionLock;
                this.paramDesc.blDescDg2 = data.blDescDg2;
                this.paramDesc.blDescDg2R = data.blDescDg2R;
                this.paramDesc.blDescNettMeasure = data.blDescNettMeasure;
                this.paramDesc.blDescAwkward = data.blDescAwkward;

                this.paramDesc.blDescVgm = data.blDescVgm;
                this.paramDesc.blDescNettMeasure = data.blDescNettMeasure;
                this.paramDesc.blDescNettMeasureString = this.parseDecimal(data.blDescNettMeasureString);
                this.paramDesc.blDescReceiptTypeCode =
                  data.blDescReceiptTypeCode;
                this.paramDesc.blDescDeliveryTypeCode =
                  data.blDescDeliveryTypeCode;

                this.blGrossWeightNum = data.blDescGrossWeight;
                this.blNettWeightNum = data.blDescNettWeight;
                this.blGrossMeasureNum = data.blDescGrossMeasure;

                this.paramDesc.blDescCommodityTypeId =
                  data.blDescCommodityTypeId;
                this.paramDesc.blDescCommodityTypeName =
                  data.blDescCommodityTypeName;
                //this.paramDesc.blDescCommodityTypeId = "";
                //this.paramDesc.blDescCommodityTypeName = "";


                /* Commented out by GLG during SHA implementation, because this will cause all locked figure to be reset
                this.paramDesc.blDescVgmLock = data.blDescVgmLock;

                if (this.site.startsWith("CN"))
                { this.paramDesc.blDescGrossWeightLock=false;
                  this.paramDesc.blDescNettWeightLock=false;
                  this.paramDesc.blDescGrossMeasLock=false;
                  this.paramDesc.blDescNettMeasLock=false;
                  this.paramDesc.blDescOuterQuantityLock = false;
                  this.paramDesc.blDescOuterPkgTypeLock = false;
                  this.blDescVgmLock=false;

                } else{
                        this.paramDesc.blDescGrossWeightLock = data.blDescGrossWeightLock;
                        this.paramDesc.blDescNettWeightLock = data.blDescNettWeightLock;
                        this.paramDesc.blDescGrossMeasLock = data.blDescGrossMeasLock;
                        this.paramDesc.blDescNettMeasLock = data.blDescNettMeasLock;
                        this.paramDesc.blDescOuterQuantityLock =data.blDescOuterQuantityLock;
                        this.paramDesc.blDescOuterPkgTypeLock =data.blDescOuterPkgTypeLock;

                        this.blDescVgmLock = data.blDescVgmLock === "Y" ? true : false;
                }
                */


                this.paramDesc.blDescGrossWeightLock = data.blDescGrossWeightLock;
                this.paramDesc.blDescNettWeightLock = data.blDescNettWeightLock;
                this.paramDesc.blDescGrossMeasLock = data.blDescGrossMeasLock;
                this.paramDesc.blDescNettMeasLock = data.blDescNettMeasLock;
                this.paramDesc.blDescVgmLock = data.blDescVgmLock;
                this.blDescVgmLock = data.blDescVgmLock === "Y" ? true : false;
                this.paramDesc.blDescOuterQuantityLock =data.blDescOuterQuantityLock;
                this.paramDesc.blDescOuterPkgTypeLock =data.blDescOuterPkgTypeLock;


                //PEB
                this.paramDesc.blDescBlDutiable = data.blDescBlDutiable;

                if (this.paramDesc.blDescInnerPackageTypeCode !== "") {
                  this.cbBlDescInnerPkgType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
                  );
                  // this.cbBlDescInnerPkgType.setValue(
                  //   this.paramDesc.blDescInnerPackageTypeCode.replace(
                  //     "/",
                  //     "[-47]"
                  //   )
                  // );
                  this.cbBlDescInnerPkgType.setForceValue(
                    this.paramDesc.blDescInnerPackageTypeName
                  );
                }

                if (this.paramDesc.blDescOuterPackageTypeCode !== "") {



                  this.cbBlDescOuterPkgType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
                  );
                  /*this.cbBlDescOuterPkgType.setValue(
                    this.paramDesc.blDescOuterPackageTypeCode.replace(
                      "/",
                      "[-47]"
                    )
                  );*/
                  this.cbBlDescOuterPkgType.setForceValue(
                    this.paramDesc.blDescOuterPackageTypeName
                  );
                }

                if (this.paramDesc.blDescPsaPackageTypeCode !== "") {
                  this.cbBlDescPSAPackageType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPackageTypes/findByComboBoxControl/packageTypeCode={query}"
                  );
                  // this.cbBlDescPSAPackageType.setValue(
                  //   this.paramDesc.blDescPsaPackageTypeCode.replace(
                  //     "/",
                  //     "[-47]"
                  //   )
                  // );
                  this.cbBlDescPSAPackageType.setForceValue(
                    this.paramDesc.blDescPsaPackageTypeName
                  );
                }

                if (this.paramDesc.blDescReceiptTypeCode !== "") {
                  this.cbBlDescReceiptType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
                  );
                  this.cbBlDescReceiptType.setValue(
                    this.paramDesc.blDescReceiptTypeCode
                  );
                }

                if (this.paramDesc.blDescReceiptTypeCode !== "") {
                  this.cbBlDescDeliveryType.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
                  );
                  this.cbBlDescDeliveryType.setValue(
                    this.paramDesc.blDescDeliveryTypeCode
                  );
                }

                if (this.paramDesc.blDescCommodityTypeId !== "") {
                  this.cbBlDescCommodityCode.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCommodities/findByComboBoxControl/commodityId={query}"
                  );
                  this.cbBlDescCommodityCode.setValue(
                    this.paramDesc.blDescCommodityTypeId
                  );
                }

                // GSO-992 GSO-993
                let reworkedDescriptGoodTextList = this.gridBlContainer.store.map(item=>{
                  let text = "";
                  if(item.blContainerIsReworkedContainer && !this.paramDesc.blDescDescriptionLock){
                    if (item.blContainerReworkedFrom  === true) {
                      text = "*Reworked Container from " + "\r\n" +
                        item.blContainerReworkedContainerNumber + " on " +
                        item.blContainerReworkedBlRef + "\r\n" +
                        item.blContainerReworkedVesselName + " V." +
                        item.blContainerReworkedVesselVoyage
                    }
                    if (item.blContainerReworkedTo === true) {
                      text = "*Reworked Container to " + "\r\n" +
                        item.blContainerReworkedContainerNumber + " on " +
                        item.blContainerReworkedBlRef + "\r\n" +
                        item.blContainerReworkedVesselName + " V." +
                        item.blContainerReworkedVesselVoyage
                    }
                    return text;
                  }
                })

                let textDescDescriptionOfGoods =  this.paramDesc.blDescDescriptionText + "\r\n" + reworkedDescriptGoodTextList.join("\r\n")
                this.paramDesc.blDescDescriptionText = textDescDescriptionOfGoods
                // this.txtBlDescDescriptionOfGoods.setValue(decodeURI(this.paramDesc.blDescDescriptionText));
                try {
                  this.txtBlDescDescriptionOfGoods.setValue(
                    decodeURI(this.paramDesc.blDescDescriptionText)
                  );
                } catch (e) {
                  this.txtBlDescDescriptionOfGoods.setValue(
                    this.paramDesc.blDescDescriptionText
                  );
                }

                this.modeFormBlDesc = "edit";
                this.gridBlDesc.onSelect(true, 1);

                // lock other tab
                this.lockParty = true;
                this.lockPlace = true;
                this.lockContainer = true;
                this.lockMarkNo = true;
                this.lockDesc = false;
                this.lockNettCharge = true;
                this.lockGrossCharge = true;
                this.lockNettChargeConsole = true;
                this.lockGrossChargeConsole = true;
              }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  btnBlDescNew() {
    this.modeFormBlDesc = "edit";
    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
  }

  utf8Decode(utf8String) {
    if (typeof utf8String != "string")
      throw new TypeError("parameter ‘utf8String’ is not a string");
    // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
    const unicodeString = utf8String
      .replace(
        /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g, // 3-byte chars
        function (c) {
          // (note parentheses for precedence)
          var cc =
            ((c.charCodeAt(0) & 0x0f) << 12) |
            ((c.charCodeAt(1) & 0x3f) << 6) |
            (c.charCodeAt(2) & 0x3f);
          return String.fromCharCode(cc);
        }
      )
      .replace(
        /[\u00c0-\u00df][\u0080-\u00bf]/g, // 2-byte chars
        function (c) {
          // (note parentheses for precedence)
          var cc = ((c.charCodeAt(0) & 0x1f) << 6) | (c.charCodeAt(1) & 0x3f);
          return String.fromCharCode(cc);
        }
      );
    return unicodeString;
  }

  utf8Encode(unicodeString) {
    if (typeof unicodeString != "string")
      throw new TypeError("parameter ‘unicodeString’ is not a string");
    const utf8String = unicodeString
      .replace(
        /[\u0080-\u07ff]/g, // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
        function (c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(0xc0 | (cc >> 6), 0x80 | (cc & 0x3f));
        }
      )
      .replace(
        /[\u0800-\uffff]/g, // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
        function (c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(
            0xe0 | (cc >> 12),
            0x80 | ((cc >> 6) & 0x3f),
            0x80 | (cc & 0x3f)
          );
        }
      );
    return utf8String;
  }

  btnBlDescUpdate() {
    // validation for nett weight and gross weight first
    this.isErrorDesc = false;
    this.paramDescValidateMessage = [];
    const desArrValue = [];

    if (!this.isErrorDesc) {
      // running null/undefinded,NaN check
      if (
        this.paramDesc.blDescNettWeight == null ||
        this.paramDesc.blDescNettWeight === undefined
      ) {
        this.paramDesc.blDescNettWeight = 0;
      }
      if (
        this.paramDesc.blDescGrossWeight == null ||
        this.paramDesc.blDescGrossWeight === undefined || this.paramDesc.blDescGrossWeight == "" || this.paramDesc.blDescGrossWeight == 'NaN'
      ) {
        this.paramDesc.blDescGrossWeight = 0;
      }
      if (
        this.paramDesc.blDescOuterQuantity == null ||
        this.paramDesc.blDescOuterQuantity === undefined
      ) {
        this.paramDesc.blDescOuterQuantity = 0;
      }
      if (
        this.paramDesc.blDescInnerQuantity == null ||
        this.paramDesc.blDescInnerQuantity === undefined
      ) {
        this.paramDesc.blDescInnerQuantity = 0;
      }
      if (
        this.paramDesc.blDescGrossMeasure == null ||
        this.paramDesc.blDescGrossMeasure === undefined
      ) {
        this.paramDesc.blDescGrossMeasure = 0;
      }
      if (
        this.paramDesc.blDescNettMeasure == null ||
        this.paramDesc.blDescNettMeasure === undefined
      ) {
        this.paramDesc.blDescNettMeasure = 0;
      }
      if (
        this.paramDesc.blDescAwkward == "" ||
        this.paramDesc.blDescAwkward === undefined
      ) {
        this.paramDesc.blDescAwkward = "";
      }
      if (
        this.paramDesc.blDescDg2 == "" ||
        this.paramDesc.blDescDg2 === undefined
      ) {
        this.paramDesc.blDescDg2 = "";
      }
      if (
        this.paramDesc.blDescDg2R == "" ||
        this.paramDesc.blDescDg2R === undefined
      ) {
        this.paramDesc.blDescDg2R = "";
      }

      /* if (Number.parseFloat(this.paramDesc.blDescNettWeight.toString()) === 0 &&
        Number.parseFloat(this.paramDesc.blDescGrossWeight.toString()) === 0) {
        this.isErrorDesc = false;
        this.paramDesc['error-blDescWeight'] = '';
      } else {
        if (Number.parseFloat(this.paramDesc.blDescNettWeight.toString()) >
          Number.parseFloat(this.paramDesc.blDescGrossWeight.toString())) {
          this.isErrorDesc = true;
          //this.paramDesc['error-blDescWeight'] = 'Nett Weight cannot be equal or greater than Gross Weight.';
          this.paramDesc['error-blDescWeight'] = 'Nett Weight greater than Gross Weight.';
        } else {
          this.isErrorDesc = false;
          this.paramDesc['error-blDescWeight'] = '';
        }
      }
    }*/

      if (
        this.parseCommaSeparatorNumber(this.paramDesc.blDescDg2R.toString()) === 0 &&
        this.parseCommaSeparatorNumber(this.paramDesc.blDescDg2.toString()) === 0
      ) {
        this.isErrorDesc = false;
        this.paramDesc["error-blDescWeight"] = "";
      } else {
        if (
          this.parseCommaSeparatorNumber(this.paramDesc.blDescDg2R.toString()) >
          this.parseCommaSeparatorNumber(this.paramDesc.blDescDg2.toString())
        ) {
          this.isErrorDesc = true;
          //this.paramDesc['error-blDescWeight'] = 'Nett Weight cannot be equal or greater than Gross Weight.';
          this.paramDesc["error-blDescWeight"] =
            "Nett Weight greater than Gross Weight.";
        } else {
          this.isErrorDesc = false;
          this.paramDesc["error-blDescWeight"] = "";
        }
      }
    }

    // validation for the Inner Package Type

    // this.checkMissingInnerPackageType();

    // validation for the outer quantity and outer package

    // QA-996 fix issue display message validate in Description tab
    if (!this.isErrorDesc){
      if (
        (!this.paramDesc.blDescOuterQuantity ||
          this.paramDesc.blDescOuterQuantity == 0) &&
        this.paramDesc.blDescOuterPackageTypeCode != null &&
        this.paramDesc.blDescOuterPackageTypeCode !== ""
      ) {
        this.isErrorDesc = true;
        this.paramDesc["error-blDescOuterQuantity"] =
          "Missing Outer Qty / Missing Outer Pkg Type";
      }else{
        this.isErrorDesc = false;
        this.paramDesc["error-blDescOuterQuantity"] = "";
      }

      if (
        (!this.paramDesc.blDescOuterPackageTypeCode ||
          this.paramDesc.blDescOuterPackageTypeCode === "") &&
        this.paramDesc.blDescOuterQuantity != null &&
        this.paramDesc.blDescOuterQuantity > 0
      ) {
        this.isErrorDesc = true;
        this.paramDesc["error-blDescOuterQuantity"] =
          "Missing Outer Qty / Missing Outer Pkg Type";
      }else{
        this.isErrorDesc = false;
        this.paramDesc["error-blDescOuterQuantity"] = "";
      }
    }

    // validation for nett measure and gross measure first
    if (!this.isErrorDesc) {
      if (
        Number.parseFloat(this.paramDesc.blDescNettMeasure.toString()) === 0 &&
        Number.parseFloat(this.paramDesc.blDescAwkward.toString()) === 0
      ) {
        this.isErrorDesc = false;
        this.paramDesc["error-blDescMeasurement"] = "";
      } else {
        if (
          Number.parseFloat(this.paramDesc.blDescNettMeasure.toString()) >
          Number.parseFloat(this.paramDesc.blDescAwkward.toString())
        ) {
          this.isErrorDesc = true;
          //this.paramDesc['error-blDescMeasurement'] = 'Nett Measurement cannot be equal or greater than Gross Measurement.';
          this.paramDesc["error-blDescMeasurement"] =
            "Nett Measurement greater than Gross Measurement.";
        } else {
          this.isErrorDesc = false;
          this.paramDesc["error-blDescMeasurement"] = "";
        }
      }
    }

    if (!this.isErrorDesc) {
      // this.paramDesc.blDescDescriptionText = decodeURI(this.txtBlDescDescriptionOfGoods.getValue());
      var encodeUri = this.utf8Encode(
        this.txtBlDescDescriptionOfGoods.getValue()
      );
      try {
        this.paramDesc.blDescDescriptionText =
          this.sCharUtil.decodeURIComponentSafe(this.utf8Decode(encodeUri), 1);
        console.log("nojan terus");
      } catch (e) {
        this.paramDesc.blDescDescriptionText =
          this.txtBlDescDescriptionOfGoods.getValue();
        console.log("nojan error");
      }

      let descClean: String = "";
      if (
        this.txtBlDescDescriptionOfGoods.getValue().toString().length <= 1 ||
        this.txtBlDescDescriptionOfGoods.getValue().toString() === "↵"
      ) {
        descClean = "";
      } else {
        console.log("###");

        for (
          let i = 0;
          i < this.paramDesc.blDescDescriptionText.split("↵").length;
          i++
        ) {
          if (i === 0) {
            descClean +=
              this.paramDesc.blDescDescriptionText.split("↵")[i] + "<br/>";
          } else if (i === 1) {
            descClean += this.paramDesc.blDescDescriptionText.split("↵")[i];
          }
        }

        for (
          let i = 0;
          i < this.paramDesc.blDescDescriptionText.split("↵").length;
          i++
        ) {
          //console.log(this.paramDesc.blDescDescriptionText.split('↵')[i] + " = " + this.paramDesc.blDescDescriptionText.split('↵')[i].length)
          let sum: number = 0;
          try {
            sum = decodeURIComponent(
              this.paramDesc.blDescDescriptionText.split("↵")[i]
            ).length;
          } catch (e) {
            sum = this.paramDesc.blDescDescriptionText.split("↵")[i].length;
          }
          if (sum > 34) {
            this.isErrorDesc = true;
            this.paramDesc["error-blDescSumChars"] =
              "Long sentences detect, please re-check your description.";
            return false;
          } else {
            this.isErrorDesc = false;
            this.paramDesc["error-blDescSumChars"] = "";
          }
        }
      }

      // check value after decimal point (.)
      if(!this.isErrorDesc){
        desArrValue.push(
          { fieldName: 'Nett Measurement', value: this.paramDesc['blDescNettMeasure'] },
          { fieldName: 'Gross Weight', value: this.paramDesc['blDescDg2'] },
          { fieldName: 'Nett Weight', value: this.paramDesc['blDescDg2R'] },
          { fieldName: 'Gross Measurement', value: this.paramDesc['blDescAwkward'] },
          { fieldName: 'VGM', value: String(this.paramDesc['blDescVgm']) }
        );
        desArrValue.forEach((str,index) => {
          if(str.value && str.value.split(".").pop() == '' && str.value != ''){
            this.isErrorDesc = true;
            this.paramDescValidateMessage.push(`Blank value after decimal point (.) detected at ${str.fieldName}. \n Either remove irrelevant decimal point or revise value input.`)
          }
        });
        if (this.paramDescValidateMessage.length > 0) {
          return false;
        }else {
          this.isErrorDesc = false;
          this.paramDesc["error-blDescVoidDot"] = "";
        }
      }

      //add validation calculator 34 chars
      if (!this.isErrorDesc) {
        //this.paramDesc.blDescDescriptionTextClean = decodeURI(descClean.toString());
        //console.log(this.sCharUtil.utf8Encode(descClean.toString()));
        try {
          this.paramDesc.blDescDescriptionTextClean =
            this.sCharUtil.decodeLongTextUriComponentSafe(descClean.toString());
          this.paramDesc.blDescDescriptionText =
            this.sCharUtil.decodeLongTextUriComponentSafe(
              this.paramDesc.blDescDescriptionText
            );
          /*
          this.paramDesc.blDescDescriptionTextClean = this.sCharUtil.decodeURIComponentSafe(this.sCharUtil.utf8Decode(
            this.sCharUtil.utf8Encode(descClean.toString())
          ), 1);
          this.paramDesc.blDescDescriptionText = this.sCharUtil.decodeURIComponentSafe(this.sCharUtil.utf8Decode(
            this.sCharUtil.utf8Encode(this.paramDesc.blDescDescriptionText)
          ),1);
          */
          console.log("no error");
        } catch (e) {
          this.paramDesc.blDescDescriptionTextClean = descClean.toString();
          this.paramDesc.blDescDescriptionText =
            this.paramDesc.blDescDescriptionText;

          console.log("error occur");
        }

        //GOS-714 Issue lies on <Nett Measurement> field on both container & description tab
        // if (this.blGrossWeightNum != 0 || this.blGrossWeightNum != null) {
        //   this.paramDesc.blDescGrossWeight = Number.parseFloat(
        //     this.blGrossWeightNum.toString()
        //   );
        // }
        // if (this.blNettWeightNum != 0 || this.blNettWeightNum != null) {
        //   this.paramDesc.blDescNettWeight = Number.parseFloat(
        //     this.blNettWeightNum.toString()
        //   );
        // }
        // if (this.blGrossMeasureNum != 0 || this.blGrossMeasureNum != null) {
        //   this.paramDesc.blDescGrossMeasure = Number.parseFloat(
        //     this.blGrossMeasureNum.toString()
        //   );
        // }



        this.paramDesc.blDescDg2 = this.paramDesc.blDescDg2;
        this.paramDesc.blDescDg2R = this.paramDesc.blDescDg2R;
        this.paramDesc.blDescNettMeasure = this.paramDesc.blDescNettMeasure;
        this.paramDesc.blDescAwkward = this.paramDesc.blDescAwkward;
        this.paramDesc.blDescVgm = this.paramDesc.blDescVgm;
        this.paramDesc.blDescVgmLock = this.blDescVgmLock ? "Y" : "N";
        this.paramDesc.blDescNettWeightLock = this.paramDesc.blDescNettWeightLock;
        this.paramDesc.blDescNettMeasLock = this.paramDesc.blDescNettMeasLock;

        this.gridBlDesc.url = "";
        // this.gridBlDesc.listStore.updateData((this.gridBlDesc.getSelectedValues()[0].blDescSeq - 1), this.paramDesc);
        this.gridBlDesc.listStore.updateData(
          this.paramDesc.blDescSeq - 1,
          this.paramDesc
        );

        this.gridBlDesc.clearSelectedValues();
        this.gridBlDesc.loadData();

        if (this.paramDesc.blDescOuterQuantity > 0) {
          this.genericService
            .GET(
              this.configService.config.BASE_API.toString() +
                "/BL/cashToWords/" +
                this.paramDesc.blDescOuterQuantity
            )
            .subscribe((resp) => {
              if (resp.json()["status"] === "OK") {
                this.txtBlDescTotalContainerPackageInWord.setValue(
                  resp.json()["message"] +
                    " " +
                    this.paramDesc.blDescOuterPackageTypeName.trim() +
                    " ONLY"
                );
                this.btnBlDescCancel();
                this.isRefreightButtonClicked = false;
              } else {
                this.btnBlDescCancel();
                this.isRefreightButtonClicked = false;
              }
            });
        } else {
          this.txtBlDescTotalContainerPackageInWord.setValue("");
          this.btnBlDescCancel();
          this.isRefreightButtonClicked = false;
        }

        //this.btnBlDescCancel();
        //this.isRefreightButtonClicked = false;
      }
    }
  }

  btnBlDescDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteDesc",
      "Confirmation",
      350,
      150
    );
  }

  btnBlDescCancel() {
    this.paramDesc = new BLDesc();
    this.isErrorDesc = false;
    this.modeFormBlDesc = "view";

    this.cbBlDescOuterPkgType.setValue("");
    this.cbBlDescInnerPkgType.setValue("");
    this.cbBlDescCommodityCode.setValue("");
    this.cbBlDescReceiptType.setValue("");
    this.cbBlDescDeliveryType.setValue("");
    this.cbBlDescPSAPackageType.setValue("");

    // release the tab
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }

    if(this.viewOnlyStatus){
      console.log("Masuk cancel description");
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
     }else{
        if (this.blLock == "N") {
          // When the status is cleared or locked, only view
          if(this.model.blStatus === "D" || this.isOpenedByOtherUser === true){
            this.lockTab5 = true;
          }else{
            this.lockTab5 = false;
          }
        } else if (this.blLock == "Y") {
          this.disableToolbarButtons =
            "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
          this.lockTab5 = true;
        } else if (this.blLock == "T1") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T2") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T3") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T4") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T5") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = false;
        } else if (this.blLock == "T6") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T7") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = true;
        } else if (this.blLock == "T8") {
          this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
          this.lockTab5 = false;
        }
    }
  }

  changeNettWeightNum(event) {
    this.blNettWeightNum = this.parseCommaSeparatorNumber(
      this.paramDesc.blDescDg2R.toString()
    );
  }
  changeGrossWeightNum(event) {
    this.blGrossWeightNum = this.parseCommaSeparatorNumber(
      this.paramDesc.blDescDg2.toString()
    );
  }
  changeGrossMeasureNum(event) {
    this.blGrossMeasureNum = Number.parseFloat(
      this.paramDesc.blDescAwkward.toString()
    );
  }

  changeEventCbBlDescReceiptType(event) {
    if (
      event.receiptTypeCode != null &&
      event.receiptTypeCode !== "" &&
      event.receiptTypeCode !== undefined
    ) {
      this.paramDesc.blDescReceiptTypeCode = event.receiptTypeCode;
    } else {
      this.paramDesc.blDescReceiptTypeCode = "";
    }
    this.cbBlDescReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeLike={query}"
    );
  }

  changeEventCbBlDescDeliveryType(event) {
    if (
      event.deliveryTypeCode != null &&
      event.deliveryTypeCode !== "" &&
      event.deliveryTypeCode !== undefined
    ) {
      this.paramDesc.blDescDeliveryTypeCode = event.deliveryTypeCode;
    } else {
      this.paramDesc.blDescDeliveryTypeCode = "";
    }
    this.cbBlDescDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
    );
  }

  changeEventCbBlDescPSAPackageType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramDesc.blDescPsaPackageTypeCode = event.packageTypeCode;
      this.paramDesc.blDescPsaPackageTypeName = event.packageTypeName;
    } else {
      this.paramDesc.blDescPsaPackageTypeCode = "";
      this.paramDesc.blDescPsaPackageTypeName = "";
    }
    this.cbBlDescPSAPackageType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlDescInnerPkgType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramDesc.blDescInnerPackageTypeCode = event.packageTypeCode;
      this.paramDesc.blDescInnerPackageTypeName = event.packageTypeName;
      this.paramDesc["error-cbBlDescInnerPkgType"] = "";
      this.checkIsErrorDesc();
    } else {
      this.paramDesc.blDescInnerPackageTypeCode = "";
      this.paramDesc.blDescInnerPackageTypeName = "";
    }
    this.cbBlDescInnerPkgType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlContainerInnerPkgType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramContainerExtra.blContainerInnerPackageTypeCode =
        event.packageTypeCode;
      this.paramContainerExtra.blContainerInnerPackageTypeName =
        event.packageTypeName;
    } else {
      this.paramContainerExtra.blContainerInnerPackageTypeCode = "";
      this.paramContainerExtra.blContainerInnerPackageTypeName = "";
    }
    this.cbBlContainerInnerPkgType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlContainerCommodity(event) {
    if (
      event.commodityId != null &&
      event.commodityId !== "" &&
      event.commodityId !== undefined
    ) {
      // this.paramContainerExtra.blContainerCommodityTypeId = event.commodityId;
      // this.paramContainerExtra.blContainerCommodityTypeName =
      //   event.commodityName;
      this.tempBlContainerCommodityTypeId = event.commodityId
      this.tempBlContainerCommodityTypeName = event.commodityName
    } else {
      this.tempBlContainerCommodityTypeId = "";
      this.tempBlContainerCommodityTypeName = "";
      // this.paramContainerExtra.blContainerCommodityTypeId = "";
      // this.paramContainerExtra.blContainerCommodityTypeName = "";
    }
    this.cbBlContainerCommodity.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}"
    );
  }

  changeEventCbBlDescOuterPkgType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramDesc.blDescOuterPackageTypeCode = event.packageTypeCode;
      this.paramDesc.blDescOuterPackageTypeName = event.packageTypeName;
    } else {
      this.paramDesc.blDescOuterPackageTypeCode = "";
      this.paramDesc.blDescOuterPackageTypeName = "";
    }
    this.cbBlDescOuterPkgType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlDescCommodityCode(event) {
    if (
      event.commodityId != null &&
      event.commodityId !== "" &&
      event.commodityId !== undefined
    ) {
      this.paramDesc.blDescCommodityTypeId = event.commodityId;
      this.paramDesc.blDescCommodityTypeName = event.commodityName;
    } else {
      this.paramDesc.blDescCommodityTypeId = "";
      this.paramDesc.blDescCommodityTypeName = "";
    }
    this.cbBlDescCommodityCode.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCommodities/findByComboBoxControl/commodityName={query}"
    );
  }

  // GOS-506 To set COMMODITY field in Additional Details section as FREE-TEXT
  // changeEventCbBlAdContainerCommodity(event) {
  //   if (
  //     event.commodityId != null &&
  //     event.commodityId !== "" &&
  //     event.commodityId !== undefined
  //   ) {
  //     this.paramBlAdContainer.blAdContainerCommodityTypeId = event.commodityId;
  //     this.paramBlAdContainer.blAdContainerCommodityTypeName = event.commodityName;
  //   } else {
  //     this.paramBlAdContainer.blAdContainerCommodityTypeId = "";
  //     this.paramBlAdContainer.blAdContainerCommodityTypeName = "";
  //   }
  //   this.cbBlAdContainerCommodity.setUrl(
  //     this.configService.config.BASE_API.toString() +
  //       "/MasterCommodities/findByComboBoxControl/commodityName={query}"
  //   );

  //   // console.log(this.configService.config.BASE_API.toString() +
  //   // "/MasterCommodities/findByComboBoxControl/commodityName={query}");
  // }

  changeCommodity(event){
    if(!event){
      this.paramBlAdContainer['error-blAdContainerCommodityTypeId'] = "Please input Commodity.";
    }else{
      this.paramBlAdContainer["error-blAdContainerCommodityTypeId"] = "";
    }
  }

  changeEventCbBlAdContainerOuterPkgType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerOuterPkgTypeCode = event.packageTypeCode;
      this.paramBlAdContainer.blAdContainerOuterPkgTypeName = event.packageTypeName;
    } else {
      this.paramBlAdContainer.blAdContainerOuterPkgTypeCode = "";
      this.paramBlAdContainer.blAdContainerOuterPkgTypeName = "";
    }
    this.cbBlAdContainerOuterPkgType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  changeEventCbBlAdContainerInnerPkgType(event) {
    if (
      event.packageTypeId != null &&
      event.packageTypeId !== "" &&
      event.packageTypeId !== undefined
    ) {
      this.paramBlAdContainer.blAdContainerInnerPkgTypeCode = event.packageTypeCode;
      this.paramBlAdContainer.blAdContainerInnerPkgTypeName = event.packageTypeName;
    } else {
      this.paramBlAdContainer.blAdContainerInnerPkgTypeCode = "";
      this.paramBlAdContainer.blAdContainerInnerPkgTypeName = "";
    }
    this.cbBlAdContainerInnerPkgType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}"
    );
  }

  tabDescClicked() {
    this.tabStatus = "tab5";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "active";
    this.tabStatus6 = "";
    this.tabStatus7 = "";

    this.cbBlDescCommodityCode.setValue("");

    if (this.gridBlDesc.listStore.store.length <= 0) {
      this.genDesc();

      this.gridBlDesc.onClear();
      this.gridBlDesc.url = "";
      this.gridBlDesc.listStore.store.push(this.paramDesc);
      this.gridBlDesc.loadData();
    } else {
      // When user click on the desc tab, but the detail has been previously generated
      // Must check for gross weight and gross meas lock status
      // When not locked, must trigger the recalculation
      // gross weight

      this.gridBlDesc.listStore.store[0].blDescVgm = this.gridBlDesc.listStore.store[0].blDescVgm;
      if (this.gridBlDesc.listStore.store[0].blDescVgmLock !== "Y") {
        this.calculateVgm();
        this.gridBlDesc.listStore.store[0].blDescVgm = this.totalVGM;
      }

      /*
        GOS-417 Fix Inconsistent display of Nett Weight/ Gross Wt/ Gross Meas
      */
      this.gridBlDesc.listStore.store[0].blDescGrossWeight = this.gridBlDesc.listStore.store[0].blDescDg2;
      this.gridBlDesc.listStore.store[0].blDescGrossWeightString = this.gridBlDesc.listStore.store[0].blDescDg2;
      this.gridBlDesc.listStore.store[0].blDescGrossMeasure = this.gridBlDesc.listStore.store[0].blDescAwkward;
      this.gridBlDesc.listStore.store[0].blDescGrossMeasureString = this.gridBlDesc.listStore.store[0].blDescAwkward;
      this.gridBlDesc.listStore.store[0].blDescNettWeight = this.gridBlDesc.listStore.store[0].blDescDg2R;
      this.gridBlDesc.listStore.store[0].blDescNettWeightString = this.gridBlDesc.listStore.store[0].blDescDg2R;
      this.gridBlDesc.listStore.store[0].blDescNettMeasure = this.gridBlDesc.listStore.store[0].blDescNettMeasure;
      this.gridBlDesc.listStore.store[0].blDescNettMeasureString = this.gridBlDesc.listStore.store[0].blDescNettMeasureString;

      if (this.gridBlDesc.listStore.store[0].blDescGrossWeightLock !== true) {
        this.calculateGrossWeightWhenUnLock();
        this.gridBlDesc.listStore.store[0].blDescGrossWeight = this.totalblDescGrossWeightLock;
        // this.gridBlDesc.listStore.store[0].blDescDg2 = totalGrossWt.toString();
        this.gridBlDesc.listStore.store[0].blDescGrossWeightString = this.totalblDescGrossWeightLock;
        this.gridBlDesc.listStore.store[0].blDescDg2 = this.totalblDescGrossWeightLock;
      }
      if (this.gridBlDesc.listStore.store[0].blDescGrossMeasLock !== true) {
        this.calculateGrossMeasWhenUnLock();
        this.gridBlDesc.listStore.store[0].blDescGrossMeasure = this.totalGrossMeasblDescAwkward;
        // this.gridBlDesc.listStore.store[0].blDescAwkward = totalGrossMeas.toString();
        this.gridBlDesc.listStore.store[0].blDescGrossMeasureString = this.totalGrossMeasblDescAwkward;
        this.gridBlDesc.listStore.store[0].blDescAwkward = this.totalGrossMeasblDescAwkward;
      }

      if (this.gridBlDesc.listStore.store[0].blDescNettWeightLock !== true) {
        this.calculateNettWeightWhenUnlock();
        this.gridBlDesc.listStore.store[0].blDescNettWeight = this.totalblDescNettWeightLock;
        // this.gridBlDesc.listStore.store[0].blDescDg2 = totalGrossWt.toString();
        this.gridBlDesc.listStore.store[0].blDescNettWeightString = this.totalblDescNettWeightLock;
        this.gridBlDesc.listStore.store[0].blDescDg2R = this.totalblDescNettWeightLock;
      }

      if (this.gridBlDesc.listStore.store[0].blDescNettMeasLock !== true) {
        this.calculateNettMeasureWhenUnlock();
        this.gridBlDesc.listStore.store[0].blDescNettMeasureString = this.totalblDescNettMeasureLock;
        this.gridBlDesc.listStore.store[0].blDescNettMeasure = this.totalblDescNettMeasureLock;
      }

      // Must auto calculate the outer quantity as well
      let outerQuantity = 0;
      let innerQuantity = 0;
      console.log("kesini dulu deh : ", this.gridBlContainer.listStore.store);
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        outerQuantity =
          outerQuantity +
          Number.parseFloat(
            this.gridBlContainer.listStore.store[i].blContainerQuantity
          );
        innerQuantity =
          innerQuantity +
          Number.parseFloat(
            this.gridBlContainer.listStore.store[i].blContainerExtra
              .blContainerInnerQuantity
          );
      }
      if (this.gridBlDesc.listStore.store[0].blDescOuterQuantityLock !== true) {
        this.gridBlDesc.listStore.store[0].blDescOuterQuantity = outerQuantity;
      }

      // outer package type worker
      let outerPkgTypeCode = "";
      let outerPkgTypeName = "";
      let innerPkgTypeCode = "";
      let innerPkgTypeName = "";
      let commodityTypeId = "";
      let commodityTypeName = "";
      const outerPkgList: Array<string> = [];
      const innerPkgList: Array<string> = [];
      const commodityTypeList: Array<string> = [];
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerPackageTypeCode.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerPackageTypeName.trim() !==
          ""
        ) {
          if (
            !outerPkgList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            )
          ) {
            console.log(
              "push test --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
            outerPkgList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
          }
        }

        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerExtra.blContainerInnerPackageTypeName.trim() !==
          ""
        ) {
          if (
            !innerPkgList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            )
          ) {
            console.log(
              "push test --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            );
            innerPkgList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            );
          }
        }

        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerExtra.blContainerCommodityTypeId.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerExtra.blContainerCommodityTypeName.trim() !==
          ""
        ) {
          if (
            !commodityTypeList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeId.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerCommodityTypeName.trim()
            )
          ) {
            console.log(
              "push test commodity --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerCommodityTypeId.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerCommodityTypeName.trim()
            );
            commodityTypeList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeId.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerCommodityTypeName.trim()
            );
          }
        }
      }


      if (outerPkgList.length > 1) {
        outerPkgTypeCode = "PKGS";
        outerPkgTypeName = "PACKAGES";
      } else if (outerPkgList.length === 1) { // GOS-401
        outerPkgTypeCode = outerPkgList[0].split("~~~")[0];
        outerPkgTypeName = outerPkgList[0].split("~~~")[1];
      }

      console.log('testt inner');
      console.log(innerPkgList);
      console.log(innerPkgList.length);
      if (innerPkgList.length > 1) {
        innerPkgTypeCode = "PKGS";
        innerPkgTypeName = "PACKAGES";
      } else if (innerPkgList.length === 2) { // undecided at the moment, not touching this area during GSO-401
        innerPkgTypeCode = innerPkgList[0].split("~~~")[0];
        innerPkgTypeName = innerPkgList[0].split("~~~")[1];
      }

      if (commodityTypeList.length > 0) {
        if(commodityTypeList.every((val, i, arr) => val == arr[0])){
          commodityTypeId = commodityTypeList[0].split("~~~")[0];
          commodityTypeName = commodityTypeList[0].split("~~~")[1];
        }else{
          commodityTypeId = "CD00174";
          commodityTypeName = "GENERAL";
        }
      }else{
        commodityTypeId = "";
        commodityTypeName = "";
      }

      if (this.gridBlDesc.listStore.store[0].blDescOuterPkgTypeLock !== true) {
        this.gridBlDesc.listStore.store[0].blDescOuterPackageTypeCode =
          outerPkgTypeCode;
        this.gridBlDesc.listStore.store[0].blDescOuterPackageTypeName =
          outerPkgTypeName;
        this.gridBlDesc.listStore.store[0].blDescInnerPackageTypeCode =
          innerPkgTypeCode;
        this.gridBlDesc.listStore.store[0].blDescInnerPackageTypeName =
          innerPkgTypeName;
      }

      this.gridBlDesc.listStore.store[0].blDescCommodityTypeId =
        commodityTypeId;
      this.gridBlDesc.listStore.store[0].blDescCommodityTypeName =
        commodityTypeName;


    }

    this.gridBlDesc.loadData();
  }

  calculateGrossWeightWhenUnLock(){
    this.totalGrossWt = 0;
    this.totalblDescGrossWeightLock = '0';
    let blCtnTotalLength = 0;
    let blContainerGrossWeightList = this.gridBlContainer.listStore.store.map(x=> {
      return x.blContainerGrossWeightString ? x.blContainerGrossWeightString : "0";
    });
    const lengthAfterTheDotElement = blContainerGrossWeightList.map(item=>{
      return (item && item.includes('.')) ? item.split('.')[1].length : [0];
    })

    blCtnTotalLength = Math.max(...lengthAfterTheDotElement);
    let isDecimalElementGrossWeightList = blContainerGrossWeightList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.totalGrossWt = this.totalGrossWt + Number.parseFloat(this.gridBlContainer.listStore.store[i].blContainerGrossWeight);
    }
    if(isDecimalElementGrossWeightList === true){
      this.totalblDescGrossWeightLock = this.totalGrossWt.toString();
    }else{
      if (this.totalGrossWt === 0) {
        this.totalblDescGrossWeightLock = "0";
      } else if (this.totalGrossWt.toString().includes(".") === true) {
        let totalGross = this.parseDecimal(this.totalGrossWt);
        let totalGrossWtDecimalLength = totalGross.toString().includes(".") ? totalGross.toString().split(".")[1].length : 0;
        if (blCtnTotalLength > totalGrossWtDecimalLength) {
          this.totalblDescGrossWeightLock = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.totalblDescGrossWeightLock = totalGross;
        }
      } else if(this.totalGrossWt.toString().includes(".") === false){
        if(blCtnTotalLength > 1){
          // totalblDescGrossWeightLock = String(totalGrossWt.toString() + '.').padEnd(blCtnTotalLength, '0')
          this.totalblDescGrossWeightLock = Number(this.totalGrossWt).toFixed(blCtnTotalLength);
        }else{
          this.totalblDescGrossWeightLock = String(this.totalGrossWt.toString() + '.0');
        }
      }
    }
  }

  calculateGrossMeasWhenUnLock() {
    this.totalGrossMeas = 0;
    this.totalGrossMeasblDescAwkward = '0';
    let blCtnTotalLength = 0;
    let blContainerGrossMeasureList = this.gridBlContainer.listStore.store.map(x => {
      return x.blContainerGrossMeasureString ? x.blContainerGrossMeasureString : "0";
    });

    const lengthAfterTheDotElement = blContainerGrossMeasureList.map(item=>{
      return (item && item.includes('.')) ? item.split('.')[1].length : [0];
    })

    blCtnTotalLength = Math.max(...lengthAfterTheDotElement)
    let isDecimalElementGrossMeasureList = blContainerGrossMeasureList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.totalGrossMeas = this.totalGrossMeas + Number.parseFloat(this.gridBlContainer.listStore.store[i].blContainerGrossMeasure);
    }
    if (isDecimalElementGrossMeasureList === true) {
      this.totalGrossMeasblDescAwkward = this.totalGrossMeas.toString();
    } else {
      if (this.totalGrossMeas === 0) {
        this.totalGrossMeasblDescAwkward = "0";
      } else if (this.totalGrossMeas.toString().includes(".") === true) {
        let totalGross = this.parseDecimal(this.totalGrossMeas);
        let totalGrossDecimalLength = totalGross.toString().includes(".") ? totalGross.toString().split(".")[1].length : 0;
        if (blCtnTotalLength > totalGrossDecimalLength) {
          this.totalGrossMeasblDescAwkward = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.totalGrossMeasblDescAwkward = totalGross;
        }
      } else if (this.totalGrossMeas.toString().includes(".") === false) {
        if (blCtnTotalLength > 1) {
          this.totalGrossMeasblDescAwkward = Number(this.totalGrossMeas).toFixed(blCtnTotalLength);
        } else {
          this.totalGrossMeasblDescAwkward = String(this.totalGrossMeas.toString() + '.0')
        }
      }
    }
  }

  calculateNettWeightWhenUnlock(){
    this.totalNettWeight = 0;
    this.totalblDescNettWeightLock = '0';
    let blCtnTotalLength = 0;
    let blContainerNettWeightList = this.gridBlContainer.listStore.store.map(x=> {
      return x.blContainerNettWeightString ? x.blContainerNettWeightString : "0";
    });
    const lengthAfterTheDotElement = blContainerNettWeightList.map(item=>{
      return (item && item.includes('.')) ? item.split('.')[1].length : [0];
    })

    blCtnTotalLength = Math.max(...lengthAfterTheDotElement);
    let isDecimalElementNettWeightList = blContainerNettWeightList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.totalNettWeight = this.totalNettWeight + Number.parseFloat(this.gridBlContainer.listStore.store[i].blContainerNettWeight);
    }
    if(isDecimalElementNettWeightList === true){
      this.totalblDescNettWeightLock = this.totalNettWeight.toString();
    }else{
      if (this.totalNettWeight === 0) {
        this.totalblDescNettWeightLock = "0";
      } else if (this.totalNettWeight.toString().includes(".") === true) {
        let totalGross = this.parseDecimal(this.totalNettWeight);
        let totalNettWeightDecimalLength = totalGross.toString().includes(".") ? totalGross.toString().split(".")[1].length : 0;
        if (blCtnTotalLength > totalNettWeightDecimalLength) {
          this.totalblDescNettWeightLock = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.totalblDescNettWeightLock = totalGross;
        }
      } else if(this.totalNettWeight.toString().includes(".") === false){
        if(blCtnTotalLength > 1){
          this.totalblDescNettWeightLock = Number(this.totalNettWeight).toFixed(blCtnTotalLength);
        }else{
          this.totalblDescNettWeightLock = String(this.totalNettWeight.toString() + '.0');
        }
      }
    }
  }

  calculateNettMeasureWhenUnlock(){
    this.totalNettMeasure = 0;
    this.totalblDescNettMeasureLock = '0';
    let blCtnTotalLength = 0;
    let blContainerNettMeasurementList = this.gridBlContainer.listStore.store.map(x=> {
      return x.blContainerNettMeasureString ? x.blContainerNettMeasureString : "0";
    });
    const lengthAfterTheDotElement = blContainerNettMeasurementList.map(item=>{
      return (item && item.includes('.')) ? item.split('.')[1].length : [0];
    })

    blCtnTotalLength = Math.max(...lengthAfterTheDotElement);
    let isDecimalElementblContainerNettMeasureListList = blContainerNettMeasurementList.every(element=> !element.includes('.'));
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.totalNettMeasure = this.totalNettMeasure + Number.parseFloat(this.gridBlContainer.listStore.store[i].blContainerNettMeasureString);
    }
    if(isDecimalElementblContainerNettMeasureListList === true){
      this.totalblDescNettMeasureLock = this.totalNettMeasure.toString();
    }else{
      if (this.totalNettMeasure === 0) {
        this.totalblDescNettMeasureLock = "0";
      } else if (this.totalNettMeasure.toString().includes(".") === true) {
        let totalGross = this.parseDecimal(this.totalNettMeasure);
        let totalNettMeasureDecimalLength = totalGross.toString().includes(".") ? totalGross.toString().split(".")[1].length : 0;
        if (blCtnTotalLength > totalNettMeasureDecimalLength) {
          this.totalblDescNettMeasureLock = Number(totalGross).toFixed(blCtnTotalLength);
        } else {
          this.totalblDescNettMeasureLock = totalGross;
        }
      } else if(this.totalNettMeasure.toString().includes(".") === false){
        if(blCtnTotalLength > 1){
          this.totalblDescNettMeasureLock = Number(this.totalNettMeasure).toFixed(blCtnTotalLength);
        }else{
          this.totalblDescNettMeasureLock = String(this.totalNettMeasure.toString() + '.0');
        }
      }
    }
  }

  genDesc() {
    console.log("bldescisprinted = " + this.model.blIsDetDemPrinted);
    this.paramDesc = new BLDesc();
    this.paramDesc.blDescOfficeCode =
      this.cookieService.getDefaultLocationCode();
    this.paramDesc.blDescBlNo = this.model.blNo;
    this.paramDesc.blDescSeq = this.gridBlDesc.listStore.store.length + 1;
    this.paramDesc.blDescReceiptTypeCode =
      this.model.blReceiptTypeCode === "" ? "CY" : this.model.blReceiptTypeCode;
    this.paramDesc.blDescDeliveryTypeCode =
      this.model.blDeliveryTypeCode === ""
        ? "CY"
        : this.model.blDeliveryTypeCode;

    this.cbBlDescCommodityCode.setValue("");

    if (this.gridBlContainer.listStore.store.length > 0) {
      let descText = "";
      let descTextClean = "";
      let countStd20 = 0;
      let countStd40 = 0;
      let countStd45 = 0;
      let countStd20E = 0;
      let countStd40E = 0;
      let countStd45E = 0;
      let countStd20hc = 0;
      let countStd40hc = 0;
      let countStd45hc = 0;
      let countStd20Ehc = 0;
      let countStd40Ehc = 0;
      let countStd45Ehc = 0;

      let countFlr20 = 0;
      let countFlr40 = 0;
      let countFlr45 = 0;
      let countFlr20E = 0;
      let countFlr40E = 0;
      let countFlr45E = 0;
      let countFlr20hc = 0;
      let countFlr40hc = 0;
      let countFlr45hc = 0;
      let countFlr20Ehc = 0;
      let countFlr40Ehc = 0;
      let countFlr45Ehc = 0;

      let countOpt20 = 0;
      let countOpt40 = 0;
      let countOpt45 = 0;
      let countOpt20E = 0;
      let countOpt40E = 0;
      let countOpt45E = 0;
      let countOpt20hc = 0;
      let countOpt40hc = 0;
      let countOpt45hc = 0;
      let countOpt20Ehc = 0;
      let countOpt40Ehc = 0;
      let countOpt45Ehc = 0;

      let countPlt20 = 0;
      let countPlt40 = 0;
      let countPlt45 = 0;
      let countPlt20E = 0;
      let countPlt40E = 0;
      let countPlt45E = 0;
      let countPlt20hc = 0;
      let countPlt40hc = 0;
      let countPlt45hc = 0;
      let countPlt20Ehc = 0;
      let countPlt40Ehc = 0;
      let countPlt45Ehc = 0;

      let countRef20 = 0;
      let countRef40 = 0;
      let countRef45 = 0;
      let countRef20E = 0;
      let countRef40E = 0;
      let countRef45E = 0;
      let countRef20hc = 0;
      let countRef40hc = 0;
      let countRef45hc = 0;
      let countRef20Ehc = 0;
      let countRef40Ehc = 0;
      let countRef45Ehc = 0;

      let countDry20 = 0;
      let countDry40 = 0;
      let countDry45 = 0;
      let countDry20E = 0;
      let countDry40E = 0;
      let countDry45E = 0;
      let countDry20hc = 0;
      let countDry40hc = 0;
      let countDry45hc = 0;
      let countDry20Ehc = 0;
      let countDry40Ehc = 0;
      let countDry45Ehc = 0;

      let countTnk20 = 0;
      let countTnk40 = 0;
      let countTnk45 = 0;
      let countTnk20E = 0;
      let countTnk40E = 0;
      let countTnk45E = 0;
      let countTnk20hc = 0;
      let countTnk40hc = 0;
      let countTnk45hc = 0;
      let countTnk20Ehc = 0;
      let countTnk40Ehc = 0;
      let countTnk45Ehc = 0;

      let countUc20 = 0;
      let countUc40 = 0;
      let countUc45 = 0;
      let countUc20E = 0;
      let countUc40E = 0;
      let countUc45E = 0;
      let countUc20hc = 0;
      let countUc40hc = 0;
      let countUc45hc = 0;
      let countUc20Ehc = 0;
      let countUc40Ehc = 0;
      let countUc45Ehc = 0;

      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        if (
          this.gridBlContainer.listStore.store[i].blContainerIsEmpty === true
        ) {
          // NORMAL
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45Ehc++;
            }
          }

          // FLAT RACK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45Ehc++;
            }
          }

          // OPEN TOP
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45Ehc++;
            }
          }

          // PLATFORM
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45Ehc++;
            }
          }

          if (
            this.gridBlContainer.listStore.store[i].blContainerIsDry === true
          ) {
            // REEFER DRY
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countDry20E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countDry20Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countDry40E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countDry40Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countDry45E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countDry45Ehc++;
              }
            }
          } else {
            // REEFER
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countRef20E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countRef20Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countRef40E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countRef40Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countRef45E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countRef45Ehc++;
              }
            }
          }

          // TANK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45Ehc++;
            }
          }

          // UC
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45Ehc++;
            }
          }
        } else {
          // NORMAL
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45hc++;
            }
          }

          // FLAT RACK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45hc++;
            }
          }

          // OPEN TOP
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45hc++;
            }
          }

          // PLATFORM
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45hc++;
            }
          }

          if (
            this.gridBlContainer.listStore.store[i].blContainerIsDry === true
          ) {
            // REEFER DRY
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countDry20++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countDry20hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countDry40++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countDry40hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countDry45++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countDry45hc++;
              }
            }
          } else {
            // REEFER
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countRef20++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "20"
              ) {
                countRef20hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countRef40++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "40"
              ) {
                countRef40hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countRef45++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize === "45"
              ) {
                countRef45hc++;
              }
            }
          }

          // TANK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45hc++;
            }
          }

          // UC
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45hc++;
            }
          }
        }
      }

      // COC
      let blContainerOwnershipText = "";
      let blDescEnding = "";
      if (this.model.blContainerOwnership === "COC") {
        blContainerOwnershipText = "COC LOCAL";
        /**
         // 00. Detection of Detention and Demurrage
         if (this.model.blDetentionRequest > 0 || this.model.blDemurrageRequest > 0 ) {
          blDescEnding = 'DETENTION IS ' + this.model.blDetentionRequest +
            ' DAYS AND DEMURRAGE IS ' + this.model.blDemurrageRequest + ' DAYS';
        }


         // 01. Detection of OOG
         const oogList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let oogInfo = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            oogInfo = 'OH=' + this.gridBlContainer.listStore.store[i].blContainerOogOH;
            oogInfo += ' ' + 'OWL=' + this.gridBlContainer.listStore.store[i].blContainerOogOWL;
            oogInfo += ' ' + 'OWR=' + this.gridBlContainer.listStore.store[i].blContainerOogOWR;
            oogInfo += ' ' + 'OLF=' + this.gridBlContainer.listStore.store[i].blContainerOogOLF;
            oogInfo += ' ' + 'OLB=' + this.gridBlContainer.listStore.store[i].blContainerOogOLB;
            oogInfo += ' ' + 'SCALE=' + this.gridBlContainer.listStore.store[i].blContainerOogScale;
            if (i === 0) {
              if ((this.gridBlContainer.listStore.store[i].blContainerOogOH +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLB) > 0) {
                oogList.push(oogInfo);
              }
            } else {
              if (!oogList.includes(oogInfo)) {
                if ((this.gridBlContainer.listStore.store[i].blContainerOogOH +
                    this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                    this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                    this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                    this.gridBlContainer.listStore.store[i].blContainerOogOLB) > 0) {
                  oogList.push(oogInfo);
                }
              }
            }

          }
        }
         if (oogList.length > 0) {
          for (const entry of oogList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 02. Detection of DG Class
         const dgClassList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let dgClass = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            dgClass = 'DG CLASS=' + this.gridBlContainer.listStore.store[i].blContainerDGClass;
            if (!dgClassList.includes(dgClass)) {
              if (this.gridBlContainer.listStore.store[i].blContainerDGClass !== 'N/A') {
                dgClassList.push(dgClass);
              }
            }
          }
        }
         if (dgClassList.length > 0) {
          for (const entry of dgClassList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 03. Detection of IMO & UNNO & F/P
         const imoUnnoList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let imoUnno = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            imoUnno = 'IMO=' + this.gridBlContainer.listStore.store[i].blContainerIMO + ' ' + 'UNNO=' +
              this.gridBlContainer.listStore.store[i].blContainerUNNO + ' ' + 'F/P=' +
              ((this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature > 0) ? '+' + this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature : this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature ) +
              this.gridBlContainer.listStore.store[i].blContainerFlashPointScale;
            if (!imoUnnoList.includes(imoUnno)) {
              if (this.gridBlContainer.listStore.store[i].blContainerIMO !== '' &&
                this.gridBlContainer.listStore.store[i].blContainerUNNO !== '' &&
                this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature !== 0 &&
                this.gridBlContainer.listStore.store[i].blContainerFlashPointScale !== '') {
                imoUnnoList.push(imoUnno);
              }
            }
          }
        }
         if (imoUnnoList.length > 0) {
          for (const entry of imoUnnoList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 04. Detection of the Reefer Temperature
         const reeferTempList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let reeferTemp = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            reeferTemp = 'REEFER TEMP.=' + ((this.gridBlContainer.listStore.store[i].blContainerReeferTemperature > 0) ? '+' + this.gridBlContainer.listStore.store[i].blContainerReeferTemperature : this.gridBlContainer.listStore.store[i].blContainerReeferTemperature ) +
              this.gridBlContainer.listStore.store[i].blContainerReeferScale;
            if (!reeferTempList.includes(reeferTemp)) {
              if (this.gridBlContainer.listStore.store[i].blContainerReeferTemperature !== 0 &&
                this.gridBlContainer.listStore.store[i].blContainerReeferScale !== '' ) {
                reeferTempList.push(reeferTemp);
              }
            }
          }
        }
         if (reeferTempList.length > 0) {
          for (const entry of reeferTempList) {
            blDescEnding += '\n' + entry;
          }
        }



         // 05. Detection of the HS Code List
         const hsCodeList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            if (this.gridBlContainer.listStore.store[i].blContainerHSCode.length > 0 ) {
              let hsCodes = '';
              for (let j = 0 ; j < this.gridBlContainer.listStore.store[i].blContainerHSCode.length; j++) {
                if (this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSPrint === true) {
                  hsCodes = this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSCode + ' ' +
                    this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSDescription ;
                  if (!hsCodeList.includes(hsCodes)) {
                    hsCodeList.push(hsCodes);
                  }
                }
              }
            }
          }
        }
         if (hsCodeList.length > 0) {
          for (const entry of hsCodeList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 06. Detection of the Shortshipment
         const shortshipmentList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let shortshipmet = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            if (this.gridBlContainer.listStore.store[i].blContainerShortShipContainer === true &&
              this.gridBlContainer.listStore.store[i].blContainerIsPrintShortShipmentInfoOnBL === true) {
              if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentFrom === true) {
                shortshipmet = '*SHORTSHIPPED FROM ' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselName +
                  ' V.' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselVoyage;
              }
              if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentTo === true) {
                if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselId !== '') {
                  shortshipmet = '*SHORTSHIPPED FROM ' + this.model.blOceanVesselName + ' V.' + this.model.blOceanVesselVoyage;
                  shortshipmet += '\n*TO BE LOADED ON ' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselName +
                    ' V.' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselVoyage;

                } else {
                  shortshipmet = '*SHORTSHIPPED FROM ' + this.model.blOceanVesselName + ' V.' + this.model.blOceanVesselVoyage;
                }
              }

            }
            if (!shortshipmentList.includes(shortshipmet)) {
              shortshipmentList.push(shortshipmet);
            }
          }
        }
         if (shortshipmentList.length > 0) {
          for (const entry of shortshipmentList) {
            blDescEnding += '\n' + entry;
          }
        }


         // 07. Detection of Next / Connecting Vessel
         if (this.model.blNextVesselClauseIsPrinted) {
          if (this.model.blNextVesselId !== '' && this.model.blNextVesselVoyage !== '' && this.model.blNextVesselBound !== '') {
            blDescEnding += '\nCONNECTING ONTO ' + this.model.blNextVesselName + ' V.' + this.model.blNextVesselVoyage +
              ((this.model.blPortOfTransshipment1Name !== '') ? ' AT ' + this.model.blPortOfTransshipment1Name : '');
          }
        }
         **/

        /**
         // Detection of the Description Clause
         const descClauseList: Array<string> = [];
         if (this.gridBlDescClause.listStore.store.length > 0) {
          let descClause = '';
          for (let i = 0 ; i < this.gridBlDescClause.listStore.store.length; i++) {
            descClause = this.gridBlDescClause.listStore.store[i].blDescClauseName.trim();
            if (!descClauseList.includes(descClause)) {
              descClauseList.push(descClause);
            }
          }
        }
         if (descClauseList.length > 0) {
          for (const entry of descClauseList) {
            blDescEnding += '\n' + entry;
          }
        }
         */
      } else if (this.model.blContainerOwnership === "COCTS") {
        blContainerOwnershipText = "COC T/S";
        //GOS-499
        if (this.officeCode == 'SGSIN') {
          blDescEnding = "GENERAL CARGOES T/S EX " + this.model.blPreVesselName + " / " + this.model.blPreVesselVoyage;
        } else {
          blDescEnding = "";
        }
        //End of GOS-499
      } else if (this.model.blContainerOwnership === "SOC") {
        blContainerOwnershipText = "SOC LOCAL";
        blDescEnding = "GENERAL CARGOES";
      } else if (this.model.blContainerOwnership === "SOCTS") {
        blContainerOwnershipText = "SOC T/S";
        //GOS-499
        if (this.officeCode == 'SGSIN') {
          blDescEnding = "GENERAL CARGOES\n\nT/S EX " + this.model.blPreVesselName + " / " + this.model.blPreVesselVoyage;
        } else {
          blDescEnding = "GENERAL CARGOES\n\nT/S EX / ";
        }
        //End of GOS-499
      } else if (this.model.blContainerOwnership === "SLOT") {
        blContainerOwnershipText = "SLOT";
        blDescEnding = "GENERAL CARGOES\n\nS/E";
      }

      //*** Section to auto generate detail  **//
      //*** Logic changed on 10/10/2018 by bizapp, the detail must be generated for all container ownership ***//
      // 00. Detection of Detention and Demurrage
      if(this.model.blIsDetDemPrinted == 'Y' ) {
        if (
          this.model.blDetentionRequest > 0 ||
          this.model.blDemurrageRequest > 0
        ) {
          blDescEnding =
            "DETENTION IS " +
            this.model.blDetentionRequest +
            " DAYS AND DEMURRAGE IS " +
            this.model.blDemurrageRequest +
            " DAYS";
        }
      }
      // 01. Detection of OOG
      const oogList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let oogInfo = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          oogInfo =
            "OH=" + this.gridBlContainer.listStore.store[i].blContainerOogOH;
          oogInfo +=
            " " +
            "OWL=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOWL;
          oogInfo +=
            " " +
            "OWR=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOWR;
          oogInfo +=
            " " +
            "OLF=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOLF;
          oogInfo +=
            " " +
            "OLB=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOLB;
          oogInfo +=
            " " +
            "SCALE=" +
            this.gridBlContainer.listStore.store[i].blContainerOogScale;
          if (i === 0) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerOogOH +
                this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                this.gridBlContainer.listStore.store[i].blContainerOogOLB >
              0
            ) {
              oogList.push(oogInfo);
            }
          } else {
            if (!oogList.includes(oogInfo)) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerOogOH +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLB >
                0
              ) {
                oogList.push(oogInfo);
              }
            }
          }
        }
      }
      if (oogList.length > 0) {
        for (const entry of oogList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 02. Detection of DG Class
      /**
       if (this.model.blDgClass != null && this.model.blDgClass !== 'N' &&
       this.model.blDgClass !== '' && this.model.blDgClass !== undefined) {
          blDescEnding += '\n' + 'DG CLASS ' + this.model.blDgClass;
        }
       */
      const dgClassList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let dgClass = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          dgClass =
            "DG CLASS=" +
            this.gridBlContainer.listStore.store[i].blContainerDGClass;
          if (!dgClassList.includes(dgClass)) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerDGClass !==
              "N/A"
            ) {
              dgClassList.push(dgClass);
            }
          }
        }
      }
      if (dgClassList.length > 0) {
        for (const entry of dgClassList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 03. Detection of IMO & UNNO & F/P
      const imoUnnoList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let imoUnno = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          imoUnno =
            "IMO=" +
            this.gridBlContainer.listStore.store[i].blContainerIMO +
            " " +
            "UNNO=" +
            this.gridBlContainer.listStore.store[i].blContainerUNNO +
            " " +
            "F/P=" +
            (this.gridBlContainer.listStore.store[i]
              .blContainerFlashPointTemperature > 0
              ? "+" +
                this.gridBlContainer.listStore.store[i]
                  .blContainerFlashPointTemperature
              : this.gridBlContainer.listStore.store[i]
                  .blContainerFlashPointTemperature) +
            this.gridBlContainer.listStore.store[i].blContainerFlashPointScale;
          if (!imoUnnoList.includes(imoUnno)) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerIMO !== "" &&
              this.gridBlContainer.listStore.store[i].blContainerUNNO !== "" &&
              this.gridBlContainer.listStore.store[i]
                .blContainerFlashPointTemperature !== 0 &&
              this.gridBlContainer.listStore.store[i]
                .blContainerFlashPointScale !== ""
            ) {
              imoUnnoList.push(imoUnno);
            }
          }
        }
      }
      if (imoUnnoList.length > 0) {
        for (const entry of imoUnnoList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 04. Detection of the Reefer Temperature
      const reeferTempList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let reeferTemp = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          reeferTemp =
            "REEFER TEMP.=" +
            (this.gridBlContainer.listStore.store[i]
              .blContainerReeferTemperature > 0
              ? "+" +
                this.gridBlContainer.listStore.store[i]
                  .blContainerReeferTemperature
              : this.gridBlContainer.listStore.store[i]
                  .blContainerReeferTemperature) +
            this.gridBlContainer.listStore.store[i].blContainerReeferScale;
          if (!reeferTempList.includes(reeferTemp)) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerReeferTemperature !== 0 &&
              this.gridBlContainer.listStore.store[i].blContainerReeferScale !==
                ""
            ) {
              reeferTempList.push(reeferTemp);
            }
          }
        }
      }
      if (reeferTempList.length > 0) {
        for (const entry of reeferTempList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 05. Detection of the HS Code List
      const hsCodeList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (this.gridBlContainer.listStore.store[i].blContainerExtra && this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerAdPrint && this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerAdPrint === "Y"){
            for (
              let j = 0;
              j <
              this.gridBlContainer.listStore.store[i].blAdContainer.length;
              j++
            ) {
              if(this.gridBlContainer.listStore.store[i].blAdContainer.blAdContainerHsCode !== "" &&
              this.gridBlContainer.listStore.store[i].blAdContainer.blAdContainerHsName !== ""
              ){
                let hsCodes = "";
                let newLine: any = [];
                let hs = '';
                hs =
                this.gridBlContainer.listStore.store[i].blAdContainer[j]
                  .blAdContainerHsCode +
                " " +
                this.gridBlContainer.listStore.store[i].blAdContainer[j]
                  .blAdContainerHsName;
                newLine = hs.match(/\b[\w\s]{32,}?(?=\s)|.+$/g);
                if (newLine.length > 0) {
                  newLine.forEach(nt => {
                    hsCodes += nt.trim() + '↵';
                  });
                }else{
                  hsCodes += hs;
                }

                if (!hsCodeList.includes(hsCodes)) {
                  hsCodeList.push(hsCodes);
                }
              }
            }
          }

          // if (
          //   this.gridBlContainer.listStore.store[i].blContainerHSCode.length > 0
          // ) {
          //   let hsCodes = "";
          //   for (
          //     let j = 0;
          //     j <
          //     this.gridBlContainer.listStore.store[i].blContainerHSCode.length;
          //     j++
          //   ) {
          //     if (
          //       this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //         .blContainerHSPrint === true
          //     ) {
          //       hsCodes =
          //         this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //           .blContainerHSCode +
          //         " " +
          //         this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //           .blContainerHSDescription;
          //       if (!hsCodeList.includes(hsCodes)) {
          //         hsCodeList.push(hsCodes);
          //       }
          //     }
          //   }
          // }
        }
      }
      if (hsCodeList.length > 0) {
        for (const entry of hsCodeList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 06. Detection of the Shortshipment
      const shortshipmentList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let shortshipmet = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer === true &&
            this.gridBlContainer.listStore.store[i]
              .blContainerIsPrintShortShipmentInfoOnBL === true
          ) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentFrom === true
            ) {
              shortshipmet =
                "*SHORTSHIPPED FROM " +
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselName +
                " V." +
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselVoyage;
            }
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentTo === true
            ) {
              if (
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselId !== ""
              ) {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
                shortshipmet +=
                  "\n*TO BE LOADED ON " +
                  this.gridBlContainer.listStore.store[i]
                    .blContainerShortShipmentVesselName +
                  " V." +
                  this.gridBlContainer.listStore.store[i]
                    .blContainerShortShipmentVesselVoyage;
              } else {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
              }
            }
          }
          if (!shortshipmentList.includes(shortshipmet)) {
            shortshipmentList.push(shortshipmet);
          }
        }
      }
      if (shortshipmentList.length > 0) {
        for (const entry of shortshipmentList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 07. Detection of Next / Connecting Vessel
      if (this.model.blNextVesselClauseIsPrinted) {
        if (
          this.model.blNextVesselId !== "" &&
          this.model.blNextVesselVoyage !== "" &&
          this.model.blNextVesselBound !== ""
        ) {
          blDescEnding +=
            "\nCONNECTING ONTO " +
            this.model.blNextVesselName +
            " V." +
            this.model.blNextVesselVoyage +
            (this.model.blPortOfTransshipment1Name !== ""
              ? " AT " + this.model.blPortOfTransshipment1Name
              : "");
        }
      }

      // 08. Detection of the SOB
      if(this.model.blIsPrintShippedOnBoard == true){
        const monString = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
        let issueDateString = '';
        if(this.model.blExtraShippedOnBoard != '') {
          issueDateString = this.model.blExtraShippedOnBoard.split('-')[2] + ', ' + monString[Number(this.model.blExtraShippedOnBoard.split('-')[1])] + ' ' + this.model.blExtraShippedOnBoard.split('-')[0];
        }else{
          issueDateString = this.model.blDateOfIssue.split('-')[2] + ', ' + monString[Number(this.model.blDateOfIssue.split('-')[1])] + ' ' + this.model.blDateOfIssue.split('-')[0];
        }
        const sob = '\nSHIPPED ON BOARD ON ' + issueDateString;
        blDescEnding += sob;
      }

      // 09. Detection of the Tax Importer is true
      if(this.model.blExporterTaxNumberPrint == true){
        const expTax = '\nEXPORTER TAX REGISTRATION NUMBER : ' + this.model.blExporterTaxNumber;
        blDescEnding += expTax;
      }

      // 10. Detection if the Tax Importer is true
      if(this.model.blImporterTaxNumberPrint == true){
        const impTax = '\nIMPORTER TAX REGISTRATION NUMBER : ' + this.model.blImporterTaxNumber;
        blDescEnding += impTax;
      }

      //*** End generated segment ***//

      if (
        countStd20 +
          countStd40 +
          countStd45 +
          countFlr20 +
          countFlr40 +
          countFlr45 +
          countOpt20 +
          countOpt40 +
          countOpt45 +
          countPlt20 +
          countPlt40 +
          countPlt45 +
          countRef20 +
          countRef40 +
          countRef45 +
          countDry20 +
          countDry40 +
          countDry45 +
          countTnk20 +
          countTnk40 +
          countTnk45 +
          countUc20 +
          countUc40 +
          countUc45 +
          (countStd20hc +
            countStd40hc +
            countStd45hc +
            countFlr20hc +
            countFlr40hc +
            countFlr45hc +
            countOpt20hc +
            countOpt40hc +
            countOpt45hc +
            countPlt20hc +
            countPlt40hc +
            countPlt45hc +
            countRef20hc +
            countRef40hc +
            countRef45hc +
            countDry20hc +
            countDry40hc +
            countDry45hc +
            countTnk20hc +
            countTnk40hc +
            countTnk45hc +
            countUc20hc +
            countUc40hc +
            countUc45hc) +
          (countStd20E +
            countStd40E +
            countStd45E +
            countFlr20E +
            countFlr40E +
            countFlr45E +
            countOpt20E +
            countOpt40E +
            countOpt45E +
            countPlt20E +
            countPlt40E +
            countPlt45E +
            countRef20E +
            countRef40E +
            countRef45E +
            countDry20E +
            countDry40E +
            countDry45E +
            countTnk20E +
            countTnk40E +
            countTnk45E +
            countUc20E +
            countUc40E +
            countUc45E) +
          (countStd20Ehc +
            countStd40Ehc +
            countStd45Ehc +
            countFlr20Ehc +
            countFlr40Ehc +
            countFlr45Ehc +
            countOpt20Ehc +
            countOpt40Ehc +
            countOpt45Ehc +
            countPlt20Ehc +
            countPlt40Ehc +
            countPlt45Ehc +
            countRef20Ehc +
            countRef40Ehc +
            countRef45Ehc +
            countDry20Ehc +
            countDry40Ehc +
            countDry45Ehc +
            countTnk20Ehc +
            countTnk40Ehc +
            countTnk45Ehc +
            countUc20Ehc +
            countUc40Ehc +
            countUc45Ehc) >
        0
      ) {
        // Normal
        if (countStd20 > 0) {
          descText =
            descText +
            countStd20 +
            " X 20' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20hc > 0) {
          descText =
            descText +
            countStd20hc +
            " X 20' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20E > 0) {
          descText =
            descText +
            countStd20E +
            " X 20' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20Ehc > 0) {
          descText =
            descText +
            countStd20Ehc +
            " X 20' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40 > 0) {
          descText =
            descText +
            countStd40 +
            " X 40' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40hc > 0) {
          descText =
            descText +
            countStd40hc +
            " X 40' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40E > 0) {
          descText =
            descText +
            countStd40E +
            " X 40' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40Ehc > 0) {
          descText =
            descText +
            countStd40Ehc +
            " X 40' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45 > 0) {
          descText =
            descText +
            countStd45 +
            " X 45' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45hc > 0) {
          descText =
            descText +
            countStd45hc +
            " X 45' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45E > 0) {
          descText =
            descText +
            countStd45E +
            " X 45' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45Ehc > 0) {
          descText =
            descText +
            countStd45Ehc +
            " X 45' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Flat rack
        if (countFlr20 > 0) {
          descText =
            descText +
            countFlr20 +
            " X 20' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20hc > 0) {
          descText =
            descText +
            countFlr20hc +
            " X 20' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20E > 0) {
          descText =
            descText +
            countFlr20E +
            " X 20' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20Ehc > 0) {
          descText =
            descText +
            countFlr20Ehc +
            " X 20' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40 > 0) {
          descText =
            descText +
            countFlr40 +
            " X 40' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40hc > 0) {
          descText =
            descText +
            countFlr40hc +
            " X 40' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40E > 0) {
          descText =
            descText +
            countFlr40E +
            " X 40' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40Ehc > 0) {
          descText =
            descText +
            countFlr40Ehc +
            " X 40' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45 > 0) {
          descText =
            descText +
            countFlr45 +
            " X 45' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45hc > 0) {
          descText =
            descText +
            countFlr45hc +
            " X 45' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45E > 0) {
          descText =
            descText +
            countFlr45E +
            " X 45' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45Ehc > 0) {
          descText =
            descText +
            countFlr45Ehc +
            " X 45' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Open Top
        if (countOpt20 > 0) {
          descText =
            descText +
            countOpt20 +
            " X 20' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20hc > 0) {
          descText =
            descText +
            countOpt20hc +
            " X 20' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20E > 0) {
          descText =
            descText +
            countOpt20E +
            " X 20' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20Ehc > 0) {
          descText =
            descText +
            countOpt20Ehc +
            " X 20' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40 > 0) {
          descText =
            descText +
            countOpt40 +
            " X 40' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40hc > 0) {
          descText =
            descText +
            countOpt40hc +
            " X 40' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40E > 0) {
          descText =
            descText +
            countOpt40E +
            " X 40' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40Ehc > 0) {
          descText =
            descText +
            countOpt40Ehc +
            " X 40' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45 > 0) {
          descText =
            descText +
            countOpt45 +
            " X 45' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45hc > 0) {
          descText =
            descText +
            countOpt45hc +
            " X 45' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45E > 0) {
          descText =
            descText +
            countOpt45E +
            " X 45' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45Ehc > 0) {
          descText =
            descText +
            countOpt45Ehc +
            " X 45' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Platform
        if (countPlt20 > 0) {
          descText =
            descText +
            countPlt20 +
            " X 20' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20hc > 0) {
          descText =
            descText +
            countPlt20hc +
            " X 20' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20E > 0) {
          descText =
            descText +
            countPlt20E +
            " X 20' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20Ehc > 0) {
          descText =
            descText +
            countPlt20Ehc +
            " X 20' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40 > 0) {
          descText =
            descText +
            countPlt40 +
            " X 40' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40hc > 0) {
          descText =
            descText +
            countPlt40hc +
            " X 40' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40E > 0) {
          descText =
            descText +
            countPlt40E +
            " X 40' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40Ehc > 0) {
          descText =
            descText +
            countPlt40Ehc +
            " X 40' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45 > 0) {
          descText =
            descText +
            countPlt45 +
            " X 45' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45hc > 0) {
          descText =
            descText +
            countPlt45hc +
            " X 45' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45E > 0) {
          descText =
            descText +
            countPlt45E +
            " X 45' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45Ehc > 0) {
          descText =
            descText +
            countPlt45Ehc +
            " X 45' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Reefer
        if (countRef20 > 0) {
          descText =
            descText +
            countRef20 +
            " X 20' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20hc > 0) {
          descText =
            descText +
            countRef20hc +
            " X 20' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20E > 0) {
          descText =
            descText +
            countRef20E +
            " X 20' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20Ehc > 0) {
          descText =
            descText +
            countRef20Ehc +
            " X 20' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40 > 0) {
          descText =
            descText +
            countRef40 +
            " X 40' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40hc > 0) {
          descText =
            descText +
            countRef40hc +
            " X 40' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40E > 0) {
          descText =
            descText +
            countRef40E +
            " X 40' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40Ehc > 0) {
          descText =
            descText +
            countRef40Ehc +
            " X 40' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45 > 0) {
          descText =
            descText +
            countRef45 +
            " X 45' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45hc > 0) {
          descText =
            descText +
            countRef45hc +
            " X 45' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45E > 0) {
          descText =
            descText +
            countRef45E +
            " X 45' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45Ehc > 0) {
          descText =
            descText +
            countRef45Ehc +
            " X 45' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Reefer Dry
        if (countDry20 > 0) {
          descText =
            descText +
            countDry20 +
            " X 20' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20hc > 0) {
          descText =
            descText +
            countDry20hc +
            " X 20' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20E > 0) {
          descText =
            descText +
            countDry20E +
            " X 20' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20Ehc > 0) {
          descText =
            descText +
            countDry20Ehc +
            " X 20' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40 > 0) {
          descText =
            descText +
            countDry40 +
            " X 40' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40hc > 0) {
          descText =
            descText +
            countDry40hc +
            " X 40' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40E > 0) {
          descText =
            descText +
            countDry40E +
            " X 40' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40Ehc > 0) {
          descText =
            descText +
            countDry40Ehc +
            " X 40' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45 > 0) {
          descText =
            descText +
            countDry45 +
            " X 45' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45hc > 0) {
          descText =
            descText +
            countDry45hc +
            " X 45' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45E > 0) {
          descText =
            descText +
            countDry45E +
            " X 45' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45Ehc > 0) {
          descText =
            descText +
            countDry45Ehc +
            " X 45' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Tank
        if (countTnk20 > 0) {
          descText =
            descText +
            countTnk20 +
            " X 20' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20hc > 0) {
          descText =
            descText +
            countTnk20hc +
            " X 20' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20E > 0) {
          descText =
            descText +
            countTnk20E +
            " X 20' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20Ehc > 0) {
          descText =
            descText +
            countTnk20Ehc +
            " X 20' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40 > 0) {
          descText =
            descText +
            countTnk40 +
            " X 40' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40hc > 0) {
          descText =
            descText +
            countTnk40hc +
            " X 40' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40E > 0) {
          descText =
            descText +
            countTnk40E +
            " X 40' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40Ehc > 0) {
          descText =
            descText +
            countTnk40Ehc +
            " X 40' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45 > 0) {
          descText =
            descText +
            countTnk45 +
            " X 45' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45hc > 0) {
          descText =
            descText +
            countTnk45hc +
            " X 45' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45E > 0) {
          descText =
            descText +
            countTnk45E +
            " X 45' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45Ehc > 0) {
          descText =
            descText +
            countTnk45Ehc +
            " X 45' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // UC
        if (countUc20 > 0) {
          descText =
            descText +
            countUc20 +
            " X 20' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20hc > 0) {
          descText =
            descText +
            countUc20hc +
            " X 20' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20E > 0) {
          descText =
            descText +
            countUc20E +
            " X 20' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20Ehc > 0) {
          descText =
            descText +
            countUc20Ehc +
            " X 20' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20Ehc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40 > 0) {
          descText =
            descText +
            countUc40 +
            " X 40' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40hc > 0) {
          descText =
            descText +
            countUc40hc +
            " X 40' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40E > 0) {
          descText =
            descText +
            countUc40E +
            " X 40' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40Ehc > 0) {
          descText =
            descText +
            countUc40Ehc +
            " X 40' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40Ehc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45 > 0) {
          descText =
            descText +
            countUc45 +
            " X 45' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45hc > 0) {
          descText =
            descText +
            countUc45hc +
            " X 45' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45E > 0) {
          descText =
            descText +
            countUc45E +
            " X 45' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45Ehc > 0) {
          descText =
            descText +
            countUc45Ehc +
            " X 45' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45Ehc > 1 ? "ES" : "") +
            "\n";
        }

        descText = descText + "STC :\n\n" + blDescEnding;
        if (descText.split("\n").length > 1) {
          descTextClean =
            descText.split("\n")[0] + "<br />" + descText.split("\n")[1];
        } else {
          descTextClean = descText.split("\n")[0];
        }
      }

      this.paramDesc.blDescDescriptionText = descText;
      this.paramDesc.blDescDescriptionTextClean = descTextClean;

      this.calculateGrossWeightWhenUnLock();
      this.calculateGrossMeasWhenUnLock();
      this.calculateVgm();
      this.calculateNettMeasureWhenUnlock();
      this.calculateNettWeightWhenUnlock();

      this.paramDesc.blDescGrossWeight =  this.blContainerGrossWeightAdditionalString;
      this.paramDesc.blDescGrossWeightString = this.parseDecimal(this.totalblDescGrossWeightLock);
      this.paramDesc.blDescGrossMeasure = this.totalGrossMeasblDescAwkward;
      this.paramDesc.blDescGrossMeasureString = this.parseDecimal(this.totalGrossMeasblDescAwkward);
      this.paramDesc.blDescVgm = this.totalVGM;
      this.paramDesc.blDescNettWeightString = this.parseDecimal(this.totalblDescNettWeightLock);
      this.paramDesc.blDescNettMeasureString = this.parseDecimal(this.totalblDescNettMeasureLock);
      this.paramDesc.blDescNettMeasure = this.totalblDescNettMeasureLock;
      this.paramDesc.blDescDg2 = this.totalblDescGrossWeightLock;
      this.paramDesc.blDescDg2R = this.totalblDescNettWeightLock;
      this.paramDesc.blDescAwkward = this.totalGrossMeasblDescAwkward;

      // outer package type worker
      let outerPkgTypeCode = "";
      let outerPkgTypeName = "";
      let innerPkgTypeCode = "";
      let innerPkgTypeName = "";
      let commodityTypeId = "";
      let commodityTypeName = "";

      const innerPkgList: Array<string> = [];
      const outerPkgList: Array<string> = [];
      const commodityTypeList: Array<string> = [];
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        console.log("index : ", i, this.gridBlContainer.listStore.store[i]);
        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerPackageTypeCode.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerPackageTypeName.trim() !==
          ""
        ) {
          if (
            !outerPkgList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            )
          ) {
            console.log(
              "push outer test --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
            outerPkgList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
          }
        }

        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerExtra &&
          !commodityTypeList.includes(
            this.gridBlContainer.listStore.store[
              i
            ].blContainerExtra.blContainerCommodityTypeId.trim() +
              "~~~" +
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeName.trim()
          )
        ) {
          console.log(
            "push commodity test --> " +
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeId.trim() +
              "~~~" +
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeName.trim()
          );

          commodityTypeList.push(
            this.gridBlContainer.listStore.store[
              i
            ].blContainerExtra.blContainerCommodityTypeId.trim() +
              "~~~" +
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerCommodityTypeName.trim()
          );
        }

        /*if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerExtra.blContainerInnerPackageTypeName.trim() !==
          ""
        ) {
          if (
            !innerPkgList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            )
          ) {
            console.log(
              "push inner test --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            );
            innerPkgList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerExtra.blContainerInnerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerExtra.blContainerInnerPackageTypeName.trim()
            );
          }
        }*/
      }

      console.log(
        "cek container store : ",
        this.gridBlContainer.listStore.store
      );

      if (outerPkgList.length > 1) {
        outerPkgTypeCode = "PKGS";
        outerPkgTypeName = "PACKAGES";
      } else if (outerPkgList.length === 1) {
        outerPkgTypeCode = outerPkgList[0].split("~~~")[0];
        outerPkgTypeName = outerPkgList[0].split("~~~")[1];
      }

      if (innerPkgList.length > 1) {
        innerPkgTypeCode = "PKGS";
        innerPkgTypeName = "PACKAGES";
      } else if (innerPkgList.length === 1) {
        innerPkgTypeCode = innerPkgList[0].split("~~~")[0];
        innerPkgTypeName = innerPkgList[0].split("~~~")[1];
      }

      if (commodityTypeList.length > 0) {
        if(commodityTypeList.every((val, i, arr) => val == arr[0])){
          commodityTypeId = commodityTypeList[0].split("~~~")[0];
          commodityTypeName = commodityTypeList[0].split("~~~")[1];
        }else{
          commodityTypeId = "CD00174";
          commodityTypeName = "GENERAL";
        }
      }else{
        commodityTypeId = "";
        commodityTypeName = "";
      }

      console.log("outerPkgTypeCode --> " + outerPkgTypeCode);
      console.log("outerPkgTypeName --> " + outerPkgTypeName);
      console.log("innerPkgTypeCode --> " + innerPkgTypeCode);
      console.log("innerPkgTypeName --> " + innerPkgTypeName);
      /*
       for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
       if (i === 0) {
       outerPkgTypeCode = this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode;
       outerPkgTypeName = this.gridBlContainer.listStore.store[i].blContainerPackageTypeName;
       } else {
       if (outerPkgTypeCode !== this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode && this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode.trim() !== '') {
       outerPkgTypeCode = 'PKGS';
       outerPkgTypeName = 'PACKAGES';
       } else {
       outerPkgTypeCode = this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode;
       outerPkgTypeName = this.gridBlContainer.listStore.store[i].blContainerPackageTypeName;
       }
       }
       }
       */
      this.paramDesc.blDescOuterPackageTypeCode = outerPkgTypeCode;
      this.paramDesc.blDescOuterPackageTypeName = outerPkgTypeName;
      this.paramDesc.blDescInnerPackageTypeCode = innerPkgTypeCode;
      this.paramDesc.blDescInnerPackageTypeName = innerPkgTypeName;
      this.paramDesc.blDescCommodityTypeId = commodityTypeId;
      this.paramDesc.blDescCommodityTypeName = commodityTypeName;
      //this.paramDesc.blDescCommodityTypeId = this.model.blCommodityId;
      // this.paramDesc.blDescCommodityTypeId = "";

      console.log("cek cuy ? : ", this.gridBlContainer.listStore.store.length);

      // calculate container quantity vs desc outer quantity
      let outerQuantity = 0;
      let innerQuantity = 0;
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        console.log(
          `cek dulu ${i} : `,
          this.gridBlContainer.listStore.store[i].blContainerQuantity
        );
        /*console.log(
          `cek dulu inner ${i} : `,
          this.gridBlContainer.listStore.store[i].blContainerExtra
            .blContainerInnerQuantity
        );*/
        outerQuantity =
          outerQuantity +
          Number.parseFloat(
            this.gridBlContainer.listStore.store[i].blContainerQuantity
          );
        /*innerQuantity =
          innerQuantity +
          Number.parseFloat(
            this.gridBlContainer.listStore.store[i].blContainerExtra
              .blContainerInnerQuantity
          );*/
      }
      this.paramDesc.blDescOuterQuantity = outerQuantity;
      this.paramDesc.blDescInnerQuantity = innerQuantity;
    }

    //Add by glg to auto uncheck all the lock checkbox when site it is china site
    if(this.site.startsWith("CN")) {
      this.paramDesc.blDescOuterQuantityLock = false
      this.paramDesc.blDescOuterPkgTypeLock = true;
      this.paramDesc.blDescGrossWeightLock = false;
      this.paramDesc.blDescNettWeightLock = false;
      this.paramDesc.blDescGrossMeasLock = false;
      this.paramDesc.blDescVgmLock = 'N';
      this.blDescVgmLock = false;
      this.paramDesc.blDescNettMeasLock = false;

    }
  }

  changeEventChBlDescGrossWeightLock(event) {
    if (event.target.checked) {
      this.paramDesc.blDescGrossWeightLock = true;
    } else {
      if (this.gridBlContainer.listStore.store.length > 0) {
        this.calculateGrossWeightWhenUnLock();
        // this.blGrossWeightNum = totalGrossWt;
        this.paramDesc.blDescGrossWeight = this.totalGrossWt;
        this.blGrossWeightNum = this.totalGrossWt;
        this.paramDesc.blDescDg2 = this.totalblDescGrossWeightLock;
      }
      this.paramDesc.blDescGrossWeightLock = false;
    }
  }

  changeEventChBlDescNettWeightLock(event) {
    if (event.target.checked) {
      this.paramDesc.blDescNettWeightLock = true;
    } else {
      if (this.gridBlContainer.listStore.store.length > 0) {
        this.calculateNettWeightWhenUnlock();
        this.paramDesc.blDescDg2R = this.totalblDescNettWeightLock;
      }
      this.paramDesc.blDescNettWeightLock = false;
    }
  }

  changeEventChBlDescNettMeasurementLock(event){
    if (event.target.checked) {
      this.paramDesc.blDescNettMeasLock = true;
    } else {
      if (this.gridBlContainer.listStore.store.length > 0) {
        this.calculateNettMeasureWhenUnlock();
        this.paramDesc.blDescNettMeasure = this.totalblDescNettMeasureLock;
      }
      this.paramDesc.blDescNettMeasLock = false;
    }
  }

  changeEventChBlDescblDescVgm(event){
    if(event){
      this.paramDesc.blDescVgmLock = "Y";
      this.paramDesc.blDescVgm  = this.paramDesc.blDescVgm;
    }else{
      this.calculateVgm();
      this.paramDesc.blDescVgmLock = "N";
      this.paramDesc.blDescVgm = this.totalVGM;
    }

  }

  changeEventChBlDescGrossMeasLock(event) {
    if (event.target.checked) {
      this.paramDesc.blDescGrossMeasLock = true;
    } else {
      if (this.gridBlContainer.listStore.store.length > 0) {
        this.calculateGrossMeasWhenUnLock();
        this.paramDesc.blDescGrossMeasure = this.totalGrossMeas;
        this.paramDesc.blDescGrossMeasureString = this.parseDecimal(this.totalGrossMeas);
        this.blGrossMeasureNum = this.totalGrossMeas;
        // this.paramDesc.blDescAwkward = this.totalGrossMeas.toString();
        this.paramDesc.blDescAwkward = this.totalGrossMeasblDescAwkward;
      }
      this.paramDesc.blDescGrossMeasLock = false;
    }
  }

  changeEventChBlDescOuterQuantityLock(event) {
    if (event.target.checked) {
      this.paramDesc.blDescOuterQuantityLock = true;
    } else {
      if (this.gridBlContainer.listStore.store.length > 0) {
        let totalOuterPkgQuantity = 0;
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          totalOuterPkgQuantity =
            totalOuterPkgQuantity +
            Number.parseFloat(
              this.gridBlContainer.listStore.store[i].blContainerQuantity
            );
        }
        this.paramDesc.blDescOuterQuantity = totalOuterPkgQuantity;
      }
      this.paramDesc.blDescOuterQuantityLock = false;
    }
  }

  changeEventChBlDescOuterPkgType(event) {
    if (event.target.checked) {
      this.paramDesc.blDescOuterPkgTypeLock = true;
    } else {
      this.paramDesc.blDescOuterPkgTypeLock = false;
      let outerPkgTypeCode = "";
      let outerPkgTypeName = "";
      const pkgList: Array<string> = [];
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        if (
          this.gridBlContainer.listStore.store[
            i
          ].blContainerPackageTypeCode.trim() +
            this.gridBlContainer.listStore.store[
              i
            ].blContainerPackageTypeName.trim() !==
          ""
        ) {
          if (
            !pkgList.includes(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            )
          ) {
            console.log(
              "push test --> " +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
            pkgList.push(
              this.gridBlContainer.listStore.store[
                i
              ].blContainerPackageTypeCode.trim() +
                "~~~" +
                this.gridBlContainer.listStore.store[
                  i
                ].blContainerPackageTypeName.trim()
            );
          }
        }
      }

      if (pkgList.length > 1) {
        outerPkgTypeCode = "PKGS";
        outerPkgTypeName = "PACKAGES";
      } else if (pkgList.length === 1) {
        outerPkgTypeCode = pkgList[0].split("~~~")[0];
        outerPkgTypeName = pkgList[0].split("~~~")[1];
      }
      console.log("outerPkgTypeCode --> " + outerPkgTypeCode);
      console.log("outerPkgTypeName --> " + outerPkgTypeName);
      /*
       for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
       if (i === 0) {
       outerPkgTypeCode = this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode;
       outerPkgTypeName = this.gridBlContainer.listStore.store[i].blContainerPackageTypeName;
       } else {
       if (outerPkgTypeCode !== this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode && this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode.trim() !== '') {
       outerPkgTypeCode = 'PKGS';
       outerPkgTypeName = 'PACKAGES';
       } else {
       outerPkgTypeCode = this.gridBlContainer.listStore.store[i].blContainerPackageTypeCode;
       outerPkgTypeName = this.gridBlContainer.listStore.store[i].blContainerPackageTypeName;
       }
       }
       }
       */
      this.cbBlDescOuterPkgType.setForceValue(outerPkgTypeName);
      this.paramDesc.blDescOuterPackageTypeCode = outerPkgTypeCode;
      this.paramDesc.blDescOuterPackageTypeName = outerPkgTypeName;
    }
  }

  changeEventChBlIsIrePermit(event) {
    if (event.target.checked) {
      this.model.blIsIrePermit = true;
    } else {
      this.model.blIsIrePermit = false;
    }
  }

  changeEventChBlIsSingleConsigneePermit(event) {
    if (event.target.checked) {
      this.model.blIsSingleConsigneePermit = true;
    } else {
      this.model.blIsSingleConsigneePermit = false;
    }
  }

  changeEventChBlIsPrintAgentInfoOnBl(event) {
    if (event.target.checked) {
      if (
        this.model.blDischargePortCode != null &&
        this.model.blDischargePortCode !== "" &&
        this.model.blDischargePortCode !== undefined
      ) {
        this.model.blIsPrintAgentInfoOnBl = true;
        this.cbBlDescAgentId.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findAgencyByLocationCode/" +
            this.model.blDischargePortCode.trim()
        );
        const self = this;
        this.cbBlDescAgentId.loadSelect(function () {
          self.isBlDescAgentIdPrinted = true;
        });
      }
    } else {
      this.cbBlDescAgentId.setUrl("");
      this.cbBlDescAgentId.loadSelect();
      this.txtBlDescAgentNameAddress.setValue("");
      this.model.blIsPrintAgentInfoOnBl = false;
      this.isBlDescAgentIdPrinted = false;
    }
  }


  changeEventChBlDescAgentAddress(event) {
    // console.log('** agent change **');
    // console.log(event);
    if (
      event.agencyId != null &&
      event.agencyId !== "" &&
      event.agencyId !== undefined
    ) {
      let theName = event.agencyCustomerDisplayName + "\n\n";
      theName = theName + event.agencyFullAddress;
      if (
        event.agencyTelNo != null &&
        event.agencyTelNo !== "" &&
        event.agencyTelNo !== undefined
      ) {
        theName = theName + "\nTELP: " + event.agencyTelNo;
      }
      if (
        event.agencyFax1 != null &&
        event.agencyFax1 !== "" &&
        event.agencyFax1 !== undefined
      ) {
        theName = theName + "\nFAX1: " + event.agencyFax1;
      }
      /* Commented out due to GBC-470
       if (event.agencyFax2 != null && event.agencyFax2 !== '' && event.agencyFax2 !== undefined) {
       theName = theName + '\nFAX2: ' + event.agencyFax2;
       }
       if (event.agencyFax3 != null && event.agencyFax3 !== '' && event.agencyFax3 !== undefined) {
       theName = theName + '\nFAX3: ' + event.agencyFax3;
       }
       End of Commented out due to GBC-470 */
      if (
        event.agencyEmail != null &&
        event.agencyEmail !== "" &&
        event.agencyEmail !== undefined
      ) {
        theName = theName + "\nEMAIL: " + event.agencyEmail;
      }

      this.model.blAgentAddressId = event.agencySeqNo;

      this.txtBlDescAgentNameAddress.setValue(theName);
      this.model.blDescAgentRemark = theName;
    }
  }

  changeEventChBlDescriptionLock(event) {
    if (event.target.checked) {
      this.paramDesc.blDescDescriptionLock = true;
    } else {
      this.paramDesc.blDescDescriptionLock = false;
    }
  }

  /********  05.01 TAB DESC CLAUSE ********/
  infoGridBlClause(event) {}

  gridEventBlDescClause(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              if (this.btnBlDescClauseCancel !== true) {
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
                if (this.blLock == "N") {
                  this.btnBlDescClauseNew = false;
                  this.btnBlDescClauseSave = true;
                  this.btnBlDescClauseDelete = true;
                  this.btnBlDescClauseCancel = true;
                  this.frmDescClauseLock = false;
                  this.blDescClauseUpdateMode = "update";
                } else {
                  this.btnBlDescClauseNew = false;
                  this.btnBlDescClauseSave = false;
                  this.btnBlDescClauseDelete = false;
                  this.btnBlDescClauseCancel = true;
                  this.frmDescClauseLock = false;
                  this.lockTab5 = false;
                  this.blDescClauseUpdateMode = "update";
                }

                this.paramDescClause = new BLDescClause();
                this.paramDescClauseTemp = new BLDescClause();
                const dt = this.gridBlDescClause.getSelectedValues()[0];

                this.paramDescClause.blDescClauseOfficeCode =
                  dt.blDescClauseOfficeCode;
                this.paramDescClause.blDescClauseBlNo = dt.blDescClauseBlNo;
                this.paramDescClause.blDescClauseSeq = dt.blDescClauseSeq;
                this.paramDescClause.blDescClauseCode = dt.blDescClauseCode;
                this.paramDescClause.blDescClauseName = dt.blDescClauseName;

                this.paramDescClauseTemp.blDescClauseOfficeCode =
                  dt.blDescClauseOfficeCode;
                this.paramDescClauseTemp.blDescClauseBlNo = dt.blDescClauseBlNo;
                this.paramDescClauseTemp.blDescClauseSeq = dt.blDescClauseSeq;
                this.paramDescClauseTemp.blDescClauseCode = dt.blDescClauseCode;
                this.paramDescClauseTemp.blDescClauseName = dt.blDescClauseName;

                if (this.paramDescClause.blDescClauseCode !== "") {
                  //this.cbBlDescClauses.setUrl(this.configService.config.BASE_API.toString() +
                  //  '/MasterClauses/findByComboBoxControl/clauseCode={query}');
                  this.cbBlDescClauses.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterClauses/findByComboBoxControl2/" +
                      this.officeCode +
                      "/clauseCodeOrName={query}"
                  );
                  this.cbBlDescClauses.setValue(
                    this.paramDescClause.blDescClauseCode
                  );
                }
              }
              // lock other tab
              this.lockParty = true;
              this.lockPlace = true;
              this.lockContainer = true;
              this.lockMarkNo = true;
              this.lockDesc = false;
              this.lockNettCharge = true;
              this.lockGrossCharge = true;
              this.lockNettChargeConsole = true;
              this.lockGrossChargeConsole = true;

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  onBlDescClauseNew() {
    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
    this.btnBlDescClauseNew = false;
    this.btnBlDescClauseSave = true;
    this.btnBlDescClauseDelete = false;
    this.btnBlDescClauseCancel = true;
    this.frmDescClauseLock = false;
    this.paramDescClause = new BLDescClause();
    this.paramDescClause.blDescClauseOfficeCode = this.model.blOfficeCode;
    this.paramDescClause.blDescClauseBlNo = this.model.blNo;
    this.paramDescClause.blDescClauseSeq =
      this.gridBlDescClause.listStore.store.length + 1;

    // lock other tab
    this.lockParty = true;
    this.lockPlace = true;
    this.lockContainer = true;
    this.lockMarkNo = true;
    this.lockDesc = false;
    this.lockNettCharge = true;
    this.lockGrossCharge = true;
    this.lockNettChargeConsole = true;
    this.lockGrossChargeConsole = true;
  }

  checkIfDuplicate(param){
    var dblFlag = false;
    var soso = [];
    this.gridBlDescClause.listStore.store.forEach((yr) => {
      //console.log("SOSO: "+yr['blDescClauseCode']);
      soso.push(yr);
    });

    dblFlag = this.checkDuplicateInObject("blDescClauseCode", soso);
    console.log("INSERT DBFLAG : "+ dblFlag)
    if (dblFlag == true) {
      this.message(
        "information",
        "Information",
        "This code has been added please use another code.",
        "okonly",
        { ok: "this.loading=false;" }
      );
      //console.log("PARAMNYA : "+param);
      if(param == "INSERT"){
        //console.log("INSERT KESINI : "+this.paramDescClause.blDescClauseSeq+" HAHAHAH")
        this.gridBlDescClause.listStore.store.splice(
          this.paramDescClause.blDescClauseSeq - 1,
          1
        );
      }else{
        this.gridBlDescClause.listStore.updateData(
          this.paramDescClause.blDescClauseSeq - 1,
          this.paramDescClauseTemp
        );
      }
      this.gridBlDescClause.loadData();

    }
  }


  onBlDescClauseUpdate() {
    this.isErrorDescClause = false;
    this.paramDescClause["error-blDescClause"] = "";

    if (this.paramDescClause.blDescClauseCode === "") {
      this.isErrorDescClause = true;
      this.paramDescClause["error-blDescClause"] =
        "Please input Clause Description.";
    }

    if (!this.isErrorDescClause) {
      if (this.blDescClauseUpdateMode === "insert") {
        // do insert
        this.gridBlDescClause.url = "";
        this.gridBlDescClause.listStore.store.push(this.paramDescClause);
        this.gridBlDescClause.loadData();
        this.checkIfDuplicate("INSERT");

      } else {
        // do update
        this.gridBlDescClause.url = "";
        //this.gridBlDescClause.listStore.updateData((this.gridBlDescClause.getSelectedValues()[0].blDescClauseSeq - 1), this.paramDescClause);
        this.gridBlDescClause.listStore.updateData(
          this.paramDescClause.blDescClauseSeq - 1,
          this.paramDescClause
        );

        this.gridBlDescClause.clearSelectedValues();
        this.gridBlDescClause.loadData();
        this.checkIfDuplicate("UPDATE");
      }
      this.onBlDescClauseCancel();
    }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};
    console.log("1: " + propertyName);

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName].trim().toUpperCase();
      console.log("2: " + itemPropertyName);

      if (item[propertyName] != "") {
        if (itemPropertyName in testObject) {
          testObject[itemPropertyName].duplicate = true;
          item.duplicate = true;
          seenDuplicate = true;
        } else {
          testObject[itemPropertyName] = item;
          delete item.duplicate;
        }
      }
    });

    return seenDuplicate;
  }



  onBlDescClauseDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteDescClause",
      "Confirmation",
      350,
      150
    );
  }

  onBlDescClauseCancel() {
    this.btnBlDescClauseNew = true;
    this.btnBlDescClauseSave = false;
    this.btnBlDescClauseDelete = false;
    this.btnBlDescClauseCancel = false;
    this.frmDescClauseLock = true;
    this.blDescClauseUpdateMode = "insert";

    this.isErrorDescClause = false;
    this.paramDescClause = new BLDescClause();
    this.cbBlDescClauses.setValue("");

    // release the lock
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }
  }

  changeEventCbBlDescClauses(event) {
    if (
      event.clauseId != null &&
      event.clauseId !== "" &&
      event.clauseId !== undefined
    ) {
      this.paramDescClause.blDescClauseId = event.clauseId;
      this.paramDescClause.blDescClauseCode = event.clauseCode;
      this.paramDescClause.blDescClauseName = event.clauseName;
    } else {
      this.paramDescClause.blDescClauseId = "";
      this.paramDescClause.blDescClauseCode = "";
      this.paramDescClause.blDescClauseName = "";
    }
    //this.cbBlDescClauses.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterClauses/findByComboBoxControl/clauseCodeOrName={query}');
    this.cbBlDescClauses.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterClauses/findByComboBoxControl2/" +
        this.officeCode +
        "/clauseCodeOrName={query}"
    );
  }

  changeEventBlDescOuterQtyPrint(val){
    if(val){
      this.chkBlDescOuterQuantityPrint = true;
      this.paramDesc.blDescOuterQuantityPrint = 'Y';
    }else{
      this.chkBlDescOuterQuantityPrint = false;
      this.paramDesc.blDescOuterQuantityPrint = 'N';
    }
  }

  changeEventBlDescOuterPkgPrint(val){
    if(val){
      this.chkBlDescOuterPkgTypePrint = true;
      this.paramDesc.blDescOuterPkgTypePrint = 'Y';
    }else{
      this.chkBlDescOuterPkgTypePrint = false;
      this.paramDesc.blDescOuterPkgTypePrint = 'N';
    }
  }

  /********  05 TAB NETT CHARGE ********/
  gridEventBlNettCharge(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.isRefreightButtonClicked = false; // stupid again
              if (this.modeFormBlNettCharge !== "edit") {
                this.isBLFNewEntry = false; // to avoid blf auto populate
                this.nettChargeDisableDelete = false;
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
                this.txtBlContainerInsertUpdate = "Update";
                this.blNettChargeUpdateMode = "update";

                this.paramFreightCharge = new BLFreightCharge();
                const dt = this.gridBlNettCharge.getSelectedValues()[0];
                console.log(dt);
                this.paramFreightCharge.blFCOfficeCode = dt.blFCOfficeCode;
                this.paramFreightCharge.blFCBlNo = dt.blFCBlNo;
                this.paramFreightCharge.blFCSeq = dt.blFCSeq;
                this.paramFreightCharge.blFCChargeCode = dt.blFCChargeCode;
                this.paramFreightCharge.blFCRevenue = dt.blFCRevenue;
                this.paramFreightCharge.blFCRate = dt.blFCRate;
                this.paramFreightCharge.blFCPerCode = dt.blFCPerCode;
                this.paramFreightCharge.blFCCurrencyCode = dt.blFCCurrencyCode;
                this.paramFreightCharge.blFCAmount = dt.blFCAmount;
                this.paramFreightCharge.blFCPc = dt.blFCPc;
                this.paramFreightCharge.blFCPayer = dt.blFCPayer;
                this.paramFreightCharge.blFCPlaceOfPaymentCode =
                  dt.blFCPlaceOfPaymentCode;
                this.paramFreightCharge.blFCPlaceOfPaymentName =
                  dt.blFCPlaceOfPaymentName;
                this.paramFreightCharge.blFCContainerType =
                  dt.blFCContainerType;
                this.paramFreightCharge.blFCIsEntered = dt.blFCIsEntered;
                this.paramFreightCharge.blFCIsEnteredString =
                  dt.blFCIsEnteredString;
                this.paramFreightCharge.blFCAmount = dt.blFCAmount;
                this.paramFreightCharge.blFCIsManifestedFreight =
                  dt.blFCIsManifestedFreight;
                this.paramFreightCharge.blFCIsManifestedFreightString =
                  dt.blFCIsManifestedFreightString;
                this.paramFreightCharge.blFCIsChargePrinted =
                  dt.blFCIsChargePrinted;
                this.paramFreightCharge.blFCIsChargePrintedString =
                  dt.blFCIsChargePrintedString;

                console.log("-- debug after double click --");
                console.log(
                  "blManifestedFreight vs blManifestedFreightString => " +
                    dt.blFCIsManifestedFreight +
                    " vs " +
                    dt.blFCIsManifestedFreightString
                );
                console.log(
                  "blChargePrinted vs chargePrintedString => " +
                    +dt.blFCIsChargePrinted +
                    " vs " +
                    dt.blFCIsChargePrintedString
                );

                this.paramFreightCharge.blFCFaiGroup = dt.blFCFaiGroup;
                this.paramFreightCharge.blFCFaiGroupString =
                  dt.blFCFaiGroupString;
                this.paramFreightCharge.blFCFreightReference =
                  dt.blFCFreightReference;

                if (this.paramFreightCharge.blFCChargeCode !== "") {
                  this.cbBlNettChargeInputCharge.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCharges/findByComboBoxControlAll/chargeCode={query}"
                  );
                  this.cbBlNettChargeInputCharge.setValue(
                    this.paramFreightCharge.blFCChargeCode
                  );
                }

                if (this.paramFreightCharge.blFCPerCode !== "") {
                  this.cbBlNettChargeInputPerCode.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPerCodes/findByComboBoxControl/perCode={query}"
                  );
                  this.cbBlNettChargeInputPerCode.setValue(
                    this.paramFreightCharge.blFCPerCode
                  );
                }

                if (this.paramFreightCharge.blFCCurrencyCode !== "") {
                  this.cbBlNettChargeInputCurrency.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCurrencies/findByComboBoxControl/currencyCode={query}"
                  );
                  this.cbBlNettChargeInputCurrency.setValue(
                    this.paramFreightCharge.blFCCurrencyCode
                  );
                }

                // populate PC
                //if (this.paramFreightCharge.blFCPc !== '') {
                this.cbBlNettChargeInputPC.listStore.store = [];

                if (
                  this.model.blPrepaidAt1Code != null &&
                  this.model.blPrepaidAt1Code !== "" &&
                  this.model.blPrepaidAt1Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "P1",
                    blPCName: "Prepaid 1",
                  });
                }
                if (
                  this.model.blPrepaidAt2Code != null &&
                  this.model.blPrepaidAt2Code !== "" &&
                  this.model.blPrepaidAt2Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "P2",
                    blPCName: "Prepaid 2",
                  });
                }
                if (
                  this.model.blPrepaidAt3Code != null &&
                  this.model.blPrepaidAt3Code !== "" &&
                  this.model.blPrepaidAt3Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "P3",
                    blPCName: "Prepaid 3",
                  });
                }
                if (
                  this.model.blPayableAt1Code != null &&
                  this.model.blPayableAt1Code !== "" &&
                  this.model.blPayableAt1Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "C1",
                    blPCName: "Collect 1",
                  });
                }
                if (
                  this.model.blPayableAt2Code != null &&
                  this.model.blPayableAt2Code !== "" &&
                  this.model.blPayableAt2Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "C2",
                    blPCName: "Collect 2",
                  });
                }
                if (
                  this.model.blPayableAt3Code != null &&
                  this.model.blPayableAt3Code !== "" &&
                  this.model.blPayableAt3Code !== undefined
                ) {
                  this.cbBlNettChargeInputPC.listStore.store.push({
                    blPCCode: "C3",
                    blPCName: "Collect 3",
                  });
                }

                this.cbBlNettChargeInputPC.loadSelect();

                this.cbBlNettChargeInputPC.setValue(
                  this.paramFreightCharge.blFCPc
                );
                //}

                // populate payer
                //if (this.paramFreightCharge.blFCPayer !== '') {
                this.cbBlNettChargeInputPayer.listStore.store = [];

                if (
                  this.model.blPayer1Code != null &&
                  this.model.blPayer1Code !== "" &&
                  this.model.blPayer1Code !== undefined
                ) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    blPCPayerCode: "1",
                    blPCPayerName: "Payer 1",
                  });
                }
                if (
                  this.model.blPayer2Code != null &&
                  this.model.blPayer2Code !== "" &&
                  this.model.blPayer2Code !== undefined
                ) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    blPCPayerCode: "2",
                    blPCPayerName: "Payer 2",
                  });
                }
                if (
                  this.model.blPayer3Code != null &&
                  this.model.blPayer3Code !== "" &&
                  this.model.blPayer3Code !== undefined
                ) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    blPCPayerCode: "3",
                    blPCPayerName: "Payer 3",
                  });
                }
                this.cbBlNettChargeInputPayer.listStore.store.push({
                  blPCPayerCode: "",
                  blPCPayerName: "",
                });

                this.cbBlNettChargeInputPayer.loadSelect();

                this.cbBlNettChargeInputPayer.setValue(
                  this.paramFreightCharge.blFCPayer
                );
                //}

                this.modeFormBlNettCharge = "edit";

                if (
                  this.blLock == "Y" ||
                  this.blLock == "T1" ||
                  this.blLock == "T2" ||
                  this.blLock == "T3" ||
                  this.blLock == "T4" ||
                  this.blLock == "T5" ||
                  this.blLock == "T8"
                ) {
                  this.disableNewUpdateChargeButton = false;
                  this.nettChargeDisableDelete = true;
                  this.lockParty = true;
                  this.lockPlace = true;
                  this.lockContainer = true;
                  this.lockMarkNo = true;
                  this.lockDesc = true;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = true;
                  this.lockNettChargeConsole = true;
                  this.lockGrossChargeConsole = true;
                  this.lockTab6 = false;
                  this.netChargeLock = true;
                } else {
                  this.lockParty = true;
                  this.lockPlace = true;
                  this.lockContainer = true;
                  this.lockMarkNo = true;
                  this.lockDesc = true;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = true;
                  this.lockNettChargeConsole = false;
                  this.lockGrossChargeConsole = true;
                  this.disableNewUpdateChargeButton = true;
                  this.netChargeLock = false;
                }

                /*if (this.blLock == "N") {
                  // lock other tab
                  this.lockParty = true;
                  this.lockPlace = true;
                  this.lockContainer = true;
                  this.lockMarkNo = true;
                  this.lockDesc = true;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = true;
                  this.lockNettChargeConsole = false;
                  this.lockGrossChargeConsole = true;
                  this.disableNewUpdateChargeButton = true;
                } else {
                  this.disableNewUpdateChargeButton = false;
                  this.lockParty = true;
                  this.lockPlace = true;
                  this.lockContainer = true;
                  this.lockMarkNo = true;
                  this.lockDesc = true;
                  this.lockNettCharge = false;
                  this.lockGrossCharge = true;
                  this.lockNettChargeConsole = true;
                  this.lockGrossChargeConsole = true;
                  this.lockTab6 = false;
                }*/
              }

              break;

            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  gridEventBlNettChargeConsole(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              this.isRefreightButtonClickedConsole = false; // stupid again
              if (this.modeFormBlNettChargeConsole !== "edit") {
                this.isBLFNewEntryConsole = false; // to avoid blf auto populate
                this.nettChargeDisableDeleteConsole = false;
                this.disableToolbarButtons =
                  "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
                this.txtBlContainerInsertUpdateConsole = "Update";
                this.blNettChargeUpdateModeConsole = "update";

                this.paramFreightChargeConsole = new BLFreightCharge();
                const dt = this.gridBlNettChargeConsole.getSelectedValues()[0];
                console.log(dt);

                this.paramFreightChargeConsole.blFCOfficeCode =
                  dt.blFCOfficeCode;
                this.paramFreightChargeConsole.blFCBlNo = dt.blFCBlNo;
                this.paramFreightChargeConsole.blFCSeq = dt.blFCSeq;
                this.paramFreightChargeConsole.blFCChargeCode =
                  dt.blFCChargeCode;
                this.paramFreightChargeConsole.blFCRevenue = dt.blFCRevenue;
                this.paramFreightChargeConsole.blFCRate = dt.blFCRate;
                this.paramFreightChargeConsole.blFCPerCode = dt.blFCPerCode;
                this.paramFreightChargeConsole.blFCCurrencyCode =
                  dt.blFCCurrencyCode;
                this.paramFreightChargeConsole.blFCAmount = dt.blFCAmount;
                this.paramFreightChargeConsole.blFCPc = dt.blFCPc;
                if (dt.blFCPayer == undefined) {
                  if (dt.blFCPayerCode == undefined) {
                    this.paramFreightChargeConsole.blFCPayer = "";
                  } else {
                    this.paramFreightChargeConsole.blFCPayer = dt.blFCPayerCode;
                  }
                } else {
                  this.paramFreightChargeConsole.blFCPayer = dt.blFCPayer;
                }
                this.paramFreightChargeConsole.blFCPayerCode = dt.blFCPayerCode;
                this.paramFreightChargeConsole.blFCPayerName = dt.blFCPayerName;
                this.paramFreightChargeConsole.blFCPlaceOfPaymentCode =
                  dt.blFCPlaceOfPaymentCode;
                this.paramFreightChargeConsole.blFCPlaceOfPaymentName =
                  dt.blFCPlaceOfPaymentName;
                this.paramFreightChargeConsole.blFCContainerType =
                  dt.blFCContainerType;
                this.paramFreightChargeConsole.blFCIsEntered = dt.blFCIsEntered;
                this.paramFreightChargeConsole.blFCIsEnteredString =
                  dt.blFCIsEnteredString;
                this.paramFreightChargeConsole.blFCAmount = dt.blFCAmount;
                this.paramFreightChargeConsole.blFCIsManifestedFreight =
                  dt.blFCIsManifestedFreight;
                this.paramFreightChargeConsole.blFCIsManifestedFreightString =
                  dt.blFCIsManifestedFreightString;
                this.paramFreightChargeConsole.blFCIsChargePrinted =
                  dt.blFCIsChargePrinted;
                this.paramFreightChargeConsole.blFCIsChargePrintedString =
                  dt.blFCIsChargePrintedString;

                console.log("-- debug after double click --");
                console.log(
                  "blManifestedFreight vs blManifestedFreightString => " +
                    dt.blFCIsManifestedFreight +
                    " vs " +
                    dt.blFCIsManifestedFreightString
                );
                console.log(
                  "blChargePrinted vs chargePrintedString => " +
                    +dt.blFCIsChargePrinted +
                    " vs " +
                    dt.blFCIsChargePrintedString
                );

                this.paramFreightChargeConsole.blFCFaiGroup = dt.blFCFaiGroup;
                this.paramFreightChargeConsole.blFCFaiGroupString =
                  dt.blFCFaiGroupString;
                this.paramFreightChargeConsole.blFCFreightReference =
                  dt.blFCFreightReference;

                if (this.paramFreightChargeConsole.blFCChargeCode !== "") {
                  this.cbBlNettChargeInputChargeConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCharges/findByComboBoxControlAll/chargeCode={query}"
                  );
                  this.cbBlNettChargeInputChargeConsole.setValue(
                    this.paramFreightChargeConsole.blFCChargeCode
                  );
                }

                if (this.paramFreightChargeConsole.blFCPerCode !== "") {
                  this.cbBlNettChargeInputPerCodeConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterPerCodes/findByComboBoxControl/perCode={query}"
                  );
                  this.cbBlNettChargeInputPerCodeConsole.setValue(
                    this.paramFreightChargeConsole.blFCPerCode
                  );
                }

                if (this.paramFreightChargeConsole.blFCCurrencyCode !== "") {
                  this.cbBlNettChargeInputCurrencyConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCurrencies/findByComboBoxControl/currencyCode={query}"
                  );
                  this.cbBlNettChargeInputCurrencyConsole.setValue(
                    this.paramFreightChargeConsole.blFCCurrencyCode
                  );
                }

                if (this.paramFreightChargeConsole.blFCPc !== "") {
                  this.cbBlNettChargeInputPCConsole.listStore.store = [];
                  this.cbBlNettChargeInputPCConsole.loadSelect();
                  this.cbBlNettChargeInputPCConsole.setValue(
                    this.paramFreightChargeConsole.blFCPc
                  );
                }

                if (
                  this.paramFreightChargeConsole.blFCPlaceOfPaymentCode !== ""
                ) {
                  this.cbBlNettChargeInputPlaceOfPaymentConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                  );
                  this.cbBlNettChargeInputPlaceOfPaymentConsole.setValue(
                    this.paramFreightChargeConsole.blFCPlaceOfPaymentCode
                  );
                  this.cbBlNettChargeInputPlaceOfPaymentConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
                  );
                }

                if (this.paramFreightChargeConsole.blFCPayer !== "") {
                  this.cbBlNettChargeInputPayerConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  );
                  this.cbBlNettChargeInputPayerConsole.setValue(
                    this.paramFreightChargeConsole.blFCPayer
                  );
                  this.cbBlNettChargeInputPayerConsole.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
                  );
                }

                // populate PC
                //if (this.paramFreightCharge.blFCPc !== '') {
                /*
                this.cbBlNettChargeInputPCConsole.listStore.store = [];

                if (this.model.blPrepaidAt1Code != null &&
                  this.model.blPrepaidAt1Code !== '' &&
                  this.model.blPrepaidAt1Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P1', 'blPCName': 'Prepaid 1'});
                }
                if (this.model.blPrepaidAt2Code != null &&
                  this.model.blPrepaidAt2Code !== '' &&
                  this.model.blPrepaidAt2Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P2', 'blPCName': 'Prepaid 2'});
                }
                if (this.model.blPrepaidAt3Code != null &&
                  this.model.blPrepaidAt3Code !== '' &&
                  this.model.blPrepaidAt3Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P3', 'blPCName': 'Prepaid 3'});
                }
                if (this.model.blPayableAt1Code != null &&
                  this.model.blPayableAt1Code !== '' &&
                  this.model.blPayableAt1Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C1', 'blPCName': 'Collect 1'});
                }
                if (this.model.blPayableAt2Code != null &&
                  this.model.blPayableAt2Code !== '' &&
                  this.model.blPayableAt2Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C2', 'blPCName': 'Collect 2'});
                }
                if (this.model.blPayableAt3Code != null &&
                  this.model.blPayableAt3Code !== '' &&
                  this.model.blPayableAt3Code !== undefined) {
                  this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C3', 'blPCName': 'Collect 3'});
                }

                this.cbBlNettChargeInputPC.loadSelect();

                this.cbBlNettChargeInputPC.setValue(this.paramFreightCharge.blFCPc);
                //}


                // populate payer
                //if (this.paramFreightCharge.blFCPayer !== '') {
                this.cbBlNettChargeInputPayer.listStore.store = [];

                if (this.model.blPayer1Code != null &&
                  this.model.blPayer1Code !== '' && this.model.blPayer1Code !== undefined) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    'blPCPayerCode': '1',
                    'blPCPayerName': 'Payer 1'
                  });
                }
                if (this.model.blPayer2Code != null &&
                  this.model.blPayer2Code !== '' && this.model.blPayer2Code !== undefined) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    'blPCPayerCode': '2',
                    'blPCPayerName': 'Payer 2'
                  });
                }
                if (this.model.blPayer3Code != null &&
                  this.model.blPayer3Code !== '' && this.model.blPayer3Code !== undefined) {
                  this.cbBlNettChargeInputPayer.listStore.store.push({
                    'blPCPayerCode': '3',
                    'blPCPayerName': 'Payer 3'
                  });
                }
                this.cbBlNettChargeInputPayer.listStore.store.push({'blPCPayerCode': '', 'blPCPayerName': ''});

                this.cbBlNettChargeInputPayer.loadSelect();

                this.cbBlNettChargeInputPayer.setValue(this.paramFreightCharge.blFCPayer);
                //}
                */

                this.modeFormBlNettChargeConsole = "edit";

                // lock other tab
                this.lockParty = true;
                this.lockPlace = true;
                this.lockContainer = true;
                this.lockMarkNo = true;
                this.lockDesc = true;
                this.lockNettCharge = false;
                this.lockGrossCharge = true;
                this.lockNettChargeConsole = false;
                this.lockGrossChargeConsole = true;
              }

              break;

            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  infoGridBlNettCharge(event) {}
  infoGridBlNettChargeConsole(event) {}

  onBlNettChargeNew() {
    this.isRefreightButtonClicked = false; // to force user do refreight again (stupid request)
    this.isBLFNewEntry = true; // to avoid blf auto populated
    this.nettChargeDisableDelete = true;
    this.txtBlContainerInsertUpdate = "Insert";
    this.blNettChargeUpdateMode = "insert";
    this.isErrorNettCharge = false;
    this.model["error-placeOfPayment"] = "";
    this.model["error-billingParty"] = "";

    if (
      this.model.blPrepaidAt1Code === "" &&
      this.model.blPrepaidAt2Code === "" &&
      this.model.blPrepaidAt3Code === "" &&
      this.model.blPayableAt1Code === "" &&
      this.model.blPayableAt2Code === "" &&
      this.model.blPayableAt3Code === ""
    ) {
      this.isErrorNettCharge = true;
      this.model["error-placeOfPayment"] =
        "Please input at least one Place of Payment.";
    }

    if (
      this.model.blPayer1Code === "" &&
      this.model.blPayer2Code === "" &&
      this.model.blPayer3Code === ""
    ) {
      this.isErrorNettCharge = true;
      this.model["error-billingParty"] =
        "Please input at least one Billing Party.";
    }

    if (!this.isErrorNettCharge) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
      this.modeFormBlNettCharge = "edit";
      this.disableNewUpdateChargeButton = true;

      if (this.model.blType === "SW" || this.model.blType === "OIS") {
        this.cbBlNettChargeInputCharge.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCharges/findByComboBoxControlAll/chargeCodeOrName={query}"
        );
      } else {
        this.cbBlNettChargeInputCharge.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCharges/findByComboBoxControlAdHocOnly/chargeCodeOrName={query}"
        );
      }

      this.paramFreightCharge = new BLFreightCharge();
      this.paramFreightCharge.blFCOfficeCode = this.model.blOfficeCode;
      this.paramFreightCharge.blFCBlNo = this.model.blNo;
      this.paramFreightCharge.blFCSeq =
        this.gridBlNettCharge.listStore.store.length + 1;
      this.paramFreightCharge.blFCIsManifestedFreight = true;
      this.paramFreightCharge.blFCIsManifestedFreightString = "Y";
      this.paramFreightCharge.blFCIsChargePrinted = false;
      this.paramFreightCharge.blFCIsChargePrintedString = "N";
      this.paramFreightCharge.blFCFaiGroup = false;
      this.paramFreightCharge.blFCFaiGroupString = "N";
      this.paramFreightCharge.blFCIsEntered = true;
      this.paramFreightCharge.blFCIsEnteredString = "N";

      // populate PC
      this.cbBlNettChargeInputPC.listStore.store = [];

      if (
        this.model.blPrepaidAt1Code != null &&
        this.model.blPrepaidAt1Code !== "" &&
        this.model.blPrepaidAt1Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "P1",
          blPCName: "Prepaid 1",
        });
      }
      if (
        this.model.blPrepaidAt2Code != null &&
        this.model.blPrepaidAt2Code !== "" &&
        this.model.blPrepaidAt2Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "P2",
          blPCName: "Prepaid 2",
        });
      }
      if (
        this.model.blPrepaidAt3Code != null &&
        this.model.blPrepaidAt3Code !== "" &&
        this.model.blPrepaidAt3Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "P3",
          blPCName: "Prepaid 3",
        });
      }
      if (
        this.model.blPayableAt1Code != null &&
        this.model.blPayableAt1Code !== "" &&
        this.model.blPayableAt1Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "C1",
          blPCName: "Collect 1",
        });
      }
      if (
        this.model.blPayableAt2Code != null &&
        this.model.blPayableAt2Code !== "" &&
        this.model.blPayableAt2Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "C2",
          blPCName: "Collect 2",
        });
      }
      if (
        this.model.blPayableAt3Code != null &&
        this.model.blPayableAt3Code !== "" &&
        this.model.blPayableAt3Code !== undefined
      ) {
        this.cbBlNettChargeInputPC.listStore.store.push({
          blPCCode: "C3",
          blPCName: "Collect 3",
        });
      }
      console.log(this.cbBlNettChargeInputPC.data);

      // console.log('debug new charge')
      // console.log(this.model);

      const self = this;
      this.cbBlNettChargeInputPC.loadSelect(function (callbackpc) {
        if (
          self.cbBlNettChargeInputPC.listStore.store !== undefined &&
          self.cbBlNettChargeInputPC.listStore.store.length > 1
        ) {
          self.paramFreightCharge.blFCPc = "";
          self.cbBlNettChargeInputPC.setValue("");
        } else {
          self.paramFreightCharge.blFCPc =
            self.cbBlNettChargeInputPC.listStore.store[0][
              "blPCCode"
            ].toString();
          self.cbBlNettChargeInputPC.setValue(
            self.cbBlNettChargeInputPC.listStore.store[0]["blPCCode"]
          );
        }

        // to auto set payer location
        if (
          self.cbBlNettChargeInputPC.listStore.store !== undefined &&
          self.cbBlNettChargeInputPC.listStore.store.length > 1
        ) {
          self.paramFreightCharge.blFCPlaceOfPaymentCode = "";
          self.paramFreightCharge.blFCPlaceOfPaymentName = "";
        } else {
          switch (self.cbBlNettChargeInputPC.listStore.store[0]["blPCCode"]) {
            case "P1": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPrepaidAt1Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPrepaidAt1Name;
              break;
            }
            case "P2": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPrepaidAt2Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPrepaidAt2Name;
              break;
            }
            case "P3": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPrepaidAt3Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPrepaidAt3Name;
              break;
            }
            case "C1": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPayableAt1Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPayableAt1Name;
              break;
            }
            case "C2": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPayableAt2Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPayableAt2Name;
              break;
            }
            case "C3": {
              self.paramFreightCharge.blFCPlaceOfPaymentCode =
                self.model.blPayableAt3Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName =
                self.model.blPayableAt3Name;
              break;
            }
            default: {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = "";
              self.paramFreightCharge.blFCPlaceOfPaymentName = "";
              break;
            }
          }
        }
      });

      // populate payer
      this.cbBlNettChargeInputPayer.listStore.store = [];
      console.log(this.model);

      console.log("payer1:"+this.model.blPayer1Code);
      console.log("payer2:"+this.model.blPayer2Code);
      console.log("payer3:"+this.model.blPayer3Code);
      if (
        this.model.blPayer1Code != null &&
        this.model.blPayer1Code !== "" &&
        this.model.blPayer1Code !== undefined
      ) {
        this.cbBlNettChargeInputPayer.listStore.store.push({
          blPCPayerCode: "1",
          blPCPayerName: "Payer 1",
        });
        this.getValidationPayer("payer1");
      }
      if (
        this.model.blPayer2Code != null &&
        this.model.blPayer2Code !== "" &&
        this.model.blPayer2Code !== undefined
      ) {
        this.cbBlNettChargeInputPayer.listStore.store.push({
          blPCPayerCode: "2",
          blPCPayerName: "Payer 2",
        });
        this.getValidationPayer("payer2");
      }
      if (
        this.model.blPayer3Code != null &&
        this.model.blPayer3Code !== "" &&
        this.model.blPayer3Code !== undefined
      ) {
        this.cbBlNettChargeInputPayer.listStore.store.push({
          blPCPayerCode: "3",
          blPCPayerName: "Payer 3",
        });
        this.getValidationPayer("payer3");
      }
      this.cbBlNettChargeInputPayer.listStore.store.push({
        blPCPayerCode: "",
        blPCPayerName: "",
      });

      console.log(this.cbBlNettChargeInputPayer.data);

      this.cbBlNettChargeInputPayer.loadSelect(function (callbackpayer) {
        self.paramFreightCharge.blFCPayer = "";
        self.cbBlNettChargeInputPayer.setValue("");
      });

      // lock the form
      this.lockParty = true;
      this.lockPlace = true;
      this.lockContainer = true;
      this.lockMarkNo = true;
      this.lockDesc = true;
      this.lockNettCharge = false;
      this.lockGrossCharge = true;
      this.lockNettChargeConsole = false;
      this.lockGrossChargeConsole = true;
    }
  }

  onBlNettChargeNewConsole() {
    this.isRefreightButtonClickedConsole = false; // to force user do refreight again (stupid request)
    this.isBLFNewEntryConsole = true; // to avoid blf auto populated
    this.nettChargeDisableDeleteConsole = true;
    this.txtBlContainerInsertUpdateConsole = "Insert";
    this.blNettChargeUpdateModeConsole = "insert";

    this.isErrorNettChargeConsole = false;
    /*
    this.model['error-placeOfPayment'] = '';
    this.model['error-billingParty'] = '';

    if (this.model.blPrepaidAt1Code === '' && this.model.blPrepaidAt2Code === '' && this.model.blPrepaidAt3Code === '' &&
      this.model.blPayableAt1Code === '' && this.model.blPayableAt2Code === '' && this.model.blPayableAt3Code === ''
    ) {
      this.isErrorNettCharge = true;
      this.model['error-placeOfPayment'] = 'Please input at least one Place of Payment.';
    }

    if (this.model.blPayer1Code === '' && this.model.blPayer2Code === '' && this.model.blPayer3Code === '') {
      this.isErrorNettCharge = true;
      this.model['error-billingParty'] = 'Please input at least one Billing Party.';
    }

    if (!this.isErrorNettCharge) {
      */
    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,cancel,close,ias,print";
    this.modeFormBlNettChargeConsole = "edit";

    if (this.model.blType === "SW" || this.model.blType === "OIS") {
      this.cbBlNettChargeInputChargeConsole.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCharges/findByComboBoxControlAll/chargeCodeOrName={query}"
      );
    } else {
      this.cbBlNettChargeInputChargeConsole.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCharges/findByComboBoxControlAdHocOnly/chargeCodeOrName={query}"
      );
    }

    this.paramFreightChargeConsole = new BLFreightCharge();
    this.paramFreightChargeConsole.blFCOfficeCode = this.model.blOfficeCode;
    this.paramFreightChargeConsole.blFCBlNo = this.model.blNo;
    this.paramFreightChargeConsole.blFCSeq =
      this.gridBlNettChargeConsole.listStore.store.length + 1;
    this.paramFreightChargeConsole.blFCIsManifestedFreight = true;
    this.paramFreightChargeConsole.blFCIsManifestedFreightString = "Y";
    this.paramFreightChargeConsole.blFCIsChargePrinted = false;
    this.paramFreightChargeConsole.blFCIsChargePrintedString = "N";
    this.paramFreightChargeConsole.blFCFaiGroup = false;
    this.paramFreightChargeConsole.blFCFaiGroupString = "N";
    this.paramFreightChargeConsole.blFCIsEntered = true;
    this.paramFreightChargeConsole.blFCIsEnteredString = "N";

    /*
      // populate PC
      this.cbBlNettChargeInputPC.listStore.store = [];

      if (this.model.blPrepaidAt1Code != null && this.model.blPrepaidAt1Code !== '' && this.model.blPrepaidAt1Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P1', 'blPCName': 'Prepaid 1'});
      }
      if (this.model.blPrepaidAt2Code != null && this.model.blPrepaidAt2Code !== '' && this.model.blPrepaidAt2Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P2', 'blPCName': 'Prepaid 2'});
      }
      if (this.model.blPrepaidAt3Code != null && this.model.blPrepaidAt3Code !== '' && this.model.blPrepaidAt3Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'P3', 'blPCName': 'Prepaid 3'});
      }
      if (this.model.blPayableAt1Code != null && this.model.blPayableAt1Code !== '' && this.model.blPayableAt1Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C1', 'blPCName': 'Collect 1'});
      }
      if (this.model.blPayableAt2Code != null && this.model.blPayableAt2Code !== '' && this.model.blPayableAt2Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C2', 'blPCName': 'Collect 2'});
      }
      if (this.model.blPayableAt3Code != null && this.model.blPayableAt3Code !== '' && this.model.blPayableAt3Code !== undefined) {
        this.cbBlNettChargeInputPC.listStore.store.push({'blPCCode': 'C3', 'blPCName': 'Collect 3'});
      }

      // console.log('debug new charge')
      // console.log(this.model);

      const self = this;
      this.cbBlNettChargeInputPC.loadSelect(function (callbackpc) {
        if (self.cbBlNettChargeInputPC.listStore.store !== undefined && self.cbBlNettChargeInputPC.listStore.store.length > 1) {
          self.paramFreightCharge.blFCPc = '';
          self.cbBlNettChargeInputPC.setValue('');
        } else {
          self.paramFreightCharge.blFCPc = self.cbBlNettChargeInputPC.listStore.store[0]['blPCCode'].toString();
          self.cbBlNettChargeInputPC.setValue(self.cbBlNettChargeInputPC.listStore.store[0]['blPCCode']);
        }


        // to auto set payer location
        if (self.cbBlNettChargeInputPC.listStore.store !== undefined && self.cbBlNettChargeInputPC.listStore.store.length > 1) {
          self.paramFreightCharge.blFCPlaceOfPaymentCode = '';
          self.paramFreightCharge.blFCPlaceOfPaymentName = '';
        } else {
          switch (self.cbBlNettChargeInputPC.listStore.store[0]['blPCCode']) {
            case 'P1': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPrepaidAt1Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPrepaidAt1Name;
              break;
            }
            case 'P2': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPrepaidAt2Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPrepaidAt2Name;
              break;
            }
            case 'P3': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPrepaidAt3Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPrepaidAt3Name;
              break;
            }
            case 'C1': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPayableAt1Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPayableAt1Name;
              break;
            }
            case 'C2': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPayableAt2Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPayableAt2Name;
              break;
            }
            case 'C3': {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = self.model.blPayableAt3Code;
              self.paramFreightCharge.blFCPlaceOfPaymentName = self.model.blPayableAt3Name;
              break;
            }
            default : {
              self.paramFreightCharge.blFCPlaceOfPaymentCode = '';
              self.paramFreightCharge.blFCPlaceOfPaymentName = '';
              break;
            }
          }
        }

      });


      // populate payer
      this.cbBlNettChargeInputPayer.listStore.store = [];

      if (this.model.blPayer1Code != null && this.model.blPayer1Code !== '' && this.model.blPayer1Code !== undefined) {
        this.cbBlNettChargeInputPayer.listStore.store.push({'blPCPayerCode': '1', 'blPCPayerName': 'Payer 1'});
      }
      if (this.model.blPayer2Code != null && this.model.blPayer2Code !== '' && this.model.blPayer2Code !== undefined) {
        this.cbBlNettChargeInputPayer.listStore.store.push({'blPCPayerCode': '2', 'blPCPayerName': 'Payer 2'});
      }
      if (this.model.blPayer3Code != null && this.model.blPayer3Code !== '' && this.model.blPayer3Code !== undefined) {
        this.cbBlNettChargeInputPayer.listStore.store.push({'blPCPayerCode': '3', 'blPCPayerName': 'Payer 3'});
      }
      this.cbBlNettChargeInputPayer.listStore.store.push({'blPCPayerCode': '', 'blPCPayerName': ''});

      this.cbBlNettChargeInputPayer.loadSelect(function (callbackpayer) {
        self.paramFreightCharge.blFCPayer = '';
        self.cbBlNettChargeInputPayer.setValue('');
      });
      */
    // lock the form
    this.lockParty = true;
    this.lockPlace = true;
    this.lockContainer = true;
    this.lockMarkNo = true;
    this.lockDesc = true;
    this.lockNettCharge = false;
    this.lockGrossCharge = true;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = true;
    //}
  }

  onBlNettChargeEntryCancel() {
    this.isErrorNettChargeEntry = false;
    this.netChargeLock = false;
    this.txtBlContainerInsertUpdate = "Insert";
    this.blNettChargeUpdateMode = "insert";
    this.modeFormBlNettCharge = "view";
    this.isBLFNewEntry = false; // to avoid blf auto populated
    this.onBlNettChargeEntryReset();

    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }
    this.nettChargeDisableDelete = false;

    if(this.viewOnlyStatus){
      console.log("Masuk cancel nett charge");
      this.disableToolbarButtons =
          "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
      this.disableNewUpdateChargeButton =false;
    }else{
      if (this.blLock == "N") {
        // When the status is cleared or locked, only view
        if(this.model.blStatus === "D" || this.isOpenedByOtherUser === true){
          this.lockTab6 = true;
        }else{
          this.lockTab6 = false;
        }
      } else if (this.blLock == "Y") {
        this.disableToolbarButtons =
          "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
        this.lockTab6 = true;
      } else if (this.blLock == "T1") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T2") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T3") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T4") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T5") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T6") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T7") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      } else if (this.blLock == "T8") {
        this.disableToolbarButtons = "retrieve,printInvoice,ias,print,partialSave2,save2";
      }
    }
  }

  onBlNettChargeEntryCancelConsole() {
    this.isErrorNettChargeEntryConsole = false;
    this.txtBlContainerInsertUpdateConsole = "Insert";
    this.blNettChargeUpdateModeConsole = "insert";
    this.modeFormBlNettChargeConsole = "view";
    this.isBLFNewEntryConsole = false; // to avoid blf auto populated
    this.onBlNettChargeEntryResetConsole();

    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if(this.viewOnlyStatus){
        this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
      }else{
        if (this.officeCode == "THBKK") {
          this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
        } else {
          this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
        }
      }

    }
    this.nettChargeDisableDeleteConsole = false;
  }

  onBlNettChargeEntryDelete() {
    this.isBLFNewEntry = false; // to avoid blf auto populated
    this.genericUtil.showDialog(
      "blConfirmDeleteNettCharge",
      "Confirmation",
      350,
      150
    );
  }

  onBlNettChargeEntryDeleteConsole() {
    this.isBLFNewEntryConsole = false; // to avoid blf auto populated
    this.genericUtil.showDialog(
      "blConfirmDeleteNettChargeConsole",
      "Confirmation",
      350,
      150
    );
  }

  onBlNettChargeEntryInsert() {
    this.isErrorNettChargeEntry = false;
    this.paramFreightCharge["error-blFCChargeCode"] = "";
    this.paramFreightCharge["error-blFCPayer"] = "";
    this.paramFreightCharge["error-blFCPerCode"] = "";
    this.paramFreightCharge["error-blFCCurrencyCode"] = "";
    this.paramFreightCharge["error-blFCDuplicateRecord"] = "";
    this.paramFreightCharge["error-blFCFaiGroup"] = "";
    this.paramFreightCharge["error-blFCRevenue"] = "";
    this.paramFreightCharge["error-blFCPc"] = "";
    this.paramFreightCharge["error-blFCRate"] = "";

    this.isBLFNewEntry = false; // to avoid blf auto populated

    if (this.blNettChargeUpdateMode === "insert") {
      if (
        this.paramFreightCharge.blFCChargeCode == null ||
        this.paramFreightCharge.blFCChargeCode === "" ||
        this.paramFreightCharge.blFCChargeCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCChargeCode"] =
          "Please input Charge(s).";
      }
      if (
        this.paramFreightCharge.blFCPc == null ||
        this.paramFreightCharge.blFCPc === "" ||
        this.paramFreightCharge.blFCPc === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCPc"] = "Please input P/C.";
      }
      if (
        this.paramFreightCharge.blFCPc === "P1" ||
        this.paramFreightCharge.blFCPc === "P2" ||
        this.paramFreightCharge.blFCPc === "P3"
      ) {
        if(
          this.paramFreightCharge.blFCPayer == null ||
          this.paramFreightCharge.blFCPayer === "" ||
          this.paramFreightCharge.blFCPayer === undefined
        ){
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCPayer"] = "Please input Payer.";
        } else {
          this.paramFreightCharge["error-blFCPayer"] = "";
          this.checkValidationPayer(this.paramFreightCharge.blFCPayer);
        }
      }
      if (
        this.paramFreightCharge.blFCPerCode == null ||
        this.paramFreightCharge.blFCPerCode === "" ||
        this.paramFreightCharge.blFCPerCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCPerCode"] = "Please input Per Code.";
      }
      if (
        this.paramFreightCharge.blFCCurrencyCode == null ||
        this.paramFreightCharge.blFCCurrencyCode === "" ||
        this.paramFreightCharge.blFCCurrencyCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCCurrencyCode"] =
          "Please input Charge Currency.";
      }

      if (this.paramFreightCharge.blFCRevenue <= 0) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCRevenue"] = "Please input Quantity.";
      }

      if (this.paramFreightCharge.blFCRate < 0) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCRate"] = `Charge Rate cannot be negative.`;
      }

      if(this.paramFreightCharge.blFCChargeCode != "FOF"){
        // GOS-638 Adhoc charges should not allow amount=0 to be inserted
        if (!this.paramFreightCharge.blFCRate) {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCRate"] = `Please input Charge Rate.`;
        }

        if (this.paramFreightCharge.blFCRate === 0 && !this.paramFreightCharge.blFCFreightReference) {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCRate"] = `Please input charges value. ${this.paramFreightCharge.blFCChargeCode} charge code cannot be 0 value.`;
        }
      }

      // Fai Group Checking segment
      let mapFAI = new Map<String, String[]>();

      if (this.gridBlNettCharge.listStore.store.length > 0) {
        this.gridBlNettCharge.listStore.store.forEach((element) => {
          console.log(element);
          if (element.blFCFaiGroupString == "Y") {
            if (mapFAI.get(element.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(element.blFCPerCode, blfList);
            } else {
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              mapFAI.get(element.blFCPerCode).push(key);
            }
          }
        });
      }

      if (this.paramFreightCharge.blFCFaiGroup === true) {
        console.log(mapFAI);
        if (this.paramFreightCharge.blFCChargeCode !== "FAI") {
          if (mapFAI.get(this.paramFreightCharge.blFCPerCode) != null) {
            mapFAI
              .get(this.paramFreightCharge.blFCPerCode)
              .forEach((element) => {
                var key: String =
                  this.paramFreightCharge.blFCPerCode +
                  ";;" +
                  this.paramFreightCharge.blFCPayer +
                  ";;" +
                  this.paramFreightCharge.blFCCurrencyCode +
                  ";;" +
                  this.paramFreightCharge.blFCPlaceOfPaymentCode +
                  ";;" +
                  this.paramFreightCharge.blFCPc;
                var el: String =
                  element.split(";;")[0] +
                  ";;" +
                  element.split(";;")[1] +
                  ";;" +
                  element.split(";;")[2] +
                  ";;" +
                  element.split(";;")[3] +
                  ";;" +
                  element.split(";;")[4];
                console.log(key);
                console.log(el);
                if (el !== key) {
                  this.isErrorNettChargeEntry = true;

                  // Generate discrepancy message
                  if (
                    this.paramFreightCharge.blFCPerCode !==
                    element.split(";;")[0]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Per Code.";
                  }
                  if (
                    this.paramFreightCharge.blFCPayer !== element.split(";;")[1]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Payer.";
                  }
                  if (
                    this.paramFreightCharge.blFCCurrencyCode !==
                    element.split(";;")[2]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Currency.";
                  }
                  if (
                    this.paramFreightCharge.blFCPlaceOfPaymentCode !==
                    element.split(";;")[3]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check PlaceOfPayment.";
                  }
                  if (
                    this.paramFreightCharge.blFCPc !== element.split(";;")[4]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check P/C.";
                  }
                } else {
                  if (mapFAI.get(this.paramFreightCharge.blFCPerCode) == null) {
                    let blfList: String[] = [];
                    var key: String =
                      this.paramFreightCharge.blFCPerCode +
                      ";;" +
                      this.paramFreightCharge.blFCPayer +
                      ";;" +
                      this.paramFreightCharge.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightCharge.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightCharge.blFCPc +
                      ";;" +
                      this.paramFreightCharge.blFCChargeCode;
                    blfList.push(key);
                    mapFAI.set(this.paramFreightCharge.blFCPerCode, blfList);
                  } else {
                    var key: String =
                      this.paramFreightCharge.blFCPerCode +
                      ";;" +
                      this.paramFreightCharge.blFCPayer +
                      ";;" +
                      this.paramFreightCharge.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightCharge.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightCharge.blFCPc +
                      ";;" +
                      this.paramFreightCharge.blFCChargeCode;
                    mapFAI.get(this.paramFreightCharge.blFCPerCode).push(key);
                  }
                }
              });
          } else {
            if (mapFAI.get(this.paramFreightCharge.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                this.paramFreightCharge.blFCPerCode +
                ";;" +
                this.paramFreightCharge.blFCPayer +
                ";;" +
                this.paramFreightCharge.blFCCurrencyCode +
                ";;" +
                this.paramFreightCharge.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightCharge.blFCPc +
                ";;" +
                this.paramFreightCharge.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(this.paramFreightCharge.blFCPerCode, blfList);
            } else {
              var key: String =
                this.paramFreightCharge.blFCPerCode +
                ";;" +
                this.paramFreightCharge.blFCPayer +
                ";;" +
                this.paramFreightCharge.blFCCurrencyCode +
                ";;" +
                this.paramFreightCharge.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightCharge.blFCPc +
                ";;" +
                this.paramFreightCharge.blFCChargeCode;
              mapFAI.get(this.paramFreightCharge.blFCPerCode).push(key);
            }
          }
        } else {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCFaiGroup"] =
            "FAI is not applicable under FAI Group.";
        }
      }

      /*
       // Fai Group Checking segment
       const listOfFaiGroupCharges: Array<String> = [];
       const distinctOfListOfFaiGroupCharges: Array<String> = [];
       if (this.paramFreightCharge.blFCFaiGroup === true) {
       if (this.paramFreightCharge.blFCChargeCode !== 'FAI') {
       // if the current charge got fai group checked
       // must loop through the nett charge to check the existence of another freight group
       if (this.gridBlNettCharge.listStore.store.length > 0) {
       for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
       console.log('-- debug FAI Check --');
       console.log(this.gridBlNettCharge.listStore.store[i].blFCSeq + ' -VS- ' + this.paramFreightCharge.blFCSeq);
       if(this.gridBlNettCharge.listStore.store[i].blFCSeq !== this.paramFreightCharge.blFCSeq) {
       if (this.gridBlNettCharge.listStore.store[i].blFCFaiGroup === true) {
       listOfFaiGroupCharges.push(this.gridBlNettCharge.listStore.store[i].blFCPerCode + ';;' +
       this.gridBlNettCharge.listStore.store[i].blFCPayer + ';;' + this.gridBlNettCharge.listStore.store[i].blFCCurrencyCode +
       ';;' + this.gridBlNettCharge.listStore.store[i].blFCPlaceOfPaymentCode + ';;' + this.gridBlNettCharge.listStore.store[i].blFCPc);
       }
       }

       }
       for (const entry of listOfFaiGroupCharges) {
       console.log('fai entry debug => ' + entry);
       if (!distinctOfListOfFaiGroupCharges.includes(entry)) {
       distinctOfListOfFaiGroupCharges.push(entry);
       }
       }

       // the the number 1 record as comparer
       for (const entry of distinctOfListOfFaiGroupCharges) {
       console.log('debug fai group');
       console.log(entry);
       if (entry !== (this.paramFreightCharge.blFCPerCode + ';;' + this.paramFreightCharge.blFCPayer +
       ';;' + this.paramFreightCharge.blFCCurrencyCode + ';;' + this.paramFreightCharge.blFCPlaceOfPaymentCode + ';;' + this.paramFreightCharge.blFCPc)) {
       this.isErrorNettChargeEntry = true;

       // Generate discrepancy message
       if (this.paramFreightCharge.blFCPerCode !== entry.split(';;')[0]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Per Code.';
       }
       if (this.paramFreightCharge.blFCPayer !== entry.split(';;')[1]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Payer.';
       }
       if (this.paramFreightCharge.blFCCurrencyCode !== entry.split(';;')[2]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Currency.';
       }
       if (this.paramFreightCharge.blFCPlaceOfPaymentCode !== entry.split(';;')[3]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check PlaceOfPayment.';
       }
       if (this.paramFreightCharge.blFCPc !== entry.split(';;')[4]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check P/C.';
       }

       }
       }

       }
       } else {
       this.isErrorNettChargeEntry = true;
       this.paramFreightCharge['error-blFCFaiGroup'] = 'FAI is not applicable under FAI Group.';
       }

       }
       */

      // check duplicate
      for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
        let newRecordString = this.paramFreightCharge.blFCChargeCode.trim();
        newRecordString += this.paramFreightCharge.blFCPerCode.trim();
        newRecordString += this.paramFreightCharge.blFCCurrencyCode.trim();

        let existingRecordString =
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode;
        existingRecordString +=
          this.gridBlNettCharge.listStore.store[i].blFCPerCode;
        existingRecordString +=
          this.gridBlNettCharge.listStore.store[i].blFCCurrencyCode;

        if (
          newRecordString.toUpperCase().trim() ===
          existingRecordString.toUpperCase().trim()
        ) {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCDuplicateRecord"] =
            "Duplicate record detected, please check your input.";
        }
      }

      if (
        this.paramFreightCharge.blFCChargeCode === "OFT" ||
        this.paramFreightCharge.blFCChargeCode === "FOF" ||
        this.paramFreightCharge.blFCChargeCode === "FAI"
      ) {
        let newRecordString2 = this.paramFreightCharge.blFCPerCode.trim();
        newRecordString2 += this.paramFreightCharge.blFCCurrencyCode.trim();
        for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
          if (
            this.gridBlNettCharge.listStore.store[i].blFCSeq !==
            this.paramFreightCharge.blFCSeq
          ) {
            if (
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode ===
                "OFT" ||
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode ===
                "FAI" ||
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FOF"
            ) {
              let existingRecordString2 =
                this.gridBlNettCharge.listStore.store[i].blFCPerCode;
              existingRecordString2 +=
                this.gridBlNettCharge.listStore.store[i].blFCCurrencyCode;

              if (this.model.blIsCombine !== "Y") {
                if (
                  newRecordString2.toUpperCase().trim() ===
                  existingRecordString2.toUpperCase().trim()
                ) {
                  this.isErrorNettChargeEntry = true;
                  this.paramFreightCharge["error-blFCDuplicateRecord"] =
                    "Duplicate main charge detected, please check your input.";
                }
              }
            }
          }
        }
      }

      if (!this.isErrorNettChargeEntry) {
        this.gridBlNettCharge.listStore.store.push(this.paramFreightCharge);
        this.gridBlNettCharge.loadData();

        // this.modeFormBlNettCharge = 'view';
        this.onBlNettChargeEntryCancel();
      }
    } else {
      this.txtBlContainerInsertUpdate = "Update";

      if (
        this.paramFreightCharge.blFCChargeCode == null ||
        this.paramFreightCharge.blFCChargeCode === "" ||
        this.paramFreightCharge.blFCChargeCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCChargeCode"] =
          "Please input Charge(s).";
      }
      if (
        this.paramFreightCharge.blFCPc == null ||
        this.paramFreightCharge.blFCPc === "" ||
        this.paramFreightCharge.blFCPc === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCPc"] = "Please input P/C.";
      }
      if (
        this.paramFreightCharge.blFCPc === "P1" ||
        this.paramFreightCharge.blFCPc === "P2" ||
        this.paramFreightCharge.blFCPc === "P3"
      ) {
        if(
          this.paramFreightCharge.blFCPayer == null ||
          this.paramFreightCharge.blFCPayer === "" ||
          this.paramFreightCharge.blFCPayer === undefined
        ){
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCPayer"] = "Please input Payer.";
        }  else {
          if (this.paramFreightCharge.blFCFreightReference == null || this.paramFreightCharge.blFCFreightReference === "" ||
              this.paramFreightCharge.blFCFreightReference === undefined) {
              this.paramFreightCharge["error-blFCPayer"] = "";
              this.checkValidationPayer(this.paramFreightCharge.blFCPayer);
          }

        }
      }
      if (
        this.paramFreightCharge.blFCPerCode == null ||
        this.paramFreightCharge.blFCPerCode === "" ||
        this.paramFreightCharge.blFCPerCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCPerCode"] =
          "Please input Charge Per.";
      }
      if (
        this.paramFreightCharge.blFCCurrencyCode == null ||
        this.paramFreightCharge.blFCCurrencyCode === "" ||
        this.paramFreightCharge.blFCCurrencyCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCCurrencyCode"] =
          "Please input Charge Currency.";
      }

      if (this.paramFreightCharge.blFCRevenue <= 0) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCRevenue"] = "Please input Quantity.";
      }

      if (this.paramFreightCharge.blFCRate < 0) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCRate"] = `Charge Rate cannot be negative.`;
      }

      if(this.paramFreightCharge.blFCChargeCode != "FOF"){
        // GOS-638 Adhoc charges should not allow amount=0 to be inserted
        if (!this.paramFreightCharge.blFCRate) {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCRate"] = `Please input Charge Rate.`;
        }

        if (this.paramFreightCharge.blFCRate === 0 && !this.paramFreightCharge.blFCFreightReference) {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCRate"] = `Please input charges value. ${this.paramFreightCharge.blFCChargeCode} charge code cannot be 0 value.`;
        }

      }

      /*
       log.info('checking place of payment')
       if (this.paramFreightCharge.blFCPlaceOfPaymentName <= 0 ) {
       this.isErrorNettChargeEntry = true;
       this.paramFreightCharge['error-blFCPlaceOfPaymentName'] = 'Place of payment mismatch, please re';
       }
       */

      // check duplicate main charge
      if (
        this.paramFreightCharge.blFCChargeCode === "OFT" ||
        this.paramFreightCharge.blFCChargeCode === "FOF" ||
        this.paramFreightCharge.blFCChargeCode === "FAI"
      ) {
        let newRecordString2 = this.paramFreightCharge.blFCPerCode.trim();
        newRecordString2 += this.paramFreightCharge.blFCCurrencyCode.trim();
        for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
          console.log(
            "debug seq no --> " +
              this.gridBlNettCharge.listStore.store[i].blFCSeq +
              " vs " +
              this.paramFreightCharge.blFCSeq
          );
          if (
            this.gridBlNettCharge.listStore.store[i].blFCSeq !==
            this.paramFreightCharge.blFCSeq
          ) {
            if (
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode ===
                "OFT" ||
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode ===
                "FAI" ||
              this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FOF"
            ) {
              let existingRecordString2 =
                this.gridBlNettCharge.listStore.store[i].blFCPerCode;
              existingRecordString2 +=
                this.gridBlNettCharge.listStore.store[i].blFCCurrencyCode;

              if (this.model.blIsCombine !== "Y") {
                if (
                  newRecordString2.toUpperCase().trim() ===
                  existingRecordString2.toUpperCase().trim()
                ) {
                  this.isErrorNettChargeEntry = true;
                  this.paramFreightCharge["error-blFCDuplicateRecord"] =
                    "Duplicate main charge detected, please check your input.";
                }
              }
            }
          }
        }
      }

      // Fai Group Checking segment
      let mapFAI = new Map<String, String[]>();

      if (this.gridBlNettCharge.listStore.store.length > 0) {
        this.gridBlNettCharge.listStore.store.forEach((element) => {
          console.log(element);
          if (element.blFCFaiGroupString == "Y") {
            if (mapFAI.get(element.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(element.blFCPerCode, blfList);
            } else {
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              mapFAI.get(element.blFCPerCode).push(key);
            }
          }
        });
      }

      if (this.paramFreightCharge.blFCFaiGroup === true) {
        console.log(mapFAI);
        console.log(this.paramFreightCharge.blFCChargeCode);

        if (this.paramFreightCharge.blFCChargeCode !== "FAI") {
          console.log(mapFAI.get(this.paramFreightCharge.blFCPerCode));
          if (mapFAI.get(this.paramFreightCharge.blFCPerCode) != null) {
            mapFAI
              .get(this.paramFreightCharge.blFCPerCode)
              .forEach((element) => {
                var key: String =
                  this.paramFreightCharge.blFCPerCode +
                  ";;" +
                  this.paramFreightCharge.blFCPayer +
                  ";;" +
                  this.paramFreightCharge.blFCCurrencyCode +
                  ";;" +
                  this.paramFreightCharge.blFCPlaceOfPaymentCode +
                  ";;" +
                  this.paramFreightCharge.blFCPc;
                var el: String =
                  element.split(";;")[0] +
                  ";;" +
                  element.split(";;")[1] +
                  ";;" +
                  element.split(";;")[2] +
                  ";;" +
                  element.split(";;")[3] +
                  ";;" +
                  element.split(";;")[4];

                console.log(el);
                console.log(key);

                if (el !== key) {
                  this.isErrorNettChargeEntry = true;

                  // Generate discrepancy message
                  if (
                    this.paramFreightCharge.blFCPerCode !==
                    element.split(";;")[0]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Per Code.";
                  }
                  if (
                    this.paramFreightCharge.blFCPayer !== element.split(";;")[1]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Payer.";
                  }
                  if (
                    this.paramFreightCharge.blFCCurrencyCode !==
                    element.split(";;")[2]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Currency.";
                  }
                  if (
                    this.paramFreightCharge.blFCPlaceOfPaymentCode !==
                    element.split(";;")[3]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check PlaceOfPayment.";
                  }
                  if (
                    this.paramFreightCharge.blFCPc !== element.split(";;")[4]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check P/C.";
                  }
                } else {
                  if (mapFAI.get(this.paramFreightCharge.blFCPerCode) == null) {
                    let blfList: String[] = [];
                    var key: String =
                      this.paramFreightCharge.blFCPerCode +
                      ";;" +
                      this.paramFreightCharge.blFCPayer +
                      ";;" +
                      this.paramFreightCharge.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightCharge.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightCharge.blFCPc +
                      ";;" +
                      this.paramFreightCharge.blFCChargeCode;
                    blfList.push(key);
                    mapFAI.set(this.paramFreightCharge.blFCPerCode, blfList);
                  } else {
                    var key: String =
                      this.paramFreightCharge.blFCPerCode +
                      ";;" +
                      this.paramFreightCharge.blFCPayer +
                      ";;" +
                      this.paramFreightCharge.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightCharge.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightCharge.blFCPc +
                      ";;" +
                      this.paramFreightCharge.blFCChargeCode;
                    mapFAI.get(this.paramFreightCharge.blFCPerCode).push(key);
                  }
                }
              });
          } else {
            if (mapFAI.get(this.paramFreightCharge.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                this.paramFreightCharge.blFCPerCode +
                ";;" +
                this.paramFreightCharge.blFCPayer +
                ";;" +
                this.paramFreightCharge.blFCCurrencyCode +
                ";;" +
                this.paramFreightCharge.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightCharge.blFCPc +
                ";;" +
                this.paramFreightCharge.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(this.paramFreightCharge.blFCPerCode, blfList);
            } else {
              var key: String =
                this.paramFreightCharge.blFCPerCode +
                ";;" +
                this.paramFreightCharge.blFCPayer +
                ";;" +
                this.paramFreightCharge.blFCCurrencyCode +
                ";;" +
                this.paramFreightCharge.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightCharge.blFCPc +
                ";;" +
                this.paramFreightCharge.blFCChargeCode;
              mapFAI.get(this.paramFreightCharge.blFCPerCode).push(key);
            }
          }
        } else {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCFaiGroup"] =
            "FAI is not applicable under FAI Group.";
        }
      }

      /*
       // Fai Group Checking segment
       const listOfFaiGroupCharges: Array<String> = [];
       const distinctOfListOfFaiGroupCharges: Array<String> = [];
       if (this.paramFreightCharge.blFCFaiGroup === true) {
       if (this.paramFreightCharge.blFCChargeCode !== 'FAI'){
       // if the current charge got fai group checked
       // must loop through the nett charge to check the existence of another freight group
       if (this.gridBlNettCharge.listStore.store.length > 0) {
       for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
       console.log('-- debug FAI Check --');
       console.log(this.gridBlNettCharge.listStore.store[i].blFCSeq + ' -VS- ' + this.paramFreightCharge.blFCSeq);
       if(this.gridBlNettCharge.listStore.store[i].blFCSeq !== this.paramFreightCharge.blFCSeq) {
       if (this.gridBlNettCharge.listStore.store[i].blFCFaiGroup === true) {
       listOfFaiGroupCharges.push(this.gridBlNettCharge.listStore.store[i].blFCPerCode + ';;' +
       this.gridBlNettCharge.listStore.store[i].blFCPayer + ';;' + this.gridBlNettCharge.listStore.store[i].blFCCurrencyCode +
       ';;' + this.gridBlNettCharge.listStore.store[i].blFCPlaceOfPaymentCode + ';;' + this.gridBlNettCharge.listStore.store[i].blFCPc);
       }
       }

       }
       for (const entry of listOfFaiGroupCharges) {
       console.log('fai entry debug => ' + entry);
       if (!distinctOfListOfFaiGroupCharges.includes(entry)) {
       distinctOfListOfFaiGroupCharges.push(entry);
       }
       }

       // the the number 1 record as comparer
       for (const entry of distinctOfListOfFaiGroupCharges) {
       console.log('debug fai group');
       console.log(entry);
       if (entry !== (this.paramFreightCharge.blFCPerCode + ';;' + this.paramFreightCharge.blFCPayer +
       ';;' + this.paramFreightCharge.blFCCurrencyCode + ';;' + this.paramFreightCharge.blFCPlaceOfPaymentCode + ';;' + this.paramFreightCharge.blFCPc)) {
       this.isErrorNettChargeEntry = true;

       // Generate discrepancy message
       if (this.paramFreightCharge.blFCPerCode !== entry.split(';;')[0]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Per Code.';
       }
       if (this.paramFreightCharge.blFCPayer !== entry.split(';;')[1]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Payer.';
       }
       if (this.paramFreightCharge.blFCCurrencyCode !== entry.split(';;')[2]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check Currency.';
       }
       if (this.paramFreightCharge.blFCPlaceOfPaymentCode !== entry.split(';;')[3]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check PlaceOfPayment.';
       }
       if (this.paramFreightCharge.blFCPc !== entry.split(';;')[4]){
       this.paramFreightCharge['error-blFCFaiGroup'] = 'Invalid FAI Group criteria. Please check P/C.';
       }

       }
       }

       }
       } else {
       this.isErrorNettChargeEntry = true;
       this.paramFreightCharge['error-blFCFaiGroup'] = 'FAI is not applicable under FAI Group.';
       }

       }
       */

      if (!this.isErrorNettChargeEntry) {
        // this.gridBlNettCharge.listStore.updateData((this.gridBlNettCharge.getSelectedValues()[0].blFCSeq - 1), this.paramFreightCharge);
        this.gridBlNettCharge.listStore.updateData(
          this.paramFreightCharge.blFCSeq - 1,
          this.paramFreightCharge
        );

        this.gridBlNettCharge.clearSelectedValues();
        this.gridBlNettCharge.loadData();

        this.onBlNettChargeEntryCancel();
      }
    }

    this.isRefreightButtonClicked = false;
  }

  onBlNettChargeEntryInsertConsole() {
    this.isErrorNettChargeEntryConsole = false;
    this.paramFreightChargeConsole["error-blFCChargeCode"] = "";
    this.paramFreightChargeConsole["error-blFCPayer"] = "";
    this.paramFreightChargeConsole["error-blFCPerCode"] = "";
    this.paramFreightChargeConsole["error-blFCCurrencyCode"] = "";
    this.paramFreightChargeConsole["error-blFCDuplicateRecord"] = "";
    this.paramFreightChargeConsole["error-blFCFaiGroup"] = "";
    this.paramFreightChargeConsole["error-blFCRevenue"] = "";
    this.paramFreightChargeConsole["error-blFCPc"] = "";
    this.paramFreightChargeConsole["error-blFCPlaceOfPaymentCode"] = "";
    this.paramFreightChargeConsole["error-blFCPayer"] = "";

    this.isBLFNewEntryConsole = false; // to avoid blf auto populated

    if (this.blNettChargeUpdateModeConsole === "insert") {
      if (
        this.paramFreightChargeConsole.blFCChargeCode == null ||
        this.paramFreightChargeConsole.blFCChargeCode === "" ||
        this.paramFreightChargeConsole.blFCChargeCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCChargeCode"] =
          "Please input Charge(s).";
      }

      if (
        this.paramFreightChargeConsole.blFCPc == null ||
        this.paramFreightChargeConsole.blFCPc === "" ||
        this.paramFreightChargeConsole.blFCPc === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCPc"] = "Please input P/C.";
      }

      // if (
      //     this.paramFreightChargeConsole.blFCPc === "P1" ||
      //     this.paramFreightChargeConsole.blFCPc === "P2" ||
      //     this.paramFreightChargeConsole.blFCPc === "P3"
      // ) {
      //   if(
      //     this.paramFreightChargeConsole.blFCPayer == null ||
      //     this.paramFreightChargeConsole.blFCPayer === "" ||
      //     this.paramFreightChargeConsole.blFCPayer === undefined
      //   ){
      //     this.isErrorNettChargeEntryConsole = true;
      //     this.paramFreightChargeConsole["error-blFCPayer"] = "Please input Payer.";
      //   }
      // }
      if (
        this.paramFreightChargeConsole.blFCPerCode == null ||
        this.paramFreightChargeConsole.blFCPerCode === "" ||
        this.paramFreightChargeConsole.blFCPerCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCPerCode"] =
          "Please input Per Code.";
      }

      if (
        this.paramFreightChargeConsole.blFCCurrencyCode == null ||
        this.paramFreightChargeConsole.blFCCurrencyCode === "" ||
        this.paramFreightChargeConsole.blFCCurrencyCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCCurrencyCode"] =
          "Please input Currency.";
      }

      if (
        this.paramFreightChargeConsole.blFCPlaceOfPaymentCode == null ||
        this.paramFreightChargeConsole.blFCPlaceOfPaymentCode === "" ||
        this.paramFreightChargeConsole.blFCPlaceOfPaymentCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCPlaceOfPaymentCode"] =
          "Please input Place of Payment.";
      }

      if (this.model.blType != "OIS" && this.model.blType != "SW") {
        if (
          this.paramFreightChargeConsole.blFCPc != "C" &&
          (this.paramFreightChargeConsole.blFCPayer == null ||
            this.paramFreightChargeConsole.blFCPayer === "" ||
            this.paramFreightChargeConsole.blFCPayer === undefined)
        ) {
          this.isErrorNettChargeEntryConsole = true;
          this.paramFreightChargeConsole["error-blFCPayer"] =
            "Please input Payer.";
        }
      }

      if (this.paramFreightChargeConsole.blFCRevenue <= 0) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCRevenue"] =
          "Please input Quantity.";
      }

      // Fai Group Checking segment
      let mapFAI = new Map<String, String[]>();

      if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
        this.gridBlNettChargeConsole.listStore.store.forEach((element) => {
          console.log(element);
          if (element.blFCFaiGroupString == "Y") {
            if (mapFAI.get(element.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(element.blFCPerCode, blfList);
            } else {
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              mapFAI.get(element.blFCPerCode).push(key);
            }
          }
        });
      }

      if (this.paramFreightChargeConsole.blFCFaiGroup === true) {
        if (this.paramFreightChargeConsole.blFCChargeCode !== "FAI") {
          if (mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) != null) {
            mapFAI
              .get(this.paramFreightChargeConsole.blFCPerCode)
              .forEach((element) => {
                var key: String =
                  this.paramFreightChargeConsole.blFCPerCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPayer +
                  ";;" +
                  this.paramFreightChargeConsole.blFCCurrencyCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPc;
                var el: String =
                  element.split(";;")[0] +
                  ";;" +
                  element.split(";;")[1] +
                  ";;" +
                  element.split(";;")[2] +
                  ";;" +
                  element.split(";;")[3] +
                  ";;" +
                  element.split(";;")[4];

                if (el !== key) {
                  this.isErrorNettChargeEntryConsole = true;

                  // Generate discrepancy message
                  if (
                    this.paramFreightChargeConsole.blFCPerCode !==
                    element.split(";;")[0]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Per Code.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCPayer !==
                    element.split(";;")[1]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Payer.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCCurrencyCode !==
                    element.split(";;")[2]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Currency.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCPlaceOfPaymentCode !==
                    element.split(";;")[3]
                  ) {
                    this.paramFreightCharge["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check PlaceOfPayment.";
                  }
                  if (
                    this.paramFreightCharge.blFCPc !== element.split(";;")[4]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check P/C.";
                  }
                } else {
                  if (
                    mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) ==
                    null
                  ) {
                    let blfList: String[] = [];
                    var key: String =
                      this.paramFreightChargeConsole.blFCPerCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPayer +
                      ";;" +
                      this.paramFreightChargeConsole.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPc +
                      ";;" +
                      this.paramFreightChargeConsole.blFCChargeCode;
                    blfList.push(key);
                    mapFAI.set(
                      this.paramFreightChargeConsole.blFCPerCode,
                      blfList
                    );
                  } else {
                    var key: String =
                      this.paramFreightChargeConsole.blFCPerCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPayer +
                      ";;" +
                      this.paramFreightChargeConsole.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPc +
                      ";;" +
                      this.paramFreightChargeConsole.blFCChargeCode;
                    mapFAI
                      .get(this.paramFreightChargeConsole.blFCPerCode)
                      .push(key);
                  }
                }
              });
          } else {
            if (
              mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) == null
            ) {
              let blfList: String[] = [];
              var key: String =
                this.paramFreightChargeConsole.blFCPerCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPayer +
                ";;" +
                this.paramFreightChargeConsole.blFCCurrencyCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPc +
                ";;" +
                this.paramFreightChargeConsole.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(this.paramFreightChargeConsole.blFCPerCode, blfList);
            } else {
              var key: String =
                this.paramFreightChargeConsole.blFCPerCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPayer +
                ";;" +
                this.paramFreightChargeConsole.blFCCurrencyCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPc +
                ";;" +
                this.paramFreightChargeConsole.blFCChargeCode;
              mapFAI.get(this.paramFreightChargeConsole.blFCPerCode).push(key);
            }
          }
        } else {
          this.isErrorNettChargeEntryConsole = true;
          this.paramFreightChargeConsole["error-blFCFaiGroup"] =
            "FAI is not applicable under FAI Group.";
        }
      }
      // check duplicate
      for (
        let i = 0;
        i < this.gridBlNettChargeConsole.listStore.store.length;
        i++
      ) {
        let newRecordString =
          this.paramFreightChargeConsole.blFCChargeCode.trim();
        newRecordString += this.paramFreightChargeConsole.blFCPerCode.trim();
        newRecordString +=
          this.paramFreightChargeConsole.blFCCurrencyCode.trim();

        let existingRecordString =
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode;
        existingRecordString +=
          this.gridBlNettChargeConsole.listStore.store[i].blFCPerCode;
        existingRecordString +=
          this.gridBlNettChargeConsole.listStore.store[i].blFCCurrencyCode;

        if (
          newRecordString.toUpperCase().trim() ===
          existingRecordString.toUpperCase().trim()
        ) {
          this.isErrorNettChargeEntryConsole = true;
          this.paramFreightChargeConsole["error-blFCDuplicateRecord"] =
            "Duplicate record detected, please check your input.";
        }
      }

      if (
        this.paramFreightChargeConsole.blFCChargeCode === "OFT" ||
        this.paramFreightChargeConsole.blFCChargeCode === "FOF" ||
        this.paramFreightChargeConsole.blFCChargeCode === "FAI"
      ) {
        let newRecordString2 =
          this.paramFreightChargeConsole.blFCPerCode.trim();
        newRecordString2 +=
          this.paramFreightChargeConsole.blFCCurrencyCode.trim();
        for (
          let i = 0;
          i < this.gridBlNettChargeConsole.listStore.store.length;
          i++
        ) {
          if (
            this.gridBlNettChargeConsole.listStore.store[i].blFCSeq !==
            this.paramFreightChargeConsole.blFCSeq
          ) {
            if (
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "OFT" ||
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "FAI" ||
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "FOF"
            ) {
              let existingRecordString2 =
                this.gridBlNettChargeConsole.listStore.store[i].blFCPerCode;
              existingRecordString2 +=
                this.gridBlNettChargeConsole.listStore.store[i]
                  .blFCCurrencyCode;

              if (this.model.blIsCombine !== "Y") {
                if (
                  newRecordString2.toUpperCase().trim() ===
                  existingRecordString2.toUpperCase().trim()
                ) {
                  this.isErrorNettChargeEntryConsole = true;
                  this.paramFreightChargeConsole["error-blFCDuplicateRecord"] =
                    "Duplicate main charge detected, please check your input.";
                }
              }
            }
          }
        }
      }

      if (!this.isErrorNettChargeEntryConsole) {
        this.gridBlNettChargeConsole.listStore.store.push(
          this.paramFreightChargeConsole
        );
        this.gridBlNettChargeConsole.loadData();

        // this.modeFormBlNettCharge = 'view';
        this.onBlNettChargeEntryCancelConsole();
      }
    } else {
      this.txtBlContainerInsertUpdateConsole = "Update";

      if (
        this.paramFreightChargeConsole.blFCChargeCode == null ||
        this.paramFreightChargeConsole.blFCChargeCode === "" ||
        this.paramFreightChargeConsole.blFCChargeCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCChargeCode"] =
          "Please input Charge(s).";
      }

      if (
        this.paramFreightChargeConsole.blFCPerCode == null ||
        this.paramFreightChargeConsole.blFCPerCode === "" ||
        this.paramFreightChargeConsole.blFCPerCode === undefined
      ) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCPerCode"] =
          "Please input Charge Per.";
      }
      if (
        this.paramFreightCharge.blFCCurrencyCode == null ||
        this.paramFreightCharge.blFCCurrencyCode === "" ||
        this.paramFreightCharge.blFCCurrencyCode === undefined
      ) {
        this.isErrorNettChargeEntry = true;
        this.paramFreightCharge["error-blFCCurrencyCode"] =
          "Please input Charge Currency.";
      }

      if (this.paramFreightChargeConsole.blFCRevenue <= 0) {
        this.isErrorNettChargeEntryConsole = true;
        this.paramFreightChargeConsole["error-blFCRevenue"] =
          "Please input Quantity.";
      }

      // check duplicate main charge
      if (
        this.paramFreightChargeConsole.blFCChargeCode === "OFT" ||
        this.paramFreightChargeConsole.blFCChargeCode === "FOF" ||
        this.paramFreightChargeConsole.blFCChargeCode === "FAI"
      ) {
        let newRecordString2 =
          this.paramFreightChargeConsole.blFCPerCode.trim();
        newRecordString2 +=
          this.paramFreightChargeConsole.blFCCurrencyCode.trim();
        for (
          let i = 0;
          i < this.gridBlNettChargeConsole.listStore.store.length;
          i++
        ) {
          console.log(
            "debug seq no --> " +
              this.gridBlNettChargeConsole.listStore.store[i].blFCSeq +
              " vs " +
              this.paramFreightChargeConsole.blFCSeq
          );
          if (
            this.gridBlNettChargeConsole.listStore.store[i].blFCSeq !==
            this.paramFreightChargeConsole.blFCSeq
          ) {
            if (
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "OFT" ||
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "FAI" ||
              this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
                "FOF"
            ) {
              let existingRecordString2 =
                this.gridBlNettChargeConsole.listStore.store[i].blFCPerCode;
              existingRecordString2 +=
                this.gridBlNettChargeConsole.listStore.store[i]
                  .blFCCurrencyCode;

              if (this.model.blIsCombine !== "Y") {
                if (
                  newRecordString2.toUpperCase().trim() ===
                  existingRecordString2.toUpperCase().trim()
                ) {
                  this.isErrorNettChargeEntryConsole = true;
                  this.paramFreightChargeConsole["error-blFCDuplicateRecord"] =
                    "Duplicate main charge detected, please check your input.";
                }
              }
            }
          }
        }
      }

      // Fai Group Checking segment
      let mapFAI = new Map<String, String[]>();

      if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
        this.gridBlNettChargeConsole.listStore.store.forEach((element) => {
          console.log(element);
          if (element.blFCFaiGroupString == "Y") {
            if (mapFAI.get(element.blFCPerCode) == null) {
              let blfList: String[] = [];
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(element.blFCPerCode, blfList);
            } else {
              var key: String =
                element.blFCPerCode +
                ";;" +
                element.blFCPayer +
                ";;" +
                element.blFCCurrencyCode +
                ";;" +
                element.blFCPlaceOfPaymentCode +
                ";;" +
                element.blFCPc +
                ";;" +
                element.blFCChargeCode;
              mapFAI.get(element.blFCPerCode).push(key);
            }
          }
        });
      }

      if (this.paramFreightChargeConsole.blFCFaiGroup === true) {
        if (this.paramFreightChargeConsole.blFCChargeCode !== "FAI") {
          if (mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) != null) {
            mapFAI
              .get(this.paramFreightChargeConsole.blFCPerCode)
              .forEach((element) => {
                var key: String =
                  this.paramFreightChargeConsole.blFCPerCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPayer +
                  ";;" +
                  this.paramFreightChargeConsole.blFCCurrencyCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                  ";;" +
                  this.paramFreightChargeConsole.blFCPc;
                var el: String =
                  element.split(";;")[0] +
                  ";;" +
                  element.split(";;")[1] +
                  ";;" +
                  element.split(";;")[2] +
                  ";;" +
                  element.split(";;")[3] +
                  ";;" +
                  element.split(";;")[4];

                if (el !== key) {
                  this.isErrorNettChargeEntryConsole = true;

                  // Generate discrepancy message
                  if (
                    this.paramFreightChargeConsole.blFCPerCode !==
                    element.split(";;")[0]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Per Code.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCPayer !==
                    element.split(";;")[1]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Payer.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCCurrencyCode !==
                    element.split(";;")[2]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check Currency.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCPlaceOfPaymentCode !==
                    element.split(";;")[3]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check PlaceOfPayment.";
                  }
                  if (
                    this.paramFreightChargeConsole.blFCPc !==
                    element.split(";;")[4]
                  ) {
                    this.paramFreightChargeConsole["error-blFCFaiGroup"] =
                      "Invalid FAI Group criteria. Please check P/C.";
                  }
                } else {
                  if (
                    mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) ==
                    null
                  ) {
                    let blfList: String[] = [];
                    var key: String =
                      this.paramFreightChargeConsole.blFCPerCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPayer +
                      ";;" +
                      this.paramFreightChargeConsole.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPc +
                      ";;" +
                      this.paramFreightChargeConsole.blFCChargeCode;
                    blfList.push(key);
                    mapFAI.set(
                      this.paramFreightChargeConsole.blFCPerCode,
                      blfList
                    );
                  } else {
                    var key: String =
                      this.paramFreightChargeConsole.blFCPerCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPayer +
                      ";;" +
                      this.paramFreightChargeConsole.blFCCurrencyCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                      ";;" +
                      this.paramFreightChargeConsole.blFCPc +
                      ";;" +
                      this.paramFreightChargeConsole.blFCChargeCode;
                    mapFAI
                      .get(this.paramFreightChargeConsole.blFCPerCode)
                      .push(key);
                  }
                }
              });
          } else {
            if (
              mapFAI.get(this.paramFreightChargeConsole.blFCPerCode) == null
            ) {
              let blfList: String[] = [];
              var key: String =
                this.paramFreightChargeConsole.blFCPerCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPayer +
                ";;" +
                this.paramFreightChargeConsole.blFCCurrencyCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPc +
                ";;" +
                this.paramFreightChargeConsole.blFCChargeCode;
              blfList.push(key);
              mapFAI.set(this.paramFreightChargeConsole.blFCPerCode, blfList);
            } else {
              var key: String =
                this.paramFreightChargeConsole.blFCPerCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPayer +
                ";;" +
                this.paramFreightChargeConsole.blFCCurrencyCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPlaceOfPaymentCode +
                ";;" +
                this.paramFreightChargeConsole.blFCPc +
                ";;" +
                this.paramFreightChargeConsole.blFCChargeCode;
              mapFAI.get(this.paramFreightChargeConsole.blFCPerCode).push(key);
            }
          }
        } else {
          this.isErrorNettChargeEntryConsole = true;
          this.paramFreightChargeConsole["error-blFCFaiGroup"] =
            "FAI is not applicable under FAI Group.";
        }
      }

      if (!this.isErrorNettChargeEntryConsole) {
        this.gridBlNettChargeConsole.listStore.updateData(
          this.paramFreightChargeConsole.blFCSeq - 1,
          this.paramFreightChargeConsole
        );

        this.gridBlNettChargeConsole.clearSelectedValues();
        this.gridBlNettChargeConsole.loadData();

        this.onBlNettChargeEntryCancelConsole();
      }
    }

    this.isRefreightButtonClickedConsole = false;
  }

  onBlNettChargeEntryReset() {
    this.paramFreightCharge = new BLFreightCharge();
    this.cbBlNettChargeInputCharge.setValue("");
    this.cbBlNettChargeInputCurrency.setValue("");
    this.cbBlNettChargeInputPerCode.setValue("");
    this.cbBlNettChargeInputPC.listStore.store = [];
    this.cbBlNettChargeInputPC.loadSelect();
    this.cbBlNettChargeInputPayer.listStore.store = [];
    this.cbBlNettChargeInputPayer.loadSelect();

    // release the lock
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;
  }

  onBlNettChargeEntryResetConsole() {
    this.paramFreightChargeConsole = new BLFreightCharge();
    this.cbBlNettChargeInputChargeConsole.setValue("");
    this.cbBlNettChargeInputCurrencyConsole.setValue("");
    this.cbBlNettChargeInputPerCodeConsole.setValue("");
    this.cbBlNettChargeInputPCConsole.setValue("");
    this.cbBlNettChargeInputPayerConsole.setValue("");
    this.cbBlNettChargeInputPlaceOfPaymentConsole.setValue("");
    //this.cbBlNettChargeInputPC.listStore.store = [];
    //this.cbBlNettChargeInputPC.loadSelect();
    //this.cbBlNettChargeInputPayer.listStore.store = [];
    //this.cbBlNettChargeInputPayer.loadSelect();

    // release the lock
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;
  }

  changeEventChBlNettChargeIsFreightAsArranged(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsFreightAsArranged = true;
    } else {
      this.model.blIsFreightAsArranged = false;
    }
  }

  changeEventChBlNettChargeIsFreightAsArrangedConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsFreightAsArrangedConsole = true;
    } else {
      this.model.blIsFreightAsArrangedConsole = false;
    }
  }

  changeEventChBlNettChargePrintLoadingVesselVoyageClause(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsPrintLoadingVesselVoyageClause = true;
    } else {
      this.model.blIsPrintLoadingVesselVoyageClause = false;
    }
  }

  changeEventChBlNettChargePrintLoadingVesselVoyageClauseConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsPrintLoadingVesselVoyageClauseConsole = true;
    } else {
      this.model.blIsPrintLoadingVesselVoyageClauseConsole = false;
    }
  }



  changeEventChBlNettChargePrintShippedOnBoardConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsPrintShippedOnBoardConsole = true;
    } else {
      this.model.blIsPrintShippedOnBoardConsole = false;
    }
  }

  changeEventBlNettChargeIsExchangeRateLock(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsExchangeRateLock = true;
    } else {
      this.model.blIsExchangeRateLock = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventBlNettChargeIsShippeOnBoardLock(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blExtraShippedOnBoardLock = true;
    } else {
      this.model.blExtraShippedOnBoardLock = false;
      this.setBlIsShippedOnBoardDate();
    }
  }



  changeEventBlNettChargeIsExchangeRateLockConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsExchangeRateLockConsole = true;
    } else {
      this.model.blIsExchangeRateLockConsole = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventBlNettChargeIsDateOfIssueLock(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blDateOfIssueLock = true;
    } else {
      this.model.blDateOfIssueLock = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventBlNettChargeIsDateOfIssueLockConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blDateOfIssueLockConsole = true;
    } else {
      this.model.blDateOfIssueLockConsole = false;
      this.setBlIssueDateExchangeRateDate();
    }
  }

  changeEventBlNettChargeFreightTypeNett(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blFreightType = "NETT";
    } else {
      this.model.blFreightType = "";
    }
  }

  changeEventBlNettChargeFreightTypeNettConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blFreightTypeConsole = "NETT";
    } else {
      this.model.blFreightTypeConsole = "";
    }
  }

  changeEventBlNettChargeFreightTypeGross(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blFreightType = "GROSS";
    } else {
      this.model.blFreightType = "";
    }
  }

  changeEventBlNettChargeFreightTypeGrossConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blFreightTypeConsole = "GROSS";
    } else {
      this.model.blFreightTypeConsole = "";
    }
  }

  changeEventBlNettChargeExchangeRateTypeNormal(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blExchangeRateType = "NORMAL";
    } else {
      this.model.blExchangeRateType = "";
    }
  }

  changeEventBlNettChargeExchangeRateTypeNormalConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blExchangeRateTypeConsole = "NORMAL";
    } else {
      this.model.blExchangeRateTypeConsole = "";
    }
  }

  changeEventBlNettChargeExchangeRateTypeSpecial(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blExchangeRateType = "SPECIAL";
    } else {
      this.model.blExchangeRateType = "";
    }
  }

  changeEventBlNettChargeExchangeRateTypeBlRate(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blExchangeRateType = "BLRATE";
      this.genericUtil.showDialog(
        "blNettChargeSpecialExchangeRateDialog",
        "BL Exchange Rate Entry",
        650,
        500
      );
    } else {
      this.model.blExchangeRateType = "";
    }
  }

  changeEventBlNettChargeExchangeRateTypeBlRateConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
    if (event.target.checked) {
      this.model.blExchangeRateTypeConsole = "BLRATE";
      this.genericUtil.showDialog(
        "blNettChargeSpecialExchangeRateDialog",
        "BL Exchange Rate Entry",
        650,
        500
      );
    } else {
      this.model.blExchangeRateTypeConsole = "";
    }
  }

  changeEventCbBlNettChargePrepaid1(event) {
    console.log("Masuk prepaid 1");
    // console.log(event);
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPrepaidAt1Code = event.locationCode;
      this.model.blPrepaidAt1Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "P1") {
            dt.blFCPlaceOfPaymentCode = this.model.blPrepaidAt1Code;
            dt.blFCPlaceOfPaymentName = this.model.blPrepaidAt1Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPrepaidAt1Code = "";
      this.model.blPrepaidAt1Name = "";
    }

    this.cbBlNettChargePrepaid1.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargePrepaid2(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPrepaidAt2Code = event.locationCode;
      this.model.blPrepaidAt2Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "P2") {
            dt.blFCPlaceOfPaymentCode = this.model.blPrepaidAt2Code;
            dt.blFCPlaceOfPaymentName = this.model.blPrepaidAt2Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPrepaidAt2Code = "";
      this.model.blPrepaidAt2Name = "";
    }

    this.cbBlNettChargePrepaid2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargePrepaid3(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPrepaidAt3Code = event.locationCode;
      this.model.blPrepaidAt3Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "P3") {
            dt.blFCPlaceOfPaymentCode = this.model.blPrepaidAt3Code;
            dt.blFCPlaceOfPaymentName = this.model.blPrepaidAt3Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPrepaidAt3Code = "";
      this.model.blPrepaidAt3Name = "";
    }

    this.cbBlNettChargePrepaid3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargePayable1(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPayableAt1Code = event.locationCode;
      this.model.blPayableAt1Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "C1") {
            dt.blFCPlaceOfPaymentCode = this.model.blPayableAt1Code;
            dt.blFCPlaceOfPaymentName = this.model.blPayableAt1Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPayableAt1Code = "";
      this.model.blPayableAt1Name = "";
    }

    this.cbBlNettChargePayableAt1.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargePayable2(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPayableAt2Code = event.locationCode;
      this.model.blPayableAt2Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "C2") {
            dt.blFCPlaceOfPaymentCode = this.model.blPayableAt2Code;
            dt.blFCPlaceOfPaymentName = this.model.blPayableAt2Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPayableAt2Code = "";
      this.model.blPayableAt2Name = "";
    }

    this.cbBlNettChargePayableAt2.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargePayable3(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.model.blPayableAt3Code = event.locationCode;
      this.model.blPayableAt3Name = event.locationName;

      // must update the place of payment of charge in grid
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const dt of this.gridBlNettCharge.listStore.store) {
          if (dt.blFCPc === "C3") {
            dt.blFCPlaceOfPaymentCode = this.model.blPayableAt3Code;
            dt.blFCPlaceOfPaymentName = this.model.blPayableAt3Name;
          }
          this.gridBlNettCharge.listStore.updateData(dt.blFCSeq - 1, dt);
        }
        this.gridBlNettCharge.loadData();
      }
    } else {
      this.model.blPayableAt3Code = "";
      this.model.blPayableAt3Name = "";
    }

    this.cbBlNettChargePayableAt3.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}"
    );
  }

  changeEventCbBlNettChargeBillingParty1(event) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.IsLockPayer1=false;
      this.model.blPayer1Code = event.customerId;
      this.model.blPayer1Name = event.customerName;
      this.cbBlNettChargeBillingParty1Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );

      if(!this.isRefreightProcess){
        console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
        const self = this;
        this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty1Address.setValue(
              self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
            );

          self.model.blExtraPayer1nameAddressOption =
          self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer1nameAddressName =
          self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer1nameAddressOption = 0;
            self.model.blExtraPayer1nameAddressName ="";
          }
        });
      }else{
        if(this.model.blExtraPayer1nameAddressName == "" || this.model.blExtraPayer1nameAddressName== undefined){
          console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
          const self = this;
          this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
            if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty1Address.setValue(
              self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
            );
          self.model.blExtraPayer1nameAddressOption =
          self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer1nameAddressName =
          self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer1nameAddressOption = 0;
            self.model.blExtraPayer1nameAddressName ="";
          }
          });
        }

      }


    } else {
      this.IsLockPayer1=true;
      this.model.blPayer1Code = "";
      this.model.blPayer1Name = "";
      this.model.blExtraPayer1nameAddressOption =0;
      this.model.blExtraPayer1nameAddressName ="";
      this.cbBlNettChargeBillingParty1Address.setUrl("");
      this.cbBlNettChargeBillingParty1Address.clearSelect();
      this.cbBlNettChargeBillingParty1Address.listStore.store = [];
    }
    this.cbBlNettChargeBillingParty1.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerName={query}"
    );
  }

  changeEventCbBlNettChargeBillingParty2(event) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.IsLockPayer2=false;
      this.model.blPayer2Code = event.customerId;
      this.model.blPayer2Name = event.customerName;
      this.cbBlNettChargeBillingParty2Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );
      if(!this.isRefreightProcess){

        const self = this;
        this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty2Address.setValue(
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
            );

          self.model.blExtraPayer2nameAddressOption =
          self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer2nameAddressName =
          self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer2nameAddressOption = 0;
            self.model.blExtraPayer2nameAddressName = "";
          }
        });
      }else{

        if(this.model.blExtraPayer2nameAddressName == "" || this.model.blExtraPayer2nameAddressName == undefined ){
          console.log("HERE2 = "+this.model.blExtraPayer2nameAddressName);
          const self = this;
          this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty2Address.setValue(
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
            );
          self.model.blExtraPayer2nameAddressOption =
          self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer2nameAddressName =
          self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer2nameAddressOption = 0;
            self.model.blExtraPayer2nameAddressName = "";
          }
          });
        }
      }
    } else {
      this.IsLockPayer2=true;
      this.model.blPayer2Code = "";
      this.model.blPayer2Name = "";
      this.cbBlNettChargeBillingParty2Address.setUrl("");
      this.cbBlNettChargeBillingParty2Address.clearSelect();
      this.cbBlNettChargeBillingParty2Address.listStore.store = [];
      this.model.blExtraPayer2nameAddressOption =0;
    }
    this.cbBlNettChargeBillingParty2.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerName={query}"
    );
  }

  changeEventCbBlNettChargeBillingParty3(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.IsLockPayer3=false;
      this.model.blPayer3Code = event.customerId;
      this.model.blPayer3Name = event.customerName;
      this.cbBlNettChargeBillingParty3Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          event.customerId.trim()+
          "/Y"
      );
      if(!this.isRefreightProcess){
        const self = this;
        this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty3Address.setValue(
              self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
            );

          self.model.blExtraPayer3nameAddressOption =
          self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer3nameAddressName =
          self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer3nameAddressOption = 0;
            self.model.blExtraPayer3nameAddressName ="";
          }
        });
      }else{
        if(this.model.blExtraPayer3nameAddressName == "" || this.model.blExtraPayer3nameAddressName == undefined){
          const self = this;
          this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
            if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
              self.cbBlNettChargeBillingParty3Address.setValue(
                self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
              );
            self.model.blExtraPayer3nameAddressOption =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer3nameAddressName =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
            } else {
              self.model.blExtraPayer3nameAddressOption = 0;
              self.model.blExtraPayer3nameAddressName ="";
            }
          });
        }
      }
    } else {
      this.IsLockPayer3=true;
      this.model.blPayer3Code = "";
      this.model.blPayer3Name = "";
      this.cbBlNettChargeBillingParty3Address.setUrl("");
      this.cbBlNettChargeBillingParty3Address.clearSelect();
      this.cbBlNettChargeBillingParty3Address.listStore.store = [];
      this.model.blExtraPayer3nameAddressOption =0;
    }
    this.cbBlNettChargeBillingParty3.setUrl(
      this.configService.config.BASE_API.toString() +
        // "/MasterCustomers/findByComboBoxControlPayer/customerName={query}"
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerName={query}"
    );
  }

  changeEventCbBlNettChargeInputCharge(event) {
    if (
      event.chargeId != null &&
      event.chargeId !== "" &&
      event.chargeId !== undefined
    ) {
      this.paramFreightCharge.blFCChargeCode = event.chargeCode;

      console.log("Code --> " + event.chargeCode);

      if (event.chargeCode === "BLF") {
        if (this.isBLFNewEntry) {
          console.log("Place of Issue --> " + this.model.blPlaceOfIssueCode);
          if (this.model.blPlaceOfIssueCode !== "") {
            console.log(
              "Place of Issue Substring --> " +
                this.model.blPlaceOfIssueCode.substr(1, 2)
            );
            if (
              this.cookieService.getDefaultLocationCode().substr(0, 2) === "TH"
            ) {
              this.paramFreightCharge.blFCRevenue = 1;
              this.paramFreightCharge.blFCRate = 1300;
              this.paramFreightCharge.blFCPerCode = "BL";
              this.cbBlNettChargeInputPerCode.setValue("BL");
              this.paramFreightCharge.blFCCurrencyCode = "THB";
              this.cbBlNettChargeInputCurrency.setValue("THB");
              this.paramFreightCharge.blFCAmount = 1300;
              this.paramFreightCharge.blFCPc = "P1";
              this.cbBlNettChargeInputPC.setValue("P1");
              this.paramFreightCharge.blFCPayer = "1";
              this.cbBlNettChargeInputPayer.setValue("1");
              // this.paramFreightCharge.blFCPlaceOfPaymentName = this.cbBlNettChargePrepaid1.listStore.store[0].locationName;
              this.paramFreightCharge.blFCPlaceOfPaymentCode =
                this.model.blPrepaidAt1Code;
              this.paramFreightCharge.blFCPlaceOfPaymentName =
                this.model.blPrepaidAt1Name;
            }
          }
        }
      }
    } else {
      this.paramFreightCharge.blFCChargeCode = "";
    }
  }

  changeEventCbBlNettChargeInputChargeConsole(event) {
    if (
      event.chargeId != null &&
      event.chargeId !== "" &&
      event.chargeId !== undefined
    ) {
      this.paramFreightChargeConsole.blFCChargeCode = event.chargeCode;

      console.log("Code --> " + this.paramFreightChargeConsole.blFCChargeCode);

      if (event.chargeCode === "BLF") {
        if (this.isBLFNewEntryConsole) {
          console.log(
            "Place of Issue --> " + this.model.blPlaceOfIssueCodeConsole
          );
          if (this.model.blPlaceOfIssueCodeConsole !== "") {
            console.log(
              "Place of Issue Substring --> " +
                this.model.blPlaceOfIssueCodeConsole.substr(1, 2)
            );
            if (
              this.cookieService.getDefaultLocationCode().substr(0, 2) === "TH"
            ) {
              /*
              this.paramFreightChargeConsole.blFCRevenue = 1;
              this.paramFreightChargeConsole.blFCRate = 1300;
              this.paramFreightChargeConsole.blFCPerCode = 'BL';
              this.paramFreightChargeConsole.blFCCurrencyCode = 'THB';
              this.paramFreightChargeConsole.blFCAmount = 1300;
              this.paramFreightChargeConsole.blFCPc = 'P1';
              this.paramFreightChargeConsole.blFCPayer = '1';
              this.paramFreightChargeConsole.blFCPlaceOfPaymentCode = this.model.blPrepaidAt1Code;
              this.paramFreightChargeConsole.blFCPlaceOfPaymentName = this.model.blPrepaidAt1Name;

              this.cbBlNettChargeInputPerCodeConsole.setValue('BL');
              this.cbBlNettChargeInputCurrencyConsole.setValue('THB');
              this.cbBlNettChargeInputPayerConsole.setValue('1');
              this.cbBlNettChargeInputPCConsole.setValue('P1');
              */
            }
          }
        }
      }
    } else {
      this.paramFreightChargeConsole.blFCChargeCode = "";
    }
  }

  changeEventCbBlNettChargeInputPC(event) {
    if (
      event.blPCCode != null &&
      event.blPCCode !== "" &&
      event.blPCCode !== undefined
    ) {
      this.paramFreightCharge.blFCPc = event.blPCCode;

      if (event.blPCCode === "P1") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPrepaidAt1Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPrepaidAt1Name;
      }
      if (event.blPCCode === "P2") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPrepaidAt2Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPrepaidAt2Name;
      }
      if (event.blPCCode === "P3") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPrepaidAt3Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPrepaidAt3Name;
      }

      if (event.blPCCode === "C1") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPayableAt1Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPayableAt1Name;
        this.cbBlNettChargeInputPayer.setValue("");
        this.paramFreightCharge.blFCPayer = "";
      }
      if (event.blPCCode === "C2") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPayableAt2Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPayableAt2Name;
      }
      if (event.blPCCode === "C3") {
        this.paramFreightCharge.blFCPlaceOfPaymentCode =
          this.model.blPayableAt3Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName =
          this.model.blPayableAt3Name;
      }
    } else {
      this.paramFreightCharge.blFCPc = "";
      this.paramFreightCharge.blFCPlaceOfPaymentCode = "";
      this.paramFreightCharge.blFCPlaceOfPaymentName = "";
    }
  }

  changeEventCbBlNettChargeInputPCConsole(event) {
    if (
      event.blPCCode != null &&
      event.blPCCode !== "" &&
      event.blPCCode !== undefined
    ) {
      this.paramFreightChargeConsole.blFCPc = event.blPCCode;
    } else {
      this.paramFreightChargeConsole.blFCPc = "";
    }
    /*
    if (event.blPCCode != null && event.blPCCode !== '' && event.blPCCode !== undefined) {
      this.paramFreightChargeConsole.blFCPc = event.blPCCode;

      if (event.blPCCode === 'P1') {
        this.paramFreightChargeConsole.blFCPlaceOfPaymentCode = this.model.blPrepaidAt1Code;
        this.paramFreightChargeConsole.blFCPlaceOfPaymentName = this.model.blPrepaidAt1Name;
      }
      if (event.blPCCode === 'P2') {
        this.paramFreightCharge.blFCPlaceOfPaymentCode = this.model.blPrepaidAt2Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName = this.model.blPrepaidAt2Name;
      }
      if (event.blPCCode === 'P3') {
        this.paramFreightCharge.blFCPlaceOfPaymentCode = this.model.blPrepaidAt3Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName = this.model.blPrepaidAt3Name;
      }

      if (event.blPCCode === 'C1') {
        this.paramFreightCharge.blFCPlaceOfPaymentCode = this.model.blPayableAt1Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName = this.model.blPayableAt1Name;
        this.cbBlNettChargeInputPayer.setValue('');
        this.paramFreightCharge.blFCPayer = '';
      }
      if (event.blPCCode === 'C2') {
        this.paramFreightCharge.blFCPlaceOfPaymentCode = this.model.blPayableAt2Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName = this.model.blPayableAt2Name;
      }
      if (event.blPCCode === 'C3') {
        this.paramFreightCharge.blFCPlaceOfPaymentCode = this.model.blPayableAt3Code;
        this.paramFreightCharge.blFCPlaceOfPaymentName = this.model.blPayableAt3Name;
      }


    } else {
      this.paramFreightCharge.blFCPc = '';
      this.paramFreightCharge.blFCPlaceOfPaymentCode = '';
      this.paramFreightCharge.blFCPlaceOfPaymentName = '';
    }
    */
  }

  changeEventCbBlNettChargeInputPerCode(event) {
    // console.log('Change event Per Code');
    // console.log(event);
    if (
      event.perId != null &&
      event.perId !== "" &&
      event.perId !== undefined
    ) {
      this.paramFreightCharge.blFCPerCode = event.perCode;
    } else {
      this.paramFreightCharge.blFCPerCode = "";
    }
    // this.cbBlNettChargeInputPerCode.setUrl(this.configService.config.BASE_API.toString() +
    // '/MasterPerCodes/findByComboBoxControl/perCodeLike={query}')
  }

  changeEventCbBlNettChargeInputPerCodeConsole(event) {
    // console.log('Change event Per Code');
    // console.log(event);
    if (
      event.perId != null &&
      event.perId !== "" &&
      event.perId !== undefined
    ) {
      this.paramFreightChargeConsole.blFCPerCode = event.perCode;
    } else {
      this.paramFreightChargeConsole.blFCPerCode = "";
    }
    // this.cbBlNettChargeInputPerCode.setUrl(this.configService.config.BASE_API.toString() +
    // '/MasterPerCodes/findByComboBoxControl/perCodeLike={query}')
  }

  changeEventCbBlNettChargeInputPayer(event) {
    if (
      event.blPCPayerCode != null &&
      event.blPCPayerCode !== "" &&
      event.blPCPayerCode !== undefined
    ) {
      this.paramFreightCharge.blFCPayer = event.blPCPayerCode;
    } else {
      this.paramFreightCharge.blFCPayer = "";
    }
  }

  changeEventCbBlNettChargeInputPayerConsole(event) {
    if (
      event.customerId != null &&
      event.customerId !== "" &&
      event.customerId !== undefined
    ) {
      this.paramFreightChargeConsole.blFCPayer = event.customerId;
      this.paramFreightChargeConsole.blFCPayerName = event.customerName;
    } else {
      this.paramFreightChargeConsole.blFCPayer = "";
      this.paramFreightChargeConsole.blFCPayerName = "";
    }
  }

  changeEventCbBlNettChargeInputPlaceOfPaymentConsole(event) {
    if (
      event.locationCode != null &&
      event.locationCode !== "" &&
      event.locationCode !== undefined
    ) {
      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode =
        event.locationCode;
      this.paramFreightChargeConsole.blFCPlaceOfPaymentName =
        event.locationName;
    } else {
      this.paramFreightChargeConsole.blFCPlaceOfPaymentCode = "";
      this.paramFreightChargeConsole.blFCPlaceOfPaymentName = "";
    }
  }

  changeEventCbBlNettChargeInputCurrency(event) {
    if (
      event.currencyId != null &&
      event.currencyId !== "" &&
      event.currencyId !== undefined
    ) {
      this.paramFreightCharge.blFCCurrencyCode = event.currencyCode;
    } else {
      this.paramFreightCharge.blFCCurrencyCode = "";
    }
  }

  changeEventCbBlNettChargeInputCurrencyConsole(event) {
    if (
      event.currencyId != null &&
      event.currencyId !== "" &&
      event.currencyId !== undefined
    ) {
      this.paramFreightChargeConsole.blFCCurrencyCode = event.currencyCode;
    } else {
      this.paramFreightChargeConsole.blFCCurrencyCode = "";
    }
  }

  changeEventChBlNettChargeIsChargeFaiGroup(event) {
    if (event.target.checked) {
      this.paramFreightCharge.blFCFaiGroup = true;
      this.paramFreightCharge.blFCFaiGroupString = "Y";
    } else {
      this.paramFreightCharge.blFCFaiGroup = false;
      this.paramFreightCharge.blFCFaiGroupString = "N";
    }
  }

  changeEventChBlNettChargeIsChargeFaiGroupConsole(event) {
    if (event.target.checked) {
      this.paramFreightChargeConsole.blFCFaiGroup = true;
      this.paramFreightChargeConsole.blFCFaiGroupString = "Y";
    } else {
      this.paramFreightChargeConsole.blFCFaiGroup = false;
      this.paramFreightChargeConsole.blFCFaiGroupString = "N";
    }
  }

  changeEventChBlNettChargeIsChargePrinted(event) {
    if (event.target.checked) {
      this.paramFreightCharge.blFCIsChargePrinted = true;
      this.paramFreightCharge.blFCIsChargePrintedString = "Y";
    } else {
      this.paramFreightCharge.blFCIsChargePrinted = false;
      this.paramFreightCharge.blFCIsChargePrintedString = "N";
    }
  }

  changeEventChBlNettChargeIsChargePrintedConsole(event) {
    if (event.target.checked) {
      this.paramFreightChargeConsole.blFCIsChargePrinted = true;
      this.paramFreightChargeConsole.blFCIsChargePrintedString = "Y";
    } else {
      this.paramFreightChargeConsole.blFCIsChargePrinted = false;
      this.paramFreightChargeConsole.blFCIsChargePrintedString = "N";
    }
  }

  changeEventChBlNettChargeIsManifestedFreight(event) {
    if (event.target.checked) {
      this.paramFreightCharge.blFCIsManifestedFreight = true;
      this.paramFreightCharge.blFCIsManifestedFreightString = "Y";
    } else {
      this.paramFreightCharge.blFCIsManifestedFreight = false;
      this.paramFreightCharge.blFCIsManifestedFreightString = "N";
    }
  }

  changeEventChBlNettChargeIsManifestedFreightConsole(event) {
    if (event.target.checked) {
      this.paramFreightChargeConsole.blFCIsManifestedFreight = true;
      this.paramFreightChargeConsole.blFCIsManifestedFreightString = "Y";
    } else {
      this.paramFreightChargeConsole.blFCIsManifestedFreight = false;
      this.paramFreightChargeConsole.blFCIsManifestedFreightString = "N";
    }
  }

  calculateNettChargeAmount() {
    this.paramFreightCharge.blFCAmount =
      this.paramFreightCharge.blFCRevenue * this.paramFreightCharge.blFCRate;
  }

  calculateNettChargeAmountConsole() {
    this.paramFreightChargeConsole.blFCAmount =
      this.paramFreightChargeConsole.blFCRevenue *
      this.paramFreightChargeConsole.blFCRate;
  }

  changeEventBlNettChargeQuantity(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.paramFreightCharge.blFCRevenue = 0;
    }

    if (val < 0) {
      val = 0;
      this.paramFreightCharge.blFCRevenue = 0;
    }

    if (val > 0) {
      this.paramFreightCharge.blFCRevenue = val;
    }
  }

  changeEventCbBlNettChargePlaceOfIssue(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPlaceOfIssueCode = event.locationCode;
      this.model.blPlaceOfIssueName = event.locationName;
    } else {
      this.model.blPlaceOfIssueCode = "";
      this.model.blPlaceOfIssueName = "";
    }

    this.cbBlNettChargePlaceOfIssueConsole.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );
    this.cbBlNettChargePlaceOfIssue.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );
  }

  changeEventCbBlNettChargePlaceOfIssueConsole(event) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }

    if (
      event.locationId != null &&
      event.locationId !== "" &&
      event.locationId !== undefined
    ) {
      this.model.blPlaceOfIssueCodeConsole = event.locationCode;
      this.model.blPlaceOfIssueNameConsole = event.locationName;
    } else {
      this.model.blPlaceOfIssueCodeConsole = "";
      this.model.blPlaceOfIssueNameConsole = "";
    }

    this.cbBlNettChargePlaceOfIssue.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );
    this.cbBlNettChargePlaceOfIssueConsole.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/" +
        this.cookieService.getDefaultLocationCode() +
        "/locationName={query}"
    );
  }

  resetRefreight() {
    this.isRefreightButtonClicked = false;
  }

  changeEventBlNoOfOBL(e) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
  }

  changeEventBlNoOfOBLConsole(e) {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
  }

  changeEventRemarks() {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
  }

  changeEventRemarksConsole() {
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
  }

  changeEventNewRemarks() {
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
  }
  /*********** GENERIC **************/

  setValidatorBLSWOIS() {
    this.validatorRules = {
      blMarkNoDescription: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Mark Description.",
          },
        ],
      },
    };
  }

  setValidatorBLNA() {
    this.validatorRules = {
      blMarkNoDescription: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Mark Description.",
          },
        ],
      },
    };
  }

  setData(data: any, isSync:boolean) {
    // console.log('debug set data');
    // console.log(data);

    // console.log('debug bl data');
    // console.log(data.blData);
    let oldBlSplitKey:string = this.model.blSplitKey;
    let oldBlFreightCoveredInBlNo:string = this.model.blFreightCoveredInBlNo;

    this.model = new BLHeader();

    console.log("masuk ga datanya ? : ", data);

    // hack
    /*
     if(data.bookingData != null && data.bookingData !== undefined){
     this.model.blBookingExMotherVesselId = (data.bookingData.bExMotherVesselId !== '') ? data.bookingData.bExMotherVesselId : '';
     this.model.blBookingExMotherVesselCode = (data.bookingData.bExMotherVesselCode !== '') ? data.bookingData.bExMotherVesselCode : '';
     this.model.blBookingExMotherVesselVoyage = (data.bookingData.bExMotherVesselVoyage !== '') ? data.bookingData.bExMotherVesselVoyage : '';
     }else{
     this.model.blBookingExMotherVesselId = '';
     this.model.blBookingExMotherVesselCode = '';
     this.model.blBookingExMotherVesselVoyage = '';
     }
     */
    // end hack

    this.model.blIsDetDemPrinted = (data.blData.blIsDetDemPrinted == undefined) ? 'N' : data.blData.blIsDetDemPrinted;

    this.model.blOfficeCode = data.blData.blOfficeCode;
    this.model.blNo = data.blData.blNo;

    this.model.blIsFinished = data.blData.blIsFinished === "Y";
    this.model.blStatus = data.blData.blStatus;

    // this.model.blBookingNo = data.bookingData.bPrefix + data.bookingData.bNo;
    this.model.blBookingOfficeCode = data.blData.blBookingOfficeCode;
    this.model.blBookingPrefix = data.blData.blBookingPrefix;
    this.model.blBookingNo = data.blData.blBookingNo;
    this.model.blBookingFreightParty = data.blData.blBookingFreightParty;
    this.model.blBookingFreightType = data.blData.blBookingFreightType;
    this.model.blIsNvocc = data.blData.blIsNvocc;
    this.nvoccIndicator = this.model.blIsNvocc === "Y" ? "YES" : "NA";

    this.model.blShipperDraftBlNo = data.blData.blShipperDraftBlNo;
    this.model.blFreightCharge = data.blFreightCharge;

    // GSO-1003 Additional field of "Customer's Internal Order #"
    this.model.blCustomerInternalOrder1 = data.blData.blCustomerInternalOrder1;
    this.model.blCustomerInternalOrder2 = data.blData.blCustomerInternalOrder2;
    this.model.blCustomerInternalOrder3 = data.blData.blCustomerInternalOrder3;

    //GSO-996
    if (data.blData.thruBlNo && data.blData.blType === "MEMO") {
      this.model.blThruBLRef = data.blData.thruBlNo;
    }

    let shipperBNo = "";
    if (data.blData.blShipperBookingNo.length > 0) {
      for (let i = 0; i < data.blData.blShipperBookingNo.length; i++) {
        if (i === 0) {
          shipperBNo = data.blData.blShipperBookingNo[0];
        } else {
          shipperBNo = shipperBNo + "\n" + data.blData.blShipperBookingNo[i];
        }
      }
      this.model.blShipperBookingNo = shipperBNo;
    } else {
      this.model.blShipperBookingNo = "";
    }

  //gos130
  if(data.blData.blDgNos.length>0){
    console.log("BLDATADGNOS : "+data.blData.blDgNos);
    this.model.blDgNos = data.blData.blDgNos;

    //GOS-733 Display of DG Class On BL Combined
    if(data.blData.blIsCombine ==="Y"){
      if(data.blData.blDgNos.length === 1 || data.blData.blDgNos.length === 0){
        this.model.blDgClass = data.blData.blDgClass ? data.blData.blDgClass : data.blData.blDgNos[0];
      }else{
        const allEqual = data.blData.blDgNos.every(val => val === data.blData.blDgNos[0])
        if(allEqual === false){
          this.model.blDgClass = "";
        }else{
          this.model.blDgClass = data.blData.blDgClass ? data.blData.blDgClass : data.blData.blDgNos[0];
        }
      }
    }
    }else{
      this.model.blDgClass = data.blData.blDgClass;
    }

    this.model.blShipperDraftBlNoSkipPrint =
      data.blData.blShipperDraftBlNoSkipPrint === "Y";

    /*
     if (data.blData.blShipperDraftBlNoSkipPrint === true) {
     this.chkSkipPrintShipperDraftBlRef = true;
     } else {
     this.chkSkipPrintShipperDraftBlRef = false;
     }
     */

    this.model.blIsCombine = data.blData.blIsCombine;
    this.model.blCombineKey = data.blData.blCombineKey;
    this.model.blIsSplit = data.blData.blIsSplit;
    if(isSync == false){
      this.model.blSplitKey = data.blData.blSplitKey;
    }else{
      this.model.blSplitKey = oldBlSplitKey;
    }
    this.model.blSplitGenerateType = data.blData.blSplitGenerateType;
    this.model.blSplitMethodType = data.blData.blSplitMethodType;
    this.model.blShipperEdiReference = data.blData.blShipperEdiReference;

    if (data.blData.blStatus == "" || data.blData.blStatus == undefined) {
      if(this.officeCode == "SGSIN" &&
        (data.blData.blContainerOwnership == "SOC" ||
         data.blData.blContainerOwnership == "SOCTS" ||
         data.blData.blContainerOwnership == "COCTS" ||
         data.blData.blContainerOwnership == "SLOT")){
          this.model.blShipperId = data.blData.blShipperId;
          if (data.blData.blShipperId == "" || data.blData.blShipperId == undefined) {
            this.custIdforCbShipper = "*";
          } else {
            this.custIdforCbShipper = data.blData.blShipperId;
          }
      } else {
        this.model.blShipperId = "";
        this.custIdforCbShipper = "*";
      }
      this.model.blShipperAddressOption = 0;
      this.model.blShipperAddressText = "";
      this.model.blShipperName = "";

      this.model.blConsigneeId = "";
      this.cneeIdforCbCnee = "*";
      this.model.blConsigneeAddressOption = 0;
      this.model.blConsigneeAddressText = "";
      this.model.blConsigneeName = "";

      this.model.blNotifyPartyId = "";
      this.partyIdforCbParty = "*";
      this.model.blNotifyPartyAddressOption = 0;
      this.model.blNotifyPartyAddressText = "";
      this.model.blNotifyPartyName = "";
    } else {
      this.model.blShipperId = data.blData.blShipperId;
      if (data.blData.blShipperId == "" || data.blData.blShipperId == undefined) {
        this.custIdforCbShipper = "*";
      } else {
        this.custIdforCbShipper = data.blData.blShipperId;
      }
      this.model.blShipperAddressOption =
        data.blData.blShipperAddressOption != undefined &&
        data.blData.blShipperAddressOption != null &&
        data.blData.blShipperAddressOption != ""
          ? Number.parseInt(data.blData.blShipperAddressOption)
          : 0;
      this.model.blShipperAddressText =
        data.blData.blShipperAddress === "↵"
          ? ""
          : data.blData.blShipperAddress;

            this.model.blShipperName =
              data.blData.blShipperName != undefined
                ? data.blData.blShipperName.toUpperCase().replace(/&NBSP;/g, " ")
                : "";


      this.model.blConsigneeId = data.blData.blConsigneeId;

      if (data.blData.blConsigneeId == "" || data.blData.blConsigneeId == undefined) {
        this.cneeIdforCbCnee = "*";
      } else {
        this.cneeIdforCbCnee = data.blData.blConsigneeId;
      }

      this.model.blConsigneeAddressOption =
        data.blData.blConsigneeAddressOption != undefined &&
        data.blData.blConsigneeAddressOption != null &&
        data.blData.blConsigneeAddressOption != ""
          ? Number.parseInt(data.blData.blConsigneeAddressOption)
          : 0;
      this.model.blConsigneeAddressText =
        data.blData.blConsigneeAddress === "↵"
          ? ""
          : data.blData.blConsigneeAddress;
      this.model.blConsigneeName =
        data.blData.blConsigneeName != undefined
          ? data.blData.blConsigneeName.toUpperCase().replace(/&NBSP;/g, " ")
          : "";

      this.model.blNotifyPartyId = data.blData.blNotifyPartyId;

      if (data.blData.blNotifyPartyId == "" || data.blData.blNotifyPartyId == undefined) {
        this.partyIdforCbParty = "*";
      } else {
        this.partyIdforCbParty = data.blData.blNotifyPartyId;
      }

      this.model.blNotifyPartyAddressOption =
        data.blData.blNotifyPartyAddressOption != undefined &&
        data.blData.blNotifyPartyAddressOption != null &&
        data.blData.blNotifyPartyAddressOption != ""
          ? Number.parseInt(data.blData.blNotifyPartyAddressOption)
          : 0;
      this.model.blNotifyPartyAddressText =
        data.blData.blNotifyPartyAddress === "↵"
          ? ""
          : data.blData.blNotifyPartyAddress;
      this.model.blNotifyPartyName =
        data.blData.blNotifyPartyName != undefined
          ? data.blData.blNotifyPartyName.toUpperCase().replace(/&NBSP;/g, " ")
          : "";
    }

    if(
      data.blData.blContainerOwnership=="SOC" && data.blData.blOperatorCode=="AX" ||
      data.blData.blContainerOwnership=="COC" && data.blData.blOperatorCode=="SA"
    ){
      this.isLockBLTypeOBL = true;
    }

    this.model.blType = data.blData.blType;
    this.model.blContainerOwnership = data.blData.blContainerOwnership;
    this.model.blContainerStatus = data.blData.blContainerStatus;
    this.model.blDetention = data.blData.blDetention;
    this.model.blDetentionRequest = data.blData.blDetentionRequest;
    this.model.blDemurrage = data.blData.blDemurrage;
    this.model.blDemurrageRequest = data.blData.blDemurrageRequest;
    this.model.blContainerType = data.blData.blContainerType;
    this.model.blOperatorCode = data.blData.blOperatorCode;
    this.model.blSOOperatorCode = data.blData.blSOOperatorCode;
    this.model.blDetentionPol = data.bookingExtra ? data.bookingExtra.bDetentionPol : "";
    this.model.blDetentionRequestPol = data.bookingExtra ? data.bookingExtra.bDetentionPolRequest : "";
    this.model.blDemurragePol = data.bookingExtra ? data.bookingExtra.bDemurragePol : "";
    this.model.blDemurrageRequestPol = data.bookingExtra ? data.bookingExtra.bDemurragePolRequest : "";

    // Place
    this.model.blReceiptTypeCode = data.blData.blReceiptTypeCode;
    this.model.blDeliveryTypeCode = data.blData.blDeliveryTypeCode;
    this.model.blPlaceOfReceiptCode = data.blData.blPlaceOfReceiptCode;

    // GOS-273 = Disabled more when final destination is blank
    this.model.blPlaceOfReceiptPrint = data.blData.blPlaceOfReceiptPrint;
    this.model.blPlaceOfReceiptName = data.blData.blPlaceOfReceiptName;
    this.model.blLoadPortCode = data.blData.blLoadPortCode;
    this.model.blLoadPortPrint = data.blData.blLoadPortPrint;
    this.model.blLoadPortName = data.blData.blLoadPortName;
    this.model.blDischargePortCode = data.blData.blDischargePortCode;
    this.model.blDischargePortPrint = data.blData.blDischargePortPrint;
    this.model.blDischargePortName = data.blData.blDischargePortName;
    this.model.blPlaceOfDeliveryCode = data.blData.blPlaceOfDeliveryCode;
    this.model.blPlaceOfDeliveryPrint = data.blData.blPlaceOfDeliveryPrint;
    this.model.blPlaceOfDeliveryName = data.blData.blPlaceOfDeliveryName;
    this.model.blPortOfTransshipment1Code =
      data.blData.blPortOfTransshipment1Code;
    this.model.blPortOfTransshipment1Name =
      data.blData.blPortOfTransshipment1Name;
    this.model.blPortOfTransshipment2Code =
      data.blData.blPortOfTransshipment2Code;
    this.model.blPortOfTransshipment2Name =
      data.blData.blPortOfTransshipment2Name;
    this.model.blPortOfTransshipment3Code =
      data.blData.blPortOfTransshipment3Code;
    this.model.blPortOfTransshipment3Name =
      data.blData.blPortOfTransshipment3Name;

    this.model.blFinalDestinationCode = data.blData.blFinalDestinationCode;
    this.model.blFinalDestinationPrint = data.blData.blFinalDestinationPrint;
    this.model.blFinalDestinationName = data.blData.blFinalDestinationName;

    // this.model.blThruBLRef = data.blData.thruBlNo;
    this.model.thruOfficeCode = data.blData.thruOfficeCode;

    console.log("SINI FINAL DESTINATION");

    if(this.model.blFinalDestinationName == null ||
      this.model.blFinalDestinationName == ""
    ){
      // this.blPlacePrintBlFinalDestination = true;
      this.blPlacePrintBlFinalDestination2 = true;

      // this.model.blPlaceOfReceiptPrint = "";
      // this.model.blLoadPortPrint = "";
      // this.model.blDischargePortPrint = "";
      // this.model.blPlaceOfDeliveryPrint = "";
      this.model.blFinalDestinationPrint = "";
    }else{
      // this.blPlacePrintBlFinalDestination = false;
      this.blPlacePrintBlFinalDestination2 = false;

      // this.model.blPlaceOfReceiptPrint = data.blData.blPlaceOfReceiptPrint;
      // this.model.blLoadPortPrint = data.blData.blLoadPortPrint;
      // this.model.blDischargePortPrint = data.blData.blDischargePortPrint;
      // this.model.blPlaceOfDeliveryPrint = data.blData.blPlaceOfDeliveryPrint;
      this.model.blFinalDestinationPrint = data.blData.blFinalDestinationPrint;
    }

    if (this.blmode === "MODE1" &&
      (
        this.model.blType == "MEMO" ||
        this.model.blType == "CRB" ||
        this.model.blType == "RFS" ||
        this.model.blType == "IRE" ||
        this.model.blType == "N"
      )
    ) {
      let checkblFinalDestinationCode = "";
      const urlFindBK = this.model.blThruBLRef && !this.model.blNo ? this.configService.config.BASE_API.toString() +
      "/BLBooking/findBLDataFromBooking/" +
      this.model.thruOfficeCode +
      "/" + this.model.blThruBLRef.trim()
      :
      this.configService.config.BASE_API.toString() +
      "/BLBooking/findBLDataFromBooking/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      (this.model.blIsSplit == "Y" ? (this.model.blSplitKey != '' ? this.model.blSplitKey.trim().toUpperCase() : this.model.blNo.trim().toUpperCase()) : this.model.blNo.trim().toUpperCase())
      this.genericService
        .GET(urlFindBK)
      .subscribe((resp_mode1) => {
        const data2 = resp_mode1.json();
        console.log("DATA BARU", data2);
        checkblFinalDestinationCode = data2.blData.blFinalDestinationCode

        if(checkblFinalDestinationCode !== this.model.blFinalDestinationCode){

          console.log("in sini");
          if(this.model.blFinalDestinationCode == "" || this.model.blFinalDestinationCode == null){
            this.blPlaceDataFromBooking = false;
            // this.blPlacePrintBlFinalDestination = true;
            this.blPlacePrintBlFinalDestination2 = true;
            // this.model.blPlaceOfReceiptPrint = "";
            // this.model.blLoadPortPrint = "";
            // this.model.blDischargePortPrint = "";
            // this.model.blPlaceOfDeliveryPrint = "";
          }else{
            this.blPlaceDataFromBooking = true;
            // this.blPlacePrintBlFinalDestination = false;
            this.blPlacePrintBlFinalDestination2 = false;
            // this.model.blPlaceOfReceiptPrint = data2.blData.blPlaceOfReceiptPrint;
            // this.model.blLoadPortPrint = data2.blData.blLoadPortPrint;
            // this.model.blDischargePortPrint = data2.blData.blDischargePortPrint;
            // this.model.blPlaceOfDeliveryPrint = data2.blData.blPlaceOfDeliveryPrint;
          }


        }

        // GOS-736 Discrepancy checks of DG Class in Container Tab VS Booking
        this.setDataToBookingDataDetail(data2)

      //Jika dari bl tidak dapat, ambil dari booking GOS-207
      //mulai
      if(!this.isRefreightProcess){
        if(!this.headerExtraFromBL){
          if (data2.blHeaderExtra != undefined) {
            console.log("EXTRA HERE data2: "+data2.blHeaderExtra.blExtraPayer1nameAddressOption)  ;
            console.log("EXTRA HERE 2 data2: "+data2.blHeaderExtra.blExtraPayer1nameAddressName)  ;

            if(data2.blHeaderExtra.blExtraPayer1nameAddressOption != 0 && this.model.blPayer1Code !='' ){
              this.model.blExtraPayer1nameAddressOption = data2.blHeaderExtra.blExtraPayer1nameAddressOption;
            }else{
              this.model.blExtraPayer1nameAddressOption =0;
            }

            if (data2.blHeaderExtra.blExtraPayer1nameAddressName !== "" && data2.blHeaderExtra.blExtraPayer1nameAddressName !== undefined) {
              this.model.blExtraPayer1nameAddressName = data2.blHeaderExtra.blExtraPayer1nameAddressName;
            } else {
              this.cbBlNettChargeBillingParty1Address.setUrl("");
              this.cbBlNettChargeBillingParty1Address.loadSelect();
              this.cbBlNettChargeBillingParty1Address.listStore.store = [];
              this.cbBlNettChargeBillingParty1Address.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterCustomers/findCustomerAddressByCustomerId/" +
                  this.model.blPayer1Code.trim()+
                  "/Y"
              );

              if(!this.isRefreightProcess){
                console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
                const self = this;
                this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
                  if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
                    self.cbBlNettChargeBillingParty1Address.setValue(
                      self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
                    );

                  self.model.blExtraPayer1nameAddressOption =
                  self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
                  self.model.blExtraPayer1nameAddressName =
                  self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
                  } else {
                    self.model.blExtraPayer1nameAddressOption = 0;
                    self.model.blExtraPayer1nameAddressName ="";
                  }
                });
              }
            // this.model.blExtraPayer1nameAddressName ="";
            }

            if(data2.blHeaderExtra.blExtraPayer2nameAddressOption != 0 && this.model.blPayer2Code !='' ){
              this.model.blExtraPayer2nameAddressOption = data2.blHeaderExtra.blExtraPayer2nameAddressOption;
            }else{
              this.model.blExtraPayer2nameAddressOption =0;
            }

            if (data2.blHeaderExtra.blExtraPayer2nameAddressName !== "" && data2.blHeaderExtra.blExtraPayer2nameAddressName !== undefined) {
              console.log("EXTRA HERE 3 data2 : "+data2.blHeaderExtra.blExtraPayer2nameAddressOption)  ;
              console.log("EXTRA HERE 4 data2: "+data2.blHeaderExtra.blExtraPayer2nameAddressName)  ;
              this.model.blExtraPayer2nameAddressName = data2.blHeaderExtra.blExtraPayer2nameAddressName;
            } else {
              this.cbBlNettChargeBillingParty2Address.setUrl("");
              this.cbBlNettChargeBillingParty2Address.loadSelect();
              this.cbBlNettChargeBillingParty2Address.listStore.store = [];
              this.cbBlNettChargeBillingParty2Address.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterCustomers/findCustomerAddressByCustomerId/" +
                  this.model.blPayer2Code.trim()+
                  "/Y"
              );
              if(!this.isRefreightProcess){

                const self = this;
                this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
                  if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
                    self.cbBlNettChargeBillingParty2Address.setValue(
                      self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
                    );

                    self.model.blExtraPayer2nameAddressOption =
                    self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
                    self.model.blExtraPayer2nameAddressName =
                    self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
                  } else {
                    self.model.blExtraPayer2nameAddressOption = 0;
                    self.model.blExtraPayer2nameAddressName = "";
                  }
                });
              }
            }

            if(data2.blHeaderExtra.blExtraPayer3nameAddressOption != 0 && this.model.blPayer3Code !=''  ){
              this.model.blExtraPayer3nameAddressOption = data2.blHeaderExtra.blExtraPayer3nameAddressOption;
            }else{
              this.model.blExtraPayer3nameAddressOption =0;
            }

            if (data2.blHeaderExtra.blExtraPayer3nameAddressName !== "" && data2.blHeaderExtra.blExtraPayer3nameAddressName !== undefined) {
              this.model.blExtraPayer3nameAddressName = data2.blHeaderExtra.blExtraPayer3nameAddressName;
            } else {
              this.cbBlNettChargeBillingParty3Address.setUrl("");
              this.cbBlNettChargeBillingParty3Address.loadSelect();
              this.cbBlNettChargeBillingParty3Address.listStore.store = [];
              this.cbBlNettChargeBillingParty3Address.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterCustomers/findCustomerAddressByCustomerId/" +
                  this.model.blPayer3Code.trim()+
                  "/Y"
              );
              if(!this.isRefreightProcess){
                const self = this;
                this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
                  if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
                    self.cbBlNettChargeBillingParty3Address.setValue(
                      self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
                    );

                  self.model.blExtraPayer3nameAddressOption =
                  self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
                  self.model.blExtraPayer3nameAddressName =
                  self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
                  } else {
                    self.model.blExtraPayer3nameAddressOption = 0;
                    self.model.blExtraPayer3nameAddressName ="";
                  }
                });
              }
            }
          }else{
            this.headerExtraFromBL = false;

              this.model.blExtraPayer1nameAddressOption =0;
              this.model.blExtraPayer2nameAddressOption =0;
              this.model.blExtraPayer3nameAddressOption =0;
              this.model.blExtraPayer1nameAddressName ="";
              this.model.blExtraPayer2nameAddressName ="";
              this.model.blExtraPayer3nameAddressName ="";
          }
        }
      }


       if(this.officeCode == "SGSIN" &&
        data2.blData.blContainerOwnership == "COCTS"){
         if(this.model.blShipperId == '' || this.model.blShipperId == null) {
            this.model.blShipperId = data2.blData.blShipperId;
            if (data2.blData.blShipperId == "" || data2.blData.blShipperId == undefined) {
              this.custIdforCbShipper = "*";
            } else {
              this.custIdforCbShipper = data2.blData.blShipperId;
            }

            this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findByComboBoxControlGeneral/shipper/"+this.dateIssueforCbShipper+"/"+this.custIdforCbShipper+"/customerId={query}");
            this.cbBlPartyShipper.setValue(this.model.blShipperId);
         }
      } else {
        /*this.model.blShipperId = "";
        this.custIdforCbShipper = "*";
        */

      }

        //end 207
        //berakhir
      });



    }


    // tjis will set as pre vessel check box print
    this.model.blCommodityId = data.blData.blCommodityId;
    if (data.blData.blCommodityId != "Y") {
      this.model.blCommodityId = "N";
      this.isPreVesselPrinted = false;
    } else {
      this.model.blCommodityId = "Y";
      this.isPreVesselPrinted = true;
    }

    this.model.blPrincipalId = data.blData.blPrincipalId;
    this.model.blServiceId = data.blData.blServiceId;

    //gos-263
    console.log("gos 263 :"+ data.blData.blPreVesselSkipCheck);
    this.model.blPreVesselSkipCheck = data.blData.blPreVesselSkipCheck === "Y";
    console.log("gos 263.2 :"+  this.model.blPreVesselSkipCheck );
    //this.model.blPreVesselIsPrinted = (data.blData.blPreVesselIsPrinted === 'Y');
    this.model.blPreVesselIsClausePrintedOnBl =
      data.blData.blPreVesselIsClausePrintedOnBl === "Y";
    this.model.blPreVesselId = data.blData.blPreVesselId;
    this.model.blPreVesselVoyage = data.blData.blPreVesselVoyage;
    this.model.blPreVesselBound = data.blData.blPreVesselBound;

    this.model.blOceanVesselIsClausePrintedOnBl =
      data.blData.blOceanVesselIsClausePrintedOnBl === "Y";
    this.model.blOceanVesselId = data.blData.blOceanVesselId;
    this.model.blOceanVesselCode = data.blData.blOceanVesselCode;
    this.model.blOceanVesselVoyage = data.blData.blOceanVesselVoyage;
    this.model.blOceanVesselBound = data.blData.blOceanVesselBound;
    this.model.blOceanVesselName =
      data.blData.blOceanVesselName !== undefined
        ? data.blData.blOceanVesselName
        : "";

    // patch bizapp 27082019
    this.originalOceanVesselName =
      data.bookingData.bOceanVesselName !== undefined
        ? data.bookingData.bOceanVesselName
        : "";
    this.originalOceanVesselId =
      data.bookingData.bOceanVesselId !== undefined
        ? data.bookingData.bOceanVesselId
        : "";
    this.originalOceanVesselCode =
      data.bookingData.bOceanVesselCode !== undefined
        ? data.bookingData.bOceanVesselCode
        : "";
    this.originalOceanVesselVoyage =
      data.bookingData.bOceanVesselVoyage !== undefined
        ? data.bookingData.bOceanVesselVoyage
        : "";
    this.originalOceanVesselBound =
      data.bookingData.bOceanVesselBound !== undefined
        ? data.bookingData.bOceanVesselBound
        : "";
    //gos-263
    console.log("gos 263.3 :"+ data.blData.blNextVesselSkipCheck);
    this.model.blNextVesselSkipCheck = data.blData.blNextVesselSkipCheck === "Y";
    console.log("gos 263.4 :"+  this.model.blNextVesselSkipCheck );
    this.model.blNextVesselClauseIsPrinted =
      data.blData.blNextVesselClauseIsPrinted === "Y";
    this.model.blNextVesselId = data.blData.blNextVesselId;
    this.model.blNextVesselVoyage = data.blData.blNextVesselVoyage;
    this.model.blNextVesselBound = data.blData.blNextVesselBound;
    this.model.blNextVesselPrintedAsOceanVessel =
      data.blData.blNextVesselPrintedAsOceanVessel === "Y";

    this.model.blPreVesselFooterOnBl =
      data.blData.blPreVesselFooterOnBl === "Y";
    this.model.blOceanVesselFooterOnBl =
      data.blData.blOceanVesselFooterOnBl === "Y";
    this.model.blNextVesselFooterOnBl =
      data.blData.blNextVesselFooterOnBl === "Y";

    // Desc
    this.model.blIsPrintAgentInfoOnBl =
      data.blData.blIsPrintAgentInfoOnBl === "Y";
    this.model.blAgentAddressId = data.blData.blAgentAddressId;
    this.model.blDescAgentRemark = data.blData.blDescAgentRemark;
    this.model.blIsIrePermit = data.blData.blIsIrePermit === "Y";
    this.model.blIsSingleConsigneePermit =
      data.blData.blIsSingleConsigneePermit === "Y";
    this.model.blDeclaredValue = data.blData.blDeclaredValue;
    this.model.blExporterTaxNumber = data.blData.blExporterTaxNumber;
    this.model.blExporterTaxNumberPrint =
      data.blData.blExporterTaxNumberPrint === "Y";
    this.model.blImporterTaxNumber = data.blData.blImporterTaxNumber;
    this.model.blImporterTaxNumberPrint =
      data.blData.blImporterTaxNumberPrint === "Y";

    // console.log("yeye");
    //if(data.blDesc[0].blDescBlDutiable != undefined){
    //  this.paramDesc.blDescBlDutiable = data.blDesc[0].blDescBlDutiable;
    //}

    // Nett Charge
    /* Commented out by GLG, because it cause see saw effect during the manual handling between bl maintenance and print out

        if(this.site==="CNSHA")
        {this.model.blIsFreightAsArranged =false
          this.model.blIsFreightAsArrangedConsole = this.model.blIsFreightAsArranged;
        } else
        {this.model.blIsFreightAsArranged =
          data.blData.blIsFreightAsArranged === "Y";
        this.model.blIsFreightAsArrangedConsole = this.model.blIsFreightAsArranged;
      }

      if(this.site==="CNSHA")
      { this.model.blIsPrintLoadingVesselVoyageClause = false
        this.model.blIsPrintLoadingVesselVoyageClauseConsole = this.model.blIsPrintLoadingVesselVoyageClause;
      } else
      { this.model.blIsPrintLoadingVesselVoyageClause =
         data.blData.blIsPrintLoadingVesselVoyageClause === "Y";
         this.model.blIsPrintLoadingVesselVoyageClauseConsole =
         this.model.blIsPrintLoadingVesselVoyageClause;
      }
   */

    /* Commented out by GLG, because it cause see saw effect during the manual handling between bl maintenance and print out
      if(this.site==="CNSHA")
      { this.model.blIsPrintShippedOnBoard = false
        this.model.blIsPrintShippedOnBoardConsole = this.model.blIsPrintShippedOnBoard;
      } else
      {
        this.model.blIsPrintShippedOnBoard =
        data.blData.blIsPrintShippedOnBoard === "Y";
        this.model.blIsPrintShippedOnBoardConsole =
          this.model.blIsPrintShippedOnBoard;
      }
     */

    // Freight as Arranged handling
    this.model.blIsFreightAsArranged =
      data.blData.blIsFreightAsArranged === "Y";
    this.model.blIsFreightAsArrangedConsole = this.model.blIsFreightAsArranged;

    // Freight Print Loading Vessel Clause Handling
    data.blData.blIsPrintLoadingVesselVoyageClause === "Y";
    this.model.blIsPrintLoadingVesselVoyageClauseConsole =
      this.model.blIsPrintLoadingVesselVoyageClause;


    // SOB Handling (reverted)
    this.model.blIsPrintShippedOnBoard =
      data.blData.blIsPrintShippedOnBoard === "Y";
    this.model.blIsPrintShippedOnBoardConsole =
      this.model.blIsPrintShippedOnBoard;

    this.model.blFreightType = "NETT";
    this.model.blFreightTypeConsole = this.model.blFreightType;

    this.model.blExchangeRateType =
      data.blData.blExchangeRateMethod != null &&
      data.blData.blExchangeRateMethod !== undefined &&
      data.blData.blExchangeRateMethod !== ""
        ? data.blData.blExchangeRateMethod
        : "NORMAL";
    this.model.blExchangeRateTypeConsole = this.model.blExchangeRateType;

    //this.model.blIsExchangeRateLock = false;
    this.model.blIsExchangeRateLockConsole = false;

    //this.model.blNoOfOBL = 3;

    var noOfBl = 3;
    if (
      this.officeCode == "SGSIN" ||
      this.officeCode == "MYKUA" ||
      this.officeCode == "MYPKG" ||
      this.officeCode == "MYPGU" ||
      this.officeCode == "MYPEN"
    ) {
      if (this.model.blType == "MEMO") {
        noOfBl = 0;
      } else {
        noOfBl = 3;
      }
    }

    //override rules based on GSO-42
    if (this.model.blOfficeCode == "SGSIN") {
      if (
        this.model.blContainerOwnership == "SOC" ||
        this.model.blContainerOwnership == "SLOT" ||
        this.model.blContainerOwnership == "COCTS" ||
        this.model.blContainerOwnership == "SOCTS"
      ) {
        noOfBl = 0;
      } else {
        noOfBl = 3;
      }
    }

    this.model.blNoOfOBL =
      data.blData.blOriginalCopyAmount != undefined
        ? data.blData.blOriginalCopyAmount
        : noOfBl;
    this.model.blNoOfOBLConsole = this.model.blNoOfOBL;

    if(isSync == false){
      this.model.blFreightCoveredInBlNo = data.blData.blFreightCoveredInBlNo;
    }else{
      this.model.blFreightCoveredInBlNo = oldBlFreightCoveredInBlNo;
    }

    this.model.blFreightCoveredInBlNoConsole =
      this.model.blFreightCoveredInBlNo;

    /*this.model.blDateOfIssue = data.blData.blDateOfIssue;
    this.model.blDateOfIssueConsole = this.model.blDateOfIssue;

    this.model.blExchangeRateDate = data.blData.blExchangeRateDate;
    this.model.blExchangeRateDateConsole = this.model.blExchangeRateDate;


    if(data.blExtraShippedOnBoard == undefined || data.blExtraShippedOnBoard == ""){
      this.setBlIsShippedOnBoardDateRetrieved();
    }else{
      this.model.blExtraShippedOnBoard = data.blHeaderExtra.blExtraShippedOnBoard;
    }
    this.model.blDateOfIssueLock = data.blData.blDateOfIssueLock === "Y";
    this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;

    this.model.blIsExchangeRateLock = data.blData.blIsExchangeRateLock === "Y";
    this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
    if(data.blExtraShippedOnBoard == undefined || data.blExtraShippedOnBoard == ""){
      this.model.blExtraShippedOnBoardLock = true;
    }else{
      this.model.blExtraShippedOnBoardLock = data.blHeaderExtra.blExtraShippedOnBoardLock === "Y";
    }*/

  console.log("Ingin menangis 1 :"+ data.blData.blDateOfIssue );
  console.log("Ingin menangis 2 :"+ data.blData.blExchangeRateDate );
  //console.log("Ingin menangis 3 :"+ data.blHeaderExtra.blExtraShippedOnBoard );
  if(data.blHeaderExtra != undefined){
    if((data.blData.blDateOfIssue == undefined || data.blData.blDateOfIssue == "") &&
      (data.blData.blExchangeRateDate == undefined || data.blData.blExchangeRateDate == "") &&
      (data.blHeaderExtra.blExtraShippedOnBoard == undefined || data.blHeaderExtra.blExtraShippedOnBoard == "")
    ){
      console.log("Ingin menangis 4 :"+ data.blHeaderExtra.blExtraShippedOnBoard );

      /* commented by glg, the handle already moved to BE
      if (this.site==="CNSHA")
      {this.model.blDateOfIssueLock = false;
        this.model.blExtraShippedOnBoardLock = false;
        this.model.blDateOfIssueLockConsole = false;
        this.model.blIsExchangeRateLock = false;
        this.model.blIsExchangeRateLockConsole = false;

      } else
      {
      this.model.blDateOfIssueLock = true;
      this.model.blExtraShippedOnBoardLock = true;
      this.model.blDateOfIssueLockConsole = true;
      this.model.blIsExchangeRateLock = true;
      this.model.blIsExchangeRateLockConsole = true;

      }*/
      //glg was here
      this.model.blDateOfIssueLock = true;
      this.model.blExtraShippedOnBoardLock = true;
      this.model.blDateOfIssueLockConsole = true;
      this.model.blIsExchangeRateLock = true;
      this.model.blIsExchangeRateLockConsole = true;
      this.setBlIsShippedOnBoardDateRetrieved();

    } else{
      console.log("Ingin menangis 6 :"+ data.blHeaderExtra.blExtraShippedOnBoard );
      // nothing
      this.model.blDateOfIssue = data.blData.blDateOfIssue;
      this.model.blDateOfIssueConsole = this.model.blDateOfIssue;
      this.model.blExchangeRateDate = data.blData.blExchangeRateDate;
      this.model.blExchangeRateDateConsole = this.model.blExchangeRateDate;
      this.model.blExtraShippedOnBoard = data.blHeaderExtra.blExtraShippedOnBoard;

      /** commented out by glg, not needed anymore since the default value has been driven from BE
      if (this.site==="CNSHA")
      {this.model.blDateOfIssueLock = false;
          this.model.blExtraShippedOnBoardLock = false;
          this.model.blDateOfIssueLockConsole = false;
          this.model.blIsExchangeRateLock = false;
          this.model.blIsExchangeRateLockConsole = false;

      }else
      {
      this.model.blDateOfIssueLock = data.blData.blDateOfIssueLock === "Y";
      this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;
      this.model.blIsExchangeRateLock = data.blData.blIsExchangeRateLock === "Y";
      this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
      this.model.blExtraShippedOnBoardLock = data.blHeaderExtra.blExtraShippedOnBoardLock === "Y";
      }
    */

      this.model.blDateOfIssueLock = data.blData.blDateOfIssueLock === "Y";
      this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;
      this.model.blIsExchangeRateLock = data.blData.blIsExchangeRateLock === "Y";
      this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
      this.model.blExtraShippedOnBoardLock = data.blHeaderExtra.blExtraShippedOnBoardLock === "Y";

      //gos-263
      //this.model.blPreVesselSkipCheck = true;
      //this.model.blNextVesselSkipCheck = true;
      //this.setBlIssueDateExchangeRateDate();

      /* https://samudera.atlassian.net/browse/GOS-382?focusedCommentId=36240
      this.rfsBL();
      */
      this.setBlIsShippedOnBoardDateOnly();
    }

  }else{

    console.log("Ingin menangis 5 :");
    this.model.blDateOfIssue = data.blData.blDateOfIssue;
    this.model.blDateOfIssueConsole = this.model.blDateOfIssue;
    this.model.blExchangeRateDate = data.blData.blExchangeRateDate;
    this.model.blExchangeRateDateConsole = this.model.blExchangeRateDate;
    //this.model.blExtraShippedOnBoard = data.blHeaderExtra.blExtraShippedOnBoard;
    /* Commented out by glg during sha live implementation
    if (this.site==="CNSHA")
    { this.model.blDateOfIssueLock = false;
      this.model.blDateOfIssueLockConsole = false;
      this.model.blIsExchangeRateLock = false;
      this.model.blIsExchangeRateLockConsole = false;
      this.model.blExtraShippedOnBoardLock =false;
    }
    else{
    this.model.blDateOfIssueLock = data.blData.blDateOfIssueLock === "Y";
    this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;
    this.model.blIsExchangeRateLock = data.blData.blIsExchangeRateLock === "Y";
    this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
    this.model.blExtraShippedOnBoardLock =true;
    }
    */
    this.model.blDateOfIssueLock = data.blData.blDateOfIssueLock === "Y";
    this.model.blDateOfIssueLockConsole = this.model.blDateOfIssueLock;
    this.model.blIsExchangeRateLock = data.blData.blIsExchangeRateLock === "Y";
    this.model.blIsExchangeRateLockConsole = this.model.blIsExchangeRateLock;
    this.model.blExtraShippedOnBoardLock =true;
    this.setBlIsShippedOnBoardDateOnly();

  }



    this.model.blPlaceOfIssueCode = data.blData.blPlaceOfIssueCode;
    this.model.blPlaceOfIssueCodeConsole = this.model.blPlaceOfIssueCode;

    this.model.blPlaceOfIssueName = data.blData.blPlaceOfIssueName;
    this.model.blPlaceOfIssueNameConsole = this.model.blPlaceOfIssueName;

    this.model.blPrepaidAt1Code = data.blData.blPrepaidAt1Code;
    this.model.blPrepaidAt1Name = data.blData.blPrepaidAt1Name;
    this.model.blPrepaidAt2Code = data.blData.blPrepaidAt2Code;
    this.model.blPrepaidAt2Name = data.blData.blPrepaidAt2Name;
    this.model.blPrepaidAt3Code = data.blData.blPrepaidAt3Code;
    this.model.blPrepaidAt3Name = data.blData.blPrepaidAt3Name;

    this.model.blPayableAt1Code = data.blData.blPayableAt1Code;
    this.model.blPayableAt1Name = data.blData.blPayableAt1Name;
    this.model.blPayableAt2Code = data.blData.blPayableAt2Code;
    this.model.blPayableAt2Name = data.blData.blPayableAt2Name;
    this.model.blPayableAt3Code = data.blData.blPayableAt3Code;
    this.model.blPayableAt3Name = data.blData.blPayableAt3Name;

    this.model.blPayer1Code = data.blData.blPayer1Code;
    this.model.blPayer2Code = data.blData.blPayer2Code;
    this.model.blPayer3Code = data.blData.blPayer3Code;

    if (data.blData.blPayer1Code == "" || data.blData.blPayer1Code == undefined) {
      this.custIdForCbPayer1 = "*";
    } else {
      this.custIdForCbPayer1 = data.blData.blPayer1Code;
    }

    if (data.blData.blPayer2Code == "" || data.blData.blPayer2Code == undefined) {
      this.custIdForCbPayer2 = "*";
    } else {
      this.custIdForCbPayer2 = data.blData.blPayer2Code;
    }

    if (data.blData.blPayer3Code == "" || data.blData.blPayer3Code == undefined) {
      this.custIdForCbPayer3 = "*";
    } else {
      this.custIdForCbPayer3 = data.blData.blPayer3Code;
    }

    console.log("GOS-207");
    if (data.blHeaderExtra != undefined) {
      this.headerExtraFromBL = true;
      console.log("EXTRA HERE : "+data.blHeaderExtra.blExtraPayer1nameAddressOption)  ;
      console.log("EXTRA HERE 2 : "+data.blHeaderExtra.blExtraPayer1nameAddressName)  ;

      if(data.blHeaderExtra.blExtraPayer1nameAddressOption != 0 && this.model.blPayer1Code !='' ){
        this.model.blExtraPayer1nameAddressOption = data.blHeaderExtra.blExtraPayer1nameAddressOption;
      }else{
        this.model.blExtraPayer1nameAddressOption =0;
      }

      if (data.blHeaderExtra.blExtraPayer1nameAddressName !== "" && data.blHeaderExtra.blExtraPayer1nameAddressName !== undefined) {
        this.model.blExtraPayer1nameAddressName = data.blHeaderExtra.blExtraPayer1nameAddressName;
      } else {
        this.cbBlNettChargeBillingParty1Address.setUrl("");
        this.cbBlNettChargeBillingParty1Address.loadSelect();
        this.cbBlNettChargeBillingParty1Address.listStore.store = [];
        this.cbBlNettChargeBillingParty1Address.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findCustomerAddressByCustomerId/" +
            this.model.blPayer1Code.trim()+
            "/Y"
        );

        if(!this.isRefreightProcess){
          console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
          const self = this;
          this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
            if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
              self.cbBlNettChargeBillingParty1Address.setValue(
                self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
              );

            self.model.blExtraPayer1nameAddressOption =
            self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer1nameAddressName =
            self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
            } else {
              self.model.blExtraPayer1nameAddressOption = 0;
              self.model.blExtraPayer1nameAddressName ="";
            }
          });
        }
       // this.model.blExtraPayer1nameAddressName ="";
      }

      if(data.blHeaderExtra.blExtraPayer2nameAddressOption != 0 && this.model.blPayer2Code !='' ){
        this.model.blExtraPayer2nameAddressOption = data.blHeaderExtra.blExtraPayer2nameAddressOption;
      }else{
        this.model.blExtraPayer2nameAddressOption =0;
      }

      if (data.blHeaderExtra.blExtraPayer2nameAddressName !== "" && data.blHeaderExtra.blExtraPayer2nameAddressName !== undefined) {
        console.log("EXTRA HERE 3 : "+data.blHeaderExtra.blExtraPayer2nameAddressOption)  ;
        console.log("EXTRA HERE 4 : "+data.blHeaderExtra.blExtraPayer2nameAddressName)  ;
        this.model.blExtraPayer2nameAddressName = data.blHeaderExtra.blExtraPayer2nameAddressName;
      } else {
        this.cbBlNettChargeBillingParty2Address.setUrl("");
        this.cbBlNettChargeBillingParty2Address.loadSelect();
        this.cbBlNettChargeBillingParty2Address.listStore.store = [];
        this.cbBlNettChargeBillingParty2Address.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findCustomerAddressByCustomerId/" +
            this.model.blPayer2Code.trim()+
            "/Y"
        );
        if(!this.isRefreightProcess){

          const self = this;
          this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
            if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
              self.cbBlNettChargeBillingParty2Address.setValue(
                self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
              );

              self.model.blExtraPayer2nameAddressOption =
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
              self.model.blExtraPayer2nameAddressName =
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
            } else {
              self.model.blExtraPayer2nameAddressOption = 0;
              self.model.blExtraPayer2nameAddressName = "";
            }
          });
        }
      }

      if(data.blHeaderExtra.blExtraPayer3nameAddressOption != 0 && this.model.blPayer3Code !=''  ){
        this.model.blExtraPayer3nameAddressOption = data.blHeaderExtra.blExtraPayer3nameAddressOption;
      }else{
        this.model.blExtraPayer3nameAddressOption =0;
      }

      if (data.blHeaderExtra.blExtraPayer3nameAddressName !== "" && data.blHeaderExtra.blExtraPayer3nameAddressName !== undefined) {
        this.model.blExtraPayer3nameAddressName = data.blHeaderExtra.blExtraPayer3nameAddressName;
      } else {
        this.cbBlNettChargeBillingParty3Address.setUrl("");
        this.cbBlNettChargeBillingParty3Address.loadSelect();
        this.cbBlNettChargeBillingParty3Address.listStore.store = [];
        this.cbBlNettChargeBillingParty3Address.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findCustomerAddressByCustomerId/" +
            this.model.blPayer3Code.trim()+
            "/Y"
        );
        if(!this.isRefreightProcess){
          const self = this;
          this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
            if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
              self.cbBlNettChargeBillingParty3Address.setValue(
                self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
              );

            self.model.blExtraPayer3nameAddressOption =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer3nameAddressName =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
            } else {
              self.model.blExtraPayer3nameAddressOption = 0;
              self.model.blExtraPayer3nameAddressName ="";
            }
          });
        }
      }
   }else{

      this.headerExtraFromBL = false;

      this.model.blExtraPayer1nameAddressOption =0;
      this.model.blExtraPayer2nameAddressOption =0;
      this.model.blExtraPayer3nameAddressOption =0;
      this.model.blExtraPayer1nameAddressName ="";
      this.model.blExtraPayer2nameAddressName ="";
      this.model.blExtraPayer3nameAddressName ="";
   }


    this.model.blRemarks =
      data.blData.blRemarks != null ? data.blData.blRemarks : "";
    this.model.blRemarksConsole = this.model.blRemarks;

    this.model.blPrincipalId = "PP00021"; // Lock to SSL
    this.model.blDateDeleted = data.blData.blDateDeleted;
    this.model.blUserDeleted = data.blData.blUserDeleted;

    this.model.blUserCreated = data.blData.blUserCreated;
    this.model.blDateCreated = data.blData.blDateCreated;

    if (this.model.blDateCreated == null ||
      this.model.blDateCreated === "" ||
      this.model.blDateCreated === undefined) {

    } else {
      const myArray = this.model.blDateCreated.split(" ");
      this.dateIssueforCbShipper = myArray[0];
    }


    this.model.blUserModified = data.blData.blUserModified;
    this.model.blDateModified = data.blData.blDateModified;

    this.model.blIsBlPrinted = data.blData.blIsBlPrinted;
    this.model.blPrintedUserid = data.blData.blPrintedUserid;
    this.model.blPrintedDate = data.blData.blPrintedDate;

    // Additional
    this.model.blPlaceOfIssueName = data.blData.blPlaceOfIssueName;
    this.model.blPrepaidAt1Name = data.blData.blPrepaidAt1Name;
    this.model.blPrepaidAt2Name = data.blData.blPrepaidAt2Name;
    this.model.blPrepaidAt3Name = data.blData.blPrepaidAt3Name;
    this.model.blPayableAt1Name = data.blData.blPayableAt1Name;
    this.model.blPayableAt2Name = data.blData.blPayableAt2Name;
    this.model.blPayableAt3Name = data.blData.blPayableAt3Name;
    this.model.blPayer1Name = data.blData.blPayer1Name;
    this.model.blPayer2Name = data.blData.blPayer2Name;
    this.model.blPayer3Name = data.blData.blPayer3Name;

    if (data.blNewRemarks != undefined) {
      this.model.blNewRemarks = data.blNewRemarks;
    } else {
      this.model.blNewRemarks = "";
    }

    // bl issue date init
    if (
      this.model.blDateOfIssue == null ||
      this.model.blDateOfIssue === "" ||
      this.model.blDateOfIssue === undefined
    ) {
      if (this.model.blOceanVesselId === "") {
        // if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeDateOfIssue.setValue(
          this.genericUtil.getPSIDate()
        );
        this.dateBlNettChargeDateOfIssueConsole.setValue(
          this.genericUtil.getPSIDate()
        );
        this.tmpDateOfIssue = this.genericUtil.getPSIDate();
        // }
      }
    } else {
      // if (this.model.blDateOfIssueLock !== true) {
      this.dateBlNettChargeDateOfIssue.setValue(this.model.blDateOfIssue);
      this.dateBlNettChargeDateOfIssueConsole.setValue(
        this.model.blDateOfIssue
      );
      this.tmpDateOfIssue = this.model.blDateOfIssue;
      // }
    }
    // if (this.dateBlNettChargeDateOfIssue.getValue() == null ||
    //   this.dateBlNettChargeDateOfIssue.getValue() === "" ||
    //   this.dateBlNettChargeDateOfIssue.getValue() === undefined) {
    //     this.dateIssueforCbShipper = this.dateBlNettChargeDateOfIssue.getValue();
    // } else {
    //   this.dateIssueforCbShipper = "*";
    // }


    // bl exchange rate date init
    if (
      this.model.blExchangeRateDate == null ||
      this.model.blExchangeRateDate === "" ||
      this.model.blExchangeRateDate === undefined
    ) {
      if (this.model.blOceanVesselId === "") {
        // if (this.model.blIsExchangeRateLock !== true) {
        this.dateBlNettChargeExchangeRate.setValue(
          this.genericUtil.getPSIDate()
        );
        this.dateBlNettChargeExchangeRateConsole.setValue(
          this.genericUtil.getPSIDate()
        );
        this.tmpExchangeRateDate = this.genericUtil.getPSIDate();
        // }
      }
    } else {
      // if (this.model.blIsExchangeRateLock !== true) {
      this.dateBlNettChargeExchangeRate.setValue(this.model.blExchangeRateDate);
      this.dateBlNettChargeExchangeRateConsole.setValue(
        this.model.blExchangeRateDate
      );
      this.tmpExchangeRateDate = this.model.blExchangeRateDate;
      // }
    }



    // bl shipped on board date init
    if (
      this.model.blExtraShippedOnBoard == null ||
      this.model.blExtraShippedOnBoard === "" ||
      this.model.blExtraShippedOnBoard === undefined
    ) {
      if (this.model.blOceanVesselId === "") {
        // if (this.model.blDateOfIssueLock !== true) {
        this.dateBlNettChargeShippedOnBoard.setValue(
          this.genericUtil.getPSIDate()
        );
        /*this.dateBlNettChargeDateOfIssueConsole.setValue(
          this.genericUtil.getPSIDate()
        );
        this.tmpShippedOnBoard = this.genericUtil.getPSIDate();*/
        // }
      }
    } else {
      // if (this.model.blDateOfIssueLock !== true) {
      this.dateBlNettChargeShippedOnBoard.setValue(this.model.blExtraShippedOnBoard);
      /*this.dateBlNettChargeDateOfIssueConsole.setValue(
        this.model.blDateOfIssue
      );
      this.tmpShippedOnBoard = this.model.blExtraShippedOnBoard;*/
      // }
    }


    /**
     if (this.model.blDateOfIssue == null || this.model.blDateOfIssue === '' || this.model.blDateOfIssue === undefined ) {
      if (this.model.blOceanVesselId === '') {
        this.dateBlNettChargeDateOfIssue.setValue(this.genericUtil.getPSIDate());
        this.dateBlNettChargeExchangeRate.setValue(this.genericUtil.getPSIDate());
      } else {
        // find the bl date and exchange rate
        this.genericService.GET(this.configService.config.BASE_API.toString() +
        '/MasterSailingSchedules/findSailingScheduleByDoubleClick/' + this.model.blOceanVesselId + '/' +
        this.model.blOceanVesselVoyage + '/' + this.model.blOceanVesselBound + '/' +
        this.cookieService.getDefaultLocationCode()).subscribe( (resp) => {
          if (resp.status === 200) {
            const dt = resp.json()['content']['0'];
            this.dateBlNettChargeDateOfIssue.setValue(dt.blDate);
            this.dateBlNettChargeExchangeRate.setValue(dt.blExchangeRateDate);
          }
        });
      }
    } else {
      this.dateBlNettChargeDateOfIssue.setValue(this.model.blDateOfIssue);
      this.dateBlNettChargeExchangeRate.setValue(this.model.blExchangeRateDate);
    }
     */

    //hardcode
    if (this.cookieService.getName() == "muchtar" || this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    }

    // patch 3 dates for china/
    // logic is very complicated, thus let the existing logic running, then we reset the flag over here
    if (this.site.startsWith("CN")){ //changed implementation from CNSHA to be CN%
      if (this.blmode === "MODE2") {
        this.model.blIsExchangeRateLock = false;
        this.model.blDateOfIssueLock = false;
        this.model.blExtraShippedOnBoardLock = false;
        this.setBlIssueDateExchangeRateDate();
      }
    }


      this.setFormControl(data);



  }

  setFormControl(data: any) {
    const self = this;

    // party
    /*
     if (this.model.blShipperId != null && this.model.blShipperId !== '' && this.model.blShipperId !== undefined) {
     this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
     '/MasterCustomers/findByComboBoxControlShipper/customerId={query}');
     this.cbBlPartyShipper.setValue(this.model.blShipperId);
     } else {
     if (this.model.blShipperAddressText !== '') {
     this.txtBlPartyShipperAddress.setValue(this.model.blShipperAddressText);
     }
     }*/
    if (
      this.model.blShipperName != null &&
      this.model.blShipperName !== undefined &&
      this.model.blShipperName !== ""
    ) {
      this.cbBlPartyShipper.setForceValue(this.model.blShipperName);
      this.cbBlPartyShipperAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blShipperId.trim()
      );
      this.cbBlPartyShipperAddress.loadSelect(function () {
        self.cbBlPartyShipperAddress.setValue(
          String(self.model.blShipperAddressOption).toString() === "0"
            ? ""
            : String(self.model.blShipperAddressOption).toString()
        );
      });
      this.txtBlPartyShipperAddress.setValue(this.model.blShipperAddressText);
    } else {
      // this.cbBlPartyShipper.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterCustomers/findByComboBoxControlShipper/customerId={query}"
      // );

      this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findByComboBoxControlGeneral/shipper/"+this.dateIssueforCbShipper+"/"+this.custIdforCbShipper+"/customerId={query}");
      this.cbBlPartyShipper.setValue(this.model.blShipperId);
    }

    /*
     if (this.model.blConsigneeId != null && this.model.blConsigneeId !== '' && this.model.blConsigneeId !== undefined) {
     this.cbBlPartyConsignee.setUrl(this.configService.config.BASE_API.toString() +
     '/MasterCustomers/findByComboBoxControlConsignee/customerId={query}');
     this.cbBlPartyConsignee.setValue(this.model.blConsigneeId,function(){
     self.cbBlPartyConsigneeAddress.setUrl(self.configService.config.BASE_API.toString() +
     '/MasterCustomers/findCustomerAddressByCustomerId/' + self.model.blConsigneeId.trim());
     self.cbBlPartyConsigneeAddress.loadSelect(function(){
     self.cbBlPartyConsigneeAddress.setValue(String(self.model.blConsigneeAddressOption));
     self.txtBlPartyConsigneeAddress.setValue(self.model.blConsigneeAddressText);
     })
     });
     }  else {
     if (this.model.blConsigneeAddressText !== '') {
     this.txtBlPartyConsigneeAddress.setValue(this.model.blConsigneeAddressText);
     }
     }
     */
    if (
      this.model.blConsigneeId != null &&
      this.model.blConsigneeId !== undefined &&
      this.model.blConsigneeId !== ""
    ) {
      this.cbBlPartyConsignee.setForceValue(this.model.blConsigneeName);
      this.cbBlPartyConsigneeAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blConsigneeId.trim()
      );
      this.cbBlPartyConsigneeAddress.loadSelect(function () {
        self.cbBlPartyConsigneeAddress.setValue(
          String(self.model.blConsigneeAddressOption).toString() === "0"
            ? ""
            : String(self.model.blConsigneeAddressOption).toString()
        );
      });
      this.txtBlPartyConsigneeAddress.setValue(
        this.model.blConsigneeAddressText
      );
    } else {
      this.cbBlPartyConsignee.setForceValue("");
      //this.cbBlPartyConsignee.setUrl('');
      // this.cbBlPartyConsignee.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterCustomers/findByComboBoxControlConsignee/customerId={query}"
      // );

      this.cbBlPartyConsignee.setUrl(this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/consignee/"+this.dateIssueforCbShipper+"/"+this.cneeIdforCbCnee+"/customerId={query}");
      this.cbBlPartyConsignee.loadSelect();
      if (this.model.blConsigneeAddressText != "") {
        this.txtBlPartyConsigneeAddress.setValue(
          this.model.blConsigneeAddressText
        );
      } else {
        this.txtBlPartyConsigneeAddress.setValue("");
      }
    }

    /*
     if (this.model.blNotifyPartyId != null && this.model.blNotifyPartyId !== '' && this.model.blNotifyPartyId !== undefined) {
     this.cbBlPartyNotifyParty.setUrl(this.configService.config.BASE_API.toString() +
     '/MasterCustomers/findByComboBoxControlNotifyParty/customerId={query}');
     this.cbBlPartyNotifyParty.setValue(this.model.blNotifyPartyId, function(){
     self.cbBlPartyNotifyPartyAddress.setUrl(self.configService.config.BASE_API.toString() +
     '/MasterCustomers/findCustomerAddressByCustomerId/' + self.model.blNotifyPartyId.trim());
     self.cbBlPartyNotifyPartyAddress.loadSelect(function() {
     self.cbBlPartyNotifyPartyAddress.setValue(String(self.model.blNotifyPartyAddressOption));
     self.txtBlPartyNotifyPartyAddress.setValue(self.model.blNotifyPartyAddressText);
     });
     });
     } else {
     if (this.model.blNotifyPartyAddressText !== '') {
     this.txtBlPartyNotifyPartyAddress.setValue(this.model.blNotifyPartyAddressText);
     }
     }
     */
    if (
      this.model.blNotifyPartyId != null &&
      this.model.blNotifyPartyId !== undefined &&
      this.model.blNotifyPartyId !== ""
    ) {
      this.cbBlPartyNotifyParty.setForceValue(this.model.blNotifyPartyName);
      this.cbBlPartyNotifyPartyAddress.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blNotifyPartyId.trim()
      );
      this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
        self.cbBlPartyNotifyPartyAddress.setValue(
          String(self.model.blNotifyPartyAddressOption).toString() === "0"
            ? ""
            : String(self.model.blNotifyPartyAddressOption).toString()
        );
      });
      this.txtBlPartyNotifyPartyAddress.setValue(
        this.model.blNotifyPartyAddressText
      );
    } else {
      this.cbBlPartyNotifyParty.setForceValue("");
      // this.cbBlPartyNotifyParty.setUrl('');
      // this.cbBlPartyNotifyParty.setUrl(
      //   this.configService.config.BASE_API.toString() +
      //     "/MasterCustomers/findByComboBoxControlNotifyParty/customerId={query}"
      // );

      this.cbBlPartyNotifyParty.setUrl(this.configService.config.BASE_API.toString() +
    "/MasterCustomers/findByComboBoxControlGeneral/notify_party/"+this.dateIssueforCbShipper+"/"+this.partyIdforCbParty+"/customerId={query}");

      this.cbBlPartyNotifyParty.loadSelect();

      this.cbBlPartyNotifyPartyAddress.loadSelect();
      if (this.model.blNotifyPartyAddressText != "") {
        this.txtBlPartyNotifyPartyAddress.setValue(
          this.model.blNotifyPartyAddressText
        );
      } else {
        this.txtBlPartyNotifyPartyAddress.setValue("");
      }
    }

    // place
    this.cbBlPlaceReceiptType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
    );
    this.cbBlPlaceReceiptType.setValue(this.model.blReceiptTypeCode);
    this.cbBlPlaceDeliveryType.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
    );
    this.cbBlPlaceDeliveryType.setValue(this.model.blDeliveryTypeCode);

    // this.cbBlPlacePOR.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePOR.setValue(this.model.blPlaceOfReceiptCode, function() {
    //  self.model.blPlaceOfReceiptPrint = data.blData.blPlaceOfReceiptPrint;
    // });
    this.cbBlPlacePOR.setForceValue(this.model.blPlaceOfReceiptName);

    // this.cbBlPlacePOL.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePOL.setValue(this.model.blLoadPortCode, function() {
    //    self.model.blLoadPortPrint = data.blData.blLoadPortPrint;
    // });
    this.cbBlPlacePOL.setForceValue(this.model.blLoadPortName);

    // this.cbBlPlacePOD.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePOD.setValue(this.model.blDischargePortCode, function() {
    //  self.model.blDischargePortPrint = data.blData.blDischargePortPrint;
    // });
    this.cbBlPlacePOD.setForceValue(this.model.blDischargePortName);

    // this.cbBlPlacePODL.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePODL.setValue(this.model.blPlaceOfDeliveryCode, function() {
    //  self.model.blPlaceOfDeliveryPrint = data.blData.blPlaceOfDeliveryPrint;
    // });
    this.cbBlPlacePODL.setForceValue(this.model.blPlaceOfDeliveryName);

    // this.cbBlPlacePOT1.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePOT1.setValue(this.model.blPortOfTransshipment1Code);
    this.cbBlPlacePOT1.setForceValue(this.model.blPortOfTransshipment1Name);
    // this.cbBlPlacePOT2.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlacePOT2.setValue(this.model.blPortOfTransshipment2Code);
    this.cbBlPlacePOT2.setForceValue(this.model.blPortOfTransshipment2Name);
    // this.cbBlPlacePOT3.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    /// this.cbBlPlacePOT3.setValue(this.model.blPortOfTransshipment3Code);
    this.cbBlPlacePOT3.setForceValue(this.model.blPortOfTransshipment3Name);
    // this.cbBlPlaceFDEST.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    // this.cbBlPlaceFDEST.setValue(this.model.blFinalDestinationCode, function() {
    //  self.model.blFinalDestinationPrint = data.blData.blFinalDestinationPrint;
    // });
    this.cbBlPlaceFDEST.setForceValue(this.model.blFinalDestinationName);

    this.cbBlPlacePrincipal.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPrincipals/findByComboBoxControlPrincipal/principalId={query}"
    );
    this.cbBlPlacePrincipal.setValue(this.model.blPrincipalId);

    if (
      this.model.blPreVesselId != null &&
      this.model.blPreVesselId !== "" &&
      this.model.blPreVesselId !== undefined
    ) {
      this.cbBlPlacePreVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselId={query}"
      );
      this.cbBlPlacePreVessel.setValue(this.model.blPreVesselId);
      //gos-263
      //this.model.blPreVesselSkipCheck = true;
    }

    this.cbBlPlaceOceanVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselId={query}"
    );
    this.cbBlPlaceOceanVessel.setValue(this.model.blOceanVesselId, function () {
      self.cbBlPlaceOceanVoyage.setUrl(
        self.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          self.model.blOceanVesselId +
          "/" +
          self.model.blOceanVesselBound.toUpperCase() +
          "/{query}/" +
          self.cookieService.getDefaultLocationCode()
      );
      //self.cbBlPlaceOceanVoyage.setValue(self.model.blOceanVesselVoyage);
      self.cbBlPlaceOceanVoyage.setForceValue(self.model.blOceanVesselVoyage);
      // self.cbBlPlaceOceanVessel.setUrl(self.configService.config.BASE_API.toString() +
      // '/MasterVessels/findByComboBoxControl/vesselName={query}');
    });

    // set value for place of issue
    // this.cbBlNettChargePlaceOfIssue.setUrl(this.configService.config.BASE_API.toString() +
    //   '/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/locationCode={query}');
    // this.cbBlNettChargePlaceOfIssue.setValue(this.model.blPlaceOfIssueCode);
    //this.cbBlNettChargePlaceOfIssue.setUrl(this.configService.config.BASE_API.toString() +
    //  '/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminalPoI/' + this.cookieService.getDefaultLocationCode() + '/locationCode={query}');
    this.cbBlNettChargePlaceOfIssue.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationCode={query}"
    );
    this.cbBlNettChargePlaceOfIssue.setValue(this.model.blPlaceOfIssueCode);

    this.cbBlNettChargePlaceOfIssueConsole.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/locationCode={query}"
    );
    this.cbBlNettChargePlaceOfIssueConsole.setValue(
      this.model.blPlaceOfIssueCodeConsole
    );

    if (
      this.model.blNextVesselId != null &&
      this.model.blNextVesselId !== "" &&
      this.model.blNextVesselId !== undefined
    ) {
      this.cbBlPlaceNextVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselId={query}"
      );
      this.cbBlPlaceNextVessel.setValue(this.model.blNextVesselId);
      //gos-263
      //this.model.blNextVesselSkipCheck = true;
    }

    //  console.log('Debug prepaid at 1 '+ this.model.blPrepaidAt1Code);
    // if (this.model.blPrepaidAt1Code != null && this.model.blPrepaidAt1Code !== '' && this.model.blPrepaidAt1Code !== undefined) {
    //  this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
    //    '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //  this.cbBlNettChargePrepaid1.setValue(this.model.blPrepaidAt1Code);
    //}
    this.cbBlNettChargePrepaid1.setForceValue(this.model.blPrepaidAt1Name);

    //  console.log('Debug prepaid at 2 ' + this.model.blPrepaidAt2Code)
    // if (this.model.blPrepaidAt2Code != null && this.model.blPrepaidAt2Code !== '' && this.model.blPrepaidAt2Code !== undefined) {
    //   this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //   this.cbBlNettChargePrepaid2.setValue(this.model.blPrepaidAt2Code);
    // }
    this.cbBlNettChargePrepaid2.setForceValue(this.model.blPrepaidAt2Name);

    //  console.log('Debug prepaid at 3 ' + this.model.blPrepaidAt3Code)
    // if (this.model.blPrepaidAt3Code != null && this.model.blPrepaidAt3Code !== '' && this.model.blPrepaidAt3Code !== undefined) {
    //   this.cbBlNettChargePrepaid3.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //  this.cbBlNettChargePrepaid3.setValue(this.model.blPrepaidAt3Code);
    // }
    this.cbBlNettChargePrepaid3.setForceValue(this.model.blPrepaidAt3Name);

    //  console.log('Debug payable at 1 '+ this.model.blPayableAt1Code);
    // if (this.model.blPayableAt1Code != null && this.model.blPayableAt1Code !== '' && this.model.blPayableAt1Code !== undefined) {
    //   this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //   this.cbBlNettChargePayableAt1.setValue(this.model.blPayableAt1Code);
    // }
    this.cbBlNettChargePayableAt1.setForceValue(this.model.blPayableAt1Name);

    //  console.log('Debug payable at 2 ' + this.model.blPayableAt2Code)
    // if (this.model.blPayableAt2Code != null && this.model.blPayableAt2Code !== '' && this.model.blPayableAt2Code !== undefined) {
    //   this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //   this.cbBlNettChargePayableAt2.setValue(this.model.blPayableAt2Code);
    // }
    this.cbBlNettChargePayableAt2.setForceValue(this.model.blPayableAt2Name);

    //  console.log('Debug payable at 3 ' + this.model.blPayableAt3Code)
    // if (this.model.blPayableAt3Code != null && this.model.blPayableAt3Code !== '' && this.model.blPayableAt3Code !== undefined) {
    //   this.cbBlNettChargePayableAt3.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //   this.cbBlNettChargePayableAt3.setValue(this.model.blPayableAt3Code);
    // }
    this.cbBlNettChargePayableAt3.setForceValue(this.model.blPayableAt3Name);

    //  console.log('Debug Billing Party 1 '+ this.model.blPayer1Code);
    // if (this.model.blPayer1Code != null && this.model.blPayer1Code !== '' && this.model.blPayer1Code !== undefined) {
    //   this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
    //   this.cbBlNettChargeBillingParty1.setValue(this.model.blPayer1Code);
    // }
    this.cbBlNettChargeBillingParty1.setForceValue(this.model.blPayer1Name);
    console.log("kalau booking nya ada : "+this.model.blBookingNo);
    console.log("Payer1Code nya : "+this.model.blPayer1Code);

    if (
      this.model.blPayer1Code != null &&
      this.model.blPayer1Code !== undefined &&
      this.model.blPayer1Code !== ""
    ) {
      this.IsLockPayer1 = false;
      this.cbBlNettChargeBillingParty1Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blPayer1Code.trim() + "/Y"
      );
      console.log("BAKEKOK1 : " + String(this.model.blExtraPayer1nameAddressOption).toString());
      this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
        self.cbBlNettChargeBillingParty1Address.setValue(
          String(self.model.blExtraPayer1nameAddressOption).toString() === "0"
            ? ""
            : String(self.model.blExtraPayer1nameAddressOption).toString()+". "+String(self.model.blExtraPayer1nameAddressName).toString()
        );
      });

    }else{
      console.log("BAKEKOK2");
      this.IsLockPayer1 = true;
      this.cbBlNettChargeBillingParty1Address.listStore.store = [];
      this.cbBlNettChargeBillingParty1Address.loadSelect();
      this.cbBlNettChargeBillingParty1Address.setValue("");
    }
    /*this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
      self.cbBlNettChargeBillingParty1Address.setValue(
        String(self.model.blBookingNo).toString() != ""?
        String(self.model.blExtraPayer1nameAddressOption).toString() === "0"
          ? ""
          : String(self.model.blExtraPayer1nameAddressOption).toString():(
          String(self.model.blExtraPayer1nameAddressOption).toString() === "0"
            ? ""
            : String(self.model.blExtraPayer1nameAddressOption).toString()
        )
      );
    });*/

    //  console.log('Debug Billing Party 2 '+ this.model.blPayer2Code);
    // if (this.model.blPayer2Code != null && this.model.blPayer2Code !== '' && this.model.blPayer2Code !== undefined) {
    //   this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
    //   this.cbBlNettChargeBillingParty2.setValue(this.model.blPayer2Code);
    // }
    this.cbBlNettChargeBillingParty2.setForceValue(this.model.blPayer2Name);
    if (
      this.model.blPayer2Code != null &&
      this.model.blPayer2Code !== undefined &&
      this.model.blPayer2Code !== ""
    ) {
      this.IsLockPayer2 = false;
      this.cbBlNettChargeBillingParty2Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blPayer2Code.trim() + "/Y"
      );
      this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
        self.cbBlNettChargeBillingParty2Address.setValue(
          String(self.model.blExtraPayer2nameAddressOption).toString() === "0"
            ? ""
            : String(self.model.blExtraPayer2nameAddressOption).toString()+". "+String(self.model.blExtraPayer2nameAddressName).toString()
        );
      });

    } else{
      this.IsLockPayer2 = true;
      this.cbBlNettChargeBillingParty2Address.listStore.store = [];
      this.cbBlNettChargeBillingParty2Address.loadSelect();
      this.cbBlNettChargeBillingParty2Address.setValue("");
    }

    //  console.log('Debug Billing Party 3 '+ this.model.blPayer3Code);
    // if (this.model.blPayer3Code != null && this.model.blPayer3Code !== '' && this.model.blPayer3Code !== undefined) {
    //   this.cbBlNettChargeBillingParty3.setUrl(this.configService.config.BASE_API.toString() +
    //     '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
    //  this.cbBlNettChargeBillingParty3.setValue(this.model.blPayer3Code);
    // }
    this.cbBlNettChargeBillingParty3.setForceValue(this.model.blPayer3Name);
    if (
      this.model.blPayer3Code != null &&
      this.model.blPayer3Code !== undefined &&
      this.model.blPayer3Code !== ""
    ) {
      this.IsLockPayer3 = false;
      this.cbBlNettChargeBillingParty3Address.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findCustomerAddressByCustomerId/" +
          this.model.blPayer3Code.trim() + "/Y"
      );
      this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
        self.cbBlNettChargeBillingParty3Address.setValue(
          String(self.model.blExtraPayer3nameAddressOption).toString() === "0"
            ? ""
            : String(self.model.blExtraPayer3nameAddressOption).toString()+". "+String(self.model.blExtraPayer3nameAddressName).toString()
        );
      });

    }  else{
      this.IsLockPayer3 = true;
      this.cbBlNettChargeBillingParty3Address.listStore.store = [];
      this.cbBlNettChargeBillingParty3Address.loadSelect();
      this.cbBlNettChargeBillingParty3Address.setValue("");
    }

    if (this.model.blIsPrintAgentInfoOnBl === true) {
      this.cbBlDescAgentId.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findAgencyByLocationCode/" +
          this.model.blDischargePortCode.trim()
      );

       if (this.site.startsWith("CN"))   //added by No No to set agentIDseq default //changed implementation from CNSHA to be CN%
      {
        console.log ("here is agency " )

        this.genericService.GET(this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findAgencyByLocationCode/"
        + this.model.blDischargePortCode.trim()).subscribe(
          (resp) => {
            let dtx: any[];

              dtx = resp.json()["content"];
              this.cbBlDescAgentId.loadSelect(() => {
                // Set default value to 1
                //this.cbBlDescAgentId.setValue("1");
                this.cbBlDescAgentId.setValue(dtx[0]["agencySeqNo"])
              });
                if (
                  dtx[0]["agencyId"] != null &&
                  dtx[0]["agencyId"] !== "" &&
                  dtx[0]["agencyId"] !== undefined
                ) {
                  let theName = dtx[0]["agencyCustomerDisplayName"] + "\n\n";
                  theName = theName + dtx[0]["agencyFullAddress"];
                  if (
                    dtx[0]["agencyTelNo"] != null &&
                    dtx[0]["agencyTelNo"] !== "" &&
                    dtx[0]["agencyTelNo"] !== undefined
                  ) {
                    theName = theName + "\nTELP: " +  dtx[0]["agencyTelNo"];
                  }
                  if (
                     dtx[0]["agencyFax1"] != null &&
                     dtx[0]["agencyFax1"] !== "" &&
                     dtx[0]["agencyFax1"] !== undefined
                  ) {
                    theName = theName + "\nFAX1: " +  dtx[0]["agencyFax1"];
                  }

                  if (
                     dtx[0]["agencyEmail"] != null &&
                     dtx[0]["agencyEmail"] !== "" &&
                     dtx[0]["agencyEmail"] !== undefined
                  ) {
                    theName = theName + "\nEMAIL: " + dtx[0]["agencyEmail"] ;
                  }

                  this.model.blAgentAddressId = dtx[0]["agencySeqNo"];

                  this.txtBlDescAgentNameAddress.setValue(theName);
                  console.log(this.txtBlDescAgentNameAddress);
                  console.log(theName);
                  this.model.blDescAgentRemark = theName;
                }
            });

         // self.txtBlDescAgentNameAddress.setValue(dtx[0]["agencySeqNo"])


             /* if (resp.length > 0) {
              console.log("here is Agency")
                const defaultAgency = resp.content[0]; // Assuming the first item as default
                console.log ("agent content :", defaultAgency);
                this.cbBlDescAgentId.setValue(defaultAgency.agencySeqNo); // Set the default value
            } */

          } else{



        this.cbBlDescAgentId.loadSelect(function () {

          self.cbBlDescAgentId.setValue(self.model.blAgentAddressId);
          self.txtBlDescAgentNameAddress.setValue(self.model.blDescAgentRemark);
        });
      }
    }


    // set bl remarks
    this.txtBlNettChargeRemarks.setValue(this.model.blRemarks);

    // set bl remarks console
    this.txtBlNettChargeRemarksConsole.setValue(this.model.blRemarksConsole);

    this.txtBlNettChargeNewRemarks.setValue(this.model.blNewRemarks);

    // populate container
    if (data.blContainer.length > 0) {
      this.gridBlContainer.url = "";
      this.gridBlContainer.onClear();
      console.log("length container >> " + data.blContainer.length);
      for (let i = 0; i < data.blContainer.length; i++) {
        console.log("parsing container " + i);
        const dataContainer = new BLContainer();

        // BL Container Extra
        if(data.blContainerExtra != undefined){
          if (data.blContainerExtra.length > 0) {
            dataContainer.blContainerExtra = data.blContainerExtra[i];
          }
        }

        dataContainer.blContainerOfficeCode =
          data.blContainer[i].blContainerOfficeCode;
        dataContainer.blContainerBlNo = data.blContainer[i].blContainerBlNo;
        dataContainer.blContainerSeq = data.blContainer[i].blContainerSeq;
        dataContainer.blContainerNumber = data.blContainer[i].blContainerNumber;
        dataContainer.blContainerSealNo1 =
          data.blContainer[i].blContainerSealNo1;
        dataContainer.blContainerSealNo2 =
          data.blContainer[i].blContainerSealNo2;
        dataContainer.blContainerSealNo3 =
          data.blContainer[i].blContainerSealNo3;
        dataContainer.blContainerQuantity =
          data.blContainer[i].blContainerQuantity;
        dataContainer.blContainerPackageTypeCode =
          data.blContainer[i].blContainerPackageTypeCode;
        dataContainer.blContainerPackageTypeName =
          data.blContainer[i].blContainerPackageTypeName;
        dataContainer.blContainerGrossWeight = data.blContainer[i].blContainerGrossWeight;
        dataContainer.blContainerGrossWeightString = data.blContainer[i].blContainerGrossWeightString ? data.blContainer[i].blContainerGrossWeightString : data.blContainer[i].blContainerGrossWeight;
        dataContainer.blContainerGrossMeasure = data.blContainer[i].blContainerGrossMeasure;
        dataContainer.blContainerGrossMeasureString = data.blContainer[i].blContainerGrossMeasureString ? data.blContainer[i].blContainerGrossMeasureString : data.blContainer[i].blContainerGrossMeasure;

        dataContainer.blContainerNettWeight = data.blContainer[i].blContainerNettWeight;
        dataContainer.blContainerNettWeightString = data.blContainer[i].blContainerNettWeightString ? data.blContainer[i].blContainerNettWeightString : data.blContainer[i].blContainerNettWeight;
        dataContainer.blContainerNettMeasure = data.blContainer[i].blContainerNettMeasure;
        dataContainer.blContainerNettMeasureString = data.blContainer[i].blContainerNettMeasureString ? data.blContainer[i].blContainerNettMeasureString : data.blContainer[i].blContainerNettMeasure;

        dataContainer.blContainerReceiptTypeCode =
          data.blContainer[i].blContainerReceiptTypeCode;
        dataContainer.blContainerDeliveryTypeCode =
          data.blContainer[i].blContainerDeliveryTypeCode;
        dataContainer.blContainerQ1 = data.blContainer[i].blContainerQ1;
        dataContainer.blContainerQ2 = data.blContainer[i].blContainerQ2;
        dataContainer.blContainerQ3 = data.blContainer[i].blContainerQ3;
        dataContainer.blContainerType = data.blContainer[i].blContainerType;
        switch (dataContainer.blContainerType) {
          case "STD": {
            dataContainer.blContainerTypeName = "NORMAL";
            break;
          }
          case "REF": {
            dataContainer.blContainerTypeName = "REEFER";
            break;
          }
          case "DRY": {
            dataContainer.blContainerTypeName = "REEFER DRY";
            break;
          }
          case "OPT": {
            dataContainer.blContainerTypeName = "OPEN TOP";
            break;
          }
          case "FLR": {
            dataContainer.blContainerTypeName = "FLATRACK";
            break;
          }
          case "PLT": {
            dataContainer.blContainerTypeName = "PLATFORM";
            break;
          }
          case "TNK": {
            dataContainer.blContainerTypeName = "TANK";
            break;
          }
          case "UC": {
            dataContainer.blContainerTypeName = "UC";
            break;
          }

          default: {
            dataContainer.blContainerTypeName = "";
            break;
          }
        }

        dataContainer.blContainerSize = data.blContainer[i].blContainerSize;
        dataContainer.blContainerSizeName = data.blContainer[i].blContainerSize;
        dataContainer.blContainerHeight = data.blContainer[i].blContainerHeight;
        dataContainer.blContainerHeightName =
          data.blContainer[i].blContainerHeight;
        dataContainer.blContainerIsEmpty =
          data.blContainer[i].blContainerIsEmpty;
        dataContainer.blContainerIsEmptyString =
          data.blContainer[i].blContainerIsEmptyString;
        //        if (this.model.blContainerType === 'DRY'){
        if (dataContainer.blContainerType === "DRY") {
          dataContainer.blContainerIsDry = true;
          dataContainer.blContainerIsDryString = "Y";
        } else {
          dataContainer.blContainerIsDry = false;
          dataContainer.blContainerIsDryString = "N";
        }

        if (
          dataContainer.blContainerIsDry === true &&
          dataContainer.blContainerType === "DRY"
        ) {
          dataContainer.blContainerTypeName = "REEFER";
        }
        dataContainer.blContainerSkipCheck =
          data.blContainer[i].blContainerSkipCheck;
        dataContainer.blContainerShortShipContainer =
          data.blContainer[i].blContainerShortShipContainer;
        dataContainer.blContainerShortShipContainerString =
          data.blContainer[i].blContainerShortShipContainerString;
        dataContainer.blContainerLotNo = data.blContainer[i].blContainerLotNo;
        dataContainer.blContainerCreatedUserid =
          data.blContainer[i].blContainerCreatedUserid;
        dataContainer.blContainerCreatedDate =
          data.blContainer[i].blContainerCreatedDate;
        dataContainer.blContainerModifiedUserid =
          data.blContainer[i].blContainerModifiedUserid;
        dataContainer.blContainerModifiedDate =
          data.blContainer[i].blContainerModifiedDate;

        dataContainer.blContainerDGClass =
          data.blContainer[i].blContainerDGClass;
        dataContainer.blContainerIMO = data.blContainer[i].blContainerIMO;
        dataContainer.blContainerUNNO = data.blContainer[i].blContainerUNNO;
        dataContainer.blContainerFlashPointTemperature =
          data.blContainer[i].blContainerFlashPointTemperature;
        dataContainer.blContainerFlashPointScale =
          data.blContainer[i].blContainerFlashPointScale;
        dataContainer.blContainerReeferTemperature =
          data.blContainer[i].blContainerReeferTemperature;
        dataContainer.blContainerReeferScale =
          data.blContainer[i].blContainerReeferScale;
        dataContainer.blContainerOogOH = Number.parseFloat(
          data.blContainer[i].blContainerOogOH
        );
        dataContainer.blContainerOogOWL = Number.parseFloat(
          data.blContainer[i].blContainerOogOWL
        );
        dataContainer.blContainerOogOWR = Number.parseFloat(
          data.blContainer[i].blContainerOogOWR
        );
        dataContainer.blContainerOogOLF = Number.parseFloat(
          data.blContainer[i].blContainerOogOLF
        );
        dataContainer.blContainerOogOLB = Number.parseFloat(
          data.blContainer[i].blContainerOogOLB
        );
        dataContainer.blContainerOogScale =
          data.blContainer[i].blContainerOogScale;

        dataContainer.blContainerIsPrintShortShipmentInfoOnBL =
          data.blContainer[i].blContainerIsPrintShortShipmentInfoOnBL;
        dataContainer.blContainerShortShipmentFrom =
          data.blContainer[i].blContainerShortShipmentFrom;
        dataContainer.blContainerShortShipmentTo =
          data.blContainer[i].blContainerShortShipmentTo;
        dataContainer.blContainerShortShipmentBlRef =
          data.blContainer[i].blContainerShortShipmentBlRef;
        dataContainer.blContainerShortShipmentVesselId =
          data.blContainer[i].blContainerShortShipmentVesselId;
        dataContainer.blContainerShortShipmentVesselCode =
          data.blContainer[i].blContainerShortShipmentVesselCode;
        dataContainer.blContainerShortShipmentVesselVoyage =
          data.blContainer[i].blContainerShortShipmentVesselVoyage;
        dataContainer.blContainerShortShipmentVesselBound =
          data.blContainer[i].blContainerShortShipmentVesselBound;
        dataContainer.blContainerShortShipmentVesselName =
          data.blContainer[i].blContainerShortShipmentVesselName;

        // GSO-992 & GSO-993
        dataContainer.blContainerIsPrintReworkedInfoOnBL = data.blContainer[i].blContainerIsPrintReworkedInfoOnBL;
        dataContainer.blContainerReworkedTo = data.blContainer[i].blContainerReworkedTo;
        dataContainer.blContainerReworkedFrom = data.blContainer[i].blContainerReworkedFrom;
        dataContainer.blContainerIsReworkedContainer = data.blContainer[i].blContainerIsReworkedContainer;
        dataContainer.blContainerReworkedContainerNumber = data.blContainer[i].blContainerReworkedContainerNumber;
        dataContainer.blContainerReworkedBlRef = data.blContainer[i].blContainerReworkedBlRef;
        dataContainer.blContainerReworkedVesselId = data.blContainer[i].blContainerReworkedVesselId;
        dataContainer.blContainerReworkedVesselCode = data.blContainer[i].blContainerReworkedVesselCode;
        dataContainer.blContainerReworkedVesselName = data.blContainer[i].blContainerReworkedVesselName;
        dataContainer.blContainerReworkedVesselVoyage = data.blContainer[i].blContainerReworkedVesselVoyage;
        dataContainer.blContainerReworkedBound = data.blContainer[i].blContainerReworkedBound;

        dataContainer.blContainerSpecialDetailCode =
          data.blContainer[i].blContainerSpecialDetailCode;
        dataContainer.blContainerSpecialDetailName =
          data.blContainer[i].blContainerSpecialDetailName;

        dataContainer.blContainerVgm = data.blContainer[i].blContainerVgm;

        dataContainer["blContainerSpecialTypeName"] =
          data.blContainer[i]["blContainerSpecialTypeName"];

        if (
          data.blContainerHSCode != null &&
          data.blContainerHSCode !== undefined &&
          data.blContainerHSCode.length > 0
        ) {
          for (let j = 0; j < data.blContainerHSCode.length; j++) {
            // console.log("cek hs code");
            // console.log(
            //   data.blContainerHSCode[j].blHsOfficeCode +
            //     " vs " +
            //     dataContainer.blContainerOfficeCode
            // );
            // console.log(
            //   data.blContainerHSCode[j].blHsBlNo +
            //     " vs " +
            //     dataContainer.blContainerBlNo
            // );
            // console.log(
            //   data.blContainerHSCode[j].blHsContainerSeq +
            //     " vs " +
            //     dataContainer.blContainerSeq
            // );
            // console.log(
            //   data.blContainerHSCode[j].blHsContainerNumber +
            //     " vs " +
            //     dataContainer.blContainerNumber
            // );

            if (
              data.blContainerHSCode[j].blHsOfficeCode ===
                dataContainer.blContainerOfficeCode &&
              data.blContainerHSCode[j].blHsBlNo ===
                dataContainer.blContainerBlNo &&
              data.blContainerHSCode[j].blHsContainerSeq ===
                dataContainer.blContainerSeq &&
              data.blContainerHSCode[j].blHsContainerNumber ===
                dataContainer.blContainerNumber
            ) {
              console.log("masuk hs code");
              const dataHSCode = new BLContainerHSCode();
              dataHSCode.blContainerHSOfficeCode =
                data.blContainerHSCode[j].blHsOfficeCode;
              dataHSCode.blContainerHSBlNo = data.blContainerHSCode[j].blHsBlNo;
              dataHSCode.blContainerHSContainerSeq =
                data.blContainerHSCode[j].blHsContainerSeq;
              dataHSCode.blContainerHSContainerNumber =
                data.blContainerHSCode[j].blHsContainerNumber;
              dataHSCode.blContainerHSSeq = data.blContainerHSCode[j].blHsSeq;
              dataHSCode.blContainerHSCode = data.blContainerHSCode[j].blHsCode;
              dataHSCode.blContainerHSDescription =
                data.blContainerHSCode[j].blHsName;
              dataHSCode.blContainerHSPrintString =
                data.blContainerHSCode[j].blHsIsPrint === true ? "Y" : "N";
              dataHSCode.blContainerHSPrint =
                data.blContainerHSCode[j].blHsIsPrint;

              dataContainer.blContainerHSCode.push(dataHSCode);
            }
          }
        }

        // ADDITIONAL DETAIL CONTAINER
        if (
          data.blAdContainer != null &&
          data.blAdContainer !== undefined &&
          data.blAdContainer.length > 0
        ) {
          for (let k = 0; k < data.blAdContainer.length; k++) {
              console.log(data.blAdContainer[k].blContainerOfficeCode + " = " + dataContainer.blContainerOfficeCode)
              console.log(data.blAdContainer[k].blContainerBlNo + " = " + dataContainer.blContainerBlNo)
              console.log(data.blAdContainer[k].blContainerSeq + " = " + dataContainer.blContainerSeq)
              console.log(data.blAdContainer[k].blContainerNumber + " = " + dataContainer.blContainerNumber)

            if (
              data.blAdContainer[k].blContainerOfficeCode === dataContainer.blContainerOfficeCode &&
              data.blAdContainer[k].blContainerBlNo === dataContainer.blContainerBlNo &&
              data.blAdContainer[k].blContainerSeq === dataContainer.blContainerSeq &&
              data.blAdContainer[k].blContainerNumber === dataContainer.blContainerNumber
            ) {
              const dataAd = new BLAdContainer();
              dataAd.blContainerOfficeCode      = data.blAdContainer[k].blContainerOfficeCode;
              dataAd.blContainerBlNo            = data.blAdContainer[k].blContainerBlNo;
              dataAd.blContainerSeq    = data.blAdContainer[k].blContainerSeq;
              dataAd.blContainerNumber = data.blAdContainer[k].blContainerNumber;
              dataAd.blAdContainerSeq = data.blAdContainer[k].blAdContainerSeq;
              dataAd.blAdContainerCommodityTypeId = data.blAdContainer[k].blAdContainerCommodityTypeId;
              dataAd.blAdContainerCommodityTypeName = data.blAdContainer[k].blAdContainerCommodityTypeName;
              dataAd.blAdContainerInnerQty = data.blAdContainer[k].blAdContainerInnerQty;

              dataAd.blAdContainerInnerPkgTypeCode = data.blAdContainer[k].blAdContainerInnerPkgTypeCode;
              dataAd.blAdContainerInnerPkgTypeName = data.blAdContainer[k].blAdContainerInnerPkgTypeName;
              dataAd.blAdContainerDGClassCode  = data.blAdContainer[k].blAdContainerDGClassCode;
              dataAd.blAdContainerDGClassName = data.blAdContainer[k].blAdContainerDGClassName;
              dataAd.blAdContainerIMO = data.blAdContainer[k].blAdContainerIMO;
              dataAd.blAdContainerUNNO = data.blAdContainer[k].blAdContainerUNNO;
              dataAd.blAdContainerFlashPoint = data.blAdContainer[k].blAdContainerFlashPoint;
              dataAd.blAdContainerFlashPointScale = data.blAdContainer[k].blAdContainerFlashPointScale;

              dataAd.blAdContainerGrossWeight = data.blAdContainer[k].blAdContainerGrossWeight;
              dataAd.blAdContainerGrossWeightString = data.blAdContainer[k].blAdContainerGrossWeightString;
              dataAd.blAdContainerGrossMeasure = data.blAdContainer[k].blAdContainerGrossMeasure;
              dataAd.blAdContainerGrossMeasureString = data.blAdContainer[k].blAdContainerGrossMeasureString;
              dataAd.blAdContainerHsCode = data.blAdContainer[k].blAdContainerHsCode;
              dataAd.blAdContainerHsName = data.blAdContainer[k].blAdContainerHsName;
              dataAd.blAdContainerHsRemarks = data.blAdContainer[k].blAdContainerHsRemarks;

              // dataAd.blContainerHSSeq             = data.blAdContainer[k].blHsSeq;
              // dataAd.blAdContainer                = data.blAdContainer[k].blHsCode;
              // dataAd.blContainerHSDescription     = data.blAdContainer[k].blHsName;
              // dataAd.blContainerHSPrintString     = data.blAdContainer[k].blHsIsPrint === true ? "Y" : "N";
              // dataAd.blContainerHSPrint           = data.blAdContainer[k].blHsIsPrint;

              dataContainer.blAdContainer.push(dataAd);
            }
          }
        }
        this.gridBlContainer.listStore.store.push(dataContainer);
        //this.storeContainer.push(dataContainer)
      }

      this.gridBlContainer.loadData();
    }

    //  populate mark number
    if (data.blMarkNo !== undefined && data.blMarkNo.length > 0) {
      this.gridBlMarkNo.url = "";
      this.gridBlMarkNo.onClear();
      for (let i = 0; i < data.blMarkNo.length; i++) {
        const dataMarkNo = new BLMarkNo();
        dataMarkNo.blMarkNoOfficeCode = data.blMarkNo[i].blMarkNoOfficeCode;
        dataMarkNo.blMarkNoBlNo = data.blMarkNo[i].blMarkNoBlNo;
        dataMarkNo.blMarkNoSeq = data.blMarkNo[i].blMarkNoSeq;
        dataMarkNo.blMarkNoDescription = data.blMarkNo[i].blMarkNoDescription;

        let markClean: String = "";
        if (
          data.blMarkNo[i].blMarkNoDescription.length <= 1 &&
          data.blMarkNo[i].blMarkNoDescription === "↵"
        ) {
          markClean = "";
        } else {
          for (
            let j = 0;
            j < data.blMarkNo[i].blMarkNoDescription.split("↵").length;
            j++
          ) {
            if (j <= 2) {
              markClean +=
                data.blMarkNo[i].blMarkNoDescription.split("↵")[j] + "<br/>";
            }
          }
        }
        console.log("debug mark no ---> " + markClean);
        dataMarkNo.blMarkNoDescriptionClean = markClean.toString();
        dataMarkNo.blMarkNoCreatedDate = data.blMarkNo[i].blMarkNoCreatedUserid;
        dataMarkNo.blMarkNoCreatedUserid = data.blMarkNo[i].blMarkNoCreatedDate;
        dataMarkNo.blMarkNoModifiedDate =
          data.blMarkNo[i].blMarkNoModifiedUserid;
        dataMarkNo.blMarkNoModifiedUserid =
          data.blMarkNo[i].blMarkNoModifiedDate;
        this.gridBlMarkNo.listStore.store.push(dataMarkNo);
      }
      this.gridBlMarkNo.loadData();
    }

    // populate desc clause
    if (data.blDescClause !== undefined && data.blDescClause.length > 0) {
      this.gridBlDescClause.url = "";
      this.gridBlDescClause.onClear();
      for (let i = 0; i < data.blDescClause.length; i++) {
        const dataDescClause = new BLDescClause();
        dataDescClause.blDescClauseOfficeCode =
          data.blDescClause[i].blDescClauseOfficeCode;
        dataDescClause.blDescClauseBlNo = data.blDescClause[i].blDescClauseBlNo;
        dataDescClause.blDescClauseSeq = data.blDescClause[i].blDescClauseSeq;
        dataDescClause.blDescClauseId = data.blDescClause[i].blDescClauseId;
        dataDescClause.blDescClauseCode = data.blDescClause[i].blDescClauseCode;
        dataDescClause.blDescClauseName = data.blDescClause[i].blDescClauseName;
        this.gridBlDescClause.listStore.store.push(dataDescClause);
      }
      this.gridBlDescClause.loadData();
    }

    // populate desc
    if (data.blDesc !== undefined && data.blDesc.length > 0) {
      this.gridBlDesc.url = "";
      this.gridBlDesc.onClear();
      for (let i = 0; i < data.blDesc.length; i++) {
        const dataDesc = new BLDesc();
        dataDesc.blDescOfficeCode = data.blDesc[i].blDescOfficeCode;
        dataDesc.blDescBlNo = data.blDesc[i].blDescBlNo;
        dataDesc.blDescSeq = data.blDesc[i].blDescSeq;
        dataDesc.blDescInnerQuantity = data.blDesc[i].blDescInnerQuantity;
        dataDesc.blDescInnerPackageTypeCode =
          data.blDesc[i].blDescInnerPackageTypeCode;
        dataDesc.blDescInnerPackageTypeName =
          data.blDesc[i].blDescInnerPackageTypeName;
        dataDesc.blDescOuterQuantity = data.blDesc[i].blDescOuterQuantity;
        dataDesc.blDescOuterPackageTypeCode =
          data.blDesc[i].blDescOuterPackageTypeCode;
        dataDesc.blDescOuterPackageTypeName =
          data.blDesc[i].blDescOuterPackageTypeName;
        dataDesc.blDescDescriptionText = data.blDesc[i].blDescDescriptionText;
        dataDesc.blDescPsaPackageTypeCode =
          data.blDesc[i].blDescPsaPackageTypeCode;
        dataDesc.blDescPsaPackageTypeName =
          data.blDesc[i].blDescPsaPackageTypeName;

        dataDesc.blDescGrossWeight = Number.parseFloat(
          data.blDesc[i].blDescGrossWeight.toString()
        );
        dataDesc.blDescNettWeight = Number.parseFloat(
          data.blDesc[i].blDescNettWeight.toString()
        );
        dataDesc.blDescGrossMeasure = Number.parseFloat(
          data.blDesc[i].blDescGrossMeasure.toString()
        );
        this.blGrossWeightNum = Number.parseFloat(
          data.blDesc[i].blDescGrossWeight.toString()
        );
        this.blNettWeightNum = Number.parseFloat(
          data.blDesc[i].blDescNettWeight.toString()
        );
        this.blGrossMeasureNum = Number.parseFloat(
          data.blDesc[i].blDescGrossMeasure.toString()
        );

        dataDesc.blDescNettMeasure = data.blDesc[i].blDescNettMeasure;
        dataDesc.blDescNettMeasureString = this.parseDecimal(data.blDesc[i].blDescNettMeasureString);
        dataDesc.blDescReceiptTypeCode = data.blDesc[i].blDescReceiptTypeCode;
        dataDesc.blDescDeliveryTypeCode = data.blDesc[i].blDescDeliveryTypeCode;
        dataDesc.blDescCommodityTypeId = data.blDesc[i].blDescCommodityTypeId;

        dataDesc.blDescAwkward = data.blDesc[i].blDescAwkward;
        dataDesc.blDescVgm = Number(data.blDesc[i].blDescVgm);
        dataDesc.blDescDg2 = data.blDesc[i].blDescDg2;
        dataDesc.blDescDg2R = data.blDesc[i].blDescDg2R;

        dataDesc.blDescBlDutiable = data.blDesc[i].blDescBlDutiable;
        dataDesc.blDescGrossWeightLock =  data.blDesc[i].blDescGrossWeightLock === "Y" ? true : false;
        dataDesc.blDescGrossMeasLock = data.blDesc[i].blDescGrossMeasLock === "Y" ? true : false;

        dataDesc.blDescNettWeightLock = data.blDesc[i].blDescNettWeightLock === "Y" ? true : false;
        dataDesc.blDescNettMeasLock = data.blDesc[i].blDescNettMeasLock === "Y" ? true : false;

        dataDesc.blDescVgmLock = data.blDesc[i].blDescVgmLock;

        dataDesc.blDescOuterQuantityLock =
          data.blDesc[i].blDescOuterQuantityLock === "Y" ? true : false;
        dataDesc.blDescOuterPkgTypeLock =
          data.blDesc[i].blDescOuterPkgTypeLock === "Y" ? true : false;
        dataDesc.blDescDescriptionLock =
          data.blDesc[i].blDescDescriptionLock === "Y" ? true : false;

        dataDesc.blDescOuterQuantityPrint =
          data.blDesc[i].blDescOuterQuantityPrint;

        dataDesc.blDescOuterPkgTypePrint =
          data.blDesc[i].blDescOuterPkgTypePrint;

        this.chkBlDescOuterQuantityPrint =
          data.blDesc[i].blDescOuterQuantityPrint === "Y" ? true : false;

        this.chkBlDescOuterPkgTypePrint =
          data.blDesc[i].blDescOuterPkgTypePrint === "Y" ? true : false;

        dataDesc.blDescCreatedUserid = data.blDesc[i].blDescCreatedUserid;
        dataDesc.blDescCreatedDate = data.blDesc[i].blDescCreatedDate;
        dataDesc.blDescModifiedUserid = data.blDesc[i].blDescModifiedUserid;
        dataDesc.blDescModifiedDate = data.blDesc[i].blDescModifiedDate;

        dataDesc.blDescDescriptionLock = data.blDesc[i].blDescDescriptionLock === 'Y' ? true : false;


        const dataClean: any[] = String(dataDesc.blDescDescriptionText).split(
          "↵"
        );
        if (dataClean.length > 0) {
          let dtClean: String = "";
          if (dataClean[0] !== undefined) {
            dtClean += dataClean[0] + "<br/>";
          }
          if (dataClean[0] !== undefined && dataClean[1] !== undefined) {
            dtClean += dataClean[1];
          }
          dataDesc.blDescDescriptionTextClean = String(dtClean);
        }

        this.gridBlDesc.listStore.store.push(dataDesc);
      }
      this.gridBlDesc.loadData();
    }

    if (
      data.blData.blTotalContainerPackageInWord != null &&
      data.blData.blTotalContainerPackageInWord !== "" &&
      data.blData !== undefined
    ) {
      this.txtBlDescTotalContainerPackageInWord.setValue(
        data.blData.blTotalContainerPackageInWord.trim()
      );
    } else {
      this.txtBlDescTotalContainerPackageInWord.setValue("");
    }

    //  populate nett charge grid
    this.gridBlNettCharge.url = "";
    this.gridBlNettCharge.onClear();
   // console.log("date shipped on board :"+ data.blHeaderExtra.blExtraShippedOnBoard );
    console.log("lock date of issue2 : "+this.model.blDateOfIssue);

    for (let i = 0; i < data.blFreightCharge.length; i++) {
      const dataNettCharge = new BLFreightCharge();
      dataNettCharge.blFCOfficeCode = this.model.blOfficeCode;
      dataNettCharge.blFCBlNo = this.model.blNo;
      //dataNettCharge.blFCSeq = data.blFreightCharge[i].blFCSeq;
      //re-order the sequence upon retrieval (bug blf from miki 12-feb-2019
      dataNettCharge.blFCSeq = i + 1;

      dataNettCharge.blFCChargeCode = data.blFreightCharge[i].blFCChargeCode;
      dataNettCharge.blFCRevenue = data.blFreightCharge[i].blFCRevenue;
      dataNettCharge.blFCRate = data.blFreightCharge[i].blFCRate;
      dataNettCharge.blFCPerCode = data.blFreightCharge[i].blFCPerCode;
      dataNettCharge.blFCCurrencyCode =
        data.blFreightCharge[i].blFCCurrencyCode;
      dataNettCharge.blFCAmount = data.blFreightCharge[i].blFCAmount;
      dataNettCharge.blFCPc = data.blFreightCharge[i].blFCPc;
      dataNettCharge.blFCPlaceOfPaymentCode =
        data.blFreightCharge[i].blFCPlaceOfPaymentCode;

      /*
       if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt1Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPrepaidAt1Name;
       } else if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt2Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPrepaidAt2Name;
       } else if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt3Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPrepaidAt3Name;
       } else if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPayableAt1Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPayableAt1Name;
       } else if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPayableAt2Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPayableAt2Name;
       } else if (data.blFreightCharge[i].blFCPlaceOfPaymentCode === this.model.blPayableAt3Code) {
       dataNettCharge.blFCPlaceOfPaymentName = this.model.blPayableAt3Name;
       }
       */

      dataNettCharge.blFCPlaceOfPaymentName =
        data.blFreightCharge[i].blFCPlaceOfPaymentName;

      dataNettCharge.blFCPayer = data.blFreightCharge[i].blFCPayer;
      dataNettCharge.blFCContainerType =
        data.blFreightCharge[i].blFCContainerType;
      dataNettCharge.blFCIsEntered = data.blFreightCharge[i].blFCIsEntered;

      // is manifested handler
      if (data.blFreightCharge[i].blFCIsManifestedFreight === "Y") {
        dataNettCharge.blFCIsManifestedFreight = true;
        dataNettCharge.blFCIsManifestedFreightString = "Y";
      } else {
        dataNettCharge.blFCIsManifestedFreight = false;
        dataNettCharge.blFCIsManifestedFreightString = "N";
      }

      // is charge printed handler
      if (data.blFreightCharge[i].blFCIsChargePrinted === "Y") {
        dataNettCharge.blFCIsChargePrinted = true;
        dataNettCharge.blFCIsChargePrintedString = "Y";
      } else {
        dataNettCharge.blFCIsChargePrinted = false;
        dataNettCharge.blFCIsChargePrintedString = "N";
      }

      // is charge fai group
      if (data.blFreightCharge[i].blFCFaiGroup === "Y") {
        dataNettCharge.blFCFaiGroup = true;
        dataNettCharge.blFCFaiGroupString = "Y";
      } else {
        dataNettCharge.blFCFaiGroup = false;
        dataNettCharge.blFCFaiGroupString = "N";
      }

      // is entered
      if (data.blFreightCharge[i].blFCIsEntered === "Y") {
        dataNettCharge.blFCIsEntered = true;
        dataNettCharge.blFCIsEnteredString = "Y";
      } else {
        dataNettCharge.blFCIsEntered = false;
        dataNettCharge.blFCIsEnteredString = "N";
      }

      dataNettCharge.blFCFreightReference =
        data.blFreightCharge[i].blFCFreightReference;

      this.gridBlNettCharge.listStore.store.push(dataNettCharge);
    }
    this.gridBlNettCharge.loadData();

    //  populate nett charge console grid
    console.log("** data.blFreightChargeConsole **");
    console.log(data.blFreightChargeConsole);
    this.gridBlNettChargeConsole.url = "";
    this.gridBlNettChargeConsole.onClear();
    if (
      data.blFreightChargeConsole !== undefined &&
      data.blFreightChargeConsole.length > 0
    ) {
      for (let i = 0; i < data.blFreightChargeConsole.length; i++) {
        const dataNettCharge = new BLFreightCharge();
        dataNettCharge.blFCOfficeCode = this.model.blOfficeCode;
        dataNettCharge.blFCBlNo = this.model.blNo;
        //dataNettCharge.blFCSeq = data.blFreightCharge[i].blFCSeq;
        //re-order the sequence upon retrieval (bug blf from miki 12-feb-2019
        dataNettCharge.blFCSeq = i + 1;

        dataNettCharge.blFCChargeCode =
          data.blFreightChargeConsole[i].blFCChargeCode;
        dataNettCharge.blFCRevenue = data.blFreightChargeConsole[i].blFCRevenue;
        dataNettCharge.blFCRate = data.blFreightChargeConsole[i].blFCRate;
        dataNettCharge.blFCPerCode = data.blFreightChargeConsole[i].blFCPerCode;
        dataNettCharge.blFCCurrencyCode =
          data.blFreightChargeConsole[i].blFCCurrencyCode;
        dataNettCharge.blFCAmount = data.blFreightChargeConsole[i].blFCAmount;
        dataNettCharge.blFCPc = data.blFreightChargeConsole[i].blFCPc;
        dataNettCharge.blFCPlaceOfPaymentCode =
          data.blFreightChargeConsole[i].blFCPlaceOfPaymentCode;
        dataNettCharge.blFCPlaceOfPaymentName =
          data.blFreightChargeConsole[i].blFCPlaceOfPaymentName;
        dataNettCharge.blFCPayer = data.blFreightChargeConsole[i].blFCPayer;
        dataNettCharge.blFCPayerCode =
          data.blFreightChargeConsole[i].blFCPayerCode;
        dataNettCharge.blFCPayerName =
          data.blFreightChargeConsole[i].blFCPayerName;
        dataNettCharge.blFCContainerType =
          data.blFreightChargeConsole[i].blFCContainerType;
        dataNettCharge.blFCIsEntered =
          data.blFreightChargeConsole[i].blFCIsEntered;

        // is manifested handler
        if (data.blFreightChargeConsole[i].blFCIsManifestedFreight === "Y") {
          dataNettCharge.blFCIsManifestedFreight = true;
          dataNettCharge.blFCIsManifestedFreightString = "Y";
        } else {
          dataNettCharge.blFCIsManifestedFreight = false;
          dataNettCharge.blFCIsManifestedFreightString = "N";
        }

        // is charge printed handler
        if (data.blFreightChargeConsole[i].blFCIsChargePrinted === "Y") {
          dataNettCharge.blFCIsChargePrinted = true;
          dataNettCharge.blFCIsChargePrintedString = "Y";
        } else {
          dataNettCharge.blFCIsChargePrinted = false;
          dataNettCharge.blFCIsChargePrintedString = "N";
        }

        // is charge fai group
        if (data.blFreightChargeConsole[i].blFCFaiGroup === "Y") {
          dataNettCharge.blFCFaiGroup = true;
          dataNettCharge.blFCFaiGroupString = "Y";
        } else {
          dataNettCharge.blFCFaiGroup = false;
          dataNettCharge.blFCFaiGroupString = "N";
        }

        // is entered
        if (data.blFreightChargeConsole[i].blFCIsEntered === "Y") {
          dataNettCharge.blFCIsEntered = true;
          dataNettCharge.blFCIsEnteredString = "Y";
        } else {
          dataNettCharge.blFCIsEntered = false;
          dataNettCharge.blFCIsEnteredString = "N";
        }

        dataNettCharge.blFCFreightReference =
          data.blFreightChargeConsole[i].blFCFreightReference;
        console.log(dataNettCharge);
        this.gridBlNettChargeConsole.listStore.store.push(dataNettCharge);
      }
      this.gridBlNettChargeConsole.loadData();
    }

    //  populate Exchange Rate grid
    this.gridBlNettChargeExchangeRate.url = "";
    this.gridBlNettChargeExchangeRate.onClear();
    if (data.blExchangeRate !== undefined && data.blExchangeRate.length > 0) {
      for (let i = 0; i < data.blExchangeRate.length; i++) {
        const dataExchangeRate = new BLExchangeRate();
        dataExchangeRate.blExchangeRateOfficeCode = this.model.blOfficeCode;
        dataExchangeRate.blExchangeRateBlNo = this.model.blNo;
        dataExchangeRate.blExchangeRateBlSeq =
          data.blExchangeRate[i].blExchangeRateBlSeq;
        dataExchangeRate.blExchangeRateHomeCurrency =
          data.blExchangeRate[i].blExchangeRateHomeCurrency;
        dataExchangeRate.blExchangeRateDestinationCurrency =
          data.blExchangeRate[i].blExchangeRateDestinationCurrency;
        dataExchangeRate.blExchangeRate = data.blExchangeRate[i].blExchangeRate;

        this.gridBlNettChargeExchangeRate.listStore.store.push(
          dataExchangeRate
        );
      }
    }
    this.gridBlNettChargeExchangeRate.loadData();

    this.genericUtil.hideLoader();
  }

  setDataToBookingDataDetail(data){
    console.log("setDataToBookingDataDetail", data);

    if(data.bookingDataDetail && data.bookingDataDetail.length > 0){
      this.bookingDataDetail = data.bookingDataDetail;
      this.bookingDataDetail.forEach(element => {

        switch (element.bContainerType) {
          case "STD": {
            element.bContainerTypeName = "NORMAL";
            break;
          }
          case "REF": {
            element.bContainerTypeName = "REEFER";
            break;
          }
          case "DRY": {
            element.bContainerTypeName = "REEFER DRY";
            break;
          }
          case "OPT": {
            element.bContainerTypeName = "OPEN TOP";
            break;
          }
          case "FLR": {
            element.bContainerTypeName = "FLATRACK";
            break;
          }
          case "PLT": {
            element.bContainerTypeName = "PLATFORM";
            break;
          }
          case "TNK": {
            element.bContainerTypeName = "TANK";
            break;
          }
          case "UC": {
            element.bContainerTypeName = "UC";
            break;
          }

          default: {
            element.bContainerTypeName = "";
            break;
          }
        }
        element.bDgClass = element.bDgClass === "N" ? "N/A" : element.bDgClass;
        element.groupTypeSizeHeight = element.bContainerTypeName + element.bContainerSize + element.bContainerHeight;
      });
    }
  }

  onDialogBlSaveOk() {
    if (this.isFullSaveClicked === true) {
      this.cancelButtonClickedCount += 1; //GSO-389

      this.lockTab1 = true;
      this.lockTab2 = true;
      this.lockTab3 = true;
      this.lockTab4 = true;
      this.lockTab5 = true;
      this.lockTab6 = true;
      this.lockTab8 = true;

      this.lockParty = false;
      this.lockPlace = true;
      this.lockContainer = true;
      this.lockMarkNo = true;
      this.lockDesc = true;
      this.lockNettCharge = true;
      this.lockGrossCharge = true;
      this.lockNettChargeConsole = true;
      this.lockGrossChargeConsole = true;

      this.isInsideRetrieveMode = false;



      // GOS-345
      if (this.officeCode.substr(0, 2) === 'IN') {
        // unlock
        this.disableToolbarButtons =
          'partialSave,partialSave2,save,save2,printInvoice,delete,print';
      } else {
        // keep lock
        this.disableToolbarButtons =
          'partialSave,partialSave2,save,save2,printInvoice,delete,ias,print';
      }

      this.tabPartyClicked();

      this.doUnlockByUser(
        this.cookieService.getDefaultLocationCode(),
        this.cookieService.getName().toUpperCase()
      );
    } else {
      this.doLock(
        this.model.blOfficeCode,
        this.model.blNo,
        this.cookieService.getName()
      );
    }

    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("blSaveDialogOkOnly");
  }

  onDialogBlYes() {
    //  Yes means creating a SW BL, so we need to compose SWITCH bl data
    this.cleanUpBeforeRetrieval();
    const blNo = this.model.blNo.toUpperCase().trim();

    this.model = new BLHeader();
    this.paramContainer = new BLContainer();
    this.paramMarkNo = new BLMarkNo();
    this.paramDesc = new BLDesc();
    this.paramDescClause = new BLDescClause();
    this.paramFreightCharge = new BLFreightCharge();

    //  set header to SW compliant
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.blNo = blNo;
    //this.model.blPlaceOfIssueCode = this.cookieService.getDefaultLocationCode();
    //this.model.blPlaceOfIssueName = this.cookieService.getDefaultLocationName();
    this.model.blType = "SW";
    this.model.blContainerOwnership = "COC";
    this.model.blContainerStatus = "";
    this.model.blDgClass = "";
    this.model.blDetention = "";
    this.model.blDetentionRequest = 0;
    this.model.blDemurrage = "";
    this.model.blDemurrageRequest = 0;
    this.model.blDateOfIssue = this.genericUtil.getPSIDate();
    this.dateBlNettChargeDateOfIssue.setValue(this.model.blDateOfIssue);
    this.dateBlNettChargeExchangeRate.setValue(this.model.blDateOfIssue);
    this.dateBlNettChargeShippedOnBoard.setValue(this.model.blDateOfIssue);
    this.model.blFreightType = "NETT";

    var self = this;
    ///GOL-1250 this.model.blConsigneeId = 'CS0000090987';
    this.model.blConsigneeId = "";
    this.model.blConsigneeAddressOption = 0;
    this.model.blConsigneeAddressText = "";
    ///GOL-1250 this.model.blConsigneeName = '***';
    this.model.blConsigneeName = "";
    this.cbBlPartyConsignee.setForceValue(this.model.blConsigneeName);
    // this.cbBlPartyConsigneeAddress.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findCustomerAddressByCustomerId/" +
    //     this.model.blConsigneeId.trim()
    // );
    // this.cbBlPartyConsigneeAddress.loadSelect(function () {
    //   self.cbBlPartyConsigneeAddress.setValue(
    //     String(self.model.blConsigneeAddressOption).toString() === "0"
    //       ? ""
    //       : String(self.model.blConsigneeAddressOption).toString()
    //   );
    //   self.txtBlPartyConsigneeAddress.setValue("");
    // });
    self.cbBlPartyConsigneeAddress.setValue("");

    ///GOL-1250 this.model.blNotifyPartyId = 'CS0000090987';
    this.model.blNotifyPartyId = "";
    this.model.blNotifyPartyAddressOption = 0;
    this.model.blNotifyPartyAddressText = "";
    ///GOL-1250 this.model.blNotifyPartyName = '***';
    this.model.blNotifyPartyName = "";
    this.cbBlPartyNotifyParty.setForceValue(this.model.blNotifyPartyName);
    // this.cbBlPartyNotifyPartyAddress.setUrl(
    //   this.configService.config.BASE_API.toString() +
    //     "/MasterCustomers/findCustomerAddressByCustomerId/" +
    //     this.model.blNotifyPartyId.trim()
    // );
    // this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
    //   self.cbBlPartyNotifyPartyAddress.setValue(
    //     String(self.model.blNotifyPartyAddressOption).toString() === "0"
    //       ? ""
    //       : String(self.model.blNotifyPartyAddressOption).toString()
    //   );
    //   self.txtBlPartyNotifyPartyAddress.setValue("");
    // });
    self.txtBlPartyNotifyPartyAddress.setValue("");

    //  SW Compliant Place
    this.model.blPreVesselSkipCheck = true;
    //this.model.blPreVesselIsPrinted = false;
    this.model.blOceanVesselIsClausePrintedOnBl = false;
    this.model.blNextVesselSkipCheck = true;
    this.model.blNextVesselClauseIsPrinted = false;
    this.model.blPrincipalId = "PP00021";

    //  SW Compliant Container
    this.paramContainer = new BLContainer();
    this.paramContainer.blContainerSkipCheck = true;
    this.paramContainer.blContainerSkipCheckString = "Y";
    this.paramContainer.blContainerIsEmpty = false;
    this.paramContainer.blContainerIsEmptyString = "N";
    this.paramContainer.blContainerIsDry = false;
    this.paramContainer.blContainerIsDryString = "N";
    this.paramContainer.blContainerShortShipContainer = false;
    this.paramContainer.blContainerShortShipContainerString = "N";

    //  SW Compliant Desc
    this.model.blIsPrintAgentInfoOnBl = false;
    this.model.blIsIrePermit = false;
    this.model.blIsSingleConsigneePermit = false;

    //  SW Compliant Nett
    this.model.blIsFreightAsArranged = false;
    this.model.blIsPrintLoadingVesselVoyageClause = false;
    this.model.blIsPrintShippedOnBoard = true;
    //this.model.blDateOfIssueLock = false;
    //this.model.blIsExchangeRateLock = false;
    this.model.blPlaceOfIssueCode = this.cookieService.getDefaultLocationCode() == 'INNSH' ? 'INNSA' : this.cookieService.getDefaultLocationCode();
    this.model.blPlaceOfIssueName = this.cookieService.getDefaultLocationCode() == 'INNSH' ? 'NHAVA SHEVA, INDIA' : this.cookieService.getDefaultLocationName();
    this.cbBlNettChargePlaceOfIssue.setForceValue(
      this.model.blPlaceOfIssueName
    );

    // SW Console Nett
    this.model.blIsFreightAsArrangedConsole = false;
    this.model.blIsPrintLoadingVesselVoyageClauseConsole = false;
    this.model.blIsPrintShippedOnBoardConsole = true;
    this.model.blDateOfIssueLockConsole = false;
    this.model.blIsExchangeRateLockConsole = false;
    this.model.blPlaceOfIssueCodeConsole =
      this.cookieService.getDefaultLocationCode();
    this.model.blPlaceOfIssueNameConsole =
      this.cookieService.getDefaultLocationName();
    this.cbBlNettChargePlaceOfIssueConsole.setForceValue(
      this.model.blPlaceOfIssueNameConsole
    );
    this.txtBlNettChargeRemarksConsole.setValue("");
    this.gridBlNettChargeConsole.onClear();

    //  Set control for SW BL
    this.cbBlPlacePrincipal.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterPrincipals/findByComboBoxControlPrincipal/principalId={query}"
    );
    this.cbBlPlacePrincipal.setValue(this.model.blPrincipalId);

    this.disableToolbarButtons = "retrieve,printInvoice,ias,delete,print,partialSave2,save2";

    // release the tab
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    this.lockTab1 = false;
    this.lockTab2 = false;
    this.lockTab3 = false;
    this.lockTab4 = false;
    this.lockTab5 = false;
    this.lockTab6 = false;
    this.lockTab8 = false;

    this.blPlacePlaceOfReceiptPrintLock =true;
    this.blPlaceLoadPortPrintLock =true;
    this.blPlaceDischargePortPrintLock =true;
    this.blPlacePlaceOfDeliveryPrintLock =true;
    this.blPlacePrintBlFinalDestination2 = true;

    this.genericUtil.closeDialog("blRetrievalDialog");
  }

  onDialogBlNo() {
    this.isInsideRetrieveMode = false;
    this.genericUtil.closeDialog("blRetrievalDialog");
  }

  handlePartialSave() {
    if (this.model.blType === 'SW' || this.model.blType === 'OIS') {
      this.setValidatorBlSwOisPartial();
    } else {
      this.setValidatorNa();
    }

    // this.setValidatorNa();

    this.isError = false;
    this.isError = this.onValidate(this.model);

    //  check shipper
    this.model["error-blShipper"] = "";
    if (this.model.blShipperName === "***") {
      if(this.officeCode == 'SGSIN' && (this.model.blContainerOwnership == "SOC"||this.model.blContainerOwnership == "SOCTS"||this.model.blContainerOwnership == "SLOT")){
        this.model["error-blShipper"] = "";
      }else{
        this.isError = true;
        this.model["error-blShipper"] =
          "*** not allowed to be selected as Shipper. Please correct your data.";
      }
    }
    // check Fai Group
    this.model["error-blFaiGroup"] = "";
    if (this.gridBlNettCharge.listStore.store.length > 0) {
      let countFaiGroup = 0;
      for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
        if (
          this.gridBlNettCharge.listStore.store[i].blFCFaiGroup
            .toString()
            .toUpperCase() === "Y"
        ) {
          countFaiGroup++;
        }
      }

      if (countFaiGroup === 1) {
        this.isError = true;
        this.model["error-blFaiGroup"] =
          "Please select at least 2 charges to be grouped under FAI.";
      }
    }

    // ************** PRE CHECK **************//
    // ----- Doing Nasty Stuff over here -----//
    const listCtr: Array<String> = [];
    const listCtr2: Array<String> = [];
    const listFC: Array<String> = [];

    // 1st Step, Checking the container
    if (this.gridBlContainer.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        let cTypeName: String = "";
        switch (this.gridBlContainer.listStore.store[i].blContainerType) {
          case "STD": {
            cTypeName = "NORMAL";
            break;
          }
          case "FLR": {
            cTypeName = "FLATRACK";
            break;
          }
          case "OPT": {
            cTypeName = "OPEN TOP";
            break;
          }
          case "REF": {
            cTypeName = "REEFER";
            break;
          }
          case "TNK": {
            cTypeName = "TANK";
            break;
          }
          case "PLT": {
            cTypeName = "PLATFORM";
            break;
          }
          case "UC": {
            cTypeName = "UC";
            break;
          }
          case "DRY": {
            cTypeName = "REEFER DRY";
            break;
          }
        }

        if (i === 0) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer !== true
          ) {
            listCtr.push(
              cTypeName +
                "-" +
                this.gridBlContainer.listStore.store[i].blContainerSize +
                "-" +
                this.gridBlContainer.listStore.store[i].blContainerHeight
            );
          }
        } else {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer !== true
          ) {
            if (
              !listCtr.includes(
                cTypeName +
                  "-" +
                  this.gridBlContainer.listStore.store[i].blContainerSize +
                  "-" +
                  this.gridBlContainer.listStore.store[i].blContainerHeight
              )
            ) {
              listCtr.push(
                cTypeName +
                  "-" +
                  this.gridBlContainer.listStore.store[i].blContainerSize +
                  "-" +
                  this.gridBlContainer.listStore.store[i].blContainerHeight
              );
            }
          }
        }
      }

      for (const x of listCtr) {
        let counter = 0;
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          let cTypeName: String = "";
          switch (this.gridBlContainer.listStore.store[i].blContainerType) {
            case "STD": {
              cTypeName = "NORMAL";
              break;
            }
            case "FLR": {
              cTypeName = "FLATRACK";
              break;
            }
            case "OPT": {
              cTypeName = "OPEN TOP";
              break;
            }
            case "REF": {
              cTypeName = "REEFER";
              break;
            }
            case "TNK": {
              cTypeName = "TANK";
              break;
            }
            case "PLT": {
              cTypeName = "PLATFORM";
              break;
            }
            case "UC": {
              cTypeName = "UC";
              break;
            }
            case "DRY": {
              cTypeName = "REEFER DRY";
              break;
            }
          }
          if (
            x ===
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          ) {
            counter++;
          }
        }
        listCtr2.push(counter + "-" + x);
      }
    }

    // 2nd Step, Checking the Freight Charge
    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
        if (
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "OFT" ||
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FAI" ||
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FOF"
        ) {
          let fcCType: String = "";
          let fcCSize: String = "";
          let fcCHeight: String = "";

          // map per code to type size height
          switch (this.gridBlNettCharge.listStore.store[i].blFCPerCode) {
            case "D20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "D40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "D45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "HC20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "HC40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "HC45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "FR20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "FR40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "FR45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "FRH20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "FRH40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "FRH45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "OT20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "OT40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "OT45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "OTH20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "OTH40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "OTH45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "R20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "R40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "R45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "RH20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "RH40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "RH45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "TK20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "TK40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "TK45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "TKH20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "TKH40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "TKH45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "PL20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "PL40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "PL45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "PLH20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "PLH40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "PLH45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }
          }

          listFC.push(
            this.gridBlNettCharge.listStore.store[i].blFCRevenue +
              "-" +
              fcCType +
              "-" +
              fcCSize +
              "-" +
              fcCHeight
          );
        }
      }
    }

    // 3rd Step, Checking the Freight Charge Console
    /*
    if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlNettChargeConsole.listStore.store.length; i++) {
        if (this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode === 'OFT' ||
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode === 'FAI' ||
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode === 'FOF') {
          let fcCType: String = '';
          let fcCSize: String = '';
          let fcCHeight: String = '';

          // map per code to type size height
          switch (this.gridBlNettChargeConsole.listStore.store[i].blFCPerCode) {
            case 'D20': {
              fcCType = 'NORMAL';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'D40': {
              fcCType = 'NORMAL';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'D45': {
              fcCType = 'NORMAL';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'HC20': {
              fcCType = 'NORMAL';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'HC40': {
              fcCType = 'NORMAL';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'HC45': {
              fcCType = 'NORMAL';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

            case 'FR20': {
              fcCType = 'FLATRACK';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'FR40': {
              fcCType = 'FLATRACK';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'FR45': {
              fcCType = 'FLATRACK';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'FRH20': {
              fcCType = 'FLATRACK';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'FRH40': {
              fcCType = 'FLATRACK';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'FRH45': {
              fcCType = 'FLATRACK';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

            case 'OT20': {
              fcCType = 'OPEN TOP';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'OT40': {
              fcCType = 'OPEN TOP';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'OT45': {
              fcCType = 'OPEN TOP';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'OTH20': {
              fcCType = 'OPEN TOP';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'OTH40': {
              fcCType = 'OPEN TOP';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'OTH45': {
              fcCType = 'OPEN TOP';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

            case 'R20': {
              fcCType = 'REEFER';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'R40': {
              fcCType = 'REEFER';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'R45': {
              fcCType = 'REEFER';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'RH20': {
              fcCType = 'REEFER';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'RH40': {
              fcCType = 'REEFER';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'RH45': {
              fcCType = 'REEFER';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

            case 'TK20': {
              fcCType = 'TANK';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'TK40': {
              fcCType = 'TANK';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'TK45': {
              fcCType = 'TANK';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'TKH20': {
              fcCType = 'TANK';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'TKH40': {
              fcCType = 'TANK';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'TKH45': {
              fcCType = 'TANK';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

            case 'PL20': {
              fcCType = 'PLATFORM';
              fcCSize = '20';
              fcCHeight = '86';
              break;
            }
            case 'PL40': {
              fcCType = 'PLATFORM';
              fcCSize = '40';
              fcCHeight = '86';
              break;
            }
            case 'PL45': {
              fcCType = 'PLATFORM';
              fcCSize = '45';
              fcCHeight = '86';
              break;
            }
            case 'PLH20': {
              fcCType = 'PLATFORM';
              fcCSize = '20';
              fcCHeight = '96';
              break;
            }
            case 'PLH40': {
              fcCType = 'PLATFORM';
              fcCSize = '40';
              fcCHeight = '96';
              break;
            }
            case 'PLH45': {
              fcCType = 'PLATFORM';
              fcCSize = '45';
              fcCHeight = '96';
              break;
            }

          }

          listFC.push(this.gridBlNettChargeConsole.listStore.store[i].blFCRevenue + '-' + fcCType + '-' + fcCSize + '-' + fcCHeight);
        }
      }
    }
    */
    // *********** END OF PRE CHECK ************//

    // ***** DO THE CHECKING **** //
    this.frmDialogMessage =
      "<br>Total quantity in Container and Freight Charge not tally. Please amend before saving.<br><br>";
    this.frmDialogMessage +=
      '<table border="0" width="100%"><tr><td width="35%" style="vertical-align:top;">Container Quantity</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listCtr2.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let a = 0;
      for (const x of listCtr2) {
        this.frmDialogMessage +=
          '<tr><td width="15%">' +
          x.split("-")[0] +
          '</td><td width="35%">' +
          x.split("-")[1] +
          '</td><td width="5%">&nbsp;' +
          x.split("-")[2] +
          "</td><td>&nbsp;" +
          x.split("-")[3] +
          "</td></tr>";
        a++;
      }
    }
    this.frmDialogMessage +=
      '</table></td></tr><tr><td colspan="3">&nbsp;</td></tr>';

    this.frmDialogMessage +=
      '<tr><td width="3%" style="vertical-align:top;">Freight Charge</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listFC.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let b = 0;
      for (const x of listFC) {
        this.frmDialogMessage +=
          '<tr><td width="15%">' +
          x.split("-")[0] +
          '</td><td width="35%">' +
          x.split("-")[1] +
          '</td><td width="5%">&nbsp;' +
          x.split("-")[2] +
          "</td><td>&nbsp;" +
          x.split("-")[3] +
          "</td></tr>";
        b++;
      }
    }
    this.frmDialogMessage += "</table></td></tr></table>";
    this.frmDialogMessage += "<br> Continue to save?<br><br>";

    let arrayCheck: Array<String> = [];
    if (listCtr2.length === 0 && listFC.length === 0) {
      arrayCheck.push("error");
    } else if (listCtr2.length >= listFC.length) {
      arrayCheck = listCtr2.filter((val) => !listFC.includes(val));
    } else if (listFC.length >= listCtr2.length) {
      arrayCheck = listFC.filter((val) => !listCtr2.includes(val));
    }

    if (arrayCheck.length > 0) {
      this.isError = true;
    }

    // **** END OF CHECKING **** //

    if (!this.isError) {
      this.onDialogBlContainerChargeDiscrepancyDialogYes();
    } else {
      this.isError = false;
      this.genericUtil.showDialog(
        "blContainerChargeDiscrepancyDialog",
        "BL Maintenance (New)",
        600,
        275
      );
    }
  }

  checkPayer(): Boolean {
    console.log("** check Payer **");
    let result: Boolean = false;

    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (const dtCheck of this.gridBlNettCharge.listStore.store) {
        if (
          dtCheck.blFCPc === "P1" ||
          dtCheck.blFCPc === "P2" ||
          dtCheck.blFCPc === "P3"
        ) {
          console.log(
            "$$.1 " +
              dtCheck.blFCPayer +
              " - " +
              this.model.blPayer1Code +
              " - " +
              result
          );
          if (dtCheck.blFCPayer === "1" && this.model.blPayer1Code === "") {
            result = true;
          }
          console.log(
            "$$.3 " +
              dtCheck.blFCPayer +
              " - " +
              this.model.blPayer2Code +
              " - " +
              result
          );
          if (dtCheck.blFCPayer === "2" && this.model.blPayer2Code === "") {
            result = true;
          }
          console.log(
            "$$.5 " +
              dtCheck.blFCPayer +
              " - " +
              this.model.blPayer3Code +
              " - " +
              result
          );
          if (dtCheck.blFCPayer === "3" && this.model.blPayer3Code === "") {
            result = true;
          }
          console.log(
            "$$.7 " +
              dtCheck.blFCPayer +
              " - " +
              this.model.blPayer1Code +
              " - " +
              result
          );
          if (
            dtCheck.blFCPayer === "" &&
            this.model.blPayer1Code === "" &&
            this.model.blPayer2Code === "" &&
            this.model.blPayer3Code === ""
          ) {
            result = true;
          }
          console.log(
            "$$.8 " +
              dtCheck.blFCPayer +
              " - " +
              this.model.blPayer1Code +
              " - " +
              result
          );

          if (dtCheck.blFCPayer === "") {
            result = true;
          }
        } else {
          console.log("--superman go else --");
        }
      }
    }

    return result;
  }

  checkPOP(): Boolean {
    console.log("** check POP **");
    let result: Boolean = false;

    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (const dtCheck of this.gridBlNettCharge.listStore.store) {
        console.log("--batman check pop--");
        console.log(dtCheck);
        console.log(
          "Comparer ---> " +
            dtCheck.blFCPayer +
            " VS " +
            this.model.blPayer1Code
        );

        if (dtCheck.blFCPc === "P1" && this.model.blPrepaidAt1Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPrepaidAt1Code !== "") {
          result = true;
        }
        if (dtCheck.blFCPc === "P2" && this.model.blPrepaidAt2Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPrepaidAt2Code !== "") {
          result = true;
        }
        if (dtCheck.blFCPc === "P3" && this.model.blPrepaidAt3Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPrepaidAt3Code !== "") {
          result = true;
        }

        if (
          dtCheck.blFCPc === "" &&
          this.model.blPrepaidAt1Code === "" &&
          this.model.blPrepaidAt2Code === "" &&
          this.model.blPrepaidAt3Code === ""
        ) {
          result = true;
        }

        if (dtCheck.blFCPc === "C1" && this.model.blPayableAt1Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPayableAt1Code !== "") {
          result = true;
        }
        if (dtCheck.blFCPc === "C2" && this.model.blPayableAt2Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPayableAt2Code !== "") {
          result = true;
        }
        if (dtCheck.blFCPc === "C3" && this.model.blPayableAt3Code === "") {
          result = true;
        }
        if (dtCheck.blFCPc === "" && this.model.blPayableAt3Code !== "") {
          result = true;
        }

        if (
          dtCheck.blFCPc === "" &&
          this.model.blPayableAt1Code === "" &&
          this.model.blPayableAt2Code === "" &&
          this.model.blPayableAt3Code === ""
        ) {
          result = true;
        }
      }
    }

    /*
     if( (this.model.blPrepaidAt1Code == "" || this.model.blPrepaidAt1Code == undefined) &&
     (this.model.blPrepaidAt2Code == "" || this.model.blPrepaidAt2Code == undefined) &&
     (this.model.blPrepaidAt3Code == "" || this.model.blPrepaidAt3Code == undefined) ){
     result = true;
     }
     */

    return result;
  }

  checkDateOfIssue(){
    var self=this;
    console.log("####check date of issue####");
    console.log("date fo issue nya : "+this.dateBlNettChargeDateOfIssue.getValue().substring(0, 10));
    console.log("shipped on board nya : "+this.dateBlNettChargeShippedOnBoard.getValue());
    var issueDate = new Date(this.dateBlNettChargeDateOfIssue.getValue().substring(0, 10));
    var shippedDate = new Date(this.dateBlNettChargeShippedOnBoard.getValue().substring(0, 10));
    if (
      issueDate < shippedDate
    ) {
      self.frmDialogMessage = "<br>Date Of Issue earlier than Shipped On Board Date.<br><br>";
      self.frmDialogMessage += "<br>Continue to save?<br><br>";
      self.genericUtil.showDialog("blDialogDateOfIssueEarlier", "BL Maintenance (New)", 500, 250);
      self.genericUtil.hideLoader();

    }else{
      this.handleSave();
    }
  }

  onCheckDateOfIssueYes(){
    this.genericUtil.closeDialog("blDialogDateOfIssueEarlier");
    this.handleSave();
  };

  onCheckDateOfIssueNo(){
    this.genericUtil.closeDialog("blDialogDateOfIssueEarlier");
  };

  handleSave() {
    /*
     if (this.model.blType === 'SW' || this.model.blType === 'OIS') {
     console.log(this.model);
     this.setValidatorBlSwOis();
     } else {
     this.setValidatorNa();
     }*/
    //to prevent saving shipped on board or DOI wrong
     this.setBlIsShippedOnBoardDate();

    if (this.model.blType === 'SW' || this.model.blType === 'OIS') {
      if (this.isFullSaveClicked) {
        this.setValidatorNa2();
      } else {
        this.setValidatorBlSwOisPartial();
      }

    } else {
      if (this.isFullSaveClicked) {
        console.log("Shipper Id nya : "+this.model.blShipperId)
        this.setValidatorNa2();
      } else {
        this.setValidatorNaPartialSave();
      }
    }

    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      this.model["payerValidator"] = this.checkPayer();
      if (this.isFullSaveClicked && this.blmode === "MODE2") {
        // this.validateBeforeSaveNew();
      }

    }
    this.model["popValidator"] = this.checkPOP();

    // Check character invalid
    this.model["error-character"] = "";
    this.handleValidateSpecialCharacter();
    if (this.lineInvalid.length > 0) {
      this.isError = true;
      return;
    }

    this.isError = false;
    this.isError = this.onValidate(this.model);

    // check shipper address must not exceed 6 lines
    this.model["error-blShipperAddress"] = "";
    let arrShipperAddress: string[] = this.txtBlPartyShipperAddress
      .getValue()
      .split("↵");
    if (arrShipperAddress.length > 6) {
      this.isError = true;
      this.model["error-blShipperAddress"] =
        "Shipper Address should not exceed 6 lines.";
    }

    for (let index = 0; index < arrShipperAddress.length; index++) {
      let element = arrShipperAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 38) {
        this.isError = true;
        this.model["error-blShipperAddress"] =
          'Shipper: "' +
          element.toUpperCase() +
          '" should not exceed 38 characters.';
        break;
      }
    }
    /*
     arrShipperAddress.forEach(element => {
     console.log(element + " - " + element.length)
     if(element.length > 32){
     this.isError = true;
     this.model['error-blShipperAddress'] = 'Shipper: "' + element + '" should not exceed 32 characters.';
     break;
     }
     });
     */

    // check consignee address must not exceed 6 lines
    this.model["error-blConsigneeAddress"] = "";
    let arrConsigneeAddress: string[] = this.txtBlPartyConsigneeAddress
      .getValue()
      .split("↵");
    if (arrConsigneeAddress.length > 6) {
      this.isError = true;
      this.model["error-blConsigneeAddress"] =
        "Consignee Address should not exceed 6 lines.";
    }

    /*
     arrConsigneeAddress.forEach(element => {
     if(element.length > 32){
     this.isError = true;
     this.model['error-blConsigneeAddress'] = 'Consignee: "' + element + '" should not exceed 32 characters.';
     return;
     }
     });
     */

    for (let index = 0; index < arrConsigneeAddress.length; index++) {
      let element = arrConsigneeAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 38) {
        this.isError = true;
        this.model["error-blConsigneeAddress"] =
          'Consignee: "' +
          element.toUpperCase() +
          '" should not exceed 38 characters.';
        break;
      }
    }

    // check notify party address must not exceed 6 lines
    this.model["error-blNotifyPartyAddress"] = "";
    let arrNotifyAddress: string[] = this.txtBlPartyNotifyPartyAddress
      .getValue()
      .split("↵");
    if (arrNotifyAddress.length > 6) {
      this.isError = true;
      this.model["error-blNotifyPartyAddress"] =
        "Notify Party Address should not exceed 6 lines.";
    }
    /*
     arrNotifyAddress.forEach(element => {
     if(element.length > 32){
     this.isError = true;
     this.model['error-blNotifyPartyAddress'] = 'Notify Party: "' + element + '" should not exceed 32 characters.';
     return;
     }
     });
     */

    //JIRA GOL 1250
    if (
      (this.model.blConsigneeId == "" ||
        this.model.blConsigneeId == null ||
        this.model.blConsigneeId == undefined) &&
      ((this.model.blNotifyPartyId == "CS0000090987" &&
        this.txtBlPartyNotifyPartyAddress.getValue().toUpperCase() ==
          "SAME AS CONSIGNEE") ||
        (this.model.blNotifyPartyId == "CS0000090987" &&
          this.txtBlPartyNotifyPartyAddress.getValue().toUpperCase() ===
            this.model.blConsigneeAddressText))
    ) {
      this.model["error-blConsigneeId"] = "";
      this.isError = true;
      this.model["error-blConsigneeId"] =
        "Please input Consignee. Blank is NOT allowed.";
      // this.model["error-blNotifyPartyId"] = "";
    } else if (
      ((this.model.blConsigneeId == "CS0000090987" &&
        this.txtBlPartyConsigneeAddress.getValue().toUpperCase() ==
          "TO ORDER") ||
        (this.model.blConsigneeId == "CS0000090987" &&
          this.txtBlPartyConsigneeAddress.getValue().toUpperCase() ==
            "TO ORDER OF SHIPPER")) &&
      (this.model.blNotifyPartyId == "" ||
        this.model.blNotifyPartyId == null ||
        this.model.blNotifyPartyId == undefined)
    ) {
      this.model["error-blConsigneeId"] = "";
      this.model["error-blNotifyPartyId"] = "";
    } else if (
      (this.model.blConsigneeId != "" ||
        this.model.blConsigneeId != null ||
        this.model.blConsigneeId != undefined) &&
      (this.txtBlPartyConsigneeAddress.getValue() == "" ||
        this.txtBlPartyConsigneeAddress.getValue() == null) &&
      ((this.model.blNotifyPartyId == "CS0000090987" &&
        this.txtBlPartyNotifyPartyAddress.getValue().toUpperCase() ==
          "SAME AS CONSIGNEE") ||
        (this.model.blNotifyPartyId == "CS0000090987" &&
          this.txtBlPartyNotifyPartyAddress.getValue().toUpperCase() ===
            this.txtBlPartyConsigneeAddress.getValue()))
    ) {
      this.model["error-blConsigneeAddress"] = "";
      this.isError = true;
      this.model["error-blConsigneeAddress"] =
        "Consignee Address should not be blank.";
    } else if (
      ((this.model.blConsigneeId == "CS0000090987" &&
        this.txtBlPartyConsigneeAddress.getValue().toUpperCase() ==
          "TO ORDER") ||
        (this.model.blConsigneeId == "CS0000090987" &&
          this.txtBlPartyConsigneeAddress.getValue().toUpperCase() ==
            "TO ORDER OF SHIPPER")) &&
      (this.model.blNotifyPartyId != "" ||
        this.model.blNotifyPartyId != null ||
        this.model.blNotifyPartyId != undefined) &&
      (this.txtBlPartyNotifyPartyAddress.getValue() == "" ||
        this.txtBlPartyNotifyPartyAddress.getValue() == null)
    ) {
      this.model["error-blNotifyPartyAddress"] = "";
      this.isError = true;
      this.model["error-blNotifyPartyAddress"] =
        "Notify Party Address should not be blank.";
    } else if(this.model.blConsigneeId == 'CS0000090987' && this.model.blNotifyPartyId == 'CS0000090987' ){
       if (this.isFullSaveClicked) {
         this.model["error-bothConsigneeAndParty"] ="";
         this.isError = true;
         this.model["error-bothConsigneeAndParty"] =
         "*** not allowed to be selected both Consignee & Notify Party. Please correct your data.";
       }
     }

    for (let index = 0; index < arrNotifyAddress.length; index++) {
      let element = arrNotifyAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 38) {
        this.isError = true;
        this.model["error-blNotifyPartyAddress"] =
          'Notify Party: "' +
          element.toUpperCase() +
          '" should not exceed 38 characters.';
        break;
      }
    }

    // check shortshipment
    if (this.isFullSaveClicked === true) {
      this.model["error-blShortshipment"] = "";
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer === true
          ) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentFrom === false &&
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentTo === false
            ) {
              this.isError = true;
              this.model["error-blShortshipment"] =
                "Missing 'To' / 'From' for Short Shipment selection.";
            }
          }
        }
      }
    }

    //validate place tab
    //pre vessel/voyage <> next vessel/voyage
    let preVes: string = "";
    let nextVes: string = "";

    preVes =
      this.model.blPreVesselName.trim() +
      "/" +
      this.model.blPreVesselVoyage.trim();
    nextVes =
      this.model.blNextVesselName.trim() +
      "/" +
      this.model.blNextVesselVoyage.trim();

    if (preVes === nextVes && preVes != "/" && nextVes != "/") {
      this.isError = true;
      this.model["error-nextVesselValidator"] =
        "Place Tab: Pre Vessel/Voyage should not be same as Next Vessel/Voyage.";
    }

    //  check shipper
    this.model["error-blShipper"] = "";
    if (this.model.blShipperName === "***") {
      if(this.officeCode == 'SGSIN' && (this.model.blContainerOwnership == "SOC"||this.model.blContainerOwnership == "SOCTS"||this.model.blContainerOwnership == "SLOT")){
        this.model["error-blShipper"] = "";
      }else if (this.officeCode.startsWith('CN')){
        this.model["error-blShipper"] = "";
      }else{
        this.isError = true;
        this.model["error-blShipper"] =
          "*** not allowed to be selected as Shipper. Please correct your data.";
      }
    }

    // check Fai Group
    let msg: String = "";
    let mapFAI = new Map<String, String[]>();

    if (this.gridBlNettCharge.listStore.store.length > 0) {
      this.gridBlNettCharge.listStore.store.forEach((element) => {
        if (element.blFCFaiGroupString == "Y") {
          if (mapFAI.get(element.blFCPerCode) == null) {
            let blfList: String[] = [];
            var key: String =
              element.blFCPerCode +
              ";;" +
              element.blFCPayer +
              ";;" +
              element.blFCCurrencyCode +
              ";;" +
              element.blFCPlaceOfPaymentCode +
              ";;" +
              element.blFCPc +
              ";;" +
              element.blFCChargeCode;
            blfList.push(key);
            mapFAI.set(element.blFCPerCode, blfList);
          } else {
            var key: String =
              element.blFCPerCode +
              ";;" +
              element.blFCPayer +
              ";;" +
              element.blFCCurrencyCode +
              ";;" +
              element.blFCPlaceOfPaymentCode +
              ";;" +
              element.blFCPc +
              ";;" +
              element.blFCChargeCode;
            mapFAI.get(element.blFCPerCode).push(key);
          }
        }
      });
    }

    mapFAI.forEach((value: String[], key: String) => {
      console.log(key, value);
      //let countFaiGroup = 0;
      let countFaiGroupMainCharge = 0;

      if (value.length == 1) {
        //this.isError = true;
        //this.model['error-blFaiGroup'] = 'Please select at least 2 charges to be grouped under FAI.';
        msg += "Please select at least 2 charges to be grouped under FAI.<br/>";
      } else if (value.length > 1) {
        value.forEach((element) => {
          var chrg = element.split(";;")[5];
          if (chrg === "OFT" || chrg === "FOF" || chrg === "FAI") {
            countFaiGroupMainCharge++;
          }
        });
        if (countFaiGroupMainCharge === 0) {
          //this.isError = true;
          //this.model['error-blFaiGroup'] = 'Missing Main Charge detected under FAI Group.';
          msg += "Missing Main Charge detected under FAI Group.<br/>";
        }
      }
    });

    if (msg != "") {
      this.isError = true;
      this.model["error-blFaiGroup"] = msg;
    }

    console.log("CEK SHIP ON BOARD DISINI : "+this.model.blExtraShippedOnBoard);
    if(this.isFullSaveClicked){
      //check doi/sob
      this.model["error-blDoiAndSob"] = "";
      let blDOI = this.dateBlNettChargeDateOfIssue.getValue();
      let blSOB = this.dateBlNettChargeShippedOnBoard.getValue();
      console.log("date of issue : "+this.model.blDateOfIssue);
      console.log("shipped on board : "+this.model.blExtraShippedOnBoard);
      console.log("shipped on board 2 : "+this.dateBlNettChargeShippedOnBoard.getValue());

      if(blSOB == ""){
        blSOB = this.dateBlNettChargeShippedOnBoard.getValue();
      }

      console.log("SOB length : "+blSOB.length);
      console.log("DOI length : "+blDOI.length);


      if (blDOI.length == 0 && blSOB.length == 0) {
        this.isError = true;
        this.model["error-blDoiAndSob"] =
          "Please select SOB/DOI date.";
      }else{
        this.model["error-blDoiAndSob"] = "";
      }
    }




    /*
     this.model['error-blFaiGroup'] = '';
     console.log('debug nett charge ');
     console.log(this.gridBlNettCharge.listStore.store.length);
     if (this.gridBlNettCharge.listStore.store.length > 0) {
     let countFaiGroup = 0;
     let countFaiGroupMainCharge = 0;
     for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
     if (this.gridBlNettCharge.listStore.store[i].blFCFaiGroup === true) {
     countFaiGroup++;
     }
     }

     if (countFaiGroup === 1) {
     this.isError = true;
     this.model['error-blFaiGroup'] = 'Please select at least 2 charges to be grouped under FAI.';
     } else if (countFaiGroup > 1 ){
     if (countFaiGroup > 1) {
     for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
     if (this.gridBlNettCharge.listStore.store[i].blFCFaiGroup === true) {
     if (this.gridBlNettCharge.listStore.store[i].blFCChargeCode === 'OFT' || this.gridBlNettCharge.listStore.store[i].blFCChargeCode === 'FOF' || this.gridBlNettCharge.listStore.store[i].blFCChargeCode === 'FAI') {
     countFaiGroupMainCharge++;
     }
     }
     }
     if (countFaiGroupMainCharge === 0) {
     this.isError = true;
     this.model['error-blFaiGroup'] = 'Missing Main Charge detected under FAI Group.';
     }
     }
     }

     }
     */

    //  check mark no
    this.model["error-blMarkNoCount"] = "";
    console.log("mark no");
    console.log(this.gridBlMarkNo.listStore.store);
    if (this.model.blType === 'SW' || this.model.blType === 'OIS') {
      if (!this.isFullSaveClicked) {
        this.isError = false;
      }else{
        // GOS-334
        if (this.gridBlMarkNo.listStore.store.length <= 0) {
          this.isError = true;
          this.model["error-blMarkNoCount"] = "Please input Mark No.";
        }
      }

    } else {
      if (this.isFullSaveClicked) {
        if (this.gridBlMarkNo.listStore.store.length <= 0) {
          this.isError = true;
          this.model["error-blMarkNoCount"] = "Please input Mark No.";
        }
      }
    }

    // ************** PRE CHECK **************//
    // ----- Doing Nasty Stuff over here -----//
    const listCtr: Array<String> = [];
    const listCtr2: Array<String> = [];
    const listCheckFC: Array<String> = [];
    const listCheckFCDistinct: Array<String> = [];
    const listFC: Array<String> = [];

    // 1st Step, Checking the container
    if (this.gridBlContainer.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        let cTypeName: String = "";
        switch (this.gridBlContainer.listStore.store[i].blContainerType) {
          case "STD": {
            cTypeName = "NORMAL";
            break;
          }
          case "FLR": {
            cTypeName = "FLATRACK";
            break;
          }
          case "OPT": {
            cTypeName = "OPEN TOP";
            break;
          }
          case "REF": {
            cTypeName = "REEFER";
            break;
          }
          case "TNK": {
            cTypeName = "TANK";
            break;
          }
          case "PLT": {
            cTypeName = "PLATFORM";
            break;
          }
          case "UC": {
            cTypeName = "UC";
            break;
          }
          case "DRY": {
            cTypeName = "REEFER DRY";
            break;
          }
        }

        // if (this.gridBlContainer.listStore.store[i].blContainerShortShipContainer !== true) {
        if (
          !listCtr.includes(
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          )
        ) {
          listCtr.push(
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          );
        }
        // } else {
        // if (!listCtr.includes('[ SHORTSHIPPED CONTAINER(S). ]- - ')) {
        //    listCtr.push('[ SHORTSHIPPED CONTAINER(S). ]- - ');
        // }
        // }
      }

      for (const x of listCtr) {
        let counter = 0;
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          let cTypeName: String = "";
          switch (this.gridBlContainer.listStore.store[i].blContainerType) {
            case "STD": {
              cTypeName = "NORMAL";
              break;
            }
            case "FLR": {
              cTypeName = "FLATRACK";
              break;
            }
            case "OPT": {
              cTypeName = "OPEN TOP";
              break;
            }
            case "REF": {
              cTypeName = "REEFER";
              break;
            }
            case "TNK": {
              cTypeName = "TANK";
              break;
            }
            case "PLT": {
              cTypeName = "PLATFORM";
              break;
            }
            case "UC": {
              cTypeName = "UC";
              break;
            }
            case "DRY": {
              cTypeName = "REEFER DRY";
              break;
            }
          }
          //if (this.gridBlContainer.listStore.store[i].blContainerShortShipContainer !== true) {
          if (
            x ===
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          ) {
            counter++;
            // }
          }
        }
        if (counter > 0) {
          listCtr2.push(counter + "-" + x);
        }
      }

      let counterShortShip = 0;
      for (const x of listCtr) {
        if (x === "[ SHORTSHIPPED CONTAINER(S). ]- - ") {
          counterShortShip++;
        }
      }
      if (counterShortShip > 0) {
        listCtr2.push(
          counterShortShip + "-" + "[ SHORTSHIPPED CONTAINER(S). ]" + "- - "
        );
      }
    }

    // 2nd Step, Checking the Freight Charge
    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
        if (
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "OFT" ||
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FAI" ||
          this.gridBlNettCharge.listStore.store[i].blFCChargeCode === "FOF"
        ) {
          let fcCType: String = "";
          let fcCSize: String = "";
          let fcCHeight: String = "";

          // map per code to type size height
          switch (this.gridBlNettCharge.listStore.store[i].blFCPerCode) {
            case "D20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "D40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "D45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "HC20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "HC40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "HC45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "FR20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "FR40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "FR45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "FRH20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "FRH40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "FRH45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "OT20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "OT40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "OT45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "OTH20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "OTH40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "OTH45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "R20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "R40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "R45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "RH20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "RH40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "RH45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "TK20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "TK40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "TK45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "TKH20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "TKH40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "TKH45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }
            case "PL20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "PL40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "PL45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "PLH20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "PLH40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "PLH45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }
          }

          // patch check data for reefer dry
          if (
            this.gridBlNettCharge.listStore.store[i].blFCPerCode.startsWith("R")
          ) {
            if (
              this.gridBlNettCharge.listStore.store[i].blFCContainerType ==
              "DRY"
            ) {
              fcCType = "REEFER DRY";
            }
          }

          listCheckFC.push(
            this.gridBlNettCharge.listStore.store[i].blFCRevenue +
              "-" +
              fcCType +
              "-" +
              fcCSize +
              "-" +
              fcCHeight
          );
        }
      }

      for (const entry of listCheckFC) {
        if (
          !listCheckFCDistinct.includes(
            entry.split("-")[1] +
              "-" +
              entry.split("-")[2] +
              "-" +
              entry.split("-")[3]
          )
        ) {
          listCheckFCDistinct.push(
            entry.split("-")[1] +
              "-" +
              entry.split("-")[2] +
              "-" +
              entry.split("-")[3]
          );
        }
      }

      for (const entry of listCheckFCDistinct) {
        let counter = 0;
        for (const entry2 of listCheckFC) {
          console.log("cek shortship -> " + entry2);
          if (
            entry2.split("-")[1] +
              "-" +
              entry2.split("-")[2] +
              "-" +
              entry2.split("-")[3] ===
            entry
          ) {
            counter += Number.parseInt(entry2.split("-")[0]);
          }
        }
        console.log("cek shortship -> " + counter + "-" + entry);
        listFC.push(counter + "-" + entry);
      }
    }
    // *********** END OF PRE CHECK ************//

    // ***** DO THE CHECKING **** //
    this.frmDialogMessage =
      "<br>Total quantity in Container and Freight Charge not tally. Please amend before saving.<br><br>";
    this.frmDialogMessage +=
      '<table border="0" width="100%"><tr><td width="35%" style="vertical-align:top;">Container Quantity</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listCtr2.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let a = 0;
      for (const x of listCtr2) {
        if (x.split("-")[1] === "[ SHORTSHIPPED CONTAINER(S). ]") {
          this.frmDialogMessage +=
            '<tr><td width="100%" colspan="4">[ ' +
            x.split("-")[1].split("[")[0] +
            " " +
            x.split("-")[0] +
            x.split("-")[1].split("[")[1] +
            "</td></tr>";
        } else {
          this.frmDialogMessage +=
            '<tr><td width="15%">' +
            x.split("-")[0] +
            '</td><td width="35%">' +
            x.split("-")[1] +
            '</td><td width="5%">&nbsp;' +
            x.split("-")[2] +
            "</td><td>&nbsp;" +
            x.split("-")[3] +
            "</td></tr>";
        }
        a++;
      }
    }
    this.frmDialogMessage +=
      '</table></td></tr><tr><td colspan="3">&nbsp;</td></tr>';

    this.frmDialogMessage +=
      '<tr><td width="3%" style="vertical-align:top;">Freight Charge</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listFC.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let b = 0;
      for (const x of listFC) {
        this.frmDialogMessage +=
          '<tr><td width="15%">' +
          x.split("-")[0] +
          '</td><td width="35%">' +
          x.split("-")[1] +
          '</td><td width="5%">&nbsp;' +
          x.split("-")[2] +
          "</td><td>&nbsp;" +
          x.split("-")[3] +
          "</td></tr>";
        b++;
      }
    }
    this.frmDialogMessage += "</table></td></tr></table>";
    this.frmDialogMessage += "<br> Continue to save?<br><br>";

    let arrayCheck: Array<String> = [];
    if (listCtr2.length === 0 && listFC.length === 0) {
      arrayCheck.push("error");
    } else if (listCtr2.length >= listFC.length) {
      arrayCheck = listCtr2.filter((val) => !listFC.includes(val));
    } else if (listFC.length >= listCtr2.length) {
      arrayCheck = listFC.filter((val) => !listCtr2.includes(val));
    }
    // **** END OF CHECKING **** //

    if (!this.isError) {
      if (arrayCheck.length > 0) {
        this.isDiscrepancyExisted = true;
        this.genericUtil.showDialog(
          "blContainerChargeDiscrepancyDialog",
          "BL Maintenance (New)",
          600,
          275
        );
      } else {
        this.isDiscrepancyExisted = false;
        this.onDialogBlContainerChargeDiscrepancyDialogYes();
      }
    }
  }

  handleSave2() {
    if (this.isFullSaveClicked) {
      this.setValidatorNa();
    } else {
      this.setValidatorNaPartialSave();
    }
    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      this.model["payerValidator"] = this.checkPayer();
      if (this.isFullSaveClicked && this.blmode === "MODE2") {
      //this.validateBeforeSaveNew();
      }
    }
    this.model["popValidator"] = this.checkPOP();

    this.isError = false;
    this.isError = this.onValidate(this.model);

    // check shipper address must not exceed 6 lines
    this.model["error-blShipperAddress"] = "";
    let arrShipperAddress: string[] = this.txtBlPartyShipperAddress
      .getValue()
      .split("↵");
    if (arrShipperAddress.length > 6) {
      this.isError = true;
      this.model["error-blShipperAddress"] =
        "Shipper Address should not exceed 6 lines.";
    }

    for (let index = 0; index < arrShipperAddress.length; index++) {
      let element = arrShipperAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 32) {
        this.isError = true;
        this.model["error-blShipperAddress"] =
          'Shipper: "' +
          element.toUpperCase() +
          '" should not exceed 32 characters.';
        break;
      }
    }

    // check consignee address must not exceed 6 lines
    this.model["error-blConsigneeAddress"] = "";
    let arrConsigneeAddress: string[] = this.txtBlPartyConsigneeAddress
      .getValue()
      .split("↵");
    if (arrConsigneeAddress.length > 6) {
      this.isError = true;
      this.model["error-blConsigneeAddress"] =
        "Consignee Address should not exceed 6 lines.";
    }

    for (let index = 0; index < arrConsigneeAddress.length; index++) {
      let element = arrConsigneeAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 32) {
        this.isError = true;
        this.model["error-blConsigneeAddress"] =
          'Consignee: "' +
          element.toUpperCase() +
          '" should not exceed 32 characters.';
        break;
      }
    }

    // check notify party address must not exceed 6 lines
    this.model["error-blNotifyPartyAddress"] = "";
    let arrNotifyAddress: string[] = this.txtBlPartyNotifyPartyAddress
      .getValue()
      .split("↵");
    if (arrNotifyAddress.length > 6) {
      this.isError = true;
      this.model["error-blNotifyPartyAddress"] =
        "Notify Party Address should not exceed 6 lines.";
    }

    for (let index = 0; index < arrNotifyAddress.length; index++) {
      let element = arrNotifyAddress[index];
      console.log(element + " - " + element.length);
      if (element.length > 32) {
        this.isError = true;
        this.model["error-blNotifyPartyAddress"] =
          'Notify Party: "' +
          element.toUpperCase() +
          '" should not exceed 32 characters.';
        break;
      }
    }

    // check shortshipment
    if (this.isFullSaveClicked === true) {
      this.model["error-blShortshipment"] = "";
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer === true
          ) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentFrom === false &&
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentTo === false
            ) {
              this.isError = true;
              this.model["error-blShortshipment"] =
                "Missing 'To' / 'From' for Short Shipment selection.";
            }
          }
        }
      }
    }

    //  check shipper
    this.model["error-blShipper"] = "";
    if (this.model.blShipperName === "***") {
      if(this.officeCode == 'SGSIN' && (this.model.blContainerOwnership == "SOC"||this.model.blContainerOwnership == "SOCTS"||this.model.blContainerOwnership == "SLOT")){
        this.model["error-blShipper"] = "";
      }else{
        this.isError = true;
        this.model["error-blShipper"] =
          "*** not allowed to be selected as Shipper. Please correct your data.";
      }
    }

    // check Fai Group
    let msg: String = "";
    let mapFAI = new Map<String, String[]>();

    if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
      this.gridBlNettChargeConsole.listStore.store.forEach((element) => {
        if (element.blFCFaiGroupString == "Y") {
          if (mapFAI.get(element.blFCPerCode) == null) {
            let blfList: String[] = [];
            var key: String =
              element.blFCPerCode +
              ";;" +
              element.blFCPayer +
              ";;" +
              element.blFCCurrencyCode +
              ";;" +
              element.blFCPlaceOfPaymentCode +
              ";;" +
              element.blFCPc +
              ";;" +
              element.blFCChargeCode;
            blfList.push(key);
            mapFAI.set(element.blFCPerCode, blfList);
          } else {
            var key: String =
              element.blFCPerCode +
              ";;" +
              element.blFCPayer +
              ";;" +
              element.blFCCurrencyCode +
              ";;" +
              element.blFCPlaceOfPaymentCode +
              ";;" +
              element.blFCPc +
              ";;" +
              element.blFCChargeCode;
            mapFAI.get(element.blFCPerCode).push(key);
          }
        }
      });
    }

    mapFAI.forEach((value: String[], key: String) => {
      console.log(key, value);
      //let countFaiGroup = 0;
      let countFaiGroupMainCharge = 0;

      if (value.length == 1) {
        msg += "Please select at least 2 charges to be grouped under FAI.<br/>";
      } else if (value.length > 1) {
        value.forEach((element) => {
          var chrg = element.split(";;")[5];
          if (chrg === "OFT" || chrg === "FOF" || chrg === "FAI") {
            countFaiGroupMainCharge++;
          }
        });

        if (countFaiGroupMainCharge === 0) {
          msg += "Missing Main Charge detected under FAI Group.<br/>";
        }
      }
    });

    if (msg != "") {
      this.isError = true;
      this.model["error-blFaiGroup"] = msg;
    }

    //  check mark no
    this.model["error-blMarkNoCount"] = "";
    if (this.gridBlMarkNo.listStore.store.length <= 0) {
      this.isError = true;
      this.model["error-blMarkNoCount"] = "Please input Mark No.";
    }

    // ************** PRE CHECK **************//
    // ----- Doing Nasty Stuff over here -----//
    const listCtr: Array<String> = [];
    const listCtr2: Array<String> = [];
    const listCheckFC: Array<String> = [];
    const listCheckFCDistinct: Array<String> = [];
    const listFC: Array<String> = [];

    // 1st Step, Checking the container
    if (this.gridBlContainer.listStore.store.length > 0) {
      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        let cTypeName: String = "";
        switch (this.gridBlContainer.listStore.store[i].blContainerType) {
          case "STD": {
            cTypeName = "NORMAL";
            break;
          }
          case "FLR": {
            cTypeName = "FLATRACK";
            break;
          }
          case "OPT": {
            cTypeName = "OPEN TOP";
            break;
          }
          case "REF": {
            cTypeName = "REEFER";
            break;
          }
          case "TNK": {
            cTypeName = "TANK";
            break;
          }
          case "PLT": {
            cTypeName = "PLATFORM";
            break;
          }
          case "UC": {
            cTypeName = "UC";
            break;
          }
          case "DRY": {
            cTypeName = "REEFER DRY";
            break;
          }
        }

        // if (this.gridBlContainer.listStore.store[i].blContainerShortShipContainer !== true) {
        if (
          !listCtr.includes(
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          )
        ) {
          listCtr.push(
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          );
        }
      }

      for (const x of listCtr) {
        let counter = 0;
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          let cTypeName: String = "";
          switch (this.gridBlContainer.listStore.store[i].blContainerType) {
            case "STD": {
              cTypeName = "NORMAL";
              break;
            }
            case "FLR": {
              cTypeName = "FLATRACK";
              break;
            }
            case "OPT": {
              cTypeName = "OPEN TOP";
              break;
            }
            case "REF": {
              cTypeName = "REEFER";
              break;
            }
            case "TNK": {
              cTypeName = "TANK";
              break;
            }
            case "PLT": {
              cTypeName = "PLATFORM";
              break;
            }
            case "UC": {
              cTypeName = "UC";
              break;
            }
            case "DRY": {
              cTypeName = "REEFER DRY";
              break;
            }
          }

          if (
            x ===
            cTypeName +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerSize +
              "-" +
              this.gridBlContainer.listStore.store[i].blContainerHeight
          ) {
            counter++;
            // }
          }
        }
        if (counter > 0) {
          listCtr2.push(counter + "-" + x);
        }
      }

      let counterShortShip = 0;
      for (const x of listCtr) {
        if (x === "[ SHORTSHIPPED CONTAINER(S). ]- - ") {
          counterShortShip++;
        }
      }
      if (counterShortShip > 0) {
        listCtr2.push(
          counterShortShip + "-" + "[ SHORTSHIPPED CONTAINER(S). ]" + "- - "
        );
      }
    }

    // 3rd Step, Checking the Freight Charge console

    if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
      for (
        let i = 0;
        i < this.gridBlNettChargeConsole.listStore.store.length;
        i++
      ) {
        if (
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
            "OFT" ||
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
            "FAI" ||
          this.gridBlNettChargeConsole.listStore.store[i].blFCChargeCode ===
            "FOF"
        ) {
          let fcCType: String = "";
          let fcCSize: String = "";
          let fcCHeight: String = "";

          // map per code to type size height
          switch (this.gridBlNettChargeConsole.listStore.store[i].blFCPerCode) {
            case "D20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "D40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "D45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "HC20": {
              fcCType = "NORMAL";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "HC40": {
              fcCType = "NORMAL";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "HC45": {
              fcCType = "NORMAL";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "FR20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "FR40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "FR45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "FRH20": {
              fcCType = "FLATRACK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "FRH40": {
              fcCType = "FLATRACK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "FRH45": {
              fcCType = "FLATRACK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "OT20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "OT40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "OT45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "OTH20": {
              fcCType = "OPEN TOP";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "OTH40": {
              fcCType = "OPEN TOP";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "OTH45": {
              fcCType = "OPEN TOP";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "R20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "R40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "R45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "RH20": {
              fcCType = "REEFER";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "RH40": {
              fcCType = "REEFER";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "RH45": {
              fcCType = "REEFER";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }

            case "TK20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "TK40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "TK45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "TKH20": {
              fcCType = "TANK";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "TKH40": {
              fcCType = "TANK";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "TKH45": {
              fcCType = "TANK";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }
            case "PL20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "86";
              break;
            }
            case "PL40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "86";
              break;
            }
            case "PL45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "86";
              break;
            }
            case "PLH20": {
              fcCType = "PLATFORM";
              fcCSize = "20";
              fcCHeight = "96";
              break;
            }
            case "PLH40": {
              fcCType = "PLATFORM";
              fcCSize = "40";
              fcCHeight = "96";
              break;
            }
            case "PLH45": {
              fcCType = "PLATFORM";
              fcCSize = "45";
              fcCHeight = "96";
              break;
            }
          }

          // patch check data for reefer dry
          if (
            this.gridBlNettChargeConsole.listStore.store[
              i
            ].blFCPerCode.startsWith("R")
          ) {
            if (
              this.gridBlNettChargeConsole.listStore.store[i]
                .blFCContainerType == "DRY"
            ) {
              fcCType = "REEFER DRY";
            }
          }

          listCheckFC.push(
            this.gridBlNettChargeConsole.listStore.store[i].blFCRevenue +
              "-" +
              fcCType +
              "-" +
              fcCSize +
              "-" +
              fcCHeight
          );
        }
      }

      for (const entry of listCheckFC) {
        if (
          !listCheckFCDistinct.includes(
            entry.split("-")[1] +
              "-" +
              entry.split("-")[2] +
              "-" +
              entry.split("-")[3]
          )
        ) {
          listCheckFCDistinct.push(
            entry.split("-")[1] +
              "-" +
              entry.split("-")[2] +
              "-" +
              entry.split("-")[3]
          );
        }
      }

      for (const entry of listCheckFCDistinct) {
        let counter = 0;
        for (const entry2 of listCheckFC) {
          console.log("cek shortship -> " + entry2);
          if (
            entry2.split("-")[1] +
              "-" +
              entry2.split("-")[2] +
              "-" +
              entry2.split("-")[3] ===
            entry
          ) {
            counter += Number.parseInt(entry2.split("-")[0]);
          }
        }
        console.log("cek shortship -> " + counter + "-" + entry);
        listFC.push(counter + "-" + entry);
      }
    }

    // *********** END OF PRE CHECK ************//

    // ***** DO THE CHECKING **** //
    this.frmDialogMessage =
      "<br>Total quantity in Container and Freight Charge not tally. Please amend before saving.<br><br>";
    this.frmDialogMessage +=
      '<table border="0" width="100%"><tr><td width="35%" style="vertical-align:top;">Container Quantity</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listCtr2.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let a = 0;
      for (const x of listCtr2) {
        if (x.split("-")[1] === "[ SHORTSHIPPED CONTAINER(S). ]") {
          this.frmDialogMessage +=
            '<tr><td width="100%" colspan="4">[ ' +
            x.split("-")[1].split("[")[0] +
            " " +
            x.split("-")[0] +
            x.split("-")[1].split("[")[1] +
            "</td></tr>";
        } else {
          this.frmDialogMessage +=
            '<tr><td width="15%">' +
            x.split("-")[0] +
            '</td><td width="35%">' +
            x.split("-")[1] +
            '</td><td width="5%">&nbsp;' +
            x.split("-")[2] +
            "</td><td>&nbsp;" +
            x.split("-")[3] +
            "</td></tr>";
        }
        a++;
      }
    }
    this.frmDialogMessage +=
      '</table></td></tr><tr><td colspan="3">&nbsp;</td></tr>';

    this.frmDialogMessage +=
      '<tr><td width="3%" style="vertical-align:top;">Freight Charge</td>' +
      '<td style="vertical-align:top;">:</td><td><table border="0" width="100%">';
    if (listFC.length === 0) {
      this.frmDialogMessage += "[NOTHING]";
    } else {
      let b = 0;
      for (const x of listFC) {
        this.frmDialogMessage +=
          '<tr><td width="15%">' +
          x.split("-")[0] +
          '</td><td width="35%">' +
          x.split("-")[1] +
          '</td><td width="5%">&nbsp;' +
          x.split("-")[2] +
          "</td><td>&nbsp;" +
          x.split("-")[3] +
          "</td></tr>";
        b++;
      }
    }
    this.frmDialogMessage += "</table></td></tr></table>";
    this.frmDialogMessage += "<br> Continue to save?<br><br>";

    let arrayCheck: Array<String> = [];
    if (listCtr2.length === 0 && listFC.length === 0) {
      arrayCheck.push("error");
    } else if (listCtr2.length >= listFC.length) {
      arrayCheck = listCtr2.filter((val) => !listFC.includes(val));
    } else if (listFC.length >= listCtr2.length) {
      arrayCheck = listFC.filter((val) => !listCtr2.includes(val));
    }
    // **** END OF CHECKING **** //

    if (!this.isError) {
      if (arrayCheck.length > 0) {
        this.isDiscrepancyExisted = true;
        this.genericUtil.showDialog(
          "blContainerChargeDiscrepancyDialogConsole",
          "BL Maintenance (New)",
          600,
          275
        );
      } else {
        this.isDiscrepancyExisted = false;
        this.onDialogBlContainerChargeDiscrepancyDialogYesConsole();
      }
    }
  }

  saveEvent() {
    this.genericUtil.showLoader();

    if (this.model.blStatus == "U") {
      this.model.blUserModified = this.cookieService.getName();
      this.model.blDateModified = this.genericUtil.getPSIDate();
    } else {
      this.model.blUserCreated = this.cookieService.getName();
      this.model.blUserModified = this.cookieService.getName();
      this.model.blDateCreated = this.genericUtil.getPSIDate();
      this.model.blDateModified = this.genericUtil.getPSIDate();
    }
    this.model.blNewRemarks = this.txtBlNettChargeNewRemarks.getValueText();
    console.log("Model before save");
    try {
      this.model.blShipperAddressText = this.sCharUtil.decodeURIComponentSafe(
        this.model.blShipperAddressText,
        1
      );
    } catch (e) {
      this.model.blShipperAddressText = this.model.blShipperAddressText;
    }

    try {
      this.model.blNotifyPartyAddressText =
        this.sCharUtil.decodeURIComponentSafe(
          this.model.blNotifyPartyAddressText,
          1
        );
    } catch (e) {
      this.model.blNotifyPartyAddressText = this.model.blNotifyPartyAddressText;
    }

    try {
      this.model.blConsigneeAddressText = this.sCharUtil.decodeURIComponentSafe(
        this.model.blConsigneeAddressText,
        1
      );
    } catch (e) {
      this.model.blConsigneeAddressText = this.model.blConsigneeAddressText;
    }
    console.log(this.model);

    // desc worker
    if (this.gridBlDesc.listStore.store.length <= 0) {
      this.genDesc();
      this.gridBlDesc.onClear();
      this.gridBlDesc.url = "";
      this.gridBlDesc.listStore.store.push(this.paramDesc);
      this.gridBlDesc.loadData();
    }

    var self = this;

    setTimeout(() => {
      //check lock dulu
      self.genericService
        .GET(
          self.configService.config.BASE_API.toString() +
            "/BL/checkBlByBlNo/" +
            self.cookieService.getDefaultLocationCode() +
            "/" +
            self.model.blNo.trim()
        )
        .subscribe((resp) => {
          if (resp.json()["status"] === "OK") {
            self.blLock = resp.json()["blLock"];
            self.blLockUserid = resp.json()["blLockByUser"]["blUserId"];

            console.log(
              self.blLock +
                " - " +
                self.blLockUserid +
                " - " +
                self.cookieService.getName()
            );

            //if(self.blLock == "" || self.blLock == "N" || (self.blLock == "Y" && self.blLockUserid == self.cookieService.getName())) {
            if (
              self.blLock === "" ||
              self.blLockUserid === self.cookieService.getName().toUpperCase()
            ) {
              //console.log("test");
              //console.log(this.paramDesc.blDescBlDutiable)
              //console.log(self.model);

              //self.model.blDesc[0].blDescBlDutiable = this.paramDesc.blDescBlDutiable;

              //console.log(self.model.blDesc[0].blDescBlDutiable);

              //patch: emergency
              //bl_desc_awkward => gross measure
              //bl_desc_dg2 => gross weight
              //bl_desc_dg2r => nett weight
              //Before we pass on the parameter to the server we must check the condition, if the decimal is not > 0, then use teh value from the text as decimal
              //This to avoid weight later on not printed on the BL body, cargo manifest, and docsys edi
              //Because the one that used to check is the decimal
              console.log('check dt');
              console.log(this.model);
              //if (+this.model.blDesc[0].blDescAwkward >= 0) {
              if (this.model.blDesc[0] && this.model.blDesc[0].blDescAwkward) {
                this.model.blDesc[0].blDescGrossMeasure = +this.model.blDesc[0].blDescAwkward;
              }
              //}

              //if (+this.model.blDesc[0].blDescDg2 >= 0) {
              if (this.model.blDesc[0] && this.model.blDesc[0].blDescDg2) {
                this.model.blDesc[0].blDescGrossWeight = this.parseCommaSeparatorNumber(this.model.blDesc[0].blDescDg2);
              }
              //}

              //if (+this.model.blDesc[0].blDescDg2R >= 0) {
              if (this.model.blDesc[0] && this.model.blDesc[0].blDescDg2R) {
                this.model.blDesc[0].blDescNettWeight = this.parseCommaSeparatorNumber(this.model.blDesc[0].blDescDg2R);
              }
              //}


              self.genericService
                .POST(
                  this.configService.config.BASE_API.toString() + "/BL/save",
                   //"http://localhost:15000/BL/save",
                  self.model
                )
                .subscribe((resp) => {
                  if (resp.json()["status"] === "OK") {
                    if (self.model.blIsFinished === true) {
                      self.frmDialogMessage = "BL saved successfully.";
                    } else {
                      self.frmDialogMessage = "BL partially saved.";
                    }
                    self.genericUtil.showDialog(
                      "blSaveDialogOkOnly",
                      "Information",
                      350,
                      150
                    );
                  }

                  self.genericUtil.hideLoader();
                  console.log(this.paramContainer);
                });
            } else {
              self.genericUtil.hideLoader();
              self.frmDialogMessage =
                "Your session for this BL has ended. Unable to save.";
              self.genericUtil.showDialog(
                "blSaveDialogOkOnly",
                "Information",
                350,
                150
              );
            }
          }
        });
    }, 50);

    /*
    setTimeout(() => {
      self.genericService.POST(self.configService.config.BASE_API.toString() + '/BL/save', self.model).subscribe((resp) => {
        if (resp.json()['status'] === 'OK') {
          if (self.model.blIsFinished === true) {
            self.frmDialogMessage = 'BL saved successfully.';
          } else {
            self.frmDialogMessage = 'BL partially saved.';
          }
          self.genericUtil.showDialog('blSaveDialogOkOnly', 'Information', 350, 150);
        }

        self.genericUtil.hideLoader();

        console.log(this.paramContainer);

      });
    }, 50);
    */
  }

  //  delete event
  handleDelete() {
    this.frmDialogMessage = "Do you really want to delete this BL?";
    this.genericUtil.showDialog("blDeleteDialogYesNo", "Confirm", 300, 150);
  }

  onDialogBlYesDelete() {
    let deleteDate: string = this.genericUtil.getPSIDate();

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/delete/" +
          this.model.blOfficeCode.trim() +
          "/" +
          this.model.blNo.trim() +
          "/" +
          this.cookieService.getName() +
          "/" +
          deleteDate
      )
      .subscribe((resp) => {
        if (resp.json()["status"] === "OK") {
          console.log("delete masuk sini ");
          this.genericUtil.closeDialog("blDeleteDialogYesNo");

          // on Successfull Deletion, do unlock (Miki case, super long pending 18/march/2019
          this.doUnlock(
            this.cookieService.getDefaultLocationCode().toUpperCase(),
            this.model.blNo,
            this.cookieService.getName().toUpperCase()
          );

          this.frmDialogMessage = "BL Successfully deleted.";
          this.genericUtil.showDialog(
            "blDeleteDialogOk",
            "Information",
            300,
            150
          );
        } else {
          console.log("delete masuk sana ");
          this.genericUtil.closeDialog("blDeleteDialogYesNo");
          this.frmDialogMessage = resp.json()["message"].toString();
          this.genericUtil.showDialog(
            "blDeleteDialogOk",
            "Information",
            500,
            200
          );
          this.doUnlock(
            this.cookieService.getDefaultLocationCode().toUpperCase(),
            this.model.blNo,
            this.cookieService.getName().toUpperCase()
          );
        }
      });
  }

  onDialogBlOk() {
    this.lockTab1 = false;
    this.lockTab2 = false;
    this.lockTab3 = false;
    this.lockTab4 = false;
    this.lockTab5 = false;
    this.lockTab6 = false;
    this.lockTab8 = false;

    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    this.isInsideRetrieveMode = false;

    this.disableToolbarButtons =
      "partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";

    this.tabPartyClicked();

    this.genericUtil.closeDialog("blDeleteDialogOk");
  }

  onDialogBlNoDelete() {
    this.genericUtil.closeDialog("blDeleteDialogYesNo");
  }

  onDialogBlDeleteOk() {
    this.genericUtil.closeDialog("blDeleteDialogOkOnly");
  }

  //  Validator BL
  setValidatorBlRetrieval() {
    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "max-length[16]",
            prompt:
              "Please check BL Number. Maximum length allowed is 16 characters.",
          },
          {
            type: "empty",
            prompt: "Please input B/L No.",
          },
        ],
      },
    };
  }

  setValidatorBlSwOis() {
    this.isError = false;
    this.model["error-blNo"] = "";
    this.model["error-blShipperId"] = "";
    this.model["error-blShipperAddress"] = "";
    this.model["error-blConsigneeAddress"] = "";
    this.model["error-blNotifyPartyAddress"] = "";
    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blDeliveryTypeCode"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blOceanVesselId"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blMarkNoCount"] = "";
    this.model["error-blShipper"] = "";
    this.model["error-blFaiGroup"] = "";
    this.model["error-blShortshipment"] = "";

    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Number.",
          },
        ],
      },
      blShipperId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Shipper. Blank is NOT allowed.",
          },
        ],
      },
      blReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Receipt Type should not be empty.",
          },
        ],
      },
      blDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Delivery Type should not be empty.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Load Port should not be empty.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Discharge Port should not be empty.",
          },
        ],
      },
      blOceanVesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel bound.",
          },
        ],
      },
    };
  }

  setValidatorBlSwOisPartial() {
    this.isError = false;
    this.model["error-blNo"] = "";
    this.model["error-blShipperId"] = "";
    this.model["error-blShipperAddress"] = "";
    this.model["error-blConsigneeAddress"] = "";
    this.model["error-blNotifyPartyAddress"] = "";

    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blDeliveryTypeCode"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blOceanVesselId"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";

    this.model["error-blShipper"] = "";
    this.model["error-blFaiGroup"] = "";
    this.model["error-blShortshipment"] = "";
    this.model["error-payerValidator"] = "";
    this.model["error-popValidator"] = "";
    this.model["error-nextVesselValidator"] = "";

    this.model.payerValidator = false;
    this.model.popValidator = false;

    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Number.",
          },
        ],
      },
      // blShipperId: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please input Shipper. Blank is NOT allowed.",
      //     },
      //   ],
      // },
      // blReceiptTypeCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Receipt Type should not be empty.",
      //     },
      //   ],
      // },
      // blDeliveryTypeCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Delivery Type should not be empty.",
      //     },
      //   ],
      // },
      // blLoadPortCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Load Port should not be empty.",
      //     },
      //   ],
      // },
      // blDischargePortCode: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Discharge Port should not be empty.",
      //     },
      //   ],
      // },
      // blOceanVesselId: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please check Ocean-vessel.",
      //     },
      //   ],
      // },
      // blOceanVesselVoyage: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please check Ocean-vessel voyage.",
      //     },
      //   ],
      // },
      // blOceanVesselBound: {
      //   rules: [
      //     {
      //       type: "empty",
      //       prompt: "Please check Ocean-vessel bound.",
      //     },
      //   ],
      // },
    };
  }

  setValidatorNa() {
    this.isError = false;
    this.model["error-blNo"] = "";
    this.model["error-blShipperId"] = "";
    this.model["error-blShipperAddress"] = "";
    this.model["error-blConsigneeAddress"] = "";
    this.model["error-blNotifyPartyAddress"] = "";
    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blDeliveryTypeCode"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blOceanVesselId"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blMarkNoCount"] = "";
    this.model["error-blShipper"] = "";
    this.model["error-blFaiGroup"] = "";
    this.model["error-blShortshipment"] = "";
    this.model["error-payerValidator"] = "";
    this.model["error-popValidator"] = "";
    this.model["error-nextVesselValidator"] = "";
    this.model["error-blDoiAndSob"] = "";
    this.model["error-character"] = "";
    this.model.payerValidator = false;
    this.model.popValidator = false;

    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Number.",
          },
        ],
      },
      /*blShipperId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Shipper. Blank is NOT allowed.",
          },
        ],
      },*/
      blReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Receipt Type should not be empty.",
          },
        ],
      },
      blDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Delivery Type should not be empty.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Load Port should not be empty.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Discharge Port should not be empty.",
          },
        ],
      },
      blOceanVesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel bound.",
          },
        ],
      },
      payerValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Invalid Payer detected. Please check your charges.",
          },
        ],
      },
      popValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Invalid Place of Payment. Please check your charges.",
          },
        ],
      },
    };
  }

  setValidatorNa2() {
    this.isError = false;
    this.model["error-blNo"] = "";
    this.model["error-blShipperId"] = "";
    this.model["error-blShipperAddress"] = "";
    this.model["error-blConsigneeAddress"] = "";
    this.model["error-blNotifyPartyAddress"] = "";
    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blDeliveryTypeCode"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blOceanVesselId"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blMarkNoCount"] = "";
    this.model["error-blShipper"] = "";
    this.model["error-blFaiGroup"] = "";
    this.model["error-blShortshipment"] = "";
    this.model["error-payerValidator"] = "";
    this.model["error-popValidator"] = "";
    this.model.payerValidator = false;
    this.model.popValidator = false;
    this.model["error-blConsigneeId"] = "";
    this.model["error-blNotifyPartyId"] = "";
    this.model["error-bothConsigneeAndParty"] = "";
    this.model["error-nextVesselValidator"] = "";
    this.model["error-blDoiAndSob"] = "";
    this.model["error-character"] = "";

    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Number.",
          },
        ],
      },
      blShipperId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Shipper. Blank is NOT allowed",
          },
        ],
      },
      blReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Receipt Type should not be empty.",
          },
        ],
      },
      blDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Delivery Type should not be empty.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Load Port should not be empty.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Discharge Port should not be empty.",
          },
        ],
      },
      blOceanVesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel bound.",
          },
        ],
      },
      payerValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Invalid Payer detected. Please check your charges.",
          },
        ],
      },
      popValidator: {
        rules: [
          {
            type: "custom",
            prompt: "Invalid Place of Payment. Please check your charges.",
          },
        ],
      },
      blNotifyPartyId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Notify Party. Blank is NOT allowed.",
          },
        ],
      },
      blConsigneeId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Consignee. Blank is NOT allowed.",
          },
        ],
      },
    };
  }

  setValidatorNaPartialSave() {
    this.isError = false;
    this.model["error-blNo"] = "";
    this.model["error-blShipperId"] = "";
    this.model["error-blShipperAddress"] = "";
    this.model["error-blConsigneeAddress"] = "";
    this.model["error-blNotifyPartyAddress"] = "";
    this.model["error-blReceiptTypeCode"] = "";
    this.model["error-blDeliveryTypeCode"] = "";
    this.model["error-blLoadPortCode"] = "";
    this.model["error-blDischargePortCode"] = "";
    this.model["error-blOceanVesselId"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model["error-blMarkNoCount"] = "";
    this.model["error-blShipper"] = "";
    this.model["error-blFaiGroup"] = "";
    this.model["error-blShortshipment"] = "";
    this.model["error-payerValidator"] = "";
    this.model["error-popValidator"] = "";
    this.model["error-nextVesselValidator"] = "";
    this.model.payerValidator = false;
    this.model.popValidator = false;

    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Number.",
          },
        ],
      },
      /*blShipperId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Shipper. Blank is NOT allowed.",
          },
        ],
      },*/
      blReceiptTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Receipt Type should not be empty.",
          },
        ],
      },
      blDeliveryTypeCode: {
        rules: [
          {
            type: "empty",
            prompt: "Delivery Type should not be empty.",
          },
        ],
      },
      blLoadPortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Load Port should not be empty.",
          },
        ],
      },
      blDischargePortCode: {
        rules: [
          {
            type: "empty",
            prompt: "Discharge Port should not be empty.",
          },
        ],
      },
      blOceanVesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please check Ocean-vessel bound.",
          },
        ],
      },
    };
  }

  changeEventRbBlPlacePreVesselFooter(event) {
    if (event.target.checked) {
      this.model.blPreVesselFooterOnBl = true;
      this.model.blOceanVesselFooterOnBl = false;
      this.model.blNextVesselFooterOnBl = false;
    } else {
      this.model.blPreVesselFooterOnBl = false;
    }
  }

  changeEventRbBlPlaceOceanVesselFooter(event) {
    if (event.target.checked) {
      this.model.blPreVesselFooterOnBl = false;
      this.model.blOceanVesselFooterOnBl = true;
      this.model.blNextVesselFooterOnBl = false;
    } else {
      this.model.blOceanVesselFooterOnBl = false;
    }
  }

  changeEventRbBlPlaceNextVesselFooter(event) {
    if (event.target.checked) {
      this.model.blPreVesselFooterOnBl = false;
      this.model.blOceanVesselFooterOnBl = false;
      this.model.blNextVesselFooterOnBl = true;
    } else {
      this.model.blNextVesselFooterOnBl = false;
    }
  }

  eventBtnUndoRadio() {
    this.model.blPreVesselFooterOnBl = false;
    this.model.blOceanVesselFooterOnBl = false;
    this.model.blNextVesselFooterOnBl = false;
  }

  onSelectDegreeTemp(val) {
    /*
     this.paramContainer = val;
     if (this.model.bReeferDegree !== '') {
     this.model.bReeferDegree = this.bDegree.toString() +  this.bDegreeTemp;
     }
     //  console.log(this.model.bReeferDegree);
     */
  }

  onDialogNotifyPartyAddressYes() {
    var self = this;
    this.model.blNotifyPartyId = "CS0000090987";
    this.model.blNotifyPartyName = "***";
    this.cbBlPartyNotifyParty.setForceValue(this.model.blNotifyPartyName);
    this.model.blNotifyPartyAddressText = "";
    this.model.blNotifyPartyAddressOption = 0;

    this.cbBlPartyNotifyPartyAddress.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCustomerAddressByCustomerId/" +
        this.model.blNotifyPartyId.trim()
    );
    this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
      self.cbBlPartyNotifyPartyAddress.setValue(
        String(self.model.blNotifyPartyAddressOption).toString() === "0"
          ? ""
          : String(self.model.blNotifyPartyAddressOption).toString()
      );
      self.txtBlPartyNotifyPartyAddress.setValue(
        self.txtBlPartyConsigneeAddress.getValue()
      );
    });

    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddress");

    // this.txtBlPartyNotifyPartyAddress.setValue(this.txtBlPartyConsigneeAddress.getValue());
  }

  onDialogNotifyPartyAddressNo() {
    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddress");
  }

  onDialogNotifyPartyAddressYesAsCnee() {
    console.log("AS CNEE")
    var self = this;
    this.model.blNotifyPartyId = this.model.blConsigneeId;
    this.model.blNotifyPartyName = this.model.blConsigneeName;
    this.cbBlPartyNotifyParty.setForceValue(
      this.model.blNotifyPartyName.toUpperCase().replace(/&NBSP;/g, " ")
    );
    this.model.blNotifyPartyAddressText = "";
    this.model.blNotifyPartyAddressOption = 0;

    this.cbBlPartyNotifyPartyAddress.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCustomerAddressByCustomerId/" +
        this.model.blNotifyPartyId.trim()+
        "/Y"
    );
    this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
      self.cbBlPartyNotifyPartyAddress.setValue(
        String( self.model.blConsigneeAddressOption).toString() === "0"
          ? ""
          : String( self.model.blConsigneeAddressOption).toString()
      );
      self.txtBlPartyNotifyPartyAddress.setValue(
        self.txtBlPartyConsigneeAddress.getValue()
      );
    });

    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddressAsCnee");

    // this.txtBlPartyNotifyPartyAddress.setValue(this.txtBlPartyConsigneeAddress.getValue());
  }

  onDialogNotifyPartyAddressNoAsCnee() {
    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddressAsCnee");
  }

  onDialogNotifyPartyAddressYes2() {
    var self = this;
    this.model.blNotifyPartyId = "CS0000090987";
    this.model.blNotifyPartyName = "***";
    this.cbBlPartyNotifyParty.setForceValue(this.model.blNotifyPartyName);
    this.model.blNotifyPartyAddressText = "";
    this.model.blNotifyPartyAddressOption = 0;

    this.cbBlPartyNotifyPartyAddress.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findCustomerAddressByCustomerId/" +
        this.model.blNotifyPartyId.trim()
    );
    this.cbBlPartyNotifyPartyAddress.loadSelect(function () {
      self.cbBlPartyNotifyPartyAddress.setValue(
        String(self.model.blNotifyPartyAddressOption).toString() === "0"
          ? ""
          : String(self.model.blNotifyPartyAddressOption).toString()
      );
      self.txtBlPartyNotifyPartyAddress.setValue("SAME AS CONSIGNEE");
    });
    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddress2");

    //this.genericUtil.closeDialog('blOverwriteNotifyPartyAddress2');
  }

  onDialogNotifyPartyAddressNo2() {
    this.genericUtil.closeDialog("blOverwriteNotifyPartyAddress2");
  }

  //  flash point handler
  changeEventBlContainerFlashPointScale(event) {
    if (
      event.flashPointScaleCode != null &&
      event.flashPointScaleCode !== undefined
    ) {
      // this.paramContainer["error-blContainerFlashPointScale"] = "Please input temperature";
      this.paramContainer.blContainerFlashPointScale =
        event.flashPointScaleCode;
    }
  }

  changeEventBlContainerOogScale(event) {
    if (event.oogScaleCode != null && event.oogScaleCode !== undefined) {
      this.paramContainer.blContainerOogScale = event.oogScaleCode;
    }
  }

  changeEventBlContainerReeferScale(event) {
    if (event.reeferScaleCode != null && event.reeferScaleCode !== undefined) {
      this.paramContainer.blContainerReeferScale = event.reeferScaleCode;
    }
  }

  onDialogConfirmDeleteContainerYes() {
    this.gridBlContainer.url = "";
    const dt = this.gridBlContainer.getSelectedValues()[0];
    this.rowDataADContainer.splice(0, this.rowDataADContainer.length);
    this.rowDataContainerHs.splice(0, this.rowDataContainerHs.length);
    this.gridBlContainer.listStore.removeData(dt);
    this.gridBlContainer.loadData();
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      if (this.gridBlContainer.listStore.store[i].blContainerExtra && this.gridBlContainer.listStore.store[i].blContainerSeq && this.gridBlContainer.listStore.store[i].blContainerNumber) {
        this.gridBlContainer.listStore.store[i].blContainerSeq =
          this.gridBlContainer.listStore.store[i].no;

        this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerSeq = this.gridBlContainer.listStore.store[i].blContainerSeq;
        this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerNumber = this.gridBlContainer.listStore.store[i].blContainerNumber;


        for (let j = 0; j < this.gridBlContainer.listStore.store[i].blAdContainer.length; j++) {
          this.gridBlContainer.listStore.store[i].blAdContainer[j].blContainerSeq = this.gridBlContainer.listStore.store[i].blContainerSeq;
          this.gridBlContainer.listStore.store[i].blAdContainer[j].blContainerNumber = this.gridBlContainer.listStore.store[i].blContainerNumber;
        }

        for (let k = 0; k < this.gridBlContainer.listStore.store[i].blContainerHSCode.length; k++) {
          this.gridBlContainer.listStore.store[i].blContainerHSCode[k].blContainerHSContainerSeq = this.gridBlContainer.listStore.store[i].blContainerSeq;
          this.gridBlContainer.listStore.store[i].blContainerHSCode[k].blContainerHSContainerNumber = this.gridBlContainer.listStore.store[i].blContainerNumber;
        }
      }
    }
    this.gridBlContainer.loadData();
    this.resetBlContainerForm();
    this.btnBlContainerNew = true;
    this.btnBlContainerSave = false;
    this.btnBlContainerDelete = false;
    this.btnBlContainerCancel = false;
    this.btnBlContainerHSNew = false;
    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }
    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("blConfirmDeleteContainer");
  }

  onDialogConfirmDeleteContainerNo() {
    this.genericUtil.closeDialog("blConfirmDeleteContainer");
  }

  onDialogConfirmDeleteContainerYesHS() {
    this.blContainerHSUpdateMode = "insert";
    this.txtBlContainerHSSaveUpdate = "Save";
    // this.gridBlContainerHS.url = "";
    // const dt = this.gridBlContainerHS.getSelectedValues()[0];
    // this.gridBlContainerHS.listStore.removeData(dt);
    // this.gridBlContainerHS.loadData();
    // for (let i = 0; i < this.gridBlContainerHS.listStore.store.length; i++) {
    //   this.gridBlContainerHS.listStore.store[i].blContainerHSSeq =
    //     this.gridBlContainerHS.listStore.store[i].no;
    // }

    let newRowDataHs = this.rowDataContainerHs.slice();
    newRowDataHs.splice(
      newRowDataHs.findIndex(v => v.blContainerHSSeq === this.paramContainerHSCode.blContainerHSSeq), 1
    );

    for (let i = 0; i < newRowDataHs.length; i++) {
      newRowDataHs[i].blContainerHSSeq = i + 1
    }
    this.rowDataContainerHs = newRowDataHs;

    // this.gridBlContainerHS.loadData();
    this.btnBlContainerHSNew = false;
    this.btnBlContainerHSSave = true;
    this.btnBlContainerHSDelete = false;
    this.btnBlContainerHSCancel = true;
    this.blBlContainerHSCancel();
    this.genericUtil.closeDialog("blConfirmDeleteContainerHS");
  }

  onDialogConfirmDeleteContainerNoHS() {
    this.genericUtil.closeDialog("blConfirmDeleteContainerHS");
  }

  // yg-sbhd
  onDialogConfirmDeleteAdContainerYes() {
    this.blAdContainerUpdateMode = "insert";
    this.txtBlAdContainerSaveUpdate = "Save";

    let newRowData = this.rowDataADContainer.slice();
    newRowData.splice(
      newRowData.findIndex(v => v.blAdContainerSeq === this.paramBlAdContainer.blAdContainerSeq), 1
    );

    for (let i = 0; i < newRowData.length; i++) {
      newRowData[i].blAdContainerSeq = i + 1
    }

    // console.log("after 2 = ", newRowData);
    this.rowDataADContainer = newRowData;
    console.log("row data ad container = ", this.rowDataADContainer);
    this.blBlAdContainerAutoUpdate();

    // for (let i = 0; i < this.gridBlAdContainer.listStore.store.length; i++) {
    //   this.gridBlAdContainer.listStore.store[i].blAdContainerSeq =
    //     this.gridBlAdContainer.listStore.store[i].no;
    // }
    // this.gridBlAdContainer.loadData();
    this.btnBlAdContainerNew = false;
    this.btnBlAdContainerSave = true;
    this.btnBlAdContainerDelete = false;
    this.btnBlAdContainerCancel = true;
    this.genericUtil.closeDialog("blConfirmDeleteAdContainer");
    this.blBlAdContainerCancelDialog();
    // this.genericUtil.closeDialog("blBLAdContainerAddDialog");

  }

  onDialogConfirmDeleteAdContainerNo() {
    this.genericUtil.closeDialog("blConfirmDeleteAdContainer");
  }
  // end yg-sbhd

  onDialogConfirmDeleteMarkNoYes() {
    this.gridBlMarkNo.url = "";
    const dt = this.gridBlMarkNo.getSelectedValues()[0];
    this.gridBlMarkNo.listStore.removeData(dt);
    this.gridBlMarkNo.loadData();
    for (let i = 0; i < this.gridBlMarkNo.listStore.store.length; i++) {
      this.gridBlMarkNo.listStore.store[i].blMarkNoSeq =
        this.gridBlMarkNo.listStore.store[i].no;
    }
    this.gridBlMarkNo.loadData();
    this.btnBlMarkNoNew = true;
    this.btnBlMarkNoSave = false;
    this.btnBlMarkNoDelete = false;
    this.btnBlMarkNoCancel = false;
    this.btnBlMarkNoNoMark = true;
    this.blMarkNoUpdateMode = "insert";
    this.txtBlMarkNoSaveUpdate = "Save";
    this.eventBtnBlMarkNoCancel();
    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("blConfirmDeleteMarkNo");
  }

  onDialogConfirmDeleteMarkNoNo() {
    this.genericUtil.closeDialog("blConfirmDeleteMarkNo");
  }

  onDialogConfirmDeleteDescYes() {
    this.gridBlDesc.url = "";
    this.gridBlDesc.listStore.removeData(this.gridBlDesc.listStore.store[0]);
    this.gridBlDesc.loadData();
    this.paramDesc = new BLDesc();
    this.btnBlDescCancel();
    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("blConfirmDeleteDesc");
  }

  onDialogConfirmDeleteDescNo() {
    this.genericUtil.closeDialog("blConfirmDeleteDesc");
  }

  onDialogConfirmDeleteNettChargeYes() {
    this.gridBlNettCharge.url = "";
    // this.gridBlNettCharge.listStore.removeData(this.gridBlNettCharge.listStore.store[0]);
    const dt = this.gridBlNettCharge.getSelectedValues()[0];
    this.gridBlNettCharge.listStore.removeData(dt);
    this.gridBlNettCharge.loadData();
    for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
      this.gridBlNettCharge.listStore.store[i].blFCSeq =
        this.gridBlNettCharge.listStore.store[i].no;
    }
    this.gridBlNettCharge.loadData();

    this.paramFreightCharge = new BLFreightCharge();
    this.onBlNettChargeEntryReset();
    this.modeFormBlNettCharge = "view";
    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    this.isErrorNettChargeEntry = false;
    this.paramFreightCharge["error-blFCChargeCode"] = "";
    this.paramFreightCharge["error-blFCPayer"] = "";
    this.paramFreightCharge["error-blFCPerCode"] = "";
    this.paramFreightCharge["error-blFCCurrencyCode"] = "";
    this.paramFreightCharge["error-blFCDuplicateRecord"] = "";
    this.paramFreightCharge["error-blFCFaiGroup"] = "";
    this.paramFreightCharge["error-blFCRevenue"] = "";
    this.paramFreightCharge["error-blFCPc"] = "";

    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("blConfirmDeleteNettCharge");
  }

  onDialogConfirmDeleteNettChargeYesConsole() {
    this.gridBlNettChargeConsole.url = "";

    const dt = this.gridBlNettChargeConsole.getSelectedValues()[0];
    this.gridBlNettChargeConsole.listStore.removeData(dt);
    this.gridBlNettChargeConsole.loadData();
    for (
      let i = 0;
      i < this.gridBlNettChargeConsole.listStore.store.length;
      i++
    ) {
      this.gridBlNettChargeConsole.listStore.store[i].blFCSeq =
        this.gridBlNettChargeConsole.listStore.store[i].no;
    }
    this.gridBlNettChargeConsole.loadData();

    this.paramFreightChargeConsole = new BLFreightCharge();
    this.onBlNettChargeEntryResetConsole();
    this.modeFormBlNettChargeConsole = "view";
    if (this.model.blStatus === "D" || this.isOpenedByOtherUser === true) {
      this.disableToolbarButtons =
        "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";
    } else {
      if (this.officeCode == "THBKK") {
        this.disableToolbarButtons = "retrieve,ias,printInvoice,partialSave2,save2";
      } else {
        this.disableToolbarButtons = "retrieve,ias,partialSave2,save2";
      }
    }
    this.lockParty = false;
    this.lockPlace = false;
    this.lockContainer = false;
    this.lockMarkNo = false;
    this.lockDesc = false;
    this.lockNettCharge = false;
    this.lockGrossCharge = false;
    this.lockNettChargeConsole = false;
    this.lockGrossChargeConsole = false;

    this.isErrorNettChargeEntryConsole = false;
    this.paramFreightChargeConsole["error-blFCChargeCode"] = "";
    this.paramFreightChargeConsole["error-blFCPayer"] = "";
    this.paramFreightChargeConsole["error-blFCPerCode"] = "";
    this.paramFreightChargeConsole["error-blFCCurrencyCode"] = "";
    this.paramFreightChargeConsole["error-blFCDuplicateRecord"] = "";
    this.paramFreightChargeConsole["error-blFCFaiGroup"] = "";
    this.paramFreightChargeConsole["error-blFCRevenue"] = "";
    this.paramFreightChargeConsole["error-blFCPc"] = "";

    this.isRefreightButtonClickedConsole = false;
    this.genericUtil.closeDialog("blConfirmDeleteNettChargeConsole");
  }

  onDialogConfirmDeleteNettChargeNo() {
    this.genericUtil.closeDialog("blConfirmDeleteNettCharge");
  }

  onDialogConfirmDeleteNettChargeNoConsole() {
    this.genericUtil.closeDialog("blConfirmDeleteNettChargeConsole");
  }

  onDialogConfirmDeleteNettChargeExchangeRateYes() {
    this.gridBlNettChargeExchangeRate.url = "";
    const dt = this.gridBlNettChargeExchangeRate.getSelectedValues()[0];
    this.gridBlNettChargeExchangeRate.listStore.removeData(dt);
    this.gridBlNettChargeExchangeRate.loadData();
    for (
      let i = 0;
      i < this.gridBlNettChargeExchangeRate.listStore.store.length;
      i++
    ) {
      this.gridBlNettChargeExchangeRate.listStore.store[i].blExchangeRateBlSeq =
        this.gridBlNettChargeExchangeRate.listStore.store[i].no;
    }
    this.gridBlNettChargeExchangeRate.loadData();
    this.onDialogSERCancel();
    this.genericUtil.closeDialog("blConfirmDeleteNettChargeExchangeRate");
  }

  onDialogConfirmDeleteNettChargeExchangeRateNo() {
    this.genericUtil.closeDialog("blConfirmDeleteNettChargeExchangeRate");
  }

  tabPartyClicked() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabPlaceClicked() {
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabContainerClicked() {
    this.tabStatus = "tab3";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "active";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "";

    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      if (this.model.blContainerStatus === "EMPTY") {
        this.paramContainer.blContainerIsEmpty = true;
        this.paramContainer.blContainerIsEmptyString = "Y";
      }

      if (this.model.blContainerType === "DRY") {
        this.paramContainer.blContainerIsDry = true;
        this.paramContainer.blContainerIsDryString = "Y";
      }
    }


    if(this.gridBlDesc.listStore.store.length > 0){
      if (this.gridBlDesc.listStore.store[0].blDescDescriptionLock !== true) {
      let descText = "";
      let descTextClean = "";
      let countStd20 = 0;
      let countStd40 = 0;
      let countStd45 = 0;
      let countStd20E = 0;
      let countStd40E = 0;
      let countStd45E = 0;
      let countStd20hc = 0;
      let countStd40hc = 0;
      let countStd45hc = 0;
      let countStd20Ehc = 0;
      let countStd40Ehc = 0;
      let countStd45Ehc = 0;

      let countFlr20 = 0;
      let countFlr40 = 0;
      let countFlr45 = 0;
      let countFlr20E = 0;
      let countFlr40E = 0;
      let countFlr45E = 0;
      let countFlr20hc = 0;
      let countFlr40hc = 0;
      let countFlr45hc = 0;
      let countFlr20Ehc = 0;
      let countFlr40Ehc = 0;
      let countFlr45Ehc = 0;

      let countOpt20 = 0;
      let countOpt40 = 0;
      let countOpt45 = 0;
      let countOpt20E = 0;
      let countOpt40E = 0;
      let countOpt45E = 0;
      let countOpt20hc = 0;
      let countOpt40hc = 0;
      let countOpt45hc = 0;
      let countOpt20Ehc = 0;
      let countOpt40Ehc = 0;
      let countOpt45Ehc = 0;

      let countPlt20 = 0;
      let countPlt40 = 0;
      let countPlt45 = 0;
      let countPlt20E = 0;
      let countPlt40E = 0;
      let countPlt45E = 0;
      let countPlt20hc = 0;
      let countPlt40hc = 0;
      let countPlt45hc = 0;
      let countPlt20Ehc = 0;
      let countPlt40Ehc = 0;
      let countPlt45Ehc = 0;

      let countRef20 = 0;
      let countRef40 = 0;
      let countRef45 = 0;
      let countRef20E = 0;
      let countRef40E = 0;
      let countRef45E = 0;
      let countRef20hc = 0;
      let countRef40hc = 0;
      let countRef45hc = 0;
      let countRef20Ehc = 0;
      let countRef40Ehc = 0;
      let countRef45Ehc = 0;

      let countDry20 = 0;
      let countDry40 = 0;
      let countDry45 = 0;
      let countDry20E = 0;
      let countDry40E = 0;
      let countDry45E = 0;
      let countDry20hc = 0;
      let countDry40hc = 0;
      let countDry45hc = 0;
      let countDry20Ehc = 0;
      let countDry40Ehc = 0;
      let countDry45Ehc = 0;

      let countTnk20 = 0;
      let countTnk40 = 0;
      let countTnk45 = 0;
      let countTnk20E = 0;
      let countTnk40E = 0;
      let countTnk45E = 0;
      let countTnk20hc = 0;
      let countTnk40hc = 0;
      let countTnk45hc = 0;
      let countTnk20Ehc = 0;
      let countTnk40Ehc = 0;
      let countTnk45Ehc = 0;

      let countUc20 = 0;
      let countUc40 = 0;
      let countUc45 = 0;
      let countUc20E = 0;
      let countUc40E = 0;
      let countUc45E = 0;
      let countUc20hc = 0;
      let countUc40hc = 0;
      let countUc45hc = 0;
      let countUc20Ehc = 0;
      let countUc40Ehc = 0;
      let countUc45Ehc = 0;

      for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
        if (
          this.gridBlContainer.listStore.store[i].blContainerIsEmpty === true
        ) {
          // NORMAL
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45Ehc++;
            }
          }

          // FLAT RACK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45Ehc++;
            }
          }

          // OPEN TOP
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45Ehc++;
            }
          }

          // PLATFORM
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45Ehc++;
            }
          }

          if (
            this.gridBlContainer.listStore.store[i].blContainerIsDry === true
          ) {
            // REEFER DRY
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countDry20E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countDry20Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countDry40E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countDry40Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countDry45E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countDry45Ehc++;
              }
            }
          } else {
            // REEFER
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countRef20E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countRef20Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countRef40E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countRef40Ehc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countRef45E++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countRef45Ehc++;
              }
            }
          }

          // TANK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45Ehc++;
            }
          }

          // UC
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40Ehc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45E++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45Ehc++;
            }
          }
        } else {
          // NORMAL
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countStd20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countStd40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "STD" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countStd45hc++;
            }
          }

          // FLAT RACK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countFlr20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countFlr40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "FLR" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countFlr45hc++;
            }
          }

          // OPEN TOP
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countOpt20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countOpt40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "OPT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countOpt45hc++;
            }
          }

          // PLATFORM
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countPlt20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countPlt40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "PLT" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countPlt45hc++;
            }
          }

          if (
            this.gridBlContainer.listStore.store[i].blContainerIsDry === true
          ) {
            // REEFER DRY
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countDry20++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countDry20hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countDry40++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countDry40hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countDry45++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "DRY" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countDry45hc++;
              }
            }
          } else {
            // REEFER
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countRef20++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "20"
              ) {
                countRef20hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countRef40++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "40"
              ) {
                countRef40hc++;
              }
            }
            if (
              this.gridBlContainer.listStore.store[i].blContainerHeight ===
              "86"
            ) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countRef45++;
              }
            } else {
              if (
                this.gridBlContainer.listStore.store[i].blContainerType ===
                  "REF" &&
                this.gridBlContainer.listStore.store[i].blContainerSize ===
                  "45"
              ) {
                countRef45hc++;
              }
            }
          }

          // TANK
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countTnk20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countTnk40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "TNK" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countTnk45hc++;
            }
          }

          // UC
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "20"
            ) {
              countUc20hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "40"
            ) {
              countUc40hc++;
            }
          }
          if (
            this.gridBlContainer.listStore.store[i].blContainerHeight === "86"
          ) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45++;
            }
          } else {
            if (
              this.gridBlContainer.listStore.store[i].blContainerType ===
                "UC" &&
              this.gridBlContainer.listStore.store[i].blContainerSize === "45"
            ) {
              countUc45hc++;
            }
          }
        }
      }

      // COC
      let blContainerOwnershipText = "";
      let blDescEnding = "";
      if (this.model.blContainerOwnership === "COC") {
        blContainerOwnershipText = "COC LOCAL";
        /**
         // 00. Detection of Detention and Demurrage
         if (this.model.blDetentionRequest > 0 || this.model.blDemurrageRequest > 0 ) {
          blDescEnding = 'DETENTION IS ' + this.model.blDetentionRequest + ' DAYS AND DEMURRAGE IS '
            + this.model.blDemurrageRequest + ' DAYS';
        }


         // 01. Detection of OOG
         const oogList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let oogInfo = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            oogInfo = 'OH=' + this.gridBlContainer.listStore.store[i].blContainerOogOH;
            oogInfo += ' ' + 'OWL=' + this.gridBlContainer.listStore.store[i].blContainerOogOWL;
            oogInfo += ' ' + 'OWR=' + this.gridBlContainer.listStore.store[i].blContainerOogOWR;
            oogInfo += ' ' + 'OLF=' + this.gridBlContainer.listStore.store[i].blContainerOogOLF;
            oogInfo += ' ' + 'OLB=' + this.gridBlContainer.listStore.store[i].blContainerOogOLB;
            oogInfo += ' ' + 'SCALE=' + this.gridBlContainer.listStore.store[i].blContainerOogScale;
            if (i === 0) {
              if ((this.gridBlContainer.listStore.store[i].blContainerOogOH +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLB) > 0) {
                oogList.push(oogInfo);
              }
            } else {
              if (!oogList.includes(oogInfo)) {
                if ((this.gridBlContainer.listStore.store[i].blContainerOogOH +
                    this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                    this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                    this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                    this.gridBlContainer.listStore.store[i].blContainerOogOLB) > 0) {
                  oogList.push(oogInfo);
                }
              }
            }

          }
        }
         if (oogList.length > 0) {
          for (const entry of oogList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 02. Detection of DG Class
         const dgClassList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let dgClass = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            dgClass = 'DG CLASS=' + this.gridBlContainer.listStore.store[i].blContainerDGClass;
            if (!dgClassList.includes(dgClass)) {
              if (this.gridBlContainer.listStore.store[i].blContainerDGClass !== 'N/A') {
                dgClassList.push(dgClass);
              }
            }
          }
        }
         if (dgClassList.length > 0) {
          for (const entry of dgClassList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 03. Detection of IMO & UNNO & F/P
         const imoUnnoList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let imoUnno = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            imoUnno = 'IMO=' + this.gridBlContainer.listStore.store[i].blContainerIMO + ' ' + 'UNNO=' +
              this.gridBlContainer.listStore.store[i].blContainerUNNO + ' ' + 'F/P=' +
              ((this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature > 0) ? '+' + this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature : this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature ) +
              this.gridBlContainer.listStore.store[i].blContainerFlashPointScale;
            if (!imoUnnoList.includes(imoUnno)) {
              if (this.gridBlContainer.listStore.store[i].blContainerIMO !== '' &&
                this.gridBlContainer.listStore.store[i].blContainerUNNO !== '' &&
                this.gridBlContainer.listStore.store[i].blContainerFlashPointTemperature !== 0 &&
                this.gridBlContainer.listStore.store[i].blContainerFlashPointScale !== '') {
                imoUnnoList.push(imoUnno);
              }
            }
          }
        }
         if (imoUnnoList.length > 0) {
          for (const entry of imoUnnoList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 04. Detection of the Reefer Temperature
         const reeferTempList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let reeferTemp = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            reeferTemp = 'REEFER TEMP.=' + ((this.gridBlContainer.listStore.store[i].blContainerReeferTemperature > 0) ? '+' + this.gridBlContainer.listStore.store[i].blContainerReeferTemperature : this.gridBlContainer.listStore.store[i].blContainerReeferTemperature ) +
              this.gridBlContainer.listStore.store[i].blContainerReeferScale;
            if (!reeferTempList.includes(reeferTemp)) {
              if (this.gridBlContainer.listStore.store[i].blContainerReeferTemperature !== 0 &&
                this.gridBlContainer.listStore.store[i].blContainerReeferScale !== '' ) {
                reeferTempList.push(reeferTemp);
              }
            }
          }
        }
         if (reeferTempList.length > 0) {
          for (const entry of reeferTempList) {
            blDescEnding += '\n' + entry;
          }
        }



         // 05. Detection of the HS Code List
         const hsCodeList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            if (this.gridBlContainer.listStore.store[i].blContainerHSCode.length > 0 ) {
              let hsCodes = '';
              for (let j = 0 ; j < this.gridBlContainer.listStore.store[i].blContainerHSCode.length; j++) {
                if (this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSPrint === true) {
                  hsCodes = this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSCode + ' ' +
                    this.gridBlContainer.listStore.store[i].blContainerHSCode[j].blContainerHSDescription ;
                  if (!hsCodeList.includes(hsCodes)) {
                    hsCodeList.push(hsCodes);
                  }
                }
              }
            }
          }
        }
         if (hsCodeList.length > 0) {
          for (const entry of hsCodeList) {
            blDescEnding += '\n' + entry;
          }
        }

         // 06. Detection of the Shortshipment
         const shortshipmentList: Array<string> = [];
         if (this.gridBlContainer.listStore.store.length > 0) {
          let shortshipmet = '';
          for (let i = 0 ; i < this.gridBlContainer.listStore.store.length; i++) {
            if (this.gridBlContainer.listStore.store[i].blContainerShortShipContainer === true &&
              this.gridBlContainer.listStore.store[i].blContainerIsPrintShortShipmentInfoOnBL === true) {
              if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentFrom === true) {
                shortshipmet = '*SHORTSHIPPED FROM ' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselName +
                  ' V.' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselVoyage;
              }
              if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentTo === true) {
                if (this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselId !== '') {
                  shortshipmet = '*SHORTSHIPPED FROM ' + this.model.blOceanVesselName + ' V.' + this.model.blOceanVesselVoyage;
                  shortshipmet += '\n*TO BE LOADED ON ' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselName +
                    ' V.' + this.gridBlContainer.listStore.store[i].blContainerShortShipmentVesselVoyage;

                } else {
                  shortshipmet = '*SHORTSHIPPED FROM ' + this.model.blOceanVesselName + ' V.' + this.model.blOceanVesselVoyage;
                }
              }

            }
            if (!shortshipmentList.includes(shortshipmet)) {
              shortshipmentList.push(shortshipmet);
            }
          }
        }
         if (shortshipmentList.length > 0) {
          for (const entry of shortshipmentList) {
            blDescEnding += '\n' + entry;
          }
        }



         // 07. Detection of Next / Connecting Vessel
         if (this.model.blNextVesselClauseIsPrinted) {
          if (this.model.blNextVesselId !== '' && this.model.blNextVesselVoyage !== '' && this.model.blNextVesselBound !== '') {
            blDescEnding += '\nCONNECTING ONTO ' + this.model.blNextVesselName +
              ' V.' + this.model.blNextVesselVoyage +
              ((this.model.blPortOfTransshipment1Name !== '') ? ' AT ' + this.model.blPortOfTransshipment1Name : '');
          }
        }  **/

        /**
         // Detection of the Description Clause
         const descClauseList: Array<string> = [];
         if (this.gridBlDescClause.listStore.store.length > 0) {
        let descClause = '';
        for (let i = 0 ; i < this.gridBlDescClause.listStore.store.length; i++) {
          descClause = this.gridBlDescClause.listStore.store[i].blDescClauseName.trim();
          if (!descClauseList.includes(descClause)) {
            descClauseList.push(descClause);
          }
        }
      }
         if (descClauseList.length > 0) {
        for (const entry of descClauseList) {
          blDescEnding += '\n' + entry;
        }
      }
         */
      } else if (this.model.blContainerOwnership === "COCTS") {
        blContainerOwnershipText = "COC T/S";
        //GOS-499
        if (this.officeCode == 'SGSIN') {
          blDescEnding = "GENERAL CARGOES T/S EX " + this.model.blPreVesselName + " / " + this.model.blPreVesselVoyage;
        } else {
          blDescEnding = "";
        }
        //End of GOS-499
      } else if (this.model.blContainerOwnership === "SOC") {
        blContainerOwnershipText = "SOC LOCAL";
        blDescEnding = "GENERAL CARGOES";
      } else if (this.model.blContainerOwnership === "SOCTS") {
        blContainerOwnershipText = "SOC T/S";
        //GOS-499
        if (this.officeCode == 'SGSIN') {
          blDescEnding = "GENERAL CARGOES\n\nT/S EX " + this.model.blPreVesselName + " / " + this.model.blPreVesselVoyage;
        } else {
          blDescEnding = "GENERAL CARGOES\n\nT/S EX / ";
        }
        //End of GOS-499
      } else if (this.model.blContainerOwnership === "SLOT") {
        blContainerOwnershipText = "SLOT";
        blDescEnding = "GENERAL CARGOES\n\nS/E";
      }

      /// ***** Changed by BIZAPP ***//

      // 00. Detection of Detention and Demurrage
      if(this.model.blIsDetDemPrinted == 'Y') {
        if (
          this.model.blDetentionRequest > 0 ||
          this.model.blDemurrageRequest > 0
        ) {
          blDescEnding =
            'DETENTION IS ' +
            this.model.blDetentionRequest +
            ' DAYS AND DEMURRAGE IS ' +
            this.model.blDemurrageRequest +
            ' DAYS';
        }
      }

      // 01. Detection of OOG
      const oogList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let oogInfo = "";
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          oogInfo =
            "OH=" + this.gridBlContainer.listStore.store[i].blContainerOogOH;
          oogInfo +=
            " " +
            "OWL=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOWL;
          oogInfo +=
            " " +
            "OWR=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOWR;
          oogInfo +=
            " " +
            "OLF=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOLF;
          oogInfo +=
            " " +
            "OLB=" +
            this.gridBlContainer.listStore.store[i].blContainerOogOLB;
          oogInfo +=
            " " +
            "SCALE=" +
            this.gridBlContainer.listStore.store[i].blContainerOogScale;
          if (i === 0) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerOogOH +
                this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                this.gridBlContainer.listStore.store[i].blContainerOogOLB >
              0
            ) {
              oogList.push(oogInfo);
            }
          } else {
            if (!oogList.includes(oogInfo)) {
              if (
                this.gridBlContainer.listStore.store[i].blContainerOogOH +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWL +
                  this.gridBlContainer.listStore.store[i].blContainerOogOWR +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLF +
                  this.gridBlContainer.listStore.store[i].blContainerOogOLB >
                0
              ) {
                oogList.push(oogInfo);
              }
            }
          }
        }
      }
      if (oogList.length > 0) {
        for (const entry of oogList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 02. Detection of DG Class
      const dgClassList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let dgClass = "";
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          dgClass =
            "DG CLASS=" +
            this.gridBlContainer.listStore.store[i].blContainerDGClass;
          if (!dgClassList.includes(dgClass)) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerDGClass !==
              "N/A"
            ) {
              dgClassList.push(dgClass);
            }
          }
        }
      }
      if (dgClassList.length > 0) {
        for (const entry of dgClassList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 03. Detection of IMO & UNNO & F/P
      const imoUnnoList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let imoUnno = "";
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          imoUnno =
            "IMO=" +
            this.gridBlContainer.listStore.store[i].blContainerIMO +
            " " +
            "UNNO=" +
            this.gridBlContainer.listStore.store[i].blContainerUNNO +
            " " +
            "F/P=" +
            (this.gridBlContainer.listStore.store[i]
              .blContainerFlashPointTemperature > 0
              ? "+" +
                this.gridBlContainer.listStore.store[i]
                  .blContainerFlashPointTemperature
              : this.gridBlContainer.listStore.store[i]
                  .blContainerFlashPointTemperature) +
            this.gridBlContainer.listStore.store[i]
              .blContainerFlashPointScale;
          if (!imoUnnoList.includes(imoUnno)) {
            if (
              this.gridBlContainer.listStore.store[i].blContainerIMO !== "" &&
              this.gridBlContainer.listStore.store[i].blContainerUNNO !==
                "" &&
              this.gridBlContainer.listStore.store[i]
                .blContainerFlashPointTemperature !== 0 &&
              this.gridBlContainer.listStore.store[i]
                .blContainerFlashPointScale !== ""
            ) {
              imoUnnoList.push(imoUnno);
            }
          }
        }
      }
      if (imoUnnoList.length > 0) {
        for (const entry of imoUnnoList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 04. Detection of the Reefer Temperature
      const reeferTempList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let reeferTemp = "";
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          reeferTemp =
            "REEFER TEMP.=" +
            (this.gridBlContainer.listStore.store[i]
              .blContainerReeferTemperature > 0
              ? "+" +
                this.gridBlContainer.listStore.store[i]
                  .blContainerReeferTemperature
              : this.gridBlContainer.listStore.store[i]
                  .blContainerReeferTemperature) +
            this.gridBlContainer.listStore.store[i].blContainerReeferScale;
          if (!reeferTempList.includes(reeferTemp)) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerReeferTemperature !== 0 &&
              this.gridBlContainer.listStore.store[i]
                .blContainerReeferScale !== ""
            ) {
              reeferTempList.push(reeferTemp);
            }
          }
        }
      }
      if (reeferTempList.length > 0) {
        for (const entry of reeferTempList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 05. Detection of the HS Code List
      const hsCodeList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          if (this.gridBlContainer.listStore.store[i].blContainerExtra && this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerAdPrint &&  this.gridBlContainer.listStore.store[i].blContainerExtra.blContainerAdPrint === "Y"){
            for (
              let j = 0;
              j <
              this.gridBlContainer.listStore.store[i].blAdContainer.length;
              j++
            ) {
              if(this.gridBlContainer.listStore.store[i].blAdContainer.blAdContainerHsCode !== "" &&
              this.gridBlContainer.listStore.store[i].blAdContainer.blAdContainerHsName !== ""
              ){
                let hsCodes = "";
                let newLine: any = [];
                let hs = '';
                hs =
                this.gridBlContainer.listStore.store[i].blAdContainer[j]
                  .blAdContainerHsCode +
                " " +
                this.gridBlContainer.listStore.store[i].blAdContainer[j]
                  .blAdContainerHsName;
                newLine = hs.match(/\b[\w\s]{32,}?(?=\s)|.+$/g);
                if (newLine.length > 0) {
                  newLine.forEach(nt => {
                    hsCodes += nt.trim() + '↵';
                  });
                }else{
                  hsCodes += hs;
                }

                if (!hsCodeList.includes(hsCodes)) {
                  hsCodeList.push(hsCodes);
                }
              }
            }
          }
          // if (
          //   this.gridBlContainer.listStore.store[i].blContainerHSCode.length >
          //   0
          // ) {
          //   let hsCodes = "";
          //   for (
          //     let j = 0;
          //     j <
          //     this.gridBlContainer.listStore.store[i].blContainerHSCode
          //       .length;
          //     j++
          //   ) {
          //     if (
          //       this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //         .blContainerHSPrint === true
          //     ) {
          //       hsCodes =
          //         this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //           .blContainerHSCode +
          //         " " +
          //         this.gridBlContainer.listStore.store[i].blContainerHSCode[j]
          //           .blContainerHSDescription;
          //       if (!hsCodeList.includes(hsCodes)) {
          //         hsCodeList.push(hsCodes);
          //       }
          //     }
          //   }
          // }
        }
      }
      if (hsCodeList.length > 0) {
        for (const entry of hsCodeList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 06. Detection of the Shortshipment
      const shortshipmentList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let shortshipmet = "";
        for (
          let i = 0;
          i < this.gridBlContainer.listStore.store.length;
          i++
        ) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerShortShipContainer === true &&
            this.gridBlContainer.listStore.store[i]
              .blContainerIsPrintShortShipmentInfoOnBL === true
          ) {
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentFrom === true
            ) {
              shortshipmet =
                "*SHORTSHIPPED FROM " +
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselName +
                " V." +
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselVoyage;
            }
            if (
              this.gridBlContainer.listStore.store[i]
                .blContainerShortShipmentTo === true
            ) {
              if (
                this.gridBlContainer.listStore.store[i]
                  .blContainerShortShipmentVesselId !== ""
              ) {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
                shortshipmet +=
                  "\n*TO BE LOADED ON " +
                  this.gridBlContainer.listStore.store[i]
                    .blContainerShortShipmentVesselName +
                  " V." +
                  this.gridBlContainer.listStore.store[i]
                    .blContainerShortShipmentVesselVoyage;
              } else {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
              }
            }
          }
          if (!shortshipmentList.includes(shortshipmet)) {
            shortshipmentList.push(shortshipmet);
          }
        }
      }
      if (shortshipmentList.length > 0) {
        for (const entry of shortshipmentList) {
          blDescEnding += "\n" + entry;
        }
      }

      // 07. Detection of Next / Connecting Vessel
      if (this.model.blNextVesselClauseIsPrinted) {
        if (
          this.model.blNextVesselId !== "" &&
          this.model.blNextVesselVoyage !== "" &&
          this.model.blNextVesselBound !== ""
        ) {
          blDescEnding +=
            "\nCONNECTING ONTO " +
            this.model.blNextVesselName +
            " V." +
            this.model.blNextVesselVoyage +
            (this.model.blPortOfTransshipment1Name !== ""
              ? " AT " + this.model.blPortOfTransshipment1Name
              : "");
        }
      }

      // 08 detection sob
      if (this.model.blIsPrintShippedOnBoard == true) {
        const monString = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
        let issueDateString = '';
        if(this.model.blExtraShippedOnBoard != '') {
          issueDateString = this.model.blExtraShippedOnBoard.split('-')[2] + ', ' + monString[Number(this.model.blExtraShippedOnBoard.split('-')[1])] + ' ' + this.model.blExtraShippedOnBoard.split('-')[0];
        }else{
          issueDateString = this.model.blDateOfIssue.split('-')[2] + ', ' + monString[Number(this.model.blDateOfIssue.split('-')[1])] + ' ' + this.model.blDateOfIssue.split('-')[0];
        }
        const sob = '\nSHIPPED ON BOARD ON ' + issueDateString;
        blDescEnding += sob;
        //this.gridBlDesc.loadData();

      }

      // 09 Export and Importer tax number handler
      if ( this.model.blExporterTaxNumberPrint == true) {
        const expTax = '\nEXPORTER TAX REGISTRATION NUMBER : ' + this.model.blExporterTaxNumber;
        blDescEnding += expTax;
        //this.gridBlDesc.loadData();
      }

      // 10 Import Tax
      if (this.model.blImporterTaxNumberPrint == true) {
        const impTax = '\nIMPORTER TAX REGISTRATION NUMBER : ' + this.model.blImporterTaxNumber;
        blDescEnding += impTax;
        //this.gridBlDesc.loadData();
      }

      //**** END CHANGED****///

      if (
        countStd20 +
          countStd40 +
          countStd45 +
          countFlr20 +
          countFlr40 +
          countFlr45 +
          countOpt20 +
          countOpt40 +
          countOpt45 +
          countPlt20 +
          countPlt40 +
          countPlt45 +
          countRef20 +
          countRef40 +
          countRef45 +
          countDry20 +
          countDry40 +
          countDry45 +
          countTnk20 +
          countTnk40 +
          countTnk45 +
          countUc20 +
          countUc40 +
          countUc45 +
          (countStd20hc +
            countStd40hc +
            countStd45hc +
            countFlr20hc +
            countFlr40hc +
            countFlr45hc +
            countOpt20hc +
            countOpt40hc +
            countOpt45hc +
            countPlt20hc +
            countPlt40hc +
            countPlt45hc +
            countRef20hc +
            countRef40hc +
            countRef45hc +
            countDry20hc +
            countDry40hc +
            countDry45hc +
            countTnk20hc +
            countTnk40hc +
            countTnk45hc +
            countUc20hc +
            countUc40hc +
            countUc45hc) +
          (countStd20E +
            countStd40E +
            countStd45E +
            countFlr20E +
            countFlr40E +
            countFlr45E +
            countOpt20E +
            countOpt40E +
            countOpt45E +
            countPlt20E +
            countPlt40E +
            countPlt45E +
            countRef20E +
            countRef40E +
            countRef45E +
            countDry20E +
            countDry40E +
            countDry45E +
            countTnk20E +
            countTnk40E +
            countTnk45E +
            countUc20E +
            countUc40E +
            countUc45E) +
          (countStd20Ehc +
            countStd40Ehc +
            countStd45Ehc +
            countFlr20Ehc +
            countFlr40Ehc +
            countFlr45Ehc +
            countOpt20Ehc +
            countOpt40Ehc +
            countOpt45Ehc +
            countPlt20Ehc +
            countPlt40Ehc +
            countPlt45Ehc +
            countRef20Ehc +
            countRef40Ehc +
            countRef45Ehc +
            countDry20Ehc +
            countDry40Ehc +
            countDry45Ehc +
            countTnk20Ehc +
            countTnk40Ehc +
            countTnk45Ehc +
            countUc20Ehc +
            countUc40Ehc +
            countUc45Ehc) >
        0
      ) {
        // Normal
        if (countStd20 > 0) {
          descText =
            descText +
            countStd20 +
            " X 20' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20hc > 0) {
          descText =
            descText +
            countStd20hc +
            " X 20' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20E > 0) {
          descText =
            descText +
            countStd20E +
            " X 20' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd20Ehc > 0) {
          descText =
            descText +
            countStd20Ehc +
            " X 20' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40 > 0) {
          descText =
            descText +
            countStd40 +
            " X 40' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40hc > 0) {
          descText =
            descText +
            countStd40hc +
            " X 40' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40E > 0) {
          descText =
            descText +
            countStd40E +
            " X 40' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd40Ehc > 0) {
          descText =
            descText +
            countStd40Ehc +
            " X 40' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45 > 0) {
          descText =
            descText +
            countStd45 +
            " X 45' GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45 > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45hc > 0) {
          descText =
            descText +
            countStd45hc +
            " X 45' HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45E > 0) {
          descText =
            descText +
            countStd45E +
            " X 45' EMPTY GP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45E > 1 ? "S" : "") +
            "\n";
        }
        if (countStd45Ehc > 0) {
          descText =
            descText +
            countStd45Ehc +
            " X 45' EMPTY HC " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countStd45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Flat rack
        if (countFlr20 > 0) {
          descText =
            descText +
            countFlr20 +
            " X 20' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20hc > 0) {
          descText =
            descText +
            countFlr20hc +
            " X 20' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20E > 0) {
          descText =
            descText +
            countFlr20E +
            " X 20' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr20Ehc > 0) {
          descText =
            descText +
            countFlr20Ehc +
            " X 20' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40 > 0) {
          descText =
            descText +
            countFlr40 +
            " X 40' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40hc > 0) {
          descText =
            descText +
            countFlr40hc +
            " X 40' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40E > 0) {
          descText =
            descText +
            countFlr40E +
            " X 40' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr40Ehc > 0) {
          descText =
            descText +
            countFlr40Ehc +
            " X 40' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45 > 0) {
          descText =
            descText +
            countFlr45 +
            " X 45' FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45 > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45hc > 0) {
          descText =
            descText +
            countFlr45hc +
            " X 45' HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45E > 0) {
          descText =
            descText +
            countFlr45E +
            " X 45' EMPTY FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45E > 1 ? "S" : "") +
            "\n";
        }
        if (countFlr45Ehc > 0) {
          descText =
            descText +
            countFlr45Ehc +
            " X 45' EMPTY HC FLAT RACK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countFlr45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Open Top
        if (countOpt20 > 0) {
          descText =
            descText +
            countOpt20 +
            " X 20' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20hc > 0) {
          descText =
            descText +
            countOpt20hc +
            " X 20' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20E > 0) {
          descText =
            descText +
            countOpt20E +
            " X 20' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt20Ehc > 0) {
          descText =
            descText +
            countOpt20Ehc +
            " X 20' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40 > 0) {
          descText =
            descText +
            countOpt40 +
            " X 40' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40hc > 0) {
          descText =
            descText +
            countOpt40hc +
            " X 40' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40E > 0) {
          descText =
            descText +
            countOpt40E +
            " X 40' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt40Ehc > 0) {
          descText =
            descText +
            countOpt40Ehc +
            " X 40' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45 > 0) {
          descText =
            descText +
            countOpt45 +
            " X 45' OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45 > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45hc > 0) {
          descText =
            descText +
            countOpt45hc +
            " X 45' HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45E > 0) {
          descText =
            descText +
            countOpt45E +
            " X 45' EMPTY OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45E > 1 ? "S" : "") +
            "\n";
        }
        if (countOpt45Ehc > 0) {
          descText =
            descText +
            countOpt45Ehc +
            " X 45' EMPTY HC OPEN TOP " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countOpt45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Platform
        if (countPlt20 > 0) {
          descText =
            descText +
            countPlt20 +
            " X 20' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20hc > 0) {
          descText =
            descText +
            countPlt20hc +
            " X 20' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20E > 0) {
          descText =
            descText +
            countPlt20E +
            " X 20' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt20Ehc > 0) {
          descText =
            descText +
            countPlt20Ehc +
            " X 20' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40 > 0) {
          descText =
            descText +
            countPlt40 +
            " X 40' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40hc > 0) {
          descText =
            descText +
            countPlt40hc +
            " X 40' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40E > 0) {
          descText =
            descText +
            countPlt40E +
            " X 40' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt40Ehc > 0) {
          descText =
            descText +
            countPlt40Ehc +
            " X 40' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45 > 0) {
          descText =
            descText +
            countPlt45 +
            " X 45' PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45 > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45hc > 0) {
          descText =
            descText +
            countPlt45hc +
            " X 45' HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45E > 0) {
          descText =
            descText +
            countPlt45E +
            " X 45' EMPTY PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45E > 1 ? "S" : "") +
            "\n";
        }
        if (countPlt45Ehc > 0) {
          descText =
            descText +
            countPlt45Ehc +
            " X 45' EMPTY HC PLATFORM " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countPlt45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Reefer
        if (countRef20 > 0) {
          descText =
            descText +
            countRef20 +
            " X 20' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20hc > 0) {
          descText =
            descText +
            countRef20hc +
            " X 20' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20E > 0) {
          descText =
            descText +
            countRef20E +
            " X 20' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef20Ehc > 0) {
          descText =
            descText +
            countRef20Ehc +
            " X 20' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40 > 0) {
          descText =
            descText +
            countRef40 +
            " X 40' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40hc > 0) {
          descText =
            descText +
            countRef40hc +
            " X 40' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40E > 0) {
          descText =
            descText +
            countRef40E +
            " X 40' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef40Ehc > 0) {
          descText =
            descText +
            countRef40Ehc +
            " X 40' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45 > 0) {
          descText =
            descText +
            countRef45 +
            " X 45' REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45 > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45hc > 0) {
          descText =
            descText +
            countRef45hc +
            " X 45' HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45E > 0) {
          descText =
            descText +
            countRef45E +
            " X 45' EMPTY REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45E > 1 ? "S" : "") +
            "\n";
        }
        if (countRef45Ehc > 0) {
          descText =
            descText +
            countRef45Ehc +
            " X 45' EMPTY HC REEFER " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countRef45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Reefer Dry
        if (countDry20 > 0) {
          descText =
            descText +
            countDry20 +
            " X 20' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20hc > 0) {
          descText =
            descText +
            countDry20hc +
            " X 20' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20E > 0) {
          descText =
            descText +
            countDry20E +
            " X 20' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry20Ehc > 0) {
          descText =
            descText +
            countDry20Ehc +
            " X 20' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40 > 0) {
          descText =
            descText +
            countDry40 +
            " X 40' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40hc > 0) {
          descText =
            descText +
            countDry40hc +
            " X 40' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40E > 0) {
          descText =
            descText +
            countDry40E +
            " X 40' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry40Ehc > 0) {
          descText =
            descText +
            countDry40Ehc +
            " X 40' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45 > 0) {
          descText =
            descText +
            countDry45 +
            " X 45' REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45 > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45hc > 0) {
          descText =
            descText +
            countDry45hc +
            " X 45' HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45E > 0) {
          descText =
            descText +
            countDry45E +
            " X 45' EMPTY REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45E > 1 ? "S" : "") +
            "\n";
        }
        if (countDry45Ehc > 0) {
          descText =
            descText +
            countDry45Ehc +
            " X 45' EMPTY HC REEFER DRY " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countDry45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // Tank
        if (countTnk20 > 0) {
          descText =
            descText +
            countTnk20 +
            " X 20' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20hc > 0) {
          descText =
            descText +
            countTnk20hc +
            " X 20' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20E > 0) {
          descText =
            descText +
            countTnk20E +
            " X 20' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk20Ehc > 0) {
          descText =
            descText +
            countTnk20Ehc +
            " X 20' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk20Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40 > 0) {
          descText =
            descText +
            countTnk40 +
            " X 40' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40hc > 0) {
          descText =
            descText +
            countTnk40hc +
            " X 40' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40E > 0) {
          descText =
            descText +
            countTnk40E +
            " X 40' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk40Ehc > 0) {
          descText =
            descText +
            countTnk40Ehc +
            " X 40' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk40Ehc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45 > 0) {
          descText =
            descText +
            countTnk45 +
            " X 45' TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45 > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45hc > 0) {
          descText =
            descText +
            countTnk45hc +
            " X 45' HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45hc > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45E > 0) {
          descText =
            descText +
            countTnk45E +
            " X 45' EMPTY TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45E > 1 ? "S" : "") +
            "\n";
        }
        if (countTnk45Ehc > 0) {
          descText =
            descText +
            countTnk45Ehc +
            " X 45' EMPTY HC TANK " +
            blContainerOwnershipText +
            " CONTAINER" +
            (countTnk45Ehc > 1 ? "S" : "") +
            "\n";
        }

        // UC
        if (countUc20 > 0) {
          descText =
            descText +
            countUc20 +
            " X 20' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20hc > 0) {
          descText =
            descText +
            countUc20hc +
            " X 20' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20E > 0) {
          descText =
            descText +
            countUc20E +
            " X 20' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc20Ehc > 0) {
          descText =
            descText +
            countUc20Ehc +
            " X 20' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc20Ehc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40 > 0) {
          descText =
            descText +
            countUc40 +
            " X 40' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40hc > 0) {
          descText =
            descText +
            countUc40hc +
            " X 40' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40E > 0) {
          descText =
            descText +
            countUc40E +
            " X 40' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc40Ehc > 0) {
          descText =
            descText +
            countUc40Ehc +
            " X 40' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc40Ehc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45 > 0) {
          descText =
            descText +
            countUc45 +
            " X 45' UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45 > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45hc > 0) {
          descText =
            descText +
            countUc45hc +
            " X 45' HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45hc > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45E > 0) {
          descText =
            descText +
            countUc45E +
            " X 45' EMPTY UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45E > 1 ? "ES" : "") +
            "\n";
        }
        if (countUc45Ehc > 0) {
          descText =
            descText +
            countUc45Ehc +
            " X 45' EMPTY HC UC " +
            blContainerOwnershipText +
            " CARGO" +
            (countUc45Ehc > 1 ? "ES" : "") +
            "\n";
        }

        descText = descText + "STC :\n\n" + blDescEnding;
        if (descText.split("\n").length > 1) {
          descTextClean =
            descText.split("\n")[0] + "<br />" + descText.split("\n")[1];
        } else {
          descTextClean = descText.split("\n")[0];
        }
      }

      this.gridBlDesc.listStore.store[0].blDescDescriptionText = descText;
      this.gridBlDesc.listStore.store[0].blDescDescriptionTextClean =
        descTextClean;
    }
    }
    this.isRefreightButtonClicked = false;
  }

  tabMarkClicked() {
    this.tabStatus = "tab4";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "active";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabNettClicked() {
    this.tabStatus = "tab6";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "active";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabGrossClicked() {
    this.tabStatus = "tab7";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "active";
    this.tabStatus8 = "";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabNettConsoleClicked() {
    this.tabStatus = "tab8";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "active";
    this.tabStatus9 = "";
    this.isRefreightButtonClicked = false;
  }

  tabGrossConsoleClicked() {
    this.tabStatus = "tab9";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "";
    this.tabStatus5 = "";
    this.tabStatus6 = "";
    this.tabStatus7 = "";
    this.tabStatus8 = "";
    this.tabStatus9 = "active";
    this.isRefreightButtonClicked = false;
  }

  retrieve(event) {
    //  console.log(event);
    if (event.keyCode === 13) {
      this.isFullSaveClicked = false;
      //this.isOpenedFromFlagStatus = false;
      //this.isOpenedFromCrossRef = false;
      //this.isOpenedFromCrossBookRef = false;
      this.cleanUpBeforeRetrieval();
      this.handleRetrieve();
    }
  }

  onDialogConfirmDeleteDescClauseYes() {
    this.gridBlDescClause.url = "";
    const dt = this.gridBlDescClause.getSelectedValues()[0];
    this.gridBlDescClause.listStore.removeData(dt);
    this.gridBlDescClause.loadData();
    for (let i = 0; i < this.gridBlDescClause.listStore.store.length; i++) {
      this.gridBlDescClause.listStore.store[i].blDescClauseSeq =
        this.gridBlDescClause.listStore.store[i].no;
    }
    this.gridBlDescClause.loadData();
    this.onBlDescClauseCancel();
    this.genericUtil.closeDialog("blConfirmDeleteDescClause");
  }

  onDialogConfirmDeleteDescClauseNo() {
    this.genericUtil.closeDialog("blConfirmDeleteDescClause");
  }

  infoGridBlNettChargeExchangeRate(event) {}

  gridEventBlNettChargeExchangeRate(event) {
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== "") {
          const arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              if (this.btnBlExchRateClauseCancel !== true) {
                this.btnBlExchRateClauseNew = false;
                this.btnBlExchRateClauseUpdate = true;
                this.btnBlExchRateClauseDelete = true;
                this.btnBlExchRateClauseCancel = true;
                this.blExchRateUpdateLock = false;
                this.blExchRateUpdateMode = "update";
                this.paramExchangeRate = new BLExchangeRate();
                const dt =
                  this.gridBlNettChargeExchangeRate.getSelectedValues()[0];
                this.paramExchangeRate.blExchangeRateOfficeCode =
                  dt.blExchangeRateOfficeCode;
                this.paramExchangeRate.blExchangeRateBlNo =
                  dt.blExchangeRateBlNo;
                this.paramExchangeRate.blExchangeRateBlSeq =
                  dt.blExchangeRateBlSeq;
                this.paramExchangeRate.blExchangeRateHomeCurrency =
                  dt.blExchangeRateHomeCurrency;
                this.paramExchangeRate.blExchangeRateDestinationCurrency =
                  dt.blExchangeRateDestinationCurrency;
                this.paramExchangeRate.blExchangeRate = dt.blExchangeRate;

                if (
                  this.paramExchangeRate.blExchangeRateDestinationCurrency !==
                  ""
                ) {
                  this.cbBlNettChargeExchangeRateCurrency.setUrl(
                    this.configService.config.BASE_API.toString() +
                      "/MasterCurrencies/findByComboBoxControl/currencyCode={query}"
                  );
                  this.cbBlNettChargeExchangeRateCurrency.setValue(
                    this.paramExchangeRate.blExchangeRateDestinationCurrency
                  );
                }
              }

              break;
            case "afterLoad":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  onDialogSERNew() {
    this.paramExchangeRate = new BLExchangeRate();
    this.paramExchangeRate.blExchangeRateOfficeCode = this.model.blOfficeCode;
    this.paramExchangeRate.blExchangeRateBlNo = this.model.blNo;
    this.paramExchangeRate.blExchangeRateBlSeq =
      this.gridBlNettChargeExchangeRate.listStore.store.length + 1;
    this.paramExchangeRate.blExchangeRateDestinationCurrency = "";

    if (
      this.cookieService.getDefaultLocationCode() === "THBKK" ||
      this.cookieService.getDefaultLocationCode() === "THSGZ"
    ) {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "THB";
    } else if (this.cookieService.getDefaultLocationCode() === "SGSIN") {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "SGD";
    } else if (
      this.cookieService.getDefaultLocationCode() === "MYKUA" ||
      this.officeCode == "MYPKG" ||
      this.officeCode == "MYPGU" ||
      this.officeCode == "MYPEN" ||
      this.officeCode == "MYBTU"
    ) {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "MYR";
    } else if (
      this.cookieService.getDefaultLocationCode() === "INCCU" ||
      this.officeCode == "INGIN" ||
      this.officeCode == "INPAV" ||
      this.officeCode == "INBOM" ||
      this.officeCode == "INMAA"
    ) {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "INR";
    } else if (this.cookieService.getDefaultLocationCode() === "THMNL") {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "PHP";
    } else if (
      this.cookieService.getDefaultLocationCode() === "VNHPH" ||
      this.officeCode == "VNDAD" ||
      this.officeCode == "VNUIH" ||
      this.officeCode == "VNHAN" ||
      this.officeCode == "VNHCM"
    ) {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "VND";
    } else if (this.cookieService.getDefaultLocationCode() === "BDCGP") {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "BDT";
    } else if (this.cookieService.getDefaultLocationCode() === "LKCMB") {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "LKR";
    } else if (
      this.cookieService.getDefaultLocationCode() === "MMYGN" ||
      this.cookieService.getDefaultLocationCode() === "MMRGN"
    ) {
      this.paramExchangeRate.blExchangeRateHomeCurrency = "MMK";
    }

    this.blExchRateUpdateLock = false;
    this.btnBlExchRateClauseNew = false;
    this.btnBlExchRateClauseUpdate = true;
    this.btnBlExchRateClauseDelete = false;
    this.btnBlExchRateClauseCancel = true;
  }

  onDialogSERUpdate() {
    //console.log(numeral(this.paramExchangeRate.blExchangeRate).format('0.0000'))

    this.isErrorBlExchRate = false;
    this.paramExchangeRate.blExchangeRate = numeral(
      this.paramExchangeRate.blExchangeRate
    ).format("0.0000");

    if (
      this.paramExchangeRate.blExchangeRateDestinationCurrency
        .toString()
        .trim() === "" ||
      this.paramExchangeRate.blExchangeRateDestinationCurrency
        .toString()
        .trim() === undefined
    ) {
      this.isErrorBlExchRate = true;
      this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] =
        "Please insert Currency.";
    } else if (
      this.paramExchangeRate.blExchangeRate == "" ||
      this.paramExchangeRate.blExchangeRate == undefined || Number(this.paramExchangeRate.blExchangeRate) == 0
    ) {
      this.isErrorBlExchRate = true;
      this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] =
        "Please insert Exchange Rate.";
    }

    if (Number(this.paramExchangeRate['blExchangeRate']) < 0) {
      this.isErrorBlExchRate = true;
      this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] =
        "Please insert an Exchange Rate greater than 0";
      return;
    } else if (Number(this.paramExchangeRate['blExchangeRate']) === 0) {
      this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] = "Exchange rate cannot be zero.";
    } else {
      this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] = "";
    }

    if (this.gridBlNettChargeExchangeRate.listStore.store.length > 0) {
      for (
        let i = 0;
        i < this.gridBlNettChargeExchangeRate.listStore.store.length;
        i++
      ) {
        if (
          this.gridBlNettChargeExchangeRate.listStore.store[i]
            .blExchangeRateBlSeq !==
            this.paramExchangeRate.blExchangeRateBlSeq &&
          this.paramExchangeRate.blExchangeRateDestinationCurrency ===
            this.gridBlNettChargeExchangeRate.listStore.store[i]
              .blExchangeRateDestinationCurrency
        ) {
          this.isErrorBlExchRate = true;
          this.paramExchangeRate["error-blExchangeRateDestinationCurrency"] =
            "Duplicate Exchange Rate detected. Please check your input.";
        }
      }
    }

    if (!this.isErrorBlExchRate) {
      if (this.blExchRateUpdateMode === "insert") {
        this.gridBlNettChargeExchangeRate.url = "";
        this.gridBlNettChargeExchangeRate.listStore.store.push(
          this.paramExchangeRate
        );
        this.gridBlNettChargeExchangeRate.loadData();
        this.paramExchangeRate = new BLExchangeRate();
        this.onDialogSERCancel();
      } else {
        /// this.gridBlNettChargeExchangeRate.listStore.updateData((this.gridBlNettChargeExchangeRate.getSelectedValues()[0].blExchangeRateBlSeq - 1), this.paramExchangeRate);
        this.gridBlNettChargeExchangeRate.listStore.updateData(
          this.paramExchangeRate.blExchangeRateBlSeq - 1,
          this.paramExchangeRate
        );

        this.gridBlNettChargeExchangeRate.clearSelectedValues();
        this.gridBlNettChargeExchangeRate.loadData();
        this.onDialogSERCancel();
      }
    }
  }

  onDialogSERDelete() {
    this.genericUtil.showDialog(
      "blConfirmDeleteNettChargeExchangeRate",
      "Confirmation",
      350,
      150
    );
  }

  onDialogSERCancel() {
    this.blExchRateUpdateMode = "insert";
    this.cbBlNettChargeExchangeRateCurrency.setValue("");
    this.paramExchangeRate = new BLExchangeRate();
    this.btnBlExchRateClauseNew = true;
    this.btnBlExchRateClauseUpdate = false;
    this.btnBlExchRateClauseDelete = false;
    this.btnBlExchRateClauseCancel = false;
    this.blExchRateUpdateLock = true;
    this.isErrorBlExchRate = false;
  }

  onDialogSERClose() {
    this.onDialogSERCancel();
    this.genericUtil.closeDialog("blNettChargeSpecialExchangeRateDialog");
  }

  changeEventCbBlNettChargeExchangeRateCurrency(event) {
    if (
      event.currencyCode != null &&
      event.currencyCode !== "" &&
      event.currencyCode !== undefined
    ) {
      this.paramExchangeRate.blExchangeRateDestinationCurrency =
        event.currencyCode;
    } else {
      this.paramExchangeRate.blExchangeRateDestinationCurrency = "";
    }
  }

  onDialogBlPreVesselValidationDialogOk() {
    this.model.blPreVesselName = "";
    this.model.blPreVesselId = "";
    this.model.blPreVesselVoyage = "";
    this.model.blPreVesselBound = "";
    this.cbBlPlacePreVessel.setValue("");
    this.genericUtil.closeDialog("blPreVesselValidationDialog");
  }

  onDialogBlNextVesselValidationDialogOk() {
    this.model.blNextVesselName = "";
    this.model.blNextVesselId = "";
    this.model.blNextVesselVoyage = "";
    this.model.blNextVesselBound = "";
    this.cbBlPlaceNextVessel.setValue("");
    this.genericUtil.closeDialog("blNextVesselValidationDialog");
  }

  onDialogBlContainerChargeDiscrepancyDialogYes() {
    console.log("** onDialogBlContainerChargeDiscrepancyDialogYes **");

    /** Party **/
    this.model.blShipperAddressText = this.txtBlPartyShipperAddress.getValue();
    this.model.blConsigneeAddressText =
      this.txtBlPartyConsigneeAddress.getValue();
    this.model.blNotifyPartyAddressText =
      this.txtBlPartyNotifyPartyAddress.getValue();
    this.model.blTotalContainerPackageInWord =
      this.txtBlDescTotalContainerPackageInWord.getValue().trim();
    this.model.blAgentNameAndAddress =
      this.txtBlDescAgentNameAddress.getValue();
    this.model.blRemarks = this.txtBlNettChargeRemarks.getValue();
    // this.model.blDescAgentRemark = this.txtBlDescAgentNameAddress.getValue();

    /** Place **/

    /** Misc **/

    /** Desc **/
    this.model.blDateOfIssue = this.dateBlNettChargeDateOfIssue.getValue();
    this.model.blExchangeRateDate =
      this.dateBlNettChargeExchangeRate.getValue();


    /** Extra **/
    this.model.blExtraOfficeCode = this.model.blOfficeCode;
    this.model.blExtraBlNo = this.model.blNo;
    this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();


    //  prepare model detail
    this.model.blMarkNo = [];
    for (let i = 0; i < this.gridBlMarkNo.listStore.store.length; i++) {
      this.model.blMarkNo.push(this.gridBlMarkNo.listStore.store[i]);
    }
    this.model.blDesc = [];
    for (let i = 0; i < this.gridBlDesc.listStore.store.length; i++) {
      this.model.blDesc.push(this.gridBlDesc.listStore.store[i]);
    }
    this.model.blDescClause = [];
    for (let i = 0; i < this.gridBlDescClause.listStore.store.length; i++) {
      this.model.blDescClause.push(this.gridBlDescClause.listStore.store[i]);
    }
    this.model.blContainer = [];
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.model.blContainer.push(this.gridBlContainer.listStore.store[i]);
    }
    this.model.blNettCharge = [];
    for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
      this.model.blNettCharge.push(this.gridBlNettCharge.listStore.store[i]);
    }
    this.model.blNettChargeConsole = [];

    this.model.blExchangeRate = [];
    for (
      let i = 0;
      i < this.gridBlNettChargeExchangeRate.listStore.store.length;
      i++
    ) {
      this.model.blExchangeRate.push(
        this.gridBlNettChargeExchangeRate.listStore.store[i]
      );
    }

    //  finally, tried to save
    if (this.isFullSaveClicked) {
      if (!this.isDiscrepancyExisted) {
        this.model.blIsFinished = true;
      } else {
        if (
          this.model.blFreightCoveredInBlNo != null &&
          this.model.blFreightCoveredInBlNo !== "" &&
          this.model.blFreightCoveredInBlNo !== undefined
        ) {
          this.model.blIsFinished = true;
        } else {
          this.model.blIsFinished = false;
        }
      }
    } else {
      this.model.blIsFinished = false;
      if (
        this.model.blFreightCoveredInBlNo != null &&
        this.model.blFreightCoveredInBlNo !== "" &&
        this.model.blFreightCoveredInBlNo !== undefined
      ) {
        if (!this.isDiscrepancyExisted) {
          this.model.blIsFinished = true;
        } else {
          this.model.blIsFinished = false;
        }
      }
    }
    console.log("before save event");
    console.log("sssss >> " + this.model.blIsFinished);
    this.saveEvent();

    this.genericUtil.closeDialog("blContainerChargeDiscrepancyDialog");
  }

  onDialogBlContainerChargeDiscrepancyDialogYesConsole() {
    console.log("** onDialogBlContainerChargeDiscrepancyDialogYesConsole **");
    /** Party **/
    this.model.blShipperAddressText = this.txtBlPartyShipperAddress.getValue();
    this.model.blConsigneeAddressText =
      this.txtBlPartyConsigneeAddress.getValue();
    this.model.blNotifyPartyAddressText =
      this.txtBlPartyNotifyPartyAddress.getValue();
    this.model.blTotalContainerPackageInWord =
      this.txtBlDescTotalContainerPackageInWord.getValue().trim();
    this.model.blAgentNameAndAddress =
      this.txtBlDescAgentNameAddress.getValue();
    this.model.blRemarks = this.txtBlNettChargeRemarksConsole.getValue();

    // this.model.blDescAgentRemark = this.txtBlDescAgentNameAddress.getValue();

    /** Place **/

    /** Misc **/

    /** Desc **/
    this.model.blDateOfIssue =
      this.dateBlNettChargeDateOfIssueConsole.getValue();
    this.model.blExchangeRateDate =
      this.dateBlNettChargeExchangeRateConsole.getValue();
    this.model.blPlaceOfIssueCode = this.model.blPlaceOfIssueCodeConsole;
    this.model.blIsFreightAsArranged = this.model.blIsFreightAsArrangedConsole;
    this.model.blIsPrintLoadingVesselVoyageClause =
      this.model.blIsPrintLoadingVesselVoyageClauseConsole;
    this.model.blIsPrintShippedOnBoard =
      this.model.blIsPrintShippedOnBoardConsole;
    this.model.blFreightType = this.model.blFreightTypeConsole;
    this.model.blIsExchangeRateLock = this.model.blIsExchangeRateLockConsole;
    this.model.blExchangeRateType = this.model.blExchangeRateTypeConsole;
    this.model.blDateOfIssueLock = this.model.blDateOfIssueLockConsole;

    //  prepare model detail
    this.model.blMarkNo = [];
    for (let i = 0; i < this.gridBlMarkNo.listStore.store.length; i++) {
      this.model.blMarkNo.push(this.gridBlMarkNo.listStore.store[i]);
    }
    this.model.blDesc = [];
    for (let i = 0; i < this.gridBlDesc.listStore.store.length; i++) {
      this.model.blDesc.push(this.gridBlDesc.listStore.store[i]);
    }
    this.model.blDescClause = [];
    for (let i = 0; i < this.gridBlDescClause.listStore.store.length; i++) {
      this.model.blDescClause.push(this.gridBlDescClause.listStore.store[i]);
    }
    this.model.blContainer = [];
    for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
      this.model.blContainer.push(this.gridBlContainer.listStore.store[i]);
    }
    this.model.blNettCharge = [];
    for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
      this.model.blNettCharge.push(this.gridBlNettCharge.listStore.store[i]);
    }
    this.model.blNettChargeConsole = [];
    for (
      let i = 0;
      i < this.gridBlNettChargeConsole.listStore.store.length;
      i++
    ) {
      this.model.blNettChargeConsole.push(
        this.gridBlNettChargeConsole.listStore.store[i]
      );
    }

    this.model.blExchangeRate = [];
    for (
      let i = 0;
      i < this.gridBlNettChargeExchangeRate.listStore.store.length;
      i++
    ) {
      this.model.blExchangeRate.push(
        this.gridBlNettChargeExchangeRate.listStore.store[i]
      );
    }

    //  finally, tried to save
    if (this.isFullSaveClicked) {
      if (!this.isDiscrepancyExisted) {
        this.model.blIsFinished = true;
      } else {
        if (
          this.model.blFreightCoveredInBlNoConsole != null &&
          this.model.blFreightCoveredInBlNoConsole !== "" &&
          this.model.blFreightCoveredInBlNoConsole !== undefined
        ) {
          this.model.blIsFinished = true;
        } else {
          this.model.blIsFinished = false;
        }
      }
    } else {
      this.model.blIsFinished = false;
      if (
        this.model.blFreightCoveredInBlNoConsole != null &&
        this.model.blFreightCoveredInBlNoConsole !== "" &&
        this.model.blFreightCoveredInBlNoConsole !== undefined
      ) {
        if (!this.isDiscrepancyExisted) {
          this.model.blIsFinished = true;
        } else {
          this.model.blIsFinished = false;
        }
      }
    }
    //if partialsave2 or save2 no of obl console will replace no of obl nett charge

    this.model.blNoOfOBL = this.model.blNoOfOBLConsole;
    console.log("before save event");
    console.log(this.model);
    this.saveEvent();

    this.genericUtil.closeDialog("blContainerChargeDiscrepancyDialogConsole");
  }

  onDialogBlContainerChargeDiscrepancyDialogNo() {
    this.genericUtil.closeDialog("blContainerChargeDiscrepancyDialog");
  }

  onDialogBlContainerChargeDiscrepancyDialogNoConsole() {
    this.genericUtil.closeDialog("blContainerChargeDiscrepancyDialogConsole");
  }



  // for deleted BL
  onBlDialogViewOnlyYes() {

    this.lockAddressRetrieve = true;
    this.lockAddressRetrieve1 = true;
    this.lockAddressRetrieve2 = true;

    this.disableToolbarButtons =
      "retrieve,partialSave,partialSave2,save,save2,printInvoice,delete,ias,print";

    this.genericUtil.showLoader();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/findBlByOfficeCodeAndBlNo/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blNo.trim().toUpperCase()
      )
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          this.setData(data, false);

          // release the tab
          this.lockParty = false;
          this.lockPlace = false;
          this.lockContainer = false;
          this.lockMarkNo = false;
          this.lockDesc = false;
          this.lockNettCharge = false;
          this.lockGrossCharge = false;
          this.lockNettChargeConsole = false;
          this.lockGrossChargeConsole = false;

          // activate locking
          this.lockTab1 = true;
          this.lockTab2 = true;
          this.lockTab3 = true;
          this.lockTab4 = true;
          this.lockTab5 = true;
          this.lockTab6 = true;
          this.lockTab8 = true;

          this.genericUtil.closeDialog("blDialogViewOnly");
        }
      }, err => {
        this.genericUtil.hideLoader();
      });
  }

  onBlDialogViewOnlyNo() {
    this.loading = false; //GOS-825 dismiss the loader when click No
    this.isInsideRetrieveMode = false;
    this.handleCancel();
    this.disableToolbarButtons =
      "partialSave,partialSave2,save,save2,printInvoice,delete,ias,print,cancel";

    this.genericUtil.closeDialog("blDialogViewOnly");
  }

  cleanUpBeforeRetrieval() {
    this.isError = false;
    this.isErrorContainer = false;
    this.isErrorHSCode = false;
    this.isErrorMarkNo = false;
    this.isErrorDesc = false;
    this.isErrorDescClause = false;
    this.isErrorNettCharge = false;
    this.isErrorNettChargeEntry = false;
    this.isErrorBlExchRate = false;
    this.isLockBLTypeOBL = false;


    // reset all model;
    // this.model = new BLHeader();
    this.paramContainer = new BLContainer();
    this.paramMarkNo = new BLMarkNo();
    this.paramDesc = new BLDesc();
    this.paramDescClause = new BLDescClause();
    this.paramFreightCharge = new BLFreightCharge();
    this.paramExchangeRate = new BLExchangeRate();
    this.paramContainerExtra = new BLContainerExtra();
    this.paramBlAdContainer = new BLAdContainer();

    // reset grid
    this.gridBlMarkNo.onClear();
    this.gridBlContainer.onClear();
    // this.gridBlContainerHS.onClear();
    this.gridBlDesc.onClear();
    this.gridBlDescClause.onClear();
    this.gridBlNettCharge.onClear();
    this.gridBlNettChargeExchangeRate.onClear();

    // reset party
    this.cbBlPartyShipper.setValue("");
    this.cbBlPartyShipperAddress.setValue("");
    this.cbBlPartyShipperAddress.listStore.store = [];
    this.cbBlPartyShipperAddress.loadSelect();
    this.txtBlPartyShipperAddress.setValue("");
    this.cbBlPartyConsignee.setValue("");
    this.cbBlPartyConsigneeAddress.setValue("");
    this.cbBlPartyConsigneeAddress.listStore.store = [];
    this.cbBlPartyConsigneeAddress.loadSelect();
    this.txtBlPartyConsigneeAddress.setValue("");
    this.cbBlPartyNotifyParty.setValue("");
    this.cbBlPartyNotifyPartyAddress.setValue("");
    this.cbBlPartyNotifyPartyAddress.listStore.store = [];
    this.cbBlPartyNotifyPartyAddress.loadSelect();
    this.txtBlPartyNotifyPartyAddress.setValue("");

    // place
    this.cbBlPlaceReceiptType.setValue("");
    this.cbBlPlaceDeliveryType.setValue("");
    this.cbBlPlacePOR.setValue("");
    this.cbBlPlacePOL.setValue("");
    this.cbBlPlacePOD.setValue("");
    this.cbBlPlacePODL.setValue("");
    this.cbBlPlaceFDEST.setValue("");
    this.cbBlPlacePOT1.setValue("");
    this.cbBlPlacePOT2.setValue("");
    this.cbBlPlacePOT3.setValue("");
    this.cbBlPlaceService.setValue("");
    this.cbBlPlacePrincipal.setValue("");
    this.cbBlPlacePreVessel.setValue("");
    // this.cbBlPlacePreVoyage.setValue('');
    this.cbBlPlaceOceanVessel.setValue("");
    this.cbBlPlaceOceanVoyage.setValue("");
    this.cbBlPlaceNextVessel.setValue("");
    // this.cbBlPlaceNextVoyage.setValue('');

    // desc
    this.cbBlDescAgentId.setValue("");
    this.txtBlDescTotalContainerPackageInWord.setValue("");
    this.txtBlDescAgentNameAddress.setValue("");

    // nett charge
    this.cbBlNettChargePrepaid1.setValue("");
    this.cbBlNettChargePrepaid2.setValue("");
    this.cbBlNettChargePrepaid3.setValue("");
    this.cbBlNettChargePayableAt1.setValue("");
    this.cbBlNettChargePayableAt2.setValue("");
    this.cbBlNettChargePayableAt3.setValue("");
    this.cbBlNettChargeBillingParty1.setValue("");
    this.cbBlNettChargeBillingParty2.setValue("");
    this.cbBlNettChargeBillingParty3.setValue("");
    this.cbBlNettChargeBillingParty1Address.listStore.store = [];
    this.cbBlNettChargeBillingParty1Address.loadSelect();
    this.cbBlNettChargeBillingParty1Address.setValue("");
    this.cbBlNettChargeBillingParty2Address.listStore.store = [];
    this.cbBlNettChargeBillingParty2Address.loadSelect();
    this.cbBlNettChargeBillingParty2Address.setValue("");
    this.cbBlNettChargeBillingParty3Address.listStore.store = [];
    this.cbBlNettChargeBillingParty3Address.loadSelect();
    this.cbBlNettChargeBillingParty3Address.setValue("");

    this.txtBlNettChargeRemarks.setValue("");
    this.txtBlPartyShipperAddress.setValue("");
    this.txtBlPartyConsigneeAddress.setValue("");
    this.txtBlPartyNotifyPartyAddress.setValue("");
    this.txtBlMarkNoDesc.setValue("");
    this.txtBlDescDescriptionOfGoods.setValue("");
    this.txtBlNettChargeRemarksConsole.setValue("");
    this.txtBlNettChargeNewRemarks.setValue("");

    this.dateBlNettChargeExchangeRateConsole.setValue("");
    this.dateBlNettChargeDateOfIssueConsole.setValue("");
    //asuasuau

    // container
    // this.cbBlContainerInnerPkgType.setValue("");
    this.cbBlContainerCommodity.setValue("");
  }

  changeEventCbBlContainerShortShipmentVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId !== "" &&
      event.vesselId !== undefined
    ) {
      this.paramContainer.blContainerShortShipmentVesselId = event.vesselId;
      this.paramContainer.blContainerShortShipmentVesselCode = event.vesselCode;
      this.paramContainer.blContainerShortShipmentVesselName = event.vesselName;
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
      this.paramContainer.blContainerShortShipmentVesselBound = "O";
      this.cbBlContainerShortShipmentVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageWithEtd/" +
          this.paramContainer.blContainerShortShipmentVesselId +
          "/" +
          this.paramContainer.blContainerShortShipmentVesselBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselVoyage +
          "/" +
          this.model.blOceanVesselBound
      );
    } else {
      this.paramContainer.blContainerShortShipmentVesselId = "";
      this.paramContainer.blContainerShortShipmentVesselCode = "";
      this.paramContainer.blContainerShortShipmentVesselName = "";
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
      this.paramContainer.blContainerShortShipmentVesselBound = "";
      this.cbBlContainerShortShipmentVoyage.setUrl("");
      this.cbBlContainerShortShipmentVoyage.setValue("");
    }
    this.cbBlContainerShortShipmentVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventCbBlContainerShortShipmentVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage !== "" &&
      event.voyage !== undefined
    ) {
      this.paramContainer.blContainerShortShipmentVesselVoyage = event.voyage;
      this.paramContainer.blContainerShortShipmentVesselBound = event.bound;
      this.cbBlContainerShortShipmentVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageWithEtd/" +
          this.paramContainer.blContainerShortShipmentVesselId +
          "/" +
          this.paramContainer.blContainerShortShipmentVesselBound.toUpperCase() +
          "/{query}/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.model.blOceanVesselId +
          "/" +
          this.model.blOceanVesselVoyage +
          "/" +
          this.model.blOceanVesselBound
      );
    } else {
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
    }
  }

  changeEventRbBlContainerShortShipmentFrom(event) {
    if (event.target.checked) {
      this.paramContainer.blContainerShortShipmentFrom = true;
      this.paramContainer.blContainerShortShipmentTo = false;
      this.paramContainer.blContainerShortShipmentBlRef = "";
      this.paramContainer.blContainerShortShipmentVesselId = "";
      this.paramContainer.blContainerShortShipmentVesselCode = "";
      this.paramContainer.blContainerShortShipmentVesselName = "";
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
      this.paramContainer.blContainerShortShipmentVesselBound = "";
      this.cbBlContainerShortShipmentVessel.setForceValue("");
      this.cbBlContainerShortShipmentVoyage.setForceValue("");
    } else {
      this.paramContainer.blContainerShortShipmentFrom = false;
    }
  }

  changeEventRbBlContainerShortShipmentTo(event) {
    if (event.target.checked) {
      this.isErrorContainer = false;
      this.paramContainer["error-blContainerShortshipment"] = "";

      this.paramContainer.blContainerShortShipmentTo = true;
      this.paramContainer.blContainerShortShipmentFrom = false;
      this.paramContainer.blContainerShortShipmentBlRef = "";
      this.paramContainer.blContainerShortShipmentVesselId = "";
      this.paramContainer.blContainerShortShipmentVesselCode = "";
      this.paramContainer.blContainerShortShipmentVesselName = "";
      this.paramContainer.blContainerShortShipmentVesselVoyage = "";
      this.paramContainer.blContainerShortShipmentVesselBound = "O";
      this.cbBlContainerShortShipmentVessel.setForceValue("");
      this.cbBlContainerShortShipmentVoyage.setForceValue("");
    } else {
      this.paramContainer.blContainerShortShipmentTo = false;
    }
  }


  // Nett Charge Refreight handler
  onBlNettChargeRefreight() {
    this.frmDialogMessage = "Are you sure to Re-Freight BL?";
    this.genericUtil.showDialog("blDialogRefreight", "Confirm", 350, 150);
  }

  onBlNettChargeRefreightNew() {
    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/BL/runRefreightByOfficeCodeBlNo/" +
            this.model.blOfficeCode +
            "/" +
            this.model.blNo
        )
        .subscribe((resp) => {
          const dt = resp.json();
          if (dt["status"] === "OK") {
            console.log("refreight triggered");
            console.log(resp.json());

            // store the old pop and payer
            let p1 = this.model.blPrepaidAt1Code;
            let p2 = this.model.blPrepaidAt2Code;
            let p3 = this.model.blPrepaidAt3Code;

            // when all blank must pull from charge
            /*
           const lsPrepaidPop: Array<string> = [];
           if (p1 === '' && p2 === '' && p3 === '') {
           if (dt['chargeData'] != null && dt['chargeData'].length > 0) {
           for (const entry of dt['chargeData']) {
           if(entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode !== '') {
           if (!lsPrepaidPop.includes(entry.blFCPlaceOfPaymentCode )) {
           lsPrepaidPop.push(entry.blFCPlaceOfPaymentCode);
           }
           }
           }
           }
           }
           if (lsPrepaidPop.length > 0) {
           let ctr = 1;
           for(const str of lsPrepaidPop) {
           if (ctr === 1) {p1 = str; }
           if (ctr === 2) {p2 = str; }
           if (ctr === 3) {p3 = str; }
           ctr++;
           }
           }
           */

            let c1 = this.model.blPayableAt1Code;
            let c2 = this.model.blPayableAt2Code;
            let c3 = this.model.blPayableAt3Code;
            /*
           const lsCollectPop: Array<string> = [];
           if (c1 === '' && c2 === '' && c3 === '') {
           if (dt['chargeData'] != null && dt['chargeData'].length > 0) {
           for (const entry of dt['chargeData']) {
           if(entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode !== '') {
           if (!lsCollectPop.includes(entry.blFCPlaceOfPaymentCode )) {
           lsCollectPop.push(entry.blFCPlaceOfPaymentCode);
           }
           }
           }
           }
           }
           if (lsCollectPop.length > 0) {
           let ctr = 1;
           for(const str of lsCollectPop) {
           if (ctr === 1) {c1 = str; }
           if (ctr === 2) {c2 = str; }
           if (ctr === 3) {c3 = str; }
           ctr++;
           }
           }
           */

            const py1 = this.model.blPayer1Code;
            const py2 = this.model.blPayer2Code;
            const py3 = this.model.blPayer3Code;

            // store grid old value before reset
            const oldGridValue: Array<BLFreightCharge> = [];
            if (this.gridBlNettCharge.listStore.store.length > 0) {
              for (
                let i = 0;
                i < this.gridBlNettCharge.listStore.store.length;
                i++
              ) {
                oldGridValue.push(this.gridBlNettCharge.listStore.store[i]);
              }
            }

            if (dt["chargeData"] != null && dt["chargeData"].length > 0) {
              console.log("---- debug regreight glg -----");
              console.log(oldGridValue);
              console.log(dt["chargeData"]);
              // reset the pop and payer
              this.cbBlNettChargePrepaid1.setValue("");
              this.cbBlNettChargePrepaid2.setValue("");
              this.cbBlNettChargePrepaid3.setValue("");
              this.cbBlNettChargePayableAt1.setValue("");
              this.cbBlNettChargePayableAt2.setValue("");
              this.cbBlNettChargePayableAt3.setValue("");
              this.cbBlNettChargeBillingParty1.setValue("");
              this.cbBlNettChargeBillingParty2.setValue("");
              this.cbBlNettChargeBillingParty3.setValue("");

              // reset the grid
              this.gridBlNettCharge.onClear();

              // check value after rest
              console.log("p1 = " + p1 + "   p2 = " + p2 + "   p3 = " + p3);
              console.log("c1 = " + c1 + "   c2 = " + c2 + "   c3 = " + c3);
              console.log(
                "py1 = " + py1 + "   py2 = " + py2 + "   py3 = " + py3
              );

              // Check again, for each value on p,c, and py against chargedata and adhoc, if now not used must be removed
              let p1Found = false;
              let p2Found = false;
              let p3Found = false;
              let c1Found = false;
              let c2Found = false;
              let c3Found = false;
              let py1Found = false;
              let py2Found = false;
              let py3Found = false;

              // checking P/C
              if (p1 != null && p1 !== "" && p1 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "P" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === p1
                  ) {
                    p1Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "P" &&
                    entry.blFCPlaceOfPaymentCode === p1
                  ) {
                    p1Found = true;
                  }
                }
              }
              if (p2 != null && p2 !== "" && p2 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "P" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === p2
                  ) {
                    p2Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "P" &&
                    entry.blFCPlaceOfPaymentCode === p2
                  ) {
                    p2Found = true;
                  }
                }
              }
              if (p3 != null && p3 !== "" && p3 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "P" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === p3
                  ) {
                    p3Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "P" &&
                    entry.blFCPlaceOfPaymentCode === p3
                  ) {
                    p3Found = true;
                  }
                }
              }

              // checking C
              if (c1 != null && c1 !== "" && c1 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "C" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === c1
                  ) {
                    c1Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "C" &&
                    entry.blFCPlaceOfPaymentCode === c1
                  ) {
                    c1Found = true;
                  }
                }
              }
              if (c2 != null && c2 !== "" && c2 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "C" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === c2
                  ) {
                    c2Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "C" &&
                    entry.blFCPlaceOfPaymentCode === c2
                  ) {
                    c2Found = true;
                  }
                }
              }
              if (c3 != null && c3 !== "" && c3 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (
                    dt.chargeData[i].blFCPc === "C" &&
                    dt.chargeData[i].blFCPlaceOfPaymentCode === c3
                  ) {
                    c3Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (
                    entry.blFCIsEntered === true &&
                    entry.blFCPc === "C" &&
                    entry.blFCPlaceOfPaymentCode === c3
                  ) {
                    c3Found = true;
                  }
                }
              }

              // checking Py
              if (py1 != null && py1 !== "" && py1 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (dt.chargeData[i].blFCPayer === py1) {
                    py1Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (entry.blFCIsEntered === true && entry.blFCPayer === py1) {
                    py1Found = true;
                  }
                }
              }
              if (py2 != null && py2 !== "" && py2 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (dt.chargeData[i].blFCPayer === py2) {
                    py2Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (entry.blFCIsEntered === true && entry.blFCPayer === py2) {
                    py2Found = true;
                  }
                }
              }
              if (py3 != null && py3 !== "" && py3 !== undefined) {
                for (let i = 0; i < dt.chargeData.length; i++) {
                  if (dt.chargeData[i].blFCPayer === py3) {
                    py3Found = true;
                  }
                }
                for (const entry of oldGridValue) {
                  if (entry.blFCIsEntered === true && entry.blFCPayer === py3) {
                    py3Found = true;
                  }
                }
              }

              // check again
              console.log(
                "p1 = " + p1Found + "   p2 = " + p2Found + "   p3 = " + p3Found
              );
              console.log(
                "c1 = " + c1Found + "   c2 = " + c2Found + "   c3 = " + c3Found
              );
              console.log(
                "py1 = " +
                  py1Found +
                  "   py2 = " +
                  py2Found +
                  "   py3 = " +
                  py3Found
              );

              // double check
              /*
             if (p1Found === false && p2Found === false && p3Found === false) {
             const lsPopPrepaid: Array<string>  = [];
             for(const entry of dt.chargeData) {
             if (!lsPopPrepaid.includes(entry.blFCPlaceOfPaymentCode)) {
             lsPopPrepaid.push(entry.blFCPlaceOfPaymentCode);
             }
             }
             for(const entry of oldGridValue)
             }
             */

              // re-assign the pop and payer
              if (p1Found === true) {
                this.model.blPrepaidAt1Code = p1;
                this.cbBlNettChargePrepaid1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid1.setValue(p1);
              }
              if (p2Found === true) {
                this.model.blPrepaidAt2Code = p2;
                this.cbBlNettChargePrepaid2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid2.setValue(p2);
              }
              if (p3Found === true) {
                this.model.blPrepaidAt1Code = p3;
                this.cbBlNettChargePrepaid3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid3.setValue(p3);
              }

              if (c1Found === true) {
                this.model.blPayableAt1Code = c1;
                this.cbBlNettChargePayableAt1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt1.setValue(c1);
              }
              if (c2Found === true) {
                this.model.blPayableAt1Code = c2;
                this.cbBlNettChargePayableAt2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt2.setValue(c2);
              }
              if (c3Found === true) {
                this.model.blPayableAt1Code = c3;
                this.cbBlNettChargePayableAt3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt3.setValue(c2);
              }

              if (py1Found === true) {
                // payer started
                this.model.blPayer1Code = py1;
                this.cbBlNettChargeBillingParty1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty1.setValue(py1);

              }
              if (py2Found === true) {
                this.model.blPayer2Code = py2;
                this.cbBlNettChargeBillingParty2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}",
                );
                this.cbBlNettChargeBillingParty2.setValue(py2);
              }
              if (py3Found === true) {
                this.model.blPayer3Code = py3;
                this.cbBlNettChargeBillingParty3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty3.setValue(py3);
              }

              // do the freight thing
              // 01. Check against oldGridValue for previous charge data
              let fcSeqno = 1;
              for (const cData of dt["chargeData"]) {
                cData.blFCSeq = fcSeqno;
                cData.blFCIsEnteredString =
                  cData.blFCIsEntered === true ? "Y" : "N";
                cData.blFCIsChargePrintedString =
                  cData.blFCIsChargePrinted === true ? "Y" : "N";
                cData.blFCIsManifestedFreightString =
                  cData.blFCIsManifestedFreight === true ? "Y" : "N";
                cData.blFCFaiGroupString =
                  cData.blFCFaiGroup === true ? "Y" : "N";

                //setter PC
                switch (cData.blFCPlaceOfPaymentCode) {
                  case p1: {
                    cData.blFCPc = "P1";
                    break;
                  }
                  case p2: {
                    cData.blFCPc = "P2";
                    break;
                  }
                  case p3: {
                    cData.blFCPc = "P3";
                    break;
                  }
                  case c1: {
                    cData.blFCPc = "C1";
                    break;
                  }
                  case c2: {
                    cData.blFCPc = "C2";
                    break;
                  }
                  case c3: {
                    cData.blFCPc = "C3";
                    break;
                  }
                  default: {
                    cData.blFCPc = "";
                    break;
                  }
                }

                //setter Payer
                switch (cData.blFCPayer) {
                  case py1: {
                    cData.blFCPayer = "1";
                    break;
                  }
                  case py2: {
                    cData.blFCPayer = "2";
                    break;
                  }
                  case py3: {
                    cData.blFCPayer = "3";
                    break;
                  }
                  default: {
                    cData.blFCPayer = "";
                    break;
                  }
                }

                for (const entry of oldGridValue) {
                  // check for charge code, and percode
                  if (
                    cData.blFCChargeCode === entry.blFCChargeCode &&
                    cData.blFCPerCode === entry.blFCPerCode
                  ) {
                    cData.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                    cData.blFCIsChargePrintedString =
                      entry.blFCIsChargePrintedString;
                    cData.blFCIsManifestedFreight =
                      entry.blFCIsManifestedFreight;
                    cData.blFCIsManifestedFreightString =
                      entry.blFCIsManifestedFreightString;
                    //cData.blFCFaiGroup = entry.blFCFaiGroup;
                    //cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                    cData.blFCPayer = entry.blFCPayer;
                  }
                }
                this.gridBlNettCharge.listStore.store.push(cData);
                fcSeqno++;
              }

              // revert the adhoc value
              for (const entry of oldGridValue) {
                if (entry.blFCIsEntered === true) {
                  entry.blFCSeq = fcSeqno;
                  this.gridBlNettCharge.listStore.store.push(entry);
                }
                fcSeqno++;
              }

              this.gridBlNettCharge.loadData();
            } else {
              // reset the grid
              this.gridBlNettCharge.onClear();
            }

            // action to detect the changes
            this.frmDialogMessage = "";
            if (dt["errorList"].length > 0) {
              this.frmDialogMessage +=
                '<table border="0" width="100%"><tr><td>## DATA CHANGED ##</td></tr></table>';
              this.frmDialogMessage +=
                '<table border="0" width="100%"><tr><td width="4%" align="center" valign="top"><b>No</b></td><td width="30%" valign="top" align="center"><b>Type</b></td><td width="30%" valign="top" align="center"><b>BL</b></td><td width="6%" valign="top">&nbsp;</td><td width="30%" valign="top" align="center"><b>BKG</b></td></tr>';
              for (let i = 0; i < dt["errorList"].length; i++) {
                this.frmDialogMessage +=
                  '<tr><td align="center" valign="top">' +
                  (i + 1) +
                  '</td><td valign="top" align="center">' +
                  dt["errorList"][i]["type"] +
                  '</td><td valign="top" align="center">' +
                  (dt["errorList"][i]["bl"] === ""
                    ? "[BLANK]"
                    : dt["errorList"][i]["bl"]) +
                  '</td><td align="center">==></td><td valign="top" align="center">' +
                  dt["errorList"][i]["booking"] +
                  "</td></tr>";
              }
              this.frmDialogMessage += "</table>";
            } else {
            }

            if (this.frmDialogMessage !== "") {
              this.refreightData = dt;
              this.genericUtil.showDialog(
                "clickRefreightDialogNew",
                "Information",
                400,
                400
              );
            }
          }
        });
    }
    // this.genericUtil.closeDialog('blDialogRefreight');
  }

  onBlDialogClickRefreightDialogNewOk() {
    console.log("** onBlDialogClickRefreightDialogNewOk **");

    if (this.refreightData["errorList"].length > 0) {
      for (const entry of this.refreightData["errorList"]) {
        console.log(entry.type + " - " + entry.booking);
        // 01. detect rtype
        if (entry.type === "R/Type") {
          this.cbBlPlaceReceiptType.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}"
          );
          this.cbBlPlaceReceiptType.setValue(entry.booking);
          this.model.blReceiptTypeCode = entry.booking;
        }
        // 02. detect dtype
        if (entry.type === "D/Type") {
          this.cbBlPlaceDeliveryType.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}"
          );
          this.cbBlPlaceDeliveryType.setValue(entry.booking);
          this.model.blDeliveryTypeCode = entry.booking;
        }
        // 03. detect POR
        if (entry.type === "POR") {
          this.cbBlPlacePOR.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOR.setValue(entry.booking);
          this.model.blPlaceOfReceiptCode = entry.booking;
        }
        // 04. detect POL
        if (entry.type === "POL") {
          this.cbBlPlacePOL.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOL.setValue(entry.booking);
          this.model.blLoadPortCode = entry.booking;
        }
        // 05. detect POD
        if (entry.type === "POD") {
          this.cbBlPlacePOD.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOD.setValue(entry.booking);
          this.model.blDischargePortCode = entry.booking;
        }
        // 06. detect PODL
        if (entry.type === "PODL") {
          this.cbBlPlacePODL.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePODL.setValue(entry.booking);
          this.model.blPlaceOfDeliveryCode = entry.booking;
        }
        // 07. detect FDEST
        if (entry.type === "FDEST") {
          this.cbBlPlaceFDEST.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlaceFDEST.setValue(entry.booking);
          this.model.blFinalDestinationCode = entry.booking;

          if(this.blPlaceDataFromBooking === true){
            // this.blPlacePrintBlFinalDestination = true;
            this.blPlacePrintBlFinalDestination2 = true;
            // this.model.blPlaceOfReceiptPrint = "";
            // this.model.blLoadPortPrint = "";
            // this.model.blDischargePortPrint = "";
            // this.model.blPlaceOfDeliveryPrint = "";
            this.model.blFinalDestinationPrint = "";
            this.cbBlPlaceFDEST.setValue("");
            this.model.blFinalDestinationCode = "";
          }

          if(this.blPlaceDataFromBooking === false){
            // this.blPlacePrintBlFinalDestination = false;
            this.blPlacePrintBlFinalDestination2 = false;
            this.model.blPlaceOfReceiptPrint = this.model.blPlaceOfReceiptName;
            this.model.blLoadPortPrint = this.model.blLoadPortName;
            this.model.blDischargePortPrint = this.model.blDischargePortName;
            this.model.blPlaceOfDeliveryPrint = this.model.blPlaceOfDeliveryName;
            this.model.blFinalDestinationPrint = this.model.blFinalDestinationName;
            this.cbBlPlaceFDEST.setValue(entry.booking);
            this.model.blFinalDestinationCode = entry.booking;
          }
        }
        // 08. detect POT1
        if (entry.type === "POT1") {
          this.cbBlPlacePOT1.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOT1.setValue(entry.booking);
          this.model.blPortOfTransshipment1Code = entry.booking;
        }
        // 09. detect POT2
        if (entry.type === "POT2") {
          this.cbBlPlacePOT2.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOT2.setValue(entry.booking);
          this.model.blPortOfTransshipment2Code = entry.booking;
        }
        // 10. detect POT3
        if (entry.type === "POT3") {
          this.cbBlPlacePOT3.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
          );
          this.cbBlPlacePOT3.setValue(entry.booking);
          this.model.blPortOfTransshipment3Code = entry.booking;
        }
        // 11. detect Detention
        if (entry.type === "DET") {
          this.model.blDetention = entry.booking;
        }
        // 12. detect Detention Request
        if (entry.type === "DET REQ") {
          this.model.blDetentionRequest = entry.booking;
        }
        // 13. detect Detention
        if (entry.type === "DEM") {
          this.model.blDemurrage = entry.booking;
        }
        // 14. detect Detention Request
        if (entry.type === "DEM REQ") {
          this.model.blDemurrageRequest = entry.booking;
        }
        // 15. detect DG Class
        if (entry.type === "DGCLS") {
          this.model.blDgClass = entry.booking;
          this.paramContainer = new BLContainer();
          this.paramContainer.blContainerDGClass = entry.booking;
          this.gridBlContainer.store.length > 0 && this.gridBlContainer.store.forEach(item=>{
            if(entry.booking === "N"){
              item.blContainerDGClass = "N/A";
              item.blContainerSpecialTypeName = "";
            }else{
              item.blContainerDGClass = entry.booking;
            }
          })
        }
        // 16. detect VSL
        if (entry.type === "VSL") {
          this.cbBlPlaceOceanVessel.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterVessels/findByComboBoxControl/vesselId={query}"
          );
          this.cbBlPlaceOceanVessel.setValue(entry.bookingVslId);
          this.model.blOceanVesselCode = entry.booking;
        }

        // 17. detect Voyage
        if (entry.type === "VOY") {
          this.cbBlPlaceOceanVoyage.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              this.model.blOceanVesselId +
              "/" +
              this.model.blOceanVesselBound.toUpperCase() +
              "/{query}/" +
              this.cookieService.getDefaultLocationCode()
          );
          this.cbBlPlaceOceanVoyage.setValue(entry.booking);
          this.model.blOceanVesselVoyage = entry.booking;

          // also update bound
          this.model.blOceanVesselBound =
            this.originalOceanVesselBound.toString();
        }

        if (entry.type === "BOUND") {
          this.model.blOceanVesselBound =
            this.originalOceanVesselBound.toString();
        }

        // 18. detect  Container Ownership
        if (entry.type === "COWNER") {
          this.model.blContainerOwnership = entry.booking;
        }

        // 19. detect  Container Ownership
        if (entry.type === "OPCODE") {
          this.model.blOperatorCode = entry.booking;
        }

        // 20. detect  Container Ownership
        if (entry.type === "SOCODE") {
          this.model.blSOOperatorCode = entry.booking;
        }

        // 21. detect  Container Status
        if (entry.type === "CNTRSTATUS") {
          this.model.blBookingContainerStatus = entry.booking;
          this.model.blContainerStatus = entry.booking;
        }

        // 22. detect  EX VESSEL
        if (entry.type === "EXVSL") {
          this.model.blPreVesselId = entry.bookingPreVesselId;
          this.model.blPreVesselCode = entry.booking;
          this.cbBlPlacePreVessel.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterVessels/findByComboBoxControl/vesselId={query}"
          );
          this.cbBlPlacePreVessel.setValue(entry.bookingPreVesselId);
          this.model.blPreVesselBound = "";
        }
        // 23. detect  EX VOYAGE
        if (entry.type === "EXVOY") {
          if (this.model.blPreVesselId !== "") {
            this.model.blPreVesselVoyage = entry.booking;
            this.model.blPreVesselBound = "M";
          } else {
            this.model.blPreVesselVoyage = entry.booking;
            this.model.blPreVesselBound = "";
          }
        }
        // 24. detect  EX BOUND
        if (entry.type === "EXBOUND") {
          this.model.blPreVesselBound = entry.booking;
        }

        //25. detect NEXT VSL
        if(entry.type === 'NEXTVSL') {
          this.model.blNextVesselId = entry.bookingNextVesselId;
          this.model.blNextVesselCode = entry.bookingNextVesselCode;
          this.model.blNextVesselVoyage = entry.bookingNextVesselVoyage;
          this.model.blNextVesselName = entry.bookingNextVesselName;
          this.cbBlPlaceNextVessel.setForceValue(this.model.blNextVesselName);
        }
      }
    }

    console.log(
      "this.isRefreightInvokedFromFinal --> " + this.isRefreightInvokedFromFinal
    );
    this.changeDataFirstTime = "FirstTime";
    if (this.isRefreightInvokedFromFinal == true) {
      this.genericUtil.closeDialog("clickRefreightDialogNew");
      this.onBlDialogRefreightYes();
    } else {
      this.genericUtil.closeDialog("clickRefreightDialogNew");
    }
  }

  onBlDialogRefreightYes() {
    console.log("** onBlDialogRefreightYes **");
    this.isRefreightProcess = true;
    this.isRefreightButtonClicked = true;

    if (this.model.blType !== "SW" && this.model.blType !== "OIS") {
      let hitUrl = "";
      if (this.model.blIsSplit === "Y") {
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/BLBooking/refreightFromBLSplit/" +
          this.model.blOfficeCode +
          "/" +
          "SUDU" +
          "/" +
          this.model.blNo.replace("SSL", "");
      } else {
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/BLBooking/refreightFromBL/" +
          this.model.blOfficeCode +
          "/" +
          "SUDU" +
          "/" +
          this.model.blNo.replace("SSL", "");
      }
      /*  commented out, because it duplicated with function run refreight in previous function
       this.genericService.GET(hitUrl).subscribe((resp) => {
       if (resp.json()['status'] === 'OK') {
       console.log('refreight triggered');
       const dt = resp.json();

       const adHocList: Array<BLFreightCharge> = [];
       const nonAdHocList: Array<BLFreightCharge> = [];
       if (this.gridBlNettCharge.listStore.store.length > 0) {
       for (let i = 0; i < this.gridBlNettCharge.listStore.store.length; i++) {
       if (this.gridBlNettCharge.listStore.store[i].blFCIsEntered === true) {
       adHocList.push(this.gridBlNettCharge.listStore.store[i]);
       }else{
       nonAdHocList.push(this.gridBlNettCharge.listStore.store[i]);
       }
       }
       }

       this.gridBlNettCharge.onClear();


       // render prepaid collect combo first
       const prepaidList: Array<string> = [];
       const collectList: Array<string> = [];
       const payerList: Array<string> = [];

       for (let i = 0; i < dt.chargeData.length; i++) {
       if (dt.chargeData[i].blFCPc === 'P') {
       if (!prepaidList.includes(dt.chargeData[i].blFCPlaceOfPaymentCode)) {
       prepaidList.push(dt.chargeData[i].blFCPlaceOfPaymentCode);
       }
       } else if (dt.chargeData[i].blFCPc === 'C') {
       if (!collectList.includes(dt.chargeData[i].blFCPlaceOfPaymentCode)) {
       collectList.push(dt.chargeData[i].blFCPlaceOfPaymentCode);
       }
       }

       if (dt.chargeData[i].blFCPayer !== '') {
       if (!payerList.includes(dt.chargeData[i].blFCPayer)) {
       payerList.push(dt.chargeData[i].blFCPayer);
       }
       }

       }

       console.log('*** debug prepaid payer ***');
       console.log(prepaidList);
       console.log(collectList);
       console.log(payerList);

       // must reset all prepaid, payable and biling party
       this.cbBlNettChargePrepaid1.setValue('');
       this.cbBlNettChargePrepaid2.setValue('');
       this.cbBlNettChargePrepaid3.setValue('');
       this.cbBlNettChargePayableAt1.setValue('');
       this.cbBlNettChargePayableAt2.setValue('');
       this.cbBlNettChargePayableAt3.setValue('');
       this.cbBlNettChargeBillingParty1.setValue('');
       this.cbBlNettChargeBillingParty2.setValue('');
       this.cbBlNettChargeBillingParty3.setValue('');



       // loop for prepaid
       let counter = 1;
       for (const entry of prepaidList) {
       if (counter === 1) {
       this.model.blPrepaidAt1Code = entry;
       this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePrepaid1.setValue(entry);
       }

       if (counter === 2) {
       this.model.blPrepaidAt2Code = entry;
       this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePrepaid2.setValue(entry);
       }

       if (counter === 3) {
       this.model.blPrepaidAt3Code = entry;
       this.cbBlNettChargePrepaid3.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePrepaid3.setValue(entry);
       }

       counter ++;
       }

       // loop for collect
       counter = 1;
       for (const entry of collectList) {
       if (counter === 1) {
       this.model.blPayableAt1Code = entry;
       this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePayableAt1.setValue(entry);
       }

       if (counter === 2) {
       this.model.blPayableAt2Code = entry;
       this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePayableAt2.setValue(entry);
       }

       if (counter === 3) {
       this.model.blPayableAt3Code = entry;
       this.cbBlNettChargePayableAt3.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
       this.cbBlNettChargePayableAt3.setValue(entry);
       }
       counter ++;
       }

       // loop for payer
       counter = 1;
       for (const entry of payerList) {
       console.log('debug payer => ' + entry);
       if (counter === 1) {
       this.model.blPayer1Code = entry;
       this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
       this.cbBlNettChargeBillingParty1.setValue(entry);
       }

       if (counter === 2) {
       this.model.blPayer2Code = entry;
       this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
       this.cbBlNettChargeBillingParty2.setValue(entry);
       }

       if (counter === 3) {
       this.model.blPayer3Code = entry;
       this.cbBlNettChargeBillingParty3.setUrl(this.configService.config.BASE_API.toString() +
       '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
       this.cbBlNettChargeBillingParty3.setValue(entry);
       }
       counter++;
       }



       for (let i = 0; i < dt.chargeData.length; i++) {
       const dataNettCharge = new BLFreightCharge();
       dataNettCharge.blFCOfficeCode = dt.chargeData[i].blFCOfficeCode;
       dataNettCharge.blFCBlNo =  dt.chargeData[i].blFCBlNo;
       dataNettCharge.blFCSeq = i + 1;
       dataNettCharge.blFCChargeCode = dt.chargeData[i].blFCChargeCode;
       dataNettCharge.blFCRevenue = dt.chargeData[i].blFCRevenue;
       dataNettCharge.blFCRate = dt.chargeData[i].blFCRate;
       dataNettCharge.blFCPerCode = dt.chargeData[i].blFCPerCode;
       dataNettCharge.blFCCurrencyCode = dt.chargeData[i].blFCCurrencyCode;
       dataNettCharge.blFCAmount = dt.chargeData[i].blFCAmount;

       // Pc Worker
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt1Code) {
       dataNettCharge.blFCPc = 'P1';
       }
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt2Code) {
       dataNettCharge.blFCPc = 'P2';
       }
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPrepaidAt3Code) {
       dataNettCharge.blFCPc = 'P3';
       }
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPayableAt1Code) {
       dataNettCharge.blFCPc = 'C1';
       }
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPayableAt2Code) {
       dataNettCharge.blFCPc = 'C2';
       }
       if (dt.chargeData[i].blFCPlaceOfPaymentCode === this.model.blPayableAt3Code) {
       dataNettCharge.blFCPc = 'C3';
       }


       dataNettCharge.blFCPlaceOfPaymentCode = dt.chargeData[i].blFCPlaceOfPaymentCode;
       dataNettCharge.blFCPlaceOfPaymentName = dt.chargeData[i].blFCPlaceOfPaymentName;

       console.log('refreight debug');
       console.log(dt.chargeData[i].blFCPayer + ' vs ' + this.model.blPayer1Code);
       console.log(dt.chargeData[i].blFCPayer + ' vs ' + this.model.blPayer2Code);
       console.log(dt.chargeData[i].blFCPayer + ' vs ' + this.model.blPayer3Code);
       console.log('debug end');
       if (dt.chargeData[i].blFCPayer !== '' && dt.chargeData[i].blFCPayer === this.model.blPayer1Code) {
       dataNettCharge.blFCPayer = '1';
       }
       if (dt.chargeData[i].blFCPayer !== '' && dt.chargeData[i].blFCPayer === this.model.blPayer2Code) {
       dataNettCharge.blFCPayer = '2';
       }
       if (dt.chargeData[i].blFCPayer !== '' && dt.chargeData[i].blFCPayer === this.model.blPayer3Code) {
       dataNettCharge.blFCPayer = '3';
       }

       dataNettCharge.blFCContainerType = dt.chargeData[i].blFCContainerType;
       dataNettCharge.blFCIsEntered = dt.chargeData[i].blFCIsEntered;

       // is manifested handler
       for(const nonAdhocEntry of nonAdHocList){
       // console.log('1 >> ' + nonAdhocEntry.blFCChargeCode + ' VS ' + dt.chargeData[i].blFCChargeCode);
       // console.log('2 >> ' + nonAdhocEntry.blFCPerCode + ' VS ' + dt.chargeData[i].blFCPerCode);
       // console.log('3 >> ' + nonAdhocEntry.blFCCurrencyCode + ' VS ' + dt.chargeData[i].blFCCurrencyCode);
       // console.log('4 >> ' + nonAdhocEntry.blFCPc + ' VS ' + dt.chargeData[i].blFCPc);

       if (nonAdhocEntry.blFCChargeCode === dt.chargeData[i].blFCChargeCode && nonAdhocEntry.blFCPerCode === dt.chargeData[i].blFCPerCode && nonAdhocEntry.blFCCurrencyCode === dt.chargeData[i].blFCCurrencyCode){
       dataNettCharge.blFCIsManifestedFreight = nonAdhocEntry.blFCIsManifestedFreight;
       dataNettCharge.blFCIsManifestedFreightString = nonAdhocEntry.blFCIsManifestedFreightString;
       }
       }



       for(const nonAdhocEntry of nonAdHocList){
       if (nonAdhocEntry.blFCChargeCode === dt.chargeData[i].blFCChargeCode && nonAdhocEntry.blFCPerCode === dt.chargeData[i].blFCPerCode && nonAdhocEntry.blFCCurrencyCode === dt.chargeData[i].blFCCurrencyCode){
       dataNettCharge.blFCIsChargePrinted = nonAdhocEntry.blFCIsChargePrinted;
       dataNettCharge.blFCIsChargePrintedString = nonAdhocEntry.blFCIsChargePrintedString;
       }
       }

       for(const nonAdhocEntry of nonAdHocList){
       if (nonAdhocEntry.blFCChargeCode === dt.chargeData[i].blFCChargeCode && nonAdhocEntry.blFCPerCode === dt.chargeData[i].blFCPerCode && nonAdhocEntry.blFCCurrencyCode === dt.chargeData[i].blFCCurrencyCode){
       dataNettCharge.blFCFaiGroup = nonAdhocEntry.blFCFaiGroup;
       dataNettCharge.blFCFaiGroupString = nonAdhocEntry.blFCFaiGroupString;
       }
       }


       dataNettCharge.blFCFreightReference = dt.chargeData[i].blFCFreightReference;

       this.gridBlNettCharge.listStore.store.push(dataNettCharge);
       }

       // re-push adhoc
       let counterAdHoc = (this.gridBlNettCharge.listStore.store.length + 1);
       for (const entry of adHocList) {
       const dataNettCharge = new BLFreightCharge();
       dataNettCharge.blFCOfficeCode = this.model.blOfficeCode;
       dataNettCharge.blFCBlNo =  entry.blFCBlNo;
       dataNettCharge.blFCSeq = counterAdHoc;
       dataNettCharge.blFCChargeCode = entry.blFCChargeCode;
       dataNettCharge.blFCRevenue = entry.blFCRevenue;
       dataNettCharge.blFCRate = entry.blFCRate;
       dataNettCharge.blFCPerCode = entry.blFCPerCode;
       dataNettCharge.blFCCurrencyCode = entry.blFCCurrencyCode;
       dataNettCharge.blFCAmount = entry.blFCAmount;

       // Pc Worker
       if (entry.blFCPlaceOfPaymentCode === this.model.blPrepaidAt1Code) {
       dataNettCharge.blFCPc = 'P1';
       }
       if (entry.blFCPlaceOfPaymentCode === this.model.blPrepaidAt2Code) {
       dataNettCharge.blFCPc = 'P2';
       }
       if (entry.blFCPlaceOfPaymentCode === this.model.blPrepaidAt3Code) {
       dataNettCharge.blFCPc = 'P3';
       }
       if (entry.blFCPlaceOfPaymentCode === this.model.blPayableAt1Code) {
       dataNettCharge.blFCPc = 'C1';
       }
       if (entry.blFCPlaceOfPaymentCode === this.model.blPayableAt2Code) {
       dataNettCharge.blFCPc = 'C2';
       }
       if (entry.blFCPlaceOfPaymentCode === this.model.blPayableAt3Code) {
       dataNettCharge.blFCPc = 'C3';
       }


       dataNettCharge.blFCPlaceOfPaymentCode = entry.blFCPlaceOfPaymentCode;
       dataNettCharge.blFCPlaceOfPaymentName = entry.blFCPlaceOfPaymentName;

       console.log('--debug sin adhoc--');
       console.log(entry.blFCPayer + ' VS ' + this.model.blPayer1Code);
       if (entry.blFCPayer !== '' && (entry.blFCPayer === this.model.blPayer1Code || entry.blFCPayer === '1')) {
       dataNettCharge.blFCPayer = '1';
       }
       if (entry.blFCPayer !== '' && (entry.blFCPayer === this.model.blPayer2Code || entry.blFCPayer === '2')) {
       dataNettCharge.blFCPayer = '2';
       }
       if (entry.blFCPayer !== '' && (entry.blFCPayer === this.model.blPayer3Code || entry.blFCPayer === '3')) {
       dataNettCharge.blFCPayer = '3';
       }

       dataNettCharge.blFCContainerType = entry.blFCContainerType;
       // dataNettCharge.blFCIsEntered = entry.blFCIsEntered;
       // is blfc is enterd
       if (entry.blFCIsEntered === true) {
       dataNettCharge.blFCIsEntered = true;
       dataNettCharge.blFCIsEnteredString = 'Y';
       } else {
       dataNettCharge.blFCIsEntered = false;
       dataNettCharge.blFCIsEnteredString = 'N';
       }

       // is manifested handler
       if (entry.blFCIsManifestedFreight === true) {
       dataNettCharge.blFCIsManifestedFreight = true;
       dataNettCharge.blFCIsManifestedFreightString = 'Y';
       } else {
       dataNettCharge.blFCIsManifestedFreight = false;
       dataNettCharge.blFCIsManifestedFreightString = 'N';
       }

       // is charge printed handler
       if (entry.blFCIsChargePrinted === true) {
       dataNettCharge.blFCIsChargePrinted = true;
       dataNettCharge.blFCIsChargePrintedString = 'Y';
       } else {
       dataNettCharge.blFCIsChargePrinted = false;
       dataNettCharge.blFCIsChargePrintedString = 'N';
       }

       // is charge fai group
       if (entry.blFCFaiGroup === true) {
       dataNettCharge.blFCFaiGroup = true;
       dataNettCharge.blFCFaiGroupString = 'Y';
       } else {
       dataNettCharge.blFCFaiGroup = false;
       dataNettCharge.blFCFaiGroupString = 'N';
       }

       dataNettCharge.blFCFreightReference = entry.blFCFreightReference;

       this.gridBlNettCharge.listStore.store.push(dataNettCharge);
       counterAdHoc ++;
       }
       this.gridBlNettCharge.loadData();


       // check freight in main BL
       let isShortShipmentPresent: Boolean = false;
       if(this.gridBlContainer.listStore.store.length > 0) {
       for (const entry of this.gridBlContainer.listStore.store){
       if(entry.blContainerShortShipContainer) {
       isShortShipmentPresent = true;
       }
       }
       }

       let isFofPresent: Boolean = false;
       if(this.gridBlNettCharge.listStore.store.length > 0) {
       for (const entry of this.gridBlNettCharge.listStore.store){
       if(entry.blFCChargeCode === 'FOF') {
       isFofPresent = true;
       }
       }
       }

       // wrap up
       console.log("this.model.blFreightCoveredInBlNo.trim().toUpperCase() => " + this.model.blFreightCoveredInBlNo.trim().toUpperCase());
       console.log('isShortShipmentPresent => ' + isShortShipmentPresent);
       console.log('isFofPresent => ' + isFofPresent);
       if (isShortShipmentPresent === true && isFofPresent === false) {
       this.model.blFreightCoveredInBlNo = '';
       } else if (isShortShipmentPresent === true && isShortShipmentPresent === true) {
       let ssBlRef: string = '';
       if(this.gridBlContainer.listStore.store.length > 0) {
       for (const entry of this.gridBlContainer.listStore.store){
       if(entry.blContainerShortShipContainer) {
       if(entry.blContainerShortShipmentBlRef.trim().toUpperCase() !== '') {
       ssBlRef = entry.blContainerShortShipmentBlRef;
       }
       }
       }
       }
       this.model.blFreightCoveredInBlNo = ssBlRef;
       }
       }
       });
       */

      // Newly updated
      // check freight in main BL
      let isShortShipmentPresent: Boolean = false;
      if (this.gridBlContainer.listStore.store.length > 0) {
        for (const entry of this.gridBlContainer.listStore.store) {
          if (entry.blContainerShortShipContainer) {
            isShortShipmentPresent = true;
          }
        }
      }

      let isFofPresent: Boolean = false;
      if (this.gridBlNettCharge.listStore.store.length > 0) {
        for (const entry of this.gridBlNettCharge.listStore.store) {
          if (entry.blFCChargeCode === "FOF") {
            isFofPresent = true;
          }
        }
      }

      // wrap up
      console.log(
        "this.model.blFreightCoveredInBlNo.trim().toUpperCase() => " +
          this.model.blFreightCoveredInBlNo.trim().toUpperCase()
      );
      console.log("isShortShipmentPresent => " + isShortShipmentPresent);
      console.log("isFofPresent => " + isFofPresent);
      if (isShortShipmentPresent === true && isFofPresent === false) {
        this.model.blFreightCoveredInBlNo = "";
      } else if (
        isShortShipmentPresent === true &&
        isShortShipmentPresent === true
      ) {
        let ssBlRef: string = "";
        if (this.gridBlContainer.listStore.store.length > 0) {
          for (const entry of this.gridBlContainer.listStore.store) {
            if (entry.blContainerShortShipContainer) {
              if (
                entry.blContainerShortShipmentBlRef.trim().toUpperCase() !== ""
              ) {
                ssBlRef = entry.blContainerShortShipmentBlRef;
              }
            }
          }
        }
        this.model.blFreightCoveredInBlNo = ssBlRef;
      }
    }
    // GOS-685 New re-freight function hanging issue
    this.genericUtil.hideLoader();
    // this.genericUtil.closeDialog("blDialogRefreight");
  }

  onBlDialogRefreightNo() {
    this.genericUtil.closeDialog("blDialogRefreight");
  }

  changeBlShortShipmentOceanBound() {
    this.cbBlContainerShortShipmentVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyageWithEtd/" +
        this.paramContainer.blContainerShortShipmentVesselId +
        "/" +
        this.paramContainer.blContainerShortShipmentVesselBound.toUpperCase() +
        "/{query}/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/" +
        this.model.blOceanVesselId +
        "/" +
        this.model.blOceanVesselVoyage +
        "/" +
        this.model.blOceanVesselBound
    );
  }

  // short shipment handler
  checkShortshipmentBlData() {
    if (this.paramContainer.blContainerShortShipmentFrom === true) {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/checkShortShipmentBooking/" +
            this.cookieService.getDefaultLocationCode() +
            "/" +
            this.paramContainer.blContainerShortShipmentBlRef
              .toUpperCase()
              .trim()
        )
        .subscribe((resp) => {
          const data = resp.json();
          if (data.status === "OK") {
            this.paramContainer.blContainerShortShipmentVesselId =
              data.vesselId;
            this.paramContainer.blContainerShortShipmentVesselCode =
              data.vesselCode;
            this.paramContainer.blContainerShortShipmentVesselName =
              data.vesselName;
            this.paramContainer.blContainerShortShipmentVesselVoyage =
              data.vesselVoyage;
            this.paramContainer.blContainerShortShipmentVesselBound =
              data.vesselBound;
            this.cbBlContainerShortShipmentVessel.setForceValue(
              data.vesselName
            );
            this.cbBlContainerShortShipmentVoyage.setForceValue(
              data.vesselVoyage
            );

            // set freight covered information
            // but must scan first, whether inside the charge got fof or not, only when fof present then set the freight covered in main bl
            let isFofPresent: Boolean = false;
            if (this.gridBlNettCharge.listStore.store.length > 0) {
              for (const entry of this.gridBlNettCharge.listStore.store) {
                if (entry.blFCChargeCode.trim().toUpperCase() === "FOF") {
                  isFofPresent = true;
                }
              }
            }

            if (isFofPresent === true) {
              this.model.blFreightCoveredInBlNo =
                this.paramContainer.blContainerShortShipmentBlRef;
            }
          } else {
            this.frmDialogMessage = "Invalid Booking/BL.";
            this.genericUtil.showDialog(
              "blDialogShortshipEtd",
              "Information",
              350,
              180
            );
          }
        });
      /*
       this.genericService.GET(this.configService.config.BASE_API.toString() + '/BL/checkShortShipmentFrom/' +
       this.cookieService.getDefaultLocationCode() + '/' + this.paramContainer.blContainerShortShipmentBlRef + '/' +
       this.paramContainer.blContainerNumber).subscribe( (resp) => {
       const dt = resp.json();
       if (dt.status !== 'OK' ) {
       this.frmDialogMessage = 'Invalid Shortshipment linkage.';
       this.genericUtil.showDialog('blDialogShortshipEtd', 'Information', 350, 180);
       } else {
       this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/checkShortShipmentBooking/' +
       this.cookieService.getDefaultLocationCode() + '/' +
       this.paramContainer.blContainerShortShipmentBlRef.toUpperCase().trim()).subscribe( (resp) => {
       const data = resp.json();
       if (data.status === 'OK') {
       this.paramContainer.blContainerShortShipmentVesselId = data.vesselId;
       this.paramContainer.blContainerShortShipmentVesselCode = data.vesselCode;
       this.paramContainer.blContainerShortShipmentVesselName = data.vesselName;
       this.paramContainer.blContainerShortShipmentVesselVoyage = data.vesselVoyage;
       this.paramContainer.blContainerShortShipmentVesselBound = data.vesselBound;
       this.cbBlContainerShortShipmentVessel.setForceValue(data.vesselName);
       this.cbBlContainerShortShipmentVoyage.setForceValue(data.vesselVoyage);

       // set freight covered information
       this.model.blFreightCoveredInBlNo = this.paramContainer.blContainerShortShipmentBlRef;
       }
       });
       }});
       */
    } else {
      if (this.paramContainer.blContainerShortShipmentTo === true) {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/BL/checkShortShipmentFrom/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.paramContainer.blContainerShortShipmentBlRef +
              "/" +
              this.paramContainer.blContainerNumber
          )
          .subscribe((resp) => {
            const dt = resp.json();
            if (dt.status !== "OK") {
              this.frmDialogMessage = "Invalid Shortshipment linkage.";
              this.genericUtil.showDialog(
                "blDialogShortshipEtd",
                "Information",
                350,
                180
              );
            } else {
              this.genericService
                .GET(
                  this.configService.config.BASE_API.toString() +
                    "/MasterBookings/checkShortShipmentBooking/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.paramContainer.blContainerShortShipmentBlRef
                      .toUpperCase()
                      .trim()
                )
                .subscribe((resp) => {
                  const data = resp.json();
                  if (data.status === "OK") {
                    this.paramContainer.blContainerShortShipmentVesselId =
                      data.vesselId;
                    this.paramContainer.blContainerShortShipmentVesselCode =
                      data.vesselCode;
                    this.paramContainer.blContainerShortShipmentVesselName =
                      data.vesselName;
                    this.paramContainer.blContainerShortShipmentVesselVoyage =
                      data.vesselVoyage;
                    this.paramContainer.blContainerShortShipmentVesselBound =
                      data.vesselBound;
                    this.cbBlContainerShortShipmentVessel.setForceValue(
                      data.vesselName
                    );
                    this.cbBlContainerShortShipmentVoyage.setForceValue(
                      data.vesselVoyage
                    );

                    // set freight covered information
                    this.model.blFreightCoveredInBlNo = "";
                  }
                });
            }
          });
      } else {
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterBookings/checkShortShipmentBooking/" +
              this.cookieService.getDefaultLocationCode() +
              "/" +
              this.paramContainer.blContainerShortShipmentBlRef
                .toUpperCase()
                .trim()
          )
          .subscribe((resp) => {
            const dt = resp.json();
            if (dt.status === "OK") {
              this.paramContainer.blContainerShortShipmentVesselId =
                dt.vesselId;
              this.paramContainer.blContainerShortShipmentVesselCode =
                dt.vesselCode;
              this.paramContainer.blContainerShortShipmentVesselName =
                dt.vesselName;
              this.paramContainer.blContainerShortShipmentVesselVoyage =
                dt.vesselVoyage;
              this.paramContainer.blContainerShortShipmentVesselBound =
                dt.vesselBound;

              this.genericService
                .GET(
                  this.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/distinctVoyageWithEtd/" +
                    this.paramContainer.blContainerShortShipmentVesselId +
                    "/" +
                    this.paramContainer.blContainerShortShipmentVesselBound.toUpperCase() +
                    "/" +
                    this.paramContainer.blContainerShortShipmentVesselVoyage +
                    "/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.cookieService.getDefaultLocationCode() +
                    "/" +
                    this.model.blOceanVesselId +
                    "/" +
                    this.model.blOceanVesselVoyage +
                    "/" +
                    this.model.blOceanVesselBound
                )
                .subscribe((resp2) => {
                  const dt2 = resp2.json();
                  if (dt2.content.length > 0) {
                    this.frmDialogMessage = "Overwrite Details?";
                    this.genericUtil.showDialog(
                      "blDialogShortShipmentOverwrite",
                      "Confirm",
                      350,
                      180
                    );
                  } else {
                    this.frmDialogMessage = "Invalid Etd.";
                    this.genericUtil.showDialog(
                      "blDialogShortshipEtd",
                      "Information",
                      350,
                      180
                    );
                  }
                });
            } else {
              this.paramContainer.blContainerShortShipmentBlRef = "";
              this.paramContainer.blContainerShortShipmentVesselId = "";
              this.paramContainer.blContainerShortShipmentVesselCode = "";
              this.paramContainer.blContainerShortShipmentVesselName = "";
              this.paramContainer.blContainerShortShipmentVesselVoyage = "";
              this.paramContainer.blContainerShortShipmentVesselBound = "";
              this.cbBlContainerShortShipmentVessel.setForceValue("");
              this.cbBlContainerShortShipmentVoyage.setForceValue("");

              // set freight covered information
              this.model.blFreightCoveredInBlNo = "";
            }
          });
      }
    }

    /**
     this.genericService.GET(this.configService.config.BASE_API.toString() +
     '/MasterBookings/checkShortShipmentBooking/' + this.cookieService.getDefaultLocationCode() + '/' +
     this.paramContainer.blContainerShortShipmentBlRef.toUpperCase().trim()).subscribe( (resp) => {
        const dt = resp.json();
        if (dt.status === 'OK' ) {
          this.frmDialogMessage = 'Pending Message'
          this.genericUtil.showDialog('blDialogShortShipmentOverwrite', 'Confirm', 350, 180);
        }
      });
     **/
    // }
  }

  onBlDialogShortshipmentOverwriteYes() {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkShortShipmentBooking/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          this.paramContainer.blContainerShortShipmentBlRef.toUpperCase().trim()
      )
      .subscribe((resp) => {
        const dt = resp.json();
        if (dt.status === "OK") {
          this.paramContainer.blContainerShortShipmentVesselId = dt.vesselId;
          this.paramContainer.blContainerShortShipmentVesselCode =
            dt.vesselCode;
          this.paramContainer.blContainerShortShipmentVesselName =
            dt.vesselName;
          this.paramContainer.blContainerShortShipmentVesselVoyage =
            dt.vesselVoyage;
          this.paramContainer.blContainerShortShipmentVesselBound =
            dt.vesselBound;
          this.cbBlContainerShortShipmentVessel.setForceValue(
            this.paramContainer.blContainerShortShipmentVesselName
          );
          this.cbBlContainerShortShipmentVoyage.setForceValue(
            this.paramContainer.blContainerShortShipmentVesselVoyage
          );
        } else {
          this.paramContainer.blContainerShortShipmentBlRef = "";
          this.paramContainer.blContainerShortShipmentVesselId = "";
          this.paramContainer.blContainerShortShipmentVesselCode = "";
          this.paramContainer.blContainerShortShipmentVesselName = "";
          this.paramContainer.blContainerShortShipmentVesselVoyage = "";
          this.paramContainer.blContainerShortShipmentVesselBound = "";
          this.cbBlContainerShortShipmentVessel.setForceValue("");
          this.cbBlContainerShortShipmentVoyage.setForceValue("");
        }
      });
    this.genericUtil.closeDialog("blDialogShortShipmentOverwrite");
  }

  onBlDialogShortshipmentOverwriteNo() {
    this.genericUtil.closeDialog("blDialogShortShipmentOverwrite");
  }

  onBlDialogShortshipEtdOk() {
    this.paramContainer.blContainerShortShipmentBlRef = "";
    this.paramContainer.blContainerShortShipmentVesselId = "";
    this.paramContainer.blContainerShortShipmentVesselCode = "";
    this.paramContainer.blContainerShortShipmentVesselName = "";
    this.paramContainer.blContainerShortShipmentVesselVoyage = "";
    this.paramContainer.blContainerShortShipmentVesselBound = "";
    this.cbBlContainerShortShipmentVessel.setForceValue("");
    this.cbBlContainerShortShipmentVoyage.setForceValue("");
    this.genericUtil.closeDialog("blDialogShortshipEtd");
  }

  onBlDialogSaveOk() {
    this.genericUtil.closeDialog("blDialogSave");
  }

  onBlDialogClickRefreightDialogOk() {
    console.log("$$$ after refreight $$$");
    console.log(this.model);
    this.isRefreightProcess = false;
    this.genericUtil.closeDialog("clickRefreightDialog");
  }

  doUnlockByUser(officeCode: String, user: String) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/setUnlockBLByUser/" +
          officeCode +
          "/" +
          user
      )
      .subscribe((resp) => {});
  }

  doUnlock(officeCode: String, blNo: String, user: String) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/setUnlockBL/" +
          officeCode +
          "/" +
          blNo +
          "/" +
          user
      )
      .subscribe((resp) => {
        this.isOpenedByOtherUser = false;
        this.genericUtil.hideLoader();
      });
  }

  doLock(officeCode: String, blNo: String, user: String) {
    console.log("doLock : " + officeCode + " - " + blNo + " - " + user);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/BL/setLockBL/" +
          officeCode +
          "/" +
          blNo +
          "/" +
          user
      )
      .subscribe((resp) => {
        console.log(resp);
      });
  }

  onRefreightFinal() {
    console.log("** onRefreightFinal **");
    console.log(this.gridBlNettCharge.listStore.store);
    this.isRefreightProcess = true;
    this.isRefreightInvokedFromFinal = true;
    this.isRefreightButtonClicked = true;

    this.tmpDateOfIssue = this.dateBlNettChargeDateOfIssue.getValue();
    this.tmpExchangeRateDate = this.dateBlNettChargeExchangeRate.getValue(); // what the fish

    let checker: Boolean = false;
    if (this.model.blIsSplit !== "Y") {
      checker = true;
    } else {
      if (this.model.blSplitMethodType.toUpperCase() === "PRORATE") {
        checker = true;
      } else if (this.model.blSplitMethodType.toUpperCase() === "FREIGHT") {
        if (this.model.blSplitKey === "") {
          checker = true;
        } else {
          checker = true;
        }
      } else {
        checker = false;
      }
    }

    console.log("BL Model Type : ", this.model.blType);

    // this.onBlNettChargeRefreightNew();
    console.log(
      "Check split method = " +
        this.model.blSplitMethodType +
        " " +
        this.model.blSplitKey
    );
    if (this.model.blType !== "SW" && this.model.blType !== "OIS" && checker) {
      let hitRefreightURL = "";
      if (this.model.blIsCombine !== "Y") {
        hitRefreightURL =
          this.configService.config.BASE_API.toString() +
          "/BL/runRefreightByOfficeCodeBlNo/" +
          this.model.blOfficeCode +
          "/" +
          this.model.blNo;
      } else {
        hitRefreightURL =
          this.configService.config.BASE_API.toString() +
          "/BL/runRefreightByOfficeCodeBlNoCombineBL/" +
          this.model.blOfficeCode +
          "/" +
          this.model.blNo;
      }

      //GSO-996

      if (this.model['blContainerOwnership'] == "COCTS" && this.model.blType == "MEMO" && this.model.blThruBLRef) {
        console.log(this.model.blOfficeCode, this.model.thruOfficeCode, this.model.blNo, this.model.blThruBLRef);
        hitRefreightURL =
          this.configService.config.BASE_API.toString() +
          `/BL/refreightTsBookingPOT/${this.model.blOfficeCode}/${this.model.thruOfficeCode}/${this.model.blNo}/${this.model.blThruBLRef}`;
      }

      this.genericUtil.showLoader();
      this.genericService.GET(hitRefreightURL).subscribe((resp) => {
        let dt = resp.json();

        if (
          this.model.blIsSplit === "Y" &&
          this.model.blSplitMethodType.toUpperCase() === "FREIGHT" &&
          this.model.blSplitKey !== ""
        ) {
          dt.chargeData = [];
        }

        const dt2 = resp.json();
        if (dt["status"] === "OK") {
          console.log("refreight triggered");
          console.log(resp.json());
          console.log(resp.json()["chargeData"]);

          //this.txtBlNettChargeRemarks.setValue(this.model.blRemarks + ((dt.pfcPrincipalRemarks == null) ? '' : dt.pfcPrincipalRemarks));
          this.txtBlNettChargeRemarks.setValue(
            dt.pfcPrincipalRemarks == null ? "" : dt.pfcPrincipalRemarks
          );

          // store the old pop and payer
          let p1 = this.model.blPrepaidAt1Code;
          let p2 = this.model.blPrepaidAt2Code;
          let p3 = this.model.blPrepaidAt3Code;
          let p1Name;
          let p2Name;
          let p3Name;

          let c1 = this.model.blPayableAt1Code;
          let c2 = this.model.blPayableAt2Code;
          let c3 = this.model.blPayableAt3Code;
          let c1Name;
          let c2Name;
          let c3Name;

          let py1 = this.model.blPayer1Code;
          let py2 = this.model.blPayer2Code;
          let py3 = this.model.blPayer3Code;
          let py1Name;
          let py2Name;
          let py3Name;

          let adHocP1Code = "";
          let adHocP2Code = "";
          let adHocP3Code = "";

          let adHocC1Code = "";
          let adHocC2Code = "";
          let adHocC3Code = "";

          let adHocPy1Code = "";
          let adHocPy2Code = "";
          let adHocPy3Code = "";

          const prepaidList: Array<string> = [];
          const collectList: Array<string> = [];
          const payerListUnsorted: Array<string> = [];
          const payerList: Array<string> = [];
          const payerListName = [];
          const prepaidListName = [];
          const collectListName = [];

          const adHocPrepaidList: Array<string> = [];
          const adHocCollectList: Array<string> = [];
          const adHocPayerList: Array<string> = [];

          //keystore
          let keyStoreGridAdhoc: Array<string> = [];

          // store grid old value before reset
          let oldGridValue: Array<BLFreightCharge> = [];
          let oldGridValueAdhoc: Array<BLFreightCharge> = [];
          if (this.gridBlNettCharge.listStore.store.length > 0) {
            for (
              let i = 0;
              i < this.gridBlNettCharge.listStore.store.length;
              i++
            ) {
              oldGridValue.push(this.gridBlNettCharge.listStore.store[i]);
            }
          }
          console.log("Old grid value => debug");
          console.log(oldGridValue);

          // detect what is the pop and payer which used by adhoc charges previously
          for (const gridData of oldGridValue) {
            if (gridData.blFCIsEntered) {
              switch (gridData.blFCPc) {
                case "P1": {
                  adHocP1Code = this.model.blPrepaidAt1Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP1Code;
                  break;
                }
                case "P2": {
                  adHocP2Code = this.model.blPrepaidAt2Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP2Code;
                  break;
                }
                case "P3": {
                  adHocP3Code = this.model.blPrepaidAt3Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP3Code;
                  break;
                }

                case "C1": {
                  adHocC1Code = this.model.blPayableAt1Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC1Code;
                  break;
                }
                case "C2": {
                  adHocC2Code = this.model.blPayableAt2Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC2Code;
                  break;
                }
                case "C3": {
                  adHocC3Code = this.model.blPayableAt3Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC3Code;
                  break;
                }
                default:
                  break;
              }

              switch (gridData.blFCPayer) {
                case "1": {
                  adHocPy1Code = this.model.blPayer1Code;
                  gridData.blFCPayer = adHocPy1Code;
                  break;
                }
                case "2": {
                  adHocPy2Code = this.model.blPayer2Code;
                  gridData.blFCPayer = adHocPy2Code;
                  break;
                }
                case "3": {
                  adHocPy3Code = this.model.blPayer3Code;
                  gridData.blFCPayer = adHocPy3Code;
                  break;
                }
              }

              oldGridValueAdhoc.push(gridData);
            }
          }

          if (dt["chargeData"] != null && dt["chargeData"].length > 0) {
            console.log("nonono")
            // reset the pop and payer
            this.cbBlNettChargePrepaid1.setValue("");
            this.model.blPrepaidAt1Code = "";
            this.model.blPrepaidAt1Name = "";
            this.cbBlNettChargePrepaid2.setValue("");
            this.model.blPrepaidAt2Code = "";
            this.model.blPrepaidAt2Name = "";
            this.cbBlNettChargePrepaid3.setValue("");
            this.model.blPrepaidAt3Code = "";
            this.model.blPrepaidAt3Name = "";
            this.cbBlNettChargePayableAt1.setValue("");
            this.model.blPayableAt1Code = "";
            this.model.blPayableAt1Name = "";
            this.cbBlNettChargePayableAt2.setValue("");
            this.model.blPayableAt2Code = "";
            this.model.blPayableAt2Name = "";
            this.cbBlNettChargePayableAt3.setValue("");
            this.model.blPayableAt3Code = "";
            this.model.blPayableAt3Name = "";
            this.cbBlNettChargeBillingParty1.setValue("");
            this.model.blPayer1Code = "";
            this.model.blPayer1Name = "";

            this.cbBlNettChargeBillingParty2.setValue("");
            this.model.blPayer2Code = "";
            this.model.blPayer2Name = "";
            this.cbBlNettChargeBillingParty3.setValue("");
            this.model.blPayer3Code = "";
            this.model.blPayer3Name = "";

            // reset the grid
            this.gridBlNettCharge.onClear();

            console.log("======= newly added to populate the payer ========");
            for (const cData of resp.json()["chargeData"]) {
              console.log(cData);
              if (cData.blFCPc === "P") {
                if (!prepaidList.includes(cData.blFCPlaceOfPaymentCode)) {
                  prepaidList.push(cData.blFCPlaceOfPaymentCode);
                  prepaidListName.push(cData.blFCPlaceOfPaymentName)
                }
              } else {
                if (!collectList.includes(cData.blFCPlaceOfPaymentCode)) {
                  collectList.push(cData.blFCPlaceOfPaymentCode);
                  collectListName.push(cData.blFCPlaceOfPaymentName);
                }
              }

              if (cData.blFCPayer != "") {
                if (!payerListUnsorted.includes(cData.blFCPayer)) {
                  console.log("### payerList Push debug ###");
                  console.log(cData.blFCPayer);
                  payerListUnsorted.push(cData.blFCPayer);
                  payerListName.push(cData.blFCPayerName);
                }
              }
            }

            if (payerListUnsorted.length > 0) {
              for (const payer of payerListUnsorted) {
                payerList.push(payer);
              }
            }

            // check value after rest
            console.log("---debug prepaid list---");
            console.log(prepaidList);
            console.log(payerList);
            console.log(payerListName);

            console.log("---end of debug prepaid list---");

            console.log("p1 = " + p1 + "   p2 = " + p2 + "   p3 = " + p3);
            console.log("c1 = " + c1 + "   c2 = " + c2 + "   c3 = " + c3);
            console.log("py1 = " + py1 + "   py2 = " + py2 + "   py3 = " + py3);

            // Check again, for each value on p,c, and py against chargedata and adhoc, if now not used must be removed
            let p1Found = false;
            let p2Found = false;
            let p3Found = false;
            let c1Found = false;
            let c2Found = false;
            let c3Found = false;
            let py1Found = false;
            let py2Found = false;
            let py3Found = false;

            for (const test of prepaidList) {
              // check for P1
              if (p1 == null || p1 === undefined || p1 === "") {
                p1 = test;
                continue;
              }
              // check for p2
              if (p2 == null || p2 === undefined || p2 === "") {
                if (test !== p1) {
                  p2 = test;
                  continue;
                } else {
                  continue;
                }
              }
              // check for p3
              if (p3 == null || p3 === undefined || p3 === "") {
                if (test !== p1 && test !== p2) {
                  p3 = test;
                  continue;
                } else {
                  continue;
                }
              }
            }

            for (const test2 of collectList) {
              // check for C1
              if (c1 == null || c1 === undefined || c1 === "") {
                c1 = test2;
                continue;
              }
              // check for C2
              if (c2 == null || c2 === undefined || c2 === "") {
                if (test2 !== c1) {
                  c2 = test2;
                  continue;
                } else {
                  continue;
                }
              }
              // check for C3
              if (c3 == null || c3 === undefined || c3 === "") {
                if (test2 !== c1 && test2 !== c2) {
                  c3 = test2;
                  continue;
                } else {
                  continue;
                }
              }
            }

            for (const test3 of payerList) {
              // check for C1
              if (py1 == null || py1 === undefined || py1 === "") {
                py1 = test3;
                continue;
              }
              // check for C2
              if (py2 == null || py2 === undefined || py2 === "") {
                if (test3 !== py1) {
                  py2 = test3;
                  continue;
                } else {
                  continue;
                }
              }
              // check for C3
              if (py3 == null || py3 === undefined || py3 === "") {
                if (test3 !== py1 && test3 !== py2) {
                  py3 = test3;
                  continue;
                } else {
                  continue;
                }
              }
            }

            // checking P/C
            if (p1 != null && p1 !== "" && p1 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "P" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === p1
                ) {
                  p1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p1) {p1Found = true;} }
              //if (adHocP1Code !== '') { p1Found = true; }
            }
            if (p2 != null && p2 !== "" && p2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "P" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === p2
                ) {
                  p2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p2) {p2Found = true;} }
              //if (adHocP2Code !== '') { p2Found = true; }
            }
            if (p3 != null && p3 !== "" && p3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "P" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === p3
                ) {
                  p3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p3) {p3Found = true;} }
              //if (adHocP3Code !== '') { p3Found = true; }
            }

            // checking C
            if (c1 != null && c1 !== "" && c1 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "C" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === c1
                ) {
                  c1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c1) {c1Found = true;} }
              //if (adHocC1Code !== '') { c1Found = true; }
            }
            if (c2 != null && c2 !== "" && c2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "C" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === c2
                ) {
                  c2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c2) {c2Found = true;} }
              //if (adHocC2Code !== '') { c2Found = true; }
            }
            if (c3 != null && c3 !== "" && c3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (
                  dt.chargeData[i].blFCPc === "C" &&
                  dt.chargeData[i].blFCPlaceOfPaymentCode === c3
                ) {
                  c3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c3) {c3Found = true;} }
              //if (adHocC3Code !== '') { c3Found = true; }
            }

            // checking Py
            if (py1 != null && py1 !== "" && py1 !== undefined) {
              console.log("### debug payer ###");
              console.log("before for -> " + py1Found);
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py1) {
                  py1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py1) {py1Found = true;} }
              console.log("after for -> " + py1Found);
              //  if (adHocPy1Code !== '') { py1Found = true; }
              console.log("after if -> " + py1Found);
            }
            if (py2 != null && py2 !== "" && py2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py2) {
                  py2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py2) {py2Found = true;} }
              //if (adHocPy2Code !== '') { py2Found = true; }
            }
            if (py3 != null && py3 !== "" && py3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py3) {
                  py3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py3) {py3Found = true;} }
              //if (adHocPy3Code !== '') { py3Found = true; }
            }

            // check again
            console.log(
              "p1 = " + p1Found + "   p2 = " + p2Found + "   p3 = " + p3Found
            );
            console.log(
              "c1 = " + c1Found + "   c2 = " + c2Found + "   c3 = " + c3Found
            );
            console.log(
              "py1 = " +
                py1Found +
                "   py2 = " +
                py2Found +
                "   py3 = " +
                py3Found
            );
            //Restore Prepaid
            if (prepaidList.length === 1) {
              p1 = prepaidList[0];
              p1Name = prepaidListName[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.model.blPrepaidAt1Name = p1Name;
              // this.cbBlNettChargePrepaid1.setValue(p1);
              this.cbBlNettChargePrepaid1.setForceValue(p1Name);
            } else if (prepaidList.length === 2) {
              p1 = prepaidList[0];
              p1Name = prepaidListName[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePrepaid1.setValue(p1);
              this.model.blPrepaidAt1Name = p1Name;
              this.cbBlNettChargePrepaid1.setForceValue(p1Name);
              p2 = prepaidList[1];
              p2Name = prepaidListName[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePrepaid2.setValue(p2);
              this.model.blPrepaidAt2Name = p2Name;
              this.cbBlNettChargePrepaid2.setForceValue(p2Name);
            } else if (prepaidList.length >= 3) {
              p1 = prepaidList[0];
              p1Name = prepaidListName[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePrepaid1.setValue(p1);
              this.model.blPrepaidAt1Name = p1Name;
              this.cbBlNettChargePrepaid1.setForceValue(p1Name);

              p2 = prepaidList[1];
              p2Name = prepaidListName[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePrepaid2.setValue(p2);
              this.model.blPrepaidAt2Name = p2Name;
              this.cbBlNettChargePrepaid2.setForceValue(p2Name);

              p3 = prepaidList[2];
              p3Name = prepaidListName[2];
              this.model.blPrepaidAt3Code = p3;
              this.cbBlNettChargePrepaid3.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePrepaid3.setValue(p3);
              this.model.blPrepaidAt3Name = p3Name;
              this.cbBlNettChargePrepaid3.setForceValue(p3Name);
            }

            //Restore Collect
            if (collectList.length === 1) {
              c1 = collectList[0];
              c1Name = collectListName[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt1.setValue(c1);
              this.model.blPayableAt1Name = c1Name;
              this.cbBlNettChargePayableAt1.setForceValue(c1Name);
            } else if (collectList.length === 2) {
              c1 = collectList[0];
              c1Name = collectListName[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt1.setValue(c1);
              this.model.blPayableAt1Name = c1Name;
              this.cbBlNettChargePayableAt1.setForceValue(c1Name);

              c2 = collectList[1];
              c2Name = collectListName[1]
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt2.setValue(c2);
              this.model.blPayableAt2Name = c2Name;
              this.cbBlNettChargePayableAt2.setForceValue(c2Name);
            } else if (collectList.length >= 3) {
              c1 = collectList[0];
              c1Name = collectListName[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt1.setValue(c1);
              this.model.blPayableAt1Name = c1Name;
              this.cbBlNettChargePayableAt1.setForceValue(c1Name);

              c2 = collectList[1];
              c2Name = collectListName[1]
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt2.setValue(c2);
              this.model.blPayableAt2Name = c2Name;
              this.cbBlNettChargePayableAt2.setForceValue(c2Name);

              c3 = collectList[2];
              c3Name = collectListName[2]
              this.model.blPayableAt3Code = c3;
              this.cbBlNettChargePayableAt3.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              // this.cbBlNettChargePayableAt3.setValue(c3);
              this.model.blPayableAt3Name = c3Name;
              this.cbBlNettChargePayableAt3.setForceValue(c3Name);
            }

            //GOS-121 From Refreight
            const ini = this;
            if (payerList.length === 1) {
              this.cbBlNettChargeBillingParty1Address.setValue("");
              this.cbBlNettChargeBillingParty1Address.clearSelect();
              py1 = payerList[0];
              py1Name = payerListName[0];
              this.model.blPayer1Code = py1;

              var uriString = "";
              if (py1 == null || py1 == "" ||py1 == undefined) {
                uriString = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py1)) {
                  this.listPyrFromRefreight.push(py1);
                }

                uriString = this.configService.config.BASE_API.toString() +
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer1+
                  this.sCharUtil.htmlEncode("|")+
                  py1+
                  "/customerId={query}";
              }

              this.cbBlNettChargeBillingParty1.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty1.setUrl(uriString);
              // this.cbBlNettChargeBillingParty1.setValue(py1);
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setForceValue(py1Name);
              this.setPayerAddress1(py1, py1Name);

              this.cbBlNettChargeBillingParty2Address.setValue("");
              this.cbBlNettChargeBillingParty2Address.clearSelect();
              this.cbBlNettChargeBillingParty3Address.setValue("");
              this.cbBlNettChargeBillingParty3Address.clearSelect();

              console.log("kadieu :" + ini.model.blBookingNo, py1 );

            } else if (payerList.length === 2) {
              this.cbBlNettChargeBillingParty1Address.setValue("");
              this.cbBlNettChargeBillingParty1Address.clearSelect();
              this.cbBlNettChargeBillingParty2Address.setValue("");
              this.cbBlNettChargeBillingParty2Address.clearSelect();
              py1 = payerList[0];
              py1Name = payerListName[0];
              var uriString = "";
              if (py1 == null || py1 == "" ||py1 == undefined) {
                uriString = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py1)) {
                  this.listPyrFromRefreight.push(py1);
                }

                uriString = this.configService.config.BASE_API.toString() +
                "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer1+
                  this.sCharUtil.htmlEncode("|")+
                  py1+
                  "/customerId={query}";
              }

              this.cbBlNettChargeBillingParty1.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty1.setUrl(uriString);
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setForceValue(py1Name);
              // this.cbBlNettChargeBillingParty1.setValue(py1);
              this.setPayerAddress1(py1, py1Name);



              py2 = payerList[1];
              py2Name = payerListName[1];
              this.model.blPayer2Code = py2;
              var uriString2 = "";
              if (py2 == null || py2 == "" ||py2 == undefined) {
                uriString2 = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py2)) {
                  this.listPyrFromRefreight.push(py2);
                }

                uriString2 = this.configService.config.BASE_API.toString() +
                "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer2+
                  this.sCharUtil.htmlEncode("|")+
                  py2+
                  "/customerId={query}";
              }

              // this.cbBlNettChargeBillingParty2.setUrl(
              //   this.configService.config.BASE_API.toString() +
              //     // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
              //     "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
              // );
              this.cbBlNettChargeBillingParty2.setUrl(uriString2);
              // this.cbBlNettChargeBillingParty2.setValue(py2);
              this.cbBlNettChargeBillingParty2.setForceValue(py2Name);
              this.setPayerAddress2(py2, py2Name);

              this.cbBlNettChargeBillingParty3Address.setValue("");
              this.cbBlNettChargeBillingParty3Address.clearSelect();

            } else if (payerList.length >= 3) {
              this.cbBlNettChargeBillingParty1Address.setValue("");
              this.cbBlNettChargeBillingParty1Address.clearSelect();
              this.cbBlNettChargeBillingParty2Address.setValue("");
              this.cbBlNettChargeBillingParty2Address.clearSelect();
              this.cbBlNettChargeBillingParty3Address.setValue("");
              this.cbBlNettChargeBillingParty3Address.clearSelect();
              py1 = payerList[0];
              py1Name = payerListName[0];
              var uriString = "";
              if (py1 == null || py1 == "" ||py1 == undefined) {
                uriString = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py1)) {
                  this.listPyrFromRefreight.push(py1);
                }

                uriString = this.configService.config.BASE_API.toString() +
                "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer1+
                  this.sCharUtil.htmlEncode("|")+
                  py1+
                  "/customerId={query}";
              }

              // this.cbBlNettChargeBillingParty1.setUrl(
              //   this.configService.config.BASE_API.toString() +
              //     // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
              //     "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              // );
              this.cbBlNettChargeBillingParty1.setUrl(uriString);
              // this.cbBlNettChargeBillingParty1.setValue(py1);
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setForceValue(py1Name);
              this.setPayerAddress1(py1, py1Name);



              py2 = payerList[1];
              py2Name = payerListName[1];

              var uriString2 = "";
              if (py2 == null || py2 == "" ||py2 == undefined) {
                uriString2 = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py2)) {
                  this.listPyrFromRefreight.push(py2);
                }
                uriString2 = this.configService.config.BASE_API.toString() +
                "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer2+
                  this.sCharUtil.htmlEncode("|")+
                  py2+
                  "/customerId={query}";
              }

              // this.cbBlNettChargeBillingParty2.setUrl(
              //   this.configService.config.BASE_API.toString() +
              //     // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
              //     "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
              // );
              // this.cbBlNettChargeBillingParty2.setUrl(uriString2);
              // this.cbBlNettChargeBillingParty2.setValue(py2);
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setForceValue(py2Name);
              this.setPayerAddress2(py2, py2Name);

              py3 = payerList[2];
              py3Name = payerListName[2];

              var uriString3 = "";
              if (py3 == null || py3 == "" ||py3 == undefined) {
                uriString3 = this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}";
              } else {
                if (!this.listPyrFromRefreight.includes(py3)) {
                  this.listPyrFromRefreight.push(py3);
                }
                uriString3 = this.configService.config.BASE_API.toString() +
                "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+
                  this.custIdForCbPayer3+
                  this.sCharUtil.htmlEncode("|")+
                  py3+
                  "/customerId={query}";
              }
              // this.cbBlNettChargeBillingParty3.setUrl(
              //   this.configService.config.BASE_API.toString() +
              //     // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
              //     "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}"
              // );

              this.cbBlNettChargeBillingParty3.setUrl(uriString3);
              // this.cbBlNettChargeBillingParty3.setValue(py3);
              this.model.blPayer3Code = py3;
              this.cbBlNettChargeBillingParty3.setForceValue(py3Name);
              this.setPayerAddress3(py3, py3Name)
            }

            console.log("$$$ check model $$$");
            console.log(this.model);

            // AdHoc Restore
            // After all prepaid, collect, and payer restored from the PFC, we try to check which slot still vacant to push adhoc
            //adhoc prepaid
            const self = this;
            setTimeout(function () {
              console.log("---before debug adhoc---");
              console.log("adHocP1Code = " + adHocP1Code);
              console.log(
                "self.model.blPrepaidAt1Code = " + self.model.blPrepaidAt1Code
              );
              console.log(
                "self.model.blPrepaidAt2Code = " + self.model.blPrepaidAt2Code
              );
              console.log(
                "self.model.blPrepaidAt3Code = " + self.model.blPrepaidAt3Code
              );

              if (adHocP1Code !== "") {
                if (
                  adHocP1Code !== self.model.blPrepaidAt1Code &&
                  adHocP1Code !== self.model.blPrepaidAt2Code &&
                  adHocP1Code !== self.model.blPrepaidAt3Code
                ) {
                  adHocPrepaidList.push(adHocP1Code);
                }
              }
              if (adHocP2Code !== "") {
                if (
                  adHocP2Code !== self.model.blPrepaidAt1Code &&
                  adHocP2Code !== self.model.blPrepaidAt2Code &&
                  adHocP2Code !== self.model.blPrepaidAt3Code
                ) {
                  adHocPrepaidList.push(adHocP2Code);
                }
              }
              if (adHocP3Code !== "") {
                if (
                  adHocP3Code !== self.model.blPrepaidAt1Code &&
                  adHocP3Code !== self.model.blPrepaidAt2Code &&
                  adHocP3Code !== self.model.blPrepaidAt3Code
                ) {
                  adHocPrepaidList.push(adHocP3Code);
                }
              }

              //adhoc collect
              if (adHocC1Code !== "") {
                if (
                  adHocC1Code !== self.model.blPayableAt1Code &&
                  adHocC1Code !== self.model.blPayableAt2Code &&
                  adHocC1Code !== self.model.blPayableAt3Code
                ) {
                  adHocCollectList.push(adHocC1Code);
                }
              }
              if (adHocC2Code !== "") {
                if (
                  adHocC2Code !== self.model.blPayableAt1Code &&
                  adHocC2Code !== self.model.blPayableAt2Code &&
                  adHocC2Code !== self.model.blPayableAt3Code
                ) {
                  adHocCollectList.push(adHocC2Code);
                }
              }
              if (adHocC3Code !== "") {
                if (
                  adHocC3Code !== self.model.blPayableAt1Code &&
                  adHocC3Code !== self.model.blPayableAt2Code &&
                  adHocC3Code !== self.model.blPayableAt3Code
                ) {
                  adHocCollectList.push(adHocC3Code);
                }
              }

              // adhoc payer
              if (adHocPy1Code !== "") {
                if (
                  adHocPy1Code !== self.model.blPayer1Code &&
                  adHocPy1Code !== self.model.blPayer2Code &&
                  adHocPy1Code !== self.model.blPayer3Code
                ) {
                  adHocPayerList.push(adHocPy1Code);
                }
              }
              if (adHocPy2Code !== "") {
                if (
                  adHocPy2Code !== self.model.blPayer1Code &&
                  adHocPy2Code !== self.model.blPayer2Code &&
                  adHocPy2Code !== self.model.blPayer3Code
                ) {
                  adHocPayerList.push(adHocPy2Code);
                }
              }
              if (adHocPy3Code !== "") {
                if (
                  adHocPy3Code !== self.model.blPayer1Code &&
                  adHocPy3Code !== self.model.blPayer2Code &&
                  adHocPy3Code !== self.model.blPayer3Code
                ) {
                  adHocPayerList.push(adHocPy3Code);
                }
              }

              console.log("----debug adhoc---");
              console.log(adHocPrepaidList);
              console.log(adHocCollectList);
              console.log(adHocPayerList);

              //try to restore prepaid
              if (adHocPrepaidList.length > 0) {
                for (const adHocPrepaid of adHocPrepaidList) {
                  if (adHocPrepaid !== "") {
                    console.log("adhocPrepaid => " + adHocPrepaid);
                    console.log("prepaid1 => " + self.model.blPrepaidAt1Code);
                    console.log("prepaid2 => " + self.model.blPrepaidAt2Code);
                    console.log("prepaid3 => " + self.model.blPrepaidAt3Code);

                    if (self.model.blPrepaidAt1Code === "") {
                      self.model.blPrepaidAt1Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid1.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePrepaid1.setValue(adHocPrepaid);
                      continue;
                    } else if (self.model.blPrepaidAt2Code === "") {
                      self.model.blPrepaidAt2Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid2.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePrepaid2.setValue(adHocPrepaid);
                      continue;
                    } else if (self.model.blPrepaidAt3Code === "") {
                      self.model.blPrepaidAt3Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid3.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePrepaid3.setValue(adHocPrepaid);
                      continue;
                    }
                  }
                }
              }

              if (adHocCollectList.length > 0) {
                for (const adHocCollect of adHocCollectList) {
                  if (adHocCollect !== "") {
                    if (self.model.blPayableAt1Code === "") {
                      self.model.blPayableAt1Code = adHocCollect;
                      self.cbBlNettChargePayableAt1.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePayableAt1.setValue(adHocCollect);
                      continue;
                    } else if (self.model.blPayableAt2Code === "") {
                      self.model.blPayableAt2Code = adHocCollect;
                      self.cbBlNettChargePayableAt2.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePayableAt2.setValue(adHocCollect);
                      continue;
                    } else if (self.model.blPayableAt3Code === "") {
                      self.model.blPayableAt3Code = adHocCollect;
                      self.cbBlNettChargePayableAt3.setUrl(
                        self.configService.config.BASE_API.toString() +
                          "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                      );
                      self.cbBlNettChargePayableAt3.setValue(adHocCollect);
                      continue;
                    }
                  }
                }
              }

              if (adHocPayerList.length > 0) {
                console.log("MASUK ADHOC NETI");
                for (const adHocPayer of adHocPayerList) {
                  if (adHocPayer !== "") {
                    if (self.model.blPayer1Code === "") {
                      self.model.blPayer1Code = adHocPayer;
                      self.cbBlNettChargeBillingParty1.setUrl(
                        self.configService.config.BASE_API.toString() +
                          // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                          "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
                      );
                      self.cbBlNettChargeBillingParty1.setValue(adHocPayer);
                      continue;
                    } else if (self.model.blPayer2Code === "") {
                      // if(payerList.length > 2){
                      //   self.model.blPayer2Code = adHocPayer;
                      //   self.cbBlNettChargeBillingParty2.setUrl(
                      //     self.configService.config.BASE_API.toString() +
                      //       // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                      //       "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
                      //   );
                      //   self.cbBlNettChargeBillingParty2.setValue(adHocPayer);
                      // }
                      self.model.blPayer2Code = adHocPayer;
                      self.cbBlNettChargeBillingParty2.setUrl(
                        self.configService.config.BASE_API.toString() +
                          // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                          "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
                      );
                      self.cbBlNettChargeBillingParty2.setValue(adHocPayer);
                      continue;
                    } else if (self.model.blPayer3Code === "") {
                      self.model.blPayer3Code = adHocPayer;
                      self.cbBlNettChargeBillingParty3.setUrl(
                        self.configService.config.BASE_API.toString() +
                          // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                          "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}"
                      );
                      self.cbBlNettChargeBillingParty3.setValue(adHocPayer);
                      continue;
                    }
                  }
                }
              }

              let fcSeqno = 1;
              for (let cData of resp.json()["chargeData"]) {
                console.log(cData);
                let blfc = new BLFreightCharge();
                blfc.blFCOfficeCode = cData.blFCOfficeCode;
                blfc.blFCBlNo = cData.blFCBlNo;
                blfc.blFCSeq = fcSeqno;
                blfc.blFCChargeCode = cData.blFCChargeCode;
                blfc.blFCRevenue = cData.blFCRevenue;
                blfc.blFCRate = cData.blFCRate;
                blfc.blFCPerCode = cData.blFCPerCode;
                blfc.blFCCurrencyCode = cData.blFCCurrencyCode;
                blfc.blFCAmount = cData.blFCAmount;
                blfc.blFCPc = cData.blFCPc;
                blfc.blFCPayerCode = cData.blFCPayerCode;
                blfc.blFCPayer = cData.blFCPayer;
                blfc.blFCPlaceOfPaymentCode = cData.blFCPlaceOfPaymentCode;
                blfc.blFCPlaceOfPaymentName = cData.blFCPlaceOfPaymentName;
                blfc.blFCContainerType = cData.blFCContainerType;
                blfc.blFCIsEntered = cData.blFCIsEntered;
                blfc.blFCIsEnteredString = cData.blFCIsEnteredString;
                blfc.blFCIsChargePrinted = cData.blFCIsChargePrinted;
                blfc.blFCIsChargePrintedString =
                  cData.blFCIsChargePrintedString;
                blfc.blFCIsManifestedFreight = cData.blFCIsManifestedFreight;
                blfc.blFCIsManifestedFreightString =
                  cData.blFCIsManifestedFreight;
                blfc.blFCFaiGroup = cData.blFCFaiGroup;
                blfc.blFCFaiGroupString = cData.blFCFaiGroupString;
                blfc.blFCFreightReference = cData.blFCFreightReference;
                blfc.blFCCreatedUserid = cData.blFCCreatedUserid;
                blfc.blFCCreatedDate = cData.blFCCreatedDate;
                blfc.blFCModifiedUserid = cData.blFCModifiedUserid;
                blfc.blFCModifiedDate = cData.blFCModifiedDate;

                blfc.blFCIsChargePrinted =
                  cData.blFCIsChargePrinted === "Y" ? true : false;
                blfc.blFCIsChargePrintedString =
                  blfc.blFCIsChargePrinted === true ? "Y" : "N";

                blfc.blFCIsManifestedFreight =
                  cData.blFCIsManifestedFreight === "Y" ? true : false;
                blfc.blFCIsManifestedFreightString =
                  blfc.blFCIsManifestedFreight === true ? "Y" : "N";

                if (blfc.blFCPc === "P") {
                  switch (blfc.blFCPlaceOfPaymentCode) {
                    case p1: {
                      blfc.blFCPc = "P1";
                      break;
                    }
                    case p2: {
                      blfc.blFCPc = "P2";
                      break;
                    }
                    case p3: {
                      blfc.blFCPc = "P3";
                      break;
                    }
                    default: {
                      blfc.blFCPc = "";
                      blfc.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }
                if (blfc.blFCPc === "C") {
                  switch (blfc.blFCPlaceOfPaymentCode) {
                    case c1: {
                      blfc.blFCPc = "C1";
                      break;
                    }
                    case c2: {
                      blfc.blFCPc = "C2";
                      break;
                    }
                    case c3: {
                      blfc.blFCPc = "C3";
                      break;
                    }
                    default: {
                      blfc.blFCPc = "";
                      blfc.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }
                //setter Payer
                if (blfc.blFCPayer !== "") {
                  switch (blfc.blFCPayer) {
                    case py1: {
                      blfc.blFCPayer = "1";
                      break;
                    }
                    case py2: {
                      blfc.blFCPayer = "2";
                      break;
                    }
                    case py3: {
                      blfc.blFCPayer = "3";
                      break;
                    }
                    default: {
                      blfc.blFCPayer = "";
                      break;
                    }
                  }
                }

                let keyy = blfc.blFCChargeCode+blfc.blFCPerCode+blfc.blFCPc+blfc.blFCFreightReference+blfc.blFCRevenue+blfc.blFCRate;
                keyStoreGridAdhoc.push(keyy);


                let iter: number = 1;
                for (const entry of oldGridValue) {
                  // check for charge code, and percode
                  if (!entry.blFCIsEntered) {
                    if (
                      blfc.blFCChargeCode === entry.blFCChargeCode &&
                      blfc.blFCPerCode === entry.blFCPerCode &&
                      blfc.blFCPc === entry.blFCPc &&
                      blfc.blFCFreightReference === entry.blFCFreightReference
                    ) {
                      blfc.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                      blfc.blFCIsChargePrintedString =
                        entry.blFCIsChargePrintedString;
                      blfc.blFCIsManifestedFreight =
                        entry.blFCIsManifestedFreight;
                      blfc.blFCIsManifestedFreightString =
                        entry.blFCIsManifestedFreightString;
                      //cData.blFCFaiGroup = entry.blFCFaiGroup;
                      //cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                      // cData.blFCPayer = entry.blFCPayer;
                    }
                  }
                  iter++;
                }

                console.log("=============");
                console.log(blfc);

                self.gridBlNettCharge.listStore.store.push(blfc);
                fcSeqno++;
              }

              //remove duplicate data
              //console.log(keyStoreGridAdhoc);
              let oldGridValueAdhocTmp: Array<BLFreightCharge> = [];
              for (const dataStore of oldGridValueAdhoc) {
                let keyy = dataStore.blFCChargeCode+dataStore.blFCPerCode+dataStore.blFCPc+dataStore.blFCFreightReference+dataStore.blFCRevenue+dataStore.blFCRate;
                //console.log(keyy + " - " + keyStoreGridAdhoc.indexOf(keyy))
                if(keyStoreGridAdhoc.indexOf(keyy) < 0){
                  oldGridValueAdhocTmp.push(dataStore);
                }
              }
              //console.log(oldGridValueAdhocTmp);
              oldGridValueAdhoc = oldGridValueAdhocTmp;

              // revert the adhoc value
              console.log('old value griddos');
              console.log(oldGridValueAdhoc);
              for (const entry of oldGridValueAdhoc) {
                entry.blFCSeq = fcSeqno;
                if (entry.blFCPlaceOfPaymentCode !== "") {
                  switch (entry.blFCPlaceOfPaymentCode) {
                    case self.model.blPrepaidAt1Code: {
                      entry.blFCPc = "P1";
                      break;
                    }
                    case self.model.blPrepaidAt2Code: {
                      entry.blFCPc = "P2";
                      break;
                    }
                    case self.model.blPrepaidAt3Code: {
                      entry.blFCPc = "P3";
                      break;
                    }
                    case self.model.blPayableAt1Code: {
                      entry.blFCPc = "C1";
                      break;
                    }
                    case self.model.blPayableAt2Code: {
                      entry.blFCPc = "C2";
                      break;
                    }
                    case self.model.blPayableAt3Code: {
                      entry.blFCPc = "C3";
                      break;
                    }
                    default: {
                      entry.blFCPc = "";
                      entry.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }

                //setter Payer
                if (entry.blFCPayer !== "") {
                  switch (entry.blFCPayer) {
                    case self.model.blPayer1Code: {
                      entry.blFCPayer = "1";
                      break;
                    }
                    case self.model.blPayer2Code: {
                      entry.blFCPayer = "2";
                      break;
                    }
                    case self.model.blPayer3Code: {
                      entry.blFCPayer = "3";
                      break;
                    }
                    default: {
                      entry.blFCPayer = "";
                      break;
                    }
                  }
                }

                self.gridBlNettCharge.listStore.store.push(entry);
                fcSeqno++;
              }

              self.gridBlNettCharge.loadData();
            }, 500);
          } else {
            // When refreight result is blank

            console.log("When no charge available : ");

            console.log("checking adHoc Data");
            var preped1code = '';
            var preped2code = '';
            var preped3code = '';
            var pyr1code = '';
            var pyr2code = '';
            var pyr3code = '';

            preped1code = this.model.blPrepaidAt1Code;
            preped2code = this.model.blPrepaidAt2Code;
            preped3code = this.model.blPrepaidAt3Code;

            pyr1code = this.model.blPayer1Code;
            pyr2code = this.model.blPayer2Code;
            pyr3code = this.model.blPayer3Code;

            console.log(this.model.blPrepaidAt1Code);
            console.log(this.model.blPayer1Code);
            let allDataAdhoc = true;
            for (const gridData of oldGridValue) {
              if (!gridData.blFCIsEntered) {
                allDataAdhoc = false;
              }
            }
            console.log("checking ater adhoc " + allDataAdhoc);

            if (!allDataAdhoc) {
              console.log("adhoc ini")
              this.cbBlNettChargePrepaid1.setValue("");
              this.model.blPrepaidAt1Code = "";
              this.model.blPrepaidAt1Name = "";
              this.cbBlNettChargePrepaid2.setValue("");
              this.model.blPrepaidAt2Code = "";
              this.model.blPrepaidAt2Name = "";
              this.cbBlNettChargePrepaid3.setValue("");
              this.model.blPrepaidAt3Code = "";
              this.model.blPrepaidAt3Name = "";
              this.cbBlNettChargePayableAt1.setValue("");
              this.model.blPayableAt1Code = "";
              this.model.blPayableAt1Name = "";
              this.cbBlNettChargePayableAt2.setValue("");
              this.model.blPayableAt2Code = "";
              this.model.blPayableAt2Name = "";
              this.cbBlNettChargePayableAt3.setValue("");
              this.model.blPayableAt3Code = "";
              this.model.blPayableAt3Name = "";
              this.cbBlNettChargeBillingParty1.setValue("");
              this.model.blPayer1Code = "";
              this.model.blPayer1Name = "";
              this.cbBlNettChargeBillingParty2.setValue("");
              this.model.blPayer2Code = "";
              this.model.blPayer2Name = "";
              this.cbBlNettChargeBillingParty3.setValue("");
              this.model.blPayer3Code = "";
              this.model.blPayer3Name = "";
            }
            // reset the grid
            this.gridBlNettCharge.onClear();
            console.log("adHocP1Code = " + adHocP1Code);
            console.log("py1 = " + py1);
            console.log(
              "this.model.blPayer1Code = " + this.model.blPrepaidAt1Code
            );

            console.log("--after yogurt--");
            console.log(adHocPrepaidList);
            console.log(p1 + " - " + this.model.blPrepaidAt1Code);
            console.log(py1 + " - " + this.model.blPayer1Code);

            if (adHocPrepaidList.length === 1) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid1.setValue(p1);
            } else if (adHocPrepaidList.length === 2) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = adHocPrepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid2.setValue(p2);
            } else if (adHocPrepaidList.length >= 3) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = adHocPrepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid2.setValue(p2);

              p3 = adHocPrepaidList[2];
              this.model.blPrepaidAt3Code = p3;
              this.cbBlNettChargePrepaid3.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePrepaid3.setValue(p3);
            }

            //Restore Collect
            if (adHocCollectList.length === 1) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt1.setValue(c1);
            } else if (adHocCollectList.length === 2) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = adHocCollectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt2.setValue(c2);
            } else if (adHocCollectList.length >= 3) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = adHocCollectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt2.setValue(c2);

              c3 = adHocCollectList[2];
              this.model.blPayableAt3Code = c3;
              this.cbBlNettChargePayableAt3.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
              );
              this.cbBlNettChargePayableAt3.setValue(c3);
            }

            if (adHocPayerList.length === 1) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty1.setValue(py1);
            } else if (adHocPayerList.length === 2) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = adHocPayerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty2.setValue(py2);
            } else if (adHocPayerList.length >= 3) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = adHocPayerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty2.setValue(py2);

              py3 = adHocPayerList[2];
              this.model.blPayer3Code = py3;
              this.cbBlNettChargeBillingParty3.setUrl(
                this.configService.config.BASE_API.toString() +
                  // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                  "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}"
              );
              this.cbBlNettChargeBillingParty3.setValue(py3);
            }

            // revert the adhoc value
            console.log('modellll')
            console.log(this.model.blPrepaidAt1Code);
            console.log("DISINI HILANG : "+this.model.blPayer1Code);

            //setting payer address jika payer kosong
            if(this.model.blPayer1Code == ""){
              this.cbBlNettChargeBillingParty1Address.listStore.store = [];
              this.cbBlNettChargeBillingParty1Address.loadSelect();
              this.cbBlNettChargeBillingParty1Address.setValue("");
            }
            if(this.model.blPayer2Code == ""){
              this.cbBlNettChargeBillingParty2Address.listStore.store = [];
              this.cbBlNettChargeBillingParty2Address.loadSelect();
              this.cbBlNettChargeBillingParty2Address.setValue("");
            }
            if(this.model.blPayer3Code == ""){
              this.cbBlNettChargeBillingParty3Address.listStore.store = [];
              this.cbBlNettChargeBillingParty3Address.loadSelect();
              this.cbBlNettChargeBillingParty3Address.setValue("");
            }

            let fcSeqno = 1;
            for (const entry of oldGridValueAdhoc) {
              entry.blFCSeq = fcSeqno;
              if (entry.blFCPlaceOfPaymentCode !== "") {
                switch (entry.blFCPlaceOfPaymentCode) {
                  case this.model.blPrepaidAt1Code: {
                    entry.blFCPc = "P1";
                    break;
                  }
                  case this.model.blPrepaidAt2Code: {
                    entry.blFCPc = "P2";
                    break;
                  }
                  case this.model.blPrepaidAt3Code: {
                    entry.blFCPc = "P3";
                    break;
                  }
                  case this.model.blPayableAt1Code: {
                    entry.blFCPc = "C1";
                    break;
                  }
                  case this.model.blPayableAt2Code: {
                    entry.blFCPc = "C2";
                    break;
                  }
                  case this.model.blPayableAt3Code: {
                    entry.blFCPc = "C3";
                    break;
                  }
                  default: {
                    entry.blFCPc = "";
                    entry.blFCPlaceOfPaymentName = "";
                    break;
                  }
                }
              }

              //setter Payer
              if (entry.blFCPayer !== "") {
                switch (entry.blFCPayer) {
                  case this.model.blPayer1Code: {
                    entry.blFCPayer = "1";
                    break;
                  }
                  case this.model.blPayer2Code: {
                    entry.blFCPayer = "2";
                    break;
                  }
                  case this.model.blPayer3Code: {
                    entry.blFCPayer = "3";
                    break;
                  }
                  default: {
                    entry.blFCPayer = "";
                    break;
                  }
                }
              }

              this.gridBlNettCharge.listStore.store.push(entry);
              fcSeqno++;
            }

            this.gridBlNettCharge.loadData();
          }

          //JIRA GOS 65
          console.log("SGFREIGHT : " + dt["sGrFreightCharge"]);
          console.log("CHARGEDATA : " + dt["chargeData"]);
          console.log("FREIGHT IN MAIN : " + this.model.blSplitMethodType);

          if (this.model.blSplitMethodType != "FREIGHT") {
            if (
              dt["sGrFreightCharge"]["chargeData"] != "" &&
              dt["chargeData"] == ""
            ) {
              console.log("ASOY GEBOY");
              console.log("kesini net :" + dt["chargeData"].size);
              console.log(dt["sGrFreightCharge"]["chargeData"]);

              this.cbBlNettChargePrepaid1.setValue("");
              this.model.blPrepaidAt1Code = "";
              this.model.blPrepaidAt1Name = "";
              this.cbBlNettChargePrepaid2.setValue("");
              this.model.blPrepaidAt2Code = "";
              this.model.blPrepaidAt2Name = "";
              this.cbBlNettChargePrepaid3.setValue("");
              this.model.blPrepaidAt3Code = "";
              this.model.blPrepaidAt3Name = "";
              this.cbBlNettChargePayableAt1.setValue("");
              this.model.blPayableAt1Code = "";
              this.model.blPayableAt1Name = "";
              this.cbBlNettChargePayableAt2.setValue("");
              this.model.blPayableAt2Code = "";
              this.model.blPayableAt2Name = "";
              this.cbBlNettChargePayableAt3.setValue("");
              this.model.blPayableAt3Code = "";
              this.model.blPayableAt3Name = "";
              this.cbBlNettChargeBillingParty1.setValue("");
              this.model.blPayer1Code = "";
              this.model.blPayer1Name = "";
              this.cbBlNettChargeBillingParty2.setValue("");
              this.model.blPayer2Code = "";
              this.model.blPayer2Name = "";
              this.cbBlNettChargeBillingParty3.setValue("");
              this.model.blPayer3Code = "";
              this.model.blPayer3Name = "";

              // reset the grid
              this.gridBlNettCharge.onClear();

              console.log("======= newly added to populate the payer ========");
              for (const cData of dt["sGrFreightCharge"]["chargeData"]) {
                console.log(cData);
                if (cData.blFCPc === "P") {
                  if (!prepaidList.includes(cData.blFCPlaceOfPaymentCode)) {
                    prepaidList.push(cData.blFCPlaceOfPaymentCode);
                  }
                } else {
                  if (!collectList.includes(cData.blFCPlaceOfPaymentCode)) {
                    collectList.push(cData.blFCPlaceOfPaymentCode);
                  }
                }

                if (cData.blFCPayer != "") {
                  if (!payerListUnsorted.includes(cData.blFCPayer)) {
                    console.log("### payerList Push debug ###");
                    console.log(cData.blFCPayer);
                    payerListUnsorted.push(cData.blFCPayer);
                  }
                }
              }

              if (payerListUnsorted.length > 0) {
                for (const payer of payerListUnsorted) {
                  payerList.push(payer);
                }
              }

              // check value after rest
              console.log("---debug prepaid list---");
              console.log(prepaidList);
              console.log(payerList);
              console.log("---end of debug prepaid list---");

              console.log("p1 = " + p1 + "   p2 = " + p2 + "   p3 = " + p3);
              console.log("c1 = " + c1 + "   c2 = " + c2 + "   c3 = " + c3);
              console.log(
                "py1 = " + py1 + "   py2 = " + py2 + "   py3 = " + py3
              );

              // Check again, for each value on p,c, and py against chargedata and adhoc, if now not used must be removed
              let p1Found = false;
              let p2Found = false;
              let p3Found = false;
              let c1Found = false;
              let c2Found = false;
              let c3Found = false;
              let py1Found = false;
              let py2Found = false;
              let py3Found = false;

              for (const test of prepaidList) {
                // check for P1
                if (p1 == null || p1 === undefined || p1 === "") {
                  p1 = test;
                  continue;
                }
                // check for p2
                if (p2 == null || p2 === undefined || p2 === "") {
                  if (test !== p1) {
                    p2 = test;
                    continue;
                  } else {
                    continue;
                  }
                }
                // check for p3
                if (p3 == null || p3 === undefined || p3 === "") {
                  if (test !== p1 && test !== p2) {
                    p3 = test;
                    continue;
                  } else {
                    continue;
                  }
                }
              }

              for (const test2 of collectList) {
                // check for C1
                if (c1 == null || c1 === undefined || c1 === "") {
                  c1 = test2;
                  continue;
                }
                // check for C2
                if (c2 == null || c2 === undefined || c2 === "") {
                  if (test2 !== c1) {
                    c2 = test2;
                    continue;
                  } else {
                    continue;
                  }
                }
                // check for C3
                if (c3 == null || c3 === undefined || c3 === "") {
                  if (test2 !== c1 && test2 !== c2) {
                    c3 = test2;
                    continue;
                  } else {
                    continue;
                  }
                }
              }

              for (const test3 of payerList) {
                // check for C1
                if (py1 == null || py1 === undefined || py1 === "") {
                  py1 = test3;
                  continue;
                }
                // check for C2
                if (py2 == null || py2 === undefined || py2 === "") {
                  if (test3 !== py1) {
                    py2 = test3;
                    continue;
                  } else {
                    continue;
                  }
                }
                // check for C3
                if (py3 == null || py3 === undefined || py3 === "") {
                  if (test3 !== py1 && test3 !== py2) {
                    py3 = test3;
                    continue;
                  } else {
                    continue;
                  }
                }
              }

              // checking P/C
              if (p1 != null && p1 !== "" && p1 !== undefined) {
                for (
                  let i = 0;
                  i < dt["sGrFreightCharge"]["chargeData"].length;
                  i++
                ) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPc === "P" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === p1
                  ) {
                    p1Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p1) {p1Found = true;} }
                //if (adHocP1Code !== '') { p1Found = true; }
              }
              if (p2 != null && p2 !== "" && p2 !== undefined) {
                for (
                  let i = 0;
                  i < dt["sGrFreightCharge"]["chargeData"].length;
                  i++
                ) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPc === "P" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === p2
                  ) {
                    p2Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p2) {p2Found = true;} }
                //if (adHocP2Code !== '') { p2Found = true; }
              }
              if (p3 != null && p3 !== "" && p3 !== undefined) {
                for (
                  let i = 0;
                  i < dt["sGrFreightCharge"]["chargeData"].length;
                  i++
                ) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPc === "P" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === p3
                  ) {
                    p3Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p3) {p3Found = true;} }
                //if (adHocP3Code !== '') { p3Found = true; }
              }

              // checking C
              if (c1 != null && c1 !== "" && c1 !== undefined) {
                for (
                  let i = 0;
                  i < dt["sGrFreightCharge"]["chargeData"].length;
                  i++
                ) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPc === "C" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === c1
                  ) {
                    c1Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c1) {c1Found = true;} }
                //if (adHocC1Code !== '') { c1Found = true; }
              }
              if (c2 != null && c2 !== "" && c2 !== undefined) {
                for (
                  let i = 0;
                  i < dt["sGrFreightCharge"]["chargeData"].length;
                  i++
                ) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"].blFCPc === "C" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === c2
                  ) {
                    c2Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c2) {c2Found = true;} }
                //if (adHocC2Code !== '') { c2Found = true; }
              }
              if (c3 != null && c3 !== "" && c3 !== undefined) {
                for (let i = 0; i < dt["sGrFreightCharge"]["chargeData"]; i++) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPc === "C" &&
                    dt["sGrFreightCharge"]["chargeData"][i]
                      .blFCPlaceOfPaymentCode === c3
                  ) {
                    c3Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c3) {c3Found = true;} }
                //if (adHocC3Code !== '') { c3Found = true; }
              }

              // checking Py
              if (py1 != null && py1 !== "" && py1 !== undefined) {
                console.log("### debug payer ###");
                console.log("before for -> " + py1Found);
                for (let i = 0; i < dt["sGrFreightCharge"]["chargeData"]; i++) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPayer === py1
                  ) {
                    py1Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py1) {py1Found = true;} }
                console.log("after for -> " + py1Found);
                //  if (adHocPy1Code !== '') { py1Found = true; }
                console.log("after if -> " + py1Found);
              }
              if (py2 != null && py2 !== "" && py2 !== undefined) {
                for (let i = 0; i < dt["sGrFreightCharge"]["chargeData"]; i++) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPayer === py2
                  ) {
                    py2Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py2) {py2Found = true;} }
                //if (adHocPy2Code !== '') { py2Found = true; }
              }
              if (py3 != null && py3 !== "" && py3 !== undefined) {
                for (let i = 0; i < dt["sGrFreightCharge"]["chargeData"]; i++) {
                  if (
                    dt["sGrFreightCharge"]["chargeData"][i].blFCPayer === py3
                  ) {
                    py3Found = true;
                  }
                }
                // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py3) {py3Found = true;} }
                //if (adHocPy3Code !== '') { py3Found = true; }
              }

              // check again
              console.log(
                "p1 = " + p1Found + "   p2 = " + p2Found + "   p3 = " + p3Found
              );
              console.log(
                "c1 = " + c1Found + "   c2 = " + c2Found + "   c3 = " + c3Found
              );
              console.log(
                "py1 = " +
                  py1Found +
                  "   py2 = " +
                  py2Found +
                  "   py3 = " +
                  py3Found
              );

              //Restore Prepaid
              if (prepaidList.length === 1) {
                p1 = prepaidList[0];
                this.model.blPrepaidAt1Code = p1;
                this.cbBlNettChargePrepaid1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid1.setValue(p1);
              } else if (prepaidList.length === 2) {
                p1 = prepaidList[0];
                this.model.blPrepaidAt1Code = p1;
                this.cbBlNettChargePrepaid1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid1.setValue(p1);

                p2 = prepaidList[1];
                this.model.blPrepaidAt2Code = p2;
                this.cbBlNettChargePrepaid2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid2.setValue(p2);
              } else if (prepaidList.length >= 3) {
                p1 = prepaidList[0];
                this.model.blPrepaidAt1Code = p1;
                this.cbBlNettChargePrepaid1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid1.setValue(p1);

                p2 = prepaidList[1];
                this.model.blPrepaidAt2Code = p2;
                this.cbBlNettChargePrepaid2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid2.setValue(p2);

                p3 = prepaidList[2];
                this.model.blPrepaidAt3Code = p3;
                this.cbBlNettChargePrepaid3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePrepaid3.setValue(p3);
              }

              //Restore Collect
              if (collectList.length === 1) {
                c1 = collectList[0];
                this.model.blPayableAt1Code = c1;
                this.cbBlNettChargePayableAt1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt1.setValue(c1);
              } else if (collectList.length === 2) {
                c1 = collectList[0];
                this.model.blPayableAt1Code = c1;
                this.cbBlNettChargePayableAt1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt1.setValue(c1);

                c2 = collectList[1];
                this.model.blPayableAt2Code = c2;
                this.cbBlNettChargePayableAt2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt2.setValue(c2);
              } else if (collectList.length >= 3) {
                c1 = collectList[0];
                this.model.blPayableAt1Code = c1;
                this.cbBlNettChargePayableAt1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt1.setValue(c1);

                c2 = collectList[1];
                this.model.blPayableAt2Code = c2;
                this.cbBlNettChargePayableAt2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt2.setValue(c2);

                c3 = collectList[2];
                this.model.blPayableAt3Code = c3;
                this.cbBlNettChargePayableAt3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}"
                );
                this.cbBlNettChargePayableAt3.setValue(c3);
              }

              if (payerList.length === 1) {

                console.log("NGEBUT DI JALANAN IBUKOTA ");
                py1 = payerList[0];
                this.model.blPayer1Code = py1;
                this.cbBlNettChargeBillingParty1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty1.setValue(py1);
              } else if (payerList.length === 2) {
                py1 = payerList[0];
                this.model.blPayer1Code = py1;
                this.cbBlNettChargeBillingParty1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty1.setValue(py1);

                py2 = payerList[1];
                this.model.blPayer2Code = py2;
                this.cbBlNettChargeBillingParty2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty2.setValue(py2);
              } else if (payerList.length >= 3) {
                py1 = payerList[0];
                this.model.blPayer1Code = py1;
                this.cbBlNettChargeBillingParty1.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer1+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty1.setValue(py1);

                py2 = payerList[1];
                this.model.blPayer2Code = py2;
                this.cbBlNettChargeBillingParty2.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer2+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty2.setValue(py2);

                py3 = payerList[2];
                this.model.blPayer3Code = py3;
                this.cbBlNettChargeBillingParty3.setUrl(
                  this.configService.config.BASE_API.toString() +
                    // "/MasterCustomers/findByComboBoxControlPayer/customerId={query}"
                    "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+this.custIdForCbPayer3+"/customerId={query}"
                );
                this.cbBlNettChargeBillingParty3.setValue(py3);
              }

            let fcSeqno = 1;

             // let fcSeqno = 1;
              for (let cData of dt["sGrFreightCharge"]["chargeData"]) {
                console.log(cData);
                let blfc = new BLFreightCharge();
                blfc.blFCOfficeCode = cData.blFCOfficeCode;
                //blfc.blFCBlNo = cData.blFCBlNo;
                blfc.blFCBlNo = dt.blNoSplit;
                blfc.blFCSeq = fcSeqno;
                blfc.blFCChargeCode = cData.blFCChargeCode;
                blfc.blFCRevenue = cData.blFCRevenue;
                blfc.blFCRate = cData.blFCRate;
                blfc.blFCPerCode = cData.blFCPerCode;
                blfc.blFCCurrencyCode = cData.blFCCurrencyCode;
                blfc.blFCAmount = cData.blFCAmount;
                blfc.blFCPc = cData.blFCPc;
                blfc.blFCPayerCode = cData.blFCPayerCode;
                blfc.blFCPayer = cData.blFCPayer;
                blfc.blFCPlaceOfPaymentCode = cData.blFCPlaceOfPaymentCode;
                blfc.blFCPlaceOfPaymentName = cData.blFCPlaceOfPaymentName;
                blfc.blFCContainerType = cData.blFCContainerType;
                blfc.blFCIsEntered = cData.blFCIsEntered;
                blfc.blFCIsEnteredString = cData.blFCIsEnteredString;
                blfc.blFCIsChargePrinted = cData.blFCIsChargePrinted;
                blfc.blFCIsChargePrintedString =
                  cData.blFCIsChargePrintedString;
                blfc.blFCIsManifestedFreight = cData.blFCIsManifestedFreight;
                blfc.blFCIsManifestedFreightString =
                  cData.blFCIsManifestedFreight;
                blfc.blFCFaiGroup = cData.blFCFaiGroup;
                blfc.blFCFaiGroupString = cData.blFCFaiGroupString;
                blfc.blFCFreightReference = cData.blFCFreightReference;
                blfc.blFCCreatedUserid = cData.blFCCreatedUserid;
                blfc.blFCCreatedDate = cData.blFCCreatedDate;
                blfc.blFCModifiedUserid = cData.blFCModifiedUserid;
                blfc.blFCModifiedDate = cData.blFCModifiedDate;

                blfc.blFCIsChargePrinted =
                  cData.blFCIsChargePrinted === "Y" ? true : false;
                blfc.blFCIsChargePrintedString =
                  blfc.blFCIsChargePrinted === true ? "Y" : "N";

                blfc.blFCIsManifestedFreight =
                  cData.blFCIsManifestedFreight === "Y" ? true : false;
                blfc.blFCIsManifestedFreightString =
                  blfc.blFCIsManifestedFreight === true ? "Y" : "N";

                if (blfc.blFCPc === "P") {
                  switch (blfc.blFCPlaceOfPaymentCode) {
                    case p1: {
                      blfc.blFCPc = "P1";
                      break;
                    }
                    case p2: {
                      blfc.blFCPc = "P2";
                      break;
                    }
                    case p3: {
                      blfc.blFCPc = "P3";
                      break;
                    }
                    default: {
                      blfc.blFCPc = "";
                      blfc.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }
                if (blfc.blFCPc === "C") {
                  switch (blfc.blFCPlaceOfPaymentCode) {
                    case c1: {
                      blfc.blFCPc = "C1";
                      break;
                    }
                    case c2: {
                      blfc.blFCPc = "C2";
                      break;
                    }
                    case c3: {
                      blfc.blFCPc = "C3";
                      break;
                    }
                    default: {
                      blfc.blFCPc = "";
                      blfc.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }
                //setter Payer
                if (blfc.blFCPayer !== "") {
                  switch (blfc.blFCPayer) {
                    case py1: {
                      blfc.blFCPayer = "1";
                      break;
                    }
                    case py2: {
                      blfc.blFCPayer = "2";
                      break;
                    }
                    case py3: {
                      blfc.blFCPayer = "3";
                      break;
                    }
                    default: {
                      blfc.blFCPayer = "";
                      break;
                    }
                  }
                }

                let iter: number = 1;
                for (const entry of oldGridValue) {
                  // check for charge code, and percode
                  if (!entry.blFCIsEntered) {
                    if (
                      blfc.blFCChargeCode === entry.blFCChargeCode &&
                      blfc.blFCPerCode === entry.blFCPerCode &&
                      blfc.blFCPc === entry.blFCPc &&
                      blfc.blFCFreightReference === entry.blFCFreightReference
                    ) {
                      blfc.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                      blfc.blFCIsChargePrintedString =
                        entry.blFCIsChargePrintedString;
                      blfc.blFCIsManifestedFreight =
                        entry.blFCIsManifestedFreight;
                      blfc.blFCIsManifestedFreightString =
                        entry.blFCIsManifestedFreightString;
                      //cData.blFCFaiGroup = entry.blFCFaiGroup;
                      //cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                      // cData.blFCPayer = entry.blFCPayer;
                    }
                  }
                  iter++;
                }

                console.log("=============");
                console.log(blfc);

                this.gridBlNettCharge.listStore.store.push(blfc);
                fcSeqno++;
              }

              for (const entry of oldGridValueAdhoc) {
                entry.blFCSeq = fcSeqno;
                if (entry.blFCPlaceOfPaymentCode !== "") {
                  switch (entry.blFCPlaceOfPaymentCode) {
                    case preped1code: {
                      entry.blFCPc = "P1";
                      break;
                    }
                    case preped2code: {
                      entry.blFCPc = "P2";
                      break;
                    }
                    case preped3code: {
                      entry.blFCPc = "P3";
                      break;
                    }
                    case this.model.blPayableAt1Code: {
                      entry.blFCPc = "C1";
                      break;
                    }
                    case this.model.blPayableAt2Code: {
                      entry.blFCPc = "C2";
                      break;
                    }
                    case this.model.blPayableAt3Code: {
                      entry.blFCPc = "C3";
                      break;
                    }
                    default: {
                      entry.blFCPc = "";
                      entry.blFCPlaceOfPaymentName = "";
                      break;
                    }
                  }
                }

                //setter Payer,akalin supaya dapet payernya yang tiba2 ilang meng goib
                console.log('entry payer '+entry.blFCPayer)
                if (entry.blFCPc !== "") {
                  switch (entry.blFCPc) {
                    case "P1": {
                      entry.blFCPayer = "1";
                      break;
                    }
                    case "P2": {
                      entry.blFCPayer = "2";
                      break;
                    }
                    case "P3": {
                      entry.blFCPayer = "3";
                      break;
                    }
                    case "C1": {
                      entry.blFCPayer = "1";
                      break;
                    }
                    case "C2": {
                      entry.blFCPayer = "2";
                      break;
                    }
                    case "C3": {
                      entry.blFCPayer = "3";
                      break;
                    }
                    default: {
                      entry.blFCPayer = "";
                      break;
                    }
                  }
                }

                this.gridBlNettCharge.listStore.store.push(entry);
                fcSeqno++;
              }

              this.gridBlNettCharge.loadData();
            }
          }

          console.log("MASUK ADHOC NETIZEN");

          // GOS-736
          let showPrompterDiscrepancies = false;
          // action to detect the changes
          this.frmDialogMessage = "";
          if (dt["errorList"].length > 0) {
            let isCompare = this.compareDgClassWithblDgClass();
            let bkgClass = this.bookingDataDetail.length > 0 ? this.bookingDataDetail[0].bDgClass : "";
            let blDgClass = this.model.blDgClass === "N/A" ? "N" : this.model.blDgClass;
            if(blDgClass === bkgClass && !isCompare && this.changeDataFirstTime != "FirstTime" ||
              blDgClass != bkgClass && this.model.blIsCombine === "Y"){
              showPrompterDiscrepancies = true;
            }else{
              this.frmDialogMessage +=
              '<table border="0" width="100%"><tr><td>## DATA CHANGED ##</td></tr></table>';
            this.frmDialogMessage +=
              '<table border="0" width="100%"><tr><td width="4%" align="center" valign="top"><b>No</b></td><td width="30%" valign="top" align="center"><b>Type</b></td><td width="30%" valign="top" align="center"><b>BL</b></td><td width="6%" valign="top">&nbsp;</td><td width="30%" valign="top" align="center"><b>BKG</b></td></tr>';
            for (let i = 0; i < dt["errorList"].length; i++) {
              this.frmDialogMessage +=
                '<tr><td align="center" valign="top">' +
                (i + 1) +
                '</td><td valign="top" align="center">' +
                dt["errorList"][i]["type"] +
                '</td><td valign="top" align="center">' +
                (dt["errorList"][i]["bl"] === ""
                  ? "[BLANK]"
                  : dt["errorList"][i]["bl"]) +
                '</td><td align="center">==></td><td valign="top" align="center">' +
                dt["errorList"][i]["booking"] +
                "</td></tr>";
            }
            this.frmDialogMessage += "</table>";
            }

          }else{
            let isCompare = true;

            if(this.bookingDataDetail.length > 0 && this.gridBlContainer.listStore.store && this.gridBlContainer.listStore.store.length > 0){
              isCompare = this.compareBlAndBKG();
            }
            // BL no combine
            if(!isCompare){
              showPrompterDiscrepancies = true;
            }
          }

          let processTimeout = true;
          if (this.frmDialogMessage !== "") {
            this.refreightData = dt;
            this.genericUtil.showDialog(
              "clickRefreightDialogNew",
              "Information",
              600,
              250
            );
            processTimeout = false;
          } else {
            // check split qty changed after split;
            if(showPrompterDiscrepancies){
              this.showPrompterDiscrepancies();
              this.genericUtil.hideLoader();
            }else{
              this.frmDialogMessage = "Refreighted successfully.";
              if (dt["flag"] === "BKGQTYCHANGED") {
                this.frmDialogMessage =
                  "BKG details have been modified after BL was SPLIT. Please proceed to SPLIT BL Screen to Update.";
                //this.resetRefreight();  // commented out as per celine comment on Jira  BKK-39 24/7/2019
                this.model.bkgQtyChanged = "Y"
              }
              setTimeout(() => {
                this.genericUtil.hideLoader();
                this.genericUtil.showDialog(
                  "clickRefreightDialog",
                  "Confirmation",
                  350,
                  180
                );
              }, 10);
            }
          }

          if(processTimeout != false ){
            // setTimeout(() => {
            //   this.frmDialogMessage = "Refreighted successfully.";
            // });
          }

        }
      });
    } else {
      this.frmDialogMessage = "Refreighted successfully.";
      this.genericUtil.hideLoader();
      this.genericUtil.showDialog(
        "clickRefreightDialog",
        "Confirmation",
        350,
        150
      );

    }
  }

  compareDgClassWithblDgClass(){
    let isCompare = true;
    if(this.gridBlContainer.listStore.store.length > 0){
      this.getContainerQuantityDGClass(()=>{});
      let DgClassBlList = this.gridBlContainer.listStore.store.map(x=>x.blContainerDGClass === "N/A" ? "N" : x.blContainerDGClass);
      let blDgClass = this.model.blDgClass === "N/A" ? "N" : this.model.blDgClass;
      isCompare = this.areAllValuesEqual(DgClassBlList, blDgClass);
    }
    return isCompare;
  }

  areAllValuesEqual(array, value) {
    for (const element of array) {
      if (element !== value) {
        return false;
      }
    }
    return true;
  }


  compareBlAndBKG(){
    console.log("** compare BL and BKG **")
    let isPass = true;
    if(this.gridBlContainer.listStore.store.length > 0){
      this.getContainerQuantityDGClass(()=>{
        this.containerQuantityDGClass = this.containerQuantityDGClass.reduce((unique, o) => {

          if(!unique.some(obj =>
            obj.blContainerTypeName + (obj.blContainerIsDry === true ? ' DRY' : '')  === o.blContainerTypeName + (o.blContainerIsDry === true ? ' DRY' : '') &&
            obj.blContainerSize === o.blContainerSize &&
            obj.blContainerHeight === o.blContainerHeight &&
            obj.blContainerDGClass === o.blContainerDGClass)) {
            unique.push(o);
          }
          return unique;
        },[]);

        isPass = this.compareArrays(this.bookingDataDetail, this.containerQuantityDGClass);
      });
    }else{
      this.containerQuantityDGClass = [];
      isPass = false;
    }

    return isPass;
  }

  getContainerQuantityDGClass(callbackFunc){
    let containerQuantityDGClass = this.gridBlContainer.listStore.store.map(obj => {
      const blContainerTypeName = obj.blContainerTypeName + (obj.blContainerIsDry === true ? ' DRY' : '');
      const blContainerSize = obj.blContainerSize;
      const blContainerHeight = obj.blContainerHeight;
      const blContainerDGClass = obj.blContainerDGClass;
      const groupTypeSizeHeight = obj.blContainerTypeName + (obj.blContainerIsDry === true ? ' DRY' : '') + obj.blContainerSize + obj.blContainerHeight;

      const count = this.gridBlContainer.listStore.store.filter(o =>
        o.blContainerTypeName + (o.blContainerIsDry === true? ' DRY' : '') === blContainerTypeName  &&
        o.blContainerSize === blContainerSize &&
        o.blContainerHeight === blContainerHeight &&
        o.blContainerDGClass === blContainerDGClass
        )
        .length;

      return { ...obj, count, groupTypeSizeHeight };
    });

    const blContainerist = containerQuantityDGClass.filter((obj, index) => {
      return index === containerQuantityDGClass.findIndex(o =>
        obj.count === o.count &&
        obj.blContainerTypeName + (obj.blContainerIsDry === true? ' DRY' : '') === o.blContainerTypeName + (o.blContainerIsDry === true? ' DRY' : '') &&
        obj.blContainerSize === o.blContainerSize &&
        obj.blContainerHeight === o.blContainerHeight &&
        obj.blContainerDGClass === o.blContainerDGClass
        );
    });
    this.containerQuantityDGClass = blContainerist;
    callbackFunc();
  }

  compareArrays(array1, array2){
    if (array1.length !== array2.length) {
      return false;
    }

    const groups = {};

    // Group objects into groups according to groupTypeSizeHeight
    for (const obj1 of array1) {
      const groupType = obj1.groupTypeSizeHeight;
      groups[groupType] = groups[groupType] || [];
      groups[groupType].push(obj1);
    }

    for (const obj2 of array2) {
      const groupType = obj2.groupTypeSizeHeight;
      if (!groups[groupType]) {
        return false; // groupTypeSizeHeight has different values
      }

      const matchingObj1 = groups[groupType].find(obj1 =>
        obj1.bQuantity === obj2.count && obj1.bDgClass === obj2.blContainerDGClass
      );

      if (!matchingObj1) {
        return false; // bQuantity or bDgClass values ​​are different
      }

      groups[groupType].splice(groups[groupType].indexOf(matchingObj1), 1); // Remove object comparison
    }

    // Check if all objects in array1 have been compared
    return Object.values(groups).every((group:any) => group.length === 0);
  };


  showPrompterDiscrepancies(){
    this.bookingDataDetail[0].title = "Booking Quantity & DG Class";
    this.containerQuantityDGClass[0].title = "Container Quantity & DG Class";
    this.genericUtil.showDialog(
      "showPrompterDiscrepancies",
      "Information",
      700,
      300
    );
  }

  closePrompterDiscrepancies(){
    this.isRefreightButtonClicked = false;
    this.genericUtil.closeDialog("showPrompterDiscrepancies");
  }

  yesPrompterDiscrepancies(){
    this.genericUtil.closeDialog("showPrompterDiscrepancies");
    this.frmDialogMessage = "Refreighted successfully.";
    this.genericUtil.showDialog(
      "clickRefreightDialog",
      "Confirmation",
      350,
      150
    );
  }

  onRefreightFinalConsole() {
    console.log("** onRefreightFinalConsole **");
    this.isRefreightProcessConsole = true;
    this.isRefreightInvokedFromFinalConsole = true;
    this.isRefreightButtonClickedConsole = true;

    this.tmpDateOfIssue = this.dateBlNettChargeDateOfIssueConsole.getValue();
    this.tmpExchangeRateDate =
      this.dateBlNettChargeExchangeRateConsole.getValue(); // what the fish
    //even fishy coz i follow the fish
    this.tmpShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();

    let checker: Boolean = false;
    if (this.model.blIsSplit !== "Y") {
      checker = true;
    } else {
      if (this.model.blSplitMethodType.toUpperCase() === "PRORATE") {
        checker = true;
      } else if (this.model.blSplitMethodType.toUpperCase() === "FREIGHT") {
        if (this.model.blSplitKey === "") {
          checker = true;
        } else {
          checker = true;
        }
      } else {
        checker = false;
      }
    }

    // this.onBlNettChargeRefreightNew();
    console.log(
      "Check split method = " +
        this.model.blSplitMethodType +
        " " +
        this.model.blSplitKey
    );
    if (this.model.blType !== "SW" && this.model.blType !== "OIS" && checker) {
      let hitRefreightURL = "";
      if (this.model.blIsCombine !== "Y") {
        hitRefreightURL =
          this.configService.config.BASE_API.toString() +
          "/BL/runRefreightByOfficeCodeBlNo/" +
          this.model.blOfficeCode +
          "/" +
          this.model.blNo;
      } else {
        hitRefreightURL =
          this.configService.config.BASE_API.toString() +
          "/BL/runRefreightByOfficeCodeBlNoCombineBL/" +
          this.model.blOfficeCode +
          "/" +
          this.model.blNo;
      }

      // //GSO-996
      // console.log(this.model['blContainerOwnership'] == "COCTS", this.model.blType == "MEMO", this.model.thruBlNo);

      // if (this.model['blContainerOwnership'] == "COCTS" && this.model.blType == "MEMO" && this.model.thruBlNo) {
      //   hitRefreightURL =
      //     this.configService.config.BASE_API.toString() +
      //     "/BL/refreightTsBookingPOT/" +
      //     this.model.blOfficeCode +
      //     "/" +
      //     this.model.thruOfficeCode
      //     "/" +
      //     this.model.blNo
      //     "/" +
      //     this.model.thruBlNo;
      // }

      this.genericUtil.showLoader();
      this.genericService.GET(hitRefreightURL).subscribe((resp) => {
        let dt = resp.json();

        if (
          this.model.blIsSplit === "Y" &&
          this.model.blSplitMethodType.toUpperCase() === "FREIGHT" &&
          this.model.blSplitKey !== ""
        ) {
          dt.chargeData = [];
        }

        const dt2 = resp.json();
        if (dt["status"] === "OK") {
          console.log("refreight triggered");
          console.log(resp.json());

          this.txtBlNettChargeRemarksConsole.setValue(
            dt.pfcPrincipalRemarks == null ? "" : dt.pfcPrincipalRemarks
          );

          // store the old pop and payer
          /*
          let p1 = this.model.blPrepaidAt1Code;
          let p2 = this.model.blPrepaidAt2Code;
          let p3 = this.model.blPrepaidAt3Code;

          let c1 = this.model.blPayableAt1Code;
          let c2 = this.model.blPayableAt2Code;
          let c3 = this.model.blPayableAt3Code;

          let py1 = this.model.blPayer1Code;
          let py2 = this.model.blPayer2Code;
          let py3 = this.model.blPayer3Code;

          let adHocP1Code = '';
          let adHocP2Code = '';
          let adHocP3Code = '';

          let adHocC1Code = '';
          let adHocC2Code = '';
          let adHocC3Code = '';

          let adHocPy1Code = '';
          let adHocPy2Code = '';
          let adHocPy3Code = '';


          const prepaidList: Array<string> = [];
          const collectList: Array<string> = [];
          const payerListUnsorted: Array<string> = [];
          const payerList: Array<string> = [];

          const adHocPrepaidList: Array<string> = [];
          const adHocCollectList: Array<string> = [];
          const adHocPayerList: Array<string> = [];
          */

          // store grid old value before reset
          let oldGridValue: Array<BLFreightCharge> = [];
          const oldGridValueAdhoc: Array<BLFreightCharge> = [];
          if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
            for (
              let i = 0;
              i < this.gridBlNettChargeConsole.listStore.store.length;
              i++
            ) {
              oldGridValue.push(
                this.gridBlNettChargeConsole.listStore.store[i]
              );
            }
          }
          console.log("Old grid value => debug");
          console.log(oldGridValue);

          // detect what is the pop and payer which used by adhoc charges previously
          for (const gridData of oldGridValue) {
            if (gridData.blFCIsEntered) {
              /*
              switch (gridData.blFCPc) {
                case 'P1': {
                  adHocP1Code = this.model.blPrepaidAt1Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP1Code;
                  break;
                }
                case 'P2': {
                  adHocP2Code = this.model.blPrepaidAt2Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP2Code;
                  break;
                }
                case 'P3': {
                  adHocP3Code = this.model.blPrepaidAt3Code;
                  gridData.blFCPlaceOfPaymentCode = adHocP3Code;
                  break;
                }

                case 'C1': {
                  adHocC1Code = this.model.blPayableAt1Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC1Code;
                  break;
                }
                case 'C2': {
                  adHocC2Code = this.model.blPayableAt2Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC2Code;
                  break;
                }
                case 'C3': {
                  adHocC3Code = this.model.blPayableAt3Code;
                  gridData.blFCPlaceOfPaymentCode = adHocC3Code;
                  break;
                }
                default:
                  break;
              }

              switch (gridData.blFCPayer) {
                case '1': {
                  adHocPy1Code = this.model.blPayer1Code;
                  gridData.blFCPayer = adHocPy1Code;
                  break;
                }
                case '2': {
                  adHocPy2Code = this.model.blPayer2Code;
                  gridData.blFCPayer = adHocPy2Code;
                  break;
                }
                case '3': {
                  adHocPy3Code = this.model.blPayer3Code;
                  gridData.blFCPayer = adHocPy3Code;
                  break;
                }
              }
              */

              oldGridValueAdhoc.push(gridData);
            }
          }

          if (dt["chargeData"] != null && dt["chargeData"].length > 0) {
            // reset the pop and payer
            /*
            this.cbBlNettChargePrepaid1.setValue('');
            this.model.blPrepaidAt1Code = '';
            this.model.blPrepaidAt1Name = '';
            this.cbBlNettChargePrepaid2.setValue('');
            this.model.blPrepaidAt2Code = '';
            this.model.blPrepaidAt2Name = '';
            this.cbBlNettChargePrepaid3.setValue('');
            this.model.blPrepaidAt3Code = '';
            this.model.blPrepaidAt3Name = '';
            this.cbBlNettChargePayableAt1.setValue('');
            this.model.blPayableAt1Code = '';
            this.model.blPayableAt1Name = '';
            this.cbBlNettChargePayableAt2.setValue('');
            this.model.blPayableAt2Code = '';
            this.model.blPayableAt2Name = '';
            this.cbBlNettChargePayableAt3.setValue('');
            this.model.blPayableAt3Code = '';
            this.model.blPayableAt3Name = '';
            this.cbBlNettChargeBillingParty1.setValue('');
            this.model.blPayer1Code = '';
            this.model.blPayer1Name = '';
            this.cbBlNettChargeBillingParty2.setValue('');
            this.model.blPayer2Code = '';
            this.model.blPayer2Name = '';
            this.cbBlNettChargeBillingParty3.setValue('');
            this.model.blPayer3Code = '';
            this.model.blPayer3Name = '';
            */
            // reset the grid
            this.gridBlNettChargeConsole.onClear();

            //newly added to populate the payer
            for (const cData of dt["chargeData"]) {
              /*
              if (cData.blFCPc === 'P') {
                if (!prepaidList.includes(cData.blFCPlaceOfPaymentCode)) {
                  prepaidList.push(cData.blFCPlaceOfPaymentCode);
                }

              } else {
                if (!collectList.includes(cData.blFCPlaceOfPaymentCode)) {
                  collectList.push(cData.blFCPlaceOfPaymentCode);
                }
              }

              if (cData.blFCPayer != '') {
                if (!payerListUnsorted.includes(cData.blFCPayer)) {
                  console.log('### payerList Push debug ###');
                  console.log(cData.blFCPayer);
                  payerListUnsorted.push(cData.blFCPayer);
                }
              }
              */
            }

            /*
            if (payerListUnsorted.length > 0) {
              for (const payer of payerListUnsorted) {
                payerList.push(payer);
              }
            }
            */

            // check value after rest
            /*
            console.log('---debug prepaid list---');
            console.log(prepaidList);
            console.log(payerList);
            console.log('---end of debug prepaid list---');

            console.log('p1 = ' + p1 + '   p2 = ' + p2 + '   p3 = ' + p3);
            console.log('c1 = ' + c1 + '   c2 = ' + c2 + '   c3 = ' + c3);
            console.log('py1 = ' + py1 + '   py2 = ' + py2 + '   py3 = ' + py3);

            // Check again, for each value on p,c, and py against chargedata and adhoc, if now not used must be removed
            let p1Found = false;
            let p2Found = false;
            let p3Found = false;
            let c1Found = false;
            let c2Found = false;
            let c3Found = false;
            let py1Found = false;
            let py2Found = false;
            let py3Found = false;


            for (const test of prepaidList) {
              // check for P1
              if (p1 == null || p1 === undefined || p1 === '') {
                p1 = test;
                continue;
              }
              // check for p2
              if (p2 == null || p2 === undefined || p2 === '') {
                if (test !== p1) {
                  p2 = test;
                  continue;
                } else {
                  continue;
                }
              }
              // check for p3
              if (p3 == null || p3 === undefined || p3 === '') {
                if (test !== p1 && test !== p2) {
                  p3 = test;
                  continue;
                } else {
                  continue;
                }
              }


            }


            for (const test2 of collectList) {
              // check for C1
              if (c1 == null || c1 === undefined || c1 === '') {
                c1 = test2;
                continue;
              }
              // check for C2
              if (c2 == null || c2 === undefined || c2 === '') {
                if (test2 !== c1) {
                  c2 = test2;
                  continue;
                } else {
                  continue
                }
              }
              // check for C3
              if (c3 == null || c3 === undefined || c3 === '') {
                if (test2 !== c1 && test2 !== c2) {
                  c3 = test2;
                  continue;
                } else {
                  continue;
                }
              }
            }

            for (const test3 of payerList) {
              // check for C1
              if (py1 == null || py1 === undefined || py1 === '') {
                py1 = test3;
                continue;
              }
              // check for C2
              if (py2 == null || py2 === undefined || py2 === '') {
                if (test3 !== py1) {
                  py2 = test3;
                  continue;
                } else {
                  continue;
                }
              }
              // check for C3
              if (py3 == null || py3 === undefined || py3 === '') {
                if (test3 !== py1 && test3 !== py2) {
                  py3 = test3;
                  continue;
                } else {
                  continue;
                }
              }
            }

            // checking P/C
            if (p1 != null && p1 !== '' && p1 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'P' && dt.chargeData[i].blFCPlaceOfPaymentCode === p1) {
                  p1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p1) {p1Found = true;} }
              //if (adHocP1Code !== '') { p1Found = true; }
            }
            if (p2 != null && p2 !== '' && p2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'P' && dt.chargeData[i].blFCPlaceOfPaymentCode === p2) {
                  p2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p2) {p2Found = true;} }
              //if (adHocP2Code !== '') { p2Found = true; }
            }
            if (p3 != null && p3 !== '' && p3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'P' && dt.chargeData[i].blFCPlaceOfPaymentCode === p3) {
                  p3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'P' && entry.blFCPlaceOfPaymentCode === p3) {p3Found = true;} }
              //if (adHocP3Code !== '') { p3Found = true; }
            }

            // checking C
            if (c1 != null && c1 !== '' && c1 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'C' && dt.chargeData[i].blFCPlaceOfPaymentCode === c1) {
                  c1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c1) {c1Found = true;} }
              //if (adHocC1Code !== '') { c1Found = true; }
            }
            if (c2 != null && c2 !== '' && c2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'C' && dt.chargeData[i].blFCPlaceOfPaymentCode === c2) {
                  c2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c2) {c2Found = true;} }
              //if (adHocC2Code !== '') { c2Found = true; }
            }
            if (c3 != null && c3 !== '' && c3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPc === 'C' && dt.chargeData[i].blFCPlaceOfPaymentCode === c3) {
                  c3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPc === 'C' && entry.blFCPlaceOfPaymentCode === c3) {c3Found = true;} }
              //if (adHocC3Code !== '') { c3Found = true; }
            }

            // checking Py
            if (py1 != null && py1 !== '' && py1 !== undefined) {
              console.log('### debug payer ###')
              console.log('before for -> ' + py1Found);
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py1) {
                  py1Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py1) {py1Found = true;} }
              console.log('after for -> ' + py1Found);
              //  if (adHocPy1Code !== '') { py1Found = true; }
              console.log('after if -> ' + py1Found);
            }
            if (py2 != null && py2 !== '' && py2 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py2) {
                  py2Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py2) {py2Found = true;} }
              //if (adHocPy2Code !== '') { py2Found = true; }
            }
            if (py3 != null && py3 !== '' && py3 !== undefined) {
              for (let i = 0; i < dt.chargeData.length; i++) {
                if (dt.chargeData[i].blFCPayer === py3) {
                  py3Found = true;
                }
              }
              // for (const entry of oldGridValue) { if (entry.blFCIsEntered === true && entry.blFCPayer === py3) {py3Found = true;} }
              //if (adHocPy3Code !== '') { py3Found = true; }
            }

            // check again
            console.log('p1 = ' + p1Found + '   p2 = ' + p2Found + '   p3 = ' + p3Found);
            console.log('c1 = ' + c1Found + '   c2 = ' + c2Found + '   c3 = ' + c3Found);
            console.log('py1 = ' + py1Found + '   py2 = ' + py2Found + '   py3 = ' + py3Found);

            // re-assign the pop and payer
            //Restore Prepaid
            if (prepaidList.length === 1) {
              p1 = prepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);
            } else if (prepaidList.length === 2) {
              p1 = prepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = prepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid2.setValue(p2);

            } else if (prepaidList.length >= 3) {
              p1 = prepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = prepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid2.setValue(p2);

              p3 = prepaidList[2];
              this.model.blPrepaidAt3Code = p3;
              this.cbBlNettChargePrepaid3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid3.setValue(p3);
            }


            //Restore Collect
            if (collectList.length === 1) {
              c1 = collectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);
            } else if (collectList.length === 2) {
              c1 = collectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = collectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt2.setValue(c2);
            } else if (collectList.length >= 3) {
              c1 = collectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = collectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt2.setValue(c2);

              c3 = collectList[2];
              this.model.blPayableAt3Code = c3;
              this.cbBlNettChargePayableAt3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt3.setValue(c3);

            }


            if (payerList.length === 1) {
              py1 = payerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);
            } else if (payerList.length === 2) {
              py1 = payerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = payerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty2.setValue(py2);

            } else if (payerList.length >= 3) {
              py1 = payerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = payerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty2.setValue(py2);

              py3 = payerList[2];
              this.model.blPayer3Code = py3;
              this.cbBlNettChargeBillingParty3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty3.setValue(py3);
            }
            */

            console.log("$$$ check model $$$");
            console.log(this.model);

            // AdHoc Restore
            // After all prepaid, collect, and payer restored from the PFC, we try to check which slot still vacant to push adhoc
            //adhoc prepaid
            const self = this;
            setTimeout(function () {
              /*
              console.log('---before debug adhoc---')
              console.log('adHocP1Code = ' + adHocP1Code);
              console.log('self.model.blPrepaidAt1Code = ' + self.model.blPrepaidAt1Code);
              console.log('self.model.blPrepaidAt2Code = ' + self.model.blPrepaidAt2Code);
              console.log('self.model.blPrepaidAt3Code = ' + self.model.blPrepaidAt3Code);

              if (adHocP1Code !== '') {
                if (adHocP1Code !== self.model.blPrepaidAt1Code && adHocP1Code !== self.model.blPrepaidAt2Code && adHocP1Code !== self.model.blPrepaidAt3Code) {
                  adHocPrepaidList.push(adHocP1Code);
                }
              }
              if (adHocP2Code !== '') {
                if (adHocP2Code !== self.model.blPrepaidAt1Code && adHocP2Code !== self.model.blPrepaidAt2Code && adHocP2Code !== self.model.blPrepaidAt3Code) {
                  adHocPrepaidList.push(adHocP2Code);
                }
              }
              if (adHocP3Code !== '') {
                if (adHocP3Code !== self.model.blPrepaidAt1Code && adHocP3Code !== self.model.blPrepaidAt2Code && adHocP3Code !== self.model.blPrepaidAt3Code) {
                  adHocPrepaidList.push(adHocP3Code);
                }
              }

              //adhoc collect
              if (adHocC1Code !== '') {
                if (adHocC1Code !== self.model.blPayableAt1Code && adHocC1Code !== self.model.blPayableAt2Code && adHocC1Code !== self.model.blPayableAt3Code) {
                  adHocCollectList.push(adHocC1Code);
                }
              }
              if (adHocC2Code !== '') {
                if (adHocC2Code !== self.model.blPayableAt1Code && adHocC2Code !== self.model.blPayableAt2Code && adHocC2Code !== self.model.blPayableAt3Code) {
                  adHocCollectList.push(adHocC2Code);
                }
              }
              if (adHocC3Code !== '') {
                if (adHocC3Code !== self.model.blPayableAt1Code && adHocC3Code !== self.model.blPayableAt2Code && adHocC3Code !== self.model.blPayableAt3Code) {
                  adHocCollectList.push(adHocC3Code);
                }
              }

              // adhoc payer
              if (adHocPy1Code !== '') {
                if (adHocPy1Code !== self.model.blPayer1Code && adHocPy1Code !== self.model.blPayer2Code && adHocPy1Code !== self.model.blPayer3Code) {
                  adHocPayerList.push(adHocPy1Code);
                }
              }
              if (adHocPy2Code !== '') {
                if (adHocPy2Code !== self.model.blPayer1Code && adHocPy2Code !== self.model.blPayer2Code && adHocPy2Code !== self.model.blPayer3Code) {
                  adHocPayerList.push(adHocPy2Code);
                }
              }
              if (adHocPy3Code !== '') {
                if (adHocPy3Code !== self.model.blPayer1Code && adHocPy3Code !== self.model.blPayer2Code && adHocPy3Code !== self.model.blPayer3Code) {
                  adHocPayerList.push(adHocPy3Code);
                }
              }

              console.log('----debug adhoc---');
              console.log(adHocPrepaidList);
              console.log(adHocCollectList);
              console.log(adHocPayerList);

              //try to restore prepaid
              if (adHocPrepaidList.length > 0) {
                for (const adHocPrepaid of  adHocPrepaidList) {
                  if (adHocPrepaid !== '') {
                    console.log('adhocPrepaid => ' + adHocPrepaid);
                    console.log('prepaid1 => ' + self.model.blPrepaidAt1Code);
                    console.log('prepaid2 => ' + self.model.blPrepaidAt2Code);
                    console.log('prepaid3 => ' + self.model.blPrepaidAt3Code);

                    if (self.model.blPrepaidAt1Code === '') {
                      self.model.blPrepaidAt1Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid1.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePrepaid1.setValue(adHocPrepaid);
                      continue;
                    } else if (self.model.blPrepaidAt2Code === '') {
                      self.model.blPrepaidAt2Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid2.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePrepaid2.setValue(adHocPrepaid);
                      continue;
                    } else if (self.model.blPrepaidAt3Code === '') {
                      self.model.blPrepaidAt3Code = adHocPrepaid;
                      self.cbBlNettChargePrepaid3.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePrepaid3.setValue(adHocPrepaid);
                      continue;
                    }
                  }
                }
              }

              if (adHocCollectList.length > 0) {
                for (const adHocCollect of  adHocCollectList) {
                  if (adHocCollect !== '') {
                    if (self.model.blPayableAt1Code === '') {
                      self.model.blPayableAt1Code = adHocCollect;
                      self.cbBlNettChargePayableAt1.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePayableAt1.setValue(adHocCollect);
                      continue;
                    } else if (self.model.blPayableAt2Code === '') {
                      self.model.blPayableAt2Code = adHocCollect;
                      self.cbBlNettChargePayableAt2.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePayableAt2.setValue(adHocCollect);
                      continue;
                    } else if (self.model.blPayableAt3Code === '') {
                      self.model.blPayableAt3Code = adHocCollect;
                      self.cbBlNettChargePayableAt3.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                      self.cbBlNettChargePayableAt3.setValue(adHocCollect);
                      continue;
                    }
                  }
                }
              }

              if (adHocPayerList.length > 0) {
                for (const adHocPayer of  adHocPayerList) {
                  if (adHocPayer !== '') {
                    if (self.model.blPayer1Code === '') {
                      self.model.blPayer1Code = adHocPayer;
                      self.cbBlNettChargeBillingParty1.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
                      self.cbBlNettChargeBillingParty1.setValue(adHocPayer);
                      continue;
                    } else if (self.model.blPayer2Code === '') {
                      self.model.blPayer2Code = adHocPayer;
                      self.cbBlNettChargeBillingParty2.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
                      self.cbBlNettChargeBillingParty2.setValue(adHocPayer);
                      continue;
                    } else if (self.model.blPayer3Code === '') {
                      self.model.blPayer3Code = adHocPayer;
                      self.cbBlNettChargeBillingParty3.setUrl(self.configService.config.BASE_API.toString() +
                        '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
                      self.cbBlNettChargeBillingParty3.setValue(adHocPayer);
                      continue;
                    }
                  }
                }
              }
              */

              // do the freight thing
              // 01. Check against oldGridValue for previous charge data
              let fcSeqno = 1;
              console.log("--debug manifested and printed freight---");
              for (let cData of resp.json()["chargeData"]) {
                console.log(cData);
                let blfc = new BLFreightCharge();
                blfc.blFCOfficeCode = cData.blFCOfficeCode;
                blfc.blFCBlNo = cData.blFCBlNo;
                blfc.blFCSeq = fcSeqno;
                blfc.blFCChargeCode = cData.blFCChargeCode;
                blfc.blFCRevenue = cData.blFCRevenue;
                blfc.blFCRate = cData.blFCRate;
                blfc.blFCPerCode = cData.blFCPerCode;
                blfc.blFCCurrencyCode = cData.blFCCurrencyCode;
                blfc.blFCAmount = cData.blFCAmount;
                blfc.blFCPc = cData.blFCPc;
                blfc.blFCPayerCode = cData.blFCPayerCode;
                blfc.blFCPayer = cData.blFCPayer;
                blfc.blFCPayerName = cData.blFCPayerName;
                blfc.blFCPlaceOfPaymentCode = cData.blFCPlaceOfPaymentCode;
                blfc.blFCPlaceOfPaymentName = cData.blFCPlaceOfPaymentName;
                blfc.blFCContainerType = cData.blFCContainerType;
                blfc.blFCIsEntered = cData.blFCIsEntered;
                blfc.blFCIsEnteredString = cData.blFCIsEnteredString;
                blfc.blFCIsChargePrinted = cData.blFCIsChargePrinted;
                blfc.blFCIsChargePrintedString =
                  cData.blFCIsChargePrintedString;
                blfc.blFCIsManifestedFreight = cData.blFCIsManifestedFreight;
                blfc.blFCIsManifestedFreightString =
                  cData.blFCIsManifestedFreight;
                blfc.blFCFaiGroup = cData.blFCFaiGroup;
                blfc.blFCFaiGroupString = cData.blFCFaiGroupString;
                blfc.blFCFreightReference = cData.blFCFreightReference;
                blfc.blFCCreatedUserid = cData.blFCCreatedUserid;
                blfc.blFCCreatedDate = cData.blFCCreatedDate;
                blfc.blFCModifiedUserid = cData.blFCModifiedUserid;
                blfc.blFCModifiedDate = cData.blFCModifiedDate;

                blfc.blFCIsChargePrinted =
                  cData.blFCIsChargePrinted === "Y" ? true : false;
                blfc.blFCIsChargePrintedString =
                  blfc.blFCIsChargePrinted === true ? "Y" : "N";

                blfc.blFCIsManifestedFreight =
                  cData.blFCIsManifestedFreight === "Y" ? true : false;
                blfc.blFCIsManifestedFreightString =
                  blfc.blFCIsManifestedFreight === true ? "Y" : "N";

                /*
                cData.blFCSeq = fcSeqno;
                cData.blFCIsEntered = (cData.blFCIsEntered === 'Y') ? true : false;
                cData.blFCIsEnteredString = (cData.blFCIsEntered === true) ? 'Y' : 'N';

                cData.blFCIsChargePrinted = (cData.blFCIsChargePrinted === 'Y') ? true : false;
                cData.blFCIsChargePrintedString = (cData.blFCIsChargePrinted === true) ? 'Y' : 'N';

                cData.blFCIsManifestedFreight = (cData.blFCIsManifestedFreight === 'Y') ? true : false;
                cData.blFCIsManifestedFreightString = (cData.blFCIsManifestedFreight === true) ? 'Y' : 'N';

                cData.blFCFaiGroup = (cData.blFCFaiGroup === 'Y') ? true : false;
                cData.blFCFaiGroupString = (cData.blFCFaiGroup === true) ? 'Y' : 'N';

                console.log('cData.blFCIsChargePrinted -> ' + cData.blFCIsChargePrinted + ' -vs- ' + cData.blFCIsChargePrintedString);
                console.log('cData.blFCIsManifestedFreight -> ' + cData.blFCIsManifestedFreight + ' -vs- ' + cData.blFCIsManifestedFreightString);
                */
                /*
                //setter PC
                console.log('hehehe -> ' + cData.blFCChargeCode + ' - ' + c1 + ' - ' + cData.blFCPlaceOfPaymentCode + ' - ' + self.model.blPayableAt1Code);
                if (cData.blFCPc === 'P') {
                  switch (cData.blFCPlaceOfPaymentCode) {
                    case p1 : {
                      cData.blFCPc = 'P1';
                      break;
                    }
                    case p2 : {
                      cData.blFCPc = 'P2';
                      break;
                    }
                    case p3 : {
                      cData.blFCPc = 'P3';
                      break;
                    }
                    default : {
                      cData.blFCPc = '';
                      cData.blFCPlaceOfPaymentName = '';
                      break;
                    }
                  }
                }
                if (cData.blFCPc === 'C') {
                  switch (cData.blFCPlaceOfPaymentCode) {
                    case c1 : {
                      cData.blFCPc = 'C1';
                      break;
                    }
                    case c2 : {
                      cData.blFCPc = 'C2';
                      break;
                    }
                    case c3 : {
                      cData.blFCPc = 'C3';
                      break;
                    }
                    default : {
                      cData.blFCPc = '';
                      cData.blFCPlaceOfPaymentName = '';
                      break;
                    }
                  }
                }
                //setter Payer
                if (cData.blFCPayer !== '') {
                  switch (cData.blFCPayer) {
                    case py1 : {
                      cData.blFCPayer = '1';
                      break;
                    }
                    case py2 : {
                      cData.blFCPayer = '2';
                      break;
                    }
                    case py3 : {
                      cData.blFCPayer = '3';
                      break;
                    }
                    default : {
                      cData.blFCPayer = '';
                      break;
                    }
                  }
                }
                */

                let iter: number = 1;
                for (const entry of oldGridValue) {
                  // check for charge code, and percode
                  if (!entry.blFCIsEntered) {
                    /*
                    if (cData.blFCChargeCode === entry.blFCChargeCode && cData.blFCPerCode === entry.blFCPerCode && cData.blFCPc === entry.blFCPc && cData.blFCFreightReference === entry.blFCFreightReference) {
                      cData.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                      cData.blFCIsChargePrintedString = entry.blFCIsChargePrintedString;
                      cData.blFCIsManifestedFreight = entry.blFCIsManifestedFreight;
                      cData.blFCIsManifestedFreightString = entry.blFCIsManifestedFreightString;
                      cData.blFCFaiGroup = entry.blFCFaiGroup;
                      cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                    }
                    */
                    if (
                      blfc.blFCChargeCode === entry.blFCChargeCode &&
                      blfc.blFCPerCode === entry.blFCPerCode &&
                      blfc.blFCPc === entry.blFCPc &&
                      blfc.blFCFreightReference === entry.blFCFreightReference
                    ) {
                      blfc.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                      blfc.blFCIsChargePrintedString =
                        entry.blFCIsChargePrintedString;
                      blfc.blFCIsManifestedFreight =
                        entry.blFCIsManifestedFreight;
                      blfc.blFCIsManifestedFreightString =
                        entry.blFCIsManifestedFreightString;
                      //cData.blFCFaiGroup = entry.blFCFaiGroup;
                      //cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                      // cData.blFCPayer = entry.blFCPayer;
                    }
                  }
                  iter++;
                }
                self.gridBlNettChargeConsole.listStore.store.push(blfc);
                fcSeqno++;
              }

              // revert the adhoc value
              for (const entry of oldGridValueAdhoc) {
                entry.blFCSeq = fcSeqno;
                /*
                if (entry.blFCPlaceOfPaymentCode !== '') {
                  switch (entry.blFCPlaceOfPaymentCode) {
                    case self.model.blPrepaidAt1Code : {
                      entry.blFCPc = 'P1';
                      break;
                    }
                    case self.model.blPrepaidAt2Code : {
                      entry.blFCPc = 'P2';
                      break;
                    }
                    case self.model.blPrepaidAt3Code : {
                      entry.blFCPc = 'P3';
                      break;
                    }
                    case self.model.blPayableAt1Code : {
                      entry.blFCPc = 'C1';
                      break;
                    }
                    case self.model.blPayableAt2Code : {
                      entry.blFCPc = 'C2';
                      break;
                    }
                    case self.model.blPayableAt3Code : {
                      entry.blFCPc = 'C3';
                      break;
                    }
                    default : {
                      entry.blFCPc = '';
                      entry.blFCPlaceOfPaymentName = '';
                      break;
                    }
                  }
                }


                //setter Payer
                if (entry.blFCPayer !== '') {
                  switch (entry.blFCPayer) {
                    case self.model.blPayer1Code : {
                      entry.blFCPayer = '1';
                      break;
                    }
                    case self.model.blPayer2Code : {
                      entry.blFCPayer = '2';
                      break;
                    }
                    case self.model.blPayer3Code : {
                      entry.blFCPayer = '3';
                      break;
                    }
                    default : {
                      entry.blFCPayer = '';
                      break;
                    }
                  }
                }
                */
                self.gridBlNettChargeConsole.listStore.store.push(entry);
                fcSeqno++;
              }

              /*
               for (const entry of oldGridValue){
               if (entry.blFCIsEntered === true) {
               entry.blFCSeq = fcSeqno;

               self.gridBlNettCharge.listStore.store.push(entry);
               fcSeqno++;
               }
               }
               */

              self.gridBlNettChargeConsole.loadData();
            }, 500);
          } else {
            // When refreight result is blank

            console.log("When no charge available");
            console.log("checking adHoc Data");
            let allDataAdhoc = true;
            for (const gridData of oldGridValue) {
              if (!gridData.blFCIsEntered) {
                allDataAdhoc = false;
              }
            }
            console.log("checking ater adhoc " + allDataAdhoc);

            if (!allDataAdhoc) {
              /*
              this.cbBlNettChargePrepaid1.setValue('');
              this.model.blPrepaidAt1Code = '';
              this.model.blPrepaidAt1Name = '';
              this.cbBlNettChargePrepaid2.setValue('');
              this.model.blPrepaidAt2Code = '';
              this.model.blPrepaidAt2Name = '';
              this.cbBlNettChargePrepaid3.setValue('');
              this.model.blPrepaidAt3Code = '';
              this.model.blPrepaidAt3Name = '';
              this.cbBlNettChargePayableAt1.setValue('');
              this.model.blPayableAt1Code = '';
              this.model.blPayableAt1Name = '';
              this.cbBlNettChargePayableAt2.setValue('');
              this.model.blPayableAt2Code = '';
              this.model.blPayableAt2Name = '';
              this.cbBlNettChargePayableAt3.setValue('');
              this.model.blPayableAt3Code = '';
              this.model.blPayableAt3Name = '';
              this.cbBlNettChargeBillingParty1.setValue('');
              this.model.blPayer1Code = '';
              this.model.blPayer1Name = '';
              this.cbBlNettChargeBillingParty2.setValue('');
              this.model.blPayer2Code = '';
              this.model.blPayer2Name = '';
              this.cbBlNettChargeBillingParty3.setValue('');
              this.model.blPayer3Code = '';
              this.model.blPayer3Name = '';
              */
            }
            // reset the grid
            this.gridBlNettChargeConsole.onClear();
            /*
            console.log('adHocP1Code = ' + adHocP1Code);
            console.log('py1 = ' + py1);
            console.log('this.model.blPayer1Code = ' + this.model.blPrepaidAt1Code);

            console.log('--after yogurt--');
            console.log(adHocPrepaidList);
            console.log(p1 + ' - ' + this.model.blPrepaidAt1Code);
            console.log(py1 + ' - ' + this.model.blPayer1Code);

            if (adHocPrepaidList.length === 1) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);
            } else if (adHocPrepaidList.length === 2) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = adHocPrepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid2.setValue(p2);

            } else if (adHocPrepaidList.length >= 3) {
              p1 = adHocPrepaidList[0];
              this.model.blPrepaidAt1Code = p1;
              this.cbBlNettChargePrepaid1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid1.setValue(p1);

              p2 = adHocPrepaidList[1];
              this.model.blPrepaidAt2Code = p2;
              this.cbBlNettChargePrepaid2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid2.setValue(p2);

              p3 = adHocPrepaidList[2];
              this.model.blPrepaidAt3Code = p3;
              this.cbBlNettChargePrepaid3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePrepaid3.setValue(p3);
            }

            //Restore Collect
            if (adHocCollectList.length === 1) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);
            } else if (adHocCollectList.length === 2) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = adHocCollectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt2.setValue(c2);
            } else if (adHocCollectList.length >= 3) {
              c1 = adHocCollectList[0];
              this.model.blPayableAt1Code = c1;
              this.cbBlNettChargePayableAt1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt1.setValue(c1);

              c2 = adHocCollectList[1];
              this.model.blPayableAt2Code = c2;
              this.cbBlNettChargePayableAt2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt2.setValue(c2);

              c3 = adHocCollectList[2];
              this.model.blPayableAt3Code = c3;
              this.cbBlNettChargePayableAt3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
              this.cbBlNettChargePayableAt3.setValue(c3);

            }


            if (adHocPayerList.length === 1) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);
            } else if (adHocPayerList.length === 2) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = adHocPayerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty2.setValue(py2);

            } else if (adHocPayerList.length >= 3) {
              py1 = adHocPayerList[0];
              this.model.blPayer1Code = py1;
              this.cbBlNettChargeBillingParty1.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty1.setValue(py1);

              py2 = adHocPayerList[1];
              this.model.blPayer2Code = py2;
              this.cbBlNettChargeBillingParty2.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty2.setValue(py2);

              py3 = adHocPayerList[2];
              this.model.blPayer3Code = py3;
              this.cbBlNettChargeBillingParty3.setUrl(this.configService.config.BASE_API.toString() +
                '/MasterCustomers/findByComboBoxControlPayer/customerId={query}');
              this.cbBlNettChargeBillingParty3.setValue(py3);
            }
            */

            // revert the adhoc value
            let fcSeqno = 1;
            for (const entry of oldGridValueAdhoc) {
              entry.blFCSeq = fcSeqno;
              /*
              if (entry.blFCPlaceOfPaymentCode !== '') {
                switch (entry.blFCPlaceOfPaymentCode) {
                  case this.model.blPrepaidAt1Code : {
                    entry.blFCPc = 'P1';
                    break;
                  }
                  case this.model.blPrepaidAt2Code : {
                    entry.blFCPc = 'P2';
                    break;
                  }
                  case this.model.blPrepaidAt3Code : {
                    entry.blFCPc = 'P3';
                    break;
                  }
                  case this.model.blPayableAt1Code : {
                    entry.blFCPc = 'C1';
                    break;
                  }
                  case this.model.blPayableAt2Code : {
                    entry.blFCPc = 'C2';
                    break;
                  }
                  case this.model.blPayableAt3Code : {
                    entry.blFCPc = 'C3';
                    break;
                  }
                  default : {
                    entry.blFCPc = '';
                    entry.blFCPlaceOfPaymentName = '';
                    break;
                  }
                }
              }


              //setter Payer
              if (entry.blFCPayer !== '') {
                switch (entry.blFCPayer) {
                  case this.model.blPayer1Code : {
                    entry.blFCPayer = '1';
                    break;
                  }
                  case this.model.blPayer2Code : {
                    entry.blFCPayer = '2';
                    break;
                  }
                  case this.model.blPayer3Code : {
                    entry.blFCPayer = '3';
                    break;
                  }
                  default : {
                    entry.blFCPayer = '';
                    break;
                  }
                }
              }
              */
              this.gridBlNettChargeConsole.listStore.store.push(entry);
              fcSeqno++;
            }

            this.gridBlNettChargeConsole.loadData();
          }

          this.genericUtil.hideLoader();

          // action to detect the changes
          this.frmDialogMessage = "";
          if (dt["errorList"].length > 0) {
            this.frmDialogMessage +=
              '<table border="0" width="100%"><tr><td>## DATA CHANGED ##</td></tr></table>';
            this.frmDialogMessage +=
              '<table border="0" width="100%"><tr><td width="4%" align="center" valign="top"><b>No</b></td><td width="30%" valign="top" align="center"><b>Type</b></td><td width="30%" valign="top" align="center"><b>BL</b></td><td width="6%" valign="top">&nbsp;</td><td width="30%" valign="top" align="center"><b>BKG</b></td></tr>';
            for (let i = 0; i < dt["errorList"].length; i++) {
              this.frmDialogMessage +=
                '<tr><td align="center" valign="top">' +
                (i + 1) +
                '</td><td valign="top" align="center">' +
                dt["errorList"][i]["type"] +
                '</td><td valign="top" align="center">' +
                (dt["errorList"][i]["bl"] === ""
                  ? "[BLANK]"
                  : dt["errorList"][i]["bl"]) +
                '</td><td align="center">==></td><td valign="top" align="center">' +
                dt["errorList"][i]["booking"] +
                "</td></tr>";
            }
            this.frmDialogMessage += "</table>";
          }

          console.log("this.isRefreightProcess = " + this.isRefreightProcess);

          if (this.frmDialogMessage !== "") {
            this.refreightData = dt;
            this.genericUtil.showDialog(
              "clickRefreightDialogNew",
              "Information",
              400,
              250
            );
          } else {
            // check split qty changed after split
            this.frmDialogMessage = "Refreighted successfully.";
            if (dt["flag"] === "BKGQTYCHANGED") {
              this.frmDialogMessage =
                "BKG details have been modified after BL was SPLIT. Please proceed to SPLIT BL Screen to Update.";
              //this.resetRefreight();  // commented out as per celine comment on Jira BKK-39 24/7/2019
              this.model.bkgQtyChanged = "Y"
            }
            this.genericUtil.showDialog(
              "clickRefreightDialog",
              "Confirmation",
              350,
              180
            );
          }
        }
      });
    } else {
      this.frmDialogMessage = "Refreighted successfully.";
      this.genericUtil.showDialog(
        "clickRefreightDialog",
        "Confirmation",
        350,
        150
      );
    }
  }

  /*
  onRefreightFinalConsole() {
    console.log("** onRefreightFinalConsole **")
    this.isRefreightProcessConsole = true;
    this.isRefreightInvokedFromFinalConsole = true;
    this.isRefreightButtonClickedConsole = true;

    this.tmpDateOfIssue = this.dateBlNettChargeDateOfIssueConsole.getValue();
    this.tmpExchangeRateDate = this.dateBlNettChargeExchangeRateConsole.getValue(); // what the fish

    let checker: Boolean = false;
    if (this.model.blIsSplit !== 'Y') {
      checker = true;
    } else {
      if (this.model.blSplitMethodType.toUpperCase() === 'PRORATE') {
        checker = true;
      } else if (this.model.blSplitMethodType.toUpperCase() === 'FREIGHT') {
        if (this.model.blSplitKey === '') {
          checker = true;
        } else {
          checker = true;
        }
      } else {
        checker = false;
      }
    }

    if (this.model.blType !== 'SW' && this.model.blType !== 'OIS' && checker) {
      let hitRefreightURL = '';
      if (this.model.blIsCombine !== 'Y') {
        hitRefreightURL = this.configService.config.BASE_API.toString() + '/BL/runRefreightByOfficeCodeBlNo/' + this.model.blOfficeCode + '/' + this.model.blNo;
      } else {
        hitRefreightURL = this.configService.config.BASE_API.toString() + '/BL/runRefreightByOfficeCodeBlNoCombineBL/' + this.model.blOfficeCode + '/' + this.model.blNo;
      }

      this.genericUtil.showLoader();
      this.genericService.GET(hitRefreightURL).subscribe((resp) => {
        let dt = resp.json();

        if (this.model.blIsSplit === 'Y' && this.model.blSplitMethodType.toUpperCase() === 'FREIGHT' && this.model.blSplitKey !== '') {
          dt.chargeData = [];
        }

        console.log(dt)

        const dt2 = resp.json();
        if (dt['status'] === 'OK') {
          console.log("dt.pfcPrincipalRemarks = " + dt.pfcPrincipalRemarks)
          this.txtBlNettChargeRemarksConsole.setValue(((dt.pfcPrincipalRemarks == null) ? '' : dt.pfcPrincipalRemarks));

          // store grid old value before reset
          let oldGridValue: Array<BLFreightCharge> = [];
          const oldGridValueAdhoc: Array<BLFreightCharge> = [];
          if (this.gridBlNettChargeConsole.listStore.store.length > 0) {
            for (let i = 0; i < this.gridBlNettChargeConsole.listStore.store.length; i++) {
              oldGridValue.push(this.gridBlNettChargeConsole.listStore.store[i]);
            }
          }
          console.log('Old grid value => debug');
          console.log(oldGridValue);

          for (const gridData of oldGridValue) {
            if (gridData.blFCIsEntered) {
              oldGridValueAdhoc.push(gridData);
            }
          }

          console.log('Old grid value adhoc => debug');
          console.log(oldGridValueAdhoc);

          if (dt['chargeData'] != null && dt['chargeData'].length > 0) {
              // reset the grid
              this.gridBlNettChargeConsole.onClear();

              // do the freight thing
              // 01. Check against oldGridValue for previous charge data
              let fcSeqno = 1;
              console.log('--debug manifested and printed freight---')
              for (const cData of dt['chargeData']) {
                cData.blFCSeq = fcSeqno;
                cData.blFCIsEntered = (cData.blFCIsEntered === 'Y') ? true : false;
                cData.blFCIsEnteredString = (cData.blFCIsEntered === true) ? 'Y' : 'N';

                cData.blFCIsChargePrinted = (cData.blFCIsChargePrinted === 'Y') ? true : false;
                cData.blFCIsChargePrintedString = (cData.blFCIsChargePrinted === true) ? 'Y' : 'N';

                cData.blFCIsManifestedFreight = (cData.blFCIsManifestedFreight === 'Y') ? true : false;
                cData.blFCIsManifestedFreightString = (cData.blFCIsManifestedFreight === true) ? 'Y' : 'N';

                cData.blFCFaiGroup = (cData.blFCFaiGroup === 'Y') ? true : false;
                cData.blFCFaiGroupString = (cData.blFCFaiGroup === true) ? 'Y' : 'N';

                let iter: number = 1
                for (const entry of oldGridValue) {
                  // check for charge code, and percode
                  if (!entry.blFCIsEntered) {
                    if (cData.blFCChargeCode === entry.blFCChargeCode && cData.blFCPerCode === entry.blFCPerCode && cData.blFCPc === entry.blFCPc && cData.blFCFreightReference === entry.blFCFreightReference) {
                      cData.blFCIsChargePrinted = entry.blFCIsChargePrinted;
                      cData.blFCIsChargePrintedString = entry.blFCIsChargePrintedString;
                      cData.blFCIsManifestedFreight = entry.blFCIsManifestedFreight;
                      cData.blFCIsManifestedFreightString = entry.blFCIsManifestedFreightString;
                      cData.blFCFaiGroup = entry.blFCFaiGroup;
                      cData.blFCFaiGroupString = entry.blFCFaiGroupString;
                      cData.blFCPayer = entry.blFCPayer;
                    }
                  }
                  iter++;
                }
                this.gridBlNettChargeConsole.listStore.store.push(cData);
                fcSeqno++;
              }

              // revert the adhoc value
              for (const entry of oldGridValueAdhoc) {
                entry.blFCSeq = fcSeqno;

                this.gridBlNettChargeConsole.listStore.store.push(entry);
                fcSeqno++;
              }
              this.gridBlNettChargeConsole.loadData()
          }

          this.genericUtil.hideLoader();

          // action to detect the changes
          this.frmDialogMessage = '';
          if (dt['errorList'].length > 0) {
            this.frmDialogMessage += '<table border="0" width="100%"><tr><td>## DATA CHANGED ##</td></tr></table>';
            this.frmDialogMessage += '<table border="0" width="100%"><tr><td width="4%" align="center" valign="top"><b>No</b></td><td width="30%" valign="top" align="center"><b>Type</b></td><td width="30%" valign="top" align="center"><b>BL</b></td><td width="6%" valign="top">&nbsp;</td><td width="30%" valign="top" align="center"><b>BKG</b></td></tr>';
            for (let i = 0; i < dt['errorList'].length; i++) {
              this.frmDialogMessage += '<tr><td align="center" valign="top">' + (i + 1) + '</td><td valign="top" align="center">' + dt['errorList'][i]['type'] + '</td><td valign="top" align="center">' + ((dt['errorList'][i]['bl'] === '') ? '[BLANK]' : dt['errorList'][i]['bl']) + '</td><td align="center">==></td><td valign="top" align="center">' + dt['errorList'][i]['booking'] + '</td></tr>';
            }
            this.frmDialogMessage += '</table>';
          }

          if (this.frmDialogMessage !== '') {
            this.refreightData = dt;
            this.genericUtil.showDialog('clickRefreightDialogNew', 'Information', 400, 250);

          } else {
            // check split qty changed after split
            this.frmDialogMessage = 'Refreighted successfully.';
            if (dt['flag'] === 'BKGQTYCHANGED') {
              this.frmDialogMessage = 'Refreight failed. BKG Quantity has been changed after BL was split. Please proceed to Split BL Screen to update.';
            }
            this.genericUtil.showDialog('clickRefreightDialog', 'Confirmation', 350, 180);
          }

        }
      });
    } else {
      this.frmDialogMessage = 'Refreighted successfully.';
      this.genericUtil.showDialog('clickRefreightDialog', 'Confirmation', 350, 150);
    }

  }
  */

  printButtonEvent(type) {
    console.log("yee");
    console.log(type);
    console.log(this.printType);
    if (type == "print") {
      if (this.printType == "original") {
        if (this.isFinished) {
          if (
            this.printOnlyOnceList.includes(this.officeCode.substring(0, 2))
          ) {
            if (this.BLPrint == "Y") {
              this.blPrintDialogMessage = "OBL has been printed.";
              this.genericUtil.showDialog(
                "blPrintDialogMessage",
                "Information",
                350,
                180
              );
            } else {
              this.printBL("OriginalBL", false);
            }
          } else {
            this.printBL("OriginalBL", false);
          }
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else if (this.printType == "draft") {
        this.printBL("DraftBL", false);
      } else if (this.printType == "draftwt") {
        this.printBL("DraftBL", true);
      } else if (this.printType == "copy") {
        if (this.isFinished) {
          this.printBL("CopyBL", false);
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else if (this.printType == "copywt") {
        if (this.isFinished) {
          this.printBL("CopyBL", true);
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else {
        this.blPrintDialogMessage = "Please choose B/L type to Print.";
        this.genericUtil.showDialog(
          "blPrintDialogMessage",
          "Information",
          350,
          180
        );
      }
    } else if (type == "close") {
      $("input:radio[name=printType]").prop("checked", false);
      this.printType = "";
      this.genericUtil.closeDialog("printDialog");
    }
  }

  printButtonEvent2(type) {
    this.printFrom = "ok2";
    console.log("yee");
    console.log(type);
    console.log(this.printType);
    if (type == "print") {
      if (this.printType == "original") {
        if (this.isFinished) {
          this.printBL("OriginalBL", false);
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else if (this.printType == "draft") {
        this.printBL("DraftBL", false);
      } else if (this.printType == "draftwt") {
        this.printBL("DraftBL", true);
      } else if (this.printType == "copy") {
        if (this.isFinished) {
          this.printBL("CopyBL", false);
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else if (this.printType == "copywt") {
        if (this.isFinished) {
          this.printBL("CopyBL", true);
        } else {
          this.blPrintDialogMessage = "BL has not been finished.";
          this.genericUtil.showDialog(
            "blPrintDialogMessage",
            "Information",
            350,
            180
          );
        }
      } else {
        this.blPrintDialogMessage = "Please choose B/L type to Print.";
        this.genericUtil.showDialog(
          "blPrintDialogMessage",
          "Information",
          350,
          180
        );
      }
    } else if (type == "close") {
      $("input:radio[name=printType]").prop("checked", false);
      this.printType = "";
      this.genericUtil.closeDialog("printDialog");
    }
  }

  printContainerNumWithSpace(value:boolean){
    console.log("BEFORE", this.allowSpacingContainerNo)
    this.allowSpacingContainerNo = value;
    console.log("AFTER", this.allowSpacingContainerNo)

  }

  printSelectionChange(type) {
    this.printType = type;
    if (type == "original") {
      this.jasper.getJasperPath(this.officeCode, "BLOriginalNS");
    } else if (type == "draft") {
      this.jasper.getJasperPath(this.officeCode, "BLDraftNS");
    } else if (type == "draftwt") {
    } else if (type == "copy") {
      this.jasper.getJasperPath(this.officeCode, "BLCopyNS");
    } else if (type == "copywt") {
    }
  }

  blPrintOkButton() {
    this.genericUtil.closeDialog("blPrintDialogMessage");
  }

  printBL(blType: string, wTemplate: boolean) {
    var config: any = require("config.json"); //this.config.jasperUrl
    this.genericUtil.showLoader();
    this.printLoading = true;
    console.log("sereal : ");
    console.log(this.hasAttch);
    var wContSpace = this.allowSpacingContainerNo ? "Y":"N";
    var postData = {};
    postData["userid"] = this.userId;
    postData["processData"] = this.genericUtil.getPSIDate();
    postData["documentType"] = blType;
    postData["silent-mode"] = "N";
    //postData["summaryUrl"] = "http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/BL/BLSummary.pdf?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo=xblno";
    postData["summaryUrl"] =
      this.configService.config.jasperUrl +
      "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
      this.officeCode +
      "/BL/BLSummary.pdf?userCreated=" +
      this.userId +
      "&officeCode=" +
      this.officeCode +
      "&blNo=xblno";
    //var hitUrl = this.configService.config.BASE_API.toString()  + "/edi-util/getFile/blNew";
    //var hitUrl = "http://10.10.100.5:12000" + "/edi-util/getFile/blNew";
    var hitUrl =
      this.configService.config.getPDFUrl + "/?q=/edi-util/getFile/blNew";

    console.log("NOVYAN KAMPREET");
    console.log(
      this.genericUtil
        .getPSIDate()
        .replace(/-/g, "")
        .replace(/:/g, "")
        .replace(" ", "")
    );
    var randomNumber = this.genericUtil
      .getPSIDate()
      .replace(/-/g, "")
      .replace(/:/g, "")
      .replace(" ", "");

    var eDataList = [];
    var eData = {};
    eData["wContSpace"] = wContSpace;
    eData["blNo"] = this.model.blNo;
    eData["fileName"] =
      blType +
      "-" +
      this.model.blNo.toUpperCase() +
      "-" +
      this.userId +
      "-" +
      randomNumber +
      ".pdf";
    eData["attach"] = "N";
    if (this.hasAttch) {
      eData["attach"] = "Y";
    }

    var docName = "";
    if (blType == "DraftBL") {
      docName = "BLDraft";
    } else if (blType == "CopyBL") {
      docName = "BLCopy";
    } else if (blType == "OriginalBL") {
      docName = "BLOriginal";
    }

    var isPackage = "Cargo";
    var surcharge = "N";
    if (this.officeCode.startsWith("ID")) {
      if (!this.hasOuter) {
        isPackage = "Cont";
      }
    } else if (this.officeCode.startsWith("TH")) {
      surcharge = "Y";
    }

    if (wTemplate) {
      //eData["url"]= "http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/BL/"+docName+"TemplateNS.pdf?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo="+this.model.blNo.toUpperCase()+"&isPackage=Cargo&surcharge=N&weight=KG&page=1";
      if (this.officeCode == "SGSIN") {
        eData["url"] =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/BL/" +
          docName +
          "TemplateNS.pdf?userCreated=" +
          this.userId +
          "&officeCode=" +
          this.officeCode +
          "&blNo=" +
          this.model.blNo.toUpperCase() +
          "&wContSpace=" +
          wContSpace +
          "&isPackage=" +
          isPackage +
          "&isExchRate=" +
          this.isExch +
          "&surcharge=" +
          surcharge +
          "&weight=KG&page=1&printFrom=" +
          this.printFrom;
      } else {
        eData["url"] =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/BL/" +
          docName +
          "TemplateNS.pdf?userCreated=" +
          this.userId +
          "&officeCode=" +
          this.officeCode +
          "&blNo=" +
          this.model.blNo.toUpperCase() +
          "&wContSpace=" +
          wContSpace +
          "&isPackage=" +
          isPackage +
          "&isExchRate=" +
          this.isExch +
          "&surcharge=" +
          surcharge +
          "&weight=KG&page=1";
      }
    } else {
      var jasperDefaultPath = "";
      var jasperUrlPath = "";
      if (this.officeCode == "SGSIN") {
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/BL/" +
          docName +
          "NS.pdf?userCreated=" +
          this.userId +
          "&officeCode=" +
          this.officeCode +
          "&blNo=" +
          this.model.blNo.toUpperCase() +
          "&wContSpace=" +
          wContSpace +
          "&isPackage=" +
          isPackage +
          "&isExchRate=" +
          this.isExch +
          "&surcharge=" +
          surcharge +
          "&weight=KG&page=1&printFrom=" +
          this.printFrom;
      } else {
        jasperDefaultPath =
          this.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
          this.officeCode +
          "/BL/" +
          docName +
          "NS.pdf?userCreated=" +
          this.userId +
          "&officeCode=" +
          this.officeCode +
          "&blNo=" +
          this.model.blNo.toUpperCase() +
          "&wContSpace=" +
          wContSpace +
          "&isPackage=" +
          isPackage +
          "&isExchRate=" +
          this.isExch +
          "&surcharge=" +
          surcharge +
          "&weight=KG&page=1";
      }

      if (this.jasper.jasperPathList.length > 0) {
        this.jasper.jasperPathList.forEach((xe) => {
          if (xe["reportPolCode"] == "*") {
            if (xe["reportUid"] == this.userIdCode) {
              jasperUrlPath =
                this.configService.config.jasperUrl +
                "/jasperserver" +
                xe["reportJasperPath"] +
                xe["reportName"] +
                "." +
                xe["reportType"];
            }
          }
        });
      }

      if (jasperUrlPath == "") {
        //jasperUrlPath = jasperDefaultPath;
        eData["url"] = jasperDefaultPath;
      } else {
        if (this.officeCode == "SGSIN") {
          eData["url"] =
            jasperUrlPath +
            "?userCreated=" +
            this.userId +
            "&officeCode=" +
            this.officeCode +
            "&blNo=" +
            this.model.blNo.toUpperCase() +
            "&wContSpace=" +
            wContSpace +
            "&isPackage=" +
            isPackage +
            "&isExchRate=" +
            this.isExch +
            "&surcharge=" +
            surcharge +
            "&weight=KG&page=1&printFrom=" +
            this.printFrom;
        } else {
          eData["url"] =
            jasperUrlPath +
            "?userCreated=" +
            this.userId +
            "&officeCode=" +
            this.officeCode +
            "&blNo=" +
            this.model.blNo.toUpperCase() +
            "&wContSpace=" +
            wContSpace +
            "&isPackage=" +
            isPackage +
            "&isExchRate=" +
            this.isExch +
            "&surcharge=" +
            surcharge +
            "&weight=KG&page=1";
        }
      }

      /*if(this.officeCode == 'SGSIN'){
        eData["url"] = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/" + this.officeCode + "/BL/" + docName + "NS.pdf?userCreated=" + this.userId + "&officeCode=" + this.officeCode + "&blNo=" + this.model.blNo.toUpperCase() + "&isPackage=" + isPackage + "&isExchRate=" + this.isExch + "&surcharge=" + surcharge + "&weight=KG&page=1&printFrom="+this.printFrom;

      }else{
      //eData["url"]= "http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/BL/"+docName+"NS.pdf?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo="+this.model.blNo.toUpperCase()+"&isPackage=Cargo&surcharge=N&weight=KG&page=1";
        eData["url"] = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/" + this.officeCode + "/BL/" + docName + "NS.pdf?userCreated=" + this.userId + "&officeCode=" + this.officeCode + "&blNo=" + this.model.blNo.toUpperCase() + "&isPackage=" + isPackage + "&isExchRate=" + this.isExch + "&surcharge=" + surcharge + "&weight=KG&page=1";
      }*/
    }

    eDataList.push(eData);

    postData["blData"] = eDataList;

    console.log('POST DATA == ' , postData)
    console.log('HIT URL == ' , hitUrl)



    this.genericService.post(hitUrl, postData).subscribe(
      (resp) => {
        console.log("RESPONSSE", resp);
        if (resp.ok) {
          var result: any = resp.json()["content"];

          result.forEach((fe) => {
            if (fe["message"] == "success") {
              //this.pdfContainer.setPDFURL("http://glossys.samudera.com"+fe["filePath"]+"#view=FitH");
              this.pdfContainer.setPDFURL(
                this.configService.config.pdfUrl + fe["filePath"] + "#view=FitH"
              );
              this.genericUtil.hideLoader();
              this.printLoading = false;
              this.pdfContainer.showPDF();
            }
          });
        }
      },
      (error) => {
        this.genericUtil.hideLoader();
        this.printLoading = false;
        this.blPrintDialogMessage = "Please choose B/L type to Print ya.";
        this.genericUtil.showDialog(
          "blPrintDialogMessage",
          "Information",
          350,
          180
        );
      }
    );
  }

  eventPDFPlugin(event) {}

  changeEventExchangeRateDate(event) {
    console.log("** changeEventExchangeRateDate");
    console.log(event);
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
  }

  changeEventExchangeRateDateConsole(event) {
    console.log("** changeEventExchangeRateDateConsole");
    console.log(event);
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
  }

  changeEventShippedOnBoard(event) {
    console.log("** changeEventShippedOnBoard");
    console.log(event.target.value);
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    this.model.blExtraShippedOnBoard = this.dateBlNettChargeShippedOnBoard.getValue();
    console.log("this.model.blExtraShippedOnBoard Event Change : " +  this.dateBlNettChargeShippedOnBoard.getValue());
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.includes('datepick-')) {
      var str = event.replace('datepick-','');
      this.model.blExtraShippedOnBoard = str;
      console.log("this.model.blExtraShippedOnBoard Event Change : " + str);
    }
  }

  changeEventDateOfIssue(event) {
    console.log("** changeEventDateOfIssue");
    console.log(event);
    console.log("this.isRefreightProcess : " + this.isRefreightProcess);
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.includes('datepick-')) {;

      var str = event.replace('datepick-','');
      console.log("this.model.blDateOfIssue Event Change : " +str);
      this.model.blDateOfIssue = str;

      // if (str == null || str === "" || str === "undefined") {
      //   this.dateIssueforCbShipper = '*';
      // } else {
      //   this.dateIssueforCbShipper = str;
      // }
      this.cbBlPartyShipper.setUrl(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findByComboBoxControlGeneral/shipper/"+this.dateIssueforCbShipper+"/"+this.custIdforCbShipper+"/customerName={query}");

      this.cbBlPartyConsignee.setUrl(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findByComboBoxControlGeneral/consignee/"+this.dateIssueforCbShipper+"/"+this.cneeIdforCbCnee+"/customerName={query}");

      this.cbBlPartyNotifyParty.setUrl(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findByComboBoxControlGeneral/notify_party/"+this.dateIssueforCbShipper+"/"+this.partyIdforCbParty+"/customerName={query}");
    }
  }

  changeEventDateOfIssueConsole(event) {
    console.log("** changeEventDateOfIssueConsole");
    console.log(event);
    console.log("this.isRefreightProcess : " + this.isRefreightProcessConsole);
    if (this.isRefreightProcessConsole == false) {
      this.isRefreightButtonClickedConsole = false; // another stupid reason
    }
  }

  IsLockPrepaidCollect(pc: string): Boolean {
    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (
        let index = 0;
        index < this.gridBlNettCharge.listStore.store.length;
        index++
      ) {
        let element = this.gridBlNettCharge.listStore.store[index];
        if (element.blFCPc == pc) {
          if (
            element.blFCFreightReference != undefined &&
            element.blFCFreightReference != ""
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  IsLockPayer(pyr: string): Boolean {
    if (this.gridBlNettCharge.listStore.store.length > 0) {
      for (
        let index = 0;
        index < this.gridBlNettCharge.listStore.store.length;
        index++
      ) {
        let element = this.gridBlNettCharge.listStore.store[index];
        if (element.blFCPayer == pyr) {
          if (
            element.blFCFreightReference != undefined &&
            element.blFCFreightReference != ""
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  NumDotOnly(event: any) {
    var regexPattern = new RegExp("[0-9.]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteEvent(event: any, type: string) {
    //console.log(event);
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^0-9.]/g, "");

    // GOS-448  Unable to paste value into HS CODE field
    event.preventDefault();
    this.cbBlAdContainerHsCode.setValue(pastedText);

    if (type == "blDescDg2") {
      this.paramDesc.blDescDg2 = pastedText;
    } else if (type == "blDescDg2R") {
      this.paramDesc.blDescDg2R = pastedText;
    } else if (type == "blDescAwkward") {
      this.paramDesc.blDescAwkward = pastedText;
    } else if (type == "blAdContainerHsCode"){
      this.paramBlAdContainer.blAdContainerHsCode = pastedText;
    }
  }

  errorVesVoy(event: any, field: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.formValidation.regVesVoy.test(pastedText) == false) {
      return (this.model[`error-${field}`] =
        "Only allowed special characters .-/");
    } else {
      return (this.model[`error-${field}`] = "");
    }
  }

  onPasteError(event: any, field: string, regex: RegExp, errorText: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regex.test(pastedText) == false) {
      return (this.model[`error-${field}`] = `Only allow ${errorText}`);
    } else {
      return (this.model[`error-${field}`] = "");
    }
  }

  checkIfError(field: string, regex: RegExp = this.formValidation.regVesVoy) {
    if (regex.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }
  }

  showDialog(id: string, options: any) {
    $("#" + id)
      .dialog(options)
      .dialog("open");
  }

  closeDialog(id: string) {
    $("#" + id).dialog("close");
    $("#" + id).dialog("destroy");
  }

  validateShipper(event) {
   // this.formValidation.ModifiedPattern(event, "\/\s\&\'-");
    if(this.model.blType == "MEMO" && this.model.blContainerOwnership == "COCTS"){
      this.formValidation.ModifiedPattern(event, "\/\s\&\'-()");
    }else if(this.officeCode == 'SGSIN' && (this.model.blContainerOwnership == "SOC"||this.model.blContainerOwnership == "SOCTS"||this.model.blContainerOwnership == "SLOT")){//GSO 797
        this.formValidation.ModifiedPattern(event, "\/\s\&\'-*");//GSO 797
    }else{
      this.formValidation.ModifiedPattern(event, "\/\s\&\'-");
    }

  }

  getValidationPayer(paramPayer: string) {
    console.log("getValidationPayer: "+paramPayer);

    var searchPayer = "";
    if (paramPayer == "payer1") {
      searchPayer = this.custIdForCbPayer1;
    } else if (paramPayer == "payer2") {
      searchPayer = this.custIdForCbPayer2;
    } else if (paramPayer == "payer3") {
      searchPayer = this.custIdForCbPayer3;
    }

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findByComboBoxControlGeneral/payer/*/"+searchPayer+"/customerId="+searchPayer
      )
      .subscribe((resp) => {
        var xs = resp.json()["content"][0];

        if (paramPayer == "payer1") {
          this.payer1IsPayer = xs.isPayer;
          this.payer1IsValid = xs.isValid;
        } else if (paramPayer == "payer2") {
          this.payer2IsPayer = xs.isPayer;
          this.payer2IsValid = xs.isValid;
        } else if (paramPayer == "payer3") {
          this.payer3IsPayer = xs.isPayer;
          this.payer3IsValid = xs.isValid;
        }

        console.log(xs);
        console.log("this.payer1IsPayer: "+this.payer1IsPayer);
        console.log("this.payer2IsPayer: "+this.payer2IsPayer);
        console.log("this.payer3IsPayer: "+this.payer3IsPayer);
      });
  }

  checkValidationPayer(paramPayer: string) {
    console.log("checkValidationPayer: "+paramPayer);
    if (paramPayer == "1") {
      if (this.model.blPayer1Code != this.custIdForCbPayer1) {
        if (this.payer1IsPayer == "N") {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCPayer"] = "Customer record is no longer set as a Payer, unable to proceed with creation";
        }
      }
    } else if (paramPayer == "2") {
      if (this.model.blPayer2Code != this.custIdForCbPayer2) {
        if (this.payer2IsPayer == "N") {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCPayer"] = "Customer record is no longer set as a Payer, unable to proceed with creation";
        }
      }
    } else if (paramPayer == "3") {
      if (this.model.blPayer3Code != this.custIdForCbPayer3) {
        if (this.payer3IsPayer == "N") {
          this.isErrorNettChargeEntry = true;
          this.paramFreightCharge["error-blFCPayer"] = "Customer record is no longer set as a Payer, unable to proceed with creation";
        }
      }
    }

  }

  setValidatorAdContainer() {
    this.paramBlAdContainer["error-blContainerNumber"] = "";
    // this.validatorRules = {
    //   blAdContainerCommodityTypeId: {
    //     rules: [
    //       {
    //         type: "empty",
    //         prompt: "Please input Commodity.",
    //       },
    //     ],
    //   },
    // };
  }

  setValidatorAdContainerInnerQty(): Boolean {
    let result: Boolean = false;
    if(this.paramBlAdContainer.blAdContainerInnerQty != null){
      if (this.paramBlAdContainer.blAdContainerInnerQty <= 0 ) {
        this.paramBlAdContainer["error-blAdContainerInnerQty"] = "Please input Inner Quantity.";
        result = true;
      }else{
        this.paramBlAdContainer["error-blAdContainerInnerQty"] = "";
        result = false;
      }
    }else{
        this.paramBlAdContainer["error-blAdContainerInnerQty"] = "Please input Inner Quantity.";
        result = true;
    }
    return result;
  }

  setValidatorAdContainerInnerPkg(){
    let result: Boolean = false;
    if(!this.paramBlAdContainer['blAdContainerInnerPkgTypeCode']){
      this.paramBlAdContainer['error-blAdContainerInnerPkgTypeCode'] = "Please input Inner Package Type.";
      result = true;
    }else{
      this.paramBlAdContainer["error-blAdContainerInnerPkgTypeCode"] = "";
      result = false;
    }
    return result;
  }

  setValidatorAdContainerCommodity(){
    let result: Boolean = false;
    if(!this.paramBlAdContainer['blAdContainerCommodityTypeName']){
      this.paramBlAdContainer['error-blAdContainerCommodityTypeId'] = "Please input Commodity.";
      result = true;
    }else{
      this.paramBlAdContainer["error-blAdContainerCommodityTypeId"] = "";
      result = false;
    }
    return result;
  }

  setValidatorAdContainerGrossWeight(): Boolean {
    let result: Boolean = false;
    if(this.paramBlAdContainer.blAdContainerGrossWeightString == '0' || this.paramBlAdContainer.blAdContainerGrossWeightString == '' ||
      this.paramBlAdContainer.blAdContainerGrossWeightString == null
    )
    {
      this.paramBlAdContainer["error-blAdContainerGrossWeightString"] = "Please input Weight (KGS)";
      result = true;
    }else{
      this.paramBlAdContainer["error-blAdContainerGrossWeightString"] = "";
      result = false;
    }
    return result;
  }

  setValidatorAdContainerGrossMeasure(): Boolean {
    let result: Boolean = false;
    if(this.paramBlAdContainer.blAdContainerGrossMeasureString == '0' || this.paramBlAdContainer.blAdContainerGrossMeasureString == '' || this.paramBlAdContainer.blAdContainerGrossMeasureString == null) {
      this.paramBlAdContainer["error-blAdContainerGrossMeasureString"] = "Please input Measurement (CBM).";
      result = true;
    }else{
      this.paramBlAdContainer["error-blAdContainerGrossMeasureString"] = "";
      result = false;
    }
    return result;
  }

  setValidatorAdContainerFlashPointScale(): Boolean {
    let result: Boolean = false;
    if (this.paramBlAdContainer.blAdContainerFlashPoint != 0.0 &&
      this.paramBlAdContainer.blAdContainerFlashPointScale == '' ) {
    // if(this.paramBlAdContainer.blAdContainerGrossMeasureString == '0' || this.paramBlAdContainer.blAdContainerGrossMeasureString == null) {
      this.paramBlAdContainer["error-blAdContainerFlashPointScale"] = "Please input f/p scale.";
      result = true;
    }else{
      this.paramBlAdContainer["error-blAdContainerFlashPointScale"] = "";
      result = false;
    }
    return result;
  }

  CharNumOnly(event: any) {
    if(event.key == "Enter"){
     this.paramBlAdContainer["error-blAdContainerCommodityTypeId"] = "";
     event.preventDefault();

    }

  }

  parseDecimal(val){
    // let v = parseFloat(Number(val)
    // .toString()
    // .replace(/^0+/, ''))
    // .toFixed(4);
    let v = parseFloat(Number(val).toFixed(4)).toString();
    v = isNaN(parseFloat(v)) ? '0': v;
    v = isNaN(parseFloat(v)) ? '0.0000': v;
    return v;
  }

  // onPaste(ev: any) {
  //   console.log("on paste fired");
  //   console.log(ev);
  //   this.txtBlDescDescriptionOfGoods.setValue('');
  //   let clipboardData = ev.clipboardData;
  //   console.log(clipboardData.getData('text').match(/\b[\w\s]{32,}?(?=\s)|.+$/g));
  //   const pastedTextList = clipboardData.getData('text').match(/\b[\w\s]{32,}?(?=\s)|.+$/g);
  //   console.log("pastedTextList");
  //   console.log(pastedTextList);

  //   let finalText = '';
  //   pastedTextList.forEach(dt => {
  //     finalText += dt.trim()+ '↵';
  //   });
  //   setTimeout(() => {
  //     this.txtBlDescDescriptionOfGoods.setValue(finalText);
  //   }, 500);
  // }

  onPasteNew(ev: any) {
    let pastedText = ev.clipboardData.getData('text').split(/\r?\n/);

    let finalText = '';
    let newLineText = [];
    pastedText.forEach((dt) => {
      newLineText = dt.match(/\b[\w\s]{32,}?(?=\s)|.+$/g);
      if (newLineText.length > 0) {
        newLineText.forEach((nt) => {
          finalText += nt.trim() + '↵';
        });
      } else {
        finalText += dt.trim() + '↵';
      }
    });

    finalText = this.txtBlDescDescriptionOfGoods.getValue() + finalText;

    setTimeout(() => {
      this.txtBlDescDescriptionOfGoods.setValue(finalText);
    }, 500);
  }


  onDialogIasOk() {
    this.genericUtil.closeDialog('dialogIas');
  }

  onDialogIasErrorOk() {
    this.genericUtil.closeDialog('dialogIasError');
  }

  onPasteDecimalFilter(event: any, modelFunc: any, field: any) {
    const regDecimal4: RegExp = /^[0-9]{0,10}(\.\d{0,4})*$/;
    var val = modelFunc[field];
    modelFunc[field] = '';
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regDecimal4.test(pastedText) == false) {
      event.preventDefault();
      modelFunc[field] = val;
      this.showTooltip = 'Invalid value, Max 10 number of char';
    }else{
      this.showTooltip = '';
    }
  }


  keypressDeclaredValue(event: any) {
    const regexPattern = /[0-9,.]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  onAdhoc() {
    const params = this.model.blFreightCharge.map(e => ({
      blFCChargeCode: e.blFCChargeCode,
      blFCContainerType: e.blFCContainerType,
      blFCPerCode: e.blFCPerCode,
      qty: e.blFCRevenue
    }));

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        "/AdhocMasterCharges/findBLAdhocCharges", params
      ).subscribe((resp) => {
        if (resp.ok) {
          var result = resp.json();
          this.formatBlCharge(result)
          this.gridBlNettCharge.loadData();
        } else {
          this.dialogPlugin.show('information', 'Information', 'Error occurred!', 'okonly', { ok: 'this.loading=false;' });
        }
      })
  }

  formatBlCharge(result) {
    for (let i = 0; i < result.length; i++) {
      const dataNettCharge = new BLFreightCharge();
      dataNettCharge.blFCOfficeCode = this.model.blOfficeCode;
      dataNettCharge.blFCBlNo = this.model.blNo;
      //dataNettCharge.blFCSeq = result[i].blFCSeq;
      //re-order the sequence upon retrieval (bug blf from miki 12-feb-2019
      dataNettCharge.blFCSeq = i + 1;

      dataNettCharge.blFCChargeCode = result[i].blFCChargeCode;
      dataNettCharge.blFCRevenue = result[i].blFCRevenue;
      dataNettCharge.blFCRate = result[i].blFCRate;
      dataNettCharge.blFCPerCode = result[i].blFCPerCode;
      dataNettCharge.blFCCurrencyCode =
        result[i].blFCCurrencyCode;
      dataNettCharge.blFCAmount = result[i].blFCAmount;
      dataNettCharge.blFCPc = result[i].blFCPc;
      dataNettCharge.blFCPlaceOfPaymentCode =
        result[i].blFCPlaceOfPaymentCode;

      dataNettCharge.blFCPlaceOfPaymentName =
        result[i].blFCPlaceOfPaymentName;

      dataNettCharge.blFCPayer = result[i].blFCPayer;
      dataNettCharge.blFCContainerType =
        result[i].blFCContainerType;
      dataNettCharge.blFCIsEntered = result[i].blFCIsEntered;

      // is manifested handler
      if (result[i].blFCIsManifestedFreight === "Y") {
        dataNettCharge.blFCIsManifestedFreight = true;
        dataNettCharge.blFCIsManifestedFreightString = "Y";
      } else {
        dataNettCharge.blFCIsManifestedFreight = false;
        dataNettCharge.blFCIsManifestedFreightString = "N";
      }

      // is charge printed handler
      if (result[i].blFCIsChargePrinted === "Y") {
        dataNettCharge.blFCIsChargePrinted = true;
        dataNettCharge.blFCIsChargePrintedString = "Y";
      } else {
        dataNettCharge.blFCIsChargePrinted = false;
        dataNettCharge.blFCIsChargePrintedString = "N";
      }

      // is charge fai group
      if (result[i].blFCFaiGroup === "Y") {
        dataNettCharge.blFCFaiGroup = true;
        dataNettCharge.blFCFaiGroupString = "Y";
      } else {
        dataNettCharge.blFCFaiGroup = false;
        dataNettCharge.blFCFaiGroupString = "N";
      }

      // is entered
      if (result[i].blFCIsEntered === "Y") {
        dataNettCharge.blFCIsEntered = true;
        dataNettCharge.blFCIsEnteredString = "Y";
      } else {
        dataNettCharge.blFCIsEntered = false;
        dataNettCharge.blFCIsEnteredString = "N";
      }

      dataNettCharge.blFCFreightReference =
        result[i].blFCFreightReference;

      this.gridBlNettCharge.listStore.store.push(dataNettCharge);
    }
  }

  keypressPackageType(event, regexPattern) {
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    } else {
      this.checkIsErrorDesc();
    }
  }

  onPasteValidationPackageType(event, field, regexPattern) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.testRegexPackageType(regexPattern, pastedText, field);
  }

  backspacePackageType(event, field, regexPattern) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.testRegexPackageType(regexPattern, event.target.value, field);
        break;
      default:
        return
    }
  }

  testRegexPackageType(regexPattern, value, field) {
    let messageError = "";
    switch (field) {
      case 'cbBlDescInnerPkgType':
        messageError = `Inner Package Type field allow enter character: a-z, A-Z, , (comma), - (dash)`;
        break;
      case 'cbBlDescPSAPackageType':
        messageError = `PSA Package Type field allow enter character: a-z, A-Z, , (comma), - (dash)`;
        break;
      case 'cbBlDescOuterPkgType':
        messageError = `Outer Package Type field allow enter character: a-z, A-Z, , (comma), - (dash)`;
        break;
      case 'cbBlDescReceiptType':
        messageError = `Receipt Type field allow enter alphabets`;
          break;
      case 'cbBlDescDeliveryType':
        messageError = `Delivery Type field allow enter alphabets`;
          break;
      case 'cbBlDescCommodityCode':
        messageError = `Commodity Type field allow enter alphabets`;
          break;
      default:
      // default statements
    }
    if (regexPattern.test(value) == false) {
      this.isErrorDesc = true;
      this.paramDesc[`error-${field}`] = messageError;
    } else {
      this.paramDesc[`error-${field}`] = "";
      if(field === 'cbBlDescInnerPkgType'){
        // this.checkMissingInnerPackageType();
      }
    }
    this.checkIsErrorDesc();

  }

  checkIsErrorDesc() {
    if (
      !this.paramDesc['error-cbBlDescInnerPkgType'] &&
      !this.paramDesc['error-cbBlDescPSAPackageType'] &&
      !this.paramDesc['error-cbBlDescOuterPkgType'] &&
      !this.paramDesc['error-cbBlDescReceiptType'] &&
      !this.paramDesc['error-cbBlDescDeliveryType'] &&
      !this.paramDesc['error-cbBlDescCommodityCode'] &&
      !this.paramDesc["error-cbBlDescInnerPkgType"]
    ) {
      this.isErrorDesc = false;
    } else {
      this.isErrorDesc = true;
    }
  }

  checkMissingInnerPackageType(){
    if((this.paramDesc.blDescInnerQuantity || this.paramDesc.blDescInnerQuantity === 0) && this.paramDesc.blDescInnerPackageTypeCode != '') {
      this.isErrorDesc = true;
      this.paramDesc["error-cbBlDescInnerPkgType"] =
        "Missing Inner Package Type, Please search and select value";
    }else{
      this.paramDesc["error-cbBlDescInnerPkgType"] = "";
      this.checkIsErrorDesc();
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = '',
    acctDebtorCode?: string
  ) {
    let clipboardData = acctDebtorCode ? '' : event.clipboardData;
    let pastedText = acctDebtorCode ? event : clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar);
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar) {
    if (regexName.test(pastedText) == false) {
      if (field === 'cbBlNettChargeBillingParty1' || field === 'cbBlNettChargeBillingParty2' || field === 'cbBlNettChargeBillingParty3') {
        this.model[`error-${field}`] = `Only accept alphanumeric and -'`;
        return;
      }
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;

    } else {
      this.model[`error-${field}`] = "";
    }
  }

  validateObls($event, className = 'num') {
    $(`.${className}`).keypress(function () {
      if ($(this).val().length == $(this).attr("maxlength")) {
        return false;
      }
    });
  }

  keyPressObls($event) {
    var regexPattern = /^[0-6]*$/;
    console.log($event, regexPattern.test($event.key));

    if (regexPattern.test($event.key) == false) {
      $event.preventDefault();
    }
  }

  getDetailCustomer(custId) {
    let resultBack = new Map();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByComboBoxControlGeneral/payer/*/" +
          custId +"/"+
          "customerId=" +
          custId
      )
      .subscribe((resp) => {
        const data = resp.json();
        if (data.status === "OK") {
          if (resp.json()["content"][0] != undefined) {
            resultBack.set('dateModified', resp.json()["content"][0].dateModified);
            resultBack.set('isValid', resp.json()["content"][0].isValid);
            resultBack.set('isPayer', resp.json()["content"][0].isValid);
            resultBack.set('customerName', resp.json()["content"][0].customerName);
            // return resultBack;
          }
        }
      });

      return resultBack;
  }

  validateBeforeSaveNew() {
    var timeCreatedBL = this.dateToTime(this.model.blDateCreated);
    var msgValidator = "";
    for (var i=0;i<this.listPyrFromRefreight.length;i++) {
      var data = this.getDetailCustomer(this.listPyrFromRefreight[i]);
      if (data.get('dateModified') && data.get('dateModified').length > 0) {
        var custDateModified = this.dateToTime(data.get('dateModified'));
        console.log("custName : "+ data.get("customerName"));
        console.log("dateModified : "+ data.get("dateModified"));
        console.log("this.model.blDateCreated : "+this.model.blDateCreated);
        console.log("timeCreatedBL: "+ timeCreatedBL);
        console.log("custDateModified : "+ custDateModified);
        if (custDateModified < timeCreatedBL) {
          if (data.get("isValid") == "N") {
            if (msgValidator != "") {
              msgValidator = msgValidator+"<br>"+data.get("customerName")+" is invalid, unable to proceed with creation";
            } else {
              msgValidator = data.get("customerName")+" is invalid, unable to proceed with creation";
            }

          } else if (data.get("isPayer") == "N") {
            if (msgValidator != "") {
              msgValidator = msgValidator+"<br>"+data.get("customerName")+" no longer set as a Payer, unable to proceed with creation";
            } else {
              msgValidator = data.get("customerName")+" no longer set as a Payer, unable to proceed with creation";
            }
          }
        }
      }
    }

    if (msgValidator != "") {
      if (this.model["error-payerValidator"] != "") {
        this.model["error-payerValidator"] = this.model["error-payerValidator"]+"<br>"+msgValidator;
      } else {
        this.model["error-payerValidator"] = msgValidator;
      }

      this.isError = true;
    }

  }

  dateToTime(date) {
    var d = new Date(date);
    return d.getTime();
  }

  validateExchage(event) {
    if (event.which == '13' || event.key == '-') {
      event.preventDefault();
    }
  }

  onPaste(event) {
    let clipboardData = event.clipboardData;
    let pastedText: any = clipboardData.getData('text');

    if (pastedText[0] === '-') {
      event.preventDefault();
      this.paramExchangeRate['blExchangeRate'] = pastedText.search('.') != -1 ? pastedText.slice(1) : parseFloat(pastedText.slice(1)).toFixed(4);
      console.log(this.paramExchangeRate['blExchangeRate']);
    }

  //   const reg4Digit4Decimal = /^\d{0,4}(?:\.\d{0,4})?$/;
  //   if (event && event >= 10000) {
  //     this.messageErrorExchangeRate = "Only accept digits less than 10000";
  //   }
  //   else if (event && reg4Digit4Decimal.test(event) === false) {
  //     this.messageErrorExchangeRate = "Only accept 4 digit, with 4 maximum decimal point";
  //   }
  //   else {
  //     this.messageErrorExchangeRate = null;
  //   }
  // }
  }

  parseCommaSeparatorNumber(str) {
    return Number.parseFloat(str.replace(/,/g, ''));
  }

  ngOnDestroy(){
    localStorage.removeItem("navigateFromTransactionBlFlagStatus");
  }

  setPayerAddress1(code, name) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    this.IsLockPayer1 = false;
    this.model.blPayer1Code = code;
    this.model.blPayer1Name = name;
    this.cbBlNettChargeBillingParty1Address.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findCustomerAddressByCustomerId/" +
      code.trim() +
      "/Y"
    );
    if (!this.isRefreightProcess) {
      console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
      const self = this;
      this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
        console.log(self.cbBlNettChargeBillingParty1Address.listStore.store);
        if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
          console.log(self.cbBlNettChargeBillingParty1Address.listStore.store);
          self.cbBlNettChargeBillingParty1Address.setValue(
            self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
          );

          self.model.blExtraPayer1nameAddressOption =
            self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer1nameAddressName =
            self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
        } else {
          self.model.blExtraPayer1nameAddressOption = 0;
          self.model.blExtraPayer1nameAddressName = "";
        }
      });
    } else {
      if (this.model.blExtraPayer1nameAddressName == "" || this.model.blExtraPayer1nameAddressName == undefined) {
        console.log("AFTER REFREIGHT : " + this.isRefreightProcess);
        const self = this;

        this.cbBlNettChargeBillingParty1Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty1Address.listStore.store.length > 0) {
            console.log(self.cbBlNettChargeBillingParty1Address.listStore.store);
            self.cbBlNettChargeBillingParty1Address.setValue(
              self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqAndDisplay"]
            );
            self.model.blExtraPayer1nameAddressOption =
              self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer1nameAddressName =
              self.cbBlNettChargeBillingParty1Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer1nameAddressOption = 0;
            self.model.blExtraPayer1nameAddressName = "";
          }
          console.log(self.cbBlNettChargeBillingParty1Address.listStore.store);
        });
      }
    }
  }

  setPayerAddress2(code, name) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    this.IsLockPayer2 = false;
    this.model.blPayer2Code = code;
    this.model.blPayer2Name = name;
    this.cbBlNettChargeBillingParty2Address.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findCustomerAddressByCustomerId/" +
      code.trim() +
      "/Y"
    );
    if (!this.isRefreightProcess) {
      const self = this;
      this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
        if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
          self.cbBlNettChargeBillingParty2Address.setValue(
            self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
          );

          self.model.blExtraPayer2nameAddressOption =
            self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer2nameAddressName =
            self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
        } else {
          self.model.blExtraPayer2nameAddressOption = 0;
          self.model.blExtraPayer2nameAddressName = "";
        }
      });
    } else {

      if (this.model.blExtraPayer2nameAddressName == "" || this.model.blExtraPayer2nameAddressName == undefined) {
        console.log("HERE2 = " + this.model.blExtraPayer2nameAddressName);
        const self = this;
        this.cbBlNettChargeBillingParty2Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty2Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty2Address.setValue(
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqAndDisplay"]
            );
            self.model.blExtraPayer2nameAddressOption =
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer2nameAddressName =
              self.cbBlNettChargeBillingParty2Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer2nameAddressOption = 0;
            self.model.blExtraPayer2nameAddressName = "";
          }
        });
      }
    }
  }

  setPayerAddress3(code, name) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }
    this.IsLockPayer3 = false;
    this.model.blPayer3Code = code;
    this.model.blPayer3Name = name;
    this.cbBlNettChargeBillingParty3Address.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findCustomerAddressByCustomerId/" +
      code.trim() +
      "/Y"
    );
    if (!this.isRefreightProcess) {
      const self = this;
      this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
        if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
          self.cbBlNettChargeBillingParty3Address.setValue(
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
          );

          self.model.blExtraPayer3nameAddressOption =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
          self.model.blExtraPayer3nameAddressName =
            self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
        } else {
          self.model.blExtraPayer3nameAddressOption = 0;
          self.model.blExtraPayer3nameAddressName = "";
        }
      });
    } else {
      if (this.model.blExtraPayer3nameAddressName == "" || this.model.blExtraPayer3nameAddressName == undefined) {
        const self = this;
        this.cbBlNettChargeBillingParty3Address.loadSelect(function () {
          if (self.cbBlNettChargeBillingParty3Address.listStore.store.length > 0) {
            self.cbBlNettChargeBillingParty3Address.setValue(
              self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqAndDisplay"]
            );
            self.model.blExtraPayer3nameAddressOption =
              self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerSeqno"];
            self.model.blExtraPayer3nameAddressName =
              self.cbBlNettChargeBillingParty3Address.listStore.store[0]["customerDisplayName"];
          } else {
            self.model.blExtraPayer3nameAddressOption = 0;
            self.model.blExtraPayer3nameAddressName = "";
          }
        });
      }
    }
  }

  handleValidateSpecialCharacter() {
    this.lineInvalid = [];
    const shipperAddress = this.txtBlPartyShipperAddress.getValue();
    const consigneeAddress = this.txtBlPartyConsigneeAddress.getValue();
    const notifyPartyAddress = this.txtBlPartyNotifyPartyAddress.getValue();
    const arrShipperAddress = shipperAddress.split(/\r?\↵/);
    const arrConsigneeAddress = consigneeAddress.split(/\r?\↵/);
    const arrNotifyPartyAddress = notifyPartyAddress.split(/\r?\↵/);
    const arr = [...arrShipperAddress, ...arrConsigneeAddress, ...arrNotifyPartyAddress];

    for (const str of arr) {
      const isValid = this.isASCII(str);
      const strArray = this.splitStringToArr(str);
      if (!isValid) {
        for (const word of strArray) {
          const isValidWord = this.isASCII(word);
          if (!isValidWord) {
            if (this.isControlCharactor(word)) {
              this.lineInvalid.push({
                isCC: true,
                message: `Invalid control character in line "${str}" detected`,
                line: str,
                word: word
              });
            } else {
              this.lineInvalid.push({
                isCC: false,
                line: str,
                word: word
              });
            }
          }
        }
      }
    }
  }

  isASCII(str) {
    if (typeof (str) !== 'string') {
      return false;
    }
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127) {
        return false;
      }
    }
    return true;
  }

  isControlCharactor(str) {
    for (var i = 0; i < str.length; i++) {
      if (str.charCodeAt(i) > 127 && str.charCodeAt(i) < 159) {
        return true;
      }
    }
    return false;
  }

  splitStringToArr(str: string) {
    const arr = str.split("");
    return arr;
  }

  showMessageInvalidCharacter(ele) {
    return `Invalid characters ${ele.word} in line "${ele.line}" detected`;
  }

  handleSync() {
    this.genericUtil.showLoader();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
        `/BL/synchTHRUBlByOfficeCodeAndBlNo/${this.model.blOfficeCode}/${this.model.thruOfficeCode}/${this.model.blNo}/${this.model.blThruBLRef}/${this.model.blOceanVesselId}/${this.model.blOceanVesselCode}/${this.model.blOceanVesselVoyage}/${this.model.blOceanVesselBound}`
      ).pipe(finalize(() => this.genericUtil.hideLoader()))
      .subscribe((resp) => {
        this.dialogPlugin.show(
          "information",
          "Information",
          "BL data has been updated successfully.",
          "okonly",
          { ok: "" }
        );
        const data = resp.json();
        this.setData(data, true);
      }, err => {
        this.dialogPlugin.show(
          "information",
          "Information",
          err.json().msg,
          "okonly",
          { ok: "" }
        );
      })
  }

  valiAlphanumericCIO(event){
    if (this.regexPatternAlphanumeric.test(event.key) == false) {
      event.preventDefault();
    }
  }

  eventPastecustomerInternalOrder(event: any, model: any, field: string, regex: string, lengthChar: Number) {
    let re = new RegExp(regex, "g");
    let pastedText = event.clipboardData.getData("text").replace(re, "");
    event.preventDefault();
    if (pastedText.length > lengthChar) pastedText = pastedText.slice(0, lengthChar);
    event.target.value = pastedText;
    model[field] = pastedText;
  }

  //inject text on print detention and demurrage clicked
  changeEventPrintDetDem(event){
    if (event.target.checked) {
      this.model.blIsDetDemPrinted = 'Y';

      console.log(this.gridBlDesc.listStore.store[0].blDescDescriptionTextClean);

      const detdemClause = 'DETENTION IS ' + this.model.blDetentionRequest + ' DAYS AND DEMURRAGE IS ' + this.model.blDemurrageRequest + ' DAYS';
      this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + detdemClause;
      this.gridBlDesc.loadData();

    } else {
      this.model.blIsDetDemPrinted = 'N';
    }
  }

  // inject text on print next vessel clause checkbox printed
  changeEventChBlPlacePrintNextVesselOnBL(event) {
    if (event.target.checked) {
      this.model.blNextVesselClauseIsPrinted = true;
      const nextVesselClause = 'CONNECTING ONTO ' + this.model.blNextVesselName + ' V.' + this.model.blNextVesselVoyage +
        (this.model.blPortOfTransshipment1Name !== '' ? ' AT ' + this.model.blPortOfTransshipment1Name: '');
      this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + nextVesselClause;
      this.gridBlDesc.loadData();
    } else {
      this.model.blNextVesselClauseIsPrinted = false;
    }
  }

  // inject text on shortshipment click
  changeEventChBlContainerPrintShortShipment(event) {
    console.log("debug shortshipment");
    console.log(event.target.checked);
    // asu bib
    if (event.target.checked) {
      console.log(event.target.checked);
      this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL = true;
      const shortshipmentList: Array<string> = [];
      if (this.gridBlContainer.listStore.store.length > 0) {
        let shortshipmet = "";
        for (let i = 0; i < this.gridBlContainer.listStore.store.length; i++) {
          if (
            this.gridBlContainer.listStore.store[i]
              .blContainerNumber == this.paramContainer.blContainerNumber &&
            this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL === true
          ) {
            if (
              this.paramContainer.blContainerShortShipmentFrom === true
            ) {
              shortshipmet =
                "*SHORTSHIPPED FROM " +
                this.paramContainer.blContainerShortShipmentVesselName +
                " V." +
                this.paramContainer.blContainerShortShipmentVesselVoyage;
            }
            if (
              this.paramContainer.blContainerShortShipmentTo === true
            ) {
              if (
                this.paramContainer
                  .blContainerShortShipmentVesselId !== ""
              ) {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
                shortshipmet +=
                  "\n*TO BE LOADED ON " +
                  this.paramContainer
                    .blContainerShortShipmentVesselName +
                  " V." +
                  this.paramContainer
                    .blContainerShortShipmentVesselVoyage;
              } else {
                shortshipmet =
                  "*SHORTSHIPPED FROM " +
                  this.model.blOceanVesselName +
                  " V." +
                  this.model.blOceanVesselVoyage;
              }
            }
          }
          if (!shortshipmentList.includes(shortshipmet)) {
            shortshipmentList.push(shortshipmet);
          }
        }
      }
      if (shortshipmentList.length > 0) {
        let shortshipClause = '';
        for (const entry of shortshipmentList) {
          shortshipClause += '↵' + entry;
        }
        console.log(shortshipClause);
        console.log(this.gridBlDesc.listStore.store[0].blDescDescriptionText);
        this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + shortshipClause;
        this.gridBlDesc.loadData();
        console.log(this.gridBlDesc.listStore.store[0].blDescDescriptionText);
      }
    } else {
      this.paramContainer.blContainerIsPrintShortShipmentInfoOnBL = false;
    }
  }

  // inject text on shipped on board checkbox click
  changeEventChBlNettChargePrintShippedOnBoard(event) {
    if (this.isRefreightProcess == false) {
      this.isRefreightButtonClicked = false; // another stupid reason
    }

    if (event.target.checked) {
      this.model.blIsPrintShippedOnBoard = true;
      const monString = ['','JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
      let issueDateString = '';
      if(this.model.blExtraShippedOnBoard != '') {
        issueDateString = this.model.blExtraShippedOnBoard.split('-')[2] + ', ' + monString[Number(this.model.blExtraShippedOnBoard.split('-')[1])] + ' ' + this.model.blExtraShippedOnBoard.split('-')[0];
      }else{
        issueDateString = this.model.blDateOfIssue.split('-')[2] + ', ' + monString[Number(this.model.blDateOfIssue.split('-')[1])] + ' ' + this.model.blDateOfIssue.split('-')[0];
      }
      const sob = 'SHIPPED ON BOARD ON ' + issueDateString;
      this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + sob;
      this.gridBlDesc.loadData();

    } else {
      this.model.blIsPrintShippedOnBoard = false;
    }
  }

  // Export and Importer tax number handler
  changeEventChBlExporterPrint(event) {
    if (event.target.checked) {
      this.model.blExporterTaxNumberPrint = true;
      const expTax = 'EXPORTER TAX REGISTRATION NUMBER : ' + this.model.blExporterTaxNumber;
      this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + expTax;
      this.gridBlDesc.loadData();
    } else {
      this.model.blExporterTaxNumberPrint = false;
    }
  }

  changeEventChBlImporterPrint(event) {
    if (event.target.checked) {
      this.model.blImporterTaxNumberPrint = true;
      const impTax = 'IMPORTER TAX REGISTRATION NUMBER : ' + this.model.blImporterTaxNumber;
      this.gridBlDesc.listStore.store[0].blDescDescriptionText = this.gridBlDesc.listStore.store[0].blDescDescriptionText + '↵' + impTax;
      this.gridBlDesc.loadData();
    } else {
      this.model.blImporterTaxNumberPrint = false;
    }
  }

}
