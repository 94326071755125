export class autoCreationBLModel{

    fileName:string="";
    pm4FileName:string="";
    fileType:string=""
    isCreateBL:boolean = false;    
    secondCarrier:string = "";
    messageValue = "";
    userCreated:string="";
    dateCreated:string="";
    officeCode:string="";

    constructor() {

    }
}