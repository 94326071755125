<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field" style="width:400px">
        <label>Place Of Creation:</label>
        <combo-plugin #cbPOC [settings]="settingPOC" (change)="changeEventPOC($event)" style="width:1400px;"></combo-plugin>
      </div>

      <div class="field" style="width:400px">
        <div class="field">
          <label>Vessel ETA FROM</label>
            <!--<calendar-plugin #dateLocalETA [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin>-->
            <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
            <!--
            <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-localEta'] != null && model['error-localEta'] != ''">
              {{model['error-localEta']}}
            </div>
            -->
          <div class="ui left icon input">
            <input id="fromDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date">
            <i class="calendar icon"></i>
          </div>
        </div>
        <div class="field">
          <label>Vessel ETA TO</label>
            <!--<calendar-plugin #dateLocalETD [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin>-->
            <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
           <!--
              <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-localEta'] != null && model['error-localEta'] != ''">
                {{model['error-localEta']}}
              </div>
           -->
            <div class="ui left icon input">
              <input id="toDate" type="text" class="form-control" data-toggle="datepicker" placeholder="date">
              <i class="calendar icon"></i>
            </div>

        </div>
      </div>

      <div class="field" style="width:400px">
        <label>Report Type :</label>
          <div class = "field">
            <!-- GOS-452 To Disable PDF Function, Applicable for SIN Site only.  -->
            <div class="ui radio checkbox" [ngClass]="officeCode === 'SG' ? 'disabledReportType' : ''">
              <input id="rType" type="radio" name="rType" value="pdf" (change)="eventRadio($event,'PDF')" [disabled]="officeCode === 'SG'">
              <label>PDF</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox" >
              <input id="rType" type="radio"  name="rType" value="xls" (change)="eventRadio($event,'XLS')">
              <label>Excel</label>
            </div>
          </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>

<pdf-plugin #dailyBookingProcessPDFContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin>
