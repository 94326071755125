import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Users, MenuManagement } from '../shared/index';
import { query } from '@angular/core/src/render3/query';
import { forEach } from '@angular/router/src/utils/collection';
import { element } from '@angular/core/src/render3/instructions';
import { concat } from 'rxjs/internal/operators/concat';
import { MenuList } from 'ag-grid-enterprise/dist/lib/menu/menuList';


declare  var $:any;

export class Location{
    location_id:string='';
    location_name:string='';
    location_code:string='';
    default:string='';

    //bikin user_location_id
    //masukin selected group ke map group
    //masukin selected menu ke map menu

    checkDefault:boolean=true;

    constructor(){};
}

export class Group{
    group_id:string='';
    group_name:string='';

    isSelect : string = '';
    key: string = '';

    constructor(){};
}

export class SelectedMenuModel {
    key:string = '';
    group_id:string = '';
    menu_id:string='';
    constructor(){};
}

@Component({
    selector: 'app-master-user-management-page',
    templateUrl: './master-user-management-page.component.html',
    styleUrls: ['./master-user-management-page.component.css']
})

export class MasterUserManagementPageComponent extends Validator  implements OnInit, AfterViewInit {
    @ViewChild('grid') grid:GridPluginComponent;
    @ViewChild('gridDetails') gridDetails:GridPluginComponent;
    @ViewChild('gridHistory') gridHistory:GridPluginComponent;
    @ViewChild('detailLocation') detailLocation:GridPluginComponent;
    @ViewChild('cbUserNameHistory') cbUserNameHistory:ComboPluginComponent;
    @ViewChild('cbLocation') cbLocation:ComboPluginComponent;

    /* Parameter for information into Footer */
    info = '';

    /* Parameter for dialog */
    dialog:any;

    /* Parameter for disable & invisible Toolbar buttons */
    disableToolbarButtons = "";
    invisibleToolbarButtons = "";

    /* Parameter modeForm (Default : false) */
    modeForm = false;

    /* parameter password (Default : false) */
    passwordForm = false;

    /* Parameter modeToolbar (Default : false) */
    modeToolbar = false;
    modeButton = false;

    /* Parameter form (Default : '') */
    form = '';

    formDetail = '';

    /* Parameter button (Default : '') */
    button = false;

    /* Parameter detailForm (Default : false) */
    detailFormMode = true;

    /* Parameter detailForm (Default : false) */
    groupFormMode = false;

    /* Misc Parameters */
    valUserId:string = '';

    key : string='';

    /* Parameter settings */
    settingToolbar;
    settingGrid;
    settingGridDetails;
    settingGridHistory;
    settingDetailGridLocation; //untuk grid input location
    settingUserHistory;
    settingLocation;

    storeLocation = [];
    storeGroup = [];
    menuList = [];
    storeComponent = [];

    selectedGroup = [];
    selectedMenu = [];
    selectedAction = [];

    mapSelectedAction = [];

    mapStore = new Map<string, Map<string, string[]>>()
    mapSelectedGroup = new Map<string, any[]>()
    mapSelectedMenu = new Map<string, any[]>()

    /* Parameter model */
    model = new Users;
    //selectList = [];

    modelLocation = new Location;
    modelGroup = new Group;
    modelMenu = new MenuManagement;


    loading = false;

    lock = false;
    lockDetail = false;
    lockCheckBox = false;
    lockAction = false;

    locationName = this.modelLocation.location_name

    listLocation: any[] = []
    listGroup: any[] = []
    listMenu: any[] = []
    listTemplate: any[] = []
    listSelectedLocation: any[] = []
    listUsersGroup: any[] = []
    listUsersMenu: any[] = []
    listComponent: any[] = []
    listUsersAction: any[] = []



    /* validatorRules */
    validatorRules = {
        userFullName: {
            rules:[
              {
                type  : 'empty',
                prompt: 'Please input Full Name.'
              }
            ]
        },
        userEmail: {
          rules: [
            {
              type  : 'empty',
              prompt: 'Please input Email'
            }
          ]
        },
        userPassword: {
            rules:[
              {
                type  : 'empty',
                prompt: 'Please input Password.'
              }
            ]
        },
        confirmPassword: {
          rules: [
            {
              type  : 'empty',
              prompt: 'Please input Confirm Password'
            }
          ]
        },
        validatorMatchPassword: {
            rules: [
                {
                    type : 'custom',
                    prompt : 'Password and Confirm Password does not Match'
                }
            ]
        },
        passwordValidasi: {
            rules: [
                {
                    type : 'custom',
                    prompt : ''
                }
            ]
        },
        formatEmail: {
            rules: [
                {
                    type : 'custom',
                    prompt : 'Invalid email format '
                }
            ]
        },
        location_name: {
            rules: [
                {
                    type : 'empty',
                    prompt : 'Please Input Location Name'
                }
        ]},
        userPhone: {
                rules: [
                  {
                    type  : 'empty',
                    prompt: 'Please input Phone'
                  }
                ]
              }
        //,
        // default: {
        //     rules: [
        //         {
        //             type : 'custom',
        //             prompt : 'Location set as a default'
        //         }
        //     ]
        // }
    }

    constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService) {
        super();

        this.settingToolbar = {
            buttonsFront          : [
                //{name: 'Group', event: 'group', icon: 'unlock alternate'}
            ],
            buttonsDetail         : [ ],
            createDefaultFront    : true,
            createDefaultDetail   : true,
            toolbarType           : 'settings',
            searchBy              : [
                {display: 'User Name', value: 'fullName'},
                {display: 'User Created', value: 'userCreated'},
                {display: 'Date Created', value: 'dateCreated'},
                {display: 'User Modified', value: 'userModified'},
                {display: 'Date Modified', value: 'dateModified'},
                {display: 'User Invalid', value: 'userInvalid'},
                {display: 'Date Invalid', value: 'dateInvalid'}
            ],
            urlhelp               : 'assets/pdf/panduan.pdf'
        }

        this.settingGrid = {
            url: this.configService.config.BASE_API.toString() + '/UserManagement/findUsers',
            page: 10,
            columns: [
                {header: 'No', field: 'no', width: 15},
                {header: 'User ID', field: 'userId', width: 100},
                {header: 'User Name', field: 'name', width: 200},
                {header: 'Full Name', field: 'fullName', width: 200},
                {header: 'User Created', field: 'userCreated', width: 100},
                {header: 'Date Created', field: 'dateCreated', width: 100},
                {header: 'User Modified', field: 'userModified', width: 100},
                {header: 'Date Modified', field: 'dateModified', width: 100},
                {header: 'Valid?', field: 'isValid', width: 50}
            ],
            buttons: [
                { name: 'Edit', event: 'edit', enabled: true }
            ],
            enableSorting: true,
            enableSelectAll: true,
            enablePagination: true,
            sortingColumns:'userId',
            sortingDirection: 'ASC'
        }

        this.settingGridDetails = {
            url: '',
            page: 5,
            columns: [
                        {header: 'No', field: 'no', width: 15},
                        {header: 'Location', field: 'location_name', width: 30},
                        {header: 'Default', field: 'default', width: 40},
                        {header: 'Action', field: 'removed', width: 10, labelButton: 'Remove', type: 'button'}

            ],
            buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
            enableSorting: false,
            enableSelectAll: false,
            enablePagination: false,
            sortingColumns:'location_name',
            sortingDirection: 'ASC',
            editable: true
        }

        this.settingGridHistory={
            url: this.configService.config.BASE_API.toString() + '/UserManagement/findGeneralHistoryById',
            page: 10,
            columns: [
                {header: 'No', field: 'no', width: 15},
                {header: 'User Id', field:'user_Id', width:200},
                {header: 'User Name', field: 'name', width: 200},
                {header: 'User Created', field: 'userCreated', width: 100},
                {header: 'Date Created', field: 'dateCreated', width: 100},
                {header: 'User Modified', field: 'userModified', width: 100},
                {header: 'Date Modified', field: 'dateModified', width: 100},
                {header: 'User Invalid', field: 'userInvalid', width: 100},
                {header: 'Date Invalid', field: 'dateInvalid', width: 100},
                {header: 'Valid?', field: 'isValid', width: 50}
            ],
            buttons: [
                { name: 'Edit', event: 'edit', enabled: true }
            ],
            enableSorting: true,
            enableSelectAll: true,
            enablePagination: true
        }

        this.settingUserHistory = {
            id          : 'cbUserNameHistory',
            type        : 'search enter', // search | select | select input
            url         : this.configService.config.BASE_API.toString() + '/UserManagement/findGeneralHistory/{query}',
            maxChars    : 3,
            template    : 'grid', // default
            placeholder : 'Search ...',
            title       : 'name',
            description  : '',
            columns     : [
                {header: 'User Id', field: 'user_Id', width:100},
                {header: 'User Name', field: 'name', width: 100},
                {header: 'Valid?', field: 'isValid', width: 100},
            ]
        }

        this.settingLocation = {
            id          : 'cbLocation',
            type        : 'search enter', // search | select | select input
            url         : this.configService.config.BASE_API.toString() + '/UserManagement/findAllLocation/{query}',
            maxChars    : 3,
            template    : 'grid', // default
            placeholder : 'Search ...',
            title       : 'location_name',
            description  : '',
            columns     : [
                {header: 'Location Code', field: 'location_code', width:100},
                {header: 'Location Name', field: 'location_name', width: 200}
            ]
        }
    }

    ngOnInit(){

    }

    getActionData(selectAction:any[]){
        this.storeComponent = [];
        this.listComponent.forEach(element => {
          console.log("masuk component")
          element['isSelect']= ''
          selectAction.forEach(el => {
              console.log("masuk juga ya")
              if(el['menu_id']==element['menu_id']){
                  console.log(this.key)
                  if(el['user_location_id'] == this.key){
                      console.log("masuuuuk sini ya")
                    element['isSelect'] = 'checked'
                 }
              }
          });
            this.storeComponent.push(element);
        });
    }

    getGroupData(selectGroup:any[]){
        this.storeGroup = [];
        ////console.log("get group data")
        ////console.log(selectGroup)
        this.listGroup.forEach(element => {
          ////console.log("masuk")
          element['isSelect']= ''
          selectGroup.forEach(el => {
              if(el['group_id']==element['group_id']){
                ////console.log("key")
                ////console.log(el.key)
                ////console.log(this.key)
                  if(el.key == this.key){
                    ////console.log("in")
                    element['isSelect'] = 'checked'
                 }
              }
          });
        //   ////console.log("element")
        //   ////console.log(element)
            this.storeGroup.push(element);

            //////console.log(this.storeGroup)
        });
    }

    getMenuData(selectMenu:any[]){

        this.menuList =  [];
        this.listMenu.forEach(element => {
            var data = new MenuManagement;
            data.isSelect = ''
            data.menuId = element['menu_id'];
            data.menuName =  element['menu_name'];
            data.menuLink = element['menu_link'];
            data.menuIconString =  element['menu_icon_string'];
            data.menuParent =  element['menu_parent'];
            data.menuOrder =  element['menu_order'];
            data.children = this.getChildren(this.listMenu,element['menu_id'],selectMenu);

            if(selectMenu != []){
                //////console.log("select menu" + selectMenu)
                selectMenu.forEach(el => {
                    // ////console.log("el")
                    // ////console.log(el)
                    if(el.menu_id ==data.menuId){
                        //////console.log("disini masuk")
                        //////console.log(el.key)
                        if(el.key == this.key){
                            data.isSelect = 'checked'
                        }
                    }
                });
            }

            if(data.menuParent == ''){
                this.menuList.push(data);
              this.modelMenu[data.menuId] = data;
            }
        });

        //console.log("menu list")
        //console.log(this.menuList)
    }

    getChildren(menuList:any[],parent:string, selectMenu:any[]){
		var childrenList = [];

		menuList.forEach(element => {
			if(element['menu_parent'] == parent){
              var data = new MenuManagement;
              data.isSelect = ''
			  data.menuId = element['menu_id'];
			  data.menuName =  element['menu_name'];
			  data.menuLink = element['menu_link'];
			  data.menuIconString =  element['menu_icon_string'];
			  data.menuParent =  element['menu_parent'];
			  data.menuOrder =  element['menu_order'];
			  if(element['menu_parent'] != ''){
			  	data.children = this.getChildren(menuList,element['menu_id'],selectMenu);
              }

              selectMenu.forEach(el => {
                if(el.menu_id ==data.menuId){
                    //////console.log("disini juga masuk")
                    if(el.key == this.key){
                        data.isSelect = 'checked'
                    }
                }
              });

			  childrenList.push(data);
			  //var x = '{' + data.menuId + ':' + data.menuName + '}';
			  //this.modelDetails.push(x);
			  this.modelMenu[data.menuId] = data;
			}
		});
		return childrenList;
    }


    ngAfterViewInit() {

        $('.test.checkbox').checkbox();
        this.disableToolbarButtons = "update,print";
        this.invisibleToolbarButtons = "cancel,delete";

        this.grid.search='*/*/*';
        this.grid.onFirst();

        this.onRetrieve()

    }

    onRetrieve(){
        ////////console.log("ini")
        //////console.log(this.model.userId)

        //console.log("#6")
        ////console.log(this.selectedGroup)
        // //console.log(this.selectedMenu)
        //console.log(this.gridDetails.listStore.store)

        if(this.model.userId == ''){
            this.model.userId = "*"
            ////console.log("masuk")
        }

        //////console.log(this.model)


        this.genericService.GET(this.configService.config.BASE_API.toString() + '/UserManagement/dataListing/' + this.model.userId.trim()).subscribe((resp) =>{
            ////////console.log("json" + resp.json()['content']);

            //////console.log("respon" + resp)

            this.listLocation = resp.json()['LocationList'];
            this.listGroup = resp.json()['GroupList'];
            this.listMenu = resp.json()['MenuList'];
            this.listTemplate = resp.json()['MenuTemplate']
            this.listSelectedLocation = resp.json()['LocationListByUserId']
            this.listUsersGroup = resp.json()['GroupListByUserLocationId']
            this.listUsersMenu = resp.json()['MenuListByUserLocationId']
            this.listComponent = resp.json()['MenuComponent']
            this.listUsersAction = resp.json()['ActionListByUserLocationId']


            this.listLocation.forEach(element => {
                ////////console.log(element)

              let ct = new Location ;
              ct.location_name = element['location_name'];
              ct.location_id = element['location_id'];

              this.storeLocation.push(ct);
            });

            if(this.model.userId != '*'){
                ////console.log("masuk")
                this.updateGridDetails()
            }

            ////console.log("on retrieve")
            ////console.log(this.selectedGroup)
            ////console.log(this.selectedMenu)
            this.getActionData(this.mapSelectedAction)
            this.getGroupData(this.selectedGroup)
            this.getMenuData(this.selectedMenu)
            this.loading = false;
        })
    }

    updateGridDetails(){
                var locationName:string;
                var defaultLocation:string;

                var strGroup:any = [];
                var strMenu:any = [];
                var strAction:any = [];

                ////console.log("list selected location")
                ////console.log(this.listSelectedLocation)

                ////console.log("list location")
                ////console.log(this.listLocation)

                //let list = new Map<string,string[]>();
                // kosongin grid
                this.gridDetails.listStore.store = []
                //console.log(this.gridDetails.listStore.store)

                this.listSelectedLocation.forEach(el=>{
                    ////////console.log(el['locationId'])
                        this.listLocation.forEach(element=>{
                            if(el['locationId'] == element['location_id']){
                                //////console.log("masuk dong")
                                //////console.log(element['location_name'])
                                var cekLocId = 0;

                                this.gridDetails.listStore.store.forEach(ele=>{
                                    if(el['locationId'] == ele['location_id']){
                                        cekLocId = 1;
                                    }else{
                                        cekLocId = 0;
                                    }
                                })

                                if(cekLocId == 0){
                                    this.gridDetails.listStore.store.push({
                                        'location_name' : element['location_name'],
                                        'default' : el['default'],
                                        'location_id' : el['locationId']
                                    })
                                }
                            }
                            ////console.log("list location")
                            ////console.log(this.gridDetails.listStore.store)
                        })

                        this.listUsersGroup.forEach(elementGroup=>{
                            // ////console.log(elementGroup['usersLocationId'])
                            // ////console.log(el['usersLocationId'])
                            // ////console.log(elementGroup)
                            if(elementGroup['usersLocationId'] === el['usersLocationId']){
                                this.listGroup.forEach(elG=>{
                                    // ////console.log("in")
                                    //     ////console.log(elG['group_id'])
                                    //     ////console.log(elementGroup['groupId'])
                                        if(elG['group_id'] === elementGroup['groupId']){
                                                strGroup.push({
                                                    'group_id' : elG['group_id'],
                                                    'group_name' : elG['group_name'],
                                                    'isSelect' : 'checked',
                                                    'key' : el['locationId']
                                                });
                                        }
                                    this.selectedGroup = strGroup
                                    ////console.log("selected group in if")
                                    ////console.log(this.selectedGroup)
                                })
                            }
                        })

                        this.listUsersMenu.forEach(elementMenu=>{
                            if(elementMenu['usersLocationId'] === el['usersLocationId']){
                                //////console.log("masukk")
                                this.listMenu.forEach(elM=>{
                                    if(elM['menu_id'] === elementMenu['menu_id']){
                                        ////console.log("masukkk")
                                        //////console.log(el['locationId'])
                                        this.selectedMenu.push({
                                            'group_id' : '',
                                            'menu_id' : elM['menu_id'],
                                            'key' : el['locationId']
                                        });
                                    }
                                    ////console.log(this.selectedMenu)
                                })
                            }
                        })

                        this.listUsersAction.forEach(elementMenu=>{
                            // console.log("helooo")
                            // console.log(elementMenu['users_location_id'])
                            // console.log(el['usersLocationId'])
                            if(elementMenu['users_location_id'] === el['usersLocationId']){
                                // console.log("masukk actiom")
                                this.listComponent.forEach(elM=>{
                                    if(elM['menu_id'] === elementMenu['menu_id']){
                                        ////console.log("masukkk")
                                        //////console.log(el['locationId'])
                                        strAction.push({
                                            'menu_id' : elM['menu_id'],
                                            'component_id' : elM['component_id'],
                                            'user_location_id' : el['locationId'],
                                            'isSelect' : 'checked'
                                        });
                                    }
                                    this.mapSelectedAction = strAction
                                    // console.log("#!")
                                    // console.log(this.mapSelectedAction)
                                })
                            }
                        })
                })

                this.gridDetails.loadData()

                // //////console.log(this.gridDetails.listStore.store)

                var nomor = this.gridDetails.listStore.store[0]['no']

                this.gridDetails.listStore.store.forEach(elGrid=>{
                    if(nomor == elGrid.no){
                        elGrid['select'] = 'checked'
                        this.key = elGrid['location_id']
                    }
                })

                //console.log("update details")
                ////console.log(this.selectedGroup)
                //console.log(this.selectedMenu)

                            //////console.log("grid detail")
                            //////console.log(this.gridDetails)
    }

    message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
        this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
        $('#dialog').modal("setting", "closable", false).modal('show');
    }


    eventMessage(event:any) {
        if(event != ''){
            eval(event);
        }
    }

    infoGrid(event:any) {
        this.info = event;
    }

    infoGridDetails(event:any) {
        this.info = event;
    }

    infoGridHistory(event:any){
        this.info=event;
    }

    gridEvent(event:any) {
        //////console.log("itu" + event);
        switch (event.split(".")[0]) {
            case 'selected':
                if(this.grid.getSelectedValues().length > 0){
                    this.disableToolbarButtons = "print";
                    this.invisibleToolbarButtons = "delete";
                }else{
                    this.disableToolbarButtons = "update,print";
                    this.invisibleToolbarButtons = "delete";
                }

                this.model.userId = this.grid.getSelectedValues()[0]['userId']
                break;
            case 'click' :
                if(this.grid.getSelectedValues().length > 0){
                    this.disableToolbarButtons = "print";
                    this.invisibleToolbarButtons = "delete";
                }else{
                    this.disableToolbarButtons = "update,print";
                    this.invisibleToolbarButtons = "delete";
                }
            default:
                let strEvent:string = event;
                if(strEvent != ''){
                    let arr:string[] = strEvent.split('-');
                    if(arr[0] == "edit"){
                        this.handleUpdate();

                    }else if(arr[0] == "dblClick"){

                        this.disableToolbarButtons = "";
                        this.invisibleToolbarButtons = "create,history,delete,print";
                        this.loading = true

                        this.passwordForm = false;
                        this.lockDetail = true;
                        this.modeForm = true;
                        this.modeToolbar = false;
                        this.setData();
                        this.lock = true;
                        this.gridDetails.getValue;

                        //console.log("store")
                        //console.log(this.gridDetails.store.length)
                        this.onRetrieve();

                    }

                }

                break;
        }
    }

    gridEventDetails(event:any) {
    ////console.log("holaa " + event);
    ////console.log(this.gridDetails.listStore.store)
    switch (event.split(".")[0]) {
      case 'selected':
        break;
      case 'click' :

        var id:string = event.split(".")[1].split("-")[0];
        var no = Number.parseInt(event.split(".")[1].split("-")[1]);
        //////console.log(id + " - " + no)

        ////console.log("id" + id)

        switch(id){
            case 'removed':
                //////console.log("test")
                //////console.log("hehe : " + this.gridDetails.listStore.store[no-1]['default'])

                var cekDefault = this.gridDetails.listStore.store[no-1]['default']
                var locId = this.gridDetails.listStore.store[no-1]['location_id']

                if(cekDefault == "Y"){
                    this.message('warning','Warning','Location is default','okonly',{ok: 'this.loading=false;'});
                }else{
                    this.gridDetails.listStore.store.splice(no-1,1);
                    var check = this.selectedGroup.indexOf(locId);
                    var checkMn = this.selectedMenu.indexOf(locId);
                    this.selectedGroup.splice(check,1);
                    this.selectedMenu.splice(checkMn,1)

                    // this.storeGroup = []
                    // this.menuList = []
                    // this.getGroupData(this.storeGroup)
                    // this.getMenuData(this.menuList)

                    this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDeleteDetail();'});
                }
                break;
            case 'checked':
                this.selectedListMod(no, this.gridDetails, 'no');

                this.gridDetails.getSelectedValues().forEach(elSel=>{
                    this.key = elSel.location_id;
                })

                let sampleGroup :any[] = []
                let sampleMenu :any[] = []

                // ////console.log("disini !")
                // ////console.log(this.gridDetails.getSelectedValues())
                // ////console.log(this.selectedGroup)
                this.gridDetails.getSelectedValues().forEach(element => {
                    // ////console.log("#4")
                    //     ////console.log(element['location_id'])
                        this.selectedGroup.forEach(el=>{
                            // ////console.log("el" + el.key)
                            // ////console.log("is Select" + el.isSelect)
                            // ////console.log("group name" + el.group_name)
                            // ////console.log("group_id" + el.group_id)
                            if(element.location_id == el.key){
                                    sampleGroup.push({
                                    'group_id' : el.group_id,
                                    'group_name' : el.group_name,
                                    'isSelect' : el.isSelect,
                                    'key' : el.key
                                })
                            }
                        })

                    this.selectedMenu.forEach(elMenu=>{
                        if(element.location_id == elMenu.key){
                            //////console.log("hola masuk sini")
                            sampleMenu.push({
                                'group_id' : '',
                                'menu_id' : elMenu['menu_id'],
                                'key' : elMenu['key']
                            })
                        }
                    })
                });

                // ////console.log("yang ini loh")
                // ////console.log(sampleGroup)
                // ////console.log(sampleMenu)
                //this.getGroupData(sampleGroup)
                this.storeGroup = []

                setTimeout(() => {
                   this.getGroupData(sampleGroup)
                }, 10);
                this.getMenuData(sampleMenu)

                ////console.log(this.storeGroup)
                break;
            case 'unchecked':
                this.gridDetails.listStore.store.forEach(el=>{
                    if(el['no'] == no){
                        el['select'] = 'checked'
                        this.key = el['location_id']
                    }
                })

                //this.getGroupData(this.selectedGroup)
                this.getMenuData(this.selectedMenu)
                break;
        }
        break;
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':

              let dt = this.gridDetails.getSelectedValues()[0];
              this.gridDetails.listStore.store.forEach(element => {
                 if(dt.no ==  element['no']){

                 }
              });

              $('#display').transition();
              $('#displayGrid').transition('hide');

              break;

            default:
            break;
          }

        }

        break;
    }

  }

    gridEventHistory(event:any){
        //do nothing
    }

    toolbarEvent(event:any) {

        //console.log("#1")
        //console.log(event)
        switch (event) {
            case 'create':
                this.handleCreate();
                break;
            case 'update':
                this.handleUpdate();
                break;
            case 'delete':
                this.handleDelete();
                break;
            case 'print':
                this.handlePrint();
                break;
            case 'save':
                this.handleSave();
                //this.checkUserName();
                break;
            case 'cancel':
                this.handleCancel();
                break;
            case 'history':
                this.handleHistory();
                break;
            default:
                let str:string = event;
                if(str.indexOf('search') > -1 ){
                    this.grid.search = str.replace("search:","");
                    this.grid.onFirst();
                    this.handleCancel();
                }else{
                    this.handleCancel();
                }
                break;
        }
    }

    handleCreate()
    {
        // handle create event
        ////console.log("create")
        ////console.log(this.selectedGroup)
        ////console.log(this.selectedMenu)
        this.lock=false;
        this.modeForm = true;
        this.modeToolbar = true;
        this.invisibleToolbarButtons = "";
        this.model = new Users;
        this.passwordForm = false;
        this.lockDetail = false;

        //this.onRetrieve();

        this.clearSelect()
    }


    handleUpdate()
    {
        // handle update event
        this.modeForm = true;
        this.modeToolbar = true;
        this.loading = true;
        this.setData();
        this.lock=false;
        this.disableToolbarButtons = "";
        this.invisibleToolbarButtons = "create,delete,print";
        this.passwordForm = false;
        this.lockDetail = true;
        this.lockAction = true;

        this.onRetrieve()
        this.clearSelect()
    }

    handleDelete()
    {
        // handle delete event
        this.loading = true;
        this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
    }

    handlePrint()
    {
        window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/6/AccessLevels-Export-Reports/*');
    }

    addLocation(){
        let isPassedValidation:boolean = this.onValidate(this.modelLocation);
        if(!isPassedValidation){

            //////console.log("default atas" + this.modelLocation.default)
                if(!this.modelLocation.default){
                    this.modelLocation.default = "N"
                }else{
                    this.modelLocation.default = "Y"
                }

                let size = this.gridDetails.listStore.store.length;

                //////console.log("size" + size)
                if(size == 0){
                    this.modelLocation.default = "Y"
                }

                var cek:number = 0;
                this.gridDetails.listStore.store.forEach(element=>{
                    if(element['location_id'] == this.modelLocation.location_id){
                        //////console.log("benar")
                        cek = cek+1;
                         }else{
                        //////console.log("salah")
                    }
                })

                if(cek == 0){
                    this.gridDetails.listStore.store.push(this.modelLocation);
                }else{
                    this.message('warning','Warning','Data Already Exists','okonly',{ok: 'this.loading=false;'});
                }
                this.gridDetails.loadData();
                //////console.log("default" + this.modelLocation.default)


                this.modelLocation = new Location();

                //(this.gridDetails.listStore.store)
            }else{
                ////console.log("error")
            }

            var nomor = this.gridDetails.listStore.store.length

            var storeMenuAdd:any = []
            var storeGroupAdd:any = []

            this.gridDetails.listStore.store.forEach(el=>{
                if(nomor == el['no']){
                    el['select'] = 'checked'
                    this.key = el['location_id']
                    this.selectedGroup.forEach(elem=>{
                        ////console.log('#1' + this.key)
                        if(elem['key'] != this.key){
                            ////console.log('#2')
                            if(elem['isSelect'] == 'checked'){
                                ////console.log('#3')
                                elem['isSelect'] = ''
                            }
                        }
                        ////console.log(this.selectedGroup)
                            this.storeGroup = []

                            setTimeout(() => {
                               this.getGroupData(this.selectedGroup)
                            }, 10);
                    })
                    this.selectedMenu.forEach(ele=>{
                        if(ele['location_id'] != this.key){
                            this.getMenuData(storeMenuAdd)

                        }
                    })
                }
            })

            ////console.log("yang ini")
            ////console.log(this.gridDetails.listStore.store)
            ////console.log(this.gridDetails)
            ////console.log(this.storeGroup)
            this.cbLocation.setValue("")
    }

    // loadData(){
    //     let sampleGroup :any[] = []
    //     let sampleMenu :any[] = []

    //     this.gridDetails.getSelectedValues().forEach(element => {
    //         ////console.log("load data")
    //         ////console.log(element)
    //         this.selectedGroup.forEach(el=>{
    //             //////console.log("el" + el.key)
    //             if(element.location_id == el.key){
    //                     sampleGroup.push(el)
    //             }
    //         })
    //         this.selectedMenu.forEach(elMenu=>{
    //             if(element.location_id == elMenu.key){
    //                 //////console.log("hola masuk sini")
    //                 sampleMenu.push(elMenu)
    //             }
    //         })
    //     });

    //     this.getGroupData(sampleGroup)
    //     this.getMenuData(sampleMenu)
    // }

    handleSave()
    {
        this.model['validatorMatchPassword'] = this.passwordMatchValidator();
        this.model['passwordValidasi'] = this.passwordValidator();
        this.model['formatEmail'] = this.checkEmail();

        //console.log("selected")
        // ////console.log(this.selectedGroup)
        //console.log(this.selectedMenu)

        this.model['mapGroup'] = this.selectedGroup;
        this.model['mapMenu'] = this.selectedMenu;
        this.model['mapAction'] = this.mapSelectedAction;
        this.model['userLocation'] = this.gridDetails.listStore.store

        this.model['to'] = this.model['userEmail']
        //////console.log("model" + this.gridDetails.listStore.store)
        console.log("save Action")
        console.log(this.model.mapAction)
        // ////console.log(this.model.mapMenu)
        // ////console.log(this.model.userLocation)

        ////console.log("model")
        ////console.log(this.model)
        let isPassedValidation:boolean = this.onValidate(this.model);

        //////console.log(isPassedValidation)
        if(!isPassedValidation){
            /*Patch for local timing
             * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
             * 2. System should use local timing instead of server timing
             * 3. Format should be YYYY-MM-DD hh:mm:ss
             */
            //////console.log("PSI Date => " + this.genericUtil.getPSIDate());
            this.model["dateCreated"] = this.genericUtil.getPSIDate();
            this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.checkUserName()', no: 'this.loading = false;'});
        }else{
            this.loading = false;
            ////console.log("error")
        }
    }

    handleCancel(){
        // handle cancel event
        this.modeForm = false;
        this.modeToolbar = false;
        this.form = '';
        this.disableToolbarButtons = "update,print";
        this.invisibleToolbarButtons = "cancel,delete";
        this.grid.clearSelectedValues();

        if(this.valUserId != '') {
            this.cbUserNameHistory.setValue("");
            this.gridHistory.onHistory("NaN")
            this.gridHistory.loadData();
          }

        this.gridDetails.onClear()
        this.clearSelect()
    }

    clearSelect(){

        this.key = ''

        this.selectedGroup = []
        this.selectedMenu = []

        this.storeGroup = []
        this.menuList = []

        setTimeout(() => {
           this.getGroupData(this.selectedGroup)
        }, 10);

        setTimeout(() => {
           this.getMenuData(this.selectedMenu)
         }, 10);
    }

    /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
     * 1. After saving record system should auto redirect to flag status screen
     * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
     * 3. So the last inputted record will be appeared in the top of the grid
     */
    afterSave(){
        this.modeForm = false;
        this.modeToolbar = false;
        this.form = '';
        this.disableToolbarButtons = "update,print";
        this.invisibleToolbarButtons = "cancel,delete";

        //////console.log("grid-> " + this.grid.getSelectedValues().length);

        if(this.grid.getSelectedValues().length >= 1){
            this.grid.onSortDir("dateModified","DESC");
        }else{
            this.grid.onSortDir("dateCreated","DESC");
        }

        this.grid.clearSelectedValues();
        this.grid.onFirst();

        this.gridDetails.onClear()
        this.clearSelect()
    }

    afterDelete(){
        this.modeForm = false;
        this.modeToolbar = false;
        this.form = '';
        this.disableToolbarButtons = "update,print";
        this.invisibleToolbarButtons = "cancel,delete";

        //////console.log("grid-> " + this.grid.getSelectedValues().length);

        this.grid.clearSelectedValues();
        this.grid.loadData();
    }

    afterDeleteDetail(){
        this.gridDetails.loadData();

        this.gridDetails.listStore.store.forEach(el=>{
            if(el['no'] == 1){
                ////console.log("#7")
                el['select'] = 'checked'
                this.key = el['location_id']
            }
        })

        this.getGroupData(this.selectedGroup)

        this.getMenuData(this.selectedMenu)

        ////console.log("after delete")
        ////console.log(this.gridDetails.listStore.store)
        ////console.log(this.selectedGroup)
        ////console.log(this.selectedMenu)

    }


    handleHistory()
    {
        // handle history event
        this.modeForm = true;
        this.modeToolbar = true;
        this.form = 'history';
        this.invisibleToolbarButtons = "save";
    }

    setData(){
        //SET MODEL DATA FOR UPDATE PROCESS
            let data = this.grid.getSelectedValues()[0];

            console.log(this.grid.getSelectedValues()[0])
            //console.log("update")
            this.model = new Users;

            if(data.isValid=='Y'){
              this.model.isValid=true;
            }else{
              this.model.isValid=false;
            }

            this.model.confirmPassword = String(data.password).trim();
            this.model.userId = String(data.userId).trim();
            this.model.userPassword = String(data.password).trim();
            this.model.userEmail = String(data.email).trim().toUpperCase();
            this.model.userSName = String(data.name).trim().toUpperCase();
            this.model.userPhone = String(data.phone).trim();
            this.model.userFullName = String(data.fullName).trim().replace(/&nbsp;/g," ");
            this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
            this.model.userCreated = String(data.userCreated).trim().toUpperCase();
            this.model.dateModified = String(data.dateModified).trim().toUpperCase();
            this.model.userModified = String(data.userModified).trim().toUpperCase();
            this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
            this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
            this.model.userLocation = this.gridDetails.listStore.store

            ////console.log("#5")
            ////console.log(this.selectedGroup)
            ////console.log(this.selectedMenu)

            this.model.mapGroup = this.selectedGroup;
            this.model.mapMenu = this.selectedMenu;
            this.model.mapAction = this.mapSelectedAction
    }

    valueUserHistory(event:any){
        this.valUserId = event['user_Id'];
        this.gridHistory.onHistory(this.valUserId);
      }

      changeEventCountry(event:any) {
        if(event['location_name'] != null){
          this.modelLocation.location_name = event['location_name'];
        }else{
          this.modelLocation.location_name = "";
        }

        if(event['location_id'] != null){
            this.modelLocation.location_id = event['location_id'];
          }else{
            this.modelLocation.location_id = "";
          }

          if(event['location_code'] != null){
            this.modelLocation.location_code = event['location_code'];
          }else{
            this.modelLocation.location_code = "";
          }
      }

      listLocationName(event:any){
        this.modelLocation.location_name = event['location_name'];
      }

    checkUserName(){
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/UserManagement/checkUser',this.model).subscribe((resp) =>{
            if(resp.json()['status'] == 'ALERT'){
                //////console.log("ENTER SAVE");
                ////console.log("#100")
                this.loading = false;
                this.message('info','Information',resp.json()['content'], 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
        `   `}else{
                ////console.log("#99");
                this.saveEvent();
            }
        });
    }

    saveEvent(){
        //////console.log('saveEvent');
        this.loading = true;
        ////console.log(this.model);
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/UserManagement/createUser',this.model).subscribe((resp) =>{
            ////console.log(resp.json()['status'])
            ////console.log(resp.json()['message'])
            if(resp.status == 200 && resp.json()['status'] == 'OK'){
                    //////console.log("ENTER SAVE");
                    this.model = new Users;
                    this.loading = false;
                    this.info = 'save successfully';
                    this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
            // }else if(resp.json()['status'] == 'ALERT'){
            //         this.loading = false;
            //         this.message('information','Information',resp.json()['content'], 'yesno', { yes: 'this.afterSave();', no: 'this.loading=false;'});
            }else{
                    this.loading = false;
                    this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
            }
        },error => {
            this.loading = false;
            //////console.log(error.mfessage);
            this.info = error.message;
            this.loading = false;
        });
    }

    deleteEvent(){
        //////console.log("Delete Event")
        this.loading = true;

        let deleteList = [];
        this.grid.getSelectedValues().forEach(element => {
            deleteList.push(element.accessLevelId);
        });
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/UserManagement/deleteUser',deleteList).subscribe((resp) =>{
            if(resp.status == 200){
                this.model = new Users;
                //  this.selectList = [];
                this.loading = false;
                this.grid.clearSelectedValues();
                this.grid.onFirst();
                //this.setGridEvent = 'refresh';
                this.loading=true;
                this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});


            }
        },error => {
            this.loading = false;
            //////console.log(error.message);
            this.info = error.message;
        });

    }

    addDetail(){
        this.detailFormMode = true;
        this.lock=false;
        this.button = true;
        this.modeButton = false;
    }

    removeDetail() {

        var check : number;
        this.gridDetails.listStore.store.forEach(element =>{
            //////console.log(element['default'])
            if(element['select'] == 'checked'){
                if(element['default'] == "Y"){
                        this.message('warning','Warning','Location is default','okonly',{ok: 'this.loading=false;'});
                        //////console.log("ini")
                    }else{
                        check = this.gridDetails.listStore.store.indexOf(element);
                        this.gridDetails.listStore.store.splice(check,1);
                        //////console.log("itu")
                    }
            }
        })

        this.gridDetails.loadData();
        this.gridDetails.clearSelectedValue;
    }

    cancelDetail(){
        this.button = false;
        this.gridDetails.clearSelectedValues;
        this.cbLocation.setValue("");

    }



    retrieveHistory(event:any){
        this.gridHistory.loadData();
    }

    // checkDefault():boolean {
    //     let result:boolean = true;

    //     let size = this.gridDetails.listStore.store.length;
    //     if(size == 0){
    //         result = true;
    //     }

    //     return result;
    // }

    copyTemplateButton(){

        // ////console.log("before copy template")
        // ////console.log(this.selectedMenu)
        // ////console.log("map store")
        // ////console.log(this.mapStore)
        // ////console.log("key")
        // ////console.log(this.key)
        // ////console.log("list selected group")
        // ////console.log(this.selectedGroup)

        if(!this.mapStore.has(this.key)){
            let list = new Map<string,string[]>();
            //////console.log("masuk")
            this.mapStore.set(this.key,list)
        }
        //////console.log(this.selectedGroup)

        this.selectedGroup.forEach(el=>{
            if(el['key'] === this.key){
                let groupId:string = el['group_id']

                let mnList:string[] = []
                this.mapStore.get(this.key).set(el['group_id'], mnList)

                this.listTemplate.forEach(elTemplate=>{
                    if(groupId === elTemplate['group_id']){
                        this.mapStore.get(this.key).get(elTemplate['group_id']).push(elTemplate['menu_id'])
                    }
                })
            }
        })


        //////console.log(this.mapStore);
        //this.selectedMenu = [];
        for (let entry of Array.from(this.mapStore.entries())) {

              for (let val of Array.from(entry[1].entries())) {

                //sl.key = entry[0]
                //sl.group_id  = val[0]
                val[1].forEach(element => {
                    let sl = new SelectedMenuModel();
                    sl.key = entry[0]
                    sl.group_id = val[0]
                    sl.menu_id = element
                    //////console.log(element)
                    this.selectedMenu.push(sl)
                });

                //this.selectedMenu.push()
              }

        }

        ////console.log("copy template")
        ////console.log(this.selectedMenu)

        this.getMenuData(this.selectedMenu)
    }

    passwordMatchValidator():boolean {

        let result:boolean = true;

        //////console.log('passwordMatch' + this.model.userPassword)
        if(this.model.userPassword == this.model.confirmPassword){
            result = false;
        }

        return result;
    }

    passwordValidator():boolean{
        let result:boolean = false;
        let arr:any[] = []

        var flag = 0;

        //////console.log('ini' + this.checkLengthPassword())
        if(this.checkLengthPassword() > 0){
            arr.push("Please input password more than 8 char <br/>")
            flag = flag + 1
        }
        if(this.checkUpperLowerMoreThanOne() > 0){
            arr.push("Please input Combination of upper case and lower case <br/>")
            flag = flag + 1
        }
        if(this.checkSpecialChar() > 0){
            arr.push("Please Contains punctuation mark(s) (!,@,#,$,%,^,&,*,?,_,~ <br/>")
            flag = flag + 1
        }
        if(this.checkNumberChar() > 0){
            arr.push("Please Contains number(s) inside the password <br/>")
            flag = flag + 1
        }

        this.model['error-passwordValidasi']= ""
        arr.forEach(element => {
            this.model['error-passwordValidasi'] += element ;
        });

        //////console.log('flag' + flag)
        if(flag > 0){
            result = true
        }

        return result;

    }

    checkLengthPassword() {
        //check length process

        var hitung = 0;

        //////console.log(this.model.userPassword.length)
        if(this.model.userPassword.length <= 8){
            hitung = hitung + 1
            //////console.log("ini length")
        }

        return hitung;
    }

    checkUpperLowerMoreThanOne() {

        var hitungError = 0;

        var lowerCase = /[a-z]/
        var upperCase = /[A-Z]/
        if(!(this.model.userPassword.match(lowerCase) && this.model.userPassword.match(upperCase))){
            hitungError = hitungError + 1
            //////console.log("ini upper lower")
        }
        return hitungError;
    }

    checkSpecialChar() {
        var specialChar = /[!@#$%^&*?_~]/

        var hitungSalah = 0;

        if(!this.model.userPassword.match(specialChar)){
            hitungSalah = hitungSalah + 1
            //////console.log("ini special char")
        }

        return hitungSalah;
    }

    checkNumberChar(){
        var angka = /[0-9]/

        var salahHitung = 0;

        if(!this.model.userPassword.match(angka)){
            salahHitung = salahHitung + 1
            //////console.log("ini number")
        }
        return salahHitung;
    }

    checkEmail(): boolean{
        var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        let result:boolean = true;

        if(this.model.userEmail.match(regexEmail) ){
            result = false;
        }

        return result;
    }

    detailsProcess(event){

    }

    // MENU


    onChangeMenu(value: string, checked: boolean){
        console.log("menu")
        // ////console.log(value['menuId'])
        // ////console.log(this.key)
        // ////console.log(this.selectedMenu)

        if(checked){
            value['isSelect'] = 'checked'
            value['key'] = this.key

            //console.log("before push")
            //console.log(value['menuId'])

            this.selectedMenu.push({
                'group_id' : '',
                'menu_id' : value['menuId'],
                'key' : value['key']
            });

            this.listMenu.forEach(el=>{
                if(el.menu_id == value['menuId']){
                    //console.log(el.menu_parent)
                    if(el.menu_parent != ''){
                            //console.log("masuk")
                            this.selectedMenu.push({
                                'group_id' : '',
                                'menu_id' : el.menu_parent,
                                'key' : value['key']
                            })
                    }
                }
            })

            this.listMenu.forEach(el=>{
                this.selectedMenu.forEach(ele=>{
                    if(ele.menu_id == el.menu_id){
                        if(el.menu_parent != ''){
                            if(el.menu_parent != ele.menu_id){
                                //console.log("*")
                                this.selectedMenu.push({
                                    'group_id' : '',
                                    'menu_id' : el.menu_parent,
                                    'key' : value['key']
                                })
                            }
                        }
                    }
                })
            })

            //console.log("after push")
            //console.log(this.selectedMenu)
            this.getMenuData(this.selectedMenu)

        }else{
            // let index = this.selectedMenu.indexOf(value['menuId']);
            // this.selectedMenu.splice(index, 1);
            //console.log(this.selectedMenu)

            var temp = []
            this.selectedMenu.forEach(el=>{
                if(el.menu_id != value['menuId']){
                    temp.push({
                        'group_id' : '',
                        'menu_id' : el.menu_id,
                        'key' : el.key
                    })

                    this.selectedMenu = temp;
                }
            })

            ////console.log(this.selectedMenu)
        }
    }

    // CheckBox Group
    onChange(value: string, checked: boolean) {
        //////console.log(this.selectedGroup)
        if (checked) {

            // value['isSelect'] = 'checked'
            // value['key'] = this.key

            // this.selectedGroup.push(value)

            this.selectedGroup.push({
                'group_id' : value['group_id'],
                'group_name' : value['group_name'],
                'isSelect' : 'checked',
                'key' : this.key
            });
            ////console.log("after push")
            ////console.log(this.selectedGroup)
        } else {
            let indexMenu;
            this.listTemplate.forEach(elTemplate=>{
                if(value['group_id'] == elTemplate['group_id']){
                    let indexMenu = this.selectedMenu.indexOf(elTemplate)
                    //////console.log("index" + indexMenu)
                    this.selectedMenu.splice(indexMenu, 1)
                }
            })
            this.getMenuData(this.selectedMenu)
          let index = this.selectedGroup.indexOf(value);
          this.selectedGroup.splice(index, 1);
        }
    }

    selectedListMod(value, store:GridPluginComponent, key){
        this.gridDetails.listStore.store.forEach(el=>{
            if(el[key] != value){
                el['select'] = ''
            }

            if(store.getSelectedValues().length > 1){
                store.getSelectedValues().forEach(ff=>{
                    if(ff[key] != value){
                    ff['select'] = '';
                    }
                });

                store.getSelectedValues().splice(0,1);
            }
        })
    }

    onCloseAction(){
        this.genericUtil.closeDialog('componentActionForm');
        this.storeComponent = []
    }

    onSaveAction(){
        console.log("save")
        console.log(this.selectedAction)
        console.log(this.storeComponent)
        this.gridDetails.listStore.store.forEach(ele => {
            console.log(ele)
            if(ele['select'] == 'checked'){
                this.selectedAction.forEach(el=>{
                    this.mapSelectedAction.push({
                        'menu_id' : el['menu_id'],
                        'component_id' : el['component_id'],
                        'user_location_id' : ele['location_id'],
                    })
                })
                this.lockAction = false;
            }
        });
        this.handleSave()
        this.genericUtil.closeDialog('componentActionForm');
    }

    // CheckBox Action
    onChangeComponent(value: string, checked: boolean) {
        console.log("action")
        console.log(this.selectedAction)
        console.log(this.storeComponent)
        if(this.selectedAction.length == 0){
            if (checked) {
                this.selectedAction.push({
                    'menu_id' : value['menu_id'],
                    'component_id' : value['component_id'],
                    'component_name' : value['component_name'],
                    'isSelect' : 'checked',
                    'key' : this.key
                });
            }else{
                // let indexMenu;
                // this.listComponent.forEach(elComponent=>{
                //     if(value['menu_id'] == elComponent['menu_id']){
                //         let indexMenu = this.mapSelectedAction.indexOf(elComponent)
                //         console.log("index" + indexMenu)
                //         this.mapSelectedAction.splice(indexMenu, 1)
                //     }
                // })
                // this.getActionData(this.mapSelectedAction)
              let index = this.mapSelectedAction.indexOf(value);
              this.mapSelectedAction.splice(index, 1);
            }
        }
    }

    onAction(menuId:string){
        //alert(this.key + " - " + menuId)
        // component

        console.log("2")
        console.log(menuId)
        console.log(this.storeComponent)
        if(this.storeComponent == []){
            this.listComponent.forEach(el=>{
                console.log(el)
                if(el.menu_id == menuId){
                    this.storeComponent.push({
                        'menu_id' : el.menu_id,
                        'component_id' : el.component_id,
                        'component_name' : el.component_name
                    })
                    console.log("masuk")
                }
            })
        }

        console.log(this.model.mapMenu)
        console.log(this.model.mapAction)

        this.genericUtil.showDialog('componentActionForm', "Component", 500, 350)
    }

}
