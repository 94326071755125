<div class="ui grid" style="padding-top:10px;">

  <div style="width:655px;">
    <div class="ui segment">
      <div id="container"></div>
    </div>
  </div>

  <div style="width:655px;">
    <div class="ui segment">
      <div id="container2"></div>
    </div>
  </div>
</div>
