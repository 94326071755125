import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { Vessel, officeModel } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

import { MasterVesselFormPageComponent } from "./master-vessel-form-page.component";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-master-vessel-page",
  templateUrl: "./master-vessel-page.component.html",
  styleUrls: ["./master-vessel-page.component.css"],
})
export class MasterVesselPageComponent
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("masterVesselForm")
  masterVesselForm: MasterVesselFormPageComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  office = new officeModel();
  userID;
  userLocationID;
  baseURI;
  menus;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* Parameter model */

  loading = false;
  createFlag = true;

  /* validatorRules */
  validatorRules = {};

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cook: CookieService,
    private router: Router,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Vessel Abbr. Code", value: "vesselCode" },
        { display: "Vessel Name", value: "vesselName" },
        { display: "PSA. Abbr. Name", value: "psaVesselCode" },
        { display: "Vessel Code (A/C)", value: "docsysCode" },
        { display: "Call Sign", value: "callSign" },
        { display: "SSL", value: "isSSL" },
        { display: "Slot", value: "isSlot" },
        { display: "3rd Party", value: "is3rdParty" },
      ],
      searchBy3: [
        { display: "Default", value: "N" },
        { display: "Mother Vessel", value: "Y" },
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Abbr. Code", field: "vesselCode", width: 130 },
        { header: "Vessel Code (A/C)", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit() {
    this.masterVesselForm.modeType=false;
    this.baseURI = this.configService.config.BASE_API.toString();
    this.office.officeCode = this.cook.getDefaultLocationCode(); //localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook
      .getCookie("defaultLocation")
      .split("|")[0];
    this.userID = this.cook.getName();
    this.userLocationID = this.cook.getCookie("defaultLocation").split("|")[4];
    this.currentMenuID = this.cook.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cook.getCookie("currentMenu").split("|")[1];
    console.log("url " + this.router.url);
    console.log("menu " + this.currentMenu);
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }

    if(this.masterVesselForm.model.vesselType == "DEFAULT"){
      this.setValidatorDefault();
    }else{
      this.setValidatorMotherVessel();
    }

    this.grid.search = "*/*/*";
    this.grid.onFirst(); /*/*';
              self.grid.onFirst();
            }, 100);
          }
        }
      })
    });*/

    /*var mnUrl = this.baseURI + "/menus/"+this.userLocationID;
    console.log("mnURl "+mnUrl);
    this._http.get(mnUrl,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      this.menus = resp;
      this.menus.forEach(fe=>{
        if(fe.menuId == this.currentMenuID){
          if(fe.menuName.includes("(View Only)")){
            this.viewOnlyStatus = true;

            var self = this;
            setTimeout(function() {
              if(self.viewOnlyStatus){
                self.disableToolbarButtons = "create,update,delete";
              } else {
                self.disableToolbarButtons = "update,delete";
              }
              self.invisibleToolbarButtons = "cancel";

              self.grid.search = '*/
  }

  ngOnDestroy() {
    //// console.log("On Destroy");
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleUpdate();
              break;
            case "dblClick":
              this.masterVesselForm.isDefault = false;

              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }

              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;

              this.masterVesselForm.lock = true;
              this.masterVesselForm.isUpdate = true;

              let dt = this.grid.getSelectedValues()[0];
              let vesId: string = String(dt.vesselId).trim();
              this.masterVesselForm.onRetrieve(vesId);

              break;

            default:
              break;
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    //// console.log('event : ' + event);
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event

    this.createFlag = true;
    this.modeForm = true;
    this.modeToolbar = true;
    this.masterVesselForm.isUpdate = false;
    this.invisibleToolbarButtons = "";
    this.masterVesselForm.model = new Vessel();
    this.masterVesselForm.model.isValid = true;
    this.masterVesselForm.cbFormType.setValue("Default");
    this.masterVesselForm.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*"
    );
    this.masterVesselForm.isDefault = true;
    this.masterVesselForm.isType = "ssl";
  }

  handleUpdate() {
    // handle update event
    this.createFlag=false;
    this.modeForm = true;
    this.modeToolbar = true;

    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";

    let dt = this.grid.getSelectedValues()[0];
    let vesId: string = String(dt.vesselId).trim();

    //must check whether this vessel already used in vessel schedule or not
    //if yes, must lock the radio button
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterVesselSchedules/checkVesselIdUsages/" +
          vesId.trim()
      )
      .subscribe((resp) => {
        if (Number.parseInt(resp.json()["count"]) > 0) {
          this.masterVesselForm.lockRadioButton = true;
          //this.masterVesselForm.lockRadioButton = false; // baypass this due to SO-12
        } else {
          this.masterVesselForm.lockRadioButton = false;
        }
      });
    this.masterVesselForm.onRetrieve(vesId);

    this.masterVesselForm.lock = false;
    this.masterVesselForm.isUpdate = true;
  }

  handleDelete() {
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      {
        yes: "this.deleteEvent()",
        no: "this.loading = false;",
      }
    );
  }

  handlePrint() {
    // handle print event
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReport/23/Vessels-Export-Reports/*"
    // );
    var formatName = "report-Vessels-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/23/Vessels-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
  }

  handleSave() {
    // handle save event

    //set date all
    this.masterVesselForm.model.dateLaunch = $("#dateLaunch")
      .find("#datePicker")
      .val();
    this.masterVesselForm.model.dateDelivery = $("#dateDelivery")
      .find("#datePicker")
      .val();
    this.masterVesselForm.model.dateKeel = $("#dateKeel")
      .find("#datePicker")
      .val();

    if (this.masterVesselForm.isType == "ssl") {
      this.masterVesselForm.model.isSsl = true;
      this.masterVesselForm.model.isSlot = false;
      this.masterVesselForm.model.is3RdParty = false;
    }

    if (this.masterVesselForm.isType == "slot") {
      this.masterVesselForm.model.isSsl = false;
      this.masterVesselForm.model.isSlot = true;
      this.masterVesselForm.model.is3RdParty = false;
    }

    if (this.masterVesselForm.isType == "3rd") {
      this.masterVesselForm.model.isSsl = false;
      this.masterVesselForm.model.isSlot = false;
      this.masterVesselForm.model.is3RdParty = true;
    }

    this.masterVesselForm.model.userCreated = this.cook.getName();
    this.masterVesselForm.model.dateCreated = this.genericUtil.getPSIDate();
    console.log("MOTHER VESSEL : "+this.masterVesselForm.model.vesselType);
    if(this.masterVesselForm.model.vesselType.toUpperCase() == "DEFAULT"){
      this.setValidatorDefault();
    }else{
      this.setValidatorMotherVessel();
    }
    console.log(this.masterVesselForm);
    let isValid: boolean = this.onValidate(this.masterVesselForm.model);

    if (!isValid) {
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.masterVesselForm.modeType = false;
    this.modeToolbar = false;

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }

    this.masterVesselForm.isDefault = true;

    this.masterVesselForm.model = new Vessel();
    this.masterVesselForm.form = "";
    this.masterVesselForm.cbVessel.setValue("");
    this.masterVesselForm.cbVesselHistory.setValue("");
    this.masterVesselForm.cbVesselHistory.close();
    this.masterVesselForm.vesselCodeHistory = "";
    this.masterVesselForm.vesselIdHistory = "";
    this.masterVesselForm.cbFormType.setValue("Default");
    this.masterVesselForm.cbIMO.setValue("");
    //this.masterVesselForm.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*');
    this.masterVesselForm.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );

    this.masterVesselForm.isDefault = true;
    this.masterVesselForm.isType = "ssl";
    this.masterVesselForm.lock = false;
    this.masterVesselForm.isUpdate = false;
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.masterVesselForm.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.masterVesselForm.form = "";

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.masterVesselForm.form = "";

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  saveEvent() {
    this.loading = true;
    console.log('debugging')
     console.log(this.masterVesselForm.model.vesselName);
     console.log(this.masterVesselForm.model.cbValue);

     if(this.masterVesselForm.model.vesselName != this.masterVesselForm.model.cbValue && this.createFlag == true){
      console.log('masuk create');
      this.masterVesselForm.model.vesselId = '';
     }
     console.log(this.masterVesselForm.model)
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterVessels/save",
       //"http://localhost:10000/MasterVessels/save",
        this.masterVesselForm.model
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //clear all
            this.masterVesselForm.model = new Vessel();
            this.masterVesselForm.cbVessel.setValue("");
            this.masterVesselForm.cbFormType.setValue("Default");
            this.masterVesselForm.cbVessel.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*"
            );
            this.masterVesselForm.isDefault = true;
            this.masterVesselForm.isType = "ssl";

            this.loading = false;
            this.info = "save successfully";

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  deleteEvent() {
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.vesselId);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterVessels/delete",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            if (resp.json()["status"] == "SUCCESS") {
              this.masterVesselForm.model = new Vessel();
              this.masterVesselForm.cbVessel.setValue("");
              this.masterVesselForm.cbFormType.setValue("Default");
              this.masterVesselForm.cbVessel.setUrl(
                this.configService.config.BASE_API.toString() +
                  "/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*"
              );
              this.masterVesselForm.isDefault = true;
              this.masterVesselForm.isType = "ssl";

              this.loading = false;
              this.message(
                "information",
                "Information",
                "Record deleted successfully. ",
                "okonly",
                { ok: "this.loading=false;this.afterDelete();" }
              );
            } else {
              this.loading = false;
              this.message(
                "information",
                "Information",
                resp.json()["message"],
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  setValidatorDefault(){
    this.masterVesselForm.model['error-vesselCode']="";
    this.masterVesselForm.model['error-callSign']="";
    this.masterVesselForm.model['error-docsysCode']="";
    this.validatorRules = {
      vesselName: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel name",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and -./ space",
          },
          {
            type: "max-length[50]",
            prompt: "Vessel Name must be maximum 50 characters long.",
          },
        ],
      },
      vesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel code",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9 ]*$/,
            prompt: "Only allowed  alphanumeric with space",
          },
        ],
      },
      callSign: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel code",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9 ]*$/,
            prompt: "Only allowed  alphanumeric with space",
          },
        ],
      },
      psaVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel name",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and -./ space",
          },
        ],
      },
      docsysCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your A/C Code",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9 ]*$/,
            prompt: "Only allowed  alphanumeric with space",
          },
        ],
      },
      remarks: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regAllChar,
            prompt: "Please input valid characters",
          },
        ],
      },
    }
  }

  setValidatorMotherVessel(){
    this.masterVesselForm.model['error-vesselCode']="";
    this.masterVesselForm.model['error-callSign']="";
    this.masterVesselForm.model['error-docsysCode']="";
    this.validatorRules = {
      vesselName: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel name",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and -./ space",
          },
          {
            type: "max-length[50]",
            prompt: "Vessel Name must be maximum 50 characters long.",
          },
        ],
      },
      psaVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please enter your vessel name",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed alphanumeric and -./ space",
          },
        ],
      },

      remarks: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regAllChar,
            prompt: "Please input valid characters",
          },
        ],
      },
    }
  }



}
