<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
    <div class="ui medium text loader">Loading</div>
  </div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

    <form class="ui form">

      <div class="field">
        <div class="fields">
          <div class="five wide field"
            [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"
              style="width:100%;"></combo-plugin>
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
              {{model['error-vesselName']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
            <label>Voyage</label>
            <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)"
              style="width:100%;"></combo-plugin>
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
              {{model['error-voyage']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <div class="fields">
          <div class="five wide field" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <label>Bound</label>
            <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['bound']" (keyup)="changeBound()"
              (change)="changeEventBound($event)" (keypress)="noneSpace($event)">
            <div class="ui basic red pointing prompt label transition error-message-hidden"
              [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
              {{model['error-bound']}}
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <fieldset style="border: 1px solid lightgray; height: 100%; width: 30%" id="myfieldset3">

          <legend style="font-weight:bold;color:dodgerblue;">Container Ownnership</legend>

          <div class="ui radio checkbox disabled">
            <input type="radio" name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'"
              (change)="changeEventTransmit($event)" value="SOC" disabled>
            <label>SOC</label>
          </div>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div class="ui radio checkbox">
            <input type="radio" name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'"
              (change)="changeEventTransmit($event)" value="COC">
            <label>COC</label>
          </div>
        </fieldset>
      </div>
    </form>
  </div> <!-- end div container -->
</form>

<pdf-plugin #ediTransmissionList style="margin-left:3px;" [settings]="settingPDF"
  (change)="eventPDFPlugin($event);"></pdf-plugin>
<footer-plugin [info]="info"></footer-plugin>
