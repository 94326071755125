<div class="ui segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
    
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-periodRange'] == null || model['error-periodRange'] == ''">
          {{model['error-periodRange']}}
        </li>
      </ul>
    </div>

    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
     
      <button class="ui button" style="display:none;"></button>
     <div class="six wide fields">
        <div class="one field" style="width:100%" [class.error]="model['error-portofLoadingCode'] != null && model['error-portofLoadingCode'] != ''">
            <label>Port Of Loading</label>
            <combo-plugin #cbpol [settings]="settingPol" (change)="changeEventPol($event)"  (keypress)="CharNumOnly($event)" style="width:100%;"></combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                [class.error-message-visible]="model['error-portofLoadingCode'] != null && model['error-portofLoadingCode'] != ''">
                    {{model['error-portofLoadingCode']}}
            </div>
        </div>
        <div class="one field" style="width:100%">
            <label>Terminal</label>
            <combo-plugin #cbTerminalPol [settings]="settingTerminalPol" (change)="changeEventTerminalPol($event)"  (keypress)="CharNumOnly($event)" [disableCombo]="lockTerminal" style="width:100%;"></combo-plugin>
        </div>
        <div class="one field" style="width:100%">
            <label>Port Of Discharge</label>
            <combo-plugin #cbpod [settings]="settingPod" (change)="changeEventPod($event)"  (keypress)="CharNumOnly($event)" style="width:100%;"></combo-plugin>
        </div>
        <div class="one field" style="width:100%">
            <label>Terminal</label>
             <combo-plugin #cbTerminalPod [settings]="settingTerminalPod" (change)="changeEventTerminalPod($event)"  (keypress)="CharNumOnly($event)" [disableCombo]="lockTerminal" style="width:100%;"></combo-plugin>
        </div>
        <div class="one field" style="width:100%" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <label>Bound</label>
             <input #vslListingBound list="bound" name="bound"  placeholder="Bound"  [(ngModel)]="model['bound']"
             (keypress)="OIMonly($event)"
             maxlength="1"
             required
             autocomplete="off"
             >
             <datalist id="bound">
                 <option value="O">
                 <option value="I">
                 <option value="M">
             </datalist>
             <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
                  {{model['error-bound']}}
                </div>
        </div>
        <div class="one required field" style="width:100%" [class.error]="model['error-localETA'] != null && model['error-localETA'] != ''">
            <label>Period From</label>
            <datetime-plugin #periodFrom  [settings]="settingPeriodFrom"  > </datetime-plugin>
        </div>
        <div class="one required field" style="width:100%" [class.error]="model['error-localETA'] != null && model['error-localETD'] != ''">
            <label>Period To</label>
            <datetime-plugin #periodTo [settings]="settingPeriodTo"  ></datetime-plugin>
        </div>
     </div>
   </form>
   <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1000000"></div>
   <div>
     <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
   </div>
   <div>
     <grid-plugin #gridAllot [settings]="settingGridAllot" (gridEvent)="gridEventAllot($event)" (infoGrid)="infoGridAllot($event)"></grid-plugin>
   </div>
  
</div>
<footer-plugin [info]="info"></footer-plugin>

