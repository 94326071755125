'use strict';
/*
export var BASE_API='http://10.20.222.193:10000';
export var BASE_API_ALLOT='http://10.20.222.193:13000';
export var BASE_API_AUTH = 'http://10.20.222.193:11000';
export var BASE_API_BOOKING = 'http://10.20.222.193:14000';
export var BASE_API_EDI = 'http://10.20.222.193:12000/edi-util';
export var BASE_API_CLL = 'http://10.20.222.193:16000';
export var BASE_API_BL = 'http://10.20.222.193:17000';
*/

/*
export var BASE_API='http://10.20.222.193:9090';
export var BASE_API_ALLOT='http://10.20.222.193:9090';
export var BASE_API_AUTH = 'http://10.20.222.193:9090';
export var BASE_API_BOOKING = 'http://10.20.222.193:9090';
export var BASE_API_EDI = 'http://10.20.222.193:12000/edi-util';
export var BASE_API_CLL = 'http://10.20.222.193:9090';
export var BASE_API_BL = 'http://10.20.222.193:9090';
*/

export var escapeChars = {
    '‘' : 'lsquo',
    '’' : 'rsquo',
    '‚' : 'sbquo',
    '“' : 'ldquo',
    '”' : 'rdquo',
    '„' : 'bdquo',
    '†' : 'dagger',
    '‡'  : 'Dagger',
    '¢' : 'cent',
    '£' : 'pound',
    '¥' : 'yen',
    '€': 'euro',
    '©' :'copy',
    '®' : 'reg',
    '!' : '-33',
    '"' : 'quot',
    '#' : '-35',
    '$' : '-36',
    '%' : '-37',
    '&' : 'amp',
    '\'' : '-39',
    '(' : '-40',
    ')' : '-41',
    '*' : '-42',
    '+' : '-43',
    ',' : '-44',
    '-' : '-45',
    '.' : '-46',
    '/' : '-47',
    ':' : '-58',
    ';' : '-59',
    '<' : 'lt',
    '=' : '-61',
    '>' : 'gt',
    '?' : '-63',
    '@' : '-64',
    '[' : '-91',
    '\\' : '-92',
    ']' : '-93',
    '^' : '-94',
    ' ' : 'space',
    '|' : '-124'
  };

  export var urlChars = {
    '%20' : ' ',
    '%21' : '!',
    '%22' : '"',
    '%23' : '#',
    '%24' : '$',
    '%25' : '%',
    '%26' : '&',
    '%27' : '\'',
    '%28' : '(',
    '%29' : ')',
    '%2A' : '*',
    '%2B' : '+',
    '%2C' : ',',
    '%2D' : '-',
    '%2E' : '.',
    '%2F' : '/',
    '%3A' : ':',
    '%3B' : ';',
    '%3C' : '<',
    '%3D' : '=',
    '%3E' : '>',
    '%3F' : '?',
    '%40' : '@',
    '%5B' : '[',
    '%5C' : '\\',
    '%5D' : ']',
    '%5E' : '^',
    '%5F' : '_',
    '%60' : '`',
    '%7C' : '|'
  };
