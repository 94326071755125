<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true">
    <div class="ui medium text loader">Loading</div>
  </div>

  <div id="container">
    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <span class="coda-config-mtb-2">Transmission to Coda configuration</span>
    <form class="ui form" [formGroup]="codaForm">
      <div class="field">
        <fieldset class="coda-config-border-box">
          <legend class="coda-config-legend">Job Setting</legend>
          <label class="coda-config-label" for="">Every day at time</label>
          <input type="time" name="" formControlName="time" />
        </fieldset>
      </div>
      <div class="field">
        <fieldset class="coda-config-border-box">
          <legend class="coda-config-legend">Report & Exception</legend>

          <div class="ui radio checkbox">
            <input type="radio" name="reportType" value="SYSTEM_LOG" formControlName="reportType" />
            <label>System Log</label>
          </div>
          <br />
          <div class="ui radio checkbox">
            <input type="radio" name="reportType" value="EMAIL" formControlName="reportType" />
            <label>Delivered through email</label>
          </div>
          <br />
          <div *ngIf="codaForm.get('reportType').value === 'EMAIL'">
            <label for="" class="recipient-list-label">Recipient List</label>
            <div class="coda-config-d-flex">
              <div class="add-email">
                <input [class]="codaForm.controls.email.invalid ? 'error' : ''" type="text" name="" formControlName="email" placeholder="Input recipient email" />
                <span *ngIf="codaForm.controls.email.invalid && codaForm.controls.email.touched" class="text-error">Email is not Valid</span>
              </div>
              <button class="coda-config-btn-plus" (click)="handleAddMail()">+</button>
            </div>
            <div class="recipient-email-list">
              <table>
                <tr>
                  <th>No</th>
                  <th>Email</th>
                  <th></th>
                </tr>
                <tr *ngFor="let item of reportDetails; let i=index">
                  <td>{{index}}</td>
                  <td>{{item}}</td>
                  <td>
                    <button class="btn-x-table" (click)="handleRemoveMail(item)">X</button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
</form>

<footer-plugin [info]="info"></footer-plugin>
