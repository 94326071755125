

export class Location {
  no : number = 0 ;
  locationId   : string = '';
  locationCode  : string = '';
  locationName  : string = '';
  isValid       : boolean = true;
  isPort        : boolean = false;
  isPlace       : boolean = false;
  isPot         : boolean = false;
  remarks       : string = '';
  locationBookingRef   : string = '';
  locationAgencyCode  : string = '';
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  countryCode  : string ='';
  countryName  : string = '';
  isPop        : boolean = false;
  agencyCodePop   : string = '';
  hasTerminal  : string = 'N';

  masterLocationTerminals = [];


  constructor() {

  }
}
