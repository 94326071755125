<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:-10px">
        <label class="ui red ribbon small label">Stage 1 : Auto Matching of OBOP</label>
        <br /><br />
        <div class="ui equal width padded grid">
          <!-- start row -->
          <div class="eight wide field">
            <label style="font-size:12px">FILE to be Processed</label>
            <input type="file" id="myfile" style="display:none">
            <div class="inline field">
              <!-- button class="ui primary mini button" id="iduploadFile" (click)="uploadProcess();">Upload</button>
              <label style="font-size:12px">{{model.fileName}}</label -->
              <upload-plugin #obopFileUpload [settings]="settingUploadObopFile" (eventUpload)="uploadEventObopFile($event)"></upload-plugin>
            </div>                        
          </div>
          <div class="eight wide field">
            <label style="font-size:12px">PM4 to be Processed</label>
            <input type="file" id="myfile2" style="display:none">
            <div class="inline field">
              <!-- button class="ui primary mini button" id="uploadPM4" (click)="uploadPM4();">Upload</button>
              <label style="font-size:12px">{{model.pm4FileName}}</label -->
              <upload-plugin #obopPM4FileUpload [settings]="settingUploadObopPM4File" (eventUpload)="uploadEventObopPM4File($event)"></upload-plugin>
            </div>
          </div>

          <div class="sixteen wide inline field">
            <label style="font-size:12px">File type</label>
            
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="frequency" [(ngModel)]="model.fileType" value = "OBOPMC" (ngModelChange)="radioChange($event)">
                <label style="font-size:12px">OBOP (SLOT B/L Matching)</label>
              </div>
            </div>

            
           <!-- <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="frequency" disabled>
                <label style="font-size:12px">OBOP (Create local B/L)</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input type="radio" name="frequency" [(ngModel)]="model.fileType" value = "OBOPSlot" (ngModelChange)="radioChange($event)">
                <label style="font-size:12px">OBOP (Create slot B/L)</label>
              </div>
            </div>-->
          </div>
          <!--<div class="sixteen wide inline fields">
            <div class="field">
              <div class="ui checkbox">
                <input type="checkbox" name="isBLCreated" [checked]="model.isCreateBL" [disabled]="isCreateDisabled">
                <label style="font-size:12px">Proceed to creation of Bill of Lading (BL) after auto-matching ?</label>
              </div>
            </div>
          </div>-->
          <div class="sixteen wide field">
            <label style="font-size:12px">Loader</label>
            <div class="ui input">
              <input type="text" name="secondCarrier" [(ngModel)] = "model.secondCarrier">
            </div>            
          </div>
          <div class="sixteen wide field">
            <label style="font-size:12px">Instruction</label>
            <textarea name="instruction" style="font-size:12px">{{model.messageValue}}
            </textarea>
          </div>
          <!-- end of row -->
        </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<pdf-plugin #obopPdfContainer style="margin-left:3px;" [settings]="settingObopPDF" (change)="eventObopPDFPlugin($event);"></pdf-plugin>

<div id="idPrompterObop" style="display:none;">
  <!-- div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:0px;" -->
    <table border="0" style="width:100%;">
      <tbody>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td style="vertical-align: top;width:12%">
            <div [ngSwitch]="prompterType">
              <div *ngSwitchCase="'information'"><i class="info circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'warning'"><i class="warning sign big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'announcement'"><i class="announcement big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'error'"><i class="minus circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'delete'"><i class="remove circle big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchCase="'save'"><i class="database big icon"></i>&nbsp;&nbsp;</div>
              <div *ngSwitchDefault>{{prompterHeader}}&nbsp;&nbsp;</div>
            </div>
          </td>
          <td>
            <span [innerHTML]="prompterContent"></span>
          </td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:center;">
            <div *ngFor="let btn of prompterButtons" class="ui mini buttons" style="position:inherit;padding-left:0;padding-right:0;">
              <div class="ui mini button" style="margin-left:0px;margin-right:5px;" (click)="clickEvent(btn.event)" >                  
                  <span [innerHTML]="btn.name"></span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  <!-- /div -->
</div>

<footer-plugin [info]="info"></footer-plugin>