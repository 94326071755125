<form class="ui raised segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
        [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)">
    </toolbar-plugin>

    <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
        <div class="ui medium text loader">Loading</div>
    </div>

    <div id='container' class="mt-20">
        <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

        <div class="ui error message" [class.hidden]="isError == false">
            <ul>
                <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == ''">
                    {{model['error-blOceanVesselCode']}}
                </li>
                <li
                    [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == ''">
                    {{model['error-blOceanVesselVoyage']}}
                </li>
                <li
                    [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
                    {{model['error-blOceanVesselBound']}}
                </li>
                <li [class.hidden]="model['error-blLoadPortCode'] == null || model['error-blLoadPortCode'] == ''">
                    {{model['error-blLoadPortCode']}}
                </li>
                <li
                    [class.hidden]="model['error-blPlaceOfDeliveryCode'] == null || model['error-blPlaceOfDeliveryCode'] == ''">
                    {{model['error-blPlaceOfDeliveryCode']}}
                </li>
                <li
                    [class.hidden]="model['error-blPlaceOfReceiptCode'] == null || model['error-blPlaceOfReceiptCode'] == ''">
                    {{model['error-blPlaceOfReceiptCode']}}
                </li>
                <li
                    [class.hidden]="model['error-blDischargePortCode'] == null || model['error-blDischargePortCode'] == ''">
                    {{model['error-blDischargePortCode']}}
                </li>
            </ul>
        </div>

        <form class="ui form">
            <div class="three fields">
                <div class="field">
                    <div class="required one field"
                        [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                        <label>Vessel</label>
                        <combo-plugin #cbVessel [disableCombo]="lockHeader==false" [settings]="settingVessel"
                            (change)="changeEventVessel($event)"></combo-plugin>
                    </div>
                </div>
                <div class="field">
                    <div class="required one field" [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                        <label>Voyage</label>
                        <combo-plugin #cbVoyage [disableCombo]="lockHeader==false" [settings]="settingVoyage" (change)="changeEventVoyage($event)">
                        </combo-plugin>
                    </div>
                </div>
                <div class="field">
                    <div class="required one field" [class.disabled]="lockHeader==false" [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''">
                        <label>Bound</label>
                        <input type="text" name="bound" style="text-transform:uppercase"
                            [(ngModel)]="model['blOceanVesselBound']" (change)="changeBound($event)"
                            placeholder="Bound">
                    </div>
                </div>
            </div>

            <!-- Container Ownership -->
            <div class="two fields">
                <div class="field">
                    <div class="two fields">
                        <div class="field">
                            <fieldset style="border: 1px solid lightgray;">
                                <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                                <div class="field">
                                    <div class="three fields">
                                        <div class="field">
                                            <div class="ui test checkbox">
                                                <input type="checkbox" [checked]="isSOC == true " name="isSOC"
                                                    (change)="changeContainerSOC($event)" tabindex="0" class="hidden">
                                                <label>SOC</label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui test checkbox">
                                                <input type="checkbox" [checked]="isCOC == true " name="isCOC"
                                                    (change)="changeContainerCOC($event)" tabindex="0" class="hidden">
                                                <label>COC</label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui test checkbox">
                                                <input type="checkbox" [checked]="isSLOT == true " name="isSLOT"
                                                    (change)="changeContainerSLOT($event)" tabindex="0" class="hidden">
                                                <label>Slot</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="three fields">
                                        <div class="field">
                                            <div class="ui test checkbox">
                                                <input type="checkbox" [checked]="isSOCTS == true " name="isSOCTS"
                                                    (change)="changeContainerSOCTS($event)" tabindex="0" class="hidden">
                                                <label>SOCTS</label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui test checkbox">
                                                <input type="checkbox" [checked]="isCOCTS == true " name="isCOCTS"
                                                    (change)="changeContainerCOCTS($event)" tabindex="0" class="hidden">
                                                <label>COCTS</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="field">
                        <fieldset style="border: 1px solid lightgray;">
                            <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria By</legend>
                            <div class="two fields">
                                <div class="field">
                                    <div class="ui radio checkbox">
                                        <input type="radio" [checked]="isSelection == 'Vessel'" value="Vessel"
                                            (change)="changeSelection($event)" name="isSelection" tabindex="0"
                                            class="hidden">
                                        <label>Vessel</label>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui tiny right floated buttons">
                                        <button class="ui button retrieve" [class.disabled]="lockButtonRetrieve==true"
                                            (click)="onRetrieve()" data-content="Retrieve">Retrieve</button>
                                    </div>
                                </div>
                            </div>
                            <div class="field" style="margin-top:-20px">
                                <div class="ui radio checkbox">
                                    <input type="radio" [checked]="isSelection == 'BL' " value="BL"
                                        (change)="changeSelection($event)" name="isSelection" tabindex="0"
                                        class="hidden">
                                    <label>B/L</label>
                                </div>
                            </div>
                            <div class="field" style="margin-top:8px">
                                <div class="ui radio checkbox">
                                    <input type="radio" [checked]="isSelection == 'LP' " name="isSelection" value="LP"
                                        (change)="changeSelection($event)" tabindex="0" class="hidden"> <label>Load
                                        Port</label>
                                </div>
                                <div class="required one field" [class.error]="model['error-blLoadPortCode'] != null && model['error-blLoadPortCode'] != ''">
                                    <combo-plugin #cbLoadPort [disableCombo]="lock==false" [settings]="settingLoadPort"
                                        (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                                </div>
                            </div>
                            <div class="field" style="margin-top:8px">
                                <div class="ui radio checkbox">
                                    <input type="radio" [checked]="isSelection == 'DP' " name="isSelection" value="DP"
                                        (change)="changeSelection($event)" tabindex="0" class="hidden">
                                    <label>Discharge Port</label>
                                </div>
                                <div class="required one field" [class.error]="model['error-blDischargePortCode'] != null && model['error-blDischargePortCode'] != ''">
                                    <combo-plugin #cbDischargePort [disableCombo]="lock==false"
                                        [settings]="settingDischargePort" (change)="changeEventDischargePort($event)"
                                        style="width:100%;"></combo-plugin>
                                </div>

                            </div>
                            <div class="field" style="margin-top:8px">
                                <div class="ui radio checkbox">
                                    <input type="radio" [checked]="isSelection == 'POR' " name="isSelection" value="POR"
                                        (change)="changeSelection($event)" tabindex="0" class="hidden">
                                    <label>Place of Receipt</label>
                                </div>
                                <div class="required one field" [class.error]="model['error-blPlaceOfReceiptCode'] != null && model['error-blPlaceOfReceiptCode'] != ''">
                                    <combo-plugin #cbPOR [disableCombo]="lock==false" [settings]="settingPOR"
                                        (change)="changeEventPOR($event)" style="width:100%;"></combo-plugin>
                                </div>
                            </div>
                            <div class="field" style="margin-top:8px">
                                <div class="ui radio checkbox">
                                    <input type="radio" [checked]="isSelection == 'POD' " value="POD"
                                        (change)="changeSelection($event)" name="isSelection" tabindex="0"
                                        class="hidden">
                                    <label>Place of Delivery</label>
                                </div>
                                <div class="required one field" [class.error]="model['error-blPlaceOfDeliveryCode'] != null && model['error-blPlaceOfDeliveryCode'] != ''">
                                    <combo-plugin #cbPOD [disableCombo]="lock==false" [settings]="settingPOD"
                                        (change)="changeEventPOD($event)" style="width:100%;">
                                    </combo-plugin>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="field">
                    <div class="field" [style.display]="addRemoveButton">
                        <button class="tiny ui positive button" data-content="addGrid" (click)="rowEvent('add');"
                            id="addGrid">+</button>
                        <button class="tiny ui negative button" data-content="remGrid" (click)="rowEvent('del');"
                            id="remGrid">-</button>
                    </div>
                    <div class="field">
                        <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)"
                            (infoGrid)="infoGrid($event)">
                        </grid-plugin>
                    </div>
                </div>
            </div>

        </form>
    </div>
</form>