import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ComboPluginComponent, ConfigService, CookieService, GenericService, GenericUtil, PdfPluginComponent } from 'sibego-ui-library';
import { officeModel, newDialogComponent } from '../shared/index';
declare var $: any;

export class Parameter {
  vesselId = '';
  vesselName = '';
  voyage = '';
  bound = 'O';
  cOwner = 'SOC';

  constructor() { }
}


@Component({
  selector: 'app-transaction-bl-same-seal-no-page',
  templateUrl: './transaction-bl-same-seal-no-page.component.html',
  styleUrls: ['./transaction-bl-same-seal-no-page.component.css']
})
export class TransactionBlSameSealNoPageComponent implements OnInit {

  @ViewChild('dialogBLSameContainer') dialogMessage: newDialogComponent;
  @ViewChild('pdfBLSameContainer') pdfContainer: PdfPluginComponent;
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('rSoc') radioSoc: ElementRef;
  @ViewChild('rCoc') radioCoc: ElementRef;
  @ViewChild('rSlot') radioSlot: ElementRef;
  @ViewChild('rAll') radioAll: ElementRef;

  /* Parameter for information into Footer */
  info = '';

  dialog: any;
  modeToolbar = false;
  loading = false;
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';
  autoCreationResultMesssage = '';
  userId: string = "";

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPDF;
  settingDialog;

  model = new Parameter();
  office = new officeModel;
  postData: any = {};
  hitUrl: string = this.configService.config.BASE_API.toString();

  validatorRules = {};

  constructor(
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient) {

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Generate', event: 'generate', icon: 'download' },
        { name: 'Cancel', event: 'cancel', icon: 'cancel' },
        { name: 'Close', event: 'close', icon: 'power' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: "BL with the same Seal No."
    };

    this.settingVessel = {
      id: 'cbVessel',
      type: 'search enter', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'vesselName',
      description: '',
      isMark: true,
      columns: [
        { header: 'Vessel Code', field: 'vesselCode', width: 130 },
        { header: 'Vessel Name', field: 'vesselName', width: 300 },
        { header: 'Valid', field: 'isValid', width: 50 }
      ]
    };

    this.settingVoyage = {
      id: 'cbVoyage',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'voyage',
      description: '',
      isMark: true,
      columns: [
        { header: 'Voyage', field: 'voyage', width: 100 },
        { header: 'Bound', field: 'bound', width: 100 },
        { header: 'Service', field: 'serviceCode', width: 100 }
      ]
    };

    this.settingPDF = {
      id: 'pdfContainerX',
      url: '',
      hidePrint: true,
    };

    this.settingDialog = {
      id: 'dialogObopMC',
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit() {
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  toolbarEvent(event) {
    switch (event) {
      case 'generate':
        if (this.model.vesselId != "" && this.model.voyage != "" && this.model.cOwner != "") {
          this.postData = {};
          this.postData["officeCode"] = this.office.officeCode;
          this.postData["userCreated"] = this.userId;
          this.postData["createdDate"] = this.genericUtil.getPSIDate();
          this.postData["vesselId"] = this.model.vesselId;
          this.postData["vesselName"] = this.model.vesselName;
          this.postData["voyage"] = this.model.voyage;
          this.postData["cOwnerShip"] = this.model.cOwner;
          this.postData["bound"] = "O";
          this.loading = true;

          //console.log(this.postData);
          this.findDuplicateContainerNumber();
        } else {
          //this.message("information","Information","please insert vessel & voyage","okonly",{ok:""});
          this.dialogMessage.showPrompterDialog("information", "Information", "Please insert Vessel & Voyage", [{ name: "OK", event: "" }]);
        }
        break;
      case 'cancel':
        this.model = new Parameter;
        this.cbVessel.setForceValue("");
        this.cbVoyage.setForceValue("");
        this.radioAll.nativeElement.checked = "";
        this.radioSoc.nativeElement.checked = "checked";
        this.radioCoc.nativeElement.checked = "";
        this.radioSlot.nativeElement.checked = "";
        this.autoCreationResultMesssage = '';
        this.disableToolbarButtons = 'print';
        this.postData = {};

        break;
      case 'close':
        // handle close event
        this.autoCreationResultMesssage = '';
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) { }

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event: eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = '';
    }
  }

  radioEvent(check: boolean, type: string) {
    //console.log("redio event "+ check + " - type : "+type );
    if (check) {
      this.model.cOwner = type;
    }
  }


  findDuplicateContainerNumber() {
    var uri = this.hitUrl + "?q=/ContainerLoadList/matching/bl/check-seal";
    this._http.post(uri, this.postData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': localStorage.getItem('token') }), responseType: 'json' }).subscribe((resp) => {
      console.log(resp);
      var result = resp;
      if (result["status"] == "ok") {
        this.showReport(result["session-id"]);
        //this.loading = false;
      } else {
        //this.message("Information","information",result["message"],"okonly",{ok:""});
        this.dialogMessage.showPrompterDialog("information", "Information", result["message"], [{ name: "OK", event: "" }]);
        this.loading = false;
      }
    });
  }

  eventPDFPlugin(event) {
    //console.log(event);
    if (event == "close-pdf") {
      //this.disableToolbarButtons = 'print';
    }
  }

  showReport(sessionId) {
    console.log("oh yee");
    var reportDate = this.postData["createdDate"].split(" ")[0].split("-")[2] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[1] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[0] + "X" + this.postData["createdDate"].split(" ")[1];
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl +
    "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/ListBLWithSameSealNoReport.pdf?sessionId=" +
    sessionId + "&Date=" + reportDate + "&userId=" + this.userId;

    var fileName = "Obop-Matching-Discrepancy-Report-" + this.userId + ".pdf";

    var postDataX = {};
    postDataX["fileName"] = fileName;
    postDataX["url"] = jasperURL;

    this.genericService.POST(hitUrl, postDataX).subscribe((resp) => {
      if (resp.ok) {
        var result = resp.json();

        if (result["message"] == "success") {
          this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.pdfContainer.showPDF();
        }

      }

      this.loading = false;
    });
  }

  eventdialog(event) {
    //console.log(event);
    if (event != "") {
      eval(event);
    }
  }
}
