<!-- <form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <h1><b>REQUEST HISTORY IS CURRENTLY UNDER CONSTRUCTION !</b></h1>
        <p>Please come back again later. :)</p>
        <br/>
        <p><i>GLOSSYS Team</i></p>
      </div>
    </form>
  </div>  <!-- end div container -->
<!-- </form>


<footer-plugin [info]="info"></footer-plugin> -->
<div class="ui segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
    <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
        <div class="ui medium text loader">Loading</div>
    </div>

    <div id='container' [style.display]="modeForm=='sailingScheduleFlagStatus'?'block':'none'">
      <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
      <form class="ui form">
          <div class="sixteen wide fields">
              <div class="four wide field" [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                      {{model['error-vesselName']}}
                  </div>
              </div>
              <div class="four wide field" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                      [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
                      {{model['error-voyage']}}
                  </div>
              </div>
              <div class="field" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
                  <label>Bound</label>
                  <input #sailingBound list="bound" name="bound"  placeholder="Bound" [(ngModel)]="model['bound']" (change)="changeEventBound($event)"

                        maxlength="1"
                        required
                        autocomplete="off"
                        >
                        <datalist id="bound">
                            <option value="O">
                            <option value="I">
                            <option value="M">
                        </datalist>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
                    {{model['error-bound']}}
                  </div>
                </div>
          </div>
          <grid-plugin id="gridHeader" #gridHeader [settings]="settingGridHeader" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </form>
    </div>
    <div id="sailingScheduleMaintenance"  [style.display]="modeForm=='sailingScheduleMaintenance'?'block':'none'">
        <form class="ui form">
            <!-- <br />
            <div class="field">
              <h1><b>TRANSMIT BOOKING DATA TO PSA IS CURRENTLY UNDER CONSTRUCTION !</b></h1>
              <p>Please come back again later. :)</p>
              <br/>
              <p><i>GLOSSYS Team</i></p>
            </div> -->
            <div class="field">
                <!-- <div class="field">
                  <!-- <button class="ui positive button" style="margin-top: 10px" (click)="onProceed()">Save</button> -->
                  <!-- <button class="ui negative button" (click)="hideMe()">Cancel</button> -->
                <!-- </div> -->
            </div>
            <div class="field">
              <div class="fields">
                <div class="five wide field" [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbNewVessel [settings]="settingNewVessel" [class.disabled]="lock == true" (change)="changeEventNewVessel($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                    {{model['error-vesselName']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields">
                <div class="five wide field" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbNewVoyage [settings]="settingNewVoyage" (change)="changeNewEventVoyage($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
                    {{model['error-voyage']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields">
                <div class="five wide field" [class.error]="model['error-dischargePort'] != null && model['error-dischargePort'] != ''">
                 <label>Discharge Port</label>
                 <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                 <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-dischargePort'] != null && model['error-dischargePort'] != ''">
                  {{model['error-dischargePort']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="fields">
                <div class="five wide field" [class.error]="model['error-replacementPort'] != null && model['error-replacementPort'] != ''">
                 <label>Replacement Port</label>
                 <combo-plugin #cbReplacementPort [settings]="settingReplacementPort" (change)="changeEventReplacementPort($event)" style="width:100%;"></combo-plugin>
                 <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-replacementPort'] != null && model['error-replacementPort'] != ''">
                  {{model['error-replacementPort']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <fieldset style="border: 1px solid lightgray; height: 100%; width: 30%" id="myfieldset3">

                <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>

                    <div class="ui radio checkbox" >
                      <input type="radio"  name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'" (change)="changeEventTransmit($event)" value="SOC">
                      <label>SOC</label>
                    </div>


                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui radio checkbox" >
                      <input type="radio"  name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'" (change)="changeEventTransmit($event)" value="COC">
                      <label>COC</label>
                  </div>
              </fieldset>
            </div>
          </form>
    </div>
</div>

<footer-plugin [info]="info"></footer-plugin>

