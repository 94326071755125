<div class="ui segment">
    <div class="ui divided items"> 
       <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

       <div [style.display]="modeForm?'none':'block'">
            <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                <div class="ui medium text loader">Loading</div>
            </div>
            <!-- Begin of Grid -->
            <grid-plugin [settings]="settingGrid" [gridEvent]="setGridEvent" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>      
            <!-- End of Grid -->
        </div>

        <div [style.display]="modeForm?'block':'none'">
            <!-- Begin of Form -->
            <div class="ui segment" style="padding-left:20%;padding-right:20%;">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                    <div class="ui medium text loader">Loading</div>
                </div>

             <form class="ui form">
                <h4 class="ui dividing header"></h4>
				
				<dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
				
                <div class="ui success message" [class.is-success-visible]="isSuccess == true">
                    <i class="close icon"></i>
                    <div class="header">
                        save successful.
                    </div>
                    <!--<p>You may now log-in with the username you have chosen</p>-->
                </div>
                <div class="edit-form" [style.display]="modeEdit?'block':'none'">
                    <h4 class="ui dividing header">{{formType}} Report</h4>
                      <div class="field">
                        <label>Code</label>
                        <input type="text" name="formData['id']" readonly="" placeholder="Code">
                      </div>
                      <div class="field">
                        <div class="required field" id="errMsg-rName" [class.error]="model['error-rName'] != null && model['error-rName'] != ''">
                            <label>Report Name</label>
                            <input type="text" name="formData['rName']" placeholder="Report Name" (keyup)="hideErrorMessage('rName')">
                            <div class = "ui basic red pointing prompt label transition error-message-hidden" id="errTxt-rName" 
                            [class.error-message-visible]="model['error-rName'] != null && model['error-rName'] != ''">
                                <p [innerHtml]="model['error-rName']"></p>
                            </div>
                        </div>
                      </div>
                      <div class="field">
                        <div class="required field" [class.error]="model['error-query'] != null && model['error-query'] != ''">
                            <label>Query</label>
                            <textarea name="formData['query']" (keyup)="hideErrorMessage('query')" ></textarea>
                            <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                            [class.error-message-visible]="model['error-query'] != null && model['error-query'] != ''">
                                <p [innerHtml]="model['error-query']"></p>
                            </div>
                        </div>
                      </div>
                      <div id="param-container">
                        <div class="ui vertical animated button" tabindex="0" id="add-param" (click)="addParam()">
                          <div class="hidden content">Param</div>
                          <div class="visible content">Add</div>
                        </div>
                        <input type="hidden" name="param-count" value="0">
                        <h4 class="ui dividing header"></h4>
                        <div class="param-item-list class" id="param-item-list"></div>    
                      </div>
                </div>

                <div class="run-form" [style.display]="modeRun?'block':'none'">
                    <h4 class="ui dividing header">Run Report</h4>
                      <input type="hidden" name="formData['id']">
                      <div class="field">
                        <label>Report Name</label>
                        <input type="text" name="formData['rName']" placeholder="Report Name" readonly="">
                      </div>                  
                      <input type="hidden" name="param-count" value="0">
                      <h4 class="ui dividing header">Input Parameter</h4>
                      <div id="param-container">
                          <div class="param-item-list run" id="param-item-list-run">                                                    
                          </div>
                      </div>
                </div>
            </form>
            <!-- End of Form -->
            </div>
        </div> 
    </div>
</div>
<footer-plugin [info]="info"></footer-plugin>