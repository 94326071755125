<div class="ui segment" [class.addpadding]="isMobile">
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:0">
    <div class="ui medium text loader">Loading</div>
  </div>
  
  <div id='{{id}}' style='width:100%;overflow-x:scroll'>
   
    <table id='{{id}}-table' class="ui sortable selectable compact celled definition striped table" [class.disabled]="lock==true" [class.manual]="auto==false" >    
      <thead class="full-width" style="font-size:12px;">
        <tr>
          <th class="collapsing" [class.hidden]="!enableSelectAll" style="width:33px;">
            <div class="ui fitted checkbox">
              <input #selectall type="checkbox" checked="{{is_select_all}}" (change)="onSelectAll(selectall.checked)"> <label></label>
            </div>
          </th>
          <th *ngFor="let column of columns" [style.width.px]="column.width" 
            [class.sorted]="column.sort=='true'" 
            [class.descending]="column.sort=='true' && is_sort_direction == 'DESC'" 
            [class.ascending]="column.sort=='true' && is_sort_direction == 'ASC'" 
            (click)="onSort(column.field)" ><span [innerHTML]="column.header"></span></th>
          <th></th>
        </tr>
      </thead>
       <!--
      <tfoot>
          <tr>
              <th>TOTAL</th>
          </tr>   
      </tfoot>
      -->
      <tbody style="font-size:12px;">
        <tr *ngFor="let data of store" style="cursor:pointer;" (dblclick)="onDoubleClick(data.no)">
          
          <td class="collapsing" [class.active]="data.select != ''" [class.hidden]="!enableSelectAll" [style.padding.px]="cbPadding">

            
            <div class="ui fitted checkbox" [style.display]="modeRadio?'none':'block'">               
                <input #select class="case" name="case" type="checkbox" value="{{data.no}}" checked='{{data.select}}' (change)="onSelect(select.checked,data.no);"><label></label>
            </div>

            
            <div class="ui radio checkbox" [style.display]="modeRadio?'block':'none'">               
                <input #rd name="rr" type="radio" value="{{data.no}}" (change)="onSelectRadio(rd.checked,data.no);"><label></label>
            </div>
            
          </td>

          <td *ngFor="let column of columns" [style.width.px]="column.width" [class.active]="data.select != ''" 
          (click)="onClick(select.checked, data.no)"
          (dblclick)="editColumn(data,column)" id="{{id}}.td.{{data.no}}.{{column.field}}">  
          
            <span id="{{id}}.span.{{data.no}}.{{column.field}}" [innerHTML]="data[column.field]"></span>
            
            <input id="{{id}}.text.{{data.no}}.{{column.field}}" type="text" style="display:none;" [style.width.px]="column.width" value="{{data[column.field]}}"  (keypress)="onEnter($event)" (blur)="onBlurColumn()">
            
            <textarea id="{{id}}.textarea.{{data.no}}.{{column.field}}" style="display:none;" [style.width.px]="column.width" (blur)="onBlurColumn()" >{{data[column.field]}}</textarea>

            <div id="{{id}}.combo.{{data.no}}.{{column.field}}" class="ui search icon input" style="display:none;" [class.loading]="loadingCombo == true">  
                    <i class="search icon"></i>

                    <input id="{{id}}.cb-{{data.no}}.{{column.field}}" autocomplete="off" type="text" 
                        [ngModel]="mymodelCombo" 
                        (ngModelChange)="onChangeDataCombo($event)"   
                        (keypress)="onSearchEnterCombo($event)"
                        (blur)="onBlurEnterCombo($event)"
                        class="search"> 

                    
            </div>

          </td>  
          
          <td *ngIf="buttons.length > 0" [style.display]="isMobile == false ?'none':'block'">
              <button *ngFor="let dataButton of buttons" class="ui button" [class.right]="dataButton.icon != null && dataButton.icon != ''" [class.labeled]="dataButton.icon != null && dataButton.icon != ''" [class.icon]="dataButton.icon != null && dataButton.icon != ''"  [class.disabled]="dataButton.enabled == false" (click)='eveButtons(dataButton.event+"-"+data.no)'>
                <i class='right {{dataButton.icon}} icon' *ngIf="dataButton.icon != null && dataButton.icon != ''"></i>
                  {{dataButton.name}}
              </button>
          </td>
                 
        </tr>
      </tbody>    
     
    </table>

  </div>

   <div class="resultss" id="resultss-{{id}}" >
      <div [ngSwitch]="listStoreCombo.store.length">
          
          <div *ngSwitchCase="0">                    
              <div class="message empty" *ngIf="loadingCombo == false">
                  <div class="header">No Results</div>
                  <div class="description">Your search returned no results</div>
              </div>
          </div>

          <div *ngSwitchDefault>
              <table class="result" class="ui selectable table ">
                  <thead>
                      <th *ngFor="let col of columnsCombo" [style.width.px]="col.width">
                           <span [innerHTML]="col.header"></span>
                      </th>
                  </thead>
                  <tbody>
                      <tr style="cursor:pointer;" *ngFor="let data of listStoreCombo.store" (click)="onSelectCombo(data.no)">
                           <td *ngFor="let column2 of columnsCombo">   
                              <span [innerHTML]="data[column2.field]"></span>
                           </td>  
                      </tr>                                
                  </tbody>
              </table>
          </div>
      </div>
          
  </div>

  <div id="pgNation" [class.hidden]="!enablePagination">                   
      <div class="ui label" style="font-size:11px;margin-top:15px;">
          Page {{currentPage}} of {{totalPage}} Pages
      </div>
      
      <div class="ui right floated pagination menu" style="height:40px;margin-top:7px;" [class.disabled]="lock">
        <a class="icon item" (click)='onFirst()' style="border-right:1px solid rgba(34, 36, 38, 0.14902);" [class.disabledButton]="is_disabled_prev">
          <i class="angle double left icon"></i>
        </a>
        <a class="icon item" (click)='onPrev()' [class.disabledButton]="is_disabled_prev">
          <i class="angle left icon"></i>
        </a>
        <div class="icon item" style="padding:3px;">
          <div class="ui input" style="width:50px;padding:3px;">
            <!--
            <input type="text" placeholder="{{currentPage}}" value="{{currentPage}}" (keypress)="onPage($event)" style="text-align:center;font-size:11px;font-weight: bold;">
            -->
            <input type="text" placeholder="{{curPage}}" [ngModel]="curPage" 
            (ngModelChange)="onChangeData($event)" (keypress)="onPage($event)" style="text-align:center;font-size:11px;font-weight: bold;">
        </div>
        </div>       
        <a class="icon item" (click)='onNext()' style="border-right:1px solid rgba(34, 36, 38, 0.14902);" [class.disabledButton]="is_disabled_next">
          <i class="angle right icon"></i>
        </a>
        <a class="icon item" (click)='onLast()' [class.disabledButton]="is_disabled_next">
          <i class="angle double right icon"></i>
        </a>                
      </div>
  </div>

</div>

 