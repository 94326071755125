import { ExceptionBookingService } from "../transaction-ts-exception.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  ExceptionModel,
  ExceptionSplitModel,
} from "../../../shared/models/exceptionModel";
import {
  ComboPluginComponent,
  ConfigService,
  CookieService,
  GenericService,
  GenericUtil,
} from "sibego-ui-library";
import { finalize } from "rxjs/operators";

declare var $: any;
@Component({
  selector: "app-transaction-ts-exception-handling",
  templateUrl: "./transaction-ts-exception-handling.component.html",
  styleUrls: ["./transaction-ts-exception-handling.component.css"],
})
export class TransactionTsExceptionHandlingComponent implements OnInit {
  @Output() redirectToExceptionPage = new EventEmitter<string>();
  @Output() emitMessageDialog = new EventEmitter<string>();
  @Output() confirmAction = new EventEmitter<string>();
  @Input() bPot1Code: string;

  dialog: any;
  modeToolbar = false;
  loading = false;
  isDisableQuantity = false;
  typeSplitMethod = "";

  getRowStyleGridPortPair: any;
  settingToolbar;

  quantityValue = 0;
  typeSplitValue = "SUFFIX";
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";
  valueVesselNameID = "";
  valuevoyageID = "";
  newConnecttingVesselID = "";
  newValuevoyageID = "";
  msg: string = "";
  resultRes: any;
  //GST-20
  valuePodNameID = "";
  valuePodlNameID = "";
  valueFdestNameID = "";

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();

  rowDataGridPortPair = [];
  model = new ExceptionModel();
  mainBkgProspect: any = {};
  mainBkgProspectClone: any = {};
  totalContainerUnit: any = {};
  tsBkgExceptionSplitList: any;
  splitStore = [];
  afterSplitExceptionList = new ExceptionSplitModel();
  afterSplitExceptionListDelete = [];
  settingNewConnectingVessel: any;
  valueVesselNameList = [];
  valueVoyageList = [];
  //GST-20
  valuePodNameList = [];
  valuePodlNameList = [];
  valueFdestNameList = [];

  unconfirmedList = [];
  newConnecttingVesselList = [];
  newConnecttingVoyageList = [];
  newConnectVesselVoyageStore = {
    newConnectingVesselName: "",
    newConnectingVesselCode: "",
    newConnectingVesselId: "",
    newConnectingVoyage: "",
    bound: "",
    newConnectingVesselService: "",
  };

  settingVesselFooter: {
    id: string;
    type: string;
    url: string;
    urlType: string;
    placeholder: string;
    code: string;
    title: string;
  };

  columnDefsGridPortPair: (
    | {
        headerName: string;
        width: number;
        headerCheckboxSelection: boolean;
        headerCheckboxSelectionFilteredOnly: boolean;
        checkboxSelection: boolean;
        showDisabledCheckboxes: boolean;
        field?: undefined;
      }
    | {
        headerName: string;
        field: string;
        width: number;
        headerCheckboxSelection?: undefined;
        headerCheckboxSelectionFilteredOnly?: undefined;
        checkboxSelection?: undefined;
        showDisabledCheckboxes?: undefined;
      }
    | {
        headerName: string;
        field: string;
        width?: undefined;
        headerCheckboxSelection?: undefined;
        headerCheckboxSelectionFilteredOnly?: undefined;
        checkboxSelection?: undefined;
        showDisabledCheckboxes?: undefined;
      }
  )[];

  constructor(
    private exceptionBookingService: ExceptionBookingService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private genericService: GenericService,
    private cookieService: CookieService,
    private renderer: Renderer2
  ) {
    this.handleSetting();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Split", event: "split", icon: "random" },
        { name: "Save", event: "save", icon: "save" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "left arrow" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Exception Handling",
    };

    this.columnDefsGridPortPair = [
      {
        headerName: "",
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: "Load Port", field: "tslLoadPortName", width: 150 },
      {
        headerName: "Terminal(POL)",
        field: "tslLoadPortTerminalName",
        width: 150,
      },
      {
        headerName: "DischargePort",
        field: "tslDischargePortName",
        width: 150,
      },
      {
        headerName: "Terminal(POD)",
        field: "tslDischargePortTerminalName",
        width: 200,
      },
      { headerName: "Allocated(Teus)", field: "tslAllocatedTeus" },
      { headerName: "Booked(Teus)", field: "tslBookedTeus" },
      { headerName: "Confirmed(Teus)", field: "tslConfirmedTeus" },
      { headerName: "Available(Teus)", field: "tslAvailableTeus" },
    ];

    this.settingVesselFooter = {
      id: "cbVesselFooter",
      type: "select",
      url: '[ { "contOprCode" : "Vessel A", "contOprName" : "Vessel A" }, { "contOprCode" : "Vessel B", "contOprName" : "Vessel B" }, ]',
      urlType: "inject",
      placeholder: "Vessel A",
      code: "contOprCode",
      title: "contOprName",
    };

    this.settingNewConnectingVessel = {
      id: "newConnectingVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "locationCode", width: 100 },
        { header: "Vessel Name", field: "locationName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.renderer.listen("window", "click", (e: Event) => {
      if (this.valueVesselNameID) {
        $(this.valueVesselNameID).addClass("d-none");
      }
      if (this.valuevoyageID) {
        $(this.valuevoyageID).addClass("d-none");
      }
      if (this.newConnecttingVesselID) {
        $(this.newConnecttingVesselID).addClass("d-none");
      }
      if (this.newValuevoyageID) {
        $(this.newValuevoyageID).addClass("d-none");
      }
    });
  }

  ngOnInit() {
    this.exceptionBookingService.modelSource$.subscribe((res) => {
      if (res) {
        this.model = res;
        this.loading = true;
        this.exceptionBookingService
          .getExceptionDataHandling(this.model.tsPotBookingNo)
          .pipe(finalize(() => (this.loading = false)))
          .subscribe(
            (res) => {
              const data = res.json();
              if (data.status != "nok") {
                this.mainBkgProspectClone =  JSON.parse(JSON.stringify(data.content.mainBkgProspect));
                this.mainBkgProspect = data.content.mainBkgProspect;
                this.tsBkgExceptionSplitList = data.content.tsBkgExceptionSplitList;
                this.totalContainerUnit = data.content.totalContainerUnit;
                if (data.content.tsBkgExceptionSplitList.length > 0) {
                  this.typeSplitMethod = data.content.tsBkgExceptionSplitList[0].tsSplitMethod;
                  this.typeSplitValue = data.content.tsBkgExceptionSplitList[0].tsSplitMethod;
                  this.renderSplitOld(data.content.tsBkgExceptionSplitList);
                }else{
                  this.typeSplitMethod = "";
                  this.splitStore = [];
                }

              } else {
                this.redirectToExceptionPage.emit("CLOSE");
                this.emitMessageDialog.emit("Data not found");
              }
            },
            (err) => {
              this.redirectToExceptionPage.emit("CLOSE");
              this.emitMessageDialog.emit("An error has occurred");
            }
          );
      }
    });
  }

  renderSplitOld(data){
    this.splitStore = [];
    data.forEach((item) => {
      let obj = {
        tsSplitMethod: item.tsSplitMethod,
        bContainerType: item.tsSplitContainerType,
        newConnectingVesselId: item.tsNewConnectingVesselId,
        newConnectingVesselCode: item.tsNewConnectingVesselCode,
        newConnectingVesselName: item.tsNewConnectingVesselName,
        newConnectingVoyage: item.tsNewConnectingVesselVoyage,
        newConnectingVesselService: item.tsNewConnectingServiceCode,
        bound: item.tsNewConnectingVesselBound,
        bOfficeCodeSplit: item.tsBOfficeCodeSplit,
        newPotBkgNo: item.tsBPrefixSplit + item.tsBNoSplit,
        polBkgNo: item.tsPolFullBNoOrigin,
        potBkgNo: item.tsPotFullBNoOrigin,
        ts20: item.tsBSplitQuantity20,
        ts40: item.tsBSplitQuantity40,
        ts45: item.tsBSplitQuantity45,
        tsH45: item.tsBSplitQuantityH45,
        tsH40: item.tsBSplitQuantityH40,
        tsH20: item.tsBSplitQuantityH20,
        ts20Disabled: false,
        ts40Disabled: false,
        ts45Disabled: false,
        tsH45Disabled: false,
        tsH40Disabled: false,
        tsH20Disabled: false,
        isChecked: false,
        isEdit: item.tsPotFullBNoOrigin === (item.tsBPrefixSplit + item.tsBNoSplit) ? false : true,
      };
      this.splitStore.push(obj);
    });
    setTimeout(() => {
      if(this.splitStore.length > 1){
        this.disableToolbarButtons = "split";
        this.isDisableQuantity = true;
        this.quantityValue = this.splitStore.length - 1;
      }else{
        this.isDisableQuantity = false;
      }
    }, 0);
  }

  ngAfterViewInit() {
    this.typeSplitValue = "SUFFIX"
    this.disableToolbarButtons = "save";
  }

  ngOnDestroy() {
    console.log("---------ngOnDestroy Exception Handling page");
    this.typeSplitValue = "SUFFIX"
    this.disableToolbarButtons = "save";
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "split":
        this.handleSplitException();
        break;
      case "save":
        this.handleSave();
        break;
      case "cancel":
        this.confirmAction.emit("cancel");
        break;
      case "close":
        this.confirmAction.emit("close");
        break;
    }
  }

  handleCancel(){
    this.afterSplitExceptionListDelete = [];
    this.clearAll();
  }

  handleClose(){
    this.quantityValue = 0;
    this.clearAll();
    this.redirectToExceptionPage.emit("CLOSE");
  }


  handleSplitException() {
    if (
      this.quantityValue === 0 ||
      !this.quantityValue ||
      this.quantityValue < 0
    ) {
      this.emitMessageDialog.emit("Please input split quantity.");
      return;
    }

    if(this.quantityValue > 100){
      this.emitMessageDialog.emit("Quantity must be less than 100");
      return;
    }

    if (this.typeSplitValue === "SUFFIX") {
      this.generateBookingNumberByPrefix();
    } else {
      this.generateBookingNumberTemp();
    }
  }

  handleQtyChange(event) {
    const value = event.target.value;
    if (value.trim() === "") {
      this.emitMessageDialog.emit("Please input split quantity.");
      this.quantityValue = 0;
    } else if (parseInt(value.trim()) > 100) {
      this.emitMessageDialog.emit("Quantity must be less than 100");
      this.quantityValue = 100;
    }
  }

  generateBookingNumberByPrefix() {
    if(this.splitStore.length === 0){
      this.splitStore = [];
      let obj = {
        tsSplitMethod: this.typeSplitValue,
        bContainerType: this.mainBkgProspect.containerTypeName,
        newConnectingVesselId: this.mainBkgProspect.connectingVesselId,
        newConnectingVesselCode:  this.mainBkgProspect.connectingVesselCode,
        newConnectingVesselName:  this.mainBkgProspect.connectingVessel,
        newConnectingVoyage: this.mainBkgProspect.connectingVoyage,
        newConnectingVesselService: this.mainBkgProspect.service,
        bound: this.mainBkgProspect.bound,
        bOfficeCodeSplit: this.officeCode,
        newPotBkgNo: "SUDU" + this.mainBkgProspect.potBkgNo,
        polBkgNo: "SUDU" + this.mainBkgProspect.polBkgNo,
        potBkgNo: "SUDU" + this.mainBkgProspect.potBkgNo,
        ts20: this.mainBkgProspect.ts20,
        ts40: this.mainBkgProspect.ts40,
        tsH45: this.mainBkgProspect.tsH45,
        ts45:  this.mainBkgProspect.ts45,
        tsH40: this.mainBkgProspect.tsH40,
        tsH20:  this.mainBkgProspect.tsH20,
        ts20Disabled: false,
        ts40Disabled: false,
        ts45Disabled: false,
        tsH45Disabled: false,
        tsH40Disabled: false,
        tsH20Disabled: false,
        isEdit: false,
        isChecked: false,
        //GST-20
        newPodCode: this.mainBkgProspect.podCode,
        newPodName: this.mainBkgProspect.podName,
        newPodlCode: this.mainBkgProspect.podlCode,
        newPodlName: this.mainBkgProspect.podlName,
        newFdestCode: this.mainBkgProspect.fdestCode,
        newFdestName: this.mainBkgProspect.fdestName,
      };
      this.splitStore.push(obj);
    }
    for (let y = 0; y < this.quantityValue; y++) {
      let obj = {
        tsSplitMethod: this.typeSplitValue,
        bContainerType: this.mainBkgProspect.containerTypeName,
        newConnectingVesselId: "",
        newConnectingVesselCode:  "",
        newConnectingVesselName: "",
        newConnectingVesselService: "",
        bound: "",
        bOfficeCodeSplit: this.officeCode,
        newPotBkgNo: "SUDU" + this.mainBkgProspect.potBkgNo + this.convertToNumberingScheme(y + 1),
        polBkgNo: "SUDU" + this.mainBkgProspect.polBkgNo,
        potBkgNo: "",
        newConnectingVoyage: "",
        ts20: null,
        ts40: null,
        tsH45: null,
        ts45:  null,
        tsH40: null,
        tsH20: null,
        ts20Disabled: this.mainBkgProspect.ts20 === 0,
        ts40Disabled: this.mainBkgProspect.ts40 === 0,
        ts45Disabled: this.mainBkgProspect.ts45 === 0,
        tsH45Disabled: this.mainBkgProspect.tsH45 === 0,
        tsH40Disabled: this.mainBkgProspect.tsH40 === 0,
        tsH20Disabled: this.mainBkgProspect.tsH20 === 0,
        isEdit: true,
        isChecked: false,
        //GST-20
        newPodCode: "",
        newPodName: "",
        newPodlCode: "",
        newPodlName: "",
        newFdestCode: "",
        newFdestName: "",
      };
      this.splitStore.push(obj);
    }
    setTimeout(() => {
      this.disableToolbarButtons = "split";
    }, 0);
  }

  generateBookingNumberTemp() {
    if(this.splitStore.length === 0){
      let obj = {
        tsSplitMethod: this.typeSplitValue,
        bContainerType: this.mainBkgProspect.containerTypeName,
        newConnectingVesselId: this.mainBkgProspect.connectingVesselId,
        newConnectingVesselCode:  this.mainBkgProspect.connectingVesselCode,
        newConnectingVesselName:  this.mainBkgProspect.connectingVessel,
        newConnectingVesselService: this.mainBkgProspect.service,
        bound: this.mainBkgProspect.bound,
        bOfficeCodeSplit: this.officeCode,
        newPotBkgNo: "SUDU" + this.mainBkgProspect.potBkgNo,
        polBkgNo: "SUDU" + this.mainBkgProspect.polBkgNo,
        potBkgNo: "SUDU" + this.mainBkgProspect.potBkgNo,
        newConnectingVoyage: this.mainBkgProspect.connectingVoyage,
        ts20: this.mainBkgProspect.ts20,
        ts40: this.mainBkgProspect.ts40,
        tsH45: this.mainBkgProspect.tsH45,
        ts45:  this.mainBkgProspect.ts45,
        tsH40: this.mainBkgProspect.tsH40,
        tsH20:  this.mainBkgProspect.tsH20,
        ts20Disabled: false,
        ts40Disabled: false,
        ts45Disabled: false,
        tsH45Disabled: false,
        tsH40Disabled: false,
        tsH20Disabled: false,
        isEdit: false,
        isChecked: false,
        //GST-20
        newPodCode: this.mainBkgProspect.podCode,
        newPodName: this.mainBkgProspect.podName,
        newPodlCode: this.mainBkgProspect.podlCode,
        newPodlName: this.mainBkgProspect.podlName,
        newFdestCode: this.mainBkgProspect.fdestCode,
        newFdestName: this.mainBkgProspect.fdestName,
      };
  
      this.splitStore.push(obj);
    }

    for (let y = 0; y < this.quantityValue; y++) {
      let padded = ("000" + y).slice(-4);
      let obj = {
        tsSplitMethod: this.typeSplitValue,
        bContainerType: this.mainBkgProspect.containerTypeName,
        newConnectingVesselId: "",
        newConnectingVesselCode:  "",
        newConnectingVesselName: "",
        newConnectingVesselService: "",
        bound: "",
        bOfficeCodeSplit: this.officeCode,
        newPotBkgNo: "SUDUXXXXXXXX" + padded,
        polBkgNo: "SUDU" + this.mainBkgProspect.polBkgNo,
        potBkgNo: "",
        newConnectingVoyage: "",
        ts20: null,
        ts40: null,
        tsH45: null,
        ts45:  null,
        tsH40: null,
        tsH20: null,
        ts20Disabled: this.mainBkgProspect.ts20 === 0,
        ts40Disabled: this.mainBkgProspect.ts40 === 0,
        ts45Disabled: this.mainBkgProspect.ts45 === 0,
        tsH45Disabled: this.mainBkgProspect.tsH45 === 0,
        tsH40Disabled: this.mainBkgProspect.tsH40 === 0,
        tsH20Disabled: this.mainBkgProspect.tsH20 === 0,
        isEdit: true,
        isChecked: false,
        //GST-20
        newPodCode: "",
        newPodName: "",
        newPodlCode: "",
        newPodlName: "",
        newFdestCode: "",
        newFdestName: "",
      };

      this.splitStore.push(obj);
    }
    setTimeout(() => {
      this.disableToolbarButtons = "split";
    }, 0);
  }

  convertToNumberingScheme(number) {
    var baseChar = "A".charCodeAt(0),
      letters = "";

    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0;
    } while (number > 0);
    return letters.toUpperCase();
  }

  searchVesselName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          "/?q=/MasterSailingSchedules/findVesselListForProspectBkg/" +
          this.officeCode +
          "/" +
          this.bPot1Code +
          `/${val}`
      )
      .subscribe((res) => {
        this.valueVesselNameList = res.json().content;
        $("#valueVesselName_" + index).removeClass("d-none");
        $("#searchVoyageName_" + index).removeAttr("disabled");
        $("#searchVoyageName_" + index).val("");
        this.valueVesselNameID = "#valueVesselName_" + index;
      });
  }

  selectedVesselname(item, index) {
    $("#valueVesselName_" + index).addClass("d-none");
    $("#searchVesselName_" + index).val(item.vesselName);
    this.splitStore[index].newConnectingVesselName = item.vesselName;
    this.splitStore[index].newConnectingVesselCode = item.vesselCode;
    this.splitStore[index].newConnectingVesselId = item.vesselId;
    this.model.vesselIdConnecting = item.vesselId;
    $("#searchVoyageName_" + index).val("");
    this.valueVesselNameID = "";
  }

  searchVoyageName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() + 
        "/?q=/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
         this.officeCode + '/' + this.model.vesselIdConnecting + "/" +
          this.model.connectingBound + 
          "/voyage_like=" + val + "?portOfCall=" + 
        this.model.bPot1Code
      )
      .subscribe((res) => {
        this.valueVoyageList = res.json().content;
        $("#valueVoyageName_" + index).removeClass("d-none");
        this.valuevoyageID = "#valueVoyageName_" + index;
      });
  }

  selectedVoyage(item, index) {
    $("#valueVoyageName_" + index).addClass("d-none");
    $("#searchVoyageName_" + index).val(item.voyage);
    this.splitStore[index].newConnectingVoyage = item.voyage;
    this.splitStore[index].bound = item.bound;
    this.splitStore[index].newConnectingVesselService = item.serviceCode;
    this.valuevoyageID = "";
    if (index === 0) {
      // GST-20
      /*
      if (
        (this.splitStore[index].newConnectingVesselId !=
          this.mainBkgProspect.connectingVesselId &&
          this.splitStore[index].newConnectingVoyage !=
            this.mainBkgProspect.connectingVoyage) ||
        (this.splitStore[index].newConnectingVesselId ===
          this.mainBkgProspect.connectingVesselId &&
          this.splitStore[index].newConnectingVoyage !=
            this.mainBkgProspect.connectingVoyage)
      ) {
      */
      if (
        (this.splitStore[index].newConnectingVesselId !=
          this.mainBkgProspect.connectingVesselId &&
          this.splitStore[index].newConnectingVoyage !=
            this.mainBkgProspect.connectingVoyage) ||
        (this.splitStore[index].newConnectingVesselId !=
          this.mainBkgProspect.connectingVesselId )
      ) {
        this.mainBkgProspect.ts20 = 0;
        this.mainBkgProspect.ts40 = 0;
        this.mainBkgProspect.ts45 = 0;
        this.mainBkgProspect.tsH20 = 0;
        this.mainBkgProspect.tsH40 = 0;
        this.mainBkgProspect.tsH45 = 0;
        const qtyLeft = this.splitStore[index]["ts20"] + this.splitStore[index]["ts40"] + this.splitStore[index]["ts45"] + this.splitStore[index]["tsH20"] + this.splitStore[index]["tsH40"] + this.splitStore[index]["tsH45"];
        if (qtyLeft === 0) this.emitMessageDialog.emit("Main BKG No. Qty should not be a 0 for New Connecting Vessel.");
      } else {
        this.mainBkgProspect.ts20 = this.totalContainerUnit.ts20 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.ts20, 0);
        this.mainBkgProspect.ts40 = this.totalContainerUnit.ts40 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.ts40, 0);
        this.mainBkgProspect.ts45 = this.totalContainerUnit.ts45 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.ts45, 0);
        this.mainBkgProspect.tsH20 = this.totalContainerUnit.tsH20 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.tsH20, 0);
        this.mainBkgProspect.tsH40 = this.totalContainerUnit.tsH40 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.tsH40, 0);
        this.mainBkgProspect.tsH45 = this.totalContainerUnit.tsH45 - this.splitStore.reduce((qty, bkg, index) => index === 0 ? 0 : qty + bkg.tsH45, 0);
      }
    }
  }

  // GST-20
  searchPodName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          `/?q=/MasterLocations/findByComboBoxControlExcludeFreightGroupFDest/locationName=${val}`
      )
      .subscribe((res) => {
        this.valuePodNameList = res.json().content;
        $("#valuePodName_" + index).removeClass("d-none");
        this.valuePodNameID = "#valuePodName_" + index;
      });
  }

  selectedPodName(item, index) {
    $("#valuePodName_" + index).addClass("d-none");
    $("#searchPodName_" + index).val(item.locationName);
    this.splitStore[index].newPodName = item.locationName;
    this.splitStore[index].newPodCode = item.locationCode;
    this.valuePodNameID = "";
  }

  searchPodlName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          `/?q=/MasterLocations/findByComboBoxControlExcludeFreightGroupFDest/locationName=${val}`
      )
      .subscribe((res) => {
        this.valuePodlNameList = res.json().content;
        $("#valuePodlName_" + index).removeClass("d-none");
        this.valuePodlNameID = "#valuePodlName_" + index;
      });
  }

  selectedPodlName(item, index) {
    $("#valuePodlName_" + index).addClass("d-none");
    $("#searchPodlName_" + index).val(item.locationName);
    this.splitStore[index].newPodlName = item.locationName;
    this.splitStore[index].newPodlCode = item.locationCode;
    this.valuePodlNameID = "";
  }

  searchFdestName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          `/?q=/MasterLocations/findByComboBoxControlExcludeFreightGroupFDest/locationName=${val}`
      )
      .subscribe((res) => {
        this.valueFdestNameList = res.json().content;
        $("#valueFdestName_" + index).removeClass("d-none");
        this.valueFdestNameID = "#valueFdestName_" + index;
      });
  }

  selectedFdestName(item, index) {
    $("#valueFdestName_" + index).addClass("d-none");
    $("#searchFdestName_" + index).val(item.locationName);
    this.splitStore[index].newFdestName = item.locationName;
    this.splitStore[index].newFdestCode = item.locationCode;
    this.valueFdestNameID = "";
  }

  // ----------


  clearAll() {
    if(this.typeSplitMethod === "NEWREF"){
      this.typeSplitValue = "NEWREF";
    }else{
      this.typeSplitValue = "SUFFIX";
    }

    this.msg = "";
    this.disableToolbarButtons = "save";
    this.mainBkgProspect = {...this.mainBkgProspectClone};
    if(this.splitStore.length > 0){
      this.renderSplitOld(this.tsBkgExceptionSplitList);
    }else{
      this.isDisableQuantity = false;
      this.splitStore = [];
    }
    this.valueVesselNameList = [];
    this.valueVoyageList = [];
    this.afterSplitExceptionListDelete = [];
  }

  calculateSplit(event, typeTs, index) {
    let dataSplit = this.splitStore.filter((x) => x.isEdit === true);
    let totalInit = dataSplit
      .map((x) => x[typeTs])
      .reduce((a, b) => {
        return a + b;
      });

    let result = this.totalContainerUnit[typeTs] - totalInit;
    if (result < 0) {
      this.splitStore[index][typeTs] = 0;
      this.emitMessageDialog.emit(
        "Total quantity of container(s) entered exceeds quantity of selected container type in Main booking. Do amend the quantity or remove unnecessary rows."
      );
      return;
    }

    this.mainBkgProspect[typeTs] = this.mainBkgProspect[typeTs] === 0 ? 0 : result;
    this.splitStore[0][typeTs] = result;

    this.splitStore.forEach((item, index) => {
      if (index !== 0 && this.splitStore[0][typeTs] === 0 && (item[typeTs] == 0 || item[typeTs] == null || item[typeTs] == "")) {
        item[`${typeTs}Disabled`] = true;
      } else {
        item[`${typeTs}Disabled`] = false;
      }
    });
  }

  noneNum(event: any) {
    const regexPattern = /[0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  selecteItemToDelete(val, item) {
    if (val === true) {
      this.afterSplitExceptionListDelete.push(item.newPotBkgNo);
    }
  }

  deleteAfterSplitExceptionItem() {
    this.afterSplitExceptionListDelete.forEach((item) => {
      this.splitStore = this.splitStore.filter((x) => x.newPotBkgNo != item);
    });
    setTimeout(() => {
      this.afterSplitExceptionListDelete = [];
    }, 0);
  }

  handleSave(){
    //GST-20
    //const isVoyageChanged = Boolean((this.splitStore[0].newConnectingVesselId != this.mainBkgProspect.connectingVesselId && this.splitStore[0].newConnectingVoyage != this.mainBkgProspect.connectingVoyage) || (this.splitStore[0].newConnectingVesselId === this.mainBkgProspect.connectingVesselId && this.splitStore[0].newConnectingVoyage != this.mainBkgProspect.connectingVoyage));
    const isVesselChanged = Boolean(
      (this.splitStore[0].newConnectingVesselId != this.mainBkgProspect.connectingVesselId && this.splitStore[0].newConnectingVoyage != this.mainBkgProspect.connectingVoyage) || 
      (this.splitStore[0].newConnectingVesselId != this.mainBkgProspect.connectingVesselId ));
    
    const qtyLeft = this.splitStore[0]["ts20"] + this.splitStore[0]["ts40"] + this.splitStore[0]["ts45"] + this.splitStore[0]["tsH20"] + this.splitStore[0]["tsH40"] + this.splitStore[0]["tsH45"];
   
    if (isVesselChanged && qtyLeft === 0) {
      this.emitMessageDialog.emit("Main BKG No. Qty should not be a 0 for New Connecting Vessel.");
    } else {
      
      this.msg = "";
      this.mainBkgProspect.bContainerType = this.mainBkgProspect.containerTypeName;
      let body = {
        userId: localStorage.getItem("logged").split("|")[0],
        mainBkgProspect: this.mainBkgProspect,
        tsBkgExceptionSplitList: this.splitStore
      }
      console.log(body)
      
      this.loading = true;
      this.exceptionBookingService
        .saveExceptionHandleLink(body)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe(res=>{
          const result = res.json();
          this.resultRes = res.json();
          if (result.status === 'ok'){
            this.emitMessageDialog.emit(result.message);
            this.redirectToExceptionPage.emit("CLOSE_SAVE");
          } else {
            this.handleErrorSaveSplit(result)
          }
        }, err => {
          console.error(err);
        });
        
    }
  }

  handleErrorSaveSplit(res){
    let responseCode = res.responseCode;
    if(responseCode === '1'){
      this.msg = "No quantity detected for below booking(s): <br/>" + res.content.emptyQuantityList.join(', ') + "<br/><br/>&lt;Yes&gt; to proceed, these booking(s) will be removed. <br/> &lt;No&gt; to edit.";
      this.genericUtil.showDialog("handleErrorWhenSave", "Information", 650, 230);
    }

    if(responseCode === '2'){
      this.emitMessageDialog.emit("Booked quantity detected but missing New Connecting Vessel / New Connecting Voyage.");
    }

    // if(responseCode === '3'){
    //   // If = same connecting vessel connecting voyage, system to prompt “Please check New Connecting Vessel / New Connecting Voyage for XXX.”
    // }

    if(responseCode === '4'){
      // If = same connecting vessel connecting voyage, system to prompt “Please re-select New Connecting Vessel / New Connecting Voyage.”
      this.msg =
      "Please re-select New Connecting Vessel / New Connecting Voyage for <br/>" +
      `POT BKG: ${res.content[0].potBkgNo} <br/>` +
      `POL BKG: ${res.content[0].polBkgNo} <br/><br/>` +
      "Possible reason(s):<br/>" +
      "- Missing Port-Pair allocation <br/>" +
      "- Insufficient Available (Teus) <br/>" +
      "- Tight Connection" +
      "<br/><br/>&lt;OK&gt; to proceed and re-select. <br/> &lt;Cancel&gt; to stop this exception handing process.";

      this.genericUtil.showDialog(
        "handleErrorWhenSave",
        "Information",
        650,
        320
      );
     
    }

    if(responseCode === '5'){
      //show prompt: Difference = X hours Y mins or -X hours Y mins
      this.unconfirmedList = res.content.notReadyList;
      if( this.unconfirmedList.length > 0){
        this.unconfirmedList.forEach(item=>{
          item.isConfirm = "N";
        })
        this.handleUnconfirmedList();
      }
    }

  }

  handleUnconfirmedList(){
    let heightDialog = 215;
    if(this.unconfirmedList.length > 3){
      heightDialog = 450;
    }
    if(this.unconfirmedList.length == 3){
      heightDialog = 350;
    }
    if(this.unconfirmedList.length == 2){
      heightDialog = 280;
    }
    if(this.unconfirmedList.length == 1){
      heightDialog = 250;
    }

    let widthWindow = window.innerWidth;
    let widthDialog = 1550; //From Full HD and above
    // HD+	1600x900
    if (widthWindow < 1700) {
      widthDialog = 1200;
    }
    // HD 1366x768
    if (widthWindow < 1400) {
      widthDialog = 900;
    }
    if (widthWindow < 1000) {
      widthDialog = 600;
    }

    this.genericUtil.showDialog(
      `UnconfirmedList`,
      "Unconfirmed List",
      widthDialog,
      heightDialog
    );
  }

  returnTime (connectingVesselPotEta, dischargingVesselPotEtd) {
    let msDate1 = new Date(connectingVesselPotEta);
    let msDate2 = new Date(dischargingVesselPotEtd);
    let ispositiveNumbers = true;    
    if(Number(msDate1) - Number(msDate2) < 0){
      ispositiveNumbers = false;
    }else{
      ispositiveNumbers = true;
    }
    let ms = Math.abs(Number(msDate1) - Number(msDate2))
    const days = Math.floor(ms / (24*60*60*1000));
    const daysms = ms % (24*60*60*1000);
    const hours = Math.floor(daysms / (60*60*1000));
    const hoursms = ms % (60*60*1000);
    const minutes = Math.floor(hoursms / (60*1000));
    const minutesms = ms % (60*1000);
    // const sec = Math.floor(minutesms / 1000);

    let daysInfo = days === 0 ? "" : `${days} days`;
    return ispositiveNumbers ? `${daysInfo} ${hours} hours ${minutes} minutes` : `-${daysInfo} ${hours} hours ${minutes} minutes`;
  }
  
  closeDialog(){
    this.genericUtil.closeDialog("handleErrorWhenSave");
  }

  closeUnconfirmedList(){
    this.genericUtil.closeDialog("UnconfirmedList");
  }

  confirmUnconfirmedList(){
    this.unconfirmedList.forEach(uncf=>{
      this.splitStore.forEach(splip=>{
        splip.isMandatory = "Y";
        if(uncf.newPotBkgNo === splip.newPotBkgNo){
          splip.newConnectingVesselCode = uncf.isConfirm === "N" ? uncf.newConnectingVesselCode2 : uncf.newConnectingVesselCode;
          splip.newConnectingVesselId = uncf.isConfirm === "N" ? uncf.newConnectingVesselId2 : uncf.newConnectingVesselId;
          splip.newConnectingVesselName = uncf.isConfirm === "N" ? uncf.newConnectingVesselName2 : uncf.newConnectingVesselName;
          splip.newConnectingVesselService = uncf.isConfirm === "N" ? uncf.newConnectingVesselService2 : uncf.newConnectingVesselService;
          splip.newConnectingVoyage =uncf.isConfirm === "N" ? uncf.newConnectingVoyage2 : uncf.newConnectingVoyage;
        }
      })
    })

    this.genericUtil.closeDialog("UnconfirmedList");
    this.handleSave();
  }

  handleResponseCode1(){
    let emptyQuantityList = this.resultRes.content.emptyQuantityList;
    emptyQuantityList.forEach((item) => {
      this.splitStore = this.splitStore.filter((x) => x.newPotBkgNo != item);
    });
    if(this.splitStore.length === 1){
      this.disableToolbarButtons = "save";
    }
    this.genericUtil.closeDialog("handleErrorWhenSave");
  }

  handleResponseCode4(){
    this.genericUtil.closeDialog("handleErrorWhenSave");
    let potBkgNo = this.resultRes.content[0].potBkgNo;
    let index = this.splitStore.findIndex(item => item.newPotBkgNo === potBkgNo);
    
    $("#searchVesselName_" + index).focus();
  }

  newConnecttingVessel(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          "/?q=/MasterSailingSchedules/findVesselListForProspectBkg/" +
          this.officeCode +
          "/" +
          this.bPot1Code +
          `/${val}`
      )
      .subscribe((res) => {
        this.newConnecttingVesselList = res.json().content;
        $("#newConnecttingVessel_" + index).removeClass("d-none");
        $("#ValueNewVoyageName_" + index).removeAttr("disabled");
        $("#ValueNewVoyageName_" + index).val("");
        this.newConnecttingVesselID = "#newConnecttingVessel_" + index;
      });
  }

  selectedNewVesselname(item, index) {
    $("#newConnecttingVessel_" + index).addClass("d-none");
    $("#valueNewConnecttingVessel_" + index).val(item.vesselName);
    this.unconfirmedList[index].newConnectingVesselName2 = item.vesselName;
    this.unconfirmedList[index].newConnectingVesselCode2 = item.vesselCode;
    this.unconfirmedList[index].newConnectingVesselId2 = item.vesselId;
    this.newConnectVesselVoyageStore.newConnectingVesselId = item.vesselId;
    $("#ValueNewVoyageName_" + index).val("");
    this.newConnecttingVesselID = "";
  }

  searchNewVoyageName(event, index) {
    let val = event.target.value ? event.target.value.toUpperCase() : "[-42]";
    this.genericService
      .get(
        this.configService.config.BASE_API.toString() +
          "/?q=/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
          this.officeCode +
          "/" +
          this.newConnectVesselVoyageStore.newConnectingVesselId+
          "/O" +
          `/voyage_like=${val}?portOfCall=${this.bPot1Code}`
      )
      .subscribe((res) => {
        this.newConnecttingVoyageList = res.json().content;
        $("#newValueVoyageName_" + index).removeClass("d-none");
        this.newValuevoyageID = "#newValueVoyageName_" + index;
      });
  }

  selectedNewVoyage(item, index) {
    $("#newValueVoyageName_" + index).addClass("d-none");
    $("#ValueNewVoyageName_" + index).val(item.voyage);
    this.unconfirmedList[index].vesselPortOfCallEta = item.vesselPortOfCallEta;
    this.unconfirmedList[index].newConnectingVoyage2 = item.voyage;
    this.unconfirmedList[index].bound2 = item.bound;
    this.unconfirmedList[index].newConnectingVesselService2 = item.serviceCode;
    this.newValuevoyageID = "";
  }

  proceedConnectChange(val, index){
    if(val === "Y"){
      $("#valueNewConnecttingVessel_" + index).attr('disabled','disabled');
      $("#valueNewConnecttingVessel_" + index).val("");
      $("#ValueNewVoyageName_" + index).attr('disabled','disabled');
      $("#ValueNewVoyageName_" + index).val("");
      this.unconfirmedList[index].vesselPortOfCallEta = "";
    }else{
      $("#valueNewConnecttingVessel_" + index).removeAttr("disabled");
    }
  }

  keypressInputsearchVesselName(event, index){
    let val = event.target.value;
    if(!val){
      $("#searchVesselName_" + index).val("");
      this.splitStore[index].newConnectingVesselName = "";
      this.splitStore[index].newConnectingVesselCode = "";
      this.splitStore[index].newConnectingVesselId = "";
      this.splitStore[index].newConnectingVoyage = "";
      this.splitStore[index].bound = "";
      this.splitStore[index].newConnectingVesselService = "";
      this.model.vesselIdConnecting = "";
      $("#searchVoyageName_" + index).val("");
      this.valueVesselNameID = "";

    }
  }

  keypressInputSearchVoyageName(event, index){
    let val = event.target.value;
    if(!val){
      this.splitStore[index].newConnectingVoyage = "";
      this.splitStore[index].bound = "";
      this.splitStore[index].newConnectingVesselService = "";
    }
  }

  keypressInputNewConnecttingVessel(event, index){
    let val = event.target.value;
    if(!val){
      this.unconfirmedList[index].newConnectingVesselName2 = "";
      this.unconfirmedList[index].newConnectingVesselCode2 = "";
      this.unconfirmedList[index].newConnectingVesselId2 = "";
      this.newConnectVesselVoyageStore.newConnectingVesselId = "";
      $("#ValueNewVoyageName_" + index).val("");
      this.newConnecttingVesselID = "";
    }
  }

  keypressInputNewVoyageName(event, index){
    let val = event.target.value;
    if(!val){
      this.unconfirmedList[index].vesselPortOfCallEta = "";
      this.unconfirmedList[index].newConnectingVoyage2= "";
      this.unconfirmedList[index].bound2 = "";
      this.unconfirmedList[index].newConnectingVesselService2 = "";
      this.newValuevoyageID = "";
    }
  }

  //GST-20
  keypressInputsearchPodName(event, index){
    let val = event.target.value;
    if(!val){
      $("#searchPodName_" + index).val("");
      this.splitStore[index].newPodName = "";
      this.splitStore[index].newPodCode = "";
      this.valuePodNameID = "";

    }
  }

  keypressInputsearchPodlName(event, index){
    let val = event.target.value;
    if(!val){
      $("#searchPodlName_" + index).val("");
      this.splitStore[index].newPodlName = "";
      this.splitStore[index].newPodlCode = "";
      this.valuePodlNameID = "";

    }
  }

  keypressInputsearchFdestName(event, index){
    let val = event.target.value;
    if(!val){
      $("#searchFdestName_" + index).val("");
      this.splitStore[index].newFdestName = "";
      this.splitStore[index].newFdestCode = "";
      this.valueFdestNameID = "";

    }
  } 
  // ------

  onGridReadyGridPortPair(event) {}

  rowDoubleClickGridPortPair(event) {}

  onSelectionChangedGridPortPair(event) {}

  changeEventVesselFooter(event) {}
}
