export class HSCode  {
  hsId    : string = '';
  hsCode  : string = '';
  hsDescription : string = '';
  hsUom   : string = '';
  refrenceId : string = '';
  uploadBy  : string ='';
  dateAndTime  : string ='';

  constructor () { }
}
