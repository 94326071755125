

export class ReceiptAndDeliveryType  {
  no : number = 0;
  receiptAndDeliveryTypeId    : string = '';
  receiptAndDeliveryTypeCode  : string = '';
  receiptAndDeliveryTypeName  : string = '';
  remarks       : string = '';
  dataType:string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';

  constructor () { }
}
