import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { specialCharUtil } from "../shared";
declare var $: any;

export class BookingTransfer {
  vesselId: string = "";
  varBound: string = "";
  voyageCode: string = "";
  varReason: string = "";
  dateModified : string = "";

  //bookingmsg:boolean=false;
  //destinationVoyage:string='';
  //destinationBound:string='';

  constructor() {}
}

@Component({
  selector: "app-transaction-unlock-bl-page",
  templateUrl: "./transaction-unlock-bl-page.component.html",
  styleUrls: ["./transaction-unlock-bl-page.component.css"],
})
export class TransactionUnlockBLPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  dialog: any;

  // * Special Char
  sCharUtil = new specialCharUtil();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm = "";

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingVessel;
  settingVoyage;

  //variable hold
  boundVar: string = "";
  officeCode: string = "";

  // lock
  cButtonLock = true;
  dButtonLock = true;
  isError: Boolean = false;

  model = new BookingTransfer();
  isUnlock = "chVessel";
  lockBound: Boolean = false;

  validatorRules = {};
  gridStore = [];

  modeUnlock = "";

  lockReason: boolean = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();
    this.model.vesselId = "";
    this.model.varBound = "";
    this.model.voyageCode = "";
    this.model.varReason = "";

    this.settingToolbar = {
      buttonsFront: [
        // {name: 'Retrieve', event: 'retrieve', icon: 'download'},
        { name: "Unlock", event: "unlock", icon: "unlock alternate" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "BL Unlock",
    };

    this.settingGrid = {
      id: "grid",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral', */,
      page: 5,
      head: 200,
      columns: [
        { header: "BL Ref.", field: "blNo", width: 150, editType: "text" },
        {
          header: "Tab Selection",
          field: "tabSelection",
          width: 150,
          editType: "select", //editType:'combo',
          ds: [
            { code: "T8", name: "ALL" },
            { code: "T1", name: "Party" },
            { code: "T2", name: "Place" },
            { code: "T3", name: "Container" },
            { code: "T4", name: "Mark No" },
            { code: "T5", name: "Desc" },
            { code: "T6", name: "Nett Charge" },
            { code: "T7", name: "Gross Charge" },
          ],
          titleSelect: "reasonCode",
          codeSelect: "tabSelection",
          maxChars: 0,
        },
        {
          header: "Reason For Unlocking",
          field: "varReason",
          width: 300,
          editType: "text",
        },
        {
          header: "Requested by",
          field: "varRequest",
          width: 150,
          editType: "text",
        },
        {
          header: "Requested Time",
          field: "varRequestTime",
          width: 180,
          editType: "calendar",
          calendarType: "dateCustomCurrentTime",
          calendarformat: "yyyy-mm-dd hh:ii:ss",
        },
        { header: "Result Message", field: "rMessage", width: 300 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enableSelectedRows: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      editable: true,
    };

    // this.settingVessel = {
    //   id          : 'cbVessel',
    //   type        : 'search enter', // search | select | select input
    //   url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
    //   maxChars    : 3,
    //   template    : 'grid', // default
    //   placeholder : 'Search Vessel',
    //   title       : 'vesselName',
    //   description  : '',
    //   columns     : [
    //     {header: 'Vessel Code', field: 'vesselCode', width: 200},
    //     {header: 'Vessel Name', field: 'vesselName', width: 350},
    //     {header: 'Valid', field: 'isValid', width: 50}
    //   ]
    // }

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };
  }

  ngOnInit() {
    this.isError = false;
  }

  ngAfterViewInit() {
    // $('grid-plugin').find('tbody').css('font-size','12px');
    $(".dataTables_scrollBody").css("height", "200px");
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();
    this.disableToolbarButtons = "unlock";
    this.cButtonLock = true;
    this.dButtonLock = true;
    this.modeUnlock = "V";

    //set default bound = "O"
    this.model.varBound = "O";
  }

  addNewRow() {
    this.grid.listStore.store.push({
      id: (this.grid.listStore.store.length + 1).toString(),
      blNo: "",
      rMessage: "",
      valid: "",
      varReason: "",
      varRequest: "",
      varRequestTime: "",
      tabSelection: "ALL",
    });
    this.grid.listStore.store.forEach((tt) => {
      // // // // console.log(tt);
    });

    this.grid.loadData();
    //this.grid.is_select_all = 'checked';
    this.grid.onSelectAllByValue(true);
    this.grid.is_select_all = "checked";
  }

  handleRetrieve() {
    this.setValidatorUnlock();

    this.isError = this.onValidate(this.model);

    if (this.isError == true) {
    } else {
      this.retrieveBlList();
    }
  }

  validateUnlock() {
    if (this.isUnlock == "chVessel") {
      this.setValidatorUnlock();

      this.isError = this.onValidate(this.model);

      if (this.isError == true) {
        // // // // console.log('test');
      } else {
        this.handleUnlock();
      }
    } else {
      this.handleUnlock();
    }
  }

  handleUnlock() {
    // // // // console.log('masuk');
    var pTool = 0;
    var tempVar = [];
    console.log(this.grid.listStore.store);
    /*
    this.setValidatorReason();
    this.isError = this.onValidate(this.model);
    // // // // console.log(this.isError);
    // // // // console.log('varreason'+this.model.varReason);
    if(this.isError == true){
      // // // // console.log('kosong tuh reason')
    }
    else{
      */
    // // // // console.log('mode unlock');
    // // // // console.log(this.modeUnlock);

    if (this.modeUnlock == "V") {
      this.gridStore = [];
      this.grid.listStore.store.forEach((ute) => {
        // // // // console.log(ute);
        //if(ute.select == "checked"){
        if (ute.valid == "N") {
          this.gridStore.push({
            id: ute.id,
            blNo: ute.blNo.trim(),
            rMessage: ute.rMessage.toUpperCase().trim(),
            valid: ute.valid,
            office: this.officeCode,
            userid: this.cookieService.getName(),
            varReason: ute.varReason.toUpperCase().trim(),
            varRequest: ute.varRequest.toUpperCase().trim(),
            varRequestTime: ute.varRequestTime,
            tabSelection: "T8",
            dateModified : this.genericUtil.getPSIDate(),
          });
        } else {
          pTool = 1;
        }

        //}
      });

      this.message(
        "information",
        "Information",
        "Confirm to unlock BL Reference(s)?",
        "yesno",
        {
          yes: "this.actionUnlock()",
          no: "// // // // console.log('cancel clicked')",
        }
      );
    } else {
      this.gridStore = [];
      this.grid.listStore.store.forEach((ute) => {
        // // // // console.log(ute);
        if (ute.select == "checked") {
          if (ute.valid == "N") {
            this.gridStore.push({
              id: ute.id,
              blNo: ute.blNo.toUpperCase().trim(),
              rMessage: ute.rMessage.toUpperCase().trim(),
              valid: ute.valid,
              office: this.officeCode,
              userid: this.cookieService.getName(),
              varReason: ute.varReason.toUpperCase().trim(),
              varRequest: ute.varRequest.toUpperCase().trim(),
              varRequestTime: ute.varRequestTime,
              tabSelection: ute.tabSelection,
              dateModified : this.genericUtil.getPSIDate(),
            });
          } else {
            pTool = 1;
          }
        }
      });

      if (pTool > 0) {
        this.message(
          "information",
          "Information",
          "Unable to unlock BL Reference(s)! Please check details.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        //this.actionUnlock();
        this.message(
          "information",
          "Information",
          "Confirm to unlock Bl Reference(s)?",
          "yesno",
          {
            yes: "this.actionUnlock()",
            no: "// // // // console.log('cancel clicked')",
          }
        );
      }
    }

    //}
  }

  actionUnlock() {
    var datas = "";
    //this.gridStore.forEach(elex=>{
    console.log("isi gridstore : ");
    console.log(this.gridStore);
    //});

    let url = this.configService.config.BASE_API.toString() + "/blunlock/doUnlock";
    if (this.isUnlock == "chVessel") {
      url += "/" + this.model.vesselId + "/" + this.sCharUtil.htmlEncode(String(this.model.voyageCode).trim()) + "/" + this.model.varBound + "/" + this.officeCode;
    }
    this.genericService
      .POST(url,
        this.gridStore
      )
      .subscribe(
        (resp) => {
          datas = resp.json()["result"];
          // // // // console.log('isi datas');
          // // // // console.log(datas);
          //// // // // console.log(data.blNo);
          if (datas == "success") {
            this.message(
              "information",
              "Information",
              "BL Reference(s) unlocked successfully.",
              "okonly",
              { ok: "// // // // console.log('ok click')" }
            );
            this.loading = false;
            this.disableToolbarButtons = "unlock";

            this.grid.listStore.store = [];

            this.gridStore.forEach((uwa) => {
              this.grid.listStore.store.push(uwa);
            });

            // // // // console.log('isi terbaru');
            // // // // console.log(this.grid.listStore.store)
            if (this.modeUnlock == "B") {
              this.grid.listStore.store.forEach((gh) => {
                //this.gridStore.forEach(ele=>{
                //if(gh.blNo == ele.blNo){
                gh.rMessage = "BL Reference(s) unlocked successfully.";
                gh.valid = "Y";
                //}
                //})
              });
              this.grid.loadData();
              this.grid.onSelectAllByValue(true);
              this.grid.is_select_all = "checked";
              this.grid.disable();
              this.disableToolbarButtons = "unlock";
              this.cButtonLock = true;
              this.dButtonLock = true;
            }
          } else if (datas == "permanently_locked") {
            this.message(
              "information",
              "Information",
              '<p style="color: red;">Unable to unlock. Vessel departed more than 1 month. Please send unlock request.</p>',
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "There are some error occur while saving the record, error message : " +
              datas +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  removeRow() {
    // // // // console.log("PANJANG GRIDNYA: "+this.grid.onSelect.length);

    // // // // console.log("masuk remove");
    var seqNo = 0;
    var countCheck = 0;
    var boxCheck: number;
    var storeTemp = new ListStore();

    this.grid.listStore.store.forEach((xy) => {
      // // // // console.log("xy "+xy);
      storeTemp.addData(xy);
    });

    storeTemp.store.forEach((el) => {
      if (el["select"] == "checked") {
        boxCheck = this.grid.listStore.store.indexOf(el);
        this.grid.listStore.store.splice(boxCheck, 1);

        $('input[name="gridCount"]').val(
          parseInt($('input[name="gridCount"]').val()) - 1
        );
      } else {
        this.disableToolbarButtons = "unlock";
      }
    });

    if (this.grid.listStore.store.length == 0) {
      $('input[name="gridCount"]').val(0);
      this.disableToolbarButtons = "unlock";
    }

    this.grid.listStore.store.forEach((col) => {
      seqNo = seqNo + 1;
      col.seqNo = seqNo;
    });

    this.grid.loadData();
    this.grid.clearSelectedValues();
  }

  setValidatorReason() {
    this.validatorRules = {
      varReason: {
        rules: [
          {
            type: "empty",
            prompt: "Please Input Reason. ",
          },
        ],
      },
    };
  }

  setValidatorUnlock() {
    this.validatorRules = {
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please Input Vessel. ",
          },
        ],
      },
      voyageCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please Input Voyage. ",
          },
        ],
      },
      varBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please Input Bound. ",
          },
        ],
      },
    };
  }

  changeType(val: string) {
    this.isUnlock = val;

    // // // // console.log("CEK VALUE: "+ val);

    if (val == "chBL") {
      this.cbVessel.disableCombo = true;
      this.cbVoyage.disableCombo = true;
      this.lockBound = true;
      this.grid.enable();
      this.grid.editable = true;
      this.cButtonLock = false;
      this.dButtonLock = false;
      this.disableToolbarButtons = "unlock";
      this.model["error-vesselId"] = "";
      this.model["error-varBound"] = "";
      this.model["error-voyageCode"] = "";
      this.model["error-varReason"] = "";
      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");
      this.model.varBound = "O";
      this.model.varReason = "";
      this.grid.onClear();
      this.grid.listStore.store = [];
      this.modeUnlock = "B";
    } else {
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.lockBound = false;
      this.grid.disable();
      this.cButtonLock = true;
      this.dButtonLock = true;
      this.grid.onClear();
      this.grid.listStore.store = [];
      this.disableToolbarButtons = "";
      this.grid.editable = false;
      this.modeUnlock = "V";
    }
  }

  toolbarEvent(event) {
    //// // // // console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.handleRetrieve();
        break;

      case "unlock":
        this.validateUnlock();
        break;

      case "cancel":
        this.handleCancel();
        break;

      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log("eventgrid" + event);
    switch (event.split(".")[0]) {
      case "selected":
        this.grid.getSelectedValues().forEach((element) => {
          if (element.select == "checked") {
            flgTool = 1;
          }
        });
        if (flgTool > 0) this.disableToolbarButtons = "";
        //// // // // console.log(flgTool);
        else this.disableToolbarButtons = "unlock";
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case "click":
        this.grid.listStore.store.forEach((yr) => {
          // // // // console.log(yr);
          if (yr.select == "checked") {
            flgTool = 1;
          }
        });

        if (flgTool > 0) console.log(flgTool);
        // this.disableToolbarButtons = "retrieve";
        else this.disableToolbarButtons = "retrieve,unlock";
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          // // // // console.log("before split4 -> " + strEvent);
          // // // // console.log("arr3 -> " + arr);
          // // // // console.log("arr-split1 -> " + arr[0]);
          // // // // console.log("arr-split2 -> " + arr[1]);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              if (strEvent.split(";")[1] == "blNo") {
                const blNo = strEvent.split(";")[2];
                this.getBlStatus(blNo.toUpperCase().trim());

                this.grid.listStore.store.forEach((yr) => {
                  // // // // console.log('arrr nih'+arr[0].split('.')[1]);
                  if (yr.id == arr[0].split(".")[1]) {
                    //flgTool = 1;
                    //}
                    yr.blNo = blNo.toUpperCase().trim();
                  }
                });
              }

              this.grid.listStore.store.forEach((yr) => {
                // // // // console.log(yr);
                soso.push(yr);
                if (yr.select == "checked") {
                  flgTool = 1;
                }
              });

              if (flgTool > 0)
                //this.disableToolbarButtons = "retrieve";
                console.log(flgTool);
              else this.disableToolbarButtons = "unlock";

              dblFlag = this.checkDuplicateInObject("blNo", soso);

              if (dblFlag == true) {
                this.message(
                  "information",
                  "Information",
                  "Duplicate BL Reference(s) detected.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.grid.listStore.store.splice(
                  parseInt(arr[0].split(".")[1]) - 1,
                  1
                );
              }

              break;
            case "dblClick":
              // this.modeForm == 'sailingScheduleMaintenance';
              //   this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  infoGrid(event) {
    this.info = event;
  }

  handleCancel() {
    this.grid.onClear();
    this.gridStore = [];
    this.model.vesselId = "";
    this.model.voyageCode = "";
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.isUnlock = "chVessel";
    this.changeType("chVessel");
    this.disableToolbarButtons = "unlock";
    //this.cButtonLock=false;
    //this.dButtonLock=false;
  }

  retrieveBlList() {
    var dumpC = [];
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/blunlock/retrieveBlListUnlock/" +
          this.model.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.voyageCode).trim()) +
          "/" +
          this.model.varBound +
          "/" +
          this.officeCode
      )
      .subscribe((resp) => {
        if (resp.ok) {
          var lenContent = Object.keys(resp.json()["content"]).length;

          if (lenContent > 0) {
            this.grid.listStore.store = [];

            for (var i = 1; i <= lenContent; i++) {
              dumpC.push({
                id: i,
                blNo: resp.json()["content"][i - 1].blNo,
                rMessage: "",
                valid: "N",
                office: this.officeCode,
                varReason: "",
                varRequest: "",
                varRequestTime: "",
              });
            }
            // // // // console.log(dumpC);
            dumpC.forEach((ea) => {
              this.grid.listStore.addData(ea);
            });

            this.grid.loadData();
            this.grid.onSelectAllByValue(true);
            this.grid.is_select_all = "checked";
          } else {
            this.message(
              "information",
              "Information",
              "No data(s) to unlock.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        }
      });
  }

  getBlStatus(noBL: string) {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/blunlock/getLockStatusByBlNo/" +
          this.officeCode +
          "/" +
          noBL.toUpperCase()
      )
      .subscribe((resp) => {
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            // Permanent lock checking:
            if (resp.json()["content"][0].blPermanentLocked === 'Y') {
              this.grid.listStore.store.forEach((ele) => {
                if (ele.blNo.toUpperCase() == resp.json()["content"][0].blNo) {
                  ele.rMessage = '<p style="color: red;">Unable to unlock. Vessel departed more than 1 month. Please send unlock request.</p>';
                  ele.valid = "Y";
                }
              });
              return;
            }

            switch (resp.json()["content"][0].bl_is_locked) {
              case "Y":
                this.grid.listStore.store.forEach((ele) => {
                  if (
                    resp.json()["content"][0].bl_status == "D"  && ele.blNo.toUpperCase() == resp.json()["content"][0].blNo  
                  ) {
                    ele.rMessage = "BL is deleted";
                    ele.valid = "Y";
                    //this.disableToolbarButtons='';
                  }
                  if (
                    ele.blNo.toUpperCase() == resp.json()["content"][0].blNo  && resp.json()["content"][0].bl_status != "D"
                  ) {
                    ele.rMessage = "Available to unlock";
                    ele.valid = "N";
                    //this.disableToolbarButtons='';
                  }
                  // // // // console.log(ele);
                });
                break;

              case "N":
                this.grid.listStore.store.forEach((ele) => {
                  if (
                    resp.json()["content"][0].bl_status == "D"  && ele.blNo.toUpperCase() == resp.json()["content"][0].blNo  
                  ) {
                    ele.rMessage = "BL is deleted";
                    ele.valid = "Y";
                  }
                  if (
                    ele.blNo.toUpperCase() == resp.json()["content"][0].blNo && resp.json()["content"][0].bl_status != "D"
                  ) {
                    ele.rMessage = "Unable to unlock";
                    ele.valid = "Y";
                  }
                  // // // // console.log(ele);
                });
                break;

              default:
                this.grid.listStore.store.forEach((ele) => {
                  if (
                    resp.json()["content"][0].bl_status == "D" && ele.blNo.toUpperCase() == resp.json()["content"][0].blNo
                  ) {
                    ele.rMessage = "BL is deleted";
                    ele.valid = "Y";
                    //this.disableToolbarButtons='';
                  }
                  if (
                    resp.json()["content"][0].bl_status != "D" && ele.blNo.toUpperCase() == resp.json()["content"][0].blNo
                  ) {
                    ele.rMessage = "Unable to unlock";
                    ele.valid = "Y";
                  }
                  // // // // console.log(ele);
                });
                break;
            }
          } else {
            this.grid.listStore.store.forEach((ele) => {
              if (ele.blNo.toUpperCase() == noBL.toUpperCase()) {
                ele.rMessage = "Cannot find bl number";
                ele.valid = "Y";
              }
              // // // // console.log(ele);
            });
          }
        }
      });
  }

  changeEventVessel(event) {
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyage/" +
        event.vesselId +
        "/" +
        this.model.varBound +
        "/{query}/" +
        this.cookieService.getDefaultLocationCode()
    );
    this.model.vesselId = event.vesselId;
    //    this.model.voyageCode = event.voyageCode;
    //this.model.varBound = event.varBound;
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      //this.model.voyageOrigin='*';
      this.model.voyageCode = "";
    } else {
      this.model.voyageCode = event.voyage;

      var dumpC = [];
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/blunlock/retrieveBlListUnlock/" +
            this.model.vesselId +
            "/" +
            this.sCharUtil.htmlEncode(String(this.model.voyageCode).trim()) +
            "/" +
            this.model.varBound +
            "/" +
            this.officeCode
        )
        .subscribe((resp) => {
          if (resp.ok) {
            var lenContent = Object.keys(resp.json()["content"]).length;

            if (lenContent > 0) {
              this.grid.listStore.store = [];

              for (var i = 1; i <= lenContent; i++) {
                dumpC.push({
                  id: i,
                  blNo: resp.json()["content"][i - 1].blNo,
                  rMessage: "",
                  valid: "N",
                  office: this.officeCode,
                  varReason: "",
                  varRequest: "",
                  varRequestTime: "",
                });
              }
              // // // // console.log(dumpC);
              dumpC.forEach((ea) => {
                this.grid.listStore.addData(ea);
              });

              this.disableToolbarButtons = "";
            } else {
              this.message(
                "information",
                "Information",
                "No data(s) to unlock.",
                "okonly",
                { ok: "this.loading=false;" }
              );
              this.model.vesselId = "";
              this.model.voyageCode = "";
              this.cbVessel.setValue("");
              this.cbVoyage.setValue("");
            }
          } else {
            this.message(
              "information",
              "Information",
              "There are some error occur while retrieving the record.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        });
    } //end else
  }

  changeEventReason(event) {
    this.model.varReason = $("textarea#reason").val();
  }
}
