<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <div class="field" style="width:400px">
            <label>Port of Creation</label>
            <combo-plugin #cbPOD [settings]="settingPOC" (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
          </div>
          <div class="field" style="width:400px">
              <div class="field">
                <label>Vessel ETA FROM</label>
                  <calendar-plugin #dateLocalETA [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin>
                  <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-localEta'] != null && model['error-localEta'] != ''">
                    {{model['error-localEta']}}
                  </div>            
              </div>
              <div class="field">
                <label>Vessel ETA TO</label>
                  <calendar-plugin #dateLocalETD [pluginType]="typeLocalETA" [settings]="settingDatePicker" ></calendar-plugin>
                  <!--input type="text" name="localEta"  placeholder="" [(ngModel)]="model['localEta']" maxlength="20"-->
                    <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-localEta'] != null && model['error-localEta'] != ''">
                      {{model['error-localEta']}}
                    </div>            
              </div>
          </div>
          <div class="field" style="width:400px">
            <label>Report Type</label>
              <div class = "field">         
                <div class="ui radio checkbox" >
                  <input type="checkbox"  name="pdf" tabindex="0">
                  <label>PDF</label>
                </div> 
              </div>
              <div class="field">    
                <div class="ui radio checkbox" >
                  <input type="checkbox"  name="excel" tabindex="0" >
                  <label>Excel</label>
                </div>              
              </div>   
          </div>
        </fieldset>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
