<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
	<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"> 
        <div class="ui medium text loader">Loading</div>
    </div>

   <form class="ui form" autocomplete="off">
     <div class="six wide fields">
        <div class="two field" style="width:100%" [class.error]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
            <label>Vessel</label>
            <combo-plugin #cbVessel [settings]="settingVessel" 
                (change)="changeEventVessel($event)" 
                (keypress)="formValidation.validateVesselVoyage($event)" 
                (keyup)="backspaceValue($event,formValidation.regVesVoy, 'vesselName')"
                (paste)="onPasteValidation(
                $event,
                'vesselName',
                formValidation.regVesVoy)"
                style="width:100%;">
            </combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                [class.error-message-visible]="model['error-vesselName'] != null && model['error-vesselName'] != ''">
                {{model['error-vesselName']}}
            </div>
        </div>
        <div class="one field" style="width:75%" [class.error]="model['error-voyage'] != null && model['error-voyage'] != ''">
            <label>Voyage</label>
            <combo-plugin #cbVoyage [settings]="settingVoyage" 
                (keypress)="formValidation.validateVesselVoyage($event)" 
                (keyup)="backspaceValue($event,formValidation.regVesVoy, 'voyage')"
                (paste)="onPasteValidation(
                $event,
                'voyage',
                formValidation.regVesVoy)"
                (change)="changeEventVoyage($event)" 
                style="width:100%;">
            </combo-plugin>
            <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                [class.error-message-visible]="model['error-voyage'] != null && model['error-voyage'] != ''">
                {{model['error-voyage']}}
            </div>
        </div>
        <div class="one field" style="width:60%" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <label>Bound</label>
            <input type="text" maxlength="1" name="bound" placeholder="Bound" [(ngModel)]="model['bound']"  (ngModelChange)="changesBound($event)" 
            (keypress)="formValidation.valiBoundOnlyIOM($event)" (blur)="blurBound($event)"
            (change)="model['error-bound'] = '';updateVoyageUrl('header');" (blur)="retrieveHeader();" [disabled] = "retLock">
            <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                [class.error-message-visible]="model['error-bound'] != null && model['error-bound'] != ''">
                {{model['error-bound']}}
            </div>
        </div>
        <div class="one field" style="width:96%">
            <label>Service</label>
            <input type="text" name="service" readonly style="background-color:lightgray;"  placeholder="Service" [(ngModel)]="model['serviceCode']">
        </div>
        <div class="one field" style="width:90%">
            <label>Local ETA</label>
            <input type="text" name="localETA" readonly style="background-color:lightgray;"  placeholder="Local ETA" [(ngModel)]="model['localETA']">
        </div>
        <div class="one field" style="width:90%">
            <label>Local ETD</label>
            <input type="text" name="localETD" readonly style="background-color:lightgray;"  placeholder="Local ETD" [(ngModel)]="model['localETD']">
        </div>
     </div>
   </form>
   <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
   <div>
     <grid-plugin id="gridHeader" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>      
   </div>
   <div>
     <form class="ui form">
     <div class="ten wide fields">
        <div class="ten field" style="width: 100%;margin-top: 10px;">
            <label style="float: left;margin-top: 7px;margin-right: 10px;">Setting of Allocation</label>
            <button class="tiny ui positive button" [disabled]="cButtonLock" (click) = "addAllocation();" >Create</button>
            <button class="tiny ui negative button" [disabled]="dButtonLock" (click) = "removeRow();">Delete</button>	
            <!-- button class="tiny ui positive button" (click) = "test();" >test</button -->
        </div>       
     </div>
     <input type="hidden" [(ngModel)]="allocationRecordId" name="nAllocationRecordId">
      <div class="ten wide fields">
         <div class="ten field" style="width: 80%;">
            <grid-plugin id='detail-allocation' #gridDetails [settings]="settingGridDetails" (gridEvent)="gridEventDetails($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>
          </div>
      </div>
     </form>
   </div>

   <!-- dialog copy allocation -->
   <div id="copyAllocDialog" class="ui small modal"  style="margin-right:400px;padding:1%;">
     <label>Copy Allocation From</label> 
     <br><br>
     <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
         <div class="ui form">
             <div class="ui grid">
                 <div class="row">
                     <div class="eight wide column" style="margin-bottom: 7px;">
                         <div class="field" [class.error]="modelCopy['error-vesselName'] != null && modelCopy['error-vesselName'] != ''">
                             <label>Vessel Name</label>
                             <combo-plugin #cbVesselCopy [settings]="settingVesselCopy" (change)="changeEventVesselCopy($event)" style="width:100%;"></combo-plugin>
                             <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                                [class.error-message-visible]="modelCopy['error-vesselName'] != null && modelCopy['error-vesselName'] != ''">
                                {{modelCopy['error-vesselName']}}
                            </div>
                         </div>

                         <div class="field" [class.error]="modelCopy['error-voyage'] != null && modelCopy['error-voyage'] != ''">
                             <label>Voyage</label>
                             <combo-plugin #cbVoyageCopy [settings]="settingVoyageCopy" (change)="changeEventVoyageCopy($event)" style="width:100%;"></combo-plugin>
                             <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                                [class.error-message-visible]="modelCopy['error-voyage'] != null && modelCopy['error-voyage'] != ''">
                                {{modelCopy['error-voyage']}}
                            </div>
                         </div>

                         <div class="field" [class.error]="modelCopy['error-bound'] != null && modelCopy['error-bound'] != ''">
                             <label>Bound</label>
                             <input type="text" name="bound" [(ngModel)]="modelCopy['bound']" (change)="updateVoyageUrl('copy');modelCopy['error-bound'] = ''">
                             <div class = "ui basic red pointing prompt label transition error-message-hidden" 
                                [class.error-message-visible]="modelCopy['error-bound'] != null && modelCopy['error-bound'] != ''">
                                {{modelCopy['error-bound']}}
                            </div>
                         </div>
                     </div>
                     <div class="eight wide column" style="margin-bottom: 7px;">
                        <div class="ui vertical buttons" style="padding-top: 16px;padding-left: 181px;">
                            <button class="ui button" style="margin-bottom: 2px;" (click)="doCopy();">Copy</button>
                            <button class="ui button" style="margin-bottom: 2px;" (click)="copyCancel();">Cancel</button>
                            <button class="ui button" (click)="copyClose();">Close</button>
                        </div>
                     </div>                     
                 </div>
             </div>
         </div>
     </div>
   </div>
   <!-- end of dialog copy allocation -->

   <!-- dialog add allocation -->
   <div id="addAllocDialog" class="ui small modal"  style="margin-right:400px;padding:1%;">
     <label>Insert Allocation</label>
     <br><br>
     <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
         <div class="ui form">             
             <div class="ui grid">                 
                 <div class="row" [style.display]="firstForm?'block':'none'">
                     <div class="eight wide column" style="margin-bottom: 7px;">
                         <div class="field">
                             <label>Allotment Type</label>
                             VESSEL WISE ({{model['vesselName']}})
                         </div>
                     </div>
                     <div class="eight wide column" style="margin-bottom: 7px;">
                     </div>
                     <div class="eight wide column" style="margin-bottom: 7px;">
                         <div class="field">
                             <label>Allocatede</label>
                             <input type="number" 
                             id="iAlloted" 
                             min=0 
                             style="text-align:right" 
                             [(ngModel)]="modelDetails['allocated']" 
                             name="nAllocated" 
                             (focus)="resetAllocated();" 
                             maxlength='10'
                             oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                             >
                         </div>
                     </div>
                 </div>
                 <div class="row" [style.display]="firstForm?'none':'block'">
                     <div class="seven wide column" style="margin-bottom: 7px;">
                         <div class="field">
                             <label>Allotment Type</label>
                             <combo-plugin id="at" #cbpoc [settings]="settingAlloment" (change)="getAllotment($event)" style="width:100%;"></combo-plugin>
                         </div>
                     </div>
                     <div class="eight wide column" style="margin-bottom: 7px;">
                         <div class="field">
                             <label>{{formLabel}}</label>
                             <input type="text" id="ioperator" placeholder="{{formLabel}}" [style.display]="formLabel == 'Operator'?'block':'none'" maxLength=4 [(ngModel)]="operator" (change)="opEvent($event);" name="nOperator">      
                             <combo-plugin [style.display]="formLabel == 'Port of Discharge'?'block':'none'" id="pd" #cbpod [settings]="settingPOD" (change)="getPOD($event)" style="width:100%;"></combo-plugin>            
                         </div>
                     </div>
                     <div class="seven wide column" style="margin-bottom: 7px;">
                         <div class="field">
                             <label>Allocated</label>
                             <input type="number" id="iAlloted" min=0 style="text-align:right" [(ngModel)]="modelDetails['allocated']" name="nAllocated" (focus)="resetAllocated();">
                         </div>
                     </div>
                 </div>
                 <div>                     
                     <input type="hidden" [(ngModel)]="modelDetails['seqNo']" name="nSeqno">
                     <input type="hidden" [(ngModel)]="modelDetails['level']" name="nLevel">
                     <input type="hidden" [(ngModel)]="modelDetails['allocationId']" name = "nAllocationId">
                     <input type="hidden" [(ngModel)]="modelDetails['allotedBy']" name="nAllotedBy">
                     <input type="hidden" [(ngModel)]="modelDetails['parent']" name="nParent">
                     <input type="hidden" [(ngModel)]="modelDetails['groupAvailable']" name="nGroupAvailable">
                     <input type="hidden" [(ngModel)]="modelDetails['parentLevel']" name="nParentLevel">
                 </div>
             </div>
         </div>
     </div>
    <div>
        <button id="btnAdd" class="compact ui button" (click)="addAllotmentRow();">Add</button>
        <button class="compact ui button" (click)="cancelAddAllotment();">Cancel</button>
    </div>

   </div>
   <!-- end of dialog add allocation -->

</div>
<footer-plugin [info]="info"></footer-plugin>