<div class="page-export-coda">
  <form class="ui raised segment">
    <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" (eventEmitterClick)="toolbarEvent($event)">
    </toolbar-plugin>

    <div id='container' class="page-export-coda__container">
      <div class="field">
        <fieldset class="border-fieldset">
          <legend class="style-legend">Transaction Date</legend>
          <div class="page-export-coda-flex">
            <div class="page-export-coda-field">
              <label class="page-export-coda-field__label">To Date:</label>
              <input type="date" class="page-export-coda-field__input" name="" id="">
            </div>
            <div class="page-export-coda-field">
              <label class="page-export-coda-field__label">To Date:</label>
              <input type="date" class="page-export-coda-field__input" name="" id="">
            </div>
            <div class="page-export-coda-field">
             <button class="page-export-coda-field__btn" (click)="retrieveHandel()">Retrieve</button>
            </div>
          </div>
        </fieldset>
      </div>
      <div>
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
    </div>
  </form>
</div>