

export class AdhocCharge {
    chargeId: string = '';
    chargeCode: string = '';
    chargeName: string = '';
    isManifested: boolean = false;
    isValid: boolean = true;
    currencyName: string = '';
    currencyCode: string = '';
    containerType: string = 'ALL';
    d20: number = 0;
    h20: number = 0;
    d40: number = 0;
    h40: number = 0;
    d45: number = 0;
    h45: number = 0;
    remarks: string = '';
    dateCreated: string = '';
    userCreated: string = '';
    dateModified: string = '';
    userModified: string = '';
    dateInvalid: string = '';
    userInvalid: string = '';

    constructor() { }
}
