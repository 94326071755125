<div class="ui raised segment">
  <dialog-plugin
    [settings]="dialog"
    (eventDialog)="eventMessage($event)"
  ></dialog-plugin>
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div class="ui error message" [class.hidden]="isError == false && model['error-blBound'] == null ||
          model['error-blBound'] == '' ||
          model['error-blBound'] == undefined">
    <ul>
      <!-- <li
        [class.hidden]="
          model['error-blVesselId'] == null ||
          model['error-blVesselId'] == '' ||
          model['error-blVesselId'] == undefined
        "
      >
        {{ model["error-blVesselId"] }}
      </li>
      <li
        [class.hidden]="
          model['error-blVoyage'] == null ||
          model['error-blVoyage'] == '' ||
          model['error-blVoyage'] == undefined
        "
      >
        {{ model["error-blVoyage"] }}
      </li> -->
      <li
        [class.hidden]="
          model['error-blBound'] == null ||
          model['error-blBound'] == '' ||
          model['error-blBound'] == undefined
        "
      >
        {{ model["error-blBound"] }}
      </li>
    </ul>
  </div>

  <form class="ui form">
    <button class="ui button" style="display: none"></button>

    <div class="field" style="margin-bottom: 0px">
      <!-- 1st line -->
      <div class="fields" style="margin-bottom: 5px">
        <div
          class="required two wide field"
          [class.error]="
            model['error-blVesselId'] != null && model['error-blVesselId'] != ''
          "
        >
          <label>Vessel</label>
          <combo-plugin
            #cbVessel
            [settings]="settingVessel"
            (change)="changeEventVessel($event)"
            (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
            (keyup)="backspaceValue($event,formValidation.regVesVoy, 'blVesselId', false, 'and -./', 'Vessel')"
            (paste)="
              onPasteValidation(
                $event,
                'blVesselId',
                formValidation.regVesVoy,
                'and -./'
              )
            "
          ></combo-plugin>
          <div class="ui basic red pointing prompt label transition"
            *ngIf="model['error-blVesselId'] != null && model['error-blVesselId'] != ''">
            {{model['error-blVesselId']}}
          </div>
        </div>
        <div
          class="required two wide field"
          [class.error]="
            model['error-blVoyage'] != null && model['error-blVoyage'] != ''
          "
        >
          <label>Voyage</label>
          <combo-plugin
            #cbVoyage
            [settings]="settingVoyage"
            (change)="changeEventVoyage($event)"
            (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
            (keyup)="backspaceValue($event,formValidation.regVesVoy, 'blVoyage', false, 'and -./', 'Voyage')"
            (paste)="
              onPasteValidation(
                $event,
                'blVoyage',
                formValidation.regVesVoy,
                'and -./'
              )
            "
          ></combo-plugin>
          <div class="ui basic red pointing prompt label transition"
            *ngIf="model['error-blVoyage'] != null && model['error-blVoyage'] != ''">
            {{model['error-blVoyage']}}
          </div>
        </div>
        <div
          class="required one wide field"
          [class.error]="
            model['error-blBound'] != null && model['error-blBound'] != ''
          "
        >
          <label>Bound</label>
          <input
            type="text"
            name="txtBound"
            placeholder="Bound"
            [(ngModel)]="model['blBound']"
            (keyup)="changeEventBound($event)"
            (keypress)="blockEnter($event)"
            maxlength="2"
            disabled
          />
        </div>
        <div class="two wide field">
          <label>Port of Load</label>
          <combo-plugin
            #cbPOL
            [settings]="settingPOL"
            (change)="changeEventPOL($event)"
            (keypress)="formValidation.CharNumOnly($event)"
          ></combo-plugin>
        </div>
        <div class="three wide field">
          <label>Port of Discharge</label>
          <combo-plugin
            #cbPOD
            [settings]="settingPOD"
            (change)="changeEventPOD($event)"
            (keypress)="formValidation.CharNumOnly($event)"
          ></combo-plugin>
        </div>
        <div class="three wide field">
          <label>Port of Transshipment 1</label>
          <combo-plugin
            #cbPOT1
            [settings]="settingPOT1"
            (change)="changeEventPOT1($event)"
            (keypress)="formValidation.CharNumOnly($event)"
          ></combo-plugin>
        </div>
        <div class="three wide field">
          <label>Final Destination</label>
          <combo-plugin
            #cbFDEST
            [settings]="settingFDEST"
            (change)="changeEventFDEST($event)"
            (keypress)="formValidation.CharNumOnly($event)"
          ></combo-plugin>
        </div>
      </div>
    </div>
    <!-- end of 1st line -->

    <div class="field" style="margin-bottom: 0px">
      <!-- 2nd line -->
      <div class="fields" style="margin-bottom: 5px">
        <div class="four wide field" style="margin-top: 10px">
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsCOC'] == true"
              [(ngModel)]="model['blIsCOC']"
              name="isCOC"
              (change)="changeEventCheckBoxIsCOC($event)"
              tabindex="0"
            />
            <label>COC&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsSOC'] == true"
              [(ngModel)]="model['blIsSOC']"
              name="isSOC"
              (change)="changeEventCheckBoxIsSOC($event)"
              tabindex="0"
            />
            <label>SOC&nbsp;&nbsp;</label>
          </div>

          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsSLOT'] == true"
              [(ngModel)]="model['blIsSLOT']"
              name="isSLOT"
              (change)="changeEventCheckBoxIsSLOT($event)"
              tabindex="0"
            />
            <label>SLOT&nbsp;&nbsp;</label>
          </div>

          <div class="ui test checkbox">
            <input
              type="checkbox"
              [(ngModel)]="model['blIsNVOCC']"
              [(ngModel)]="model['blIsNVOCC']"
              name="isNVOCC"
              (change)="changeEventCheckBoxIsNVOCC($event)"
              tabindex="0"
            />
            <label>NVOCC</label>
          </div>
        </div>

        <div class="three wide field">
          <combo-plugin
            #cbLocalTransshipment
            [settings]="settingLocalTransshipment"
            (change)="changeEventLocalTransshipment($event)"
          ></combo-plugin>
        </div>
        <div class="three wide field">
          <combo-plugin
            #cbFullEmpty
            [settings]="settingFullEmpty"
            (change)="changeEventFullEmpty($event)"
          ></combo-plugin>
        </div>
      </div>
    </div>
    <!-- end of 2nd line -->

    <div class="field">
      <!-- 3rd line -->
      <div class="fields">
        <!--
        <div class="four wide field">
          <label>BL Ref.</label>
          <input type="text" name="txtShipperDraft"  placeholder="Shipper Draft BL Number" [(ngModel)]="model['blShipperDraftBLNumber']" (keypress)="blockEnter($event)">
        </div>
        -->
        <div class="twelve wide field" style="margin-top: 25px">
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsFinished'] == true"
              [(ngModel)]="model['blIsFinished']"
              name="isFinished"
              (change)="changeEventCheckBoxIsFinished($event)"
              tabindex="0"
            />
            <label>Finished&nbsp;&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsNotFinished'] == true"
              [(ngModel)]="model['blIsNotFinished']"
              name="isNotFinished"
              (change)="changeEventCheckBoxIsNotFinished($event)"
              tabindex="0"
            />
            <label>Not Finished&nbsp;&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsDeleted'] == true"
              [(ngModel)]="model['blIsDeleted']"
              name="isDeleted"
              (change)="changeEventCheckBoxIsDeleted($event)"
              tabindex="0"
            />
            <label>Deleted&nbsp;&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsWithoutContainer'] == true"
              [(ngModel)]="model['blIsWithoutContainer']"
              name="isWithoutContainer"
              (change)="changeEventCheckBoxIsWithoutContainer($event)"
              tabindex="0"
            />
            <label>Without Container&nbsp;&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blIsWithoutCharge'] == true"
              [(ngModel)]="model['blIsWithoutCharge']"
              name="isWithoutCharge"
              (change)="changeEventCheckBoxIsWithoutCharge($event)"
              tabindex="0"
            />
            <label>Without Charge&nbsp;&nbsp;&nbsp;</label>
          </div>
          <div class="ui test checkbox" style="margin-right: 20px;">
            <input
              type="checkbox"
              [checked]="model['blPoi'] == true"
              [(ngModel)]="model['blPoi']"
              name="blPoi"
              disabled
            />
            <label>Place of issue</label>
          </div>
          <div class="ui test checkbox">
            <input
              type="checkbox"
              [checked]="model['blPop'] == true"
              [(ngModel)]="model['blPop']"
              name="blPop"
              disabled
            />
            <label>Place of payment</label>
          </div>
        </div>
      </div>
    </div>
    <!-- end of 3rd line -->

    <div style="width: 100%; margin-top: 24px;">
      <app-custom-ag-grid-angular
        #topGrid
        id="transaction-in-bl-flag-status"
        [getRowStyle]="getRowStyle"
        [rowData]="rowData"
        [defaultColumnDefs]="defaultColumnDefs"
        [enableSorting]="true"
        [enableFilter]="true"
        [enableColResize]="true"
        (gridReady)="onGridReady($event)"
        (rowDoubleClicked)="rowDoubleClick($event)"
        rowSelection='multiple'
        [rowDeselection]="true"
        [suppressCopyRowsToClipboard]="true"
      >
      </app-custom-ag-grid-angular>
    </div>

    <br />

    <div class="field" style="display: none">
      <grid-plugin
        #grid
        [settings]="settingGrid"
        (gridEvent)="gridEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>
    </div>

    <div class="field" style="margin-bottom: 0px">
      <div class="fields" style="margin-bottom: 5px">
        <div class="two wide field">
          <label>SOC B/Ls</label>
          <input
            type="number"
            name="totalSOC"
            [(ngModel)]="model['blTotalSOC']"
            readonly
            style="text-align: right"
          />
        </div>
        <div class="two wide field">
          <label>COC B/Ls</label>
          <input
            type="number"
            name="totalCOC"
            [(ngModel)]="model['blTotalCOC']"
            readonly
            style="text-align: right"
          />
        </div>
        <div class="two wide field">
          <label>SLOT B/Ls</label>
          <input
            type="number"
            name="totalSLOT"
            [(ngModel)]="model['blTotalSLOT']"
            readonly
            style="text-align: right"
          />
        </div>
        <div class="two wide field">
          <label>Total B/Ls</label>
          <input
            type="number"
            name="totalAll"
            [(ngModel)]="model['blTotalAll']"
            readonly
            style="text-align: right"
          />
        </div>
        <div class="three wide field">
          <label>Total Deleted B/Ls</label>
          <input
            type="number"
            name="totalDeleted"
            [(ngModel)]="model['blTotalDeleted']"
            readonly
            style="text-align: right"
          />
        </div>
      </div>
    </div>
    <div class="field" style="margin-top: 0px">
      <div class="fields">
        <div class="two wide field">
          <label>NVOCC B/Ls</label>
          <input
            type="number"
            name="totalNVOCC"
            [(ngModel)]="model['blTotalNVOCC']"
            readonly
            style="text-align: right"
          />
        </div>
        <!--
        <div class="nine wide field">
          <div class="ui indicating progress" data-value="0" data-total="100" id="blflag-progress-bar" style="margin-top:20px">
            <div class="bar"></div>
            <div class="label">{{progressBarValue}}% Completed {{progressBarMessage}}</div>
          </div>
        </div>
        -->
      </div>
    </div>
  </form>
</div>

<div
  id="blDeleteDialogYesNo"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <p>
        <i
          class="question circle big icon"
          style="
            float: left;
            width: 1.3em;
            height: 1em;
            text-align: justify;
            padding-left: 0.2em;
            padding-top: 0.2em;
          "
        ></i>
        {{ frmDialogMessage }}
      </p>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onDialogBlYesDelete()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogBlNoDelete()">
        No
      </button>
    </div>
  </form>
</div>
