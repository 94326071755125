<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <fieldset style="border: 1px solid lightgray;">
        <div class="two fields">
          <div class="field">
            <label>Place Of Creation:</label>
              <combo-plugin #poc [settings]="settingPoc" (change)="changeEventPoc($event)" style="width:100%;"></combo-plugin>
              <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-vesselId'] != null && model['error-vesselId'] != ''">
                {{model['error-vesselId']}}
              </div>
          </div>
          <div class="field">
            <label>Discharge Port:</label>
              <combo-plugin #pod [settings]="settingPod" (change)="changeEventPod($event)" style="width:100%;"></combo-plugin>
              <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-vesselId'] != null && model['error-vesselId'] != ''">
                {{model['error-vesselId']}}
              </div>
          </div>
          <div class="field">
            <div class="ui tiny right floated buttons" style="margin-top:21px">
              <button  class="ui button retrieve" data-content="Retrieve" >Search</button>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="field">
        <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
