<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #pfcToolbar
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <!--Error Message Block-->
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-schFreightPartyId'] == null ||
            model['error-schFreightPartyId'] == ''
          "
        >
          {{ model["error-schFreightPartyId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schShipperId'] == null ||
            model['error-schShipperId'] == ''
          "
        >
          {{ model["error-schShipperId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schBookingPartyId'] == null ||
            model['error-schBookingPartyId'] == ''
          "
        >
          {{ model["error-schBookingPartyId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schPlaceOfReceiptCode'] == null ||
            model['error-schPlaceOfReceiptCode'] == ''
          "
        >
          {{ model["error-schPlaceOfReceiptCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schLoadPortCode'] == null ||
            model['error-schLoadPortCode'] == ''
          "
        >
          {{ model["error-schLoadPortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schDischargePortCode'] == null ||
            model['error-schDischargePortCode'] == ''
          "
        >
          {{ model["error-schDischargePortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schPlaceOfDeliveryCode'] == null ||
            model['error-schPlaceOfDeliveryCode'] == ''
          "
        >
          {{ model["error-schPlaceOfDeliveryCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schReceiptTypeCode'] == null ||
            model['error-schReceiptTypeCode'] == ''
          "
        >
          {{ model["error-schReceiptTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schDeliveryTypeCode'] == null ||
            model['error-schDeliveryTypeCode'] == ''
          "
        >
          {{ model["error-schDeliveryTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schEffectiveFrom'] == null ||
            model['error-schEffectiveFrom'] == ''
          "
        >
          {{ model["error-schEffectiveFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schEffectiveTo'] == null ||
            model['error-schEffectiveTo'] == ''
          "
        >
          {{ model["error-schEffectiveTo"] }}
        </li>
        <li
          [class.hidden]="
            model['error-schAcctPic'] == null || model['error-schAcctPic'] == ''
          "
        >
          {{ model["error-schAcctPic"] }}
        </li>
        <li
          [class.hidden]="
            model['error-effectiveDateExceedValidator'] == null ||
            model['error-effectiveDateExceedValidator'] == ''
          "
        >
          {{ model["error-effectiveDateExceedValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-expiryDateValidator'] == null ||
            model['error-expiryDateValidator'] == ''
          "
        >
          {{ model["error-expiryDateValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePORValidator'] == null ||
            model['error-samePORValidator'] == ''
          "
        >
          {{ model["error-samePORValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePOLValidator'] == null ||
            model['error-samePOLValidator'] == ''
          "
        >
          {{ model["error-samePOLValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePODValidator'] == null ||
            model['error-samePODValidator'] == ''
          "
        >
          {{ model["error-samePODValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-samePODLValidator'] == null ||
            model['error-samePODLValidator'] == ''
          "
        >
          {{ model["error-samePODLValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-sameFDestValidator'] == null ||
            model['error-sameFDestValidator'] == ''
          "
        >
          {{ model["error-sameFDestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankFreightChargesValidator'] == null ||
            model['error-blankFreightChargesValidator'] == ''
          "
        >
          {{ model["error-blankFreightChargesValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-missingMainChargesValidator'] == null ||
            model['error-missingMainChargesValidator'] == ''
          "
        >
          {{ model["error-missingMainChargesValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankDetentionRequestValidator'] == null ||
            model['error-blankDetentionRequestValidator'] == ''
          "
        >
          {{ model["error-blankDetentionRequestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankDemurrageRequestValidator'] == null ||
            model['error-blankDemurrageRequestValidator'] == ''
          "
        >
          {{ model["error-blankDemurrageRequestValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blankAdhocVoyageValidator'] == null ||
            model['error-blankAdhocVoyageValidator'] == ''
          "
        >
          {{ model["error-blankAdhocVoyageValidator"] }}
        </li>
        <li
          [class.hidden]="
            model['error-checkDuplicateValidator'] == null ||
            model['error-checkDuplicateValidator'] == ''
          "
        >
          <div [innerHtml]="model['error-checkDuplicateValidator']"></div>
        </li>
      </ul>
    </div>
    <!--End of Error Message Block-->

    <form class="ui form">
      <button class="ui button" style="display: none"></button>

      <div
        [style.display]="modeForm == 'surchargeFlagStatus' ? 'block' : 'none'"
        style="width: 100%"
      >
        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <div
            class="ui inverted dimmer"
            [class.active]="loading == true"
            style="z-index: 1"
          >
            <div class="ui medium text loader">Loading</div>
          </div>

          <div
            class="ui top attached tabular menu"
            style="background-color: beige"
          >
            <a class="item active" data-tab="General">General</a>
            <a class="item" data-tab="Terminal">Terminal</a>
          </div>

          <!-- data for tab general must goes here -->
          <div
            class="ui bottom attached tab segment active"
            id="GeneralTab"
            data-tab="General"
          >
            <a class="ui red ribbon label">Search Criteria</a>
            <!-- Row 1 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label
                  >Freight Party
                  <a
                    [class.hidden]="fpAcctCode == ''"
                    style="font-size: 10px; font-weight: bold; color: red"
                    >{{ fpAcctCode }}</a
                  ></label
                >
                <combo-plugin
                  #cbFreightParty
                  [settings]="settingCbFreightParty"
                  (change)="changeFreightParty($event)"
                  (keypress)="validateFieldSurcharge($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Ownership</label>
                <combo-plugin
                  #cbContainerOwnership
                  [settings]="settingCbContainerOwnership"
                  (change)="changeContainerOwnership($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Effective From</label
                ><!--settingEffectiveFrom-->
                <datetime-plugin
                  #dateEffectiveFrom
                  [settings]="settingDateEffectiveFrom"
                  [disableDatetime]="lockEffectiveFrom"
                ></datetime-plugin>
              </div>
              <div class="field" style="padding-right: 5px">
                <div class="field">
                  <label>Expiry On</label>
                  <datetime-plugin
                    #dateEffectiveTo
                    [settings]="settingDateEffectiveTo"
                    [disableDatetime]="lockEffectiveTo"
                  ></datetime-plugin>
                </div>
              </div>
            </div>
            <!-- Row 1 end -->

            <!-- Row 2 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Booking Party</label>
                <combo-plugin
                  #cbBookingParty
                  [settings]="settingCbBookingParty"
                  (change)="changeCbBookingParty($event)"
                  (keypress)="validateFieldSurcharge"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Shipper</label>
                <combo-plugin
                  #cbShipper
                  [settings]="settingCbShipper"
                  (change)="changeCbShipper($event)"
                  (keypress)="validateFieldSurcharge"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Type</label>
                <combo-plugin
                  #cbContainerType
                  [settings]="settingCbContainerType"
                  (change)="changeContainerType($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Container Status</label>
                <combo-plugin
                  #cbContainerStatus
                  [settings]="settingCbContainerStatus"
                  (change)="changeContainerStatus($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 2 end -->

            <!-- Row 3 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Place of Receipt</label>
                <combo-plugin
                  #cbPlaceOfReceipt
                  [settings]="settingCbPlaceOfReceipt"
                  (change)="changePlaceOfReceipt($event)"
                  (keypress)="validateOnlyRegName($event)"
                  [disableCombo]="checkLock == true"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbPlaceOfReceipt', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Port of Load</label>
                <combo-plugin
                  #cbPortOfLoad
                  [settings]="settingCbPortOfLoad"
                  (change)="changePortOfLoad($event)"
                  (keypress)="validateOnlyRegName($event)"
                  [disableCombo]="checkLock == true"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbPortOfLoad', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Receipt Type</label>
                <combo-plugin
                  #cbReceiptType
                  [settings]="settingCbReceiptType"
                  (change)="changeReceiptType($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>DG Class</label>
                <combo-plugin
                  #cbDGClass
                  [settings]="settingCbDGClass"
                  (change)="changeDGClass($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 3 end -->

            <!-- Row 4 start -->
            <div class="four fields" style="margin-bottom: 0px">
              <div class="field">
                <label>Port of Discharge</label>
                <combo-plugin
                  #cbPortOfDischarge
                  [settings]="settingCbPortOfDischarge"
                  (change)="changePortOfDischarge($event)"
                  (keypress)="validateOnlyRegName($event)"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbPortOfDischarge', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Place of Delivery</label>
                <combo-plugin
                  #cbPlaceOfDelivery
                  [settings]="settingCbPlaceOfDelivery"
                  (change)="changePlaceOfDelivery($event)"
                  (keypress)="validateOnlyRegName($event)"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbPlaceOfDelivery', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Delivery Type</label>
                <combo-plugin
                  #cbDeliveryType
                  [settings]="settingCbDeliveryType"
                  value="CY"
                  (change)="changeDeliveryType($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Valid/Invalid</label>
                <combo-plugin
                  #cbValidInvalid
                  [settings]="settingCbValidInvalid"
                  (change)="changeValidInvalid($event)"
                  (keypress)="CharOnly($event)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>

                <!--
                -->
              </div>
            </div>
            <!-- Row 4 end -->
            <!-- Row 5 start -->
            <div class="four fields" style="margin-bottom: 0px;margin-top:10px;">
              <div class="field">
                <label>Port of Transshipment (1)</label>
                <combo-plugin
                  #cbPortOfTransshipment
                  [settings]="settingCbPortOfTransshipment"
                  (change)="changePortOfTransshipment($event)"
                  (keypress)="validateOnlyRegName($event)"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbPortOfTransshipment', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>Final Destination</label>
                <combo-plugin
                  #cbFinalDestination
                  [settings]="settingCbFinalDestination"
                  (change)="changeFinalDestination($event)"
                  (keypress)="validateOnlyRegName($event)"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'cbFinalDestination', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                  [disableCombo]="checkLock == true"
                ></combo-plugin>
              </div>
              <div class="field" style="margin-top:-7px;">
                <div class="ui checkbox" style="margin-bottom: 10px">
                  <input
                    [checked]="model['schSerchByRefNo'] == 'Y'"
                    value="Y"
                    type="checkbox"
                    (change)="changeEventRetrieveByRefNo($event)"
                    name="checkbox"
                  />
                  <label>Retrieve by Surcharge Ref</label>
                </div>
                <input
                  type="text"
                  [(ngModel)]="model['schSurchargeId']"
                  name="schSurchargeId"
                  placeholder="Surcharge ID"
                  [disabled]="checkLock == false"
                  maxlength="20"
                  (keypress)="formValidation.ModifiedPattern($event, '-(.)/, ')"
                />
              </div>
              <div class="field" style="margin-top: 10px">
                <label class="hidden">Test</label>
                <div class="ui checkbox">
                  <input
                    id="isDeleted"
                    type="checkbox"
                    [checked]="model['schIsDeleted'] == 'Y'"
                    (change)="changeIsDeleted($event)"
                  />
                  <label>Deleted&nbsp;&nbsp;&nbsp;</label>
                </div>
                <div class="ui checkbox">
                  <input
                    id="isCommissionable"
                    type="checkbox"
                    [checked]="model['schIsCommission'] == 'Y'"
                    (change)="changeIsCommissionable($event)"
                  />
                  <label>Commissionable</label>
                </div>
              </div>
            </div>
            <!-- Row 5 end -->
          </div>
          <!-- end of tab general -->

          <!-- Start of Tab Terminnal -->
          <div
            class="ui bottom attached tab segment"
            id="TerminalTab"
            data-tab="Terminal"
          >
            <a class="ui red ribbon label">Search Criteria</a>

            <!-- Row 1 start -->
            <div class="two fields">
              <div class="field">
                <label>POR Terminal</label>
                <combo-plugin
                  #cbPORTerminal
                  [settings]="settingCbPORTerminal"
                  (change)="changePORTerminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>PODL Terminal</label>
                <combo-plugin
                  #cbPODLTerminal
                  [settings]="settingCbPODLTerminal"
                  (change)="changePODLTerminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 1 end-->

            <!-- Row 2 start -->
            <div class="two fields">
              <div class="field">
                <label>POL Terminal</label>
                <combo-plugin
                  #cbPOLTerminal
                  [settings]="settingCbPOLTerminal"
                  (change)="changePOLTerminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>POT1 Terminal</label>
                <combo-plugin
                  #cbPOT1Terminal
                  [settings]="settingCbPOT1Terminal"
                  (change)="changePOT1Terminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 2 end-->

            <!-- Row 3 start -->
            <div class="two fields">
              <div class="field">
                <label>POD Terminal</label>
                <combo-plugin
                  #cbPODTerminal
                  [settings]="settingCbPODTerminal"
                  (change)="changePODTerminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
              <div class="field">
                <label>FDEST Terminal</label>
                <combo-plugin
                  #cbFDESTTerminal
                  [settings]="settingCbFDESTTerminal"
                  (change)="changeFDESTTerminal($event)"
                  (keypress)="CharNumOnly($event)"
                ></combo-plugin>
              </div>
            </div>
            <!-- Row 3 end-->
          </div>

          <a class="ui red ribbon label">Search Result</a>
          <div class="field">
            <grid-plugin
              #mainGrid
              [settings]="settingMainGrid"
              (gridEvent)="mainGridEvent($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
          </div>
        </div>
        <!-- End of container -->
      </div>
      <!-- End of flag status form control -->

      <div
        [style.display]="modeForm == 'surchargeMaintenance' ? 'block' : 'none'"
      >
        <div
          class="ui top attached tabular menu"
          style="background-color: beige"
        >
          <a class="item active" data-tab="maintenanceGeneralTab" #maintenanceGeneralTab
            >General &nbsp;
            <span style="color: red; text-transform: uppercase">{{
              appStatusLabel
            }}</span></a
          >
          <a class="item" data-tab="maintenanceTerminalTab">Terminal</a>
        </div>

        <!-- Maintenance General Tab Start -->
        <div
          class="ui bottom attached tab segment active"
          id="maintenanceGeneralTab"
          data-tab="maintenanceGeneralTab"
        >
          <div class="field">
            <div
              class="ui inverted dimmer active"
              style="z-index: 9; opacity: 0.2; padding-top: 15%"
              [class.hidden]="lockHeader == false"
            >
              <i class="lockicon circular inverted large lock icon"></i>
            </div>

            <div class="fields">
              <!-- divide into 4 sections -->
              <div class="four wide field">
                <!-- 1st Pane from Left Start -->

                <div class="field">
                  <label>Freight Party
                    <a [class.hidden]="fpAcctCode == ''" style="font-size: 10px; font-weight: bold; color: #ff0000">{{ fpAcctCode }}</a>
                  </label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceFreightParty
                    [settings]="settingCbMaintenanceFreightParty"
                    (change)="changeCbMaintenanceFreightParty($event)"
                    (keypress)="validateFieldSurcharge($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Booking Party</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceBookingParty
                    [settings]="settingCbMaintenanceBookingParty"
                    (change)="changeCbMaintenanceBookingParty($event)"
                    (keypress)="validateFieldSurcharge($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Shipper</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceShipper
                    [settings]="settingCbMaintenanceShipper"
                    (change)="changeCbMaintenanceShipper($event)"
                    (keypress)="validateFieldSurcharge($event)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-schPlaceOfReceiptCode'] != null &&
                    model['error-schPlaceOfReceiptCode'] != ''
                  "
                >
                  <label style="color: red">Place Of Receipt</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePlaceOfReceipt
                    [settings]="settingCbMaintenancePlaceOfReceipt"
                    (change)="changeCbMaintenancePlaceOfReceipt($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePlaceOfReceipt', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-schLoadPortCode'] != null &&
                    model['error-schLoadPortCode'] != ''
                  "
                >
                  <label style="color: red">Port Of Load</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePortOfLoad
                    [settings]="settingCbMaintenancePortOfLoad"
                    (change)="changeCbMaintenancePortOfLoad($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePortOfLoad', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-schDischargePortCode'] != null &&
                    model['error-schDischargePortCode'] != ''
                  "
                >
                  <label style="color: red">Port Of Discharge</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePortOfDischarge
                    [settings]="settingCbMaintenancePortOfDischarge"
                    (change)="changeCbMaintenancePortOfDischarge($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePortOfDischarge', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div
                  class="required field"
                  [class.error]="
                    model['error-schPlaceOfDeliveryCode'] != null &&
                    model['error-schPlaceOfDeliveryCode'] != ''
                  "
                >
                  <label style="color: red">Place Of Delivery</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenancePlaceOfDelivery
                    [settings]="settingCbMaintenancePlaceOfDelivery"
                    (change)="changeCbMaintenancePlaceOfDelivery($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePlaceOfDelivery', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Final Destination</label>
                  <combo-plugin
                    class="toDisable"
                    #cbMaintenanceFinalDestination
                    [settings]="settingCbMaintenanceFinalDestination"
                    (change)="changeCbMaintenanceFinalDestination($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenanceFinalDestination', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field">
                  <label>Port Of Transshipment(1) </label>
                  <combo-plugin
                    #cbMaintenancePOT1
                    [settings]="settingCbMaintenancePOT1"
                    (change)="changeCbMaintenancePOT1($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePOT1', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field" style="margin-bottom: 5px">
                  <label>Port Of Transshipment(2) </label>
                  <combo-plugin
                    #cbMaintenancePOT2
                    [settings]="settingCbMaintenancePOT2"
                    (change)="changeCbMaintenancePOT2($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePOT2', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>

                <div class="field" style="margin-bottom: 5px">
                  <label>Port Of Transshipment(3) </label>
                  <combo-plugin
                    #cbMaintenancePOT3
                    [settings]="settingCbMaintenancePOT3"
                    (change)="changeCbMaintenancePOT3($event)"
                    (keypress)="validateOnlyRegName($event)"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'cbMaintenancePOT3', '[^a-zA-Z0-9\/\s.,() -]', 50)"
                    [disableCombo]="lock == true"
                  ></combo-plugin>
                </div>
              </div>
              <!-- 1st Pane from Left End -->

              <div class="ten wide field">
                <div class="field">
                  <div class="fields">
                    <div class="eight wide field">
                      <!--#3-->
                      <div class="field">
                        <div class="fields">
                          <div class="eight wide field">
                            <div
                              class="required field"
                              [class.error]="
                                model['error-schReceiptTypeCode'] != null &&
                                model['error-schReceiptTypeCode'] != ''
                              "
                            >
                              <label style="font-size: 13px; color: red"
                                >Receipt Type</label
                              >
                              <combo-plugin
                                #cbMaintenanceReceiptType
                                [settings]="settingCbMaintenanceReceiptType"
                                (change)="
                                  changeCbMaintenanceReceiptType($event)
                                "
                                (keypress)="CharOnly($event)"
                                [disableCombo]="
                                  lock == true || lockHeader == true
                                "
                              ></combo-plugin>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div
                              class="required field"
                              [class.error]="
                                model['error-schDeliveryTypeCode'] != null &&
                                model['error-schDeliveryTypeCode'] != ''
                              "
                            >
                              <label style="font-size: 13px; color: red"
                                >Delivery Type</label
                              >
                              <combo-plugin
                                #cbMaintenanceDeliveryType
                                [settings]="settingCbMaintenanceDeliveryType"
                                (change)="
                                  changeCbMaintenanceDeliveryType($event)
                                "
                                (keypress)="CharOnly($event)"
                                [disableCombo]="
                                  lock == true || lockHeader == true
                                "
                              ></combo-plugin>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <fieldset style="border: 1px solid lightgray">
                          <legend style="font-weight: bold; color: red">
                            Container Ownership*
                          </legend>
                          <div class="field">
                            <div
                              class="fields"
                              [class.disabled]="lockHeader == true"
                              style="margin-bottom: 0px"
                            >
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerOwnership'] == 'COC'
                                    "
                                    value="COC"
                                    (change)="
                                      changeEventPfcContainerOwnership($event)
                                    "
                                    name="schContainerOwnership"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>COC</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerOwnership'] == 'COCTS'
                                    "
                                    value="COCTS"
                                    (change)="
                                      changeEventPfcContainerOwnership($event)
                                    "
                                    name="schContainerOwnership"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>COC T/S</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div
                              class="fields"
                              [class.disabled]="lockHeader == true"
                              style="margin-bottom: 0px"
                            >
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerOwnership'] == 'SOC'
                                    "
                                    value="SOC"
                                    (change)="
                                      changeEventPfcContainerOwnership($event)
                                    "
                                    name="schContainerOwnership"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>SOC</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerOwnership'] == 'SOCTS'
                                    "
                                    value="SOCTS"
                                    (change)="
                                      changeEventPfcContainerOwnership($event)
                                    "
                                    name="schContainerOwnership"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>SOC T/S</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div
                              class="fields"
                              [class.disabled]="lockHeader == true"
                              style="margin-bottom: 0px"
                            >
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerOwnership'] == 'SLOT'
                                    "
                                    value="SLOT"
                                    (change)="
                                      changeEventPfcContainerOwnership($event)
                                    "
                                    name="schContainerOwnership"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>SLOT</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui checkbox">
                                  <input
                                    [checked]="model['schIsCommission'] == 'Y'"
                                    type="checkbox"
                                    (change)="
                                      changeEventPfcIsCommission($event)
                                    "
                                    [disabled]="lock == true"
                                  />
                                  <label>Commissionable</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div class="field" style="margin-top: 10px">
                        <fieldset style="border: 1px solid lightgray">
                          <legend style="font-weight: bold; color: red">
                            Container Status*
                          </legend>
                          <div class="field">
                            <div
                              class="fields"
                              [class.disabled]="lockHeader == true"
                              style="margin-bottom: 0px"
                            >
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerStatus'] == 'FULL'
                                    "
                                    value="FULL"
                                    (change)="
                                      changeEventPfcContainerStatus($event)
                                    "
                                    name="schContainerStatus"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Full</label>
                                </div>
                              </div>
                            </div>

                            <div
                              class="fields"
                              [class.disabled]="lockHeader == true"
                              style="margin-bottom: 0px"
                            >
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="
                                      model['schContainerStatus'] == 'EMPTY'
                                    "
                                    value="EMPTY"
                                    (change)="
                                      changeEventPfcContainerStatus($event)
                                    "
                                    name="schContainerStatus"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Empty</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div class="field" style="margin-top: 10px">
                        <fieldset
                          style="border: 1px solid lightgray"
                          disabled="true"
                        >
                          <legend style="font-weight: bold">
                            Shipment Type
                          </legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'N'"
                                    value="N"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'D'"
                                    value="D"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>RD-TRIP</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'I'"
                                    value="I"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>IRE</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'S'"
                                    value="S"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>SPECIAL</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'R'"
                                    value="R"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>ROB</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schShipmentType'] == 'C'"
                                    value="C"
                                    (change)="
                                      changeEventPfcShipmentType($event)
                                    "
                                    name="schShipmentType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>CRB</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="ui radio checkbox">
                              <input
                                [checked]="model['schShipmentType'] == 'T'"
                                value="T"
                                (change)="changeEventPfcShipmentType($event)"
                                name="schShipmentType"
                                type="radio"
                                tabindex="0"
                                class="hidden"
                                [disabled]="lock == true"
                              />
                              <label>THRU</label>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <!--#4-->
                      <div class="field">
                        <div class="fields">
                          <div class="eight wide field">
                            <div
                              class="required field"
                              [class.error]="
                                model['error-schEffectiveFrom'] != null &&
                                model['error-schEffectiveFrom'] != ''
                              "
                            >
                              <label style="color: red">Effective From</label>
                              <datetime-plugin
                                #dateMaintenanceEffectiveFrom
                                [settings]="settingDateMaintenanceEffectiveFrom"
                                [disableDatetime]="lock == true"
                              ></datetime-plugin>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div
                              class="required field"
                              [class.error]="
                                model['error-schEffectiveTo'] != null &&
                                model['error-schEffectiveTo'] != ''
                              "
                            >
                              <label style="color: red">Expiry On</label>
                              <datetime-plugin
                                class="toDisable"
                                #dateMaintenanceEffectiveTo
                                [settings]="settingDateMaintenanceEffectiveTo"
                                [disableDatetime]="lock == true"
                              ></datetime-plugin>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <fieldset style="border: 1px solid lightgray">
                          <legend style="font-weight: bold; color: red">
                            Container Type*
                          </legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'STD'
                                    "
                                    value="STD"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('STD')"
                                    >Normal</label
                                  >
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'TNK'
                                    "
                                    value="TNK"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('TNK')"
                                    >Tank</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'REF'
                                    "
                                    value="REF"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('REF')"
                                    >Reefer</label
                                  >
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'DRY'
                                    "
                                    value="DRY"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('DRY')"
                                    >Reefer Dry</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'OPT'
                                    "
                                    value="OPT"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('OPT')"
                                    >Open Top</label
                                  >
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'UC'
                                    "
                                    value="UC"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('UC')">UC</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>InG</label>
                                </div>
                              </div>
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>OutG</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'FLR'
                                    "
                                    value="FLR"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('FLR')"
                                    >Flat Rack</label
                                  >
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>InG</label>
                                </div>
                              </div>
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>OutG</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div
                                  class="ui radio checkbox"
                                  style="z-index: 10"
                                >
                                  <input
                                    [checked]="
                                      model['schContainerType'] == 'PLT'
                                    "
                                    value="PLT"
                                    (change)="
                                      changeEventPfcContainerType($event)
                                    "
                                    name="schContainerType"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label [class.bold]="map.has('PLT')"
                                    >Platform</label
                                  >
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>InG</label>
                                </div>
                              </div>
                              <div class="four wide field">
                                <div class="ui test checkbox">
                                  <input
                                    name="containerType"
                                    type="checkbox"
                                    tabindex="0"
                                    class="hidden"
                                    disabled="true"
                                  />
                                  <label>OutG</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      <div class="field">
                        <fieldset>
                          <legend style="font-weight: bold">DG Class</legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schDgClass'] == 'NA'"
                                    value="NA"
                                    (change)="changeEventPfcDgClass($event)"
                                    name="schDgClass"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schDgClass'] == 'C3'"
                                    value="C3"
                                    (change)="changeEventPfcDgClass($event)"
                                    name="schDgClass"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Class 3</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schDgClass'] == 'C2'"
                                    value="C2"
                                    (change)="changeEventPfcDgClass($event)"
                                    name="schDgClass"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Class 2</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schDgClass'] == 'C2F'"
                                    value="C2F"
                                    (change)="changeEventPfcDgClass($event)"
                                    name="schDgClass"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Class 2F</label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input
                                    [checked]="model['schDgClass'] == 'C1'"
                                    value="C1"
                                    (change)="changeEventPfcDgClass($event)"
                                    name="schDgClass"
                                    type="radio"
                                    tabindex="0"
                                    class="hidden"
                                    [disabled]="lock == true"
                                  />
                                  <label>Class 1</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <!--#2-->
                  <div class="field">
                    <fieldset style="border: 1px solid lightgray">
                      <legend style="font-weight: bold" disabled="true">
                        Ad-HOC Shipment
                      </legend>
                      <div class="field">
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="eight wide field">
                            <div class="field">
                              <label>Vessel</label>
                              <combo-plugin
                                #cbMaintenanceAdhocVessel
                                [settings]="settingCbMaintenanceAdhocVessel"
                                (change)="
                                  changeCbMaintenanceAdhocVessel($event)
                                "
                                [disableCombo]="
                                  lock == true || lockHeader == true
                                "
                                (keypress)="
                                  formValidation.ModifiedPattern($event, '-./ ')
                                "
                              ></combo-plugin>
                            </div>
                          </div>

                          <div class="eight wide field">
                            <div class="field">
                              <label>ETA</label>
                              <input
                                type="text"
                                [(ngModel)]="model['schLocalEta']"
                                name="schLocalEta"
                                placeholder="ETA"
                                maxlength="30"
                                style="border: 1px solid gray"
                                readonly
                                style="background-color: lightgray"
                              />
                            </div>
                          </div>

                          <div class="eight wide field">
                            <div class="field">
                              <label>ETD</label>
                              <input
                                type="text"
                                [(ngModel)]="model['schLocalEtd']"
                                name="schLocalEtd"
                                placeholder="ETD"
                                maxlength="30"
                                style="border: 1px solid gray"
                                readonly
                                style="background-color: lightgray"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="field">
                        <div class="fields" style="margin-bottom: 0px">
                          <div class="eight wide field">
                            <div
                              class="field"
                              [class.error]="
                                model['error-blankAdhocVoyageValidator'] !=
                                  null &&
                                model['error-blankAdhocVoyageValidator'] != ''
                              "
                            >
                              <label>Voyage</label>
                              <combo-plugin
                                class="toDisable"
                                #cbMaintenanceAdhocVoyage
                                [settings]="settingCbMaintenanceAdhocVoyage"
                                (change)="
                                  changeCbMaintenanceAdhocVoyage($event)
                                "
                                (keypress)="
                                  formValidation.ModifiedPattern($event, '-./ ')
                                "
                                [disableCombo]="
                                  lock == true || lockHeader == true
                                "
                              ></combo-plugin>
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="field">
                              <label>ATB</label>
                              <input
                                type="text"
                                [(ngModel)]="model['schLocalAta']"
                                name="schLocalAta"
                                placeholder="ATA"
                                maxlength="30"
                                style="border: 1px solid gray"
                                readonly
                                style="background-color: lightgray"
                              />
                            </div>
                          </div>
                          <div class="eight wide field">
                            <div class="field">
                              <label>ATD</label>
                              <input
                                type="text"
                                [(ngModel)]="model['schLocalAtd']"
                                name="schLocalAtd"
                                placeholder="ATD"
                                maxlength="30"
                                style="border: 1px solid gray"
                                readonly
                                style="background-color: lightgray"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <!--
                        <div class="field">
                          <div class="fields" style="margin-bottom: 0px">
                            <div class="eight wide field">
                              <div class="field" >
                                <label>ATB</label>
                                <input  type="text" [(ngModel)]="model['schLocalAta']" name="schLocalAta" placeholder="ATA" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray" >
                              </div>
                            </div>
                            <div class="eight wide field">
                              <div class="field" >
                                <label>ATD</label>
                                <input   type="text" [(ngModel)]="model['schLocalAtd']" name="schLocalAtd" placeholder="ATD" maxlength="30" style="border:1px solid gray;" readonly style="background-color: lightgray" >
                              </div>
                            </div>
                          </div>
                        </div>
                      -->
                    </fieldset>
                  </div>
                </div>
              </div>

              <div class="four wide field">
                <!-- 4th Pane from Left Start -->
                <div class="field">
                  <div class="two fields" style="margin-bottom: 0px">
                    <div class="field">
                      <label>Reference No</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schSurchargeId']"
                        name="schSurchargeId"
                        placeholder="Reference Number"
                        maxlength="20"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>

                    <div class="field">
                      <label class="hidden">T</label>
                      <div class="ui checkbox" style="z-index: 10">
                        <input
                          id="invalidcheckbox"
                          type="checkbox"
                          [checked]="model['schIsValid'] == 'N'"
                          (change)="changeInvalid($event)"
                        />
                        <!--[disabled]="lock == true || model['disableInvalid'] == 'true' "-->
                        <label>Invalid</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label>User ID</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schUserID']"
                        name="schUserID"
                        placeholder="User ID"
                        maxlength="20"
                        style="border: 1px solid gray"
                        disabled
                      />
                    </div>
                    <div
                      class="eight wide required field"
                      [class.error]="
                        model['error-schAcctPic'] != null &&
                        model['error-schAcctPic'] != ''
                      "
                      style="margin-bottom: 5px"
                    >
                      <label>Acct PIC</label>
                      <combo-plugin
                        #cbAcctPic
                        [settings]="settingCbAcctPIC"
                        (change)="changeAcctPIC($event)"
                        [disableCombo]="lock == true"
                      ></combo-plugin>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label>Created By</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schUserCreated']"
                        name="schUserCreated"
                        placeholder="Created By"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                    <div class="eight wide field">
                      <label>Created Date</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schDateCreated']"
                        name="schDateCreated"
                        placeholder="Created Date"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="fields" style="margin-bottom: 0px">
                    <div class="eight wide field">
                      <label style="font-size: 11px">Last Modified By</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schUserModified']"
                        name="schUserModified"
                        placeholder="Last Modified By"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>

                    <div class="eight wide field">
                      <label style="font-size: 11px">Last Modified Date</label>
                      <input
                        type="text"
                        [(ngModel)]="model['schDateModified']"
                        name="schDateModified"
                        placeholder="Last Modified Date"
                        maxlength="50"
                        style="border: 1px solid gray"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <!-- <div class="field" style="margin-top: 10px;">
                <fieldset style="border: 1px solid lightgray;" disabled=true>
                  <legend style="font-weight:bold">POL (For Info Only)</legend>
                  <div class="field">
                    <div class="fields">
                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray">
                          <legend style="font-weight:bold">Detention</legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input checked="checked" name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input  name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>Standard</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox" style="width:10%;margin-top:1px;">
                                  <input  name="podDetention" type="radio" tabindex="0" class="hidden">
                                  <label>Request</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <input  id="maintenance_podDetentionRequest" type="number"   max="99999" (keypress)="NumOnly($event)" name="poddetentionRequest" placeholder="0"  [disabled]="lock == false">
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <!--pod detention group-->
                <!-- </div>
                      <div class="eight wide field">
                        <fieldset style="border: 1px solid lightgray;" > <!-- width: 56%; -->
                <!-- <legend style=" font-weight:bold;">Demurrage</legend>
                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input checked="checked" name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>N/A</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox">
                                  <input  name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>Standard</label>
                                </div>
                              </div>
                              <div class="eight wide field">&nbsp;</div>
                            </div>
                          </div>

                          <div class="field">
                            <div class="fields" style="margin-bottom: 0px">
                              <div class="eight wide field">
                                <div class="ui radio checkbox" style="margin-top:1px;">
                                  <input  name="podDemurrage" type="radio" tabindex="0" class="hidden">
                                  <label>Request</label>
                                </div>
                              </div>
                              <div class="eight wide field">
                                <input  id="maintenance_podDemRequest" type="number"  max="99999" (keypress)="NumOnly($event)" name="poddemurrageRequest" placeholder="0"  oninput="this.value = Math.abs(this.value)" onkeydown="return (event.keyCode!=13);" [disabled]="lock == false">
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div> -->

                <!-- for POL-->

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray" disabled="true">
                    <legend style="font-weight: bold">
                      POL
                    </legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <legend style="font-weight: bold">Detention</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                               <input
                                [checked]="model['schDetentionPOL'] == 'N'"
                                (change)="changeEventDetentionPOL($event)"
                                value="N"
                                name="detentionPol"
                                type="radio"
                                tabindex="0"
                                class="hidden"
                                [disabled]="lock == true"
                              />
                                <label>N/A</label>
                              </div>
                            </div>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                [checked]="model['schDetentionPOL'] == 'S'"
                                (change)="changeEventDetentionPOL($event)"
                                value="S"
                                name="detentionPol"
                                type="radio"
                                tabindex="0"
                                class="hidden"
                                [disabled]="lock == true"
                              />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div
                                class="ui radio checkbox"
                                style="margin-top: 1px"
                              >
                                <input
                                [checked]="model['schDetentionPOL'] == 'R'"
                                (change)="changeEventDetentionPOL($event)"
                                value="R"
                                name="detentionPol"
                                type="radio"
                                tabindex="0"
                                class="hidden"
                                [disabled]="lock == true"
                              />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                name="detentionReqPol"
                                [(ngModel)]="model['schDetentionRequestPOL']"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="lockDetentionPol == true || lock == true"
                                placeholder=""
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <legend style="font-weight: bold">Demurrage</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurragePOL'] == 'N'"
                                  (change)="changeEventDemurragePol($event)"
                                  value="N"
                                  name="demurragePol"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>N/A</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurragePOL'] == 'S'"
                                  (change)="changeEventDemurragePol($event)"
                                  value="S"
                                  name="demurragePol"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurragePOL'] == 'R'"
                                  (change)="changeEventDemurragePol($event)"
                                  value="R"
                                  name="demurragePol"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                name="demurrageReqPol"
                                [(ngModel)]="model['schDemurrageRequestPOL']"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="lockDemurragePol == true || lock == true"
                                placeholder=""
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="field" style="margin-top: 10px">
                  <fieldset style="border: 1px solid lightgray" disabled="true">
                    <legend style="font-weight: bold">POD</legend>
                    <div class="field">
                      <div class="fields" style="margin-bottom: 0px">
                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <legend style="font-weight: bold">Detention</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDetention'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDetention($event)"
                                  name="schDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="
                                    lock == true || lockDetention == false
                                  "
                                />
                                <label>N/A</label>
                              </div>
                            </div>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDetention'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDetention($event)"
                                  name="schDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div
                                class="ui radio checkbox"
                                style="margin-top: 1px"
                              >
                                <input
                                  [checked]="model['schDetention'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDetention($event)"
                                  name="schDetention"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                [(ngModel)]="model['schDetentionRequest']"
                                name="schDetentionRequest"
                                max="99999"
                                (keypress)="NumOnly($event)"
                                placeholder="0"
                                oninput="this.value = Math.abs(this.value)"
                                onkeydown="return (event.keyCode!=13);"
                                [disabled]="
                                  lock == true || model['schDetention'] != 'R'
                                "
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>

                        <div class="eight wide field">
                          <fieldset style="border: 1px solid lightgray">
                            <!--width: 56%;-->
                            <legend style="font-weight: bold">Demurrage</legend>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurrage'] == 'N'"
                                  value="N"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="schDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="
                                    lock == true || lockDemurrage == false
                                  "
                                />
                                <label>N/A</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurrage'] == 'S'"
                                  value="S"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="schDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Standard</label>
                              </div>
                            </div>

                            <div class="field">
                              <div class="ui radio checkbox">
                                <input
                                  [checked]="model['schDemurrage'] == 'R'"
                                  value="R"
                                  (change)="changeEventPfcDemurrage($event)"
                                  name="schDemurrage"
                                  type="radio"
                                  tabindex="0"
                                  class="hidden"
                                  [disabled]="lock == true"
                                />
                                <label>Request</label>
                              </div>
                            </div>

                            <div class="field">
                              <input
                                type="number"
                                [(ngModel)]="model['schDemurrageRequest']"
                                name="schDemurrageRequest"
                                max="99999"
                                (keypress)="NumOnly($event)"
                                placeholder="0"
                                [disabled]="
                                  lock == true || model['schDemurrage'] != 'R'
                                "
                                style="border: 1px solid gray"
                              />
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <!--
                <div class="three wide fields" style="margin-top: 20px">
                    <div class="field" style="margin-left: 5px; margin-right: 5px">
                      <button style="font-size:1.1em; height: 100px" class="large ui button" (click)="onSurcharge()" [disabled]="lock == true">Surcharge</button>
                    </div>

                    <div class="field" style="margin-left: 5px; margin-right: 5px">
                      <button style="font-size:1.1em; height: 100px; margin-left: 43px" class="large ui button" (click)="onTransmit()" [disabled]="lock == true || model['schAppStatus'] != 'P'">Transmit</button>
                    </div>

                    <div class="field">
                      &nbsp;
                    </div>
                </div>
              -->
                </div>
              </div>
              <!-- 4th Pane from Left End -->
            </div>
          </div>
        </div>
        <!-- End of Maintenance General Tab -->

        <!-- Start of Tab Terminal -->
        <div
          class="ui bottom attached tab segment"
          id="maintenanceTerminalTab"
          data-tab="maintenanceTerminalTab"
        >
          <!-- Row 1 start -->
          <div class="two fields">
            <div class="field">
              <label>POR Terminal</label>
              <combo-plugin
                #cbMaintenancePORTerminal
                [settings]="settingCbMaintenancePORTerminal"
                (change)="changeCbMaintenancePORTerminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockPORTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>PODL Terminal</label>
              <combo-plugin
                #cbMaintenancePODLTerminal
                [settings]="settingCbMaintenancePODLTerminal"
                (change)="changeCbMaintenancePODLTerminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockPODLTerminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 1 end-->

          <!-- Row 2 start -->
          <div class="two fields">
            <div class="field">
              <label>POL Terminal</label>
              <combo-plugin
                #cbMaintenancePOLTerminal
                [settings]="settingCbMaintenancePOLTerminal"
                (change)="changeCbMaintenancePOLTerminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockPOLTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>POT1 Terminal</label>
              <combo-plugin
                #cbMaintenancePOT1Terminal
                [settings]="settingCbMaintenancePOT1Terminal"
                (change)="changeCbMaintenancePOT1Terminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockPOT1Terminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 2 end-->

          <!-- Row 3 start -->
          <div class="two fields">
            <div class="field">
              <label>POD Terminal</label>
              <combo-plugin
                #cbMaintenancePODTerminal
                [settings]="settingCbMaintenancePODTerminal"
                (change)="changeCbMaintenancePODTerminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockPODTerminal"
              ></combo-plugin>
            </div>
            <div class="field">
              <label>FDEST Terminal</label>
              <combo-plugin
                #cbMaintenanceFDESTTerminal
                [settings]="settingCbMaintenanceFDESTTerminal"
                (change)="changeCbMaintenanceFDESTTerminal($event)"
                (keypress)="CharNumOnly($event)"
                [disableCombo]="lockFDESTTerminal"
              ></combo-plugin>
            </div>
          </div>
          <!-- Row 3 end-->
        </div>
        <!-- End of Maintenance terminal tab -->

        <div class="ui styled accordion field" style="width: 100%">
          <!-- Grid Charge Detail Start -->
          <div class="title"><i class="icon dropdown"></i>Details</div>

          <div class="three fields" style="margin-left: 10px">
            <div class="field">
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailNew()"
                [disabled]="lock == true"
              >
                New
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailUpdate()"
                [disabled]="lock == true"
              >
                Update
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailRemove()"
                [disabled]="lock == true"
              >
                Remove
              </button>
              <button
                class="ui button"
                style="font-size: 10px"
                (click)="onDetailCopy()"
                [disabled]="lock == true"
              >
                Copy
              </button>
            </div>
            <div class="field"></div>
            <div class="field"></div>
          </div>
          <div
            class="ui top attached tabular menu"
            style="background-color: beige"
          >
            <!--
          <a class="item"  [class.active]="currentActiveTab=='prepaid'"  data-tab="Prepaid" (click)="onTabPrepaid()">Prepaid</a>
          <a class="item" [class.active]="currentActiveTab=='collect'"  data-tab="Collect" (click)="onTabCollect()">Collect</a>
          -->
            <a
              class="item"
              [class.active]="tabStatus == 'tab1'"
              data-tab="Prepaid"
              (click)="initTab1()"
              >Prepaid</a
            >
            <a
              class="item"
              [class.active]="tabStatus == 'tab2'"
              data-tab="Collect"
              (click)="initTab2()"
              >Collect</a
            >
          </div>

          <!--<div class="ui bottom attached tab segment active" id="PrepaidTab" data-tab="Prepaid" >-->
          <div
            class="ui bottom attached tab segment"
            [class.active]="tabStatus1 == 'active' && tabStatus == 'tab1'"
            id="PrepaidTab"
            data-tab="Prepaid"
          >
            <div class="field">
              <!--
            <grid-plugin id='gridPrepaid'  #gridPrepaid [settings]="settingGridPrepaid"  (gridEvent)="gridEventPrepaid($event)" (infoGrid)="infoGridPrepaid($event)"  >
            </grid-plugin>
            -->
              <ag-grid-angular
                #gridPrepaid2
                style="width: 100%; height: 400px"
                [getRowStyle]="getRowStyle"
                class="ag-theme-balham"
                id="gridPrepaid2"
                [enableSorting]="false"
                [enableFilter]="true"
                [enableColResize]="true"
                [rowData]="rowDataPrepaid"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="true"
                [columnDefs]="columnDefsPrepaid"
                [pinnedBottomRowData]="pinnedBottomRowDataPrepaid"
                [animateRows]="true"
                [enableCellChangeFlash]="true"
                (rowDoubleClicked)="rowDoubleClick($event)"
                (gridReady)="onGridReadyPrepaid($event)"
                (selectionChanged)="onSelectionChanged($event)"
              >
              </ag-grid-angular>
            </div>
            <!--field-->
          </div>
          <!-- Prepaid-->

          <div
            class="ui bottom attached tab segment"
            [class.active]="tabStatus2 == 'active' || tabStatus == 'tab2'"
            id="CollectTab"
            data-tab="Collect"
          >
            <div class="field">
              <!--
            <grid-plugin id='gridCollect' #gridCollect [settings]="settingGridCollect"  (gridEvent)="gridEventCollect($event)" (infoGrid)="infoGridCollect($event)"></grid-plugin>
            -->
              <ag-grid-angular
                #gridCollect2
                style="width: 100%; height: 400px"
                [getRowStyle]="getRowStyle"
                class="ag-theme-balham"
                id="gridCollect2"
                [enableSorting]="false"
                [enableFilter]="true"
                [enableColResize]="true"
                [rowData]="rowDataCollect"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="true"
                [columnDefs]="columnDefsCollect"
                [pinnedBottomRowData]="pinnedBottomRowDataCollect"
                [animateRows]="true"
                [enableCellChangeFlash]="true"
                (rowDoubleClicked)="rowDoubleClick($event)"
                (gridReady)="onGridReadyCollect($event)"
                (selectionChanged)="onSelectionChanged($event)"
              >
              </ag-grid-angular>
            </div>
            <!--field-->
          </div>

          <!--
        <div class="field">
          <div class="two wide fields">
            <div class="field">
              <label>Remarks Principal</label>
              <textarea-plugin #txtRemarksPrincipal [settings]="settingRemarksPrincipal" [disabled]="lockRemarksPrincipal"></textarea-plugin>
            </div>
            <div class="field">
              <label>Remarks Agency</label>
              <textarea-plugin #txtRemarksAgency [settings]="settingRemarksAgency" [disabled]="lock == true"></textarea-plugin>
            </div>
          </div>
        </div>
      -->
        </div>
        <!-- Grid Charge Detail Stop -->
      </div>
      <!-- End of Freight Maintenance Form Control -->
    </form>
  </div>
</div>

<!-- START, For NCT Form -->
<div id="NCTForm" style="width: 25%; padding: 0.5%; display: none">
  <form class="ui form" style="width: 100%">
    <fieldset style="border: 1px solid lightgray; margin-left: 0.5px">
      <!--margin-right:15px;-->
      <legend style="font-weight: bold; color: dodgerblue; margin-bottom: 0.1%">
        Copy Rate From Container Type
      </legend>
      <div class="field" style="margin-bottom: 2%">
        <label style="font-size: 11px"
          >Which cntr type do you want to copy from?</label
        >

        <combo-plugin
          #nctform_ctrType
          [settings]="setting_nctFormForContainerType"
          (change)="value_nctFormForContainerType($event)"
          (keypress)="CharNumOnly($event)"
          [disableCombo]="lock"
        ></combo-plugin>
      </div>
      <div class="two fields">
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="onNCT_Save()"
          >
            Save
          </button>
        </div>
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="onNCT_Cancel($event)"
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- END, For NCT Form -->

<!-- START, For Delete Form -->
<div id="DeleteForm" style="width: 25%; padding: 0.5%; display: none">
  <div class="ui error message" [class.hidden]="isErrorDeleted == false">
    <ul>
      <li
        [class.hidden]="
          modelDelete['error-schReasonDeleted'] == null ||
          modelDelete['error-schReasonDeleted'] == ''
        "
      >
        {{ modelDelete["error-schReasonDeleted"] }}
      </li>
    </ul>
  </div>
  <form class="ui form" style="width: 100%">
    <fieldset style="border: 1px solid lightgray; margin-left: 0.5px">
      <!--margin-right:15px;-->
      <div class="field" style="margin-bottom: 2%">
        <label style="font-size: 11px">Please entry the reason of delete</label>
        <textarea
          id="reasonDelete"
          name="reason"
          style="font-size: 12px"
          [(ngModel)]="modelDelete['schReasonDeleted']"
          placeholder="reason delete"
        ></textarea>
      </div>
      <div class="two fields">
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="this.deleteProcess()"
          >
            Process
          </button>
        </div>
        <div class="field" style="width: 20%">
          <button
            class="ui button"
            style="font-size: 9px"
            (click)="
              this.closeDialog('DeleteForm');
              modelDelete['schReasonDeleted'] = '';
              this.isErrorDeleted = false
            "
          >
            Cancel
          </button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
<!-- END, For Delete Form -->

<div
  id="historyDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td>
            <div [innerHTML]="msg"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="onHistoryDialogOk()">
        OK
      </button>
    </div>
  </form>
</div>

<!-- charge management section -->
<div
  id="freightNewChargeForm"
  style="display: none; font-family: Lucida Console, monospace"
>
  <!--class="ui fullscreen modal"  style="width:90%;padding:1%;">-->

  <!--Error Message Block-->
  <div class="ui error message" [class.hidden]="isErrorDetail == false">
    <ul>
      <li
        [class.hidden]="
          modelDetail['error-schDetailChargeCode'] == null ||
          modelDetail['error-schDetailChargeCode'] == ''
        "
      >
        {{ modelDetail["error-schDetailChargeCode"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-schDetailCurrency'] == null ||
          modelDetail['error-schDetailCurrency'] == ''
        "
      >
        {{ modelDetail["error-schDetailCurrency"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-schDetailPC'] == null ||
          modelDetail['error-schDetailPC'] == ''
        "
      >
        {{ modelDetail["error-schDetailPC"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-schDetailPlaceOfPayment'] == null ||
          modelDetail['error-schDetailPlaceOfPayment'] == ''
        "
      >
        {{ modelDetail["error-schDetailPlaceOfPayment"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-chargesValueValidator'] == null ||
          modelDetail['error-chargesValueValidator'] == ''
        "
      >
        {{ modelDetail["error-chargesValueValidator"] }}
      </li>
      <li
        [class.hidden]="
          modelDetail['error-schDuplicateChargeCode'] == null ||
          modelDetail['error-schDuplicateChargeCode'] == ''
        "
      >
        {{ modelDetail["error-schDuplicateChargeCode"] }}
      </li>
    </ul>
  </div>
  <!--End of Error Message Block-->

  <form class="ui form" style="width: 100%">
    <!-- form start -->
    <h4
      class="ui dividing header"
      style="width: 100%; text-align: left; margin-top: 10px"
    ></h4>
    <div class="field">
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_OK()"
        [disabled]="lock == true"
      >
        OK
      </button>
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_Reset()"
        [disabled]="lock == true"
      >
        Reset
      </button>
      <button
        class="ui button"
        style="font-size: 12px"
        (click)="onNewCharge_Close()"
      >
        Close
      </button>
    </div>

    <fieldset
      style="width: 100%; border: 1px solid lightgray; margin-bottom: 1px"
    >
      <div class="nine wide fields" style="margin-left: 0.5px">
        <div class="field" style="margin-bottom: 3px; width: 10%">
          <label>Seq No.</label>
          <input
            type="number"
            [(ngModel)]="modelDetail['schDetailSeqNo']"
            name="schDetailSeqNo"
            placeholder="0"
            maxlength="3"
            readonly
          />
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-schDetailChargeCode'] != null &&
            modelDetail['error-schDetailChargeCode'] != ''
          "
          style="width: 35%"
        >
          <label style="color: red">Charge</label>
          <combo-plugin
            #cbMaintenanceDetailCharge
            [settings]="settingCbMaintenanceDetailCharge"
            (change)="changeCbMaintenanceDetailCharge($event)"
            [disableCombo]="lock == true"
            (keypress)="validateFieldSurcharge($event)"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-schDetail '] != null &&
            modelDetail['error-schDetailCurrency'] != ''
          "
          style="width: 15%"
        >
          <label style="color: red">Currency</label>
          <combo-plugin
            #cbMaintenanceDetailCurrency
            [settings]="settingCbMaintenanceDetailCurrency"
            (change)="changeCbMaintenanceDetailCurrency($event)"
            [disableCombo]="lock == true"
            (keypress)="formValidation.AlphabetsIncludeSpace($event)"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-schDetailPC'] != null &&
            modelDetail['error-schDetailPC'] != ''
          "
          style="width: 9%"
        >
          <label style="color: red">P/C</label>
          <combo-plugin
            #cbMaintenanceDetailPC
            [settings]="settingCbMaintenanceDetailPC"
            (change)="changeCbMaintenanceDetailPC($event)"
            (keypress)="PCOnly($event)"
            [disableCombo]="lock == true"
            [disableCombo]="lock == true"
          ></combo-plugin>
        </div>
        <div
          class="required field"
          [class.error]="
            modelDetail['error-schDetailPlaceOfPayment'] != null &&
            modelDetail['error-schDetailPlaceOfPayment'] != ''
          "
          style="width: 20%"
        >
          <label style="color: red">Place of Payment</label>
          <combo-plugin
            #cbMaintenanceDetailPlaceOfPayment
            [settings]="settingCbMaintenanceDetailPlaceOfPayment"
            (change)="changeCbMaintenancePlaceOfPayment($event)"
            [disableCombo]="lock == true"
          ></combo-plugin>
        </div>
        <div class="field" style="width: 35%">
          <label
            >Payer
            <a
              [class.hidden]="fpPayer == ''"
              style="font-size: 10px; font-weight: bold; color: navy"
              >{{ fpPayer }}</a
            >
          </label>
          <combo-plugin
            #cbMaintenanceDetailPayer
            [settings]="settingCbMaintenanceDetailPayer"
            (change)="changeCbMaintenanceDetailPayer($event)"
            [disableCombo]="lock == true"
            (keypress)="validateFieldSurcharge($event)"
          ></combo-plugin>
        </div>
      </div>
      <!--End for nine wide fields-->

      <div class="two wide fields">
        <div class="field">
          <div class="nine wide fields" style="margin-left: 2px">
            <!--div class="six wide fields"-->
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D20</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailD20']"
                name="schDetailD20"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H20</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailH20']"
                name="schDetailH20"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D40</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailD40']"
                name="schDetailD40"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H40</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailH40']"
                name="schDetailH40"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>D45</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailD45']"
                name="schDetailD45"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <div
              class="field"
              [class.error]="
                modelDetail['error-chargesValueValidator'] != null &&
                modelDetail['error-chargesValueValidator'] != ''
              "
              style="margin-bottom: 3px; width: 16%"
            >
              <label>H45</label>
              <input
                type="number"
                step="0.001"
                min="0"
                [(ngModel)]="modelDetail['schDetailH45']"
                name="schDetailH45"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                style="text-align: right"
                [disabled]="lock == true"
                maxlength='10'
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                (keypress)="onlyWholeNumAndDot($event)"
              />
            </div>
            <!--/div-->
          </div>
          <div class="nine wide fields" style="margin-left: 2px">
            <!--div class="six wide fields"-->
            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>M3</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['schDetailM3']"
                name="schDetailM3"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>KT</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['schDetailKT']"
                name="schDetailKT"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 16%">
              <label>RT</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['schDetailRT']"
                name="schDetailRT"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 19%">
              <label>Min Charge</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['schDetailMinCharge']"
                name="schDetailMinCharge"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>

            <div class="field" style="margin-bottom: 3px; width: 20%">
              <label>Kill Slot</label>
              <input
                type="number"
                min="0"
                [(ngModel)]="modelDetail['schDetailKillSlot']"
                name="schDetailKillSlot"
                onkeydown="return (event.keyCode!=13);"
                placeholder="0"
                maxlength="10"
                disabled
                style="text-align: right; background-color: lightgray"
              />
            </div>
            <!--/div-->
          </div>
          <div
            class="three wide fields"
            style="margin-bottom: 3px; margin-left: 7.2px"
          >
            <!--
            <div class="field">
              <div class="ui test checkbox" >
                <input type="checkbox" disabled="true"  name="schDetailFAIGroup" tabindex="0" [checked]="modelDetail['schDetailFAIGroup'] == 'Y'"  (change)="changeEventPfcDetailFAIGroup($event)">
                <label style="margin-left: 3px;font-weight: bold" >FAI Group</label>
              </div>
            </div>
          -->
            <div class="field" style="width: 100%">
              <div class="ui test checkbox">
                <input
                  [checked]="modelDetail['schDetailCopyTab'] == 'Y'"
                  value="Y"
                  type="checkbox"
                  name="schDetailCopyTab"
                  tabindex="0"
                  (change)="changeEventPfcDetailCopyTab($event)"
                />
                <label style="margin-left: 3px; font-weight: bold"
                  >Copy Freight to
                  {{
                    currentActiveTab == "prepaid" ? "Collect" : "Prepaid"
                  }}</label
                >
              </div>
            </div>
          </div>
          <div class="field">&nbsp;</div>
        </div>
        <div class="field">
          <div class="two wide fields">
            <div
              class="field"
              [class.disabled]="lockOOGOOG == true"
              style="width: 70%"
            >
              <fieldset
                id="oogDetailCMGroup"
                style="border: 1px solid lightgray; margin-bottom: 1px"
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  OOG Details(CM)
                </legend>
                <div class="field" style="width: 36%; margin-bottom: 3px">
                  <label>OH</label>
                  <input
                    type="number"
                    min="0"
                    [disabled]="lockOOGOOG == true"
                    [(ngModel)]="modelDetail['schDetailOogOH']"
                    name="schDetailOogOH"
                    onkeydown="return (event.keyCode!=13);"
                    placeholder="0"
                    maxlength='10'
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    (keypress)="onlyWholeNumAndDot($event)"
                    style="text-align: right"
                  />
                </div>

                <div
                  class="two fields"
                  [class.disabled]="
                    lockOOGOOG == true ||
                    this.modelDetail.schDetailContainerType == 'OPT'
                  "
                >
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OWL</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.schDetailContainerType == 'OPT'
                      "
                      [(ngModel)]="modelDetail['schDetailOogOWL']"
                      name="schDetailOogOWL"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OWR</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.schDetailContainerType == 'OPT'
                      "
                      [(ngModel)]="modelDetail['schDetailOogOWR']"
                      name="schDetailOogOWR"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <div
                  class="two fields"
                  [class.disabled]="
                    lockOOGOOG == true ||
                    this.modelDetail.schDetailContainerType == 'OPT'
                  "
                >
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OLF</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.schDetailContainerType == 'OPT'
                      "
                      [(ngModel)]="modelDetail['schDetailOogOLF']"
                      name="schDetailOogOLF"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                  <div class="field" style="width: 40%; margin-bottom: 3px">
                    <label>OLB</label>
                    <input
                      type="number"
                      min="0"
                      [disabled]="
                        lockOOGOOG == true ||
                        this.modelDetail.schDetailContainerType == 'OPT'
                      "
                      [(ngModel)]="modelDetail['schDetailOogOLB']"
                      name="schDetailOogOLB"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="field" [class.disabled]="lockOOGDoorOpen == true">
              <fieldset
                id="doorOpenGroup"
                style="
                  width: 100%;
                  border: 1px solid lightgray;
                  margin-bottom: 1px;
                "
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  Door Open
                </legend>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['schDetailOogDoorOpen'] == 'N'"
                        value="N"
                        [disabled]="lockOOGDoorOpen == true"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="schDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>N/A</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['schDetailOogDoorOpen'] == 'D'"
                        value="D"
                        [disabled]="lockOOGDoorOpen == true"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="schDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Detached</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['schDetailOogDoorOpen'] == 'B'"
                        value="B"
                        [disabled]="lockOOGDoorOpen == true"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="schDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Tied Both</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['schDetailOogDoorOpen'] == 'L'"
                        value="L"
                        [disabled]="lockOOGDoorOpen == true"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="schDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Tied Left</label>
                    </div>
                  </div>
                </div>
                <div class="one wide fields" style="margin-bottom: 1px">
                  <div class="field" style="margin-top: 1px">
                    <div class="ui radio checkbox">
                      <input
                        [checked]="modelDetail['schDetailOogDoorOpen'] == 'R'"
                        value="R"
                        [disabled]="lockOOGDoorOpen == true"
                        (change)="changeEventPfcDetailDoorOpen($event)"
                        name="schDetailOogDoorOpen"
                        type="radio"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Tied Right</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <!--Door Open-->

            <div class="field" [class.disabled]="lockOOGUC == true">
              <fieldset
                id="ucCMGroup"
                style="
                  width: 80%;
                  border: 1px solid lightgray;
                  margin-bottom: 1px;
                "
              >
                <legend
                  style="
                    font-weight: bold;
                    color: dodgerblue;
                    margin-bottom: 1px;
                  "
                >
                  UC(CM)
                </legend>
                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>H</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="lockOOGUC == true"
                      [(ngModel)]="modelDetail['schDetailOogUcH']"
                      name="schDetailOogUcH"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for first row //two fields width:50%;-->
                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>L</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="lockOOGUC == true"
                      [(ngModel)]="modelDetail['schDetailOogUcL']"
                      name="schDetailOogUcL"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for second row //two fields -->

                <div class="two fields">
                  <div class="field" style="width: 9%; margin-bottom: 3px">
                    <label>W</label>
                  </div>
                  <div class="field" style="width: 71%; margin-bottom: 3px">
                    <input
                      type="number"
                      min="0"
                      [disabled]="lockOOGUC == true"
                      [(ngModel)]="modelDetail['schDetailOogUcW']"
                      name="schDetailOogUcW"
                      onkeydown="return (event.keyCode!=13);"
                      placeholder="0"
                      maxlength='10'
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      (keypress)="onlyWholeNumAndDot($event)"
                      style="text-align: right"
                    />
                  </div>
                </div>
                <!-- for 3rd row //two fields -->
              </fieldset>
            </div>
            <!--UCM-->
          </div>
        </div>
      </div>
    </fieldset>
  </form>
  <!-- form end -->
</div>

<!-- end of charge management section -->

<!--<dialog-multiple-plugin #dialogMultiple (eventDialog)="eventMessage($event)"></dialog-multiple-plugin>-->

<div
  id="multipleCtypeDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>
            {{ multipleCtypeDialogText }}
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button
        class="positive ui button"
        (click)="onMultipleCtypeDialogYes($event)"
      >
        Yes
      </button>
      <button
        class="negative ui button"
        (click)="onMultipleCtypeDialogCancel($event)"
      >
        Cancel
      </button>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
