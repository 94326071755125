import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { PackageType } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-master-package-type-page",
  templateUrl: "./master-package-type-page.component.html",
  styleUrls: ["./master-package-type-page.component.css"],
})
export class MasterPackageTypePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbPackageTypeNameHistory")
  cbPackageTypeNameHistory: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingPackageTypeLogStartDate;
  //settingPackageTypeLogEndDate;
  settingPackageTypeHistory;

  /* Misc Parameters */
  valPackageTypeCode: string = "";
  valPackageTypeName: string = "";
  valPackageTypeId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  typePackageTypeLogStartDate = "calendar";
  typePackageTypeLogEndDate = "calendar";

  /* Parameter model */
  model = new PackageType();
  //selectList = [];

  loading = false;

  lock = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    packageTypeName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input PackageType Name.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9-()/,. ]*$/,
          prompt: "Only alphanumeric and -(). that allowed",
        },
      ],
    },
    packageTypeCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input PackageType Code.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9-()/,. ]*$/,
          prompt: "Only alphanumeric and -(). that allowed",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAllChar,
          prompt: "Please input valid characters",
        },
      ],
    },
    packageTypeInternationalCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input International Package Type Code.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[A-Za-z0-9 ]*$/,
          prompt: "Only alphanumeric that allowed",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Package Type Code", value: "packageTypeCode" },
        { display: "Package Type Name", value: "packageTypeName" },
        { display: "International Package Code", value: "packageTypeInternationalCode" },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Package Type Name",
          field: "packageTypeName",
          width: 200,
          type: "string",
        },
        { header: "Package Type Code", field: "packageTypeCode", width: 50 },
        { header: "International Package Code", field: "packageTypeInternationalCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "packageTypeName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Package Type Id", field: "packageTypeId", width: 200 },
        {
          header: "Package Type Name",
          field: "packageTypeName",
          width: 200,
          type: "string",
        },
        { header: "Package Type Code", field: "packageTypeCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      
      enablePagination: true,
    };

    this.settingPackageTypeHistory = {
      id: "cbPackageTypeHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterPackageTypes/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "packageTypeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Package Type Id", field: "packageTypeId", width: 50 },
        {
          header: "Package Type Name",
          field: "packageTypeName",
          width: 50,
          type: "string",
        },
        { header: "Package Type Code", field: "packageTypeCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    /*
     this.settingPackageTypeLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingPackageTypeLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }
  onPasteValidation(value: string, regex: RegExp, model:any, field:String ){
    const isFailRegex = regex.test(value)
    model[`error-${field}`] = isFailRegex ? "Only allowed special characters -()/,." : ""
  }
  
  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event: any) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }
        }

        break;
    }
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  toolbarEvent(event: any) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.packageTypeId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new PackageType();
    this.valPackageTypeCode = "";
    this.valPackageTypeName = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event

    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      var deleteObj = {"packageTypeId":"","packageTypeCode":""};
      deleteObj.packageTypeId = element.packageTypeId;
      deleteObj.packageTypeCode = element.packageTypeCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListPackageTypeUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            
            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
            
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );

    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    var formatName = "report-PackageTypes-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/29/PackageTypes-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // window.open(
    //   this.configService.config.BASE_API.toString().replace("9090", "10000") +
    //     "/Mreport/runReport/29/PackageTypes-Export-Reports/*"
    // );
    // handle print event
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new PackageType();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    if (this.valPackageTypeId != "") {
      this.valPackageTypeCode = "";
      this.valPackageTypeId = "";
      this.cbPackageTypeNameHistory.setValue("");
      this.cbPackageTypeNameHistory.close();
      this.gridHistory.onHistory("NaN");
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new PackageType();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.packageTypeId = String(data.packageTypeId).trim().toUpperCase();
    this.model.packageTypeCode = String(data.packageTypeCode)
      .trim()
      .toUpperCase();
    this.model.packageTypeName = String(data.packageTypeName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.packageTypeInternationalCode = String(data.packageTypeInternationalCode).trim().toUpperCase();
  }

  valuePackageTypeHistory(event: any) {
    this.valPackageTypeCode = event["packageTypeCode"];
    this.valPackageTypeId = event["packageTypeId"];
    // console.log("curr code= " + event["packageTypeCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valPackageTypeId);
  }

  generatePackageTypeHistoryReport(event: any) {
    //this.valHistoryStartDate= $('#logHistoryPackageTypeStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryPackageTypeEndDate').find('#datePicker').val();
    if (this.valPackageTypeId == "" || this.valPackageTypeId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      var formatName = "report-History-PackageTypes-"+this.valPackageTypeId+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/30/History-PackageTypes-"
        +
        this.valPackageTypeId +
        "/" +
        this.valPackageTypeId
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      }
    // {
    //   window.open(
    //     this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
    //       "/Mreport/runReport/30/History-PackageTypes-" +
    //       this.valPackageTypeId +
    //       "/" +
    //       this.valPackageTypeId
    //   );
    // }
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterPackageTypes/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new PackageType();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.packageTypeId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"packageTypeId":[]}
    paramsDelete.packageTypeId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/MasterPackageTypes/delete",
        // deleteList
        "/MasterPackageTypes/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new PackageType();
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  onPasteErrorValidation(event: any, field: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    const regex = /^[a-zA-Z-()/., ]*$/

    if (regex.test(pastedText) == false) {
      this.model[`error-${field}`] = "Only allowed special characters -()/.,";
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  onPasteRemarks(event: any, field: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.formValidation.regAllChar.test(pastedText) == false) {
      this.model[`error-${field}`] = "Please input valid characters";
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string) {
    const regex = /^[a-zA-Z-()/., ]*$/
    if (regex.test(this.model[field]) == true) {
      this.model[`error-${field}`] = "";
    }
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }

  CharNumOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z0-9 ]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  onPasteCharNumOnly(event: any, field: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    const regex = /^[A-Za-z0-9 ]*$/

    if (regex.test(pastedText) == false) {
      this.model[`error-${field}`] = "Only allowed Alphanumeric";
    } else {
      this.model[`error-${field}`] = "";
    }
  }
}
