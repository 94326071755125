export class surchargeDetails  {
            surchargeId    : string='';
            containerType  : string='OPT';
            containerSize  : string='';
            containerHeight  : string='';
            seqNo   : number=1;
            chargeCode     : string='';

            currencyCode :string='';
            chargeAmount : number=0;

            placeOfPaymentCode:string='';
            freightType:string='';
            payerCode:string='';
            pcTab:string='';
            user_created:string='';
            date_created:string='';
            user_modified:string='';
            date_modified:string='';


            MasterSurchargeOogDetailsRevised:any;
      constructor() {

    }
}


export class surchargeDetailsForValidator  {
  surchargeId    : string='';
  containerType  : string='OPT';
  containerSize  : string='';
  containerHeight  : string='';
  seqNo   : number=1;
  chargeCode     : string='';

  currencyCode :string='';
  chargeAmount : number=0;
  placeOfPaymentCode:string='';
  freightType:string='';
  payerCode:string='';
  pcTab:string='';
  user_created:string='';
  date_created:string='';
  user_modified:string='';
  date_modified:string='';

  D20:number = 0;
  H20:number =0;  
  D40:number =0;

  H40:number =0;
  D45:number=0;
  H45:number=0;

  percodeAmount:string='1'; // If all charge code is 0, percodeamount is 0. at this time label messge must show.


  MasterSurchargeOogDetailsRevised:any;
constructor() {

}
}
