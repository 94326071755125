<div class="ui segment">
    <div class="ui divided items">
       <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

       <div [style.display]="modeForm?'none':'block'">
            <!-- Begin of Grid -->
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master News</h4>
            <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
            <!-- End of Grid -->
            <!-- Begin Password -->
            <button class="ui button" (click)="forgetPassword()">Forget Password</button>
            <div class="form-popup" id="forgetPassword">
               <div class="window">
                  <form class="ui form" >
                    <h4>Password Reset</h4>
                    <div class="ui inverted dimmer" [class.active]="loadingForget == true" style="z-index:1">
                        <div class="ui medium text loader">Loading</div>
                    </div>
                    <div class="required field" [class.error]="modelEmail['error-to'] != null && modelEmail['error-to'] != ''">
                      <label>Email Address</label>
                      <input type="text" [(ngModel)]="modelEmail['to']" name="email"  placeholder="Email" [disabled]="lock == true" >
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="modelEmail['error-to'] != null && modelEmail['error-to'] != ''">
                        {{modelEmail['error-to']}}
                      </div>
                    </div>
                        <button class="ui button" (click)="submitEmail()">Submit</button>
                        <button class="ui button" (click)="closeForm()">Close</button>
                        <div style="text-align:center;margin-top:10px;">
                          <label>Enter your email address above to reset your password</label>
                        </div>
                    </form>
                </div>
            </div>
            <div class="form-popup" id="otp-popup">
                <div class="window">
                    <form class="ui form" >
                        <h4>Verifikasi OTP</h4>
                        <div class="ui inverted dimmer" [class.active]="loadingOTP == true" style="z-index:1">
                            <div class="ui medium text loader">Loading</div>
                        </div>
                        <div class="required field" [class.error]="modelEmail['error-email'] != null && modelEmail['error-email'] != ''">
                          <label>OTP</label>
                          <input type="text" [(ngModel)]="modelEmail['otp']" name="otp"  placeholder="OTP Number" [disabled]="lock == true" >
                          <div class = "ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="modelEmail['error-otp'] != null && modelEmail['error-otp'] != ''">
                            {{modelEmail['error-otp']}}
                          </div>
                        </div>
                            <button class="ui button" (click)="submitOTP()">Submit</button>
                            <button class="ui button" (click)="closeOTPForm()">Close</button>
                            <div style="text-align:center;margin-top:10px;">
                              <label>Check your email and Enter your number OTP above</label>
                            </div>
                    </form>
                </div>
            </div>
            <!-- End Password -->
        </div>

        <div [style.display]="modeForm?'block':'none'">
            <div class="ui segment" style="padding-left:1%;padding-right:20%;">
                <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
                    <div class="ui medium text loader">Loading</div>
                </div>

                <div id='container'>
                    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

                    <!-- Begin of Form -->
                    <form class="ui form" [style.display]="form == ''?'block':'none'" >
                    <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Master News</h4>

                      <div class="field">
                        <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                          <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                          <label>Valid?</label>
                        </div>
                      </div>

                      <div class="ui styled accordion field" style="width:100%;height: 50%;">
                        <div class="active title">
                          <i class="icon dropdown"></i>
                          General
                        </div>
                        <div class="active content">


                                <div class="required field" [class.error]="model['error-title'] != null && model['error-title'] != ''">
                                  <label>Title</label>
                                  <input type="text" [(ngModel)]="model['title']" name="title"  placeholder="Title" [disabled]="lock == true">
                                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                       [class.error-message-visible]="model['error-title'] != null && model['error-title'] != ''">
                                    {{model['error-title']}}
                                  </div>
                                </div>

                                <div class="required field" [class.error]="model['error-subtitle'] != null && model['error-subtitle'] != ''">
                                  <label>Sub Title</label>
                                  <textarea [(ngModel)]="model['subtitle']" name="subtitle"  placeholder="Sub Title" [disabled]="lock == true"></textarea>
                                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                       [class.error-message-visible]="model['error-subtitle'] != null && model['error-subtitle'] != ''">
                                    {{model['error-subtitle']}}
                                  </div>
                                </div>

                                <div class="field">
                                  <label>Content</label>
                                  <textarea [(ngModel)]="model['content']" name="content" placeholder="content"></textarea>
                                </div>
                        </div>
                      </div>

                      <div class="ui raised segment">
                        <div class="two fields">
                          <div class="field">
                            <label>Created By</label>
                            <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" readonly style="background-color: lightgray">
                          </div>
                          <div class="field">
                            <label>Created Date</label>
                            <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" readonly style="background-color: lightgray">
                          </div>
                        </div>
                        <div class="two fields">
                          <div class="field">
                            <label>Last Modified By</label>
                            <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" readonly style="background-color: lightgray">
                          </div>
                          <div class="field">
                            <label>Last Modified Date</label>
                            <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" readonly style="background-color: lightgray">
                          </div>
                        </div>
                        <div class="two fields">
                          <div class="field">
                            <label>Invalid By</label>
                            <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" readonly style="background-color: lightgray">
                          </div>
                          <div class="field">
                            <label>Invalid Date</label>
                            <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" readonly style="background-color: lightgray">
                          </div>
                        </div>
                      </div>

                    </form>
                    <!-- End of Form -->
                    <!-- Begin of History Form -->
                      <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
                          <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History News</h4>

                        <div class="ui raised segment">
                        <a class="ui red ribbon label">Select Title</a>

                          <div class="active content">
                            <div class="one field" style="margin-top:10px;">
                              <div class="field">
                                <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                              </div>
                            </div>

                            <div class="two fields">
                              <div class="required field" [class.error]="model['error-title'] != null && model['error-title'] != ''">
                                <label>Title</label>
                                <combo-plugin #cbTitleHistory [settings]="settingNewsPageHistory" (change)="valueNewsPageHistory($event)" ></combo-plugin>
                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                     [class.error-message-visible]="model['error-title'] != null && model['error-title'] != ''">
                                  {{model['error-title']}}
                                </div>
                              </div>

                              <div class="required field" [class.error]="model['error-subtitle'] != null && model['error-subtitle'] != ''">
                                <label>Subtitle</label>
                                <input type="text" [value]="valSubtitle" name="newsPageHistorySubtitle"  placeholder="Subtitle" [disabled]="lock == true" maxlength="50" readonly style="background-color: lightgray">
                                <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                     [class.error-message-visible]="model['error-subtitle'] != null && model['error-subtitle'] != ''">
                                  {{model['error-subtitle']}}
                                </div>
                              </div>
                            </div>
<!--
                            <div class="two fields">
                              <div class="field">
                                <label>Log Start Date</label>
                                <calendar-plugin id="logHistoryCurrencyStartDate" [pluginType]="typeCurrencyLogStartDate" [settings]="settingCurrencyLogStartDate"></calendar-plugin>
                              </div>
                              <div class="field">
                                <label>Log End Date</label>
                                <calendar-plugin id="logHistoryCurrencyEndDate" [pluginType]="typeCurrencyLogEndDate" [settings]="settingCurrencyLogEndDate"></calendar-plugin>
                              </div>
                            </div>
-->
                            <div class="field">
                              <grid-plugin #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
                            </div>
                          </div>
                        </div>
                      </form>
                    <!-- End of History Form -->
                </div>
            </div>
        </div>

    </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
