<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <div class="three fields">
          <div class="field" style ="" >
            <label>Spooler Name</label>
            <input type="text" name="spoolerName"  placeholder="Spooler Name" [(ngModel)]="model['spooler']" >
          </div>
          <div class="field" style ="" >
            <label>IP Address</label>
            <input type="text" name="ipAddress"  placeholder="IPAddress" [(ngModel)]="model['ipAddress']" >
          </div>
          <div class="field">
            <div class="ui tiny right floated buttons" style="margin-top:22px">
            <button  class="ui button retrieve" data-content="Retrieve" >Retrieve</button>
            </div>
          </div>
        </div>
      </div>
      
      <div class="ui top attached tabular menu" style="background-color:beige;">
          <a class="item active" data-tab="view" (click)="initTab1()">View</a>
          <a class="item" data-tab="new" (click)="initTab2()">New/Modify</a>
      </div>
      <div class="ui bottom attached tab segment active" id="viewTab" data-tab="view">
        <div class="field">            
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                     
        </div>   
      </div>

       <div class="ui bottom attached tab segment" id="newTab" data-tab="new">
         <div class="field"> 
           <fieldset style="border: 1px solid lightgray;">
             <div class="field" style ="width:40%" >
              <label>Spooler Name</label>
              <input type="text" name="spoolerNames"  placeholder="Spoiler Name" [(ngModel)]="model['spooler']" >
            </div>
            <div class="field" style ="width:40%" >
              <label>IP Address</label>
              <input type="text" name="ipAddresses"  placeholder="IP Address" [(ngModel)]="model['spooler']" >
            </div>
            <div class="field" style ="width:40%" >
              <label>Module</label>
              <combo-plugin #cbModule [settings]="settingModule" (change)="changeEventModule($event)" style="width:1400px;"></combo-plugin>
            </div>
            <div class="field" style="width:40%">
              <label>Remarks</label>
              <textarea style="margin-top:5px;" [(ngModel)]="model['remarks']" name="remarks" placeholder="Input your remarks..." [disabled]="lock == true" ></textarea>
            </div>
            <div class="field">
              <div class="twenty fields">
                <div class="field">
            <div class="ui tiny right floated buttons" >
              <button style="width: 100px" class="ui button ok" data-content="ok" >OK</button>
              </div>
            </div>
            <div class="field">
              <div class="ui tiny right floated buttons" >
              <button style="width: 100px" class="ui button cancel" data-content="cancel" >Cancel</button>
              </div>
            </div>
              </div>
            </div>
           </fieldset>
         </div>
      </div>



    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
