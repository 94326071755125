import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Booking, BookingDetail, BookingFreightCharge, MSailingSchedule } from '../shared/index';
import { ListStore, Authorize, Validator, DialogPluginComponent, GridPluginComponent, ComboPluginComponent,
  DatetimePluginComponent, CalendarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { isEmpty } from 'rxjs/internal/operators';
import {BookingContainerInfo, BookingMisc} from '../shared/models/booking';
import * as timeEntry from '../../assets/js/jquery.plugin.js';
import * as timeEntryLib from '../../assets/js/jquery.timeentry.js';


declare  var $: any;

export class ContainerType {
  containerTypeCode: String = '';
  containerTypeName: String = '';
  isSlot: String = '';
}

@Component({
  selector: 'app-transaction-booking-maintenance-coparn',
  templateUrl: './transaction-booking-maintenance-coparn-page.component.html',
  styleUrls: ['./transaction-booking-maintenance-coparn-page.component.css']
})
export class TransactionBookingMaintenanceCoparnPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbSupplier') cbSupplier: ComboPluginComponent;
  @ViewChild('cbBookingParty') cbBookingParty: ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  @ViewChild('cbPOT1') cbPOT1: ComboPluginComponent;
  @ViewChild('cbShipper') cbShipper: ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort: ComboPluginComponent;
  @ViewChild('cbPOT2') cbPOT2: ComboPluginComponent;
  @ViewChild('cbFreightParty') cbFreightParty: ComboPluginComponent;
  @ViewChild('cbPODL') cbPODL: ComboPluginComponent;
  @ViewChild('cbPOT3') cbPOT3: ComboPluginComponent;
  @ViewChild('cbPOR') cbPOR: ComboPluginComponent;
  @ViewChild('cbFinalDest') cbFinalDest: ComboPluginComponent;
  @ViewChild('cbCommodity') cbCommodity: ComboPluginComponent;
  @ViewChild('cbReceiptType') cbReceiptType: ComboPluginComponent;
  @ViewChild('cbDeliveryType') cbDeliveryType: ComboPluginComponent;
  @ViewChild('cbFeederVessel') cbFeederVessel: ComboPluginComponent;
  @ViewChild('cbFeederVoyage') cbFeederVoyage: ComboPluginComponent;
  @ViewChild('cbExVessel') cbExVessel: ComboPluginComponent;
  // @ViewChild('cbExVoyage') cbExVoyage: ComboPluginComponent;
  @ViewChild('cbNextVessel') cbNextVessel: ComboPluginComponent;
  @ViewChild('cbNextVoyage') cbNextVoyage: ComboPluginComponent;
  @ViewChild('cbHaulier') cbHaulier: ComboPluginComponent;
  @ViewChild('cbSocOPR') cbSocOPR: ComboPluginComponent;
  @ViewChild('cbCocOPR') cbCocOPR: ComboPluginComponent;
  @ViewChild('cbSlotOPR') cbSlotOPR: ComboPluginComponent;
  @ViewChild('cbPackageType') cbPackageType: ComboPluginComponent;
  @ViewChild('cbSpecialDet') cbSpecialDet: ComboPluginComponent;
  @ViewChild('cbReturnTo') cbReturnTo: ComboPluginComponent;
  @ViewChild('cbReturnToPor') cbReturnToPor: ComboPluginComponent;
  @ViewChild('cbReturnToPol') cbReturnToPol: ComboPluginComponent;
  @ViewChild('cbReleaseAt') cbReleaseAt: ComboPluginComponent;
  @ViewChild('cbPackedAt') cbPackedAt: ComboPluginComponent;
  @ViewChild('cbStuffAt') cbStuffAt: ComboPluginComponent;

  @ViewChild('cbPORTerminal') cbPORTerminal: ComboPluginComponent;
  @ViewChild('cbLoadPortTerminal') cbLoadPortTerminal: ComboPluginComponent;
  @ViewChild('cbDischargePortTerminal') cbDischargePortTerminal: ComboPluginComponent;
  @ViewChild('cbPODLTerminal') cbPODLTerminal: ComboPluginComponent;
  @ViewChild('cbFinalDestTerminal') cbFinalDestTerminal: ComboPluginComponent;
  @ViewChild('cbPOT1Terminal') cbPOT1Terminal: ComboPluginComponent;
  @ViewChild('cbPOT2Terminal') cbPOT2Terminal: ComboPluginComponent;
  @ViewChild('cbPOT3Terminal') cbPOT3Terminal: ComboPluginComponent;

  @ViewChild('dtRequestReleaseDate') dtRequestReleaseDate: CalendarPluginComponent;
  @ViewChild('dtExpReturnDate') dtExpReturnDate: CalendarPluginComponent;
  @ViewChild('dtReeferCollection') dtReeferCollection: CalendarPluginComponent;

  // @ViewChild('gridNew') gridNew: GridPluginComponent;
  @ViewChild('grid') grid: GridPluginComponent;
  @ViewChild('grid2') grid2: GridPluginComponent;
  @ViewChild('grid3') grid3: GridPluginComponent;
  @ViewChild('grid4') grid4: GridPluginComponent;
  @ViewChild('grid5') grid5: GridPluginComponent;
  @ViewChild('gridWeightProfiles') gridWeightProfiles: GridPluginComponent;
  @ViewChild('gridContainerProfiles') gridContainerProfiles: GridPluginComponent;

  @ViewChild('gridFreightCharges') gridFreightCharges: GridPluginComponent;
  @ViewChild('gridFreightCurrencySummary') gridFreightCurrencySummary: GridPluginComponent;
  @ViewChild('gridFreightPerSummary') gridFreightPerSummary: GridPluginComponent;

  @ViewChild('gridContainerInfo') gridContainerInfo: GridPluginComponent;

  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;

  // container info component
  @ViewChild('cbBContainerReceiptType') cbBContainerReceiptType: ComboPluginComponent;
  @ViewChild('cbBContainerPackageType') cbBContainerPackageType: ComboPluginComponent;
  @ViewChild('cbReceiptTypeExtra') cbReceiptTypeExtra: ComboPluginComponent;
  @ViewChild('cbVGMMethod') cbVGMMethod: ComboPluginComponent;

  @ViewChild('dateReleaseAt') dateReleaseAt: DatetimePluginComponent;
  @ViewChild('dateReturnToPor') dateReturnToPor: DatetimePluginComponent;
  @ViewChild('dateReturnToPorGateInClosing') dateReturnToPorGateInClosing: DatetimePluginComponent;
  @ViewChild('dateReturnToPorVgmClosing') dateReturnToPorVgmClosing: DatetimePluginComponent;

  @ViewChild('dateReturnToPol') dateReturnToPol: DatetimePluginComponent;
  @ViewChild('dateReturnToPolGateInClosing') dateReturnToPolGateInClosing: DatetimePluginComponent;
  @ViewChild('dateReturnToPolVgmClosing') dateReturnToPolVgmClosing: DatetimePluginComponent;

  @ViewChild('dateStuffAtPor') dateStuffAtPor: DatetimePluginComponent;
  @ViewChild('dateStuffAtPorGateInClosing') dateStuffAtPorGateInClosing: DatetimePluginComponent;
  @ViewChild('dateStuffAtPorVgmClosing') dateStuffAtPorVgmClosing: DatetimePluginComponent;

  @ViewChild('pdfContainer') pdfContainer:PdfPluginComponent;

  @Output() clicked = new EventEmitter();

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog: any;

  loading = false;
  lock = true;
  lockTNK = false;
  appStatusLabel: String = '';
  tabStatus: String = 'tab1';
  lockContainerInfo: Boolean = true;
  lockBtnContainerNew: Boolean = false;
  lockBtnContainerSaveUpdate: Boolean = true;
  lockBtnContainerDelete: Boolean = true;
  lockBtnContainerCancel: Boolean = true;


  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = 'back';
  disableToolbarButtons = '';

  modeToolbar = false;
  model = new Booking();
  bookingContainerInfo = new BookingContainerInfo();
  bookingMisc = new BookingMisc();

  errorUnitContainer: Boolean = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingGridAllocationNew;
  settingGridAllocation;
  settingGridAllocation2;
  settingGridAllocation3;
  settingGridAllocation4;
  settingGridAllocation5;
  settingGridFreightCurrencySummary;
  settingGridFreightPerSummary;
  settingGridWeightProfiles;
  settingGridContainerProfiles;
  settingGridFreightCharges;
  settingGridContainerInfo;
  settingBContainerReceiptType;

  settingSupplier;
  settingBookingParty;
  settingShipper;
  settingFreightParty;
  settingPOR;
  settingPORTerminal;
  settingLoadPort;
  settingLoadPortTerminal;
  settingDischargePort;
  settingDischargePortTerminal;
  settingPODL;
  settingPODLTerminal;
  settingFinalDest;
  settingFinalDestTerminal;
  settingSpecialDet;
  settingPOT1;
  settingPOT1Terminal;
  settingPOT2;
  settingPOT2Terminal;
  settingPOT3;
  settingPOT3Terminal;
  settingCommodity;
  settingReceiptType;
  settingDeliveryType;
  settingFeederVessel;
  settingFeederVoyage;
  settingExVessel;
  settingExVoyage;
  settingNextVessel;
  settingNextVoyage;
  settingHaulier;
  settingReturnTo;
  settingReturnToPor;
  settingReturnToPol;
  settingReleaseAt;
  settingStuffAt;
  settingPackedAt;
  settingPackageType;
  settingSocOPR;
  settingCocOPR;
  settingSlotOPR;
  lockNextVessel: Boolean = true;
  settingCbVGMMethod;

  settingReqReleaseDate;
  settingExpReturnDate;
  settingReeferCollection;
  typeReqReleaseDate = 'calendarBL';
  typeExpReturnDate = 'calendarBL';
  typeReeferCollection = 'calendarBL';

  settingPDF;

  validatorRules = { }

  containerTypes: ContainerType[] = [];
  containerSlots = new Map<String, String>();

  bookingDetails = new Map<String, Map <String, BookingDetail>>();

  isSocBookingParty: Boolean = false;
  isCocBookingParty: Boolean = false;
  isSlotBookingParty: Boolean = false;
  isCOCLocalExceed: Boolean = false;

  isErrorContainerInfo: Boolean = false;

  nvoccBookingParty: Boolean = false;
  nvoccShipper: Boolean = false;
  nvoccFreightParty: Boolean = false;
  isError: Boolean = false;
  isNew: Boolean = false;
  isCopy: Boolean = false;
  isInit: Boolean = true;
  lockEdit: Boolean = false;
  serviceCode: String = '';
  currentContainerType = 'STD';
  d20: Number = 0;
  d40: Number = 0;
  d45: Number = 0;
  h20: Number = 0;
  h40: Number = 0;
  h45: Number = 0;
  bDegree: Number;
  bDegreeTemp: String = 'C';
  bDegreeSign: String = '+';
  killSlot: Number = 0;
  doorOpen: String = 'N';
  UCH: Number = 0;
  UCL: Number = 0;
  UCW: Number = 0;
  OH: String = 'N';
  OW: String = 'N';
  OL: String = 'N';
  heightMeasure: Number = 0;
  leftMeasure: Number = 0;
  rightMeasure: Number = 0;
  frontMeasure: Number = 0;
  endMeasure: Number = 0;

  lockSOC: Boolean = false;
  lockCOC: Boolean = false;
  lockSLOT: Boolean = false;

  lockSOCOPR: Boolean = true;
  lockCOCOPR: Boolean = true;
  lockSLOTOPR: Boolean = true;

  lockWeight: Boolean = true;
  lockDetention: Boolean = true;
  lockDemurrage: Boolean = true;
  lockOH: Boolean = true;
  lockOW: Boolean = true;
  lockOL: Boolean = true;

  lockPORTerminal: Boolean = true;
  lockPOLTerminal: Boolean = true;
  lockPODTerminal: Boolean = true;
  lockPODLTerminal: Boolean = true;
  lockFDESTTerminal: Boolean = true;
  lockPOT1Terminal: Boolean = true;
  lockPOT2Terminal: Boolean = true;
  lockPOT3Terminal: Boolean = true;

  rotation: String = '';
  strParams: string = '';
  defaultBooked: Number = 0;
  defaultBooked2: String = '';
  tsData:any;
  prev: String = '';

  refreightEffectiveFrom: String = '';
  refreightExpiryOn: String = '';
  refreightModifiedBy: String = '';
  refreightModifiedDate: String = '';
  refreightMessage: String = '';
  refreightRefNo: String = '';
  refreightAccountPIC: String = '';
  refreightApprovalBy: String = '';
  refreightApprovedDate: String = '';

  isCRB: boolean = false;

  checkLockAfterSave: boolean = false;

  defaultLocation: String = '';
  defaultCountry: String = '';

  typeDate = 'calendar';
  settingDateReturnToGateIn;
  settingCommonDate;
  bContainerInfoMode: String = 'insert';

  typeSizeComboLoading:boolean =  false;
  typeSizeComboDisabled:boolean = true;
  hitUrl:string = this.configService.config.BASE_API.toString();
  settingCbBContainerPackageType;
  settingCbReceiptTypeExtra;
  settingDateReleaseAt;
  settingDateReturnToPor;
  settingDateReturnToPorGateInClosing;
  settingDateReturnToPorVgmClosing;
  settingDateReturnToPol;
  settingDateReturnToPolGateInClosing;
  settingDateReturnToPolVgmClosing;
  settingDateStuffAtPor;
  settingDateStuffAtPorGateInClosing;
  settingDateStuffAtPorVgmClosing;
  bufferNextVoyage: String = '';

  constructor(private cdr: ChangeDetectorRef,private router:Router, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService, private location:Location) {
    super();



    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterContainerTypes/findGeneral/*/*/*/1/10/ASC/dateCreated').subscribe((resp) =>{
      // console.log(resp.json());
      let det: any[] = resp.json()['content'];

      det.forEach(element => {
        let ct = new ContainerType;
        ct.containerTypeCode = element['containerTypeCode'];
        ct.containerTypeName = element['containerTypeName'];
        ct.isSlot = element['isSlot'];
        this.containerTypes.push(ct);
        if(ct.isSlot == 'Y'){
          this.containerSlots.set(ct.containerTypeCode, ct.containerTypeName);
        }
      });




    });

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'New',  event: 'new', icon : 'plus' },
        { name : 'Modify',  event: 'modify', icon : 'write' },
        { name : 'Save',  event: 'save', icon : 'checkmark' },
        { name : 'Save & Copy',  event: 'copy', icon : 'copy' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Print' ,  event: 'print' , icon : 'file outline' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Back',  event: 'back', icon : 'arrow left' },
        { name : 'Close',  event: 'close', icon : 'remove circle' },
      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType : 'label',
      label : 'Booking Maintenance'
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 150},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150}
      ]
    };

    this.settingGridAllocationNew = {
      id: 'allocation',
      url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'no',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridAllocation = {
      id: 'allocation',
      url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'no',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridAllocation2 = {
      id: 'allocation2',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      //url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      url : '',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridAllocation3 = {
      id: 'allocation3',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      //url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      url : '',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridAllocation4 = {
      id: 'allocation4',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      //url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      url : '',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridAllocation5 = {
      id: 'allocation5',
      //url: this.configService.config.BASE_API.toString() + '/VesselAllocation/getAllotmentDetail',
      //url: this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2',
      url : '',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 50},
        {header: 'Description', field: 'allotmentCode', width: 100},
        {header: 'Allotted', field: 'alloted', width: 100},
        {header: 'Booked', field: 'booked', width: 100},
        {header: 'Available', field: 'available', width: 100},
        {header: 'Resv', field: 'reserved', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 500,
      height: 200,
      minHeight: 200
    };

    this.settingGridFreightPerSummary = {
      id: 'gridFreightPerSummary',
      url:'',
      page: 5,
      columns: [
        {header: 'Container Type', field: 'perCode', width: 75},
        {header: 'Freight Reference No', field: 'recordId', width: 75},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'recordId',
      sortingDirection: 'ASC',
      height: 150,
      minHeight: 150
    }

    this.settingGridFreightCurrencySummary = {
      id: 'gridFreightCurrencySummary',
      url:'',
      page: 5,
      columns: [
        {header: 'Currency', field: 'currencyCode', width: 75},
        {header: 'Amount', field: 'totalPerCurrency', width: 75},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      height: 150,
      minHeight: 150
    }

    this.settingGridWeightProfiles = {
      id: 'gridWeightProfiles',
      url: this.configService.config.BASE_API.toString() + '/MasterBookings/findWeightCalculationByOfficeCodeVesselIdVoyageBound/',
      page: 10,
      columns: [
        {header: 'Description', field: 'description', width: 275, type:'string'},
        {header: 'Total', field: 'total', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 410,
      height: 230
    };

    this.settingGridContainerProfiles = {
      id: 'gridContainerProfiles',
      url: this.configService.config.BASE_API.toString() + '/MasterBookings/findContainerProfilesByOfficeCodeVesselIdVoyageBound',
      page: 10,
      columns: [
        {header: 'Type', field: 'cType', width: 100, type: 'string'},
        {header: 'D20', field: '20', width: 70},
        {header: 'D40', field: '40', width: 70},
        {header: 'D45', field: '45', width: 70},
        {header: 'H20', field: 'h20', width: 70},
        {header: 'H40', field: 'h40', width: 70},
        {header: 'H45', field: 'h45', width: 70},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      width: 410,
      height: 200,
      minHeight: 200
    };

    this.settingGridFreightCharges = {
      url: '',
      page: 20,
      columns: [
        {header: 'Seq', field: 'no', width: 50},
        {header: 'Payer', field: 'payerName', width: 100},
        {header: 'Reference No.', field: 'recordId', width: 100},
        {header: 'Container Type', field: 'containerTypeName', width: 100},
        {header: 'FAI Grp', field: 'faiGroup', width: 100},
        {header: 'Charge Code', field: 'chargeCode', width: 100},
        {header: 'Per', field: 'perCode', width: 100},
        {header: 'Unit No', field: 'quantity', width: 100},
        {header: 'Rate', field: 'rate', width: 100},
        {header: 'Currency', field: 'currencyCode', width: 100},
        {header: 'Amount', field: 'chargeAmount', width: 100},
        {header: 'P/C', field: 'pc', width: 100},
        {header: 'Place of Payment', field: 'popName', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      height: 220,
      minHeight: 220
    };

    this.settingSupplier = {
      id          : 'cbSupplier',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Supplier --',
      title       : 'customerName',
      isMark      : true,
      description  : '',
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };

    this.settingBookingParty = {
      id          : 'cbBookingParty',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerBookingParty/{query}/1/10',
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Booking Party --',
      title       : 'customerName',
      isMark      : true,
      description  : '',
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };


    this.settingShipper = {
      id          : 'cbShipper',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerShipper/{query}/1/10',
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Shipper --',
      title       : 'customerName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };

    this.settingFreightParty = {
      id          : 'cbFreightParty',
      type        : 'search enter', // search | select | select input
//      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerPayer/{query}/1/10',
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Freight Party --',
      title       : 'customerName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };

    this.settingPOR = {
      id          : 'cbPOR',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Place of Receipt --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPORTerminal = {
      id          : 'cbPORTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Place of Receipt Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Load Port --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingLoadPortTerminal = {
      id          : 'cbLoadPortTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Load Port Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingDischargePort = {
      id          : 'cbDischargePort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Discharge Port --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingDischargePortTerminal = {
      id          : 'cbDischargePortTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Discharge Port Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPODL = {
      id          : 'cbPODL',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Place of Delivery --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPODLTerminal = {
      id          : 'cbPODLTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Place of Delivery Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingFinalDest = {
      id          : 'cbFinalDest',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Final Destination --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingFinalDestTerminal = {
      id          : 'cbFinalDestTerminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Final Destination Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingSpecialDet = {
      id          : 'cbSpecialDet',
      type        : 'search enter', // search | select | select input
      // url         : this.configService.config.BASE_API.toString() + '/MasterSpecialDetails/findGeneral/*/*/{query}/1/5/*/*',
      url          : this.configService.config.BASE_API.toString() + '/MasterSpecialDetails/findByComboBoxControl/specialDetailCodeOrName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Special Detail --',
      title       : 'specialDetailName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Special Detail Code', field: 'specialDetailCode', width: 150},
        {header: 'Special Detail Name', field: 'specialDetailName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT1 = {
      id          : 'cbPOT1',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 1 --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT1Terminal = {
      id          : 'cbPOT1Terminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 1 Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT2 = {
      id          : 'cbPOT2',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 2 --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT2Terminal = {
      id          : 'cbPOT2Terminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 2 Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT3 = {
      id          : 'cbPOT3',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 3 --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPOT3Terminal = {
      id          : 'cbPOT3Terminal',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Port of Transhipment 3 Terminal --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingCommodity = {
      id          : 'cbCommodity',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCommodities/findGeneral/*/*/{query}/1/5/*/*',
      url         : this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}',

      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Commodity --',
      title       : 'commodityName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Commodity Name', field: 'commodityName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingReceiptType = {
      id          : 'cbReceiptType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Rec Type',
      title       : 'receiptTypeCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };


    this.settingDeliveryType = {
      id          : 'cbDeliveryType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Delv Type',
      title       : 'deliveryTypeCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Receipt Type Code', field: 'deliveryTypeCode', width: 150},
        {header: 'Receipt Type Name', field: 'deliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingFeederVessel = {
      id          : 'cbFeederVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Feeder Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingFeederVoyage = {
      id          : 'cbFeederVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Feeder Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 150},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150}
      ]
    }

    this.settingExVessel = {
      id          : 'cbExVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselNameAndPSAVesselCode={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Ex Vessel --',
      title       : 'psaVesselCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Vessel Abbr. Name', field: 'psaVesselCode', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }
    /*
        this.settingExVoyage = {
            id          : 'cbExVoyage',
            type        : 'search enter', // search | select | select input
            url         : '',
            maxChars    : 0,
            template    : 'grid', // default
            placeholder : '-- Ex Voyage --',
            title       : 'voyage',
            description  : '',
            isMark      : true,
            columns     : [
              {header: 'Voyage', field: 'voyage', width: 100},
              {header: 'Bound', field: 'bound', width: 100},
              {header: 'Service', field: 'serviceCode', width: 100}
            ]
        }
    */
    this.settingNextVessel = {
      id          : 'cbNextVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Next Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingNextVoyage = {
      id          : 'cbNextVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Next Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 150},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingHaulier = {
      id          : 'cbHaulier',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlHaulier/customerName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Haulier --',
      title       : 'customerName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };

    this.settingReturnTo = {
      id          : 'cbReturnTo',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url         :this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneralDepo/'+this.cookieService.getDefaultLocationCode()+'/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Return To --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Country Code', field: 'depoCode', width: 150},
        {header: 'Country Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingReturnToPor = {
      id          : 'cbReturnToPor',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url         :'',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Return To Por --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Country Code', field: 'depoCode', width: 150},
        {header: 'Country Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingReturnToPol = {
      id          : 'cbReturnToPol',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      url         :'',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Return To Pol--',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Country Code', field: 'depoCode', width: 150},
        {header: 'Country Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingReleaseAt = {
      id          : 'cbReleaseAt',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Release At --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Depo Code', field: 'depoCode', width: 150},
        {header: 'Depo Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingPackedAt = {
      id          : 'cbPackedAt',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneralDepo/'+this.cookieService.getDefaultLocationCode()+'/*/*/{query}/1/5/*/*',
      //url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Packed At --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Depo Code', field: 'depoCode', width: 150},
        {header: 'Depo Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingStuffAt = {
      id          : 'cbStuffAt',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneralDepo/'+this.cookieService.getDefaultLocationCode()+'/*/*/{query}/1/5/*/*',
      //url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Stuff At --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Depo Code', field: 'depoCode', width: 150},
        {header: 'Depo Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingPackageType = {
      id          : 'cbPackageType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterPackageTypes/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Package Type --',
      title       : 'packageTypeName',
      description  : '',
      columns     : [
        {header: 'Package Type Code', field: 'packageTypeCode', width: 150},
        {header: 'Package Type Name', field: 'packageTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingSocOPR = {
      id          : 'cbSocOPR',
      type        : 'select', // search | select | select input
      url         : '',
      urlType     : 'dynamic',
      placeholder : '',
      code        : 'opr',
      title       : 'opr',
      allowRemove : false
    }

    this.settingCocOPR = {
      id          : 'cbCocOPR',
      type        : 'select', // search | select | select input
      url         : '',
      urlType     : 'dynamic',
      placeholder : '',
      code        : 'opr',
      title       : 'opr',
      allowRemove : false
    }

    this.settingSlotOPR = {
      id          : 'cbSlotOPR',
      type        : 'select', // search | select | select input
      url         : '',
      urlType     : 'dynamic',
      placeholder : '',
      code        : 'opr',
      title       : 'opr',
      allowRemove : false
    }

    this.settingReqReleaseDate = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingExpReturnDate = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingReeferCollection = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    };

    this.settingGridContainerInfo = {
      id: 'gridContainerInfo',
      url: '',
      page: 20,
      columns: [
        {header: 'Seq', field: 'no', width: 50},
        {header: 'Package Type', field: 'bPackageTypeName', width: 100},
        {header: 'Cont Operator', field: 'bContOperator', width: 100},
        {header: 'Cont Type', field: 'bContType', width: 100},
        {header: 'Gross Weight', field: 'bGrossWeight', width: 100},
        {header: 'Gross Meas', field: 'bGrossMeas', width: 100},
        {header: 'Nett Wt', field: 'bNettWeight', width: 100},
        {header: 'IMO', field: 'bIMO', width: 100},
        {header: 'UNNO', field: 'bUNNO', width: 100},
        {header: 'Flash Point', field: 'bFlashPoint', width: 100},
        {header: 'Receipt Type', field: 'bReceiptType', width: 100},
        {header: 'Container Number', field: 'bContainerNo', width: 100},
        {header: 'Cell Location', field: 'bCellLocation', width: 100},
        {header: 'VGM Enabled', field: 'bVGMEnabledFlag', width: 100},
        {header: 'VGM Type', field: 'bVGMType', width: 100},
        {header: 'VGM Weight', field: 'bVGMWeight', width: 100},
        {header: 'Other Details', field: 'bOtherDetails', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'customerName',
      sortingDirection: 'ASC',
      height:200,
      minHeight:200,
    };

    this.settingBContainerReceiptType = {
      id          : 'cbBContainerReceiptType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'R/Type',
      title       : 'receiptTypeCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'R/Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'R/Type Name', field: 'receiptTypeName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };
    this.settingCbVGMMethod={
      id          : 'settingCbVGMMethod',
      type        : 'select', // search | select | select input
      url         : 'assets/json/booking/vgmMethod.json',
      placeholder : 'SEARCH ...',
      code        : 'vgmMethodCode',
      title       : 'vgmMethodName',
    };

    this.settingDateReturnToGateIn = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    };
    this.settingCommonDate = {
      modeCal : true,
      type : 'dateCustom',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    };


    this.settingDateReleaseAt = { id: 'dateReleaseAt', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };

    this.settingDateReturnToPor = { id: 'dateReturnToPor', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateReturnToPorGateInClosing = { id: 'dateReturnToPorGateInClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateReturnToPorVgmClosing = { id: 'dateReturnToPorVgmClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateReturnToPol = { id: 'dateReturnToPol', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateReturnToPolGateInClosing = { id: 'dateReturnTopolGateInClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateReturnToPolVgmClosing = { id: 'dateReturnTopolVgmClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateStuffAtPor = { id: 'dateStuffAtPor', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateStuffAtPorGateInClosing = { id: 'dateStuffAtporGateInClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };
    this.settingDateStuffAtPorVgmClosing = { id: 'dateStuffAtporVgmClosing', type : 'date', format : 'yyyy-mm-dd', placeholder : '-- Date --', customtext : '' };


    this.settingCbBContainerPackageType = {
      id          : 'cbBContainerPackageType',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'packageTypeName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Pkg. Type Code', field: 'packageTypeCode', width: 175},
        {header: 'Pkg. Type Name', field: 'packageTypeName', width: 300},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    };

    this.settingCbReceiptTypeExtra = {
      id          : 'cbReceiptTypeExtra',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'R/Type',
      title       : 'receiptTypeCode',
      description  : '',
      isMark      : true,
      align       : 'right',
      columns     : [
        {header: 'R/Type Code', field: 'receiptTypeCode', width: 150},
        {header: 'R/Type Name', field: 'receiptTypeName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingPDF = {
      id : "pdfContainer",
      url : "",
      hidePrint : false,
    }

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $('#timeReleaseAt').timeEntry({show24Hours: true});
    $('#timeReturnToPor').timeEntry({show24Hours: true});
    $('#timeReturnToPorClosing').timeEntry({show24Hours: true});
    $('#timeReturnToPorVgm').timeEntry({show24Hours: true});
    $('#timeReturnToPol').timeEntry({show24Hours: true});
    $('#timeReturnToPolClosing').timeEntry({show24Hours: true});
    $('#timeReturnToPolVgm').timeEntry({show24Hours: true});
    $('#timeStuffAtPor').timeEntry({show24Hours: true});
    $('#timeStuffAtPorClosing').timeEntry({show24Hours: true});
    $('#timeStuffAtPorVgm').timeEntry({show24Hours: true});
    /*
    $('#sidebar-booking')
    .sidebar('setting', {
             dimPage          : true,
             scrollLock       : true,
             closable         : false,
             transition       : 'push',
             mobileTransition : 'push'
           });
    $('#sidebar-booking').sidebar('toggle');
    */
    $('.menu .item')
      .tab()
    ;

    this.bookingContainerInfo = new BookingContainerInfo();
    this.bookingMisc = new BookingMisc();

    // hack to open POL when port /default office code <> SGSIN
    if(this.cookieService.getDefaultLocationCode() !== 'SGSIN'){
      this.isCRB = true;
    }


    var loc = this.cookieService.getCookie('defaultLocation').split('|')
    this.defaultLocation = loc[0];
    this.defaultCountry = loc[2];
    // console.log('default country ==> ' + this.defaultCountry);

    //this.dtRequestReleaseDate.initCalendar('');
    //this.dtExpReturnDate.initCalendar('');
    //this.dtReeferCollection.initCalendar('');

    this.init();
    var self = this;
    setTimeout(() => {

      // console.log('prev --> ' + self.prev);
      if(localStorage.getItem("new-booking-from-flag") != null){
        // console.log("** new-booking-from-flag **");
        self.strParams = '';
        self.strParams = localStorage.getItem("new-booking-from-flag");
        if(self.strParams != null){
          self.invisibleToolbarButtons = '';
          //localStorage.removeItem("new-booking-from-flag");
          self.prev = 'booking-flag';
          self.isNew = true;
          self.lock = false;
          self.lockEdit = true;
          self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
          //this.setValidatorSave();

          let par:any = JSON.parse(self.strParams);
          // console.log(par);

          self.model.bOceanVesselBound = par.bOceanVesselBound;

          if(par.bOceanVesselCode != ''){
            // console.log("#1");
            //var self = this;
            // console.log(par.bOceanVesselCode);

            //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
            self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselCode={query}');
            self.cbVessel.setValue(String(par.bOceanVesselCode).trim(),function (callbackVessel) {

              //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');
              self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

              // console.log("** callback after vessel **");
              // console.log(callbackVessel);
              self.model.bOceanVesselId = callbackVessel.vesselId;
              self.model.bOceanVesselCode = callbackVessel.vesselCode;

              if(par.bOceanVesselVoyage != ''){

                //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ par.bOceanVesselId+"/"+ par.bOceanVesselBound+"/{query}/" + self.cookieService.getDefaultLocationCode());
                self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + par.bOceanVesselId + "/" + par.bOceanVesselBound  + "/voyage={query}");
                self.cbVoyage.setValue(par.bOceanVesselVoyage, function (callbackVoyage) {
                  // console.log("** callback after voyage **");
                  // console.log(callbackVoyage);
                  self.model.bOceanVesselVoyage = callbackVoyage.voyage;
                  self.serviceCode = callbackVoyage.serviceCode;

                  self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + par.bOceanVesselId + "/" + par.bOceanVesselBound  + "/voyage_like={query}");

                  self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
                  self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

                  self.getRotation(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
                  self.getAllocationAndEstimateArrivalDate(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
                  //self.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
                  //self.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);

                  self.genericUtil.hideLoader();


                  //$("body").removeClass("pushable");

                });
              }else{
                // console.log("#2");
                self.genericUtil.hideLoader();
                //$("body").removeClass("pushable");
              }

            });

          }else{
            // console.log("#3");
            self.genericUtil.hideLoader();
            //$("body").removeClass("pushable");
          }
        }else{
          // console.log("#4");
          self.genericUtil.hideLoader();
          //$("body").removeClass("pushable");
        }
      }

      if(localStorage.getItem("retrieve-booking-from-flag") != null){
        self.strParams = '';
        self.strParams = localStorage.getItem("retrieve-booking-from-flag");
        if(self.strParams != null){
          let par:any = JSON.parse(self.strParams);
          self.prev = 'booking-flag';
          self.isInit = false;
          self.isNew = false;
          self.lockEdit = true;
          self.invisibleToolbarButtons = '';
          localStorage.removeItem("retrieve-booking-from-flag");
          self.setValidatorSave();
          let selectedList :String[] = String(par.selected).split("-");
          self.model.bPrefix = selectedList[0];
          self.model.bNo = selectedList[1];

          //var self = this;
          self.retrieveEvent(function(callbackRetrieve) {
            // console.log(callbackRetrieve);

            if(callbackRetrieve.bStatus == 'D'){
              self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
            }else if(callbackRetrieve.bContainerOwnership == 'COCTS' && (callbackRetrieve.bOfficeCodeTs != '' && callbackRetrieve.bOfficeCodeTs != undefined ) && (callbackRetrieve.bPrefixTs != '' && callbackRetrieve.bPrefixTs != undefined ) && (callbackRetrieve.bNoTs != '' && callbackRetrieve.bNoTs != undefined)){
              self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
            }else{
              if(callbackRetrieve.bIsUnlocked == 'N'){
                self.appStatusLabel = "-- LOCKED --";
                self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
              }else{
                // console.log("#3")
                self.disableToolbarButtons = 'retrieve,save,copy';
              }
            }

            self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

            self.genericUtil.hideLoader();

            //$("body").removeClass("pushable");
          });

        }
      }


      //handle redirection from vessel listing
      if(localStorage.getItem("new-booking-from-vessel-listing") != null){

        self.invisibleToolbarButtons = '';
        self.strParams = '';
        self.strParams = localStorage.getItem("new-booking-from-vessel-listing");
        if(self.strParams != null){
          self.prev = 'vessel-listing';
          self.lock = false;

          let par:any = JSON.parse(self.strParams);


          self.setValidatorNew();
          self.model.bPrefix = 'SUDU';
          self.model.bNo = '';
          self.model.bOceanVesselBound = 'O';

          if(par.bOceanVesselId != ''){
            // console.log("#1");
            //var self = this;
            // console.log(par.bOceanVesselCode);

            self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
            self.cbVessel.setValue(String(par.vesselId).trim(),function (callbackVessel) {

              //restore url vessel
              //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');
              self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

              // console.log("** callback after vessel **");
              // console.log(callbackVessel);
              self.model.bOceanVesselId = callbackVessel.vesselId;
              self.model.bOceanVesselCode = callbackVessel.vesselCode;

              if(par.voyage != ''){

                //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ par.vesselId+"/"+ par.bound+"/{query}/" + self.cookieService.getDefaultLocationCode());
                self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + par.vesselId + "/" + par.bound  + "/voyage={query}");
                self.cbVoyage.setValue(par.voyage, function (callbackVoyage) {
                  // console.log("** callback after voyage **");
                  // console.log(callbackVoyage);
                  self.model.bOceanVesselVoyage = callbackVoyage.voyage;
                  self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + self.cookieService.getDefaultLocationCode() + "/" + par.vesselId + "/" + par.bound  + "/voyage_like={query}");

                  self.serviceCode = callbackVoyage.serviceCode;

                  self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
                  self.cbDischargePort.setValue(par.portofDischarge);

                  self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
                  self.cbFinalDest.setValue(par.portofDischarge, function (callbackFDest) {
                    self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
                  });
                  self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

                  self.getRotation(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
                  self.getAllocationAndEstimateArrivalDate(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);

                  self.genericUtil.hideLoader();

                });
              }else{
                // console.log("#2");
                self.genericUtil.hideLoader();
              }
            });

          }else{
            // console.log("#3");
            self.genericUtil.hideLoader();
            //$("body").removeClass("pushable");
          }

        }

        // console.log("param from vessel listing");
        // console.log(self.strParams);

      }
    }, 100);


    this.cdr.detectChanges();
  }

  onClear(){
    this.model = new Booking();
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbBookingParty.setValue('');
    this.cbShipper.setValue('');
    this.cbFreightParty.setValue('');
    this.cbDischargePort.setValue('');
    this.cbPODL.setValue('');
    this.cbFinalDest.setValue('');
    this.cbSpecialDet.setValue('');
    this.cbPOT1.setValue('');
    this.cbPOT2.setValue('');
    this.cbPOT3.setValue('');
    this.cbCommodity.setValue('');
    this.cbFeederVessel.setValue('');
    this.cbFeederVoyage.setValue('');
    this.cbSupplier.setValue('');
    this.cbExVessel.setValue('');
    //  this.cbExVoyage.setValue('');
    this.cbNextVessel.setValue('');
    this.cbNextVoyage.setValue('');
    //this.cbHaulier.setValue('');
    //this.cbReturnTo.setValue('');
    //this.cbReleaseAt.setValue('');
    //this.cbPackedAt.setValue('');
    this.cbPackageType.setValue('');
    //this.dtExpReturnDate.resetCalendar();
    //this.dtReeferCollection.resetCalendar();
    //this.dtRequestReleaseDate.resetCalendar();

    this.cbSocOPR.clearSelect();
    this.cbSocOPR.listStore.store = [];

    this.cbSlotOPR.clearSelect();
    this.cbSlotOPR.listStore.store = [];

    this.isSocBookingParty = false;
    this.isCocBookingParty = false;
    this.isSlotBookingParty = false;

    this.nvoccBookingParty = false;
    this.nvoccFreightParty = false;
    this.nvoccShipper = false;

    this.serviceCode = '';
    this.rotation = '';
    this.OH = 'N';
    this.OW = 'N';
    this.OL = 'N';
    this.heightMeasure = 0;
    this.leftMeasure = 0;
    this.rightMeasure = 0;
    this.frontMeasure = 0;
    this.endMeasure = 0;
    this.UCH = 0;
    this.UCL = 0;
    this.UCW = 0;
    this.d20 = 0;
    this.d40 = 0;
    this.d45 = 0;
    this.h20 = 0;
    this.h40 = 0;
    this.h45 = 0;
    this.killSlot = 0;
    this.doorOpen = 'N';
    this.bDegree = null;
    this.bDegreeTemp ='C';

    /*
    this.gridNew.setBody(this.model);
    this.gridNew.onClear();
    */

    this.grid.setBody(this.model);
    this.grid.onClear();

    this.gridFreightCharges.onClear();
    this.gridFreightPerSummary.onClear();
    this.gridFreightCurrencySummary.onClear();
    this.refreightEffectiveFrom = '';
    this.refreightExpiryOn = '';
    this.refreightModifiedBy = '';
    this.refreightModifiedDate = '';
    this.refreightAccountPIC = '';
    this.refreightApprovalBy = '';
    this.refreightApprovedDate = '';

    this.gridContainerInfo.onClear();

    this.bookingContainerInfo = new BookingContainerInfo();
    this.bookingMisc = new BookingMisc();

    this.cbHaulier.setValue('');
    this.cbReceiptTypeExtra.setValue('');
    this.cbReleaseAt.setValue('');
    this.dateReleaseAt.setValue('');
    this.cbReturnToPor.setValue('');
    this.dateReturnToPor.setValue('');
    this.dateReturnToPorGateInClosing.setValue('');
    this.dateReturnToPorVgmClosing.setValue('');
    this.dateReturnToPol.setValue('');
    this.dateReturnToPolGateInClosing.setValue('');
    this.dateReturnToPolVgmClosing.setValue('');
    this.dateStuffAtPor.setValue('');
    this.dateStuffAtPorGateInClosing.setValue('');
    this.dateStuffAtPorVgmClosing.setValue('');


    /*
    this.grid2.setBody(this.model);
    this.grid2.onClear();

    this.grid3.setBody(this.model);
    this.grid3.onClear();

    this.grid4.setBody(this.model);
    this.grid4.onClear();

    this.grid5.setBody(this.model);
    this.grid5.onClear();
    */

    /*
    this.gridNew.setBody(this.model);
    this.gridNew.loadData();

    this.grid.setBody(this.model);
    this.grid.loadData();

    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */

  }

  init(){
    var self = this;
    this.onClear();
    this.setValidatorInit();
    this.lock = true;
    this.lockTNK = false;
    this.lockEdit = false;
    this.isError = false;
    this.isInit = true;

    /*
    if(this.strParams == ''){
      this.invisibleToolbarButtons = 'close';
    }else{
      this.invisibleToolbarButtons = '';
    }
    */
    this.model.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.bOceanVesselBound = 'O';
    this.model.bPrefix = 'SUDU';
    this.disableToolbarButtons = 'modify,save,copy,cancel,print,delete';

   // this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');
    //this.cbPackedAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');
    //this.cbReturnTo.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneral/'+this.model.bOfficeCode+'/*/*/{query}/1/5/*/*');

    if(this.cookieService.getDefaultLocationCode() != null){

      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}')
      this.cbLoadPort.setValue(this.cookieService.getDefaultLocationCode(), function (callbackPOL) {
        self.model.bPolCode = self.cookieService.getDefaultLocationCode();
        // console.log(callbackPOL.locationBookingRef);
        self.model.bPolBooking = callbackPOL.locationBookingRef;
        //self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
        self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

      });

      this.cbPOR.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}')
      this.cbPOR.setValue(this.cookieService.getDefaultLocationCode(), function (callbackPOR) {
        self.model.bPorCode = self.cookieService.getDefaultLocationCode();
        self.cbPOR.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}')

      });
    }

    this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
    this.cbReceiptType.setValue('CY');
    this.model.bReceiptType = 'CY';

    this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
    this.cbDeliveryType.setValue('CY');
    this.model.bDeliveryType = 'CY';

    this.currentContainerType = "STD";
    this.model.bContainerOwnership = 'SOC';
    this.model.bSocOperatorCode = 'SA';
    this.model.bContainerStatus = "FULL";

    this.lockSOC = false;
    this.lockCOC = false;
    this.lockSLOT = false;

    this.lockSOCOPR = false;
    this.lockCOCOPR = true;
    this.lockSLOTOPR = true;

    this.model.bIsFinished = 'N';
    this.model.bIsTransmittedToPsa = 'N';
    this.model.bTransmitToSds = 'N';
    this.model.bReleaseOrderPrinted = 'N';
    this.model.bFreightType = 'P';
    this.model.bShipmentType = 'N';
    this.model.bDgClass = 'N';
    this.model.bIhc = 'N';
    this.model.bWeightClass = 'M';
    this.model.bIsTransmittedToPsa = 'N';
    this.model.bStatus = 'N';
    this.model.bIsFinished = 'N';
    this.model.bReleaseOrderPrinted = 'N';
    this.model.bDetention = 'N';
    this.model.bDemurrage = 'N';
    this.model.bIsDry = 'N';
    this.model.bVent = 'N';
    this.model.bTsBooking = 'N';
    this.model.bIsReserve = 'N';
    this.model.bSocOperatorCode = 'SA';
    this.model.bCocOperatorCode = '';
    this.model.bOutwardSlotOperatorCode = '';

    this.cbCocOPR.clearSelect();
    this.cbCocOPR.listStore.store = [];
    this.cbCocOPR.url = '';


    this.bookingDetails.clear();
    let list = new Map<String,BookingDetail>();
    this.bookingDetails.set(this.currentContainerType,list);

    window.onbeforeunload = function() {
      return "Dude, are you sure you want to leave? Think of the kittens!";
    }

  }

  initNew(){
    // console.log("** init New **");
    let data:any = this.model;
    this.onClear();

    var self = this;
    // console.log("vessel : " + data.bOceanVesselCode);
    //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
    this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselCode={query}');
    this.cbVessel.setValue(String(data.bOceanVesselCode).trim(),function (callbackVessel) {
      // console.log("** callback after vessel **");
      // console.log(callbackVessel);

      //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');
      self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

      self.model.bOceanVesselId = callbackVessel.vesselId;
      self.model.bOceanVesselCode = callbackVessel.vesselCode;
      self.model.bOceanVesselName =  callbackVessel.vesselName;
      self.model.bOceanVesselBound = data.bOceanVesselBound;
      self.model.bOfficeCode = data.bOfficeCode;
      self.model.bPrefix = data.bPrefix;

      //reset pol
      self.model.bPolCode = '';

      //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bOceanVesselId+"/"+ data.bOceanVesselBound+"/{query}/" + data.bOfficeCode);
      self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + data.bOfficeCode + "/" + data.bOceanVesselId + "/" + data.bOceanVesselBound  + "/voyage={query}");
      self.cbVoyage.setValue(data.bOceanVesselVoyage, function (callbackVoyage) {
        // console.log("** callback after voyage **");
        // console.log(callbackVoyage);
        self.model.bOceanVesselVoyage = callbackVoyage.voyage;
        self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + data.bOfficeCode + "/" + data.bOceanVesselId + "/" + data.bOceanVesselBound  + "/voyage_like={query}");

        self.serviceCode = callbackVoyage.serviceCode;

        self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
        self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

        console.log("--- debug before get rotation ----");
        self.getRotation(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
        self.getAllocationAndEstimateArrivalDate(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
        self.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
        self.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);

      });
    });

    this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
    this.cbReceiptType.setValue('CY');
    this.model.bReceiptType = 'CY';

    this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
    this.cbDeliveryType.setValue('CY');
    this.model.bDeliveryType = 'CY';

    this.model.bContainerOwnership = 'SOC';
    this.model.bSocOperatorCode = 'SA';
    this.model.bContainerStatus = "FULL";

    this.disableToolbarButtons = 'retrieve,new,modify,print,delete';


    if(this.cookieService.getDefaultLocationCode() != null){
      this.cbPOR.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
      this.cbPOR.setValue(this.cookieService.getDefaultLocationCode(), function (callbackPOR) {
        self.model.bPorCode = self.cookieService.getDefaultLocationCode();
        self.cbPOR.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      });
    }

    this.lock = false;
    this.lockTNK = false;
    this.lockSOC = false;
    this.lockCOC = false;
    this.lockSLOT = false;

    this.lockSOCOPR = false;
    this.lockCOCOPR = true;
    this.lockSLOTOPR = true;

    this.model.bIsFinished = 'N';
    this.model.bIsTransmittedToPsa = 'N';
    this.model.bTransmitToSds = 'N';
    this.model.bReleaseOrderPrinted = 'N';
    this.model.bFreightType = 'P';
    this.model.bShipmentType = 'N';
    this.model.bDgClass = 'N';
    this.model.bIhc = 'N';
    this.model.bWeightClass = 'M';
    this.model.bIsTransmittedToPsa = 'N';
    this.model.bStatus = 'N';
    this.model.bIsFinished = 'N';
    this.model.bReleaseOrderPrinted = 'N';
    this.model.bDetention = 'N';
    this.model.bDemurrage = 'N';
    this.model.bIsDry = 'N';
    this.model.bVent = 'N';
    this.model.bTsBooking = 'N';
    this.model.bIsReserve = 'N';
    this.model.bSocOperatorCode = 'SA';
    this.model.bCocOperatorCode = '';
    this.model.bOutwardSlotOperatorCode = '';

    //updated as per miki email per 20-march-2018
    this.currentContainerType="STD";


    //update as per 10-april-2018
    this.cbCocOPR.clearSelect();
    this.cbCocOPR.listStore.store = [];
    this.cbCocOPR.url = '';
  }

  initTab1() {
    //this.gridNew.loadData();
    this.grid.loadData();
    this.tabStatus = "tab1";
  }

  initTab2() {
    this.grid2.listStore.store = this.grid.listStore.store;
    this.grid2.loadData();
    this.tabStatus = "tab2";

  }

  initTab3() {
    this.grid3.listStore.store = this.grid.listStore.store;
    this.grid3.loadData();
    if(this.lock == false) {
      this.refreightProcess();
    }
    this.tabStatus = "tab3";
  }

  initTab4() {
    // this.grid4.listStore.store = this.grid.listStore.store;
    // this.grid4.loadData();
    this.tabStatus = "tab4";
  }

  initTab5() {
    this.grid5.listStore.store = this.grid.listStore.store;
    this.grid5.loadData();
    this.tabStatus = "tab5";
  }

  setContainerValue(list:Map<String,BookingDetail>): Map<String,BookingDetail>{
    if(this.d20 > 0){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '20';
      det.bContainerHeight = '86';
      det.bQuantity = this.d20;

      list.set("2086",det);
    }
    if(this.d40 > 0){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '40';
      det.bContainerHeight = '86';
      det.bQuantity = this.d40;

      list.set("4086",det);
    }
    if(this.d45 > 0){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '45';
      det.bContainerHeight = '86';
      det.bQuantity = this.d45;

      list.set("4586",det);
    }
    if(this.h20 > 0){
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '20';
      det.bContainerHeight = '96';
      det.bQuantity = this.h20;

      list.set("2096",det);
      //}else{
      //  this.h20 = 0;
      //}
    }
    if(this.h40 > 0){
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '40';
      det.bContainerHeight = '96';
      det.bQuantity = this.h40;

      list.set("4096",det);
      //}else{
      //  this.h40 = 0;
      //}
    }
    if(this.h45 > 0){
      //if(this.currentContainerType != 'TNK'){
      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix;
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '45';
      det.bContainerHeight = '96';
      det.bQuantity = this.h45;

      list.set('4596', det);
      //}else{
      //  this.h45 = 0;
      //}
    }
    return list;
  }

  setValidatorInit() {
    this.validatorRules = {
      allocationValidator: {
        rules: [{
          type  : 'custom',
          prompt: 'Allocation has not been set for this vessel, unable to create booking.'
        }]
      }
    };
  }

  setValidatorNew() {
    this.clearValidatorError();
    this.validatorRules = {
      bOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      bOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      bOceanVesselVoyage: {
        rules:[{
          type  : 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      allocationValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Allocation has not been set for this vessel, unable to create booking.'
        }]
      },
    }
  }

  setValidatorRetrieve(){
    this.clearValidatorError();
    this.validatorRules = {
      bPrefix: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Prefix.'
        }]
      },
      bNo: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Booking No.'
        }]
      }
    }
  }

  setValidatorSave(){
    this.clearValidatorError();
    this.validatorRules = {
      bOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      bOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      bOceanVesselVoyage: {
        rules:[{
          type  : 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      bBookingPartyCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Booking Party.'
        }]
      },
      bShipperCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Shipper.'
        }]
      },
      bFreightPartyCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Freight Party.'
        }]
      },
      bPorCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Place of Receipt.'
        }]
      },
      bPolCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Load Port.'
        }]
      },
      bPodCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Discharge Port.'
        }]
      },
      bPodlCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Place of Delivery.'
        }]
      },
      bReceiptType: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Receipt Type.'
        }]
      },
      bDeliveryType: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Delivery Type.'
        }]
      },
      rotationValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Please input rotation.'
        }]
      },
      unitContainerValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Please input Unit Container.'
        }]
      },
      allocationValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Allocation has not been set for this vessel, unable to create booking.'
        }]
      },
      operatorCodeValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Please input Operator Code.'
        }]
      },
      coctsExceedValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Allocation for COC T/S is exceeded.'
        }]
      },
      cocLocalExceedValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'Allocation for COC LOCAL is exceeded.'
        }]
      },
      /*
      podAllocationValidator: {
        rules:[{
            type  : 'custom',
            prompt: 'Allocation of Discharge Port is not yet created. Unable to create booking.'
        }]
      },
      */
      bContactPerson: {
        rules: [{
          type: 'empty',
          prompt: 'Please input CTC PAX.'
        }]
      },
      feederValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Please input Feeder Voyage.'
        }]
      },
      ExVoyageValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Please input Ex Voyage.'
        }]
      },
      NextVoyageValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Please input Next Voyage.'
        }]
      },
      ReeferDegreeValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Please fill in reefer temperature.'
        }]
      },
      POLPODValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Port of Discharge should not be identical with Port of Loading.'
        }]
      },
      POLPOTValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Port of Transshipment 1 should not be identical with Port of Loading.'
        }]
      },
      PODPOTValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Port of Discharge should not be identical with Port of Transshipment 1.'
        }]
      },
      bookingPartyValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Booking Party is not compatible with Container Ownership.'
        }]
      },
      ucValidator: {
        rules: [ {
          type  : 'custom',
          prompt: 'Please input UC.'
        }]
      },

    }
  }

  clearValidatorError(){
    this.model['error-bOceanVesselBound'] = '';
    this.model['error-bOceanVesselCode'] = '';
    this.model['error-bOceanVesselVoyage'] = '';
    this.model['error-allocationValidator'] = '';
    this.model['error-bNo'] = '';
    this.model['error-bPrefix'] = '';
    this.model['error-bBookingPartyCode'] = '';
    this.model['error-bPorCode'] = '';
    this.model['error-bPolCode'] = '';
    this.model['error-bPodlCode'] = '';
    this.model['error-bReceiptType'] = '';
    this.model['error-bDeliveryType'] = '';
    this.model['error-unitContainerValidator'] = '';
    this.model['error-operatorCodeValidator'] = '';
    this.model['error-coctsExceedValidator'] = '';
    this.model['error-cocLocalExceedValidator'] = '';
    // this.model['error-podAllocationValidator'] = '';
    this.model['error-rotationValidator'] = '';
    this.model['error-feederValidator'] = '';
    this.model['error-ExVoyageValidator'] = '';
    this.model['error-NextVoyageValidator'] = '';
    this.model['error-ReeferDegreeValidator'] = '';
    this.model['error-bContactPerson'] = '';
    this.model['error-POLPODValidator'] = '';
    this.model['error-POLPOTValidator'] = '';
    this.model['error-PODPOTValidator'] = '';
    this.model['error-bookingPartyValidator'] = '';
    this.model['error-ucValidator'] = '';
  }

  openBookingAllotment(){
    // console.log("** open booking allotment **");
    $('#sidebar-booking').addClass("visible");
    //$('#sidebar-booking').sidebar('toggle');
    $('#openBookingAllotment').css("display","none");
    $('#loader').css({'z-index':'102','opacity':'0.5'}).removeClass("inverted").addClass("active");
    $('#loader').find('.spinner').css('display','none');
    this.getWeightProfiles(this.model.bOfficeCode,this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound);
    this.getContainerProfiles(this.model.bOfficeCode,this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound);

  }

  closeBookingAllotment(){
    // console.log('** close booking allotment **');
    $('#sidebar-booking').removeClass('visible');
    //$('#sidebar-booking').sidebar('toggle');
    $('#loader').find('.spinner').css('display','block');
    $('#loader').css({'z-index':'60','opacity':'1'}).removeClass('active').addClass('inverted');
    $('#openBookingAllotment').css('display','block');
  }

  //message(txtIcon: String, txtHeader: String, txtContent: String, btns: String, eve:any){
  // this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
  //  this.dialog1.show(txtIcon,txtHeader,txtContent,btns,eve);
  // }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }



  changeEventTS(event){
    if(event.target.checked){
      this.model.bTsBooking = 'Y';
    }else{
      this.model.bTsBooking = 'N';
    }
  }

  changeEventReserve(event){
    if(event.target.checked){
      this.model.bIsReserve = 'Y';
      this.model.bPrefix = 'RESV';
    }else{
      this.model.bIsReserve = 'N';
      this.model.bPrefix = 'SUDU';
    }
  }

  changeEventDry(event){
    if(event.target.checked){
      this.model.bIsDry = 'Y';
    }else{
      this.model.bIsDry = 'N';
    }
  }

  changeEventVessel(event) {
    // console.log('Change Event Vessel');
    // console.log(event);
    if(event.vesselId == null){
      this.model.bOceanVesselId = '';
      this.model.bOceanVesselCode = '';
    }else{
      this.model.bOceanVesselId = event.vesselId;
      this.model.bOceanVesselCode = event.vesselCode;

      this.model.bOceanVesselBound = 'O';
      this.cbVoyage.disableCombo = false;

      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+event.vesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyage/' + this.cookieService.getDefaultLocationCode() + '/' + event.vesselId + '/' + this.model.bOceanVesselBound  + '/voyage_like={query}');
      this.cbVoyage.setValue('');

      this.model.ETA = '';
      this.model.ETD = '';
      this.model.ATA = '';
      this.model.ATD = '';

      this.serviceCode = '';


    }
  }
  changeEventVoyage(event) {
    // console.log(event);
    if(event.voyage == null){
      this.model.bOceanVesselVoyage = '';
    }else{
      this.model.bOceanVesselVoyage = event.voyage;
      this.serviceCode = event.serviceCode;
      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');
      this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');

      console.log('fire before get rotattion vyage ')
      console.log(this.model.bNextMotherVesselVoyage);
      //another hack
      if(this.model.bNextMotherVesselVoyage != ''){
        this.bufferNextVoyage = this.model.bNextMotherVesselVoyage;
      }

      //this.getRotation(this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound,this.cookieService.getDefaultLocationCode());
      console.log('fired adter voyage');
      console.log(this.model.bNextMotherVesselVoyage);
      // asss
      this.getAllocationAndEstimateArrivalDate(this.model.bOceanVesselId,this.model.bOceanVesselVoyage,this.model.bOceanVesselBound,this.cookieService.getDefaultLocationCode());

    }
  }

  gridEvent(event) {
    //// console.log(event);
    switch (event.split('.')[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:String = event;
        if(strEvent != ''){
          let arr:String[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
              break;
          }

        }

        break;
    }

  }

  gridAllocationEvent(event) {
    // console.log(event);
    switch (event.split('.')[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      case 'afterLoad' :
        // console.log('*** this is after load event **');

        if(this.grid.listStore.store.length > 0){
          // console.log('defaultBooked : ' + this.grid.listStore.store[0]['booked']);
          this.defaultBooked = this.grid.listStore.store[0]['booked'];

          this.defaultBooked2 = '';
          this.grid.listStore.store.forEach(element => {
            // console.log(element);
            if(element.allotmentCode != 'VS'){
              this.defaultBooked2 = this.defaultBooked2 + element.booked + ';'
            }
          });

          // console.log('default booked 2 : ' + this.defaultBooked2);


          this.grid2.listStore.store = this.grid.listStore.store;
          this.grid2.loadData();

          this.grid3.listStore.store = this.grid.listStore.store;
          this.grid3.loadData();

          //this.grid4.listStore.store = this.grid.listStore.store;
          //this.grid4.loadData();

          this.grid5.listStore.store = this.grid.listStore.store;
          this.grid5.loadData();


        }



        var self = this;
        if(localStorage.getItem('new-booking-from-vessel-listing') != null){

          setTimeout(() => {

            self.model['allocationValidator'] = false;
            self.model['error-allocationValidator'] = '';
            // console.log('self.grid.listStore.store.length = ' + self.grid.listStore.store.length);
            if(self.grid.listStore.store.length == 0){
              self.lock = true;
              self.lockEdit = false;
              self.model['error-allocationValidator']='Allocation has not been set for this vessel, unable to create booking.';
              self.model['allocationValidator'] = true;
              self.isError = self.onValidate(self.model);
              // console.log('self.isError = ' + self.isError);
              if(!self.isError){
                self.isNew = true;
                self.lock = false;
                self.lockEdit = true;
                self.setValidatorSave();
                self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
              }
            }else{
              self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
            }

            localStorage.removeItem('new-booking-from-vessel-listing');
            self.genericUtil.hideLoader();

          }, 5000);

        }


        //fired when booking was created through button new-booking
        /*
        if(localStorage.getItem('new-booking-from-flag') != null ){
          self.model['allocationValidator'] = false;
          self.model['error-allocationValidator'] = '';
          // console.log('self.grid.listStore.store.length = ' + self.grid.listStore.store.length);
          if(self.grid.listStore.store.length == 0){
            self.lock = true;
            self.lockEdit = false;
            self.model['error-allocationValidator']='Allocation has not been set for this vessel, unable to create booking.';
            self.model['allocationValidator'] = true;
            self.isError = self.onValidate(self.model);
            // console.log('self.isError = ' + self.isError);
            if(!self.isError){
              self.isNew = true;
              self.lock = false;
              self.lockEdit = true;
              self.setValidatorSave();
              self.disableToolbarButtons = 'retrieve,new,modify,print,delete';
            }
          }else{
            //this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
          }

          localStorage.removeItem('new-booking-from-vessel-listing');
          self.genericUtil.hideLoader();
        }
        */
        break;

      default:
        let strEvent:String = event;
        if(strEvent != ''){
          let arr:String[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
              break;
          }

        }

        break;
    }

  }

  infoGrid(event) {
    this.info = event;
  }

  onRetrieve(){
    this.setValidatorRetrieve();
    // console.log(this.model);
    this.handleRetrieve();
  }

  retrieve(event){
    // console.log(event);
    if(event.keyCode == 13){
      this.onRetrieve();
    }
  }

  toolbarEvent(event: any) {
    // console.log('event : ' + event);
    switch (event) {
      case 'retrieve':
        this.onRetrieve();
        break;
      case 'new':
        // console.log('isInit = ' + this.isInit);

        if(this.isInit){
          this.setValidatorNew();
          this.handleNew();
        }else{

          this.isNew = true;
          this.isInit = false;
          this.initNew();
        }

        //this.initNew();
        break;
      case 'modify':
        this.handleModify();
        break;
      case 'delete' :
        this.handleDelete();
        break;
      case 'save':

        if(this.model.bExMotherVesselVoyage != null && this.model.bExMotherVesselVoyage != '' && this.model.bExMotherVesselVoyage != undefined){
          let hitUrl = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/checkDuplicateBoundMFromBooking/' + this.model.bOfficeCode + '/' + this.model.bExMotherVesselId + '/' + this.model.bExMotherVesselVoyage.toUpperCase() + '/M' ;
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if(resp.json()['status'].toUpperCase() == 'OK'){
              //create bound M
              let msg: string = '';
              msg += 'Schedule for vessel <b>' + this.model.bExMotherVesselName + ' ' + this.model.bExMotherVesselVoyage.toUpperCase() + '</b> is not yet registered. <br />';
              msg += 'System will now register this schedule?';

              this.dialogPlugin.show('information','Information',msg, 'yesno', { yes: 'this.registerBoundM();', no:''});
            }else{
              this.handleSave();
            }
          });
        }else{
          this.handleSave();
        }




        break;
      case 'copy':
        this.isCopy = true;
        if(this.model.bExMotherVesselVoyage != null && this.model.bExMotherVesselVoyage != '' && this.model.bExMotherVesselVoyage != undefined){
          let hitUrl = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/checkDuplicateBoundMFromBooking/' + this.model.bOfficeCode + '/' + this.model.bExMotherVesselId + '/' + this.model.bExMotherVesselVoyage.toUpperCase() + '/M' ;
          this.genericService.GET(hitUrl).subscribe((resp) => {
            if(resp.json()['status'].toUpperCase() == 'OK'){
              //create bound M
              let msg: string = '';
              msg += 'Schedule for vessel <b>' + this.model.bExMotherVesselName + ' ' + this.model.bExMotherVesselVoyage.toUpperCase() + '</b> is not yet registered. <br />';
              msg += 'System will now register this schedule?';

              this.dialogPlugin.show('information','Information',msg, 'yesno', { yes: 'this.registerBoundM();', no:''});
            }else{
              this.handleSave();
            }
          });
        }else{
          this.handleSave();
        }
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        /*
        if(this.strParams != ''){
          localStorage.setItem('close-booking-to-flag',this.strParams);
          this.router.navigateByUrl('/main/transaction-booking-flag');
        }else{
          this.router.navigateByUrl('/main/home');
        }
        */

        this.strParams = '';
        localStorage.removeItem('new-booking-from-flag');
        localStorage.removeItem('vessel-listing-last-searched');

        localStorage.removeItem('vessel-listing-last-searched-url');
        localStorage.removeItem('vessel-listing-last-searched-search');
        localStorage.removeItem('vessel-listing-last-searched-valPortLoadCode');
        localStorage.removeItem('vessel-listing-last-searched-valTerminalLoadCode');
        localStorage.removeItem('vessel-listing-last-searched-valPortDischargeCode');
        localStorage.removeItem('vessel-listing-last-searched-valTerminalDischargeCode');
        localStorage.removeItem('vessel-listing-last-searched-valBound');
        localStorage.removeItem('vessel-listing-last-searched-valPeriodFrom');
        localStorage.removeItem('vessel-listing-last-searched-valPeriodTo');
        localStorage.removeItem('vessel-listing-last-searched-valOfficeCode');

        this.router.navigateByUrl('/main/home');
        break;
      case 'print':
        //$('#loader').css({'z-index':'50','margin-left':'19px','width':'calc(100% - 40px)'}).dimmer('show');
        if(this.model.bNo == "" && this.model.bPrefix == ""){
          this.dialogPlugin.show('information','Information','please insert Bno ', 'okonly', { ok: ''});
        } else {
          this.loading = true;
          this.printBookingConfirmation();
        }
        break;
      case 'back':
        this.handleBack();
        break;
      default:
        let str:String = event;
        // console.log('str = ' + str);
        break;
    }
  }

  changeEventBookingParty(event) {
    // console.log(event);
    if (event.customerId != null && event.customerId != '' && event.customerId != undefined) {
      this.model.bBookingPartyCode = event.customerId;
      this.model.bBookingPartyName = event.customerName;
      var self = this;

      this.cbSocOPR.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findSocOperator/' + this.model.bBookingPartyCode);
      this.cbSocOPR.loadSelect();

      this.cbCocOPR.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findCocOperator/' + this.model.bBookingPartyCode);
      //this.cbCocOPR.loadSelect();
      //var self= this;
      this.cbCocOPR.loadSelect(function(clcocoper){
        if((self.model.bContainerOwnership == 'COC' || self.model.bContainerOwnership == 'COC') && self.cbCocOPR.listStore.store.length > 0){
          self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]['code'].toString();
          self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]['code']);
        }
      });
      this.model['error-operatorCodeValidator'] = '';

      //this.cbCocOPR.setValue(this.cbCocOPR.listStore.store[0]['code'].toString());

      this.cbSlotOPR.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findSlotOperator/' + this.model.bBookingPartyCode);
      this.cbSlotOPR.loadSelect();


      if(this.model.bShipperCode == '' && event.isShipper == 'Y' ){
        this.cbShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerId={query}');
        this.cbShipper.setValue(event.customerId,function(callbackShippers){
          self.model.bShipperCode = callbackShippers.customerId;
          if(callbackShippers.isNvocc == 'Y'){
            self.nvoccShipper = true;
          }else{
            self.nvoccShipper = false;
          }
          self.cbShipper.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
        });
      }

      if(this.model.bFreightPartyCode == '' && event.isPayer == 'Y' ){
        this.cbFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
        this.cbFreightParty.setValue(event.customerId, function(callbackFreightParty){
          self.model.bFreightPartyCode = callbackFreightParty.customerId;
          if(callbackFreightParty.isNvocc == 'Y'){
            self.nvoccFreightParty = true;
          }else{
            self.nvoccFreightParty = false;
          }
          self.cbFreightParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
        });
      }

      if(event.isNvocc == 'Y'){
        this.nvoccBookingParty = true;
      }else{
        this.nvoccBookingParty = false;
      }

      if(event.isSoc == 'Y'){
        this.isSocBookingParty = true;
      }else{
        this.isSocBookingParty = false;
      }

      if(event.isCoc == 'Y'){
        this.isCocBookingParty = true;
      }else{
        this.isCocBookingParty = false;
      }

      if(event.isSlot == 'Y'){
        this.isSlotBookingParty = true;
      }else{
        this.isSlotBookingParty = false;
      }


    } else {
      this.model.bBookingPartyCode = '';
      this.model.bBookingPartyName = '';
      this.nvoccBookingParty = false;
      this.isSocBookingParty = false;
      this.isCocBookingParty = false;
      this.isSlotBookingParty = false;

      this.cbSocOPR.clearSelect();
      this.cbSocOPR.listStore.store = [];

      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];
      this.cbCocOPR.url = '';

      this.cbSlotOPR.clearSelect();
      this.cbSlotOPR.listStore.store = [];


    }

    this.cbBookingParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}');
  }

  changeEventShipper(event) {
    // console.log('** change event shipper **');
    if (event.customerId != null) {
      var self=this;
      this.model.bShipperCode = event.customerId;
      this.model.bShipperName = event.customerName;

      if(this.model.bFreightPartyCode == '' && event.isPayer == 'Y' ){
        this.cbFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
        this.cbFreightParty.setValue(event.customerId, function(callbackFreightParty){
          self.model.bFreightPartyCode = callbackFreightParty.customerId;
          if(callbackFreightParty.isNvocc == 'Y'){
            self.nvoccFreightParty = true;
          }else{
            self.nvoccFreightParty = false;
          }
          self.cbFreightParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
        });
      }

      if(event.isNvocc == 'Y'){
        this.nvoccShipper = true;
      }else{
        this.nvoccShipper = false;
      }
    } else {
      this.model.bShipperCode = '';
      this.model.bShipperName = '';
      this.nvoccShipper = false;
    }
    this.cbShipper.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
  }

  changeEventFreightParty(event) {
    // console.log(event);
    if (event.customerId != null) {
      this.model.bFreightPartyCode = event.customerId;
      if(event.isNvocc == 'Y'){
        this.nvoccFreightParty = true;
      }
    } else {
      this.model.bFreightPartyCode = '';
      this.nvoccFreightParty = false;
    }
    this.cbFreightParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
  }

  changeEventPOR(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPorCode = event.locationCode;

      if(event.hasTerminal == 'Y'){
        this.lockPORTerminal = false;
      }else{
        this.lockPORTerminal = true;
      }
      this.cbPORTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbPORTerminal.setValue('');

      this.populateReceiptTypeExtra();

    } else {
      this.model.bPorCode = '';

      this.cbPORTerminal.setUrl('');
      this.cbPORTerminal.setValue('');
    }
  }


  changeEventPORTerminal(event) {
    if (event.locationCode != null) {
      this.model.bPorTerminalCode = event.locationCode;
    } else {
      this.model.bPorTerminalCode = '';
    }
  }

  changeEventLoadPort(event) {
    console.log(event);
    if (event.locationCode != null) {
      this.model.bPolCode = event.locationCode;
      console.log('bebfore assignnment > ' + this.model.bPolBooking);
      this.model.bPolBooking = event.locationBookingRef;
      console.log('after assignnment > ' + this.model.bPolBooking);

      if (event.hasTerminal === 'Y') {
        this.lockPOLTerminal = false;
      } else {
        this.lockPOLTerminal = true;
      }

      this.cbLoadPortTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbLoadPortTerminal.setValue('');
      this.populateReceiptTypeExtra();
    } else {
      console.log('bebfore assignnment blank > ' + this.model.bPolBooking);
      this.model.bPolCode = '';
      this.model.bPolBooking = '';
      console.log('bebfore assignnment not blank> ' + this.model.bPolBooking);

      this.cbLoadPortTerminal.setUrl('');
      this.cbLoadPortTerminal.setValue('');
    }
  }

  changeEventLoadPortTerminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPolTerminalCode = event.locationCode;
    }else{
      this.model.bPolTerminalCode = '';
    }
  }

  changeEventDischargePort(event) {
    // console.log(event);
    var self = this;
    if (event.locationCode != null) {
      this.model.bPodCode = event.locationCode;
      this.model.bPodName = event.locationName;
      this.model.bPodBooking = event.locationBookingRef;

      if(this.model.bPodlCode == ''){
        this.cbPODL.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}')
        this.cbPODL.setValue(event.locationCode,function (callbackPODL) {
          self.model.bPodlCode = event.locationCode;
          self.cbPODL.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}')
        });
      }

      if(event.hasTerminal == 'Y'){
        this.lockPODTerminal = false;
      }else{
        this.lockPODTerminal = true;
      }

      this.cbDischargePortTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbDischargePortTerminal.setValue('');

    } else {
      this.model.bPodCode = '';
      this.model.bPodName = '';
      this.model.bPodBooking = '';
      this.cbDischargePortTerminal.setUrl('');
      this.cbDischargePortTerminal.setValue('');
    }


    setTimeout(function() {

      self.setDetail();

      self.grid.setBody(self.model);
      self.grid.loadData();

      /*
      self.grid2.setBody(self.model);
      self.grid2.loadData();

      self.grid3.setBody(self.model);
      self.grid3.loadData();

      self.grid4.setBody(self.model);
      self.grid4.loadData();

      self.grid5.setBody(self.model);
      self.grid5.loadData();
      */

    }, 150);

  }

  changeEventDischargePortTerminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPodTerminalCode = event.locationCode;
    }else{
      this.model.bPodTerminalCode = '';
    }
  }

  changeEventPODL(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPodlCode = event.locationCode;

      if(event.hasTerminal == 'Y'){
        this.lockPODLTerminal = false;
      }else{
        this.lockPODLTerminal = true;
      }

      this.cbPODLTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbPODLTerminal.setValue('');

    } else {
      this.model.bPodlCode = '';

      this.cbPODLTerminal.setUrl('');
      this.cbPODLTerminal.setValue('');
    }
  }

  changeEventPODLTerminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPodlTerminalCode = event.locationCode;
    }else{
      this.model.bPodlTerminalCode = '';
    }
  }

  changeEventFinalDest(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bFdestCode = event.locationCode;

      if(event.hasTerminal == 'Y'){
        this.lockFDESTTerminal = false;
      }else{
        this.lockFDESTTerminal = true;
      }

      this.cbFinalDestTerminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbFinalDestTerminal.setValue('');


    } else {
      this.model.bFdestCode = '';

      this.cbFinalDestTerminal.setUrl('');
      this.cbFinalDestTerminal.setValue('');
    }
  }

  changeEventFinalDestTerminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bFdestTerminalCode = event.locationCode;
    }else{
      this.model.bFdestTerminalCode = '';
    }
  }

  changeEventSpecialDet(event) {
    // console.log(event);
    if (event.specialDetailId != null) {
      this.model.bSpecialDetailCode = event.specialDetailId;
    }else{
      this.model.bSpecialDetailCode = '';
    }
    this.cbSpecialDet.setUrl(this.configService.config.BASE_API.toString() + '/MasterSpecialDetails/findByComboBoxControl/specialDetailCodeOrName={query}');
  }

  changeEventPOT1(event) {
     console.log(event);
     console.log('change event pot fired');
    if (event.locationCode != null) {
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;

      if(event.hasTerminal == 'Y'){
        this.lockPOT1Terminal = false;
      }else{
        this.lockPOT1Terminal = true;
      }

      this.cbPOT1Terminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbPOT1Terminal.setValue('');

      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}')
      //this.cbDischargePort.setValue('');
      this.cbNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+this.model.bNextMotherVesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + event.locationCode);

      this.lockNextVessel = false;
    } else {
      this.model.bPot1Code = '';
      this.model.bPot1Name = '';
      this.cbPOT1Terminal.setUrl('');
      this.cbPOT1Terminal.setValue('');

      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');
      //this.cbDischargePort.setValue('');

      this.lockNextVessel = true;
      this.cbNextVessel.setValue('');
      this.cbNextVoyage.setValue('');
    }
  }

  changeEventPOT1Terminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPot1TerminalCode = event.locationCode;
    }else{
      this.model.bPot1TerminalCode = '';
    }
  }

  changeEventPOT2(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot2Code = event.locationCode;

      if(event.hasTerminal == 'Y'){
        this.lockPOT2Terminal = false;
      }else{
        this.lockPOT2Terminal = true;
      }

      this.cbPOT2Terminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbPOT2Terminal.setValue('');

    } else {
      this.model.bPot2Code = '';

      this.cbPOT2Terminal.setUrl('');
      this.cbPOT2Terminal.setValue('');
    }
  }

  changeEventPOT2Terminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPot2TerminalCode = event.locationCode;
    }else{
      this.model.bPot2TerminalCode = '';
    }
  }

  changeEventPOT3(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.model.bPot3Code = event.locationCode;

      if(event.hasTerminal == 'Y'){
        this.lockPOT3Terminal = false;
      }else{
        this.lockPOT3Terminal = true;
      }

      this.cbPOT3Terminal.setUrl(this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/' + event.locationId + '/{query}');
      this.cbPOT3Terminal.setValue('');

    } else {
      this.model.bPot3Code = '';

      this.cbPOT3Terminal.setUrl('');
      this.cbPOT3Terminal.setValue('');

    }
  }

  changeEventPOT3Terminal(event) {
    // console.log(event);
    if(event.locationCode != null){
      this.model.bPot3TerminalCode = event.locationCode;
    }else{
      this.model.bPot3TerminalCode = '';
    }
  }

  changeEventCommodity(event) {
    // console.log(event);
    if (event.commodityId != null) {
      this.model.bCommodityCode = event.commodityId;
    } else {
      this.model.bCommodityCode = '';
    }
    this.cbCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}');
  }

  changeEventReceiptType(event) {
    // console.log(event);
    if (event.receiptTypeCode != null) {
      this.model.bReceiptType = event.receiptTypeCode;

    } else {
      this.model.bReceiptType = '';
    }
    this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeEventDeliveryType(event) {
    // console.log(event);
    if (event.deliveryTypeCode != null) {
      this.model.bDeliveryType = event.deliveryTypeCode;

    } else {
      this.model.bDeliveryType = '';
    }
    this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCodeOrName={query}');
  }

  changeEventFeederVessel(event) {
    // console.log(event);
    if(event.vesselId == null){
      this.model.bFeederVesselId = '';
      this.model.bFeederVesselCode = '';

    }else{
      this.model.bFeederVesselId = event.vesselId;
      this.model.bFeederVesselCode = event.vesselCode;

      this.cbFeederVoyage.disableCombo = false;
      this.cbFeederVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+event.vesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.cookieService.getDefaultLocationCode());
      this.cbFeederVoyage.setValue('');

    }
  }

  changeEventFeederVoyage(event) {
    // console.log(event);
    if(event.voyage == null){
      this.model.bFeederVesselVoyage = '';
    }else{
      this.model.bFeederVesselVoyage = event.voyage;
    }
  }

  changeEventSupplier(event){
    // console.log(event);
    if (event.customerId != null) {
      this.model.bSupplierCode = event.customerId;
      this.model.bSupplierName = event.customerName;
    } else {
      this.model.bSupplierCode = '';
      this.model.bSupplierName = '';
    }
    this.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerName={query}');
  }

  changeEventExVessel(event) {
    // console.log(event);
    if(event.vesselId == null) {
      this.model.bExMotherVesselId = '';
      this.model.bExMotherVesselCode = '';
      this.model.bExMotherVesselName = '';

    }else{
      this.model.bExMotherVesselId = event.vesselId;
      this.model.bExMotherVesselCode = event.vesselCode;
      this.model.bExMotherVesselName = event.vesselName;

      //   this.cbExVoyage.disableCombo = false;
      //  this.cbExVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+event.vesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.cookieService.getDefaultLocationCode());
      //  this.cbExVoyage.setValue('');
    }

  }

  changeEventExVoyage(event) {
    // console.log('** change event ex voyage **');
    if (event.voyage == null) {
      this.model.bExMotherVesselVoyage = '';
    } else {
      this.model.bExMotherVesselVoyage = event.voyage;
    }
  }

  changeEventNextVessel(event) {
     console.log(event);
     console.log('fired after next vessell');
    if(event.vesselId == null){
      this.model.bNextMotherVesselId = '';
      this.model.bNextMotherVesselCode = '';

    }else{
      this.model.bNextMotherVesselId = event.vesselId;
      this.model.bNextMotherVesselCode = event.vesselCode;

      //this.cbNextVoyage.disableCombo = false;
      this.cbNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+event.vesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.model.bPot1Code);
      //this.cbNextVoyage.setValue(this.tsData['voyage']);
    }

  }

  changeEventNextVoyage(event) {
    // console.log(event);
    if(event.voyage != null && event.voyage != undefined && event.voyage != ''){
      this.cbNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+this.model['bNextMotherVesselId']+'/'+this.model.bOceanVesselBound+'/{query}/' + this.model.bPot1Code);
      console.log('Value Next Voayage before assignment ' + this.model.bNextMotherVesselVoyage);
      this.model.bNextMotherVesselVoyage = event.voyage;
      console.log('Value Next Voayage after assignment ' + this.model.bNextMotherVesselVoyage);
    }else{
      console.log('*Value Next Voayage before assignment ' + this.model.bNextMotherVesselVoyage);
      this.model.bNextMotherVesselVoyage = '';
      console.log('*Value Next Voayage after assignment ' + this.model.bNextMotherVesselVoyage);
    }

  }

  changeEventHaulier(event) {
    // console.log(event);
    if (event.customerId != null && event.customerId != '' && event.customerId != undefined) {
      this.bookingMisc.bHaulierId = event.customerId;
      this.bookingMisc.bContactPerson = event.contactInfo;
    } else {
      this.bookingMisc.bHaulierId = '';
    }
    this.cbHaulier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlHaulier/customerName={query}');
  }

  changeEventReturnTo(event) {
    // console.log(event);
    if (event.depoCode != null) {
      this.model.bReturnToCode = event.depoCode;
    } else {
      this.model.bReturnToCode = '';
    }
  }

  changeEventPackedAt(event) {
    // console.log(event);
    if (event.depoCode != null) {
      this.model.bPackedAtCode = event.depoCode;
    } else {
      this.model.bPackedAtCode = '';
    }
  }

  changeEventPackageType(event) {
    // console.log(event);
    if (event.packageTypeCode != null) {
      this.model.bPackageTypeCode = event.packageTypeCode;
    } else {
      this.model.bPackageTypeCode = '';
    }
  }

  changeEventShipmentType(event){
    this.model.bShipmentType = event.target.value;
    if(this.model.bShipmentType == 'C'){
      this.isCRB = true;
    }else{
      this.isCRB = false;
    }
  }

  changeEventIhc(event){
    this.model.bIhc = event.target.value;
  }

  changeEventFreightType(event){
    this.model.bFreightType = event.target.value;
  }

  changeEventDGClass(event){
    this.model.bDgClass = event.target.value;
  }

  changeEventDegree(event){

    if(event.target.value != null){
      let val = Number.parseInt(event.target.value);
      if(isNaN(val)){
        this.model.bReeferDegree = '';
      }else{
        this.model.bReeferDegree = this.bDegree.toString()  + this.bDegreeTemp;
      }
    }else{
      this.model.bReeferDegree = '';
    }
    // console.log(this.model.bReeferDegree);
  }

  onSelectDegreeTemp(val){
    this.bDegreeTemp = val;
    if(this.model.bReeferDegree != ''){
      this.model.bReeferDegree = this.bDegree.toString() +  this.bDegreeTemp;
    }
    // console.log(this.model.bReeferDegree);
  }

  changeEventWeightClass(event){
    this.model.bWeightClass = event.target.value;
    this.model.bWeight = 0;
    if(event.target.value == 'C'){
      this.lockWeight = false;
    }else{
      this.lockWeight = true;
    }

    if(event.target.value == 'E'){
      this.model.bContainerStatus = 'EMPTY';
    }else{
      this.model.bContainerStatus = 'FULL';
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

    /*
    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */

  }

  changeEventWeight(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.model.bWeight = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bWeight = 0;
    }
  }

  changeEventKillSlot(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.killSlot = 0;
    }

    if (val < 0) {
      val = 0;
      this.killSlot = 0;
    }

    // console.log(this.model.bookingDetails.length)
    if(this.model.bookingDetails.length > 0){
      this.setDetail()
      this.grid.setBody(this.model);
      this.grid.loadData();
    }
  }

  changeEventDoorOpen(event) {
    this.doorOpen = event.target.value;
  }

  changeEventVent(event) {
    this.model.bVent = event.target.value;
  }

  changeEventSocOPR(event) {
    // console.log('** change event SOC OPR **');
    if (event.code != null) {
      // console.log(event.code);
      this.model.bSocCntrOperatorCode = event.code;
      this.bookingContainerInfo.bContOperator = event.code;
      if(this.gridContainerInfo.listStore.store.length > 0){
        this.gridContainerInfo.url = '';
        for(let i=0; i<this.gridContainerInfo.listStore.store.length; i++){
          this.gridContainerInfo.listStore.store[i].bContOperator = event.code;
        }
        this.gridContainerInfo.loadData();
      }

    } else {
      this.model.bSocCntrOperatorCode = '';

    }
  }

  changeEventCocOPR(event){
    // console.log('** change event COC OPR **');
    if(event.code != null){
      // console.log(event.code);
      this.model.bCocCntrOperatorCode = event.code;

      this.bookingContainerInfo.bContOperator = event.code;
      if(this.gridContainerInfo.listStore.store.length > 0){
        this.gridContainerInfo.url = '';
        for(let i=0; i<this.gridContainerInfo.listStore.store.length; i++){
          this.gridContainerInfo.listStore.store[i].bContOperator = event.code;
        }
        this.gridContainerInfo.loadData();
      }

    } else {
      this.model.bCocCntrOperatorCode = '';

    }
  }

  changeEventSlotOPR(event){
    // console.log('** change event slot opr **');
    if(event.code != null){
      // console.log(event.code);
      this.model.bSlotOperatorCode = event.code;

    }else{
      this.model.bSlotOperatorCode = '';

    }
    this.bookingContainerInfo.bContOperator = event.code;
    if(this.gridContainerInfo.listStore.store.length > 0){
      this.gridContainerInfo.url = '';
      for(let i=0; i<this.gridContainerInfo.listStore.store.length; i++){
        this.gridContainerInfo.listStore.store[i].bContOperator = event.code;
      }
      this.gridContainerInfo.loadData();
    }
    this.grid.setBody(this.model);
    this.grid.loadData();
  }

  changeEventOH(event){
    /*
    this.OH = event.target.checked;
    if(this.OH){
      this.lockOH = false;
    }else{
      this.lockOH = true;
      this.heightMeasure = 0;
    }
     */
    if(event.target.checked){
      this.OH = 'Y';
      this.lockOH = false;
    }else{
      this.OH = 'N';
      this.lockOH = true;
      this.heightMeasure = 0;
    }
  }

  changeEventOW(event){
    /*
    this.OW = event.target.checked;
    if(this.OW){
      this.lockOW = false;
    }else{
      this.lockOW = true;
      this.leftMeasure = 0;
      this.rightMeasure = 0;
    }
    */
    if(event.target.checked){
      this.OW = 'Y';
      this.lockOW = false;
    }else{
      this.OW = 'N';
      this.lockOW = true;
      this.leftMeasure = 0;
      this.rightMeasure = 0;
    }
  }

  changeEventOL(event){
    /*
    this.OL = event.target.checked;
    if(this.OL){
      this.lockOL = false;
    }else{
      this.lockOL = true;
      this.frontMeasure = 0;
      this.endMeasure = 0;
    }
    */
    if(event.target.checked){
      this.OL = 'Y';
      this.lockOL = false;
    }else{
      this.OL = 'N';
      this.lockOL = true;
      this.frontMeasure = 0;
      this.endMeasure = 0;
    }
  }

  changeEventHeightMeasure(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.heightMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.heightMeasure = 0;
    }
  }

  changeEventLeftMeasure(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.leftMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.leftMeasure = 0;
    }
  }

  changeEventRightMeasure(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.rightMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.rightMeasure = 0;
    }
  }

  changeEventFrontMeasure(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.frontMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.frontMeasure = 0;
    }
  }

  changeEventEndMeasure(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.endMeasure = 0;
    }

    if (val < 0) {
      val = 0;
      this.endMeasure = 0;
    }
  }

  changeEventDetention(event){
    this.model.bDetention = event.target.value;
    if(this.model.bDetention == 'R'){
      this.lockDetention = false;
    }else{
      this.lockDetention = true;
      this.model.bDetentionRequest = 0;
    }
  }

  changeEventDetentionReq(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.model.bDetentionRequest = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bDetentionRequest = 0;
    }
  }

  changeEventDemurrage(event){
    this.model.bDemurrage = event.target.value;
    if(this.model.bDemurrage == 'R'){
      this.lockDemurrage = false;
    }else{
      this.lockDemurrage = true;
      this.model.bDemurrageRequest = 0;
    }
  }

  changeEventDemurrageReq(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.model.bDemurrageRequest = 0;
    }

    if (val < 0) {
      val = 0;
      this.model.bDemurrageRequest = 0;
    }
  }

  changeEventContainerOwnership(event) {
    this.model.bContainerOwnership = event.target.value;
    if(this.model.bContainerOwnership == 'SOC' || this.model.bContainerOwnership == 'SOCTS'){
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
      this.cbSlotOPR.clearSelect();

      this.model.bSocOperatorCode = 'SA';
      this.model.bCocOperatorCode = '';
      this.model.bCocCntrOperatorCode = '';
      this.model.bOutwardSlotOperatorCode = '';
      this.model.bSlotOperatorCode = '';

      this.model.bDetention = 'N';
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = 'N';
      this.model.bDemurrageRequest = 0;
      this.lockDetention = true;
      this.lockDemurrage = true;

      //reset coc
      this.cbCocOPR.setValue('');

    }else if(this.model.bContainerOwnership == 'COC' || this.model.bContainerOwnership == 'COCTS'){
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
      this.cbSocOPR.clearSelect();
      this.cbSlotOPR.clearSelect();

      this.model.bSocOperatorCode = '';
      this.model.bCocOperatorCode = 'SA';
      this.model.bCocCntrOperatorCode = '';
      this.cbCocOPR.clearSelect();
      this.cbCocOPR.listStore.store = [];

      //this.cbCocOPR.setValue('SA');
      var self= this;
      this.cbCocOPR.loadSelect(function(clcocoper){
       if(self.cbCocOPR.listStore.store.length > 0){
         self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]['code'].toString();
         self.cbCocOPR.setValue(self.cbCocOPR.listStore.store[0]['code']);
       }
      });

      /*
      this.cbCocOPR.setValue(this.cbCocOPR.listStore.store[0]['code'].toString(),function(cbCntrOpr){
        self.model.bCocCntrOperatorCode = self.cbCocOPR.listStore.store[0]['code'].toString();
        self.model['error-operatorCodeValidator'] = '';
        // console.log('COC OPR ' + self.model.bCocCntrOperatorCode );
      });
      */

      this.model.bOutwardSlotOperatorCode = '';
      this.model.bSlotOperatorCode = '';


      this.model.bDetention = 'S';
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = 'S';
      this.model.bDemurrageRequest = 0;
      this.lockDetention = true;
      this.lockDemurrage = true;

    }else if(this.model.bContainerOwnership == 'SLOT'){
      this.lockSOCOPR = true;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
      this.cbSocOPR.clearSelect();

      this.model.bSocOperatorCode = '';
      this.model.bCocOperatorCode = '';
      this.model.bCocCntrOperatorCode = '';
      this.model.bOutwardSlotOperatorCode = 'SA';
      this.model.bSlotOperatorCode = '';

      this.model.bDetention = 'N';
      this.model.bDetentionRequest = 0;
      this.model.bDemurrage = 'N';
      this.model.bDemurrageRequest = 0;
      this.lockDetention = true;
      this.lockDemurrage = true;

      //reset coc
      this.cbCocOPR.setValue('');

    }else{
      this.lockSOCOPR = true;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
    }

    this.bookingDetails.clear();
    let list = new Map<String,BookingDetail>();
    this.bookingDetails.set(this.currentContainerType,this.setContainerValue(list));

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

    this.isCOCLocalExceed = false;
    if(this.model.bContainerOwnership == 'COC'){
      var check:Boolean = this.checkCocLocalExceedForLabel();
      // console.log('checkCocLocalExceed ==> ')
      // console.log(check)
      if(!check){
        this.isCOCLocalExceed = true;
      }
    }


    /*
    this.grid2.setBody(this.model);
    this.grid2.loadData();

    this.grid3.setBody(this.model);
    this.grid3.loadData();

    this.grid4.setBody(this.model);
    this.grid4.loadData();

    this.grid5.setBody(this.model);
    this.grid5.loadData();
    */

  }

  changeEventContainerType(event){
    // console.log('** change event container type **');

    this.currentContainerType = event.target.value;

    if(this.currentContainerType == 'TNK'){
      //this.lockTNK = true;
      this.lockTNK = false;   //enable based on miki email on 20 march 2017
    }else{
      this.lockTNK = false;

    }

    if(this.currentContainerType == 'DRY'){
      this.model.bIsDry = 'Y';
    }else{
      this.model.bIsDry = 'N';
    }

    if (this.model.bContainerOwnership == 'SLOT'){

      // console.log(this.bookingDetails);

      if(this.currentContainerType == 'OPT' || this.currentContainerType == 'FLR' || this.currentContainerType == 'PLT'){
        this.bookingDetails.clear();
      }

      if (this.bookingDetails.has(this.currentContainerType)){
        this.d20 = 0;
        this.h20 = 0;
        this.d40 = 0;
        this.h40 = 0;
        this.d45 = 0;
        this.h45 = 0;

        for (let entry of Array.from(this.bookingDetails.entries())) {
          if(entry[0] == this.currentContainerType){
            for (let val of Array.from(entry[1].entries())) {
              if(val[0] == '2086'){
                this.d20 = val[1].bQuantity;
              }else if(val[0] == '2096'){
                this.h20 = val[1].bQuantity;
              }else if(val[0] == '4086'){
                this.d40 = val[1].bQuantity;
              }else if(val[0] == '4096'){
                this.h40 = val[1].bQuantity;
              }else if(val[0] == '4586'){
                this.d45 = val[1].bQuantity;
              }else if(val[0] == '4596'){
                this.h45 = val[1].bQuantity;
              }
            }
          }
        }

      }else{
        this.d20 = 0;
        this.h20 = 0;
        this.d40 = 0;
        this.h40 = 0;
        this.d45 = 0;
        this.h45 = 0;


        if(this.containerSlots.get(this.currentContainerType)){
        }else{
          this.bookingDetails.clear();
        }
        let list = new Map<String,BookingDetail>();
        this.bookingDetails.set(this.currentContainerType,list);
      }


    }else{

      // console.log(this.currentContainerType);
      this.bookingDetails.clear();
      let list = new Map<String,BookingDetail>();
      this.bookingDetails.set(this.currentContainerType,this.setContainerValue(list));

    }


  }

  changeEventD20(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.d20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d20 = 0;
    }

    if(val > 0){

      if(this.bookingDetails.get(this.currentContainerType).get('2086') != null){
        this.bookingDetails.get(this.currentContainerType).delete('2086');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '20';
      det.bContainerHeight = '86';
      det.bQuantity = val;
      this.bookingDetails.get(this.currentContainerType).set('2086',det);

    }else{
      // console.log('#2');
      this.bookingDetails.get(this.currentContainerType).delete('2086');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  changeEventH20(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.h20 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h20 = 0;
    }

    if(val > 0){
      if(this.bookingDetails.get(this.currentContainerType).get('2096') != null){
        this.bookingDetails.get(this.currentContainerType).delete('2096');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '20';
      det.bContainerHeight = '96';
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set('2096',det);

    }else{
      this.bookingDetails.get(this.currentContainerType).delete('2096');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  changeEventD40(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.d40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d40 = 0;
    }

    if(val > 0){
      if(this.bookingDetails.get(this.currentContainerType).get('4086') != null){
        this.bookingDetails.get(this.currentContainerType).delete('4086');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '40';
      det.bContainerHeight = '86';
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set('4086',det);

    }else{
      this.bookingDetails.get(this.currentContainerType).delete('4086');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  changeEventH40(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.h40 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h40 = 0;
    }

    if(val > 0){
      if(this.bookingDetails.get(this.currentContainerType).get('4096') != null){
        this.bookingDetails.get(this.currentContainerType).delete('4096');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '40';
      det.bContainerHeight = '96';
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set('4096',det);

    }else{
      this.bookingDetails.get(this.currentContainerType).delete('4096');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  changeEventD45(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.d45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.d45 = 0;
    }

    if(val > 0){
      if(this.bookingDetails.get(this.currentContainerType).get('4586') != null){
        this.bookingDetails.get(this.currentContainerType).delete('4586');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '45';
      det.bContainerHeight = '86';
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set('4586',det);


    }else{
      this.bookingDetails.get(this.currentContainerType).delete('4586');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  changeEventH45(event){
    let val = Number.parseInt(event.target.value);
    if(isNaN(val)){
      val = 0;
      this.h45 = 0;
    }

    if (val < 0) {
      val = 0;
      this.h45 = 0;
    }

    if(val > 0){
      if(this.bookingDetails.get(this.currentContainerType).get('4596') != null){
        this.bookingDetails.get(this.currentContainerType).delete('4596');
      }

      let det = new BookingDetail;
      det.bOfficeCode = this.model.bOfficeCode;
      det.bPrefix = this.model.bPrefix.toUpperCase();
      det.bNo = this.model.bNo;
      det.bContainerType = this.currentContainerType;
      det.bContainerSize = '45';
      det.bContainerHeight = '96';
      det.bQuantity = val;

      this.bookingDetails.get(this.currentContainerType).set('4596',det);

    }else{
      this.bookingDetails.get(this.currentContainerType).delete('4596');
    }

    this.setDetail();

    this.grid.setBody(this.model);
    this.grid.loadData();

  }

  handleCancel() {
    this.disableToolbarButtons = 'modify,save,copy,cancel,print,delete';
    /*
    this.cbLoadPort.setValue(this.cookieService.getCookie('defaultLocationCode'));

    this.cbPOR.setValue(this.cookieService.getDefaultLocationCode());

    this.cbReceiptType.setValue('CY');
    this.model.bReceiptType = 'CY';

    this.cbDeliveryType.setValue('CY');
    this.model.bDeliveryType = 'CY';
    */
    this.init();
    this.isNew = false;
    //this.strParams = '';
    localStorage.removeItem('new-booking-from-flag');
  }

  handleRetrieve() {
    this.isError = this.onValidate(this.model);
    if(!this.isError){
      // console.log('retrieve : ' + this.model.bNo);
      this.genericUtil.showLoader();

      var self = this;
      this.retrieveEvent(function(callbackRetrieve) {

        // console.log('Is Unlock => ' + callbackRetrieve.bIsUnlocked);

        if(callbackRetrieve.bStatus == 'D'){
          // console.log('#1')
          self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
        }else if(callbackRetrieve.bContainerOwnership == 'COCTS' && (callbackRetrieve.bOfficeCodeTs != '' && callbackRetrieve.bOfficeCodeTs != undefined ) && (callbackRetrieve.bPrefixTs != '' && callbackRetrieve.bPrefixTs != undefined ) && (callbackRetrieve.bNoTs != '' && callbackRetrieve.bNoTs != undefined)){
          // console.log('#2')
          self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
        }else{
          if(callbackRetrieve.bIsUnlocked == 'N'){
            self.appStatusLabel = '-- LOCKED --';
            self.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
          }else{
            // console.log('#3')
            self.disableToolbarButtons = 'retrieve,save,copy';
          }
        }
        self.genericUtil.hideLoader();
        self.isInit = false;
        self.lockEdit = true;
      });
    }else{
      window.scrollTo(0,0);
    }
  }

  retrieveEvent(callback?: (data) => void){
    // console.log('** retrieve event **');
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingById/' + this.model.bOfficeCode + '/' + this.model.bPrefix.toUpperCase() + '/' + String(this.model.bNo.toUpperCase()).toUpperCase().trim()).subscribe((resp) => {
      let data = resp.json();
      // console.log(data);
      if(data.status == 'OK'){
        if(callback){
          if(data.bStatus == 'D'){
            this.dialogPlugin.show('warning','Information','Booking already deleted.', 'okonly', { ok: ' this.genericUtil.hideLoader();'});

          }else{
            this.setData(data);
            callback(data);
          }
        }
      }else{
        this.dialogPlugin.show('information','Information','No record(s) found.','okonly',{ok: ''});
        this.genericUtil.hideLoader();
      }

    })
  }

  handleNew() {
    // console.log('** handle new **');

    this.model['allocationValidator'] = false;
    if(this.model.bOceanVesselId != '' && this.model.bOceanVesselVoyage != '' && this.grid.listStore.store.length == 0){
      this.model['allocationValidator'] = true;
    }
    this.isError = this.onValidate(this.model);
    if(!this.isError){
      this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
      this.lock = false;
      this.lockEdit = true;
      this.isNew = true;
      this.isInit = false;
      this.setValidatorSave();

      this.initNew();

    }else{
      window.scrollTo(0,0);
    }
  }

  handleModify() {
    this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
    this.lock = false;
    this.lockEdit = true;
    this.setValidatorSave();
    this.isInit = false;

    //fast hack pot issue
    this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');

    console.log('MMother vessel id before set pot ' + this.model.bNextMotherVesselId);
    console.log('MMother vessel voyage before set pot ' + this.model.bNextMotherVesselVoyage);

    if(this.model.bPot1Code != null && this.model.bPot1Code != '' && this.model.bPot1Code != undefined){
      this.lockNextVessel = false;
      console.log('MMother vessel id ' + this.model.bNextMotherVesselId);
      console.log('MMother vessel voyage ' + this.model.bNextMotherVesselVoyage);

      this.cbNextVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/'+this.model.bNextMotherVesselId+'/'+this.model.bOceanVesselBound+'/{query}/' + this.model.bPot1Code);
      console.log('Value Next Voayage before set value ' + this.model.bNextMotherVesselVoyage);
      this.cbNextVoyage.setValue(String(this.model.bNextMotherVesselVoyage).trim());
      console.log('Value Next Voayage after set value ' + this.model.bNextMotherVesselVoyage);

    }
  }

  setDetail(){
    this.model.bookingDetails = [];

    for(let entry of Array.from(this.bookingDetails.entries())){
      for(let det of Array.from(entry[1].entries())){
        // console.log(det[1]);
        det[1].bKillSlot = this.killSlot;
        det[1].bDoorOpen = this.doorOpen;
        det[1].bOverHeight = this.OH;
        det[1].bOverHeightCm = this.heightMeasure;
        det[1].bOverWidth = this.OW;
        det[1].bOverWidthLeftCm = this.leftMeasure;
        det[1].bOverWidthRightCm = this.rightMeasure;
        det[1].bOverLength = this.OL;
        det[1].bOverLengthFrontCm = this.frontMeasure;
        det[1].bOverLengthBackCm = this.endMeasure;

        det[1].bUch = this.UCH;
        det[1].bUcl = this.UCL;
        det[1].bUcw = this.UCW;

        this.model.bookingDetails.push(det[1]);
      }
    }
    // patched by gilang to include the container info list
    if (this.gridContainerInfo.listStore.store.length > 0){
      this.model.bookingContainerInfo = [];
      for(let i=0; i<this.gridContainerInfo.listStore.store.length; i++){
        this.model.bookingContainerInfo.push(this.gridContainerInfo.listStore.store[i]);
      }
    }

    this.bookingMisc.bOfficeCode = this.model.bOfficeCode;
    this.bookingMisc.bPrefix = this.model.bPrefix;
    this.bookingMisc.bNo = this.model.bNo;

    this.bookingMisc.bReleaseAtDate = this.dateReleaseAt.getValue();
    this.bookingMisc.bReleaseAtTime =  $('#timeReleaseAt').val();

    this.bookingMisc.bReturnToPorGateInDate = this.dateReturnToPor.getValue();
    this.bookingMisc.bReturnToPorGateInTime = $('#timeReturnToPor').val();
    this.bookingMisc.bReturnToPorClosingDate = this.dateReturnToPorGateInClosing.getValue();
    this.bookingMisc.bReturnToPorClosingTime = $('#timeReturnToPorClosing').val();
    this.bookingMisc.bReturnToPorVgmDate = this.dateReturnToPorVgmClosing.getValue();
    this.bookingMisc.bReturnToPorVgmTime = $('#timeReturnToPorVgm').val();

    this.bookingMisc.bReturnToPolGateInDate = this.dateReturnToPol.getValue();
    this.bookingMisc.bReturnToPolGateInTime = $('#timeReturnToPol').val();
    this.bookingMisc.bReturnToPolClosingDate = this.dateReturnToPolGateInClosing.getValue();
    this.bookingMisc.bReturnToPolClosingTime = $('#timeReturnToPolClosing').val();
    this.bookingMisc.bReturnToPolVgmDate = this.dateReturnToPolVgmClosing.getValue();
    this.bookingMisc.bReturnToPolVgmTime = $('#timeReturnToPolVgm').val();

    this.bookingMisc.bStuffAtPorGateInDate = this.dateStuffAtPor.getValue();
    this.bookingMisc.bStuffAtPorGateInTime = $('#timeStuffAtPor').val();
    this.bookingMisc.bStuffAtPorClosingDate = this.dateStuffAtPorGateInClosing.getValue();
    this.bookingMisc.bStuffAtPorClosingTime = $('#timeStuffAtPorClosing').val();
    this.bookingMisc.bStuffAtPorVgmDate = this.dateStuffAtPorVgmClosing.getValue();
    this.bookingMisc.bStuffAtPorVgmTime = $('#timeStuffAtPorVgm').val();

    console.log('dari set detail ');
    console.log(this.bookingMisc);
    console.log(this.model.bookingMisc);
    this.model.bookingMisc = this.bookingMisc;
    console.log('dari set detail 2');
    console.log(this.bookingMisc);
    console.log(this.model.bookingMisc);

  }

  handleSave() {
    // console.log('** Handle Save ** ');
    this.model.bFeederVesselBound = 'O';
    this.model.bExMotherVesselBound = 'M';
    this.model.bNextMotherVesselBound = 'O';

    //this.model.bRequestReleaseDate = this.dtRequestReleaseDate.dateP.nativeElement.value;
    //this.model.bExpectedReturnDate = this.dtExpReturnDate.dateP.nativeElement.value;
    //this.model.bReeferCollectionDate = this.dtReeferCollection.dateP.nativeElement.value;

    // console.log('this.bDegree => ' +this.bDegree)

    if(this.bDegree == undefined){
      this.model.bReeferDegree = this.bDegreeTemp;
    }

    // console.log('this.model.bReeferDegree => ' + this.model.bReeferDegree)
    this.setDetail();

    if(this.model.bNo == ''){
      this.model.bStatus = 'N';
      this.model.bCreatedUserid = this.cookieService.getName();
      this.model.bCreatedDate = this.genericUtil.getPSIDate();

      this.model.bModifiedUserid = this.cookieService.getName();
      this.model.bModifiedDate = this.genericUtil.getPSIDate();
    }else{
      this.model.bStatus = 'U';
      this.model.bModifiedUserid = this.cookieService.getName();
      this.model.bModifiedDate = this.genericUtil.getPSIDate();
    }
    console.log('Check validation before validdation save');
    console.log('the value of next mother vessel voyage = ' + this.model.bNextMotherVesselVoyage);

    this.setValidatorSave();
    this.model['bookingPartyValidator'] = this.checkBookingPartyValidator();
    this.model['rotationValidator'] = this.checkRotation();
    this.model['unitContainerValidator'] = this.checkUnitContainer();
    this.model['operatorCodeValidator'] = this.checkOperatorCode();
    this.model['coctsExceedValidator'] = this.checkCoctsExceed();
    this.model['cocLocalExceedValidator'] = this.checkCocLocalExceed();
    //this.model['podAllocationValidator'] = this.checkPodAllocation();
    if(this.model['unitContainerValidator'] == false){
      this.model['allocationValidator'] = this.checkAllocationValidator();
    }
    this.model['feederValidator'] = this.checkFeederVoyage();
    this.model['ExVoyageValidator'] = this.checkExVoyage();
    this.model['NextVoyageValidator'] = this.checkNextVoyage();
    this.model['ReeferDegreeValidator'] = this.checkReeferDegree();
    this.model['ucValidator'] = this.checkUC();

    /*
    this.model['allocationValidator'] = false;
    if(this.model.bOceanVesselId != '' && this.model.bOceanVesselVoyage != '' && this.grid.listStore.store.length == 0){
      this.model['allocationValidator'] = true;
    }
    */

    this.model['POLPODValidator'] = false;
    if(this.model.bPolCode != '' && this.model.bPodCode != ''){
      this.model['POLPODValidator'] = (this.model.bPolCode == this.model.bPodCode)?  true : false;
    }

    this.model['POLPOTValidator'] = false;
    if(this.model.bPolCode != '' && this.model.bPot1Code != ''){
      this.model['POLPOTValidator'] = (this.model.bPolCode == this.model.bPot1Code)?  true : false;
    }

    this.model['PODPOTValidator'] = false;
    if(this.model.bPodCode != '' && this.model.bPot1Code != ''){
      this.model['PODPOTValidator'] = (this.model.bPodCode == this.model.bPot1Code)?  true : false;
    }


    this.isError = this.onValidate(this.model);

    if(!this.isError){

      //debug glg
      /*
      if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/Transshipment/inquireConnectionVessel', this.model).subscribe((resp)=> {
          if(resp.status == 200){
            if(resp.json()['content']['current'] == undefined){
              this.dialogPlugin.show('information','Information','System is unable to find any available 2nd leg vessel suitable for this booking !. Please create transshipment request to POT: ' + this.model.bPot1Name, 'okonly', { ok: 'this.loading=false;'});
              return
            }
            let msg:String = '';
            this.tsData = resp.json()['content']['current'];
            msg+='<table border='0' width='100%'><tr><td colspan='6'>Earliest connection available is:</td></tr>';
            msg+='<tr><td><strong>'+resp.json()['content']['current']['vesselName']+'</strong></td><td><strong>'+resp.json()['content']['current']['voyage']+'</strong></td><td><strong>ETA: </strong></td><td><strong>'+resp.json()['content']['current']['eta']+'</strong></td><td><strong>Est POD Arrival:</strong></td><td><strong>'+resp.json()['content']['current']['arrival']+'</strong></td></tr>';
            msg+='<tr><td colspann='6'>&nbsp;</td></tr>';
            //msg+='<strong>' +  + ' ' + resp.json()['content']['current']['voyage'] + '</strong> ETA: ' + resp.json()['content']['current']['eta'] + ' Arrival: '++'<br /><br />';
            msg+='<tr><td colspan='6'>Next available connection: </td></tr>';

            for(let i=0; i< resp.json()['content']['next'].length; i++){
              msg+='<tr><td>'+resp.json()['content']['next'][i]['vesselName']+'</td><td>'+ resp.json()['content']['next'][i]['voyage']+'</td><td>ETA: </td><td>'+resp.json()['content']['next'][i]['eta']+'</td><td>Est POD Arrival:</td><td>'+resp.json()['content']['next'][i]['arrival']+'</td></tr>';
              //msg+=resp.json()['content']['next'][i]['vesselName'] + ' ' + resp.json()['content']['next'][i]['voyage'] + ' ETA: ' + resp.json()['content']['next'][i]['eta'] + ' Arrival: '+resp.json()['content']['next'][i]['arrival']+ '<br />';
            }

            msg += '</table>';
            this.dialogPlugin.show('information','Connection Information',msg,'yesno',{yes:'this.setNextVesselVoyage()',no:''});

          }
        });
      }else{
      */
        let stsSoc:String = this.checkBookingExceedSoc();
        // console.log('stsSoc => ' + stsSoc);
        let stsSoclocal:String = this.checkBookingExceedSoclocal();
        // console.log('stsSoclocal => ' + stsSoc);
        let stsSocts:String = this.checkBookingExceedSocts();
        // console.log('stsSocts => ' + stsSoc);

        let stsCoc:String = this.checkBookingExceedCoc();
        // console.log('stsCoc => ' + stsCoc);
        let stsCoclocal:String = this.checkBookingExceedCoclocal();
        // console.log('stsCoclocal => ' + stsCoclocal);
        let stsCocts:String = this.checkBookingExceedCocts();
        // console.log('stsCocts => ' + stsCocts);

        let stsSlot:String = this.checkBookingExceedSlot();
        // console.log('stsSlot => ' + stsSlot);

        if(this.checkBookingExceed()){
          // console.log('#1');
          this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});

        }else if(stsSoc != '0'){
          // console.log('#2');
          if(stsSoc == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for SOC is missing. Please set figures in allocation. ', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsSoc == '2'){
              this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
          }
        }else if(stsSoclocal != '0'){
          // console.log('#2');
          if(stsSoclocal == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for SOC Local is missing. Please set figures in allocation. ', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsSoclocal == '2'){
              this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
          }
        }else if(stsSocts != '0'){
          // console.log('#2');
          if(stsSocts == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for SOC T/S is missing. Please set figures in allocation. ', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsSocts == '2'){
              this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
          }
        }else if(stsCoc != '0'){
          // console.log('#3');
          if(stsCoc == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for COC is missing. Please set figures in allocation.', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsCoc == '2'){
              //if(this.model.bContainerOwnership == 'COCTS'){
                this.dialogPlugin.show('save','Saving data','Booking exceeds allocation.', 'okonly', { ok: 'this.loading = false;'});
              //}else{
              //  this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
              //}
        }
        }else if(stsCoclocal != '0'){
          // console.log('#3');
          if(stsCoclocal == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for COC Local is missing. Please set figures in allocation.', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsCoclocal == '2'){
            if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
              this.inquireConnectionVessel();
            }else{
              this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
            }
          }
        }else if(stsCocts != '0'){
          // console.log('#3');
          if(stsCocts == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for COC T/S is missing. Please set figures in allocation.', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsCocts == '2'){
            this.inquireConnectionVessel();
              //this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
          }
        }else if(stsSlot != '0'){
          // console.log('#4');
          if(stsSlot == '1'){
              this.dialogPlugin.show('save','Saving data','Allotted figures for SS-' + this.model.bSlotOperatorCode +' is missing. Please set figures in allocation. ', 'okonly', { ok: 'this.loading = false;'});
          }
          if(stsSlot == '2'){
              this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
          }
        }else{
          // console.log('#5');
          if(this.model.bPot1Code != '' && this.model.bContainerOwnership == 'COC'){
            this.inquireConnectionVessel();
          }else if(this.model.bContainerOwnership == 'COCTS'){
            this.inquireConnectionVessel();
          }else{
            this.saveEvent();
          }
        }
      //}


    }else{
      window.scrollTo(0,0);
    }

  }

  inquireConnectionVessel(){
    if(this.defaultCountry == "sg"){
      this.genericService.POST(this.configService.config.BASE_API.toString() + "/Transshipment/inquireConnectionVessel", this.model).subscribe((resp)=> {
        if(resp.status == 200){
          if(resp.json()['content']["current"] == undefined){
            this.dialogPlugin.show('information','Information','System is unable to find any available 2nd leg vessel suitable for this booking!<br/> Please create <b><u>transshipment</u></b> request to <b><u>POT: ' + this.model.bPot1Name + '</u></b>', 'okonly', { ok: 'this.loading=false;'});
            return
          }
          let msg: string = '';
          this.tsData = resp.json()["content"]["current"];
          msg+="<table border='0' width='100%'><tr><td colspan='6'>Earliest connection available is:</td></tr>";
          msg+="<tr><td><strong>"+resp.json()["content"]["current"]["vesselName"]+"</strong></td><td><strong>"+resp.json()["content"]["current"]["voyage"]+"</strong></td><td><strong>ETA: </strong></td><td><strong>"+resp.json()["content"]["current"]["eta"]+"</strong></td><td><strong>Est POD Arrival:</strong></td><td><strong>"+resp.json()["content"]["current"]["arrival"]+"</strong></td></tr>";
          msg+="<tr><td colspann='6'>&nbsp;</td></tr>";
          //msg+="<strong>" +  + " " + resp.json()["content"]["current"]["voyage"] + "</strong> ETA: " + resp.json()["content"]["current"]["eta"] + " Arrival: "++"<br /><br />";
          msg+="<tr><td colspan='6'>Next available connection: </td></tr>";

          for(let i=0; i< resp.json()["content"]["next"].length; i++){
            msg+="<tr><td>"+resp.json()["content"]["next"][i]["vesselName"]+"</td><td>"+ resp.json()["content"]["next"][i]["voyage"]+"</td><td>ETA: </td><td>"+resp.json()["content"]["next"][i]["eta"]+"</td><td>Est POD Arrival:</td><td>"+resp.json()["content"]["next"][i]["arrival"]+"</td></tr>";
            //msg+=resp.json()["content"]["next"][i]["vesselName"] + " " + resp.json()["content"]["next"][i]["voyage"] + " ETA: " + resp.json()["content"]["next"][i]["eta"] + " Arrival: "+resp.json()["content"]["next"][i]["arrival"]+ "<br />";
          }

          msg += "</table>";
          this.dialogPlugin.show('information','Connection Information',msg,'yesno',{yes:'this.setNextVesselVoyage()',no:''});

        }
      });
    }else{
      this.saveEvent();
    }
  }

  setNextVesselVoyage(){
    // console.log("** set next vessel voyage **");
    // console.log(this.tsData);

    this.cbNextVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');

    this.cbNextVessel.setValue(this.tsData["vesselId"]);

    if(this.checkBookingExceed()){
      // console.log("#1");
      var self = this;
      setTimeout(() => {
        self.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
      }, 50);
    }else{
      // console.log("#2");
      this.saveEvent();
    }
  }

  checkBookingExceed():Boolean {
    // console.log("** check booking exceeded **");
    var self = this;
    let result:Boolean = false;
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);

      if(!chk){
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
              if(element.available < 0){
                result = true;
              }

            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          /*
          if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("SOC LOCAL" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC LOCAL created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("SOC T/S" == String(element.allotmentCode) && ("SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }
          */

          /*
          if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("COC LOCAL" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC LOCAL  created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }

          if ("COC T/S" == String(element.allotmentCode) && ("COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0){
              result = true;
            }
          }
          */

          /*
          if (String(element.allotmentCode).startsWith("SS-")) {
            let str = String(element.allotmentCode).replace("SS-", '');

            if(str.trim() == String(self.model.bSlotOperatorCode).trim()) {
              // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
              strPath = element.allotmentCode + ";" + strPath;
              if(element.available < 0){
                result = true;
              }
            }

          }
          */

        }

      }
    })
    return result;
  }

  checkBookingExceedSoc():String {
    // console.log("** check booking exceeded soc **");
    var self = this;
    //let result:Boolean = false;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);
      // console.log("chk ==> " + chk)
      if(!chk){
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }
          }


        }

      }
    })
    return status;
  }

  checkBookingExceedSoclocal():String {
    // console.log("** check booking exceeded soc local **");
    var self = this;
    //let result:Boolean = false;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);
      // console.log("chk ==> " + chk)
      if(!chk){
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("SOC LOCAL" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC LOCAL created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }
          }


        }

      }
    })
    return status;
  }

  checkBookingExceedSocts():String {
    // console.log("** check booking exceeded soc ts **");
    var self = this;
    //let result:Boolean = false;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);
      // console.log("chk ==> " + chk)
      if(!chk){
        // console.log('strPath => ' + strPath)
        // console.log('element.Parent => ' + element.Parent)
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("SOC T/S" == String(element.allotmentCode) && ("SOCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation SOC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }
          }

        }

      }
    })
    return status;
  }

  checkBookingExceedCoc():String {
    // console.log("** check booking exceeded coc **");
    var self = this;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);

      if(!chk){
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }

          }

        }

      }
    })
    // console.log("status ==> " + status);
    return status;
  }

  checkBookingExceedCoclocal():String {
    // console.log("** check booking exceeded coc local **");
    var self = this;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);

      if(!chk){
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("COC LOCAL" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC LOCAL  created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }
          }


        }

      }
    })
    // console.log("status ==> " + status);
    return status;
  }

  checkBookingExceedCocts():String {
    // console.log("** check booking exceeded coc ts **");
    var self = this;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);

      if(!chk){
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("COC T/S" == String(element.allotmentCode) && ("COCTS" == String(self.model.bContainerOwnership))) {
            // console.log("** allocation COC T/S created");
            strPath = element.allotmentCode + ";" + strPath;
            if(element.available < 0 && element.alloted == 0){
              status = "1";
            }
            if(element.available < 0 && element.alloted > 0){
              status = "2";
            }
          }

        }

      }
    })

    // console.log("status ==> " + status)
    return status;
  }


  checkBookingExceedSlot():String {
    // console.log("** check booking exceeded slot **");
    var self = this;
    //let result:Boolean = false;
    let status:String = "0"
    var strPath:String = 'VS';

    $(this.grid.listStore.store).each(function(i,element) {

      let parentList:String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParentExceeded(parentList,element.allotmentCode,element.available,self.model);

      if(!chk){
        if(strPath ==  String(element.parent)){
          // console.log(element.allotmentCode + " - " + element.available + " - " + chk)

          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
              strPath = element.allotmentCode + ";" + strPath;
            }
          }

          if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
            // console.log("** allocation EMPTY created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
            // console.log("** allocation LADEN created");
            strPath = element.allotmentCode + ";" + strPath;
          }

          if (String(element.allotmentCode).startsWith("SS-")) {
            let str = String(element.allotmentCode).replace("SS-", '');

            if(str.trim() == String(self.model.bSlotOperatorCode).trim()) {
              // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
              strPath = element.allotmentCode + ";" + strPath;
              if(element.available < 0 && element.alloted == 0){
               // this.dialogPlugin.show('save','Saving data','Allocation for Operator Slot ' + this.model.bSlotOperatorCode +' is exceeded. ', 'okonly', { ok: 'this.loading = false;'});
                status = "1";
                //result = true;

              }

              if(element.available < 0 && element.alloted > 0){
                //this.dialogPlugin.show('save','Saving data','Booking exceeds allocation, do you want to continue? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
                status = "2";
                //result = true;
              }
            }

          }
        }

      }
    })
    return status;
  }

  checkFeederVoyage():Boolean {
    let result:Boolean = false;
    if(this.model.bFeederVesselCode != '' && this.model.bFeederVesselVoyage == ''){
      return true;
    }
    return result;
  }

  checkExVoyage():Boolean {
    let result:Boolean = false;
    if(this.model.bExMotherVesselCode != '' && this.model.bExMotherVesselVoyage == ''){
      return true;
    }
    return result;
  }

  checkNextVoyage():Boolean {
    let result:Boolean = false;
    console.log("Value Next Voayage before checking checkNextVoyage " + this.model.bNextMotherVesselVoyage);
    if(this.model.bNextMotherVesselCode != '' && this.model.bNextMotherVesselVoyage == ''){
      console.log("Masuk return true " + this.model.bNextMotherVesselVoyage);
      return true;
    }
    return result;
  }

  checkReeferDegree():Boolean {
    // console.log('** check reefer degree **');
    // console.log(this.bDegree);
    // console.log("this.model.bContainerOwnership => " + this.model.bContainerOwnership);

    let result:Boolean = false;

    if(String(this.bDegree).length > 5){
      this.model['error-ReeferDegreeValidator'] = 'Max degree is 5 chars.';
      result = true;
    }else{
      if(this.model.bContainerOwnership == 'COC' || this.model.bContainerOwnership == 'COCTS'){
        for(let entry of Array.from(this.bookingDetails.entries())){
          // console.log('size ' + entry[1].size);

          if(entry[0] == 'REF' && entry[1].size > 0){
            // console.log('come here');
            if(this.bDegree != null){
              /*
              // console.log('come here2');
              // console.log(this.bDegree == undefined);
              // console.log(this.bDegree == NaN);
              // console.log(this.bDegree === undefined);
              // console.log(this.bDegree === NaN);
              // console.log(String(this.bDegree));
              // console.log(String(this.bDegree) == 'NaN');
              // console.log(String(this.bDegree) === 'NaN');
              // console.log(this.bDegree == true );
              */


              if(String(this.bDegree) == 'null' || String(this.bDegree) == '' || String(this.bDegree) == 'NaN'){
                result = true;
                // console.log('come here3');
              }
            }else{
              result = true;
            }
          }
        }
      }
    }
    // console.log("result reefer degree => " + result)
    return result;
  }

  checkRotation():Boolean {
    //// console.log("** check rotation **");
    let result:Boolean = true;
    let rotationList:String[] = this.rotation.split(" - ");
    if(this.model.bPot1Code != ''){
      this.model['error-rotationValidator'] = 'Port of Transhipment 1 is not valid with rotation.';

      rotationList.forEach(element => {
        if(String(element).trim() == String(this.model.bPot1Code).trim()){
          this.model['error-rotationValidator'] = '';
          result = false;
        }
      });

    }else{
      if(this.model.bPodCode != ''){
        this.model['error-rotationValidator'] = 'Discharge Port is not valid with rotation.';

        rotationList.forEach(element => {
          if(String(element).trim() == String(this.model.bPodCode).trim()){
            this.model['error-rotationValidator'] = '';
            result = false;
          }
        });
      }else{
        this.model['error-rotationValidator'] = '';
        result = false;
      }
    }
    return result;
  }

  checkUnitContainer():Boolean {
    //// console.log("** check unit container **");
    let result:Boolean = true;

    for(let entry of Array.from(this.bookingDetails.entries())){
      if(entry[1].size > 0){
        result = false;
      }
    }
    return result;
  }

  checkUC():Boolean {
    // console.log("** check UC **");
    let result:Boolean = false;

    for(let entry of Array.from(this.bookingDetails.entries())){
      for(let entryDetail of Array.from(entry[1].entries())){
        if(entryDetail[1].bContainerType == 'UC' && entryDetail[1].bUch == 0 && entryDetail[1].bUcl == 0 && entryDetail[1].bUcw == 0 ) {
          result = true;
        }
      }
    }
    return result;
  }

  checkCoctsExceed():Boolean {
    // console.log("** check cocts exceed **");

    let result:Boolean = false;
    let availableCOC : Number = 0;
    let availableCOCTS : number = 0;
    let alloted:number = 0;

    if(this.model.bContainerOwnership == 'COCTS'){
      this.grid.listStore.store.forEach(element => {
        if (String(element.allotmentCode) == 'COC') {
          availableCOC = Number(element.available);
        }
        if (String(element.allotmentCode) == 'COC T/S') {
          availableCOCTS = Number.parseInt(element.available);
          alloted = Number.parseInt(element.alloted);
        }
      });

      /*
      if(availableCOC < 0){
        if(availableCOCTS > availableCOC){
          return true;
        }else if(availableCOCTS < 0){
          return true;
        }
      }else{
        if(availableCOCTS < 0){
          return true;
        }
      }
      */
      if(availableCOCTS < 0 && alloted > 0){
        return true;
      }
    }
    return result;
  }

  checkCocLocalExceed():Boolean {
    // console.log("** check coc local exceed **");

    let result:Boolean = true;
    let existCOCTS:Boolean = false;
    let existCOCLOCAL:Boolean = false;
    let availableCOC : number = 0;
    let availableCOCTS : number = 0;
    let availableCOCLocal : number = 0;
    let alloted : number = 0;

    // console.log(this.model.bContainerOwnership)

    if(this.model.bContainerOwnership == 'COC'){
      this.grid.listStore.store.forEach(element => {
        // console.log(element);

        if (String(element.allotmentCode) == 'COC') {
          availableCOC = Number.parseInt(element.available);
        }

        if (String(element.allotmentCode) == 'COC T/S') {
          availableCOCTS = Number.parseInt(element.available);
          existCOCTS = true;
        }

        if (String(element.allotmentCode) == 'COC LOCAL') {
          availableCOCLocal = Number.parseInt(element.available);
          alloted = Number.parseInt(element.alloted);

          existCOCLOCAL = true;
        }

      });

      if(existCOCLOCAL && existCOCTS){
        // console.log("availableCOC ==> " + availableCOC);
        // console.log("alloted ==> " + alloted);
        if(availableCOC >= 0){
          // console.log("#1")
            return false;
        }else{
          if(alloted <= 0){
            // console.log("#3")
            return false;
          }
        }
      }else{
        // console.log("#4")
        return false;
      }
    }else{
      // console.log("#5")
      return false;
    }


    return result;
  }

  checkCocLocalExceedForLabel(): Boolean {
    // console.log("** check coc local exceed for label **");

    let result: Boolean = true;
    let availableCOC : Number = 0;
    let availableCOCTS : Number = 0;
    let availableCOCLocal : Number = 0;

    // console.log(this.model.bContainerOwnership)

    if(this.model.bContainerOwnership == 'COC'){
      this.grid.listStore.store.forEach(element => {
        // console.log(element);

        if (String(element.allotmentCode) == 'COC') {
          availableCOC = Number(element.available);
        }

        if (String(element.allotmentCode) == 'COC T/S') {
          availableCOCTS = Number(element.available);
        }

        if (String(element.allotmentCode) == 'COC LOCAL') {
          availableCOCLocal = Number(element.available);
        }

      });

      if(availableCOC >= 0 && availableCOCTS > 0 && availableCOCLocal < 0){
          return false;
      }
    }
    return result;
  }

  checkBookingPartyValidator():Boolean {
    // console.log("** check booking party validator **");
    // console.log(this.isSocBookingParty);

    let result:Boolean = false;

    if(this.isSocBookingParty == true && ("SOC" == String(this.model.bContainerOwnership) || "SOCTS" == String(this.model.bContainerOwnership))){
      result = false;
    }else if(this.isCocBookingParty == true && ("COC" == String(this.model.bContainerOwnership) || "COCTS" == String(this.model.bContainerOwnership))){
      result = false;
    }else if(this.isSlotBookingParty == true && "SLOT" == String(this.model.bContainerOwnership)){
      result = false;
    }else{
      result = true;
    }

    return result;
  }
  checkAllocationValidator():Boolean {
    // console.log("** check allocation validator **");
    var self = this;
    let result:Boolean = false;

    // console.log("size grid allocation = " + this.grid.listStore.store.length);
    if(this.grid.listStore.store.length == 0){
      // console.log("#1");
      this.model['error-allocationValidator']='Allocation has not been set for this vessel, unable to create booking.';
      result = true;

    //check structure allocation

    }else if(this.checkAllocationTest("1",this.grid.listStore.store) == true){
      // console.log("#2");
      result = true;

    }else{
      // console.log("#3");
      //var pattern = this.getPattern();
      //// console.log("pattern : " + pattern);

      var defaultBookedTemp = '';
      this.grid.listStore.store.forEach(element => {
        if(element.allotmentCode != 'VS'){
          defaultBookedTemp = defaultBookedTemp + element.booked + ";"
        }
      });


      this.model['error-allocationValidator'] = '';
      var msg:String = 'Allocation of ';
      var map = new Map();
      var lastPath:String = '';
      var strPath:String = 'VS';
      $(this.grid.listStore.store).each(function(i,element) {

        let parentList:String[] = String(element.parent).trim().split(";");
        let chk:Boolean = self.checkParent(parentList,element.allotmentCode,self.model);

        if(chk){


          if(strPath ==  String(element.parent)){

            if (String(element.allotmentCode).startsWith("PD-")) {
              let str = String(element.allotmentCode).replace("PD-", '');
              if( (str.trim() == String(self.model.bPodCode).trim()) || str.trim() == String(self.model.bPot1Code).trim()) {
                // console.log("** allocation SS-" + self.model.bPodCode + " created");
                strPath = element.allotmentCode + ";" + strPath;
                result = false;
              }else{

                if(String(element.allotmentCode).startsWith("PD-")){

                  // console.log("default booked : " + self.defaultBooked2);
                  // console.log("current booked : " + defaultBookedTemp);
                  if(defaultBookedTemp != self.defaultBooked2){
                  }else{
                    if(String(self.model.bPodCode) != '' && String(self.model.bPot1Code) == ''){
                      // console.log("#1")
                      if(map.get("PD") == null){
                        map.set("PD",self.model.bPodName + ' (' + self.model.bPodCode + ')');
                      }
                      result = true;
                    }else if(String(self.model.bPodCode) != '' && String(self.model.bPot1Code) != ''){
                      // console.log("#2")
                      if(map.get("PD") == null){
                        map.set("PD",self.model.bPot1Name +' (' + self.model.bPot1Code + ')');
                      }
                      result = true;
                    }
                  }

                }
              }
            }

            if ("EMPTY" == String(element.allotmentCode) && "EMPTY" == String(self.model.bContainerStatus)) {
              // console.log("** allocation EMPTY created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              if("EMPTY" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CS") == null){
                    map.set("CS",'Container Status (' + self.model.bContainerStatus + ')');
                  }
                  result = true;
                }
              }
            }

            if ("LADEN" == String(element.allotmentCode) && "FULL" == String(self.model.bContainerStatus)) {
              // console.log("** allocation LADEN created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              if("LADEN" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CS") == null){
                    map.set("CS",'Container Status (' + self.model.bContainerStatus + ')');
                  }
                  result = true;
                }
              }
            }

            if ("SOC" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership) || "SOCTS" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation SOC created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if("SOC" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if ("SOC LOCAL" == String(element.allotmentCode) && ("SOC" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation SOC LOCAL created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if("SOC LOCAL" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if ("SOC T/S" == String(element.allotmentCode) && ("SOCTS" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation SOC T/S created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if("SOC T/S" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if ("COC" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership) || "COCTS" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation COC created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if("COC" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if ("COC LOCAL" == String(element.allotmentCode) && ("COC" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation COC LOCAL  created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              //self.model['error-allocationValidator'] = self.model['error-allocationValidator'] + 'Allocation of Container Ownership (' + self.model.bContainerOwnership + ') has not been set for this vessel, unable to create booking.';
              if("COC LOCAL" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if ("COC T/S" == String(element.allotmentCode) && ("COCTS" == String(self.model.bContainerOwnership))) {
              // console.log("** allocation COC T/S created");
              strPath = element.allotmentCode + ";" + strPath;
              result = false;
            }else{
              if("COC T/S" == String(element.allotmentCode)){
                if(defaultBookedTemp != self.defaultBooked2){
                }else{
                  if(map.get("CO") == null){
                    map.set("CO",'Container Ownership (' + self.model.bContainerOwnership + ')');
                  }
                  result = true;
                }
              }
            }

            if (String(element.allotmentCode).startsWith("SS-")) {
              let str = String(element.allotmentCode).replace("SS-", '');

              if(str.trim() == String(self.model.bSlotOperatorCode).trim()) {
                // console.log("** allocation SS-" + self.model.bSlotOperatorCode + "  created");
                strPath = element.allotmentCode + ";" + strPath;
                result = false;
              }else{
                if(String(element.allotmentCode).startsWith("SS-")){
                  if(String(self.model.bSlotOperatorCode) != ''){
                    if(defaultBookedTemp != self.defaultBooked2){
                    }else{
                      if(map.get("SS") == null){
                        map.set("SS",'Slot Operator (' + self.model.bSlotOperatorCode + ')');
                      }
                      result = true;
                    }
                  }
                }
              }

            }


            // console.log('PATH : ' + strPath);
            // console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);
          }

        }else{
          //// console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);
        }
      });

    }

    // console.log('result : ' + result);
    if(result){
      // console.log('tes');
      // console.log(map)
      if (map != undefined){
        map.forEach(function (item, key, mapObj) {
          // console.log(key + " - " + item);
          msg = msg + item + ",";
        });

        msg = msg.substring(0,msg.length -1);
        msg = msg + ' has not been set for this vessel, unable to create booking.';

        // console.log("msg ==> ")
        // console.log(msg)
        this.model['error-allocationValidator'] = msg;
      }
    }

    return result;
  }

  checkPodAllocation():Boolean {
    // console.log("** check pod allocation **");
    var self = this;
    let result:Boolean = false;
    let hit:Number = 0;

    if(this.model.bPot1Code == '' && this.model.bPodCode != ''){

      $(this.grid.listStore.store).each(function(i,element) {


        let parentList:String[] = String(element.parent).trim().split(";");
        let chk:Boolean = self.checkParent(parentList,element.allotmentCode,self.model);

        if(chk){
          // console.log(element.allotmentCode + " - " + element.allotmentLevel + " - parent : " + String(element.parent).trim() + " - check Parent : " + chk);


          if (String(element.allotmentCode).startsWith("PD-")) {
            let str = String(element.allotmentCode).replace("PD-", '');
            // console.log(str + " - " + self.model.bPodCode);

            if(str.trim() == String(self.model.bPodCode).trim()){
              //result = true;
              hit = 1;
              // console.log("PD SAME ");
              return false;
            }else{
              hit = 0;
            }

          }else{
            //result = false;
            hit = 1;

          }


        }else{
          //result = false;
        }


      });

      if(hit == 0){
        this.model['error-podAllocationValidator']= "No allocation created for " + this.model.bPodName;
        result = true;
      }
      // console.log("** RESULT check pod allocation ** : " + result);

    }

    return result;
  }


  checkOperatorCode():Boolean {
    // console.log("** check operator code **");

    let result:Boolean = false;

    if(this.model.bContainerOwnership == 'SOC' || this.model.bContainerOwnership == 'SOCTS'){
      if(this.model.bSocCntrOperatorCode == ''){
        this.model['error-operatorCodeValidator'] = 'Select a valid SOC Operator Code.';
        result = true;
      }else{
        this.model['error-operatorCodeValidator'] = '';
        result = false;
      }
    }else if(this.model.bContainerOwnership == 'COC' || this.model.bContainerOwnership == 'COCTS'){
      // console.log("DEBUG OPERATOR CODE");
      // console.log(this.model.bContainerOwnership + " --- " + this.model.bCocCntrOperatorCode);
      // console.log(this.model['error-operatorCodeValidator']);

      if(this.model.bCocCntrOperatorCode == ''){
        this.model['error-operatorCodeValidator'] = 'Select a valid COC Operator Code.';
        result = true;
      }else{
        this.model['error-operatorCodeValidator'] = '';
        result = false;
      }
    }else if(this.model.bContainerOwnership == 'SLOT'){

      // console.log(this.model.bSlotOperatorCode);

      if(this.model.bSlotOperatorCode == ''){

        this.model['error-operatorCodeValidator'] = 'Select a valid SLOT Operator Code.';
        result = true;

      }else{
        // check di allocation
        // console.log("check allocation");

        let chk:Boolean = false;
        this.grid.listStore.store.forEach(element => {
          let parentList:String[] = String(element.parent).split(";");
          if(this.checkParent(parentList,element.allotmentCode,this.model)){

            if (String(element.allotmentCode).startsWith("SS-")) {
              let str = String(element.allotmentCode).replace("SS-", '');
              // console.log(str + " - " + this.model.bSlotOperatorCode);
              if(str.trim() == String(this.model.bSlotOperatorCode).trim()){
                chk = true;
              }
            }

          }

        });

        // console.log('chk : ' + chk);
        if(!chk){
          // console.log("#1");
          this.model['error-operatorCodeValidator'] = 'Allocation is not set for op code ' + this.model.bSlotOperatorCode + '.';
          result = true;
        }else{
          // console.log("#2");
          this.model['error-operatorCodeValidator'] = '';
          result = false;
        }
      }
    }
    return result;
  }

  checkParentExceeded(parentList: String[],allotmentCode: String, available: Number, masterBookings: Booking){

    let result:Boolean = false;

    if("VS" != allotmentCode) {

      $(parentList).each(function(i,parent) {

        if (parent != '') {

          if ("VS" == parent) {
            if(available < 0){
              return true;
            }
          } else if (parent.startsWith("PD") &&
            (parent.replace("PD-", '') == masterBookings.bPodCode
              || parent.replace("PD-", '') == masterBookings.bPot1Code)) {
            if(available < 0){
              return true;
            }
          } else if ("EMPTY" == parent && "EMPTY" == masterBookings.bContainerStatus) {
            if(available < 0){
              return true;
            }
          } else if ("LADEN" == parent && "FULL" == masterBookings.bContainerStatus ) {
            if(available < 0){
              return true;
            }
          } else if ("SOC" == parent && ("SOC" == masterBookings.bContainerOwnership || "SOCTS" == masterBookings.bContainerOwnership)) {
            if(available < 0){
              return true;
            }
          } else if ("SOC LOCAL" == parent && ("SOC" == masterBookings.bContainerOwnership)) {
            if(available < 0){
              return true;
            }
          } else if ("SOC T/S" == parent && ("SOCTS" == masterBookings.bContainerOwnership)) {
            if(available < 0){
              return false;
            }
          } else if ("COC" == parent && ("COC" == masterBookings.bContainerOwnership || "COCTS" == masterBookings.bContainerOwnership)) {
            // console.log("available #2 : " + available)
            if(available < 0){
              return true;
            }
          } else if ("COC LOCAL" == parent && ("COC" == masterBookings.bContainerOwnership)) {
            if(available < 0){
              return true;
            }
          } else if ("COC T/S" == parent && ("COCTS" == masterBookings.bContainerOwnership)) {
            if(available < 0){
              return true;
            }
          } else if (parent.startsWith("SS")
            && parent.replace("SS-", '') == masterBookings.bSlotOperatorCode
            && "SLOT" == masterBookings.bContainerOwnership) {
            if(available < 0){
              return true;
            }
          } else {
            return false;
          }
        }


      });

    }else{
      result = false;
    }
    //// console.log("result : " + result);
    return result;
  }

  checkParent(parentList:String[],allotmentCode:String, masterBookings:Booking){

    let result:Boolean = false;

    if("VS" != allotmentCode) {
      let hit:Number = -1;

      $(parentList).each(function(i,parent) {

        //// console.log("#parent : " + parent);

        if (parent != '') {

          if(hit == 0){
            return false;
          }

          if ("VS" == parent) {
            hit = 1;
          } else if (parent.startsWith("PD") &&
            (parent.replace("PD-", '') == masterBookings.bPodCode
              || parent.replace("PD-", '') == masterBookings.bPot1Code)) {
            //// console.log("check PD- " + parent + " - " + masterBookings.bPodCode);
            hit = 1;
          } else if ("EMPTY" == parent && "EMPTY" == masterBookings.bContainerStatus) {
            hit = 1;
          } else if ("LADEN" == parent && "FULL" == masterBookings.bContainerStatus ) {
            hit = 1;
          } else if ("SOC" == parent && ("SOC" == masterBookings.bContainerOwnership || "SOCTS" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if ("SOC LOCAL" == parent && ("SOC" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if ("SOC T/S" == parent && ("SOCTS" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if ("COC" == parent && ("COC" == masterBookings.bContainerOwnership || "COCTS" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if ("COC LOCAL" == parent && ("COC" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if ("COC T/S" == parent && ("COCTS" == masterBookings.bContainerOwnership)) {
            hit = 1;
          } else if (parent.startsWith("SS")
            && parent.replace("SS-", '') == masterBookings.bSlotOperatorCode
            && "SLOT" == masterBookings.bContainerOwnership) {
            hit = 1;
          } else {
            hit = 0;
          }
        }


      });

      if(hit == 1){
        result = true;
      }
    }else{
      result = true;
    }
    //// console.log("result : " + result);
    return result;
  }

  handleDelete() {
    // console.log('** delete event **');
    var self = this;
    setTimeout(function() {
      self.dialogPlugin.show('save','Deleting data','Do you want to delete the record? ', 'yesno', { yes: 'this.deleteProcess()', no: 'this.loading = false;'});
    }, 50);

  }

  deleteProcess(){
    this.genericUtil.showLoader();
    this.model.bStatus = 'D';
    this.model.bDeletedUserid = this.cookieService.getName();
    this.model.bDeletedDate = this.genericUtil.getPSIDate();

    this.model.bModifiedUserid = this.cookieService.getName();
    this.model.bModifiedDate = this.genericUtil.getPSIDate();

    this.genericService.POST(this.configService.config.BASE_API.toString() + "/MasterBookings/delete", this.model).subscribe((resp) => {
      let data = resp.json();
      this.genericUtil.hideLoader();
      this.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
      this.dialogPlugin.show('information','Information', 'Booking deleted successfully.', 'okonly', { ok: 'this.loading=false;'});

    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });
  }


  saveEvent() {
    // console.log('** save event **');
    var self = this;
    setTimeout(function() {
      self.dialogPlugin.show('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveProcess()', no: 'this.loading = false;'});
    }, 50);

  }

  saveProcess(){
    this.genericUtil.showLoader();
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCGenericAPI/freightingFromBooking',this.model).subscribe((refreightResp) =>{
      // console.log("** refreight when save booking **")
      //// console.log(refreightResp.json());
      if(refreightResp.json()['status'] == 'OK'){

        this.model.bIsFinished = 'Y'
        let refreightList:any[] = refreightResp.json()['content'];
        let no:number = 1
        this.model.bookingFreightCharges = [];
        refreightList.forEach(element => {

          let bfr = new BookingFreightCharge;
          bfr.bOfficeCode = this.model.bOfficeCode;
          bfr.bPrefix = this.model.bPrefix;
          bfr.bNo = this.model.bNo;
          bfr.bFrtSeqNo = no++;
          bfr.bContainerType = element.containerType;
          bfr.bChargeCode = element.chargeCode;
          bfr.bPerCode = element.perCode;
          bfr.bCurrencyCode = element.currencyCode;
          bfr.bFreightType = element.pc;
          bfr.bPayerCode = element.payer;
          bfr.bIsFaiGroup = element.faiGroup;

          if(element.perCode == "D20"){
            bfr.bContainerSize = '20'
            bfr.bContainerHeight = '86'
          }

          if(element.perCode == "HC20"){
            bfr.bContainerSize = '20'
            bfr.bContainerHeight = '96'
          }

          if(element.perCode == "D40"){
            bfr.bContainerSize = '40'
            bfr.bContainerHeight = '86'
          }

          if(element.perCode == "HC40"){
            bfr.bContainerSize = '40'
            bfr.bContainerHeight = '96'
          }

          if(element.perCode == "D45"){
            bfr.bContainerSize = '45'
            bfr.bContainerHeight = '86'
          }

          if(element.perCode == "HC45"){
            bfr.bContainerSize = '45'
            bfr.bContainerHeight = '96'
          }
          bfr.bRevenue = element.quantity;
          bfr.bRate = element.rate;
          bfr.bAmount = element.chargeAmount;

          //// console.log(element);
          this.model.bookingFreightCharges.push(bfr);
        });
        //this.model.bookingFreightCharges = refreightResp.json()['content'];
      }else{
        this.model.bIsFinished = 'N'
        this.model.bookingFreightCharges = [];
      }

      this.genericService.POST(this.configService.config.BASE_API.toString() + "/MasterBookings/save", this.model).subscribe((resp) => {
        let data = resp.json();
        //// console.log(data.bNo);
        //// console.log(this.model);
        this.model.bNo = data.bNo;
        this.model.bPsaReferenceNo =  data.bPsaReferenceNo;

        let msg = 'Save Successfully with booking number ' + this.model.bNo;
        if(this.model.bookingFreightCharges.length == 0){
          msg = 'Save Successfully, pricing role not found, without freight charges (' + this.model.bNo + ')'
        }
        this.genericUtil.hideLoader();
        if(this.isCopy){
          this.dialogPlugin.show('information','Information',msg, 'okonly', { ok: 'this.loading=false;this.afterSaveCopy();'});
        }else{
          if(data.bIsUnlocked == 'N'){
            this.checkLockAfterSave=true;
          }else{
            this.checkLockAfterSave=false;
          }

          this.dialogPlugin.show('information','Information',msg, 'okonly', { ok: 'this.loading=false;this.afterSave();'});
        }
      },error => {
        this.loading = false;
        // console.log(error.message);
        this.info = error.message;
      });


    });

  }

  afterSave(){
    if(this.checkLockAfterSave){
      this.appStatusLabel = "-- LOCKED --";
      this.disableToolbarButtons = 'retrieve,new,modify,save,copy,cancel,print,delete';
      this.isInit = true;
      this.lockEdit = true;
      this.lock=true;
    }else {
      this.info = 'save successfully';
      this.disableToolbarButtons = 'retrieve,save,copy';
      this.lock = true;
      this.lockEdit = true;
    }

    var self = this;
    console.log('POL = ' + this.model.bPolCode);
    if(this.rotation.includes(this.model.bPolCode.toString())){
      console.log('Masuk A');
      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
      this.cbLoadPort.setValue(this.model.bPolCode.toString(), function (callbackPOL) {
        console.log('---callbacak pol after get rotation fired---');
        self.model.bPolBooking = callbackPOL.locationBookingRef;
        self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
      });
    }else{
      console.log('Masuk B');
      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId  + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/{query}');
      this.cbLoadPort.setValue('');
      this.model.bPolCode = '';
    }
  }

  afterSaveCopy(){
    this.info = 'save successfully';
    this.disableToolbarButtons = 'retrieve,new,modify,print,delete';
    this.lock = false;
    this.lockTNK = false;
    this.lockEdit = true;
    this.isCopy = false;
    //this.currentContainerType = "STD";
    this.d20 = 0;
    this.d40 = 0;
    this.d45 = 0;
    this.h20 = 0;
    this.h40 = 0;
    this.h45 = 0;
    this.model.bNo = '';
    this.model.bPsaReferenceNo = '';
    this.model.bookingDetails = [];

    //reset the loading port to trigger the bloadingport prefix

    let self = this;
    this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}')
    this.cbLoadPort.setValue(this.model.bPolCode.toString(), function (callbackPOL) {
      self.model.bPolCode = callbackPOL.locationCode;
      self.model.bPolBooking = callbackPOL.locationBookingRef;
      //self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

    });


    this.bookingDetails.clear();
    let list = new Map<String,BookingDetail>();
    this.bookingDetails.set(this.currentContainerType,list);

    this.bookingContainerInfo = new BookingContainerInfo();
    this.bookingMisc = new BookingMisc();
    this.gridFreightCharges.onClear();
    this.refreightEffectiveFrom = '';
    this.refreightExpiryOn = '';
    this.refreightModifiedBy = '';
    this.refreightModifiedDate = '';
    this.refreightAccountPIC = '';
    this.refreightApprovalBy = '';
    this.refreightApprovedDate = '';
    this.gridFreightPerSummary.onClear();
    this.gridFreightCurrencySummary.onClear();
    this.gridContainerInfo.onClear();

  }

  getRotation(vesselId: String, voyage: String, bound: String, officeCode: String) {
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransfer/' + officeCode + '/' + vesselId + '/' + voyage + '/' + bound).subscribe((resp) =>{
      if (resp.json()['content'].length>0){
        this.rotation = resp.json()['content'][0]['rotation'];

        var self = this;
        console.log("insinde the get rotation");
        if(this.model.bPolCode != null && this.model.bPolCode != '' && this.model.bPolCode != undefined){
          console.log("test");
          console.log(this.rotation + " -------- " + this.model.bPolCode);
          console.log(this.rotation.includes(this.model.bPolCode.toString()));
          if(this.rotation.includes(this.model.bPolCode.toString())){
            this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
            this.cbLoadPort.setValue(this.model.bPolCode.toString(), function (callbackPOL) {
              console.log('---callbacak pol after get rotation fired---');
              self.model.bPolBooking = callbackPOL.locationBookingRef;
              self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
            });
          }else{
            this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + this.cookieService.getDefaultLocationCode() + '/' + vesselId + '/' + voyage + '/' + bound + '/{query}');
            this.cbLoadPort.setValue('');
            this.model.bPolCode = '';
          }
        }else{
          this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + this.cookieService.getDefaultLocationCode() + '/' + vesselId + '/' + voyage + '/' + bound + '/{query}');
          this.cbLoadPort.setValue('');
          this.model.bPolCode = '';
        }

      }
    });
  }

  getWeightProfiles(officeCode:String, vesselId:String, voyage:String, bound:String){
    this.gridWeightProfiles.search = officeCode + '/' + vesselId + '/' + voyage + '/' + bound;
    this.gridWeightProfiles.loadData();
    //// console.log("ke hit");
  }

  getContainerProfiles(officeCode:String, vesselId:String, voyage:String, bound:String){
    this.gridContainerProfiles.search = officeCode + '/' + vesselId + '/' + voyage + '/' + bound;
    this.gridContainerProfiles.loadData();
  }

  getAllocationAndEstimateArrivalDate(vesselId:String, voyage: String, bound: String, officeCode: String){
    this.model['allocationValidator'] = false;
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getSailSchedule/' + vesselId + '/' + voyage + '/' + bound + '/' + officeCode).subscribe((resp) =>{

      // console.log(resp.json());
      let data = resp.json();

      this.model.ETA = String(data['ETA']).trim().replace(/\//gi,"-");
      // console.log('ETA : ' +  this.model.ETA);

      this.model.ETD = String(data['ETD']).replace(/\//g,"-");
      // console.log('ETD : ' +  this.model.ETD);

      this.model.ATA = String(data['ATA']).replace(/\//g,"-");
      // console.log('ATA : ' +  this.model.ATA);

      this.model.ATD = String(data['ATD']).replace(/\//g,"-");
      // console.log('ATD : ' +  this.model.ATD);

      /*
      this.gridNew.setBody(this.model);
      this.gridNew.loadData();
      */

      //this.grid.search = vesselId + '/' + voyage + '/' + bound;
      this.grid.setBody(this.model);
      this.grid.loadData();

      //this.grid2.search = vesselId + '/' + voyage + '/' + bound;
      /*
      this.grid2.setBody(this.model);
      this.grid2.loadData();

      //this.grid3.search = vesselId + '/' + voyage + '/' + bound;
      this.grid3.setBody(this.model);
      this.grid3.loadData();

      //this.grid4.search = vesselId + '/' + voyage + '/' + bound;
      this.grid4.setBody(this.model);
      this.grid4.loadData();

      //this.grid5.search = vesselId + '/' + voyage + '/' + bound;
      this.grid5.setBody(this.model);
      this.grid5.loadData();
      */

      /*
      var self = this;
      setTimeout(function() {
        if(self.grid.listStore.store.length > 0){
          // console.log('defaultBooked : ' + self.grid.listStore.store[0]['booked']);
          self.defaultBooked = self.grid.listStore.store[0]['booked'];


          self.grid.listStore.store.forEach(element => {
            // console.log(element);
          });

        }
      }, 100);
      */

    },error => {
      // console.log(error.message);
    });
  }

  refreightProcess(){
    // console.log("** refreight process **");
    // console.log(this.model);
    this.setDetail();

    this.gridFreightCharges.listStore.store = [];
    this.gridFreightCharges.loadData();
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCGenericAPI/freightingFromBooking',this.model).subscribe((resp) =>{

      this.refreightEffectiveFrom = '';
      this.refreightExpiryOn = '';
      this.refreightModifiedBy = '';
      this.refreightModifiedDate = '';
      this.refreightAccountPIC = '';
      this.refreightApprovalBy = '';
      this.refreightApprovedDate = '';

      if(resp.json()['status'] == 'OK'){
        this.dialogPlugin.show('information','Information',resp.json()['message'], 'okonly', { ok: 'this.afterRefreight(' + JSON.stringify(resp.json()) + ')'});
      }else{
        this.dialogPlugin.show('information','Information',resp.json()['message'], 'okonly', { ok: ''});
        //this.refreightMessage = '';
        //this.refreightMessage = resp.json()['message'];
        //this.showDialog('#compareDialog');
      }

    });

  }

  afterRefreight(data:any){
    if (data['content'] != undefined) {
      this.gridFreightCharges.listStore.store = data['content'];
      this.gridFreightCharges.loadData();
    }

    if (data['content2'] != undefined) {
      this.gridFreightPerSummary.listStore.store = data['content2'];
      this.gridFreightPerSummary.loadData();
    }

    if(data['content3'] != undefined) {
      this.gridFreightCurrencySummary.listStore.store = data['content3'];
      this.gridFreightCurrencySummary.loadData();
    }

    this.refreightEffectiveFrom =  data['summary']['effectiveFrom'];
    this.refreightExpiryOn =  data['summary']['effectiveTo'];
    this.refreightModifiedBy =  data['summary']['userModified'];
    this.refreightModifiedDate =  data['summary']['dateModified'];

    this.refreightAccountPIC =  data['summary']['userPIC'];
    this.refreightApprovalBy =  data['summary']['userApproved'];
    this.refreightApprovedDate =  data['summary']['dateApproved'];


  }

  setData(data:any){
    // console.log("** setData **");
    // console.log(data);
    var self = this;

    this.model = new Booking;
    this.model.bOfficeCode = String(data.bOfficeCode).trim().toUpperCase();
    this.model.bPrefix = String(data.bPrefix).trim().toUpperCase();
    this.model.bNo = String(data.bNo).trim().toUpperCase();
    this.model.bOfficeCodeTs = String(data.bOfficeCodeTs).trim().toUpperCase();
    this.model.bPrefixTs = String(data.bPrefixTs).trim().toUpperCase();
    this.model.bNoTs = String(data.bNoTs).trim().toUpperCase();
    this.model.bOceanVesselBound = String(data.bOceanVesselBound).trim().toUpperCase();
    this.model.bTsBooking = data.bTsBooking;
    this.model.bIsSlotCommission = data.bIsSlotCommission;
    this.model.bIsReserve = data.bIsReserve;
    this.model.bCreatedUserid = data.bCreatedUserid;
    this.model.bCreatedDate = data.bCreatedDate;
    this.model.bModifiedUserid = data.bModifiedUserid;
    this.model.bModifiedDate = data.bModifiedDate;
    this.model.bContainerStatus = data.bContainerStatus;
    this.model.bIsFinished = data.bIsFinished;
    this.model.bReleaseOrderPrinted = data.bReleaseOrderPrinted;
    this.model.bIsTransmittedToPsa = data.bIsTransmittedToPsa;
    this.model.bTransmitToSds =  data.bTransmitToSds;

    //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
    this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselCode={query}');
    this.cbVessel.setValue(data.bOceanVesselCode,function (callbackVessel) {
      // console.log("** callback after vessel **");
      // console.log(callbackVessel);

      //self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');
      self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

      self.model.bOceanVesselId = callbackVessel.vesselId;
      self.model.bOceanVesselCode = String(callbackVessel.vesselCode).trim();
      self.model.bOceanVesselName =  callbackVessel.vesselName;

      //self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bOceanVesselId+"/"+ data.bOceanVesselBound+"/{query}/" + data.bOfficeCode);
      self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + data.bOfficeCode + "/" + data.bOceanVesselId + "/" + data.bOceanVesselBound  + "/voyage={query}");
      self.cbVoyage.setValue(data.bOceanVesselVoyage, function (callbackVoyage) {
        // console.log("** callback after voyage **");
        // console.log(callbackVoyage);
        self.model.bOceanVesselVoyage = callbackVoyage.voyage;
        self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + data.bOfficeCode + "/" + data.bOceanVesselId + "/" + data.bOceanVesselBound  + "/voyage_like={query}");

        self.serviceCode = callbackVoyage.serviceCode;
        console.log('fired before get rotation ');
        console.log(self.model.bPot1Code);
        console.log(self.model.bNextMotherVesselVoyage);
        console.log(self.bufferNextVoyage);
        self.getRotation(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
        console.log('fired after get rotation ');
        console.log(self.model.bPot1Code);
        console.log(self.model.bNextMotherVesselVoyage);
        console.log(self.bufferNextVoyage);
        self.getAllocationAndEstimateArrivalDate(self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound,self.model.bOfficeCode);
        self.getWeightProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);
        self.getContainerProfiles(self.model.bOfficeCode,self.model.bOceanVesselId,self.model.bOceanVesselVoyage,self.model.bOceanVesselBound);

        //self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
        self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
        // console.log("POT 1 : " + data.bPot1Code);
        self.cbPOT1.setValue(data.bPot1Code, function (callbackPOT1) {
          self.model.bPot1Code = data.bPot1Code;
          self.model.bPot1Name = callbackPOT1.locationName;

          self.cbPOT1Terminal.setValue(data.bPot1TerminalCode);

          self.cbPOT1.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');

          console.log('fire from set data ');
          console.log(self.model.bPot1Code);
          console.log(self.model.bNextMotherVesselVoyage);
        });

        if(data.bPot1Code != ''){
          self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}')
        }else{
          self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/locationCode={query}');
          //self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
        }
        // console.log("Discharge Port : " + data.bPodCode);
        self.cbDischargePort.setValue(data.bPodCode, function (callbackPOD) {
          // console.log("** callback POD **");
          // console.log(callbackPOD);
          self.model.bPodCode = data.bPodCode;
          self.model.bPodName = callbackPOD.locationName;

          if(data.bPot1Code != ''){
            self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}')
          }else{
            self.cbDischargePort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');
          }

          // console.log('POD Terminal : ' + data.bPodTerminalCode);
          self.cbDischargePortTerminal.setValue(data.bPodTerminalCode);
        });
      });
    });

    // console.log("Booking Party : " + data.bBookingPartyCode);

    this.cbBookingParty.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerId={query}')
    this.cbBookingParty.setValue(data.bBookingPartyCode,function (callbackBookingParty) {
      // console.log("** callback after booking party **");

      self.model.bBookingPartyCode = data.bBookingPartyCode;

      if(callbackBookingParty.isNvocc == 'Y'){
        self.nvoccBookingParty = true;
      }else{
        self.nvoccBookingParty = false;
      }

      if(callbackBookingParty.isSoc == 'Y'){
        self.isSocBookingParty = true;
      }else{
        self.isSocBookingParty = false;
      }

      if(callbackBookingParty.isCoc == 'Y'){
        self.isCocBookingParty = true;
      }else{
        self.isCocBookingParty = false;
      }

      if(callbackBookingParty.isSlot == 'Y'){
        self.isSlotBookingParty = true;
      }else{
        self.isSlotBookingParty = false;
      }

      // console.log("Shipper : " + data.bShipperCode);
      self.cbShipper.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerId={query}');
      self.cbShipper.setValue(data.bShipperCode, function(callbackShipper){
        self.model.bShipperCode = callbackShipper.customerId;
        if(callbackShipper.isNvocc == 'Y'){
          self.nvoccShipper = true;
        }else{
          self.nvoccShipper = false;
        }
        self.cbShipper.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlShipper/customerName={query}');
      });

      // console.log("Freight Party : " + data.bFreightPartyCode);
      self.cbFreightParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerId={query}');
      self.cbFreightParty.setValue(data.bFreightPartyCode, function(callbackFreightParty){
        self.model.bFreightPartyCode = callbackFreightParty.customerId;
        if(callbackFreightParty.isNvocc == 'Y'){
          self.nvoccFreightParty = true;
        }else{
          self.nvoccFreightParty = false;
        }
        self.cbFreightParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlFreightParty/customerName={query}');
      });

      self.cbSocOPR.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findSocOperator/' + data.bBookingPartyCode);
      self.cbSocOPR.loadSelect();

      self.model.bSocCntrOperatorCode = data.bSocCntrOperatorCode;

      // console.log("data.bSocCntrOperatorCode : " + data.bSocCntrOperatorCode);
      self.cbSocOPR.setValue(data.bSocCntrOperatorCode);

      self.cbCocOPR.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findCocOperator/' + data.bBookingPartyCode);
      self.cbCocOPR.loadSelect();
      self.cbCocOPR.setValue(data.bCocCntrOperatorCode);


      self.cbSlotOPR.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findSlotOperator/' + data.bBookingPartyCode);
      self.cbSlotOPR.loadSelect();

      // console.log("data.bSlotOperatorCode : " + data.bSlotOperatorCode);
      self.cbSlotOPR.setValue(data.bSlotOperatorCode);

      self.model.bOutwardSlotOperatorCode = data.bOutwardSlotOperatorCode;

      self.cbBookingParty.setUrl(self.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlBookingParty/customerName={query}');
    });

    // console.log("Place of Receipt : " + data.bPorCode);
    this.cbPOR.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbPOR.setValue(data.bPorCode, function (callbackPOR) {
      self.model.bPorCode = data.bPorCode;
      self.cbPOR.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      // console.log('POR Terminal : ' + data.bPorTerminalCode);
      self.cbPORTerminal.setValue(data.bPorTerminalCode);
    });


    // console.log("Portnet Ref : " + data.bPsaReferenceNo);
    this.model.bPsaReferenceNo = data.bPsaReferenceNo;

    // console.log("Load Port : " + data.bPolCode);
    this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbLoadPort.setValue(data.bPolCode, function (callbackPOL) {
      self.model.bPolCode = data.bPolCode;

      self.model.bPolBooking = data.bPolBooking;

      // console.log("** POL Booking ** => " + self.model.bPolBooking);
      //self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbLoadPort.setUrl(self.configService.config.BASE_API.toString() + '/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails2/' + self.cookieService.getDefaultLocationCode() + '/' + self.model.bOceanVesselId + '/' + self.model.bOceanVesselVoyage + '/' + self.model.bOceanVesselBound + '/{query}');


      // console.log('POL Terminal : ' + data.bPolTerminalCode);
      self.cbLoadPortTerminal.setValue(data.bPolTerminalCode);
    });

    // console.log("Place of Delivery : " + data.bPodlCode);
    this.cbPODL.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbPODL.setValue(data.bPodlCode, function (callbackPODL) {
      self.model.bPodlCode = data.bPodlCode;
      self.cbPODL.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbPODLTerminal.setValue(data.bPodlTerminalCode);
    });


    // console.log("Final Destination : " + data.bFdestCode);
    if(data.bFdestCode == ''){
      this.cbFinalDest.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
    }else{
      this.cbFinalDest.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    }
    this.cbFinalDest.setValue(data.bFdestCode, function (callbackFDest) {
      self.model.bFdestCode = data.bFdestCode;
      self.cbFinalDest.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbFinalDestTerminal.setValue(data.bFdestTerminalCode);
    });



    // console.log("Special Detail : " + data.bSpecialDetailCode);

    this.cbSpecialDet.setUrl(this.configService.config.BASE_API.toString() + '/MasterSpecialDetails/findByComboBoxControl/specialDetailId={query}');
    this.cbSpecialDet.setValue(data.bSpecialDetailCode);


    // console.log("POT 2 : " + data.bPot2Code);
    this.cbPOT2.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbPOT2.setValue(data.bPot2Code, function (callbackPOT2) {
      self.model.bPot2Code = data.bPot2Code;
      self.cbPOT2.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbPOT2Terminal.setValue(data.bPot2TerminalCode);
    });

    // console.log("POT 3 : " + data.bPot3Code);
    this.cbPOT3.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbPOT3.setValue(data.bPot3Code, function (callbackPOT3) {
      self.model.bPot3Code = data.bPot2Code;
      self.cbPOT3.setUrl(self.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
      self.cbPOT3Terminal.setValue(data.bPot3TerminalCode);
    });

    // console.log("Commodity : " + data.bCommodityCode);
    this.cbCommodity.setUrl(this.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityId={query}');
    this.cbCommodity.setValue(data.bCommodityCode,function (callbackCommodity) {
      self.cbCommodity.setUrl(self.configService.config.BASE_API.toString() + '/MasterCommodities/findByComboBoxControl/commodityName={query}');
    });



    // console.log("Rec Type : " + data.bReceiptType);
    this.cbReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
    this.cbReceiptType.setValue(data.bReceiptType);
    // console.log("Delv Type : " + data.bDeliveryType);
    this.cbDeliveryType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}');
    this.cbDeliveryType.setValue(data.bDeliveryType);
    this.model.bFreightType = data.bFreightType;
    this.model.bIhc = data.bIhc;
    this.model.bContainerOwnership = data.bContainerOwnership;
    if(this.model.bContainerOwnership == 'SOC' || this.model.bContainerOwnership == 'SOCTS'){
      this.lockSOC = false;
      this.lockCOC = true;
      this.lockSLOT = true;
      this.lockSOCOPR = false;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = true;
    }else if(this.model.bContainerOwnership == 'COC' || this.model.bContainerOwnership == 'COCTS'){
      this.lockSOC = true;
      this.lockCOC = false;
      this.lockSLOT = true;
      this.lockSOCOPR = true;
      this.lockCOCOPR = false;
      this.lockSLOTOPR = true;
    }else if(this.model.bContainerOwnership == 'SLOT'){
      this.lockSOC = true;
      this.lockCOC = true;
      this.lockSLOT = false;
      this.lockSOCOPR = true;
      this.lockCOCOPR = true;
      this.lockSLOTOPR = false;
    }

    this.model.bShipmentType = data.bShipmentType;
    this.model.bDgClass = data.bDgClass;
    this.model.bWeightClass = data.bWeightClass;
    this.model.bWeight = data.bWeight;
    this.model.bRemarks = data.bRemarks;
    this.model.bContactPerson = data.bContactPerson;
    this.model.bTelephone = data.bTelephone;
    this.model.bSocCntrOperatorCode = data.bSocCntrOperatorCode;
    this.model.bSocOperatorCode = data.bSocOperatorCode;
    this.model.bCocCntrOperatorCode = data.bCocCntrOperatorCode;
    this.model.bCocOperatorCode = data.bCocOperatorCode;
    this.model.bOutwardSlotOperatorCode = data.bOutwardSlotOperatorCode;
    this.model.bSlotOperatorCode = data.bSlotOperatorCode;

    //Booking Note 2
    this.cbFeederVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/*/{query}/1/100/*/*');
    this.cbFeederVessel.setValue(data.bFeederVesselCode,function (callbackVesselFeeder) {
      // console.log("** callback after feeder vessel **");
      // console.log(callbackVesselFeeder);

      self.cbFeederVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*');

      self.model.bFeederVesselId = callbackVesselFeeder.vesselId;
      self.model.bFeederVesselCode = callbackVesselFeeder.vesselCode;

      self.cbFeederVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bFeederVesselId+"/"+ data.bFeederVesselBound+"/{query}/" + data.bOfficeCode);
      self.cbFeederVoyage.setValue(data.bFeederVesselVoyage);
    });

    this.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerId={query}');
    this.cbSupplier.setValue(data.bSupplierCode,(callbacksupplier) => {
      self.cbSupplier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlSupplier/customerName={query}');
    });

    // console.log("data.bExMotherVesselId => " + data.bExMotherVesselId)
    if(data.bExMotherVesselId != null && data.bExMotherVesselId != '' && data.bExMotherVesselId != undefined){
      this.cbExVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
      this.cbExVessel.setValue(data.bExMotherVesselId,function (callbackVesselEx) {
        // console.log("** callback after ex vessel **");

        self.cbExVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselNameAndPSAVesselCode={query}');

        self.model.bExMotherVesselId = callbackVesselEx.vesselId;
        self.model.bExMotherVesselCode = callbackVesselEx.vesselCode;
        self.model.bExMotherVesselVoyage = data.bExMotherVesselVoyage;

        //self.cbExVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bExMotherVesselId+"/"+ data.bExMotherVesselBound+"/{query}/" + data.bOfficeCode);
        //self.cbExVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+callbackVesselEx.vesselId+"/"+self.model.bOceanVesselBound+"/{query}/" + self.cookieService.getDefaultLocationCode());
        //self.cbExVoyage.setValue(data.bExMotherVesselVoyage);
      });
    }else{
      this.cbExVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselNameAndPSAVesselCode={query}');
    }

     console.log("data.bNextMotherVesselId => " + data.bNextMotherVesselId);
     console.log("data.bNextMotherVesselVoyage => " + data.bNextMotherVesselVoyage);

    if(data.bNextMotherVesselId != null && data.bNextMotherVesselId != '' && data.bNextMotherVesselId != undefined) {
      this.cbNextVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselId={query}');
      this.cbNextVessel.setValue(data.bNextMotherVesselId, function (callbackVesselNext) {
        // console.log("** callback after next vessel **");

        self.cbNextVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');


        //self.cbNextVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+ data.bNextMotherVesselId+"/"+ data.bNextMotherVesselBound+"/{query}/" + data.bOfficeCode);
        self.cbNextVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/" + callbackVesselNext.vesselId + "/" + self.model.bOceanVesselBound + "/{query}/" + self.model.bPot1Code);
        self.cbNextVoyage.setValue(data.bNextMotherVesselVoyage);
        console.log('after set data next voyage');
        console.log('vessel ->' + data.bNextMotherVesselId);
        console.log('voyage ->' + data.bNextMotherVesselVoyage);

        self.model.bNextMotherVesselId = data.bNextMotherVesselId;
        self.model.bNextMotherVesselCode = data.bNextMotherVesselCode;
        self.model.bNextMotherVesselVoyage = data.bNextMotherVesselVoyage;
      });
    }else{
      this.cbNextVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}')
    }

    console.log("** bNextMotherVesselVoyage ==> ");
    console.log(this.model)

    if(String(data.bRequestReleaseDate) != ''){
      this.model.bRequestReleaseDate = String(data.bRequestReleaseDate)+ ':00';
      //this.dtRequestReleaseDate.dateP.nativeElement.value= this.model.bRequestReleaseDate;
    }

    if(String(data.bExpectedReturnDate) != ''){
      this.model.bExpectedReturnDate = String(data.bExpectedReturnDate)+ ':00';
      //this.dtExpReturnDate.dateP.nativeElement.value= this.model.bExpectedReturnDate;
    }

    if(String(data.bReeferCollectionDate) != ''){
      this.model.bReeferCollectionDate = String(data.bReeferCollectionDate)+ ':00';
      //this.dtReeferCollection.dateP.nativeElement.value= this.model.bReeferCollectionDate;
    }

    //this.cbHaulier.setValue(data.bHaulierCode);
    //this.cbReturnTo.setValue(data.bReturnToCode);
    //this.cbReleaseAt.setValue(data.bReleaseAtCode);
    this.model.bHaulierContact = data.bHaulierContact;
    //this.cbPackedAt.setValue(data.bPackedAtCode);
    this.model.bHaulierContactNo = data.bHaulierContactNo;

    this.model.bWt = data.bWt;
    this.model.bMeasure = data.bMeasure;
    this.model.bPackageNo = data.bPackageNo;
    this.cbPackageType.setValue(data.bPackageTypeCode);

    this.bDegreeTemp = String(data.bReeferDegree).substring(String(data.bReeferDegree).length + 1,String(data.bReeferDegree).length-1);
    this.bDegree = Number.parseInt(String(data.bReeferDegree).replace(this.bDegreeTemp.toString(),''));
    this.model.bReeferDegree = String(this.bDegree) + this.bDegreeTemp;
    this.model.bIsDry = data.bIsDry;
    this.model.bVent = data.bVent;
    this.model.bDetention = data.bDetention;
    if(this.model.bDetention == 'R'){
      this.lockDetention = false;
    }else{
      this.lockDetention = true;
    }
    this.model.bDetentionRequest = data.bDetentionRequest;
    this.model.bDemurrage = data.bDemurrage;
    if(this.model.bDemurrage == 'R'){
      this.lockDemurrage = false;
    }else{
      this.lockDemurrage = true;
    }
    this.model.bDemurrageRequest = data.bDemurrageRequest;

    if(data.masterBookingDetails != null){

      this.bookingDetails.clear();

      this.currentContainerType = '';

      data.masterBookingDetails.forEach(element => {
        this.killSlot = element.bKillslot;
        this.doorOpen = element.bDoorOpen;
        this.UCH = element.bUch;
        this.UCL = element.bUcl;
        this.UCW = element.bUcw;
        this.OH = element.bOverHeight;
        if(this.OH == 'Y'){
          this.lockOH = false;
        }else{
          this.lockOH = true;
        }
        this.OL = element.bOverLength;
        if(this.OL == 'Y'){
          this.lockOL = false;
        }else{
          this.lockOL = true;
        }
        this.OW = element.bOverWidth;
        if(this.OW == 'Y'){
          this.lockOW = false;
        }else{
          this.lockOW = true;
        }
        this.heightMeasure =  element.bOverHeightCm;
        this.leftMeasure = element.bOverWidthLeftCm;
        this.rightMeasure = element.bOverWidthRightCm;
        this.frontMeasure = element.bOverLengthFrontCm;
        this.endMeasure = element.bOverLengthBackCm;

        if(this.bookingDetails.get(element.bContainerType) == null){
          let list = new Map<String,BookingDetail>();
          list.set(element.bContainerSize + element.bContainerHeight,element);
          this.bookingDetails.set(element.bContainerType,list);
        }else{
          this.bookingDetails.get(element.bContainerType).set(element.bContainerSize + element.bContainerHeight,element);
        }

        if(this.model.bContainerOwnership == 'SLOT'){
          // console.log("Size : " + this.bookingDetails.size);
          if(this.bookingDetails.size > 1){
            if(this.bookingDetails.get("STD") != null){
              this.currentContainerType = 'STD';
            }else if(this.bookingDetails.get("REF") != null){
              this.currentContainerType = 'REF';
            }else if(this.bookingDetails.get("TNK") != null){
              this.currentContainerType = 'TNK';
            }else if(this.bookingDetails.get("DRY") != null){
              this.currentContainerType = 'DRY';
            }
          }else{
            this.currentContainerType = element.bContainerType;
          }
          // console.log("this.currentContainerType : " + this.currentContainerType);
        }else{
          this.currentContainerType = element.bContainerType;
        }

      });

      for(let det of Array.from(this.bookingDetails.get(this.currentContainerType)) ) {
        switch (det[0]) {
          case '2086':
            this.d20 = det[1].bQuantity;
            break;
          case '4086':
            this.d40 = det[1].bQuantity;
            break;
          case '4586':
            this.d45 = det[1].bQuantity;
            break;
          case '2096':
            this.h20 = det[1].bQuantity;
            break;
          case '4096':
            this.h40 = det[1].bQuantity;
            break;
          case '4596':
            this.h45 = det[1].bQuantity;
            break;
          default:
            break;
        }
      }

    }

    // Container info
    if(data.masterBookingContainerInformations != null && data.masterBookingContainerInformations.length > 0 ) {
      this.gridContainerInfo.url = '';
      this.gridContainerInfo.onClear();
      for(let i=0; i<data.masterBookingContainerInformations.length; i++){
        this.model.bookingContainerInfo.push(data.masterBookingContainerInformations[i]);
        this.gridContainerInfo.listStore.store.push(data.masterBookingContainerInformations[i]);
      }
      this.gridContainerInfo.loadData();
    }

    //misc
    if(data.masterBookingMisc != null ) {
      console.log("before kitchen cat");
      console.log(data.masterBookingMisc);
      this.bookingMisc = data.masterBookingMisc;

      //set combo
      // 1. Hauiler if exist
      if(this.bookingMisc.bHaulierId != null && this.bookingMisc.bHaulierId != '' && this.bookingMisc.bHaulierId != undefined){
        this.cbHaulier.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findByComboBoxControlHaulier/customerId={query}');
        this.cbHaulier.setValue(this.bookingMisc.bHaulierId.toString());
      }

      // 2. Release date
      if(this.bookingMisc.bReleaseAtDate != null && this.bookingMisc.bReleaseAtDate != '' && this.bookingMisc.bReleaseAtDate != undefined){
        this.dateReleaseAt.setValue(this.bookingMisc.bReleaseAtDate.toString());
      }


      // 3. Return to (por)
      if(this.bookingMisc.bReturnToPorGateInDate != null && this.bookingMisc.bReturnToPorGateInDate != '' && this.bookingMisc.bReturnToPorGateInDate != undefined){
        this.dateReturnToPor.setValue(this.bookingMisc.bReturnToPorGateInDate.toString());
      }
      if(this.bookingMisc.bReturnToPorClosingDate != null && this.bookingMisc.bReturnToPorClosingDate != '' && this.bookingMisc.bReturnToPorClosingDate != undefined){
        this.dateReturnToPorGateInClosing.setValue(this.bookingMisc.bReturnToPorClosingDate.toString());
      }
      if(this.bookingMisc.bReturnToPorVgmDate != null && this.bookingMisc.bReturnToPorVgmDate != '' && this.bookingMisc.bReturnToPorVgmDate != undefined){
        this.dateReturnToPorVgmClosing.setValue(this.bookingMisc.bReturnToPorVgmDate.toString());
      }

      // 4. Return to (pol)
      if(this.bookingMisc.bReturnToPolGateInDate != null && this.bookingMisc.bReturnToPolGateInDate != '' && this.bookingMisc.bReturnToPolGateInDate != undefined){
        this.dateReturnToPol.setValue(this.bookingMisc.bReturnToPolGateInDate.toString());
      }
      if(this.bookingMisc.bReturnToPolClosingDate != null && this.bookingMisc.bReturnToPolClosingDate != '' && this.bookingMisc.bReturnToPolClosingDate != undefined){
        this.dateReturnToPolGateInClosing.setValue(this.bookingMisc.bReturnToPolClosingDate.toString());
      }
      if(this.bookingMisc.bReturnToPolVgmDate != null && this.bookingMisc.bReturnToPolVgmDate != '' && this.bookingMisc.bReturnToPolVgmDate != undefined){
        this.dateReturnToPolVgmClosing.setValue(this.bookingMisc.bReturnToPolVgmDate.toString());
      }

      // 5. Stuff at
      if(this.bookingMisc.bStuffAtPorGateInDate != null && this.bookingMisc.bStuffAtPorGateInDate != '' && this.bookingMisc.bStuffAtPorGateInDate != undefined){
        this.dateStuffAtPor.setValue(this.bookingMisc.bStuffAtPorGateInDate.toString());
      }
      if(this.bookingMisc.bStuffAtPorClosingDate != null && this.bookingMisc.bStuffAtPorClosingDate != '' && this.bookingMisc.bStuffAtPorClosingDate != undefined){
        this.dateStuffAtPorGateInClosing.setValue(this.bookingMisc.bStuffAtPorClosingDate.toString());
      }
      if(this.bookingMisc.bStuffAtPorVgmDate != null && this.bookingMisc.bStuffAtPorVgmDate != '' && this.bookingMisc.bStuffAtPorVgmDate != undefined){
        this.dateStuffAtPorVgmClosing.setValue(this.bookingMisc.bStuffAtPorVgmDate.toString());
      }


      if(this.bookingMisc.bReleaseAt != null && this.bookingMisc.bReleaseAt != '' && this.bookingMisc.bReleaseAt != undefined){
        this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReleaseAt.setValue(this.bookingMisc.bReleaseAt.toString());
      }
      if(this.bookingMisc.bReturnToPor != null && this.bookingMisc.bReturnToPor != '' && this.bookingMisc.bReturnToPor != undefined){
        this.cbReturnToPor.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReturnToPor.setValue(this.bookingMisc.bReturnToPor.toString());
      }
      if(this.bookingMisc.bReturnToPol != null && this.bookingMisc.bReturnToPol != '' && this.bookingMisc.bReturnToPol != undefined){
        this.cbReturnToPol.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbReturnToPol.setValue(this.bookingMisc.bReturnToPol.toString());
      }
      if(this.bookingMisc.bStuffAtPor != null && this.bookingMisc.bStuffAtPor != '' && this.bookingMisc.bStuffAtPor != undefined){
        this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
        this.cbStuffAt.setValue(this.bookingMisc.bStuffAtPor.toString());
      }


      if(this.bookingMisc.bReceiptTypeExtra != null && this.bookingMisc.bReceiptTypeExtra != '' && this.bookingMisc.bReceiptTypeExtra != undefined){
        console.log("Debug get summary");
        console.log(this.getDetailSummary() );
        if(this.getDetailSummary() == '' ) {
          this.cbReceiptTypeExtra.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
          this.cbReceiptTypeExtra.setValue(this.bookingMisc.bReceiptTypeExtra.toString());
        }else{
          this.populateReceiptTypeExtra();
          this.cbReceiptTypeExtra.setForceValue(this.bookingMisc.bReceiptTypeExtra.toString());
        }

      }


      console.log('kitchen cat ');
      console.log(this.bookingMisc);


      if(data.masterBookingFreightCharges != null && data.masterBookingFreightCharges.length > 0 ) {
        this.gridFreightCharges.url='';
        this.gridFreightCharges.onClear();

        for(let i=0; i<data.masterBookingFreightCharges.length; i++){
          this.model.bookingFreightCharges.push(data.masterBookingFreightCharges[i]);
          this.gridFreightCharges.listStore.store.push(data.masterBookingFreightCharges[i]);
        }
        this.gridFreightCharges.loadData();

      }

    }

  }


  getPattern():String{
    var result:String = '';
    var self = this;
    $(this.grid.listStore.store).each(function(i,element) {

      let parentList: String[] = String(element.parent).trim().split(";");
      let chk:Boolean = self.checkParent(parentList,element.allotmentCode,self.model);
      if(chk){
        var s = ";" + element.allotmentCode + ";" + element.parent;
        s = s.replace(/;VS/g,'');

        if(result.length <= s.length){
          result = s;
        }
      }

    });


    return result;
  }



  //Used to check bound M whether existed in the sailing schedule or not
  checkBoundM(){
    // console.log("** check bound M **")
    let hitUrl = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/checkDuplicateBoundMFromBooking/" + this.model.bOfficeCode + "/" + this.model.bExMotherVesselId + "/" + this.model.bExMotherVesselVoyage.toUpperCase() + "/M" ;
    this.genericService.GET(hitUrl).subscribe((resp) => {
      // console.log(resp.json()["status"])
      if(resp.json()["status"].toUpperCase() == "OK"){
        //create bound M
        let msg: string = '';
        msg += "Schedule for vessel <b>" + this.model.bExMotherVesselName + " " + this.model.bExMotherVesselVoyage.toUpperCase() + "</b> is not yet registered. <br />";
        msg += "System will now register this schedule?";

        this.dialogPlugin.show('information','Information',msg, 'yesno', { yes: 'this.registerBoundM();', no:''});
      }
    });
  }

  registerBoundM(){
    let dateBoundM = this.genericUtil.getPSIDate();
    let boundMSailingSchedule:MSailingSchedule = new MSailingSchedule();
    boundMSailingSchedule.officeCode = this.model.bOfficeCode.toString();
    boundMSailingSchedule.vesselId = this.model.bExMotherVesselId.toString();
    boundMSailingSchedule.vesselCode = this.model.bExMotherVesselCode.toString();
    boundMSailingSchedule.voyage = this.model.bExMotherVesselVoyage.toString().toUpperCase();
    boundMSailingSchedule.bound = "M";
    boundMSailingSchedule.nameOfMaster = '';
    boundMSailingSchedule.nationality = '';
    boundMSailingSchedule.blDate = dateBoundM;
    boundMSailingSchedule.exchangeRateDate = dateBoundM;
    boundMSailingSchedule.nonSsl = '';
    boundMSailingSchedule.localETA = dateBoundM;
    boundMSailingSchedule.localETD = dateBoundM;
    boundMSailingSchedule.gdvNumber = '';
    boundMSailingSchedule.leg = '';
    boundMSailingSchedule.callingSeq = '';
    boundMSailingSchedule.psaVoyage = '';
    boundMSailingSchedule.agent = '';
    boundMSailingSchedule.dryDocking = '';
    boundMSailingSchedule.psaTransmitFlag = '';
    boundMSailingSchedule.lockedBlDate="N";
    boundMSailingSchedule.lockedExchangeRateDate="N";
    boundMSailingSchedule.isValid="Y";
    //boundMSailingSchedule.createdUserid=this.cookieService.getName();
    //boundMSailingSchedule.createdDate=dateBoundM;
    boundMSailingSchedule.createdUserid = '';
    boundMSailingSchedule.createdDate = '';
    boundMSailingSchedule.modifiedUserid = '';
    boundMSailingSchedule.modifiedDate = '';
    boundMSailingSchedule.serviceId="SV00530";
    boundMSailingSchedule.serviceCode="OTHS";

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/saveBoundM', boundMSailingSchedule).subscribe((resp) => {
      if(resp.status == 200 && resp.json()['message'] == 'OK') {
        this.dialogPlugin.show('information','Information','Schedule has been registered successfully.', 'okonly', { ok: 'this.loading=false;'});
      }
    });

  }



  //Used to go back into vessel listing
  handleBack(){
    // console.log("** handle back **")

    //clean up unnecessar details
    localStorage.removeItem("new-booking-from-flag");

    if(this.prev == 'booking-flag'){
      // console.log("#1")
      if(this.strParams != ''){
        // console.log(this.strParams)
        localStorage.setItem("close-booking-to-flag",this.strParams);
        this.router.navigateByUrl('/main/transaction-booking-flag');
      }else{
        this.location.back();
      }
    }else {
      this.location.back();
    }

  }

  showDialog(id){
    $(id).modal('setting', 'closable', false).modal('show');
  }

  closeDialog(id){
    $(id).modal('hide');
  }

  onCompare(){
    this.setDetail();
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/PFCGenericAPI/compareRefreightBookingPFC/' + this.refreightRefNo, this.model).subscribe((resp) => {
      if(resp.status == 200 ) {
        this.dialogPlugin.show('information','Information',resp.json()['message'], 'okonly', { ok: ''});

      }
    });
  }

  onCancelCompare(){
    this.closeDialog('#compareDialog');
  }

  countChildNew(parentLevel: String, store:any){
    var total = 0;
    store.forEach(fe=>{
      if(fe.allotmentLevel.startsWith(parentLevel) && fe.allotmentLevel.split(".").length == parentLevel.split(".").length+1){
        total++
      }
    });

    return total;
  }

  getChildListNew(parentLevel: String, store:any){
    var result = [];
    store.forEach(fe=>{
      if(fe.allotmentLevel.startsWith(parentLevel) && fe.allotmentLevel.split(".").length == parentLevel.split(".").length+1){
        result.push(fe);
      }
    });

    return result;
  }

  checkAllocationTest(parent, dataStore:any){
    var result:boolean = true;
    var allocationChild = [];
    var countSOC = 0;var countCOC = 0;var countSlot = 0; var isError = '';
    var tmpPod: String = '';
    var tmpPodName: String = '';


    // console.log("this.model.bContainerOwnership => " + this.model.bContainerOwnership);
    // console.log("this.model.bContainerStatus => " + this.model.bContainerStatus);

    //if(this.countChildNew(parent, dataStore) > 0){
      allocationChild = this.getChildListNew(parent, dataStore);
      allocationChild.forEach(fe=>{
        // console.log(fe);


        if(this.model.bPodCode != '' && this.model.bPot1Code != ''){
          tmpPod = this.model.bPot1Code;
          tmpPodName = this.model.bPot1Name;
        }else{
          tmpPod = this.model.bPodCode;
          tmpPodName = this.model.bPodName;
        }

        if (fe.allotmentCode.startsWith("PD") && fe.allotmentCode.replace("PD-",'') == tmpPod) {

          // console.log("** check PD **")
          countSOC = 0;
          countCOC = 0;
          countSlot = 0;

          isError = '';
          isError = "PD";

          var childPD = [];
          childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
          childPD.forEach(fe=>{

            if (fe.allotmentCode == "LADEN" && this.model.bContainerStatus == "FULL") {
              countSOC = 0;
              countCOC = 0;
              countSlot = 0;

              isError = '';
              isError = "LADEN";

              var childLaden = [];
              childLaden = this.getChildListNew(fe.allotmentLevel, dataStore);
              childLaden.forEach(fe=>{
                // console.log(fe.allotmentCode)


                if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
                  if(fe.allotmentCode.substring(0,3) == "SOC"){
                      countSOC++;
                  }
                }

                if(this.model.bContainerOwnership.substring(0,3) == "COC"){
                  if(fe.allotmentCode.substring(0,3) == "COC"){
                      countCOC++;
                  }
                }

                if(this.model.bContainerOwnership == "SLOT"){
                  if(fe.allotmentCode.startsWith("SS")){
                      countSlot++;
                  }
                }

              });

            }

            if (fe.allotmentCode == "EMPTY" && this.model.bContainerStatus == "EMPTY") {
              countSOC = 0;
              countCOC = 0;
              countSlot = 0;

              isError = '';
              isError = "EMPTY";


              var childEmpty = [];
              childEmpty = this.getChildListNew(fe.allotmentLevel, dataStore);
              childEmpty.forEach(fe=>{

                if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
                  if(fe.allotmentCode.substring(0,3) == "SOC"){
                      countSOC++;
                  }
                }

                if(this.model.bContainerOwnership.substring(0,3) == "COC"){
                  if(fe.allotmentCode.substring(0,3) == "COC"){
                      countCOC++;
                  }
                }

                if(this.model.bContainerOwnership == "SLOT"){
                  if(fe.allotmentCode.startsWith("SS")){
                      countSlot++;
                  }
                }

              });
            }

            if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
              if(fe.allotmentCode.substring(0,3) == "SOC"){
                  countSOC++;
              }
            }

            if(this.model.bContainerOwnership.substring(0,3) == "COC"){
              if(fe.allotmentCode.substring(0,3) == "COC"){
                  countCOC++;
              }
            }

            if(this.model.bContainerOwnership == "SLOT"){
              if(fe.allotmentCode.startsWith("SS")){
                  countSlot++;
              }
            }

          });
        }else{

        }

        if (fe.allotmentCode == "LADEN" && this.model.bContainerStatus == "FULL") {
          countSOC = 0;
          countCOC = 0;
          countSlot = 0;

          isError = '';
          isError = "LADEN";

          var childLaden = [];
          childLaden = this.getChildListNew(fe.allotmentLevel, dataStore);
          childLaden.forEach(fe=>{
            // console.log(fe.allotmentCode)

            if (fe.allotmentCode.startsWith("PD") && fe.allotmentCode.replace("PD-",'') == tmpPod) {
              // console.log("** check PD **")
              countSOC = 0;
              countCOC = 0;
              countSlot = 0;

              isError = '';
              isError = "PD";

              var childPD = [];
              childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
              childPD.forEach(fe=>{
                if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
                  if(fe.allotmentCode.substring(0,3) == "SOC"){
                      countSOC++;
                  }
                }

                if(this.model.bContainerOwnership.substring(0,3) == "COC"){
                  if(fe.allotmentCode.substring(0,3) == "COC"){
                      countCOC++;
                  }
                }

                if(this.model.bContainerOwnership == "SLOT"){
                  if(fe.allotmentCode.startsWith("SS")){
                      countSlot++;
                  }
                }

              });
            }


            if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
              if(fe.allotmentCode.substring(0,3) == "SOC"){
                  countSOC++;
              }
            }

            if(this.model.bContainerOwnership.substring(0,3) == "COC"){
              if(fe.allotmentCode.substring(0,3) == "COC"){
                  countCOC++;
              }
            }

            if(this.model.bContainerOwnership == "SLOT"){
              if(fe.allotmentCode.startsWith("SS")){
                  countSlot++;
              }
            }


          });
        }

        if (fe.allotmentCode == "EMPTY" && this.model.bContainerStatus == "EMPTY") {
          countSOC = 0;
          countCOC = 0;
          countSlot = 0;

          isError = '';
          isError = "EMPTY";


          var childEmpty = [];
          childEmpty = this.getChildListNew(fe.allotmentLevel, dataStore);
          childEmpty.forEach(fe=>{

            if (fe.allotmentCode.startsWith("PD") && fe.allotmentCode.replace("PD-",'') == tmpPod) {
              // console.log("** check PD **")
              countSOC = 0;
              countCOC = 0;
              countSlot = 0;

              isError = '';
              isError = "PD";

              var childPD = [];
              childPD = this.getChildListNew(fe.allotmentLevel, dataStore);
              childPD.forEach(fe=>{
                if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
                  if(fe.allotmentCode.substring(0,3) == "SOC"){
                      countSOC++;
                  }
                }

                if(this.model.bContainerOwnership.substring(0,3) == "COC"){
                  if(fe.allotmentCode.substring(0,3) == "COC"){
                      countCOC++;
                  }
                }

                if(this.model.bContainerOwnership == "SLOT"){
                  if(fe.allotmentCode.startsWith("SS")){
                      countSlot++;
                  }
                }

              });
            }

            if(this.model.bContainerOwnership.substring(0,3) == "SOC"){
              if(fe.allotmentCode.substring(0,3) == "SOC"){
                  countSOC++;
              }
            }

            if(this.model.bContainerOwnership.substring(0,3) == "COC"){
              if(fe.allotmentCode.substring(0,3) == "COC"){
                  countCOC++;
              }
            }

            if(this.model.bContainerOwnership == "SLOT"){
              if(fe.allotmentCode.startsWith("SS")){
                  countSlot++;
              }
            }

          });
        }

        if (fe.allotmentCode.substring(0,3) == "SOC" && this.model.bContainerOwnership.substring(0,3) == "SOC"){
          isError = '';
          countSOC = 0;
          countSOC++;
        }

        if (fe.allotmentCode.substring(0,3) == "COC" && this.model.bContainerOwnership.substring(0,3) == "COC"){
          isError = '';
          countCOC = 0;
          countCOC++;
        }

        if (fe.allotmentCode.startsWith("SS") && this.model.bContainerOwnership == "SLOT"){
          isError = '';
          countSlot = 0;
          countSlot++;
        }


      });
    //}

    // console.log("soc "+countSOC+" coc "+countCOC+" slot "+countSlot);
    if(isError == "PD"){
      this.model['error-allocationValidator']='Allocation of Container Ownership (COC, SOC & SLOT) for ' + tmpPodName + ' has not been set for this vessel, unable to create booking.';
    }
    if(isError == "LADEN"){
        this.model['error-allocationValidator']='Allocation of Container Ownership (COC, SOC & SLOT) for LADEN has not been set for this vessel, unable to create booking.';
    }
    if(isError == "EMPTY"){
      this.model['error-allocationValidator']='Allocation of Container Ownership (COC, SOC & SLOT) for EMPTY has not been set for this vessel, unable to create booking.';
    }

    if(isError == ''){
      result = false;
    }

    if((countSOC > 0 || countCOC > 0 || countSlot > 0)){
      result = false;
    }

    return result;
  }

  lpad(s, width, char) {
    return (s.length >= width) ? s : (new Array(width).join(char) + s).slice(-width);
  }





  /** Container Information Handler **/
  gridEventContainerInfo(event) {
    switch (event.split('.')[0]) {
      case 'selected':
        break;
      case 'click' :
        break;
      default:
        let strEvent: String = event;
        if(strEvent != ''){
          let arr: String[] = strEvent.split('-');
          switch (arr[0]) {
            case 'edit':
              break;
            case 'dblClick':
              this.lockContainerInfo = false;
              this.bContainerInfoMode = 'update';

              this.bookingContainerInfo = new BookingContainerInfo();

              this.bookingContainerInfo.bOfficeCode = this.gridContainerInfo.getSelectedValues()[0].bOfficeCode;
              this.bookingContainerInfo.bPrefix = this.gridContainerInfo.getSelectedValues()[0].bPrefix;
              this.bookingContainerInfo.bNo = this.gridContainerInfo.getSelectedValues()[0].bNo;
              this.bookingContainerInfo.bSeqNo = this.gridContainerInfo.getSelectedValues()[0].bSeqNo;
              this.bookingContainerInfo.bPageNo = this.gridContainerInfo.getSelectedValues()[0].bPageNo;
              this.bookingContainerInfo.bVent = this.gridContainerInfo.getSelectedValues()[0].bVent;
              this.bookingContainerInfo.bNoOfPkg = this.gridContainerInfo.getSelectedValues()[0].bNoOfPkg;
              this.bookingContainerInfo.bPkgGroup = this.gridContainerInfo.getSelectedValues()[0].bPkgGroup;
              this.bookingContainerInfo.bReleaseDate = this.gridContainerInfo.getSelectedValues()[0].bReleaseDate;
              this.bookingContainerInfo.bReleaseTime = this.gridContainerInfo.getSelectedValues()[0].bReleaseTime;
              this.bookingContainerInfo.bPackageType = this.gridContainerInfo.getSelectedValues()[0].bPackageType;
              this.bookingContainerInfo.bPSAClass = this.gridContainerInfo.getSelectedValues()[0].bPSAClass;
              this.bookingContainerInfo.bContOperator = this.gridContainerInfo.getSelectedValues()[0].bContOperator;
              this.bookingContainerInfo.bIsPartLot = this.gridContainerInfo.getSelectedValues()[0].bIsPartLot;
              this.bookingContainerInfo.bTrueShippingName = this.gridContainerInfo.getSelectedValues()[0].bTrueShippingName;
              this.bookingContainerInfo.bContType = this.gridContainerInfo.getSelectedValues()[0].bContType;
              this.bookingContainerInfo.bChemicalName = this.gridContainerInfo.getSelectedValues()[0].bChemicalName;
              this.bookingContainerInfo.bGrossWeight = this.gridContainerInfo.getSelectedValues()[0].bGrossWeight;
              this.bookingContainerInfo.bGrossMeas = this.gridContainerInfo.getSelectedValues()[0].bGrossMeas;
              this.bookingContainerInfo.bNettWeight = this.gridContainerInfo.getSelectedValues()[0].bNettWeight;
              this.bookingContainerInfo.bLabel = this.gridContainerInfo.getSelectedValues()[0].bLabel;
              this.bookingContainerInfo.bIMO = this.gridContainerInfo.getSelectedValues()[0].bIMO;
              this.bookingContainerInfo.bUNNO = this.gridContainerInfo.getSelectedValues()[0].bUNNO;
              this.bookingContainerInfo.bFlashPoint = this.gridContainerInfo.getSelectedValues()[0].bFlashPoint;
              this.bookingContainerInfo.bEMS = this.gridContainerInfo.getSelectedValues()[0].bEMS;
              this.bookingContainerInfo.bMFAG = this.gridContainerInfo.getSelectedValues()[0].bMFAG;
              this.bookingContainerInfo.bContainerNo = this.gridContainerInfo.getSelectedValues()[0].bContainerNo;
              this.bookingContainerInfo.bCellLocation = this.gridContainerInfo.getSelectedValues()[0].bCellLocation;
              this.bookingContainerInfo.bMark = this.gridContainerInfo.getSelectedValues()[0].bMark;
              this.bookingContainerInfo.bReceiptType = this.gridContainerInfo.getSelectedValues()[0].bReceiptType;
              this.bookingContainerInfo.bVGMEnabled = this.gridContainerInfo.getSelectedValues()[0].bVGMEnabled;
              this.bookingContainerInfo.bVGMType = this.gridContainerInfo.getSelectedValues()[0].bVGMType;
              this.bookingContainerInfo.bVGMWeight = this.gridContainerInfo.getSelectedValues()[0].bVGMWeight;
              this.bookingContainerInfo.bOtherDetails = this.gridContainerInfo.getSelectedValues()[0].bOtherDetails;

              this.cbVGMMethod.setValue(this.bookingContainerInfo.bVGMType.toString());

              this.cbBContainerPackageType.setUrl(this.configService.config.BASE_API.toString() + '/MasterPackageTypes/findByComboBoxControl/packageTypeId={query}');
              this.cbBContainerPackageType.setValue(this.bookingContainerInfo.bPackageType.toString());

              this.cbBContainerReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
              this.cbBContainerReceiptType.setValue(this.gridContainerInfo.getSelectedValues()[0].bReceiptType.toString());

              this.lockBtnContainerNew = true; this.lockBtnContainerSaveUpdate = false; this.lockBtnContainerDelete = false; this.lockBtnContainerCancel = false;

              this.initDropDown();

              break;
            case 'afterLoad':
              break;
            default:
              break;
          }
        }
        break;
    }
  }
  infoGridContainerInfo(event) {}

  onContainerInfo__New() {

      this.bContainerInfoMode = 'insert';
      this.lockBtnContainerNew = true; this.lockBtnContainerSaveUpdate = false; this.lockBtnContainerDelete = true; this.lockBtnContainerCancel = false;
      this.lockContainerInfo = false;
      this.bookingContainerInfo = new BookingContainerInfo();

      this.cbBContainerReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
      this.cbBContainerReceiptType.setValue(this.bookingMisc.bReceiptTypeExtra.toString());

      this.bookingContainerInfo.bOfficeCode = this.model.bOfficeCode.toUpperCase();
      this.bookingContainerInfo.bPrefix = this.model.bPrefix.toUpperCase();
      this.bookingContainerInfo.bNo = this.model.bNo.toUpperCase();
      this.bookingContainerInfo.bSeqNo = this.gridContainerInfo.listStore.store.length + 1;
      this.cbVGMMethod.setValue('');

      this.initDropDown();


  }

  onContainerInfo__SaveUpdate() {
    // this.setValidatorContainerInsert();
    // this.isErrorContainerInfo = this.onValidate(this.bookingContainerInfo);

    this.capitalizeInput();

    if (this.bContainerInfoMode === 'insert') {
      if (!this.isErrorContainerInfo) {
        if (this.bookingContainerInfo.bGrossMeas > this.bookingContainerInfo.bGrossWeight) {
          this.isErrorContainerInfo = true;
          this.bookingContainerInfo['error-checkWtMeasValidator'] = 'Gross Meas cannot be bigger than Gross Weight';
        } else {
          this.isErrorContainerInfo = false;
          this.bookingContainerInfo['error-checkWtMeasValidator'] = '';
        }
      }

      if (this.gridContainerInfo.listStore.store.length > 0){
        for (let i=0; i<this.gridContainerInfo.listStore.store.length; i++) {
          if (this.gridContainerInfo.listStore.store[i].bContainerNo.trim() == this.bookingContainerInfo.bContainerNo.trim()) {
            this.isErrorContainerInfo = true;
            this.bookingContainerInfo['error-bContainerNo'] = 'Duplicate container number ' + this.bookingContainerInfo.bContainerNo;
          }
        }
      }

      if (!this.isErrorContainerInfo) {
        this.lockBtnContainerNew = false;
        this.lockBtnContainerSaveUpdate = true;
        this.lockBtnContainerDelete = true;
        this.lockBtnContainerCancel = true;
        this.gridContainerInfo.url = '';
        this.gridContainerInfo.listStore.store.push(this.bookingContainerInfo);
        this.gridContainerInfo.loadData();
        this.lockContainerInfo = true;
        this.onContainerInfo__Reset();
      }
    } else {
      // on update goes here
      this.gridContainerInfo.listStore.updateData((this.gridContainerInfo.getSelectedValues()[0].bSeqNo -1),this.bookingContainerInfo);
      this.gridContainerInfo.clearSelectedValues();
      this.gridContainerInfo.loadData();

      this.lockBtnContainerNew = false; this.lockBtnContainerSaveUpdate = true; this.lockBtnContainerDelete = true; this.lockBtnContainerCancel = true;
      this.lockContainerInfo = true;
      this.onContainerInfo__Reset();

    }

    //$("#typeSize").dropdown("clear");
    this.typeSizeComboDisabled = true;
    //this.cbBContainerReceiptType.disableCombo = true;

  }

  onContainerInfo__Delete() {
    this.gridContainerInfo.url = '';
    const dt = this.gridContainerInfo.getSelectedValues();
    this.gridContainerInfo.listStore.removeData(dt);
    this.gridContainerInfo.loadData();
    this.onContainerInfo__Reset()
  }

  onContainerInfo__Cancel() {
    this.bContainerInfoMode = 'insert';
    this.lockBtnContainerNew = false; this.lockBtnContainerSaveUpdate = true; this.lockBtnContainerDelete = true; this.lockBtnContainerCancel = true;
    this.lockContainerInfo = true;
    this.onContainerInfo__Reset();
    $("#typeSize").dropdown("clear");
    this.typeSizeComboDisabled = true;
  }

  onContainerInfo__Reset(){
    this.isErrorContainerInfo = false;
    this.bookingContainerInfo = new BookingContainerInfo();
    this.cbBContainerPackageType.setValue('');
    this.cbBContainerReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
    this.cbBContainerReceiptType.setValue(this.bookingMisc.bReceiptTypeExtra.toString());
    this.cbVGMMethod.setValue('');
  }

  changeEventBContainerReceiptType(event) {
    if (event.receiptTypeCode != null && event.receiptTypeCode !== '' && event.receiptTypeCode !== undefined) {
      this.bookingContainerInfo.bReceiptType = event.receiptTypeCode;
    } else {
      this.bookingContainerInfo.bReceiptType = '';
    }
    this.cbBContainerReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeEventVGMMethod(event) {
    console.log('debugggg vgm');
    console.log(event);
    if (event.vgmMethodCode != null && event.vgmMethodCode !== '' && event.vgmMethodCode !== undefined) {
      this.bookingContainerInfo.bVGMType = event.vgmMethodCode;
    } else {
      this.bookingContainerInfo.bVGMType = '';
    }
  }
  setValidatorContainerInsert() {
    this.validatorRules = {
      bContainerNo: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Container No.'
        }]
      },
    };
  }


  capitalizeInput(){
    this.bookingContainerInfo.bOfficeCode = this.bookingContainerInfo.bOfficeCode.toUpperCase();
    this.bookingContainerInfo.bPrefix = this.bookingContainerInfo.bPrefix.toUpperCase();
    this.bookingContainerInfo.bNo = this.bookingContainerInfo.bNo.toUpperCase();
    this.bookingContainerInfo.bPageNo = this.bookingContainerInfo.bPageNo.toUpperCase();
    this.bookingContainerInfo.bVent = this.bookingContainerInfo.bVent.toUpperCase();
    this.bookingContainerInfo.bPkgGroup = this.bookingContainerInfo.bPkgGroup.toUpperCase();
    this.bookingContainerInfo.bReleaseDate = this.bookingContainerInfo.bReleaseDate.toUpperCase();
    this.bookingContainerInfo.bReleaseTime = this.bookingContainerInfo.bReleaseTime.toUpperCase();
    this.bookingContainerInfo.bPackageType = this.bookingContainerInfo.bPackageType.toUpperCase();
    this.bookingContainerInfo.bPackageTypeName = this.bookingContainerInfo.bPackageTypeName.toUpperCase();
    this.bookingContainerInfo.bPSAClass = this.bookingContainerInfo.bPSAClass.toUpperCase();
    this.bookingContainerInfo.bContOperator = this.bookingContainerInfo.bContOperator.toUpperCase();
    this.bookingContainerInfo.bTrueShippingName = this.bookingContainerInfo.bTrueShippingName.toUpperCase();

    this.bookingContainerInfo.bContType = this.bookingContainerInfo.bContType.toUpperCase();
    this.bookingContainerInfo.bChemicalName = this.bookingContainerInfo.bChemicalName.toUpperCase();
    this.bookingContainerInfo.bTrueShippingName = this.bookingContainerInfo.bTrueShippingName.toUpperCase();
    this.bookingContainerInfo.bContType = this.bookingContainerInfo.bContType.toUpperCase();
    this.bookingContainerInfo.bLabel = this.bookingContainerInfo.bLabel.toUpperCase();
    this.bookingContainerInfo.bIMO = this.bookingContainerInfo.bIMO.toUpperCase();
    this.bookingContainerInfo.bUNNO = this.bookingContainerInfo.bUNNO.toUpperCase();
    this.bookingContainerInfo.bEMS = this.bookingContainerInfo.bEMS.toUpperCase();

    this.bookingContainerInfo.bMFAG = this.bookingContainerInfo.bMFAG.toUpperCase();
    this.bookingContainerInfo.bContainerNo = this.bookingContainerInfo.bContainerNo.toUpperCase();
    this.bookingContainerInfo.bCellLocation = this.bookingContainerInfo.bCellLocation.toUpperCase();
    this.bookingContainerInfo.bMark = this.bookingContainerInfo.bMark.toUpperCase();
    this.bookingContainerInfo.bReceiptType = this.bookingContainerInfo.bReceiptType.toUpperCase();
    this.bookingContainerInfo.bVGMEnabledFlag = this.bookingContainerInfo.bVGMEnabledFlag.toUpperCase();
    this.bookingContainerInfo.bVGMType = this.bookingContainerInfo.bVGMType.toUpperCase();
    this.bookingContainerInfo.bOtherDetails = this.bookingContainerInfo.bOtherDetails.toUpperCase();

  }

  changeEventChBookingContainerInfo(event){
    if(event.target.checked){
      this.bookingContainerInfo.bVGMEnabled=true;
      this.bookingContainerInfo.bVGMEnabledFlag='Y';
    } else {
      this.bookingContainerInfo.bVGMEnabled=false;
      this.bookingContainerInfo.bVGMEnabledFlag='N';
      this.cbVGMMethod.setValue('');
      this.bookingContainerInfo.bVGMWeight=0;
    }
  }

  changeEventCbBContainerPackageType(event) {
    if (event.packageTypeId != null && event.packageTypeId !== '' && event.packageTypeId !== undefined) {
      this.bookingContainerInfo.bPackageType = event.packageTypeId;
      this.bookingContainerInfo.bPackageTypeName = event.packageTypeName;
    } else {
      this.bookingContainerInfo.bPackageType = '';
      this.bookingContainerInfo.bPackageTypeName = '';
    }
    this.cbBContainerPackageType.setUrl(this.configService.config.BASE_API.toString() + '/MasterPackageTypes/findByComboBoxControl/packageTypeName={query}');
  }

  initDropDown(){
    this.loadContainerDropDown(this.currentContainerType);
  }

  eventDropDown(type:string, value:string){
    if(value != ''){
      if(type == "ctype"){
        this.typeSizeComboLoading = true;
        this.loadContainerDropDown(value);
      } else if(type == "csize"){
        this.bookingContainerInfo.bContType = value;
      }
    }
  }

  loadContainerDropDown(value:string){
    var hitURL = this.hitUrl + "/MasterPerCodes/getPerCodeByCtype/tags/"+value.toUpperCase()+"/";
    $("#typeSize").dropdown("clear");
    $("#typeSize").find(".menu").empty();
    if(!this.typeSizeComboDisabled){
      this.typeSizeComboDisabled = true;
    }
    this.genericService.GET(hitURL).subscribe((resp)=>{
      if(resp.ok){
        var htmlData = '';
        var data = [];
        data = resp.json()["results"];
        data.forEach(fe=>{
          htmlData = htmlData + "<div class='item' data-value='"+fe.value+"' style='font-size:11px'>"+fe.name+"</div>";
        });
        $("#typeSize").find(".menu").append(htmlData);
        this.typeSizeComboLoading = false;
        this.typeSizeComboDisabled = false;
        var self = this;

        $("#typeSize").dropdown({
          action : 'activate',
          onChange : function (value, text, $selectedItem){
            self.eventDropDown("csize", value);
          }
        });

        if(this.bookingContainerInfo.bContType != ''){
          $("#typeSize").dropdown("set selected", this.bookingContainerInfo.bContType);
        }
      }
    });

  }


  changeEventCbReceiptTypeExtra(event) {
    console.log('Extra');
    console.log(event);
    if (event.receiptTypeCode != null && event.receiptTypeCode !== undefined) {
      this.bookingMisc.bReceiptTypeExtra = event.receiptTypeCode;

      // another handle to pull down sub terminal information
      this.bookingMisc.bReturnToPor = event.porTerminalCode;
      this.cbReturnToPor.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
      this.cbReturnToPor.setValue(this.bookingMisc.bReturnToPor.toString());
      this.bookingMisc.bReturnToPorClosingDate = event.porTerminalClosingDate;
      this.dateReturnToPorGateInClosing.setValue(this.bookingMisc.bReturnToPorClosingDate);
      this.bookingMisc.bReturnToPorClosingTime = event.porTerminalClosingTime;
      this.bookingMisc.bReturnToPorVgmDate = event.porVgmClosingDate;
      this.dateReturnToPorVgmClosing.setValue(this.bookingMisc.bReturnToPorVgmDate);
      this.bookingMisc.bReturnToPorVgmTime = event.porVgmClosingTime;

      this.bookingMisc.bReturnToPol = event.polTerminalCode;
      this.cbReturnToPol.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
      this.cbReturnToPol.setValue(this.bookingMisc.bReturnToPol.toString());
      this.bookingMisc.bReturnToPolClosingDate = event.polTerminalClosingDate;
      this.dateReturnToPolGateInClosing.setValue(this.bookingMisc.bReturnToPolClosingDate);
      this.bookingMisc.bReturnToPolClosingTime = event.polTerminalClosingTime;
      this.bookingMisc.bReturnToPolVgmDate = event.polVgmClosingDate;
      this.dateReturnToPolVgmClosing.setValue(this.bookingMisc.bReturnToPolVgmDate);
      this.bookingMisc.bReturnToPolVgmTime = event.polVgmClosingTime;

      this.bookingMisc.bStuffAtPor = event.porStuffAtTerminalCode;
      this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoCode={query}');
      this.cbStuffAt.setValue(this.bookingMisc.bStuffAtPor.toString());
      this.bookingMisc.bStuffAtPorClosingDate = event.porStuffAtTerminalClosingDate;
      this.dateStuffAtPorGateInClosing.setValue(this.bookingMisc.bStuffAtPorClosingDate);
      this.bookingMisc.bStuffAtPorClosingTime = event.porStuffAtTerminalClosingTime;
      this.bookingMisc.bStuffAtPorVgmDate = event.porStuffAtVgmClosingDate;
      this.dateStuffAtPorVgmClosing.setValue(this.bookingMisc.bStuffAtPorVgmDate);
      this.bookingMisc.bStuffAtPorVgmTime = event.porStuffAtVgmClosingTime;

      this.cbBContainerReceiptType.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}');
      this.cbBContainerReceiptType.setValue(this.bookingMisc.bReceiptTypeExtra.toString());

    } else {
      this.bookingMisc.bReceiptTypeExtra = '';
    }
    //this.cbReceiptTypeExtra.setUrl(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}');
  }

  changeEventReleaseAt(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.bookingMisc.bReleaseAt = event.depoCode;
    } else {
      this.bookingMisc.bReleaseAt = '';
    }
    this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoName={query}');
  }
  changeEventReturnToPor(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.bookingMisc.bReturnToPor = event.depoCode;
    } else {
      this.bookingMisc.bReturnToPor = '';
    }
    this.cbReturnToPor.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoName={query}');
  }
  changeEventReturnToPol(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.bookingMisc.bReturnToPol = event.depoCode;
    } else {
      this.bookingMisc.bReturnToPol = '';
    }
    this.cbReturnToPol.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoName={query}');
  }
  changeEventStuffAt(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.bookingMisc.bStuffAtPor = event.depoCode;
    } else {
      this.bookingMisc.bStuffAtPor = '';
    }
    this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode() + '/depoName={query}');
  }




  populateReceiptTypeExtra() {
    console.log('masuk populate');
    if (this.model.bPorCode != null && this.model.bPorCode !== '' && this.model.bPorCode !== undefined) {
      console.log('masuk populate 2');
      if (this.model.bPolCode != null && this.model.bPolCode !== '' && this.model.bPolCode !== undefined) {
        console.log('masuk populate 3');
        this.genericService.GET(this.configService.config.BASE_API.toString() + '/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/' + this.model.bPorCode + '/' + this.model.bPolCode).subscribe((resp) =>{
          let data: any[] = resp.json()['content'];
          if(data.length === 1){
            this.cbReceiptTypeExtra.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/' + this.model.bPorCode + '/' + this.model.bPolCode);
            this.cbReceiptTypeExtra.setValue(data[0]['receiptTypeCode']);
          }else{
            this.cbReceiptTypeExtra.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterTerminalClosingTime/findReceiptTypeByPortPair/' + this.cookieService.getDefaultLocationCode() + '/' + this.model.bOceanVesselId + '/' + this.model.bOceanVesselVoyage + '/' + this.model.bOceanVesselBound + '/' + this.model.bPorCode + '/' + this.model.bPolCode);
          }
        });
      } else {
        this.cbReceiptTypeExtra.setUrl('');
      }
    }
  }

  getDetailSummary(): String {
    let result: String = '';
    result += this.bookingMisc.bHaulierId.trim() + this.bookingMisc.bContactPerson.trim() + this.bookingMisc.bReceiptTypeExtra.trim() + this.bookingMisc.bReleaseAt.trim() + this.bookingMisc.bReleaseAtDate.trim() + this.bookingMisc.bReleaseAtTime.trim();
    result += this.bookingMisc.bReturnToPol.trim() + this.bookingMisc.bReturnToPolClosingDate.trim() + this.bookingMisc.bReturnToPolClosingTime.trim() + this.bookingMisc.bReturnToPolGateInDate.trim() + this.bookingMisc.bReturnToPolGateInTime.trim() + this.bookingMisc.bReturnToPolVgmDate.trim() + this.bookingMisc.bReturnToPolVgmTime.trim();
    result += this.bookingMisc.bReturnToPor.trim() + this.bookingMisc.bReturnToPorClosingDate.trim() + this.bookingMisc.bReturnToPorClosingTime.trim() + this.bookingMisc.bReturnToPorGateInDate.trim() + this.bookingMisc.bReturnToPorGateInTime.trim() + this.bookingMisc.bReturnToPorVgmDate.trim() + this.bookingMisc.bReturnToPorVgmTime.trim();
    result += this.bookingMisc.bStuffAtPor.trim() + this.bookingMisc.bStuffAtPorClosingDate.trim() + this.bookingMisc.bStuffAtPorClosingTime.trim() + this.bookingMisc.bStuffAtPorGateInDate.trim() + this.bookingMisc.bStuffAtPorGateInTime.trim() + this.bookingMisc.bStuffAtPorVgmDate.trim() + this.bookingMisc.bStuffAtPorVgmTime.trim();

    return result;
  }

  printBookingConfirmation(){
    var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile";
    var jasperURL =  this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/THBKK/BOOKING/Booking_Confirmation.pdf?bOfficeCode='+this.cookieService.getDefaultLocationCode()+'&bPrefix='+this.model.bPrefix+'&bNo='+this.model.bNo+'&userId='+this.cookieService.getName();
    var fileName = this.cookieService.getDefaultLocationCode() + '-' + this.model.bPrefix + this.model.bNo;

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.pdfContainer.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
          this.pdfContainer.showPDF();
        }

        this.loading = false;
      }
    });
  }
}
