import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import {CookieService,ConfigService, GenericService_V3} from 'sibego-ui-library';
import 'ag-grid-enterprise';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';

import * as moment from 'moment';
declare  var $:any;

@Component({
  selector: 'app-edi-receive-bl-data-from-penavico-page',
  templateUrl: './edi-receive-bl-data-from-penavico-page.component.html',
  styleUrls: ['./edi-receive-bl-data-from-penavico-page.component.css']
})
export class EDIReceiveBLDataFromPenavicoPageComponent   implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild("gridHeader") agGrid: any;

  dialog: any;
  info = '';
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';
  modeToolbar = false;
  getRowStyle='';

  loading = false;
  loadingUpload = false;
  cButtonLock = true;
  dButtonLock = true;

  gridApi: GridApi;
  gridColumnApi: ColumnApi;

  settingToolbar: any;
  columnDefs: any[];
  rowData: any[];
  defaultColDef: any;
  frameworkComponents: any;
  //hitUrl: string = 'http://localhost:10001';
  userId: string = '';

  isLoading: boolean = false;
  createList:any =[];


  constructor(private router: Router,private cookieService: CookieService,  private configService: ConfigService,private genericServiceV3: GenericService_V3,) {
    this.settingToolbar = {
      buttonsFront: [
        { name: 'Receive BL EDI', event: 'receiveBlEDI', id: 'receiveBKGEDI', icon: 'upload' },
        { name: 'Close', event: 'close', icon: 'power' }
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Receive BL From Agent'
    }

  }

  ngOnInit() {
    this.firstInit();

    this.columnDefs = [
      { headerName: 'No.', field: 'no',  width: 100, headerClass: 'custom-header' },
      { headerName: 'Uploaded', field: 'uploaded', width: 100 , headerClass: 'custom-header'},
      { headerName: 'File Name', field: 'fileName', width: 300 , headerClass: 'custom-header'},
      { headerName: 'Vessel Name', field: 'vesselName', width: 200 , headerClass: 'custom-header'},
      { headerName: 'Voyage No.', field: 'voyageNo', width: 200, headerClass: 'custom-header' },
      { headerName: 'File Date Time', field: 'fileDateTime', width: 200 , headerClass: 'custom-header' },
      { headerName: 'Status', field: 'status', width: 100 , headerClass: 'custom-header'},
      { headerName: 'Error', field: 'errorReason',width: 400 ,  headerClass: 'custom-header'}
    ];

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.rowData = [];


  }

  firstInit() {
 /*    const loggedValue = localStorage.getItem('logged');
    if (loggedValue) {
      const [username, email, timestamp, userId] = loggedValue.split('|');
      this.userId = userId;
      console.log('User ID from local storage:', this.userId);
    } */
      this.userId = this.cookieService.getUserId();
      console.log('User ID from cookie:', this.userId);
      //this.hitUrl = this.configService.config.BASE_API.toString();

  }

  toolbarEvent(event) {
    switch (event) {
      case "receiveBlEDI":
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = '.txt';

        input.onchange = (event) => {
          const files = (event.target as HTMLInputElement).files;

          if (files) {
            const formData = new FormData();
            let validFileFound = false;

            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const fileType = file.name.split('.').pop();
              if (fileType && fileType.toLowerCase() === 'txt') {
                formData.append('files', file);
                console.log('File:', file.name);
                validFileFound = true;
              } else {
                console.warn(`File '${file.name}' is not a valid .txt file.`);
                this.message(
                  "Information",
                  "information",
                  "Please select only .txt file.",
                  "okonly",
                  { ok: "" }
                );
              }
            }

            if (validFileFound) {
              this.isLoading = true;
              formData.append('userID', this.userId);
              const url = this.configService.config.BASE_API.toString() + "/ContainerLoadList/receiveBlSHA/" + this.userId;
              this.genericServiceV3.POST(url, formData, { reportProgress: true, observe: 'events', responseType: 'json' },true)
                .subscribe((data: any) => {
                  if (data.type === HttpEventType.UploadProgress) {
                    console.log('Upload progress: ', Math.round((100 * data.loaded) / data.total));

                  } else if (data.type === HttpEventType.Response) {
                    console.log('Upload complete: ', data.body);
                    //this.processData(data.body);
                    data.body.forEach((element) => {   //to append previously uploaded files list in grid
                      if(element["status"] ==="9")
                        {element["status"]="New"}
                      this.createList.push(element);
                    })
                    this.processData(this.createList);

                  }
                });
            }
          }
        };
        input.click(); // Simulate click to open file dialog

      break;
      case "close":
        this.handleClose();
      break;
    }
  }

  processData(data: any[]) {
    if (Array.isArray(data)) {
      this.rowData = data.map((item, index)=> this.mapItem(item, index));
    } else if (typeof data === 'object' && data !== null) {
      this.rowData = [this.mapItem(data,0)];
    } else {
      console.error('Unexpected data format:', data);
      this.isLoading = false;
    }
    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
      this.isLoading = false;
    }
  }

    mapItem(item: any, index: number): any {
      return {
        no: index + 1, // Assign sequence number starting from 1
        uploaded: item.errorReason ? '' : 'Y', // Conditionally set uploaded field
        fileName: item.fileName || '',
        vesselName: item.vesselName || '',
        voyageNo: item.voyageNo || '',
        fileDateTime: item.fileDateTime ? this.convertToDateTimeString(item.fileDateTime): '' ,
        status: item.status || '',
        errorReason: item.errorReason || ''
      };
    }


  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event: eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }


  handleClose() {
    this.router.navigateByUrl("/main/home");
  }

convertToDateTimeString(fileDate: string): string {
  return moment(fileDate, 'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm');
}



eventMessage(e){}
}
