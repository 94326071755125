import { forEach } from '@angular/router/src/utils/collection';
import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { specialCharUtil } from "../shared";
declare var $: any;

export class ParamTransmitBookingDataToPSAPage {
  vesselId: String = "";
  vesselCode: String = "";
  vesselName: String = "";
  voyage: String = "";
  bound: String = "O";
  containerOwnership: String = "";
  bOutwardSlotOperatorCode: any;
  bSlotOperatorCode: any;
  bookingPartyId: string = "";
  cntrOpr: string = "";
  soOpr: string = "";
  bookingPartyCode: any;
  constructor() {}
}

@Component({
  selector: "app-edi-transmit-booking-data-to-psa-page",
  templateUrl: "./edi-transmit-booking-data-to-psa-page.component.html",
  styleUrls: ["./edi-transmit-booking-data-to-psa-page.component.css"],
})
export class EDITransmitBookingDataToPSAPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("ediTransmissionList") ediTransmissionList: PdfPluginComponent;
  @ViewChild("cbSlotCntrOPR") cbSlotCntrOPR: ComboPluginComponent;
  @ViewChild("cbSlotOPR") cbSlotOPR: ComboPluginComponent;
  @ViewChild("cbBookingParty") cbBookingParty: ComboPluginComponent;
  
  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;
  modeForm = "sailingScheduleFlagStatus";

  loading = false;

  radioContainerOwnerSOC = "N";
  radioContainerOwnerCOC = "N";
  radioContainerOwnerSLOT = "N";

  /* Parameter settings */
  settingToolbar;
  settingPDF;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  formatName: String = "";

  // * HTML Encode
  sCharUtil = new specialCharUtil();

  // lock
  cButtonLock = true;
  dButtonLock = true;

  //model ='';
  model = new ParamTransmitBookingDataToPSAPage();

  validatorRules = {};

  isError: Boolean = false;

  settingVessel;
  settingVoyage;

  transmitViaSFTP = false;
  settingBookingParty: any;
  settingSlotCntrOPR: any;
  settingSlotOPR: any;
  lockSLOTOPR: Boolean = true;
  lockSLOTCNTROPR: Boolean = true;
  isShowSOOPRInputOfSOCCOC = true;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Transmit", event: "transmit", icon: "exchange" },
        { name: "Transmit via COPARN", event: "transmitCoparn", icon: "exchange" },
        { name: "Close", event: "close", icon: "power" },
        { name: "Reset", event: "reset", icon: "exchange" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Transmission to PSA",
    };
    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxlength: 20,
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingPDF = {
      id: "ediTransmissionList",
      url: "",
      hidePrint: false,
    };

    this.settingBookingParty = {
      id          : 'cbBookingParty',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findAllCustomerBookingParty/{query}/1/10',
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Booking Party --',
      title       : 'customerName',
      isMark      : true,
      description  : '',
      columns     : [
        {header: 'Customer Name', field: 'customerName', width: 300, type:'string'},
        {header: 'NVOCC?', field: 'isNvocc', width: 125},
        {header: 'Valid?', field: 'isValid', width: 125}
      ]
    };

    this.settingSlotCntrOPR = {
      id: "cbSlotCntrOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "cntrOpr",
      title: "cntrOpr",
      allowRemove: true,
    };

    this.settingSlotOPR = {
      id: "cbSlotOPR",
      type: "select", // search | select | select input
      url: "",
      urlType: "dynamic",
      placeholder: "",
      code: "soOpr",
      title: "soOpr",
      allowRemove: false,
    };
  }
  ngOnInit() {}

  ngAfterViewInit() {}

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "transmit":
        //this.setValidator()
        this.clickToTransmit();
        break;
      case "transmitCoparn":
        this.handleTransmitCoparn();
        break;
      case "reset":
        this.clickToReset();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }
  
  clickToTransmit(){
    if (
      this.model.vesselId != "" &&
      this.model.voyage != "" &&
      this.model.bound != "" &&
      this.model.containerOwnership != ""
    ) {

      // GSO-1064 Booking Party =Y, CNTR OPR & SO OPR compulsory
      if(this.model.bookingPartyId && (!this.model.bOutwardSlotOperatorCode || !this.model.bSlotOperatorCode))
        {
          this.message(
            "information",
            "Information",
            "Please input CNTR OPR & SO OPR",
            "okonly",
            { ok: "" }
          );
          return;
      }

      this.message('save', 'Saving data', 'ALL bookings will be transmitted to PSA. Confirm to proceed? ', 'yesno', { yes: 'this.handleProcessTransmit()', no: 'this.loading = false;' });
    } else {
      this.message(
        "information",
        "Information",
        "please insert vessel, voyage & container ownership",
        "okonly",
        { ok: "" }
      );
      this.loading = false;
    }
  }

  handleProcessTransmit() {
    this.loading = true;
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/ediController/checkTransmit/" +
          this.model.vesselCode +
          "/" +
          this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
          "/" +
          this.model.bound +
          "/" +
          this.model.containerOwnership
      )
      .subscribe((resp) => {
        //this.genericService.GET("http://localhost:12000/ediController/checkTransmit/" + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership).subscribe((resp) => {
        console.log(resp["message"]);
        if (resp.json()["message"] == "ok") {
          console.log("ok");
          this.transmitProcess();
        } else {
          this.message(
            "information",
            "Information",
            "No data transmitted ",
            "okonly",
            { ok: "" }
          );
          this.loading = false;
        }
      });
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  changeEventVessel(event) {
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.vesselId = "";
    } else {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.bound +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }
  }

  changeEventVoyage(event) {
    this.model.voyage = event.voyage;
    this.cbBookingParty.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterCustomers/searchBkgPartyInBookings/" +
      this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership + "/{query}"
    );
  }

  changeEventTransmit(event) {
    if (event.target.value) {
      this.model.containerOwnership = event.target.value;
      this.cbBookingParty.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/searchBkgPartyInBookings/" + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership + "/{query}"
      );
      this.cbSlotCntrOPR.clearSelect();
      this.cbSlotOPR.clearSelect();

      this.isShowSOOPRInputOfSOCCOC = event.target.value === "SLOT" ? false : true;

      // GSO-1064 fix <Booking Party> field is not refreshed when select different <Container Ownership>
      this.cbBookingParty.clearSelect();
      this.model.bookingPartyId = "";

      // GSO-1076  Whenever there’s change of cntr ownership, Booking Party=N,  there shouldn’t be selection available under  <CNTR OPR>  field
      this.cbSlotCntrOPR.listStore.store = [];
      this.cbSlotOPR.listStore.store = [];

    }
  }

  setValidator() {
    this.isError = false;
    this.model["error-vesselName"] = "";
    this.model["error-voyage"] = "";
    this.model["error-bound"] = "";
    this.model["error-containerOwnership"] = "";

    this.validatorRules = {
      vesselName: {
        rules: [
          {
            type: "empty",
            prompt: "Vessel should not be empty.",
          },
        ],
      },

      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Voyage should not be empty.",
          },
        ],
      },

      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Bound should not be empty.",
          },
        ],
      },
    };
  }

  transmitProcess() {
    console.log(this.model);
    var objToday = new Date();
    var curHour =
      objToday.getHours() > 12
        ? objToday.getHours() - 12
        : objToday.getHours() < 10
        ? "0" + objToday.getHours()
        : objToday.getHours();
    var curMinute =
      objToday.getMinutes() < 10
        ? "0" + objToday.getMinutes()
        : objToday.getMinutes();
    var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";
    var curDate =
      objToday.getDate() +
      "/" +
      (objToday.getMonth() + 1) +
      "/" +
      objToday.getFullYear();
    var localTime =
      curDate + "@" + curHour + ":" + curMinute + "@" + curMeridiem;

    var fileName =
      "cntrbk_opncon_" +
      objToday.getFullYear() +
      (objToday.getMonth() + 1) +
      objToday.getDate() +
      "_" +
      objToday.getHours() +
      objToday.getMinutes() +
      objToday.getSeconds();

    var procDate =
      objToday.getFullYear() +
      (objToday.getMonth() + 1) +
      objToday.getDate() +
      "_" +
      objToday.getHours() +
      objToday.getMinutes() +
      objToday.getSeconds();

    console.log(fileName);
    var uri =
      this.configService.config.getPDFUrl +
      "?q=/ediController/transmit/psa/" +
      this.model.vesselCode +
      "/" +
      this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
      "/" +
      this.model.bound +
      "/" +
      this.genericUtil.getPSIDate() +
      "/" +
      this.model.containerOwnership +
      "/" +
      (this.model.bookingPartyId || '[null]') +
      "/" +
      (this.model.bOutwardSlotOperatorCode || '[null]') +
      "/" +
      (this.model.bSlotOperatorCode || '[null]') +
      "/" +
      this.cookieService.getName() +
      "/" +
      fileName;
    //  var uri = 'http://localhost:12000' + '/ediController/transmit/psa/' + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.genericUtil.getPSIDate() + "/" + this.model.containerOwnership + "/" + this.cookieService.getUserId() + "/" + fileName;
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // var uri = this.configService.config.getPDFUrl.replace(splitUrl[2],"16000") + '/ediController/transmit/psa/' + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.genericUtil.getPSIDate() + "/" + this.model.containerOwnership + "/" + this.cookieService.getUserId() + "/" + fileName;

    // window.open(uri);

    // console.log("masuk sini");

    var self = this;
    $.ajax({
      url: uri,
      method: "GET",
      xhrFields: {
        responseType: "blob",
      },
      beforeSend: function (xhr) {
        xhr.setRequestHeader("Token", localStorage.getItem("token"));
      },
      success: function (data) {
        // var a = document.createElement('a');
        // var url = window.URL.createObjectURL(data);
        // a.href = url;
        // a.download = fileName;
        // a.click();
        // window.URL.revokeObjectURL(url);

        // PDF
        var hitUrl = self.configService.config.getPDFUrl + "/edi-util/getFile";
        var jasperURL =
          self.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmittedPSA.pdf?id=" +
          fileName +
          "&localTime=" +
          localTime;
        var formatName = "TransmittedBookingListPSA.pdf";

        console.log("jasperurl" + jasperURL);

        var postDatas = {};
        postDatas["fileName"] = formatName;
        postDatas["url"] = jasperURL;
        self.genericService.POST(hitUrl, postDatas).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json();

            console.log("message" + result["message"]);
            if (result["message"] == "success") {
              self.ediTransmissionList.setPDFURL(
                self.configService.config.pdfUrl + result["filePath"]
              );
              self.ediTransmissionList.showPDF();
            }

            self.loading = false;
          }
        });

        //this.loading = false;
      },
    });

    //var uri = 'a'
    //this.downloadFileFromServer(uri, fileName, "xls", true);
  }

  downloadFileFromServer(
    path: string,
    fileName: string,
    fileExt: string,
    loading: boolean
  ) {
    loading = true;
    this._http
      .get(path, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          //var docName = fileName+"."+fileExt;
          FileSaver.saveAs(res, fileName);
          loading = false;
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          loading = false;
        }
      );
  }

  changeEventBound(event) {}
  noneSpace(event) {}
  eventPDFPlugin(event) { }

  handleTransmitCoparn() {
    if (
      this.model.vesselId != "" &&
      this.model.voyage != "" &&
      this.model.bound != "" &&
      this.model.containerOwnership != ""
    ) {

      var objToday = new Date();
      var curHour =
        objToday.getHours() > 12
          ? objToday.getHours() - 12
          : objToday.getHours() < 10
          ? "0" + objToday.getHours()
          : objToday.getHours();
      var curMinute =
        objToday.getMinutes() < 10
          ? "0" + objToday.getMinutes()
          : objToday.getMinutes();
      var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";
      var curDate =
        objToday.getDate() +
        "/" +
        (objToday.getMonth() + 1) +
        "/" +
        objToday.getFullYear();
      var localTime =
        curDate + "@" + curHour + ":" + curMinute + "@" + curMeridiem;

      var fileName =
        "cntrbk_opncon_" +
        objToday.getFullYear() +
        (objToday.getMonth() + 1) +
        objToday.getDate() +
        "_" +
        objToday.getHours() +
        objToday.getMinutes() +
        objToday.getSeconds();

      var procDate =
        objToday.getFullYear() +
        (objToday.getMonth() + 1) +
        objToday.getDate() +
        "_" +
        objToday.getHours() +
        objToday.getMinutes() +
        objToday.getSeconds();

      console.log(this.model.containerOwnership);
      this.loading = true;
      const params = {
        bOfficeCode: this.cookieService.getDefaultLocationCode(),
        vessel: this.model.vesselCode,
        voyage: this.model.voyage,
        bound: this.model.bound,
        containerOwnership: this.model.containerOwnership
      }
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
          "/MasterBookings/coparn/generate/" + fileName + "/" + (this.transmitViaSFTP ? "Y" : "N"),
          params
        )
        .subscribe((resp) => {
          //this.genericService.GET("http://localhost:12000/ediController/checkTransmit/" + this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership).subscribe((resp) => {
          console.log(resp.json()["status"]);
          if (resp.json()["status"] === 'OK') {
            this.transmitCoparnProcess(fileName, localTime);
          } else {
            this.message(
              "information",
              "Information",
              resp.json()["message"],
              "okonly",
              { ok: "" }
            );
            this.loading = false;
          }
        });
    } else {
      this.message(
        "information",
        "Information",
        "please insert vessel, voyage & container ownership",
        "okonly",
        { ok: "" }
      );
      this.loading = false;
    }
  }

  transmitCoparnProcess(fileName, localTime) {
    const self = this;
    console.log(this.model);
    console.log(fileName);

        // PDF
        //var hitUrl = self.configService.config.getPDFUrl + "/MasterBookings/getFile";
        var hitUrl = self.configService.config.getPDFUrl + "/edi-util/getFile";
        var jasperURL =
          self.configService.config.jasperUrl +
          "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/bookingTransmittedCOPARN.pdf?id=" +
          fileName +
          "&localTime=" +
          localTime;
        var formatName = "TransmittedBookingListViaCoparn.pdf";

        console.log("jasperurl" + jasperURL);

        var postDatas = {};
        postDatas["fileName"] = formatName;
        postDatas["url"] = jasperURL;
        self.genericService.POST(hitUrl, postDatas).subscribe((resp) => {
          if (resp.ok) {
            var result = resp.json();

            console.log("message" + result["message"]);
            if (result["message"] == "success") {
              self.ediTransmissionList.setPDFURL(
                self.configService.config.pdfUrl + result["filePath"]
              );
              self.ediTransmissionList.showPDF();
            }

            self.loading = false;
          }
        });
  }
  
  changeEventSlotCntrOPR(event) {
    if (event.code != null) {
      this.model.bOutwardSlotOperatorCode = event.code;
    } else {
      this.model.bOutwardSlotOperatorCode = "";
    }
  }

  loadDataAlloc() {
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/calculateAllocation2",
        this.model
      )
      .subscribe((resp) => {
        let dataAlloc: any[] = resp.json()["content"];

      });
  }

  changeEventSlotOPR(event) {
    if (event.code != null) {
      this.model.bSlotOperatorCode = event.code;
    } else {
      this.model.bSlotOperatorCode = "";
    }
  }

  changeEventBookingParty(e) {
    if (e.customerId) {
      this.model.bookingPartyId = e.customerId;
      this.model.bookingPartyCode = e.customerCode;

      this.cbSlotCntrOPR.clearSelect();
      this.cbSlotOPR.clearSelect();

      this.cbSlotOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/searchOpr" +
        "/" + "so/" +
        this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership + "/" +
        this.model.bookingPartyId
      );
      
      var self = this;
      this.cbSlotCntrOPR.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/searchOpr" +
        "/" + "cntr/" +
        this.model.vesselCode + "/" + this.model.voyage + "/" + this.model.bound + "/" + this.model.containerOwnership + "/" +
        this.model.bookingPartyId
      );
      this.cbSlotCntrOPR.loadSelect();

      // GSO-1064 When Booking Party=Y for COC & SOC, system will default SO Opr as <SA> --> Follow as per booking screen
      if(this.model.containerOwnership === "SOC" || this.model.containerOwnership === "COC"){
        this.model.bSlotOperatorCode = "SA";    
        this.cbSlotOPR.loadSelect(function (clcocoper) {
          if (self.cbSlotOPR.listStore.store.length > 0) {
            self.cbSlotOPR.setValue(self.cbSlotOPR.listStore.store[0]["code"]);
          }
        });
      }else{
        this.cbSlotOPR.loadSelect();
        this.model.bSlotOperatorCode = "";
      }

    }
  }

  clickToReset(){
    if (
      this.model.vesselId != "" &&
      this.model.voyage != "" &&
      this.model.bound != "" &&
      this.model.containerOwnership != ""
    ) {

      // GSO-1064 Booking Party =Y, CNTR OPR & SO OPR compulsory
      if(this.model.bookingPartyId && (!this.model.bOutwardSlotOperatorCode || !this.model.bSlotOperatorCode))
        {
          this.message(
            "information",
            "Information",
            "Please input CNTR OPR & SO OPR",
            "okonly",
            { ok: "" }
          );
          return;
      }

      this.message('save', 'Reset PSA', 'ALL bookings will be reset. Confirm to proceed? ', 'yesno', { yes: 'this.resetProcess()', no: 'this.loading = false;' });
    } else {
      this.message(
        "information",
        "Information",
        "please insert vessel, voyage & container ownership",
        "okonly",
        { ok: "" }
      );
      this.loading = false;
    }
  }

  resetProcess(){
    var uri =
      this.configService.config.getPDFUrl +
      "/ediController/transmit/psa/reset/" +
      this.model.vesselCode +
      "/" +
      this.sCharUtil.htmlEncode(String(this.model.voyage).trim()) +
      "/" +
      this.model.bound +
      "/" +
      this.genericUtil.getPSIDate() +
      "/" +
      this.model.containerOwnership +
      "/" +
      (this.model.bookingPartyId || '[null]') +
      "/" +
      (this.model.bOutwardSlotOperatorCode || '[null]') +
      "/" +
      (this.model.bSlotOperatorCode || '[null]') +
      "/" +
      this.cookieService.getName() +
      "/";

      this.genericService
      .POST(
        uri, {}
      )
      .subscribe((resp) => {
        this.loading = false;
        this.message(
          "information",
          "Information",
          resp.json()["status"],
          "okonly",
          { ok: "" }
        );
      }, err=>{
        this.loading = false;
        this.message(
          "information",
          "Information",
          "An error has occurred",
          "okonly",
          { ok: "" }
        );
      });

  }
  
}
