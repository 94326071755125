<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <br />
      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <legend style="font-weight:bold;color:dodgerblue;">Lock Maintenance</legend>
          <div class="field">
            <div class="two fields">
              <div class="field">
                <label>Country</label>
                <combo-plugin #cbCountry [settings]="settingCountry" (change)="changeEventCountry($event)" style="width:1400px;"></combo-plugin>
              </div>
              <div class="field" style="width:300px">
                <label>Lock Period</label>
                <input type="text" name="lockPeriod"  placeholder="Lock Period" [(ngModel)]="model['lockPeriod']" >
                <label  style="margin-top:-25px; margin-left:300px">Days</label>
              </div>
            </div>
          </div>
          <div class="field">            
           <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>                     
          </div>  
        </fieldset>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
