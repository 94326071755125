

export class Bank  {
  no  : number = 0;
  bankId    : string = '';
  bankStandardClause  : string = '';
  bankSwiftCode  : string = '';
  bankName     : string = '';
  bankCode     : string = '';
  bankBranch     : string = '';
  bankUSDAccNo     : string = '';
  bankSGDAccNo     : string = '';
  bankRemarks     : string = '';
  bankAddress     : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';
  officeCode  : string ='';
  isPrintedInvoice : boolean = false;

  checkValidasi : boolean = true;

  constructor () { }
}
