<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        ><div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Clauses
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Clauses
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="field">
                  <div class="fields">
                    <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-clauseName'] != null &&
                        model['error-clauseName'] != ''
                      "
                    >
                      <label>Clause Name</label>
                      <textarea-plugin
                        #txtClauseName
                        [settings]="settingClauseName"
                        (input)="onInput($event)"
                        (keypress)="
                          formValidation.ModifiedPattern($event, '-()/,.&\' ')
                        "
                        (paste)="
                          onPasteValidation(
                            $event,
                            'clauseName',
                            regClauseName,
                            '-(.)/,&\''
                          )
                        "
                      ></textarea-plugin>
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-clauseName'] != null &&
                          model['error-clauseName'] != ''
                        "
                      >
                        {{ model["error-clauseName"] }}
                      </div>
                    </div>
                    <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-clauseCode'] != null &&
                        model['error-clauseCode'] != ''
                      "
                    >
                      <label>Clause Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['clauseCode']"
                        (ngModelChange)="
                          checkIfError(
                            'clauseCode',
                            formValidation.regAlphaNumeric
                          )
                        "
                        name="clauseCode"
                        placeholder="Clause Code"
                        [disabled]="lock == true"
                        maxlength="15"
                        (keypress)="formValidation.ModifiedPattern($event)"
                        (paste)="
                          onPasteValidation(
                            $event,
                            'clauseCode',
                            formValidation.regAlphaNumeric
                          )
                        "
                      />
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-clauseCode'] != null &&
                          model['error-clauseCode'] != ''
                        "
                      >
                        {{ model["error-clauseCode"] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="field"
                  [class.error]="
                    model['error-remarks'] != null &&
                    model['error-remarks'] != ''
                  "
                >
                  <label>Remarks</label>
                  <textarea
                    [(ngModel)]="model['remarks']"
                    name="remarks"
                    placeholder="Input your remarks..."
                    [disabled]="lock == true"
                    maxlength="200"
                    (keypress)="formValidation.AllChar($event)"
                  ></textarea>
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-remarks'] != null &&
                      model['error-remarks'] != ''
                    "
                  >
                    {{ model["error-remarks"] }}
                  </div>
                </div>
              </div>

              <!--
                <div class="one fields">
                  <div class="required field" >
                    <label>Clause Name</label>


                    <textarea-plugin #txtClauseName [settings]="settingClauseName"></textarea-plugin>


                    <form id="frmTextarea">
                      <div class="papers">
                      <textarea id="mytexts" name="clauseName" placeholder="Clause Name" wrap="hard" [disabled]="lock == true" cols="25" maxlength="255" style="text-transform: uppercase"></textarea>
                      </div>
                       <input type="submit" value="Go">
                    </form>
                    -->

              <!--input type="text" [(ngModel)]="model['clauseName']" name="clauseName"  placeholder="Clause Name" [disabled]="lock == true" maxlength="50"-->
              <!---
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-clauseName'] != null && model['error-clauseName'] != ''">
                      {{model['error-clauseName']}}
                    </div>

                  </div>

                  <div class="required field" [class.error]="model['error-clauseCode'] != null && model['error-clauseCode'] != ''">
                    <label>Clause Code</label>
                    <input type="text" [(ngModel)]="model['clauseCode']" name="clauseCode"  placeholder="Clause Code" [disabled]="lock == true" maxlength="15">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-clauseCode'] != null && model['error-clauseCode'] != ''">
                      {{model['error-clauseCode']}}
                    </div>
                  </div>
                </div>
                -->
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <button class="ui button" style="display: none"></button>
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Clauses
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Clauses</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateClauseHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-clauseName'] != null &&
                      model['error-clauseName'] != ''
                    "
                  >
                    <label>Clause Name</label>
                    <combo-plugin
                      #cbClauseNameHistory
                      [settings]="settingClauseHistory"
                      (change)="valueClauseHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-clauseName'] != null &&
                        model['error-clauseName'] != ''
                      "
                    >
                      {{ model["error-clauseName"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-clauseCode'] != null &&
                      model['error-clauseCode'] != ''
                    "
                  >
                    <label>Clause Code</label>
                    <input
                      type="text"
                      [value]="valClauseCode"
                      name="clauseHistoryCode"
                      placeholder="Clause Code"
                      [disabled]="lock == true"
                      maxlength="15"
                      readonly
                      style="background-color: lightgray"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-clauseCode'] != null &&
                        model['error-clauseCode'] != ''
                      "
                    >
                      {{ model["error-clauseCode"] }}
                    </div>
                  </div>
                </div>
                <!--
                                            <div class="two fields">
                                              <div class="field">
                                                <label>Log Start Date</label>
                                                <calendar-plugin id="logHistoryClauseStartDate" [pluginType]="typeClauseLogStartDate" [settings]="settingClauseLogStartDate"></calendar-plugin>
                                              </div>
                                              <div class="field">
                                                <label>Log End Date</label>
                                                <calendar-plugin id="logHistoryClauseEndDate" [pluginType]="typeClauseLogEndDate" [settings]="settingClauseLogEndDate"></calendar-plugin>
                                              </div>
                                            </div>
                -->
                <div class="field">
                  <grid-plugin2
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin2>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
