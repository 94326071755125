export class sha_booking_edi  {
    // EDIID : string = '';
    // processed  : boolean = false;
    // FileName  : string = '';
    // VesselID : string = '';
    // VesselCode : string = '';
    // VesselName:string = '';
    // VoyageNo:string = '';
    // TotalBkg: string = '';
    // TotalBkgPending: string = '';
    // DateReceived : string = '';
    // TimeReceived : string = '';

    ediId : string = '';
    ediLinesNo : number = 0;
    rejectionReason : string = '';
    
    ediFileName : string = '';

    
        status : string = '';
        bNo : string = '';
        accept  : string = '';
        reject : string = '';
        receiptType : string = '';
        deliveryType : string = '';
        vesselId : string = '';
        vesselName : string = '';
        vesselCode : string = '';
        voyageNo : string = '';
        por : string = '';
        porName : string = '';
        pol : string = '';
        polName : string = '';
        pod : string = '';
        podName : string = '';
        pot : string = '';
        potName : string = '';
        placeOfDelivery : string = '';
        placeOfDeliveryName : string = '';
        finalDestination : string = '';
        finalDestinationName : string = '';
        freightType : string = '';
        remarks : string = '';
        shipperId : string = '';
        shipperName : string = '';
        cneeName : string = '';
        cneeId : string = '';
        notifyPartyId : string = '';
        notifyPartyName : string = '';
        innerPackageTypeCode : string = '';
        markNoDesc : string = '';
        description : string = '';
        containerType : string = '';
        containerSize : string = '';
        containerHeight : string = '';
        containerStatus : string = '';
        containerIsoCode : string = '';
        outerQuantity : string = '';
        innerQuantity : string = '';
        containerOwnership : string = '';
        
        containerQty : string = '';

    constructor() {

    }
}