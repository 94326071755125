/**
 * Created by fef on 24/1/17.
 */
export class LocationGroup {
  no : number = 0;

  locationGroupId : string = '';
  locationGroupSeq : string = '';

  countryCode : string = '';
  countryName : string = '';
  
  dischargePortId : string = '';
  dischargePortCode : string = '';
  dischargePortName : string = '';
  
  assignedPortId : string = '';
  assignedPortCode : string = '';
  assignedPortName : string = '';
  
  remarks : string = '';
  isValid : boolean = false;
  dateCreated : string = '';
  userCreated : string = '';
  dateModified : string = '';
  userModified : string = '';
  dateInvalid : string = '';
  userInvalid : string = '';

}

export class LocationGroupMember {
  no:number = 0;
  locationId: string = '';
  locationCode: string = '';
  locationName: string = '';
  locationGroup: string = '';
  locationGroupCode:string = '';
  locationGroupId: string = '';
}
