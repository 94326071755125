<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        ><div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Tare Weight Local Master
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 1%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Tare Weight Local Master
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="field">
                  <div class="fields">

                    <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-containerSizeType'] != null &&
                        model['error-containerSizeType'] != ''
                      "
                    >
                      <label style="display: contents;">Container Size & Type</label><span>(Eg:20GP,40HC,20RH...)</span>
                      <input
                        type="text"
                        [(ngModel)]="model['containerSizeType']"
                        (ngModelChange)="
                          checkIfError(
                            'containerSizeType',
                            regContainerSizeType
                          )
                        "
                        name="containerSizeType"
                        placeholder="Container Size & Type"
                        [disabled]="lock == true"
                        maxlength="5"
                        (keypress)="formValidation.ModifiedPatternAplhaNumericWithoutSpace($event)"
                        (paste)="formValidation.pasteEventRegexWith($event, model, 'containerSizeType', '[^a-zA-Z0-9]', 5)"
                      />
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-containerSizeType'] != null &&
                          model['error-containerSizeType'] != ''
                        "
                      >
                        {{ model["error-containerSizeType"] }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">

                    <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-tareWeightKGS'] != null &&
                        model['error-tareWeightKGS'] != ''
                      "
                    >
                      <label>Tare Weight (KGS)</label>
                      <input
                        type="text"
                        [(ngModel)]="model['tareWeightKGS']"
                        (ngModelChange)="
                          checkIfError(
                            'tareWeightKGS',
                            regTareWeight
                          )
                        "
                        name="tareWeightKGS"
                        placeholder="Tare Weight (KGS)"
                        [disabled]="lock == true"
                        maxlength="5"
                        (keypress)="formValidation.ModifiedPatternNumericOnly($event)"
                        (paste)="formValidation.pasteEventRegexWith($event, model, 'tareWeightKGS', '[^0-9]', 5)"

                      />
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-tareWeightKGS'] != null &&
                          model['error-tareWeightKGS'] != ''
                        "
                      >
                        {{ model["error-tareWeightKGS"] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <button class="ui button" style="display: none"></button>
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Tare Weight Local Master
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Tare Weight</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateTareWeightHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-containerSizeType'] != null &&
                      model['error-containerSizeType'] != ''
                    "
                  >
                  <label style="display: contents;">Container Size & Type</label><span>(Eg:20GP,40HC,20RH...)</span>

                    <combo-plugin
                      #cbContainerSizeTypeHistory
                      [settings]="settingContainerSizeTypeHistory"
                      (change)="valueContainerSizeTypeHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-containerSizeType'] != null &&
                        model['error-containerSizeType'] != ''
                      "
                    >
                      {{ model["error-containerSizeType"] }}
                    </div>
                  </div>


                </div>

                <div class="field">
                  <grid-plugin2
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin2>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
