<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        ><div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Local Master Freight Group
        </h4>
        <!-- Begin of Grid -->
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Local Master Freight Group
            </h4>

            <div class="three fields">
              <div class="field">
                <label><br /></label>
                <div
                  class="ui test toggle checkbox"
                  [class.disabled]="lock == true"
                >
                  <input
                    type="checkbox"
                    [(ngModel)]="model['isValid']"
                    name="isValid"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>Valid</label>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >General</a
                  >

                  <div class="ten wide fields">
                    <div class="one field" style="width: 100%">
                      <div class="eight wide fields">
                        <div
                          class="required five field"
                          style="width: 100%"
                          [class.error]="
                            model['error-dischargePortName'] != null &&
                            model['error-dischargePortName'] != ''
                          "
                        >
                          <label>Freight Group Name</label>

                          <combo-plugin
                            #cbDischargePort
                            [settings]="settingDischargePort"
                            (change)="changeEventDischargePort($event)"
                          ></combo-plugin>

                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-dischargePortName'] != null &&
                              model['error-dischargePortName'] != ''
                            "
                          >
                            {{ model["error-dischargePortName"] }}
                          </div>
                        </div>
                        <div
                          class="two required field"
                          style="width: 100%"
                          [class.error]="
                            model['error-dischargePortCode'] != null &&
                            model['error-dischargePortCode'] != ''
                          "
                        >
                          <label>Port Code</label>

                          <input
                            type="text"
                            [(ngModel)]="model['dischargePortCode']"
                            (ngModelChange)="
                              model['error-dischargePortCode'] = ''
                            "
                            name="dischargePortCode"
                            placeholder="Location Group Code"
                            maxlength="200"
                            [disabled]="lock == true"
                            readonly
                            style="background-color: lightgray"
                          />

                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-dischargePortCode'] != null &&
                              model['error-dischargePortCode'] != ''
                            "
                          >
                            {{ model["error-dischargePortCode"] }}
                          </div>
                        </div>
                      </div>

                      <div class="eight wide fields">
                        <div
                          class="field"
                          style="width: 100%"
                          [class.error]="
                            model['error-remarks'] != null &&
                            model['error-remarks'] != ''
                          "
                        >
                          <label>Remarks</label>
                          <textarea
                            style="margin-top: 5px"
                            [(ngModel)]="model['remarks']"
                            (ngModelChange)="model['error-remarks'] = ''"
                            name="remarks"
                            placeholder="Input your remarks..."
                            [disabled]="lock == true"
                            maxlength="200"
                          ></textarea>
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-remarks'] != null &&
                              model['error-remarks'] != ''
                            "
                          >
                            {{ model["error-remarks"] }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >Details</a
                  >

                  <div id="displayGrid">
                    <div class="one fields">
                      <div class="field" style="width: 100%">
                        <div class="ui icon tiny buttons">
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true"
                            data-content="Add"
                            (click)="addDetail()"
                          >
                            <i class="plus icon"></i>
                          </button>
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true"
                            data-content="Remove"
                            (click)="removeDetail()"
                          >
                            <i class="minus icon"></i>
                          </button>
                        </div>
                        <grid-plugin
                          id="detail-location-group"
                          #gridDetails
                          [settings]="settingGridDetails"
                          (gridEvent)="gridEventDetails($event)"
                          (infoGrid)="infoGridDetails($event)"
                        >
                        </grid-plugin>
                      </div>
                    </div>
                  </div>

                  <div id="display">
                    <div class="two fields">
                      <div
                        class="required field"
                        [class.error]="
                          modelDetail['error-locationName'] != null &&
                          modelDetail['error-locationName'] != ''
                        "
                      >
                        <label>Member Port</label>

                        <combo-plugin
                          #cbAssignedPort
                          [settings]="settingAssignedPort"
                          (change)="changeEventAssignedPort($event)"
                        ></combo-plugin>

                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-locationName'] != null &&
                            modelDetail['error-locationName'] != ''
                          "
                        >
                          {{ modelDetail["error-locationName"] }}
                        </div>
                      </div>
                      <div class="field">
                        <label>Port Code</label>
                        <input
                          type="text"
                          [(ngModel)]="modelDetail['locationCode']"
                          name="locationCode"
                          placeholder="Location Code"
                          [disabled]="lock == true"
                          maxlength="50"
                          readonly
                          style="background-color: lightgray"
                        />
                      </div>
                    </div>

                    <div class="two fields">
                      <div class="field" style="width: 100%"></div>
                      <div class="field" style="width: 100%">
                        <div class="ui buttons">
                          <button class="ui button" (click)="cancelDetail()">
                            Cancel
                          </button>
                          <div class="or"></div>
                          <button
                            class="ui positive button"
                            (click)="saveDetail()"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Freight Group
            </h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Location Groups</a>

              <div class="one fields" style="margin-top: 10px">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">
                    Retrieve
                  </button>
                  <button
                    class="ui button"
                    (click)="generateLocationGroupsHistoryReport($event)"
                  >
                    Export
                  </button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>Freight Group Name</label>
                  <combo-plugin
                    #cbDischargePortHistroy
                    [settings]="settingDischargePortHistory"
                    (change)="changeEventDischargePortHistory($event)"
                  ></combo-plugin>
                </div>
              </div>

              <div class="field">
                <grid-plugin
                  #gridHistory
                  [settings]="settingGridHistory"
                  (gridEvent)="gridEventHistory($event)"
                  (infoGrid)="infoGridHistory($event)"
                ></grid-plugin>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
