import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild  } from '@angular/core';
import { MSailingSchedule,VesselAllocation, MSailingScheduleDetail, Booking } from '../shared/index';
import {
  ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil,
  ConfigService, CookieService, DatetimePluginComponent
} from 'sibego-ui-library';
import { Router } from '@angular/router';
import {AllocationModel} from "../shared/models/allocationModel";
declare  var $:any;

/*
export class ModelAllotment{
  bOceanVesselBound:string="";
  bOceanVesselCode:string="";
  bOceanVesselId:string="";
  bOceanVesselVoyage:string="";
  bOfficeCode:string="";
  constructor(){}
}*/

@Component({
  selector: 'app-transaction-vessel-listing-byportofcall-page',
  templateUrl: './transaction-vessel-listing-byportofcall-page.component.html',
  styleUrls: ['./transaction-vessel-listing-byportofcall-page.component.css']
})
export class TransactionVesselListingByportofcallPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridAllot') gridAllot:GridPluginComponent;
  /* Parameter for information into Footer */
  @ViewChild('cbpol') cbPol:ComboPluginComponent;
  @ViewChild('cbpod') cbPod:ComboPluginComponent;
  @ViewChild('cbTerminalPol') cbTerminalPol:ComboPluginComponent;
  @ViewChild('cbTerminalPod') cbTerminalPod:ComboPluginComponent;
  @ViewChild('periodFrom') periodFrom:DatetimePluginComponent;
  @ViewChild('periodTo') periodTo:DatetimePluginComponent;


  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;
  isError:boolean = false;
  /* Parameter form (Default : '') */
  form = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridAllot;
  settingPol;
  settingPod;
  settingTerminalPol;
  settingTerminalPod;
  settingPeriodFrom;
  settingPeriodTo;

  currentMenu;
  viewSOCStatus = false;
  viewCOCStatus = false;

  isLoad : boolean = false;

  valHasTerminal : String = '';
  valTerminalLoadId : String = '';
  valTerminalDischargeId : String = '';
  valTerminalLoadCode : String = '*';
  valTerminalDischargeCode : String = '*';
  valPortLoadId: String = '';
  valPortDischargeId: String = '';
  valPortLoadCode: String = '';
  valPortDischargeCode: String = '*';
  valPortDischargeCodePrint: String = '';
  valPeriodFrom: String = '';
  valPeriodTo: String = '';
  valBound : String ='O';
  officeCode = ""//(localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
  combo: String = '';
  globalALL : Number = 0;
  stopByAll = '';
  stopDetection : Number = 0;
  labelMenu : String ="";

  typePeriodFrom = 'calendar';
  typePeriodTo = 'calendar';

  lockTerminal = false;

  /* Parameter model */
  model = new MSailingSchedule;
  modelDetail = new VesselAllocation;
  selectList = [];

  loading = false;

  /* validatorRules */
  validatorRules = {}



  constructor(private auth:Authorize, private genericService:GenericService, private router: Router, private configService:ConfigService,private genericUtil:GenericUtil, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Close',  event: 'close', icon : 'power' },
        { name : 'Export to Excel',  event: 'print', icon : 'file excel outline' },

      ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [ ],
      toolbarType           : 'label',
      label                 : this.labels(),
      widthLabel            : '310',
    }

    this.settingGrid = {
        id : "abc",
        url: this.configService.config.BASE_API.toString() +'/MasterSailingSchedules/findGeneralVesselListing',//this.configService.config.BASE_API.toString()+'/MasterSailingSchedules/findGeneral/Y/SGSIN/*/*/*/2017-04-01/2017-04-30',
        page: 5,
        columns: [
                    {header: 'Stop', field: 'stopBooking', width: 50},
                    {header: 'Vessel', field: 'vesselName', width: 300, type: 'string'},
                    {header: 'Vessel Code', field: 'vesselCode', width: 100},
                    {header: 'Voyage', field: 'voyage', width: 100},
                    {header: 'Bound', field: 'bound', width: 100},
                    {header: 'Load Port', field: 'portofLoadingName', width: 200},
                    {header: 'Terminal', field: 'terminalCodeName', width: 100},
                    {header: 'ETA POL', field: 'arrivalDateLoading', width: 300, type: 'string'},
                    {header: 'ETD POL', field: 'sailDateLoading', width: 300, type: 'string'},
                    {header: 'Discharge Port', field: 'portofDischargeName', width: 200},
                    {header: 'Terminal', field: 'terminalCodeDischargeName', width: 100},
                    {header: 'ETA POD', field: 'destArrival', width: 300},
                    {header: 'ETD POD', field: 'destSail', width: 300}
        ],
        buttons: [ ],
        enableSorting: false,
        enableSelectAll: false,
        enablePagination: false,
        sortingColumns:'vesselName',
        sortingDirection: 'ASC'
    }

     this.settingGridAllot = {
        id:'gridAllot',
        url: '',
        page: 5,
        columns: [
                    {header: 'Level', field: 'allotmentLevel', width: 50},
                    {header: 'Description', field: 'allotmentCode', width: 200, type: 'string'},
                    {header: 'Allocated', field: 'alloted', width: 100},
                    {header: 'Booked', field: 'booked', width: 100},
                    {header: 'Reserved', field: 'reserved', width: 100},
                    {header: 'Available', field: 'available', width: 100}
        ],
        buttons: [ ],
        enableSorting: true,
        enableSelectAll: false,
        enablePagination: false
    }

    this.settingPol = {
       id          : 'cbPol',
       type        : 'search enter', // search | select | select input
       //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationNameOrLocationCode/{query}/1/100',
       url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
       maxChars    : 3,
       template    : 'grid', // default
       placeholder : 'Search Port of Loading',
       title       : 'locationName',
       description  : '',
       isMark      : true,
       columns     : [
                     {header: 'Location Name', field: 'locationName', width: 400,  type: 'string'},
                     {header: 'Location Code', field: 'locationCode', width: 100,  type: 'string'},
       ]
    }
    this.settingPod = {
       id          : 'cbPod',
       type        : 'search enter', // search | select | select input
       //url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationNameOrLocationCode/{query}/1/100',
       url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
       maxChars    : 3,
       template    : 'grid', // default
       placeholder : 'Search Port Of Discharge',
       title       : 'locationName',
       description  : '',
       isMark      : true,
       columns     : [
                     {header: 'Location Name', field: 'locationName', width: 400,  type: 'string'},
                     {header: 'Location Code', field: 'locationCode', width: 100,  type: 'string'},
       ]
    }

    this.settingTerminalPod = {
       id          : 'cbTerminalPod',
       type        : 'search enter', // search | select | select input
       url         : '',
       maxChars    : 0,
       template    : 'grid', // default
       placeholder : 'Search Terminal',
       title       : 'locationName',
       description  : '',
       isMark      : true,
       columns     : [
                     {header: 'Location Name', field: 'locationName', width: 200},
                     {header: 'Location Code', field: 'locationCode', width: 50},
       ]
    }

    this.settingTerminalPol = {
       id          : 'cbTerminalPol',
       type        : 'search enter', // search | select | select input
       url         : '',
       maxChars    : 0,
       template    : 'grid', // default
       placeholder : 'Search Terminal',
       title       : 'locationName',
       description  : '',
       isMark      : true,
       columns     : [
                     {header: 'Location Name', field: 'locationName', width: 200},
                     {header: 'Location Code', field: 'locationCode', width: 50},
       ]
    }

    this.settingPeriodFrom = {
      id:'periodFrom',
      type : 'date',
      format : 'yyyy-mm-dd',
      placeholder : '',
      customtext :'',
      popupOptions : 'bottom left'
    }

    this.settingPeriodTo = {
      id:'periodTo',
      type : 'date',
      format : 'yyyy-mm-dd',
      placeholder : '',
      customtext :'',
      popupOptions : 'bottom right'
    }

  }

  ngOnInit(){

  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    $('#vslListingBound').on('click', function () {
      $(this).val('');
    });
    $('#vslListingBound').on('mouseleave', function () {
      if ($(this).val() == '') {
        $(this).val('O');
      }
    });

    this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode();

    this.valPortLoadCode= (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode();
    this.valPortLoadId=(this.cookieService.getDefaultLocationId() == null) ? '*':this.cookieService.getDefaultLocationId();

    //FIX-ME set default POL

    //this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationCode/{query}');
    this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    if(!localStorage.getItem("vessel-listing-last-searched") || localStorage.getItem("vessel-listing-last-searched") == null){
      this.cbPol.setValue( (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode());
    }else{
      this.cbPol.setValue((localStorage.getItem("vessel-listing-last-searched-valPortLoadCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valPortLoadCode"):"");
    }

    //ser url terminal
    this.cbTerminalPol.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.valPortLoadId+'/{query}';
    this.cbTerminalPol.setValue("");

    //set default value for bound
    this.model.bound='O';

    //reset isLoad
    this.isLoad=false;

    this.periodFrom.setValue("");
    this.periodFrom.init();
    this.periodTo.setValue("");
    this.periodTo.init();


    if(localStorage.getItem("vessel-listing-last-searched") != null){

      this.setValidatorHeader();

      let valPol = (localStorage.getItem("vessel-listing-last-searched-valPortLoadCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valPortLoadCode"):"*";
      let valPolTerminal = (localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode"):"*";
      let valPod = (localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode"):"*";
      let valPodTerminal = (localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode"):"*";


      this.cbPol.setValue((localStorage.getItem("vessel-listing-last-searched-valPortLoadCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valPortLoadCode"):"");
      this.cbTerminalPol.setValue((localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") != "*") ? localStorage.getItem("vessel-listing-last-searched-valTerminalLoadCode") : "");

      this.cbPod.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
      this.cbPod.setValue((localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode") != "*")?localStorage.getItem("vessel-listing-last-searched-valPortDischargeCode"):"");


      //this.cbTerminalPod.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.valPortDischargeId+'/{query}';
      this.cbTerminalPod.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
      var self = this;
      this.cbTerminalPod.setValue((localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode") != "*") ? localStorage.getItem("vessel-listing-last-searched-valTerminalDischargeCode") : "", function(callbackTPod){
        self.cbTerminalPod.setUrl(self.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+valPod+'/{query}');
      });

      this.valBound = localStorage.getItem("vessel-listing-last-searched-valBound");
      $('input[name="bound"]').val(this.valBound);

      this.valPeriodFrom =  localStorage.getItem("vessel-listing-last-searched-valPeriodFrom");
      this.valPeriodTo =  localStorage.getItem("vessel-listing-last-searched-valPeriodTo");
      this.periodFrom.setValue(this.valPeriodFrom);
      this.periodTo.setValue(this.valPeriodTo);

      this.valPortLoadCode = valPol;
      this.valTerminalLoadCode = valPolTerminal;
      this.valPortDischargeCode = valPod;
      this.valTerminalDischargeCode = valPodTerminal;



      //this.grid.url="";
      //this.grid.search="";
      //this.grid.url =  localStorage.getItem("vessel-listing-last-searched-url");
      this.grid.search = valPol+'/'+valPolTerminal+'/'+valPod+'/'+valPodTerminal+'/'+this.valBound+'/'+this.valPeriodFrom+'/'+this.valPeriodTo+'/'+ this.officeCode;
      //this.grid.loadData();
      this.handleRetrieve();
      //this.grid.search = this.valPortLoadCode+'/'+this.valTerminalLoadCode+'/'+this.valPortDischargeCode+'/'+this.valTerminalDischargeCode+'/'+this.valBound+'/'+this.valPeriodFrom+'/'+this.valPeriodTo+'/'+ this.officeCode;

      //Clean up the cache


    }



  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridAllot(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
        break;

      default:

        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              //ar data = this.listStore.findData(Number.parseInt(arr[1]));
              this.selectList = [];
              //this.selectList.push(data);
              this.handleUpdate();

              break;
            case 'click.checked':

              var no = event.split(".")[1].split("-")[1];
              this.gridAllot.onClear();
              this.selectedListMod(no, this.grid,'no');
              let dt = this.grid.getSelectedValues()[0];

              //this.getAllot(dt.vesselCode.trim(),dt.voyage,dt.bound);
              this.getAllot(dt);

              break;
            case 'click.unchecked' :
              this.grid.clearSelectedValues();
              this.gridAllot.onClear();
              break;
            case 'dblClick':
              this.globalALL = 0;
              this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
              var infoMsg = '';
              console.log('current Menu ==> ' + this.currentMenu);

              if(!this.currentMenu.includes("(View Only)")){
                if(this.grid.getSelectedValues()[0].bound.trim() == 'O'){
                  this.genericUtil.showLoaderPage();
                  var self=this
                  //stop booking validation
                  console.log(this.grid.listStore.store)
                  console.log(self.grid.getSelectedValues())
                  var locPort = this.valPortDischargeCode

                  //gso-387
                  if (this.currentMenu.includes("(SOC)")) {
                    self.viewSOCStatus = true;
                  }

                  if (this.currentMenu.includes("(COC)")) {
                    self.viewCOCStatus = true;
                  }
   // }
                    /* old validation
                    this.genericService.GET(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/getStopBookingDataWithPortOfCall/" + this.grid.getSelectedValues()[0].vesselId + "/" + this.grid.getSelectedValues()[0].voyage + "/" + this.grid.getSelectedValues()[0].bound + "/" + this.officeCode+'/'+locPort+'/'+'ALL'+'/'+'BOTH'+'/'+'ALL').subscribe((resp) => {
                      //this.genericService.GET("http://localhost:10000/MasterSailingSchedules/getStopBookingDataWithPortOfCall/" + this.model.bOceanVesselId + "/" + this.model.bOceanVesselVoyage + "/" + this.model.bOceanVesselBound + "/" + this.model.bOfficeCode+'/'+locPort+'/'+this.model.bContainerOwnership+'/'+this.model.bContainerStatus).subscribe((resp) => {

                      if (resp.status == 200) {

                        if(Object.keys(resp.json()['content']).length>0) {

                          this.stopDetection = 1;
                          console.log('mmmasuk lenght')
                          resp.json()['content'].forEach(spal=>{
                             this.stopByAll = spal.stopby
                            if(spal.portofcall == 'ALL' && spal.bcntowner == 'ALL' && spal.bcntstatus == 'BOTH' && spal.slotoperator == ''){
                              console.log('mmmasuk ALL')
                              this.globalALL = 1;

                              //this.globalALL = 'ALL'
                            }else{
                              infoMsg += spal.portofcall
                              if(spal.terminalcode != '') infoMsg+= ', '+spal.terminalcode
                              if(spal.bcntstatus != 'BOTH') infoMsg+= ', '+spal.bcntstatus
                              if(spal.bcntowner != 'ALL') infoMsg+= ', '+spal.bcntowner
                              if(spal.slotoperator != '') infoMsg+= ', '+spal.slotoperator.toUpperCase()
                              infoMsg +=' bookings stopped by '+ this.stopByAll.toUpperCase()+'.<br/>'
                            }





                          })
                        }else{//end object key lenght
                          this.globalALL = 0;
                          this.stopDetection = 0;
                        }

                      }else{
                        var self = this;
                        setTimeout(function() {
                          //self.message('information','Information','Information','Error Occured, please contact administrator.', 'okonly', { ok: 'this.loading=false;'});
                          //self.message('information','Information','Information','Error Occured, please contact administrator.', 'okonly', { ok: 'this.loading=false;'});
                        }, 50);
                      }

                    });

                  //end stop booking validation
                    */

                  //setTimeout(function(){

                    if(this.grid.getSelectedValues()[0].stopBooking == 'Y'){
                      console.log('STOP detection '+this.stopDetection )
                    //check if there any all booking stop data
                    /*
                      if(self.globalALL == 1){
                            self.genericUtil.hideLoader()
                            self.message('information','Saving data','All bookings stopped by '+self.stopByAll.toUpperCase()+'.', 'okonly', { ok: 'this.loading=false'});

                      }else{

                           self.genericUtil.hideLoader()
                            self.message('information','Information',infoMsg, 'okonly', { ok: 'this.loading=false'});

                      }
                      */
                     this.genericUtil.hideLoader()
                     this.message('information','Saving data','Booking has been stopped.', 'okonly', { ok: 'this.loading=false'});
                            //if(self.model.bPot1Code != '')locWarn = self.model.bPot1Code
                            //else
                            //self.dialogPlugin.show('save','Saving data',locWarn+','+self.model.bContainerStatus+','+self.model.bContainerOwnership+','+self.model.bSlotOperatorCode+' stopped by '+self.stopByAll.toUpperCase()+'.', 'okonly', { ok: 'this.loading = false;return false'});
                    }else{

                        localStorage.setItem("new-booking-from-vessel-listing",JSON.stringify(self.grid.getSelectedValues()[0]));

                        localStorage.setItem("vessel-listing-last-searched", JSON.stringify(self.grid.listStore.store));
                        localStorage.setItem("vessel-listing-last-searched-selected-no",self.grid.getSelectedValues()[0].no);
                        localStorage.setItem("vessel-listing-last-searched-url", self.grid.url);
                        localStorage.setItem("vessel-listing-last-searched-search", self.grid.search);
                        localStorage.setItem("vessel-listing-last-searched-valPortLoadCode", self.valPortLoadCode.toString());
                        localStorage.setItem("vessel-listing-last-searched-valTerminalLoadCode", self.valTerminalLoadCode.toString());
                        localStorage.setItem("vessel-listing-last-searched-valPortDischargeCode", self.valPortDischargeCode.toString());
                        localStorage.setItem("vessel-listing-last-searched-valTerminalDischargeCode", self.valTerminalDischargeCode.toString());
                        localStorage.setItem("vessel-listing-last-searched-valBound", self.valBound.toString());
                        localStorage.setItem("vessel-listing-last-searched-valPeriodFrom", self.valPeriodFrom.toString());
                        localStorage.setItem("vessel-listing-last-searched-valPeriodTo", self.valPeriodTo.toString());
                        localStorage.setItem("vessel-listing-last-searched-valOfficeCode", self.officeCode.toString());
                         //gso-387
                        localStorage.setItem("vessel-listing-last-searched-viewSOCStatus", self.viewSOCStatus.toString());
                        localStorage.setItem("vessel-listing-last-searched-viewCOCStatus", self.viewCOCStatus.toString());

                        self.router.navigate(["/main/transaction-booking-maintenance"]);
                    }

                  //},800);

                  //this.message('information','Information','This action will redirect to Booking Maintenance.<br/>Not implemented yet.', 'okonly', { ok: 'this.loading=false;'});
                }else{
                  this.message('information','Information','Booking entry allowed  for Outbound vessel only.', 'okonly', { ok: 'this.loading=false;'});
                }
              }
            break;

	     case 'afterLoad':
        if(this.grid.listStore.store.length == 0){
          if(this.isLoad){
            this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=false;this.isLoad=false;'});
          }
        }

        if(localStorage.getItem("vessel-listing-last-searched") != null){

            this.grid.clearSelectedValues();
            this.grid.onSelect(true, Number.parseInt(localStorage.getItem("vessel-listing-last-searched-selected-no")));
            localStorage.removeItem("vessel-listing-last-searched");

            localStorage.removeItem("vessel-listing-last-searched-url");
            localStorage.removeItem("vessel-listing-last-searched-search");
            localStorage.removeItem("vessel-listing-last-searched-valPortLoadCode");
            localStorage.removeItem("vessel-listing-last-searched-valTerminalLoadCode");
            localStorage.removeItem("vessel-listing-last-searched-valPortDischargeCode");
            localStorage.removeItem("vessel-listing-last-searched-valTerminalDischargeCode");
            localStorage.removeItem("vessel-listing-last-searched-valBound");
            localStorage.removeItem("vessel-listing-last-searched-valPeriodFrom");
            localStorage.removeItem("vessel-listing-last-searched-valPeriodTo");
            localStorage.removeItem("vessel-listing-last-searched-valOfficeCode");

            localStorage.removeItem("vessel-listing-last-searched-selected-no");
        }
	     break;

            default:
            break;
          }

        }

        break;
    }
  }

    gridEventAllot(event) {

    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
            break;
          }

        }

        break;
    }

  }

  toolbarEvent(event) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      case 'close':
        this.handleClose();
        break;
      case 'retrieve':
        this.handleRetrieve();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
   // this.model = new <CHANGE MODEL>;
  }


  handleUpdate()
  {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;

    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";
  }

  labels(){

    this.labelMenu = "";
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("SOC")) {
      this.labelMenu = "Vessel Listing by Port of Call SOC";
    } else if  (this.currentMenu.includes("COC")) {
      this.labelMenu = "Vessel Listing by Port of Call COC";
    }else{
      this.labelMenu = "Vessel Listing by Port of Call";
    }

    return this.labelMenu;

  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    // handle print event

    if(this.model.bound=='' || this.model.bound==null || this.model.bound==undefined){
      this.valBound='*';
    }

    if(this.valPortLoadCode == '' ||  this.valPeriodFrom == '' || this.valPeriodTo == ''){
      this.message('warning','Warning','Please select (1) data before exporting.','okonly',{ok:''});
    }else {
      console.log(location.protocol);
      var uri = this.configService.config.BASE_API.toString() +
        '?q=/Mreport/runReport/54/Vessel-Listing-ByPortOfCall/' +
        this.valPortLoadCode +
        '@' +  ((this.valTerminalLoadCode == '*') ? '' : this.valTerminalLoadCode)  +
        '@' + this.valPortDischargeCodePrint +
        '@' + ((this.valTerminalDischargeCode == '*') ? '' : this.valTerminalDischargeCode) +
        '@' + this.valPeriodFrom +
        '@' + this.valPeriodTo +
        '@' + this.officeCode +
        '@' + this.valBound;

      this.loading = true;
      var self = this;

      $.ajax({
        url: uri,
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Token', localStorage.getItem('token'));
      },
        success: function (data) {

          console.log("#3")
          console.log(data)


            self.loading = false;
            var a = document.createElement('a');
            var url = window.URL.createObjectURL(data);
            a.href = url;
            a.download = 'VesselListingByPortOfCall.xls';
            a.click();
            window.URL.revokeObjectURL(url);

        }
        });



        //window.open(prot+'//'+location.hostname + ':10000/Mreport/runReport/54/Vessel-Listing-ByPortOfCall/'+this.valPortLoadCode + '@'+  ((this.valTerminalLoadCode == '*') ? '' : this.valTerminalLoadCode)  +'@' + this.valPortDischargeCodePrint + '@'+ ((this.valTerminalDischargeCode == '*') ? '' : this.valTerminalDischargeCode) + '@' + this.valPeriodFrom+ '@' + this.valPeriodTo + '@' + this.officeCode+'@'+this.valBound);
    }
}

  handleSave()
  {
    // handle save event
    //let isValid:boolean = this.onValidate(this.model);

    //if(!isValid){
      this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
   // }else{
      this.loading = false;
    //}
  }

  handleCancel(){
    // handle cancel event

    this.modeForm = false;
    this.modeToolbar = false;
    //this.officeCode = (localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
    this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode();


    //FIX-ME set default POL
    var urlTemp = '';

    /*
    if(this.model.bound == 'I'){
      urlTemp = this.cbPod.url;
      this.cbPod.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationName/{query}');
      this.cbPod.setUrl(urlTemp);
      this.cbPod.setValue((localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode"));
      this.cbPol.setValue("");

      this.valTerminalLoadCode='*';
      this.valTerminalDischargeCode='*';
      this.valPortLoadCode='*';
      this.valPortDischargeCode=this.officeCode;
      this.valPeriodFrom='';
      this.valPeriodTo='';

    }else{
      urlTemp = this.cbPol.url;
      this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationName/{query}');
      this.cbPol.setUrl(urlTemp);
      this.cbPol.setValue((localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode"));
      this.cbPod.setValue("");

      this.valTerminalLoadCode='*';
      this.valTerminalDischargeCode='*';
      this.valPortLoadCode=this.officeCode;
      this.valPortDischargeCode='*';
      this.valPeriodFrom='';
      this.valPeriodTo='';
    }
*/

    //reset all into outbound
    urlTemp = this.cbPol.url;
    //this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    //this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByLocationCode/{query}');
    //this.cbPol.setUrl(urlTemp);
    //this.cbPol.setValue((this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode());

    this.cbPod.setValue("");
    this.cbTerminalPol.close();
    this.cbPod.close();
    this.cbTerminalPod.close();

    this.valTerminalLoadCode='*';
    this.valTerminalDischargeCode='*';
    this.valPortLoadCode=this.officeCode;
    this.valPortDischargeCode='*';
    this.valPeriodFrom='';
    this.valPeriodTo='';
    this.valBound='O';
    this.model.bound='O';


    this.cbTerminalPod.setValue("");
    this.cbTerminalPol.setValue("");

    //$('#periodFrom').find('#datePicker').val("");
    //$('#periodTo').find('#datePicker').val("");
    this.periodFrom.clearCalendar();
    this.periodTo.clearCalendar();


    this.grid.onClear();
    this.gridAllot.onClear();
    this.isError=false;

    this.model["error-localETA"]="";
    this.model["error-localETD"]="";
    this.model["error-periodRange"]="";


    this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationCode={query}');
    this.cbPol.setValue((this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode());


  }

  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }

  handleClose()
  {
    // handle close event
    this.router.navigateByUrl("/main/home");
  }

  handleRetrieve(){

    this.setValidatorHeader();
    this.gridAllot.onClear();
//    this.valPeriodFrom =$('#periodFrom').find('#datePicker').val();
  //  this.valPeriodTo = $('#periodTo').find('#datePicker').val();
    this.valPeriodFrom =this.periodFrom.getValue();
    this.valPeriodTo = this.periodTo.getValue();


    this.model.localETA = String(this.valPeriodFrom);
    this.model.localETD = String(this.valPeriodTo);
    this.model.portofLoadingCode = String(this.valPortLoadCode);
    this.valBound = $('input[name="bound"]').val().toUpperCase();

    if(this.model.bound=='' || this.model.bound==null || this.model.bound==undefined){
     this.valBound='*';
    }

    //let isValid:boolean = this.onValidate(this.model);
    this.isError = this.onValidate(this.model);
    if(!this.isError){
         this.grid.search = this.valPortLoadCode+'/'+this.valTerminalLoadCode+'/'+this.valPortDischargeCode+'/'+this.valTerminalDischargeCode+'/'+this.valBound+'/'+this.valPeriodFrom+'/'+this.valPeriodTo+'/'+ this.officeCode;

        this.model["error-periodRange"] = "";
	      this.isLoad = true;
        this.grid.loadData();

    }else{
      if(this.model["error-localETA"] != "" && this.model["error-localETD"] != ""){
        this.model["error-periodRange"] = "Please input period range.";
      }else if(this.model["error-localETA"] != "" && this.model["error-localETD"] == ""){
        this.model["error-periodRange"] = "Please input period range.";
      }else if(this.model["error-localETA"] == "" && this.model["error-localETD"] != ""){
        this.model["error-periodRange"] = "Please input period range.";
      }

    }

    //
     //this.grid.setBody(this.model);

  }



  saveEvent(){
    this.loading = true;

    this.loading = false;
    this.info = 'save successfully';
    this.grid.clearSelectedValues();
    this.grid.onFirst();

    this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;'});

  }

  deleteEvent(){
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.xxx);
    });
    this.genericService.DELETE('<CHANGE URL DELETE>',deleteList).subscribe((resp) =>{
          if(resp.status == 200){
              //this.model = new <CHANGE MODEL>;
              this.selectList = [];
              this.loading = false;
              this.grid.clearSelectedValues();
              this.grid.onFirst();

              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;

      this.info = error.message;
    });

  }

   lockingTerminal(terminal, combo){
      if(terminal == 'N'){
        this.lockTerminal = false;
        if(combo == 'cbTerminalPod'){
          this.cbTerminalPod.setValue("");
        }else{
          this.cbTerminalPol.setValue("");
        }
        $('input[name="model[detail][terminalCode]"]').val("");
      }else{
        this.lockTerminal = false;
      }
    }

  changeEventPol(event) {
    if(event['locationId'] != null){
        this.valPortLoadId = event['locationId'];
        this.valPortLoadCode = event['locationCode'];

        //set the office code
        this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode();
      }else{
        this.valPortLoadId = "";
        this.valPortLoadCode = "*";
      }

    if(event['hasTerminal'] != null){
        this.valHasTerminal = event['hasTerminal'];
        this.combo = 'cbTerminalPol';
        this.lockingTerminal(this.valHasTerminal, this.combo);
      }else{
        this.valHasTerminal ="*";
        this.valTerminalLoadCode = "*";
      }
      this.cbTerminalPol.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.valPortLoadId+'/{query}';
      this.cbTerminalPol.setValue("");
    this.cbPol.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');
    this.cbTerminalPol.close();
    this.cbPod.close();
    this.cbTerminalPod.close();
  }

  changeEventPod(event) {
    if(event['locationId'] != null){
        this.valPortDischargeId = event['locationId'];
        this.valPortDischargeCode = event['locationCode'];
        this.valPortDischargeCodePrint = event['locationCode'];
    }else{
        this.valPortDischargeId = "";
        this.valPortDischargeCode = "*";
        this.valPortDischargeCodePrint = "";
      }

    if(event['hasTerminal'] != null){
        this.valHasTerminal = event['hasTerminal'];
        this.combo = 'cbTerminalPod';
        this.lockingTerminal(this.valHasTerminal, this.combo);
      }else{
        this.valHasTerminal ="";
        this.valTerminalDischargeCode ="*";
      }

    this.cbPod.setUrl(this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}');

    this.cbTerminalPod.url =  this.configService.config.BASE_API.toString() + '/MasterTerminals/findTerminalByLocation/'+this.valPortDischargeId+'/{query}';
    this.cbTerminalPod.setValue("");
  }

  changeEventTerminalPol(event) {
    if(event['locationId'] != null){
     this.valTerminalLoadCode = event['locationCode'];
    }else{
      this.valTerminalLoadCode = "*";
    }
  }

  changeEventTerminalPod(event) {
    if(event['locationId'] != null){
      this.valTerminalDischargeCode = event['locationCode'];
    }else{
       this.valTerminalDischargeCode ="*";
    }
  }

   setValidatorHeader() {
     this.validatorRules = {
       portofLoadingCode: {
          rules: [
            {
              type   : 'empty',
              prompt : 'Please input Port of Loading.',
            }
          ]
        },
        localETA: {
          rules: [
            {
              type   : 'empty',
              prompt : 'Please input period range.',
            }
          ]
        },
        localETD: {
          rules:[
            {
              type  : 'empty',
              prompt : 'Please input period range.',
            }
          ]
        },
        bound:{
          rules:[{
            type: "empty",
            prompt: "Please input bound."
          }]
        },
     }
   }

  getAllot(dt:any){
     /*
    var hiturl = this.configService.config.BASE_API.toString() +'/VesselAllocation/findByVesselVoy/'+vesselCode+'/'+voyage+'/'+bound;
     this.grid.search = '*';
     this.genericService.GET(hiturl).subscribe((resp) =>{
          if(resp.ok){
            if(resp.json()['content'] != null && resp.json()['content'] != "" && resp.json()['content'] != undefined){
              if(resp.json()['content'][0]['vesselAllocationDetails'].length > 0){
                this.gridAllot.listStore.store= resp.json()['content'][0]['vesselAllocationDetails'];
                this.gridAllot.listStore.store.forEach(dataAllot=>{
                  dataAllot['available'] = (parseInt(dataAllot['alloted'])-(parseInt(dataAllot['booked'] + parseInt(dataAllot['reserved'])))).toString();
                });
                this.gridAllot.loadData();
              }
            }
          }
     });
     */
     this.gridAllot.loading = true;
     var hiturl = this.configService.config.BASE_API.toString() + '/MasterBookings/calculateAllocation2';
     var param = new Booking();
     param.bOceanVesselId = dt.vesselId;
     param.bOceanVesselCode=dt.vesselCode;
     param.bOceanVesselVoyage=dt.voyage;
     param.bOceanVesselBound=dt.bound;
     param.bOfficeCode = this.cookieService.getDefaultLocationCode();


     this.grid.search='*';
     this.genericService.POST(hiturl,param).subscribe((resp) => {
       if(resp.ok){
         if(resp.json()['content'] != null && resp.json()['content'] != "" && resp.json()['content'] != undefined){
           this.gridAllot.listStore.store = resp.json()['content'];
           this.gridAllot.loading = false;
           this.gridAllot.loadData();
         }else{
          this.gridAllot.loading = false;
          this.genericUtil.hideLoader()
         }
       }else{
        this.gridAllot.loading = false;
       }
     },err=>{
        this.gridAllot.loading = false;
     });
  }


  selectedListMod(value, store:GridPluginComponent, key){
    if(store.getSelectedValues().length > 1){
      store.getSelectedValues().forEach(ff=>{
        if(ff[key] != value){
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0,1);
    }
  }


  OIMonly(event:any){
    if(!(event.key=="O" || event.key=="I" || event.key=="M")) {
      event.preventDefault();
     }
  }

  CharNumOnly(event:any){
    var regexPattern = new RegExp("[A-Za-z0-9 ]");
    if(regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }


}
