import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransactionTsPortHomeService {


  constructor() { }

  public summaryPortPairAllocationSubject = new BehaviorSubject<any>({});
  public portPairAllocationSubject = new BehaviorSubject<any>({});
  public propectBookingSubject = new BehaviorSubject<any>({});
  public remominationBookingSubject = new BehaviorSubject<any>({});
  public transferPodBookingSubject = new BehaviorSubject<any>({});
  public exceptionSubject = new BehaviorSubject<any>({});
  
  setDataSummaryPortPairAllocation(data: any){
    this.summaryPortPairAllocationSubject.next(data);
  }

  getDataSummaryPortPairAllocation() {
    return this.summaryPortPairAllocationSubject.asObservable();
  }

  setDataPortPairAllocation(data: any){
    this.portPairAllocationSubject.next(data);
  }

  getDataPortPairAllocation() {
    return this.portPairAllocationSubject.asObservable();
  }

  setDataPropectBooking(data: any){
    this.propectBookingSubject.next(data);
  }

  getDataPropectBooking() {
    return this.propectBookingSubject.asObservable();
  }

  setDataRenominationBooking(data: any){
    this.remominationBookingSubject.next(data);
  }

  getDataRenominationBooking() {
    return this.remominationBookingSubject.asObservable();
  }

  setDataTransferPodBooking(data: any){
    this.transferPodBookingSubject.next(data);
  }

  getDataTransferPodBooking() {
    return this.transferPodBookingSubject.asObservable();
  }

  setDataExceptionBooking(data: any){
    this.exceptionSubject.next(data);
  }

  getDataExceptionBooking() {
    return this.exceptionSubject.asObservable();
  }

}
