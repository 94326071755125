<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Location
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Location
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-locationName'] != null &&
                      model['error-locationName'] != ''
                    "
                  >
                    <label>Location Name</label>
                    <input
                      type="text"
                      [(ngModel)]="model['locationName']"
                      (ngModelChange)="model['error-locationName'] = ''"
                      name="locationName"
                      placeholder="Location Name"
                      [disabled]="lock == true"
                      maxlength="50"
                      (keypress)="
                        formValidation.ModifiedPattern($event, '-&().,\' ')
                      "
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-locationName'] != null &&
                        model['error-locationName'] != ''
                      "
                    >
                      {{ model["error-locationName"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-locationCode'] != null &&
                      model['error-locationCode'] != ''
                    "
                  >
                    <label>Location Code</label>
                    <input
                      type="text"
                      [(ngModel)]="model['locationCode']"
                      (ngModelChange)="model['error-locationCode'] = ''"
                      name="locationCode"
                      placeholder="Location Code"
                      [disabled]="lock == true"
                      maxlength="7"
                      (keypress)="formValidation.ModifiedPattern($event)"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-locationCode'] != null &&
                        model['error-locationCode'] != ''
                      "
                    >
                      {{ model["error-locationCode"] }}
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-countryName'] != null &&
                      model['error-countryName'] != ''
                    "
                  >
                    <label>Country</label>
                    <combo-plugin
                      #cbCountry
                      [settings]="settingCountry"
                      [disableCombo]="lock"
                      (change)="changeEventCountry($event)"
                      style="width: 100%"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-countryName'] != null &&
                        model['error-countryName'] != ''
                      "
                    >
                      {{ model["error-countryName"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-locationBookingRef'] != null &&
                      model['error-locationBookingRef'] != ''
                    "
                  >
                    <label>Booking Ref</label>
                    <input
                      type="text"
                      [(ngModel)]="model['locationBookingRef']"
                      (ngModelChange)="model['error-locationBookingRef'] = ''"
                      name="Booking Ref"
                      placeholder="Booking Ref"
                      [disabled]="lock == true"
                      maxlength="2"
                      (keypress)="formValidation.AlphabetsOnlyWOSpace($event)"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-locationBookingRef'] != null &&
                        model['error-locationBookingRef'] != ''
                      "
                    >
                      {{ model["error-locationBookingRef"] }}
                    </div>
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Agency Name</label>
                    <combo-plugin
                      #cbAgency
                      [settings]="settingAgency"
                      (change)="valueAgency($event)"
                      [disableCombo]="lock"
                    ></combo-plugin>
                  </div>
                  <div class="required field"
                    [class.error]="
                      model['error-agencyCodePop'] != null &&
                      model['error-agencyCodePop'] != ''
                    ">
                    <label>Agency Code (POP)</label>
                      <input
                      type="text"
                      [(ngModel)]="model['agencyCodePop']"
                      (ngModelChange)="model['error-agencyCodePop'] = ''"
                      name="agencyCodePop"
                      placeholder="Agency Code"
                      [disabled]="lockPop == true"
                      maxlength="10"
                      (keypress)="formValidation.ModifiedPattern($event)"
                      />
                      <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-agencyCodePop'] != null &&
                        model['error-agencyCodePop'] != ''
                      "
                    >
                      {{ model["error-agencyCodePop"] }}
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isPort']"
                        name="isPort"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Port</label>
                    </div>
                  </div>

                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isPlace']"
                        name="isPlace"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Place</label>
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field">
                    <div
                      class="ui test toggle checkbox"
                      [class.disabled]="lock == true"
                    >
                      <input
                        type="checkbox"
                        [(ngModel)]="model['isPot']"
                        name="isPot"
                        tabindex="0"
                        class="hidden"
                      />
                      <label>Port of Transshipment</label>
                    </div>
                  </div>                
                  <div class="field">
                  <div
                    class="ui test toggle checkbox"
                    [class.disabled]="lock == true"
                  >
                    <input
                      type="checkbox"
                      [(ngModel)]="model['isPop']"
                      name="isPop"
                      (change)="changePop($event)"
                      tabindex="0"
                      class="hidden"
                    />
                    <label>Place of Payment</label>
                  </div>
                </div>
              </div> 

                <div
                  class="field"
                  [class.error]="
                    model['error-remarks'] != null &&
                    model['error-remarks'] != ''
                  "
                >
                  <label>Remarks</label>
                  <textarea
                    [(ngModel)]="model['remarks']"
                    (ngModelChange)="model['error-remarks'] = ''"
                    name="remarks"
                    placeholder="Input your remarks..."
                    [disabled]="lock == true"
                    maxlength="200"
                    (keypress)="formValidation.AllChar($event)"
                  ></textarea>
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-remarks'] != null &&
                      model['error-remarks'] != ''
                    "
                  >
                    {{ model["error-remarks"] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Location
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Location</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateLocationHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-locationName'] != null &&
                      model['error-locationName'] != ''
                    "
                  >
                    <label>Location Name</label>
                    <combo-plugin
                      #cbLocationNameHistory
                      [settings]="settingLocationHistory"
                      (change)="valueLocationHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-locationName'] != null &&
                        model['error-locationName'] != ''
                      "
                    >
                      {{ model["error-locationName"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-locationCode'] != null &&
                      model['error-locationCode'] != ''
                    "
                  >
                    <label>Location Code</label>
                    <input
                      type="text"
                      [value]="valLocationCode"
                      name="locationHistoryCode"
                      placeholder="Location Code"
                      [disabled]="lock == true"
                      maxlength="5"
                      readonly
                      style="background-color: lightgray"
                      (keypress)="noneSpace($event)"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-locationCode'] != null &&
                        model['error-locationCode'] != ''
                      "
                    >
                      {{ model["error-locationCode"] }}
                    </div>
                  </div>
                </div>
                <!--
                                            <div class="two fields">
                                              <div class="field">
                                                <label>Log Start Date</label>
                                                <calendar-plugin id="logHistoryLocationStartDate" [pluginType]="typeLocationLogStartDate" [settings]="settingLocationLogStartDate"></calendar-plugin>
                                              </div>
                                              <div class="field">
                                                <label>Log End Date</label>
                                                <calendar-plugin id="logHistoryLocationEndDate" [pluginType]="typeLocationLogEndDate" [settings]="settingLocationLogEndDate"></calendar-plugin>
                                              </div>
                                            </div>
                -->
                <br>
                <br>                
                <div class="field">
                  <grid-plugin
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->

          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'terminal' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Terminal mapping
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Location</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button
                      class="ui button"
                      (click)="saveTerminalMapping()"
                      [disabled]="lockSaveMapping == true"
                    >
                      Save Mapping
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field">
                    <label>Location Name</label>
                    <input
                      type="text"
                      [value]="valLocationName"
                      name="locationName"
                      placeholder="Location Name"
                      [disabled]="lock == true"
                      readonly
                      style="background-color: lightgray"
                    />
                    <!--
                    <combo-plugin #cbTerminalMapping [settings]="settingCbTerminalMapping" (change)="valueTerminalMapping($event)" ></combo-plugin>
                    -->
                  </div>
                  <div class="field">
                    <label>Location Code</label>
                    <input
                      type="text"
                      [value]="valLocationCode"
                      name="locationCode"
                      placeholder="Location Code"
                      [disabled]="lock == true"
                      readonly
                      style="background-color: lightgray"
                    />
                  </div>
                </div>
                <!-- end active content -->
                <div id="displayGrid">
                  <div class="field">
                    <div class="ui icon tiny buttons">
                      <button
                        class="ui button sapi"
                        [disabled]="lock == true"
                        data-content="Add"
                        (click)="addDetail()"
                      >
                        <i class="plus icon"></i>
                      </button>
                      <button
                        class="ui button sapi"
                        [disabled]="lock == true"
                        data-content="Remove"
                        (click)="removeDetail()"
                      >
                        <i class="minus icon"></i>
                      </button>
                    </div>
                    <grid-plugin
                      id="detail-terminal"
                      #gridTerminalDetail
                      [settings]="settingGridTerminalDetail"
                      (gridEvent)="gridEventTerminalDetail($event)"
                      (infoGrid)="infoGridTerminalDetail($event)"
                    ></grid-plugin>
                  </div>
                </div>

                <div id="displayForm">
                  <div class="two fields">
                    <div class="field">
                      <label>Terminal Name</label>
                      <combo-plugin
                        #cbTerminalMappingDetail
                        [settings]="settingCbTerminalMappingDetail"
                        (change)="valueTerminalMappingDetail($event)"
                      ></combo-plugin>
                      <span class="error-red" *ngIf="errorMessageTerminalName">
                        {{errorMessageTerminalName}}
                      </span>
                    </div>

                    <div class="field">
                      <label>Terminal Code</label>
                      <input
                        type="text"
                        [value]="valTerminalCode"
                        name="terminalCode"
                        placeholder="Terminal Code"
                        [disabled]="lock == true"
                        maxlength="5"
                        readonly
                        style="background-color: lightgray"
                      />
                    </div>
                  </div>

                  <div class="two fields">
                    <div class="field" style="width: 100%"></div>
                    <div class="field" style="width: 100%">
                      <div class="ui buttons">
                        <button class="ui button" (click)="cancelDetail()">
                          Cancel
                        </button>
                        <div class="or"></div>
                        <button
                          class="ui positive button"
                          (click)="saveDetail()"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
