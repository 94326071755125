import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { qlec, GridPluginComponent2 } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, ToolbarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
declare  var $:any;

@Component({
  selector: 'app-master-qlec-page',
  templateUrl: './master-qlec-page.component.html',
  styleUrls: ['./master-qlec-page.component.css']
})
export class MasterQlecPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridDetails') gridDetails:GridPluginComponent;
  @ViewChild('cbQlecHistory') cbQlecHistory:ComboPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent2;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingQlecHistory
  settingGridHistory;

  typeCustomerLogStartDate = 'calendar';
  typeCustomerLogEndDate = 'calendar';

  /* Parameter model */
  model = new qlec;

  loading = false;

  lock = false;
  qlecIdHistory:string='';

  /* validatorRules */
  validatorRules = {
    length: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Length.'
        }
      ]
    },
    comparison: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Comparison.'
        }
      ]
    },
    weight: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Weight.'
        }
      ]
    },
    status: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Status.'
        }
      ]
    },
    type: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Type.'
        }
      ]
    }

  }

  ComparisonOnly;
  lengthOnly;
  statusOnly;
  typeOnly;

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        { display: 'Length', value: 'length'},
        { display: 'Comparison', value: 'comparison'},
        { display: 'Weight', value: 'weight'},
        { display: 'Status', value: 'status'},
        { display: 'Type', value: 'type'},
        { display: 'Valid', value: 'isValid'},
        { display: 'User Created', value: 'userCreated'},
        { display: 'Date Created', value: 'dateCreated'},
        { display: 'User Modified', value: 'userModified'},
        { display: 'Date Modified', value: 'dateModified'},
        { display: 'User Invalid', value: 'userInvalid'},
        { display: 'Date Invalid', value: 'dateInvalid'},
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterQlec/findGeneral',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Qlec ID', field: 'qlecId', width: 100},
                    {header: 'Length', field: 'length', width: 100},
                    {header: 'Comparison', field: 'comparison', width: 200},
                    {header: 'Weight', field: 'weight', width: 120},
                    {header: 'Status', field: 'status', width: 120},
                    {header: 'Type', field: 'type', width: 120},
                    {header: 'User Created', field: 'userCreated', width: 110},
                    {header: 'Date Created', field: 'dateCreated', width: 110},
                    {header: 'User Modified', field: 'userModified', width: 110},
                    {header: 'Date Modified', field: 'dateModified', width: 110},
                    {header: 'User Invalid', field: 'userInvalid', width: 110},
                    {header: 'Date Invalid', field: 'dateInvalid', width: 110},
                    {header: 'Valid', field: 'isValid', width: 80},
                    { header: "User Deleted", field: "userDeleted", width: 110 },
                    { header: "Date Deleted", field: "dateDeleted", width: 110 },
                    { header: "Delete", field: "isDeleted", width: 80 }
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'length',
        sortingDirection: 'ASC'
    }

    this.settingGridHistory = {
        url: this.configService.config.BASE_API.toString() + '/MasterQlec/findGeneralHistoryById',
        page: 10,
        columns: [
                {header: 'No', field: 'no', width: 50},
                {header: 'Qlec ID', field: 'qlecId', width: 100},
                {header: 'Length', field: 'length', width: 100},
                {header: 'Comparison', field: 'comparison', width: 200},
                {header: 'Weight', field: 'weight', width: 120},
                {header: 'Status', field: 'status', width: 120},
                {header: 'Type', field: 'type', width: 120},
                {header: 'User Created', field: 'userCreated', width: 110},
                {header: 'Date Created', field: 'dateCreated', width: 110},
                {header: 'User Modified', field: 'userModified', width: 110},
                {header: 'Date Modified', field: 'dateModified', width: 110},
                {header: 'User Invalid', field: 'userInvalid', width: 110},
                {header: 'Date Invalid', field: 'dateInvalid', width: 110},
                {header: 'Valid', field: 'isValid', width: 80}
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: true,

        enablePagination: true
    }

    this.settingGridDetails = {
        url: '',
        page: 5,
        columns: [
                {header: 'No', field: 'no', width: 50},
                {header: 'Qlec ID', field: 'qlecId', width: 100},
                {header: 'Length', field: 'length', width: 100},
                {header: 'Comparison', field: 'comparison', width: 200},
                {header: 'Weight', field: 'weight', width: 120},
                {header: 'Status', field: 'status', width: 120},
                {header: 'Type', field: 'type', width: 120},
                {header: 'User Created', field: 'userCreated', width: 110},
                {header: 'Date Created', field: 'dateCreated', width: 110},
                {header: 'User Modified', field: 'userModified', width: 110},
                {header: 'Date Modified', field: 'dateModified', width: 110},
                {header: 'User Invalid', field: 'userInvalid', width: 110},
                {header: 'Date Invalid', field: 'dateInvalid', width: 110},
                {header: 'Valid', field: 'isValid', width: 80}
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: false,
        enableSelectAll: true,
        enablePagination: false,
        sortingColumns:'length',
        sortingDirection: 'ASC'
    }

    this.settingQlecHistory = {
      id          : 'cbQlecHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterQlec/findGeneralHistory/{query}',
      maxChars    : 1,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'length',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'QLEC Id', field: 'qlecId', width: 100},
        {header: 'Length', field: 'length', width: 150},
        {header: 'Weight', field: 'weight', width:150}
      ]
    }


  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    $('.special.cards .image').dimmer({
      on: 'hover'
    });

    //this.setValidatorHeader();
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.genericUtil.setBreadcrumb("Master,Entity,Master-Customer:/main/master-customer");

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGridDetails(event:any) {
    this.info = event;
  }

  gridEvent(event:any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
        break;

      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              this.handleUpdate();
              break;
            case 'dblClick':
              let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                this.disableToolbarButtons = "";
                this.invisibleToolbarButtons = "create,history,print";

                this.modeForm = true;
                this.modeToolbar = false;

                let dt = this.grid.getSelectedValues()[0];
                //let custId:string = String(dt.customerId).trim();
                //this.onRetrieve(custId);

                this.setData()

                this.lock = true;
                //this.gridDetails.disable();
              }
              break;

            default:
            break;
          }

        }

        break;
    }
  }

  gridEventDetails(event:any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              //this.setValidatorDetails();
              this.disableToolbarButtons = "save,cancel";

              // if(this.model['error-customerName'] != null){
              //   this.model['error-customerName'] = '';
              // }

              let dt = this.gridDetails.getSelectedValues()[0];
              this.gridDetails.listStore.store.forEach(element => {
                 if(dt.no ==  element['no']){
                    // this.modelDetail = new CustomerDetail;
                    // this.modelDetail = element;
                    // console.log(this.modelDetail);
                 }
              });

              $('#display').transition();
              $('#displayGrid').transition('hide');

              break;

            default:
            break;
          }

        }

        break;
    }

  }

  toolbarEvent(event:any) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        // if(this.model.customerId != ""){
        //   this.model.userModified = this.cookieService.getName();
        // } else {
        //   this.model.userCreated = this.cookieService.getName();
        // }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  setValidatorHeader(){

  }

  setValidatorDetails(){

  }

  handleCreate(){
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";

    this.model = new qlec;
    this.model.isValid = true;
  }

  handleUpdate(){
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock=false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,delete,print";
    }

    //this.onRetrieve()
  }

  handleDelete(){
    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"qlecId":""};
    //   deleteObj.qlecId = element.qlecId;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListMasterQlecUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint(){
    var formatName = "report-QLEC-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/71/QLEC-report/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/71/QLEC-report/*');
  }

  handleSave(){
    // handle save event
    //this.model.dateCreated = this.genericUtil.getPSIDate();
    // console.log(this.model);

    this.model['error-length'] = ''
    this.model['error-comparison'] = ''
    this.model['error-type'] = ''
    this.model['error-weight'] = ''
    this.model['error-status'] = ''

    let isValid:boolean = this.onValidate(this.model);

    console.log(isValid);
    console.log('length -> ' + this.model.length);
/*
    if(this.model.length.toUpperCase() !== "20'" && this.model.length.toUpperCase() !== "40'" && this.model.length.toUpperCase() !== "45'" && this.model.length.toUpperCase() !== "HC20'" && this.model.length.toUpperCase() !== "HC40'" && this.model.length.toUpperCase() !== "HC45'"){
      isValid = true;
      this.model['error-length'] = 'Please input Length.';
    }
    if(this.model.comparison.toUpperCase() !== "< LESS THAN" && this.model.comparison.toUpperCase() !== "<= LESS THAN OR EQUAL TO" && this.model.comparison.toUpperCase() !== "> GREATER THAN" && this.model.comparison.toUpperCase() !== ">= GREATER THAN OR EQUAL TO" ){
      isValid = true;
      this.model['error-comparison'] = 'Please input Comparison.';
    }
    if(this.model.status.toUpperCase() !== "E" && this.model.status.toUpperCase() !== "F" ){
      isValid = true;
      this.model['error-status'] = 'Please input Status.';
    }
    if(this.model.type.toUpperCase() !== "ALL" && this.model.type.toUpperCase() !== "ALL" ){
      isValid = true;
      this.model['error-type'] = 'Please input Type.';
    }
*/
    if(!isValid){
        this.model["userCreated"] = this.cookieService.getName();
        this.model["userModified"] = this.cookieService.getName();
        this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.model["dateModified"] = this.genericUtil.getPSIDate();
        this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;

    this.model.length = "";
    this.model.status = "";
    this.model.type = "";
    this.model.weight = "";
    this.model.comparison = "";

    this.cbQlecHistory.setValue("")

    this.cbQlecHistory.clearSelect()
    this.cbQlecHistory.close()
  }

  handleHistory(){
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  generateQlecHistoryReport(event:any){
    if (this.cbQlecHistory.data.qlecId == "" || this.cbQlecHistory.data.qlecId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-Qlec-"+this.cbQlecHistory.data.qlecId+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/71/Qlec-"
      +
      this.cbQlecHistory.data.qlecId  +
      '/'+
      this.cbQlecHistory.data.qlecId
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/71/Qlec-' +
    //  this.cbQlecHistory.data.qlecId  +
    //  '/'+
    //  this.cbQlecHistory.data.qlecId
    // );

  }

  saveEvent(){
    this.loading = true;
    //// console.log(this.model);

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterQlec/save',this.model).subscribe((resp) =>{
          if(resp.status == 200 && resp.json()['message'] == 'OK'){
              //clear all
              //this.model = new AccountPic;
              this.cbQlecHistory.setValue("");

              this.loading = false;
              this.info = 'save successfully';

              this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});

          }else{
            this.loading = false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });
  }

  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.form = '';
    this.lock =  false;

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
  //  this.grid.onFirst();
  }

  deleteEvent(){
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.qlecId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"qlecId":[]}
    paramsDelete.qlecId = deleteList;

    this.genericService.POST(
      this.configService.config.BASE_API.toString() +
      // '/MasterQlec/delete',
      // deleteList
      '/MasterQlec/softDelete',
      paramsDelete
      )
      .subscribe((resp) =>{
          if(resp.status == 200){
              //this.model = new AccountPic;
              this.cbQlecHistory.setValue("");

              this.loading = false;

              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false;this.afterDelete();'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.form = '';
    this.lock =  false;

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }


  onRetrieve(accountId:string){

  }

  retrieveHistory(event:any){
    this.gridHistory.search = this.cbQlecHistory.data.qlecId
    this.gridHistory.loadData();
  }

  changeEventQlec(event:any){

  }

  changeEventCustomerHistory(event:any) {
    this.qlecIdHistory = '';
  }

  gridEventHistory(event:any){
    //do nothing
  }

  infoGridHistory(event:any){
    // this.info=event;
  }

  setData(){
    let data = this.grid.getSelectedValues()[0];

    console.log(data.countryName)
    this.model = new qlec;

    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }
    this.model.qlecId = String(data.qlecId).trim();
    this.model.length = String(data.length).trim();
    this.model.comparison = String(data.comparison).trim();
    this.model.weight = String(data.weight).trim();
    this.model.status = String(data.status).trim();
    this.model.type = String(data.type).trim();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
  }


  changeEventComparison(event){}
  changeEventType(event){}
  changeEventStatus(event){}
  changeEventLength(event){}

  noKey(event){
    event.preventDefault();
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
