import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import {blTransfer} from "../shared/models/blTransfer";
import {Param} from "../transaction-bl-flag-status-page/transaction-bl-flag-status-page.component";
declare  var $:any;

export class BookingTransferPayload{
  bOceanVesselDes:string='';
  bOceanVoyageDes:string='';
  boundDes:string='';
  payloads: Payload[]=[];

  constructor(){};
}

export class Payload{
  bPrefix:string='';
  bNo:string='';

  constructor(){};
}


@Component({
  selector: 'app-transaction-bl-transfer-page',
  templateUrl: './transaction-bl-transfer-page.component.html',
  styleUrls: ['./transaction-bl-transfer-page.component.css']
})
export class TransactionBLTransferPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  @ViewChild('cbVesselFrom') cbVesselFrom:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbVoyageDes') cbVoyageDes:ComboPluginComponent;
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVesselDes') cbVesselDes:ComboPluginComponent;
  @ViewChild('cbVoyageDestination') cbVoyageDestination:ComboPluginComponent;

  @ViewChild('cbVoyageFrom') cbVoyageFrom:ComboPluginComponent;
  @ViewChild('cbVesselTo') cbVesselTo:ComboPluginComponent;
  @ViewChild('cbVoyageTo') cbVoyageTo:ComboPluginComponent;
  @ViewChild('grid')  grid :GridPluginComponent;
  @ViewChild('gridBookingTransferByNo') gridBookingTransferByNo:GridPluginComponent;
  @ViewChild('gridBookingTransferAllocation') gridBookingTransferAllocation:GridPluginComponent;



  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';
  modeForm = 'bookingTransferDefault';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingVoyageDes;
  settingVesselDes;
  settingVoyageTo;
  settingGrid;
  settingGridBookingTransferByNo;

  officeCode:string = "";

  radioTransferByVessel:string="N";
  radioTransferByBNo:string="N";
  transferToLock:boolean=true;
  transferFromLock:boolean=true;
  addRemoveGrid:string="none";
  availableTeus: number = 0;
  availableTeusTemp: number = 0;
  ckFlag:number = 0;
  ckPsa:number = 0;
  sendVar = [];

  portOfCallFrom: string="";
  portOfCallTo: string="";
  requiredTeus: number =0;

  localEtaFrom : string="";
  localEtaTo : string = "";



  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new blTransfer;
  private isLoad: boolean;

  // officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();

  validatorRules = {  }
  public settingGridBookingTransferAllocation: {id: string; url: string; page: number; columns: [{header: string; field: string; width: number}]; buttons: [{name: string; event: string; enabled: boolean}]; enableSorting: boolean; enableSelectAll: boolean; enablePagination: boolean; sortingColumns: string; sortingDirection: string};
  private ckval: string;

  setValidatorTransferByBNo(){
    this.validatorRules = {
      vesselIdDestination: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel."
        }]
      },
      voyageDestination: {
        rules: [{
          type: "empty",
          prompt: "Please input Voyage."
        }]
      },
      boundDestination: {
        rules: [{
          type: "empty",
          prompt: "Please input Bound."
        }]
      },
    }
  }

  setValidatorTransferByVessel(){
    this.validatorRules = {
      vesselName: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel."
        }]
      },
      bOceanVesselVoyage: {
        rules: [{
          type: "empty",
          prompt: "Please input Voyage."
        }]
      },
      bOceanVesselBound: {
        rules: [{
          type: "empty",
          prompt: "Please input Bound."
        }]
      },
    }
  }

  setValidatorBeforeDoTransfer(){
    this.validatorRules = {

      bOceanVoyageDes: {
        rules: [{
          type: "empty",
          prompt: "Please insert `Transfer To` Voyage."
        }]
      },
      boundDes: {
        rules: [{
          type: "empty",
          prompt: "Please input Bound."
        }]
      },
    }
  }



  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Transfer', event: 'transfer', icon: 'exchange'},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Transfer'
    }
    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url: '', //this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }
    this.settingVesselDes= {
      id          : 'cbVesselDes',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyageDes= {
      id          : 'cbVoyageDes',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }
    this.settingGrid = {
      id : 'grid',
      url: '',//this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 100 ,  editType:'text'},

      ],
      buttons: [
        { /*name: 'Edit', event: 'edit', enabled: true */}
      ],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'blNo',
      editable:true,
      sortingDirection: 'ASC'
    }

    this.settingGridBookingTransferByNo = {
      id : 'gridBookingTransferByNo',
      url: '',/*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/
      page: 8,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 100},

      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,

      sortingColumns:'no',
      sortingDirection: 'ASC',
    }


  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    $('.dataTables_scrollBody').css('height','300px');
    this.disableToolbarButtons = "transfer,cancel";
    this.officeCode = this.cookieService.getDefaultLocationCode().toString().trim();
    // console.log(this.officeCode);
    this.radioTransferByVessel=='Y';
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.handleRetrieve();
        //// console.log("url grid "+this.grid.url);
        break;
      case 'close' :
        this.handleClose();
        break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'transfer' :
        this.handleTransfer();
        break;
    }
  }
  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }




  changeEventVesselTo(event){
    this.cbVoyageTo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+"O"+"/{query}/"+this.officeCode);
  }

  changeEventVessel(event) {
    this.model['error-vesselName'] = "";

    //this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
    if(event.vesselId != null){
      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.model.bound.toUpperCase()+"/{query}/SGSIN");
      this.cbVoyage.setValue("");
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      if(event.capacity != null){
        // this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";

    }

  }



  changeEventVoyage(event){
    this.model['error-bOceanVesselVoyage'] = "";

    if(event.voyage != null){
      this.model.bOceanVesselVoyage = event.voyage;
      this.model.bound = event.bound;

    }
    else {
      this.model.bOceanVesselVoyage = "";

    }
  }

  changeEventVoyageDes(event){
    this.model['error-bOceanVoyageDes'] = "";

    if(event.voyage != null){
      this.model.bOceanVoyageDes = event.voyage;
      this.model.boundDes = event.bound;

    }
    else {
      this.model.bOceanVoyageDes = "";

    }
  }

  changeEventVesselDes(event) {
    this.model['error-bOceanVesselDes'] = "";

    //this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
    if(event.vesselId != null){
      this.cbVoyageDes.disableCombo = false;
      this.cbVoyageDes.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.model.bound.toUpperCase()+"/{query}/SGSIN");
      this.cbVoyageDes.setValue("");
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;


    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";

    }
  }


  rowEvent(act:string){
    var tableStore = [];
    if(act=='add'){

      tableStore.push({

        blNo:'SSL',

        select : 'checked'
      });

      tableStore.forEach(yt=>{

        this.grid.listStore.addData(yt);
        this.grid.loadData();


      });

      this.grid.listStore.store.forEach(tt=>{
        // console.log(tt);
      });

      this.grid.loadData();

    }else{
      this.grid.getSelectedValues().forEach(element => {
        this.grid.listStore.removeData(element);
        // console.log(element);
        this.requiredTeus = this.requiredTeus - element.reqTeus;
      });

      if(this.grid.listStore.store.length > 0){

        this.grid.listStore.store.forEach(grh=>{
          this.availableTeus = this.availableTeusTemp - this.requiredTeus;
        });

      }else{

        this.availableTeus = this.availableTeusTemp;

      }

      this.grid.loadData();
      this.grid.clearSelectedValues();
    }

  }

  gridEvent(event){
    // console.log('eventgrid'+event);
    //this.gridBookingTransfer.onSelectAll(true);
    var flagCk = 0;
    var selGrid = [];

    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
        break;

      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          // console.log("before split -> " + strEvent);
          // console.log("arr -> " + arr);
          // console.log("arr-split -> " + arr[0]);
          // console.log("arr-split -> " + arr[1]);
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              this.modeForm == 'sailingScheduleMaintenance';
              this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }

        }

        break;
    }

  }

  handleDoubleClickedSailingSchedule(){
    // console.log('modal routine');
    $('#sailingScheduleMaintenance')
      .modal('setting', 'closable', false)
      .modal('show');
    //this.model.voyage = $('input[name="model[baseVoyage]"]').val();

    if(this.model.vesselId == '' || this.model.bOceanVoyageDes == ''){
      this.message('warning', 'Alert','Random error!', 'okcancel', { ok: '// console.log("ok button clicked")'});
    } else {
      if(this.modeForm == 'sailingScheduleMaintenance'){
        $('#sailingScheduleMaintenance')
          .modal('setting', 'closable', false)
          .modal('show');
      } else if (this.modeForm == 'batch'){
        //this.copyProcess(this.model);
      }
    }

  }

  fillGrid(){
    this.isLoad = true;
    this.grid.loadData();
  }

  infoGrid(event){
    this.info = event;
  }

  gridBookingTransferEventByNo(event) {
    // console.log('eventgrid'+event);
    let gt = this.gridBookingTransferByNo.getSelectedValues()[0];
    switch (event.split(".")[0]) {
      case 'selected':
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case 'click' :
        //this.selectedListMod(event);
        var no = event.split(".")[0].split("-")[0];
        this.disableToolbarButtons="retrieve";
        this.gridBookingTransferByNo.clearSelectedValues();
        this.gridBookingTransferByNo.onSelect(true,Number.parseInt(no));
        // this.gridBookingTransferByNo.listStore.removeData()
        // console.log(gt);

        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split(';');
          // console.log("before split4 -> " + strEvent);
          // console.log("arr3 -> " + arr);
          // console.log("arr-split1 -> " + arr[0]);
          // console.log("arr-split2 -> " + arr[1]);
          switch(arr[0].split('.')[0] ){
            case 'AfterEdit':
              //this.getFreightShipperTeus(strEvent.split(';')[2]);
              break;
            case 'dblClick':
              this.modeForm == 'sailingScheduleMaintenance';
              this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }

        }

        break;
    }

  }



  handleRetrieve(){
    this.setValidatorTransferByVessel();
    this.grid.clearSelectedValues();
    // console.log("tes");
    if(this.radioTransferByVessel.toUpperCase()=="Y"){
      this.setValidatorTransferByVessel();
      var URL_Retrieve = "http://localhost:16000/bltransfer/GetBlTransfer/"
        + this.model.vesselId + "/"
        + this.model.bOceanVesselVoyage + "/"
        + this.model.bound + "/" + "1/10/"
      this.isLoad = true;
      this.grid.url = URL_Retrieve;
      this.grid.loadData();
      this.grid.onSelectAll(true);
      this.disableToolbarButtons='';

    }else if(this.radioTransferByBNo.toUpperCase() == "Y"){
      this.setValidatorTransferByBNo();
    }
    let isPassedValidation:boolean = this.onValidate(this.model);
    // console.log('ispassed');
    // console.log(isPassedValidation);
    if(!isPassedValidation) {

      // console.log("retrieve Event");
      /* if (this.radioTransferByVessel.toUpperCase() == "Y") {
       var URL_Retrieve = "http://localhost:16001/bltransfer/GetBlTransfer/"
       + this.model.vesselId + "/"
       + this.model.bOceanVesselVoyage + "/"
       + this.model.bound + "/" + "1/10/"
       }*/


      this.grid.is_select_all = 'checked';
      //this.gridBookingTransfer.onSelectAll(true);
      this.grid.url = URL_Retrieve;
      this.disableToolbarButtons="";

    }
  }

  handleCancel(){
    this.model=new blTransfer;
    this.cbVoyage.setValue('');
    this.cbVessel.setValue('');
    this.cbVesselDes.setValue('');
    this.cbVoyageDes.setValue('');
    this.grid.onClear();
    this.addRemoveGrid = "none";
    this.modeForm ="bookingTransferDefault";


  }

  handleClose(){
    this.router.navigateByUrl("/main/home");

  }

  changeEventTransferBy(event){
    // console.log("Radio debug despacito => " + event.target.value.toString().trim().toUpperCase());


    if(event.target.value.toString().trim().toUpperCase() == "VESSEL"){

      this.handleCancel();
      this.grid.enableSelectAll = true;
      this.radioTransferByVessel="Y";
      this.radioTransferByBNo="D";
      this.transferFromLock=false;
      this.transferToLock = false;
      this.disableToolbarButtons="transfer";
      this.addRemoveGrid = "none";
      this.modeForm ="bookingTransferDefault";



    }else if(event.target.value.toString().trim().toUpperCase() == "BOOKINGNUMBER"){
      this.handleCancel();
      this.radioTransferByVessel="D";
      this.radioTransferByBNo="Y";
      //this.transferFromLock=true;
     // this.transferToLock = true;
      this.addRemoveGrid = "block";
      this.disableToolbarButtons="retrieve";
      //this.grid.onClear();
     // let data = this.model;
     // data.blNo = String(this.model.blNo);
     // this.grid.loadData();

      this.grid.enableSelectAll=false;
      this.grid.editable=true;
      this.modeForm ="bookingTransferDefault2";


    }
  }

  generateBookingNumber(locref:string,podref:string,cntown:string,callback?: (data) => void){

    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingNo/'+locref+'/'+podref+'/'+cntown).subscribe((resp) =>{


      if(resp.ok){

        if(Object.keys(resp.json()['blNo']).length>0){
          var blNo = resp.json()['blNo'];
          //this.setvalvar(bbno);
          /*
           this.splitDetails = new BookingSplit;
           this.splitDetails.seqnoNew = iteration+1;
           this.splitDetails.bNoNew = resp.json()['bNo'];
           this.splitDetails.containerTypeNew = this.typeSize[0];
           this.splitDetails.containerSizeNew = this.cntSz[0];
           this.splitDetails.qtyNew = 0;
           this.splitDetails.bSeqPrefix = 0;
           this.splitStore.push(this.splitDetails);
           */
        }

        if(callback)
          callback(blNo);

      }


    });




  }

  handleTransfer(){

    this.sendVar = [];
    this.ckFlag = 0;
    this.ckPsa = 0;
    let isPassedValidation:boolean = this.onValidate(this.model);

    var splPortTo = this.portOfCallTo.trim().split(' - ');

    if(this.radioTransferByVessel.toUpperCase()=="Y"){
      // console.log("kkkk"+this.radioTransferByVessel);
      this.setValidatorBeforeDoTransfer();
      var ckBt = this.ckTransferValidation(splPortTo);
      this.grid.clearSelectedValues();

    }else if(this.radioTransferByBNo.toUpperCase() == "Y"){
      // console.log("jjj"+this.radioTransferByBNo);
      this.setValidatorTransferByBNo();
      var ckBt = this.ckTransferValidationByNo(splPortTo);
    }

    var ckCeked = 0;
    this.grid.listStore.store.forEach(ytr=>{
      // console.log('ye te er'+ this.grid.listStore.store);// console.log(ytr);
      if(ytr.select == 'checked'){
        ckCeked = 1;
      }
    });

    this.grid.listStore.store.forEach(ytr=>{
      if(ytr.select == 'checked'){
        ckCeked = 1;
      }




    });


    // console.log('vessel 2 return');
    if(!isPassedValidation) {

      if (this.ckFlag == 0) {
        // console.log("123 "+this.ckFlag);
        this.message('information', "Information", " Please tick at least one booking number to do transfer event", "okonly", {ok: "// console.log('ok click')"})
        // console.log("234 "+'information', "Information", " Please tick at least one booking number to do transfer event", "okonly", {ok: "// console.log('ok click')"})
        this.loading = false
        return false;
      }

      if (this.ckFlag == 1) {
        // console.log('masuk kondisi vessel not same');

        this.message('information', "Information", " Destination Vessel doesn`t have same Port of Call,Transfer failed.", "okonly", {ok: "// console.log('ok click')"})
        this.loading = false
        return false;
      }
      else if ((this.radioTransferByBNo.toUpperCase() != "Y") && (this.cbVesselDes.getValue().vesselId == this.cbVessel.getValue().vesselId) && (this.cbVoyageDes.getValue().voyage == this.cbVoyageDes.getValue().voyage)) {
        this.message('information', "Information", " Booking is not transferred, since source vessel and destination vessel are same.", "okonly", {ok: "// console.log('ok click')"})
        this.loading = false
        return false;
      }
      else if (this.ckPsa == 1) {
        // console.log('masuk kondisi psa');

        this.message('information', "Information", "  Booking already transmitted to PSA, unable to retain Booking No.", "okonly", {ok: "// console.log('ok click')"})
        this.loading = false
        return false;
      }

      else {
        let isPassedValidation:boolean;

        if (!isPassedValidation) {
          // console.log('masuk else kudu di transfer')
          //setup the payload before AJAX Call
          this.model.bOceanVesselDes = this.model.bOceanVesselDes;
          this.model.bOceanVoyageDes = this.model.bOceanVoyageDes;
          this.model.boundDes = this.model.boundDes;
          // this.modelTransfer.payloads = [];

          //// console.log(sendVar);
          var datas = '';

          //do the AJAX Call

          this.sendVar.forEach(xh => {
            if (xh.mntBook == 0) {
              var self = this;

              this.generateBookingNumber(xh.locHref, xh.bPodCode, xh.bCntOwner, function (valcallback) {
                //b// console.log('callback : ' + callback);

                // console.log('udahnewbnonih')
                // console.log(valcallback);
                xh.NbNo = valcallback;

              });
              //// console.log('ini set 2');
              //// console.log(self.newBno);
              //xh.NbNo = self.newBno;
            }

          });

          this.sendVar.forEach(yyt => {
            let data = this.model;
            // console.log('yyt');
            // console.log(yyt);
          /*  this.genericService.POST("http://localhost:16000/bltransfer/transfer",data).subscribe((resp) => {

              // console.log("ytt 1  "+resp.json()[0]);
              // console.log("ytt 2  "+resp.json());
              datas = resp.json()[0]['status'];

              if (datas == 'OK') {

                // console.log(this.modeForm);
                // console.log(this.sendVar);
                this.disableToolbarButtons = "transfer,retrieve";
                this.grid.listStore.store.splice(0, this.grid.listStore.store.length);
                //this.gridBookingTransfer.onClear();


                this.gridBookingTransferByNo.listStore.store = [];
                resp.json().forEach(yg => {
                  this.grid.listStore.addData(yg);
                  this.gridBookingTransferByNo.listStore.addData(yg);
                });
                // console.log('wkwkwkw');
                this.gridBookingTransferByNo.listStore.store.forEach(uy => {
                  // console.log(uy);
                })

                this.gridBookingTransferByNo.loadData();
                this.grid.enableSelectAll = false;
                this.gridBookingTransferByNo.editable = false;
                //this.gridBookingTransfer.loadData();

                this.message('information', "Information", "Record(s) transferred successfully.", "okonly", {ok: "// console.log('ok click')"})
                this.loading = false;


              }
            }, error => {


              this.loading = false;
              this.message('information', "Information", "There are some error occur while saving the record, error message : " + datas + ". Please contact your administrator!", "okonly", {ok: ""});

            });*/

            //}


          });

          //bikin sekalian aja di function do transferbooking, di bagian service nya..
          this.genericService.POST(this.configService.config.BASE_API.toString() + "/MasterBookings/doTransferBooking", this.sendVar).subscribe((resp) => {
            // console.log("ytt 1  "+resp.json()[0]);
            // console.log("ytt 2  "+resp.json());
            datas = resp.json()[0]['status'];

            if (datas == 'OK') {

              // console.log(this.modeForm);
              // console.log(this.sendVar);
              this.disableToolbarButtons = "transfer,retrieve";
              this.grid.listStore.store.splice(0, this.grid.listStore.store.length);
              //this.gridBookingTransfer.onClear();


              this.gridBookingTransferByNo.listStore.store = [];
              resp.json().forEach(yg => {
                this.grid.listStore.addData(yg);
                this.gridBookingTransferByNo.listStore.addData(yg);
              });
              // console.log('wkwkwkw');
              this.gridBookingTransferByNo.listStore.store.forEach(uy => {
                // console.log(uy);
              })

              this.gridBookingTransferByNo.loadData();
              this.grid.enableSelectAll = false;
              this.gridBookingTransferByNo.editable = false;
              //this.gridBookingTransfer.loadData();

              this.message('information', "Information", "Record(s) transferred successfully.", "okonly", {ok: "// console.log('ok click')"})
              this.loading = false;


            }
          },error => {


            this.loading = false;
            this.message('information', "Information", "There are some error occur while saving the record, error message : " + datas + ". Please contact your administrator!", "okonly", {ok: ""});

          });
          this.disableToolbarButtons = "transfer,retrieve";


          //}

        }//end else
      }
    }

  }


  saveEvent(){

  }


  ckTransferValidation(param){
    var mntBook = 0;

    if($('#maintainBook').is(':checked')){
      mntBook = 1;
    }else{
      mntBook = 0;
    }

    this.grid.getSelectedValues().forEach(get=>{
      // console.log(get);
      // console.log(param);
      // console.log(param.indexOf(get.bPodCode));
      if(this.ckval == 'vessel') {

        if (param.indexOf(get.bPodCode) < 0) {
          // console.log('masuk kondisi');
          // console.log(param.indexOf(get.bPodCode));
          this.ckFlag = 1;
        }
        if (get.bIsPsa == 'Y') {
          this.ckPsa = 1;
        }
      }
    });

    this.grid.getSelectedValues().forEach(gs=>{
      //// console.log(gs);
      // var tPayload = new Payload();
      // tPayload.bPrefix = gs.bPrefix;
      //tPayload.bNo = gs.bNo;
      //this.modelTransfer.payloads.push(tPayload);


      this.sendVar.push({

        destinationVesselId : this.model.vesselIdDestination,
        destinationVoyage : this.model.voyageDestination,
        destinationBound  :this.model.boundDestination,
        destinationVesselCode :this.model.vesselCodeDestination,
        bPrefix : gs.bPrefix,
        bNo : gs.bNo,
        NbPrefix : gs.bPrefix,
        NbNo : '',
        bShipperName : gs.bShipperName,
        bFreightPartyName :gs.bFreightPartyName,
        bPodCode : gs.bPodCode,
        bIsPsa : gs.bIsPsa,
        mntBook : mntBook,
        locHref:gs.locHref,
        bCntOwner:gs.bCntOwner


      });
    });

  }





  ckTransferValidationByNo(param){
    var mntBook = 0;

    if($('#maintainBook').is(':checked')){
      mntBook = 1;
    }else{
      mntBook = 0;
    }


    //this.gridBookingTransferByNo.getSelectedValues().forEach(get=>{


  }




  private vesselName() {

  }


  gridBookingTransferAllocationEvent(event){}
}
