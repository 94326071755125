

export class ContainerIsoMapping  {

  no                                  :   number  = 0;
  containerIsoMappingId               :   string  = '';
  containerIsoMappingCode             :   string  = '';
  containerIsoMappingSize             :   string  = '';
  sizeCode                            :   string  = '';
  containerIsoMappingType             :   string  = '';
  typeCode                            :   string  = '';
  containerIsoMappingHeight           :   string  = '';
  heightCode                          :   string  = '';
  containerIsoMappingWeight           :   number  = 0;
  containerIsoMappingQuality          :   string  = '';
  qualityCode                         :   string  = '';
  containerIsoMappingReeferIndicator  :   boolean  = false;
  remarks                             :   string  = '';
  isValid                             :   boolean = true;
  dateCreated                         :   string  = '';
  userCreated                         :   string  = '';
  dateModified                        :   string  = '';
  userModified                        :   string  = '';
  dateInvalid                         :   string  = '';
  userInvalid                         :   string  = '';

  constructor () { }
}
