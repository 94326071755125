<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <!--<Error Message Block-->
      <div class="ui error message" [class.hidden]="isError == false">
        <ul>
          <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == ''">
            {{model['error-blOceanVesselCode']}}
          </li>
          <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == ''">
            {{model['error-blOceanVesselVoyage']}}
          </li>
          <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
            {{model['error-blOceanVesselBound']}}
          </li>
          <li [class.hidden]="model2['error-blOceanVesselCode'] == null || model2['error-blOceanVesselCode'] == ''">
            {{model2['error-blOceanVesselCode']}}
          </li>
          <li [class.hidden]="model2['error-blOceanVesselVoyage'] == null || model2['error-blOceanVesselVoyage'] == ''">
            {{model2['error-blOceanVesselVoyage']}}
          </li>
          <li [class.hidden]="model2['error-blOceanVesselBound'] == null || model2['error-blOceanVesselBound'] == ''">
            {{model2['error-blOceanVesselBound']}}
          </li>
        </ul>
      </div>
      <!-- End of Error Message Block-->
    <form class="ui form">
      <div class="ui top attached tabular menu" style="background-color:beige;">
        <a class="item active" data-tab="freightListDetail" (click)="initTab1()">Freight List Report</a>
        <!-- a class="item disabled" data-tab="freightListSummary" (click)="initTab2()">Freight List Summary Report</a -->
      </div>

      <div class="ui bottom attached tab segment active" id="freightListDetailTab" data-tab="freightListDetail">
        <div class="field">
          <table border = 0 style="width:100%;">
            <tr>
              <td style="width:50%">
                <fieldset style="width: 100%;border: 1px solid lightgray;margin-top:-8px;">
                    <legend style="font-weight:bold;color:dodgerblue;">Print By</legend>
                    <div class="field">
                      <div class="two fields">
                        <div class="field">
                          <div class="ui radio checkbox" >
                            <input type="radio"  [checked]="chSelection == 'BL'" name="chSelection"  value = "BL" (change)="changeSelection($event)"  >
                            <label>B/L Wise</label>
                          </div>
                        </div>
                        <div class="field">
                          <div class="ui tiny right floated buttons" >
                            <!-- <button  class="ui button retrieve" (click)="onPrint()" data-content="Print" >Print</button>-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="ui radio checkbox" >
                        <input type="radio" [checked]="chSelection == 'Vessel'" name="chSelection"  value = "Vessel" (change)="changeSelection($event)">
                        <label>Vessel Wise</label>
                      </div>
                    </div>
                  </fieldset>
              </td>

              <td style="width:50%">
                  <fieldset style="width: 100%;border: 1px solid lightgray;margin-top:-8px;">
                      <legend style="font-weight:bold;color:dodgerblue;">Report</legend>
                      <div class="field">
                        <table border="0" style="width:100%;">
                          <tr>
                            <td>
                              <div class="ui radio checkbox" >
                                <input type="radio"  [checked]="chTSelection == 'FLD'" name="chTSelection"  value = "FLD" (change)="changeSelectionR($event)"  >
                                <label>Freight List Details</label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="ui radio checkbox" >
                                <input type="radio" [checked]="chTSelection == 'FSC'" name="chTSelection"  value = "FSC" (change)="changeSelectionR($event)">
                                <label>Freight List Summary By Container Type</label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="ui radio checkbox" >
                                <input type="radio" [checked]="chTSelection == 'FSP'" name="chTSelection"  value = "FSP" (change)="changeSelectionR($event)">
                                <label>Freight List Summary By Place of Payment</label>
                              </div>
                            </td>
                          </tr>
                        </table>                        
                      </div>                      
                    </fieldset>
              </td>
            </tr>
          </table>                    
        </div>

        <div class="field">
          <fieldset style="width: 100%;border: 1px solid lightgray;margin-top:-8px;"> 
            <legend style="font-weight:bold;color:dodgerblue;"></legend>
            <div class="field">
              <div class="two fields">
                <div class="field" >
                  <div class="field">
                    <fieldset style="width: 100%;border: 1px solid lightgray;">
                      <legend style="font-weight:bold;color:dodgerblue;"></legend>
                      <div class="required one field"  [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''" [class.disabled]="lockBL == true">
                        <label>Vessel</label>
                        <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:1400px;"></combo-plugin>
                      </div>

                      <div class="required one field"  [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''" [class.disabled]="lockBL == true">
                        <label>Voyage</label>
                        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                      </div>
                      <div class="required one field"  [class.error]="model['error-blOceanVesselBound'] != null && model['error-blOceanVesselBound'] != ''"  [class.disabled]="lockBL == true">
                        <label>Bound:</label>
                        <input id = "bound" type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']" readonly>
                      </div>

                    </fieldset>
                  </div>

                  <div class="field" >
                    <div class="two fields">
                      <div class="field" >
                        <fieldset style="width: 100%;border: 1px solid lightgray;" >
                          <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria</legend>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Place of Receipt</label>
                            <combo-plugin #cbPOR [settings]="settingPOR" (change)="changeEventPOR($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Port of Loading</label >
                            <combo-plugin #cbLoadPort  [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Port of Discharge</label >
                            <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Port of Delivery</label>
                            <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Port of Transhipment</label >
                            <combo-plugin #cbTranshipment [settings]="settingTranshipment" (change)="changeEventTranshipment($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field" [class.disabled]="lockBL == true">
                            <label>Final Destination</label >
                            <combo-plugin #cbFinDes [settings]="settingFinDes" (change)="changeEventFinDes($event)" style="width:100%;"></combo-plugin>
                          </div>
                        </fieldset>
                      </div>


                      <div class="field" >
                        <div class="field">
                          <fieldset style="width: 100%;border: 1px solid lightgray;">
                            <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                            <div class="field">
                              <div class="ui test checkbox" [class.disabled]="lockBL == true">
                                <input type="checkbox"  [checked]="isSOC == true" name = 'isSOC' (change)="changeContainerSOC($event)" value="SOC" tabindex="0" class="hidden">
                                <label>SOC</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui test checkbox" [class.disabled]="lockBL == true">
                                <input type="checkbox" [checked]="isSOCTs == true" name = 'isSOCTs' (change)="changeContainerSOCTs($event)" value="SOCTS" tabindex="0" class="hidden">
                                <label>SOC T/S</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui test checkbox" [class.disabled]="lockBL == true">
                                <input type="checkbox"  [checked]="isSLOT == true" name = 'isSLOT' (change)="changeContainerSLOT($event)" value="SLOT" tabindex="0" class="hidden">
                                <label>Slot</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui test checkbox" [class.disabled]="lockBL == true">
                                <input type="checkbox"  id= "isCOC" [checked]="isCOC == true" name = 'isCOC' (change)="changeContainerCOC($event)" value="COC" tabindex="0" class="hidden">
                                <label>COC</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui test checkbox" [class.disabled]="lockBL == true">
                                <input type="checkbox"  [checked]="isCOCTs == true" name = 'isCOCTs' (change)="changeContainerCOCTs($event)" value="COCTS" tabindex="0" class="hidden">
                                <label>COC T/S</label>
                              </div>
                            </div><br>
                          </fieldset>
                        </div><br>

                        <div class="field" style="margin-top: 2px">
                          <fieldset style="width: 100%;border: 1px solid lightgray;">
                            <legend style="font-weight:bold;color:dodgerblue;">Container Status</legend>
                            <div class="field">
                              <div class="ui radio checkbox" [class.disabled]="lockBL == true">
                                <input type="radio"  [checked]="chType == 'ALL'" name="chType" value='ALL' (change)="changeType($event)" tabindex="0" class="hidden">
                                <label>ALL</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui radio checkbox" [class.disabled]="lockBL == true">
                                <input type="radio"  [checked]="chType == 'FULL'" name="chType" value='FULL' (change)="changeType($event)"  tabindex="0" class="hidden">
                                <label>Full</label>
                              </div>
                            </div><br>
                            <div class="field">
                              <div class="ui radio checkbox"   [class.disabled]="lockBL == true">
                                <input type="radio" [checked]="chType == 'EMPTY'" name="chType" value='EMPTY' (change)="changeType($event)"  tabindex="0" class="hidden">
                                <label>Empty</label>
                              </div>
                            </div><br>
                          </fieldset>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="field">
                    <div class="ui test checkbox"  >
                      <input type="checkbox"   name="chSilent" tabindex="0" class="hidden">
                      <label>Silent Mode</label>
                    </div>
                  </div><br>
                  <!-- tombol add and remove -->
                  <div class="field" [style.display]="addRemoveButton">
                    <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
                    <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
                  </div>
                  <!-- end tombol -->
                  <div class="field">
                    <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
                  </div>
                  <div class="field" style="width:400px; margin-top: 20px">
                    <label>Generate As :</label>
                    <div class="two fields">
                      <div class = "field">
                        <div class="ui radio checkbox" >
                          <input type="radio"  [checked]="gA == 'PDFFreight'" name="gA"  value = "PDFFreight" (change)="changeGenerateFreight($event)" tabindex="0">
                          <label>PDF</label>
                        </div>
                      </div>
                      
                      
                    </div>
                    <div class="field">
                      <div class="ui radio checkbox" >
                        <input type="radio" [checked]="gA == 'EXCFreight'" name="gA"  value = "EXCFreight" (change)="changeGenerateFreight($event)"  tabindex="0" disabled>
                        <label style="color: grey;">Excel</label>
                      </div>
                    </div>
                  </div>

                  <!-- div class="field" style="width:400px; margin-top: 20px">
                    <label>Generate As :</label>
                    <div class="two fields">
                      <div class = "field">
                        <div class="ui radio checkbox" >
                          <input type="radio"  [checked]="gA == 'PDFFreight'" name="gA"  value = "PDFFreight" (change)="changeGenerateFreight($event)" tabindex="0">
                          <label>PDF</label>
                        </div>
                      </div>
                      
                      
                    </div>
                    <div class="field">
                      <div class="ui radio checkbox" >
                        <input type="radio" [checked]="gA == 'EXCFreight'" name="gA"  value = "EXCFreight" (change)="changeGenerateFreight($event)"  tabindex="0" >
                        <label>Excel</label>
                      </div>
                    </div>
                  </div -->


                </div>

              </div>
            </div>
          </fieldset>
        </div>
      </div>

      <!-- div class="ui bottom attached tab segment" id="freightListSummaryTab" data-tab="freightListSummary" >
        <div class="field">
          <div class="two fields">
            <div  class="required one field"  [class.error]="model2['error-blOceanVesselCode'] != null && model2['error-blOceanVesselCode'] != ''" [class.disabled]="lockBLSum == true" style="width:562px">
              <label>Vessel</label>
              <combo-plugin #cbVesselSum [settings]="settingVesselSum" (change)="changeEventVesselSum($event)" style="width:100%;"></combo-plugin>
            </div>
            <div class="field" style= "margin-left:500px">
              <div class="ui tiny right floated buttons" >
              </div>
            </div>
          </div>
          <div  class="required one field"  [class.error]="model2['error-blOceanVesselVoyage'] != null && model2['error-blOceanVesselVoyage'] != ''" [class.disabled]="lockBLSum == true" style="width:400px">
            <label>Voyage</label>
            <combo-plugin #cbVoyageSum [settings]="settingVoyageSum" (change)="changeEventVoyageSum($event)" style="width:100%;"></combo-plugin>
          </div>
          <div class="field" style="width:400px">
            <label>Port of Loading</label>
            <combo-plugin #cbLoadPortSum [settings]="settingLoadPortSum" (change)="changeEventLoadPortSum($event)" style="width:100%;"></combo-plugin>
          </div>
          <div class="field" style="width:400px">
            <label>Port of Discharge</label>
            <combo-plugin #cbDischargePortSum [settings]="settingDischargePortSum" (change)="changeEventDischargePortSum($event)" style="width:100%;"></combo-plugin>
          </div>
          <div  class="required one field"  [class.error]="model2['error-blOceanVesselBound'] != null && model2['error-blOceanVesselBound'] != ''" [class.disabled]="lockBLSum == true" style="width:400px">
            <label>Bound:</label>
            <input type="text" name="boundSum"  placeholder="Bound" [(ngModel)]="model2['blOceanVesselBound']" >
          </div>
          <div class="field" style="width:400px">
            <label>Report Type :</label>
            <div class = "field">
              <div class="ui radio checkbox" >
                <input type="radio"  [checked]="rdType == 'Cont'" name="rdType"  value = "Cont" (change)="changeRadioType($event)"  tabindex="0">
                <label>Container Type</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox" >
                <input type="radio"  [checked]="rdType == 'POP'" name="rdType"  value = "POP" (change)="changeRadioType($event)" tabindex="0" >
                <label>Place Of Payment</label>
              </div>
            </div>
          </div>
          <div class="field" style="width:400px">
            <label>Generate As :</label>
            <div class="two fields">
              <div class = "field">
                <div class="ui radio checkbox" >
                  <input type="radio"  [checked]="generateAs == 'PDF'" name="generateAs"  value = "PDF" (change)="changeGenerate($event)" tabindex="0">
                  <label>PDF</label>
                </div>
              </div>
              <div class = "field">
                <div class="ui test checkbox" >
                  <input type="checkbox"   name="chSilentSum" tabindex="0">
                  <label>Silent Mode</label>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox" >
                <input type="radio" [checked]="generateAs == 'EXC'" name="generateAs"  value = "EXC" (change)="changeGenerate($event)"  tabindex="0" >
                <label>Excel</label>
              </div>
            </div>
          </div>
        </div>
      </div -->

    </form>
  </div>  <!-- end div container -->
</form>
<pdf-plugin #pdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin>
