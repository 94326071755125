

export class PackageType  {
  no : number = 0;
  packageTypeId    : string = '';
  packageTypeCode  : string = '';
  packageTypeName  : string = '';
  packageTypeInternationalCode  : string = '';
  remarks       : string = '';
  isValid      : boolean = true;
  dateCreated  : string ='';
  userCreated  : string ='';
  dateModified : string ='';
  userModified : string ='';
  dateInvalid  : string ='';
  userInvalid  : string ='';

  constructor () { }
}
