import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { TareWeight, GridPluginComponent2 } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  TextareaPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: "app-local-master-tare-weight-page",
  templateUrl: "./local-master-tare-weight-page.component.html",
  styleUrls: ["./local-master-tare-weight-page.component.css"],
})
export class LocalMasterTareWeightPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent2;
  @ViewChild("cbContainerSizeTypeHistory") cbContainerSizeTypeHistory: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = true;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // * Form Validation
  formValidation = new FormValidation();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;

  settingContainerSizeTypeHistory;
  settingClauseName;

  /* Misc Parameters */
  valContainerSizeType: string = "";
  valTareWeightId: string = "";

  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  officeCode: string = "";

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  typeClauseLogStartDate = "calendar";
  typeClauseLogEndDate = "calendar";

  /* Parameter model */
  model = new TareWeight();
  //selectList = [];

  loading = false;

  lock = false;

  /* validatorRules */
  regContainerSizeType = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
  regTareWeight = /^[-0-9]\d*(\.\d+)?$/;

  validatorRules = {
    containerSizeType: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Container Size & Type.",
        },
        {
          type: "modifiedPattern",
          pattern: this.regContainerSizeType,
          prompt: "Invalid Container Size & Type.",
        },
      ],
    },
    tareWeightKGS: {
      rules: [
        {
          type: "emptyZero",
          prompt: "Please input Tare Weight (KGS).",
        },
        {
          type: "modifiedPattern",
          pattern: this.regTareWeight,
          prompt: "Invalid Tare Weight",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private router: Router
  ) {
    super();

    if( this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim().substring(0,2) != "CN"){
      this.router.navigateByUrl("/main/home");
    }

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Container Size & Type", value: "containerSizeType" },
        { display: "Tare Weight (KGS)", value: "tareWeightKGS" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Container Size & Type",
          field: "containerSizeType",
          width: 200,
          type: "string",
        },
        {
          header: "Tare Weight (KGS)",
          field: "tareWeightKGS",
          width: 50,
          type: "string",
        },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "containerSizeType",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      id: "tareWeightHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterTareWeight/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Container Size & Type",
          field: "containerSizeType",
          width: 200,
          type: "string",
        },
        {
          header: "Tare Weight (KGS)",
          field: "tareWeightKGS",
          width: 50,
          type: "string",
        },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 100 },
        { header: "Date Deleted", field: "dateDeleted", width: 100 },
        { header: "Delete", field: "isDeleted", width: 80 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true,
    };

    this.settingContainerSizeTypeHistory = {
      id: "cbContainerSizeTypeHistory",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneralHistory/{query}',
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "containerSizeType",
      description: "",
      isMark: true,
      columns: [
        {
          header: "Container Size & Type ID",
          field: "tareWeightId",
          width: 300,
          type: "string",
        },
        {
          header: "Container Size & Type",
          field: "containerSizeType",
          width: 300,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };



  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();


    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search=this.officeCode+'/*/*/*';
    this.grid.url =
      this.configService.config.BASE_API.toString() +
      "/LocalMasterTareWeight/findGeneral/" +
      this.officeCode;
    this.grid.loadData();

    this.cbContainerSizeTypeHistory.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterTareWeight/findGeneralHistory/" +
        this.officeCode +
        "/{query}"
    );
    //this.grid.onFirst();

    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  onInput(event) {
    this.model["error-containerSizeType"] = null;
    this.model["error-tareWeightKGS"] = null;
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      // case 'afterLoad':
      //   this.grid.url = this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneral';
      //   break;
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
              if (dt.isDeleted == "Y") {
                this.message(
                  "warning",
                  "Error Input",
                  "The record has been deleted",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                return false;
              } else {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons = "update,delete";
                } else {
                  this.disableToolbarButtons = "";
                }
                this.invisibleToolbarButtons = "create,history,print";

                this.modeForm = true;
                this.modeToolbar = false;
                this.setData();
                this.lock = true;
              }
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.tareWeightId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          //this.grid.search = this.officeCode+'/'+str.replace("search:","");
          this.grid.search = str.replace("search:", "");

          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new TareWeight();
    this.valContainerSizeType = "";
    this.valTareWeightId = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event

    this.loading = true;
     this.message(
       "delete",
       "Deletion Record",
       "Confirm to delete record(s)?",
       "yesno",
       { yes: "this.deleteEvent()", no: "this.loading = false;" }
     );
  }

  handlePrint() {
    var formatName = "report-Tare-Weight-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/78/Tare-Weight-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
  }

  /*
  insert(index:number, char:string, data:string) {
    var idx:number = index;
    if (idx > 0) {
      return data.substring(0, idx) + char + data.substring(idx, data.length);
    } else {
      return char + data;
    }
  };
  */

  handleSave() {
    // handle save event
    console.log('handleSave');
    console.log(this.model)
    this.model.containerSizeType = this.model.containerSizeType.trim();
    this.model.tareWeightKGS = this.model.tareWeightKGS.trim();

    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log(this.model['error-clauseName']);

     console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new TareWeight();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    this.valContainerSizeType = "";
    this.valTareWeightId = "";

    this.cbContainerSizeTypeHistory.setValue("");
    this.cbContainerSizeTypeHistory.close();

    this.gridHistory.onHistory("NaN");
    this.gridHistory.loadData();

  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.url =
      this.configService.config.BASE_API.toString() +
      "/LocalMasterTareWeight/findGeneral/" +
      this.officeCode;
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new TareWeight();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.tareWeightId = data.tareWeightId;
    this.model.containerSizeType = String(data.containerSizeType)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");

    this.model.tareWeightKGS = data.tareWeightKGS;


    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.officeCode = this.officeCode;
  }

  valueContainerSizeTypeHistory(event) {

    if (event["containerSizeType"] != null) {
      this.valContainerSizeType = event["containerSizeType"];
      this.valTareWeightId = event["tareWeightId"];

      let tmp = String(event["containerSizeType"])
        .toUpperCase()
        .replace(/&NBSP;/g, " ");
      this.valContainerSizeType = tmp;

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valTareWeightId);
    }else{
      this.valTareWeightId = '';
      this.valContainerSizeType = '';
    }

  }

  generateTareWeightHistoryReport(event: any) {

    if (this.valTareWeightId == "" || this.valTareWeightId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {

      var formatName = "report-History-Tare-Weight-"+this.valTareWeightId+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/79/History-Tare-Weight-" +
        this.valTareWeightId +
        "/" +
        this.valTareWeightId
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
    }

  }

  retrieveHistory(event: any) {
    // field validation
    this.model['error-containerSizeType'] = '';
    console.log(this.valTareWeightId);
    if(this.valTareWeightId == '') {
      this.model['error-containerSizeType'] = 'Please input Container Size & Type.';

    }else {
      this.gridHistory.loadData();
    }


  }

  saveEvent() {
    // console.log('saveEvent');

      this.loading = true;
      this.model.officeCode = this.officeCode;

      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() + "/LocalMasterTareWeight/save",
          this.model
        )
        .subscribe(
          (resp) => {
            // console.log('STATUS=' + resp.status);
            // console.log('MESSAGE=' + resp.json()['message']);
            if (resp.status == 200 && resp.json()["message"] == "OK") {
              // console.log("ENTER SAVE");
              this.model = new TareWeight();
              this.loading = false;
              this.info = "save successfully";
              //this.setGridEvent = 'refresh';
              this.message(
                "information",
                "Information",
                "Record saved successfully.",
                "okonly",
                { ok: "this.loading=false;this.afterSave();" }
              );
            } else {
              this.loading = false;
              this.message(
                "warning",
                "Warning",
                resp.json()["content"],
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          },
          (error) => {
            this.loading = false;
            // console.log(error.message);
            this.info = error.message;
            this.loading = false;
          }
        );

  }

  deleteEvent() {
    // console.log("Delete Event")

    let checkValid:Boolean = false;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      if(element.isValid === 'N'){
        deleteList.push(element.tareWeightId);
      }else{
        checkValid = true;
      }
    });

    if(checkValid === true){
      this.message(
        "information",
        "Information",
        "Please set record(s) as invalid prior deletion.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    }else{
      if(deleteList.length > 0){
        this.loading = true;
        var currentdate = new Date();
        var datetime =  + currentdate.getFullYear() + "-"
                    + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                    + ("0" + (currentdate.getDate())).slice(-2) + " "
                    + ("0" + (currentdate.getHours())).slice(-2) + ":"
                    + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                    + ("0" + (currentdate.getSeconds())).slice(-2);

        let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"tareWeightId":[]}
        paramsDelete.tareWeightId = deleteList;

        this.genericService
          .POST(
            this.configService.config.BASE_API.toString() +
            // "/MasterClauses/delete",
            // deleteList
            "/LocalMasterTareWeight/softDelete",
            paramsDelete
          )
          .subscribe(
            (resp) => {
              if (resp.status == 200) {
                this.model = new TareWeight();
                //  this.selectList = [];
                this.loading = false;
                this.grid.clearSelectedValues();
                this.grid.onFirst();
                //this.setGridEvent = 'refresh';
                this.loading = true;
                this.message(
                  "information",
                  "Information",
                  "Record deleted successfully. ",
                  "okonly",
                  { ok: "this.loading=false; this.afterDelete();" }
                );
              }
            },
            (error) => {
              this.loading = false;
              // console.log(error.message);
              this.info = error.message;
            }
          );
      }
    }

  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  // * Field Validation Regex
  //regClauseName = /^[\s\r\na-zA-Z0-9-(.)/&',↵]*$/;

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar: string = ""
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    console.log("** checkIfError ** ")
    console.log(regexName)
    console.log(field);


    if (regexName.test(String(this.model[field]).trim()) == true) {
       this.model[`error-${field}`] = "";
    }else{
      if(field == "containerSizeType"){
        if(this.model[field] == ""){
          this.model[`error-${field}`] = "Please input Container Size & Type.";
        }else{
          this.model[`error-${field}`] = "Invalid Container Size & Type.";
        }
      }
      if(field == "tareWeightKGS") {
        if(this.model[field] == ""){
          this.model[`error-${field}`] = "Please input Tare Weight (KGS).";
        }else{
          this.model[`error-${field}`] = "Invalid Tare Weight";
        }
      }
    }
    //return false;
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
