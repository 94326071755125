<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <!--Error Message Block-->
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-pfcFreightPartyId'] == null ||
            model['error-pfcFreightPartyId'] == ''
          "
        >
          {{ model["error-pfcFreightPartyId"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcPlaceOfReceiptCode'] == null ||
            model['error-pfcPlaceOfReceiptCode'] == ''
          "
        >
          {{ model["error-pfcPlaceOfReceiptCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcLoadPortCode'] == null ||
            model['error-pfcLoadPortCode'] == ''
          "
        >
          {{ model["error-pfcLoadPortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcDischargePortCode'] == null ||
            model['error-pfcDischargePortCode'] == ''
          "
        >
          {{ model["error-pfcDischargePortCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcPlaceOfDeliveryCode'] == null ||
            model['error-pfcPlaceOfDeliveryCode'] == ''
          "
        >
          {{ model["error-pfcPlaceOfDeliveryCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcReceiptTypeCode'] == null ||
            model['error-pfcReceiptTypeCode'] == ''
          "
        >
          {{ model["error-pfcReceiptTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcDeliveryTypeCode'] == null ||
            model['error-pfcDeliveryTypeCode'] == ''
          "
        >
          {{ model["error-pfcDeliveryTypeCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcEffectiveFrom'] == null ||
            model['error-pfcEffectiveFrom'] == ''
          "
        >
          {{ model["error-pfcEffectiveFrom"] }}
        </li>
        <li
          [class.hidden]="
            model['error-pfcEffectiveTo'] == null ||
            model['error-pfcEffectiveTo'] == ''
          "
        >
          {{ model["error-pfcEffectiveTo"] }}ng
        </li>
      </ul>
    </div>
    <!--End of Error Message Block-->

    <form class="ui form">
      <div
        [style.display]="modeForm == 'bulkUpdateFlagStatus' ? 'block' : 'none'"
        style="width: 100%"
      >
        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <div
            class="ui inverted dimmer"
            [class.active]="loading == true"
            style="z-index: 1"
          >
            <div class="ui medium text loader">Loading</div>
          </div>

          <div class="ui segment">
            <a class="ui red ribbon label">Search Criteria</a>

            <div class="two fields">
              <div class="field">
                <!-- left side -->
                <div class="two fields">
                  <!-- first row -->
                  <div class="field">
                    <label>Place of Receipt</label>
                    <combo-plugin
                      #cbPlaceOfReceipt
                      [settings]="settingCbPlaceOfReceipt"
                      (change)="changePlaceOfReceipt($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Final Destination</label>
                    <combo-plugin
                      #cbFinalDestination
                      [settings]="settingCbFinalDestination"
                      (change)="changeFinalDestination($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <!-- second row -->
                  <div class="field">
                    <label>Port of Load</label>
                    <combo-plugin
                      #cbPortOfLoad
                      [settings]="settingCbPortOfLoad"
                      (change)="changePortOfLoad($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Port of Transshipment (1)</label>
                    <combo-plugin
                      #cbPortOfTransshipment1
                      [settings]="settingCbPortOfTransshipment1"
                      (change)="changePortOfTransshipment1($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <!-- third row -->
                  <div class="field">
                    <label>Port of Discharge</label>
                    <combo-plugin
                      #cbPortOfDischarge
                      [settings]="settingCbPortOfDischarge"
                      (change)="changePortOfDischarge($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Port of Transshipment (2)</label>
                    <combo-plugin
                      #cbPortOfTransshipment2
                      [settings]="settingCbPortOfTransshipment2"
                      (change)="changePortOfTransshipment2($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <!-- fourth row -->
                  <div class="field">
                    <label>Place of Delivery</label>
                    <combo-plugin
                      #cbPlaceOfDelivery
                      [settings]="settingCbPlaceOfDelivery"
                      (change)="changePlaceOfDelivery($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Port of Transshipment (3)</label>
                    <combo-plugin
                      #cbPortOfTransshipment3
                      [settings]="settingCbPortOfTransshipment3"
                      (change)="changePortOfTransshipment3($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <!-- fifth row -->
                  <div class="field">
                    <label>Freight Party</label>
                    <combo-plugin
                      #cbFreightParty
                      [settings]="settingCbFreightParty"
                      (change)="changeFreightParty($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                  <div class="field">
                    <label>Commodity</label>
                    <combo-plugin
                      #cbCommodity
                      [settings]="settingCbCommodity"
                      (change)="changeCbCommodity($event)"
                    ></combo-plugin>
                  </div>
                </div>

                <div class="two fields">
                  <div class="field">
                    <label>Container Ownership</label>
                    <combo-plugin
                      #cbContainerOwnership
                      [settings]="settingCbContainerOwnership"
                      (change)="changeContainerOwnership($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>

                  <div class="field">
                    <label>Update Method</label>
                    <combo-plugin
                      #cbUpdateMeethod
                      [settings]="settingCbUpdateMethod"
                      (change)="changeContainerOwnership($event)"
                      [disableCombo]="checkLock == true"
                    ></combo-plugin>
                  </div>
                </div>
              </div>
              <!-- end of left box layout -->

              <!-- box right start here -->
              <div class="field">
                <!-- right side -->
                <div class="two fields">
                  <!-- first row -->
                  <div class="field">
                    <div class="two fields">
                      <div class="field">
                        <label>Receipt Type</label>
                        <combo-plugin
                          #cbReceiptType
                          [settings]="settingCbReceiptType"
                          (change)="changeReceiptType($event)"
                          [disableCombo]="checkLock == true"
                        ></combo-plugin>
                      </div>
                      <div class="field">
                        <label>Delivery Type</label>
                        <combo-plugin
                          #cbDeliveryType
                          [settings]="settingCbDeliveryType"
                          value="CY"
                          (change)="changeDeliveryType($event)"
                          [disableCombo]="checkLock == true"
                        ></combo-plugin>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="two fields">
                      <div class="field">
                        <label>Effective From</label
                        ><!--settingEffectiveFrom-->
                        <datetime-plugin
                          #dateEffectiveFrom
                          [settings]="settingDateEffectiveFrom"
                          [disableDatetime]="lockEffectiveFrom"
                        ></datetime-plugin>
                      </div>
                      <div class="field">
                        <label>Effective To</label>
                        <!-- [settings]="settingEffectiveTo" -->
                        <datetime-plugin
                          #dateEffectiveTo
                          [settings]="settingDateEffectiveTo"
                          [disableDatetime]="lockEffectiveTo"
                        ></datetime-plugin>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <!-- second row -->
                  <div class="field">
                    <fieldset style="width: 100%; border: 1px solid lightgray">
                      <legend style="font-weight: bold" disabled="true">
                        DG Class
                      </legend>
                      <div class="field">
                        <div class="ui checkbox">
                          <input type="checkbox" name="pfcDGClass" />
                          <label>C3</label>
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui checkbox">
                          <input type="checkbox" name="pfcDGClass" />
                          <label>C2</label>
                        </div>
                      </div>

                      <div class="field">
                        <div class="ui checkbox">
                          <input type="checkbox" name="pfcDGClass" />
                          <label>C2</label>
                        </div>
                      </div>

                      <div class="field">
                        <div class="ui checkbox">
                          <input type="checkbox" name="pfcDGClass" />
                          <label>C1</label>
                        </div>
                      </div>

                      <div class="field">
                        <div class="ui checkbox">
                          <input type="checkbox" name="pfcDGClass" />
                          <label>All (Include Non DG)</label>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div class="field">
                    <!-- detention and demurrage split start here -->
                    <div class="two fields">
                      <div class="field">
                        <fieldset
                          style="width: 100%; border: 1px solid lightgray"
                        >
                          <legend style="font-weight: bold" disabled="true">
                            Detention
                          </legend>
                          <div class="grouped fields">
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input name="pfcDetention" type="radio" />
                                <label>Standard</label>
                              </div>
                            </div>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input name="pfcDetention" type="radio" />
                                <label>Request</label>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <input
                              type="number"
                              name="pfcDetentionRequest"
                              placeholder="0"
                              maxlength="20"
                              [disabled]="lock == false"
                            />
                          </div>
                        </fieldset>
                      </div>

                      <div class="field">
                        <fieldset
                          style="width: 100%; border: 1px solid lightgray"
                        >
                          <legend style="font-weight: bold" disabled="true">
                            Demurrage
                          </legend>
                          <div class="grouped fields">
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input name="pfcDemurrage" type="radio" />
                                <label>Standard</label>
                              </div>
                            </div>
                            <div class="field">
                              <div class="ui radio checkbox">
                                <input name="pfcDemurrage" type="radio" />
                                <label>Request</label>
                              </div>
                            </div>
                          </div>
                          <div class="field">
                            <input
                              type="number"
                              name="pfcDemurrageRequest"
                              placeholder="0"
                              maxlength="20"
                              [disabled]="lock == false"
                            />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="two fields">
                  <!-- third row -->
                  <div class="field">
                    <fieldset style="width: 100%; border: 1px solid lightgray">
                      <legend style="font-weight: bold" disabled="true">
                        Container Status
                      </legend>
                      <div class="ui checkbox">
                        <input type="checkbox" name="pfcDGClass" />
                        <label>Full</label>
                      </div>

                      <div class="ui checkbox">
                        <input type="checkbox" name="pfcDGClass" />
                        <label>Empty</label>
                      </div>
                    </fieldset>
                  </div>

                  <div class="field">
                    <fieldset style="width: 100%; border: 1px solid lightgray">
                      <legend style="font-weight: bold" disabled="true">
                        Shipment Type
                      </legend>
                      <div class="ui radio checkbox">
                        <input type="radio" name="pfcDGClass" />
                        <label>IRE</label>
                      </div>

                      <div class="ui radio checkbox">
                        <input type="radio" name="pfcDGClass" />
                        <label>ROB</label>
                      </div>

                      <div class="ui radio checkbox">
                        <input type="radio" name="pfcDGClass" />
                        <label>SPECIAL</label>
                      </div>

                      <div class="ui radio checkbox">
                        <input type="radio" name="pfcDGClass" />
                        <label>THRU</label>
                      </div>

                      <div class="ui radio checkbox">
                        <input type="radio" name="pfcDGClass" />
                        <label>RD-TRIP</label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <!-- adhoc start -->
                <div class="two fields">
                  <!-- fourth row -->
                  <fieldset style="width: 100%; border: 1px solid lightgray">
                    <legend style="font-weight: bold" disabled="true">
                      Ah-hoc Shipment
                    </legend>
                    <div class="two fields">
                      <div class="field">
                        <label>Vessel</label>
                        <combo-plugin
                          #cbAdhocVessel
                          [settings]="settingCbAdhocVessel"
                          (change)="changeCbMaintenanceAdhocVessel($event)"
                        ></combo-plugin>
                      </div>
                      <div class="field">
                        <label>Voyage</label>
                        <combo-plugin
                          class="toDisable"
                          #cbAdhocVoyage
                          [settings]="settingCbAdhocVoyage"
                          (change)="changeCbMaintenanceAdhocVoyage($event)"
                        ></combo-plugin>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="two fields">
              <fieldset style="width: 100%; border: 1px solid lightgray">
                <div class="two fields">
                  <div class="field">
                    <grid-plugin
                      #mainGrid
                      [settings]="settingMainGrid"
                      (gridEvent)="mainGridEvent($event)"
                      (infoGrid)="infoGrid($event)"
                    ></grid-plugin>
                  </div>

                  <div class="field">
                    <div class="three fields">
                      <div class="field">
                        <div class="ui checkbox">
                          <input name="pfcDetention" type="checkbox" />
                          <label>Tick All</label>
                        </div>
                        <div class="field">
                          <input
                            type="number"
                            name="pfcDemurrageRequest"
                            placeholder="0"
                            maxlength="20"
                            [disabled]="lock == false"
                          />
                        </div>
                        <div class="field">
                          <input
                            type="number"
                            name="pfcDemurrageRequest"
                            placeholder="0"
                            maxlength="20"
                            [disabled]="lock == false"
                          />
                        </div>
                      </div>
                      <div class="field">&nbsp;</div>
                      <div class="field">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="field">
              <!--Another grid -->
              <fieldset style="width: 100%; border: 1px solid lightgray">
                <legend><b>Freight charges to be updated</b></legend>
                <div class="field">
                  <fieldset style="width: 100%; border: 1px solid lightgray">
                    <div class="two fields">
                      <div class="field">
                        <div class="inline field" style="margin-bottom: 5px">
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Normal</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Open Top</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Flat Rack</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Platform</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Reefer</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Reefer as DRY</label>
                          </div>
                          <div class="ui radio checkbox">
                            <input type="radio" name="containerType" />
                            <label>Tank</label>
                          </div>
                        </div>

                        <div class="field">
                          <grid-plugin
                            #mainGrid
                            [settings]="settingMainGrid"
                            (gridEvent)="mainGridEvent($event)"
                            (infoGrid)="infoGrid($event)"
                          ></grid-plugin>
                        </div>
                      </div>
                      <div class="field">
                        <div class="field" style="margin-bottom: 5px">
                          <button class="ui button" (click)="onProceed()">
                            New
                          </button>
                        </div>
                        <div class="field" style="margin-bottom: 5px">
                          <button class="ui button" (click)="onProceed()">
                            Update
                          </button>
                        </div>
                        <div class="field" style="margin-bottom: 5px">
                          <button class="ui button" (click)="onProceed()">
                            Delete
                          </button>
                        </div>
                        <div class="field" style="margin-bottom: 5px">
                          <button class="ui button" (click)="onProceed()">
                            Cancel
                          </button>
                        </div>
                        <div class="field" style="margin-bottom: 5px">
                          <button class="ui button" (click)="onProceed()">
                            Copy
                          </button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </fieldset>
            </div>
          </div>
          <!-- end of ui segment -->
        </div>
        <!-- End of container -->
      </div>
      <!-- End of flag status form control -->
    </form>
  </div>
</div>
