import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Authorize, Validator, GridPluginComponent, DialogPluginComponent,
  ComboPluginComponent, GenericService, GenericUtil, ConfigService,
  CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { BLHeader } from '../shared/index';
import FormValidation from '../../utils/formValidation';
import { finalize } from 'rxjs/operators';
declare  var $: any;



export class UserDetails {
  userDeptCode = '';
  userDeptName = '';
  userFullName = '';
  userId = '';
  userName = '';
  userPhone = '';
}

export class InvoiceParam {
  invVesselCode = '';
  invVesselId = '';
  invVesselName = '';
  invVesselVoyage = '';
  invVesselBound = '';
  invPayerCode = '';
  invPayerName = '';
  invLoadPortCode = '';
  invLoadPortName = '';
  invAccCode = '';
  invTransshipmentPortCode = '';
  invTransshipmentPortName = '';
  invPlaceOfPaymentCode = '';
  invPlaceOfPaymentName = '';
  invDischargePortCode = '';
  invDischargePortName = '';
  invIsCoc = 'Y';
  invIsSoc = '';
  invIsSlot = '';
  invIsCocTs = '';
  invIsSocTs = '';
  invDeptcode = '';
  invUserName = '';
  invNumberOfPrintSet = 1;

}


export class ToggleOption {
  invOfficeCode = '';
  invBlNo = '';
  invNo = '';
  invPrintByCurrency = 'N';
  invConvertedToSgd = 'Y';
  invUserName = '';
  invUserDeptCode = '';
}

@Component({
  selector:  'app-report-finance-actual-freight-bill-latest-page',
  templateUrl:  './report-finance-actual-freight-bill-latest-page.component.html',
  styleUrls:  ['./report-finance-actual-freight-bill-latest-page.component.css']
})
export class ReportFinanceActualFreightBillLatestPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel:  ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:  ComboPluginComponent;
  @ViewChild('cbPayer') cbPayer:  ComboPluginComponent;
  @ViewChild('cbPlacePayment') cbPlacePayment:  ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort:  ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort:  ComboPluginComponent;
  @ViewChild('cbTranshipmentPort') cbTranshipmentPort:  ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD:  ComboPluginComponent;
  @ViewChild('grid') grid:  GridPluginComponent;
  @ViewChild('gridPDFPrev') gridPDFPrev:  GridPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin:  DialogPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  formValidation = new FormValidation();

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPayer;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingLoadPort;
  settingDischargePort;
  settingTranshipmentPort;
  settingGrid;
  settingGridPDFPrev;
  settingPDF;
  settingPlacePayment;
  newDialogOpt;
  addRemoveButton = '';

  //  types


  officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' :  this.cookieService.getDefaultLocationCode();
  userId = '';
  userEmail = '';
  officeId = '';

  //  lock
  cButtonLock = true;
  dButtonLock = true;
  isError:  Boolean = false;
  isHidden:  Boolean = true;
  selection: string;

  validatorRules = {};
  jasperPath = [];
  dataBLArr = [];
  dataInvoice = [];
  printCopy = 1;
  gridData = [];
  gridRowData = [];


  rowDataProforma:  any[];
  columnDefs;
  gridApiProforma;
  gridColumnProforma;

  getRowStyle;
  isRowSelectable;

  // dialog Variable
  frmDialogMessage = '';
  mergedId: number;
  rowDataUncheck = [];

  // url related service
  userDetails = new UserDetails();
  invoiceParam = new InvoiceParam();
  toggleOption = new ToggleOption();

  rowIndexW = 0;

  constructor(private auth: Authorize, private genericService: GenericService,
              private genericUtil: GenericUtil, private router:  Router, private configService: ConfigService,
              private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront:  [
        {name:  'Retrieve',  event:  'retrieve', icon:  'search' },
        {name:  'OK', event:  'ok', icon:  'print'},
        {name:  'Cancel', event:  'cancel', icon:  'cancel'},
        {name:  'Close', event:  'close', icon:  'power'},
        {name:  'Transmit to Receipt System', event:  'transmitToCN', icon:  'exchange'}
       /* {name:  'Transmit to Receipt System', event:  'rs', icon:  'exchange'},
        { name: 'Transmit to RS with CN', event: 'transmitToCN', icon: 'exchange' },*/
      ],
      buttonsDetail:  [],
      createDefaultFront:  false,
      createDefaultDetail:  false,
      toolbarType:  'label',
      label:  'Actual Invoice'
    };

    this.settingVessel = {
      id         :  'cbVessel',
      type       :  'search enter', //  search | select | select input
      url        :  this.configService.config.BASE_API.toString() +
        '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'vesselName',
      description :  '',
      isMark     :  true,
      columns    :  [
        {header:  'Vessel Code', field:  'vesselCode', width:  100},
        {header:  'Vessel Name', field:  'vesselName', width:  200},
        {header:  'Valid', field:  'isValid', width:  50}
      ],
      maxLength: 20,
    };

    this.settingVoyage = {
      id         :  'cbVoyage',
      type       :  'search enter', //  search | select | select input
      url        :  'N',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'voyage',
      description :  '',
      isMark     :  true,
      columns    :  [
        {header:  'Voyage', field:  'voyage', width:  100},
        {header:  'Bound', field:  'bound', width:  100},
        {header:  'Service', field:  'serviceCode', width:  150},
        {header: 'Local ETA', field: 'localEta', width: 300}
      ],
      maxLength: 20,
    };

    this.settingPayer = {
      id         :  'cbPayer',
      type       :  'search enter', //  search | select | select input
       url       :  'N',
       maxChars   :  0,
       template   :  'grid', //  default
       placeholder:  'Search...',
       title      :  'invPayerName',
       description :  '',
       isMark     :  true,
       columns    :  [
         {header: 'Payer Id', field: 'invPayerId', width: 100},
         {header: 'Payer Name', field: 'invPayerName', width: 300}
       ],
       maxLength: 200,
    };


    this.settingPlacePayment = {
      id         :  'cbPlacePayment',
      type       :  'search enter', //  search | select | select input
      url        :  this.configService.config.BASE_API.toString() + '/invoice/findPOP/{query}',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'invPlaceOfPaymentName',
      description :  '',
      isMark     :  true,
      columns    :  [
        {header: 'Location Code', field: 'invPlaceOfPaymentCode', width: 150},
        {header: 'Location Name', field: 'invPlaceOfPaymentName', width: 300},
      ],
      maxLength: 5,
    };


    this.settingLoadPort = {
      id         :  'cbLoadPort',
      type       :  'search enter', //  search | select | select input
      url        :  'N',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'invPortOfLoadingName',
      description :  '',
      columns    :  [
        {header: 'Location Code', field: 'invPortOfLoadingCode', width: 150},
        {header: 'Location Name', field: 'invPortOfLoadingName', width: 300},
      ],
      maxLength: 300,
    }

     this.settingDischargePort = {
      id         :  'cbDiscPort',
      type       :  'search enter', //  search | select | select input
      url        :  'N',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'invPortOfDischargeName',
      description :  '',
      columns    :  [
        {header: 'Location Code', field: 'invPortOfDischargeCode', width: 150},
        {header: 'Location Name', field: 'invPortOfDischargeName', width: 300},
      ],
      maxLength: 300,
    };

    this.settingTranshipmentPort = {
      id         :  'cbTranshipmentPort',
      type       :  'search enter', //  search | select | select input
      url        :  'N',
      maxChars   :  0,
      template   :  'grid', //  default
      placeholder:  'Search...',
      title      :  'invPortOfTransshipmentName',
      description :  '',
      columns    :  [
        {header: 'Location Code', field: 'invPortOfTransshipmentCode', width: 150},
        {header: 'Location Name', field: 'invPortOfTransshipmentName', width: 300},
      ],
      maxLength: 300,
    };





    this.settingGridPDFPrev = {
      id:  'gridPDFPrev',
      url: 'N',
      page:  '10',
      columns:  [
        {header:  'Invoice No', field:  'invoiceNo', width:  250, editType:  'text'},
      ],
      buttons:  [
      ],
      // store:  this.listStore,
      enableSorting:  true,
      enableSelectAll:  false,
      enablePagination:  false,
      sortingColumns: 'blNo',
      sortingDirection:  'ASC',
      editable:  false,
      height: 500,
      minHeight:  500,
      autoSize: 'auto'
    };

    this.columnDefs = [
        {
          headerName: '',
          width: 50,
          headerCheckboxSelection:  true,
          headerCheckboxSelectionFilteredOnly: false,
          checkboxSelection: true,
          showDisabledCheckboxes: true, // only available from AG Grid 28.1
        },
        {
          headerName:  'Invoice No.',
          field:  'invNo',
          width:  180,
          editable: this.checkInvNoEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 10, rows: 1, cols: 20 }
        },
        {
          headerName:  'B/L No',
          field:  'blNo',
          width:  200,
          editable: this.checkBlNoEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 20, rows: 1, cols: 20 }
        },
        {headerName:  'Payer', field:  'payer', width:  250},
        {
          headerName:  'Reason(s)',
          field:  'reason',
          width:  250,
          editable: this.checkReasonEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 300, rows: 1, cols: 30 }
        },
        {headerName:  'Status', field:  'status', width:  280},
        {headerName: 'Invoice by Currency', field: 'invByCurr', width: 150,  cellRenderer: this.byCurRender.bind(this)},
        {headerName: 'Convert to SGD', field: 'convSGD', width: 150, chekboxSelection: false,
          cellRenderer: this.convertToSGDCellRenderer.bind(this) },
        {headerName: 'Do Not Convert to SGD', field: 'idconvNonSGD', width: 200,
          cellRenderer: this.doNotConvertToSGDCellRenderer.bind(this) },
      ];


    this.settingGrid = {
      id:  'grid',
      url:  'N',
      page:  10,
      columns:  [
        {header:  'Invoice No.', field:  'blNo', width:  180, editType:  'text'},
        {header:  'B/L No', field:  'blNo', width:  250, editType:  'text'},
        {header:  'Payer', field:  'payer', width:  250, editType:  'text'},
        {header:  'Reason(s)', field:  'reason', width:  250, editType:  'text'},
        {header:  'Status', field:  'status', width:  250, editType:  'text'},
        {header:  'Invoice by Currency', field:  'invoiceNo', width:  250, editType:  'text'},
        {header:  'Convert to SGD', field:  'invoiceNo', width:  250, editType:  'text'},
        {header:  'Do Not Convert to SGD', field:  'invoiceNo', width:  250, editType:  'text'},

      ],
      buttons:  [
        { name:  'Edit', event:  'edit', enabled:  true }
      ],
      // store:  this.listStore,
      enableSorting:  true,
      enableSelectAll:  true,
      enablePagination:  false,
      editable: true,
      sortingColumns: 'blNo',
      sortingDirection:  'ASC'
    };

    this.settingPDF = {
      id:  'pdfContainer',
      url:  'N',
      hidePrint:  false,
      tabOptio:  false
    };

    this.getRowStyle = function(params) {
      if (params.node.rowPinned) {
        return { 'font-weight':  'bold' };
      }
      if (params.node.data.invIsChild === 'Y') {
        return {'background' : '#90EE90'};
      }
    };

    this.isRowSelectable = function(params) {
      return !params.data || params.data.invStatus !== 'C' && params.data.invIsValid !== 'N';
    };

  }
  ngOnInit() {
    this.isHidden = false;
    this.rowDataProforma = [];

    // Initial value of accoutn code
    this.invoiceParam.invAccCode = '';
    this.cbPayer.disableCombo = true;
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.mergedId = 0;
  }

  ngAfterViewInit() {
    $('.dataTables_scrollBody').css('height', '395px');
    $('#silentMode').prop('checked', true);

    this.cbPlacePayment.setForceValue('');
    this.userId = this.cookieService.getName();
    this.userEmail = localStorage.getItem('logged').split('|')[1];
    this.officeId = (this.cookieService.getDefaultLocationId() == null) ? '*' : this.cookieService.getDefaultLocationId();

    this.isHidden = false;

    // Invoice setup related
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getUserDetails/' +
      this.cookieService.getName().toUpperCase()).subscribe((resp) => {
      if (resp.json() !== null) {
        this.userDetails.userId = resp.json()['userId'];
        this.userDetails.userDeptCode = resp.json()['userDeptCode'];
        this.userDetails.userDeptName = resp.json()['userDeptName'];
        this.userDetails.userPhone = resp.json()['userPhone'];
        this.userDetails.userFullName = resp.json()['userFullName'];
        this.userDetails.userName = resp.json()['userName'];

        // setup radio button on load
        this.setBound();

        this.invoiceParam.invUserName = this.userDetails.userName;
        this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
      }
    });

    this.disableToolbarButtons = 'retrieve,ok,transmitToCN';
  }

  setBound() {
    if (this.userDetails.userDeptCode === '') {
      $('#selBoundO').prop('checked', false);
      $('#selBoundI').prop('checked', true);
      this.invoiceParam.invVesselBound = 'I';
    } else {
      if (this.userDetails.userDeptCode.substr(0, 1) === 'I') {
        $('#selBoundO').prop('checked', false);
        $('#selBoundI').prop('checked', true);
        this.invoiceParam.invVesselBound = 'I';
      } else {
        $('#selBoundO').prop('checked', true);
        $('#selBoundI').prop('checked', false);
        this.invoiceParam.invVesselBound = 'O';
      }
    }
  }

  toolbarEvent(event) {
    // //  console.log('event:  ' + event);
    // karena get selected rows tidak ada id nya maka pake foreach node buat ambil datanya
    switch (event) {
      case 'rs':
        this.loading = true;
        this.genericService.GET(this.configService.config.BASE_API.toString() +
          '/invoice/transmitReceiptSystem').subscribe((resp) => {
            if (resp.ok) {
              if (resp.json()['status'] === 'ok') {
                let msg = 'Data successfully transmitted to Receipt System.<br />';
                msg += 'Total ' + resp.json()['data'] + ' record(s) are processed.';
                this.dialogPlugin.show('information', 'Information', msg, 'okonly', {ok: 'this.loading=false;'});
              } else {
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
              }
            } else {
              this.dialogPlugin.show('information', 'Information', 'HTTP Call error.', 'okonly', {ok: 'this.loading=false;'});
            }
        });
        break;
      case 'retrieve':
        this.onRetrieve();
        break;
        case 'ok':
        // if retrieving by voyage & the selected invoice(s) is proforma => then block printing
        const selectionBy = $('input[name="selectionBy"]:checked').val();
        if (selectionBy === 'VESSEL') {
          const proformaInvNos = [];
          this.gridApiProforma.getSelectedRows().forEach(dt => {
            if (dt.invStatus === 'P') {
              proformaInvNos.push(dt.invNo);
            }
          });

          if (proformaInvNos.length > 0) {
            this.loading = true;
            this.dialogPlugin.show('information', 'Information',
              'Unable to print, please print actual invoice through retrieval by BL first.', 'okonly', {ok: 'this.loading=false;'});
            break;
          }
        }

        //Hit mighty Ok Check
        const hitParamList = [];
        let rowDt = new ToggleOption();

        this.gridApiProforma.getSelectedRows().forEach(dt => {
          rowDt = new ToggleOption();
          rowDt.invOfficeCode = this.officeCode;
          rowDt.invBlNo = dt.blNo;
          rowDt.invNo = dt.invNo;
          hitParamList.push(rowDt);
        });
        this.loading = true;
        this.genericService.POST(this.configService.config.BASE_API.toString() +
          '/invoice/theMightyOkValidation', hitParamList).subscribe((resp) => {
          if (resp.ok) {
              if (resp.json().status === 'ok' || (resp.json().status === 'nok' && resp.json().error.errType === 'CONTAIN_LOCKED_AI' ) ){
                    if (this.gridApiProforma.getSelectedRows().length > 0 ) {
                                if ($('#silentMode').is(':checked')) {

                                             /* GOS-681 To silent print via Backend service (no direct calling to print service from UI)
                                              check for user spooler first, whether already been configured or not
                                              const pData = {};
                                              pData['userEmail'] = this.userEmail;
                                              pData['userLocationId'] = this.officeId;
                                              pData['userDocumentType'] = 'PROFORMAINVOICE';
                                              this.genericService.POST(this.configService.config.BASE_API.toString() +
                                                '/MasterUsers/checkSpoolers', pData).subscribe((resp) => {
                                                  if (resp.ok) {
                                                    if (resp.json().status.toString() === 'ok') {
                                                      console.log('spooler debug');
                                                      console.log(resp.json());
                                                      this.loading = true;
                                                      this.frmDialogMessage = 'This action will print the document to the nearest printer?';
                                                      this.genericUtil.showDialog(
                                                        'invDialogSilentPrintYesNo',
                                                        'Confirm',
                                                        350,
                                                        150
                                                      );
                                                    } else {
                                                      this.loading = true;
                                                      this.dialogPlugin.show('information', 'Information',
                                                        'Please Ask Administrator to Register Printer Spooler For Your User Id', 'okonly', {ok: 'this.loading=false;'});
                                                    }
                                                  } else {
                                                    this.loading = true;
                                                    this.dialogPlugin.show('information', 'Information',
                                                      'API Call error. Please contact support team.', 'okonly', {ok: 'this.loading=false;'});
                                                  }
                                              });
                                              */

                                              this.frmDialogMessage = 'This action will print the document to the nearest printer?';
                                              this.genericUtil.showDialog(
                                                'invDialogSilentPrintYesNo',
                                                'Confirm',
                                                350,
                                                150
                                              );

                                              // this.dialogPlugin.show('information', 'Information',
                                               // 'Silent print not yet supported at the moment.', 'okonly', {ok: 'this.loading=false;'});
                                            } else {
                                              this.gridRowData = [];
                                              this.gridPDFPrev.listStore.store = [];
                                              this.gridApiProforma.getSelectedRows().forEach(dt => {
                                                this.gridPDFPrev.listStore.addData(
                                                  {'invoiceNo' : dt.invNo, 'blNo': dt.blNo, 'printByCurrency': dt.invByCurr,
                                                    'convertToSGD': dt.convSGD, 'doNotConvertToSGD': dt.idconvNonSGD}
                                                );
                                                this.gridPDFPrev.url = '';
                                                this.gridPDFPrev.loadData();
                                              });
                                              this.loading = false;
                                              this.showPDF();
                                            }
                    } else {
                      this.loading = true;
                      this.dialogPlugin.show('information', 'Information',
                                  'Please select at least 1 record to print.', 'okonly', {ok: 'this.loading=false;'});
                     }
              } else {
                  this.loading = true;
                  let errMsg = '';
                  resp.json().error.forEach(dt => {
                     errMsg += dt.errMessage + '<br />';
                  });
                  this.dialogPlugin.show('information', 'Information', errMsg, 'okonly', {ok: 'this.loading=false;'});
              }
          } else {
            this.loading = true;
            this.dialogPlugin.show('information', 'Information', 'HTTP Call error.', 'okonly', {ok: 'this.loading=false;'});
          }
        });


          break;
        case'cancel':
            this.defaultBehaveBL();
        break;
      case 'close':
        //  handle close event
        this.router.navigateByUrl('/main/home');
        break;
      case 'transmitToCN':
        this.loading = true;
        this.genericService.GET(this.configService.config.BASE_API.toString() +
          '/invoice/transmitReceiptSystemV2').subscribe((resp) => {
            if (resp.ok) {
              if (resp.json()['status'] === 'ok') {
                let msg = 'Data successfully transmitted to Receipt System with CN.<br />';
                msg += 'Total ' + resp.json()['data'] + ' record(s) are processed.';
                this.dialogPlugin.show('information', 'Information', msg, 'okonly', { ok: 'this.loading=false;' });
              } else {
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', { ok: 'this.loading=false;' });
              }
            } else {
              this.dialogPlugin.show('information', 'Information', 'HTTP Call error.', 'okonly', { ok: 'this.loading=false;' });
            }
          });
        break;
    }
  }
  eventMessage(event: any) {
    if(event != ''){
      eval(event);
    }
  }

  setValidatorVessel() {
    this.clearValidatorError();
    this.validatorRules = {
      invVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      invVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      invVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
    };
  }



  changeEventVessel(event) {
    if (event.vesselId == null || event.vesselId === '' || event.vesselId === undefined) {
      this.invoiceParam.invVesselCode = '';
      this.invoiceParam.invVesselId = '';
      this.invoiceParam.invVesselName = '';
    } else {
      this.invoiceParam.invVesselCode = event.vesselCode;
      this.invoiceParam.invVesselId = event.vesselId;
      this.invoiceParam.invVesselName = event.vesselName;

      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
        this.cookieService.getDefaultLocationCode() + '/' + event.vesselId + '/' + this.invoiceParam.invVesselBound.toUpperCase() +
        '/voyage_like=' + '{query}');
      this.cbVoyage.setValue('');

      this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOP/{query}');
    }

    this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
    this.invoiceParam['error-invVesselCode'] = '';
  }


  changeSelection(indexParam) {
    if ($('#currBy_' + indexParam).is(':checked')) {
      $('#idNonConvSGD_' + indexParam).prop('checked', true);
      $('#idConvSGD_' + indexParam).prop('checked', false);
      $('#idConvSGD_' + indexParam).attr('disabled', false);
    } else {
      $('#idNonConvSGD_' + indexParam).prop('checked', false);
      $('#idConvSGD_' + indexParam).attr('disabled', false);
      $('#idConvSGD_' + indexParam).prop('checked', true);
    }

    /*
    if ($('#currBy_' + indexParam).is(':checked')) {
      $('#idNonConvSGD_' + indexParam).prop('checked', true);
      $('#idConvSGD_' + indexParam).prop('checked', false);
      $('#idConvSGD_' + indexParam).attr('disabled', false);
      this.gridApiProforma.forEachNode(node => {
        if (node.id == indexParam) {

          this.toggleOption = new ToggleOption();
          this.toggleOption.invOfficeCode = this.officeCode;
          this.toggleOption.invBlNo = node.data.blNo;
          this.toggleOption.invNo = node.data.invNo;
          this.toggleOption.invPrintByCurrency = 'Y';
          this.toggleOption.invConvertedToSgd = 'N';
          this.toggleOption.invUserName = this.userDetails.userName;
          this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

          console.log(node);
          console.log(node.data.blNo);
          console.log(node.data.invNo);
          node.setDataValue('invByCurr', 1);
          node.setDataValue('convSGD', 0);
          node.setDataValue('idconvNonSGD', 1);


          console.log(this.toggleOption);
          this.loading = true;
          const self = this;
          this.genericService.POST(this.configService.config.BASE_API.toString() +
            '/invoice/setPrintByCurrency/', this.toggleOption).subscribe( (resp) => {
            if (resp.ok) {
              if (resp.json().status === 'ok') {
                const rowDataNew = [];
                const rowDataOld = [];

                self.gridApiProforma.forEachNode( nodes => rowDataOld.push(nodes));
                console.log('----oldRowData---');
                console.log(rowDataOld);

                rowDataOld.forEach( (data, index) => {
                  rowDataNew.push(data.data);
                  console.log('pushed data ');
                  console.log(data);
                  console.log('data.data.blNo >>  ' + data.data.blNo);
                  console.log('resp.json().data[0].invBlNo >>  ' + resp.json().data[0].invBlNo);

                  if (data.data.blNo === resp.json().data[0].invBlNo && data.data.payerId === resp.json().data[0].invPayerId) {
                    resp.json().data.forEach((dt, idx) => {
                      let invStatusString = '';
                      if (dt['invStatus'] === 'A') {
                        if (dt['invIsPrintedActual'] === 'Y') {
                          invStatusString = 'Actual invoice printed.';
                        } else {
                          invStatusString = 'Actual created but not yet printed.';
                        }
                      } else if (dt['invStatus'] === 'P') {
                        if (dt['invIsPrinted'] === 'Y') {
                          invStatusString = 'Proforma invoice printed.';
                        } else {
                          invStatusString = 'Proforma invoice created.';
                        }
                      } else {
                        invStatusString = 'Unable determine status.';
                      }
                      rowDataNew.push({
                        invNo: dt['invNo'],
                        blNo: dt['invBlNo'],
                        payer: dt['invPayerName'],
                        payerId: dt['invPayerId'],
                        reason: dt['invReason'],
                        status: invStatusString,
                        invIsValid: dt['invIsValid'],
                        invSplitMethod: dt['invSplitMethod'],
                        payerNo: dt['invPayerOrderInBl'],
                        blFcCurrency: dt['invHomeCurrency'],
                        invHash: dt['invHash'],
                        convSGD: (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                        idconvNonSGD: (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                        invByCurr: (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                        invIsChild: dt['invPartOfInvoice']
                      });
                    });
                  }
                });

                self.clearGrid();
                self.gridApiProforma.setRowData(rowDataNew);
                self.loading = false;

              }
            } else {
              self.loading = true;
              this.dialogPlugin.show('information', 'Information', 'Error occurred!', 'okonly', {ok: 'this.loading=false;'});
            }
          });
        }
      });
    } else {
      this.rowDataUncheck = [];
      this.gridApiProforma.forEachNode( nodes => this.rowDataUncheck.push(nodes.data));

      this.frmDialogMessage =
        'This action will causing all any siblings Invoice will be cancelled and merged?';
      this.genericUtil.showDialog(
        'invDialogYesNo',
        'Confirm',
        350,
        150
      );
    }
    */
  }

// Dialog handler
  onDialogInvYes() {
    this.loading = true;
    $('#idNonConvSGD_' + this.mergedId).prop('checked', false);
    $('#idConvSGD_' + this.mergedId).attr('disabled', false);
    $('#idConvSGD_' + this.mergedId).prop('checked', true);

    this.gridApiProforma.forEachNode(node => {
      if (node.id == this.mergedId) {
        this.toggleOption = new ToggleOption();
        this.toggleOption.invOfficeCode = this.officeCode;
        this.toggleOption.invBlNo = node.data.blNo;
        this.toggleOption.invNo = node.data.invNo;
        this.toggleOption.invPrintByCurrency = 'N';
        this.toggleOption.invConvertedToSgd = 'Y';
        this.toggleOption.invUserName = this.userDetails.userName;
        this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

        node.setDataValue('invByCurr', 0);
        node.setDataValue('convSGD', 1);
        node.setDataValue('idconvNonSGD', 0);

        const self = this;
        this.genericService.POST(this.configService.config.BASE_API.toString() +
          '/invoice/setPrintByCurrency', this.toggleOption).subscribe((resp) => {
          const rowDataNew = [];
          const rowDataOld = [];

          self.gridApiProforma.forEachNode( nodes => rowDataOld.push(nodes));

          rowDataOld.forEach( (data, index) => {
            resp.json().data.forEach((dt, idx) => {
              if (data.data.invNo === dt.invNo) {
                rowDataOld.splice(index, 1);
              }
            });

          });

          rowDataOld.forEach( (data, index) => {
            rowDataNew.push(data.data);
          });

          self.clearGrid();
          self.gridApiProforma.setRowData(rowDataNew);

          self.loading = false;

        });
      }

    });
    this.genericUtil.closeDialog('invDialogYesNo');
  }
  onDialogInvNo() {
    this.gridApiProforma.setRowData(this.rowDataUncheck);
    this.genericUtil.closeDialog('invDialogYesNo');
  }



  // Grid Checkbox Event
  convertToSGDCellRenderer(params) {
    const self = this;
    const eDiv = document.createElement('div');

    if (params.value == 1) {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idConvSGD_' + params.node.id +
        '" name="converter_' + params.node.id + '" type="radio" disabled checked value={{params.value}}></div>';
    } else {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idConvSGD_' + params.node.id +
        '" name="converter_' + params.node.id + '" type="radio" disabled value={{params.value}}></div>';
    }

    eDiv.addEventListener('change', (rad) => {
      $('#idNonConvSGD_' + params.node.id).prop('checked', false);
      $('#idConvSGD_' + params.node.id).prop('checked', true);
      $('#currBy_' + params.node.id).prop('checked', false);
      self.gridApiProforma.forEachNode(node => {
        if (node.id == params.node.id) {
          this.toggleOption = new ToggleOption();
          this.toggleOption.invOfficeCode = this.officeCode;
          this.toggleOption.invBlNo = node.data.blNo;
          this.toggleOption.invNo = node.data.invNo;
          this.toggleOption.invPrintByCurrency = 'N';
          this.toggleOption.invConvertedToSgd = 'Y';
          this.toggleOption.invUserName = this.userDetails.userName;
          this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

          node.setDataValue('invByCurr', 0);
          node.setDataValue('convSGD', 1);
          node.setDataValue('idconvNonSGD', 0);

          this.genericService.POST(this.configService.config.BASE_API.toString() +
            '/invoice/setPrintByCurrency', this.toggleOption).subscribe((resp) => {
            const rowDataNew = [];
            const rowDataOld = [];

            self.gridApiProforma.forEachNode( nodes => rowDataOld.push(nodes));

            rowDataOld.forEach( (data, index) => {
              resp.json().data.forEach((dt, idx) => {
                if (data.data.invNo === dt.invNo) {
                  rowDataOld.splice(index, 1);
                }
              });

            });

            rowDataOld.forEach( (data, index) => {
              rowDataNew.push(data.data);
            });

            self.clearGrid();
            self.gridApiProforma.setRowData(rowDataNew);

          });
        }
      });
    });

    return eDiv;
  }

  doNotConvertToSGDCellRenderer(params) {
    const self = this;
    const eDiv = document.createElement('div');

    if (params.value == 1) {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idNonConvSGD_' +
        params.node.id + '" name="converter_' + params.node.id + '" type="radio" disabled checked value={{params.value}}></div>';
    } else {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idNonConvSGD_' +
        params.node.id + '" name="converter_' + params.node.id + '" type="radio" disabled value={{params.value}}></div>';
    }

    eDiv.addEventListener('change', (rad) => {
      $('#idNonConvSGD_' + params.node.id).prop('checked', true);
      $('#idConvSGD_' + params.node.id).prop('checked', false);
      self.gridApiProforma.forEachNode(node => {
        if (node.id == params.node.id) {
          this.toggleOption = new ToggleOption();
          this.toggleOption.invOfficeCode = this.officeCode;
          this.toggleOption.invBlNo = node.data.blNo;
          this.toggleOption.invNo = node.data.invNo;
          this.toggleOption.invPrintByCurrency = 'Y';
          this.toggleOption.invConvertedToSgd = 'N';
          this.toggleOption.invUserName = this.userDetails.userName;
          this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

          node.setDataValue('convSGD', 0);
          node.setDataValue('idconvNonSGD', 1);

          this.genericService.POST(this.configService.config.BASE_API.toString() +
            '/invoice/setConvertSgd', this.toggleOption).subscribe();
        }
      });
    });

    return eDiv;
  }
  byCurRender(params) {
    const cellValue = '';
    const self = this;
    const eDiv = document.createElement('div');
    eDiv.setAttribute('style', 'align=\'center\' ');
    if (params.value == 1) {
      eDiv.innerHTML = '<input style="width:15px;height:15px;margin-top:3%" id="currBy_' + params.node.id +
        '" disabled name="currBy_' + params.node.id + '" checked   value = 1  type="checkbox" />';
      eDiv.addEventListener('change', (listen) => {
        self.mergedId = params.node.id;
        self.changeSelection(params.node.id);
      });

    } else {
      eDiv.innerHTML = '<input style="width:15px;height:15px;margin-top:3%" id="currBy_' + params.node.id +
        '" disabled name="currBy_' + params.node.id + '"   value = 0  type="checkbox" /  >';

      eDiv.addEventListener('change', () => {
        self.changeSelection(params.node.id);
      });
    }

    return eDiv;
  }


  changeEventCriteria(event) {
    const isiGrid = [];
    this.invoiceParam = new InvoiceParam();
    if (event.target.value.toString() === 'VESSEL') {
      this.selection = 'VESSEL';
      this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
      this.gridApiProforma.updateRowData({
        remove: isiGrid
      });
      this.gridApiProforma.refreshCells();

      // this.gridColumnProforma.getColumn('invNo').getColDef().editable = false;
      // this.gridColumnProforma.getColumn('blNo').getColDef().editable = false;
      this.addRemoveButton = 'none';
      this.dataBLArr = [];
      this.dataInvoice = [];
      this.clearValidatorError();
      this.isError = false;
      // $('#selBoundO').prop('checked', true);
      // $('#selBoundI').prop('checked', false);

      this.setBound();

      $('#myContainer').attr('disabled', false);
      $('#idBound').attr('disabled', false);
      $('#selInvoice').prop('checked', true);
      $('#selBL').prop('checked', false);
      $('#selVessel').prop('checked', true);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbPayer.disableCombo = true;

      this.cbPayer.setValue('');

      this.cbPlacePayment.disableCombo = false;
      this.cbPlacePayment.setForceValue('');
      this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();

      this.cbDischargePort.setValue('');
      this.cbLoadPort.setValue('');

      this.cbTranshipmentPort.setValue('');

      this.cbLoadPort.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbVessel.setValue('');
      this.cbVoyage.setValue('');
      this.disableToolbarButtons = 'ok,transmitToCN';

      this.invoiceParam.invIsCoc = 'Y';
      this.invoiceParam.invIsCocTs = 'N';
      this.invoiceParam.invIsSoc = 'N';
      this.invoiceParam.invIsSocTs = 'N';
      this.invoiceParam.invIsSlot = 'N';
      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

    } else if (event.target.value.toString() === 'INVOICE') {
      this.mergedId = 0;
      this.selection = 'INVOICE';
      this.invoiceParam = new InvoiceParam();
      this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
      this.invoiceParam.invUserName = this.userDetails.userName;
      const isiGrid = [];
      this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
      this.gridApiProforma.updateRowData({
        remove: isiGrid
      });
      this.gridApiProforma.refreshCells();
      // this.gridColumnProforma.getColumn('invNo').getColDef().editable = true;
      // this.gridColumnProforma.getColumn('blNo').getColDef().editable = false;
      this.dataBLArr = [];
      this.dataInvoice = [];

      $('#myContainer').attr('disabled', true);
      $('#fieldSortBy').attr('disabled', true);
      $('#idBound').attr('disabled', true);
      $('#selVessel').prop('checked', false);
      $('#selInvoice').prop('checked', true);
      $('#selBL').prop('checked', false);

      this.setBound();



      $('#printFAI').prop('checked', true);
      $('#printItem').prop('checked', false);
      $('#convertSGD').prop('checked', true);
      $('#cntSOC').prop('checked', false);
      $('#silentMode').prop('checked', true);
      $('#cntCOC').prop('checked', true);
      $('#cntSOCTS').prop('checked', false);
      $('#cntCOCTS').prop('checked', false);
      $('#cntSLOT').prop('checked', false);
      $('#invByCurrency').prop('checked', false);
      $('#notconvertSGD').prop('checked', false);
      this.cbVessel.disableCombo = true;
      this.cbVoyage.disableCombo = true;
      this.cbPayer.disableCombo = false;
      this.addRemoveButton = '';
      this.cbVessel.setValue('');
      this.cbVoyage.setValue('');
      this.cbPayer.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbDischargePort.setValue('');
      this.cbLoadPort.setValue('');
      this.cbTranshipmentPort.setValue('');

      this.cbPayer.setValue('');

      this.cbPlacePayment.setForceValue('');
      this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
      this.clearValidatorError();
      this.isError = false;

      this.invoiceParam.invIsCoc = 'Y';
      this.invoiceParam.invIsCocTs = 'N';
      this.invoiceParam.invIsSoc = 'N';
      this.invoiceParam.invIsSocTs = 'N';
      this.invoiceParam.invIsSlot = 'N';

      $('#addGrid').attr('disabled', false);
      $('#remGrid').attr('disabled', false);
      this.disableToolbarButtons = 'retrieve,ok,transmitToCN';
    } else {
      this.defaultBehaveBL();
    }
  }

  defaultBehave() {
    // this.loading = false;
    this.mergedId = 0;
    this.selection = 'INVOICE';
    this.invoiceParam = new InvoiceParam();
    this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
    this.invoiceParam.invUserName = this.userDetails.userName;
    const isiGrid = [];
    this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
    this.gridApiProforma.updateRowData({
      remove: isiGrid
    });
    this.gridApiProforma.refreshCells();
    // this.gridColumnProforma.getColumn('invNo').getColDef().editable = true;
    // this.gridColumnProforma.getColumn('blNo').getColDef().editable = false;
    this.dataBLArr = [];
    this.dataInvoice = [];

    $('#myContainer').attr('disabled', true);
    $('#fieldSortBy').attr('disabled', true);
    $('#idBound').attr('disabled', true);
    $('#selVessel').prop('checked', false);
    $('#selInvoice').prop('checked', true);
    $('#selBL').prop('checked', false);

    this.setBound();



    $('#printFAI').prop('checked', true);
    $('#printItem').prop('checked', false);
    $('#convertSGD').prop('checked', true);
    $('#cntSOC').prop('checked', false);
    $('#silentMode').prop('checked', true);
    $('#cntCOC').prop('checked', true);
    $('#cntSOCTS').prop('checked', false);
    $('#cntCOCTS').prop('checked', false);
    $('#cntSLOT').prop('checked', false);
    $('#invByCurrency').prop('checked', false);
    $('#notconvertSGD').prop('checked', false);
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbPayer.disableCombo = false;
    this.addRemoveButton = '';
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbPayer.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbDischargePort.setValue('');
    this.cbLoadPort.setValue('');
    this.cbTranshipmentPort.setValue('');

    this.cbPayer.setValue('');

    this.cbPlacePayment.setForceValue('');
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.clearValidatorError();
    this.isError = false;

    this.invoiceParam.invIsCoc = 'Y';
    this.invoiceParam.invIsCocTs = 'N';
    this.invoiceParam.invIsSoc = 'N';
    this.invoiceParam.invIsSocTs = 'N';
    this.invoiceParam.invIsSlot = 'N';

    $('#addGrid').attr('disabled', false);
    $('#remGrid').attr('disabled', false);
    this.disableToolbarButtons = 'retrieve,ok,transmitToCN';

  }

  defaultBehaveBL() {
    this.mergedId = 0;
    const isiGrid = [];
    this.invoiceParam = new InvoiceParam();
    this.selection = 'BL';
    this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
    this.gridApiProforma.updateRowData({
      remove: isiGrid
    });
    this.gridApiProforma.refreshCells();
    // this.gridColumnProforma.getColumn('invNo').getColDef().editable = false;
    // this.gridColumnProforma.getColumn('blNo').getColDef().editable = true;
    this.dataBLArr = [];
    this.dataInvoice = [];

    $('#myContainer').attr('disabled', true);
    $('#fieldSortBy').attr('disabled', true);
    $('#idBound').attr('disabled', true);
    $('#selVessel').prop('checked', false);
    $('#selInvoice').prop('checked', false);
    $('#selBL').prop('checked', true);


    this.setBound();

    $('#printFAI').prop('checked', true);
    $('#printItem').prop('checked', false);
    $('#convertSGD').prop('checked', true);
    $('#cntSOC').prop('checked', false);
    $('#silentMode').prop('checked', true);
    $('#cntCOC').prop('checked', true);
    $('#cntSOCTS').prop('checked', false);
    $('#cntCOCTS').prop('checked', false);
    $('#cntSLOT').prop('checked', false);
    $('#invByCurrency').prop('checked', false);
    $('#notconvertSGD').prop('checked', false);

    this.invoiceParam.invIsCoc = 'Y';
    this.invoiceParam.invIsCocTs = 'N';
    this.invoiceParam.invIsSoc = 'N';
    this.invoiceParam.invIsSocTs = 'N';
    this.invoiceParam.invIsSlot = 'N';

    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbPayer.disableCombo = true;
    this.addRemoveButton = '';
    this.cbPayer.setValue('');
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbDischargePort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbDischargePort.setValue('');
    this.cbLoadPort.setValue('');
    this.cbTranshipmentPort.setValue('');
    this.cbPlacePayment.setForceValue('');
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.clearValidatorError();
    this.isError = false;

    this.disableToolbarButtons = 'retrieve,ok,transmitToCN';
  }

  changeBound(event) {
    this.invoiceParam.invVesselBound = event.target.value.toUpperCase();
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
      this.cookieService.getDefaultLocationCode() + '/' + this.invoiceParam.invVesselId + '/' + this.invoiceParam.invVesselBound.toUpperCase() +
      '/voyage_like=' + '{query}');
    this.cbVoyage.setValue('');

    this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') +
      '/' + this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOP/{query}');
  }

  changeOfSet(event){
    this.printCopy = event.target.value ;
  }
  printResult(res) {
    if (res.add) {
      res.add.forEach(function (rowNode) {

      });
    }
  }
  rowEvent(act: string){
    var tableStore = [];

     if (act == 'add') {
      this.gridApiProforma.updateRowData({
        add:   [{'invNo': '', 'blNo': '', 'payer': '', 'reason': '', 'status': '', 'invByCurr': 0, 'convSGD': 1,
          'idconvNonSGD': 0, 'selected': 1, 'retrieve': 0}]
      });
     } else {
        const rowSel = this.gridApiProforma.getSelectedRows();
        this.gridApiProforma.updateRowData({
          remove:   rowSel
        });
        this.gridApiProforma.refreshCells();
        this.gridApiProforma.refreshView();
     }
  }



  onRetrieve() {
    this.clearGrid();
    this.invoiceParam['error-invPlaceOfPaymentCode'] = '';
    // this.invoiceParam['error-invAccCode'] = '';
    this.invoiceParam['error-invVesselCode'] = '';
    this.invoiceParam['error-invVesselVoyage'] = '';
    this.invoiceParam['error-invContainerOwnership'] = '';
    this.isError = false;

    if (this.invoiceParam.invIsCoc.trim() + this.invoiceParam.invIsCocTs.trim() +
      this.invoiceParam.invIsSoc.trim() + this.invoiceParam.invIsSocTs +
      this.invoiceParam.invIsSlot.trim() === 'NNNNN') {
      this.invoiceParam['error-invContainerOwnership'] = 'Please select at least one container ownership.';
    }

    /*
    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.invoiceParam['error-invPlaceOfPaymentCode'] = 'Please input Place of Payment.';
    }
     */

    /*
    if (this.invoiceParam.invAccCode === '') {
      this.isError = true;
      this.invoiceParam['error-invAccCode'] = 'Please input Acct/Debtor code.';
    } else {
      this.isError = false;
    }
    */

    if (this.invoiceParam.invVesselCode === '') {
      this.invoiceParam['error-invVesselCode'] = 'Please input vessel.';
    }

    if (this.invoiceParam.invVesselVoyage === '') {
      this.invoiceParam['error-invVesselVoyage'] = 'Please input voyage.';
    }

    if (this.invoiceParam['error-invPlaceOfPaymentCode'] !== '' ||
      this.invoiceParam['error-invVesselCode'] !== '' ||
      this.invoiceParam['error-invVesselVoyage'] !== '' ||
      this.invoiceParam['error-invContainerOwnership'] !== '') {
      this.isError = true;
    }

    if (!this.isError) {
      // this.loading = true;
      this.disableToolbarButtons = 'ok,cancel,close,transmitToCN';
      const self = this;
      // ag-grid load
      this.gridApiProforma.showLoadingOverlay();
      this.genericService.POST(this.configService.config.BASE_API.toString() +
        '/invoice/generateInvoiceByVessel/', this.invoiceParam).pipe(finalize(() => this.gridApiProforma.hideOverlay())).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['status'] == 'ok') {

            if (resp.json()['data'].length > 0) {
              this.disableToolbarButtons = '';
              const rowData = [];
              const selectedData = [];
              this.gridApiProforma.forEachNode(node => rowData.push(node.data));
              if (rowData.length > 0) {
                rowData.forEach(items => {
                  selectedData.push(items);
                  self.gridApiProforma.updateRowData({
                    remove:  selectedData
                  });
                });
              }


              self.gridData = [];

              resp.json()['data'].forEach(dt => {
               let invStatusString = '';
                if (dt['invStatus'] === 'A') {
                  if(dt['invIsValid'] === 'N'){
                    invStatusString = 'Invalid invoice';
                  }
                  else if (dt['invIsPrintedActual'] === 'Y' && dt['invIsValid'] != 'N') {
                    invStatusString = 'Actual invoice printed.';
                  } else {
                    invStatusString = 'Actual created but not yet printed.';
                  }
                  if(dt['blIsFinished'] !== 'Y') {
                    invStatusString = '';
                  }
                } else if (dt['invStatus'] === 'P' ) {
                  if(dt['invIsValid'] === 'N'){
                    invStatusString = 'Invalid invoice';
                  }
                  else if (dt['invIsPrinted'] === 'Y' && dt['invIsValid'] != 'N') {
                    invStatusString = 'Proforma invoice printed.';
                  } else {
                    invStatusString = 'Proforma invoice created but not yet printed.';
                  }
                  if(dt['blIsFinished'] !== 'Y') {
                    invStatusString = '';
                  }
                } else if (dt['invStatus'] === 'C') {
                  invStatusString = 'CANCELLED';
                } else {
                  invStatusString = 'Unable determine status.';
                }

                self.gridData.push({
                  invNo : dt['invNo'],
                  blNo : dt['invBlNo'],
                  payer : dt['invPayerName'],
                  payerId : dt['invPayerId'],
                  reason : dt['invReason'],
                  status : invStatusString,
                  invIsValid : dt['invIsValid'],
                  invSplitMethod : dt['invSplitMethod'],
                  payerNo : dt['invPayerOrderInBl'],
                  blFcCurrency : dt['invHomeCurrency'],
                  invHash : dt['invHash'],
                  convSGD :  (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                  idconvNonSGD : (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                  invByCurr : (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                  invIsChild: dt['invPartOfInvoice'],
                  invStatus: dt['invStatus'],
                });
              });

              self.gridApiProforma.updateRowData({
                add:  self.gridData
              });

              self.loading = false;
            } else {
              self.loading = true;
              this.dialogPlugin.show('information', 'Information', 'There is no Invoice being generated for this Vessel and Voyage.', 'okonly', {ok: 'this.loading=false; this.clearGrid();'});
            }

          } else {
            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                selectedData.push(items);
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                this.gridApiProforma.updateRowData({
                  remove:  selectedData
                });
              });
            }
          }
          self.loading = false;
          // this.disableToolbarButtons = 'retrieve';
        }
      });

    }
  }


  clearGrid() {
    this.mergedId = 0;
    this.gridApiProforma.setRowData([]);
  }

  changeEventVoyage(event) {
    if (event.voyage == null || event.voyage == '' || event.voyage == undefined) {
      this.invoiceParam.invVesselVoyage = '';

    } else {
      this.invoiceParam.invVesselVoyage = event.voyage;
      this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOP/{query}');
      this.invoiceParam['error-invVesselVoyage'] = '';
      this.clearValidatorErrorbox();
      this.genericService.POST(this.configService.config.BASE_API.toString() + '/invoice/checkGenerateInvoiceByVessel/', this.invoiceParam).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['size'] > 0) {
            if (resp.json()['flagPOT'] == 'Y') {
              this.cbPayer.disableCombo = false;
              this.cbLoadPort.disableCombo = false;
              this.cbTranshipmentPort.disableCombo = false;
              this.cbDischargePort.disableCombo = false;
              this.cbPayer.setValue('');
              this.cbLoadPort.setValue('');
              this.cbTranshipmentPort.setValue('');
              this.cbDischargePort.setValue('');
            } else {

              this.cbPayer.disableCombo = false;
              this.cbLoadPort.disableCombo = false;
              this.cbTranshipmentPort.disableCombo = true;
              this.cbDischargePort.disableCombo = false;
              this.cbPayer.setValue('');
              this.cbLoadPort.setValue('');
              this.cbTranshipmentPort.setValue('');
              this.cbDischargePort.setValue('');
            }
          } else {
            // disable all

            this.cbPayer.disableCombo = true;
            this.cbLoadPort.disableCombo = true;
            this.cbTranshipmentPort.disableCombo = true;
            this.cbDischargePort.disableCombo = true;
            this.cbPayer.setValue('');
            this.cbLoadPort.setValue('');
            this.cbTranshipmentPort.setValue('');
            this.cbDischargePort.setValue('');
          }
        } else {
          console.log('ERROR HIT');
        }
      });

    }

  }

  changeEventLoadPort(event) {
    if (event.invPortOfLoadingCode == null || event.invPortOfLoadingCode === '' ||
      event.invPortOfLoadingCode == undefined) {
      this.invoiceParam.invLoadPortCode = '';
      this.invoiceParam.invLoadPortName = '';
    } else {
      this.invoiceParam[`error-invLoadPortCode`] = "";
      this.invoiceParam.invLoadPortCode = event.invPortOfLoadingCode;
      this.invoiceParam.invLoadPortName = event.invPortOfLoadingName;
    }
  }

  changeEventDischargePort(event) {
    if (event.invPortOfDischargeCode == null || event.invPortOfDischargeCode === '' ||
      event.invPortOfDischargeCode == undefined) {
      this.invoiceParam.invDischargePortCode = '';
      this.invoiceParam.invDischargePortName = '';
    } else {
      this.invoiceParam[`error-invDischargePortCode`] = "";
      this.invoiceParam.invDischargePortCode = event.invPortOfDischargeCode;
      this.invoiceParam.invDischargePortName = event.invPortOfDischargeName;
    }
  }
  changeEventTranshipmentPort(event) {
    if (event.invPortOfTransshipmentCode == null || event.invPortOfTransshipmentCode == '' ||
      event.invPortOfTransshipmentCode == undefined) {
      this.invoiceParam.invTransshipmentPortCode = '';
      this.invoiceParam.invTransshipmentPortName = '';
    } else {
      this.invoiceParam[`error-invTransshipmentPortCode`] = "";
      this.invoiceParam.invTransshipmentPortCode = event.invPortOfTransshipmentCode;
      this.invoiceParam.invTransshipmentPortName = event.invPortOfTransshipmentName;
    }
  }

  infoGrid(event){}

   /**
   *
   * @param blNo
   * @param method
   * @param theLineAlreadyChosen if the line that user input already chosen (checkbox checked).
   */
   getInvoiceStatus(blNo: String, method: String, theLineAlreadyChosen: boolean) {
    /*
    this.invoiceParam['error-invPlaceOfPaymentCode'] = '';
    // this.invoiceParam['error-invAccCode'] = '';
    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.isError = true;
      this.invoiceParam['error-invPlaceOfPaymentCode'] = 'Please input Place of Payment.';
    } else {
      this.isError = false;
    }*/
    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    }

    /*
    if (this.invoiceParam.invAccCode === '') {
      this.isError = true;
      this.invoiceParam['error-invAccCode'] = 'Please input Acct/Debtor code.';
    } else {
      this.isError = false;
    }*/

    if (!this.isError) {
      this.gridData = [];
      const radioParamYes = 1;
      const radioParamNo = 0;
      const paramCur = 0;



      if (blNo.trim() === '' || blNo.trim() === undefined) {
        blNo = 'blNo';
      } else {
        this.loading = true;
        this.disableToolbarButtons = 'retrieve,ok,transmitToCN';
      }
      let boxCheck: number;
      const self = this;
      let url = '';
      if (method === 'BL') {
        url = this.configService.config.BASE_API.toString() + '/invoice/generateInvoiceActual/' + this.userDetails.userName.toUpperCase().trim() +
          '/' + this.officeCode + '/' + blNo.toUpperCase().trim() + '/' + this.invoiceParam.invVesselBound +
          '/' + (this.invoiceParam.invAccCode === '' ? '*' : this.invoiceParam.invAccCode) + '/' + this.invoiceParam.invPlaceOfPaymentCode;
      } else if (method === 'INV') {
        url = this.configService.config.BASE_API.toString() + '/invoice/generateInvoiceByInvNoActual/' + this.userDetails.userName.toUpperCase().trim() +
          '/' + this.officeCode + '/' + blNo.toUpperCase().trim() + '/' + this.invoiceParam.invVesselBound +
          '/' + (this.invoiceParam.invAccCode === '' ? '*' : this.invoiceParam.invAccCode) + '/' + this.invoiceParam.invPlaceOfPaymentCode;
      }
      this.genericService.GET(url).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['status'] == 'ok') {

            // GSO-1027
            let message = resp.json()['message'];
            if(method === 'BL' && message !== 'All is well'){
              this.dialogPlugin.show('information', 'Information', message, 'okonly', {ok: 'this.loading=false;'});
            }

            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                if (items['invNo'].toString() === '' || items['blNo'].toString() === '') {
                  selectedData.push(items);
                }
                self.gridApiProforma.updateRowData({
                  remove:  selectedData
                });
              });
            }

            if (resp.json()['data'].length > 0) {
              this.disableToolbarButtons = 'retrieve,transmitToCN';
            }


            resp.json()['data'].forEach(dt => {
            let invStatusString = '';
              if (dt['invStatus'] === 'A') {
                if(dt['invIsValid'] === 'N'){
                  invStatusString = 'Invalid invoice';
                }
                else if (dt['invIsPrintedActual'] === 'Y' && dt['invIsValid'] != 'N') {
                  invStatusString = 'Actual invoice printed.';
                } else {
                  invStatusString = 'Actual created but not yet printed.';
                }

                if(dt['blIsFinished'] !== 'Y') {
                  invStatusString = '';
                }
              } else if (dt['invStatus'] === 'P') {
                if(dt['invIsValid'] === 'N'){
                  invStatusString = 'Invalid invoice';
                }
                else if (dt['invIsPrinted'] === 'Y'  && dt['invIsValid'] != 'N') {
                  invStatusString = 'Proforma invoice printed.';
                } else {
                  invStatusString = 'Proforma invoice created but not yet printed.';
                }
              } else if (dt['invStatus'] === 'C') {
                invStatusString = 'CANCELLED';
              } else {
                invStatusString = 'Unable determine status.';
              }
              self.gridData.push({
                invNo : dt['invNo'],
                blNo : dt['invBlNo'],
                payer : dt['invPayerName'],
                payerId : dt['invPayerId'],
                reason : dt['invReason'],
                status : invStatusString,
                invIsValid : dt['invIsValid'],
                invSplitMethod : dt['invSplitMethod'],
                payerNo : dt['invPayerOrderInBl'],
                blFcCurrency : dt['invHomeCurrency'],
                invHash : dt['invHash'],
                convSGD :  (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                idconvNonSGD : (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                invByCurr : (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                invIsChild: dt['invPartOfInvoice'],
                invStatus: dt['invStatus'],
                /*
                convSGD :  selectedData[0]['convSGD'],
                idconvNonSGD : selectedData[0]['idconvNonSGD'],
                invByCurr : selectedData[0]['invByCurr']
                */
              });
            });

            self.gridApiProforma.updateRowData({
              add:  self.gridData
            });

            // Set checked status:
            if (theLineAlreadyChosen) {
              this.gridApiProforma.forEachNode(node => {
                const affectedNode = self.gridData.find(item => item.blNo === node.data.blNo && item.invNo === node.data.invNo);
                if (affectedNode) {
                    node.setSelected(true);
                }
              });
            }

            const testData = [];
            this.gridApiProforma.forEachNode(node => testData.push(node.data));
            let invNos = testData.map(o => o.invNo);
            var isDuplicateinvNos = invNos.some((item, idx) => item ? invNos.indexOf(item) != idx : false);
            let params = {
              rowIndex: this.rowIndexW,
              value: resp.json()['data'][0].invBlNo
            }
            if(isDuplicateinvNos){
              this.showMessageDuplicateInvoiceNo(params, testData, 'BL Number');
            }

            self.loading = false;
          } else {
            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                if (items['blNo'] === '' || items['invNo'] === '') {
                  selectedData.push(items);
                }
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                this.gridApiProforma.updateRowData({
                  remove:  selectedData
                });
              });
            }
          }
          self.loading = false;
        }
      });
    }
  }
  clearValidatorError() {
    this.invoiceParam['error-invVesselCode'] = '';
    this.invoiceParam['error-invVesselVoyage'] = '';
    this.invoiceParam['error-invVesselBound'] = '';
    this.invoiceParam['error-invLoadPortCode'] = '';
    this.invoiceParam['error-invDischargePortCode'] = '';
    this.invoiceParam['error-invTransshipmentPortCode'] = '';
    this.invoiceParam['error-invContainerOwnership'] = '';
  }

  clearValidatorErrorbox() {
    if (
      this.invoiceParam['error-invVesselCode'] == '' &&
      this.invoiceParam['error-invVesselVoyage'] == ''
    ) {
      this.isError = false
    }

  }
  showPDF() {
    const self = this;

    this.newDialogOpt = {
      title: 'PDF Preview',
      modal: true,
      closeText: 'hide',
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function(event, ui) {
        // hide close button.
        $('.ui-dialog').css('z-index', 103);
        $('.ui-widget-overlay').css('z-index', 102);

        $('body').first().css('overflow', 'hidden');
      },
      close: function(event, ui) {
        $('body').first().css('overflow', ' auto');
        self.clearPDFPlugin();
      }
    };

    this.showDialog('pdfContainerNewInvoice', this.newDialogOpt);

  }

  gridEventPDFPrev(event) {
    console.log(event);
    switch (event.split('.')[0]) {
      case 'selected':
        this.selectedEvent(event);
        break;
      case 'click' :
        this.selectedEvent(event);
        break;
      default:
        break;
    }
  }

  selectedEvent(event) {
    //// // // console.log(event);
    let no = 0;
    const opt = (event.split('.').length > 1 ? event.split('.')[1].split('-')[0] : event );
    const opt2 = (event.split('.').length > 1 ? event.split('.')[1].split('-')[1] : event );
    console.log('OPT ' + opt);

    switch (opt) {
      case 'checked':
        no = opt2;
        this.selectedListMod(no, this.gridPDFPrev, 'no');
        this.gridPDFPrev.listStore.store.forEach(fe => {
          if (fe['no'] == no) {

            const printByCurrency = fe['printByCurrency'] > 0 ? 'Y' : 'N';
            const convertToSGD = fe['convertToSGD'] > 0 ? 'Y' : 'N';
            const doNotConvertToSGD = fe['doNotConvertToSGD'] > 0 ? 'Y' : 'N';
            let conversionOption = 'N';

            if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
              conversionOption = 'Y';
            } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
              conversionOption = 'N';
            } else {
              conversionOption = 'X';
            }

            this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getPDFActual/' + this.officeCode + '/' +
              fe['blNo'] + '/' + fe['invoiceNo'] + '/' + this.userDetails.userName + '/' + printByCurrency + '/' + conversionOption).subscribe((resp) => {
              if (resp.ok) {
                if(resp._body !== "nok"){
                  fe['pdfUrl'] = this.configService.config.pdfUrl + '/download/invoice/' + resp._body;
                /*
                if (this.configService.config.server === 'DEVELOPMENT') {
                  fe['pdfUrl'] = 'http://localhost/download/invoice/' + resp._body;
                } else if (this.configService.config.server === 'PRODUCTIONSTG') {
                  fe['pdfUrl'] = 'http://staging.glossys.samudera.id' + '/download/invoice/' + resp._body;
                } else if (this.configService.config.server === 'PRODUCTION') {
                  fe['pdfUrl'] = 'http://sg.glossys.samudera.id' + '/download/invoice/' + resp._body;
                }
                */

                $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
                $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').append('<object id="o" data="' + fe['pdfUrl'] + '" type="application/pdf" width="100%" height="100%"></object>');
                }else{
                  this.dialogPlugin.show('Error', 'Information', "Unable Generate Invoice for FOF=0", 'okonly', {ok: ''});
                  this.closeDialog('pdfContainerNewInvoice');
                }
              }
            });
          }
        });

        break;
      case 'unchecked':
        no = opt2;
        break;
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach(ff => {
        if (ff[key] != value) {
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
  }

  showDialog(id: string, options: any) {
    $('#' + id).dialog(options).dialog('open');
  }

  closeDialog(id: string) {
    $('#' + id).dialog('close');
    $('#' + id).dialog('destroy');
  }

  clearPDFPlugin() {
    this.gridPDFPrev.listStore.store.splice(0, this.gridPDFPrev.listStore.store.length);
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-label').show();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-grid-container').show();
    this.gridPDFPrev.clearSelectedValues();
    this.closeDialog('pdfContainerNewInvoice');
  }

  onGridReady(params){
    this.gridApiProforma = params.api;
    this.gridColumnProforma = params.columnApi;
    // this.gridColumnProforma.getColumn('invNo').getColDef().editable = false;
    // this.gridColumnProforma.getColumn('blNo').getColDef().editable = true;
    this.gridApiProforma.hideOverlay();
  }

  onCellValueChanged(params) {
    this.rowIndexW = params.rowIndex;
    const testDat = [];
    const colId = params.column.getId();
    this.gridApiProforma.forEachNode(node => testDat.push(node.data));

    testDat.forEach(grup => {
      grup.blNo = grup.blNo.toUpperCase();
      grup.invNo = grup.invNo.toUpperCase();
      grup.reason = grup.reason.toUpperCase();
    });

    if(params.oldValue){
      return;
    }

    let invNos = testDat.map(o => o.invNo);
    var isDuplicateinvNos = invNos.some((item, idx) => item ? invNos.indexOf(item) != idx : false);
    if(isDuplicateinvNos){
      this.showMessageDuplicateInvoiceNo(params, testDat, 'Invoice Number');
      return;
    }

    this.gridApiProforma.refreshCells();

    if (this.isSpecialChars(params)) {
      const rowData = [];
      this.gridApiProforma.forEachNode(node => rowData.push(node.data));
      if (rowData.length > 0) {
        this.showMessageCheckAlphanumeric(rowData, params);
        return;
      }
    }

    if ($('#selBL').is(':checked')) {
      if (colId == 'blNo') {
        this.getInvoiceStatus(params.value, 'BL', params.node.selected);

      }
    } else if ($('#selInvoice').is(':checked')) {
      if (colId == 'invNo') {
        this.getInvoiceStatus(params.value, 'INV', params.node.selected);
      }
    }
  }

  showMessageCheckAlphanumeric(rowData, params){
    const selectedData = [];
    rowData.forEach(items => {
      this.dialogPlugin.show(
        "error",
        "Error",
        "Only accept alphanumeric",
        "okonly",
        { ok: "" }
      );
      selectedData.push(items);
      const selectedRow = selectedData.splice(params.rowIndex, 1)
      this.gridApiProforma.updateRowData({
        remove: selectedRow
      });
    });
  }

  showMessageDuplicateInvoiceNo(params, testDat, numberType){
    const selectedTestDat = [];
    this.dialogPlugin.show(
      "error",
      "Error",
      `Duplicate ${numberType} ${params.value} detected`,
      "okonly",
      { ok: "" }
    );
    testDat.forEach(items => {
      selectedTestDat.push(items);
      const selectedRow = selectedTestDat.splice(params.rowIndex, 1)
      this.gridApiProforma.updateRowData({
        remove: selectedRow
      });
    });
  }

  changeEventPayer(event) {
    if (event.invPayerId == null || event.invPayerId === '' || event.invPayerId == undefined) {
      this.invoiceParam.invPayerCode = '';
      this.invoiceParam.invPayerName = '';
    } else {
      this.invoiceParam.invPayerCode = event.invPayerId;
      this.invoiceParam.invPayerName = event.invPayerName;
    }
  }

  changeEventPlacePayment(event) {
    if (event.invPlaceOfPaymentCode != null && event.invPlaceOfPaymentCode !== '' && event.invPlaceOfPaymentCode != undefined) {
      this.invoiceParam[`error-invPlaceOfPaymentCode`] = "";
      this.invoiceParam.invPlaceOfPaymentCode = event.invPlaceOfPaymentCode;
      this.invoiceParam.invPlaceOfPaymentName = event.invPlaceOfPaymentCode;
    } else {
      this.invoiceParam.invPlaceOfPaymentCode = '';
      this.invoiceParam.invPlaceOfPaymentName = '';
    }
  }

  // CheckboxesHandles
  changeContainerOwnershipCOC(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsCoc = 'Y';
    } else {
      this.invoiceParam.invIsCoc = 'N';
    }
  }

  changeContainerOwnershipCOCTS(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsCocTs = 'Y';
    } else {
      this.invoiceParam.invIsCocTs = 'N';
    }
  }

  changeContainerOwnershipSOC(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSoc = 'Y';
    } else {
      this.invoiceParam.invIsSoc = 'N';
    }
  }

  changeContainerOwnershipSOCTS(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSocTs = 'Y';
    } else {
      this.invoiceParam.invIsSocTs = 'N';
    }
  }

  changeContainerOwnershipSLOT(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSlot = 'Y';
    } else {
      this.invoiceParam.invIsSlot = 'N';
    }
  }


  onDialogSilentPrintYes() {
    const listOfInvoices = [];

    // construct payload to silent print
    let payload = {}; // real payload
    let printDataList = []; // used to hold the document list
    let invData = {}; // not sure what is it used for
    let printDocDetail = {};  // use to hold the document details to be printed

    const self = this;

    const lengthGridApiProforma = this.gridApiProforma.getSelectedRows().length;
    let counter = 0;


    this.gridApiProforma.getSelectedRows().forEach(fe => {
      const printByCurrency = fe['invByCurr'] > 0 ? 'Y' : 'N';
      const convertToSGD = fe['convSGD'] > 0 ? 'Y' : 'N';
      const doNotConvertToSGD = fe['idconvNonSGD'] > 0 ? 'Y' : 'N';
      let conversionOption = 'N';

      if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
        conversionOption = 'Y';
      } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
        conversionOption = 'N';
      } else {
        conversionOption = 'X';
      }


      self.genericService.GET(self.configService.config.BASE_API.toString() + '/invoice/getPDFSilentActual/' + self.officeCode + '/' +
        fe['blNo'] + '/' + fe['invNo'] + '/' + self.userDetails.userName + '/' + printByCurrency + '/' +
        conversionOption).subscribe((resp) => {
          if (resp.ok) {
            counter++;
            invData = {};
            payload = {};
            printDocDetail = {};
            printDataList = [];

            invData['docNo'] = fe['invNo'];
            invData['printDoc'] = [];

            if (self.configService.config.server === 'PRODUCTION') {
              printDocDetail['docName'] = resp._body;
            } else if (self.configService.config.server === 'PRODUCTIONSTG') {
              printDocDetail['docName'] = resp._body;
            } else {
              printDocDetail['docName'] = resp._body;
            }
            printDocDetail['docAttachName'] = '';
            printDocDetail['spoolerDoc'] = 'PROFORMAINVOICE';
            printDocDetail['spoolerDocAttach'] = '';
            printDocDetail['totalPrint'] = 1;
            printDocDetail['hasAttach'] = 'N';
            printDocDetail['proreason'] = fe['reason'];
            printDocDetail['docAttachName'] = '';

            invData['printDoc'].push(printDocDetail);
            // printDataList.push(invData);
            listOfInvoices.push(invData);
            payload['printList'] = listOfInvoices;

            if (lengthGridApiProforma == counter) {
              this.handleCallPrint(payload);
            }
        }
      });
    });

    // setTimeout(() => {
    //   payload['userId'] = this.userId;
    //   payload['userEmail'] = this.userEmail;
    //   payload['userLocationId'] = this.officeId;
    //   payload['server'] = this.configService.config.server;

    //   const hitUrl = this.configService.config.BASE_API.toString() + '/PrintService/print';
    //   this.genericService.POST(hitUrl, payload).subscribe((resp) => {
    //     // console.log('this is response from printserver');
    //     console.log(resp._body);
    //   });
    // }, 500);

    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
     // this.loading = true;

    /*
    this.dialogPlugin.show('information', 'Information',
      'Your document has been printed. Please check at your nearest printer to collect them.', 'okonly', {ok: 'this.loading=false;'});
    */
  }

  handleCallPrint(payload) {
      payload['userId'] = this.userId;
      payload['userEmail'] = this.userEmail;
      payload['userLocationId'] = this.officeId;
      payload['server'] = this.configService.config.server;

      const hitUrl = this.configService.config.BASE_API.toString() + '/PrintService/print';
      this.genericService.POST(hitUrl, payload).subscribe((resp) => {
        if (resp.json()['status'] === "ok") {
          this.dialogPlugin.show(
            'information',
            'Information',
            'Your document has been printed. Please check at your nearest printer to collect them.',
            'okonly', { ok: '' }
          );
          this.loading = false;
        } else {
          this.dialogPlugin.show(
            "warning",
            "Warning",
            resp.json()['message'],
            "okonly",
            { ok: "" }
          );
          this.loading = false;
        }
      });
  }

  onDialogSilentPrintNo() {
    this.loading = false;
    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = '',
    acctDebtorCode?: string
  ) {
    let clipboardData = acctDebtorCode ? '' : event.clipboardData;
    let pastedText = acctDebtorCode ? event : clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar);
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return
    }
  }

  handleBackspaceValueRegex(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        const regexPattern = /^[A-Za-z ]+$/;
        if (!regexPattern.test(event.target.value)) {
          this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        }
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar){
    if (regexName.test(pastedText) == false) {
      this.invoiceParam[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.invoiceParam[`error-${field}`] = "";
    }
  }

  isSpecialChars(event: any): boolean {
    let pastedText = event.value;
    var specialChars = /^[a-zA-Z0-9]*$/;
    if (specialChars.test(pastedText) == false) {
      return true;
    }
    return false;
  }

  onCellKeyPress($event) {
    if ($event.data.blNo === '') {
      $('#loader').css("display", "none");
    }
  }

  checkInvNoEditable(params) {
    const selectionBy = $('input[name="selectionBy"]:checked').val();
    return params.data.invStatus != 'C' && selectionBy === 'INVOICE' && params.data.invIsValid !== 'N';
  }

  checkBlNoEditable(params) {
    const selectionBy = $('input[name="selectionBy"]:checked').val();
    return params.data.invStatus != 'C' && selectionBy === 'BL' && params.data.invIsValid !== 'N';
  }

  checkReasonEditable(params) {
    return params.data.invStatus != 'C' && params.data.invIsValid !== 'N';
  }
}
