import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MSailingSchedule, MSailingScheduleDetail, AllocationModel } from '../shared/index';
import { Router } from '@angular/router';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import {log} from "util";
import get = Reflect.get;

declare  var $:any;

export class Param {
  TbCoc: number = 0;
  TbSoc: number = 0;
  TbSlot: number = 0;
  TbTotal: number = 0;
  TbNvocc: number = 0;
  bOfficeCode: string='';
  bOceanVesselId: string='';
  bOceanVesselCode: string='';
  bOceanVesselName: string='';
  bOceanVesselVoyage: string='';
  bOceanVesselBound: string = 'O';
  bPot1Code: string='';
  bFinalDestinationCode : string='';
  bPodCode: string='';
  bPolCode: string='';
  bContainerOwnership : string='';
  bContainerOwnershipCount : string='';
  bContainerStatus : string = '';
  bContainerDataStatus : string = '';
  shdraft : string='';
  dataCSSN : string='';
  direction: string = '';
  selected: string = '';

  //allocationValidator:Boolean = false;
  constructor() {
  }
}

@Component({
  selector: 'app-transaction-bl-flag-status-page',
  templateUrl: './transaction-bl-flag-status-page.component.html',
  styleUrls: ['./transaction-bl-flag-status-page.component.css']
})

export class TransactionBLFlagStatusPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbpod') cbPortOfDischarge:ComboPluginComponent;
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridDetails') gridDetails:GridPluginComponent;
  @ViewChild('cbpoc') cbAllotmentType:ComboPluginComponent;
  @ViewChild('cbCSSN') cbCSSN:ComboPluginComponent;
  @ViewChild('cbCossn') cbCossn:ComboPluginComponent;
  @ViewChild('cbLocalTranshipment') cbLocalTranshipment:ComboPluginComponent;
  @ViewChild('cbFullEmpty') cbFullEmpty:ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD:ComboPluginComponent;
  @ViewChild('cbPOL') cbPOL:ComboPluginComponent;
  @ViewChild('cbPOT1') cbPOT1:ComboPluginComponent;
  @ViewChild('cbFinalDestination') cbFinalDestination:ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';
  /* Parameter for dialog */
  dialog:any;
  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';
  modeToolbar = false;
  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingPOL;
  settingPOT1;
  settingFinalDestination;
  settingCossn;
  settingLocalTranshipment;
  settingFullEmpty;
  settingGrid;
  isLoad : boolean = false;

  /* Parameter for enable/disable Toolbar buttons */
  settingGridDetails;
  settingAlloment;

  // lock
  cButtonLock = true;
  dButtonLock = true;
  retLock = false;

  model = new MSailingSchedule;
  modelDetails = new AllocationModel;
  modelCopy = new MSailingSchedule;
  copyStructure:boolean = false;
  formLabel = "";
  firstForm = false;
  operator:string = "";
  allocationRecordId:string = "";
  poDischarge:string = "";
  loading = false;
  extVesselCapacity:number = 0;
  srcVesselCapacity:number = 0;
  hgridDetailsStore = [];


  // validator bukan dari sini, dari file lain
  validatorRules = {
    bOceanVesselBound: {
      rules: [{
        type: 'empty',
        prompt: 'Please input bound.'
      }]
    },
    bOceanVesselCode: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Vessel.'
      }]
    },
    bOceanVesselVoyage: {
      rules:[{
        type  : 'empty',
        prompt: 'Please input Voyage.'
      }]
    },
  }

  param = new Param();
  isError: Boolean = false;
  /*
  isCOC:Boolean = true;
  isSOC:Boolean = true;
  isSLOT:Boolean = true;
  isNVOCC:Boolean = false;
  */
  isCom:Boolean = false;
  isUncom:Boolean = false;
  isDel:Boolean = false;
  isWcon:Boolean = false;
  isWcharge:Boolean = false;
  tsBooking:string = 'All';
  tsCossn : string = 'All';

  total_coc_allocated:number = 0;
  total_soc_allocated:number = 0;
  total_slot_allocated:number = 0;
  total_all_allocated:number = 0;

  constructor(private cdr: ChangeDetectorRef, private router: Router, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Close',  event: 'close', icon : 'remove circle' },
      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType           : 'label',
      label                 : 'BL Flag Status'
    }

    this.settingGrid = {
//      url: this.configService.config.BASE_API.toString() + "/BlFlag/GetBL",
      url: "http://glossys.samudera.com:16001/BlFlag/GetBL",
      page: 10,
      columns: [
        {header: 'Bl Reference', field: 'blNo', width: 100},
        {header: 'BLS', field: 'blStatus', width: 100},
        {header: 'F', field: 'blIsFinished', width: 100},
        {header: 'POL', field: 'blLoadPortCode', width: 100},
        {header: 'POD', field: 'blDischarePortCode', width: 100},
        {header: 'POT', field: 'blPortOfTransshipment1code', width: 100},
        {header: 'Freight Party', field: '', width: 100},
        {header: 'Shipper', field: 'blShipperName', width: 100},
        {header: 'Consignee', field: 'blConsigneeName', width: 100},
        {header: 'Notify Party', field: 'blNotifypartyname', width: 100},
        {header: 'Created By', field: 'blUserCreated', width: 100},
        {header: 'Created Date', field: 'blDateCreated', width: 100},
      ],
      buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'blNo',
      sortingDirection: 'ASC',
      height : 500
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 130},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }


    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 50},
        {header: 'Service', field: 'serviceCode', width: 100},
      ]
    }

    this.settingAlloment = {
      id          : 'cAllot',
      type        : 'select input', // search | select | select input
      url         : 'assets/json/allotmentType.json',//this.configService.config.BASE_API.toString() + '/MasterLocation/searchLocation?q={query}',
      maxChars    : 3,
      placeholder : 'Allocation Type',
      code        : 'allotmentCode',
      title       : 'allotmentType',
      description  : '',
      flag        : ''
    }

    this.settingLocalTranshipment = {
      id          : 'cbLocalTranshipment',
      type        : 'select', // search | select | select input
      url         : 'assets/json/localTranshipment.json',
      placeholder : 'All / Local / Transhipment',
      code        : 'localTranshipmentCode',
      title       : 'localTranshipmentName'
    }

    this.settingFullEmpty = {
      id          : 'cbFullEmpty',
      type        : 'select', // search | select | select input
      url         : 'assets/json/fullEmpty.json',
      placeholder : 'All / Full / Empty',
      code        : 'fullEmptyCode',
      title       : 'fullEmptyName'
    }

    this.settingCossn = {
      id          : 'cbCossn',
      type        : 'select',
      url         : 'assets/json/CSSN.json',
      placeholder : 'All / COC / SOC / SLOT',
      code        : 'localCSSNCode',
      title       : 'localCSSNName'
    }

    this.settingPOD = {
      id          : 'cbPOD',
      type        : 'search enter', // search | select | select input
//      url         : this.configService.config.BASE_API.toString() + '/BlFlag/GetDischargePortCode',
      url         : 'http://glossys.samudera.com:16001/BlFlag/GetDischargePortCode',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'blDischargePortPrint',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'blDischargePortCode', width: 100},
        {header: 'Location Name', field: 'blDischargePortPrint', width: 300},
      ]
    }

    this.settingPOL = {
      id          : 'cbPOL',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/BlFlag/GetPortOfLoad',
      url         : 'http://glossys.samudera.com:16001/BlFlag/GetPortOfLoad',

      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'blLoadPortPrint',
      description  : '',
      isMark      : true,
      align       : 'right',
      columns     : [
        {header: 'Location Name', field: 'blLoadPortPrint', width: 300},
        {header: 'Location Code', field: 'blLoadPortCode', width: 100},
      ]
    }

    this.settingPOT1 = {
      id          : 'cbPOT1',
      type        : 'search enter', // search | select | select input
//      url         : this.configService.config.BASE_API.toString() + '/BlFlag/GetPortOfTransshipment',
      url         : 'http://glossys.samudera.com:16001/BlFlag/GetPortOfTransshipment',

      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'blPortOfTransshipment1Code',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'POT 1', field: 'blPortOfTransshipment1Print', width: 300},
        {header: 'POT 1 Code', field: 'blPortOfTransshipment1Code', width: 100},
      ]
    }

    this.settingFinalDestination = {
      id          : 'cbFinalDestination',
      type        : 'search enter', // search | select | select input
//      url         : this.configService.config.BASE_API.toString() + '/BlFlag/GetFinalDestination',
      url         : 'http://glossys.samudera.com:16001/BlFlag/GetFinalDestination',

      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'blFinalDestinationCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'BL Final Destination Code', field: 'blFinalDestinationCode', width: 100},
        {header: 'BL Final Destination Print', field: 'blFinalDestinationPrint', width: 300},
      ]
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit():void {
    $('#gridHeader').find('th.collapsing').find('div.checkbox').remove();
    this.grid.search = '';
    this.grid.onFirst();

  }

  toolbarEvent(event: any) {
    // console.log('event : ' + event);
    switch (event) {
      case 'retrieve':
        this.handleRetrieve();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        this.router.navigateByUrl('/main/home');
        break;
      default:
        let str:string = event;
        // console.log('str = ' + str);
        break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridDetails(event) {
    // console.log(event);
    this.info = event;
  }

  gridEvent(event) {
    // console.log(event);
  }

  updateVoyageUrl(type:string){
    switch(type){
      case "header" :
        this.updateVoyageUrl1a(this.cbVoyage, this.model);
        break;
    }
  }
  updateVoyageUrl1a(combo:ComboPluginComponent, dt:MSailingSchedule){
    if(dt.bound == ''){
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+dt.vesselId+"/*/{query}/SGSIN");
    }else{
      combo.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+dt.vesselId+"/"+dt.bound.toUpperCase()+"/{query}/SGSIN");
    }
  }

  changeEventVessel(event){
    this.grid.url = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBound";
    if(event.vesselId != null){
      this.cbVoyage.disableCombo = false;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.model.bound.toUpperCase()+"/{query}/SGSIN");
      this.cbVoyage.setValue("");
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.param.bOceanVesselId = event.vesselId;
      this.param.bOceanVesselCode = event.vesselCode;
      this.param.bOceanVesselName = event.vesselName;
      if(event.capacity != null){
        this.extVesselCapacity = parseFloat(event.capacity);
      }
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
      this.param.bOceanVesselId = "";
      this.param.bOceanVesselCode = "";
      this.param.bOceanVesselName = "";
    }
  }

  changeEventVoyage(event) {
    if(event.voyage != null){
      this.model.voyage = event.voyage;
      this.model.bound = event.bound;
      this.param.bOceanVesselBound = event.bound;
      this.param.bOceanVesselVoyage = event.voyage;
    } else {
      this.model.voyage = "";
      this.param.bOceanVesselVoyage = "";
    }
  }

  changeEventPOD(event){
    this.param.bPodCode = event['blDischargePortCode'];
  }

  changeEventPOL(event) {
    this.param.bPolCode = event['blLoadPortCode'];
  }

  changeEventPOT1(event){
    this.param.bPot1Code = event['blPortOfTransshipment1Code'];
  }

  changeEventFinalDestination(event){
    this.param.bFinalDestinationCode = event['blFinalDestinationCode'];
  }

  changeBound(event){
    this.param.bOceanVesselBound = event.target.value;
    this.cbVoyage.disableCombo = false;
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    this.cbVoyage.setValue("");
    this.param.bOceanVesselVoyage = '';
  }

  /*
  changeContainerSOC(event){
    if(event.target.checked){
      this.isSOC = true;
    }else{
      this.isSOC = false;
    }
  }

  changeContainerCOC(event){
    if(event.target.checked){
      this.isCOC = true;
    }else{
      this.isCOC = false;
    }
  }

  changeContainerSLOT(event){
    if(event.target.checked){
      this.isSLOT = true;
    }else{
      this.isSLOT = false;
    }
  }

  changeContainerNVOCC(event){
    if(event.target.checked){
      this.isNVOCC = true;
    }else{
      this.isNVOCC = false;
    }
  }
  changeEventCssn(event){
    // console.log(event.title);
  }
*/

  changeCossn(event) {
    // console.log(event.title);
    this.tsCossn = event.title;
  }

  changeEventLocalTranshipment(event) {
    // console.log(event.title);
    this.tsBooking = event.title;
  }

  changeEventFullEmpty(event) {
    // console.log(event);
    if(event.title != 'All'){
      this.param.bContainerStatus = event.title
    }else{
      this.param.bContainerStatus = '';
    }
  }


  changeContainerCom(event){
    if(event.target.checked){
      this.isCom = true;
    }else{
      this.isCom = false;
    }
  }

  changeContainerUncom(event){
    if(event.target.checked){
      this.isUncom = true;
    }else{
      this.isUncom = false;
    }
  }

  changeContainerDel(event){
    if(event.target.checked){
      this.isDel = true;
    }else{
      this.isDel = false;
    }
  }

  changeContainerWcon(event){
    if(event.target.checked){
      this.isWcon = true;
    }else{
      this.isWcon = false;
    }
  }

  changeContainerWcharge(event){
    if(event.target.checked){
      this.isWcharge = true;
    }else{
      this.isWcharge = false;
    }
  }

  getAllotment(event){
    // console.log(event);

    this.modelDetails.allotedBy = event.allotmentCode;

    switch(event.allotmentCode){
      case "SS-" :
        this.formLabel = "Operator";
        break;
      case "PD-" :
        this.formLabel = "Port of Discharge";
        break;
      default :
        this.formLabel = "";
        break;
    }
  }

  getPOD(event){
    // console.log(event);
    this.poDischarge = event.portCode;
  }

  handleRetrieve() {
    // console.log('retrieve event');

    this.isError = false;

    this.grid.curPage = 1;
    this.grid.currentPage = 1;
    this.param.selected = '';
    //this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.param.bContainerOwnership = '';
    this.param.bContainerOwnershipCount + '';

    if (this.tsBooking === 'All' || this.tsBooking === '') {

      if (this.tsCossn == 'All') {
        this.param.bContainerOwnership = 'COC|COCTS|SOC|SOCTS|SLOT|NVOCC';
      }
      else if (this.tsCossn == 'COC') {
        this.param.bContainerOwnership = 'COC|COCTS|';
      }
      else if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnership = 'SOC|SOCTS|';
      }
      else if (this.tsCossn == 'SLOT') {
        this.param.bContainerOwnership = 'SLOT|';
      }
      else if (this.tsCossn == 'NVOCC') {
        this.param.bContainerOwnership = 'NVOCC|';
      }
      else {
        this.param.bContainerOwnership = this.param.bContainerOwnership + 'COC|COCTS|SOC|SOCTS|SLOT|NVOCC';
      }

    } else if (this.tsBooking === 'Local') {

      if (this.tsCossn == 'All') {
        this.param.bContainerOwnership = 'COC|SOC|SLOT|NVOCC';
      }
      else if (this.tsCossn == 'COC') {
        this.param.bContainerOwnership = 'COC|';
      }
      if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnership = 'SOC|';
      }
      if (this.tsCossn == 'SLOT') {
        this.param.bContainerOwnership = 'SLOT|';
      }
      if (this.tsCossn == 'NVOCC') {
        this.param.bContainerOwnership = 'NVOCC|';
      }
      else {
        this.tsCossn = 'COC|SOC|SLOT|NVOCC'
      }

    } else if (this.tsBooking === 'Transhipment') {
      if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnership = 'SOCTS|';
      }
      if (this.tsCossn == 'COC') {
        this.param.bContainerOwnership = 'COCTS|';
      }
    }

    if (this.tsBooking === 'All' || this.tsBooking === '') {
      if (this.tsCossn == 'All') {
        this.param.bContainerOwnershipCount = 'SOC|SOCTS|COC|COCTS|SLOT|NVOCC';
      }
      else if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnershipCount = 'SOC|SOCTS|';
      }
      else if (this.tsCossn == 'COC') {
        this.param.bContainerOwnershipCount = 'COC|COCTS|';
      }
      else if (this.tsCossn == 'SLOT') {
        this.param.bContainerOwnershipCount = 'SLOT|';
      }
      else if (this.tsCossn == 'NVOCC') {
        this.param.bContainerOwnershipCount = 'NVOCC|';
      }
      else {
        this.param.bContainerOwnershipCount = this.param.bContainerOwnershipCount + 'SOC|SOCTS|COC|COCTS|SLOT|NVOCC';
      }
    } else if (this.tsBooking === 'Local') {
      if (this.tsCossn == 'All') {
        this.param.bContainerOwnershipCount = 'SOC|COC|SLOT|NVOCC';
      }
      else if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnershipCount = 'SOC|';
      }
      if (this.tsCossn == 'COC') {
        this.param.bContainerOwnershipCount = 'COC|';
      }
      if (this.tsCossn == 'SLOT') {
        this.param.bContainerOwnershipCount = 'SLOT|';
      }
      if (this.tsCossn == 'NVOCC') {
        this.param.bContainerOwnershipCount = 'NVOCC|';
      }
      else {
        this.tsCossn = 'SOC|COC|SLOT|NVOCC'
      }
    } else if (this.tsBooking === 'Transhipment') {
      if (this.tsCossn == 'SOC') {
        this.param.bContainerOwnershipCount = 'SOCTS|';
      }
      if (this.tsCossn == 'COC') {
        this.param.bContainerOwnershipCount = 'COCTS|';
      }
    }

    this.param.bContainerDataStatus = '';
    if (this.isCom) {
      this.param.bContainerDataStatus = this.param.bContainerDataStatus + 'Y|';
    }
    if (this.isUncom) {
      this.param.bContainerDataStatus = this.param.bContainerDataStatus + 'N|';
    }
    if (this.isDel) {
      this.param.bContainerDataStatus = this.param.bContainerDataStatus + 'DEL|';
    }
    if (this.isWcon) {
      this.param.bContainerDataStatus = this.param.bContainerDataStatus + 'WCO|';
    }
    if (this.isWcharge) {
      this.param.bContainerDataStatus = this.param.bContainerDataStatus + 'WCH|';
    }
    if (this.param.bContainerDataStatus == null || this.param.bContainerDataStatus == "") {
      this.param.bContainerDataStatus = "Y|N|"
    }

    if (this.param.shdraft == null || this.param.shdraft == "") {
      this.param.shdraft = 'Q';
    } else {
      this.param.shdraft = this.param.shdraft;
    }

    if (this.param.bPot1Code == null || this.param.bPot1Code == "") {
      this.param.bPot1Code = 'Q';
    } else {
      this.param.bPot1Code = this.param.bPot1Code;
    }

    if (this.param.bFinalDestinationCode == null || this.param.bFinalDestinationCode == "") {
      this.param.bFinalDestinationCode = 'Q';
    } else {
      this.param.bFinalDestinationCode = this.param.bFinalDestinationCode;
    }

    if (this.param.bPodCode == null || this.param.bPodCode == "") {
      this.param.bPodCode = 'Q';
    } else {
      this.param.bPodCode = this.param.bPodCode;
    }

    if (this.param.bPolCode == null || this.param.bPolCode == "") {
      this.param.bPolCode = 'Q';
    } else {
      this.param.bPolCode = this.param.bPolCode;
    }

    /*
    if(this.param.bOceanVesselId == null || this.param.bOceanVesselId ==""){
      this.param.bOceanVesselId = "Q";
    }else{
      this.param.bOceanVesselId = this.param.bOceanVesselId;
    }
    if(this.param.bOceanVesselVoyage == null || this.param.bOceanVesselVoyage==""){
      this.param.bOceanVesselVoyage = "Q";
    }else{
      this.param.bOceanVesselVoyage = this.param.bOceanVesselVoyage
    }
    if(this.param.bOceanVesselBound == null || this.param.bOceanVesselBound==""){
      this.param.bOceanVesselBound = "Q";
    }else{
      this.param.bOceanVesselBound = this.param.bOceanVesselBound;
    }*/

    this.param.bContainerOwnership = this.param.bContainerOwnership.substring(0, this.param.bContainerOwnership.length - 1);
    //let URL_Retrieve = "";

    this.isError = this.onValidate(this.param);

    if (this.param.shdraft != "Q") {
      this.isError = false;

      //var URL_Retrieve = this.configService.config.BASE_API.toString() + "/BlFlag/GetContohID/Q/Q/O/"
      var URL_Retrieve = "http://glossys.samudera.com:16001/BlFlag/GetContohID/Q/Q/O/"

        + this.param.shdraft + "/"
        + this.param.bPolCode + "/"
        + this.param.bPodCode + "/"
        + this.param.bPot1Code + "/"
        + this.param.bFinalDestinationCode + "/"
        + this.param.bContainerOwnership + "/"
        + this.param.bContainerDataStatus;

      //var hitUrl = this.configService.config.BASE_API.toString() + "/BlFlag/getCOCSOCSLOT/Q/Q/O/"
      var hitUrl = "http://glossys.samudera.com:16001/BlFlag/GetContohID/Q/Q/O/"
        + this.param.shdraft + "/"
        + this.param.bPolCode + "/"
        + this.param.bPodCode + "/"
        + this.param.bPot1Code + "/"
        + this.param.bFinalDestinationCode + "/"
        + this.param.bContainerOwnershipCount + "/"
        + this.param.bContainerDataStatus + "/1/10/ASC/blNo";
      // console.log(hitUrl);
                 //abc

      // console.log(this.genericService.GET(hitUrl))
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.ok) {
          this.param.TbCoc = resp.json()['totalCOC'];
          this.param.TbSoc = resp.json()['totalSOC']; // = result['getSOC'];
          this.param.TbSlot = resp.json()['totalSLOT']; // = result['getSLOT'];
          this.param.TbTotal = this.param.TbCoc + this.param.TbSoc + this.param.TbSlot;
        }
      })

      // akhir disini
      this.isLoad = true;
      this.grid.url = URL_Retrieve;
      this.grid.loadData();
    }
    else {

      if (!this.isError) {
        // console.log(this.param);
        // console.log("KONDISI Benar")

        //var URL_Retrieve = this.configService.config.BASE_API.toString() + "/BlFlag/GetContohID/"
        var URL_Retrieve = "http://glossys.samudera.com:16001/BlFlag/GetContohID/"
          + this.param.bOceanVesselId + "/"
          + this.param.bOceanVesselVoyage + "/"
          + this.param.bOceanVesselBound + "/"
          + this.param.shdraft + "/"
          + this.param.bPolCode + "/"
          + this.param.bPodCode + "/"
          + this.param.bPot1Code + "/"
          + this.param.bFinalDestinationCode + "/"
          + this.param.bContainerOwnership + "/"
          + this.param.bContainerDataStatus;

        //count COC,SOC,SLOT disini
        //var hitUrl = this.configService.config.BASE_API.toString() + "/BlFlag/getCOCSOCSLOT/"
        var URL_Retrieve = "http://glossys.samudera.com:16001/BlFlag/getCOCSOCSLOT/"
          + this.param.bOceanVesselId + "/"
          + this.param.bOceanVesselVoyage + "/"
          + this.param.bOceanVesselBound + "/"
          + this.param.shdraft + "/"
          + this.param.bPolCode + "/"
          + this.param.bPodCode + "/"
          + this.param.bPot1Code + "/"
          + this.param.bFinalDestinationCode + "/"
          + this.param.bContainerOwnershipCount + "/"
          + this.param.bContainerDataStatus + "/1/10/ASC/blNo";
        // console.log(hitUrl);

        // console.log(this.genericService.GET(hitUrl))
        this.genericService.GET(hitUrl).subscribe((resp) => {
          if (resp.ok) {
            this.param.TbCoc = resp.json()['totalCOC'];
            this.param.TbSoc = resp.json()['totalSOC']; // = result['getSOC'];
            this.param.TbSlot = resp.json()['totalSLOT']; // = result['getSLOT'];
            this.param.TbTotal = this.param.TbCoc + this.param.TbSoc + this.param.TbSlot;
          }
        })

        // akhir disini
        this.isLoad = true;
        this.grid.url = URL_Retrieve;
        this.grid.loadData();
      }

    }
  }

  handleCancel(){
    this.isError=false;
    this.param = new Param;
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbPOL.setValue('');
    this.cbPOD.setValue('');
    this.cbPOT1.setValue('');
    this.cbFinalDestination.setValue('');
    this.cbFullEmpty.setValue('All');
    this.cbLocalTranshipment.setValue('All');
    this.cbCossn.setValue('All');
    this.isCom = false;
    this.isUncom = false;
    this.isWcon = false;
    this.isDel = false;
    this.isWcharge = false;
    this.tsBooking = '';
    this.isLoad = false;
    this.param.bOceanVesselBound = 'O';
    this.grid.onClear();

  }

  resetGrid(){
    this.grid.onClear();
    this.gridDetails.onClear();
    this.hgridDetailsStore.splice(0,this.hgridDetailsStore.length);
  }

  resetButton(){
    this.cButtonLock = true;
    this.dButtonLock = true;
  }

  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  showDialog(id){
    $(id)
      .modal('setting', 'closable', false)
      .modal('show');
  }

  closeDialog(id){
    $(id).modal('hide');
  }

  resetAllocated(){
    $('#iAlloted').val('');
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }
}
