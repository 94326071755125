<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      
      
       <div class="field" style="width:400px">
        <label>B/L No:</label>
        <input type="text" name="blNo"  placeholder="Please Enter B/L No" [(ngModel)]="model['blNo']" >
      </div>

    </form>
  </div>  <!-- end div container -->
</form>

<pdf-plugin #cllPdfContainer style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin> 
