<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>
    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        ><div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Reason
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>
          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Reason Name
            </h4>
            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="field">
                  <div class="fields">
                   <!-- <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-reasonName'] != null &&
                        model['error-reasonName'] != ''
                      "
                    >
                      <label>Reason</label>
                      <textarea-plugin
                        #txtReasonName
                        [settings]="settingReasonName"
                        (input)="onInput($event)"
                        (keypress)="formValidation.ModifiedPattern($event, ' ')"
                        (paste)="onPasteReasonName($event)"
                      ></textarea-plugin>
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-reasonName'] != null &&
                          model['error-reasonName'] != ''
                        "
                      >
                        {{ model["error-reasonName"] }}
                      </div>
                    </div> -->
                    <!--coba coba coba coba -->
                    <div
                      class="eight wide field required"
                      [class.error]="
                        model['error-reasonName'] != null &&
                        model['error-reasonName'] != ''
                      "
                    >
                      <label>Reason</label>
                      <textarea-v2-plugin
                        #txtReasonName
                        [settings]="settingReasonsName"
                        (input)="onInput($event)"
                        (keypress)="formValidation.ModifiedPattern($event, ' ')"
                        (paste)="onPasteReasonName($event)"   
                        [disabled]="reasonLock == true"                     
                      ></textarea-v2-plugin>
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-reasonName'] != null &&
                          model['error-reasonName'] != ''
                        "
                      >
                        {{ model["error-reasonName"] }}
                      </div>
                    </div>
                    <!--- end coba coba coba coba -->

                  </div>
                </div>
                <div
                  class="field"
                  [class.error]="
                    model['error-remarks'] != null &&
                    model['error-remarks'] != ''
                  "
                >
                  <label>Remarks</label>
                  <textarea
                    [(ngModel)]="model['remarks']"
                    name="remarks"
                    placeholder="Input your remarks..."
                    [disabled]="lock == true"
                    maxlength="200"
                    (keypress)="formValidation.AllChar($event)"
                  ></textarea>
                  <div
                    class="
                      ui
                      basic
                      red
                      pointing
                      prompt
                      label
                      transition
                      error-message-hidden
                    "
                    [class.error-message-visible]="
                      model['error-remarks'] != null &&
                      model['error-remarks'] != ''
                    "
                  >
                    {{ model["error-remarks"] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Reason
            </h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Reason</a>

              <div class="one fields" style="margin-top: 10px">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">
                    Retrieve
                  </button>
                  <button
                    class="ui button"
                    (click)="generateReasonHistoryReport($event)"
                  >
                    Export
                  </button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>Reason Name</label>
                  <combo-plugin
                      #cbReasonNameHistory
                      [settings]="settingReasonHistory"
                      (change)="valueReasonHistory($event)"
                    ></combo-plugin>
                </div>
                <!--
                <div class="field">
                    <label>Customer Code</label>
                    <input type="text"  [value]="customerCodeHistory" name="customerCodeHistory"  placeholder="Customer History Code" disabled readonly style="background-color: lightgray">
                </div>
                -->
              </div>

              <div class="field">
                <grid-plugin
                  #gridHistory
                  [settings]="settingGridHistory"
                  (gridEvent)="gridEventHistory($event)"
                  (infoGrid)="infoGridHistory($event)"
                ></grid-plugin>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
