<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <form class="ui form">
        <div class="field">
          <h4
            class="ui dividing header"
            style="text-align: center; margin-top: 10px"
          >
          Customer Master
        </h4>
        </div>
        <!--
        <div class="twelve wide fields">
          <div class="one field" style="width:10% !important;">
              <label style="line-height: 2.5;">Customer Name</label>
          </div>
          <div class="one field" style="width:20% !important;">
            <combo-plugin #cbCustName [settings]="settingCustName" (change)="changeEventCustName($event)" class="field" style="width:100%;"></combo-plugin>
          </div>
          <div class="one field" style="width:10% !important;">
              <label style="line-height: 2.5;">Display Name</label>
          </div>
          <div class="one field" style="width:20% !important;" [class.error]="model['error-bound'] != null && model['error-bound'] != ''">
            <combo-plugin #cbDsplyNm [settings]="settingDsplyNm" (change)="changeEventDsplyNm($event)" class="field" style="width:100%;"></combo-plugin>
          </div>
          <div class="one field" style="width:7% !important;">
            <label style="line-height: 2.5;">Country</label>
          </div>
          <div class="one field" style="width:20% !important;">
            <combo-plugin #cbCntry [settings]="settingCntry" (change)="changeEventCntry($event)" class="field" style="width:100%;"></combo-plugin>
          </div>
        </div>
        -->
        <div class="twelve wide fields">
          <div class="one field item" style="height:25px;">
            <div class="ui basic floating scrolling dropdown button" style="padding:0.4em 0.4em !important;" tabindex="0">
              <div class="text">ALL</div> <i class="dropdown icon"></i> 
              <div class="menu"> 
                <div class="item active selected" (click)="onSelectValid('*')" > All </div> 
                <div class="item" *ngFor="let src of searchValid" (click)="onSelectValid(src.value)"> 
                  {{ src.display }} 
                </div> 
              </div>
            </div>
          </div>
          <!---
          <div class="one field">
            <label style="line-height: 2.2;">Customer Name</label>
          </div>
          -->
          <!---
          <div class="boxpredictive one field" style="width:20% !important;">
            <div class="auto-complete ui search icon input"
            [class.disabled]="loadingCustName == true"
            [class.loading]="loadingCustName == true"
            >
	            <i class="search icon"></i>
              <input #inputSearch type="text" placeholder="Search Customer Name" class="search"
                (input)="onSearchRowPredictive($event)"
              > <!--
                (click)="onShowList(true)"
              
              <div *ngIf="showList" class="drop-down" #listSelect>
                <table *ngIf="!noRecord else showNoRecord" class="ui selectable table">
                  <thead>
                    <tr>
                      <th style="width: 300px;">Customer Name</th>
                      <th style="width: 250px;">Valid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="item" *ngFor="let dt of filterRows" (click)="onChooseItem(dt)">
                      <td>{{dt.customerName}}</td>
                      <td>{{dt.isValid}}</td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #showNoRecord>
                  <div class="message empty">
                    <div class="header">No Results</div>
                    <div class="description">Your search returned no results</div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          -->
          <div class="one field">
            <autoselect-plugin #auCustName [settings]="settingAuCustName" (inputTyped)="inputTypedCustName($event)" (chooseItem)="selectItemCustName($event)"></autoselect-plugin>
          </div>
          <!--
          <div class="one field">
            <label style="line-height: 2.2;">Display Name</label>
          </div>
          -->
          <div class="one field">
            <autoselect-plugin #auDisName [settings]="settingAuDisName" (inputTyped)="inputTypedDisName($event)" (chooseItem)="selectItemDisName($event)"></autoselect-plugin>
          </div>
          <!---
          <div class="boxpredictive one field" style="width:20% !important;">
            <div class="auto-complete ui search icon input"
            [class.disabled]="loadingDN == true"
            [class.loading]="loadingDN == true"
            >
	            <i class="search icon"></i>
              <input #inputSearchDN type="text" placeholder="Search Display Name" class="search"
                (input)="onSearchRowPredictiveDN($event)"
              > <!--
                (click)="onShowList(true)"
              
              <div *ngIf="showListDN" class="drop-down" #listSelectDN>
                <table *ngIf="!noRecordDN else showNoRecordDN" class="ui selectable table">
                  <thead>
                    <tr>
                      <th style="width: 300px;">Display Name</th>
                      <th style="width: 80px;">Seq No</th>
                      <th style="width: 170px;">Customer ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="item" *ngFor="let dt of filterRowsDN" (click)="onChooseItemDN(dt)">
                      <td>{{dt.customerDisplayName}}</td>
                      <td>{{dt.seqNo}}</td>
                      <td>{{dt.customerID}}</td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #showNoRecordDN>
                  <div class="message empty">
                    <div class="header">No Results</div>
                    <div class="description">Your search returned no results</div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        -->
          <!--
          <div class="one field">
            <label style="line-height: 2.2;">Country</label>
          </div>
          -->

          <div class="one field">
            <autoselect-plugin #auCountry [settings]="settingAuCountry" (inputTyped)="inputTypedCountry($event)" (chooseItem)="selectItemCountry($event)"></autoselect-plugin>
          </div>
          <!--
          <div class="boxpredictive one field" style="width:20% !important;">
            <div class="auto-complete ui search icon input"
            [class.disabled]="loadingCN == true"
            [class.loading]="loadingCN == true"
            >
	            <i class="search icon"></i>
              <input #inputSearchCN type="text" placeholder="Search Country" class="search"
                (input)="onSearchRowPredictiveCN($event)"
              > <!--
                (click)="onShowList(true)"
              <div *ngIf="showListCN" class="drop-down" #listSelectCN>
                <table *ngIf="!noRecordCN else showNoRecordCN" class="ui selectable table">
                  <thead>
                    <tr>
                      <th style="width: 100px;">Country Code</th>
                      <th style="width: 300px;">Country Name</th>
                      <th style="width: 150px;">Valid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="item" *ngFor="let dt of filterRowsCN" (click)="onChooseItemCN(dt)">
                      <td>{{dt.countryCode}}</td>
                      <td>{{dt.countryName}}</td>
                      <td>{{dt.isValid}}</td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #showNoRecordCN>
                  <div class="message empty">
                    <div class="header">No Results</div>
                    <div class="description">Your search returned no results</div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        -->
        </div>
        <div class="ui styled accordion field" style="width: 100%;min-height:25px;">
          <div class="title" style="padding: 0.3em 1em !important;">
            <i class="icon dropdown"></i>
            Advanced Search
          </div>
          <div class="content">
            <div class="twelve wide fields">
              <div class="one field" style="width: 13% !important;">
                <label style="line-height: 2.7;font-size:0.8rem;">Container Ownership</label>
              </div>
              <div class="one field" style="width: 7% !important;font-size:0.8rem;">
                <combo-plugin
                  #cbContOpr
                  [settings]="settingContOpr"
                  (change)="changeEventContOpr($event)"
                ></combo-plugin>
              </div>
            </div>
            <div class="twelve wide fields">
              <!--
              <table>
                <tbody>
                  <tr>
                    <ng-container *ngFor="let ca of checkboxAdvanceSearch let i = index">
                      <td>{{ca.label}}</td>
                      <td><input type="checkbox" [name]="ca.name" [(ngModel)]="ca.value" (click)="onCheckboxClick(ca)"></td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
              -->
              
              <ng-container *ngFor="let ca of checkboxAdvanceSearch let i = index">
                <div class="one field" [style]="sanitize(ca.style)">
                  <label style="font-size:0.8rem;">{{ca.label}}</label>
                </div>
                <div class="one field" style="width: 7% !important;font-size:0.8rem;">
                  <input type="checkbox" [name]="ca.name" [(ngModel)]="ca.isChecked" (change)="onCheckboxClick(checkboxAdvanceSearch[i])">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="field" style="margin-bottom: 0 !important;">
          <div class="ui top attached tabular menu" style="background-color: beige;width:37%;margin-left: 43.5%;">
            <a
              class="item"
              [class.active]="tabStatus == 'tab1'"
              data-tab="finance"
              (click)="initTab1()"
              >FINANCE &nbsp;
              <span
                [style.display]="tabStatus == 'tab1' ? 'block' : 'none'"
                style="color: red; text-transform: uppercase"
                >{{ appStatusLabel }}</span
              ></a
            >
            <a
              class="item"
              [class.active]="tabStatus == 'tab2'"
              data-tab="general"
              (click)="initTab2()"
              >GENERAL &nbsp;
              <span
                [style.display]="tabStatus == 'tab2' ? 'block' : 'none'"
                style="color: red; text-transform: uppercase"
                >{{ appStatusLabel }}</span
              ></a
            >
            <a
              class="item"
              [class.active]="tabStatus == 'tab3'"
              data-tab="party"
              (click)="initTab3()"
              >PARTY &nbsp;
              <span
                [style.display]="tabStatus == 'tab3' ? 'block' : 'none'"
                style="color: red; text-transform: uppercase"
                >{{ appStatusLabel }}</span
              ></a
            >
            <a
              class="item"
              [class.active]="tabStatus == 'tab4'"
              data-tab="auditLog"
              (click)="initTab4()"
              >AUDIT LOG &nbsp;
              <span
                [style.display]="tabStatus == 'tab4' ? 'block' : 'none'"
                style="color: red; text-transform: uppercase"
                >{{ appStatusLabel }}</span
              ></a
            >
          </div>
        </div>
        <div class="field">
          <ag-grid-angular
                #mainGrid id="mainGrid"
                style="width: 100%; height: 400px;"
                class="ag-theme-balham"
                [getRowStyle]="getRowStyle"
                [rowData]="rowData"
                [columnDefs]="columnFinanceDefs"
                [enableSorting]="true"
                [enableFilter]="true"
                [enableColResize]="true"
                [rowSelection]="rowSelectionP"
                [rowMultiSelectWithClick]="rowMultiSelectWithClickP"
                [angularCompileHeaders]="true"
                [suppressRowClickSelection]="true"
                [suppressScrollOnNewData]="true"
                (gridReady)="onGridReady($event)"
                (firstDataRendered)="onFirstDataRendered($event)"
                (rowDoubleClicked)="rowDoubleClick($event)"
                [pinnedBottomRowData]="pinnedBottomRowData"
                (selectionChanged)="onSelectionChanged($event)"
                (bodyScroll)="handleScroll($event)"
                (rowDataChanged)="handleRowDataChanged($event)"  
                (sortChanged)="sortChanged($event)"
              >
              </ag-grid-angular>
              <!--(columnPinned)="onColumnPinned($event)"
                (filterChanged)="filterChanged($event)"-->
        </div>   
        <!-- Begin of Grid -->
        
        <!--
        <grid-plugin
              #grid
              [settings]="settingGrid"
              (gridEvent)="gridEvent($event)"
              (infoGrid)="infoGrid($event)"
            ></grid-plugin>
        -->
        <!-- End of Grid -->  
      </form>
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4 class="ui dividing header">Master Customer</h4>

            <div class="three fields">
              <div class="field">
                <label><br /></label>
                <div
                  class="ui test toggle checkbox"
                  [class.disabled]="lock == true"
                >
                  <input
                    type="checkbox"
                    [(ngModel)]="model['isValid']"
                    name="isValid"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>Valid</label>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >General</a
                  >

                  <div class="ten wide fields">
                    <div class="one field" style="width: 100%">
                      <div class="ten wide fields">
                        <div
                          class="required field"
                          style="width: 100%"
                          [class.error]="
                            model['error-customerName'] != null &&
                            model['error-customerName'] != ''
                          "
                        >
                          <label>Name</label>
                          <input
                            type="text"
                            [(ngModel)]="model['customerName']"
                            (ngModelChange)="model['error-customerName'] = ''"
                            name="customerName"
                            placeholder="Customer Name"
                            maxlength="50"
                            [disabled]="lock == true"
                            (keypress)="
                              formValidation.CharNumSpecialCharOnly($event)
                            "
                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-customerName'] != null &&
                              model['error-customerName'] != ''
                            "
                          >
                            {{ model["error-customerName"] }}
                          </div>
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div
                          class="required five field"
                          style="width: 100%"
                          [class.error]="
                            model['error-countryName'] != null &&
                            model['error-countryName'] != ''
                          "
                        >
                          <label>Country</label>
                          <combo-plugin
                            #cbCountry
                            [settings]="settingCountry"
                            [disableCombo]="lock"
                            (change)="changeEventCountry($event)"
                            style="width: 100%"
                          ></combo-plugin>
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-countryName'] != null &&
                              model['error-countryName'] != ''
                            "
                          >
                            {{ model["error-countryName"] }}
                          </div>
                        </div>
                        <div
                          class="five field"
                          style="width: 100%"
                          [class.error]="
                            model['error-companyRegNo'] != null &&
                            model['error-companyRegNo'] != ''
                          "
                        >
                          <label>Company Registration Number</label>
                          <input
                            type="text"
                            [(ngModel)]="model['companyRegNo']"
                            name="companyRegNo"
                            placeholder="Company Registration Number"
                            [disabled]="lock == true"
                            maxlength="50"
                            (keypress)="formValidation.ModifiedPattern($event)"
                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-companyRegNo'] != null &&
                              model['error-companyRegNo'] != ''
                            "
                          >
                            {{ model["error-companyRegNo"] }}
                          </div>
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div class="field" style="width: 100%">
                          <label>Remarks</label>
                          <textarea
                            style="margin-top: 5px"
                            [(ngModel)]="model['remarks']"
                            name="remarks"
                            placeholder="Input your remarks..."
                            maxlength="200"
                            [disabled]="lock == true"
                            (keypress)="formValidation.AllChar($event)"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="one field" style="width: 100%">
                      <div class="ui raised segment" style="margin-left: 5px">
                        <a
                          class="ui red ribbon label"
                          style="margin-bottom: 5px"
                          >Customer Type</a
                        >

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isShipper']"
                                name="isShipper"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Shipper</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isAgency']"
                                name="isAgency"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Agency</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isConsignee']"
                                name="isConsigneee"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Consignee</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isBookingParty']"
                                name="isBookingParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Booking Party</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isNotifyParty']"
                                name="isNotifyParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Notify Party</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isHaulier']"
                                name="isHaulier"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Haulier</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isPayer']"
                                name="isPayer"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Payer</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isNvocc']"
                                name="isNvocc"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>NVOCC</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isFreightParty']"
                                name="isFreightParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Freight Party</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isSupplier']"
                                name="isSupplier"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Supplier</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >Container Ownership</a
                  >

                  <div class="three fields">
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isCoc']"
                          (ngModelChange)="
                            changeEventOwnership('COC-' + $event)
                          "
                          name="isCoc"
                          tabindex="0"
                          class="hidden"
                        />
                        <label>COC</label>
                      </div>
                    </div>
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isSoc']"
                          name="isSoc"
                          (ngModelChange)="
                            changeEventOwnership('SOC-' + $event)
                          "
                          tabindex="0"
                          class="hidden"
                        />
                        <label>SOC</label>
                      </div>
                    </div>
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isSlot']"
                          name="isSlot"
                          (ngModelChange)="
                            changeEventOwnership('SLOT-' + $event)
                          "
                          tabindex="0"
                          class="hidden"
                        />
                        <label>SLOT</label>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-cocOperatorCode'] != null &&
                        model['error-cocOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['cocOperatorCode']"
                        name="cocOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isCOC == false"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-cocOperatorCode'] != null &&
                          model['error-cocOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-cocOperatorCode']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-socOperatorCode'] != null &&
                        model['error-socOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['socOperatorCode']"
                        name="socOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isSOC == false"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-socOperatorCode'] != null &&
                          model['error-socOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-socOperatorCode']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-slotOperatorCode'] != null &&
                        model['error-slotOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['slotOperatorCode']"
                        name="slotOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isSLOT == false"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-slotOperatorCode'] != null &&
                          model['error-slotOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-slotOperatorCode']"
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['cocAccountCode']"
                        name="cocAccountCode"
                        placeholder="Account Code"
                        [disabled]="isCOC == false"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['socAccountCode']"
                        name="socAccountCode"
                        placeholder="Account Code"
                        [disabled]="isSOC == false"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['slotAccountCode']"
                        name="slotAccountCode"
                        placeholder="Account Code"
                        [disabled]="isSLOT == false"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                  </div>

                  <div class="three fields">
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-cocCreditDays'] != null &&
                        model['error-cocCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['cocCreditDays']"
                        name="cocCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isCOC == false"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-cocCreditDays'] != null &&
                          model['error-cocCreditDays'] != ''
                        "
                      >
                        {{ model["error-cocCreditDays"] }}
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-socCreditDays'] != null &&
                        model['error-socCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['socCreditDays']"
                        name="socCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isSOC == false"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-socCreditDays'] != null &&
                          model['error-socCreditDays'] != ''
                        "
                      >
                        {{ model["error-socCreditDays"] }}
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-slotCreditDays'] != null &&
                        model['error-slotCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['slotCreditDays']"
                        name="slotCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isSLOT == false"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-slotCreditDays'] != null &&
                          model['error-slotCreditDays'] != ''
                        "
                      >
                        {{ model["error-slotCreditDays"] }}
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class ="field">
                      <div class="two fields">
                        <div
                          class="field"
                          style="width: 50%"
                          [class.error]="
                            model['error-cocCreditLimit'] != null &&
                            model['error-cocCreditLimit'] != ''
                          "
                        >
                          <label>Credit Limit</label>
                          <input
                            type="number"
                            [(ngModel)]="model['cocCreditLimit']"
                            name="cocCreditLimit"
                            maxlength="5"
                            placeholder="Credit Limit"
                            [disabled]="isCOC == false"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keypress)="noneNum($event)"
                            
                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-cocCreditLimit'] != null &&
                              model['error-cocCreditLimit'] != ''
                            "
                          >
                            {{ model["error-cocCreditLimit"] }}
                          </div>
                        </div>
                        <div
                        class="field"
                        style="width: 50%">
                        <label>Tolerance (%)</label>
                          <input
                            type="number"
                            [(ngModel)]="model['cocTolerance']"
                            name="cocTolerance"
                            placeholder="Tolerance (%)"
                            [disabled]="isCOC == false"
                            maxlength="3"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keypress)="noneNum($event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class ="field">
                        <div class="two fields">
                          <div
                            class="field"
                            style="width: 50%"
                            [class.error]="
                              model['error-socCreditLimit'] != null &&
                              model['error-socCreditLimit'] != ''
                            "
                          >
                            <label>Credit Limit</label>
                            <input
                              type="number"
                              [(ngModel)]="model['socCreditLimit']"
                              name="socCreditLimit"
                              maxlength="5"
                              placeholder="Credit Limit"
                              [disabled]="isSOC == false"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              (keypress)="noneNum($event)"
                             
                            />
                            <div
                              class="ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="
                                model['error-socCreditLimit'] != null &&
                                model['error-socCreditLimit'] != ''
                              "
                            >
                              {{ model["error-socCreditLimit"] }}
                            </div>
                          </div>
                          <div
                          class="field"
                          style="width: 50%">
                          <label>Tolerance (%)</label>
                            <input
                              type="number"
                              [(ngModel)]="model['socTolerance']"
                              name="socTolerance"
                              placeholder="Tolerance (%)"
                              [disabled]="isSOC == false"
                              maxlength="3"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              (keypress)="noneNum($event)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class ="field">
                          <div class="two fields">
                            <div
                              class="field"
                              style="width: 50%"
                              [class.error]="
                                model['error-slotCreditLimit'] != null &&
                                model['error-slotCreditLimit'] != ''
                              "
                            >
                              <label>Credit Limit</label>
                              <input
                                type="number"
                                [(ngModel)]="model['slotCreditLimit']"
                                name="slotCreditLimit"
                                maxlength="5"
                                placeholder="Credit Limit"
                                [disabled]="isSLOT == false"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                (keypress)="noneNum($event)"
                               
                              />
                              <div
                                class="ui basic red pointing prompt label transition error-message-hidden"
                                [class.error-message-visible]="
                                  model['error-slotCreditLimit'] != null &&
                                  model['error-slotCreditLimit'] != ''
                                "
                              >
                                {{ model["error-slotCreditLimit"] }}
                              </div>
                            </div>
                            <div
                            class="field"
                            style="width: 50%">
                            <label>Tolerance (%)</label>
                              <input
                                type="number"
                                [(ngModel)]="model['slotTolerance']"
                                name="slotTolerance"
                                placeholder="Tolerance (%)"
                                [disabled]="isSLOT == false"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                (keypress)="noneNum($event)"
                              />
                            </div>
                          </div>
                        </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >Details</a
                  >

                  <div id="displayGrid">
                    <div class="one fields">
                      <div class="field" style="width: 100%">
                        <div class="ui icon tiny buttons">
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true"
                            data-content="Add"
                            (click)="addDetail()"
                          >
                            <i class="plus icon"></i>
                          </button>
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true"
                            data-content="Remove"
                            (click)="removeDetail()"
                          >
                            <i class="minus icon"></i>
                          </button>
                        </div>
                        <grid-plugin
                          id="detail-customer"
                          #gridDetails
                          [settings]="settingGridDetails"
                          (gridEvent)="gridEventDetails($event)"
                          (infoGrid)="infoGridDetails($event)"
                        ></grid-plugin>
                      </div>
                    </div>
                  </div>

                  <div id="display">
                    <div class="two fields">
                      <div
                        class="required field"
                        [class.error]="
                          modelDetail['error-customerDisplayName'] != null &&
                          modelDetail['error-customerDisplayName'] != ''
                        "
                      >
                        <label>Display Name</label>
                        <input
                          type="text"
                          [(ngModel)]="modelDetail['customerDisplayName']"
                          (ngModelChange)="
                            modelDetail['error-customerDisplayName'] = ''
                          "
                          name="customerDisplayName"
                          placeholder="Display Name"
                          [disabled]="lock == true"
                          maxlength="50"
                          (keypress)="
                            formValidation.CharNumSpecialCharOnly($event)
                          "
                        />
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-customerDisplayName'] != null &&
                            modelDetail['error-customerDisplayName'] != ''
                          "
                        >
                          {{ modelDetail["error-customerDisplayName"] }}
                        </div>
                      </div>
                      <div
                        class="field"
                        style="width: 100%"
                        [class.error]="
                          modelDetail['error-contactPerson'] != null &&
                          modelDetail['error-contactPerson'] != ''
                        "
                      >
                        <label>Contact Person</label>
                        <input
                          type="text"
                          [(ngModel)]="modelDetail['contactPerson']"
                          name="contactPerson"
                          placeholder="Contact Person"
                          [disabled]="lock == true"
                          maxlength="50"
                          (keypress)="
                            formValidation.ModifiedPattern($event, '-&(.)\' ')
                          "
                        />
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-contactPerson'] != null &&
                            modelDetail['error-contactPerson'] != ''
                          "
                        >
                          {{ modelDetail["error-contactPerson"] }}
                        </div>
                      </div>
                    </div>

                    <div class="two fields">
                      <div
                        class="required field"
                        [class.error]="
                          modelDetail['error-fullAddress'] != null &&
                          modelDetail['error-fullAddress'] != ''
                        "
                        style="width: 100%"
                      >
                        <label>Display Address</label>
                        <textarea
                          style="margin-top: 5px"
                          maxlength="255"
                          [(ngModel)]="modelDetail['fullAddress']"
                          (ngModelChange)="
                            modelDetail['error-fullAddress'] = ''
                          "
                          name="fullAddress"
                          placeholder="Input your display address.."
                          [disabled]="lock == true"
                        ></textarea>
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-fullAddress'] != null &&
                            modelDetail['error-fullAddress'] != ''
                          "
                        >
                          {{ modelDetail["error-fullAddress"] }}
                        </div>
                      </div>
                      <div class="field" style="width: 100%">
                        <div class="one fields">
                          <div class="field" style="width: 100%">
                            <label>Tel. No(s).</label>
                            <input
                              type="text"
                              [(ngModel)]="modelDetail['telno']"
                              name="tel"
                              placeholder="Tel. No(s)."
                              [disabled]="lock == true"
                              maxlength="50"
                              (keypress)="formValidation.PhoneNum($event)"
                            />
                          </div>
                        </div>
                        <div class="one fields">
                          <div
                            class="field"
                            style="width: 100%"
                            [class.error]="
                              modelDetail['error-email'] != null &&
                              modelDetail['error-email'] != ''
                            "
                          >
                            <label>Email</label>
                            <input
                              style="text-transform: uppercase"
                              type="email"
                              [(ngModel)]="modelDetail['email']"
                              (ngModelChange)="modelDetail['error-email'] = ''"
                              name="email"
                              placeholder="Email"
                              [disabled]="lock == true"
                              maxlength="50"
                              (keypress)="
                                formValidation.ModifiedPattern($event, '-@_.,')
                              "
                            />
                            <div
                              class="ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="
                                modelDetail['error-email'] != null &&
                                modelDetail['error-email'] != ''
                              "
                            >
                              {{ modelDetail["error-email"] }}
                            </div>
                          </div>
                        </div>
                        <div class="one fields">
                          <div class="field" style="width: 100%">
                            <label>Fax. No(s).</label>
                            <input
                              type="text"
                              [(ngModel)]="modelDetail['fax']"
                              name="fax"
                              placeholder="Fax. No(s)."
                              [disabled]="lock == true"
                              maxlength="50"
                              (keypress)="formValidation.PhoneNum($event)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field" style="width: 100%"></div>
                      <div class="two fields" style="width: 100%">
                        <div class="field">
                          <div class="ui buttons">
                            <button class="ui button" (click)="cancelDetail()">
                              Cancel
                            </button>
                            <div class="or"></div>
                            <button
                              class="ui positive button"
                              (click)="saveDetail()"
                              [disabled]="viewOnlyStatus == true"
                            >
                              Save
                            </button>
                          </div>
                        </div>  
                        <div class="field">
                          <div class="field">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="modelDetail['isValidD']"
                                name="isValidD"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Valid</label>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Customer
            </h4>
            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Customer</a>

              <div class="one fields" style="margin-top: 10px">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">
                    Retrieve
                  </button>
                  <button
                    class="ui button"
                    (click)="generateCustomerHistoryReport($event)"
                  >
                    Export
                  </button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>Customer Name</label>
                  <combo-plugin
                    #cbCustomerHistroy
                    [settings]="settingCustomerHistory"
                    (change)="changeEventCustomerHistory($event)"
                  ></combo-plugin>
                </div>
                <!--
                <div class="field">
                    <label>Customer Code</label>
                    <input type="text"  [value]="customerCodeHistory" name="customerCodeHistory"  placeholder="Customer History Code" disabled readonly style="background-color: lightgray">
                </div>
                -->
              </div>

              <div class="field">
                <grid-plugin
                  #gridHistory
                  [settings]="settingGridHistory"
                  (gridEvent)="gridEventHistory($event)"
                  (infoGrid)="infoGridHistory($event)"
                ></grid-plugin>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>