import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { Vessel } from "../shared/index";
import {
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  ConfigService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";
declare var $: any;

@Component({
  selector: "app-master-vessel-form-page",
  templateUrl: "./master-vessel-form-page.component.html",
  styleUrls: ["./master-vessel-form-page.component.css"],
})
export class MasterVesselFormPageComponent
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("cbFormType") cbFormType: ComboPluginComponent;
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVesselHistory") cbVesselHistory: ComboPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbIMO") cbIMO: ComboPluginComponent;

  form = "";
  // dialog:any;
  /* Parameter settings */

  settingGridHistory;
  settingFormType;
  settingVessel;
  settingVesselHistory;
  settingCalendarDateLaunch;
  settingCalendarDateDelivery;
  settingCalendarDateKeel;
  settingIMO;

  typeCalendarDateLaunch = "calendar";
  typeCalendarDateDelivery = "calendar";
  typeCalendarDateKeel = "calendar";

  /* Parameter model */
  model = new Vessel();

  formValidation = new FormValidation();

  loading = false;
  isUpdate = false;
  lock = false;
  isDefault = true;
  isType = "";
  lockRadioButton: boolean = false;
  modeType = false;

  vesselIdHistory: string = "";
  vesselCodeHistory: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  /* validatorRules */
  validatorRules = {};
  isLockIMO = false;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private configService: ConfigService
  ) {
    super();

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Abbr. Code", field: "vesselCode", width: 130 },
        { header: "Vessel Code (A/C)", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Remarks", field: "remarks", width: 200 },
        { header: "User Created", field: "userCreated", width: 110 },
        { header: "Date Created", field: "dateCreated", width: 110 },
        { header: "User Modified", field: "userModified", width: 110 },
        { header: "Date Modified", field: "dateModified", width: 110 },
        { header: "User Invalid", field: "userInvalid", width: 110 },
        { header: "Date Invalid", field: "dateInvalid", width: 110 },
        { header: "Valid", field: "isValid", width: 80 },
        { header: "Action Log", field: "action", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
    };

    this.settingFormType = {
      id: "cbFormType",
      type: "select", // search | select | select input
      //url: "assets/json/formTypeVessel.json",
      url: '[{ "formTypeCode" : "DEFAULT", "formTypeName" : "Default" },{ "formTypeCode" : "MOTHERVESSEL", "formTypeName" : "Mother Vessel" }]',
      urlType: "inject",
      placeholder: "Form Type",
      code: "formTypeCode",
      title: "formTypeName",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Abbr. Code", field: "vesselCode", width: 130 },
        { header: "Vessel Code (A/C)", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingVesselHistory = {
      id: "cbVesselHistory",
      type: "search", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Abbr. Code", field: "vesselCode", width: 130 },
        { header: "Vessel Code (A/C)", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingIMO = {
      id: "cbIMO",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/getMarineTraffic/{query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search IMO",
      title: "imo",
      description: "",
      isMark: true,
      columns: [
        { header: "Ship Name ", field: "shipname", width: 150 },
        { header: "Mmsi", field: "mmsi", width: 100 },
        { header: "IMO", field: "imo", width: 100 },
        { header: "Call Sign ", field: "callsign", width: 150 },
        { header: "Country", field: "country", width: 150 },
        { header: "Year Built", field: "yearBuilt ", width: 150 },
      ],
    };

    this.settingCalendarDateLaunch = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingCalendarDateDelivery = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingCalendarDateKeel = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();

    $(".special.cards .image").dimmer({
      on: "hover",
    });

    this.cbFormType.setValue("Default");
  }

  ngOnDestroy() {
    //// console.log("On Destroy");
  }

  onRetrieve(vesselId: string) {
    //// console.log('OnRetrieve : ' + vesselId)
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findById/" +
          vesselId
      )
      .subscribe((resp) => {
        let data = resp.json();
        this.setData(data);
      });
  }

  onRetrieveButton(vesselId: string) {
    //// console.log('OnRetrieve : ' + vesselId)
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findById/" +
          vesselId
      )
      .subscribe((resp) => {
        let data = resp.json();
        // console.log(data);
        this.setData(data);
        this.isUpdate = true;
      });
  }

  setData(data: any) {
    this.model = new Vessel();
    this.model.vesselId = String(data.vesselId).trim();
    this.model.vesselType = String(data.vesselType).trim();

    //// console.log("this.model.vesselType : " + this.model.vesselType);
    if (
      this.model.vesselType != "DEFAULT" &&
      this.model.vesselType != "MOTHERVESSEL"
    ) {
      this.model.vesselType = "DEFAULT";
    }

    if (this.model.vesselType == "DEFAULT") {
      this.cbFormType.setValue("Default");
      this.modeType = false;
      this.isDefault = true;
    } else if (this.model.vesselType == "MOTHERVESSEL") {
      this.cbFormType.setValue("Mother Vessel");
      this.isDefault = false;
      this.modeType = true;
    }

    this.model.vesselCode = String(data.vesselCode).trim();
    this.model.vesselName = String(data.vesselName).trim();

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselId={query}"
    );
    this.cbVessel.setForceValue(this.model.vesselName);

    //this.cbVessel.setValue(this.model.vesselId);

    this.model.vesselName = String(data.vesselName).trim();
    this.model.psaVesselCode = String(data.psaVesselCode).trim();
    this.model.docsysCode = String(data.docsysCode).trim();
    this.model.callSign = String(data.callSign).trim();
    this.model.remarks = String(data.remarks).trim();

    if (String(data.isSsl).trim() == "Y") {
      this.isType = "ssl";
    }

    if (String(data.isSlot).trim() == "Y") {
      this.isType = "slot";
    }

    if (String(data.is3RdParty).trim() == "Y") {
      this.isType = "3rd";
    }

    if (String(data.isValid).trim() == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.capacity = Number.parseInt(data.capacity);
    this.model.gtiInternational = Number.parseInt(data.gtiInternational);
    this.model.ntiInternational = Number.parseInt(data.ntiInternational);
    this.model.gtsuezCanal = Number.parseInt(data.gtsuezCanal);
    this.model.ntsuezCanal = Number.parseInt(data.ntsuezCanal);
    this.model.ntpanamaCanal = Number.parseInt(data.ntpanamaCanal);
    this.model.deadWeight = Number.parseInt(data.deadWeight);
    this.model.lightWeight = Number.parseInt(data.lightWeight);
    this.model.loa = Number.parseInt(data.loa);
    this.model.lpp = Number.parseInt(data.lpp);
    this.model.breadthMould = Number.parseInt(data.breadthMould);
    this.model.depthMould = Number.parseInt(data.depthMould);
    this.model.draftDesign = Number.parseInt(data.draftDesign);
    this.model.draftExtreme = Number.parseInt(data.draftExtreme);

    this.model.type = String(data.type).trim();
    this.model.clazz = String(data.clazz).trim();
    this.model.flag = String(data.flag).trim();
    this.model.imoNo = String(data.imoNo).trim();
    this.cbIMO.setForceValue(String(data.imoNo).trim());

    this.model.owner = String(data.owner).trim();
    this.model.officialNumber = String(data.officialNumber).trim();
    this.model.shipManagement = String(data.shipManagement).trim();
    this.model.portRegistry = String(data.portRegistry).trim();
    this.model.navigationArea = String(data.navigationArea).trim();
    this.model.builder = String(data.builder).trim();

    this.model.dateLaunch = String(data.dateLaunch).trim();
    $("#dateLaunch").find("#datePicker").val(this.model.dateLaunch);
    this.model.dateDelivery = String(data.dateDelivery).trim();
    $("#dateDelivery").find("#datePicker").val(this.model.dateDelivery);
    this.model.dateKeel = String(data.dateKeel).trim();
    $("#dateKeel").find("#datePicker").val(this.model.dateKeel);

    this.model.yearBuild = Number.parseInt(data.yearBuild);
    this.model.max20OnHatch20 = Number.parseInt(data.max20OnHatch20);
    this.model.max20InHold20 = Number.parseInt(data.max20InHold20);
    this.model.max20OnHatch40 = Number.parseInt(data.max20OnHatch40);
    this.model.max20InHold40 = Number.parseInt(data.max20InHold40);
    this.model.max20OnHatch45 = Number.parseInt(data.max20OnHatch45);
    this.model.max20InHold45 = Number.parseInt(data.max20InHold45);
    this.model.max40OnHatch20 = Number.parseInt(data.max40OnHatch20);
    this.model.max40InHold20 = Number.parseInt(data.max40InHold20);
    this.model.max40OnHatch40 = Number.parseInt(data.max40OnHatch40);
    this.model.max40InHold40 = Number.parseInt(data.max40InHold40);
    this.model.max40OnHatch45 = Number.parseInt(data.max40OnHatch45);
    this.model.max40InHold45 = Number.parseInt(data.max40InHold45);
    this.model.reeferOnHatch = Number.parseInt(data.reeferOnHatch);
    this.model.reeferInHatch = Number.parseInt(data.reeferInHatch);
    this.model.sbaseOnHatch20 = Number.parseInt(data.sbaseOnHatch20);
    this.model.sbaseInHold20 = Number.parseInt(data.sbaseInHold20);
    this.model.sbaseOnHatch40 = Number.parseInt(data.sbaseOnHatch40);
    this.model.sbaseInHold40 = Number.parseInt(data.sbaseInHold40);
    this.model.sbaseOnHatch45 = Number.parseInt(data.sbaseOnHatch45);
    this.model.sbaseInHold45 = Number.parseInt(data.sbaseInHold45);
    this.model.serviceSpeed = Number.parseInt(data.serviceSpeed);
    this.model.trialMaxSpeed = Number.parseInt(data.trialMaxSpeed);
    this.model.foConsumptionRate = Number.parseInt(data.foConsumptionRate);
    this.model.waterBallastTank = Number.parseInt(data.waterBallastTank);
    this.model.freshWaterTank = Number.parseInt(data.freshWaterTank);
    this.model.fuelOilTank = Number.parseInt(data.fuelOilTank);
    this.model.dieselOilTank = Number.parseInt(data.dieselOilTank);

    if (data.vesselImagePath != null) {
      this.model.vesselImagePath = String(data.vesselImagePath).trim();
    }

    // console.log('data.mainEngine : ' + data.mainEngine);
    this.model.mainEngine = String(data.mainEngine).trim();
    // console.log('data.bowThruster : ' + data.bowThruster);
    this.model.bowThruster = String(data.bowThruster).trim();

    this.model.userCreated = String(data.userCreated).trim();
    this.model.dateCreated = String(data.dateCreated).trim();
    this.model.userModified = String(data.userModified).trim();
    this.model.dateModified = String(data.dateModified).trim();
    this.model.userInvalid = String(data.userInvalid).trim();
    this.model.dateInvalid = String(data.dateInvalid).trim();
  }

  changeEventFormType(event: any) {
    //// console.log(event['code']);
    if (event["code"] == "DEFAULT") {
      this.modeType = false;
      this.isDefault = true;
      this.model.vesselType = "DEFAULT";
      this.model.isSsl = true;
      this.isType = "ssl";
      this.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findGeneral/N/*/vesselName/{query}/1/5/*/*"
      );
    } else {
      this.modeType = true;
      this.isDefault = false;
      this.model.vesselType = "MOTHERVESSEL";
      this.model.isSsl = false;
      this.model.is3RdParty = false;
      this.model.isSlot = false;
      this.isType = "";
      this.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findGeneral/Y/*/vesselName/{query}/1/5/*/*"
      );
    }
  }

  changeEventVessel(event: any) {
    console.log('masuk change event');
    console.log(event);

    //// console.log("event.vesselId  : " + event.vesselId);
    if (event.vesselId == null) {
      if (event.target != null) {
        this.model.vesselName = event.target.value;
      }
    } else {
      this.model.cbValue = this.cbVessel.getValue().vesselName;
      this.model.vesselId = String(event.vesselId).trim();
      this.model.vesselCode = String(event.vesselCode).trim();
      this.model.vesselName = String(event.vesselName).trim();

      console.log('debignnilai');
      console.log(this.model.vesselName+' '+this.cbVessel.getValue().vesselName);
      
    }
    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeType(val: string) {
    this.isType = val;
    if (val.toUpperCase() == "SSL") {
      this.modeType = false;
      this.isDefault = true;
      this.model.vesselType = "DEFAULT";
      this.cbFormType.setValue("Default");
      console.info("kesini net");
    }
  }

  changeEventVesselHistory(event: any) {
    this.vesselIdHistory = null;
    this.vesselCodeHistory = null;
    if (event.vesselId == null) {
      if (event.target != null) {
      }
    } else {
      this.vesselIdHistory = String(event.vesselId).trim();
      this.vesselCodeHistory = String(event.vesselCode).trim();

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.vesselIdHistory);
    }
  }

  onUpload() {
    var self = this;

    $("#upload").click();

    $(":file").change(function () {
      if (this.files && this.files[0]) {
        var reader = new FileReader();
        reader.onload = imageIsLoaded;
        reader.readAsDataURL(this.files[0]);
      }
    });

    function imageIsLoaded(e) {
      // console.log(e.target.result);
      $("#img").attr("src", e.target.result);
      self.model.vesselImagePath = e.target.result;
    }
  }

  onRemove() {
    var self = this;
    var data: any =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA8AAAAMcAQMAAACl5F6MAAAABlBMVEW8vsDn6OnyCdevAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4AezBMQEAAADCoPVP7WsIoAcAAAAAAAAAAAAAAAAAAAAAAIydO0iOk4eCAGyVXNEuukF0FB1NHE1H0RG0ZEHRf5A99WYMk1T+Kvolw+vVjBf+CkMLoRCFWQkuqCquB3QOOQKrCpwBKJ1i6Jxk/EzTubaArgAHQOeyjhu8aMEafUrYolLjLRMfLrpw5cMYadeBnTLcrwN7ZXi+DhyuCi8GG/xy8Gyw3SRe7n7cLzfnajah5z87keFJ//lYf0WAAq+vv+rDX+fir+zpr2Xqr95qrle/ywr9OxX+/nF19fGRmR/yrzCJCudRqNHiNDHhIlquTBgCF2aX3V2BwYT9nUYdNoNojnqjiKJ56q0xiRaok4EsWqQO2EW0RJ3wQbTMvDc6iFaYswEvkx5Hnf8E0Tx1xhcfj3gh1lhOLHVWnx8vLvBq/FAnYpGBhwEElVfjhyETjVjj5bFanATpLrHIcpDkIstpJRdZLmRykaW63CLLMZKLLGeVXGS5jvlFluZKq8k1lnGMXGMZuck1lnsVu8ZydybXWOYj5BrLDIxcY5lz8mrML/JupPIC02osf3tyjeVqY9dYikys8QlTAUIsFovFYrFYLJaIP8hq8D4GG2ywwQYbbLDBBhtssMEGG2ywwQYb3N9+G/ePwQYbbLDBBhtssMEGG5yhA3ugqcARmFXgDKwqcAGgAsu+J1zYy94UXDjIRxosH+crwUm2euHCWX43Fy7YciUYI9N1YIeRavDpsMdIM9jgs+BOh4PBavA3ras6aMFRC06TEpyrLsy/H5emBKOrwvxZpsOsA3ssOk8SQQ9edZ4WI6DzfJwU4Ym/FCE7ofFXfQoJlnUugRsHdl9GTKBzlxRF6vzVW9kngr5eLTB5hT6MUhPgLfdDRhwQCQ79TQXe756tAWcp10XgIuMYF8aWxoedLtz5sB/wzIeDLrzw4QiRqHBSgOvnwLWFCrc7eCLCbnwrXFh2ZMdHKhEOyx3ciHBch8OHE2TtCZ0IZ0wCz1S4jhGTDhc0ecN9IcLja/qEVwIswqwC+/H3zfgMDw7j95cbPBFg+YEKnIaGWyoNzpvmcEujws0rwGX7LnCnwWOgDLhlZsFuaJEP+zFCC7yw4DCAhFtWFhwHl/lwwpeowRMJzlpw+QpXEgwl2O3gxoH9Du4cOOzgmQNHLTjt4OXF4byDVw5csAsHhhLssM/EgD32qQw4aMER+zQtuDPgpAVn7DMz4IJ9FgYMJdgdwSsB9jgKAQ5acDyEJy24ng8nLTgfwu18uBzC/XwYSrA7hmfCeyCHWU6Hw18Gr4R3fY5zOpy04PwEngj/KfYw9WwYSrB7BjfCW4qH6a8Kh2fwTHgF9jDLyXDSgvMzeKW8UX6Uk2Eowe45PL0m7J/D9VQ4aMG/isH/JwYbbLDBBhtssMEGG2ywtz3K/2tvDmQAAAAABvlbn+NbCSQWi8VisVgsFovFYrFYLBYvAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHVJjR5bJLd8AAAAASUVORK5CYII=";

    $("#img").attr("src", data);
    self.model.vesselImagePath = data;
    $(":file").val("");
  }

  generateVesselHistoryReport(event) {
    // console.log('this.vesselIdHistory : ' + this.vesselIdHistory);

    if (this.vesselCodeHistory == "") {
      $("#dialogvesselform").modal("show");
      //this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    } else {
      var formatName = "report-History-Vessel-"+this.vesselIdHistory+this.vesselCodeHistory +".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/24/History-Vessel-"
        +
        this.vesselIdHistory +
        "-" +
        this.vesselCodeHistory +
        "/" +
        this.vesselIdHistory +
        "@" +
        this.valHistoryStartDate +
        "@" +
        this.valHistoryEndDate
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000")  +
      //     "/Mreport/runReport/24/History-Vessel-" +
      //     this.vesselIdHistory +
      //     "-" +
      //     this.vesselCodeHistory +
      //     "/" +
      //     this.vesselIdHistory +
      //     "@" +
      //     this.valHistoryStartDate +
      //     "@" +
      //     this.valHistoryEndDate
      // );
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  retrieveHistory(event) {
    this.gridHistory.loadData();
  }

  infoGridHistory(event) {
    // this.info=event;
  }

  onOk() {
    $("#dialogvesselform").modal("hide");
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar: string = ""
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      return (this.model[
        `error-${field}`
      ] = `Only accept alphanumeric ${validSPChar}`);
    } else {
      return (this.model[`error-${field}`] = "");
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      this.model[`error-${field}`] = "";

      return true;
    }

    return false;
  }

  keypressEventNumber(event: any) {
    var regexPattern = new RegExp("[0-9]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  pasteEventNumber(event: any) {
    var pastedText = event.clipboardData
      .getData("text")
      .replace(/[^0-9]/g, "");

    event.preventDefault();
    this.model.imoNo = pastedText;
  }

  /*
    eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

   message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialogvesselform').modal('show');
  }

*/

  changeEventIMO(event){
    if(event.imo){
      this.model.imoNo = event.imo;
      this.isLockIMO = true;
    }else{
      this.isLockIMO = false;
    }
  }

  resetIMO(){
    this.cbIMO.setValue("");
    this.model.imoNo = "";
    this.isLockIMO = false;
  }

}
