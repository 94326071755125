import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, CookieService, GenericService } from 'sibego-ui-library';
import { BehaviorSubject, Subject } from 'rxjs';
import { RenominationBookingModel } from '../../shared/models/renominationModel';

@Injectable({
  providedIn: 'root'
})
export class RenominationService {

  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.201.201:14000';
  userID = this.cookieService.getName();
  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  private model = new RenominationBookingModel();

  private modelSource = new Subject<boolean>();
  private messageSource = new Subject<{
    type: 'event' | 'nEvent' | '',
    mess: string
  }>();
  private statusRenominateSource = new Subject<any>();
  private handlingAfterRenomSource = new Subject<any>();

  messageState$ = this.messageSource.asObservable();
  modelSource$ = this.modelSource.asObservable();
  statusRenominateState$ = this.statusRenominateSource.asObservable();
  statusAfterRenom$ = this.handlingAfterRenomSource.asObservable();

  constructor(private configService: ConfigService, private cookieService: CookieService, private genericService: GenericService, private _http: HttpClient) { }

  getRenominationBooking(model: RenominationBookingModel) {
    const url =
      this.BASE_URL +
      `/TsBookingRenomination/retrieve?pot=${model.bPot1Code}&status=${model.status}&pol=${model.bPolCode}&pod=${model.podCode}&dischargingVesselId=${model.vesselIdDischarging}&dischargingVoyage=${model.voyageDischarging}&dischargingVesselBound=${model.bound}&connectingVesselId=${model.vesselIdConnecting}&connectingVoyage=${model.voyageConnecting}&connectingVesselBound=${model.bound}`
    return this.genericService.GET(url);
  }

  getListPortPair(model: RenominationBookingModel) {
    const url =
      this.BASE_URL +
      `/PortPairAllocation/findByBkgProspect/${model.vesselId}/${model.voyage}/${model.bound}/${this.officeCode}`
    return this.genericService.GET(url);
  }

  rejectAllocation(data) {
    const url = this.BASE_URL + '/TsBookingProspect/reject';
    return this.genericService.POST(url, data);
  }
  
  exportProspectBooking(model) {
    const url =
      this.BASE_URL +
      `/Mreport/runReport/75/fileName/${model.bPot1Code}@${model.bPolCode}@${model.status}@${model.vesselCodeDischarging}@${model.voyageDischarging}@${model.podCode}@${model.vesselCodeConnecting}@${model.voyageConnecting}`
    
      this._http.get(url, { headers: new HttpHeaders({'Token': localStorage.getItem('token') }), responseType: 'blob' })
    return this.genericService.GET(url);
  }

  handleCheckValidBookingList(data) {
    const url = this.BASE_URL + '/TsBookingRenomination/renominateIfValid';
    return this.genericService.POST(url, data);
  }

  updateModel(model) {
    this.model = model;
  }

  getModelReno() {
    return this.model;
  }

  callMessageProm(data: {
    type: 'event' | 'nEvent' | '',
    mess: string
  }) {
    this.messageSource.next(data);
  }

  updateModelStatus(mess) {
    this.modelSource.next(mess);
  }

  updateStatusRenominate(mess) {
    this.statusRenominateSource.next(mess);
  }

  handleAfterRenom(data) {
    this.handlingAfterRenomSource.next(data);
  }
}
