import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  MasterVesselSchedule,
  MasterVesselScheduleDetail,
  GridPluginComponent2,
  officeModel,
  specialCharUtil,
  newDialogComponent,
  MasterVesselTransmitEdi
} from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  CalendarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  UploadPluginComponent
} from "sibego-ui-library";
import { Router } from "@angular/router";
import FormValidation from "../../utils/formValidation";
import { removeSummaryDuplicates } from "@angular/compiler";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip'
import * as JSZipUtils from 'jszip-utils'
import {GridApi} from "ag-grid-community";

declare var $: any;

@Component({
  selector: "app-master-vessel-schedule-page",
  templateUrl: "./master-vessel-schedule-page.component.html",
  styleUrls: ["./master-vessel-schedule-page.component.css"],
})
export class MasterVesselSchedulePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  /* Parameter for information into Footer */
  info = "";
  space = "&nbsp;&nbsp;";
  @ViewChild("grid") grid: GridPluginComponent2;
  @ViewChild("gridCustom") gpc: GridPluginComponent2;
  @ViewChild("gridSequence") gsq: GridPluginComponent2;
  @ViewChild("gridCopy") gc: GridPluginComponent2;
  @ViewChild("gridH") gh: GridPluginComponent2;
  @ViewChild("gridCopy2") gridCopy2: GridPluginComponent;

  @ViewChild("sopan") vName: ComboPluginComponent;
  @ViewChild("cbservice") srv: ComboPluginComponent;
  @ViewChild("cbpc") pc: ComboPluginComponent;
  @ViewChild("cbtml") tm: ComboPluginComponent;
  @ViewChild("cbpoc") poc: ComboPluginComponent;
  @ViewChild("sopin") hVName: ComboPluginComponent;
  @ViewChild("sopon") bVName: ComboPluginComponent;
  @ViewChild("cbpLp") pLoop: ComboPluginComponent;
  @ViewChild("gVCombo") gridCombo: ComboPluginComponent;
  @ViewChild("cbVesselCopy2") cbVesselCopy: ComboPluginComponent;
  @ViewChild("cbVoyageCopy2") cbVoyageCopy: ComboPluginComponent;
  //@ViewChild('cbnLoop') nLoop:ComboPluginComponent;

  //@ViewChild('createdDate') crDate:CalendarPluginComponent;
  @ViewChild("petaDTP") petaDTP: CalendarPluginComponent;
  @ViewChild('customDialog') dialogMessage:newDialogComponent;

  @ViewChild("cbVesselTransmit")
  cbVesselTransmit: ComboPluginComponent;
  @ViewChild("cbVoyageTransmit") cbVoyageTransmit: ComboPluginComponent;
  @ViewChild('ediFileUpload') ediFileUpload:UploadPluginComponent;

  //GenericUtil = new GenericUtil;

  //@ViewChild('asd') tdTable:ElementRef;

  //@ViewChild('crdate') cdate:CalendarPluginComponent;

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = "flagStatus"; //false;//false;
  modeToolbar = false; //false;
  modeDetail = false;
  modeButton = false;
  modeSequence = false;
  modeSlot = true;
  modeViewOnly = false;
  typeCalendar = "calendar"; // for calendar
  typeTime = "time12"; // time12 for 12 hour time24  for 24 hour
  checkedBox = 0;
  doNotCalculate = true;
  bsVoyMode = "new";

  /* Parameter listStore for Grid */
  listStore = new ListStore();
  mvScheduleStore = new ListStore();
  mvScheduleDetailStore = new ListStore();
  sequenceStore = new ListStore();
  serviceSequenceStore = new ListStore();
  sequenceSelectedNo: number = 0;
  copyStore = new ListStore();
  copy2Store = new ListStore();
  batchCopyStore = new ListStore();
  vesselTempStore = new ListStore();
  oldDetailsData = new ListStore();
  voyageList = [];

  setGridEvent: string = "";
  //setSeqEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingCalendarPeta;
  settingCalendarPetd;
  settingCalendarEta;
  settingCalendarEtd;
  settingCalendarAta;
  settingCalendarAtd;
  settingTime;
  gridCustomSetting;
  gridCopySetting;
  gridCopy2Setting;
  gridBatchCopySetting;
  settingPOC;
  settingVName;
  settingService;
  settingPC;
  settingTml;
  settingTest;
  settingSequence;
  settingGridHistory;
  settingHVName;
  settingBVName;
  settingPLoop;
  settingNLoop;
  settingVesselCopy2;
  settingVoyageCopy2;
  settingCustomDialog;
  settingVesselTransmit;
  settingVoyageTransmit;
  settingEdiUploadFile;

  /* Parameter model */
  model = new MasterVesselSchedule();
  modelDetail = new MasterVesselScheduleDetail();
  modelTransmitEdi = new MasterVesselTransmitEdi();
  gridTransmitEdi : MasterVesselTransmitEdi[] = [];
  formDataDetail = new MasterVesselScheduleDetail();
  office = new officeModel();
  copy2Model = new MasterVesselSchedule();
  serviceSeq = [];
  selectList = [];
  detailSeqMode: string = "-";
  yearList = [];
  monthsList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  monthsList2 = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  dayList = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];
  hourList = [];
  minuteList = [];
  retainEta: string = "";
  postParam: any = {};
  //poc = {code:'',purpose:''};

  /* status success or not */
  isSuccess: boolean = false;
  before: number = 0;
  after: number = 0;
  messageDialog: string = "";
  messageDialog2: string = "";
  dCopyMessage = [];
  dCopyMessage1 = [];

  loading = false;
  loadingCopy = false;

  calLock = true;
  lock = false;
  serviceLock = false;
  createLock = false;
  historyLock = true;
  terminalLock = true;
  voyageLock = false;
  updateMode = false;
  verticalButtonLock = true;
  connectionLock = false;
  nextConnectionLock = true;
  copy2Lock = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  isDateValid = true;

  tabStatus: string = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "unActive";

  columnDefsTransmit;
  rowDataTransmit: any = [];
  rowSelectionTransmit;
  pinnedBottomRowDataTransmit;
  VesseltransmitEdiList = [];
  gridApiTransmit: GridApi;
  isErrorTransmitEdi: boolean = false;
  btnGenerate: boolean = true;
  btnEdiUpload: boolean = true;
  gridApi: GridApi;
  /* validatorRules */
  validatorRules = new Object();

  isError: any = false;
  errorDuplicatePreLoop: any = "";
  errorSpecialChar = [];
  isErrorSpcChar = false;

  isUnderUpdateMode: Boolean = false;
  changeServiceProceed: Boolean = false

  sCharUtil = new specialCharUtil();
  formValidation = new FormValidation();

  isReadOnlyBound = false;

  //map
  //pocMap = new Map<String, String>();
  pocMap :any[]=[];
  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cook: CookieService,
    private router: Router,
    private cookieService: CookieService,
    private httpClient : HttpClient
  ) {
    super();

    this.rowSelectionTransmit = "multiple";

    this.router.events.pairwise().subscribe((e) => {
      this.clearDialog();
    });

    this.settingTest = {
      modeCal: true,
      type: "date",
      debug: true,
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "", // default : ''
      hour12: false,
      isRange: true,
    };

    this.settingCalendarPeta = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#kancil", // default : ''
      hour12: false,
      calId: "#PETD",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingCalendarPetd = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#petdTime", // default : ''
      hour12: false,
      calId: "",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingCalendarEta = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#etaTime", // default : ''
      hour12: false,
      calId: "#ETD",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingCalendarEtd = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#etdTime", // default : ''
      hour12: false,
      calId: "",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingCalendarAta = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#ataTime", // default : ''
      hour12: false,
      calId: "",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingCalendarAtd = {
      modeCal: true,
      type: "datetime",
      returnText: "year-month-day", // format 'text-text-text' (text = year/month/day) separated by "-""
      returnTextTime: "hour-minute",
      separator: "", // date separator between day, month and year.
      AltTimeId: "#atdTime", // default : ''
      hour12: false,
      calId: "",
      addHour: 0,
      addHourETA: 0,
    };

    this.settingToolbar = {
      buttonsFront: [
        { name: "Create", event: "create", icon: "plus" },
        { name: "Update", event: "update", icon: "write" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "History", event: "history", icon: "history" },
        { name: "Batch Update", event: "batch", icon: "upload" },
        { name: "Transmit", event: "transmit", icon: "list" },
        { name: "Cancel", event: "cancel", icon: "remove" },
      ],
      buttonsDetail: [
        { name: "Save", event: "save", icon: "save" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Copy", event: "copy", icon: "copy", enable: false },
        { name: "Copy2", event: "copy2", icon: "copy", enable: false },
        {
          name: "Change Service",
          event: "cService",
          icon: "exchange",
          enabled: false,
        },
      ],
      buttonDetailsBu: [
        { name: "Copy2", event: "copy2", icon: "copy", enable: false },
      ],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Vessel Code", value: "vesselCode" },
        { display: "Vessel Name", value: "vesselName" },
        { display: "Base Voyage", value: "baseVoyage" },
        { display: "Service", value: "service" },
        { display: "Bound", value: "sBound" },
      ],
      border: true,
      urlhelp: "assets/pdf/panduan.pdf",
      bheight: "45px" /* default 68px */,
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVesselSchedules/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 20 },
        { header: "Vessel Name", field: "vesselName", width: 10 },
        { header: "Vessel Code", field: "vesselCode", width: 10 },
        { header: "Voyage", field: "voyage", width: 10 },
        { header: "PSA Abv. Name", field: "psaAbvName", width: 10 },
        { header: "Service", field: "serviceName", width: 10 },
        { header: "Bound", field: "sBound", width: 10 },
        { header: "SSL.S", field: "sslS", width: 10 },
        { header: "Slot.S", field: "slotS", width: 10 },
        { header: "3rd Party.S", field: "3rdPartyS", width: 10 },
        { header: "SSL.V", field: "sslV", width: 10 },
        { header: "Slot.V", field: "slotV", width: 10 },
        { header: "3rd Party.V", field: "3rdPartyV", width: 10 },
        { header: "User Created", field: "userCreated", width: 10 },
        { header: "Date Created", field: "dateCreated", width: 10 },
        { header: "User Modified", field: "userModified", width: 10 },
        { header: "Date Modified", field: "dateModified", width: 10 },
        { header: "Valid?", field: "isValid", width: 10 },
      ],
      buttons: [],
      store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      //auto:false
    };

    this.gridCustomSetting = {
      url: "",
      page: 5,
      columns: [
        { header: "Seq No", field: "seqNo", width: 50 },
        { header: "Port of Call", field: "portOfCallName", width: 200 },
        { header: "Port Code", field: "portOfCall", width: 80 },
        { header: "Terminal", field: "terminalName", width: 200 },
        { header: "Terminal Code", field: "terminalCode", width: 100 },
        { header: "Purpose", field: "callReason", width: 200 },
        { header: "Port PETA", field: "peta", width: 150 },
        { header: "Port ETA", field: "eta", width: 150 },
        { header: "Port PETD", field: "petd", width: 150 },
        { header: "Port ETD", field: "etd", width: 150 },
      ],
      buttons: [],
      store: this.mvScheduleDetailStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      auto: false,
      cbPadding: 1,
    };

    this.gridCopySetting = {
      url: "",
      page: 5,
      columns: [
        { header: "Voyage", field: "voyage", width: 25, type: "string" },
        {
          header: "ETA of 1st Port (yyyymmdd hhmm)",
          field: "eta",
          width: 70,
          type: "date",
        },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
    };

    this.gridCopy2Setting = {
      id: "gridCopy2",
      url: "",
      page: 5,
      columns: [
        {
          header: "Vessel",
          field: "vessel",
          width: 30,
          type: "combo",
          settingCombo: {
            url: "",
          },
        },
        { header: "Voyage", field: "voyage", width: 20, type: "string" },
        {
          header: "ETA of 1st Port (yyyymmdd hhmm)",
          field: "eta",
          width: 30,
          type: "date",
        },
        { header: "Status", field: "status", width: 20 },
      ],
      buttons: [],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "Vessel",
      sortingDirection: "ASC",
    };

    this.gridBatchCopySetting = {
      url: "",
      page: 5,
      columns: [
        { header: "Vessel", field: "vesselName", width: 15, type: "-" },
        { header: "Voyage", field: "voyage", width: 15, type: "string" },
        { header: "ETA of 1st Port", field: "eta", width: 40, type: "date" },
        { header: "Status", field: "status", width: 30, type: "-" },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
    };

    this.settingGridHistory = {
      url: "", //BASE_API + '/MasterVesselSchedules/findGeneral',
      page: 5,
      columns: [
        { header: "No", field: "no", width: 10 },
        { header: "Vessel Name", field: "vesselName", width: 10 },
        { header: "Vessel Code", field: "vesselCode", width: 10 },
        { header: "Voyage", field: "voyage", width: 10 },
        { header: "PSA Abv. Name", field: "psaAbvName", width: 10 },
        { header: "Service", field: "serviceCode", width: 10 },
        { header: "Bound", field: "sBound", width: 10 },
        { header: "SSL.S", field: "sslS", width: 10 },
        { header: "Slot.S", field: "slotS", width: 10 },
        { header: "3rd Party.S", field: "3rdPartyS", width: 10 },
        { header: "SSL.V", field: "sslV", width: 10 },
        { header: "Slot.V", field: "slotV", width: 10 },
        { header: "3rd Party.V", field: "3rdPartyV", width: 10 },
        { header: "User Created", field: "userCreated", width: 10 },
        { header: "Date Created", field: "dateCreated", width: 10 },
        { header: "User Modified", field: "userModified", width: 10 },
        { header: "Date Modified", field: "dateModified", width: 10 },
        { header: "Valid?", field: "isValid", width: 10 },
      ],
      buttons: [],
      store: this.listStore,
      enableSorting: false,

      enablePagination: false,
    };

    this.settingSequence = {
      url: "",
      page: 5,
      columns: [
        { header: "Seq No", field: "seqNo", width: 20 },
        { header: "Port of Call", field: "pocName", width: 50 },
      ],
      buttons: [],
      store: this.sequenceStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
    };

    this.settingPOC = {
      id: "poc",
      type: "select", // search | select | select input
      url: "assets/json/purposeOfCall.json", //BASE_API + '/MasterLocation/searchLocation?q={query}',
      maxChars: 3,
      placeholder: "Search ...",
      code: "purposeCode",
      title: "purpose",
      description: "",
      flag: "",
    };

    this.settingPLoop = {
      id: "pLoop",
      type: "select input option",
      url: "",
      maxChars: 3,
      placeholder: "",
      code: "voyage",
      title: "voyage",
      description: "",
      flag: "",
      urlType: "dynamic",
    };

    this.settingNLoop = {
      id: "nLoop",
      type: "select input",
      url: "",
      maxChars: 3,
      placeholder: "",
      code: "voyage",
      title: "voyage",
      description: "",
      flag: "",
      urlType: "dynamic",
    };

    this.settingVName = {
      id: "vName",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 2,
      template: "grid",
      placeholder: "Search ...",
      code: "vesselCode",
      title: "vesselName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "A/C Code", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingHVName = {
      id: "hVName",
      type: "search enter",
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/25/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 2,
      template: "grid",
      placeholder: "Search ...",
      code: "vesselCode",
      title: "vesselName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "A/C Code", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingBVName = {
      id: "bVName",
      type: "search enter",
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/25/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 2,
      template: "grid",
      placeholder: "Search ...",
      code: "vesselCode",
      title: "vesselName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "A/C Code", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingPC = {
      id: "pc",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findLikeLocationNameOrLocationCodeIsValid/*/{query}",
      maxChars: 2,
      template: "grid",
      placeholder: "Search ...",
      code: "locationCode",
      title: "locationName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingTml = {
      id: "tml",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/25/*/*",
      maxChars: 0,
      template: "grid",
      placeholder: "Search ...",
      code: "locationCode",
      title: "locationName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Location Id", field: "locationId", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingService = {
      id: "sev",
      type: "search enter",
      urlBu:
        this.configService.config.BASE_API.toString() +
        "/MasterServices/findGeneral/Y/distinctServiceName/{query}/1/100/*/*",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterServices/findGeneral/Y/mvSchedule/{query}/1/100/ASC/serviceName",
      maxChars: 2,
      template: "grid",
      placeholder: "Search ...",
      code: "serviceCode",
      title: "serviceName",
      description: "",
      flag: "",
      columns: [
        { header: "Service Name", field: "serviceName", width: 150 },
        { header: "Service Code", field: "serviceCode", width: 100 },
        { header: "Psa Service Code", field: "psaServiceCode", width: 100 },
        { header: "Seq", field: "serviceSequence", width: 50 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Is Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingVesselCopy2 = {
      id: "newCopyVessel",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/vesselName/{query}/1/100/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "Vessel Name", field: "vesselName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageCopy2 = {
      id: "newCopyVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingCustomDialog = {
      id  : 'customDialog',
    };

    this.settingVesselTransmit = {
      id: "cbVesselTransmit",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxVessel/vesselName={query}",
      maxChars: 3,
      template: "grid",
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Name", field: "vesselName", width: 100 },
        { header: "PSA.Abbr. Name", field: "psaVesselCode", width: 150 },
        { header: "Vessel Code", field: "vesselCode", width: 130 },
        { header: "A/C Code", field: "docsysCode", width: 100 },
        { header: "Call Sign", field: "callSign", width: 100 },
        { header: "SSL", field: "isSsl", width: 50 },
        { header: "Slot", field: "isSlot", width: 50 },
        { header: "3rd Party", field: "is3RdParty", width: 150 },
        { header: "Vessel Type", field: "vesselType", width: 100 },
        { header: "Valid", field: "isValid", width: 80 },
      ],
    };

    this.settingVoyageTransmit = {
      id: "cbVoyageTransmit",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 200 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 200 },
      ],
    };

    this.columnDefsTransmit = [
      {
        headerName: "Seq",
        field: "seq",
        width: 100,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
      },
      {
        headerName: "Vessel",
        field: "vesselName",
        headerTooltip: "Vessel",
        tooltipField: "vesselName",
      },
      {
        headerName: "Voyage",
        field: "voyage",
        headerTooltip: "Voyage",
        tooltipField: "voyage",
      },
      {
        headerName: "Service Name",
        field: "serviceName",
        headerTooltip: "Service Name",
        tooltipField: "serviceName",
      },
    ];

    this.settingEdiUploadFile = {
      id   : 'uploadEdi',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'edi',
      showUploadButton: false,
      customButtons : [
      ]
    }
  }

  ngOnInit() {
    this.setYearList();
  }

  ngAfterViewInit() {
    this.firstInit();

    $(".ui.radio.checkbox").checkbox();
    $(".sapi").popup();
    $("#kampreeet").dropdown();
    $("combo-plugin").find("input").css("height", "20px");
    //$('#dpc').find('div').first().css('font-size','10px');
    //$('.ui.dropdown').dropdown();
    var self = this;
    $("#purposeoc").dropdown();

    this.generateCalendar("kancil", this.settingCalendarPeta, null);
    this.generateCalendar("petdTime", this.settingCalendarPetd, null);
    this.generateCalendar("etaTime", this.settingCalendarEta, null);
    this.generateCalendar("etdTime", this.settingCalendarEtd, null);
    this.generateCalendar("ataTime", this.settingCalendarAta, null);
    this.generateCalendar("atdTime", this.settingCalendarAtd, null);

    $("#detail-section").find("#container").css({
      //'width' : '100%',
      height: "120px",
      "overflow-x": "scroll",
      "overflow-y": "scroll",
    });

    $("#detail-section").find("#container").find("table").find("thead").css({
      "font-size": "10px",
      "line-height": "4px",
    });

    $("#mainGrid").find("#container").find("table").find("thead").css({
      "font-size": "10px",
      "line-height": "4px",
    });

    $("#detail-section").find("div").first().css({
      "margin-top": "-57px",
      "margin-left": "22px",
      "padding-top": "1px",
      "padding-bottom": "1px",
      "padding-left": "1px",
      "padding-right": "1px",
    });

    $("#detail-section").find("#container").find(".collapsing").css({
      width: "19px",
      padding: "1px",
    });

    /*$('#detail-section').find('#container').find('table').find('thead').find('tr').find('.collapsing').css({
      'width' : '20px',
      'padding-top' : '4px',
      'padding-bottom' : '4px',
      'padding-left' : '4px',
      'padding-right' : '4px'
    });

    $('#detail-section').find('#container').find('table').find('tbody').find('tr').find('.collapsing').css({
      'width' : '20px',
      'padding-top' : '4px',
      'padding-bottom' : '4px',
      'padding-left' : '4px',
      'padding-right' : '4px'
    });*/

    $("#detail-section").find("#container").find("table").find("tbody").css({
      "font-size": "10px",
      "line-height": "4px",
    });

    $("#mainGrid").find("#container").find("table").find("tbody").css({
      "font-size": "10px",
      "line-height": "10px",
    });

    $("#tPlug").find(".ui.button").css({
      "font-size": "10px",
    });

    $("#tPlug").find(".ui.input").css({
      "font-size": "10px",
    });

    /*$('#tPlug').find('.ui.menu').first().css({
      'height' : '45px'
    });*/

    $("#tPlug").find("#toolbar_o").find("#toolbar_body_1").css({
      height: "45px",
    });

    $("#tPlug").find("#toolbar_o").find("#toolbar_body_2").css({
      height: "45px",
    });

    $("#tPlug").find("#toolbar").css({
      "margin-top": "-10px",
      height: "47px",
    });

    $("#tPlug").find("#toolbar_o").css({
      height: "59px",
    });

    $("#tPlug").find("#toolbar_o").find("#toolbar_b_1").css({
      "margin-top": "-9px",
    });

    $("#tPlug").find("#toolbar_o").find("#toolbar_b_2").css({
      "margin-top": "-9px",
    });

    $("#detail-copy").find("#container").css({
      width: "100%",
      height: "200px",
      //'overflow-x' : 'scroll',
      "overflow-y": "scroll",
    });

    $("#PETA").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#PETA").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#PETD").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#PETD").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ETA").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ETA").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ETD").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ETD").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ATA").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ATA").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ATD").find("#date").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#ATD").find("#time").css({
      "font-size": "10px",
      height: "20px",
    });

    $("#detail-copy").find("#pgNation").remove();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();

    this.validatorRules = {
      vesselName: {
        rules: [
          { type: "empty", prompt: "Please input vessel name!" },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      baseVoyage: {
        rules: [
          { type: "empty", prompt: "Please input base voyage!" },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      servicePreviousVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      previousVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      psaInVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      psaOutVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
    };

    // console.log("GMT "+ this.model.gmtData);
    //$('.coupled.modal').modal({allowMultiple: false});
    //this.crDate.generateCalendar(new Date());
    //this.crDate.setdataValue('date','20161210');
  }

  generateRowTableCSS() {
    $("#detail-section").find("#container").find(".collapsing").css({
      width: "19px",
      padding: "1px",
    });
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false).modal("show");
  }

  message1(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any,
    txtIcon1: string,
    txtHeader1: string,
    txtContent1: string,
    btns1: string
  ) {
    //$('#dialog1').modal('attach events', '#dialog .yes.button');
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
      icon1: txtIcon1,
      header1: txtHeader1,
      content1: txtContent1,
      buttons1: btns1,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridCustom(event) {
    this.info = event;
  }

  getPOC(event) {
    // console.log(event);
    this.modelDetail.callReason = event.purpose;
    this.modelDetail["error-purpose"] = "";
    //this.poc.setValue(event.purpose);
    //this.poc.code = event.purposeCode;
    //this.poc.purpose = event.purpose;
    //this.valPOC = event.purpose;
  }

  getPLoop(event) {
    // console.log(event);
  }

  getNLoop(event) {
    // console.log(event);
  }

  checkOnInput(event) {

  }

  getVName(event) {
    console.log('cek dulu lah bor', event)
    this.formValidation = new FormValidation();

    if (!this.isUnderUpdateMode) {
      console.log('bor1');
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.model.psaVesselCode = event.psaVesselCode;
      this.model.vesselIsSsl =
      event.isSsl == "" ? false : event.isSsl == "Y" ? true : false;
      this.model.vesselIsSlot =
        event.isSlot == "" ? false : event.isSlot == "Y" ? true : false;
      this.model.vesselIs3rdParty =
        event.is3RdParty == "" ? false : event.is3RdParty == "Y" ? true : false;
      this.model.vesselIsSsl
        ? $("#ssl").prop("checked", true)
        : $("#ssl").prop("checked", false);
        this.model.vesselIsSlot
        ? $("#slot").prop("checked", true)
        : $("#slot").prop("checked", false);
        this.model.vesselIs3rdParty
        ? $("#3rd").prop("checked", true)
        : $("#3rd").prop("checked", false);
        console.log('bor2');

      // if (this.model.vesselIsSsl) {
      //   this.modeSlot = true;
      // }
      // if (this.model.vesselIsSlot) {
      //   this.modeSlot = false;
      // }
      // if (this.model.vesselIs3rdParty) {
      //   this.modeSlot = true;
      // }

      if (this.model.vesselCode != "") {
        this.loadbaseVoyageData("#idBsVoyage", this.model);
        //this.loadbaseVoyageData('#idPrevLoop', this.model);
        this.loadBaseVoyageAComplete("#prevLoopId", this.model);
        $("#nextLoopId").autocomplete({
          source: this.voyageList,
        });
        //this.loadbaseVoyageData('#idNextLoop', this.model);
        //this.loadBaseVoyageAComplete('#nextLoopId',this.model);
        this.vName.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterVessels/findByComboBoxControl/vesselName={query}/Y"
        );
      }
    }
    // console.log("10 = " , event.target.value);
    // console.log("11 = " , event.target.value.trim());
    // console.log("1 = " , this.formValidation.regVesVoy.test(event.target.value));
    if (event.target && this.formValidation.regVesVoy.test(event.target.value) == true) {
      this.model[`error-vesselName`] = "";
    }

    //this.vName.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/25/*/*');
    this.vName.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}/Y"
    );
  }

  getGridCombo(event) {
    // console.log(event);
  }

  getHVName(event) {
    // console.log(event);
    this.model.vesselId = event.vesselId;
    this.model.vesselCode = event.vesselCode;
    this.model.vesselName = event.vesselName;
    this.model.psaVesselCode = event.psaVesselCode;

    if (this.modeForm == "history") {
      this.loadbaseVoyageData2("#idHistoryVoyage", this.model);
    }
  }

  getBVName(event) {
     console.log(event);
    this.getHVName(event);
    if (this.model.vesselCode != "") {
      this.loadbaseVoyageData("#idBatchVoyage", this.model);
    }
    //$('#idBatchVoyage').find('.menu').find('.item').empty();
    //$('#idBatchVoyage').find('.menu').find('.item').remove();
    //var store = [];
    //var hitUrl = BASE_API + '/MasterVesselSchedules/findGeneral/Y/distinctBaseVoyage/'+ (this.model.vesselCode != null ? this.model.vesselCode.trim() : "*") +'/1/100/*/*';
    /*if(this.model.vesselCode != null && this.model.vesselCode != ''){
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if(resp.ok){
          store = resp.json()['content'];
          store.forEach(st=>{
            $('#idBatchVoyage').find('.menu').append('<div class="item" data-value="'+st.voyage+'">'+st.voyage+'</div>');
          });
        }
      });
    }*/
  }

  loadBaseVoyageAComplete(id: string, data) {
    this.voyageList = [];
    var store = [];
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/*/distinctBaseVoyage/" +
      (data.vesselCode != null ? data.vesselCode.trim() : "*") +
      "/1/100/*/*";
    if (data.vesselCode != null && data.vesselCode != "") {
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.ok) {
          store = resp.json()["content"];
          store.forEach((st) => {
            if (st.voyage != data.baseVoyage) {
              this.voyageList.push(st.voyage);
            }
          });
          $(id).autocomplete({
            source: this.voyageList,
          });
        }
      });
    }
  }

  loadbaseVoyageData(id: string, data) {
    $(id).find(".menu").find(".item").empty();
    $(id).find(".menu").find(".item").remove();

    var store = [];
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/*/distinctBaseVoyage/" +
      (data.vesselCode != null ? data.vesselCode.trim() : "*") +
      "/1/100/*/*";
    if (data.vesselCode != null && data.vesselCode != "") {
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.ok) {
          var blankSelect = true;
          store = resp.json()["content"];
          store.forEach((st) => {
            if (blankSelect) {
              $(id)
                .find(".menu")
                .append(
                  '<div class="item" data-value="" style="font-size:10px;">---</div>'
                );
              blankSelect = false;
            }
            $(id)
              .find(".menu")
              .append(
                '<div class="item" data-value="' +
                  st.voyage +
                  '" style="font-size:10px;">' +
                  st.voyage +
                  "</div>"
              );
          });
          $(id).dropdown();
        }
      });
    }
  }

  loadbaseVoyageData2(id: string, data) {
    $(id).find(".menu").find(".item").empty();
    $(id).find(".menu").find(".item").remove();

    var store = [];
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/*/distinctBaseVoyageHistory/" +
      (data.vesselCode != null ? data.vesselCode : "*") +
      "/1/100/*/*";
    if (data.vesselCode != null && data.vesselCode != "") {
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.ok) {
          var blankSelect = true;
          store = resp.json()["content"];
          store.forEach((st) => {
            if (blankSelect) {
              $(id)
                .find(".menu")
                .append('<div class="item" data-value="">---</div>');
              blankSelect = false;
            }
            $(id)
              .find(".menu")
              .append(
                '<div class="item" data-value="' +
                  st.voyage +
                  '">' +
                  st.voyage +
                  "</div>"
              );
          });
          $(id).dropdown();
        }
      });
    }
  }

  getService(event) {
    //if (!this.onValidate(this.model)) {
    if (!this.serviceLock) {
      if (event.serviceCode != null) {
        $("#idSeq").dropdown("clear");
        this.model.serviceCode = event.serviceCode;
        if(event.sBound){
          this.model.sBound = event.sBound;
        }else{
        }
        this.onOuw(event.serviceId);
        //this.loadServiceSequenceData(this.model.serviceCode,0);
      }
    }
    //}
  }

  loadServiceSequenceData(serviceCode: string, seq: number) {
    // console.log('serviceCode : '+serviceCode);
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterServices/findGeneral/Y/serviceCode/" +
      serviceCode.trim() +
      "/1/100/*/*";
    $("#idSeq").find(".menu").find(".item").empty();
    $("#idSeq").find(".menu").find(".item").remove();
    this.genericService.GET(hitUrl).subscribe((resp) => {
      // console.log('resp : '+resp.ok);
      if (resp.ok) {
        this.serviceSequenceStore.store = resp.json()["content"];
        this.serviceSequenceStore.store.forEach((ss) => {
          // console.log('res : '+ss.serviceCode);
          if (ss.serviceCode.trim() == serviceCode.trim()) {
            $("#idSeq")
              .find(".menu")
              .append(
                '<div class="item" data-value="' +
                  ss.serviceId +
                  '">' +
                  ss.serviceSequence +
                  "</div>"
              );
          }
        });
        $("#idSeq").dropdown();
      }
    });
  }

  onOuw(value: string) {
    if (value != "" && value != null) {
      // console.log('hehehehe : '+value);
      var hitUrl =
        this.configService.config.BASE_API.toString() +
        "/MasterServices/findById/" +
        value;
      if (this.gpc.listStore.store.length > 0) {
        this.gpc.listStore.store.splice(0, this.gpc.listStore.store.length);
      }
      this.gpc.loadData();

      this.genericService.GET(hitUrl).subscribe((resp) => {
        if (resp.ok) {
          // console.log(resp.json());
          if (resp.json()["serviceId"] != null) {
            var tempStore = [];
            this.modeViewOnly = false;

            this.model.serviceIsSsl =
              resp.json()["isSsl"] == ""
                ? false
                : resp.json()["isSsl"] == "Y"
                ? true
                : false;
            this.model.serviceIsSlot =
              resp.json()["isSlot"] == ""
                ? false
                : resp.json()["isSlot"] == "Y"
                ? true
                : false;
            this.model.serviceIs3rdParty =
              resp.json()["is3RdParty"] == ""
                ? false
                : resp.json()["is3RdParty"] == "Y"
                ? true
                : false;
            this.model.serviceIsSsl
              ? $("#Sssl").prop("checked", true)
              : $("#Sssl").prop("checked", false);
            this.model.serviceIsSlot
              ? $("#Sslot").prop("checked", true)
              : $("#Sslot").prop("checked", false);
            this.model.serviceIs3rdParty
              ? $("#S3rd").prop("checked", true)
              : $("#S3rd").prop("checked", false);

            this.model.serviceIs3rdParty
              ? this.modeSlot = false
              : this.modeSlot = true;
            this.model.serviceSequence = resp.json()["serviceSequence"];

            tempStore = resp.json()["masterServicesDetails"];
            $('input[name="gridCount"]').val(tempStore.length);
            if (tempStore.length > 0) {
              for (var i = 0; i < tempStore.length; i++) {
                tempStore.forEach((tdd) => {
                  if (i == tdd.seqNo - 1) {
                    var tmpStoreDetail = new MasterVesselScheduleDetail();
                    // console.log("seq no "+tdd.seqNo);
                    tmpStoreDetail.seqNo = tdd.seqNo;
                    tmpStoreDetail.portOfCall = tdd.portCode.trim();
                    tmpStoreDetail.portOfCallName = tdd.portName.trim();
                    tmpStoreDetail.portCode = tdd.portId;
                    tmpStoreDetail.terminalCode =
                      tdd.terminalCode == "ZZZZZ"
                        ? ""
                        : tdd.terminalCode.trim();
                    tmpStoreDetail.terminalName =
                      tdd.terminalCode == "ZZZZZ"
                        ? ""
                        : tdd.terminalName.trim();
                    tmpStoreDetail.callReason = tdd.callReason.trim();

                    tmpStoreDetail.petaDays = parseInt(
                      tdd.fmPortDaysHours.split(" ")[0].replace("D", "")
                    );
                    tmpStoreDetail.petaHour = parseInt(
                      tdd.fmPortDaysHours.split(" ")[1].replace("H", "")
                    );
                    tmpStoreDetail.petdDays = parseInt(
                      tdd.atPortDaysHours.split(" ")[0].replace("D", "")
                    );
                    tmpStoreDetail.petdHour = parseInt(
                      tdd.atPortDaysHours.split(" ")[1].replace("H", "")
                    );

                    tmpStoreDetail.etaDays = parseInt(
                      tdd.fmPortDaysHours.split(" ")[0].replace("D", "")
                    );
                    tmpStoreDetail.etaHour = parseInt(
                      tdd.fmPortDaysHours.split(" ")[1].replace("H", "")
                    );
                    tmpStoreDetail.etdDays = parseInt(
                      tdd.atPortDaysHours.split(" ")[0].replace("D", "")
                    );
                    tmpStoreDetail.etdHour = parseInt(
                      tdd.atPortDaysHours.split(" ")[1].replace("H", "")
                    );

                    tmpStoreDetail.pfmh = this.stringDaysHourToNumber(
                      tdd.fmPortHours
                    );
                    tmpStoreDetail.path = this.stringDaysHourToNumber(
                      tdd.atPortHours
                    );
                    tmpStoreDetail.efmh = this.stringDaysHourToNumber(
                      tdd.fmPortHours
                    );
                    tmpStoreDetail.eath = this.stringDaysHourToNumber(
                      tdd.atPortHours
                    );

                    if (tdd.hasTerminal != null) {
                      tmpStoreDetail.hasTerminal = tdd.hasTerminal;
                    }

                    /*if(tmpStoreDetail.seqNo == 1){
                      tmpStoreDetail.peta = this.genericUtil.getPSIDate().replace("-","").replace("-","").replace(":","").replace(":","").substring(0,13);
                      var tDate = this.stringToDateUtil(tmpStoreDetail.peta);
                      tDate.setHours(tDate.getHours() + tmpStoreDetail.path);
                      tmpStoreDetail.petd = this.dateToStringUtil(tDate);
                      tmpStoreDetail.eta = this.genericUtil.getPSIDate().replace("-","").replace("-","").replace(":","").replace(":","").substring(0,13);
                      tDate = this.stringToDateUtil(tmpStoreDetail.eta);
                      tDate.setHours(tDate.getHours() + tmpStoreDetail.eath);
                      tmpStoreDetail.etd = this.dateToStringUtil(tDate);
                    }*/

                    tmpStoreDetail["select"] = "";
                    tmpStoreDetail["no"] = tmpStoreDetail.seqNo;

                    this.gpc.listStore.addData(tmpStoreDetail);
                    this.gpc.loadData();
                  }
                });
              }
            }

            this.add();
            this.modeButton = true;
            this.setDetailData(this.gpc.listStore.store[0], true);
          }
        }
      });

      this.gpc.loadData();
      this.model.serviceId = value;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "copy, copy2";
      }
      this.serviceLock = true;
      this.calLock = false;
    }
  }

  stringDaysHourToNumber(value: string) {
    var stringValue = value.split(" ");
    var hour: number = 0;
    stringValue.forEach((sv) => {
      if (sv.startsWith("D")) {
        hour = hour + parseInt(sv.replace("D", "")) * 24;
      } else if (sv.startsWith("H")) {
        hour = hour + parseInt(sv.replace("H", ""));
      }
    });

    return hour;
  }

  getPC(event) {
    // console.log(event);
    this.modelDetail.portOfCall = event.locationCode;
    this.modelDetail.portOfCallName = event.locationName;
    this.modelDetail["error-portOfCallName"] = "";
    /*if(this.modelDetail.terminalCode != ""){
      this.modelDetail.terminalCode = '';
      this.modelDetail.terminalName = '';
      this.modelDetail.hasTerminal = 'N'
      if(event.hasTerminal != null){
        this.modelDetail.hasTerminal = event.hasTerminal;
      }
      this.tm.setValue("");
    }*/
    if (event.hasTerminal == "Y") {
      this.terminalLock = false;
      this.tm.url =
        this.configService.config.BASE_API.toString() +
        "/MasterTerminals/findTerminalByLocation/" +
        event.locationId +
        "/{query}";
    } else {
      this.terminalLock = true;
      if (this.modelDetail.terminalCode != "") {
        this.modelDetail.terminalCode = "";
        this.modelDetail.terminalName = "";
        this.modelDetail.hasTerminal = "N";

        this.tm.setValue("");
      }
    }
    //this.valPC = event.portNormalizedName;
    //this.pc.setValue(event.portNormalizedName);
  }

  getTml(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.modelDetail.terminalCode = event.locationCode;
      this.modelDetail.terminalName = event.locationName;
    } else {
      this.modelDetail.terminalCode = "";
      this.modelDetail.terminalName = "";
    }
    //this.valTml = event.portNormalizedName;
    //this.tm.setValue(event.portNormalizedName);
  }

  getValue(event) {
    // console.log(event);
  }

  updateConnection(type: string) {
    if (type == "servicePreviousVoyage") {
      this.model.servicePreviousVoyage = $("#prevLoopId").val();
    } else {
      this.model.previousVoyage = $("#prevBaseVoyId").val();
    }
  }

  gridEventCustom(event) {
    // console.log(event);
    switch (event.split("-")[0]) {
      case "dblClick":
        //if(event.split("-")[1] == 1){
        //set combo
        this.add();
        this.modeButton = true;
        console.log("update mode "+this.updateMode);
        if (this.updateMode) {
          this.lock = false;
          this.verticalButtonLock = true;
        } else {
          this.lock = true;
        }
        // console.log(this.gpc.getSelectedValues()[0]);
        this.setDetailData(this.gpc.getSelectedValues()[0], false);
        //}
        break;
      case "select":
        this.gsq.clearSelectedValues();
        break;
      default:
        break;
    }
  }

  gridEventCopy(event) {
    // console.log(event);
  }

  gridEventHistory(event) {
    // console.log(event);
  }

  seqEvent(event) {
    // console.log(event);
    switch (event.split("-")[0]) {
      case "selected":
        break;
      case "selectRadio":
        var no = parseInt(event.split("-")[1]);
        var maxSeq = this.gsq.listStore.store.length;
        this.sequenceSelectedNo = no;
        if (no == 1) {
          $("#moveUp").prop("disabled", true);
          $("#moveDown").prop("disabled", false);
        } else if (no == maxSeq) {
          $("#moveUp").prop("disabled", false);
          $("#moveDown").attr("disabled", true);
        } else {
          $("#moveUp").prop("disabled", false);
          $("#moveDown").attr("disabled", false);
        }
        break;
      default:
        break;
    }
  }

  gridEvent(event) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              this.handleUpdate();
              break;
            case "dblClick":
              //this.loading = true;
              this.setToolbarButtons(
                "Save",
                "Update",
                "doUpdateData",
                true,
                "write"
              );
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "cService";
              }
              this.invisibleToolbarButtons = "create";

              this.modeForm = "schedule"; //true;
              this.modeToolbar = true;
              this.modeViewOnly = true;

              let dt = this.grid.getSelectedValues()[0];
              // console.log(dt);
              this.loadMVSData(dt);

              this.lock = true;
              this.connectionLock = true;
              this.verticalButtonLock = true;
              this.createLock = true;
              this.serviceLock = true;
              this.voyageLock = true;
              this.bsVoyMode = "new";
              break;

            default:
              break;
          }
        }
        break;
    }
  }

  getCalData() {
    // console.log('event');
  }

  toolbarEvent(event) {
    // console.log('event : ' + event);
    switch (event) {
      case "create":
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
        this.loading = false;
        this.handleCreate();
        break;
      case "update":
        this.isUnderUpdateMode = true;
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        this.isError = false;
        this.errorDuplicatePreLoop = "";

        if (
          this.model.servicePreviousVoyage !== "" &&
          this.model.previousVoyage !== ""
        ) {
          this.isError = true;
          this.errorDuplicatePreLoop =
            "Prev Loop No and Prev Base Voyage cannot coexist.";
        } else {

          // todo
         this.checkBookingIfExistOnSave();
         // this.handleSave();

        }
        break;
      case "cancel":
        this.isError = false;
        this.errorDuplicatePreLoop = "";
        this.isUnderUpdateMode = false;
        this.handleCancel();
        break;
      case "copy":
        this.handleCopy();
        break;
      case "copy2":
        this.handleCopy2();
        break;
      case "doUpdateData":
        this.doUpdateData();
        break;
      case "cService":
        this.doChangeService1();
        break;
      case "history":
        this.handleHistory();
        break;
      case "batch":
        this.handleBatch();
        break;
      case "transmit":
        console.log("transmit");
        this.handlePopupTransmit();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }


  handleCreate() {
    // handle create event
    this.modeForm = "schedule"; //true;
    this.modeToolbar = true;
    this.isReadOnlyBound = false;
    this.bsVoyMode = "new";
    $("#cbox").prop("checked", false);
    this.lock = true;
    this.serviceLock = true;
    this.invisibleToolbarButtons = "create";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "delete";
    }
    this.isSuccess = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "copy,copy2,cService";
    }
    this.invisibleToolbarButtons = "";
    this.updateMode = true;

    $("#tPlug").find("#toolbar_o").find("#toolbar_body").css({
      height: "45px",
    });
  }

  handleUpdate() {
    // handle update event
    //this.modeForm = true;
    this.loading = true;
    this.setToolbarButtons("Save", "Update", "doUpdateData", true, "write");
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "cService";
    }
    this.invisibleToolbarButtons = "create";

    this.modeForm = "schedule"; //true;
    this.modeToolbar = true;
    this.modeViewOnly = false;

    let dt = this.grid.getSelectedValues()[0];
    // console.log(dt);
    this.loadMVSData(dt);

    this.updateMode = true;
    this.lock = true;
    this.connectionLock = true;
    this.verticalButtonLock = true;
    this.createLock = true;
    this.serviceLock = true;
  }

  handleDelete() {
    // handle delete event
    //this.loading = true;
    if (this.grid.getSelectedValues().length > 1) {
      this.message(
        "information",
        "Delete Your Master ##",
        "Confirm to delete record?",
        "yesno",
        { yes: "this.deleteEvent2()", no: "this.loading = false;" }
      );
    } else {
      this.message(
        "information",
        "Delete Your Master ##",
        "Confirm to delete record?",
        "yesno",
        { yes: "this.deleteEvent()", no: "this.loading = false;" }
      );
    }
  }

  handlePrint() {
    // handle print event
    var formatName = "report-Vessel-Schedule-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReports/45/Vessel-Schedule-Export-Reports/*/xlsx";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReports/45/Vessel-Schedule-Export-Reports/*/xlsx"
    // );
  }

  setValidatorWithService() {
    this.isError = false;

    this.model['error-vesselName'] = ""
    this.model['error-baseVoyage'] = ""
    this.model['error-servicePreviousVoyage'] = ""
    this.model['error-previousVoyage'] = ""
    this.model['error-psaInVoyage'] = ""
    this.model['error-psaOutVoyage'] = ""
    this.model['error-serviceId'] = ""
    this.model['error-serviceCode'] = ""

    this.validatorRules = {
      vesselName: {
        rules: [
          { type: "empty", prompt: "Please input vessel name!" },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      baseVoyage: {
        rules: [
          { type: "empty", prompt: "Please input base voyage!" },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      servicePreviousVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      previousVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      psaInVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      psaOutVoyage: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      serviceCode: {
        rules: [
          {
            type: 'empty',
            prompt: 'Please input service!'
          }
        ]
      }
    };
  }

  handleSave() {
    // handle save event
    // console.log('handle save event');
    console.log('aktif gak neh : ', this.changeServiceProceed)

    if (this.changeServiceProceed) {
      this.model['ServiceValidator'] = this.setValidatorWithService()
    }

    if (this.modeForm == "schedule") {
      if (!this.modeDetail) {
        let isValid: boolean = this.onValidate(this.model);
        if (!isValid) {
          var hitUrl =
            this.configService.config.BASE_API.toString() +
            "/MasterVesselSchedules/checkDuplicateVoyage/" +
            this.model.vesselId +
            "/" +
            this.sCharUtil.htmlEncode(this.model.baseVoyage) +
            "/" +
            (this.model.servicePreviousVoyage == ""
              ? "*"
              : this.sCharUtil.htmlEncode(
                  this.model.servicePreviousVoyage.toUpperCase()
                )) +
            "/" +
            (this.model.previousVoyage == ""
              ? "*"
              : this.sCharUtil
                  .htmlEncode(this.model.previousVoyage)
                  .toUpperCase());
          this.genericService.GET(hitUrl).subscribe(
            (resp) => {
              if (resp.ok) {
                if (resp.json()["prevLoop-isUsed"] == "Y") {
                  this.message(
                    "information",
                    "Information",
                    "Identical prev loop detected on " +
                      this.model.vesselName +
                      " V." +
                      resp.json()["prevLoop-usedIn"] +
                      ", please check your data.",
                    "okonly",
                    { ok: "" }
                  );
                } else if (resp.json()["prevBase-isUsed"] == "Y") {
                  this.message(
                    "information",
                    "Information",
                    "Identical prev base voyage detected on " +
                      this.model.vesselName +
                      " V." +
                      resp.json()["prevBase-usedIn"] +
                      ", please check your data.",
                    "okonly",
                    { ok: "" }
                  );
                } else if((this.model.serviceNextVoyage != "" && this.model.nextVoyage != "") && (this.model.serviceNextVoyage == this.model.nextVoyage)){

                  this.message(
                    "information",
                    "Information",
                    "Identical detected on next loop voyage and next base voyage ",
                    "okonly",
                    {
                      ok: "",

                    }
                  );

                }else {
                  if (this.preSaveChecking()) {
                    this.message(
                      "information",
                      "Information",
                      "Are you sure want to save the data?",
                      "okcancel",
                      {
                        ok: "this.populateData()",
                        cancel: "// console.log('cancel clicked')",
                      }
                    );
                  }
                }
              }
            },
            (error) => {
              this.loading = false;
              this.message(
                "information",
                "Information",
                "There are some error occur while saving the record. Please contact your administrator!",
                "okonly",
                { ok: "" }
              );
            }
          );
        } else {
          console.log(this.model);
          this.errorSpecialChar = [];
          if (this.model["error-vesselName"] != "") {
            this.errorSpecialChar.push(this.model["error-vesselName"]);
          }
          if (this.model["error-baseVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-baseVoyage"]);
          }
          if (this.model["error-servicePreviousVoyage"] != "") {
            this.errorSpecialChar.push(
              this.model["error-servicePreviousVoyage"]
            );
          }
          if (this.model["error-previousVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-previousVoyage"]);
          }
          if (this.model["error-psaInVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-psaInVoyage"]);
          }
          if (this.model["error-psaOutVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-psaOutVoyage"]);
          }
          if (this.model["error-serviceCode"] != "" && this.model["error-serviceCode"] != null) {
            this.errorSpecialChar.push(this.model["error-serviceCode"]);
          }

          if (this.errorSpecialChar.length > 0) {
            this.isError = true;
            this.isErrorSpcChar = true;
          }
          this.loading = false;
        }
      } else {
        this.message(
          "information",
          "Alert",
          "You need to finished add or update detail first!",
          "okonly",
          { ok: '// console.log("oke clicked")' }
        );
      }
    }

    if (this.modeForm == "batch") {
      // console.log('long store : '+this.batchCopyStore.store.length);
      this.batchCopyStore.store.forEach((bcs) => {
        var dataDetails = new MasterVesselScheduleDetail();
        dataDetails.vesselId = bcs.vesselId;
        dataDetails.vesselCode = bcs.vesselCode;
        dataDetails.voyage = bcs.voyage;
        dataDetails.peta = bcs.eta;
        this.model.MasterVesselScheduleDetails.push(dataDetails);
      });

      // console.log(this.model);
      this.message(
        "information",
        "Information",
        "Confirm to save record(s)?",
        "yesno",
        { yes: "this.handleCopy()", no: "" }
      );
    }
  }

  handleSaveForOrphanBookings() {
    // handle save event
    // console.log('handle save event');
    console.log('aktif gak neh : ', this.changeServiceProceed)

    if (this.changeServiceProceed) {
      this.model['ServiceValidator'] = this.setValidatorWithService()
    }

    if (this.modeForm == "schedule") {
      if (!this.modeDetail) {
        let isValid: boolean = this.onValidate(this.model);
        if (!isValid) {
          var hitUrl =
            this.configService.config.BASE_API.toString() +
            "/MasterVesselSchedules/checkDuplicateVoyage/" +
            this.model.vesselId +
            "/" +
            this.sCharUtil.htmlEncode(this.model.baseVoyage) +
            "/" +
            (this.model.servicePreviousVoyage == ""
              ? "*"
              : this.sCharUtil.htmlEncode(
                  this.model.servicePreviousVoyage.toUpperCase()
                )) +
            "/" +
            (this.model.previousVoyage == ""
              ? "*"
              : this.sCharUtil
                  .htmlEncode(this.model.previousVoyage)
                  .toUpperCase());
          this.genericService.GET(hitUrl).subscribe(
            (resp) => {
              if (resp.ok) {
                if (resp.json()["prevLoop-isUsed"] == "Y") {
                  this.message(
                    "information",
                    "Information",
                    "Identical prev loop detected on " +
                      this.model.vesselName +
                      " V." +
                      resp.json()["prevLoop-usedIn"] +
                      ", please check your data.",
                    "okonly",
                    { ok: "" }
                  );
                } else if (resp.json()["prevBase-isUsed"] == "Y") {
                  this.message(
                    "information",
                    "Information",
                    "Identical prev base voyage detected on " +
                      this.model.vesselName +
                      " V." +
                      resp.json()["prevBase-usedIn"] +
                      ", please check your data.",
                    "okonly",
                    { ok: "" }
                  );
                } else if((this.model.serviceNextVoyage != "" && this.model.nextVoyage != "") && (this.model.serviceNextVoyage == this.model.nextVoyage)){

                  this.message(
                    "information",
                    "Information",
                    "Identical detected on next loop voyage and next base voyage ",
                    "okonly",
                    {
                      ok: "",

                    }
                  );

                }else {
                  if (this.preSaveChecking()) {
                    this.message(
                      "information",
                      "Information",
                      "Are you sure want to save the data?",
                      "okcancel",
                      {
                        ok: "this.populateDataForOrphanedCargoes()",
                        cancel: "// console.log('cancel clicked')",
                      }
                    );
                  }
                }
              }
            },
            (error) => {
              this.loading = false;
              this.message(
                "information",
                "Information",
                "There are some error occur while saving the record. Please contact your administrator!",
                "okonly",
                { ok: "" }
              );
            }
          );
        } else {
          console.log(this.model);
          this.errorSpecialChar = [];
          if (this.model["error-vesselName"] != "") {
            this.errorSpecialChar.push(this.model["error-vesselName"]);
          }
          if (this.model["error-baseVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-baseVoyage"]);
          }
          if (this.model["error-servicePreviousVoyage"] != "") {
            this.errorSpecialChar.push(
              this.model["error-servicePreviousVoyage"]
            );
          }
          if (this.model["error-previousVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-previousVoyage"]);
          }
          if (this.model["error-psaInVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-psaInVoyage"]);
          }
          if (this.model["error-psaOutVoyage"] != "") {
            this.errorSpecialChar.push(this.model["error-psaOutVoyage"]);
          }
          if (this.model["error-serviceCode"] != "" && this.model["error-serviceCode"] != null) {
            this.errorSpecialChar.push(this.model["error-serviceCode"]);
          }

          if (this.errorSpecialChar.length > 0) {
            this.isError = true;
            this.isErrorSpcChar = true;
          }
          this.loading = false;
        }
      } else {
        this.message(
          "information",
          "Alert",
          "You need to finished add or update detail first!",
          "okonly",
          { ok: '// console.log("oke clicked")' }
        );
      }
    }

    if (this.modeForm == "batch") {
      // console.log('long store : '+this.batchCopyStore.store.length);
      this.batchCopyStore.store.forEach((bcs) => {
        var dataDetails = new MasterVesselScheduleDetail();
        dataDetails.vesselId = bcs.vesselId;
        dataDetails.vesselCode = bcs.vesselCode;
        dataDetails.voyage = bcs.voyage;
        dataDetails.peta = bcs.eta;
        this.model.MasterVesselScheduleDetails.push(dataDetails);
      });

      // console.log(this.model);
      this.message(
        "information",
        "Information",
        "Confirm to save record(s)?",
        "yesno",
        { yes: "this.handleCopy()", no: "" }
      );
    }
  }

  prePreSaveChecking() {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "MasterVesselSchedules/checkDuplicateVoyage/" +
      this.model.vesselId +
      "/" +
      this.sCharUtil.htmlEncode(this.model.baseVoyage) +
      "/" +
      this.sCharUtil.htmlEncode(this.model.servicePreviousVoyage) +
      "/" +
      this.sCharUtil.htmlEncode(this.model.previousVoyage);
    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.status == 200) {
        var result = resp.json();
        if (result["prevLoop-isUsed"] == "Y") {
          this.message(
            "information",
            "Information",
            "Identical prev loop detected on " +
              this.model.vesselName +
              " V." +
              result["prevLoop-usedIn"] +
              ", please check your data.",
            "okonly",
            { ok: "" }
          );
        } else if (result["prevBase-isUsed"] == "Y") {
          this.message(
            "information",
            "Information",
            "Identical prev base voyage detected on " +
              this.model.vesselName +
              " V." +
              result["prevLoop-usedIn"] +
              ", please check your data.",
            "okonly",
            { ok: "" }
          );
        } else {
          this.preSaveChecking();
        }
      }
    });
  }

  preSaveChecking() {
    // check
    var result = true;
    var checkCalendarPass: boolean = true;
    var tmpStore = [];
    this.gpc.listStore.store.forEach((ls) => {
      tmpStore.push(ls);
    });

    if (tmpStore.length > 0) {
      tmpStore.forEach((ts) => {
        if (
          ts.peta.replace(" ", "") == "" ||
          ts.petd.replace(" ", "") == "" ||
          ts.eta.replace(" ", "") == "" ||
          ts.etd.replace(" ", "") == ""
        ) {
          checkCalendarPass = false;
        }
      });
    }

    console.log('update mode : ', this.isUnderUpdateMode);

    //if(this.model.vesselIs3rdParty && (this.model.vesselSlotOwner1 == '' && this.model.vesselSlotOwner2 == '' && this.model.vesselSlotOwner3 == '')){
    if (
      this.model.serviceIs3rdParty &&
      this.model.vesselSlotOwner1 == "" &&
      this.model.vesselSlotOwner2 == "" &&
      this.model.vesselSlotOwner3 == ""
    ) {
      result = false;
      this.message(
        "information",
        "Information",
        "Please insert Vessel Slot owner before saving the data!",
        "okonly",
        { ok: "" }
      );
    } else if (!checkCalendarPass) {
      this.message(
        "information",
        "Information",
        "Please complete uncomplete details data first before saving the data!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (
      this.model.servicePreviousVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase() ||
      this.model.serviceNextVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase() ||
      this.model.previousVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase() ||
      this.model.nextVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase()
    ) {
      if (
        this.model.servicePreviousVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Identical previous loop detected, please check your data.",
          "okonly",
          { ok: "" }
        );
      } else if (
        this.model.serviceNextVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Identical next loop detected, please check your data.",
          "okonly",
          { ok: "" }
        );
      } else if (
        this.model.previousVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Identical previous base voyage detected, please check your data.",
          "okonly",
          { ok: "" }
        );
      } else if (
        this.model.nextVoyage.trim().toUpperCase() ==
        this.model.baseVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Identical next base voyage detected, please check your data.",
          "okonly",
          { ok: "" }
        );
      } else if (
        this.model.previousVoyage.trim().toUpperCase() ==
        this.model.nextVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Next base and previous base is identical, please check your data.",
          "okonly",
          { ok: "" }
        );
      } else if (
        this.model.serviceNextVoyage.trim().toUpperCase() ==
        this.model.servicePreviousVoyage.trim().toUpperCase()
      ) {
        this.message(
          "information",
          "Information",
          "Next loop and previous loop is identical, please check your data.",
          "okonly",
          { ok: "" }
        );
      }

      result = false;
    } else if (
      this.model.servicePreviousVoyage.trim().toUpperCase() != "" &&
      this.model.previousVoyage.trim().toUpperCase() != "" &&
      this.model.servicePreviousVoyage.trim().toUpperCase() ==
        this.model.previousVoyage.trim().toUpperCase()
    ) {
      this.message(
        "information",
        "Information",
        "Previous loop and previous base voyage can not be identical.",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (tmpStore.length == 0) {
      this.message(
        "information",
        "Information",
        "Please insert at least 1 port to save the data!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if(this.isUnderUpdateMode) {

      if(this.postParam.length != undefined){
        const dumpArr = this.gpc.listStore.store.filter((item) => {
          return this.postParam.portOfcallList.indexOf(item.portOfCall) > -1;
        }).map((item) => item.portOfCall);

        const portLeft = this.postParam.portOfcallList.filter(item => {
          return dumpArr.indexOf(item) <= -1
        })

        console.log('cek ricek : ', dumpArr, portLeft)

        if (dumpArr.length !== this.postParam.portOfcallList.length) {
          this.message(
            "information",
            "Information",
            `Please insert missing port from the previous schedule ${String(portLeft)}`,
            "okonly",
            { ok: "" }
          );
          result = false;

        }
      }
    } /*else if(this.model.servicePreviousVoyage.length > 8){
      this.message('information','Information','Prev Loop Number length should be less than or equal to 8!','okonly',{ok:''});
      result = false;
    }*/

    return result;
  }

  handleCancel() {
    // handle cancel event
    // console.log('handle cancel event');
    this.modeForm = "flagStatus"; //false;
    this.modeToolbar = false;
    this.modeDetail = false;
    this.modeViewOnly = false;
    this.gpc.listStore.store.splice(0, this.gpc.listStore.store.length);
    this.gsq.listStore.store.splice(0, this.gsq.listStore.store.length);
    this.batchCopyStore.store.splice(0, this.batchCopyStore.store.length);
    this.copyStore.store.splice(0, this.copyStore.store.length);
    this.mvScheduleDetailStore.store.splice(
      0,
      this.mvScheduleDetailStore.store.length
    );
    this.gh.listStore.store.splice(0, this.gh.listStore.store.length);
    $('input[name="gridCount"]').val(0);
    this.detailSeqMode = "-";
    this.gpc.loadData();
    this.gpc.clearSelectedValues();
    this.gsq.loadData();
    this.gsq.clearSelectedValues();
    this.gh.loadData();
    this.setToolbarButtons("Update", "Save", "save", true, "save");
    this.setToolbarButtons(
      "Change Service",
      "Change Service",
      "cService",
      false,
      "exchange"
    );
    this.lock = false;
    this.connectionLock = false;
    this.verticalButtonLock = true;
    this.calLock = true;
    this.serviceLock = false;
    this.createLock = false;
    this.historyLock = true;
    this.updateMode = false;
    $("#retriveBtn").prop("disabled", false);
    this.resetHeaderData();
    this.resetDetailData();
    this.grid.clearSelectedValues();
    this.invisibleToolbarButtons = "cancel";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.retainEta = "";
    this.errorSpecialChar = [];
    this.isErrorSpcChar = false;
    this.loading = false;
    this.pocMap=[];

    this.srv.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterServices/findGeneral/Y/mvSchedule/{query}/1/100/ASC/serviceName"
    );
  }

  handleHistory() {
    this.modeForm = "history";
    this.model = new MasterVesselSchedule();
    this.invisibleToolbarButtons = "create,update,delete,print,history,batch";
  }

  handleBatch() {
    this.modeForm = "batch";
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "copy,cService";
    this.model = new MasterVesselSchedule();
    this.selectList = [];
  }

  saveEvent() {
    //// console.log(this.doNotCalculate);
    //// console.log(this.model);
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() +"/MasterVesselSchedules/save",this.model)
    //this.genericService.POST("http://localhost:10000/MasterVesselSchedules/save",this.model)
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            //clear all
            this.message(
              "information",
              "Information",
              "Record save sucessfully.",
              "okonly",
              { ok: "// console.log('ok click')" }
            );
            this.loading = false;
            this.info = "save successfully";
            this.handleCancel();
            this.grid.onSortDir("dateCreated", "DESC");
            this.grid.onFirst();
            this.isUnderUpdateMode = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "There are some error occur while saving the record, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  saveEventForOrphanedCargoes() {
    //// console.log(this.doNotCalculate);
    //// console.log(this.model);
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() +"/MasterVesselSchedules/save",this.model)
    //this.genericService.POST("http://localhost:10000/MasterVesselSchedules/save",this.model)
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            //clear all
            this.message(
              "information",
              "Information",
              "Record save sucessfully.",
              "okonly",
              { ok: "// console.log('ok click')" }
            );
            this.loading = false;
            this.info = "save successfully";
            this.handleCancel();
            this.grid.onSortDir("dateCreated", "DESC");
            this.grid.onFirst();
            this.isUnderUpdateMode = false;

          const url = this.configService.config.BASE_API.toString() + "/MasterBookings/bookingsToOrphanedFlag";
          this.genericService.POST(url, this.postParam).subscribe((orphanResp) => {
            if (orphanResp.ok) {
              console.log("Orphaning of bookings successful");
            } else {
              console.error("Error while orphaning bookings");
            }
          });
          }
        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "There are some error occur while saving the record, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  deleteEvent2() {
    // console.log("multiple delete function");
    this.grid.loading = true;

    var deleteList = [];
    this.grid.getSelectedValues().forEach((el) => {
      var deletedData = new MasterVesselSchedule();
      deletedData.vesselScheduleId = el.vesselScheduleId;
      deletedData.baseVoyage = el["voyage"];
      deletedData.vesselId = el.vesselId;
      deleteList.push(deletedData);
    });

    // console.log(deleteList);

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterVesselSchedules/delete2",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            var resultList = resp.json()["content"];
            var message = "";
            resultList.forEach((rl) => {
              if (rl["message"] == "SUCCESS") {
                message =
                  message + rl["deletedData"] + " sucessfully deleted<br>";
              } else {
                if (rl["message"].substr(0, 8) == "Deletion") {
                  message =
                    message +
                    "Deletion not allowed for " +
                    rl["deletedData"] +
                    ".<br>There is data which currently being used by Booking/BL.";
                } else {
                  message =
                    message +
                    "There are some error occur while deleting " +
                    rl["deletedData"] +
                    "Please contact your administrator.";
                }
              }
            });

            this.message("information", "Information", message, "okonly", {
              ok: "// console.log('ok click')",
            });
            this.grid.loading = false;
            this.info = "record sucessfully deleted";
            this.grid.clearSelectedValues();
            this.handleCancel();
            this.grid.loadData();
          }
        },
        (error) => {
          //this.loading = false;
          this.grid.loading = false;
          this.message(
            "information",
            "Information",
            "there are some error occur while deleting the record, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  deleteEvent() {
    // console.log('delete event');
    //this.loading = true;
    this.grid.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.vesselScheduleId);
    });

    var deletedData = new MasterVesselSchedule();
    this.grid.getSelectedValues().forEach((element) => {
      deletedData.vesselScheduleId = element.vesselScheduleId;
      deletedData.baseVoyage = element["voyage"];
      deletedData.vesselId = element.vesselId;
      // console.log(element);
    });

    // console.log('count deleted : '+deleteList.length);
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterVesselSchedules/delete",
        deletedData
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            if (resp.json()["status"] == "SUCCESS") {
              this.message(
                "information",
                "Information",
                "Record deleted.",
                "okonly",
                { ok: "// console.log('ok click')" }
              );
              //this.loading = false;
              this.grid.loading = false;
              this.info = "record sucessfully deleted";
              this.grid.clearSelectedValues();
              this.handleCancel();
              this.grid.loadData();
            } else {
              this.message(
                "information",
                "Information",
                resp.json()["message"],
                "okonly",
                { ok: "this.grid.loading=false;" }
              );
            }
          }
        },
        (error) => {
          //this.loading = false;
          this.grid.loading = false;
          this.message(
            "information",
            "Information",
            "there are some error occur while deleting the record, error message : " +
              error.json()["message"] +
              ". Please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  checkHeader() {
    //this.model.voyage = $('input[name="model[baseVoyage]"]').val();
    if (this.model.vesselCode == "" || this.model.baseVoyage == "") {
      this.message(
        "warning",
        "Alert",
        "Please insert vessel name and base Voyage first!",
        "okcancel",
        { ok: '// console.log("ok button clicked")' }
      );
      return false;
    }

    return true;
  }

  addRow() {
    // console.log('this is add row');
    if (this.checkHeader()) {
      if (!this.modeDetail) {
        if (this.gpc.listStore.store.length > 0) {
          if (this.gpc.getSelectedValues().length > 0) {
            var seqList = [];
            this.gpc.getSelectedValues().forEach((sv) => {
              seqList.push(sv.seqNo);
            });
            seqList.sort(function (a, b) {
              return a - b;
            });
            this.before = seqList[0];
            this.after = seqList[seqList.length - 1];
            $("#addRowDialogVessel")
              .modal("setting", "closable", false)
              .modal("show");
          } else {
            //this.add();
          }
        } else {
          //this.add();
        }
      }
    }
  }

  add() {
    // console.log('this is add');
    if (!this.modeDetail) {
      //get sequence
      this.resetDetailData();
      this.lock = false;
      this.connectionLock = true;
      this.verticalButtonLock = true;

      var seq = parseInt($('input[name="gridCount"]').val()) + 1;
      //var mvScheduleDetailModel = new MasterVesselScheduleDetail;
      var mrgin = 33;
      //mvScheduleDetailModel.seqNo = seq;

      //this.modelDetail = mvScheduleDetailModel;
      this.modelDetail = new MasterVesselScheduleDetail();
      this.modelDetail.seqNo = seq;
      $("#petaDays").val(0);
      $("#etaDays").val(0);

      this.modeDetail = true;
      mrgin = mrgin + 4 * mrgin;
      $('input[name="gridCount"]').val(seq);
      //$('html, body').animate({ scrollTop: $("#blabla").offset().top-130 }, 'slow');

      this.genChangeTimeScript("#PETA");
      this.genChangeTimeScript("#ETA");
      this.genDefaultChangeDateTimeScript("#PETD");
    }
  }

  remove() {
    if (!this.modeDetail) {
      if (this.gpc.getSelectedValues().length == 0) {
        this.message(
          "warning",
          "Alert",
          "Please choose at least one record to delete vessel schedule details!",
          "okcancel",
          { ok: '// console.log("ok button clicked")' }
        );
      } else {
        this.message(
          "warning",
          "Confirmation",
          "Are you sure want to remove this record ?",
          "yesno",
          {
            yes: "this.removeProcess()",
            no: '// console.log("button cancel clicked");',
          }
        );
      }
    }
  }

  removeProcess() {

    this.loading = true;
    console.log('masukkk1')
    var nseq = 0;
    var ind2Rem: number;
    var storeTemp = new ListStore();
    var reqBody = {};

    reqBody["vesselId"] = this.model.vesselId;
    reqBody["voyage"] = this.model.baseVoyage;
    reqBody["portOfcallList"] = [];
    this.gpc.getSelectedValues().forEach((el) => {
      reqBody["portOfcallList"].push(el.portOfCall);
      // var delItem = {};
      // delItem["portOfCall"] = el.portOfCall;

      // reqBody.push(delItem);
    });

    if (this.model.vesselScheduleId != "") {
      console.log('masukk 2');
      // var hitUrl =
      //   this.configService.config.BASE_API.toString() +
      //   "/MasterVesselSchedules/checkIfBookingExistByPortOfCall/" +
      //   this.model.vesselScheduleId.trim();
      var hitUrl =
          this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkBookingByPortOfCall";

      console.log(reqBody);

      this.genericService.POST(hitUrl, reqBody).subscribe(
        (resp) => {
          var message = "";
          if (resp.ok) {
            var data = resp.json();
            if(data["status"] == "OK"){
              var ttlBkg = 0;
              console.log(data["result"]);
              if(data["result"] != undefined){
                let i : number = 0;
                this.gpc.getSelectedValues().forEach((el) => {
                  if(parseInt(data["result"][el.portOfCall]) > 0){
                    message =
                      message +
                      "Can not remove port " +
                      el.portOfCall +
                      ", port " +
                      el.portOfCall +
                      " has been used by booking/Bl. <br>";
                  } else {
                    this.gpc.listStore.removeData(el);
                    $('input[name="gridCount"]').val(
                      parseInt($('input[name="gridCount"]').val()) - 1
                    );
                  }
                });
              }
            }
          }

          if (message != "") {
            //this.message("information","Information",message, "okonly",{ok:""});
            $("#myDialog2").modal("show");
            this.messageDialog2 = message;
          }

          this.gpc.listStore.store.forEach((gl) => {
            nseq = nseq + 1;
            gl.seqNo = nseq;
          });

          this.gpc.clearSelectedValues();
          this.gpc.loadData();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    } else {
      var seqNo = 0;
      this.gpc.getSelectedValues().forEach((element) => {
        console.log(element);
        console.log(this.gpc.listStore.store);
        this.gpc.listStore.removeData(element);
        $('input[name="gridCount"]').val(
          parseInt($('input[name="gridCount"]').val()) - 1
        );
      });
      this.gpc.listStore.store.forEach(col=>{
        seqNo = seqNo + 1;
        col.seqNo=seqNo;
      });
      this.gpc.loadData();
      this.gpc.clearSelectedValues();
      console.log(this.gpc.listStore.store);
      this.loading = false;
    }
  }

  detailsProcess(state) {
    if (state == "OK") {
      // console.log('Ok button clicked');
      var addSeqNo = 0;
      var mvScheduleDetailModel = this.getDetailData();
      mvScheduleDetailModel["select"] = "";
      mvScheduleDetailModel["no"] = mvScheduleDetailModel.seqNo;
      // console.log("## add" + mvScheduleDetailModel.seqNo);

      if (this.detailsValidation(mvScheduleDetailModel)) {
        if (this.detailSeqMode == "" || this.detailSeqMode == "-") {
          this.gpc.listStore.addData(mvScheduleDetailModel);
        } else {
          var tmpStore = new ListStore();
          var newSeq = 0;
          this.gpc.listStore.store.forEach((ls) => {
            tmpStore.addData(ls);
          });
          this.gpc.listStore.store.splice(0, this.gpc.listStore.store.length);
          tmpStore.store.forEach((ts) => {
            // console.log('tsseq : '+ts.seqNo);
            if (ts.seqNo == parseInt(this.detailSeqMode.split("-")[1])) {
              if (this.detailSeqMode.split("-")[0] == "before") {
                newSeq = newSeq + 1;
                mvScheduleDetailModel.seqNo = newSeq;
                mvScheduleDetailModel["no"] = mvScheduleDetailModel.seqNo;
                addSeqNo = mvScheduleDetailModel.seqNo;
                // console.log('before 1 : ' +newSeq);
                this.gpc.listStore.addData(mvScheduleDetailModel);
                newSeq = newSeq + 1;
                ts.seqNo = newSeq;
                ts["no"] = ts.seqNo;
                // console.log('before 2 : '+newSeq);
                this.gpc.listStore.addData(ts);
              } else if (this.detailSeqMode.split("-")[0] == "after") {
                newSeq = newSeq + 1;
                ts.seqNo = newSeq;
                ts["no"] = ts.seq;
                this.gpc.listStore.addData(ts);
                newSeq = newSeq + 1;
                mvScheduleDetailModel.seqNo = newSeq;
                mvScheduleDetailModel["no"] = mvScheduleDetailModel.seqNo;
                addSeqNo = mvScheduleDetailModel.seqNo;
                this.gpc.listStore.addData(mvScheduleDetailModel);
              }
            } else {
              newSeq = newSeq + 1;
              ts.seqNo = newSeq;
              ts["no"] = ts.seqNo;
              // console.log('tsseq else : '+newSeq);
              this.gpc.listStore.addData(ts);
            }
          });
        }

        if (addSeqNo != 0) {
          if (addSeqNo == 1) {
            if (this.model.previousVoyage != "") {
              if (this.doNotCalculate) {
                this.updateDetailsData(mvScheduleDetailModel, addSeqNo);
                //this.updateDetailsData(mvScheduleDetailModel, 0);
              } else {
                this.updateAllDetailsData(
                  this.model.previousVoyage,
                  mvScheduleDetailModel
                );
              }
            } else {
              if (this.model.servicePreviousVoyage != "") {
                this.updateAllDetailsData(
                  this.model.servicePreviousVoyage,
                  mvScheduleDetailModel
                );
              } else {
                this.updateDetailsData(mvScheduleDetailModel, addSeqNo);
                //this.updateDetailsData(mvScheduleDetailModel, 1);
              }
            }
            /*if(this.doNotCalculate){
              this.updateDetailsData(mvScheduleDetailModel, addSeqNo);
            } else {*/

            /*}*/
          } else {
            //this.updateDetailsData(mvScheduleDetailModel, addSeqNo);
            this.updateDetailsData(this.gpc.listStore.store[0], 1);
          }
        }

        this.modeDetail = false;
        this.gpc.clearSelectedValues();
        this.gpc.loadData();
        this.detailSeqMode = "";
        this.resetDetailData();
        //this.poc.setValue("");
      }
    } else if (state == "CANCEL") {
      // console.log('cancel button clicked');
      this.modelDetail = new MasterVesselScheduleDetail();
      this.modeButton = false;
      var seq = parseInt($('input[name="gridCount"]').val());
      $('input[name="gridCount"]').val(seq - 1);
      this.modeDetail = false;
      this.gpc.clearSelectedValues();
      //this.serviceLock = true;
      this.detailSeqMode = "-";
      this.resetDetailData();
      //this.poc.setValue("");
    } else if (state == "UPDATE") {
      // console.log('update button clicked');

      var mvScheduleDetailModel = this.getDetailData();
      mvScheduleDetailModel["select"] = "";
      mvScheduleDetailModel["no"] = mvScheduleDetailModel.seqNo;
      // console.log("nojan update detail");
      // console.log(mvScheduleDetailModel);

      if (this.detailsValidation(mvScheduleDetailModel)) {
        this.gpc.listStore.store.forEach((wiperman) => {
          if (wiperman.seqNo == mvScheduleDetailModel.seqNo) {
            if (
              wiperman.portOfCall != this.modelDetail.portOfCall ||
              wiperman.terminalCode != this.modelDetail.terminalCode ||
              wiperman.callReason != this.modelDetail.callReason
            ) {
              // console.log("change detected");
              if (this.model.bookAffectedDetails.length > 0) {
                this.model.bookAffectedDetails.forEach((fe) => {
                  if (fe["portOfCall"] == wiperman.portOfCall) {
                    if (parseInt(fe["total"]) > 0) {
                      // console.log("booking existence detected");
                      this.oldDetailsData.store.push(wiperman);
                    }
                  }
                });
              }
            }

            this.gpc.listStore.updateData(
              this.gpc.listStore.store.indexOf(wiperman),
              mvScheduleDetailModel
            );
          }
        });

        var seq = parseInt($('input[name="gridCount"]').val());
        $('input[name="gridCount"]').val(seq - 1);
        if (this.model.previousVoyage != "") {
          if (this.doNotCalculate) {
            //this.updateDetailsData(mvScheduleDetailModel,1);
            //this.updateDetailsData(mvScheduleDetailModel, mvScheduleDetailModel.seqNo);
            this.updateDetailsData(this.gpc.listStore.store[0], 1);
          } else {
            this.updateAllDetailsData(
              this.model.previousVoyage,
              mvScheduleDetailModel
            );
          }
        } else {
          if (this.model.servicePreviousVoyage != "") {
            this.updateAllDetailsData(
              this.model.servicePreviousVoyage,
              mvScheduleDetailModel
            );
          } else {
            //this.updateDetailsData(mvScheduleDetailModel,1);
            //this.updateDetailsData(mvScheduleDetailModel,mvScheduleDetailModel.seqNo);
            this.updateDetailsData(this.gpc.listStore.store[0], 1);
          }
        }

        /*if(this.doNotCalculate){
          this.updateDetailsData(mvScheduleDetailModel,1);
        } else {*/

        /*}*/

        this.modeButton = false;
        this.modeDetail = false;
        this.gpc.clearSelectedValues();
        this.gpc.loadData();
        this.gsq.loadData();
        //this.serviceLock = true;
        this.resetDetailData();
        //this.poc.setValue("");
      }
    }

    //$('html, body').animate({ scrollTop: $("#blezak").offset().top-170 }, 'slow');
  }

  reCountDateTimeData(data) {
    var patHour = data.path;
    var eatHour = data.eath;

    /* Count PETD*/
    var date = this.stringToDateUtil(data.peta);
    date.setHours(date.getHours() + patHour);
    data.petd = this.dateToStringUtil(date);

    date = this.stringToDateUtil(data.eta);
    date.setHours(date.getHours() + eatHour);
    data.etd = this.dateToStringUtil(date);

    return data;
  }

  updateAllDetailsData(prevVoyage, dataDetail) {
    var hitUrl = "";
    var detailsData = new MasterVesselScheduleDetail();
    var detailsTemp = [];
    var detailsLength = 0;

    // console.log(dataDetail);
    //var hiturl = this.configService.config.BASE_API.toString() + '/MasterVesselSchedules/findGeneral/Y/*/'+this.model.vesselCode.trim()+'*'+prevVoyage.trim()+'/1/1/*/*';
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findVesselScheduleData/" +
      this.model.vesselCode.trim() +
      "/" +
      this.sCharUtil.htmlEncode(prevVoyage.trim());
    this.loading = true;
    this.genericService.GET(hiturl).subscribe(
      (resp) => {
        if (resp.ok) {
          if (parseInt(resp.json()["size"]) > 0) {
            var check = resp.json()["content"][0]["vesselScheduleId"];
            // console.log('check '+check);
            hiturl =
              this.configService.config.BASE_API.toString() +
              "/MasterVesselSchedules/findById/" +
              check.trim();
            this.genericService.GET(hiturl).subscribe(
              (resp) => {
                if (resp.ok) {
                  detailsTemp = resp.json()["masterVesselScheduleDetails"];
                  detailsLength = detailsTemp.length;
                  // console.log("date p = "+detailsTemp[detailsLength-1].petd);
                  detailsData.petd =
                    this.dateUtilConvert(
                      detailsTemp[detailsLength - 1].petd,
                      "date"
                    ) +
                    " " +
                    this.dateUtilConvert(
                      detailsTemp[detailsLength - 1].petd,
                      "time"
                    );
                  // console.log("petd = "+detailsData.petd);
                  detailsData.etd =
                    this.dateUtilConvert(
                      detailsTemp[detailsLength - 1].etd,
                      "date"
                    ) +
                    " " +
                    this.dateUtilConvert(
                      detailsTemp[detailsLength - 1].etd,
                      "time"
                    );
                  // console.log("etd = "+detailsData.etd);
                  this.updateDetailsData(detailsData, 0);
                  this.loading = false;
                }
              },
              (error) => {
                if (dataDetail != null) {
                  this.updateDetailsData(dataDetail, dataDetail.seqNo);
                }
                //this.updateDetailsData(dataDetail,dataDetail.seqNo);
                this.loading = false;
              }
            );
          } else {
            if (dataDetail != null) {
              this.updateDetailsData(dataDetail, dataDetail.seqNo);
            }
            this.loading = false;
          }
        }
      },
      (error) => {
        if (dataDetail != null) {
          this.updateDetailsData(dataDetail, dataDetail.seqNo);
        }
        //this.updateDetailsData(dataDetail,dataDetail.seqNo);
        this.loading = false;
      }
    );
  }

  updateDetailsData(dataDetail, topSeqNo: number) {
    var petdDetails = dataDetail.petd;
    var etdDetails = dataDetail.etd;

    this.gpc.listStore.store.forEach((gls) => {
      if (gls.seqNo > topSeqNo) {
        // console.log('#1 '+ petdDetails);
        var petdDate = this.stringToDateUtil(petdDetails);
        petdDate.setHours(petdDate.getHours() + gls.pfmh);
        // console.log('#2 '+ gls.pfmh);
        var pday = (petdDate.getDate() < 10 ? "0" : "") + petdDate.getDate();
        var pmonth =
          (Number(petdDate.getMonth()) + 1 < 10 ? "0" : "") +
          (Number(petdDate.getMonth()) + 1);
        var pyear =
          (petdDate.getFullYear() < 10 ? "0" : "") + petdDate.getFullYear();
        var phour = (petdDate.getHours() < 10 ? "0" : "") + petdDate.getHours();
        var pminute =
          (petdDate.getMinutes() < 10 ? "0" : "") + petdDate.getMinutes();
        gls.peta = pyear + pmonth + pday + " " + phour + pminute;
        // console.log('#3 '+ gls.peta);

        // console.log('#11 '+ gls.peta);
        petdDate = this.stringToDateUtil(gls.peta);
        petdDate.setHours(petdDate.getHours() + gls.path);
        // console.log('#21 '+ gls.path);
        pday = (petdDate.getDate() < 10 ? "0" : "") + petdDate.getDate();
        pmonth =
          (Number(petdDate.getMonth()) + 1 < 10 ? "0" : "") +
          (Number(petdDate.getMonth()) + 1);
        pyear =
          (petdDate.getFullYear() < 10 ? "0" : "") + petdDate.getFullYear();
        phour = (petdDate.getHours() < 10 ? "0" : "") + petdDate.getHours();
        pminute =
          (petdDate.getMinutes() < 10 ? "0" : "") + petdDate.getMinutes();
        gls.petd = pyear + pmonth + pday + " " + phour + pminute;
        // console.log('#31 '+ gls.petd);

        // console.log('#1 '+ etdDetails);
        var etdDate = this.stringToDateUtil(etdDetails);
        etdDate.setHours(etdDate.getHours() + gls.efmh);
        // console.log('#2 '+ gls.efmh);
        var eday = (etdDate.getDate() < 10 ? "0" : "") + etdDate.getDate();
        var emonth =
          (Number(etdDate.getMonth()) + 1 < 10 ? "0" : "") +
          (Number(etdDate.getMonth()) + 1);
        var eyear =
          (etdDate.getFullYear() < 10 ? "0" : "") + etdDate.getFullYear();
        var ehour = (etdDate.getHours() < 10 ? "0" : "") + etdDate.getHours();
        var eminute =
          (etdDate.getMinutes() < 10 ? "0" : "") + etdDate.getMinutes();
        gls.eta = eyear + emonth + eday + " " + ehour + eminute;
        // console.log('#3 '+ gls.eta);

        etdDate = this.stringToDateUtil(gls.eta);
        etdDate.setHours(etdDate.getHours() + gls.eath);
        eday = (etdDate.getDate() < 10 ? "0" : "") + etdDate.getDate();
        emonth =
          (Number(etdDate.getMonth()) + 1 < 10 ? "0" : "") +
          (Number(etdDate.getMonth()) + 1);
        eyear = (etdDate.getFullYear() < 10 ? "0" : "") + etdDate.getFullYear();
        ehour = (etdDate.getHours() < 10 ? "0" : "") + etdDate.getHours();
        eminute = (etdDate.getMinutes() < 10 ? "0" : "") + etdDate.getMinutes();
        gls.etd = eyear + emonth + eday + " " + ehour + eminute;

        petdDetails = gls.petd;
        etdDetails = gls.etd;
      }
    });
  }

  retrieveDetailData(seqNo) {
    this.mvScheduleDetailStore.store.forEach((fe) => {
      if (fe.seqNo == seqNo) {
        var detailData = new MasterVesselScheduleDetail();
        detailData = fe;
        //this.valPC = detailData.portOfCallName;
        //this.valTml = detailData.terminalName;
        //this.valPOC = detailData.purpose;
      }
    });
  }

  setDetailData(detailData, defaultDate: boolean) {
    // console.log('terminal '+detailData.hasTerminal);
    this.modelDetail = new MasterVesselScheduleDetail();
    this.modelDetail.seqNo = detailData.seqNo;
    // BASE_API + '/MasterLocations/findGeneral/*/*/{query}/1/25/*/*'
    this.pc.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/locationCode/{query}/1/25/*/*"
    );
    this.pc.setValue(detailData.portOfCall);
    this.modelDetail.portOfCall = detailData.portOfCall; //$('input[name="model[detail][portcode]"]').val().toUpperCase();
    this.modelDetail.portOfCallName = detailData.portOfCallName; //$('input[name="model[detail][portofcall]"]').val().toUpperCase();
    this.modelDetail.portCode = detailData.portCode;

    if (detailData.hasTerminal != null) {
      if (detailData.hasTerminal == "Y") {
        this.terminalLock = false;
        //this.tm.url = BASE_API + '/MasterTerminals/findTerminalByLocation/'+detailData.portCode+'/{query}';
        (this.tm.url =
          this.configService.config.BASE_API.toString() +
          "/MasterLocations/findGeneral/*/*/{query}/1/25/*/*"),
          (this.modelDetail.hasTerminal = detailData.hasTerminal);
      } else {
        this.terminalLock = true;
      }
    } else {
      this.terminalLock = true;
    }

    this.tm.setValue(
      detailData.terminalCode == "ZZZZZ" ? "" : detailData.terminalCode
    );
    this.modelDetail.terminalCode =
      detailData.terminalCode == "ZZZZZ" ? "" : detailData.terminalCode; //$('input[name="model[detail][terminalcode]"]').val().toUpperCase();
    this.modelDetail.terminalName =
      detailData.terminalCode == "ZZZZZ" ? "" : detailData.terminalName;
    //this.poc.setValue(detailData.callReason);
    $("#purposeoc").dropdown("set selected", detailData.callReason);
    this.modelDetail.callReason = detailData.callReason;
    this.settingCalendarPeta.addHour = detailData.path.toString();
    this.settingCalendarPeta.addHourETA = detailData.eath.toString();
    this.generateCalendar(
      "kancil",
      this.settingCalendarPeta,
      this.stringToDateUtil(detailData.peta)
    );
    $("#PETA").find("#date").val(detailData.peta.split(" ")[0]);
    $("#PETA").find("#time").val(detailData.peta.split(" ")[1]);
    this.modelDetail.petaDays = detailData.petaDays;
    this.modelDetail.petaHour = detailData.petaHour;
    this.generateCalendar(
      "petdTime",
      this.settingCalendarPetd,
      this.stringToDateUtil(detailData.petd)
    );
    $("#PETD").find("#date").val(detailData.petd.split(" ")[0]);
    $("#PETD").find("#time").val(detailData.petd.split(" ")[1]);
    this.modelDetail.petdDays = detailData.petdDays;
    this.modelDetail.petdHour = detailData.petdHour;
    this.settingCalendarEta.addHour = detailData.eath.toString();
    this.generateCalendar(
      "etaTime",
      this.settingCalendarEta,
      this.stringToDateUtil(detailData.eta)
    );
    $("#ETA").find("#date").val(detailData.eta.split(" ")[0]);
    $("#ETA").find("#time").val(detailData.eta.split(" ")[1]);
    this.modelDetail.etaDays = detailData.etaDays;
    this.modelDetail.etaHour = detailData.etaHour;
    this.generateCalendar(
      "etdTime",
      this.settingCalendarEtd,
      this.stringToDateUtil(detailData.etd)
    );
    $("#ETD").find("#date").val(detailData.etd.split(" ")[0]);
    $("#ETD").find("#time").val(detailData.etd.split(" ")[1]);
    this.modelDetail.etdDays = detailData.etdDays;
    this.modelDetail.etdHour = detailData.etdHour;
    $("#ATA").find("#date").val(detailData.ata.split(" ")[0]);
    $("#ATA").find("#time").val(detailData.ata.split(" ")[1]);
    $("#ATD").find("#date").val(detailData.atd.split(" ")[0]);
    $("#ATD").find("#time").val(detailData.atd.split(" ")[1]);

    this.modelDetail.term1 = detailData.term1;
    this.modelDetail.term2 = detailData.term2;
    this.modelDetail.term3 = detailData.term3;
    this.modelDetail.term4 = detailData.term4;
    this.modelDetail.term5 = detailData.term5;

    this.modelDetail.efmh = detailData.efmh;
    this.modelDetail.eath = detailData.eath;
    this.modelDetail.pfmh = detailData.pfmh;
    this.modelDetail.path = detailData.path;

    this.modelDetail.callIndicator = detailData.callIndicator;
    this.modelDetail.inIndicator = detailData.inIndicator;
    this.modelDetail.outIndicator = detailData.outIndicator;
    this.pc.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneral/*/*/{query}/1/25/*/*"
    );

    if (defaultDate) {
      if (this.retainEta != "") {
        this.modelDetail.peta = this.retainEta;
        this.modelDetail.eta = this.retainEta;
      } else {
        this.modelDetail.peta = this.genericUtil
          .getPSIDate()
          .replace("-", "")
          .replace("-", "")
          .replace(":", "")
          .replace(":", "")
          .substring(0, 13);
        this.modelDetail.eta = this.genericUtil
          .getPSIDate()
          .replace("-", "")
          .replace("-", "")
          .replace(":", "")
          .replace(":", "")
          .substring(0, 13);
      }
      var tDate = this.stringToDateUtil(this.modelDetail.peta);
      tDate.setHours(tDate.getHours() + this.modelDetail.path);
      this.modelDetail.petd = this.dateToStringUtil(tDate);
      //this.modelDetail.eta = this.genericUtil.getPSIDate().replace("-","").replace("-","").replace(":","").replace(":","").substring(0,13);
      tDate = this.stringToDateUtil(this.modelDetail.eta);
      tDate.setHours(tDate.getHours() + this.modelDetail.eath);
      this.modelDetail.etd = this.dateToStringUtil(tDate);

      $("#PETA").find("#date").val(this.modelDetail.peta.split(" ")[0]);
      $("#PETA").find("#time").val(this.modelDetail.peta.split(" ")[1]);
      $("#PETD").find("#date").val(this.modelDetail.petd.split(" ")[0]);
      $("#PETD").find("#time").val(this.modelDetail.petd.split(" ")[1]);
      $("#ETA").find("#date").val(this.modelDetail.eta.split(" ")[0]);
      $("#ETA").find("#time").val(this.modelDetail.eta.split(" ")[1]);
      $("#ETD").find("#date").val(this.modelDetail.etd.split(" ")[0]);
      $("#ETD").find("#time").val(this.modelDetail.etd.split(" ")[1]);
    }
  }

  getDetailData() {
    var md = new MasterVesselScheduleDetail();
    var dt = new Date();
    md.seqNo = $('input[name="model[detail][seq]"]').val();
    md.portOfCall = this.modelDetail.portOfCall; //$('input[name="model[detail][portcode]"]').val().toUpperCase();
    md.portOfCallName = this.modelDetail.portOfCallName; //$('input[name="model[detail][portofcall]"]').val().toUpperCase();
    md.terminalCode = this.modelDetail.terminalCode; //$('input[name="model[detail][terminalcode]"]').val().toUpperCase();
    md.terminalName = this.modelDetail.terminalName; //$('input[name="model[detail][terminal]"]').val().toUpperCase();
    this.modelDetail.callReason = $("#purposeoc").dropdown("get value");
    md.callReason = this.modelDetail.callReason;
    md.peta =
      $("#PETA").find("#date").val() + " " + $("#PETA").find("#time").val();
    md.petaDays = this.modelDetail.petaDays; //parseInt($('#petaDays').val());
    md.petaHour = this.modelDetail.petaHour; //parseInt($('#petaHour').val());
    md.petd =
      $("#PETD").find("#date").val() + " " + $("#PETD").find("#time").val();
    md.petdDays = this.modelDetail.petdDays; //parseInt($('#petdDays').val());
    md.petdHour = this.modelDetail.petdHour; //parseInt($('#petdHour').val());
    md.eta =
      $("#ETA").find("#date").val() + " " + $("#ETA").find("#time").val();
    md.etaDays = this.modelDetail.etaDays; //parseInt($('#etaDays').val());
    md.etaHour = this.modelDetail.etaHour; //parseInt($('#etaHour').val());
    md.etd =
      $("#ETD").find("#date").val() + " " + $("#ETD").find("#time").val();
    md.etdDays = this.modelDetail.etdDays; //parseInt($('#etdDays').val());
    md.etdHour = this.modelDetail.etdHour; //parseInt($('#etdHour').val());
    md.ata =
      $("#ATA").find("#date").val() + " " + $("#ATA").find("#time").val();
    if (md.ata == " ") {
      md.ata = "";
    }
    md.atd =
      $("#ATD").find("#date").val() + " " + $("#ATD").find("#time").val();
    if (md.atd == " ") {
      md.atd = "";
    }

    md.term1 = this.modelDetail.term1;
    md.term2 = this.modelDetail.term2;
    md.term3 = this.modelDetail.term3;
    md.term4 = this.modelDetail.term4;
    md.term5 = this.modelDetail.term5;

    md.pfmh =
      this.modelDetail.petaDays * 24 +
      parseInt(this.modelDetail.petaHour.toString());
    md.path =
      this.modelDetail.petdDays * 24 +
      parseInt(this.modelDetail.petdHour.toString());
    md.efmh =
      this.modelDetail.etaDays * 24 +
      parseInt(this.modelDetail.etaHour.toString());
    md.eath =
      this.modelDetail.etdDays * 24 +
      parseInt(this.modelDetail.etdHour.toString());

    md.callIndicator = this.modelDetail.callIndicator;
    md.inIndicator = this.modelDetail.inIndicator;
    md.outIndicator = this.modelDetail.outIndicator;
    md.hasTerminal = this.modelDetail.hasTerminal;
    md.portCode = this.modelDetail.portCode;

    md = this.reCountDateTimeData(md);

    // console.log(md);
    return md;
  }

  resetDetailData() {
    this.modelDetail = new MasterVesselScheduleDetail();
    //this.valPC = '';//$('input[name="model[detail][portcode]"]').val("");
    this.pc.setValue("");
    $('input[name="model[detail][portofcall]"]').val("");
    //this.valTml = '';//$('input[name="model[detail][terminalcode]"]').val("");
    this.tm.setValue("");
    $('input[name="model[detail][terminal]"]').val("");
    //this.valPOC = '';//$('input[name="model[detail][poc]"]').val("");

    //$('#poc').dropdown('restore default text');
    //this.poc.setValue("");
    $("#purposeoc").dropdown("clear");
    $("#PETA").find("#date").val("");
    $("#PETA").find("#time").val("");
    //$('#petaDays').val(0);
    //$('#petaHour').val(0);
    $("#PETD").find("#date").val("");
    $("#PETD").find("#time").val("");
    //$('#petdDays').val(0);
    //$('#petdHour').val(0);

    $("#ETA").find("#date").val("");
    $("#ETA").find("#time").val("");
    //$('#etaDays').val(0);
    //$('#etaHour').val(0);
    $("#ETD").find("#date").val("");
    $("#ETD").find("#time").val("");
    //$('#etdDays').val(0);
    //$('#etdHour').val(0);

    $("#ATA").find("#date").val("");
    $("#ATA").find("#time").val("");
    $("#ATD").find("#date").val("");
    $("#ATD").find("#time").val("");
    this.terminalLock = true;
    this.lock = true;
    this.connectionLock = false;
    this.verticalButtonLock = false;
  }

  retrieveData() {
    // console.log('hip hip horee');
    //this.model.voyage = $('input[name="model[baseVoyage]"]').val();
    //if (!this.onValidate(this.model)) {
    this.loading = true;
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/Y/*/" +
      this.model.vesselCode.trim() +
      "*" +
      this.sCharUtil.htmlEncode(this.model.baseVoyage.trim()) +
      "/1/25/*/*";
    // console.log(hiturl);
    var isExist = false;
    this.genericService.GET(hiturl).subscribe((resp) => {
      if (resp.ok) {
        // console.log(resp.json());
        var tempData = resp.json()["content"];
        var dataRetrieve: any;
        if (parseInt(resp.json()["size"]) > 0) {
          // console.log('#1 data exist');
          tempData.forEach((td) => {
            // console.log('#2 check vessel exist');
            if (
              td.vesselCode.trim() == this.model.vesselCode.trim() &&
              td.voyage.trim() == this.model.baseVoyage.trim()
            ) {
              isExist = true;
              dataRetrieve = td;
            }
          });

          // console.log('#3 isexist '+isExist);
          if (isExist) {
            this.setToolbarButtons(
              "Save",
              "Update",
              "doUpdateData",
              true,
              "write"
            );
            this.loadMVSData(dataRetrieve);
            if (this.viewOnlyStatus) {
              this.disableToolbarButtons = "create,update,delete";
            } else {
              this.disableToolbarButtons = "cService";
            }
            this.createLock = true;
            this.lock = true;
            this.verticalButtonLock = false;
            this.switchCombo(false);
            $("#cbox").prop("checked", false);
          } else {
            this.loading = false;
            this.serviceLock = false;
            this.lock = true;
            this.verticalButtonLock = true;
            this.createLock = false;
          }
        } else {
          this.loading = false;
          this.serviceLock = false;
          this.lock = true;
          this.verticalButtonLock = true;
          this.createLock = false;
        }
      }
    });
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/Y/distinctBaseVoyage/" +
      (this.model.vesselCode != null ? this.model.vesselCode.trim() : "*") +
      "/1/100/*/*";
    //}
  }

  /*modeCal : true,
type : 'datetime',
returnText : 'year-month-day', // format 'text-text-text' (text = year/month/day) separated by "-""
returnTextTime : 'hour-minute',
separator : '', // date separator between day, month and year.
AltTimeId : '#kancil', // default : ''
hour12 : false*/

  setToolbarButtons(editButton, editTo, eventEditTo, enableStatEditTo, icon) {
    this.settingToolbar.buttonsDetail.forEach((ds) => {
      if (ds.name == editButton) {
        ds.name = editTo;
        ds.event = eventEditTo;
        ds.icon = icon;
        ds.enabled = enableStatEditTo;
      }
    });
  }

  generateCalendar(id, calSettings, initDate) {
    //// console.log('calendar init : '+initDate);
    $("#" + id + "-cc")
      .find(".ui.calendar")
      .calendar({
        type: "datetime",
        monthFirst: false,
        ampm: calSettings.hour12,
        initialDate: initDate,
        text: {
          returnText: calSettings.returnText,
          returnTextTime: calSettings.returnTextTime,
          returnSeparator: calSettings.separator,
          altid: id,
          altTime: true,
          callId: calSettings.calId,
          addHour: calSettings.addHour,
          addHourETA: calSettings.addHourETA,
        },
        formatter: {
          datetime: function (date, settings) {
            if (!date) return "";
            var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();
            var hour = date.getHours();
            var minute = date.getMinutes();
            var second = date.getSeconds();
            var rtn =
              month + "/" + day + "/" + year + " " + hour + ":" + minute;
            if (settings.text.returnText != "") {
              rtn = "";
              var i = settings.text.returnText.split("-").length;
              //// console.log(i);
              for (var z = 0; z < i; z++) {
                switch (settings.text.returnText.split("-")[z]) {
                  case "day":
                    rtn = rtn + (day < 10 ? "0" + day : day);
                    break;
                  case "month":
                    rtn =
                      rtn + (month + 1 < 10 ? "0" + (month + 1) : month + 1);
                    break;
                  case "year":
                    rtn = rtn + year;
                    break;
                  default:
                    break;
                }
                if (z != i - 1) {
                  rtn = rtn + settings.text.returnSeparator;
                }
                //// console.log(rtn);
              }
              if (!settings.text.altTime) {
                i = settings.text.returnTextTime.split("-").length;
                rtn = rtn + " ";
                for (z = 0; z < i; z++) {
                  switch (settings.text.returnTextTime.split("-")[z]) {
                    case "hour":
                      if (settings.ampm && hour >= 12)
                        rtn = rtn + (hour == 12 ? hour : hour - 12);
                      else if (settings.ampm && hour < 12)
                        rtn = rtn + (hour == 0 ? 12 : hour);
                      else rtn = rtn + (hour < 10 ? "0" + hour : hour);
                      break;
                    case "minute":
                      rtn = rtn + (minute < 10 ? "0" + minute : minute);
                      if (settings.ampm && hour >= 12) rtn = rtn + " PM";
                      else if (settings.ampm && hour < 12) rtn = rtn + " AM";
                      break;
                    default:
                      break;
                  }
                  if (z != i - 1) {
                    rtn = rtn + ":";
                  }
                  //// console.log(rtn);
                }
              } else {
                // console.log('noyan : '+settings.text.altid);
                var timeReturn =
                  (hour < 10 ? "0" : "") +
                  hour +
                  (minute < 10 ? "0" : "") +
                  minute;
                if (settings.ampm) {
                  timeReturn = (hour == 0 ? 12 : hour) + ":" + minute + " AM";
                  if (hour >= 12) {
                    timeReturn =
                      (hour == 12 ? hour : hour - 12) + ":" + minute + " PM";
                  }
                }
                //// console.log(settings.text.altid);
                //$(settings.text.altid).val(timeReturn);
                $("#" + settings.text.altid + "-cc")
                  .find("#time")
                  .val(timeReturn);

                //if(settings.text.addHour != "0"){
                if (settings.text.callId != "") {
                  var rDate = new Date(year, month, day, hour, minute, second);
                  var tHour =
                    parseInt($("#petdDays").val()) * 24 +
                    parseInt($("#petdHour").val());
                  if (settings.text.callId == "#ETD") {
                    tHour =
                      parseInt($("#etdDays").val()) * 24 +
                      parseInt($("#etdHour").val());
                  }
                  // console.log('tHour '+tHour);
                  rDate.setHours(rDate.getHours() + tHour);

                  var rday =
                    (rDate.getDate() < 10 ? "0" : "") + rDate.getDate();
                  var rmonth =
                    (Number(rDate.getMonth()) + 1 < 10 ? "0" : "") +
                    (Number(rDate.getMonth()) + 1);
                  var ryear =
                    (rDate.getFullYear() < 10 ? "0" : "") + rDate.getFullYear();
                  var rhour =
                    (rDate.getHours() < 10 ? "0" : "") + rDate.getHours();
                  var rminute =
                    (rDate.getMinutes() < 10 ? "0" : "") + rDate.getMinutes();

                  // console.log(ryear + rmonth + rday + " " + rhour + rminute);
                  $(settings.text.callId)
                    .find("#date")
                    .val(ryear + rmonth + rday);
                  $(settings.text.callId)
                    .find("#time")
                    .val(rhour + rminute);

                  if (settings.text.callId == "#PETD") {
                    $("#ETA").find("#date").val(rtn);
                    $("#ETA").find("#time").val(timeReturn);

                    var rDate = new Date(
                      year,
                      month,
                      day,
                      hour,
                      minute,
                      second
                    );
                    var tHour =
                      parseInt($("#etdDays").val()) * 24 +
                      parseInt($("#etdHour").val());
                    rDate.setHours(rDate.getHours() + tHour);

                    var rday =
                      (rDate.getDate() < 10 ? "0" : "") + rDate.getDate();
                    var rmonth =
                      (Number(rDate.getMonth()) + 1 < 10 ? "0" : "") +
                      (Number(rDate.getMonth()) + 1);
                    var ryear =
                      (rDate.getFullYear() < 10 ? "0" : "") +
                      rDate.getFullYear();
                    var rhour =
                      (rDate.getHours() < 10 ? "0" : "") + rDate.getHours();
                    var rminute =
                      (rDate.getMinutes() < 10 ? "0" : "") + rDate.getMinutes();

                    $("#ETD")
                      .find("#date")
                      .val(ryear + rmonth + rday);
                    $("#ETD")
                      .find("#time")
                      .val(rhour + rminute);
                  }
                }
                if (settings.text.altid == "petdTime") {
                  $("#ETD").find("#date").val(rtn);
                  $("#ETD").find("#time").val(timeReturn);
                }
              }
            }

            return rtn;
          },
        },
      });
  }

  sombre(e) {
    if (e.keyCode.toString() == "13") {
      e.preventDefault();
    }
  }

  countHour2(val) {
    var days = 0;
    var hour = val;
    if (hour > 23) {
      days = Math.floor(val / 24);
      hour = val % 24;
    }
    // console.log(days + "-" + hour);
    return days + "-" + hour;
  }

  countHour(element, targetElement) {
    if (parseInt($("#" + element).val()) > 23) {
      $("#" + targetElement).val(
        parseInt($("#" + targetElement).val()) +
          Math.floor(parseInt($("#" + element).val()) / 24)
      );
      $("#" + element).val(parseInt($("#" + element).val()) % 24);
    }

    var addHour =
      parseInt($("#" + targetElement).val()) * 24 +
      parseInt($("#" + element).val());
    if (element == "petdHour") {
      if (
        $("#PETA").find("#date").val() != "" &&
        $("#PETA").find("#time").val() != ""
      ) {
        var hour = this.stringToDateUtil(
          $("#PETA").find("#date").val() + " " + $("#PETA").find("#time").val()
        );
        hour.setHours(hour.getHours() + addHour);
        $("#PETD").find("#date").val(this.dateToStringUtil(hour).split(" ")[0]);
        $("#PETD").find("#time").val(this.dateToStringUtil(hour).split(" ")[1]);
      }
    }

    if (element == "etdHour") {
      if (
        $("#ETA").find("#date").val() != "" &&
        $("#ETA").find("#time").val() != ""
      ) {
        var hour = this.stringToDateUtil(
          $("#ETA").find("#date").val() + " " + $("#ETA").find("#time").val()
        );
        hour.setHours(hour.getHours() + addHour);
        $("#ETD").find("#date").val(this.dateToStringUtil(hour).split(" ")[0]);
        $("#ETD").find("#time").val(this.dateToStringUtil(hour).split(" ")[1]);
      }
    }
  }

  openSequenceDialog() {
    if (this.gpc.listStore.store.length > 0) {
      $("#testDialog").modal("setting", "closable", false).modal("show");
      $(".sapi").popup();
      $("#moveUp").prop("disabled", true);
      $("#moveDown").attr("disabled", true);
      $("#gridSequenceEditor")
        .find("#container")
        .find("th.collapsing")
        .find("div.checkbox")
        .remove();

      var sequenceDetailData = { seqNo: 0, pocName: "", dataList: [] };
      this.gsq.setModeRadio(true);
      this.gsq.listStore.store.splice(0, this.gsq.listStore.store.length);
      this.gpc.listStore.store.forEach((el) => {
        sequenceDetailData = { seqNo: 0, pocName: "", dataList: [] };
        sequenceDetailData.seqNo = el.seqNo;
        sequenceDetailData.pocName = el.portOfCallName;
        sequenceDetailData.dataList.push(el);
        this.gsq.listStore.addData(sequenceDetailData);
      });

      this.gsq.loadData();
    }
  }

  replaceCheckBox() {
    $("#moveUp").prop("disabled", false);
    $("#moveDown").attr("disabled", false);
    $("#gridSequenceEditor")
      .find("#container")
      .find("td.collapsing")
      .find("div.checkbox")
      .each(function (index) {
        var cbValue = $(this).find("input").val();
        $(this).replaceWith(
          '<div class="ui radio checkbox">' +
            '<input id="rd-editor" type="radio" name="seqEditor" value="' +
            cbValue +
            '" tabindex="0" class="hidden">' +
            "</div>"
        );
      });

    $("input[name=seqEditor]").change(function () {
      // console.log('value '+$(this).val());
      $("#sequenceChecked").val($(this).val());

      /*var maxSeq = $('#gridSequenceEditor').find('#container').find('td.collapsing').find('div.checkbox').length;
      if(cbValue == 1){
        $('#moveUp').prop('disabled',true);
        $('#moveDown').prop('disabled',false);
      } else if (cbValue == maxSeq){
        $('#moveUp').prop('disabled',false);
        $('#moveDown').attr('disabled',true);
      } else {
        $('#moveUp').prop('disabled',false);
        $('#moveDown').attr('disabled',false);
      }*/
    });

    $(".ui.radio.checkbox").checkbox();
  }

  closeSequence() {
    $("#testDialog").modal("hide");
  }

  updateSequence() {
    var updateSequence = new ListStore();
    var firstData = this.gpc.listStore.store[0];
    this.gpc.listStore.store.splice(0, this.gpc.listStore.store.length);
    this.gsq.listStore.store.forEach((el) => {
      // console.log(el.dataList[0].seq);
      el.dataList[0].seqNo = el.seqNo;
      this.gpc.listStore.addData(el.dataList[0]);
    });

    if (this.model.previousVoyage != "") {
      if (this.doNotCalculate) {
        this.updateDetailsData(firstData, 1);
        //this.updateDetailsData(mvScheduleDetailModel, 0);
      } else {
        this.updateAllDetailsData(this.model.previousVoyage, firstData);
      }
    } else {
      if (this.model.servicePreviousVoyage != "") {
        this.updateAllDetailsData(this.model.servicePreviousVoyage, firstData);
      } else {
        this.updateDetailsData(firstData, 1);
        //this.updateDetailsData(mvScheduleDetailModel, 1);
      }
    }

    this.gpc.loadData();
    $("#testDialog").modal("hide");
  }

  resetupdown() {
    // console.log(this.gsq.getSelectedValues()[0].seqNo);
    var maxSeq = this.gsq.listStore.store.length;
    if (this.gsq.getSelectedValues()[0].seqNo == 1) {
      $("#moveUp").prop("disabled", true);
      $("#moveDown").prop("disabled", false);
    } else if (this.gsq.getSelectedValues()[0].seqNo == maxSeq) {
      $("#moveUp").prop("disabled", false);
      $("#moveDown").attr("disabled", true);
    } else {
      $("#moveUp").prop("disabled", false);
      $("#moveDown").attr("disabled", false);
    }
  }

  moveUpSeq() {
    //// console.log('sequence no '+this.sequenceSelectedNo);
    var vesselScheduleDetail = new ListStore();
    this.gsq.listStore.store.forEach((el) => {
      vesselScheduleDetail.addData(el);
    });
    let data = this.gsq.getSelectedValues()[0];
    var indexFrom = vesselScheduleDetail.store.indexOf(data);
    var indexTo = indexFrom - 1;
    vesselScheduleDetail.store[indexFrom] = vesselScheduleDetail.store.splice(
      indexTo,
      1,
      vesselScheduleDetail.store[indexFrom]
    )[0];
    this.gsq.listStore.store.splice(0, this.gsq.listStore.store.length);
    var seq = 0;
    vesselScheduleDetail.store.forEach((element) => {
      seq = seq + 1;
      element.seqNo = seq;
      element["no"] = element.seqNo;
      this.gsq.listStore.addData(element);
    });

    this.gsq.loadData();
    this.resetupdown();
  }

  moveDownSeq() {
    var vesselScheduleDetail = new ListStore();
    this.gsq.listStore.store.forEach((el) => {
      vesselScheduleDetail.addData(el);
    });
    let data = this.gsq.getSelectedValues()[0];
    var indexFrom = vesselScheduleDetail.store.indexOf(data);
    var indexTo = indexFrom + 1;
    vesselScheduleDetail.store[indexFrom] = vesselScheduleDetail.store.splice(
      indexTo,
      1,
      vesselScheduleDetail.store[indexFrom]
    )[0];
    this.gsq.listStore.store.splice(0, this.gsq.listStore.store.length);
    var seq = 0;
    vesselScheduleDetail.store.forEach((elm) => {
      seq = seq + 1;
      elm.seqNo = seq;
      elm["no"] = elm.seqNo;
      this.gsq.listStore.addData(elm);
    });

    this.gsq.loadData();
    this.resetupdown();
  }

  rowChoose(state) {
    $("#addRowDialogVessel")
      .find("input[name=rowChoose]")
      .prop("checked", false);
    $("#addRowDialogVessel").modal("hide");
    if (state == "yes") {
      this.add();
      var mvScheduleDetailModel = new MasterVesselScheduleDetail();
      mvScheduleDetailModel.seqNo = 0;
      this.modelDetail = mvScheduleDetailModel;
    } else if (state == "no") {
      //this.detailSeqMode = '';
      //this.add();
    }
  }

  setInsertSeqRadio(status: boolean, state: string, val) {
    if (status) {
      switch (state) {
        case "before":
          this.detailSeqMode = state + "-" + val;
          break;
        case "after":
          this.detailSeqMode = state + "-" + val;
          break;
        default:
          this.detailSeqMode = "-";
          break;
      }
    } else {
      this.detailSeqMode = "-";
    }
  }

  checkServices(){
    var uri = this.configService.config.BASE_API.toString() + "/MasterServices/findById/"+this.model.serviceId;
    var isProceed = false;
    this.genericService.GET(uri).subscribe((resp)=>{
      if(resp.ok){
        let data = resp.json();
        if(data["isValid"] == "Y"){
          isProceed = true;
        }
      }
    });
  }

  handleCopy() {
    //this.model.voyage = $('input[name="model[baseVoyage]"]').val();
    if (this.model.vesselCode == "" || this.model.baseVoyage == "") {
      this.message(
        "warning",
        "Alert",
        "Please insert vessel and base voyage to copy!",
        "okcancel",
        { ok: '// console.log("ok button clicked")' }
      );
    } else {
      this.loading = true;
      var uri = this.configService.config.BASE_API.toString() + "/MasterServices/findById/"+this.model.serviceId;
      var isProceed = false;
      this.genericService.GET(uri).subscribe((resp)=>{
        if(resp.ok){
          let data = resp.json();
          if(data["isValid"] == "Y"){
            isProceed = true;
          }

          console.log(isProceed + "init");
        }
      },error=>{
        this.loading = false;
        this.message(
          "information",
          "Information",
          "Error occur while copying process, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      },
      () => {
        console.log(isProceed + "fin");
        this.loading = false;
        if(isProceed){
          if (this.modeForm == "schedule") {
            $("#copyDialogVessel")
              .modal("setting", "closable", false)
              .modal("show");
          } else if (this.modeForm == "batch") {
            this.batchUpdateProcess(this.model);
          }
        } else {
          this.message(
            "warning",
            "Alert",
            "Service is invalid. Not Allowed to copy",
            "okonly",
            { ok: '// console.log("ok button clicked")' }
          );
        }
      });
      /*
      if (this.modeForm == "schedule") {
        $("#copyDialogVessel")
          .modal("setting", "closable", false)
          .modal("show");
      } else if (this.modeForm == "batch") {
        this.batchUpdateProcess(this.model);
      }*/
    }
  }

  handleCopy2() {
    if (this.model.vesselCode == "" || this.model.baseVoyage == "") {
      this.message(
        "warning",
        "Alert",
        "Please insert vessel and base voyage to copy!",
        "okcancel",
        { ok: '// console.log("ok button clicked")' }
      );
    } else {
      this.loading = true;
      var uri = this.configService.config.BASE_API.toString() + "/MasterServices/findById/"+this.model.serviceId;
      var isProceed = false;
      this.genericService.GET(uri).subscribe((resp)=>{
        if(resp.ok){
          let data = resp.json();
          if(data["isValid"] == "Y"){
            isProceed = true;
          }
        }
      },error=>{
        this.loading = false;
        this.message(
          "information",
          "Information",
          "Error occur while copying process, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      },
      () => {
        console.log(isProceed + "fin");
        this.loading = false;
        if(isProceed){
          if (this.modeForm == "schedule") {
            var self = this;
            $("#cloneCopy")
              .dialog({
                title: "Vessel Schedule Copy Function",
                modal: true,
                width: 800,
                height: "auto",
                close: function (event) {
                  if (event.originalEvent) {
                    self.doCancel();
                  }
                },
              })
              .dialog("open");
          }
        } else {
          this.message(
            "warning",
            "Alert",
            "Service is invalid. Not Allowed to copy",
            "okonly",
            { ok: '// console.log("ok button clicked")' }
          );
        }
      });

      /*if (this.modeForm == "schedule") {
        var self = this;
        $("#cloneCopy")
          .dialog({
            title: "Vessel Schedule Copy Function",
            modal: true,
            width: 800,
            height: "auto",
            close: function (event) {
              if (event.originalEvent) {
                self.doCancel();
              }
            },
          })
          .dialog("open");

        /*$('#cloneCopy')
          .modal('setting', 'closable', false)
          .modal('show');*//*
      }*/
    }
  }

  doCopy() {
    var data = new MasterVesselSchedule();
    data.vesselCode = this.model.vesselCode;
    data.baseVoyage = this.model.baseVoyage;

    data.createdBy = this.cook.getName();
    data.createdDate = this.genericUtil.getPSIDate();
    data.lastModifiedBy = data.createdBy;
    data.lastModifiedDate = data.createdDate;

    this.copyStore.store.forEach((cs) => {
      var dataDetails = new MasterVesselScheduleDetail();
      dataDetails.voyage = cs.voyage;
      dataDetails.peta = cs.eta;
      data.MasterVesselScheduleDetails.push(dataDetails);
    });

    this.copyProcess(data);
  }

  validateCopy() {
    var result: boolean = true;
    this.dCopyMessage1 = [];

    if (this.copy2Store.store.length > 0) {
      this.copy2Store.store.forEach((cs) => {
        if (cs.voyage == "" && cs.eta == "") {
          result = false;
          cs["status"] = "Missing Details";
          cs["statusCode"] = "1";
        } else if (cs.voyage == "" && cs.eta != "") {
          result = false;
          cs["status"] = "Missing Voyage";
          cs["statusCode"] = "1";
        } else if (cs.eta == "" && cs.voyage != "") {
          result = false;
          cs["status"] = "Missing ETA of 1st Port";
          cs["statusCode"] = "1";
        }
      });
    } else {
      result = false;
      var stat = "0";
      var msg = "Please insert at least 1 data to copy";

      this.dCopyMessage1.push({ message: msg, status: stat });
    }

    return result;
  }

  doCopy2() {
    if (this.validateCopy()) {
      var data = new MasterVesselSchedule();
      data.vesselId = this.model.vesselId;
      data.vesselCode = this.model.vesselCode;
      data.baseVoyage = this.model.baseVoyage;
      data.vesselScheduleId = this.model.vesselScheduleId;
      data.createdBy = this.cook.getName();
      data.createdDate = this.genericUtil.getPSIDate();
      data.lastModifiedBy = data.createdBy;
      data.lastModifiedDate = data.createdDate;

      this.copy2Store.store.forEach((cs) => {
        var dataDetails = new MasterVesselScheduleDetail();
        dataDetails.vesselId = cs.vid;
        dataDetails.voyage = cs.voyage;
        dataDetails.peta = cs.eta;
        dataDetails.vesselName = cs.vessel;
        dataDetails.seqNo = cs.id;
        data.MasterVesselScheduleDetails.push(dataDetails);
      });

      //console.log(data);
      this.copyProcess2(data);
    }
  }

  copyProcess2(data) {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/copy2";
    this.loadingCopy = true;
    this.genericService.POST(hitUrl, data).subscribe(
      (resp) => {
        if (resp.ok) {
          var tmpStore = [];
          tmpStore = resp.json()["content"];
          this.copy2Store.store.forEach((fe) => {
            tmpStore.forEach((fe2) => {
              if (fe["id"] == fe2["no"]) {
                fe["status"] = fe2["message"];
                fe["statusCode"] = fe2["statusCode"];
              }
            });
          });
        }
        this.loadingCopy = false;
      },
      (error) => {
        this.loadingCopy = false;
        this.message(
          "information",
          "Information",
          "Error occur while copying process, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  batchUpdateProcess(data: MasterVesselSchedule) {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/batchUpdate";
    var tempStore = [];
    this.genericService.POST(hitUrl, data).subscribe(
      (resp) => {
        if (resp.ok) {
          tempStore = resp.json()["result"];
          this.batchCopyStore.store.forEach((bc) => {
            tempStore.forEach((ts) => {
              if (ts.vesselId == bc.vesselId && ts.voyage == bc.voyage) {
                if (ts.result == "1") {
                  bc["status"] = "success";
                } else {
                  bc["status"] = "not success";
                }
              }
            });
          });
        }
      },
      (error) => {
        this.message(
          "information",
          "Information",
          "Error occur while batch update process, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  copyProcess(data) {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/copy";
    this.genericService.POST(hitUrl, data).subscribe(
      (resp) => {
        if (resp.ok) {
          var tmpStore = [];
          tmpStore = resp.json()["copyResult"];
          if (tmpStore.length > 0) {
            tmpStore.forEach((ts) => {
              var msg =
                ts["voyage"] +
                " : " +
                (ts["result"] == "copied"
                  ? "Copied successfully"
                  : ts["result"]);
              var stat = ts["result"] == "copied" ? "1" : "0";

              this.dCopyMessage.push({ message: msg, status: stat });
              //this.dCopyMessage = this.dCopyMessage + ts['voyage'] + '        : ' +(ts['result'] == 'copied' ? 'Copied successfully': ts['result'] ) + '<br>';
            });
          }
          //this.message("information","Information","Record sucessfully saved.","okonly",{ok:''});
          if (this.modeForm == "batch") {
            this.handleCancel();
            this.grid.onSortDir("dateModified", "DESC");
            this.grid.onFirst();
          }
        }
      },
      (error) => {
        this.message(
          "information",
          "Information",
          "Error occur while copying process, error message : " +
            error.json()["message"] +
            ". Please contact your administrator!",
          "okonly",
          { ok: "" }
        );
      }
    );
  }

  addCopyRow(type: string) {
    if (type == "") {
      this.copyStore.addData({
        id: (this.copyStore.store.length + 1).toString(),
        voyage: "",
        eta: "",
      });
    } else {
      this.copy2Store.addData({
        id: (this.copy2Store.store.length + 1).toString(),
        vessel: this.model.vesselName,
        voyage: "",
        eta: "",
        vid: "",
        vcd: "",
      });
    }
  }

  addBatchCopyRow() {
    if (this.model.vesselCode != "" && this.model.baseVoyage != "") {
      this.getFirstEta(this.model);

      //this.model = new MasterVesselSchedule;
      //this.bVName.setValue("");
      //$('#idBatchVoyage').find('.menu').find('.item').empty();
      //$('#idBatchVoyage').find('.menu').find('.item').remove();

      //$('#idBatchVoyage').dropdown('clear');
    }
  }

  getFirstEta(data: MasterVesselSchedule) {
    var result = "";
    if (data.vesselCode != "" && data.baseVoyage != "") {
      var hitUrl =
        this.configService.config.BASE_API.toString() +
        "/MasterVesselSchedules/findFistEtaByVesselVoyage/" +
        data.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(data.baseVoyage);
      this.genericService.GET(hitUrl).subscribe(
        (resp) => {
          if (resp.ok) {
            if (resp.json()["content"].length > 0) {
              result =
                this.dateUtilConvert(
                  resp.json()["content"][0].fistETA,
                  "date"
                ) +
                " " +
                this.dateUtilConvert(resp.json()["content"][0].fistETA, "time");
              this.batchCopyStore.addData({
                id: (this.batchCopyStore.store.length + 1).toString(),
                vesselName: data.vesselName,
                vesselId: data.vesselId,
                vesselCode: data.vesselCode,
                voyage: data.baseVoyage,
                eta: result,
              });
            } else {
              this.batchCopyStore.addData({
                id: (this.batchCopyStore.store.length + 1).toString(),
                vesselName: data.vesselName,
                vesselId: data.vesselId,
                vesselCode: data.vesselCode,
                voyage: data.baseVoyage,
                eta: "",
              });
            }
          } else {
            this.batchCopyStore.addData({
              id: (this.batchCopyStore.store.length + 1).toString(),
              vesselName: data.vesselName,
              vesselId: data.vesselId,
              vesselCode: data.vesselCode,
              voyage: data.baseVoyage,
              eta: "",
            });
          }
        },
        (error) => {
          this.batchCopyStore.addData({
            id: (this.batchCopyStore.store.length + 1).toString(),
            vesselName: data.vesselName,
            vesselId: data.vesselId,
            vesselCode: data.vesselCode,
            voyage: data.baseVoyage,
            eta: "",
          });
        }
      );
    }
  }

  removeCopyRow(elId: string) {
    var store =
      elId == "batchCopy"
        ? this.batchCopyStore.store
        : elId == ""
        ? this.copyStore.store
        : this.copy2Store.store;
    var data;
    if (this.selectList.length > 0) {
      this.selectList.forEach((sl) => {
        var idx = store.indexOf(sl);
        data = sl;
        store.splice(idx, 1);
      });
      this.selectList = [];
    } else {
      this.message(
        "information",
        "information",
        "Please select at least 1 record to remove row!",
        "okonly",
        { ok: "" }
      );
    }
  }

  onDoubleClick(
    id: number,
    field: string,
    val: string,
    type: string,
    elId: string
  ) {
    // console.log('data id : '+id);
    // console.log('data field : '+field);
    // console.log('data type : ' + type);
    this.copy2Lock = true;
    var conf = this.configService;
    var self = this;
    $("#" + elId)
      .find("#editable")
      .find("table")
      .find("tbody")
      .find("td")
      .each(function (index, value) {
        if ($(this).attr("id") == "s." + id.toString() + "." + field) {
          $(this).find("span").hide();
          if (type == "date") {
            if ($(this).find("span").text() != "") {
              $(this)
                .find(".syear")
                .val($(this).find("span").text().substr(0, 4));
              $(this)
                .find(".smonth")
                .val($(this).find("span").text().substr(4, 2));
              $(this)
                .find(".sday")
                .val($(this).find("span").text().substr(6, 2));
              $(this)
                .find(".shour")
                .val($(this).find("span").text().substr(9, 2));
              $(this)
                .find(".sminute")
                .val($(this).find("span").text().substr(11, 2));
            } else {
              var tanggal: Date = new Date();
              $(this).find(".syear").val(tanggal.getFullYear());
              $(this)
                .find(".smonth")
                .val(
                  (tanggal.getMonth() + 1 < 10 ? "0" : "") +
                    (tanggal.getMonth() + 1)
                );
              $(this)
                .find(".sday")
                .val((tanggal.getDay() < 10 ? "0" : "") + tanggal.getDay());
              $(this)
                .find(".shour")
                .val((tanggal.getHours() < 10 ? "0" : "") + tanggal.getHours());
              $(this)
                .find(".sminute")
                .val(
                  (tanggal.getMinutes() < 10 ? "0" : "") + tanggal.getMinutes()
                );
            }
            $(this).find(".fields").show();
          } else if (type == "string") {
            $(this).find("input").show();
            $(this).find("input").focus();
          } else if (type == "combo") {
            $(this).find(".combo").show();
            self.loadVesselData();
            var selfFunction = $(this);
            $(".cmb-combo").dropdown({
              direction: "downward",
              allowReselection: true,
              onChange: function (value, text) {
                selfFunction.find("span").show();
                selfFunction.find(".combo").hide();
                var inputElement = text;
                selfFunction.find("span").text(inputElement);
                self.updateStore(
                  self.copy2Store.store,
                  id,
                  field,
                  inputElement
                );
                self.updateStore(self.copy2Store.store, id, "vid", value);
              },
            });
          } else {
            $(this).find("span").show();
          }
        }
      });
    //<input id="{{data.id}}.{{col.field}}.input" type="text" value="{{data[col.field]}}" style="display:none">
  }

  divBlur(id: number, field: string, val: string, elId: string) {
    var inputElement: any;
    this.copy2Lock = false;
    $("#" + elId)
      .find("#editable")
      .find("table")
      .find("tbody")
      .find("td")
      .each(function (index, value) {
        if ($(this).attr("id") == "s." + id.toString() + "." + field) {
          $(this).find("span").show();
          $(this).find(".fields").hide();
          inputElement =
            $(this).find(".fields").find(".field").find(".syear").val() +
            $(this).find(".fields").find(".field").find(".smonth").val() +
            $(this).find(".fields").find(".field").find(".sday").val() +
            " " +
            $(this).find(".fields").find(".field").find(".shour").val() +
            $(this).find(".fields").find(".field").find(".sminute").val();
          $(this).find("span").text(inputElement);
        }
      });

    if (elId == "batchCopy") {
      this.updateStore(this.batchCopyStore.store, id, field, inputElement);
    } else if (elId == "cloneCopy") {
      this.updateStore(
        this.copy2Store.store,
        id,
        field,
        inputElement.toUpperCase()
      );
    } else {
      this.updateStore(this.copyStore.store, id, field, inputElement);
    }
  }

  textBlur(id: number, field: string, val: string, elId: string) {
    var inputElement: any;
    this.copy2Lock = false;
    $("#" + elId)
      .find("#editable")
      .find("table")
      .find("tbody")
      .find("td")
      .each(function (index, value) {
        if ($(this).attr("id") == "s." + id.toString() + "." + field) {
          $(this).find("input").first().focusout();
          $(this).find("span").show();
          $(this).find("input").hide();
          $(this).find("i").hide();
          inputElement = $(this).find("input").first();
          /*$(this).find('input').each(function(){
          if($(this).val() != ""){
            inputElement = $(this);
          }
        });*/
          $(this).find("span").text(inputElement.val().toUpperCase());
        }
      });

    if (elId == "batchCopy") {
      this.updateStore(
        this.batchCopyStore.store,
        id,
        field,
        inputElement.val().toUpperCase()
      );
    } else if (elId == "cloneCopy") {
      this.updateStore(
        this.copy2Store.store,
        id,
        field,
        inputElement.val().toUpperCase()
      );
    } else {
      this.updateStore(
        this.copyStore.store,
        id,
        field,
        inputElement.val().toUpperCase()
      );
    }
  }

  textKeyPress(e, id: number, field: string, val: string, elId: string) {
    var inputElement: any;
    if (e.keyCode.toString() == "13") {
      e.preventDefault();
      this.copy2Lock = false;
      $("#" + elId)
        .find("#editable")
        .find("table")
        .find("tbody")
        .find("td")
        .each(function (index, value) {
          if ($(this).attr("id") == "s." + id.toString() + "." + field) {
            $(this).find("input").first().focusout();
            $(this).find("span").show();
            $(this).find("input").first().hide();
            inputElement = $(this).find("input").first();
            $(this).find("span").text(inputElement.val().toUpperCase());
          }
        });
      if (elId == "batchCopy") {
        this.updateStore(
          this.batchCopyStore.store,
          id,
          field,
          inputElement.val().toUpperCase()
        );
      } else if (elId == "cloneCopy") {
        this.updateStore(
          this.copy2Store.store,
          id,
          field,
          inputElement.val().toUpperCase()
        );
      } else {
        this.updateStore(
          this.copyStore.store,
          id,
          field,
          inputElement.val().toUpperCase()
        );
      }
    }
  }

  updateStore(store, id: number, field: string, value: string) {
    store.forEach((st) => {
      if (st.id == id) {
        for (var keys in st) {
          if (keys == field) {
            if (st.hasOwnProperty(keys)) {
              st[keys] = value;
            }
          }
        }
      }
    });
  }

  clickCheckBox(check: boolean, id: number, elId: string) {
    var store =
      elId == "batchCopy"
        ? this.batchCopyStore.store
        : elId == ""
        ? this.copyStore.store
        : this.copy2Store.store;
    // console.log('check : '+check+' id : '+id+' elId :'+elId);
    if (check) {
      store.forEach((st) => {
        // console.log('id : '+st.id);
        if (st.id == id) {
          this.selectList.push(st);
        }
      });
    } else {
      var indexOf = 0;
      store.forEach((st) => {
        if (st.id == id) {
          indexOf = this.selectList.indexOf(st);
        }
      });
      this.selectList.splice(indexOf, 1);
    }

    // console.log('size of list '+this.selectList.length);
  }

  setYearList() {
    var curDate = new Date();

    for (var i = 0; i < 3; i++) {
      this.yearList.push(curDate.getFullYear() + i);
    }

    for (var j = 0; j < 24; j++) {
      this.hourList.push(j < 10 ? "0" + j.toString() : j.toString());
    }

    for (var j = 0; j < 60; j++) {
      this.minuteList.push(j < 10 ? "0" + j.toString() : j.toString());
    }
  }

  doCancel() {
    this.copyStore.store.splice(0, this.copyStore.store.length);
    this.copy2Store.store.splice(0, this.copy2Store.store.length);
    this.dCopyMessage = [];
    this.dCopyMessage1 = [];
    this.copy2Lock = false;
    $("#copyDialogVessel")
      //.modal('setting', 'closable', false)
      .modal("hide");
    $("#cloneCopy").dialog("close");
  }

  loadMVSData(data) {
    this.loading = true;
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findById/";
    // Get id
    this.model.vesselScheduleId = data.vesselScheduleId;
    this.genericService
      .GET(hitUrl + this.model.vesselScheduleId)
      .subscribe((resp) => {
        var self = this;
        if (resp.ok) {
          // console.log(resp.json());
          var tmpData = resp.json();
          this.model.isValid = tmpData["isValid"] == "Y" ? true : false;
          this.model.vesselScheduleId = tmpData["vesselScheduleId"];
          this.model.vesselId = tmpData["vesselId"];
          this.model.vesselCode = tmpData["vesselCode"];
          this.model.vesselName = tmpData["vesselName"].trim();
          this.model.sBound = tmpData["sBound"];
          this.isReadOnlyBound = false;
          // this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/25/*/*'
          //this.vName.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/vesselCode/{query}/1/25/*/*');
          this.vName.setUrl(
            this.configService.config.BASE_API.toString() +
              "/MasterVessels/findByComboBoxControl/vesselCode={query}"
          );
          this.vName.setValue(this.model.vesselCode, function (callbackVessel) {
            self.model.psaVesselCode = tmpData["psaAbvName"];
            self.model.vesselIsSsl =
              tmpData["sslV"] == ""
                ? false
                : tmpData["sslV"] == "Y"
                ? true
                : false;
            // if (self.model.vesselIsSsl) {
            //   self.modeSlot = true;
            // }
            self.model.vesselIsSlot =
              tmpData["slotV"] == ""
                ? false
                : tmpData["slotV"] == "Y"
                ? true
                : false;
            // if (self.model.vesselIsSlot) {
            //   self.modeSlot = false;
            // }
            self.model.vesselIs3rdParty =
              tmpData["3rdPartyV"] == ""
                ? false
                : tmpData["3rdPartyV"] == "Y"
                ? true
                : false;
            // if (self.model.vesselIs3rdParty) {
            //   self.modeSlot = true;
            // }
            self.model.vesselIsSsl
              ? $("#ssl").prop("checked", true)
              : $("#ssl").prop("checked", false);
            self.model.vesselIsSlot
              ? $("#slot").prop("checked", true)
              : $("#slot").prop("checked", false);
            self.model.vesselIs3rdParty
              ? $("#3rd").prop("checked", true)
              : $("#3rd").prop("checked", false);

            if (self.model.vesselCode != "") {
              self.loadbaseVoyageData("#idBsVoyage", self.model);
              //this.loadbaseVoyageData('#idPrevLoop', this.model);
              self.loadBaseVoyageAComplete("#prevLoopId", self.model);
              $("#nextLoopId").autocomplete({
                source: self.voyageList,
              });
              //this.loadbaseVoyageData('#idNextLoop', this.model);
              //this.loadBaseVoyageAComplete('#nextLoopId',this.model);
            }

            //this.vName.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/25/*/*');
            self.vName.setUrl(
              self.configService.config.BASE_API.toString() +
                "/MasterVessels/findByComboBoxControl/vesselName={query}"
            );
          });
          this.model.serviceCode = tmpData["serviceCode"];
          //this.srv.setValue(this.model.serviceCode+'*'+tmpData['serviceSequence']);
          this.model.serviceId = tmpData["serviceId"];
          if (this.model.serviceCode != null || this.model.serviceCode != "") {
            //this.loadServiceSequenceData(this.model.serviceCode, tmpData['serviceSequence']);
            //BASE_API + '/MasterServices/findGeneral/*/mvSchedule/{query}/1/25/*/*'
            this.srv.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterServices/findGeneral/*/serviceId/{query}/1/25/*/*"
            );
            this.srv.setValue(this.model.serviceId);
            this.srv.setUrl(
              this.configService.config.BASE_API.toString() +
                "/MasterServices/findGeneral/*/mvSchedule/{query}/1/25/*/*"
            );
          }
          this.model.serviceSequence = tmpData["serviceSequence"];
          this.model.remarks = tmpData["remarks"].trim();
          //this.loadServiceSequence(this.model.serviceCode, tmpData['serviceSequence']);
          this.model.vesselIsSsl =
            tmpData["sslV"] == ""
              ? false
              : tmpData["sslV"] == "Y"
              ? true
              : false;
          this.model.vesselIsSlot =
            tmpData["slotV"] == ""
              ? false
              : tmpData["slotV"] == "Y"
              ? true
              : false;
          this.model.vesselIs3rdParty =
            tmpData["3rdPartyV"] == ""
              ? false
              : tmpData["3rdPartyV"] == "Y"
              ? true
              : false;


          this.model.vesselIsSsl
            ? $("#ssl").prop("checked", true)
            : $("#ssl").prop("checked", false);
          this.model.vesselIsSlot
            ? $("#slot").prop("checked", true)
            : $("#slot").prop("checked", false);
          this.model.vesselIs3rdParty
            ? $("#3rd").prop("checked", true)
            : $("#3rd").prop("checked", false);
          this.model.baseVoyage = tmpData["voyage"].trim();
          this.model.psaVesselCode = tmpData["psaAbvName"].trim();
          this.model.serviceIsSsl =
            tmpData["sslS"] == ""
              ? false
              : tmpData["sslS"] == "Y"
              ? true
              : false;
          this.model.serviceIsSlot =
            tmpData["slotS"] == ""
              ? false
              : tmpData["slotS"] == "Y"
              ? true
              : false;
          this.model.serviceIs3rdParty =
            tmpData["3rdPartyS"] == ""
              ? false
              : tmpData["3rdPartyS"] == "Y"
              ? true
              : false;
          this.model.serviceIsSsl
            ? $("#Sssl").prop("checked", true)
            : $("#Sssl").prop("checked", false);
          this.model.serviceIsSlot
            ? $("#Sslot").prop("checked", true)
            : $("#Sslot").prop("checked", false);
          this.model.serviceIs3rdParty
            ? $("#S3rd").prop("checked", true)
            : $("#S3rd").prop("checked", false);
          this.model.createdBy = tmpData["userCreated"].trim();
          this.model.createdDate = tmpData["dateCreated"].trim();
          this.model.lastModifiedBy =
            tmpData["userModified"] != null
              ? tmpData["userModified"].trim()
              : "";
          this.model.lastModifiedDate =
            tmpData["dateModified"] != null
              ? tmpData["dateModified"].trim()
              : "";
          this.model.servicePreviousVoyage =
            tmpData["servicePreviousVoyage"] != null
              ? tmpData["servicePreviousVoyage"].trim()
              : "";
          this.model.serviceNextVoyage =
            tmpData["serviceNextVoyage"] != null
              ? tmpData["serviceNextVoyage"].trim()
              : "";
          if (this.model.vesselCode != "") {
            this.loadbaseVoyageData("#idPrevLoop", this.model);
            this.loadbaseVoyageData("#idNextLoop", this.model);
          }
          this.model.psaInVoyage =
            tmpData["psaInVoyage"] != null ? tmpData["psaInVoyage"].trim() : "";
          this.model.psaOutVoyage =
            tmpData["psaOutVoyage"] != null
              ? tmpData["psaOutVoyage"].trim()
              : "";
          this.model.previousVoyage =
            tmpData["previousVoyage"] != null
              ? tmpData["previousVoyage"].trim()
              : "";
          this.model.nextVoyage =
            tmpData["nextVoyage"] != null ? tmpData["nextVoyage"].trim() : "";
          this.model.invalidateBy =
            tmpData["invalidateBy"] != null
              ? tmpData["invalidateBy"].trim()
              : "";
          this.model.invalidateDate =
            tmpData["invalidateDate"] != null
              ? tmpData["invalidateDate"].trim()
              : "";
          this.model.vesselSlotOwner1 =
            tmpData["vesselSlotOwner1"] != null
              ? tmpData["vesselSlotOwner1"].trim()
              : "";
          this.model.vesselSlotOwner2 =
            tmpData["vesselSlotOwner2"] != null
              ? tmpData["vesselSlotOwner2"].trim()
              : "";
          this.model.vesselSlotOwner3 =
            tmpData["vesselSlotOwner3"] != null
              ? tmpData["vesselSlotOwner3"].trim()
              : "";
          this.model.baseEta =
            tmpData["baseEta"] != null ? tmpData["baseEta"].trim() : "";
          this.model.baseEtd =
            tmpData["baseEtd"] != null ? tmpData["baseEtd"].trim() : "";
          this.model.sslOperated = tmpData["sslOperated"] == "Y" ? true : false;
          this.model.transmitToPsa =
            tmpData["transmitToPsa"] == "Y" ? true : false;
          this.model.dateToPsa =
            tmpData["dateToPsa"] != null ? tmpData["dateToPsa"].trim() : "";
          this.model.transmitToAgent =
            tmpData["transmitToAgent"] == "Y" ? true : false;
          this.model.recordStatus =
            tmpData["recordStatus"] != null
              ? tmpData["recordStatus"].trim()
              : "";
          this.model.groupVoyage =
            tmpData["groupVoyage"] != null ? tmpData["groupVoyage"].trim() : "";
          this.model.noCallFromPrePort =
            tmpData["noCallFromPrePort"] != null
              ? tmpData["noCallFromPrePort"].trim()
              : "";

          this.model.bookAffected =
            tmpData["bookingAffected"] != null
              ? parseInt(tmpData["bookingAffected"])
              : 0;

          var bookDetails = [];
          if (tmpData["bookDetails"] != null) {
            bookDetails = tmpData["bookDetails"];

            if (bookDetails.length > 0) {
              bookDetails.forEach((fe) => {
                this.model.bookAffectedDetails.push(fe);
              });
            }

            // console.log("book affected details");
            // console.log(this.model.bookAffectedDetails);
          }

          //detail
          var tmpDataDetails = [];
          tmpDataDetails = tmpData["masterVesselScheduleDetails"];

          $('input[name="gridCount"]').val(tmpDataDetails.length);
          for (var i = 1; i <= tmpDataDetails.length; i++) {
            tmpDataDetails.forEach((tdd) => {
              if (i == tdd.seqNo) {
                // console.log(tdd.seqNo);
                var tmpStoreDetail = new MasterVesselScheduleDetail();
                tmpStoreDetail.seqNo = tdd.seqNo;
                tmpStoreDetail.portOfCall = tdd.portOfCall.trim();
                tmpStoreDetail.portOfCallName = tdd.portName.trim();
                tmpStoreDetail.portCode = tdd.portCode;
                tmpStoreDetail.terminalCode =
                  tdd.terminalCode == "ZZZZZ" ? "" : tdd.terminalCode;
                tmpStoreDetail.terminalName =
                  tdd.terminalCode == "ZZZZZ" ? "" : tdd.terminalName;
                tmpStoreDetail.callReason = tdd.callReason.trim();

                tmpStoreDetail.peta =
                  this.dateUtilConvert(tdd.peta, "date") +
                  " " +
                  this.dateUtilConvert(tdd.peta, "time");
                tmpStoreDetail.petd =
                  this.dateUtilConvert(tdd.petd, "date") +
                  " " +
                  this.dateUtilConvert(tdd.petd, "time");
                tmpStoreDetail.eta =
                  this.dateUtilConvert(tdd.eta, "date") +
                  " " +
                  this.dateUtilConvert(tdd.eta, "time");
                tmpStoreDetail.etd =
                  this.dateUtilConvert(tdd.etd, "date") +
                  " " +
                  this.dateUtilConvert(tdd.etd, "time");

                if (tdd.ata != null && tdd.ata != "") {
                  tmpStoreDetail.ata =
                    this.dateUtilConvert(tdd.ata, "date") +
                    " " +
                    this.dateUtilConvert(tdd.ata, "time");
                }
                if (tdd.atd != null && tdd.atd != "") {
                  tmpStoreDetail.atd =
                    this.dateUtilConvert(tdd.atd, "date") +
                    " " +
                    this.dateUtilConvert(tdd.atd, "time");
                }
                tmpStoreDetail.efmh = tdd.efmh;
                tmpStoreDetail.eath = tdd.eath;
                tmpStoreDetail.pfmh = tdd.pfmh;
                tmpStoreDetail.path = tdd.path;

                tmpStoreDetail.petaDays = parseInt(
                  this.countHour2(tmpStoreDetail.pfmh).split("-")[0]
                );
                tmpStoreDetail.petaHour = parseInt(
                  this.countHour2(tmpStoreDetail.pfmh).split("-")[1]
                );
                tmpStoreDetail.petdDays = parseInt(
                  this.countHour2(tmpStoreDetail.path).split("-")[0]
                );
                tmpStoreDetail.petdHour = parseInt(
                  this.countHour2(tmpStoreDetail.path).split("-")[1]
                );

                tmpStoreDetail.etaDays = parseInt(
                  this.countHour2(tmpStoreDetail.efmh).split("-")[0]
                );
                tmpStoreDetail.etaHour = parseInt(
                  this.countHour2(tmpStoreDetail.efmh).split("-")[1]
                );
                tmpStoreDetail.etdDays = parseInt(
                  this.countHour2(tmpStoreDetail.eath).split("-")[0]
                );
                tmpStoreDetail.etdHour = parseInt(
                  this.countHour2(tmpStoreDetail.eath).split("-")[1]
                );

                tmpStoreDetail.term1 = tdd.term1;
                tmpStoreDetail.term2 = tdd.term2;
                tmpStoreDetail.term3 = tdd.term3;
                tmpStoreDetail.term4 = tdd.term4;
                tmpStoreDetail.term5 = tdd.term5;

                tmpStoreDetail.callIndicator = tdd.callIndicator;
                tmpStoreDetail.inIndicator = tdd.inIndicator;
                tmpStoreDetail.outIndicator = tdd.outIndicator;
                if (tdd.hasTerminal != null) {
                  tmpStoreDetail.hasTerminal = tdd.hasTerminal;
                }

                tmpStoreDetail["select"] = "";
                tmpStoreDetail["no"] = tmpStoreDetail.seqNo;

                this.gpc.listStore.addData(tmpStoreDetail);
                this.gpc.loadData();
              }
              //this.generateRowTableCSS();
            });
          }
          //this.generateRowTableCSS();

          this.model.tempServicePrevVoyage = this.model.servicePreviousVoyage;
          console.log('isinya ');
          console.log(this.model.previousVoyage);
          console.log(this.model.servicePreviousVoyage);
          console.log(this.model.nextVoyage);
          console.log(this.model.serviceNextVoyage);
        }
        this.srv.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterServices/findGeneral/Y/mvSchedule/{query}/1/100/ASC/serviceName"
        );
        this.loading = false;
      });



  }

  dateUtilConvert(dataDate: string, type) {
    var vdate = dataDate.split(" ")[0];
    var vtime = dataDate.split(" ")[1];

    var indexMonth = this.monthsList.indexOf(vdate.split("-")[1]) + 1;

    if (type == "date") {
      return (
        vdate.split("-")[2] +
        (indexMonth < 10 ? "0" : "") +
        indexMonth +
        vdate.split("-")[0]
      );
    } else {
      return vtime.split(":")[0] + vtime.split(":")[1];
    }
  }

  dateToStringUtil(value: Date) {
    return (
      value.getFullYear() +
      this.genericUtil.pad2(value.getMonth() + 1) +
      this.genericUtil.pad2(value.getDate()) +
      " " +
      this.genericUtil.pad2(value.getHours()) +
      this.genericUtil.pad2(value.getMinutes())
    );
  }

  stringToDateUtil(stringDate: string) {
    // format yyyymmdd hhmm
    // console.log(stringDate);
    if (stringDate == "") {
      return new Date();
    } else {
      var year = parseInt(stringDate.substring(0, 4)); // get year
      var month = parseInt(stringDate.substring(4, 6)) - 1; // get month
      var day = parseInt(stringDate.substring(6, 8)); // get date
      var hour = parseInt(stringDate.split(" ")[1].substring(0, 2)); // get hour
      var minute = parseInt(stringDate.split(" ")[1].substring(2)); // get minute

      // console.log(year.toString() + month.toString() + day.toString() + " "+ hour.toString() + minute.toString());
      return new Date(year, month, day, hour, minute);
    }
  }

  checkDateInput(stringDate: string) {}

  doUpdateData() {
    this.setToolbarButtons("Update", "Save", "save", true, "save");
    this.setToolbarButtons(
      "Change Service",
      "Change Service",
      "cService",
      true,
      "exchange"
    );
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "";
    }
    if (this.model.servicePreviousVoyage == "") {
      this.calLock = false;
    }
    this.lock = true;
    this.modeViewOnly = false;
    this.connectionLock = false;
    this.verticalButtonLock = false;
    this.createLock = true;
    this.isUnderUpdateMode = true;
    if (this.model.serviceIs3rdParty) {
      this.modeSlot = false;
    } else {
      this.modeSlot = true;
    }
    this.updateMode = true;

    $("#retriveBtn").prop("disabled", true);
  }

  resetHeaderData() {
    this.model = new MasterVesselSchedule();
    this.vName.setValue("");
    this.hVName.setValue("");
    this.bVName.setValue("");
    this.srv.setValue("");
    $("#ssl").prop("checked", false);
    $("#slot").prop("checked", false);
    $("#3rd").prop("checked", false);

    $("#Sssl").prop("checked", false);
    $("#Sslot").prop("checked", false);
    $("#S3rd").prop("checked", false);

    $("#idBsVoyage").find(".menu").find(".item").empty();
    $("#idBsVoyage").find(".menu").find(".item").remove();

    $("#idPrevLoop").find(".menu").find(".item").empty();
    $("#idPrevLoop").find(".menu").find(".item").remove();

    $("#idNextLoop").find(".menu").find(".item").empty();
    $("#idNextLoop").find(".menu").find(".item").remove();

    $("#idBsVoyage").dropdown("clear");
    $("#idPrevLoop").dropdown("clear");
    $("#idNextLoop").dropdown("clear");

    $("#idBatchVoyage").find(".menu").find(".item").empty();
    $("#idBatchVoyage").find(".menu").find(".item").remove();

    $("#idBatchVoyage").dropdown("clear");
    $("#idHistoryVoyage").dropdown("clear");

    this.modeSlot = true;
    this.voyageLock = false;
    this.bsVoyMode = "new";
    $("#cbox").prop("checked", false);
    this.model["error-vesselName"] = "";
    this.model["error-baseVoyage"] = "";
    this.model["error-servicePreviousVoyage"] = "";
    this.model["error-previousVoyage"] = "";
    this.model["error-psaInVoyage"] = "";
    this.model["error-psaOutVoyage"] = "";
    //this.model.serviceSequence = '';
  }

  checkDetailsDate(data: string, id: string, seq: number) {
    var result: boolean = true;
    // console.log("## detail seq mode "+this.detailSeqMode);
    if (
      (parseInt(this.detailSeqMode.split("-")[1]) == 1 &&
        this.detailSeqMode.split("-")[0] == "before" &&
        this.model.servicePreviousVoyage == "" &&
        this.model.previousVoyage == "") ||
      seq == 1
    ) {
      if (data.trim() == "") {
        result = false;
      } else if (
        $("#" + id)
          .find("#date")
          .val() == "" ||
        $("#" + id)
          .find("#time")
          .val() == ""
      ) {
        //$('#PETA').find('#date').val() + ' ' + $('#PETA').find('#time').val();
        result = false;
      }
    }

    return result;
  }

  detailsValidation(data) {
    var result = true;
    if (data.portOfCallName == "") {
      //this.modelDetail['error-portOfCallName'] = "Please insert Port of Call!";
      this.message(
        "information",
        "Information",
        "Please insert Port of Call!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (data.callReason == "") {
      //this.modelDetail['error-purpose'] = "Please insert Purpose!";
      this.message(
        "information",
        "Information",
        "Please insert Purpose!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (!this.checkDetailsDate(data.peta, "PETA", data.seqNo)) {
      //this.modelDetail['error-peta'] = "Please insert proforma estimated time arrive!";
      this.message(
        "information",
        "Information",
        "Please insert proforma estimated time arrive!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (!this.checkDetailsDate(data.petd, "PETD", data.seqNo)) {
      //this.modelDetail['error-petd'] = "Please insert proforma estimated time discharge!";
      this.message(
        "information",
        "Information",
        "Please insert proforma estimated time discharge!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (!this.checkDetailsDate(data.eta, "ETA", data.seqNo)) {
      //this.modelDetail['error-eta'] = "Please insert proforma estimate time arrive!";
      this.message(
        "information",
        "Information",
        "Please insert proforma estimate time arrive!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (!this.checkDetailsDate(data.etd, "ETD", data.seqNo)) {
      //this.modelDetail['error-etd'] = "Please insert proforma estimate time discharge!";
      this.message(
        "information",
        "Information",
        "Please insert proforma estimate time discharge!",
        "okonly",
        { ok: "" }
      );
      result = false;
    } else if (!this.terminalLock) {
      if (data.terminalCode == "" || data.terminalName == "") {
        //this.modelDetail['error-terminalName'] = "Please insert Terminal!";
        this.message(
          "information",
          "Information",
          "Please insert Terminal!",
          "okonly",
          { ok: "" }
        );
        result = false;
      }
    }

    return result;
  }

  populateData() {
    this.model.psaInVoyage = this.model.psaInVoyage.toUpperCase();
    this.model.psaOutVoyage = this.model.psaOutVoyage.toUpperCase();
    this.model.nextVoyage = this.model.nextVoyage.toUpperCase();
    this.model.previousVoyage = this.model.previousVoyage.toUpperCase();
    this.model.serviceNextVoyage = this.model.serviceNextVoyage.toUpperCase();
    this.model.servicePreviousVoyage =
      this.model.servicePreviousVoyage.toUpperCase();

    if (this.model.vesselScheduleId == "") {
      this.model.createdBy = this.cook.getName();
      this.model.createdDate = this.genericUtil.getPSIDate();
    } else {
      this.model.lastModifiedBy = this.cook.getName();
      this.model.lastModifiedDate = this.genericUtil.getPSIDate();
    }
    console.log("** DEBUG FOR UFT START **");
    console.log(this.gpc.listStore.store);

    this.gpc.listStore.store.forEach((gl) => {

      gl["vesselScheduleId"] = this.model.vesselScheduleId;
      if (gl.seqNo == 1) {
        this.model.baseEta = gl.eta;
        this.model.baseEtd = gl.etd;
        console.log("checking eta/etd");
        console.log("gl.eta = " + gl.eta);
        console.log("gl.etd = " + gl.etd);
        console.log("model.baseEta = " + this.model.baseEta);
        console.log("model.baseEtd = " + this.model.baseEtd);
      }
      if (gl.terminalCode == "") {
        gl.terminalCode = "ZZZZZ";
        gl.terminalName = "OPTIONAL";
      }

      this.model.MasterVesselScheduleDetails.push(gl);
    });
    console.log("** DEBUG FOR UFT END **");

    if (this.oldDetailsData.store.length > 0) {
      this.oldDetailsData.store.forEach((each) => {
        each["vesselScheduleId"] = this.model.vesselScheduleId;
        if (each.terminalCode == "") {
          each.terminalCode = "ZZZZZ";
          each.terminalName = "OPTIONAL";
        }

        this.model.oldMasterVesselScheduleDetails.push(each);
      });
    }

    this.model.doNotCalculate = this.doNotCalculate;
    // console.log(this.model);
    this.saveEvent();
  }
  
  populateDataForOrphanedCargoes() {
    console.log("populateDataForOrphanedCargoes()")
    this.model.psaInVoyage = this.model.psaInVoyage.toUpperCase();
    this.model.psaOutVoyage = this.model.psaOutVoyage.toUpperCase();
    this.model.nextVoyage = this.model.nextVoyage.toUpperCase();
    this.model.previousVoyage = this.model.previousVoyage.toUpperCase();
    this.model.serviceNextVoyage = this.model.serviceNextVoyage.toUpperCase();
    this.model.servicePreviousVoyage =
      this.model.servicePreviousVoyage.toUpperCase();

    if (this.model.vesselScheduleId == "") {
      this.model.createdBy = this.cook.getName();
      this.model.createdDate = this.genericUtil.getPSIDate();
    } else {
      this.model.lastModifiedBy = this.cook.getName();
      this.model.lastModifiedDate = this.genericUtil.getPSIDate();
    }
    console.log("** DEBUG FOR UFT START **");
    console.log(this.gpc.listStore.store);

    this.gpc.listStore.store.forEach((gl) => {

      gl["vesselScheduleId"] = this.model.vesselScheduleId;
      if (gl.seqNo == 1) {
        this.model.baseEta = gl.eta;
        this.model.baseEtd = gl.etd;
        console.log("checking eta/etd");
        console.log("gl.eta = " + gl.eta);
        console.log("gl.etd = " + gl.etd);
        console.log("model.baseEta = " + this.model.baseEta);
        console.log("model.baseEtd = " + this.model.baseEtd);
      }
      if (gl.terminalCode == "") {
        gl.terminalCode = "ZZZZZ";
        gl.terminalName = "OPTIONAL";
      }

      this.model.MasterVesselScheduleDetails.push(gl);
    });
    console.log("** DEBUG FOR UFT END **");

    if (this.oldDetailsData.store.length > 0) {
      this.oldDetailsData.store.forEach((each) => {
        each["vesselScheduleId"] = this.model.vesselScheduleId;
        if (each.terminalCode == "") {
          each.terminalCode = "ZZZZZ";
          each.terminalName = "OPTIONAL";
        }

        this.model.oldMasterVesselScheduleDetails.push(each);
      });
    }

    this.model.doNotCalculate = this.doNotCalculate;
    // console.log(this.model);
    this.saveEventForOrphanedCargoes();
  }

  updateDetails(type: string) {
    if (this.gpc.listStore.store.length > 0) {
      this.reCalculateDetailsDate();
    }

    /*if($("#prevLoopId").val() == ''){
      $("#nextLoopId").val('')
      this.model.servicePreviousVoyage = '';
      this.model.serviceNextVoyage = '';
    }*/
  }

  reCalculateDetailsDate() {
    if (this.model.previousVoyage != "") {
      if (!this.doNotCalculate) {
        //this.updateAllDetailsData(this.model.previousVoyage,this.model.MasterVesselScheduleDetails[0]);
        if (this.gpc.listStore.store.length > 0) {
          this.updateAllDetailsData(
            this.model.previousVoyage,
            this.gpc.listStore.store[0]
          );
        }
      }
    } else if (this.model.servicePreviousVoyage != "") {
      //this.updateAllDetailsData(this.model.servicePreviousVoyage,this.model.MasterVesselScheduleDetails[0]);
      if (this.gpc.listStore.store.length > 0) {
        this.updateAllDetailsData(
          this.model.servicePreviousVoyage,
          this.gpc.listStore.store[0]
        );
      }
    } else {
      //this.updateDetailsData(this.model.MasterVesselScheduleDetails[0],1);
      if (this.gpc.listStore.store.length > 0) {
        this.updateDetailsData(this.gpc.listStore.store[0], 1);
      }
    }
  }

  radioChange(type, status) {
    if (type == "slot") {
      //this.modeSlot = true;
      this.model.vesselIsSlot = true;
      this.model.vesselIsSsl = false;
      this.model.vesselIs3rdParty = false;

      /*this.model.vesselSlotOwner1 = '';
      this.model.vesselSlotOwner2 = '';
      this.model.vesselSlotOwner3 = '';*/
    } else if (type == "ssl") {
      //this.modeSlot = true;
      this.model.vesselIsSlot = false;
      this.model.vesselIsSsl = true;
      this.model.vesselIs3rdParty = false;

      /*this.model.vesselSlotOwner1 = '';
      this.model.vesselSlotOwner2 = '';
      this.model.vesselSlotOwner3 = '';*/
    } else if (type == "3p") {
      //this.modeSlot = true;
      this.model.vesselIsSlot = false;
      this.model.vesselIsSsl = false;
      this.model.vesselIs3rdParty = true;
    }
  }

  radioChange2(type, status) {
    if (type == "sslot") {
      this.modeSlot = true;
      this.model.serviceIsSlot = true;
      this.model.serviceIsSsl = false;
      this.model.serviceIs3rdParty = false;

      this.model.vesselSlotOwner1 = "";
      this.model.vesselSlotOwner2 = "";
      this.model.vesselSlotOwner3 = "";
    } else if (type == "sssl") {
      this.modeSlot = true;
      this.model.serviceIsSlot = false;
      this.model.serviceIsSsl = true;
      this.model.serviceIs3rdParty = false;

      this.model.vesselSlotOwner1 = "";
      this.model.vesselSlotOwner2 = "";
      this.model.vesselSlotOwner3 = "";
    } else if (type == "s3p") {
      this.modeSlot = false;
      this.model.serviceIsSlot = false;
      this.model.serviceIsSsl = false;
      this.model.serviceIs3rdParty = true;
    }
  }

  doChangeService1() {
    /*this.message(
      "information",
      "Information",
      "Please take a note that once you change your service, the connection between the previous and the next voyage will be removed. Proceed to Change Service?",
      "yesno",
      { yes: "this.checkBookingIfExist();", no: "" }
    );*/
    //this.message1("information","Information","Please take a note that once you change your service, the connection between the previous and the next voyage will be removed. Proceed to Change Service?","yesnoCoupled",{yes:'this.doChangeServiceAction();',no:'this.doChangeServiceAction();'},"information", "Information","Retain ETA?","yesno");

    this.dialogMessage.showPrompterDialog(
      "information",
      "Information",
      "Please take a note that once you change your service, the connection between the previous and the next voyage will be removed. Proceed to Change Service?",
      [{name:"YES",event:"this.checkBookingIfExist();"},{name:"NO",event:""}]
    );

  }

  noMan() {
    $("#d1").find("#dialog").modal("hide");
  }

  checkBookingIfExist(){
    var uri = this.configService.config.BASE_API.toString() + "/MasterBookings/checkBookingByPortOfCall";
    var msg = "";
    this.pocMap=[];
    this.postParam["vesselId"] = this.model.vesselId;
    this.postParam["voyage"] = this.model.baseVoyage;
    this.postParam["portOfcallList"] = [];
    if(this.gpc.listStore.store.length > 0){
      this.gpc.listStore.store.forEach((each)=>{
        this.postParam["portOfcallList"].push(each.portOfCall);
      });
    }

    console.log("novyan");
    console.log(this.postParam);

    this.genericService.POST(uri, this.postParam).subscribe((resp)=>{
      if(resp.ok){
        var data = resp.json();
        if(data["status"] == "OK"){
          var ttlBkg = 0;
          console.log(data["result"]);
          if(data["result"] != undefined){
            let i : number = 0;
            this.gpc.listStore.store.forEach((each)=>{
              if(data["result"][each.portOfCall] != undefined){
                ttlBkg = ttlBkg + parseInt(data["result"][each.portOfCall]);
                if(parseInt(data["result"][each.portOfCall]) > 0){
                  msg = msg + each.portOfCall + ",";
                  console.log("DATA RESULT : "+data["result"]);
                  //this.pocMap.set(i.toString(),each.portOfCall);
                  this.pocMap.push(each.portOfCall);
                }
              }
              i++;
            });
          }
          msg = msg.substring(0, msg.length-1);
          console.log("total BKG "+ttlBkg);
          if(ttlBkg > 0){
            this.dialogMessage.showPrompterDialog(
              "information",
              "Information",
              "There are bookings taken for "+msg+". Do you want to continue?",
              [{name:"YES",event:"this.doChangeService2();"},{name:"NO",event:""}]
            );

            /*this.message(
              "information",
              "Information",
              "There are bookings taken for "+msg+". Do you want to continue?",
              "yesno",
              { yes: "this.doChangeService2();", no: "" }
            );*/
          } else {
            this.doChangeService2();
          }
        } else {
          this.doChangeService2();
        }
      } else {
        this.doChangeService2();
      }
    })

  }

  arrayDiff (a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }
    for (var k in a) {
        diff.push(k);
    }
    return diff;
  }

  checkBookingIfExistOnSave(){
    if(this.pocMap.length > 0){
      var msg = "";
      let totalDif:number=0;
      var a :any[]=[];
      var b :any[]=[];
      var c :any[]=[];


      this.gpc.listStore.store.forEach((each)=>{
        a.push(each.portOfCall);

      });

      this.pocMap.forEach((popeach)=>{
        b.push(popeach);
      });


      c=this.arrayDiff(a,b);

      b.forEach(el=>{
        c.forEach(ele => {
          if(el==ele){
            msg = msg + ele + ",";
            totalDif = totalDif+1;
            console.log("totalDif :"+totalDif);
          }
        });
      });

      console.log("A :"+a);
      console.log("B :"+b);
      console.log("C :"+c);
      console.log("DIFFERENT :"+this.arrayDiff(a,b));


      msg = msg.substring(0, msg.length-1);
      if(totalDif > 0){
        console.log('aktif gak neh2 : ', this.changeServiceProceed);
        if (this.changeServiceProceed) {
          this.model['ServiceValidator'] = this.setValidatorWithService()
        }

        if (this.modeForm == "schedule") {
          if (!this.modeDetail) {
            // todo - add custom api to change the bookings tied to this vessel to "orphaned"
            this.handleSaveForOrphanBookings();
            console.log("orphaning bookings: " + this.postParam)
            // const url = this.configService.config.BASE_API.toString() + "/MasterBookings/bookingsToOrphanedFlag";
            // this.genericService.POST(url, this.postParam).subscribe((resp) => {
            //   if(resp.ok) {

            //   }
            // })

            // this.message(
            //   "information",
            //   "Information",
            //   "There are bookings taken for "+msg+". Please add these Port into Port of Call list.",
            //   "okonly",
            //   { ok: "" }
            // );
          }else{
            this.message(
              "information",
              "Alert",
              "You need to finished add or update detail first!",
              "okonly",
              { ok: '// console.log("oke clicked")' }
            );

          }
        }
      }else{
        this.handleSave();
      }
    }else{
      this.handleSave();
    }

  }

  doChangeService2() {
    this.dialogMessage.showPrompterDialog(
      "information",
      "Information",
      "Retain ETA?",
      [{name:"YES",event:"this.resetService(true);"},{name:"NO",event:"this.resetService(false);"}]
    );
  }

  resetService(retain: boolean) {
    if (retain) {
      this.retainEta = this.gpc.listStore.store[0].peta;
    }

    this.changeServiceProceed = true
    this.serviceLock = false;
    this.srv.setValue("");
    this.model.serviceId = "";
    this.model.serviceCode = "";
    this.model.serviceSequence = "";
    this.model.servicePreviousVoyage = "";
    this.model.serviceNextVoyage = "";
    this.model.previousVoyage = "";
    this.model.nextVoyage = "";
    this.model.sBound = "";
    $("#idSeq").dropdown("clear");
  }

  doChangeServiceAction() {
    // console.log('jalan');
    //this.resetService();
  }

  retrieveHistory() {
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVesselSchedules/findGeneral/*/history/" +
      this.model.vesselCode.trim() +
      this.sCharUtil.htmlEncode(this.model.baseVoyage) +
      "/1/100/*/*";
    var store = [];
    this.gh.listStore.store.splice(0, this.gh.listStore.store.length);
    this.genericService.GET(hitUrl).subscribe((resp) => {
      if (resp.ok) {
        store = resp.json()["content"];
        if (store.length > 0) {
          store.forEach((st) => {
            this.gh.listStore.addData(st);
          });
          this.historyLock = false;
          this.gh.loadData();
        }
      }
    });
  }

  generateServiceHistoryReport() {
    if (this.model.vesselCode.trim() == "" || this.model.vesselCode.trim() == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-History-Vessel-Schedule-"+this.model.vesselCode.trim()+"-"+this.sCharUtil.htmlEncode(this.model.baseVoyage)+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/50/History-Vessel-Schedule-"
      +
      this.model.vesselCode.trim() +
      "-" +
      this.sCharUtil.htmlEncode(this.model.baseVoyage) +
      "/" +
      this.model.vesselCode.trim() +
      "@" +
      this.sCharUtil.htmlEncode(this.model.baseVoyage)
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // window.open(
    //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
    //   +"Mreport/runReport/50/History-Vessel-Schedule-" +
    //     this.model.vesselCode.trim() +
    //     "-" +
    //     this.sCharUtil.htmlEncode(this.model.baseVoyage) +
    //     "/" +
    //     this.model.vesselCode.trim() +
    //     "@" +
    //     this.sCharUtil.htmlEncode(this.model.baseVoyage)
    // );
  }

  setBVoyage(value: string) {
    this.model.baseVoyage = value;
  }

  setHVoyage(value: string) {
    this.model.baseVoyage = value;
  }

  genDefaultChangeDateTimeScript(id: string) {
    var elTime = $(id).find("#time");
    var elDate = $(id).find("#date");

    elDate.blur(function () {
      //// console.log('gombah : '+$(this).val());
      if (id == "#PETD") {
      }
    });
  }

  genChangeTimeScript(id: string) {
    var elTime = $(id).find("#time");
    var elDate = $(id).find("#date");

    elTime.change(function () {
      if (id == "#PETA") {
        $("#ETA").find("#date").val(elDate.val());
        $("#ETA").find("#time").val(elTime.val());
      }

      var stringDate = elDate.val() + " " + $(this).val();

      var year = parseInt(stringDate.substring(0, 4)); // get year
      var month = parseInt(stringDate.substring(4, 6)) - 1; // get month
      var day = parseInt(stringDate.substring(6, 8)); // get date
      var hour = parseInt(stringDate.split(" ")[1].substring(0, 2)); // get hour
      var minute = parseInt(stringDate.split(" ")[1].substring(2)); // get minute

      var dtDate = new Date(year, month, day, hour, minute);

      var at = 0;
      if (id == "#PETA") {
        at =
          parseInt($("#petdDays").val()) * 24 + parseInt($("#petdHour").val());
      } else if (id == "#ETA") {
        at = parseInt($("#etdDays").val()) * 24 + parseInt($("#etdHour").val());
      }
      // console.log('xx' + at);

      dtDate.setHours(dtDate.getHours() + at);
      var sYear = dtDate.getFullYear();
      var sMonth =
        (dtDate.getMonth() + 1 < 10 ? "0" : "") + (dtDate.getMonth() + 1);
      var sDay = (dtDate.getDate() < 10 ? "0" : "") + dtDate.getDate();
      var sHour = (dtDate.getHours() < 10 ? "0" : "") + dtDate.getHours();
      var sMinute = (dtDate.getMinutes() < 10 ? "0" : "") + dtDate.getMinutes();

      if (id == "#PETA") {
        $("#PETD")
          .find("#date")
          .val(sYear + sMonth + sDay);
        $("#PETD")
          .find("#time")
          .val(sHour + sMinute);

        stringDate =
          $("#ETA").find("#date").val() + " " + $("#ETA").find("#time").val();
        year = parseInt(stringDate.substring(0, 4)); // get year
        month = parseInt(stringDate.substring(4, 6)) - 1; // get month
        day = parseInt(stringDate.substring(6, 8)); // get date
        hour = parseInt(stringDate.split(" ")[1].substring(0, 2)); // get hour
        minute = parseInt(stringDate.split(" ")[1].substring(2)); // get minute

        dtDate = new Date(year, month, day, hour, minute);
        at = parseInt($("#etdDays").val()) * 24 + parseInt($("#etdHour").val());
        dtDate.setHours(dtDate.getHours() + at);

        sYear = dtDate.getFullYear();
        sMonth =
          (dtDate.getMonth() + 1 < 10 ? "0" : "") + (dtDate.getMonth() + 1);
        sDay = (dtDate.getDate() < 10 ? "0" : "") + dtDate.getDate();
        sHour = (dtDate.getHours() < 10 ? "0" : "") + dtDate.getHours();
        sMinute = (dtDate.getMinutes() < 10 ? "0" : "") + dtDate.getMinutes();

        $("#ETD")
          .find("#date")
          .val(sYear + sMonth + sDay);
        $("#ETD")
          .find("#time")
          .val(sHour + sMinute);
      } else if (id == "#ETA") {
        $("#ETD")
          .find("#date")
          .val(sYear + sMonth + sDay);
        $("#ETD")
          .find("#time")
          .val(sHour + sMinute);
      }
    });

    if (id == "#PETA") {
      //this.countHour('etdHour','etdDays');
    }
  }

  switchCombo(stat) {
    if (stat) {
      this.bsVoyMode = "ext";
      this.lock = true;
    } else {
      this.bsVoyMode = "new";
      //this.lock = false;
    }
  }

  bsVoyageAction(value: string) {
    console.log(value)
    if (value != "" || value != undefined) {
      this.model.baseVoyage = value;
      this.model["error-baseVoyage"] = "";
    }
  }

  bsSloopAction(value: string, type: string) {
    // console.log('nilai value : '+value+' type '+type);
    if (type == "prev") {
      this.model.servicePreviousVoyage = value;
    }
    if (type == "next") {
      this.model.serviceNextVoyage = value;
    }
  }

  baseVoyCheck() {
    if (this.formValidation.regVesVoy.test(this.model.baseVoyage) == false)
      return null;

    if (!this.model.baseVoyage || !this.model.vesselCode) return null;

    console.log(this.model.vesselCode);

    if (this.model.baseVoyage != "" && this.model.vesselCode != "") {
      if (!this.createLock) {
        this.loading = true;
        this.model.baseVoyage = this.model.baseVoyage.toUpperCase();
        var hiturl =
          this.configService.config.BASE_API.toString() +
          "/MasterVesselSchedules/findGeneral/Y/*/" +
          this.model.vesselCode.trim() +
          "*" +
          this.sCharUtil.htmlEncode(this.model.baseVoyage.trim()) +
          "/1/25/*/*";
        var isExist = false;
        //if (!this.onValidate(this.model)) {
        this.genericService.GET(hiturl).subscribe((resp) => {
          if (resp.ok) {
            var tempData = resp.json()["content"];
            var dataRetrieve: any;
            if (parseInt(resp.json()["size"]) > 0) {
              tempData.forEach((td) => {
                if (
                  td.vesselCode.trim() == this.model.vesselCode.trim() &&
                  td.voyage.trim() == this.model.baseVoyage.trim()
                ) {
                  isExist = true;
                }
              });

              if (isExist) {
                //if(this.model.createdDate == ''){
                //  this.message('information','Information','Base Voyage already Exist', 'okonly',{ok:''});
                //  this.model.baseVoyage = '';
                //}
                this.retrieveData();
              } else {
                this.serviceLock = false;
                this.voyageLock = true;
                this.loading = false;
              }
            } else {
              this.serviceLock = false;
              this.voyageLock = true;
              this.loading = false;
            }
          } else {
            this.serviceLock = false;
            this.voyageLock = true;
            this.loading = false;
          }
        });
        //}
      }
    }
  }

  calcDate(comp: string) {
    // checkDate
    var waktu ;
    if (this.isDateValid) {
      if (comp == "peta.date") {
        $("#ETA").find("#date").val($("#PETA").find("#date").val());
        if ($("#PETA").find("#time").val() == "") {
          $("#PETA").find("#time").val("0000");
          $("#ETA").find("#time").val("0000");
        }

        this.countHour("petdHour", "petdDays");
        this.countHour("etdHour", "etdDays");
      } else if (comp == "peta.time") {
        $("#ETA").find("#time").val($("#PETA").find("#time").val());

        this.countHour("petdHour", "petdDays");
        this.countHour("etdHour", "etdDays");

        waktu = $("#PETA").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#PETA").find("#time").val(waktu);
          $("#ETA").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#PETA").find("#time").val("0000");
          $("#ETA").find("#time").val("0000");
        }


      } else if (comp == "petd.date") {
        if ($("#PETD").find("#time").val() == "") {
          $("#PETD").find("#time").val("0000");
        }
        this.countHour("petdHour", "petdDays");
      } else if (comp == "petd.time") {
        this.countHour("petdHour", "petdDays");
        waktu = $("#PETD").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#PETD").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#PETD").find("#time").val("0000");
        }
      } else if (comp == "eta.date") {
        if ($("#ETA").find("#time").val() == "") {
          $("#ETA").find("#time").val("0000");
        }
        this.countHour("etdHour", "etdDays");
      } else if (comp == "eta.time") {
        this.countHour("etdHour", "etdDays");
        waktu = $("#ETA").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#ETA").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#ETA").find("#time").val("0000");
        }
      } else if (comp == "etd.date") {
        this.countHour("etdHour", "etdDays");
      } else if (comp == "etd.time") {
        this.countHour("etdHour", "etdDays");
        waktu = $("#ETD").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#ETD").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#ETD").find("#time").val("0000");
        }
      }else if (comp == "atb.time") {
        waktu = $("#ATA").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#ATA").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#ATA").find("#time").val("0000");
        }
      }else if (comp == "atd.time") {
        waktu = $("#ATD").find("#time").val();
        if(waktu.length > 4){
          waktu = waktu.substring(0,4);
          $("#ATD").find("#time").val(waktu);
        }

        if(waktu.length < 4){
          this.message(
            "information",
            "Information",
            "Invalid time format inserted, please check your data again!",
            "okonly",
            { ok: "" }
          );
          $("#ATD").find("#time").val("0000");
        }
      }
    } else {
      this.isDateValid = true;
    }



  }

  setSeqEvent(event: any) {}

  preCheckDate(el) {
   console.log("## - "+el.size);
    this.isDateValid = true;
    var dateData = el.value;

    if (dateData != "") {
      var year = parseInt(dateData.substring(0, 4)); // get year
      var month = parseInt(dateData.substring(4, 6)) - 1; // get month
      var day = parseInt(dateData.substring(6, 8)); // get date

      if (month > 11) {
        this.isDateValid = false;
      } else if (day > 31) {
        this.isDateValid = false;
      }
    }

    if (!this.isDateValid) {
      this.message(
        "information",
        "Information",
        "Invalid date format inserted, please check your data again!",
        "okonly",
        { ok: "" }
      );
      el.value = "";
    }

    if(dateData.length > 8){
      el.value = parseInt(dateData.substring(0,8));
    }

    if(dateData.length < 8){
      this.message(
        "information",
        "Information",
        "Invalid date format inserted, please check your data again!",
        "okonly",
        { ok: "" }
      );
      el.value = "";

    }
  }

  doSomeSearch(element: any) {
    $("#mn").transition("fade up").transition("show");
  }

  changeEventVesselCopy2(event) {
    if (event.vesselId != null) {
      this.cbVoyageCopy.disableCombo = false;
      this.cbVoyageCopy.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          event.vesselId +
          "/O/{query}/" +
          this.office.officeCode
      );
      this.cbVoyageCopy.setValue("");

      this.copy2Model.vesselId = event.vesselId;
      this.copy2Model.vesselCode = event.vesselCode;
      this.copy2Model.vesselName = event.vesselName;
    } else {
      this.copy2Model.vesselId = "";
      this.copy2Model.vesselCode = "";
      this.copy2Model.vesselName = "";
    }
  }

  changeEventVoyageCopy2(event) {
    if (event.voyage != null) {
      this.copy2Model.baseVoyage = event.voyage;
    } else {
      this.copy2Model.baseVoyage = "";
    }
  }

  firstInit() {
    // console.log("office : "+this.cook.getCookie("defaultLocation"));

    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    this.office.officeCode = this.cook.getDefaultLocationCode(); //localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook
      .getCookie("defaultLocation")
      .split("|")[0];

    // loadVoyage
    var hitUrl =
      this.configService.config.BASE_API.toString() +
      "/MasterVessels/findGeneral/Y/*/*/1/1000/*/*";

    this.loading = true;
    this.genericService.GET(hitUrl).subscribe(
      (resp) => {
        if (resp.ok) {
          this.vesselTempStore.store = resp.json()["content"];
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  handlePopupTransmit(){
    document.getElementById('filter-text-box').style.display = 'none';
    this.gridApiTransmit.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value = ''
    );

    this.modelTransmitEdi = new MasterVesselTransmitEdi();
    this.genericUtil.showDialog(
      "transmitForm",
      // `Mapping ${this.model.vesselName} V. ${
      //   this.gridHeader.getSelectedValues()[0].voyage
      // }`,
      "TRANSMIT EDI",
      800,
      500
    );
  }

  initTabTransmit1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "unActive";
  }

  initTabTransmit2() {
    this.tabStatus = "tab2";
    this.tabStatus1 = "unActive";
    this.tabStatus2 = "active";
  }

  changeEventVesselTransmit(event) {
      this.modelTransmitEdi["error-vesselId"] = "";

      if (
        event.vesselId == null ||
        event.vesselId == "" ||
        event.vesselId == undefined
      ) {
        this.modelTransmitEdi.vesselId = "";
        this.modelTransmitEdi.vesselCode = "";
        this.modelTransmitEdi.vesselName = "";
        this.modelTransmitEdi.voyage = "";
        this.cbVoyageTransmit.setUrl("");
        this.cbVoyageTransmit.setValue("");

      }else{
        this.modelTransmitEdi.vesselId = event.vesselId;
        this.modelTransmitEdi.vesselCode = event.vesselCode;
        this.modelTransmitEdi.vesselName = event.vesselName;
        // console.log(this.modelTransmitEdi);

        this.cbVoyageTransmit.disableCombo = false;

        this.cbVoyageTransmit.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findByComboBoxControlVoyageForPsaPortnet/" +
            this.modelTransmitEdi.vesselId +
            "/" +
            "O" +
            "/" +
            "{query}" +
            "/" +
            this.office.officeCode
        );
      }

      this.cbVesselTransmit.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxVessel/vesselName={query}"
      );
  }

  changeEventVoyageTransmit(event) {
    this.modelTransmitEdi["error-voyage"] = "";
    this.modelTransmitEdi.voyage = event.voyage;
    this.modelTransmitEdi.scheduleId = event.scheduleId;
    this.modelTransmitEdi.serviceName = event.serviceName;

    this.cbVoyageTransmit.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByComboBoxControlVoyageForPsaPortnet/" +
        this.modelTransmitEdi.vesselId +
        "/" +
        "O" +
        "/" +
        "{query}" +
        "/" +
        this.office.officeCode
    );

  }

  onTransmitEdi_Cancel(){
    this.genericUtil.closeDialog("transmitForm");
    // this.clearFormTransmitEdi();
    this.isErrorTransmitEdi = false;
    this.btnGenerate = true;
    this.btnEdiUpload = true;
    this.rowDataTransmit = [];
    this.gridApiTransmit.setRowData([]);
    this.ediFileUpload.store = [];
    this.ediFileUpload.errorFile = "";
    this.modelTransmitEdi = new MasterVesselTransmitEdi();
  }

  onTransmitEdi_Add(){
    let num = 0;
    let newRowDataTransmit = this.rowDataTransmit.slice();
    this.setValidatorAddTransmitEdi();

    this.isErrorTransmitEdi = this.onValidate(this.modelTransmitEdi);

    if(!this.isErrorTransmitEdi){
      newRowDataTransmit.push(this.modelTransmitEdi);

      newRowDataTransmit.map((item, i) => {
        num = num + 1;
        newRowDataTransmit[i].seq = num;
      })


      this.rowDataTransmit = newRowDataTransmit;
      console.log("grid = ", this.gridTransmitEdi);
      this.modelTransmitEdi = new MasterVesselTransmitEdi;
      this.clearFormTransmitEdi();
      this.btnGenerate = false;
    }
  }

  onTransmitEdi_Remove(){
    var rowCount = [];
    this.gridApiTransmit.forEachNode(function (node){
      rowCount.push(node.data);
    })

    if(rowCount.length == 0){
      this.message(
        "warning",
        "Warning",
        "There is no data",
        "okonly",
        {ok : ""}
      );
      return;
    }

    if (this.gridApiTransmit.getSelectedRows().length == 0) {
      this.message(
        "information",
        "information",
        "Please select row to delete.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    } else {
      this.message(
        "information",
        "Information",
        "Are you sure you want to delete this? ",
        "yesno",
        {
          yes: "this.onTransmitEdi_Delete();",
          no: "this.gridApiTransmit.deselectAll();",
        }
      );
    }
  }

  onTransmitEdi_Delete(){
    console.log("masuk");
    var num = 0;
    var rowDataTransmitDelete = this.rowDataTransmit.slice();
    var selectedDataTransmit = this.gridApiTransmit.getSelectedRows();
    var sequenceSelected = selectedDataTransmit.slice();
    var arraySelected = [];

    console.log("slice = ", sequenceSelected[0].serviceName);

    for(let x = 0; x < sequenceSelected.length; x++){
      console.log(sequenceSelected[x]);
      arraySelected.push(sequenceSelected[x].seq);
    }

    for(let x = 0;x < rowDataTransmitDelete.length; x++){
      console.log("rowData = ", rowDataTransmitDelete[x].seq);
      // this.RemoveElementFromObjectArray(x);

      arraySelected.forEach((el) => {
        if(el == rowDataTransmitDelete[x].seq){
          console.log(el, "true");
          rowDataTransmitDelete.splice(
            rowDataTransmitDelete.findIndex(v => v.seq == rowDataTransmitDelete[x].seq),1
          );

        }else{
          console.log(el, "false");
        }
      });
    }

    rowDataTransmitDelete.map((item, i) => {
      num = num + 1;
      rowDataTransmitDelete[i].seq = num;
    })

    this.rowDataTransmit = rowDataTransmitDelete;


    this.clearFormTransmitEdi();

    if(this.rowDataTransmit.length == 0){
      this.btnGenerate = true;
    }
  }

  onSelectionChanged(event){}

  onGridReadyTransmit(params){
    this.gridApiTransmit = params.api;
  }

  onTransmitEdi_Retrieve(){
    this.loading = true;
    var uri = this.configService.config.BASE_API.toString() + "/MasterVesselSchedules/generateIFTSAIEDI";

    this.genericService.GET(uri).subscribe((resp) => {
      if (resp.ok) {
        let retrieveDataTransmit: any = [];
        const res = resp.json()["content"];
        retrieveDataTransmit = retrieveDataTransmit.slice();

        res.map((item, i) => {
          let tempRow: any = {};
          tempRow["seq"] = i + 1;
          tempRow["scheduleId"] = item.scheduleId;
          tempRow["serviceId"] = item.serviceId;
          tempRow["serviceName"] = item.serviceName;
          tempRow["vesselCode"] = item.vesselCode;
          tempRow["vesselName"] = item.vesselName;
          tempRow["voyage"] = item.voyage;
          retrieveDataTransmit.push(tempRow);
        });

        this.rowDataTransmit = retrieveDataTransmit;
        this.btnGenerate = false;
        this.loading = false;

        document.getElementById('filter-text-box').style.display = 'block';
        this.gridApiTransmit.setQuickFilter(
          (document.getElementById('filter-text-box') as HTMLInputElement).value = ''
        );
      }
    });
  }

  onFilterTextBoxChanged() {
    this.gridApiTransmit.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  // onTransmitEdi_Generate(){
  //   console.log("GENERATE!");
  //   console.log(this.rowDataTransmit);
  //   this.loading = true;

  //   var uri = this.configService.config.BASE_API.toString() + "/?q=/ediController/transmit/IFTSAI";
  //   var uri2 =  "http://10.10.100.85:12000/ediController/transmit/IFTSAI";

  //   if(this.rowDataTransmit.length > 0){
  //     //KALO DATA CUMA 1
  //     if(this.rowDataTransmit.length == 1){
  //       var token = localStorage.getItem('token') // Will return if it is not set
  //       const headers = new HttpHeaders({
  //         'token': token
  //       });

  //       this.httpClient.get(
  //         uri2+"/"+this.rowDataTransmit[0].scheduleId,
  //         {
  //           headers:headers,
  //           observe:'response',
  //           responseType:'blob' as 'json'
  //         }
  //       )
  //       .subscribe((resp: any) => {
  //           if(resp.ok){
  //             var headers = resp.headers;
  //             var contentDisposition = headers.get('content-disposition');
  //             var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
  //             var blob = new Blob([resp.body], {type: 'text/plain'});
  //             FileSaver.saveAs(blob, filename);
  //             this.loading = false;
  //             this.onTransmitEdi_Cancel();
  //           }
  //       },
  //       error => {
  //         console.log("errornya ", error );
  //         this.message(
  //           "warning",
  //           "Warning",
  //           error.message,
  //           "okonly",
  //           { ok: "this.loading=false;this.onTransmitEdi_Cancel();" }
  //         );
  //       });
  //     }

  //     // KALO LEBIH DARI 1
  //     if(this.rowDataTransmit.length > 1){
  //       var self = this;
  //       var zip = new JSZip();
  //       var count = 0;
  //       var count2 = 0;
  //       var urls = [];
  //       var names = [];
  //       var zipFilename = "TransmitEdi_"+Date.now()+".zip";

  //       this.rowDataTransmit.map((item, i) => {
  //         urls.push(uri2+"/"+this.rowDataTransmit[i].scheduleId);
  //         names.push(
  //           "IFTSAI-SAMPLE_" +
  //           this.rowDataTransmit[i].vesselName.trim() +
  //           "_" +
  //           this.rowDataTransmit[i].scheduleId.trim() +
  //           ".EDI"
  //         );
  //       });

  //       urls.forEach(function(url, i){
  //         console.log("url nya " , url);
  //         count2++;
  //         var filename = names[i];
  //         JSZipUtils.getBinaryContent(url, function (err, data) {
  //           if (err) {
  //               self.message(
  //                 "warning",
  //                 "Warning",
  //                 "Download Failed! Connection Error!",
  //                 "okonly",
  //                 { ok: "this.loading=false;this.onTransmitEdi_Cancel();" }
  //               );
  //               throw err; // or handle the error
  //           }
  //           try{
  //             zip.file(filename, data, { binary: true });
  //             count++;
  //             if (count == urls.length) {
  //               console.log("SUKSES");
  //               self.loading = false;
  //               zip.generateAsync({ type: "blob" }).then(function (content) {
  //                 FileSaver.saveAs(content, zipFilename);
  //               });
  //               self.onTransmitEdi_Cancel();
  //             }
  //           } catch (e) {
  //             console.log("errorrr...k", e);
  //             self.message(
  //               "warning",
  //               "Warning",
  //               e,
  //               "okonly",
  //               { ok: "this.loading=false;this.onTransmitEdi_Cancel();" }
  //             );
  //           }
  //         });
  //       });
  //     }
  //   }
  // }

  onTransmitEdi_Generate2(){
    let scheduleIdArray: any = [];
    let scheduleIdArray2: any = [];

    let tempGridApiTransmit = this.gridApiTransmit.getSelectedRows();
    this.loading = true;

    var uri = this.configService.config.BASE_API.toString() + "?q=/ediController/transmit/IFTSAI";
    var uri2 =  "http://10.10.100.85:12000/ediController/transmit/IFTSAI";

    if(tempGridApiTransmit.length > 0){

      tempGridApiTransmit.map((item, i) => {
        scheduleIdArray.push(item.scheduleId);
      });

      var token = localStorage.getItem('token') // Will return if it is not set
      const headers = new HttpHeaders({
        'token': token
      });

      this.httpClient.post(
        uri,
        scheduleIdArray,
        {
          headers:headers,
          observe:'response',
          responseType:'blob' as 'json'
        }
      )
      .subscribe((resp: any) => {
          if(resp.ok){
            var headers = resp.headers;
            var contentDisposition = headers.get('content-disposition');
            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            var blob = new Blob([resp.body], {type: 'text/plain'});
            FileSaver.saveAs(blob, filename);
            this.loading = false;
            this.onTransmitEdi_Cancel();
          }
      },
      error => {
        console.log("errornya ", error );
        this.message(
          "warning",
          "Warning",
          error.message,
          "okonly",
          { ok: "this.loading=false;this.onTransmitEdi_Cancel();" }
        );
      });

    }else{
      console.log("data 0");
      this.message(
        "warning",
        "Warning",
        "No Data Selected",
        "okonly",
        { ok: "this.loading=false;" }
      );
    }
  }

  clearFormTransmitEdi(){
    this.cbVesselTransmit.setValue("");
    this.cbVoyageTransmit.setValue("");
  }

  uploadEventEdiFile(event){
    var list;
    var ext;
    if (event.includes(".edi")) {
      list = event.split(".edi");
      ext = ".edi";
    } else if (event.includes(".EDI")) {
      list = event.split(".EDI");
      ext = ".EDI";
    }

    console.log(event);
    if(event.includes("-")){
      var temp = event.split("-");
      switch(temp.pop().trim()){
        case "uploaded":
          this.btnEdiUpload = false;
          break;
        case "deleted":
          this.btnEdiUpload = true;
          this.ediFileUpload.store = [];
          break;
      }
    } else {
      switch(event){
        case "success":
          break;
        default:
          break;
      }
    }
  }

  onTransmitEdi_Upload(){
    console.log("Updated");
  }

  gridCopy2Event(event) {}

  infoGridDetails(event) {}

  loadVesselData() {
    var htmlData = "";
    this.vesselTempStore.store.forEach((fe) => {
      htmlData =
        htmlData +
        "<div class='item' data-value='" +
        fe.vesselId +
        "' style='font-size:10px'>" +
        fe.vesselName +
        "</div>";
    });
    $(".cmb-combo").find(".menu").append(htmlData);
  }

  clearDialog() {
    $("body")
      .find(".ui.dimmer.modals.page")
      .find("div")
      .each(function (index) {
        if ($(this).attr("id") == "cloneCopy") {
          $(this).remove();
        }
        if ($(this).attr("id") == "myDialog") {
          $(this).remove();
        }
        if ($(this).attr("id") == "myDialog2") {
          $(this).remove();
        }
        if ($(this).attr("id") == "testDialog") {
          $(this).remove();
        }
        if ($(this).attr("id") == "addRowDialogVessel") {
          $(this).remove();
        }
        if ($(this).attr("id") == "copyDialogVessel") {
          $(this).remove();
        }
      });
  }

  errorVesVoy(event: any, field: string) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.formValidation.regVesVoy.test(pastedText) == false) {
      this.model[`error-${field}`] = "Only allowed special characters .-/";
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string) {
    if (this.formValidation.regVesVoy.test(this.model[field]) == true) {
      this.model[`error-${field}`] = "";
    }
  }

  eventCustomDialog(event){
    //console.log(event);
    if(event != ""){
      eval(event);
    }
  }

  setValidatorAddTransmitEdi() {
    this.clearValidatorErrorTransmitEdi();
    this.validatorRules = {
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
    };
  }

  clearValidatorErrorTransmitEdi() {
    this.modelTransmitEdi["error-vesselId"] = "";
    this.modelTransmitEdi["error-voyage"] = "";
  }

  changesBound(value){
    if (value && this.formValidation.regStringOnly.test(value) == false) {
      this.model['error-sBound'] = "Only allow alphabets";
    }else{
      this.model['error-sBound'] = ""
    }
  }

}
