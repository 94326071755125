import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { ContainerIsoMapping } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-master-container-iso-mapping-page",
  templateUrl: "./master-container-iso-mapping.component.html",
  styleUrls: ["./master-container-iso-mapping-page.component.css"],
})
export class MasterContainerIsoMappingPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbContainerIsoMappingCodeHistory")
  cbContainerIsoMappingCodeHistory: ComboPluginComponent;
  @ViewChild("cbContainerIsoMappingSize")
  cbContainerIsoMappingSize: ComboPluginComponent;
  @ViewChild("cbContainerIsoMappingType")
  cbContainerIsoMappingType: ComboPluginComponent;
  @ViewChild("cbContainerIsoMappingHeight")
  cbContainerIsoMappingHeight: ComboPluginComponent;
  @ViewChild("cbContainerIsoMappingQuality")
  cbContainerIsoMappingQuality: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  // * Form Validation
  formValidation = new FormValidation();

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingContainerType;
  settingContainerSize;
  settingContainerHeight;
  settingContainerQuality;
  settingContainerIsoMappingLogStartDate;
  settingContainerIsoMappingLogEndDate;
  settingContainerIsoMappingHistory;

  /* Misc Parameters */
  valContainerIsoMappingCode: string = "";
  valContainerIsoMappingId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  valType: string = "";
  valSize: string = "";
  valHeight: string = "";
  valQuality: string = "";
  valContainerType: string = "";
  valContainerSize: string = "";
  valContainerHeight: string = "";

  typeContainerIsoMappingLogStartDate = "calendar";
  typeContainerIsoMappingLogEndDate = "calendar";

  /* Parameter model */
  model = new ContainerIsoMapping();
  //selectList = [];

  loading = false;

  lock = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    containerIsoMappingCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input ISO Code.",
        },
        // {
        //   type: "length[10]",
        //   prompt: "ISO Code must be 10 characters long.",
        // },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9 ]*$/,
          prompt: "Please input alphanumeric only",
        },
      ],
    },
    containerIsoMappingSize: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Container Size.",
        },
      ],
    },
    containerIsoMappingType: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Container Type.",
        },
      ],
    },
    containerIsoMappingHeight: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Container Height.",
        },
      ],
    },
    containerIsoMappingWeight: {
      rules: [
        {
          type: "positiveInteger",
          prompt: "No negative value!",
        },
        {
          type: "emptyZero",
          prompt: "Please input Container Weight.",
        },
        // {
        //   type: "empty",
        //   prompt: "Please input Container Height.",
        // },
      ],
    },
    containerIsoMappingQuality: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Container Quality.",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          prompt: "Please input valid pattern",
          pattern: this.formValidation.regAllChar,
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "ISO Code", value: "containerIsoMappingCode" },
        { display: "Type", value: "containerIsoMappingType" },
        { display: "Size", value: "containerIsoMappingSize" },
        { display: "Height", value: "containerIsoMappingHeight" },
        { display: "Weight", value: "containerIsoMappingWeight" },
        { display: "Quality", value: "containerIsoMappingQuality" },
        {
          display: "Reefer Indicator",
          value: "containerIsoMappingReeferIndicator",
        },
        { display: "Remarks", value: "remarks" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" },
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingContainerType = {
      id: "cbContainerType",
      type: "select input",
      url: "assets/json/containerType_iso.json",
      placeholder: "Select Container Type",
      code: "containerTypeCode",
      title: "containerType",
    };

    this.settingContainerSize = {
      id: "cbContainerSize",
      type: "select input",
      url: "assets/json/containerSize.json",
      placeholder: "Select Container Size",
      code: "containerSizeCode",
      title: "containerSize",
    };

    this.settingContainerHeight = {
      id: "cbContainerHeight",
      type: "select input",
      url: "assets/json/containerHeight.json",
      placeholder: "Select Container Height",
      code: "containerHeightCode",
      title: "containerHeight",
    };

    this.settingContainerQuality = {
      id: "cbContainerQuality",
      type: "select input",
      url: "assets/json/containerQuality.json",
      placeholder: "Select Container Quality",
      code: "containerQualityCode",
      title: "containerQuality",
    };

    this.settingContainerIsoMappingLogStartDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingContainerIsoMappingLogEndDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterContainerIsoMapping/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "ISO Code", field: "containerIsoMappingCode", width: 15 },
        { header: "Type", field: "containerIsoMappingType", width: 15 },
        { header: "Size", field: "containerIsoMappingSize", width: 15 },
        { header: "Height", field: "containerIsoMappingHeight", width: 15 },
        { header: "Weight", field: "containerIsoMappingWeight", width: 15 },
        { header: "Quality", field: "containerIsoMappingQuality", width: 15 },
        {
          header: "Reefer Indicator",
          field: "containerIsoMappingReeferIndicator",
          width: 15,
        },
        { header: "Remarks", field: "remarks", width: 15 },
        { header: "User Created", field: "userCreated", width: 15 },
        { header: "Date Created", field: "dateCreated", width: 15 },
        { header: "User Modified", field: "userModified", width: 15 },
        { header: "Date Modified", field: "dateModified", width: 15 },
        { header: "User Invalid", field: "userInvalid", width: 15 },
        { header: "Date Invalid", field: "dateInvalid", width: 15 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterContainerIsoMapping/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "History Id", field: "containerIsoMappingId", width: 15 },
        { header: "ISO Code", field: "containerIsoMappingCode", width: 15 },
        { header: "Type", field: "containerIsoMappingType", width: 15 },
        { header: "Size", field: "containerIsoMappingSize", width: 15 },
        { header: "Height", field: "containerIsoMappingHeight", width: 15 },
        { header: "Weight", field: "containerIsoMappingWeight", width: 15 },
        { header: "Quality", field: "containerIsoMappingQuality", width: 15 },
        {
          header: "Reefer Indicator",
          field: "containerIsoMappingReeferIndicator",
          width: 15,
        },
        { header: "Remarks", field: "remarks", width: 15 },
        { header: "User Created", field: "userCreated", width: 15 },
        { header: "Date Created", field: "dateCreated", width: 15 },
        { header: "User Modified", field: "userModified", width: 15 },
        { header: "Date Modified", field: "dateModified", width: 15 },
        { header: "User Invalid", field: "userInvalid", width: 15 },
        { header: "Date Invalid", field: "dateInvalid", width: 15 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      
      enablePagination: true,
    };

    this.settingContainerIsoMappingHistory = {
      id: "cbContainerIsoMappingCodeHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterContainerIsoMapping/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "containerIsoMappingCode",
      description: "",
      isMark: true,
      columns: [
        { header: "History Id", field: "containerIsoMappingId", width: 50 },
        { header: "ISO Code", field: "containerIsoMappingCode", width: 50 },
        { header: "Type", field: "containerIsoMappingType", width: 50 },
        { header: "Size", field: "containerIsoMappingSize", width: 50 },
        { header: "Height", field: "containerIsoMappingHeight", width: 50 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingContainerIsoMappingLogStartDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };

    this.settingContainerIsoMappingLogEndDate = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
    };
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
    /* tinymce.init(
      {selector: 'textarea#tiny', menubar:false, elementpath: false}
    );*/
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.firstInit();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = "*/*/*";
    this.grid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  gridEvent(event: any) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;

      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";
  
              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.containerIsoMappingId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";
    this.cbContainerIsoMappingHeight.setValue("");
    this.cbContainerIsoMappingQuality.setValue("");
    this.cbContainerIsoMappingSize.setValue("");
    this.cbContainerIsoMappingType.setValue("");

    $('#cbContainerType').find(".text").html(this.cbContainerIsoMappingType.placeholder);
    $('#cbContainerSize').find(".text").html(this.cbContainerIsoMappingSize.placeholder);
    $('#cbContainerHeight').find(".text").html(this.cbContainerIsoMappingHeight.placeholder);
    $('#cbContainerQuality').find(".text").html(this.cbContainerIsoMappingQuality.placeholder);
    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new ContainerIsoMapping();
    this.valContainerIsoMappingCode = "";
    this.valContainerType = "";
    this.valContainerSize = "";
    this.valContainerHeight = "";
    this.valQuality = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event

    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"containerIsoMappingId":"","containerIsoMappingCode":""};
    //   deleteObj.containerIsoMappingId = element.containerIsoMappingId;
    //   deleteObj.containerIsoMappingCode = element.containerIsoMappingCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBooking/checkingListContainerIsoMappingUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {
            
    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }
            
    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  handlePrint() {
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReport/27/ContainerIsoMapping-Export-Reports/*"
    // );
    // handle print event
    var formatName = "report-ContainerIsoMapping-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/27/ContainerIsoMapping-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation: boolean = this.onValidate(this.model);
    if (this.model.containerIsoMappingWeight == 0 || !this.model.containerIsoMappingWeight) {
      this.model["error-containerIsoMappingWeight"] = "Please Input Container Weight.";
    }
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      
      if (this.model.containerIsoMappingWeight == 0 || !this.model.containerIsoMappingWeight) {
        // this.message(
        //   "warning",
        //   "Error input",
        //   "Please Input Container Weight.",
        //   "okonly",
        //   { ok: "this.loading=false;" }
        // );
        this.model["error-containerIsoMappingWeight"] = "Please Input Container Weight.";
      } else if (
        (this.model.containerIsoMappingType == "REF" ||
          this.model.containerIsoMappingType == "REEFER") &&
        !this.model.containerIsoMappingReeferIndicator
      ) {
        this.message(
          "warning",
          "Error input",
          "Please specify Reefer Indicator.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        // console.log("PSI Date => " + this.genericUtil.getPSIDate());
        this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          {
            yes: "this.saveEvent()",
            no: "this.loading = false;",
          }
        );
      }
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();
    this.cbContainerIsoMappingType.setValue("");
    this.cbContainerIsoMappingSize.setValue("");
    this.cbContainerIsoMappingHeight.setValue("");
    this.cbContainerIsoMappingQuality.setValue("");
    
    $('#cbContainerType').find(".text").html(this.cbContainerIsoMappingType.placeholder);
    $('#cbContainerSize').find(".text").html(this.cbContainerIsoMappingSize.placeholder);
    $('#cbContainerHeight').find(".text").html(this.cbContainerIsoMappingHeight.placeholder);
    $('#cbContainerQuality').find(".text").html(this.cbContainerIsoMappingQuality.placeholder);
    if (this.valContainerIsoMappingId != "") {
      this.valContainerIsoMappingId = "";
      this.valContainerIsoMappingCode = "";
      this.cbContainerIsoMappingCodeHistory.setValue("");
      this.cbContainerIsoMappingCodeHistory.close();
      this.gridHistory.onHistory("NaN");
      this.gridHistory.loadData();
    }
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
    this.model = new ContainerIsoMapping();
    this.valContainerType = "";
    this.valContainerSize = "";
    this.valContainerHeight = "";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new ContainerIsoMapping();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    if (data.containerIsoMappingReeferIndicator == "Y") {
      this.model.containerIsoMappingReeferIndicator = true;
    } else {
      this.model.containerIsoMappingReeferIndicator = false;
    }

    this.model.containerIsoMappingId = String(data.containerIsoMappingId)
      .trim()
      .toUpperCase();
    this.model.containerIsoMappingCode = String(data.containerIsoMappingCode)
      .trim()
      .toUpperCase();
    this.model.containerIsoMappingType = String(data.containerIsoMappingType)
      .trim()
      .toUpperCase();
    this.model.containerIsoMappingSize = String(data.containerIsoMappingSize)
      .trim()
      .toUpperCase();
    this.model.containerIsoMappingHeight = String(
      data.containerIsoMappingHeight
    )
      .trim()
      .toUpperCase();
    this.model.containerIsoMappingWeight = Number.parseInt(
      data.containerIsoMappingWeight
    );
    this.model.containerIsoMappingQuality = String(
      data.containerIsoMappingQuality
    )
      .trim()
      .toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    if (String(data.containerIsoMappingType).trim().toUpperCase() == "DRY") {
      this.cbContainerIsoMappingType.setValue("DRY");
      $('#cbContainerType').find(".text").html("DRY");
    } else if (
      String(data.containerIsoMappingType).trim().toUpperCase() == "REF"
    ) {
      this.cbContainerIsoMappingType.setValue("REEFER");
      $('#cbContainerType').find(".text").html("REEFER");
      // this.cbContainerIsoMappingType.setForceValue("REEFER");
    } else if (
      String(data.containerIsoMappingType).trim().toUpperCase() == "TNK"
    ) {
      this.cbContainerIsoMappingType.setValue("TANK");
      $('#cbContainerType').find(".text").html("TANK");
    } else if (
      String(data.containerIsoMappingType).trim().toUpperCase() == "FLR"
    ) {
      this.cbContainerIsoMappingType.setValue("FLATRACK");
      $('#cbContainerType').find(".text").html("FLATRACK");
    } else if (
      String(data.containerIsoMappingType).trim().toUpperCase() == "PLT"
    ) {
      this.cbContainerIsoMappingType.setValue("PLATFORM");
      $('#cbContainerType').find(".text").html("PLATFORM");
    } else if (
      String(data.containerIsoMappingType).trim().toUpperCase() == "OPT"
    ) {
      this.cbContainerIsoMappingType.setValue("OPEN TOP");
      $('#cbContainerType').find(".text").html("OPEN TOP");
    } else if (String(data.containerIsoMappingType).trim().toUpperCase() == "STD") {
      this.cbContainerIsoMappingType.setValue("NORMAL");
      $('#cbContainerType').find(".text").html("NORMAL");
    }

    this.cbContainerIsoMappingSize.setValue(
      String(data.containerIsoMappingSize).trim().toUpperCase()
    );
    $('#cbContainerSize').find(".text").html(String(data.containerIsoMappingSize).trim().toUpperCase());

    this.cbContainerIsoMappingHeight.setValue(
      String(data.containerIsoMappingHeight).trim().toUpperCase()
    );
    $('#cbContainerHeight').find(".text").html(String(data.containerIsoMappingHeight).trim().toUpperCase());

    this.cbContainerIsoMappingQuality.setValue(
      String(data.containerIsoMappingQuality).trim().toUpperCase()
    );
    $('#cbContainerQuality').find(".text").html(String(data.containerIsoMappingQuality).trim().toUpperCase());

    this.model.remarks = String(data.remarks).trim().toUpperCase();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterContainerIsoMapping/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);

          if (resp.status == 200 && resp.json()["message"] == "OK") {
            this.model = new ContainerIsoMapping();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            //this.grid.clearSelectedValues();
            this.grid.onFirst();

            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.containerIsoMappingId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"containerIsoMappingId":[]}
    paramsDelete.containerIsoMappingId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/MasterContainerIsoMapping/delete",
        // deleteList
        "/MasterContainerIsoMapping/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new ContainerIsoMapping();
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  valueType(event: any) {
    this.model["error-containerIsoMappingType"] = "";
    this.model.containerIsoMappingType = event.containerTypeCode;
    this.model.typeCode = event.containerType;
    this.valType = this.model.containerIsoMappingType;
  }

  valueSize(event: any) {
    this.model["error-containerIsoMappingSize"] = "";
    this.model.containerIsoMappingSize = event.containerSize;
    this.model.sizeCode = event.containerSizeCode;
    this.valSize = this.model.containerIsoMappingSize;
  }

  valueHeight(event: any) {
    this.model["error-containerIsoMappingHeight"] = "";
    this.model.containerIsoMappingHeight = event.containerHeight;
    this.model.heightCode = event.containerHeightCode;
    this.valHeight = this.model.containerIsoMappingHeight;
  }

  valueQuality(event: any) {
    this.model["error-containerIsoMappingQuality"] = "";
    this.model.containerIsoMappingQuality = event.containerQuality;
    this.model.qualityCode = event.containerQualityCode;
    this.valQuality = this.model.qualityCode;
  }

  valueContainerIsoMappingHistory(event: any) {
    // console.log("event = " + event);
    if (event != null) {
      this.valContainerIsoMappingCode = event["containerIsoMappingCode"];
      this.valContainerIsoMappingId = event["containerIsoMappingId"];
      this.valContainerType = event["containerIsoMappingType"];
      this.valContainerSize = event["containerIsoMappingSize"];
      this.valContainerHeight = event["containerIsoMappingHeight"];
      // console.log("containerIsoMapping code= " + event["containerIsoMappingCode"]);

      this.gridHistory.onHistory(this.valContainerIsoMappingId);
    }
  }

  generateContainerIsoMappingReport(event: any) {
    if (
      this.valContainerIsoMappingId == "" ||
      this.valContainerIsoMappingId == "undefined"
    ) {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      var formatName = "report-History-ContainerIsoMapping-"+this.valContainerIsoMappingId+"-"+this.valContainerIsoMappingCode +".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/28/History-ContainerIsoMapping-"
        +
        this.valContainerIsoMappingId +
        "-" +
        this.valContainerIsoMappingCode +
        "/" +
        this.valContainerIsoMappingId +
        "@" +
        this.valHistoryStartDate +
        "@" +
        this.valHistoryEndDate
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000")  +
      //     "/Mreport/runReport/28/History-ContainerIsoMapping-" +
      //     this.valContainerIsoMappingId +
      //     "-" +
      //     this.valContainerIsoMappingCode +
      //     "/" +
      //     this.valContainerIsoMappingId +
      //     "@" +
      //     this.valHistoryStartDate +
      //     "@" +
      //     this.valHistoryEndDate
      // );
    }
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  infoGridHistory(event: any) {
    this.info = event;
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }
}