import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Bank } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, TextareaPluginComponent, ToolbarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare  var $:any;

@Component({
  selector: 'app-local-master-bank-page',
  templateUrl: './local-master-bank-page.component.html',
  styleUrls: ['./local-master-bank-page.component.css']
})

export class LocalMasterBankPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;
  @ViewChild('cbBankNameHistory') cbBankNameHistory:ComboPluginComponent;
  @ViewChild('txtBankAddress') txtBankAddress:TextareaPluginComponent;
  @ViewChild('cbBankDisplay') cbBankDisplay:ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = true;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingBankHistory;
  settingBankAddress;
  settingBankDisplay;


  /* Misc Parameters */
  valBankCode:string = '';
  valBankName:string = '';
  valBankId:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';
  officeCode:string = "";

  hitUrl:string = this.configService.config.BASE_API.toString();

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* Parameter model */
  model = new Bank;
  //selectList = [];

  loading = false;
  lock = false;

  valid:string = 'Y';

  /* validatorRules */
  validatorRules = {
    bankStandardClause: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Bank Standard Clause.'
        }
      ]
    },
    bankSwiftCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank Swift Code.'
        }
      ]
    },
    bankName: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank Name.'
        }
      ]
    },
    bankCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank Code.'
        }
      ]
    },
    bankAddress: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank Address.'
        }
      ]
    },
    bankBranch: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank Branch.'
        }
      ]
    },
    bankUSDAccNo: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Bank USD Acc No.'
        }
      ]
    },
    bankSGDAccNo: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input bank SGD Acc No.'
        }
      ]
    },
    checkValidasi: {
      rules: [
          {
              type : 'custom',
              prompt : ''
          }
      ]
    }

  }
  isUpdate;

  currentActiveBankId: string = '';

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService, private _http: HttpClient) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        {display: 'Standard Clause', value: 'bankStandardClause'},
        {display: 'SWIFT Code', value: 'swiftCode'},
        {display: 'Bank Name', value: 'bankName'},
        {display: 'Bank Code', value: 'bankCode'},
        {display: 'Bank Address', value: 'bankAddress'},
        {display: 'Branch', value: 'bankBranch'},
        {display: 'USD Acc No', value: 'bankUsdAccNo'},
        {display: 'SGD Acc No', value: 'bankSgdAccNo'},
        {display: 'Remarks', value: 'bankRemarks'},
        {display: 'User Created', value: 'userCreated'},
        {display: 'Date Created', value: 'dateCreated'},
        {display: 'User Modified', value: 'userModified'},
        {display: 'Date Modified', value: 'dateModified'},
        {display: 'User Invalid', value: 'userInvalid'},
        {display: 'Date Invalid', value: 'dateInvalid'},
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
      //url: this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneral',
      url:'',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Standard Clause', field: 'bankStandardClause', width: 200, type:'string'},
        {header: 'SWIFT Code', field: 'swiftCode', width: 100, type:'string'},
        {header: 'Bank Name', field: 'bankName', width: 100, type:'string'},
        {header: 'Bank Code', field: 'bankCode', width: 100, type:'string'},
        {header: 'Bank Address', field: 'bankAddress', width: 100, type:'string'},
        {header: 'Branch', field: 'bankBranch', width: 100, type:'string'},
        {header: 'USD Acc. No', field: 'bankUsdAccNo', width: 100, type:'string'},
        {header: 'SGD Acc. No', field: 'bankSgdAccNo', width: 100, type:'string'},

        {header: 'Remarks', field: 'bankRemarks', width: 300, type:'string'},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
        /*{header: 'Is Printed Inovice?', field: 'isPrintedInvoice', width: 50},*/
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'bankName',
      sortingDirection: 'ASC'
    }

    this.settingGridHistory={
      id:'bankHistory',
      url: this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralBankDetailsHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Bank Id', field: 'bankId', width: 100, type:'string'},
        {header: 'Bank Name', field: 'bankName', width: 100, type:'string'},
        {header: 'Bank Code', field: 'bankCode', width: 100, type:'string'},
        {header: 'Bank Address', field: 'bankAddress', width: 100, type:'string'},
        {header: 'Remarks', field: 'bankRemarks', width: 300, type:'string'},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
        {header: 'Is Printed Inovice?', field: 'isPrintedInvoice', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true
    }

    this.settingBankHistory = {
      id          : 'cbBankNameHistory',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneralHistory/{query}',
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'bankName',
      description : '',
      isMark      : true,
      columns     : [
        {header: 'Bank Id', field: 'bankId', width:100 },
        {header: 'Bank Name', field: 'bankName', width: 150, type:'string'},
        {header: 'Bank Code', field: 'bankCode', width: 100, type:'string'},
        {header: 'Remarks', field: 'remarks', width: 150, type:'string'},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }


    this.settingBankAddress = {
      id          : 'txtBankAddress',
      name        : 'bankAddress',
      placeholder : 'Bank Address',
      cols        : 30,
      rows        : 10,
      maxlength   : 260,
      border      : 400
    }

  //   this.settingBankDisplay = {
  //     id          : 'cbBankDisplay',
  //     type        : 'select', // search | select | select input
  //     url         : '',
  //     placeholder : 'Bank to Display in Invoice',
  //     code        : 'bankCode',
  //     title       : 'bankName'
  //  }

  this.settingBankDisplay = {
    id          : 'cbBankDisplay',
    type        : 'select', // search | select | select input
    url         : '',
    urlType     : 'dynamic',
    placeholder : '',
    code        : 'bankId',
    title       : 'bankName',
    allowRemove : false,
    showOnTop: true
  }


    /*
     this.settingClauseLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingClauseLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    this.firstInit();

    this.officeCode = this.cookieService.getDefaultLocationCode().toString().trim();
    console.log("office", this.officeCode)
    this.grid.url = this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralBankDetails/' + this.officeCode;
    this.grid.loadData();


    this.bankDetailsInvoice();

    //this.cbBankNameHistory.setUrl(this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneraBankDetailslHistory/'+this.officeCode+'/{query}')
    //this.grid.onFirst();

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];

    this.modeForm = false;
  }

  bankDetailsInvoice(){
    var hiturl = this.configService.config.BASE_API.toString() + '/MasterCurrencies/findBankPrintedInovice/' + this.officeCode;
    this.genericService.GET(hiturl).subscribe((resp) =>{
      console.log(resp);
      if(resp['content'] != null || resp['content'] != ''){
        var listTempContent = []
        listTempContent = resp.json()['content'];

        listTempContent.forEach(eleem=>{
          console.log(eleem.bankName);
          this.currentActiveBankId = eleem.bankId;
          this.cbBankDisplay.setValue(eleem.bankName);

        })

      }
    })

    this.cbBankDisplay.setUrl(this.configService.config.BASE_API.toString() + '/MasterCurrencies/findBankDetails/' + this.officeCode + '/' + this.valid);
    this.cbBankDisplay.loadSelect();

  }

  firstInit(){
    this.currentMenuID = this.cookieService.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
  }


  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event){
    this.info=event;
  }

  gridEvent(event) {
    console.log(event)
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      // case 'afterLoad':
      //   this.grid.url = this.configService.config.BASE_API.toString() + '/MasterClauses/findGeneral';
      //   break;
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {

              if(this.viewOnlyStatus){
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "delete";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
              this.txtBankAddress.disabled = true;
              this.txtBankAddress.error = false;
            }
          }

        }

        break;
    }
  }

  gridEventHistory(event){
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        if(this.model.bankId != ""){
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          //this.grid.search = this.officeCode+'/'+str.replace("search:","");
          this.grid.search = str.replace("search:","");

          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.lock=false;
    this.txtBankAddress.disabled = false;
    this.txtBankAddress.error = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Bank;
    //this.model.clauseName = '12345678901234567890123456789012\r34567890123456789012345678901234\r567\r\r234 345234 234123 12312 121 123 \r1231';
    this.valBankCode='';
    this.valBankName='';
  }


  handleUpdate()
  {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock=false;
      this.txtBankAddress.disabled = false;
      this.txtBankAddress.error = false;
      this.disableToolbarButtons = "delete";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete()
  {
    // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"bankId":"","bankCode":""};
    //   deleteObj.bankId = element.bankId;
    //   deleteObj.bankCode = element.bankCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListLocalMasterBankUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );

    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    //window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/63/Local-Master-Bank-Details/*');
    // window.open('http://glossys.samudera.com:10000/Mreport/runReport/65/Local-Master-Bank-Details/*');
    // handle print event
    var path = '63/Local-Master-Bank-Details/*';
    var filename = 'Local-Master-Bank-Details';
    this.downloadFileFromServer(path, filename, "xls", true);
  }

  handleSave()
  {
    // handle save event
    // console.log('handleSave');
    // this.checkStandardClause();
    // this.checkBankName();
    // this.checkSwiftCode();
    // this.checkBankCode();
    // this.checkBranch();
    // this.checkSGD();
    // this.checkUSD();
    this.model['error-bankStandardClause']= ""
    this.model['error-bankSwiftCode']= ""
    this.model['error-bankName']= ""
    this.model['error-bankCode']= ""
    this.model['error-bankAddress']= ""
    this.model['error-bankBranch']= ""
    this.model['error-bankUSDAccNo']= ""
    this.model['error-bankSGDAccNo']= ""
    this.model['error-checkValidasi']= ""
    this.model['checkValidasi'] = this.checkValidator();
    this.model.bankAddress = this.txtBankAddress.getValue();

    /*
     var self = this;
     $("#frmTextarea").submit(function(event) {
     var value = $(this).serialize().replace(/%20/g," ").replace(/%0D%0A/g,"↵");
     var txt = value.substring(11,value.length);

     var lines = txt.split("↵");

     for (var x = 0; x < lines.length; x++) {

     var counter:number = 1;
     var limit:number = 32;

     for (var i = 0; i < lines[x].length; i++) {
     if((i % limit) === 0){
     if(counter == 1){
     lines[x] = self.insert(limit,"↵",lines[x]);
     }else{
     lines[x] = self.insert(limit + 1 ,"↵",lines[x]);
     }
     counter = counter + 1;
     limit = limit * counter;
     }
     }

     // console.log(lines[x]);

     }

     var result:string = lines.join("↵");
     // console.log('result = ' + result);
     self.model.clauseName = result;

     event.preventDefault();

     });
     $("#frmTextarea").submit();
     */

    console.log(this.model)
    let isPassedValidation:boolean = this.onValidate(this.model);
    // console.log(this.model['error-clauseName']);
    // if(this.model['error-clauseName'] != '' ){
    //   this.txtBankAddress.error = true;
    // }else{
    //   this.txtBankAddress.error = false;
    // }
    // console.log('isPassedValidation ' + isPassedValidation);
    if(!isPassedValidation){
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.model = new Bank;
    this.txtBankAddress.error = false;
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    // if(this.valClauseId != '') {
    this.valBankCode = '';
    this.valBankId = '';

    this.cbBankNameHistory.setValue("");
    this.cbBankNameHistory.close();


    this.txtBankAddress.setValue("");
    this.gridHistory.onHistory("NaN")
    this.gridHistory.loadData();
    // }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.url = this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralBankDetails/' + this.officeCode;
    this.grid.onFirst();

    this.bankDetailsInvoice();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }



  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
    this.cbBankNameHistory.setUrl(this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneraBankDetailsHistory/'+this.officeCode+'/{query}')

  }

  setData(){
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new Bank;

    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }

    if(data.isPrintedInvoice=='Y'){
      this.model.isPrintedInvoice=true;
    }else{
      this.model.isPrintedInvoice=false;
    }


    console.log("data",data)

    this.model.bankId = String(data.bankId).trim().toUpperCase();
    this.model.bankStandardClause = String(data.bankStandardClause).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankSwiftCode = String(data.swiftCode).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankName = String(data.bankName).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankCode = String(data.bankCode).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankAddress = String(data.bankAddress).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.txtBankAddress.setValue(this.model.bankAddress);
    this.model.bankBranch = String(data.bankBranch).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankUSDAccNo = String(data.bankUsdAccNo).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankSGDAccNo = String(data.bankSgdAccNo).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.bankRemarks = String(data.bankRemarks).trim().toUpperCase().replace(/&NBSP;/g," ");

    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.officeCode = this.officeCode;



  }

  valueBankHistory(event){
    if(event["bankCode"] != null) {
      this.valBankCode = event["bankCode"];
      this.valBankId = event["bankId"];
      // console.log("curr code= " + event["clauseCode"]);

      let tmp = String(event["bankCode"]).toUpperCase().replace(/&NBSP;/g," ");
      this.valBankCode=tmp;

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valBankId);
    }
  }

  generateBankHistoryReport(event:any){
    //this.valHistoryStartDate= $('#logHistoryClauseStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryClauseEndDate').find('#datePicker').val();

    if(this.valBankId == '' || this.valBankId == 'undefined'){
      this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    }else {
      //window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/62/History-Bank-' + this.valBankId  + '/'+this.valBankId);
      //window.open('http://glossys.samudera.com:10000/Mreport/runReport/64/History-Bank-' + this.valBankId  + '/'+this.valBankId);
      // var path = '62/History-Bank-' + this.valBankId  + '/'+this.valBankId;
      // var filename = 'History-Bank-' + this.valBankId;
      // this.downloadFileFromServer(path, filename, "xls", true);

      // var splitUrl = this.configService.config.getPDFUrl.split(":");
      // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/62/History-Bank-' +
      //  this.valBankId  +
      //  '/'+
      //  this.valBankId
      // );

      var formatName = "report-History-Bank-"+this.valBankId+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/62/History-Bank-"
        +
        this.valBankId  +
        '/'+
        this.valBankId
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });

    }
  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    // console.log('saveEvent');
    this.loading = true;
    this.model.officeCode = this.officeCode;

    console.log("#2", this.model)

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterCurrencies/saveBankDetails',this.model).subscribe((resp) =>{
      // console.log('STATUS=' + resp.status);
      // console.log('MESSAGE=' + resp.json()['message']);
      if(resp.status == 200 && resp.json()['message'] == 'OK'){
        // console.log("ENTER SAVE");
        this.model = new Bank;
        this.txtBankAddress.setValue("");
        this.loading = false;
        this.info = 'save successfully';
        //this.setGridEvent = 'refresh';
        this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
      }else{
        this.loading = false;
        this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
      this.loading = false;
    });
  }

  deleteEvent(){
    // console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';

    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.bankId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"bankId":[]}
    paramsDelete.bankId = deleteList;

    this.genericService.POST(
      this.configService.config.BASE_API.toString() +
      // '/MasterCurrencies/deleteBankDetails',
      // deleteList
      '/MasterCurrencies/softDeleteBankDetails',
      paramsDelete
      )
      .subscribe((resp) =>{
      if(resp.status == 200){
        this.model = new Bank;
        this.txtBankAddress.setValue("");
        //  this.selectList = [];
        this.loading = false;
        this.grid.clearSelectedValues();
        this.grid.onFirst();
        //this.setGridEvent = 'refresh';
        this.loading=true;
        this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});


      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  noneSpace(event:any){
    // console.log("Event none => " + event);
    if(event.keyCode == 32){
      event.preventDefault();
    }
  }

  checkStandardClause(): boolean{
    var specialChar = /[$+:;=?@#|<>^*%!~{}_\'\"\[\]\/\\]/
    // var alpha = '[a-zA-Z0-9&-()`,.]'
    let result:boolean = true;

    if(this.model.bankStandardClause.match(specialChar)){
      result = false;
    }

    return result;
  }

  checkBankName(): boolean{
    var specialChar = /[$+:;=?@#|<>^*%!~{}_\'\"\[\]\/\\]/

    let result:boolean = true;

    if(this.model.bankName.match(specialChar) ){
        result = false;
        console.log("masuk 2")
    }

    return result;
  }

  checkSwiftCode(): boolean{
    var specialChar = /[$+:&;()`,.=?@#|<>^*%!~{}_\'\"\[\]\/\\]/
    let result:boolean = true;

    if(this.model.bankSwiftCode.match(specialChar) ){
      result = false;
      console.log("masuk 3")
    }

    return result;
  }

  checkBankCode(): boolean{
    var specialChar = /[$+:&;()`,.=?@#|<>^*%!~{}_\'\"\[\]\/\\]/

    let result:boolean = true;

    if(this.model.bankCode.match(specialChar) ){
        result = false;
        console.log("masuk 4")
    }

    return result;
  }

  checkBranch(): boolean{
    var specialChar = /[$+:&;()`,.=?@#|<>^*%!~{}_\'\"\[\]\/\\-]/
    let result:boolean = true;

    if(this.model.bankBranch.match(specialChar) ){
      result = false;
      console.log("masuk 5")
    }

    return result;
  }

  checkUSD(): boolean{
    var specialChar = /[$+:&;()`,.=?@#|<>^*%!~{}_\'\"\[\]\\]/
    var alpha = /[a-zA-Z]/


    let result:boolean = true;

    if(this.model.bankUSDAccNo.match(specialChar) || this.model.bankUSDAccNo.match(alpha) ){
        result = false;
        console.log("masuk 6")
    }

    return result;
  }

  checkSGD(): boolean{
    var specialChar = /[$+:&;()`,.=?@#|<>^*%!~{}_\'\"\[\]\\]/
    var alpha = /[a-zA-Z]/
    let result:boolean = true;

    if(this.model.bankSGDAccNo.match(specialChar) || this.model.bankSGDAccNo.match(alpha) ){
        result = false;
        console.log("masuk 7")
    }

    return result;
  }

  checkValidator():boolean{
    let result:boolean = false;
    let arr:any[] = []

    var flag = 0;

    if(!this.checkStandardClause()){
        arr.push("<br/> Please input Standard Clause contains alphanumeric only and & ( ) - ‘ , . allowed <br/>")
        flag = flag + 1
    }
    if(!this.checkBankName()){
        arr.push("<br/> Please input Bank Name contains alphanumeric only and & ( ) - ‘ , . allowed <br/>")
        flag = flag + 1
    }
    if(!this.checkSwiftCode()){
        arr.push("<br/> Please input Swift Code contains alphanumeric only <br/>")
        flag = flag + 1
    }
    if(!this.checkBankCode()){
        arr.push("<br/> Please input Bank Code contains alphanumeric only and - allowed <br/>")
        flag = flag + 1
    }
    if(!this.checkBranch()){
      arr.push("<br/> Please input Branch contains alphanumeric only <br/>")
      flag = flag + 1
    }
    if(!this.checkUSD()){
      arr.push("<br/> Please input USD Acc No. number(s) only and - / allowed <br/>")
      flag = flag + 1
    }
    if(!this.checkSGD()){
      arr.push("<br/> Please input SGD Acc No. number(s) only and - / allowed <br/>")
      flag = flag + 1
    }

    this.model['error-checkValidasi']= ""
    arr.forEach(element => {
        console.log(element);
        this.model['error-checkValidasi'] += element ;
    });

    console.log('flag' + flag)
    if(flag > 0){
        result = true
    }

    return result;

  }

  downloadFileFromServer(path:string, fileName:string, fileExt:string, loading:boolean){
    loading = true;
    var dwnldUrl = this.hitUrl + "?q=/Mreport/runReport/" + path;
    this._http.get(dwnldUrl,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{
      var docName = fileName+"."+fileExt;
      FileSaver.saveAs(res, docName);
      loading = false;
    }, error => {
      this.message("Information","information","An Error occured, please contact your administrator!","okonly",{ok:""});
      loading = false;
    });
  }

  changeEventBankDisplay(event){
    console.log("dor")
    console.log(event);
    this.currentActiveBankId = event.code;
    var hiturl = this.configService.config.BASE_API.toString() + '/MasterCurrencies/getBankDetails/' + this.officeCode + '/' + event.code;
    this.genericService.GET(hiturl).subscribe((resp) =>{
      const res = resp.json();
       if(res.content === 'berhasil update'){
         this.message('Information','Changes to Bank Details on Invoice Display','Changes has been saved successfully.','okonly',{ ok:'this.loading = false'});
       }else{
         this.message('Information','Changes to Bank Details on Invoice Display','Error occurred. Please contact your system support.','yesno',{ ok:'this.loading = false'});
       }
    })

  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

}
