import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { MasterNewsPage } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router'

declare  var $:any;

export class Email{
  to:string='';
  otp:string='';


  constructor(){};
}



@Component({
  selector: 'app-master-news-page',
  templateUrl: './master-news-page.component.html',
  styleUrls: ['./master-news-page.component.css']
})

export class MasterNewsPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;
  @ViewChild('cbTitleHistory') cbTitleHistory:ComboPluginComponent;
  @ViewChild('cbSubtitle') cbSubtitleHistory:ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingCurrencyLogStartDate;
  //settingCurrencyLogEndDate;
  settingNewsPageHistory;


  /* Misc Parameters */
  valNewsId:string = '';
  valTitle:string = '';
  valSubtitle:string = '';
  // valCurrencyCode:string = '';
  // valCurrencyName:string = '';
  // valCurrencyId:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';

  // typeCurrencyLogStartDate = 'calendar';
  // typeCurrencyLogEndDate = 'calendar';

   /* Parameter model */
  model = new MasterNewsPage;
  modelEmail = new Email;
  //selectList = [];

  loading = false;
  loadingForget = false;
  loadingOTP = false;

  lock = false;

  /* validatorRules */
  validatorRules = {
    title: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Title.'
          }
        ]
    },
    subtitle: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Subtitle'
        }
      ]
    },
    to: {
      rules: [
        {
          type: 'empty',
          prompt: 'Please input Email'
        }
      ]
    }
  }

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService, private router:Router) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        {display: 'Title', value: 'title'},
        {display: 'Subtitle', value: 'subtitle'},
        {display: 'Content', value: 'content'},
        {display: 'User Created', value: 'userCreated'},
        {display: 'Date Created', value: 'dateCreated'},
        {display: 'User Modified', value: 'userModified'},
        {display: 'Date Modified', value: 'dateModified'},
        {display: 'User Invalid', value: 'userInvalid'},
        {display: 'Date Invalid', value: 'dateInvalid'}
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterNewsPage/findGeneral',
        page: 10,
        columns: [
          {header: 'News ID', field: 'newsId', width: 15},
          {header: 'Title', field: 'title', width: 200},
          {header: 'Subtitle', field: 'subtitle', width: 50},
          {header: 'User Created', field: 'userCreated', width: 100},
          {header: 'Date Created', field: 'dateCreated', width: 100},
          {header: 'User Modified', field: 'userModified', width: 100},
          {header: 'Date Modified', field: 'dateModified', width: 100},
          {header: 'User Invalid', field: 'userInvalid', width: 100},
          {header: 'Date Invalid', field: 'dateInvalid', width: 100},
          {header: 'Valid?', field: 'isValid', width: 50}
        ],
        buttons: [
          { name: 'Edit', event: 'edit', enabled: true }
        ],
        //store: this.listStore,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'title',
        sortingDirection: 'ASC'
    }

    this.settingGridHistory = {
      url: this.configService.config.BASE_API.toString() + '/MasterNewsPage/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'News Id', field:'newsId', width:200},
        {header: 'Title', field: 'title', width: 200},
        {header: 'Subtitle', field: 'subtitle', width: 50},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true
    }

    this.settingNewsPageHistory = {
      id          : 'cbNewsPageHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterNewsPage/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'title',
      description : '',
      isMark      : true,
      columns     : [
        {header: 'News Id', field: 'newsId', width:50},
        {header: 'Title', field: 'title', width: 50},
        {header: 'Subtitle', field: 'subtitle', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }
/*
    this.settingCurrencyLogStartDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingCurrencyLogEndDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }
*/
  }

  ngOnInit(){
    $('.ui.accordion').accordion();
    document.getElementById("forgetPassword").style.display = "none";
    document.getElementById("otp-popup").style.display = "none";
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    this.disableToolbarButtons = "update,delete,print";
    this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    this.grid.search='*/*/*';
    this.grid.onFirst();

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  message2(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGridHistory(event:any){
    this.info = event;
  }

  gridEvent(event:any) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete,print";
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete,print";
        }
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){

            this.disableToolbarButtons = "print";
            this.invisibleToolbarButtons = "create,history";

            this.modeForm = true;
            this.modeToolbar = false;
            this.setData();
            this.lock = true;
          }

        }

        break;
    }
  }

  gridEventHistory(event:any){
    //do nothing
  }

  toolbarEvent(event:any) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        if(this.model.newsId == ""){
          this.model.userCreated = this.cookieService.getName();
        } else {
          this.model.userModified = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new MasterNewsPage;
      this.valTitle = '';
      this.valSubtitle = '';
  }


  handleUpdate()
  {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;
    this.setData();
    this.lock=false;
    this.disableToolbarButtons = "print";
    this.invisibleToolbarButtons = "create";
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/62/News-Log/*');
    // handle print event
  }

  handleSave()
  {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation:boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if(!isPassedValidation){
      /*Patch for local timing
      * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
      * 2. System should use local timing instead of server timing
      * 3. Format should be YYYY-MM-DD hh:mm:ss
      */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["userCreated"] = this.cookieService.getName();
      this.model["userModified"] = this.cookieService.getName();
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.model["dateModified"] = this.genericUtil.getPSIDate();
      this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.model =  new MasterNewsPage;
    this.disableToolbarButtons = "update,delete,print";
    this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    if(this.valNewsId != '') {
      this.valTitle = '';
      this.valSubtitle = '';
      this.cbTitleHistory.setValue("");
      this.cbSubtitleHistory.close();
      this.gridHistory.onHistory("NaN")
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete,print";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete,print";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }



  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }

  setData(){
      //SET MODEL DATA FOR UPDATE PROCESS
      let data = this.grid.getSelectedValues()[0];

      this.model = new MasterNewsPage;

      if(data.isValid=='Y'){
        this.model.isValid=true;
      }else{
        this.model.isValid=false;
      }

      this.model.newsId = String(data.newsId).trim().toUpperCase();
      this.model.title = String(data.title).trim().replace(/&nbsp;/g," ");
      this.model.subtitle = String(data.subtitle).trim().replace(/&nbsp;/g," ");
      this.model.content = String(data.content).trim();
      this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
      this.model.userCreated = String(data.userCreated).trim().toUpperCase();
      this.model.dateModified = String(data.dateModified).trim().toUpperCase();
      this.model.userModified = String(data.userModified).trim().toUpperCase();
      this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
      this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();

  }

  valueNewsPageHistory(event:any){
    this.valTitle=event["title"];
    this.valSubtitle=event["subtitle"];
    this.valNewsId=event["newsId"];
    // console.log("curr code= " + event["currencyCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valNewsId);
  }

  generateNewsPageHistoryReport(event:any){
    //this.valHistoryStartDate= $('#logHistoryCurrencyStartDate').find('#datePicker').val();
   // this.valHistoryEndDate= $('#logHistoryCurrencyEndDate').find('#datePicker').val();
    window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/4/History-NewsPage-' + this.valNewsId + '-' + this.valTitle +'/'+this.valNewsId + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);

  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    // console.log('saveEvent');
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterNewsPage/save',this.model).subscribe((resp) =>{
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if(resp.status == 200 && resp.json()['message'] == 'OK'){
            // console.log("ENTER SAVE");
            this.model = new MasterNewsPage;
            this.loading = false;
            this.info = 'save successfully';
            //this.setGridEvent = 'refresh';
            this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
          }else{
            this.loading = false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
      this.loading = false;
    });
  }

  deleteEvent(){
    // console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.newsId);
    });
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterNewsPage/delete',deleteList).subscribe((resp) =>{
          if(resp.status == 200){
              this.model = new MasterNewsPage;
            //  this.selectList = [];
              this.loading = false;
              this.grid.clearSelectedValues();
              this.grid.onFirst();
              //this.setGridEvent = 'refresh';
              this.loading=true;
              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});


          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  submitEmail(){
    this.loadingForget = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/verify/verifyEmail', this.modelEmail).subscribe((resp) =>{
      // console.log('STATUS=' + resp.status);
      // console.log('MESSAGE=' + resp.json()['message']);
        if(resp.status == 200 && resp.json()['status'] !== 'Email not found'){
          // console.log("ENTER SAVE");
          //this.modelEmail = new Email;
          this.loadingForget = false;
          this.info = 'submit successfully';
          //this.setGridEvent = 'refresh';
          //document.getElementById("otp-popup").style.display = "block";
          document.getElementById("forgetPassword").style.display = "none";
          this.message('information','Information','Submit successfully.', 'okonly', { ok: 'this.loadingForget=false; this.OTPForm(); this.form=""'});
        }else{
          this.loadingForget = false;
          this.closeForm();
          this.message('warning','Warning','Wrong Email.','okonly',{ok: 'this.loadingForget=false; this.forgetPassword(); this.form=""'});
        }
    },error => {
      this.loadingForget = false;
      // console.log(error.message);
      this.info = error.message;
      this.loadingForget = false;
    });
  }

  submitOTP(){
    this.loadingOTP = true;
    console.log(this.modelEmail);
        this.genericService.POST(this.configService.config.BASE_API.toString() + '/verify/checkOTP', this.modelEmail).subscribe((resp) =>{
          // console.log('STATUS=' + resp.status);
          console.log( resp.json());
            if(resp.status == 200 && resp.json()['result'] == 'ok'){
              // console.log("ENTER SAVE");
              this.modelEmail = new Email;
              this.loadingOTP = false;
              this.info = 'submit successfully';
              //this.setGridEvent = 'refresh';
              //document.getElementById("otp-popup").style.display = "block";
              document.getElementById("otp-popup").style.display = "none";
              this.router.navigateByUrl("/reset-password-page")
              this.message('information','Information','Submit successfully.', 'okonly', { ok: 'this.loadingOTP=false; this.closeOTPForm(); this.form=""'});
            }else{
              this.loadingOTP = false;
              this.closeOTPForm();
              this.message('warning','Warning','Wrong OTP.','okonly',{ok: 'this.loadingOTP=false; this.OTPForm(); this.form=""'});
            }
        },error => {
          this.loadingOTP = false;
          // console.log(error.message);
          this.info = error.message;
          this.loadingOTP = false;
        });
  }

  forgetPassword() {
    document.getElementById("forgetPassword").style.display = "block";
  }

  OTPForm(){
    document.getElementById("otp-popup").style.display = "block";
  }

  closeForm() {
    document.getElementById("forgetPassword").style.display = "none";
  }

  closeOTPForm(){
    document.getElementById("otp-popup").style.display = "none";
  }
}
