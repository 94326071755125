<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <!-- Begin of Grid -->
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        ><div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Sub-Terminal Closing Time (Vessel/Voyage)
        </h4>
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Sub-Terminal Closing Time (Vessel/Voyage)
            </h4>

            <div class="field">
              <div
                class="ui test toggle checkbox"
                [class.disabled]="lock == true"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="model['isValid']"
                  name="isValid"
                  tabindex="0"
                  class="hidden"
                />
                <label>Valid?</label>
              </div>
            </div>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="field">
                  <div class="fields">
                    <div class="four wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-vesselId'] != null &&
                          model['error-vesselId'] != ''
                        "
                      >
                        <label>Vessel</label>
                        <combo-plugin
                          #cbVessel
                          [settings]="settingVessel"
                          [disableCombo]="lockAfterEdit == true"
                          (change)="
                            changeEventVessel($event);
                            model['error-vesselId'] = ''
                          "
                          style="width: 100%"
                          (keypress)="
                            formValidation.ModifiedPattern($event, '-./ ')
                          "
                          (paste)="
                            onPasteValidation(
                              $event,
                              'vesselId',
                              formValidation.regVesVoy,
                              'and -./'
                            )
                          "
                        ></combo-plugin>
                        <div
                          class="
                            ui
                            basic
                            red
                            pointing
                            prompt
                            label
                            transition
                            error-message-hidden
                          "
                          [class.error-message-visible]="
                            model['error-vesselId'] != null &&
                            model['error-vesselId'] != ''
                          "
                        >
                          {{ model["error-vesselId"] }}
                        </div>
                      </div>
                    </div>
                    <div class="four wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-voyage'] != null &&
                          model['error-voyage'] != ''
                        "
                      >
                        <label>Voyage</label>
                        <combo-plugin
                          #cbVoyage
                          [settings]="settingVoyage"
                          [disableCombo]="lockAfterEdit == true"
                          (change)="
                            changeEventVoyage($event);
                            model['error-voyage'] = ''
                          "
                          style="width: 100%"
                          (keypress)="
                            formValidation.ModifiedPattern($event, '-./ ')
                          "
                          (paste)="
                            onPasteValidation(
                              $event,
                              'vesselId',
                              formValidation.regVesVoy,
                              'and -./'
                            )
                          "
                        ></combo-plugin>
                        <div
                          class="
                            ui
                            basic
                            red
                            pointing
                            prompt
                            label
                            transition
                            error-message-hidden
                          "
                          [class.error-message-visible]="
                            model['error-voyage'] != null &&
                            model['error-voyage'] != ''
                          "
                        >
                          {{ model["error-voyage"] }}
                        </div>
                      </div>
                    </div>

                    <div class="two wide field">
                      <div
                        class="required field"
                        [class.error]="
                          model['error-bound'] != null &&
                          model['error-bound'] != ''
                        "
                        [class.disabled]="lockAfterEdit == true"
                      >
                        <label>Bound</label>
                        <input
                          type="text"
                          name="bound"
                          placeholder="Bound"
                          maxlength="2"
                          [(ngModel)]="model['bound']"
                          (change)="changeEventBound($event)"
                          (keypress)="noneSpace($event)"
                        />
                        <div
                          class="
                            ui
                            basic
                            red
                            pointing
                            prompt
                            label
                            transition
                            error-message-hidden
                          "
                          [class.error-message-visible]="
                            model['error-bound'] != null &&
                            model['error-bound'] != ''
                          "
                        >
                          {{ model["error-bound"] }}
                        </div>
                      </div>
                    </div>

                    <div class="three wide field">
                      <div class="field">
                        <label>Receipt Type</label>
                        <combo-plugin
                          #cbReceiptType
                          [settings]="settingReceiptType"
                          [disableCombo]="lock == true"
                          (change)="changeEventReceiptType($event)"
                          style="width: 100%"
                        ></combo-plugin>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <fieldset
                    style="
                      border: 1px solid lightgray;
                      margin-bottom: 5px;
                      margin-top: 10px;
                    "
                  >
                    <legend style="font-weight: bold; color: dodgerblue">
                      POR
                    </legend>
                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div
                            class="required field"
                            [class.error]="
                              model['error-porCode'] != null &&
                              model['error-porCode'] != ''
                            "
                          >
                            <label>Place of Receipt</label>
                            <combo-plugin
                              #cbPOR
                              [settings]="settingPOR"
                              [disableCombo]="lock == true"
                              (change)="changeEventPOR($event)"
                              style="width: 100%"
                            ></combo-plugin>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              [class.error-message-visible]="
                                model['error-porCode'] != null &&
                                model['error-porCode'] != ''
                              "
                            >
                              {{ model["error-porCode"] }}
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field">
                            <label>Sub Terminal</label>
                            <!--input type="text" name="porTerminalCode"  placeholder="Terminal" [disabled]="terminalPORLock" [(ngModel)]="model['porTerminalCode']" -->
                            <combo-plugin
                              #cbPORTerminalCode
                              [settings]="settingPORTerminalCode"
                              [disableCombo]="lock == true"
                              (change)="changeEventPORTerminalCode($event)"
                              style="width: 100%"
                            ></combo-plugin>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Gate-in Closing Date</label>
                            <!-- calendar-plugin id="porTerminalClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="porTerminalClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="porTerminalClosingTime"
                              placeholder="--:--"
                              name="porTerminalClosingTime"
                              required
                              [(ngModel)]="model['porTerminalClosingTime']"
                            />
                          </div>
                        </div>

                        <div class="four wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>VGM Closing Date</label>
                            <!-- calendar-plugin id="porVgmClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="porVgmClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="porVgmClosingTime"
                              placeholder="--:--"
                              name="porVgmClosingTime"
                              required
                              [(ngModel)]="model['porVgmClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--
                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>VGM Closing Date</label>
                            <calendar-plugin id="porVgmClosingDate" [pluginType]="typevcdPOR" [settings]="settingvcdPOR"  ></calendar-plugin>
                           </div>
                          
                        </div>
  
                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input type="text"  id='porVgmClosingTime' name="porVgmClosingTime" required [(ngModel)]="model['porVgmClosingTime']" />
                            <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin>
                           </div> 
                          
                        </div>
                      </div>
                    </div>
                  -->
                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div class="field">
                            <label>Stuff At</label>
                            <combo-plugin
                              #cbPorStuffTerminalCode
                              [settings]="settingPORStuffTerminalCode"
                              [disableCombo]="lock == true"
                              (change)="changeEventPORStuff($event)"
                              style="width: 100%"
                            ></combo-plugin>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Stuffing Date</label>
                            <!-- calendar-plugin id="porStuffTerminalClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="porStuffTerminalClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="porStuffClosingTime"
                              placeholder="--:--"
                              name="porStuffTerminalClosingTime"
                              required
                              [(ngModel)]="model['porStuffTerminalClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>VGM Closing Date</label>
                            <!-- calendar-plugin id="porstuffVgmClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="porstuffVgmClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="porstuffVgmClosingTime"
                              placeholder="--:--"
                              name="porstuffVgmClosingTime"
                              required
                              [(ngModel)]="model['porstuffVgmClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="field">
                  <fieldset
                    style="
                      border: 1px solid lightgray;
                      margin-bottom: 5px;
                      margin-top: 10px;
                    "
                  >
                    <legend style="font-weight: bold; color: dodgerblue">
                      POL
                    </legend>
                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div
                            class="required field"
                            [class.error]="
                              model['error-polCode'] != null &&
                              model['error-polCode'] != ''
                            "
                          >
                            <label>Port of Load</label>
                            <combo-plugin
                              #cbPOL
                              [settings]="settingPOL"
                              [disableCombo]="lock == true"
                              (change)="changeEventPOL($event)"
                              style="width: 100%"
                            ></combo-plugin>
                            <div
                              class="
                                ui
                                basic
                                red
                                pointing
                                prompt
                                label
                                transition
                                error-message-hidden
                              "
                              [class.error-message-visible]="
                                model['error-polCode'] != null &&
                                model['error-polCode'] != ''
                              "
                            >
                              {{ model["error-polCode"] }}
                            </div>
                          </div>
                        </div>
                        <div class="three wide field">
                          <div class="field">
                            <label>Sub Terminal</label>
                            <!--input type="text" name="polTerminalCode"  placeholder="Terminal" [disabled]="terminalPOLLock" [(ngModel)]="model['polTerminalCode']" -->
                            <combo-plugin
                              #cbPOLTerminalCode
                              [settings]="settingPOLTerminalCode"
                              [disableCombo]="lock == true"
                              (change)="changeEventPOLTerminalCode($event)"
                              style="width: 100%"
                            ></combo-plugin>
                          </div>
                        </div>
                        <!-- <div class="three wide field">
                          <div
                            class="field"
                            [class.error]="
                              model['error-year'] != null &&
                              model['error-year'] != ''
                            "
                          >
                            <label>Year</label>
                            <input
                              type="text"
                              name="year"
                              style="text-transform: uppercase"
                              placeholder="Year"
                              (change)="changeYear($event)"
                            />
                          </div>
                        </div>
                        <div class="three wide field">
                          <div
                            class="field"
                            [class.error]="
                              model['error-weekName'] != null &&
                              model['error-weekName'] != ''
                            "
                          >
                            <label>Weeks</label>
                            <combo-plugin
                              #cbWeeks
                              [settings]="settingWeeks"
                              (change)="changeWeeks($event)"
                              style="width: 100%"
                            ></combo-plugin>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Gate-in Closing Date</label>
                            <!-- calendar-plugin id="polTerminalClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="polTerminalClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="polTerminalClosingTime"
                              placeholder="--:--"
                              name="polTerminalClosingTime"
                              required
                              [(ngModel)]="model['polTerminalClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>

                        <div class="four wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>VGM Closing Date</label>
                            <!-- calendar-plugin id="polVgmClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="polVgmClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              placeholder="--:--"
                              id="polVgmClosingTime"
                              name="polVgmClosingTime"
                              required
                              [(ngModel)]="model['polVgmClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--
                      <div class="field">
                        <div class="fields">
                          
                        </div>
                      </div>
                      -->

                    <div class="field">
                      <div class="fields">
                        <div class="four wide field">
                          <div class="field">
                            <label>Stuff At</label>
                            <combo-plugin
                              #cbPolStuffTerminalCode
                              [disableCombo]="lock == true"
                              [settings]="settingPOLStuffTerminalCode"
                              (change)="changeEventPOLStuff($event)"
                              style="width: 100%"
                            ></combo-plugin>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Stuffing Date</label>
                            <!-- calendar-plugin id="polStuffTerminalClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="polStuffTerminalClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              placeholder="--:--"
                              id="polStuffClosingTime"
                              name="polStuffClosingTime"
                              required
                              [(ngModel)]="model['polStuffTerminalClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>VGM Closing Date</label>
                            <!-- calendar-plugin id="polstuffVgmClosingDate" [pluginType]="typeGeneral" [settings]="settingGeneralCal"  ></calendar-plugin -->
                            <div class="ui left icon input">
                              <input
                                id="polstuffVgmClosingDate"
                                type="text"
                                class="form-control"
                                data-toggle="datepicker"
                                placeholder="date"
                              />
                              <i class="calendar icon"></i>
                            </div>
                          </div>
                        </div>

                        <div class="three wide field">
                          <div class="field" [class.disabled]="lock == true">
                            <label>Time</label>
                            <input
                              type="text"
                              id="polstuffVgmClosingTime"
                              placeholder="--:--"
                              name="polstuffVgmClosingTime"
                              required
                              [(ngModel)]="model['polstuffVgmClosingTime']"
                            />
                            <!--combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:100%;"></combo-plugin-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->

          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History Master Terminal Closing Time
            </h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Terminal Closing Time</a>

              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generateTerminalClosingTimeHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="three fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-vesselHistory'] != null &&
                      model['error-vesselHistory'] != ''
                    "
                  >
                    <label>Vessel</label>
                    <combo-plugin
                      #cbVesselNameHistory
                      [settings]="settingVesselHistory"
                      (change)="valueVesselHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-vesselHistory'] != null &&
                        model['error-vesselHistory'] != ''
                      "
                    >
                      {{ model["error-vesselHistory"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-voyageHistoryCode'] != null &&
                      model['error-voyageHistoryCode'] != ''
                    "
                  >
                    <label>Voyage</label>
                    <combo-plugin
                      #cbVoyageNameHistory
                      [settings]="settingVoyageHistory"
                      (change)="valueVoyageHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-voyageHistoryCode'] != null &&
                        model['error-voyageHistoryCode'] != ''
                      "
                    >
                      {{ model["error-voyageHistoryCode"] }}
                    </div>
                  </div>
                  <div class="two wide field">
                    <div
                      class="required field"
                      [class.error]="
                        model['error-boundHistory'] != null &&
                        model['error-boundHistory'] != ''
                      "
                      [class.disabled]="lock == true"
                    >
                      <label>Bound</label>
                      <input
                        type="text"
                        name="boundHistory"
                        placeholder="Bound"
                        [(ngModel)]="model['boundHistory']"
                        (change)="changeEventBoundHistory($event)"
                        (keypress)="noneSpace($event)"
                      />
                      <div
                        class="
                          ui
                          basic
                          red
                          pointing
                          prompt
                          label
                          transition
                          error-message-hidden
                        "
                        [class.error-message-visible]="
                          model['error-boundHistory'] != null &&
                          model['error-boundHistory'] != ''
                        "
                      >
                        {{ model["error-boundHistory"] }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <grid-plugin
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>
            </div>
          </form>

          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
