<div class="ui segment">
  <div class="ui divided items">

    <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

    <div [style.display]="modeForm?'none':'block'">
      <!-- Begin of Grid -->
      <div class="ui raised segment">
        <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Bank Details Master</h4>
        <label class="ui red ribbon mini label">Section A - Registered Bank Details</label>
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
      </div>
      <!-- End of Grid -->
      <div class="ui raised segment">
        <label class="ui red ribbon mini label">Section B - Bank Details to be displayed on Invoice</label>
          <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%"  id="myfieldset">
            <div class="two fields">
              <div class="field" style="margin-top:10px;width:50%;">
                <label>Bank to Display in Invoice : </label>
              </div>
              <div class="field" style="margin-top:10px;width:50%;">
                  <combo-plugin #cbBankDisplay [settings]="settingBankDisplay"  (change)="changeEventBankDisplay($event)"></combo-plugin>
              </div>
            </div>
          </fieldset>
      </div>
    </div>

    <div [style.display]="modeForm?'block':'none'">
      <div class="ui segment" style="padding-left:1%;padding-right:20%;">
        <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id='container'>
          <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

          <!-- Begin of Form -->
          <form class="ui form" [style.display]="form == ''?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local Bank Details Master</h4>

            <div class="field">
              <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                <label>Valid?</label>
              </div>
            </div>

            <div class="ui styled accordion field"  style="width:100%;height: 50%;">
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <!-- 1st row -->
                <div class="field">
                  <div class = "ui basic red pointing prompt label transition error-message-hidden"
                  [class.error-message-visible]="model['error-checkValidasi'] != null && model['error-checkValidasi'] != ''">
                      <p [innerHTML]="model['error-checkValidasi']"></p>
                  </div>
                </div>
                <div class="field">
                  <div class="fields">
                    <div class=" required eight wide field" [class.error]="model['error-bankStandardClause'] != null && model['error-bankStandardClause'] != ''">
                      <label>Standard Clause</label>
                      <input type="text" [(ngModel)]="model['bankStandardClause']" name="bankStandardClause"  placeholder="Standard Clause" [disabled]="lock == true" maxlength="70">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-bankStandardClause'] != null && model['error-bankStandardClause'] != ''">
                        {{model['error-bankStandardClause']}}
                      </div>
                    </div>
                    <div class=" required eight wide field" [class.error]="model['error-bankSwiftCode'] != null && model['error-bankSwiftCode'] != ''">
                      <label>SWIFT Code</label>
                      <input type="text" [(ngModel)]="model['bankSwiftCode']" name="bankSwiftCode"  placeholder="SWIFT Code" [disabled]="lock == true" maxlength="11">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-bankSwiftCode'] != null && model['error-bankSwiftCode'] != ''">
                        {{model['error-bankSwiftCode']}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 2nd row -->
                <div class="field">
                  <div class="fields">
                    <div class=" required eight wide field" [class.error]="model['error-bankName'] != null && model['error-bankName'] != ''">
                      <label>Bank Name</label>
                      <input type="text" [(ngModel)]="model['bankName']" name="bankName"  placeholder="Bank Name" [disabled]="lock == true" maxlength="70">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-bankName'] != null && model['error-bankName'] != ''">
                        {{model['error-bankName']}}
                      </div>
                    </div>
                    <div class=" required eight wide field" [class.error]="model['error-bankCode'] != null && model['error-bankCode'] != ''">
                      <label>Bank Code</label>
                      <input type="text" [(ngModel)]="model['bankCode']" name="bankCode"  placeholder="Bank Code" [disabled]="lock == true" maxlength="22">
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-bankCode'] != null && model['error-bankCode'] != ''">
                        {{model['error-bankCode']}}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 3rd row -->
                <div class="field">
                  <div class="fields">
                    <div class="required eight wide field" [class.error]="model['error-bankAddress'] != null && model['error-bankAddress'] != ''">
                      <label>Bank Address</label>
                      <textarea-plugin #txtBankAddress [settings]="settingBankAddress" maxlength="260"></textarea-plugin>
                      <div class = "ui basic red pointing prompt label transition error-message-hidden"
                           [class.error-message-visible]="model['error-bankAddress'] != null && model['error-bankAddress'] != ''">
                        {{model['error-bankAddress']}}
                      </div>
                    </div>
                    <div class="eight wide field">
                      <div class="required field" [class.error]="model['error-bankBranch'] != null && model['error-bankBranch'] != ''">
                        <label>Branch</label>
                        <input type="text" [(ngModel)]="model['bankBranch']" name="bankBranch"  placeholder="Branch" [disabled]="lock == true" maxlength="11">
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="model['error-bankBranch'] != null && model['error-bankBranch'] != ''">
                          {{model['error-bankBranch']}}
                        </div>
                      </div>
                      <div class="required field" [class.error]="model['error-bankUSDAccNo'] != null && model['error-bankUSDAccNo'] != ''">
                        <label>USD A/C No.</label>
                        <input type="text" [(ngModel)]="model['bankUSDAccNo']" name="bankUSDAccNo"  placeholder="USD Acc. No" [disabled]="lock == true" maxlength="15">
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="model['error-bankUSDAccNo'] != null && model['error-bankUSDAccNo'] != ''">
                          {{model['error-bankUSDAccNo']}}
                        </div>
                      </div>
                      <div class="required field" [class.error]="model['error-bankSGDAccNo'] != null && model['error-bankSGDAccNo'] != ''">
                        <label>SGD A/C No.</label>
                        <input type="text" [(ngModel)]="model['bankSGDAccNo']" name="bankSGDAccNo"  placeholder="SGD Acc. No" [disabled]="lock == true" maxlength="15">
                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                             [class.error-message-visible]="model['error-bankSGDAccNo'] != null && model['error-bankSGDAccNo'] != ''">
                          {{model['error-bankSGDAccNo']}}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- 4th row -->
                <div class="field">
                  <label>Remarks</label>
                  <textarea [(ngModel)]="model['bankRemarks']" name="bankRemarks" placeholder="Input your remarks..." [disabled]="lock == true" style="text-transform: uppercase" maxlength="260"></textarea>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" readonly style="background-color: lightgray">
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" readonly style="background-color: lightgray">
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" readonly style="background-color: lightgray">
                </div>
              </div>
            </div>

          </form>
          <!-- End of Form -->
          <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <button class="ui button" style="display:none;"></button>
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Local Bank Details Master</h4>

            <div class="ui raised segment">
              <a class="ui red ribbon label">Select Bank</a>

              <div class="active content">
                <div class="one field" style="margin-top:10px;">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                    <button class="ui button" (click)="generateBankHistoryReport($event)">Export</button>
                  </div>
                </div>

                <div class="two fields">
                  <div class="required field" [class.error]="model['error-bankName'] != null && model['error-bankName'] != ''">
                    <label>Bank Name</label>
                    <combo-plugin #cbBankNameHistory [settings]="settingBankHistory" (change)="valueBankHistory($event)" ></combo-plugin>
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-bankName'] != null && model['error-bankName'] != ''">
                      {{model['error-bankName']}}
                    </div>
                  </div>

                  <div class="required field" [class.error]="model['error-bankCode'] != null && model['error-bankCode'] != ''">
                    <label>Bank Code</label>
                    <input type="text" [value]="valBankCode" name="bankHistoryCode"  placeholder="Bank Code" [disabled]="lock == true" maxlength="15" readonly style="background-color: lightgray">
                    <div class = "ui basic red pointing prompt label transition error-message-hidden"
                         [class.error-message-visible]="model['error-bankCode'] != null && model['error-bankCode'] != ''">
                      {{model['error-bankCode']}}
                    </div>
                  </div>
                </div>

                <!--
                                            <div class="two fields">
                                              <div class="field">
                                                <label>Log Start Date</label>
                                                <calendar-plugin id="logHistoryClauseStartDate" [pluginType]="typeClauseLogStartDate" [settings]="settingClauseLogStartDate"></calendar-plugin>
                                              </div>
                                              <div class="field">
                                                <label>Log End Date</label>
                                                <calendar-plugin id="logHistoryClauseEndDate" [pluginType]="typeClauseLogEndDate" [settings]="settingClauseLogEndDate"></calendar-plugin>
                                              </div>
                                            </div>
                -->
                <div class="field">
                  <grid-plugin #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>

  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
