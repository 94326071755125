<div id="dialogHsform" class="ui small first coupled modal">
  <div class="header"><i class="warning sign big icon"></i> &nbsp; Warning</div>
  <div class="content">
    <p>Please select (1) historical data before exporting.</p>
  </div>
  <div class="actions">
    <div class="ui positive right labeled icon button" (click)="onOk()">
      OK
      <i class="checkmark icon"></i>
    </div>
  </div>
</div>

<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <div class="ui segment">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 99"
        >
          <div class="ui medium text loader">Loading</div>
        </div>
        <h4
          class="ui dividing header"
          style="text-align: center; margin-top: 10px"
        >
          Master Harmonized System (HS) Codes
        </h4>
        <!-- <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin> -->

        <!-- <ag-grid-angular
          style="width: 100%; height: 200px"
          #gridHSCode
          class="ag-theme-balham"
          [rowData]="rowDataHSCode"
          [columnDefs]="columnDefsHSCode"
          (rowDoubleClicked)="eventDoubleClickHSCode($event)"
          (gridReady)="onGridReady($event)"
          [enableSorting]="true"
          [enableFilter]="true"
          [enableColResize]="true"
        >
        </ag-grid-angular> -->
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
<!-- 
        <ag-grid-angular
          style="width: 100%; height: 200px"
          [gridOptions]="gridOptions" 
        ></ag-grid-angular> -->
      </div>
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 20%">
        <div
          class="ui inverted dimmer"
          [class.active]="loading == true"
          style="z-index: 1"
        >
          <div class="ui medium text loader">Loading</div>
        </div>

        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
            <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              Master Harmonized System (HS) Codes
              
            </h4>

            <div
              class="ui styled accordion field"
              style="width: 100%; height: 50%"
            >
              <div class="active title">
                <i class="icon dropdown"></i>
                General
              </div>
              <div class="active content">
                <div class="three fields">
                  <div class="field">
                    <label>HS Code</label>
                    <input
                      type="text"
                      [(ngModel)]="model['hsCode']"
                      name="hsCode"
                      placeholder="HS Code"
                      [disabled]="lock == true"
                      maxlength="35"
                    />
                  </div>

                  <div class="field">
                    <label>HS UOM</label>
                    <input
                      type="text"
                      [(ngModel)]="model['hsUom']"
                      name="hsUom"
                      placeholder="Uom"
                      [disabled]="lock == true"
                      maxlength="35"
                    />
                  </div>

                  <div class="field">
                    <label>ReferenceID</label>
                    <input
                      type="text"
                      [(ngModel)]="model['refrenceId']"
                      name="refrenceId"
                      placeholder="Reference ID"
                      [disabled]="lock == true"
                      maxlength="35"
                    />
                  </div>
                </div>

                <div class="field" >
                  <label>HS/Product Description</label>
                  <textarea
                    [(ngModel)]="model['hsDescription']"
                    name="hsDescription"
                    placeholder="Input your HS Description..."
                    [disabled]="lock == true"
                    style="text-transform: uppercase"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Uploaded By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['uploadBy']"
                    name="uploadBy"
                    placeholder="Upload By"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Date & Time</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateAndTime']"
                    name="dateAndTime"
                    placeholder="Upload Date"
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              
              
            </div>
          </form>
          
          

          <!-- Begin of History Form -->
          <form
            class="ui form"
            [style.display]="form == 'history' ? 'block' : 'none'"
          >
            <!-- <h4
              class="ui dividing header"
              style="text-align: center; margin-top: 10px"
            >
              History HS Code Master
            </h4> -->

            <div class="ui raised segment">
              <a class="ui red ribbon label">HS Code History</a>
              <div class="active content">
                <div class="one field" style="margin-top: 10px">
                  <div class="field">
                    <button class="ui button" (click)="retrieveHistory()">
                      Retrieve
                    </button>
                    <button
                      class="ui button"
                      (click)="generatePrincipalHistoryReport($event)"
                    >
                      Export
                    </button>
                  </div>
                </div>

                <div class="two fields">
                  <div
                    class="required field"
                    [class.error]="
                      model['error-hsDescription'] != null &&
                      model['error-hsDescription'] != ''
                    "
                  >
                    <label>HS Description</label>
                    <combo-plugin
                      #cbHsDescriptionHistory
                      [settings]="settingHsDescriptionHistory"
                      (change)="valueHsDescriptionHistory($event)"
                    ></combo-plugin>
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-hsDescription'] != null &&
                        model['error-hsDescription'] != ''
                      "
                    >
                      {{ model["error-hsDescription"] }}
                    </div>
                  </div>

                  <div
                    class="required field"
                    [class.error]="
                      model['error-hsCode'] != null &&
                      model['error-hsCode'] != ''
                    "
                  >
                    <label>HS Code</label>
                    <input
                      type="text"
                      [(ngModel)]="valHsCode"
                      [value]="valHsCode"
                      name="hsCodeHistory"
                      placeholder="HS Code"
                      maxlength="12"
                      readonly
                      style="background-color: lightgray"
                    />
                    <div
                      class="
                        ui
                        basic
                        red
                        pointing
                        prompt
                        label
                        transition
                        error-message-hidden
                      "
                      [class.error-message-visible]="
                        model['error-hsCode'] != null &&
                        model['error-hsCode'] != ''
                      "
                    >
                      {{ model["error-hsCode"] }}
                    </div>
                  </div>
                </div>
              
                <div class="field">
                  <grid-plugin
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>
            </div>
          </form>
          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dialog Receive CLL -->
<div id="uploadDialog" style="display: none">
  <div class="ui buttons">
    <!-- button class="ui tiny icon button" style="font-size:10px" (click)="uploadCLL();">SAVE</button -->
    <button
      class="ui tiny icon button"
      style="font-size: 10px; margin-right: 5px; margin-bottom: 5px"
      (click)="closeUploadHsCode()"
    >
      CLOSE
    </button>
    
  </div>
  <div
    class="ui raised segment"
    style="
      height: auto;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 0px;
      padding-right: 160px;
    "
  >
    <div
      class="ui equal width padded grid"
      style="margin-left: -14px !important; margin-bottom: 15px !important"
    >
      <div
        class="ui inverted dimmer"
        [class.active]="loadingUploadHsCode == true"
        style="z-index: 1"
      >
        <div class="ui medium text loader">Loading</div>
      </div>
      <div class="sixteen wide field" style="width: 100%">
        <label style="font-size: 12px">FILE HS CODE</label>
        <upload-plugin
          #uploadHsCode
          [settings]="settingUploadHsCode"
          (eventUpload)="uploadEventHsCode($event)"
        ></upload-plugin>
      </div>
    </div>
    <!-- grid-plugin id="cllGridUpload" #cllGridUpload [settings]="settingGridUpload" (gridEvent)="gridEventUpload($event)" (infoGrid)="infoGrid($event)"></grid-plugin -->
  </div>
</div>
<!-- End of Receive CLL dialog -->
<footer-plugin [info]="info"></footer-plugin>
