<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <h4
        class="ui dividing header"
        style="text-align: center; margin-top: 10px"
      >
        Master Vessel
      </h4>
      <!-- Begin of Grid -->
      <grid-plugin
        #grid
        [settings]="settingGrid"
        (gridEvent)="gridEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>
      <!-- End of Grid -->
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <dialog-plugin
        [settings]="dialog"
        (eventDialog)="eventMessage($event)"
      ></dialog-plugin>
      <app-master-vessel-form-page
        #masterVesselForm
      ></app-master-vessel-form-page>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
