<div id="dialogvesselform" class="ui small first coupled modal">
  <div class="header"><i class="warning sign big icon"></i> &nbsp; Warning</div>
  <div class="content">
    <p>Please select (1) historical data before exporting.</p>
  </div>
  <div class="actions">
    <div class="ui positive right labeled icon button" (click)="onOk()">
      OK
      <i class="checkmark icon"></i>
    </div>
  </div>
</div>

<div class="ui segment" style="padding-left: 1%; padding-right: 20%">
  <div
    class="ui inverted dimmer"
    [class.active]="loading == true"
    style="z-index: 1"
  >
    <div class="ui medium text loader">Loading</div>
  </div>

  <div id="container">
    <!-- Begin of Form -->
    <form class="ui form" [style.display]="form == '' ? 'block' : 'none'">
      <h4 class="ui dividing header" style="text-align: center">
        Master Vessel
      </h4>

      <div class="three fields">
        <div class="field">
          <label>Form Type</label>
          <combo-plugin
            #cbFormType
            [settings]="settingFormType"
            [disableCombo]="lock == true || isUpdate == true"
            (change)="changeEventFormType($event)"
            style="width: 100%"
          ></combo-plugin>
        </div>

        <div class="field">
          <label><br /></label>
          <div class="ui test toggle checkbox" [class.disabled]="lock == true">
            <input
              type="checkbox"
              [(ngModel)]="model['isValid']"
              name="isValid"
              tabindex="0"
              class="hidden"
            />
            <label>Valid</label>
          </div>
        </div>
      </div>
      <div class="ui styled accordion field" style="width: 100%">
        <div class="active title">
          <i class="icon dropdown"></i>
          Vessel
        </div>
        <div class="active content">
          <div class="four fields">
            <!--
                                    <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true || isDefault == false">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('ssl')" [checked]="isType == 'ssl'">
                                                <label>SSL</label>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true || isDefault == false">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('slot')" [checked]="isType == 'slot'">
                                                <label>Slot</label>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <div class="ui radio checkbox" [class.disabled]="lock == true || isDefault == false">
                                                <input type="radio" name="isType" tabindex="0" class="hidden" (change)="changeType('3rd')" [checked]="isType == '3rd'">
                                                <label>3rd Party</label>
                                        </div>
                                    </div>
                                    -->
            <div class="field">
              <!--div class="ui radio checkbox" [class.disabled]="lockRadioButton == true || isDefault == false"-->
              <div class="ui radio checkbox">
                <input
                  type="radio"
                  name="isType"
                  tabindex="0"
                  class="hidden"
                  (change)="changeType('ssl')"
                  [checked]="isType == 'ssl'"
                />
                <label>SSL</label>
              </div>
            </div>
            <div class="field">
              <!--div class="ui radio checkbox" [class.disabled]="lockRadioButton == true || isDefault == false"-->
              <div class="ui radio checkbox">
                <input
                  type="radio"
                  name="isType"
                  tabindex="0"
                  class="hidden"
                  (change)="changeType('slot')"
                  [checked]="isType == 'slot'"
                />
                <label>Slot</label>
              </div>
            </div>
            <div class="field">
              <!--div class="ui radio checkbox" [class.disabled]="lockRadioButton == true || isDefault == false"-->
              <div class="ui radio checkbox">
                <input
                  type="radio"
                  name="isType"
                  tabindex="0"
                  class="hidden"
                  (change)="changeType('3rd')"
                  [checked]="isType == '3rd'"
                />
                <label>3rd Party</label>
              </div>
            </div>
            <div class="field">
              <button
                style="margin-top: -10px"
                [disabled]="lock == true"
                class="ui button"
                (click)="onRetrieveButton(model['vesselId'])"
              >
                Retrieve
              </button>
            </div>
          </div>

          <div class="two fields">
            <div
              class="required field"
              [class.error]="
                model['error-vesselName'] != null &&
                model['error-vesselName'] != ''
              "
            >
              <label>Vessel Name</label>
              <combo-plugin
                #cbVessel
                [settings]="settingVessel"
                [required]="{
                  name: 'error-vesselName',
                  field: model['error-vesselName'],
                  model: model
                }"
                [disableCombo]="lock"
                (change)="changeEventVessel($event)"
                style="width: 100%"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="
                  onPasteValidation(
                    $event,
                    'vesselName',
                    formValidation.regVesVoy,
                    'and .-/'
                  )
                "
              ></combo-plugin>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselName'] != null &&
                  model['error-vesselName'] != ''
                "
              >
                {{ model["error-vesselName"] }}
              </div>
            </div>
            <!--
                                    <div class="required field" [class.error]="model['error-vesselCode'] != null && model['error-vesselCode'] != ''">
                                        <label>Vessel Abbr. Code</label>
                                        <input type="text" [(ngModel)]="model['vesselCode']" (ngModelChange)="model['error-vesselCode']=''" name="vesselCode"  placeholder="Vessel Abbr. Code" [disabled]="lock == true" maxlength="6">
                                        <div class = "ui basic red pointing prompt label transition error-message-hidden"
                                        [class.error-message-visible]="model['error-vesselCode'] != null && model['error-vesselCode'] != ''">
                                            {{model['error-vesselCode']}}
                                        </div>
                                    </div>
                                    -->
            <div
              [style.display]="modeType ? 'block' : 'none'"
              class="field"
              [class.error]="
                model['error-vesselCode'] != null &&
                model['error-vesselCode'] != ''
              "
              [class.tooltip]="
                model['vesselCode'] != '' && model['vesselCode'] != 'null'
              "
            >
              <label>Vessel Abbr. Code</label>
              <input
                type="text"
                [(ngModel)]="model['vesselCode']"
                (ngModelChange)="
                  checkIfError(
                    'vesselCode',
                    formValidation.regAlphaNumericWSpace
                  )
                "
                name="vesselCode"
                placeholder="Vessel Abbr. Code"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <span class="tooltiptext">{{ model["vesselCode"] }}</span>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselCode'] != null &&
                  model['error-vesselCode'] != ''
                "
              >
                {{ model["error-vesselCode"] }}
              </div>
            </div>
            <div
              [style.display]="modeType ? 'none' : 'block'"
              class="required field"
              [class.error]="
                model['error-vesselCode'] != null &&
                model['error-vesselCode'] != ''
              "
              [class.tooltip]="
                model['vesselCode'] != 'null' && model['vesselCode'] != ''
              "
            >
              <label>Vessel Abbr. Code</label>
              <input
                type="text"
                [(ngModel)]="model['vesselCode']"
                name="vesselCode"
                placeholder="Vessel Abbr. Code"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <span class="tooltiptext">{{ model["vesselCode"] }}</span>
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-vesselCode'] != null &&
                  model['error-vesselCode'] != ''
                "
              >
                {{ model["error-vesselCode"] }}
              </div>
            </div>

            <div
              [style.display]="modeType ? 'block' : 'none'"
              class="field"
              [class.error]="
                model['error-callSign'] != null && model['error-callSign'] != ''
              "
            >
              <label>Call Sign</label>
              <input
                type="text"
                [(ngModel)]="model['callSign']"
                (ngModelChange)="model['error-callSign'] = ''"
                name="callSign"
                placeholder="Call Sign"
                [disabled]="lock == true"
                maxlength="10"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-callSign'] != null &&
                  model['error-callSign'] != ''
                "
              >
                {{ model["error-callSign"] }}
              </div>
            </div>
            <div
              [style.display]="modeType ? 'none' : 'block'"
              class="required field"
              [class.error]="
                model['error-callSign'] != null && model['error-callSign'] != ''
              "
            >
              <label>Call Sign</label>
              <input
                type="text"
                [(ngModel)]="model['callSign']"
                (ngModelChange)="model['error-callSign'] = ''"
                name="callSign"
                placeholder="Call Sign"
                [disabled]="lock == true"
                maxlength="10"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-callSign'] != null &&
                  model['error-callSign'] != ''
                "
              >
                {{ model["error-callSign"] }}
              </div>
            </div>
          </div>

          <div class="two fields">
            <div
              class="required field"
              [class.error]="
                model['error-psaVesselCode'] != null &&
                model['error-psaVesselCode'] != ''
              "
            >
              <label>Vessel Abbr. Name</label>
              <input
                type="text"
                [(ngModel)]="model['psaVesselCode']"
                (ngModelChange)="
                  checkIfError('psaVesselCode', formValidation.regVesVoy)
                "
                name="psaVesselCode"
                placeholder="Vessel Abbr. Name"
                [disabled]="lock == true"
                maxlength="50"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-psaVesselCode'] != null &&
                  model['error-psaVesselCode'] != ''
                "
              >
                {{ model["error-psaVesselCode"] }}
              </div>
            </div>

            <div
              [style.display]="modeType ? 'block' : 'none'"
              class="field"
              [class.error]="
                model['error-docsysCode'] != null &&
                model['error-docsysCode'] != ''
              "
            >
              <label>Vessel Code (A/C)</label>
              <input
                type="text"
                [(ngModel)]="model['docsysCode']"
                (ngModelChange)="model['error-docsysCode'] = ''"
                name="docsysCode"
                placeholder="Vessel Code (A/C)"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-docsysCode'] != null &&
                  model['error-docsysCode'] != ''
                "
              >
                {{ model["error-docsysCode"] }}
              </div>
            </div>
            <div
              [style.display]="modeType ? 'none' : 'block'"
              class="required field"
              [class.error]="
                model['error-docsysCode'] != null &&
                model['error-docsysCode'] != ''
              "
            >
              <label>Vessel Code (A/C)</label>
              <input
                type="text"
                [(ngModel)]="model['docsysCode']"
                (ngModelChange)="model['error-docsysCode'] = ''"
                name="docsysCode"
                placeholder="Vessel Code (A/C)"
                [disabled]="lock == true"
                maxlength="6"
                (keypress)="formValidation.ModifiedPattern($event, ' ')"
              />
              <div
                class="
                  ui
                  basic
                  red
                  pointing
                  prompt
                  label
                  transition
                  error-message-hidden
                "
                [class.error-message-visible]="
                  model['error-docsysCode'] != null &&
                  model['error-docsysCode'] != ''
                "
              >
                {{ model["error-docsysCode"] }}
              </div>
            </div>
          </div>

          <div class="fields">
            <div class="field" style="width: 100%">
              <label>Remarks</label>
              <textarea
                style="margin-top: 5px"
                [(ngModel)]="model['remarks']"
                name="remarks"
                placeholder="Input your remarks..."
                [disabled]="lock == true"
                (keypress)="formValidation.AllChar($event)"
                maxlength="200"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="ui styled accordion field"
        [class.disabled]="isDefault == false"
        style="width: 100%"
      >
        <div class="title">
          <i class="icon dropdown"></i>
          Vessel Information
        </div>
        <div class="content">
          <div class="three fields">
            <div class="field">
              <label>Vessel Name</label>
              <input
                type="text"
                [(ngModel)]="model['vesselName']"
                name="vesselName2"
                placeholder="Vessel Name"
                disabled
                readonly
                style="background-color: lightgray"
              />
            </div>
            <div class="field">
              <label>Vessel Abbr. Code</label>
              <input
                type="text"
                [(ngModel)]="model['vesselCode']"
                name="vesselCode2"
                placeholder="Vessel Abbr. Code"
                disabled
                readonly
                style="background-color: lightgray"
              />
            </div>
            <div class="field">
              <label>Capacity</label>
              <div class="ui right labeled input">
                <input
                  type="number"
                  [(ngModel)]="model['capacity']"
                  name="capacity"
                  placeholder="Capacity"
                  [disabled]="lock == true"
                />
                <div class="ui basic label" style="font-size: 11px">teus</div>
              </div>
            </div>
          </div>

          <div class="four fields" style="padding-left: 10px">
            <div class="field">
              <div class="ui raised segment">
                <a class="ui red ribbon label">Tonnage (T)</a>
                <div class="one fields">
                  <div class="field">
                    <label>G/T (International)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['gtiInternational']"
                      name="gtiInternational"
                      placeholder="G/T (International)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>N/T (International)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['ntiInternational']"
                      name="ntiInternational"
                      placeholder="N/T (International)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>G/T (Suez Canal)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['gtsuezCanal']"
                      name="gtsuezCanal"
                      placeholder="G/T (Suez Canal)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>N/T (Suez Canal)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['ntsuezCanal']"
                      name="ntsuezCanal"
                      placeholder="N/T (Suez Canal)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>N/T (Panama Canal)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['ntpanamaCanal']"
                      name="ntpanamaCanal"
                      placeholder="G/T (Panama Canal)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
              </div>

              <div class="ui raised segment">
                <a class="ui red ribbon label">Weight (MT)</a>
                <div class="one fields">
                  <div class="field">
                    <label>Dead Weight</label>
                    <input
                      type="number"
                      [(ngModel)]="model['deadWeight']"
                      name="deadWeight"
                      placeholder="Dead Weight"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>Light Weight</label>
                    <input
                      type="number"
                      [(ngModel)]="model['lightWeight']"
                      name="lightWeight"
                      placeholder="Light Weight"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
              </div>

              <div class="ui raised segment">
                <a class="ui red ribbon label">Dimension (M)</a>
                <div class="one fields">
                  <div class="field">
                    <label>L.O.A.</label>
                    <input
                      type="number"
                      [(ngModel)]="model['loa']"
                      name="loa"
                      placeholder="L.O.A."
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>L.P.P.</label>
                    <input
                      type="number"
                      [(ngModel)]="model['lpp']"
                      name="lpp"
                      placeholder="L.P.P."
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>Breadth (Mould)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['breadthMould']"
                      name="breadthMould"
                      placeholder="Breadth (Mould)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>Depth (Mould)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['depthMould']"
                      name="depthMould"
                      placeholder="Depth (Mould)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>Draft (Design)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['draftDesign']"
                      name="draftDesign"
                      placeholder="Draft (Design)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field">
                    <label>Draft (Extreme)</label>
                    <input
                      type="number"
                      [(ngModel)]="model['draftExtreme']"
                      name="draftExtreme"
                      placeholder="Draft (Extreme)"
                      maxlength="18"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="ten wide field" style="padding-left: 10px">
              <div class="ui raised segment">
                <a class="ui red ribbon label">Type, Class, Port of Registry</a>

                <div class="four fields">
                  <div class="field">
                    <label>Type</label>
                    <input
                      type="text"
                      [(ngModel)]="model['type']"
                      name="type"
                      placeholder="Type"
                      [disabled]="lock == true"
                      maxlength="2"
                    />
                  </div>
                  <div class="field">
                    <label>Class</label>
                    <input
                      type="text"
                      [(ngModel)]="model['clazz']"
                      name="clazz"
                      placeholder="Class"
                      [disabled]="lock == true"
                      maxlength="2"
                    />
                  </div>
                  <div class="field">
                    <label>Flag</label>
                    <input
                      type="text"
                      [(ngModel)]="model['flag']"
                      name="flag"
                      placeholder="Flag"
                      [disabled]="lock == true"
                      maxlength="2"
                    />
                  </div>
                  <div class="field">
                    <label>IMO</label>
                    <div class="imo-flex">
                      <combo-plugin
                        #cbIMO
                        [settings]="settingIMO"
                        [disableCombo]="lock == true || isLockIMO"
                        (change)="changeEventIMO($event)"
                        style="width: 100%"
                        (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                      ></combo-plugin>
                      <button class="ml-3" title="Change IMO" [disabled]="lock == true || !isLockIMO" (click)="resetIMO()">
                        <i class="refresh icon"></i>
                      </button>
                    </div>
                    <!-- <input
                      type="text"
                      [(ngModel)]="model['imoNo']"
                      name="imoNo"
                      placeholder="Imo"
                      [disabled]="lock == true"
                      maxlength="20"
                      (keypress)="keypressEventNumber($event)"
                      (paste)="pasteEventNumber($event)"
                    /> -->
                  </div>
                </div>
                <div class="five fields">
                  <div class="twelve wide field">
                    <label>Owner</label>
                    <input
                      type="text"
                      [(ngModel)]="model['owner']"
                      name="owner"
                      placeholder="Owner"
                      [disabled]="lock == true"
                      maxlength="50"
                    />
                  </div>
                  <div class="four wide field" style="padding-right: 5px">
                    <label>Official Number</label>
                    <input
                      type="text"
                      [(ngModel)]="model['officialNumber']"
                      name="officialNumber"
                      placeholder="Official Number"
                      [disabled]="lock == true"
                      maxlength="50"
                    />
                  </div>
                </div>
                <div class="five fields">
                  <div class="twelve wide field">
                    <label>Ship Managing Company</label>
                    <input
                      type="text"
                      [(ngModel)]="model['shipManagement']"
                      name="shipManagement"
                      placeholder="Ship Managing Company"
                      [disabled]="lock == true"
                      maxlength="50"
                    />
                  </div>
                  <div class="four wide field" style="padding-right: 5px">
                    <label>Date of Launching</label>
                    <calendar-plugin
                      id="dateLaunch"
                      [pluginType]="typeCalendarDateLaunch"
                      [settings]="settingCalendarDateLaunch"
                    ></calendar-plugin>
                  </div>
                </div>
                <div class="five fields">
                  <div class="twelve wide field">
                    <label>Port of Registry</label>
                    <input
                      type="text"
                      [(ngModel)]="model['portRegistry']"
                      name="portRegistry"
                      placeholder="Port of Registry"
                      [disabled]="lock == true"
                      maxlength="5"
                    />
                  </div>
                  <div class="four wide field" style="padding-right: 5px">
                    <label>Date of Delivery</label>
                    <calendar-plugin
                      id="dateDelivery"
                      [pluginType]="typeCalendarDateDelivery"
                      [settings]="settingCalendarDateDelivery"
                    ></calendar-plugin>
                  </div>
                </div>
                <div class="five fields">
                  <div class="twelve wide field">
                    <label>Navigation Area</label>
                    <input
                      type="text"
                      [(ngModel)]="model['navigationArea']"
                      name="navigationArea"
                      placeholder="Navigation Area"
                      [disabled]="lock == true"
                      maxlength="50"
                    />
                  </div>
                  <div class="four wide field" style="padding-right: 5px">
                    <label>Date of KEEL</label>
                    <calendar-plugin
                      id="dateKeel"
                      [pluginType]="typeCalendarDateKeel"
                      [settings]="settingCalendarDateKeel"
                    ></calendar-plugin>
                  </div>
                </div>
                <div class="four fields">
                  <div class="twelve wide field">
                    <label>Builder</label>
                    <input
                      type="text"
                      [(ngModel)]="model['builder']"
                      name="builder"
                      placeholder="Builder"
                      [disabled]="lock == true"
                      maxlength="50"
                    />
                  </div>
                  <div class="field">
                    <label>Year Built</label>
                    <input
                      type="number"
                      [(ngModel)]="model['yearBuild']"
                      name="yearBuild"
                      placeholder="Year Built"
                      [disabled]="lock == true"
                      maxlength="4"
                      oninput="javascript: if (this.value.length > this.maxlength) this.value = this.value.slice(0, this.maxLength) ;"
                    />
                  </div>
                </div>
              </div>
              <div class="ui raised segment">
                <a class="ui red ribbon label">Number of Container (Units)</a>

                <div class="one fields" style="height: 7px">
                  <div class="field">
                    <label>(1) 20 feet max (On Hatch / In Hold)</label>
                  </div>
                </div>
                <div class="three fields" style="margin-left: 15px">
                  <div class="field">
                    <label>20 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20OnHatch20']"
                        name="max20OnHatch20"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20InHold20']"
                        name="max20InHold20"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label>40 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20OnHatch40']"
                        name="max20OnHatch40"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20InHold40']"
                        name="max20InHold40"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label>45 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20OnHatch45']"
                        name="max20OnHatch45"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max20InHold45']"
                        name="max20InHold45"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                </div>

                <div class="one fields" style="height: 7px">
                  <div class="field">
                    <label>(2) 40 feet max (On Hatch / In Hold)</label>
                  </div>
                </div>
                <div class="three fields" style="margin-left: 15px">
                  <div class="field">
                    <label>20 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40OnHatch20']"
                        name="max40OnHatch20"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40InHold20']"
                        name="max40InHold20"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label>40 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40OnHatch40']"
                        name="max40OnHatch40"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40InHold40']"
                        name="max40InHold40"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label>45 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40OnHatch45']"
                        name="max40OnHatch45"
                        placeholder=""
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['max40InHold45']"
                        name="max40InHold45"
                        placeholder=""
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                </div>

                <div class="one fields" style="height: 7px">
                  <div class="field">
                    <label>(3) Reefer Container (On Hatch / In Hold)</label>
                  </div>
                </div>
                <div class="three fields" style="margin-left: 15px">
                  <div class="field">
                    <label>20 Feet</label>
                    <div class="two fields">
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['reeferOnHatch']"
                        name="reeferOnHatch"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <label style="margin: 7px">/</label>
                      <input
                        type="number"
                        style="width: 70px"
                        [(ngModel)]="model['reeferInHatch']"
                        name="reeferInHatch"
                        placeholder=""
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                    </div>
                  </div>
                </div>

                <div class="one fields" style="height: 7px">
                  <div class="field">
                    <label>Strength Base of Container</label>
                  </div>
                </div>
                <div class="two fields" style="margin-left: 15px">
                  <div class="field">
                    <label>20 Feet on Hatch</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseOnHatch20']"
                        name="sbaseOnHatch20"
                        placeholder="20 Feet on Hatch"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>20 Feet in Hold</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseInHold20']"
                        name="sbaseInHold20"
                        placeholder="20 Feet in Hold"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two fields" style="margin-left: 15px">
                  <div class="field">
                    <label>40 Feet on Hatch</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseOnHatch40']"
                        name="sbaseOnHatch40"
                        placeholder="40 Feet on Hatch"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>40 Feet in Hold</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseInHold40']"
                        name="sbaseInHold40"
                        placeholder="40 Feet in Hold"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                </div>
                <div class="two fields" style="margin-left: 15px">
                  <div class="field">
                    <label>45 Feet on Hatch</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseOnHatch45']"
                        name="sbaseOnHatch45"
                        placeholder="45 Feet on Hatch"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>45 Feet in Hold</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['sbaseInHold45']"
                        name="sbaseInHold45"
                        placeholder="45 Feet in Hold"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        t/stack
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui raised segment">
                <a class="ui red ribbon label">Speed, Endurance</a>
                <div class="three fields">
                  <div class="field">
                    <label>Service Speed (35%)</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['serviceSpeed']"
                        name="serviceSpeed"
                        maxlength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        placeholder="Service Speed.."
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        knots
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>Trial Max. Speed</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['trialMaxSpeed']"
                        name="trialMaxSpeed"
                        placeholder="Trial Max Speed.."
                        maxlength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        knots
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>F.O. Consumption For M.E.</label>
                    <div class="ui right labeled input">
                      <input
                        type="number"
                        [(ngModel)]="model['foConsumptionRate']"
                        name="foConsumptionRate"
                        placeholder="F.O Consumption"
                        maxlength="6"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        [disabled]="lock == true"
                      />
                      <div class="ui basic label" style="font-size: 11px">
                        g/ps/hr
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field" style="padding-left: 10px">
              <div class="ui raised segment">
                <a class="ui red ribbon label">Tank Capacity (MT)</a>
                <div class="fields">
                  <div class="field" style="width: 100%">
                    <label>Water Ballast</label>
                    <input
                      type="number"
                      [(ngModel)]="model['waterBallastTank']"
                      name="waterBallastTank"
                      placeholder="Water Ballast"
                      maxlength="6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field" style="width: 100%">
                    <label>Fresh Water</label>
                    <input
                      type="number"
                      [(ngModel)]="model['freshWaterTank']"
                      name="freshWaterTank"
                      placeholder="Fresh Water"
                      maxlength="6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field" style="width: 100%">
                    <label>Fuel Oil</label>
                    <input
                      type="number"
                      [(ngModel)]="model['fuelOilTank']"
                      name="fuelOilTank"
                      placeholder="Fuel Oil"
                      maxlength="6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
                <div class="one fields">
                  <div class="field" style="width: 100%">
                    <label>Diesel Oil</label>
                    <input
                      type="number"
                      [(ngModel)]="model['dieselOilTank']"
                      name="dieselOilTank"
                      placeholder="Diesel Oil"
                      maxlength="6"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      [disabled]="lock == true"
                    />
                  </div>
                </div>
              </div>

              <div class="ui raised segment">
                <a class="ui red ribbon label">Main Engine</a>
                <textarea
                  style="margin-top: 5px"
                  [(ngModel)]="model['mainEngine']"
                  name="mainEngine"
                  [disabled]="lock == true"
                ></textarea>
              </div>
              <div class="ui raised segment">
                <a class="ui red ribbon label">Bow Thruster</a>
                <textarea
                  style="margin-top: 5px"
                  [(ngModel)]="model['bowThruster']"
                  name="bowThruster"
                  [disabled]="lock == true"
                ></textarea>
              </div>

              <div class="ui raised segment">
                <a class="ui red ribbon label">Ship Image</a>

                <div class="ui special cards">
                  <div class="card" style="margin-top: 30px; z-index: 1">
                    <div
                      class="blurring dimmable image"
                      style="margin-top: 0px"
                      [class.disabled]="lock == true"
                    >
                      <div class="ui dimmer">
                        <div class="content">
                          <div class="center">
                            <div
                              class="ui inverted button"
                              (click)="onUpload()"
                              style="font-size: 11px"
                            >
                              Change Image
                            </div>
                            <div
                              class="ui horizontal divider"
                              style="color: white"
                            >
                              Or
                            </div>
                            <div
                              class="ui inverted button"
                              (click)="onRemove()"
                              style="font-size: 11px"
                            >
                              Remove Image
                            </div>
                            <input
                              type="file"
                              id="upload"
                              style="display: none"
                            />
                          </div>
                        </div>
                      </div>
                      <img id="img" [attr.src]="model['vesselImagePath']" />
                    </div>
                  </div>
                </div>

                <!--
                                            <img class="ui small image" style="margin-top:5px;margin-left:4px;" [attr.src]="model['vesselImagePath']">
                                            -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ui raised segment">
        <div class="two fields">
          <div class="field">
            <label>Created By</label>
            <input
              type="text"
              [(ngModel)]="model['userCreated']"
              name="userCreated"
              placeholder="Created By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Created Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateCreated']"
              name="dateCreated"
              placeholder="Created Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Last Modified By</label>
            <input
              type="text"
              [(ngModel)]="model['userModified']"
              name="userModified"
              placeholder="Last Modified By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Last Modified Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateModified']"
              name="dateModified"
              placeholder="Last Modified Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
        <div class="two fields">
          <div class="field">
            <label>Invalid By</label>
            <input
              type="text"
              [(ngModel)]="model['userInvalid']"
              name="userInvalid"
              placeholder="Invalid By"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
          <div class="field">
            <label>Invalid Date</label>
            <input
              type="text"
              [(ngModel)]="model['dateInvalid']"
              name="dateInvalid"
              placeholder="Invalid Date"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>
      </div>
    </form>
    <!-- End of Form -->
    <!-- Begin of History Form -->
    <form
      class="ui form"
      [style.display]="form == 'history' ? 'block' : 'none'"
    >
      <h4 class="ui dividing header" style="text-align: center">
        History Master Vessel
      </h4>

      <div class="ui raised segment">
        <a class="ui red ribbon label">Select Vessel</a>

        <div class="one fields" style="margin-top: 10px">
          <div class="field">
            <button class="ui button" (click)="retrieveHistory($event)">
              Retrieve
            </button>
            <button
              class="ui button"
              (click)="generateVesselHistoryReport($event)"
            >
              Export
            </button>
          </div>
        </div>

        <div class="two fields">
          <div class="field">
            <label>Vessel Name</label>
            <combo-plugin
              #cbVesselHistory
              [settings]="settingVesselHistory"
              (change)="changeEventVesselHistory($event)"
            ></combo-plugin>
          </div>
          <div class="field">
            <label>Vessel Abbr. Code</label>
            <input
              type="text"
              [(ngModel)]="vesselCodeHistory"
              name="vesselCodeHistory"
              placeholder="Vessel History Code"
              disabled
              readonly
              style="background-color: lightgray"
            />
          </div>
        </div>

        <div class="field">
          <grid-plugin
            #gridHistory
            [settings]="settingGridHistory"
            (gridEvent)="gridEventHistory($event)"
            (infoGrid)="infoGridHistory($event)"
          ></grid-plugin>
        </div>
      </div>
    </form>
    <!-- End of History Form -->
  </div>
</div>
