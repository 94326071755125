import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild }
from '@angular/core';
import {officeModel,
  sha_booking_edi,
  } from "../shared/index";
import { specialCharUtil } from '../shared';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil,ConfigService,CookieService
  ,TextareaPluginComponent,DialogPluginComponent
}
from 'sibego-ui-library';
import { Router } from '@angular/router';

import {ShaBookingEDIInfo,} from "../shared/models/booking";
import { DISABLED } from '@angular/forms/src/model';
import FormValidation from '../../utils/formValidation';

import * as moment from 'moment';
declare  var $:any;

//pass parameter continue to Booking maintenance
export class Param {
  bOfficeCode: string = "";
  bOceanVesselId: string = "";
  bOceanVesselCode: string = "";
  bOceanVesselVoyage: string = "";
  bOceanVesselBound: string = "";
  bPot1Code: string = "";
  //bPot1TerminalCode : string='';
  bPolCode: string = "";
  bPodCode: string = "";
  bFDestCode: string = "";
  bContainerOwnership: string = "";
  bEDIid: string = "";
  bContainerStatus: string = "";
  direction: string = "";
  selected: string = "";
  ETA: string = "";
  ETD: string = "";
  ATA: string = "";
  ATD: string = "";


  allocationValidator: Boolean = false;
  lock7daysValidator: Boolean = false;
  constructor() {}
}

export class Param2 {
  bOfficeCode: string = "";
  bEDIid: string = "";
  bEdiFileName: string = "";
  bNoList: string = "";
  bNoListOriginal: string = "";

  constructor() {}
}

@Component({
  selector: 'app-edi-process-edi-booking-page',
  templateUrl: './edi-process-edi-booking-page.component.html',
  styleUrls: ['./edi-process-edi-booking-page.component.css']
})
export class EDIProcessEDIBookingPageComponent extends Validator
implements OnInit, AfterViewInit
{

  dialog:any;

  /* Parameter for information into Footer */
  info = '';
  space = "&nbsp;&nbsp;";
  @ViewChild("gridShaBookingEDIInfo") gridShaBookingEDIInfo: GridPluginComponent;
  @ViewChild('cbEDIFileName') cbEDIFileName:ComboPluginComponent;
  @ViewChild('gridShaBookingAccRejInfo') gridShaBookingAccRejInfo:GridPluginComponent;
  @ViewChild('cbBookingRejectionReason') cbBookingRejectionReason: ComboPluginComponent;
  @ViewChild("txtOtherRejectionReason") txtOtherRejectionReason: TextareaPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;
  /* Parameter for dialog */
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";
  appStatusLabel: string ="";
  /* Parameter modeForm (Default : false) */
  modeForm = false;
 // modeForm = "ShaBookingEDIProcess"; //false;//false;
  modeToolbar =false;
  modeToolbar_AccpRejBkg  = false;
  shaBookingEDIInfo = new ShaBookingEDIInfo();

   /*modeForm = 'sailingScheduleFlagStatus';*/

  loading = false;

    /* Parameter for enable/disable Toolbar buttons */
   invisibleToolbarButtons  = "";
   invisibleToolbarButtons_AccpRejBkg = "";
   disableToolbarButtons =  "";
   disableToolbarButtons_AccpRejBkg = "";


   /* Parameter listStore for Grid */
   listStore = new ListStore();

   //parameter continueToMaintenance
   param = new Param();
   param2 = new Param2();
   isError: Boolean = false;
   isCOC: Boolean = true;
   isSOC: Boolean = true;
   isSLOT: Boolean = true;
   isNVOCC: Boolean = false;

  /* Parameter settings */
  settingToolbar;
  settingGridShaBookingEDIProcessInfo;

  settingGridShaBookingAccRejInfo;
  settingToolbar_AccpRejBkg;
  settingBookingRejectionReason;

  settingEDIFileName;
  settingtxtOtherRejectionReason;

  // * HTML Encode
  sCharUtil = new specialCharUtil();

    /* Parameter model */
  model = new sha_booking_edi();
  //seletedBookingEdi_Model : sha_seleted_booking_edi[]=[];

  //Default parameter
  rejectionReason='';

  otherRejectionReasonFormat: any;

  strParams: string = "";
  dataGrid: any;
  rowData = [];

  txtreprocess: string = "";
  //Flag active tab
  isCopy: boolean = false;
  isModify: Boolean = false;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';
  //this.model["error-BookingRejectionReason"] = "";

  // lock
  cButtonLock = true;
  dButtonLock = true;
  lock = false;
  updateMode = false;

  lockBtnShaBookingEDIDiscard: Boolean = false;
  lockBtnShaBookingReject: Boolean = false;



  //model ='';

  validatorRules = {
    ediFileName : {
      rules:[
        {type:'empty', prompt:'Please select a file.'}
      ]
    }
  };


 // Form Validation
  formValidation = new FormValidation();

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil,
    private configService: ConfigService, private router: Router, private cookieService:CookieService) {
    super();

    /** Start constructor - Shanghai Booking EDI process **/
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'checkmark'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Process Booking EDI'
    };

    this.settingGridShaBookingEDIProcessInfo = {
     id: "gridShaBookingEDIInfo",
      url: this.configService.config.BASE_API.toString() +
        "/sha/getShaBookingEDI",
      //http://localhost:14000/sha/getShaBookingEDI/*/*/*/1/10/*/*
      // url: "http://localhost:14000/sha/getShaBookingEDI",
      // url: this.configService.config.standalone[0].target +
      //      this.configService.config.standalone[0].endpoint +
      //      "/getShaBookingEDI",
      page: 10,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "EDI ID", field: "ediId", width: 80 },
        { header: "Processed", field: "ediIsProcessed", width: 50 },
        { header: "File Name", field: "ediFileName", width: 200 },
        { header: "Vessel Name", field: "ediVesselName", width: 200 },
        { header: "Voyage No.", field: "ediVesselVoyage", width: 100 },
        { header: "Total No. of BKG", field: "ediTotalBkgCounts", width: 100 },
        { header: "No.of BKG pending actions", field: "ediTotalBkgPendingActions", width: 100 },
        { header: "Date Received", field: "ediDateUpload", width: 100 },
        { header: "Time Received", field: "ediTimeUpload", width: 100 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "ediDateUpload",
      sortingDirection: "DESC",
    };

    /** End constructor - Shanghai Booking EDI process **/

    /** Start constructor - AccpRej Booking **/

    this.settingToolbar_AccpRejBkg = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'View Detail', event: 'detail', icon: 'calculator'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Acceptance / Rejection of Booking(s)'
    };

    this.settingGridShaBookingAccRejInfo = {
      id: "gridShaBookingAccRejInfo",
      url: "",
      page: 20,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "Status", field: "status", width: 50 },
        { header: "Accepted", field: "accept", width: 100 },
        { header: "Rejected", field: "reject", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 150 },
        { header: "Voyage No.", field: "voyageNo", width: 100 },
        { header: "BKG No.", field: "bNo", width: 150 },
        { header: "Shipper Ref.", field: "agentCustomerCode", width: 150 },
        { header: "Shipper", field: "shipperName", width: 450 },
        { header: "Consignee", field: "cneeName", width: 450 },
        { header: "Container Size", field: "containerSize", width: 100 },
        { header: "Container Type", field: "containerType", width: 100 },
        { header: "Qty", field: "containerQty", width: 40 },
        { header: "EDI BKG Commodity", field: "description", width: 150 },
        { header: "POR", field: "porName", width: 100 },
        { header: "POL", field: "polName", width: 100 },
        { header: "POD", field: "podName", width: 100 },
        { header: "PODL", field: "placeOfDeliveryName", width: 100 },
        { header: "FDEST", field: "finalDestinationName", width: 100 },
        { header: "Ownership", field: "containerOwnership", width: 100 },
        { header: "Rejection Reasons", field: "rejectionReasons", width: 150 },
      ],
      buttons: [],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "bNo",
      sortingDirection: "ASC",
    };

    this.settingEDIFileName = {
      id          : 'cbEDIFileName',
      type        : 'search enter', // search | select | select input

      //http://localhost:14000/sha/findByComboBoxControl/ediFileName=ONE
      url         : this.configService.config.BASE_API.toString() + '/sha/findByComboBoxControl/ediFileName={query}',
      //url         :'http://localhost:14000/sha/findByComboBoxControl/ediFileName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search EDI File Name',
      title       : 'ediFileName',
      description  : '',
      columns     : [
        {header: 'EDI ID', field: 'ediId', width: 100},
        {header: 'File Name', field: 'ediFileName', width: 1000},
      ],
      //maxlength: 50
    }

    this.settingBookingRejectionReason= {
      id: 'cbBookingRejectionReason',
      type: 'select', // search | select | select input
      url: this.configService.config.BASE_API.toString() + '/sha/getRejectionReasonList',
      placeholder: '',
      code: 'reasonCode',
      title: 'reasonName'
    };

    this.settingtxtOtherRejectionReason = {
      id: "txtOtherRejectionReason",
      name: "otherRejectionReason",
      placeholder: "",
      cols: 160,
      rows: 1,
      maxlength: 320,
      border: 350,
    };

  }/** End constructor - AccpRej Booking **/

  ngOnInit() {


  }

  ngAfterViewInit() {

    this.strParams = "";
    if (localStorage.getItem("Shanghai-booking-process-data-grid") != null &&
      localStorage.getItem("Shanghai-booking-process-data-grid") != undefined &&
      localStorage.getItem("Shanghai-booking-process-data-grid") != "undefined"
    ) {

      this.strParams = localStorage.getItem("Shanghai-booking-process-data-grid");
      if (this.strParams != null) {
        let par: any = JSON.parse(this.strParams);
        this.model.ediId=par.bEDIid;
        this.model.ediFileName=par.bEdiFileName;
        this.cbEDIFileName.setValue(this.model.ediFileName);

        localStorage.removeItem("Shanghai-booking-process-data-grid");
        this.tabStatus = "tab2";
        this.tabStatus1 = "";
        this.tabStatus2 = "active";
        this.gridShaBookingAccRejInfo.onClear();
        this.handleRetrieve();

      }
      this.genericUtil.hideLoader();

    }else{
    this.init();
    }

    var self = this;


  }

  init(){
    this.modeForm=true;
    if (this.tabStatus == "tab1"){

      this.disableToolbarButtons = "process";
      this.gridShaBookingEDIInfo.search = "*/*/*";
      this.gridShaBookingEDIInfo.onFirst();
      this.lockBtnShaBookingEDIDiscard = true;
    }
    if (this.tabStatus == "tab2"){
      this.disableToolbarButtons_AccpRejBkg = "detail";
      this.gridShaBookingAccRejInfo.clearSelectedValues();
      this.gridShaBookingAccRejInfo.loadData();
      // this.gridShaBookingAccRejInfo.onClear();
      //console.log('this.rejectionReason', this.rejectionReason);
    }


  }

  initTab1() {

    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
    this.tabStatus2 = "";
    this.modeForm=true;
    //this.modeToolbar=true;
    this.disableToolbarButtons = "process";

    //refersh tab 1
    this.gridShaBookingEDIInfo.search = "*/*/*";
    this.gridShaBookingEDIInfo.onFirst();
    this.lockBtnShaBookingEDIDiscard = true;

  }

  initTab2() {
    //this.grid2.listStore.store = this.grid.listStore.store;
    //this.grid2.loadData();
    this.tabStatus = "tab2";
    this.tabStatus1 = "";
    this.tabStatus2 = "active";
    this.modeForm=true;
    this.disableToolbarButtons_AccpRejBkg = "detail";
    this.clearOtherReason();
  }



  toolbarEvent(event) {
    console.log('event : ' + event);
    switch (event) {
      case 'process' :
        this.message(
          "save",
          "Process data",
          "Do you want to process the selected Booking EDI?",
          "yesno",
          { yes: "this.handleProcess()", no: "this.loading = false;" }
        );
        break;
      case 'close':
        // handle close event

        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  toolbarEvent_AccpRejBkg(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.gridShaBookingAccRejInfo.clearSelectedValues();
        this.gridShaBookingAccRejInfo.onClear();
        this.handleRetrieve();
        break;
      case 'detail' :
        this.handleDetail();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      // case "afterLoad":
      //     if (this.gridShaBookingAccRejInfo.listStore.store.length > 0) {
      //       this.model.vesselCode=this.gridShaBookingAccRejInfo.listStore.store[0]["VesselCode"];
      //       this.model.voyageNo=this.gridShaBookingAccRejInfo.listStore.store[0]["voyageNo"];
      //     }
      //     break;
    }
  }
  eventMessage(event: any){
   if (event != "") {
      eval(event);
    } else {
     event.preventDefault();
   }

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  //start Method - Process the EDI Booking data
  handleProcess() {
     // handle update event

    if (this.gridShaBookingEDIInfo.getSelectedValues().length >= 1) {
      // if( (this.txtreprocess.split('<br />').length-1)==this.gridShaBookingEDIInfo.getSelectedValues().length){
      //   this.loading = false;
      // }
      // else if(this.txtreprocess!=''){
      //   this.message(
      //     "save",
      //     "Process data",
      //     "Do you want to process the other selected Booking EDI?",
      //     "yesno",
      //     { yes: "this.processEvent()", no: "this.loading = false;" }
      //   );
      // }else{
      //   this.message(
      //     "save",
      //     "Process data",
      //     "Do you want to process the selected Booking EDI?",
      //     "yesno",
      //     { yes: "this.processEvent()", no: "this.loading = false;" }
      //   );
      // }

      this.txtreprocess  = '';
        this.gridShaBookingEDIInfo.getSelectedValues().forEach((element) => {
          if (element.ediIsProcessed == "Y"){
            this.txtreprocess = this.txtreprocess + element.ediFileName + '<br />';
           }
        });
        if(this.txtreprocess!="") {
          this.message(
            "information",
            "Information",
            "Re-processing is NOT allowed for file(s) listed below:<br />" + this.txtreprocess + "<br />" +
            "<br />System will proceed for other selected file(s) that have not been processed.",
            "okcancel",
            { ok: "this.processEvent()",cancel:"this.loading = false;"}
          );
        }else{
          this.processEvent();
          this.loading = false;
        }
    } else {
        this.message(
          "information",
          "Information",
          "No Booking EDI selected, please select at least 1 EDI.",
          "okonly",
          { ok: "this.loading=false;" }
        );
    }

    //let dt = this.gridShaBookingEDIInfo.getSelectedValues()[0];
    // console.log(dt);
   // this.LoadBkgEDIFile(dt);


  }

  processEvent(){
    this.loading = true;
    console.log('debugging')
   // console.log(this.model.EDIID);
    //console.log(this.model.FileName);

    let processList : string[]= [];
    this.gridShaBookingEDIInfo.getSelectedValues().forEach((element) => {
      if (element.ediIsProcessed != "Y"){
        processList.push(element.ediId);
       }
    });

    if(processList.length>0) {

      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() + "/sha/processBookingEDI",
          //"http://localhost:14000/sha/processBookingEDI",
          processList
        )
        .subscribe(
          (resp) => {
            if (resp.status == 200 ) { //return 500
              //clear all
              this.loading = false;
              this.info = "save successfully";

              this.message(
                "information",
                "Information",
                "Booking EDI processed successfully.",
                "okonly",
                { ok: "this.loading=false;this.afterProcess();" }
              );
            } else {
              this.loading = false;
              this.message(
                "warning",
                "Warning",
                "Booking EDI failed to processe.",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          },
          (error) => {
            this.loading = false;
            // console.log(error.message);
            this.info = error.message;
          }
        );
    } else{ //end if processList.length>0
      this.message(
        "information",
        "Information",
        "No valid Booking EDI for processing now.",
        "okonly",
        { ok: "this.loading=false;" }
      );

    }
  }

  afterProcess() {
    this.modeForm = false;

    this.disableToolbarButtons = "process";
    this.lockBtnShaBookingEDIDiscard = true;
    //this.modeToolbar=false;

    this.gridShaBookingEDIInfo.clearSelectedValues();
    this.gridShaBookingEDIInfo.loadData();

  }

  onShaBookingEDIInfo__Discard(event) {

    this.handleDiscard();

  }

  handleDiscard(){
    this.loading = true;
    if (this.gridShaBookingEDIInfo.getSelectedValues().length >= 1) {
      this.message(
        "delete",
        "Delete Shanghai Booking EDI",
        "Do you want to discard the selected Booking EDI?",
        "yesno",
        { yes: "this.discardEvent()", no: "this.loading = false;" }
      );
    } else {

      this.message(
        "information",
        "Information",
        "Process denied. No Booking EDI was selected.",
        "okonly",
        { ok: "this.loading=false;" }
      );

    }
  }

  //Discard Sha booking EDI File
  discardEvent() {
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.gridShaBookingEDIInfo.getSelectedValues().forEach((element) => {
       deleteList.push(element.ediId);
    });

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/sha/discardBookingEDI",
        //"http://localhost:14000/sha/discardBookingEDI",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
              this.loading = false;
              this.message(
                "information",
                "Information",
                "Booking EDI discarded successfully.",
                "okonly",
                { ok: "this.loading=false;this.afterDiscard();" }
              );
          } else {
              this.loading = false;
              this.message(
                "warning",
                "Warning",
                "Booking EDI failed to discard.",
                "okonly",
                { ok: "this.loading=false;" }
              );

          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterDiscard() {
    this.modeForm = false;

   //this.modeToolbar= false;
    this.disableToolbarButtons = "process";
    this.lockBtnShaBookingEDIDiscard = true;

    this.gridShaBookingEDIInfo.clearSelectedValues();
    this.gridShaBookingEDIInfo.loadData();
  }

  //End Method - Process the EDI Booking data

  /** Start Shanghai Booking EDI information Handler **/

  gridEventShaBookingEDIProcessInfo(event: any) {

    this.modeForm = true;

    switch (event.split(".")[0]) {
      case "selected":{

        // if(event.split(".").length>1){
        //   if(event.split(".")[1].split("-")[0]=="unchecked"){

        //   }else if(event.split(".")[1].split("-")[0]=="checked"){
        //     this.seletedEdiEvent(Number(event.split("-")[1]));
        //   }
        // }

        if (this.gridShaBookingEDIInfo.getSelectedValues().length > 0) {
          this.lockBtnShaBookingEDIDiscard = false;
          this.disableToolbarButtons = "";
        } else {
            this.disableToolbarButtons = "process";
            this.lockBtnShaBookingEDIDiscard = true;
        }
      }
        break;
      case "click":
        if (this.gridShaBookingEDIInfo.getSelectedValues().length > 0) {
          this.lockBtnShaBookingEDIDiscard = false;
          this.disableToolbarButtons = "" ;
        } else {
          this.disableToolbarButtons = "process";
          this.lockBtnShaBookingEDIDiscard = true;
       }
       break;
      case "afterLoad":
          var sDate='';
          var sTime='';
          if(this.gridShaBookingEDIInfo.listStore.store.length>0){
            this.gridShaBookingEDIInfo.listStore.store.forEach((element) => {
              sDate=element["ediDateUpload"];
              sTime=element["ediTimeUpload"];
              element["ediDateUpload"]=this.convertToDateString( sDate + sTime + '00');
              element["ediTimeUpload"]=this.convertToTimeString( sDate + sTime + '00');
            })



           // this.seletedEdi();
           //Because of Gird view bug, hence require this function to clear all the seleted value
            if(this.gridShaBookingEDIInfo.getSelectedValues().length > 0){
            //   this.lockBtnShaBookingEDIDiscard = false;
            //   this.disableToolbarButtons = "" ;
            // }
            // else{
              this.disableToolbarButtons = "process";
              this.lockBtnShaBookingEDIDiscard = true;
              this.gridShaBookingEDIInfo.clearSelectedValues();
            }

          }
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
          case "edit":
          //  this.LoadBkgEDIFile();
              break;
          case "rowDoubleClicked":
            if (this.gridShaBookingEDIInfo.getSelectedValues().length > 0) {
              this.lockBtnShaBookingEDIDiscard = false;
              this.disableToolbarButtons = ""
            } else {
              this.disableToolbarButtons = "process";
              this.lockBtnShaBookingEDIDiscard = true;
            }
          break;
          case "dblClick":
            if (this.gridShaBookingEDIInfo.getSelectedValues().length > 0) {
              this.lockBtnShaBookingEDIDiscard = false;
              this.disableToolbarButtons = ""
            } else {
              this.disableToolbarButtons = "process";
              this.lockBtnShaBookingEDIDiscard = true;
            }
            break;
          default:
            break;
          }
        }
      break;
    }
  }

  infoGridShaBookingEDIProcessInfo(event: any) {
    this.info = event;
  }


   /** End Shanghai Booking EDI information Handler **/

  //Start toolbarEvent - Accp Rej Bkg
  handleRetrieve(){
  //  console.log("retrieve Event");
  //  console.log(this.model);
    let isValid:boolean = this.onValidate(this.model);
    if(!isValid){
      console.log("isValid => " + isValid);
      this.retrieveBookingDetail();
    }

  }

  retrieveBookingDetail(){

    if(this.model.ediId != ''){

      this.gridShaBookingAccRejInfo.url = this.configService.config.BASE_API.toString() + "/sha/getChinaEdiLinesByEdiId";
      this.gridShaBookingAccRejInfo.search = this.model.ediId;
      this.gridShaBookingAccRejInfo.loadData();

    }

  }

  handleDetail(){
    var self = this;

    this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();
    this.param.bOceanVesselBound = "O";
    this.model.vesselId=this.gridShaBookingAccRejInfo.listStore.store[0]["vesselID"];
    this.model.voyageNo=this.gridShaBookingAccRejInfo.listStore.store[0]["voyageNo"];
    // this.model.vesselId=this.gridShaBookingAccRejInfo.getSelectedValues()[0]["vesselID"];
    // this.model.voyageNo=this.gridShaBookingAccRejInfo.getSelectedValues()[0]["voyageNo"];

    this.genericService
    .GET(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
        this.model.vesselId +
        "/" +
        this.sCharUtil.htmlEncode(String(this.model.voyageNo).trim()) +
        "/" +
        this.param.bOceanVesselBound +
        "/" +
        this.cookieService.getDefaultLocationCode() +
        "/DESC/custom"
    )
    .subscribe((resp) => {
      let dtx: any[];
      let valid: String;

      if (Object.keys(resp.json()["content"]).length > 0) {
        dtx = resp.json()["content"];
        valid = dtx[0]["isValid"];

        if (valid != "Y") {

          this.dialogPlugin.show(
            "information",
            "Information",
            "Vessel schedule is invalid, unable to create booking.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }else { //schedule valid, then check vsl allocation
          this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/VesselAllocation/findByVesselIDVoy/" +
              this.model.vesselId +
              "/" +
              this.sCharUtil.htmlEncode(String(this.model.voyageNo).trim()) +
              "/" +
              this.param.bOceanVesselBound +
              "/" +
              this.cookieService.getDefaultLocationCode()
          )
          .subscribe((resp) => {
            if(resp.ok){
              if (resp.json()["content"]!=null && resp.json()["content"]!="" && resp.json()["content"]!= undefined)
              { //both schedule and vls allocation valid

                //start Check if selected record has Accepted=Y or Rejected=Y
                let txtAccBkg  = "";
                let txtRejBkg  = "";

                this.gridShaBookingAccRejInfo.getSelectedValues().forEach((element) => {
                  if (element.accept=='Y' && txtAccBkg.includes(element.bNo)==false){
                    txtAccBkg = txtAccBkg + element.ediFileName + '(' + element.bNo + ')' + '<br />';
                  }
                  if (element.reject=='Y' && txtRejBkg.includes(element.bNo)==false){
                    txtRejBkg = txtRejBkg + element.ediFileName + '(' + element.bNo + ')' + '<br />';
                  }
                });

                if(txtAccBkg!="" || txtRejBkg!="") {
                  let msg="";

                  if(txtAccBkg!="") msg=msg + "Booking already exists, unable to proceed for:<br />" + txtAccBkg + "<br />";
                  if(txtRejBkg!="") msg=msg + "Booking already rejected, unable to proceed for:<br />" + txtRejBkg + "<br />";

                  this.message(
                    "information",
                    "Information",
                     msg +
                    "Do you want to proceed for other selected Booking(s)? ",
                    "okcancel",
                    { ok: "this.viewDetailEvent()",cancel:"this.loading = false;" }
                  );
                }else //both schedule and vsl allocation valid,the view detail
                this.viewDetailEvent();
                //End Check if selected record has Accepted=Y

              }else{
                this.message(
                  "information",
                  "Information",
                  "Allocation has not been set for this vessel, unable to create booking.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
              }
            }
          });
        }
      }else {
        this.message(
          "information",
          "Information",
          "Vessel/Voyage not found.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      }

    });

  }


  checkValidSchedule( vesselId: String,voyage: String, bound: String, officeCode: String ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByVesselVoyageBoundHeader/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode + "/DESC/custom"
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;

        if (Object.keys(resp.json()["content"]).length > 0) {
            dtx = resp.json()["content"];
            validSch = dtx[0]["isValid"];

            if (validSch != "Y") {

              this.dialogPlugin.show(
                "information",
                "Information",
                "Vessel schedule is invalid, unable to create booking.",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
            // else {
            //   validSch="Y";

            // }
        }else {
          validSch="NONE";
          this.message(
            "information",
            "Information",
            "Vessel/Voyage not found.",
            "okonly",
            { ok: "this.loading=false;" }
          );
        }

      });


    console.log("CEK VALID 3 : " + validSch);
    return validSch;

  }

  checkVesselAllocation(vesselId: String,voyage: String, bound: String, officeCode: String ): String {
    let validAllocation: String = "";
    console.log("CEK Vessel Allocation : " + validAllocation);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/VesselAllocation/findByVesselIDVoy/" +
          vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(voyage).trim()) +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        if(resp.ok){
          if (resp.json()["content"]!=null && resp.json()["content"]!="" && resp.json()["content"]!= undefined)
          {
            validAllocation="Y";
          }else{
            validAllocation="N";
            this.message(
              "information",
              "Information",
              "Allocation has not been set for this vessel, unable to create booking.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        }

      });
    console.log("CEK Vessel Allocation 3 : " + validAllocation);

    return validAllocation;
  }

  viewDetailEvent(){
    this.genericUtil.showLoaderPage();
    var bNo="";
    var BkgNo_1st="";

    //Clear Previous param
    this.param = new Param();
    this.param2 = new Param2();

    this.param2.bNoList='';
    this.param2.bNoListOriginal='';

    //Fill in original booking list
    this.gridShaBookingAccRejInfo.getSelectedValues().forEach((element) => {
      if(element.bNo!=bNo){
        if(this.param2.bNoListOriginal==''){
          this.param2.bNoListOriginal= element.bNo;
        }else{
          this.param2.bNoListOriginal= this.param2.bNoListOriginal + ',' + element.bNo;
        }

        bNo=element.bNo;
      }
    });

    //Fill in booking list to pass and show in booking maintenance
    bNo="";
    this.gridShaBookingAccRejInfo.getSelectedValues().forEach((element) => {

      if(element.bNo!=bNo && element.accept=='' && element.reject==''){
        if(this.param2.bNoList==''){
          BkgNo_1st=element.bNo;
          this.param2.bNoList= element.bNo;

          this.param.bEDIid=element.ediId;
          this.param.bOfficeCode = this.cookieService.getDefaultLocationCode();

          this.param.bOceanVesselId=this.model.vesselId;
          this.param.bOceanVesselCode=element.VesselCode;
          this.param.bOceanVesselVoyage=element.voyageNo;

          this.param.bPot1Code=element.potCode;
          this.param.bPolCode=element.polCode;
          this.param.bPodCode=element.podCode;
          this.param.bFDestCode=element.fdestCode;
          this.param.bContainerOwnership=element.containerOwnership;
          this.param["allocationValidator"] = true;
        }
        else{
          this.param2.bNoList= this.param2.bNoList + ',' + element.bNo;
        }

        bNo=element.bNo;
      }

    });

    if(this.param2.bNoList!='') {
      //const dt = this.gridShaBookingAccRejInfo.getSelectedValues()[0];
      this.continueToBookingMaintenance(BkgNo_1st); //dt.ediId,dt.bNo

    }else {

      this.message(
        "information",
        "Information",
        "No valid Booking(s) for acceptance now.",
        "okonly",
        { ok: "this.genericUtil.hideLoader();this.loading=false;" }
      );

    }

  }

  continueToBookingMaintenance(bno: string) {

    var self = this;

    if (localStorage.getItem("Shanghai-booking-process-data-grid") != null &&
    localStorage.getItem("Shanghai-booking-process-data-grid") != undefined &&
    localStorage.getItem("Shanghai-booking-process-data-grid") != "undefined"
    ) {
      localStorage.removeItem("Shanghai-booking-process-data-grid");
    }
    // console.log("Above set time out ");
    // setTimeout(function () {

    self.param.selected = bno;

    self.param2.bOfficeCode=self.param.bOfficeCode;
    self.param2.bEDIid=self.model.ediId;
    self.param2.bEdiFileName=self.model.ediFileName;

    localStorage.setItem("Shanghai-booking-process-data-grid",JSON.stringify(self.param2));

    //localStorage.setItem("booking-from-Shanghai-booking-process-data-grid",JSON.stringify(self.gridShaBookingAccRejInfo.listStore.store));

    console.log(self.param);
    if(localStorage.removeItem("retrieve-booking-from-shanghai-processed-edi")!=null){
      localStorage.removeItem("retrieve-booking-from-shanghai-processed-edi");
    }
    localStorage.setItem("retrieve-booking-from-shanghai-processed-edi",JSON.stringify(self.param));

    self.router.navigate(["/main/transaction-booking-maintenance"]);
    // }, 100);
  }


  onShaBookingReject(event){

    this.message(
      "delete",
      "Reject Shanghai Booking",
      "Do you want to reject the selected Booking(s)?",
      "yesno",
      { yes: "this.handleReject()", no: "this.loading = false;" }
    );
  }

  handleReject(){

    if (this.gridShaBookingAccRejInfo.getSelectedValues().length >= 1) {
      console.log('rejectEvent: ' + this.cbBookingRejectionReason.getValue())
      if(!this.cbBookingRejectionReason.getValue() || this.rejectionReason==''){
        this.showMessage('Please select "Reason"!');
      }else if(this.rejectionReason == 'Other' &&  this.txtOtherRejectionReason.getValue()==''){
        this.showMessage('Please enter "Other" Reason!');
      }else {
          let txtInvalidRejectBkg  = "";

          this.gridShaBookingAccRejInfo.getSelectedValues().forEach((element) => {
            if (element.reject != '' || element.accept!='' || element.rejectionReasons!=''){
              txtInvalidRejectBkg = txtInvalidRejectBkg + element.ediFileName + '(' + element.bNo + ')' + '<br />';
            }
          });

          if(txtInvalidRejectBkg!="") {
            this.message(
              "information",
              "Information",
              "Unable to reject Booking(s) listed below:<br />" + txtInvalidRejectBkg + "<br />" +
              "<br />System will proceed rejection of other selected Booking(s) now.",
              "okcancel",
              { ok: "this.rejectEvent()",cancel:"this.loading = false;"}

            );
          }else{this.rejectEvent(); }
      }
    } else {
      this.message(
        "information",
        "Information",
        "Process denied. No record was selected.",
        "okonly",
        { ok: "this.loading=false;" }
      );
    }
  }

  rejectEvent(){
    this.loading = true;
    console.log('rejectEvent')
    // console.log(this.model.ediId);
     //console.log(this.model.ediFileName);

     let rejectList_model : sha_booking_edi[]=[];
     let rejectModel : sha_booking_edi;

     this.gridShaBookingAccRejInfo.getSelectedValues().forEach((element) => {
       if (element.reject == "" && element.accept=="" && element.rejectionReasons==""){
        rejectModel = new sha_booking_edi();
        rejectModel.ediId=element.ediId;
        rejectModel.ediLinesNo=element.fileLinesNo;
        if(this.rejectionReason == 'Other'){
          rejectModel.rejectionReason=this.txtOtherRejectionReason.getValue();
        }else{
          rejectModel.rejectionReason=this.rejectionReason;
        }
        rejectList_model.push(rejectModel);

      }
     });

     if(rejectList_model.length>0) {

       this.genericService
         .POST(
           this.configService.config.BASE_API.toString() + "/sha/rejectBookingContainer",
           //"http://localhost:14000/sha/rejectBookingContainer",
           rejectList_model
         )
         .subscribe(
           (resp) => {
             if (resp.status == 200 ) { //return 500
               //clear all
               this.loading = false;
               this.info = "rejected successfully";

               this.message(
                 "information",
                 "Information",
                 "Booking(s) rejected successfully.",
                 "okonly",
                 { ok: "this.loading=false;this.afterReject();" }
               );
             } else {
               this.loading = false;
               this.message(
                 "warning",
                 "Warning",
                 "No Booking(s) rejection now.",
                 "okonly",
                 { ok: "this.loading=false;" }
               );
             }
           },
           (error) => {
             this.loading = false;
             // console.log(error.message);
             this.info = error.message;
           }
         );
     } else{ //end if rejectList_model.length>0
       this.message(
         "information",
         "Information",
         "No valid Booking(s) for rejection now.",
         "okonly",
         { ok: "this.loading=false;" }
       );

     }
  }

  afterReject(){
    this.modeForm = false;

    this.disableToolbarButtons_AccpRejBkg = "detail";
    this.lockBtnShaBookingReject = true;
    //this.modeToolbar=false;

    this.clearOtherReason();
    this.gridShaBookingAccRejInfo.clearSelectedValues();
    this.gridShaBookingAccRejInfo.loadData();

  }
  //End toolbarEvent - Accp Rej Bkg



  /** Start Acceptance / Rejection Shanghai Booking information Handler **/
  gridEventShaBookingAccRejInfo(event: any) {
    this.modeForm = true;

    switch (event.split(".")[0]) {
      case "selected":{

        if(event.split(".").length>1){
          if(event.split(".")[1].split("-")[0]=="unchecked"){
            this.uncheckRowEvent(Number(event.split("-")[1]));
          }else if(event.split(".")[1].split("-")[0]=="checked"){
            this.checkRowEvent(Number(event.split("-")[1]));
          }
        }

          if (this.gridShaBookingAccRejInfo.getSelectedValues().length > 0) {
            this.lockBtnShaBookingReject = false;
            this.disableToolbarButtons_AccpRejBkg = ""
          } else {
              this.disableToolbarButtons_AccpRejBkg = "detail";
              this.lockBtnShaBookingReject = true;
              this.clearOtherReason();
          }
        }
        break;
      case "click":{
        if(event.split(".").length>1){
          if(event.split(".")[1].split("-")[0]=="unchecked"){
            this.uncheckRowEvent(Number(event.split("-")[1]));
          }else if(event.split(".")[1].split("-")[0]=="checked"){
            this.checkRowEvent(Number(event.split("-")[1]));
          }
        }

        if (this.gridShaBookingAccRejInfo.getSelectedValues().length > 0) {
          this.lockBtnShaBookingReject = false;
          this.disableToolbarButtons_AccpRejBkg = ""
        } else {
          this.disableToolbarButtons_AccpRejBkg = "detail";
          this.lockBtnShaBookingReject = true;
          this.clearOtherReason();
       }
      }
       break;

      case "afterLoad":
        let self = this;

        setTimeout(() => {
          var idx = 0;
          let selectedList: String[]
          let par: any;

          //
          if(this.strParams !="" && this.strParams != null){
            par = JSON.parse(this.strParams);
            selectedList = String(par.bNoListOriginal).split(",");
            this.gridShaBookingAccRejInfo.clearSelectedValues();
          }


          this.gridShaBookingAccRejInfo.listStore.store.forEach((element) => {


            if(this.strParams !="" && this.strParams != null){

              selectedList.forEach((el) => {
                if( element["ediId"] == par.bEDIid && element["bNo"] == el && element['select']==''){
                  element['select'] = 'checked'
                  this.gridShaBookingAccRejInfo.onSelect(true,idx +1)
                }
              });
            }

            if(element["reject"] == 'Y'){
              //self.gridShaBookingAccRejInfo.disableRowGrid(idx,true);    //enable grid view row but system keep loading
              self.markRedRow(idx); //element.no-1

            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
          case "edit":
          //  this.LoadBkgEDIFile();
              break;
          case "dblClick":
            if (this.gridShaBookingAccRejInfo.getSelectedValues().length > 0) {
              this.lockBtnShaBookingReject = true;
              this.disableToolbarButtons_AccpRejBkg = ""
          } else {
            this.disableToolbarButtons_AccpRejBkg = "detail";
            this.lockBtnShaBookingReject = false;
            this.clearOtherReason();
          }

            break;
          default:
            break;
          }
        }
      break;
    }
  }

  infoGridShaBookingAccRejInfo(event) {
   this.modeForm=true;



  }

  changeEventEDIFileName (event) {
     this.model['error-ediFileName'] = "";

     if(event.ediId != null && event.ediId != undefined && event.ediId != ""){

      this.model.ediId = event.ediId;
      this.model.ediFileName = event.ediFileName;

    //   if(event.capacity != null){
    //     this.extVesselCapacity = parseFloat(event.capacity);
     //  }

    } else {
      this.model.ediId = "";
      this.model.ediFileName ="";
    }

    this.cbEDIFileName.setUrl(this.configService.config.BASE_API.toString() + '/sha/findByComboBoxControl/ediFileName={query}');

    }

  changeEventBookingRejectionReason(event) {
    this.rejectionReason = event.reasonName;

    if(this.rejectionReason == 'Other'){
      this.isModify = true;
    }
    else{
      this.isModify = false;
      this.txtOtherRejectionReason.setValue("");

      // // added by glg to resolve GCHO-95
      // this.cbBookingRejectionReason.setForceValue(this.rejectionReason);
    }

  }
  backspaceValue(event: any, regexName: RegExp, field: string) {

    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field);
        break;
      default:
        return
    }


  }

  onPasteValidation(  event: any,  field: string, regexName: RegExp ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field);
  }

  showErrorMessage(regexName, pastedText, field){
    const fileName = field === 'ediFileName';
    if (pastedText && regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${fileName} 'Only accept alphanumeric and -./'`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  clearOtherReason(){
    this.isModify = false;
    this.txtOtherRejectionReason.setValue("");
    this.cbBookingRejectionReason.setValue("");
    this.rejectionReason="";
  }
  showMessage(message: string) {
    this.message('information', 'Information', message, 'okonly', { ok: 'this.loading=false;' });
  }

  markRedRow(dataNo){
    var i = this.gridShaBookingAccRejInfo.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        //$(this).addClass("disabled");
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.gridShaBookingAccRejInfo.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

  checkRowEvent(index:number){
    //check corresponding bookings
    var bookingNo = this.gridShaBookingAccRejInfo.listStore.store[index-1]["bNo"];
    this.gridShaBookingAccRejInfo.listStore.store.forEach((el) => {
      if(bookingNo==el["bNo"] && el['select']=='' ){
        el['select']='checked';
        this.gridShaBookingAccRejInfo.onSelect(true,el["no"]);
      }
      // if(bookingNo==el["bNo"] && el['select']=='checked' && el['reject']=='Y'){
      //   el['select']='';
      //   this.gridShaBookingAccRejInfo.onSelect(false,el["no"]);
      // }
    });

  }

  uncheckRowEvent(index:number){

    var bookingNo = this.gridShaBookingAccRejInfo.listStore.store[index-1]["bNo"];
    //uncheck corresponding bookings
    this.gridShaBookingAccRejInfo.listStore.store.forEach((el) => {
      if(bookingNo==el["bNo"] && el['select']=='checked' ){//
        el['select']='';
        this.gridShaBookingAccRejInfo.onSelect(false, el["no"]);
      }
    });

  }

  // seletedEdiEvent(index:number){
  //   //check corresponding EDIId
  //   var ediId = this.gridShaBookingEDIInfo.listStore.store[index-1]["ediId"];
  //   this.seletedBookingEdi_Model.push(ediId);
  // }

  // seletedEdi(){
  //   this.gridShaBookingEDIInfo.listStore.store.forEach((el) => {
  //     this.seletedBookingEdi_Model.forEach((el2)=>{
  //       if(el2["ediId"]==el["ediId"] && el['select']=='' ){
  //         el['select']='checked';
  //         this.gridShaBookingEDIInfo.onSelect(true,el["no"]);
  //       }

  //     })
  //   });

  // }

  convertToDateString(fileDate: string): string {
    return moment(fileDate, 'YYYYMMDDHHmmss').format('YYYY-MM-DD');
  }

  convertToTimeString(fileTime: string): string {
    return moment(fileTime, 'YYYYMMDDHHmmss').format('HH:mm:ss');
  }
  /** End Acceptance / Rejection Shanghai Booking information Handler **/


}
