import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Authorize, GenericService, ConfigService, GenericUtil } from 'sibego-ui-library';
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/timer";
import "rxjs/add/operator/finally";
import "rxjs/add/operator/takeUntil";
import "rxjs/add/operator/map";
import { LocalMasterExchangeRatePageComponent } from '../local-master-exchange-rate-page/local-master-exchange-rate-page.component';
import { } from "@types/googlemaps";
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import * as jwt_decode from "jwt-decode";

declare  var $:any;

export class Marker {
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  constructor() {

  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild('gmap', { static: true }) gmapElement: any;
  // map: google.maps.Map;

  countdown:number=0;
  startCount:number=0;
  statusMaster:string="OK";

  i:number=1000;
  d:number= 60 * 1000;

  txt:string = '';

  /*
  // initial position
  zoom: number = 1;
  lat: number = -6.197366;
  lng: number = 106.797145;
  label: string = '';
  title: string = '';

  origin: LatLng = {lat: 13.7037553, lng: 100.5666717}
  destination: LatLng = {lat: -6.1003705, lng: 106.8498745}
 
  */

 paths: LatLng[] = []
 samuderaOffices: marker[] = []
 iframeUrl: any;
 iframeTokenTimeout: NodeJS.Timer | null = null;

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private configService: ConfigService,
    public sanitizer: DomSanitizer,
    private genericUtil: GenericUtil) {
    /*
    const interval = 1000;
    const duration = 10 * 1000;


    const stream$ = Observable.timer(this.startCount, interval)
      .finally(() =>   {this.countdown=0;} )
      .takeUntil(Observable.timer(duration + interval))
      .map(value => duration - value * interval);

     stream$.subscribe(value => {
       this.countdown = value/interval;
       if(this.countdown == 0){
         this.countdown=duration;
       }
       // console.log("Countdown - " + this.countdown);
     });

    */
    // console.log("Countdown = " + this.countdown);
//    if(this.countdown == 0){
      //this.countDown();
  //  }

     //hit master
     
    //  this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterOffices/getOffices').subscribe((resp) =>{
    //   console.log(resp)
    //   let data = resp.json()['content']

    //   data.forEach(element => {
    //     let m = new Marker();
    //     m.lat = Number.parseFloat(element['latitude']);
    //     m.lng = Number.parseFloat(element['longitude']);
    //     m.label = element['officeName'];
    //     m.draggable = false;
    //     console.log(m)
    //     this.samuderaOffices.push(m);  
        
    //     var marker = new google.maps.Marker({
    //       position: {lat: m.lat, lng: m.lng},
    //       map: this.map,
    //       title: m.label
    //     });

    //   });
      
    // })

    
    
    
  }

  ngOnInit() {
    // this.renderChart();
    const tokenMetaBase = localStorage.getItem('tokenMetaBase');
    const urlMetaBase = localStorage.getItem('urlMetaBase');
    
    if (!tokenMetaBase || !urlMetaBase) {
      this.getTokenIframe();
    } else {
      const expiredTime = jwt_decode.jwtDecode(tokenMetaBase).exp;
      const expires = new Date(expiredTime * 1000);
      const syncGap = 60 * 1000; // 1 min - synchronization gap between server time & client time (worst case)
      const timeout = expires.getTime() - Date.now();
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('urlMetaBase'));
      this.iframeTokenTimeout = setTimeout(() => this.getTokenIframe(), timeout - syncGap);
    }
  }

  ngOnDestroy(): void {
    if (this.iframeTokenTimeout) clearTimeout(this.iframeTokenTimeout);
  }

  ngAfterViewInit() {
    $("body").css({"overflow-y":"visible"});

    // let mapProp = {
    //   center: new google.maps.LatLng(-6, 106),
    //   zoom: 5,
    //   streetViewControl: false,
    //   mapTypeControl: false
    // };
    // this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
  }

  openDashboard(){

  }

  // countDown(){
    // const stream = Observable.timer(0,this.i)
    //  .finally(() => {/*do nothing*/})
    //  .takeUntil(Observable.timer(this.d + this.i))
    //  .map(v => this.d - v * this.i);

   // stream.subscribe(v => {
   //   this.countdown = v/this.i;
   // });


  // }



  searchEvent(event){
    console.log(event)
    console.log("search => " + this.txt)
    this.onRetrieve();    
  }

  onRetrieve(){
    // if(this.txt == ""){
    //   this.vesselTracking("--kosong--")
    // }else{
    //   this.vesselTracking(this.txt.replace(" ",""))
    // }
  }

  retrieve(event){
    // console.log(event);
    if(event.keyCode == 13){
      this.onRetrieve();
    }
  }


  // vesselTracking(param){
  //   this.genericService.GET('http://staging.glossys.samudera.id:5000/api/v1/vessels?param=' + param).subscribe((resp) =>{
     
  //     let vessels = resp.json()
  //     vessels.forEach(element => {

  //         let info = element['info']
  //         console.log(info)

  //         let shipTrack = element['path']
  //         console.log(shipTrack)
  //         this.paths = []
  //         shipTrack.forEach(element => {
  //           let m = new Marker();
  //           m.lat = Number.parseFloat(element['lat']);
  //           m.lng = Number.parseFloat(element['lng']);

  //           this.paths.push(m)
  //         })

  //         var shipPath = new google.maps.Polyline({
  //           path: this.paths,
  //           strokeColor: '#000000',
  //           strokeOpacity: 0.5,
  //           strokeWeight: 1
  //         });
  //         shipPath.setMap(this.map)


  //         let shipLastPosition = element['lastPosition']
  //         console.log(shipLastPosition)

  //         let latitude = Number.parseFloat(shipLastPosition['lat'])
  //         let longitude = Number.parseFloat(shipLastPosition['lng'])          
  //         let speed = Number.parseFloat(shipLastPosition['speed'])
  //         let rotate = Number.parseFloat(shipLastPosition['course'])
      
  //         var contentString = '<div id="content" style="width:300px;height:250px;">' +
  //           '<h1 id="firstHeading" class="firstHeading">' + info['name'] + '</h1>' +
  //           '<div id="bodyContent">' +
  //               '<table class="ui celled table"><tbody>'+
  //               '<tr><td style="background-color:gainsboro;">MMSI</td><td>'+ info['mmsi']+'</td></tr>'+
  //               '<tr><td style="background-color:gainsboro;">IMO</td><td>'+ info['imo']+'</td></tr>'+
  //               '<tr><td style="background-color:gainsboro;">Call Sign</td><td>'+ info['callSign']+'</td></tr>'+
  //               '<tr><td style="background-color:gainsboro;">Speed</td><td>'+ speed+' Knots</td></tr>'+
  //               '<tr><td style="background-color:gainsboro;">Course</td><td>'+ rotate+'°</td></tr>'+
  //               '</tbody></table>' +
  //             '</div>' +
  //         '</div>';

  //         var infowindow = new google.maps.InfoWindow({
  //           content: contentString
  //         });

  //         var shipMarker = new google.maps.Marker({
  //           position: {lat: latitude, lng: longitude},
  //           title: info['name']      
  //         });
          
  //         shipMarker.setIcon({
  //           path: google.maps.SymbolPath.CIRCLE,
  //           strokeColor: 'black',
  //           strokeWeight: 1,
  //           fillColor: 'yellow',
  //           fillOpacity:1,
  //           scale: 4
  //         })

  //         if(speed > 1){
  //           shipMarker.setIcon({
  //             path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  //             strokeColor: 'black',
  //             strokeWeight: 1,
  //             fillColor: 'yellow',
  //             fillOpacity:1,
  //             scale: 4,
  //             rotation: rotate
  //           })
  //         }
  //         shipMarker.setMap(this.map)

  //         google.maps.event.addListener(shipMarker, 'click', function () {
  //           infowindow.open(this.map, shipMarker);
  //         });
  //         this.map.setZoom(17);
  //         this.map.panTo(shipMarker.getPosition());
    
      
  //     });
  //   })
  // }

  getTokenIframe(id?: string) {
    const body = {
      dashboard: '1'
    };
    this.genericUtil.showLoader();
    this.genericService.POST(this.configService.config.BASE_API.toString() + `/dashboard/auth/login/1`, body).pipe(
      finalize(() => this.genericUtil.hideLoader())
    ).subscribe((resp) => {
      const data = resp.json();
      const METABASE_SITE_URL = this.configService.config.dashboardUrl.toString();
      const token = data.token;
      var url = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";
      this.iframeUrl= this.sanitizer.bypassSecurityTrustResourceUrl(url);
      localStorage.setItem('tokenMetaBase', token);
      localStorage.setItem('urlMetaBase', url);
     })
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

interface LatLng{
  lat: number;
  lng: number;
}

