<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-etaToValidator'] == null || model['error-etaToValidator'] == ''">
        {{model['error-etaToValidator']}}
      </li>
    </ul>
  </div>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>
  <div id='container'>
      <!--<div class="ui error message" [class.hidden]="isError == false">
          <ul>
            <li [class.hidden]="model['error-fromDate'] == null || model['error-fromDate'] == ''">
              {{model['error-fromDate']}}
            </li>
            <li [class.hidden]="model['error-toDate'] == null || model['error-toDate'] == ''">
              {{model['error-toDate']}}
            </li>            
          </ul>
        </div>-->
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field">
        <fieldset style="border: 1px solid lightgray;">          
          <div class="field" style="width:400px">
              <div class="field">
                <label>Vessel ETA FROM</label>
                  <div class="ui left icon input">
                    <input id="etaFrom" readonly type="text" class="form-control" data-toggle="datepicker" placeholder="Date From">
                    <i class="calendar icon"></i>
                  </div>  
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-fromDate'] != null && model['error-fromDate'] != ''">
                    {{model['error-fromDate']}}
                  </div>          
              </div>
              <div class="field">
                <label>Vessel ETA TO</label>
                  <div class="ui left icon input">
                    <input id="etaTo" readonly type="text"  class="form-control" data-toggle="datepicker" placeholder="Date To">
                       <i class="calendar icon"></i>
                  </div>   
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-toDate'] != null && model['error-toDate'] != ''">
                    {{model['error-toDate']}}
                  </div>       
              </div>
          </div>
          <!-- <div class="field" style="width:400px">
            <label>Report Type</label>             
              <div class="field">    
                <div class="ui radio checkbox" >
                  <input type="checkbox"  name="excel" tabindex="0" >
                  <label>Excel</label>
                </div>              
              </div>   
          </div>-->
        </fieldset>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
