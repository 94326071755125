import { Component, Input, Output, OnInit, EventEmitter, AfterViewInit, OnDestroy} from '@angular/core';
import { ListStore, GenericService, GenericUtil  } from 'sibego-ui-library';
import { Subscription } from 'rxjs';

declare  var $:any;

@Component({
  selector: 'grid-plugin2',
  templateUrl: './grid-plugin2.component.html',
  styleUrls: ['./grid-plugin2.component.css']
})
export class GridPluginComponent2 implements OnInit, OnDestroy{
  @Input('settings') _settings:any;
  @Input('gridEvent') _event;
  @Output() infoGrid = new EventEmitter<any>();
  @Output('gridEvent') gridEvent = new EventEmitter<any>();

  loading = false;
  auto:boolean = true;

  lock = false;
  is_disabled_next = false;
  is_disabled_prev = false;

  is_select_all = '';
  is_sort_direction;
  is_sort_column;

  currentPage = 1;
  curPage =1;
  totalPage = 0;
  search = '';

  id:string = 'container';
  url:string;
  page:number;
  columns=[];
  enableSorting: boolean = true;
  enableSelectAll:boolean = true;
  enablePagination:boolean = true;
  sortingColumns = '';
  sortingDirection = 'ASC';
  body;
  cbPadding = 8;

  listStore = new ListStore;
  store = [];
  private selectList = [];
  modeRadio = false;

  buttons = [];

  isMobile:boolean = false;
  editable:boolean = false;

  service:Subscription;

  dataSelect:any;
  comboActive:string = '';
  modeEnterCombo: boolean = false;
  listStoreCombo = new ListStore;
  mymodelCombo:string;
  loadingCombo: boolean = false;
  columnsCombo=[];
  columnCombo:any;
  rowCombo:any;
  noEdit:number;

  constructor(private genericService:GenericService, private genericUtil:GenericUtil) {
    this.infoGrid = new EventEmitter<any>();
    this.isMobile = genericUtil.isMobile();
    //// console.log('is mobile ? ' + this.isMobile);

    var self = this;
    $(window).scroll(function(){

      if(self.comboActive != null && self.comboActive != ''){
        //// console.log('SCROLL BODY' + self.comboActive + ' - ' + self.id);

        var rect = document.getElementById(self.comboActive).getBoundingClientRect();
        //// console.log('top = ' + rect.top + ' - left = ' + rect.left + ' - height = ' + rect.height);
        var rectTop = rect.top + rect.height;
        //// console.log('recTop = ' + rectTop);
        $("#" + 'resultss-' + self.id).css('top', rectTop);
        $("#" + 'resultss-' + self.id).css('left', rect.left);
      }

    });

  }

  ngOnInit() {

    if(this._settings['id'] != null){
      this.id = this._settings['id'];
    }

    this.url = this._settings['url'];
    this.page = this._settings['page'];
    this.columns = this._settings['columns'];
    this.buttons = this._settings['buttons'];
    this.enableSorting = this._settings['enableSorting'];
    this.enableSelectAll = this._settings['enableSelectAll'];
    this.enablePagination = this._settings['enablePagination'];
    this.sortingColumns = this._settings['sortingColumns'];
    this.sortingDirection = this._settings['sortingDirection'];

    if(this._settings['editable'] != null){
      this.editable = this._settings['editable'];
    }

    this.auto = this._settings['auto'];
    if(this.isMobile == true){
      this.auto = true;
    }

    if(this.enableSorting){
      this.is_sort_direction = this.sortingDirection;
      this.is_sort_column = this.sortingColumns;
    }else{
      this.is_sort_direction = '';
      this.is_sort_column = '';
    }

    if(this._settings['cbPadding'] != null){
      this.cbPadding = this._settings['cbPadding'];
    }

    this.body = this._settings['body'];

  }

  ngAfterViewInit(){
    $('.ui.radio.checkbox').checkbox();

  }

  ngOnDestroy(){
    if(this.service != null){
      //// console.log('destroy grid');
      this.service.unsubscribe();
    }
  }

  setBody(store:any){
    this.body = store;
  }

  loadData(){
      let dir = '*';
      let cSort = '*';
      let cPage = 0;
      let pg  = 0;

      if(this.url != ''){

          var t0 = window.performance.now();
          this.loading = true;

          if(this.is_sort_direction != null){
            dir = this.is_sort_direction;
          }
          if(this.is_sort_column != null){
            cSort = this.is_sort_column;
          }

          cPage = 1;
          pg = 1000000;

          if(this.enablePagination){
             cPage =  this.currentPage;
             pg = this.page;
          }

          var targetURL;
          var method;
          if(this.body == null){
            targetURL = this.url.concat("/"+this.search+"/"+cPage+"/"+pg+"/"+dir+"/"+cSort);
            method = this.genericService.GET(targetURL);
          }else{
            targetURL = this.url.concat("/"+cPage+"/"+pg+"/"+dir+"/"+cSort);
            method = this.genericService.POST(targetURL,this.body);
          }
          this.service = method.subscribe((resp) =>{

                this.store = resp.json()['content'];

                let no:number=1;

                if(this.currentPage > 1){
                  no = ((this.currentPage * this.page) - this.page)+1;
                }
                this.store.forEach(element => {
                    element['select'] = '';
                    element['no'] = no++;

                    this.columns.forEach(col => {
                        if(col['type'] != null && col['type']=='number'){
                          var dec:number = 5;
                          if(col['decimal'] != null){
                            dec = col['decimal'];
                          }
                          var n:number = element[col['field']];
                          element[col['field']] = n.toFixed(dec);

                        }else if(col['type'] != null && col['type']!='undefined' && col['type']!='number'){
                           element[col['field']] = element[col['field']].replace(/ /g,'&nbsp;');
                        }

                    });

                });

                this.columns.forEach(col => {

                  col['sort'] = 'false';
                  if(this.enableSorting){
                    if(col['field'] == this.is_sort_column){
                      col['sort'] = 'true';
                    }
                  }

                  this.store.forEach(element => {

                    if(col['render'] != null){
                      var valRender : string = col['render']['value'];
                      var newValRender = this.generateValRender(valRender,element);
                      element[col['field']] = newValRender;
                    }

                  });

                });

                this.listStore.store = this.store;

                this.totalPage =  resp.json()['totalPages'];
                var t1 = window.performance.now();
                var total =  (t1 - t0);

                this.infoGrid.emit(total.toFixed(2));
                this.loading = false;

                if(this.enablePagination){
                    //// console.log('this.currentPage : ' + this.currentPage + ' - this.totalPage : ' + this.totalPage);
                    if(this.currentPage == 1){
                      if(this.totalPage > 1){
                        this.is_disabled_prev = true;
                        this.is_disabled_next = false;
                      }else if(this.totalPage <= 1){
                        this.is_disabled_prev = true;
                        this.is_disabled_next = true;
                      }

                    }else if(this.currentPage > 1 && this.currentPage < this.totalPage){
                      this.is_disabled_prev = false;
                      this.is_disabled_next = false;

                    }else if(this.currentPage == this.totalPage){
                      this.is_disabled_prev = false;
                      this.is_disabled_next = true;
                    }
                }

                this.service.unsubscribe();

          },error => {
            this.loading = false;
            // console.log(error.message);
            this.infoGrid.emit(error.message);
          });

      }else{
        //// console.log('current page : ' + this.currentPage);

        let start:number = 1;
        let end:number =  this.page;

        if(this.currentPage == 1){
          start = 1;
          end =  this.page;
        }else{
          start = (this.currentPage * this.page)-1;
          end =  (start + this.page)-1;
        }

        //// console.log('start : ' +  start + ' - end : ' + end);

        this.totalPage = Math.round(this.listStore.store.length / this.page);
        if(this.totalPage < 1){
          this.totalPage = 1;
        }
        //// console.log('Total Page : ' + this.totalPage);

        this.store = [];
        let no:number=1;

        if(this.currentPage > 1){
          no = ((this.currentPage * this.page) - this.page) + 1;
        }
        this.listStore.store.forEach(element => {
            element['select'] = '';
            element['no'] = no++;

            //// console.log('seq no '+element.seq);
            if(this.enablePagination){
              if(no >= start && no <= end){
                this.store.push(element);
              }
            } else {
              this.store.push(element);
            }

            //no++;
        });


        this.columns.forEach(col => {

            col['sort'] = 'false';
            if(this.enableSorting){
              if(col['field'] == this.is_sort_column){
                col['sort'] = 'true';
              }
            }

            if(col['render'] != null){
              this.store.forEach(element => {
                var valRender : string = col['render']['value'];
                var newValRender = this.generateValRender(valRender,element);
                element[col['field']] = newValRender;
              });

               this.listStore.store.forEach(element => {
                var valRender : string = col['render']['value'];
                var newValRender = this.generateValRender(valRender,element);
                element[col['field']] = newValRender;
              });
            }

        });

        if(this.enablePagination){
            if(this.currentPage == 1){
              if(this.totalPage > 1){
                this.is_disabled_prev = true;
                this.is_disabled_next = false;
              }else if(this.totalPage <= 1){
                this.is_disabled_prev = true;
                this.is_disabled_next = true;
              }
            }else if(this.currentPage > 1 && this.currentPage< this.totalPage){
              this.is_disabled_prev = false;
              this.is_disabled_next = false;
            }else if(this.currentPage == this.totalPage){
              this.is_disabled_prev = false;
              this.is_disabled_next = true;
            }
        }


      }

  }

  onFirst(){
    this.is_select_all = '';
    this.currentPage = 1;
    this.curPage = this.currentPage;
    this.loadData();
  }

  onPrev() {
      this.is_select_all = '';
      this.currentPage--;
      this.curPage = this.currentPage;
      this.loadData();
  }

  onNext() {
      this.is_select_all = '';
      this.currentPage++;
      this.curPage = this.currentPage;
      this.loadData();
  }

  onLast(){
    this.is_select_all = '';
    this.currentPage  = this.totalPage;
    this.curPage = this.currentPage;
    this.loadData();
  }

  onPage(event){
    if(event.keyCode == 13){
      event.preventDefault();
      if(event.target.value > 0 && event.target.value <= this.totalPage){
        this.is_select_all = '';
        this.currentPage = event.target.value;
        this.curPage = this.currentPage;
        this.loadData();
      }else{
        this.curPage = this.currentPage;
      }
    }
  }

  onChangeData(val:number){
    if(val != null){
      this.curPage = val;
    }
  }

  onSelectAll(check:boolean){
    this.selectList = [];
    this.listStore.store.forEach(element => {
        if(check){
          element['select'] = 'checked';
          this.is_select_all = 'checked';
          this.selectList.push(element);
        }else{
          element['select'] = '';
          this.is_select_all = '';
        }
    });

    this.gridEvent.emit('selected');
  }

  onSelect(check:boolean, no: number){
      let data = this.listStore.findData(no);

      if(check){
        if(this.modeRadio){
          this.resetSelectRadio();
        }
        data['select']='checked';
        this.selectList.push(data);
      }else{
        data['select']='';
        var idx:number = this.selectList.indexOf(data);
        this.selectList.splice(idx,1);
      }

      this.gridEvent.emit('selected');
  }

  resetSelectRadio(){
    this.listStore.store.forEach(element=>{
      element['select'] = '';
    });
    this.clearSelectedValues();
  }

  getSelectedValues():any[]{
    return this.selectList;
  }

  clearSelectedValues() {
    this.selectList = [];
    this.onSelectAll(false);
  }

  onDoubleClick(no: number){
    if(this.editable == false){
      let data = this.listStore.findData(no);
      this.selectList = [];
      this.selectList.push(data);

      this.gridEvent.emit("dblClick-"+no);
    }
  }

  onEnter(event){
    if(event.keyCode == 13){
          event.preventDefault();
    }
  }

  onClick(check:boolean ,no: number){
    if(this.editable ==  false){
      let data = this.listStore.findData(no);
      var msg = '';
      if(check){
        data['select']='';
        var idx:number = this.selectList.indexOf(data);
        this.selectList.splice(idx,1);
        msg = 'click.unchecked';
      } else {
        data['select'] = 'checked';
        //this.selectList = [];
        this.selectList.push(data);
        msg = 'click.checked';
      }

      this.gridEvent.emit(msg+"-"+no);
    }
  }

  generateValRender(val:string, element: any) : string {

    for (var i in element) {
        var test1 = "element['"+ i +"']";
        if(val.includes(test1)){
          var test2 : string = element[i];
          val = val.replace(test1, test2);
        }
    }
    return val;
  }

  onSort(col:string){
    if(this.enableSorting){
      this.is_sort_column = col;
      if(this.is_sort_direction == 'ASC'){
        this.is_sort_direction = 'DESC';
      }else{
        this.is_sort_direction = 'ASC';
      }
      this.loadData();
    }
  }


  onSortDir(col:string, dir:string){
    let tempCol:string = this.is_sort_column;
    let tempDir:string = this.is_sort_direction;
    let tempSearch:string = this.search;

    if(this.enableSorting){
      this.is_sort_column = col;
      if(dir != null && dir != ''){
        this.is_sort_direction=dir;
      }
      this.currentPage=1; //reset paging to 1
      this.curPage = 1;
      this.loadData();
    }

  }

  onHistory(search:string){
      this.search=search;
  }

  eveButtons(event){
    let strEvent:string = event;
    if(strEvent != ''){
        let arr:string[] = strEvent.split('-');
        if(arr[0] == "edit"){
            let data = this.listStore.findData(Number.parseInt(arr[1]));
            this.selectList = [];
            this.selectList.push(data);
        }
    }
    this.gridEvent.emit(event);
  }

  disable(){
    this.lock = true;
  }

  enable(){
    this.lock = false;
  }

  setModeRadio(status:boolean){
    this.modeRadio = status;
    if(status){
      $('.ui.radio.checkbox').checkbox();
    }
  }

  onSelectRadio(check:boolean, no: number){
    let data = this.listStore.findData(no);
    this.resetSelectRadio();

    this.selectList.push(data);
    this.gridEvent.emit('selectRadio-'+no);
  }

  onClear(){
    this.store = [];
    this.listStore.store = [];
  }

  editColumn(data,col){
    this.noEdit = data.no;
    this.columnCombo = col;
    this.rowCombo = data;

    if(this.editable == true){
      if(col.editType != null){
        document.getElementById(this.id + '.span.' + this.noEdit + '.' + col.field).style.display = 'none';
        document.getElementById(this.id + '.' + col.editType + '.' + this.noEdit + '.' + col.field).style.display = 'block';
        if(col.editType != 'combo'){
          document.getElementById(this.id + '.' + col.editType + '.' + this.noEdit + '.' + col.field).focus();
        }else{
          this.mymodelCombo = data[col.field];
          this.comboActive = this.id + '.' + 'cb-' + this.noEdit + '.' + col.field;
          this.dataSelect = null;
          document.getElementById(this.comboActive).focus();
          $('#resultss-' + this.id).transition('hide');
        }
      }
    }
  }

  onBlurColumn(){
    var i:string = this.id;
    var self = this;
    $('#'+ i).find('table').find('tbody').find('td').each(function(index, value){
      if($(this).attr('id') ==  i + ".td." + self.noEdit + "." + self.columnCombo.field){
        var spanElement =  $(this).find('span');

        if(self.columnCombo.editType == 'text'){
          var inputElement = $(this).find('input');
          inputElement.each(function(index, value){
            if($(this).attr('id') == i + "." + self.columnCombo.editType + "." + self.noEdit + "." + self.columnCombo.field){
                spanElement.show();
                spanElement.text(inputElement.val());
                inputElement.hide();

                self.rowCombo[self.columnCombo.field] = inputElement.val();
                self.store[self.noEdit-1] = self.rowCombo;
            }
          });
        }

        if(self.columnCombo.editType == 'textarea'){
          var textareaElement = $(this).find('textarea');
          textareaElement.each(function(index, value){
            if($(this).attr('id') == i + "." + self.columnCombo.editType + "." + self.noEdit + "." + self.columnCombo.field){
                spanElement.show();
                spanElement.text(textareaElement.val());
                textareaElement.hide();

                self.rowCombo[self.columnCombo.field] = textareaElement.val();
                self.store[self.noEdit-1] = self.rowCombo;
            }
          });
        }

        if(self.columnCombo.editType == 'combo'){
          var comboDivElement = $(this).find('div');
          comboDivElement.each(function(index, value){
                // console.log('id 1 = ' + $(this).attr('id'));
                if($(this).attr('id') == i + "." + self.columnCombo.editType + "." + self.noEdit + "." + self.columnCombo.field){

                  var valueCombo:string;
                  var comboElement = $(this).find('input');
                  comboElement.each(function(index, value){
                    // console.log('id 2 = ' + $(this).attr('id'));
                    if($(this).attr('id') == i + ".cb-" + self.noEdit + "." + self.columnCombo.field){
                      valueCombo = comboElement.val();
                    }
                  });

                  spanElement.show();
                  spanElement.text(valueCombo);
                  comboDivElement.hide();

                  if(self.columnCombo.titleCombo != null){
                      self.rowCombo[self.columnCombo.titleCombo] = valueCombo;
                  }else{
                      self.rowCombo[self.columnCombo.field] = valueCombo;
                  }
                  self.store[self.noEdit-1] = self.rowCombo;

                }

          })
        }

      }

    });

  }

  /* ------------- For Combo ----------------- */
  onChangeDataCombo(val:string){
      this.mymodelCombo = val;
      $('#resultss-' + this.id).transition('hide');
  }

  onSelectCombo(no:number){
     this.dataSelect = this.listStoreCombo.findData(no);
     if(this.columnCombo.titleCombo != null){
        this.mymodelCombo = this.dataSelect[this.columnCombo.titleCombo];
     }else{
       this.mymodelCombo = this.dataSelect[this.columnCombo.field];
     }

     this.setDataCombo(this.mymodelCombo);
     this.onBlurColumn();
     $('#resultss-' + this.id).transition();
     $('#resultss-' + this.id).transition('stop all');

  }

  getDataCombo():string{
    var i:string = this.id;
    let result:string;
    var self = this;
    $('#'+ i).find('table').find('tbody').find('td').each(function(index, value){
        if($(this).attr('id') ==  i +".td."+ self.noEdit +"."+ self.columnCombo.field){
          if(self.columnCombo.editType == 'combo'){
              var comboDivElement = $(this).find('div');
              comboDivElement.each(function(index, value){
                if($(this).attr('id') == i + "." + self.columnCombo.editType + "." + self.noEdit + "." + self.columnCombo.field){
                  var comboElement = $(this).find('input');
                  comboElement.each(function(index, value){
                    if($(this).attr('id') == i + ".cb-" + self.noEdit + "." + self.columnCombo.field){
                      result = comboElement.val();
                    }
                  })
                }
              })
          }
        }
    });
    return result;
  }

  setDataCombo(text:string){
    var i:string = this.id;
    var self = this;

    $('#'+ i).find('table').find('tbody').find('td').each(function(index, value){
        if($(this).attr('id') ==  i +".td."+ self.noEdit +"."+ self.columnCombo.field){
          if(self.columnCombo.editType == 'combo'){
              var comboDivElement = $(this).find('div');
              comboDivElement.each(function(index, value){
                if($(this).attr('id') == i + "." + self.columnCombo.editType + "." + self.noEdit + "." + self.columnCombo.field){
                  var comboElement = $(this).find('input');
                  comboElement.each(function(index, value){
                    if($(this).attr('id') == i + ".cb-" + self.noEdit + "." + self.columnCombo.field){
                      comboElement.val(text);
                    }
                  })
                }
              })
          }
        }
    });
  }

  setHiddenCombo(isHidden:boolean){
    var i:string = this.id;
    let result:string;

    var self = this;
    $('#'+ i).find('table').find('tbody').find('td').each(function(index, value){
        if($(this).attr('id') ==  i +".td."+ self.noEdit +"."+ self.columnCombo.field){
          if(self.columnCombo.editType == 'combo'){
            var comboDivElement = $(this).find('div');
            if(isHidden == false){
              comboDivElement.show();
            }else{
              comboDivElement.hide();
            }
          }
        }
    });
  }

  onSearchEnterCombo(event){

    if(event.keyCode == 13){
      event.preventDefault();
      this.modeEnterCombo = true;

      var rect = document.getElementById(this.id + ".cb-" + this.noEdit + "." + this.columnCombo.field).getBoundingClientRect();
      var rectTop = rect.top + rect.height;
      $("#" + 'resultss-' + this.id).css('top', rectTop);
      $("#" + 'resultss-' + this.id).css('left', rect.left);

      let url:string = this.columnCombo.urlCombo;
      var value:string = this.getDataCombo();

      if(value.length < 2){
          $('#resultss-' + this.id).transition('hide');
      }

      if(value.length >= 2){
          this.listStoreCombo.store = [];
          this.columnsCombo = [];
          this.columnsCombo = this.columnCombo.columnsCombo;
          this.loadingCombo = true;

          let uri:string = (value.length == 0)? '*' : value;
          let result:string = this.genericUtil.decode(uri);
          this.genericService.GET(url.replace("{query}",result)).subscribe((resp) => {

                if(resp.ok){

                  this.listStoreCombo.store = resp.json()['content'];

                  let no:number=1;
                  this.listStoreCombo.store.forEach(element => {
                      element['no'] = no++;

                      this.columnsCombo.forEach(col => {
                        // console.log('titleCombo = ' + this.columnCombo.titleCombo);
                        if(this.columnCombo.titleCombo != null){
                          element['title'] = element[this.columnCombo.titleCombo];
                        }else{
                          element['title'] = element[col['field']];
                        }

                        if(col['type'] != null && col['type']=='number'){
                          var dec:number = 5;
                          if(col['decimal'] != null){
                            dec = col['decimal'];
                          }
                          var n:number = element[col['field']];
                          element[col['field']] = n.toFixed(dec);

                        }else if(col['type'] != null && col['type']!='undefined' && col['type']!='number'){
                          element[col['field']] = element[col['field']].replace(/ /g,'&nbsp;');
                        }
                    });

                  });

                  this.columnsCombo.forEach(col => {
                      if(col['render'] != null){
                          this.listStoreCombo.store.forEach(element => {
                          var valRender : string = col['render']['value'];
                          var newValRender = this.generateValRender(valRender,element);
                          element[col['field']] = newValRender;
                        });
                      }
                  });

                  $('#resultss-' + this.id).transition('show');
                  this.loadingCombo = false;

                }

          },error => {
              this.loadingCombo = false;
              // console.log(error.message);
          });



      }

    }
  }


  onBlurEnterCombo(event){
    var value:string = this.getDataCombo();

    if(value != null){
        if(value != ''){
            if(value.length >= 2){
               if(this.modeEnterCombo){
                  this.modeEnterCombo = false;
                  if(this.dataSelect == null){
                    $('#resultss-' + this.id).transition();
                    this.setHiddenCombo(true);
                  }
                }else{
                  this.onBlurColumn();
                  this.setHiddenCombo(true);
                }
            }
        }else{
           this.setHiddenCombo(true);
           $('#resultss-' + this.id).transition('stop');
        }
    }else{
      this.setHiddenCombo(true);
      $('#resultss-' + this.id).transition('stop');
    }

  }


  /* ----------------------------------------*/

}
