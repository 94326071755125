<div class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-blOceanVesselCodeFrom'] == null || model['error-blOceanVesselCodeFrom'] == '' || model['error-blOceanVesselCodeFrom'] == undefined ">
          {{model['error-blOceanVesselCodeFrom']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyageFrom'] == null || model['error-blOceanVesselVoyageFrom'] == '' || model['error-blOceanVesselVoyageFrom'] == undefined ">
          {{model['error-blOceanVesselVoyageFrom']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBoundFrom'] == null || model['error-blOceanVesselBoundFrom'] == '' || model['error-blOceanVesselBoundFrom'] == undefined ">
          {{model['error-blOceanVesselBoundFrom']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselCodeTo'] == null || model['error-blOceanVesselCodeTo'] == '' || model['error-blOceanVesselCodeTo'] == undefined ">
          {{model['error-blOceanVesselCodeTo']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyageTo'] == null || model['error-blOceanVesselVoyageTo'] == '' || model['error-blOceanVesselVoyageTo'] == undefined ">
          {{model['error-blOceanVesselVoyageTo']}}
        </li>
        <!--
        <li [class.hidden]="model['error-blOceanVesselBoundTo'] == null || model['error-blOceanVesselBoundTo'] == '' || model['error-blOceanVesselBoundTo'] == undefined ">
          {{model['error-blOceanVesselBoundTo']}}
        </li>
        -->
        <li [class.hidden]="model['error-blNoValidator'] == null || model['error-blNoValidator'] == ''">
          {{model['error-blNoValidator']}}
      </li>
      </ul>
    </div>

    <form class="ui form">
        <button class="ui button" style="display:none;"></button>

        <div class="field">
            <fieldset style="border: 1px solid lightgray;width: 100%">
              <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria</legend>

                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdTransferBy" [checked]="radioTransferByVesselWise == 'Y'" (change)="changeEventTransmit($event)" value="VESSEL">
                    <label>Vessel Wise</label>
                  </div>

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="ui radio checkbox" >
                    <input type="radio"  name="rdTransferBy" [checked]="radioTransferByBlWise == 'Y'" (change)="changeEventTransmit($event)" value="BL">
                    <label>B/L Wise</label>
                </div>
            </fieldset>

            <div style="display: flex; gap: 10px;">
              <fieldset style="border: 1px solid lightgray; width: 50%">
                <legend style="font-weight:bold;color:dodgerblue;">Transmission Type</legend>
                <div style="display: flex; align-items: center; gap: 20px;">
                  <div class="ui radio checkbox" >
                    <input type="radio"  name="rdTransmissionTy" [checked]="radioTransTypeActual == 'Y'" (change)="changeEventTransmissionType($event)" value="ACTUAL">
                    <label>Actual</label>
                  </div>

                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="ui radio checkbox" >
                    <input type="radio"  name="rdTransmissionTy" [checked]="radioTransTypeProforma == 'Y'" (change)="changeEventTransmissionType($event)" value="PROFORMA">
                    <label>Proforma</label>
                </div>
                </div>
              </fieldset>
            
              <fieldset style="border: 1px solid lightgray; width: 50%">
                <legend style="font-weight:bold;color:dodgerblue;">Download Option</legend>
                <div style="display: flex; align-items: center; gap: 20px;">            
                  <div class="ui checkbox">
                    <input type="checkbox" id="ckDownload" name="ckDownload" value="save" 
                    class="ckDownload" checked="isDownloadable==true" [(ngModel)]="isDownloadable">
                    <label>Download as file</label>
                  </div>
                </div>
              </fieldset>
            </div>            

          </div>


      <div class="field">
        <div class="two fields">

          <div class="field">

            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%"  id="myfieldset">

              <legend style="font-weight:bold;color:dodgerblue;"></legend>

              <div class="four wide fields">

                <div class="required two field" style="width:100%" [class.error]="model['error-blOceanVesselCodeFrom'] != null && model['error-blOceanVesselCodeFrom'] != ''">
                  <label>Vessel</label>
                  <combo-plugin #cbVesselFrom [settings]="settingVesselFrom" (change)="changeEventVesselFrom($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-blOceanVesselCodeFrom'] != null && model['error-blOceanVesselCodeFrom'] != ''">
                    {{model['error-blOceanVesselCodeFrom']}}
                  </div>

                </div>

                <div class="required one field" style="width:70%" [class.error]="model['error-blOceanVesselVoyageFrom'] != null && model['error-blOceanVesselVoyageFrom'] != ''">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyageFrom [settings]="settingVoyageFrom" (change)="changeEventVoyageFrom($event)" style="width:100%;"></combo-plugin>
                  <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-blOceanVesselVoyageFrom'] != null && model['error-blOceanVesselVoyageFrom'] != ''">
                    {{model['error-blOceanVesselVoyageFrom']}}
                  </div>
                </div>

                <div class="required one field" style="width:45%" [class.error]="model['error-blOceanVesselBoundFrom'] != null && model['error-blOceanVesselBoundFrom'] != ''">
                  <label>Bound</label>
                  <input type="text" name="boundFrom" placeholder="Bound" [(ngModel)]="model['blOceanVesselBoundFrom']" >
                </div>
              </div>

            </fieldset>

          </div>

          <div class="field">
            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%"  id="myfieldset2">

              <legend style="font-weight:bold;color:dodgerblue;"></legend>

              <div class="four wide fields">

                <div class="two field" style="width:100%"  [class.error]="model['error-cbPOD'] != null && model['error-cbPOD'] != ''">
                  <label>POD</label>
                  <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
                </div>

                <div class="one field" style="width:100%" [class.error]="model['error-cbPOT'] != null && model['error-cbPOT'] != ''">
                  <label>POT</label>
                  <combo-plugin #cbPOT [settings]="settingPOT" (change)="changeEventPOT($event)" style="width:100%;"></combo-plugin>
                </div>



              </div>



            </fieldset>

          </div>





        </div>
      </div>

      <div class="field">
        <div class="two fields">


          <div class="field">
            <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%" id="myfieldset3">

              <legend style="font-weight:bold;color:dodgerblue;">Container Ownnership</legend>


                  <div class="ui checkbox" >
                    <input type="checkbox" id="ckSOC" name="ckSOC" value="SOC" class="cntType" checked>
                    <label>SOC</label>
                  </div>


                  &nbsp;&nbsp;&nbsp;&nbsp;
                <div class="ui checkbox" >
                    <input type="checkbox"  id="ckCOC" name="ckCOC" value="COC" class="cntType" checked>
                    <label>COC</label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div class="ui checkbox" >
                    <input type="checkbox"  id="ckSLOT" name="ckSLOT" value="SLOT" class="cntType" checked>
                    <label>SLOT</label>
                </div>

                <div class="field">&nbsp;</div>
                <div class="field">&nbsp;</div>
                <div class="field">&nbsp;</div>

                <div class="field">

                    <div class="ui  checkbox" >
                        <input type="checkbox"  id="ckSOCTS" name="ckSOCTS" value="SOCTS" class="cntType" checked>
                        <label>SOC T/S</label>
                    </div>
                    &nbsp;&nbsp;
                    <div class="ui  checkbox" >
                        <input type="checkbox"  id="ckCOCTS" name="ckCOCTS" value="COCTS" class="cntType" checked>
                        <label>COC T/S</label>
                    </div>

                </div>



            </fieldset>
          </div>

          <div class="field">
              <fieldset style="border: 1px solid lightgray; height: 100%; width: 100%"  id="myfieldset4">

                <legend style="font-weight:bold;color:dodgerblue;">B/L Status</legend>


                    <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blFinished == true " id="blFinished" name="blFinished" tabindex="0" (change)="eventBLFinished($event)" checked  value="Y">
                      <label>B/L Finished</label>
                    </div>
                  <div class="field">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blFinishedN == true " id="blFinishedN" name="blFinishedN" tabindex="0" (change)="eventBLFinishedN($event)" class="hidden" checked value="N" class="clFinished">
                      <label>N</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blFinishedU == true " id="blFinishedU" name="blFinishedU" tabindex="0" (change)="eventBLFinishedU($event)" blFinishedN class="hidden" checked value="U"  class="clFinished">
                      <label>U</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blFinishedD == true " id="blFinishedD" name="blFinishedD" tabindex="0" (change)="eventBLFinishedD($event)" class="hidden" checked value="D"  class="clFinished">
                      <label>D</label>
                  </div>
                </div>

                  <div class="field">&nbsp;</div>


                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blUnfinished == true" id="blUnfinished" name="blUnfinished" tabindex="0" (change)="eventBLUnfinished($event)"  class="hidden" value="N">
                      <label>B/L Unfinished</label>
                    </div>

                  <div class="field">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blUnfinishedN == true" id="blUnfinishedN" name="blUnfinishedN" tabindex="0" (change)="eventBLUnfinishedN($event)"  class="hidden" value="N" class="clUnfinished">
                      <label>N</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blUnfinishedU == true" id="blUnfinishedU" name="blUnfinishedU" tabindex="0" (change)="eventBLUnfinishedU($event)"  class="hidden" value="U" class="clUnfinished">
                      <label>U</label>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div class="ui checkbox" >
                      <input type="checkbox" [checked]="blUnfinishedD == true" id="blUnfinishedD" name="blUnfinishedD" tabindex="0" (change)="eventBLUnfinishedD($event)"  class="hidden" value="D" class="clUnfinished">
                      <label>D</label>
                  </div>
                </div>



              </fieldset>
            </div>


        </div>
      </div>


      <div class="field">
        <fieldset style="border: 1px solid lightgray;">
          <!--
          <div class="field" [style.display]="radioTransferByVesselWise =='Y'?'block':'none'">
           <grid-plugin #gridBLTransferByVessel [settings]="settingGridBLTransferByVessel" (gridEvent)="gridEventByVessel($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>

          [style.display]="radioTransferByBlWise =='N'?'none':'block'
        -->

          <div class="field">
            <button class="tiny ui positive button" data-content="addGrid"  (click) = "rowEvent('add');" id="addGrid">+</button>
            <button class="tiny ui negative button"  data-content="remGrid" (click) = "rowEvent('del');" id="remGrid">-</button>
            <br/>
            <grid-plugin #gridBLTransferByBL [settings]="settingGridBLTransferByBL" (gridEvent)="gridEventByBL($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
          </div>
        </fieldset>
      </div>

    </form>

  </div>  <!-- end div container -->
</div>


<!-- START, For Add Form -->
<div id="AddForm" style="width:25%;padding:0.5%;display:none;">
  <div class="ui error message" [class.hidden]="isErrorAdd == false">
      <ul>
          <li [class.hidden]="modelAdd['error-blNo'] == null || modelAdd['error-blNo'] == ''">
            {{modelAdd['error-blNo']}}
          </li>
      </ul>
  </div>

  <form  class="ui form" style="width:100%;" >
    <fieldset style=" border: 1px solid lightgray;margin-left:0.5px;">
      <div  class="field" style="margin-bottom:2%;">
        <label style="font-size: 11px;">BL No</label>


      </div>
      <div class="two fields">
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;" >OK</button>

        </div>
        <div class="field" style="width:20%;">
          <button class="ui button" style="font-size: 9px;"  (click)="this.closeDialog('AddForm');this.isErrorAdd = false;">Cancel</button>
        </div>
      </div>
    </fieldset>

  </form>
</div>
<!-- END, For Delete Form -->

<pdf-plugin #ediTransmissionList style="margin-left:3px;" [settings]="settingPDF" (change)="eventPDFPlugin($event);"></pdf-plugin>
<div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1"><div class="ui medium text loader">Loading</div></div>

<footer-plugin [info]="info"></footer-plugin>
