export class blTransfer{

  vesselIdOrigin:string='';
  voyageOrigin:string='';
  boundOrigin:string='';
  boundDes : string ='';
  bOceanVesselVoyageDes : string ='';
  vesselId : string ='';
  vesselCode : string ='';
  vesselName : string ='';
  bOceanVesselDes: string ='';
  bOceanVoyageDes: string ='';

  vesselIdDestination:string='';
  voyageDestination:string='';
  boundDestination:string='';
  vesselCodeDestination:string='';

  potCode:string='';
  podCode:string='';

  bWeight:number=0;
  bMeasure:number=0;
  bound : string ='';
  blNo:string='';


  bDetentionRequest : number=0;
  bDemurrageRequest:number=0;
  bWt:number=0;
  bOceanVesselVoyage : string ='';
  bOceanVesselBound: string ='';

  bOceanBound: string ='';
  radioTransferByVessel: string ='';



  constructor(){

  }
}
