<div>
  <div id="{{id}}" style="display:none;">
    <!-- div class="ui raised segment" style="height:auto;margin-bottom:0px;margin-right:0px;margin-top:0px;" -->
      <table border="0" style="width:100%;">
        <tbody>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td style="vertical-align: top;width:12%">
              <div [ngSwitch]="prompterType">
                <div *ngSwitchCase="'information'"><i class="info circle big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchCase="'warning'"><i class="warning sign big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchCase="'announcement'"><i class="announcement big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchCase="'error'"><i class="minus circle big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchCase="'delete'"><i class="remove circle big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchCase="'save'"><i class="database big icon"></i>&nbsp;&nbsp;</div>
                <div *ngSwitchDefault>{{prompterHeader}}&nbsp;&nbsp;</div>
              </div>
            </td>
            <td>
              <span [innerHTML]="prompterContent"></span>
            </td>
          </tr>
          <tr>
            <td colspan="2">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align:center;">
              <div *ngFor="let btn of prompterButtons" class="ui mini buttons" style="position:inherit;padding-left:0;padding-right:0;">
                <div class="ui mini button" style="margin-left:0px;margin-right:5px;" (click)="clickEvent(btn.event)" >                  
                    <span [innerHTML]="btn.name"></span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    <!-- /div -->
  </div>
</div>