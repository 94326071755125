import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Charge } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-master-charge-page",
  templateUrl: "./master-charge-page.component.html",
  styleUrls: ["./master-charge-page.component.css"],
})
export class MasterChargePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("chargeGrid") chargeGrid: GridPluginComponent;
  @ViewChild("chargeGridHistory") chargeGridHistory: GridPluginComponent;
  @ViewChild("cbChargeNameHistory") cbChargeNameHistory: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  /* Form Validation */
  formValidation = new FormValidation();

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingChargeLogStartDate;
  //settingChargeLogEndDate;
  settingChargeHistory;

  /* Misc Parameters */
  valChargeCode: string = "";
  valChargeName: string = "";
  valChargeId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  /* Parameter model */
  model = new Charge();
  //selectList = [];

  loading = false;

  lock = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  regChargeName = /^[a-zA-Z0-9-(.)&' ]*$/;

  validatorRules = {
    chargeName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Charge Name.",
        },
        {
          type: "modifiedPattern",
          pattern: this.regChargeName,
          prompt: "Please input valid characters",
        },
      ],
    },
    chargeCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Charge Code.",
        },
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAlphaNumeric,
          prompt: "Please input valid characters",
        },
      ],
    },
    remarks: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regAllChar,
          prompt: "Please input valid characters",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Charge Code", value: "chargeCode" },
        { display: "Charge Name", value: "chargeName" },
        { display: "Remarks", value: "remarks" },
        { display: "Main Charge", value: "isMainCharge" },
        { display: "Surcharge", value: "isSurcharge" },
        { display: "Ad Hoc Charge", value: "isAdhoc" },
        { display: "Manifest", value: "isManifested" },
        { display: "Show in Report", value: "isShownInReport" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Charge Name",
          field: "chargeName",
          width: 200,
          type: "string",
        },
        { header: "Charge Code", field: "chargeCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "Main Charge", field: "isMainCharge", width: 100 },
        { header: "Surcharge", field: "isSurcharge", width: 100 },
        { header: "Ad Hoc Charge", field: "isAdhoc", width: 100 },
        { header: "Manifest", field: "isManifested", width: 100 },
        { header: "Show in Report", field: "isShownInReport", width: 100 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "chargeName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Charge Id", field: "chargeId", width: 200 },
        {
          header: "Charge Name",
          field: "chargeName",
          width: 200,
          type: "string",
        },
        { header: "Charge Code", field: "chargeCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 300 },
        { header: "Main Charge", field: "isMainCharge", width: 100 },
        { header: "Surcharge", field: "isSurcharge", width: 100 },
        { header: "Ad Hoc Charge", field: "isAdhoc", width: 100 },
        { header: "Manifest", field: "isManifested", width: 100 },
        { header: "Show in Report", field: "isShownInReport", width: 100 },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true,
    };

    this.settingChargeHistory = {
      id: "cbChargeHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCharges/findGeneralHistory/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "chargeName",
      description: "",
      isMark: true,
      columns: [
        { header: "Charge Id", field: "chargeId", width: 50 },
        { header: "Charge Name", field: "chargeName", width: 50 },
        { header: "Charge Code", field: "chargeCode", width: 50 },
        { header: "Remarks", field: "remarks", width: 50 },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };
    /*
     this.settingChargeLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingChargeLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.chargeGrid.search='*/*/*';
    //this.chargeGrid.onFirst();
    this.firstInit();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.chargeGrid.search = "*/*/*";
    this.chargeGrid.onFirst();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  chargeGridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.chargeGrid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.chargeGrid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.chargeGrid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.chargeGrid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }
        }

        break;
    }
  }

  chargeGridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.chargeId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.chargeGrid.search = str.replace("search:", "");
          this.chargeGrid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Charge();
    this.valChargeCode = "";
    this.valChargeName = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.chargeGrid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event
    this.loading = true;
    let deleteList = [];
    this.chargeGrid.getSelectedValues().forEach((element) => {
      var deleteObj = {"chargeId":"","chargeCode":""};
      deleteObj.chargeId = element.chargeId;
      deleteObj.chargeCode = element.chargeCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListChargeUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {

            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }

          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );
    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    var formatName = "report-Charges-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/25/Charges-Export-Reports/*";
    var self = this;
    this.loading = true;

    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // window.open(
    //   "http://glossys.samudera.com:10000/Mreport/runReport/25/Charges-Export-Reports/*"
    // );
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation: boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */

      /*anoter update to ensure user select either of the option*/
      if (
        !this.model.isMainCharge &&
        !this.model.isSurcharge &&
        !this.model.isAdhoc &&
        !this.model.isShownInReport &&
        !this.model.isManifested
      ) {
        this.message(
          "warning",
          "Error Input",
          "Please select at least one (1) option.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else {
        // console.log("PSI Date => " + this.genericUtil.getPSIDate());
        this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          {
            yes: "this.saveEvent()",
            no: "this.loading = false;",
          }
        );
      }
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.model = new Charge();
    this.form = "";

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }

    this.chargeGrid.clearSelectedValues();

    if (this.valChargeId != "") {
      this.valChargeCode = "";
      this.valChargeId = "";
      this.cbChargeNameHistory.setValue("");
      this.cbChargeNameHistory.close();
      this.chargeGridHistory.onHistory("NaN");
      this.chargeGridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the chargeGrid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    if (this.chargeGrid.getSelectedValues().length >= 1) {
      this.chargeGrid.onSortDir("dateModified", "DESC");
    } else {
      this.chargeGrid.onSortDir("dateCreated", "DESC");
    }

    this.chargeGrid.clearSelectedValues();
    this.chargeGrid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    this.chargeGrid.clearSelectedValues();
    this.chargeGrid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.chargeGrid.getSelectedValues()[0];

    this.model = new Charge();

    // console.log(this.model);

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }
    this.model.chargeId = String(data.chargeId).trim().toUpperCase();
    this.model.chargeCode = String(data.chargeCode).trim().toUpperCase();
    this.model.chargeName = String(data.chargeName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");

    if (data.isManifested == "Y") {
      this.model.isManifested = true;
    } else {
      this.model.isManifested = false;
    }

    if (data.isAdhoc == "Y") {
      this.model.isAdhoc = true;
    } else {
      this.model.isAdhoc = false;
    }

    if (data.isShownInReport == "Y") {
      this.model.isShownInReport = true;
    } else {
      this.model.isShownInReport = false;
    }

    if (data.isSurcharge == "Y") {
      this.model.isSurcharge = true;
    } else {
      this.model.isSurcharge = false;
    }

    if (data.isMainCharge == "Y") {
      this.model.isMainCharge = true;
    } else {
      this.model.isMainCharge = false;
    }

    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
  }

  valueChargeHistory(event) {
    this.valChargeCode = event["chargeCode"];
    this.valChargeId = event["chargeId"];
    // console.log("curr code= " + event["chargeCode"]);

    //refresh the history chargeGrid accordingly
    this.chargeGridHistory.onHistory(this.valChargeId);
  }

  generateChargeHistoryReport(event) {
    //this.valHistoryStartDate= $('#logHistoryChargeStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryChargeEndDate').find('#datePicker').val();
    if (this.valChargeId == "" || this.valChargeId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
    var formatName = "report-History-Charge-"+this.valChargeId+"-"+this.valChargeCode+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/26/History-Charge-"
      +
      this.valChargeId +
      "-" +
      this.valChargeCode +
      "/" +
      this.valChargeId +
      "@" +
      this.valHistoryStartDate +
      "@" +
      this.valHistoryEndDate ;
    var self = this;
    this.loading = true;

    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    }
    // window.open(
    //   this.configService.config.getPDFUrl.toString().replace("12000", "10000").replace("5001","10000") +
    //     "/Mreport/runReport/26/History-Charge-" +
    //     this.valChargeId +
    //     "-" +
    //     this.valChargeCode +
    //     "/" +
    //     this.valChargeId +
    //     "@" +
    //     this.valHistoryStartDate +
    //     "@" +
    //     this.valHistoryEndDate
    // );
  }

  retrieveHistory(event) {
    this.chargeGridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterCharges/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new Charge();
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")

    this.loading = true;
    let deleteList = [];
    this.chargeGrid.getSelectedValues().forEach(element => {
      deleteList.push(element.chargeId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"chargeId":[]}
    paramsDelete.chargeId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        // "/MasterCharges/delete",
        // deleteList
        "/MasterCharges/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new Charge();
            //  this.selectList = [];
            this.loading = false;
            this.chargeGrid.clearSelectedValues();
            this.chargeGrid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar?: string
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar ? validSPChar : ''}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  markRedRow(dataNo){
    var i = this.chargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.chargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
