<div class="ui segment">
    <div class="ui divided items">
      <toolbar-plugin #toolbarSetting [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

      <div [style.display]="modeForm?'none':'block'">
        <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local DG Class Master</h4>
        <!-- Begin of Grid -->
        <grid-plugin #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        <!-- End of Grid -->
      </div>

      <div [style.display]="modeForm?'block':'none'">
        <div class="ui segment" style="padding-left:1%;padding-right:20%;">
          <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99">
            <div class="ui medium text loader">Loading</div>
          </div>

          <div id='container'>

            <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

            <!-- Begin of Form -->
            <form class="ui form" [style.display]="form == ''?'block':'none'" >
              <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">Local DG Class Master</h4>

              <div class="ui raised segment">
                <div class="one field" style="width:100%">
                    <div class="field">
                      <div class="ui test toggle checkbox" [class.disabled]="lock == true">
                        <input type="checkbox" [(ngModel)]="model['isValid']" name="isValid" tabindex="0" class="hidden">
                        <label>Valid?</label>
                      </div>
                    </div>
                    <div class="ten wide fields">
                        <div class="required field" style="width:50%;margin-top:10px;" [class.error]="model['error-psaGroup'] != null && model['error-psaGroup'] != ''">
                            <label>PSA Group</label>
                            <input type="text" [(ngModel)]="model['psaGroup']" maxlength="5" name="psaGroup"  placeholder="PSA Group" [disabled]="lock == true">
                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="model['error-psaGroup'] != null && model['error-psaGroup'] != ''">
                                {{model['error-psaGroup']}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ten wide fields">
                        <div class="required field" style="width:50%;" [class.error]="model['error-bkgClass'] != null && model['error-bkgClass'] != ''">
                            <label>BKG Class</label>
                            <input type="text" [(ngModel)]="model['bkgClass']" maxlength="5" name="bkgClass"  placeholder="BKG Class" [disabled]="lock == true">
                            <div class = "ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="model['error-bkgClass'] != null && model['error-bkgClass'] != ''">
                                {{model['error-bkgClass']}}
                            </div>
                        </div>
                    </div> -->

                    <div class="required field" style="width:50%" [class.error]="model['error-bkgClass'] != null && model['error-bkgClass'] != ''">
                        <label>BKG Class</label>
                        <input #bkgClass list="bkgClass" name="bkgClass"  placeholder="BKG Class" [(ngModel)]="model['bkgClass']"
                               (keypress)="noKey($event)"
                              maxlength="2"
                              required
                              autocomplete="off"
                              [disabled]="lock == true"
                              >
                              <datalist id="bkgClass">
                                  <option value="1">
                                  <option value="2">
                                  <option value="2F">
                                  <option value="3">
                              </datalist>
                        <div class = "ui basic red pointing prompt label transition error-message-hidden" [class.error-message-visible]="model['error-bkgClass'] != null && model['error-bkgClass'] != ''">
                          {{model['error-bkgClass']}}
                        </div>
                      </div>

                </div>
              </div>
              <div class="ui raised segment">
                <div class="two fields">
                  <div class="field">
                    <label>Created By</label>
                    <input type="text"  [(ngModel)]="model['userCreated']" name="userCreated"  placeholder="Created By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Created Date</label>
                    <input type="text"  [(ngModel)]="model['dateCreated']" name="dateCreated"  placeholder="Created Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Last Modified By</label>
                    <input type="text"  [(ngModel)]="model['userModified']" name="userModified"  placeholder="Last Modified By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Last Modified Date</label>
                    <input type="text"  [(ngModel)]="model['dateModified']" name="dateModified"  placeholder="Last Modified Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
                <div class="two fields">
                  <div class="field">
                    <label>Invalid By</label>
                    <input type="text"  [(ngModel)]="model['userInvalid']" name="userInvalid"  placeholder="Invalid By" disabled readonly style="background-color: lightgray">
                  </div>
                  <div class="field">
                    <label>Invalid Date</label>
                    <input type="text"  [(ngModel)]="model['dateInvalid']" name="dateInvalid"  placeholder="Invalid Date" disabled readonly style="background-color: lightgray">
                  </div>
                </div>
              </div>

            </form>
            <!-- End of Form -->
            <!-- Begin of History Form -->
          <form class="ui form" [style.display]="form == 'history'?'block':'none'" >
            <h4 class="ui dividing header" style="text-align:center;margin-top:10px;">History Master DG Class</h4>
            <div class="ui raised segment">
              <div class="one fields" style="margin-top:10px;">
                <div class="field">
                  <button class="ui button" (click)="retrieveHistory($event)">Retrieve</button>
                  <button class="ui button" (click)="generateDgClsHistoryReport($event)">Export</button>
                </div>
              </div>

              <div class="two fields">
                <div class="field">
                  <label>PSA Group</label>
                  <combo-plugin #cbDgClassHistory [settings]="settingDgClassHistory" (change)="changeEventDgClass($event)"></combo-plugin>
                </div>
              </div>

              <div class="field">
                <grid-plugin2 #gridHistory [settings]="settingGridHistory" (gridEvent)="gridEventHistory($event)" (infoGrid)="infoGridHistory($event)"></grid-plugin2>
              </div>

            </div>
          </form>
          <!-- End of History Form -->
          </div>
        </div>
      </div>

    </div>
  </div>
  <footer-plugin [info]="info"></footer-plugin>
