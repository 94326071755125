export class ediVesselScheduleHeader{
    functionIndicator:string = '';
    documentName:string = 'edi_vessel_schedule';
    type:string = 'HD';
    vesselName:string = '';
    voyageNumber:string ='';
    lineCode:string = 'SUD';
    customerServiceCode:string = '';
    consortiumCode:string = '';
    remarks:string = '';
    direction:string = '';
    filler:string='';

    details : any = [];

    constructor() { 
      
    }
}