import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { Depo } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  TextareaPluginComponent,
  ToolbarPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-local-master-depo-page",
  templateUrl: "./local-master-depo-page.component.html",
  styleUrls: ["./local-master-depo-page.component.css"],
})
export class MasterDepoPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbDepoNameHistory") cbDepoNameHistory: ComboPluginComponent;
  @ViewChild("txtDepoName") txtDepoName: TextareaPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;
  dialog2: any;
  oldCode = "";

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // Form Validation
  formValidation = new FormValidation();

  // setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  // settingDepoLogStartDate;
  // settingDepoLogEndDate;
  settingDepoHistory;
  settingDepoName;

  /* Misc Parameters */
  valDepoCode: string = "";
  valDepoName: string = "";
  valDepoId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  officeCode: string = "";

  typeDepoLogStartDate = "calendar";
  typeDepoLogEndDate = "calendar";

  /* Parameter model */
  model = new Depo();
  //selectList = [];

  loading = false;

  lock = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    depoName: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Depot Name.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9-(),.:' ]*$/,
          prompt: "Please input valid depot name",
        },
      ],
    },
    depoPic: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Depot PIC.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z@ ]*$/,
          prompt: "Please input valid depot pic",
        },
      ],
    },
    depoAddress: {
      rules: [
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9\s -.&@^*()_+';:/,\\]*$/,
          prompt: "Please input valid depot address",
        },
      ],
    },
    depoTelp: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Depo Phone No.",
        },
        {
          type: "modifiedPattern",
          pattern: this.formValidation.regPhone,
          prompt: "Please input valid depot telp",
        },
      ],
    },
    depoCode: {
      rules: [
        {
          type: "empty",
          prompt: "Please input Depot Code.",
        },
        {
          type: "modifiedPattern",
          pattern: /^[a-zA-Z0-9-(), ]*$/,
          prompt: "Please input valid depot code",
        },
      ],
    },
  };

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Depot Code", value: "depoCode" },
        { display: "Depot Name", value: "depoName" },
        { display: "Address", value: "depoAddress" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        {
          header: "Sub Terminal/Depot Name",
          field: "depoName",
          width: 200,
          type: "string",
        },
        {
          header: "Sub Terminal/Depot Code",
          field: "depoCode",
          width: 50,
          type: "string",
        },
        { header: "Address", field: "depoAddress", width: 300, type: "string" },
        { header: "Telephone No", field: "depoTelp", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "depoName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      id: "depoHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralHistoryById",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Depot Id", field: "depoId", width: 200 },
        {
          header: "Sub Terminal/Depot Name",
          field: "depoName",
          width: 200,
          type: "string",
        },
        {
          header: "Sub Terminal/Depot Code",
          field: "depoCode",
          width: 50,
          type: "string",
        },
        { header: "Address", field: "depoAddress", width: 300, type: "string" },
        { header: "User Created", field: "userCreated", width: 100 },
        { header: "Date Created", field: "dateCreated", width: 100 },
        { header: "User Modified", field: "userModified", width: 100 },
        { header: "Date Modified", field: "dateModified", width: 100 },
        { header: "User Invalid", field: "userInvalid", width: 100 },
        { header: "Date Invalid", field: "dateInvalid", width: 100 },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "action", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      
      enablePagination: true,
    };

    this.settingDepoHistory = {
      id: "cbDepoNameHistory",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "depoName",
      description: "",
      isMark: true,
      columns: [
        { header: "Depot Name", field: "depoName", width: 100, type: "string" },
        { header: "Depot Code", field: "depoCode", width: 200, type: "string" },
        //{header: 'Address', field: 'depoAddress', width: 300, type:'string'},
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };

    this.settingDepoName = {
      id: "txtDepoName",
      name: "depoName",
      placeholder: "Depo Name",
      cols: 30,
      rows: 10,
      maxlength: 255,
    };

    /*
     this.settingDepoLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingDepoLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    $(".test.checkbox").checkbox();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    //this.setGridEvent = 'search:*/*/* + refresh';
    this.grid.search = this.officeCode + "/*/*/*";
    this.grid.onFirst();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
              //this.txtDepoName.disabled = true;
              //this.txtDepoName.error = false;
            }
          }
        }

        break;
    }
  }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.depoId != "") {
          this.model.userModified = this.cookieService.getName();
          this.model.dateModified = this.genericUtil.getPSIDate();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;

      case "history":
        this.handleHistory();
        break;

      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = this.officeCode + "/" + str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    // this.txtDepoName.disabled = false;
    //this.txtDepoName.error = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Depo();
    //this.model.depoName = '12345678901234567890123456789012\r34567890123456789012345678901234\r567\r\r234 345234 234123 12312 121 123 \r1231';
    this.valDepoCode = "";
    this.valDepoName = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.model.updateMode = 1;
      // this.txtDepoName.disabled = false;
      // this.txtDepoName.error = false;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      var deleteObj = {"localMasterDepoId":"","localMasterDepoCode":""};
      deleteObj.localMasterDepoId = element.depoId;
      deleteObj.localMasterDepoCode = element.depoCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListLocalMasterDepoUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            
            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
            
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );
    // this.loading = true;
    // this.message(
    //   "delete",
    //   "Deletion Record",
    //   "Confirm to delete record(s)?",
    //   "yesno",
    //   { yes: "this.deleteEvent()", no: "this.loading = false;" }
    // );
  }

  handlePrint() {
    //window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/55/Depos-Export-Reports/*');
    //temporary
    // window.open(
    //   "http://glossys.samudera.com:10000/Mreport/runReport/55/Depos-Export-Reports/*"
    // );
    // handle print event
    var formatName = "report-Depos-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/55/Depos-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
  }

  /*
  insert(index:number, char:string, data:string) {
    var idx:number = index;
    if (idx > 0) {
      return data.substring(0, idx) + char + data.substring(idx, data.length);
    } else {
      return char + data;
    }
  };
  */

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    this.model.updateMode = 0;
    //this.model.depoName = this.txtDepoName.getValue();

    let isPassedValidation: boolean = this.onValidate(this.model);
    //// console.log(this.model['error-depoName']);
    /*
    if(this.model['error-depoName'] != '' ){
      this.txtDepoName.error = true;
    }else{
      this.txtDepoName.error = false;
    }
    */
    //// console.log('isPassedValidation ' + isPassedValidation);
    if (!isPassedValidation) {
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */

      // console.log('model nih');
      // console.log(this.model);

      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new Depo();
    //this.txtDepoName.error = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    // if(this.valDepoId != '') {
    this.valDepoCode = "";
    this.valDepoId = "";
    this.cbDepoNameHistory.setValue("");
    this.cbDepoNameHistory.close();

    // this.txtDepoName.setValue("");
    this.gridHistory.onHistory("NaN");
    this.gridHistory.loadData();
    // }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.cbDepoNameHistory.setForceValue("");
    this.cbDepoNameHistory.close();
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
    this.cbDepoNameHistory.setUrl(
      this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralHistory/" +
        this.officeCode +
        "/{query}"
    );
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    // console.log('datanya');
    // console.log(data)

    this.model = new Depo();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.model.oldValid = this.model.isValid;
    this.model.oldCode = String(data.depoCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");

    this.model.depoId = String(data.depoId).trim().toUpperCase();
    this.model.depoCode = String(data.depoCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.depoName = String(data.depoName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.depoAddress = String(
      String(data.depoAddress)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    ).replace(/\n/g, " ");
    this.model.depoPic = String(data.depoPic).trim().toUpperCase();
    this.model.depoTelp = String(data.depoTelp).trim().toUpperCase();
    // this.txtDepoName.setValue(this.model.depoName);
    // this.model.remarks = String(data.remarks).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
  }

  valueDepoHistory(event) {
    if (event["depoCode"] != null) {
      this.valDepoCode = event["depoCode"];
      this.valDepoId = event["depoId"];
      // console.log("curr code= " + event["depoCode"]);

      let tmp = String(event["depoCode"])
        .toUpperCase()
        .replace(/&NBSP;/g, " ");
      this.valDepoCode = tmp;

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valDepoId);
    }
  }

  generateDepoHistoryReport(event: any) {
    //this.valHistoryStartDate= $('#logHistoryDepoStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryDepoEndDate').find('#datePicker').val();

    if (this.valDepoId == "" || this.valDepoId == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      // window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/12/History-Depo-' + this.valDepoId  + '/'+this.valDepoId);
      var formatName = "report-Local-Depot-Export-Log-"+this.valDepoId+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/56/Local-Depot-Export-Log-"
        +
        this.valDepoId +
        "/" +
        this.valDepoId
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000")  + "/Mreport/runReport/56/Local-Depot-Export-Log-" +
      //     this.valDepoId +
      //     "/" +
      //     this.valDepoId
      // );
    }
  }

  retrieveHistory(event: any) {
    this.gridHistory.loadData();
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    this.model.officeCode = this.officeCode;

    if (this.model.isValid == false && this.model.depoId != "") {
      this.model.dateInvalid = this.genericUtil.getPSIDate();
      this.model.userInvalid = this.cookieService.getName();
    }

    var listModel = [];
    listModel.push(this.model);
    var datas = "";

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterDepos/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);

          //datas = resp.json()[0]['status'];

          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log("ENTER SAVE");
            this.model = new Depo();
            //this.txtDepoName.setValue("");
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.depoId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"depoId":[]}
    paramsDelete.depoId = deleteList;
   
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/LocalMasterDepos/delete",
        // deleteList
        "/LocalMasterDepos/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          // console.log('respirespi');
          // console.log(resp);
          if (resp.status == 200) {
            this.model = new Depo();
            //this.txtDepoName.setValue("");
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }
}
