export class SystemManagement {
    systemId   : string = '';
    systemTitle  : string = '';
    systemColorBanner  : string = '';
    systemFontType : string = '';
    systemTimeZone : string = '';
    systemDateFormat : string = '';
    systemTimeFormat : string = '';
    systemLanguage : string = '';
    systemVersion : string = '';
    systemIcon : string = '';

    constructor() {

    }
}