import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { TerminalClosingTime } from "../shared/index";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  ToolbarPluginComponent,
  TextareaPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
} from "sibego-ui-library";
import { Router } from "@angular/router";
//import { THIS_EXPR } from '../../../node_modules/@angular/compiler/src/output/output_ast';
//import * as timeMask from  '../../assets/js/time-mask.js';
import * as timeEntry from "../../assets/js/jquery.plugin.js";
import * as timeEntryLib from "../../assets/js/jquery.timeentry.js";
import * as nDPicker from "../../assets/js/datepicker.js";
import FormValidation from "../../utils/formValidation";

declare var $: any;

@Component({
  selector: "app-local-master-terminal-closing-time-page",
  templateUrl: "./local-master-terminal-closing-time-page.component.html",
  styleUrls: ["./local-master-terminal-closing-time-page.component.css"],
})
export class LocalMasterTerminalClosingTimePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("cbTerminalClosingTimeNameHistory")
  cbTerminalClosingTimeNameHistory: ComboPluginComponent;
  @ViewChild("txtTerminalClosingTimeName")
  txtTerminalClosingTimeName: TextareaPluginComponent;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOL") cbPOL: ComboPluginComponent;
  @ViewChild("cbPORTerminalCode") cbPORTerminalCode: ComboPluginComponent;
  @ViewChild("cbPOLTerminalCode") cbPOLTerminalCode: ComboPluginComponent;
  @ViewChild("cbStuff") cbStuff: ComboPluginComponent;
  @ViewChild("cbStuffTerminalCode") cbStuffTerminalCode: ComboPluginComponent;
  @ViewChild("cbVesselNameHistory") cbVesselNameHistory: ComboPluginComponent;
  @ViewChild("cbVoyageNameHistory") cbVoyageNameHistory: ComboPluginComponent;
  @ViewChild("cbReceiptType") cbReceiptType: ComboPluginComponent;
  @ViewChild("cbPorStuffTerminalCode")
  cbPorStuffTerminalCode: ComboPluginComponent;
  @ViewChild("cbPolStuffTerminalCode")
  cbPolStuffTerminalCode: ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = "";

  /* Parameter listStore for Grid */
  listStore = new ListStore();

  // setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  settingVessel;
  settingVoyage;
  settingPOL;
  settingPOLTerminalCode;
  settingPOR;
  settingPORTerminalCode;
  settingStuff;
  settingStuffTerminalCode;
  podTerminalCode;
  polTerminalCode;
  settinggcdPOR;
  settingvcdPOR;
  settinggcdPOL;
  settingvcdPOL;
  settinggcdStuff;
  settingvcdStuff;
  settingVesselHistory;
  settingVoyageHistory;
  settingReceiptType;
  settingPorStuffClosingDate;
  settingPolStuffClosingDate;
  settingPORStuffTerminalCode;
  settingPOLStuffTerminalCode;
  settingPORStuffVGM;
  settingPOLStuffVGM;
  settingGeneralCal;
  // settingWeeks;

  /* Misc Parameters */
  valTerminalClosingTimeCode: string = "";
  valTerminalClosingTimeName: string = "";
  valTerminalClosingTimeId: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";
  localEtd: string = "";
  officeCode: string = "";

  /*
  typegcdPOR = 'calendar';
  typevcdPOR = 'calendar';
  typegcdPOL = 'calendar';
  typevcdPOL = 'calendar';
  typegcdStuff = 'calendar';
  typevcdStuff = 'calendar';
  typeStuffPOR = 'calendar';
  typeStuffPOL = 'calendar';
  typeStuffVGMR = 'calendar';
  typeStuffVGML = 'calendar';
  */
  typeGeneral = "calendar";
  /* Parameter model */
  model = new TerminalClosingTime();

  loading = false;

  lock = false;
  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  lockAfterEdit = false;
  terminalPORLock = false;
  terminalPOLLock = false;

  /* validatorRules */
  validatorRules = {};

  // * Form Validation
  formValidation = new FormValidation();

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private router: Router
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Create", event: "create", icon: "plus" },
        { name: "Update", event: "update", icon: "write" },
        { name: "Delete", event: "delete", icon: "minus circle" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "History", event: "history", icon: "history" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: true,
      createDefaultDetail: true,
      searchBy: [
        { display: "Vessel Name", value: "vesselId" },
        { display: "voyage", value: "voyage" },
        { display: "POR Terminal Name", value: "porTerminalCode" },
        { display: "POL Terminal Name", value: "polTerminalCode" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };

    this.settingGrid = {
      id: "grid",
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterTerminalClosingTime/findGeneral",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Vessel", field: "vesselName", width: 170, type: "string" },
        { header: "Voyage", field: "voyage", width: 75, type: "string" },
        { header: "Bound", field: "bound", width: 75, type: "string" },
        {
          header: "Place of Receipt",
          field: "porName",
          width: 100,
          type: "string",
        },
        {
          header: "POR Sub Terminal",
          field: "porTerminalName",
          width: 100,
          type: "string",
        },
        {
          header: "POR Gate-in Closing Date",
          field: "porTerminalClosingDate1",
          width: 100,
          type: "string",
        },
        {
          header: "POR VGM Closing Date",
          field: "porVgmClosingDate1",
          width: 100,
          type: "string",
        },
        {
          header: "Port of Load",
          field: "polName",
          width: 100,
          type: "string",
        },
        {
          header: "POL Sub Terminal",
          field: "polTerminalName",
          width: 100,
          type: "string",
        },
        {
          header: "POL Gate-in Closing Date",
          field: "polTerminalClosingDate1",
          width: 100,
          type: "string",
        },
        {
          header: "POL VGM Closing Date",
          field: "polVgmClosingDate1",
          width: 100,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],

      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "vesselName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      id: "gridHistory",
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterTerminalClosingTime/findGeneralHistory",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 15 },
        { header: "Vessel", field: "vesselName", width: 170, type: "string" },
        { header: "Voyage", field: "voyage", width: 75, type: "string" },
        { header: "Bound", field: "bound", width: 75, type: "string" },
        {
          header: "Place of Receipt",
          field: "porName",
          width: 100,
          type: "string",
        },
        {
          header: "POR Sub Terminal",
          field: "porTerminalCode",
          width: 100,
          type: "string",
        },
        {
          header: "POR Gate-in Closing Date",
          field: "porTerminalClosingDate",
          width: 100,
          type: "string",
        },
        {
          header: "POR VGM Closing Date",
          field: "porVgmClosingDate",
          width: 100,
          type: "string",
        },
        {
          header: "Port of Load",
          field: "polName",
          width: 100,
          type: "string",
        },
        {
          header: "POL Sub Terminal",
          field: "polTerminalCode",
          width: 100,
          type: "string",
        },
        {
          header: "POL Gate-in Closing Date",
          field: "polTerminalClosingDate",
          width: 100,
          type: "string",
        },
        {
          header: "POL VGM Closing Date",
          field: "polVgmClosingDate",
          width: 100,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 50 },
        { header: "Action Log", field: "actionLog", width: 50 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],

      enableSorting: false,
      
      enablePagination: true,
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselHistory = {
      id: "cbVesselNameHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findGeneral/*/vesselName/{query}/1/100/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminal/" +
        this.cookieService
          .getDefaultLocationCode()
          .toString()
          .trim()
          .substring(0, 2) +
        "/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Place of Receipt",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOL = {
      id: "cbPOL",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroupTerminal/" +
        this.cookieService
          .getDefaultLocationCode()
          .toString()
          .trim()
          .substring(0, 2) +
        "/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Port of Load",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPORTerminalCode = {
      id: "cbPORTerminalCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOLTerminalCode = {
      id: "cbPOLTerminalCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingReceiptType = {
      id: "cbReceiptType",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCodeOrName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "R/Type",
      title: "receiptTypeCode", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Receipt Type Name", field: "receiptTypeName", width: 300 },
        { header: "Receipt Type Code", field: "receiptTypeCode", width: 150 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingStuff = {
      id: "cbStuff",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPOLStuffTerminalCode = {
      id: "cbPolStuffTerminalCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingPORStuffTerminalCode = {
      id: "cbPorStuffTerminalCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingStuffTerminalCode = {
      id: "cbStuffTerminalCode",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/LocalMasterDepos/findGeneralDepo/" +
        this.cookieService.getDefaultLocationCode() +
        "/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Depot --",
      title: "depoName",
      description: "",
      columns: [
        { header: "Depo Code", field: "depoCode", width: 150 },
        { header: "Depo Name", field: "depoName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage Code", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service Code", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageHistory = {
      id: "cbVoyageHistory",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage Code", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service Code", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGeneralCal = {
      modeCal: true,
      type: "date",
      returnText: "year-month-day",
      returnTextTime: "",
      separator: "-",
      AltTimeId: "", // default : ''
      hour12: false,
      showResetButton: false,
    };

    // this.settingWeeks = {
    //   id: "cbWeeks",
    //   type: "select", // search | select | select input
    //   url: "assets/json/weeks.json",
    //   placeholder: "Weeks",
    //   code: "weekCode",
    //   title: "weekName",
    // };

    /*
    this.settinggcdPOR = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingvcdPOR = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }


    this.settinggcdPOL = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingvcdPOL = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settinggcdStuff = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingvcdStuff = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingPorStuffClosingDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingPORStuffVGM = {

      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false

    }

    this.settingPOLStuffVGM = {

      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false

    }

    this.settingPolStuffClosingDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }
    */
    this.model.bound = "O";
    this.model.boundHistory = "O";
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  ngAfterViewInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    $("#polStuffClosingTime").timeEntry({ show24Hours: true });
    $("#porStuffClosingTime").timeEntry({ show24Hours: true });
    $("#polTerminalClosingTime").timeEntry({ show24Hours: true });
    $("#porTerminalClosingTime").timeEntry({ show24Hours: true });
    $("#porVgmClosingTime").timeEntry({ show24Hours: true });
    $("#polVgmClosingTime").timeEntry({ show24Hours: true });
    $("#porstuffVgmClosingTime").timeEntry({ show24Hours: true });
    $("#polstuffVgmClosingTime").timeEntry({ show24Hours: true });

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.officeCode = this.cookieService
      .getDefaultLocationCode()
      .toString()
      .trim();

    //this.setGridEvent = 'search:*/*/* + refresh';
    this.grid.search = this.officeCode + "/*/*/*";
    this.grid.onFirst();

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: "yyyy-mm-dd",
      zIndex: 2048,
    });

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  infoGridHistory(event) {
    this.info = event;
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case "click":
        if (this.grid.getSelectedValues().length > 0) {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        } else {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      break;
      case "afterLoad": 
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          if (arr[0] == "edit") {
            this.handleUpdate();
          } else if (arr[0] == "dblClick") {
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
              this.lockAfterEdit = true;
            }
          }
        }

        break;
    }
  }

  // changeYear(event) {
  //   console.log("ON CHANGE YEAR: " + event.target.value);
  //   this.model.year = event.target.value;
  // }

  // changeWeeks(event) {
  //   console.log("ON CHANGE YEAR: " + event.weekCode);
  //   this.model.weekName = event.weekCode;
  // }

  gridEventHistory(event) {
    //do nothing
  }

  toolbarEvent(event) {
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.terminalClosingTimeId != "") {
          this.model.userModified = this.cookieService.getName();
          this.model.dateModified = this.genericUtil.getPSIDate();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = this.officeCode + "/" + str.replace("search:", "");
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate() {
    // handle create event
    this.lock = false;
    this.lockAfterEdit = false;
    // this.txtTerminalClosingTimeName.disabled = false;
    //this.txtTerminalClosingTimeName.error = false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new TerminalClosingTime();
    //this.model.terminalClosingTimeName = '12345678901234567890123456789012\r34567890123456789012345678901234\r567\r\r234 345234 234123 12312 121 123 \r1231';
    this.valTerminalClosingTimeCode = "";
    this.valTerminalClosingTimeName = "";
  }

  handleUpdate() {
    // handle update event
    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock = false;
      this.model.updateMode = 1;
      // this.txtTerminalClosingTimeName.disabled = false;
      // this.txtTerminalClosingTimeName.error = false;
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete() {
    // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"closingId":"","closingCode":""};
    //   deleteObj.closingId = element.closingId;
    //   deleteObj.closingCode = element.closingCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBooking/checkingListClosingTimeUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {
            
    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }
            
    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
    this.message(
      "delete",
      "Deletion Record",
      "Confirm to delete record(s)?",
      "yesno",
      { yes: "this.deleteEvent()", no: "this.loading = false;" }
    );
  }

  handlePrint() {
    var formatName = "report-TerminalClosingTime-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/57/TerminalClosingTime-Export-Reports/" + this.officeCode;
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");

    // window.open(
    //   //this.configService.config.BASE_API.toString().replace("9090", "10000") +
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000")+
    //     "/Mreport/runReport/57/TerminalClosingTime-Export-Reports/" +
    //     this.officeCode
    // );
    // // handle print event
  }

  setValidator() {
    this.validatorRules = {
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },

      porCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Place of Receipt.",
          },
        ],
      },
      polCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Port of Load.",
          },
        ],
      },

      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
          {
            type: "modifiedPattern",
            pattern: /^[Oo]*$/,
            prompt: "Only O character is allowed",
          },
        ],
      },
    };
  }

  setValidatorHistory() {
    this.validatorRules = {
      vesselHistory: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyageHistoryCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
    };
  }

  handleSave() {
    // handle save event
    // console.log('handleSave');
    //this.handleCancel();
    console.log("-=-handle save");

    //console.log($('#porStuffClosingTime').val());
    this.model.porStuffTerminalClosingTime = $("#porStuffClosingTime").val();
    this.model.polStuffTerminalClosingTime = $("#polStuffClosingTime").val();
    this.model.porVgmClosingTime = $("#porVgmClosingTime").val();
    this.model.polVgmClosingTime = $("#polVgmClosingTime").val();
    this.model.porTerminalClosingTime = $("#porTerminalClosingTime").val();
    this.model.polTerminalClosingTime = $("#polTerminalClosingTime").val();
    this.model.porstuffVgmClosingTime = $("#porstuffVgmClosingTime").val();
    this.model.polstuffVgmClosingTime = $("#polstuffVgmClosingTime").val();

    this.model.updateMode = 0;
    /*this.model.polTerminalClosingDate = $('#polTerminalClosingDate').find('#datePicker').val();
    this.model.porTerminalClosingDate = $('#porTerminalClosingDate').find('#datePicker').val();
    this.model.porVgmClosingDate = $('#porVgmClosingDate').find('#datePicker').val();
    this.model.polVgmClosingDate = $('#polVgmClosingDate').find('#datePicker').val();
    this.model.porStuffTerminalClosingDate = $('#porStuffTerminalClosingDate').find('#datePicker').val();
    this.model.polStuffTerminalClosingDate = $('#polStuffTerminalClosingDate').find('#datePicker').val();
    this.model.porstuffVgmClosingDate = $('#porstuffVgmClosingDate').find('#datePicker').val();
    this.model.polstuffVgmClosingDate = $('#polstuffVgmClosingDate').find('#datePicker').val();*/

    this.model.polTerminalClosingDate = $("#polTerminalClosingDate").val();
    this.model.porTerminalClosingDate = $("#porTerminalClosingDate").val();
    this.model.porVgmClosingDate = $("#porVgmClosingDate").val();
    this.model.polVgmClosingDate = $("#polVgmClosingDate").val();
    this.model.porStuffTerminalClosingDate = $(
      "#porStuffTerminalClosingDate"
    ).val();
    this.model.polStuffTerminalClosingDate = $(
      "#polStuffTerminalClosingDate"
    ).val();
    this.model.porstuffVgmClosingDate = $("#porstuffVgmClosingDate").val();
    this.model.polstuffVgmClosingDate = $("#polstuffVgmClosingDate").val();

    this.setValidator();
    let isPassedValidation: boolean = this.onValidate(this.model);

    if (!isPassedValidation) {
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      console.log(this);
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        { yes: "this.saveEvent()", no: "this.loading = false;" }
      );
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    this.model = new TerminalClosingTime();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbPOL.setValue("");
    this.cbPOR.setValue("");
    this.cbPolStuffTerminalCode.setValue("");
    this.cbPorStuffTerminalCode.setValue("");
    this.cbReceiptType.setValue("");
    this.cbPORTerminalCode.setValue("");
    this.cbPOLTerminalCode.setValue("");
    this.cbVesselNameHistory.setValue("");
    this.cbVoyageNameHistory.setValue("");
    this.model.boundHistory = "O";
    this.model.vesselHistory = "";
    this.model.voyageHistoryCode = "";

    $('[data-toggle="datepicker"]').datepicker("reset");

    /*$('#polTerminalClosingDate').find('#datePicker').val(null);
    $('#porTerminalClosingDate').find('#datePicker').val(null);
    $('#porVgmClosingDate').find('#datePicker').val(null);
    $('#polVgmClosingDate').find('#datePicker').val(null);
    $('#porStuffTerminalClosingDate').find('#datePicker').val(null);
    $('#polStuffTerminalClosingDate').find('#datePicker').val(null);
    $('#polstuffVgmClosingDate').find('#datePicker').val(null);
    $('#porstuffVgmClosingDate').find('#datePicker').val(null);*/

    // reset time
    $("#porStuffClosingTime").val("");
    $("#polStuffClosingTime").val("");
    $("#porVgmClosingTime").val("");
    $("#polVgmClosingTime").val("");
    $("#porTerminalClosingTime").val("");
    $("#polTerminalClosingTime").val("");
    $("#porstuffVgmClosingTime").val("");
    $("#polstuffVgmClosingTime").val("");

    //this.txtTerminalClosingTimeName.error = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";
    this.grid.clearSelectedValues();

    // if(this.valTerminalClosingTimeId != '') {
    this.valTerminalClosingTimeCode = "";
    this.valTerminalClosingTimeId = "";
    this.cbTerminalClosingTimeNameHistory.close();
    this.cbTerminalClosingTimeNameHistory.setValue("");
    this.cbVesselNameHistory.setValue("");
    this.cbVesselNameHistory.close();
    this.cbVoyageNameHistory.setValue("");
    this.cbVoyageNameHistory.close();
    // this.txtTerminalClosingTimeName.setValue('');
    //this.gridHistory.onClear();
    this.gridHistory.search = "NaN";
    //this.gridHistory.listStore.store.splice(0,this.gridHistory.listStore.store.length);
    this.gridHistory.loadData();
    // }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log('grid-> ' + this.grid.getSelectedValues().length);

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    // console.log('grid-> ' + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";
    this.model.boundHistory = "O";
    this.gridHistory.listStore.store.splice(
      0,
      this.gridHistory.listStore.store.length
    );
    //this.gridHistory.onClear();
    //this.cbTerminalClosingTimeNameHistory.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterTerminalClosingTime/findGeneralHistory/'+this.officeCode+'/{query}');
  }

  setData() {
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];
    var self = this;
    this.lockAfterEdit = true;

    let currentDate = new Date();
    let theDate =
      this.genericUtil.pad2(currentDate.getFullYear()) +
      "-" +
      this.genericUtil.pad2(currentDate.getMonth() + 1) +
      "-" +
      this.genericUtil.pad2(currentDate.getDate()) +
      ":" +
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds();

    console.log("time now" + theDate);

    this.model = new TerminalClosingTime();

    if (data.isValid == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    this.loading = true;
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          data.vesselId +
          "/" +
          data.bound +
          "/" +
          data.voyage +
          "/" +
          this.officeCode
      )
      .subscribe((resp) => {
        //// console.log("rotation orig => " + resp.json()["content"][0]["rotation"].toString());
        this.localEtd = resp.json()["content"][0]["localEtd"];
        if (this.localEtd < theDate) {
          this.message(
            "information",
            "Information",
            "Vessel already departed, unable to modify this data.",
            "okonly",
            { ok: "" }
          );
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,save";
          }
          this.lock = true;
        }
      });
    this.loading = false;
    var porDate = String(data.porTerminalClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");
    var polDate = String(data.polTerminalClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");

    var porStuffDate = String(data.porStuffClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");
    var polStuffDate = String(data.polStuffClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");

    var porVgmDate = String(data.porVgmClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");
    var polVgmDate = String(data.polVgmClosingDate)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ")
      .split(" ");

    /*$('#porTerminalClosingDate').find('#datePicker').val(String(data.porTerminalClosingDate).trim().toUpperCase());
    $('#polTerminalClosingDate').find('#datePicker').val(String(data.polTerminalClosingDate).trim().toUpperCase());

    $('#porVgmClosingDate').find('#datePicker').val(String(data.porVgmClosingDate).trim().toUpperCase());
    $('#polVgmClosingDate').find('#datePicker').val(String(data.polVgmClosingDate).trim().toUpperCase());

    $('#porStuffTerminalClosingDate').find('#datePicker').val(String(data.porStuffClosingDate).trim().toUpperCase());
    $('#polStuffTerminalClosingDate').find('#datePicker').val(String(data.polStuffClosingDate).trim().toUpperCase());

    $('#polstuffVgmClosingDate').find('#datePicker').val(String(data.polStuffVgmClosingDate).trim().toUpperCase());
    $('#porstuffVgmClosingDate').find('#datePicker').val(String(data.porStuffVgmClosingDate).trim().toUpperCase());*/

    $("#porTerminalClosingDate").val(
      String(data.porTerminalClosingDate).trim().toUpperCase()
    );
    $("#polTerminalClosingDate").val(
      String(data.polTerminalClosingDate).trim().toUpperCase()
    );

    $("#porVgmClosingDate").val(
      String(data.porVgmClosingDate).trim().toUpperCase()
    );
    $("#polVgmClosingDate").val(
      String(data.polVgmClosingDate).trim().toUpperCase()
    );

    $("#porStuffTerminalClosingDate").val(
      String(data.porStuffClosingDate).trim().toUpperCase()
    );
    $("#polStuffTerminalClosingDate").val(
      String(data.polStuffClosingDate).trim().toUpperCase()
    );

    $("#polstuffVgmClosingDate").val(
      String(data.polStuffVgmClosingDate).trim().toUpperCase()
    );
    $("#porstuffVgmClosingDate").val(
      String(data.porStuffVgmClosingDate).trim().toUpperCase()
    );

    this.cbPolStuffTerminalCode.setForceValue(
      String(data.polStuffName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.cbPorStuffTerminalCode.setForceValue(
      String(data.porStuffName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.model.polStuffTerminalCode = String(data.polStuffTerminalCode)
      .trim()
      .toUpperCase();
    this.model.porStuffTerminalCode = String(data.porStuffTerminalCode)
      .trim()
      .toUpperCase();

    this.cbPORTerminalCode.setForceValue(
      String(data.porTerminalName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.cbPOLTerminalCode.setForceValue(
      String(data.polTerminalName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );

    this.model.porTerminalCode = String(data.porTerminalCode)
      .trim()
      .toUpperCase();
    this.model.polTerminalCode = String(data.polTerminalCode)
      .trim()
      .toUpperCase();

    this.cbReceiptType.setForceValue(
      String(data.receiptType).trim().toUpperCase()
    );
    this.model.receiptType = String(data.receiptType).trim().toUpperCase();

    this.model.closingId = String(data.closingId).trim().toUpperCase();

    this.model.porTerminalClosingTime = String(data.porTerminalClosingTime)
      .trim()
      .toUpperCase();

    this.model.porStuffTerminalClosingTime = String(data.porStuffClosingTime)
      .trim()
      .toUpperCase();
    this.model.polStuffTerminalClosingTime = String(data.polStuffClosingTime)
      .trim()
      .toUpperCase();

    this.model.polstuffVgmClosingTime = String(data.polStuffVgmClosingTime)
      .trim()
      .toUpperCase();
    this.model.porstuffVgmClosingTime = String(data.porStuffVgmClosingTime)
      .trim()
      .toUpperCase();

    this.model.polTerminalClosingTime = String(data.polTerminalClosingTime)
      .trim()
      .toUpperCase();

    this.model.porVgmClosingTime = String(data.porVgmClosingTime)
      .trim()
      .toUpperCase();
    this.model.polVgmClosingTime = String(data.polVgmClosingTime)
      .trim()
      .toUpperCase();

    this.model.oldValid = this.model.isValid;

    this.model.terminalClosingTimeId = String(data.terminalClosingTimeId)
      .trim()
      .toUpperCase();
    this.model.terminalClosingTimeCode = String(data.terminalClosingTimeCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.terminalClosingTimeName = String(data.terminalClosingTimeName)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.terminalClosingTimeAddress = String(
      data.terminalClosingTimeAddress
    )
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.terminalClosingTimePic = String(data.terminalClosingTimePic)
      .trim()
      .toUpperCase();
    this.model.terminalClosingTimeTelp = String(data.terminalClosingTimeTelp)
      .trim()
      .toUpperCase();
    this.cbVessel.setForceValue(
      String(data.vesselName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.model.vesselId = data.vesselId;
    this.cbPOL.setForceValue(
      String(data.polName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.model.porCode = String(data.porCode).trim().toUpperCase();
    this.cbPOR.setForceValue(
      String(data.porName)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );
    this.model.voyage = String(data.voyage)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.polCode = String(data.polCode).trim().toUpperCase();
    this.model.vesselId = String(data.vesselId).trim().toUpperCase();
    this.model.bound = String(data.bound)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");

    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/distinctVoyage/" +
        this.model.vesselId +
        "/" +
        this.model.bound +
        "/" +
        "{query}/" +
        this.officeCode
    );
    this.cbVoyage.setForceValue(
      String(this.model.voyage)
        .trim()
        .toUpperCase()
        .replace(/&NBSP;/g, " ")
    );

    this.model.keyCode = String(data.keyCode).trim().toUpperCase();
    this.model.porCode = String(data.porCode).trim().toUpperCase();
    this.model.polCode = String(data.polCode).trim().toUpperCase();
    this.model.porTerminalCode = String(data.porTerminalCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.polTerminalCode = String(data.polTerminalCode)
      .trim()
      .toUpperCase()
      .replace(/&NBSP;/g, " ");
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
  }

  valueTerminalClosingTimeHistory(event) {
    if (event["terminalClosingTimeCode"] != null) {
      this.valTerminalClosingTimeCode = event["terminalClosingTimeCode"];
      this.valTerminalClosingTimeId = event["terminalClosingTimeId"];
      // console.log('curr code= ' + event['terminalClosingTimeCode']);

      let tmp = String(event["terminalClosingTimeCode"])
        .toUpperCase()
        .replace(/&NBSP;/g, " ");
      this.valTerminalClosingTimeCode = tmp;

      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valTerminalClosingTimeId);
    }
  }

  generateTerminalClosingTimeHistoryReport(event: any) {
    if (
      this.model.vesselHistory == "" ||
      this.model.vesselHistory == "undefined" ||
      this.model.voyageHistoryCode == "" ||
      this.model.voyageHistoryCode == "undefined" ||
      this.model.boundHistory == ""
    ) {
      this.message(
        "warning",
        "Warning",
        "Please input parameter before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      var formatName = "report-History-TerminalClosingTime"+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/58/History-TerminalClosingTime/"
        +
        this.model.vesselHistory +
        this.model.voyageHistoryCode +
        this.model.boundHistory +
        this.officeCode
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(
      //   this.configService.config.BASE_API.toString().replace("9090", "10000").replace("5001","10000") +
      //     "/Mreport/runReport/58/History-TerminalClosingTime/" +
      //     this.model.vesselHistory +
      //     this.model.voyageHistoryCode +
      //     this.model.boundHistory +
      //     this.officeCode
      // );
    }
  }

  retrieveHistory(event: any) {
    this.setValidatorHistory();
    let isPassedValidationHistory: boolean = this.onValidate(this.model);

    if (!isPassedValidationHistory) {
      //this.gridHistory.loadData();
      this.gridHistory.search =
        this.model.vesselHistory +
        "/" +
        this.model.voyageHistoryCode +
        "/" +
        this.model.boundHistory +
        "/" +
        this.officeCode;
      this.gridHistory.onFirst();
    } else {
      this.loading = false;
    }
  }

  saveEvent() {
    // console.log('saveEvent');
    this.loading = true;
    this.model.officeCode = this.officeCode;

    var convertValid = "Y";

    if (this.model.isValid == false) convertValid = "N";

    if (this.model.isValid == false && this.model.terminalClosingTimeId != "") {
      this.model.dateInvalid = this.genericUtil.getPSIDate();
      this.model.userInvalid = this.cookieService.getName();
    }

    //this.model.keyCode = this.model.vesselId+this.model.voyage+this.model.bound+this.model.porCode+this.model.porTerminalCode+this.model.porTerminalClosingDate+this.model.porTerminalClosingTime+this.model.porVgmClosingDate+this.model.porVgmClosingTime
    //+this.model.polCode+this.model.polTerminalCode+this.model.polTerminalClosingDate+this.model.polVgmClosingDate+this.model.polVgmClosingTime+convertValid+this.officeCode;

    this.model.keyCode =
      this.model.vesselId +
      this.model.voyage +
      this.model.bound +
      this.model.receiptType +
      this.model.porCode +
      this.model.polCode;
    this.model.keyCode = this.model.keyCode
      .trim()
      .toUpperCase()
      .replace(" ", "");

    var listModel = [];
    listModel.push(this.model);
    var datas = "";

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/LocalMasterTerminalClosingTime/save",
        this.model
      )
      .subscribe(
        (resp) => {
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);

          //datas = resp.json()[0]['status'];

          if (resp.status == 200 && resp.json()["message"] == "OK") {
            // console.log('ENTER SAVE');
            this.model = new TerminalClosingTime();
            this.cbVessel.setForceValue("");
            this.cbVoyage.setForceValue("");
            this.cbReceiptType.setForceValue("");
            this.cbPOL.setForceValue("");
            this.cbPOR.setForceValue("");
            this.cbPolStuffTerminalCode.setForceValue("");
            this.cbPorStuffTerminalCode.setForceValue("");
            this.cbPORTerminalCode.setForceValue("");
            this.cbPOLTerminalCode.setForceValue("");

            $('[data-toggle="datepicker"]').datepicker("reset");
            /*$('#polTerminalClosingDate').find('#datePicker').val(null);
        $('#porTerminalClosingDate').find('#datePicker').val(null);
        $('#porVgmClosingDate').find('#datePicker').val(null);
        $('#polVgmClosingDate').find('#datePicker').val(null);
        $('#porStuffTerminalClosingDate').find('#datePicker').val(null);
        $('#polStuffTerminalClosingDate').find('#datePicker').val(null);
        $('#porstuffVgmClosingDate').find('#datePicker').val(null);
        $('#polstuffVgmClosingDate').find('#datePicker').val(null);*/

            this.loading = false;
            this.info = "save successfully";
            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  deleteEvent() {
    // console.log('Delete Event')
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.closingId);
    });

    var currentdate = new Date(); 
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-" 
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"  
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":" 
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"closingId":[]}
    paramsDelete.closingId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/LocalMasterTerminalClosingTime/delete",
        // deleteList
        "/LocalMasterTerminalClosingTime/softDelete",
        paramsDelete
      )
      .subscribe(
        (resp) => {
          // console.log('respirespi');
          // console.log(resp);
          if (resp.status == 200) {
            this.model = new TerminalClosingTime();
            //this.txtTerminalClosingTimeName.setValue('');
            //  this.selectList = [];
            this.loading = false;
            this.grid.clearSelectedValues();
            this.grid.onFirst();
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  noneSpace(event: any) {
    // console.log('Event none => ' + event);
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }

  changeEventStuff(event) {
    if (event.depoCode == null) {
      this.model.stuffCode = "";
    } else {
      this.model.stuffCode = event.depoCode;
    }
  }

  changeEventStuffTerminalCode(event) {
    if (event.depoCode == null) {
      this.model.stuffTerminalCode = "";
    } else {
      this.model.stuffTerminalCode = event.depoCode;
    }
  }

  changeEventVessel(event) {
    if (event.vesselId == null) {
      this.model.vesselId = "";
    } else {
      this.model.vesselId = event.vesselId;
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselId +
          "/" +
          this.model.bound +
          "/" +
          "{query}/" +
          this.officeCode
      );
      this.cbVoyage.setValue("");
    }
  }

  valueVesselHistory(event) {
    if (event.vesselId == null) {
      this.model.vesselHistory = "";
    } else {
      this.model.vesselHistory = event.vesselId;

      this.cbVoyageNameHistory.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselHistory +
          "/" +
          this.model.boundHistory +
          "/" +
          "{query}/" +
          this.officeCode
      );
      this.cbVoyageNameHistory.setValue("");
    }
  }

  changeEventVoyage(event) {
    if (event.voyage == null) {
      this.model.voyage = "";
    } else {
      this.model.voyage = event.voyage;
      this.localEtd = event.localEtd;

      console.log("local_ETA" + this.localEtd);
    }
  }

  valueVoyageHistory(event) {
    if (event.voyage == null) {
      this.model.voyageHistoryCode = "";
    } else {
      this.model.voyageHistoryCode = event.voyage;
      //this.gridHistory.onHistory(this.model.vesselHistory+'-'+this.model.voyageHistoryCode+'-'+this.model.boundHistory);
    }
  }

  changeEventBound(event) {
    this.model["error-bound"] = "";
  }

  changeEventBoundHistory(event) {}
  changeEventPORStuff(event) {
    if (event.depoCode == null) {
      this.model.porStuffTerminalCode = "";
    } else {
      this.model.porStuffTerminalCode = event.depoCode;
    }
  }
  changeEventPOLStuff(event) {
    if (event.depoCode == null) {
      this.model.polStuffTerminalCode = "";
    } else {
      this.model.polStuffTerminalCode = event.depoCode;
    }
  }

  changeEventPOR(event) {
    if (event.locationCode == null) {
      this.model.porCode = "";
    } else {
      this.model.porCode = event.locationCode;
    }
  }

  changeEventPORTerminalCode(event) {
    console.log(event);
    if (event.depoCode == null) {
      this.model.porTerminalCode = "";
    } else {
      this.model.porTerminalCode = event.depoCode;
    }
  }

  changeEventPOL(event) {
    if (event.locationCode == null) {
      this.model.polCode = "";
    } else {
      this.model.polCode = event.locationCode;
    }
  }

  changeEventReceiptType(event) {
    if (event.receiptTypeCode == null) {
      this.model.receiptType = "";
    } else {
      this.model.receiptType = event.receiptTypeCode;
    }
  }

  changeEventPOLTerminalCode(event) {
    if (event.depoCode == null) {
      this.model.polTerminalCode = "";
    } else {
      this.model.polTerminalCode = event.depoCode;
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar?: string
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      return (this.model[`error-${field}`] = "");
    }

    return false;
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }      
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }      
    });
  }
}
