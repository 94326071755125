<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:99"><div class="ui medium text loader">Loading</div></div>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

     <!--<Error Message Block-->
      <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li [class.hidden]="model['error-blOceanVesselCode'] == null || model['error-blOceanVesselCode'] == ''">
          {{model['error-blOceanVesselCode']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselVoyage'] == null || model['error-blOceanVesselVoyage'] == ''">
          {{model['error-blOceanVesselVoyage']}}
        </li>
        <li [class.hidden]="model['error-blOceanVesselBound'] == null || model['error-blOceanVesselBound'] == ''">
          {{model['error-blOceanVesselBound']}}
        </li>
      </ul>
    </div>
    <!-- End of Error Message Block-->

    <form class="ui form">
        <div class="field" style="height: 300px;">
          <div class="three fields">
            <br>
            <div class="field">
              <div class="required one field"  [class.error]="model['error-blOceanVesselCode'] != null && model['error-blOceanVesselCode'] != ''">
                <label>Vessel</label>
                <combo-plugin #cbVessel  [settings]="settingVessel" (change)="changeEventVessel($event)"></combo-plugin>
              </div>
            </div>

            <div class="field">
              <div class="required one field"  [class.error]="model['error-blOceanVesselVoyage'] != null && model['error-blOceanVesselVoyage'] != ''">
                <label>Voyage</label>
                <combo-plugin #cbVoyage  [settings]="settingVoyage" (change)="changeEventVoyage($event)" ></combo-plugin>
              </div>
            </div>

            <div class="one field">
              <label>Bound</label>
              <input id = "bound" type="text" name="bound"  placeholder="Bound" [(ngModel)]="bound" disabled >
            </div>
          </div>
          <hr>
          
          <div class="field">
            <fieldset style="border: 1px solid lightgray;width: 100%">
              <legend style="font-weight:bold;color:darkblue;">Inbound</legend>

              <div class="three fields">
                <div class="field">
                  <div class="ui test checkbox" style="margin: 10px;">
                    <input type="checkbox" id="chkBl" name="chkBl" [checked]="chkBl == true">
                    <label>BL</label>
                  </div>

                  <div class="ui test checkbox" style="margin: 10px;">
                    <input type="checkbox" id="chkContainer" name="chkContainer" [checked]="chkContainer == true">
                    <label>Container</label>
                  </div>

                  <div class="ui test checkbox" style="margin: 10px;">
                    <input type="checkbox" id="chkCharge" name="chkCharge" [checked]="chkCharge == true">
                    <label>Charge</label>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>

<footer-plugin [info]="info"></footer-plugin>
