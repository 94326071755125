import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  PdfPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { BLHeader, specialCharUtil } from "../shared";
import { Promise } from "q";
import { BLPrint } from "../shared";
declare var $: any;

@Component({
  selector: "app-report-in-bl-cargo-manifest",
  templateUrl: "./report-in-bl-cargo-manifest-page.component.html",
  styleUrls: ["./report-in-bl-cargo-manifest-page.component.css"],
})
export class ReportInBlCargoManifestPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  dialog: any;

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;

  @ViewChild("grid") grid: GridPluginComponent;

  @ViewChild("cbTranshipment") cbTranshipment: ComboPluginComponent;
  @ViewChild("cbFinDes") cbFinDes: ComboPluginComponent;
  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbPOR") cbPOR: ComboPluginComponent;
  @ViewChild("cbPOD") cbPOD: ComboPluginComponent;
  @ViewChild("pdfContainer") pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingPOR;
  settingLoadPort;
  settingDischargePort;
  settingTranshipment;
  settingFinDes;
  settingGrid;
  settingPDF;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  isCOC: Boolean = true;
  isSOC: Boolean = true;
  isSLOT: Boolean = true;
  isSOCTs: Boolean = true;
  isError: Boolean = false;
  isCOCTs: Boolean = true;
  chSilent: Boolean = true;
  lockBL: Boolean = false;
  cCOC: String;
  cSOC: String;
  cSLOT: String;
  cSOCTs: String;
  cCOCTs: String;
  chSelection: String;
  chType: String;
  rdType: String;
  generateAs: String;
  param: String;
  userName: String;
  IsContOwn: String;
  isChecked: String = "Y";
  isFinish: String = "Y";
  addRemoveLeftSide: String = "disable";
  addRemoveButton = "none";
  lengthArray: number = 0;
  postDt = {};
  flagPrint: boolean = true;
  errMessageType : number = 0;

  // lock
  cButtonLock = true;
  dButtonLock = true;

  sCharUtil = new specialCharUtil();
  model = new BLHeader();
  model2 = new BLHeader();

  officeCode =
    this.cookieService.getDefaultLocationCode() == null
      ? "*"
      : this.cookieService.getDefaultLocationCode();
  userId: string = "";

  validatorRules = {};

  constructor(
    private cdr: ChangeDetectorRef,
    auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "Print", event: "print", icon: "print" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Cargo Manifest Report (Inward)",
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingTranshipment = {
      id: "cbTrans",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingFinDes = {
      id: "cbFinDes",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDiscPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOR = {
      id: "cbPOR",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingPOD = {
      id: "cbPOD",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControl/LocationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "locationName",
      description: "",
      columns: [
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Valid?", field: "isValid", width: 100 },
      ],
    };

    this.settingGrid = {
      id: "grid",
      url: "", //this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: "1000",
      columns: [
        { header: "B/L No", field: "blNo", width: 200, editType: "text" },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "blNo",
      sortingDirection: "ASC",
      editable: true,
      enableSelectedRows: false,
      height: 500,
      minHeight: 500,
      autoSize: "auto",
    };
    this.settingPDF = {
      id: "pdfContainer",
      url: "",
      hidePrint: false,
    };
  }

  setValidatorVessel() {
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      blOceanVesselVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      blOceanVesselBound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  ngOnInit() {}

  ngAfterViewInit() {
    $(".dataTables_scrollBody").css("height", "422px");

    $(".menu .item").tab();

    this.afterViewMode();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.onRetrieve();
        break;
      case "print":
        //this.onPrint();
        this.saveBL();
        if (this.flagPrint == true) {
          this.saveEvent();
        } else {
          this.grid.loadData();
          if(this.errMessageType == 0) {
            this.message(
              "information",
              "Information",
              "Please fill BL no in the blank row.",
              "okonly",
              { ok: "this.loading=false;" }
            );
          } else {
            this.message(
              "information",
              "Information",
              "Please select bl to print",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        }
        break;
      case "cancel":
        this.onCancel();
        break;
      case "close":
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event) {}

  clearValidatorError() {
    this.model["error-blOceanVesselCode"] = "";
    this.model["error-blOceanVesselVoyage"] = "";
    this.model["error-blOceanVesselBound"] = "";
    this.model2["error-blOceanVesselCode"] = "";
    this.model2["error-blOceanVesselVoyage"] = "";
    this.model2["error-blOceanVesselBound"] = "";
  }

  onRetrieve() {
    if (this.lockBL) {
    } else {
      this.grid.is_select_all = "checked";
      this.setValidatorVessel();
      this.IsContOwn =
        this.cCOC +
        "-" +
        this.cSOC +
        "-" +
        this.cCOCTs +
        "-" +
        this.cSOCTs +
        "-" +
        this.cSLOT;
      this.isError = this.onValidate(this.model);
      console.log("VALIDASINYA -- > " + this.onValidate(this.model));
      if (!this.isError) {
        this.grid.url =
          this.configService.config.BASE_API.toString() +
          "/CargoManifestReportInward/findBLByVesselCargo";

        this.grid.search =
          this.officeCode +
          "=" +
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "=" +
          this.model.blOceanVesselBound +
          "=" +
          this.model.blPlaceOfReceiptCode +
          "=" +
          this.model.blLoadPortCode +
          "=" +
          this.model.blDischargePortCode +
          "=" +
          this.model.blPlaceOfDeliveryCode +
          "=" +
          this.model.blPortOfTransshipment1Code +
          "=" +
          this.model.blFinalDestinationCode +
          "=" +
          this.IsContOwn +
          "=" +
          this.chType +
          "=" +
          this.isFinish;
        this.grid.loadData();
        this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/CargoManifestReportInward/findBLByVesselCargo/" +
              this.grid.search +
              "/ASC/10"
          )
          .subscribe((resp) => {
            console.log("Masuk getBLStatus");
            if (resp.ok) {
              if (Object.keys(resp.json()["content"]).length <= 0) {
                this.message(
                  "information",
                  "Information",
                  "BL Not Found.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
              }
            } else {
              this.message(
                "information",
                "Information",
                "BL Not Found.",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          });
      }
    }
  }

  onPrint() {
    // console.log("Print disini");
    // console.log ("VesselId : "+this.model.blOceanVesselId);
    //console.log ("voyage : "+this.model.blOceanVesselVoyage);
    //console.log ("bound : "+this.model.blOceanVesselBound);
    //  console.log("userName : "+this.cookieService.getName());
    // console.log("ContOwn : "+this.IsContOwn);
    // console.log("Status nya: "+this.lockBL);

    if (this.grid.listStore.store.length > 0) {
      if (this.lockBL) {
        this.saveBL();
      } else {
        console.log("print vessel");

        this.userName = this.cookieService.getName();
        this.IsContOwn =
          this.cCOC +
          "-" +
          this.cSOC +
          "-" +
          this.cCOCTs +
          "-" +
          this.cSOCTs +
          "-" +
          this.cSLOT;
        this.param =
          this.model.blOceanVesselId +
          "=" +
          this.sCharUtil.htmlEncode(
            String(this.model.blOceanVesselVoyage).trim()
          ) +
          "=" +
          this.model.blOceanVesselBound +
          "=" +
          this.model.blPlaceOfReceiptCode +
          "=" +
          this.model.blLoadPortCode +
          "=" +
          this.model.blDischargePortCode +
          "=" +
          this.model.blPlaceOfDeliveryCode +
          "=" +
          this.model.blPortOfTransshipment1Code +
          "=" +
          this.model.blFinalDestinationCode +
          "=" +
          this.IsContOwn +
          "=" +
          this.chType;
        //this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
        this.setValidatorVessel();
        this.isError = this.onValidate(this.model);
        if (!this.isError) {
          if (this.model.blOceanVesselCode != "") {
            console.log("THIS PARAM--> " + this.param);
            this.loading = true;
            var hitUrl =
              this.configService.config.BASE_API.toString() +
              "/edi-util/getFile";
            var jasperURL =
              this.configService.config.jasperUrl.toString() +
              "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
              this.officeCode +
              "/CargoManifestInward.pdf?userCreated=" +
              this.userName +
              "&officeCode=" +
              this.officeCode +
              "&param=" +
              this.param;
            var fileName = "Cargo Manifest";

            console.log("HIT JASPER: " + jasperURL);

            var postData = {};
            postData["fileName"] = fileName;
            postData["url"] = jasperURL;

            this.genericService.POST(hitUrl, postData).subscribe((resp) => {
              if (resp.ok) {
                var result = resp.json();

                if (result["message"] == "success") {
                  this.pdfContainer.setPDFURL(
                    "http://glossys.samudera.com" + result["filePath"]
                  );
                  this.pdfContainer.showPDF();
                }

                this.loading = false;
              }
            });
            //}
          }
        }
      }
    } else {
      if (this.lockBL) {
        this.message(
          "information",
          "Information",
          "Please insert bl to print",
          "okonly",
          { ok: "" }
        );
      } else {
        this.message(
          "information",
          "Information",
          "Please retrieve the data",
          "okonly",
          { ok: "" }
        );
      }
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  rowEvent(act: string) {
    var tableStore = [];
    if (act == "add") {
      // console.log("here");

      this.grid.listStore.store.push({
        id: (this.grid.listStore.store.length + 1).toString(),
        blNo: "",
      });
      this.grid.listStore.store.forEach((tt) => {
        console.log(tt);
      });

      this.grid.loadData();
      //this.grid.is_select_all = 'checked';
      this.grid.onSelectAllByValue(true);
      this.grid.is_select_all = "checked";
    } else if (act == "del") {
      // console.log("PANJANG GRIDNYA: "+this.grid.onSelect.length);

      //  console.log("masuk remove");
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck: number;
      var storeTemp = new ListStore();

      this.grid.listStore.store.forEach((xy) => {
        //  console.log("xy "+xy);
        storeTemp.addData(xy);
      });

      storeTemp.store.forEach((el) => {
        if (el["select"] == "checked") {
          boxCheck = this.grid.listStore.store.indexOf(el);
          this.grid.listStore.store.splice(boxCheck, 1);

          $('input[name="gridCount"]').val(
            parseInt($('input[name="gridCount"]').val()) - 1
          );
        } else {
        }
      });

      this.grid.listStore.store.forEach((col) => {
        seqNo = seqNo + 1;
        col.seqNo = seqNo;
      });

      this.grid.loadData();
      this.grid.clearSelectedValues();
    }
  }

  changeEventVessel(event) {
    // console.log("EVENTnya :"+event.vesselCode);
    //  console.log("Vessel Id :"+event.vesselId);
    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      console.log("Vessel kosong");
      this.model.blOceanVesselId = "";
      this.model.blOceanVesselCode = "";
    } else {
      this.model.blOceanVesselId = event.vesselId;
      this.model.blOceanVesselCode = event.vesselCode;

      this.cbVoyage.disableCombo = false;
      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyageInward/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBound.toUpperCase() +
          "/voyage_like={query}"
      );
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  afterViewMode() {
    this.vesselMode();
    this.model.blOceanVesselBound = "I";
    this.model2.blOceanVesselBound = "I";
    this.chSelection = "Vessel";
    this.chType = "ALL";
    this.cCOC = "COC";
    this.cSOC = "SOC";
    this.cCOCTs = "COCTS";
    this.cSOCTs = "SOCTS";
    this.cSLOT = "SLOT";
    this.rdType = "Cont";
    this.generateAs = "PDF";
    this.chSilent = true;
    this.isFinish = "N";
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSLOT;
  }

  onCancel() {
    this.clearValidatorError();
    this.isError = false;
    this.chSelection = "Vessel";
    this.vesselMode();
    this.model.blOceanVesselBound = "I";
    this.model2.blOceanVesselBound = "I";
    this.chType = "ALL";
    this.cCOC = "COC";
    this.cSOC = "SOC";
    this.cCOCTs = "COCTS";
    this.cSOCTs = "SOCTS";
    this.cSLOT = "SLOT";
    this.isCOC = true;
    this.isSOC = true;
    this.isCOCTs = true;
    this.isSOCTs = true;
    this.isSLOT = true;
    this.rdType = "Cont";
    this.generateAs = "PDF";
    this.chSilent = true;
    this.isFinish = "N";
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSLOT;
    this.cbVessel.clearSelect();
    this.cbVessel.setValue("");
    this.cbVoyage.clearSelect();
    this.cbVoyage.setValue("");
    this.cbDischargePort.clearSelect();
    this.cbDischargePort.setValue("");
    this.cbLoadPort.clearSelect();
    this.cbLoadPort.setValue("");
    this.cbFinDes.clearSelect();
    this.cbFinDes.setValue("");
    this.cbTranshipment.clearSelect();
    this.cbTranshipment.setValue("");
    this.cbPOD.clearSelect();
    this.cbPOD.setValue("");
    this.cbPOR.clearSelect();
    this.cbPOR.setValue("");
    this.grid.onClear();
    this.grid.url = "";
  }

  onClear() {
    this.model.blOceanVesselBound = "I";
    this.model2.blOceanVesselBound = "I";
    this.chType = "ALL";
    this.cCOC = "COC";
    this.cSOC = "SOC";
    this.cCOCTs = "COCTS";
    this.cSOCTs = "SOCTS";
    this.cSLOT = "SLOT";
    this.isCOC = true;
    this.isSOC = true;
    this.isCOCTs = true;
    this.isSOCTs = true;
    this.isSLOT = true;
    this.rdType = "Cont";
    this.generateAs = "PDF";
    this.chSilent = true;
    this.isFinish = "N";
    this.IsContOwn =
      this.cCOC +
      "-" +
      this.cSOC +
      "-" +
      this.cCOCTs +
      "-" +
      this.cSOCTs +
      "-" +
      this.cSLOT;
    this.cbVessel.clearSelect();
    this.cbVessel.setValue("");
    this.cbVoyage.clearSelect();
    this.cbVoyage.setValue("");
    this.cbDischargePort.clearSelect();
    this.cbDischargePort.setValue("");
    this.cbLoadPort.clearSelect();
    this.cbLoadPort.setValue("");
    this.cbFinDes.clearSelect();
    this.cbFinDes.setValue("");
    this.cbTranshipment.clearSelect();
    this.cbTranshipment.setValue("");
    this.cbPOD.clearSelect();
    this.cbPOD.setValue("");
    this.cbPOR.clearSelect();
    this.cbPOR.setValue("");
    this.cbDischargePort;
    //console.log("LENGHT GRID : "+this.grid.listStore.store.length);
    this.grid.onClear();

    this.grid.url = "";
  }

  changeContainerCOC(event) {
    if (event.target.checked) {
      this.isCOC = true;
      this.cCOC = "COC";
    } else {
      this.isCOC = false;
      this.cCOC = "";
    }
  }

  changeUnfinish(event) {
    if (event.target.checked) {
      this.chSilent = true;
      this.isFinish = "N";
    } else {
      this.chSilent = false;
      this.isFinish = "Y";
    }
  }

  changeContainerSOC(event) {
    if (event.target.checked) {
      this.isSOC = true;
      this.cSOC = "SOC";
    } else {
      this.isSOC = false;
      this.cSOC = "";
    }
  }

  changeContainerCOCTs(event) {
    if (event.target.checked) {
      this.isCOCTs = true;
      this.cCOCTs = "COCTS";
    } else {
      this.isCOCTs = false;
      this.cCOCTs = "";
    }
  }

  changeContainerSOCTs(event) {
    if (event.target.checked) {
      this.isSOCTs = true;
      this.cSOCTs = "SOCTS";
    } else {
      this.cSOCTs = "";
      this.isSOCTs = false;
    }
  }

  changeContainerSLOT(event) {
    if (event.target.checked) {
      this.isSLOT = true;
      this.cSLOT = "SLOT";
    } else {
      this.isSLOT = false;
      this.cSLOT = "";
    }
  }

  initTab1() {}
  initTab2() {}
  changeEventVoyage(event) {
    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      this.model.blOceanVesselVoyage = "";
    } else {
      this.model.blOceanVesselVoyage = event.voyage;
    }
  }
  changeEventPOR(event) {
    console.log("POR " + event.locationCode);
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfReceiptCode = "";
    } else {
      this.model.blPlaceOfReceiptCode = event.locationCode;
    }
  }
  changeEventLoadPort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blLoadPortCode = "";
    } else {
      this.model.blLoadPortCode = event.locationCode;
    }
  }
  changeEventDischargePort(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blDischargePortCode = "";
    } else {
      this.model.blDischargePortCode = event.locationCode;
    }
  }
  changeEventPOD(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPlaceOfDeliveryCode = "";
    } else {
      this.model.blPlaceOfDeliveryCode = event.locationCode;
    }
  }
  changeEventTranshipment(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blPortOfTransshipment1Code = "";
    } else {
      this.model.blPortOfTransshipment1Code = event.locationCode;
    }
  }
  changeEventFinDes(event) {
    if (
      event.locationCode == null ||
      event.locationCode == "" ||
      event.locationCode == undefined
    ) {
      this.model.blFinalDestinationCode = "";
    } else {
      this.model.blFinalDestinationCode = event.locationCode;
    }
  }
  gridEvent(event) {
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log("eventgrid" + event);
    switch (event.split(".")[0]) {
      case "selected":
        this.grid.getSelectedValues().forEach((element) => {
          if (element.select == "checked") {
            flgTool = 1;
          }
        });
        if (flgTool > 0)
          //this.disableToolbarButtons = "";
          //console.log(flgTool);
          //else
          // this.disableToolbarButtons = "unlock";
          //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
          break;
      case "click":
        this.grid.listStore.store.forEach((yr) => {
          console.log("KLIK GRID " + yr);
          if (yr.select == "checked") {
            console.log("ceki ceki " + yr["blNo"]);
            this.model.blOfficeCode = this.officeCode;
            this.model.blNo = yr["blNo"].toUpperCase();
            flgTool = 1;
          } else {
            this.model.blNo = "";
            this.model.blOfficeCode = "";
          }
        });

        if (flgTool > 0) console.log(flgTool);
        // this.disableToolbarButtons = "retrieve";
        else this.isChecked = "N";
        //this.disableToolbarButtons = "retrieve,unlock";
        break;

      case "afterLoad":
        /*
        this.lengthArray = this.grid.listStore.store.length;

        if(this.lengthArray == 0){
          console.log("LENGTH GRID --> "+this.grid.listStore.store.length);
          this.grid.listStore.store=[];
          this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=true;'});
        }
        */

        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split(";");
          console.log("before split4 -> " + strEvent);
          console.log("arr3 -> " + arr);
          console.log("arr-split1 -> " + arr[0]);
          console.log("arr-split2 -> " + arr[1]);
          switch (arr[0].split(".")[0]) {
            case "AfterEdit":
              console.log("strEvent : " + strEvent);
              if (strEvent.split(";")[1] == "blNo") {
                this.getBLStatus(strEvent.split(";")[2]);
              }

              this.grid.listStore.store.forEach((yr) => {
                console.log(yr);

                if (yr.select == "checked") {
                  flgTool = 1;
                  this.model.blNo = yr["blNo"].trim().toUpperCase();
                  this.model.blOfficeCode = this.officeCode;
                } else {
                  this.model.blNo = "";
                  this.model.blOfficeCode = "";
                }
                soso.push(yr);
              });

              console.log("Size : " + this.grid.listStore.store.length);

              if (flgTool > 0)
                //this.disableToolbarButtons = "retrieve";
                console.log(flgTool);
              else this.disableToolbarButtons = "unlock";
              dblFlag = this.checkDuplicateInObject("blNo", soso);

              if (dblFlag == true) {
                this.message(
                  "information",
                  "Information",
                  "Duplicate BL Reference(s) detected.",
                  "okonly",
                  { ok: "this.loading=false;" }
                );
                this.grid.listStore.store.splice(
                  parseInt(arr[0].split(".")[1]) - 1,
                  1
                );
                this.grid.loadData();
              }

              break;
            case "dblClick":
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  getBLStatus(blNo: String) {
    console.log("** getBLStatus **");
    var BLPrint: String;
    var BLFinish: String;
    var boxCheck: number;

    if (blNo.trim() == "" || blNo.trim() == undefined) {
      blNo = "blNo";
    }
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/CargoManifestReportInward/findBLNo/" +
          this.officeCode +
          "/" +
          blNo.trim().toUpperCase()
      )
      .subscribe((resp) => {
        //console.log("Masuk getBLStatus");
        if (resp.ok) {
          if (Object.keys(resp.json()["content"]).length > 0) {
            BLPrint = resp.json()["content"][0].blIsBlPrinted;
            console.log("Status BL --> " + resp.json()["content"][0].blStatus);
            switch (resp.json()["content"][0].blStatus) {
              case "D":
                //console.log("bl delete");
                this.grid.listStore.store.forEach((data) => {
                  if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                    this.message(
                      "information",
                      "Information",
                      "This BL has been deleted.",
                      "okonly",
                      { ok: "this.loading=false;" }
                    );

                    boxCheck = this.grid.listStore.store.indexOf(data);
                    //console.log("BOXCEK --> "+boxCheck);
                    this.grid.listStore.store.splice(boxCheck, 1);
                    this.grid.loadData();
                  }
                });
                break;
              default:
                BLFinish = resp.json()["content"][0].blIsFinished;
                this.grid.listStore.store.forEach((data) => {
                  if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                    if (BLFinish == "N" && this.isFinish == "Y") {
                      this.message(
                        "information",
                        "Information",
                        "BL has not been finished.",
                        "okonly",
                        { ok: "this.loading=false;" }
                      );

                      boxCheck = this.grid.listStore.store.indexOf(data);
                      console.log("BOXCEK --> " + boxCheck);
                      this.grid.listStore.store.splice(boxCheck, 1);
                      this.grid.loadData();
                    }
                  }
                });
                break;
            }
          } else {
            if (this.grid.listStore.store.length > 0) {
              //console.log("satu: "+Object.keys(resp.json()['content']).length );

              this.grid.listStore.store.forEach((data) => {
                if (data.blNo.toUpperCase() == blNo.toUpperCase()) {
                  this.message(
                    "information",
                    "Information",
                    "BL Not Found.",
                    "okonly",
                    { ok: "this.loading=false;" }
                  );

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  console.log("BOXCEK --> " + boxCheck);
                  this.grid.listStore.store.splice(boxCheck, 1);
                  this.grid.loadData();
                }
              });

              /*if(Object.keys(resp.json()['content']).length == 0){
              this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});
              this.grid.onClear();
              this.grid.loadData();
            }*/
            }
          }
        }
      });
  }

  infoGrid(event) {}

  changeType(event) {
    if (event.target.value == "ALL") {
      this.chType = "ALL";
    } else if (event.target.value == "FULL") {
      this.chType = "FULL";
    } else if (event.target.value == "EMPTY") {
      this.chType = "EMPTY";
    }
  }
  changeSelection(event) {
    if (event.target.value == "BL") {
      this.clearValidatorError();
      this.isError = false;
      this.onClear();
      this.chSelection = "BL";
      this.blMode();
      this.grid.enable();
    } else if (event.target.value == "Vessel") {
      this.clearValidatorError();
      this.isError = false;
      this.onClear();
      this.chSelection = "Vessel";
      this.vesselMode();
      //this.grid.disable();
    }
  }

  changeRadioType(event) {
    if (event.target.value == "Cont") {
      this.rdType = "Cont";
    } else if (event.target.value == "POP") {
      this.rdType = "POP";
    }
  }

  changeGenerate(event) {
    if (event.target.value == "PDF") {
      this.generateAs = "PDF";
    } else if (event.target.value == "EXC") {
      this.generateAs = "EXC";
    }
  }

  blMode() {
    this.addRemoveButton = "block";
    this.disableToolbarButtons = "retrieve";
    this.lockBL = true;
    this.chSilent = true;
  }
  vesselMode() {
    console.log("vesselMode");
    this.chSilent = false;
    //this.grid.disable();
    this.addRemoveButton = "none";
    this.lockBL = false;
    this.chSilent = true;
    this.disableToolbarButtons = "";
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName].trim().toUpperCase();
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  eventPDFPlugin(event) {}

  saveBL() {
    this.postDt = {};
    this.postDt["blNo"] = [];
    console.log("Masuk save BL 0");

    var boxCheck: number;
    if (this.grid.listStore.store.length > 0) {
      console.log("Masuk save BL");
      var totalSelect: number = 0;
      this.errMessageType = 0;
      this.grid.listStore.store.forEach((element) => {
        if (element.select == "checked") {
          console.log("data elemen: " + element.blNo + " - " + element);
          totalSelect++;
          if (element.blNo == "") {
            /*console.log("kosong satu : "+this.grid.listStore.store.indexOf(element));
          boxCheck = this.grid.listStore.store.indexOf(element);
          this.grid.listStore.store.splice(boxCheck,1);*/
            this.flagPrint = false;
          } else {
            this.postDt["blNo"].push(element.blNo);
            this.flagPrint = true;
          }
        }
      });
      if(totalSelect == 0){
        this.flagPrint = false;
        this.errMessageType = 1;
      }
      this.postDt["sessionId"] = this.cookieService.getName();
      this.postDt["officeCode"] = this.officeCode;
      this.postDt["result"] = "CargoManifestInward";
      this.postDt["containerOwnership"] = "";
      this.postDt["blOceanVesselId"] = "";
      this.postDt["blOceanVesselVoyage"] = "";
      this.postDt["blOceanVesselBound"] = "";
      this.postDt["blOceanVesselName"] = "";
    } else {
      this.message(
        "information",
        "Information",
        "Please insert bl to print",
        "okonly",
        { ok: "" }
      );
      this.grid.onClear();
    }
  }

  saveEvent() {
    console.log("Save event: " + this.postDt);
    this.loading = true;
    /* this.grid.listStore.store.push(this.postDt);
    this.grid.listStore.store.forEach(element => {
      console.log("Data BL: "+element.blNo);
    });*/

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/CargoManifestReportInward/saveBLNo",
        this.postDt
      )
      .subscribe(
        (resp) => {
          console.log("STATUS=" + resp.status);
          console.log("MESSAGE=" + resp.json()["message"]);
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            console.log("ENTER SAVE");

            this.loading = false;
            this.info = "save successfully";

            //this.setGridEvent = 'refresh';
            //this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;'});
            this.userName = this.cookieService.getName();
            this.param = "session=" + this.userName + "=" + "CargoManifestInward";
            //this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
            //if(this.model.blNo != ''){
            this.loading = true;

            var hitUrl =
              this.configService.config.getPDFUrl + "/edi-util/getFile";
            var jasperURL =
              this.configService.config.jasperUrl +
              "/jasperserver/rest_v2/reports/reports/GLOSSYS/" +
              this.officeCode +
              "/CargoManifestInward.pdf?userCreated=" +
              this.userName +
              "&officeCode=" +
              this.officeCode +
              "&param=" +
              this.param;
            var fileName = "InwardCargoManifest.pdf";

            console.log("HIT JASPER: " + jasperURL);

            var postData = {};
            postData["fileName"] = fileName;
            postData["url"] = jasperURL;

            this.genericService.POST(hitUrl, postData).subscribe((resp) => {
              if (resp.ok) {
                var result = resp.json();

                if (result["message"] == "success") {
                  this.pdfContainer.setPDFURL(
                    this.configService.config.pdfUrl + result["filePath"]
                  );
                  this.pdfContainer.showPDF();
                }

                this.loading = false;
              }
            });
            //}
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              "Please insert at least 1 BL to print Cargo Manifest",
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
          this.loading = false;
        }
      );
  }
}
