import { Component, OnInit, AfterViewInit, EventEmitter,ViewChild, ChangeDetectorRef } from '@angular/core';
import { Region, GridPluginComponent2 } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent,  ComboPluginComponent, ToolbarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';

declare  var $:any;
//import 'definitions/modules/calendar';

@Component({
  selector: 'app-master-region-page',
  templateUrl: './master-region-page.component.html',
  styleUrls: ['./master-region-page.component.css']
})
export class MasterRegionPageComponent extends Validator  implements OnInit, AfterViewInit {

  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent2;
  @ViewChild('cbRegionNameHistory')
  cbRegionNameHistory:ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;
  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  enableToolbarButtons = '';
  disableToolbarButtons = '';
  invisibleToolbarButtons = "";
  typeCalendar = 'calendar'; // for calendar
  typeTime = 'time12'; // time12 for 12 hour time24  for 24 hour
  typeCalendarPort = 'calendar'; // for calendar
  typeTimePort = 'time12'; // time12 for 12 hour time24  for 24 hour
  settingCalendar;
  settingCalendarPort;

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter listStore for Grid */
  listStore = new ListStore;
  setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingRegion;
  settingValid;
  settingRegionHistory;
  settingGridHistory;
  valRegionId:string='';
  valRegionCode:string='';
  valRegionName:string='';
  valHistoryStartDate:string='';
  valHistoryEndDate:string='';

   /* Parameter model */
  model = new Region;
  selectList = [];

   /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';
  lock = false;

  /* status success or not */
 // isSuccess:boolean = false;

  loading = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
      regionName: {
        rules: [
          {
            type   : 'empty',
            prompt : 'Please input Region Name.'
          }
        ]
      },
      regionCode: {
           rules: [
          {
           type  : 'empty',
            prompt : 'Please input Region Code.'
          },
          {
            type   : 'length[3]',
            prompt : 'Region Code must be 3 characters long.'
          }
        ]
      },
       regionPosition: {
           rules: [
          {
           type  : 'positiveInteger',
            prompt : 'No negative value!'
          }

        ]
      }

  }



  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();
    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
       searchBy              : [
        { display: 'Region Code', value: 'regionCode'},
        { display: 'Region Name', value: 'regionName'},
        { display: 'Position', value: 'regionPosition'},
        { display: 'L.Trade Code', value: 'linerTradeCode'},
        { display: 'Exception', value: 'exception'},
        { display: 'User Created', value: 'userCreated'},
        { display: 'Date Created', value: 'dateCreated'},
        { display: 'User Modified', value: 'userModified'},
        { display: 'Date Modified', value: 'dateModified'},
        { display: 'User Invalid', value: 'userValid'},
        { display: 'Date Invalid', value: 'dateValid'},
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" },

      ],
    //  searchBy              : [
      //  { display: 'Valid', value: 'valid'},
       // { display: 'Invalid', value: 'invalid'},
      //],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }


    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterRegion/findGeneral',
       // template: '<h4>Master Region</h4>',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 10},

                    {header: 'Region Name', field: 'regionName', width: 200},
                    {header: 'Region Code', field: 'regionCode', width: 100},
                    {header: 'Position', field: 'regionPosition', width: 50},
                    {header: 'L. Trade Code', field: 'linerTradeCode', width: 50},
                    {header: 'Exception', field: 'exception', width: 100},
                    {header: 'User Created', field: 'userCreated', width: 100},
                    {header: 'Date Created', field: 'dateCreated', width: 100},
                    {header: 'User Modified', field: 'userModified', width: 100},
                    {header: 'Date Modified', field: 'dateModified', width: 100},
                    {header: 'User Invalid', field: 'userValid', width: 100},
                    {header: 'Date Invalid', field: 'dateValid', width: 100},
                    {header: 'Valid?', field: 'isValid', width: 50},
                    { header: "User Deleted", field: "userDeleted", width: 110 },
                    { header: "Date Deleted", field: "dateDeleted", width: 110 },
                    { header: "Delete", field: "isDeleted", width: 80 }
        ],
        buttons: [

         { name: 'Edit', event: 'edit', enabled: true }
         ],
        //store: this.listStore,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'dateCreated',
        sortingDirection: 'DESC'
    }
//findAllMasterRegionHistoryById
     this.settingGridHistory={
      url: this.configService.config.BASE_API.toString() +
        '/MasterRegion/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Region Id', field:'regionId', width:200},
        {header: 'Region Name', field: 'regionName', width: 200},
        // {header: 'Region Code', field: 'regionCode', width: 50},
        {header: 'Position', field: 'regionPosition', width: 300},
        {header: 'Liner Trade Code', field: 'linerTradeCode',width: 300},
        {header: 'Exception', field: 'exception', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userValid', width: 100},
        {header: 'Date Invalid', field: 'dateValid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [

        { name: 'Edit', event: 'edit', enabled: true }
        ],
       //store: this.listStore,
       enableSorting: true,
       enableSelectAll: false,
       enablePagination: true,
       sortingColumns:'dateCreated',
       sortingDirection: 'DESC'
    }

    this.settingRegionHistory = {
      id          : 'cbRegionNameHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterRegion/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'regionName',
      description : '',
      columns     : [
        {header: 'Region Id', field: 'regionId', width:100},
        {header: 'Region Name', field: 'regionName', width: 250},
        {header: 'Region Code', field: 'regionCode', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50}
      ]
    }


  }
 ngOnInit(){
    $('.ui.accordion').accordion();
  }
  ngAfterViewInit(){
    $('.test.checkbox').checkbox();
    //this.invisibleToolbarButtons = "cancel";
    //this.disableToolbarButtons = "update,delete";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();

    this.firstInit();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];

  }

  firstInit(){
    this.currentMenuID = this.cookieService.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }
 message2(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }
  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

   infoGridHistory(event:any){
    this.info=event;
   }

  gridEvent(event:any) {
   switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if(this.viewOnlyStatus){
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }
        }

        break;
    }
  }
  gridEventHistory(event:any){
    //do nothing
  }

  toolbarEvent(event:any) {
    // console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        if(this.model.regionId != ""){
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
       // // console.log('str.indexOf = ' + str.indexOf('search'));
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.lock=false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    //this.isSuccess = false;
    this.model = new Region;
      this.valRegionCode='';
      this.valRegionName='';
   }

  handleDelete()
  {
    // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   deleteList.push(element.accountId);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListAccountPICUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
     this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
     //this.grid.clearSelectedValues();
  }
handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }
  handlePrint()
  {
    // handle print event
    // console.log('handle print event');
    var formatName = "report-MasterRegions-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/41/MasterRegions-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/41/MasterRegions-Export-Reports/*');
  }

  handleSave()
  {
    // handle save event
        // console.log('handleSave');
    let isvalid:boolean = this.onValidate(this.model);

    if(!isvalid){
       /*Patch for local timing
      * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
      * 2. System should use local timing instead of server timing
      * 3. Format should be YYYY-MM-DD hh:mm:ss
      */
      if(this.model["dateCreated"]==null || this.model["dateCreated"]=="")
      { // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      }
       this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
   // this.grid.clearSelectedValues();
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form='';
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";
    }
   // this.lock = false;
    this.grid.clearSelectedValues();

      if(this.valRegionId != '') {
      this.valRegionCode = '';
      this.valRegionId = '';
      this.cbRegionNameHistory.setValue("");
      this.cbRegionNameHistory.close();
      this.gridHistory.onHistory("NaN")
      this.gridHistory.loadData();
    }
  }

/* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
   // this.grid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }
 handleUpdate()
  {//handle update event
    //if(this.selectList.length > 1){


   // this.isSuccess = false;
   let dt = this.grid.getSelectedValues()[0];
   if (dt.isDeleted == "Y") {
     this.message(
       "warning",
       "Error Input",
       "The record has been deleted",
       "okonly",
       { ok: "this.loading=false;" }
     );
     return false;
   } else {
    this.modeForm = true;
    this.modeToolbar = true;
    this.setData();
    // console.log("PSI Date => " + this.genericUtil.getPSIDate());
    this.model["dateModified"] = this.genericUtil.getPSIDate();
   //this.model["dateValid"] = this.model["dateModified"] ;
    this.lock = false;
    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";
   }
    //this.grid.clearSelectedValues();
  }
  setData(){
          //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];
      // console.log(data);
    this.model = new Region;

    if(data.exception== 'Y'){
      this.model.exception = true;
    }else{
      this.model.exception = false;
    }
    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }
    this.model.regionId = String(data.regionId).trim().toUpperCase();
    this.model.regionCode = String(data.regionCode).trim().toUpperCase();
    this.model.regionName  = String(data.regionName).trim().toLowerCase();
    this.model.regionPosition = Number(data.regionPosition);
    this.model.linerTradeCode = String(data.linerTradeCode).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.dateValid = String(data.dateValid).trim().toUpperCase();
    this.model.userValid = String(data.userValid).trim().toUpperCase();
    this.model.dateDeleted = String(data.dateDeleted).trim().toUpperCase();
    this.model.userDeleted = String(data.userDeleted).trim().toUpperCase();
    if(data.isDeleted=='Y'){
      this.model.isDeleted=true;
    }else{
      this.model.isDeleted=false;
    }
  }
valueRegionHistory(event:any){
    // this.valRegionCode=event["regionCode"];
    //  this.valRegionId=event["regionId"];
    // // console.log("region code= " + event["regionCode"]);
    // this.gridHistory.onHistory(this.valRegionId);

    this.valRegionCode = null;
    this.valRegionId = null;
    if (event.regionId == null) {
      if (event.target != null) {
      }
    } else {
      this.valRegionId = String(event.regionId).trim();
      this.valRegionCode = String(event.regionCode).trim();
      //refresh the history grid accordingly
      this.gridHistory.onHistory(this.valRegionId);
    }
  }


  generateRegionHistoryReport(event){
    // this.valHistoryStartDate= $('#logHistoryRegionStartDate').find('#datePicker').val();
    //  this.valHistoryEndDate= $('#logHistoryRegionEndDate').find('#datePicker').val();
    if(this.valRegionId == ''){
      this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    }else{
      var formatName = "report-History-Region-"+this.valRegionId+"-"+this.valRegionCode+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/42/History-Region-"
        + this.valRegionId + '-'
        + this.valRegionCode +'/'
        +this.valRegionId + '@'
        + this.valHistoryStartDate + '@'
        + this.valHistoryEndDate
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      // window.open(this.configService.config.BASE_API.toString().replace('9090','10000').replace("5001","10000") + '/Mreport/runReport/42/History-Region-' + this.valRegionId + '-' + this.valRegionCode +'/'+this.valRegionId + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);
    }
  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    // console.log('saveEvent');
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterRegion/save',this.model).subscribe((resp) =>{
          // console.log('STATUS=' + resp.status);
          // console.log('MESSAGE=' + resp.json()['message']);
          if(resp.status == 200 && resp.json()['message'] == 'OK'){
            // console.log("ENTER SAVE");
            this.model = new Region;
            this.loading = false;
            this.info = 'save successfully';
            //this.setGridEvent = 'refresh';
            this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
          }else{
            this.loading = false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
      this.loading = false;
    });
  }


  deleteEvent(){
    // console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.regionId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"regionId":[]}
    paramsDelete.regionId = deleteList;

    // this.genericService.DELETE(this.configService.config.BASE_API.toString() + '/MasterRegion/delete',deleteList)
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        //   "/MasterPrincipals/delete",
        // deleteList
        "/MasterRegion/softDelete",
          paramsDelete
      ).subscribe((resp) =>{
          if(resp.status == 200){
              this.model = new Region;
            //  this.selectList = [];
              this.loading = false;
              this.grid.clearSelectedValues();
              this.grid.onFirst();
              //this.setGridEvent = 'refresh';
              this.loading=true;
              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  noneSpace(event:any){
    // console.log("Event none => " + event);
    if(event.keyCode == 32){
      event.preventDefault();
    }
  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

}
