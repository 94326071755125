import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService, DialogPluginComponent, GenericService } from 'sibego-ui-library';
import { specialCharUtil } from '../shared';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;

export class CODATransmissionConfig {
  scheduleFrequency = ''; // types: DAILY, HOURLY, etc.
  scheduleDetails = ''; // detail value of the frequency
  reportType = ''; // report type: EMAIL or SYSTEM_LOG
  reportDetails: string[] = [];
}

@Component({
  selector: 'app-edi-transmit-to-coda-config',
  templateUrl: './edi-transmit-to-coda-config.component.html',
  styleUrls: ['./edi-transmit-to-coda-config.component.css']
})
export class EdiTransmitToCodaConfigComponent implements OnInit {

  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;

  modeToolbar = false;
  loading = false;

  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter settings */
  settingToolbar;

  /* Parameter for enable/disable Toolbar buttons */
  dialog: any;

  /* Parameter for information into Footer */
  info = "";

  // * HTML Encode
  sCharUtil = new specialCharUtil();
  codaForm: FormGroup;

  scheduleFrequency = 'DAILY';
  reportDetails: string[] = [];

  get emailAddForm() {
    return this.codaForm.get("email").value;
  }

  constructor(
    private router: Router,
    fb: FormBuilder,
    private genericService: GenericService,
    private configService: ConfigService
  ) {

    this.settingToolbar = {
      buttonsFront: [
        { name: "Save", event: "save", icon: "checkmark" },
        { name: "Cancel", event: "cancel", icon: "remove" },
        { name: "Close", event: "close", icon: "power" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Transmit to CODA Config",
    };

    this.codaForm = fb.group({
      reportType: ['SYSTEM_LOG', Validators.required],
      email: ['', Validators.email],
      time: ['']
    })
  }

  ngOnInit() {
    this.getSavedInfo();
  }

  getSavedInfo() {
    let self = this;
    this.loading = true;
    this.genericService.GET(
      this.configService.config.BASE_API.toString() + '/invoice/getCODATransmissionConfig'
      // 'http://localhost:12345' + '/invoice/getCODATransmissionConfig'
    ).subscribe(resp => {
      self.loading = false;

      const respObj = resp.json();
      console.log('getSavedInfo', respObj);

      if (respObj && respObj.data) {
        self.scheduleFrequency = respObj.data.scheduleFrequency;
        self.reportDetails = respObj.data.reportDetails;
        self.codaForm.controls['time'].setValue(respObj.data.scheduleDetails);
        self.codaForm.controls['reportType'].setValue(respObj.data.reportType);
      }
    });
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "save":
        this.handleSaveForm();
        break;
      case "cancel":
        this.onCancel();
        break;
      case "close":
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  onCancel(){
    // handel when cancel
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event) { }

  handleSaveForm() {
    let dataToSave = {
      ...new CODATransmissionConfig(),
      scheduleFrequency: this.scheduleFrequency,
      scheduleDetails: this.codaForm.get('time').value,
      reportType: this.codaForm.get('reportType').value,
      reportDetails: this.reportDetails,
    };

    console.log('dataToSave', dataToSave);

    let self = this;
    this.loading = true;
    this.genericService.POST(
      this.configService.config.BASE_API.toString() + '/invoice/saveCODATransmissionConfig', dataToSave
      // 'http://localhost:12345' + '/invoice/saveCODATransmissionConfig', dataToSave
    ).subscribe(resp => {
      self.loading = false;
      let respObj = resp.json();
      console.log('handleSaveForm', respObj);

      if (respObj && respObj.status === 'ok') {
        self.dialogPlugin.show('information', 'Information',
        'Success.', 'okonly', null);

      } else {
        self.dialogPlugin.show('information', 'Information',
        'Errors occurred.', 'okonly', null);
      }
    });
  }

  handleAddMail() {
    if (this.codaForm.get('email').invalid) {
      return;
    }
    this.reportDetails.push(this.emailAddForm);
    this.setEmailAddForm('');
  }

  handleRemoveMail(mail: string) { 
    const mailSearch = this.reportDetails.findIndex(m => m === mail);
    this.reportDetails.splice(mailSearch, 1);
  }

  setEmailAddForm(value) {
    this.codaForm.controls['email'].setValue(value);
  }
}
