import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, ToolbarPluginComponent, GenericService, ConfigService, GenericUtil, CookieService } from 'sibego-ui-library';
import { LocalMasterCharge, GridPluginComponent2 } from '../shared/index';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-local-master-charge-code-page',
  templateUrl: './local-master-charge-code-page.component.html',
  styleUrls: ['./local-master-charge-code-page.component.css']
})
export class LocalMasterChargeCodePageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('localChargeGrid') localChargeGrid:GridPluginComponent;
  @ViewChild('cbLocalChargeNameHistory') cbLocalChargeNameHistory:ComboPluginComponent;
  @ViewChild('cbCharge') cbCharge:ComboPluginComponent;
  @ViewChild('localChargeGridHistory') localChargeGridHistory:GridPluginComponent2;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = false;
  form = '';
  lock = false;
  officeCode:string = "";

  loading = false;
  isError:boolean =false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingCharge;
  settingGridHistory;
  settingLocalChargeHistory;

  valChargeCode:string = '';
  valChargeName:string = '';
  valChargeId:string = '';
  valChargeCodeHistory:string = '';
  valChargeNameHistory:string = '';
  valChargeIdHistory:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';
  validatorRules={};

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model =new LocalMasterCharge;

setValidatorSave() {
  this.clearValidatorError();
  this.validatorRules = {
    lChargeName: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Charge Name.'
        }
      ]
    },
    lChargeCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Charge Code.'
        },
      ]
    },
    isGstorZeroRated : {
      rules: [
        {
          type  : 'custom',
          prompt: 'Please select either \'GST\' or \'Zero-Rated\'.'
        },
      ]
    },
  }
}

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService,private router: Router, private cookieService:CookieService) {
    super();

    this.settingGrid = {
      url: '',//this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGenerals/' + this.officeCode,
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Charge Name', field: 'lChargeName', width: 200, type: 'string'},
        {header: 'Charge Code', field: 'lChargeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'GST', field: 'isGst', width: 100},
        {header: 'Zero Rated', field: 'isZeroRated', width: 100},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'chargeName',
      sortingDirection: 'DESC'
    }

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        {display: 'Charge Code', value: 'lChargeCode'},
        {display: 'Charge Name', value: 'lChargeName'},
        {display: 'Remarks', value: 'remarks'},
        {display: 'GST', value: 'isGst'},
        {display: 'Zero Rated', value: 'isZeroRated'},
        {display: 'User Created', value: 'userCreated'},
        {display: 'Date Created', value: 'dateCreated'},
        {display: 'User Modified', value: 'userModified'},
        {display: 'Date Modified', value: 'dateModified'},
        {display: 'User Invalid', value: 'userInvalid'},
        {display: 'Date Invalid', value: 'dateInvalid'},
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" }
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingCharge = {
      id          : 'cbCharge',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCharges/findByComboBoxControlAll/chargeCodeOrName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'chargeName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Charge Name', field: 'chargeName', width: 200},
        {header: 'Charge Code', field: 'chargeCode', width: 200},
      ]
    }

    this.settingGridHistory={
      url: this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Charge Id', field:'lChargeId', width:200},
        {header: 'Charge Name', field: 'lChargeName', width: 200, type: 'string'},
        {header: 'Charge Code', field: 'lChargeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'GST', field: 'isGst', width: 100},
        {header: 'Xero Rate', field: 'isZeroRated', width: 100},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true
    }

    this.settingLocalChargeHistory = {
      id          : 'cbLocalChargeNameHistory',
      type        : 'search enter', // search | select | select input
      url         : '',//this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGeneralHistory'+this.officeCode+'/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'lchargeName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Charge Id', field: 'lchargeId', width:50},
        {header: 'Charge Name', field: 'lchargeName', width: 250},
        {header: 'Charge Code', field: 'lchargeCode', width: 150},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }
  }
  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  firstInit(){
    this.localChargeGrid.search = '*/*/*';
    this.localChargeGrid.onFirst();

  }

  ngAfterViewInit() {
    this.firstInit();
    this.officeCode = this.cookieService.getDefaultLocationCode().toString().trim();
    this.localChargeGrid.url = this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGeneral/' + this.officeCode;
    this.localChargeGrid.loadData();

    this.cbLocalChargeNameHistory.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGeneralHistory/'+this.officeCode+'/{query}')

    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];

  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }
  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal("setting", "closable", false).modal('show');
  }

  toolbarEvent(event) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
        case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        //this.handlePrint();
        break;
      case 'save':
      console.log("SAVE NETI");
        if(this.model.lChargeId != ""){
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;

      default:
      let str:string = event;
      if(str.indexOf('search') > -1 ){
        this.localChargeGrid.search = str.replace("search:","");
        this.localChargeGrid.onFirst();
        this.handleCancel();
      }else{
        this.handleCancel();
      }
      break;
    }
  }

  infoGrid(event) {
    this.info = event;
  }

  handleDelete()
  {
    // handle delete event
    // this.loading = true;
    // let deleteList = [];
    // this.localChargeGrid.getSelectedValues().forEach((element) => {
    //   var deleteObj = {"lChargeId":"","lChargeCode":""};
    //   deleteObj.lChargeId = element.lChargeId;
    //   deleteObj.lChargeCode = element.lChargeCode;
    //   deleteList.push(deleteObj);
    // });
    // this.genericService
    //   .POST(
    //     this.configService.config.BASE_API.toString() +
    //       "/MasterBookings/checkingListLocalMasterChargeCodeUsed",
    //       deleteList
    //   )
    //   .subscribe(
    //     (resp) => {
    //       if (resp.status == 200) {

    //         let dataRes = resp.json();
    //         console.log(dataRes['totalUsed']);
    //         if (dataRes['totalUsed'] == 0) {
    //           this.loading = false;
    //           this.message(
    //             "delete",
    //             "Deletion Record",
    //             "Confirm to delete record(s)?",
    //             "yesno",
    //             { yes: "this.deleteEvent()", no: "this.loading = false;" }
    //           );
    //         } else {
    //           this.message(
    //             "information",
    //             "Information",
    //             "Record being utilized, cannot be deleted. ",
    //             "okonly",
    //             { ok: "this.loading=false;" }
    //           );
    //         }

    //       }
    //     },
    //     (error) => {
    //       this.loading = false;
    //       this.info = error.message;
    //     }
    //   );
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  deleteEvent(){
    console.log("Delete Event")
    this.loading = true;
    let deleteList = [];
    this.localChargeGrid.getSelectedValues().forEach(element => {
      deleteList.push(element.lChargeId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"lChargeId":[]}
    paramsDelete.lChargeId = deleteList;
    //this.setGridEvent = '';
    // let deleteList = [];
    // this.localChargeGrid.getSelectedValues().forEach(element => {
    //   deleteList.push(element.lChargeId);
    // });
    this.genericService.POST(
      this.configService.config.BASE_API.toString() +
      // '/LocalMasterCharges/delete',
      // deleteList
      "/LocalMasterCharges/softDelete",
        paramsDelete
      )
    .subscribe((resp) =>{
      if(resp.status == 200){
        this.model = new LocalMasterCharge;
        //  this.selectList = [];
        this.loading = false;
        this.localChargeGrid.clearSelectedValues();
        this.localChargeGrid.onFirst();
        //this.setGridEvent = 'refresh';
        this.loading=true;
        this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    this.localChargeGrid.clearSelectedValues();
    this.localChargeGrid.loadData();
    this.cbCharge.clearSelect();
    this.cbCharge.setValue("");
    this.localChargeGrid.onFirst();
  }

  handleCreate()
  {
    // handle create event

    this.modeForm = true;
    this.lock = false;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";

  }



  generateChargeHistoryReport(event){
    //this.valHistoryStartDate= $('#logHistoryChargeStartDate').find('#datePicker').val();
    var splitUrl = this.configService.config.getPDFUrl.split(":");
     window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/64/History-Local-Charge-' + this.valChargeIdHistory + '-' + this.valChargeCodeHistory +'/'+this.valChargeIdHistory + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);

  }

  retrieveHistory(event){
    this.localChargeGridHistory.loadData();
  }

  clearValidatorError(){
    this.model['error-lChargeName'] = '';
    this.model['error-lChargeCode'] = '';
    this.model['error-isGstorZeroRated'] = '';
    console.log("ERRORNYA: "+this.model['error-isGstorZeroRated']);
    console.log("STATUS ERROR: "+this.isError);
  }

  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.model =  new LocalMasterCharge;
    this.form = '';


      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";


    this.localChargeGrid.clearSelectedValues();
    this.localChargeGrid.loadData();

    if(this.valChargeId != '') {
      this.valChargeCode = '';
      this.valChargeId = '';

    }
    this.cbCharge.clearSelect();
    this.cbCharge.setValue("");
    this.isError=false;

  }

  saveEvent(){
    //console.log("SAVE EVENT");
    this.loading = true;
    //console.log("SAVE EVENTSS");
    this.model.lOfficeCode = this.officeCode;
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/LocalMasterCharges/save',this.model).subscribe((resp) =>{
      // console.log('STATUS=' + resp.status);
      // console.log('MESSAGE=' + resp.json()['message']);
      if(resp.status == 200 && resp.json()['message'] == 'OK'){
        // console.log("ENTER SAVE");
        this.model = new LocalMasterCharge;
        this.loading = false;
        this.info = 'save successfully';
        //this.setGridEvent = 'refresh';
        this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
      }else{
        this.loading = false;
        this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
      this.loading = false;
    });
  }

  isGSTChange(event){
    this.model.isZeroRated=false;

  }

  isZeroRatedChange(event){
    this.model.isGst=false;

  }

  handleSave(){
    this.setValidatorSave();
    this.model['isGstorZeroRated']= this.checkValidityGstOrZeroRated();
    this.isError = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if(!this.isError){
      console.log("Handle save 3");

      /*if(!this.model.isMainCharge && !this.model.isSurcharge && !this.model.isAdhoc && !this.model.isShownInReport && !this.model.isManifested){
        this.message('warning','Error Input', 'Please select at least one (1) option.', 'okonly',{ok:'this.loading=false;'});
      }else {*/
        // console.log("PSI Date => " + this.genericUtil.getPSIDate());
        this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.saveEvent();

       /* this.message('save', 'Saving data', 'Do you want to save the record? ', 'yesno', {
          yes: 'this.saveEvent()',
          no: 'this.loading = false;'
        });*/
     // }
    }else{
      this.loading = false;
    }
  }

  valueCharge(event){
    console.log("NETI CODE : "+event.chargeId);
    this.valChargeCode=event.chargeCode;
    this.valChargeId=event.chargeId;
    this.model["lChargeCode"] = this.valChargeCode;
    this.model["lChargeName"] = event.chargeName;

  }

  valueLocalChargeHistory(event){
    this.valChargeCodeHistory=event.lchargeCode;
    this.valChargeIdHistory=event.lchargeId;
    // console.log("curr code= " + event["chargeCode"]);

    //refresh the history chargeGrid accordingly
    this.localChargeGridHistory.onHistory(this.valChargeIdHistory);
  }
  checkValidityGstOrZeroRated():Boolean{
    let result:Boolean = false;
    if (this.model.isGst == false && this.model.isZeroRated == false){
      this.model['error-isGstorZeroRated'] = 'Please select either \'GST\' or \'Zero-Rated\'.';

      return true;
    }
    console.log("CEK RETURN RESULT: "+result);
    return result;


  }



  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";


    // console.log("chargeGrid-> " + this.chargeGrid.getSelectedValues().length);

    if(this.localChargeGrid.getSelectedValues().length >= 1){
      this.localChargeGrid.onSortDir("dateModified","DESC");
    }else{
      this.localChargeGrid.onSortDir("dateCreated","DESC");
    }

    this.localChargeGrid.clearSelectedValues();
    this.cbCharge.clearSelect();
    this.cbCharge.setValue("");
    this.localChargeGrid.url = this.configService.config.BASE_API.toString() + '/LocalMasterCharges/findGeneral/' + this.officeCode;
    this.localChargeGrid.onFirst();
  }

  localChargeGridEvent(event) {
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.localChargeGrid.getSelectedValues().length > 0){
            this.disableToolbarButtons = "";
        }else{
            this.disableToolbarButtons = "update,delete";
        }
        break;
      case 'click' :
        if(this.localChargeGrid.getSelectedValues().length > 0){
            this.disableToolbarButtons = "";
        }else{
            this.disableToolbarButtons = "update,delete";
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.localChargeGrid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){
            let dt = this.localChargeGrid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {

              this.disableToolbarButtons = "";

              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.setData();
              this.lock = true;
            }
          }

        }

        break;
    }
  }

  setData(){
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.localChargeGrid.getSelectedValues()[0];

    this.model = new LocalMasterCharge;

    // console.log(this.model);


    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }
    this.model.lChargeId = String(data.lChargeId).trim().toUpperCase();
    this.model.lChargeCode = String(data.lChargeCode).trim().toUpperCase();
    this.model.lChargeName = String(data.lChargeName).trim().toUpperCase().replace(/&NBSP;/g," ");
    this.cbCharge.setValue(this.model.lChargeName.toString());
    this.valChargeCode = this.model.lChargeCode.toString();

    if(data.isGst=='Y'){
      this.model.isGst=true;
    }else{
      this.model.isGst=false;
    }

    if(data.isZeroRated=='Y'){
      this.model.isZeroRated=true;
    }else{
      this.model.isZeroRated=false;
    }

    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.lOfficeCode = String(data.lOfficeCode).trim().toUpperCase();

  }

  handleUpdate(){

    // handle update event
    let dt = this.localChargeGrid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData();
      this.lock=false;
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  localChargeGridEventHistory(event){}
  infoGridHistory(event){}

  markRedRow(dataNo){
    var i = this.localChargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.localChargeGrid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
