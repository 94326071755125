export class transhipmentModel{
    loadPortCode:string = "";
    loadPortName:string = "";
    nextPortCode:string = "";
    nextPortName:string = "";
    vesselId:string = "";
    vesselName:string = "";
    vesselCode:string = "";
    baseVoyage:string = "";    

    constructor(){

    }
}