import { Component, HostListener } from '@angular/core';
import { ConfigService } from 'sibego-ui-library';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private configService:ConfigService){
    // // console.log(configService.config);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    return false;
  }

  // Not - working https://stackoverflow.com/questions/65993066/how-to-get-browser-back-action-with-confirm-message-in-angular-10
  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: any) {
  //   var stayOnPage = event.currentTarget.confirm("Changes you made may not be saved.");
  //   if (!stayOnPage) {        
  //      event.preventDefault();
  //      return false;
  //   } else {
    
  //   }
  // }

}
