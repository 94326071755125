<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field">
        <div class="two fields">
          <div class="twelve wide field">
            <fieldset  style="border: 1px solid lightgray;">
              <div class="field">
                <div class="ui radio checkbox" >
                  <input type="checkbox"  name="blWise" tabindex="0">
                  <label>BL Wise</label>
                </div> 
              </div><br>
              <div class="field">
                <div class="ui radio checkbox" >
                  <input type="checkbox"  name="vesselWise" tabindex="0">
                  <label>Vessel Wise</label>
                </div> 
              </div><br>
              <div class="field">
                <fieldset style="border: 1px solid lightgray;">
                  <legend style="font-weight:bold;color:dodgerblue;">Vessel Wise</legend>
                  <div class="field">
                    <div class="four fields">
                      <div class="field">
                        <label>Vessel</label>
                        <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)" style="width:1400px;"></combo-plugin>
                      </div>
                      <div class="field" style ="">
                        <label>Voyage</label>
                        <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" style="width:100%;"></combo-plugin>
                      </div>
                      <div class="field" style ="" >
                        <label>Bound</label>
                        <input type="text" name="bound"  placeholder="Bound" [(ngModel)]="model['bound']" >
                      </div>
                      <div class="field">            
                        <div class="ui test checkbox" >
                          <input type="checkbox"  name="chUnfinished" tabindex="0" class="hidden">
                            <label>Unfinished</label>
                        </div>                      
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <div class="two fields">
                      <div class="field">
                        <fieldset style="width: 100%;border: 1px solid lightgray;">
                          <legend style="font-weight:bold;color:dodgerblue;">Selection Criteria</legend>
                          <div class="field">
                              <label>Place of Receipt</label>
                              <combo-plugin #cbPOR [settings]="settingPOR" (change)="changeEventPOR($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field">
                              <label>Port of Loading</label>
                              <combo-plugin #cbLoadPort [settings]="settingLoadPort" (change)="changeEventLoadPort($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field">
                              <label>Port of Discharge</label>
                              <combo-plugin #cbDischargePort [settings]="settingDischargePort" (change)="changeEventDischargePort($event)" style="width:100%;"></combo-plugin>
                          </div>                          
                          <div class="field">
                              <label>Port of Delivery</label>
                              <combo-plugin #cbPOD [settings]="settingPOD" (change)="changeEventPOD($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field">
                              <label>Port of Transhipment</label>
                              <combo-plugin #cbTranshipment [settings]="settingTranshipment" (change)="changeEventTranshipment($event)" style="width:100%;"></combo-plugin>
                          </div>
                          <div class="field">
                              <label>Final Destination</label>
                              <combo-plugin #cbFinDes [settings]="settingFinDes" (change)="changeEventFinDes($event)" style="width:100%;"></combo-plugin>
                          </div>
                        </fieldset>
                      </div>


                      <div class="field">
                        <div class="field">
                        <fieldset style="width: 100%;border: 1px solid lightgray;">
                          <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                          <div class="field">            
                            <div class="ui test checkbox" >
                              <input type="checkbox"  name="chSoc" tabindex="0" class="hidden">
                                <label>SOC</label>
                            </div>                      
                          </div><br>
                          <div class="field">            
                            <div class="ui test checkbox" >
                              <input type="checkbox"  name="chSocTs" tabindex="0" class="hidden">
                                <label>SOC T/S</label>
                            </div>                      
                          </div><br>                          
                          <div class="field">            
                            <div class="ui test checkbox" >
                              <input type="checkbox"  name="chSlot" tabindex="0" class="hidden">
                                <label>Slot</label>
                            </div>                      
                          </div><br>
                          <div class="field">            
                            <div class="ui test checkbox" >
                              <input type="checkbox"  name="chCoc" tabindex="0" class="hidden">
                                <label>COC</label>
                            </div>                      
                          </div><br>
                          <div class="field">            
                            <div class="ui test checkbox" >
                              <input type="checkbox"  name="chCocTs" tabindex="0" class="hidden">
                                <label>COC T/S</label>
                            </div>                      
                          </div><br>
                        </fieldset>
                        </div><br>

                        <div class="field" style="margin-top: 2px">
                          <fieldset style="width: 100%;border: 1px solid lightgray;">
                          <legend style="font-weight:bold;color:dodgerblue;">Container Ownership</legend>
                          <div class="field">            
                            <div class="ui radio checkbox" >
                              <input type="checkbox"  name="chAll" tabindex="0" class="hidden">
                                <label>ALL</label>
                            </div>                      
                          </div><br>
                          <div class="field">            
                            <div class="ui radio checkbox" >
                              <input type="checkbox"  name="chFull" tabindex="0" class="hidden">
                                <label>Full</label>
                            </div>                      
                          </div><br>                          
                          <div class="field">            
                            <div class="ui radio checkbox" >
                              <input type="checkbox"  name="chEmpty" tabindex="0" class="hidden">
                                <label>Empty</label>
                            </div>                      
                          </div><br>
                          </fieldset>
                        </div>
                      </div>

                    </div>
                  </div>
                </fieldset>
              </div>
            </fieldset>
          </div>

          <div class="field">
            <div class="field">            
              <div class="ui test checkbox" >
                <input type="checkbox"  name="chSilent" tabindex="0" class="hidden">
                <label>Silent Mode</label>
              </div>                      
            </div><br>
            <div class="field">
              <grid-plugin id="grid" #grid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
