<div class="ui raised segment">
  <div class="ui divided items">
      <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>       
      <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>

      <form class="ui form">
        <br>
        <div id="searchForm">        
            <div class="fields" style="margin-bottom: 5px">              
                <div class="four wide field">
                    <label>Vessel</label>
                    <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"></combo-plugin>
                </div>
                <div class="four wide field">
                    <label>Voyage</label>
                    <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)" ></combo-plugin>
                </div>
                <div class="four wide field">
                    <label>Bound</label>
                    <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['vesselBound']" style="width:100px">
                </div>
                <div class="four wide field">&nbsp;</div>
            </div>
        </div>
        <div id="resultGrid" style="margin-top:10px" [style.display]="modeToolbar?'none':'box'">
            <grid-plugin id="cllDGResultGrid" #cllGrid [settings]="settingGrid" (gridEvent)="gridEvent($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
            <!-- div class="ui con tiny buttons" style="vertical-align: top;margin-bottom: 100px;">
                <button id="addContainer" class="ui button" style="margin-bottom: 5px;">
                    <i class="plus icon"></i>
                </button>
                <button class="ui button">
                    <i class="minus icon"></i>
                </button>
            </div -->            
        </div>
        <div id="editForm" style="display:none">
            <div class="fields" style="margin-bottom: 5px">
                <div class="four wide field">
                    <label>IMO</label>
                    <input type="number" name="imo" [(ngModel)]="modelForm['imo']" [disabled]="disIndicator">
                </div>
                <div class="four wide field">
                    <label>Un No</label>
                    <input type="number" name="unno" [(ngModel)]="modelForm['unno']" [disabled]="disIndicator">
                </div>
                <div class="eight wide field">
                    <label style="margin-bottom:3px;">Flash Point</label>
                    <div class="inline field">
                        <select #flashSign id="flash-sign" style="font-size: 13px;height:33px;margin-right:3px;" (change)="eventSelect(flashSign, 'flashSign')" [disabled]="disIndicator">
                            <option style="font-size: 10px" value="+">+</option>
                            <option style="font-size: 10px" value="-">-</option>
                        </select>
                        <input type="number" name="flashtemp"  style="height:33px;margin-right:3px" [(ngModel)]="modelForm['flashPointTemp']" [disabled]="disIndicator">
                        <select #flashDegree id="flash-Degreee" style="font-size: 13px;height:33px;margin-right:3px" (change)="eventSelect(flashDegree, 'flashScale')" [disabled]="disIndicator">
                            <option style="font-size: 10px" value="C">C</option>
                            <option style="font-size: 10px" value="F">F</option>
                        </select>
                    </div>                    
                </div>                                                              
            </div>
            <div class="fields" style="margin-bottom: 5px">
                <div class="four wide field">
                    <label>Package Type</label>
                    <combo-plugin #packageType [settings]="settingPackageType" (change)="comboEvent($event,'PackageType')"></combo-plugin>
                </div>                  
                <div class="four wide field">
                    <label>No of PKG</label>
                    <input type="number" name="noOfPkg" [(ngModel)]="modelForm['noOfPackage']">
                </div>
                <div class="four wide field">
                    <label>WT</label>
                    <input type="number" name="wt" [(ngModel)]="modelForm['dgWt']">
                </div>
            </div>
            <div class="four wide field">
                <label>Product Name</label>
                <input type="text" name="productName" [(ngModel)]="modelForm['productName']" style="width:310px">
            </div>
            <div class="four wide field">
                <label>Proper Shipping Name</label>
                <input type="text" name="properShippingName" [(ngModel)]="modelForm['properShippingName']" style="width:310px">
            </div>
        </div>
        <div id="editFormGrid" style="display:none;">
            <grid-plugin id="cllDGDetail" #cllGridDG [settings]="settingGridDG" (gridEvent)="gridEventDG($event)" (infoGrid)="infoGrid($event)"></grid-plugin>
        </div>  
      </form>      
  </div>
</div>



<footer-plugin [info]="info"></footer-plugin>
