<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)"></toolbar-plugin>
  <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index:1">
    <div class="ui medium text loader">Loading</div>
  </div>

  <div class="ui error message" [class.hidden]="isError == false">
    <ul>
      <li [class.hidden]="model['error-vesselCode'] == null || model['error-vesselCode'] == ''">
        {{model['error-vesselCode']}}
      </li>
      <li [class.hidden]="model['error-voyage'] == null || model['error-voyage'] == ''">
        {{model['error-voyage']}}
      </li>
      <!-- <li [class.hidden]="model['error-bound'] == null || model['error-bound'] == ''">
        {{model['error-bound']}}
      </li> -->
      <li [class.hidden]="model['error-bPot1Code'] == null || model['error-bPot1Code'] == ''">
        {{model['error-bPot1Code']}}
      </li>
    </ul>
  </div>

  <div id='container'>
    <dialog-plugin #dialogPortPair [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <button class="ui button" style="display:none;"></button>
      <br />
      <div class="field">
        <div class="five fields">
          <div class="six wide field">

            <div class="ui raised segment">
              <a class="ui red ribbon label">Connecting Vessel</a>
              <div class="three fields">
                <div style="width: 30%;" class="required field" [class.error]="
                    model['error-vesselCode'] != null &&
                    model['error-vesselCode'] != '' &&
                    model['error-vesselCode'] != undefined
                  ">
                  <label>Vessel</label>
                  <combo-plugin #cbVessel [settings]="settingVessel" (change)="changeEventVessel($event)"
                    style="width:100%;"></combo-plugin>
                </div>
                <div style="width: 30%;" class="required field" [class.error]="
                    model['error-voyage'] != null &&
                    model['error-voyage'] != '' &&
                    model['error-voyage'] != undefined
                  ">
                  <label>Voyage</label>
                  <combo-plugin #cbVoyage [settings]="settingVoyage" (change)="changeEventVoyage($event)"
                    style="width:100%;"></combo-plugin>
                </div>
                <div style="width: 20%;" class="field required" [class.error]="
                    model['error-bound'] != null &&
                    model['error-bound'] != '' &&
                    model['error-bound'] != undefined
                  ">
                  <label>Bound</label>
                  <input type="text" name="bound" style="text-transform: uppercase" placeholder="Bound"
                    [value]="model.bound" (change)="changeBound($event)"
                    (keypress)="formValidation.BoundValidator($event)" disabled maxlength="1" />
                </div>
                <div style="width: 20%;" class="field"
                  [class.tooltip]="model['tsServiceCode'] != 'null' && model['tsServiceCode'] != ''">
                  <label>Service</label>
                  <input type="text" name="service" placeholder="" [(ngModel)]="model['tsServiceCode']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsServiceCode"] }}</span>
                </div>
              </div>
            </div>

          </div>
          <!--
            <div class="one wide field">
              <div class="ui raised segment" style="padding-top: 40px;">
                <div class="one fields">
                  <div class="field required" [class.error]="
                    model['error-bound'] != null &&
                    model['error-bound'] != '' &&
                    model['error-bound'] != undefined
                  ">
                    <label>Bound</label>
                    <input
                      type="text"
                      name="bound"
                      style="text-transform: uppercase"
                      placeholder="Bound"
                      [value]="model.bound"
                      (change)="changeBound($event)"
                      (keypress)="formValidation.BoundValidator($event)"
                      disabled
                      maxlength="1" />
                  </div>
                </div>
              </div>
            </div> -->

          <div class="six wide field">
            <div class="ui raised segment">
              <a class="ui red ribbon label">POT</a>
              <div class="three fields">
                <div class="required field" [class.error]="
                    model['error-bPot1Code'] != null &&
                    model['error-bPot1Code'] != '' &&
                    model['error-bPot1Code'] != undefined
                  ">
                  <label>POT</label>
                  <combo-plugin #cbPOT1 [settings]="settingPOT1" (change)="changeEventPOT1($event)" style="width: 100%"
                    (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                    (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)">
                  </combo-plugin>
                </div>
                <!-- <div class="field">
                    <label>Service</label>
                    <input type="text" name="service" placeholder="" [(ngModel)]="model['tsServiceCode']" readonly style="background-color: lightgray">
                  </div> -->
                <div class="field" [class.tooltip]="
                  model['tsEta'] != '' && model['tsEta'] != 'null'
                ">
                  <label>ETA</label>
                  <input type="text" name="eta" placeholder="" [(ngModel)]="model['tsEta']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsEta"] }}</span>
                </div>
                <div class="field" [class.tooltip]="
                  model['tsEtd'] != '' && model['tsEtd'] != 'null'
                ">
                  <label>ETD</label>
                  <input type="text" name="etd" placeholder="" [(ngModel)]="model['tsEtd']" readonly
                    style="background-color: lightgray">
                  <span class="tooltiptext">{{ model["tsEtd"] }}</span>
                </div>
              </div>
            </div>

          </div>

          <div class="wide field">
            <div class="ui raised segment" style="padding-top: 40px;height: 100%;">
              <!-- <a class="ui red ribbon label">Container ownership</a> -->
              <div class="field">
                <div class="ui radio checkbox disabled">
                  <input type="radio" name="containerOwner" [checked]="radioContainerOwnerSOC == 'Y'"
                    (change)="changeEventTransmit($event)" value="SOC" disabled>
                  <label>SOC</label>
                </div>
                <div class="ui radio checkbox">
                  <input type="radio" name="containerOwner" [checked]="radioContainerOwnerCOC == 'Y'"
                    (change)="changeEventTransmit($event)" value="COC">
                  <label style="color: green;font-weight: bold;">COC</label>
                </div>
              </div>
            </div>
          </div>


          <div class="three wide field">
            <div class="ui raised segment" style="padding-top: 40px;">
              <div class="three fields">
                <div class="field">
                  <label style="color: green;">TOTAL:</label>
                  <input type="text" name="total" [(ngModel)]="model['tsTotal']" style="background-color: lightgray"
                    disabled>
                </div>

                <div class="field">
                  <label style="color: green;">LOCAL:</label>
                  <input type="text" name="local" [(ngModel)]="model['tsLocal']" style="background-color: lightgray"
                    disabled>
                </div>

                <div class="field">
                  <label style="color: green;">T/S:</label>
                  <input type="text" name="T/S" [(ngModel)]="model['tsTS']" style="background-color: lightgray"
                    disabled>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="six fields">
          <div class="six wide field">
            <div class="ui raised segment">
              <a class="ui red ribbon label">Rotation</a>
              <div class="one fields">
                <form class="field" style="width: 100%;padding-top: 5px;">
                  <fieldset style="width: 100%; border: 1px solid gray; min-height: 33px;">
                    <label style="float: left; font-weight: bold">{{ model.tsRotation }}</label>
                  </fieldset>
                  <!-- <input type="text" style="float: left; font-weight: bold" [value]="model.tsRotation" disabled /> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width:100%; margin-top: 50px;">
        <app-custom-ag-grid-angular #topGrid id="transaction-ts-port-pair-allocation-page-v2"
          [getRowStyle]="getRowStyle" [rowData]="rowData" [rowMultiSelectWithClick]="true" [defaultColumnDefs]="defaultColumnDefs"
          [enableSorting]="true" [enableFilter]="true" [enableColResize]="true" (gridReady)="onGridReady($event)"
          (rowDoubleClicked)="rowDoubleClick($event)" (selectionChanged)="onSelectionChanged($event)"
          [rowSelection]="'multiple'" [pinnedBottomRowData]="pinnedBottomRowData" [isRowSelectable]="isRowSelectable">
        </app-custom-ag-grid-angular>
      </div>

      <!-- form edit allocation -->
      <div id="addAllocDialog" class="add-alloc-dialog">
        <!-- <label>Allocation Entry</label> -->
        <!-- <br><br> -->
        <div class="ui error message" [class.hidden]="isErrorDetail == false">
          <ul>
            <li [class.hidden]="modelDetail['error-polCode'] == null || modelDetail['error-polCode'] == ''">
              {{modelDetail['error-polCode']}}
            </li>
            <li [class.hidden]="modelDetail['error-podCode'] == null || modelDetail['error-podCode'] == ''">
              {{modelDetail['error-podCode']}}
            </li>
            <li [class.hidden]="modelDetail['error-alloted'] == null || modelDetail['error-alloted'] == ''">
              {{modelDetail['error-alloted']}}
            </li>
            <li [class.hidden]="modelDetail['error-api'] == null || modelDetail['error-api'] == ''">
              {{modelDetail['error-api']}}
            </li>
          </ul>
        </div>
        <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
          <form class="ui form">
            <div class="six wide fields">
              <div class="one field" style="width:33.3%;">
                <label>POT</label>
                <combo-plugin #cbPOT1Insert [settings]="settingPOT1Insert" (change)="changeEventPOT1($event)"
                  style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)"
                  [disableCombo]="true">
                </combo-plugin>
              </div>
            </div>
            <div class="six wide fields">
              <div class="one field" style="width:100%;">
                <fieldset style="width: 100%; border: 1px solid gray">
                  <legend style="font-weight: bold; color: dodgerblue">
                    Rotation
                  </legend>
                  <label style="float: left; font-weight: bold">{{ model.tsRotation }}</label>
                </fieldset>
              </div>
            </div>
            <div class="six wide fields">
              <div class="one field required" style="width:33.3%;">
                <label>Load Port</label>
                <combo-plugin #cbLoadPortInsert [settings]="settingLoadPortInsert"
                  (change)="changeEventLoadPortInsert($event)" style="width:100%;"
                  [disableCombo]="isRowSaved"></combo-plugin>
              </div>
              <div class="one field" style="width:33.3%;">
                <label>Terminal (POL)</label>
                <combo-plugin #cbTerminalLoadPort [settings]="settingTerminalLoadPort"
                  (change)="changeEventTerminalLoadPort($event)" style="width:100%;"
                  [disableCombo]="lockPOLTerminal"></combo-plugin>
              </div>
              <div class="one field required" style="width:33.3%;">
                <label>Discharge Port</label>
                <combo-plugin #cbDischargePort [settings]="settingDischargePort"
                  (change)="changeEventDischargePort($event)" style="width:100%;"
                  [disableCombo]="isRowSaved && isPodAllowedToChangedAfterSaved"></combo-plugin>
              </div>
              <!--
                  <div class="one field" style="width:31%;">
                      <label>Port of Discharge Terminal</label>
                      <combo-plugin #cbDischargePortTerminal [settings]="settingDischargePortTerminal" [disableCombo]="lockPODTerminal" (change)="changeEventDischargePortTerminal($event)" style="width:100%;"></combo-plugin>
                  </div>
                  -->
            </div>
            <div class="six wide fields">
              <div class="one field required" style="width:33.3%;">
                <label>Allocated (Teus)</label>
                <input type="number" name="alloted" placeholder="Allocated" min="0" maxlength="5"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  (change)="changeEventAllocated($event)" (keyup)="changeEventAllocated($event);"
                  [(ngModel)]="modelDetail['alloted']">
              </div>
            </div>
            <div class="six wide fields" style="margin-top: 2.5rem !important;">
              <div class="two field" style="width:100%;">
                <button class="tiny ui positive button" (click)="onInsert();">Insert</button>
                <button class="tiny ui button" (click)="onCancel();">Cancel</button>
                <button class="tiny ui negative button" (click)="onClose();">Close</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- end of dialog add allocation -->

      <!-- form Copy allocation -->
      <div id="copyAllocDialog" class="ui small modal" style="margin-right:400px;padding:1%;">
        <label>Copy Allocation From</label>
        <br><br>
        <div class="ui error message" [class.hidden]="isErrorCopy == false">
          <ul>
            <li [class.hidden]="modelCopy['error-vesselCode'] == null || modelCopy['error-vesselCode'] == ''">
              {{modelCopy['error-vesselCode']}}
            </li>
            <li [class.hidden]="modelCopy['error-voyage'] == null || modelCopy['error-voyage'] == ''">
              {{modelCopy['error-voyage']}}
            </li>
          </ul>
        </div>
        <div class="ui raised segment" style="padding-left:2%;padding-right:2%;">
          <form class="ui form">
            <div class="six wide fields">
              <div class="one field" style="width:31%;">
                <label>POT</label>
                <combo-plugin #cbPOT1Copy [settings]="settingPOT1" (change)="changeEventPOT1($event)"
                  style="width: 100%"
                  (keypress)="formValidation.keypressEventRegexWith($event, model, 'settingPOT1', '[a-zA-Z0-9-() ]')"
                  (paste)="formValidation.pasteEventRegexWith($event, model, 'settingPOT1', '[^a-zA-Z0-9-() ]', 50)"
                  [disableCombo]="true">
                </combo-plugin>
              </div>
            </div>
            <div class="six wide fields">
              <div class="one field" style="width:31%;">
                <label>Vessel</label>
                <combo-plugin #cbVesselCopy [settings]="settingVesselCopy" (change)="changeEventVesselCopy($event)"
                  style="width:100%;"></combo-plugin>
              </div>
              <div class="one field" style="width:31%;">
                <label>Voyage</label>
                <combo-plugin #cbVoyageCopy [settings]="settingVoyageCopy" (change)="changeEventVoyageCopy($event)"
                  style="width:100%;"></combo-plugin>
              </div>
              <div class="one field" style="width:31%;">
                <label>Bound</label>
                <input type="text" name="bound" style="width:100%;" [value]="modelCopy.bound"
                  [(ngModel)]="modelCopy.bound" (change)="changeBoundCopy($event)" disabled>
              </div>
            </div>
            <div class="six wide fields">
              <div class="two field" style="width:100%;">
                <button class="tiny ui positive button" (click)="onSaveCopy();">Copy</button>
                <button class="tiny ui button" (click)="onCancelCopy();">Cancel</button>
                <button class="tiny ui negative button" (click)="onCloseCopy();">Close</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- end of dialog copy allocation -->


      <div id="uploadExcelDialog" class="ui small modal" style="margin-right:400px;padding:1%;">
       
        <button class="ui button" [class.disabled]="isDisableProcessButton" (click)="processUploadExcelDialog()">
          <i class="upload icon"></i>
          Process
        </button>
        <button class="ui button" (click)="cancelUploadExcelDialog()">
          <i class="close icon"></i>
          Cancel
        </button>
        <button class="ui button" (click)="closeUploadExcelDialog()">
          <i class="power of icon"></i>
          Close
        </button>
        <br>
        <br>
        <form class="ui form" [class.loading]="loadingUpload == true">
          <div class="field">
            <label style="font-size:12px">FILE to be Processed</label>
            <div class="inline field">
              <upload-plugin #ppaFileUpload [settings]="settingUploadPPAFile" (eventUpload)="uploadEventPPAFile($event)"></upload-plugin>
            </div>
          </div>
        </form>
      </div>
      
    </form>
  </div>
</form>

<footer-plugin [info]="info"></footer-plugin>
