import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import {
  MSailingSchedule,
  specialCharUtil,
  ScheduleMapping,
} from "../shared/index";
import {
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DatetimePluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";

import FormValidation from "../../utils/formValidation";

declare var $: any;

export class SelectModel {
  code: String = "";
  name: String = "";

  constructor() {}
}

export class DataDetail {
  no: number = 0;
  seqNo: number = 0;
  locationId: string = "";
  terminalId: string = "";
  enumCode: string = "ALL";
  cntStatus: string = "BOTH";
  operatorCode: string = "";
  status: string = "";
  //statusbuttonR:boolean=true;
  //statusbuttonD:boolean=false;
  stoppedBy: string = "";
  stoppedTime: string = "";
  resumedBy: string = "";
  resumedTime: string = "";
  locationName: string = "ALL";
  locationCode: string = "ALL";
  dateCreated: string = "";
  //terminalCode:string = 'ALL';
  //terminalName:string = 'ALL';
}

export class dataVoyageVessel {
  bOceanVesselId: string = "";
  bOceanVesselCode: string = "";
  bOceanVesselVoyage: string = "";
  bOfficeCode: string = "";
  bOceanVesselBound: string = "";
}

export class Helper {
  createBoundM: string = "N";
  constructor() {}
}

@Component({
  selector: "app-transaction-sailing-schedule-page",
  templateUrl: "./transaction-sailing-schedule-page.component.html",
  styleUrls: ["./transaction-sailing-schedule-page.component.css"],
})
export class TransactionSailingSchedulePageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbNewVessel") cbNewVessel: ComboPluginComponent;
  @ViewChild("cbVesselScheduleMapping")
  cbVesselScheduleMapping: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;
  @ViewChild("cbVoyageScheduleMapping")
  cbVoyageScheduleMapping: ComboPluginComponent;
  @ViewChild("cbNewService") cbNewService: ComboPluginComponent;
  @ViewChild("gridHeader") gridHeader: GridPluginComponent;
  @ViewChild("gridSailingDetail") gridSailingDetail: GridPluginComponent;
  @ViewChild("gridSailingDetail2") gridSailingDetail2: GridPluginComponent;
  @ViewChild("gridSailingDetailModal")
  gridSailingDetailModal: GridPluginComponent;
  @ViewChild("gridSailingDetailSpecial")
  gridSailingDetailSpecial: GridPluginComponent;
  @ViewChild("gridStopBooking") gridStopBooking: GridPluginComponent;
  @ViewChild("dateLocalETA") dateLocalETA: DatetimePluginComponent;
  @ViewChild("dateLocalETD") dateLocalETD: DatetimePluginComponent;
  @ViewChild("blDate") blDate: DatetimePluginComponent;
  @ViewChild("exchangeRateDate") exchangeRateDate: DatetimePluginComponent;

  dialog: any;

  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = "";

  msg = "";
  confirmAction: "save" | "remove" = "save";
  confirmSaved: boolean = false;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";

  disableToolbarButtons = "print";

  modeToolbar = false;
  modeForm = "sailingScheduleFlagStatus";
  lockForm = false;
  lockFormBoundM = false;
  lockLocalETA: boolean = false;
  lockLocalETD: boolean = false;
  lockBLDate: boolean = false;
  lockExchangeRateDate: boolean = false;
  prevSelectedRecord: number = 0;
  extVesselCapacity: number = 0;
  rowStop: number = 0;
  rowPrint: number = 0;

  cntOwnerShip = [
    "SOC",
    "SOC LOCAL",
    "SOC T/S",
    "COC",
    "COC LOCAL",
    "COC T/S",
    "SLOT",
  ];

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingNewVessel;
  settingVesselScheduleMapping;
  settingVoyage;
  settingVoyageScheduleMapping;
  settingNewService;
  settingGridHeader;
  settingGridSailingDetail;
  settingGridSailingDetail2;
  settingGridSailingDetailModal;
  settingGridSailingDetailSpecial;
  settingGridStopBooking;
  settingDateLocalETA;
  settingDateLocalETD;
  settingBLDate;
  settingExchangeRateDate;

  // * Ag Grid Schedule Mapping
  rowSelection = "single";
  rowSelected: any[] = [];
  gridApiScheduleMapping;
  gridColumnApiScheduleMapping;
  columnDefsScheduleMapping;
  rowDataScheduleMapping: any[];
  pinnedBottomRowDataScheduleMapping;

  isLoad: boolean = false;
  isError: boolean = true;
  isErrorScheduleMapping: boolean = false;
  isOpenedRevertFromAllocation: boolean = false;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new MSailingSchedule();
  helper = new Helper();
  modelVoyage = new dataVoyageVessel();
  modelMapping = new ScheduleMapping();

  validatorRules = {};

  //stop booking password
  stopBookingPassword = "";
  stopBookingPasswordResume = "";
  errorStopBookingPassword = "";
  errorStopBookingPasswordResume = "";

  //previous search
  prevVesselId = "";
  prevVesselCode = "";
  prevVoyage = "";
  prevBound = "";
  prevServiceId = "";
  clickIndex: number = 0;

  hitSave = 0;
  lenPortOfCall = 0;
  lenTerminal = 0;

  tempPort = [];

  searchVesselId = "";
  searchVoyage = "";
  searchBound = "";
  voyageVess = "";

  dsPortOfCall: any;
  dsTerminal: any;

  detail = new DataDetail();

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  // * Schedule Mapping Variables
  tabStatus: string = "tab1";
  tabStatus1: string = "active";

  lockMapping = true;
  btnSaveMapping = true;
  btnDeleteMapping = true;
  btnAddMapping = false;
  mappingId: number = 0;
  mappingSailingScheduleId: number = 0;
  mappingSource: string = "";
  mappingVoyage: string = "";
  mappingBound: string = "";
  mappingIsValid: string = "";
  serviceId: string = "";

  bodyValidationOfMappingSchedule = [];
  isLoadingValidationOfMappingSchedule = false;

  /*Parameter List Store for grid */

  contentStore = [];
  sCharUtil = new specialCharUtil();

  localETDModel: string = "";
  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService
  ) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "retrieve", icon: "search" },
        { name: "New", event: "new", icon: "plus" },
        //{ name : 'Modify',  event: 'modify', icon : 'write' },
        { name: "Delete ", event: "delete", icon: "minus circle" },
        { name: "Print", event: "print", icon: "file outline" },
        { name: "View Allotment", event: "allotment", icon: "calculator" },
        { name: "Export", event: "export", icon: "file excel outline" },
        { name: "Close", event: "close", icon: "power" },
        { name: "Schedule Mapping", event: "mapping", icon: "list" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Sailing Schedule",
    };

    this.settingGridHeader = {
      id: "gridHeader",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        {
          header: "Vessel Name",
          field: "vesselName",
          width: 200,
          type: "string",
        },
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 50 },
        { header: "Service", field: "serviceCode", width: 100 },
        { header: "Local ETA", field: "localETA", width: 200 },
        { header: "Local ETD", field: "localETD", width: 200 },
        { header: "BL Date", field: "blDate", width: 200 },
        { header: "Exch. Rate Date", field: "exchangeRateDate", width: 200 },
        { header: "VSL/VOY Modified Date", field: "createdDate", width: 200 },
        { header: "Mapped (D4A)", field: "mappedDoc4allFlag", width: 200 },
        { header: "Modified Date", field: "modifiedDate", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "custom",
      sortingDirection: "DESC",
    };

    this.settingGridSailingDetail = {
      id: "gridSailingDetail",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "POL Voyage", field: "polVoyage", width: 100 },
        { header: "Port Name", field: "portofDischargeName", width: 175 },
        { header: "Port Code", field: "portofDischargeCode", width: 80 },
        {
          header: "Terminal Name",
          field: "terminalCodeDischargeName",
          width: 175,
        },
        { header: "Terminal Code", field: "terminalCodeDischarge", width: 100 },
        { header: "ETA", field: "arrivalDate", width: 175 },
        { header: "ETD", field: "sailDate", width: 175 },
        { header: "Call Reason", field: "callReason", width: 175 },
      ],
      buttons: [],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridSailingDetail2 = {
      id: "gridSailingDetail2",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Currency", field: "currency", width: 100 },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          width: 150,
          type: "string",
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridSailingDetailModal = {
      id: "gridSailingDetailModal",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Seq", field: "no", width: 50 },
        { header: "POL Voyage", field: "polVoyage", width: 100 },
        { header: "Port Name", field: "portofDischargeName", width: 175 },
        { header: "Port Code", field: "portofDischargeCode", width: 80 },
        {
          header: "Terminal Name",
          field: "terminalCodeDischargeName",
          width: 175,
        },
        { header: "Terminal Code", field: "terminalCodeDischarge", width: 100 },
        { header: "ETA", field: "arrivalDate", width: 175 },
        { header: "ETD", field: "sailDate", width: 175 },
      ],
      buttons: [],
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridSailingDetailSpecial = {
      id: "gridSailingDetailSpecial",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Currency", field: "currency", width: 100 },
        {
          header: "Exchange Rate",
          field: "exchangeRate",
          width: 150,
          type: "string",
        },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
    };

    this.settingGridStopBooking = {
      id: "gridStopBooking",
      modalId: "stopBookingMaintenance",
      url: "" /*this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',*/,
      page: 5,
      columns: [
        { header: "Seq", field: "no", width: 50, editType: "text" },
        /*
        {
          header: 'Port of Call', field: 'locationName', width: 200, editType: 'combo', maxCharsCombo: 0,

          //urlCombo:this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCall/VS00491/042/O/SGSIN',
          urlCombo: '',
          titleCombo: 'locationName', maxChars: 0, codeCombo: 'locationCode',
          columnsCombo: [
            {header: 'Port of Call', field: 'locationName', width: 200},
          ]
        },
        */
        {
          header: "Port of Call",
          field: "locationName",
          width: 200,
          editType: "select", //editType:'combo',

          titleSelect: "locationName",
          codeSelect: "locationCode",
          maxChars: 0,
        },
        {
          header: "Terminal",
          field: "terminalName",
          width: 150,
          editType: "select",
          urlCombo: "",
          titleSelect: "terminalName",
          codeSelect: "terminalCode",
          maxChars: 0,
        },

        {
          header: "Container Ownership",
          field: "enumCode",
          width: 100,
          editType: "select", //editType:'combo',
          ds: [
            { code: "ALL", name: "ALL" },
            { code: "SOC", name: "SOC" },
            { code: "SOC LOCAL", name: "SOC LOCAL" },
            { code: "SOC T/S", name: "SOC T/S" },
            { code: "COC", name: "COC" },
            { code: "COC LOCAL", name: "COC LOCAL" },
            { code: "COC T/S", name: "COC T/S" },
            { code: "SLOT", name: "SLOT" },
          ],
          titleSelect: "enumCode",
          codeSelect: "enumCode",
          maxChars: 0,
        },
        {
          header: "Container Status",
          field: "cntStatus",
          width: 100,
          editType: "select",
          ds: [
            { code: "BOTH", name: "BOTH" },
            { code: "LADEN", name: "LADEN" },
            { code: "EMPTY", name: "EMPTY" },
          ],
          titleSelect: "cntStatus",
          maxChars: 0,
          codeSelect: "cntStatus",
        },
        {
          header: "Operator Code",
          field: "operatorCode",
          width: 100,
          editType: "text",
        },
        { header: "Status", field: "status", width: 100 },
        {
          header: "Resume/Stop Booking",
          field: "statusR",
          width: 100,
          type: "button",
          labelButton: "Resume",
        },
        {
          header: "Delete",
          field: "btndelete",
          width: 100,
          type: "button",
          labelButton: "Delete",
        },
        { header: "Stopped By", field: "stoppedBy", width: 150 },
        { header: "Stopped Time/Date", field: "stoppedTime", width: 150 },
        { header: "Resumed By", field: "resumedBy", width: 150 },
        { header: "Resumed Time/Date", field: "resumedTime", width: 150 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: "no",
      sortingDirection: "ASC",
      editable: true,
    };

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findLikeVesselNameOrVesselIdAndIsValid/*/{query}',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxlength: 50,
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselScheduleMapping = {
      id: "cbVesselScheduleMapping",
      type: "search enter",
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findInAnotherResource/vesselName={query}",
      maxChars: 3,
      template: "grid",
      placeholder: "Search Vessel",
      title: "VesselName",
      description: "",
      columns: [
        { header: "Vessel Code", field: "VesselCode", width: 200 },
        { header: "Vessel Name", field: "VesselName", width: 350 },
        { header: "Valid", field: "Valid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      maxlength: 20,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingVoyageScheduleMapping = {
      id: "cbVoyageScheduleMapping",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search Voyage",
      title: "Voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "Voyage", width: 100 },
        { header: "Bound", field: "Bound", width: 100 },
        { header: "Service", field: "ServiceCode", width: 100 },
      ],
    };

    this.settingNewVessel = {
      id: "cbNewVessel",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}",
      maxChars: 3,
      maxlength: 50,
      template: "grid", // default
      placeholder: "Search Vessel",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 350 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingNewService = {
      id: "cbNewService",
      type: "search enter",
      //url         : this.configService.config.BASE_API.toString() + '/MasterServices/findGeneral/*/*/{query}/1/25/*/*',
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterServices/findLikeServiceNameOrVesselIdIsValid/*/{query}",
      maxChars: 3,
      template: "grid",
      placeholder: "Search Service",
      code: "serviceCode",
      title: "serviceName",
      description: "",
      isMark: true,
      flag: "",
      columns: [
        { header: "Service Name", field: "serviceName", width: 200 },
        { header: "Service Code", field: "serviceCode", width: 100 },
        { header: "PSA Service Code", field: "psaServiceCode", width: 150 },
        { header: "Seq", field: "serviceSequence", width: 50 },
        { header: "Remarks", field: "remarks", width: 100 },
        { header: "Is Valid?", field: "isValid", width: 50 },
      ],
    };

    //set default voyage to *
    this.model.voyage = "*";

    this.settingDateLocalETA = {
      id: "dateLocalETA",
      type: "dateCustom",
      format: "yyyy-mm-dd",
      placeholder: "Local ETA",
      customtext: "00:00:00",
    };
    this.settingDateLocalETD = {
      id: "dateLocalETD",
      type: "dateCustom",
      format: "yyyy-mm-dd",
      placeholder: "Local ETD",
      customtext: "00:00:00",
    };
    this.settingBLDate = {
      id: "blDate",
      type: "dateCustom",
      format: "yyyy-mm-dd",
      placeholder: "BL Date",
      customtext: "00:00:00",
    };
    this.settingExchangeRateDate = {
      id: "exchangeRateDate",
      type: "dateCustom",
      format: "yyyy-mm-dd",
      placeholder: "Exchange Rate Date",
      customtext: "00:00:00",
    };

    this.columnDefsScheduleMapping = [
      {
        headerName: "Vessel",
        field: "mappingSourceVesselName",
        headerTooltip: "Vessel",
        tooltipField: "mappingSourceVesselName",
      },
      {
        headerName: "Voyage",
        field: "mappingSourceVoyage",
        headerTooltip: "Voyage",
        tooltipField: "mappingSourceVoyage",
      },
      {
        headerName: "SSS/D4A",
        field: "mappingFromSource",
        headerTooltip: "SSS/D4A",
        tooltipField: "mappingFromSource",
      },
    ];

    this.rowDataScheduleMapping = this.model.scheduleMapping;
  }

  onGridReadyScheduleMapping(params) {
    this.gridApiScheduleMapping = params.api;
    this.gridColumnApiScheduleMapping = params.columnApi;
  }

  onSelectionChanged(event) {
    const selectedRows = this.gridApiScheduleMapping.getSelectedRows();

    this.rowSelected = selectedRows;
  }

  ngOnInit() {
    this.rowDataScheduleMapping = [];
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    console.log("MENU ID : " + this.currentMenu);
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons =
        "modify,delete,new,create,allotment,export,print";
    }
  }

  ngAfterViewInit() {
    this.firstInit();
    $("#gridHeader").find("th.collapsing").find("div.checkbox").remove();
    $(".dataTables_scrollBody").css("height", "200px");
    $("#sailingBound").on("click", function () {
      $(this).val("");
    });
    $("#sailingBound").on("mouseleave", function () {
      if ($(this).val() == "") {
        $(this).val("O");
      }
    });

    $("#detail-section").find("#container").css({
      //'width' : '100%',
      // 'height' : '120px',
      "overflow-x": "scroll",
      "overflow-y": "scroll",
    });

    //this.grid.setModeRadio(true);
    this.gridHeader.search = "*/*/*";
    this.gridHeader.onFirst();
    this.cbVoyage.disableCombo = true;
    this.setValidatorRetrieve();
    //set default value of bound to O
    this.model.bound = "O";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
    } else {
      this.disableToolbarButtons = "delete,allotment,export,print,mapping";
    }
    //reset isLoad
    this.isLoad = false;

    //reload the scrollbar
    //document.body.scrollTop;

    //reset form lock
    this.lockForm = false;
    this.lockFormBoundM = false;

    if (localStorage.getItem("prevSailingSchedule") == "N") {
      this.isOpenedRevertFromAllocation = true;
      var self = this;
      this.model.vesselId = localStorage.getItem("prevVesselId");
      this.cbVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselId={query}"
      );
      this.model.voyage = localStorage.getItem("prevVesselVoyage");
      this.model.bound = localStorage.getItem("prevVesselBound").toUpperCase();

      //update search parameter
      this.searchVesselId = localStorage.getItem("prevVesselId");
      this.searchVoyage = localStorage.getItem("prevVesselVoyage");
      this.searchBound = localStorage.getItem("prevVesselBound").toUpperCase();

      this.cbVessel.setValue(this.model.vesselId, function (callbackVessel) {
        let officeCode =
          self.cookieService.getDefaultLocationCode() == null
            ? "*"
            : self.cookieService.getDefaultLocationCode();

        if (self.model.bound.toUpperCase().trim() == "O") {
          self.cbVoyage.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              self.model.vesselId +
              "/" +
              self.model.bound.toUpperCase() +
              "/{query}/" +
              officeCode
          );
        } else if (self.model.bound.toUpperCase().trim() == "I") {
          //   self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageInbound/" + self.model.vesselId + "/" + self.model.bound.toUpperCase() + "/{query}/" + officeCode);
          self.cbVoyage.setUrl(
            self.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/distinctVoyage/" +
              self.model.vesselId +
              "/" +
              self.model.bound.toUpperCase() +
              "/{query}/" +
              officeCode
          );
        }

        if (self.model.voyage == "*") {
          self.cbVoyage.setValue("");
        } else {
          self.cbVoyage.setValue(self.model.voyage);
        }
        self.cbVoyage.disableCombo = false;
      });
      this.gridHeader.onClear();
      this.gridHeader.clearSelectedValues();
      this.gridHeader.url = "";
      this.gridHeader.listStore.store = JSON.parse(
        localStorage.getItem("prevGridHeader")
      );

      //EEQ start here
      let xPos: number = Number.parseFloat(localStorage.getItem("xPos"));
      let yPos: number = Number.parseFloat(localStorage.getItem("yPos"));
      this.gridHeader.loadData(xPos, yPos);
      this.gridHeader.clearSelectedValues();
      this.gridHeader.onSelect(
        true,
        Number.parseInt(localStorage.getItem("prevSelectedRecordNo"))
      );
      this.gridHeader.search = "*/*/*";

      this.gridSailingDetail.onClear();
      this.gridSailingDetail.clearSelectedValues();
      this.gridSailingDetail.url = "";
      this.gridSailingDetail.listStore.store = JSON.parse(
        localStorage.getItem("prevGridSailingDetail")
      );
      this.gridSailingDetail.loadData();
      this.extVesselCapacity = JSON.parse(
        localStorage.getItem("extVesselCapacity")
      );

      /*
      // console.log("**Debug after redirection **");
      // console.log(this.gridHeader.getSelectedValues()[0].vesselId);
      // console.log(this.gridHeader.getSelectedValues()[0].voyage);
      // console.log(this.gridHeader.getSelectedValues()[0].bound);
      // console.log(this.gridHeader.getSelectedValues()[0].serviceId);
      let officeCode = (self.cookieService.getDefaultLocationCode() == null) ? '*':self.cookieService.getDefaultLocationCode();
      this.getSailingDetail(this.gridHeader.getSelectedValues()[0].vesselId,this.gridHeader.getSelectedValues()[0].voyage,this.gridHeader.getSelectedValues()[0].bound, this.gridHeader.getSelectedValues()[0].serviceId, officeCode);
      */
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
      } else {
        this.disableToolbarButtons = "delete,export,print";
      }

      localStorage.removeItem("prevSailingSchedule");
      localStorage.removeItem("prevGridHeader");
      localStorage.removeItem("prevGridSailingDetail");
      localStorage.removeItem("prevSelectedRecordNo");
      localStorage.removeItem("prevVesselBound");
      localStorage.removeItem("prevVesselId");
      localStorage.removeItem("prevVesselVoyage");
      localStorage.removeItem("ssVoyage");
      localStorage.removeItem("xPos");
      localStorage.removeItem("yPos");
      localStorage.removeItem("extVesselCapacity");
    } else {
      this.isOpenedRevertFromAllocation = false;
    }
    this.firstInit();
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "retrieve":
        this.retrieveEvent();
        //// console.log("url grid "+this.grid.url);
        break;
      case "modify":
        this.modifyEvent();
        break;
      case "delete":
        this.deleteEvent();
        break;
      case "new":
        this.modeForm = "sailingScheduleMaintenance";
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print,mapping";
        } else {
          this.disableToolbarButtons =
            "retrieve,new,print,allotment,delete,export,mapping";
        }
        localStorage.removeItem("createBoundM");
        this.helper.createBoundM = "N";

        this.setValidatorInput();
        this.cbNewVessel.setValue("");
        this.cbNewService.setValue("");

        //store previous search profile
        this.prevVesselId = this.model.vesselId;
        this.prevVesselCode = this.model.vesselCode;
        this.prevVoyage = this.model.voyage;
        this.prevBound = this.model.bound.toUpperCase();
        this.prevServiceId = this.model.serviceId;

        //reset the model
        this.model = new MSailingSchedule();
        this.model.voyage = "";
        this.model.bound = "M";
        //this.model.officeCode = (localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
        //this.model.createdUserid = (localStorage.getItem("username") == null) ?'*': localStorage.getItem("username")
        this.model.officeCode =
          this.cookieService.getDefaultLocationCode() == null
            ? "*"
            : this.cookieService.getDefaultLocationCode();
        this.model.createdUserid =
          this.cookieService.getName() == null
            ? "*"
            : this.cookieService.getName();

        //open form for editting
        this.lockForm = false;
        this.lockFormBoundM = false;

        //open modal for entry`

        var self = this;

        this.dateLocalETA.setValue("");
        this.dateLocalETD.setValue("");
        this.blDate.setValue("");
        this.exchangeRateDate.setValue("");

        this.lockBLDate = false;
        this.model.lockedBlDate = "Y";
        this.lockExchangeRateDate = false;
        this.model.lockedExchangeRateDate = "Y";
        this.lockLocalETA = false;
        this.lockLocalETD = false;

        this.gridSailingDetailModal.onClear();
        break;
      case "print":
        this.printEvent();
        break;
      case "allotment":
        this.allotmentEvent();
        break;
      case "export":
        this.exportEvent();
        break;
      case "close":
        this.handleClose();
        break;
      case "mapping":
        this.handleScheduleMapping();
        break;
    }
  }

  gridEvent(event) {
    switch (event.split(".")[0]) {
      case "selected":
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case "click":
        //this.selectedListMod(event);
        console.log("debug sailing schedule");
        console.log(event);
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "delete,print";
        }
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split("-")[1];
        console.log("mode = " + mode);
        console.log("no = " + no);

        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, Number.parseInt(no));
        this.prevSelectedRecord = this.gridHeader.getSelectedValues()[0].no;
        /*switch(mode){
          case 'checked' :
            this.selectedListMod(no, this.gridHeader,'no');
            break;
          case 'unchecked' :
            break;
        }
        */
        let dt = this.gridHeader.getSelectedValues()[0];
        this.gridSailingDetail.onClear();
        //var officeCode = (localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
        var officeCode =
          this.cookieService.getDefaultLocationCode() == null
            ? "*"
            : this.cookieService.getDefaultLocationCode();
        if (dt.bound != "M") {
          if (dt.bound == "O") {
            this.getSailingDetail(
              dt.vesselId.trim(),
              dt.voyage,
              dt.bound,
              dt.serviceId,
              officeCode
            );
          } else if (dt.bound == "I") {
            this.getSailingDetailInbound(dt.id);
          }
        }

        //set the toolbar again to activate the delete and allotment button
        if (dt.bound.toUpperCase() == "M") {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons =
              "modify,delete,new,allotment,export,print";
          } else {
            this.disableToolbarButtons = "allotment,print";
          }
        } else if (dt.bound.toUpperCase() == "I") {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons =
              "modify,delete,new,allotment,export,print";
          } else {
            this.disableToolbarButtons = "delete,new,allotment,print";
          }
        } else if (dt.bound.toUpperCase() == "O") {
          if (this.viewOnlyStatus) {
            this.disableToolbarButtons =
              "modify,delete,new,allotment,export,print";
          } else {
            this.disableToolbarButtons = "delete,print";
          }
        }

        this.mappingSailingScheduleId = dt.id;
        this.mappingVoyage = dt.voyage;
        this.mappingBound = dt.bound;
        this.mappingIsValid = dt.isValid;
        this.serviceId = dt.serviceId;

        break;
      case "afterLoad":
        if (this.gridHeader.listStore.store.length == 0) {
          if (this.isLoad) {
            this.gridSailingDetail.onClear();

            this.message(
              "information",
              "Information",
              "No record(s) found.",
              "okonly",
              { ok: "this.loading=false;this.isLoad=false;" }
            );
          }
        } else {
          if (this.gridHeader.listStore.store[0].bound == "M") {
            // console.log("#0");
            if (this.viewOnlyStatus) {
              this.disableToolbarButtons =
                "modify,delete,new,allotment,export,print,mapping";
            } else {
              this.disableToolbarButtons = "allotment,print,mapping";
            }

            this.gridHeader.clearSelectedValues();
            if (this.isOpenedRevertFromAllocation == false) {
              // console.log("#0.0");
              if (this.prevSelectedRecord == 0) {
                // console.log("#0.0.0");
                this.gridHeader.onSelect(true, 1);
                this.gridSailingDetail.onClear();
              } else {
                // console.log("#0.1");
                this.gridHeader.onSelect(true, this.prevSelectedRecord);
              }
            }
            this.gridSailingDetail.onClear();
          } else {
            var officeCode =
              this.cookieService.getDefaultLocationCode() == null
                ? "*"
                : this.cookieService.getDefaultLocationCode();

            if (this.isOpenedRevertFromAllocation == false) {
              if (this.prevSelectedRecord == 0) {
                // console.log("#1");
                this.gridHeader.clearSelectedValues();
                this.gridHeader.onSelect(true, 1);

                if (
                  this.gridHeader.listStore.store[0].bound.toUpperCase() == "O"
                ) {
                  // console.log("#1.1");
                  // if(localStorage.getItem("createBoundM") == "Y"){
                  if (this.helper.createBoundM == "Y") {
                    // console.log("#1.1.1");
                    this.gridSailingDetail.onClear();
                  } else {
                    // console.log("#1.1.2");
                    this.getSailingDetail(
                      this.gridHeader.listStore.store[0].vesselId,
                      this.gridHeader.listStore.store[0].voyage,
                      this.gridHeader.listStore.store[0].bound,
                      this.gridHeader.listStore.store[0].serviceId,
                      officeCode
                    );
                  }
                } else if (
                  this.gridHeader.listStore.store[0].bound.toUpperCase() == "I"
                ) {
                  // console.log("#1.2");
                  this.getSailingDetailInbound(
                    this.gridHeader.listStore.store[0].id
                  );
                } else if (
                  this.gridHeader.listStore.store[0].bound.toUpperCase() == "M"
                ) {
                  // console.log("#1.3");
                  this.gridSailingDetail.onClear();
                }
              } else {
                // console.log("#2");
                this.gridHeader.clearSelectedValues();
                this.gridHeader.onSelect(true, this.prevSelectedRecord);
                if (
                  this.gridHeader.listStore.store[
                    this.prevSelectedRecord - 1
                  ].bound.toUpperCase() == "O"
                ) {
                  // console.log("#2.1");
                  this.getSailingDetail(
                    this.gridHeader.listStore.store[this.prevSelectedRecord - 1]
                      .vesselId,
                    this.gridHeader.listStore.store[this.prevSelectedRecord - 1]
                      .voyage,
                    this.gridHeader.listStore.store[this.prevSelectedRecord - 1]
                      .bound,
                    this.gridHeader.listStore.store[this.prevSelectedRecord - 1]
                      .serviceId,
                    officeCode
                  );
                } else if (
                  this.gridHeader.listStore.store[
                    this.prevSelectedRecord - 1
                  ].bound.toUpperCase() == "I"
                ) {
                  // console.log("#2.2");
                  this.getSailingDetailInbound(
                    this.gridHeader.listStore.store[this.prevSelectedRecord - 1]
                      .id
                  );
                } else if (
                  this.gridHeader.listStore.store[
                    this.prevSelectedRecord - 1
                  ].bound.toUpperCase() == "M"
                ) {
                  // console.log("#2.3");
                  this.gridSailingDetail.onClear();
                }
              }
            } else {
              this.isOpenedRevertFromAllocation = false;
            }
          }

          var self = this;
          setTimeout(() => {
            if (self.model.bound.toUpperCase() == "") {
              // console.log(self.gridHeader.getSelectedValues()[0].no);
              // console.log(self.gridHeader.listStore.store[(self.gridHeader.getSelectedValues()[0].no - 1)].bound.toUpperCase());
              if (
                self.gridHeader.listStore.store[
                  self.gridHeader.getSelectedValues()[0].no - 1
                ].bound.toUpperCase() == "O"
              ) {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "delete,print,mapping";
                }
              } else if (
                self.gridHeader.listStore.store[
                  self.gridHeader.getSelectedValues()[0].no - 1
                ].bound.toUpperCase() == "I"
              ) {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "new,delete,allotment,print,mapping";
                }
              }
              if (
                self.gridHeader.listStore.store[
                  self.gridHeader.getSelectedValues()[0].no - 1
                ].bound.toUpperCase() == "M"
              ) {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "allotment,print,mapping";
                }
                this.gridSailingDetail.onClear();
              }
            } else {
              if (self.searchBound.toUpperCase() == "O") {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "delete,print,mapping";
                }
              } else if (self.searchBound.toUpperCase() == "I") {
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "new,delete,allotment,print,mapping";
                }
              } else if (self.searchBound.toUpperCase() == "M") {
                this.gridSailingDetail.onClear();
                if (this.viewOnlyStatus) {
                  this.disableToolbarButtons =
                    "modify,delete,new,allotment,export,print,mapping";
                } else {
                  self.disableToolbarButtons = "allotment,print,mapping";
                }
              }
            }
          }, 10);

          //this.gridHeader.onSelect(true,1);
        }
        this.gridHeader.listStore.store.forEach((element) => {
          element["mappedDoc4allFlag"] = element["mappedDoc4allFlag"] ? "Y" : "N";
        });
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              //store previous search profile
              this.prevVesselId = this.model.vesselId;
              this.prevVesselCode = this.model.vesselCode;
              this.prevVoyage = this.model.voyage;
              this.prevBound = this.model.bound.toUpperCase();
              this.prevServiceId = this.model.serviceId;
              this.voyageVess = this.model.vesselName + "/" + this.model.voyage;

              this.modeForm = "sailingScheduleMaintenance";
              this.cookieService.setCookie("clickedRow", arr[1].toString());
              this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }
        }

        break;
    }
  }

  gridEventDetailsModal(event) {}

  gridEventDetails(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;
      case "click":
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split("-")[1];
        switch (mode) {
          case "checked":
            this.selectedListMod(no, this.gridSailingDetail, "no");
            break;
          case "afterLoad":
            // console.log("after load grid detail");
            if (localStorage.getItem("createBoundM") == "Y") {
              // console.log("after load grid detail");
              //this.gridSailingDetail.onClear();
            }
            break;
          case "unchecked":
            break;
        }
        break;
      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              break;
            default:
              break;
          }
        }
        break;
    }
  }

  gridEventDetails2(event) {}

  infoGrid(event) {
    this.info = event;
  }

  infoGridDetails(event) {
    this.info = event;
  }

  infoGridDetails2(event) {
    this.info = event;
  }

  changeEventVessel(event) {
    // console.log("vessel event");
    // console.log(event);

    this.model["error-vesselId"] = "";

    if (
      event.vesselId == null ||
      event.vesselId == "" ||
      event.vesselId == undefined
    ) {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
      this.model.voyage = "";
      this.cbVoyage.setUrl("");
      this.gridHeader.onClear();
      this.gridHeader.clearSelectedValues();
      this.gridSailingDetail.onClear();
    } else {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.extVesselCapacity = event.capacity;

      this.cbVoyage.disableCombo = false;
      this.model.voyage = "";
      //var officeCode = (localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();

      if (this.model.bound.toUpperCase().trim() == "O") {
        this.cbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            this.model.vesselId +
            "/" +
            this.model.bound.toUpperCase() +
            "/{query}/" +
            officeCode
        );
      } else if (this.model.bound.toUpperCase().trim() == "I") {
        //        this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageInbound/" + this.model.vesselId + "/" + this.model.bound.toUpperCase() + "/{query}/" + officeCode);
        this.cbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            this.model.vesselId +
            "/" +
            this.model.bound.toUpperCase() +
            "/{query}/" +
            officeCode
        );
      } else if (this.model.bound.toUpperCase().trim() == "M") {
        this.cbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            this.model.vesselId +
            "/" +
            this.model.bound.toUpperCase() +
            "/{query}/" +
            officeCode
        );
      }
      this.cbVoyage.setValue("");
    }

    this.cbVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselName={query}"
    );
  }

  changeEventVesselScheduleMapping(event) {
    console.log("hasil event = ", event)
    if (
      event.VesselCode == null ||
      event.VesselCode == "" ||
      event.VesselCode == undefined
    ) {
      this.modelMapping.mappingSourceVesselCode = "";
      this.modelMapping.mappingSourceVesselName = "";
      this.modelMapping.mappingSourceBound = "";
      // this.modelMapping.mappingSourceServiceCode = "";
      this.cbVoyageScheduleMapping.setUrl("");
    } else {
      this.modelMapping.mappingSourceVesselCode = event.VesselCode;
      this.modelMapping.mappingSourceVesselName = event.VesselName;
      this.modelMapping.mappingSourceBound = event.Boun;
      // this.modelMapping.mappingSourceServiceCode = "";

      this.cbVoyage.disableCombo = false;

      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();

      if (this.mappingBound.toUpperCase().trim() == "O") {
        this.cbVoyageScheduleMapping.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyageInAnotherResource/" +
            this.modelMapping.mappingSourceVesselCode +
            "/O" +
            "/{query}/" +
            officeCode
        );
      } else if (this.mappingBound.toUpperCase().trim() == "I") {
        this.cbVoyageScheduleMapping.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyageInAnotherResource/" +
            this.modelMapping.mappingSourceVesselCode +
            "/I" +
            "/{query}/" +
            officeCode
        );
      } else if (this.mappingBound.toUpperCase().trim() == "M") {
        this.cbVoyageScheduleMapping.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyageInAnotherResource/" +
            this.modelMapping.mappingSourceVesselCode +
            "/M" +
            "/{query}/" +
            officeCode
        );
      }
      this.cbVoyageScheduleMapping.setValue("");
    }

    this.cbVesselScheduleMapping.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findInAnotherResource/vesselName={query}" +
        "/"+
        "Y"+
        "/"+
        this.modelMapping.mappingFromSource
    );
  }

  changeEventBound(event) {
    //var officeCode = (localStorage.getItem("defaultLocationCode") == null) ?'*': localStorage.getItem("defaultLocationCode");
    var officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    if(this.model.bound){
      this.model['error-bound'] = null;
    }
    if (this.model.bound.toUpperCase().trim() == "O") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
      } else {
        this.disableToolbarButtons = "delete,print";
      }
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselId +
          "/" +
          this.model.bound.toUpperCase() +
          "/{query}/" +
          officeCode
      );
      this.prevSelectedRecord = 0;
    } else if (this.model.bound.toUpperCase().trim() == "I") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
      } else {
        this.disableToolbarButtons = "new,delete,allotment,print";
      }
      //  this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyageInbound/" + this.model.vesselId + "/" + this.model.bound.toUpperCase() + "/{query}/" + officeCode);
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselId +
          "/" +
          this.model.bound.toUpperCase() +
          "/{query}/" +
          officeCode
      );
      this.prevSelectedRecord = 0;
    } else if (this.model.bound.toUpperCase().trim() == "M") {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
      } else {
        this.disableToolbarButtons = "delete,allotment,print";
      }
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselId +
          "/" +
          this.model.bound.toUpperCase() +
          "/{query}/" +
          officeCode
      );
      this.prevSelectedRecord = 0;
    }

    this.cbVoyage.setValue("");
    this.model.voyage = "*";
  }

  changeEventNewVessel(event) {
    this.model["error-vesselId"] = "";

    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;

      //reset back the vessel url after resolved
      this.cbNewVessel.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterVessels/findByComboBoxControl/vesselName={query}"
      );
    }
  }

  changeEventNewService(event) {
    if (
      event.serviceId != null &&
      event.serviceId != "" &&
      event.serviceId != undefined
    ) {
      this.model.serviceId = event.serviceId;
      this.model.serviceCode = event.serviceCode;
      this.model.serviceName = event.serviceName;

      //reset back the service url after resolved
      this.cbNewService.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterServices/findLikeServiceNameOrVesselIdIsValid/*/{query}"
      );
    }
  }

  checkValidSchedule(
    vesselId: String,
    voyage: String,
    bound: String,
    officeCode: String
  ): String {
    let validSch: String = "";
    console.log("CEK VALID 1 : " + validSch);
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
          vesselId +
          "/" +
          voyage +
          "/" +
          bound +
          "/" +
          officeCode
      )
      .subscribe((resp) => {
        let dtx: any[];
        let valid: String;
        dtx = resp.json()["content"];
        validSch = dtx[0]["isValid"];
        if (validSch == "N") {
          this.message(
            "information",
            "Information",
            "Schedule is invalid, unable to retrieve.",
            "okonly",
            { ok: "/*this.handleCancelInvalid();*/" }
          );
          this.handleCancel();
        }
      });
    console.log("CEK VALID 3 : " + validSch);

    return validSch;
  }

  handleCancel() {
    this.cbVessel.clearSelect();
    this.cbVoyage.clearSelect();
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
  }

  changeEventVoyage(event) {
    // console.log("voyage event");
    // console.log(event);

    this.model["error-voyage"] = "";

    if (
      event.voyage == null ||
      event.voyage == "" ||
      event.voyage == undefined
    ) {
      if (
        this.model.vesselId != null &&
        this.model.vesselId != "" &&
        this.model.vesselId != undefined
      ) {
        this.model.voyage = "";
        var officeCode =
          this.cookieService.getDefaultLocationCode() == null
            ? "*"
            : this.cookieService.getDefaultLocationCode();
        this.cbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyage/" +
            this.model.vesselId +
            "/" +
            this.model.bound.toUpperCase() +
            "/{query}/" +
            officeCode
        );
      } else {
        this.model.voyage = "";
        this.cbVoyage.setUrl("");
      }
    } else {
      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyage/" +
          this.model.vesselId +
          "/" +
          this.model.bound.toUpperCase() +
          "/{query}/" +
          officeCode
      );
      this.model.voyage = event.voyage;
      this.model.bound = event.bound;

      this.checkValidSchedule(
        this.model.vesselId,
        this.sCharUtil.htmlEncode(String(this.model.voyage).trim()),
        this.model.bound.toUpperCase(),
        officeCode
      );
    }
    this.model.serviceCode = event.service;
    this.model.localETA = event.localEta;
    this.model.localETD = event.localEtd;
  }

  handleEnter(){
    if(!this.model['bound']){
      this.model['error-bound'] = "Please input bound.";
    }else{
      this.model['error-bound'] = null;
    }
  }

  changeEventVoyageScheduleMapping(event) {
    console.log("voyage event");
    console.log(event);

    if (
      event.Voyage == null ||
      event.Voyage == "" ||
      event.Voyage == undefined
    ) {
      if (
        this.modelMapping.mappingSourceVesselCode != null &&
        this.modelMapping.mappingSourceVesselCode != "" &&
        this.modelMapping.mappingSourceVesselCode != undefined

      ) {

        var officeCode =
          this.cookieService.getDefaultLocationCode() == null
            ? "*"
            : this.cookieService.getDefaultLocationCode();

        this.cbVoyageScheduleMapping.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/distinctVoyageInAnotherResource/" +
            this.modelMapping.mappingSourceVesselCode +
            "/" +
            "O" +
            "/{query}/" +
            officeCode +
            "/" +
            this.modelMapping.mappingFromSource
        );

        this.modelMapping.mappingSourceBound = event.Bound;
        this.modelMapping.mappingSourceVoyage = event.Voyage;


      } else {
        this.modelMapping.mappingSourceVoyage = "";
        this.modelMapping.mappingSourceBound = "";
        this.cbVoyageScheduleMapping.setUrl("");
      }
    } else {
      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();
      this.cbVoyageScheduleMapping.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctVoyageInAnotherResource/" +
          this.modelMapping.mappingSourceVesselCode +
          "/" +
          "O" +
          "/{query}/" +
          officeCode +
          "/" +
          this.modelMapping.mappingFromSource
      );
      this.modelMapping.mappingSourceVoyage = event.Voyage;
      this.modelMapping.mappingSourceBound = event.Bound;
      this.modelMapping.mappingSourceServiceCode = event.ServiceCode;
    }

    console.log("model mapping = ", this.modelMapping);

    // this.model.serviceCode = event.service;
    // this.model.localETA = event.localEta;
    // this.model.localETD = event.localEtd;
  }

  retrieveEvent() {
    this.model["error-vesselId"] = "";
    this.model["error-bound"] = "";

    localStorage.removeItem("createBoundM");
    this.helper.createBoundM = "N";

    let isPassedValidation: boolean = this.onValidate(this.model);
    if (!isPassedValidation) {
      this.prevSelectedRecord = 0;
      var hitUrl = "";
      if (
        this.model.bound.toUpperCase() == "O" ||
        this.model.bound.toUpperCase() == "M"
      ) {
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByVesselVoyageBoundHeader";
      } else if (this.model.bound.toUpperCase() == "I") {
        //hitUrl = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBoundHeaderInbound";
        hitUrl =
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByVesselVoyageBoundHeader";
      }

      //VS00287/TESTE1/I/CCU;
      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();
      this.searchVoyage =
        this.model.voyage != ""
          ? this.sCharUtil.htmlEncode(this.model.voyage)
          : "*";
      this.searchVesselId = this.model.vesselId;
      this.searchBound = this.model.bound.toUpperCase();

      this.gridHeader.search =
        this.model.vesselId +
        "/" +
        this.searchVoyage +
        "/" +
        this.model.bound.toUpperCase() +
        "/" +
        officeCode;
      this.gridHeader.url = hitUrl;
      //this.gridHeader.onClear();
      this.gridHeader.clearSelectedValues();
      //this.gridSailingDetail.onClear();
      this.fillGrid();

      //set the toolbar to activate export button
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "modify,delete,new,allotment,export,print,mapping";
      } else {
        this.disableToolbarButtons = "delete,allotment,print,mapping";
      }
      //localStorage.removeItem("createBoundM");
    }
  }

  deleteEvent() {
    // console.log(this.gridHeader.getSelectedValues());
    let data = this.gridHeader.getSelectedValues()[0];

    if (this.gridHeader.getSelectedValues().length == 0) {
      this.loading = true;
      this.message(
        "warning",
        "SSL Sailing Schedule",
        "Please select an entry to delete!",
        "okonly",
        { ok: "this.loading = false;" }
      );
    } else {
      this.loading = true;

      //check the schedule being used or not
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
            "/MasterBookings/findScheduleInBookingRecord/" +
            data.vesselCode +
            "/" +
            data.voyage +
            "/" +
            data.bound
        )
        .subscribe((resp) => {
          if (resp.status == 200) {
            if (resp.json()["status"] == "OK") {
              this.gridHeader.getSelectedValues().forEach((el) => {
                console.log("IDNYA : " + el.id);
              });
              this.message(
                "delete",
                "SSL Sailing Schedule",
                "Confirm to delete record(s)?",
                "yesno",
                {
                  yes: "this.confirmDelete()",
                  no: "this.loading = false;",
                }
              );
            } else {
              this.message(
                "delete",
                "SSL Sailing Schedule",
                "Deletion not allowed because the schedule is being used in booking",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }
          }
        });
    }
  }

  actionPrintStop() {
    var formatName = "Stop-Booking-Reports"+".xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/65/Stop-Booking-Reports/"
      +
      this.model.vesselId +
      "@" +
      this.model.voyage +
      "@" +
      this.model.bound +
      "@" +
      this.model.officeCode
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });

    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReport/65/Stop-Booking-Reports/" +
    //     this.model.vesselId +
    //     "@" +
    //     this.model.voyage +
    //     "@" +
    //     this.model.bound +
    //     "@" +
    //     this.model.officeCode
    // );
  }

  modifyEvent() {
    if (this.gridHeader.getSelectedValues().length == 0) {
      this.loading = true;
      this.message(
        "warning",
        "SSL Sailing Schedule",
        "Please select an entry to modify!",
        "okonly",
        { ok: "this.loading = false;" }
      );
    } else {
      this.loading = true;
      this.message(
        "information",
        "SSL Sailing Schedule",
        "Modify function not implemented yet.",
        "okonly",
        { ok: "this.loading = false;" }
      );
    }
  }

  setAllocation() {
    this.allotmentEvent();
  }

  verifyResume(idx) {
    // console.log('verify resume '+idx)
    this.clickIndex = idx;
    console.log("verify Index " + idx);

    //$('#resumeScheduleVerification')
    //.modal('setting', 'closable', false)
    //.modal('show');
    this.genericUtil.showDialog(
      "resumeScheduleVerification",
      "Resume Booking Verification",
      700,
      250
    );
  }

  stopBooking() {
    this.modelVoyage.bOceanVesselCode = this.model.vesselCode;
    this.modelVoyage.bOceanVesselId = this.model.vesselId;
    this.modelVoyage.bOceanVesselVoyage = this.model.voyage;
    this.modelVoyage.bOfficeCode = this.model.officeCode;
    this.modelVoyage.bOceanVesselBound = this.model.bound;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/calculateAllocation3new/",
        this.modelVoyage
      )
      .subscribe((resp) => {
        //this.genericService.POST("http://localhost:14Object.key000/MasterBookings/calculateAllocation3new/",this.modelVoyage).subscribe((resp) => {
        console.log(
          "lenghtt allocation " + Object.keys(resp.json()["content"]).length
        );
        if (Object.keys(resp.json()["content"]).length > 0) {
          console.log("after stop booking " + this.clickIndex);

          //$('#sailingScheduleVerification')
          //.modal('setting', 'closable', false)
          //.modal('show');
          this.genericUtil.showDialog(
            "sailingScheduleVerification",
            "Password",
            700,
            250
          );

          if (this.gridStopBooking.listStore.store.length > 0) {
            this.rowStop = 1;
            this.rowPrint = 1;
          } else {
            this.rowStop = 0;
            this.rowPrint = 0;
          }
        } else {
          this.message(
            "information",
            "Information",
            "Allocation has not been set for selected vessel / voyage. Unable to stop bookings.",
            "okonly",
            { ok: "// console.log('ok click')" }
          );
        }
      });

    //this.loading = true;
    //this.message('warning','SSL Sailing Schedule','Stop Booking function not implemented yet!','okonly',{ok: 'this.loading = false;' });
  }

  printEvent() {
    /*
    this.loading = true;
    this.message('warning','SSL Sailing Schedule','Print function not implemented yet!','okonly',{ok: 'this.loading = false;' });
    */

    //open modal for printing
    $("#printSailingSchedule")
      .modal("setting", "closable", false)
      .modal("show");
  }

  allotmentEvent() {
    /*
    localStorage.setItem("allocation-from-sailing-schedule","Y");
    localStorage.setItem("ssVesselCode",this.model.vesselCode);
    localStorage.setItem("ssVoyage",this.gridHeader.getSelectedValues()[0].voyage);
    localStorage.setItem("ssBound",this.model.bound);
    */

    localStorage.setItem(
      "ssVoyage",
      this.gridHeader.getSelectedValues()[0].voyage
    );
    localStorage.setItem(
      "allocation-from-sailing-schedule",
      JSON.stringify(this.gridHeader.getSelectedValues()[0])
    );
    localStorage.setItem("prevSailingSchedule", "Y");
    localStorage.setItem("prevVesselId", this.model.vesselId);
    localStorage.setItem("prevVesselVoyage", this.model.voyage);
    localStorage.setItem("prevVesselBound", this.model.bound.toUpperCase());
    localStorage.setItem(
      "extVesselCapacity",
      JSON.stringify(this.extVesselCapacity.toString())
    );
    localStorage.setItem(
      "prevGridHeader",
      JSON.stringify(this.gridHeader.listStore.store)
    );
    localStorage.setItem(
      "prevGridSailingDetail",
      JSON.stringify(this.gridSailingDetail.listStore.store)
    );
    localStorage.setItem(
      "prevSelectedRecordNo",
      this.gridHeader.getSelectedValues()[0].no
    );

    localStorage.setItem("xPos", this.gridHeader.getScrollX().toString());
    localStorage.setItem("yPos", this.gridHeader.getScrollY().toString());

    this.router.navigate(["/main/transaction-vessel-allocation"]);
    //    this.loading = true;
    //   this.message('warning','SSL Sailing Schedule','Allotment function not implemented yet!','okonly',{ok: 'this.loading = false;' });
  }

  exportEvent() {
    this.loading = true;
    this.message(
      "warning",
      "SSL Sailing Schedule",
      "Export function not implemented yet!",
      "okonly",
      { ok: "this.loading = false;" }
    );
  }

  handleScheduleMapping() {
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findMappingSailingScheduleBySailingScheduleId/" +
          this.mappingSailingScheduleId
      )
      .subscribe((resp) => {
        if(Object.keys(resp.json()["content"]).length > 0){
          this.modelMapping = new ScheduleMapping();
          this.mappingId = resp.json()["content"][0].id;
          this.modelMapping = resp.json()["content"][0];
          this.model.scheduleMapping.push(this.modelMapping);
          this.gridApiScheduleMapping.setRowData(this.model.scheduleMapping);
          this.btnAddMapping = true;
          this.btnSaveMapping = true;
          this.btnDeleteMapping = false;
        }else{
          this.mappingId = 0;
          console.log("data not found");
        }
      });


    this.genericUtil.showDialog(
      "scheduleMappingForm",
      `Mapping ${this.model.vesselName} V. ${
        this.gridHeader.getSelectedValues()[0].voyage
      }`,
      1000,
      500
    );
  }

  fillGrid() {
    this.isLoad = true;
    this.gridHeader.loadData();
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("setting", "closable", false);
    $("#dialog").modal("show");
  }

  handleDoubleClickedSailingSchedule() {
    this.lenPortOfCall = 0;
    this.modeForm = "sailingScheduleMaintenance";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
    } else {
      this.disableToolbarButtons = "retrieve,new,print,allotment,delete,export";
    }

    //update the voyage 1st to hide the * from user
    this.model.voyage = "";

    this.prevSelectedRecord = this.gridHeader.getSelectedValues()[0].no;

    //log the value of X and Y position
    localStorage.setItem("xPos", this.gridHeader.getScrollX().toString());
    localStorage.setItem("yPos", this.gridHeader.getScrollY().toString());

    if (this.gridHeader.getSelectedValues()[0].bound.toUpperCase() == "M") {
      this.lockForm = false;
      this.lockFormBoundM = true;
      this.lockLocalETA = false;
      this.lockLocalETD = false;
      this.lockBLDate = false;
      this.lockExchangeRateDate = false;
    } else {
      this.lockForm = true;
      this.lockFormBoundM = true;
      this.lockLocalETA = true;
      this.lockLocalETD = true;
      this.lockBLDate = true;
      this.lockExchangeRateDate = true;
    }

    //intercept from here

    //this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findBySailingScheduleId2/'+this.gridHeader.getSelectedValues()[0].id.toString().trim()).subscribe((resp) =>{
    var officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    let hitUrl: string = "";

    if (this.model.bound.toUpperCase() == "I") {
      //      hitUrl = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findSailingScheduleByDoubleClickInbound/'+this.gridHeader.getSelectedValues()[0].vesselId.toString().trim()+'/'+this.gridHeader.getSelectedValues()[0].voyage.toString().trim()+'/'+this.gridHeader.getSelectedValues()[0].bound.toString().trim()+'/'+officeCode;
      hitUrl =
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
        this.gridHeader.getSelectedValues()[0].vesselId.toString().trim() +
        "/" +
        // this.gridHeader.getSelectedValues()[0].voyage.toString().trim() +
        this.sCharUtil.htmlEncode(this.gridHeader.getSelectedValues()[0].voyage) +
        "/" +
        this.gridHeader.getSelectedValues()[0].bound.toString().trim() +
        "/" +
        officeCode;
    } else {
      hitUrl =
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findSailingScheduleByDoubleClick/" +
        this.gridHeader.getSelectedValues()[0].vesselId.toString().trim() +
        "/" +
        // this.gridHeader.getSelectedValues()[0].voyage.toString().trim() +
        this.sCharUtil.htmlEncode(this.gridHeader.getSelectedValues()[0].voyage) +
        "/" +
        this.gridHeader.getSelectedValues()[0].bound.toString().trim() +
        "/" +
        officeCode;
    }

    this.genericService.GET(hitUrl).subscribe((resp) => {
      this.setData(resp.json()["content"][0]);

      if (this.model.bound.toUpperCase() == "I") {
        this.lockBLDate = true;
      } else {
        if (this.model.lockedBlDate == "Y") {
          this.lockBLDate = false;
        } else {
          this.lockBLDate = false;
        }
      }

      if (this.model.lockedExchangeRateDate == "Y") {
        this.lockExchangeRateDate = false;
      } else {
        // this.lockExchangeRateDate = true;
        this.lockExchangeRateDate = false;
      }

      if (this.gridHeader.getSelectedValues()[0].bound.toUpperCase() == "O") {
        this.getSailingDetailModal(
          this.gridHeader.getSelectedValues()[0].vesselId,
          this.gridHeader.getSelectedValues()[0].voyage,
          this.gridHeader.getSelectedValues()[0].bound.toUpperCase(),
          this.gridHeader.getSelectedValues()[0].serviceId,
          officeCode
        );
      } else if (
        this.gridHeader.getSelectedValues()[0].bound.toUpperCase() == "I"
      ) {
        this.getSailingDetailModalInbound(
          this.gridHeader.getSelectedValues()[0].id
        );
      } else if (
        this.gridHeader.getSelectedValues()[0].bound.toUpperCase() == "M"
      ) {
        if (this.prevSelectedRecord != 0) {
          this.prevSelectedRecord = this.prevSelectedRecord - 1;
        }
      }
    });

    this.voyageVess =
      this.model.vesselName +
      "/" +
      this.gridHeader.getSelectedValues()[0].voyage;

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctPortOfCall/" +
          this.gridHeader.getSelectedValues()[0].vesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.gridHeader.getSelectedValues()[0].voyage) +
          "/" +
          this.gridHeader.getSelectedValues()[0].bound.toUpperCase() +
          "/" +
          officeCode +
          "/[-42]"
      )
      .subscribe((resp) => {
        console.log(resp.json());

        this.lenPortOfCall = resp.json()["content"].length - 1;

        this.dsPortOfCall = resp.json()["content"];

        //console.log(this.dsPortOfCall)
      });

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/distinctPortOfCallTerminalVer2/" +
          this.gridHeader.getSelectedValues()[0].vesselId +
          "/" +
          this.sCharUtil.htmlEncode(this.gridHeader.getSelectedValues()[0].voyage) +
          "/" +
          this.gridHeader.getSelectedValues()[0].bound.toUpperCase() +
          "/ALL/" +
          officeCode
      )
      .subscribe((sesp) => {
        //  this.genericService.GET( 'http://localhost:10000/MasterSailingSchedules/distinctPortOfCallTerminalVer2/'+ this.gridHeader.getSelectedValues()[0].vesselId+'/'+this.gridHeader.getSelectedValues()[0].voyage+'/'+this.gridHeader.getSelectedValues()[0].bound.toUpperCase()+'/ALL/'+officeCode).subscribe((sesp)=>{
        console.log(sesp.json());

        // this.lenPortOfCall = sesp.json()['content'].length-1

        this.lenTerminal = Object.keys(sesp.json()["content"]).length;
        this.dsTerminal = sesp.json()["content"];

        // console.log("terminal");
        // console.log(this.dsTerminal[0].terminalName);
      });
      this.mappingSailingScheduleId = this.gridHeader.getSelectedValues()[0].id;
      this.mappingVoyage = this.model.voyage;
      this.mappingBound = this.model.bound;
      this.mappingIsValid = this.model.isValid;
      this.serviceId = this.model.serviceId;

      console.log("tis exc =", this.lockExchangeRateDate);
      console.log("tis bldate =", this.lockBLDate);
      console.log("model ec =", this.model.lockedExchangeRateDate);
      console.log("model bldate =", this.model.lockedBlDate);
  }

  hideMe() {
    // clear error
    this.model["error-vesselId"] = "";
    this.model["error-voyage"] = "";
    this.model["error-psaVoyage"] = "";

    //store previous search profile
    this.model.vesselId = this.prevVesselId;
    this.model.vesselCode = this.prevVesselCode;
    this.model.voyage = this.prevVoyage;
    this.model.bound = this.prevBound.toUpperCase();
    this.model.serviceId = this.prevServiceId;
    this.setValidatorRetrieve();

    /*
    $('#sailingScheduleMaintenance')
      .modal('setting', 'closable', false)
      .modal('hide')
      .modal('refresh');
    */

    //reset the lockForm flag
    this.lockForm = false;
    this.lockForm = false;

    //unlock the datepicker
    this.lockLocalETA = this.lockForm;
    this.lockLocalETD = this.lockForm;

    this.dateLocalETA.setValue("");
    this.dateLocalETD.setValue("");
    this.blDate.setValue("");
    this.exchangeRateDate.setValue("");

    //this.gridHeader.onSelect(true,Number.parseInt(this.cookieService.getCookie("clickedRow")));

    if (this.gridHeader.getSelectedValues().length > 0) {
      this.gridHeader.clearSelectedValues();

      if (this.prevSelectedRecord == 0) {
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, 1);
      } else {
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, this.prevSelectedRecord);
      }

      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();

      let data = this.gridHeader.getSelectedValues()[0];

      if (data.bound.toUpperCase() == "O") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "delete,print";
        }
        this.getSailingDetail(
          data.vesselId,
          data.voyage,
          data.bound,
          data.serviceId,
          officeCode
        );
      } else if (data.bound.toUpperCase() == "I") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "new,delete,allotment,print";
        }
        this.getSailingDetailInbound(data.id);
      } else if (data.bound.toUpperCase() == "M") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "allotment,print";
        }

        this.gridSailingDetail.onClear();
      }
    } else {
      this.disableToolbarButtons = "delete,allotment,export,print,mapping";
    }

    // this.getSailingDetail(this.gridHeader.listStore.store[this.gridHeader.listStore.store.length-1].vesselId,this.gridHeader.listStore.store[this.gridHeader.listStore.store.length-1].voyage,this.gridHeader.listStore.store[this.gridHeader.listStore.store.length-1].bound,this.gridHeader.listStore.store[this.gridHeader.listStore.store.length-1].serviceId,officeCode);
    this.serviceId = "";
    this.mappingVoyage = "";
    this.mappingBound = "";
    this.mappingIsValid = "";
    this.mappingSailingScheduleId = 0;
    this.modeForm = "sailingScheduleFlagStatus";
  }

  /*
    selectedListMod(value){
      this.gridHeader.getSelectedValues().forEach(gs=>{
        if(value.split("-")[1] !=  gs.seqno){
          gs['select'] = '';
          this.gridHeader.getSelectedValues().splice(this.gridHeader.getSelectedValues().indexOf(gs),1);
        }
      });
      if(value.split(".")[1].split("-")[0] == "checked"){
        // console.log("size "+this.gridHeader.getSelectedValues().length);
        // console.log("name "+this.gridHeader.getSelectedValues()[0].portofLoadingName);
      }
    }
    */

  selectedListMod(value, store: GridPluginComponent, key) {
    // console.log("no "+value);
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach((ff) => {
        if (ff[key] != value) {
          ff["select"] = "";
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
    // console.log("total checked "+store.getSelectedValues().length);
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  redundantData(pariabel) {
    var reqReturn = 0;
    this.gridStopBooking.listStore.store.forEach((kyt) => {
      if (pariabel == "COC") {
        if (
          kyt.enumCode == "COC LOCAL" &&
          kyt.cntStatus == "LADEN" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "COC LOCAL" &&
          kyt.cntStatus == "EMPTY" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "COC T/S" &&
          kyt.cntStatus == "LADEN" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "COC T/S" &&
          kyt.cntStatus == "EMPTY" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
      }

      if (pariabel == "SOC") {
        if (
          kyt.enumCode == "SOC LOCAL" &&
          kyt.cntStatus == "LADEN" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "SOC LOCAL" &&
          kyt.cntStatus == "EMPTY" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "SOC T/S" &&
          kyt.cntStatus == "LADEN" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
        if (
          kyt.enumCode == "SOC T/S" &&
          kyt.cntStatus == "EMPTY" &&
          kyt.status != "RESUMED"
        )
          reqReturn = reqReturn + 1;
      }

      // if(pariabel == 'SOC' && (kyt.enumCode == 'SOC LOCAL' || kyt.enumCode == 'SOC T/S')) reqReturn =  1;
      // if(pariabel == 'BOTH' && (kyt.enumCode == 'FULL' || kyt.enumCode == 'EMPTY')) reqReturn =  1;
    });

    return reqReturn;
  }

  actionSaveResume(keyIndex) {
    var resPost = [];
    console.log("keyIndex Resume" + keyIndex);

    this.stopBookingPasswordResume = "";
    this.errorStopBookingPasswordResume = undefined;

    this.gridStopBooking.listStore.store.forEach((rev) => {
      if (rev.no == keyIndex) {
        this.gridStopBooking.disableButtonGrid(
          this.clickIndex,
          "STATUSR",
          true
        );
        rev.resumedBy = this.cookieService.getName();
        rev.resumedTime = this.genericUtil.getPSIDate();
        rev.statusbuttonR = true;
        rev.status = "RESUMED";
      }
    });

    this.gridStopBooking.listStore.store.forEach((key) => {
      resPost.push({
        vesselId: this.model.vesselId,
        voyage: this.model.voyage,
        bound: this.model.bound,
        seqno: key.no,
        portofcall: key.locationCode,
        bcntowner: key.enumCode,
        bcntstatus: key.cntStatus,
        stopby: key.stoppedBy,
        stopdate: key.stoppedTime,
        slotoperator: key.operatorCode,
        status: key.status,
        datecreated: key.dateCreated,
        lastmodified: this.genericUtil.getPSIDate(),
        isdeleted: "N",
        resumedby: key.resumedBy,
        resumeddate: key.resumedTime,
        deleteddate: "",
        deletedby: "",
        officecode: this.cookieService.getDefaultLocationCode(),
        vesselCode: this.model.vesselCode,
        terminalcode: key.terminalCode,
      });
    });

    var varok = "";
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/saveStopBooking",
        resPost
      )
      .subscribe(
        (resp) => {
          //this.genericService.POST("http://localhost:10000/MasterSailingSchedules/saveStopBooking", resPost).subscribe((resp) => {

          varok = resp.json()[0]["status"];

          if (varok == "OK") {
            // console.log('after save');
            // console.log(this.gridStopBooking.listStore.store);

            this.gridStopBooking.loadData();
          }
        },
        (error) => {
          this.loading = false;
          this.message(
            "information",
            "Information",
            "Some error(s) had occured while saving the record. <br/> Please contact your administrator.",
            "okonly",
            { ok: "" }
          );
        }
      );
  }

  actionSave() {
    var varPost = [];
    var dup1 = [];
    var dblflag1 = false;
    var dblflag2 = false;
    var dblflag3 = false;
    var dblflag4 = false;

    var vesselCode = "";
    var voyage = "";
    var bound = "";
    var seqno = "";
    var portofcall = "";
    var bcntowner = "";
    var bcntstatus = "";
    var stopby = "";
    var stopdate = "";
    var slotoperator = "";
    var datecreated = "";
    var lastmodified = "";
    var isdeleted = "";
    var resumedby = "";
    var resumeddate = "";
    var deleteddate =
      "                                                                                                                                                                                                          ";
    var deletedby = "";

    var SOCMaster = 0;
    var COCMaster = 0;
    var CntStatusMaster = 0;
    var flagLocation = "";
    var portOfCallMaster = 0;

    var logStopBy = "";
    var logStopTime = "";
    var countLoop = 0;

    this.gridStopBooking.listStore.store.forEach((ez) => {
      ez.keyCompare =
        ez.locationCode +
        ez.terminalCode +
        ez.enumCode +
        ez.cntStatus +
        ez.operatorCode;
      if (ez.status == "RESUMED")
        ez.keyCompare =
          ez.locationCode +
          ez.terminalCode +
          ez.enumCode +
          ez.cntStatus +
          ez.operatorCode +
          countLoop;

      if (ez.status == "STOPPED" || ez.status == "") {
        ez.keyCompare = ez.keyCompare + "STOPPED";
      }

      ez.keyCompare = ez.keyCompare.replace(" ", "");

      dup1.push(ez);

      if (
        ez.locationCode == "ALL" &&
        ez.cntStatus == "BOTH" &&
        ez.enumCode == "ALL"
      ) {
        console.log("len por of call " + this.lenPortOfCall);
        console.log("tempport " + this.tempPort.length);
        if (this.lenPortOfCall == this.tempPort.length) portOfCallMaster = 1;
      }

      if (
        ez.enumCode == "COC" &&
        ez.cntStatus == "BOTH" &&
        ez.status != "RESUMED"
      ) {
        // console.log('masuk COCC')
        COCMaster = this.redundantData("COC");
      }
      if (
        ez.enumCode == "SOC" &&
        ez.cntStatus == "BOTH" &&
        ez.status != "RESUMED"
      ) {
        //console.log('masuk SOCC')
        SOCMaster = this.redundantData("SOC");
      }

      countLoop++;
      //if(ez.enumCode == 'BOTH') CntStatusMaster = this.redundantData('BOTH');
    });

    dblflag1 = this.checkDuplicateInObject("keyCompare", dup1);
    //dblflag2 = this.checkDuplicateInObject('enumCode',dup1);
    //dblflag3 = this.checkDuplicateInObject('cntStatus',dup1);
    console.log(dblflag1 + " " + dblflag2 + " " + dblflag3);
    console.log(dup1);
    console.log("COCO" + COCMaster + "SOC" + SOCMaster);

    if (COCMaster == 4 || SOCMaster == 4 || portOfCallMaster == 1) {
      // console.log('masuk redundant')
      this.message(
        "information",
        "Information",
        "Repeated condition(s) detected.",
        "okonly",
        {
          ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
        }
      );
    } else if (dblflag1 == true) {
      this.message(
        "information",
        "Information",
        "Duplicate condition(s) detected.",
        "okonly",
        {
          ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
        }
      );
    } else {
      //dblflag4 = this.checkDuplicateInObject('locationCode',dup1);

      this.gridStopBooking.listStore.store.forEach((key) => {
        if (key.status != "RESUMED" && key.status != "STOPPED") {
          (key.stoppedTime = this.genericUtil.getPSIDate()),
            (key.stoppedBy = this.cookieService.getName());
        } else {
          (key.stoppedTime = key.stoppedTime), (key.stoppedBy = key.stoppedBy);
        }

        if (key.status != "RESUMED") {
          (key.status = "STOPPED"),
            (key.statusbuttonR = false),
            (key.statusbuttonD = true);
        }
      });

      this.gridStopBooking.listStore.store.forEach((key) => {
        if (
          key.locationCode == undefined ||
          key.locationCode == "" ||
          key.locationCode == "ALL"
        )
          flagLocation = "ALL";
        else flagLocation = key.locationCode;

        if (key.status == "") {
          logStopBy = this.cookieService.getName();
          logStopTime = this.genericUtil.getPSIDate();
        } else {
          logStopBy = key.stoppedBy;
          logStopTime = key.stoppedTime;
        }

        varPost.push({
          vesselId: this.model.vesselId,
          voyage: this.model.voyage,
          bound: this.model.bound,
          seqno: key.no,
          portofcall: flagLocation,
          bcntowner: key.enumCode,
          bcntstatus: key.cntStatus,
          stopby: logStopBy,
          stopdate: logStopTime,
          slotoperator: key.operatorCode,
          status: key.status,
          datecreated: this.genericUtil.getPSIDate(),
          lastmodified: this.genericUtil.getPSIDate(),
          isdeleted: "N",
          resumedby: key.resumedBy,
          resumeddate: key.resumedTime,
          deleteddate: "",
          deletedby: "",
          officecode: this.cookieService.getDefaultLocationCode(),
          vesselCode: this.model.vesselCode,
          terminalcode: key.terminalCode,
        });
      });
      //this.gridStopBooking.loadData();

      // console.log('pecah pecah');
      // console.log(this.gridStopBooking.listStore.store);
      // console.log('vaarpost');
      // console.log(varPost);

      var datas = "";

      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/saveStopBooking",
          varPost
        )
        .subscribe(
          (resp) => {
            // this.genericService.POST("http://localhost:10000/MasterSailingSchedules/saveStopBooking", varPost).subscribe((resp) => {
              this.gridStopBooking.listStore.store.forEach((key) => {
            datas = resp.json()[0]["status"];

            if (datas == "OK") {
              // console.log('after save');
              // console.log(this.gridStopBooking.listStore.store);

              this.gridStopBooking.loadData();
              this.message(
                "information",
                "Information",
                "Bookings have been stopped successfully.",
                "okonly",
                {
                  ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
                }
              );
            }if(key.status != "STOPPED"){
              this.message(
                "information",
                "Information",
                "Bookings resumed successfully.",
                "okonly",
                {
                  ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
                }
              );
            }
          },
          (error) => {
            this.loading = false;
            this.message(
              "information",
              "Information",
              "Some error(s) had occured while saving the record. <br/> Please contact your administrator.",
              "okonly",
              { ok: "" }
            );
          }
        );}
        );
    } //end else double
  }

  addAllocation() {
    // console.log("bruray");
  }

  getSailingDetailModal(vesselId, voyage, bound, service, officeCode) {
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByVesselVoyageBoundServiceId/" +
      vesselId +
      "/" +
      this.sCharUtil.htmlEncode(voyage) +
      "/" +
      bound +
      "/" +
      service +
      "/" +
      officeCode;
    // console.log("HIT URL Modal: "+hiturl);
    this.gridSailingDetailModal.url = hiturl;
    if (bound != "M") {
      this.gridSailingDetailModal.loadData();
    }
  }

  getSailingDetailModalInbound(sailingScheduleId) {
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findInboundBySailingScheduleId/" +
      sailingScheduleId;
    this.gridSailingDetailModal.url = hiturl;
    this.gridSailingDetailModal.loadData();
  }

  getSailingDetail(vesselId, voyage, bound, service, officeCode) {
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findByVesselVoyageBoundServiceId/" +
      vesselId +
      "/" +
      this.sCharUtil.htmlEncode(voyage) +
      "/" +
      bound +
      "/" +
      service +
      "/" +
      officeCode;
    // console.log("From getSailingDetail");
    // console.log(hiturl);
    this.gridSailingDetail.url = hiturl;
    this.gridSailingDetail.loadData();
  }

  getSailingDetailInbound(sailingScheduleId) {
    var hiturl =
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/findInboundBySailingScheduleId/" +
      sailingScheduleId;
    // console.log("From getSailingDetailInbound");
    // console.log(hiturl);
    this.gridSailingDetail.url = hiturl;
    this.gridSailingDetail.loadData();
  }

  setData(data: any) {
    //SET MODEL DATA FOR UPDATE PROCESS
    //let data = this.gridHeader.getSelectedValues()[0];

    this.model = new MSailingSchedule();

    this.model.id =
      data.id != undefined && data.id != null
        ? Number.parseInt(data.id)
        : undefined;

    this.model.vesselId =
      data.vesselId != undefined && data.vesselId != "" && data.vesselId != null
        ? String(data.vesselId).trim().toUpperCase()
        : "";
    this.model.vesselCode =
      data.vesselCode != undefined &&
      data.vesselCode != "" &&
      data.vesselCode != null
        ? String(data.vesselCode).trim().toUpperCase()
        : "";
    this.model.vesselName =
      data.vesselName != undefined &&
      data.vesselName != "" &&
      data.vesselName != null
        ? String(data.vesselName).trim().toUpperCase()
        : "";

    this.cbNewVessel.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterVessels/findByComboBoxControl/vesselId={query}"
    );
    this.cbNewVessel.setValue(this.model.vesselId);

    this.model.voyage =
      data.voyage != undefined && data.voyage != "" && data.voyage != null
        ? String(data.voyage).trim().toUpperCase()
        : "";
    this.model.bound =
      data.bound != undefined && data.bound != "" && data.bound != null
        ? String(data.bound).trim().toUpperCase()
        : "";

    this.model.serviceId =
      data.serviceId != undefined &&
      data.serviceId != "" &&
      data.serviceId != null
        ? String(data.serviceId).trim().toUpperCase()
        : "";

    this.model.serviceCode =
      data.serviceCode != undefined &&
      data.serviceCode != "" &&
      data.serviceCode != null
        ? String(data.serviceCode).trim().toUpperCase()
        : "";
    this.model.serviceName =
      data.serviceName != undefined &&
      data.serviceName != "" &&
      data.serviceName != null
        ? String(data.serviceName).trim().toUpperCase()
        : "";
    this.cbNewService.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterServices/findLikeServiceNameOrVesselIdIsValid/{query}/*"
    );
    this.cbNewService.setValue(this.model.serviceId);

    this.model.nameOfMaster =
      data.nameOfMaster != undefined &&
      data.nameOfMaster != "" &&
      data.nameOfMaster != null
        ? String(data.nameOfMaster).trim().toUpperCase()
        : "";
    this.model.nationality =
      data.nationality != undefined &&
      data.nationality != "" &&
      data.nationality != null
        ? String(data.nationality).trim().toUpperCase()
        : "";

    this.model.nonSsl =
      data.nonSsl != undefined && data.nonSsl != "" && data.nonSsl != null
        ? String(data.nonSsl).trim().toUpperCase()
        : "";

    this.model.localETA =
      data.localETA != undefined && data.localETA != "" && data.localETA != null
        ? String(data.localETA).trim().toUpperCase()
        : "";
    this.dateLocalETA.setValue(this.model.localETA);

    this.model.localETD =
      data.localETD != undefined && data.localETD != "" && data.localETD != null
        ? String(data.localETD).trim().toUpperCase()
        : "";
    this.dateLocalETD.setValue(this.model.localETD);

    this.model.blDate =
      data.blDate != undefined && data.blDate != "" && data.blDate != null
        ? String(data.blDate).trim().toUpperCase()
        : "";
    this.blDate.setValue(this.model.blDate);

    this.model.exchangeRateDate =
      data.exchangeRateDate != undefined &&
      data.exchangeRateDate != "" &&
      data.exchangeRateDate != null
        ? String(data.exchangeRateDate).trim().toUpperCase()
        : "";
    this.exchangeRateDate.setValue(this.model.exchangeRateDate);

    this.model.psaVoyage =
      data.psaVoyage != undefined &&
      data.psaVoyage != "" &&
      data.psaVoyage != null
        ? String(data.psaVoyage).trim().toUpperCase()
        : "";
    this.model.lockedBlDate =
      data.lockedBLDate != undefined &&
      data.lockedBLDate != "" &&
      data.lockedBLDate != null
        ? String(data.lockedBLDate).trim().toUpperCase()
        : "";
    this.model.lockedExchangeRateDate =
      data.lockedExchangeRateDate != undefined &&
      data.lockedExchangeRateDate != "" &&
      data.lockedExchangeRateDate != null
        ? String(data.lockedExchangeRateDate).trim().toUpperCase()
        : "";

    this.model.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();

    this.model.gdvNumber =
      data.gdvNumber != undefined &&
      data.gdvNumber != "" &&
      data.gdvNumber != null
        ? String(data.gdvNumber).trim().toUpperCase()
        : "";
    this.model.psaTransmitFlag =
      data.psaTransmitFlag != undefined &&
      data.psaTransmitFlag != "" &&
      data.psaTransmitFlag != null
        ? String(data.psaTransmitFlag).trim().toUpperCase()
        : "";
    this.model.dryDocking =
      data.dryDocking != undefined &&
      data.dryDocking != "" &&
      data.dryDocking != null
        ? String(data.dryDocking).trim().toUpperCase()
        : "";
    this.model.leg =
      data.leg != undefined && data.leg != "" && data.leg != null
        ? String(data.leg).trim().toUpperCase()
        : "";

    this.model.agent =
      data.agent != undefined && data.agent != "" && data.agent != null
        ? String(data.agent).trim().toUpperCase()
        : "";
    this.model.callingSeq =
      data.callingSeq != undefined &&
      data.callingSeq != "" &&
      data.callingSeq != null
        ? String(data.callingSeq).trim().toUpperCase()
        : "";
    this.model.createdUserid =
      data.createdUserid != undefined &&
      data.createdUserid != "" &&
      data.createdUserid != null
        ? String(data.createdUserid).trim().toUpperCase()
        : "";
    this.model.createdDate =
      data.createdDate != undefined &&
      data.createdDate != "" &&
      data.createdDate != null
        ? String(data.createdDate).trim().toUpperCase()
        : "";
    //this.model.modifiedUserid = (data.modifiedUserid != undefined && data.modifiedUserid != "" && data.modifiedUserid != null)?String(data.modifiedUserid).trim().toUpperCase():"";
    //this.model.modifiedDate = (data.modifiedDate != undefined && data.modifiedDate != "" && data.modifiedDate != null)?String(data.modifiedDate).trim().toUpperCase():"";
    this.model.modifiedUserid = this.cookieService.getName();
    this.model.modifiedDate = this.genericUtil.getPSIDate();
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  onProceed() {
    //this.model.createdDate = this.genericUtil.getPSIDate();

    this.model.localETA = this.dateLocalETA.getValue();
    this.model.localETD = this.dateLocalETD.getValue();
    this.model.blDate = this.blDate.getValue();
    this.model.exchangeRateDate = this.exchangeRateDate.getValue();

    if (
      this.model.localETA != null &&
      this.model.localETA != "" &&
      this.model.localETA != undefined
    ) {
      this.model.localETA =
        this.model.localETA.length < 19
          ? this.model.localETA + " 00:00:00"
          : this.model.localETA;
    }

    if (
      this.model.localETD != null &&
      this.model.localETD != "" &&
      this.model.localETD != undefined
    ) {
      this.model.localETD =
        this.model.localETD.length < 19
          ? this.model.localETD + " 00:00:00"
          : this.model.localETD;
    }

    if (
      this.model.blDate != null &&
      this.model.blDate != "" &&
      this.model.blDate != undefined
    ) {
      this.model.blDate =
        this.model.blDate.length < 19
          ? this.model.blDate + " 00:00:00"
          : this.model.blDate;
    }

    if (
      this.model.exchangeRateDate != null &&
      this.model.exchangeRateDate != "" &&
      this.model.exchangeRateDate != undefined
    ) {
      this.model.exchangeRateDate =
        this.model.exchangeRateDate.length < 19
          ? this.model.exchangeRateDate + " 00:00:00"
          : this.model.exchangeRateDate;
    }

    this.isError = this.onValidate(this.model);
    // console.log("is error => " + this.isError);
    if (!this.isError) {
      //this.model["dateCreated"] = new GenericUtil().getPSIDate();
      this.loading = true;
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        {
          yes: "this.saveEvent()",
          no: "this.loading = false;",
        }
      );
    } else {
      this.loading = false;
    }
  }

  //unused
  cancelSave() {
    // console.log('cancel save');
    $("#sailingScheduleMaintenance")
      .modal("setting", "closable", false)
      .modal("show");
  }

  saveEvent() {
    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/saveBoundM",
        this.model
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //to autofill upon save when form is blank
            if (
              this.prevVesselId == null ||
              this.prevVesselId == "" ||
              this.prevVesselId == undefined
            ) {
              this.prevVesselId = this.model.vesselId;
              this.prevVesselCode = this.model.vesselCode;
              this.prevVoyage = this.model.voyage;
              this.prevBound = this.model.bound.toUpperCase();
            }

            //set the ID into the model
            //if(resp.status == 200 && resp.json()['message'] == 'OK'){
            //  this.model.id = resp.json()["content"]["id"];
            //}

            //this.model = new MSailingSchedule;
            this.loading = false;
            this.info = "save successfully";
            //this.setGridEvent = 'refresh';

            if (this.model.bound.toUpperCase() == "M") {
              let arr: any[] = [];
              this.model.voyage = this.model.voyage.toUpperCase();

              if (this.gridHeader.listStore.store.length > 1) {
                if (this.model.id == 0) {
                  this.model.id = resp.json()["content"]["id"];
                }
                //push the last modified data into the top grid
                arr.push(this.model);

                for (
                  let i = 0;
                  i < this.gridHeader.listStore.store.length;
                  i++
                ) {
                  if (this.gridHeader.listStore.store[i].id != this.model.id) {
                    arr.push(this.gridHeader.listStore.store[i]);
                  }
                }
              } else {
                if (this.model.id == 0) {
                  this.model.id = resp.json()["content"]["id"];
                }
                arr.push(this.model);
              }

              this.gridHeader.onClear();
              this.gridHeader.url = "";
              this.gridHeader.listStore.store = arr;
              // console.log("ARRRRR");
              // console.log(arr);
              this.gridHeader.loadData();

              if (this.prevSelectedRecord != 0) {
                this.prevSelectedRecord = this.prevSelectedRecord + 1;
              }

              localStorage.removeItem("createBoundM");
              localStorage.setItem("createBoundM", "Y");
              this.helper.createBoundM = "N";
              this.helper.createBoundM = "Y";

              /*
          let hitUrl = this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBoundHeader";

          var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*':this.cookieService.getDefaultLocationCode();
          this.gridHeader.url=hitUrl;
          this.gridHeader.search = this.searchVesselId + "/" + this.searchVoyage + "/" + this.searchBound.toUpperCase() + '/' + officeCode ;


          this.gridHeader.loadData();

          // console.log("Assuuu");
          // console.log(this.gridHeader.url);
        */
            } else {
              //for bound O and I

              let hitUrl =
                this.configService.config.BASE_API.toString() +
                "/MasterSailingSchedules/findByVesselVoyageBoundHeader";

              var officeCode =
                this.cookieService.getDefaultLocationCode() == null
                  ? "*"
                  : this.cookieService.getDefaultLocationCode();
              this.gridHeader.url = hitUrl;
              this.gridHeader.search =
                this.searchVesselId +
                "/" +
                this.searchVoyage +
                "/" +
                this.searchBound.toUpperCase() +
                "/" +
                officeCode;

              this.gridHeader.loadData();
            }

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
          this.loading = false;
        }
      );
  }

  revertMaintenance() {
    //$('#sailingScheduleMaintenance').modal('setting', 'closable', false).modal('show');
    this.setValidatorRetrieve();
    this.modeForm = "sailingScheduleFlagStatus";
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "modify,delete,new,allotment,export,print";
    } else {
      this.disableToolbarButtons = "delete,print";
    }
  }

  afterSave() {
    this.setValidatorRetrieve();

    /**stupid request is aways stupid **/

    //var self = this;
    //this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findLikeVesselNameOrVesselIdAndIsValid/{query}/*');
    //this.cbVessel.setValue(String(this.model.vesselId).trim(),function(callbackVesselId){
    //  self.cbVoyage.disableCombo = false;
    //  self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+self.model.vesselId+"/"+self.model.bound.toUpperCase()+"/{query}/"+self.model.officeCode);
    //  self.cbVoyage.setValue(self.model.voyage, function(callbackVoyage){
    //      self.gridHeader.url = self.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByVesselVoyageBoundHeader";
    //      self.gridHeader.search = self.model.vesselId + "/" + self.model.voyage + "/" + self.model.bound.toUpperCase() + '/' + self.model.officeCode ;
    //      self.gridHeader.loadData();

    //    //revert back the combo vessel
    //    self.cbVessel.setUrl(self.configService.config.BASE_API.toString() + '/MasterVessels/findLikeVesselNameOrVesselIdAndIsValid/*/{query}');
    //  });
    //});

    /*
    this.model.vesselId = this.prevVesselId;
    this.model.vesselCode = this.prevVesselCode;
    this.model.voyage = this.prevVoyage;
    this.model.bound = this.prevBound;
    this.model.serviceId = this.prevServiceId;
    */

    if (this.model.bound.toUpperCase() == "M") {
      this.model = new MSailingSchedule();
      this.model.voyage = "";
      this.model.bound = "";

      this.cbVessel.setValue("");
      this.cbVoyage.setValue("");

      this.gridHeader.url = "";
      let xPos: number = Number.parseFloat(localStorage.getItem("xPos"));
      let yPos: number = Number.parseFloat(localStorage.getItem("yPos"));

      this.gridHeader.loadData(xPos, yPos);

      if (this.prevSelectedRecord == 0) {
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, 1);
      } else {
        console.log("PREVIOUS RECORD: " + this.prevSelectedRecord);
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, this.prevSelectedRecord);
      }

      //this.gridHeader.onSelect(true, this.gridHeader.listStore.store.length);
      if (this.helper.createBoundM != "Y") {
        this.gridSailingDetail.loadData();
      } else {
        this.gridSailingDetail.onClear();
      }
      // console.log("pancene kowe pabu");
      // console.log("param bound " + this.helper.createBoundM);
    } else {
      this.model.voyage = this.searchVoyage;
      this.model.bound = this.searchBound.toUpperCase();

      let xPos: number = Number.parseFloat(localStorage.getItem("xPos"));
      let yPos: number = Number.parseFloat(localStorage.getItem("yPos"));

      this.gridHeader.loadData(xPos, yPos);
      //this.gridHeader.onSelect(true,Number.parseInt(this.cookieService.getCookie("clickedRow")));

      if (this.prevSelectedRecord == 0) {
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, 1);
      } else {
        this.gridHeader.clearSelectedValues();
        this.gridHeader.onSelect(true, this.prevSelectedRecord);
      }

      var officeCode =
        this.cookieService.getDefaultLocationCode() == null
          ? "*"
          : this.cookieService.getDefaultLocationCode();
      let data = this.gridHeader.getSelectedValues()[0];

      if (data.bound.toUpperCase() == "O") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "delete,print";
        }
        this.getSailingDetail(
          data.vesselId,
          data.voyage,
          data.bound,
          data.serviceId,
          officeCode
        );
      } else if (data.bound.toUpperCase() == "I") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "new,delete,allotment,print";
        }
        this.getSailingDetailInbound(data.id);
      } else if (data.bound.toUpperCase() == "M") {
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons =
            "modify,delete,new,allotment,export,print";
        } else {
          this.disableToolbarButtons = "allotment,print";
        }
        this.gridSailingDetail.onClear();
      }
    }

    this.modeForm = "sailingScheduleFlagStatus";
  }

  setValidatorAllocation() {
    this.validatorRules = {
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input In House Voyage.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regVesVoy,
            prompt: "Only allowed special characters .-/",
          },
        ],
      },
      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
    };
  }

  setValidatorInput() {
    this.validatorRules = {
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input In House Voyage.",
          },
        ],
      },
      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Bound.",
          },
        ],
      },
      serviceId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Service.",
          },
        ],
      },
      localETA: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Local ETA.",
          },
        ],
      },
      localETD: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Local Etd.",
          },
        ],
      },
      blDate: {
        rules: [
          {
            type: "empty",
            prompt: "Please input BL Date.",
          },
        ],
      },
      exchangeRateDate: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Exchange Rate Date.",
          },
        ],
      },
    };
  }

  setValidatorRetrieve() {
    this.validatorRules = {
      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      vesselId: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
    };
  }

  setValidatorSaveScheduleMapping() {
    console.log("masuk ke validation schedule");
    this.clearValidatorErrorScheduleMapping();
    this.validatorRules = {
      mappingSourceVesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      mappingSourceVoyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      mappingFromSource: {
        rules: [
          {
            type: "empty",
            prompt: "Please select SSS/D4A.",
          },
        ],
      },
    };
  }

  clearValidatorErrorScheduleMapping() {
    this.modelMapping["error-mappingSourceVesselCode"] = "";
    this.modelMapping["error-mappingSourceVoyage"] = "";
    this.modelMapping["error-mappingFromSource"] = "";
  }

  confirmDelete() {
    //patch GSO-46
    this.prevVesselId = this.model.vesselId;
    this.prevVoyage = this.model.voyage;
    this.prevBound = this.model.bound.toUpperCase();
    this.prevServiceId == this.model.serviceId;

    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    let deleteIndex: any;

    if (this.gridHeader.getSelectedValues().length == 1) {
      deleteIndex = this.gridHeader.getSelectedValues()[0];
    }

    this.gridHeader.getSelectedValues().forEach((el) => {
      deleteList.push(el.id);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/deleteBoundM",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new MSailingSchedule();

            this.loading = false;
            if (this.gridHeader.getSelectedValues().length == 1) {
              this.gridHeader.listStore.removeData(deleteIndex);
              this.gridHeader.url = "";
              this.gridHeader.loadData();
            }
            this.gridHeader.clearSelectedValues();
            //this.grid.onFirst();
            //this.setGridEvent = 'refresh';
            this.loading = true;
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false; this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  afterDelete() {
    this.model.vesselId = this.prevVesselId;
    this.model.voyage = this.prevVoyage;
    this.model.bound = this.prevBound.toUpperCase();
    this.model.serviceId = this.prevServiceId;
    this.gridHeader.loadData();
  }

  resetErrorPassword(event) {
    this.errorStopBookingPassword = undefined;
  }

  openStopBookingGrid2() {
    // $('#stopBookingMaintenance')
    // .modal('setting', 'closable', false)
    //.modal('show');
    this.genericUtil.showDialog(
      "stopBookingMaintenance",
      "Stop Booking",
      1200,
      500
    );
  }

  openStopBookingGrid() {
    // $('#stopBookingMaintenance')
    // .modal('setting', 'closable', false)
    //.modal('show');

    this.genericUtil.showDialog(
      "stopBookingMaintenance",
      "Stop Booking",
      1200,
      500
    );

    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/getStopBookingData/" +
          this.model.vesselId +
          "/" +
          this.model.voyage +
          "/" +
          this.model.bound +
          "/" +
          this.model.officeCode
      )
      .subscribe((resp) => {
        if (resp.status == 200) {
          // console.log(resp.json()['content']);

          resp.json()["content"].forEach((spal) => {
            console.log(spal);
            this.gridStopBooking.listStore.store.push({
              seqNo: spal.seqno,
              locationId: spal.portofcall,
              terminalId: "",
              enumCode: spal.bcntowner,
              cntStatus: spal.bcntstatus,
              operatorCode: spal.slotoperator,
              status: spal.status,
              stoppedBy: spal.stopby,
              stoppedTime: spal.stopdate,
              resumedBy: spal.resumedby,
              resumedTime: spal.resumeddate,
              locationName: spal.locationName,
              locationCode: spal.portofcall,
              dateCreated: spal.datecreated,
              terminalCode: spal.terminalCode,
              terminalName: spal.terminalName,
            });
          });
          // console.log(this.gridStopBooking.listStore.store)
          this.gridStopBooking.loadData();
          this.loading = false;
          // this.gridStopBooking.listStore.addData(resp.json()['content']);
          //this.gridStopBooking.loadData()

          console.log("masuk after load");
        }
      });
    this.loading = false;
  }

  openResumeBookingWindow() {
    if (this.stopBookingPasswordResume.length < 1) {
      this.errorStopBookingPasswordResume = "Please input password.";
    } else {
      if (this.stopBookingPasswordResume.toUpperCase() != "FISHTANK") {
        this.errorStopBookingPasswordResume = "Incorrect password.";
      } else {
        this.stopBookingPasswordResume = "";
        this.errorStopBookingPasswordResume = undefined;
        this.errorStopBookingPasswordResume = "";

        console.log("isis mopdel error muncul");
        // console.log(this.model);

        if (this.clickIndex > 0) {
          this.genericUtil.closeDialog("resumeScheduleVerification");

          this.actionSaveResume(this.clickIndex);

          this.message(
            "information",
            "Information",
            "Bookings resumed successfully.",
            "okonly",
            {
              ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
            }
          );
        }
      }
    }
  }

  openStopBookingWindow() {
    //if(event.keyCode == 13){

    if (this.stopBookingPassword.length < 1) {
      this.errorStopBookingPassword = "Please input password.";
    } else {
      if (this.stopBookingPassword.toUpperCase() != "FISHTANK") {
        this.errorStopBookingPassword = "Incorrect password.";
      } else {
        this.stopBookingPassword = "";
        this.errorStopBookingPassword = undefined;

        // console.log('isis mopdel')
        // console.log(this.model);
        /*
          this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCall/' + this.model.vesselId + '/' + this.model.voyage + '/' + this.model.bound + '/' + this.model.officeCode + '/[-42]').subscribe((resp)=>{
            console.log(resp.json());
          })
          */
        //this.loading = true;
        this.genericUtil.closeDialog("sailingScheduleVerification");
        this.openStopBookingGrid();
      }
    }

    //}
  }

  triggerButtonClickResumePass(event) {
    console.log(event);
    if (event.keyCode == 13) {
      $("#okStopBookingResume").click();
    } else {
      this.errorStopBookingPasswordResume = undefined;
    }
  }

  triggerbuttonClick(event) {
    if (event.keyCode == 13) {
      $("#buttonStopBooking").click();
    } else {
      this.errorStopBookingPassword = undefined;
    }
  }
  hideVerificationWindow() {
    //reset previous password input and its error message
    this.stopBookingPassword = "";
    this.errorStopBookingPassword = undefined;

    //close current verificaiton window
    //$('#sailingScheduleVerification')
    //.modal('setting', 'closable', false)
    //.modal('hide');

    this.genericUtil.closeDialog("sailingScheduleVerification");

    // this.openStopBookingGrid();

    //reopen the sailing schedule maintenance window
    //$('#sailingScheduleMaintenance').modal('setting', 'closable', false).modal('show');
  }

  hideVerificatioResumenWindow() {
    //reset previous password input and its error message
    this.stopBookingPasswordResume = "";
    this.errorStopBookingPasswordResume = undefined;

    //close current verificaiton window
    //$('#resumeScheduleVerification')
    //.modal('setting', 'closable', false)
    //.modal('hide');
    this.genericUtil.closeDialog("resumeScheduleVerification");

    this.openStopBookingGrid2();

    //reopen the sailing schedule maintenance window
    //$('#sailingScheduleMaintenance').modal('setting', 'closable', false).modal('show');
  }

  infoGridStopBooking(event) {}

  eventGridStopBooking(event) {
    var portCode = "";
    var fieldEvent = event.split(";")[1];
    var indexEvent = event.split(".")[1];
    indexEvent = event.split(";")[0];

    // var no =0;

    // console.log(event.split(".")[0]);
    console.log(event);

    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "afterLoad":
        // console.log(this.model)
        //this.gridStopBooking.disableButtonGrid(1,'btndelete','true')
        // this.gridStopBooking.disableButtonGrid(1,'statusR','true')
        this.tempPort = [];

        var kloop = 1;
        this.gridStopBooking.listStore.store.forEach((yr) => {
          var xx: any[] = [];
          var yy: any[] = [];

          this.dsPortOfCall.forEach((dt) => {
            //console.log("CEK LOAD 1: "+ dt.blFcPop);
            // this.blFcPop = dt.blFcPop;
            //this.locationName = dt.locationName;
            if (dt.locationCode != this.model.officeCode) {
              var md = new SelectModel();
              md["code"] = dt.locationCode;
              md["name"] = dt.locationName;
              xx.push(md);
            }
          });

          // if(this.dsTerminal.length > 1){

          this.dsTerminal.forEach((element) => {
            var minal = new SelectModel();
            minal["code"] = element.terminalCode;
            minal["name"] = element.terminalName;
            yy.push(minal);
          });

          // }

          this.settingGridStopBooking.columns.forEach((jaran) => {
            if (jaran.field == "locationName") {
              jaran.ds = xx;
            }
            if (jaran.field == "terminalName") {
              jaran.ds = yy;
              //jaran.urlCombo = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCallTerminal/'+this.model.vesselId+'/'+this.model.voyage+'/'+this.model.bound+'/'+jaran.field.        VS00496/888/O/SGSIN/SGSIN'
            }
          });

          kloop++;
        });

        var i = 1;
        /*
       this.gridStopBooking.listStore.store.forEach(index=>{

        index.statusbuttonD = false
        index.statusbuttonR = true

          if(index.status == 'STOPPED')
            //index.statusbuttonR = false
            this.gridStopBooking.disableButtonGrid(index.no,'statusR',false);
          else
            this.gridStopBooking.disableButtonGrid(index.no,'statusR',true);
          //else





         //this.gridStopBooking.disableButtonGrid(index.no,'btndelete',index.statusbuttonD);



       })
       */
        var self = this;
        setTimeout(() => {
          self.gridStopBooking.listStore.store.forEach((index) => {
            if (this.lenTerminal == 0) {
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "terminalName",
                true
              );
            }

            if (index.status == "STOPPED") {
              console.log("#1");
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "statusR",
                false
              );
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "enumCode",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "cntStatus",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "locationName",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "operatorCode",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "terminalName",
                true
              );

              //self.gridStopBooking.disableRowGrid(index.no-1,true)
            } else if (index.status == "RESUMED") {
              console.log("#2");

              //self.gridStopBooking.disableRowGrid(index.no-1,true)

              self.gridStopBooking.disableButtonGrid(index.no, "statusR", true);
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "enumCode",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "cntStatus",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "locationName",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "operatorCode",
                true
              );
              self.gridStopBooking.disableColumnGrid(
                index.no,
                "terminalName",
                true
              );
            } else {
              console.log("#3");

              //self.gridStopBooking.disableRowGrid(index.no-1,false)

              self.gridStopBooking.disableButtonGrid(index.no, "statusR", true);
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                false
              );
              //self.gridStopBooking.disableColumnGrid(index.no,'enumCode',false);
            }
          });
        }, 100);

        if (this.gridStopBooking.listStore.store.length > 0) this.rowStop = 1;
        //this.gridStopBooking.loadData();

        this.gridStopBooking.listStore.store.forEach((pitch) => {
          if (
            pitch.status != "RESUMED" &&
            this.tempPort.indexOf(pitch.locationCode) < 0 &&
            pitch.locationCode != "ALL"
          ) {
            //console.log('masuk temport');
            //console.log(pitch.status)
            //console.log(this.tempPort.indexOf(pitch.locationCode))

            this.tempPort.push(pitch.locationCode);
          }
        });
        console.log("aftr process afterload");

        console.log(this.gridStopBooking.listStore.store);

        break;

      case "click":
        // this.clickIndex = 0;
        var valseq = 0;
        var mode = event.split(".")[1].split("-")[0];
        // var id:string = event.split(".")[1].split("-")[0];
        var nos = Number.parseInt(event.split(".")[1].split("-")[1]);

        valseq = no - 1;
        //this.gridStopBooking.disableButtonGrid(1,'statusR','true');
        switch (mode) {
          case "checked":
            this.selectedListMod(no, this.gridStopBooking, "no");
            break;
        }

        if (mode == "statusR") {
          console.log("indexnoss" + nos);
          // this.gridStopBooking.disableButtonGrid(nos, 'STATUSR', true);
          // this.gridStopBooking.disableButtonGrid(2, 'statusR', 'false');
          this.clickIndex = nos;
          console.log("after inser index " + this.clickIndex);
          this.verifyResume(nos);
        }

        if (mode == "btndelete") {
          console.log(mode + " - " + nos);
          // if(this.gridStopBooking.listStore.store.length != nos){
          this.gridStopBooking.listStore.store.splice(nos - 1, 1);
          this.gridStopBooking.loadData();
          //.delete(no-1);
          // }

          if (this.gridStopBooking.listStore.store.length < 1) {
            this.rowStop = 0;
            this.rowPrint = 0;
          }
        }

        /*
          var mode = event.split(".")[1].split("-")[0];
          var no = event.split(".")[1].split("-")[1];
          var valseq = no - 1;
          var portCode = this.gridStopBooking.listStore.store[valseq].locationCode;
          switch(mode){


            case 'checked' :

            // console.log('nomer kurang 1' + valseq);



              //this.selectedListMod(no, this.gridSailingDetail,'no');
              //this.gridStopBooking.setUrlCombo(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCall/'+this.model.vesselId+'/'+this.model.voyage+'/'+this.model.bound+'/'+this.model.officeCode+'/'+'{query}',1,'locationId')
              // console.log('checked mod');
              break;
           case 'unchecked' :
              break;
          }
          */
        break;

      case "AfterEdit":
        this.tempPort = [];
        var valseq = 0;
        var mode = event.split(".")[1].split("-")[0];
        var no = event.split(".")[1].split(";")[0];
        valseq = no - 1;
        var zz: any[] = [];
        // console.log('dieditidiit'+no);
        portCode = this.gridStopBooking.listStore.store[no - 1].locationCode;
        // console.log('after edit checked mod' + valseq);
        // console.log('after edit portcode'+portCode);
        // this.gridStopBooking.disableButtonGrid(1,'statusR','true');
        // // console.log(this.gridStopBooking.listStore.store[valseq].locationCode);

        //if(this.settingGridStopBooking.columns[valseq].field == 'terminalName'){

        //// console.log('masuk set url comob')

        // this.settingGridStopBooking.columns[valseq].urlCombo =

        var self = this;
        var termAny: any;
        setTimeout(() => {
          self.gridStopBooking.listStore.store.forEach((index) => {
            //phase 1 detect redundant port of call
            console.log(fieldEvent + "-" + indexEvent.split(".")[1]);
            if (index.no == indexEvent.split(".")[1]) {
              self.genericService
                .GET(
                  self.configService.config.BASE_API.toString() +
                    "/MasterSailingSchedules/distinctPortOfCallTerminalVer2/" +
                    self.model.vesselId +
                    "/" +
                    self.model.voyage +
                    "/" +
                    self.model.bound +
                    "/" +
                    index.locationCode +
                    "/" +
                    self.model.officeCode
                )
                .subscribe((resp) => {
                  termAny = resp.json()["content"];
                  console.log("dari after edit");
                  if (fieldEvent == "locationName") {
                    index.terminalName = "";
                    index.terminalCode = "";
                    //console.log(resp.json()['content']);

                    if (termAny.length > 1) {
                      self.gridStopBooking.disableColumnGrid(
                        index.no,
                        "terminalName",
                        false
                      );
                      if (index.terminalName == "") {
                        index.terminalName = "ALL";
                        index.terminalCode = "ALL";
                      }
                      termAny.forEach((dt) => {
                        //console.log("CEK LOAD 1: "+ dt.blFcPop);
                        // this.blFcPop = dt.blFcPop;
                        //this.locationName = dt.locationName;

                        var isi = new SelectModel();
                        isi["code"] = dt.terminalCode;
                        isi["name"] = dt.terminalName;
                        zz.push(isi);
                      });

                      this.settingGridStopBooking.columns.forEach((eachs) => {
                        if (eachs.field == "terminalName") {
                          eachs.ds = zz;
                        }
                      });
                    } else if (termAny.length == 1) {
                      termAny.forEach((dt) => {
                        //console.log("CEK LOAD 1: "+ dt.blFcPop);
                        // this.blFcPop = dt.blFcPop;
                        //this.locationName = dt.locationName;

                        index.terminalCode = dt.terminalCode;
                        index.terminalName = dt.terminalName;
                        //zz.push(isi);
                      });
                      self.gridStopBooking.disableColumnGrid(
                        index.no,
                        "terminalName",
                        true
                      );
                    } else {
                      //end length termAnaye

                      index.terminalName = "";
                      index.terminalCode = "";
                      self.gridStopBooking.disableColumnGrid(
                        index.no,
                        "terminalName",
                        true
                      );
                    }
                  }
                });
            }

            if (index.status == "STOPPED") {
              console.log("#1");

              //  this.settingGridStopBooking.columns.map(v=>Object.assign({}, {editType: undefined}))

              self.gridStopBooking.disableButtonGrid(
                index.no,
                "statusR",
                false
              );
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                true
              );
            } else if (index.status == "RESUMED") {
              console.log("#2 After Edit");

              // this.settingGridStopBooking.columns.map(v=>Object.assign({}, {editType: undefined}))

              self.gridStopBooking.disableButtonGrid(index.no, "statusR", true);
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                true
              );
            } else {
              console.log("#3 After Edit");

              //this.settingGridStopBooking.columns.map(v=>Object.assign({}, {editType: 'text'}))

              self.gridStopBooking.disableButtonGrid(index.no, "statusR", true);
              self.gridStopBooking.disableButtonGrid(
                index.no,
                "btndelete",
                false
              );
            }
          });
        }, 100);

        //var self = this;
        this.gridStopBooking.listStore.store.forEach((pitch) => {
          if (pitch.locationCode == "" || pitch.locationName == "") {
            pitch.locationCode = "ALL";
            pitch.locationName = "ALL";
          }

          if (
            pitch.status != "RESUMED" &&
            this.tempPort.indexOf(pitch.locationCode) < 0 &&
            pitch.locationCode != "ALL"
          ) {
            //console.log('masuk temport');
            //console.log(pitch.status)
            //console.log(this.tempPort.indexOf(pitch.locationCode))

            this.tempPort.push(pitch.locationCode);
          }
        });

        //
        // console.log('log arrray temp port');
        //console.log(this.tempPort)

        //this.gridStopBooking.setUrlCombo(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCallTerminal/' + this.model.vesselId + '/' + this.model.voyage + '/' + this.model.bound + '/' + portCode + '/' + this.model.officeCode + '/{query}', valseq + 1, 'terminalName');//        VS00496/888/O/SGSIN/SGSIN';

        //}

        //this.gridStopBooking.listStore.store.forEach(vallen=>{

        //})
        // this.gridStopBooking.setUrlCombo(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCall/'+this.model.vesselId+'/'+this.model.voyage+'/'+this.model.bound+'/'+this.model.officeCode+'/'+'{query}',1,'locationId')

        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":
              break;
            case "dblClick":
              // console.log('double click mod')
              break;

            default:
          }
        }

        break;
    }
  }

  addStopBookingRow() {
    // console.log('add detail')
    var flagStop = 0;
    var flagName = "";
    var trmName = "";
    var trmCode = "";

    this.gridStopBooking.listStore.store.forEach((xt) => {
      if (
        xt.locationName == "ALL" &&
        xt.enumCode == "ALL" &&
        xt.cntStatus == "BOTH" &&
        xt.status == "STOPPED"
      ) {
        flagStop = 1;
        flagName = xt.stoppedBy;
      }
    });

    if (this.lenTerminal > 1) {
      trmCode = "ALL";
      trmName = "ALL";
    } else if (this.lenTerminal == 1) {
      trmName = this.dsTerminal[0].terminalName;

      trmCode = this.dsTerminal[0].terminalCode;
    } else {
      trmCode = "";
      trmName = "";
    }

    if (flagStop == 0) {
      this.detail = new DataDetail();
      this.rowStop = 1;
      this.rowPrint = 1;

      this.gridStopBooking.listStore.store.push({
        no: 0,
        seqNo: 0,
        locationId: "",
        terminalId: "",
        enumCode: "ALL",
        cntStatus: "BOTH",
        operatorCode: "",
        status: "",
        stoppedBy: "",
        stoppedTime: "",
        resumedBy: "",
        resumedTime: "",
        locationName: "ALL",
        locationCode: "ALL",
        dateCreated: "",
        terminalName: trmName,
        terminalCode: trmCode,
      });
      //this.gridStopBooking.loadData();
      //console.log('detailsss');
      //console.log(this.detail);

      //this.gridStopBooking.listStore.addData(this.detail);

      // this.gridStopBooking.listStore.store.forEach(yx=>{

      //})

      //this.gridStopBooking.disableButtonGrid(2,'statusR','false');

      //this.gridStopBooking.listStore.store.forEach(key=>{
      //key.stoppedTIme = 1;
      //});

      /*
        this.settingGridStopBooking.columns.forEach(jaran => {
          if (jaran.field == 'locationName') {
            jaran.urlCombo = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCall/' + this.model.vesselId + '/' + this.model.voyage + '/' + this.model.bound + '/' + this.model.officeCode + '/{query}';
          }
          // else if(jaran.field == 'terminalName'){
          //jaran.urlCombo = this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctPortOfCallTerminal/'+this.model.vesselId+'/'+this.model.voyage+'/'+this.model.bound+'/'+jaran.field.        VS00496/888/O/SGSIN/SGSIN'
          //}
        });*/

      this.gridStopBooking.loadData();
    } else {
      //end if flagstop

      this.message(
        "information",
        "Information",
        "ALL Bookings stopped by " + flagName.toUpperCase() + ".",
        "okonly",
        {
          ok: "this.loading=false;this.isLoad=false;this.openStopBookingGrid2();",
        }
      );
    }

    //console.log(this.detail);
    //this.gridStopBooking.disableButtonGrid(9,'btndelete','true')
    //  this.gridStopBooking.disableButtonGrid(8,'statusR','true')
  }

  hide2() {
    this.gridStopBooking.onClear();
    this.genericUtil.closeDialog("stopBookingMaintenance");
    /*
    console.log('close')
    //close current verificaiton window
    setTimeout(function(){
    $('#stopBookingMaintenance')
      .modal('setting', 'closable', false)
      .modal('hide');
    },200);
    /*
        //close current verificaiton window
        $('#sailingScheduleVerification')
          .modal('setting', 'closable', false)
          .modal('hide');
    */
    //reopen the sailing schedule maintenance window
    //$('#sailingScheduleMaintenance').modal('setting', 'closable', false).modal('show');
  }

  handleClose() {
    // handle close event
    this.router.navigateByUrl("/main/home");
  }

  printSailingSchedule() {}

  hidePrintSailingSchedule() {
    //close current verificaiton window
    $("#printSailingSchedule")
      .modal("setting", "closable", false)
      .modal("hide");
  }

  // * Schedule Mapping Services

  onScheduleMapping_Save() {
    this.confirmAction = "save";
    this.msg = "Are you sure want to save the data?";
    //this.genericUtil.showDialog("msgDialog", "Information", 420, 160, 103);

    this.loading = true;
      this.message(
        "save",
        "Saving data",
        "Do you want to save the record? ",
        "yesno",
        {
          yes: "this.saveEventMapping()",
          no: "this.loading = false;",
        }
      );
  }

  saveEventMapping(){
    this.loading = true;
    console.log("final broo = ", this.modelMapping);

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/saveMappingSailingSchedule",
        this.modelMapping
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSaveMapping();" }
            );
          } else {
          this.loading = false;
          this.message(
            "warning",
            "Warning",
            resp.json()["error"],
            "okonly",
            { ok: "this.loading=false;" }
          );
          }
          console.log("response = ", resp);
      },
      (error) => {
        this.loading = false;
        // console.log(error.message);
        this.info = error.message;
        this.loading = false;
      }
    );
  }

  afterSaveMapping(){
    this.onScheduleMapping_Cancel();
  }

  onScheduleMapping_Delete() {
    // this.confirmAction = "save";
    this.msg = "Are you sure want to delete the data?";
    //this.genericUtil.showDialog("msgDialog", "Information", 420, 160, 103);

    this.loading = true;
      this.message(
        "delete",
        "Delete data",
        "Do you want to delete the record? ",
        "yesno",
        {
          yes: "this.deleteEventMapping()",
          no: "this.loading = false;",
        }
      );
  }

  deleteEventMapping(){
    let deleteList = [];
    deleteList.push(this.mappingId);
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/deleteMappingSailingSchedule",
        deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "SUCCESS") {

            this.message(
              "information",
              "Information",
              "Record delete successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSaveMapping();" }
            );
          } else {
            this.message(
              "warning",
              "Warning",
              resp.json()["error"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
          console.log("response = ", resp);
      },
      (error) => {
        this.loading = false;
        // console.log(error.message);
        this.info = error.message;
        this.loading = false;
      }
    );

  }

  onScheduleMapping_Cancel() {
    this.genericUtil.closeDialog("scheduleMappingForm");
    this.model.scheduleMapping = [];
    this.bodyValidationOfMappingSchedule = [];
    this.gridApiScheduleMapping.hideOverlay();
    this.rowSelected = [];
    this.rowDataScheduleMapping = [];
    this.clearFieldScheduleMapping();
    this.clearValidatorErrorScheduleMapping();
    this.btnSaveMapping = true;
    this.btnDeleteMapping = true;
    this.mappingId = 0;

  }

  onScheduleMapping_Add() {
    var mappingSourceTmp = this.mappingSource;
    this.setValidatorSaveScheduleMapping();
    this.modelMapping.mappingFromSource = mappingSourceTmp;

    this.isErrorScheduleMapping = this.onValidate(this.modelMapping);

    this.bodyValidationOfMappingSchedule = [];
    if (!this.isErrorScheduleMapping) {
      let paramGlosSys = {
        officeCode: this.gridHeader.getSelectedValues()[0].officeCode,
        vesselId: this.gridHeader.getSelectedValues()[0].vesselId,
        voyage: this.gridHeader.getSelectedValues()[0].voyage,
        bound: this.gridHeader.getSelectedValues()[0].bound,
        source: "GlosSys"
      }
      this.bodyValidationOfMappingSchedule.push(paramGlosSys);
  
      let paramD4A = {
        officeCode: "",
        vesselId: "",
        vesselCode: this.modelMapping.mappingSourceVesselCode ? this.modelMapping.mappingSourceVesselCode.trim() : "",
        voyage: this.modelMapping.mappingSourceVoyage,
        bound: this.modelMapping.mappingSourceBound,
        source: "D4A"
      }
  
      this.bodyValidationOfMappingSchedule.push(paramD4A);
  
      this.isLoadingValidationOfMappingSchedule = true;
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/theValidationOfMappingSchedule", this.bodyValidationOfMappingSchedule)
        .subscribe((resp) => {
          this.isLoadingValidationOfMappingSchedule = false;
          if (resp.status == 200) {
            if (resp.json()["status"] == "ok") {
              this.onScheduleMapping_Add_Progress();
            } else {
              this.message(
                "warning",
                "Warning",
                resp.json()["message"],
                "okonly",
                { ok: "" }
              );
            }
          }
        }, err=>{
          this.isLoadingValidationOfMappingSchedule = false;
          this.message(
            "Error",
            "error",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
        });
    }
  }

  onScheduleMapping_Add_Progress(){
    let officeCodeMapping =
          this.cookieService.getDefaultLocationCode() == null
            ? "*"
            : this.cookieService.getDefaultLocationCode();

      this.modelMapping.id = this.mappingId;
      this.modelMapping.mappingSailingScheduleId = this.mappingSailingScheduleId;
      this.modelMapping.mappingServiceId = this.serviceId;
      this.modelMapping.isValid = this.mappingIsValid;
      this.modelMapping.mappingVesselId = this.model.vesselId;
      this.modelMapping.mappingVesselCode = this.model.vesselCode;
      this.modelMapping.mappingVesselName = this.model.vesselName;
      this.modelMapping.mappingVoyage = this.mappingVoyage;
      this.modelMapping.mappingBound = this.mappingBound;
      this.modelMapping.officeCode = officeCodeMapping;
      this.modelMapping.userCreated = this.cookieService.getName();
      this.modelMapping.dateCreated = this.genericUtil.getPSIDate();
      this.modelMapping.userModified = this.cookieService.getName();
      this.modelMapping.dateModified = this.genericUtil.getPSIDate();

      this.model.scheduleMapping.push(this.modelMapping);
      this.gridApiScheduleMapping.setRowData(this.model.scheduleMapping);
      this.cbVesselScheduleMapping.setValue("");
      this.cbVoyageScheduleMapping.setValue("");
      this.btnAddMapping = true;
      this.btnSaveMapping = false;
      if(this.mappingId == 0){
        this.btnDeleteMapping = true;
      }else{
        this.btnDeleteMapping = false;
      }
  }

  onScheduleMapping_Remove() {
    this.confirmAction = "remove";
    const selectedLength = this.rowSelected.length;
    this.btnSaveMapping = true;

    if (selectedLength > 0) {
      this.modelMapping = new ScheduleMapping();
      this.model.scheduleMapping = [];
      this.gridApiScheduleMapping.hideOverlay();
      this.rowSelected = [];
      this.rowDataScheduleMapping = [];
      // this.clearFieldScheduleMapping();
      this.cbVesselScheduleMapping.setValue("");
      this.cbVoyageScheduleMapping.setValue("");
      this.btnAddMapping = false;
      this.lockMapping = true;
      // this.msg = "Are you sure want to remove the mapping?";
      //this.genericUtil.showDialog("msgDialog", "Information", 420, 160, 103);
    }

  }

  confirmMappingMsg() {
    if (this.confirmAction === "remove") {
      const selectedRow = this.gridApiScheduleMapping.getFocusedCell();

      this.model.scheduleMapping.splice(selectedRow.rowIndex, 1);
      this.gridApiScheduleMapping.setRowData(this.model.scheduleMapping);
      this.closeMsgDialog();
    } else {
      this.closeMsgDialog();

      this.msg = "Record has been saved successfully.";
      this.confirmSaved = true;
      //this.genericUtil.showDialog("msgDialog", "Information", 420, 160, 103);
    }
  }

  initTab1() {
    this.tabStatus = "tab1";
    this.tabStatus1 = "active";
  }

  changeEventScheduleOption(event: any) {
    this.modelMapping.mappingFromSource = event.target.value;
    this.mappingSource = event.target.value;
    this.lockMapping = false;
  }

  clearFieldScheduleMapping() {
    this.modelMapping = new ScheduleMapping();
    this.cbVesselScheduleMapping.setValue("");
    this.cbVoyageScheduleMapping.setValue("");
    // this.mappingSailingScheduleId = 0;
    this.mappingVoyage = "";
    this.mappingBound = "";
    this.mappingIsValid = "";
    this.mappingSource = "";
    this.serviceId = "";
    this.lockMapping = true;
    this.btnAddMapping = false;
  }



  closeMsgDialog() {
    this.confirmSaved = false;
    this.genericUtil.closeDialog("msgDialog");
  }

  // * End Schedule Mapping Services

  changeEventLocalETD(event: any){
    event.preventDefault();
    this.blDate.setValue(event.target.value);
  }

  changeEventexchangeRateDate(event: any){
    event.preventDefault();
    this.exchangeRateDate.setValue(event.target.value);
  }

  changeEventLockedBLDate(event) {
    // console.log(this.dateLocalETD.getValue());
    if (event.target.checked) {
      this.model.lockedBlDate = "Y";
      this.lockBLDate = false;
    } else {
      this.model.lockedBlDate = "N";
      this.lockBLDate = false;
      this.blDate.setValue(this.dateLocalETD.getValue())
      // this.blDate.setValue(this.model.localETD);
    }
  }

  changeEventLockedExchangeRateDate(event) {
    if (event.target.checked) {
      this.model.lockedExchangeRateDate = "Y";
      this.lockExchangeRateDate = false;
    } else {
      this.model.lockedExchangeRateDate = "N";
      this.lockExchangeRateDate = false;
      this.exchangeRateDate.setValue(this.dateLocalETA.getValue())

      // this.exchangeRateDate.setValue(this.model.localETA);
    }
  }

  noneSpace(event: any) {
    // console.log("Event none => " + event);
    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }

  OIMonly(event: any) {
    if (!(event.key == "O" || event.key == "I" || event.key == "M")) {
      event.preventDefault();
    }
  }

  CharNumOnly(event: any) {
    var regexPattern = new RegExp("[A-Za-z0-9-./ ]");
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    validSPChar: string = ""
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `Only accept alphanumeric ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  checkIfError(field: string, regexName: RegExp) {
    if (regexName.test(this.model[field]) == true) {
      this.model[`error-${field}`] = "";

      return true;
    }

    return false;
  }
}
