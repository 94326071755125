import { finalize } from 'rxjs/operators';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Authorize, Validator, GridPluginComponent,
  DialogPluginComponent, ComboPluginComponent, GenericService,
  GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { BLHeader } from '../shared/index';

import FormValidation from '../../utils/formValidation';
import {forEach} from '@angular/router/src/utils/collection';
declare  var $: any;



export class InvoiceReason {
  invoiceOfficeCode = '';
  invoiceBlNo = '';
  invoiceNo = '';
  invoiceReason = '';
}

export class UserDetails {
  userDeptCode = '';
  userDeptName = '';
  userFullName = '';
  userId = '';
  userName = '';
  userPhone = '';
}

export class InvoiceParam {
  invVesselCode = '';
  invVesselId = '';
  invVesselName = '';
  invVesselVoyage = '';
  invVesselBound = '';
  invPayerCode = '';
  invPayerName = '';
  invLoadPortCode = '';
  invLoadPortName = '';
  invAccCode = '';
  invTransshipmentPortCode = '';
  invTransshipmentPortName = '';
  invPlaceOfPaymentCode = '';
  invPlaceOfPaymentName = '';
  invDischargePortCode = '';
  invDischargePortName = '';
  invIsCoc = 'Y';
  invIsSoc = '';
  invIsSlot = '';
  invIsCocTs = '';
  invIsSocTs = '';
  invDeptcode = '';
  invUserName = '';


}

export class ToggleOption {
  invOfficeCode = '';
  invBlNo = '';
  invNo = '';
  invPrintByCurrency = 'N';
  invConvertedToSgd = 'Y';
  invUserName = '';
  invUserDeptCode = '';
}

export class CancellingParam {
  blOfficeCode = '';
  blNo = '';
  invNo = '';
  reason = '';
  userId = '';
  invStatus = ''
}

@Component({
  selector: 'app-report-finance-proforma-freight-bill-latest-page',
  templateUrl: './report-finance-proforma-freight-bill-latest-page.component.html',
  styleUrls: ['./report-finance-proforma-freight-bill-latest-page.component.css']
})
export class ReportFinanceProformaFreightBillLatestPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbPayer') cbPayer: ComboPluginComponent;
  @ViewChild('cbPlacePayment') cbPlacePayment: ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort: ComboPluginComponent;
  @ViewChild('cbTranshipmentPort') cbTranshipmentPort: ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD: ComboPluginComponent;
  @ViewChild('grid') grid: GridPluginComponent;
  @ViewChild('gridPDFPrev') gridPDFPrev: GridPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;

  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = 'retrieve,ok';

  modeToolbar = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPayer;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingLoadPort;
  settingDischargePort;
  settingTranshipmentPort;
  settingGrid;
  settingGridPDFPrev;
  settingPDF;
  settingPlacePayment;
  newDialogOpt;
  addRemoveButton = '';

  officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
  userId = '';
  userEmail = '';
  officeId = '';

  // lock
  cButtonLock = true;
  dButtonLock = true;
  isError: Boolean = false;
  isHidden: Boolean = true;



  selection: string;
  validatorRules = {};
  jasperPath = [];
  dataBLArr = [];
  dataInvoice = [];
  printCopy = 1;
  gridData = [];
  gridRowData = [];

  rowDataProforma: any[];
  columnDefs;
  gridApiProforma;
  gridColumnProforma;

  getRowStyle;
  isRowSelectable;
  // dialog handler
  frmDialogMessage;
  mergedId: number;
  rowDataUncheck = [];

  // When cancel invoice, then allow to re-retrieve the same BL without checking BL duplication (by removing BL's old result).
  // This variable intended to store BL numbers that JUST have cancelled invoice(s).
  justCancelButNotReretrieveBlNos = [];

  // url related service
   userDetails = new UserDetails();
   invoiceParam = new InvoiceParam();
   toggleOption = new ToggleOption();
    invReason = new InvoiceReason();
    components: { showCellRenderer: () => void; };

  rowIndexW = 0;

  constructor(private auth: Authorize, private genericService: GenericService,
              private genericUtil: GenericUtil, private router: Router,
              private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        {name: 'OK', event: 'ok', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Proforma Invoice'
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() +
        '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 250},
        {header: 'Valid', field: 'isValid', width: 50}
      ],
      maxLength: 20,
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url: 'N',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150},
        {header: 'Local ETA', field: 'localEta', width: 300}
      ],
      maxLength: 20,
    };

    this.settingPayer = {
      id          : 'cbPayer',
      type        : 'search enter', // search | select | select input
      url: 'N',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'invPayerName',
      description  : '',
      isMark      : true,
      columns     : [
                    {header: 'Payer Id', field: 'invPayerId', width: 100},
                    {header: 'Payer Name', field: 'invPayerName', width: 300}
      ],
      maxLength: 200,
    };


    this.settingPlacePayment = {
      id          : 'cbPlacePayment',
      type        : 'search enter', // search | select | select input
      url         :  this.configService.config.BASE_API.toString() + '/invoice/findPOP/{query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'invPlaceOfPaymentName',
      description  : '',
      columns     : [
        {header: 'Location Code', field: 'invPlaceOfPaymentCode', width: 150},
        {header: 'Location Name', field: 'invPlaceOfPaymentName', width: 300},
      ],
      maxLength: 5,
    };


    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url: 'N',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'invPortOfLoadingName',
      description  : '',
      columns     : [
        {header: 'Location Code', field: 'invPortOfLoadingCode', width: 150},
        {header: 'Location Name', field: 'invPortOfLoadingName', width: 300},

      ],
      maxLength: 300,
    };

     this.settingDischargePort = {
      id          : 'cbDiscPort',
      type        : 'search enter', // search | select | select input
       url: 'N',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'invPortOfDischargeName',
      description  : '',
      columns     : [
        {header: 'Location Code', field: 'invPortOfDischargeCode', width: 150},
        {header: 'Location Name', field: 'invPortOfDischargeName', width: 300},
      ],
      maxLength: 300,
    };

    this.settingTranshipmentPort = {
      id          : 'cbTranshipmentPort',
      type        : 'search enter', // search | select | select input
      url: 'N',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search...',
      title       : 'invPortOfTransshipmentName',
      description  : '',
      columns     : [
        {header: 'Location Code', field: 'invPortOfTransshipmentCode', width: 150},
        {header: 'Location Name', field: 'invPortOfTransshipmentName', width: 300},

      ],
      maxLength: 300,
    };



    this.settingGridPDFPrev = {
      id : 'gridPDFPrev',
      url: 'N',
      page: '10',
      columns: [
        {header: 'Invoice No', field: 'invoiceNo', width: 250, editType: 'text'},
      ],
      buttons: [
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
      editable : false,
      height: 500,
      minHeight: 500,
      autoSize: 'auto'
    };

    this.columnDefs = [
        {
          headerName: '',
          width: 50,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          checkboxSelection: true,
          showDisabledCheckboxes: true, // only available from AG Grid 28.1
        },
        {
          headerName: 'Invoice No.',
          field: 'invNo',
          width: 180,
          editable: this.checkInvNoEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 10, rows: 1, cols: 20 }
        },
        {
          headerName: 'B/L No',
          field: 'blNo',
          width: 200,
          editable: this.checkBlNoEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 20, rows: 1, cols: 20 }
        },
        {headerName: 'Payer', field: 'payer', width: 250},
        {
          headerName: 'Reason(s)',
          field: 'reason',
          width: 250,
          editable: this.checkReasonEditable.bind(this),
          cellEditor: 'agLargeTextCellEditor',
          cellEditorParams: { maxLength: 100, rows: 1, cols: 30 }
        },
        {headerName: 'Status', field: 'status', width: 280},
        {headerName: 'Invoice by Currency', field: 'invByCurr', width: 150, cellRenderer: this.byCurRender.bind(this)},
        {
          headerName: 'Convert to SGD', field: 'convSGD', width: 150, chekboxSelection: false,
          cellRenderer: this.convertToSGDCellRenderer.bind(this)
        },
        {
          headerName: 'Do Not Convert to SGD', field: 'idconvNonSGD', width: 200,
          cellRenderer: this.doNotConvertToSGDCellRenderer.bind(this)
        },
      ];


    this.settingGrid = {
      id : 'grid',
      url: '',
      page: 10,
      columns: [
        {header: 'Invoice No.', field: 'blNo', width: 180, editType: 'text'},
        {header: 'B/L No', field: 'blNo', width: 250, editType: 'text'},
        {header: 'Payer', field: 'payer', width: 250, editType: 'text'},
        {header: 'Reason(s)', field: 'reason', width: 250, editType: 'text'},
        {header: 'Status', field: 'status', width: 250, editType: 'text'},
        {header: 'Invoice by Currency', field: 'invoiceNo', width: 250, editType: 'text'},
        {header: 'Convert to SGD', field: 'invoiceNo', width: 250, editType: 'text'},
        {header: 'Do Not Convert to SGD', field: 'invoiceNo', width: 250, editType: 'text'},

      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],

      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      editable: true,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    };

    this.settingPDF = {
      id : 'pdfContainer',
      url : 'N',
      hidePrint : false,
      tabOptio : false
    };

    this.getRowStyle = function(params) {
      if (params.node.rowPinned) {
        return { 'font-weight': 'bold' };
      }
      if (params.node.data.invIsChild === 'Y') {
        return {'background' : '#90EE90'};
      }
    };

    this.isRowSelectable = function(params) {
      return !params.data || params.data.invStatus !== 'C' && params.data.invIsValid !== 'N';
    };

  }
  ngOnInit() {
    this.isHidden = false;
    this.disableToolbarButtons = 'retrieve,ok';
    this.rowDataProforma = [];

    // Initial value of accoutn code
    this.invoiceParam.invAccCode = '';
    this.cbPayer.disableCombo = true;
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.mergedId = 0;
  }

  ngAfterViewInit() {
      $('.dataTables_scrollBody').css('height', '395px');
      $('#silentMode').prop('checked', true);

      this.cbPlacePayment.setForceValue('');
      this.userId = this.cookieService.getName();
      this.userEmail = localStorage.getItem('logged').split('|')[1];
      this.officeId = (this.cookieService.getDefaultLocationId() == null) ? '*' : this.cookieService.getDefaultLocationId();

      this.isHidden = false;

      // Invoice setup related
      this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getUserDetails/' +
        this.cookieService.getName().toUpperCase()).subscribe((resp) => {
        if (resp.json() !== null) {
          this.userDetails.userId = resp.json()['userId'];
          this.userDetails.userDeptCode = resp.json()['userDeptCode'];
          this.userDetails.userDeptName = resp.json()['userDeptName'];
          this.userDetails.userPhone = resp.json()['userPhone'];
          this.userDetails.userFullName = resp.json()['userFullName'];
          this.userDetails.userName = resp.json()['userName'];

          // setup radio button on load
          this.setBound();

          this.invoiceParam.invUserName = this.userDetails.userName;
          this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
        }
      });

      this.disableToolbarButtons = 'retrieve,ok,';

  }

  setBound() {
    if (this.userDetails.userDeptCode === '') {
      $('#selBoundO').prop('checked', false);
      $('#selBoundI').prop('checked', true);
      this.invoiceParam.invVesselBound = 'I';
    } else {
      if (this.userDetails.userDeptCode.substr(0, 1) === 'I') {
        $('#selBoundO').prop('checked', false);
        $('#selBoundI').prop('checked', true);
        this.invoiceParam.invVesselBound = 'I';
      } else {
        $('#selBoundO').prop('checked', true);
        $('#selBoundI').prop('checked', false);
        this.invoiceParam.invVesselBound = 'O';
      }
    }
  }

  preventPrintOnBlankStatus(){
    const invalidInvoices = [];
    this.gridApiProforma.getSelectedRows().forEach(dt => {
      if (dt.invStatus === 'A' && dt.status === '') {
          invalidInvoices.push(dt.invNo);
      }
    });

    if (invalidInvoices.length > 0) {
      this.loading = true;
      this.dialogPlugin.show('information', 'Information',
        'Invoice: ' + invalidInvoices.join(', ') + ' cannot be printed due to blank status.', 'okonly', {ok: 'this.loading=false;'});
      return true;
    } else {
      return false;
    }
  }


  preventPrintActualOnProforma(){
    const invalidInvoices = [];
    this.gridApiProforma.getSelectedRows().forEach(dt => {
      if (dt.invStatus === 'A') {
        invalidInvoices.push(dt.invNo);
      }
    });

    if (invalidInvoices.length > 0) {
      this.loading = true;
      this.dialogPlugin.show('information', 'Information',
        'Invoice: ' + invalidInvoices.join(', ') + ' already in Actual state, cannot be printed through PI.', 'okonly', {ok: 'this.loading=false;'});
      return;
    }
  }

  onCancellationDialogYes() {
    this.loading = false;
    this.doCancelInvoices();
    this.genericUtil.closeDialog('invCancellationDialogYesNo');
  }

  onCancellationDialogNo() {
    this.loading = false;
    this.genericUtil.closeDialog('invCancellationDialogYesNo');
  }


  onLockedDialogOk() {
    this.loading = false;
   // this.doCancelInvoices();
    this.genericUtil.closeDialog('invLockedOkOnly');
  }


  /**
   * Validate & Cancel chosen actual invoices.
   */
  doCancelInvoicesWrapper() {
    const self = this;
    // Changes detection
    const detectionParams = [];
    this.gridApiProforma.getSelectedRows().forEach(dt => {
      if (dt.invStatus === 'A' || (dt.invStatus === 'P' && dt.invIsPrinted === "Y" && dt.reason.trim() != "")) {
        detectionParams.push({
          invOfficeCode: this.officeCode,
          invBlNo: dt.blNo,
          invNo: dt.invNo,
          invStatus: dt.invStatus
        });
      }
    });
    this.loading = true;
    this.genericService.POST(this.configService.config.BASE_API.toString() +
      '/invoice/theMightyOkValidation', detectionParams).subscribe((resp) => {
      if (resp.ok) {
        console.log(resp.json().error.errType);
        console.log(resp.json().error);
        if (resp.json().error.errType === 'CONTAIN_LOCKED_AI') {
          // locked invoice handler
          this.loading = true;

          let preMsg = 'Cancellation is Not Allowed. <br><br>Affected Invoice(S):<br>';
          resp.json().error.errorData.forEach(dt => {
              preMsg += dt.invNo + '<br>';
          });


          this.frmDialogMessage = preMsg;
          this.genericUtil.showDialog(
            'invLockedOkOnly',
            'Confirm',
            500,
            200
          );




        } else {
            const changeDetectedErrors = resp.json().error.filter(err => err.errType === 'CHANGE_DETECTION_ERROR');
            const changeDetectedInvoices = changeDetectedErrors.map(err => err.errorData);
            let changeDetectedInvNos = changeDetectedInvoices.map(inv => inv.invNo);

            // Remove duplicated inv number
            changeDetectedInvNos = changeDetectedInvNos.filter(function(item, pos, self) {
              return self.indexOf(item) == pos;
            });

            const actualInvNos = detectionParams.filter(inv => inv.invStatus === 'A').map(inv => inv.invNo);
            const proformaInvNos = detectionParams.filter(inv => inv.invStatus !== 'A').map(inv => inv.invNo);

            const noChangesDetectedAINos = actualInvNos.filter(invNo => !changeDetectedInvNos.includes(invNo));
            const noChangesDetectedPINos = proformaInvNos.filter(invNo => !changeDetectedInvNos.includes(invNo));
            if (noChangesDetectedAINos.length > 0 || noChangesDetectedPINos.length > 0) {
              this.loading = true;

              let preMsg = '';
              if (noChangesDetectedAINos.length > 0 && noChangesDetectedPINos.length > 0) {
                preMsg = 'Actual Invoice(s): ' + noChangesDetectedAINos.join(', ') + ' and Proforma Invoice(s): ' + noChangesDetectedPINos.join(', ');
              } else if (noChangesDetectedAINos.length > 0) {
                preMsg = 'Actual Invoice(s): ' + noChangesDetectedAINos.join(', ');
              } else {
                preMsg = 'Proforma Invoice(s): ' + noChangesDetectedPINos.join(', ');
              }

              this.frmDialogMessage = preMsg + ' have no changes. Confirm to cancel this/these invoice?';
              this.genericUtil.showDialog(
                'invCancellationDialogYesNo',
                'Confirm',
                500,
                200
              );
            } else {
              self.doCancelInvoices();
            }
        }
      } else {
        this.loading = true;
        this.dialogPlugin.show('information', 'Information', 'HTTP Call error.', 'okonly', {ok: 'this.loading=false;'});
      }
    });

  }

  /**
   * Cancel chosen actual invoices.
   */
  doCancelInvoices() {

    // Validation
    let invalidInvoices = [];
    let invalidNotChosenSiblings = [];
    let notChosenSiblingInvs = [];
    const selectedActualInvNos = [];
    this.gridApiProforma.getSelectedRows().forEach(dt => {
      if (dt.invStatus === 'A' || (dt.invStatus === 'P' && dt.invIsPrinted === "Y" && dt.reason.trim() != "")) {
        selectedActualInvNos.push(dt.invNo);
      }
    });

    this.gridApiProforma.getSelectedRows().forEach(dt => {
      if (dt.invStatus === 'A' || (dt.invStatus === 'P' && dt.invIsPrinted === "Y" && dt.reason.trim() != "")) {
        if (!dt.reason || !dt.reason.trim()) {
          invalidInvoices.push(dt.invNo);
        }

        this.gridApiProforma.forEachNode(node => {
          const nodeData = node.data;
          if (!selectedActualInvNos.includes(nodeData.invNo)) {
            if (nodeData.blNo === dt.blNo && nodeData.payerId === dt.payerId && dt.invSplitMethod !== '') {
              notChosenSiblingInvs.push(nodeData);
              if (!nodeData.reason || !nodeData.reason.trim()) {
                invalidNotChosenSiblings.push({
                  invNo: nodeData.invNo,
                  payer: nodeData.payer,
                });
              }
            }
          }
        });
      }
    });

    if (invalidInvoices.length > 0 || invalidNotChosenSiblings.length > 0) {
      this.loading = true;
      let msg = '';
      if (invalidInvoices.length > 0) {
        msg = 'Please input reason for invoice(s): ' + invalidInvoices.join(', ') + ' to proceed to cancellation.\n';
      }
      invalidNotChosenSiblings.forEach(sib => {
        msg += 'Invoice (' + sib.invNo + ') detected under Payer - ' + sib.payer + '. Please input cancellation reason for ' + sib.invNo + '.\n';
      });
      this.dialogPlugin.show('information', 'Information', msg, 'okonly', {ok: 'this.loading=false;'});
      return;
    }

    // Create api params
    const params = [];
    console.log('--debug selected rows --');
    this.gridApiProforma.getSelectedRows().forEach(dt => {
      console.log(dt);
      if (dt.invStatus === 'A' || (dt.invStatus === 'P' && dt.invIsPrinted === "Y" && dt.reason.trim() != "")) {
        let rowDt = new CancellingParam();
        rowDt.blOfficeCode = this.officeCode;
        rowDt.blNo = dt.blNo;
        rowDt.invNo = dt.invNo;
        rowDt.reason = dt.reason.trim();
        rowDt.userId = this.userDetails.userName.toUpperCase().trim();
        rowDt.invStatus = dt.invStatus;
        params.push(rowDt);
      }
    });
    console.log('--debug not chosen sibling--');
    notChosenSiblingInvs.forEach(dt => { // regarless of invoice status
       console.log(dt);
      let rowDt = new CancellingParam();
      rowDt.blOfficeCode = this.officeCode;
      rowDt.blNo = dt.blNo;
      rowDt.invNo = dt.invNo;
      rowDt.reason = dt.reason.trim();
      rowDt.userId = this.userDetails.userName.toUpperCase().trim();
      rowDt.invStatus = dt.invStatus;
      params.push(rowDt);
    });



    // Do cancelation

    this.loading = true;
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/invoice/cancelActualInv', params)
      .subscribe((resp) => {
        if (resp.ok) {
          this.loading = false;

          if (resp.json().status === 'ok') { // succeed partially/totally
            // Show error(s) if exist

            // Success inv
            let successInvs = [];
            let successBls = [];
            const mapSuccessInvReason = {};
            resp.json().successCancellations.forEach(dt => {
              successInvs.push(dt.invNo);
              mapSuccessInvReason[dt.invNo] = dt.invReason;
              if (!successBls.includes(dt.invBlNo)) {
                successBls.push(dt.invBlNo);
              }
            });

            // Error msg
            let errorMessages = [];
            resp.json().error.forEach(dt => {
                errorMessages.push(dt.errMessage);
            });

            if (errorMessages.length > 0) {

              let msg = 'Cancellation with error:<br /><br />';
              if (successInvs.length > 0) {
                msg += 'Success cancelled invoice(s): ' + successInvs.join(', ') + '<br /><br />';
                msg += 'Detailed error(s):<br />';
              }
              msg += errorMessages.join('<br />');

              this.loading = true;
              this.dialogPlugin.show('information', 'Information', msg, 'okonly', {ok: 'this.loading=false;'});
            }

            // Update the old rows: status, uncheck
            const nodeDataToRefresh = [];
            this.gridApiProforma.forEachNode(node => {
              const dt = node.data;
              if (successInvs.includes(dt.invNo)) {
                // uncheck
                node.setSelected(false);
                // update grid's data
                dt.invStatus = 'C';
                dt.status = 'CANCELLED';
                dt.reason = mapSuccessInvReason[dt.invNo];

                nodeDataToRefresh.push(dt);
              }
            });

            //
            // * Cuz the grid cannot re-value the value 'isSelectable'.
             //* So we need to trigger the grid to refresh the cells.
             //
             this.gridApiProforma.updateRowData({
              update: nodeDataToRefresh
             });

            const selectionBy = $('input[name="selectionBy"]:checked').val();
            if (selectionBy === 'BL') {
              this.justCancelButNotReretrieveBlNos = successBls;
            }

          } else {
            this.loading = true;
              this.dialogPlugin.show('information', 'Information', resp.json().message, 'okonly', {ok: 'this.loading=false;'});
          }

        } else {
          this.dialogPlugin.show('information', 'Information', 'HTTP Call error.', 'okonly', {ok: 'this.loading=false;'});
        }
      });

  }

  toolbarEvent(event) {
    const self = this;
    switch (event) {
      case 'retrieve':
        this.onRetrieve();
        break;
      case 'ok' :
          if (this.gridApiProforma.getSelectedRows().length > 0 ) {

                const hasBlankStatus = self.preventPrintOnBlankStatus();
                if (hasBlankStatus) {
                  break;
                }
                // Check if the selected rows contains an AI with blank status
                let hasInvToBeCancelled = false;
                this.gridApiProforma.getSelectedRows().forEach(dt => {
                  if (dt.invStatus === 'A' || (dt.invStatus === 'P' && dt.invIsPrinted === "Y" && dt.reason.trim() != "")) {
                    hasInvToBeCancelled = true;
                  }
                });

                if (hasInvToBeCancelled) {
                  self.doCancelInvoicesWrapper();
                  //self.doCancelInvoices();
                  //self.preventPrintActualOnProforma();
                } else {
                  // proceed to print invoice(s)
                  if ($('#silentMode').is(':checked')) {

                    /*GOS-681 To silent print via Backend service (no direct calling to print service from UI)
                      check for user spooler first, whether already been configured or not
                      const pData = {};
                      pData['userEmail'] = this.userEmail;
                      pData['userLocationId'] = this.officeId;
                      pData['userDocumentType'] = 'PROFORMAINVOICE';
                      this.genericService.POST(this.configService.config.BASE_API.toString() +
                        '/MasterUsers/checkSpoolers', pData).subscribe((resp) => {
                        if (resp.ok) {
                          if (resp.json().status.toString() === 'ok') {
                            console.log('spooler debug');
                            console.log(resp.json());
                            this.loading = true;
                            this.frmDialogMessage = 'This action will print the document to the nearest printer?';
                            this.genericUtil.showDialog(
                              'invDialogSilentPrintYesNo',
                              'Confirm',
                              350,
                              150
                            );
                          } else {
                            this.loading = true;
                            this.dialogPlugin.show('information', 'Information',
                              'Please Ask Administrator to Register Printer Spooler For Your User Id', 'okonly', {ok: 'this.loading=false;'});
                          }
                        } else {
                          this.loading = true;
                          this.dialogPlugin.show('information', 'Information',
                            'API Call error. Please contact support team.', 'okonly', {ok: 'this.loading=false;'});
                        }
                      });
                    */

                    this.frmDialogMessage = 'This action will print the document to the nearest printer?';
                    this.genericUtil.showDialog(
                      'invDialogSilentPrintYesNo',
                      'Confirm',
                      350,
                      150
                    );

                    // this.dialogPlugin.show('information', 'Information',
                    // 'Silent print not yet supported at the moment.', 'okonly', {ok: 'this.loading=false;'});
                  } else {
                    this.gridRowData = [];
                    this.gridPDFPrev.listStore.store = [];
                    this.gridApiProforma.getSelectedRows().forEach(dt => {
                      console.log(dt)
                      this.gridPDFPrev.listStore.addData(
                        {'invoiceNo' : dt.invNo, 'blNo': dt.blNo, 'printByCurrency': dt.invByCurr,
                          'convertToSGD': dt.convSGD, 'doNotConvertToSGD': dt.idconvNonSGD}
                      );
                      console.log(this.gridPDFPrev.listStore)
                      this.gridPDFPrev.url = '';
                      this.gridPDFPrev.loadData();
                    });
                    this.showPDF();
                  }

                }

          } else {
            this.loading = true;
            this.dialogPlugin.show('information', 'Information',
              'Please select at least 1 record to print.', 'okonly', {ok: 'this.loading=false;'});
          }

          break;
        case'cancel':
            this.defaultBehaveBL();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event: any) {
    if (event !== '') {
      eval(event);
    }
  }

  setValidatorVessel() {
    this.clearValidatorError();
    this.validatorRules = {
      invVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      invVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      invVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
    };
  }


  changeEventVessel(event) {
      console.log(event);
      if (event.vesselId == null || event.vesselId === '' || event.vesselId === undefined) {
        console.log('Vessel kosong');
        this.invoiceParam.invVesselCode = '';
        this.invoiceParam.invVesselId = '';
        this.invoiceParam.invVesselName = '';
      } else {
        this.invoiceParam.invVesselCode = event.vesselCode;
        this.invoiceParam.invVesselId = event.vesselId;
        this.invoiceParam.invVesselName = event.vesselName;

        this.cbVoyage.disableCombo = false;
        this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
          this.cookieService.getDefaultLocationCode() + '/' + event.vesselId + '/' + this.invoiceParam.invVesselBound.toUpperCase() +
          '/voyage_like=' + '{query}');
        this.cbVoyage.setValue('');

        this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
          '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
          this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
        this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
          '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
          this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
        this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
          '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
          this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
        this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
          '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
          this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
        this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
          '/invoice/findPOP/{query}');
      }

      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
  }


  changeSelection(indexParam) {
    if ($('#currBy_' + indexParam).is(':checked')) {
      /**
       * This case is when user CHECK the split-by-currency checkbox.
       * Means to split this invoice into child invoices if it contains more than 1 currency.
       */

        // Keep the checked/unchecked state until the api calling done
        $('#currBy_' + indexParam).prop('checked', false);

        this.gridApiProforma.forEachNode(node => {
          if (node.id == indexParam) {
            this.toggleOption = new ToggleOption();
            this.toggleOption.invOfficeCode = this.officeCode;
            this.toggleOption.invBlNo = node.data.blNo;
            this.toggleOption.invNo = node.data.invNo;
            this.toggleOption.invPrintByCurrency = 'Y';
            this.toggleOption.invConvertedToSgd = 'N';
            this.toggleOption.invUserName = this.userDetails.userName;
            this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

            console.log(node);
            console.log(node.data.blNo);
            console.log(node.data.invNo);

            console.log(this.toggleOption);
            this.loading = true;
            const self = this;
            this.genericService.POST(this.configService.config.BASE_API.toString() +
            // this.genericService.POST('http://localhost:12345' +
              '/invoice/setPrintByCurrency/', this.toggleOption).subscribe( (resp) => {
                if (resp.ok) {
                  if (resp.json().status === 'ok') {

                    // Confirm the checked/unchecked state
                    $('#currBy_' + indexParam).prop('checked', true);

                    $('#idNonConvSGD_' + indexParam).prop('checked', true);
                    $('#idConvSGD_' + indexParam).prop('checked', false);
                    $('#idConvSGD_' + indexParam).attr('disabled', false);

                    node.setDataValue('invByCurr', 1);
                    node.setDataValue('convSGD', 0);
                    node.setDataValue('idconvNonSGD', 1);

                    if (resp.json().data.length > 0) {
                      const rowDataNew = [];
                      const rowDataOld = [];

                      self.gridApiProforma.forEachNode(nodes => rowDataOld.push(nodes));
                      console.log('----oldRowData---');
                      console.log(rowDataOld);

                      rowDataOld.forEach((data, index) => {
                        rowDataNew.push(data.data);
                        console.log('pushed data ');
                        console.log(data);
                        console.log('data.data.blNo >>  ' + data.data.blNo);
                        console.log('resp.json().data[0].invBlNo >>  ' + resp.json().data[0].invBlNo);

                        if (data.data.blNo === resp.json().data[0].invBlNo && data.data.payerId === resp.json().data[0].invPayerId && data.data.invStatus != 'C') {
                          resp.json().data.forEach((dt, idx) => {
                            let invStatusString = '';
                            if (dt['invStatus'] === 'A') {
                              if(dt['invIsValid'] === 'N'){
                                invStatusString = 'Invalid invoice';
                              }
                              else if (dt['invIsPrintedActual'] === 'Y' && dt['invIsValid'] != 'N') {
                                invStatusString = 'Actual invoice printed.';
                              } else {
                                invStatusString = 'Actual created but not yet printed.';
                              }
                            } else if (dt['invStatus'] === 'P' && dt['invIsValid'] === 'N') {
                              invStatusString = 'Invalid invoice';
                            } else if (dt['invStatus'] === 'P') {
                              if (dt['invIsPrinted'] === 'Y') {
                                invStatusString = 'Proforma invoice printed.';
                              } else {
                                invStatusString = 'Proforma invoice created but not yet printed.';
                              }
                            } else if (dt['invStatus'] === 'C') {
                              invStatusString = 'CANCELLED';
                            } else {
                              invStatusString = 'Unable determine status.';
                            }
                            rowDataNew.push({
                              invNo: dt['invNo'],
                              blNo: dt['invBlNo'],
                              payer: dt['invPayerName'],
                              payerId: dt['invPayerId'],
                              reason: dt['invReason'],
                              status: invStatusString,
                              invIsValid: dt['invIsValid'],
                              invSplitMethod: dt['invSplitMethod'],
                              payerNo: dt['invPayerOrderInBl'],
                              blFcCurrency: dt['invHomeCurrency'],
                              invHash: dt['invHash'],
                              convSGD: (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                              idconvNonSGD: (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                              invByCurr: (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                              invIsChild: dt['invPartOfInvoice'],
                              invStatus: dt['invStatus'],
                              invIsPrinted: dt['invIsPrinted'],
                              invPartOfKey: dt['invPartOfInvoice'] === 'Y' ? dt['invPartOfKey'] : '',
                            });
                          });
                        }
                      });

                      self.clearGrid();
                      self.gridApiProforma.setRowData(rowDataNew);
                      self.loading = false;
                    } else {
                      self.loading = false;
                    }
                  } else {
                    self.loading = false;
                    this.dialogPlugin.show('information', 'Information', resp.json().message, 'okonly', {ok: 'this.loading=false;'});
                  }
                } else {
                  self.loading = true;
                  this.dialogPlugin.show('information', 'Information', 'Error occurred!', 'okonly', {ok: 'this.loading=false;'});
                }
            });
          }
        });
    } else {
      /**
       * This case is when user UNcheck the split-by-currency checkbox.
       * Means:
       *  - If this checkbox is on a child invoice -> DELETE this child invoice, and, MERGE its lines into its parent.
       *  - If this checkbox is on parent invoice -> DO NOT ALLOW.
       */
      this.onUncheckPrintByCurrency(indexParam);

    }

  }

  onUncheckPrintByCurrency(indexParam) {
    var thisNode, parentNodeId;
    this.gridApiProforma.forEachNode(node => {
      if (!thisNode && node.id == indexParam) {
        thisNode = node;
      }
    });

    if (thisNode.data.invIsChild === 'Y') {
      var parentInv = thisNode.data.invPartOfKey;
      this.gridApiProforma.forEachNode(node => {
        if (!parentNodeId && node.data.invNo == parentInv) {
          parentNodeId = node.id;
        }
      });

    } else {
      parentNodeId = indexParam;
    }

    this.rowDataUncheck = [];
    this.gridApiProforma.forEachNode( nodes => this.rowDataUncheck.push(nodes.data));

    let affectedInvNo = '';
    let affectedPayerNo = '';
    let affectedBlNo = '';
    const mergedList = [];
    this.gridApiProforma.forEachNode(node => {
      if (node.id == parentNodeId) {
        affectedInvNo = node.data.invNo;
        affectedPayerNo = node.data.payerNo;
        affectedBlNo = node.data.blNo;
      }
    });
    this.gridApiProforma.forEachNode(node => {
      if (node.id != parentNodeId) {
        if (node.data.blNo == affectedBlNo && node.data.payerNo == affectedPayerNo && node.data.invStatus != 'C' && node.data.invIsValid == 'Y') {
          mergedList.push(node.data.invNo);
        }
      }
    });
    if (mergedList.length > 0 ) {
      this.frmDialogMessage =
        'This action may resulting in cancellation of INV ' + mergedList.join(',') +
        ', upon cancellation data will be merged with INV ' + affectedInvNo + '?';
      this.genericUtil.showDialog(
        'invDialogYesNo',
        'Confirm',
        500,
        200
      );
    } else {
      // proceed to update this current invoice
      this.onDialogInvYes();
    }
  }

  // Dialog handler
  onDialogInvYes() {
    const self = this;

    // Keep the checked/unchecked state until the api calling done
    $('#currBy_' + this.mergedId).prop('checked', true);

    this.gridApiProforma.forEachNode(node => {
      if (node.id == this.mergedId) {
        this.toggleOption = new ToggleOption();
        this.toggleOption.invOfficeCode = this.officeCode;
        this.toggleOption.invBlNo = node.data.blNo;
        this.toggleOption.invNo = node.data.invNo;
        this.toggleOption.invPrintByCurrency = 'N';
        this.toggleOption.invConvertedToSgd = 'Y';
        this.toggleOption.invUserName = this.userDetails.userName;
        this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

        console.log(this.toggleOption);
        this.loading = true;
        this.genericService.POST(this.configService.config.BASE_API.toString() +
        // this.genericService.POST('http://localhost:12345' +
          '/invoice/setPrintByCurrency', this.toggleOption).subscribe((resp) => {
            if (resp.ok) {

            if (resp.json().status === 'ok') {

              // Confirm the checked/unchecked state
              $('#currBy_' + this.mergedId).prop('checked', false);

              $('#idNonConvSGD_' + self.mergedId).prop('checked', false);
              $('#idConvSGD_' + self.mergedId).attr('disabled', false);
              $('#idConvSGD_' + self.mergedId).prop('checked', true);

              node.setDataValue('invByCurr', 0);
              node.setDataValue('convSGD', 1);
              node.setDataValue('idconvNonSGD', 0);

              if (resp.json().data.length > 0) {
                const rowDataNew = [];
                const rowDataOld = [];

                self.gridApiProforma.forEachNode( nodes => rowDataOld.push(nodes));
                console.log('----oldRowData---');
                console.log(rowDataOld);

                rowDataOld.forEach( (data, index) => {
                  resp.json().data.forEach((dt, idx) => {
                    if (data.data.invNo === dt.invNo) {
                      rowDataOld.splice(index, 1);
                    }
                  });

                });

                rowDataOld.forEach( (data, index) => {
                  rowDataNew.push(data.data);
                });

                self.clearGrid();
                self.gridApiProforma.setRowData(rowDataNew);

                self.loading = false;
              } else {
                self.loading = false;
              }
            } else {
              self.loading = false;
              this.dialogPlugin.show('information', 'Information', resp.json().message, 'okonly', {ok: 'this.loading=false;'});
            }
            } else {
              this.dialogPlugin.show('information', 'Information', 'Error occurred!', 'okonly', {ok: 'this.loading=false;'});
            }
        });
      }

    });
    this.genericUtil.closeDialog('invDialogYesNo');
  }
  onDialogInvNo() {
    console.log('onDialogInvNo', this.rowDataUncheck);
    this.gridApiProforma.setRowData(this.rowDataUncheck);
    this.genericUtil.closeDialog('invDialogYesNo');
  }

  /**
   *
   * @param params row's params
   * @returns 'disabled' or ''
   */
  determineRowToBeDisableOrNot(params) {
    if (params.data.invStatus === 'A' || params.data.invStatus === 'C' || params.data.invIsValid === 'N' && params.data.invStatus === 'P' ) {
      return'disabled';
    } else if (params.data.invStatus === 'P') {
      // check if this is a parent inv or not
      var hasChild = false;
      this.gridApiProforma.forEachNode(node => {
        if (node.data.invPartOfKey == params.data.invNo) {
          hasChild = true;
        }
      });

      return hasChild && params.data.invIsChild === 'N' ? 'disabled' : '';
    }
  }

  // Grid Checkbox Event
  convertToSGDCellRenderer(params) {
    const self = this;
    const eDiv = document.createElement('div');
    console.log(params);

    // If this is a AI or (is a "parent" PI - means has no child) => disable the box
    var disabled = this.determineRowToBeDisableOrNot(params);
    if (params.value == 1) {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idConvSGD_' + params.node.id +
        '" name="converter_' + params.node.id + '" type="radio" checked value={{params.value}} ' + disabled + '></div>';
    } else {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idConvSGD_' + params.node.id +
        '" name="converter_' + params.node.id + '" type="radio" value={{params.value}} ' + disabled + '></div>';
    }

    eDiv.addEventListener('change', (rad) => {
      /**
       * This event fire only when user check the "Convert to SGD" radio MANUALLY.
       */
      console.log('change event radio fired');
      console.log(rad);
      if (params.data.invStatus === 'A') {
        return
      }

      /**
       * This case is when user UNcheck the split-by-currency checkbox.
       * Means:
       *  - If this checkbox is on a child invoice -> DELETE this child invoice, and, MERGE its lines into its parent.
       *  - If this checkbox is on parent invoice -> DO NOT ALLOW.
       */

      if (params.data.invIsChild === 'Y') {

        // Changes in GSO-772: now the mergedId would refer to the root/parent invoice
        var mergedId;
        if (params.data.invIsChild === 'Y') {
          this.gridApiProforma.forEachNode(node => {
            console.log('byCurRender child node:', node);
            if (!mergedId && node.data.invNo === params.data.invPartOfKey) {
              mergedId = node.id;
            }
          });
        } else {
          mergedId = params.node.id;
        }

        self.mergedId = mergedId;

        // trigger uncheck print by currency
        this.onUncheckPrintByCurrency(params.node.id);

      } else {
        // Update "Convert to SGD"
        $('#idNonConvSGD_' + params.node.id).prop('checked', false);
        $('#idConvSGD_' + params.node.id).prop('checked', true);
        self.gridApiProforma.forEachNode(node => {
          if (node.id == params.node.id) {
            this.toggleOption = new ToggleOption();
            this.toggleOption.invOfficeCode = this.officeCode;
            this.toggleOption.invBlNo = node.data.blNo;
            this.toggleOption.invNo = node.data.invNo;
            //this.toggleOption.invPrintByCurrency = 'Y';
            this.toggleOption.invConvertedToSgd = 'Y';
            this.toggleOption.invUserName = this.userDetails.userName;
            this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

            console.log('setting value of convSGD to -> ' + 1);
            node.setDataValue('convSGD', 1);
            node.setDataValue('idconvNonSGD', 0);

            this.genericService.POST(this.configService.config.BASE_API.toString() +
              '/invoice/setConvertSgd', this.toggleOption).subscribe();
          }
        });
      }

      /*
      $('#idNonConvSGD_' + params.node.id).prop('checked', false);
      $('#idConvSGD_' + params.node.id).prop('checked', true);
      $('#currBy_' + params.node.id).prop('checked', false);
      self.gridApiProforma.forEachNode(node => {
        if (node.id == params.node.id) {
          this.toggleOption = new ToggleOption();
          this.toggleOption.invOfficeCode = this.officeCode;
          this.toggleOption.invBlNo = node.data.blNo;
          this.toggleOption.invNo = node.data.invNo;
          this.toggleOption.invPrintByCurrency = 'N';
          this.toggleOption.invConvertedToSgd = 'Y';
          this.toggleOption.invUserName = this.userDetails.userName;
          this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

          console.log('setting value of convSGD to -> ' + 1);
          node.setDataValue('invByCurr', 0);
          node.setDataValue('convSGD', 1);
          node.setDataValue('idconvNonSGD', 0);
          console.log(this.toggleOption);
          this.genericService.POST(this.configService.config.BASE_API.toString() +
          // this.genericService.POST('http://localhost:12345' +
            '/invoice/setPrintByCurrency', this.toggleOption).subscribe((resp) => {
              if (resp.ok) {
                if ( resp.json().data.length > 0) {
                  const rowDataNew = [];
                  const rowDataOld = [];

                  self.gridApiProforma.forEachNode( nodes => rowDataOld.push(nodes));

                  console.log('debug change radio button');
                  rowDataOld.forEach( (data, index) => {
                    console.log('row data old');
                    console.log(data);
                    resp.json().data.forEach((dt, idx) => {
                      console.log('--resp json--');
                      console.log(dt);
                      if (data.data.invNo === dt.invNo) {
                        console.log('splice executed');
                        rowDataOld.splice(index, 1);
                      }
                    });

                  });

                  rowDataOld.forEach( (data, index) => {
                    rowDataNew.push(data.data);
                  });

                  self.clearGrid();
                  self.gridApiProforma.setRowData(rowDataNew);
                } else {
                  self.loading = false;
                }
              } else {
                self.loading = true;
                this.dialogPlugin.show('information', 'Information', 'Error occurred!', 'okonly', {ok: 'this.loading=false;'});
              }



          });
        }
      });
      */
    });

    return eDiv;
  }

  doNotConvertToSGDCellRenderer(params) {
    console.log('do not convert cell renderer method fired');
    const self = this;
    const eDiv = document.createElement('div');

    // If this is a AI or (is a "parent" PI - means has no child) => disable the box
    var disabled = this.determineRowToBeDisableOrNot(params);
    if (params.value == 1) {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idNonConvSGD_' +
        params.node.id + '" name="converter_' + params.node.id + '" type="radio" checked value={{params.value}} ' + disabled + '></div>';
    } else {
      eDiv.innerHTML = '<div class="ngSelectionCell" align="center"><input id="idNonConvSGD_' +
        params.node.id + '" name="converter_' + params.node.id + '" type="radio" value={{params.value}} ' + disabled + '></div>';
    }

    // if (params.value == 1) {
    //   eDiv.innerHTML = `<div class="ngSelectionCell" align="center"><input disabled=${params.data.invStatus === 'A'} id="idNonConvSGD_' +
    //     params.node.id + '" name="converter_' + params.node.id + '" type="radio" checked value={{params.value}}></div>`;
    // } else {
    //   eDiv.innerHTML = `<div class="ngSelectionCell" align="center"><input disabled=${params.data.invStatus === 'A'} id="idNonConvSGD_' +
    //     params.node.id + '" name="converter_' + params.node.id + '" type="radio" value={{params.value}}></div>`;
    // }

    eDiv.addEventListener('change', (rad) => {
      console.log('change event radio fired');
      console.log(rad);
      if (params.data.invStatus === 'A') {
        return
      }
      $('#idNonConvSGD_' + params.node.id).prop('checked', true);
      $('#idConvSGD_' + params.node.id).prop('checked', false);
      self.gridApiProforma.forEachNode(node => {
        if (node.id == params.node.id) {
          this.toggleOption = new ToggleOption();
          this.toggleOption.invOfficeCode = this.officeCode;
          this.toggleOption.invBlNo = node.data.blNo;
          this.toggleOption.invNo = node.data.invNo;
          //this.toggleOption.invPrintByCurrency = 'Y';
          this.toggleOption.invConvertedToSgd = 'N';
          this.toggleOption.invUserName = this.userDetails.userName;
          this.toggleOption.invUserDeptCode = this.userDetails.userDeptCode;

          console.log('setting value of convNonSGD to -> ' + 1);
          node.setDataValue('convSGD', 0);
          node.setDataValue('idconvNonSGD', 1);

          this.genericService.POST(this.configService.config.BASE_API.toString() +
            '/invoice/setConvertSgd', this.toggleOption).subscribe();
        }
      });
    });

    return eDiv;
  }
  byCurRender(params) {
    const cellValue = '';
    const self = this;
    const eDiv = document.createElement('div');
    eDiv.setAttribute('style', 'align=\'center\' ');

    // If this is a AI or (is a "parent" PI - means has no child) => disable the box
    var disabled = this.determineRowToBeDisableOrNot(params);
    if (params.value == 1) {
      console.log('params.value before change selection 1=> ' + params.value);
      eDiv.innerHTML = '<input style="width:15px;height:15px;margin-top:3%" id="currBy_' + params.node.id +
        '" name="currBy_' + params.node.id + '" checked   value = 1  type="checkbox" ' + disabled + ' />';
      eDiv.addEventListener('change', (listen) => {

        // Changes in GSO-772: now the mergedId would refer to the root/parent invoice
        var mergedId;
        if (params.data.invIsChild === 'Y') {
          this.gridApiProforma.forEachNode(node => {
            console.log('byCurRender child node:', node);
            if (!mergedId && node.data.invNo === params.data.invPartOfKey) {
              mergedId = node.id;
            }
          });
        } else {
          mergedId = params.node.id;
        }

        self.mergedId = mergedId;
        self.changeSelection(params.node.id);
      });

    } else {
      eDiv.innerHTML = '<input style="width:15px;height:15px;margin-top:3%" id="currBy_' + params.node.id +
        '" name="currBy_' + params.node.id + '"   value = 0  type="checkbox" ' + disabled + ' /  >';

      eDiv.addEventListener('change', () => {
        console.log('button clicked 2');
          self.changeSelection(params.node.id);
      });
    }

    return eDiv;
  }




  changeEventCriteria(event) {
    const isiGrid = [];
    this.invoiceParam = new InvoiceParam();
    if (event.target.value.toString() === 'VESSEL') {
      this.selection = 'VESSEL';
      this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
      this.gridApiProforma.updateRowData({
        remove: isiGrid
      });
      this.gridApiProforma.refreshCells();

      this.addRemoveButton = 'none';
      this.dataBLArr = [];
      this.dataInvoice = [];
      this.clearValidatorError();
      this.isError = false;
      // $('#selBoundO').prop('checked', true);
      // $('#selBoundI').prop('checked', false);

      this.setBound();

      $('#myContainer').attr('disabled', false);
      $('#idBound').attr('disabled', false);
      $('#selInvoice').prop('checked', false);
      $('#selBL').prop('checked', false);
      $('#selVessel').prop('checked', true);
      this.cbVessel.disableCombo = false;
      this.cbVoyage.disableCombo = false;
      this.cbPayer.disableCombo = true;

      this.cbPayer.setValue('');

      this.cbPlacePayment.disableCombo = false;
      this.cbPlacePayment.setForceValue('');
      this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();

      this.cbDischargePort.setValue('');
      this.cbLoadPort.setValue('');

      this.cbTranshipmentPort.setValue('');

      this.cbLoadPort.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbVessel.setValue('');
      this.cbVoyage.setValue('');
      this.disableToolbarButtons = 'ok';

      this.invoiceParam.invIsCoc = 'Y';
      this.invoiceParam.invIsCocTs = 'N';
      this.invoiceParam.invIsSoc = 'N';
      this.invoiceParam.invIsSocTs = 'N';
      this.invoiceParam.invIsSlot = 'N';
      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');

    } else if (event.target.value.toString() === 'INVOICE') {
      this.mergedId = 0;
      this.selection = 'INVOICE';
      this.invoiceParam = new InvoiceParam();
      this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
      this.invoiceParam.invUserName = this.userDetails.userName;
      const isiGrid = [];
      this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
      this.gridApiProforma.updateRowData({
        remove: isiGrid
      });
      this.gridApiProforma.refreshCells();
      this.dataBLArr = [];
      this.dataInvoice = [];

      $('#myContainer').attr('disabled', true);
      $('#fieldSortBy').attr('disabled', true);
      $('#idBound').attr('disabled', true);
      $('#selVessel').prop('checked', false);
      $('#selInvoice').prop('checked', true);
      $('#selBL').prop('checked', false);

      this.setBound();



      $('#printFAI').prop('checked', true);
      $('#printItem').prop('checked', false);
      $('#convertSGD').prop('checked', true);
      $('#cntSOC').prop('checked', false);
      $('#silentMode').prop('checked', true);
      $('#cntCOC').prop('checked', true);
      $('#cntSOCTS').prop('checked', false);
      $('#cntCOCTS').prop('checked', false);
      $('#cntSLOT').prop('checked', false);
      $('#invByCurrency').prop('checked', false);
      $('#notconvertSGD').prop('checked', false);
      this.cbVessel.disableCombo = true;
      this.cbVoyage.disableCombo = true;
      this.cbPayer.disableCombo = false;
      this.addRemoveButton = '';
      this.cbVessel.setValue('');
      this.cbVoyage.setValue('');
      this.cbPayer.disableCombo = true;
      this.cbDischargePort.disableCombo = true;
      this.cbLoadPort.disableCombo = true;
      this.cbTranshipmentPort.disableCombo = true;
      this.cbPlacePayment.disableCombo = false;
      this.cbDischargePort.setValue('');
      this.cbLoadPort.setValue('');
      this.cbTranshipmentPort.setValue('');

      this.cbPayer.setValue('');

      this.cbPlacePayment.setForceValue('');
      this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
      this.clearValidatorError();
      this.isError = false;

      this.invoiceParam.invIsCoc = 'Y';
      this.invoiceParam.invIsCocTs = 'N';
      this.invoiceParam.invIsSoc = 'N';
      this.invoiceParam.invIsSocTs = 'N';
      this.invoiceParam.invIsSlot = 'N';

      $('#addGrid').attr('disabled', false);
      $('#remGrid').attr('disabled', false);
      this.disableToolbarButtons = 'retrieve,ok';
    } else {
        this.defaultBehaveBL();
    }
  }

  defaultBehave() {
    // this.loading = false;
    this.mergedId = 0;
    this.selection = 'INVOICE';
    this.invoiceParam = new InvoiceParam();
    this.invoiceParam.invDeptcode = this.userDetails.userDeptCode;
    this.invoiceParam.invUserName = this.userDetails.userName;
    const isiGrid = [];
    this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
      this.gridApiProforma.updateRowData({
        remove: isiGrid
      });
      this.gridApiProforma.refreshCells();
    this.dataBLArr = [];
    this.dataInvoice = [];

    $('#myContainer').attr('disabled', true);
    $('#fieldSortBy').attr('disabled', true);
    $('#idBound').attr('disabled', true);
    $('#selVessel').prop('checked', false);
    $('#selInvoice').prop('checked', false);
    $('#selBL').prop('checked', true);

    this.setBound();



    $('#printFAI').prop('checked', true);
    $('#printItem').prop('checked', false);
    $('#convertSGD').prop('checked', true);
    $('#cntSOC').prop('checked', false);
    $('#silentMode').prop('checked', true);
    $('#cntCOC').prop('checked', true);
    $('#cntSOCTS').prop('checked', false);
    $('#cntCOCTS').prop('checked', false);
    $('#cntSLOT').prop('checked', false);
    $('#invByCurrency').prop('checked', false);
    $('#notconvertSGD').prop('checked', false);
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbPayer.disableCombo = false;
    this.addRemoveButton = '';
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbPayer.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbDischargePort.setValue('');
    this.cbLoadPort.setValue('');
    this.cbTranshipmentPort.setValue('');

    this.cbPayer.setValue('');

    this.cbPlacePayment.setForceValue('');
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.clearValidatorError();
    this.isError = false;

    this.invoiceParam.invIsCoc = 'Y';
    this.invoiceParam.invIsCocTs = 'N';
    this.invoiceParam.invIsSoc = 'N';
    this.invoiceParam.invIsSocTs = 'N';
    this.invoiceParam.invIsSlot = 'N';

    $('#addGrid').attr('disabled', false);
    $('#remGrid').attr('disabled', false);
    this.disableToolbarButtons = 'retrieve,ok';

  }

  defaultBehaveBL() {
    this.mergedId = 0;
    const isiGrid = [];
    this.invoiceParam = new InvoiceParam();
    this.selection = 'BL';
    this.gridApiProforma.forEachNode(node => isiGrid.push(node.data));
    this.gridApiProforma.updateRowData({
      remove: isiGrid
    });
    this.gridApiProforma.refreshCells();
    this.dataBLArr = [];
    this.dataInvoice = [];

    $('#myContainer').attr('disabled', true);
    $('#fieldSortBy').attr('disabled', true);
    $('#idBound').attr('disabled', true);
    $('#selVessel').prop('checked', false);
    $('#selInvoice').prop('checked', false);
    $('#selBL').prop('checked', true);


    this.setBound();

    $('#printFAI').prop('checked', true);
    $('#printItem').prop('checked', false);
    $('#convertSGD').prop('checked', true);
    $('#cntSOC').prop('checked', false);
    $('#silentMode').prop('checked', true);
    $('#cntCOC').prop('checked', true);
    $('#cntSOCTS').prop('checked', false);
    $('#cntCOCTS').prop('checked', false);
    $('#cntSLOT').prop('checked', false);
    $('#invByCurrency').prop('checked', false);
    $('#notconvertSGD').prop('checked', false);

    this.invoiceParam.invIsCoc = 'Y';
    this.invoiceParam.invIsCocTs = 'N';
    this.invoiceParam.invIsSoc = 'N';
    this.invoiceParam.invIsSocTs = 'N';
    this.invoiceParam.invIsSlot = 'N';

    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbPayer.disableCombo = true;
    this.addRemoveButton = '';
    this.cbPayer.setValue('');
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbDischargePort.disableCombo = true;
    this.cbLoadPort.disableCombo = true;
    this.cbTranshipmentPort.disableCombo = true;
    this.cbPlacePayment.disableCombo = false;
    this.cbDischargePort.setValue('');
    this.cbLoadPort.setValue('');
    this.cbTranshipmentPort.setValue('');
    this.cbPlacePayment.setForceValue('');
    this.invoiceParam.invPlaceOfPaymentName = this.cookieService.getDefaultLocationName();
    this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.clearValidatorError();
    this.isError = false;

    console.log('should be fired');
    this.disableToolbarButtons = 'retrieve,ok';

    this.justCancelButNotReretrieveBlNos = [];

  }

  changeBound(event) {
    this.invoiceParam.invVesselBound = event.target.value.toUpperCase();
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
      this.cookieService.getDefaultLocationCode() + '/' + this.invoiceParam.invVesselId + '/' + this.invoiceParam.invVesselBound.toUpperCase() +
      '/voyage_like=' + '{query}');
    this.cbVoyage.setValue('');

    this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
      this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
    this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOP/{query}');
  }

  rowEvent(act: string) {
    const tableStore = [];

     if (act == 'add') {
      this.gridApiProforma.updateRowData({
        add:  [{'invNo': '', 'blNo': '', 'payer': '', 'reason': '', 'status': '', 'invByCurr': 0, 'convSGD': 1,
          'idconvNonSGD': 0, 'selected': 1, 'retrieve': 0}]
      });

     } else {
        const rowSel = this.gridApiProforma.getSelectedRows();
        this.gridApiProforma.updateRowData({
          remove:  rowSel
        });
        this.gridApiProforma.refreshCells();
        this.gridApiProforma.refreshView();
     }
  }



  onRetrieve() {
    this.clearGrid();
    this.invoiceParam['error-invPlaceOfPaymentCode'] = '';
    // this.invoiceParam['error-invAccCode'] = '';
    this.invoiceParam['error-invVesselCode'] = '';
    this.invoiceParam['error-invVesselVoyage'] = '';
    this.invoiceParam['error-invContainerOwnership'] = '';
    this.isError = false;

    if (this.invoiceParam.invIsCoc.trim() + this.invoiceParam.invIsCocTs.trim() +
      this.invoiceParam.invIsSoc.trim() + this.invoiceParam.invIsSocTs +
      this.invoiceParam.invIsSlot.trim() === 'NNNNN') {
      this.invoiceParam['error-invContainerOwnership'] = 'Please select at least one container ownership.';
    }

    /*
    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.invoiceParam['error-invPlaceOfPaymentCode'] = 'Please input Place of Payment.';
    }
     */

    /*
    if (this.invoiceParam.invAccCode === '') {
      this.isError = true;
      this.invoiceParam['error-invAccCode'] = 'Please input Acct/Debtor code.';
    } else {
      this.isError = false;
    }
     */

    if (this.invoiceParam.invVesselCode === '') {
      this.invoiceParam['error-invVesselCode'] = 'Please input vessel.';
    }

    if (this.invoiceParam.invVesselVoyage === '') {
      this.invoiceParam['error-invVesselVoyage'] = 'Please input voyage.';
    }

    if (this.invoiceParam['error-invPlaceOfPaymentCode'] !== '' ||
      this.invoiceParam['error-invVesselCode'] !== '' ||
      this.invoiceParam['error-invVesselVoyage'] !== '' ||
      this.invoiceParam['error-invContainerOwnership'] !== '') {
      this.isError = true;
    }

    if (!this.isError) {
      // this.loading = true;
      this.disableToolbarButtons = 'ok,cancel,close';
      const self = this;
      // ag-grid load
      this.gridApiProforma.showLoadingOverlay();
      this.genericService.POST(this.configService.config.BASE_API.toString() +
        '/invoice/generateInvoiceByVessel/', this.invoiceParam).pipe(finalize(() => this.gridApiProforma.hideOverlay())).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['status'] == 'ok') {

            if (resp.json()['data'].length > 0) {
              this.disableToolbarButtons = '';
              const rowData = [];
              const selectedData = [];
              this.gridApiProforma.forEachNode(node => rowData.push(node.data));
              if (rowData.length > 0) {
                rowData.forEach(items => {
                  selectedData.push(items);
                  self.gridApiProforma.updateRowData({
                    remove:  selectedData
                  });
                });
              }

              console.log('selectedData');
              console.log(selectedData);

              self.gridData = [];

              resp.json()['data'].forEach(dt => {
                let invStatusString = '';
                if (dt['invStatus'] === 'A') {
                  if(dt['invIsValid'] === 'N'){
                    invStatusString = 'Invalid invoice';
                  }
                  else if (dt['invIsPrintedActual'] === 'Y'  && dt['invIsValid'] != 'N') {
                    invStatusString = 'Actual invoice printed.';
                  } else {
                    invStatusString = 'Actual created but not yet printed.';
                  }
                  if(dt['blIsFinished'] === 'N') {
                    invStatusString = '';
                  }
                } else if (dt['invStatus'] === 'P' && dt['invIsValid'] === 'N') {
                  invStatusString = 'Invalid invoice';
                } else if (dt['invStatus'] === 'P') {
                  if (dt['invIsPrinted'] === 'Y') {
                    invStatusString = 'Proforma invoice printed.';
                  } else {
                    invStatusString = 'Proforma invoice created but not yet printed.';
                  }
                } else if (dt['invStatus'] === 'C') {
                  invStatusString = 'CANCELLED';
                } else {
                  invStatusString = 'Unable determine status.';
                }

                self.gridData.push({
                  invNo : dt['invNo'],
                  blNo : dt['invBlNo'],
                  payer : dt['invPayerName'],
                  payerId : dt['invPayerId'],
                  reason : dt['invReason'],
                  status : invStatusString,
                  invIsValid : dt['invIsValid'],
                  invSplitMethod : dt['invSplitMethod'],
                  payerNo : dt['invPayerOrderInBl'],
                  blFcCurrency : dt['invHomeCurrency'],
                  invHash : dt['invHash'],
                  convSGD :  (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                  idconvNonSGD : (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                  invByCurr : (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                  invIsChild: dt['invPartOfInvoice'],
                  invStatus: dt['invStatus'],
                  invIsPrinted: dt['invIsPrinted'],
                  invPartOfKey: dt['invPartOfInvoice'] === 'Y' ? dt['invPartOfKey'] : '',
                });
              });

              self.gridApiProforma.updateRowData({
                add:  self.gridData
              });

              self.loading = false;
            } else {
              self.loading = true;
              this.dialogPlugin.show('information', 'Information', 'There is no Invoice being generated for this Vessel and Voyage.', 'okonly', {ok: 'this.loading=false; this.clearGrid();'});
            }

          } else {
            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                selectedData.push(items);
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                this.gridApiProforma.updateRowData({
                  remove:  selectedData
                });
              });
            }
          }
          self.loading = false;
          // this.disableToolbarButtons = 'retrieve';
        }
      });

    }
  }


  clearGrid() {
    console.log('clear grid invoked');
    this.mergedId = 0;
     this.gridApiProforma.setRowData([]);
  }



  changeEventVoyage(event) {
    if (event.voyage == null || event.voyage == '' || event.voyage == undefined) {
      this.invoiceParam.invVesselVoyage = '';
    } else {
      this.invoiceParam.invVesselVoyage = event.voyage;
      this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPayer/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbTranshipmentPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOT/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbDischargePort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOD/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbLoadPort.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOL/' + this.invoiceParam.invVesselCode.replace('/', '*slash*') + '/' +
        this.invoiceParam.invVesselVoyage.replace('/', '*slash*') + '/' + this.invoiceParam.invVesselBound + '/' + '{query}');
      this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOP/{query}');

      this.genericService.POST(this.configService.config.BASE_API.toString() + '/invoice/checkGenerateInvoiceByVessel/', this.invoiceParam).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['size'] > 0) {
            if (resp.json()['flagPOT'] == 'Y') {
              console.log('voyage -> gt 10 and pot = Y');
              this.cbPayer.disableCombo = false;
              this.cbLoadPort.disableCombo = false;
              this.cbTranshipmentPort.disableCombo = false;
              this.cbDischargePort.disableCombo = false;
              this.cbPlacePayment.disableCombo = false;
              this.cbPayer.setValue('');
              this.cbLoadPort.setValue('');
              this.cbTranshipmentPort.setValue('');
              this.cbDischargePort.setValue('');
            } else {
              console.log('voyage -> gt 10 and pot = N');

              this.cbPayer.disableCombo = false;
              this.cbLoadPort.disableCombo = false;
              this.cbTranshipmentPort.disableCombo = true;
              this.cbDischargePort.disableCombo = false;
              this.cbPlacePayment.disableCombo = false;
              this.cbPayer.setValue('');
              this.cbLoadPort.setValue('');
              this.cbTranshipmentPort.setValue('');
              this.cbDischargePort.setValue('');
            }
          } else {
            // disable all
            console.log('voyage -> lt 10');

            this.cbPayer.disableCombo = true;
            this.cbLoadPort.disableCombo = true;
            this.cbTranshipmentPort.disableCombo = true;
            this.cbDischargePort.disableCombo = true;
            this.cbPayer.setValue('');
            this.cbLoadPort.setValue('');
            this.cbTranshipmentPort.setValue('');
            this.cbDischargePort.setValue('');
          }
        } else {
          console.log('ERROR HIT');
        }
      });

    }

  }

  changeEventLoadPort(event) {
    console.log('event Load Port : ' + event);

    if (event.invPortOfLoadingCode == null || event.invPortOfLoadingCode === '' ||
      event.invPortOfLoadingCode == undefined) {
       this.invoiceParam.invLoadPortCode = '';
       this.invoiceParam.invLoadPortName = '';
    } else {
      this.invoiceParam[`error-invPortOfLoadingName`] = "";
      this.invoiceParam.invLoadPortCode = event.invPortOfLoadingCode;
      this.invoiceParam.invLoadPortName = event.invPortOfLoadingName;
    }
  }

  changeEventDischargePort(event) {
    if (event.invPortOfDischargeCode == null || event.invPortOfDischargeCode === '' ||
      event.invPortOfDischargeCode == undefined) {
      this.invoiceParam.invDischargePortCode = '';
      this.invoiceParam.invDischargePortName = '';
    } else {
      this.invoiceParam[`error-invPortOfDischargeName`] = "";
      this.invoiceParam.invDischargePortCode = event.invPortOfDischargeCode;
      this.invoiceParam.invDischargePortName = event.invPortOfDischargeName;
    }
  }
  changeEventTranshipmentPort(event) {
    if (event.invPortOfTransshipmentCode == null || event.invPortOfTransshipmentCode == '' ||
      event.invPortOfTransshipmentCode == undefined) {
     console.log('transshipment port >>blank');
      this.invoiceParam.invTransshipmentPortCode = '';
     this.invoiceParam.invTransshipmentPortName = '';
    } else {
      console.log('transshipment port >> not blank >> ' + event.invPortOfTransshipmentName);
      this.invoiceParam[`error-invPortOfTransshipmentName`] = "";
      this.invoiceParam.invTransshipmentPortCode = event.invPortOfTransshipmentCode;
      this.invoiceParam.invTransshipmentPortName = event.invPortOfTransshipmentName;
    }
  }


  infoGrid(event) {}

  /**
   *
   * @param blNo
   * @param method
   * @param theLineAlreadyChosen if the line that user input already chosen (checkbox checked).
   */
  getInvoiceStatus(blNo: String, method: String, theLineAlreadyChosen: boolean) {
    /*
    this.invoiceParam['error-invPlaceOfPaymentCode'] = '';
    // this.invoiceParam['error-invAccCode'] = '';
    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.isError = true;
      this.invoiceParam['error-invPlaceOfPaymentCode'] = 'Please input Place of Payment.';
    } else {
      this.isError = false;
    }
    */

    if (this.invoiceParam.invPlaceOfPaymentCode === '') {
      this.invoiceParam.invPlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    }

    /*
    if (this.invoiceParam.invAccCode === '') {
      this.isError = true;
      this.invoiceParam['error-invAccCode'] = 'Please input Acct/Debtor code.';
    } else {
      this.isError = false;
    }
    */

    if (!this.isError) {
      this.gridData = [];
      const radioParamYes = 1;
      const radioParamNo = 0;
      const paramCur = 0;



      if (blNo.trim() === '' || blNo.trim() === undefined) {
        blNo = 'blNo';
      } else {
        this.loading = true;
        this.disableToolbarButtons = 'retrieve, ok';
      }
      let boxCheck: number;
      const self = this;
      let url = '';
      if (method === 'BL') {
        url = this.configService.config.BASE_API.toString() + '/invoice/generateInvoice/' + this.userDetails.userName.toUpperCase().trim() +
          '/' + this.officeCode + '/' + blNo.toUpperCase().trim() + '/' + this.invoiceParam.invVesselBound +
          '/' + (this.invoiceParam.invAccCode === '' ? '*' : this.invoiceParam.invAccCode) + '/' + this.invoiceParam.invPlaceOfPaymentCode;
      } else if (method === 'INV') {
        url = this.configService.config.BASE_API.toString() + '/invoice/generateInvoiceByInvNo/' + this.userDetails.userName.toUpperCase().trim() +
          '/' + this.officeCode + '/' + blNo.toUpperCase().trim() + '/' + this.invoiceParam.invVesselBound +
          '/' + (this.invoiceParam.invAccCode === '' ? '*' : this.invoiceParam.invAccCode) + '/' + this.invoiceParam.invPlaceOfPaymentCode;
      }
      this.genericService.GET(url).subscribe((resp) => {
        console.log('test');
        console.log(resp.json());
        console.log(resp.json().length);

        if (resp.ok) {
          if (resp.json()['status'] == 'ok') {

            // GSO-1027
            let message = resp.json()['message'];
            if(method === 'BL' && message !== 'success'){
              this.dialogPlugin.show('information', 'Information', message, 'okonly', {ok: 'this.loading=false;'});
            }

            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                if (items['invNo'].toString() === '' || items['blNo'].toString() === '') {
                  selectedData.push(items);
                }
                self.gridApiProforma.updateRowData({
                  remove: selectedData
                });
              });
            }

            console.log('selectedData');
            console.log(selectedData);

            if (resp.json()['data'].length > 0) {
              this.disableToolbarButtons = 'retrieve';
            }

            resp.json()['data'].forEach(dt => {
            let invStatusString = '';
              if (dt['invStatus'] === 'A') {
                if(dt['invIsValid'] === 'N'){
                  invStatusString = 'Invalid invoice';
                }
                else if (dt['invIsPrintedActual'] === 'Y' && dt['invIsValid'] != 'N') {
                  invStatusString = 'Actual invoice printed.';
                } else {
                  invStatusString = 'Actual created but not yet printed.';
                }
              } else if (dt['invStatus'] === 'P' && dt['invIsValid'] === 'N') {
                invStatusString = 'Invalid invoice';
              } else if (dt['invStatus'] === 'P') {
                if (dt['invIsPrinted'] === 'Y') {
                  invStatusString = 'Proforma invoice printed.';
                } else {
                  invStatusString = 'Proforma invoice created but not yet printed.';
                }
              } else if (dt['invStatus'] === 'C') {
                invStatusString = 'CANCELLED';
              } else {
                invStatusString = 'Unable determine status.';
              }
              self.gridData.push({
                invNo : dt['invNo'],
                blNo : dt['invBlNo'],
                payer : dt['invPayerName'],
                payerId : dt['invPayerId'],
                reason : dt['invReason'],
                status : invStatusString,
                invIsValid : dt['invIsValid'],
                invSplitMethod : dt['invSplitMethod'],
                payerNo : dt['invPayerOrderInBl'],
                blFcCurrency : dt['invHomeCurrency'],
                invHash : dt['invHash'],
                convSGD :  (dt['invIsConvertedToSgd'] === 'Y' ? 1 : 0),
                idconvNonSGD : (dt['invIsConvertedToSgd'] === 'N' ? 1 : 0),
                invByCurr : (dt['invIsPrintByCurrency'] === 'Y' ? 1 : 0),
                invIsChild: dt['invPartOfInvoice'],
                invStatus: dt['invStatus'],
                invIsPrinted: dt['invIsPrinted'],
                invPartOfKey: dt['invPartOfInvoice'] === 'Y' ? dt['invPartOfKey'] : '',
                /*
                convSGD :  selectedData[0]['convSGD'],
                idconvNonSGD : selectedData[0]['idconvNonSGD'],
                invByCurr : selectedData[0]['invByCurr']
                */
              });
            });

            if (method === 'BL' && this.justCancelButNotReretrieveBlNos.length > 0) {
              // remove charges of this BL:
              const selectedData = rowData.filter(items => this.justCancelButNotReretrieveBlNos.includes(items['blNo']));
              this.gridApiProforma.updateRowData({
                remove:  selectedData
              });

              // reset flag:
              this.justCancelButNotReretrieveBlNos = [];
            }

            self.gridApiProforma.updateRowData({
              add:  self.gridData
            });

            // Set checked status:
            if (theLineAlreadyChosen) {
              this.gridApiProforma.forEachNode(node => {
                const affectedNode = self.gridData.find(item => item.blNo === node.data.blNo && item.invNo === node.data.invNo);
                if (affectedNode) {
                    node.setSelected(true);
                }
              });
            }

            const testData = [];
            this.gridApiProforma.forEachNode(node => testData.push(node.data));
            let invNos = testData.map(o => o.invNo);
            var isDuplicateinvNos = invNos.some((item, idx) => invNos.indexOf(item) != idx );
            let params = {
              rowIndex: this.rowIndexW,
              value: resp.json()['data'][0].invBlNo
            }
            if(isDuplicateinvNos){
              this.showMessageDuplicateInvoiceNo(params, testData, 'BL Number');
            }

            self.loading = false;
          } else {
            const rowData = [];
            const selectedData = [];
            this.gridApiProforma.forEachNode(node => rowData.push(node.data));
            if (rowData.length > 0) {
              rowData.forEach(items => {
                if (items['blNo'] === '' || items['invNo'] === '') {
                  selectedData.push(items);
                }
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                this.gridApiProforma.updateRowData({
                  remove:  selectedData
                });
              });
            }
          }
          self.loading = false;
        }
      });
    }
  }

  clearValidatorError() {
    this.invoiceParam['error-invVesselCode'] = '';
    this.invoiceParam['error-invVesselVoyage'] = '';
    this.invoiceParam['error-invVesselBound'] = '';
    this.invoiceParam['error-invLoadPortCode'] = '';
    this.invoiceParam['error-invDischargePortCode'] = '';
    this.invoiceParam['error-invTransshipmentPortCode'] = '';
    this.invoiceParam['error-invContainerOwnership'] = '';
  }



  showPDF() {
    const self = this;

    this.newDialogOpt = {
      title: 'PDF Preview',
      modal: true,
      closeText: 'hide',
      closeOnEscape: false,
      width: 1020,
      height: 690,
      open: function(event, ui) {
          // hide close button.
          $('.ui-dialog').css('z-index', 103);
          $('.ui-widget-overlay').css('z-index', 102);

          $('body').first().css('overflow', 'hidden');
      },
      close: function(event, ui) {
        $('body').first().css('overflow', ' auto');
        self.clearPDFPlugin();
      }
    };

    this.showDialog('pdfContainerNewInvoice', this.newDialogOpt);

  }

  gridEventPDFPrev(event) {
    console.log(event);
    switch (event.split('.')[0]) {
      case 'selected':
        this.selectedEvent(event);
        break;
      case 'click' :
        this.selectedEvent(event);
        break;
      default:
        break;
    }
  }

  selectedEvent(event) {
    //// // // console.log(event);
    let no = 0;
    const opt = (event.split('.').length > 1 ? event.split('.')[1].split('-')[0] : event );
    const opt2 = (event.split('.').length > 1 ? event.split('.')[1].split('-')[1] : event );
    console.log('OPT ' + opt);

    switch (opt) {
      case 'checked':
        no = opt2;
        this.selectedListMod(no, this.gridPDFPrev, 'no');
        this.gridPDFPrev.listStore.store.forEach(fe => {
          if (fe['no'] == no) {
            console.log('check value fe');
            console.log(fe);
            const printByCurrency = fe['printByCurrency'] > 0 ? 'Y' : 'N';
            const convertToSGD = fe['convertToSGD'] > 0 ? 'Y' : 'N';
            const doNotConvertToSGD = fe['doNotConvertToSGD'] > 0 ? 'Y' : 'N';
            let conversionOption = 'N';

            if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
              conversionOption = 'Y';
            } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
              conversionOption = 'N';
            } else {
              conversionOption = 'X';
            }

            this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getPDFProforma/' + this.officeCode + '/' +
              fe['blNo'] + '/' + fe['invoiceNo'] + '/' + this.userDetails.userName + '/' + printByCurrency + '/' + conversionOption).subscribe((resp) => {
                if (resp.ok) {

                  if(resp._body === "nok"){
                    this.dialogPlugin.show('Error', 'Information', "Unable Generate Invoice for FOF=0", 'okonly', {ok: ''});
                    this.closeDialog('pdfContainerNewInvoice');
                  } else{
                    fe['pdfUrl'] = this.configService.config.pdfUrl + '/download/invoice/' + resp._body;
                    console.log(resp._body);

                    /*
                    if (this.configService.config.server === 'DEVELOPMENT') {
                      fe['pdfUrl'] = 'http://localhost/download/invoice/' + resp._body;
                    } else if (this.configService.config.server === 'PRODUCTIONSTG') {
                      fe['pdfUrl'] = 'http://staging.glossys.samudera.id' + '/download/invoice/' + resp._body;
                    } else if (this.configService.config.server === 'PRODUCTION') {
                      fe['pdfUrl'] = 'http://sg.glossys.samudera.id' + '/download/invoice/' + resp._body;
                    } else if () {
                      fe['pdfUrl'] = 'http://sg.glossys.samudera.id' + '/download/invoice/' + resp._body;
                    }
                    */


                    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
                    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').append('<object id="o" data="' + fe['pdfUrl'] + '" type="application/pdf" width="100%" height="100%"></object>');
                  }
                }
              });
          }
        });

        break;
      case 'unchecked':
        no = opt2;
        break;
    }
  }

  selectedListMod(value, store: GridPluginComponent, key) {
    if (store.getSelectedValues().length > 1) {
      store.getSelectedValues().forEach(ff => {
        if (ff[key] != value) {
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0, 1);
    }
  }

  showDialog(id: string, options: any) {
    $('#' + id).dialog(options).dialog('open');
  }

  closeDialog(id: string) {
    $('#' + id).dialog('close');
    $('#' + id).dialog('destroy');
  }

  clearPDFPlugin() {
    this.gridPDFPrev.listStore.store.splice(0, this.gridPDFPrev.listStore.store.length);
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-viewer-container').empty();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-label').show();
    $('#pdfContainerNewInvoice').find('#pdfContainerNewInvoice-grid-container').show();
    this.gridPDFPrev.clearSelectedValues();
    this.closeDialog('pdfContainerNewInvoice');
  }

  onGridReady(params) {
    this.gridApiProforma = params.api;
    this.gridColumnProforma = params.columnApi;
    // this.gridColumnProforma.getColumn('invNo').getColDef().editable = false;
    // this.gridColumnProforma.getColumn('blNo').getColDef().editable = true;
    // this.gridColumnProforma.hideOverlay();
  }

  onCellValueChanged(params) {
    this.rowIndexW = params.rowIndex;
    const colId = params.column.getId();
    const testDat = [];

    this.gridApiProforma.forEachNode(node => testDat.push(node.data));

    testDat.forEach(grup => {
      grup.blNo = grup.blNo.toUpperCase();
      grup.invNo = grup.invNo.toUpperCase();
      grup.reason = grup.reason.toUpperCase();
    });

    // QA-1036
    // replace the comma (,) to space ( ) during
    testDat.forEach(ele=>{
      if(ele.reason && ele.reason.includes(',')){
        ele.reason = ele.reason.replaceAll(',', ' ').replace(/  +/g, ' ');
        this.gridApiProforma.refreshCells();
      }
    })

    if(params.oldValue){
      return;
    }
    let invNos = testDat.map(o => o.invNo);
    var isDuplicateblinvNos = invNos.some((item, idx) => item ? invNos.indexOf(item) != idx : false);
    if(isDuplicateblinvNos){
      this.showMessageDuplicateInvoiceNo(params, testDat, 'Invoice Number');
      return;
    }

    this.gridApiProforma.refreshCells();

    console.log('debug onValueCellChanged');
    console.log(params);

    if(!params.value){
      return;
    }

    if (this.isSpecialChars(params)) {
      const rowData = [];
      this.gridApiProforma.forEachNode(node => rowData.push(node.data));

      if (rowData.length > 0) {
        this.showMessageCheckAlphanumeric(rowData, params);
        return;
      }
    }

    if ($('#selBL').is(':checked')) {
      if (colId == 'blNo') {
        this.getInvoiceStatus(params.value, 'BL', params.node.selected);
      }
      /*
      } else if (colId == 'reason') {
            this.invReason = new InvoiceReason();
            this.invReason.invoiceOfficeCode = this.officeCode;
            this.invReason.invoiceBlNo = params.data.blNo;
            this.invReason.invoiceNo = params.data.invNo;
            this.invReason.invoiceReason = params.value;

            console.log(this.invReason);
            this.genericService.POST(this.configService.config.BASE_API.toString() +
              '/invoice/setReason', this.invReason).subscribe((resp) => {
              if (resp.ok) {
                if (resp.json()['status'] == 'ok') {
                  this.loading = true;
                  this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                } else {
                  this.loading = true;
                  this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
                }
              } else {
                this.loading = true;
                this.dialogPlugin.show('information', 'Information', resp.json()['message'], 'okonly', {ok: 'this.loading=false;'});
              }
            });
      }
      */
    } else if ($('#selInvoice').is(':checked')) {
      if (colId == 'invNo') {
        this.getInvoiceStatus(params.value, 'INV', params.node.selected);
      }
    }
  }

  showMessageCheckAlphanumeric(rowData, params) {
    let selectedRow = [];
    rowData.forEach(items => {
      if (items['invNo'].toString() != '' || items['blNo'].toString() != '') {
        if (items.blNo === params.data.blNo) {
          selectedRow.push(items);
          this.gridApiProforma.updateRowData({
            remove: selectedRow
          });
        }
      }else{
        selectedRow.push(items);
        this.gridApiProforma.updateRowData({
          remove: selectedRow
        });
      }
    });
    this.dialogPlugin.show(
      "error",
      "Error",
      "Only accept alphanumeric",
      "okonly",
      { ok: "" }
    );
  }

  showMessageDuplicateInvoiceNo(params, testDat, numberType){
    const selectedTestDat = [];
    this.dialogPlugin.show(
      "error",
      "Error",
      `Duplicate ${numberType} ${params.value} detected`,
      "okonly",
      { ok: "" }
    );
    testDat.forEach(items => {
      selectedTestDat.push(items);
      const selectedRow = selectedTestDat.splice(params.rowIndex, 1)
      this.gridApiProforma.updateRowData({
        remove: selectedRow
      });
    });
  }

  changeEventPayer(event) {
    if (event.invPayerId == null || event.invPayerId === '' || event.invPayerId == undefined) {
      console.log('payer goes blank');
      this.invoiceParam.invPayerCode = '';
      this.invoiceParam.invPayerName = '';
    } else {
      console.log('payer supposed to have value');
      console.log('payer supposed to have value');
      this.invoiceParam[`error-invPayerName`] = "";
      this.invoiceParam.invPayerCode = event.invPayerId;
      this.invoiceParam.invPayerName = event.invPayerName;
    }
  }



  changeEventPlacePayment(event) {
    if (event.invPlaceOfPaymentCode != null && event.invPlaceOfPaymentCode !== '' && event.invPlaceOfPaymentCode != undefined) {
      this.invoiceParam[`error-invPlaceOfPaymentCode`] = "";
      this.invoiceParam.invPlaceOfPaymentCode = event.invPlaceOfPaymentCode;
      this.invoiceParam.invPlaceOfPaymentName = event.invPlaceOfPaymentCode;
    } else {
      this.invoiceParam.invPlaceOfPaymentCode = '';
      this.invoiceParam.invPlaceOfPaymentName = '';
    }
  }

  // CheckboxesHandles
  changeContainerOwnershipCOC(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsCoc = 'Y';
    } else {
      this.invoiceParam.invIsCoc = 'N';
    }
  }

  changeContainerOwnershipCOCTS(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsCocTs = 'Y';
    } else {
      this.invoiceParam.invIsCocTs = 'N';
    }
  }

  changeContainerOwnershipSOC(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSoc = 'Y';
    } else {
      this.invoiceParam.invIsSoc = 'N';
    }
  }

  changeContainerOwnershipSOCTS(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSocTs = 'Y';
    } else {
      this.invoiceParam.invIsSocTs = 'N';
    }
  }

  changeContainerOwnershipSLOT(event) {
    if (event.target.checked) {
      this.invoiceParam.invIsSlot = 'Y';
    } else {
      this.invoiceParam.invIsSlot = 'N';
    }
  }

  onDialogSilentPrintYes() {
    const listOfInvoices = [];

    // construct payload to silent print
    const payload = {}; // real payload
    const printDataList = []; // used to hold the document list
    const invData = {}; // not sure what is it used for
    const printDocDetail = {};  // use to hold the document details to be printed

    payload["userId"] = this.userId;
    payload["userEmail"] = this.userEmail;
    payload["userLocationId"] = this.officeId;
    payload["server"] = this.configService.config.server;

    const self = this;
    this.gridApiProforma.getSelectedRows().forEach(fe => {
      setTimeout( () => {
        const printByCurrency = fe['invByCurr'] > 0 ? 'Y' : 'N';
        const convertToSGD = fe['convSGD'] > 0 ? 'Y' : 'N';
        const doNotConvertToSGD = fe['idconvNonSGD'] > 0 ? 'Y' : 'N';
        let conversionOption = 'N';

        if (convertToSGD == 'Y' && doNotConvertToSGD == 'N') {
          conversionOption = 'Y';
        } else if (convertToSGD == 'N' && doNotConvertToSGD == 'Y') {
          conversionOption = 'N';
        } else {
          conversionOption = 'X';
        }
        console.log(fe);
        self.genericService.GET(self.configService.config.BASE_API.toString() + '/invoice/getPDFSilentProforma/' + self.officeCode + '/' +
          fe['blNo'] + '/' + fe['invNo'] + '/' + self.userDetails.userName + '/' + printByCurrency + '/' +
          conversionOption).subscribe((resp) => {
            if (resp.ok) {

              invData['docNo'] = fe['invNo'];
              invData['printDoc'] = [];

              if (self.configService.config.server === 'PRODUCTION') {
                printDocDetail['docName'] = resp._body;
              } else if (self.configService.config.server === 'PRODUCTIONSTG') {
                printDocDetail['docName'] = resp._body;
              } else {
                printDocDetail['docName'] = resp._body;
              }
              printDocDetail['docAttachName'] = '';
              printDocDetail['spoolerDoc'] = 'PROFORMAINVOICE';
              printDocDetail['spoolerDocAttach'] = '';
              printDocDetail['totalPrint'] = 1;
              printDocDetail['hasAttach'] = 'N';
              printDocDetail['proreason'] = fe['reason'];
              printDocDetail['docAttachName'] = '';

              invData['printDoc'].push(printDocDetail);
              printDataList.push(invData);
              listOfInvoices.push(invData);
              /**
               * https://samudera.atlassian.net/browse/GSO-828?focusedCommentId=33878
               * Because the solution in AI page is using setTimeout,
               * but what if the api getPDFSilent response too late?
               * so the solution is calling printing api for each file.
               * Later can refactor for better performance. (Maybe the make the getPDFSilent to recieve an inv array, instead of only 1)
               */
              payload["printList"] = [invData];
              // payload["printList"] = listOfInvoices;



               console.log(payload);
              this.loading = true;
              const hitUrl = this.configService.config.BASE_API.toString() + "/PrintService/print";
                      this.genericService.POST(hitUrl, payload).subscribe((resp) => {
                        console.log('this is response from printserver');
                        if (resp.json()['status'] === "ok") {
                          this.dialogPlugin.show(
                            'information',
                            'Information',
                            'Your document has been printed. Please check at your nearest printer to collect them.',
                            'okonly', { ok: '' }
                          );
                          this.loading = false;
                        } else {
                          this.dialogPlugin.show(
                            "warning",
                            "Warning",
                            resp.json()['message'],
                            "okonly",
                            { ok: "" }
                          );
                          this.loading = false;
                        }
                      }, err=>{
                        this.dialogPlugin.show(
                          'error',
                          'Error',
                          'An error has occurred.',
                          'okonly',
                          { ok: '' }
                        );
                        this.loading = false;
                      });



            }
        });
      }, 200);
    });



    console.log('files');
    console.log(listOfInvoices);
    console.log(printDataList);
    // this.loading = false;
    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
     // this.loading = true;
    // this.dialogPlugin.show('information', 'Information',
    //   'Your document has been printed. Please check at your nearest printer to collect them.', 'okonly', {ok: 'this.loading=false;'});
  }

  onDialogSilentPrintNo() {
    this.loading = false;
    this.genericUtil.closeDialog('invDialogSilentPrintYesNo');
  }

  isSpecialChars(event: any): boolean {
    let pastedText = event.value;
    var special = /[A-Za-z0-9]/;
    if (!special.test(pastedText)) {
      return true;
    }
    return false;
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = '',
    acctDebtorCode?: string
  ) {
    let clipboardData = acctDebtorCode ? '' : event.clipboardData;
    let pastedText = acctDebtorCode ? event : clipboardData.getData("text");
    this.showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar);
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        const regexPattern = /^[A-Za-z\s]+$/;
        if (!regexPattern.test(event.target.value)) {
          this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        }
        break;
      default:
        return
    }
  }

  handleBackspaceValueRegex(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        const regexPattern = /^[A-Za-z ]+$/;
        if (!regexPattern.test(event.target.value)) {
          this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        }
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet, validSPChar){
    if (regexName.test(pastedText) == false) {
      this.invoiceParam[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.invoiceParam[`error-${field}`] = "";
    }
  }

  onCellKeyPress($event) {
    if ($event.data.blNo === '') {
      $('#loader').css("display", "none");
    }
  }

  checkInvNoEditable(params) {
    const selectionBy = $('input[name="selectionBy"]:checked').val();
    return params.data.invStatus != 'C' && selectionBy === 'INVOICE' && params.data.invIsValid !== 'N';
  }

  checkBlNoEditable(params) {
    const selectionBy = $('input[name="selectionBy"]:checked').val();
    return params.data.invStatus != 'C' && selectionBy === 'BL' && params.data.invIsValid !== 'N';
  }

  checkReasonEditable(params) {
    return params.data.invStatus != 'C' && params.data.invIsValid !== 'N';
  }

}
