<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <div id="container">
    <dialog-plugin #dialogPlugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <div class="ui error message" [class.hidden]="isError == false">
      <ul>
        <li
          [class.hidden]="
            model['error-blOceanVesselCode'] == null ||
            model['error-blOceanVesselCode'] == '' ||
            model['error-blOceanVesselCode'] == undefined
          "
        >
          {{ model["error-blOceanVesselCode"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselVoyage'] == null ||
            model['error-blOceanVesselVoyage'] == ''
          "
        >
          {{ model["error-blOceanVesselVoyage"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blOceanVesselBound'] == null ||
            model['error-blOceanVesselBound'] == ''
          "
        >
          {{ model["error-blOceanVesselBound"] }}
        </li>
        <li
          [class.hidden]="
            model['error-blLoadPortCode'] == null ||
            model['error-blLoadPortCode'] == ''
          "
        >
          {{ model["error-blLoadPortCode"] }}
        </li>
      </ul>
    </div>

    <form class="ui form">
      <button class="ui button" style="display: none"></button>
      <div class="field" style="width: 100%">
        <fieldset style="border: 1px solid lightgray">
          <legend style="font-weight: bold; color: dodgerblue">
            B/L Search
          </legend>

          <div class="six wide fields">
            <div
              class="required two field"
              style="width: 100%"
              [class.error]="
                model['error-blOceanVesselCode'] != null &&
                model['error-blOceanVesselCode'] != ''
              "
            >
              <label>Vessel </label>
              <combo-plugin
                #cbVessel
                [settings]="settingVessel"
                (change)="changeEventVessel($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="
                  onPasteValidation(
                    $event,
                    'blOceanVesselCode',
                    formValidation.regVesVoy,
                    'and -./'
                  )
                "
                style="width: 100%"
              ></combo-plugin>
            </div>

            <div
              class="required two field"
              style="width: 75%"
              [class.error]="
                model['error-blOceanVesselVoyage'] != null &&
                model['error-blOceanVesselVoyage'] != ''
              "
            >
              <label>Voyage</label>
              <combo-plugin
                #cbVoyage
                [settings]="settingVoyage"
                (change)="changeEventVoyage($event)"
                (keypress)="formValidation.ModifiedPattern($event, '-./ ')"
                (paste)="
                  onPasteValidation(
                    $event,
                    'blOceanVesselVoyage',
                    formValidation.regVesVoy,
                    'and -./'
                  )
                "
                style="width: 100%"
              ></combo-plugin>
            </div>

            <div class="required two field" style="width: 60%">
              <!-- <label>Bound</label>
                  <input type="text" name="bound" placeholder="Bound" [(ngModel)]="model['blOceanVesselBound']"> -->

              <label>Bound</label>
              <input
                #sailingBound
                list="bound"
                name="bound"
                placeholder="Bound"
                [(ngModel)]="model['blOceanVesselBound']"
                (keypress)="OIMonly($event)"
                maxlength="1"
                required
                autocomplete="off"
              />
              <datalist id="bound">
                <option value="O"></option>
                <option value="I"></option>
                <option value="M"></option>
              </datalist>
            </div>

            <div
              class="required two field"
              style="width: 100%"
              [class.error]="
                model['error-blLoadPortCode'] != null &&
                model['error-blLoadPortCode'] != ''
              "
            >
              <label>Load Port</label>
              <combo-plugin
                #cbLoadPort
                [settings]="settingLoadPort"
                (change)="changeEventLoadPort($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>

            <div class="two field" style="width: 100%">
              <label>Transhipment Port</label>
              <combo-plugin
                #cbPOT1
                [settings]="settingPOT1"
                (change)="changeEventPOT1($event)"
                (keypress)="ExceptApostrophe($event)"
                style="width: 100%"
              ></combo-plugin>
            </div>

            <div class="field" style="width: 100%">
              <label>Discharge Port</label>
              <combo-plugin
                #cbDischargePort
                [settings]="settingDischargePort"
                (change)="changeEventDischargePort($event)"
                style="background-color: lightgray; width: 100%"
                (keypress)="ExceptApostrophe($event)"
              ></combo-plugin>
            </div>

            <div class="field" style="width: 100%">
              <label>Final Destination</label>
              <combo-plugin
                #cbFinalDest
                [settings]="settingFinalDest"
                (change)="changeEventFinalDest($event)"
                (keypress)="ExceptApostrophe($event)"
                style="background-color: lightgray; width: 100%"
              ></combo-plugin>
            </div>
          </div>
          <div class="ui styled accordion field" style="width: 100%">
            <div class="title">
              <i class="icon dropdown"></i>
              Advanced Search
            </div>
            <div class="content">
              <div class="six wide fields">
                <div class="field" style="width: 15%">
                  <label>OP Code</label>
                  <input
                    type="text"
                    name="op"
                    placeholder="OP Code"
                    [(ngModel)]="model['opCode']"
                    maxlength='4'
                    (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                  />
                </div>
                <div class="field" style="width: 15%">
                  <label>Status</label>
                  <combo-plugin
                    #cbFullEmpty
                    [settings]="settingFullEmpty"
                    (change)="changeEventFullEmpty($event)"
                  ></combo-plugin>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="field" style="width: 100%">
        <!--
        <br />
        <grid-plugin
          #grid
          [settings]="settingGrid"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
        -->
        <ag-grid-angular
                id="grid"
                [getRowStyle]="getRowStyle"
                style="width: 100%; height:450px;font-family: Lucida Console; font-size: 13px;"
                class="ag-theme-balham"
                [rowData]="rowData"
                [enableSorting]="true"
                [enableFilter]="true"
                [enableColResize]="true"
                [columnDefs]="columnDefs"
                [rowSelection]="rowSelection"
                [rowMultiSelectWithClick]="rowMultiSelectWithClick"
                (selectionChanged)="onSelectionChanged($event)"
                [suppressRowClickSelection]="true"
                (gridReady)="onGridReady($event)"
                >
          </ag-grid-angular>
      </div>
    </form>
  </div>
</div>

<div
  id="msgDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeMsgDialog()">Ok</button>
    </div>
  </form>
</div>

<div
  id="saveDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="15%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td style="font-size: 14px">
            <div [innerHTML]="msg | safe: 'html'"></div>
          </td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: right">
      <button class="positive ui button" (click)="closeSaveDialog()">Ok</button>
    </div>
  </form>
</div>

<div
  id="exceedDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%">
            <i class="info circle big icon"></i>
          </td>
          <td>Exceed Prepaid/Collect/Payer. Continue?</td>
        </tr>
      </table>
    </div>
    <div class="field" style="text-align: center">
      <button class="positive ui button" (click)="onDialogExceedYes()">
        Yes
      </button>
      <button class="negative ui button" (click)="onDialogExceedCancel()">
        Cancel
      </button>
    </div>
  </form>
</div>

<!--
<div id="selectMainBLDialog" style="display: none; font-family: Lucida Console, monospace">
  <form class="ui form">
    <div class="field" style="margin-bottom: 5px">
        <b style="margin: 10px 0px;display: inline-block;">Please select a record as the "Main BL"</b>
        <p style="color: red" *ngIf="isSelectMultipleMainBl">Please select a single record</p>
        <div id="grid-bl">
          <grid-plugin #gridBlMain [settings]="settingGridBl" (gridEvent)="gridEventBl($event)"
            (infoGrid)="infoGridBl($event)"></grid-plugin>
        </div>
    </div>
    <div class="field" style="text-align: center">
      <button class="positive ui button" (click)="continueCombine()">
        Ok
      </button>
      <button class="negative ui button" (click)="cancelCombine()">
        Cancel
      </button>
    </div>
  </form>
</div>
-->
<div id="selectMainBLDialog" class="dialogMainList">
  <!--
  <grid-plugin #gridBlMain [settings]="settingGridBl" (gridEvent)="gridEventBl($event)"
            (infoGrid)="infoGridBl($event)"></grid-plugin>
  -->

  <label>Please select a record as the "Main BL"</label>

  <ag-grid-angular
                id="gridMainSelection"
                [getRowStyle]="getRowStyle"
                style="width: 100%; height:80%;font-family: Lucida Console; font-size: 13px;"
                class="ag-theme-balham"
                [rowData]="rowDataMain"
                [columnDefs]="columnDefsMain"
                [rowSelection]="rowSelectionMain"
                [enableSorting]="true"
                [enableFilter]="true"
                [enableColResize]="true"
                [suppressRowClickSelection]="true"
                (selectionChanged)="onSelectionChangedMain($event)"
                (gridReady)="onGridReadyMain($event)"
                >
          </ag-grid-angular>

  <div class="btn-dialogMainList" style="padding-top: 10px;padding-bottom: 10px;">
    <button class="ui button btn-yes" [class.disabled]="isDisabledOK == true" (click)="continueCombine()">OK</button>
    <button class="ui button btn-no" (click)="cancelCombine()">Cancel</button>
  </div>
</div>

<footer-plugin [info]="info"></footer-plugin>
