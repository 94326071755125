export class MSailingSchedule {
  id: number = 0;
  vesselId: string = "";
  vesselCode: string = "";
  vesselName: string = "";
  voyage: string = "";
  bound: string = "O";
  serviceId: string = "";
  serviceCode: string = "";
  serviceName: string = "";
  nameOfMaster: string = "";
  nationality: string = "";
  blDate: string = "";
  exchangeRateDate: string = "";
  nonSsl: string = "";
  localETA: string = "";
  localETD: string = "";
  gdvNumber: string = "";
  leg: string = "";
  callingSeq: string = "";
  psaVoyage: string = "";
  agent: string = "";
  dryDocking: string = "";
  psaTransmitFlag: string = "";
  lockedBlDate: string = "";
  lockedExchangeRateDate: string = "";
  isValid: string = "";
  officeCode: string = "";
  createdUserid: string = "";
  createdDate: string = "";
  modifiedUserid: string = "";
  modifiedDate: string = "";

  portofLoadingCode: string = "";
  portofLoadingName: string = "";
  terminalCodeName: string = "";
  arrivalDateLoading: string = "";
  sailDateLoading: string = "";
  portofDischargeCode: string = "";
  portofDischargeName: string = "";
  terminalCodeDischargeName: string = "";
  arrivalDateDischarge: string = "";
  sailDateDischarge: string = "";
  stopBooking: string = "N";

  scheduleMapping: ScheduleMapping[] = [];

  constructor() {}
}

export class ScheduleMapping {
  // vesselName: string = "";
  // vesselId: string = "";
  // vesselCode: string = "";
  // voyage: string = "";
  mappingOption: string = "";

  id: number = 0;
  mappingSailingScheduleId: number = 0;
  mappingVesselId: string = "";
  mappingVesselCode: string = "";
  mappingVesselName: string =  "";
  mappingVoyage: string = "";
  mappingBound: string =  "";
  mappingServiceId: string = "";
  mappingSourceServiceCode: string =  "";
  mappingSourceVesselCode: string =  "";
  mappingSourceVesselName: string = "";
  mappingSourceVoyage: string = "";
  mappingSourceBound: string = "";
  mappingFromSource : string = "";
  isValid: string = "";
  officeCode: string = "";
  dateCreated: string = "";
  userCreated: string = "";
  dateModified: string = "";
  userModified: string =  "";

}
